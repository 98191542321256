import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import {
  BUTTON_CANCEL,
  CATEGORIES,
  MODAL_MOVE_PRODUCT_ARIA_LABEL,
  MODAL_MOVE_PRODUCT_BUTTON,
  MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE,
  MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ContainerModalActions from "../../../Common/Containers/ContainerModalActions";

const ModalMoveProduct = ({
  data,
  categories,
  onCloseModal,
  onMoveProduct,
}) => {
  const [category, setCategory] = useState("");
  const [error, setError] = useState(null);

  const { t } = useTranslationApp();

  const onSubmit = () => {
    if (category === "") {
      setError(t(MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED));
    } else {
      onMoveProduct(category);
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={data.open}
      fullWidth={true}
      onCloseModal={onCloseModal}
      scroll="paper"
      ariaLabel={t(MODAL_MOVE_PRODUCT_ARIA_LABEL)}
      customComponentTitle={
        !!error && (
          <Alert severity="error" sx={{ alignItems: "center" }}>
            {error}
          </Alert>
        )
      }
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t(MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE)}
      </Typography>
      <FormControl fullWidth variant="filled">
        <InputLabel id="select-categories">{t(CATEGORIES)}</InputLabel>
        <Select
          labelId="select-categories"
          value={category}
          name="categories"
          label={t(CATEGORIES)}
          onChange={(e) => setCategory(e.target.value)}
        >
          {Object.keys(categories).map((category, index) => (
            <MenuItem key={index} value={category}>
              {categories[category].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DialogActions sx={{}}>
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: MODAL_MOVE_PRODUCT_BUTTON,
            onClick: onSubmit,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalMoveProduct;
