import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import Menu from "../../../pages/Menu";
import { MenuProvider } from "../../../Contexts/MenuContext";
import { useSelectorApp } from "../../../lib/redux";
import { DialogActions } from "@mui/material";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { BUTTON_SAVE_CATALOG } from "../../../locales/keysTranslations";

const ModalCatalog = ({
  modalOpen,
  onCloseModal,
  onCallbackCreateCatalog = () => {},
}) => {
  const currentMenu = useSelectorApp((state) => state?.shop?.currentMenu);

  const onValidateMenu = () => {
    let hasCategories = false;
    let hasProducts = false;

    currentMenu.map((category) => {
      hasCategories = true;
      const products = category.products || [];
      products?.forEach((product) => {
        if (product?.isActive) {
          hasProducts = true;
        }
      });
      return null;
    });
    return hasCategories && hasProducts;
  };

  const validateMenu = onValidateMenu();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xlg"
      scroll="paper"
      hasTitle={true}
    >
      <MenuProvider>
        <Menu
          sx={{
            maxWidth: "100%",
            pl: 0,
            pb: 0,
          }}
        />
      </MenuProvider>

      {validateMenu && (
        <DialogActions>
          <ContainerModalActions
            propsPrimaryButton={{
              showButton: true,
              onClick: async () => {
                const response = await onCallbackCreateCatalog();
                if (response) {
                  onCloseModal();
                }
              },
              text: BUTTON_SAVE_CATALOG,
            }}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalCatalog;
