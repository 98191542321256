import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "../../Common/Fields/TextField";
import CurrencyField from "../../Common/NumberFormats/CurrencyField";
import RecurrentFields from "../../Common/Fields/RecurrentFields";
import {
  FIELD_REQUIRED,
  INVITE_FORM_VALUE_CLARIFICATION,
  VALUE_TO_PAY_AMBASSADOR_CLARIFICATION,
  INVITE_VALUE,
  NO,
  RECURRENT_FIELDS_PAYMENT_TITLE,
  VALUE_EXPRESSED_IN,
  VALUE_TO_PAY_AMBASSADOR,
  WITH_RECURRENT_INVITATION_TITLE,
  WITH_RECURRENT_PAYMENT_TITLE,
  YES,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { REVIEW_FORM_INVITATION_VALUE } from "../../../onboarding/stepsSelectors";
import Select from "../../Common/Selects/Select";
import { getCurrencyObject } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import { FEE_VALUE_DIRECT_PAYMENT } from "../../../utils/constants";
import { numberDecimal } from "../../../utils/numberFormats";

const AmbassadorForm = ({ control, watch, errors, setValue, validate }) => {
  const { t } = useTranslationApp();
  const withRecurrentInvite = watch("withRecurrentInvite");
  const withRecurrentPayment = watch("withRecurrentPayment");
  const valueToPay = Number(watch("valueToPay")) || 0;

  const dispatch = useDispatchApp();

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        {t(WITH_RECURRENT_INVITATION_TITLE)}
      </Typography>
      <Select
        name="withRecurrentInvite"
        displayEmpty={true}
        variant="filled"
        options={[
          { value: false, label: t(NO) },
          { value: true, label: t(YES) },
        ]}
        fullWidth
        sx={{
          "& .MuioutlinedInput-root": {
            "& .MuiSelect-select": {
              pt: "16px !important",
              pb: "16px !important",
            },
          },
          mb: 2,
        }}
        control={control}
        errors={errors}
        rules={{
          required: {
            value: false,
            message: t(FIELD_REQUIRED),
          },
        }}
      />
      {withRecurrentInvite && (
        <>
          <TextField
            name="value"
            dataTour={REVIEW_FORM_INVITATION_VALUE}
            label={t(INVITE_VALUE)}
            fullWidth
            helperText={`${t(VALUE_EXPRESSED_IN, {
              currency: dispatch(getCurrencyObject()),
            })}. ${t(INVITE_FORM_VALUE_CLARIFICATION)}`}
            variant="filled"
            InputProps={{
              inputComponent: CurrencyField,
            }}
            control={control}
            errors={errors}
            rules={{
              ...validate?.value,
            }}
          />
          <RecurrentFields
            errors={errors}
            setValue={setValue}
            date={watch("orderFrom")}
            recurrentPattern={watch("recurrentPattern")}
          />
        </>
      )}
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        {t(WITH_RECURRENT_PAYMENT_TITLE)}
      </Typography>
      <Select
        name="withRecurrentPayment"
        displayEmpty={true}
        variant="filled"
        options={[
          { value: false, label: t(NO) },
          { value: true, label: t(YES) },
        ]}
        fullWidth
        sx={{
          "& .MuioutlinedInput-root": {
            "& .MuiSelect-select": {
              pt: "16px !important",
              pb: "16px !important",
            },
          },
          mb: 2,
        }}
        control={control}
        errors={errors}
        rules={{
          required: {
            value: false,
            message: t(FIELD_REQUIRED),
          },
        }}
      />
      {withRecurrentPayment && (
        <>
          <TextField
            name="valueToPay"
            label={t(VALUE_TO_PAY_AMBASSADOR)}
            fullWidth
            variant="filled"
            InputProps={{
              inputComponent: CurrencyField,
            }}
            helperText={`${t(VALUE_EXPRESSED_IN, { currency: "USD" })}. ${t(
              VALUE_TO_PAY_AMBASSADOR_CLARIFICATION,
              {
                currency: "USD",
                value: valueToPay,
                valueWithFee: numberDecimal(
                  valueToPay + valueToPay * FEE_VALUE_DIRECT_PAYMENT
                ),
                fee: FEE_VALUE_DIRECT_PAYMENT * 100,
              }
            )}`}
            control={control}
            errors={errors}
            rules={{
              ...validate?.valueToPay,
            }}
          />
          <RecurrentFields
            errors={errors}
            setValue={setValue}
            date={watch("orderFrom")}
            text={RECURRENT_FIELDS_PAYMENT_TITLE}
            name="recurrentPatternPayment"
            recurrentPattern={watch("recurrentPatternPayment")}
          />
        </>
      )}
    </Box>
  );
};

export default AmbassadorForm;
