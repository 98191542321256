import React, { useEffect, useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import { startGetLastCollaborationInfluencerAndShop } from "../../../actions/influencers";
import Loader from "../Loader/Loader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../lib/i18next";
import { DATE_SHORT } from "../../../locales/keysTranslations";
import { openLink } from "../../../utils/urls";

const InfluencerLastCollaboration = ({
  userID,
  loaderSize = 40,
  variantText = "body1",
  label,
  sx = {},
  sxText = {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [lastCollaboration, setLastCollaboration] = useState(null);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const getInitialData = async () => {
    const lastCollaboration = await dispatch(
      startGetLastCollaborationInfluencerAndShop(userID)
    );
    setLastCollaboration(lastCollaboration);
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        ...sx,
      }}
    >
      {isLoading && <Loader size={loaderSize} />}
      {!isLoading && !lastCollaboration && null}
      {!isLoading && lastCollaboration && (
        <Typography
          variant={variantText}
          onClick={(e) => {
            e.stopPropagation();
            openLink(`/publication?orderID=${lastCollaboration?.orderID}`);
          }}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
            ...sxText,
          }}
        >
          {`${label ? `${t(label)}: ` : ""}${t(DATE_SHORT, {
            date: new Date(lastCollaboration.creationTime * -1),
          })}`}
        </Typography>
      )}
    </Box>
  );
};

export default InfluencerLastCollaboration;
