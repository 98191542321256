import React from "react";
import RadioGroupCards from "./RadioGroupCards";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { PAYMENT_METHODS } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const ModalStripePaymentMethods = ({
  modalOpen,
  onCloseModal,
  showMakeDefaultPaymentMethod,
}) => {
  const { t } = useTranslationApp();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(PAYMENT_METHODS)}
      title={t(PAYMENT_METHODS)}
    >
      <RadioGroupCards
        onCallbackMakeDefaultPaymentMethod={onCloseModal}
        showMakeDefaultPaymentMethod={showMakeDefaultPaymentMethod}
      />
    </ModalBasicLayout>
  );
};

export default ModalStripePaymentMethods;
