import React, { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import TextFieldMUI from "@mui/material/TextField";
import i18next from "i18next";
import {
  DATE_LONG_TEXT,
  FIELD_REQUIRED,
} from "../../../locales/keysTranslations";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../lib/i18next";

const DateField = React.forwardRef(
  (
    {
      control,
      rules = {
        required: {
          value: true,
          message: i18next.t(FIELD_REQUIRED),
        },
      },
      minDate,
      maxDate,
      errors = {},
      name = "",
      label = "",
      disabled = false,
      onActionsChange = () => {},
      onOpen = () => {},
      withoutController = false,
      value = new Date(),
      variant = "filled",
      mode = "input",
      sx = {},
      isDisabledClickText = false,
      onClickText = () => {},
      formatText = DATE_LONG_TEXT,
      propsText = {},
      className = "",
      fullWidth = true,
    },
    ref
  ) => {
    const [date, setDate] = useState(value);
    const refIcon = useRef(null);

    const { t } = useTranslationApp();

    useEffect(() => {
      setDate(value);
    }, [value]);

    const sxMode =
      mode === "text"
        ? {
            visibility: "hidden",
            position: "absolute",
          }
        : {};

    if (withoutController) {
      return (
        <>
          {mode === "text" && (
            <Typography
              variant="h6"
              {...propsText}
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
                ...(propsText?.sx || {}),
              }}
              onClick={() => {
                if (isDisabledClickText) return;
                const child = refIcon.current.children[1];
                if (child) {
                  child.click();
                  onClickText();
                }
              }}
            >
              {t(formatText, {
                date: date,
              })}
            </Typography>
          )}
          <DatePicker
            ref={ref}
            name={name}
            onOpen={onOpen}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => {
              onActionsChange(date);
              setDate(date);
            }}
            value={value}
            disabled={disabled}
            label={label}
            InputAdornmentProps={{
              position: "start",
              ref: refIcon,
            }}
            renderInput={(params) => (
              <TextFieldMUI
                {...params}
                fullWidth={fullWidth}
                variant={variant}
                error={!!errors[name]}
                sx={{
                  ...sx,
                  ...sxMode,
                }}
              />
            )}
          />
        </>
      );
    }
    return (
      <>
        {mode === "text" && (
          <Typography
            variant="h6"
            {...propsText}
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
              ...(propsText?.sx || {}),
            }}
            onClick={() => {
              if (isDisabledClickText) return;
              const child = refIcon.current.children[1];
              if (child) {
                child.click();
                onClickText();
              }
            }}
          >
            {t(formatText, {
              date: date,
            })}
          </Typography>
        )}
        <Controller
          name={name}
          defaultValue={value}
          control={control}
          rules={rules}
          render={({ field }) => (
            <DatePicker
              {...field}
              ref={ref}
              onOpen={onOpen}
              onChange={(date) => {
                field.onChange(date);
                setDate(date);
                onActionsChange();
              }}
              minDate={minDate}
              maxDate={maxDate}
              disabled={disabled}
              label={label}
              InputAdornmentProps={{
                position: "start",
                ref: refIcon,
              }}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  className={className}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  variant={variant}
                  error={!!errors[name]}
                  sx={{ ...sx, ...sxMode }}
                />
              )}
            />
          )}
        />
        {errors[name]?.message && (
          <Typography variant="caption" color="error">
            {errors[name]?.message}
          </Typography>
        )}
      </>
    );
  }
);

export default DateField;
