import React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import { REDEEM_CODE_TABLE } from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  DATE_SHORT,
  VALUE_WITH_CURRENCY,
  COLUMN_NAME_CODE_INVITATION,
  COLUMN_NAME_REDEEMED_DATE,
  COLUMN_NAME_VALUE_INVITATION,
  COLUMN_NAME_TERMS,
  VALUE_WITH_PERCENTAGE,
  EMPTY_REDEEM_CODES,
} from "../../../locales/keysTranslations";
import { useLocale } from "../../../Contexts/LocaleContext";

const RedeemCodeTable = ({
  data,
  loading,
  invites,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].date.getTime() - data[a].date.getTime()
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={loading}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        emptyText={EMPTY_REDEEM_CODES}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CODE_INVITATION)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_REDEEMED_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_VALUE_INVITATION)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_TERMS)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            const info = data[key];
            return (
              <TableRow key={key} data-tour={index === 0 && REDEEM_CODE_TABLE}>
                <TableCell align="center" indexRow={index}>
                  {info.from === "invite"
                    ? invites[info?.inviteID]?.inviteCode
                    : info?.code}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {t(DATE_SHORT, {
                    date: info.date,
                  })}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {t(
                    info.from === "invite"
                      ? VALUE_WITH_CURRENCY
                      : VALUE_WITH_PERCENTAGE,
                    {
                      value: info.value,
                      currency: LOCALE.currency,
                    }
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ maxWidth: 200 }}
                >
                  {info.from === "invite"
                    ? invites[info?.inviteID]?.terms
                    : "-"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default RedeemCodeTable;
