import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION } from "../../../../locales/keysTranslations";

const ContentQuality = () => {
  const { t } = useTranslationApp();
  return (
    <React.Fragment>
      <Typography
        color="inherit"
        variant="body1"
        sx={{ fontWeight: 300, mb: 1 }}
      >
        {t(MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION)}
      </Typography>
    </React.Fragment>
  );
};

export default ContentQuality;
