import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ALERT_ICON_TYPE_ERROR, FONT_FAMILY } from "./constants";
import { RED_COLOR, YELLOW_RATING } from "./colors";
import i18next from "i18next";
import {
  ACTIVATE,
  ALERT_INACTIVE_SHOP_DESCRIPTION,
  ALERT_INACTIVE_SHOP_TITLE,
  ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION,
  BRAND,
  BUTTON_GO_BACK,
  BUTTON_YES_I_SURE,
} from "../locales/keysTranslations";

const MySwal = withReactContent(Swal);
const minWidthButton = "150px";

export const AlertInactivePlan = () => {
  return MySwal.fire({
    title: i18next.t(ALERT_INACTIVE_SHOP_TITLE),
    text: i18next.t(ALERT_INACTIVE_SHOP_DESCRIPTION),
    icon: ALERT_ICON_TYPE_ERROR,
    confirmButtonColor: global.secondaryColor,
    confirmButtonText: `${i18next.t(ACTIVATE)} ${i18next.t(BRAND)}`,
    didOpen: () => {
      const title = MySwal.getTitle();
      title.style.padding = "0 1em 0";
      title.style.color = global.paperSecondaryContrastText;

      const htmlContainer = MySwal.getHtmlContainer();
      htmlContainer.style.color = global.paperSecondaryContrastText;

      const iconDom = MySwal.getIcon();
      iconDom.style.fontSize = "12px";
      iconDom.style.color = RED_COLOR;
      iconDom.style.borderColor = RED_COLOR;
      const popup = MySwal.getPopup();
      popup.style.borderRadius = "20px";
      popup.style.backgroundColor = global.paper;

      const button = MySwal.getConfirmButton();
      button.style.color = global.textSecondaryColor;
      button.style.minWidth = minWidthButton;
      button.style.fontFamily = FONT_FAMILY;
      const cancelButton = MySwal.getCancelButton();
      cancelButton.style.minWidth = minWidthButton;
      cancelButton.style.fontFamily = FONT_FAMILY;
      const container = MySwal.getContainer();
      container.style.zIndex = 1500;
    },
  });
};

export const AlertOperatorActionUnauthorized = () => {
  return MySwal.fire({
    title: "Error",
    text: i18next.t(ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION),
    icon: ALERT_ICON_TYPE_ERROR,
    confirmButtonText: "Ok",
    confirmButtonColor: global.secondaryColor,
    didOpen: () => {
      const title = MySwal.getTitle();
      title.style.padding = "0 1em 0";
      title.style.color = global.paperSecondaryContrastText;

      const htmlContainer = MySwal.getHtmlContainer();
      htmlContainer.style.color = global.paperSecondaryContrastText;

      const iconDom = MySwal.getIcon();
      iconDom.style.fontSize = "12px";
      iconDom.style.color = RED_COLOR;
      iconDom.style.borderColor = RED_COLOR;
      const popup = MySwal.getPopup();
      popup.style.borderRadius = "20px";
      popup.style.backgroundColor = global.paper;

      const button = MySwal.getConfirmButton();
      button.style.color = global.textSecondaryColor;
      button.style.minWidth = minWidthButton;
      button.style.fontFamily = FONT_FAMILY;
      const cancelButton = MySwal.getCancelButton();
      cancelButton.style.minWidth = minWidthButton;
      cancelButton.style.fontFamily = FONT_FAMILY;
      const container = MySwal.getContainer();
      container.style.zIndex = 1500;
    },
  });
};

export const ConfirmAlert = async ({
  title = "",
  text = "",
  additionalText = "",
  confirmButtonText = i18next.t(BUTTON_YES_I_SURE),
  cancelButtonText = i18next.t(BUTTON_GO_BACK),
  confirmButtonColor = global.secondaryColor,
  cancelButtonColor = global.primaryColor,
  showCancelButton = true,
  showThirdButton = false,
  thirdButtonText = "",
  thirdButtonColor = global.secondaryColor,
  icon = "warning",
}) => {
  return MySwal.fire({
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: cancelButtonColor,
    confirmButtonText,
    cancelButtonText,
    showDenyButton: showThirdButton,
    denyButtonText: thirdButtonText,
    denyButtonColor: thirdButtonColor,
    didOpen: () => {
      const title = MySwal.getTitle();
      title.style.padding = "0 1em 0";
      title.style.color = global.paperSecondaryContrastText;

      const htmlContainer = MySwal.getHtmlContainer();
      htmlContainer.style.color = global.paperSecondaryContrastText;

      const popup = MySwal.getPopup();
      popup.style.borderRadius = "20px";
      popup.style.backgroundColor = global.paper;

      const button = MySwal.getConfirmButton();
      button.style.color = global.textSecondaryColor;
      button.style.minWidth = minWidthButton;
      button.style.fontFamily = FONT_FAMILY;
      const cancelButton = MySwal.getCancelButton();
      cancelButton.style.minWidth = minWidthButton;
      cancelButton.style.fontFamily = FONT_FAMILY;
      const denyButton = MySwal.getDenyButton();
      denyButton.style.minWidth = minWidthButton;
      denyButton.style.fontFamily = FONT_FAMILY;
      denyButton.style.color = global.textSecondaryColor;

      if (showThirdButton) {
        button.style.fontSize = "0.9em";
        button.style.minWidth = "120px";
        cancelButton.style.fontSize = "0.9em";
        cancelButton.style.minWidth = "120px";
        denyButton.style.fontSize = "0.9em";
        denyButton.style.minWidth = "120px";
      }
      const container = MySwal.getContainer();
      container.style.zIndex = 1500;

      const actions = MySwal.getActions();
      actions.style.flexDirection = "row-reverse";
      const iconDom = MySwal.getIcon();
      iconDom.style.fontSize = "12px";

      if (icon === "warning") {
        iconDom.style.color = YELLOW_RATING;
        iconDom.style.borderColor = YELLOW_RATING;
      }
      if (icon === "error") {
        iconDom.style.color = RED_COLOR;
        iconDom.style.borderColor = RED_COLOR;
      }

      if (icon === "success") {
        iconDom.style.color = global.paperSecondaryContrastText;
        iconDom.style.borderColor = global.paperSecondaryContrastText;
        const children = iconDom.children;
        children[1].style.backgroundColor = global.paperSecondaryContrastText;
        children[2].style.backgroundColor = global.paperSecondaryContrastText;
        children[3].style.border = `0.25em solid ${global.paperSecondaryContrastText}`;
      }

      if (additionalText) {
        const htmlContainer = MySwal.getHtmlContainer();
        const br = document.createElement("br");
        htmlContainer.appendChild(br);
        const br2 = document.createElement("br");
        htmlContainer.appendChild(br2);

        const textnode = document.createTextNode(additionalText);
        htmlContainer.appendChild(textnode);
      }
    },
  });
};

export const SimpleAlert = ({
  title = "",
  text = "",
  additionalText = "",
  icon = "success",
  hasList = false,
  list = [],
  confirmButtonText = "Ok",
  confirmButtonTextColor = global.textSecondaryColor,
  confirmButtonColor = global.secondaryColor,
  html,
}) => {
  return MySwal.fire({
    title,
    text,
    icon,
    confirmButtonText,
    confirmButtonColor,
    html,
    didOpen: () => {
      const title = MySwal.getTitle();
      title.style.padding = "0 1em 0";
      title.style.color = global.paperSecondaryContrastText;

      const htmlContainer = MySwal.getHtmlContainer();
      htmlContainer.style.color = global.paperSecondaryContrastText;

      const iconDom = MySwal.getIcon();
      iconDom.style.fontSize = "12px";

      if (icon === "warning") {
        iconDom.style.color = YELLOW_RATING;
        iconDom.style.borderColor = YELLOW_RATING;
      }
      if (icon === "error") {
        iconDom.style.color = RED_COLOR;
        iconDom.style.borderColor = RED_COLOR;
      }
      if (icon === "success") {
        iconDom.style.color = global.paperContrastText;
        iconDom.style.borderColor = global.paperContrastText;
        const children = iconDom.children;
        children[1].style.backgroundColor = global.paperSecondaryContrastText;
        children[2].style.backgroundColor = global.paperSecondaryContrastText;
        children[3].style.border = `0.25em solid ${global.paperSecondaryContrastText}`;
      }

      const popup = MySwal.getPopup();
      popup.style.borderRadius = "20px";
      popup.style.backgroundColor = global.paper;

      const button = MySwal.getConfirmButton();
      button.style.color = confirmButtonTextColor;
      button.style.minWidth = minWidthButton;
      button.style.fontFamily = FONT_FAMILY;
      const cancelButton = MySwal.getCancelButton();
      cancelButton.style.minWidth = minWidthButton;
      cancelButton.style.fontFamily = FONT_FAMILY;
      const container = MySwal.getContainer();
      container.style.zIndex = 1500;
      if (hasList) {
        const htmlContainer = MySwal.getHtmlContainer();
        const br = document.createElement("br");
        htmlContainer.appendChild(br);
        const ul = document.createElement("ul");
        ul.style.listStyle = "none";
        ul.style.paddingLeft = "0px";
        list.map((item) => {
          const li = document.createElement("li");
          const textnode = document.createTextNode(item);
          li.appendChild(textnode);
          ul.appendChild(li);
          return null;
        });
        htmlContainer.appendChild(ul);
      }
      if (additionalText) {
        const htmlContainer = MySwal.getHtmlContainer();
        const br = document.createElement("br");
        htmlContainer.appendChild(br);
        const br2 = document.createElement("br");
        htmlContainer.appendChild(br2);

        const textnode = document.createTextNode(additionalText);
        htmlContainer.appendChild(textnode);
      }
    },
  });
};

export const TimerAlert = ({
  title = "",
  html = "",
  timer = 2000,
  timerProgressBar = true,
}) => {
  return MySwal.fire({
    title,
    html,
    timer,
    timerProgressBar,
    didOpen: () => {
      const title = MySwal.getTitle();
      title.style.padding = "0 1em 0";
      title.style.color = global.paperSecondaryContrastText;

      const htmlContainer = MySwal.getHtmlContainer();
      htmlContainer.style.color = global.paperSecondaryContrastText;

      const iconDom = MySwal.getIcon();
      iconDom.style.fontSize = "12px";
      const popup = MySwal.getPopup();
      popup.style.borderRadius = "20px";
      popup.style.backgroundColor = global.paper;

      MySwal.showLoading();
      const container = MySwal.getContainer();
      container.style.zIndex = 1500;
    },
  });
};
