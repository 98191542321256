import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import StatusButton from "../../Common/Buttons/StatusButton";
import { OWNER_ROLE } from "../../../utils/constants";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_CREATOR,
  COLUMN_NAME_DATE,
  COLUMN_NAME_ID,
  COLUMN_NAME_SHOP,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_VALUE,
  DATE_SHORT,
  EMPTY_RESULTS_SHOW,
  EMPTY_RESULTS_SHOW_ON_THIS_PAGE,
  VALUE_WITH_CURRENCY,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";
import LinkText from "../../Common/Texts/LinkText";

const ContentsTable = ({
  data,
  contentType,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].date.getTime() - data[a].date.getTime()
  );

  const { influencers } = useSelectorApp((state) => state.influencers);
  const { shops } = useSelectorApp((state) => state.shop);
  const { t } = useTranslationApp();
  const LOCALES = useLocales();

  const currentDataPage = keysSorted.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const currentFilteredData = currentDataPage.filter(
    (key) => data[key].postStatus === contentType
  );

  const getTextEmptyResults = () => {
    if (currentFilteredData.length === 0 && contentType !== null) {
      return EMPTY_RESULTS_SHOW_ON_THIS_PAGE;
    }
    return EMPTY_RESULTS_SHOW;
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={Object.keys(data)}
        additionalValidationEmpty={
          currentFilteredData.length === 0 && contentType
        }
        loading={false}
        emptyText={getTextEmptyResults()}
        additionalValidationNext={currentDataPage.length === 0}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SHOP)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_VALUE)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? currentDataPage : keysSorted).map(
            (key, index) => {
              if (data[key].postStatus !== contentType && contentType !== null)
                return null;

              const order = data[key];
              const shopID = order.shopID;
              const shop = shops[shopID];
              return (
                <TableRow key={index}>
                  <TableCell align="center" indexRow={index}>
                    {key}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <LinkText
                      variant="body2"
                      href={`shop?shopID=${shopID}`}
                      target="_blank"
                    >
                      {shop?.businessName ?? "-"}
                    </LinkText>
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <NameInfluencerCell
                      userID={order.userID}
                      data={influencers[order.userID]}
                      showButton={false}
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <StatusButton
                      status={order.postStatus}
                      orderID={key}
                      from={OWNER_ROLE}
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {t(DATE_SHORT, {
                      date: new Date(order.date),
                    })}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {shop?.signUpCountry &&
                      t(VALUE_WITH_CURRENCY, {
                        value: order.value,
                        currency: LOCALES[shop?.signUpCountry]?.currency,
                      })}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ContentsTable;
