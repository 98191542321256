import React from "react";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

const DescriptionPage = ({
  children,
  variantPrimary = "h6",
  variantSecondary = "body1",
  sx,
}) => {
  const isMobile = useMediaQuery("(min-width:600px)");

  return (
    <Typography
      variant={isMobile ? variantPrimary : variantSecondary}
      component="h6"
      sx={{ fontWeight: 300, mb: 2, ...sx }}
    >
      {children}
    </Typography>
  );
};

export default DescriptionPage;
