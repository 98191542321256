import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import i18next from "i18next";
import { FIELD_MIN_LENGTH, SEARCH } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = React.forwardRef((props, ref) => {
  const {
    placeholder = i18next.t(SEARCH),
    value = "",
    onEditText,
    isEditText = false,
    dataTour = "",
    disabled = false,
    minLength,
    sx = {},
    sxPaper = {},
    sxError = {},
    hasIcon = true,
  } = props;
  const [field, setField] = useState(value);

  const { t } = useTranslationApp();

  const onChange = (e) => {
    setField(e.target.value);
    onEditText(e.target.value);
  };

  useEffect(() => {
    if (isEditText) {
      setField(value);
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        my: 2,
        ...sx,
      }}
    >
      <Paper
        component="div"
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: 430,
          backgroundColor: "background.paperSecondary",
          borderRadius: 3,
          maxHeight: 42,
          px: 1,
          ...sxPaper,
        }}
        data-tour={dataTour}
      >
        {hasIcon && (
          <SearchIcon
            sx={{
              color: "fields.placeholder",
              mr: -0.5,
              height: 24,
              fontSize: "1.2rem",
            }}
          />
        )}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder }}
          value={field}
          onChange={onChange}
          disabled={disabled}
        />
      </Paper>
      {minLength && field.length > 0 && field.length < minLength && (
        <Typography variant="caption" sx={{ color: "red", ...sxError }}>
          {t(FIELD_MIN_LENGTH, {
            value: minLength,
          })}
        </Typography>
      )}
    </Box>
  );
});

export default SearchBar;
