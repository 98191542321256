import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { Link } from "react-router-dom";
import RedeemCodeTable from "../components/User/RedeemCode/RedeemCodeTable";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputFieldButton from "../components/Common/InputFieldButton/InputFieldButton";
import { startGetInviteByCode } from "../actions/invites";
import {
  startGetGeneralRedemptionsByStore,
  startGetOrdersByStore,
} from "../actions/orders";
import {
  ADMIN_ROLE,
  ALERT_ICON_TYPE_SUCCESS,
  DEFAULT_TABLE_PAGES,
  GENERAL_REDEMPTION,
  OPERATOR_ROLE,
  SUPER_ADMIN_ROLE,
} from "../utils/constants";
import { SimpleAlert } from "../utils/alerts";
import {
  startGetGeneralDiscountCode,
  startGetGeneralDiscountValue,
} from "../actions/shops";
import SelectStore from "../components/Common/Selects/SelectStore";
import Button from "../components/Common/Buttons/Button";
import { usePagination } from "../hooks/usePagination";
import { startGetEventsActiveNameByShop } from "../actions/events";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTour } from "@reactour/tour";
import { STEPS_MAIN_FUNCTIONALITY_OPERATOR } from "../onboarding/steps";
import { REDEEM_CODE_SEARCHBAR } from "../onboarding/stepsSelectors";
import { useTranslationApp } from "../lib/i18next";
import {
  BUTTON_ADD_STORE,
  BUTTON_REDEEM_CODE,
  CODE_REDEEMED,
  NO_STORES_CREATED,
  REDEEM_CODE_DESCRIPTION,
  REDEEM_CODE_SEARCHBAR_PLACEHOLDER,
  REDEEM_CODE_TITLE,
  STORE,
  VALUE_CODE_REDEEMED,
  VALUE_GENERAL_CODE_REDEEMED,
} from "../locales/keysTranslations";
import { useLocale } from "../Contexts/LocaleContext";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import Loader from "../components/Common/Loader/Loader";

const RedeemCode = () => {
  const [data, setData] = useState({});
  const [isLoadingCodes, setIsLoadingCodes] = useState(true);
  const [storeSelected, setStoreSelected] = useState("");
  const [loadingRedeem, setLoadingRedeem] = useState(false);

  const dispatch = useDispatchApp();

  const LOCALE = useLocale();

  const { t } = useTranslationApp();

  const { user } = useSelectorApp((state) => state.auth);
  const { invitesInactive } = useSelectorApp((state) => state.invites);
  const { orders, generalRedemptions } = useSelectorApp(
    (state) => state.orders
  );
  const { stores, isLoading: isLoadingStores } = useSelectorApp(
    (state) => state.shop
  );
  const storesKeys = Object.keys(stores || {});

  const { isOpen, setCurrentStep, currentStep, setIsOpen, setSteps } =
    useTour();

  const getNextData = async () => {
    dispatch(
      startGetOrdersByStore({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
        storeID: storeSelected,
      })
    );
    dispatch(
      startGetGeneralRedemptionsByStore({
        lastKey: lastKeyGeneralRedemptions,
        limit: rowsPerPage,
        onChangeLastKey: onChangeLastKeyGeneralRedemptions,
        storeID: storeSelected,
      })
    );
  };

  const getInitialData = async () => {
    setIsLoadingCodes(true);
    dispatch(startGetGeneralDiscountCode());
    dispatch(startGetGeneralDiscountValue());
    if (user.role !== OPERATOR_ROLE) {
      dispatch(startGetEventsActiveNameByShop());
    }
    if (storeSelected !== "") {
      dispatch(
        startGetOrdersByStore({
          limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
          onChangeLastKey: onChangeLastKey,
          storeID: storeSelected,
          onCallbackFinish: () => setIsLoadingCodes(false),
        })
      );
      dispatch(
        startGetGeneralRedemptionsByStore({
          limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
          onChangeLastKey: onChangeLastKeyGeneralRedemptions,
          storeID: storeSelected,
        })
      );
    }
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextData,
  });
  const {
    lastKey: lastKeyGeneralRedemptions,
    onChangeLastKey: onChangeLastKeyGeneralRedemptions,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
  });

  useEffect(() => {
    if (user.role === OPERATOR_ROLE) {
      setStoreSelected(user.storeID);
    }
    //eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    if (
      Object.keys(generalRedemptions).length === 0 &&
      Object.keys(orders).length === 0
    ) {
      setData({});
      return;
    }
    let filteredData = {};
    if (Object.keys(orders).length > 0) {
      Object.keys(orders).map((key) => {
        return (filteredData[key] = {
          ...orders[key],
          date: new Date(orders[key].date),
          from: "invite",
        });
      });
    }
    if (Object.keys(generalRedemptions).length > 0) {
      Object.keys(generalRedemptions).map((key) => {
        return (filteredData[key] = {
          ...generalRedemptions[key],
          date: new Date(generalRedemptions[key].creationTime),
          from: GENERAL_REDEMPTION,
        });
      });
    }

    setData(filteredData);
  }, [invitesInactive, orders, generalRedemptions]);

  useEffect(() => {
    if (!user.storeID) {
      setStoreSelected("");
    }
    getInitialData();
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [storeSelected]);

  const onSelectStore = (event) => {
    onPageChange(0);
    onChangeLastKey(null);
    setStoreSelected(event.target.value);
  };

  const onRedeemCode = async (code) => {
    setLoadingRedeem(true);
    const response = await dispatch(startGetInviteByCode(code, storeSelected));
    setLoadingRedeem(false);
    if (response.ok) {
      if (isOpen) {
        return setCurrentStep(currentStep + 1);
      }
      SimpleAlert({
        title: t(CODE_REDEEMED),
        text: t(
          response.from === "invite"
            ? VALUE_CODE_REDEEMED
            : VALUE_GENERAL_CODE_REDEEMED,
          {
            value: response.value,
            currency: LOCALE.currency,
          }
        ),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setLoadingRedeem(false);
    return response.ok;
  };

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <Box sx={{ width: "100%" }}>
          <TitlePage
            hasTooltip={user.role === OPERATOR_ROLE}
            toolTipAction={() => {
              setSteps(STEPS_MAIN_FUNCTIONALITY_OPERATOR);
              setIsOpen(true);
            }}
          >
            {t(REDEEM_CODE_TITLE)}
          </TitlePage>
          <DescriptionPage>{t(REDEEM_CODE_DESCRIPTION)} </DescriptionPage>

          {isLoadingStores && <Loader size={40} />}

          {(storesKeys.length > 0 || storeSelected.length > 0) && (
            <Box
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "center",
                },
                justifyContent:
                  storeSelected.length > 0 ? "space-between" : "flex-end",
                gap: {
                  xs: 0,
                  sm: 2,
                },
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
              }}
            >
              {storeSelected.length > 0 && (
                <Box>
                  <InputFieldButton
                    placeholder={t(REDEEM_CODE_SEARCHBAR_PLACEHOLDER)}
                    buttonText={t(BUTTON_REDEEM_CODE)}
                    onActionButton={(code) => onRedeemCode(code)}
                    loading={loadingRedeem}
                    dataTour={REDEEM_CODE_SEARCHBAR}
                  />
                </Box>
              )}
              {(user.role === ADMIN_ROLE || user.role === SUPER_ADMIN_ROLE) && (
                <SelectStore
                  storeSelected={storeSelected}
                  storesList={stores}
                  onChange={onSelectStore}
                  showEvents={true}
                  label={t(STORE)}
                />
              )}
            </Box>
          )}
        </Box>

        {storesKeys.length === 0 &&
          !isLoadingStores &&
          user.role !== OPERATOR_ROLE && (
            <>
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                  mt: 2,
                }}
              >
                {t(NO_STORES_CREATED)}
              </Typography>
              <Link to="/stores" style={{ textDecoration: "none" }}>
                <Button
                  sx={{
                    mt: 2,
                  }}
                >
                  {t(BUTTON_ADD_STORE)}
                </Button>
              </Link>
            </>
          )}

        {storeSelected && (
          <RedeemCodeTable
            data={data}
            loading={isLoadingCodes}
            invites={invitesInactive}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
          />
        )}

        {!storeSelected && <></>}
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default RedeemCode;
