import React from "react";
import { useDispatchApp } from "../../../../../lib/redux";
import { Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadFile from "../../../../Common/Buttons/UploadFile";
import RemoveFile from "../../../../Common/Buttons/RemoveFile";
import { getShopID } from "../../../../../actions/getters";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  ADVANCED_CONFIGURATION,
  CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER,
  CAMPAIGN_ATTACHMENT_LINK_TITLE,
  CAMPAIGN_BRIEF_DESCRIPTION,
  CAMPAIGN_BRIEF_PLACEHOLDER,
  CAMPAIGN_COLORS_CONTENT_PLACEHOLDER,
  CAMPAIGN_ENVIRONMENT_PLACEHOLDER,
  CAMPAIGN_GENDERS_PLACEHOLDER,
  CAMPAIGN_HASHTAG_PLACEHOLDER,
  CAMPAIGN_KEY_WORDS_PLACEHOLDER,
  CAMPAIGN_MESSAGE_TONE_PLACEHOLDER,
  CAMPAIGN_OUTFIT_PLACEHOLDER,
  CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER,
  CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER,
  CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER,
  CREATE_CAMPAIGN_REMOVED_BRIEF,
  CREATE_CAMPAIGN_UPLOAD_BRIEF,
  FEMALES,
  FIELD_URL_VALID,
  MALES,
} from "../../../../../locales/keysTranslations";
import { REGEX_URL } from "../../../../../utils/regexsValidation";
import TextField from "../../../../Common/Fields/TextField";
import SelectUGCContentFormat from "../../../../Common/Selects/SelectUGCContentFormat";
import LinkText from "../../../../Common/Texts/LinkText";

const AttachmentsCampaign = ({
  control,
  pathRTDB,
  watch,
  errors,
  isDisabledInput,
}) => {
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const shopID = dispatch(getShopID());

  const isUgcCampaign = watch("isUgcCampaign");

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ my: 1 }}>
          {t(ADVANCED_CONFIGURATION)}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="subtitle1">
          {t(CAMPAIGN_BRIEF_PLACEHOLDER)}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CAMPAIGN_BRIEF_DESCRIPTION)}
        </Typography>
        <Controller
          name="brief"
          control={control}
          render={({ field }) => (
            <>
              {!field.value ? (
                <UploadFile
                  disabled={isDisabledInput}
                  textButton={CREATE_CAMPAIGN_UPLOAD_BRIEF}
                  pathStorage={`campaigns/${shopID}/`}
                  automaticSetRTDB={true}
                  pathRTDB={`${pathRTDB}/brief`}
                  acceptFormats="application/pdf"
                  targetName="brief"
                  onReadyFile={field.onChange}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <LinkText
                    variant="subtitle1"
                    href={field.value}
                    target="_blank"
                    sx={{ fontWeight: 600 }}
                  >
                    Brief.pdf
                  </LinkText>
                  <RemoveFile
                    disabled={isDisabledInput}
                    textButton={CREATE_CAMPAIGN_REMOVED_BRIEF}
                    pathStorage={`campaigns/${shopID}/`}
                    pathRTDB={`${pathRTDB}/brief`}
                    targetName="brief"
                    urlFile={field.value}
                    automaticRemoveRTDB={false}
                    onRemoveFile={field.onChange}
                  />
                </Box>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="subtitle1">
          {t(CAMPAIGN_ATTACHMENT_LINK_TITLE)}
        </Typography>
        <Typography variant="body1">
          {t(CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION)}
        </Typography>
        <TextField
          name="attachmentLink"
          label={t(CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          sx={{ mt: 1 }}
          control={control}
          errors={errors}
          rules={{
            validate: (value) => {
              if (value && !REGEX_URL.test(value)) {
                return t(FIELD_URL_VALID);
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SelectUGCContentFormat
          name="ugcContentFormat"
          variant="filled"
          disabled={isDisabledInput}
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: "",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="body1">
          {t(CAMPAIGN_GENDERS_PLACEHOLDER)}
        </Typography>
        <FormControl
          component="fieldset"
          variant="filled"
          disabled={isDisabledInput}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="quantityMale"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                  />
                }
                label={t(MALES)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="quantityFemale"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                  />
                }
                label={t(FEMALES)}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      {!isUgcCampaign && (
        <>
          <Grid item xs={12} lg={6}>
            <TextField
              name="referenceProfiles"
              label={t(CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER)}
              fullWidth
              disabled={isDisabledInput}
              margin="normal"
              variant="filled"
              control={control}
              rules={{}}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              name="hashtag"
              label={t(CAMPAIGN_HASHTAG_PLACEHOLDER)}
              fullWidth
              disabled={isDisabledInput}
              margin="normal"
              variant="filled"
              control={control}
              rules={{}}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} lg={6}>
        <TextField
          name="messageTone"
          label={t(CAMPAIGN_MESSAGE_TONE_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="environmentContent"
          label={t(CAMPAIGN_ENVIRONMENT_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="colorsContent"
          label={t(CAMPAIGN_COLORS_CONTENT_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="prohibitedColorsContent"
          label={t(CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="keyWords"
          label={t(CAMPAIGN_KEY_WORDS_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="prohibitedWords"
          label={t(CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="outfit"
          label={t(CAMPAIGN_OUTFIT_PLACEHOLDER)}
          fullWidth
          disabled={isDisabledInput}
          margin="normal"
          variant="filled"
          control={control}
          rules={{}}
        />
      </Grid>
    </Grid>
  );
};

export default AttachmentsCampaign;
