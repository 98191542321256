import React from "react";
import Box from "@mui/material/Box";
import { GREY_DARK_COLOR } from "../../../utils/colors";
import SwitchStatusEvent from "./SwitchStatusEvent";
import { useTheme } from "@emotion/react";

const EventStatus = ({ isActive, onChangeStatusEvent }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: 1,
        minWidth: 140,
      }}
    >
      <SwitchStatusEvent
        color={isActive ? theme.palette.tertiary.main : GREY_DARK_COLOR}
        isActive={isActive}
        onChangeStatusEvent={onChangeStatusEvent}
      />
    </Box>
  );
};

export default EventStatus;
