//META
const pageViewMetaPixel = () => {
  window.fbq("track", "PageView");
};
const leadEventMetaPixel = () => {
  window.fbq("track", "Lead");
};
const purchaseEventMetaPixel = (value) => {
  window.fbq("track", "Purchase", {
    value: value,
    currency: "USD",
  });
};

//GOOGLE TAG MANAGER
const pageViewGoogleManagerEvent = () => {
  window.gtag("event", "page_view");
};
const leadGoogleTagManagerEvent = () => {
  window.gtag("event", "lead");
};
const purchaseGoogleManagerEvent = (value) => {
  window.gtag("event", "conversion", {
    value,
    currency: "USD",
    send_to: "AW-16704298856/nat-CfMuttKZEOi-np0-",
  });
};

//GOOGLE ANALYTICS
const pageViewGoogleAnalyticsEvent = () => {
  window.dataLayer.push({
    event: "pageView",
  });
};
const leadGoogleAnalyticsEvent = () => {
  window.dataLayer.push({
    event: "Lead",
  });
};
const purchaseGoogleAnalyticsEvent = (value) => {
  window.dataLayer.push({
    event: "Purchase",
    value,
    currency: "USD",
  });
};

export const trackingPageView = () => {
  if (process.env.REACT_APP_IS_STAGING === "false") {
    pageViewMetaPixel();
    pageViewGoogleAnalyticsEvent();
    pageViewGoogleManagerEvent();
  }
};

export const trackingLead = () => {
  if (process.env.REACT_APP_IS_STAGING === "false") {
    leadEventMetaPixel();
    leadGoogleTagManagerEvent();
    leadGoogleAnalyticsEvent();
  }
};

export const trackingPurchase = (value) => {
  if (process.env.REACT_APP_IS_STAGING === "false") {
    purchaseEventMetaPixel(value);
    purchaseGoogleManagerEvent(value);
    purchaseGoogleAnalyticsEvent(value);
  }
};
