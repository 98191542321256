import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {
  INFLUENCERS_FILTERS,
  PAGE_INFLUENCERS_SEARCHBAR,
} from "../../../onboarding/stepsSelectors";
import SearchBar from "../SearchBar/SearchBar";
import { NEW_CREATORS_CATEGORY } from "../../../utils/categories";
import { useTranslationApp } from "../../../lib/i18next";
import {
  FAVORITES_CATEGORY,
  SEARCH,
  TOP_RATED_CATEGORY,
  SEARCH_BY,
  SEARCH_USERNAME_PLACEHOLDER,
  SEARCH_TOPIC_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import { Controller } from "react-hook-form";
import InfluencerCategory from "../Influencer/InfluencerCategory";
import { BOX_SHADOW, WHITE_COLOR } from "../../../utils/colors";
import TuneIcon from "@mui/icons-material/Tune";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalFiltersInfluencers from "./ModalFiltersInfluencers";
import SelectSearchTypeInfluencers from "./SelectSearchTypeInfluencers";
import Typography from "@mui/material/Typography";
import {
  SEARCH_INFLUENCERS_TOPICS_OPTION,
  SEARCH_INFLUENCERS_USERNAME_OPTION,
} from "../../../utils/constants";
import SelectTopicsPhyllo from "./SelectTopicsPhyllo";
import SearchIcon from "@mui/icons-material/Search";

const FiltersInfluencers = ({
  filters,
  onChangeFilters,
  control,
  sx = {},
  sxSelectsContainer = {},
  sxSearchBar = {},
  disabledNewCategory = false,
  disabledFavoritesCategory = false,
  disabledTopRatedCategory = false,
  hiddenNewCategory = false,
  hiddenFavoritesCategory = false,
  hiddenTopRatedCategory = false,
  hiddenSearchBar = false,
  hiddenTypeSearch = false,
  onSetInitialStateFilters = () => {},
  onCallbackSearch = () => {},
}) => {
  const [modalFiltersOpen, setModalFiltersOpen] = useState(false);

  const { t } = useTranslationApp();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isHiddenNewCategory = hiddenNewCategory;
  const isHiddenFavoritesCategory = hiddenFavoritesCategory;
  const isHiddenTopRatedCategory = hiddenTopRatedCategory;

  const searchType = filters?.searchType;

  return (
    <Box
      data-tour={INFLUENCERS_FILTERS}
      sx={{
        backgroundColor: "background.paperSecondary",
        color: "background.paperSecondaryContrastText",
        width: "100%",
        mt: 1,
        mb: 3,
        px: {
          xs: 0,
          sm: 2,
        },
        py: {
          xs: 1,
          sm: 1.5,
        },
        borderRadius: 4,
        boxShadow: BOX_SHADOW,
        display: "inline-flex",
        flexDirection: {
          xs: hiddenSearchBar ? "row" : "column",
          sm: "row",
        },
        alignItems: "center",
        gap: 1,
        ...sx,
      }}
    >
      {!hiddenTypeSearch && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 0.5,
            width: 202,
          }}
        >
          <SearchIcon
            sx={{
              color: "fields.placeholder",
              mt: 0.25,
              height: 24,
              fontSize: "1.2rem",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {t(SEARCH_BY)}
          </Typography>
          <SelectSearchTypeInfluencers
            control={control}
            name={"searchType"}
            actionsChange={(value) => onChangeFilters("searchType", value)}
            useOnChange={false}
            sx={{
              mt: "3px",
              ".MuiInputBase-root": {
                ".MuiSelect-select": {
                  backgroundColor: "transparent",
                },
                borderBottom: "none !important",
                "::before": {
                  borderBottom: "none !important",
                },
                "::after": {
                  borderBottom: "none !important",
                },
                "&.Mui-focused": {
                  borderBottom: "none !important",
                },
              },
            }}
          />
        </Box>
      )}
      {((isMobile && !hiddenSearchBar) || !hiddenSearchBar) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            ml: searchType === SEARCH_INFLUENCERS_TOPICS_OPTION ? 0 : -1,
            gap: 1,
            px: {
              xs: 2,
              sm: 0,
            },
          }}
        >
          {!hiddenSearchBar &&
            searchType === SEARCH_INFLUENCERS_TOPICS_OPTION && (
              <SelectTopicsPhyllo
                control={control}
                name="topicsSelected"
                variant="standard"
                placeholder={t(SEARCH_TOPIC_PLACEHOLDER)}
                fullWidth={true}
                actionsChange={(value) => {
                  if (value?.length > 0 && filters.audienceLocations) {
                    onChangeFilters("audienceLocations", []);
                  }
                }}
                sx={{
                  mt: 0.25,
                  ".MuiFormControl-root": {
                    ".MuiInputBase-root": {
                      ".MuiSelect-select": {
                        backgroundColor: "transparent",
                      },
                      borderBottom: "none !important",
                      "::before": {
                        borderBottom: "none !important",
                      },
                      "::after": {
                        borderBottom: "none !important",
                      },
                      "&.Mui-focused": {
                        borderBottom: "none !important",
                      },
                    },
                  },
                }}
              />
            )}
          {!hiddenSearchBar &&
            searchType === SEARCH_INFLUENCERS_USERNAME_OPTION && (
              <Controller
                name="searchText"
                control={control}
                render={({ field }) => {
                  return (
                    <SearchBar
                      {...field}
                      placeholder={t(SEARCH_USERNAME_PLACEHOLDER)}
                      onEditText={(textQuery) => {
                        if (textQuery?.length >= 3)
                          onSetInitialStateFilters({
                            valuesExcept: ["searchText", "searchType"],
                          });
                        field.onChange(textQuery);
                      }}
                      hasButton={true}
                      buttonText={t(SEARCH)}
                      variantButton="icon"
                      isEditText={true}
                      hasIcon={false}
                      dataTour={PAGE_INFLUENCERS_SEARCHBAR}
                      backgroundColor={WHITE_COLOR}
                      sx={{
                        width: "100%",
                        alignSelf: "flex-start",
                        my: 0,
                        mt: 0.25,
                        position: "relative",
                      }}
                      sxError={{
                        position: "absolute",
                        bottom: -12,
                        left: 8,
                      }}
                      sxPaper={{
                        width: "100%",
                        maxWidth: "100%",
                        px: 0,
                      }}
                    />
                  );
                }}
              />
            )}
          {isMobile && (
            <IconButton
              onClick={() => setModalFiltersOpen(true)}
              sx={{
                display: "grid",
              }}
            >
              <TuneIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: { xs: hiddenSearchBar ? "auto" : "100%", sm: "auto" },
          justifyContent: "space-between",
          pl: {
            xs: hiddenSearchBar ? 2 : 0,
            sm: 0,
          },
        }}
      >
        {!isHiddenNewCategory && (
          <Controller
            control={control}
            name="newCategorySelected"
            render={({ field }) => (
              <InfluencerCategory
                sx={{
                  flex: 1,
                }}
                category={NEW_CREATORS_CATEGORY}
                disabled={disabledNewCategory}
                isSelected={field.value}
                onSelectCategory={(value) =>
                  onChangeFilters("newCategorySelected", value)
                }
              />
            )}
          />
        )}
        {!isHiddenFavoritesCategory && (
          <Controller
            control={control}
            name="favoritesCategorySelected"
            render={({ field }) => (
              <InfluencerCategory
                sx={{
                  flex: 1,
                }}
                category={FAVORITES_CATEGORY}
                disabled={disabledFavoritesCategory}
                isSelected={field.value}
                onSelectCategory={(value) =>
                  onChangeFilters("favoritesCategorySelected", value)
                }
              />
            )}
          />
        )}
        {!isHiddenTopRatedCategory && (
          <Controller
            control={control}
            name="topRatedCategorySelected"
            render={({ field }) => (
              <InfluencerCategory
                sx={{
                  flex: 1,
                }}
                category={TOP_RATED_CATEGORY}
                disabled={disabledTopRatedCategory}
                isSelected={field.value}
                onSelectCategory={(value) =>
                  onChangeFilters("topRatedCategorySelected", value)
                }
              />
            )}
          />
        )}
      </Box>
      {(!isMobile || hiddenSearchBar) && (
        <IconButton
          onClick={() => setModalFiltersOpen(true)}
          sx={{
            display: "grid",
          }}
        >
          <TuneIcon />
        </IconButton>
      )}
      {modalFiltersOpen && (
        <ModalFiltersInfluencers
          modalOpen={modalFiltersOpen}
          onCloseModal={() => setModalFiltersOpen(false)}
          control={control}
          filters={filters}
          onChangeFilters={onChangeFilters}
          hiddenNewCategory={hiddenNewCategory}
          onSetInitialStateFilters={onSetInitialStateFilters}
          onCallbackSearch={onCallbackSearch}
        />
      )}
    </Box>
  );
};

export default FiltersInfluencers;
