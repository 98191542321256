import React, { useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import { useLocale, useLocales } from "../../../Contexts/LocaleContext";
import {
  ANY,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION,
  EMPTY_RESULTS_SHOW,
  FIELD_TYPE_MIN_LENGTH,
  SELECT_A_OPTION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Controller, useController } from "react-hook-form";
import { capitalizeSections } from "../../../utils/formats";
import { Popper, styled, useMediaQuery, useTheme } from "@mui/material";
import { VariableSizeList } from "react-window";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  const { key, ...optionProps } = dataSet[0];
  const state = dataSet[2];
  const option = dataSet[1];
  const isMultiple = dataSet[3];

  if (isMultiple) {
    return (
      <li key={key} {...optionProps} style={inlineStyle}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={state.selected}
        />
        {option?.label}
      </li>
    );
  }

  return (
    <Typography
      key={key}
      component="li"
      noWrap
      style={inlineStyle}
      {...optionProps}
    >
      {option?.label}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const SelectCities = React.forwardRef(
  (
    {
      name,
      label = "",
      variant = "filled",
      country = "",
      displayEmpty = false,
      disabled = false,
      multiple = false,
      showAllOption = false,
      showAnyOption = false,
      fullWidth,
      actionsChange = () => {},
      sx = {},
      mode = "all",
      control,
      rules,
      additionalOptions = [],
      errors = {},
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");
    const limitTags = 3;
    const LOCALES = useLocales();
    const LOCALE = useLocale();

    const { t } = useTranslationApp();

    const {
      field: { value },
    } = useController({
      name,
      control,
    });

    const CITIES =
      (country ? LOCALES?.[country]?.cities : LOCALE?.cities) || [];

    const getCombinedOptions = () => {
      //verify if any additional options are in CITIES and filter

      const additionalOptionsFiltered = additionalOptions?.filter(
        (o) => !CITIES?.find((c) => c.value === o.value)
      );

      return [...CITIES, ...additionalOptionsFiltered];
    };

    const combinedOptions = getCombinedOptions();

    const getOptions = () => {
      if (mode === "query") {
        if (!inputValue || inputValue.length < 3) return [];

        return combinedOptions
          ?.filter((o) =>
            o.value.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((o) => o.value);
      } else {
        let currentOptions = combinedOptions?.map((o) => o.value);
        if (showAnyOption) {
          currentOptions = ["any", ...currentOptions];
        }
        if (showAllOption) {
          currentOptions = ["all", ...currentOptions];
        }

        return currentOptions;
      }
    };

    const options = getOptions();

    const getEmptyOptionsLabel = () => {
      if (inputValue.length <= 2 && options.length === 0)
        return t(FIELD_TYPE_MIN_LENGTH, {
          value: 3,
        });
      return t(EMPTY_RESULTS_SHOW);
    };

    const getObjectOption = (value) => {
      if (value === "all") {
        return {
          label: t(CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION),
          value: value,
        };
      }
      if (value === "any") {
        return {
          label: t(ANY),
          value: value,
        };
      }
      const optionFound = combinedOptions.find((o) => o.value === value);

      return (
        optionFound || {
          label: capitalizeSections(value),
          value: value,
        }
      );
    };

    if (multiple) {
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={disabled}
              limitTags={limitTags}
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => {
                      const optionObject = getObjectOption(option);
                      return (
                        <Chip
                          {...getTagProps({ index })}
                          key={index}
                          label={optionObject.label}
                        />
                      );
                    })}

                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              disableListWrap
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              fullWidth={fullWidth}
              noOptionsText={getEmptyOptionsLabel()}
              onChange={(_, newValue) => {
                let newValueState = [];
                if (newValue.includes("all")) {
                  newValueState =
                    value.length === options.length - 1
                      ? []
                      : options.filter((o) => o !== "all");
                }
                if (newValue.includes("any") && !newValue.includes("all")) {
                  newValueState = value?.includes("any")
                    ? newValue?.filter((v) => v !== "any")
                    : ["any"];
                }
                if (!newValue.includes("any") && !newValue.includes("all")) {
                  newValueState = newValue;
                }

                newValueState = newValueState.map((v) => {
                  if (v.value) return v.value;

                  return v;
                });

                actionsChange(newValueState);
                field.onChange(newValueState);
              }}
              multiple={true}
              sx={sx}
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => {
                const optionObject = getObjectOption(option);
                return optionObject?.label;
              }}
              renderOption={(props, option, state) => {
                let selected = false;

                if (value.includes(option)) {
                  selected = true;
                }
                if (value.includes("any")) {
                  selected = true;
                }
                if (option === "all") {
                  selected = value.length === options.length - 1;
                }

                const optionObject = getObjectOption(option);

                return [props, optionObject, { ...state, selected }, multiple];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant={variant}
                  error={!!errors[name]}
                  helperText={
                    errors[name] && (
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{errors[name]?.message}</span>
                      </Box>
                    )
                  }
                />
              )}
            />
          )}
        />
      );
    }
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disabled={disabled}
            fullWidth={fullWidth}
            noOptionsText={getEmptyOptionsLabel()}
            onChange={(_, newValue) => {
              actionsChange(newValue);
              field.onChange(newValue);
            }}
            getOptionLabel={(option) => {
              const optionObject = getObjectOption(option);
              return optionObject?.label;
            }}
            disableListWrap
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            renderOption={(props, option, state) => {
              const selected = value === option;
              const optionObject = getObjectOption(option);

              return [props, optionObject, { ...state, selected }, multiple];
            }}
            sx={sx}
            disablePortal
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  e.preventDefault();
                }}
                label={label}
                variant={variant}
                error={!!errors[name]}
                placeholder={displayEmpty ? SELECT_A_OPTION : ""}
                helperText={
                  errors[name] && (
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{errors[name]?.message}</span>
                    </Box>
                  )
                }
              />
            )}
          />
        )}
      />
    );
  }
);

export default SelectCities;
