import React from "react";
import Grid from "@mui/material/Grid";
import GeneralStatisticPoint from "../../Owner/Statistics/General/GeneralStatisticPoint";
import {
  numberCompactFormat,
  numberCurrencyFormat,
  numberRounded,
} from "../../../utils/numberFormats";

import Diversity3Icon from "@mui/icons-material/Diversity3";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { ENGAGEMENT_INTERACTIONS } from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ACCOUNTS_REACHED,
  COLLABORATIONS,
  COSTS_PER_ENGAGEMENT,
  TOTAL_INVESTMENT,
  COMMENTS,
  LIKES,
  SHARES,
  SAVES,
  REPLIES,
  STICKERS_TAPS,
} from "../../../locales/keysTranslations";

const ShopSummary = ({ data }) => {
  const { t } = useTranslationApp();
  if (!data) return null;
  const values = {
    [LIKES]: numberCurrencyFormat(data.totalLikes),
    [COMMENTS]: numberCurrencyFormat(data.totalComments),
    [SHARES]: numberCurrencyFormat(data.totalShares),
    [SAVES]: numberCurrencyFormat(data.totalSaves),
    [REPLIES]: numberCurrencyFormat(data.totalReplies),
    [STICKERS_TAPS]: numberCurrencyFormat(data.totalStickerTaps),
  };
  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: {
            xs: "center",
            sm: "flex-start",
          },
        }}
      >
        <GeneralStatisticPoint
          sx={{
            width: "100%",
            alignItems: {
              display: "flex",
              flexDirection: "column",
              xs: "center",
              sm: "flex-start",
            },
          }}
          Icon={Diversity3Icon}
          title={t(COLLABORATIONS)}
          value={`${numberCompactFormat(data.totalCollabs)}`}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: {
            xs: "center",
            sm: "flex-start",
          },
        }}
      >
        <GeneralStatisticPoint
          sx={{
            width: "100%",
            alignItems: {
              display: "flex",
              flexDirection: "column",
              xs: "center",
              sm: "flex-start",
            },
          }}
          Icon={AttachMoneyIcon}
          title={t(TOTAL_INVESTMENT)}
          value={`$${numberCurrencyFormat(data.totalInvestment)}`}
        />
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={6}lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: {
            xs: "center",
            sm:"flex-start"
          }
        }}
      >
        <GeneralStatisticPoint
        sx={{width:"100%"
        display:"flex",
        flexDirection:"column",, alignItems:{
          xs:"center",
          sm: "flex-start"
        }}}
          Icon={AttachMoneyIcon}
          title={t(TOTAL_EXPENSES)}
          value={`$${numberCurrencyFormat(data.totalCosts)}`}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: {
            xs: "center",
            sm:"flex-start"
          }
        }}
      >
        <GeneralStatisticPoint
        sx={{width:"100%"
        display:"flex",
        flexDirection:"column",, alignItems:{
          xs:"center",
          sm: "flex-start"
        }}}
          Icon={AttachMoneyIcon}
          title={t(COSTS_PER_1000_VIEWS)}
          value={`$${numberCurrencyFormat(
            numberRounded(data.costPerAccountsReached)
          )}`}
        />
      </Grid> */}
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: {
            xs: "center",
            sm: "flex-start",
          },
        }}
      >
        <GeneralStatisticPoint
          sx={{
            width: "100%",
            alignItems: {
              display: "flex",
              flexDirection: "column",
              xs: "center",
              sm: "flex-start",
            },
          }}
          Icon={AttachMoneyIcon}
          title={t(COSTS_PER_ENGAGEMENT)}
          value={`$${numberCurrencyFormat(
            numberRounded(data.costPerEngagement)
          )}`}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: {
            xs: "center",
            sm: "flex-start",
          },
        }}
      >
        <GeneralStatisticPoint
          sx={{
            width: "100%",
            alignItems: {
              display: "flex",
              flexDirection: "column",
              xs: "center",
              sm: "flex-start",
            },
          }}
          Icon={GroupIcon}
          title={t(ACCOUNTS_REACHED)}
          value={`${numberCurrencyFormat(data.totalAccountsReached)}`}
        />
      </Grid>
      {ENGAGEMENT_INTERACTIONS.map((interaction) => {
        if (interaction.label === ACCOUNTS_REACHED) return null;
        return (
          <Grid
            key={interaction.label}
            item
            xs={12}
            sm={6}
            lg={3}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            <GeneralStatisticPoint
              sx={{
                width: "100%",
                alignItems: {
                  display: "flex",
                  flexDirection: "column",
                  xs: "center",
                  sm: "flex-start",
                },
              }}
              Icon={interaction.icon}
              title={t(interaction.label)}
              value={values[interaction.label]}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ShopSummary;
