import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import {
  CREATOR_DESCRIPTION,
  CREATORS_TITLE,
  LOADING_INFORMATION,
  TAB_CREATORS,
  TAB_REQUESTS,
  TAB_SUGGESTED,
} from "../locales/keysTranslations";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTranslationApp } from "../lib/i18next";
import useTabs from "../hooks/useTabs";
import useCreatorsFilters from "../hooks/useCreatorsFilters";
import { useDispatchApp } from "../lib/redux";
import FiltersInfluencers from "../components/Common/Filters/FiltersInfluencers";
import SegmentedController from "../components/Common/Containers/SegmentedController";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { INFLUENCERS_TABS } from "../onboarding/stepsSelectors";
import {
  TAB_CREATORS_INDEX,
  TAB_REQUESTS_INDEX,
  TAB_SUGGESTED_INDEX,
} from "../utils/constants";
import Loader from "../components/Common/Loader/Loader";
import { startGetProfileInfluencerData } from "../actions/shops";
import InfluencersListTable from "../components/User/InfluencersListTable/InfluencersListTable";
import { startGetInvitesByShop } from "../actions/invites";
import RequestInfluencersTable from "../components/User/RequestInfluencers/RequestInfluencersTable";
import InfluencersSuggestedTable from "../components/User/InfluencersSuggested/InfluencersSuggestedTable";
import { isFilteringInfluencers } from "../services/shops";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        zIndex: 1,
        width: "100%",
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

const Creators2 = () => {
  const refAction = React.useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslationApp();
  const { filters, onChangeFilters, control, onReset } = useCreatorsFilters();
  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
    topicsSelected,
    audienceLocations,
  } = filters;
  const { tab, onChangeTab, tabs } = useTabs({
    initialValue: 1,
    tabs: [
      {
        label: TAB_CREATORS,
        icon: <PeopleAltIcon />,
        iconPosition: "top",
        show: true,
      },
      {
        label: TAB_SUGGESTED,
        icon: <PersonSearchIcon />,
        iconPosition: "top",
        show: true,
      },
      {
        label: TAB_REQUESTS,
        icon: <GroupAddIcon />,
        iconPosition: "top",
        show: true,
      },
    ],
  });

  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    dispatch(startGetInvitesByShop());
    await dispatch(startGetProfileInfluencerData());
    setIsLoading(false);
  };

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    categoriesSelected,
    citySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
    topicsSelected,
    audienceLocations,
  });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isFiltering && tab !== TAB_CREATORS_INDEX) {
      onChangeTab(TAB_CREATORS_INDEX);
    }

    // eslint-disable-next-line
  }, [
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    categoriesSelected,
    citySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
    topicsSelected,
    audienceLocations,
  ]);

  useEffect(() => {
    if (tab !== TAB_CREATORS_INDEX) {
      onReset({
        valuesExcept: ["searchType"],
      });
    }
    // eslint-disable-next-line
  }, [tab]);

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <TitlePage>{t(CREATORS_TITLE)}</TitlePage>
        <DescriptionPage>{t(CREATOR_DESCRIPTION)}</DescriptionPage>
        {isLoading && (
          <Loader
            size={70}
            hasMessage={true}
            hasMarginTop={true}
            variantMessage="body1"
            message={t(LOADING_INFORMATION)}
            fullWidth
          />
        )}
        {!isLoading && (
          <>
            <FiltersInfluencers
              control={control}
              filters={filters}
              onChangeFilters={onChangeFilters}
              onSetInitialStateFilters={onReset}
              onCallbackSearch={refAction?.current?.onGetData}
            />
            <SegmentedController
              initialValue={tab}
              value={tab}
              onChangeTab={onChangeTab}
              dataTour={INFLUENCERS_TABS}
              tabs={tabs}
            />
            {tab === TAB_CREATORS_INDEX && (
              <CustomTabPanel value={tab} index={TAB_CREATORS_INDEX}>
                <InfluencersListTable
                  refAction={refAction}
                  filters={filters}
                  onChangeFilters={onChangeFilters}
                />
              </CustomTabPanel>
            )}
            {tab === TAB_SUGGESTED_INDEX && (
              <CustomTabPanel value={tab} index={TAB_SUGGESTED_INDEX}>
                <InfluencersSuggestedTable
                  filters={filters}
                  onChangeFilters={onChangeFilters}
                  onChangeTab={onChangeTab}
                />
              </CustomTabPanel>
            )}
            {tab === TAB_REQUESTS_INDEX && (
              <CustomTabPanel value={tab} index={TAB_REQUESTS_INDEX}>
                <RequestInfluencersTable
                  filters={filters}
                  onChangeFilters={onChangeFilters}
                />
              </CustomTabPanel>
            )}
          </>
        )}
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default Creators2;
