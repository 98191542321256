import {
  DAYS_TO_MINUTES,
  HOURS_TO_MINUTES,
  WEEKS,
  WEEKS_TO_MINUTES,
} from "./constants";
import es from "date-fns/locale/es";
import format from "date-fns/format";
import { RRule } from "rrule";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import addWeeks from "date-fns/addWeeks";
import differenceInWeeks from "date-fns/differenceInWeeks";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import addYears from "date-fns/addYears";
import differenceInYears from "date-fns/differenceInYears";
import {
  DAY_LETTER_COUNT_DOWN,
  FRIDAY,
  HOUR_LETTER_COUNT_DOWN,
  MINUTE_LETTER_COUNT_DOWN,
  MONDAY,
  MONTH,
  MONTHS,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
  WEEK,
  WEEK_LETTER_COUNT_DOWN,
} from "../locales/keysTranslations";

export const dateDisplay = (date) => {
  if (date.years > 0) {
    return `${date.years} año${date.years > 1 ? "s" : ""}`;
  }
  if (date.months > 0) {
    return `${date.months} mes${date.months > 1 ? "es" : ""}`;
  }
  if (date.days > 0) {
    return `${date.days} día${date.days > 1 ? "s" : ""}`;
  }
  if (date.hours > 0) {
    return `${date.hours} hora${date.hours > 1 ? "s" : ""}`;
  }
  if (date.minutes > 0) {
    return `${date.minutes} minuto${date.minutes > 1 ? "s" : ""}`;
  }
  if (date.seconds > 0) {
    return `${date.seconds} segundo${date.seconds > 1 ? "s" : ""}`;
  }
  return `Hace 1 segundo`;
};

export const convertMinutes = (minutes) => {
  if (minutes >= HOURS_TO_MINUTES && minutes <= DAYS_TO_MINUTES)
    return {
      label: HOUR_LETTER_COUNT_DOWN,
      value: Math.round(minutes / HOURS_TO_MINUTES),
    };
  if (minutes >= DAYS_TO_MINUTES && minutes <= WEEKS_TO_MINUTES)
    return {
      label: DAY_LETTER_COUNT_DOWN,
      value: Math.round(minutes / DAYS_TO_MINUTES),
    };
  if (minutes >= WEEKS_TO_MINUTES)
    return {
      label: WEEK_LETTER_COUNT_DOWN,
      value: Math.round(minutes / WEEKS_TO_MINUTES),
    };
  return {
    label: MINUTE_LETTER_COUNT_DOWN,
    value: minutes,
  };
};

export const getTimeFromDateFirestore = (date) => {
  if (!date) return false;
  if (date.seconds) return date.seconds * 1000;
  return date._seconds * 1000;
};

export const getRecurrentOptions = (date) => {
  // Función para obtener el día de la semana en formato texto
  function getDayOfWeekText(date) {
    const dayOfWeek = format(date, "EEEE", { locale: es });
    return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  }

  // Función para obtener la opción de  recurrencia "Todos los días"
  function getDailyOption(date) {
    return `Todos los días a las ${format(date, "p")}`;
  }

  // Función para obtener la opción de recurrencia "cada semana"
  function getWeeklyOption(date) {
    const dayOfWeekText = getDayOfWeekText(date);
    return `Cada semana el ${dayOfWeekText}`;
  }

  // Función para obtener la opción de recurrencia "cada mes"
  function getMonthlyOption(date) {
    const dayOfWeekText = getDayOfWeekText(date);
    const weekOfMonth = format(date, "do");
    let weekOfMonthText = "el primer";
    if (weekOfMonth === "2nd") {
      weekOfMonthText = "el segundo";
    } else if (weekOfMonth === "3rd") {
      weekOfMonthText = "el tercer";
    } else if (weekOfMonth === "4th") {
      weekOfMonthText = "el cuarto";
    }
    return `Cada mes ${weekOfMonthText} ${dayOfWeekText}`;
  }

  // Función para obtener la opción de recurrencia "anualmente"
  function getAnnualOption(date) {
    const dayOfMonth = format(date, "do", { locale: es });
    const monthText = format(date, "MMMM", { locale: es });
    return `Anualmente el ${dayOfMonth} de ${monthText}`;
  }

  //Obtener todas las opciones de recurrencia para una fecha dada
  date = new Date(date);
  const dailyOption = getDailyOption(date);
  const weeklyOption = getWeeklyOption(date);
  const monthlyOption = getMonthlyOption(date);
  const annualOption = getAnnualOption(date);
  return [
    { label: dailyOption, value: "day" },
    { label: weeklyOption, value: "week" },
    { label: monthlyOption, value: "month" },
    { label: annualOption, value: "year" },
  ];
};

export const verifyRecurrentDate = ({
  dateStart,
  dateEnd,
  dateNow,
  typeRecurrent,
}) => {
  dateStart = new Date(dateStart);
  dateEnd = new Date(dateEnd);
  dateNow = new Date(dateNow);

  const functions = {
    day: {
      difference: differenceInDays,
      add: addDays,
    },
    week: {
      difference: differenceInWeeks,
      add: addWeeks,
    },
    month: {
      difference: differenceInMonths,
      add: addMonths,
    },
    year: {
      difference: differenceInYears,
      add: addYears,
    },
  };

  const actions = functions[typeRecurrent];

  const validateDatesRange = ({ startDate, endDate, prefix }) => {
    if (dateNow >= startDate && dateNow <= endDate) {
      return { ok: true, message: "" };
    }
    const startString = format(
      typeRecurrent !== "once"
        ? actions.add(new Date(startDate), 1)
        : new Date(startDate),
      "p dd/MM/yyyy"
    );
    const endString = format(
      typeRecurrent !== "once"
        ? actions.add(new Date(endDate), 1)
        : new Date(endDate),
      "p dd/MM/yyyy"
    );

    return {
      ok: false,
      message: `La fecha actual no está dentro del próximo periodo del evento ${prefix}: ${startString} - ${endString}`,
    };
  };

  switch (typeRecurrent) {
    case "once":
      return validateDatesRange({
        startDate: dateStart,
        endDate: dateEnd,
        prefix: "único",
      });
    case "day":
      let differenceDays = Math.abs(actions.difference(dateNow, dateStart));
      const currentDateStartAddDays = actions.add(dateStart, differenceDays);
      const currentDateEndAddDays = actions.add(dateEnd, differenceDays);

      return validateDatesRange({
        startDate: currentDateStartAddDays,
        endDate: currentDateEndAddDays,
        prefix: "diario",
      });
    case "week":
      let differenceWeeks = Math.abs(actions.difference(dateNow, dateStart));
      const currentDateStartAddWeeks = actions.add(dateStart, differenceWeeks);
      const currentDateEndAddWeeks = actions.add(dateEnd, differenceWeeks);
      return validateDatesRange({
        startDate: currentDateStartAddWeeks,
        endDate: currentDateEndAddWeeks,
        prefix: "semanal",
      });
    case "month":
      let differenceMonths = Math.abs(actions.difference(dateNow, dateStart));
      const currentDateStartAddMonths = actions.add(
        dateStart,
        differenceMonths
      );
      const currentDateEndAddMonths = actions.add(dateEnd, differenceMonths);
      return validateDatesRange({
        startDate: currentDateStartAddMonths,
        endDate: currentDateEndAddMonths,
        prefix: "mensual",
      });
    case "year":
      let differenceYears = Math.abs(actions.difference(dateNow, dateStart));
      const currentDateStartAddYears = actions.add(dateStart, differenceYears);
      const currentDateEndAddYears = actions.add(dateEnd, differenceYears);
      return validateDatesRange({
        startDate: currentDateStartAddYears,
        endDate: currentDateEndAddYears,
        prefix: "anual",
      });

    default:
      return { ok: false, message: `No se envió un periodo correcto.` };
  }
};

export const getRecurrentOption = (date, optionRequired) => {
  const options = getRecurrentOptions(date);
  return options.find((option) => option.value === optionRequired);
};

export const getDaysOptionsRRule = () => [
  {
    label: MONDAY,
    valueNumeric: 1,
    value: RRule.MO,
  },
  {
    label: TUESDAY,
    valueNumeric: 2,
    value: RRule.TU,
  },
  {
    label: WEDNESDAY,
    valueNumeric: 3,
    value: RRule.WE,
  },
  {
    label: THURSDAY,
    valueNumeric: 4,
    value: RRule.TH,
  },
  {
    label: FRIDAY,
    valueNumeric: 5,
    value: RRule.FR,
  },
  {
    label: SATURDAY,
    valueNumeric: 6,
    value: RRule.SA,
  },
  {
    label: SUNDAY,
    valueNumeric: 7,
    value: RRule.SU,
  },
];

export const getRecurrentOptionsRRule = () => [
  {
    label: WEEK,
    labelPlural: WEEKS,
    value: RRule.WEEKLY,
  },
  {
    label: MONTH,
    labelPlural: MONTHS,
    value: RRule.MONTHLY,
  },
];

export const waitDelay = (milliseconds) => {
  return new Promise((ok) => setTimeout(ok, milliseconds));
};

export const validateIsValidDate = (date) => {
  if (!date) return null;

  if (Object.prototype.toString.call(date) !== "[object Date]") {
    date = new Date(date);
  }

  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getCreationTimeFromID = (id) => {
  let PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
  id = id.substring(0, 8);
  let timestamp = 0;
  for (let i = 0; i < id.length; i++) {
    let c = id.charAt(i);
    timestamp = timestamp * 64 + PUSH_CHARS.indexOf(c);
  }
  return timestamp;
};
