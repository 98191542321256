import React from "react";
import ButtonMUI from "@mui/material/Button";
import { BORDER_RADIUS } from "../../../utils/constants";

const Button = ({
  id,
  children,
  variant = "contained",
  component = "button",
  color = "primary",
  fullWidth = false,
  disableElevation = true,
  disabled = false,
  size = "medium",
  ariaLabel = "",
  type = "",
  startIcon = "",
  endIcon = "",
  sx = {},
  onClick,
  dataTour = "",
  refButton,
  hasShadow = true,
}) => {
  return (
    <ButtonMUI
      id={id}
      ref={refButton}
      data-tour={dataTour}
      variant={variant}
      disabled={disabled}
      color={color}
      component={component}
      fullWidth={fullWidth}
      disableElevation={disableElevation}
      aria-label={ariaLabel}
      size={size}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        borderRadius: BORDER_RADIUS,
        fontWeight: 600,
        maxHeight: 40,
        lineHeight: "normal",
        minWidth: "max-content",
        py: "10px",
        whiteSpace: "nowrap",
        ...sx,
        textTransform: "capitalize",
      }}
      onClick={onClick}
    >
      {children}
    </ButtonMUI>
  );
};

export default Button;
