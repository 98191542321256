import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DEFAULT_TABLE_PAGES } from "../../../../utils/constants";
import { usePagination } from "../../../../hooks/usePagination";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import {
  startGetShopsAccepted,
  startGetShopsApplicantAcceptedByName,
} from "../../../../actions/shops";
import Loader from "../../../Common/Loader/Loader";
import ShopsAcceptedTable from "./ShopsAcceptedTable";
import ModalShopAcceptedInfo from "./ModalShopAcceptedInfo";
import SearchBar from "../../../Common/SearchBar/SearchBar";
import { SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER } from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const ShopsAccepted = ({ filter }) => {
  const [shopsApplicants, setShopsApplicants] = useState({});
  const [shopSelected, setShopSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const limit = DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false;

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const { registerShopsAccepted } = useSelectorApp((state) => state.shop);

  const onGetNextPageShopsAccepted = () => {
    dispatch(
      startGetShopsAccepted({ limit: rowsPerPage, onChangeLastKey, lastKey })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: onGetNextPageShopsAccepted,
  });

  useEffect(() => {
    dispatch(
      startGetShopsAccepted({
        limit: limit,
        onChangeLastKey,
      })
    );

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShopsApplicants(registerShopsAccepted);
    setIsLoading(false);
  }, [registerShopsAccepted]);

  const onChangeShopSelected = (shopID) => {
    setShopSelected({
      shopID,
      ...shopsApplicants[shopID],
    });
  };

  const onSearchShop = async (text) => {
    setSearchText(text);
    if (text.length > 0) {
      await dispatch(startGetShopsApplicantAcceptedByName(text));
    }
  };

  const onFilterShopsApplicantsAccepted = () => {
    const filtered = {};

    Object.keys(shopsApplicants).forEach((key) => {
      if (
        searchText.length > 0 &&
        !shopsApplicants[key].businessName
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ) {
        return;
      }

      filtered[key] = shopsApplicants[key];
    });

    return filtered;
  };

  const shopsApplicantAcceptedFiltered = onFilterShopsApplicantsAccepted();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {isLoading ? (
        <Loader size={70} fullWidth={true} />
      ) : (
        <>
          <Box
            sx={{
              mb: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
              minWidth: 200,
            }}
          >
            <SearchBar
              placeholder={t(SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER)}
              hasButton={false}
              onEditText={(text) => onSearchShop(text)}
              sx={{ mt: 0 }}
            />
          </Box>
          <ShopsAcceptedTable
            data={shopsApplicantAcceptedFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            filter={filter}
            onChangeShopSelected={onChangeShopSelected}
          />
        </>
      )}
      {shopSelected && (
        <ModalShopAcceptedInfo
          modalOpen={shopSelected !== null}
          onCloseModal={() => setShopSelected(null)}
          data={shopSelected}
        />
      )}
    </Box>
  );
};

export default ShopsAccepted;
