import React from "react";
import ContainerPaperSection from "./ContainerPaperSection";
import TiktokSection from "./PlatformsConnect/TiktokSection";
import { PLATFORMS_CONNECTED_TITLE } from "../../../locales/keysTranslations";
import FacebookSection from "./PlatformsConnect/FacebookSection";
import ShopifySection from "./PlatformsConnect/ShopifySection";
import WooCommerceSection from "./PlatformsConnect/WooCommerceSection";

const PlatformsConnectSection = () => {
  return (
    <ContainerPaperSection
      title={PLATFORMS_CONNECTED_TITLE}
      showTopButton={false}
      showBottomButton={false}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="platforms-connect-section"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TiktokSection />
      <FacebookSection />
      <ShopifySection />
      <WooCommerceSection />
    </ContainerPaperSection>
  );
};

export default PlatformsConnectSection;
