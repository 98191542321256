import React from "react";
import RatingMUI from "@mui/material/Rating";
import { YELLOW_RATING } from "../../../../utils/colors";
import Typography from "@mui/material/Typography";
import { NEW } from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const Rating = React.forwardRef((props, ref) => {
  const {
    value = 0,
    readOnly,
    precision,
    variantText = "body1",
    size = "medium",
    onChange = () => {},
    name = "",
    sx = {},
  } = props;

  const { t } = useTranslationApp();

  if (Number(value) < precision && readOnly) {
    return (
      <Typography
        variant={variantText}
        sx={{
          fontWeight: "600",
          color: "background.paperContrastText",
        }}
      >
        {t(NEW)}
      </Typography>
    );
  }
  return (
    <RatingMUI
      value={value}
      readOnly={readOnly}
      precision={precision}
      size={size}
      onChange={onChange}
      name={name}
      sx={{
        color: YELLOW_RATING,
        ...sx,
      }}
      ref={ref}
    />
  );
});

export default Rating;
