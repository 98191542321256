import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatisticsContainer from "../../../Common/Statistics/StatisticContainer";
import BarChart from "../../../Common/Statistics/BarChart";
import GroupIcon from "@mui/icons-material/Group";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import TextWithIcon from "../../../Common/Texts/TextWithIcon";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import StoreIcon from "@mui/icons-material/Store";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  DELIVERIES,
  IN_STORE,
  ORDERS,
  REPORTS_NUMBER_ORDER,
  REPORTS_NUMBER_USERS,
  REPORTS_TYPE_ORDER,
  REPORTS_TYPE_USERS,
  ROLE,
  STATUS,
  USERS,
} from "../../../../locales/keysTranslations";

const GeneralStatisticsGraphs = ({ data }) => {
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);

  const { t } = useTranslationApp();

  useEffect(() => {
    const users = data.users;
    const usersData = [];
    Object.keys(users).map((userRole) => {
      if (userRole !== "totalUsers") {
        return usersData.push({
          [t(ROLE)]: userRole,
          [t(USERS)]: users[userRole],
        });
      }
      return null;
    });
    setUsers(usersData);

    const orders = data.orders;
    const ordersData = [];
    Object.keys(orders).map((orderType) => {
      if (
        orderType !== "totalOrders" &&
        orderType !== "deliveryOrders" &&
        orderType !== "storeOrders"
      ) {
        return ordersData.push({
          [t(STATUS)]: orderType,
          [t(ORDERS)]: orders[orderType],
        });
      }
      return null;
    });
    setOrders(ordersData);
    //eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        mt: 2,
        height: "100%",
        maxWidth: { xs: "100%", md: "calc(100vw - 320px)" },
      }}
    >
      <Grid container sx={{ height: "100%" }} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              border: "2px rgba(0, 0, 0, 0.12) solid",
              p: 1,
              borderRadius: 4,
            }}
          >
            <Typography variant="h6">{t(USERS)}</Typography>
            <TextWithIcon
              Icon={GroupIcon}
              text={`${data.users.totalUsers} ${t(USERS)}`}
              sx={{ mb: 3 }}
            />
            <StatisticsContainer sx={{ height: 400, pr: 2, pb: 2 }}>
              <BarChart
                data={users}
                nameX={t(REPORTS_TYPE_USERS)}
                dataKeyX={t(ROLE)}
                nameY={t(REPORTS_NUMBER_USERS)}
                dataKeyY={t(USERS)}
              />
            </StatisticsContainer>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              border: "2px rgba(0, 0, 0, 0.12) solid",
              p: 1,
              borderRadius: 4,
            }}
          >
            <Typography variant="h6">{t(ORDERS)}</Typography>
            <TextWithIcon
              Icon={RequestQuoteIcon}
              text={`${data.orders.totalOrders} ${t(ORDERS)}`}
            />
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextWithIcon
                Icon={DeliveryDiningIcon}
                variantText="body1"
                text={`${data.orders.deliveryOrders} ${t(DELIVERIES)}`}
              />
              <TextWithIcon
                Icon={StoreIcon}
                variantText="body1"
                text={`${data.orders.storeOrders} ${t(IN_STORE)}`}
              />
            </Box>
            <StatisticsContainer sx={{ height: 400, pr: 2, pb: 2 }}>
              <BarChart
                data={orders}
                nameX={t(REPORTS_TYPE_ORDER)}
                dataKeyX={t(STATUS)}
                nameY={t(REPORTS_NUMBER_ORDER)}
                dataKeyY={t(ORDERS)}
              />
            </StatisticsContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralStatisticsGraphs;
