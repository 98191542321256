import React, { useContext, useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CurrencyField from "../../../Common/NumberFormats/CurrencyField";
import AccompanimentsType from "./AccompanimentsType";
import Button from "../../../Common/Buttons/Button";
import EditIcon from "@mui/icons-material/Edit";
import ImageEditor from "../../../Common/Images/ImageEditor";
import { saveUrl, startUpdateFileAws } from "../../../../actions/shops";
import { uuidv4 } from "@firebase/util";
import { getUUIDFromURL } from "../../../../utils/urls";
import {
  MIN_ALL_NUMBER_VALUE,
  OPERATOR_ROLE,
} from "../../../../utils/constants";
import { MenuContext } from "../../../../Contexts/MenuContext";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_EDIT,
  BUTTON_REPLACE_IMAGE,
  BUTTON_SAVE_PRODUCT,
  FIELD_ALL_REQUIRED,
  MENU_PRODUCT_DESCRIPTION_PLACEHOLDER,
  MENU_PRODUCT_MIN_VALUE,
  MENU_PRODUCT_NAME_PLACEHOLDER,
  MENU_PRODUCT_PRICE_PLACEHOLDER,
  MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION,
  MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import UploadImageButtons from "../../../Common/Buttons/UploadImageButtons";

const INITIAL_STATE = {
  name: "",
  description: "",
  price: "",
  photoURL: "",
  isProductSensitive: false,
  options: [],
  isActive: true,
};
const ProductDetail = ({ data, pathRTDB, userRole }) => {
  const {
    // PRODUCTS
    onSaveProduct,
  } = useContext(MenuContext);
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [mode, setMode] = useState("view");
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormValues({
      name: data?.name,
      description: data?.description,
      price: data?.price,
      photoURL: data?.photoURL,
      isProductSensitive: data?.isProductSensitive ?? false,
      options: data?.options || [],
      isActive: data?.isActive,
    });
    setMode("view");
    setError(null);
  }, [data, data?.options]);

  const { user } = useSelectorApp((state) => state.auth);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onEditImage = async (imgResult, mimeType) => {
    if (formValues.photoURL.includes("amazonaws")) {
      const UUID = getUUIDFromURL(formValues.photoURL);
      await dispatch(
        startUpdateFileAws(
          imgResult,
          mimeType,
          `shops/${user.shopID}/images/${UUID}`
        )
      );
    } else {
      const url = await dispatch(
        startUpdateFileAws(
          imgResult,
          mimeType,
          `shops/${user.shopID}/images/${uuidv4()}`
        )
      );
      await dispatch(saveUrl(url, `${pathRTDB}/photoURL`));
      setFormValues({
        ...formValues,
        photoURL: url,
      });
    }

    setMode("view");
  };

  const onChange = (event) => {
    if (event.target.name === "isProductSensitive")
      return setFormValues({
        ...formValues,
        [event.target.name]: event.target.checked,
      });
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeImage = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      !formValues.name ||
      !formValues.description ||
      !formValues.photoURL ||
      !formValues.price
    )
      return setError(t(FIELD_ALL_REQUIRED));

    if (parseInt(formValues.price) < MIN_ALL_NUMBER_VALUE)
      return setError(
        t(MENU_PRODUCT_MIN_VALUE, {
          value: MIN_ALL_NUMBER_VALUE,
        })
      );
    setError(null);
    const response = await onSaveProduct(formValues);
    return response && setFormValues(INITIAL_STATE);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mt: 1,
        pl: { xs: 0, md: 3 },
        pr: { xs: 1, md: 2 },
        pb: 1,
        overflow: "auto",
      }}
    >
      <Box sx={{ mt: 1, width: "100%" }}>
        {error && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Alert
              severity="error"
              sx={{ width: "100%", maxWidth: 350, justifyContent: "center" }}
            >
              {error}
            </Alert>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <UploadImageButtons
            urlImage={formValues.photoURL}
            targetName="photoURL"
            pathStorage={`shops/${user.shopID}/images/`}
            pathRTDB={`${pathRTDB}/photoURL`}
            onReadyUrlImage={onChangeImage}
            onRemoveUrlImage={onChangeImage}
            textButtonRemove={BUTTON_REPLACE_IMAGE}
            automaticSetRTDB={true}
            deleteFile={false}
            deleteRTDB={false}
            disabled={userRole === OPERATOR_ROLE}
            showImage={mode !== "edit"}
            mode="replace"
            showSecondButton={formValues.photoURL}
            textButtonSecond={mode === "view" ? BUTTON_EDIT : BUTTON_CANCEL}
            iconSecondButton={<EditIcon />}
            colorSecond="secondary"
            variantSecond="contained"
            disabledSecondButton={userRole === OPERATOR_ROLE}
            onClickSecond={() => setMode(mode === "view" ? "edit" : "view")}
            centeredSecondButton={true}
            sxPreview={{
              width: "auto",
              minWidth: 0,
              height: "auto",
              maxHeight: 200,
            }}
          />
          {mode === "edit" && (
            <ImageEditor onSave={onEditImage} img={formValues.photoURL} />
          )}
        </Box>
        <TextField
          id="name"
          name="name"
          label={t(MENU_PRODUCT_NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          margin="normal"
          value={formValues.name}
          onChange={onChange}
          disabled={userRole === OPERATOR_ROLE}
        />
        <TextField
          id="description"
          name="description"
          label={t(MENU_PRODUCT_DESCRIPTION_PLACEHOLDER)}
          multiline
          rows={3}
          variant="filled"
          fullWidth
          margin="normal"
          value={formValues.description}
          onChange={onChange}
          disabled={userRole === OPERATOR_ROLE}
        />
        <TextField
          id="price"
          name="price"
          label={t(MENU_PRODUCT_PRICE_PLACEHOLDER)}
          variant="filled"
          fullWidth
          margin="normal"
          value={formValues.price}
          onChange={onChange}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          disabled={userRole === OPERATOR_ROLE}
        />
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend" sx={{ fontWeight: 600 }}>
            {t(MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER)}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.isProductSensitive}
                  name="isProductSensitive"
                  onChange={onChange}
                />
              }
              label={t(MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION)}
            />
          </FormGroup>
        </FormControl>
        <AccompanimentsType data={formValues.options} userRole={userRole} />
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={userRole === OPERATOR_ROLE}
          >
            {t(BUTTON_SAVE_PRODUCT)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductDetail;
