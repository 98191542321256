import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { startGetEventByID } from "../actions/events";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "../components/Common/Loader/Loader";
import EventFormContainer from "../components/User/Events/EventDetail/EventFormContainer";

const EventDetail = () => {
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const eventID = params.get("eventID");

  const { user } = useSelector((state) => state.auth);

  const getEventData = async () => {
    if (!eventID) {
      return navigate("/events");
    }
    let eventData = await dispatch(startGetEventByID(eventID));
    if (!eventData) {
      SimpleAlert({
        title: "No puedes acceder a esta información",
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return navigate("/campaigns");
    }
    setEvent({ eventID, ...eventData });
    setIsLoading(false);
  };

  useEffect(() => {
    getEventData();
    // eslint-disable-next-line
  }, [eventID, user.shopID]);

  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {isLoading ? (
        <Loader
          size={70}
          hasMessage={true}
          message="Cargando información"
          variantMessage="h5"
          fullWidth={true}
        />
      ) : (
        <React.Fragment>
          <Typography
            variant={isMobile ? "h3" : "h4"}
            component="h1"
            sx={{ fontWeight: 600, mb: 2 }}
          >
            {`Evento ${event.name}`}
          </Typography>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <EventFormContainer
              data={event}
              isMobile={isMobile}
              eventID={eventID}
            />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default EventDetail;
