import React from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import NumberField from "../../Common/NumberFormats/NumberField";
import StaffListDropdown from "../../Common/Staff/StaffListDropdown";
import { getNameBusinessTheme } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";

const ShopInformationForm = ({ control }) => {
  const dispatch = useDispatchApp();

  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <Controller
        name="businessName"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            label="Nombre de la marca"
            variant="filled"
            {...field}
          />
        )}
      />
      <Controller
        name="shortDescription"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            label="Descripción de la marca"
            variant="filled"
            {...field}
          />
        )}
      />
      <Controller
        name="clappCredits"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            label={`${NAME_BUSINESS} Credits`}
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
            {...field}
          />
        )}
      />
      <Controller
        name="costCollaboration"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            label="Costo por Colaboración"
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
            {...field}
          />
        )}
      />

      <Controller
        name="staffID"
        control={control}
        render={({ field }) => (
          <StaffListDropdown
            value={field.value}
            doAssign={false}
            fullWidth={true}
            variant="filled"
            onChange={(value) => field.onChange(value)}
          />
        )}
      />

      <Controller
        name="sortingIndex"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            label="Puesto"
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
            {...field}
          />
        )}
      />

      {/* <Controller
        name="hasBillingCycle"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
               
                variant="filled"
                {...field}
                checked={field.value}
                sx={{
                  ".MuiSwitch-track": {
                    backgroundColor: `${
                      field.value ? "#00b900" : "#757575"
                    } !important`,
                  },
                  ".MuiSwitch-thumb": {
                    backgroundColor: `${field.value ? "#FFF" : "#757575"}`,
                  },
                }}
              />
            }
            labelPlacement="end"
            label={`${field.value ? "No" : "Sí"} tiene ciclo de facturación`}
          />
        )}
      /> */}
    </Box>
  );
};

export default ShopInformationForm;
