import React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import RequestInfluencerStatus from "./RequestInfluencerStatus";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import RequestInfluencerAccountsActions from "./RequestInfluencerAccountsActions";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import { useSelectorApp } from "../../../lib/redux";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_ACTION,
  COLUMN_NAME_CODE_VERIFICATION,
  COLUMN_NAME_COUNTRY,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_STATUS,
} from "../../../locales/keysTranslations";

const RequestsInfluencersAccountsTable = ({
  data,
  searchText,
  onSelectRequest,
  onCodeSent,
  rowsPerPage,
  page,
  onRowsPerPageChange,
  onPageChange,
}) => {
  const country = useSelectorApp((state) => state?.auth?.user?.country);

  const { t } = useTranslationApp();

  const getInfluencersFiltered = () => {
    const filtered = {};
    Object.keys(data).forEach((key) => {
      const influencer = data[key];
      if (influencer?.country !== country) {
        return;
      }
      if (
        !influencer?.accountName
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
      ) {
        return;
      }
      return (filtered[key] = influencer);
    });
    return filtered;
  };

  const influencersFiltered = getInfluencersFiltered();
  const keys = Object.keys(influencersFiltered);

  const keysSorted = keys.sort((a, b) => {
    return !data[b].isCodeSent - !data[a].isCodeSent;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={false}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PHONE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_COUNTRY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CODE_VERIFICATION)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((row, index) => (
            <TableRow key={index}>
              <TableCell
                indexRow={index}
                align="center"
                sx={{
                  wordBreak: "break-all",
                }}
              >
                <NameInfluencerCell
                  isExternal={true}
                  data={{
                    mainAccountName: data[row].accountName,
                    mainPlatform: data[row].platform,
                  }}
                  showButton={false}
                />
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].phoneNumber}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].country ?? "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].verificationCode ?? "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <RequestInfluencerStatus
                  verificationCode={data[row].verificationCode}
                  isCodeSent={data[row].isCodeSent}
                  isRejected={data[row].isRejected}
                />
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <RequestInfluencerAccountsActions
                  data={data[row]}
                  id={row}
                  onSelectRequest={onSelectRequest}
                  onCodeSent={onCodeSent}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default RequestsInfluencersAccountsTable;
