import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounced } from "../../../hooks/useDebounce";
import {
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE,
  DELAY_DEBOUNCE,
} from "../../../utils/constants";
import { useDispatchApp } from "../../../lib/redux";
import { startGetAdsCampaignSegmentation } from "../../../actions/adsCampaigns";
import {
  EMPTY_RESULTS_SHOW,
  FIELD_REQUIRED,
  LOADING,
  TYPE_LEAST_CHARACTERS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { Controller } from "react-hook-form";
import i18next from "i18next";
import Typography from "@mui/material/Typography";

const SearchSegmentationsField = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  groupBy,
  orderBy = "asc",
  disabled = false,
  placeholder,
  variant = "filled",
  fullWidth,
  helperText,
  type = "interests",
  mode = "fetching",
  platform,
  objective,
}) => {
  const [open, setOpen] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  // Get value by path
  const getValueByPath = (obj, path) => {
    return path.reduce((acc, key) => acc[key], obj);
  };

  // Sort by groupBy
  const sortByGroupBy = () => {
    const newOptions = [...options];
    return newOptions.sort((a, b) => {
      for (let path of groupBy) {
        const aValue = getValueByPath(a, Array.isArray(path) ? path : [path]);
        const bValue = getValueByPath(b, Array.isArray(path) ? path : [path]);

        if (aValue > bValue) {
          return orderBy === "asc" ? 1 : -1;
        }
        if (aValue < bValue) {
          return orderBy === "asc" ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const getTextExtended = (option) => {
    if (type === ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE) {
      return (
        `${option?.region ? `${option?.region},` : ""} ${
          option?.country_name
        }` || option?.region
      );
    }

    return option?.path?.[0];
  };

  const getAdsCampaignSegmentation = useDebounced(
    async (textQuery) => {
      setLoading(true);
      const response = await dispatch(
        startGetAdsCampaignSegmentation({
          keyWord: textQuery,
          type,
          platform,
          objective,
        })
      );
      if (response) {
        setOptions((prevState) => {
          //filter out the options that are already in the state
          const newOptions = response.filter(
            (option) =>
              !prevState.some((prevOption) => prevOption.name === option.name)
          );

          return [...prevState, ...newOptions];
        });
      }
      setLoading(false);
    },
    [DELAY_DEBOUNCE]
  );

  useEffect(() => {
    if (disabled) return;
    if (mode === "load" && !isFetched) {
      setIsFetched(true);
      getAdsCampaignSegmentation();
    }
    // eslint-disable-next-line
  }, [mode, disabled]);

  useEffect(() => {
    if (!groupBy) return;

    const sortedOptions = sortByGroupBy();
    if (sortedOptions.length !== options.length) {
      setOptions(sortedOptions);
    }
    // eslint-disable-next-line
  }, [options, groupBy]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          open={open}
          multiple={true}
          groupBy={(option) => {
            if (!groupBy) return;
            const hasMoreOneGroup = groupBy.length > 1;

            if (!hasMoreOneGroup) return option[groupBy[0]];

            return option[groupBy[0]][groupBy[1]];
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value) => {
            field.onChange(value);
          }}
          onInputChange={(_, value) => {
            if (value.length > 2 && mode === "fetching") {
              setLoading(true);
              getAdsCampaignSegmentation(value);
            }
          }}
          noOptionsText={t(
            options.length === 0 ? TYPE_LEAST_CHARACTERS : EMPTY_RESULTS_SHOW,
            {
              value: 3,
            }
          )}
          loadingText={t(LOADING)}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          getOptionLabel={(option) => `${option?.name}`}
          options={options}
          loading={loading}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{ display: "flex", gap: 1 }}
                key={option.id || option.name}
              >
                <Typography variant="body1">{option.name}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "fields.placeholder",
                    fontStyle: "italic",
                  }}
                >
                  {getTextExtended(option)}
                </Typography>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!errors[name]}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {!!errors[name] ? errors[name]?.message : helperText}
                  </span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SearchSegmentationsField;
