import React, { useState } from "react";
import { useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import StatusInvoice from "./StatusInvoice";
import ActionsInvoicesTable from "./ActionsInvoicesTable";
import {
  PAYMENT_AMBASSADOR,
  CLAPP_CREDITS,
  DEFAULT_TABLE_PAGES,
  PAYMENT_APPLICANT,
  PLAN_COLLABORATION,
  PLANS_PACKAGE,
  PAYMENT_INFLUENCER,
  PAYMENT_INVITATION,
} from "../../../../utils/constants";
import TableCell from "../../../Common/Table/TableCell";
import TableContainer from "../../../Common/Table/TableContainer";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  COLUMN_NAME_ACTION,
  BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL,
  COLUMN_NAME_PAID_DATE,
  COLUMN_NAME_DUE_DATE,
  COLUMN_NAME_VALUE,
  COLUMN_NAME_STATUS,
  DATE_SHORT,
  VALUE_WITH_CURRENCY,
  MODAL_INVOICE_AMBASSADOR,
  MODAL_INVOICE_APPLICANT,
  MODAL_INVOICE_CREDITS,
  MODAL_INVOICE_COLLABORATION,
  MODAL_INVOICE_INVITATION,
  PAYMENT_REASON_PACKAGE,
  PAYMENT_REASON_PLAN,
  COLUMN_NAME_REASON,
  MODAL_INVOICE_INFLUENCER,
  EMPTY_INVOICES,
} from "../../../../locales/keysTranslations";
import { useLocale } from "../../../../Contexts/LocaleContext";

const InvoicesTable = ({ data, loading, onSelectInvoice, onPayInvoice }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(DEFAULT_TABLE_PAGES);

  const { plans } = useSelectorApp((state) => state.payments);

  const { t } = useTranslationApp();

  const LOCALE = useLocale();

  const onChangePage = (newPage) => {
    setPage(newPage);
  };

  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].dueDate.getTime() - data[a].dueDate.getTime()
  );

  const getPlanName = (id) => {
    if (id === PLAN_COLLABORATION) return t(MODAL_INVOICE_COLLABORATION);
    if (id === PAYMENT_APPLICANT) return t(MODAL_INVOICE_APPLICANT);
    if (id === PAYMENT_AMBASSADOR) return t(MODAL_INVOICE_AMBASSADOR);
    if (id === PAYMENT_INFLUENCER) return t(MODAL_INVOICE_INFLUENCER);
    if (id === CLAPP_CREDITS) return t(MODAL_INVOICE_CREDITS);
    if (id === PAYMENT_INVITATION) return t(MODAL_INVOICE_INVITATION);
    if (PLANS_PACKAGE.includes(id)) {
      return t(PAYMENT_REASON_PACKAGE, {
        name: id,
      });
    }
    return t(PAYMENT_REASON_PLAN, {
      name: plans[id]?.name,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={loading}
        emptyText={EMPTY_INVOICES}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        ariaLabel={t(BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL)}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PAID_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_DUE_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_VALUE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_REASON)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => (
            <TableRow key={key}>
              <TableCell align="center" indexRow={index}>
                {data[key].paidDate === ""
                  ? "-"
                  : t(DATE_SHORT, {
                      date: data[key].paidDate,
                    })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[key].dueDate === ""
                  ? "-"
                  : t(DATE_SHORT, {
                      date: data[key].dueDate,
                    })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {t(VALUE_WITH_CURRENCY, {
                  value: data[key].value,
                  currency:
                    data[key]?.currency?.toUpperCase() || LOCALE.currency,
                })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {getPlanName(data[key].subscription)}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <StatusInvoice isPaid={data[key].isPaid} />
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <ActionsInvoicesTable
                  data={data[key]}
                  keyInvoice={key}
                  onSelectInvoice={onSelectInvoice}
                  onPayInvoice={onPayInvoice}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default InvoicesTable;
