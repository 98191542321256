import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslationApp } from "../../../lib/i18next";
import {
  REVIEWS,
  EMPTY_REVIEWS,
  SHOW_REVIEWS,
} from "../../../locales/keysTranslations";
import Rating from "../Forms/Rating/Rating";

const Review = ({ data }) => {
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
        {data.comment}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          <Rating
            readOnly={true}
            value={Number(data.score || 0)}
            precision={0.5}
          />
        </Box>
      </Box>
    </Box>
  );
};

const InfluencerReviews = ({ data, maxHeight = 250 }) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery("(max-width:900px)");
  if (!data)
    return (
      <Box sx={{ width: "100%" }}>
        <Typography component="p" variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
          {t(REVIEWS)}
        </Typography>
        <Typography variant="body1">{t(EMPTY_REVIEWS)}</Typography>
      </Box>
    );
  return (
    <Box sx={{ mx: 0, width: "100%" }}>
      <Typography component="p" variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
        {t(REVIEWS)}
      </Typography>
      {isMobile ? (
        <Box>
          <Accordion elevation={0} disableGutters={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography>{t(SHOW_REVIEWS)}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                maxHeight: maxHeight,
                overflowY: "auto",
                pl: 0,
                pr: 1,
              }}
            >
              {Object.keys(data).map((reviewKey) => {
                return <Review key={reviewKey} data={data[reviewKey]} />;
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: maxHeight,
            overflowY: "auto",
            pr: 1,
          }}
        >
          {Object.keys(data).map((reviewKey) => {
            return <Review key={reviewKey} data={data[reviewKey]} />;
          })}
        </Box>
      )}
    </Box>
  );
};

export default InfluencerReviews;
