import React from "react";
import { getExtensionFile } from "../../../../utils/urls";
import Box from "@mui/material/Box";
import { VIDEO_FORMATS } from "../../../../utils/constants";
import Avatar from "@mui/material/Avatar";
import VideoPlayer from "../../Video/VideoPlayer";

const ShopPost = ({ url, orderID = "" }) => {
  const extension = getExtensionFile(url);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {VIDEO_FORMATS.includes(extension) ? (
        <VideoPlayer
          url={url}
          controls={true}
          sx={{ position: "relative", maxWidth: "auto", maxHeight: 360 }}
        />
      ) : (
        <Avatar
          variant="rounded"
          src={url}
          sx={{
            height: 360,
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt={`Image ${orderID}`}
        />
      )}
    </Box>
  );
};

export default ShopPost;
