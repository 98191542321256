import React from "react";
import { VIDEO_FORMATS } from "../../../utils/constants";
import { getExtensionFile } from "../../../utils/urls";
import MediaDownloadContainer from "../../Common/Media/MediaDownloadContainer";
import VideoPlayer from "../../Common/Video/VideoPlayer";

const GalleryContentItem = ({ item }) => {
  const extension = getExtensionFile(item);

  return VIDEO_FORMATS.includes(extension) ? (
    <MediaDownloadContainer
      item={item}
      url={item}
      disabledActions={true}
      doScale={true}
    >
      <VideoPlayer
        url={`${item}`}
        controls={true}
        sx={{
          width: "100%",
          borderRadius: 16,
          transition: "1s",
          height: 350,
          maxHeight: "100%",
          objectFit: "cover",
        }}
        controlsList="nodownload"
        muted
      />
    </MediaDownloadContainer>
  ) : (
    <MediaDownloadContainer
      item={item}
      url={item}
      disabledActions={true}
      doScale={true}
    >
      <img
        src={`${item}`}
        srcSet={`${item}`}
        alt={item.key}
        loading="lazy"
        style={{
          display: "block",
          width: "100%",
          borderRadius: 16,
          transition: "1s",
          height: 350,
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    </MediaDownloadContainer>
  );
};

export default GalleryContentItem;
