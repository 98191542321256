import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ADD_CATEGORY_FIELD } from "../../../../onboarding/stepsSelectors";
import {
  MENU_CATEGORY_NAME_PLACEHOLDER,
  MENU_CATEGORY_REQUIRED,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

export const FieldCategory = ({
  onCreateCategory,
  onEditCategory,
  mode,
  onCancel,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const { t } = useTranslationApp();

  const createCategory = () => {
    if (!value) {
      return setError(true);
    }
    setError(false);
    onCreateCategory(value);
  };

  const editCategory = () => {
    if (!value) {
      return setError(true);
    }
    setError(false);
    onEditCategory(value);
  };

  return (
    <React.Fragment>
      <TextField
        hiddenLabel={true}
        variant="filled"
        data-tour={ADD_CATEGORY_FIELD}
        error={error}
        name="name"
        autoFocus={true}
        onBlur={() => (mode === "create" ? createCategory() : editCategory())}
        onKeyDown={(e) => {
          e.key === "Escape" && onCancel();
        }}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        sx={{
          width: "100%",
          px: 2,
        }}
        placeholder={t(MENU_CATEGORY_NAME_PLACEHOLDER)}
      />
      {error && (
        <Typography variant="body2" color="error" sx={{ mx: 2 }}>
          {t(MENU_CATEGORY_REQUIRED)}
        </Typography>
      )}
    </React.Fragment>
  );
};
