import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AnswerContainer from "../../../Common/Forms/TypeForm/AnswerContainer";
import AnswerTitle from "../../../Common/Forms/TypeForm/AnswerTitle";
import Alert from "@mui/material/Alert";
import TextField from "../../../Common/Fields/TextField";
import {
  SELECT_A_OPTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION,
  CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
  BUTTON_REPLACE_CONTENT,
  BUTTON_SELECT_CONTENT,
  CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION,
  BUTTON_NEXT,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ActionsButtons from "../../../Common/Forms/TypeForm/ActionsButtons";
import Select from "../../../Common/Selects/Select";
import { optionsAdsCampaignObjectives } from "../../../../utils/slidersMarks";
import { Controller } from "react-hook-form";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "../../../../utils/constants";
import IconButtonAddContent from "../../../Common/Modal/ModalGenerateSocialMediaContent/IconButtonAddContent";
import { useDispatchApp } from "../../../../lib/redux";
import { getShopID } from "../../../../actions/getters";
import AnswerSubTitle from "../../../Common/Forms/TypeForm/AnswerSubTitle";
import LinearLoaderWithTime from "../../../Common/Loader/LinearLoaderWithTime";
import SelectTrackingIDField from "../../../Common/Fields/SelectTrackingIDField";
import { useMediaQuery } from "@mui/material";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import ModalMedia from "../../../Common/Modal/ModalMedia";

const CreateAdsCampaignForm = ({
  currentAnswer,
  stepVisible,
  error,
  onNextStep,
  onPreviousStep,
  setValue,
  control,
  getValues,
  watch,
  isCreating,
  onChangePixels,
}) => {
  const [modalMediaOpen, setModalMediaOpen] = useState(false);

  const mediaUrl = watch("mediaUrl");
  const platform = watch("platform");

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const shopID = dispatch(getShopID());

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer stepPosition={"mediaUrl"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: mediaUrl || error ? 1 : -2 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "mediaUrl" && (
          <Alert severity="error">{error.text}</Alert>
        )}
        {mediaUrl && (
          <IconButton
            onClick={() => setModalMediaOpen(true)}
            sx={{
              p: 0,
              mt: error ? 2 : 0,
            }}
          >
            <PermMediaIcon
              sx={{
                fontSize: "2rem",
              }}
            />
          </IconButton>
        )}
        <ActionsButtons
          showPreviousButton={false}
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("mediaUrl", getValues("mediaUrl"))}
          customComponentPreviousNext={
            <Controller
              name="mediaUrl"
              control={control}
              render={({ field }) => (
                <IconButtonAddContent
                  mode="button"
                  color="primary"
                  size="large"
                  pathStorage={`adsCampaigns/${shopID}/`}
                  onAddContent={(contents) => field.onChange(contents[0].url)}
                  onCallbackSaveCanva={(url) => field.onChange(url)}
                  multiple={false}
                  acceptedFormats={VIDEO_FORMATS.concat(IMAGE_FORMATS)}
                  showContent={false}
                  urlContent={mediaUrl}
                  textButton={
                    mediaUrl ? BUTTON_REPLACE_CONTENT : BUTTON_SELECT_CONTENT
                  }
                  sx={{
                    mt: 2,
                    alignItems: "flex-start",
                  }}
                  sxButton={{
                    height: 40,
                    fontSize: "0.875rem",
                  }}
                />
              )}
            />
          }
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"objective"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION)}`}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION)}
        </Typography>
        {!!error && error.step === "objective" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Select
          options={optionsAdsCampaignObjectives}
          size="small"
          name="objective"
          displayEmpty={true}
          showEmptyOption={true}
          fullWidth
          placeholder={t(SELECT_A_OPTION)}
          doTranslate={true}
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("objective", getValues("objective"))}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"trackingID"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "trackingID" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <SelectTrackingIDField
          control={control}
          name="trackingID"
          placeholder={t(SELECT_A_OPTION)}
          fullWidth
          variant="standard"
          platform={platform}
          onLoadOptions={onChangePixels}
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("trackingID", getValues("trackingID"))}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"destinationURL"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "destinationURL" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="destinationURL"
          fullWidth
          placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
          variant="standard"
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("destinationURL", getValues("destinationURL"))
          }
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"context"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION)}`}
        </AnswerTitle>
        <AnswerSubTitle>
          {t(CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION)}
        </AnswerSubTitle>
        {!!error && error.step === "context" && (
          <Alert severity="error" sx={{ my: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          multiline
          minRows={isMobile ? 2 : 4}
          maxRows={isMobile ? 3 : 5}
          name="context"
          placeholder={t(CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER)}
          fullWidth
          variant="standard"
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onFinish={() => onNextStep("context", getValues("context"), true)}
          saveButtonText={BUTTON_NEXT}
          showLoadingComponent={true}
          customComponentLoading={
            <LinearLoaderWithTime
              time={40 * 1000}
              labels={[
                t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
                t(CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION),
                t(CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS),
                t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
              ]}
            />
          }
          showSaveButton={mediaUrl}
          showNextButton={false}
          loading={isCreating}
        />
      </AnswerContainer>
      {modalMediaOpen && (
        <ModalMedia
          url={mediaUrl}
          modalOpen={modalMediaOpen}
          onCloseModal={(e) => {
            e?.stopPropagation();
            setModalMediaOpen(false);
          }}
          disabledActions={true}
        />
      )}
    </Box>
  );
};

export default CreateAdsCampaignForm;
