import React, { useState } from "react";
import Box from "@mui/material/Box";
import GeneralStatistics from "../../components/Owner/Statistics/General/GeneralStatistics";
import ModalReports from "../../components/Owner/Statistics/Reports/ModalReports";
import Button from "../../components/Common/Buttons/Button";
import TitlePage from "../../components/Common/Texts/TitlePage";
import DescriptionPage from "../../components/Common/Texts/DescriptionPage";
import { useDispatchApp } from "../../lib/redux";
import { getNameBusinessTheme } from "../../actions/getters";
import { useTranslationApp } from "../../lib/i18next";
import {
  BUTTON_DOWNLOAD_REPORT,
  REPORTS_DESCRIPTION,
  REPORTS_TITLE,
} from "../../locales/keysTranslations";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const Statistics = () => {
  const [modalReportOpen, setModalReportOpen] = useState(false);

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { t } = useTranslationApp();

  const onChangeModalReportStatus = (status) => setModalReportOpen(status);

  return (
    <ContainerPage>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TitlePage>{t(REPORTS_TITLE)}</TitlePage>
        <Button sx={{ mt: 2 }} onClick={() => onChangeModalReportStatus(true)}>
          {t(BUTTON_DOWNLOAD_REPORT)}
        </Button>
      </Box>
      <DescriptionPage>
        {t(REPORTS_DESCRIPTION, {
          NAME_BUSINESS,
        })}
      </DescriptionPage>
      <GeneralStatistics />
      {modalReportOpen && (
        <ModalReports
          modalOpen={modalReportOpen}
          onCloseModal={() => onChangeModalReportStatus(false)}
        />
      )}
    </ContainerPage>
  );
};

export default Statistics;
