import React from "react";
import TableContainer from "../../../Common/Table/TableContainer";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "../../../Common/Table/TableCell";
import { useTranslationApp } from "../../../../lib/i18next";
import { validateIsValidDate } from "../../../../utils/dates";
import {
  COLUMN_NAME_COUNTRY,
  COLUMN_NAME_DATE,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_IS_PLAN_ACTIVE,
  COLUMN_NAME_NAME,
  COLUMN_NAME_PHONE,
  DATE_SHORT,
  NO,
  YES,
} from "../../../../locales/keysTranslations";
import { useSelectorApp } from "../../../../lib/redux";

const AdvertisersTable = ({ data, page, onPageChange, rowsPerPage }) => {
  const adsUsers = useSelectorApp((state) => state.advertisers.adsUsers) || {};
  const locales = useSelectorApp((state) => state?.locales?.localesList) || {};

  const { t } = useTranslationApp();

  const keysSorted = Object.keys(data).sort((a, b) => {
    const dateA = data[a].creationTime;
    const dateB = data[b].creationTime;
    if (!dateA && !dateB) return 0;
    return dateB - dateA;
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={false}
        sx={{ overflow: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_COUNTRY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PHONE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_IS_PLAN_ACTIVE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_DATE)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((row, index) => {
            const info = data[row || {}];
            const userID = Object.keys(info.users || {})[0];
            const user = adsUsers[userID] || {};
            const country = locales[info.signUpCountry] || {};

            return (
              <TableRow key={index}>
                <TableCell align="center" indexRow={index}>
                  {country?.name || "-"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {user?.name || "-"}
                  </Box>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {user?.email || "-"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {user?.phone?.value || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 230 }}
                >
                  {info?.isPlanActive ? t(YES) : t(NO)}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {validateIsValidDate(info.creationTime) &&
                    t(DATE_SHORT, {
                      date: new Date(info.creationTime),
                    })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default AdvertisersTable;
