import React from "react";
import RadioGroupMUI from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../locales/keysTranslations";

const RadioGroup = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  variantTitle = "body1",
  variantOption = "body1",
  options,
  isBooleanValues = false,
  disabled = false,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormControl component="fieldset">
          <FormLabel>
            <Typography
              variant={variantTitle}
              sx={{
                fontWeight: 500,
              }}
            >
              {label}
            </Typography>
          </FormLabel>
          <RadioGroupMUI
            {...field}
            disabled={disabled}
            onChange={(e) =>
              field.onChange({
                ...e,
                target: {
                  ...e.target,
                  value: isBooleanValues
                    ? e.target.value === "true"
                    : e.target.value,
                },
              })
            }
          >
            {options.map((option, index) => (
              <FormControlLabel
                disabled={disabled}
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
                componentsProps={{
                  typography: { variant: variantOption },
                }}
              />
            ))}
          </RadioGroupMUI>
          {!!errors[name] && (
            <Typography variant="caption" color="error">
              {errors[name]?.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default RadioGroup;
