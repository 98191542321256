import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  GREY_DARK_COLOR,
  ORANGE_COLOR,
  RED_COLOR,
} from "../../../utils/colors";
import {
  REQUEST_INFLUENCERS_CODE_SENT_STATUS,
  REQUEST_INFLUENCERS_PENDING_SENT_CODE,
  REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const states = {
  waiting: {
    textButton: REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS,
    color: ORANGE_COLOR,
  },
  sentCode: {
    textButton: REQUEST_INFLUENCERS_CODE_SENT_STATUS,
    color: GREY_DARK_COLOR,
  },
  accepted: {
    textButton: REQUEST_INFLUENCERS_PENDING_SENT_CODE,
    color: RED_COLOR,
  },
};

const RequestInfluencerStatus = ({
  verificationCode,
  isCodeSent,
  isRejected,
}) => {
  const { t } = useTranslationApp();
  const status =
    verificationCode && !isCodeSent
      ? "accepted"
      : verificationCode && isCodeSent
      ? "sentCode"
      : "waiting";
  const { textButton, color } = states[status];
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          color: color,
          fontWeight: 600,
        }}
      >
        {t(textButton)}
      </Typography>
    </Box>
  );
};

export default RequestInfluencerStatus;
