import types from "../types";

const initialState = {
  localesList: {},
  locales: {},
  locale: {},
  isLoading: true,
};
export const localesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOCALES_LIST_FINISH:
      return {
        ...state,
        localesList: {
          ...state.locales,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.GET_LOCALE_FINISH:
      return {
        ...state,
        locales: {
          ...state.locales,
          [action.payload.locale]: action.payload.data,
        },
        locale: action.payload.data,
        isLoading: false,
      };
    case types.SET_LOCALE_FINISH:
      return {
        ...state,
        locale: action.payload,
      };
    case types.LOCALE_LOADING_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
