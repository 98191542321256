import types from "../types";
import {
  endAt,
  equalTo,
  get,
  limitToFirst,
  orderByChild,
  push,
  query,
  ref,
  set,
  startAfter,
  startAt,
  update,
} from "firebase/database";
import { db } from "../firebase";
import {
  getNextInfluencers,
  startGetInfluencer,
  startGetInfluencersByUserIDs,
} from "./influencers";
import { startGetInvitesInactiveByInvitesID } from "./invites";
import { startGetShopsByShopIDs } from "./shops";
import {
  ADMIN_ROLE,
  ALERT_ICON_TYPE_ERROR,
  POST_STATUS_PENDING,
  POST_STATUS_PENDING_CONTENT,
  POST_STATUS_REVIEWED,
  POST_STATUS_SENT,
  ROLES_DASHBOARD,
  SUPER_ADMIN_ROLE,
} from "../utils/constants";
import { getRole, getShopID } from "./getters";
import {
  getCityInfluencer,
  getObjectError,
  normalizeString,
} from "../utils/formats";
import { SimpleAlert } from "../utils/alerts";
import { DUMMY_ORDER } from "../onboarding/stepsSelectors";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { verifyIsErrorPermissionDenied } from "../utils/errors";

export const startGetOrders =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch) => {
    try {
      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `ordersIDS`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `ordersIDS`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      } else {
        q = query(ref(db, `ordersIDS`), orderByChild("creationTime"));
      }

      const snapshot = await get(q);
      const keysOrders = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize && limit)
          onChangeLastKey(snapshot.val().creationTime);
        keysOrders.push(snapshot.key);
        count++;
      });

      const queriesOrders = [];
      keysOrders.forEach((keyOrder) => {
        queriesOrders.push(get(ref(db, `orders/${keyOrder}`)));
      });

      const ordersSnapshots = await Promise.all(queriesOrders);

      let orders = {};
      let userIDs = [];
      let shopIDs = [];
      ordersSnapshots.forEach((orderSnapshot) => {
        orders = {
          ...orders,
          [orderSnapshot.key]: orderSnapshot.val(),
        };
        userIDs.push(orderSnapshot.val().userID);
        shopIDs.push(orderSnapshot.val().shopID);
      });

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      await dispatch(startGetShopsByShopIDs({ shopIDs }));
      dispatch(getOrders(orders));
      return true;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetOrdersByUserID =
  ({ userID }) =>
  async (dispatch) => {
    try {
      let q = query(ref(db, `orders`), orderByChild("userID"), equalTo(userID));

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        dispatch(getOrders({}));
      } else {
        const orders = snapshot.val();
        dispatch(getOrders(orders));
      }

      return true;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      console.log(error);
      return false;
    }
  };

export const startGetOrdersByStatus =
  ({ status }) =>
  async (dispatch) => {
    try {
      let q = query(
        ref(db, `ordersIDS`),
        orderByChild("postStatus"),
        equalTo(status)
      );

      const snapshot = await get(q);
      const keysOrders = [];

      snapshot.forEach((snapshot) => {
        keysOrders.push(snapshot.key);
      });

      const queriesOrders = [];
      keysOrders.forEach((keyOrder) => {
        queriesOrders.push(get(ref(db, `orders/${keyOrder}`)));
      });

      const ordersSnapshots = await Promise.all(queriesOrders);
      let orders = {};
      let userIDs = [];
      let shopIDs = [];
      ordersSnapshots.forEach((orderSnapshot) => {
        orders = {
          ...orders,
          [orderSnapshot.key]: orderSnapshot.val(),
        };
        userIDs.push(orderSnapshot.val().userID);
        shopIDs.push(orderSnapshot.val().shopID);
      });

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      await dispatch(startGetShopsByShopIDs({ shopIDs }));
      dispatch(getOrders(orders));
      return true;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetOrdersByShop =
  ({
    limit,
    onChangeLastKey,
    lastKey,
    loadLastItem = () => {},
    getAnalytics = false,
    onCallbackFinish = () => {},
  }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `shopsIDS/${shopID}/orders`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `shopsIDS/${shopID}/orders`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          ref(db, `shopsIDS/${shopID}/orders`),
          orderByChild("creationTime")
        );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        dispatch(loadingOrdersFinish());
        dispatch(getNextInfluencers({}));
        onCallbackFinish();
        return loadLastItem();
      }

      const keysOrders = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      const queriesAnalytics = [];

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();

        if (count === snapshotSize && limit) onChangeLastKey(data.creationTime);

        keysOrders.push(snapshot.key);
        count++;

        if (
          getAnalytics &&
          (data.postStatus === POST_STATUS_SENT ||
            data.postStatus === POST_STATUS_REVIEWED)
        ) {
          queriesAnalytics.push(get(ref(db, `reports/orders/${snapshot.key}`)));
        }
      });

      const queriesOrders = [];
      keysOrders.forEach((keyOrder) => {
        queriesOrders.push(get(ref(db, `orders/${keyOrder}`)));
      });

      const ordersSnapshots = await Promise.all(queriesOrders);
      let analyticsSnapshots;

      if (getAnalytics && queriesAnalytics.length > 0) {
        analyticsSnapshots = await Promise.all(queriesAnalytics);
      }

      let orders = {};
      let userIDs = [];

      ordersSnapshots.forEach((orderSnapshot) => {
        const order = orderSnapshot.val();
        orders[orderSnapshot.key] = order;
        userIDs.push(order.userID);
      });

      if (getAnalytics && queriesAnalytics?.length > 0) {
        analyticsSnapshots.forEach((analyticsSnapshot) => {
          if (!analyticsSnapshot.exists()) return;

          const analytics = analyticsSnapshot.val();

          orders[analyticsSnapshot.key] = {
            ...orders[analyticsSnapshot.key],
            analyticsData: analytics,
          };
        });
      }

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      dispatch(getOrdersByShop(orders));
      onCallbackFinish();
      return true;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      console.log(error);
      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return {
        ok: false,
        data: [],
        message: errorFormatted.message,
      };
    }
  };
export const startGetOrdersByUserIDPagination =
  ({
    userID,
    limit,
    onChangeLastKey,
    lastKey,
    loadLastItem = () => {},
    getAnalytics = false,
  }) =>
  async (dispatch) => {
    try {
      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `influencersIDS/${userID}/orders`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `influencersIDS/${userID}/orders`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          ref(db, `influencersIDS/${userID}/orders`),
          orderByChild("creationTime")
        );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        dispatch(getNextInfluencers({}));
        loadLastItem();
        return {};
      }

      const keysOrders = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      const queriesAnalytics = [];

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();

        if (count === snapshotSize && limit) onChangeLastKey(data.creationTime);

        keysOrders.push(snapshot.key);
        count++;

        if (
          getAnalytics &&
          (data.postStatus === POST_STATUS_SENT ||
            data.postStatus === POST_STATUS_REVIEWED)
        ) {
          queriesAnalytics.push(get(ref(db, `reports/orders/${snapshot.key}`)));
        }
      });

      const queriesOrders = [];
      keysOrders.forEach((keyOrder) => {
        queriesOrders.push(get(ref(db, `orders/${keyOrder}`)));
      });

      const ordersSnapshots = await Promise.all(queriesOrders);
      let analyticsSnapshots;

      if (getAnalytics && queriesAnalytics.length > 0) {
        analyticsSnapshots = await Promise.all(queriesAnalytics);
      }

      let orders = {};

      ordersSnapshots.forEach((orderSnapshot) => {
        const order = orderSnapshot.val();
        orders[orderSnapshot.key] = order;
      });

      if (getAnalytics && queriesAnalytics?.length > 0) {
        analyticsSnapshots.forEach((analyticsSnapshot) => {
          if (!analyticsSnapshot.exists()) return;

          const analytics = analyticsSnapshot.val();

          orders[analyticsSnapshot.key] = {
            ...orders[analyticsSnapshot.key],
            analyticsData: analytics,
          };
        });
      }

      await dispatch(
        startGetInfluencersByUserIDs({
          userIDs: [userID],
          getIsDiscarded: false,
        })
      );
      return orders;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      console.log(error);
      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetOrdersByStore =
  ({ limit, onChangeLastKey, lastKey, storeID, onCallbackFinish = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/stores/${storeID}/orders`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/stores/${storeID}/orders`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);
      const keysOrders = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize && limit)
          onChangeLastKey(snapshot.val().creationTime);
        keysOrders.push(snapshot.key);
        count++;
      });

      const queriesOrders = [];
      keysOrders.forEach((keyOrder) => {
        queriesOrders.push(get(ref(db, `orders/${keyOrder}`)));
      });

      const ordersSnapshots = await Promise.all(queriesOrders);

      let orders = {};
      let invitesID = [];
      ordersSnapshots.forEach((orderSnapshot) => {
        orders = {
          ...orders,
          [orderSnapshot.key]: orderSnapshot.val(),
        };
        invitesID.push(orderSnapshot.val().inviteID);
      });
      await dispatch(startGetInvitesInactiveByInvitesID({ invitesID }));

      lastKey
        ? dispatch(getOrdersByStorePagination(orders))
        : dispatch(getOrdersByStore(orders));

      onCallbackFinish();
      return true;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      onCallbackFinish();
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const getOrders = (orders) => ({
  type: types.GET_ORDERS_FINISH,
  payload: orders,
});
const getOrdersByShop = (orders) => ({
  type: types.GET_ORDERS_BY_SHOP_FINISH,
  payload: orders,
});
const getOrdersByStore = (orders) => ({
  type: types.GET_ORDERS_BY_STORE_FINISH,
  payload: orders,
});
const getOrdersByStorePagination = (orders) => ({
  type: types.GET_ORDERS_BY_STORE_PAGINATION,
  payload: orders,
});

export const startCreateOrder = (data) => async (dispatch, getState) => {
  try {
    const isOpenOnboarding = getState().onboarding.isOpen;
    if (isOpenOnboarding) {
      const orderData = {
        key: DUMMY_ORDER,
        data: data,
      };
      dispatch(createOrder(orderData));
      return true;
    }
    const dbRef = ref(db, "orders");
    const orderID = push(dbRef, data).key;
    const orderData = {
      key: orderID,
      data: data,
    };
    dispatch(createOrder(orderData));
    return true;
  } catch (error) {
    console.log(error);

    const errorFormatted = getObjectError(error);

    if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
      return false;
    }

    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startCreateOrderFromInviteID =
  ({ inviteID, type }) =>
  async (dispatch) => {
    try {
      let refInvite;

      if (type === "active") {
        refInvite = ref(db, `invites/${inviteID}`);
      } else {
        refInvite = ref(db, `invitesInactive/${inviteID}`);
      }

      const snapshotInvite = await get(refInvite);
      const inviteData = snapshotInvite.val();

      if (!inviteData) return false;

      const redemptionDate = new Date();

      const updates = {};

      const orderID = push(ref(db, "orders")).key;

      if (type === "inactive") {
        updates[`invitesInactive/${inviteID}/redemptionDate`] =
          redemptionDate.getTime();
        updates[`invitesInactive/${inviteID}/orderID`] = orderID;
      }

      const storesSnapshot = await get(
        ref(db, `shops/${inviteData.shopID}/stores`)
      );

      if (!storesSnapshot.exists()) return false;

      const stores = storesSnapshot.val();
      const storesKeys = Object.keys(stores);

      let storeID;

      if (storesKeys.length === 0) return false;

      if (storesKeys.length === 1) {
        storeID = storesKeys[0];
      }
      if (storesKeys.length > 1) {
        const influencer = await dispatch(
          startGetInfluencer(inviteData.userID)
        );

        if (!influencer) {
          storeID = storesKeys[0];
        } else {
          const cityInfluencer = getCityInfluencer(influencer);

          storesKeys.forEach((storeKey) => {
            if (storeID) return;

            const store = stores[storeKey];

            const storeCity = store.city;
            if (normalizeString(storeCity).includes(cityInfluencer)) {
              storeID = storeKey;
            }

            if (!storeID) {
              const cities = store?.cities;
              if (cities?.length > 0) {
                cities.forEach((city) => {
                  if (normalizeString(city).includes(cityInfluencer)) {
                    storeID = storeKey;
                  }
                });
              }
            }
          });

          if (!storeID) {
            storeID = storesKeys[0];
          }
        }
      }

      const order = {
        value: inviteData.value,
        shopID: inviteData.shopID,
        userID: inviteData.userID,
        postStatus: POST_STATUS_PENDING,
        date: redemptionDate,
        inviteID,
        isDelivery: false,
        storeID,
      };

      updates[`orders/${orderID}`] = order;
      await update(ref(db), updates);

      return { order, orderID };
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const createOrder = (data) => ({
  type: types.CREATE_ORDER,
  payload: data,
});
export const editOrder = (data) => ({
  type: types.EDIT_ORDER,
  payload: data,
});

export const startGetOrderInfo =
  (orderID, getAnalytics = false) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const role = dispatch(getRole());
      const dbRef = ref(db, `orders/${orderID}`);
      const snapshotOrder = await get(dbRef);
      const orderData = snapshotOrder.val();

      let analyticsData;
      if (
        getAnalytics &&
        (orderData.postStatus === POST_STATUS_SENT ||
          orderData.postStatus === POST_STATUS_REVIEWED)
      ) {
        analyticsData = await dispatch(startGetOrderAnalytics(orderID));
      }

      await dispatch(
        startGetInfluencersByUserIDs({
          userIDs: [orderData.userID],
          getIsDiscarded: false,
        })
      );

      if (
        ((role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
          orderData?.shopID === shopID) ||
        ROLES_DASHBOARD.includes(role)
      ) {
        dispatch(
          getOrders({
            [orderID]: { ...orderData, analyticsData },
          })
        );
        return { ...orderData, analyticsData };
      }
      return false;
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetOrderAnalytics = (orderID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const role = dispatch(getRole());
    const dbRef = ref(db, `reports/orders/${orderID}`);
    const snapshotOrder = await get(dbRef);
    const analytics = snapshotOrder.val();
    if (
      ((role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
        analytics?.shopID === shopID) ||
      ROLES_DASHBOARD.includes(role)
    ) {
      return analytics;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);

    if (verifyIsErrorPermissionDenied(errorFormatted)) {
      return false;
    }
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startAssignInviteIDNegativeReview =
  (orderID, inviteID) => async () => {
    try {
      const dbRef = ref(db, `orders/${orderID}/negativeReview/inviteID`);
      await set(dbRef, inviteID);
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startAssignChatIDOrder =
  ({ orderID, chatID }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `orders/${orderID}/chatID`);
      await set(dbRef, chatID);
      dispatch(
        assignChatIDOrder({
          key: orderID,
          chatID,
        })
      );
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const assignChatIDOrder = (ambassadorID) => ({
  type: types.ASSIGN_CHAT_ID_ORDER,
  payload: ambassadorID,
});

export const startApproveContent = (orderID) => async (dispatch, getState) => {
  try {
    const order = getState()?.orders?.orders[orderID];
    const content = order?.content;

    const dbRef = ref(db, `orders/${orderID}`);
    await update(dbRef, {
      postStatus: order?.postStatus,
      posts: content,
    });

    if (order) {
      dispatch(
        editOrder({
          key: orderID,
          data: {
            postStatus: order?.postStatus,
            posts: content,
          },
        })
      );
    }
    return true;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startSendRevision =
  ({ orderID, data }) =>
  async (dispatch, getState) => {
    try {
      let order = getState().orders[orderID];

      if (!order) {
        order = await dispatch(startGetOrderInfo(orderID));
      }

      const updates = {};

      const history = order.history || [];
      const historyLength = history.length;

      updates[`orders/${orderID}/postStatus`] = POST_STATUS_PENDING_CONTENT;
      updates[`orders/${orderID}/content`] = null;
      updates[`orders/${orderID}/history/${historyLength}`] = {
        ...data,
        content: order.content,
        creationTime: new Date().getTime(),
      };

      await update(ref(db), updates);

      dispatch(
        editOrder({
          key: orderID,
          data: {
            postStatus: POST_STATUS_PENDING_CONTENT,
            content: null,
            history: [
              ...history,
              {
                ...data,
                content: order.content,
                creationTime: new Date().getTime(),
              },
            ],
          },
        })
      );

      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetOrderByUserID = (userID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const q = query(
      ref(db, "orders"),
      orderByChild("shopIDuserID"),
      equalTo(`${shopID}_${userID}`)
    );
    const querySnapshot = await get(q);
    if (querySnapshot.exists()) {
      const orders = querySnapshot.val();
      dispatch(getOrdersByShop(orders));
      return true;
    } else {
      dispatch(loadingOrdersFinish());
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    dispatch(loadingOrdersFinish());
  }
};

export const generalDiscountRedemption = (data) => ({
  type: types.GENERAL_DISCOUNT_REDEMPTION,
  payload: data,
});

export const startGetGeneralRedemptionsByStore =
  ({ limit, onChangeLastKey, lastKey, storeID }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/stores/${storeID}/generalRedemptions`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/stores/${storeID}/generalRedemptions`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);
      const keysGeneralRedemptions = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize && limit)
          onChangeLastKey(snapshot.val().creationTime);
        keysGeneralRedemptions.push(snapshot.key);
        count++;
      });

      const queriesGeneralRedemptions = [];
      keysGeneralRedemptions.forEach((keyGeneralRedemption) => {
        queriesGeneralRedemptions.push(
          get(ref(db, `generalRedemptions/${keyGeneralRedemption}`))
        );
      });

      const generalRedemptionsSnapshots = await Promise.all(
        queriesGeneralRedemptions
      );

      let generalRedemptions = {};
      generalRedemptionsSnapshots.forEach((generalRedemptionsSnapshot) => {
        generalRedemptions[generalRedemptionsSnapshot.key] =
          generalRedemptionsSnapshot.val();
      });

      lastKey
        ? dispatch(getGeneralRedemptionsByStorePagination(generalRedemptions))
        : dispatch(getGeneralRedemptionsByStore(generalRedemptions));
      return true;
    } catch (error) {
      dispatch(loadingOrdersFinish());
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getGeneralRedemptionsByStore = (generalRedemptions) => ({
  type: types.GET_GENERAL_REDEMPTIONS_BY_STORE_FINISH,
  payload: generalRedemptions,
});
const getGeneralRedemptionsByStorePagination = (generalRedemptions) => ({
  type: types.GET_GENERAL_REDEMPTIONS_BY_STORE_PAGINATION,
  payload: generalRedemptions,
});

export const removeOrders = () => ({
  type: types.REMOVE_ORDERS,
});
const loadingOrdersFinish = () => ({
  type: types.LOADING_ORDERS_FINISH,
});

export const startDeleteUrlOrder =
  ({ orderID, url }) =>
  async (dispatch, getState) => {
    try {
      let order = getState()?.orders?.orders[orderID];

      if (!order) {
        order = await dispatch(startGetOrderInfo(orderID));
      }
      const urls = order?.posts.filter((post) => post !== url);

      // await set(ref(db, `orders/${orderID}/posts`), urls);

      dispatch(
        editOrder({
          key: orderID,
          data: {
            posts: urls,
          },
        })
      );

      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetTopContent =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());

      const q = query(
        ref(db, `shopsIDS/${shopID}/orders`),
        orderByChild("creationTime"),
        startAt(-endDate),
        endAt(-startDate)
      );

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        return { ok: false, data: [] };
      }

      const keysOrders = [];

      const queriesAnalytics = [];

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();
        if (
          data.postStatus === POST_STATUS_SENT ||
          data.postStatus === POST_STATUS_REVIEWED
        ) {
          keysOrders.push(snapshot.key);

          queriesAnalytics.push(get(ref(db, `reports/orders/${snapshot.key}`)));
        }
      });

      const queriesOrders = [];
      keysOrders.forEach((keyOrder) => {
        queriesOrders.push(get(ref(db, `orders/${keyOrder}`)));
      });

      const ordersSnapshots = await Promise.all(queriesOrders);
      let analyticsSnapshots;

      if (queriesAnalytics.length > 0) {
        analyticsSnapshots = await Promise.all(queriesAnalytics);
      }

      let orders = {};
      let userIDs = [];

      ordersSnapshots.forEach((orderSnapshot) => {
        const order = orderSnapshot.val();
        orders[orderSnapshot.key] = { ...order, orderID: orderSnapshot.key };
        userIDs.push(order.userID);
      });

      if (queriesAnalytics?.length > 0) {
        analyticsSnapshots.forEach((analyticsSnapshot) => {
          if (!analyticsSnapshot.exists()) return;

          const analytics = analyticsSnapshot.val();

          orders[analyticsSnapshot.key] = {
            ...orders[analyticsSnapshot.key],
            analyticsData: analytics,
          };
        });
      }

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );

      let ordersSorted = [];
      Object.keys(orders).forEach((key) => {
        const order = orders[key];

        const analyticsData = order.analyticsData;
        if (!analyticsData?.accountsReached) return;

        ordersSorted.push({
          orderKey: key,
          accountsReached: analyticsData.accountsReached,
        });
      });

      ordersSorted.sort((a, b) => b.accountsReached - a.accountsReached);

      const topOrders = ordersSorted.slice(0, 5).map((item) => ({
        ...orders[item.orderKey],
      }));

      if (topOrders.length <= 2) {
        return { ok: false, data: [] };
      }

      return { ok: true, data: topOrders };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return {
        ok: false,
      };
    }
  };
