import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ButtonArrow = ({
  onClickAction = () => {},
  orientation = "next",
  hasBackground = false,
  backgroundColor = "rgba(0,0,0,0.7)",
  color = "#fff",
  colorText = "#000",
  sxContainer = {},
  sxButton = {},
  show = true,
}) => {
  if (!show) return null;
  if (!hasBackground) {
    return (
      <IconButton
        onClick={onClickAction}
        sx={{
          background: color,
          color: colorText,
          "&:hover": {
            backgroundColor: color,
            color: colorText,
            filter: "brightness(0.8)",
          },
          ...sxButton,
        }}
      >
        {orientation === "next" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
      </IconButton>
    );
  }

  return (
    <Box
      sx={{
        padding: "4px",
        borderRadius: "50%",
        background: backgroundColor,
        ...sxContainer,
      }}
      onClick={onClickAction}
    >
      <IconButton
        sx={{
          background: color,
          color: colorText,
          "&:hover": {
            backgroundColor: color,
            color: colorText,
            filter: "brightness(0.8)",
          },
          ...sxButton,
        }}
      >
        {orientation === "next" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
      </IconButton>
    </Box>
  );
};

export default ButtonArrow;
