import React, { useEffect, useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Switch from "../../Common/Forms/Switchs/Switch";
import Typography from "@mui/material/Typography";
import {
  startChangeFeatureFlagShopByOwner,
  startChangeIsActiveShopByOwner,
  startChangeIsPlanActiveShopByOwner,
  startChangeIsRenewPlanShopByOwner,
} from "../../../actions/owner";
import differenceInDays from "date-fns/differenceInDays";
import {
  ACTIONS,
  AUTOMATIC_RENOVATION,
  BRAND,
  IS_TRAINED_PHYSICAL_BRAND,
  PLAN,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const ShopActions = ({ data }) => {
  const [isActiveShop, setIsActiveShop] = useState(data.isActive);
  const [isPlanActiveShop, setIsPlanActiveShop] = useState(data.isPlanActive);
  const [isRenewPlanShop, setIsRenewPlanShop] = useState(
    data?.isRenewPlan ?? true
  );
  const [isTrainedPhysicalBrand, setIsTrainedPhysicalBrand] = useState(
    data?.featureFlags?.isTrainedPhysicalBrand ?? false
  );

  useEffect(() => {
    setIsActiveShop(data.isActive);
    setIsPlanActiveShop(data.isPlanActive);
    setIsRenewPlanShop(data?.isRenewPlan ?? true);
    setIsTrainedPhysicalBrand(
      data?.featureFlags?.isTrainedPhysicalBrand ?? false
    );
  }, [data]);

  const differenceDaysToRenew = differenceInDays(
    new Date(data?.subscriptionPlanInfo?.renewDate),
    new Date()
  );

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onChangeIsActiveShop = async (status) => {
    const response = await dispatch(
      startChangeIsActiveShopByOwner({ shopID: data.shopID, status: status })
    );
    if (response) setIsActiveShop(status);
  };
  const onChangeIsPlanActiveShop = async (status) => {
    const response = await dispatch(
      startChangeIsPlanActiveShopByOwner({
        shopID: data.shopID,
        status: status,
      })
    );
    if (response) setIsPlanActiveShop(status);
  };
  const onChangeIsRenewPlan = async (status) => {
    const response = await dispatch(
      startChangeIsRenewPlanShopByOwner({
        shopID: data.shopID,
        status: status,
      })
    );
    if (response) setIsRenewPlanShop(status);
  };
  const onChangeFeatureFlag = async (status, featureFlag) => {
    const response = await dispatch(
      startChangeFeatureFlagShopByOwner({
        shopID: data.shopID,
        status,
        featureFlag,
      })
    );
    if (response) setIsTrainedPhysicalBrand(status);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">{t(ACTIONS)}</Typography>
      <Box>
        <Typography variant="body1">{t(BRAND)}</Typography>
        <Switch
          isActive={isActiveShop}
          onChangeIsActive={(status) => onChangeIsActiveShop(status)}
        />
      </Box>
      <Box>
        <Typography variant="body1">{t(PLAN)}</Typography>
        <Switch
          isActive={isPlanActiveShop}
          onChangeIsActive={(status) => onChangeIsPlanActiveShop(status)}
        />
      </Box>
      {data?.subscriptionPlanInfo?.renewDate &&
        differenceDaysToRenew <= 5 &&
        differenceDaysToRenew >= 0 && (
          <Box>
            <Typography variant="body1">{t(AUTOMATIC_RENOVATION)}</Typography>
            <Switch
              isActive={isRenewPlanShop}
              onChangeIsActive={(status) => onChangeIsRenewPlan(status)}
            />
          </Box>
        )}
      <Box>
        <Typography variant="body1">{t(IS_TRAINED_PHYSICAL_BRAND)}</Typography>
        <Switch
          isActive={isTrainedPhysicalBrand}
          onChangeIsActive={(status) =>
            onChangeFeatureFlag(status, "isTrainedPhysicalBrand")
          }
        />
      </Box>
    </Box>
  );
};

export default ShopActions;
