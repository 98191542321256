import React, { useEffect, useRef, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import FormContact from "./FormContact";
import { REGEX_EMAIL } from "../../../../utils/regexsValidation";
import { ACCOUNT_MODE_CREATE } from "../../../../utils/constants";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import { formatPhoneValueLibrary } from "../../../../utils/formats";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { matchIsValidTel } from "mui-tel-input";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_CREATE,
  BUTTON_SAVE,
  FIELD_ALL_REQUIRED,
  FIELD_EMAIL_VALID,
  FIELD_PHONE_VALID,
  MODAL_CONTACT_ARIA_LABEL,
  MODAL_CONTACT_DESCRIPTION,
  MODAL_CONTACT_DESCRIPTION_EDIT,
  MODAL_CONTACT_TITLE,
  MODAL_CONTACT_TITLE_EDIT,
} from "../../../../locales/keysTranslations";
import { getNameBusinessTheme } from "../../../../actions/getters";
import ContainerModalActions from "../../../Common/Containers/ContainerModalActions";

const INITIAL_STATE = {
  id: "",
  name: "",
  phone: "",
  email: "",
};

const ModalContact = ({
  data,
  open,
  mode = ACCOUNT_MODE_CREATE,
  onCloseModal,
  onCreateContact,
  onEditContact,
}) => {
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);

  const LOCALE = useLocale();

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { t } = useTranslationApp();

  const signUpCountry = useSelectorApp((state) => state?.shop?.signUpCountry);

  useEffect(() => {
    setFormValues({
      id: mode === ACCOUNT_MODE_CREATE ? "" : data.id,
      name: mode === ACCOUNT_MODE_CREATE ? "" : data.name,
      phone:
        mode === ACCOUNT_MODE_CREATE
          ? ""
          : formatPhoneValueLibrary({
              phone: data?.phone,
              country: signUpCountry,
              locale: LOCALE,
            }),
      email: mode === ACCOUNT_MODE_CREATE ? "" : data.email,
    });
    //eslint-disable-next-line
  }, [mode, data]);

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const onChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!formValues.name || !formValues.phone || !formValues.email) {
      return setError(t(FIELD_ALL_REQUIRED));
    }
    if (!REGEX_EMAIL.test(formValues.email)) {
      return setError(t(FIELD_EMAIL_VALID));
    }
    if (!matchIsValidTel(formValues?.phone?.value)) {
      return setError(t(FIELD_PHONE_VALID));
    }
    setError(null);

    if (mode === ACCOUNT_MODE_CREATE) {
      const response = onCreateContact({
        ...formValues,
        phone: formatPhoneValueLibrary({
          phone: formValues?.phone?.value,
          mode: "save",
        }),
      });
      response && setFormValues(INITIAL_STATE);
    } else {
      const response = onEditContact({
        ...formValues,
        phone: formatPhoneValueLibrary({
          phone: formValues?.phone?.value,
          mode: "save",
        }),
      });
      response && setFormValues(INITIAL_STATE);
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_CONTACT_ARIA_LABEL)}
      title={t(
        mode === "create" ? MODAL_CONTACT_TITLE : MODAL_CONTACT_TITLE_EDIT
      )}
      description={t(
        mode === "create"
          ? MODAL_CONTACT_DESCRIPTION
          : MODAL_CONTACT_DESCRIPTION_EDIT,
        {
          NAME_BUSINESS,
        }
      )}
    >
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              align: "center",
              mb: 1,
            }}
          >
            {error}
          </Alert>
        </Box>
      )}
      <FormContact
        data={formValues}
        onChangeForm={onChange}
        mode={mode}
        signUpCountry={signUpCountry}
      />
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: () => {
              onCloseModal();
            },
          }}
          propsPrimaryButton={{
            showButton: true,
            text: mode === ACCOUNT_MODE_CREATE ? BUTTON_CREATE : BUTTON_SAVE,
            onClick: onSubmit,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalContact;
