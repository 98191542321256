import {
  CHAT_BOT_USER_ID,
  ROLES_DASHBOARD,
  ROLES_SHOPS,
} from "../utils/constants";

export const getChatUnread = ({ chat = {}, shopID, role }) => {
  if (chat?.userID === CHAT_BOT_USER_ID) return false;
  if (chat?.isRead) return false;

  if (
    chat?.from === "help" &&
    !chat?.helpers?.includes(chat?.lastSender) &&
    ROLES_DASHBOARD.includes(role)
  ) {
    return true;
  }

  if (chat?.lastSender !== shopID && ROLES_SHOPS.includes(role)) {
    return true;
  }

  return false;
};
