import React from "react";
import IconButton from "@mui/material/IconButton";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Button from "../Buttons/Button";
import { ALERT_ICON_TYPE_ERROR } from "../../../utils/constants";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  BUTTON_INIT_CHAT,
  ALERT_INACTIVE_SHOP_TITLE,
  ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { getIsBlockedActions } from "../../../actions/getters";
import { SimpleAlert } from "../../../utils/alerts";

const IconChatButton = ({ onClick, color, disabled, sx = {} }) => {
  return (
    <IconButton
      color={color}
      onClick={onClick}
      disabled={disabled}
      sx={{ ...sx }}
    >
      <ChatBubbleIcon />
    </IconButton>
  );
};

const TextChatButton = ({ onClick, textButton, color, disabled, sx = {} }) => {
  const { t } = useTranslationApp();
  return (
    <Button color={color} onClick={onClick} disabled={disabled} sx={sx}>
      {t(textButton)}
    </Button>
  );
};

const InitChatButton = ({
  onClick,
  textButton = BUTTON_INIT_CHAT,
  type = "icon",
  color = "text.primary",
  hasRestrictionShop = false,
  disabled = false,
  sx = {},
}) => {
  const isActiveShop = useSelectorApp((state) => state?.shop?.isActive);
  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onClickButton = () => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedLastInvite: true,
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) return;

    if (hasRestrictionShop && !isActiveShop) {
      SimpleAlert({
        title: t(ALERT_INACTIVE_SHOP_TITLE),
        text: t(ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return;
    }

    onClick();
  };

  return type === "icon" ? (
    <IconChatButton
      onClick={onClickButton}
      color={color}
      disabled={disabled}
      sx={sx}
    />
  ) : (
    <TextChatButton
      onClick={onClickButton}
      textButton={textButton}
      color={color}
      disabled={disabled}
      sx={sx}
    />
  );
};

export default InitChatButton;
