import React from "react";
import Box from "@mui/material/Box";
import { errorsActivateShop } from "../../../utils/errors";
import Typography from "@mui/material/Typography";
import Button from "../Buttons/Button";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED,
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON,
} from "../../../locales/keysTranslations";
import { ThemeProvider, createTheme } from "@mui/material";
import LinkText from "../Texts/LinkText";

const ListErrorsActiveShop = ({
  errors,
  title = ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED,
  themeSettings = {},
}) => {
  const { t } = useTranslationApp();
  const getErrorLink = (error) => {
    if (
      error === "businessName" ||
      error === "address" ||
      error === "logoImg" ||
      error === "coverImg" ||
      error === "category" ||
      error === "subCategories" ||
      error === "shortDescription" ||
      error === "schedule" ||
      error === "profileInfluencer" ||
      error === "automaticInvitations"
    ) {
      return `/account?focus=${error}`;
    }
    if (error === "stores") {
      return `/stores`;
    }
    if (
      error === "defaultPaymentMethod" ||
      error === "hasDebt" ||
      error === "subscriptionPlan"
    ) {
      return `/billing-and-payments`;
    }
  };
  const theme = createTheme({
    ...themeSettings,
  });

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Typography variant="body1" align="center" gutterBottom>
          {t(title)}
        </Typography>
        {errors.map((error, index) => {
          return (
            <Box
              key={error}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                p: "4px",
              }}
            >
              <Typography variant="body1" align="center">
                {t(errorsActivateShop[error])}
              </Typography>
              <LinkText
                target="_top"
                onClick={() =>
                  window.document
                    .querySelector(".swal2-container .swal2-confirm")
                    .click()
                }
                href={getErrorLink(error)}
                sx={{
                  color: "white",
                  "&:hover": {
                    textDecoration: "none",
                    color: "white",
                  },
                }}
              >
                <Button
                  variant="text"
                  color="error"
                  hasShadow={false}
                  sx={{
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    maxHeight: "30px",
                  }}
                >
                  {t(ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON)}
                </Button>
              </LinkText>
            </Box>
          );
        })}
      </Box>
    </ThemeProvider>
  );
};

export default ListErrorsActiveShop;
