import React from "react";
import Accordion from "../../Common/Containers/Accordion";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ContainerKeyValue from "../../Common/Containers/ContainerKeyValue";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CODE,
  COLUMN_NAME_ID,
  CREATOR,
  DATE_LONG,
  INVITATIONS,
  IS_AUTOMATIC,
  NO,
  REPORT_INVITATIONS,
  VALUE,
  VALUE_WITH_CURRENCY,
  WAS_GENERATED,
  YES,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";
import ArchiveIcon from "@mui/icons-material/Archive";

const ShopReportInvites = ({
  data,
  influencers,
  onDeleteInvite,
  onExtendInvite,
  onCreateOrderFromInvite,
  shop,
}) => {
  const { t } = useTranslationApp();
  return (
    <Accordion title={t(REPORT_INVITATIONS)} sx={{}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          maxHeight: 400,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
          <Typography variant="body1">{`${t(INVITATIONS)}: ${
            Object.keys(data).length
          }`}</Typography>
        </Box>
        {Object.keys(data).map((inviteKey) => {
          return (
            <Invite
              key={inviteKey}
              onDeleteInvite={onDeleteInvite}
              onExtendInvite={onExtendInvite}
              onCreateOrderFromInvite={onCreateOrderFromInvite}
              inviteKey={inviteKey}
              data={data[inviteKey]}
              influencer={influencers[data[inviteKey].userID]}
              userID={data[inviteKey].userID}
              shop={shop}
            />
          );
        })}
      </Box>
    </Accordion>
  );
};

const Invite = ({
  data,
  inviteKey,
  influencer,
  onDeleteInvite,
  onExtendInvite,
  onCreateOrderFromInvite,
  userID,
  shop,
}) => {
  const { t } = useTranslationApp();
  const LOCALES = useLocales();
  const LOCALE = LOCALES[shop.signUpCountry];
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <IconButton
            sx={{ p: 0, pb: "4px" }}
            disabled={data?.isExtended ?? false}
            onClick={() => onExtendInvite(inviteKey)}
          >
            <MoreTimeIcon />
          </IconButton>
          <IconButton
            sx={{ p: 0, pb: "4px" }}
            onClick={() => onCreateOrderFromInvite(inviteKey, "active")}
          >
            <ArchiveIcon />
          </IconButton>
          <IconButton
            sx={{ p: 0, pb: "4px" }}
            onClick={() => onDeleteInvite(inviteKey)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <ContainerKeyValue
          keyValue={`${t(COLUMN_NAME_ID)}: `}
          value={inviteKey}
        />
        <ContainerKeyValue
          keyValue={`${t(CREATOR)}: `}
          value={influencer?.mainAccountName}
          redirectURL="influencers-accounts?userID"
          redirectValue={userID}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            value: data.value,
            currency: LOCALE.currency,
          })}`}
        />
        <ContainerKeyValue keyValue={`${t(CODE)}: `} value={data.inviteCode} />
        <ContainerKeyValue
          keyValue={`${t(WAS_GENERATED)}: `}
          value={t(DATE_LONG, {
            date: new Date(data.creationTime),
          })}
        />
        <ContainerKeyValue
          keyValue={`${t(IS_AUTOMATIC)}: `}
          value={data?.isAutomatic ? t(YES) : t(NO)}
        />
      </Box>
    </Box>
  );
};
export default ShopReportInvites;
