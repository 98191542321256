import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import InfluencerNameLogo from "../../../Common/Influencer/InfluencerNameLogo";
import TitlePage from "../../../Common/Texts/TitlePage";
import { APPLICANT_STATUS_DELIVERED } from "../../../../utils/constants";
import Button from "../../../Common/Buttons/Button";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  APPLICANT_CONTENT_OF_CREATOR,
  BUTTON_REVIEW,
} from "../../../../locales/keysTranslations";

const InfoApplicant = ({
  influencer,
  onHeightElementsChange,
  applicant,
  onOpenModalReview,
}) => {
  const refInfo = useRef(null);

  const { t } = useTranslationApp();

  useEffect(() => {
    onHeightElementsChange({
      info: refInfo?.current?.clientHeight,
    });
  }, [refInfo?.current?.clientHeight, onHeightElementsChange]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "fixed",
        left: "auto",
        top: 0,
        zIndex: 1000,
        background: "white",
        mt: {
          xs: "56px",
          sm: "64px",
        },
        pt: 2,
      }}
      ref={refInfo}
    >
      <TitlePage sx={{ mb: 2, mt: 1 }}>
        {t(APPLICANT_CONTENT_OF_CREATOR, {
          influencerName: influencer.mainAccountName,
        })}
      </TitlePage>
      <Box
        sx={{
          display: "flex",
          gap: {
            xs: 0,
            md: 2,
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: "flex-start",
        }}
      >
        <InfluencerNameLogo
          data={influencer}
          variantText="h5"
          sizeType="auto"
          userID={applicant.userID}
        />
        {applicant.status === APPLICANT_STATUS_DELIVERED &&
          !applicant.reviewID && (
            <Button color="error" sx={{ mb: 2 }} onClick={onOpenModalReview}>
              {t(BUTTON_REVIEW)}
            </Button>
          )}
      </Box>
    </Box>
  );
};

export default InfoApplicant;
