import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import HeaderUsersTab from "./HeaderUsersTab";
import UsersTable from "./UsersTable";
import ModalUsers from "./ModalUsers";
import {
  ACCOUNT_MODE_CREATE,
  ACCOUNT_MODE_EDIT,
  ADMIN_ROLE,
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  OPERATOR_ROLE,
} from "../../../../utils/constants";
import {
  createAccountsByAdmin,
  startCreateAccountsByAdmin,
  startDeleteUser,
  startEditUser,
} from "../../../../actions/users";
import {
  SimpleAlert,
  ConfirmAlert,
  AlertInactivePlan,
  TimerAlert,
} from "../../../../utils/alerts";
import { startChangeIsActiveShop } from "../../../../actions/shops";
import { useTour } from "@reactour/tour";
import { getShopID } from "../../../../actions/getters";
import {
  DUMMY_STORE,
  DUMMY_USER,
  DUMMY_USER2,
} from "../../../../onboarding/stepsSelectors";
import {
  BUTTON_DELETE,
  ERROR,
  USERS_EMAIL_DUPLICATE,
  USERS_ONBOARDING_FINISH,
  USERS_USER_CREATED,
  USERS_USER_DELETED,
  USERS_USER_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_USER_DELETE_CONFIRMATION_TITLE,
  USERS_USER_DELETING,
  USERS_USER_SAVED,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const UserTab = ({ loading, usersData, storesList }) => {
  const [usersDataTable, setUsersDataTable] = useState(usersData);
  const [open, setOpenModal] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, setCurrentStep, steps, currentStep, setIsOpen } = useTour();

  const { user, isActivePlan } = useSelectorApp((state) => state.auth);
  const dispatch = useDispatchApp();

  const shopID = dispatch(getShopID());

  const { t } = useTranslationApp();

  useEffect(() => {
    if (isOpen) {
      const step = steps[currentStep];
      if (step.actionID === "createUsers") {
        dispatch(
          createAccountsByAdmin({
            email: "operator@gmail.com",
            name: "operator user",
            role: OPERATOR_ROLE,
            shopID,
            storeID: DUMMY_STORE,
            uid: DUMMY_USER2,
          })
        );
        dispatch(
          createAccountsByAdmin({
            email: "admin@gmail.com",
            name: "admin user",
            role: ADMIN_ROLE,
            shopID,
            storeID: "",
            uid: DUMMY_USER,
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [currentStep, isOpen, steps]);

  useEffect(() => {
    setUsersDataTable(usersData);
  }, [usersData]);

  const onOpenModal = () => {
    if (isOpen) setCurrentStep(currentStep + 1);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setUserSelected(null);
  };

  const onSelectUser = (uid, user) => {
    setUserSelected({ uid, ...user });
    setOpenModal(true);
  };

  const onCreateUser = async (data) => {
    if (!isActivePlan && !isOpen) {
      const result = await AlertInactivePlan();
      if (result.isConfirmed) {
        dispatch(startChangeIsActiveShop(true));
      }
      return false;
    }
    if (
      Object.keys(usersData).some(
        (key) => usersData[key].email === data.email
      ) &&
      !isOpen
    ) {
      SimpleAlert({
        title: t(ERROR),
        text: t(USERS_EMAIL_DUPLICATE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    setIsLoading(true);
    const response = await dispatch(startCreateAccountsByAdmin(data));
    setIsLoading(false);
    if (response) {
      onCloseModal();
      if (isOpen) {
        setCurrentStep(currentStep + 1);
        return response;
      }
      SimpleAlert({
        title: t(USERS_USER_CREATED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onEditUser = async (data) => {
    if (!isActivePlan && !isOpen) {
      const result = await AlertInactivePlan();
      if (result.isConfirmed) {
        dispatch(startChangeIsActiveShop(true));
      }
      return false;
    }
    if (
      Object.keys(usersData).some(
        (key) => usersData[key].email === data.email && key !== data.uid
      ) &&
      !isOpen
    ) {
      SimpleAlert({
        title: t(ERROR),
        text: t(USERS_EMAIL_DUPLICATE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    setIsLoading(true);
    const response = await dispatch(startEditUser(data));
    setIsLoading(false);
    if (response) {
      onCloseModal();
      if (isOpen) {
        setCurrentStep(currentStep + 1);
        return response;
      }
      SimpleAlert({
        title: t(USERS_USER_SAVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();
    }
    return response;
  };

  const onDeleteUser = async (uid) => {
    if (isOpen) {
      const response = await dispatch(startDeleteUser(uid));
      setIsOpen(false);
      SimpleAlert({
        title: t(USERS_USER_DELETED),
        text: t(USERS_ONBOARDING_FINISH),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      return response;
    }
    const result = await ConfirmAlert({
      title: t(USERS_USER_DELETE_CONFIRMATION_TITLE),
      text: t(USERS_USER_DELETE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(USERS_USER_DELETING),
        timer: 8000,
      });
      const response = await dispatch(startDeleteUser(uid));
      if (isOpen) {
        setIsOpen(false);
        SimpleAlert({
          title: t(USERS_USER_DELETED),
          text: t(USERS_ONBOARDING_FINISH),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        return response;
      }
      if (response) {
        SimpleAlert({
          title: t(USERS_USER_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <HeaderUsersTab
        usersData={usersData}
        onChangeUsersDataTable={setUsersDataTable}
        onOpenModal={onOpenModal}
      />
      <UsersTable
        data={usersDataTable}
        loading={loading}
        storesList={storesList}
        onSelectUser={onSelectUser}
        shopID={user.shopID}
        onDeleteUser={onDeleteUser}
      />
      {open && (
        <ModalUsers
          open={open}
          shopID={user.shopID}
          data={userSelected}
          isLoading={isLoading}
          storesList={storesList}
          onCloseModal={onCloseModal}
          onCreateUser={onCreateUser}
          onEditUser={onEditUser}
          mode={!!userSelected ? ACCOUNT_MODE_EDIT : ACCOUNT_MODE_CREATE}
        />
      )}
    </Box>
  );
};

export default UserTab;
