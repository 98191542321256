import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Box from "@mui/material/Box";
import Loader from "../../components/Common/Loader/Loader";
import { getAllDeliveriesOwner } from "../../actions/deliveries";
import DeliveriesOwnerTable from "../../components/Owner/DeliveriesOwner/DeliveriesOwnerTable";
import OrdenModal from "../../components/User/Delivery/OrdenModal";
import { OWNER_ROLE } from "../../utils/constants";
import { startDeleteDelivery } from "../../actions/owner";
import TitlePage from "../../components/Common/Texts/TitlePage";
import {
  DELIVERIES_OWNER_TITLE,
  LOADING_INFORMATION,
} from "../../locales/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import DeliveriesOwnerFiltersType from "../../components/Owner/DeliveriesOwner/DeliveriesOwnerFiltersType";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const DeliveriesOwner = () => {
  const [data, setData] = useState({});
  const [filter, setFilter] = useState(null);
  const [deliverySelected, setDeliverySelected] = useState(null);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const { allStoresDeliveries, isLoading } = useSelectorApp(
    (state) => state.deliveries
  );

  useEffect(() => {
    dispatch(getAllDeliveriesOwner());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(allStoresDeliveries);
    //eslint-disable-next-line
  }, [allStoresDeliveries]);

  const onSelectDelivery = (deliveryID, status) =>
    setDeliverySelected({ deliveryID, status });

  const onDeleteDelivery = (deliveryID) => {
    dispatch(startDeleteDelivery({ deliveryID, data: data[deliveryID] }));
  };

  const onChangeFilter = (filterSelect) => {
    if (filterSelect !== null) {
      if (filterSelect === filter) {
        setFilter(null);
      } else {
        setFilter(filterSelect);
      }
    } else {
      setFilter(filterSelect);
    }
  };

  const onCloseModal = () => setDeliverySelected(null);

  return (
    <ContainerPage>
      <TitlePage>{t(DELIVERIES_OWNER_TITLE)}</TitlePage>
      {isLoading ? (
        <Loader
          size={70}
          hasMessage={true}
          message={t(LOADING_INFORMATION)}
          variantMessage="h5"
          fullWidth={true}
        />
      ) : (
        <Box
          sx={{
            mt: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <DeliveriesOwnerFiltersType
            deliveryType={filter}
            onChangeDeliveryType={onChangeFilter}
          />
          <DeliveriesOwnerTable
            data={data}
            filter={filter}
            onSelectDelivery={onSelectDelivery}
            onDeleteDelivery={onDeleteDelivery}
          />
        </Box>
      )}
      {deliverySelected && (
        <OrdenModal
          data={data[deliverySelected.deliveryID]}
          onCloseModal={onCloseModal}
          type={deliverySelected.status}
          from={OWNER_ROLE}
        />
      )}
    </ContainerPage>
  );
};

export default DeliveriesOwner;
