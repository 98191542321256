import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { collabsFormats } from "../../../utils/constants";
import { numberDecimal } from "../../../utils/numberFormats";
import {
  COLLABORATIONS_SUMMARY,
  FEEDBACK_PUBLICATIONS,
  PENDING_ANALYTICS_PUBLICATIONS,
  PENDING_PUBLICATIONS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LinkText from "../Texts/LinkText";

const ShopSummaryCollabs = ({ data = {} }) => {
  const { t } = useTranslationApp();
  const { collabFormats = {} } = data;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
        }}
      >
        {t(COLLABORATIONS_SUMMARY)}
      </Typography>
      <Box component="ul">
        <Box component="li">
          <Typography variant="body1">
            {t(PENDING_PUBLICATIONS, {
              count: data.totalOrdersPending,
            })}
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1">
            {t(PENDING_ANALYTICS_PUBLICATIONS, {
              count: data.totalOrdersPendingAnalytics,
            })}
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1">
            {t(FEEDBACK_PUBLICATIONS, {
              count: data.totalOrdersFeedback,
            })}
          </Typography>
        </Box>
        {Object.keys(collabFormats).map((key) => {
          if (collabFormats[key] === 0) return null;

          const collabFormat = collabsFormats.find((f) => f.value === key);

          if (key === "unknown" && collabFormats[key] > 0)
            return (
              <Box component="li" key={key}>
                <Typography variant="body1">
                  {`${t(collabFormat.label)}: ${
                    collabFormats[key]
                  } (${numberDecimal(
                    (collabFormats[key] / data.totalCollabs) * 100
                  )}%) `}
                  (
                  {data?.ordersWithFormatUnknown.map((orderID, index) => (
                    <span key={orderID} style={{ wordBreak: "break-all" }}>
                      {index > 0 && ", "}
                      <LinkText
                        href={`/publication?orderID=${orderID}`}
                        target="_blank"
                      >
                        {orderID}
                      </LinkText>
                    </span>
                  ))}
                  )
                </Typography>
              </Box>
            );

          return (
            <Box component="li" key={key}>
              <Typography variant="body1">
                {`${t(collabFormat.label)}: ${
                  collabFormats[key]
                } (${numberDecimal(
                  (collabFormats[key] / data.totalCollabs) * 100
                )}%)`}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShopSummaryCollabs;
