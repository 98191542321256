import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Box from "@mui/material/Box";
import { REGEX_URL } from "../utils/regexsValidation";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CampaignInformationSection from "../components/User/Campaigns/CreateCampaigns/CampaignInformationSection";
import InfluencersInformationSection from "../components/User/Campaigns/CreateCampaigns/InfluencersInformationSection";
import AttachmentsSection from "../components/User/Campaigns/CreateCampaigns/AttachmentsSection";
import { SimpleAlert } from "../utils/alerts";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  startCreateCampaign,
  startInviteInfluencerCampaign,
} from "../actions/campaigns";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DRAFT,
} from "../utils/constants";
import { useForm } from "react-hook-form";
import Button from "../components/Common/Buttons/Button";
import Loader from "../components/Common/Loader/Loader";
import { formatCitiesSave } from "../utils/formats";
import {
  getIsBlockedActions,
  getNameBusinessTheme,
  getShopID,
} from "../actions/getters";
import { useLocale } from "../Contexts/LocaleContext";
import {
  PAGE_CAMPAIGNS_SAVE_PROGRESS_BUTTON,
  PAGE_CAMPAIGNS_SELECT_ANSWER,
} from "../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../lib/i18next";
import {
  BUTTON_SAVE_PROGRESS,
  CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE,
  CREATE_CAMPAIGN_ATTACHMENT_LINK,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED,
  CREATE_CAMPAIGN_BUDGET_MIN_VALUE,
  CREATE_CAMPAIGN_BUDGET_REQUIRED,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS,
  CREATE_CAMPAIGN_DESCRIPTION_REQUIRED,
  CREATE_CAMPAIGN_GOAL_REQUIRED,
  CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE,
  CREATE_CAMPAIGN_HAS_BEEN_SAVE,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED,
  CREATE_CAMPAIGN_NAME_REQUIRED,
  CREATE_CAMPAIGN_PLATFORMS_REQUIRED,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED,
  CREATE_CAMPAIGN_QUESTION,
  CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION,
  CREATE_CAMPAIGN_SAVE_PROGRESS,
  CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED,
  CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED,
  CREATE_CAMPAIGN_TRAFFIC_LINK_VALID,
  CREATE_CAMPAIGN_TYPE_CONTENT,
  CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED,
  ERROR,
  LOADING_INFORMATION,
  QUESTION,
} from "../locales/keysTranslations";
import ModalGenerateScriptAndDescriptionCampaign from "../components/User/Campaigns/CreateCampaigns/ModalGenerateScriptAndDescriptionCampaign";
import LinkText from "../components/Common/Texts/LinkText";
import { getMinValue } from "../services/campaigns";

const answerOrder = {
  1: "name",
  2: "description",
  3: "goal",
  4: "isUgcCampaign",
  5: "amountInfluencers",
  6: "typeInfluencers",
  7: "individualBudget",
  8: "platforms",
  9: "typeContentMedia",
  10: "typeContent",
  11: "copyPost",
  12: "hasCollaboration",
  13: "trafficLink",
  14: "productAttributes",
  15: "messageContent",
  16: "repost",
  17: "audienceLocation",
  18: "categoriesInfluencers",
  19: "ageRange",
  20: "brief",
  21: "attachmentLink",
  22: "ugcContentFormat",
  23: "quantityGender",
  24: "referenceProfiles",
  25: "hashtag",
  26: "messageTone",
  27: "environmentContent",
  28: "colorsContent",
  29: "prohibitedColorsContent",
  30: "keyWords",
  31: "prohibitedWords",
  32: "outfit",
};
const stepsIsNotUgcCampaign = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
];
// remove typeInfluencers, platforms, typeContent, copyPost, hasCollaboration, repost, audienceLocation, referenceProfiles, hashtag
const stepsIsUgcCampaign = [
  1, 2, 3, 4, 5, 7, 9, 13, 14, 15, 18, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30,
  31, 32,
];

const CreateCampaigns = () => {
  const [step, setStep] = useState(1);
  const [visibleSteps, setVisibleSteps] = useState(stepsIsUgcCampaign);
  const [lastStep, setLastStep] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataContext, setDataContext] = useState(null);
  const [statusAction, setStatusAction] = useState(null);
  const { isOpen, currentStep, setCurrentStep } = useTour();

  const stepVisible = visibleSteps.indexOf(step) + 1;
  const currentAnswer = answerOrder[step];

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const userIDToInvite = params.get("userIDToInvite");

  const LOCALE = useLocale();

  const { profileInfluencer } = useSelectorApp((state) => state.shop);
  const shopID = dispatch(getShopID());

  const { control, watch, getValues, setValue } = useForm({
    defaultValues: {
      name: "",
      goal: "",
      individualBudget: LOCALE.minValuePaymentUgcCampaign,
      budget: LOCALE.minValuePaymentUgcCampaign,
      description: "",
      isUgcCampaign: false,
      platforms: {
        instagram: false,
        tiktok: false,
        youtube: false,
      },
      hashtag: "",
      productAttributes: "",
      messageContent: "",
      messageTone: "",
      environmentContent: "",
      colorsContent: "",
      prohibitedColorsContent: "",
      trafficLink: "",
      keyWords: "",
      prohibitedWords: "",
      outfit: "",
      additionalComments: "",
      referenceProfiles: "",
      prohibitedProfiles: "",
      repost: {},
      socialNetworkLink: "",
      typeContent: {
        instagramBurstStories: false,
        instagramPost: false,
        instagramReel: false,
        instagramStory: false,
        tiktokPost: false,
        tiktokStory: false,
        youtubeVideo: false,
        youtubeShort: false,
      },
      typeContentMedia: {
        images: false,
        videos: false,
      },
      ugcContentFormat: "",
      copyPost: "",
      hasCollaboration: false,
      amountInfluencers: "",
      audienceLocation: [],
      typeInfluencers: 5000,
      ageRange: [18, 40],
      categoriesInfluencers: [],
      brief: "",
      attachmentFile: "",
      attachmentLink: "",
      quantityMale: false,
      quantityFemale: false,
    },
  });

  const isUgcCampaign = watch("isUgcCampaign");
  const typeContent = watch("typeContent");
  const typeInfluencers = watch("typeInfluencers");
  const showCopy =
    typeContent.instagramPost ||
    typeContent.instagramReel ||
    typeContent.tiktokPost;
  const showHasCollaboration =
    typeContent.instagramPost || typeContent.instagramReel;

  useEffect(() => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) {
      navigate("/campaigns");
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isUgcCampaign) {
      setVisibleSteps(stepsIsUgcCampaign);
    } else {
      if (showCopy && showHasCollaboration) {
        setVisibleSteps(stepsIsNotUgcCampaign);
      }
      if (showCopy && !showHasCollaboration) {
        //remove copyPost
        setVisibleSteps(stepsIsNotUgcCampaign.filter((step) => step !== 11));
      }
      if (!showCopy && !showHasCollaboration) {
        //remove copyPost and hasCollaboration
        setVisibleSteps(
          stepsIsNotUgcCampaign.filter((step) => step !== 10 && step !== 11)
        );
      }
    }
    if (step !== 1) {
      setLastStep(5);
    }
    // eslint-disable-next-line
  }, [isUgcCampaign, typeContent, showCopy]);

  useEffect(() => {
    profileInfluencer.platforms &&
      setValue("platforms", profileInfluencer.platforms);
    profileInfluencer.formats &&
      setValue("typeContent", profileInfluencer.formats);
    profileInfluencer.typeContent &&
      setValue("typeContentMedia", profileInfluencer.typeContent);
    profileInfluencer.categories &&
      setValue("categoriesInfluencers", profileInfluencer.categories);

    if (profileInfluencer?.budgets && profileInfluencer?.budgets?.length > 0) {
      let minFollowers = parseInt(
        profileInfluencer?.budgets[0]?.audienceRange[0]
      );

      profileInfluencer?.budgets.forEach((budget) => {
        if (parseInt(budget.audienceRange[0]) < minFollowers) {
          minFollowers = parseInt(budget.audienceRange[0]);
        }
      });

      setValue("typeInfluencers", minFollowers);
    } else {
      setValue("typeInfluencers", 5000);
    }

    if (
      profileInfluencer?.ageRange &&
      profileInfluencer?.ageRange?.length > 0
    ) {
      const ageRange = profileInfluencer?.ageRange;
      let minAge;
      let maxAge;

      ageRange.forEach((age) => {
        const ages = age.split("-").map((age) => parseInt(age));

        if (ages.includes(65)) {
          maxAge = 65;
        }

        if (!minAge || ages[0] < minAge) {
          minAge = ages[0];
        }

        if (!maxAge || ages[1] > maxAge) {
          maxAge = ages[1];
        }
      });

      if (minAge && maxAge) {
        setValue("ageRange", [minAge, maxAge]);
      }
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [profileInfluencer]);

  useEffect(() => {
    if (step > lastStep) setLastStep(stepVisible);
    // eslint-disable-next-line
  }, [step]);

  const onPreviousStep = () => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const previousStep = visibleSteps[currentStepPosition - 1];
    setStep(previousStep);
  };

  const onNextStep = (name, value) => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const nextStep = visibleSteps[currentStepPosition + 1];

    const minValue = getMinValue({
      LOCALE,
      isUgcCampaign,
      typeInfluencers,
    });

    switch (name) {
      case "name":
        if (isOpen) {
          if (value.length === 0) {
            setValue("name", t(CREATE_CAMPAIGN_NAME_REQUIRED));
          }
          setStep(nextStep);
          return setCurrentStep(currentStep + 1);
        }
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_NAME_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "description":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_DESCRIPTION_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "goal":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_GOAL_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "amountInfluencers":
        if (!value)
          return setError({
            text: t(CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED),
            step: answerOrder[step],
          });
        if (value < 1)
          return setError({
            text: t(CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE, {
              value: 1,
            }),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "individualBudget":
        if (!value)
          return setError({
            text: t(CREATE_CAMPAIGN_BUDGET_REQUIRED),
            step: answerOrder[step],
          });
        if (value < minValue)
          return setError({
            text: `${t(CREATE_CAMPAIGN_BUDGET_MIN_VALUE, {
              value: minValue,
              currency: "USD",
            })}`,
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "isUgcCampaign":
        if (value === undefined || value === null)
          return setError({
            text: t(CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED),
            step: answerOrder[step],
          });

        if (Number(getValues("individualBudget")) < minValue) {
          setValue("individualBudget", minValue);
        }

        if (isUgcCampaign) {
          setValue("typeInfluencers", 5000);
        }

        setError(null);
        return setStep(nextStep);

      case "typeInfluencers":
        if (Number(getValues("individualBudget")) < minValue) {
          setValue("individualBudget", minValue);
        }

        setError(null);
        return setStep(nextStep);

      case "platforms":
        if (!Object.values(value).includes(true))
          return setError({
            text: t(CREATE_CAMPAIGN_PLATFORMS_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "typeContentMedia":
        if (!Object.values(value).includes(true))
          return setError({
            text: t(CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "typeContent":
        if (!Object.values(value.typeContent).includes(true))
          return setError({
            text: t(CREATE_CAMPAIGN_TYPE_CONTENT),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "trafficLink":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED),
            step: answerOrder[step],
          });
        if (value && !REGEX_URL.test(value)) {
          return setError({
            text: t(CREATE_CAMPAIGN_TRAFFIC_LINK_VALID),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "productAttributes":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "messageContent":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "repost":
        if (
          watch("hasCollaboration") &&
          (!value.instagram || value.instagram.length === 0)
        ) {
          return setError({
            text: t(CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION),
            step: answerOrder[step],
          });
        }
        return setStep(nextStep);

      case "audienceLocation":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      case "categoriesInfluencers":
        if (value.length === 0)
          return setError({
            text: t(CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "attachmentLink":
        if (value && !REGEX_URL.test(value))
          return setError({
            text: t(CREATE_CAMPAIGN_ATTACHMENT_LINK),
            step: answerOrder[step],
          });
        setError(null);
        return setStep(nextStep);

      default:
        return setStep(nextStep);
    }
  };

  const onOpenModalGenerateScriptAndDescription = (status) => {
    const formValues = getValues();
    const citiesFormatted = formatCitiesSave({
      mode: "save",
      cities: formValues.audienceLocation,
      citiesShop: LOCALE.cities,
    });

    setDataContext({
      ...formValues,
      audienceLocation: citiesFormatted,
    });
    setStatusAction(status);
  };

  const onSendInvitationCampaign = async (campaignID) => {
    if (!userIDToInvite) return;

    await dispatch(
      startInviteInfluencerCampaign({
        campaignID,
        userID: userIDToInvite,
      })
    );
    return;
  };

  const onFinish = async ({ descriptionAi, script }) => {
    const response = await dispatch(
      startCreateCampaign(
        {
          ...dataContext,
          descriptionAi,
          script,
        },
        statusAction
      )
    );
    if (response) {
      // setStep(nextStep);
      await onSendInvitationCampaign(response);
      SimpleAlert({
        title:
          statusAction === CAMPAIGN_STATUS_ACTIVE
            ? t(CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE)
            : t(CREATE_CAMPAIGN_HAS_BEEN_SAVE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      navigate(`/campaign?campaignID=${response}`);
    }
  };

  const onSaveProgress = async () => {
    const formValues = getValues();
    if (formValues.name.length === 0)
      return SimpleAlert({
        title: t(ERROR),
        text: t(CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED),
        icon: ALERT_ICON_TYPE_ERROR,
      });

    const citiesFormatted =
      formValues.audienceLocation.length > 0
        ? formatCitiesSave({
            mode: "save",
            cities: formValues.audienceLocation,
            citiesShop: LOCALE.cities,
          })
        : null;

    const response = await dispatch(
      startCreateCampaign(
        {
          ...formValues,
          audienceLocation: citiesFormatted,
        },
        CAMPAIGN_STATUS_DRAFT
      )
    );
    if (response) {
      if (!isOpen) {
        await onSendInvitationCampaign(response);

        SimpleAlert({
          title: t(CREATE_CAMPAIGN_SAVE_PROGRESS),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      } else {
        setCurrentStep(currentStep + 1);
      }
      navigate(`/campaign?campaignID=${response}`);
    }
    return;
  };

  if (loading)
    return (
      <Loader
        size={70}
        fullWidth={true}
        message={t(LOADING_INFORMATION)}
        hasMessage={true}
      />
    );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 80px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pt: 3,
          px: 4,
          gap: 2,
        }}
      >
        <FormControl
          variant="filled"
          fullWidth
          sx={{ maxWidth: 200 }}
          data-tour={PAGE_CAMPAIGNS_SELECT_ANSWER}
        >
          <InputLabel id="select-question">{t(QUESTION)}</InputLabel>
          <Select
            labelId="select-question"
            id="select-question"
            value={stepVisible}
            label={t(QUESTION)}
            onChange={(e) =>
              setStep(visibleSteps[e.target.value - 1] || visibleSteps[0])
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                },
              },
            }}
          >
            {visibleSteps.map((key, item) => {
              if (item + 1 <= lastStep) {
                return (
                  <MenuItem
                    key={key}
                    value={item + 1}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {t(CREATE_CAMPAIGN_QUESTION, {
                      question: `N°${item + 1}`,
                    })}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        </FormControl>
        <Button
          onClick={onSaveProgress}
          dataTour={PAGE_CAMPAIGNS_SAVE_PROGRESS_BUTTON}
        >
          {t(BUTTON_SAVE_PROGRESS)}
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CampaignInformationSection
          step={step}
          currentAnswer={currentAnswer}
          stepVisible={stepVisible}
          error={error}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          watch={watch}
          setValue={setValue}
          control={control}
          getValues={getValues}
        />
        <InfluencersInformationSection
          step={step}
          currentAnswer={currentAnswer}
          stepVisible={stepVisible}
          error={error}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          control={control}
          watch={watch}
          getValues={getValues}
          onGenerateScriptAndDescription={
            onOpenModalGenerateScriptAndDescription
          }
        />
        <AttachmentsSection
          shopID={shopID}
          step={step}
          currentAnswer={currentAnswer}
          stepVisible={stepVisible}
          error={error}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          onGenerateScriptAndDescription={
            onOpenModalGenerateScriptAndDescription
          }
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
      </Box>
      <Typography variant="body1" align="center">
        <LinkText href="https://www.heybuzzly.com/saas-terms" target="_blank">
          {t(CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER, {
            NAME_BUSINESS,
          })}
        </LinkText>
      </Typography>
      {dataContext && (
        <ModalGenerateScriptAndDescriptionCampaign
          modalOpen={Boolean(dataContext)}
          data={dataContext}
          onCallbackSave={onFinish}
        />
      )}
    </Box>
  );
};

export default CreateCampaigns;
