import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTour } from "@reactour/tour";
import {
  BLOCK_PACKAGE_DESCRIPTION,
  BLOCK_PLAN_BUTTON_ACTIVATE_PLAN,
  BLOCK_PAYMENT_FAILED_TITLE,
  BLOCK_TRIAL_END_TITLE,
  MODAL_FREE_TRIAL_TITLE,
  MODAL_FREE_TRIAL_DESCRIPTION,
  MODAL_FREE_TRIAL_BUTTON,
  READY,
  MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION,
  BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE,
  SUPER_ADMIN,
  BLOCK_PACKAGE_TITLE,
  LOGOUT,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import ButtonLarge from "../Buttons/ButtonLarge";
import { startVerifyPaymentsFailed } from "../../../actions/payments";
import Loader from "../Loader/Loader";
import {
  ALERT_ICON_TYPE_SUCCESS,
  BUSINESS_ID_PROJECT,
  OPERATOR_ROLE,
} from "../../../utils/constants";
import {
  startAssignFreeTrial,
  startGetIsFreeTrial,
} from "../../../actions/shops";
import { getNameBusinessTheme, getRole } from "../../../actions/getters";
import { SimpleAlert } from "../../../utils/alerts";
import Button from "../Buttons/Button";
import { startLogout } from "../../../actions/auth";

const BlockPlanNoActive = ({ children, onCallback = () => {}, sx = {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [hasFreeTrial, setHasFreeTrial] = useState(null);
  const [hasPaymentsFailed, setHasPaymentsFailed] = useState(false);

  const {
    isActivePlan,
    user,
    isLoading: isLoadingAuth,
  } = useSelectorApp((state) => state.auth);
  const plan = useSelectorApp((state) => state?.payments?.plan);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const dispatch = useDispatchApp();
  const role = dispatch(getRole());
  const { t } = useTranslationApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { isOpen } = useTour();

  const getInitialData = async () => {
    if (isLoadingAuth) return;

    if (user.role === OPERATOR_ROLE) {
      setIsLoading(false);
      return;
    }

    const hasPaymentsFailed = await dispatch(startVerifyPaymentsFailed());
    if (hasPaymentsFailed) {
      setHasPaymentsFailed(hasPaymentsFailed);
      setIsLoading(false);
      return;
    }

    const isFreeTrial = await dispatch(startGetIsFreeTrial());

    if (typeof isFreeTrial === "boolean") {
      setHasFreeTrial(isFreeTrial);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [isActivePlan, isLoadingAuth]);

  const onActiveFreeTrial = async () => {
    setIsFetching(true);
    const response = await dispatch(startAssignFreeTrial());
    if (response) {
      setHasFreeTrial(true);
      SimpleAlert({
        title: t(READY),
        text: t(MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION, {
          NAME_BUSINESS,
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsFetching(false);
  };

  const getInfoText = () => {
    if (BUSINESS_ID_PROJECT && role !== SUPER_ADMIN) {
      return {
        title: t(BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE),
        description: "",
        button: "",
        action: () => {},
        redirect: "",
      };
    }
    if (hasPaymentsFailed) {
      return {
        title: t(BLOCK_PAYMENT_FAILED_TITLE),
        description: t(BLOCK_PACKAGE_DESCRIPTION),
        button: t(BLOCK_PLAN_BUTTON_ACTIVATE_PLAN),
        action: () => onCallback(),
        redirect: `/billing-and-payments${plan ? "" : "?openPlans=true"}`,
      };
    }
    if (typeof hasFreeTrial === "boolean" && !hasFreeTrial) {
      return {
        title: t(MODAL_FREE_TRIAL_TITLE),
        description: t(MODAL_FREE_TRIAL_DESCRIPTION),
        button: t(MODAL_FREE_TRIAL_BUTTON),
        action: () => onActiveFreeTrial(),
        redirect: "",
      };
    }
    if (typeof hasFreeTrial === "boolean" && hasFreeTrial) {
      return {
        title: t(BLOCK_TRIAL_END_TITLE),
        description: t(BLOCK_PACKAGE_DESCRIPTION),
        button: t(BLOCK_PLAN_BUTTON_ACTIVATE_PLAN),
        action: () => onCallback(),
        redirect: `/billing-and-payments${plan ? "" : "?openPlans=true"}`,
      };
    }
    return {
      title: t(BLOCK_PACKAGE_TITLE),
      description: t(BLOCK_PACKAGE_DESCRIPTION),
      button: t(BLOCK_PLAN_BUTTON_ACTIVATE_PLAN),
      action: () => onCallback(),
      redirect: `/billing-and-payments${plan ? "" : "?openPlans=true"}`,
    };
  };

  const onLogout = () => {
    dispatch(startLogout());
  };

  const infoButton = getInfoText();

  const getTopPosition = () => {
    if (!isMobile) return -64;
    if (isMobile) return -56;
    return 0;
  };
  const getMinHeight = () => {
    return "100vh";
  };

  return (
    <div
      className="information-overlay-parent"
      style={{ position: "relative", ...sx }}
    >
      {children}

      {!isActivePlan && !isOpen && (
        <Box
          sx={{
            position: "absolute",
            top: getTopPosition(),
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(5px)",
            zIndex: 1101,
            minHeight: getMinHeight(),
          }}
        >
          {!isMobile && (
            <Button
              color="secondary"
              hasShadow={false}
              sx={{
                mt: "11px",
                position: "absolute",
                right: 24,
                height: 42,
                maxHeight: 42,
                backgroundColor: "background.paper",
                color: "background.paperContrastText",
                "&:hover": {
                  backgroundColor: "background.paper",
                  color: "background.paperContrastText",
                  filter: "brightness(0.95)",
                },
              }}
              onClick={onLogout}
            >
              {t(LOGOUT)}
            </Button>
          )}
          <Box
            sx={{
              position: "absolute",
              top: plan ? 100 : 150,
              left: 0 > window.innerWidth ? (0 - window.innerWidth) / 2 : "50%",
              transform: "translate(-50%, -50%)",
              padding: "3rem",
              borderRadius: "0.5rem",
              textAlign: "center",
              width: "100%",
              minWidth: 250,
              mt: "20vh",
            }}
          >
            {isLoading && (
              <Loader colorCustom="white" size={40} fullWidth={true} />
            )}
            {!isLoading && (
              <>
                <Typography
                  variant={isMobile ? "h4" : "h3"}
                  sx={{
                    mb: 2,
                    color: "white !important",
                    fontStyle: "normal",
                    fontWeight: "bold",
                  }}
                >
                  {infoButton.title}
                </Typography>
                <Typography
                  variant={isMobile ? "h6" : "h5"}
                  sx={{
                    color: "white !important",
                    fontStyle: "normal",
                    mt: 1,
                    mb: 1,
                  }}
                >
                  {infoButton.description}
                </Typography>
                {infoButton.button && (
                  <Link
                    to={infoButton.redirect}
                    style={{ textDecoration: "none" }}
                  >
                    <ButtonLarge
                      loading={isFetching}
                      onClick={infoButton.action}
                      color="secondary"
                      sx={{ mt: 2 }}
                    >
                      {infoButton.button}
                    </ButtonLarge>
                  </Link>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default BlockPlanNoActive;
