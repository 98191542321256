import { get, onValue, ref } from "firebase/database";
import types from "../types";
import { db } from "../firebase";
import { BUSINESS_ID_PROJECT } from "../utils/constants";

export const setError = (errorText) => ({
  type: types.UI_SET_ERROR,
  payload: errorText,
});

export const clearError = () => ({
  type: types.UI_CLEAR_ERROR,
});

export const setOpenSlideMenu = (open) => ({
  type: types.UI_SET_OPEN_SLIDE_MENU,
  payload: open,
});

export const setOpenModalImage = ({ open, url }) => ({
  type: types.UI_SET_OPEN_MODAL_IMAGE,
  payload: { open, url },
});

export const setActiveSoundDelivery = (state) => ({
  type: types.UI_SET_ACTIVE_SOUND_DELIVERY,
  payload: state,
});

export const setOpenNotification = (state) => ({
  type: types.UI_SET_OPEN_NOTIFICATION,
  payload: {
    open: state.open,
    message: state.message ?? "",
    horizontal: state.horizontal ?? "right",
    vertical: state.vertical ?? "bottom",
    action: state.action,
  },
});

export const setNewOrderInStore = (state) => ({
  type: types.UI_SET_NEW_ORDER_IN_STORE,
  payload: state,
});

export const setShowBackdrop = (state) => ({
  type: types.UI_SET_SHOW_BACKDROP,
  payload: state,
});
export const setShowOpenChatButton = (state) => ({
  type: types.UI_SET_SHOW_OPEN_CHAT_BUTTON,
  payload: state,
});

export const enableMenuWithAction = (data) => ({
  type: types.UI_ENABLE_MENU_WITH_ACTION,
  payload: data,
});
export const disableMenuWithAction = (data) => ({
  type: types.UI_DISABLE_MENU_WITH_ACTION,
  payload: data,
});

export const resetUIReducer = () => ({
  type: types.RESET_UI_STATE,
});

export const startGetVersionWeb = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `versions/web`);
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      dispatch(setVersion(data));
    });
  } catch (error) {
    console.log(error);
    return false;
  }
};
const setVersion = (data) => ({
  type: types.SET_VERSION,
  payload: data,
});

export const setShowModalBlockLastInvite = (open) => ({
  type: types.UI_SHOW_MODAL_BLOCK_LAST_INVITE,
  payload: open,
});
export const setShowModalSelectPackage = (open) => ({
  type: types.UI_SHOW_MODAL_SELECT_PACKAGE,
  payload: open,
});
export const setShowModalOnboardingRequiredInformation = (open) => ({
  type: types.UI_SHOW_MODAL_ONBOARDING_REQUIRED_INFORMATION,
  payload: open,
});

export const startGetThemeBusiness = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `businesses/${BUSINESS_ID_PROJECT}/theme`);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      dispatch(setThemeBusiness(snapshot.val()));
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const setThemeBusiness = (data) => ({
  type: types.UI_SET_THEME_BUSINESS,
  payload: data,
});
