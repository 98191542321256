import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { useDispatchApp } from "../../../lib/redux";
import { startCreateAdsPaymentLink } from "../../../actions/owner";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextFieldMUI from "@mui/material/TextField";
import TextField from "../Fields/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ADS_COINS_PLACEHOLDER,
  ADS_CREDITS_PLACEHOLDER,
  ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION,
  ADVERTISER_PLACEHOLDER,
  BUTTON_CANCEL,
  BUTTON_CREATE,
  DESCRIPTION_PAYMENT_LINK_CLARIFICATION,
  DESCRIPTION_PLACEHOLDER,
  EMPTY_RESULTS_SHOW,
  FIELD_REQUIRED,
  LOADING,
  MODAL_CREATE_PAYMENT_LINK_TITLE,
  MONTHLY,
  PLAN_PLACEHOLDER,
  PRICE_PLACEHOLDER,
  QUARTERLY,
  RECURRENCE_PLACEHOLDER,
  SEMIYEARLY,
  TYPE_LEAST_CHARACTERS,
  VALUE_EXPRESSED_IN,
  YEARLY,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Select from "../Selects/Select";
import { startGetAdsPlans } from "../../../actions/payments";
import { capitalizeFirstLetter } from "../../../utils/formats";
import CurrencyField from "../NumberFormats/CurrencyField";
import NumberField from "../NumberFormats/NumberField";
import _ from "lodash";
import { RECURRENT_MULTIPLIER } from "../../../utils/constants";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { startGetAdvertisersByBusinessName } from "../../../actions/advertisers";
import LinkText from "../Texts/LinkText";
import ButtonCopy from "../Buttons/ButtonCopy";

const ModalStripeCreateAdsPaymentLink = ({
  modalOpen,
  onCloseModal = () => {},
}) => {
  const [adsPlans, setAdsPlans] = useState({});
  const [advertisers, setAdvertisers] = useState({});
  const [isFetchingAdvertisers, setIsFetchingAdvertisers] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [isLoadingAdsPlans, setIsLoadingAdsPlans] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const { control, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues: {
      advertiserID: "",
      planID: "",
      price: 0,
      credits: 0,
      coins: 0,
      recurring: "monthly",
    },
  });
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const recurrent = watch("recurrent");
  const planID = watch("planID");

  const getInitialData = async () => {
    const response = await dispatch(startGetAdsPlans());
    if (response) {
      setAdsPlans(response);
    }
    setIsLoadingAdsPlans(false);
  };

  const getAdvertisersByBusinessName = async (textQuery) => {
    setIsFetchingAdvertisers(true);
    const response = await dispatch(
      startGetAdvertisersByBusinessName(textQuery)
    );
    if (!_.isEmpty(response)) {
      setAdvertisers({
        ...advertisers,
        ...response,
      });
    }
    setIsFetchingAdvertisers(false);
  };

  const getPlansOptions = () => {
    const keys = Object.keys(adsPlans);

    return keys.map((key) => ({
      label: capitalizeFirstLetter(key),
      value: key,
    }));
  };

  const getAdvertisersOptions = () => {
    if (_.isEmpty(advertisers)) return [];

    return Object.keys(advertisers).map((key) => ({
      label: advertisers[key].businessName,
      value: key,
    }));
  };

  const onSubmit = async (data) => {
    setIsFetching(true);
    const response = await dispatch(
      startCreateAdsPaymentLink({
        ...data,
        credits: Number(data.credits),
        coins: Number(data.coins),
      })
    );
    if (response) {
      setPaymentLink(response.paymentLink);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!recurrent || !planID || _.isEmpty(adsPlans)) return;

    const plan = adsPlans?.[planID];
    const planByRecurrent = plan?.[recurrent];

    if (!planByRecurrent) return;

    setValue("price", planByRecurrent.price);

    const benefits = plan.benefits;
    if (!benefits) return;

    const credits = benefits.credits;
    const coins = benefits.coins;
    const multiplier = RECURRENT_MULTIPLIER[recurrent];

    if (credits && multiplier) {
      setValue("credits", credits * multiplier);
    }
    if (coins && multiplier) {
      setValue("coins", coins * multiplier);
    }

    //eslint-disable-next-line
  }, [recurrent, planID, adsPlans]);

  const plansOptions = getPlansOptions();
  const advertisersOptions = getAdvertisersOptions();

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      maxWidth="sm"
      title={t(MODAL_CREATE_PAYMENT_LINK_TITLE)}
    >
      <Box
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Select
          fullWidth
          name="recurrent"
          label={t(RECURRENCE_PLACEHOLDER)}
          variant="filled"
          options={[
            { value: "monthly", label: capitalizeFirstLetter(t(MONTHLY)) },
            { value: "quarterly", label: capitalizeFirstLetter(t(QUARTERLY)) },
            {
              value: "semiyearly",
              label: capitalizeFirstLetter(t(SEMIYEARLY)),
            },
            { value: "yearly", label: capitalizeFirstLetter(t(YEARLY)) },
          ]}
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
        />
        <Select
          fullWidth
          name="planID"
          label={t(PLAN_PLACEHOLDER)}
          variant="filled"
          disabled={isLoadingAdsPlans}
          options={plansOptions}
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
        />
        <TextField
          fullWidth
          name="price"
          label={t(PRICE_PLACEHOLDER)}
          variant="filled"
          InputProps={{
            inputComponent: CurrencyField,
          }}
          helperText={t(VALUE_EXPRESSED_IN, {
            currency: "USD",
          })}
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
            validate: (value) => {
              if (Number(value) < 1) return t(FIELD_REQUIRED);
            },
          }}
        />
        <TextField
          fullWidth
          name="credits"
          label={t(ADS_CREDITS_PLACEHOLDER)}
          variant="filled"
          InputProps={{
            inputComponent: NumberField,
          }}
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
            validate: (value) => {
              if (Number(value) < 1) return t(FIELD_REQUIRED);
            },
          }}
        />
        <TextField
          fullWidth
          name="coins"
          label={t(ADS_COINS_PLACEHOLDER)}
          variant="filled"
          InputProps={{
            inputComponent: NumberField,
          }}
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
            validate: (value) => {
              if (Number(value) < 1) return t(FIELD_REQUIRED);
            },
          }}
        />
        <TextField
          fullWidth
          name="description"
          label={t(DESCRIPTION_PLACEHOLDER)}
          helperText={t(DESCRIPTION_PAYMENT_LINK_CLARIFICATION)}
          multiline
          minRows={2}
          maxRows={2}
          variant="filled"
          control={control}
          errors={errors}
          rules={{
            required: { value: false, message: t(FIELD_REQUIRED) },
          }}
        />
        <Controller
          name="advertiserID"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                if (!value) {
                  field.onChange("");
                  return;
                }
                field.onChange(value?.value);
              }}
              onInputChange={(_, value) => {
                if (value.length > 1) {
                  getAdvertisersByBusinessName(value);
                }
              }}
              noOptionsText={t(
                advertisersOptions.length === 0
                  ? TYPE_LEAST_CHARACTERS
                  : EMPTY_RESULTS_SHOW,
                {
                  value: 2,
                }
              )}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value;
              }}
              getOptionLabel={(option) => {
                if (!option) return "";

                if (option?.label) return option.label;

                const advertiser = advertisers?.[option];
                if (advertiser) return advertiser?.businessName;

                return "";
              }}
              loadingText={t(LOADING)}
              options={advertisersOptions}
              loading={isFetchingAdvertisers}
              renderInput={(params) => {
                const { key: someKey, ...rest } = params;
                return (
                  <TextFieldMUI
                    {...rest}
                    key={someKey}
                    label={t(ADVERTISER_PLACEHOLDER)}
                    variant={"filled"}
                    helperText={
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {t(ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION)}
                        </span>
                      </Box>
                    }
                    sx={{
                      ".MuiFormHelperText-root": {
                        ml: "0 !important",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isFetchingAdvertisers ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
            />
          )}
        />
        {paymentLink && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 1,
              justifyContent: "center",
            }}
          >
            <LinkText href={paymentLink} target="_blank">
              {paymentLink}
            </LinkText>
            <ButtonCopy value={paymentLink} />
          </Box>
        )}
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            type: "button",
            onClick: onCloseModal,
            disabled: isFetching,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_CREATE,
            type: "submit",
            loading: isFetching,
            onClick: () => {},
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalStripeCreateAdsPaymentLink;
