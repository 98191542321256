import React, { useState, useEffect } from "react";
import { useDispatchApp } from "../../../lib/redux";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Loader from "../../Common/Loader/Loader";
import { SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  APPLICANT_PAYMENT_STATUS_PENDING_RELEASE,
} from "../../../utils/constants";
import InfluencerNameLogo from "../../Common/Influencer/InfluencerNameLogo";
import { numberCurrencyFormat } from "../../../utils/numberFormats";
import format from "date-fns/format";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { startGetInfluencer } from "../../../actions/influencers";
import { startGetBusinessNameByOwner } from "../../../actions/shops";

const ModalDetailPaymentApplicant = ({
  paymentData,
  paymentSelect,
  modalOpen,
  onCloseModal,
  isMobile,
  onPayApplicant,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);

  const dispatch = useDispatchApp();

  const getAllData = async () => {
    try {
      const finalData = {};

      const influencer = await dispatch(startGetInfluencer(paymentData.userID));
      if (!influencer) {
        onCloseModal();
        return SimpleAlert({
          title: "Ocurrió un error, intenta de nuevo",
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }

      finalData.influencer = influencer;

      if (paymentData.shopID) {
        const businessName = await dispatch(
          startGetBusinessNameByOwner(paymentData.shopID)
        );
        if (businessName) {
          finalData.shop = { businessName };
        }
      }

      setData(finalData);
      return setLoading(false);
    } catch (error) {
      SimpleAlert({
        title: "Ocurrió un error, intenta de nuevo",
        icon: ALERT_ICON_TYPE_ERROR,
      });
      onCloseModal();
    }
  };

  useEffect(() => {
    getAllData();
    //eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      ariaLabel="Modal Detalle de Pago al Aplicante"
    >
      {loading ? (
        <Loader size={70} />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              mt: 3,
              mb: -1,
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "body1"}
              sx={{ fontWeight: 600 }}
            >
              Creador de Contenido:
            </Typography>
            <InfluencerNameLogo data={data.influencer} />
          </Box>
          <ContainerData
            hasMarginTop={true}
            textLeft="ID pago:"
            textRight={paymentSelect}
            isMobile={isMobile}
            oneColumn={true}
            breakWord={true}
          />
          <ContainerData
            hasMarginTop={true}
            textLeft="Tipo de Pago:"
            textRight={paymentData.from}
            isMobile={isMobile}
          />
          <ContainerData
            textLeft="ID del tipo de Pago:"
            textRight={paymentData.typeID}
            isMobile={isMobile}
            breakWord={true}
            alignTextRight="right"
          />
          {data.shop && (
            <ContainerData
              hasMarginTop={true}
              textLeft="Marca:"
              textRight={data?.shop?.businessName}
              isMobile={isMobile}
            />
          )}
          <ContainerData
            hasMarginTop={true}
            textLeft="Fecha de pago (Marca o Referido):"
            textRight={format(new Date(paymentData.paymentDate), "dd/MM/yyyy")}
            isMobile={isMobile}
          />
          <ContainerData
            hasMarginTop={true}
            textLeft="Saldo total:"
            textRight={`$${numberCurrencyFormat(paymentData.totalAmount)}`}
            isMobile={isMobile}
          />
          <ContainerData
            textLeft="Saldo neto para el creador:"
            textRight={`$${numberCurrencyFormat(paymentData.netAmount)}`}
            isMobile={isMobile}
          />
          <ContainerData
            textLeft="Fecha de pago (Creador):"
            textRight={
              paymentData.paymentReleaseDate
                ? format(new Date(paymentData.paymentReleaseDate), "dd/MM/yyyy")
                : "-"
            }
            isMobile={isMobile}
          />
        </Box>
      )}
      {paymentData.status === APPLICANT_PAYMENT_STATUS_PENDING_RELEASE && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            mb: 3,
            gap: 2,
          }}
        >
          <LoadingButton
            loading={loadingAction}
            disabled={loading}
            color="secondary"
            onClick={async () => {
              setLoadingAction(true);
              await onPayApplicant({
                userID: paymentData.userID,
                paymentID: paymentSelect,
              });
              setLoadingAction(false);
            }}
          >
            Realizar Pago
          </LoadingButton>
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

const ContainerData = ({
  hasMarginTop = false,
  textRight,
  textLeft,
  isMobile,
  oneColumn = false,
  breakWord = false,
  alignTextRight = "unset",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: oneColumn && "column", sm: "row" },
        justifyContent: "space-between",
        mt: hasMarginTop ? 2 : 0,
      }}
    >
      <Typography variant={isMobile ? "h5" : "body1"} sx={{ fontWeight: 600 }}>
        {textLeft}
      </Typography>
      <Typography
        variant={isMobile ? "h5" : "body1"}
        sx={{
          fontWeight: 600,
          wordBreak: breakWord ? "break-word" : "normal",
          textAlign: alignTextRight,
        }}
      >
        {textRight}
      </Typography>
    </Box>
  );
};
export default ModalDetailPaymentApplicant;
