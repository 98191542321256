import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "../../../../Common/Buttons/LoadingButton";
import {
  startGetInfluencer,
  startGetReviewsInfluencer,
} from "../../../../../actions/influencers";
import InfluencerReviews from "../../../../Common/Influencer/InfluencerReviews";
import { getAnalyticsByUser } from "../../../../../utils/socialNetworks";
import ModalPayApplicant from "./ModalPayApplicant";
import Typography from "@mui/material/Typography";
import ModalBasicLayout from "../../../../Common/Modal/ModalBasicLayout";
import { startCreateInvoicePayApplicant } from "../../../../../actions/payments";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  APPLICATION_VALUE,
  BUTTON_ACCEPT,
  BUTTON_ACCEPT_AND_PAY,
  BUTTON_REJECT,
  PAY_INFLUENCER,
  SERVICE_FEE,
  TOTAL_PAYMENT,
  VALUE_WITH_CURRENCY,
} from "../../../../../locales/keysTranslations";
import { FEE_VALUE_CAMPAIGN } from "../../../../../utils/constants";
import InfluencerDetailHorizontal from "../../../../Common/Influencer/InfluencerDetailHorizontal";
import { BOX_SHADOW_ACTIONS_MODAL } from "../../../../../utils/colors";
import { numberDecimal } from "../../../../../utils/numberFormats";

const ModalApplicant = ({
  modalOpen,
  onCloseModal,
  onAcceptApplicant,
  onRejectedApplicant,
  data,
  applicantID,
  campaignID,
}) => {
  const [modalOpenPayApplicant, setModalOpenPayApplicant] = useState(false);
  const [loading, setLoading] = useState(true);
  const [influencer, setInfluencer] = useState({});
  const [reviews, setReviews] = useState(null);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const hasAdvancedCampaignPayments = useSelectorApp(
    (state) => state?.shop?.hasAdvancedCampaignPayments
  );

  useEffect(() => {
    (async () => {
      const influencerData = await dispatch(startGetInfluencer(data.userID));
      const response = await dispatch(startGetReviewsInfluencer(data.userID));
      if (response.ok) {
        setReviews(response.reviews);
      }
      setInfluencer(influencerData);
      setLoading(false);
    })();
    //eslint-disable-next-line
  }, []);

  const onChangeModalOpenPayApplicantStatus = (status) => {
    setModalOpenPayApplicant(status);
  };

  const onCreateInvoiceApplicant = async () => {
    setLoading(true);
    const response = await dispatch(
      startCreateInvoicePayApplicant({
        applicantID,
        campaignID,
      })
    );
    if (response) {
      setLoading(false);
      onCallBackPayApplicant({
        applicantID,
        paymentID: response.paymentID,
      });
      return true;
    }
  };

  const onCallBackPayApplicant = async (data) => {
    onCloseModal();
    await onAcceptApplicant(data);
    return true;
  };

  const FEE_VALUE_CALCULATE = numberDecimal(data.price * FEE_VALUE_CAMPAIGN);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          width: "100%",
        }}
      >
        <InfluencerDetailHorizontal
          analytics={getAnalyticsByUser(influencer)}
          data={influencer}
          userID={data.userID}
          colorButton="primary"
          sx={{ width: "100%" }}
          sxNameLogo={{
            width: "100%",
            justifyContent: "space-between",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <InfluencerReviews data={reviews} />
        </Box>
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 1,
          p: 2,
          boxShadow: BOX_SHADOW_ACTIONS_MODAL,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {hasAdvancedCampaignPayments ? (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ mt: 1, fontWeight: 600 }}
                  >
                    {`${t(PAY_INFLUENCER)}:`}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: data.price,
                      currency: "USD",
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ mt: 1, fontWeight: 600 }}
                  >
                    {`${t(SERVICE_FEE)}:`}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: FEE_VALUE_CALCULATE,
                      currency: "USD",
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ mt: 1, fontWeight: 600 }}
                  >
                    {`${t(TOTAL_PAYMENT)}:`}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: data.price + FEE_VALUE_CALCULATE,
                      currency: "USD",
                    })}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ mt: 1, fontWeight: 600 }}
                  >
                    {`${t(APPLICATION_VALUE)}:`}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: data.price,
                      currency: "USD",
                    })}
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoadingButton
              sx={{
                minWidth: 120,
                width: "100%",
              }}
              loading={loading}
              variant="contained"
              type="button"
              color="primary"
              onClick={async () => {
                const response = await onRejectedApplicant(applicantID);
                if (response) {
                  onCloseModal();
                }
              }}
            >
              {t(BUTTON_REJECT)}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="button"
              color="secondary"
              sx={{
                minWidth: 120,
                width: "100%",
              }}
              onClick={() => {
                hasAdvancedCampaignPayments
                  ? onChangeModalOpenPayApplicantStatus(true)
                  : onCreateInvoiceApplicant();
              }}
            >
              {hasAdvancedCampaignPayments
                ? t(BUTTON_ACCEPT_AND_PAY)
                : t(BUTTON_ACCEPT)}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
      {modalOpenPayApplicant && (
        <ModalPayApplicant
          modalOpen={modalOpenPayApplicant}
          onCloseModal={() => onChangeModalOpenPayApplicantStatus(false)}
          applicantID={applicantID}
          campaignID={campaignID}
          data={{ ...influencer, userID: data.userID }}
          price={data.price}
          callBackPayApplicant={onCallBackPayApplicant}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalApplicant;
