import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import RadioSelectCard from "./RadioSelectCard";
import Typography from "@mui/material/Typography";
import {
  startDeleteStripeSource,
  startGetStripeSources,
  startMakeStripeSourceDefault,
  startSetStripeSourceDefault,
} from "../../../actions/payments";
import Loader from "../Loader/Loader";
import { ConfirmAlert } from "../../../utils/alerts";
import ModalStripeAddCard from "./ModalStripeAddCard";
import StripeSecurePayments from "./StripeSecurePayments";
import StripeInformationCollected from "./StripeInformationCollected";
import NumberField from "../NumberFormats/NumberField";
import {
  ADD_CARD,
  BUTTON_DELETE,
  DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE,
  LOADING_INFORMATION,
  MAIN_PAYMENT_METHOD,
  OTHERS_PAYMENT_METHODS,
  OTHERS_PAYMENT_METHODS_EMPTY,
  PAY,
  PAYMENT_METHODS_EMPTY,
  QUANTITY_TO_PAY,
  SAVE_CONFIGURATION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerModalActions from "../Containers/ContainerModalActions";

const RadioGroupCards = ({
  onSelectCard = () => {},
  amount: amountValue = "",
  onChangeAmount = () => {},
  onCallbackAddPaymentMethod = () => {},
  onCallbackMakeDefaultPaymentMethod = () => {},
  error = null,
  showMakeDefaultPaymentMethod = true,
  showAddPaymentMethod = true,
  isSpecificValue = false,
  sx = {},
  minWidthButtons = 130,
  showCustomButton = false,
  customButtonText = PAY,
  onCallbackCustomButtonText = () => {},
  CustomComponent,
  CustomComponentDetailPayment,
}) => {
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState([]);
  const [amount, setAmount] = useState(amountValue);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalAddCardOpen, setModalAddCardOpen] = useState(false);

  const dispatch = useDispatchApp();

  const { defaultPaymentMethod } = useSelectorApp(
    (state) => state.payments.stripe
  );

  const { t } = useTranslationApp();

  const onChangeCard = (e) => {
    setCard(e.target.value);
    onSelectCard(e.target.value);
  };
  const onChangeStatusModalAddCardOpen = (status) => {
    setModalAddCardOpen(status);
  };

  const getInitialData = async () => {
    const response = await dispatch(startGetStripeSources());
    if (response.ok) {
      if (response.data.length > 0 && defaultPaymentMethod.id.length > 0) {
        const cards = response.data.filter(
          (dataKey) => dataKey.id !== defaultPaymentMethod.id
        );
        setCards([{ ...defaultPaymentMethod }, ...cards]);
        setCard(defaultPaymentMethod.id);
        onSelectCard(defaultPaymentMethod.id);
      }
      setLoadingData(false);
    } else {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const onAddPaymentMethod = async (paymentMethod) => {
    setLoading(true);
    setCards([...cards, paymentMethod]);
    onChangeStatusModalAddCardOpen();
    onCallbackAddPaymentMethod();
    setLoading(false);
  };

  const onDeletePaymentMethod = async (paymentMethodID) => {
    const result = await ConfirmAlert({
      title: t(DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      setLoading(true);
      const response = await dispatch(
        startDeleteStripeSource({ paymentMethodID })
      );
      if (response.ok) {
        const newCardsList = cards.filter(
          (card) => card.id !== paymentMethodID
        );
        setCards([...newCardsList]);
      }
      setLoading(false);
    }
  };

  const onMakeStripeDefaultPaymentMethod = async (paymentMethodID) => {
    setLoading(true);
    const response = await dispatch(
      startMakeStripeSourceDefault({ paymentMethodID })
    );
    if (response.ok) {
      const cardIndex = cards.findIndex((card) => card.id === paymentMethodID);
      const card = cards[cardIndex];

      cards.splice(cardIndex, 1);
      cards.unshift(card);
      setCards([...cards]);
      dispatch(startSetStripeSourceDefault(card));
      onCallbackMakeDefaultPaymentMethod();
    }
    setLoading(false);
  };
  return (
    <Box sx={{ width: "100%", ...sx }}>
      <StripeSecurePayments />
      {loadingData ? (
        <Loader size={70} message={t(LOADING_INFORMATION)} hasMessage={true} />
      ) : cards.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ mb: 1 }}>
          {t(PAYMENT_METHODS_EMPTY)}
        </Typography>
      ) : (
        <>
          {!!error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {isSpecificValue && (
            <Box sx={{ mb: 2 }}>
              <TextField
                value={amount}
                label={t(QUANTITY_TO_PAY)}
                variant="filled"
                fullWidth
                InputProps={{
                  inputComponent: NumberField,
                }}
                onChange={(e) => {
                  setAmount(e.target.value);
                  onChangeAmount(e.target.value);
                }}
              />
            </Box>
          )}
          {CustomComponent && CustomComponent}
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t(MAIN_PAYMENT_METHOD)}
          </Typography>
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="radio-group-cards"
              name="card"
              value={card}
              onChange={onChangeCard}
              sx={{ display: "flex", gap: 2 }}
            >
              <RadioSelectCard
                key={cards[0].id}
                cardInfo={cards[0]}
                cardID={cards[0].id}
              />
              <Typography variant="body1" sx={{ mb: -1 }}>
                {t(OTHERS_PAYMENT_METHODS)}
              </Typography>
              {cards.length > 1 ? (
                cards.map((card, index) => {
                  if (index >= 1) {
                    return (
                      <RadioSelectCard
                        key={card.id}
                        cardInfo={card}
                        cardID={card.id}
                        loading={loading}
                        onDeletePaymentMethod={onDeletePaymentMethod}
                      />
                    );
                  }
                  return null;
                })
              ) : (
                <Typography variant="body1" sx={{ mb: -1 }}>
                  {t(OTHERS_PAYMENT_METHODS_EMPTY)}
                </Typography>
              )}
            </RadioGroup>
          </FormControl>
        </>
      )}
      <StripeInformationCollected align="right" sx={{ mt: 2 }} />

      {!loadingData && (
        <ContainerModalActions
          sx={{
            mt: 2,
          }}
          hasPadding={false}
          propsPrimaryButton={{
            showButton: showMakeDefaultPaymentMethod || showCustomButton,
            text: showMakeDefaultPaymentMethod
              ? SAVE_CONFIGURATION
              : customButtonText,
            onClick: showMakeDefaultPaymentMethod
              ? () => onMakeStripeDefaultPaymentMethod(card, amount)
              : () => onCallbackCustomButtonText(card, amount),
            sx: { minWidth: minWidthButtons },
            disabled: loading,
          }}
          propsSecondaryButton={{
            showButton: showAddPaymentMethod,
            text: ADD_CARD,
            onClick: () => onChangeStatusModalAddCardOpen(true),
            sx: { minWidth: minWidthButtons },
            disabled: loading,
          }}
        />
      )}

      {CustomComponentDetailPayment && CustomComponentDetailPayment}

      {modalAddCardOpen && (
        <ModalStripeAddCard
          modalOpen={modalAddCardOpen}
          onCloseModal={() => onChangeStatusModalAddCardOpen(false)}
          onSubmit={onAddPaymentMethod}
          saveCard={true}
          setDefaultCard={false}
        />
      )}
    </Box>
  );
};

export default RadioGroupCards;
