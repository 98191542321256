import React, { useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "../Buttons/LoadingButton";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import { getNameBusinessTheme } from "../../../actions/getters";

const ContainerModalActions = ({
  propsPrimaryButton = {},
  propsSecondaryButton = {},
  hasPadding = true,
  sx = {},
  sxButtonPrimary = {},
  sxButtonSecondary = {},
}) => {
  const [isLoadingPrimaryButton, setIsLoadingPrimaryButton] = useState(false);
  const [isLoadingSecondaryButton, setIsLoadingSecondaryButton] =
    useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const hasTwoButtons =
    propsPrimaryButton.showButton && propsSecondaryButton.showButton;

  const sxContainerOneButton = {
    justifyContent: "flex-end",
  };
  const sxContainerTwoButtons = {
    justifyContent: "center",
  };
  const sxButtonOneButton = {
    width: "auto",
  };
  const sxButtonTwoButtons = {
    width: "100%",
  };

  const sxContainer = hasTwoButtons
    ? sxContainerTwoButtons
    : sxContainerOneButton;
  const sxButton = hasTwoButtons ? sxButtonTwoButtons : sxButtonOneButton;

  const sxPadding = hasPadding
    ? {
        px: {
          xs: 3,
          sm: 5,
        },
        pb: 2,
      }
    : {};

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        gap: 1,
        ...sxPadding,
        ...sxContainer,
        ...sx,
      }}
    >
      {propsSecondaryButton?.showButton && (
        <LoadingButton
          color="primary"
          {...propsSecondaryButton}
          loading={isLoadingSecondaryButton || propsSecondaryButton?.loading}
          onClick={async () => {
            setIsLoadingSecondaryButton(true);
            await propsSecondaryButton?.onClick();
            setIsLoadingSecondaryButton(false);
          }}
          sx={{
            ...propsSecondaryButton?.sx,
            width: "100%",
            ...sxButton,
            ...sxButtonSecondary,
          }}
        >
          {t(propsSecondaryButton?.text, {
            NAME_BUSINESS,
          })}
        </LoadingButton>
      )}
      {propsPrimaryButton?.showButton && (
        <LoadingButton
          color="secondary"
          {...propsPrimaryButton}
          loading={isLoadingPrimaryButton || propsPrimaryButton?.loading}
          onClick={async (e) => {
            setIsLoadingPrimaryButton(true);
            await propsPrimaryButton?.onClick(e);
            setIsLoadingPrimaryButton(false);
          }}
          sx={{
            ...propsPrimaryButton?.sx,
            width: "100%",
            minWidth: 130,
            ...sxButton,
            ...sxButtonPrimary,
          }}
        >
          {t(propsPrimaryButton?.text, {
            NAME_BUSINESS,
          })}
        </LoadingButton>
      )}
    </Box>
  );
};

export default ContainerModalActions;
