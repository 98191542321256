import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "../../Common/Buttons/Button";
import {
  BUTTON_INVITE_AGAIN,
  FEEDBACK_REASON_ABOUT,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const NegativeReview = ({ order, onClick }) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ width: "100%" }}>
      {order.negativeReview.photoURLs && (
        <Avatar
          variant="rounded"
          src={order.negativeReview.photoURLs[0]}
          sx={{
            width: "100%",
            height: "auto",
            maxWidth: { xs: 400, md: 500 },
            "& .MuiAvatar-img": {
              maxWidth: { xs: 400, md: 500 },
            },
          }}
          alt={t(FEEDBACK_REASON_ABOUT, {
            reason: order?.negativeReview?.categories[0],
          })}
        />
      )}
      {!order.negativeReview.inviteID && (
        <Button color="secondary" sx={{ mt: 3 }} onClick={onClick}>
          {t(BUTTON_INVITE_AGAIN)}
        </Button>
      )}
    </Box>
  );
};

export default NegativeReview;
