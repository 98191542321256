import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import {
  ALERT_ICON_TYPE_SUCCESS,
  STAFF_ROLE,
  CREATE_SHOPS_ADMIN_MODE_CREATE,
  PAYMENT_MODEL_PACKAGE,
  PAYMENT_MODEL_USE,
  PLAN_BASIC,
  PLAN_PRO,
  DEFAULT_PACKAGE_PRICES,
} from "../../../utils/constants";
import { REGEX_EMAIL } from "../../../utils/regexsValidation";
import { SimpleAlert } from "../../../utils/alerts";
import CreateShopForm from "./CreateShopForm";
import { startCreateShop } from "../../../actions/owner";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import {
  getNameBusinessTheme,
  getRole,
  getUserID,
} from "../../../actions/getters";
import {
  BUTTON_CANCEL,
  BUTTON_CREATE,
  BUTTON_SAVE,
  FIELD_ALL_REQUIRED,
  FIELD_STAFF_IS_REQUIRED,
  FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE,
  FIELD_CREDITS_NOT_BE_NEGATIVE,
  FIELD_EMAIL_VALID,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_MIN_LENGTH,
  FIELD_PLAN_PRICES_NOT_BE_NEGATIVE,
  FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE,
  SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL,
  SHOPS_MODAL_CREATE_SHOP_TITLE,
  SHOPS_SHOP_CREATE,
  SHOPS_MODAL_CREATE_SHOP_DESCRIPTION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const ModalShop = ({ modalOpen, onCloseModal, mode, data }) => {
  const dispatch = useDispatchApp();

  const userRole = dispatch(getRole());
  const staffID = dispatch(getUserID());

  const { t } = useTranslationApp();

  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const INITIAL_STATE = {
    businessName: "",
    paymentModel: PAYMENT_MODEL_PACKAGE,
    createUser: true,
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
    hasBillingCycle: "",
    clappCredits: 0,
    costCollaboration: 0,
    trialDays: 0,
    priceBasic: DEFAULT_PACKAGE_PRICES?.[PLAN_BASIC]?.price ?? 149,
    costCollaborationBasic:
      DEFAULT_PACKAGE_PRICES?.[PLAN_BASIC]?.costCollaboration ?? 25,
    pricePro: DEFAULT_PACKAGE_PRICES?.[PLAN_PRO]?.price ?? 249,
    costCollaborationPro:
      DEFAULT_PACKAGE_PRICES?.[PLAN_PRO]?.costCollaboration ?? 20,
    staffID: userRole === STAFF_ROLE ? staffID : "",
  };

  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);

  const onChange = (e) => {
    if (
      e.target.name === "paymentModel" &&
      e.target.value === PAYMENT_MODEL_PACKAGE
    ) {
      return setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        hasBillingCycle: false,
        costCollaboration: "",
      });
    }
    if (
      e.target.name === "paymentModel" &&
      e.target.value === PAYMENT_MODEL_USE
    ) {
      return setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        hasBillingCycle: false,
        costCollaboration: 0,
      });
    }
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onCreateShop = async (shopData) => {
    const response = await dispatch(startCreateShop(shopData));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(SHOPS_SHOP_CREATE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onSubmit = async () => {
    if (
      (formValues.createUser &&
        (!formValues.name ||
          !formValues.email ||
          !formValues.password ||
          !formValues.repeatPassword)) ||
      !formValues.businessName ||
      !formValues.paymentModel ||
      formValues.clappCredits.length === 0 ||
      formValues.trialDays.length === 0 ||
      formValues.priceBasic.length === 0 ||
      formValues.costCollaborationBasic.length === 0
    ) {
      return setError(t(FIELD_ALL_REQUIRED));
    }
    if (formValues.clappCredits < 0) {
      return setError(t(FIELD_CREDITS_NOT_BE_NEGATIVE, { NAME_BUSINESS }));
    }
    if (formValues.costCollaboration < 0) {
      return setError(t(FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE));
    }
    if (formValues.trialDays < 0) {
      return setError(t(FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE));
    }
    if (formValues.priceBasic < 0 || formValues.costCollaborationBasic < 0) {
      return setError(t(FIELD_PLAN_PRICES_NOT_BE_NEGATIVE));
    }
    if (formValues.staffID === "") {
      return setError(t(FIELD_STAFF_IS_REQUIRED));
    }
    if (formValues.createUser && !REGEX_EMAIL.test(formValues.email)) {
      return setError(t(FIELD_EMAIL_VALID));
    }
    if (formValues.createUser && formValues.password.length < 6) {
      return setError(t(FIELD_PASSWORD_MIN_LENGTH));
    }
    if (
      formValues.createUser &&
      formValues.password !== formValues.repeatPassword
    ) {
      return setError(t(FIELD_PASSWORDS_NO_MATCH));
    }

    const response = await onCreateShop(formValues);
    response && setFormValues(INITIAL_STATE);
    setError(null);
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
      ariaLabel={t(SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL)}
      title={t(SHOPS_MODAL_CREATE_SHOP_TITLE)}
      description={t(SHOPS_MODAL_CREATE_SHOP_DESCRIPTION, {
        NAME_BUSINESS,
      })}
    >
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              align: "center",
            }}
          >
            {error}
          </Alert>
        </Box>
      )}
      <CreateShopForm data={formValues} onChangeForm={onChange} />
      <DialogActions sx={{ p: 0 }}>
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text:
              mode === CREATE_SHOPS_ADMIN_MODE_CREATE
                ? BUTTON_CREATE
                : BUTTON_SAVE,
            onClick: onSubmit,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalShop;
