import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const ThemeContainer = ({ children }) => {
  const themeSettings = useSelector((state) => state.ui.theme);
  const theme = createTheme({
    ...themeSettings,
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContainer;
