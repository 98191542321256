import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

const ButtonCopy = ({ value, onClick = () => {} }) => {
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    }
  }, [showCopied]);

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(value);
          setShowCopied(true);
          onClick();
        }}
      >
        {showCopied ? (
          <CheckIcon
            sx={{
              color: "green",
            }}
          />
        ) : (
          <ContentCopyIcon />
        )}
      </IconButton>
    </Box>
  );
};

export default ButtonCopy;
