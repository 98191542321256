import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "./LoadingButton";
import { logoPlatform } from "../../../utils/socialNetworks";
import { BUTTON_UPLOAD_TIKTOK } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { TIKTOK } from "../../../utils/constants";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  startConnectTiktok,
  startGetTokenTiktok,
} from "../../../actions/shops";

const ButtonTikTokAction = ({
  text = BUTTON_UPLOAD_TIKTOK,
  prevBlockClick = false,
  onPrevBlockClick = () => {},
  onClick = () => {},
  showGlobalLoading = false,
  sx = {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [externalPopup, setExternalPopup] = useState(null);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const integrations = useSelectorApp((state) => state?.shop?.integrations);
  const showTiktokOption = integrations?.tiktok?.isActive;

  const onGetTokenTiktok = async ({ code, state }) => {
    setIsLoading(true);
    const response = await dispatch(
      startGetTokenTiktok({ code, state, withAuth: true })
    );

    if (response) {
      await onClick();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      const state = searchParams.get("state");

      if (code && state) {
        externalPopup.close();
        setExternalPopup(null);
        clearInterval(timer);

        onGetTokenTiktok({
          code,
          state,
        });
      }
    }, 500);

    return () => {
      timer && clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [externalPopup]);
  return (
    <LoadingButton
      loading={isLoading}
      color="primary"
      sx={{ width: "100%", ...sx }}
      onClick={async (e) => {
        e.preventDefault();
        if (prevBlockClick) {
          onPrevBlockClick();
          return;
        }

        if (!showTiktokOption) {
          dispatch(
            startConnectTiktok({
              onChangeLoading: (loading) => setIsLoading(loading),
              onChangePopupReference: (popup) => setExternalPopup(popup),
            })
          );
          return;
        }

        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      }}
    >
      <Avatar
        src={logoPlatform(TIKTOK)}
        variant="square"
        sx={{
          width: "20px",
          height: "20px",
          mr: 1,
        }}
      />
      {t(text)}
    </LoadingButton>
  );
};

export default ButtonTikTokAction;
