import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BRAND } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const SelectShops = ({ control }) => {
  const { shops } = useSelectorApp((state) => state.shop);
  const { t } = useTranslationApp();
  return (
    <Controller
      name="shops"
      control={control}
      render={({ field }) => (
        <FormControl fullWidth sx={{ maxWidth: 250 }} variant="filled">
          <InputLabel id="select-shops">{t(BRAND)}</InputLabel>
          <Select
            labelId="select-shops"
            id="select-shops"
            label={t(BRAND)}
            multiple
            renderValue={(selected) => selected.join(", ")}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8 * 2,
                  width: 250,
                },
              },
            }}
            {...field}
          >
            {Object.keys(shops ?? {}).map((shopKey) => {
              return (
                <MenuItem key={shopKey} value={shopKey}>
                  <Checkbox checked={field.value.indexOf(shopKey) > -1} />
                  <ListItemText
                    primary={shops[shopKey].businessName ?? shopKey}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default SelectShops;
