import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import {
  ALERT_DESCRIPTION_DISCONNECT_FACEBOOK,
  ARE_YOU_SURE,
  BUTTON_CONNECT_INSTAGRAM,
  BUTTON_DISCONNECT,
} from "../../../../locales/keysTranslations";
import { logoPlatform } from "../../../../utils/socialNetworks";
import { useTranslationApp } from "../../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { startRevokeFacebookAccount } from "../../../../actions/shops";
import Loader from "../../../Common/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import ButtonMetaAction from "../../../Common/Buttons/ButtonMetaAction";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmAlert } from "../../../../utils/alerts";
import { INSTAGRAM } from "../../../../utils/constants";
import { GRADIENT_INSTAGRAM } from "../../../../utils/colors";
import MetaTextInformation from "../../../Common/Texts/MetaTextInformation";

const FacebookSection = () => {
  const [isFetching, setIsFetching] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const [params] = useSearchParams();
  const focusInstagram = params.get("focusInstagram");
  const instagramLogout = params.get("instagramLogout");

  const { isLoading, integrations } = useSelectorApp((state) => state.shop);
  const facebookIntegrations = integrations?.facebook || {};
  const integrationID = Object.keys(facebookIntegrations)[0];
  const integrationData = facebookIntegrations?.[integrationID] || {};
  const adAccountID = Object.keys(integrationData?.businessAccounts || {})[0];

  const { isActive: isFacebookConnected = false } = integrationData;

  useEffect(() => {
    if (focusInstagram) {
      setTimeout(() => {
        window.document
          .querySelectorAll(".platforms-connect-section")[0]
          .scrollIntoView({
            inline: "nearest",
            behavior: "smooth",
            block: "center",
          });
      }, 300);
    }
    // eslint-disable-next-line
  }, [focusInstagram]);

  useEffect(() => {
    instagramLogout && onRemoveTokenFacebook();
    // eslint-disable-next-line
  }, [instagramLogout]);

  const onRemoveTokenFacebook = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DESCRIPTION_DISCONNECT_FACEBOOK),
      confirmButtonText: t(BUTTON_DISCONNECT),
    });
    if (!result.isConfirmed) return;
    dispatch(
      startRevokeFacebookAccount({
        showGlobalLoading: false,
        onChangeLoading: (loading) => setIsFetching(loading),
        id: integrationID,
      })
    );
  };

  if (isLoading) return <Loader />;

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      {isFacebookConnected ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: 320,
          }}
        >
          <Avatar
            alt={`Logo de ${INSTAGRAM}`}
            src={logoPlatform(INSTAGRAM)}
            variant="square"
            sx={{
              mr: 1,
              height: {
                xs: 30,
                sm: 40,
              },
              width: {
                xs: 30,
                sm: 40,
              },
              "& .MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <MetaTextInformation
              target={"instagram"}
              variant="h6"
              variantMobile="body1"
              integrationID={integrationID}
              adAccountID={adAccountID}
            />
            {isFetching ? (
              <Loader size={20} hasMarginTop={false} />
            ) : (
              <IconButton onClick={onRemoveTokenFacebook}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ) : (
        <ButtonMetaAction
          logo={INSTAGRAM}
          text={BUTTON_CONNECT_INSTAGRAM}
          sx={{
            width: "auto",
            minWidth: 230,
            background: GRADIENT_INSTAGRAM,
          }}
        />
      )}
    </Box>
  );
};

export default FacebookSection;
