import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  EMPTY_TRACKING_IDS_META,
  EMPTY_TRACKING_IDS_TIKTOK,
  FIELD_REQUIRED,
  LOADING,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import { Controller } from "react-hook-form";
import { useDispatchApp } from "../../../lib/redux";
import { useTranslationApp } from "../../../lib/i18next";
import { startGetAdsCampaignTrackingIDs } from "../../../actions/adsCampaigns";
import { META } from "../../../utils/constants";

const SelectTrackingIDField = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled = false,
  placeholder,
  variant = "filled",
  fullWidth,
  helperText,
  platform,
  onLoadOptions = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const getTrackingIDs = async () => {
    setLoading(true);
    const response = await dispatch(
      startGetAdsCampaignTrackingIDs({
        platform,
      })
    );
    if (response) {
      setOptions((prevState) => {
        //filter out the options that are already in the state
        const newOptions = response.filter(
          (option) =>
            !prevState.some((prevOption) => prevOption.name === option.name)
        );

        return [...prevState, ...newOptions];
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getTrackingIDs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onLoadOptions(options);
    // eslint-disable-next-line
  }, [options]);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value) => {
            field.onChange(value.id);
          }}
          noOptionsText={t(
            platform === META
              ? EMPTY_TRACKING_IDS_META
              : EMPTY_TRACKING_IDS_TIKTOK
          )}
          loadingText={t(LOADING)}
          isOptionEqualToValue={(option, value) => option?.id === value}
          getOptionLabel={(option) => {
            const optionValue = options.find((opt) => opt.id === option);
            if (optionValue) {
              return `${optionValue?.name} - ${optionValue?.id}`;
            }
            if (typeof option === "string") {
              return option;
            }
            return `${option?.name} - ${option?.id}`;
          }}
          options={options}
          loading={loading}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start !important",
                }}
                key={option.id || option.name}
              >
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="body2">{`Pixel ID: ${option.id}`}</Typography>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!errors[name]}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {!!errors[name] ? errors[name]?.message : helperText}
                  </span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectTrackingIDField;
