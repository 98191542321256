import React from "react";
import Box from "@mui/material/Box";
const ContainerOverlay = ({ children, sx = {}, direction = "top" }) => {
  return (
    <Box
      className="overlay"
      sx={{
        position: "absolute",
        background: `linear-gradient(to ${direction}, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))`,
        width: "100%",
        zIndex: 800,
        transition: ".3s",
        p: 2,
        height: "85%",
        display: "flex",
        flexDirection: "column",
        justifyContent: direction === "top" ? "flex-end" : "flex-start",
        gap: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerOverlay;
