import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import BackdropMUI from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Backdrop = () => {
  const { showBackdrop } = useSelectorApp((state) => state.ui);
  return (
    <BackdropMUI
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </BackdropMUI>
  );
};

export default Backdrop;
