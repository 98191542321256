import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Box from "@mui/material/Box";
import BillingForm from "../components/User/BillingAndPayments/BillingData/BillingForm";
import { ALERT_ICON_TYPE_SUCCESS } from "../utils/constants";
import { starGetBillingData, startSaveBillingData } from "../actions/shops";
import Loader from "../components/Common/Loader/Loader";
import { SimpleAlert } from "../utils/alerts";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import {
  BILLING_DATA_DESCRIPTION,
  BILLING_DATA_SAVED,
  BILLING_DATA_TITLE,
  LOADING_INFORMATION,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const BillingData = () => {
  const [data, setData] = useState(null);
  const { user } = useSelectorApp((state) => state.auth);
  const { billing, isLoading } = useSelectorApp((state) => state.shop);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  useEffect(() => {
    dispatch(starGetBillingData(user.shopID));
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    setData(billing);
  }, [billing]);

  const onSaveData = async (formValues) => {
    const response = await dispatch(
      startSaveBillingData(user.shopID, formValues)
    );
    if (response) {
      SimpleAlert({
        title: t(BILLING_DATA_SAVED),
        type: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  return (
    <ContainerPage
      sx={{
        mb: 16,
      }}
    >
      <TitlePage>{t(BILLING_DATA_TITLE)}</TitlePage>
      <DescriptionPage>{t(BILLING_DATA_DESCRIPTION)}</DescriptionPage>
      {isLoading || data === null ? (
        <Box sx={{ width: "100%" }}>
          <Loader
            size={70}
            hasMessage={true}
            message={t(LOADING_INFORMATION)}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <BillingForm data={data} onSaveData={onSaveData} />
        </Box>
      )}
    </ContainerPage>
  );
};

export default BillingData;
