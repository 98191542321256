import React from "react";
import AccordionMUI from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";

const Accordion = ({
  children,
  title = "",
  sx,
  sxTitle = {},
  sxContainerTitle = {},
  hasBorder = true,
  variantTitle = "body1",
  initialValue = false,
  actionsComponent,
}) => {
  const theme = useTheme();
  return (
    <AccordionMUI
      defaultExpanded={initialValue}
      sx={{
        border: hasBorder
          ? `${theme.palette.background.paperContrastText} 1px solid`
          : "none",
        ...sx,
      }}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        sx={{
          ...sxContainerTitle,
          ".MuiAccordionSummary-content": {
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            mr: 1,
          },
        }}
      >
        <Typography sx={sxTitle} variant={variantTitle}>
          {title}
        </Typography>
        {actionsComponent && actionsComponent}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionMUI>
  );
};

export default Accordion;
