import React from "react";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { generalCategories } from "../../../../utils/categories";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  optionsProductAvailability,
  optionsYouMeet,
} from "../../../../utils/slidersMarks";
import { REGEX_URL } from "../../../../utils/regexsValidation";
import format from "date-fns/format";
import {
  COLUMN_NAME_ACCEPTED,
  COLUMN_NAME_BRAND,
  COLUMN_NAME_BUDGET_MARKETING,
  COLUMN_NAME_CATEGORY,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_EMPLOYEES,
  COLUMN_NAME_MONTHLY_SALES,
  COLUMN_NAME_NAME,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_PRODUCT_AVAILABILITY,
  COLUMN_NAME_SOCIAL_NETWORK,
  COLUMN_NAME_WEB_SITE,
  COLUMN_NAME_YOU_MEET,
  SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL,
} from "../../../../locales/keysTranslations";
import LinkText from "../../../Common/Texts/LinkText";

const ModalShopAcceptedInfo = ({ modalOpen, onCloseModal, data }) => {
  const { t } = useTranslationApp();

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      title={t(SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL)}
      sxPaper={{
        width: "100%",
      }}
      maxWidth="xs"
      sxContent={{
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>ID:</strong> ${data.shopID}`,
          }}
        />

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_ACCEPTED)}:</strong> ${format(
              new Date(data.creationTime),
              "dd/MM/yyyy"
            )}`,
          }}
        />

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_BRAND)}:</strong> ${
              data.businessName
            }`,
          }}
        />

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_NAME)}:</strong> ${data.name}`,
          }}
        />

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_EMAIL)}:</strong> ${data.email}`,
          }}
        />

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_PHONE)}:</strong> ${data.phone}`,
          }}
        />
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_CATEGORY)}:</strong> ${
              t(
                generalCategories.find((cat) => cat.value === data?.category)
                  .label
              ) ?? "-"
            }`,
          }}
        />

        {data?.monthlySales && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>${t(COLUMN_NAME_MONTHLY_SALES)}:</strong> ${
                data?.monthlySales
              }`,
            }}
          />
        )}

        {data?.budgetMarketing && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>${t(COLUMN_NAME_BUDGET_MARKETING)}:</strong> ${
                data?.budgetMarketing
              }`,
            }}
          />
        )}

        {data?.employees && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>${t(COLUMN_NAME_EMPLOYEES)}:</strong> ${
                data.employees
              }`,
            }}
          />
        )}

        {data?.productAvailability && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>${t(
                COLUMN_NAME_PRODUCT_AVAILABILITY
              )}:</strong> ${
                data?.productAvailability
                  ? t(
                      optionsProductAvailability.find(
                        (c) => c.value === data.productAvailability
                      ).label
                    )
                  : "-"
              }`,
            }}
          />
        )}

        {data?.socialNetwork && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>${t(COLUMN_NAME_SOCIAL_NETWORK)}:</strong> ${
                REGEX_URL.test(data?.socialNetwork) ? (
                  <LinkText
                    href={data?.socialNetwork}
                    target="_blank"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {data?.socialNetwork}
                  </LinkText>
                ) : (
                  `${data?.socialNetwork || "-"}`
                )
              }`,
            }}
          />
        )}

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `<strong>${t(COLUMN_NAME_WEB_SITE)}:</strong> ${
              data?.webSite ? data?.webSite : "-"
            }`,
          }}
        />

        {data?.youMeet && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>${t(COLUMN_NAME_YOU_MEET)}:</strong>  ${
                data?.youMeet
                  ? t(
                      optionsYouMeet.find((c) => c.value === data.youMeet).label
                    )
                  : "-"
              }`,
            }}
          />
        )}
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalShopAcceptedInfo;
