import React from "react";
import Box from "@mui/material/Box";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_FINISHED,
} from "../../../utils/constants";
import Switch from "../../Common/Forms/Switchs/Switch";

const StatusCampaign = ({ status, onChangeStatusCampaign }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        minWidth: 140,
      }}
    >
      <Switch
        sx={{
          ml: -2,
        }}
        hasLabel={false}
        disabled={status === CAMPAIGN_STATUS_FINISHED}
        isActive={status === CAMPAIGN_STATUS_ACTIVE ? true : false}
        onChangeIsActive={onChangeStatusCampaign}
      />
    </Box>
  );
};

export default StatusCampaign;
