import React from "react";
import Box from "@mui/material/Box";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import TitlePage from "../../Common/Texts/TitlePage";
import {
  MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE,
  MODAL_SELECT_PACKAGE_UPLOAD_GROWTH,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const PricingHero = ({ isMobile, isProrated, isOnlyBasicPlan }) => {
  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        pt: 6,
        pb: 6,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        px: {
          md: 4,
          xs: 3,
        },
        background: GRADIENT_PURPLE_FUCHSIA,
      }}
    >
      <Box
        sx={{
          maxWidth: "md",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitlePage
          align="center"
          sx={{
            mt: {
              xs: 1,
              sm: 0,
            },
            color: `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT} !important`,
            textTransform: "none",
          }}
        >
          {isProrated
            ? t(MODAL_SELECT_PACKAGE_UPLOAD_GROWTH)
            : t(MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE)}
        </TitlePage>
      </Box>
    </Box>
  );
};

export default PricingHero;
