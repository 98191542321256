import { getApplicantsByCampaignID } from "../actions/campaigns";
import {
  getNextInfluencers,
  startGetInfluencersByUserIDs,
} from "../actions/influencers";
import { createOrder } from "../actions/orders";
import { saveStores } from "../actions/shops";
import { resetUIReducer } from "../actions/ui";
import {
  MAIN_FUNCTIONALITY_STEP_1,
  MAIN_FUNCTIONALITY_STEP_2,
  MAIN_FUNCTIONALITY_STEP_3,
  MODAL_INVITATION_STEP_1,
  MODAL_INVITATION_STEP_3,
  MODAL_INVITATION_STEP_4,
  MODAL_INVITATION_STEP_5,
  PUBLICATIONS_FUNCTIONALITY_STEP_1,
  PUBLICATIONS_FUNCTIONALITY_STEP_2,
  PUBLICATIONS_FUNCTIONALITY_STEP_3,
  PUBLICATIONS_FUNCTIONALITY_STEP_4,
  PUBLICATION_FUNCTIONALITY_STEP_1,
  PUBLICATION_FUNCTIONALITY_STEP_2,
  PUBLICATION_FUNCTIONALITY_STEP_3,
  REVIEW_FUNCTIONALITY_STEP_1,
  REVIEW_FUNCTIONALITY_STEP_2,
  // REVIEW_FUNCTIONALITY_STEP_3,
  REVIEW_FUNCTIONALITY_STEP_4,
  REVIEW_FUNCTIONALITY_STEP_5,
  MENU_FUNCTIONALITY_STEP_1,
  MENU_FUNCTIONALITY_STEP_2,
  MENU_FUNCTIONALITY_STEP_3,
  MENU_FUNCTIONALITY_STEP_4,
  MENU_FUNCTIONALITY_STEP_5,
  MENU_FUNCTIONALITY_STEP_6,
  MENU_FUNCTIONALITY_STEP_7,
  MENU_FUNCTIONALITY_STEP_8,
  MENU_FUNCTIONALITY_STEP_9,
  MENU_FUNCTIONALITY_STEP_10,
  MENU_FUNCTIONALITY_STEP_11,
  MENU_FUNCTIONALITY_STEP_12,
  USERS_FUNCTIONALITY_STEP_1,
  USERS_FUNCTIONALITY_STEP_2,
  USERS_FUNCTIONALITY_STEP_3,
  USERS_FUNCTIONALITY_STEP_4,
  READY,
  USERS_ONBOARDING_FINISH,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_1,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_2,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_3,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_4,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_5,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_6,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_7,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_8,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_9,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_10,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_11,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_12,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_13,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_14,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_15,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_16,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_17,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_18,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_19,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_20,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_21,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_22,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_23,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_24,
  AMBASSADORS_FUNCTIONALITY_STEP_1,
  AMBASSADORS_FUNCTIONALITY_STEP_2,
  AMBASSADORS_FUNCTIONALITY_STEP_3,
  AMBASSADORS_FUNCTIONALITY_STEP_4,
  AMBASSADORS_FUNCTIONALITY_STEP_5,
  AMBASSADORS_FUNCTIONALITY_STEP_6,
  AMBASSADORS_FUNCTIONALITY_STEP_7,
  AMBASSADORS_FUNCTIONALITY_STEP_8,
  AMBASSADORS_FUNCTIONALITY_STEP_9,
  AMBASSADORS_FUNCTIONALITY_STEP_10,
  AMBASSADORS_FUNCTIONALITY_STEP_11,
  DISCOVERY_FUNCTIONALITY_STEP_1,
  DISCOVERY_FUNCTIONALITY_STEP_2,
  DISCOVERY_FUNCTIONALITY_STEP_3,
  DISCOVERY_FUNCTIONALITY_STEP_4,
  DISCOVERY_FUNCTIONALITY_STEP_5,
  DISCOVERY_FUNCTIONALITY_STEP_6,
  DISCOVERY_FUNCTIONALITY_STEP_7,
  DISCOVERY_FUNCTIONALITY_STEP_8,
  DISCOVERY_FUNCTIONALITY_STEP_9,
  DISCOVERY_FUNCTIONALITY_STEP_10,
  DISCOVERY_FUNCTIONALITY_STEP_11,
  DISCOVERY_FUNCTIONALITY_STEP_12,
  DISCOVERY_FUNCTIONALITY_STEP_13,
  DISCOVERY_FUNCTIONALITY_STEP_14,
  DISCOVERY_FUNCTIONALITY_STEP_15,
  DISCOVERY_FUNCTIONALITY_STEP_16,
  DISCOVERY_FUNCTIONALITY_STEP_17,
  CAMPAIGNS_FUNCTIONALITY_STEP_1,
  CAMPAIGNS_FUNCTIONALITY_STEP_2,
  CAMPAIGNS_FUNCTIONALITY_STEP_3,
  CAMPAIGNS_FUNCTIONALITY_STEP_4,
  CAMPAIGNS_FUNCTIONALITY_STEP_5,
  CAMPAIGNS_FUNCTIONALITY_STEP_6,
  CAMPAIGNS_FUNCTIONALITY_STEP_7,
  CAMPAIGNS_FUNCTIONALITY_STEP_8,
  CAMPAIGNS_FUNCTIONALITY_STEP_9,
  CAMPAIGNS_FUNCTIONALITY_STEP_10,
  CAMPAIGNS_FUNCTIONALITY_STEP_11,
  CAMPAIGNS_FUNCTIONALITY_STEP_12,
  CAMPAIGNS_FUNCTIONALITY_STEP_13,
  CAMPAIGNS_FUNCTIONALITY_STEP_14,
  CAMPAIGNS_FUNCTIONALITY_STEP_15,
  CAMPAIGNS_FUNCTIONALITY_STEP_16,
  CAMPAIGNS_FUNCTIONALITY_STEP_17,
  STORES_FUNCTIONALITY_STEP_1,
  STORES_FUNCTIONALITY_STEP_2,
  STORES_FUNCTIONALITY_STEP_3,
  MAIN_FUNCTIONALITY_STEP_4,
  MODAL_INVITATION_STEP_6,
  MODAL_INVITATION_STEP_7,
  MAIN_FUNCTIONALITY_ONBOARDING_FINISH,
  AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION,
  CAMPAIGN_FINISH_ONBOARDING,
  DISCOVERY_INFLUENCERS_DONE_ONBOARDING,
} from "../locales/keysTranslations";
import {
  ALERT_ICON_TYPE_SUCCESS,
  APPLICANT_STATUS_PENDING,
  INSTAGRAM,
  NAME_BUSINESS,
  POST_STATUS_ANALYTICS,
  POST_STATUS_SENT,
  TAB_SUGGESTED_INDEX,
} from "../utils/constants";
import {
  NAVIGATION_ACTIONS,
  NAVIGATION_PLATFORM,
  NAVIGATION_SETTINGS,
  PAGE_AMBASSADORS,
  PAGE_AMBASSADORS_TABLE,
  PAGE_AMBASSADORS_TABLE_ACTIONS,
  PAGE_AMBASSADORS_TABLE_INVITATION_VALUE,
  PAGE_AMBASSADORS_TABLE_LAST_COLLABORATION,
  PAGE_AMBASSADORS_TABLE_NAME,
  PAGE_AMBASSADORS_TABLE_PLATFORM,
  PAGE_INFLUENCERS,
  PAGE_INFLUENCERS_FILTERS,
  PAGE_INFLUENCERS_SEARCHBAR,
  PAGE_INFLUENCERS_TABLE,
  PAGE_INFLUENCERS_TABLE_AUDIENCE,
  PAGE_INFLUENCERS_TABLE_CATEGORIES,
  PAGE_INFLUENCERS_TABLE_CITY,
  PAGE_INFLUENCERS_TABLE_ENGAGEMENT,
  PAGE_INFLUENCERS_TABLE_LAST_COLLABORATION,
  PAGE_INFLUENCERS_TABLE_NAME,
  PAGE_INFLUENCERS_TABLE_SCORE,
  PAGE_INVITATIONS,
  PAGE_INVITATIONS_INACTIVE_TABLE,
  PAGE_INVITATIONS_INACTIVE_TABLE_CODE,
  PAGE_INVITATIONS_INACTIVE_TABLE_DATE,
  PAGE_INVITATIONS_INACTIVE_TABLE_ID,
  PAGE_INVITATIONS_INACTIVE_TABLE_REASON,
  PAGE_INVITATIONS_INACTIVE_TABLE_TYPE,
  PAGE_INVITATIONS_INACTIVE_TABLE_USER,
  PAGE_INVITATIONS_INACTIVE_TABLE_VALUE,
  PAGE_INVITATIONS_TABLE,
  PAGE_INVITATIONS_TABLE_CODE,
  PAGE_INVITATIONS_TABLE_FROM,
  PAGE_INVITATIONS_TABLE_ID,
  PAGE_INVITATIONS_TABLE_TO,
  PAGE_INVITATIONS_TABLE_USER,
  PAGE_INVITATIONS_TABLE_VALUE,
  PAGE_REQUESTS,
  PAGE_REQUESTS_FILTERS,
  PAGE_REQUESTS_TABLE,
  PLATFORM_AMBASSADORS,
  PLATFORM_INFLUENCERS,
  PLATFORM_INVITATIONS,
  PLATFORM_REQUESTS,
  PLATFORM_PUBLICATIONS,
  PAGE_PUBLICATIONS,
  PAGE_PUBLICATIONS_TABLE,
  PAGE_PUBLICATIONS_TABLE_ID,
  PAGE_PUBLICATIONS_TABLE_STATUS,
  PAGE_PUBLICATIONS_TABLE_USER,
  PAGE_PUBLICATIONS_TABLE_DATE,
  PAGE_PUBLICATIONS_TABLE_VALUE,
  PAGE_PUBLICATIONS_TABLE_ACTIONS,
  PLATFORM_CAMPAIGNS,
  PAGE_CAMPAIGNS,
  PAGE_CAMPAIGNS_SEARCHBAR,
  PAGE_CAMPAIGNS_BUTTON_CREATE_CAMPAIGN,
  PAGE_CAMPAIGNS_TABLE,
  PAGE_CAMPAIGNS_TABLE_ID,
  PAGE_CAMPAIGNS_TABLE_NAME,
  PAGE_CAMPAIGNS_TABLE_PLATFORMS,
  PAGE_CAMPAIGNS_TABLE_STATUS,
  PLATFORM_REPORTS,
  PAGE_REPORTS,
  PAGE_REPORTS_DATES_FORM,
  PLATFORM_GALLERY,
  PLATFORM_EXPLORER,
  PAGE_EXPLORER,
  PAGE_EXPLORER_FILTERS,
  INFLUENCERS_FILTERS,
  BUTTON_INFLUENCER_ACTIONS,
  MODAL_INFLUENCER_ACTIONS_INFORMATION,
  BUTTON_INFLUENCER_VIEW_REPORT,
  PUBLICATION_STATUS,
  PUBLICATION_SOCIAL_SECTION,
  PUBLICATION_ANALYTICS_SECTION,
  REVIEW_BUTTON,
  REVIEW_FORM,
  REVIEW_FORM_SCORE_CONTENT,
  REVIEW_FORM_COMMENT,
  REVIEW_FORM_BUTTON,
  SELECT_STORE_MENU,
  DUMMY_STORE,
  ADD_CATEGORY_MENU,
  ADD_CATEGORY_FIELD,
  NEW_CATEGORY,
  ADD_PRODUCT_MENU,
  ADD_PRODUCT_FORM_IMAGE,
  ADD_PRODUCT_FORM_NAME,
  ADD_PRODUCT_FORM_DESCRIPTION,
  ADD_PRODUCT_FORM_PRICE,
  ADD_PRODUCT_FORM_IS_SENSITIVE,
  ADD_PRODUCT_FORM_CREATE_BUTTON,
  NEW_PRODUCT,
  // ADD_PRODUCT_CATEGORY_OPTION,
  // ADD_PRODUCT_CATEGORY_OPTION_NAME,
  // ADD_PRODUCT_CATEGORY_OPTION_MAX,
  // ADD_PRODUCT_CATEGORY_OPTION_MIN,
  // ADD_PRODUCT_CATEGORY_OPTION_CREATE,
  // NEW_PRODUCT_CATEGORY_OPTION,
  // IS_MANDATORY_CATEGORY_OPTION,
  // ADD_PRODUCT_OPTION,
  // ADD_PRODUCT_OPTION_NAME,
  // ADD_PRODUCT_OPTION_PRICE,
  // ADD_PRODUCT_OPTION_CREATE,
  INACTIVE_PRODUCT,
  // PLATFORM_USERS,
  // CREATE_USER_BUTTON,
  // USER_FORM_NAME,
  // USER_FORM_EMAIL,
  // USER_FORM_ROLE,
  // USER_FORM_STORE,
  // USER_FORM_PASSWORD,
  // USER_FORM_PASSWORD_CONFIRMATION,
  // USER_FORM_CREATE_BUTTON,
  // NEW_USER,
  // EDIT_USER_BUTTON,
  // DELETE_USER_BUTTON,
  // PLATFORM_REDEEM_CODE,
  REDEEM_CODE_SEARCHBAR,
  REDEEM_CODE_TABLE,
  PLATFORM_ORDERS,
  STATUS_IN_PREPARATION,
  STATUS_TO_BE_ACCEPTED,
  STATUS_TO_BE_DELIVERED,
  NEW_ORDER,
  ADMIN_USER_ROW,
  OPERATOR_USER_ROW,
  OPERATOR_USER_ROLE_CELL,
  ADMIN_USER_ROLE_CELL,
  DUMMY_USER,
  ORDER_MODAL_LIST_PRODUCTS,
  ORDER_MODAL_LIST_MESSAGE,
  ORDER_MODAL_LIST_USER,
  ORDER_MODAL_LIST_TOTAL_PRICE,
  ORDER_MODAL_LIST_TIME_TYPE,
  ORDER_MODAL_LIST_TIME,
  ORDER_MODAL_LIST_ACCEPTED_BUTTON,
  ORDER_IN_PREPARATION,
  ORDER_IN_PREPARATION_TIME,
  ORDER_MODAL_DELIVERY_INFO,
  ORDER_MODAL_BUTTON_READY,
  ORDER_TO_BE_DELIVERED,
  ORDER_MODAL_BUTTON_DELIVERED,
  PLATFORM_MENU,
  DUMMY_ORDER,
  DUMMY_INVITE,
  STEP_MAIN_FUNCTIONALITY,
  STEP_AMBASSADORS_FUNCTIONALITY,
  REVIEW_FORM_CONTENT,
  REVIEW_FORM_DO_AMBASSADOR,
  REVIEW_FORM_INVITATION_VALUE,
  REVIEW_FORM_RECURRENT_FIELDS,
  DUMMY_INFLUENCER,
  PAGE_AMBASSADORS_TABLE_ROW,
  AMBASSADORS_PAYMENTS_BUTTON,
  AMBASSADORS_BROADCAST_BUTTON,
  STEP_DISCOVERY_FUNCTIONALITY,
  PAGE_EXPLORER_FILTERS_NAME,
  PAGE_EXPLORER_FILTERS_PLATFORM,
  PAGE_EXPLORER_SEARCH_BUTTON,
  PAGE_EXPLORER_FIRST_ROW,
  PAGE_EXPLORER_ACTION_BUTTON,
  PAGE_EXPLORER_INVITATION_VALUE,
  PAGE_EXPLORER_INVITATION_BUTTON,
  PAGE_EXPLORER_FILTERS_MAX_FOLLOWERS,
  PAGE_EXPLORER_FILTERS_MIN_FOLLOWERS,
  PAGE_EXPLORER_TABLE,
  STEP_CAMPAIGN_FUNCTIONALITY,
  PAGE_CAMPAIGNS_ANSWER_CONTAINER,
  PAGE_CAMPAIGNS_ANSWER_NAME,
  PAGE_CAMPAIGNS_ANSWER_NEXT_BUTTON,
  PAGE_CAMPAIGNS_SELECT_ANSWER,
  PAGE_CAMPAIGNS_SAVE_PROGRESS_BUTTON,
  PAGE_CAMPAIGN_TITLE,
  PAGE_CAMPAIGN_TABLE_APPLICANTS,
  PAGE_CAMPAIGN_TABLE_INVITATIONS,
  // PAGE_CAMPAIGN_TABLE_INVITATIONS_EXTERNALS,
  PAGE_CAMPAIGN_DETAIL_TITLE,
  PAGE_CAMPAIGN_DETAIL_SAVE_BUTTON,
  PAGE_CAMPAIGN_DETAIL_ACTIVE_BUTTON,
  PAGE_CAMPAIGN_DETAIL_FINISHED_BUTTON,
  PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW,
  DUMMY_CAMPAIGN,
  DUMMY_APPLICANT,
  STEP_STORE_FUNCTIONALITY,
  PAGE_STORE,
  PAGE_STORE_BUTTON_CREATE_STORE,
  STORE_FORM,
  STEP_MENU_FUNCTIONALITY,
  INFLUENCERS_TABS,
  MODAL_INFLUENCER_ACTIONS_SECTIONS,
  BUTTON_INVITE_TO_CAMPAIGN,
  BUTTON_INVITE_TO_EXCHANGE,
  BUTTON_SEND_PAYMENT,
  BUTTON_SEND_MESSAGE,
} from "./stepsSelectors";
import { ComponentTranslation } from "../lib/i18next";

export const STEPS_NAVIGATION = [
  {
    selector: `[data-tour="${NAVIGATION_PLATFORM}"]`,
    content: `Esta es la sección de Plataforma, aquí encontrarás las funcionalidades principales de Buzzly. Podrás ver los creadores de contenido, gestionar tus invitaciones, revisar las publicaciones, visualizar tus campañas, obtener estadísticas, generar reportes, entre otras cosas.`,
  },
  {
    selector: `[data-tour="${NAVIGATION_ACTIONS}"]`,
    content: `Esta es la sección de Acciones, encontrarás las acciones poder redimir los códigos de invitación de los creadores que acudan a tu local físico, así como también gestionar las ordenes por delivery que hagan los creadores.`,
  },
  {
    selector: `[data-tour="${NAVIGATION_SETTINGS}"]`,
    content: `Esta es la sección Configuración, aquí podrás registrar los datos de tu marca, configurar tu perfil de creador ideal, crear tus tiendas, configurar tu catálogo/menú, gestionar los usuarios de tu marca y toda tu facturación.`,
  },
];

export const STEPS_INFLUENCERS = [
  {
    selector: `[data-tour="${PLATFORM_INFLUENCERS}"]`,
    content:
      "Este es el botón para navegar hacia la página de Creadores de Contenido.",
    switchRoute: "/creators",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS}"]`,
    content:
      "Esta es la página de Creadores de Contenido, aquí encontrarás el listado de todos los creadores que están registrados en Buzzly.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_SEARCHBAR}"]`,
    content:
      "Con la barra de busqueda podrás filtrar por el nombre de usuario de los creadores de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_FILTERS}"]`,
    content:
      "Con los filtros podrán buscar creadores de contenido por sus categorías, ubicación, número de seguidores y porcentaje de engagement de su audiencia.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE}"]`,
    content:
      "En esta tabla aparecerán los resultados de la búsqueda de creadores de contenido mostrando toda la siguiente información de cada uno.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_NAME}"]`,
    content: "Nombre de usuario del creador de contenido",
  },
  //   {
  //     selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_BUTTON_INVITE}"]`,
  //     content:
  //       "Botón que muestra el estado del creador, podrás invitar al creador de contenido o si ya tiene una invitación podrás editarla.",
  //   },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_CITY}"]`,
    content: "La ciudad en la que se encuentra el creador de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_AUDIENCE}"]`,
    content: "El número de seguidores que tiene el creador de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_ENGAGEMENT}"]`,
    content:
      "El porcentaje de engagement de la audiencia del creador de contenido",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_CATEGORIES}"]`,
    content: "Las categorías del creador de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_SCORE}"]`,
    content:
      "La puntuación Buzzly es un indicador de calidad del creador de contenido que se calcula con base a las reviews que han hecho otras marcas al trabajar con este creador de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INFLUENCERS_TABLE_LAST_COLLABORATION}"]`,
    content:
      "Fecha y enlace a la última colaboración que tuviste con este creador de contenido.",
    switchRoute: "/creators",
  },
];

export const STEPS_AMBASSADORS = [
  {
    selector: `[data-tour="${PLATFORM_AMBASSADORS}"]`,
    content: "Este es el botón para navegar hacia la página de Embajadores.",
    switchRoute: "/ambassadors",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS}"]`,
    content:
      "Esta es la página de Embajadores, los embajadores son creadores de contenido que escoges luego de hacer una colaboración y quieres seguir trabajando con ellos de una manera más cercana.",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE}"]`,
    content:
      "En esta tabla encontrarás el listado de todos los embajadores de tu marca.",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE_NAME}"]`,
    content: "El nombre de usuario del embajador.",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE_PLATFORM}"]`,
    content: "La red social del embajador.",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE_INVITATION_VALUE}"]`,
    content: "El valor de la invitación recurrente para el embajador.",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE_LAST_COLLABORATION}"]`,
    content: "La última colaboración que hiciste con el embajador.",
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE_ACTIONS}"]`,
    content:
      "Las acciones que puedes hacer con cada embajador, puedes iniciar un chat con el embajador, editar la información de su invitación o eliminarlo.",
    switchRoute: "/ambassadors",
  },
];

export const STEPS_REQUESTS = [
  {
    selector: `[data-tour="${PLATFORM_REQUESTS}"]`,
    content: "Este es el botón para navegar hacia la página de Solicitudes.",
    switchRoute: "/requests-influencers",
  },
  {
    selector: `[data-tour="${PAGE_REQUESTS}"]`,
    content:
      "Esta es la página de Solicitudes, las solicitudes son las peticiones por parte de los creadores de contenido para que los invites a colaborar con tu marca.",
  },
  {
    selector: `[data-tour="${PAGE_REQUESTS_FILTERS}"]`,
    content:
      "Con los filtros podrán buscar las solicitudes de creadores de contenido filtradas por sus categorías, ubicación, número de seguidores y porcentaje de engagement de su audiencia.",
  },
  {
    selector: `[data-tour="${PAGE_REQUESTS_TABLE}"]`,
    content:
      "En esta tabla aparecerán los resultados de la búsqueda de las solicitudes de creadores de contenido, que son los mismos que están en la página de Creadores de Contenido.",
    switchRoute: "/requests-influencers",
  },
  //   {
  //     selector: `[data-tour="${PAGE_REQUESTS_TABLE_SHOW_REQUEST}"]`,
  //     content:
  //       "Botón que muestra el estado de la solicitud, podrás aceptar o rechazar la solicitud del creador de contenido.",
  //     switchRoute: "/requests-influencers",
  //   },
];

export const STEPS_INVITATIONS = [
  {
    selector: `[data-tour="${PLATFORM_INVITATIONS}"]`,
    content: "Este es el botón para navegar hacia la página de Invitaciones.",
    switchRoute: "/invitations",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS}"]`,
    content:
      "Esta es la página de Invitaciones, aquí podrás ver el listado y gestionar las invitaciones que has hecho a los creadores de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE}"]`,
    content:
      "En esta tabla aparecerán los resultados de las invitaciones activas que has hecho a los creadores de contenido.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE_ID}"]`,
    content: "El ID de la invitación.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE_USER}"]`,
    content: "El creador de contenido al que invitaste.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE_CODE}"]`,
    content: "El código de la invitación que usará el creador para redimirla.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE_VALUE}"]`,
    content: "El valor de la invitación.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE_FROM}"]`,
    content:
      "La fecha desde la cual el creador de contenido puede redimir la invitación.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_TABLE_TO}"]`,
    content:
      "La fecha hasta la cual el creador de contenido puede redimir la invitación.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE}"]`,
    content:
      "En esta tabla aparecerán los resultados de las invitaciones inactivas que se hicieron en algún momento.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_ID}"]`,
    content: "El ID de la invitación inactive.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_USER}"]`,
    content: "El creador de contenido al que invitaste.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_CODE}"]`,
    content: "El código de la invitación que usó el creador para redimirla.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_VALUE}"]`,
    content: "El valor de la invitación.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_TYPE}"]`,
    content:
      "El tipo de invitación puede ser Redimida si el creador la usó, Expirada si no la usó o Rechazada si por alguna razón el creador la rechazó.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_REASON}"]`,
    content: "La razón por la cual el creador rechazó la invitación.",
  },
  {
    selector: `[data-tour="${PAGE_INVITATIONS_INACTIVE_TABLE_DATE}"]`,
    content:
      "La fecha en la que la invitación expiró o se redimió, esto dependerá del tipo de invitación.",
    switchRoute: "/invitations",
  },
];

export const STEPS_PUBLICATIONS = [
  {
    selector: `[data-tour="${PLATFORM_PUBLICATIONS}"]`,
    content: "Este es el botón para navegar hacia la página de Publicaciones.",
    switchRoute: "/publications",
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS}"]`,
    content:
      "Esta es la página de Publicaciones, aquí podrás ver el listado de las  publicaciones/colaboraciones que has hecho con los creadores de contenido que invitaste.",
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE}"]`,
    content:
      "En esta tabla aparecerán los resultados de las publicaciones/colaboraciones que has hecho con los creadores de contenido que invitaste.",
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE_ID}"]`,
    content: "El ID de la publicación.",
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE_STATUS}"]`,
    content: `El estado de la publicación, que pueden ser los siguientes:\n
    - Pendiente: El creador no ha publicado ningún contenido.\n
    - Pendiente de Analítica: El creador publicó el contenido pero hace falta la analítica.\n
    - Verificar Publicación: El creador ya subió el contenido y la analítica, ahora debes revisarlos, darle una calificación al creador y si quieres hacerlo embajador.\n
    - Publicación Verificada: Son las publicaciones que ya revisaste y calificaste.\n
    - Feedback: Son las publicaciones en las algo salió mal para el creador con la colaboración con tu marca, puedes ver la razón y si deseas puedes volver a invitarlo.
    `,
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE_USER}"]`,
    content: `El creador de contenido con que hizo la publicación.`,
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE_DATE}"]`,
    content: `La fecha en la que se redimió la invitación y se creó la orden.`,
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE_VALUE}"]`,
    content: `El valor de la orden.`,
  },
  {
    selector: `[data-tour="${PAGE_PUBLICATIONS_TABLE_ACTIONS}"]`,
    content:
      "Las acciones que puedes hacer con las publicaciones, puedes iniciar un chat con los creadores que hicieron una publicación y esta se encuentra en el estado de Pendiente o Pendiente de Analítica.",
    switchRoute: "/publications",
  },
];

export const STEPS_CAMPAIGNS = [
  {
    selector: `[data-tour="${PLATFORM_CAMPAIGNS}"]`,
    content: "Este es el botón para navegar hacia la página de Campañas.",
    switchRoute: "/campaigns",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS}"]`,
    content:
      "Esta es la página de Campañas, aquí podrás ver el listado de las campañas que has creado.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_SEARCHBAR}"]`,
    content:
      "Con la barra de busqueda podrás filtrar por el nombre de las campañas.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_BUTTON_CREATE_CAMPAIGN}"]`,
    content: "Con este botón podrás crear una nueva campaña.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_TABLE}"]`,
    content:
      "En esta tabla aparecerán los resultados de las campañas que has creado.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_TABLE_ID}"]`,
    content: "El ID de la campaña.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_TABLE_NAME}"]`,
    content: "El nombre de la campaña.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_TABLE_PLATFORMS}"]`,
    content: "Las plataformas en las que se va a realizar la campaña.",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_TABLE_STATUS}"]`,
    content: `El estado de la campaña, que pueden ser los siguientes:\n
            - Desactivada: la campaña no está activa, no le aparecerá a los creadores, puedes seguir editando los datos de esta campaña.\n
            - Activa: la campaña está activa, le aparecerá a los creadores, puedes seguir editando los datos de esta campaña.\n`,
  },
];

export const STEPS_REPORTS = [
  {
    selector: `[data-tour="${PLATFORM_REPORTS}"]`,
    content: "Este es el botón para navegar hacia la página de Reportes.",
    switchRoute: "/reports",
  },
  {
    selector: `[data-tour="${PAGE_REPORTS}"]`,
    content:
      "Esta es la página de Reportes, aquí podrás generar reportes en un intervalo de fechas.",
  },
  {
    selector: `[data-tour="${PAGE_REPORTS_DATES_FORM}"]`,
    content:
      "Puedes seleccionar intervalo de fechas para el reporte y calcularlo.",
    switchRoute: "/reports",
  },
];

export const STEPS_GALLERY = [
  {
    selector: `[data-tour="${PLATFORM_GALLERY}"]`,
    content:
      "Este es el botón para mostrar tu galería de contenido que han subido los creadores por medio de tus colaboraciones.",
  },
];

export const STEPS_EXPLORER = [
  {
    selector: `[data-tour="${PLATFORM_EXPLORER}"]`,
    content: "Este es el botón para navegar hacia la página de Explorar.",
    switchRoute: "/discovery-influencers",
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER}"]`,
    content:
      "Esta es la página de Explorar, buscar creadores de contenidos por diferentes paramétros y que pueden que no esté registrado en Buzzly.",
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS}"]`,
    content:
      "Con los filtros podrás buscar creadores de contenido por sus categorías, ubicación, número de seguidores, porcentaje de engagement de su audiencia, género, intereses, edad, keywords, etc.",
    switchRoute: "/discovery-influencers",
  },
];

const MAIN_FUNCTIONALITY_SIMILAR_DATA = {
  onboardingName: STEP_MAIN_FUNCTIONALITY,
  alert: {
    title: READY,
    text: MAIN_FUNCTIONALITY_ONBOARDING_FINISH,
    icon: ALERT_ICON_TYPE_SUCCESS,
  },
  switchRouterOnClose: `/creators?tab=${TAB_SUGGESTED_INDEX}`,
};

export const STEPS_MAIN_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PLATFORM_INFLUENCERS}"]`,
    content: (
      <ComponentTranslation keyTranslation={MAIN_FUNCTIONALITY_STEP_1} />
    ),
    switchRoute: "/creators?tab=0",
    mutationObservables: [`[data-tour="${PLATFORM_INFLUENCERS}"]`],
    resizeObservables: [`[data-tour="${PLATFORM_INFLUENCERS}"]`],
    position: "right",
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${INFLUENCERS_FILTERS}"]`,
    content: (
      <ComponentTranslation keyTranslation={MAIN_FUNCTIONALITY_STEP_2} />
    ),
    switchRoute: "/creators?tab=0",
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${INFLUENCERS_TABS}"]`,
    content: (
      <ComponentTranslation keyTranslation={MAIN_FUNCTIONALITY_STEP_3} />
    ),
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${BUTTON_INFLUENCER_ACTIONS}"]`,
    content: (
      <ComponentTranslation keyTranslation={MAIN_FUNCTIONALITY_STEP_4} />
    ),
    resizeObservables: [`[data-tour="${BUTTON_INFLUENCER_ACTIONS}"]`],
    mutationObservables: [`[data-tour="${BUTTON_INFLUENCER_ACTIONS}"]`],
    highlightedSelectors: [`[data-tour="${BUTTON_INFLUENCER_ACTIONS}"]`],
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
];

export const STEPS_MODAL_INVITATION = [
  {
    selector: `[data-tour="${MODAL_INFLUENCER_ACTIONS_INFORMATION}"]`,
    content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_1} />,
    resizeObservables: [
      `[data-tour="${BUTTON_INFLUENCER_VIEW_REPORT}"]`,
      `[data-tour="${MODAL_INFLUENCER_ACTIONS_INFORMATION}"]`,
    ],
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  // {
  //   selector: `[data-tour="${BUTTON_INFLUENCER_VIEW_REPORT}"]`,
  //   content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_2} />,
  //   ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  // },
  {
    selector: `[data-tour="${MODAL_INFLUENCER_ACTIONS_SECTIONS}"]`,
    content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_3} />,
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${BUTTON_INVITE_TO_CAMPAIGN}"]`,
    content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_4} />,
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${BUTTON_INVITE_TO_EXCHANGE}"]`,
    content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_5} />,
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${BUTTON_SEND_PAYMENT}"]`,
    content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_6} />,
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${BUTTON_SEND_MESSAGE}"]`,
    content: <ComponentTranslation keyTranslation={MODAL_INVITATION_STEP_7} />,
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
  },
  {
    ...MAIN_FUNCTIONALITY_SIMILAR_DATA,
    isFinished: true,
    disablePrev: true,
    disableNext: true,
    switchRouterOnClose: `/creators?tab=${TAB_SUGGESTED_INDEX}`,
  },
];

export const STEPS_PUBLICATIONS_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PLATFORM_PUBLICATIONS}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={PUBLICATIONS_FUNCTIONALITY_STEP_1}
      />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
    switchRoute: "/publications",
    position: "right",
    actionData: "create",
  },
  {
    selector: `[data-tour="${PUBLICATION_STATUS}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={PUBLICATIONS_FUNCTIONALITY_STEP_2}
      />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
  },
  {
    selector: `[data-tour="${PUBLICATION_STATUS}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={PUBLICATIONS_FUNCTIONALITY_STEP_3}
      />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
    actionData: "edit",
    data: {
      postStatus: POST_STATUS_ANALYTICS,
      posts: [
        "https://clapp-prod.s3.us-east-1.amazonaws.com/orders/-NTUH9SErTEfHpVAfZ9x/content/0f624df3-a557-4ba0-b88d-9e13daedd08c.quicktime",
      ],
    },
  },
  {
    selector: `[data-tour="${PUBLICATION_STATUS}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={PUBLICATIONS_FUNCTIONALITY_STEP_4}
      />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
    actionData: "edit",
    data: {
      postStatus: POST_STATUS_SENT,
      analytics: [
        "https://clapp-prod.s3.us-east-1.amazonaws.com/orders/-NTUH9SErTEfHpVAfZ9x/content/2a284fae-f0cb-4604-b4ce-3d5a3a77781f.png",
        "https://clapp-prod.s3.us-east-1.amazonaws.com/orders/-NTUH9SErTEfHpVAfZ9x/content/5003f1a3-7a10-401f-a398-2c2c93ad59ac.png",
      ],
    },
  },
];

export const STEPS_PUBLICATION_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PUBLICATION_ANALYTICS_SECTION}"]`,
    content: (
      <ComponentTranslation keyTranslation={PUBLICATION_FUNCTIONALITY_STEP_1} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
    highlightedSelectors: [`[data-tour="${PUBLICATION_ANALYTICS_SECTION}"]`],
    mutationObservables: [`[data-tour="${PUBLICATION_ANALYTICS_SECTION}"]`],
    resizeObservables: [`[data-tour="${PUBLICATION_ANALYTICS_SECTION}"]`],
  },
  {
    selector: `[data-tour="${PUBLICATION_SOCIAL_SECTION}"]`,
    content: (
      <ComponentTranslation keyTranslation={PUBLICATION_FUNCTIONALITY_STEP_2} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
    highlightedSelectors: [`[data-tour="${PUBLICATION_SOCIAL_SECTION}"]`],
    mutationObservables: [`[data-tour="${PUBLICATION_SOCIAL_SECTION}"]`],
    resizeObservables: [`[data-tour="${PUBLICATION_SOCIAL_SECTION}"]`],
  },
  {
    selector: `[data-tour="${REVIEW_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={PUBLICATION_FUNCTIONALITY_STEP_3} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
  },
];

export const STEPS_REVIEW_FUNCTIONALITY = [
  {
    selector: `[data-tour="${REVIEW_FORM}"]`,
    content: (
      <ComponentTranslation keyTranslation={REVIEW_FUNCTIONALITY_STEP_1} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_SCORE_CONTENT}"]`,
    content: (
      <ComponentTranslation keyTranslation={REVIEW_FUNCTIONALITY_STEP_2} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
  },
  // {
  //   selector: `[data-tour="${REVIEW_FORM_SCORE_ANALYTICS}"]`,
  //   content: (
  //     <ComponentTranslation keyTranslation={REVIEW_FUNCTIONALITY_STEP_3} />
  //   ),
  //   onboardingName: STEP_MAIN_FUNCTIONALITY,
  // },
  {
    selector: `[data-tour="${REVIEW_FORM_COMMENT}"]`,
    content: (
      <ComponentTranslation keyTranslation={REVIEW_FUNCTIONALITY_STEP_4} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={REVIEW_FUNCTIONALITY_STEP_5} />
    ),
    onboardingName: STEP_MAIN_FUNCTIONALITY,
  },
];

const MENU_SIMILAR_DATA = {
  onboardingName: STEP_MENU_FUNCTIONALITY,
  switchRouterOnClose: "/menu",
};
export const STEPS_MENU_FUNCTIONALITY = [
  {
    selector: `[data-tour="${SELECT_STORE_MENU}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_1} />
    ),
    action: saveStores({
      [DUMMY_STORE]: {
        name: "Tienda de prueba",
        description: "Esta es una tienda de prueba",
        storeType: "regular",
      },
    }),
    actionID: "selectStore",
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_CATEGORY_MENU}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_2} />
    ),
    highlightedSelectors: [`[data-tour="${ADD_CATEGORY_FIELD}"]`],
    mutationObservables: [`[data-tour="${ADD_CATEGORY_FIELD}"]`],
    disableNext: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${NEW_CATEGORY}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_3} />
    ),
    actionID: "selectCategory",
    highlightedSelectors: [`[data-tour="${NEW_CATEGORY}"]`],
    resizeObservables: [`[data-tour="${NEW_CATEGORY}"]`],
    mutationObservables: [`[data-tour="${NEW_CATEGORY}"]`],
    disablePrev: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_MENU}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_4} />
    ),
    actionID: "showProductForm",
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_FORM_IMAGE}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_5} />
    ),
    highlightedSelectors: [`[data-tour="${ADD_PRODUCT_FORM_IMAGE}"]`],
    mutationObservables: [`[data-tour="${ADD_PRODUCT_FORM_IMAGE}"]`],
    resizeObservables: [`[data-tour="${ADD_PRODUCT_FORM_IMAGE}"]`],
    disableNext: true,
    disablePrev: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_FORM_NAME}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_6} />
    ),
    disableNext: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_FORM_DESCRIPTION}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_7} />
    ),
    disableNext: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_FORM_PRICE}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_8} />
    ),
    disableNext: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_FORM_IS_SENSITIVE}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_9} />
    ),
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${ADD_PRODUCT_FORM_CREATE_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_10} />
    ),
    disableNext: true,
    ...MENU_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${NEW_PRODUCT}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_11} />
    ),
    highlightedSelectors: [`[data-tour="${NEW_PRODUCT}"]`],
    mutationObservables: [`[data-tour="${NEW_PRODUCT}"]`],
    actionID: "showProductFormEdit",
    disablePrev: true,
    ...MENU_SIMILAR_DATA,
  },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_CATEGORY_OPTION}"]`,
  //   content: "Aquí podrás agregar una categoría de opción de producto",
  //   actionID: "showCategoryOptionForm",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_CATEGORY_OPTION_NAME}"]`,
  //   content: "Aquí pondrás el nombre de la categoría de opción de producto",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_CATEGORY_OPTION_MIN}"]`,
  //   content:
  //     "Aquí pondrás el número de opciones mínimas que se podrán escoger de esta categoría de opciones",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_CATEGORY_OPTION_MAX}"]`,
  //   content:
  //     "Aquí pondrás el número de opciones máximas que se podrán escoger de esta categoría de opciones",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_CATEGORY_OPTION_CREATE}"]`,
  //   content:
  //     "Una vez lleno el formulario, para crear la categoría de opción de producto debes darle click al botón Crear.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${NEW_PRODUCT_CATEGORY_OPTION}"]`,
  //   content: "Esta es la categoría de opción de producto que acabas de crear",
  //   actionID: "showOption",
  //   highlightedSelectors: [`[data-tour="${NEW_PRODUCT_CATEGORY_OPTION}"]`],
  //   mutationObservables: [`[data-tour="${NEW_PRODUCT_CATEGORY_OPTION}"]`],
  //   resizeObservables: [`[data-tour="${NEW_PRODUCT_CATEGORY_OPTION}"]`],
  //   disablePrev: true,
  // },
  // {
  //   selector: `[data-tour="${IS_MANDATORY_CATEGORY_OPTION}"]`,
  //   content:
  //     "Aquí podrás indicar si la categoría de opción de producto es obligatoria de escoger",
  //   highlightedSelectors: [`[data-tour="${IS_MANDATORY_CATEGORY_OPTION}"]`],
  //   mutationObservables: [`[data-tour="${IS_MANDATORY_CATEGORY_OPTION}"]`],
  //   resizeObservables: [`[data-tour="${IS_MANDATORY_CATEGORY_OPTION}"]`],
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_OPTION}"]`,
  //   content: "Aquí podrás agregar una opción de producto",
  //   actionID: "showOptionForm",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_OPTION_NAME}"]`,
  //   content: "Aquí pondrás el nombre de la opción de producto",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_OPTION_PRICE}"]`,
  //   content: "Aquí pondrás el precio de la opción de producto",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${ADD_PRODUCT_OPTION_CREATE}"]`,
  //   content:
  //     "Una vez lleno el formulario, para crear la opción de producto debes darle click al botón Crear.",
  //   disableNext: true,
  // },
  {
    selector: `[data-tour="${INACTIVE_PRODUCT}"]`,
    content: (
      <ComponentTranslation keyTranslation={MENU_FUNCTIONALITY_STEP_12} />
    ),
    disablePrev: true,
  },
];

export const STEPS_USERS_FUNCTIONALITY = [
  {
    selector: `[data-tour="${ADMIN_USER_ROW}"]`,
    content: (
      <ComponentTranslation keyTranslation={USERS_FUNCTIONALITY_STEP_1} />
    ),
    actionID: "createUsers",
    action: saveStores({
      [DUMMY_STORE]: {
        name: "Tienda de prueba",
        description: "Esta es una tienda de prueba",
        storeType: "regular",
      },
    }),
  },
  {
    selector: `[data-tour="${ADMIN_USER_ROLE_CELL}"]`,
    content: (
      <ComponentTranslation keyTranslation={USERS_FUNCTIONALITY_STEP_2} />
    ),
  },
  {
    selector: `[data-tour="${OPERATOR_USER_ROW}"]`,
    content: (
      <ComponentTranslation keyTranslation={USERS_FUNCTIONALITY_STEP_3} />
    ),
  },
  {
    selector: `[data-tour="${OPERATOR_USER_ROLE_CELL}"]`,
    content: (
      <ComponentTranslation keyTranslation={USERS_FUNCTIONALITY_STEP_4} />
    ),
  },
  // {
  //   selector: `[data-tour="${PLATFORM_USERS}"]`,
  //   content:
  //     "Esta es la sección de Usuarios, aquí podrás ver el listado de los diferentes usuarios que están para tu marca en Buzzly.",
  //   switchRoute: "/users",
  // },
  // {
  //   selector: `[data-tour="${CREATE_USER_BUTTON}"]`,
  //   content:
  //     "Con este botón podrás crear un nuevo usuario, todo lo que hagas en este tutorial es a modo de prueba, ninguna acción es real. Dale click al boton Crear Usuario.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_NAME}"]`,
  //   content:
  //     "Primero crearás un usuario Administrador, aquí pondrás el nombre del usuario.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_EMAIL}"]`,
  //   content: "Aquí pondrás el correo del usuario.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_ROLE}"]`,
  //   content: "Aquí escogerás el rol del usuario.",
  //   actionID: "selectAdminRole",
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_PASSWORD}"]`,
  //   content: "Aquí pondrás la contraseña del usuario.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_PASSWORD_CONFIRMATION}"]`,
  //   content: "Aquí pondrás la confirmación de la contraseña del usuario.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_CREATE_BUTTON}"]`,
  //   content:
  //     "Una vez lleno el formulario, para crear el usuario debes darle click al botón Crear.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${NEW_USER}"]`,
  //   content: "Este es el usuario que acabas de crear",
  // },
  // {
  //   selector: `[data-tour="${EDIT_USER_BUTTON}"]`,
  //   content:
  //     "Con este botón podrás editar el usuario que acabas de crear, convertiremos este usuario en rol de Operario, dale click al botón de Editar.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_NAME}"]`,
  //   content: "Aquí pondrás el nombre del usuario.",
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_EMAIL}"]`,
  //   content: "Aquí pondrás el correo del usuario.",
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_ROLE}"]`,
  //   content: "Aquí escogerás el rol del usuario.",
  //   actionID: "selectOperatorRole",
  //   action: saveStores({
  //     [DUMMY_STORE]: {
  //       name: "Tienda de prueba",
  //       description: "Esta es una tienda de prueba",
  //       storeType: "regular",
  //     },
  //   }),
  //   highlightedSelectors: [`[data-tour="${USER_FORM_ROLE}"]`],
  //   mutationObservables: [`[data-tour="${USER_FORM_ROLE}"]`],
  //   resizeObservables: [`[data-tour="${USER_FORM_ROLE}"]`],
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_STORE}"]`,
  //   content: "Aquí escogerás la tienda a la que pertenece el usuario Operador.",
  //   actionID: "selectDummyStore",
  // },
  // {
  //   selector: `[data-tour="${USER_FORM_CREATE_BUTTON}"]`,
  //   content:
  //     "Una vez lleno el formulario, para editar el usuario debes darle click al botón Guardar.",
  //   disableNext: true,
  // },
  // {
  //   selector: `[data-tour="${NEW_USER}"]`,
  //   content:
  //     "Este es el usuario que acabas de editar, el cual ya quedó como rol de Operario",
  // },
  // {
  //   selector: `[data-tour="${DELETE_USER_BUTTON}"]`,
  //   content:
  //     "Con este botón podrás eliminar el usuario que acabas de editar, dale click al botón de Eliminar.",
  //   disableNext: true,
  // },
  {
    isFinished: true,
    alert: {
      title: READY,
      text: USERS_ONBOARDING_FINISH,
      icon: ALERT_ICON_TYPE_SUCCESS,
    },
  },
];

export const STEPS_MAIN_FUNCTIONALITY_OPERATOR = [
  {
    selector: `[data-tour="${REDEEM_CODE_SEARCHBAR}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_1}
      />
    ),
    switchRoute: "/redeem-code",
    disableNext: true,
    actionID: "redeemCode",
  },
  {
    selector: `[data-tour="${REDEEM_CODE_TABLE}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_2}
      />
    ),
    disablePrev: true,
  },
  {
    selector: `[data-tour="${PLATFORM_ORDERS}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_3}
      />
    ),
    disablePrev: true,
    switchRoute: "/delivery",
  },
  {
    selector: `[data-tour="${STATUS_TO_BE_ACCEPTED}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_4}
      />
    ),
  },
  {
    selector: `[data-tour="${STATUS_IN_PREPARATION}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_5}
      />
    ),
  },
  {
    selector: `[data-tour="${STATUS_TO_BE_DELIVERED}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_6}
      />
    ),
    actionID: "createDelivery",
  },
  {
    selector: `[data-tour="${NEW_ORDER}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_7}
      />
    ),
    disableNext: true,
    highlightedSelectors: [`[data-tour="${NEW_ORDER}"]`],
    mutationObservables: [`[data-tour="${NEW_ORDER}"]`],
    resizeObservables: [`[data-tour="${NEW_ORDER}"]`],
    action: getNextInfluencers({
      [DUMMY_USER]: {
        mainAccountName: `${NAME_BUSINESS}club`,
        imageURL: "https://clapp-prod.s3.amazonaws.com/Buzzly/favicon.ico",
        mainPlatform: INSTAGRAM,
      },
    }),
    actionID: "openModal",
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_PRODUCTS}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_8}
      />
    ),
    disablePrev: true,
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_MESSAGE}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_9}
      />
    ),
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_TOTAL_PRICE}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_10}
      />
    ),
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_USER}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_11}
      />
    ),
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_TIME_TYPE}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_12}
      />
    ),
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_TIME}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_13}
      />
    ),
  },
  {
    selector: `[data-tour="${ORDER_MODAL_LIST_ACCEPTED_BUTTON}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_14}
      />
    ),
    tab: "inPreparation",
    disableNext: true,
  },
  {
    selector: `[data-tour="${ORDER_IN_PREPARATION}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_15}
      />
    ),
    disablePrev: true,
  },
  {
    selector: `[data-tour="${ORDER_IN_PREPARATION_TIME}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_16}
      />
    ),
    disableNext: true,
    actionID: "openModal",
  },
  {
    selector: `[data-tour="${ORDER_MODAL_DELIVERY_INFO}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_17}
      />
    ),
    disablePrev: true,
  },
  {
    selector: `[data-tour="${ORDER_MODAL_BUTTON_READY}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_18}
      />
    ),
    disableNext: true,
    tab: "toBeDelivered",
  },
  {
    selector: `[data-tour="${ORDER_TO_BE_DELIVERED}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_19}
      />
    ),
    actionID: "openModal",
    disablePrev: true,
    disableNext: true,
  },
  {
    selector: `[data-tour="${ORDER_MODAL_BUTTON_DELIVERED}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_20}
      />
    ),
    disableNext: true,
  },
  {
    selector: `[data-tour="${PLATFORM_MENU}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_21}
      />
    ),
    switchRoute: "/menu",
    disablePrev: true,
    actionID: "createCategoryTest",
  },
  {
    selector: `[data-tour="${NEW_CATEGORY}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_22}
      />
    ),
  },
  {
    selector: `[data-tour="${NEW_PRODUCT}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_23}
      />
    ),
  },
  {
    selector: `[data-tour="${INACTIVE_PRODUCT}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={MAIN_FUNCTIONALITY_OPERATOR_STEP_24}
      />
    ),
    action: resetUIReducer(),
  },
];

const AMBASSADORS_SIMILAR_DATA = {
  onboardingName: STEP_AMBASSADORS_FUNCTIONALITY,
  switchRouterOnClose: "/ambassadors",
};
export const STEPS_AMBASSADORS_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PAGE_AMBASSADORS}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_1} />
    ),
    switchRoute: "/ambassadors",
    highlightedSelectors: [`[data-tour="${PAGE_AMBASSADORS}"]`],
    mutationObservables: [`[data-tour="${PAGE_AMBASSADORS}"]`],
    resizeObservables: [`[data-tour="${PAGE_AMBASSADORS}"]`],
    action: createOrder({
      data: {
        value: 100000,
        shopID: "asdasd",
        userID: DUMMY_INFLUENCER,
        postStatus: POST_STATUS_SENT,
        posts: [
          "https://clapp-prod.s3.us-east-1.amazonaws.com/orders/-NTUH9SErTEfHpVAfZ9x/content/0f624df3-a557-4ba0-b88d-9e13daedd08c.quicktime",
        ],
        analytics: [
          "https://clapp-prod.s3.us-east-1.amazonaws.com/orders/-NTUH9SErTEfHpVAfZ9x/content/2a284fae-f0cb-4604-b4ce-3d5a3a77781f.png",
          "https://clapp-prod.s3.us-east-1.amazonaws.com/orders/-NTUH9SErTEfHpVAfZ9x/content/5003f1a3-7a10-401f-a398-2c2c93ad59ac.png",
        ],
        date: new Date().toISOString(),
        inviteID: DUMMY_INVITE,
        isDelivery: false,
        storeID: "DUMMY_STORE",
        analyticsData: {
          accountsReached: 942,
          creationTime: 1693092742008,
          engagement: 0.01,
          orderID: DUMMY_ORDER,
          stickerTaps: 13,
          comments: 4,
          likes: 32,
          shares: 3,
        },
      },
      key: DUMMY_ORDER,
    }),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    switchRoute: `/publication?orderID=${DUMMY_ORDER}`,
    isHidden: true,
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${REVIEW_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_2} />
    ),
    switchRoute: `/publication?orderID=${DUMMY_ORDER}`,
    disablePrev: true,
    disableNext: true,
    highlightedSelectors: [`[data-tour="${REVIEW_BUTTON}"]`],
    mutationObservables: [`[data-tour="${REVIEW_BUTTON}"]`],
    resizeObservables: [`[data-tour="${REVIEW_BUTTON}"]`],
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_CONTENT}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_3} />
    ),
    highlightedSelectors: [`[data-tour="${REVIEW_FORM_CONTENT}"]`],
    mutationObservables: [`[data-tour="${REVIEW_FORM_CONTENT}"]`],
    resizeObservables: [`[data-tour="${REVIEW_FORM_CONTENT}"]`],
    disablePrev: true,
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_DO_AMBASSADOR}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_4} />
    ),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_INVITATION_VALUE}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_5} />
    ),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_RECURRENT_FIELDS}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_6} />
    ),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${REVIEW_FORM_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_7} />
    ),
    disablePrev: true,
    disableNext: true,
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS}"]`,
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_8} />
    ),
    disablePrev: true,
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS_TABLE_ROW}"]`,
    mutationObservables: [`[data-tour="${PAGE_AMBASSADORS_TABLE_ROW}"]`],
    resizeObservables: [`[data-tour="${PAGE_AMBASSADORS_TABLE_ROW}"]`],
    content: (
      <ComponentTranslation keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_9} />
    ),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${AMBASSADORS_PAYMENTS_BUTTON}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_10}
      />
    ),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${AMBASSADORS_BROADCAST_BUTTON}"]`,
    content: (
      <ComponentTranslation
        keyTranslation={AMBASSADORS_FUNCTIONALITY_STEP_11}
      />
    ),
    ...AMBASSADORS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_AMBASSADORS}"]`,
    isFinished: true,
    switchRoute: "/ambassadors",
    alert: {
      title: READY,
      text: AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION,
      icon: ALERT_ICON_TYPE_SUCCESS,
    },
    ...AMBASSADORS_SIMILAR_DATA,
  },
];

const DISCOVERY_INFLUENCERS_SIMILAR_DATA = {
  onboardingName: STEP_DISCOVERY_FUNCTIONALITY,
  switchRouterOnClose: "/discovery-influencers",
};
export const STEPS_DISCOVERY_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PAGE_EXPLORER}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_1} />
    ),
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_2} />
    ),
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS_NAME}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_3} />
    ),
    actionData: "name",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS_PLATFORM}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_4} />
    ),
    actionData: "platform",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_SEARCH_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_5} />
    ),
    disableNext: true,
    actionData: "search",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FIRST_ROW}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_EXPLORER_FIRST_ROW}"]`],
    mutationObservables: [`[data-tour="${PAGE_EXPLORER_FIRST_ROW}"]`],
    resizeObservables: [`[data-tour="${PAGE_EXPLORER_FIRST_ROW}"]`],
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_6} />
    ),
    disablePrev: false,
    actionData: "disabledButton",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    mutationObservables: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    resizeObservables: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_7} />
    ),
    disableNext: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    mutationObservables: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    resizeObservables: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_8} />
    ),
    disablePrev: true,
    disableNext: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    mutationObservables: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    resizeObservables: [`[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`],
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_9} />
    ),
    disablePrev: true,
    disableNext: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_INVITATION_VALUE}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_EXPLORER_INVITATION_VALUE}"]`],
    mutationObservables: [`[data-tour="${PAGE_EXPLORER_INVITATION_VALUE}"]`],
    resizeObservables: [`[data-tour="${PAGE_EXPLORER_INVITATION_VALUE}"]`],
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_10} />
    ),
    disablePrev: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_INVITATION_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_11} />
    ),
    disableNext: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_ACTION_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_12} />
    ),
    disablePrev: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS_PLATFORM}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_13} />
    ),
    actionData: "platform2",
    disablePrev: true,
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS_MIN_FOLLOWERS}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_14} />
    ),
    actionData: "minFollowers",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_FILTERS_MAX_FOLLOWERS}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_15} />
    ),
    actionData: "maxFollowers",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_SEARCH_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_16} />
    ),
    disableNext: true,
    actionData: "search2",
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER_TABLE}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_EXPLORER_TABLE}"]`],
    mutationObservables: [`[data-tour="${PAGE_EXPLORER_TABLE}"]`],
    resizeObservables: [`[data-tour="${PAGE_EXPLORER_TABLE}"]`],
    actionData: "disabledButton",
    content: (
      <ComponentTranslation keyTranslation={DISCOVERY_FUNCTIONALITY_STEP_17} />
    ),
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_EXPLORER}"]`,
    isFinished: true,
    alert: {
      title: READY,
      text: DISCOVERY_INFLUENCERS_DONE_ONBOARDING,
      icon: ALERT_ICON_TYPE_SUCCESS,
    },
    ...DISCOVERY_INFLUENCERS_SIMILAR_DATA,
  },
];

const CAMPAIGNS_SIMILAR_DATA = {
  onboardingName: STEP_CAMPAIGN_FUNCTIONALITY,
  switchRouterOnClose: "/campaigns",
};
export const STEPS_CAMPAIGNS_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_1} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_BUTTON_CREATE_CAMPAIGN}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_2} />
    ),
    disableNext: true,
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`],
    mutationObservables: [`[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`],
    resizeObservables: [`[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`],
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_3} />
    ),
    disablePrev: true,
    actionData: "disabledButton",
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_ANSWER_NAME}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_4} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_ANSWER_NEXT_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_5} />
    ),
    disableNext: true,
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`],
    mutationObservables: [`[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`],
    resizeObservables: [`[data-tour="${PAGE_CAMPAIGNS_ANSWER_CONTAINER}"]`],
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_6} />
    ),
    actionData: "disabledButton",
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_SELECT_ANSWER}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_7} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGNS_SAVE_PROGRESS_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_8} />
    ),
    disableNext: true,
    ...CAMPAIGNS_SIMILAR_DATA,
    action: () => startGetInfluencersByUserIDs({ userIDs: [DUMMY_INFLUENCER] }),
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_TITLE}"]`,
    highlightedSelectors: [`[data-tour="${PAGE_CAMPAIGN_TITLE}"]`],
    mutationObservables: [`[data-tour="${PAGE_CAMPAIGN_TITLE}"]`],
    resizeObservables: [`[data-tour="${PAGE_CAMPAIGN_TITLE}"]`],
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_9} />
    ),
    disablePrev: true,
    ...CAMPAIGNS_SIMILAR_DATA,
    action: getApplicantsByCampaignID({
      [DUMMY_APPLICANT]: {
        creationTime: new Date().getTime(),
        price: 100000,
        campaignID: DUMMY_CAMPAIGN,
        status: APPLICANT_STATUS_PENDING,
        userID: DUMMY_INFLUENCER,
        name: "gabrielatafur",
      },
    }),
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_TABLE_APPLICANTS}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_10} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
    actionData: "disabledButton",
  },
  {
    selector: `[data-tour="${INFLUENCERS_FILTERS}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_11} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_12} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
    actionData: "disabledButton",
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_TABLE_INVITATIONS}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_13} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_DETAIL_TITLE}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_14} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_DETAIL_SAVE_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_15} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_DETAIL_ACTIVE_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_16} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_DETAIL_FINISHED_BUTTON}"]`,
    content: (
      <ComponentTranslation keyTranslation={CAMPAIGNS_FUNCTIONALITY_STEP_17} />
    ),
    ...CAMPAIGNS_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_CAMPAIGN_DETAIL_FINISHED_BUTTON}"]`,
    isFinished: true,
    switchRoute: "/campaigns",
    alert: {
      title: READY,
      text: CAMPAIGN_FINISH_ONBOARDING,
      icon: ALERT_ICON_TYPE_SUCCESS,
    },
    ...CAMPAIGNS_SIMILAR_DATA,
  },
];

const STORES_SIMILAR_DATA = {
  onboardingName: STEP_STORE_FUNCTIONALITY,
  switchRouterOnClose: "/stores",
};
export const STEPS_STORES_FUNCTIONALITY = [
  {
    selector: `[data-tour="${PAGE_STORE}"]`,
    content: (
      <ComponentTranslation keyTranslation={STORES_FUNCTIONALITY_STEP_1} />
    ),
    ...STORES_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${PAGE_STORE_BUTTON_CREATE_STORE}"]`,
    content: (
      <ComponentTranslation keyTranslation={STORES_FUNCTIONALITY_STEP_2} />
    ),
    disableNext: true,
    ...STORES_SIMILAR_DATA,
  },
  {
    selector: `[data-tour="${STORE_FORM}"]`,
    highlightedSelectors: [`[data-tour="${STORE_FORM}"]`],
    mutationObservables: [`[data-tour="${STORE_FORM}"]`],
    resizeObservables: [`[data-tour="${STORE_FORM}"]`],
    content: (
      <ComponentTranslation keyTranslation={STORES_FUNCTIONALITY_STEP_3} />
    ),
    disablePrev: true,
    ...STORES_SIMILAR_DATA,
    padding: {
      mask: [10, 10, 70],
    },
  },
];
