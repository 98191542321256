import React from "react";
import Accordion from "../../Common/Containers/Accordion";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ContainerKeyValue from "../../Common/Containers/ContainerKeyValue";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CODE,
  COLUMN_NAME_ID,
  CREATOR,
  DATE_LONG,
  EXPIRED,
  EXPIRED_SINGULAR,
  INVITATIONS,
  IS_AUTOMATIC,
  NO,
  REDEEMED,
  REDEEMED_SINGULAR,
  REPORT_INVITATIONS_INACTIVE,
  STATUS,
  VALUE,
  VALUE_WITH_CURRENCY,
  WAS_GENERATED,
  YES,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";
import ArchiveIcon from "@mui/icons-material/Archive";

const ShopReportInvitesInactive = ({
  data,
  influencers,
  ordersLength,
  shop,
  onCreateOrderFromInvite,
}) => {
  const dataLength = Object.keys(data).length;
  const { t } = useTranslationApp();
  return (
    <Accordion title={t(REPORT_INVITATIONS_INACTIVE)} sx={{}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          maxHeight: 400,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
          <Typography variant="body1">{`${t(
            INVITATIONS
          )}: ${dataLength}`}</Typography>
          <Typography variant="body1">{`${t(
            REDEEMED
          )}: ${ordersLength}`}</Typography>
          <Typography variant="body1">{`${t(EXPIRED)}: ${
            dataLength - ordersLength
          }`}</Typography>
        </Box>
        {Object.keys(data).map((inviteKey) => {
          return (
            <Invite
              key={inviteKey}
              inviteKey={inviteKey}
              data={data[inviteKey]}
              influencer={influencers[data[inviteKey].userID]}
              userID={data[inviteKey].userID}
              shop={shop}
              onCreateOrderFromInvite={onCreateOrderFromInvite}
            />
          );
        })}
      </Box>
    </Accordion>
  );
};

const Invite = ({
  data,
  inviteKey,
  influencer,
  userID,
  shop,
  onCreateOrderFromInvite,
}) => {
  const { t } = useTranslationApp();

  const LOCALES = useLocales();
  const LOCALE = LOCALES[shop.signUpCountry];
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          {!data?.redemptionDate && (
            <IconButton
              sx={{ p: 0, pb: "4px" }}
              onClick={() => onCreateOrderFromInvite(inviteKey, "inactive")}
            >
              <ArchiveIcon />
            </IconButton>
          )}
        </Box>
        <ContainerKeyValue
          keyValue={`${t(COLUMN_NAME_ID)}: `}
          value={inviteKey}
        />
        <ContainerKeyValue
          keyValue={`${t(CREATOR)}: `}
          value={influencer?.mainAccountName}
          redirectURL="influencers-accounts?userID"
          redirectValue={userID}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            value: data.value,
            currency: LOCALE.currency,
          })}`}
        />
        <ContainerKeyValue keyValue={`${t(CODE)}: `} value={data.inviteCode} />
        <ContainerKeyValue
          keyValue={`${t(WAS_GENERATED)}: `}
          value={t(DATE_LONG, {
            date: new Date(data.creationTime),
          })}
        />
        <ContainerKeyValue
          keyValue={`${t(IS_AUTOMATIC)}: `}
          value={data?.isAutomatic ? t(YES) : t(NO)}
        />
        <ContainerKeyValue
          keyValue={`${t(STATUS)}: `}
          value={
            data?.redemptionDate ? t(REDEEMED_SINGULAR) : t(EXPIRED_SINGULAR)
          }
        />
      </Box>
    </Box>
  );
};

export default ShopReportInvitesInactive;
