import React from "react";
import ChipMUI from "@mui/material/Chip";
import { useTranslationApp } from "../../../lib/i18next";

const Chip = ({ isSelected, label, onClick, icon, sx = {} }) => {
  const { t } = useTranslationApp();
  return (
    <ChipMUI
      color="default"
      icon={icon}
      label={t(label)}
      onClick={onClick}
      sx={{
        backgroundColor: isSelected && "primary.contrastText",
        color: isSelected && "primary.main",
        "& .MuiChip-icon": {
          color: isSelected && "primary.main",
        },
        ...sx,
      }}
    />
  );
};

export default Chip;
