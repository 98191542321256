import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import KanbanCardToBeAcceptContent from "./toBeAccept/KanbanCardToBeAcceptContent";
import KanbanCardInPreparationContent from "./InPreparation/KanbanCardInPreparationContent";
import KanbanCardToBeDeliveryContent from "./ToBeDelivery/KanbanCardToBeDeliveryContent";
import {
  ORDERS_TAB_IN_PREPARATION,
  ORDERS_TAB_IN_PREPARATION_DESCRIPTION,
  ORDERS_TAB_TO_BE_ACCEPTED,
  ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION,
  ORDERS_TAB_TO_BE_DELIVERED,
  ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const textEmpty = {
  toBeAccepted: ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION,
  inPreparation: ORDERS_TAB_IN_PREPARATION_DESCRIPTION,
  toBeDelivered: ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION,
};

const textTitle = {
  toBeAccepted: ORDERS_TAB_TO_BE_ACCEPTED,
  inPreparation: ORDERS_TAB_IN_PREPARATION,
  toBeDelivered: ORDERS_TAB_TO_BE_DELIVERED,
};

const KanbanCard = ({
  type,
  data,
  setData,
  setOrdenSelected,
  onLaunchSound,
  storeSelected,
  dataTour = "",
}) => {
  const [heightElements, setHeightElements] = useState({
    target: 0,
  });

  const { t } = useTranslationApp();

  const refTarget = useRef(null);

  useEffect(() => {
    setHeightElements({
      target: refTarget.current.clientHeight,
    });
  }, [refTarget?.current?.clientHeight]);

  return (
    <Paper
      elevation={2}
      sx={{
        height: "100%",
        px: 2,
        borderRadius: 5,
        minHeight: 400,
      }}
      ref={refTarget}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 2,
          flexDirection: "row",
        }}
        data-tour={dataTour}
      >
        <Typography
          variant="h5"
          component="h1"
          sx={{ fontWeight: 600, wordBreak: "break-word" }}
        >
          {t(textTitle[type])}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            width: 30,
            height: 30,
            borderRadius: 7,
            backgroundColor: "#9BA2A0",
            display: "grid",
            placeItems: "center",
            color: "white !important",
          }}
        >
          {Object.keys(data).length}
        </Typography>
      </Box>
      <Divider sx={{ mx: -2 }} flexItem />
      <Box
        sx={{
          mt: 2,
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {Object.keys(data)?.length > 0 && type === "toBeAccepted" ? (
          <KanbanCardToBeAcceptContent
            height={heightElements.target}
            data={data}
            storeSelected={storeSelected}
            onSelectOrden={(orden) =>
              setOrdenSelected({ data: orden, type: type })
            }
          />
        ) : Object.keys(data)?.length > 0 && type === "inPreparation" ? (
          <KanbanCardInPreparationContent
            height={heightElements.target}
            data={data}
            setData={setData}
            storeSelected={storeSelected}
            onSelectOrden={(orden) =>
              setOrdenSelected({ data: orden, type: type })
            }
            onLaunchSound={onLaunchSound}
          />
        ) : Object.keys(data)?.length > 0 && type === "toBeDelivered" ? (
          <KanbanCardToBeDeliveryContent
            height={heightElements.target}
            data={data}
            onSelectOrden={(orden) =>
              setOrdenSelected({ data: orden, type: type })
            }
          />
        ) : (
          <Typography
            variant="bod1"
            align="center"
            sx={{ color: "#B6BCC4", maxWidth: 300 }}
          >
            {t(textEmpty[type])}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default KanbanCard;
