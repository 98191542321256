import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RenderContentMedia from "../../Common/Media/RenderContentMedia";
import {
  DATE_SHORT,
  ONE,
  REVISION_COUNT,
  THREE,
  TWO,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const REVISION_NUMBERS = {
  1: ONE,
  2: TWO,
  3: THREE,
};

const ContentHistory = ({ data, isMobile }) => {
  const history = data.history || [];

  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        width: "auto",
        mt: 2,
      }}
    >
      {history.reverse().map((revision, index) => {
        return (
          <Box key={index} sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
              <Typography
                variant={isMobile ? "h4" : "h5"}
                sx={{ fontWeight: 600 }}
              >
                {t(REVISION_COUNT, {
                  value: t(REVISION_NUMBERS[index + 1]),
                })}
              </Typography>
            </Box>
            <Box
              sx={{
                gap: 2,
                display: "flex",
                overflowX: "auto",
                pb: 1,
              }}
            >
              {revision?.content.map((media, indexMedia) => {
                return (
                  <RenderContentMedia
                    key={indexMedia}
                    url={media}
                    keyMedia={indexMedia}
                  />
                );
              })}
            </Box>
            <Box>
              <Typography variant="body1">
                {t(DATE_SHORT, {
                  date: new Date(revision.creationTime),
                })}
              </Typography>
              <Typography variant="body1">{revision.comment}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ContentHistory;
