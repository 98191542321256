import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "../components/Common/Loader/Loader";
import { DEFAULT_TABLE_PAGES } from "../utils/constants";
import { usePagination } from "../hooks/usePagination";
import EventsTable from "../components/User/Events/EventsTable";
import {
  startChangeStatusEvent,
  startGetEventsByShop,
} from "../actions/events";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Common/Buttons/Button";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";

const Events = () => {
  const [dataTable, setDataTable] = useState({});
  const [, setAllData] = useState({});

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { events, isLoading } = useSelector((state) => state.events);

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(min-width:600px)");

  const getNextEvents = async () => {
    await dispatch(
      startGetEventsByShop({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };
  const getInitialData = async () => {
    dispatch(
      startGetEventsByShop({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
      })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextEvents,
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    if (!isLoading) {
      setDataTable(events);
      setAllData(events);
    }
    //eslint-disable-next-line
  }, [events]);

  const onClickEvent = (id) => {
    return navigate(`/event?eventID=${id}`);
  };

  const onChangeStatusEvent = async ({ eventID, isActive }) => {
    await dispatch(startChangeStatusEvent({ eventID, isActive }));
  };

  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <TitlePage>Eventos</TitlePage>
      <DescriptionPage>
        Crea eventos especiales para invitar a creadores en una fecha, hora y
        lugar en específico.
      </DescriptionPage>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 3,
        }}
      >
        <Link to="/create-event" style={{ textDecoration: "none" }}>
          <Button fullWidth={!isMobile} sx={{ minWidth: 140 }}>
            Crear Evento
          </Button>
        </Link>
      </Box>
      {isLoading ? (
        <Loader
          size={70}
          hasMessage={true}
          message="Cargando información"
          variantMessage="h5"
          fullWidth={true}
        />
      ) : (
        <EventsTable
          data={dataTable}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          onChangeStatusEvent={onChangeStatusEvent}
          onClickEvent={onClickEvent}
        />
      )}
    </Box>
  );
};

export default Events;
