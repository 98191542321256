import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import { startChangeIsActiveShop } from "../../../../actions/shops";
import Loader from "../../Loader/Loader";
import {
  FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  ACTIVATE,
  DEACTIVATE,
  REMINDER_ACTIVATE_SHOP,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { useTour } from "@reactour/tour";
import {
  getIsBlockedActions,
  getIsCampaignModeFeatureFlag,
} from "../../../../actions/getters";
import Switch from "../../Forms/Switchs/Switch";

const SwitchShop = ({ onSlideMenu = false, sx, hidden = false }) => {
  const [openTooltipShop, setOpenTooltipShop] = useState(false);
  const { isActive, isLoading } = useSelectorApp((state) => state.shop);
  const isActivePlan = useSelectorApp((state) => state.auth.isActivePlan);

  const { t } = useTranslationApp();

  const { isOpen } = useTour();

  const isActiveFirstTime = useSelectorApp(
    (state) => state.shop.isActiveFirstTime
  );

  const dispatch = useDispatchApp();
  const isCampaignMode = dispatch(getIsCampaignModeFeatureFlag());

  const onChange = async (checked) => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) return;
    const response = await dispatch(startChangeIsActiveShop(checked));
    if (!isActiveFirstTime && response && !isCampaignMode) {
      // navigate("/account?focus=autoInvites");
    }
  };

  useEffect(() => {
    if (!isActive) setOpenTooltipShop(true);
  }, [isActive]);

  useEffect(() => {
    if (isLoading) return;
    setTimeout(() => {
      setOpenTooltipShop(true);
    }, 1000);

    return () => {
      setOpenTooltipShop(false);
    };
  }, [isLoading, onSlideMenu]);

  if (hidden) return null;

  if (isLoading) return <Loader color="secondary" hasMarginTop={false} />;

  return (
    <Box>
      <Switch
        disabled={!isActivePlan}
        isActive={isActive}
        onChangeIsActive={onChange}
        labelActive={DEACTIVATE}
        labelDeactivate={ACTIVATE}
      />
      {!isActive && !isOpen && isActivePlan && (
        <Tooltip
          className="animate__animated animate__fadeInUp animate__faster"
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              positionFixed: true,
              modifiers: {
                preventOverflow: {
                  enabled: true,
                  boundariesElement: "window",
                },
              },
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                p: 0,
                background: GRADIENT_PURPLE_FUCHSIA,
                borderRadius: 3,
                mt: "8px !important",
                ".MuiTooltip-arrow": {
                  color: FUCHSIA,
                },
                width: 260,
              },
            },
          }}
          open={openTooltipShop}
          title={
            <Box sx={{ p: 2 }}>
              {isActiveFirstTime && (
                <Box sx={{ width: "100%", display: "flex", mb: "4px" }}>
                  <IconButton
                    size="small"
                    aria-label="close"
                    onClick={() => setOpenTooltipShop(false)}
                    sx={{
                      color: "black",
                      backgroundColor: "white !important",
                      p: 0,
                      "&:hover": {
                        backgroundColor: "white !important",
                        color: "black",
                        filter: "brightness(0.8)",
                      },
                    }}
                  >
                    <CloseIcon
                      sx={{
                        fontSize: "1.1rem",
                        padding: "3px",
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Typography
                variant="body1"
                sx={{
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  fontSize: "0.975rem",
                }}
              >
                {t(REMINDER_ACTIVATE_SHOP)}
              </Typography>
            </Box>
          }
          arrow
        >
          <div />
        </Tooltip>
      )}
    </Box>
  );
};

export default SwitchShop;
