import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import format from "date-fns/format";
import { numberCurrencyFormat } from "../../../utils/numberFormats";
import {
  PAYMENT_AMBASSADOR,
  CLAPP_CREDITS,
  PAYMENT_APPLICANT,
  PLANS_PACKAGE,
  PLAN_COLLABORATION,
  PLAN_ENTERPRISE,
} from "../../../utils/constants";
import LinkText from "../../Common/Texts/LinkText";

const PaymentsFailedTable = ({ data, rowsPerPage, page, onPageChange }) => {
  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].creationTime - data[a].creationTime
  );

  const { plans } = useSelectorApp((state) => state.payments);

  const { shops } = useSelectorApp((state) => state.shop);

  const getPlanName = (id) => {
    if (id === PLAN_COLLABORATION) return "Pago Colaboración";
    if (id === PAYMENT_APPLICANT) return "Pago Aplicante";
    if (id === PAYMENT_AMBASSADOR) return "Pago Embajador";
    if (id === CLAPP_CREDITS) return "Pago Créditos";
    if (PLANS_PACKAGE.includes(id))
      return `Paquete ${
        id === PLAN_ENTERPRISE ? "Enterprise" : plans[id]?.name
      }`;
    return `Plan ${plans[id]?.name}`;
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={Object.keys(data)}
        loading={false}
        ariaLabel="Tabla de Pagos Fallidos"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              ID
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Shop
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Valor
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Tipo de Pago
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Estado
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Fecha
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            const payment = data[key];
            return (
              <TableRow key={index}>
                <TableCell align="center" indexRow={index}>
                  {key}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <LinkText
                    variant="body2"
                    href={`shop?shopID=${data[key].shopID}`}
                    target="_blank"
                  >
                    {shops[payment.shopID]?.businessName ?? "-"}
                  </LinkText>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {`$${numberCurrencyFormat(payment.value)}`}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {getPlanName(data[key].subscription)}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {payment.status === "failed" ? "Fallido" : "Incompleto"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {format(data[key].creationTime, "dd/MM/yyyy")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default PaymentsFailedTable;
