export const NAVIGATION_PLATFORM = "navigation-platform";
export const NAVIGATION_ACTIONS = "navigation-actions";
export const NAVIGATION_SETTINGS = "navigation-settings";

export const STEP_MAIN_FUNCTIONALITY = "step-main-functionality";
export const STEP_AMBASSADORS_FUNCTIONALITY = "step-ambassadors-functionality";
export const STEP_DISCOVERY_FUNCTIONALITY = "step-discovery-functionality";
export const STEP_CAMPAIGN_FUNCTIONALITY = "step-campaign-functionality";
export const STEP_STORE_FUNCTIONALITY = "step-store-functionality";
export const STEP_MENU_FUNCTIONALITY = "step-menu-functionality";

//INFLUENCERS
export const PLATFORM_INFLUENCERS = "platform-influencers";

export const PAGE_INFLUENCERS = "page-influencers";
export const PAGE_INFLUENCERS_SEARCHBAR = "page-influencers-searchbar";
export const PAGE_INFLUENCERS_FILTERS = "page-influencers-filters";
export const PAGE_INFLUENCERS_TABLE = "page-influencers-table";
export const PAGE_INFLUENCERS_TABLE_NAME = "page-influencers-table-name";
export const PAGE_INFLUENCERS_TABLE_BUTTON_INVITE =
  "page-influencers-table-button_invite";
export const PAGE_INFLUENCERS_TABLE_CITY = "page-influencers-table-city";
export const PAGE_INFLUENCERS_TABLE_AUDIENCE =
  "page-influencers-table-audience";
export const PAGE_INFLUENCERS_TABLE_ENGAGEMENT =
  "page-influencers-table-engagement";
export const PAGE_INFLUENCERS_TABLE_CATEGORIES =
  "page-influencers-table-categories";
export const PAGE_INFLUENCERS_TABLE_SCORE = "page-influencers-table-score";
export const PAGE_INFLUENCERS_TABLE_LAST_COLLABORATION =
  "page-influencers-table-last-collaboration";
export const DUMMY_INFLUENCER = "KgqBdpfbVNd0YYceRm0VkZU5M5i1";
export const DUMMY_INFLUENCER_LOCAL = "DUMMY_INFLUENCER";

//AMBASSADORS

export const PLATFORM_AMBASSADORS = "platform-ambassadors";

export const PAGE_AMBASSADORS = "page-ambassadors";
export const PAGE_AMBASSADORS_TABLE = "page-ambassadors-table";
export const PAGE_AMBASSADORS_TABLE_ROW = "page-ambassadors-table_row";
export const PAGE_AMBASSADORS_TABLE_NAME = "page-ambassadors-table-name";
export const PAGE_AMBASSADORS_TABLE_PLATFORM =
  "page-ambassadors-table-platform";
export const PAGE_AMBASSADORS_TABLE_INVITATION_VALUE =
  "page-ambassadors-table-invitation-value";
export const PAGE_AMBASSADORS_TABLE_LAST_COLLABORATION =
  "page-ambassadors-table-last-collaboration";
export const PAGE_AMBASSADORS_TABLE_ACTIONS = "page-ambassadors-table-actions";
export const DUMMY_AMBASSADOR = "dummyAmbassador";
export const AMBASSADORS_PAYMENTS_BUTTON = "ambassadors-payments-button";
export const AMBASSADORS_PAYMENTS_FORM = "ambassadors-payments-form";
export const AMBASSADORS_BROADCAST_BUTTON = "ambassadors-broadcast-button";
export const AMBASSADORS_BROADCAST_FORM = "ambassadors-broadcast-form";

//REQUESTS
export const PLATFORM_REQUESTS = "platform-requests";

export const PAGE_REQUESTS = "page-requests";
export const PAGE_REQUESTS_FILTERS = "page-influencers-filters";
export const PAGE_REQUESTS_TABLE = "page-influencers-table";
export const PAGE_REQUESTS_TABLE_SHOW_REQUEST =
  "page-influencers-table_show_request";

//INVITATIONS
export const PLATFORM_INVITATIONS = "platform-invitations";

export const PAGE_INVITATIONS = "page-invitations";
export const PAGE_INVITATIONS_TABLE = "page-invitations-table";
export const PAGE_INVITATIONS_TABLE_ID = "page-invitations-table-id";
export const PAGE_INVITATIONS_TABLE_USER = "page-invitations-table-user";
export const PAGE_INVITATIONS_TABLE_CODE = "page-invitations-table-code";
export const PAGE_INVITATIONS_TABLE_VALUE = "page-invitations-table-value";
export const PAGE_INVITATIONS_TABLE_FROM = "page-invitations-table-from";
export const PAGE_INVITATIONS_TABLE_TO = "page-invitations-table-to";

export const PAGE_INVITATIONS_INACTIVE_TABLE =
  "page-invitations-inactive-table";
export const PAGE_INVITATIONS_INACTIVE_TABLE_ID =
  "page-invitations-inactive-table-id";
export const PAGE_INVITATIONS_INACTIVE_TABLE_USER =
  "page-invitations-inactive-table-user";
export const PAGE_INVITATIONS_INACTIVE_TABLE_CODE =
  "page-invitations-inactive-table-code";
export const PAGE_INVITATIONS_INACTIVE_TABLE_VALUE =
  "page-invitations-inactive-table-value";
export const PAGE_INVITATIONS_INACTIVE_TABLE_TYPE =
  "page-invitations-inactive-table-type";
export const PAGE_INVITATIONS_INACTIVE_TABLE_REASON =
  "page-invitations-inactive-table-reason";
export const PAGE_INVITATIONS_INACTIVE_TABLE_DATE =
  "page-invitations-inactive-table-date";

// PUBLICATIONS
export const PLATFORM_PUBLICATIONS = "platform-publications";

export const PAGE_PUBLICATIONS = "page-publications";
export const PAGE_PUBLICATIONS_TABLE = "page-publications-table";
export const PAGE_PUBLICATIONS_TABLE_ID = "page-publications-table-id";
export const PAGE_PUBLICATIONS_TABLE_STATUS = "page-publications-table-status";
export const PAGE_PUBLICATIONS_TABLE_USER = "page-publications-table-user";
export const PAGE_PUBLICATIONS_TABLE_DATE = "page-publications-table-date";
export const PAGE_PUBLICATIONS_TABLE_VALUE = "page-publications-table-value";
export const PAGE_PUBLICATIONS_TABLE_ACTIONS =
  "page-publications-table-actions";

//CAMPAIGNS
export const PLATFORM_CAMPAIGNS = "platform-campaigns";

export const PAGE_CAMPAIGNS = "page-campaigns";
export const PAGE_CAMPAIGNS_SEARCHBAR = "page-campaigns-searchbar";
export const PAGE_CAMPAIGNS_BUTTON_CREATE_CAMPAIGN =
  "page-campaigns-button-create-campaign";
export const PAGE_CAMPAIGNS_TABLE = "page-campaigns-table";
export const PAGE_CAMPAIGNS_TABLE_ID = "page-campaigns-table-id";
export const PAGE_CAMPAIGNS_TABLE_NAME = "page-campaigns-table-name";
export const PAGE_CAMPAIGNS_TABLE_PLATFORMS = "page-campaigns-table-platforms";
export const PAGE_CAMPAIGNS_TABLE_STATUS = "page-campaigns-table-status";
export const PAGE_CAMPAIGNS_ANSWER_CONTAINER =
  "page-campaigns-answer-container";
export const PAGE_CAMPAIGNS_ANSWER_NAME = "page-campaigns-answer-name";
export const PAGE_CAMPAIGNS_SELECT_ANSWER = "page-campaigns-select-answer";
export const PAGE_CAMPAIGNS_SAVE_PROGRESS_BUTTON =
  "page-campaigns-save-progress_button";
export const PAGE_CAMPAIGNS_ANSWER_PREV_BUTTON =
  "page-campaigns-answer-prev-button";
export const PAGE_CAMPAIGNS_ANSWER_NEXT_BUTTON =
  "page-campaigns-answer-next-button";
export const PAGE_CAMPAIGN_TITLE = "page-campaign-title";
export const PAGE_CAMPAIGN_TABLE_APPLICANTS = "page-campaign-table-applicants";
export const PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW =
  "page-campaign-table-applicants-first-row";
export const PAGE_CAMPAIGN_TABLE_INVITATIONS =
  "page-campaign-table-invitations";
export const PAGE_CAMPAIGN_TABLE_INVITATIONS_EXTERNALS =
  "page-campaign-table-invitations-externals";
export const PAGE_CAMPAIGN_DETAIL_TITLE = "page-campaign-detail-title";
export const PAGE_CAMPAIGN_DETAIL_SAVE_BUTTON = "page-campaign-save-button";
export const PAGE_CAMPAIGN_DETAIL_ACTIVE_BUTTON = "page-campaign-active-button";
export const PAGE_CAMPAIGN_DETAIL_FINISHED_BUTTON =
  "page-campaign-finished-button";

export const DUMMY_CAMPAIGN = "dummyCampaign";
export const DUMMY_APPLICANT = "dummyApplicant";

//REPORTS
export const PLATFORM_REPORTS = "platform-reports";

export const PAGE_REPORTS = "page-reports";
export const PAGE_REPORTS_DATES_FORM = "page-reports-dates-form";
export const PAGE_REPORTS_BUTTON_GENERATE = "page-reports-button-generate";

//GALLERY
export const PLATFORM_GALLERY = "platform-gallery";

//EXPLORER
export const PLATFORM_EXPLORER = "platform-explorer";

export const PAGE_EXPLORER = "page-explorer";
export const PAGE_EXPLORER_FILTERS = "page-explorer-filters";
export const PAGE_EXPLORER_FILTERS_NAME = "page-explorer-filters-name";
export const PAGE_EXPLORER_FILTERS_PLATFORM = "page-explorer-filters-platform";
export const PAGE_EXPLORER_SEARCH_BUTTON = "page-explorer-search-button";
export const PAGE_EXPLORER_FIRST_ROW = "page-explorer-first-row";
export const PAGE_EXPLORER_ACTION_BUTTON = "page-explorer-action-button";
export const PAGE_EXPLORER_INVITATION_VALUE = "page-explorer-invitation-value";
export const PAGE_EXPLORER_INVITATION_BUTTON =
  "page-explorer-invitation-button";
export const PAGE_EXPLORER_FILTERS_MIN_FOLLOWERS =
  "page-explorer-filters-min-followers";
export const PAGE_EXPLORER_FILTERS_MAX_FOLLOWERS =
  "page-explorer-filters-max-followers";
export const PAGE_EXPLORER_TABLE = "page-explorer-table";

//MAIN FUNCTIONALITY
export const STEPPER_MAIN_FUNCTIONALITY = "stepper-main-functionality";

export const INFLUENCERS_TITLE = "influencers-title";
export const INFLUENCERS_FILTERS = "influencers-filters";
export const INFLUENCERS_TABS = "influencers-tabs";
export const BUTTON_INFLUENCER_ACTIONS = "button-influencer-actions";
export const BUTTON_INFLUENCER_VIEW_REPORT = "button-influencer-view-report";
export const BUTTON_INVITE_TO_CAMPAIGN = "button-invite-to-campaign";
export const BUTTON_INVITE_TO_EXCHANGE = "button-invite-to-exchange";
export const BUTTON_SEND_PAYMENT = "button-send-payment";
export const BUTTON_SEND_MESSAGE = "button-send-message";

export const MODAL_INFLUENCER_ACTIONS_INFORMATION =
  "modal-influencer-actions-information";
export const MODAL_INFLUENCER_ACTIONS_SECTIONS =
  "modal-influencer-actions-sections";

export const MODAL_INVITE_BUTTON = "modal_invite-button";
export const MODAL_INVITE_INFLUENCER_INFORMATION =
  "modal-influencer-information";
export const MODAL_INVITE_FORM = "modal-invite-form";

export const INVITE_FORM_VALUE = "invite-form-value";
export const INVITE_FORM_CODE = "invite-form-code";
export const INVITE_FORM_COMMENT = "invite-form-comment";
export const INVITE_FORM_DISCOUNT = "invite-form-discount";
export const INVITE_FORM_BUTTON = "invite-form-button";
export const DUMMY_INVITE = "dummyInvite";

export const PUBLICATION_STATUS = "publication-status";
export const PUBLICATION_TITLE = "publication-title";
export const PUBLICATION_SOCIAL_SECTION = "publication-social-section";
export const PUBLICATION_ANALYTICS_SECTION = "publication-analytics_section";
export const DUMMY_ORDER = "orderDummy";

export const REVIEW_BUTTON = "review-button";
export const REVIEW_FORM = "review-form";
export const REVIEW_FORM_CONTENT = "review-form-section-content";
export const REVIEW_FORM_SCORE_CONTENT = "review-form-score-content";
export const REVIEW_FORM_SCORE_ANALYTICS = "review-form-score-analytics";
export const REVIEW_FORM_COMMENT = "review-form-comment";
export const REVIEW_FORM_BUTTON = "review-form-button";
export const REVIEW_FORM_DO_AMBASSADOR = "review-form-do-ambassador";
export const REVIEW_FORM_INVITATION_VALUE = "review-form-invitation_value";
export const REVIEW_FORM_RECURRENT_FIELDS = "review-form-recurrent_fields";

//MENU FUNCTIONALITY
export const PLATFORM_MENU = "platform-menu";
export const DUMMY_STORE = "dummyStore";
export const SELECT_STORE_MENU = "select-store-menu";
export const ADD_CATEGORY_MENU = "add-category-menu";
export const ADD_CATEGORY_FIELD = "add-category-field";
export const NEW_CATEGORY = "new-category";
export const ADD_PRODUCT_MENU = "add-product-menu";
export const ADD_PRODUCT_FORM = "add-product-form";
export const ADD_PRODUCT_FORM_IMAGE = "add-product-form-image";
export const ADD_PRODUCT_FORM_NAME = "add-product-form-name";
export const ADD_PRODUCT_FORM_DESCRIPTION = "add-product-form-description";
export const ADD_PRODUCT_FORM_PRICE = "add-product-form-price";
export const ADD_PRODUCT_FORM_IS_SENSITIVE = "add-product-form-is-sensitive";
export const ADD_PRODUCT_FORM_CREATE_BUTTON = "add-product-form-create-button";
export const NEW_PRODUCT = "new-product";
export const ADD_PRODUCT_CATEGORY_OPTION = "add-product-category-option";
export const ADD_PRODUCT_CATEGORY_OPTION_NAME =
  "add-product-category-option-name";
export const ADD_PRODUCT_CATEGORY_OPTION_MIN =
  "add-product-category-option-min";
export const ADD_PRODUCT_CATEGORY_OPTION_MAX =
  "add-product-category-option-max";
export const ADD_PRODUCT_CATEGORY_OPTION_CREATE =
  "add-product-category-option-create";
export const NEW_PRODUCT_CATEGORY_OPTION = "new-product-category-option";
export const IS_MANDATORY_CATEGORY_OPTION = "is-mandatory-category-option";
export const ADD_PRODUCT_OPTION = "add-product-option";
export const ADD_PRODUCT_OPTION_NAME = "add-product-option-name";
export const ADD_PRODUCT_OPTION_PRICE = "add-product-option-price";
export const ADD_PRODUCT_OPTION_CREATE = "add-product-option-create";
export const INACTIVE_PRODUCT = "inactive-product";

//USERS FUNCTIONALITY
export const PLATFORM_USERS = "platform-users";
export const DUMMY_USER = "dummyUser";
export const DUMMY_USER2 = "dummyUser2";
export const CREATE_USER_BUTTON = "create-user-button";
export const USER_FORM_NAME = "user-form-name";
export const USER_FORM_EMAIL = "user-form-email";
export const USER_FORM_ROLE = "user-form-role";
export const USER_FORM_STORE = "user-form-store";
export const USER_FORM_PASSWORD = "user-form-password";
export const USER_FORM_PASSWORD_CONFIRMATION =
  "user-form-password-confirmation";
export const USER_FORM_CREATE_BUTTON = "user-form-create-button";
export const NEW_USER = "new-user";
export const EDIT_USER_BUTTON = "edit-user-button";
export const DELETE_USER_BUTTON = "delete-user-button";

export const ADMIN_USER_ROW = "admin-user-row";
export const ADMIN_USER_ROLE_CELL = "admin-user-role-cell";
export const OPERATOR_USER_ROW = "operator-user-row";
export const OPERATOR_USER_ROLE_CELL = "operator-user-role-cell";

//OPERATOR FUNCTIONALITY
export const PLATFORM_REDEEM_CODE = "platform-redeem-code";
export const REDEEM_CODE_SEARCHBAR = "redeem-code-searchbar";
export const REDEEM_CODE_TABLE = "redeem-code-table";
export const PLATFORM_ORDERS = "platform-orders";
export const STATUS_TO_BE_ACCEPTED = "status-to-be-accepted";
export const STATUS_IN_PREPARATION = "status-in-preparation";
export const STATUS_TO_BE_DELIVERED = "status-to-be-delivered";
export const DUMMY_DELIVERY = {
  products: [
    {
      description:
        "Sushi dog tempurizado con bastón de langostinos tempura, láminas de aguacate, tomates cherry, ajonjolí, mayonesa y chili dulce.",
      id: "20",
      name: "Futu Dog",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/flup-prod.appspot.com/o/shops%2F-NCWWNEE8vPR4HRtf71x%2Fimages%2F7b32d6e5-3422-4a32-8ffe-83fb386724a7?alt=media&token=eea230a4-0fa5-4534-a289-9cba1df5a009",
      price: "30900",
      quantity: 1,
      status: true,
    },
    {
      description:
        "Nachos de alga nori tempurizados acompañados de dos salsas de la casa, palmitos con spicy mayo y tartar de atún.",
      id: "70",
      name: "Nori Nachos",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/flup-prod.appspot.com/o/shops%2F-NCWWNEE8vPR4HRtf71x%2Fimages%2Fbdeea277-9ba4-4eb2-a12d-2ad3c84ceb13?alt=media&token=2c840cbe-451f-4144-bdfd-3d18986ce769",
      price: "14000",
      quantity: 1,
      status: true,
    },
  ],
  address: {
    address: "calle 158 sur 42 135",
    city: "bogotá",
    cityCountry: "colombia",
    coordinates: {
      lat: 6.1425277,
      lng: -75.6215639,
    },
    country: "colombia",
    details: "apto 1702 jardines ",
    idNumber: "1152449979",
    userInfo: {
      fullName: "Pepito Perez",
      idNumber: "1152449979",
      phoneNumber: "3012518892",
    },
  },
  message: "Dejar en portería",
  totalPrice: 44990,
  isNew: true,
  deliveryID: DUMMY_ORDER,
  userID: DUMMY_USER,
  orderID: DUMMY_ORDER,
  inviteID: DUMMY_INVITE,
  date: new Date().toISOString(),
};
export const NEW_ORDER = "new-order";

export const ORDER_MODAL_LIST_PRODUCTS = "order-modal-list-products";
export const ORDER_MODAL_LIST_MESSAGE = "order-modal-list-message";
export const ORDER_MODAL_LIST_USER = "order-modal-list-total-user";
export const ORDER_MODAL_LIST_TOTAL_PRICE = "order-modal-list-total-price";
export const ORDER_MODAL_LIST_TIME_TYPE = "order-modal-list-time-type";
export const ORDER_MODAL_LIST_TIME = "order-modal-list-time";
export const ORDER_MODAL_LIST_ACCEPTED_BUTTON =
  "order-modal-list-accepted_button";

export const ORDER_IN_PREPARATION = "order-in-preparation";
export const ORDER_IN_PREPARATION_TIME = "order-in-preparation-time";
export const ORDER_MODAL_DELIVERY_INFO = "order-modal-delivery-info";
export const ORDER_MODAL_BUTTON_READY = "order-modal-button-ready";

export const ORDER_TO_BE_DELIVERED = "order-in-preparation";
export const ORDER_TO_BE_DELIVERED_DELIVERY_INFO =
  "order-in-preparation-delivery-info";
export const ORDER_MODAL_BUTTON_DELIVERED = "order-modal-button-delivered";

//STORE
export const PAGE_STORE = "page-store";
export const PAGE_STORE_BUTTON_CREATE_STORE = "page-store-button-create-store";
export const STORE_FORM = "store-form";
export const STORE_FORM_STORE_TYPE = "store-form-store-type";
export const STORE_FORM_STORE_NAME = "store-form-store-name";
export const STORE_FORM_STORE_DESCRIPTION = "store-form-store-description";
export const STORE_FORM_STORE_ADDRESS = "store-form-store-address";
export const STORE_FORM_STORE_CITY = "store-form-store-city";
export const STORE_FORM_STORE_PHONE = "store-form-store-phone";
export const STORE_FORM_STORE_EMAIL = "store-form-store-email";
export const STORE_FORM_STORE_ORDERS_METHODS =
  "store-form-store-orders-methods";
export const STORE_FORM_STORE_ORDERS_METHODS_PRODUCT_AVAILABILITY =
  "store-form-store-orders-methods-product-availability";
export const STORE_FORM_BUTTON_CREATE = "store-form-store-button-create";
