import React, { useState } from "react";
import { useDispatchApp } from "../../../../../lib/redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { startPayApplicantStripe } from "../../../../../actions/payments";
import { SimpleAlert } from "../../../../../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../../../../../utils/constants";
import InfluencerNameLogo from "../../../../Common/Influencer/InfluencerNameLogo";
import ModalBasicLayout from "../../../../Common/Modal/ModalBasicLayout";
import { onInit3DSecureAuth } from "../../../../../services/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import ContainerStripeInformation from "../../../../Common/Stripe/ContainerStripeInformation";
import {
  CONTENT_CREATOR,
  PAY_INFLUENCER,
  SERVICE_FEE,
  TOTAL_PAYMENT,
  VALUE_WITH_CURRENCY,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { FEE_VALUE_CAMPAIGN } from "../../../../../utils/constants";
import { numberDecimal } from "../../../../../utils/numberFormats";

const ModalPayApplicant = ({
  modalOpen,
  onCloseModal,
  applicantID,
  campaignID,
  data,
  price,
  callBackPayApplicant,
}) => {
  const [card, setCard] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const stripe = useStripe();

  const onPayInfluencer = async (paymentMethodID = card, paymentIntent) => {
    try {
      setLoading(true);
      const response = await dispatch(
        startPayApplicantStripe({
          applicantID,
          paymentMethodID,
          campaignID,
          paymentIntent,
        })
      );
      if (response.ok) {
        onCloseModal();
        callBackPayApplicant({ applicantID, paymentID: response.data });
        return { ok: true };
      }
      if (!response.ok && response.status === "requiresAction") {
        const responseVerification = await onInit3DSecureAuth({
          clientSecret: response.clientSecret,
          stripe,
          onCallbackFailed: onCloseModal,
        });
        if (responseVerification.ok) {
          const paymentIntent = responseVerification.paymentIntent;
          await onPayInfluencer(paymentMethodID, paymentIntent);
          return { ok: true };
        }
      }
      SimpleAlert({
        title: "Error al pagar al aplicante",
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return { ok: false };
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const FEE_VALUE_CALCULATE = numberDecimal(price * FEE_VALUE_CAMPAIGN);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
    >
      <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {`${t(CONTENT_CREATOR)}:`}
          </Typography>
          <InfluencerNameLogo
            data={data}
            userID={data.userID}
            variantText="body1"
            sx={{ mb: 0 }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {`${t(PAY_INFLUENCER)}:`}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: price,
              currency: "USD",
            })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {`${t(SERVICE_FEE)}:`}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: FEE_VALUE_CALCULATE,
              currency: "USD",
            })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {`${t(TOTAL_PAYMENT)}:`}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: price + FEE_VALUE_CALCULATE,
              currency: "USD",
            })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <ContainerStripeInformation
          onCallback={onPayInfluencer}
          loading={loading}
          card={card}
          onChangeCard={setCard}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalPayApplicant;
