import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const ActionsContactTable = ({
  idContact,
  data,
  onSelectContact,
  onDeleteContact,
}) => {
  return (
    <Box>
      <IconButton
        color="primary.contrastText"
        onClick={() => onDeleteContact(idContact)}
      >
        <DeleteIcon />
      </IconButton>
      <IconButton
        color="primary.contrastText"
        onClick={() => onSelectContact(idContact)}
      >
        <ModeEditIcon />
      </IconButton>
    </Box>
  );
};

export default ActionsContactTable;
