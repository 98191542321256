import React, { useState } from "react";
import { useSelectorApp } from "../../../lib/redux";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "./LoadingButton";
import { useTranslationApp } from "../../../lib/i18next";
import { BUTTON_REMOVE_IMAGE } from "../../../locales/keysTranslations";
import { IMAGE_FORMATS } from "../../../utils/constants";
import { useTour } from "@reactour/tour";

const RemoveImage = ({
  acceptedFormats = IMAGE_FORMATS,
  hasIcon = true,
  removeIcon = <DeleteIcon />,
  hasText = true,
  textButton = BUTTON_REMOVE_IMAGE,
  color = "primary",
  variant = "contained",
  targetName,
  disabled,
  onChangeImage = () => {},
  onRemoveImage = () => {},
  onReadyUrlImage = () => {},
  onCallbackLoading = () => {},
  imageOnboarding,
  mode = "remove",
  isHidden = false,
  sx = {},
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslationApp();

  const isNewBrand = useSelectorApp((state) => state.onboarding.isNewBrand);

  const { isOpen } = useTour();

  const getTypeButton = () => {
    if (isOpen && !isNewBrand) return "button";
    if (isOpen && isNewBrand) return "file";

    if (mode === "remove") return "button";

    return "file";
  };
  return (
    <>
      <LoadingButton
        sx={{
          display: isHidden ? "none" : "flex",
          borderRadius: 3,
          ...sx,
        }}
        endIcon={hasIcon && removeIcon}
        disableElevation
        loading={loading}
        disabled={disabled}
        loadingPosition="end"
        component="label"
        color={color}
        variant={variant}
        onClick={() => {
          if (mode === "remove") {
            onRemoveImage();
          }
        }}
      >
        {hasText && t(textButton)}
        <input
          hidden
          accept={acceptedFormats
            .map((item) => {
              if (IMAGE_FORMATS.includes(item)) return `image/${item},`;
              return `video/${item},`;
            })
            .join(" ")}
          name="image"
          type={getTypeButton()}
          onChange={async (e) => {
            if (mode === "replace") {
              setLoading(true);
              onCallbackLoading(true);
              await onChangeImage(e);
              setLoading(false);
              onCallbackLoading(false);
            }
          }}
          onClick={(e) => {
            if (isOpen && !isNewBrand) {
              e.preventDefault();
              onReadyUrlImage({
                target: { name: targetName, value: imageOnboarding },
              });
            }
          }}
        />
      </LoadingButton>
    </>
  );
};

RemoveImage.propTypes = {
  hasIcon: PropTypes.bool,
  textButton: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  pathStorage: PropTypes.string.isRequired,
  pathRTDB: PropTypes.string.isRequired,
  urlImage: PropTypes.string.isRequired,
  targetName: PropTypes.string.isRequired,
  onRemoveUrlImage: PropTypes.func.isRequired,
};

export default RemoveImage;
