import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CurrencyField from "../../../Common/NumberFormats/CurrencyField";
import Button from "../../../Common/Buttons/Button";
import {
  ADD_PRODUCT_OPTION_CREATE,
  ADD_PRODUCT_OPTION_NAME,
  ADD_PRODUCT_OPTION_PRICE,
} from "../../../../onboarding/stepsSelectors";
import { useDispatchApp } from "../../../../lib/redux";
import { useTour } from "@reactour/tour";
import { changeShowNextButton } from "../../../../actions/onboarding";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CREATE,
  BUTTON_SAVE,
  FIELD_ALL_REQUIRED,
  FIELD_NUMBER_NEGATIVE,
  MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER,
} from "../../../../locales/keysTranslations";

const FieldAccompaniments = ({
  mode,
  data,
  onCreateAccompaniment,
  onEditAccompaniment,
  onCancel,
}) => {
  const [values, setValues] = useState({
    name: mode === "create" ? "" : data.name,
    price: mode === "create" ? "" : data.price,
    isActive: true,
  });

  const [error, setError] = useState(false);

  const dispatch = useDispatchApp();

  const { isOpen } = useTour();

  const { t } = useTranslationApp();

  const onChange = (event) => {
    if (isOpen && event.target.value !== "") {
      dispatch(changeShowNextButton(true));
    } else if (isOpen && event.target.value === "") {
      dispatch(changeShowNextButton(false));
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    if (!values.name || values.price.toString().length === 0)
      return setError(t(FIELD_ALL_REQUIRED));
    if (values.price < 0) return setError(t(FIELD_NUMBER_NEGATIVE));
    setError(null);
    return mode === "create"
      ? onCreateAccompaniment({
          ...values,
          price: parseInt(values.price),
        })
      : onEditAccompaniment({
          ...values,
          price: parseInt(values.price),
        });
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} data-tour={ADD_PRODUCT_OPTION_NAME}>
        <TextField
          variant="filled"
          hiddenLabel={true}
          name="name"
          autoFocus={true}
          onKeyDown={(e) => {
            e.key === "Escape" && onCancel();
          }}
          onChange={onChange}
          value={values.name}
          sx={{
            width: "100%",
            px: 2,
          }}
          placeholder={t(MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER)}
        />
      </Grid>
      <Grid item xs={12} data-tour={ADD_PRODUCT_OPTION_PRICE}>
        <TextField
          variant="filled"
          hiddenLabel={true}
          name="price"
          onKeyDown={(e) => {
            e.key === "Escape" && onCancel();
          }}
          onChange={onChange}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          value={values.price}
          sx={{
            width: "100%",
            px: 2,
          }}
          placeholder={t(MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER)}
        />
      </Grid>
      {error && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          type="button"
          sx={{ mx: 2, minWidth: 150, mt: 1 }}
          dataTour={ADD_PRODUCT_OPTION_CREATE}
        >
          {mode === "create" ? t(BUTTON_CREATE) : t(BUTTON_SAVE)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FieldAccompaniments;
