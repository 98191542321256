import React from "react";
import Box from "@mui/material/Box";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import {
  INTEGRATIONS_TERMS_CONTENT,
  INTEGRATIONS_TERMS_DESCRIPTION,
  INTEGRATIONS_TERMS_TITLE,
} from "../locales/keysTranslations";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../lib/i18next";
import { useDispatchApp } from "../lib/redux";
import { getNameBusinessTheme } from "../actions/getters";

const IntegrationsTerms = () => {
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        pt: 3,
        pb: 3,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: "75ch" }}>
        <TitlePage>{t(INTEGRATIONS_TERMS_TITLE, { NAME_BUSINESS })}</TitlePage>
        <DescriptionPage>
          {t(INTEGRATIONS_TERMS_DESCRIPTION, {
            date: new Date("11/18/2023"),
          })}
        </DescriptionPage>

        <Typography
          variant="body1"
          sx={{ maxWidth: "75ch" }}
          dangerouslySetInnerHTML={{
            __html: t(INTEGRATIONS_TERMS_CONTENT, {
              NAME_BUSINESS,
            }),
          }}
        />
      </Box>
    </Box>
  );
};

export default IntegrationsTerms;
