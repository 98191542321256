import React, { useState } from "react";
import AnswerTitle from "../../Common/Forms/TypeForm/AnswerTitle";
import AnswerContainer from "../../Common/Forms/TypeForm/AnswerContainer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { generalCategories } from "../../../utils/categories";
import TextField from "../../Common/Fields/TextField";
import Select from "../../Common/Selects/Select";
import ActionsButtons from "../../Common/Forms/TypeForm/ActionsButtons";
import Avatar from "@mui/material/Avatar";
import { useSelectorApp } from "../../../lib/redux";
import PhoneTextField from "../../Common/Fields/PhoneTextField";
import {
  BUTTON_NEXT,
  BUTTON_RESEND_CODE,
  FEMALES,
  FIELD_REQUIRED,
  MALES,
  PASSWORD_CONFIRMATION_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  REGISTER_BUSINESS_NAME_ANSWER,
  REGISTER_CATEGORY_ANSWER,
  REGISTER_EMAIL_ANSWER,
  REGISTER_NAME_ANSWER,
  REGISTER_OTP_ANSWER,
  REGISTER_PASSWORD_ANSWER,
  REGISTER_PHONE_ANSWER,
  REGISTER_PROFILE_CREATOR_AGE_ANSWER,
  REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER,
  REGISTER_PROFILE_CREATOR_GENDER_ANSWER,
  REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER,
  REGISTER_SELECT_PLACEHOLDER,
  REGISTER_WEBSITE_ANSWER,
  REGISTER_WEBSITE_ANSWER_DESCRIPTION,
  REGISTER_WRITE_PLACEHOLDER,
  SELECT_SOME_OPTIONS,
  WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Controller } from "react-hook-form";
import SelectInfluencerCategories from "../../Common/Selects/SelectInfluencerCategories";
import { optionsAudienceAgeGender } from "../../../utils/slidersMarks";
import SelectMultiple from "../../Common/Selects/SelectMultiple";
import AnswerSubTitle from "../../Common/Forms/TypeForm/AnswerSubTitle";
import { MAX_WIDTH_ANSWER_CONTAINER } from "../../../utils/constants";
import LoadingButton from "../../Common/Buttons/LoadingButton";

const RegisterForm = ({
  error,
  currentAnswer,
  control,
  getValues,
  watch,
  onPreviousStep,
  onNextStep,
  isResendAllowed,
  countdown,
  isResendingCode,
  resendCode,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const signUpCountry = getValues("signUpCountry");

  const { theme } = useSelectorApp((state) => state.ui);

  const { t } = useTranslationApp();
  const onChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: MAX_WIDTH_ANSWER_CONTAINER,
          mb: 2,
        }}
      >
        <Avatar
          src={theme.logo}
          alt="Logo"
          variant="square"
          sx={{
            px: 4,
            height: "auto",
            width: "100%",
            maxWidth: 250,
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      </Box>
      <AnswerContainer
        stepPosition={"name"}
        step={currentAnswer}
        onNextCallback={() => onNextStep("name", getValues("name"))}
      >
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_NAME_ANSWER)}</AnswerTitle>
        {!!error && error.step === "name" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          name="name"
          placeholder={t(REGISTER_WRITE_PLACEHOLDER)}
          fullWidth
          margin="normal"
          variant="standard"
          control={control}
          autoFocus={true}
        />
        <ActionsButtons
          showPreviousButton={false}
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("name", getValues("name"))}
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"businessName"}
        step={currentAnswer}
        onNextCallback={() =>
          onNextStep("businessName", getValues("businessName"))
        }
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {t(REGISTER_BUSINESS_NAME_ANSWER)}
        </AnswerTitle>
        {!!error && error.step === "businessName" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          name="businessName"
          placeholder={t(REGISTER_WRITE_PLACEHOLDER)}
          fullWidth
          margin="normal"
          variant="standard"
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("businessName", getValues("businessName"))
          }
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"category"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_CATEGORY_ANSWER)}</AnswerTitle>
        {!!error && error.step === "category" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Select
          name="category"
          placeholder={t(REGISTER_SELECT_PLACEHOLDER)}
          displayEmpty={true}
          showEmptyOption={true}
          fullWidth={true}
          variant="standard"
          options={generalCategories.map((category) => ({
            ...category,
            label: t(category.label),
          }))}
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("category", getValues("category"))}
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"webSite"}
        step={currentAnswer}
        onNextCallback={() => onNextStep("webSite", getValues("webSite"))}
      >
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_WEBSITE_ANSWER)}</AnswerTitle>
        <AnswerSubTitle>
          {t(REGISTER_WEBSITE_ANSWER_DESCRIPTION)}
        </AnswerSubTitle>
        {!!error && error.step === "webSite" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          name="webSite"
          placeholder={t(REGISTER_WRITE_PLACEHOLDER)}
          fullWidth
          margin="normal"
          variant="standard"
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("webSite", getValues("webSite"))}
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"profileCreatorPlatforms"}
        step={currentAnswer}
        onNextCallback={() =>
          onNextStep(
            "profileCreatorPlatforms",
            getValues("profileCreatorPlatforms")
          )
        }
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {t(REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER)}
        </AnswerTitle>
        {!!error && error.step === "profileCreatorPlatforms" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="profileCreatorPlatforms"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.instagram}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          instagram: e.target.checked,
                        })
                      }
                      name="instagram"
                    />
                  )}
                />
              }
              label="Instagram"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="profileCreatorPlatforms"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.tiktok}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          tiktok: e.target.checked,
                        })
                      }
                      name="tiktok"
                    />
                  )}
                />
              }
              label="TikTok"
            />
          </Grid>
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep(
              "profileCreatorPlatforms",
              getValues("profileCreatorPlatforms")
            )
          }
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"profileCreatorCategories"}
        step={currentAnswer}
        onNextCallback={() =>
          onNextStep(
            "profileCreatorCategories",
            getValues("profileCreatorCategories")
          )
        }
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {t(REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER)}
        </AnswerTitle>
        {!!error && error.step === "profileCreatorCategories" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <SelectInfluencerCategories
          name="profileCreatorCategories"
          label=""
          placeholder={SELECT_SOME_OPTIONS}
          displayEmpty={true}
          showEmptyOption={true}
          control={control}
          rules={{}}
          variant="standard"
          fullWidth
          sx={{
            mt: "0px !important",
          }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep(
              "profileCreatorCategories",
              getValues("profileCreatorCategories")
            )
          }
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"profileCreatorGender"}
        step={currentAnswer}
        onNextCallback={() =>
          onNextStep("profileCreatorGender", getValues("profileCreatorGender"))
        }
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {t(REGISTER_PROFILE_CREATOR_GENDER_ANSWER)}
        </AnswerTitle>
        {!!error && error.step === "profileCreatorGender" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="profileCreatorGender"
                  rules={{}}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.male}
                      name="male"
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          male: e.target.checked,
                        })
                      }
                    />
                  )}
                />
              }
              label={t(MALES)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="profileCreatorGender"
                  rules={{}}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.female}
                      name="female"
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          female: e.target.checked,
                        })
                      }
                    />
                  )}
                />
              }
              label={t(FEMALES)}
            />
          </Grid>
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep(
              "profileCreatorGender",
              getValues("profileCreatorGender")
            )
          }
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"profileCreatorAgeRange"}
        step={currentAnswer}
        onNextCallback={() =>
          onNextStep(
            "profileCreatorAgeRange",
            getValues("profileCreatorAgeRange")
          )
        }
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {t(REGISTER_PROFILE_CREATOR_AGE_ANSWER)}
        </AnswerTitle>
        {!!error && error.step === "profileCreatorAgeRange" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}

        <SelectMultiple
          name="profileCreatorAgeRange"
          fullWidth={true}
          placeholder={SELECT_SOME_OPTIONS}
          displayEmpty={true}
          showEmptyOption={true}
          variant="standard"
          options={optionsAudienceAgeGender.map((option) => option.value)}
          control={control}
          rules={{}}
          sx={{
            mt: "0px !important",
          }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep(
              "profileCreatorAgeRange",
              getValues("profileCreatorAgeRange")
            )
          }
          hasAdvancedSettings={false}
          showSaveCampaign={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"email"}
        step={currentAnswer}
        onNextCallback={() => onNextStep("email", getValues("email"))}
      >
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_EMAIL_ANSWER)}</AnswerTitle>
        {!!error && error.step === "email" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          name="email"
          placeholder={t(REGISTER_WRITE_PLACEHOLDER)}
          fullWidth
          margin="normal"
          variant="standard"
          control={control}
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("email", getValues("email"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"password"}
        step={currentAnswer}
        onNextCallback={() =>
          onNextStep("password", {
            password: getValues("password"),
            repeatPassword: getValues("repeatPassword"),
          })
        }
      >
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_PASSWORD_ANSWER)}</AnswerTitle>
        {!!error && error.step === "password" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          name="password"
          placeholder={t(PASSWORD_PLACEHOLDER)}
          fullWidth
          margin="normal"
          type={showPassword ? "text" : "password"}
          variant="standard"
          InputProps={{
            endAdornment: (
              <IconButton onClick={onChangeShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        <TextField
          name="repeatPassword"
          placeholder={t(PASSWORD_CONFIRMATION_PLACEHOLDER)}
          fullWidth
          margin="normal"
          type={showPassword ? "text" : "password"}
          variant="standard"
          InputProps={{
            endAdornment: (
              <IconButton onClick={onChangeShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          sx={{ mt: 1 }}
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("password", {
              password: getValues("password"),
              repeatPassword: getValues("repeatPassword"),
            })
          }
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"phone"}
        step={currentAnswer}
        onNextCallback={() => onNextStep("phone", getValues("phone"), true)}
      >
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_PHONE_ANSWER)}</AnswerTitle>
        {!!error && error.step === "phone" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <PhoneTextField
          name="phone"
          onlyCountries={[]}
          defaultCountry={signUpCountry}
          control={control}
          disableDropdown={false}
          fullWidth
          margin="normal"
          variant="standard"
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          showNextButton={false}
          showSaveButton={true}
          saveButtonText={BUTTON_NEXT}
          onFinish={() => onNextStep("phone", getValues("phone"), true)}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"OTP"}
        step={currentAnswer}
        onNextCallback={() => onNextStep("OTP", getValues("OTP"))}
      >
        <AnswerTitle sx={{ mb: 1 }}>{t(REGISTER_OTP_ANSWER)}</AnswerTitle>
        <AnswerSubTitle>
          {t(WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE)}
        </AnswerSubTitle>

        {!!error && error.step === "OTP" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}

        {/* <OTPField
          fullWidth
          subLabel={t(WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE)}
          name="code"
          variant="filled"
          color="primary"
          InputLabelProps={{ shrink: true }}
          control={control}
        /> */}
        <TextField
          name="OTP"
          fullWidth
          margin="normal"
          variant="standard"
          placeholder={t(REGISTER_WRITE_PLACEHOLDER)}
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          showNextButton={false}
          showSaveButton={true}
          customComponentPreviousNext={
            <LoadingButton
              loading={isResendingCode}
              onClick={resendCode}
              disabled={!isResendAllowed}
              color="primary"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 2, height: 40 }}
            >
              {`${t(BUTTON_RESEND_CODE)}${
                isResendAllowed ? "" : ` (${countdown})`
              }`}
            </LoadingButton>
          }
          onFinish={() => onNextStep("OTP", getValues("OTP"), true)}
        />
      </AnswerContainer>
    </Box>
  );
};

export default RegisterForm;
