import { EMAIL_SUPPORT } from "../../utils/constants";
import {
  SEARCH,
  LOADING_INFORMATION,
  AUDIENCE,
  AUDIENCE_TITLE_EXPLANATION,
  AUDIENCE_EXPLANATION,
  ENGAGEMENT,
  ENGAGEMENT_TITLE_EXPLANATION,
  ENGAGEMENT_EXPLANATION,
  RECORDS_IN_TABLE,
  PAGE_IN_TABLE,
  BUTTON_INVITE,
  BUTTON_EDIT_INVITE,
  BUTTON_VIEW_REQUEST,
  BUTTON_REJECT_REQUEST,
  BUTTON_ORDER_IN_PROCESS,
  BUTTON_VIEW_COLLABORATION,
  NEW_CREATORS_CATEGORY,
  CREATORS_TITLE,
  CREATOR_DESCRIPTION,
  SEARCH_BAR_TEXT_CREATORS,
  TAB_CREATORS,
  TAB_REQUESTS,
  TAB_SUGGESTED,
  CREATORS_TABLE_COLUMN_NAME,
  CREATORS_TABLE_COLUMN_CITY,
  CREATORS_TABLE_COLUMN_AUDIENCE,
  CREATORS_TABLE_COLUMN_ENGAGEMENT,
  CREATORS_TABLE_COLUMN_DATE,
  CREATORS_TABLE_COLUMN_CATEGORIES,
  CREATORS_TABLE_COLUMN_SCORE,
  CREATORS_TABLE_COLUMN_ACTIVITY,
  BUTTON_VIEW_INVITE,
  DATE_SHORT,
  MODAL_INVITATION_TITLE,
  MODAL_INVITATION_TITLE_CAMPAIGN,
  BUTTON_VIEW_METRICS,
  QUALITY_AUDIENCE,
  QUALITY_CONTENT,
  CONTENT,
  ANALYTIC,
  COMMENTS,
  EMPTY_COMMENTS,
  SHOW_COMMENTS,
  BUTTON_CANCEL_INVITE,
  INVITE_FORM_VALUE_PLACEHOLDER,
  INVITE_VALUE,
  INVITE_VALUE_EXPLANATION,
  INVITE_FORM_CODE_PLACEHOLDER,
  INVITE_FORM_CODE_HELPER_TEXT,
  INVITE_FORM_COMMENT_PLACEHOLDER,
  INVITE_FORM_COMMENT_HELPER_TEXT,
  INVITE_FORM_VALUE_HELPER_TEXT,
  INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT,
  INVITE_FORM_DISCOUNT_CODE_PLACEHOLDER,
  INVITE_FORM_DISCOUNT_CODE_HELPER_TEXT,
  ADVANCED_CONFIGURATION,
  INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION,
  INVITE_FORM_SELECT_FORMATS_PLATFORM,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
  INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER,
  INVITE_FORM_TERMS_PLACEHOLDER,
  INVITE_FORM_SELECT_EVENTS_PLACEHOLDER,
  SELECT_EVENTS_DISABLED_OPTION,
  MENU_PLATFORM,
  MENU_ACTIONS,
  MENU_CONFIGURATION,
  MENU_ADMINISTRATION,
  SUB_MENU_CREATORS,
  SUB_MENU_AMBASSADORS,
  SUB_MENU_INVITATIONS,
  SUB_MENU_PUBLICATIONS,
  SUB_MENU_EVENTS,
  SUB_MENU_CAMPAIGNS,
  SUB_MENU_REPORTS,
  SUB_MENU_REDEEM_CODE,
  SUB_MENU_ORDERS,
  SUB_MENU_ACCOUNT,
  SUB_MENU_STORES,
  SUB_MENU_MENU,
  SUB_MENU_USERS,
  SUB_MENU_BILLING_AND_PAYMENTS,
  SUB_MENU_BILLING_DASHBOARD,
  SUB_MENU_BILLING_DATA,
  SUB_MENU_USERS_OWNER,
  SUB_MENU_BRANDS,
  SUB_MENU_BRAND_SIGNUPS,
  SUB_MENU_CREATORS_OWNER,
  SUB_MENU_CREATORS_EXTERNALS,
  SUB_MENU_PAY_CREATORS,
  SUB_MENU_CREATOR_SIGNUPS,
  SUB_MENU_CONTENTS,
  SUB_MENU_DELIVERIES,
  SUB_MENU_REPORTS_OWNER,
  SUB_MENU_FEEDBACK,
  SUB_MENU_PAYMENTS_FAILED,
  SUB_MENU_DISCOVERY,
  SUB_MENU_GALLERY,
  INVITE_SENT,
  INVITE_SENT_DESCRIPTION,
  INVITE_EDIT,
  REQUEST_REJECTED,
  REQUEST_REJECTED_DESCRIPTION,
  INVITE_CANCELED,
  INVITE_CANCELED_DESCRIPTION,
  HELP,
  CREDITS,
  DEACTIVATE,
  ACTIVATE,
  BRAND,
  NOTIFICATIONS,
  LOGOUT,
  LOADING_SUGGESTED_INFORMATION,
  GO_TO_PROFILE,
  CREATORS_SUGGESTED_EMPTY,
  CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER,
  INVITE_VALUE_REQUIRED,
  INVITE_VALUE_MIN_VALUE,
  INVITE_COMMENT_MAX_LENGTH,
  INVITE_COMMENT_NO_LINK,
  INVITE_COMMENT_NO_PHONE,
  INVITE_TERMS_LENGTH,
  INVITE_PERSONALIZED_URL_INVALID,
  WARNING,
  INVITE_MIN_VALUE_OF_MENU,
  SHOP_DEACTIVATE_ALERT,
  SHOP_DEACTIVATE_ALERT_DESCRIPTION,
  WAIT,
  INVITE_STORE_LOCATION,
  ERROR,
  INVITE_CODE_IN_OTHER_INVITE,
  ERROR_DESCRIPTION_GENERIC,
  INFLUENCER_NOT_FOUND,
  LIST_CREATORS_WITH_AUDIENCE_SIMILAR,
  LAST_UPDATE_DATE,
  BUTTON_INIT_CHAT,
  UPDATE,
  INFORMATION_INVALID_ACCESS,
  FOLLOWERS,
  AVG_LIKES,
  AVG_COMMENTS,
  ANALYTICS,
  AVG_VIEWS,
  AUDIENCE_INFORMATION,
  AUDIENCE_CREDIBILITY,
  AUDIENCE_CREDIBILITY_TITLE_EXPLANATION,
  AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION,
  AUDIENCE_BY_COUNTRY,
  AUDIENCE_BY_CITIES,
  AUDIENCE_BY_BRAND_AFFINITY,
  AUDIENCE_BY_INTERESTS,
  MALES,
  FEMALES,
  AUDIENCE_BY_DISTRIBUTION_AGE,
  AUDIENCE_BY_PERCENTAGE_GENDERS,
  BUTTON_USE_AS_REFERENCE_PROFILE,
  AUDIENCE_BY_SIMILAR_AUDIENCE,
  PROFILE,
  INFLUENCER_POPULAR_CONTENT,
  PUBLISHED_DATE,
  GALLERY_CONTENT_BUZZLY,
  EMPTY_CONTENT_SHOW,
  BUTTON_INVITE_ALL,
  LOADING_SIMILAR_PROFILES_INFORMATION,
  EMPTY_SIMILAR_PROFILES_SHOW,
  INVITE_THESE_CREATORS,
  INVITE_THESE_CREATORS_CONFIRMATION,
  FIELD_REQUIRED,
  INVITE_FORM_COMMENT_PLACEHOLDER_2,
  INVITE_COMMENT_MIN_LENGTH,
  INVITE_CREATORS,
  EMPTY_RESULTS_SHOW,
  DISCOVER_NEW_CREATORS,
  REPORT_SHOP_DESCRIPTION,
  BUTTON_DOWNLOAD,
  START_DATE_AND_END_DATE_VALIDATION,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  BUTTON_CALCULATE,
  BUTTON_ENTERPRISE_CALCULATE,
  LOADING_REPORT_INFORMATION,
  GLOBAL_REPORT_BUSINESS,
  INSUFFICIENT_DATA_REPORT,
  COLLABORATIONS,
  TOTAL_INVESTMENT,
  TOTAL_EXPENSES,
  COSTS_PER_1000_VIEWS,
  COSTS_PER_ENGAGEMENT,
  ACCOUNTS_REACHED,
  LIKES,
  SHARES,
  SAVES,
  REPLIES,
  STICKERS_TAPS,
  COLLABORATIONS_SUMMARY,
  PENDING_PUBLICATIONS,
  PENDING_ANALYTICS_PUBLICATIONS,
  FEEDBACK_PUBLICATIONS,
  UNKNOWN_FORMAT,
  INVITATIONS_SUMMARY,
  TOTAL_INVITATIONS,
  INVITATIONS_REDEEMED,
  INVITATIONS_REJECTED,
  INVITATIONS_EXPIRED,
  INVITATIONS_ACCEPTANCE_RATE,
  COLLABORATIONS_FOR_PLATFORM,
  COLLABORATION,
  FEATURED_CONTENT,
  CATEGORY,
  EMPTY_IMAGES_FOR_COMMENT,
  REDEEM_CODE_TITLE,
  REDEEM_CODE_DESCRIPTION,
  REDEEM_CODE_SEARCHBAR_PLACEHOLDER,
  BUTTON_REDEEM_CODE,
  BUTTON_ADD_STORE,
  STORE,
  SELECT_STORE,
  EMPTY_STORES,
  NO_STORES_CREATED,
  REDEEM_CODE_TABLE_COLUMN_VALUE,
  REDEEM_CODE_TABLE_COLUMN_DATE,
  REDEEM_CODE_TABLE_COLUMN_CODE,
  FIELD_ALPHANUMERIC,
  FIELD_TEXT,
  FIELD_NUMERIC,
  CODE_REDEEMED,
  VALUE_CODE_REDEEMED,
  CODE_NO_EXIST,
  CODE_NO_REDEEM_BEFORE_DATE,
  EVENT_NO_EXIST,
  EVENT_DISABLED,
  EVENT_INVITATION_NO_BELONG,
  ORDERS_SEARCHBAR_PLACEHOLDER,
  ORDERS_DESCRIPTION,
  ORDERS_TITLE,
  NEW_ORDER,
  ORDERS_TAB_TO_BE_DELIVERED,
  ORDERS_TAB_IN_PREPARATION,
  ORDERS_TAB_TO_BE_ACCEPTED,
  ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION,
  ORDERS_TAB_IN_PREPARATION_DESCRIPTION,
  ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION,
  NEW,
  MINUTE_LETTER_COUNT_DOWN,
  HOUR_LETTER_COUNT_DOWN,
  DAY_LETTER_COUNT_DOWN,
  WEEK_LETTER_COUNT_DOWN,
  DELIVERY_INTERN,
  DELIVERY_ON_THE_WAY,
  ORDER_ACCEPTED,
  ORDER_ACCEPTED_DESCRIPTION,
  ORDER_READY,
  ORDER_READY_DESCRIPTION,
  ORDER_TO_BE_DELIVERED_CONFIRMATION,
  ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON,
  ORDER_DELIVERED,
  ORDER_DELIVERED_DESCRIPTION,
  DELIVERY_SELECTED,
  ORDER_REJECTED,
  ORDER_REJECTED_DESCRIPTION,
  DELIVERY_SELECTED_DESCRIPTION,
  ORDER_TOTAL_VALUE,
  ORDER_LIST_OF_PRODUCTS,
  VALUE_WITH_CURRENCY,
  NUMBER_CURRENCY,
  ORDER_OPTIONS,
  CLIENT,
  ADDRESS,
  IDENTIFICATION_NUMBER,
  PHONE_NUMBER,
  NAME,
  BUTTON_ACCEPT_ORDER,
  BUTTON_REJECT_ORDER,
  DATE_LONG,
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  ORDER_TIME_PREPARATION_TITLE,
  MIN_VALUE_PREPARATION_ORDER,
  MAX_VALUE_PREPARATION_ORDER,
  INVALID_TIME_PREPARATION,
  TIME_PREPARATION_VALUES_VALIDATION,
  GO,
  BUTTON_READY_ORDER,
  GUIDE_NUMBER_REQUIRED,
  DELIVERY_TYPE_DUPLICATE,
  SELECT_DELIVERY_TYPE,
  SELECT_DELIVERY_TYPE_OTHER,
  GUIDE_NUMBER,
  DELIVERY_DATA,
  BUTTON_DELIVER_ORDER,
  BUTTON_CANCEL,
  BUTTON_CREATE_CAMPAIGN,
  CAMPAIGNS_TITLE,
  CAMPAIGNS_DESCRIPTION,
  CAMPAIGNS_SEARCHBAR_PLACEHOLDER,
  CAMPAIGNS_TABLE_COLUMN_NAME,
  CAMPAIGNS_TABLE_COLUMN_PLATFORMS,
  CAMPAIGNS_TABLE_COLUMN_STATUS,
  CAMPAIGN_ACTIVATE,
  CAMPAIGN_DEACTIVATE,
  CAMPAIGN_ACTIVATE_TIME_CONFIRMATION,
  CAMPAIGN_DEACTIVATE_TIME,
  CAMPAIGN_DEACTIVATE_APPLICANTS_ACCEPTED,
  CAMPAIGN_ACTIVATE_FIELDS_REQUIRED,
  CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR,
  CAMPAIGN_ACTIVATE_TRAFFIC_LINK,
  CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM,
  CAMPAIGN_ACTIVATE_ATTACHMENT_LINK,
  CREATE_CAMPAIGN_QUESTION,
  CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER,
  CREATE_CAMPAIGN_NAME_REQUIRED,
  CREATE_CAMPAIGN_DESCRIPTION_REQUIRED,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE,
  CREATE_CAMPAIGN_BUDGET_REQUIRED,
  CREATE_CAMPAIGN_BUDGET_MIN_VALUE,
  CREATE_CAMPAIGN_PLATFORMS_REQUIRED,
  CREATE_CAMPAIGN_TYPE_CONTENT,
  CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED,
  CREATE_CAMPAIGN_DATES_REQUIRED,
  CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS,
  CREATE_CAMPAIGN_ATTACHMENT_LINK,
  CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE,
  CREATE_CAMPAIGN_HAS_BEEN_SAVE,
  CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED,
  CREATE_CAMPAIGN_SAVE_PROGRESS,
  BUTTON_SAVE_PROGRESS,
  QUESTION,
  CREATE_CAMPAIGN_NAME_EXAMPLE,
  BUTTON_NEXT,
  BUTTON_PREVIOUS,
  BUTTON_ADVANCED_CONFIGURATION,
  BUTTON_FINISH,
  CREATE_CAMPAIGN_NAME_QUESTION,
  CREATE_CAMPAIGN_NAME_PLACEHOLDER,
  CREATE_CAMPAIGN_DESCRIPTION_QUESTION,
  CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER,
  CREATE_CAMPAIGN_BUDGET_QUESTION,
  CREATE_CAMPAIGN_BUDGET_DESCRIPTION,
  CREATE_CAMPAIGN_PLATFORMS,
  CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION,
  CREATE_CAMPAIGN_COPYWRITING_QUESTION,
  CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER,
  CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_DATES_QUESTION,
  CREATE_CAMPAIGN_DATES_DESCRIPTION,
  CREATE_CAMPAIGN_DATES_START_DATE_PLACEHOLDER,
  CREATE_CAMPAIGN_DATES_END_DATE_PLACEHOLDER,
  CREATE_CAMPAIGN_REPOST_QUESTION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER,
  CREATE_CAMPAIGN_AGE_RANGE_QUESTION,
  CREATE_CAMPAIGN_BRIEF_QUESTION,
  CREATE_CAMPAIGN_BRIEF_DESCRIPTION,
  CREATE_CAMPAIGN_UPLOAD_BRIEF,
  CREATE_CAMPAIGN_REMOVED_BRIEF,
  CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION,
  CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER,
  CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION,
  CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION,
  CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER,
  CREATE_CAMPAIGN_HASHTAG_QUESTION,
  CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER,
  CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION,
  CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER,
  CREATE_CAMPAIGN_ENVIRONMENT_QUESTION,
  CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER,
  CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION,
  CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_KEY_WORDS_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER,
  CREATE_CAMPAIGN_OUTFIT_QUESTION,
  CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER,
  BUTTON_UPLOAD_CONTENT,
  BUTTON_REMOVE_CONTENT,
  CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER,
  READY,
  CAMPAIGN_FINISHED_CONFIRMATION,
  BUTTON_FINISHED_CAMPAIGN,
  BUTTON_SAVE_CAMPAIGN,
  BUTTON_DEACTIVATE_CAMPAIGN,
  BUTTON_ACTIVATE_CAMPAIGN,
  CAMPAIGN_HAS_BEEN_FINISHED,
  CAMPAIGN_DETAIL_TITLE,
  CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE,
  CAMPAIGN_DETAIL_CREATORS_INVITED_TITLE,
  CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE,
  BUTTON_INVITE_CREATOR,
  ACTIONS,
  STATUS,
  CITIES,
  CATEGORIES,
  CAMPAIGN_FINISH_APPLICANTS_ACCEPTED,
  PAYMENT_SUCCESSFUL,
  BUTTON_SEND_INVITE,
  CAMPAIGN_DETAIL_APPLICANT_PAYMENT_DESCRIPTION,
  CAMPAIGN_DETAIL_APPLICANT_PAYMENT_ADDITIONAL_DESCRIPTION,
  CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION,
  BUTTON_REJECT,
  CAMPAIGN_DETAIL_INVITE_CREATOR_DESCRIPTION,
  LIST_OF_CREATORS,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_DESCRIPTION,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_PLACEHOLDER,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_ACCOUNT_NAME_REQUIRED,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_SOCIAL_NETWORK_REQUIRED,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_REQUIRED,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_MIN_VALUE,
  CAMPAIGN_FIELDS_REQUIRED,
  CAMPAIGN_NAME_PLACEHOLDER,
  CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER,
  CAMPAIGN_BUDGET_PLACEHOLDER,
  CAMPAIGN_PLATFORMS_PLACEHOLDER,
  CAMPAIGN_TYPE_CONTENT_PLACEHOLDER,
  CAMPAIGN_COPY_POST_PLACEHOLDER,
  CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CAMPAIGN_HAS_COLLABORATION_PLACEHOLDER,
  CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER,
  CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER,
  CAMPAIGN_DATES_START_DATE_PLACEHOLDER,
  CAMPAIGN_DATES_END_DATE_PLACEHOLDER,
  CAMPAIGN_REPOST_PLACEHOLDER,
  CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER,
  CAMPAIGN_AGE_RANGE_PLACEHOLDER,
  CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER,
  CAMPAIGN_BRIEF_PLACEHOLDER,
  CAMPAIGN_BRIEF_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_FILE_PLACEHOLDER,
  CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_LINK_TITLE,
  CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER,
  CAMPAIGN_GENDERS_PLACEHOLDER,
  CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER,
  CAMPAIGN_HASHTAG_PLACEHOLDER,
  CAMPAIGN_MESSAGE_TONE_PLACEHOLDER,
  CAMPAIGN_ENVIRONMENT_PLACEHOLDER,
  CAMPAIGN_COLORS_CONTENT_PLACEHOLDER,
  CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER,
  CAMPAIGN_KEY_WORDS_PLACEHOLDER,
  CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER,
  CAMPAIGN_OUTFIT_PLACEHOLDER,
  BUTTON_ACCEPT,
  BUTTON_ASSIGN_INVITE,
  BUTTON_REVIEW_CONTENT,
  BUTTON_VIEW_PRELIMINARY_CONTENT,
  BUTTON_VIEW_APPLICANT,
  BUTTON_ACCEPT_AND_PAY,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  CONTENT_CREATOR,
  PRICE_TO_BE_PAID,
  PAYMENT_METHODS_SECURE,
  PAYMENT_METHODS_SECURE_EXPLANATION,
  PAYMENT_METHODS_ENCRYPTED,
  PAY,
  PAYMENT_METHODS_EMPTY,
  OTHERS_PAYMENT_METHODS,
  MAIN_PAYMENT_METHOD,
  OTHERS_PAYMENT_METHODS_EMPTY,
  ADD_CARD,
  SAVE_CONFIGURATION,
  ADD,
  NAME_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  PASSWORD_CONFIRMATION_PLACEHOLDER,
  CARD_INFORMATION_EXPLANATION_TITLE,
  CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE,
  CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE,
  CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_CVV_EXPLANATION_TITLE,
  CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION,
  APPLICANTS_STATUS_PENDING,
  APPLICANTS_STATUS_REJECTED,
  APPLICANTS_STATUS_ACCEPTED,
  APPLICANTS_STATUS_CONTENT_SUBMITTED,
  APPLICANTS_STATUS_CONTENT_REVISIONS,
  APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL,
  APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS,
  APPLICANTS_STATUS_DELIVERED,
  BUTTON_REVIEW,
  APPLICANT_CONTENT_OF_CREATOR,
  APPLICANT_REVISION_PLACEHOLDER,
  BUTTON_ACCEPT_CONTENT,
  BUTTON_REJECT_CONTENT,
  BUTTON_SEND_REVIEW,
  WARNING_MOBILE_TUTORIAL,
  BUTTON_CONTINUE,
  BUTTON_UNDERSTOOD,
  BUTTON_MORE_QUESTIONS,
  FAQ_DESCRIPTION,
  EMPTY_QUESTIONS_SHOW,
  BUTTON_INIT_TUTORIAL,
  FAQ_QUESTION_WHAT_IS_BUZZLY,
  FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_REQUEST,
  FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_PUBLICATION,
  FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_AMBASSADOR,
  FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_CAMPAIGN,
  FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_ORDER,
  FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_STORE,
  FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION,
  FAQ_QUESTION_WHAT_ROLES_EXISTS,
  FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION,
  FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS,
  FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION,
  FAQ_QUESTION_MUST_CALL_ADMIN,
  FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION,
  FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION,
  FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION,
  FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE,
  FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_INVITATION,
  FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION,
  AMBASSADORS_TITLE,
  AMBASSADORS_DESCRIPTION,
  BUTTON_PAYMENTS,
  BUTTON_BROADCAST,
  AMBASSADORS_TABLE_COLUMN_PLATFORM,
  AMBASSADORS_TABLE_COLUMN_INVITATION,
  AMBASSADORS_TABLE_ARIAL_LABEL,
  BUTTON_SAVE,
  MODAL_AMBASSADOR_ARIAL_LABEL,
  MODAL_AMBASSADOR_TITLE,
  RECURRENT_FIELDS_DEFAULT_TITLE,
  RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER,
  RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION,
  RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION,
  RECURRENT_FIELD_FREQUENCY_PLACEHOLDER,
  RECURRENT_FIELD_DAY_AN_PLACEHOLDER,
  RECURRENT_FIELD_DAY_ALL_PLACEHOLDER,
  WEEK,
  MONTHS,
  MONTH,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  SELECT_A_OPTION,
  FILE_SELECTED,
  BUTTON_SEND_MESSAGE,
  BUTTON_UPLOAD_FILE,
  BUTTON_REMOVE_FILE,
  MODAL_BROADCAST_ARIAL_LABEL,
  MODAL_BROADCAST_TITLE,
  MODAL_BROADCAST_SELECT_USER_PLACEHOLDER,
  MODAL_BROADCAST_SELECT_USER_REQUIRED,
  MODAL_BROADCAST_MESSAGE_PLACEHOLDER,
  BROADCAST_MESSAGE_SENT,
  SELECT_PAYMENT_METHOD,
  MODAL_PAY_AMBASSADORS_ARIAL_LABEL,
  MODAL_PAY_AMBASSADORS_TITLE,
  MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER,
  MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED,
  MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER,
  MODAL_PAY_AMBASSADOR_AMOUNT_MIN,
  MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR,
  MODAL_PAY_AMBASSADOR_TITLE_2,
  PAY_TO_AMBASSADORS,
  YOU_WILL_PAY,
  AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION,
  INVITATIONS_TITLE,
  INVITATIONS_DESCRIPTION,
  INVITATIONS_ACTIVE_TITLE,
  INVITATIONS_ACTIVE_TABLE_ARIA_LABEL,
  INVITATIONS_TABLE_COLUMN_CODE,
  INVITATIONS_TABLE_COLUMN_VALUE,
  INVITATIONS_TABLE_COLUMN_USE,
  INVITATIONS_TABLE_COLUMN_EXPIRATION,
  INVITATION_EXPIRED,
  DAY,
  HOUR,
  MINUTE,
  SECONDS,
  SECOND,
  INVITATION_REDEEMED,
  INVITATION_REJECTED,
  INVITATIONS_INACTIVE_TITLE,
  INVITATIONS_INACTIVE_REDEEM_FILTER,
  INVITATIONS_INACTIVE_EXPIRED_FILTER,
  INVITATIONS_INACTIVE_TABLE_ARIA_LABEL,
  INVITATIONS_TABLE_COLUMN_TYPE_INVITATION,
  INVITATIONS_TABLE_COLUMN_REJECTED_REASON,
  INVITATIONS_TABLE_COLUMN_DATE,
  INVITATIONS_EVENT_TABLE_COLUMN_EVENT,
  INVITATIONS_EVENT_TABLE_ARIA_LABEL,
  INVITATIONS_EVENT_TABLE,
  PUBLICATION_TITLE,
  PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION,
  PUBLICATION_FINISH_DESCRIPTION,
  FEEDBACK_PLACEHOLDER,
  FEEDBACK_TITLE,
  NUMBER_PUBLICATION,
  FIELD_MIN_LENGTH,
  PUBLICATIONS_TITLE,
  PUBLICATIONS_DESCRIPTION,
  PUBLICATIONS_TABLE_ARIA_LABEL,
  PUBLICATIONS_TABLE_COLUMN_DATE,
  PUBLICATION_STATUS_PENDING,
  PUBLICATIONS_STATUS_PENDING_ANALYTICS,
  PUBLICATIONS_STATUS_SENT,
  PUBLICATIONS_STATUS_FEEDBACK,
  PUBLICATIONS_STATUS_VERIFIED,
  FEEDBACK_REASON,
  FEEDBACK_COMMENT,
  ANALYTICS_EXPLANATION_TITLE,
  ANALYTICS_EXPLANATION_DESCRIPTION,
  ANALYTICS_EXPLANATION_DESCRIPTION_2,
  ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION,
  ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION,
  ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION,
  ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION,
  EMPTY_ANALYTICS,
  BUTTON_VERIFY_PUBLICATION,
  FEEDBACK_REASON_ABOUT,
  BUTTON_INVITE_AGAIN,
  RECURRENT_VALUE_NOT_FOUND,
  MODAL_REVIEW_ARIA_LABEL,
  MODAL_REVIEW_DISCLAIMER,
  MODAL_REVIEW_SCORE_CONTENT_PLACEHOLDER,
  MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE,
  MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE,
  MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION,
  MODAL_REVIEW_SCORE_ENGAGEMENT_PLACEHOLDER,
  MODAL_REVIEW_SCORE_ENGAGEMENT_MIN_VALUE,
  MODAL_REVIEW_COMMENT_PLACEHOLDER,
  INFLUENCER_IS_AMBASSADOR,
  MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER,
  YES,
  NO,
  DO_AMBASSADOR,
  DONE_AMBASSADOR,
  MODAL_DO_AMBASSADOR_TITLE,
  MODAL_DO_AMBASSADOR_ARIA_LABEL,
  DISCOVERY_INFLUENCERS_TITLE,
  DISCOVERY_INFLUENCERS_DESCRIPTION,
  DISCOVERY_INFLUENCERS_DONE_ONBOARDING,
  DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS,
  DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED,
  DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION,
  LOADING_DOWNLOAD_PROFILES,
  BUTTON_INVITE_BUZZLY,
  DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE,
  DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER,
  BUTTON_SEARCH,
  FIELD_TYPE_MIN_LENGTH,
  BUTTON_METRICS_READY,
  DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES,
  DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED,
  DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND,
  DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS,
  IMAGES,
  VIDEOS,
  LOADING_CONTENT,
  MODAL_GALLERY_ARIA_LABEL,
  MODAL_GALLERY_EMPTY_CONTENT,
  MODAL_GALLERY_NO_MORE_CONTENT,
  MODAL_MEDIA_ARIA_LABEL,
  BUTTON_UPLOAD_TIKTOK,
  MODAL_UPLOAD_TIKTOK_ARIA_LABEL,
  MODAL_UPLOAD_TIKTOK_TITLE,
  MODAL_UPLOAD_FAILED_GET_CONFIGURATION,
  LOADING_CONFIGURATION,
  MODAL_UPLOAD_CONTENT_UPLOADED,
  MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION,
  MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION,
  MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL,
  MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION,
  BUTTON_VIEW_PROFILE,
  BUTTON_GENERATE_DESCRIPTION,
  ACCOUNT_TITLE,
  ACCOUNT_DESCRIPTION,
  BUTTON_UPLOAD_IMAGE,
  BUTTON_REMOVE_IMAGE,
  FIELD_IMAGE_REQUIRED,
  FIELD_IMAGE_FORMAT_VALID,
  FIELD_IMAGE_SIZE_VALID,
  FIELD_FILE_REQUIRED,
  FIELD_FILE_SIZE_VALID,
  COMPANY_INFORMATION_TITLE,
  COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE,
  COMPANY_INFORMATION_TYPE_ID_PLACEHOLDER,
  COMPANY_INFORMATION_ID_NUMBER_PLACEHOLDER,
  COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH,
  COMPANY_INFORMATION_ID_NUMBER_TYPE,
  COMPANY_INFORMATION_BRAND_NAME_PLACEHOLDER,
  COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER,
  COMPANY_INFORMATION_ADDRESS_PLACEHOLDER,
  COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER,
  COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER,
  COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER,
  COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER,
  COMPANY_INFORMATION_CATEGORY_PLACEHOLDER,
  COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER,
  COMPANY_INFORMATION_NEW_SUBCATEGORY,
  COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER,
  COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_VIEW_DOCUMENT,
  COMPANY_INFORMATION_SHOP_ID_COPIED,
  COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE,
  FIELD_URL_VALID,
  BUTTON_SAVE_CHANGES,
  PROFILE_INFLUENCER_INFORMATION_TITLE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_ENGAGEMENT,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION1,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2,
  AUTOMATIC_INVITES_DEACTIVATE,
  AUTOMATIC_INVITES_ACTIVATE,
  AUTOMATIC_INVITES_TITLE,
  AUTOMATIC_INVITES_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE,
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_BUDGETS_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION1,
  AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION2,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
  BUTTON_REMOVE_VIDEO,
  BUTTON_UPLOAD_VIDEO,
  AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION,
  AUTOMATIC_INVITES_OPTIONAL_COMMENT_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_TERMS_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_SAVED,
  BUTTON_CONNECT_TIKTOK,
  BUTTON_DISCONNECT,
  PLATFORMS_CONNECTED_TITLE,
  SCHEDULE_TITLE,
  SCHEDULE_ECOMMERCE_ALERT_TITLE,
  SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION,
  SCHEDULE_ONE_DAY_WITH_TURN,
  SCHEDULE_EMPTY_TURN,
  SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION,
  SCHEDULE_MAX_CLOSE_HOUR,
  BUTTON_BE_ECOMMERCE,
  BUTTON_NOT_BE_ECOMMERCE,
  SCHEDULE_TABLE_ARIA_LABEL,
  SCHEDULE_TABLE_COLUMN_OPEN,
  SCHEDULE_TABLE_COLUMN_CLOSE,
  BUTTON_ADD_TURN,
  SCHEDULE_SAVED,
  LEGAL_REPRESENTATIVE_TITLE,
  LEGAL_REPRESENTATIVE_NAME_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_LAST_NAME_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_TYPE_ID_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_ID_NUMBER_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_PHONE_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_SAVED,
  PASSWORD_TITLE,
  PASSWORD_CURRENT_PASSWORD_PLACEHOLDER,
  PASSWORD_NEW_PASSWORD_PLACEHOLDER,
  PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER,
  PASSWORD_FORMAT_DESCRIPTION,
  PASSWORD_FORMAT_SUGGESTION1,
  PASSWORD_FORMAT_SUGGESTION2,
  PASSWORD_FORMAT_SUGGESTION3,
  PASSWORD_FORMAT_SUGGESTION4,
  PASSWORD_INSECURE,
  PASSWORDS_NOT_MATCH,
  PASSWORD_CHANGED,
  PASSWORD_CURRENT_INVALID,
  PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS,
  STORES_TITLE,
  STORES_DESCRIPTION,
  STORES_STORE_WOOCOMMERCE_CREATED,
  STORES_STORE_NAME_DUPLICATE,
  STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION,
  STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION,
  STORES_STORE_CREATED,
  STORES_STORE_CREATED_DESCRIPTION,
  STORES_STORE_COORDS_NOT_FOUND,
  STORES_STORE_SAVED,
  STORES_STORE_NO_DELETED_LAST_STORE,
  STORES_STORE_DELETE_CONFIRMATION_TITLE,
  STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION,
  STORES_STORE_DELETED,
  OK,
  BUTTON_DELETE,
  STORES_TABLE_ARIAL_LABEL,
  STORES_TABLE_COLUMN_NAME,
  STORES_TABLE_COLUMN_CITY,
  STORES_TABLE_COLUMN_ADDRESS,
  STORES_TABLE_COLUMN_EMAIL,
  STORES_TABLE_COLUMN_PHONE,
  STORES_TABLE_COLUMN_STATUS,
  STORES_TABLE_COLUMN_TYPE,
  STORES_STORE_WOOCOMMERCE_DUPLICATE,
  BUTTON_CREATE,
  MODAL_STORE_ARIA_LABEL,
  MODAL_STORE_TYPE_PLACEHOLDER,
  MODAL_STORE_HOW_TO_INTEGRATE_STORE,
  MODAL_STORE_NAME_PLACEHOLDER,
  MODAL_STORE_DESCRIPTION_PLACEHOLDER,
  MODAL_STORE_ADDRESS_PLACEHOLDER,
  MODAL_STORE_CITY_PLACEHOLDER,
  MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER,
  MODAL_STORE_NAME_SHOPIFY_PLACEHOLDER,
  MODAL_STORE_LOOK_NAME_SHOPIFY,
  MODAL_STORE_LOOK_NAME_SHOPIFY_HELPER_TEXT,
  MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER,
  MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER,
  MODAL_STORE_CITIES_PLACEHOLDER,
  MODAL_STORE_ORDERS_METHODS_PLACEHOLDER,
  MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION,
  MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION,
  MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER,
  MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION,
  MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION,
  MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER,
  MODAL_STORE_TYPE_REGULAR_OPTION,
  FIELD_EMAIL_VALID,
  FIELD_SPACES_VALID,
  MODAL_STORE_PHONE_PLACEHOLDER,
  FIELD_PHONE_VALID,
  USERS_TITLE,
  USERS_DESCRIPTION,
  USERS_CONTACTS_TAB,
  USERS_PORTAL_TAB,
  USERS_TABS_ARIA_LABEL,
  USERS_EMAIL_DUPLICATE,
  USERS_USER_CREATED,
  USERS_USER_SAVED,
  USERS_USER_DELETE_CONFIRMATION_TITLE,
  USERS_USER_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_USER_DELETING,
  USERS_USER_DELETED,
  USERS_ONBOARDING_FINISH,
  USERS_USER_SEARCH_BAR_PLACEHOLDER,
  BUTTON_CREATE_USER,
  FILTER,
  OPERATOR,
  ADMIN,
  USERS_TABLE_COLUMN_ROLE,
  USERS_TABLE_COLUMN_STORE,
  USERS_TABLE_COLUMN_EMAIL,
  USERS_TABLE_COLUMN_NAME,
  USERS_TABLE_ARIA_LABEL,
  MODAL_USER_ARIA_LABEL,
  MODAL_USER_TITLE,
  MODAL_USER_NAME_PLACEHOLDER,
  MODAL_USER_ROLE_PLACEHOLDER,
  MODAL_USER_ROLE_OPERATOR_EXPLANATION,
  MODAL_USER_STORE_PLACEHOLDER,
  MODAL_USER_PASSWORD_PLACEHOLDER,
  MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_MIN_LENGTH,
  FIELD_ALL_REQUIRED,
  USERS_CONTACT_CREATED,
  USERS_CONTACT_SAVED,
  USERS_CONTACT_DELETE_CONFIRMATION_TITLE,
  USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_CONTACT_DELETED,
  USERS_CONTACT_SEARCH_BAR_PLACEHOLDER,
  BUTTON_CREATE_CONTACT,
  USERS_CONTACT_TABLE_ARIA_LABEL,
  USERS_CONTACT_TABLE_COLUMN_PHONE,
  MODAL_CONTACT_ARIA_LABEL,
  MODAL_CONTACT_TITLE,
  MODAL_CONTACT_PHONE_PLACEHOLDER,
  BILLING_DATA_TITLE,
  BILLING_DATA_DESCRIPTION,
  BILLING_DATA_SAVED,
  BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION,
  BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION,
  BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER,
  BILLING_AND_PAYMENTS_TITLE,
  BILLING_AND_PAYMENTS_DESCRIPTION,
  BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_PAID_DATE,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_DUE_DATE,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_VALUE,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_REASON,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_STATUS,
  PAYMENT_REASON_COLLABORATION,
  PAYMENT_REASON_APPLICANT,
  PAYMENT_REASON_AMBASSADOR,
  PAYMENT_REASON_CLAPP_CREDITS,
  PAYMENT_REASON_PACKAGE,
  PAYMENT_REASON_PLAN,
  PAYMENT_PAID,
  PAYMENT_PENDING,
  BUTTON_VIEW_DETAILS,
  BUTTON_DOWNLOAD_INVOICE,
  BUTTON_CLOSE,
  TOTAL,
  MODAL_INVOICE_ARIA_LABEL,
  MODAL_INVOICE_TITLE,
  MODAL_INVOICE_DISCLAIMER,
  MODAL_INVOICE_NUMBER_CREDITS,
  MODAL_INVOICE_ORDER_TO_COLLECT,
  MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID,
  MODAL_PAY_INVOICE_ARIA_LABEL,
  MODAL_PAY_INVOICE_TITLE,
  MODAL_PAY_INVOICE_WILL_PAY,
  NO_HAVE,
  BILLING_AND_PAYMENT_CHANGE_YOUR_PLAN,
  BILLING_AND_PAYMENT_ACTIVATE_PLAN,
  BILLING_AND_PAYMENT_SELECT_PLAN,
  BILLING_AND_PAYMENT_NO_HAVE_PLAN,
  BILLING_AND_PAYMENT_PLAN_EXPIRED,
  BILLING_AND_PAYMENT_PLAN_NAME,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT6,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT5,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT4,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT3,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT2,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT1,
  BILLING_AND_PAYMENT_MODEL_USAGE_SHOW_BENEFTIS,
  BILLING_AND_PAYMENT_MODEL_USAGE_WHAT_BENEFITS,
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_NAME,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CHANGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_COLLECT_COST_COLLABORATION,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_ADD_PAYMENT_METHOD_TO_ACTIVATE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_EXPIRED,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE,
  ADD_PAYMENT_METHOD,
  MODAL_SELECT_PACKAGE_ARIA_LABEL,
  MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED,
  MODAL_SELECT_PACKAGE_UPLOAD_GROWTH,
  MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE,
  MODAL_SELECT_PACKAGE_PRICING_DISCOVER_BUZZLY,
  MODAL_SELECT_PACKAGE_UPLOAD_GROWTH_DESCRIPTION,
  MODAL_SELECT_PACKAGE_PRICING_HERO_DESCRIPTION,
  SUPPORT,
  MODAL_SELECT_PACKAGE_VALUE_EXPRESSED,
  CONTACT_US,
  MODAL_SELECT_PACKAGE_PRICE,
  MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH,
  MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT,
  MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME,
  MODAL_SELECT_PACKAGE_COST_COLLABORATION,
  MODAL_SELECT_PACKAGE_BENEFIT_CATALOG,
  MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION,
  MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES,
  MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS,
  MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC,
  MODAL_SELECT_PACKAGE_BENEFIT_KAM,
  MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY,
  MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT,
  MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING,
  MODAL_SELECT_PACKAGE_BENEFIT_ALL_GROWTH,
  MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED,
  MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS,
  MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL,
  MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_WHAT_BENEFITS,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_DISCLAIMER,
  BASIC_PACKAGE_NAME,
  BASIC_PACKAGE_SUB_HEADER,
  BASIC_PACKAGE_DESCRIPTION,
  GROWTH_PACKAGE_NAME,
  GROWTH_PACKAGE_SUB_HEADER,
  GROWTH_PACKAGE_DESCRIPTION,
  ENTERPRISE_PACKAGE_NAME,
  ENTERPRISE_PACKAGE_SUB_HEADER,
  ENTERPRISE_PACKAGE_DESCRIPTION,
  COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION,
  MODAL_FREE_TRIAL_TITLE,
  MODAL_FREE_TRIAL_DESCRIPTION,
  MODAL_FREE_TRIAL_BUTTON,
  ACCEPT_TERMS_PAY,
  DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE,
  QUANTITY_TO_PAY,
  BLOCK_PAYMENT_FAILED_TITLE,
  BLOCK_TRIAL_END_TITLE,
  BLOCK_PLAN_BUTTON,
  MODAL_BUY_CREDITS_ARIA_LABEL,
  MODAL_BUY_CREDITS_TITLE,
  MODAL_BUY_CREDITS_DESCRIPTION,
  MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION,
  MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY,
  MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME,
  MODAL_BUY_CREDITS_ADDITIONAL_CREDITS,
  MODAL_BUY_CREDITS_LAST_TEXT_PAY,
  MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE,
  MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED,
  MODAL_BUY_CREDITS_NEGATIVE,
  MODAL_BUY_CREDITS_PURCHASED,
  MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION,
  MODAL_BUY_CREDITS_GIFT_CREDITS,
  BUTTON_COPY_CATALOG,
  BUTTON_UPLOAD_CATALOG,
  MENU_TITLE,
  MENU_DESCRIPTION,
  MENU_EMPTY_STORES,
  MODAL_COPY_MENU_ARIA_LABEL,
  MODAL_COPY_MENU_TITLE,
  MODAL_COPY_MENU_SAME_STORE,
  MODAL_COPY_MENU_CONFIRM_ALERT_TITLE,
  MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION,
  MODAL_COPY_MENU_COPIED,
  SELECT_CATALOG,
  BUTTON_DOWNLOAD_FORMAT,
  MODAL_UPLOAD_MENU_ARIA_LABEL,
  MODAL_UPLOAD_MENU_TITLE,
  MODAL_UPLOAD_MENU_DESCRIPTION,
  MODAL_UPLOAD_MENU_FILE_PLACEHOLDER,
  MODAL_UPLOAD_MENU_ERROR_FORMAT,
  MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT,
  MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE,
  MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION,
  MODAL_UPLOAD_MENU_UPLOADED,
  BUTTON_EDIT,
  BUTTON_DUPLICATE,
  MENU_ADD_CATEGORY,
  MENU_CATEGORY_NAME_PLACEHOLDER,
  MENU_CATEGORY_REQUIRED,
  BUTTON_MOVE_OTHER_CATEGORY,
  MENU_ADD_PRODUCT,
  MENU_PRODUCT_MIN_VALUE,
  MENU_CREATE_PRODUCT_TITLE,
  MENU_PRODUCT_NAME_PLACEHOLDER,
  MENU_PRODUCT_DESCRIPTION_PLACEHOLDER,
  MENU_PRODUCT_PRICE_PLACEHOLDER,
  MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER,
  MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION,
  MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE,
  MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION,
  MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER,
  MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER,
  BUTTON_COPY,
  FIELD_NUMBER_NEGATIVE,
  MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER,
  MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED,
  MODAL_MOVE_PRODUCT_BUTTON,
  MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE,
  MODAL_MOVE_PRODUCT_ARIA_LABEL,
  PRODUCTS,
  PRODUCT,
  MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL,
  MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER,
  MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED,
  MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED,
  MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED,
  MENU_ONBOARDING_TEST_CATEGORY,
  MENU_ONBOARDING_TEST_PRODUCT,
  MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION,
  MENU_ONBOARDING_FINISH_DESCRIPTION,
  MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION,
  MENU_CATEGORY_DUPLICATE,
  MENU_CATEGORY_CREATED,
  MENU_CATEGORY_SAVED,
  MENU_CATEGORY_DELETE_CONFIRMATION_TITLE,
  MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION,
  MENU_CATEGORY_DELETED,
  MENU_PRODUCT_DUPLICATE,
  MENU_PRODUCT_DUPLICATE_DESCRIPTION,
  MENU_PRODUCT_CREATED,
  MENU_PRODUCT_SAVED,
  MENU_PRODUCT_DELETE_CONFIRMATION_TITLE,
  MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION,
  MENU_PRODUCT_DELETING,
  MENU_PRODUCT_DELETED,
  MENU_PRODUCT_MOVE_SAME_CATEGORY,
  MENU_PRODUCT_MOVED,
  MENU_PRODUCT_CHANGE_STATUS,
  MENU_ACCOMPANIMENT_TYPE_DUPLICATE,
  MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION,
  MENU_ACCOMPANIMENT_TYPE_COPIED,
  MENU_ACCOMPANIMENT_DUPLICATED,
  MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION,
  MENU_CATEGORY_DUPLICATE_DESCRIPTION,
  MENU_CATEGORY_DELETING,
  REGISTER_HERE,
  NO_HAVE_ACCOUNT,
  FORGOT_PASSWORD,
  LOG_IN,
  FIELD_EMAIL_REQUIRED,
  ACCOUNT_ROLE_INVALID,
  ERROR_INTERNAL,
  AUTH_INVALID_PASSWORD,
  AUTH_WRONG_PASSWORD,
  AUTH_MANY_REQUESTS,
  AUTH_USER_NOT_FOUND,
  AUTH_EMAIL_ALREADY_EXISTS,
  STRIPE_CARD_DECLINED,
  STRIPE_EXPIRED_CARD,
  STRIPE_INCORRECT_CVC,
  STRIPE_PROCESSING_ERROR,
  STRIPE_INCORRECT_NUMBER,
  STRIPE_GENERIC_DECLINE,
  STRIPE_INSUFFICIENT_FUNDS,
  STRIPE_LOST_CARD,
  STRIPE_STOLEN_CARD,
  STRIPE_PROFILE_NOT_FOUND,
  STRIPE_INTENT_AUTH_FAILURE,
  NIT_VALID,
  BUSINESS_NAME_VALID,
  BRAND_NAME_VALID,
  ADDRESS_VALID,
  CATEGORY_VALID,
  SUBCATEGORIES_VALID,
  SHORT_DESCRIPTION_VALID,
  ORDERS_METHODS_VALID,
  LOGO_IMG_VALID,
  COVER_IMG_VALID,
  SCHEDULE_VALID,
  STORES_VALID,
  IS_PLAN_ACTIVE_VALID,
  MENUS_VALID,
  DEFAULT_PAYMENT_METHOD_VALID,
  HAS_DEBT_VALID,
  SUBSCRIPTION_PLAN_VALID,
  PROFILE_INFLUENCER_VALID,
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED,
  ALERT_ACTIVATE_SHOP_BY_OWNER_FIELDS_REQUIRED,
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON,
  REMINDER_ACTIVATE_SHOP,
  REGISTER_COUNTRY_ANSWER,
  REGISTER_COUNTRY_PLACEHOLDER,
  REGISTER_NAME_ANSWER,
  REGISTER_PHONE_ANSWER,
  REGISTER_EMAIL_ANSWER,
  REGISTER_BUSINESS_NAME_ANSWER,
  REGISTER_CATEGORY_ANSWER,
  REGISTER_SOCIAL_NETWORK_ANSWER,
  REGISTER_SOCIAL_NETWORK_ANSWER_DESCRIPTION,
  REGISTER_WEBSITE_ANSWER,
  REGISTER_WEBSITE_ANSWER_DESCRIPTION,
  REGISTER_MONTHLY_SALES_ANSWER,
  REGISTER_BUDGET_MARKETING_ANSWER,
  REGISTER_EMPLOYEES_ANSWER,
  REGISTER_PRODUCT_AVAILABILITY_ANSWER,
  REGISTER_RUT_ANSWER,
  REGISTER_INVIMA_ANSWER,
  REGISTER_ICA_ANSWER,
  REGISTER_YOU_MEET_ANSWER,
  REGISTER_WRITE_PLACEHOLDER,
  REGISTER_SELECT_PLACEHOLDER,
  BUTTON_REGISTER,
  BUTTON_NO_HAVE_DOCUMENT,
  BUTTON_UPLOAD_AFTER,
  REGISTER_COUNTRY_REQUIRED,
  REGISTER_NAME_REQUIRED,
  REGISTER_PHONE_REQUIRED,
  REGISTER_PHONE_NUMERIC_REQUIRED,
  REGISTER_BUSINESS_NAME_REQUIRED,
  REGISTER_CATEGORY_REQUIRED,
  REGISTER_SOCIAL_NETWORK_REQUIRED,
  REGISTER_WEB_SITE_REQUIRED,
  REGISTER_MONTHLY_SALES_REQUIRED,
  REGISTER_BUDGET_MARKETING_REQUIRED,
  REGISTER_EMPLOYEES_REQUIRED,
  REGISTER_PRODUCT_AVAILABILITY_REQUIRED,
  REGISTER_RUT_REQUIRED,
  REGISTER_INVIMA_REQUIRED,
  REGISTER_ICA_REQUIRED,
  REGISTER_YOU_MEET_REQUIRED,
  REGISTER_NO_HAVE_DOCUMENTS_ALERT_TITLE,
  REGISTER_NO_HAVE_DOCUMENTS_ALERT_DESCRIPTION,
  REGISTER_REGISTERED_ALERT_DESCRIPTION,
  RESTAURANTS,
  FOODS,
  TRAVEL,
  ENTERTAINMENT,
  TECHNOLOGY,
  FITNESS,
  FASHION,
  BEAUTY,
  PETS,
  HOME,
  EMPLOYEES_2_10,
  EMPLOYEES_10_20,
  EMPLOYEES_20_50,
  EMPLOYEES_50_100,
  EMPLOYEES_100_THAN,
  PRODUCT_AVAILABILITY_LOCAL,
  PRODUCT_AVAILABILITY_NATIONAL,
  PRODUCT_AVAILABILITY_INTERNATIONAL,
  INSTAGRAM,
  TIKTOK,
  LINKEDIN,
  GOOGLE,
  DARK_KITCHEN,
  OTHERS,
  PLEASE_SELECT_COUNTRY,
  BLOCK_PACKAGE_TITLE,
  BLOCK_PACKAGE_DESCRIPTION,
  BLOCK_PLAN_BUTTON_ACTIVATE_PLAN,
  BILLING_AND_PAYMENT_PLAN_TRIAL,
  BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION,
  BILLING_AND_PAYMENT_PLAN_DEACTIVATE,
  BUTTON_INVITED,
  MAIN_FUNCTIONALITY_STEP_1,
  MAIN_FUNCTIONALITY_STEP_2,
  MAIN_FUNCTIONALITY_STEP_3,
  MODAL_INVITATION_STEP_1,
  MODAL_INVITATION_STEP_2,
  MODAL_INVITATION_STEP_3,
  MODAL_INVITATION_STEP_4,
  MODAL_INVITATION_STEP_5,
  PUBLICATIONS_FUNCTIONALITY_STEP_1,
  PUBLICATIONS_FUNCTIONALITY_STEP_2,
  PUBLICATIONS_FUNCTIONALITY_STEP_3,
  PUBLICATIONS_FUNCTIONALITY_STEP_4,
  PUBLICATION_FUNCTIONALITY_STEP_1,
  PUBLICATION_FUNCTIONALITY_STEP_2,
  PUBLICATION_FUNCTIONALITY_STEP_3,
  REVIEW_FUNCTIONALITY_STEP_1,
  REVIEW_FUNCTIONALITY_STEP_2,
  REVIEW_FUNCTIONALITY_STEP_3,
  REVIEW_FUNCTIONALITY_STEP_4,
  REVIEW_FUNCTIONALITY_STEP_5,
  MENU_FUNCTIONALITY_STEP_1,
  MENU_FUNCTIONALITY_STEP_2,
  MENU_FUNCTIONALITY_STEP_3,
  MENU_FUNCTIONALITY_STEP_4,
  MENU_FUNCTIONALITY_STEP_5,
  MENU_FUNCTIONALITY_STEP_6,
  MENU_FUNCTIONALITY_STEP_7,
  MENU_FUNCTIONALITY_STEP_8,
  MENU_FUNCTIONALITY_STEP_9,
  MENU_FUNCTIONALITY_STEP_10,
  MENU_FUNCTIONALITY_STEP_11,
  MENU_FUNCTIONALITY_STEP_12,
  USERS_FUNCTIONALITY_STEP_1,
  USERS_FUNCTIONALITY_STEP_2,
  USERS_FUNCTIONALITY_STEP_3,
  USERS_FUNCTIONALITY_STEP_4,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_1,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_2,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_3,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_4,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_5,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_6,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_7,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_8,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_9,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_10,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_11,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_12,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_13,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_14,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_15,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_16,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_17,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_18,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_19,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_20,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_21,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_22,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_23,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_24,
  AMBASSADORS_FUNCTIONALITY_STEP_1,
  AMBASSADORS_FUNCTIONALITY_STEP_2,
  AMBASSADORS_FUNCTIONALITY_STEP_3,
  AMBASSADORS_FUNCTIONALITY_STEP_4,
  AMBASSADORS_FUNCTIONALITY_STEP_5,
  AMBASSADORS_FUNCTIONALITY_STEP_6,
  AMBASSADORS_FUNCTIONALITY_STEP_7,
  AMBASSADORS_FUNCTIONALITY_STEP_8,
  AMBASSADORS_FUNCTIONALITY_STEP_9,
  AMBASSADORS_FUNCTIONALITY_STEP_10,
  AMBASSADORS_FUNCTIONALITY_STEP_11,
  DISCOVERY_FUNCTIONALITY_STEP_1,
  DISCOVERY_FUNCTIONALITY_STEP_2,
  DISCOVERY_FUNCTIONALITY_STEP_3,
  DISCOVERY_FUNCTIONALITY_STEP_4,
  DISCOVERY_FUNCTIONALITY_STEP_5,
  DISCOVERY_FUNCTIONALITY_STEP_6,
  DISCOVERY_FUNCTIONALITY_STEP_7,
  DISCOVERY_FUNCTIONALITY_STEP_8,
  DISCOVERY_FUNCTIONALITY_STEP_9,
  DISCOVERY_FUNCTIONALITY_STEP_10,
  DISCOVERY_FUNCTIONALITY_STEP_11,
  DISCOVERY_FUNCTIONALITY_STEP_12,
  DISCOVERY_FUNCTIONALITY_STEP_13,
  DISCOVERY_FUNCTIONALITY_STEP_14,
  DISCOVERY_FUNCTIONALITY_STEP_15,
  DISCOVERY_FUNCTIONALITY_STEP_16,
  DISCOVERY_FUNCTIONALITY_STEP_17,
  CAMPAIGNS_FUNCTIONALITY_STEP_1,
  CAMPAIGNS_FUNCTIONALITY_STEP_2,
  CAMPAIGNS_FUNCTIONALITY_STEP_3,
  CAMPAIGNS_FUNCTIONALITY_STEP_4,
  CAMPAIGNS_FUNCTIONALITY_STEP_5,
  CAMPAIGNS_FUNCTIONALITY_STEP_6,
  CAMPAIGNS_FUNCTIONALITY_STEP_7,
  CAMPAIGNS_FUNCTIONALITY_STEP_8,
  CAMPAIGNS_FUNCTIONALITY_STEP_9,
  CAMPAIGNS_FUNCTIONALITY_STEP_10,
  CAMPAIGNS_FUNCTIONALITY_STEP_11,
  CAMPAIGNS_FUNCTIONALITY_STEP_12,
  CAMPAIGNS_FUNCTIONALITY_STEP_13,
  CAMPAIGNS_FUNCTIONALITY_STEP_14,
  CAMPAIGNS_FUNCTIONALITY_STEP_15,
  CAMPAIGNS_FUNCTIONALITY_STEP_16,
  CAMPAIGNS_FUNCTIONALITY_STEP_17,
  STORES_FUNCTIONALITY_STEP_1,
  STORES_FUNCTIONALITY_STEP_2,
  STORES_FUNCTIONALITY_STEP_3,
  SHOP_ID_USER_ID_NOT_SENT,
  AMBASSADOR_DELETE,
  AMBASSADOR_DELETE_DESCRIPTION,
  AUTH_SENT_EMAIL_RESET_PASSWORD,
  EVENT_RECURRENT_REQUIRED,
  EVENT_HAS_FIELDS_REQUIRED,
  PROFILE_INFLUENCER_INCOMPLETE,
  MENU_HAS_ELEMENTS_CREATED,
  INVOICE_CREATED_FOR_RETRY_PAYMENT,
  BILLING_AND_PAYMENT_PLAN_CANCELLED,
  COMPANY_INFORMATION_SAVED,
  OPERATOR_USER_STORE,
  STORE_NO_HAS_MENU,
  STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT,
  STORE_COVERAGE_RADIUS_REQUIRED,
  ALERT_INACTIVE_SHOP_TITLE,
  ALERT_INACTIVE_SHOP_DESCRIPTION,
  ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION,
  BUTTON_YES_I_SURE,
  ERROR_COMPONENT_CURRENT_ROUTE,
  ERROR_COMPONENT_BUTTON_RELOAD_PAGE,
  ERROR_COMPONENT_DESCRIPTION,
  ERROR_COMPONENT_TITLE,
  LESS_500K,
  BETWEEN_500K_1M,
  BETWEEN_1M_2_5M,
  BETWEEN_2_5M_5M,
  MORE_5M,
  LESS_1M,
  BETWEEN_1M_5M,
  BETWEEN_5M_15M,
  BETWEEN_15M_30M,
  BETWEEN_30M_100M,
  MORE_100M,
  LESS_150,
  BETWEEN_150_250,
  BETWEEN_250_650,
  BETWEEN_650_1250,
  MORE_1250,
  LESS_250,
  BETWEEN_250_1250,
  BETWEEN_1250_3750,
  BETWEEN_3750_7500,
  BETWEEN_7500_25K,
  MORE_25K,
  DOCUMENT_CC,
  DOCUMENT_CE,
  DOCUMENT_NUIP,
  DOCUMENT_NIT,
  DOCUMENT_CI,
  DOCUMENT_PASSPORT,
  DOCUMENT_ID,
  DOCUMENT_RUC,
  MISSING_PARAMETERS,
  PASSWORD_INVALID,
  USER_ID_REQUIRED,
  SHOP_ID_REQUIRED,
  LAST_USER_NOT_DELETED,
  PLAN_TRIAL_USER_USED,
  ORDERS_NOT_FOUND_IN_INTERVAL,
  TYPE_REPORT_NOT_FOUND,
  SHOP_NOT_FOUND,
  PAYMENT_MODEL_INVALID,
  SHOP_NOT_HAS_SOCIAL_NETWORK,
  NO_VALID_PLATFORM_FOUND,
  GETTING_STATISTICS,
  SHOP_BUSINESS_NAME_NOT_FOUND,
  INFLUENCERS_SUGGESTED_NOT_FOUND,
  REFERENCE_PROFILES_REQUIRED,
  INFLUENCERS_SIMILAR_NOT_FOUND,
  DECRYPTING_PASSWORD,
  USER_ID_AND_PAYMENT_ID,
  MESSAGES_REQUIRED,
  PLAN_IS_NO_ACTIVE,
  USER_BANNED,
  GETTING_LOCALES,
  GETTING_PROFILE,
  PRODUCTS_NOT_SENT,
  INVITATION_NOT_FOUND,
  TOTAL_PRICE_EXCEEDED_VALUE_INVITATION,
  ACCOUNT_NOT_FOUND,
  COLLECTING_INFORMATION_RETRY_AFTER,
  ERROR_OCURRED,
  PLATFORM_NOT_SENT,
  VERIFICATION_CODE_NOT_FOUND,
  ORDER_NOT_FOUND,
  INVITE_ID_NO_SENT,
  INVITE_NOT_FOUND,
  INVITE_CLOUD_TASK_ID_NOT_FOUND,
  TYPE_OR_PATH_NOT_SENT,
  ID_OR_PATH_NOT_SENT,
  IDS_NOT_SENT,
  ANALYTIC_URL_NOT_SENT,
  ANALYTIC_URL_INVALID,
  EXTRACTING_ANALYTICS,
  CREDENTIALS_INVALID,
  COLLABORATION_PRICE_NOT_BE_NEGATIVE,
  CREDITS_NOT_BE_NEGATIVE,
  PACKAGES_PRICES_NOT_BE_NEGATIVE,
  ROL_REQUIRED,
  SHOP_ID_AND_STORE_ID_REQUIRED,
  PASSWORD_MUST_BE_6_CHARACTERS,
  ALL_USERS_NOT_DELETE,
  SHOP_APPLICANT_NOT_FOUND,
  USER_PASSWORD_NOT_FOUND,
  PLAN_NOT_FOUND,
  SUBSCRIPTION_NOT_CREATED,
  ID_SUBSCRIPTION_NOT_FOUND,
  SUBSCRIPTION_REACTIVATE,
  CUSTOMER_ID_NOT_FOUND,
  APPLICANT_NOT_FOUND,
  SHOP_COUNTRY_NOT_FOUND,
  FINAL_PRICE_NOT_FOUND,
  PAYMENT_FAILED,
  CREDITS_MUST_BE_GREATER_THAN_ZERO,
  AMBASSADOR_NOT_FOUND,
  CSRF_INVALID,
  INTEGRATION_INFORMATION_NOT_FOUND,
  GETTING_VIDEO,
  UPLOAD_URL_NOT_FOUND,
  UPLOADING_VIDEO,
  STORE_NOT_FOUND,
  NAME_REQUIRED,
  PRICE_REQUIRED,
  CURRENCY_REQUIRED,
  RECURRING_REQUIRED,
  CREATING_PRODUCT,
  CREATING_PRICE,
  CREATING_PAYMENT_LINK,
  GETTING_PAYMENT_LINK,
  INVITE_COMMENT_REQUIRED,
  INVITE_PERSONALIZED_URL_EXPLANATION,
  INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION,
  INVITE_FORM_TYPE_CONTENT,
  INVITE_FORM_TYPE_CONTENT_IMAGES,
  INVITE_FORM_TYPE_CONTENT_VIDEOS,
  INVITE_FORM_TYPE_CONTENT_REQUIRED,
  MODAL_REVIEW_SCORE_PLACEHOLDER,
  SCORE,
  INVITE_FORM_COMMENT_PLACEHOLDER_3,
  CHAT_ERROR_SENT_MESSAGE,
  CHAT_DO_PAYMENT,
  CHAT_WRITE_QUESTION_HERE,
  CHAT_WRITE_MESSAGE_HERE,
  CHAT_EMAIL_NO_PERMITTED,
  RESET_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD,
  RESET_PASSWORD_GET_TOKEN_FAILED,
  RESET_PASSWORD_SAVED,
  TOKEN_INVALID,
  TOKEN_EXPIRED,
  NOTIFICATIONS_TITLE,
  NOTIFICATIONS_MARK_READS,
  NOTIFICATIONS_EMPTY,
  NOTIFICATIONS_NO_MORE,
  NOTIFICATIONS_CHECK_NOTIFICATION,
  ONE_SECOND_AGO,
  DATE_DISTANCE,
  CAMPAIGN_FINISH_ONBOARDING,
  CHAT_FIRST_MESSAGE_BOT,
  OWN_DELIVERY,
  CHAT_NO_MORE,
  PUBLICATION_STATUS_PENDING_CONTENT,
  PUBLICATION_STATUS_PENDING_APPROVAL,
  BUTTON_SEND_REVISION,
  BUTTON_APPROVE_CONTENT,
  REVISION_COUNT,
  ONE,
  TWO,
  THREE,
  DATE_HOUR,
  ORDER_CANCELLED_CONFIRMATION,
  ORDER_CANCELLED_CONFIRMATION_BUTTON,
  COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER,
  IG_ACCOUNT_NOT_FOUND,
  IG_DATA_NOT_FOUND,
  BUTTON_UPLOAD_INSTAGRAM,
  BUTTON_CONNECT_INSTAGRAM,
  PLATFORMS_CONNECTED_INSTAGRAM_FAILED,
  MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TITLE,
  MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION,
  MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER,
  MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL,
  PLATFORMS_TOKEN_EXPIRED,
  INTEGRATIONS_TERMS_TITLE,
  INTEGRATIONS_TERMS_DESCRIPTION,
  INTEGRATIONS_TERMS_CONTENT,
  MODAL_FUNCTIONALITY_AMBASSADOR,
  MODAL_FUNCTIONALITY_DISCOVERY,
  MODAL_FUNCTIONALITY_CAMPAIGNS,
  LESS_1000,
  BETWEEN_1000_5000,
  BETWEEN_5000_15K,
  BETWEEN_15K_30K,
  BETWEEN_30K_60K,
  MORE_60K,
  BETWEEN_1000_2000,
  BETWEEN_2000_5000,
  BETWEEN_5000_10K,
  MORE_10K,
  DOCUMENT_EIN,
  MENU_SERVICES,
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE,
  RESTAURANTS_SUBCATEGORY_FOOD,
  RESTAURANTS_SUBCATEGORY_DRINKS,
  RESTAURANTS_SUBCATEGORY_DESSERTS,
  RESTAURANTS_SUBCATEGORY_COFFEE,
  RESTAURANTS_SUBCATEGORY_HOT_DRINKS,
  RESTAURANTS_SUBCATEGORY_COLD_DRINKS,
  RESTAURANTS_SUBCATEGORY_SOFT_DRINKS,
  RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS,
  RESTAURANTS_SUBCATEGORY_SUSHI,
  RESTAURANTS_SUBCATEGORY_ASIAN,
  RESTAURANTS_SUBCATEGORY_JAPANESE,
  RESTAURANTS_SUBCATEGORY_CHINESE,
  RESTAURANTS_SUBCATEGORY_ITALIAN,
  RESTAURANTS_SUBCATEGORY_MEXICAN,
  RESTAURANTS_SUBCATEGORY_HEALTHY,
  RESTAURANTS_SUBCATEGORY_VEGAN,
  RESTAURANTS_SUBCATEGORY_VEGETARIAN,
  FOODS_SUBCATEGORY_SEASONINGS,
  FOODS_SUBCATEGORY_FOOD,
  FOODS_SUBCATEGORY_GRANOLA,
  FOODS_SUBCATEGORY_DRIED_FRUITS,
  FOODS_SUBCATEGORY_HEALTHY,
  TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM,
  TRAVEL_SUBCATEGORY_RURAL_TOURISM,
  TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM,
  TRAVEL_SUBCATEGORY_NATURE_TOURISM,
  TRAVEL_SUBCATEGORY_CULTURAL_TOURISM,
  TRAVEL_SUBCATEGORY_HEALTH_TOURISM,
  ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT,
  TECHNOLOGY_SUBCATEGORY_PHONES,
  TECHNOLOGY_SUBCATEGORY_COMPUTERS,
  TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES,
  TECHNOLOGY_SUBCATEGORY_ACCESSORIES,
  TECHNOLOGY_SUBCATEGORY_PERIPHERALS,
  TECHNOLOGY_SUBCATEGORY_ELECTRONICS,
  FITNESS_SUBCATEGORY_SPORTSWEAR,
  FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES,
  FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS,
  FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT,
  FASHION_SUBCATEGORY_HAUTE_COUTURE,
  FASHION_SUBCATEGORY_HIPSTER,
  FASHION_SUBCATEGORY_CASUAL,
  FASHION_SUBCATEGORY_CLASSIC,
  FASHION_SUBCATEGORY_ROMANTIC,
  FASHION_SUBCATEGORY_SPORTSWEAR,
  FASHION_SUBCATEGORY_PREPPY,
  FASHION_SUBCATEGORY_GRUNGE,
  FASHION_SUBCATEGORY_VINTAGE,
  BEAUTY_SUBCATEGORY_COSMETICS,
  BEAUTY_SUBCATEGORY_EYE_CARE,
  BEAUTY_SUBCATEGORY_FEMININE_HYGIENE,
  BEAUTY_SUBCATEGORY_FIRST_AID,
  BEAUTY_SUBCATEGORY_HAIR_CARE,
  BEAUTY_SUBCATEGORY_MEDICAL_DEVICES,
  BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT,
  BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS,
  BEAUTY_SUBCATEGORY_MEDICATIONS,
  BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE,
  BEAUTY_SUBCATEGORY_SCALES,
  BEAUTY_SUBCATEGORY_SKIN_CARE,
  BEAUTY_SUBCATEGORY_VITAMINS,
  BEAUTY_SUBCATEGORY_FACIAL_TISSUES,
  PETS_SUBCATEGORY_FOOD,
  PETS_SUBCATEGORY_COSMETICS,
  PETS_SUBCATEGORY_ACCESSORIES,
  PETS_SUBCATEGORY_CLOTHING,
  PETS_SUBCATEGORY_MEDICATIONS,
  HOME_SUBCATEGORY_TEXTILES_CARPETS,
  HOME_SUBCATEGORY_ART_PRINTS,
  HOME_SUBCATEGORY_STORAGE_ORGANIZATION,
  HOME_SUBCATEGORY_PLANT_DECOR,
  HOME_SUBCATEGORY_CURTAINS_BLINDS,
  UPLOAD_NEW_CONTENT,
  MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL,
  BUTTON_RELOAD_PAGE,
  NEW_VERSION_TITLE,
  NEW_VERSION_DESCRIPTION,
  DELIVERY_INTERN_ON_THE_WAY,
  FIELD_EMAIL_REGISTERED,
  MODAL_BLOCK_LAST_INVITE_TITLE,
  MODAL_BLOCK_LAST_INVITE_DESCRIPTION,
  MODAL_BLOCK_LAST_INVITE_COUNT,
  INVITATIONS,
  MODAL_DISSATISFACTION_COLLABORATION_TITLE,
  MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION,
  MODAL_DISSATISFACTION_COLLABORATION_BUTTON,
  MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT,
  REVIEW_NOT_FOUND,
  REVIEW_IS_NOT_ONE_START,
  DISSATISFACTION_CREDIT_ALREADY_EXISTS,
  MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED,
  BUTTON_REPLACE_IMAGE,
  ADD_URL_PUBLICATION,
  VALUE_EXPRESSED_IN,
  ANY,
  GENDER,
  MESSAGE_AFTER_INVITATION,
  FIELD_SEARCH_CHATS_MORE_3_CHARACTERS,
  INFLUENCER_NO_REDEEM_CODE,
  ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION,
  MODAL_SUBSCRIPTION_PRO_ARIA_LABEL,
  MODAL_SUBSCRIPTION_PRO_TITLE_HERO,
  MODAL_SUBSCRIPTION_PRO_DESCRIPTION_HERO,
  MODAL_SUBSCRIPTION_PRO_TITLE,
  MODAL_SUBSCRIPTION_PRO_SUB_HEADER,
  MODAL_SUBSCRIPTION_PRO_BENEFIT_1,
  MODAL_SUBSCRIPTION_PRO_BUY_BUTTON,
  PAYMENT_REASON_SUBSCRIPTION_PRO,
  NO_ADDED,
  BUTTON_SEE_PUBLICATION_ON,
  EDIT_AMBASSADOR,
  SEND_REVISION_INFLUENCER_COMMENT,
  REVISIONS,
  CURRENT_CONTENT,
  CURRENT_CONTENT_EMPTY,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_ENGAGEMENT_RANGE,
  PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED,
  VIEW_SUGGESTED_CREATORS,
  AUTOMATIC_INVITATIONS_VALID,
  CREATORS_SUGGESTED_BAD_CONFIGURATION_AUTOMATIC_INVITATIONS,
  GO_TO_AUTOMATIC_INVITATIONS,
  MALE,
  FEMALE,
  COMMENT,
  NO_SEND_CONTENT_FEEDBACK,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_CODE_PLACEHOLDER,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_PERCENTAGE_PLACEHOLDER,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_TITLE,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_DESCRIPTION,
  FIELD_NUMBER_0_OR_NEGATIVE,
  FIELD_NUMBER_100_GREATER,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_2,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_1,
  VALUE_GENERAL_CODE_REDEEMED,
  VALUE_WITH_PERCENTAGE,
  FIELD_NUMBER_LESS_THAN,
  FIELD_NUMBER_GREATER_THAN,
  OWNER_USERS_TITLE,
  OWNER_USERS_SEARCH_BAR,
  OWNER_USERS_TABLE_ARIA_LABEL,
  OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE,
  OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION,
  OWNER_USERS_DELETE_USER_DELETING,
  OWNER_USERS_DELETE_USER_DELETED,
  OWNER_USERS_FAILED_DELETE_USER,
  COLUMN_NAME_SHOP_ID,
  COLUMN_NAME_SHOP,
  OWNER_USERS_DELETE_USER_CONFIRM,
  OWNER_USERS_CREATED_USER,
  OWNER_USERS_SAVED_USER,
  OWNER_USERS_MODAL_ARIA_LABEL,
  OWNER_USERS_MODAL_TITLE,
  SUPER_ADMIN,
  SELECT_BUSINESS_PLACEHOLDER,
  NAME_AND_LAST_NAME_PLACEHOLDER,
  SELECT_STORE_PLACEHOLDER,
  SELECT_BRAND_PLACEHOLDER,
  MAIN_BRAND_PLACEHOLDER,
  SELECT_BRANDS_PLACEHOLDER,
  NAME_BUSINESS_PLACEHOLDER,
  FIELD_PASSWORD_CHANGE_MIN_LENGTH,
  OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE,
  OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES,
  STORES_STORE_SPECIAL_STORE_EXISTS,
  ORDER_TOTAL_VALUE_DISCOUNT,
  UPDATING_ANALYTICS,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION,
  GO_TO_ACCOUNT,
  ORDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_DESCRIPTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED,
  CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE,
  SHOPS_APPLICANTS_TITLE,
  SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER,
  SHOPS_APPLICANTS_TABLE_ARIA_LABEL,
  SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_TITLE,
  SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_DESCRIPTION,
  SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_BUTTON,
  SHOPS_APPLICANTS_REJECT_SHOP_REJECTING,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_ARIA_LABEL,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_TITLE,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_EMPTY,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_RUT,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_INVIMA,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_ICA,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_OPEN,
  SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_ARIA_LABEL,
  SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_TITLE,
  SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_CREATE_BRAND,
  SELECT_TYPE_PLAN_PLACEHOLDER,
  COST_COLLABORATION_PLACEHOLDER,
  CREDITS_PLACEHOLDER,
  TRIAL_DAYS_PLACEHOLDER,
  TYPE_PACKAGE_PLACEHOLDER,
  PRICE_BASIC_PLACEHOLDER,
  COST_COLLABORATION_BASIC_PLACEHOLDER,
  PRICE_GROWTH_PLACEHOLDER,
  COST_COLLABORATION_GROWTH_PLACEHOLDER,
  PLAN_PAY_PER_USE,
  PLAN_PACKAGE,
  PLAN_REGULAR,
  PLAN_ENTERPRISE,
  SHOPS_ACCEPTED_TITLE,
  SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED,
  SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED,
  SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR,
  SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE,
  SHOPS_ACCEPTED_TABLE_ARIA_LABEL,
  SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL,
  COLUMN_NAME_BRAND,
  COLUMN_NAME_CATEGORY,
  COLUMN_NAME_IS_LOGGED,
  COLUMN_NAME_IS_ACTIVE,
  COLUMN_NAME_PACKAGE,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_NAME,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_STAFF,
  COLUMN_NAME_ACCEPTED,
  COLUMN_NAME_MONTHLY_SALES,
  COLUMN_NAME_BUDGET_MARKETING,
  COLUMN_NAME_EMPLOYEES,
  COLUMN_NAME_PRODUCT_AVAILABILITY,
  COLUMN_NAME_SOCIAL_NETWORK,
  COLUMN_NAME_WEB_SITE,
  COLUMN_NAME_YOU_MEET,
  COLUMN_NAME_DATE,
  COMMERCIAL_PLACEHOLDER,
  ADD_NEW_OPTION,
  MODAL_COMMERCIAL_TITLE_CREATE,
  MODAL_COMMERCIAL_TITLE_EDIT,
  MODAL_COMMERCIAL_CREATE_COMMERCIAL,
  MODAL_COMMERCIAL_ALREADY_EXIST,
  BUTTON_ADD_SHOP,
  SHOPS_TITLE,
  SHOPS_SEARCH_BAR_PLACEHOLDER,
  SHOPS_TABLE_ARIA_LABEL,
  COLUMN_NAME_PLAN,
  COLUMN_NAME_PAYMENT_METHOD,
  COLUMN_NAME_COST_COLLABORATION,
  COLUMN_NAME_CREDITS,
  COLUMN_NAME_COUNTRY,
  PLAN_PAY_PER_USE_NAME,
  PLAN_PACKAGE_NAME,
  BUSINESS_NAME_PLACEHOLDER,
  SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL,
  SHOPS_MODAL_CREATE_SHOP_TITLE,
  SHOPS_SHOP_CREATE,
  FIELD_CREDITS_NOT_BE_NEGATIVE,
  FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE,
  FIELD_PLAN_PRICES_NOT_BE_NEGATIVE,
  FIELD_STAFF_IS_REQUIRED,
  FIELD_TRIAL_MONTHS_NOT_BE_NEGATIVE,
  SHOP_DETAIL_TITLE,
  SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK,
  USERS,
  SHOP_DETAIL_HAS_BILLING_CYCLE,
  STORES,
  PLAN,
  AUTOMATIC_RENOVATION,
  DEACTIVATED,
  ACTIVE,
  MODAL_CREATE_PAYMENT_LINK_TITLE,
  MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL,
  MODAL_CREATE_PAYMENT_LINK_LINKS,
  MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY,
  MODAL_CREATE_PAYMENT_LINK_NAME,
  DESCRIPTION_OPTIONAL_PLACEHOLDER,
  PRICE_PLACEHOLDER,
  CURRENCY_PLACEHOLDER,
  IS_RECURRENCE_PLACEHOLDER,
  RECURRENCE_PLACEHOLDER,
  RECURRENT_OPTION,
  RECURRING_DAYS,
  RECURRING_WEEKS,
  RECURRING_MONTHS,
  RECURRING_YEARS,
  FIELD_BOTH_DATES_REQUIRED,
  FIELD_START_DATE_GREATER_THAN_END_DATE,
  SOMETHING_ERROR_GENERATING_STATISTICS,
  SHOP_DETAIL_REPORT_TITLE,
  SHOP_DETAIL_REPORT_SELECT_DATES,
  BUTTON_DOWNLOAD_REPORT,
  REPORT_ORDERS,
  ORDERS,
  DELIVERIES,
  IN_STORE,
  ORDERS_PENDING,
  ORDERS_PENDING_ANALYTICS,
  ORDERS_FEEDBACK,
  ORDERS_SENT,
  ORDERS_REVIEWED,
  CREATOR,
  VALUE,
  WAS_GENERATED,
  MODAL_BLOCK_LAST_INVITE_BUTTON,
  META_INTEGRATION_UNLINKED_PAGES_ERROR,
  PROFILE_INFLUENCER_INFORMATION_AUDIENCE_CITIES_PLACEHOLDER,
  EDIT,
  MENU_MEDIA,
  CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA,
  CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION,
  CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER,
  THIS_CREATOR_IS_FAVORITE,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  REDEEM_CODE_TABLE_COLUMN_TERMS,
  BUTTON_GENERATE_COMMENT,
  FAVORITES_CATEGORY,
  EMAIL,
  INVITE_FORM_CODE_REQUIRED,
  INVITE_FORM_CODE_MIN_LENGTH,
  INVITE_FORM_ORDER_FROM_PLACE_HOLDER,
  INVITE_FORM_ORDER_FROM_HELPER_TEXT,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_LABEL,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_TOP_FIVE_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION,
  BUTTON_GENERATE_CONTENT,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_EMPTY_CONTENT,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_GENERATING_CONTENT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TONES_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_TEXT_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_BACKGROUND_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_CONTENTS_REQUIRED,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULE_BUTTON,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULED_CONTENT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION,
  BUTTON_UPLOAD_LOGO,
  BUTTON_REPLACE_LOGO,
  BUTTON_UPLOAD_BACKGROUND,
  BUTTON_REPLACE_BACKGROUND,
  BUTTON_REMOVE_BACKGROUND,
  BUTTON_REGENERATE_CONTENT,
  SUB_MENU_SOCIAL_FEED,
  CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION,
  ORDERS_REMEMBER_MARK_AS_DELIVERED,
  BUTTON_CONTINUOUS,
  CREATE_CAMPAIGN_GOAL_QUESTION,
  CREATE_CAMPAIGN_GOAL_PLACEHOLDER,
  CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION,
  CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION,
  CREATE_CAMPAIGN_GOAL_LEADS_OPTION,
  CREATE_CAMPAIGN_GOAL_SALES_OPTION,
  CREATE_CAMPAIGN_GOAL_REQUIRED,
  CAMPAIGN_GOAL_PLACEHOLDER,
  MODAL_SELECT_PACKAGE_BENEFIT_COLLABORATE_WITH_CREATOR,
  MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS,
  MODAL_SELECT_PACKAGE_BENEFIT_DOWNLOAD_CONTENT,
  MODAL_SELECT_PACKAGE_BENEFIT_ANALYZE_METRICS,
  CAMPAIGN_REPOST_PLACEHOLDER_ITEM,
  PAYMENT_METHOD_NOT_FOUND,
  HAS_PENDING_PAYMENTS,
  CREATE_CAMPAIGN_TRAFFIC_LINK_VALID,
  ORDER_ID,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_LANGUAGE_LABEL,
  ENGLISH,
  SPANISH,
  CAMPAIGN_FINISHED_TIME,
  DATE_SHORT_LOCALIZED,
  DATE_LONG_LOCALIZED,
  VIEW,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL,
  FIELD_MAX_LENGTH,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING,
  BUTTON_GENERATE_OTHER,
  MODAL_INVOICE_SUBSCRIPTION,
  MODAL_INVOICE_APPLICANT,
  MODAL_INVOICE_AMBASSADOR,
  MODAL_INVOICE_FEE,
  MODAL_INVOICE_IVA,
  MODAL_INVOICE_COLLABORATION,
  MODAL_INVOICE_CREDITS,
  MODAL_INVOICE_PLAN_ENTERPRISE_WITH_CREDITS,
  BUTTON_SUBSCRIBE_NOW,
  PRO_PACKAGE_NAME,
  FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE,
  PENDING,
  ACCEPTED,
  ARE_YOU_SURE,
  CHANGE,
  SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION,
  BUTTON_PASS_TO_SUBSCRIPTION_BASIC,
  REQUESTS_INFLUENCERS_ACCOUNT_TITLE,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_CITY,
  COLUMN_NAME_AUDIENCE,
  COLUMN_NAME_ENGAGEMENT,
  COLUMN_NAME_CATEGORIES,
  COLUMN_NAME_SCORE,
  COLUMN_NAME_ACTIVITY,
  COLUMN_NAME_CREATOR_PLATFORM,
  COLUMN_NAME_VALUE_INVITATION,
  COLUMN_NAME_ACTIONS,
  COLUMN_NAME_CODE_INVITATION,
  COLUMN_NAME_INVITATION,
  COLUMN_NAME_USE_INVITATION,
  COLUMN_NAME_EXPIRATION_INVITATION,
  COLUMN_NAME_TYPE_INVITATION,
  COLUMN_NAME_REJECTED_REASON_INVITATION,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_REDEEMED_DATE,
  COLUMN_NAME_TERMS,
  COLUMN_NAME_TYPE,
  COLUMN_NAME_ADDRESS,
  COLUMN_NAME_STORE,
  COLUMN_NAME_ROLE,
  COLUMN_NAME_REASON,
  COLUMN_NAME_DUE_DATE,
  COLUMN_NAME_PAID_DATE,
  COLUMN_NAME_VALUE,
  COLUMN_NAME_ID,
  COLUMN_NAME_CREATOR,
  COLUMN_NAME_CREATED_DATE,
  COLUMN_NAME_ACCEPTED_DATE,
  COLUMN_NAME_DETAILS,
  COLUMN_NAME_DELIVERY,
  COLUMN_NAME_CODE_VERIFICATION,
  COLUMN_NAME_ACCOUNT_NAME,
  COLUMN_NAME_IS_HIDDEN,
  COLUMN_NAME_COMMENT,
  SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION,
  SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE,
  SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE,
  SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION,
  SHOP_DETAIL_REPORT_EXTENDING_INVITE,
  BUTTON_EXTEND,
  SHOP_DETAIL_REPORT_INVITATION_EXTENDED,
  REPORT_REQUESTS,
  REQUESTS_ACCEPTED,
  REQUESTS_REJECTED,
  REQUESTS_ACTIVE,
  REPORT_INVITATIONS,
  CODE,
  IS_AUTOMATIC,
  REPORT_INVITATIONS_INACTIVE,
  REDEEMED,
  EXPIRED,
  EXPIRED_SINGULAR,
  REDEEMED_SINGULAR,
  DELIVERIES_OWNER_TITLE,
  DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER,
  DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER,
  DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER,
  DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION,
  REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS,
  REQUEST_INFLUENCERS_CODE_SENT_STATUS,
  REQUEST_INFLUENCERS_PENDING_SENT_CODE,
  REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER,
  EMPTY_RESULTS_SHOW_ON_THIS_PAGE,
  CONTENT_TITLE,
  REPORTS_TITLE,
  REPORTS_DESCRIPTION,
  REPORTS_ERROR_GENERATING,
  REPORTS_INVITATIONS_ACTIVE,
  REPORTS_INVITATIONS_INACTIVE,
  REPORTS_REQUESTS_ACTIVE,
  REPORTS_REQUESTS_INACTIVE,
  REPORTS_TYPE_USERS,
  REPORTS_NUMBER_USERS,
  REPORTS_TYPE_ORDER,
  REPORTS_NUMBER_ORDER,
  REJECTED,
  ROLE,
  REPORTS_MODAL_DESCRIPTION,
  REPORTS_MODAL_ONLY_BRANDS_ACTIVE,
  REPORTS_MODAL_TYPE_REPORT_LABEL,
  REPORTS_MODAL_TYPE_REPORT_BRANDS,
  REPORTS_MODAL_TYPE_REPORT_CREATORS,
  REPORTS_MODAL_TYPE_REPORT_ORDERS,
  REPORTS_MODAL_TYPE_REPORT_INVITATIONS,
  CREATORS_OWNER_TITLE,
  CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER,
  CREATORS_OWNER_CREATOR_NOT_FOUND,
  CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETING_CREATOR,
  CREATORS_OWNER_DELETED_CREATOR,
  BUTTON_ARCHIVE,
  BUTTON_UNARCHIVE,
  CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE,
  CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_EXTENDING_INVITATION,
  CREATORS_OWNER_INVITATION_EXTENDED,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING,
  CREATORS_OWNER_REPORT_EMPTY_INVITATIONS,
  CREATORS_OWNER_REPORT_DATE_REDEMPTION,
  CREATORS_OWNER_REPORT_WAS_ACCEPTED,
  CREATORS_OWNER_REPORT_DELIVERIES,
  CREATORS_OWNER_REPORT_DELIVERIES_EMPTY,
  CREATORS_OWNER_REPORT_IS_DELIVERY,
  CREATORS_OWNER_REPORT_ORDERS_EMPTY,
  CREATORS_OWNER_REPORT_ORDERS_ARCHIVED,
  CREATORS_OWNER_REPORT_ORDERS_ARCHIVED_EMPTY,
  CREATORS_OWNER_REPORT_WAS_ARCHIVED,
  CREATORS_OWNER_REPORT_TRANSACTIONS,
  CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY,
  TRANSACTION_CONCEPT_LABEL,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_DEBIT,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_PENDING_RELEASE,
  TRANSACTION_STATUS_IN_PROCESS_RELEASE,
  TRANSACTION_STATUS_RELEASED,
  TRANSACTION_STATUS_PENDING_SENT,
  TRANSACTION_IN_TRANSIT,
  TRANSACTION_STATUS_PAID,
  UNKNOWN,
  TRANSACTION_WAS_PAID,
  BUTTON_CREATE_TRANSACTION,
  BUTTON_UPDATE_STATISTICS,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED,
  MODAL_CREATE_TRANSACTION,
  MODAL_CREATE_TRANSACTION_AMOUNT_LABEL,
  MODAL_CREATE_TRANSACTION_CONCEPT_LABEL,
  MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR,
  MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT,
  MODAL_CREATE_TRANSACTION_CONCEPT_ORDER,
  MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL,
  MODAL_CREATE_TRANSACTION_CONCEPT_ID,
  MODAL_EDIT_CREATOR_OWNER_TITLE,
  MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES,
  MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL,
  MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL,
  MODAL_DISABLED_CREATOR_OWNER_TITLE,
  FIELD_END_DATE_GREATER_NOW,
  BUTTON_INVITE_EXCHANGE,
  BUTTON_INVITE_CAMPAIGN,
  BUTTON_SEND_PAYMENT,
  BUTTON_VIEW_REPORT,
  REVIEWS,
  REVIEW,
  SHOW_REVIEWS,
  EMPTY_REVIEWS,
  DESCRIPTION_INVITE_EXCHANGE,
  DESCRIPTION_INVITE_CAMPAIGN,
  DESCRIPTION_SEND_MESSAGE,
  DESCRIPTION_SEND_PAYMENT,
  SELECT_CAMPAIGN_PLACEHOLDER,
  CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE,
  MODAL_PAY_CREATOR_AMOUNT_LABEL,
  FIELD_MIN_AMOUNT_PAY,
  PAYMENT_REASON_INFLUENCER,
  MODAL_INVOICE_INFLUENCER,
  PAY_INFLUENCER,
  REGISTER_PASSWORD_ANSWER,
  FIELD_PASSWORD_INSECURE,
  BLOCK_TRIAL_ACTIVE_TITLE,
  BLOCK_TRIAL_ACTIVE_DESCRIPTION,
  BLOCK_TRIAL_ACTIVE_BUTTON,
  MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION,
  BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE,
  ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION,
  FIELD_BOTH_PASSWORD_REQUIRED,
  MODAL_FREE_TRIAL_CAPTION,
  BUTTON_ADD_AS_REFERENCE,
  BUTTON_ADDED,
  BUTTON_CREATE_CAMPAIGN2,
  BUTTON_CREATOR_CHAT,
  BUTTON_SEND_FILE,
  COLUMN_NAME_ACTION,
  BUTTON_SEE_MORE,
  BUTTON_SEND,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_LABEL,
  RIGHT,
  LEFT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_HAS_TEXT_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_FONT_FAMILY_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_TEXT_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_LOGO_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_HAS_TEXTS_LABEL,
  LOGO,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_PLACEHOLDER,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WEBSITE_URL_LABEL,
  FIELD_FILE_VALID_REQUIRED,
  FIELD_FILE_MAX_SIZE,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_TITLE,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TEXT_LABEL,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION,
  BUTTON_YOU_HAVE_DOUBTS,
  CANCEL_ANYTIME,
  NO_IMAGES_FOUND_ON_PAGE,
  FIELD_URL_HTTPS_VALID,
  ADD_BRAND,
  MODAL_ADD_BRAND_SUPER_ADMIN_TITLE,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_EMPTY,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION,
  DATE_LONG_TEXT,
  DATE,
  BUTTON_SAVE_CONTENT,
  BUTTON_REGENERATE_TEXT,
  DATE_LONG_TEXT_WITH_HOUR,
  DATE_LONG_TEXT_2,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALREADY_CONTENT_ON_THIS_DATE,
  HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP,
  NO_CREATE_DISCOUNT_SHOPIFY,
  NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY,
  NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY,
  NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY,
  FIELD_ACCESS_TOKEN_SHOPIFY_VALID,
  STORES_STORE_SHOPIFY_DUPLICATE,
  BUTTON_SEND_BROADCAST,
  MODAL_BROADCAST_CONFIRM_TITLE,
  MODAL_BROADCAST_CONFIRM_DESCRIPTION,
  NAME_BUSINESS_BOT,
  SERVICE_FEE,
  TOTAL_PAYMENT,
  MODAL_USER_TITLE_EDIT,
  MODAL_USER_DESCRIPTION_EDIT,
  MODAL_USER_DESCRIPTION,
  MODAL_CONTACT_TITLE_EDIT,
  MODAL_CONTACT_DESCRIPTION,
  MODAL_CONTACT_DESCRIPTION_EDIT,
  MODAL_STORE_DESCRIPTION_EDIT,
  MODAL_STORE_DESCRIPTION,
  MODAL_STORE_TITLE_EDIT,
  MODAL_STORE_TITLE,
  MODAL_AMBASSADOR_DESCRIPTION,
  MODAL_BROADCAST_DESCRIPTION,
  MODAL_PAY_AMBASSADORS_DESCRIPTION,
  MODAL_COPY_MENU_DESCRIPTION,
  DESIGNER,
  SHOPS_MODAL_CREATE_SHOP_DESCRIPTION,
  MODAL_CREATE_PAYMENT_LINK_DESCRIPTION,
  OWNER_USERS_MODAL_TITLE_EDIT,
  OWNER_USERS_MODAL_DESCRIPTION,
  OWNER_USERS_MODAL_DESCRIPTION_EDIT,
  BUTTON_ADD_REFERENCE_PROFILE,
  AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND,
  OPTION_UGC_FORMAT_GRWM,
  OPTION_UGC_FORMAT_GRWM_EXPLANATION,
  OPTION_UGC_FORMAT_OODT,
  OPTION_UGC_FORMAT_OODT_EXPLANATION,
  OPTION_UGC_FORMAT_HAULS,
  OPTION_UGC_FORMAT_HAULS_EXPLANATION,
  OPTION_UGC_FORMAT_DIYS,
  OPTION_UGC_FORMAT_DIYS_EXPLANATION,
  OPTION_UGC_FORMAT_UNBOXING,
  OPTION_UGC_FORMAT_UNBOXING_EXPLANATION,
  OPTION_UGC_FORMAT_CHALLENGES,
  OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION,
  OPTION_UGC_FORMAT_REVIEWS,
  OPTION_UGC_FORMAT_REVIEWS_EXPLANATION,
  OPTION_UGC_FORMAT_VLOGS,
  OPTION_UGC_FORMAT_VLOGS_EXPLANATION,
  OPTION_UGC_FORMAT_QA,
  OPTION_UGC_FORMAT_QA_EXPLANATION,
  OPTION_UGC_FORMAT_STORYTIME,
  OPTION_UGC_FORMAT_STORYTIME_EXPLANATION,
  OPTION_UGC_FORMAT_ROOM_TOURS,
  OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION,
  OPTION_UGC_FORMAT_WHATS_IN_MY_BAG,
  OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION,
  OPTION_UGC_FORMAT_COOKING,
  OPTION_UGC_FORMAT_COOKING_EXPLANATION,
  OPTION_UGC_FORMAT_FITNESS_ROUTINES,
  OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION,
  OPTION_UGC_FORMAT_ASMR,
  OPTION_UGC_FORMAT_ASMR_EXPLANATION,
  OPTION_UGC_FORMAT_PLAN_WITH_ME,
  OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION,
  OPTION_UGC_FORMAT_MUKBANG,
  OPTION_UGC_FORMAT_MUKBANG_EXPLANATION,
  OPTION_UGC_FORMAT_DAY_IN_THE_LIFE,
  OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION,
  OPTION_UGC_FORMAT_BEHIND_THE_SCENES,
  OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION,
  OPTION_UGC_FORMAT_TRAVEL_DIARIES,
  OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION,
  SELECT_UGC_CONTENT_FORMAT,
  CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION,
  CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER,
  SUB_MENU_CONTENT_APPLICATION,
  CONTENT_APPLICATION_TITLE,
  COLUMN_NAME_URLS,
  CONTENT_APPLICATION_ACCEPT_TITLE,
  CONTENT_APPLICATION_ACCEPT_DESCRIPTION,
  CONTENT_APPLICATION_REJECT_TITLE,
  CONTENT_APPLICATION_REJECT_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_BUDGETS_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_BUDGETS_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_DESCRIPTION,
  BUTTON_CONNECT_WOOCOMMERCE,
  FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS,
  FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION,
  FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS,
  FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION,
  BUTTON_ADD_CONTENT,
  BUTTON_SEE_ALL_APPLICANTS,
  REQUEST_REJECT_CONFIRMATION_DESCRIPTION,
  MAIN_FUNCTIONALITY_STEP_4,
  MODAL_INVITATION_STEP_6,
  MODAL_INVITATION_STEP_7,
  MAIN_FUNCTIONALITY_ONBOARDING_FINISH,
  GETTING_INFORMATION_FROM_SHOPIFY_URL,
  LAST_COLLABORATION,
  REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER,
  REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER,
  REGISTER_PROFILE_CREATOR_GENDER_ANSWER,
  REGISTER_PROFILE_CREATOR_AGE_ANSWER,
  REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED,
  REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED,
  REGISTER_PROFILE_CREATOR_GENDER_REQUIRED,
  REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED,
  SELECT_SOME_OPTIONS,
  REGISTER_PROFILE_CREATOR_CITIES_ANSWER,
  REGISTER_PROFILE_CREATOR_CITIES_REQUIRED,
  RATE_DAILY_LIMIT_AI,
  RATE_DAILY_LIMIT_AI_CHAT,
  ALERT_DISCARD_INFLUENCER_DEFINITIVELY_TITTLE,
  ALERT_DISCARD_INFLUENCER_DEFINITIVELY_DESCRIPTION,
  ALERT_DISCARD_INFLUENCER_DEFINITIVELY_BUTTON,
  BUTTON_GO_BACK,
  TOP_RATED_CATEGORY,
  WITH_RECURRENT_INVITATION_TITLE,
  BUTTON_CLEAR_FILTERS,
  BUTTON_DEACTIVATE_AUTOMATICS_INVITATIONS,
  BUTTON_ACTIVATE_AUTOMATICS_INVITATIONS,
  PERSONALIZED_INSTRUCTIONS,
  BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR,
  EMPTY_REQUESTS,
  EMPTY_CAMPAIGNS,
  EMPTY_AMBASSADORS,
  EMPTY_INVITATIONS,
  EMPTY_INVITATIONS_INACTIVE,
  EMPTY_ORDERS,
  EMPTY_REDEEM_CODES,
  EMPTY_USERS,
  EMPTY_CONTACTS,
  EMPTY_INVOICES,
  EMPTY_INVITATIONS_APPLICANTS,
  EMPTY_APPLICANTS_PENDING,
  EMPTY_APPLICANTS_ACCEPTED,
  PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META,
  ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS,
  AUTH_TIKTOK_VERIFICATION_LOADING,
  AUTH_TIKTOK_VERIFICATION_SUCCESS,
  AUTH_TIKTOK_VERIFICATION_FAILED,
  DOCUMENT_ID_EMIRATES,
  DOCUMENT_TRN,
  ADS_CAMPAIGNS_DESCRIPTION,
  ADS_CAMPAIGNS_TITLE,
  EMPTY_ADS_CAMPAIGNS,
  CREATE_ADS_CAMPAIGN_NAME_QUESTION,
  CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION,
  CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION,
  CREATE_ADS_CAMPAIGN_GENDERS_QUESTION,
  CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT,
  ADS_CAMPAIGN_OBJECTIVE_LEADS,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES,
  CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION,
  FIELD_NAME_REQUIRED,
  FIELD_OBJECTIVE_REQUIRED,
  FIELD_TYPE_BUDGET_REQUIRED,
  FIELD_BUDGET_REQUIRED,
  FIELD_CURRENCY_MIN_VALUE,
  FIELD_LOCATIONS_REQUIRED,
  FIELD_GENDERS_REQUIRED,
  FIELD_INTERESTS_REQUIRED,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION,
  FIELD_PLATFORMS_REQUIRED,
  CREATE_ADS_CAMPAIGN_PLATFORM_QUESTION,
  CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION,
  CTR,
  CLICKS,
  IMPRESSIONS,
  STATISTICS,
  PLATFORMS,
  AGES,
  GENDERS,
  LOCATIONS,
  TARGET_CAMPAIGN,
  ADS_CAMPAIGN_TITLE,
  ADS_CAMPAIGN_HAS_BEEN_FINISHED,
  OBJECTIVE_PLACEHOLDER,
  PLATFORMS_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  AGE_RANGE_PLACEHOLDER,
  GENDERS_PLACEHOLDER,
  INTERESTS_PLACEHOLDER,
  DESCRIPTION_PLACEHOLDER,
  ADS_CAMPAIGN_HAS_BEEN_ACTIVATE,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  FIELD_DESCRIPTION_REQUIRED,
  TYPE_LEAST_CHARACTERS,
  LOADING,
  META,
  META_FACEBOOK_AND_INSTAGRAM,
  PLATFORM,
  FIELD_PLATFORM_REQUIRED,
  ALERT_CREATE_SECOND_ADS_CAMPAIGN,
  ALERT_CREATE_SECOND_ADS_CAMPAIGN_DESCRIPTION,
  BUTTON_TIKTOK_BILLING,
  BUTTON_META_BILLING,
  PLATFORM_PLACEHOLDER,
  SUB_MENU_ADS_CAMPAIGNS,
  OBJECTIVE,
  INTERACTIONS,
  LEADS,
  DOWNLOADS,
  SALES,
  FIELD_VIDEO_REQUIRED,
  CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
  BUTTON_REPLACE_CONTENT,
  TIKTOK_CAMPAIGN,
  META_CAMPAIGN,
  ADS_CAMPAIGN_HAS_BEEN_DUPLICATE,
  BUTTON_REPLACE_VIDEO,
  BUTTON_SELECT_VIDEO,
  CONTENT_TO_ADVERTISE,
  FIELD_BEHAVIORS_REQUIRED,
  CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION,
  CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION,
  CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER,
  BEHAVIORS_PLACEHOLDER,
  DEMOGRAPHICS_PLACEHOLDER,
  BUTTON_SELECT_THIS_CONTENT,
  BUTTON_REMOVE_SELECTION,
  IG_BUSINESS_ACCOUNT_NOT_FOUND,
  FACEBOOK_PAGE_ID_NOT_FOUND,
  META_BUSINESS_ACCOUNT_NOT_FOUND,
  CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION,
  EMPTY_TRACKING_IDS_META,
  FIELD_META_TRACKING_ID_REQUIRED,
  TRACKING_META_ID_PLACEHOLDER,
  FINISHED,
  ACCOUNT_TIKTOK_AUTH_NOT_MATCH,
  ACCOUNT_TOKEN_META_NOT_FOUND,
  ACCOUNT_ID_BUSINESS_META_NOT_FOUND,
  OBJECTIVE_CAMPAIGN_META_INVALID,
  SPECIAL_CATEGORY_CAMPAIGN_META_INVALID,
  CAMPAIGN_META_NOT_DELETED,
  CAMPAIGN_META_NOT_CREATED,
  ADS_CAMPAIGN_NOT_FOUND,
  NO_PIXELS_FOUND,
  NO_PIXELS_ACTIVE,
  CAMPAIGN_META_NOT_FOUND,
  CAMPAIGN_META_NOT_UPDATED,
  CAMPAIGN_AD_SET_META_NOT_CREATED,
  CAMPAIGN_AD_SET_META_NOT_UPDATED,
  CAMPAIGN_MEDIA_META_NOT_CREATED,
  CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND,
  CAMPAIGN_CREATIVE_AD_META_NOT_CREATED,
  CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED,
  CAMPAIGN_CREATIVE_AD_META_NOT_FOUND,
  CAMPAIGN_AD_META_NOT_CREATED,
  CAMPAIGN_AD_META_NOT_UPDATED,
  CAMPAIGN_METRICS_META_NOT_FOUND,
  BUTTON_CONNECT_TIKTOK_BUSINESS,
  TIKTOK_BUSINESS,
  EMPTY_TRACKING_IDS_TIKTOK,
  SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  FIELD_DESTINATION_URL_REQUIRED,
  FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL,
  FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED,
  CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  BUTTON_RECHARGE_BALANCE,
  ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM,
  INFORMATION,
  DESTINATION_URL_PLACEHOLDER,
  CAMPAIGN_BUDGET_IS_TOO_LOW,
  CAMPAIGN_AUDIENCE_IS_TOO_NARROW,
  PRICE_PRO_PLACEHOLDER,
  COST_COLLABORATION_PRO_PLACEHOLDER,
  PRO_PACKAGE_SUB_HEADER,
  PRO_PACKAGE_DESCRIPTION,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS,
  MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM,
  MODAL_SELECT_PACKAGE_LICENSED_PRICE,
  MODAL_SELECT_PACKAGE_LICENSED_FEE,
  MODAL_SELECT_PACKAGE_COST_CONTENT,
  WOOCOMMERCE,
  SHOPIFY,
  BUTTON_CONNECT_SHOPIFY,
  MODAL_STORE_TITLE_CREATE_INTEGRATE_SHOPIFY,
  MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_SHOPIFY,
  MODAL_STORE_BUTTON_CREATE_INTEGRATE_SHOPIFY,
  MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_DESCRIPTION_DISCONNECT_SHOPIFY,
  MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE,
  ALERT_DESCRIPTION_DISCONNECT_FACEBOOK,
  ALERT_DESCRIPTION_DISCONNECT_TIKTOK,
  MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT,
  MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE,
  MODAL_SELECT_PACKAGE_CONTENT_ENTERPRISE,
  ALERT_DESCRIPTION_DISCONNECT_TIKTOK_BUSINESS,
  VIDEO_ERROR_ILLEGAL_DURATION,
  VIDEO_ERROR_BLACK_EDGE,
  VIDEO_ERROR_ASPECT_RATIO,
  VIDEO_ERROR_NO_BACKGROUND_AUDIO,
  VIDEO_ERROR_LOW_RESOLUTION,
  IDENTITY_NOT_FOUND,
  TRACKING_TIKTOK_ID_PLACEHOLDER,
  BUTTON_CONNECT_META,
  FIELD_TIKTOK_TRACKING_ID_REQUIRED,
  ENGAGEMENT_ABBREVIATED,
  FOLLOWERS_ABBREVIATED,
  NO_MORE_RESULTS_TO_SHOW,
  INVITE_FORM_VALUE_QUESTION,
  INVITE_FORM_VALUE_CLARIFICATION,
  INVITE_FORM_VALUE_PLACEHOLDER_2,
  INVITE_FORM_VALUE_TO_PAY_PLACEHOLDER,
  INVITE_FORM_VALUE_TO_PAY_CLARIFICATION,
  INVITE_FORM_VALUE_TO_PAY_QUESTION,
  INVITE_FORM_VALUE_TO_PAY_CLARIFICATION_2,
  INVITE_VALUE_TO_PAY_MIN_VALUE,
  INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER,
  INVITE_FORM_REFERENCE_URL_QUESTION,
  INVITE_FORM_LANGUAGE_QUESTION,
  INVITE_FORM_UGC_FORMAT_QUESTION,
  PAYMENT_REASON_INVITATION,
  MODAL_INVOICE_INVITATION,
  PUBLICATION_STATUS_PENDING_SOCIAL,
  ADD_NEW_STYLE,
  WANTS_SOCIAL_MEDIA_POST,
  DOES_NOT_WANTS_SOCIAL_MEDIA_POST,
  BUTTON_CONTACT_AN_ADVISOR,
  BUTTON_GENERATE_SCRIPT,
  EXPLANATION_SEND_ONE_REVISION,
  I_NEED_HELP,
  REVISION_OPTION_GOOD,
  REVISION_OPTION_REGULAR,
  REVISION_OPTION_BAD,
  BUTTON_VIEW_INVOICE,
  MODAL_INVOICE_LICENSE,
  CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER,
  CREATOR_CONTENT_STYLE_PLACEHOLDER,
  CREATOR_CLOTHING_STYLE_PLACEHOLDER,
  CREATOR_BODY_BUILD_PLACEHOLDER,
  BODY_BUILD_THIN,
  BODY_BUILD_AVERAGE,
  BODY_BUILD_WIDE,
  BODY_BUILD_MUSCULAR,
  BODY_BUILD_CURVY,
  BODY_BUILD_ATHLETIC,
  CLOTHING_STYLE_CASUAL,
  CLOTHING_STYLE_FORMAL,
  CLOTHING_STYLE_SPORTY,
  CLOTHING_STYLE_BUSINESS,
  CLOTHING_STYLE_ELEGANT,
  CLOTHING_STYLE_TRENDY,
  CLOTHING_STYLE_STREETWEAR,
  CLOTHING_STYLE_TRADITIONAL,
  CLOTHING_STYLE_VINTAGE,
  CLOTHING_STYLE_BOHEMIAN,
  CLOTHING_STYLE_HIPSTER,
  CLOTHING_STYLE_PUNK,
  CLOTHING_STYLE_GOTHIC,
  DISTINGUISHING_FEATURES_TATTOOS,
  DISTINGUISHING_FEATURES_SCARS,
  DISTINGUISHING_FEATURES_BIRTHMARKS,
  DISTINGUISHING_FEATURES_FRECKLES,
  DISTINGUISHING_FEATURES_MOLES,
  DISTINGUISHING_FEATURES_PIERCINGS,
  DISTINGUISHING_FEATURES_DIMPLES,
  DISTINGUISHING_FEATURES_WRINKLES,
  DISTINGUISHING_FEATURES_GLASSES,
  DISTINGUISHING_FEATURES_BRACES,
  DISTINGUISHING_FEATURES_MAKEUP,
  DISTINGUISHING_FEATURES_HAIR_ACCESSORIES,
  DISTINGUISHING_FEATURES_BEARD,
  DISTINGUISHING_FEATURES_MUSTACHE,
  CONTENT_STYLE_ELEGANT,
  CONTENT_STYLE_AESTHETIC,
  CONTENT_STYLE_RAW,
  CONTENT_STYLE_MINIMALISTIC,
  CONTENT_STYLE_ARTISTIC,
  CONTENT_STYLE_VINTAGE,
  CONTENT_STYLE_MODERN,
  CONTENT_STYLE_PLAYFUL,
  CONTENT_STYLE_RUSTIC,
  CONTENT_STYLE_MONOCHROMATIC,
  REGISTER_PROFILE_CREATOR_BODY_BUILD,
  REGISTER_PROFILE_CREATOR_CLOTHING_STYLE,
  REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES,
  REGISTER_PROFILE_CREATOR_CONTENT_STYLE,
  REGISTER_PROFILE_CREATOR_BODY_BUILD_REQUIRED,
  REGISTER_PROFILE_CREATOR_CLOTHING_STYLE_REQUIRED,
  REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES_REQUIRED,
  REGISTER_PROFILE_CREATOR_CONTENT_STYLE_REQUIRED,
  BUTTON_ADJUST_PREFERENCES,
  FIELD_MAX_LENGTH_SELECT,
  FIELD_MIN_LENGTH_SELECT,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION,
  CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER,
  BUTTON_GENERATE_AGAIN,
  BUTTON_CREATE_ADS_CAMPAIGN,
  WHY_THESE_RECOMMENDED_SETTINGS,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION,
  BUTTON_EDIT_BUDGET,
  CALL_TO_ACTION_PLACEHOLDER,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU,
  ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP,
  ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE,
  ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US,
  ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES,
  ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE,
  ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME,
  ADS_CAMPAIGN_CALL_TO_ACTION_SAVE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES,
  ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS,
  ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME,
  ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP,
  ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE,
  BUTTON_PUBLISH_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_TITLE,
  CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON,
  PIXEL_REQUIRED,
  ADS_CAMPAIGN_CONTEXT,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_SEGMENTATION,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_COMMUNICATE,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_PRODUCT_ADVERTISE,
  BUTTON_START_CHAT,
  AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_TYPE_CONTENT_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_TERMS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_TERMS_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION,
  URL_PLACEHOLDER,
  AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED,
  AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED,
  AUTOMATIC_INVITES_COMMENT_REQUIRED,
  AUTOMATIC_INVITES_TERMS_REQUIRED,
  AUTOMATIC_INVITES_FORMATS_REQUIRED,
  AUTOMATIC_INVITES_CONTENT_REFERENCE_REQUIRED,
  AUTOMATIC_INVITES_TYPE_CONTENT_REQUIRED,
  AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED,
  BUTTON_NOT_NOW,
  BUTTON_ACTIVATE_NOW,
  MODAL_FUNCTIONALITY_AUTO_INVITES,
  IS_TRAINED_PHYSICAL_BRAND,
  BUTTON_PROMOTE,
  BUTTON_SELECT_CONTENT,
  FIELD_CONTENT_REQUIRED,
  TYPE_BUDGET_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  DRAFT,
  SUB_MENU_TOP_CONTENT,
  TOP_CONTENT_DESCRIPTION,
  TOP_CONTENT_TITLE,
  TOP_CONTENT_NOT_FOUND_IN_INTERVAL,
  BUTTON_DISCARD,
  ADS,
  UGC,
  COLUMN_NAME_IS_PLAN_ACTIVE,
  AUTH_INVALID_VERIFICATION_CODE,
  REGISTER_OTP_ANSWER,
  WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE,
  FIELD_VERIFICATION_CODE_REQUIRED,
  INTEGRATION_CURRENCY_NOT_FOUND,
  MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE,
  MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION,
  MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE,
  MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION,
  BUTTON_ACTIVATE_BRAND,
  MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION,
  MODAL_ONBOARDING_STORES_SECTION,
  MODAL_ONBOARDING_SCHEDULE_SECTION,
  BUTTON_SAVE_CATALOG,
  BUTTON_SAVE_PRODUCT,
  MODAL_ONBOARDING_BASIC_INFORMATION_SECTION,
  BUTTON_RESEND_CODE,
  SEARCH_INFLUENCERS_USERNAME,
  SEARCH_INFLUENCERS_TOPICS,
  SEARCH_BY,
  TYPE_A_USERNAME,
  TYPE_A_TOPIC,
  SEARCH_USERNAME_PLACEHOLDER,
  SEARCH_TOPIC_PLACEHOLDER,
  PROCESSING,
  CREATOR_LOCATION_PLACEHOLDER,
  AUDIENCE_LOCATION_PLACEHOLDER,
  RETRIEVING_TOP_POSTS_INFORMATION,
  RETRIEVING_AUDIENCE_STATISTICS,
  RETRIEVING_PROFILE_STATISTICS,
  RETRIEVING_BASIC_PROFILE_INFORMATION,
  COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION,
  ATTENTION,
  USER_NOT_FOUND,
  USER_EMAIL_OR_PASSWORD_WRONG,
  MODAL_DO_AMBASSADOR_DESCRIPTION,
  WITH_RECURRENT_PAYMENT_TITLE,
  RECURRENT_FIELDS_PAYMENT_TITLE,
  VALUE_TO_PAY_AMBASSADOR,
  FIELD_MIN_VALUE_TO_PAY_AMBASSADOR,
  VALUE_TO_PAY_AMBASSADOR_CLARIFICATION,
  WE_ARE_DOING_A_DEEPER_SEARCH,
  ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION,
  CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER,
  CREATORS_OWNER_WAS_REGISTERED_FILTER,
  CREATORS_OWNER_IS_PRE_REGISTERED_FILTER,
  COLUMN_NAME_IS_PRE_REGISTERED,
  COLUMN_NAME_WAS_PRE_REGISTERED,
  APPLICATION_VALUE,
  ERROR_NETWORK,
  MONTHLY,
  QUARTERLY,
  SEMIYEARLY,
  YEARLY,
  PLAN_PLACEHOLDER,
  ADS_CREDITS_PLACEHOLDER,
  ADS_COINS_PLACEHOLDER,
  ADVERTISER_PLACEHOLDER,
  ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION,
  DESCRIPTION_PAYMENT_LINK_CLARIFICATION,
  BUTTON_CREATE_PAYMENT_LINK,
} from "../keysTranslations";

const translation = {
  /*COMMON*/
  [SEARCH]: "Buscar",
  [LOADING_INFORMATION]: "Cargando información",
  [LOADING_SUGGESTED_INFORMATION]:
    "Buscando creadores sugeridos, puede tardar un poco",
  [LOADING_SIMILAR_PROFILES_INFORMATION]: "Cargando perfiles de referencia",
  [LOADING_REPORT_INFORMATION]: "Cargando reporte",
  [LOADING_DOWNLOAD_PROFILES]: "Descargando perfiles",
  [LOADING_CONTENT]: "Cargando contenido",
  [LOADING_CONFIGURATION]: "Cargando configuración",
  [INFORMATION_INVALID_ACCESS]: "No puedes acceder a esta información",
  [EMPTY_CONTENT_SHOW]: "No hay contenido para mostrar",
  [EMPTY_SIMILAR_PROFILES_SHOW]: "No se encontraron perfiles de referencia",
  [NO_MORE_RESULTS_TO_SHOW]: "No hay más resultados para mostrar",
  [EMPTY_RESULTS_SHOW]: "No se encontraron resultados",
  [EMPTY_RESULTS_SHOW_ON_THIS_PAGE]:
    "No se encontraron resultados en esta página",
  [EMPTY_QUESTIONS_SHOW]: "No se encontraron preguntas",
  [EMPTY_ANALYTICS]:
    "No se pudieron extraer las analíticas de esta publicación, contacta con soporte o a tu asesor.",
  [WE_ARE_DOING_A_DEEPER_SEARCH]:
    "Estamos haciendo una búsqueda más profunda de creadores que cumplan estos criterios, por favor intenta de nuevo en 5 minutos",
  [ADVANCED_CONFIGURATION]: "Configuración avanzada",
  [CREDITS]: "Créditos",
  [WAIT]: "Espera",
  [COLLABORATIONS]: "Colaboraciones",
  [COLLABORATION]: "Colaboración",
  [DEACTIVATE]: "Desactivar",
  [ACTIVATE]: "Activar",
  [DEACTIVATED]: "Desactivada",
  [ACTIVE]: "Activada",
  [BRAND]: "Marca",
  [WARNING]: "Advertencia",
  [INFORMATION]: "Información",
  [CATEGORY]: "Categoría",
  [CATEGORIES]: "Categorías",
  [PRODUCT]: "Producto",
  [PRODUCTS]: "Productos",
  [ERROR]: "Error",
  [ACTIONS]: "Acciones",
  [STATUS]: "Status",
  [CITIES]: "Ciudades",
  [UNKNOWN_FORMAT]: "Formato desconocido",
  [ERROR_DESCRIPTION_GENERIC]: "{{message}}",
  [READY]: "Hecho",
  [HELP]: "Ayuda",
  [GO]: "Ir",
  [GO_TO_PROFILE]: "Ir al perfil",
  [GO_TO_AUTOMATIC_INVITATIONS]: "Ir a invitaciones automáticas",
  [GO_TO_ACCOUNT]: "Ir a cuenta",
  [PROFILE]: "Perfil",
  [LOGOUT]: "Cerrar sesión",
  [CLIENT]: "Cliente",
  [NAME]: "Nombre",
  [ADDRESS]: "Dirección",
  [IDENTIFICATION_NUMBER]: "Número de identificación",
  [PHONE_NUMBER]: "Celular",
  [EMAIL]: "Correo electrónico",
  [NOTIFICATIONS]: "Notificaciones",
  [CONTENT]: "Contenido",
  [ANALYTIC]: "Analitíca",
  [ANALYTICS]: "Analitícas",
  [COMMENTS]: "Comentarios",
  [COMMENT]: "Comentario",
  [SHOW_COMMENTS]: "Mostrar comentarios",
  [EMPTY_COMMENTS]: "No hay comentarios",
  [REVIEWS]: "Reseñas",
  [REVIEW]: "Reseña",
  [SHOW_REVIEWS]: "Mostrar reseña",
  [EMPTY_REVIEWS]: "No hay reseñas",
  [SCORE]: "Puntaje",
  [QUALITY_AUDIENCE]: "Calidad de audiencia",
  [QUALITY_CONTENT]: "Calidad de contenido",
  [AUDIENCE]: "Audiencia",
  [AUDIENCE_TITLE_EXPLANATION]: "¿Qué es la audiencia?",
  [AUDIENCE_EXPLANATION]:
    "La audiencia es el número de personas que siguen o estas suscritas al perfil del creador de contenido. Audiencias grandes tienen un mejor alcance o reach en cada publicación pero generalmente la tasa de interacción o engagement es menor a medida que aumenta la audiencia.",
  [ENGAGEMENT]: "Engagement",
  [ENGAGEMENT_TITLE_EXPLANATION]: "¿Qué es el engagement?",
  [ENGAGEMENT_EXPLANATION]:
    "El engagement o tasa de interacción es la cantidad de personas que interactúe con el contenido publicado, ya sea un like, un comentario, un share o save y otras acciones dependiendo de la plataforma. Si quieres estimar cuantas personas van a interactuar con el contenido, multiplicas la audiencia por el engagement y lo divides en 100.",
  [FOLLOWERS]: "Seguidores",
  [AVG_LIKES]: "Promedio likes",
  [AVG_COMMENTS]: "Promedio comentarios",
  [AVG_VIEWS]: "Promedio visualizaciones",
  [RECORDS_IN_TABLE]: "Registros",
  [PAGE_IN_TABLE]: "Página",
  [SHOP_DEACTIVATE_ALERT]: "Tu marca está desactivada",
  [SHOP_DEACTIVATE_ALERT_DESCRIPTION]:
    "Para poder realizar esta acción debes activar tu tienda",
  [UPDATE]: "Actualizar",
  [MALES]: "Hombres",
  [MALE]: "Hombre",
  [FEMALES]: "Mujeres",
  [FEMALE]: "Mujer",
  [STORE]: "Tienda",
  [SELECT_STORE]: "Selecciona una tienda",
  [NO_STORES_CREATED]: "No hay tiendas creadas",
  [EMPTY_STORES]: "No hay tiendas creadas",
  [ORDER]: "Orden",
  [NEW_ORDER]: "Nueva orden",
  [NEW]: "Nuevo",
  [SECOND]: "Segundo",
  [SECONDS]: "Segundos",
  [MINUTE]: "Minuto",
  [MINUTES]: "Minutos",
  [MINUTE_LETTER_COUNT_DOWN]: "min",
  [HOUR]: "Hora",
  [HOURS]: "Horas",
  [HOUR_LETTER_COUNT_DOWN]: "h",
  [DAY]: "Día",
  [DAYS]: "Días",
  [DAY_LETTER_COUNT_DOWN]: "d",
  [WEEK]: "Semana",
  [WEEKS]: "Semanas",
  [WEEK_LETTER_COUNT_DOWN]: "sem",
  [MONTH]: "Mes",
  [MONTHS]: "Meses",
  [QUESTION]: "Pregunta",
  [CONTENT_CREATOR]: "Creador de contenido",
  [ADD]: "Agregar",
  [MONDAY]: "Lunes",
  [TUESDAY]: "Martes",
  [WEDNESDAY]: "Miercoles",
  [THURSDAY]: "Jueves",
  [FRIDAY]: "Viernes",
  [SATURDAY]: "Sábado",
  [SUNDAY]: "Domingo",
  [SELECT_A_OPTION]: "Selecciona una opción",
  [FILE_SELECTED]: "Archivo seleccionado: {{fileName}}",
  [YES]: "Sí",
  [NO]: "No",
  [IMAGES]: "Imágenes",
  [VIDEOS]: "Videos",
  [OK]: "Ok",
  [FILTER]: "Filtrar",
  [SUPER_ADMIN]: "Super administrador",
  [ADMIN]: "Administrador",
  [OPERATOR]: "Operador",
  [DESIGNER]: "Diseñador",
  [TOTAL]: "Total",
  [NO_HAVE]: "No hay",
  [NO_ADDED]: "No agregados",
  [SUPPORT]: "Soporte",
  [CONTACT_US]: "Contáctanos",
  [ONE]: "Uno",
  [TWO]: "Dos",
  [THREE]: "Tres",
  [INVITATIONS]: "Invitaciones",
  [ANY]: "Cualquiera",
  [GENDER]: "Género",
  [INFLUENCER_NO_REDEEM_CODE]:
    "El creador asociado a este código no puede redimirlo por una limitación que tiene su cuenta, por favor contacta con soporte o a tu staff.",
  [SEND_REVISION_INFLUENCER_COMMENT]:
    "Envíe comentarios al creador de contenido sobre por qué no se aprobó el contenido; considere proporcionar detalles específicos para mejorar la calidad del contenido.",
  [REVISIONS]: "Revisiones",
  [CURRENT_CONTENT]: "Contenido actual",
  [CURRENT_CONTENT_EMPTY]:
    "No hay contenido actual, espera a que el creador lo suba",
  [NO_SEND_CONTENT_FEEDBACK]: "El creador no envió contenido del feedback",
  [USERS]: "Usuarios",
  [STORES]: "Tiendas",
  [PLAN]: "Plan",
  [AUTOMATIC_RENOVATION]: "Renovación automática",
  [ORDERS]: "Órdenes",
  [REPORT_ORDERS]: "Reporte de ordenes",
  [DELIVERIES]: "Deliveries",
  [IN_STORE]: "En tienda",
  [ORDERS_PENDING]: "Pendientes",
  [ORDERS_PENDING_ANALYTICS]: "Sin analíticas",
  [ORDERS_FEEDBACK]: "Feedback",
  [ORDERS_SENT]: "Enviadas",
  [ORDERS_REVIEWED]: "Verificadas",
  [CREATOR]: "Creador",
  [VALUE]: "Valor",
  [WAS_GENERATED]: "Se generó",
  [EDIT]: "Editar",
  [THIS_CREATOR_IS_FAVORITE]: "Es favorito",
  [ADD_TO_FAVORITES]: "Agregar a favoritos",
  [REMOVE_FROM_FAVORITES]: "Quitar de favoritos",
  [SPANISH]: "Español",
  [ENGLISH]: "Inglés",
  [VIEW]: "Ver",
  [ACCEPTED]: "Aceptadas",
  [PENDING]: "Pendientes",
  [ARE_YOU_SURE]: "¿Estás seguro?",
  [CHANGE]: "Cambiar",
  [REPORT_REQUESTS]: "Reporte solicitudes",
  [REQUESTS_ACCEPTED]: "Aceptadas",
  [REQUESTS_REJECTED]: "Rechazadas",
  [REQUESTS_ACTIVE]: "Solicitudes activas",
  [REPORT_INVITATIONS]: "Reporte Invitaciones",
  [CODE]: "Código",
  [IS_AUTOMATIC]: "Es automática",
  [REPORT_INVITATIONS_INACTIVE]: "Reporte Invitaciones Inactivas",
  [REDEEMED]: "Redimidas",
  [EXPIRED]: "Expiradas",
  [REDEEMED_SINGULAR]: "Redimida",
  [EXPIRED_SINGULAR]: "Expirada",
  [REJECTED]: "Rechazadas",
  [ROLE]: "Rol",
  [UNKNOWN]: "Desconocido",
  [RIGHT]: "Derecha",
  [LEFT]: "Izquierda",
  [LOGO]: "Logo",
  [CANCEL_ANYTIME]: "Cancela en cualquier momento",
  [ADD_BRAND]: "Agregar marca",
  [DATE]: "Fecha",
  [HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP]:
    "No puedes desactivar tu marca si tienes invitaciones activas",
  [NAME_BUSINESS_BOT]: "Ana de {{NAME_BUSINESS}}",
  [SERVICE_FEE]: "Tarifa de servicio",
  [TOTAL_PAYMENT]: "Pago total",
  [LAST_COLLABORATION]: "Última colaboración",
  [PERSONALIZED_INSTRUCTIONS]: "Instrucciones personalizadas",
  [EMPTY_REQUESTS]: "Aún no tienes solicitudes",
  [EMPTY_CAMPAIGNS]: "Aún no tienes campañas",
  [EMPTY_AMBASSADORS]: "Aún no tienes embajadores",
  [EMPTY_INVITATIONS]: "Aún no tienes invitaciones",
  [EMPTY_INVITATIONS_INACTIVE]: "Aún no tienes invitaciones inactivas",
  [EMPTY_ORDERS]: "Aún no tienes colaboraciones",
  [EMPTY_REDEEM_CODES]: "Aún no tienes códigos redimidos",
  [EMPTY_USERS]: "Aún no tienes usuarios creados",
  [EMPTY_CONTACTS]: "Aún no tienes contactos creados",
  [EMPTY_INVOICES]: "Aún no tienes facturas creadas",
  [EMPTY_INVITATIONS_APPLICANTS]:
    "Aún no has invitado a creadores a tu campaña",
  [EMPTY_APPLICANTS_PENDING]: "Aún no tienes aplicantes pendientes",
  [EMPTY_APPLICANTS_ACCEPTED]: "Aún no has aceptado aplicantes",
  [EMPTY_ADS_CAMPAIGNS]: "Aún no tienes campañas publicitarias",
  [TARGET_CAMPAIGN]: "Segmentación",
  [LOCATIONS]: "Ubicaciones",
  [GENDERS]: "Géneros",
  [AGES]: "Edades",
  [PLATFORMS]: "Plataformas",
  [PLATFORM]: "Plataforma",
  [STATISTICS]: "Estadísticas",
  [IMPRESSIONS]: "Impresiones",
  [CLICKS]: "Clicks",
  [CTR]: "CTR",
  [TYPE_LEAST_CHARACTERS]: "Debes escribir al menos {{value}} caracteres",
  [LOADING]: "Cargando",
  [OBJECTIVE]: "Objetivo",
  [INTERACTIONS]: "Interacciones",
  [LEADS]: "Leads",
  [DOWNLOADS]: "Descargas",
  [SALES]: "Ventas",
  [META_CAMPAIGN]: "Campaña de Meta",
  [TIKTOK_CAMPAIGN]: "Campaña de TikTok",
  [CONTENT_TO_ADVERTISE]: "Contenido a promocionar",
  [EMPTY_TRACKING_IDS_META]: "No tienes meta pixels creados",
  [FINISHED]: "Finalizada",
  [TIKTOK_BUSINESS]: "TikTok Business",
  [EMPTY_TRACKING_IDS_TIKTOK]: "No tienes pixels de TikTok creados",
  [SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS]:
    "Ha pasado poco tiempo desde que activaste la campaña, las estadísticas pueden tardar en actualizarse",
  [SHOPIFY]: "Shopify",
  [WOOCOMMERCE]: "Woocommerce",
  [FOLLOWERS_ABBREVIATED]: "FLL",
  [ENGAGEMENT_ABBREVIATED]: "ER",
  [ADD_NEW_STYLE]: "Agrega nuevo estilo",
  [WANTS_SOCIAL_MEDIA_POST]: "Quiero exposición en redes sociales",
  [DOES_NOT_WANTS_SOCIAL_MEDIA_POST]: "No quiero exposición en redes sociales",
  [EXPLANATION_SEND_ONE_REVISION]: "Solo podrás enviar una revisión",
  [I_NEED_HELP]: "Necesito ayuda",
  [REVISION_OPTION_GOOD]: "¡Increíble! Pero tengo una duda",
  [REVISION_OPTION_REGULAR]: "Está bien, pero falta algo",
  [REVISION_OPTION_BAD]: "Esto no me sirve",
  [WHY_THESE_RECOMMENDED_SETTINGS]: "¿Por qué estos ajustes recomendados?",
  [PIXEL_REQUIRED]: "Pixel requerido",
  [IS_TRAINED_PHYSICAL_BRAND]: "Está capacitada para tienda física",
  [ADS]: "ADS",
  [UGC]: "UGC",
  [WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE]:
    "Le enviaremos un código de verificación a su teléfono, puede tardar hasta un minuto en llegar.",
  [SEARCH_BY]: "Buscar por",
  [TYPE_A_USERNAME]: "Escribe un usuario",
  [TYPE_A_TOPIC]: "Escribe un tema",
  [DRAFT]: "Borrador",
  [PROCESSING]: "Procesando",
  [RETRIEVING_BASIC_PROFILE_INFORMATION]:
    "Recuperando información básica del perfil",
  [RETRIEVING_PROFILE_STATISTICS]: "Recuperando estadísticas del perfil",
  [RETRIEVING_AUDIENCE_STATISTICS]: "Recuperando estadísticas de la audiencia",
  [RETRIEVING_TOP_POSTS_INFORMATION]:
    "Recuperando información de los mejores posts",
  [ATTENTION]: "Atención",
  [COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION]:
    "Las estadísticas de este creador no están disponibles de inmediato, estamos recolectando la información. Esta acción puede tardar unos minutos, la página se actualizará automáticamente cuando estén listos los datos.",
  [MONTHLY]: "Mensual",
  [QUARTERLY]: "Trimestral",
  [SEMIYEARLY]: "Semestral",
  [YEARLY]: "Anual",
  [ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION]:
    "Una vez se realice el pago de este link, se le adjuntará la información del plan al anunciante que selecciones.",
  [DESCRIPTION_PAYMENT_LINK_CLARIFICATION]:
    "Esta descripción se mostrará en el link de pago para darle más información al anunciante sobre qué pagará.",

  /*PAYMENTS*/
  [SELECT_PAYMENT_METHOD]: "Seleccionar método de pago",
  [ADD_PAYMENT_METHOD]: "Agregar método de pago",
  [PAYMENT_METHOD]: "Método de pago",
  [PAYMENT_METHODS]: "Métodos de pago",
  [PAYMENT_METHODS_EMPTY]: "No hay métodos de pago, añade uno",
  [MAIN_PAYMENT_METHOD]: "Método de pago principal",
  [OTHERS_PAYMENT_METHODS]: "Otros métodos de pago",
  [OTHERS_PAYMENT_METHODS_EMPTY]: "No hay métodos de pago adicionales",
  [PAYMENT_METHODS_SECURE]: "Tus métodos de pago estarán seguros",
  [PAYMENT_METHODS_SECURE_EXPLANATION]:
    "Tu información de pago nunca estará expuesta y podrás modificarla cuando quieras",
  [PAYMENT_METHODS_ENCRYPTED]: "Encriptado de extremo a extremo",
  [PAYMENT_SUCCESSFUL]: "Pago exitoso",
  [PRICE_TO_BE_PAID]: "Precio a pagar",
  [PAY]: "Pagar",
  [ADD_CARD]: "Añadir tarjeta",
  [SAVE_CONFIGURATION]: "Guardar configuración",
  [CARD_INFORMATION_EXPLANATION_TITLE]: "Información recolectada de tu tarjeta",
  [CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE]: "Número de la tarjeta",
  [CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION]:
    "El número de la tarjeta consta de 16 dígitos y eetán separados en grupos de 4, se encuentran en el frente de la tarjeta.",
  [CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE]: "Fecha de Vencimiento",
  [CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION]:
    "Se puede encontrar en la parte delantera o trasera de la tarjeta, consta de 4 o a veces 3 números, su formato es MM/YY (Mes en número/Últimos dos números del año).",
  [CARD_INFORMATION_CVV_EXPLANATION_TITLE]: "Código de Seguridad (CVV)",
  [CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION]:
    "Generalmente se encuentra en la parte trasera, a veces en la delantera, consta de 4 o 3 números y su función es para validar la tarjeta.",
  [YOU_WILL_PAY]: "Pagarás",
  [ACCEPT_TERMS_PAY]: `Acepta los términos y condiciones de {{NAME_BUSINESS}}`,
  [DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE]:
    "¿Estás seguro de borrar este método de pago?",
  [QUANTITY_TO_PAY]: "Cantidad a pagar",

  /*FIELDS PLACEHOLDERS*/
  [NAME_PLACEHOLDER]: "Nombre",
  [EMAIL_PLACEHOLDER]: "Correo electrónico",
  [PASSWORD_PLACEHOLDER]: "Contraseña",
  [PASSWORD_CONFIRMATION_PLACEHOLDER]: "Repite la contraseña",
  [SELECT_BUSINESS_PLACEHOLDER]: "Selecciona una empresa",
  [NAME_BUSINESS_PLACEHOLDER]: "Nombre de la Empresa",
  [SELECT_BRANDS_PLACEHOLDER]: "Selecciona las marcas",
  [MAIN_BRAND_PLACEHOLDER]: "Marca principal",
  [SELECT_BRAND_PLACEHOLDER]: "Selecciona una marca",
  [SELECT_STORE_PLACEHOLDER]: "Selecciona una tienda",
  [NAME_AND_LAST_NAME_PLACEHOLDER]: "Nombres y apellidos",
  [SELECT_TYPE_PLAN_PLACEHOLDER]: "Tipo de plan",
  [COST_COLLABORATION_PLACEHOLDER]: "Costo por colaboración",
  [CREDITS_PLACEHOLDER]: `{{NAME_BUSINESS}} Credits`,
  [TRIAL_DAYS_PLACEHOLDER]: "Días de prueba",
  [TYPE_PACKAGE_PLACEHOLDER]: "Tipo de planes",
  [PRICE_BASIC_PLACEHOLDER]: "Costo por suscripción basic",
  [COST_COLLABORATION_BASIC_PLACEHOLDER]: "Costo por colaboración basic",
  [PRICE_PRO_PLACEHOLDER]: "Costo por suscripción pro",
  [COST_COLLABORATION_PRO_PLACEHOLDER]: "Costo por colaboración pro",
  [PRICE_GROWTH_PLACEHOLDER]: "Costo por suscripción growth",
  [COST_COLLABORATION_GROWTH_PLACEHOLDER]: "Costo por colaboración growth",
  [COMMERCIAL_PLACEHOLDER]: "Comercial",
  [BUSINESS_NAME_PLACEHOLDER]: "Nombre de la marca",
  [DESCRIPTION_OPTIONAL_PLACEHOLDER]: "Descripción (opcional)",
  [PRICE_PLACEHOLDER]: "Precio",
  [CURRENCY_PLACEHOLDER]: "Moneda",
  [IS_RECURRENCE_PLACEHOLDER]: "El cobro es",
  [RECURRENCE_PLACEHOLDER]: "Recurrencia",
  [SELECT_CAMPAIGN_PLACEHOLDER]: "Selecciona una campaña",
  [SELECT_UGC_CONTENT_FORMAT]: "Selecciona un estilo del contenido",
  [SELECT_SOME_OPTIONS]: "Selecciona algunas opciones",
  [OBJECTIVE_PLACEHOLDER]: "Objetivo",
  [PLATFORMS_PLACEHOLDER]: "Plataformas",
  [PLATFORM_PLACEHOLDER]: "Plataforma",
  [DAILY_BUDGET_PLACEHOLDER]: "Presupuesto diario",
  [LIFETIME_BUDGET_PLACEHOLDER]: "Presupuesto total",
  [LOCATIONS_PLACEHOLDER]: "Ubicaciones",
  [AGE_RANGE_PLACEHOLDER]: "Rangos de edad",
  [GENDERS_PLACEHOLDER]: "Géneros",
  [INTERESTS_PLACEHOLDER]: "Intereses",
  [BEHAVIORS_PLACEHOLDER]: "Comportamientos",
  [DEMOGRAPHICS_PLACEHOLDER]: "Demografía",
  [DESCRIPTION_PLACEHOLDER]: "Descripción",
  [TRACKING_META_ID_PLACEHOLDER]: "Meta Pixel ID",
  [TRACKING_TIKTOK_ID_PLACEHOLDER]: "TikTok Pixel ID",
  [DESTINATION_URL_PLACEHOLDER]: "URL a donde se lleva el trafico",
  [CREATOR_BODY_BUILD_PLACEHOLDER]: "Tipo de cuerpo",
  [CREATOR_CLOTHING_STYLE_PLACEHOLDER]: "Estilo de vestimenta",
  [CREATOR_CONTENT_STYLE_PLACEHOLDER]: "Estilo de contenido",
  [CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER]: "Características distintivas",
  [CALL_TO_ACTION_PLACEHOLDER]: "Call to action",
  [ADS_CAMPAIGN_CONTEXT]: "¿De qué se trata tu campaña?",
  [URL_PLACEHOLDER]: "https://www.url.com",
  [TYPE_BUDGET_PLACEHOLDER]: "Tipo de presupuesto",
  [SEARCH_TOPIC_PLACEHOLDER]: "Foodie",
  [SEARCH_USERNAME_PLACEHOLDER]: "sofiavergara",
  [CREATOR_LOCATION_PLACEHOLDER]: "Ubicación del creador",
  [AUDIENCE_LOCATION_PLACEHOLDER]: "Ubicación de la audiencia",
  [PLAN_PLACEHOLDER]: "Plan",
  [ADS_CREDITS_PLACEHOLDER]: "Créditos",
  [ADS_COINS_PLACEHOLDER]: "Monedas",
  [ADVERTISER_PLACEHOLDER]: "Anunciante",

  /*OPTIONS SELECT*/
  [PLAN_PAY_PER_USE]: "Suscripción pago por uso",
  [PLAN_PACKAGE]: "Licencia de paquete",
  [PLAN_REGULAR]: "Planes regulares (Basic y Pro)",
  [PLAN_ENTERPRISE]: "Enterprise",
  [ADD_NEW_OPTION]: `Agregar "{{value}}"`,
  [RECURRENT_OPTION]: "Recurrente",
  [RECURRING_DAYS]: "Cada día",
  [RECURRING_WEEKS]: "Cada semana",
  [RECURRING_MONTHS]: "Cada mes",
  [RECURRING_YEARS]: "Cada año",

  /*PLANS*/
  [PLAN_PAY_PER_USE_NAME]: "Plan pago por uso",
  [PLAN_PACKAGE_NAME]: "Plan {{value}}",

  /*FIELDS VALIDATIONS*/
  [FIELD_REQUIRED]: "Este campo es requerido",
  [FIELD_NUMERIC]: "El campo debe ser numérico",
  [FIELD_TEXT]: "El campo solo debe tener letras",
  [FIELD_ALPHANUMERIC]: "El campo solo debe tener letras y números",
  [FIELD_MIN_LENGTH]: "Debes ingresar al menos {{value}} caracteres",
  [FIELD_MAX_LENGTH]: "Debes ingresar máximo {{value}} caracteres",
  [FIELD_FILE_VALID_REQUIRED]:
    "Debes seleccionar un archivo alguno de estos formatos: {{value}}",
  [FIELD_FILE_MAX_SIZE]:
    "El archivo es muy grande, por favor selecciona uno con un tamaño menor a {{value}}{{type}}",
  [FIELD_IMAGE_REQUIRED]: "Debe seleccionar una imagen de formato valido",
  [FIELD_IMAGE_FORMAT_VALID]:
    "La imagen es muy grande, por favor selecciona una con un tamaño menor a {{value}}{{type}}",
  [FIELD_IMAGE_SIZE_VALID]: "No se ha seleccionado ninguna imagen",
  [FIELD_FILE_REQUIRED]:
    "El archivo es muy grande, por favor seleccione uno con un tamaño menor a {{value}}{{type}}",
  [FIELD_FILE_SIZE_VALID]: "No se ha seleccionado ningún archivo",
  [FIELD_URL_VALID]: "La URL debe ser válida",
  [FIELD_URL_HTTPS_VALID]: "La URL debe incluir https://",
  [FIELD_EMAIL_VALID]: "El correo electrónico debe ser válido",
  [FIELD_EMAIL_REGISTERED]:
    "Este correo electrónico ya está registrado, intenta con un correo electrónico diferente",
  [FIELD_SPACES_VALID]: "No se permiten espacios en blanco",
  [FIELD_PHONE_VALID]: "El número de celular debe ser válido",
  [FIELD_ALL_REQUIRED]: "Todos los campos son requeridos",
  [FIELD_BOTH_PASSWORD_REQUIRED]: "Ambas contraseñas son requeridas",
  [FIELD_PASSWORDS_NO_MATCH]: "Las contraseñas no coinciden",
  [FIELD_PASSWORD_MIN_LENGTH]: "La contraseña debe tener al menos 6 caracteres",
  [FIELD_PASSWORD_CHANGE_MIN_LENGTH]:
    "Si vas a cambiar la contraseña, debe de ser de al menos de 6 caracteres",
  [FIELD_PASSWORD_INSECURE]:
    "La contraseña debe tener al menos 6 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial",
  [FIELD_NUMBER_NEGATIVE]: "El número no puede ser negativo",
  [FIELD_EMAIL_REQUIRED]: "El correo electrónico es requerido",
  [FIELD_SEARCH_CHATS_MORE_3_CHARACTERS]:
    "Escribe más de 3 caracteres para buscar nuevos chats",
  [FIELD_NUMBER_0_OR_NEGATIVE]: "El número no puede ser 0 o negativo",
  [FIELD_NUMBER_100_GREATER]: "El número no puede ser mayor a 100",
  [FIELD_NUMBER_LESS_THAN]: "El número no puede ser menor a {{value}}",
  [FIELD_NUMBER_GREATER_THAN]: "El número no puede ser mayor a {{value}}",
  [FIELD_CREDITS_NOT_BE_NEGATIVE]: `Los {{NAME_BUSINESS}} Credits no pueden ser negativos`,
  [FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE]:
    "El precio por colaboración no puede ser negativo",
  [FIELD_TRIAL_MONTHS_NOT_BE_NEGATIVE]:
    "Los meses de prueba no pueden ser negativos",
  [FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE]:
    "Los días de prueba no pueden ser negativos",
  [FIELD_PLAN_PRICES_NOT_BE_NEGATIVE]:
    "Los precios de los planes no pueden ser negativos",
  [FIELD_STAFF_IS_REQUIRED]: "Debe seleccionar un staff",
  [FIELD_BOTH_DATES_REQUIRED]: "Ambas fechas son requeridas",
  [FIELD_START_DATE_GREATER_THAN_END_DATE]:
    "La fecha inicial no puede ser mayor a la fecha final",
  [FIELD_END_DATE_GREATER_NOW]:
    "La fecha de finalización no puede ser menor a la actual",
  [FIELD_MIN_AMOUNT_PAY]:
    "El monto a pagar no puede ser menor a {{value,currency}}",
  [FIELD_ACCESS_TOKEN_SHOPIFY_VALID]:
    "El token de acceso de Shopify no tiene el formato correcto, generalmente empieza con shpat_ seguido de caracteres aleatorios",
  [FIELD_NAME_REQUIRED]: "El nombre es requerido",
  [FIELD_OBJECTIVE_REQUIRED]: "El objetivo es requerido",
  [FIELD_PLATFORMS_REQUIRED]: "Las plataformas son requeridas",
  [FIELD_PLATFORM_REQUIRED]: "La plataforma es requerida",
  [FIELD_TYPE_BUDGET_REQUIRED]: "El tipo de presupuesto es requerido",
  [FIELD_BUDGET_REQUIRED]: "El presupuesto es requerido",
  [FIELD_CURRENCY_MIN_VALUE]:
    "El valor no puede ser menor a {{value,currency}}",
  [FIELD_LOCATIONS_REQUIRED]: "Las ubicaciones son requeridas",
  [FIELD_GENDERS_REQUIRED]: "Los géneros son requeridos",
  [FIELD_INTERESTS_REQUIRED]: "Los intereses son requeridos",
  [FIELD_BEHAVIORS_REQUIRED]: "Los comportamientos son requeridos",
  [FIELD_DESCRIPTION_REQUIRED]: "La descripción es requerida",
  [FIELD_VIDEO_REQUIRED]: "El video es requerido",
  [FIELD_META_TRACKING_ID_REQUIRED]: "El meta pixel es requerido",
  [FIELD_DESTINATION_URL_REQUIRED]: "La URL de destino es requerida",
  [FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED]:
    "La URL de destino no se encuentra configurada con el pixel seleccionado",
  [FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL]:
    "La URL de destino no se encuentra configurada con el pixel seleccionado, si continúas puede que no se registren algunas estadísticas",
  [FIELD_TIKTOK_TRACKING_ID_REQUIRED]: "El TikTok pixel es requerido",
  [FIELD_MIN_LENGTH_SELECT]: "Debes seleccionar al menos {{value}} opciones",
  [FIELD_MAX_LENGTH_SELECT]: "Debes seleccionar máximo {{value}} opciones",
  [FIELD_CONTENT_REQUIRED]: "El contenido es requerido",
  [FIELD_VERIFICATION_CODE_REQUIRED]: "El código de verificación es requerido",
  [FIELD_TYPE_MIN_LENGTH]: "Escribe al menos {{value}} caracteres",
  [FIELD_MIN_VALUE_TO_PAY_AMBASSADOR]:
    "El valor a pagar al embajador no puede ser menor a {{value,currency}}",

  /*MODAL INVITATION*/
  [MODAL_INVITATION_TITLE]: "Invita a {{influencerName}}",
  [MODAL_INVITATION_TITLE_CAMPAIGN]: "Invita a {{influencerName}} a tu campaña",
  [INVITE_FORM_ORDER_FROM_PLACE_HOLDER]: "Usar a partir de",
  [INVITE_FORM_ORDER_FROM_HELPER_TEXT]:
    "El creador podrá usar este código a partir de esta fecha",
  [INVITE_FORM_VALUE_PLACEHOLDER]: "Ingresa el valor de la invitación",
  [INVITE_FORM_VALUE_PLACEHOLDER_2]: "Ingresa el valor",
  [INVITE_FORM_VALUE_QUESTION]:
    "¿Cuál es el valor en producto que le quieres regalar al creador de contenido?",
  [INVITE_FORM_VALUE_CLARIFICATION]:
    "Este valor es netamente redimible en canje y no se te debitará de tu método de pago.",
  [INVITE_FORM_VALUE_HELPER_TEXT]: "Valor sugerido: {{value,currency}}",
  [INVITE_FORM_VALUE_TO_PAY_QUESTION]:
    "¿Quieres hacerle un pago adicional en efectivo al creador de contenido?",
  [INVITE_FORM_VALUE_TO_PAY_CLARIFICATION]:
    "Este valor se te debitará de tu método de pago una vez apruebes el contenido y se le pagará al creador. Se te debitará {{valueWithFee,currency}} (+{{fee}}% fee).",
  [INVITE_FORM_VALUE_TO_PAY_CLARIFICATION_2]:
    "Se te debitará {{valueWithFee,currency}} (+{{fee}}% fee)",
  [INVITE_FORM_VALUE_TO_PAY_PLACEHOLDER]: "",
  [INVITE_VALUE]: "Valor de la invitación",
  [INVITE_VALUE_EXPLANATION]:
    "Este valor no se lo darás en efectivo, sino en canje de productos de tu marca.",
  [INVITE_FORM_UGC_FORMAT_QUESTION]:
    "¿Qué estilo de contenido quieres que haga el creador?",
  [INVITE_FORM_LANGUAGE_QUESTION]:
    "¿En qué idioma quieres que se haga el contenido?",
  [INVITE_FORM_REFERENCE_URL_QUESTION]:
    "¿Qué contenido quieres que el creador tenga como referencia?",
  [INVITE_FORM_COMMENT_PLACEHOLDER]: "Escribe aquí tus recomendaciones",
  [INVITE_FORM_COMMENT_PLACEHOLDER_2]: "Comentario",
  [INVITE_FORM_COMMENT_PLACEHOLDER_3]:
    "Deseo que el contenido sea educativo, que invite a la audiencia a experimentar mi producto destacando sus ventajas, en escenas cotidianas, preferiblemente en un entorno elegante.",
  [INVITE_FORM_COMMENT_HELPER_TEXT]:
    "Crea un guión de contenido. Describre el estilo, el mensaje principal y cualquier elemento imprescindible.",
  [INVITE_FORM_TYPE_CONTENT]: "Tipo de contenido",
  [INVITE_FORM_TYPE_CONTENT_IMAGES]: "Imágenes",
  [INVITE_FORM_TYPE_CONTENT_VIDEOS]: "Vídeos",
  [INVITE_FORM_TYPE_CONTENT_REQUIRED]: "El tipo de contenido es requerido",
  [INVITE_FORM_CODE_PLACEHOLDER]: "Usa un código personalizado",
  [INVITE_FORM_CODE_HELPER_TEXT]:
    "Utiliza el código de descuento de tu propio e-commerce para procesar la venta.",
  [INVITE_FORM_CODE_REQUIRED]: "El código es requerido",
  [INVITE_FORM_CODE_MIN_LENGTH]:
    "El código debe tener al menos {{value}} caracteres",
  [INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT]: `¿Como integrar mis invitaciones de {{NAME_BUSINESS}} con {{platform}}?`,
  [INVITE_FORM_DISCOUNT_CODE_PLACEHOLDER]: "Crea código de descuento",
  [INVITE_FORM_DISCOUNT_CODE_HELPER_TEXT]:
    "(Opcional) Crea un código de descuento para la audiencia de este creador.",
  [INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION]:
    "Puedes explorar las opciones en esta sección, aunque ten en cuenta que algunas configuraciones podrían afectar la tasa de aceptación de invitaciones de creadores de contenido.",
  [INVITE_FORM_SELECT_FORMATS_PLATFORM]: "Selecciona el formato del contenido",
  [INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION]:
    "El formato final dependerá del creador",
  [INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES]: "Ráfaga de Historias",
  [INVITE_FORM_FORMAT_INSTAGRAM_REEL]: "Instagram reel",
  [INVITE_FORM_FORMAT_INSTAGRAM_POST]: "Instagram post",
  [INVITE_FORM_FORMAT_INSTAGRAM_STORY]: "Instagram story",
  [INVITE_FORM_FORMAT_TIKTOK_POST]: "TikTok post",
  [INVITE_FORM_FORMAT_TIKTOK_STORY]: "TikTok story",
  [INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER]: "Link personalizado de tracking",
  [INVITE_FORM_TERMS_PLACEHOLDER]: "Términos y condiciones",
  [INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER]: "Selecciona un idioma",
  [INVITE_FORM_SELECT_EVENTS_PLACEHOLDER]: "Selecciona un evento",
  [SELECT_EVENTS_DISABLED_OPTION]: "Ningún evento",
  [INVITE_SENT]: "Invitación enviada",
  [INVITE_SENT_DESCRIPTION]:
    "Por un valor de {{value,currency}} al creador de contenido {{influencerName}}",
  [INVITE_EDIT]: "Invitación editada",
  [REQUEST_REJECT_CONFIRMATION_DESCRIPTION]:
    "Rechaza esta solicitud o descarta definitivamente este creador para que no te vuelva aparecer ",
  [REQUEST_REJECTED]: "Solicitud Rechazada",
  [REQUEST_REJECTED_DESCRIPTION]:
    "Se rechazó correctamente la solicitud del creador de contenido {{influencerName}}",
  [INVITE_CANCELED]: "Invitación cancelada",
  [INVITE_CANCELED_DESCRIPTION]:
    "Se canceló la invitación del creador de contenido {{influencerName}}",
  [INVITE_VALUE_REQUIRED]: "El valor de la invitación es necesario",
  [INVITE_VALUE_MIN_VALUE]:
    "El valor de la invitación no puede ser menor a {{value,currency}}",
  [INVITE_VALUE_TO_PAY_MIN_VALUE]:
    "El valor a pagar no puede ser menor a {{value,currency}}",
  [INVITE_COMMENT_REQUIRED]: "Las recomendaciones son requeridas",
  [INVITE_COMMENT_MIN_LENGTH]:
    "Las recomendaciones no pueden tener menos de {{value}} cáracteres",
  [INVITE_COMMENT_MAX_LENGTH]:
    "Las recomendaciones no pueden tener más de {{value}} cáracteres",
  [INVITE_COMMENT_NO_LINK]: "No puede haber un link en tus recomendaciones",
  [INVITE_COMMENT_NO_PHONE]:
    "No puede haber un número de celular en tus recomendaciones",
  [INVITE_TERMS_LENGTH]:
    "Los términos no pueden tener menos de {{minLength}} y más de {{maxLength}} cáracteres",
  [INVITE_PERSONALIZED_URL_INVALID]: "El link personalizado debe ser válido",
  [INVITE_PERSONALIZED_URL_EXPLANATION]:
    "El link personalizado de tracking es un link que puedes crear para que el creador de contenido pueda usarlo en la colaboración que hará con tu marca, de esta manera podrás saber cuantas personas ingresaron a tu sitio web desde del creador de contenido.",
  [INVITE_MIN_VALUE_OF_MENU]:
    "Según tu catálogo, el producto de menor valor es de {{productValue,currency}} y tu invitación es de {{inviteValue,currency}}",
  [INVITE_STORE_LOCATION]:
    "Es posible que no tengas una tienda en la ciudad en la que se encuentra este creador{{city}}. ¿Quieres invitarlo de todas formas?",
  [INVITE_CODE_IN_OTHER_INVITE]:
    "Este código ya lo estás usando en otra invitación",
  [INVITE_THESE_CREATORS]: "Invitar a estos creadores",
  [INVITE_THESE_CREATORS_CONFIRMATION]:
    "¿Estás seguro que deseas invitar a estos {{count}} creadores?",
  [INVITE_CREATORS]: "Invitar creadores",
  [LIST_OF_CREATORS]: "Listado de creadores",
  [MESSAGE_AFTER_INVITATION]: "¡Te enviamos una invitación! {{description}}",

  /*MODAL COMMERCIAL*/
  [MODAL_COMMERCIAL_TITLE_CREATE]: "Crear comercial",
  [MODAL_COMMERCIAL_TITLE_EDIT]: "Comercial {{value}}",
  [MODAL_COMMERCIAL_CREATE_COMMERCIAL]: "Crear comercial",
  [MODAL_COMMERCIAL_ALREADY_EXIST]: "Un comercial con este nombre ya existe",

  /*MODAL CREATE PAYMENT LINK*/
  [MODAL_CREATE_PAYMENT_LINK_TITLE]: "Crear link de pago",
  [MODAL_CREATE_PAYMENT_LINK_DESCRIPTION]:
    "Crea un link de pago para esta marca, esto creará una suscripción enterprise.",
  [MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL]: "Modal de Creación de Link de Pago",
  [MODAL_CREATE_PAYMENT_LINK_LINKS]: "Links de Pago",
  [MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY]: "No hay links de pago",
  [MODAL_CREATE_PAYMENT_LINK_NAME]: "Pago {{value}}",

  /*MODAL HELP VIDEO AND FAQ*/
  [WARNING_MOBILE_TUTORIAL]:
    "Para una mejor experiencia, realizar el tutorial desde tu computadora o laptop",
  [FAQ_DESCRIPTION]:
    "Aquí encontrarás las preguntas más frecuentes de nuestros usuarios,puedes buscarlas por palabras o frases claves.",
  [FAQ_QUESTION_WHAT_IS_BUZZLY]: `¿Qué es {{NAME_BUSINESS}}?`,
  [FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION]: `{{NAME_BUSINESS}} es la primera plataforma que permite a las pequeñas empresas ofrecer productos gratuitos o con descuento a los creadores de contenidos a cambio de exposición en las redes sociales. Al mismo tiempo, los creadores pueden acceder a una amplia variedad de productos y servicios directamente de sus marcas favoritas a través de nuestra aplicación móvil.`,
  [FAQ_QUESTION_WHAT_IS_INVITATION]: "¿Qué es una invitación?",
  [FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION]:
    "Una invitación es un código que se le entrega a un creador de contenido para que pueda redimirlo en tu tienda en la app movil y así obtener productos o servicios de tu marca a cambio de generar contenido que podrás usar.",
  [FAQ_QUESTION_WHAT_IS_REQUEST]: "¿Qué es una solicitud?",
  [FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION]:
    "Una solicitud es una petición que hace un creador de contenido para que le envíes una invitación a colaborar con tu marca.",
  [FAQ_QUESTION_WHAT_IS_PUBLICATION]: "¿Qué es una publicación?",
  [FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION]: `Una publicación es la colaboración que se generó cuando el creador de contenido redimió una invitación en tu tienda. Una publicación puede tener los siguientes estados: <br><br>
  <strong>Publicación Pendiente:</strong> El creador de contenido redimió la invitación pero aún no ha publicado nada en sus redes sociales.<br>
  <strong>Analítica Pendiente:</strong> El creador de contenido ya publicó el contenido de tu marca en sus redes sociales y lo subió, pero aún no está disponible las analíticas.<br>
  <strong>Verificar:</strong> El creador ya subió las analíticas y ya puedes revisar los resultados de la colaboración para poder darle una calificación al creador.<br>
  <strong>Verificada:</strong> Ya calificaste la colaboración con base en el contenido y las analíticas generadas.<br>
  <strong>Feedback:</strong> El creador tuvo algún problema con tus productos o servicios, no se genera una colaboración, puedes invitarlo de nuevo.<br>
  `,
  [FAQ_QUESTION_WHAT_IS_AMBASSADOR]: "¿Qué es un embajador?",
  [FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION]:
    "Un embajador es un creador de contenido que ha tenido una colaboración exitosa con tu marca y lo elegiste como embajador, por lo que podrás invitarlo a colaborar cada cierto tiempo y tener una comunicación de una manera más cercana.",
  [FAQ_QUESTION_WHAT_IS_CAMPAIGN]: "¿Qué es una campaña?",
  [FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION]: `Una campaña es una forma de colaboración con los creadores mucho más estructurada, donde puedes asignar un presupuesto, un objetivo, tipo de contenidos y con cuales características específicas para que los creadores de contenido puedan postularse. Una vez se postulen puedes ir aceptando o rechazando creadores para tu campaña.`,
  [FAQ_QUESTION_WHAT_IS_ORDER]: "¿Qué es una orden?",
  [FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION]: `Una orden es una solicitud de productos o servicios que hace un creador de contenido a tu marca vía delivery. Una orden puede tener los siguientes estados: <br><br>
  <strong>Por aceptar:</strong> El creador de contenido ya solicitó los productos o servicios pero debes aceptar la orden y asignarle un tiempo de procesamiento.<br>
  <strong>En proceso:</strong> Ya aceptaste la orden y estás procesando los productos o servicios para enviarlos al creador de contenido cuando esté listo, asignándole la información de envío.<br>
  <strong>Por entregar:</strong> Ya enviaste los productos o servicios al creador de contenido y solo falta que le sea entregado.<br>
  `,
  [FAQ_QUESTION_WHAT_IS_STORE]: "¿Qué es una tienda?",
  [FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION]:
    "Una tienda es una sucursal física donde los creadores de contenido pueden redimir las invitaciones que les envías para obtener productos o servicios de tu marca y generar contenido desde tus instalaciones.",
  [FAQ_QUESTION_WHAT_ROLES_EXISTS]: "¿Cuáles roles existen?",
  [FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION]: `Puedes crear diferentes usuarios para que accedan a tu panel de marca, existen dos roles: <br><br>
  <strong>Administrador:</strong> El administrador puede gestionar todo las invitaciones a creadores de contenidos, solicitudes, embajadores, ordenes, campañas, etc. También puede modificar la información de tu marca y crear más usuarios.<br>
  <strong>Operador:</strong> El operador es el que se encarga de recibir al creador si asistirá a una de tus tiendas, por lo que podrá efectuar la redención del código, gestionar las ordenes en sus tres estados y ver el catálogo.<br>
  `,
  [FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS]:
    "¿Le entrego los productos y debo hacer algo adicional en la plataforma?",
  [FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION]: `No debes hacer nada adicional. Únicamente ingresas el código en la sección de “Redimir Código”, verificas el valor de la invitación y le entregas los productos.<br><br>
    
  <strong>1.</strong> Selecciona la tienda en la parte superior derecha.<br>
  <strong>2.</strong> Ingresa el código que te suministra el creador de contenido en el momento que llega a la tienda..<br>
  <strong>3.</strong> Valida código y su respectivo valor.`,
  [FAQ_QUESTION_MUST_CALL_ADMIN]:
    "¿Debo llamar al propietario del negocio a verificar las invitaciones?",
  [FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION]:
    "No te debes comunicar. Si cuando ingresas el código, la plataforma te lo acepta, eso quiere decir que el propietario del negocio, tu jefe directo o el administrador; ya le hizo una invitación a ese Creador de Contenido anteriormente y está aprobado por esa persona.",
  [FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION]:
    "¿Qué pasa si el creador de contenido pide productos que excedan el valor de la invitación?",
  [FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION]:
    "Deben pagar el excedente. <strong>Sugerencia:</strong> siempre decirle al creador que en lo posible se acerque lo más que pueda al valor de la invitación.",
  [FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE]:
    "¿Qué pasa si el creador de contenido redime el código y no completa el valor total de la invitación?",
  [FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION]:
    "Una vez redimen, el código se expira automáticamente, esto quiere decir que ese código no funciona para una siguiente ocasión",
  [FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS]:
    "¿Cuáles son las diferencias entre la modalidad de canje y las campañas pagas?",
  [FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION]: `La diferencia principal es que en la <strong>modalidad de canje</strong>, los creadores de contenido tienen más libertad para elegir cómo hacer el contenido, generando una colaboración más orgánica y natural. En cambio, en las <strong>campañas pagas</strong>, las marcas pueden establecer reglas más estrictas sobre cómo se debe hacer el contenido y el creador debe seguir esos lineamientos para aprobarse el contenido.`,
  [FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS]:
    "¿Cómo puedo asegurarme de que los creadores comprendan y acepten los términos del canje?",
  [FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION]: `Para asegurarse de que los creadores de contenido comprendan y acepten los términos del canje, puedes hacer lo siguiente:<br><br>
    <strong>1.</strong> Revisar el perfil y las métricas del creador para saber si el contenido que generalmente hace está alineado con lo que buscas.<br>
    <strong>2.</strong> El comentario de la invitación debe ser claro y fácil de entender.<br>
    <strong>3.</strong> Una vez envíes la invitación, puedes enviarle un mensaje al creador de contenido para establecer una comunicación más directa y aclarar cualquier duda que tenga para hacer el contenido.<br>
    `,

  /*INFLUENCER STATISTICS*/
  [INFLUENCER_NOT_FOUND]: "No se encontró este creador",
  [LIST_CREATORS_WITH_AUDIENCE_SIMILAR]:
    "Listado de creadores con audiencias similares",
  [AUDIENCE_INFORMATION]: "Información de la audiencia",
  [AUDIENCE_CREDIBILITY]: "Credibilidad de la audiencia",
  [AUDIENCE_CREDIBILITY_TITLE_EXPLANATION]:
    "¿Qué es la credibilidad de la audiencia?",
  [AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION]:
    "La credibilidad de la audiencia es un indicador que determina el porcentaje de seguidores que son perfiles reales o validos, es decir, se excluyen bots o perfiles fraudulentos. Entre mayor sea el porcentaje más confiable será la audiencia del creador de contenido.",
  [AUDIENCE_BY_COUNTRY]: "Audiencia por país",
  [AUDIENCE_BY_CITIES]: "Audiencia por ciudades",
  [AUDIENCE_BY_BRAND_AFFINITY]: "Afinidad de marcas",
  [AUDIENCE_BY_INTERESTS]: "Intereses",
  [AUDIENCE_BY_DISTRIBUTION_AGE]: "Distribución de edades y géneros",
  [AUDIENCE_BY_PERCENTAGE_GENDERS]: "Porcentaje de géneros",
  [AUDIENCE_BY_SIMILAR_AUDIENCE]: "Perfiles similares",
  [AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND]:
    "No se encontraron perfiles similares",
  [INFLUENCER_POPULAR_CONTENT]: "Contenido popular",
  [GALLERY_CONTENT_BUZZLY]: `Portafolio`,

  /* LIST MENU*/
  [MENU_PLATFORM]: "Canje",
  [MENU_MEDIA]: "Contenido",
  [MENU_SERVICES]: "Herramientas",
  [MENU_ACTIONS]: "Gestión",
  [MENU_CONFIGURATION]: "Configuración",
  [MENU_ADMINISTRATION]: "Administración",
  [SUB_MENU_CREATORS]: "Creadores",
  [SUB_MENU_AMBASSADORS]: "Embajadores",
  [SUB_MENU_INVITATIONS]: "Invitaciones",
  [SUB_MENU_PUBLICATIONS]: "Colaboraciones",
  [SUB_MENU_EVENTS]: "Eventos",
  [SUB_MENU_CAMPAIGNS]: "Campañas",
  [SUB_MENU_ADS_CAMPAIGNS]: "{{NAME_BUSINESS}} Ads",
  [SUB_MENU_REPORTS]: "Reportes",
  [SUB_MENU_DISCOVERY]: "Explorar",
  [SUB_MENU_SOCIAL_FEED]: "Social feed",
  [SUB_MENU_GALLERY]: "Galería",
  [SUB_MENU_TOP_CONTENT]: "Contenido destacado",
  [SUB_MENU_REDEEM_CODE]: "Redimir código",
  [SUB_MENU_ORDERS]: "Órdenes",
  [SUB_MENU_ACCOUNT]: "Cuenta",
  [SUB_MENU_STORES]: "Mis tiendas",
  [SUB_MENU_MENU]: "Catálogo",
  [SUB_MENU_USERS]: "Usuarios",
  [SUB_MENU_BILLING_AND_PAYMENTS]: "Facturación",
  [SUB_MENU_BILLING_DASHBOARD]: "Pagos",
  [SUB_MENU_BILLING_DATA]: "Información",
  [SUB_MENU_USERS_OWNER]: "Usuarios",
  [SUB_MENU_BRANDS]: "Marcas",
  [SUB_MENU_BRAND_SIGNUPS]: "Registros de marcas",
  [SUB_MENU_CREATORS_OWNER]: "Creadores",
  [SUB_MENU_CREATORS_EXTERNALS]: "Creadores externos",
  [SUB_MENU_PAY_CREATORS]: "Pagos a creadores",
  [SUB_MENU_CREATOR_SIGNUPS]: "Registro de creadores",
  [SUB_MENU_CONTENTS]: "Contenidos",
  [SUB_MENU_DELIVERIES]: "Deliveries",
  [SUB_MENU_CONTENT_APPLICATION]: "Aplicación de contenido",
  [SUB_MENU_REPORTS_OWNER]: "Reportes",
  [SUB_MENU_FEEDBACK]: "Feedback",
  [SUB_MENU_PAYMENTS_FAILED]: "Pagos fallidos",

  /*DATE*/
  [DATE_HOUR]: "{{date, hour}}",
  [DATE_SHORT]: "{{date, day}} de {{date, monthText}}, {{date, year}}",
  [DATE_SHORT_LOCALIZED]: "{{date, short}}",
  [DATE_LONG]: "{{date, day}}/{{date,month}}/{{date,year}} {{date,hour}}",
  [DATE_LONG_TEXT]:
    "{{date,dayText}} {{date, day}} de {{date,monthText}} de {{date,year}}",
  [DATE_LONG_TEXT_2]:
    "{{date,dayTextLong}} {{date, day}} de {{date,monthText}} de {{date,year}}",
  [DATE_LONG_TEXT_WITH_HOUR]:
    "{{date,dayText}} {{date, day}} de {{date,monthText}} de {{date,year}} {{date,hour}}",
  [DATE_LONG_LOCALIZED]: "{{date, long}}",
  [DATE_DISTANCE]: "{{date, ago}}",
  [LAST_UPDATE_DATE]: "Última actualización: {{date, short}}",
  [PUBLISHED_DATE]: "Publicado: {{date, short}}",

  /*CURRENCY*/
  [VALUE_WITH_CURRENCY]: "{{value,currency}}",
  [VALUE_WITH_PERCENTAGE]: "{{value, number}}%",
  [NUMBER_CURRENCY]: "{{value,currency}}",
  [VALUE_EXPRESSED_IN]: "Valor expresado en {{currency}}",

  /*BUTTON*/
  [BUTTON_INVITE]: "Invitar",
  [BUTTON_INVITE_ALL]: "Invitar a todos",
  [BUTTON_VIEW_INVITE]: "Ver invitación",
  [BUTTON_EDIT_INVITE]: "Editar invitación",
  [BUTTON_CANCEL_INVITE]: "Cancelar invitación",
  [BUTTON_VIEW_REQUEST]: "Ver solicitud",
  [BUTTON_REJECT_REQUEST]: "Rechazar solicitud",
  [BUTTON_ORDER_IN_PROCESS]: "Orden en proceso",
  [BUTTON_VIEW_COLLABORATION]: "Ver colaboración",
  [BUTTON_VIEW_METRICS]: "Ver métricas",
  [BUTTON_VIEW_REPORT]: "Ver reporte",
  [BUTTON_INIT_CHAT]: "Enviar mensaje",
  [BUTTON_ADD_REFERENCE_PROFILE]: "Agregar perfil de referencia",
  [BUTTON_USE_AS_REFERENCE_PROFILE]: "Usar como perfil de referencia",
  [BUTTON_ADD_AS_REFERENCE]: "Agregar como referencia",
  [BUTTON_ADDED]: "Agregado",
  [BUTTON_DOWNLOAD]: "Descargar",
  [BUTTON_CALCULATE]: "Calcular",
  [BUTTON_ENTERPRISE_CALCULATE]: "Calcular para empresarial",
  [BUTTON_REDEEM_CODE]: "Redimir",
  [BUTTON_ADD_STORE]: "Agregar tienda",
  [BUTTON_SEARCH]: "Buscar",
  [BUTTON_ACCEPT_ORDER]: "Aceptar orden",
  [BUTTON_REJECT_ORDER]: "Rechazar orden",
  [BUTTON_READY_ORDER]: "Orden lista",
  [BUTTON_DELIVER_ORDER]: "Orden entregada",
  [BUTTON_CANCEL]: "Cancelar",
  [BUTTON_CREATE_CAMPAIGN]: "Crear",
  [BUTTON_CREATE_CAMPAIGN2]: "Crear nueva campaña",
  [BUTTON_SAVE_PROGRESS]: "Guardar progreso",
  [BUTTON_NEXT]: "Siguiente",
  [BUTTON_PREVIOUS]: "Regresar",
  [BUTTON_ADVANCED_CONFIGURATION]: "Configuración avanzada",
  [BUTTON_FINISH]: "Finalizar",
  [BUTTON_UPLOAD_CONTENT]: "Subir contenido",
  [BUTTON_REMOVE_CONTENT]: "Eliminar contenido",
  [BUTTON_FINISHED_CAMPAIGN]: "Finalizar campaña",
  [BUTTON_SAVE_CAMPAIGN]: "Guardar campaña",
  [BUTTON_DEACTIVATE_CAMPAIGN]: "Desactivar campaña",
  [BUTTON_ACTIVATE_CAMPAIGN]: "Activar campaña",
  [BUTTON_INVITE_CREATOR]: "Invitar creador",
  [BUTTON_SEND_INVITE]: "Enviar invitación",
  [BUTTON_REJECT]: "Rechazar",
  [BUTTON_ACCEPT]: "Aceptar",
  [BUTTON_ACCEPT_AND_PAY]: "Pagar ahora",
  [BUTTON_ASSIGN_INVITE]: "Asignar invitación",
  [BUTTON_REVIEW_CONTENT]: "Revisar contenido",
  [BUTTON_VIEW_PRELIMINARY_CONTENT]: "Revisar",
  [BUTTON_VIEW_APPLICANT]: "Ver aplicante",
  [BUTTON_REVIEW]: "Calificar",
  [BUTTON_ACCEPT_CONTENT]: "Aceptar contenido",
  [BUTTON_REJECT_CONTENT]: "Rechazar contenido",
  [BUTTON_SEND_REVIEW]: "Mandar revisiones",
  [BUTTON_CONTINUE]: "Continuar",
  [BUTTON_CONTINUOUS]: "Continua",
  [BUTTON_UNDERSTOOD]: "Entendido",
  [BUTTON_MORE_QUESTIONS]: "Tengo más preguntas",
  [BUTTON_INIT_TUTORIAL]: "Iniciar tutorial",
  [BUTTON_PAYMENTS]: "Pagos",
  [BUTTON_BROADCAST]: "Broadcast",
  [BUTTON_SAVE]: "Guardar",
  [BUTTON_SEND_MESSAGE]: "Chatear ahora",
  [BUTTON_UPLOAD_FILE]: "Subir archivo",
  [BUTTON_REMOVE_FILE]: "Eliminar archivo",
  [BUTTON_VERIFY_PUBLICATION]: "Verificar publicación",
  [BUTTON_SEE_PUBLICATION_ON]: "Ver en {{value}}",
  [BUTTON_INVITE_AGAIN]: "Invitar de nuevo",
  [BUTTON_INVITE_BUZZLY]: `Invitar a {{NAME_BUSINESS}}`,
  [BUTTON_METRICS_READY]: "Métricas listas",
  [BUTTON_UPLOAD_TIKTOK]: "Subir en TikTok",
  [BUTTON_VIEW_PROFILE]: "Ver perfil",
  [BUTTON_GENERATE_DESCRIPTION]: "Generar descripción",
  [BUTTON_GENERATE_COMMENT]: "Generar comentario",
  [BUTTON_UPLOAD_IMAGE]: "Subir imagen",
  [BUTTON_REMOVE_IMAGE]: "Eliminar imagen",
  [BUTTON_SAVE_CHANGES]: "Guardar cambios",
  [BUTTON_UPLOAD_VIDEO]: "Subir vídeo",
  [BUTTON_REMOVE_VIDEO]: "Eliminar vídeo",
  [BUTTON_DISCONNECT]: "Desconectar",
  [BUTTON_CONNECT_TIKTOK]: "Conectar TikTok",
  [BUTTON_CONNECT_TIKTOK_BUSINESS]: "Conectar TikTok Business",
  [BUTTON_BE_ECOMMERCE]: "Siempre abierto",
  [BUTTON_NOT_BE_ECOMMERCE]: "Editar horarios",
  [BUTTON_ADD_TURN]: "Agregar turno",
  [BUTTON_DELETE]: "Eliminar",
  [BUTTON_CREATE]: "Crear",
  [BUTTON_CONNECT_WOOCOMMERCE]: "Conectar WooCommerce",
  [BUTTON_CREATE_USER]: "Crear usuario",
  [BUTTON_CREATE_CONTACT]: "Crear contacto",
  [BUTTON_VIEW_DETAILS]: "Ver detalles",
  [BUTTON_DOWNLOAD_INVOICE]: "Descargar factura",
  [BUTTON_CLOSE]: "Cerrar",
  [BUTTON_UPLOAD_CATALOG]: "Subir catálogo",
  [BUTTON_COPY_CATALOG]: "Copiar catálogo",
  [BUTTON_DOWNLOAD_FORMAT]: "Descargar formato",
  [BUTTON_DUPLICATE]: "Duplicar",
  [BUTTON_EDIT]: "Editar",
  [BUTTON_MOVE_OTHER_CATEGORY]: "Mover a otra categoría",
  [BUTTON_COPY]: "Copiar",
  [BUTTON_REGISTER]: "Registrarse",
  [BUTTON_NO_HAVE_DOCUMENT]: "No tengo este documento",
  [BUTTON_UPLOAD_AFTER]: "Subir después",
  [BUTTON_INVITED]: "Invitado",
  [BUTTON_YES_I_SURE]: "Sí, estoy seguro",
  [BUTTON_SEND_REVISION]: "Enviar revisión",
  [BUTTON_APPROVE_CONTENT]: "Aprobar",
  [BUTTON_UPLOAD_INSTAGRAM]: "Subir en Instagram",
  [BUTTON_CONNECT_INSTAGRAM]: "Conectar Instagram",
  [BUTTON_RELOAD_PAGE]: "Recargar página",
  [BUTTON_REPLACE_IMAGE]: "Reemplazar imagen",
  [BUTTON_GENERATE_CONTENT]: "Generar contenido",
  [BUTTON_REGENERATE_CONTENT]: "Regenerar contenido",
  [BUTTON_REGENERATE_TEXT]: "Regenerar texto",
  [BUTTON_UPLOAD_LOGO]: "Subir logo",
  [BUTTON_REPLACE_LOGO]: "Reemplazar logo",
  [BUTTON_UPLOAD_BACKGROUND]: "Subir fondo",
  [BUTTON_REPLACE_BACKGROUND]: "Reemplazar fondo",
  [BUTTON_REMOVE_BACKGROUND]: "Eliminar fondo",
  [BUTTON_GENERATE_OTHER]: "Regenerar",
  [BUTTON_SUBSCRIBE_NOW]: "Suscríbete ahora",
  [BUTTON_ADD_SHOP]: "Agregar tienda",
  [BUTTON_DOWNLOAD_REPORT]: "Descargar reporte",
  [BUTTON_PASS_TO_SUBSCRIPTION_BASIC]: "Cambiar a suscripción basic",
  [BUTTON_EXTEND]: "Extender",
  [BUTTON_ARCHIVE]: "Archivar",
  [BUTTON_UNARCHIVE]: "Desarchivar",
  [BUTTON_CREATE_TRANSACTION]: "Crear transacción",
  [BUTTON_UPDATE_STATISTICS]: "Actualizar estadísticas",
  [BUTTON_INVITE_EXCHANGE]: "Invitar en canje",
  [BUTTON_INVITE_CAMPAIGN]: "Invitar a campaña",
  [BUTTON_SEND_PAYMENT]: "Enviar pago",
  [BUTTON_CREATOR_CHAT]: "Chat de Creadores",
  [BUTTON_SEND_FILE]: "Enviar archivo",
  [BUTTON_SEE_MORE]: "Ver más",
  [BUTTON_SEND]: "Enviar",
  [BUTTON_YOU_HAVE_DOUBTS]: "¿Tienes preguntas? Contáctanos ",
  [BUTTON_SAVE_CONTENT]: "Guardar contenido",
  [BUTTON_SEND_BROADCAST]: "Enviar broadcast",
  [BUTTON_ADD_CONTENT]: "Agregar contenido",
  [BUTTON_SEE_ALL_APPLICANTS]: "Ver todos los aplicantes",
  [BUTTON_GO_BACK]: "Ir atrás",
  [BUTTON_CLEAR_FILTERS]: "Limpiar filtros",
  [BUTTON_ACTIVATE_AUTOMATICS_INVITATIONS]: "Activar invitaciones automáticas",
  [BUTTON_DEACTIVATE_AUTOMATICS_INVITATIONS]:
    "Desactivar invitaciones automáticas",
  [BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR]:
    "Envía invitaciones automaticas a perfiles similares a {{mainAccountName}}",
  [BUTTON_TIKTOK_BILLING]: "Facturación de TikTok",
  [BUTTON_META_BILLING]: "Facturación de Meta",
  [BUTTON_REPLACE_CONTENT]: "Reemplazar contenido",
  [BUTTON_SELECT_VIDEO]: "Seleccionar un video",
  [BUTTON_REPLACE_VIDEO]: "Reemplazar video",
  [BUTTON_SELECT_THIS_CONTENT]: "Seleccionar este contenido",
  [BUTTON_REMOVE_SELECTION]: "Eliminar selección",
  [BUTTON_RECHARGE_BALANCE]: "Recargar saldo",
  [BUTTON_CONNECT_SHOPIFY]: "Conectar Shopify",
  [BUTTON_CONNECT_META]: "Conectar Meta",
  [BUTTON_CONTACT_AN_ADVISOR]: "Necesito ayuda",
  [BUTTON_GENERATE_SCRIPT]: "Generar guión",
  [BUTTON_VIEW_INVOICE]: "Ver factura",
  [BUTTON_ADJUST_PREFERENCES]: "Ajustar preferencias",
  [BUTTON_GENERATE_AGAIN]: "Generar de nuevo",
  [BUTTON_CREATE_ADS_CAMPAIGN]: "Crear campaña",
  [BUTTON_EDIT_BUDGET]: "Editar presupuesto",
  [BUTTON_PUBLISH_CAMPAIGN]: "Publicar campaña",
  [BUTTON_START_CHAT]: "Chatear ahora",
  [BUTTON_ACTIVATE_NOW]: "Activar ahora",
  [BUTTON_NOT_NOW]: "Ahora no",
  [BUTTON_PROMOTE]: "Pautar",
  [BUTTON_SELECT_CONTENT]: "Seleccionar contenido",
  [BUTTON_DISCARD]: "Descartar",
  [BUTTON_ACTIVATE_BRAND]: "Activar marca",
  [BUTTON_SAVE_CATALOG]: "Guardar catálogo",
  [BUTTON_SAVE_PRODUCT]: "Guardar producto",
  [BUTTON_RESEND_CODE]: "Reenviar código",
  [BUTTON_CREATE_PAYMENT_LINK]: "Crear link de pago",

  /*CATEGORIES*/
  [NEW_CREATORS_CATEGORY]: "Nuevos creadores",
  [FAVORITES_CATEGORY]: "Favoritos",
  [TOP_RATED_CATEGORY]: "Mejor calificados",

  //VERSION
  [NEW_VERSION_TITLE]: "¡Nueva versión disponible!",
  [NEW_VERSION_DESCRIPTION]:
    "Hemos lanzado una actualización de nuestra web que podría generar conflictos con tu versión actual. Te recomendamos recargar la página para obtener la última versión y evitar posibles errores.",

  //INFLUENCER ACTIONS
  [DESCRIPTION_INVITE_EXCHANGE]:
    "Envia un bono digital para canjearlo por contenido",
  [DESCRIPTION_INVITE_CAMPAIGN]: "Crea una campaña y paga por contenido",
  [DESCRIPTION_SEND_MESSAGE]: "Chatea con este creador",
  [DESCRIPTION_SEND_PAYMENT]: "Envia un pago de forma segura",

  //MODAL PAY INFLUENCER
  [MODAL_PAY_CREATOR_AMOUNT_LABEL]: "Cantidad a pagar",
  [PAY_INFLUENCER]: "Pago a creador",
  [APPLICATION_VALUE]: "Valor de la aplicación",

  /*PAGES*/

  //CREATORS
  [CREATORS_TITLE]: "Creadores de Contenido",
  [CREATOR_DESCRIPTION]:
    "Encuentra y filtra creadores para colaborar en canje, enviar mensajes y pagos",
  [SEARCH_BAR_TEXT_CREATORS]: "Busca por nombre de usuario",
  [TAB_CREATORS]: "Todos",
  [TAB_REQUESTS]: "Solicitudes",
  [TAB_SUGGESTED]: "Para ti",
  [CREATORS_SUGGESTED_EMPTY]:
    "No se encontraron creadores sugeridos, ajusta tus preferencias para encontrar creadores",
  [CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER]:
    "Para poder ver los creadores sugeridos, primero debes crear o actualizar tu información en la sección de cuenta",
  [CREATORS_SUGGESTED_BAD_CONFIGURATION_AUTOMATIC_INVITATIONS]:
    "Para poder ver los creadores sugeridos, primero debes llenar o actualizar tu información en la sección de cuenta",
  [CREATORS_TABLE_COLUMN_NAME]: "Nombre",
  [CREATORS_TABLE_COLUMN_CITY]: "Ciudad",
  [CREATORS_TABLE_COLUMN_AUDIENCE]: "Audiencia",
  [CREATORS_TABLE_COLUMN_ENGAGEMENT]: "Engagement",
  [CREATORS_TABLE_COLUMN_DATE]: "Fecha",
  [CREATORS_TABLE_COLUMN_CATEGORIES]: "Categorias",
  [CREATORS_TABLE_COLUMN_SCORE]: "Puntuación",
  [CREATORS_TABLE_COLUMN_ACTIVITY]: "Actividad",
  [DISCOVER_NEW_CREATORS]: "Descubre nuevos creadores",

  //AMBASSADORS
  [AMBASSADORS_TITLE]: "Embajadores",
  [AMBASSADORS_DESCRIPTION]: "Gestiona los embajadores de tu marca",
  [AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION]:
    "Has completado el tutorial de embajadores, ahora sabes como potenciar tu marca con embajadores",
  [AMBASSADORS_TABLE_ARIAL_LABEL]: "Tabla de Embajadores de Marca",
  [AMBASSADORS_TABLE_COLUMN_PLATFORM]: "Platforma",
  [AMBASSADORS_TABLE_COLUMN_INVITATION]: "Invitación",
  [MODAL_AMBASSADOR_ARIAL_LABEL]: "Modal de embajador",
  [MODAL_AMBASSADOR_TITLE]: "Edita un embajador",
  [MODAL_AMBASSADOR_DESCRIPTION]:
    "Edita la información de este embajador de tu marca",
  [WITH_RECURRENT_INVITATION_TITLE]: "Invitación recurrente (opcional)",
  [WITH_RECURRENT_PAYMENT_TITLE]: "Pago recurrente (opcional)",
  [VALUE_TO_PAY_AMBASSADOR]: "Valor en efectivo a pagar al embajador",
  [VALUE_TO_PAY_AMBASSADOR_CLARIFICATION]:
    "Este valor se te debitará de tu método de pago en cada ciclo que describas a continuación y se le pagará al creador. Se te debitará {{valueWithFee,currency}} (+{{fee}}% fee).",
  [RECURRENT_FIELDS_DEFAULT_TITLE]:
    "¿Cada cuánto quieres invitar a este creador?",
  [RECURRENT_FIELDS_PAYMENT_TITLE]: "¿Cada cuánto le pagarás a este creador?",
  [RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER]: "Cada",
  [RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION]: "El valor mínimo es {{value}}",
  [RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION]:
    "La recurrencia máxima es de {{months}} meses o {{weeks}} semanas",
  [RECURRENT_FIELD_FREQUENCY_PLACEHOLDER]: "Escoge una opción",
  [RECURRENT_FIELD_DAY_AN_PLACEHOLDER]: "Un",
  [RECURRENT_FIELD_DAY_ALL_PLACEHOLDER]: "Todos los",
  [MODAL_BROADCAST_ARIAL_LABEL]: "Modal de Broadcast",
  [MODAL_BROADCAST_TITLE]: "Envía un Broadcast",
  [MODAL_BROADCAST_DESCRIPTION]: "Envíale un mensaje a todos tus embajadores",
  [MODAL_BROADCAST_SELECT_USER_PLACEHOLDER]: "Selecciona los usuarios",
  [MODAL_BROADCAST_SELECT_USER_REQUIRED]:
    "Debes seleccionar al menos un usuario",
  [MODAL_BROADCAST_MESSAGE_PLACEHOLDER]: "Mensaje",
  [BROADCAST_MESSAGE_SENT]: "Mensaje global enviado",
  [MODAL_BROADCAST_CONFIRM_TITLE]: "Enviar Broadcast",
  [MODAL_BROADCAST_CONFIRM_DESCRIPTION]:
    "¿Estás seguro que deseas enviar un mensaje a todos tus embajadores?",
  [MODAL_PAY_AMBASSADORS_ARIAL_LABEL]: "Modal de pago a embajadores",
  [MODAL_PAY_AMBASSADORS_TITLE]: "Envía un pago",
  [MODAL_PAY_AMBASSADORS_DESCRIPTION]: "Envíale un pago a tus embajadores",
  [MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER]: "Selecciona los usuarios",
  [MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED]:
    "Debes seleccionar al menos un usuario",
  [MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER]: "Cantidad a Pagar",
  [MODAL_PAY_AMBASSADOR_AMOUNT_MIN]: "El monto mínimo es de {{value,currency}}",
  [MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR]:
    "Cada embajador recibirá {{value,currency}}",
  [MODAL_PAY_AMBASSADOR_TITLE_2]: "Pagar a embajadores",
  [PAY_TO_AMBASSADORS]: "Pago a Embajadores",
  [INFLUENCER_IS_AMBASSADOR]: "Este creador es embajador de tu marca",
  [DO_AMBASSADOR]: "Agregar como embajador",
  [EDIT_AMBASSADOR]: "Editar embajador",
  [DONE_AMBASSADOR]: "El creador ha sido asignado como embajador",
  [MODAL_DO_AMBASSADOR_TITLE]: "Haz embajador a este creador",
  [MODAL_DO_AMBASSADOR_ARIA_LABEL]: "Haz embajador a este creador",
  [MODAL_DO_AMBASSADOR_DESCRIPTION]:
    "Si haces embajador a este creador, podrás invitarlo a colaborar con tu marca cada cierto tiempo y tener una comunicación más cercana.",
  [AMBASSADOR_DELETE]: "Eliminar embajador",
  [AMBASSADOR_DELETE_DESCRIPTION]:
    "¿Estás seguro que deseas eliminar este embajador?",

  //INVITATIONS
  [INVITATIONS_TITLE]: "Invitaciones",
  [INVITATIONS_DESCRIPTION]: "Gestiona todas las invitaciones de tu marca",
  [INVITATIONS_ACTIVE_TITLE]: "Activas",
  [INVITATIONS_ACTIVE_TABLE_ARIA_LABEL]: "Tabla de invitaciones activas",
  [INVITATIONS_TABLE_COLUMN_CODE]: "Código",
  [INVITATIONS_TABLE_COLUMN_VALUE]: "Valor",
  [INVITATIONS_TABLE_COLUMN_USE]: "Uso",
  [INVITATIONS_TABLE_COLUMN_EXPIRATION]: "Expiración",
  [INVITATION_REDEEMED]: "Redimida",
  [INVITATION_REJECTED]: "Rechazada",
  [INVITATION_EXPIRED]: "Expirada",
  [INVITATIONS_INACTIVE_TITLE]: "Inactivas",
  [INVITATIONS_INACTIVE_REDEEM_FILTER]: "Redimidas",
  [INVITATIONS_INACTIVE_EXPIRED_FILTER]: "Expiradas",
  [INVITATIONS_INACTIVE_TABLE_ARIA_LABEL]: "Tabla de invitaciones inactivas",
  [INVITATIONS_TABLE_COLUMN_TYPE_INVITATION]: "Tipo",
  [INVITATIONS_TABLE_COLUMN_REJECTED_REASON]: "Razón de rechazo",
  [INVITATIONS_TABLE_COLUMN_DATE]: "Fecha",
  [INVITATIONS_EVENT_TABLE]: " Invitaciones a eventos",
  [INVITATIONS_EVENT_TABLE_ARIA_LABEL]: "Tabla de Invitaciones a Eventos",
  [INVITATIONS_EVENT_TABLE_COLUMN_EVENT]: "Evento",

  //PUBLICATIONS
  [PUBLICATIONS_TITLE]: "Colaboraciones",
  [PUBLICATIONS_DESCRIPTION]:
    "Revisa las colaboraciones que han realizado los creadores de contenido sobre tus productos",
  [PUBLICATIONS_TABLE_ARIA_LABEL]: "Tabla de Colaboraciones",
  [PUBLICATIONS_TABLE_COLUMN_DATE]: "Fecha",
  [PUBLICATION_STATUS_PENDING_CONTENT]: "Contenido Pendiente",
  [PUBLICATION_STATUS_PENDING_APPROVAL]: "Calificar",
  [PUBLICATION_STATUS_PENDING]: "Publicación pendiente",
  [PUBLICATION_STATUS_PENDING_SOCIAL]: "Publicación pendiente",
  [PUBLICATIONS_STATUS_PENDING_ANALYTICS]: "Analítica pendiente",
  [PUBLICATIONS_STATUS_SENT]: "Calificar",
  [PUBLICATIONS_STATUS_FEEDBACK]: "Feedback",
  [PUBLICATIONS_STATUS_VERIFIED]: "Verificada",

  //PUBLICATION
  [PUBLICATION_TITLE]: "Publicación",
  [NUMBER_PUBLICATION]: "N° Publicación",
  [FEEDBACK_TITLE]: "Feedback",
  [PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION]:
    "Ahora puedes continuar con el tutorial de tiendas.",
  [PUBLICATION_FINISH_DESCRIPTION]:
    "Has completado el tutorial de la plataforma, ahora ya sabes como generar invitaciones.",
  [FEEDBACK_PLACEHOLDER]: "¿Cómo fue tu experiencia usando la plataforma?",
  [FEEDBACK_REASON_ABOUT]: "Review negativa sobre {{reason}}",
  [FEEDBACK_REASON]: "Motivo de la review negativa",
  [FEEDBACK_COMMENT]: "Comentario",
  [ANALYTICS_EXPLANATION_TITLE]: "¿Cómo interpretar las analíticas?",
  [ANALYTICS_EXPLANATION_DESCRIPTION]:
    " En las analíticas podrás determinar si el contenido que publicó el creador de contenido sobre tu marca generó uno resultados según tus criterios.",
  [ANALYTICS_EXPLANATION_DESCRIPTION_2]:
    "Las analíticas generalmente se dividen en diferentes secciones de información, pero las más importantes son las de resumen e interacciones. A continuación una explicación de los diferentes datos que tienen estas secciones.",
  [ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION]:
    "• <b>Cuentas alcanzadas</b>: son las diferentes cuentas de la red social a las que llegó la publicación en cuestión, se pueden dividir en seguidores y no seguidores, haciendo referencia a que sigan o no al creador de contenido.",
  [ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION]:
    "• <b>Cuentas que interactuaron</b>: son las cuentas que tuvieron algún tipo de interacción con la publicación, por ejemplo respuestas a las historias o veces que se compartió por parte de otros usuarios.",
  [ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION]:
    "• <b>Toques en etiquetas o stickers</b>: son el número de veces que se interactúo con la etiqueta o sticker de alguna publicación, por lo que los usuarios pudieron ver el perfil de tu marca.",
  [ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION]:
    "• <b>Navegación</b>: describe el comportamiento que tuvieron los usuarios con el contenido que el creador de contenido publicó, si abandonó la historia, si pasó a la siguiente sin esperar el tiempo de finalización, si se esperó todo el tiempo determinado de la historia o si retrocedió para ver la historia otra vez.",
  [RECURRENT_VALUE_NOT_FOUND]:
    "No se pudo obtener la recurrencia de la invitación por alguna razón, cambia los valores.",
  [MODAL_REVIEW_ARIA_LABEL]: "Modal para hacer review a un influencer",
  [MODAL_REVIEW_DISCLAIMER]:
    "Tu calificación no será compartida con el creador de contenido.",
  [MODAL_REVIEW_SCORE_PLACEHOLDER]:
    "Califica de 1 a 5 los resultados de este creador según tus expectativas.",
  [MODAL_REVIEW_SCORE_CONTENT_PLACEHOLDER]:
    "Califica la calidad y producción del contenido publicado.",
  [MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE]:
    "La calificación mínima del contenido es de 1 estrellas",
  [MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE]:
    "¿Cómo interpretar el contenido?",
  [MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION]:
    "Dale una calificación al creador de contenido basado en la calidad de la publicación - imagenes, texto o videos acerca de tu marca. Una estrella siendo que no estas satisfecho con el contenido y no lo vas a usar o descargar y cinco siendo que estas totalmente satisfech@ con el contenido y lo vas a usar y volver a publicar en tus canales o redes.",
  [MODAL_REVIEW_SCORE_ENGAGEMENT_PLACEHOLDER]:
    "Califica el alcance y el engagement del contenido publicado.",
  [MODAL_REVIEW_SCORE_ENGAGEMENT_MIN_VALUE]:
    "La calificación mínima de las estadísticas es de 1 estrellas",
  [MODAL_REVIEW_COMMENT_PLACEHOLDER]:
    "Describe qué te pareció el contenido y el alcance de este creador",
  [MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER]:
    "¿Deseas volver a colaborar con este creador?",
  [REVISION_COUNT]: "Revisión {{value}}",
  [UPLOAD_NEW_CONTENT]: "Sube nuevo contenido",
  [ADD_URL_PUBLICATION]: "Agrega URL",

  //ADS CAMPAIGNS
  [ADS_CAMPAIGNS_TITLE]: "{{NAME_BUSINESS}} Ads",
  [ADS_CAMPAIGNS_DESCRIPTION]:
    "Crea & gestiona campañas en redes sociales y analiza métricas.",

  //ADS CAMPAIGN
  [ADS_CAMPAIGN_TITLE]: "Información de la campaña",
  [ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE]: "Información de la campaña",
  [ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE]: "Información del presupuesto",
  [ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE]: "Información de segmentación",
  [ADS_CAMPAIGN_HAS_BEEN_FINISHED]: "Esta campaña ha finalizado",
  [ADS_CAMPAIGN_HAS_BEEN_ACTIVATE]: "Campaña activada",
  [ADS_CAMPAIGN_HAS_BEEN_SAVE]: "Campaña guardada",
  [ADS_CAMPAIGN_HAS_BEEN_DUPLICATE]: "Campaña duplicada",

  //CREATE ADS CAMPAIGN
  [CREATE_ADS_CAMPAIGN_NAME_QUESTION]: "¿Cuál será el nombre de tu campaña?",
  [CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER]: "Nueva colección de verano",
  [CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION]:
    "¿Cuál es el objetivo de tu campaña?",
  [CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION]:
    "El objetivo de tu campaña es importante para que tus anuncios se muestren de la mejor manera posible.",
  [CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION]:
    "¿A dónde quieres dirigir el tráfico de tu campaña?",
  [CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION]:
    "¿Con qué pixel deseas rastrear tu campaña?",
  [CREATE_ADS_CAMPAIGN_PLATFORM_QUESTION]:
    "¿En qué plataforma deseas crear los anuncions?",
  [CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION]:
    "¿Qué tipo de presupuesto deseas asignar a tu campaña?",
  [CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION]:
    "¿Cuál será el presupuesto diario de tu campaña?",
  [CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION]:
    "¿Cuál será el presupuesto total de tu campaña?",
  [CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER]: "10.000",
  [CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION]:
    "¿En qué ubicaciones deseas que se muestren tus anuncios?",
  [CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION]:
    "¿Cuál es el rango de edad de la audiencia que verá tus anuncios?",
  [CREATE_ADS_CAMPAIGN_GENDERS_QUESTION]:
    "¿A qué géneros deseas que se muestren tus anuncios?",
  [CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION]:
    "¿Qué intereses deseas que tenga la audiencia que verá tus anuncios?",
  [CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER]:
    "Ropa de playa, vacaciones, verano",
  [CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION]:
    "¿Qué comportamientos deseas que tenga la audiencia que verá tus anuncios?",
  [CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER]:
    "Compradores de ropa de playa, viajeros frecuentes",
  [CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION]:
    "¿Qué demografía deseas que tenga la audiencia que verá tus anuncios?",
  [CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER]:
    "Recién comprometidos, cumpleaños próximo, aniversario próximo",
  [CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION]:
    "¿Qué texto acompañará tu anuncio?",
  [CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER]:
    "La nueva colección de verano ya está aquí, eleva tu estilo en la playa con nuestras prendas. ¡Descúbrelas ahora!",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION]: "Háblanos sobre tu campaña",
  [CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION]: `Escribe un párrafo detallando cuál es el producto o servicio que deseas promocionar, cuál es el mensaje principal y a qué público objetivo deseas llegar.`,
  [CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER]:
    "Mi campaña es para promocionar mi nueva colección de verano que acabo de lanzar. Esta colección es perfecta para las vacaciones en la playa, con trajes de baño, pantalones cortos y accesorios de playa. Es ideal para aquellos hombres y mujeres que buscan un look fresco y moderno.",
  [CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION]:
    "¿Qué contenido quieres promocionar?",
  [CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION]:
    "Obteniendo información requerida",
  [CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION]:
    "Analizando la información",
  [CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS]:
    "Generando recomendaciones",
  [CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS]: "Finalizando detalles",
  [CREATE_ADS_CAMPAIGN_INTRO_TITLE]: "{{NAME_BUSINESS}} Ads",
  [CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION]:
    "Una solución poderosa para la creación, optimización y gestión de tus campañas en múltiples plataformas",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN]:
    "Crea campañas publicitarias en minutos",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI]:
    "Optimiza tus campañas con IA",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE]:
    "Gestiona tus campañas de forma sencilla",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE]:
    "Analiza el rendimiento de tus campañas",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE]:
    "¡Y mucho más! Todo en un solo lugar.",
  [CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION]:
    "¿Qué esperas para comenzar? ¡Crea tu campaña ahora!",
  [CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON]: "Crear campaña",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_PRODUCT_ADVERTISE]:
    "- ¿Cuál es el producto o servicio que deseas promocionar?",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_COMMUNICATE]:
    "- ¿Cuál es el mensaje principal que quieres comunicar?",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_SEGMENTATION]:
    "- ¿A qué público objetivo deseas llegar?",

  // CAMPAIGNS
  [CAMPAIGNS_TITLE]: "Campañas",
  [CAMPAIGNS_DESCRIPTION]:
    "Crea tu campaña, selecciona & paga a los aplicantes y aprueba el contenido",
  [CAMPAIGNS_SEARCHBAR_PLACEHOLDER]: "Buscar campañas",
  [CAMPAIGNS_TABLE_COLUMN_NAME]: "Nombre",
  [CAMPAIGNS_TABLE_COLUMN_PLATFORMS]: "Plataformas",
  [CAMPAIGNS_TABLE_COLUMN_STATUS]: "Status",
  [CAMPAIGN_ACTIVATE]: "Activar campaña",
  [CAMPAIGN_DEACTIVATE]: "Desactivar campaña",
  [CAMPAIGN_ACTIVATE_TIME_CONFIRMATION]:
    "¿Estás seguro que deseas activar esta campaña? Tu campaña estará activa durante un mínimo de 24 horas",
  [CAMPAIGN_DEACTIVATE_TIME]:
    "Debes esperar 24 horas para desactivar la campaña desde su última activación",
  [CAMPAIGN_FINISHED_TIME]:
    "Debes esperar 24 horas para finalizar la campaña desde su última activación",
  [CAMPAIGN_DEACTIVATE_APPLICANTS_ACCEPTED]:
    "No puedes desactivar la campaña hasta que todos los aplicantes aceptados hayan entregado su contenido",
  [CAMPAIGN_FINISH_APPLICANTS_ACCEPTED]:
    "No puedes finalizar la campaña hasta que todos los aplicantes aceptados hayan entregado su contenido",
  [CAMPAIGN_ACTIVATE_FIELDS_REQUIRED]:
    "Esta campaña tiene campos requeridos vacíos, para activarlos completalos",
  [CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR]:
    "El presupuesto por cada creador de contenido no debe ser menor a {{value,currency}}",
  [CAMPAIGN_ACTIVATE_TRAFFIC_LINK]:
    "El link de tráfico debe ser una url valida",
  [CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM]:
    "El usuario de Instagram es requerido si harás una colaboración",
  [CAMPAIGN_ACTIVATE_ATTACHMENT_LINK]:
    "El link de referencia de la campaña debe ingresar una url valida",
  [CAMPAIGN_FINISHED_CONFIRMATION]: "¿Estás seguro de finalizar esta campaña?",
  [CAMPAIGN_HAS_BEEN_FINISHED]: "Esta campaña ha sido finalizada",
  [CAMPAIGN_FINISH_ONBOARDING]:
    "Has terminado el tutorial, ahora sabes como generar colaboraciones más personalizadas con creadores de contenido.",

  // CAMPAIGN DETAIL
  [CAMPAIGN_DETAIL_TITLE]: "Campaña {{name}}",
  [CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE]: "Participantes",
  [CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE]: "Solicitudes",
  [CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE]: "Invitados",
  [CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER]:
    "¿Necesitas que el creador reciba un producto de tu marca?",
  [CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER]:
    "Valor del producto en tu catalogo",
  [CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION]:
    "Para instrucciones de contenido revisa la información de la campaña",
  [CAMPAIGN_DETAIL_CREATORS_INVITED_TITLE]: "Creadores invitados",
  [CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE]: "Información de la campaña",
  [CAMPAIGN_DETAIL_APPLICANT_PAYMENT_DESCRIPTION]:
    "Tu pago ha sido exitoso, ahora debes esperar que {{influencerName}} genere el contenido para tu aprobación",
  [CAMPAIGN_DETAIL_APPLICANT_PAYMENT_ADDITIONAL_DESCRIPTION]:
    "Adicionalmente puedes enviarle una invitación en canje de productos al creador de contenido para que pueda acceder a tus productos de forma gratuita",
  [CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION]:
    "¿Estás seguro de rechazar este aplicante?",
  [CAMPAIGN_DETAIL_INVITE_CREATOR_DESCRIPTION]:
    "La invitación a {{influencerName}} se enviará y podrás ver si acepta o no la invitación a tu campaña",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_DESCRIPTION]:
    "No hemos encontrado al creador en nuestra plataforma, si quieres que lo invitemos selecciona los siguientes campos y dale al botón Invitar",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_PLACEHOLDER]:
    "Presupuesto de la invitación",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_ACCOUNT_NAME_REQUIRED]:
    "Debes ingresar el usuario del creador de contenido en el buscador",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_SOCIAL_NETWORK_REQUIRED]:
    "Debes escoger una red social",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_REQUIRED]:
    "Debes asignar un presupuesto",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_MIN_VALUE]: `El presupuesto mínimo para invitar a un creador es de {{value,currency}}`,
  [CAMPAIGN_FIELDS_REQUIRED]:
    "Los campos que no son configuración avanzada son requeridos",
  [CAMPAIGN_NAME_PLACEHOLDER]: "Nombre de la campaña",
  [CAMPAIGN_DESCRIPTION_PLACEHOLDER]: "Descripción de la campaña",
  [CAMPAIGN_GOAL_PLACEHOLDER]: "Objetivo de la campaña",
  [CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER]:
    "Cantidad de creadores de contenido para tu campaña",
  [CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER]:
    "Audiencia mínima de los creadores de contenido",
  [CAMPAIGN_BUDGET_PLACEHOLDER]: "Presupuesto individual por cada contenido",
  [CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER]:
    "Con contenido publicado en redes sociales",
  [CAMPAIGN_PLATFORMS_PLACEHOLDER]: "Plataformas para tu campaña",
  [CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER]:
    "Tipo de contenido para tu campaña",
  [CAMPAIGN_TYPE_CONTENT_PLACEHOLDER]: "Formatos para tu campaña",
  [CAMPAIGN_COPY_POST_PLACEHOLDER]:
    "Copy que acompañará el contenido (Opcional)",
  [CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER]:
    "Link hacia donde quieres llevar el tráfico",
  [CAMPAIGN_HAS_COLLABORATION_PLACEHOLDER]:
    "Quiero que el contenido se publique con un colaboración de Instagram",
  [CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER]:
    "Principales atributos del producto a comunicar",
  [CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER]:
    "Mensaje a comunicar con el contenido",
  [CAMPAIGN_DATES_START_DATE_PLACEHOLDER]: "Fecha de inicio de la campaña",
  [CAMPAIGN_DATES_END_DATE_PLACEHOLDER]: "Fecha de finalización de la campaña",
  [CAMPAIGN_REPOST_PLACEHOLDER]:
    "¿Deseas que el creador de contenido etiquete tu perfil?",
  [CAMPAIGN_REPOST_PLACEHOLDER_ITEM]: "Usuario de {{platform}}",
  [CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER]:
    "Ciudades de interés para impactar con tu campaña",
  [CAMPAIGN_AGE_RANGE_PLACEHOLDER]:
    "Rango de edades que deben tener los creadores de contenido para tu campaña",
  [CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER]:
    "Temáticas a considerar para la búsqueda del perfil",
  [CAMPAIGN_BRIEF_PLACEHOLDER]: "Brief de la Marca",
  [CAMPAIGN_BRIEF_DESCRIPTION]:
    "Es un pequeño documento creativo con toda la información detallada y guía de acción para la campaña, debe estar en formato PDF.",
  [CAMPAIGN_ATTACHMENT_FILE_PLACEHOLDER]:
    "Referencia de Contenido de la Campaña",
  [CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION]:
    "Una imagen o vídeo que sirva como referencia para los creadores de contenido de cómo será la campaña",
  [CAMPAIGN_ATTACHMENT_LINK_TITLE]: "Referencia en Link de la Campaña",
  [CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION]:
    "Un enlace a otra página con contenido que sirva como referencia para los creadores de contenido de cómo será la campaña",
  [CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER]: "Link de referencia",
  [CAMPAIGN_GENDERS_PLACEHOLDER]: "Generos para la campaña",
  [CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER]:
    "Perfil(es) que pueden servir de referencia",
  [CAMPAIGN_HASHTAG_PLACEHOLDER]: "#Hashtag de tu campaña",
  [CAMPAIGN_MESSAGE_TONE_PLACEHOLDER]: "Tono del mensaje de tu campaña",
  [CAMPAIGN_ENVIRONMENT_PLACEHOLDER]: "Entorno donde se hará el contenido",
  [CAMPAIGN_COLORS_CONTENT_PLACEHOLDER]:
    "Colores que deben salir en el contenido",
  [CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER]:
    "Colores que NO deben salir en el contenido",
  [CAMPAIGN_KEY_WORDS_PLACEHOLDER]: "Palabras clave de tu campaña",
  [CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER]:
    "Que NO se debe decir durante la campaña",
  [CAMPAIGN_OUTFIT_PLACEHOLDER]: "Vestimenta recomendada",

  //CREATE CAMPAIGN
  [CREATE_CAMPAIGN_QUESTION]: "Pregunta {{question}}",
  [CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER]: `Al crear una campaña, estás aceptando los Términos y Condiciones de {{NAME_BUSINESS}}`,
  [CREATE_CAMPAIGN_NAME_EXAMPLE]: "Campaña de ejemplo",
  [CREATE_CAMPAIGN_NAME_REQUIRED]: "El nombre es requerido",
  [CREATE_CAMPAIGN_DESCRIPTION_REQUIRED]: "La descripción es requerida",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED]:
    "La cantidad de creadores de contenido es requerida",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE]:
    "La cantidad de creadores de contenido no debe ser menor a {{value}",
  [CREATE_CAMPAIGN_BUDGET_REQUIRED]: "El presupuesto es requerido",
  [CREATE_CAMPAIGN_BUDGET_MIN_VALUE]:
    "El presupuesto por cada creador de contenido no debe ser menor a {{value,currency}}",
  [CREATE_CAMPAIGN_PLATFORMS_REQUIRED]:
    "Debes seleccionar al menos una plataforma",
  [CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA]:
    "Debes seleccionar al menos un tipo de contenido",
  [CREATE_CAMPAIGN_TYPE_CONTENT]: "Debes seleccionar al menos un formato",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED]:
    "El link hacia donde se quiere llevar el trafico es requerido",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_VALID]:
    "El link hacia donde se quiere llevar el trafico debe ser una url valida",
  [CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED]:
    "Los atributos de los productos son requeridos",
  [CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED]:
    "El mensaje a comunicar con el contenido es requerido",
  [CREATE_CAMPAIGN_DATES_REQUIRED]: "Ambas fechas son requeridas",
  [CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION]:
    "Como harás una colaboración en Instagram, es requerido el username de Instagram",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED]: "Las ciudades son requeridas",
  [CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS]:
    "Las categorías para la búsqueda del perfil son requeridas",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK]: "La url debe ser valida",
  [CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE]: "Campaña activada",
  [CREATE_CAMPAIGN_HAS_BEEN_SAVE]: "Campaña guardada",
  [CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED]:
    "Para guardar el progreso debes al menos ingresar el nombre de la campaña",
  [CREATE_CAMPAIGN_SAVE_PROGRESS]: "Tu progreso ha sido guardado",
  [CREATE_CAMPAIGN_NAME_QUESTION]: "¿Cuál será el nombre de tu campaña?",
  [CREATE_CAMPAIGN_NAME_PLACEHOLDER]: "Nueva colección X de zapatos",
  [CREATE_CAMPAIGN_DESCRIPTION_QUESTION]:
    "¡Crea una descripción llamativa y clara de tu campaña para que los creadores se animen a participar!",
  [CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER]:
    "La colección de zapatos X que revolucionará el mercado del calzado",
  [CREATE_CAMPAIGN_GOAL_QUESTION]: "¿Cuál es el objetivo de tu campaña?",
  [CREATE_CAMPAIGN_GOAL_PLACEHOLDER]: "Selecciona una opción",
  [CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION]: "Reconocimiento de marca",
  [CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION]: "Interacciones",
  [CREATE_CAMPAIGN_GOAL_LEADS_OPTION]: "Leads",
  [CREATE_CAMPAIGN_GOAL_SALES_OPTION]: "Conversión de venta",
  [CREATE_CAMPAIGN_GOAL_REQUIRED]: "Debes seleccionar un objetivo",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION]:
    "¿Cuántos creadores de contenido necesitas para tu campaña?",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER]: "10",
  [CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION]:
    "¿Qué audiencia mínima deben tener los creadores de contenido?",
  [CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION]:
    "El valor mínimo que pagarás a cada creador con esta audiencia será de {{value,currency}}",
  [CREATE_CAMPAIGN_BUDGET_QUESTION]:
    "¿Cuánto estás dispuesto a pagar a cada creador de contenido?",
  [CREATE_CAMPAIGN_BUDGET_DESCRIPTION]:
    "En función a la cantidad de creadores que necesitas ({{quantity}}), te sugerimos un presupuesto de {{budget,currency}}",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION]: "Tipo de campaña",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_DESCRIPTION]:
    "La mención en redes significa que los creadores publicarán el contenido mencionando tu cuenta, si seleccionas que no, simplemente se te entregará el contenido.",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER]:
    "Selecciona una opción",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION]:
    "UGC - Obtén solo el contenido para que tú lo publiques",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION]:
    "Influencer - Obtén el contenido más exposición en redes sociales",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED]:
    "Debes seleccionar una opción",
  [CREATE_CAMPAIGN_PLATFORMS]:
    "¿Cuáles serán las plataformas que quieres para tu campaña?",
  [CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION]:
    "¿Cuál será el tipo de contenido para tu campaña?",
  [CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION]:
    "¿Cuál será el formato para tu campaña?",
  [CREATE_CAMPAIGN_COPYWRITING_QUESTION]:
    "¿Cuál sería el texto “copy” que acompañaría el contenido? (Opcional)",
  [CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER]:
    "¡Se ha lanzado la nueva colección de Zapatos X, atrévete a probarlos!",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION]:
    "¿Cuál es el link hacia donde quieres llevar el tráfico?",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER]: "https://www.tumarca.com/",
  [CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION]:
    "¿Quieres que el contenido se publique con una colaboración de Instagram?",
  [CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION]:
    "¿Cuales son los principales atributos del producto o servicio a comunicar con el contenido?",
  [CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER]:
    "Novedoso, revolucionario, original, singular, etc.",
  [CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION]:
    "¿Cuál es el mensaje a comunicar con el contenido?",
  [CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER]:
    "Se debe comunicar que no hay algún producto igual en el mercado, porque es una nueva tendencia",
  [CREATE_CAMPAIGN_DATES_QUESTION]:
    "¿Cuál será la fecha de inicio y de finalización de tu campaña?",
  [CREATE_CAMPAIGN_DATES_DESCRIPTION]:
    "La duración de la campaña debe ser de mínimo una semana, por eso hay un espacio obligatorio entre la fecha de inicio y la de finalización, además de que se debe crear con una semana de antelación.",
  [CREATE_CAMPAIGN_DATES_START_DATE_PLACEHOLDER]:
    "Fecha de inicio de la campaña",
  [CREATE_CAMPAIGN_DATES_END_DATE_PLACEHOLDER]:
    "Fecha de finalización de la campaña",
  [CREATE_CAMPAIGN_REPOST_QUESTION]:
    "Si quieres que el creador de contenido etiquete tu perfil digita el @. De lo contrario, haz click en siguiente",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION]:
    "¿Cuáles son las ciudades de interes donde quieres impactar con tu campaña?",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER]: "Selecciona las ciudades",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION]: "Todas",
  [CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION]:
    "¿Cuáles son las categorías de los creadores de contenido que necesitas para tu campaña?",
  [CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER]:
    "Selecciona las categorías",
  [CREATE_CAMPAIGN_AGE_RANGE_QUESTION]:
    "¿Cuál es el rango de edades que deben tener los creadores de contenido para tu campaña?",
  [CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION]:
    "¿Qué estilo de contenido quieres para tu campaña?",
  [CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER]: "Selecciona un estilo",
  [CREATE_CAMPAIGN_BRIEF_QUESTION]: "Sube el brief de la marca",
  [CREATE_CAMPAIGN_BRIEF_DESCRIPTION]:
    "Es un pequeño documento creativo con toda la información detallada y guía de acción para la campaña, debe estar en formato PDF.",
  [CREATE_CAMPAIGN_UPLOAD_BRIEF]: "Subir brief",
  [CREATE_CAMPAIGN_REMOVED_BRIEF]: "Eliminar brief",
  [CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION]:
    "Sube una referencia de contenido de la campaña",
  [CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION]:
    "Una imagen o vídeo que sirva como referencia para los creadores de contenido de cómo será la campaña.",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION]:
    "Sube una referencia en link de contenido de la campaña",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION]:
    "Un enlace a otra página con contenido que sirva como referencia para los creadores de contenido de cómo será la campaña.",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER]: "https://www.tumarca.com/",
  [CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION]:
    "¿Qué generos necesitas para tu campaña?",
  [CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION]:
    "¿Tienes @ perfil(es) que nos puedan servir de referencia?",
  [CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER]:
    "@xavi, @laura, @daniel, @maria, etc.",
  [CREATE_CAMPAIGN_HASHTAG_QUESTION]: "¿Cuál será el hashtag de tu campaña?",
  [CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER]: "#ZapatosXTrend",
  [CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION]:
    "¿Cómo debe ser el tono del mensaje de tu campaña?",
  [CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER]:
    "Serio, jocoso, tranquilo, libre, etc.",
  [CREATE_CAMPAIGN_ENVIRONMENT_QUESTION]:
    "¿Cómo debe ser el entorno donde se hará el contenido?",
  [CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER]:
    "Hogareño, natural, comercial, libre, etc",
  [CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION]:
    "¿Qué colores deben salir en el contenido?",
  [CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER]: "Azul and white",
  [CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION]:
    "¿Qué colores NO pueden salir en el contenido?",
  [CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER]: "Rojo y negro",
  [CREATE_CAMPAIGN_KEY_WORDS_QUESTION]:
    "¿Cuáles son las palabras claves de tu campaña?",
  [CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER]:
    "Revolucionario, tendencia, novedoso, etc.",
  [CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION]:
    "¿Qué NO se debe decir durante la campaña?",
  [CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER]:
    "Palabras, detalles, referencia a otras marcas, etc",
  [CREATE_CAMPAIGN_OUTFIT_QUESTION]: "¿Hay alguna vestimenta recomendada?",
  [CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER]: "Qué se debe o no mostrar en el físico",
  [CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE]:
    "¡Listo! Aquí está tu guión del contenido",
  [CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING]:
    "Estamos generando un guión de contenido para tu campaña...",
  [CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL]:
    "Guión de campaña",

  //APPLICANTS
  [APPLICANTS_STATUS_PENDING]: "Pendiente",
  [APPLICANTS_STATUS_ACCEPTED]: "Contenido Pendiente",
  [APPLICANTS_STATUS_REJECTED]: "Rechazado",
  [APPLICANTS_STATUS_CONTENT_SUBMITTED]: "Contenido por Revisar",
  [APPLICANTS_STATUS_CONTENT_REVISIONS]: "Comentario Enviado",
  [APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL]:
    "Pendiente de Publicación a Redes",
  [APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS]: "Pendiente de Analítica",
  [APPLICANTS_STATUS_DELIVERED]: "Contenido Listo",
  [APPLICANT_CONTENT_OF_CREATOR]: "Contenido de {{influencerName}}",
  [APPLICANT_REVISION_PLACEHOLDER]:
    "Manda una revisión al creador de contenido",

  //REPORTS SHOP
  [PROFILE_INFLUENCER_INCOMPLETE]:
    "No tienes completo o actualizado tu perfil de creador de contenido",
  [REPORT_SHOP_DESCRIPTION]:
    "Aquí encontrarás el reporte mensual de tus colaboraciones",
  [START_DATE_AND_END_DATE_VALIDATION]:
    "La fecha de inicio no puede ser mayor a la fecha de finalización",
  [START_DATE_PLACEHOLDER]: "Fecha de inicio",
  [END_DATE_PLACEHOLDER]: "Fecha de finalización",
  [GLOBAL_REPORT_BUSINESS]: "Reporte Global de {{businessName}}",
  [INSUFFICIENT_DATA_REPORT]:
    "No hay suficiente información para generar el reporte, intentacon otro rango de fechas",
  [TOTAL_INVESTMENT]: "Inversión total",
  [TOTAL_EXPENSES]: "Gastos totales",
  [COSTS_PER_1000_VIEWS]: "Costo por 1.000 vistas",
  [COSTS_PER_ENGAGEMENT]: "Costo por engagement",
  [ACCOUNTS_REACHED]: "Personas alcanzadas",
  [LIKES]: "Likes",
  [SHARES]: "Compartidos",
  [SAVES]: "Guardados",
  [REPLIES]: "Respuestas",
  [STICKERS_TAPS]: "Stickers taps",
  [COLLABORATIONS_SUMMARY]: "Resumen de colaboraciones",
  [PENDING_PUBLICATIONS]: "Publicaciones pendientes: {{count}}",
  [PENDING_ANALYTICS_PUBLICATIONS]: "Analitícas pendientes: {{count}}",
  [FEEDBACK_PUBLICATIONS]: "Feedback {{count}}",
  [INVITATIONS_SUMMARY]: "Resumen de Invitaciones",
  [TOTAL_INVITATIONS]: "Total invitaciones: {{count}}",
  [INVITATIONS_REDEEMED]: "Redimidas: {{count}}",
  [INVITATIONS_REJECTED]: "Rechazadas: {{count}}",
  [INVITATIONS_EXPIRED]: "Expiradas: {{count}}",
  [INVITATIONS_ACCEPTANCE_RATE]: `Tasa de aceptación: {{percentage}}% (1 de cada {{ratio}} invitaciones)`,
  [COLLABORATIONS_FOR_PLATFORM]: "Colaboraciones por plataforma",
  [FEATURED_CONTENT]: "Contenido destacado",
  [EMPTY_IMAGES_FOR_COMMENT]: "No hay imágenes para este comentario",
  [UPDATING_ANALYTICS]: "Actualizando analíticas",

  //DISCOVERY INFLUENCERS
  [DISCOVERY_INFLUENCERS_TITLE]: "Explora Creadores de Contenido",
  [DISCOVERY_INFLUENCERS_DESCRIPTION]: `Busca a un creador/creadores de contenido fuera de {{NAME_BUSINESS}} usando diferentes parámetros`,
  [DISCOVERY_INFLUENCERS_DONE_ONBOARDING]:
    "Has completado el tutorial de explorar creadores, ahora sabes como buscar a creadores según tus requerimientos.",
  [DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS]:
    "No se encontró a creadores/as con estos parametros",
  [DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED]:
    "Ocurrió un error, el servicio de estadísticas está actualizándose, inténtalo de nuevo más tarde",
  [DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION]:
    "La invitación a {{accountName}} se enviará y te avisaremos si acepta.",
  [DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER]: "Nombre de usuario",
  [DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER]: "Plataforma",
  [DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER]: "Ubicación de los creador/a",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER]: "Mínimo de seguidores",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE]:
    "El mínimo de seguidores es {{value,number}}",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED]:
    "Este valor no puede estar vacío si el máximo de seguidores tiene un valor",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED]:
    "El máximo de seguidores no puede ser 0",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS]:
    "El mínimo de seguidores no puede ser mayor al máximo",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER]: "Máximo de seguidores",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE]:
    "El mínimo de seguidores es {{value,number}}",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED]:
    "Este valor no puede estar vacío si el mínimo de seguidores tiene un valor",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED]:
    "El máximo de seguidores no puede ser 0",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS]:
    "El máximo de seguidores no puede ser menor al mínimo",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER]: "Engagement",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE]:
    "El mínimo de engagement es {{value,number}}",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE]:
    "El mínimo de engagement es {{value,number}}",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE]:
    "Solo se pueden poner números, con máximo 2 decimales",
  [DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER]: "Género del creador/a",
  [DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER]: "Edad del creador/a",
  [DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER]: "Género de la audiencia",
  [DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER]: "Edad de la audiencia",
  [DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER]:
    "Categorías de la audiencia",
  [DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER]: "Keywords",
  [DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL]: "Tabla de Creadores Encontrados",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME]: "Nombre",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION]: "Ubicación",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS]: "Seguidores",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT]: "Engagement",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES]: "Promedio de Likes",
  [DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED]: "Ya fue invitado/a",
  [DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND]: "No se cargaron métricas",
  [DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS]: "No hay más creadores",

  //GALLERY
  [MODAL_GALLERY_ARIA_LABEL]: "Modal de galería de contenido de la marca",
  [MODAL_GALLERY_EMPTY_CONTENT]:
    "Aún no se ha creado contenido para tu marca, invita a creadores de contenido",
  [MODAL_GALLERY_NO_MORE_CONTENT]: "No hay más contenido para mostrar",

  //MODAL_MEDIA
  [MODAL_MEDIA_ARIA_LABEL]: "Modal de media",

  //MODAL UPLOAD TIKTOK
  [MODAL_UPLOAD_TIKTOK_ARIA_LABEL]: "Subir video a TikTok",
  [MODAL_UPLOAD_TIKTOK_TITLE]: "Subir video a TikTok",
  [MODAL_UPLOAD_FAILED_GET_CONFIGURATION]:
    "Ocurrió un error al obtener la configuración, intenta más tarde o contacta a soporte.",
  [MODAL_UPLOAD_CONTENT_UPLOADED]: "¡Contenido subido!",
  [MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION]:
    "El video se ha subido correctamente a TikTok, aparecerá en tu perfil en unos instantes",
  [MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION]:
    "No se pudo recuperar la información de la colaboración, contacta con soporte",
  [MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL]: "Descripción",
  [MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER]:
    "Recuerda añadir una descripción llamativa de tu vídeo, etiquetar a los usuarios que salen en él y añadir hashtags relevantes.",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL]: "¿Quién puede ver este video?",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION]: "Público",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION]: "Seguidores",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION]: "Solo yo",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER]: "Permitir a los usuarios",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION]: "Comentar",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION]: "Dúos",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION]: "Recortar",

  //MODAL UPLOAD INSTAGRAM
  [MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL]: "Subir contenido a Instagram",
  [MODAL_UPLOAD_INSTAGRAM_TITLE]: "Subir contenido a Instagram",
  [MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION]:
    "El contenido se ha subido correctamente a Instagram, aparecerá en tu perfil en unos instantes",
  [MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL]: "Descripción",
  [MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER]:
    "Recuerda añadir una descripción llamativa de tu contenido, etiquetar a los usuarios que salen en él y añadir hashtags relevantes.",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL]: "Tipo",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL]: "Publicación",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL]: "Historia",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL]: "Reel",

  //MODAL MAIN GENERATE SOCIAL MEDIA CONTENT
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL]:
    "Modal para generar el contenido de la semana",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE]: `Generador de feeds`,
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION]:
    "Genera tu contenido semanal para redes sociales con un clic",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_LABEL]:
    "¿Qué quieres comunicar con este contenido?",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_PLACEHOLDER]:
    "Deseo comunicar que mis productos/marca es todo lo que el usuario necesita",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WEBSITE_URL_LABEL]: "URL de tu sitio web",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_HAS_TEXTS_LABEL]:
    "¿Quieres que los contenidos tengan texto?",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT]: "Todos con texto",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT]: "Algunos con texto",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT]: "Ninguno con texto",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_LANGUAGE_LABEL]: "Idioma",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TONES_LABEL]: "Tonos",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_TEXT_LABEL]: "Color de texto",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_BACKGROUND_LABEL]:
    "Color de fondo",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_HAS_TEXT_LABEL]: "Con texto",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_FONT_FAMILY_LABEL]: "Fuente",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_TEXT_LABEL]:
    "Alineación del texto",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_LOGO_LABEL]:
    "Alineación del logo",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_TITLE]: "Galería de contenido",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_EMPTY]:
    "Aún no hay contenido, agregálo",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION]:
    "Subir desde el dispositivo",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION]: `Subir desde {{NAME_BUSINESS}}`,
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_CONTENTS_REQUIRED]:
    "Debes seleccionar al menos un día",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULE_BUTTON]:
    "Programar contenido",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULED_CONTENT]:
    "Contenido programado",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALREADY_CONTENT_ON_THIS_DATE]:
    "Ya hay un contenido para esta fecha, por favor selecciona otra",

  //MODAL GENERATE SOCIAL MEDIA CONTENT
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL]:
    "Modal para generar contenido",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE]: "Genera contenido",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_LABEL]: "Elige el tono",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_TOP_FIVE_OPTION]:
    "Top cinco",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION]:
    "Educacional",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION]:
    "Frases famosas",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION]:
    "Datos curiosos",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION]:
    "Rimas o poemas",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION]: "Humor",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TEXT_LABEL]: "Texto de la imagen",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION]:
    "Descripción del contenido",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_EMPTY_CONTENT]:
    "No hay contenido generado",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_GENERATING_CONTENT]:
    "Generando contenido",

  //REDEEM CODE
  [REDEEM_CODE_TITLE]: "Redimir Código",
  [REDEEM_CODE_DESCRIPTION]:
    "Ingresa el código que te suministra el creador de contenido para validar el valor y realizar el canje de producto",
  [REDEEM_CODE_SEARCHBAR_PLACEHOLDER]: "Escribe un código",
  [REDEEM_CODE_TABLE_COLUMN_CODE]: "Código",
  [REDEEM_CODE_TABLE_COLUMN_DATE]: "Fecha de redención",
  [REDEEM_CODE_TABLE_COLUMN_VALUE]: "Valor",
  [REDEEM_CODE_TABLE_COLUMN_TERMS]: "Términos y condiciones",
  [CODE_REDEEMED]: "Código redimido",
  [VALUE_CODE_REDEEMED]: "Valor: {{value,currency}}",
  [VALUE_GENERAL_CODE_REDEEMED]: "Valor: {{value}}%",
  [CODE_NO_EXIST]: "Este código no existe",
  [CODE_NO_REDEEM_BEFORE_DATE]:
    "Aún no se puede reclamar este código, lo puede hacer a partir del {{date,short}}",

  //ORDERS
  [ORDERS_TITLE]: "Órdenes",
  [ORDERS_DESCRIPTION]:
    "Gestiona las órdenes que realizan los creadores de contenido",
  [ORDERS_REMEMBER_MARK_AS_DELIVERED]:
    "Recuerda marcar tu pedido como 'Entregado' para que el creador pueda subir tu contenido.",
  [ORDERS_SEARCHBAR_PLACEHOLDER]: "Buscar por n° de orden",
  [ORDERS_TAB_TO_BE_ACCEPTED]: "Por aceptar",
  [ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION]:
    "Aquí verás nuevas órdenes de los creadores de contenido invitados. Acéptalas para confirmar que las procesarás.",
  [ORDERS_TAB_IN_PREPARATION]: "Procesando",
  [ORDERS_TAB_IN_PREPARATION_DESCRIPTION]:
    "Aquí verás las órdenes a procesar por tu lado. Al expirar el tiempo, un delivery estará atento para recogerla.",
  [ORDERS_TAB_TO_BE_DELIVERED]: "Por entregar",
  [ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION]:
    "Aquí verás las órdenes listas de tu lado para que un delivery las pueda recoger.",
  [DELIVERY_INTERN]: "Propio",
  [DELIVERY_INTERN_ON_THE_WAY]: "Delivery interno en camino",
  [DELIVERY_ON_THE_WAY]: "Delivery {{delivery}} en camino",
  [ORDER_ID]: "Pedido #{{id}}",
  [ORDER_ACCEPTED]: "Orden aceptada",
  [ORDER_ACCEPTED_DESCRIPTION]:
    "La orden ha sido aceptada y se ha movido a procesando",
  [ORDER_READY]: "Orden lista",
  [ORDER_READY_DESCRIPTION]:
    "La orden está lista y se ha movido a por entregar",
  [ORDER_TO_BE_DELIVERED_CONFIRMATION]:
    "¿Estas seguro que el pedido ya fue entregado?",
  [ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON]: "Entregar pedido",
  [ORDER_CANCELLED_CONFIRMATION]:
    "¿Estas seguro que deseas cancelar el pedido?",
  [ORDER_CANCELLED_CONFIRMATION_BUTTON]: "Cancelar pedido",
  [ORDER_DELIVERED]: "Orden entregada",
  [ORDER_DELIVERED_DESCRIPTION]: "La orden ya fue entregada al usuario",
  [DELIVERY_SELECTED]: "¡Delivery seleccionado!",
  [DELIVERY_SELECTED_DESCRIPTION]:
    "Se ha seleccionado el delivery {{delivery}} para esta orden",
  [ORDER_REJECTED]: "Orden rechazada",
  [ORDER_REJECTED_DESCRIPTION]: "La orden ha sido rechazada",
  [ORDER_TOTAL_VALUE]: "Valor total",
  [ORDER_TOTAL_VALUE_DISCOUNT]: "Con {{value}}% descuento",
  [ORDER_LIST_OF_PRODUCTS]: "Lista de productos",
  [ORDER_OPTIONS]: "Opciones",
  [ORDER_TIME_PREPARATION_TITLE]: "Indica el tiempo de entrega de la orden",
  [MIN_VALUE_PREPARATION_ORDER]:
    "Este es el tiempo mínimo en procesar esta orden",
  [MAX_VALUE_PREPARATION_ORDER]:
    "Este es el tiempo máximo en procesar esta orden",
  [INVALID_TIME_PREPARATION]: "Debes escoger un tipo de tiempo valido",
  [TIME_PREPARATION_VALUES_VALIDATION]:
    "Debes escoger un tiempo mayor a {{min}} o menor a {{max}}",
  [GUIDE_NUMBER]: "Número de guía",
  [GUIDE_NUMBER_REQUIRED]: "El número de guía es requerido",
  [DELIVERY_TYPE_DUPLICATE]: "Ya existe un delivery con este nombre",
  [SELECT_DELIVERY_TYPE]: "Selecciona un Delivery",
  [SELECT_DELIVERY_TYPE_OTHER]: "Otro delivery",
  [DELIVERY_DATA]: "Datos del delivery",
  [OWN_DELIVERY]: "Mensajero propio",

  //EVENTS
  [EVENT_NO_EXIST]: "El evento no existe",
  [EVENT_DISABLED]: "El evento {{event}} no está activo",
  [EVENT_INVITATION_NO_BELONG]: "Este código no pertenece al evento {{event}}",
  [EVENT_HAS_FIELDS_REQUIRED]:
    "Esta evento tiene campos requeridos vacíos, para activarlos completalos",
  [EVENT_RECURRENT_REQUIRED]: "El tipo de recursión es requerido",

  [TOP_CONTENT_TITLE]: "Contenido Destacado",
  [TOP_CONTENT_DESCRIPTION]:
    "Descubre cuáles son los contenidos con mejores resultados.",
  [TOP_CONTENT_NOT_FOUND_IN_INTERVAL]:
    "No se encontraron colaboraciones en este intervalo de tiempo",

  //ACCOUNT
  [ACCOUNT_TITLE]: "Mi Cuenta",
  [ACCOUNT_DESCRIPTION]: "Diligencia la información de tu empresa",

  //COMPANY INFORMATION
  [COMPANY_INFORMATION_TITLE]: "Configura tu marca",
  [COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE]:
    "Ya existe una subcategoría con este nombre",
  [COMPANY_INFORMATION_TYPE_ID_PLACEHOLDER]: "Tipo de Identificación",
  [COMPANY_INFORMATION_ID_NUMBER_PLACEHOLDER]: "Número de identificación",
  [COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH]:
    "El número de identificación debe tener al menos {{value}} dígitos",
  [COMPANY_INFORMATION_ID_NUMBER_TYPE]:
    "El número de identificación solo debe tener dígitos, en caso de ser NIT debe contener un guión y el dígito de verificación",
  [COMPANY_INFORMATION_BRAND_NAME_PLACEHOLDER]: "Razón social",
  [COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER]: "Nombre de la marca",
  [COMPANY_INFORMATION_ADDRESS_PLACEHOLDER]: "Dirección registrada",
  [COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER]: "Valor del ticket promedio",
  [COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE]:
    "El valor del ticket promedio debe ser de al menos {{value,currency}}",
  [COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER]: "Red social principal",
  [COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER]:
    "Red social secundaria",
  [COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER]: "Página web de tu marca",
  [COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER]:
    "URL para redirigir el tráfico",
  [COMPANY_INFORMATION_CATEGORY_PLACEHOLDER]: "Categorías",
  [COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER]: "Sub categorías",
  [COMPANY_INFORMATION_NEW_SUBCATEGORY]: "Nueva SubCategoría",
  [COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER]:
    "Escribe una descripción llamativa de tu marca para que creadores se animen a colaborar contigo",
  [COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER]: "Celular del administrador",
  [COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER]: "URL para reservas",
  [COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER]: "Subir logo de la marca",
  [COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER]:
    "Esto ayudará a que los clientes o mensajeros identifiquen tu marca.",
  [COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER]: "Subir imagen de portada",
  [COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER]:
    "Esto aumentará la visibilidad. Usa imágenes horizontales o con una relación de aspecto 16:9 para una mejor visualización.",
  [COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER]:
    "Subir documento regulatorio",
  [COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER]:
    "Documento regulatorio para venta de alimentos (por ejemplo Invima, FDA)",
  [COMPANY_INFORMATION_VIEW_DOCUMENT]: "Ver documento",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_TITLE]: "Código de descuento general",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_DESCRIPTION]: `El código de descuento general es un código que los creadores de {{NAME_BUSINESS}} pueden usar para comprar tus productos como cualquier otro cliente, por lo que no se generará una colaboración.`,
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_1]:
    "Si guardas un porcentaje de descuento y tienes una integración e-commerce se creará una código de descuento con ese porcentaje.",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_2]:
    "Si quitas el porcentaje de descuento, tienes una integración e-commerce y tenías un código general previamente creador, se desactivará.",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_PERCENTAGE_PLACEHOLDER]:
    "Porcentaje de descuento",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_CODE_PLACEHOLDER]:
    "Código de descuento",
  [COMPANY_INFORMATION_SHOP_ID_COPIED]: "Shop ID copiado",
  [COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION]:
    "Ahora deberás llenar toda la información de tu marca en esta sección de cuenta.",
  [COMPANY_INFORMATION_SAVED]: "Información de la empresa guardada",

  //PROFILE INFLUENCER INFORMATION
  [PROFILE_INFLUENCER_INFORMATION_SAVED]:
    "Información del perfil del creador de contenido guardada",
  [PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED]:
    "Ahora puedes revisar tus creadores sugeridos",
  [VIEW_SUGGESTED_CREATORS]: "Ver creadores sugeridos",
  [PROFILE_INFLUENCER_INFORMATION_TITLE]: "Perfil de creador de contenido",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION]:
    "Hablemos de tus creadores de contenido de ideales",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER]:
    "Categorías de creador de contenido",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE]:
    "Se deben seleccionar al menos {{value}} categorías",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER]:
    "Ciudades donde están los creadores",
  [PROFILE_INFLUENCER_INFORMATION_AUDIENCE_CITIES_PLACEHOLDER]:
    "Ciudades donde quieres ser visto",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_MAX_VALUE]:
    "Solo se pueden seleccionar {{value}} ciudades",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_PLACEHOLDER]:
    "Audiencia mínima",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_MIN_VALUE]:
    "La audiencia debe ser de al menos un {{value,number}}",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_ENGAGEMENT]:
    "Audiencia que podría interactuar según el engagement {{value,number}}",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_PLACEHOLDER]:
    "Engagement mínimo",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_MIN_VALUE]:
    "`El engagement debe ser de al menos un {{value,number}}%",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER]:
    "Género del creador",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE]:
    "Rango de edad del creador",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER]:
    "Género de la audiencia",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER]:
    "Rango de edad de la audiencia",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_MAX_VALUE]:
    "Solo se pueden seleccionar {{value}} opciones",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER]: "Plataformas",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_PLACEHOLDER]:
    "Valor del ticket promedio",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_MIN_VALUE]:
    "El valor del ticket promedio debe ser de al menos {{value,currency}}",
  [PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION]:
    "Perfiles de referencia",
  [PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION1]:
    " - Para agregar perfil de referencia, debes ver sus métricas, seleccionando “Invitar” y “Ver Métricas”. Luego das click en el botón de “Agregar perfil de referencia”.",
  [PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2]:
    "- Solo puedes tener hasta un máximo de tres perfiles de referencia.",

  //AUTOMATIC INVITES
  [AUTOMATIC_INVITES_TITLE]: "Configuracion de canjes",
  [AUTOMATIC_INVITES_ACTIVATE]: "Auto-invites activadas",
  [AUTOMATIC_INVITES_DEACTIVATE]: "Auto-invites desactivadas",
  [AUTOMATIC_INVITES_DESCRIPTION]:
    "Déjanos hacer el trabajo, nosotros enviaremos tus invitaciones automáticamente",
  [AUTOMATIC_INVITES_BUDGET_DESCRIPTION]: "Presupuesto",
  [AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER]:
    "Número de contenidos al mes",
  [AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE]:
    "El número de contenidos es de mínimo {{min}} y máximo {{max}}",
  [AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION]:
    "Los algoritmos de redes sociales valoran más la cantidad de contenidos que la calidad. Le sugerimos un mínimo de 10 contenidos, pero recomendamos 25 para mejores resultados.",
  [AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER]:
    "Costo total de productos por mes",
  [AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE]:
    "El costo total de productos no puede ser menor a {{value,currency}}",
  [AUTOMATIC_INVITES_BUDGET_BUDGETS_DESCRIPTION]:
    "Agrega los presupuestos de invitación para cada audiencia que te interesa",
  [AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION1]:
    "- Si quieres poner un presupuesto general para tus invitaciones, seleccionar el rango completo (de 1K a 500K) y escribe el presupuesto.",
  [AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION2]:
    "- Si en un rango pones 500K (El máximo) se incluirán a todos los creadores con más de 500K de audiencia.",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE]: "Audiencia N° {{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE]:
    "Rango de audiencia N° {{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_ENGAGEMENT_RANGE]:
    "Rango de engagement N° {{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE]:
    "Presupuesto para audiencia N° {{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE]:
    "El presupuesto mínimo es de {{value,currency}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT]:
    "Tu producto de menor valor es de {{value,currency}} y no puedes ofrecer un presupuesto menor a este valor.",
  [AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION]: "Personalización de contenido",
  [AUTOMATIC_INVITES_OPTIONAL_COMMENT_PLACEHOLDER]:
    "Comentario para los creadores de contenido",
  [AUTOMATIC_INVITES_OPTIONAL_TERMS_PLACEHOLDER]:
    "Términos y condiciones de tus canjes",
  [AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER]:
    "Selecciona el formato requerido para el contenido",
  [AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER]:
    "Url de contenido de referencia",
  [AUTOMATIC_INVITES_TOOLTIP_TITLE]: "Configuración de canjes",
  [AUTOMATIC_INVITES_TOOLTIP_DESCRIPTION]:
    "En esta sección podrás configurar los parámetros clave relacionados con los canjes que se enviarán a los creadores de contenido. Todas las configuraciones que hagas se establecerán por defecto en las invitaciones que vayas a enviar o en las que se envían automáticamente <strong>solo si activas esta opción.</strong>",
  [AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_TITLE]: "Número de canjes al mes",
  [AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_DESCRIPTION]:
    "<strong>En caso de que actives las invitaciones automáticas</strong>, este será el número máximo de canjes que se enviarán a los creadores de contenido cada mes.",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGETS_TITLE]: "Presupuestos individuales",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGETS_DESCRIPTION]:
    "En los presupuestos individuales podrás asignar un presupuesto individual en canje a los creadores que se encuentren en el rango de audiencia que selecciones, puedes agregar tantos presupuestos como necesites.",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_TITLE]:
    "Valor total de productos en canje por mes",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_DESCRIPTION]:
    "<strong>En caso de que actives las invitaciones automáticas</strong>, este será el valor total de productos en canje que se enviarán. Este valor se distribuirá entre los presupuesto individuales que hayas configurado.",
  [AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_TITLE]:
    "Invitaciones automáticas",
  [AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_DESCRIPTION]: `En caso de activar esta opción, {{NAME_BUSINESS}} se encargará de enviar las invitaciones automáticamente a los creadores de que contenido que cumplan con los requisitos que hayas configurado en la sección <strong>Perfil de creador de contenido</strong>, respetando los límites de canjes y presupuesto máximo mensual configurado en la sección <strong>Configuración de canjes</strong>. <br/> <br/>
  Esta opción te permitirá ahorrar tiempo y esfuerzo en la busqueda de creadores de contenido, ya que nuestro sistema los filtrará según tus intereses y enviará las invitaciones automáticamente.
  `,
  [AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_TITLE]:
    "Personaliza tus canjes",
  [AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_DESCRIPTION]:
    "Con estos parametros podrás personalizar los canjes que enviarás manualmente a los creadores de contenido, sin embargo podrás modificarlos antes de enviarlos. <strong>En caso de que actives las invitaciones automáticas</strong>, estos serán los parámetros que se establecerán por defecto. ",
  [AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION]:
    "¿Cuántos contenidos quieres al mes?",
  [AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER]: "12",
  [AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION]:
    "¿Cuál es el costo total de productos que deseas ofrecer a cambio de contenido cada mes?",
  [AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER]: "$500.000",
  [AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION]:
    "¿Cuál es el costo del producto que quieres dar a cada creador de contenido?",
  [AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION]:
    "¿Cuál es el guión que deben seguir los creadores para realizar el contenido?",
  [AUTOMATIC_INVITES_TYPE_FORM_TYPE_CONTENT_QUESTION]:
    "¿Cuál es el tipo de contenido que deseas?",
  [AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION]:
    "¿Cuáles son los formatos de contenido que deseas?",
  [AUTOMATIC_INVITES_TYPE_FORM_TERMS_QUESTION]:
    "¿Qué términos y condiciones tienen tus canjes?",
  [AUTOMATIC_INVITES_TYPE_FORM_TERMS_PLACEHOLDER]:
    "Solo quiero vídeos creativos",
  [AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION]:
    "Deja un enlace a un ejemplo de contenido que sirva como guía para los creadores",
  [AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION]:
    "¿Deseas activar las auto-invites?",
  [AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED]:
    "El número de contenidos es requerido",
  [AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED]:
    "El costo total de productos por mes es requerido",
  [AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED]:
    "Todos los presupuestos deben tener un valor",
  [AUTOMATIC_INVITES_COMMENT_REQUIRED]: "El guión es requerido",
  [AUTOMATIC_INVITES_TERMS_REQUIRED]:
    "Los términos y condiciones son requeridos",
  [AUTOMATIC_INVITES_TYPE_CONTENT_REQUIRED]:
    "El tipo de contenido es requerido",
  [AUTOMATIC_INVITES_FORMATS_REQUIRED]:
    "Los formatos de contenido son requeridos",
  [AUTOMATIC_INVITES_CONTENT_REFERENCE_REQUIRED]:
    "El contenido de referencia es requerido",

  //PLATFORMS CONNECT
  [PLATFORMS_CONNECTED_TITLE]: "Conecta tus cuentas",
  [PLATFORMS_CONNECTED_INSTAGRAM_FAILED]: "No se pudo conectar con Instagram",
  [PLATFORMS_TOKEN_EXPIRED]:
    "Tu token ha expirado, por favor reconecta tu cuenta",

  //SCHEDULE
  [SCHEDULE_TITLE]: "Horarios",
  [SCHEDULE_ECOMMERCE_ALERT_TITLE]: "¿Está seguro?",
  [SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION]:
    "Esto hará que el horario de tu marca sea 24/7",
  [SCHEDULE_ONE_DAY_WITH_TURN]: "Al menos un día debe tener un turno",
  [SCHEDULE_EMPTY_TURN]: "No puede haber turnos vacíos",
  [SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION]:
    "La hora de apertura no puede ser mayor a la de cierre",
  [SCHEDULE_MAX_CLOSE_HOUR]:
    "La hora no puede ser mayor a 23:59, utilice 00:00 en lugar de 24:00",
  [SCHEDULE_TABLE_ARIA_LABEL]: "Tabla de Horarios",
  [SCHEDULE_TABLE_COLUMN_OPEN]: "Abre",
  [SCHEDULE_TABLE_COLUMN_CLOSE]: "Cierra",
  [SCHEDULE_SAVED]: "Horario guardado",

  //LEGAL REPRESENTATIVE
  [LEGAL_REPRESENTATIVE_TITLE]: "Información del representante legal",
  [LEGAL_REPRESENTATIVE_NAME_PLACEHOLDER]: "Nombres",
  [LEGAL_REPRESENTATIVE_LAST_NAME_PLACEHOLDER]: "Apellidos",
  [LEGAL_REPRESENTATIVE_TYPE_ID_PLACEHOLDER]: "Tipo de Identificación",
  [LEGAL_REPRESENTATIVE_ID_NUMBER_PLACEHOLDER]: "Número de Identificación",
  [LEGAL_REPRESENTATIVE_PHONE_PLACEHOLDER]: "Celular",
  [LEGAL_REPRESENTATIVE_SAVED]: "Información del representante legal guardada",

  //PASSWORD
  [PASSWORD_TITLE]: "Cambiar contraseña",
  [PASSWORD_CURRENT_PASSWORD_PLACEHOLDER]: "Contraseña actual",
  [PASSWORD_NEW_PASSWORD_PLACEHOLDER]: "Contraseña nueva",
  [PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER]: "Repite la contraseña nueva",
  [PASSWORD_FORMAT_DESCRIPTION]: "Formato de la contraseña:",
  [PASSWORD_FORMAT_SUGGESTION1]: "Al menos 6 caracteres",
  [PASSWORD_FORMAT_SUGGESTION2]: "Al menos una letra mayúscula",
  [PASSWORD_FORMAT_SUGGESTION3]: "Al menos una letra minúscula",
  [PASSWORD_FORMAT_SUGGESTION4]: "Al menos un número",
  [PASSWORD_INSECURE]:
    "La contraseña no es segura, ten en cuenta las recomendaciones",
  [PASSWORDS_NOT_MATCH]: "Las contraseñas nuevas no coinciden",
  [PASSWORD_CHANGED]: "Contraseña cambiada",
  [PASSWORD_CURRENT_INVALID]: "La contraseña actual no es correcta",
  [PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS]:
    "La cuenta se ha deshabilitado temporalmente por muchos intentos fallidos, inténtelo de nuevo más tarde.",

  //STORES
  [STORES_TITLE]: "Mis tiendas",
  [STORES_DESCRIPTION]: "Crea una o múltiples tiendas para tu marca",
  [STORES_STORE_SHOPIFY_DUPLICATE]:
    "Ya tienes una tienda integrada con Shopify",
  [STORES_STORE_WOOCOMMERCE_DUPLICATE]:
    "Ya tienes una tienda integrada con Wordpress Woocommerce",
  [STORES_STORE_WOOCOMMERCE_CREATED]:
    "La tienda y la integración con Woocommerce se crearon correctamente",
  [STORES_STORE_NAME_DUPLICATE]: "Una tienda con este nombre ya existe",
  [STORES_STORE_SPECIAL_STORE_EXISTS]: "Ya tienes una tienda especial creada",
  [STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION]:
    "Has completado el tutorial de tiendas, ahora ya sabes como crear tus tiendas y configurarlas.",
  [STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION]:
    "Ahora puedes continuar con el tutorial de cátalogo.",
  [STORES_STORE_CREATED]: "Tienda creada",
  [STORES_STORE_CREATED_DESCRIPTION]:
    "La tienda se ha creado correctamente ¿quieres crear su catálogo?",
  [STORES_STORE_COORDS_NOT_FOUND]:
    "No se encontró esta dirección en google maps, usa una más específica",
  [STORES_STORE_SAVED]: "Tienda guardada",
  [STORES_STORE_NO_DELETED_LAST_STORE]:
    "No puedes borrar todas las tiendas, debes dejar al menos una.",
  [STORES_STORE_DELETE_CONFIRMATION_TITLE]:
    "¿Estás seguro de querer eliminar esta tienda?",
  [STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION]:
    "Esta acción es irreversible y no podrás recuperar esta información.",
  [STORES_STORE_DELETED]: "Tienda eliminada",
  [STORES_TABLE_ARIAL_LABEL]: "Tabla de tiendas",
  [STORES_TABLE_COLUMN_TYPE]: "Tipo",
  [STORES_TABLE_COLUMN_NAME]: "Nombre",
  [STORES_TABLE_COLUMN_CITY]: "Ciudad",
  [STORES_TABLE_COLUMN_ADDRESS]: "Dirección",
  [STORES_TABLE_COLUMN_EMAIL]: "Correo",
  [STORES_TABLE_COLUMN_PHONE]: "Celular",
  [STORES_TABLE_COLUMN_STATUS]: "Estado",
  [MODAL_STORE_ARIA_LABEL]: "Modal de tienda",
  [MODAL_STORE_TITLE]: "Crea una tienda",
  [MODAL_STORE_TITLE_EDIT]: "Edita una tienda",
  [MODAL_STORE_DESCRIPTION]:
    "Crea una tienda que es un punto de venta físico o virtual de marca.",
  [MODAL_STORE_DESCRIPTION_EDIT]: "Edita una tienda que hayas creado.",
  [MODAL_STORE_TYPE_PLACEHOLDER]: "Tipo de tienda",
  [MODAL_STORE_TYPE_REGULAR_OPTION]: "Regular",
  [MODAL_STORE_HOW_TO_INTEGRATE_STORE]: `¿Como integrar mi tienda de {{value}} con {{NAME_BUSINESS}}?`,
  [MODAL_STORE_NAME_PLACEHOLDER]: "Nombre de la tienda",
  [MODAL_STORE_DESCRIPTION_PLACEHOLDER]: "Descripción",
  [MODAL_STORE_ADDRESS_PLACEHOLDER]: "Dirección",
  [MODAL_STORE_CITY_PLACEHOLDER]: "Ciudad",
  [MODAL_STORE_PHONE_PLACEHOLDER]: "Celular",
  [MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER]: "Link de tu tienda {{value}}",
  [MODAL_STORE_NAME_SHOPIFY_PLACEHOLDER]: "Nombre de la tienda en Shopify",
  [MODAL_STORE_LOOK_NAME_SHOPIFY]: "Mira el nombre de tu tienda",
  [MODAL_STORE_LOOK_NAME_SHOPIFY_HELPER_TEXT]:
    "El nombre de tu tienda es el que aparecerá en la URL a la que se te redirige, deberás copiar el fragmento que está antes de <strong>.myshopify.com</strong>. Por ejemplo, si te redirige a la URL https://<strong>testclapps</strong>.myshopify.com el nombre de la tienda es <strong>testclapps</strong>.",
  [MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER]: `AccessToken para {{NAME_BUSINESS}}`,
  [MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER]: "Dar envío gratis",
  [MODAL_STORE_CITIES_PLACEHOLDER]: "Selecciona las ciudades",
  [MODAL_STORE_ORDERS_METHODS_PLACEHOLDER]:
    "Selecciona métodos para la entrega de tus productos",
  [MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION]: "Delivery",
  [MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION]: "En tienda",
  [MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER]:
    "Disponibilidad de tus productos",
  [MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION]:
    "Nacional (Todas las ciudades)",
  [MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION]:
    "Local (Solo en la ciudad seleccionada)",
  [MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER]: "Radio de cobertura en kilometros",
  [MODAL_STORE_TITLE_CREATE_INTEGRATE_SHOPIFY]: "Integrar Shopify",
  [MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_SHOPIFY]:
    "Conecta tu tienda de Shopify con {{NAME_BUSINESS}} para facilitar la gestión de tus invitaciones y canjes.",
  [MODAL_STORE_BUTTON_CREATE_INTEGRATE_SHOPIFY]: "Integrar Shopify",
  [MODAL_STORE_DESCRIPTION_DISCONNECT_SHOPIFY]:
    "Se deshabilitarán todas las funcionalidades de gestión de invitaciones y canjes  de Shopify",
  [MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE]: "Integrar Woocommerce",
  [MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE]:
    "Conecta tu tienda de Woocommerce con {{NAME_BUSINESS}} para facilitar la gestión de tus invitaciones y canjes.",
  [MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE]: "Integrar Woocommerce",
  [MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE]:
    "Se deshabilitarán todas las funcionalidades de gestión de invitaciones y canjes  de Woocommerce",

  //MENU
  [MENU_TITLE]: "Catálogo",
  [MENU_DESCRIPTION]: "Crea el catálogo de los productos de tu marca",
  [MENU_EMPTY_STORES]: "No tienes una tienda creada para agregar un catálogo",
  [MODAL_COPY_MENU_ARIA_LABEL]: "Modal para copiar catálogo",
  [MODAL_COPY_MENU_TITLE]: "Copiar catálogo",
  [MODAL_COPY_MENU_DESCRIPTION]: "Copia el catálogo de una tienda a otra",
  [MODAL_COPY_MENU_SAME_STORE]:
    "No puede copiar un catálogo para la misma tienda",
  [MODAL_COPY_MENU_CONFIRM_ALERT_TITLE]:
    "¿Estás seguro de copiar este catálogo?",
  [MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION]:
    "Esta acción sobreescribirá el catálogo que esté en la tienda destino.",
  [MODAL_COPY_MENU_COPIED]: "Catálogo copiado",
  [SELECT_CATALOG]: "Selecciona un catálogo",
  [MODAL_UPLOAD_MENU_ARIA_LABEL]: "Modal para subir catálogo",
  [MODAL_UPLOAD_MENU_TITLE]: "Subir catálogo",
  [MODAL_UPLOAD_MENU_DESCRIPTION]:
    "Selecciona la tienda a la que quieres subir el catálogo",
  [MODAL_UPLOAD_MENU_FILE_PLACEHOLDER]:
    "Para subir un cátalogo deberás hacerlo desde un formato excel con la extensión xlsx, respetando la plantilla de las columnas.",
  [MODAL_UPLOAD_MENU_ERROR_FORMAT]:
    "Se ha encontrado un error en el formato, por favor no modifique las estructura de la plantilla o no deje campos requeridos vacíos",
  [MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT]:
    "El precio de un producto debe de ser {{value,number}} en adelante",
  [MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE]:
    "¿Estás seguro de subir este catálogo?",
  [MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION]:
    "Esta acción borrará el catálogo existente y lo reemplazará.",
  [MODAL_UPLOAD_MENU_UPLOADED]: "Catálogo subido",
  [MENU_ADD_CATEGORY]: "Agregar categoría",
  [MENU_CATEGORY_NAME_PLACEHOLDER]: "Nombre de categoría",
  [MENU_CATEGORY_REQUIRED]: "Debes ingresar un nombre de categoría",
  [MENU_ADD_PRODUCT]: "Agregar producto",
  [MENU_PRODUCT_MIN_VALUE]:
    "El precio debe de ser {{value,number}} en adelante",
  [MENU_CREATE_PRODUCT_TITLE]: "Crea el producto base",
  [MENU_PRODUCT_NAME_PLACEHOLDER]: "Nombre",
  [MENU_PRODUCT_DESCRIPTION_PLACEHOLDER]: "Descripción",
  [MENU_PRODUCT_PRICE_PLACEHOLDER]: "Precio",
  [MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER]: "Tipo de producto",
  [MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION]:
    "Este producto no es apto para el público en general (es nocivo o para mayores de 18 años)",
  [MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE]: "Opciones de producto",
  [MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION]:
    "Agrega opciones y customización de este producto",
  [MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER]:
    "Agrega categoría de opción de producto",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER]:
    "¿Es obligatorio?",
  [MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER]: "Agrega opción de producto",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER]:
    "El minimo de opciones no puede ser mayor al maximo",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER]:
    "El minimo o máximo de opciones no puede ser menor a 0",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER]: "Nombre",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER]: "Opciones mínimas",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER]: "Opciones máximas",
  [MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER]: "Nombre",
  [MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER]: "Precio",
  [MODAL_MOVE_PRODUCT_ARIA_LABEL]:
    "Modal para mover un producto a otra categoría",
  [MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE]:
    "Selecciona la categoría a la que quieres mover el producto",
  [MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED]: "Debes seleccionar una categoría",
  [MODAL_MOVE_PRODUCT_BUTTON]: "Mover Producto",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL]:
    "Modal para copiar una categoría de opción de producto a otro producto",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE]:
    "Selecciona la categoría de producto que contiene la categoría de opción de producto",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE]:
    " Selecciona el producto que contiene la categoría de opción de producto",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE]:
    "Selecciona la categoría de opción de producto que quieres copiar",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER]:
    "Categoría de opción de producto",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED]:
    "Debes seleccionar una categoría",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED]:
    "Debes seleccionar un producto",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED]:
    "Debes seleccionar una categoría de opción de producto",
  [MENU_ONBOARDING_TEST_CATEGORY]: "Categoría de prueba",
  [MENU_ONBOARDING_TEST_PRODUCT]: "Producto de prueba",
  [MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION]: "Descripción de prueba",
  [MENU_ONBOARDING_FINISH_DESCRIPTION]:
    "Has completado el tutorial de creación de catálogo, ahora ya sabes como gestionar tu cátalogo.",
  [MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION]:
    "Ahora deberás llenar toda la información de tu marca en esta sección de cuenta.",
  [MENU_CATEGORY_DUPLICATE]: "Categoría duplicada",
  [MENU_CATEGORY_DUPLICATE_DESCRIPTION]:
    "Una categoría con este nombre ya existe",
  [MENU_CATEGORY_CREATED]: "Categoría creada",
  [MENU_CATEGORY_SAVED]: "Categoría guardada",
  [MENU_CATEGORY_DELETE_CONFIRMATION_TITLE]: "Eliminar categoría",
  [MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de eliminar esta categoría?",
  [MENU_CATEGORY_DELETING]: "Eliminando categoría",
  [MENU_CATEGORY_DELETED]: "Categoría eliminada",
  [MENU_PRODUCT_DUPLICATE]: "Producto duplicado",
  [MENU_PRODUCT_DUPLICATE_DESCRIPTION]: "Un producto con este nombre ya existe",
  [MENU_PRODUCT_CREATED]: "Producto creado",
  [MENU_PRODUCT_SAVED]: "Producto guardado",
  [MENU_PRODUCT_DELETE_CONFIRMATION_TITLE]: "Eliminar producto",
  [MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION]:
    "¿Está seguro de eliminar este producto?",
  [MENU_PRODUCT_DELETING]: "Eliminando producto",
  [MENU_PRODUCT_DELETED]: "Producto eliminado",
  [MENU_PRODUCT_MOVE_SAME_CATEGORY]:
    "No se puede mover el producto a la misma categoría",
  [MENU_PRODUCT_MOVED]: "Producto movido",
  [MENU_PRODUCT_CHANGE_STATUS]: "Producto {{status}}",
  [MENU_ACCOMPANIMENT_TYPE_DUPLICATE]:
    "Categoría de opción de producto duplicada",
  [MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION]:
    "Una categoría de opción de producto con este nombre ya existe",
  [MENU_ACCOMPANIMENT_TYPE_COPIED]: "Categoría de opción de producto copiado",
  [MENU_ACCOMPANIMENT_DUPLICATED]: "Opción de producto duplicado",
  [MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION]:
    "Una opción de producto con este nombre ya existe",

  //USERS
  [USERS_TITLE]: "Usuarios",
  [USERS_DESCRIPTION]: "Crea los usuarios que te ayudaran a gestionar tu marca",
  [USERS_TABS_ARIA_LABEL]: "tabs de usuarios y contactos",
  [USERS_PORTAL_TAB]: "Usuarios",
  [USERS_CONTACTS_TAB]: "Contactos",
  [USERS_EMAIL_DUPLICATE]:
    "El correo electrónico ya está en uso por otro usuario",
  [USERS_USER_CREATED]: "Usuario creado",
  [USERS_USER_SAVED]: "Usuario guardado",
  [USERS_USER_DELETE_CONFIRMATION_TITLE]:
    "¿Está seguro de eliminar este usuario?",
  [USERS_USER_DELETE_CONFIRMATION_DESCRIPTION]:
    "Una vez eliminado, no se podrá recuperar",
  [USERS_USER_DELETING]: "Borrando usuario",
  [USERS_USER_DELETED]: "Usuario eliminado",
  [USERS_ONBOARDING_FINISH]:
    "Ahora ya sabes cuáles son los roles que existen y para que sirve cada uno",
  [USERS_USER_SEARCH_BAR_PLACEHOLDER]: "Buscar usuario",
  [USERS_TABLE_ARIA_LABEL]: "Tabla de Usuarios",
  [USERS_TABLE_COLUMN_NAME]: "Nombre",
  [USERS_TABLE_COLUMN_EMAIL]: "Email",
  [USERS_TABLE_COLUMN_STORE]: "Tienda",
  [USERS_TABLE_COLUMN_ROLE]: "Rol",
  [MODAL_USER_ARIA_LABEL]: "Modal de crear usuario",
  [MODAL_USER_TITLE]: "Crear usuario",
  [MODAL_USER_TITLE_EDIT]: "Editar usuario",
  [MODAL_USER_DESCRIPTION]:
    "Crea un usuario de tu empresa para que también pueda acceder a {{NAME_BUSINESS}}.",
  [MODAL_USER_DESCRIPTION_EDIT]:
    "Edita un usuario de tu empresa que tiene acceso a {{NAME_BUSINESS}}.",
  [MODAL_USER_NAME_PLACEHOLDER]: "Nombres y apellidos",
  [MODAL_USER_ROLE_PLACEHOLDER]: "Rol del Usuario",
  [MODAL_USER_ROLE_OPERATOR_EXPLANATION]:
    "Personas que gestionan las acciones > Redimir Código y Órdenes, y se aseguran de activar o desactivar los productos según el inventario.",
  [MODAL_USER_STORE_PLACEHOLDER]: "Escoge una tienda",
  [MODAL_USER_PASSWORD_PLACEHOLDER]: "Contraseña",
  [MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER]: "Repetir contraseña",
  [USERS_CONTACT_CREATED]: "Contacto creado",
  [USERS_CONTACT_SAVED]: "Contacto guardado",
  [USERS_CONTACT_DELETE_CONFIRMATION_TITLE]:
    "¿Está seguro de eliminar este contacto?",
  [USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION]:
    "Una vez eliminado, no se podrá recuperar",
  [USERS_CONTACT_DELETED]: "Contacto Eliminado",
  [USERS_CONTACT_SEARCH_BAR_PLACEHOLDER]: "Buscar contacto",
  [USERS_CONTACT_TABLE_ARIA_LABEL]: "Tabla de Contacto",
  [USERS_CONTACT_TABLE_COLUMN_PHONE]: "Celular",
  [MODAL_CONTACT_ARIA_LABEL]: "Modal de crear contacto",
  [MODAL_CONTACT_TITLE]: "Crea un contacto",
  [MODAL_CONTACT_TITLE_EDIT]: "Edita un contacto",
  [MODAL_CONTACT_DESCRIPTION]:
    "Crea un contacto para que {{NAME_BUSINESS}} se comunique contigo.",
  [MODAL_CONTACT_DESCRIPTION_EDIT]: "Edita un contacto que hayas creado.",
  [MODAL_CONTACT_PHONE_PLACEHOLDER]: "Celular de contacto",

  //BILLING AND PAYMENTS
  [BILLING_AND_PAYMENTS_TITLE]: "Panel de Facturación",
  [BILLING_AND_PAYMENTS_DESCRIPTION]:
    "Conoce tu plan comercial y gestiona los pagos de las facturas",
  [BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL]: "Tabla de códigos redimidos",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_PAID_DATE]: "Fecha de pago",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_DUE_DATE]: "Fecha de vencimiento",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_VALUE]: "Valor",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_REASON]: "Motivo",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_STATUS]: "Estado",
  [PAYMENT_REASON_COLLABORATION]: "Pago por colaboración",
  [PAYMENT_REASON_APPLICANT]: "Pago por aplicante",
  [PAYMENT_REASON_AMBASSADOR]: "Pago a embajador",
  [PAYMENT_REASON_INFLUENCER]: "Pago a creador",
  [PAYMENT_REASON_INVITATION]: "Pago de invitación",
  [PAYMENT_REASON_CLAPP_CREDITS]: "Pago por créditos",
  [PAYMENT_REASON_SUBSCRIPTION_PRO]: `Suscripción {{NAME_BUSINESS}} Pro`,
  [PAYMENT_REASON_PACKAGE]: "Plan {{name}}",
  [PAYMENT_REASON_PLAN]: "Plan {{name}}",
  [PAYMENT_PAID]: "Pagada",
  [PAYMENT_PENDING]: "Pendiente",
  [MODAL_INVOICE_ARIA_LABEL]: "Modal de detalle de factura",
  [MODAL_INVOICE_TITLE]: "Factura No. {{value}}",
  [MODAL_INVOICE_DISCLAIMER]:
    "Si crees que hay un error en la facturación, puedes comunicarte con soporte para recibir un atención detallada.",
  [MODAL_INVOICE_CREDITS]: "Pago por créditos",
  [MODAL_INVOICE_NUMBER_CREDITS]: "Número de créditos",
  [MODAL_INVOICE_PLAN_ENTERPRISE_WITH_CREDITS]:
    "{{credits}} colaboraciones con medios y publicidad",
  [MODAL_INVOICE_SUBSCRIPTION]:
    "Pago de suscripción {{name}}, periodo del {{startDate, short}} al {{endDate, short}}",
  [MODAL_INVOICE_LICENSE]:
    "Pago de licencia {{name}}, periodo del {{startDate, short}} al {{endDate, short}}",
  [MODAL_INVOICE_COLLABORATION]: "Pago por canje de contenido",
  [MODAL_INVOICE_APPLICANT]: "Pago a aplicante de campaña",
  [MODAL_INVOICE_AMBASSADOR]: "Pago a embajador",
  [MODAL_INVOICE_INFLUENCER]: "Pago a creador de contenido",
  [MODAL_INVOICE_INVITATION]: "Pago de invitación en canje",
  [MODAL_INVOICE_FEE]: "Fee {{value}}%",
  [MODAL_INVOICE_IVA]: "IVA {{value}}%",
  [MODAL_INVOICE_ORDER_TO_COLLECT]: "Orden a Cobrar",
  [MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID]: "Campaña",
  [MODAL_PAY_INVOICE_ARIA_LABEL]: "Modal de pago de factura",
  [MODAL_PAY_INVOICE_TITLE]: "Pagar Factura  No. {{value}} ",
  [MODAL_PAY_INVOICE_WILL_PAY]: "Pagarás {{value,currency}}",
  [BILLING_AND_PAYMENT_PLAN_DEACTIVATE]: "Tu plan está inactivo",
  [BILLING_AND_PAYMENT_PLAN_CANCELLED]: "Tu plan ha sido cancelado",
  [BILLING_AND_PAYMENT_PLAN_TRIAL]: "Prueba",
  [BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION]: `Tienes activo un plan de prueba para que conozcas los beneficios de usar {{NAME_BUSINESS}}`,
  [BILLING_AND_PAYMENT_CHANGE_YOUR_PLAN]: "Cambia tu plan ahora",
  [BILLING_AND_PAYMENT_ACTIVATE_PLAN]: "Activar plan",
  [BILLING_AND_PAYMENT_SELECT_PLAN]: "Selecciona un plan",
  [BILLING_AND_PAYMENT_NO_HAVE_PLAN]: "-",
  [BILLING_AND_PAYMENT_PLAN_EXPIRED]: `Tu plan de prueba ha expirado, agrega un método de pago y activa tu plan ahora para seguir utilizando {{NAME_BUSINESS}}.`,
  [BILLING_AND_PAYMENT_PLAN_NAME]: "Plan {{name}}",
  [BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE]: "Pago por uso",
  [BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST]:
    "{{value,currency}} por canje",
  [BILLING_AND_PAYMENT_MODEL_USAGE_WHAT_BENEFITS]:
    "¿Qué beneficios obtienes con este plan?",
  [BILLING_AND_PAYMENT_MODEL_USAGE_SHOW_BENEFTIS]: "Mostrar beneficios",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT1]:
    "Accede a nuestro catálogo de creadores de contenido.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT2]:
    "Exhibe tus productos y/o servicios a audiencias entre 5 mil y 2 millones de personas en Intagram, TikTok y YouTube.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT3]:
    "Analiza los perfiles, verifica la calidad y autenticidad de sus contenidos, obtén información sobre su audiencia.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT4]:
    "Automatiza canje de productos y/o servicios (invitaciones y solicitudes).",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT5]: "Automatiza campañas pagas.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT6]:
    "Descarga el contenido en la plataforma y úsalo en tus redes sociales, página web y otros canales de marketing.",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_NAME]: "Plan {{name}}",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_CHANGE]: "Cambia tu plan ahora",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_COLLECT_COST_COLLABORATION]:
    "Aún no has seleccionado un plan, por ahora se te cobrará por {{value,currency}} por cada colaboración que se realice.",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_ADD_PAYMENT_METHOD_TO_ACTIVATE]:
    "Agrega un método de pago para activar tu plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_EXPIRED]: `Tu plan de prueba ha expirado, selecciona un plan para seguir utilizando {{NAME_BUSINESS}}.`,
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE]: "-",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE]: "Activar plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE]: "Seleccionar plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE]: "Cancelar plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_WHAT_BENEFITS]:
    "¿Qué beneficios obtienes con este plan?",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION]:
    "{{value,currency}} por colaboración",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_DISCLAIMER]:
    " * Las suscripciones se renuevan automáticamente cada mes, si deseas cancelar o cambiar tu plan debes contactar a soporte con 5 días de anticipación.",
  [MODAL_SELECT_PACKAGE_ARIA_LABEL]: "Modal de selección de plan",
  [MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED]:
    "El plan y método de pago son necesarios",
  [MODAL_SELECT_PACKAGE_UPLOAD_GROWTH]: "¡Sube a Grotwh!",
  [MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE]: "Pago exitoso",
  [MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION]: "Tu plan ha sido activado",
  [MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE]:
    "¿Estás listo para creador contenido de valor que realmente te genera ventas?",
  [MODAL_SELECT_PACKAGE_PRICING_DISCOVER_BUZZLY]: `Descubre {{NAME_BUSINESS}}`,
  [MODAL_SELECT_PACKAGE_UPLOAD_GROWTH_DESCRIPTION]:
    "Con el plan growth podrás acceder a más beneficios",
  [MODAL_SELECT_PACKAGE_PRICING_HERO_DESCRIPTION]:
    "Descubre la plataforma que llevará a tu marca al siguiente nivel de reconocimiento en redes sociales.",
  [MODAL_SELECT_PACKAGE_VALUE_EXPRESSED]:
    "Los valores están expresados en {{currency}}",
  [MODAL_SELECT_PACKAGE_PRICE]: "{{value,currency}}/mes",
  [MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE]: "Fee Licencia",
  [MODAL_SELECT_PACKAGE_LICENSED_FEE]: "Fee Licencia ({{period}} meses)",
  [MODAL_SELECT_PACKAGE_LICENSED_PRICE]: "{{value,currency}}",
  [MODAL_SELECT_PACKAGE_CONTENT_ENTERPRISE]: "Contenido",
  [MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH]:
    "Se te cobrará {{value,currency}} por subir a growth",
  [MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT]: "Según requerimiento",
  [MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME]: "Comprar {{value}}",
  [MODAL_SELECT_PACKAGE_COST_COLLABORATION]:
    "{{value,currency}} por colaboración",
  [MODAL_SELECT_PACKAGE_COST_CONTENT]: "{{value,currency}} por contenido",
  [MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS]:
    "Chat directo con creadores",
  [MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION]: "Curación de contenido",
  [MODAL_SELECT_PACKAGE_BENEFIT_COLLABORATE_WITH_CREATOR]:
    "• Colabora con creadores",
  [MODAL_SELECT_PACKAGE_BENEFIT_ANALYZE_METRICS]:
    "• Analiza métricas de audiencia, alcance y engagement",
  [MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS]: "• Lanza campañas",
  [MODAL_SELECT_PACKAGE_BENEFIT_DOWNLOAD_CONTENT]:
    "• Descarga contenido para tu feed de redes sociales",
  [MODAL_SELECT_PACKAGE_BENEFIT_CATALOG]:
    "• Acceso al catálogo de creadores de contenido",
  [MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION]:
    "• Automatización canje de productos por contenido",
  [MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES]:
    "• Invitaciones automáticas",
  [MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS]:
    "• Soporte de ayuda por tickets",
  [MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC]: `• Todos los beneficios del Plan Basic`,
  [MODAL_SELECT_PACKAGE_BENEFIT_KAM]:
    "• KAM - Account Manager (Consultoría estratégica)",
  [MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT]: "• Volumen de contenidos",
  [MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY]:
    "• Descubre y analiza las métricas de cualquier perfil de IG o TikTok",
  [MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS]: "• Programa Embajadores de marca",
  [MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM]:
    "• Publicación directa a IG o TikTok",
  [MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT]: "• Chat directo con creadores",
  [MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING]:
    "• Asistente de marketing con inteligencia artificial 24/7",
  [MODAL_SELECT_PACKAGE_BENEFIT_ALL_GROWTH]:
    "• Todos los beneficios del Plan Growth",
  [MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED]: "• Marcas ilimitadas",
  [MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS]:
    "• Integraciones personalizadas (whatsapp, páginas web)",
  [MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL]:
    "• Implementaciones tecnológicas",
  [MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT]: "• Soporte prioritario",
  [MODAL_SUBSCRIPTION_PRO_ARIA_LABEL]: "Modal para pagar suscripción pro",
  [MODAL_SUBSCRIPTION_PRO_TITLE_HERO]: `¿Estás listo para desbloquear herramientas para mejorar tus colaboraciones?`,
  [MODAL_SUBSCRIPTION_PRO_DESCRIPTION_HERO]: `{{NAME_BUSINESS}} Pro te dará acceso a funcionalidades que te ayudarán a mejorar tus colaboraciones con creadores.`,
  [MODAL_SUBSCRIPTION_PRO_TITLE]: `{{NAME_BUSINESS}} Pro`,
  [MODAL_SUBSCRIPTION_PRO_SUB_HEADER]: "Más funcionalidades",
  [MODAL_SUBSCRIPTION_PRO_BENEFIT_1]:
    "• Iniciar chats directos con creadores que no son embajadores",
  [MODAL_SUBSCRIPTION_PRO_BUY_BUTTON]: `Comprar {{NAME_BUSINESS}} Pro`,

  //PACKAGES
  [BASIC_PACKAGE_NAME]: `{{NAME_BUSINESS}} Basic`,
  [BASIC_PACKAGE_SUB_HEADER]: "Atrae tu audiencia",
  [BASIC_PACKAGE_DESCRIPTION]: "Para pequeñas empresas",
  [PRO_PACKAGE_NAME]: "{{NAME_BUSINESS}} Pro",
  [PRO_PACKAGE_SUB_HEADER]: "Haz crecer tu marca",
  [PRO_PACKAGE_DESCRIPTION]: "Para pequeñas y medianas empresas",
  [GROWTH_PACKAGE_NAME]: "Growth",
  [GROWTH_PACKAGE_SUB_HEADER]: "Más popular",
  [GROWTH_PACKAGE_DESCRIPTION]: "Para pequeñas y medianas empresas",
  [ENTERPRISE_PACKAGE_NAME]: "Enterprise",
  [ENTERPRISE_PACKAGE_SUB_HEADER]: "Escala tu negocio",
  [ENTERPRISE_PACKAGE_DESCRIPTION]:
    "Para grupos empresariales y agencias, contacta con nosotros para obtener un plan personalizado",

  //BILLING DATA
  [BILLING_DATA_TITLE]: "Datos de Facturación",
  [BILLING_DATA_DESCRIPTION]:
    "Completa los datos que se usarán para emitir las facturas",
  [BILLING_DATA_SAVED]: "Datos de facturación guardados",
  [BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION]: "Información personal",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER]:
    "Tipo de persona",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION]:
    "Persona natural",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION]:
    "Persona jurídica",
  [BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER]: "Razón social",
  [BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER]: "Nombres",
  [BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER]: "Apellidos",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER]:
    "Tipo de identificación",
  [BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER]:
    "Número de identificación",
  [BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION]: "Información de contacto",
  [BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER]:
    "Correo electrónico (Facturación)",
  [BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER]: "Dirección",
  [BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER]: "Número de celular",

  [MODAL_FREE_TRIAL_TITLE]: "¡Inicia tu prueba gratis de 3 días!",
  [MODAL_FREE_TRIAL_DESCRIPTION]:
    "Colabora en canje, campañas, envia pagos y chatea con creadores de contenido.",
  [MODAL_FREE_TRIAL_CAPTION]: "Empieza ahora gratis",
  [MODAL_FREE_TRIAL_BUTTON]: "Probar GRATIS",
  [MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION]:
    "Desbloqueaste tu prueba gratis, súbete a {{NAME_BUSINESS}} Pro para empezar a trabajar con creadores de contenido.",

  [BLOCK_PAYMENT_FAILED_TITLE]: "Tu pago falló",
  [BLOCK_TRIAL_END_TITLE]: "Tu periodo de prueba ha finalizado",
  [BLOCK_PACKAGE_DESCRIPTION]:
    "Activa tu plan para colaborar con creadores, publicar campañas, analizar métricas y descargar contenido para tu feed de redes sociales",
  [BLOCK_PACKAGE_TITLE]: "Plan desactivado",
  [BLOCK_PLAN_BUTTON]: "Ir a panel de facturación",
  [BLOCK_PLAN_BUTTON_ACTIVATE_PLAN]: "Activar plan",
  [BLOCK_TRIAL_ACTIVE_TITLE]: "Prueba gratis está activa",
  [BLOCK_TRIAL_ACTIVE_DESCRIPTION]:
    "Activa tu plan para desbloquear todo el potencial de {{NAME_BUSINESS}}",
  [BLOCK_TRIAL_ACTIVE_BUTTON]: "Activar plan",
  [BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE]:
    "Su cuenta está inactiva, por favor comuníquese con su administrador.",

  [MODAL_BLOCK_LAST_INVITE_TITLE]: "Restricción de Cuenta: Acción Necesaria",
  [MODAL_BLOCK_LAST_INVITE_DESCRIPTION]:
    "Hemos aplicado una restricción temporal a tu cuenta, para mantener la calidad de nuestra plataforma. Algunas funcionalidades no estarán disponibles hasta que invites a cinco creadores.",
  [MODAL_BLOCK_LAST_INVITE_COUNT]: "{{count}}/5",
  [MODAL_BLOCK_LAST_INVITE_BUTTON]: "Invitar creadores",

  [MODAL_DISSATISFACTION_COLLABORATION_TITLE]:
    "Sabemos que no estás satisfecho",
  [MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION]:
    " Lamentamos que la colaboración no haya sido satisfactoria. Es por eso que te ofrecemos la próxima colaboración gratis.",
  [MODAL_DISSATISFACTION_COLLABORATION_BUTTON]: "Recibir 1 crédito",
  [MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT]:
    " *Un crédito equivale a una colaboración gratuita, se aplicará en la próxima colaboración que realices.",
  [MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED]: "Recibiste un crédito",

  [MODAL_BUY_CREDITS_ARIA_LABEL]: `Modal para comprar {{NAME_BUSINESS}} créditos`,
  [MODAL_BUY_CREDITS_TITLE]: "Comprar créditos",
  [MODAL_BUY_CREDITS_DESCRIPTION]:
    "Un crédito equivale a una publicación en canje de un creador",
  [MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION]:
    "Cada canje exitoso tiene un costo de {{value,currency}}",
  [MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY]:
    "¿Cuántos créditos quieres comprar?",
  [MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME]:
    "Tus créditos se redimirán en un estimado de {{value}} meses",
  [MODAL_BUY_CREDITS_ADDITIONAL_CREDITS]:
    "¡Te regalaremos {{value}} crédito(s)!",
  [MODAL_BUY_CREDITS_LAST_TEXT_PAY]: "El pago total será de {{value,currency}}",
  [MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE]:
    "{{credits}} Créditos por {{value,currency}}",
  [MODAL_BUY_CREDITS_GIFT_CREDITS]:
    "(Te regalamos {{creditsGift}} por comprar más de {{creditsBuy}})",
  [MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED]:
    "El número de créditos y método de pago es necesario",
  [MODAL_BUY_CREDITS_NEGATIVE]: "El número de créditos no puede ser menor a 1",
  [MODAL_BUY_CREDITS_PURCHASED]: "Créditos comprados",
  [MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION]:
    "Tus créditos se han agregado correctamente, podrás visualizarlos en la barra superior.",

  //MODAL ONBOARDING REQUIRED INFORMATION
  [MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE]: "Información requerida",
  [MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION]:
    "Completa esta información para activar tu marca y empezar a colaborar con creadores de contenido.",
  [MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION]: "Perfil de creador",
  [MODAL_ONBOARDING_STORES_SECTION]: "Tiendas",
  [MODAL_ONBOARDING_SCHEDULE_SECTION]: "Horarios",
  [MODAL_ONBOARDING_BASIC_INFORMATION_SECTION]: "Información de la marca",

  //MODAL ADD BRAND SUPER ADMIN
  [MODAL_ADD_BRAND_SUPER_ADMIN_TITLE]: "Agregar Marca",

  //LOGIN
  [FORGOT_PASSWORD]: "¿Olvidaste tu contraseña?",
  [NO_HAVE_ACCOUNT]: "¿Aún no tienes cuenta?",
  [REGISTER_HERE]: "Regístrate",
  [LOG_IN]: "Acceder",
  [RESET_PASSWORD_EMAIL_SENT]:
    "Hemos enviado un correo electrónico para con un enlace para que puedas restablecer tu contraseña.",

  //RESET PASSWORD
  [RESET_PASSWORD]: "Restablecer contraseña",
  [RESET_PASSWORD_GET_TOKEN_FAILED]:
    "No pudimos obtener el token para restablecer la contraseña, intenta abriendo de nuevo el enlace envíado a tu correo o vuelve darle click al botón de Olvidaste tu contraseña.",
  [RESET_PASSWORD_SAVED]: "Contraseña cambiada",

  //REGISTER
  [REGISTER_COUNTRY_ANSWER]: "¿En cuál país está tu marca?",
  [REGISTER_COUNTRY_PLACEHOLDER]: "País",
  [REGISTER_NAME_ANSWER]: "¿Cuál es tu nombre?",
  [REGISTER_PHONE_ANSWER]: "¿Cuál es tu número de celular?",
  [REGISTER_EMAIL_ANSWER]: "¿Cuál es tu correo electrónico?",
  [REGISTER_PASSWORD_ANSWER]: "Crea una contraseña",
  [REGISTER_OTP_ANSWER]: "Ingresa el código de verificación",
  [REGISTER_BUSINESS_NAME_ANSWER]: "¿Cuál es el nombre de tu marca?",
  [REGISTER_CATEGORY_ANSWER]: "¿Cuál es la categoría de tu marca?",
  [REGISTER_SOCIAL_NETWORK_ANSWER]:
    "¿Cuál es la red social principal de tu marca?",
  [REGISTER_SOCIAL_NETWORK_ANSWER_DESCRIPTION]:
    "Ingresa el @‌usuario de tu red social.",
  [REGISTER_WEBSITE_ANSWER]: "¿Cuál es la página web de tu marca?",
  [REGISTER_WEBSITE_ANSWER_DESCRIPTION]:
    "Ingresa el link de tu IG u otra red social si no tienes página web.",
  [REGISTER_MONTHLY_SALES_ANSWER]:
    "¿Cuál es la facturación mensual de tu marca?",
  [REGISTER_BUDGET_MARKETING_ANSWER]:
    "¿De cuánto es el presupuesto mensual de marketing de tu marca?",
  [REGISTER_EMPLOYEES_ANSWER]: "¿Cuántos empleados tiene tu marca?",
  [REGISTER_PRODUCT_AVAILABILITY_ANSWER]:
    "¿Cuál es la disponibilidad de tus productos?",
  [REGISTER_RUT_ANSWER]: "Sube el documento de constitución de tu empresa",
  [REGISTER_INVIMA_ANSWER]:
    "Sube el registro de autoridad sanitaria de tu marca",
  [REGISTER_ICA_ANSWER]:
    "Sube el registro de autoridad de productos para animales de tu marca",
  [REGISTER_YOU_MEET_ANSWER]: "¿Dónde nos conociste?",
  [REGISTER_WRITE_PLACEHOLDER]: "Escribe tu respuesta aquí",
  [REGISTER_SELECT_PLACEHOLDER]: "Selecciona una opción",
  [REGISTER_COUNTRY_REQUIRED]: "Debes seleccionar tu país",
  [REGISTER_NAME_REQUIRED]: "Debes escribir tu nombre",
  [REGISTER_PHONE_REQUIRED]: "Debes escribir tu número de celular",
  [REGISTER_PHONE_NUMERIC_REQUIRED]: "El número de celular no es válido",
  [REGISTER_BUSINESS_NAME_REQUIRED]: "Debes escribir el nombre de tu marca",
  [REGISTER_CATEGORY_REQUIRED]: "Debes seleccionar una categoría",
  [REGISTER_SOCIAL_NETWORK_REQUIRED]:
    "El @ de tu red social debe tener al menos 3 caracteres",
  [REGISTER_WEB_SITE_REQUIRED]:
    "Debes escribir el link a la página web de tu marca",
  [REGISTER_MONTHLY_SALES_REQUIRED]:
    "Debes seleccionar la facturación mensual de tu marca",
  [REGISTER_BUDGET_MARKETING_REQUIRED]:
    "Debes seleccionar el presupuesto mensual de marketing de tu marca",
  [REGISTER_EMPLOYEES_REQUIRED]:
    "Debes seleccionar el número de empleados de tu marca",
  [REGISTER_PRODUCT_AVAILABILITY_REQUIRED]:
    "Debes seleccionar la disponibilidad de tus productos",
  [REGISTER_RUT_REQUIRED]:
    "Debes seleccionar el RUT o documento de constitución de empresa",
  [REGISTER_INVIMA_REQUIRED]:
    "Debes seleccionar el registro INVIMA de tu marca",
  [REGISTER_ICA_REQUIRED]: "Debes seleccionar el registro ICA de tu marca",
  [REGISTER_YOU_MEET_REQUIRED]:
    "Debes seleccionar una opción de donde nos conociste",
  [REGISTER_NO_HAVE_DOCUMENTS_ALERT_TITLE]: "Lo sentimos",
  [REGISTER_NO_HAVE_DOCUMENTS_ALERT_DESCRIPTION]:
    "No podemos continuar con el proceso si no cuentas con los documentos requeridos, te invitamos a que retomes el proceso cuando los tengas.",
  [REGISTER_REGISTERED_ALERT_DESCRIPTION]:
    "Estamos analizando tu información, te enviaremos un correo una vez tu marca sea aprobada",
  [PLEASE_SELECT_COUNTRY]: "Por favor selecciona un país",
  [REGISTER_PROFILE_CREATOR_CITIES_ANSWER]:
    "¿De qué ciudades quieres encontrar creadores para trabajar?",
  [REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER]:
    "¿En qué plataformas de redes sociales o contenido quieres encontrar creadores para trabajar?",
  [REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER]:
    "¿Qué tipo de contenido quieres que haga tu creador ideal?",
  [REGISTER_PROFILE_CREATOR_GENDER_ANSWER]:
    "¿Con creadores de qué género te interesa colaborar?",
  [REGISTER_PROFILE_CREATOR_AGE_ANSWER]:
    "¿Con creadores de qué edades te interesa colaborar?",
  [REGISTER_PROFILE_CREATOR_BODY_BUILD]:
    "¿Qué tipo de cuerpo quieres que tenga tu creador ideal?",
  [REGISTER_PROFILE_CREATOR_CLOTHING_STYLE]:
    "¿Qué estilo de vestimenta quieres que tenga tu creador ideal?",
  [REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES]:
    "¿Qué características distintivas quieres que tenga tu creador ideal?",
  [REGISTER_PROFILE_CREATOR_CONTENT_STYLE]:
    "¿Qué estilo de contenido quieres que tenga tu creador ideal?",
  [REGISTER_PROFILE_CREATOR_CITIES_REQUIRED]:
    "Debes seleccionar al menos una ciudad",
  [REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED]:
    "Debes seleccionar al menos una plataforma",
  [REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED]:
    "Debes seleccionar mínimo tres categorías",
  [REGISTER_PROFILE_CREATOR_GENDER_REQUIRED]:
    "Debes seleccionar al menos un género",
  [REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED]:
    "Debes seleccionar mínimo dos rangos de edad",
  [REGISTER_PROFILE_CREATOR_BODY_BUILD_REQUIRED]:
    "Debes seleccionar al menos dos tipos de cuerpo",
  [REGISTER_PROFILE_CREATOR_CLOTHING_STYLE_REQUIRED]:
    "Debes seleccionar al menos tres estilos de vestimenta",
  [REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES_REQUIRED]:
    "Debes seleccionar al menos tres características distintivas",
  [REGISTER_PROFILE_CREATOR_CONTENT_STYLE_REQUIRED]:
    "Debes seleccionar al menos tres estilos de contenido",

  //CHATS
  [CHAT_ERROR_SENT_MESSAGE]:
    "Lo siento, hubo un fallo interno, intenta más tarde.",
  [CHAT_DO_PAYMENT]: "Realizar pago",
  [CHAT_WRITE_QUESTION_HERE]: "Escribe una pregunta",
  [CHAT_WRITE_MESSAGE_HERE]: "Escribe un mensaje",
  [CHAT_EMAIL_NO_PERMITTED]: "No se permiten enviar correos electrónicos",
  [ONE_SECOND_AGO]: "Hace un segundo",
  [CHAT_FIRST_MESSAGE_BOT]:
    "Hola, soy Ana, asesora experta en la plataforma.\n¿En qué puedo ayudarte hoy?",
  [CHAT_NO_MORE]: "No hay más chats",

  //NOFITICATIONS
  [NOTIFICATIONS_TITLE]: "Notificaciones",
  [NOTIFICATIONS_MARK_READS]: "Marcar como leídas",
  [NOTIFICATIONS_EMPTY]: "No tienes notificaciones",
  [NOTIFICATIONS_NO_MORE]: "No tienes más notificaciones",
  [NOTIFICATIONS_CHECK_NOTIFICATION]: "Revisar",

  //SHOPS
  [SHOPS_TITLE]: "Marcas",
  [SHOPS_SEARCH_BAR_PLACEHOLDER]: "Buscar por nombre de marca",
  [SHOPS_TABLE_ARIA_LABEL]: "Tabla de Marcas",
  [SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL]: "Modal de Marca",
  [SHOPS_MODAL_CREATE_SHOP_TITLE]: "Crea una marca",
  [SHOPS_MODAL_CREATE_SHOP_DESCRIPTION]:
    "Crea una marca que se unirá a {{NAME_BUSINESS}}",
  [SHOPS_SHOP_CREATE]: "Marca creada",

  //SHOP DETAIL
  [SHOP_DETAIL_TITLE]: `Marca {{value}}`,
  [SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK]:
    " Crear link de pago enterprise",
  [SHOP_DETAIL_HAS_BILLING_CYCLE]: "Tiene ciclo de facturación",
  [SHOP_DETAIL_REPORT_TITLE]: "Informe",
  [SHOP_DETAIL_REPORT_SELECT_DATES]:
    "Selecciona un intervalo de fechas y dale calcular",
  [SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION]:
    "Esto borrará el plan actual y lo cambiará para que la marca deba pagar una suscripción basic",
  [SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE]: "Eliminar invitación",
  [SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de eliminar esta invitación?",
  [SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE]: "Extender invitación",
  [SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de extender el tiempo de esta invitación?",
  [SHOP_DETAIL_REPORT_EXTENDING_INVITE]: "Extendiendo tiempo de invitación",
  [SHOP_DETAIL_REPORT_INVITATION_EXTENDED]: "Invitación extendida",

  //SHOPS APPLICANTS
  [SHOPS_APPLICANTS_TITLE]: "Registros de marca",
  [SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER]: "Buscar por nombre de marca",
  [SHOPS_APPLICANTS_TABLE_ARIA_LABEL]: "Tabla de Marcas",
  [SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_TITLE]: "Rechazar marca",
  [SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de rechazar esta marca?",
  [SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_BUTTON]: "Rechazar",
  [SHOPS_APPLICANTS_REJECT_SHOP_REJECTING]: "Rechazando marca",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_ARIA_LABEL]: "Modal de documentos de marca",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_TITLE]: "Documentos de {{value}}",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_EMPTY]: "No hay documentos para mostrar",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_RUT]: "RUT",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_INVIMA]: "INVIMA",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_ICA]: "ICA",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_OPEN]: "Abrir",
  [SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_ARIA_LABEL]: "Modal de aceptar marca",
  [SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_TITLE]: "Aceptar marca",
  [SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_CREATE_BRAND]: "Crear marca",

  //SHOPS ACCEPTED
  [SHOPS_ACCEPTED_TITLE]: "Marcas aceptadas",
  [SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED]: "No se logeó",
  [SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED]: "Se logeó",
  [SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR]: "Plan regular",
  [SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE]: "Plan enterprise",
  [SHOPS_ACCEPTED_TABLE_ARIA_LABEL]: "Tabla de shops",
  [SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL]: "Información de la tienda",

  //OWNER USERS
  [OWNER_USERS_TITLE]: "Usuarios",
  [OWNER_USERS_SEARCH_BAR]: "Buscar usuarios",
  [OWNER_USERS_TABLE_ARIA_LABEL]: "Tabla de usuarios administradores",
  [OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE]:
    "¿Estás seguro de eliminar este usuario?",
  [OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION]:
    "Una vez eliminado, no se podrá recuperar",
  [OWNER_USERS_DELETE_USER_CONFIRM]: "Sí, eliminar",
  [OWNER_USERS_DELETE_USER_DELETING]: "Borrando usuario",
  [OWNER_USERS_DELETE_USER_DELETED]: "Usuario eliminado",
  [OWNER_USERS_FAILED_DELETE_USER]: "No se eliminó el usuario",
  [OWNER_USERS_CREATED_USER]: "Usuario creado",
  [OWNER_USERS_SAVED_USER]: "Usuario guardado",
  [OWNER_USERS_MODAL_ARIA_LABEL]: "Modal de crear usuario",
  [OWNER_USERS_MODAL_TITLE]: "Crea un usuario",
  [OWNER_USERS_MODAL_TITLE_EDIT]: "Edita un usuario",
  [OWNER_USERS_MODAL_DESCRIPTION]: "Crea un usuario para una marca",
  [OWNER_USERS_MODAL_DESCRIPTION_EDIT]: "Edita un usuario ya creado",
  [OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE]:
    "No selecciones una empresa si crearás una nueva",
  [OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES]: "Esta marca no tiene tiendas",

  //DELIVERIES OWNER
  [DELIVERIES_OWNER_TITLE]: "Deliveries",
  [DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER]: "Por aceptar",
  [DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER]: "Procesando",
  [DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER]: "Por entregar",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING]: "Por aceptar",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION]: "Procesando",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED]: "Por entregar",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED]: "Entregado",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED]: "Cancelado",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED]: "Cancelado",
  [DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION]:
    "Una vez borrado el delivery, no podrás recuperarlo",

  //CREATORS SIGNUPS
  [REQUESTS_INFLUENCERS_ACCOUNT_TITLE]: "Registros de creadores",
  [REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER]: "Buscar por nombre",
  [REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS]: "Esperando respuesta",
  [REQUEST_INFLUENCERS_CODE_SENT_STATUS]: "Código enviado",
  [REQUEST_INFLUENCERS_PENDING_SENT_CODE]: "Pendiente de enviar código",

  //CREATORS OWNER
  [CREATORS_OWNER_TITLE]: "Creadores",
  [CREATORS_OWNER_IS_PRE_REGISTERED_FILTER]: "Pre registrados",
  [CREATORS_OWNER_WAS_REGISTERED_FILTER]: "Ya registrados",
  [CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER]: "Busca por nombre o usuario",
  [CREATORS_OWNER_CREATOR_NOT_FOUND]: "Creador no encontrado",
  [CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE]:
    "¿Estás seguro de eliminar este creador?",
  [CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION]:
    "Una vez eliminado, no se podrá recuperar",
  [CREATORS_OWNER_DELETING_CREATOR]: "Borrando creador",
  [CREATORS_OWNER_DELETED_CREATOR]: "Creador eliminado",
  [CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE]: "Eliminar orden",
  [CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de eliminar esta orden?",
  [CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE]: "Archivar orden",
  [CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de archivar esta orden?",
  [CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE]: "Desarchivar orden",
  [CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de desarchivar esta orden?",
  [CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE]: "Eliminar invitación",
  [CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de eliminar esta invitación?",
  [CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE]: "Extender invitación",
  [CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de extender el tiempo de esta invitación?",
  [CREATORS_OWNER_EXTENDING_INVITATION]: "Extendiendo tiempo de invitación",
  [CREATORS_OWNER_INVITATION_EXTENDED]: "Invitación extendida",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE]:
    "Marcar como redimida",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION]:
    "¿Estás seguro de marcar estar invitación como redimida? esto generará una orden nueva",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON]:
    "Marcar como redimida",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING]:
    "Redimiendo invitación y creando orden",
  [CREATORS_OWNER_REPORT_EMPTY_INVITATIONS]: "No tienes invitaciones",
  [CREATORS_OWNER_REPORT_DATE_REDEMPTION]: "Fecha de redención",
  [CREATORS_OWNER_REPORT_DELIVERIES]: "Deliveries",
  [CREATORS_OWNER_REPORT_DELIVERIES_EMPTY]: "No hay deliveries",
  [CREATORS_OWNER_REPORT_WAS_ACCEPTED]: "Se aceptó",
  [CREATORS_OWNER_REPORT_IS_DELIVERY]: "Es delivery",
  [CREATORS_OWNER_REPORT_ORDERS_EMPTY]: "No hay órdenes",
  [CREATORS_OWNER_REPORT_ORDERS_ARCHIVED]: "Ordenes archivadas",
  [CREATORS_OWNER_REPORT_ORDERS_ARCHIVED_EMPTY]: "No hay ordenes archivadas",
  [CREATORS_OWNER_REPORT_WAS_ARCHIVED]: "Se archivó",
  [CREATORS_OWNER_REPORT_TRANSACTIONS]: "Transacciones",
  [CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY]: "No hay transacciones",
  [MODAL_EDIT_CREATOR_OWNER_TITLE]: "Información del creador",
  [MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR]: "Ocultar creador",
  [MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR]: "Mostrar creador",
  [MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR]: "Desactivar creador",
  [MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR]: "Activar creador",
  [MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES]:
    "No se pueden escoger más de 3 categorías",
  [MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL]:
    "@ del creador de contenido",
  [MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL]: "Categorias",
  [MODAL_DISABLED_CREATOR_OWNER_TITLE]: "Desactivar creador de contenido",

  //TRANSACTIONS
  [TRANSACTION_CONCEPT_LABEL]: "Concepto",
  [TRANSACTION_TYPE]: "Tipo",
  [TRANSACTION_TYPE_CREDIT]: "Crédito",
  [TRANSACTION_TYPE_DEBIT]: "Débito",
  [TRANSACTION_STATUS]: "Estado",
  [TRANSACTION_STATUS_PENDING]: "Pendiente",
  [TRANSACTION_STATUS_PENDING_RELEASE]: "Pendiente de liberar",
  [TRANSACTION_STATUS_IN_PROCESS_RELEASE]: "En proceso de liberación",
  [TRANSACTION_STATUS_RELEASED]: "Liberado",
  [TRANSACTION_STATUS_PENDING_SENT]: "Pendiente de enviar",
  [TRANSACTION_IN_TRANSIT]: "En tránsito",
  [TRANSACTION_STATUS_PAID]: "Pagado",
  [TRANSACTION_WAS_PAID]: "Se pagó",
  [MODAL_CREATE_TRANSACTION]: "Crea una transacción a un creador",
  [MODAL_CREATE_TRANSACTION_AMOUNT_LABEL]: "Monto (USD)",
  [MODAL_CREATE_TRANSACTION_CONCEPT_LABEL]: "Concepto",
  [MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR]: "Embajador",
  [MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT]: "Aplicante",
  [MODAL_CREATE_TRANSACTION_CONCEPT_ORDER]: "Órden",
  [MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL]: "Referido",
  [MODAL_CREATE_TRANSACTION_CONCEPT_ID]: "ID del concepto (Opcional)",

  //CONTENT
  [CONTENT_TITLE]: "Contenido de marcas",

  //CONTENT APPLICATION
  [CONTENT_APPLICATION_TITLE]: "Aplicación de contenido",
  [CONTENT_APPLICATION_ACCEPT_TITLE]: "Aceptar aplicación",
  [CONTENT_APPLICATION_ACCEPT_DESCRIPTION]:
    "Al aceptar esta aplicación, el usuario se podrá registrar como creador de contenido",
  [CONTENT_APPLICATION_REJECT_TITLE]: "Rechazar aplicación",
  [CONTENT_APPLICATION_REJECT_DESCRIPTION]:
    "Al rechazar estar aplicación, el usuario no podrá registrarse como creador de contenido",

  //REPORTS
  [REPORTS_TITLE]: "Reportes",
  [REPORTS_DESCRIPTION]:
    "Aquí encontrarás las estadísticas generales del ecosistema de {{NAME_BUSINESS}}",
  [REPORTS_ERROR_GENERATING]:
    "Algo salió mal con la generación de estadísticas, por favor inténtalo de nuevo.",
  [REPORTS_INVITATIONS_ACTIVE]: "Invitaciones activas",
  [REPORTS_INVITATIONS_INACTIVE]: "Invitaciones inactivas",
  [REPORTS_REQUESTS_ACTIVE]: "Solicitudes activas",
  [REPORTS_REQUESTS_INACTIVE]: "Solicitudes inactivas",
  [REPORTS_TYPE_USERS]: "Tipo de usuario",
  [REPORTS_NUMBER_USERS]: "Número de usuarios",
  [REPORTS_TYPE_ORDER]: "Tipo de orden",
  [REPORTS_NUMBER_ORDER]: "Número de ordenes",
  [REPORTS_MODAL_DESCRIPTION]:
    "Aquí encontrarás los reportes de distintas categorías",
  [REPORTS_MODAL_ONLY_BRANDS_ACTIVE]: "Solo tiendas activas",
  [REPORTS_MODAL_TYPE_REPORT_LABEL]: "Tipo de reporte",
  [REPORTS_MODAL_TYPE_REPORT_BRANDS]: "Marcas",
  [REPORTS_MODAL_TYPE_REPORT_CREATORS]: "Creadores",
  [REPORTS_MODAL_TYPE_REPORT_ORDERS]: "Órdenes",
  [REPORTS_MODAL_TYPE_REPORT_INVITATIONS]: "Invitaciones",

  //SHOPIFY TERMS
  [INTEGRATIONS_TERMS_TITLE]: `Política de Privacidad de {{NAME_BUSINESS}} con Integraciones`,
  [INTEGRATIONS_TERMS_DESCRIPTION]: "Última actualización: {{date,short}}",
  [INTEGRATIONS_TERMS_CONTENT]: `
  <strong>1. Información recopilada</strong>

  En {{NAME_BUSINESS}}, respetamos la privacidad de nuestros usuarios y clientes. A través de nuestra integración con plataformas, recopilamos información limitada y específica únicamente para el funcionamiento técnico de nuestra aplicación. Los datos que recopilamos incluyen:

  Nombre de la tienda de la plataforma: Utilizamos esta información para identificar la tienda y facilitar la ejecución de acciones específicas dentro de la plataforma.

  Token de autorización: Utilizamos un token de autorización proporcionado por los comerciantes de la plataforma para realizar las solicitudes técnicas necesarias para el monitoreo de cambios de estado de entregas y la ejecución de acciones según sea necesario.

  <strong>2. Uso de la información</strong>

  Es importante destacar que no almacenamos ningún dato personal ni información identificable de los comerciantes o sus clientes. La información recopilada se utiliza únicamente para la ejecución técnica de la aplicación {{NAME_BUSINESS}} en la plataforma. No se utiliza con ningún otro fin y no se almacena en nuestras bases de datos.

  <strong>3. Retención de datos</strong>

  En línea con nuestra política de no almacenamiento de datos, no retenemos ninguna información de los comerciantes o sus clientes. Cualquier información recibida se utiliza en tiempo real y se descarta inmediatamente después de su uso.

  <strong>4. Contacto</strong>

  Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, no dudes en ponerte en contacto con nosotros en ${EMAIL_SUPPORT}.
  `,

  [AUTH_TIKTOK_VERIFICATION_LOADING]: "Verificando tu cuenta de TikTok",
  [AUTH_TIKTOK_VERIFICATION_SUCCESS]: "Tu cuenta de TikTok ha sido verificada",
  [AUTH_TIKTOK_VERIFICATION_FAILED]:
    "Ocurrió un error. No pudimos verificar tu cuenta de TikTok",

  //COLUMN NAMES
  [COLUMN_NAME_SHOP_ID]: "ID Tienda",
  [COLUMN_NAME_SHOP]: "Tienda",
  [COLUMN_NAME_BRAND]: "Marca",
  [COLUMN_NAME_CATEGORY]: "Categoría",
  [COLUMN_NAME_IS_LOGGED]: "Se logeó",
  [COLUMN_NAME_IS_ACTIVE]: "Está Activa",
  [COLUMN_NAME_IS_PLAN_ACTIVE]: "Plan activo",
  [COLUMN_NAME_PACKAGE]: "Paquete",
  [COLUMN_NAME_EMAIL]: "Correo",
  [COLUMN_NAME_NAME]: "Nombre",
  [COLUMN_NAME_PHONE]: "Telefono",
  [COLUMN_NAME_STAFF]: "Staff",
  [COLUMN_NAME_ACCEPTED]: "Aceptado",
  [COLUMN_NAME_MONTHLY_SALES]: "Ventas por mes",
  [COLUMN_NAME_BUDGET_MARKETING]: "Presupuesto marketing",
  [COLUMN_NAME_EMPLOYEES]: "Trabajadores",
  [COLUMN_NAME_PRODUCT_AVAILABILITY]: "Disponibilidad de productos",
  [COLUMN_NAME_SOCIAL_NETWORK]: "Red social",
  [COLUMN_NAME_WEB_SITE]: "Website",
  [COLUMN_NAME_YOU_MEET]: "Donde nos conoció",
  [COLUMN_NAME_DATE]: "Fecha",
  [COLUMN_NAME_PLAN]: "Plan",
  [COLUMN_NAME_PAYMENT_METHOD]: "Método de pago",
  [COLUMN_NAME_COST_COLLABORATION]: "Costo por colaboración",
  [COLUMN_NAME_CREDITS]: "Créditos",
  [COLUMN_NAME_COUNTRY]: "País",
  [COLUMN_NAME_CREATOR_NAME]: "Nombre",
  [COLUMN_NAME_CREATOR_PLATFORM]: "Plataforma",
  [COLUMN_NAME_CITY]: "Ciudad",
  [COLUMN_NAME_AUDIENCE]: "Audiencia",
  [COLUMN_NAME_ENGAGEMENT]: "Engagement",
  [COLUMN_NAME_CATEGORIES]: "Categorías",
  [COLUMN_NAME_SCORE]: "Puntuación",
  [COLUMN_NAME_ACTIVITY]: "Actividad",
  [COLUMN_NAME_ACTIONS]: "Acciones",
  [COLUMN_NAME_ACTION]: "Acción",
  [COLUMN_NAME_INVITATION]: "Invitación",
  [COLUMN_NAME_CODE_INVITATION]: "Código",
  [COLUMN_NAME_VALUE_INVITATION]: "Valor",
  [COLUMN_NAME_USE_INVITATION]: "Uso",
  [COLUMN_NAME_EXPIRATION_INVITATION]: "Expira",
  [COLUMN_NAME_TYPE_INVITATION]: "Tipo",
  [COLUMN_NAME_REJECTED_REASON_INVITATION]: "Rechazada por",
  [COLUMN_NAME_STATUS]: "Estado",
  [COLUMN_NAME_REDEEMED_DATE]: "Redimida el",
  [COLUMN_NAME_TERMS]: "Términos",
  [COLUMN_NAME_TYPE]: "Tipo",
  [COLUMN_NAME_ADDRESS]: "Dirección",
  [COLUMN_NAME_STORE]: "Tienda",
  [COLUMN_NAME_ROLE]: "Rol",
  [COLUMN_NAME_PAID_DATE]: "Pagada el",
  [COLUMN_NAME_DUE_DATE]: "Vence el",
  [COLUMN_NAME_REASON]: "Razón",
  [COLUMN_NAME_VALUE]: "Valor",
  [COLUMN_NAME_ID]: "ID",
  [COLUMN_NAME_CREATOR]: "Creador",
  [COLUMN_NAME_CREATED_DATE]: "Creado el",
  [COLUMN_NAME_ACCEPTED_DATE]: "Aceptado el",
  [COLUMN_NAME_DETAILS]: "Detalles",
  [COLUMN_NAME_DELIVERY]: "Delivery",
  [COLUMN_NAME_CODE_VERIFICATION]: "Código de verificación",
  [COLUMN_NAME_ACCOUNT_NAME]: "Nombre de cuenta",
  [COLUMN_NAME_IS_HIDDEN]: "Oculto",
  [COLUMN_NAME_COMMENT]: "Comentario",
  [COLUMN_NAME_URLS]: "Urls",
  [COLUMN_NAME_IS_PRE_REGISTERED]: "Es pre registrado",
  [COLUMN_NAME_WAS_PRE_REGISTERED]: "Ya está pre registrado",

  //ALERTS
  [ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED]:
    "Para poder activar tu marca necesitas completar los siguientes campos: ",
  [ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON]: "Completar ahora",
  [ALERT_ACTIVATE_SHOP_BY_OWNER_FIELDS_REQUIRED]:
    "Para poder activar esta marca necesita completar los siguientes campos: ",
  [REMINDER_ACTIVATE_SHOP]:
    "Activa tu cuenta para que los creadores puedan ver tu marca.",
  [ALERT_INACTIVE_SHOP_TITLE]: "Marca desactivada",
  [ALERT_INACTIVE_SHOP_DESCRIPTION]:
    "Activa la marca para seguir usando la plataforma",
  [ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION]:
    "Activa la marca para iniciar este chat",
  [ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION]:
    "No puedes ejecutar esta acción porque no tienes los permisos suficientes",
  [ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION]:
    "Para poder invitar a un creador a colaborar, necesitas completa los siguientes campos: ",
  [ALERT_DISCARD_INFLUENCER_DEFINITIVELY_TITTLE]: "Descartar creador",
  [ALERT_DISCARD_INFLUENCER_DEFINITIVELY_DESCRIPTION]:
    "No te volverá aparecer este creador y no podrás colaborar con él",
  [ALERT_DISCARD_INFLUENCER_DEFINITIVELY_BUTTON]: "Descartar creador",
  [ALERT_CREATE_SECOND_ADS_CAMPAIGN]: "Crear campaña en {{platform}}",
  [ALERT_CREATE_SECOND_ADS_CAMPAIGN_DESCRIPTION]:
    "¿Deseas crear una campaña en {{platform}} con la misma configuración que esta campaña?",
  [ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM]:
    "Si recargas tu cuenta, ten en cuenta que el saldo puede verse tardar unos minutos en verse reflejado y habilitarse de nuevo esta opción.",
  [ALERT_DESCRIPTION_DISCONNECT_FACEBOOK]:
    "Se deshabilitarán todas las funcionalidades campañas en Meta y publicar directamente a Instagram.",
  [ALERT_DESCRIPTION_DISCONNECT_TIKTOK]:
    "Se deshabilitarán todas las funcionalidades de publicar directamente a TikTok",
  [ALERT_DESCRIPTION_DISCONNECT_TIKTOK_BUSINESS]:
    "Se deshabilitarán todas las funcionalidades de crear y gestionar campañas de TikTok",
  [ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE]: "¿Estás seguro?",
  [ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION]:
    "Crearás una campaña con un presupuesto de {{value,currency}}.",
  [ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN]:
    "Este creador ya fue invitado a esta campaña",

  //OPTIONS UGC FORMATS
  [OPTION_UGC_FORMAT_GRWM]: "GRWM (Get Ready With Me)",
  [OPTION_UGC_FORMAT_GRWM_EXPLANATION]:
    "Este tipo de contenido involucra a creadores de contenido compartiendo su rutina de preparación, ya sea para el día a día o para eventos especiales. Los espectadores pueden ver cómo el creador se maquilla, peina y elige su vestuario, mientras habla de diversos temas de manera informal.",
  [OPTION_UGC_FORMAT_OODT]: "OOTD (Outfit of the Day)",
  [OPTION_UGC_FORMAT_OODT_EXPLANATION]:
    "En estos videos o publicaciones, los creadores muestran el atuendo que han elegido para el día. Es popular entre entusiastas de la moda y puede incluir detalles sobre dónde compraron la ropa, cómo la combinan con otros elementos y consejos de estilo.",
  [OPTION_UGC_FORMAT_HAULS]: "Hauls",
  [OPTION_UGC_FORMAT_HAULS_EXPLANATION]:
    "Los 'hauls' son videos o series de fotos donde los creadores muestran una colección de productos que han comprado recientemente, que pueden incluir moda, belleza, tecnología, etc. A menudo, comparten sus primeras impresiones sobre estos productos y pueden incluir demostraciones de uso.",
  [OPTION_UGC_FORMAT_DIYS]: "Tutorials/DIYs",
  [OPTION_UGC_FORMAT_DIYS_EXPLANATION]:
    "Tutoriales o 'Do It Yourself' (Hazlo Tú Mismo) son guías paso a paso creadas por usuarios que muestran cómo hacer algo, desde recetas de cocina hasta proyectos de manualidades y trucos de belleza. Estos contenidos son educativos y buscan enseñar habilidades de manera fácil y accesible.",
  [OPTION_UGC_FORMAT_UNBOXING]: "Unboxing",
  [OPTION_UGC_FORMAT_UNBOXING_EXPLANATION]:
    "En estos videos, los creadores abren productos nuevos frente a la cámara para mostrar su contenido y dar una primera impresión. Son comunes en tecnología, juguetes, cosméticos y más, ayudando a los espectadores a decidir si desean comprar esos productos.",
  [OPTION_UGC_FORMAT_CHALLENGES]: "Challenges",
  [OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION]:
    "Los retos o 'challenges' son actividades en las que los creadores participan para entretener a sus seguidores. Estos pueden variar desde retos de comida, juegos, actividades físicas hasta retos de moda, como crear un atuendo con elementos específicos.",
  [OPTION_UGC_FORMAT_REVIEWS]: "Reviews/Testimonios",
  [OPTION_UGC_FORMAT_REVIEWS_EXPLANATION]:
    "Los creadores comparten sus opiniones y experiencias detalladas sobre un producto o servicio específico. Estas reseñas ayudan a otros usuarios a formarse una opinión antes de hacer una compra.",
  [OPTION_UGC_FORMAT_VLOGS]: "Vlogs",
  [OPTION_UGC_FORMAT_VLOGS_EXPLANATION]:
    "Los vlogs son diarios en video donde los creadores comparten aspectos de su vida diaria, viajes, eventos especiales, etc. Son personales y permiten a los espectadores sentir una conexión más cercana con el creador.",
  [OPTION_UGC_FORMAT_QA]: "Q&A (Questions and Answers)",
  [OPTION_UGC_FORMAT_QA_EXPLANATION]:
    "En un formato de preguntas y respuestas, los creadores interactúan directamente con su audiencia respondiendo preguntas que estos han enviado previamente. Esto ayuda a construir una relación más personal y directa con los seguidores.",
  [OPTION_UGC_FORMAT_STORYTIME]: "Storytime",
  [OPTION_UGC_FORMAT_STORYTIME_EXPLANATION]:
    "Estos son videos o posts en los que los creadores narran historias personales o experiencias significativas. Son atractivos debido a su aspecto narrativo y a menudo incluyen lecciones o moralejas.",
  [OPTION_UGC_FORMAT_ROOM_TOURS]: "Room Tours",
  [OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION]:
    "Los creadores muestran su espacio personal, como su dormitorio, estudio o casa entera. Estos videos son populares porque permiten a los seguidores obtener ideas de decoración y sentir una cercanía con el estilo de vida del creador.",
  [OPTION_UGC_FORMAT_WHATS_IN_MY_BAG]: "What’s in My Bag?",
  [OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION]:
    "En este tipo de contenido, los creadores revelan lo que llevan en sus bolsos o mochilas diariamente. Es popular especialmente entre celebridades y personas influyentes en la moda y belleza, ya que ofrece un vistazo a sus esenciales personales.",
  [OPTION_UGC_FORMAT_COOKING]: "Cooking/Baking Recipes",
  [OPTION_UGC_FORMAT_COOKING_EXPLANATION]:
    "Los creadores comparten recetas y procesos de cocina o repostería en forma de videos o posts. Estos contenidos son útiles para quienes buscan nuevas ideas de comidas y disfrutan del aspecto visual y explicativo de la cocina.",
  [OPTION_UGC_FORMAT_FITNESS_ROUTINES]: "Fitness Routines",
  [OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION]:
    "Los influencers de fitness comparten sus rutinas de ejercicios, consejos de salud y jornadas de bienestar. Estos videos son motivacionales y educativos, ayudando a los seguidores a mantenerse activos y saludables.",
  [OPTION_UGC_FORMAT_ASMR]: "ASMR (Autonomous Sensory Meridian Response):",
  [OPTION_UGC_FORMAT_ASMR_EXPLANATION]:
    "Los videos de ASMR están diseñados para provocar una sensación relajante en los espectadores a través de sonidos suaves y movimientos meticulosos. Son muy populares para la relajación y el manejo del estrés.",
  [OPTION_UGC_FORMAT_PLAN_WITH_ME]: "Plan With Me",
  [OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION]:
    "En estos videos, los creadores muestran cómo organizan su agenda o planificador semanal, compartiendo técnicas de gestión del tiempo y decoración de sus planificadores. Es ideal para los amantes de la papelería y la organización personal.",
  [OPTION_UGC_FORMAT_MUKBANG]: "Mukbang",
  [OPTION_UGC_FORMAT_MUKBANG_EXPLANATION]:
    "Originado en Corea del Sur, en estos videos los creadores comen grandes cantidades de comida mientras interactúan con su audiencia. Se ha popularizado globalmente como una forma de compañía virtual durante las comidas.",
  [OPTION_UGC_FORMAT_DAY_IN_THE_LIFE]: "Day in the Life",
  [OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION]:
    "Los creadores comparten un video que documenta un día típico en su vida, mostrando desde la mañana hasta la noche. Esto da a los seguidores una visión completa de sus actividades cotidianas y su estilo de vida.",
  [OPTION_UGC_FORMAT_BEHIND_THE_SCENES]: "Behind the Scenes",
  [OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION]:
    "Estos videos ofrecen una mirada detrás de cámaras en la vida de los creadores, incluyendo cómo preparan sus contenidos, gestionan negocios, o incluso eventos especiales a los que asisten.",
  [OPTION_UGC_FORMAT_TRAVEL_DIARIES]: "Travel Diaries",
  [OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION]:
    "En diarios de viaje, los creadores comparten experiencias, consejos y aventuras de sus viajes. Estos videos o posts son atractivos para quienes buscan inspiración para viajar o aprender sobre nuevas culturas.",

  //ERRORS
  [SOMETHING_ERROR_GENERATING_STATISTICS]:
    "Algo salió mal con la generación de estadísticas, por favor inténtalo de nuevo.",
  [AUTH_SENT_EMAIL_RESET_PASSWORD]:
    "Se ha enviado un correo para restablecer la contraseña",
  [ACCOUNT_ROLE_INVALID]:
    "Esta cuenta no tiene un rol válido para iniciar sesión",
  [ERROR_INTERNAL]: "Error interno, intente de nuevo más tarde",
  [AUTH_INVALID_PASSWORD]: "La contraseña no es válida",
  [AUTH_WRONG_PASSWORD]: "La contraseña es incorrecta",
  [AUTH_MANY_REQUESTS]:
    "Demasiados intentos fallidos, intente de nuevo más tarde",
  [AUTH_USER_NOT_FOUND]: "El usuario no existe",
  [AUTH_EMAIL_ALREADY_EXISTS]: "El correo electrónico ya está en uso",
  [AUTH_INVALID_VERIFICATION_CODE]: "El código de verificación no es válido",
  [STRIPE_CARD_DECLINED]:
    "La tarjeta ha sido rechazada. Verifica la información o validez de la tarjeta o usa una tarjeta diferente",
  [STRIPE_EXPIRED_CARD]:
    "La tarjeta ha caducado. Verifica la fecha de vencimiento o usa una tarjeta diferente",
  [STRIPE_INCORRECT_CVC]:
    "El código de seguridad de la tarjeta es incorrecto. Verifica el código de seguridad de la tarjeta o usa una tarjeta diferente",
  [STRIPE_PROCESSING_ERROR]:
    "Ocurrió un error al procesar la tarjeta. Vuelve a intentarlo más tarde o prueba con otro método de pago",
  [STRIPE_INCORRECT_NUMBER]:
    "El número de tarjeta es incorrecto. Verifica el número de la tarjeta o usa una tarjeta diferente",
  [STRIPE_GENERIC_DECLINE]:
    "La tarjeta fue rechazada por un motivo desconocido",
  [STRIPE_INSUFFICIENT_FUNDS]:
    "La tarjeta no tiene fondos suficientes para completar la compra o para el cobro de verificación",
  [STRIPE_LOST_CARD]:
    "El pago fue rechazado porque la tarjeta se reportó perdida",
  [STRIPE_STOLEN_CARD]:
    "El pago fue rechazado porque la tarjeta fue reportada como robada",
  [STRIPE_PROFILE_NOT_FOUND]: "Este perfil no existe",
  [STRIPE_INTENT_AUTH_FAILURE]: "La autenticación de la tarjeta falló",
  [NIT_VALID]: "Número de identificación",
  [BUSINESS_NAME_VALID]: "Razón social de la empresa",
  [BRAND_NAME_VALID]: "Nombre de la marca",
  [ADDRESS_VALID]: "Dirección de la empresa",
  [CATEGORY_VALID]: "Categoría",
  [SUBCATEGORIES_VALID]: "Sub categorías",
  [SHORT_DESCRIPTION_VALID]: "Descripción corta de la empresa",
  [ORDERS_METHODS_VALID]: "Métodos de entrega de pedidos",
  [LOGO_IMG_VALID]: "Logo de la empresa",
  [COVER_IMG_VALID]: "Imagen de portada",
  [SCHEDULE_VALID]: "Horarios de atención de la empresa",
  [STORES_VALID]: "Al menos una tienda creada y que esté activa",
  [IS_PLAN_ACTIVE_VALID]: "Debes tener un plan activo",
  [MENUS_VALID]:
    "Debes tener al menos una categoría con al menos un producto creado en el catálogo",
  [DEFAULT_PAYMENT_METHOD_VALID]:
    "Debes tener una tarjeta por defecto añadida o créditos comprados",
  [HAS_DEBT_VALID]: "No debes tener pagos pendientes",
  [SUBSCRIPTION_PLAN_VALID]: "Debes tener un plan activo",
  [PROFILE_INFLUENCER_VALID]:
    "Debes crear o actualizar tu perfil de creador de contenido",
  [AUTOMATIC_INVITATIONS_VALID]:
    "Debes llenar o actualizar tu configuración de canjes",
  [SHOP_ID_USER_ID_NOT_SENT]: "ShopID y userID no se enviaron",
  [MENU_HAS_ELEMENTS_CREATED]:
    "Asegúrese que el catálogo seleccionado tenga elementos creados.",
  [INVOICE_CREATED_FOR_RETRY_PAYMENT]:
    "Se creó una factura en el panel de facturación para que reintentes el pago.",
  [OPERATOR_USER_STORE]:
    "Para eliminar esta tienda no debes tener ningún usuario operador asociado a ella, eliminalo o asocialo a otra tienda.",
  [STORE_NO_HAS_MENU]: "Esta tienda no tiene un catálogo para ser activada.",
  [STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT]:
    "Esta tienda no tiene un catálogo para ser activada.",
  [STORE_COVERAGE_RADIUS_REQUIRED]:
    "El radio de cobertura es requerido para activar esta tienda.",
  [MISSING_PARAMETERS]: "No se enviaron los parámetros requeridos.",
  [PASSWORD_INVALID]: "Contraseña inválida.",
  [USER_ID_REQUIRED]: "UserID es requerido.",
  [SHOP_ID_REQUIRED]: "ShopID es requerido.",
  [LAST_USER_NOT_DELETED]:
    "No se pueden eliminar todos los usuarios, debe quedar al menos uno.",
  [PLAN_TRIAL_USER_USED]: "Ya se usó el periodo de prueba para este usuario.",
  [ORDERS_NOT_FOUND_IN_INTERVAL]:
    "No se encontraron órdeenes en el intervalo de fechas seleccionado.",
  [TYPE_REPORT_NOT_FOUND]: "No se encontró el tipo de reporte.",
  [SHOP_NOT_FOUND]: "Marca no encontrada.",
  [PAYMENT_MODEL_INVALID]: "Modelo de pago inválido.",
  [SHOP_NOT_HAS_SOCIAL_NETWORK]: "La marca no tiene una red social asociada.",
  [NO_VALID_PLATFORM_FOUND]: "No se encontró una plataforma válida.",
  [GETTING_STATISTICS]:
    "Error obteniendo estadísticas, por favor intenta de nuevo más tarde o contacta soporte.",
  [SHOP_BUSINESS_NAME_NOT_FOUND]: "No se encontró el nombre de la marca.",
  [INFLUENCERS_SUGGESTED_NOT_FOUND]: "No se encontró creadores sugeridos.",
  [REFERENCE_PROFILES_REQUIRED]: "No se encontró perfiles de referencia.",
  [INFLUENCERS_SIMILAR_NOT_FOUND]: "No se encontró perfiles de referencia.",
  [DECRYPTING_PASSWORD]: "Error obteniendo contraseña.",
  [USER_ID_AND_PAYMENT_ID]: "El UserID y paymentID son requeridos.",
  [MESSAGES_REQUIRED]: "Los mensajes requeridos.",
  [PLAN_IS_NO_ACTIVE]: "El plan no está activo.",
  [USER_BANNED]: "Este usuario está baneado.",
  [GETTING_LOCALES]:
    "Error obteniendo archivos locales, por favor intenta de nuevo más tarde o contacta soporte.",
  [GETTING_PROFILE]:
    "Error obteniendo los datos del perfil, por favor intenta de nuevo más tarde o contacta soporte.",
  [PRODUCTS_NOT_SENT]: "No se enviaron los productos.",
  [INVITATION_NOT_FOUND]: "No se encontró la invitación",
  [TOTAL_PRICE_EXCEEDED_VALUE_INVITATION]:
    "El precio total excede el valor de la invitación.",
  [ACCOUNT_NOT_FOUND]: "No se encontró la cuenta.",
  [COLLECTING_INFORMATION_RETRY_AFTER]:
    "Error recolectando la información, por favor intenta de nuevo más tarde o contacta soporte.",
  [ERROR_OCURRED]:
    "Ocurrió un error inesperado, por favor intente de nuevo más tarde",
  [PLATFORM_NOT_SENT]: "No se envió la plataforma.",
  [VERIFICATION_CODE_NOT_FOUND]: "No se encontró el código de verificación.",
  [ORDER_NOT_FOUND]: "No se encontró la orden.",
  [INVITE_ID_NO_SENT]: "No se envió el inviteID.",
  [INVITE_NOT_FOUND]: "No se encontró la invitación.",
  [INVITE_CLOUD_TASK_ID_NOT_FOUND]:
    "No se encontró el cloudTaskID de la invitación.",
  [TYPE_OR_PATH_NOT_SENT]: "El archivo o la ruta no se enviaron.",
  [ID_OR_PATH_NOT_SENT]: "El ID del archivo o la ruta no se enviaron.",
  [IDS_NOT_SENT]: "Los IDs no se enviaron",
  [ANALYTIC_URL_NOT_SENT]: "La URL de la analítica no se envió.",
  [ANALYTIC_URL_INVALID]: "La URL de la analítica es inválida.",
  [EXTRACTING_ANALYTICS]:
    "Error extrayendo la analítica, por favor intenta de nuevo más tarde o contacta soporte.",
  [CREDENTIALS_INVALID]: "Credenciales inválidas.",
  [COLLABORATION_PRICE_NOT_BE_NEGATIVE]:
    "El precio de la colaboración no puede ser negativo.",
  [CREDITS_NOT_BE_NEGATIVE]: "Los créditos no pueden ser negativos.",
  [PACKAGES_PRICES_NOT_BE_NEGATIVE]:
    "El precio de los planes no puede ser negativo.",
  [ROL_REQUIRED]: "El rol es requerido.",
  [SHOP_ID_AND_STORE_ID_REQUIRED]: "ShopID y storeID son requeridos.",
  [PASSWORD_MUST_BE_6_CHARACTERS]:
    "La contraseña debe tener al menos 6 caracteres.",
  [ALL_USERS_NOT_DELETE]:
    "No se pueden eliminar todos los usuarios, debe quedar al menos uno.",
  [SHOP_APPLICANT_NOT_FOUND]: "No se encontró la aplicación de la marca.",
  [USER_PASSWORD_NOT_FOUND]: "No se encontró la contraseña del usuario.",
  [PLAN_NOT_FOUND]: "No se encontró el plan.",
  [SUBSCRIPTION_NOT_CREATED]: "No se creó la suscripción.",
  [ID_SUBSCRIPTION_NOT_FOUND]: "El ID de la suscripción no se encontró.",
  [SUBSCRIPTION_REACTIVATE]: "Suscripción reactivada.",
  [CUSTOMER_ID_NOT_FOUND]: "No se encontró el customerID.",
  [APPLICANT_NOT_FOUND]: "No sé encontró el aplicante.",
  [SHOP_COUNTRY_NOT_FOUND]: "No sé encontró el país de la marca.",
  [FINAL_PRICE_NOT_FOUND]: "No se pudo obtener el precio final.",
  [PAYMENT_FAILED]: "Pago fallido",
  [CREDITS_MUST_BE_GREATER_THAN_ZERO]: "Los créditos deben ser mayores a 0.",
  [AMBASSADOR_NOT_FOUND]: "No se encontró el embajador.",
  [CSRF_INVALID]: "CSRF inválido",
  [INTEGRATION_INFORMATION_NOT_FOUND]:
    "No se encontró información de la integración.",
  [GETTING_VIDEO]: "Error obteniendo el vídeo.",
  [UPLOAD_URL_NOT_FOUND]: "No se encontró la URL de subida.",
  [UPLOADING_VIDEO]: "Error subiendo el vídeo.",
  [STORE_NOT_FOUND]: "No se encontró la tienda.",
  [NAME_REQUIRED]: "El nombre es requerido.",
  [PRICE_REQUIRED]: "El precio es requerido.",
  [CURRENCY_REQUIRED]: "La moneda es requerida.",
  [RECURRING_REQUIRED]: "La recurrencia es requerida.",
  [CREATING_PRODUCT]: "Error creando el producto.",
  [CREATING_PRICE]: "Error creado el precio.",
  [CREATING_PAYMENT_LINK]: "Error creando el link de pago.",
  [GETTING_PAYMENT_LINK]: "Error obteniendo el link de pago.",
  [TOKEN_INVALID]: "Token invalido",
  [TOKEN_EXPIRED]: "El token expiró",
  [IG_ACCOUNT_NOT_FOUND]:
    "No se encontró una cuenta de Instagram vinculada a una página de Facebook. Por favor, asegurate de haber seleccionado una cuenta de Instagram durante la integración o vincula una e intenta de nuevo.",
  [IG_DATA_NOT_FOUND]: "Información de Instagram no encontrada",
  [REVIEW_NOT_FOUND]: "No se encontró la review para esta colaboración",
  [REVIEW_IS_NOT_ONE_START]:
    "La review de esta colaboración no es igual a 1 estrella, no se te puede dar un crédito",
  [DISSATISFACTION_CREDIT_ALREADY_EXISTS]:
    "Ya se ha asignado un crédito gratis para esta colaboración",
  [META_INTEGRATION_UNLINKED_PAGES_ERROR]:
    "Es posible que no tengas vinculada tu cuenta de instagram a tu página de Facebook o Meta Business. Por favor vincula tus cuentas para poder continuar.",
  [PAYMENT_METHOD_NOT_FOUND]:
    "No tienes un método de pago agregado, por favor agrega uno para continuar.",
  [HAS_PENDING_PAYMENTS]:
    "Tienes facturas pendientes, por favor realiza el pago para continuar.",
  [NO_IMAGES_FOUND_ON_PAGE]: "No se encontraron imágenes en la página",
  [NO_CREATE_DISCOUNT_SHOPIFY]:
    "Obtuvimos un error por parte de Shopify durante la integración, asegúrate de haber seleccionado los alcances 'read_price_rules', 'write_price_rules', 'write_discounts' y 'read_discounts' durante los pasos de la integración.",
  [NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY]:
    "Obtuvimos un error por parte de Shopify durante la integración, asegúrate de haber seleccionado el alcance 'read_orders' durante los pasos de la integración.",
  [NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY]:
    "Obtuvimos un error por parte de Shopify durante la integración, asegúrate de haber seleccionado el alcance 'read_fulfillments' durante los pasos de la integración.",
  [NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY]:
    "Obtuvimos un error por parte de Shopify durante la integración, asegúrate de haber seleccionado el alcance 'read_fulfillments' durante los pasos de la integración.",
  [GETTING_INFORMATION_FROM_SHOPIFY_URL]:
    "Error obteniendo la configuración de Shopify, por favor asegúrate de que la URL sea correcta y que el acceso a la tienda sea publico.",
  [RATE_DAILY_LIMIT_AI]:
    "Has alcanzado el límite diario de uso de funcionalidades de inteligencia artificial. Por favor, inténtalo de nuevo mañana.",
  [RATE_DAILY_LIMIT_AI_CHAT]:
    "Has alcanzado el límite de Buzzly Help por hoy. Por favor, inténtalo de nuevo mañana.",
  [PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META]:
    "Los permisos o la configuración de su cuenta cambiaron, inténtelo de nuevo. Si el error persiste, desconecte y vuelva a conectar la cuenta.",
  [ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS]:
    "Tu cuenta de instagram no es del tipo Business o no seleccionaste todos los permisos, por favor cambia tu cuenta a business e intenta de nuevo. Si el error persiste desconecta y conecta de nuevo la cuenta.",
  [FACEBOOK_PAGE_ID_NOT_FOUND]:
    "No se encontró una página de Facebook vinculada. Por favor, asegúrate de haber seleccionado una página durante la integración o vincula una e intenta de nuevo.",
  [META_BUSINESS_ACCOUNT_NOT_FOUND]:
    "No se encontró una cuenta de negocio vinculada. Por favor, asegúrate de haber seleccionado una cuenta de negocio durante la integración o vincula una e intenta de nuevo.",
  [IG_BUSINESS_ACCOUNT_NOT_FOUND]:
    "No se encontró una cuenta de Instagram vinculada tu cuenta general de Meta. Por favor, asegúrate de haber seleccionado una cuenta de Instagram durante la integración o vincula una e intenta de nuevo.",
  [ACCOUNT_TIKTOK_AUTH_NOT_MATCH]:
    "No pudimos verificar tu cuenta, por favor inténtalo de nuevo.",
  [ACCOUNT_TOKEN_META_NOT_FOUND]:
    "No pudimos encontrar la información de tu integración con Meta, intenta de nuevo o reconecta tu cuenta.",
  [ACCOUNT_ID_BUSINESS_META_NOT_FOUND]:
    "No encontramos una cuenta Business de Meta vinculada a tu cuenta, por favor intenta de nuevo o vincula una cuenta Business.",
  [OBJECTIVE_CAMPAIGN_META_INVALID]:
    "El objetivo de la campaña seleccionado no es válido.",
  [SPECIAL_CATEGORY_CAMPAIGN_META_INVALID]:
    "El tipo de categoría especial seleccionado no es válido.",
  [CAMPAIGN_META_NOT_DELETED]:
    "No se pudo eliminar la campaña, intenta de nuevo.",
  [CAMPAIGN_META_NOT_CREATED]: "No se pudo crear la campaña, intenta de nuevo.",
  [ADS_CAMPAIGN_NOT_FOUND]: "No se encontró la campaña de anuncios.",
  [NO_PIXELS_FOUND]:
    "No se encontraron píxeles asociados a tu cuenta de Meta, por favor crea uno e inténtalo de nuevo.",
  [NO_PIXELS_ACTIVE]:
    "No tienes un píxel activo asociado a tu cuenta de Meta, por favor activa uno e inténtalo de nuevo.",
  [CAMPAIGN_META_NOT_FOUND]: "No se encontró la campaña en Meta.",
  [CAMPAIGN_META_NOT_UPDATED]:
    "No se pudo actualizar la campaña, intenta de nuevo.",
  [CAMPAIGN_AD_SET_META_NOT_CREATED]:
    "No se pudo crear el conjunto de anuncios, intenta de nuevo.",
  [CAMPAIGN_AD_SET_META_NOT_UPDATED]:
    "No se pudo actualizar el conjunto de anuncios, intenta de nuevo.",
  [CAMPAIGN_MEDIA_META_NOT_CREATED]:
    "No se pudo subir el vídeo de la campaña, intenta de nuevo.",
  [CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND]:
    "No se encontró la miniatura del vídeo de la campaña.",
  [CAMPAIGN_CREATIVE_AD_META_NOT_CREATED]:
    "No se puedo crear la plantilla creativa del anuncio, intenta de nuevo.",
  [CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED]:
    "No se pudo actualizar la plantilla creativa del anuncio, intenta de nuevo.",
  [CAMPAIGN_CREATIVE_AD_META_NOT_FOUND]:
    "No se encontró la plantilla creativa del anuncio.",
  [CAMPAIGN_AD_META_NOT_CREATED]:
    "No se pudo crear el anuncio, intenta de nuevo.",
  [CAMPAIGN_AD_META_NOT_UPDATED]:
    "No se pudo actualizar el anuncio, intenta de nuevo.",
  [CAMPAIGN_METRICS_META_NOT_FOUND]:
    "No se encontraron las métricas de la campaña.",
  [CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE]:
    "No tienes saldo suficiente para crear una campaña, por favor recarga tu cuenta y vuelve a intentarlo.",
  [ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE]:
    "No tienes saldo suficiente para activar la campaña, por favor recarga tu cuenta y vuelve a intentarlo.",
  [CAMPAIGN_AUDIENCE_IS_TOO_NARROW]:
    "Tu audiencia objetivo es muy pequeña, intenta cambiar tus criterios de segmentación para alcanzar una audiencia más ampliar.",
  [CAMPAIGN_BUDGET_IS_TOO_LOW]: "Tu presupuesto es muy bajo.",
  [IDENTITY_NOT_FOUND]:
    "No encontramos un perfil de TikTok vinculado a tu cuenta de TikTok Business, por favor vincula un perfil e intenta de nuevo.",
  [VIDEO_ERROR_LOW_RESOLUTION]:
    "El video tiene una resolución muy baja, intenta subir un video con una resolución mayor.",
  [VIDEO_ERROR_NO_BACKGROUND_AUDIO]:
    "El vídeo no tiene audio o el audio es muy bajo, intenta subir un vídeo con un audio claro y audible.",
  [VIDEO_ERROR_ASPECT_RATIO]:
    "El video no tiene un aspecto permitido, intenta subir un vídeo con un aspecto de 1:1 (cuadrado), 9:16 (vertical) o 16:9(horizontal).",
  [VIDEO_ERROR_BLACK_EDGE]:
    "El vídeo tiene franjas negras o bordes, intenta subir un vídeo sin bordes.",
  [VIDEO_ERROR_ILLEGAL_DURATION]:
    "El video tiene una duración no permitida, intenta subir un video con una duración entre 5 y 60 segundos.",
  [INTEGRATION_CURRENCY_NOT_FOUND]:
    "No se encontró la moneda de la integración.",
  [USER_EMAIL_OR_PASSWORD_WRONG]: "Email o contraseña incorrectos",
  [USER_NOT_FOUND]: "No se encontró un usuario con este email",
  [ERROR_NETWORK]:
    "Ocurrió un error de red, por favor verifica tu conexión a internet e intenta de nuevo",

  //ERROR COMPONENT
  [ERROR_COMPONENT_TITLE]: "¡Oops! Parece que ocurrió un error",
  [ERROR_COMPONENT_DESCRIPTION]:
    "Esto puede ser causado por un error técnico. Intenta de nuevo recargando la página, si el error persiste contacta a soporte.",
  [ERROR_COMPONENT_BUTTON_RELOAD_PAGE]: "Recargar página",
  [ERROR_COMPONENT_CURRENT_ROUTE]: "Ruta actual {{value}}",

  //CATEGORIES
  [RESTAURANTS]: "Gastronomía",
  [FOODS]: "Alimentos",
  [TRAVEL]: "Viajes y Turismo",
  [ENTERTAINMENT]: "Entretenimiento",
  [TECHNOLOGY]: "Tecnología",
  [FITNESS]: "Fitness",
  [FASHION]: "Moda",
  [BEAUTY]: "Salud y Belleza",
  [PETS]: "Pets",
  [HOME]: "Home Decor",

  //SUBCATEGORIES
  [RESTAURANTS_SUBCATEGORY_FOOD]: "Comida",
  [RESTAURANTS_SUBCATEGORY_DRINKS]: "Bebidas",
  [RESTAURANTS_SUBCATEGORY_DESSERTS]: "Postres",
  [RESTAURANTS_SUBCATEGORY_COFFEE]: "Café",
  [RESTAURANTS_SUBCATEGORY_HOT_DRINKS]: "Bebidas calientes",
  [RESTAURANTS_SUBCATEGORY_COLD_DRINKS]: "Bebidas frías",
  [RESTAURANTS_SUBCATEGORY_SOFT_DRINKS]: "Bebidas gaseosas",
  [RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS]: "Bebidas congeladas",
  [RESTAURANTS_SUBCATEGORY_SUSHI]: "Sushi",
  [RESTAURANTS_SUBCATEGORY_ASIAN]: "Asiatica",
  [RESTAURANTS_SUBCATEGORY_JAPANESE]: "Japonesa",
  [RESTAURANTS_SUBCATEGORY_CHINESE]: "China",
  [RESTAURANTS_SUBCATEGORY_ITALIAN]: "Italiana",
  [RESTAURANTS_SUBCATEGORY_MEXICAN]: "Mexicana",
  [RESTAURANTS_SUBCATEGORY_HEALTHY]: "Saludable",
  [RESTAURANTS_SUBCATEGORY_VEGAN]: "Vegana",
  [RESTAURANTS_SUBCATEGORY_VEGETARIAN]: "Vegetariana",
  [FOODS_SUBCATEGORY_SEASONINGS]: "Condimentos",
  [FOODS_SUBCATEGORY_FOOD]: "Alimentos",
  [FOODS_SUBCATEGORY_GRANOLA]: "Granolas",
  [FOODS_SUBCATEGORY_DRIED_FRUITS]: "Frutos deshidratados",
  [FOODS_SUBCATEGORY_HEALTHY]: "Healthy",
  [TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM]: "Turismo de sol y playa",
  [TRAVEL_SUBCATEGORY_RURAL_TOURISM]: "Turismo rural",
  [TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM]: "Turismo gastronómico",
  [TRAVEL_SUBCATEGORY_NATURE_TOURISM]: "Turismo de naturaleza",
  [TRAVEL_SUBCATEGORY_CULTURAL_TOURISM]: "Turismo cultural",
  [TRAVEL_SUBCATEGORY_HEALTH_TOURISM]: "Turismo de salud",
  [ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT]: "Entretenimiento",
  [TECHNOLOGY_SUBCATEGORY_PHONES]: "Celulares",
  [TECHNOLOGY_SUBCATEGORY_COMPUTERS]: "Computadores",
  [TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES]: "Consolas de Videojuegos",
  [TECHNOLOGY_SUBCATEGORY_ACCESSORIES]: "Accesorios",
  [TECHNOLOGY_SUBCATEGORY_PERIPHERALS]: "Perifericos",
  [TECHNOLOGY_SUBCATEGORY_ELECTRONICS]: "Electrónica",
  [FITNESS_SUBCATEGORY_SPORTSWEAR]: "Ropa deportiva",
  [FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES]: "Accesorios deportivos",
  [FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS]: "Suplementos alimenticios",
  [FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT]: "Equipos de entrenamiento",
  [FASHION_SUBCATEGORY_HAUTE_COUTURE]: "Moda de alta costura",
  [FASHION_SUBCATEGORY_HIPSTER]: "Moda hipster",
  [FASHION_SUBCATEGORY_CASUAL]: "Moda casual",
  [FASHION_SUBCATEGORY_CLASSIC]: "Moda clásica",
  [FASHION_SUBCATEGORY_ROMANTIC]: "Moda romántica",
  [FASHION_SUBCATEGORY_SPORTSWEAR]: "Moda deportiva",
  [FASHION_SUBCATEGORY_PREPPY]: "Moda preppy",
  [FASHION_SUBCATEGORY_GRUNGE]: "Moda grunge",
  [FASHION_SUBCATEGORY_VINTAGE]: "Moda vintage",
  [BEAUTY_SUBCATEGORY_COSMETICS]: "Cosméticos",
  [BEAUTY_SUBCATEGORY_EYE_CARE]: "Cuidado de los ojos",
  [BEAUTY_SUBCATEGORY_FEMININE_HYGIENE]: "Higiene femenina",
  [BEAUTY_SUBCATEGORY_FIRST_AID]: "Primeros auxilios",
  [BEAUTY_SUBCATEGORY_HAIR_CARE]: "Cuidado del cabello",
  [BEAUTY_SUBCATEGORY_MEDICAL_DEVICES]: "Dispositivos y aparatos médicos",
  [BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT]: "Equipo para movilidad",
  [BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS]: "Suplementos nutricionales",
  [BEAUTY_SUBCATEGORY_MEDICATIONS]: "Medicamentos",
  [BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE]: "Higiene personal",
  [BEAUTY_SUBCATEGORY_SCALES]: "Balanzas",
  [BEAUTY_SUBCATEGORY_SKIN_CARE]: "Cuidado de la piel",
  [BEAUTY_SUBCATEGORY_VITAMINS]: "Vitaminas",
  [BEAUTY_SUBCATEGORY_FACIAL_TISSUES]: "Pañuelos faciales",
  [PETS_SUBCATEGORY_FOOD]: "Comida",
  [PETS_SUBCATEGORY_COSMETICS]: "Cosméticos",
  [PETS_SUBCATEGORY_ACCESSORIES]: "Accesorios",
  [PETS_SUBCATEGORY_CLOTHING]: "Ropa",
  [PETS_SUBCATEGORY_MEDICATIONS]: "Medicamentos",
  [HOME_SUBCATEGORY_TEXTILES_CARPETS]: "Textiles y Alfombras",
  [HOME_SUBCATEGORY_ART_PRINTS]: "Arte y Grabados",
  [HOME_SUBCATEGORY_STORAGE_ORGANIZATION]: "Almacenamiento y Organización",
  [HOME_SUBCATEGORY_PLANT_DECOR]: "Decoraciones con Plantas",
  [HOME_SUBCATEGORY_CURTAINS_BLINDS]: "Cortinas y Persianas",

  //OPTIONS
  [SEARCH_INFLUENCERS_TOPICS]: "tema",
  [SEARCH_INFLUENCERS_USERNAME]: "usuario",

  [BODY_BUILD_THIN]: "Delgado",
  [BODY_BUILD_AVERAGE]: "Promedio",
  [BODY_BUILD_WIDE]: "Ancho",
  [BODY_BUILD_MUSCULAR]: "Musculoso",
  [BODY_BUILD_CURVY]: "Curvilíneo",
  [BODY_BUILD_ATHLETIC]: "Atlético",

  [CLOTHING_STYLE_CASUAL]: "Casual",
  [CLOTHING_STYLE_FORMAL]: "Formal",
  [CLOTHING_STYLE_SPORTY]: "Deportivo",
  [CLOTHING_STYLE_BUSINESS]: "Negocios",
  [CLOTHING_STYLE_ELEGANT]: "Elegante",
  [CLOTHING_STYLE_TRENDY]: "De moda",
  [CLOTHING_STYLE_STREETWEAR]: "Úrbano",
  [CLOTHING_STYLE_TRADITIONAL]: "Tradicional",
  [CLOTHING_STYLE_VINTAGE]: "Vintage",
  [CLOTHING_STYLE_BOHEMIAN]: "Bohemio",
  [CLOTHING_STYLE_HIPSTER]: "Hipster",
  [CLOTHING_STYLE_PUNK]: "Punk",
  [CLOTHING_STYLE_GOTHIC]: "Gótico",

  [DISTINGUISHING_FEATURES_TATTOOS]: "Tatuajes",
  [DISTINGUISHING_FEATURES_SCARS]: "Cicatrices",
  [DISTINGUISHING_FEATURES_BIRTHMARKS]: "Manchas de nacimiento",
  [DISTINGUISHING_FEATURES_FRECKLES]: "Pecas",
  [DISTINGUISHING_FEATURES_MOLES]: "Lunares",
  [DISTINGUISHING_FEATURES_PIERCINGS]: "Piercings",
  [DISTINGUISHING_FEATURES_DIMPLES]: "Hoyuelos",
  [DISTINGUISHING_FEATURES_WRINKLES]: "Arrugas",
  [DISTINGUISHING_FEATURES_GLASSES]: "Gafas",
  [DISTINGUISHING_FEATURES_BRACES]: "Frenillos",
  [DISTINGUISHING_FEATURES_MAKEUP]: "Maquillaje",
  [DISTINGUISHING_FEATURES_HAIR_ACCESSORIES]: "Accesorios para el cabello",
  [DISTINGUISHING_FEATURES_BEARD]: "Barba",
  [DISTINGUISHING_FEATURES_MUSTACHE]: "Bigote",

  [CONTENT_STYLE_ELEGANT]: "Elegante",
  [CONTENT_STYLE_AESTHETIC]: "Estético",
  [CONTENT_STYLE_RAW]: "Crudo",
  [CONTENT_STYLE_MINIMALISTIC]: "Minimalista",
  [CONTENT_STYLE_ARTISTIC]: "Artístico",
  [CONTENT_STYLE_VINTAGE]: "Vintage",
  [CONTENT_STYLE_MODERN]: "Moderno",
  [CONTENT_STYLE_PLAYFUL]: "Juguetón",
  [CONTENT_STYLE_RUSTIC]: "Rústico",
  [CONTENT_STYLE_MONOCHROMATIC]: "Monocromático",

  [EMPLOYEES_2_10]: "2-10 Empleados",
  [EMPLOYEES_10_20]: "10-20 Empleados",
  [EMPLOYEES_20_50]: "20-50 Empleados",
  [EMPLOYEES_50_100]: "50-100 Empleados",
  [EMPLOYEES_100_THAN]: "100+ Empleados",

  [PRODUCT_AVAILABILITY_LOCAL]: "Local (Ciudad)",
  [PRODUCT_AVAILABILITY_NATIONAL]: "Nacional",
  [PRODUCT_AVAILABILITY_INTERNATIONAL]: "Internacional",

  [META]: "Meta",
  [META_FACEBOOK_AND_INSTAGRAM]: "Meta (Facebook e Instagram)",
  [INSTAGRAM]: "Instagram",
  [TIKTOK]: "TikTok",
  [LINKEDIN]: "LinkedIn",
  [GOOGLE]: "Google",
  [DARK_KITCHEN]: "Cocinas ocultas",
  [OTHERS]: "Otros",

  [ADS_CAMPAIGN_OBJECTIVE_AWARENESS]: "Reconocimiento",
  [ADS_CAMPAIGN_OBJECTIVE_TRAFFIC]: "Tráfico",
  [ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT]: "Interacción",
  [ADS_CAMPAIGN_OBJECTIVE_LEADS]: "Clientes potenciales",
  [ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION]: "Promoción de una aplicación",
  [ADS_CAMPAIGN_OBJECTIVE_SALES]: "Ventas",
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY]: "Presupuesto diario",
  [ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME]: "Presupuesto total",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU]: "Ver menú",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW]: "Comprar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP]: "Registrarse",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE]: "Suscribirse",
  [ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE]: "Ver más",
  [ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW]: "Aplicar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW]: "Reservar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW]: "Llamar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US]: "Contáctanos",
  [ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD]: "Descargar",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS]: "Obtener direcciones",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE]: "Obtener cotización",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES]: "Obtener horarios",
  [ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE]: "Saber más",
  [ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW]: "Escuchar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE]: "Enviar mensaje",
  [ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW]: "Ordenar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME]: "Solicitar tiempo",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SAVE]: "Guardar",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER]: "Obtener oferta",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS]: "Obtener promociones",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS]: "Obtener acceso",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES]: "Obtener actualizaciones",
  [ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW]: "Consultar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS]: "Comprar boletos",
  [ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME]: "Jugar",
  [ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE]: "Explorar más",
  [ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW]: "Instalar ahora",
  [ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP]: "Usar aplicación",

  [LESS_500K]: "Menos de $500.000",
  [BETWEEN_500K_1M]: "$500.000 - $1.000.000",
  [BETWEEN_1M_2_5M]: "$1.000.000 - $2.500.000",
  [BETWEEN_2_5M_5M]: "$2.500.000 - $5.000.000",
  [MORE_5M]: "Más de $5.000.000",
  [LESS_1M]: "Menos de $1.000.000",
  [BETWEEN_1M_5M]: "$1.000.000 - $5.000.000",
  [BETWEEN_5M_15M]: "$5.000.000 - $15.000.000",
  [BETWEEN_15M_30M]: "$15.000.000 - $30.000.000",
  [BETWEEN_30M_100M]: "$30.000.000 - $100.000.000",
  [MORE_100M]: "Más de $100.000.000",
  [LESS_150]: "Menos de $150",
  [BETWEEN_150_250]: "$150 - $250",
  [BETWEEN_250_650]: "$250 - $650",
  [BETWEEN_650_1250]: "$650 - $1.250",
  [MORE_1250]: "Más de $1.250",
  [LESS_250]: "Menos de $250",
  [BETWEEN_250_1250]: "$250 - $1.250",
  [BETWEEN_1250_3750]: "$1.250 - $3.750",
  [BETWEEN_3750_7500]: "$3.750 - $7.500",
  [BETWEEN_7500_25K]: "$7.500 - $25.000",
  [MORE_25K]: "Más de $25.000",
  [LESS_1000]: "Menos de $1.000",
  [BETWEEN_1000_5000]: "$1.000 - $5.000",
  [BETWEEN_5000_15K]: "$5.000 - $15.000",
  [BETWEEN_15K_30K]: "$15.000 - $30.000",
  [BETWEEN_30K_60K]: "$30.000 - $60.000",
  [MORE_60K]: "Más de $60.000",
  [BETWEEN_1000_2000]: "$1.000 - $2.000",
  [BETWEEN_2000_5000]: "$2.000 - $5.000",
  [BETWEEN_5000_10K]: "$5.000 - $10.000",
  [MORE_10K]: "Más de $10.000",

  [DOCUMENT_CC]: "Cédula de ciudadanía",
  [DOCUMENT_CE]: "Cédula de extranjería",
  [DOCUMENT_NUIP]: "NUIP",
  [DOCUMENT_NIT]: "NIT",
  [DOCUMENT_CI]: "Cédula de identidad",
  [DOCUMENT_PASSPORT]: "Pasaporte",
  [DOCUMENT_EIN]: "EIN",
  [DOCUMENT_RUC]: "RUC",
  [DOCUMENT_ID]: "ID",
  [DOCUMENT_ID_EMIRATES]: "ID de Emiratos",
  [DOCUMENT_TRN]: "TRN",

  //EXPLANATIONS TOOLTIP
  [ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION]:
    "¿Qué es el objetivo de reconocimiento?",
  [ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION]: `Los anuncios se mostrarán a personas que tienen más probabilidades de recordarlos. Este objetivo es ideal para aumentar la notoriedad de tu marca o producto.<br><br>
    Bueno para:<br>
    <li><strong>Alcance:</strong> Alcanza a personas que tienen más probabilidades de que les interese tu marca.</li>
    <li><strong>Conciencia de marca:</strong> Alcanza a personas que tienen más probabilidades de recordar tu marca.</li>
    <li><strong>Vistas de vídeo:</strong> Alcanza a personas que tienen más probabilidades de ver tu video.</li>
    <li><strong>Conocimiento de la ubicación de tu tienda:</strong> Alcanza a personas utilizando la ubicación física de tu negocio, organización o conjunto de tiendas.</li>
    `,
  [ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION]:
    "¿Qué es el objetivo de tráfico?",
  [ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION]: `Los anuncios se mostrarán a personas que tienen más probabilidades de hacer clic en ellos. Este objetivo es ideal para aumentar el tráfico a tu sitio web o aplicación.<br><br>
    Bueno para:<br>
    <li><strong>Link clicks:</strong> Consigue que las personas hagan clic en un enlace a tu sitio web, aplicación o evento de Facebook.</li>
    <li><strong>Visitas a página:</strong> Consigue que las personas vean el contenido de tu página.</li>
    <li><strong>Visitas a perfil de Instagram:</strong> Consigue que las personas visiten tu perfil de Instagram.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION]:
    "¿Qué es el objetivo de interacciones?",
  [ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION]: `Los anuncios se mostrarán a personas que tienen más probabilidades de interactuar con ellos. Este objetivo es ideal para aumentar los comentarios, me gusta, compartidos y clics en tu anuncio.<br><br>
    Bueno para:<br>
    <li><strong>Vistas de vídeo:</strong> Alcanza a personas que tienen más probabilidades de ver tu video.</li>
    <li><strong>Engagement en publicación:</strong> Consigue que las personas interactúen con tus publicaciones.</li>
    <li><strong>Conversiones:</strong> Consigue que las personas vean contenido, contacten tu negocio, añadan a una lista de deseos o realicen otra acción en tu sitio web o aplicación.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION]:
    "¿Qué es el objetivo de clientes potenciales?",
  [ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION]: `Los anuncios se mostrarán a personas que tienen más probabilidades de proporcionar información de contacto. Este objetivo es ideal para aumentar los registros de clientes potenciales.<br><br>
    Bueno para:<br>
    <li><strong>Formularios instantaneos:</strong> Encuentra personas dispuestas a compartir su información de contacto y otros detalles completando un formulario.</li>
    <li><strong>Conversiones:</strong> Consigue que las personas compartan su información de contacto, completen un formulario de registro, envíen una solicitud o realicen otra acción en tu sitio web o aplicación.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION]:
    "¿Qué es el objetivo de promoción de aplicaciones?",
  [ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION]: `Los anuncios se mostrarán a personas que tienen más probabilidades de descargar tu aplicación. Este objetivo es ideal para aumentar las descargas de tu aplicación.<br><br>
    Bueno para:<br>
    <li><strong>Instalación de aplicaciones:</strong> Consigue que las personas descarguen e instalen tu aplicación.</li>
    <li><strong>Eventos de aplicaciones:</strong> Conseguir que los usuarios realicen compras dentro de la aplicación o realicen otras acciones dentro de tu aplicación.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION]:
    "¿Qué es el objetivo de ventas?",
  [ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION]: `Los anuncios se mostrarán a personas que tienen más probabilidades de realizar una compra. Este objetivo es ideal para aumentar las ventas de tu producto o servicio.<br><br>
    Bueno para:<br>
    <li><strong>Conversiones:</strong> Consigue que las personas añadan un artículo a su carrito, realicen una compra, inicien una suscripción o realicen otra acción en tu sitio web o aplicación.</li>
    <li><strong>Ventas por catálogo:</strong> Consigue que las personas compren productos o servicios de tu catálogo.</li>`,
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION]:
    "¿Que significa un tipo de presupuesto diario?",
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION]:
    "El presupuesto diario es la cantidad máxima que estás dispuesto a gastar por día en tu campaña. La red social intentará no superar este límite, pero puede gastar un poco más en días en los que haya más oportunidades para mostrar tus anuncios.",
  [ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION]:
    "¿Qué significa un tipo de presupuesto de total?",
  [ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION]:
    "El presupuesto total es la cantidad máxima que estás dispuesto a gastar en toda tu campaña. La red social intentará no superar este límite, pero puede gastar un poco más en días en los que haya más oportunidades para mostrar tus anuncios.",

  //ONBOARDING

  //MAIN FUNCTIONALITY
  [MAIN_FUNCTIONALITY_STEP_1]: `Esta es la sección de Creadores de Contenido, aquí encontrarás el listado de creadores que están registrados en {{NAME_BUSINESS}} y que están a disposición de colaborar con tu marca.`,
  [MAIN_FUNCTIONALITY_STEP_2]:
    "Con los filtros podrás buscar creadores de contenido por su nombre de cuenta, categorías, ciudad, número de seguidores, porcentaje de engagement de su audiencia, etc.",
  [MAIN_FUNCTIONALITY_STEP_3]: `Tienes tres secciones para buscar creadores de contenido: <br/><br/>
    <strong>Creadores:</strong> Este es el listado general de todos los creadores registrados en {{NAME_BUSINESS}}.<br/>
    <strong>Para ti:</strong> Este es el listado de los creadores que coinciden con el perfil de creador que te interesa.<br/>
    <strong>Solicitudes:</strong> Este es el listado de los creadores que están interesados en tu marca y te envían una solicitud de colaboración.
    `,
  [MAIN_FUNCTIONALITY_STEP_4]:
    "Esta es la información general del creador, puedes darle click a cada tarjeta para acceder a una información más completa. Dale click a la tarjeta.",
  [MODAL_INVITATION_STEP_1]: `Esta es la información del creador de contenido, podrás ver su red social, audiencia, engagement y las puntuaciones generales que tiene en {{NAME_BUSINESS}}.`,
  [MODAL_INVITATION_STEP_2]:
    "Si quieres ver una información más completa y detallada debes darle click al botón Ver reporte.",
  [MODAL_INVITATION_STEP_3]:
    "Estas son las acciones que puedes realizar con el creador.",
  [MODAL_INVITATION_STEP_4]:
    "<strong>Invitar a campaña:</strong> podrás invitar a un creador a participar en una campaña paga activa que hayas creado, sino tienes una podrás crearla.",
  [MODAL_INVITATION_STEP_5]:
    "<strong>Invitar a canje:</strong> podrías invitar a un creador a colaborar con tu marca a través del canje de tus productos o servicios.",
  [MODAL_INVITATION_STEP_6]: `<strong>Enviar pago:</strong> podrás enviarle un pago al creador de manera segura y fácil.`,
  [MODAL_INVITATION_STEP_7]: `<strong>Chatear ahora:</strong> podrás enviarle un mensaje al creador directamente desde la plataforma.`,
  [PUBLICATIONS_FUNCTIONALITY_STEP_1]:
    "Una vez los creadores rediman sus invitaciones se generarán publicaciones, para ir a la sección publicaciones, dale click en el menú lateral al botón de Publicaciones.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_2]:
    "Las publicaciones pueden tener multiples estados, el primero es Publicación Pendiente, es cuando el creador redimió su invitación pero aún no publicado el contenido que generó.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_3]:
    "El segundo es Analítica Pendiente, es cuando el creador ya publicó el contenido que generó pero aún no publicado la analítica para ver los resultados de la colaboración.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_4]:
    "El tercero es Calificar, es cuando el creador ya publicó el contenido que generó y la analítica, ahora debes revisarlos, darle una calificación al creador y pensar si quieres hacerlo embajador. Dale click al botón Calificar",
  [PUBLICATION_FUNCTIONALITY_STEP_1]:
    "Aquí verás las analíticas y los resultados generados por el contenido del creador de contenido.",
  [PUBLICATION_FUNCTIONALITY_STEP_2]:
    "Aquí verás todo el contenido que el creador de contenido generó para tu marca.",
  [PUBLICATION_FUNCTIONALITY_STEP_3]:
    "Este es el botón para calificar el creador de contenido, dale click al botón Calificar.",
  [REVIEW_FUNCTIONALITY_STEP_1]:
    "Este es el formulario para calificar al creador de contenido, la información de tu calificación no será compartida con el creador.",
  [REVIEW_FUNCTIONALITY_STEP_2]:
    "Puedes calificar el contenido del creador de contenido, en un intervalo de 1 a 5 qué tan bueno fue el contenido generado.",
  [REVIEW_FUNCTIONALITY_STEP_3]:
    "Puedes calificar la analítica del creador de contenido, en un intervalo de 1 a 5 qué tan bueno fue el resultado generado.",
  [REVIEW_FUNCTIONALITY_STEP_4]:
    "Describir en texto como fue la experiencia en general con la colaboración, este texto puede tener mínimo 10 caracteres.",
  [REVIEW_FUNCTIONALITY_STEP_5]:
    "Una vez lleno el formulario, para realizar la calificación debes darle click al botón Finalizar.",
  [MAIN_FUNCTIONALITY_ONBOARDING_FINISH]:
    "Has completado el tutorial de la plataforma, ahora sabes dónde encontrar a los creadores de contenido, ver su información y realizar diferentes acciones con ellos.",

  //MENU FUNCTIONALITY
  [MENU_FUNCTIONALITY_STEP_1]:
    "Aquí podrás seleccionar la tienda para la que quieres gestionar su catálogo.",
  [MENU_FUNCTIONALITY_STEP_2]:
    "Aquí podrás agregar una categoría para organizar tus productos o servicios, dale click al botón y escribe el nombre de la categoría.",
  [MENU_FUNCTIONALITY_STEP_3]:
    "Esta es la nueva categoria que acabas de crear.",
  [MENU_FUNCTIONALITY_STEP_4]: "Aquí podrás agregar un producto o servicio.",
  [MENU_FUNCTIONALITY_STEP_5]:
    "Dale click al botón para subir una imagen para el producto.",
  [MENU_FUNCTIONALITY_STEP_6]:
    "Escribe el nombre del producto y dale siguiente.",
  [MENU_FUNCTIONALITY_STEP_7]:
    "Escribe la descripción del producto y dale siguiente.",
  [MENU_FUNCTIONALITY_STEP_8]:
    "Escribe el precio del producto y dale siguiente.",
  [MENU_FUNCTIONALITY_STEP_9]:
    "Indica si es un producto es nocivo para la salud o para mayores de edad dándole click al cuadro.",
  [MENU_FUNCTIONALITY_STEP_10]:
    "Una vez lleno el formulario, para crear el producto debes darle click al botón Crear.",
  [MENU_FUNCTIONALITY_STEP_11]: "Este es el producto que acabas de crear",
  [MENU_FUNCTIONALITY_STEP_12]:
    "Dale click para  activar/desactivar un producto según tu inventario.",

  //USERS FUNCTIONALITY
  [USERS_FUNCTIONALITY_STEP_1]: "Este es un usuario Administrador.",
  [USERS_FUNCTIONALITY_STEP_2]:
    "El rol administrador tiene acceso a todos las funcionalidades, es decir, es capaz de gestionar invitaciones a creadores, gestionar las publicaciones, modificar información de la marca, gestionar información de pago, etc.",
  [USERS_FUNCTIONALITY_STEP_3]: "Este es un usuario Operador.",
  [USERS_FUNCTIONALITY_STEP_4]:
    "El rol operador tiene acceso únicamente a las funcionalidades de redimir los código de los creadores que lleguen a tu tienda física, de gestionar las órdenes/deliveries que hagan los creadores vía app y activar/desactivar productos según inventario.",

  //MAIN FUNCTIONALITY OPERATOR
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_1]:
    "Con la barra de redención podrás escribir un código e intentar redimirlo. Escribe cualquier código y dale Redimir.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_2]:
    "Aquí está el código que acabas de redimir y el valor de la invitación, este es el valor que se le otorgará al creador de contenido, si lo que pida el creador es mayor al valor de la invitación, se le cobrará el excedente.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_3]: `Esta es la sección de Órdenes, aquí podrás ver el listado de las órdenes que han hecho los creadores de contenido vía delivery por la aplicación de {{NAME_BUSINESS}}. Las órdenes/deliveries tienen 3 estados`,
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_4]:
    "El primero es Por Aceptar, es cuando el creador de contenido hizo un pedido, está esperando que lo aceptes y le asignes un tiempo de procesamiento.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_5]:
    "El segundo es Procesando, es cuando el creador de contenido hizo un pedido, lo aceptaste y le asignaste un tiempo de procesamiento, ahora debes comunicar la preperación del pedido.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_6]:
    "El tercero es Por Entregar, es cuando el pedido que estaba en procesamiento ya está listo y lo despachaste con algún método de entrega, una vez se te notifique la entrega o el creador lo marque como entregado, el pedido desaparecerá de acá.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_7]:
    "¡Acaba de entrar un nuevo pedido, la plataforma comenzará a emitir un sonido hasta que veas el pedido nuevo, dale click para verlo.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_8]:
    "Aquí están los productos que pidió el creador de contenido, puedes ver la cantidad y precio de cada producto.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_9]:
    "Aquí está el mensaje que dejó el creador de contenido, puede ser una indicación o recomendación para llevar a cabo la entrega.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_10]:
    "Aquí está el valor total de la orden.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_11]:
    "Aquí está el creador de contenido que hizo el pedido y su información.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_12]:
    "Aquí podrás indicar el tiempo de procesamiento y entrega del pedido, pueden ser minutos, horas, días o semanas.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_13]:
    "Aquí podrás indicar la cantidad de tiempo de procesamiento y entrega del pedido, puedes añadir o restar cantidad de tiempo.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_14]:
    "Una vez revisada la información, asignado el tiempo de procesamiento del pedido, puedes darle click al botón de Aceptar Orden para pasarlo a procesando",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_15]:
    "Este es el pedido que acabas de aceptar, se movió de estado.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_16]:
    "Aquí verás cuánto tiempo de procesamiento le queda, dale click para volver a ver los detalles de la orden.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_17]:
    "Una vez el pedido esté listo, deberás asignarle información de entrega.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_18]:
    "Una vez lleno el formulario, para marcar el pedido como listo debes darle click al botón Pedido Listo.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_19]:
    "Este es el pedido que acabas de enviar, se movió de estado. Aquí encontrarás la información de entrega que le asignaste al pedido, una vez se te informe que el pedido fue entregado, debes marcarlo como entregado. Dale click ver detalles de la orden",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_20]:
    "Una vez revisada la información y se te haya informado que fue entregado al creador debes marcar el pedido como entregado, para esto debes darle click al botón Pedido Entregado.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_21]:
    "Esta es la sección de catálogo, aquí podrás ver el listado de los productos que tiene tu marca en el catálogo.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_22]:
    "Acá están el listado de categorías y la categoría de prueba.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_23]:
    "Acá están el listado de productos y el producto de prueba.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_24]:
    "Como operador, solo puedes activar o desactivar productos según veas la disponibilidad de inventario, para desactivar/activar un producto dale click al botón.",

  //AMBASSADOR FUNCTIONALITY
  [AMBASSADORS_FUNCTIONALITY_STEP_1]:
    "Esta es la página de Embajadores, los embajadores son creadores de contenido que escoges luego de hacer una colaboración y quieres seguir trabajando con ellos de una manera más cercana.",
  [AMBASSADORS_FUNCTIONALITY_STEP_2]:
    "Todo inicia al momento de calificar un creador, dale click al botón Calificar.",
  [AMBASSADORS_FUNCTIONALITY_STEP_3]:
    "Completa el formulario esta parte del formulario.",
  [AMBASSADORS_FUNCTIONALITY_STEP_4]:
    "Cuando quieres convertir en embajador a un creador, deberás seleccionar la opción de Sí.",
  [AMBASSADORS_FUNCTIONALITY_STEP_5]:
    "Deberás asignar un valor de la invitación recurrente que tendrá tu embajador.",
  [AMBASSADORS_FUNCTIONALITY_STEP_6]:
    "Deberás asignar la recurrencia de la invitación del embajador, es decir, cada cuánto tiempo colaborará con tu marca.",
  [AMBASSADORS_FUNCTIONALITY_STEP_7]:
    "Una vez lleno el formulario, para realizar la calificación debes darle click al botón Finalizar.",
  [AMBASSADORS_FUNCTIONALITY_STEP_8]:
    "Al crear un embajador, serás redirigido a la página de embajadores.",
  [AMBASSADORS_FUNCTIONALITY_STEP_9]:
    "Este es el embajador que acabas de crear y su información.",
  [AMBASSADORS_FUNCTIONALITY_STEP_10]:
    "Aquí puedes hacer un pago a los embajadores.",
  [AMBASSADORS_FUNCTIONALITY_STEP_11]:
    "Aquí puedes enviar un mensaje global a tus embajadores.",

  //DISCOVERY INFLUENCERS FUNCTIONALITY
  [DISCOVERY_FUNCTIONALITY_STEP_1]: `Esta es la página de Explorar, podrás buscar creadores de contenidos usando diferentes filtros y que no esté registrado en {{NAME_BUSINESS}}.`,
  [DISCOVERY_FUNCTIONALITY_STEP_2]:
    "Con los filtros podrás buscar creadores de contenido filtrados por su plataforma, categorías, ubicación, número de seguidores, porcentaje de engagement de su audiencia, género, intereses, edad, keywords, etc.",
  [DISCOVERY_FUNCTIONALITY_STEP_3]:
    "Por ejemplo, buscaremos a la creadora gabrielatafur.",
  [DISCOVERY_FUNCTIONALITY_STEP_4]: "En la plataforma Instagram.",
  [DISCOVERY_FUNCTIONALITY_STEP_5]: "Dale click al botón Buscar.",
  [DISCOVERY_FUNCTIONALITY_STEP_6]:
    "Acá aparecerá la información de la creadora gabrielatafur, podrás saber su ubicación, numero de seguidores, engagement y promedio de likes.",
  [DISCOVERY_FUNCTIONALITY_STEP_7]:
    "Podrás solicitar las estadísticas completas dándole click al botón Ver estadísticas.",
  [DISCOVERY_FUNCTIONALITY_STEP_8]:
    "Una vez las estadísticas estén listas, al darle click se te abrirá otra página en la cuál podrás ver sus estadísticas completas, en este caso no se abrirá. Dale click al botón Ver estadísticas.",
  [DISCOVERY_FUNCTIONALITY_STEP_9]: `Una vez hayas visto las estadísticas si la creadora no ha sido invitada a {{NAME_BUSINESS}} o no está registrada en la plataforma, podrás invitarla dándole click al botón Invitar.`,
  [DISCOVERY_FUNCTIONALITY_STEP_10]:
    "Podrás asignarle un valor a la invitación.",
  [DISCOVERY_FUNCTIONALITY_STEP_11]: `Dale click al botón Invitar a {{NAME_BUSINESS}}.`,
  [DISCOVERY_FUNCTIONALITY_STEP_12]: `Aquí aparecerá que ya fue invitada, nosotros le haremos saber a la creadora y te avisaremos si se registra en {{NAME_BUSINESS}}.`,
  [DISCOVERY_FUNCTIONALITY_STEP_13]:
    "También puedes buscar varios creadores que cumplan diferentes filtros, por ejemplo buscaremos creadores de TikTok.",
  [DISCOVERY_FUNCTIONALITY_STEP_14]: "Que tengan minímo 50.000 Seguidores.",
  [DISCOVERY_FUNCTIONALITY_STEP_15]: "Y máximo 100.000 Seguidores.",
  [DISCOVERY_FUNCTIONALITY_STEP_16]: "Dale click al botón Buscar.",
  [DISCOVERY_FUNCTIONALITY_STEP_17]:
    "Aquí aparecerán todos los resultados de los creadores que cumplan este requisito, igualmente podrás solicitar las estadísticas completas, invitarlos, etc.",

  //CAMPAIGNS FUNCTIONALITY
  [CAMPAIGNS_FUNCTIONALITY_STEP_1]:
    "Esta es la página de Campañas, podrás crear campañas para generar colaboraciones más personalizadas con los creadores.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_2]:
    "Comencemos creando una campaña, dale click a Crear Campaña.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_3]:
    "En el centro de la pantalla irán apareciendo las preguntas para tu campaña.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_4]:
    "En la primera pregunta deberás escribir el nombre de tu campaña.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_5]:
    "Una vez completes la pregunta, dale click al botón Siguiente.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_6]:
    "Una vez le des al botón Siguiente, aparecerá la siguiente pregunta.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_7]:
    "Aquí podrás seleccionar la pregunta que quieras ver, aparecerán solo las que hayas visto.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_8]:
    "El formulario puede ser algo extenso, por lo que puedes guardar el progreso que tengas hasta ahora, dale click a Guardar Progreso.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_9]:
    "Una vez guardes el progreso, serás redirigido a la página de campaña.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_10]:
    "Esta es la sección de aplicantes, aquí podrás ver a los creadores que aplicaron a tu campaña. Podrás ver su información, estadísticas, aceptarlos o rechazarlos.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_11]:
    "Aquí también podrás usar los filtros para ver a los aplicantes que te interesen.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_12]:
    "Este es un creador que aplicó a tu campaña.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_13]:
    "Esta es la sección de creadores invitados, aquí aparecerán los creadores que te interesaron para tu campaña y los invitaste.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_14]:
    "En esta sección encontrarás la información de tu campaña, aquí podrás completar la información que te falta y activar la campaña para que los creadores puedan aplicar.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_15]:
    "Con este botón podrás guardar los cambios de tu campaña y seguirá en estado desactivada, por lo que aún los creadores no podrán aplicar.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_16]:
    "Con este botón podrás activar la campaña, deberás tener toda la información requerida para poder hacerlo, en este estado los creadores podrán aplicar a tu campaña.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_17]:
    "Con este botón podrás dar por finalizada la campaña, ya ningún creador podrá aplicar y no podrás activarla nunca más.",

  //STORES FUNCTIONALITY
  [STORES_FUNCTIONALITY_STEP_1]:
    "Esta es la página de Tiendas, podrás crear todas las sucursales que tiene tu marca, asignar su información y métodos de entrega.",
  [STORES_FUNCTIONALITY_STEP_2]:
    "Comencemos creando una tienda, dale click a Crear Tienda.",
  [STORES_FUNCTIONALITY_STEP_3]:
    "Esta es toda la información requerida para crear una tienda, llena todos los campos disponibles, cuanto esten llenos dale click al botón Crear.",

  //MODAL FUNCTIONALITY
  [MODAL_FUNCTIONALITY_CAMPAIGNS]:
    "https://clapp-prod.s3.amazonaws.com/ClappIn/YouKnow/campaigns/campaigns.png",
  [MODAL_FUNCTIONALITY_AMBASSADOR]:
    "https://clapp-prod.s3.amazonaws.com/ClappIn/YouKnow/ambassadors/ambassadors.png",
  [MODAL_FUNCTIONALITY_DISCOVERY]:
    "https://clapp-prod.s3.amazonaws.com/ClappIn/YouKnow/discovery/discovery.png",
  [MODAL_FUNCTIONALITY_AUTO_INVITES]:
    "https://clapp-prod.s3.amazonaws.com/Buzzly/autoInvites-es.webp",
};

export default translation;
