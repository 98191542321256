import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import UserTab from "./UserTab/UserTab";
import ContactTab from "./ContactTab/ContactTab";
import {
  USERS_CONTACTS_TAB,
  USERS_PORTAL_TAB,
} from "../../../locales/keysTranslations";
import SegmentedController from "../../Common/Containers/SegmentedController";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`Tab-de-${index}`}
      aria-labelledby={`Tab-de-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const UsersTabs = ({
  loading,
  usersData,
  storesList,
  onChangeUsersData,
  contactData,
  onChangeContactData,
}) => {
  const [value, setValue] = useState(0);
  const onChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <SegmentedController
        value={value}
        onChangeTab={onChange}
        tabs={[
          {
            label: USERS_PORTAL_TAB,
          },
          {
            label: USERS_CONTACTS_TAB,
          },
        ]}
      />
      <TabPanel value={value} index={0}>
        <UserTab
          loading={loading}
          usersData={usersData}
          storesList={storesList}
          onChangeUsersData={onChangeUsersData}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContactTab
          loading={loading}
          contactData={contactData}
          onChangeContactData={onChangeContactData}
        />
      </TabPanel>
    </Paper>
  );
};

export default UsersTabs;
