import {
  ref,
  push,
  set,
  get,
  orderByChild,
  query,
  equalTo,
  update,
  remove,
  startAfter,
  limitToFirst,
} from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  APPLICANT_STATUS_ACCEPTED,
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_STATUS_PENDING,
  APPLICANT_STATUS_PENDING_SOCIAL,
  APPLICANT_STATUS_REJECTED,
  APPLICANT_STATUS_REVISIONS,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DRAFT,
  CAMPAIGN_STATUS_FINISHED,
  FEE_VALUE_CAMPAIGN,
  PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS,
  VALIDATION_FROM_CAMPAIGNS_TABLE,
} from "../utils/constants";
import {
  startGeneratePreRegisteredInfluencerExecution,
  startGetDiscardedInfluencersByUserIDs,
  startGetInfluencersByUserIDs,
} from "./influencers";
import { getInfluencerRedux, getShopID } from "./getters";
import { validatePlanShop } from "./shops";
import differenceInDays from "date-fns/differenceInDays";
import { getObjectError } from "../utils/formats";
import { DUMMY_CAMPAIGN } from "../onboarding/stepsSelectors";
import i18next from "i18next";
import {
  ACTIVATE,
  CAMPAIGN_ACTIVATE_TIME_CONFIRMATION,
  CAMPAIGN_DEACTIVATE_TIME,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  WARNING,
} from "../locales/keysTranslations";
import { renewToken } from "./auth";
import axios from "../lib/axios";
import { verifyIsErrorPermissionDenied } from "../utils/errors";
import { numberRounded } from "../utils/numberFormats";
import {
  filterCampaignDataSave,
  validationCampaignData,
} from "../services/campaigns";
import { removeInfluencersSuggestedStorage } from "../services/influencers";

const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startGetCampaignsByShop =
  ({ limit, onChangeLastKey, lastKey, onCallbackFinish = () => {} }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/campaigns`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/campaigns`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysCampaigns = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysCampaigns.push(snapshot.key);
        count++;
      });

      const queriesCampaigns = [];
      keysCampaigns.forEach((keyCampaign) => {
        queriesCampaigns.push(get(ref(db, `campaigns/${keyCampaign}`)));
      });

      const campaignsSnapshots = await Promise.all(queriesCampaigns);

      let campaigns = {};
      campaignsSnapshots.forEach((campaignSnapshot) => {
        campaigns = {
          ...campaigns,
          [campaignSnapshot.key]: campaignSnapshot.val(),
        };
      });

      dispatch(getCampaignsByShop(campaigns));
      onCallbackFinish();
    } catch (error) {
      dispatch(loadingCampaignsFinish());
      onCallbackFinish();
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getCampaignsByShop = (data) => ({
  type: types.GET_CAMPAIGNS_BY_SHOP_PAGINATION,
  payload: data,
});
export const cleanDataCampaign = () => {
  return {
    type: types.CLEAN_DATA_CAMPAIGN,
  };
};

export const startCreateCampaign =
  (campaign, status) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      if (!isOpenOnboarding) {
        const validationShop = dispatch(validatePlanShop());
        if (!validationShop) return false;
      }

      const shopID = dispatch(getShopID());

      const signUpCountry = getState().shop.signUpCountry;

      const dbRef = ref(db, `campaigns`);
      const creationTime = new Date().getTime();

      campaign = filterCampaignDataSave(campaign);

      const budget = numberRounded(
        campaign.individualBudget * campaign.amountInfluencers
      );
      const newBudget = numberRounded(budget + budget * FEE_VALUE_CAMPAIGN);

      campaign = {
        ...campaign,
        shopID,
        status,
        creationTime,
        country: signUpCountry,
        budget: newBudget,
        statusAndCountry: `${status}-${signUpCountry}`,
      };

      let keyCampaign;

      if (!isOpenOnboarding) {
        keyCampaign = await push(dbRef, campaign).key;
        await set(
          ref(db, `shopsIDS/${shopID}/campaigns/${keyCampaign}/creationTime`),
          -creationTime
        );
        await remove(ref(db, `campaignsProgress/${shopID}`));
      } else {
        keyCampaign = DUMMY_CAMPAIGN;
      }

      dispatch(
        createCampaign({
          key: keyCampaign,
          data: campaign,
        })
      );

      return keyCampaign;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const createCampaign = (data) => ({
  type: types.CREATE_CAMPAIGN,
  payload: data,
});

export const startGetProgressCampaign =
  (data) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `campaignsProgress/${shopID}`);
      const snapshot = await get(dbRef, data);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return {};
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetCampaignsActive = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `campaigns`);

    const q = query(
      dbRef,
      orderByChild("shopIDAndStatus"),
      equalTo(`${shopID}-${CAMPAIGN_STATUS_ACTIVE}`)
    );

    const snapshot = await get(q);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();

    dispatch(getCampaignsByShop(data));
    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startSaveProgressCampaign =
  (data) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `campaignsProgress/${shopID}`);
      await set(dbRef, data);
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startSaveCampaign =
  (campaignID, data, status) => async (dispatch, getState) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop)
        return {
          ok: false,
        };

      const dbRef = ref(db, `campaigns/${campaignID}`);
      data = filterCampaignDataSave(data);

      const budget = numberRounded(
        data.individualBudget * data.amountInfluencers
      );
      const newBudget = numberRounded(budget + budget * FEE_VALUE_CAMPAIGN);

      data = {
        ...data,
        status,
        budget: newBudget,
      };

      if (status === CAMPAIGN_STATUS_ACTIVE) {
        data.lastActiveDate = new Date().getTime();
      }

      await update(dbRef, data);

      dispatch(
        saveCampaign({
          key: campaignID,
          data,
        })
      );

      return {
        ok: true,
        data,
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return { ok: false };
    }
  };
const saveCampaign = (data) => ({
  type: types.SAVE_CAMPAIGN,
  payload: data,
});

export const startFinishedCampaign = (campaignID) => async (dispatch) => {
  try {
    const dbRef = ref(db, `campaigns/${campaignID}/status`);
    await set(dbRef, CAMPAIGN_STATUS_FINISHED);

    return { ok: true };
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return { ok: false };
  }
};

export const startChangeStatusCampaign =
  ({ campaignID, status, locale }) =>
  async (dispatch, getState) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop) return false;

      const dbRef = ref(db, `campaigns/${campaignID}/status`);

      const campaign = getState().campaigns.campaigns[campaignID];

      if (status === CAMPAIGN_STATUS_ACTIVE) {
        const result = await ConfirmAlert({
          title: i18next.t(WARNING),
          text: i18next.t(CAMPAIGN_ACTIVATE_TIME_CONFIRMATION),
          confirmButtonText: i18next.t(ACTIVATE),
        });
        if (!result.isConfirmed) return;
        campaign.lastActiveDate = new Date().getTime();
        await set(
          ref(db, `campaigns/${campaignID}/lastActiveDate`),
          campaign.lastActiveDate
        );
      }

      if (
        campaign.status === CAMPAIGN_STATUS_ACTIVE &&
        status === CAMPAIGN_STATUS_DRAFT &&
        campaign.lastActiveDate &&
        differenceInDays(new Date(), new Date(campaign.lastActiveDate)) < 1
      ) {
        return SimpleAlert({
          title: i18next.t(WARNING),
          text: i18next.t(CAMPAIGN_DEACTIVATE_TIME),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }

      if (status === CAMPAIGN_STATUS_DRAFT) {
        await set(dbRef, status);
        dispatch(
          changeStatusCampaign({
            key: campaignID,
            status,
          })
        );
        return true;
      }

      const isValidValues = validationCampaignData({
        data: campaign,
        locale: locale,
        from: VALIDATION_FROM_CAMPAIGNS_TABLE,
      });

      if (!isValidValues.ok) {
        return SimpleAlert({
          title: i18next.t(ERROR),
          text: isValidValues.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }

      await set(dbRef, status);
      dispatch(
        changeStatusCampaign({
          key: campaignID,
          status,
        })
      );
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeStatusCampaign = (data) => ({
  type: types.CHANGE_STATUS_CAMPAIGN,
  payload: data,
});

export const startGetCampaignByID =
  (campaignID) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `campaigns/${campaignID}`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data?.shopID === shopID) {
          dispatch(
            getCampaignsByShop({
              [campaignID]: data,
            })
          );
          return data;
        }
        return false;
      } else {
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetCampaignIsUgcCampaign = (campaignID) => async () => {
  try {
    const dbRef = ref(db, `campaigns/${campaignID}/isUgcCampaign`);
    const snapshot = await get(dbRef);

    return snapshot.exists() ? snapshot.val() : false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetApplicantsByCampaignID =
  ({ campaignID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, "applicants"),
        orderByChild("campaignID"),
        equalTo(campaignID)
      );
      const snapshot = await get(q);

      const queriesAnalytics = [];

      if (!snapshot.exists()) return false;

      const userIDs = [];
      const userIDsPending = [];

      let data = snapshot.val();

      snapshot.forEach((snapshotApplicant) => {
        const data = snapshotApplicant.val();
        userIDs.push(data.userID);

        if (data.status === APPLICANT_STATUS_PENDING) {
          userIDsPending.push(data.userID);
        }

        if (data.status === APPLICANT_STATUS_DELIVERED) {
          queriesAnalytics.push(
            get(ref(db, `reports/applicants/${snapshotApplicant.key}`))
          );
        }
      });

      if (queriesAnalytics.length > 0) {
        const analyticsSnapshots = await Promise.all(queriesAnalytics);

        analyticsSnapshots.forEach((analyticsSnapshot) => {
          if (!analyticsSnapshot.exists()) return;

          const analytics = analyticsSnapshot.val();

          data[analyticsSnapshot.key] = {
            ...data[analyticsSnapshot.key],
            analyticsData: analytics,
          };
        });
      }

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );

      await dispatch(
        startGetDiscardedInfluencersByUserIDs({
          userIDs: userIDsPending,
        })
      );

      dispatch(getApplicantsByCampaignID(data));
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const getApplicantsByCampaignID = (data) => ({
  type: types.GET_APPLICANTS_BY_CAMPAIGN_ID,
  payload: data,
});

export const startGetApplicantsByShopID = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const q = query(
      ref(db, "applicants"),
      orderByChild("shopID"),
      equalTo(shopID)
    );
    const snapshot = await get(q);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();

    const queriesAnalytics = [];

    Object.keys(data).forEach((key) => {
      const applicant = data[key];
      if (applicant.status === APPLICANT_STATUS_DELIVERED) {
        queriesAnalytics.push(get(ref(db, `reports/applicants/${key}`)));
      }
    });

    if (queriesAnalytics.length > 0) {
      const analyticsSnapshots = await Promise.all(queriesAnalytics);
      analyticsSnapshots.forEach((analyticsSnapshot) => {
        if (!analyticsSnapshot.exists()) return;

        const analytics = analyticsSnapshot.val();

        data[analyticsSnapshot.key] = {
          ...data[analyticsSnapshot.key],
          analyticsData: analytics,
        };
      });
    }

    dispatch(getApplicantsByShopID(data));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getApplicantsByShopID = (data) => ({
  type: types.GET_APPLICANTS_BY_SHOP,
  payload: data,
});

export const startGetApplicantByID = (applicantID) => async (dispatch) => {
  try {
    const dbRef = ref(db, `applicants/${applicantID}`);
    const snapshot = await get(dbRef);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();

    const snapshotAnalytics = await get(
      ref(db, `reports/applicants/${applicantID}`)
    );

    if (snapshotAnalytics.exists()) {
      data.analyticsData = snapshotAnalytics.val();
    }

    await dispatch(
      startGetInfluencersByUserIDs({
        userIDs: [data.userID],
        getIsDiscarded: false,
      })
    );

    if (data.status === APPLICANT_STATUS_PENDING) {
      await dispatch(
        startGetDiscardedInfluencersByUserIDs({ userIDs: [data.userID] })
      );
    }

    dispatch(getApplicantsByPagination({ [applicantID]: data }));
    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetApplicantsByShopPagination =
  ({ limit, onChangeLastKey, lastKey, loadLastItem = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `shopsIDS/${shopID}/applicants`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `shopsIDS/${shopID}/applicants`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          ref(db, `shopsIDS/${shopID}/applicants`),
          orderByChild("creationTime")
        );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        dispatch(loadingCampaignsFinish());
        return loadLastItem();
      }

      const keysApplicants = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();

        if (count === snapshotSize && limit) onChangeLastKey(data.creationTime);

        keysApplicants.push(snapshot.key);
        count++;
      });

      const queriesApplicants = [];
      keysApplicants.forEach((keyApplicant) => {
        queriesApplicants.push(get(ref(db, `applicants/${keyApplicant}`)));
      });

      const applicantsSnapshots = await Promise.all(queriesApplicants);

      let applicants = {};
      let userIDs = [];
      const userIDsPending = [];

      const queriesAnalytics = [];

      applicantsSnapshots.forEach((snapshotApplicant) => {
        const applicant = snapshotApplicant.val();

        applicants[snapshotApplicant.key] = applicant;
        userIDs.push(applicant.userID);

        if (applicant.status === APPLICANT_STATUS_PENDING) {
          userIDsPending.push(applicant.userID);
        }

        if (applicant.status === APPLICANT_STATUS_DELIVERED) {
          queriesAnalytics.push(
            get(ref(db, `reports/applicants/${snapshotApplicant.key}`))
          );
        }
      });

      if (queriesAnalytics.length > 0) {
        const analyticsSnapshots = await Promise.all(queriesAnalytics);
        analyticsSnapshots.forEach((analyticsSnapshot) => {
          if (!analyticsSnapshot.exists()) return;

          const analytics = analyticsSnapshot.val();

          applicants[analyticsSnapshot.key] = {
            ...applicants[analyticsSnapshot.key],
            analyticsData: analytics,
          };
        });
      }

      await dispatch(
        startGetDiscardedInfluencersByUserIDs({ userIDs: userIDsPending })
      );
      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      dispatch(getApplicantsByPagination(applicants));
      return true;
    } catch (error) {
      dispatch(loadingCampaignsFinish());
      console.log(error);
      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const getApplicantsByPagination = (data) => ({
  type: types.GET_APPLICANTS_BY_PAGINATION,
  payload: data,
});

export const editApplicant = (data) => ({
  type: types.EDIT_APPLICANT,
  payload: data,
});

export const startAcceptedApplicant =
  ({ applicantID, paymentID }) =>
  async (dispatch) => {
    try {
      const updates = {};
      updates[`applicants/${applicantID}/status`] = APPLICANT_STATUS_ACCEPTED;
      updates[`applicants/${applicantID}/paymentID`] = paymentID;
      await update(ref(db), updates);
      dispatch(acceptedApplicant(applicantID));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const acceptedApplicant = (data) => ({
  type: types.ACCEPTED_APPLICANT,
  payload: data,
});

export const startInvitedApplicant =
  ({ applicantID, inviteID }) =>
  async (dispatch) => {
    try {
      const updates = {};
      updates[`applicants/${applicantID}/inviteID`] = inviteID;
      await update(ref(db), updates);
      dispatch(invitedApplicant({ applicantID, inviteID }));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const invitedApplicant = (data) => ({
  type: types.INVITED_APPLICANT,
  payload: data,
});

export const startRejectedApplicant = (applicantID) => async (dispatch) => {
  try {
    const dbRef = ref(db, `applicants/${applicantID}/status`);
    await set(dbRef, APPLICANT_STATUS_REJECTED);
    dispatch(rejectedApplicant(applicantID));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const rejectedApplicant = (data) => ({
  type: types.REJECTED_APPLICANT,
  payload: data,
});

export const startAcceptedApplicantContent =
  (applicantID) => async (dispatch, getState) => {
    try {
      let campaignID = getState().campaigns.applicants[applicantID].campaignID;

      if (!campaignID) {
        const applicant = await dispatch(startGetApplicantByID(applicantID));
        campaignID = applicant.campaignID;
      }

      const isUgcCampaign = await dispatch(
        startGetCampaignIsUgcCampaign(campaignID)
      );

      const dbRef = ref(db, `applicants/${applicantID}/status`);

      await set(
        dbRef,
        isUgcCampaign
          ? APPLICANT_STATUS_DELIVERED
          : APPLICANT_STATUS_PENDING_SOCIAL
      );
      dispatch(
        acceptedApplicantContent({
          key: applicantID,
          status: isUgcCampaign
            ? APPLICANT_STATUS_DELIVERED
            : APPLICANT_STATUS_PENDING_SOCIAL,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const acceptedApplicantContent = (data) => ({
  type: types.ACCEPTED_CONTENT_APPLICANT,
  payload: data,
});

export const startRevisionsApplicantContent =
  ({ applicantID, comment, content }) =>
  async (dispatch, getState) => {
    try {
      const updates = {};

      let applicant = getState()?.campaigns?.applicants[applicantID];

      if (!applicant) {
        applicant = await dispatch(startGetApplicantByID(applicantID));
      }

      const history = applicant?.contentHistory || [];
      const historyLength = history.length;

      updates[`applicants/${applicantID}/status`] = APPLICANT_STATUS_REVISIONS;
      updates[`applicants/${applicantID}/revision`] = historyLength;
      updates[`applicants/${applicantID}/content`] = null;
      updates[`applicants/${applicantID}/contentHistory/${historyLength}/`] = {
        contentUrls: content,
        comment,
        creationTime: new Date().getTime(),
      };
      await update(ref(db), updates);
      dispatch(
        revisionsApplicantContent({ applicantID, revision: historyLength })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const revisionsApplicantContent = (data) => ({
  type: types.REVISIONS_CONTENT_APPLICANT,
  payload: data,
});

export const startGetInvitationsCampaigns =
  ({ campaignID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, `invitesCampaigns`),
        orderByChild("campaignID"),
        equalTo(campaignID)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const userIDs = [];
        snapshot.forEach((snapshotInvitation) => {
          userIDs.push(snapshotInvitation.val().userID);
        });
        await dispatch(startGetInfluencersByUserIDs({ userIDs }));
        const data = snapshot.val();
        dispatch(getInvitationsCampaigns(data));
      }
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getInvitationsCampaigns = (data) => ({
  type: types.GET_INVITATIONS_CAMPAIGNS_BY_CAMPAIGN_FINISH,
  payload: data,
});
const getInvitationsCampaignsNext = (data) => ({
  type: types.GET_INVITATIONS_CAMPAIGNS_BY_CAMPAIGN_NEXT_FINISH,
  payload: data,
});

export const startInviteInfluencerCampaign =
  ({ campaignID, userID }) =>
  async (dispatch, getState) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop) return false;

      const shopID = dispatch(getShopID());
      const influencer = dispatch(getInfluencerRedux(userID));
      const isExternal = influencer?.isExternal;

      const key = push(ref(db)).key;

      const path = `invitesCampaigns/${key}`;
      const data = {
        campaignID,
        userID,
        isSendNotification: false,
        campaignIDuserID: `${campaignID}-${userID}`,
        creationTime: new Date().getTime(),
        shopID,
      };

      if (isExternal) {
        const response = await dispatch(
          startGeneratePreRegisteredInfluencerExecution({
            userID,
            mainAccountName: influencer.mainAccountName,
            mainPlatform: influencer.mainPlatform,
            imageURL: influencer.imageURL,
            actions: [
              {
                path,
                data,
                db: "realtime",
              },
            ],
            withActions: true,
            type: PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS,
          })
        );
        if (!response) return false;
      } else {
        const dbRef = ref(db, path);
        await set(dbRef, data).key;
      }

      dispatch(inviteInfluencerCampaign({ key, data }));
      removeInfluencersSuggestedStorage(userID);
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const inviteInfluencerCampaign = (data) => ({
  type: types.INVITE_INFLUENCER_CAMPAIGN,
  payload: data,
});

export const startGetInviteInfluencerCampaign =
  ({ campaignID, userID }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `invitesCampaigns`);
      const q = query(
        dbRef,
        orderByChild("campaignIDuserID"),
        equalTo(`${campaignID}-${userID}`)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data);
        dispatch(getInvitationsCampaignsNext(data));
        return data;
      }
      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startDeleteInviteInfluencerCampaign =
  ({ inviteID }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `invitesCampaigns/${inviteID}`);
      await remove(dbRef);
      dispatch(deleteInviteInfluencerCampaign(inviteID));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const deleteInviteInfluencerCampaign = (data) => ({
  type: types.DELETE_INVITE_INFLUENCER_CAMPAIGN,
  payload: data,
});

export const startAssignChatIDApplicant =
  ({ applicantID, chatID }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `applicants/${applicantID}/chatID`);
      await set(dbRef, chatID);
      dispatch(
        assignChatIDApplicant({
          key: applicantID,
          chatID,
        })
      );
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const assignChatIDApplicant = (applicantID) => ({
  type: types.ASSIGN_CHAT_ID_APPLICANT,
  payload: applicantID,
});

const loadingCampaignsFinish = () => ({
  type: types.LOADING_CAMPAIGNS_FINISH,
});

export const startDisableAllCampaigns = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `campaigns`);
    const q = query(dbRef, orderByChild("shopID"), equalTo(shopID));
    const snapshot = await get(q);
    if (!snapshot.exists()) return false;
    const updates = {};
    const campaigns = snapshot.val();
    Object.keys(campaigns).forEach((key) => {
      updates[`campaigns/${key}/status`] = CAMPAIGN_STATUS_DRAFT;
      campaigns[key].status = CAMPAIGN_STATUS_DRAFT;
    });
    await update(ref(db), updates);
    dispatch(disableAllCampaigns(campaigns));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const disableAllCampaigns = (data) => ({
  type: types.DISABLE_ALL_CAMPAIGNS,
  payload: data,
});

export const startGenerateDescriptionAndScriptCampaign =
  ({
    campaignID,
    campaignData,
    generateDescription = true,
    generateScript = true,
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { campaignID, campaignData, generateDescription, generateScript },
        url: `${url}/generateDescriptionCampaign`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data?.data;
      }
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
