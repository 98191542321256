import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { getExtensionFile } from "../../../utils/urls";
import {
  CHAT_BOT_USER_ID,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
} from "../../../utils/constants";
import PDFImage from "../../../assets/img/pdf-icon.png";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../Loader/Loader";
import linkifyHtml from "linkify-html";
import { DATE_DISTANCE, DATE_HOUR } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { startOfDay } from "date-fns";
import MediaDownloadContainer from "../Media/MediaDownloadContainer";
import CardMedia from "../Media/CardMedia";
import { startGetAwsDownloadUrl } from "../../../actions/shops";
import { useDispatchApp } from "../../../lib/redux";

const ChatCustomMessage = ({ message = {}, direction, currentChat }) => {
  const [isFetching, setIsFetching] = useState(false);
  const hasMedia = message?.mediaURL;
  const ext = hasMedia ? getExtensionFile(message?.mediaURL) : "";

  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const startDay = startOfDay(new Date()).getTime();

  const tKeyDate =
    message?.creationTime <= startDay ? DATE_DISTANCE : DATE_HOUR;

  const getStylesMessage = () => {
    if (hasMedia) {
      return {
        backgroundColor: "inherit",
        color: "background.paperSecondaryContrastText",
      };
    }

    if (direction === "outgoing") {
      return {
        backgroundColor: "primary.main",
        color: "primary.contrastText",
      };
    }

    return {
      backgroundColor: "inherit",
      color: "background.paperSecondaryContrastText",
    };
  };

  const stylesMessage = getStylesMessage();
  return (
    <>
      <Box
        sx={{
          backgroundColor: stylesMessage.backgroundColor,
          color: stylesMessage.color,
          borderRadius: 4,
          padding: 1.5,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: stylesMessage.color,
            fontSize: ".91rem",
            whiteSpace: "preWrap",
            overflowWrap: "anywhere",
            wordBreak: "breakWord",
            fontFeatureSettings: "normal",
            fontVariant: "normal",
            mb: hasMedia ? 1 : 0,
          }}
          dangerouslySetInnerHTML={{
            __html: linkifyHtml(message?.message || "", {
              attributes: {
                rel: "noopener noreferrer",
                target: "_blank",
                style: "color: #00A3FF",
              },
            }),
          }}
        />
        {hasMedia &&
        !IMAGE_FORMATS.includes(ext) &&
        !VIDEO_FORMATS.includes(ext) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                borderRadius: 6,
                py: 1,
                px: 2,
              }}
            >
              <Avatar
                src={PDFImage}
                alt="PDF icon"
                variant="square"
                sx={{
                  width: 30,
                  height: "auto",
                }}
              />
              {isFetching ? (
                <Loader color="secondary" size={30} hasMarginTop={false} />
              ) : (
                <IconButton
                  sx={{
                    border: "1px solid white",
                  }}
                  onClick={async () => {
                    setIsFetching(true);
                    await dispatch(startGetAwsDownloadUrl(message.mediaURL));
                    setIsFetching(false);
                  }}
                >
                  <DownloadIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        ) : null}
        {hasMedia && IMAGE_FORMATS.includes(ext) ? (
          <MediaDownloadContainer url={message.mediaURL} alt={message.message}>
            <Avatar
              variant="square"
              sx={{
                width: "auto",
                maxWidth: 200,
                height: "auto",
                borderRadius: 5,
              }}
              src={message.mediaURL}
              alt={message.message}
            />
          </MediaDownloadContainer>
        ) : null}
        {hasMedia && VIDEO_FORMATS.includes(ext) ? (
          <MediaDownloadContainer
            url={message.mediaURL}
            alt={message.message}
            onClick={() => {
              const video = document.getElementById("video-container");
              if (video) {
                video.pause();
                video.muted = true;
              }
            }}
            onClose={() => {
              const video = document.getElementById("video-container");
              if (video) {
                video.muted = false;
                video.pause();
              }
            }}
          >
            <CardMedia
              id="video-container"
              url={message.mediaURL}
              propsType={{
                controlsList: "nodownload",
                controls: true,
              }}
              sx={{
                width: "100%",
                maxWidth: 200,
                height: "auto",
                borderRadius: 25,
                position: "relative",
              }}
            />
          </MediaDownloadContainer>
        ) : null}
      </Box>
      {currentChat?.chatID !== CHAT_BOT_USER_ID && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            mt: 0.7,
            ml: direction === "incoming" ? 0.7 : 0,
          }}
        >
          <Typography variant="caption" color="inherit">
            {t(tKeyDate, {
              date: new Date(message?.creationTime),
            })}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ChatCustomMessage;
