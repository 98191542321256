import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TooltipModal from "../../../Common/Tooltips/TooltipModal";
import ContentAnalytics from "../../../Common/Tooltips/Content/ContentAnalytics";
import RenderContentMedia from "../../../Common/Media/RenderContentMedia";

const ContentApplicantAnalytics = ({ data, isMobile }) => {
  return (
    <Box
      sx={{
        width: "auto",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
        <Typography variant={isMobile ? "h4" : "h5"} sx={{ fontWeight: 600 }}>
          Analítica
        </Typography>
        <TooltipModal
          title="¿Cómo interpretar las analíticas?"
          content={<ContentAnalytics />}
        />
      </Box>
      <Box
        sx={{
          gap: 2,
          display: "flex",
          overflowX: "auto",
          pb: 1,
        }}
      >
        {data ? (
          data.map((analytic, index) => (
            <RenderContentMedia key={index} url={analytic} keyMedia={index} />
          ))
        ) : (
          <Typography variant="h5" sx={{ pb: -2 }}>
            No hay analíticas aún
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ContentApplicantAnalytics;
