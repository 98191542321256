import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AnswerContainer from "../../Common/Forms/TypeForm/AnswerContainer";
import AnswerTitle from "../../Common/Forms/TypeForm/AnswerTitle";
import Alert from "@mui/material/Alert";
import TextField from "../../Common/Fields/TextField";
import { useTranslationApp } from "../../../lib/i18next";
import ActionsButtons from "../../Common/Forms/TypeForm/ActionsButtons";
import {
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
  AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION,
  BUTTON_ACTIVATE_NOW,
  BUTTON_GENERATE_SCRIPT,
  BUTTON_NOT_NOW,
  FIELD_REQUIRED,
  INVITE_FORM_COMMENT_PLACEHOLDER_3,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
  URL_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import CurrencyField from "../../Common/NumberFormats/CurrencyField";
import { Controller } from "react-hook-form";
import SliderBase from "../../Common/Forms/Sliders/SliderBase";
import { marksInfluencersMax500K } from "../../../utils/slidersMarks";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { startGenerateCommentProfileInfluencer } from "../../../actions/shops";
import { MIN_ALL_NUMBER_VALUE } from "../../../utils/constants";

const AutomaticInvitesTypeFormForm = ({
  currentAnswer,
  step,
  error,
  errors,
  onNextStep,
  onPreviousStep,
  control,
  getValues,
  watch,
  setValue,
}) => {
  const [isFetchingComment, setIsFetchingComment] = useState(false);
  const { t } = useTranslationApp();
  const budgets = watch("budgets");

  const isMobile = useTheme((theme) => theme.breakpoints.down("xs"));
  const dispatch = useDispatchApp();

  const sxAlert = {
    my: 1,
  };
  const sxSubContainer = {
    px: {
      xs: 0,
      sm: 1,
      md: 2,
      lg: 3,
    },
  };
  const sxContainer = {
    width: "auto",
  };

  const onGenerateComment = async () => {
    setIsFetchingComment(true);
    const response = await dispatch(
      startGenerateCommentProfileInfluencer({
        typeContent: getValues("typeContent"),
        formats: getValues("formats"),
        wantsSocialMediaPost: true,
      })
    );
    if (response) {
      setValue("comment", response);
    }
    setIsFetchingComment(false);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer
        stepPosition={"numberInvitations"}
        step={currentAnswer}
        sx={sxSubContainer}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(
            AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION
          )}`}
        </AnswerTitle>
        {!!error && error.step === "numberInvitations" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="numberInvitations"
          placeholder={t(
            AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER
          )}
          fullWidth
          variant="standard"
        />
        <ActionsButtons
          showPreviousButton={false}
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("numberInvitations", getValues("numberInvitations"))
          }
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"budgetMonthly"}
        step={currentAnswer}
        sx={sxSubContainer}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "budgetMonthly" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="budgetMonthly"
          placeholder={t(
            AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER
          )}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          fullWidth
          variant="standard"
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("budgetMonthly", getValues("budgetMonthly"))
          }
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"budgets"}
        step={currentAnswer}
        sx={{ ...sxSubContainer, mt: 2 }}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "budgets" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pr: 1.5,
            maxHeight: "calc(100vh - 394px)",
            overflowY: "auto",
            width: "100%",
            overflowX: "hidden",
          }}
        >
          {budgets.map((budget, index) => (
            <IndividualBudgetItem
              key={index}
              budget={budget}
              budgets={budgets}
              control={control}
              index={index}
              numAudience={index + 1}
              setValue={setValue}
              errors={errors}
            />
          ))}
        </Box>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("budgets", getValues("budgets"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"comment"}
        step={currentAnswer}
        sx={sxSubContainer}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "comment" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          multiline
          minRows={isMobile ? 2 : 4}
          maxRows={isMobile ? 3 : 5}
          name="comment"
          placeholder={t(INVITE_FORM_COMMENT_PLACEHOLDER_3)}
          fullWidth
          variant="standard"
          hasButtonGenerate={true}
          propsButtonGenerate={{
            loading: isFetchingComment,
            onClick: onGenerateComment,
            text: BUTTON_GENERATE_SCRIPT,
          }}
          showCounterCharacters={true}
          maxCharacters={3000}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("comment", getValues("comment"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"formats"}
        step={currentAnswer}
        sx={sxSubContainer}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "formats" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1} sx={{ mb: 1, maxWidth: 350 }}>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="formats"
              nameValue="instagramBurstStories"
              label={t(INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES)}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="formats"
              nameValue="instagramPost"
              label={t(INVITE_FORM_FORMAT_INSTAGRAM_POST)}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="formats"
              nameValue="instagramReel"
              label={t(INVITE_FORM_FORMAT_INSTAGRAM_REEL)}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="formats"
              nameValue="instagramStory"
              label={t(INVITE_FORM_FORMAT_INSTAGRAM_STORY)}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="formats"
              nameValue="tiktokPost"
              label={t(INVITE_FORM_FORMAT_TIKTOK_POST)}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="formats"
              nameValue="tiktokStory"
              label={t(INVITE_FORM_FORMAT_TIKTOK_STORY)}
            />
          </Grid>
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("formats", getValues("formats"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"contentReference"}
        step={currentAnswer}
        sx={sxSubContainer}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(
            AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION
          )}`}
        </AnswerTitle>
        {!!error && error.step === "contentReference" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="contentReference"
          placeholder={t(URL_PLACEHOLDER)}
          fullWidth
          variant="standard"
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("contentReference", getValues("contentReference"))
          }
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"autoInvitationsIsActive"}
        step={currentAnswer}
        sx={sxSubContainer}
        sxContainer={sxContainer}
      >
        <AnswerTitle>
          {`${step}. ${t(
            AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION
          )}`}
        </AnswerTitle>
        {!!error && error.step === "autoInvitationsIsActive" && (
          <Alert severity="error" sx={sxAlert}>
            {error.text}
          </Alert>
        )}
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          showNextButton={false}
          draftButtonText={BUTTON_NOT_NOW}
          showSaveButton={true}
          saveButtonText={BUTTON_ACTIVATE_NOW}
          showDraftButton={true}
          valueDraft={false}
          valueFinish={true}
          onFinish={async (value) => {
            await onNextStep(
              "autoInvitationsIsActive",
              getValues("autoInvitationsIsActive"),
              true,
              value
            );
          }}
        />
      </AnswerContainer>
    </Box>
  );
};

const IndividualBudgetItem = ({
  budget,
  budgets,
  control,
  index,
  setValue,
  errors,
}) => {
  const LOCALE = useLocale();
  const { t } = useTranslationApp();
  const minProductValueShop = useSelectorApp(
    (state) => state.shop.minProductValue
  );

  const onAddBudget = () => {
    const newBudgets = [...budgets];
    newBudgets.push({
      value: "",
      audienceRange: [5000, 500000],
      fieldName: `budget${budgets.length + 1}`,
    });
    setValue("budgets", newBudgets);
  };
  const onDeleteBudget = (index) => {
    const newBudgets = [...budgets];
    newBudgets.splice(index, 1);
    setValue("budgets", newBudgets);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={budget.fieldName}
          render={({ field }) => (
            <SliderBase
              innerRef={field.ref}
              range={budget?.audienceRange}
              onChange={(e) => {
                const value = e.target.value;
                const newBudgets = [...budgets];
                newBudgets[index].audienceRange = value;
                field.onChange({
                  ...field.value,
                  audienceRange: value,
                });
              }}
              title={t(AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE, {
                value: index + 1,
              })}
              variantTitle="body1"
              step={1000}
              min={5000}
              max={500000}
              marks={marksInfluencersMax500K}
              sx={{ pr: 1, pl: 1.25, width: "100%" }}
              sxTitle={{
                fontWeight: 500,
              }}
            />
          )}
        />
        {!!errors["audienceRange"] && (
          <Typography variant="body2" color="error">
            {errors["audienceRange"]?.message}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          name={budget.fieldName}
          label={t(AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE, {
            value: index + 1,
          })}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          variant="filled"
          value={budget.value}
          doOnChange={false}
          useValue={false}
          actionsChange={(value) => {
            const newBudgets = [...budgets];
            newBudgets[index].value = value;
            setValue("budgets", newBudgets);
            setValue(budget.fieldName, {
              ...budget,
              value,
            });
          }}
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: {
              value: (budget) => {
                if (!budget.value) return t(FIELD_REQUIRED);
                if (parseInt(budget.value) < MIN_ALL_NUMBER_VALUE) {
                  return t(
                    AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
                    {
                      value: MIN_ALL_NUMBER_VALUE,
                      currency: LOCALE.currency,
                    }
                  );
                }
                if (parseInt(budget.value) < minProductValueShop) {
                  return t(
                    AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
                    {
                      value: minProductValueShop,
                      currency: LOCALE.currency,
                    }
                  );
                }
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "8px !important" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: 2,
          }}
        >
          {budgets.length > 1 && (
            <IconButton
              color="primary"
              onClick={() => onDeleteBudget(index)}
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.5rem",
                },
                backgroundColor: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.dark",
                },
              }}
            >
              <ClearIcon
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1.5rem",
                  },
                }}
              />
            </IconButton>
          )}
          {index === budgets.length - 1 && (
            <IconButton
              color="primary"
              onClick={onAddBudget}
              sx={{
                backgroundColor: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.dark",
                },
              }}
            >
              <AddIcon
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1.5rem",
                  },
                }}
              />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const CheckItem = ({ control, nameKey, nameValue, label }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={nameKey}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={field.value[nameValue]}
              onChange={(e) =>
                field.onChange({
                  ...field.value,
                  [nameValue]: e.target.checked,
                })
              }
              name={nameValue}
            />
          )}
        />
      }
      label={label}
    />
  );
};
export default AutomaticInvitesTypeFormForm;
