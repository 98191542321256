import React, { useState } from "react";
import { Controller } from "react-hook-form";
import SelectMUI from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import {
  ANY,
  FIELD_REQUIRED,
  SELECT_SOME_OPTIONS,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import { useTranslationApp } from "../../../lib/i18next";

const SelectMultiple = ({
  control,
  rules = { required: { value: true, message: i18next.t(FIELD_REQUIRED) } },
  errors = {},
  label = "",
  placeholder = "",
  displayEmpty = false,
  showEmptyOption = false,
  variant = "standard",
  name = "",
  value = "",
  fullWidth = false,
  idLabel = `select-${name}`,
  options = [],
  disabled = false,
  renderValue = (selected) => selected.join(", "),
  menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8 * 2,
        width: 250,
      },
    },
  },
  showAddAction = false,
  labelAddAction = "",
  addAction = () => {},
  errorAddAction = null,
  className = "",
  formatOptions = "string",
  doTranslation = false,
  customOnChange,
  isRounded = false,
  backgroundColor = "",
  sx = {},
  showAllOption = false,
}) => {
  const hiddenLabel = variant === "filled" && !label;

  const { t } = useTranslationApp();
  const [newOption, setNewOption] = useState("");

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <FormControl
          className={className}
          fullWidth={fullWidth}
          variant={variant}
          error={!!errors[name]}
          disabled={disabled}
          hiddenLabel={hiddenLabel}
        >
          <InputLabel id={idLabel}>{label}</InputLabel>
          <SelectMUI
            labelId={idLabel}
            label={label}
            placeholder={placeholder}
            displayEmpty={displayEmpty}
            multiple
            renderValue={
              field.value?.length > 0
                ? renderValue
                : () => t(SELECT_SOME_OPTIONS)
            }
            MenuProps={menuProps}
            {...field}
            onChange={(e) => {
              if (customOnChange) return customOnChange(e);

              if (e?.target?.value?.includes("all")) {
                const newValue =
                  field.value.length === options.length
                    ? []
                    : options.map((option) =>
                        formatOptions === "object" ? option.value : option
                      );
                field.onChange(newValue);
              } else if (e?.target?.value?.indexOf(e?.target?.value) !== -1) {
                const newValue = field.value.filter(
                  (s) => s !== e?.target?.value
                );
                field.onChange(newValue);
              } else {
                const newValue = e?.target?.value;
                field.onChange(newValue);
              }
            }}
            sx={{
              borderRadius: isRounded ? 4 : 1,
              backgroundColor,
              ...sx,
            }}
          >
            {showEmptyOption && (
              <MenuItem value="" disabled>
                <em>{t(SELECT_SOME_OPTIONS)}</em>
              </MenuItem>
            )}
            {showAddAction && (
              <Box sx={{ px: 2, py: 1 }} onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  label={labelAddAction}
                  error={!!errorAddAction}
                  helperText={errorAddAction}
                  variant={variant}
                  name="newOption"
                  value={newOption}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      const response = addAction(newOption);
                      if (response) setNewOption("");
                    }
                  }}
                  onChange={(e) => setNewOption(e.target.value)}
                  fullWidth
                />
              </Box>
            )}
            {showAllOption && (
              <MenuItem value="all">
                <Checkbox checked={field?.value.length === options.length} />
                <ListItemText primary={t(ANY)} />
              </MenuItem>
            )}
            {options.map((option) => {
              if (formatOptions === "object") {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={field.value.indexOf(option.value) > -1}
                    />
                    <ListItemText
                      primary={doTranslation ? t(option.label) : option.label}
                    />
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={field.value.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              );
            })}
          </SelectMUI>
          {!!errors[name] && (
            <Typography variant="body2" color="error">
              {errors[name]?.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectMultiple;
