import { DUMMY_AMBASSADOR } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  ambassadors: {},
  isLoading: true,
};

export const ambassadorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AMBASSADORS_FINISH:
      return {
        ...state,
        ambassadors: action.payload,
        isLoading: false,
      };
    case types.GET_NEXT_AMBASSADORS_FINISH:
      return {
        ...state,
        ambassadors: { ...state.ambassadors, ...action.payload },
        isLoading: false,
      };
    case types.CREATE_AMBASSADOR:
      return {
        ...state,
        ambassadors: {
          ...state.ambassadors,
          [action.payload.key]: action.payload.data,
        },
        isLoading: false,
      };
    case types.EDIT_AMBASSADOR:
      return {
        ...state,
        ambassadors: {
          ...state.ambassadors,
          [action.payload.key]: {
            ...state.ambassadors[action.payload.key],
            ...action.payload.data,
          },
        },
      };
    case types.DELETE_AMBASSADOR:
      const ambassadors = { ...state.ambassadors };
      delete ambassadors[action.payload];
      return {
        ...state,
        ambassadors,
        isLoading: false,
      };
    case types.ASSIGN_CHAT_ID_AMBASSADOR:
      return {
        ...state,
        ambassadors: {
          ...state.ambassadors,
          [action.payload.key]: {
            ...state.ambassadors[action.payload.key],
            chatID: action.payload.chatID,
          },
        },
        isLoading: false,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_AMBASSADOR]: ambassadorDelete, ...restAmbassadors } =
        state.ambassadors;
      return {
        ...state,
        ambassadors: restAmbassadors,
      };
    case types.LOADING_AMBASSADORS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
