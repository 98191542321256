import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "../Buttons/Button";
import {
  INSTAGRAM,
  TIKTOK,
  VIDEO_FORMATS,
  YOUTUBE,
} from "../../../utils/constants";
import MediaDownloadContainer from "./MediaDownloadContainer";
import { getExtensionFile } from "../../../utils/urls";
import { detectPlatform } from "../../../utils/urls";
import LinkText from "../Texts/LinkText";

const RenderContentMedia = ({ url, keyMedia }) => {
  const result = detectPlatform(url);
  switch (result) {
    case INSTAGRAM:
      return (
        <LinkText
          href={url}
          target="_blank"
          style={{
            textDecoration: "none",
            width: "250px",
          }}
          key={keyMedia}
        >
          <Button color="secondary">Verificar publicación</Button>
        </LinkText>
      );
    case TIKTOK:
      return (
        <LinkText
          href={url}
          target="_blank"
          style={{
            textDecoration: "none",
            width: "250px",
          }}
          key={keyMedia}
        >
          <Button color="secondary">Verificar publicación</Button>
        </LinkText>
      );
    case YOUTUBE:
      return (
        <LinkText
          href={url}
          target="_blank"
          style={{
            textDecoration: "none",
            width: "250px",
          }}
          key={keyMedia}
        >
          <Button color="secondary">Verificar publicación</Button>
        </LinkText>
      );

    default:
      const extension = getExtensionFile(url);

      return VIDEO_FORMATS.includes(extension) ? (
        <MediaDownloadContainer
          url={url}
          sx={{
            height: "auto",
            width: "auto",
            mr: 2,
          }}
          key={keyMedia}
        >
          <video
            src={url}
            controlsList="nodownload"
            controls={true}
            style={{ width: 320, height: "auto" }}
          ></video>
        </MediaDownloadContainer>
      ) : (
        <MediaDownloadContainer
          url={url}
          sx={{
            height: "auto",
            width: "auto",
            mr: 2,
          }}
          key={keyMedia}
        >
          <Avatar
            variant="rounded"
            src={url}
            sx={{
              height: "auto",
              width: {
                xs: 270,
                lg: 320,
              },
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt={`Publicación`}
          />
        </MediaDownloadContainer>
      );
  }
};

export default RenderContentMedia;
