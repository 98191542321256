import React, { useState, useRef, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Gallery from "./Gallery";
import { startGetOrdersByShop } from "../../../actions/orders";
import Loader from "../Loader/Loader";
import Typography from "@mui/material/Typography";
import ChipsFilterGallery from "./ChipsFilterGallery";
import { VIDEO_FORMATS, IMAGE_FORMATS } from "../../../utils/constants";
import useNearScreen from "../../../hooks/useNearScreen";
import { usePagination } from "../../../hooks/usePagination";
import { useDebounced } from "../../../hooks/useDebounce";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { getExtensionFile } from "../../../utils/urls";
import {
  BUTTON_ADD_CONTENT,
  BUTTON_PREVIOUS,
  BUTTON_REMOVE_SELECTION,
  BUTTON_SELECT_THIS_CONTENT,
  LOADING_CONTENT,
  MODAL_GALLERY_ARIA_LABEL,
  MODAL_GALLERY_EMPTY_CONTENT,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Buttons/Button";
import { startGetReviewsByIDs } from "../../../actions/influencers";
import ModalMedia from "../Modal/ModalMedia";
import ContainerModalActions from "../Containers/ContainerModalActions";

const PAGE_SIZE = 10;

const ModalGallery = ({
  modalOpen,
  filterByFormat,
  multiple = true,
  onCloseModal,
  acceptedFormats = VIDEO_FORMATS,
  onCallbackSelectedItems,
}) => {
  const [data, setData] = useState([]);
  const [selectPreview, setSelectPreview] = useState(null);
  const [reviews, setReviews] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState(filterByFormat);
  const [loading, setLoading] = useState(true);
  const [loadLastItem, setLoadLastItem] = useState(false);
  const [keysProcessed, setKeysProcessed] = useState([]);

  const { t } = useTranslationApp();

  const galleryRef = useRef(null);

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "100px",
    once: false,
  });

  const { orders } = useSelectorApp((state) => state.orders);

  const dispatch = useDispatchApp();

  const onChangeLoadLastItem = (status) => setLoadLastItem(status);

  const getInitialData = async () => {
    setLoading(true);
    await dispatch(
      startGetOrdersByShop({
        limit: PAGE_SIZE !== -1 ? PAGE_SIZE + 1 : false,
        onChangeLastKey: onChangeLastKey,
      })
    );
    setLoading(false);
  };

  const getNextOrders = useDebounced(async () => {
    await dispatch(
      startGetOrdersByShop({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
        loadLastItem: () => onChangeLoadLastItem(true),
      })
    );
  }, 1000);

  const { rowsPerPage, lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: getNextOrders,
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(orders).length > 0) {
      const { ordersContent, reviewIDs } = onFilterOrders();
      onGetReviews(reviewIDs);
      const newData = [...data, ...ordersContent];
      setData(newData);
    } else {
      setData([]);
    }
    //eslint-disable-next-line
  }, [orders, filter]);

  useEffect(() => {
    if (isNearScreen && !loadLastItem) getNextOrders();
    //eslint-disable-next-line
  }, [isNearScreen]);

  const onGetReviews = async (reviewIDs) => {
    const newReviews = await dispatch(startGetReviewsByIDs(reviewIDs));
    if (!newReviews) return;

    setReviews({ ...reviews, ...newReviews });
  };

  const onChangeFilter = (filterSelect) => {
    if (filterSelect !== null) {
      if (filterSelect === filter) {
        setFilter(null);
      } else {
        setFilter(filterSelect);
      }
    } else {
      setFilter(filterSelect);
    }
  };
  const onFilterOrders = () => {
    let filteredData = [];
    let userIDs = [];
    let reviewIDs = [];
    const newKeysProcessed = [];

    Object.keys(orders).forEach((keyOrder) => {
      newKeysProcessed.push(keyOrder);

      const order = orders[keyOrder];
      const posts = orders[keyOrder].posts;

      if (!posts) return;

      userIDs.push(order.userID);

      const reviewID = order.reviewID || "";
      if (reviewID) {
        reviewIDs.push({ reviewID, userID: order.userID });
      }

      Object.keys(posts).forEach((keyPost) => {
        const url = posts[keyPost];
        if (!url.includes("firebasestorage") && !url.includes("amazonaws"))
          return;

        return filteredData.push({
          url,
          key: keyOrder,
          from: "order",
          userID: orders[keyOrder].userID,
          reviewID,
        });
      });
    });
    filteredData.sort(
      (a, b) => orders[b.key].creationTime - orders[a.key].creationTime
    );
    setKeysProcessed([...keysProcessed, ...newKeysProcessed]);
    return { ordersContent: filteredData, userIDs, reviewIDs };
  };

  const onFailedLoadUrl = (index) => {
    setData((prevState) => {
      const newData = [...prevState];
      const filteredData = newData.filter((item, i) => i !== index);
      return filteredData;
    });
  };

  const onSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      if (multiple) {
        setSelectedItems([...selectedItems, index]);
      } else {
        setSelectedItems([index]);
      }
    }
  };

  const onSelectPreview = (index) => {
    setSelectPreview(index);
  };

  const onSendSelectedItems = (indexes) => {
    const dataSelectedItems = [];
    (indexes || selectedItems).forEach((index) => {
      const item = data[index];
      dataSelectedItems.push({
        url: item.url,
        from: item.from,
        key: item.key,
        userID: item.userID,
      });
    });
    onCallbackSelectedItems(dataSelectedItems);
    onCloseModal();
  };

  const dataFiltered = filter
    ? data.filter((item) => {
        const extension = getExtensionFile(item.url);
        return (
          ((filter === "image" && IMAGE_FORMATS.includes(extension)) ||
            (filter === "video" && VIDEO_FORMATS.includes(extension))) &&
          acceptedFormats.includes(extension)
        );
      })
    : data;

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      aria-label={t(MODAL_GALLERY_ARIA_LABEL)}
      customComponentTitle={
        <>
          {!filterByFormat ? (
            <ChipsFilterGallery
              filterSelect={filter}
              onChangeFilter={onChangeFilter}
            />
          ) : null}
        </>
      }
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
        overflowX: "hidden",
        "::-webkit-scrollbar": {
          width: 0,
          height: 0,
          background: "transparent",
        },
      }}
      refContent={galleryRef}
    >
      {loading && (
        <Loader size={70} hasMessage={true} message={t(LOADING_CONTENT)} />
      )}
      {!loading && (
        <Gallery
          data={dataFiltered}
          reviews={reviews}
          onCloseModal={onCloseModal}
          lastItemRef={fromRef}
          loadLastItem={loadLastItem}
          onFailedLoadUrl={onFailedLoadUrl}
          onSelectItem={onSelectItem}
          selectedItems={selectedItems}
          onSelectPreview={onSelectPreview}
        />
      )}
      {!loading && dataFiltered.length === 0 && loadLastItem && (
        <Box
          sx={{
            height: "calc(100% - 64px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ml: "-12px",
          }}
        >
          <Typography variant="h4" align="center">
            {t(MODAL_GALLERY_EMPTY_CONTENT)}
          </Typography>
        </Box>
      )}

      {selectedItems.length > 0 && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <Button color="secondary" onClick={onSendSelectedItems}>
            {t(BUTTON_ADD_CONTENT)}
          </Button>
        </DialogActions>
      )}
      {selectPreview !== null && (
        <ModalMedia
          modalOpen={selectPreview !== null}
          onCloseModal={() => {
            setSelectPreview(null);
          }}
          url={data[selectPreview].url}
          customActions={
            <ContainerModalActions
              hasPadding={false}
              propsSecondaryButton={{
                showButton: true,
                text: BUTTON_PREVIOUS,
                onClick: () => setSelectPreview(null),
              }}
              propsPrimaryButton={{
                showButton: true,
                text: selectedItems.includes(selectPreview)
                  ? BUTTON_REMOVE_SELECTION
                  : BUTTON_SELECT_THIS_CONTENT,
                onClick: () => {
                  if (selectedItems.includes(selectPreview)) {
                    setSelectedItems(
                      selectedItems.filter((item) => item !== selectPreview)
                    );
                  } else {
                    if (multiple) {
                      onSelectItem(selectPreview);
                    } else {
                      onSendSelectedItems([selectPreview]);
                    }
                  }
                  setSelectPreview(null);
                },
              }}
            />
          }
          disabledActions={true}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalGallery;
