import React, { useState } from "react";
import ModalGalleryArrowButtons from "../components/Common/Gallery/ModalGalleryArrowButtons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTour } from "@reactour/tour";

const Order = () => {
  const [modalOpen, setModalOpen] = useState(true);

  const [params] = useSearchParams();
  const orderID = params.get("orderID");

  const { isOpen } = useTour();

  const navigate = useNavigate();

  const onCloseModal = () => {
    setModalOpen(false);
    if (!isOpen) {
      navigate("/publications");
    }
  };

  return (
    <ModalGalleryArrowButtons
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      mode="order"
      orderID={orderID}
    />
  );
};

export default Order;
