import React from "react";
import Select from "../Selects/Select";
import { optionsSearchInfluencers } from "../../../utils/slidersMarks";

const SelectSearchTypeInfluencers = ({
  control,
  errors,
  name,
  label,
  useOnChange = true,
  actionsChange = () => {},
  sx = {},
}) => {
  return (
    <Select
      control={control}
      name={name}
      errors={errors}
      options={optionsSearchInfluencers}
      actionsChange={actionsChange}
      useOnChange={useOnChange}
      doTranslate={true}
      label={label}
      sx={sx}
    />
  );
};

export default SelectSearchTypeInfluencers;
