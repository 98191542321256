import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RED_COLOR } from "../../../utils/colors";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <Box>
      <Typography variant="body2" sx={{ color: isDanger && RED_COLOR }}>
        {value}
      </Typography>
      <Typography variant="body2" sx={{ color: isDanger && RED_COLOR }}>
        {type}
      </Typography>
    </Box>
  );
};

export default DateTimeDisplay;
