import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {
  numberCompactFormat,
  numberDecimal,
} from "../../../utils/numberFormats";
import InfluencerNodeData from "./InfluencerNodeData";
import Button from "../Buttons/Button";
import InfluencerNameLogo from "./InfluencerNameLogo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslationApp } from "../../../lib/i18next";
import {
  AUDIENCE,
  BUTTON_VIEW_REPORT,
  ENGAGEMENT,
  SCORE,
} from "../../../locales/keysTranslations";
import { BUTTON_INFLUENCER_VIEW_REPORT } from "../../../onboarding/stepsSelectors";
import LinkText from "../Texts/LinkText";

const InfluencerDetailHorizontal = ({
  data,
  userID,
  analytics,
  hasDivider,
  colorButton,
  sx,
  sxNameLogo = {},
  type = "influencer",
  isExternal = false,
  redirectSocial = false,
}) => {
  const { t } = useTranslationApp();

  const getNumberDisplay = ({ analytics, metric, sign }) => {
    const signDisplay = metric === "audiencie" ? "" : sign;
    let number =
      metric === "audience"
        ? numberCompactFormat(analytics.followers)
        : numberDecimal(
            analytics.engagement ?? analytics.likes / analytics.followers
          );
    if (number === 0 || number === "0" || number === 0.01) return "-";
    return `${number}${signDisplay}`;
  };

  const breakButtonAnalytics = useMediaQuery("(min-width: 450px)");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: "center",
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: breakButtonAnalytics ? "row" : "column",
            alignItems: breakButtonAnalytics ? "center" : "flex-start",
            gap: 2,
            mb: 2,
            ...sxNameLogo,
          }}
        >
          <InfluencerNameLogo
            data={data}
            userID={userID}
            sizeType="auto"
            isExternal={isExternal}
            sx={{ mb: 0 }}
            redirectSocial={redirectSocial}
          />
          {(data?.hasAnalytics || data?.isExternal) && (
            <LinkText
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                fontWeight: 600,
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              dataTour={BUTTON_INFLUENCER_VIEW_REPORT}
              href={
                data?.isExternal
                  ? `/influencer?platform=${data?.mainPlatform}&accountName=${data?.mainAccountName}&from=discovery`
                  : `/influencer?userID=${userID}`
              }
              target="_blank"
            >
              <Button color={colorButton}>{t(BUTTON_VIEW_REPORT)}</Button>
            </LinkText>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: { xs: 1, sm: 3 },
              flexWrap: "wrap",
            }}
          >
            <InfluencerNodeData
              title={t(AUDIENCE)}
              data={getNumberDisplay({
                analytics,
                metric: "audience",
                sign: "",
              })}
              sx={{
                minHeight: "unset",
              }}
            />
            <InfluencerNodeData
              title={t(ENGAGEMENT)}
              data={getNumberDisplay({
                analytics,
                metric: "engagement",
                sign: "%",
              })}
              sx={{
                minHeight: "unset",
              }}
            />
            {type === "influencer" && (
              <Box>
                <InfluencerNodeData
                  title={t(SCORE)}
                  data={numberDecimal(data.score || 0)}
                  isRating={true}
                  sx={{
                    minHeight: "unset",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {hasDivider && (
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        />
      )}
    </>
  );
};

export default InfluencerDetailHorizontal;
