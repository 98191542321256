import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { DatePicker } from "@mui/x-date-pickers";
import addDays from "date-fns/addDays";
import startOfDay from "date-fns/startOfDay";
import { startDisableInfluencerAccountForTime } from "../../../actions/owner";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import {
  DEACTIVATE,
  END_DATE_PLACEHOLDER,
  FIELD_END_DATE_GREATER_NOW,
  MODAL_DISABLED_CREATOR_OWNER_TITLE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalDisableInfluencerAccount = ({
  modalOpen,
  onCloseModal,
  userID,
  onCallbackSuccess,
}) => {
  const [error, setError] = useState(null);

  const { control, getValues } = useForm({
    defaultValues: {
      disableDate: addDays(new Date(), 1),
    },
  });

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onSubmit = async () => {
    const formValues = getValues();
    if (formValues.disableDate.getTime() < new Date().getTime()) {
      return setError(t(FIELD_END_DATE_GREATER_NOW));
    }
    setError(null);
    const disableDate = startOfDay(formValues.disableDate);
    const response = await dispatch(
      startDisableInfluencerAccountForTime({
        userID,
        disableDate,
      })
    );
    if (response) {
      onCallbackSuccess();
      return onCloseModal();
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_DISABLED_CREATOR_OWNER_TITLE)}
      title={t(MODAL_DISABLED_CREATOR_OWNER_TITLE)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {!!error && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Alert
              severity="error"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                align: "center",
              }}
            >
              {error}
            </Alert>
          </Box>
        )}
        <Controller
          name="disableDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              label={t(END_DATE_PLACEHOLDER)}
              disablePast
              minDate={addDays(new Date(), 1)}
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
            />
          )}
        />
        <ContainerModalActions
          hasPadding={false}
          propsPrimaryButton={{
            showButton: true,
            text: DEACTIVATE,
            onClick: onSubmit,
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalDisableInfluencerAccount;
