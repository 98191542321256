import React from "react";
import { useDispatchApp } from "../../../lib/redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { startUpdateIsReadNotification } from "../../../actions/notifications";
import {
  DATE_DISTANCE,
  ONE_SECOND_AGO,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { capitalizeFirstLetter } from "../../../utils/formats";
import {
  NOTIFICATION_TYPE_NEW_MESSAGE,
  NOTIFICATION_TYPE_REQUEST,
  TAB_REQUESTS_INDEX,
} from "../../../utils/constants";
import { startOpenInfluencerConversation } from "../../../actions/chat";
import { useTheme } from "@mui/material";

const Notification = ({ data, notificationKey }) => {
  const isMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const theme = useTheme();

  const { t } = useTranslationApp();
  const onClick = () => {
    dispatch(startUpdateIsReadNotification(notificationKey));
    if (data?.type === NOTIFICATION_TYPE_REQUEST && data?.params?.requestID) {
      navigate(`/creators?tab=${TAB_REQUESTS_INDEX}&requestID=${data.params.requestID}
      `);
      return;
    }
    if (data.route) {
      navigate(`/${data.route}`);
      return;
    }
    if (data.type === NOTIFICATION_TYPE_NEW_MESSAGE) {
      onOpenChatInfluencer(data?.params?.userID);
      return;
    }
  };

  const onOpenChatInfluencer = (userID) => {
    dispatch(
      startOpenInfluencerConversation({
        userID,
      })
    );
  };

  const getDateAgo = (date) => {
    const dateStart = new Date(date);
    const dateEnd = new Date();

    if (dateStart.getTime() > dateEnd.getTime()) {
      return t(ONE_SECOND_AGO);
    }

    return capitalizeFirstLetter(
      t(DATE_DISTANCE, {
        date: dateStart,
      })
    );
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        p: 2,
        backgroundColor: "background.paper",
        backgroundImage: "none",
        "&:hover": {
          cursor: "pointer",
          filter: theme.palette.hover.main,
        },
      }}
      onClick={onClick}
      square
    >
      <Box
        sx={{
          maxWidth: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isMobile && !data.isRead && (
          <Box
            sx={{
              backgroundColor: "red",
              width: 10,
              height: 10,
              borderRadius: 3,
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h6">{data.title}</Typography>
        {!isMobile && (
          <Typography variant="subtitle2" sx={{ mt: -1 }}>
            {getDateAgo(data.date)}
          </Typography>
        )}
        <Typography variant="body2">{data.description}</Typography>
      </Box>
      {!isMobile ? (
        <Box
          sx={{
            maxWidth: 15,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          {!data.isRead && (
            <Box
              sx={{
                mt: 1,
                backgroundColor: "red",
                width: 10,
                height: 10,
                borderRadius: 3,
              }}
            />
          )}
        </Box>
      ) : (
        <Box sx={{ width: "15%", textAlign: "end" }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {getDateAgo(data.date)}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default Notification;
