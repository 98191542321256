import React, { useEffect, useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { startGetAcceptedTerms } from "../../../actions/shops";
import { ACCEPT_TERMS_PAY } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { getNameBusinessTheme } from "../../../actions/getters";
import LinkText from "../Texts/LinkText";

const AcceptTerms = ({ onChangeIsAcceptedTerms, onChangeCheck }) => {
  const [check, setCheck] = useState(false);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(true);

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { t } = useTranslationApp();

  const getAcceptedTerms = async () => {
    const response = await dispatch(startGetAcceptedTerms());
    if (!response) {
      onChangeIsAcceptedTerms(false);
      return setIsAcceptedTerms(false);
    }
    return onChangeIsAcceptedTerms(true);
  };

  useEffect(() => {
    getAcceptedTerms();
    //eslint-disable-next-line
  }, []);

  if (isAcceptedTerms) return null;

  return (
    <Box sx={{ mt: 2, display: "flex", alignItems: "flex-start" }}>
      <Checkbox
        sx={{ p: 0, mr: 1 }}
        checked={check}
        onChange={(e) => {
          setCheck(e.target.checked);
          onChangeCheck(e.target.checked);
        }}
      />
      <Typography variant="body1" align="left">
        <LinkText
          href="https://www.heybuzzly.com/saas-terms"
          target="_blank"
          sx={{ color: "Highlight" }}
        >
          {t(ACCEPT_TERMS_PAY, {
            NAME_BUSINESS,
          })}
        </LinkText>
      </Typography>
    </Box>
  );
};

export default AcceptTerms;
