import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { startGetPaymentsFailed } from "../../actions/payments";
import { usePagination } from "../../hooks/usePagination";
import { DEFAULT_TABLE_PAGES } from "../../utils/constants";
import TitlePage from "../../components/Common/Texts/TitlePage";
import Box from "@mui/material/Box";
import Loader from "../../components/Common/Loader/Loader";
import PaymentsFailedTable from "../../components/Owner/PaymentsFailed/PaymentsFailedTable";

const PaymentsFailed = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatchApp();

  const payments = useSelectorApp((state) => state.payments.paymentsFailed);

  const getNextPaymentsFailed = async () => {
    dispatch(
      startGetPaymentsFailed({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(
      startGetPaymentsFailed({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
        onCallBack: () => setIsLoading(false),
      })
    );
  };

  const { rowsPerPage, page, lastKey, onChangeLastKey, onPageChange } =
    usePagination({
      rowsPerPageValue: DEFAULT_TABLE_PAGES,
      onCallBackNextPage: getNextPaymentsFailed,
    });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(payments);
    //eslint-disable-next-line
  }, [payments]);

  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <TitlePage>Pagos Fallidos</TitlePage>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          mt: 2,
        }}
      >
        {isLoading ? (
          <Loader
            size={70}
            hasMessage={true}
            message="Cargando información"
            variantMessage="h5"
            fullWidth={true}
          />
        ) : (
          <PaymentsFailedTable
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default PaymentsFailed;
