import React, { useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import {
  FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
  RED_COLOR,
} from "../../../utils/colors";
import { setNewOrderInStore } from "../../../actions/ui";
import i18next from "i18next";
import {
  NEW_ORDER,
  SELECT_STORE_PLACEHOLDER,
  STORE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocale } from "../../../Contexts/LocaleContext";

const SelectStore = ({
  storeSelected,
  storesList = {},
  showNewOrder = false,
  onChange,
  hiddenSpecialStores = false,
  showEvents = false,
  label = i18next.t(STORE),
  dataTour = "",
  showTooltip = true,
}) => {
  const { t } = useTranslationApp();
  const { storesWithNewOrder } = useSelectorApp((state) => state.ui);
  const dispatch = useDispatchApp();

  const LOCALE = useLocale();
  const SPECIAL_STORES = Object.keys(LOCALE.specialStores || {});

  // const { events } = useSelectorApp((state) => state.events);
  // const eventKeys = Object.keys(events);

  const storesListKeys = Object.keys(storesList);

  useEffect(() => {
    if (storesListKeys.length === 0) return;
    if (storesListKeys.length === 1)
      onChange({ target: { value: storesListKeys[0] } });

    // eslint-disable-next-line
  }, [storesList]);

  const showTooltipSelectStore =
    storesListKeys.length > 1 && !storeSelected && showTooltip;

  return (
    <FormControl
      fullWidth
      sx={{ maxWidth: 250 }}
      variant="filled"
      data-tour={dataTour}
    >
      <InputLabel id="select-store">{label}</InputLabel>
      <Select
        labelId="select-store"
        id="select-store"
        value={storeSelected}
        label={label}
        onChange={onChange}
      >
        {Object.keys(storesList).map((key) => {
          if (
            hiddenSpecialStores &&
            SPECIAL_STORES.includes(storesList[key].storeType)
          )
            return null;
          return (
            <MenuItem
              key={key}
              value={key}
              onClick={() => {
                showNewOrder &&
                  dispatch(
                    setNewOrderInStore({
                      store: key,
                      status: false,
                    })
                  );
              }}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {`${storesList[key].name}`}
              <Typography
                variant="body1"
                sx={{ color: RED_COLOR, fontWeight: 600 }}
              >
                {`${
                  showNewOrder && storesWithNewOrder[key]
                    ? `¡${t(NEW_ORDER)}!`
                    : ""
                }`}
              </Typography>
            </MenuItem>
          );
        })}
        {/* {showEvents &&
          eventKeys.map((eventID) => (
            <MenuItem
            key={eventID}
            value={eventID}
            sx={{ display: "flex", justifyContent: "space-between" }}
            >
            {`${t(EVENT)}: ${events[eventID].name}`}
            </MenuItem>
          ))} */}
      </Select>
      {showTooltipSelectStore && (
        <Tooltip
          className="animate__animated animate__fadeInUp animate__faster"
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              positionFixed: true,
              modifiers: {
                preventOverflow: {
                  enabled: true,
                  boundariesElement: "window",
                },
              },
            },
          }}
          componentsProps={{
            popper: {
              sx: {
                zIndex: 1000,
              },
            },
            tooltip: {
              sx: {
                p: 0,
                background: GRADIENT_PURPLE_FUCHSIA,
                borderRadius: 3,
                mt: "8px !important",
                ".MuiTooltip-arrow": {
                  color: FUCHSIA,
                },
              },
            },
          }}
          open={showTooltipSelectStore}
          title={
            <Box sx={{ p: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  fontSize: "0.975rem",
                }}
              >
                {t(SELECT_STORE_PLACEHOLDER)}
              </Typography>
            </Box>
          }
          arrow
        >
          <div />
        </Tooltip>
      )}
    </FormControl>
  );
};

export default SelectStore;
