import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import CampaignsTable from "../components/User/Campaigns/CampaignsTable";
import Button from "../components/Common/Buttons/Button";
import { useNavigate } from "react-router-dom";
import {
  cleanDataCampaign,
  startChangeStatusCampaign,
  startGetCampaignsByShop,
} from "../actions/campaigns";
import { usePagination } from "../hooks/usePagination";
import { DEFAULT_TABLE_PAGES } from "../utils/constants";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import {
  PAGE_CAMPAIGNS,
  PAGE_CAMPAIGNS_BUTTON_CREATE_CAMPAIGN,
} from "../onboarding/stepsSelectors";
import { useLocale } from "../Contexts/LocaleContext";
import { useTour } from "@reactour/tour";
import {
  BUTTON_CREATE_CAMPAIGN,
  CAMPAIGNS_DESCRIPTION,
  CAMPAIGNS_TITLE,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import { getIsBlockedActions } from "../actions/getters";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { isOpen, currentStep, setCurrentStep, steps } = useTour();
  const step = steps[currentStep];

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const { user } = useSelectorApp((state) => state.auth);
  const { campaigns: campaignsData } = useSelectorApp(
    (state) => state.campaigns
  );

  const LOCALE = useLocale();

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const getNextCampaigns = async () => {
    await dispatch(
      startGetCampaignsByShop({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextCampaigns,
  });

  const getInitialData = () => {
    dispatch(
      startGetCampaignsByShop({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
        onCallbackFinish: () => setIsLoading(false),
      })
    );
  };

  useEffect(() => {
    if (!isOpen) return;
  }, [isOpen, currentStep, step]);

  useEffect(() => {
    dispatch(cleanDataCampaign());
    getInitialData();
    //eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    setCampaigns(campaignsData);
    //
  }, [campaignsData]);

  const onClickCampaign = (id) => {
    return navigate(`/campaign?campaignID=${id}`);
  };

  const onChangeStatusCampaign = async ({ campaignID, status }) => {
    await dispatch(
      startChangeStatusCampaign({ campaignID, status, locale: LOCALE })
    );
  };

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            justifyContent: "space-between",
            width: "100%",
            mb: 3,
          }}
        >
          <Box>
            <TitlePage
              dataTour={PAGE_CAMPAIGNS}
              // hasTooltip={true}
              // toolTipAction={() => {
              //   setSteps(STEPS_CAMPAIGNS_FUNCTIONALITY);
              //   setIsOpen(true);
              // }}
            >
              {t(CAMPAIGNS_TITLE)}
            </TitlePage>
            <DescriptionPage>{t(CAMPAIGNS_DESCRIPTION)}</DescriptionPage>
          </Box>
          <Box>
            <Button
              onClick={() => {
                const isBlocked = dispatch(
                  getIsBlockedActions({
                    verifyBlockedFreeTrial: true,
                  })
                );
                if (isBlocked) return;
                navigate("/create-campaign");
                if (isOpen) {
                  setCurrentStep(currentStep + 1);
                }
              }}
              fullWidth={isMobile}
              sx={{ minWidth: 140 }}
              dataTour={PAGE_CAMPAIGNS_BUTTON_CREATE_CAMPAIGN}
            >
              {t(BUTTON_CREATE_CAMPAIGN)}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <CampaignsTable
            data={campaigns}
            onClickCampaign={onClickCampaign}
            onChangeStatusCampaign={onChangeStatusCampaign}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            loading={isLoading}
          />
        </Box>
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default Campaigns;
