import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NumberField from "../../Common/NumberFormats/NumberField";
import {
  STAFF_ROLE,
  PAYMENT_MODEL_PACKAGE,
  PAYMENT_MODEL_USE,
  PLAN_BASIC,
  DEFAULT_PACKAGE_PRICES,
} from "../../../utils/constants";
import StaffListDropdown from "../../Common/Staff/StaffListDropdown";
import { getNameBusinessTheme, getRole } from "../../../actions/getters";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  BUSINESS_NAME_PLACEHOLDER,
  BUTTON_CREATE_USER,
  COST_COLLABORATION_BASIC_PLACEHOLDER,
  COST_COLLABORATION_PLACEHOLDER,
  COST_COLLABORATION_PRO_PLACEHOLDER,
  CREDITS_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  NAME_AND_LAST_NAME_PLACEHOLDER,
  PASSWORD_CONFIRMATION_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  PLAN_PACKAGE,
  PLAN_PAY_PER_USE,
  PRICE_BASIC_PLACEHOLDER,
  PRICE_PRO_PLACEHOLDER,
  SELECT_TYPE_PLAN_PLACEHOLDER,
  TRIAL_DAYS_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useLocales } from "../../../Contexts/LocaleContext";

const CreateShopForm = ({ data, onChangeForm }) => {
  const dispatch = useDispatchApp();
  const userRole = dispatch(getRole());

  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { t } = useTranslationApp();
  const country = useSelectorApp((state) => state?.auth?.user?.country);
  const LOCALES = useLocales();
  const LOCALE = LOCALES[country];

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1.5 }}>
      <TextField
        name="businessName"
        value={data.businessName}
        onChange={onChangeForm}
        label={t(BUSINESS_NAME_PLACEHOLDER)}
        fullWidth
        variant="filled"
      />
      <FormControl sx={{ width: "100%" }} variant="filled" disabled={true}>
        <InputLabel id="label-model-payment">
          {t(SELECT_TYPE_PLAN_PLACEHOLDER)}
        </InputLabel>
        <Select
          onChange={onChangeForm}
          id="label-model-payment"
          value={data.paymentModel}
          name="paymentModel"
          variant="filled"
          label={t(SELECT_TYPE_PLAN_PLACEHOLDER)}
        >
          <MenuItem value={PAYMENT_MODEL_USE}>{t(PLAN_PAY_PER_USE)}</MenuItem>
          <MenuItem value={PAYMENT_MODEL_PACKAGE}>{t(PLAN_PACKAGE)}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        name="clappCredits"
        value={data.clappCredits}
        onChange={onChangeForm}
        label={t(CREDITS_PLACEHOLDER, {
          NAME_BUSINESS,
        })}
        fullWidth
        variant="filled"
      />
      {data.paymentModel === PAYMENT_MODEL_USE && (
        <>
          <Typography variant="body1" gutterBottom>
            {t(VALUE_EXPRESSED_IN, {
              currency: LOCALE.currency,
            })}
          </Typography>
          <TextField
            name="costCollaboration"
            value={data.costCollaboration}
            onChange={onChangeForm}
            label={t(COST_COLLABORATION_PLACEHOLDER)}
            fullWidth
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
          />
        </>
      )}
      <TextField
        name="trialDays"
        value={data.trialDays}
        onChange={onChangeForm}
        label={t(TRIAL_DAYS_PLACEHOLDER)}
        fullWidth
        variant="filled"
        InputProps={{
          inputComponent: NumberField,
        }}
      />
      {data.paymentModel === PAYMENT_MODEL_PACKAGE && (
        <>
          <Typography variant="body1" gutterBottom>
            {t(VALUE_EXPRESSED_IN, {
              currency:
                DEFAULT_PACKAGE_PRICES?.[PLAN_BASIC]?.currency ||
                LOCALE?.currency,
            })}
          </Typography>
          <TextField
            name="priceBasic"
            value={data.priceBasic}
            onChange={onChangeForm}
            label={t(PRICE_BASIC_PLACEHOLDER)}
            fullWidth
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
          />
          <TextField
            name="costCollaborationBasic"
            value={data.costCollaborationBasic}
            onChange={onChangeForm}
            label={t(COST_COLLABORATION_BASIC_PLACEHOLDER)}
            fullWidth
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
          />
          <TextField
            name="pricePro"
            value={data.pricePro}
            onChange={onChangeForm}
            label={t(PRICE_PRO_PLACEHOLDER)}
            fullWidth
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
          />
          <TextField
            name="costCollaborationPro"
            value={data.costCollaborationPro}
            onChange={onChangeForm}
            label={t(COST_COLLABORATION_PRO_PLACEHOLDER)}
            fullWidth
            variant="filled"
            InputProps={{
              inputComponent: NumberField,
            }}
          />
        </>
      )}

      {userRole !== STAFF_ROLE && (
        <StaffListDropdown
          doAssign={false}
          fullWidth={true}
          variant="filled"
          onChange={(value) => {
            onChangeForm({ target: { name: "staffID", value } });
          }}
        />
      )}

      <FormControlLabel
        name="createUser"
        control={
          <Checkbox
            checked={data.createUser}
            onChange={(e) => {
              onChangeForm({
                target: {
                  name: "createUser",
                  value: e.target.checked,
                },
              });
            }}
            name="createUser"
          />
        }
        label={t(BUTTON_CREATE_USER)}
      />

      {data.createUser && (
        <>
          <TextField
            name="name"
            value={data.name}
            onChange={onChangeForm}
            label={t(NAME_AND_LAST_NAME_PLACEHOLDER)}
            fullWidth
            variant="filled"
          />
          <TextField
            name="email"
            value={data.email}
            onChange={onChangeForm}
            label={t(EMAIL_PLACEHOLDER)}
            fullWidth
            variant="filled"
          />
          <TextField
            name="password"
            value={data.password}
            onChange={onChangeForm}
            label={t(PASSWORD_PLACEHOLDER)}
            fullWidth
            variant="filled"
          />
          <TextField
            name="repeatPassword"
            value={data.repeatPassword}
            onChange={onChangeForm}
            label={t(PASSWORD_CONFIRMATION_PLACEHOLDER)}
            fullWidth
            variant="filled"
          />
        </>
      )}
    </Box>
  );
};

export default CreateShopForm;
