import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_STATUS_PENDING,
  APPLICANT_STATUS_REJECTED,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_FINISHED,
} from "../utils/constants";
import Loader from "../components/Common/Loader/Loader";
import Campaign from "../components/User/Campaigns/CampaignDetail/Campaign/Campaign";
import {
  cleanDataCampaign,
  startAcceptedApplicant,
  startFinishedCampaign,
  startGetApplicantsByCampaignID,
  startGetCampaignByID,
  startGetInvitationsCampaigns,
  startInvitedApplicant,
  startRejectedApplicant,
  startSaveCampaign,
} from "../actions/campaigns";
import { startGetHasAdvancedCampaignPaymentsShop } from "../actions/shops";
import { formatCitiesSave } from "../utils/formats";
// import InvitationsCampaigns from "../components/User/Campaigns/CampaignDetail/InvitationsCampaigns/InvitationsCampaigns";
// import InvitationsExternals from "../components/User/Campaigns/CampaignDetail/InvitationsExternals/InvitationsExternals";
import TitlePage from "../components/Common/Texts/TitlePage";
import ApplicantsContainer from "../components/User/Campaigns/CampaignDetail/Applicants/ApplicantsContainer";
import { useLocale } from "../Contexts/LocaleContext";
import { useTour } from "@reactour/tour";
import { PAGE_CAMPAIGN_TITLE } from "../onboarding/stepsSelectors";
import { useTranslationApp } from "../lib/i18next";
import {
  BUTTON_FINISH,
  BUTTON_REJECT,
  CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION,
  CAMPAIGN_DETAIL_TITLE,
  CAMPAIGN_FINISHED_CONFIRMATION,
  CAMPAIGN_FINISHED_TIME,
  CAMPAIGN_FINISH_APPLICANTS_ACCEPTED,
  CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE,
  CREATE_CAMPAIGN_HAS_BEEN_SAVE,
  LOADING_INFORMATION,
  WARNING,
} from "../locales/keysTranslations";
import ModalGalleryArrowButtons from "../components/Common/Gallery/ModalGalleryArrowButtons";
import ModalSendInviteApplicant from "../components/User/Campaigns/CampaignDetail/Applicants/ModalSendInviteApplicant";
import { differenceInDays } from "date-fns";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const CampaignDetail = () => {
  const [campaign, setCampaign] = useState({});
  const [applicants, setApplicants] = useState({});
  const [invitesCampaign, setInvitesCampaign] = useState({});
  const [modalOpenPublication, setModalPublication] = useState(false);
  const [modalOpenSendInvitation, setModalSendInvitation] = useState(false);
  const [applicantSelected, setApplicantSelected] = useState(null);

  const { isOpen } = useTour();

  const { t } = useTranslationApp();

  const [isLoading, setIsLoading] = useState(isOpen ? false : true);

  const {
    applicants: applicantsData,
    campaigns,
    invitations,
  } = useSelectorApp((state) => state.campaigns);
  const { influencers } = useSelectorApp((state) => state.influencers);
  const { user } = useSelectorApp((state) => state.auth);

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery("(min-width:600px)");

  const campaignID = params.get("campaignID");

  const LOCALE = useLocale();

  const getCampaignAndApplicantsData = async () => {
    dispatch(cleanDataCampaign());

    if (!campaignID) {
      return navigate("/campaigns");
    }

    let campaignData;

    if (!isOpen) {
      campaignData = await dispatch(startGetCampaignByID(campaignID));
    } else {
      campaignData = campaigns[campaignID];
    }

    if (!campaignData) {
      return navigate("/campaigns");
    }
    const citiesFormatted = formatCitiesSave({
      mode: "load",
      cities: campaignData.audienceLocation,
      citiesShop: LOCALE.cities,
    });
    campaignData = {
      ...campaignData,
      audienceLocation: citiesFormatted,
    };
    dispatch(startGetHasAdvancedCampaignPaymentsShop());

    if (!isOpen) {
      await dispatch(startGetApplicantsByCampaignID({ campaignID }));
      await dispatch(
        startGetInvitationsCampaigns({
          campaignID,
        })
      );
    }

    setCampaign({ campaignID, ...campaignData });
    setIsLoading(false);
  };

  const onDeleteRejectedApplicants = () => {
    let filteredData = {};
    Object.keys(applicantsData).forEach((applicantKey) => {
      if (
        applicantsData[applicantKey].status !== APPLICANT_STATUS_REJECTED &&
        applicantsData[applicantKey].campaignID === campaignID
      ) {
        return (filteredData[applicantKey] = applicantsData[applicantKey]);
      }
    });
    setApplicants(filteredData);
  };

  useEffect(() => {
    getCampaignAndApplicantsData();
    // eslint-disable-next-line
  }, [campaignID, user.shopID]);

  useEffect(() => {
    onDeleteRejectedApplicants();
    // eslint-disable-next-line
  }, [applicantsData]);

  useEffect(() => {
    setInvitesCampaign(invitations);
  }, [invitations]);

  const onSaveCampaign = async (data, status) => {
    const response = await dispatch(
      startSaveCampaign(campaignID, data, status)
    );
    if (response.ok) {
      SimpleAlert({
        title:
          status === CAMPAIGN_STATUS_ACTIVE
            ? t(CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE)
            : t(CREATE_CAMPAIGN_HAS_BEEN_SAVE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      setCampaign({
        ...campaign,
        ...response.data,
      });
    }
    return response.ok;
  };

  const onFinishedCampaign = async () => {
    const responseAlert = await ConfirmAlert({
      title: t(CAMPAIGN_FINISHED_CONFIRMATION),
      confirmButtonText: t(BUTTON_FINISH),
    });

    if (!responseAlert.isConfirmed) return;

    if (
      campaign.lastActiveDate &&
      differenceInDays(new Date(), new Date(campaign.lastActiveDate)) < 1
    ) {
      return SimpleAlert({
        title: t(WARNING),
        text: t(CAMPAIGN_FINISHED_TIME),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }

    const applicantsIDS = Object.keys(applicants || {});

    let doFinishedCampaign = true;
    applicantsIDS.forEach((applicantID) => {
      const applicant = applicants[applicantID];
      const status = applicant.status;

      if (
        status === APPLICANT_STATUS_PENDING ||
        status === APPLICANT_STATUS_REJECTED
      )
        return;

      if (status !== APPLICANT_STATUS_DELIVERED) {
        doFinishedCampaign = false;
      }
    });

    if (!doFinishedCampaign) {
      SimpleAlert({
        title: t(WARNING),
        text: t(CAMPAIGN_FINISH_APPLICANTS_ACCEPTED),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }

    const response = await dispatch(startFinishedCampaign(campaignID));

    if (response.ok) {
      setCampaign({
        ...campaign,
        status: CAMPAIGN_STATUS_FINISHED,
      });
    }

    return true;
  };

  const onSelectApplicant = async (applicantID) => {
    const influencerData = influencers[applicants[applicantID].userID];
    setApplicantSelected({
      ...influencerData,
      userID: applicants[applicantID].userID,
      applicantID,
    });
    setModalPublication(true);
  };

  const onAcceptApplicant = async ({ applicantID, paymentID }) => {
    const responseAccepted = await dispatch(
      startAcceptedApplicant({ applicantID, paymentID })
    );
    if (!responseAccepted) return;

    setApplicantSelected({
      ...applicants[applicantID],
      applicantID,
    });
  };

  const onRejectedApplicant = async (applicantID) => {
    const response = await ConfirmAlert({
      title: t(CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION),
      confirmButtonText: t(BUTTON_REJECT),
    });
    if (response.isConfirmed) {
      await dispatch(startRejectedApplicant(applicantID));
      return true;
    }
    return false;
  };

  const onInviteApplicant = async (inviteID) => {
    dispatch(
      startInvitedApplicant({
        inviteID,
        applicantID: applicantSelected.applicantID,
      })
    );
  };

  return (
    <ContainerPage
      mode="padding"
      sx={{
        minHeight: "calc(100vh - 64px)",
        mb: 18,
      }}
    >
      {isLoading ? (
        <Loader
          size={40}
          hasMessage={true}
          message={t(LOADING_INFORMATION)}
          variantMessage="body1"
          fullWidth={true}
        />
      ) : (
        <React.Fragment>
          <TitlePage
            dataTour={PAGE_CAMPAIGN_TITLE}
            sx={{
              fontSize: { xs: "1.9rem", sm: "2.5rem" },
            }}
          >
            {t(CAMPAIGN_DETAIL_TITLE, {
              name: campaign.name || "",
            })}
          </TitlePage>
          <Box
            sx={{
              mt: 2,
              width: "100%",
            }}
          >
            <ApplicantsContainer
              data={applicants}
              dataCampaign={campaign}
              invitesCampaign={invitesCampaign}
              onAcceptApplicant={onAcceptApplicant}
              onRejectedApplicant={onRejectedApplicant}
              onSelectApplicant={onSelectApplicant}
              statusCampaign={campaign.status}
              campaignID={campaignID}
            />
            <Campaign
              data={campaign}
              onFinishedCampaign={onFinishedCampaign}
              onSaveCampaign={onSaveCampaign}
              isMobile={isMobile}
              isLoading={isLoading}
            />
          </Box>
        </React.Fragment>
      )}
      {modalOpenSendInvitation && (
        <ModalSendInviteApplicant
          modalOpen={modalOpenSendInvitation}
          onCloseModal={() => setModalSendInvitation(false)}
          applicantID={applicantSelected.applicantID}
          onCallbackInvite={onInviteApplicant}
        />
      )}
      {modalOpenPublication && (
        <ModalGalleryArrowButtons
          modalOpen={modalOpenPublication}
          onCloseModal={() => setModalPublication(false)}
          mode="applicant"
          applicantID={applicantSelected.applicantID}
        />
      )}
    </ContainerPage>
  );
};

export default CampaignDetail;
