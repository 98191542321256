import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { AUDIENCE_EXPLANATION } from "../../../../locales/keysTranslations";

const ContentAudience = () => {
  const { t } = useTranslationApp();
  return (
    <React.Fragment>
      <Typography color="inherit" variant="body1" sx={{ mb: 1 }}>
        {t(AUDIENCE_EXPLANATION)}
      </Typography>
    </React.Fragment>
  );
};

export default ContentAudience;
