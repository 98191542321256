import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  PAYMENT_AMBASSADOR,
  PAYMENT_APPLICANT,
  PAYMENT_INFLUENCER,
  PAYMENT_INVITATION,
  PLANS_PACKAGE,
  PLANS_SUBSCRIPTION,
  PLAN_COLLABORATION,
  SUBSCRIPTION_PRO,
} from "../../../../utils/constants";
import { capitalizeFirstLetter } from "../../../../utils/formats";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CLOSE,
  BUTTON_VIEW_INVOICE,
  MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID,
  MODAL_INVOICE_ARIA_LABEL,
  MODAL_INVOICE_DISCLAIMER,
  MODAL_INVOICE_NUMBER_CREDITS,
  MODAL_INVOICE_ORDER_TO_COLLECT,
  MODAL_INVOICE_TITLE,
  VALUE_WITH_CURRENCY,
  PAY,
  TOTAL,
  MODAL_INVOICE_SUBSCRIPTION,
  MODAL_INVOICE_APPLICANT,
  MODAL_INVOICE_AMBASSADOR,
  MODAL_INVOICE_FEE,
  MODAL_INVOICE_IVA,
  MODAL_INVOICE_COLLABORATION,
  MODAL_INVOICE_CREDITS,
  MODAL_INVOICE_INFLUENCER,
  MODAL_INVOICE_INVITATION,
  MODAL_INVOICE_LICENSE,
} from "../../../../locales/keysTranslations";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { getNameBusinessTheme } from "../../../../actions/getters";
import LinkText from "../../../Common/Texts/LinkText";
import ContainerModalActions from "../../../Common/Containers/ContainerModalActions";
import { openLink } from "../../../../utils/urls";
import { startGetInvoiceUrl } from "../../../../actions/payments";
import addMonths from "date-fns/addMonths";

const ModalInvoice = ({ data, modalOpen, onCloseModal, invoice }) => {
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onGetInvoiceUrl = async () => {
    await dispatch(
      startGetInvoiceUrl({
        paymentID: invoice,
      })
    );
  };

  const showPayButton = !data?.isPaid && data?.paymentLink;
  const showDownloadInvoiceButton = data.isPaid;
  const showActionButton = showPayButton || showDownloadInvoiceButton;

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_INVOICE_ARIA_LABEL)}
      title={t(MODAL_INVOICE_TITLE, {
        value: invoice,
      })}
    >
      <LayoutDescriptionInvoice data={data} />
      <Typography variant="subtitle1" align="center" sx={{ mt: 5 }}>
        {t(MODAL_INVOICE_DISCLAIMER)}
      </Typography>
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CLOSE,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: showActionButton,
            text: showPayButton ? PAY : BUTTON_VIEW_INVOICE,
            onClick: showPayButton
              ? () => openLink(data.paymentLink)
              : onGetInvoiceUrl,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

const LayoutDescriptionInvoice = ({ data }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const plans = [...PLANS_SUBSCRIPTION, ...PLANS_PACKAGE, SUBSCRIPTION_PRO];

  return (
    <>
      {data?.items?.map((item, index) => {
        if (plans.includes(item.subscription)) {
          return <ItemPlan data={item} invoice={data} key={index} />;
        }
        if (item.subscription === "credits") {
          return <ItemCredits data={item} invoice={data} key={index} />;
        }
        if (item.subscription === PLAN_COLLABORATION) {
          return <ItemUsage data={item} invoice={data} key={index} />;
        }
        if (item.subscription === PAYMENT_APPLICANT) {
          return <ItemApplicant data={item} invoice={data} key={index} />;
        }
        if (item.subscription === PAYMENT_AMBASSADOR) {
          return <ItemAmbassador data={item} invoice={data} key={index} />;
        }
        if (item.subscription === PAYMENT_INFLUENCER) {
          return <ItemInfluencer data={item} invoice={data} key={index} />;
        }
        if (item.subscription === PAYMENT_INVITATION) {
          return <ItemInvitation data={item} invoice={data} key={index} />;
        }
        if (item.subscription === "fee") {
          return <ItemFee data={item} invoice={data} key={index} />;
        }
        if (item.subscription === "iva") {
          return <ItemIva data={item} invoice={data} key={index} />;
        }
        return null;
      })}

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(TOTAL)}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: data.value,
              currency: data?.currency || LOCALE.currency,
            })}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ItemPlan = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const { plan, subscriptionPlanInfo, plans } = useSelectorApp(
    (state) => state.payments
  );

  const getDescriptionTranslation = () => {
    let planInfo;
    const subscription = data.subscription;

    if (plan === subscription) {
      planInfo = subscriptionPlanInfo;
    } else {
      planInfo = plans[subscription];
    }

    const period = planInfo?.period;

    const translation = period
      ? MODAL_INVOICE_LICENSE
      : MODAL_INVOICE_SUBSCRIPTION;

    const paidDate = invoice?.paidDate
      ? invoice?.paidDate
      : new Date().getTime();
    const dataTranslation = {
      name: capitalizeFirstLetter(subscription),
      startDate: new Date(paidDate),
      endDate: new Date(addMonths(paidDate, period || 1)),
    };

    return {
      translation,
      dataTranslation,
    };
  };

  const { translation, dataTranslation } = getDescriptionTranslation();

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", my: 2, gap: 2 }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {`${t(translation, dataTranslation)}:`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: data.price,
            currency: invoice?.currency || LOCALE.currency,
          })}
        </Typography>
      </Box>
    </Box>
  );
};

const ItemCredits = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`${t(MODAL_INVOICE_CREDITS, {
              NAME_BUSINESS,
            })}:`}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: data.price,
              currency: invoice?.currency || LOCALE.currency,
            })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`${t(MODAL_INVOICE_NUMBER_CREDITS)}:`}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {data.quantity}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ItemUsage = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`${t(MODAL_INVOICE_COLLABORATION)}:`}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: data.price,
              currency: invoice?.currency || LOCALE.currency,
            })}
          </Typography>
        </Box>
      </Box>
      {invoice?.params?.orderID && (
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {`${t(MODAL_INVOICE_ORDER_TO_COLLECT)}:`}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "end" }}>
            <LinkText
              href={`/publication?orderID=${invoice.params.orderID}`}
              target="_blank"
              sx={{ textDecorationColor: "black" }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {invoice.params.orderID}
              </Typography>
            </LinkText>
          </Box>
        </Box>
      )}
    </>
  );
};

const ItemApplicant = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`${t(MODAL_INVOICE_APPLICANT)}:`}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t(VALUE_WITH_CURRENCY, {
              value: data.price,
              currency: invoice?.currency || LOCALE.currency,
            })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`${t(MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID)}:`}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <LinkText
            href={`/campaign?campaignID=${invoice.params.campaignID}`}
            target="_blank"
            sx={{ textDecorationColor: "black" }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {invoice.params.campaignID}
            </Typography>
          </LinkText>
        </Box>
      </Box>
    </>
  );
};
const ItemAmbassador = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {`${t(MODAL_INVOICE_AMBASSADOR)}:`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: data.price,
            currency: invoice?.currency || LOCALE.currency,
          })}
        </Typography>
      </Box>
    </Box>
  );
};
const ItemInfluencer = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {`${t(MODAL_INVOICE_INFLUENCER)}:`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: data.price,
            currency: invoice?.currency || LOCALE.currency,
          })}
        </Typography>
      </Box>
    </Box>
  );
};
const ItemInvitation = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {`${t(MODAL_INVOICE_INVITATION)}:`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: data.price,
            currency: invoice?.currency || LOCALE.currency,
          })}
        </Typography>
      </Box>
    </Box>
  );
};
const ItemFee = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const FEE_VALUE = data.price * 100;
  const VALUE_ITEM = invoice?.items[0].price;
  const FEE_VALUE_CALCULATE = (VALUE_ITEM * FEE_VALUE) / 100;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {`${t(MODAL_INVOICE_FEE, {
            value: FEE_VALUE,
          })}:`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: FEE_VALUE_CALCULATE,
            currency: invoice?.currency || LOCALE.currency,
          })}
        </Typography>
      </Box>
    </Box>
  );
};
const ItemIva = ({ data, invoice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const IVA_VALUE = data.price;
  const VALUE_ITEM = invoice?.items[0].price;
  const IVA_VALUE_CALCULATE = (VALUE_ITEM * IVA_VALUE) / 100;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {`${t(MODAL_INVOICE_IVA, {
            value: IVA_VALUE,
          })}:`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: IVA_VALUE_CALCULATE,
            currency: invoice?.currency || LOCALE.currency,
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default ModalInvoice;
