import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "../Containers/Accordion";
import { getOrderStatusInfo } from "../../../utils/formats";
import ContainerKeyValue from "../Containers/ContainerKeyValue";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import {
  BRAND,
  CREATORS_OWNER_REPORT_IS_DELIVERY,
  CREATORS_OWNER_REPORT_ORDERS_EMPTY,
  DATE_LONG,
  NO,
  ORDERS,
  STATUS,
  VALUE,
  VALUE_WITH_CURRENCY,
  WAS_GENERATED,
  YES,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../lib/i18next";

const Order = ({ id, data, onDeleteOrder, onArchivedOrder, influencer }) => {
  const { shops } = useSelectorApp((state) => state.shop);
  const LOCALES = useLocales();
  const LOCALE = LOCALES[influencer?.signUpCountry || "co"];

  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <IconButton
            sx={{ p: 0, pb: "4px" }}
            disabled={data?.isExtended ?? false}
            onClick={() => onDeleteOrder(id)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            sx={{ p: 0, pb: "4px" }}
            onClick={() => onArchivedOrder(id)}
          >
            <ArchiveIcon />
          </IconButton>
        </Box>
        <ContainerKeyValue
          keyValue="ID: "
          value={id}
          redirectURL="content?orderID"
        />
        <ContainerKeyValue
          keyValue={`${t(BRAND)}: `}
          value={shops[data.shopID]?.businessName ?? "-"}
          redirectURL="shop?shopID"
          redirectValue={data.shopID}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            value: data.value,
            currency: LOCALE.currency,
          })}`}
        />
        <ContainerKeyValue
          keyValue={`${t(STATUS)}: `}
          value={t(getOrderStatusInfo(data.postStatus).label)}
        />

        {data.date && (
          <ContainerKeyValue
            keyValue={`${t(WAS_GENERATED)}: `}
            value={t(DATE_LONG, {
              date: new Date(data.date),
            })}
          />
        )}

        <ContainerKeyValue
          keyValue={`${t(CREATORS_OWNER_REPORT_IS_DELIVERY)}: `}
          value={data.isDelivery ? t(YES) : t(NO)}
        />
        <ContainerKeyValue keyValue="InviteID: " value={data.inviteID} />
      </Box>
    </Box>
  );
};

const InfluencerOrders = ({
  data,
  onDeleteOrder,
  onArchivedOrder,
  influencer,
}) => {
  const keysSorted = Object.keys(data).sort((a, b) => {
    if (data[a].date === undefined || data[b].date === undefined) return 0;
    return (
      new Date(data[b]?.date)?.getTime() - new Date(data[a]?.date)?.getTime()
    );
  });

  const { t } = useTranslationApp();

  return (
    <Box sx={{ mx: 0, width: "100%" }}>
      <Accordion title={t(ORDERS)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: 230,
            overflowY: "auto",
            pl: 0,
            pr: 1,
          }}
        >
          {keysSorted.length === 0 ? (
            <Typography>{t(CREATORS_OWNER_REPORT_ORDERS_EMPTY)}</Typography>
          ) : (
            keysSorted.map((orderID) => {
              return (
                <Order
                  key={orderID}
                  id={orderID}
                  data={data[orderID]}
                  onDeleteOrder={onDeleteOrder}
                  onArchivedOrder={onArchivedOrder}
                  influencer={influencer}
                />
              );
            })
          )}
        </Box>
      </Accordion>
    </Box>
  );
};

export default InfluencerOrders;
