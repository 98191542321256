import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { getIsCampaignModeFeatureFlag } from "../../../../actions/getters";
import { validationProfileInfluencer } from "../../../../utils/influencers";
import { BORDER_RADIUS } from "../../../../utils/constants";
import { useTheme, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  MODAL_ONBOARDING_BASIC_INFORMATION_SECTION,
  MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION,
  MODAL_ONBOARDING_SCHEDULE_SECTION,
  MODAL_ONBOARDING_STORES_SECTION,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ModalProfileInfluencer from "../ModalProfileInfluencer";
import ModalScheduleShop from "../ModalScheduleShop";
import OnboardingStoresContainer from "../../Containers/OnboardingStoresContainer";
import ModalBasicInformationShop from "../ModalBasicInformationShop";

const OnboardingRequiredInformationContainer = ({
  onChangeIsValidData = () => {},
}) => {
  const [modalProfileInfluencerOpen, setModalProfileInfluencerOpen] =
    useState(false);
  const [modalScheduleShopOpen, setModalScheduleShopOpen] = useState(false);
  const [modalStoresOpen, setModalStoresOpen] = useState(false);
  const [modalBasicInformationShopOpen, setModalBasicInformationShopOpen] =
    useState(false);

  const dispatch = useDispatchApp();
  const isCampaignMode = dispatch(getIsCampaignModeFeatureFlag());
  const shop = useSelectorApp((state) => state.shop);
  const schedule = shop.schedule;
  const profileInfluencer = shop.profileInfluencer;

  const getScheduleValid = () => {
    let hasOneDayWithSchedule = false;
    schedule.map((day) => {
      if (day.turns) {
        hasOneDayWithSchedule = true;
      }
      return null;
    });

    return hasOneDayWithSchedule;
  };
  const getStoresValid = () => {
    const stores = shop.stores || {};
    const storesKeys = Object.keys(stores);

    if (storesKeys.length === 0) return false;

    let hasOneStoreActive = false;

    storesKeys.forEach((key) => {
      const store = stores[key];
      if (store.isActive) {
        hasOneStoreActive = true;
      }
    });

    return hasOneStoreActive;
  };
  const getProfileInfluencerValid = () => {
    return validationProfileInfluencer(profileInfluencer, "profileInfluencer");
  };
  const getBasicInformationValid = () => {
    const {
      businessName,
      websiteURL,
      category,
      subCategories,
      shortDescription,
      logoImg,
      coverImg,
    } = shop;

    return (
      businessName &&
      websiteURL &&
      category &&
      subCategories &&
      shortDescription &&
      logoImg &&
      coverImg
    );
  };

  const isIncompleteSchedule = !getScheduleValid();
  const isIncompleteStore = !getStoresValid();
  const isIncompleteProfileInfluencer = !getProfileInfluencerValid();
  const isIncompleteBasicInformation = !getBasicInformationValid();

  useEffect(() => {
    let isAllValid = true;
    if (!isCampaignMode) {
      if (isIncompleteStore) isAllValid = false;
      if (isIncompleteSchedule) isAllValid = false;
    }
    if (isIncompleteProfileInfluencer) isAllValid = false;
    if (isIncompleteBasicInformation) isAllValid = false;
    onChangeIsValidData(isAllValid);
    // eslint-disable-next-line
  }, [
    isIncompleteProfileInfluencer,
    isIncompleteStore,
    isIncompleteSchedule,
    isIncompleteBasicInformation,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <ContainerItem
        label={MODAL_ONBOARDING_BASIC_INFORMATION_SECTION}
        isIncomplete={isIncompleteBasicInformation}
        onClick={() => setModalBasicInformationShopOpen(true)}
      />
      <ContainerItem
        label={MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION}
        isIncomplete={isIncompleteProfileInfluencer}
        onClick={() => setModalProfileInfluencerOpen(true)}
      />
      {!isCampaignMode && (
        <>
          <ContainerItem
            label={MODAL_ONBOARDING_STORES_SECTION}
            isIncomplete={isIncompleteStore}
            onClick={() => setModalStoresOpen(true)}
          />
          <ContainerItem
            label={MODAL_ONBOARDING_SCHEDULE_SECTION}
            isIncomplete={isIncompleteSchedule}
            onClick={() => setModalScheduleShopOpen(true)}
          />
        </>
      )}

      {modalProfileInfluencerOpen && (
        <ModalProfileInfluencer
          modalOpen={modalProfileInfluencerOpen}
          onCloseModal={() => setModalProfileInfluencerOpen(false)}
          modeContainer="empty"
          hasDividers={false}
          elevation={0}
          showBottomButton={false}
          onCallbackSave={() => setModalProfileInfluencerOpen(false)}
          sx={{ mt: 0 }}
        />
      )}
      {modalScheduleShopOpen && (
        <ModalScheduleShop
          modalOpen={modalScheduleShopOpen}
          onCloseModal={() => setModalScheduleShopOpen(false)}
          modeContainer="empty"
          hasDividers={false}
          elevation={0}
          showBottomButton={false}
          onCallbackSave={() => {
            setModalScheduleShopOpen(false);
          }}
          sx={{ mt: 0 }}
        />
      )}
      {modalStoresOpen && (
        <OnboardingStoresContainer
          modalOpen={modalStoresOpen}
          onCloseModal={() => setModalStoresOpen(false)}
        />
      )}
      {modalBasicInformationShopOpen && (
        <ModalBasicInformationShop
          modalOpen={modalBasicInformationShopOpen}
          onCloseModal={() => setModalBasicInformationShopOpen(false)}
          modeContainer="empty"
          hasDividers={false}
          elevation={0}
          showBottomButton={false}
          onlyRequiredFields={true}
          onlyEmptyFields={isIncompleteBasicInformation}
          onCallbackSave={() => {
            setModalBasicInformationShopOpen(false);
          }}
          sxTitle={{ mb: 2 }}
        />
      )}
    </Box>
  );
};

const ContainerItem = ({ label, onClick = () => {}, isIncomplete }) => {
  const theme = useTheme();
  const { t } = useTranslationApp();
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 1.5,
        px: 2,
        border: `1px solid ${theme.palette.background.paperSecondary}`,
        borderRadius: BORDER_RADIUS,
        width: "100%",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Typography variant="body1">{t(label)}</Typography>
      {isIncomplete ? <AlertPending /> : <AlertSuccess />}
    </Box>
  );
};

const AlertSuccess = () => {
  return (
    <Alert
      severity="success"
      sx={{
        borderRadius: BORDER_RADIUS,
        px: 1,
        py: 0.5,
        backgroundColor: "rgb(53 72 56)",
        width: 113,
        ".MuiAlert-message": {
          overflow: "hidden",
        },
      }}
    >
      Complete
    </Alert>
  );
};

const AlertPending = () => {
  return (
    <Alert
      severity="warning"
      sx={{
        borderRadius: BORDER_RADIUS,
        px: 1,
        py: 0.5,
        backgroundColor: "rgb(61 42 13)",
        width: 113,
        ".MuiAlert-message": {
          overflow: "hidden",
        },
      }}
    >
      Pending
    </Alert>
  );
};

export default OnboardingRequiredInformationContainer;
