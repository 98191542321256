import React from "react";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

const AnswerSubTitle = ({ children, sx = {} }) => {
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Typography
      variant={matches ? "body1" : "body2"}
      sx={{ mb: 1, ...sx }}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
};

export default AnswerSubTitle;
