import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import PersonalSection from "./PersonalSection";
import ContactSection from "./ContactSection";
import {
  REGEX_EMAIL,
  REGEX_NIT_OR_CEDULA,
} from "../../../../utils/regexsValidation";
import {
  MIN_LENGTH_IDENTIFICATION,
  TYPE_PERSON_LEGAL,
  TYPE_PERSON_NATURAL,
} from "../../../../utils/constants";
import { getCountry } from "../../../../actions/getters";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { formatPhoneValueLibrary } from "../../../../utils/formats";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { matchIsValidTel } from "mui-tel-input";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH,
  COMPANY_INFORMATION_ID_NUMBER_TYPE,
  FIELD_ALL_REQUIRED,
  FIELD_EMAIL_VALID,
  FIELD_PHONE_VALID,
} from "../../../../locales/keysTranslations";
import ContainerPaperSection from "../../Account/ContainerPaperSection";

const BillingForm = ({ data, onSaveData }) => {
  const [formValues, setFormValues] = useState(data);
  const [error, setError] = useState(null);

  const dispatch = useDispatchApp();
  const country = dispatch(getCountry("shop"));

  const LOCALE = useLocale();

  const { t } = useTranslationApp();

  const signUpCountry = useSelectorApp((state) => state?.shop?.signUpCountry);

  useEffect(() => {
    setFormValues({
      ...data,
      phone: formatPhoneValueLibrary({
        phone: data.phone,
        country: signUpCountry,
        locale: LOCALE,
      }),
    });
    // eslint-disable-next-line
  }, [data]);

  const onChange = (e) => {
    if (e.target.name === "typePerson") {
      return setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        firstName: "",
        lastName: "",
        businessName: "",
      });
    }
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !formValues.typePerson ||
      (formValues.typePerson === TYPE_PERSON_NATURAL &&
        (!formValues.firstName || !formValues.lastName)) ||
      (formValues.typePerson === TYPE_PERSON_LEGAL &&
        !formValues.businessName) ||
      !formValues.typeId ||
      !formValues.numId ||
      !formValues.email ||
      !formValues.address ||
      !formValues.phone.value
    ) {
      return setError(t(FIELD_ALL_REQUIRED));
    }
    if (!REGEX_EMAIL.test(formValues.email)) {
      return setError(t(FIELD_EMAIL_VALID));
    }
    if (formValues.numId.length < MIN_LENGTH_IDENTIFICATION) {
      return setError(
        t(COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH, {
          value: MIN_LENGTH_IDENTIFICATION,
        })
      );
    }
    if (!REGEX_NIT_OR_CEDULA.test(formValues.numId)) {
      return setError(t(COMPANY_INFORMATION_ID_NUMBER_TYPE));
    }
    if (!matchIsValidTel(formValues.phone.value)) {
      return setError(t(FIELD_PHONE_VALID));
    }
    setError(null);
    onSaveData({
      ...formValues,
      phone: formatPhoneValueLibrary({
        phone: formValues.phone.value,
        mode: "save",
      }),
    });
  };

  return (
    <ContainerPaperSection
      showTopButton={false}
      hasDividers={false}
      onSubmit={onSubmit}
      sx={{
        p: 0,
        mt: 0,
      }}
    >
      <Box sx={{ pt: 3 }}>
        {!!error && (
          <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <PersonalSection formValues={formValues} onChangeForm={onChange} />
        <ContactSection
          formValues={formValues}
          onChangeForm={onChange}
          defaultCountry={country}
          onlyCountries={[]}
        />
      </Box>
    </ContainerPaperSection>
  );
};

export default BillingForm;
