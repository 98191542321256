import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextWithIcon from "../../../Common/Texts/TextWithIcon";
import TooltipModal from "../../../Common/Tooltips/TooltipModal";
import useMediaQuery from "@mui/material/useMediaQuery";

const GeneralStatisticPoint = ({
  Icon,
  title,
  value,
  items = [],
  sx = {},
  color = "text.primary",
  hasTooltip = false,
  tooltipTitle = "",
  tooltipContent = "",
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Box sx={{ mr: 1, ...sx }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TextWithIcon
            Icon={Icon}
            variantText={isMobile ? "body2" : "h6"}
            text={title}
            sx={{ wordBreak: "break-word" }}
          />
          {hasTooltip && (
            <TooltipModal title={tooltipTitle} content={tooltipContent} />
          )}
        </Box>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            gap: { xs: 0, sm: 2 },
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignItems: "flex-start",
          }}
        >
          <Typography
            component="p"
            variant={isMobile ? "h5" : "h4"}
            fontWeight="bold"
            sx={{ wordBreak: "break-word", color: `${color} !important` }}
          >
            {value}
          </Typography>
          {items.length > 0 && (
            <Box>
              {items.map((item, index) => (
                <Typography
                  variant="body2"
                  key={index}
                  sx={{
                    color: `${color} !important`,
                  }}
                >
                  {`${item.label}: ${item.value}`}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Divider orientation="vertical" sx={{ height: "100%" }} />
      </Box>
    </>
  );
};

export default GeneralStatisticPoint;
