import React, { useState } from "react";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import TextField from "../Fields/TextField";
import Select from "../Selects/Select";
import { useDispatchApp } from "../../../lib/redux";
import { startCreateInfluencerTransactionOwner } from "../../../actions/owner";
import { SimpleAlert } from "../../../utils/alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import NumberField from "../NumberFormats/NumberField";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CREATE,
  FIELD_REQUIRED,
  MODAL_CREATE_TRANSACTION,
  MODAL_CREATE_TRANSACTION_AMOUNT_LABEL,
  MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR,
  MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT,
  MODAL_CREATE_TRANSACTION_CONCEPT_ID,
  MODAL_CREATE_TRANSACTION_CONCEPT_LABEL,
  MODAL_CREATE_TRANSACTION_CONCEPT_ORDER,
  MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL,
  READY,
} from "../../../locales/keysTranslations";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalCreateTransactionInfluencer = ({
  modalOpen,
  onCloseModal,
  userID,
  callbackCreateTransaction = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      amount: "",
      from: "",
      fromID: "",
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await dispatch(
      startCreateInfluencerTransactionOwner({
        userID,
        ...data,
      })
    );
    if (response) {
      callbackCreateTransaction(response);
      onCloseModal();
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsLoading(false);
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      ariaLabel={t(MODAL_CREATE_TRANSACTION)}
      title={t(MODAL_CREATE_TRANSACTION)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          label={t(MODAL_CREATE_TRANSACTION_AMOUNT_LABEL)}
          name="amount"
          variant="filled"
          fullWidth
          InputProps={{
            inputComponent: NumberField,
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        <Select
          label={t(MODAL_CREATE_TRANSACTION_CONCEPT_LABEL)}
          name="from"
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          options={[
            {
              value: "ambassador",
              label: t(MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR),
            },
            {
              value: "applicant",
              label: t(MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT),
            },
            {
              value: "orders",
              label: t(MODAL_CREATE_TRANSACTION_CONCEPT_ORDER),
            },
            {
              value: "referred",
              label: t(MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL),
            },
          ]}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        <TextField
          label={t(MODAL_CREATE_TRANSACTION_CONCEPT_ID)}
          name="fromID"
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        <ContainerModalActions
          hasPadding={false}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_CREATE,
            type: "submit",
            loading: isLoading,
            onClick: () => {},
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalCreateTransactionInfluencer;
