import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION,
  ANALYTICS_EXPLANATION_DESCRIPTION,
  ANALYTICS_EXPLANATION_DESCRIPTION_2,
  ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION,
  ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION,
  ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION,
} from "../../../../locales/keysTranslations";

const ContentAnalytics = () => {
  const { t } = useTranslationApp();
  return (
    <React.Fragment>
      <Typography
        color="inherit"
        variant="body1"
        sx={{ fontWeight: 300, mb: 1 }}
      >
        {t(ANALYTICS_EXPLANATION_DESCRIPTION)}
      </Typography>
      <Typography
        color="inherit"
        variant="body1"
        sx={{ fontWeight: 300, mb: 1 }}
      >
        {t(ANALYTICS_EXPLANATION_DESCRIPTION_2)}
      </Typography>
      <Typography
        color="inherit"
        variant="body2"
        sx={{ fontWeight: 300, mb: 1 }}
        dangerouslySetInnerHTML={{
          __html: t(ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION),
        }}
      />
      <Typography
        color="inherit"
        variant="body2"
        sx={{ fontWeight: 300, mb: 1 }}
        dangerouslySetInnerHTML={{
          __html: t(ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION),
        }}
      />
      <Typography
        color="inherit"
        variant="body2"
        sx={{ fontWeight: 300, mb: 1 }}
        dangerouslySetInnerHTML={{
          __html: t(ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION),
        }}
      />
      <Typography
        color="inherit"
        variant="body2"
        sx={{ fontWeight: 300, mb: 1 }}
        dangerouslySetInnerHTML={{
          __html: t(ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION),
        }}
      />
    </React.Fragment>
  );
};

export default ContentAnalytics;
