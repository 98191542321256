import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
  detectPlatform,
  getExtensionFile,
  getHlsUrl,
} from "../../../utils/urls";
import Button from "../../Common/Buttons/Button";
import {
  INSTAGRAM,
  ROLES_DASHBOARD,
  TIKTOK,
  VIDEO_FORMATS,
  YOUTUBE,
} from "../../../utils/constants";
import MediaDownloadContainer from "../../Common/Media/MediaDownloadContainer";
import { PUBLICATION_SOCIAL_SECTION } from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ADD_URL_PUBLICATION,
  BUTTON_SAVE_CHANGES,
  BUTTON_UPLOAD_VIDEO,
  BUTTON_VERIFY_PUBLICATION,
  EMPTY_CONTENT_SHOW,
  FIELD_URL_VALID,
  PUBLICATION_TITLE,
  PUBLISHED_DATE,
  UPLOAD_NEW_CONTENT,
} from "../../../locales/keysTranslations";
import { getRole } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import UploadFile from "../../Common/Buttons/UploadFile";
import linkifyHtml from "linkify-html";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import { useForm } from "react-hook-form";
import TextField from "../../Common/Fields/TextField";
import { REGEX_URL } from "../../../utils/regexsValidation";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadImageButtons from "../../Common/Buttons/UploadImageButtons";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import VideoPlayer from "../../Common/Video/VideoPlayer";
import LinkText from "../../Common/Texts/LinkText";

const SocialNetworksSection = ({
  order,
  influencer,
  isMobile,
  onUploadNewContent,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [newContent, setNewContent] = useState([]);

  const hls = order.hls;

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      url: "",
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const dispatch = useDispatchApp();
  const role = dispatch(getRole());
  const { t } = useTranslationApp();

  const onSubmit = (data) => {
    const url = data.url;

    if (!url) return;

    setNewContent((prev) => [...prev, url]);

    reset();
  };

  const posts = [...(order?.posts || []), order?.socialUrl];
  const postsKeys = Object.keys(posts).filter((key) => posts[key]);

  const renderContent = (result, url, keyPost) => {
    switch (result) {
      case INSTAGRAM:
        return (
          <LinkText
            href={url}
            target="_blank"
            sx={{
              textDecoration: "none",
              width: "250px",
            }}
            key={keyPost}
          >
            <Button color="secondary" sx={{}}>
              {t(BUTTON_VERIFY_PUBLICATION)}
            </Button>
          </LinkText>
        );
      case TIKTOK:
        return (
          <LinkText
            href={url}
            target="_blank"
            sx={{
              textDecoration: "none",
              width: "250px",
            }}
            key={keyPost}
          >
            <Button color="secondary" sx={{}}>
              {t(BUTTON_VERIFY_PUBLICATION)}
            </Button>
          </LinkText>
        );
      case YOUTUBE:
        return (
          <LinkText
            href={url}
            target="_blank"
            sx={{
              textDecoration: "none",
              width: "250px",
            }}
            key={keyPost}
          >
            <Button color="secondary" sx={{}}>
              {t(BUTTON_VERIFY_PUBLICATION)}
            </Button>
          </LinkText>
        );

      default:
        const extension = getExtensionFile(url);
        const hlsLink = getHlsUrl({
          url,
          hls,
        });
        return VIDEO_FORMATS.includes(extension) ? (
          <MediaDownloadContainer
            url={url}
            sx={{
              width: 360,
              height: 460,
              mr: 2,
              flex: "none",
            }}
            key={keyPost}
          >
            <VideoPlayer url={hlsLink || url} controls={true} />
          </MediaDownloadContainer>
        ) : (
          <MediaDownloadContainer
            key={keyPost}
            url={url}
            sx={{
              mr: 2,
              width: 360,
              height: 460,
              flex: "none",
            }}
          >
            <Avatar
              variant="rounded"
              src={url}
              sx={{
                height: "100%",
                width: "100%",
              }}
              alt={t(PUBLICATION_TITLE)}
            />
          </MediaDownloadContainer>
        );
    }
  };
  return (
    <Box
      sx={{
        width: "auto",
        height: "auto",
      }}
    >
      <Typography
        variant={isMobile ? "h4" : "h5"}
        data-tour={PUBLICATION_SOCIAL_SECTION}
        sx={{ fontWeight: 600, mb: 3 }}
      >
        {influencer.mainPlatform === TIKTOK
          ? "TikTok"
          : influencer.mainPlatform === INSTAGRAM
          ? "Instagram"
          : "YouTube"}
      </Typography>
      {order?.publicationTime && (
        <Typography variant="body1">
          {t(PUBLISHED_DATE, {
            date: new Date(order?.publicationTime),
          })}
        </Typography>
      )}
      {postsKeys.length >= 1 ? (
        <Box
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            mb: 2,
            overflowX: "auto",
            pb: 1,
          }}
        >
          <Box
            sx={{
              width: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {postsKeys.map((keyPost) => {
              const result = detectPlatform(posts[keyPost]);
              if (result === "asset")
                return renderContent(result, posts[keyPost], keyPost);
              return null;
            })}
          </Box>
          <Box
            sx={{
              width: "auto",
              display: "flex",
              flexDirection: "row",
              gap: 2,
              flexWrap: { xs: "noWrap", md: "wrap" },
            }}
          >
            {postsKeys.map((keyPost, index) => {
              const result = detectPlatform(posts[keyPost]);
              if (
                result === INSTAGRAM ||
                result === TIKTOK ||
                result === YOUTUBE
              ) {
                return renderContent(result, posts[keyPost], keyPost);
              }
              return null;
            })}
          </Box>
        </Box>
      ) : (
        <Typography variant={isMobile ? "h5" : "h6"} sx={{ pb: -2 }}>
          {t(EMPTY_CONTENT_SHOW)}
        </Typography>
      )}

      {ROLES_DASHBOARD.includes(role) && (
        <Box sx={{ mt: 2, maxWidth: 400 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t(UPLOAD_NEW_CONTENT)}
          </Typography>
          {newContent.length > 0 &&
            newContent.map((url, index) => {
              return (
                <Typography
                  variant="body2"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: linkifyHtml(`${index + 1}. ${url}`, {
                      attributes: {
                        rel: "noopener noreferrer",
                        target: "_blank",
                        style: "color: #00A3FF",
                      },
                    }),
                  }}
                />
              );
            })}
          <Box sx={{ display: "flex", gap: 1, mt: 2, width: "100%" }}>
            <UploadImageButtons
              isCompressed={false}
              disabled={isFetching}
              pathStorage={`orders/${order.orderID}/content/`}
              automaticSetRTDB={false}
              onReadyUrlImage={(e) => {
                setNewContent((prev) => [...prev, e.target.value]);
              }}
              targetName="image"
            />
            <UploadFile
              disabled={isFetching}
              targetName="video"
              hasIcon={true}
              textButton={BUTTON_UPLOAD_VIDEO}
              acceptFormats={VIDEO_FORMATS.map((format) => `video/${format},`)}
              pathStorage={`orders/${order.orderID}/content/`}
              automaticSetRTDB={false}
              onReadyFile={(e) => {
                setNewContent((prev) => [...prev, e.target.value]);
              }}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 2, display: "flex", gap: 1, alignItems: "center" }}
          >
            <TextField
              fullWidth
              name="url"
              label={t(ADD_URL_PUBLICATION)}
              variant="filled"
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (value && !REGEX_URL.test(value))
                    return t(FIELD_URL_VALID);
                },
              }}
            />
            <Button color="secondary">
              <AddCircleIcon />
            </Button>
          </Box>

          {newContent.length > 0 && (
            <LoadingButton
              onClick={async () => {
                setIsFetching(true);
                const response = await onUploadNewContent(newContent);
                setIsFetching(false);
                if (response) {
                  setNewContent([]);
                }
              }}
              sx={{ mt: 2 }}
            >
              {t(BUTTON_SAVE_CHANGES)}
            </LoadingButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SocialNetworksSection;
