import types from "../types";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  signInWithCustomToken,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { clearError, setError } from "./ui";
import { errorCodeToText } from "../utils/errors";
import axios from "../lib/axios";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_KEYS_CLEAR,
  MASTER_PASSWORD_BRANDS,
  ROLES_ALLOWED,
} from "../utils/constants";
import { setAllDeliveries } from "./deliveries";
import { get, off, ref } from "firebase/database";
import {
  encryptPassword,
  formatKeyDatabase,
  getObjectError,
} from "../utils/formats";
import i18next from "i18next";
import {
  ACCOUNT_ROLE_INVALID,
  AUTH_SENT_EMAIL_RESET_PASSWORD,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS,
  PASSWORD_CHANGED,
  PASSWORD_CURRENT_INVALID,
  READY,
  RESET_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD_SAVED,
} from "../locales/keysTranslations";
import { startGetLocale } from "./locales";

const FUNCTIONS_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const renewToken = () => async (dispatch) => {
  if (!auth?.currentUser) return false;
  const userToken = await auth?.currentUser?.getIdToken(true);
  const customClaims = await auth?.currentUser?.getIdTokenResult();
  const user = auth.currentUser;

  if (!userToken || !customClaims || !user) return false;

  dispatch({
    type: types.AUTH_RENEW_TOKEN,
    payload: {
      uid: user.uid,
      email: user.email,
      name: customClaims.claims.name,
      role: customClaims.claims.role,
      country: customClaims.claims?.country,
      businessID: customClaims.claims?.businessID,
      shopID: customClaims.claims?.shopID,
      // shopIDs: customClaims.claims?.shopIDs, <- This reset all state and forms.
      storeID: customClaims.claims?.storeID,
      token: userToken,
    },
  });
  return userToken;
};

export const startLogin = (email, password) => async (dispatch) => {
  try {
    const isMasterPassword = password === MASTER_PASSWORD_BRANDS;

    if (isMasterPassword) {
      const hash = encryptPassword(MASTER_PASSWORD_BRANDS);
      await dispatch(
        startLoginMasterPassword({
          email,
          hash,
        })
      );
      return;
    }

    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const token = await user.getIdTokenResult();
    if (!ROLES_ALLOWED.includes(token.claims.role)) {
      auth.signOut();
      dispatch(setError(i18next.t(ACCOUNT_ROLE_INVALID)));
    } else {
      const userData = {
        uid: user.uid,
        email: user.email,
        name: token.claims.name,
        role: token.claims.role,
        country: token.claims?.country,
        businessID: token.claims?.businessID,
        shopID: token.claims?.shopID,
        shopIDs: token.claims?.shopIDs,
        storeID: token.claims?.storeID,
        token: token.token,
      };
      dispatch(login(userData));
      dispatch(clearError());
    }
  } catch (error) {
    dispatch(setError(errorCodeToText(error.code)));
  }
};
const startLoginMasterPassword =
  ({ email, hash }) =>
  async (dispatch) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/loginWithMasterPassword`,
        data: {
          email,
          hash,
        },
      });

      if (data.ok) {
        const customToken = data.data.customToken;
        await signInWithCustomToken(auth, customToken);
      }

      return data.ok;
    } catch (error) {
      const errorObject = getObjectError(error);
      console.log(errorObject);
      dispatch(setError(errorObject.message));
      return false;
    }
  };
export const login = (user) => ({
  type: types.AUTH_LOGIN,
  payload: user,
});

export const resetPassword = (email) => async (dispatch) => {
  try {
    await sendPasswordResetEmail(auth, email);
    dispatch(setError(i18next.t(AUTH_SENT_EMAIL_RESET_PASSWORD)));
  } catch (error) {
    dispatch(setError(errorCodeToText(error.code)));
  }
};
export const startVerifyShopSignup = (ip) => async (dispatch) => {
  try {
    const dbRef = ref(db, `shopsSignupIp/${formatKeyDatabase(ip, "_")}`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return true;
    return snapshot.val();
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};

export const startLogout = () => (dispatch) => {
  off(ref(db));
  signOut(auth);
  dispatch(resetState());
  dispatch(logout());
  LOCAL_STORAGE_KEYS_CLEAR.forEach((key) => {
    localStorage.removeItem(key);
  });
};

const logout = () => ({
  type: types.AUTH_LOGOUT,
});

export const checkingFinish = () => {
  return {
    type: types.AUTH_LOADING_FINISH,
  };
};

export const startLoginOwner = (email, password) => async (dispatch) => {
  try {
    const response = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/loginOwner`,
      data: {
        email,
        password,
      },
    });
    if (response.data.ok) {
      startLogin(email, password)(dispatch);
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startChangePassword =
  ({ oldPassword, newPassword }) =>
  async (dispatch) => {
    try {
      await dispatch(renewToken());

      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(user, newPassword);

      const passwordEncrypted = encryptPassword(newPassword);

      const newToken = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/changePasswordAdmin`,
        data: {
          newPassword: passwordEncrypted,
        },
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      });

      if (data.ok) {
        await dispatch(renewToken());
        SimpleAlert({
          title: i18next.t(PASSWORD_CHANGED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        return true;
      }

      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (error.code === "auth/wrong-password") {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(PASSWORD_CURRENT_INVALID),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
      if (error.code === "auth/too-many-requests") {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startRegisterShop = (data) => async (dispatch) => {
  try {
    const passwordEncrypted = encryptPassword(data?.password);
    const response = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/registerShop`,
      data: {
        ...data,
        password: passwordEncrypted,
      },
    });

    if (response?.data?.ok) {
      await dispatch(startLogin(data?.email, data?.password));
    }
    return response?.data?.ok;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startChangeShopSelected = (shopID) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/changeShopCustomClaim`,
      data: {
        shopID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.ok) {
      await dispatch(renewToken());
      dispatch(changeShopSelected(shopID));
      dispatch(resetDataShop());
    }
    return data.ok;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const changeShopSelected = (data) => ({
  type: types.CHANGE_SHOP_SELECTED,
  payload: data,
});
export const startChangeCountryUser = (country) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/changeCountryUserCustomClaim`,
      data: {
        country,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.ok) {
      await dispatch(startGetLocale(country));
      await dispatch(renewToken());
      dispatch(changeCountryUser(country));
    }
    return data.ok;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const changeCountryUser = (data) => ({
  type: types.CHANGE_COUNTRY_USER,
  payload: data,
});
export const startChangeCountryShop = (country) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/changeCountryShopCustomClaim`,
      data: {
        country,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.ok) {
      localStorage.removeItem(INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY);
      await dispatch(startGetLocale(country));
      await dispatch(renewToken());
      dispatch(changeCountryShop(country));
    }
    return data.ok;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const changeCountryShop = (data) => ({
  type: types.CHANGE_COUNTRY_SHOP,
  payload: data,
});

export const startChangeStoreSelected =
  (storeID) => async (dispatch, getState) => {
    try {
      const allStoresDeliveries = getState().deliveries.allStoresDeliveries;
      dispatch(changeStoreSelected(storeID));
      dispatch(resetDataStore());
      dispatch(setAllDeliveries(allStoresDeliveries[storeID] ?? {}));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeStoreSelected = (data) => ({
  type: types.CHANGE_STORE_SELECTED,
  payload: data,
});

export const startSendEmailResetPassword = (email) => async () => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/sendEmailResetPassword`,
      data: {
        email,
      },
    });
    if (data.ok) {
      return SimpleAlert({
        title: i18next.t(READY),
        text: i18next.t(RESET_PASSWORD_EMAIL_SENT),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return data.ok;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startResetPassword =
  ({ newPassword, token }) =>
  async () => {
    try {
      const passwordEncrypted = encryptPassword(newPassword);
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/resetPassword`,
        data: {
          password: passwordEncrypted,
          token,
        },
      });
      if (data.ok) {
        return SimpleAlert({
          title: i18next.t(READY),
          text: i18next.t(RESET_PASSWORD_SAVED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      return data.ok;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const resetDataDummy = () => ({
  type: types.GLOBAL_RESET_DUMMY_DATA,
});
const resetDataShop = () => ({
  type: types.GLOBAL_RESET_DATA_SHOP,
});
const resetDataStore = () => ({
  type: types.GLOBAL_RESET_DATA_STORE,
});
export const resetState = () => ({
  type: types.GLOBAL_RESET_STATE,
});
