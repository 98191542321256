/*COMMON*/
export const SEARCH = "search";
export const LOADING_INFORMATION = "loadingInformation";
export const LOADING_SIMILAR_PROFILES_INFORMATION =
  "loadingSimilarProfilesInformation";
export const LOADING_SUGGESTED_INFORMATION = "loadingSuggestedInformation";
export const LOADING_REPORT_INFORMATION = "loadingReportInformation";
export const LOADING_DOWNLOAD_PROFILES = "loadingDownloadProfiles";
export const LOADING_CONTENT = "loadingContent";
export const LOADING_CONFIGURATION = "loadingConfiguration";
export const INFORMATION_INVALID_ACCESS = "informationInvalidAccess";
export const EMPTY_SIMILAR_PROFILES_SHOW = "emptySimilarProfilesShow";
export const EMPTY_CONTENT_SHOW = "emptyContentShow";
export const NO_MORE_RESULTS_TO_SHOW = "noMoreResultsToShow";
export const EMPTY_RESULTS_SHOW = "emptyResultsShow";
export const EMPTY_RESULTS_SHOW_ON_THIS_PAGE = "emptyResultsShowOnThisPage";
export const EMPTY_QUESTIONS_SHOW = "emptyQuestionsShow";
export const EMPTY_ANALYTICS = "emptyAnalytics";
export const WE_ARE_DOING_A_DEEPER_SEARCH = "weAreDoingADeeperSearch";
export const ADVANCED_CONFIGURATION = "advancedConfiguration";
export const CREDITS = "credits";
export const WAIT = "wait";
export const COLLABORATIONS = "colLaborations";
export const COLLABORATION = "colLaboration";
export const DEACTIVATE = "deactivate";
export const DEACTIVATED = "deactivated";
export const ACTIVATE = "activate";
export const ACTIVE = "active";
export const BRAND = "brand";
export const WARNING = "warning";
export const INFORMATION = "information";
export const ERROR = "error";
export const ACTIONS = "actions";
export const STATUS = "status";
export const CITIES = "cities";
export const UNKNOWN_FORMAT = "unknownFormat";
export const CATEGORY = "category";
export const CATEGORIES = "categories";
export const PRODUCT = "product";
export const PRODUCTS = "products";
export const ERROR_DESCRIPTION_GENERIC = "errorDescriptionGeneric";
export const READY = "ready";
export const HELP = "help";
export const GO = "go";
export const GO_TO_ACCOUNT = "goToAccount";
export const GO_TO_PROFILE = "goToProfile";
export const GO_TO_AUTOMATIC_INVITATIONS = "goToAutomaticInvitations";
export const PROFILE = "profile";
export const LOGOUT = "logout";
export const CLIENT = "client";
export const NAME = "name";
export const ADDRESS = "address";
export const IDENTIFICATION_NUMBER = "identificationNumber";
export const PHONE_NUMBER = "phoneNumber";
export const EMAIL = "email";
export const NOTIFICATIONS = "notifications";
export const CONTENT = "content";
export const ANALYTIC = "analytic";
export const ANALYTICS = "analytics";
export const COMMENTS = "comments";
export const COMMENT = "comment";
export const SHOW_COMMENTS = "showComments";
export const EMPTY_COMMENTS = "emptyComments";
export const REVIEWS = "reviews";
export const REVIEW = "review";
export const SHOW_REVIEWS = "showReviews";
export const EMPTY_REVIEWS = "emptyReviews";
export const SCORE = "score";
export const QUALITY_AUDIENCE = "qualityAudience";
export const QUALITY_CONTENT = "qualityContent";
export const AUDIENCE = "audience";
export const AUDIENCE_TITLE_EXPLANATION = "audienceTitleExplanation";
export const AUDIENCE_EXPLANATION = "audienceExplanation";
export const ENGAGEMENT = "engagement";
export const ENGAGEMENT_TITLE_EXPLANATION = "engagementTitleExplanation";
export const ENGAGEMENT_EXPLANATION = "engagementExplanation";
export const FOLLOWERS = "followers";
export const AVG_VIEWS = "avgViews";
export const AVG_LIKES = "avgLikes";
export const AVG_COMMENTS = "avgComments";
export const RECORDS_IN_TABLE = "recordsInTable";
export const PAGE_IN_TABLE = "pageInTable";
export const SHOP_DEACTIVATE_ALERT = "shopDeactivateAlert";
export const SHOP_DEACTIVATE_ALERT_DESCRIPTION =
  "shopDeactivateAlertDescription";
export const UPDATE = "update";
export const MALES = "males";
export const MALE = "male";
export const FEMALES = "females";
export const FEMALE = "female";
export const STORE = "store";
export const SELECT_STORE = "selectStore";
export const NO_STORES_CREATED = "noStoresCreated";
export const EMPTY_STORES = "emptyStores";
export const NEW_ORDER = "newOrder";
export const ORDER = "order";
export const NEW = "new";
export const SECOND = "second";
export const SECONDS = "seconds";
export const MINUTE = "minute";
export const MINUTES = "minutes";
export const MINUTE_LETTER_COUNT_DOWN = "minuteLetterCountDown";
export const HOUR = "hour";
export const HOURS = "hours";
export const HOUR_LETTER_COUNT_DOWN = "hourLetterCountDown";
export const DAY = "day";
export const DAYS = "days";
export const DAY_LETTER_COUNT_DOWN = "dayTextCountDown";
export const WEEK = "week";
export const WEEKS = "weeks";
export const WEEK_LETTER_COUNT_DOWN = "weekLetterCountDown";
export const MONTH = "month";
export const MONTHS = "months";
export const QUESTION = "question";
export const CONTENT_CREATOR = "contentCreator";
export const ADD = "add";
export const MONDAY = "monday";
export const TUESDAY = "tuesday";
export const WEDNESDAY = "wednesday";
export const THURSDAY = "thursday";
export const FRIDAY = "friday";
export const SATURDAY = "saturday";
export const SUNDAY = "sunday";
export const SELECT_A_OPTION = "selectAOption";
export const FILE_SELECTED = "fileSelected";
export const YES = "yes";
export const NO = "no";
export const IMAGES = "images";
export const VIDEOS = "videos";
export const OK = "ok";
export const FILTER = "filter";
export const SUPER_ADMIN = "superAdmin";
export const ADMIN = "admin";
export const OPERATOR = "operator";
export const DESIGNER = "designer";
export const TOTAL = "total";
export const NO_HAVE = "noHave";
export const NO_ADDED = "noAdded";
export const SUPPORT = "support";
export const CONTACT_US = "contactUs";
export const ONE = "one";
export const TWO = "two";
export const THREE = "three";
export const INVITATIONS = "invitations";
export const ANY = "any";
export const GENDER = "gender";
export const INFLUENCER_NO_REDEEM_CODE = "influencerNoRedeemCode";
export const SEND_REVISION_INFLUENCER_COMMENT = "sendRevisionInfluencerComment";
export const REVISIONS = "revisions";
export const CURRENT_CONTENT = "currentContent";
export const CURRENT_CONTENT_EMPTY = "currentContentEmpty";
export const NO_SEND_CONTENT_FEEDBACK = "noSendContentFeedback";
export const EDIT = "edit";
export const THIS_CREATOR_IS_FAVORITE = "thisCreatorIsFavorite";
export const ADD_TO_FAVORITES = "addToFavorites";
export const REMOVE_FROM_FAVORITES = "removeFromFavorites";
export const SPANISH = "spanish";
export const ENGLISH = "english";
export const VIEW = "view";
export const USERS = "users";
export const STORES = "stores";
export const PLAN = "plan";
export const AUTOMATIC_RENOVATION = "automaticRenovation";
export const ACCEPTED = "accepted";
export const PENDING = "pending";
export const ARE_YOU_SURE = "areYouSure";
export const CHANGE = "change";
export const REPORT_REQUESTS = "reportRequests";
export const REQUESTS_ACCEPTED = "requestsAccepted";
export const REQUESTS_REJECTED = "requestsRejected";
export const REQUESTS_ACTIVE = "requestsActive";
export const REPORT_INVITATIONS = "reportInvitations";
export const CODE = "code";
export const IS_AUTOMATIC = "isAutomatic";
export const REPORT_INVITATIONS_INACTIVE = "reportInvitationsInactive";
export const REDEEMED = "redeemed";
export const EXPIRED = "expired";
export const REDEEMED_SINGULAR = "redeemedSingular";
export const EXPIRED_SINGULAR = "expiredSingular";
export const REJECTED = "rejected";
export const ROLE = "role";
export const UNKNOWN = "unknown";
export const LEFT = "left";
export const RIGHT = "right";
export const LOGO = "logo";
export const ADD_BRAND = "addBrand";
export const DATE = "date";
export const HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP =
  "hasInvitesActiveForDeactivateShop";
export const NAME_BUSINESS_BOT = "nameBusinessBot";
export const SERVICE_FEE = "serviceFee";
export const TOTAL_PAYMENT = "totalPayment";
export const LAST_COLLABORATION = "lastCollaboration";
export const PERSONALIZED_INSTRUCTIONS = "personalizedInstructions";
export const AUTOMATIC_INVITES_CONFIGURATION = "automaticInvitesConfiguration";
export const EMPTY_REQUESTS = "emptyRequests";
export const EMPTY_CAMPAIGNS = "emptyCampaigns";
export const EMPTY_AMBASSADORS = "emptyAmbassadors";
export const EMPTY_INVITATIONS = "emptyInvitations";
export const EMPTY_INVITATIONS_INACTIVE = "emptyInvitationsInactive";
export const EMPTY_ORDERS = "emptyOrders";
export const EMPTY_REDEEM_CODES = "emptyRedeemCodes";
export const EMPTY_USERS = "emptyUsers";
export const EMPTY_CONTACTS = "emptyContacts";
export const EMPTY_INVOICES = "emptyInvoices";
export const EMPTY_INVITATIONS_APPLICANTS = "emptyInvitationsApplicants";
export const EMPTY_APPLICANTS_PENDING = "emptyApplicantsPending";
export const EMPTY_APPLICANTS_ACCEPTED = "emptyApplicantsAccepted";
export const EMPTY_ADS_CAMPAIGNS = "emptyAdsCampaigns";
export const TARGET_CAMPAIGN = "targetCampaign";
export const LOCATIONS = "locations";
export const GENDERS = "genders";
export const AGES = "ages";
export const PLATFORMS = "platforms";
export const PLATFORM = "platform";
export const STATISTICS = "statistics";
export const IMPRESSIONS = "impressions";
export const CLICKS = "clicks";
export const CTR = "ctr";
export const TYPE_LEAST_CHARACTERS = "typeLeastCharacters";
export const LOADING = "loading";
export const OBJECTIVE = "objective";
export const INTERACTIONS = "interactions";
export const LEADS = "leads";
export const DOWNLOADS = "downloads";
export const SALES = "sales";
export const META_CAMPAIGN = "metaCampaign";
export const TIKTOK_CAMPAIGN = "tiktokCampaign";
export const CONTENT_TO_ADVERTISE = "contentToAdvertise";
export const EMPTY_TRACKING_IDS_META = "emptyTrackingIdsMeta";
export const FINISHED = "finished";
export const TIKTOK_BUSINESS = "tiktokBusiness";
export const EMPTY_TRACKING_IDS_TIKTOK = "emptyTrackingIdsTiktok";
export const SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS =
  "shortTimeHasPassedSinceActivationStatistics";
export const SHOPIFY = "shopify";
export const WOOCOMMERCE = "woocommerce";
export const ADD_NEW_STYLE = "addNewStyle";
export const WANTS_SOCIAL_MEDIA_POST = "wantsSocialMediaPost";
export const DOES_NOT_WANTS_SOCIAL_MEDIA_POST = "doesNotWantsSocialMediaPost";
export const FOLLOWERS_ABBREVIATED = "followersAbbreviated";
export const ENGAGEMENT_ABBREVIATED = "engagementAbbreviated";
export const EXPLANATION_SEND_ONE_REVISION = "explanationSendOneRevision";
export const I_NEED_HELP = "iNeedHelp";
export const REVISION_OPTION_GOOD = "revisionOptionGood";
export const REVISION_OPTION_REGULAR = "revisionOptionRegular";
export const REVISION_OPTION_BAD = "revisionOptionBad";
export const WHY_THESE_RECOMMENDED_SETTINGS = "whyTheseRecommendedSettings";
export const PIXEL_REQUIRED = "pixelRequired";
export const IS_TRAINED_PHYSICAL_BRAND = "isTrainedPhysicalBrand";
export const DRAFT = "draft";
export const ADS = "ads";
export const UGC = "ugc";
export const WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE =
  "weSendVerificationCodeToYourPhone";
export const SEARCH_BY = "searchBy";
export const TYPE_A_USERNAME = "typeAName";
export const TYPE_A_TOPIC = "typeATopic";
export const PROCESSING = "processing";
export const RETRIEVING_BASIC_PROFILE_INFORMATION =
  "retrievingBasicProfileInformation";
export const RETRIEVING_PROFILE_STATISTICS = "retrievingProfileStatistics";
export const RETRIEVING_AUDIENCE_STATISTICS = "retrievingAudienceStatistics";
export const RETRIEVING_TOP_POSTS_INFORMATION = "retrievingTopPostsInformation";
export const ATTENTION = "attention";
export const COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION =
  "collectingInfluencer_informationPhylloDescription";
export const MONTHLY = "monthly";
export const QUARTERLY = "quarterly";
export const SEMIYEARLY = "semiyearly";
export const YEARLY = "yearly";
export const ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION =
  "advertiserAttachPaymentLinkClarification";
export const DESCRIPTION_PAYMENT_LINK_CLARIFICATION =
  "descriptionPaymentLinkClarification";

/*PAYMENTS*/
export const SELECT_PAYMENT_METHOD = "selectPaymentMethod";
export const ADD_PAYMENT_METHOD = "addPaymentMethod";
export const PAYMENT_METHOD = "paymentMethod";
export const PAYMENT_METHODS = "paymentMethods";
export const PAYMENT_METHODS_EMPTY = "paymentMethodsEmpty";
export const MAIN_PAYMENT_METHOD = "mainPaymentMethod";
export const OTHERS_PAYMENT_METHODS = "othersPaymentMethods";
export const OTHERS_PAYMENT_METHODS_EMPTY = "othersPaymentMethodsEmpty";
export const PAYMENT_METHODS_SECURE = "paymentMethodsSecure";
export const PAYMENT_METHODS_SECURE_EXPLANATION =
  "paymentMethodsSecureExplanation";
export const PAYMENT_METHODS_ENCRYPTED = "paymentMethodsEncrypted";
export const PAYMENT_SUCCESSFUL = "paymentSuccessful";
export const PRICE_TO_BE_PAID = "priceToBePaid";
export const PAY = "pay";
export const ADD_CARD = "addCard";
export const SAVE_CONFIGURATION = "saveConfiguration";
export const CARD_INFORMATION_EXPLANATION_TITLE =
  "cardInformationExplanationTitle";
export const CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE =
  "cardInformationNumberCardExplanationTitle";
export const CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION =
  "cardInformationNumberCardExplanationDescription";
export const CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE =
  "cardInformationExpirationDateExplanationTitle";
export const CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION =
  "cardInformationExpirationDateExplanationDescription";
export const CARD_INFORMATION_CVV_EXPLANATION_TITLE =
  "cardInformationCVVExplanationTitle";
export const CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION =
  "cardInformationCVVExplanationDescription";
export const YOU_WILL_PAY = "youWillPay";
export const ACCEPT_TERMS_PAY = "acceptTermsPay";
export const DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE =
  "deletePaymentMethodConfirmationTitle";
export const QUANTITY_TO_PAY = "quantityToPay";

/*FIELDS PLACEHOLDERS*/
export const NAME_PLACEHOLDER = "namePlaceholder";
export const EMAIL_PLACEHOLDER = "emailPlaceholder";
export const PASSWORD_PLACEHOLDER = "passwordPlaceholder";
export const PASSWORD_CONFIRMATION_PLACEHOLDER =
  "passwordConfirmationPlaceholder";
export const SELECT_BUSINESS_PLACEHOLDER = "selectBusinessPlaceholder";
export const NAME_BUSINESS_PLACEHOLDER = "nameBusinessPlaceholder";
export const SELECT_BRANDS_PLACEHOLDER = "selectBrandsPlaceholder";
export const MAIN_BRAND_PLACEHOLDER = "mainBrandPlaceholder";
export const SELECT_BRAND_PLACEHOLDER = "selectBrandPlaceholder";
export const SELECT_STORE_PLACEHOLDER = "selectStorePlaceholder";
export const NAME_AND_LAST_NAME_PLACEHOLDER = "nameAndLastNamePlaceholder";
export const SELECT_TYPE_PLAN_PLACEHOLDER = "selectTypePlanPlaceholder";
export const COST_COLLABORATION_PLACEHOLDER = "costCollaborationPlaceholder";
export const CREDITS_PLACEHOLDER = "creditsPlaceholder";
export const TRIAL_DAYS_PLACEHOLDER = "trialDaysPlaceholder";
export const TYPE_PACKAGE_PLACEHOLDER = "typePackagePlaceholder";
export const PRICE_BASIC_PLACEHOLDER = "priceBasicPlaceholder";
export const COST_COLLABORATION_BASIC_PLACEHOLDER =
  "costCollaborationBasicPlaceholder";
export const PRICE_PRO_PLACEHOLDER = "priceProPlaceholder";
export const COST_COLLABORATION_PRO_PLACEHOLDER =
  "costCollaborationProPlaceholder";
export const PRICE_GROWTH_PLACEHOLDER = "priceGrowthPlaceholder";
export const COST_COLLABORATION_GROWTH_PLACEHOLDER =
  "costCollaborationGrowthPlaceholder";
export const COMMERCIAL_PLACEHOLDER = "commercialPlaceholder";
export const BUSINESS_NAME_PLACEHOLDER = "businessNamePlaceholder";
export const DESCRIPTION_OPTIONAL_PLACEHOLDER =
  "descriptionOptionalPlaceholder";
export const PRICE_PLACEHOLDER = "pricePlaceholder";
export const CURRENCY_PLACEHOLDER = "currencyPlaceholder";
export const IS_RECURRENCE_PLACEHOLDER = "isRecurrencePlaceholder";
export const RECURRENCE_PLACEHOLDER = "recurrencePlaceholder";
export const START_DATE_PLACEHOLDER = "startDatePlaceholder";
export const END_DATE_PLACEHOLDER = "endDatePlaceholder";
export const SELECT_CAMPAIGN_PLACEHOLDER = "selectCampaignPlaceholder";
export const SELECT_UGC_CONTENT_FORMAT = "selectUgcContentFormat";
export const SELECT_SOME_OPTIONS = "selectSomeOptions";
export const OBJECTIVE_PLACEHOLDER = "objectivePlaceholder";
export const PLATFORMS_PLACEHOLDER = "platformsPlaceholder";
export const PLATFORM_PLACEHOLDER = "platformPlaceholder";
export const TYPE_BUDGET_PLACEHOLDER = "typeBudgetPlaceholder";
export const DAILY_BUDGET_PLACEHOLDER = "dailyBudgetPlaceholder";
export const LIFETIME_BUDGET_PLACEHOLDER = "lifetimeBudgetPlaceholder";
export const LOCATIONS_PLACEHOLDER = "locationsPlaceholder";
export const AGE_RANGE_PLACEHOLDER = "ageRangePlaceholder";
export const GENDERS_PLACEHOLDER = "gendersPlaceholder";
export const INTERESTS_PLACEHOLDER = "interestsPlaceholder";
export const BEHAVIORS_PLACEHOLDER = "behaviorsPlaceholder";
export const DEMOGRAPHICS_PLACEHOLDER = "demographicsPlaceholder";
export const DESCRIPTION_PLACEHOLDER = "descriptionPlaceholder";
export const TRACKING_META_ID_PLACEHOLDER = "trackingMetaIdPlaceholder";
export const TRACKING_TIKTOK_ID_PLACEHOLDER = "trackingTikTokIdPlaceholder";
export const DESTINATION_URL_PLACEHOLDER = "destinationUrlPlaceholder";
export const CREATOR_BODY_BUILD_PLACEHOLDER = "creatorBodyBuildPlaceholder";
export const CREATOR_CLOTHING_STYLE_PLACEHOLDER =
  "creatorClothingStylePlaceholder";
export const CREATOR_CONTENT_STYLE_PLACEHOLDER =
  "creatorContentStylePlaceholder";
export const CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER =
  "creatorDistinguishingFeaturesPlaceholder";
export const CALL_TO_ACTION_PLACEHOLDER = "callToActionPlaceholder";
export const ADS_CAMPAIGN_CONTEXT = "adsCampaignContext";
export const URL_PLACEHOLDER = "urlPlaceholder";
export const SEARCH_TOPIC_PLACEHOLDER = "searchTopicPlaceholder";
export const SEARCH_USERNAME_PLACEHOLDER = "searchUsernamePlaceholder";
export const CREATOR_LOCATION_PLACEHOLDER = "creatorLocationPlaceholder";
export const AUDIENCE_LOCATION_PLACEHOLDER = "audienceLocationPlaceholder";
export const PLAN_PLACEHOLDER = "planPlaceholder";
export const ADS_CREDITS_PLACEHOLDER = "adsCreditsPlaceholder";
export const ADS_COINS_PLACEHOLDER = "adsCoinsPlaceholder";
export const ADVERTISER_PLACEHOLDER = "advertiserPlaceholder";

/*OPTIONS SELECTS*/
export const PLAN_PAY_PER_USE = "planPayPerUse";
export const PLAN_PACKAGE = "planPackage";
export const PLAN_REGULAR = "planRegular";
export const PLAN_ENTERPRISE = "planEnterprise";
export const ADD_NEW_OPTION = "addNewOption";
export const RECURRENT_OPTION = "recurrentOption";
export const RECURRING_DAYS = "recurringDays";
export const RECURRING_WEEKS = "recurringWeeks";
export const RECURRING_MONTHS = "recurringMonths";
export const RECURRING_YEARS = "recurringYears";

/*PLANS*/
export const PLAN_PAY_PER_USE_NAME = "planPayPerUseName";
export const PLAN_PACKAGE_NAME = "planPackageName";

/*FIELDS VALIDATIONS*/
export const FIELD_REQUIRED = "fieldRequired";
export const FIELD_NUMERIC = "fieldNumeric";
export const FIELD_TEXT = "fieldText";
export const FIELD_ALPHANUMERIC = "fieldAlphanumeric";
export const FIELD_MIN_LENGTH = "fieldMinLength";
export const FIELD_MAX_LENGTH = "fieldMaxLength";
export const FIELD_FILE_VALID_REQUIRED = "fieldFileValidRequired";
export const FIELD_FILE_MAX_SIZE = "fieldFileMaxSize";
export const FIELD_IMAGE_REQUIRED = "fieldImageRequired";
export const FIELD_IMAGE_FORMAT_VALID = "fieldImageFormatValid";
export const FIELD_IMAGE_SIZE_VALID = "fieldImageSizeValid";
export const FIELD_FILE_REQUIRED = "fieldImageRequired";
export const FIELD_FILE_SIZE_VALID = "fieldImageSizeValid";
export const FIELD_URL_VALID = "fieldUrlValid";
export const FIELD_URL_HTTPS_VALID = "fieldUrlHttpsValid";
export const FIELD_EMAIL_VALID = "fieldEmailValid";
export const FIELD_EMAIL_REGISTERED = "fieldEmailRegistered";
export const FIELD_SPACES_VALID = "fieldSpacesValid";
export const FIELD_PHONE_VALID = "fieldPhoneValid";
export const FIELD_ALL_REQUIRED = "fieldAllRequired";
export const FIELD_BOTH_PASSWORD_REQUIRED = "fieldBothPasswordRequired";
export const FIELD_PASSWORDS_NO_MATCH = "fieldPasswordsNoMatch";
export const FIELD_PASSWORD_MIN_LENGTH = "fieldPasswordMinLength";
export const FIELD_PASSWORD_CHANGE_MIN_LENGTH = "fieldPasswordChangeMinLength";
export const FIELD_PASSWORD_INSECURE = "fieldPasswordInsecure";
export const FIELD_NUMBER_NEGATIVE = "fieldNumberNegative";
export const FIELD_EMAIL_REQUIRED = "fieldEmailRequired";
export const FIELD_SEARCH_CHATS_MORE_3_CHARACTERS =
  "fieldSearchChatsMore3Characters";
export const FIELD_NUMBER_0_OR_NEGATIVE = "fieldNumber0OrNegative";
export const FIELD_NUMBER_100_GREATER = "fieldNumber100Greater";
export const FIELD_NUMBER_LESS_THAN = "fieldNumberLessThan";
export const FIELD_NUMBER_GREATER_THAN = "fieldNumberGreaterThan";
export const FIELD_CREDITS_NOT_BE_NEGATIVE = "fieldCreditsNotBeNegative";
export const FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE =
  "fieldCostCollaborationNotBeNegative";
export const FIELD_TRIAL_MONTHS_NOT_BE_NEGATIVE =
  "fieldTrialMonthsNotBeNegative";
export const FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE = "fieldTrialDaysNotBeNegative";
export const FIELD_PLAN_PRICES_NOT_BE_NEGATIVE = "fieldPlanPricesNotBeNegative";
export const FIELD_STAFF_IS_REQUIRED = "fieldStaffIsRequired";
export const FIELD_BOTH_DATES_REQUIRED = "fieldBothDatesRequired";
export const FIELD_START_DATE_GREATER_THAN_END_DATE =
  "fieldStartDateGreaterThanEndDate";
export const FIELD_END_DATE_GREATER_NOW = "fieldEndDateGreaterNow";
export const FIELD_MIN_AMOUNT_PAY = "fieldMinAmountPay";
export const FIELD_ACCESS_TOKEN_SHOPIFY_VALID = "fieldAccessTokenShopifyValid";
export const FIELD_NAME_REQUIRED = "fieldNameRequired";
export const FIELD_OBJECTIVE_REQUIRED = "fieldObjectiveRequired";
export const FIELD_PLATFORMS_REQUIRED = "fieldPlatformsRequired";
export const FIELD_PLATFORM_REQUIRED = "fieldPlatformRequired";
export const FIELD_TYPE_BUDGET_REQUIRED = "fieldTypeBudgetRequired";
export const FIELD_BUDGET_REQUIRED = "fieldBudgetRequired";
export const FIELD_CURRENCY_MIN_VALUE = "fieldCurrencyMinValue";
export const FIELD_LOCATIONS_REQUIRED = "fieldLocationsRequired";
export const FIELD_GENDERS_REQUIRED = "fieldGendersRequired";
export const FIELD_INTERESTS_REQUIRED = "fieldInterestsRequired";
export const FIELD_BEHAVIORS_REQUIRED = "fieldBehaviorsRequired";
export const FIELD_DESCRIPTION_REQUIRED = "fieldDescriptionRequired";
export const FIELD_VIDEO_REQUIRED = "fieldVideoRequired";
export const FIELD_META_TRACKING_ID_REQUIRED = "fieldMetaTrackingIdRequired";
export const FIELD_DESTINATION_URL_REQUIRED = "fieldDestinationUrlRequired";
export const FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED =
  "fieldDestinationUrlNotFoundInEventsPixelRequired";
export const FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL =
  "fieldDestinationUrlNotFoundInEventsPixel";
export const FIELD_TIKTOK_TRACKING_ID_REQUIRED =
  "fieldTikTokTrackingIdRequired";
export const FIELD_MIN_LENGTH_SELECT = "fieldMinLengthSelect";
export const FIELD_MAX_LENGTH_SELECT = "fieldMaxLengthSelect";
export const FIELD_CONTENT_REQUIRED = "fieldContentRequired";
export const FIELD_VERIFICATION_CODE_REQUIRED = "fieldVerificationCodeRequired";
export const FIELD_TYPE_MIN_LENGTH = "fieldTypeMinLength";
export const FIELD_MIN_VALUE_TO_PAY_AMBASSADOR = "fieldMinValueToPayAmbassador";

/*MODAL INVITATION*/
export const MODAL_INVITATION_TITLE = "modalInvitationTitle";
export const MODAL_INVITATION_TITLE_CAMPAIGN = "modalInvitationTitleCampaign";
export const INVITE_FORM_ORDER_FROM_PLACE_HOLDER =
  "inviteFormOrderFromPlaceHolder";
export const INVITE_FORM_ORDER_FROM_HELPER_TEXT =
  "inviteFormOrderFromHelperText";
export const INVITE_FORM_VALUE_QUESTION = "inviteFormValueQuestion";
export const INVITE_FORM_VALUE_CLARIFICATION = "inviteFormValueClarification";
export const INVITE_FORM_VALUE_PLACEHOLDER = "inviteFormValuePlaceholder";
export const INVITE_FORM_VALUE_PLACEHOLDER_2 = "inviteFormValuePlaceholder2";
export const INVITE_FORM_VALUE_HELPER_TEXT = "inviteFormValueHelperText";
export const INVITE_FORM_VALUE_SUGGESTED = "inviteFormValueSuggested";
export const INVITE_FORM_VALUE_TO_PAY_QUESTION = "inviteFormValueToPayQuestion";
export const INVITE_FORM_VALUE_TO_PAY_CLARIFICATION =
  "inviteFormValueToPayClarification";
export const INVITE_FORM_VALUE_TO_PAY_CLARIFICATION_2 =
  "inviteFormValueToPayClarification2";
export const INVITE_FORM_VALUE_TO_PAY_PLACEHOLDER =
  "inviteFormValueToPayPlaceholder";
export const INVITE_VALUE = "inviteValue";
export const INVITE_VALUE_EXPLANATION = "inviteValueExplanation";
export const INVITE_FORM_UGC_FORMAT_QUESTION = "inviteFormUgcFormatQuestion";
export const INVITE_FORM_LANGUAGE_QUESTION = "inviteFormLanguageQuestion";
export const INVITE_FORM_REFERENCE_URL_QUESTION =
  "inviteFormReferenceUrlQuestion";
export const INVITE_FORM_COMMENT_PLACEHOLDER = "inviteFormCommentPlaceholder";
export const INVITE_FORM_COMMENT_PLACEHOLDER_2 =
  "inviteFormCommentPlaceholder2";
export const INVITE_FORM_COMMENT_PLACEHOLDER_3 =
  "inviteFormCommentPlaceholder3";
export const INVITE_FORM_COMMENT_HELPER_TEXT = "inviteFormCommentHelperText";
export const INVITE_FORM_TYPE_CONTENT = "inviteFormTypeContent";
export const INVITE_FORM_TYPE_CONTENT_IMAGES = "inviteFormTypeContentImages";
export const INVITE_FORM_TYPE_CONTENT_VIDEOS = "inviteFormTypeContentVideos";
export const INVITE_FORM_TYPE_CONTENT_REQUIRED =
  "inviteFormTypeContentRequired";
export const INVITE_FORM_CODE_PLACEHOLDER = "inviteFormCodePlaceholder";
export const INVITE_FORM_CODE_HELPER_TEXT = "inviteFormCodeHelperText";
export const INVITE_FORM_CODE_REQUIRED = "inviteFormCodeRequired";
export const INVITE_FORM_CODE_MIN_LENGTH = "inviteFormCodeMinLength";
export const INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT =
  "inviteFormCodePlatformIntegrationText";
export const INVITE_FORM_DISCOUNT_CODE_PLACEHOLDER =
  "inviteFormDiscountCodePlaceholder";
export const INVITE_FORM_DISCOUNT_CODE_HELPER_TEXT =
  "inviteFormDiscountCodeHelperText";
export const INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION =
  "inviteFormAdvancedConfigurationDescription";
export const INVITE_FORM_SELECT_FORMATS_PLATFORM =
  "inviteFormSelectFormatsPlatform";
export const INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION =
  "inviteFormSelectFormatsPlatformDescription";
export const INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES =
  "inviteFormFormatInstagramBurstStories";
export const INVITE_FORM_FORMAT_INSTAGRAM_POST =
  "inviteFormFormatInstagramPost";
export const INVITE_FORM_FORMAT_INSTAGRAM_REEL =
  "inviteFormFormatInstagramReel";
export const INVITE_FORM_FORMAT_INSTAGRAM_STORY =
  "inviteFormFormatInstagramStory";
export const INVITE_FORM_FORMAT_TIKTOK_POST = "inviteFormFormatTiktokPost";
export const INVITE_FORM_FORMAT_TIKTOK_STORY = "inviteFormFormatTiktokStory";
export const INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER =
  "inviteFormPersonalizedUrlPlaceholder";
export const INVITE_FORM_TERMS_PLACEHOLDER = "inviteFormTermsPlaceholder";
export const INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER =
  "inviteFormSelectLanguagePlaceholder";
export const INVITE_FORM_SELECT_EVENTS_PLACEHOLDER =
  "inviteFormSelectEventsPlaceholder";
export const SELECT_EVENTS_DISABLED_OPTION = "selectEventsDisabledOption";
export const INVITE_SENT = "inviteSent";
export const INVITE_SENT_DESCRIPTION = "inviteSentDescription";
export const INVITE_EDIT = "inviteEdit";
export const REQUEST_REJECT_CONFIRMATION_DESCRIPTION =
  "requestRejectConfirmationDescription";
export const REQUEST_REJECTED = "requestRejected";
export const REQUEST_REJECTED_DESCRIPTION = "requestRejectedDescription";
export const INVITE_CANCELED = "inviteCanceled";
export const INVITE_CANCELED_DESCRIPTION = "inviteCanceledDescription";
export const INVITE_VALUE_REQUIRED = "inviteValueRequired";
export const INVITE_VALUE_MIN_VALUE = "inviteValueMinValue";
export const INVITE_VALUE_TO_PAY_MIN_VALUE = "inviteValueToPayMinValue";
export const INVITE_COMMENT_REQUIRED = "inviteCommentRequired";
export const INVITE_COMMENT_MIN_LENGTH = "inviteCommentMinLength";
export const INVITE_COMMENT_MAX_LENGTH = "inviteCommentMaxLength";
export const INVITE_COMMENT_NO_LINK = "inviteCommentNoLink";
export const INVITE_COMMENT_NO_PHONE = "inviteCommentNoPhone";
export const INVITE_TERMS_LENGTH = "inviteTermsLength";
export const INVITE_PERSONALIZED_URL_INVALID = "invitePersonalizedUrlInvalid";
export const INVITE_PERSONALIZED_URL_EXPLANATION =
  "invitePersonalizedUrlExplanation";
export const INVITE_MIN_VALUE_OF_MENU = "inviteMinValueOfMenu";
export const INVITE_STORE_LOCATION = "inviteStoreLocation";
export const INVITE_CODE_IN_OTHER_INVITE = "inviteCodeInOtherInvite";
export const INVITE_THESE_CREATORS = "inviteTheseCreators";
export const INVITE_THESE_CREATORS_CONFIRMATION =
  "inviteTheseCreatorsConfirmation";
export const INVITE_CREATORS = "inviteCreators";
export const LIST_OF_CREATORS = "listOfCreators";
export const MESSAGE_AFTER_INVITATION = "messageAfterInvitation";

/*MODAL COMMERCIAL*/
export const MODAL_COMMERCIAL_TITLE_CREATE = "modalCommercialTitleCreate";
export const MODAL_COMMERCIAL_TITLE_EDIT = "modalCommercialTitleEdit";
export const MODAL_COMMERCIAL_CREATE_COMMERCIAL =
  "modalCommercialCreateCommercial";
export const MODAL_COMMERCIAL_ALREADY_EXIST = "modalCommercialAlreadyExist";

/*MODAL CREATE PAYMENT LINK*/
export const MODAL_CREATE_PAYMENT_LINK_TITLE = "modalCreatePaymentLinkTitle";
export const MODAL_CREATE_PAYMENT_LINK_DESCRIPTION =
  "modalCreatePaymentLinkDescription";
export const MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL =
  "modalCreatePaymentLinkAriaLabel";
export const MODAL_CREATE_PAYMENT_LINK_LINKS = "modalCreatePaymentLinkLinks";
export const MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY =
  "modalCreatePaymentLinkLinksEmpty";
export const MODAL_CREATE_PAYMENT_LINK_NAME = "modalCreatePaymentLinkName";

/*MODAL HELP VIDEO AND FAQ*/
export const WARNING_MOBILE_TUTORIAL = "warningMobileTutorial";
export const FAQ_DESCRIPTION = "faqDescription";
export const FAQ_QUESTION_WHAT_IS_BUZZLY = "faqQuestionWhatIsBuzzly";
export const FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION =
  "faqQuestionWhatIsBuzzlyDescription";
export const FAQ_QUESTION_WHAT_IS_INVITATION = "faqQuestionWhatIsInvitation";
export const FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION =
  "faqQuestionWhatIsInvitationDescription";
export const FAQ_QUESTION_WHAT_IS_REQUEST = "faqQuestionWhatIsRequest";
export const FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION =
  "faqQuestionWhatIsRequestDescription";
export const FAQ_QUESTION_WHAT_IS_PUBLICATION = "faqQuestionWhatIsPublication";
export const FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION =
  "faqQuestionWhatIsPublicationDescription";
export const FAQ_QUESTION_WHAT_IS_AMBASSADOR = "faqQuestionWhatIsAmbassador";
export const FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION =
  "faqQuestionWhatIsAmbassadorDescription";
export const FAQ_QUESTION_WHAT_IS_CAMPAIGN = "faqQuestionWhatIsCampaign";
export const FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION =
  "faqQuestionWhatIsCampaignDescription";
export const FAQ_QUESTION_WHAT_IS_ORDER = "faqQuestionWhatIsOrder";
export const FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION =
  "faqQuestionWhatIsOrderDescription";
export const FAQ_QUESTION_WHAT_IS_STORE = "faqQuestionWhatIsStore";
export const FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION =
  "faqQuestionWhatIsStoreDescription";
export const FAQ_QUESTION_WHAT_ROLES_EXISTS = "faqQuestionWhatRolesExists";
export const FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION =
  "faqQuestionWhatRolesExistsDescription";
export const FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS =
  "faqQuestionAdditionalActionsDeliveredProducts";
export const FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION =
  "faqQuestionAdditionalActionsDeliveredProductsDescription";
export const FAQ_QUESTION_MUST_CALL_ADMIN = "faqQuestionMustCallAdmin";
export const FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION =
  "faqQuestionMustCallAdminDescription";
export const FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION =
  "faqQuestionCreatorWantMoreProductsExceedValueInvitation";
export const FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION =
  "faqQuestionCreatorWantMoreProductsExceedValueInvitationDescription";
export const FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE =
  "faqQuestionCreatorRedeemCodeWithMinorValue";
export const FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION =
  "faqQuestionCreatorRedeemCodeWithMinorValueDescription";
export const FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS =
  "faqQuestionDifferenceBetweenExchangesAndCampaigns";
export const FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION =
  "faqQuestionDifferenceBetweenExchangesAndCampaignsDescription";
export const FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS =
  "faqQuestionEnsureCreatorsUnderstandAcceptExchangeTerms";
export const FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION =
  "faqQuestionEnsureCreatorsUnderstandAcceptExchangeTermsDescription";

/*INFLUENCER STATISTICS*/
export const INFLUENCER_NOT_FOUND = "influencerNotFound";
export const LIST_CREATORS_WITH_AUDIENCE_SIMILAR =
  "listCreatorsWithAudienceSimilar";
export const AUDIENCE_INFORMATION = "audienceInformation";
export const AUDIENCE_CREDIBILITY = "audienceCredibility";
export const AUDIENCE_CREDIBILITY_TITLE_EXPLANATION =
  "audienceCredibilityTitleExplanation";
export const AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION =
  "audienceCredibilityDescriptionExplanation";
export const AUDIENCE_BY_COUNTRY = "audienceByCountry";
export const AUDIENCE_BY_CITIES = "audienceByCities";
export const AUDIENCE_BY_BRAND_AFFINITY = "brandAffinity";
export const AUDIENCE_BY_INTERESTS = "audienceByInterests";
export const AUDIENCE_BY_DISTRIBUTION_AGE = "audienceByDistributionAge";
export const AUDIENCE_BY_PERCENTAGE_GENDERS = "audienceByPercentageGenders";
export const AUDIENCE_BY_SIMILAR_AUDIENCE = "audienceBySimilarAudience";
export const AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND =
  "audienceBySimilarAudienceNotFound";
export const INFLUENCER_POPULAR_CONTENT = "influencerPosts";
export const GALLERY_CONTENT_BUZZLY = "galleryContentBuzzly";

/*LIST MENU*/
export const MENU_PLATFORM = "menuPlatform";
export const MENU_MEDIA = "menuMedia";
export const MENU_SERVICES = "menuServices";
export const MENU_ACTIONS = "menuActions";
export const MENU_CONFIGURATION = "menuConfiguration";
export const MENU_ADMINISTRATION = "menuAdministration";
export const SUB_MENU_CREATORS = "subMenuCreators";
export const SUB_MENU_AMBASSADORS = "subMenuAmbassadors";
export const SUB_MENU_INVITATIONS = "subMenuInvitations";
export const SUB_MENU_PUBLICATIONS = "subMenuPublications";
export const SUB_MENU_EVENTS = "subMenuEvents";
export const SUB_MENU_CAMPAIGNS = "subMenuCampaigns";
export const SUB_MENU_ADS_CAMPAIGNS = "subMenuAdsCampaigns";
export const SUB_MENU_REPORTS = "subMenuReports";
export const SUB_MENU_DISCOVERY = "subMenuDiscovery";
export const SUB_MENU_SOCIAL_FEED = "subMenuSocialFeed";
export const SUB_MENU_GALLERY = "subMenuGallery";
export const SUB_MENU_TOP_CONTENT = "subMenuTopContent";
export const SUB_MENU_REDEEM_CODE = "subMenuRedeemCode";
export const SUB_MENU_ORDERS = "subMenuOrders";
export const SUB_MENU_ACCOUNT = "subMenuAccount";
export const SUB_MENU_STORES = "subMenuStores";
export const SUB_MENU_MENU = "subMenuMenu";
export const SUB_MENU_USERS = "subMenuUsers";
export const SUB_MENU_BILLING_AND_PAYMENTS = "subMenuBillingAndPayments";
export const SUB_MENU_BILLING_DASHBOARD = "subMenuBillingDashboard";
export const SUB_MENU_BILLING_DATA = "subMenuBillingData";
export const SUB_MENU_USERS_OWNER = "subMenuUsersOwner";
export const SUB_MENU_BRANDS = "subMenuShops";
export const SUB_MENU_BRAND_SIGNUPS = "subMenuRegisterShops";
export const SUB_MENU_CREATORS_OWNER = "subMenuCreatorsOwner";
export const SUB_MENU_CREATORS_EXTERNALS = "subMenuCreatorsExternals";
export const SUB_MENU_PAY_CREATORS = "subMenuPayCreators";
export const SUB_MENU_CREATOR_SIGNUPS = "subMenuRequestsAccounts";
export const SUB_MENU_CONTENTS = "subMenuContents";
export const SUB_MENU_DELIVERIES = "subMenuDeliveries";
export const SUB_MENU_CONTENT_APPLICATION = "subMenuContentApplication";
export const SUB_MENU_REPORTS_OWNER = "subMenuReportsOwner";
export const SUB_MENU_FEEDBACK = "subMenuFeedback";
export const SUB_MENU_PAYMENTS_FAILED = "subMenuPaymentsFailed";
export const CANCEL_ANYTIME = "cancelAnytime";

/*DATE*/
export const DATE_HOUR = "dateHour";
export const DATE_SHORT = "dateShort";
export const DATE_SHORT_LOCALIZED = "dateShortLocalized";
export const DATE_LONG = "dateLong";
export const DATE_LONG_TEXT = "dateLongText";
export const DATE_LONG_TEXT_2 = "dateLongText2";
export const DATE_LONG_TEXT_WITH_HOUR = "dateLongTextWithHour";
export const DATE_LONG_LOCALIZED = "dateLongLocalized";
export const DATE_DISTANCE = "dateDistance";
export const LAST_UPDATE_DATE = "lastUpdateDate";
export const PUBLISHED_DATE = "publishedDate";

/*CURRENCY*/
export const VALUE_WITH_CURRENCY = "valueWithCurrency";
export const VALUE_WITH_PERCENTAGE = "valueWithPercentage";
export const NUMBER_CURRENCY = "numberCurrency";
export const VALUE_EXPRESSED_IN = "valueExpressedIn";

/*BUTTON*/
export const BUTTON_INVITE = "buttonInvite";
export const BUTTON_INVITE_ALL = "buttonInviteAll";
export const BUTTON_VIEW_INVITE = "buttonViewInvite";
export const BUTTON_EDIT_INVITE = "buttonEditInvite";
export const BUTTON_CANCEL_INVITE = "buttonCancelInvite";
export const BUTTON_VIEW_REQUEST = "buttonViewRequest";
export const BUTTON_REJECT_REQUEST = "buttonCancelRequest";
export const BUTTON_ORDER_IN_PROCESS = "buttonOrderInProcess";
export const BUTTON_VIEW_COLLABORATION = "buttonViewCollaboration";
export const BUTTON_VIEW_METRICS = "buttonViewMetrics";
export const BUTTON_VIEW_REPORT = "buttonViewReport";
export const BUTTON_INIT_CHAT = "buttonInitChat";
export const BUTTON_ADD_REFERENCE_PROFILE = "buttonAddReferenceProfile";
export const BUTTON_USE_AS_REFERENCE_PROFILE = "buttonUseAsReferenceProfile";
export const BUTTON_ADD_AS_REFERENCE = "buttonAddAsReference";
export const BUTTON_ADDED = "buttonAdded";
export const BUTTON_DOWNLOAD = "buttonDownload";
export const BUTTON_CALCULATE = "buttonCalculate";
export const BUTTON_ENTERPRISE_CALCULATE = "buttonEnterpriseCalculate";
export const BUTTON_REDEEM_CODE = "buttonRedeemCode";
export const BUTTON_ADD_STORE = "buttonAddStore";
export const BUTTON_SEARCH = "buttonSearch";
export const BUTTON_ACCEPT_ORDER = "buttonAcceptOrder";
export const BUTTON_REJECT_ORDER = "buttonRejectOrder";
export const BUTTON_READY_ORDER = "buttonReadyOrder";
export const BUTTON_DELIVER_ORDER = "buttonDeliverOrder";
export const BUTTON_CANCEL = "buttonCancel";
export const BUTTON_CREATE_CAMPAIGN = "buttonCreateCampaign";
export const BUTTON_CREATE_CAMPAIGN2 = "buttonCreateCampaign2";
export const BUTTON_SAVE_PROGRESS = "buttonSaveProgress";
export const BUTTON_NEXT = "buttonNext";
export const BUTTON_PREVIOUS = "buttonPrevious";
export const BUTTON_ADVANCED_CONFIGURATION = "buttonAdvancedConfiguration";
export const BUTTON_FINISH = "buttonFinish";
export const BUTTON_UPLOAD_CONTENT = "buttonUploadContent";
export const BUTTON_REMOVE_CONTENT = "buttonRemoveContent";
export const BUTTON_FINISHED_CAMPAIGN = "buttonFinishedCampaign";
export const BUTTON_SAVE_CAMPAIGN = "buttonSaveCampaign";
export const BUTTON_DEACTIVATE_CAMPAIGN = "buttonDeactivateCampaign";
export const BUTTON_ACTIVATE_CAMPAIGN = "buttonActivateCampaign";
export const BUTTON_INVITE_CREATOR = "buttonInviteCreator";
export const BUTTON_SEND_INVITE = "buttonSendInvite";
export const BUTTON_REJECT = "buttonReject";
export const BUTTON_ACCEPT = "buttonAccept";
export const BUTTON_ACCEPT_AND_PAY = "buttonAcceptAndPay";
export const BUTTON_ASSIGN_INVITE = "buttonAssignInvite";
export const BUTTON_REVIEW_CONTENT = "buttonReviewContent";
export const BUTTON_VIEW_PRELIMINARY_CONTENT = "buttonViewPreliminaryContent";
export const BUTTON_VIEW_APPLICANT = "buttonViewApplicant";
export const BUTTON_REVIEW = "buttonReview";
export const BUTTON_ACCEPT_CONTENT = "buttonAcceptContent";
export const BUTTON_REJECT_CONTENT = "buttonRejectContent";
export const BUTTON_SEND_REVIEW = "buttonSendReview";
export const BUTTON_CONTINUE = "buttonContinue";
export const BUTTON_CONTINUOUS = "buttonContinuous";
export const BUTTON_UNDERSTOOD = "buttonUnderstood";
export const BUTTON_MORE_QUESTIONS = "buttonMoreQuestions";
export const BUTTON_INIT_TUTORIAL = "buttonInitTutorial";
export const BUTTON_PAYMENTS = "buttonPayments";
export const BUTTON_BROADCAST = "buttonBroadcast";
export const BUTTON_SAVE = "buttonSave";
export const BUTTON_SEND_MESSAGE = "buttonSendMessage";
export const BUTTON_UPLOAD_FILE = "buttonUploadFile";
export const BUTTON_REMOVE_FILE = "buttonRemoveFile";
export const BUTTON_VERIFY_PUBLICATION = "buttonVerifyPublication";
export const BUTTON_SEE_PUBLICATION_ON = "buttonSeePublicationOn";
export const BUTTON_INVITE_AGAIN = "buttonInviteAgain";
export const BUTTON_INVITE_BUZZLY = "buttonInviteBuzzly";
export const BUTTON_METRICS_READY = "buttonMetricsReady";
export const BUTTON_UPLOAD_TIKTOK = "buttonUploadTiktok";
export const BUTTON_VIEW_PROFILE = "buttonViewProfile";
export const BUTTON_GENERATE_DESCRIPTION = "buttonGenerateDescription";
export const BUTTON_GENERATE_COMMENT = "buttonGenerateComment";
export const BUTTON_UPLOAD_IMAGE = "buttonUploadImage";
export const BUTTON_REMOVE_IMAGE = "buttonRemoveImage";
export const BUTTON_SAVE_CHANGES = "buttonSaveChanges";
export const BUTTON_UPLOAD_VIDEO = "buttonUploadVideo";
export const BUTTON_REMOVE_VIDEO = "buttonRemoveVideo";
export const BUTTON_DISCONNECT = "buttonDisconnect";
export const BUTTON_CONNECT_TIKTOK = "buttonConnectTiktok";
export const BUTTON_CONNECT_TIKTOK_BUSINESS = "buttonConnectTiktokBusiness";
export const BUTTON_BE_ECOMMERCE = "buttonBeEcommerce";
export const BUTTON_NOT_BE_ECOMMERCE = "buttonNotBeEcommerce";
export const BUTTON_ADD_TURN = "buttonAddTurn";
export const BUTTON_DELETE = "buttonDelete";
export const BUTTON_CREATE = "buttonCreate";
export const BUTTON_CONNECT_WOOCOMMERCE = "buttonConnectWoocommerce";
export const BUTTON_CREATE_USER = "buttonCreateUser";
export const BUTTON_CREATE_CONTACT = "buttonCreateContact";
export const BUTTON_VIEW_DETAILS = "buttonViewDetails";
export const BUTTON_DOWNLOAD_INVOICE = "buttonDownloadInvoice";
export const BUTTON_CLOSE = "buttonClose";
export const BUTTON_UPLOAD_CATALOG = "buttonUploadCatalog";
export const BUTTON_COPY_CATALOG = "buttonCopyCatalog";
export const BUTTON_DOWNLOAD_FORMAT = "buttonDownloadFormat";
export const BUTTON_DUPLICATE = "buttonDuplicate";
export const BUTTON_EDIT = "buttonEdit";
export const BUTTON_MOVE_OTHER_CATEGORY = "buttonMoveOtherCategory";
export const BUTTON_COPY = "buttonCopy";
export const BUTTON_REGISTER = "buttonRegister";
export const BUTTON_NO_HAVE_DOCUMENT = "buttonNoHaveDocument";
export const BUTTON_UPLOAD_AFTER = "buttonUploadAfter";
export const BUTTON_INVITED = "buttonInvited";
export const BUTTON_YES_I_SURE = "buttonYesISure";
export const BUTTON_SEND_REVISION = "buttonSendRevision";
export const BUTTON_APPROVE_CONTENT = "buttonApproveContent";
export const BUTTON_UPLOAD_INSTAGRAM = "buttonUploadInstagram";
export const BUTTON_CONNECT_INSTAGRAM = "buttonConnectInstagram";
export const BUTTON_RELOAD_PAGE = "buttonReloadPage";
export const BUTTON_REPLACE_IMAGE = "buttonReplaceImage";
export const BUTTON_GENERATE_CONTENT = "buttonGenerateContent";
export const BUTTON_REGENERATE_CONTENT = "buttonRegenerateContent";
export const BUTTON_REGENERATE_TEXT = "buttonRegenerateText";
export const BUTTON_UPLOAD_LOGO = "buttonUploadLogo";
export const BUTTON_REPLACE_LOGO = "buttonReplaceLogo";
export const BUTTON_UPLOAD_BACKGROUND = "buttonUploadBackground";
export const BUTTON_REPLACE_BACKGROUND = "buttonReplaceBackground";
export const BUTTON_REMOVE_BACKGROUND = "buttonRemoveBackground";
export const BUTTON_GENERATE_OTHER = "buttonGenerateOther";
export const BUTTON_SUBSCRIBE_NOW = "buttonSubscribeNow";
export const BUTTON_ADD_USER = "buttonAddUser";
export const BUTTON_ADD_SHOP = "buttonAddShop";
export const BUTTON_DOWNLOAD_REPORT = "buttonDownloadReport";
export const BUTTON_PASS_TO_SUBSCRIPTION_BASIC =
  "buttonPassToSubscriptionBasic";
export const BUTTON_EXTEND = "buttonExtend";
export const BUTTON_ARCHIVE = "buttonArchive";
export const BUTTON_UNARCHIVE = "buttonUnarchive";
export const BUTTON_CREATE_TRANSACTION = "buttonCreateTransaction";
export const BUTTON_UPDATE_STATISTICS = "buttonUpdateStatistics";
export const BUTTON_INVITE_EXCHANGE = "buttonInviteExchange";
export const BUTTON_INVITE_CAMPAIGN = "buttonInviteCampaign";
export const BUTTON_SEND_PAYMENT = "buttonSendPayment";
export const BUTTON_CREATOR_CHAT = "buttonCreatorChat";
export const BUTTON_SEND_FILE = "buttonSendFile";
export const BUTTON_SEE_MORE = "buttonSeeMore";
export const BUTTON_SEND = "buttonSend";
export const BUTTON_YOU_HAVE_DOUBTS = "buttonYouHaveDoubts";
export const BUTTON_SAVE_CONTENT = "buttonSaveContent";
export const BUTTON_SEND_BROADCAST = "buttonSendBroadcast";
export const BUTTON_ADD_CONTENT = "buttonAddContent";
export const BUTTON_SEE_ALL_APPLICANTS = "buttonSeeAllApplicants";
export const BUTTON_GO_BACK = "buttonGoBack";
export const BUTTON_CLEAR_FILTERS = "buttonClearFilters";
export const BUTTON_ACTIVATE_AUTOMATICS_INVITATIONS =
  "buttonActivateAutomaticsInvitations";
export const BUTTON_DEACTIVATE_AUTOMATICS_INVITATIONS =
  "buttonDeactivateAutomaticsInvitations";
export const BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR =
  "buttonSendAutomaticInvitesLookalikeCreator";
export const BUTTON_TIKTOK_BILLING = "buttonTiktokBilling";
export const BUTTON_META_BILLING = "buttonMetaBilling";
export const BUTTON_REPLACE_CONTENT = "buttonReplaceContent";
export const BUTTON_SELECT_VIDEO = "buttonSelectVideo";
export const BUTTON_REPLACE_VIDEO = "buttonReplaceVideo";
export const BUTTON_SELECT_THIS_CONTENT = "buttonSelectThisContent";
export const BUTTON_REMOVE_SELECTION = "buttonRemoveSelection";
export const BUTTON_RECHARGE_BALANCE = "buttonRechargeBalance";
export const BUTTON_CONNECT_SHOPIFY = "buttonConnectShopify";
export const BUTTON_CONNECT_META = "buttonConnectMeta";
export const BUTTON_CONTACT_AN_ADVISOR = "buttonContactAnAdvisor";
export const BUTTON_GENERATE_SCRIPT = "buttonGenerateScript";
export const BUTTON_VIEW_INVOICE = "buttonViewInvoice";
export const BUTTON_ADJUST_PREFERENCES = "buttonAdjustPreferences";
export const BUTTON_GENERATE_AGAIN = "buttonGenerateAgain";
export const BUTTON_CREATE_ADS_CAMPAIGN = "buttonCreateAdsCampaign";
export const BUTTON_EDIT_BUDGET = "buttonEditBudget";
export const BUTTON_PUBLISH_CAMPAIGN = "buttonPublishCampaign";
export const BUTTON_START_CHAT = "buttonStartChat";
export const BUTTON_ACTIVATE_NOW = "buttonActivateNow";
export const BUTTON_NOT_NOW = "buttonNotNow";
export const BUTTON_PROMOTE = "buttonPromote";
export const BUTTON_SELECT_CONTENT = "buttonSelectContent";
export const BUTTON_DISCARD = "buttonDiscard";
export const BUTTON_ACTIVATE_BRAND = "buttonActivateBrand";
export const BUTTON_SAVE_CATALOG = "buttonSaveCatalog";
export const BUTTON_SAVE_PRODUCT = "buttonSaveProduct";
export const BUTTON_RESEND_CODE = "buttonVerifyCode";
export const BUTTON_CREATE_PAYMENT_LINK = "buttonCreatePaymentLink";

/*CATEGORIES*/
export const NEW_CREATORS_CATEGORY = "newCategoryCreator";
export const FAVORITES_CATEGORY = "favoritesCategory";
export const TOP_RATED_CATEGORY = "topRatedCategory";

//VERSION
export const NEW_VERSION_TITLE = "newVersionTitle";
export const NEW_VERSION_DESCRIPTION = "newVersionDescription";

//INFLUENCER ACTIONS
export const DESCRIPTION_INVITE_EXCHANGE = "descriptionInviteExchange";
export const DESCRIPTION_INVITE_CAMPAIGN = "descriptionInviteCampaign";
export const DESCRIPTION_SEND_MESSAGE = "descriptionSendMessage";
export const DESCRIPTION_SEND_PAYMENT = "descriptionSendPayment";

//MODAL PAY INFLUENCER
export const MODAL_PAY_CREATOR_AMOUNT_LABEL = "modalPayCreatorAmountLabel";
export const PAY_INFLUENCER = "payInfluencer";
export const APPLICATION_VALUE = "applicationValue";

/*PAGES*/

//CREATORS
export const CREATORS_TITLE = "creatorsTitle";
export const CREATOR_DESCRIPTION = "creatorsDescription";
export const SEARCH_BAR_TEXT_CREATORS = "searchBarTextCreators";
export const TAB_CREATORS = "tabCreators";
export const TAB_REQUESTS = "tabRequests";
export const TAB_SUGGESTED = "tabSuggested";
export const CREATORS_SUGGESTED_EMPTY = "creatorsSuggestedEmpty";
export const CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER =
  "creatorsSuggestedBadConfiguration";
export const CREATORS_SUGGESTED_BAD_CONFIGURATION_AUTOMATIC_INVITATIONS =
  "creatorsSuggestedBadConfigurationAutomaticInvitations";
export const CREATORS_TABLE_COLUMN_NAME = "creatorsTableColumnName";
export const CREATORS_TABLE_COLUMN_CITY = "creatorsTableColumnCity";
export const CREATORS_TABLE_COLUMN_AUDIENCE = "creatorsTableColumnAudience";
export const CREATORS_TABLE_COLUMN_ENGAGEMENT = "creatorsTableColumnEngagement";
export const CREATORS_TABLE_COLUMN_DATE = "creatorsTableColumnDate";
export const CREATORS_TABLE_COLUMN_CATEGORIES = "creatorsTableColumnCategories";
export const CREATORS_TABLE_COLUMN_SCORE = "creatorsTableColumnScore";
export const CREATORS_TABLE_COLUMN_ACTIVITY = "creatorsTableColumnActivity";
export const DISCOVER_NEW_CREATORS = "discoverNewCreators";

//AMBASSADORS
export const AMBASSADORS_TITLE = "ambassadorsTitle";
export const AMBASSADORS_DESCRIPTION = "ambassadorsDescription";
export const AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION =
  "ambassadorsOnboardingFinishDescription";
export const AMBASSADORS_TABLE_ARIAL_LABEL = "ambassadorsTableAriaLabel";
export const AMBASSADORS_TABLE_COLUMN_PLATFORM =
  "ambassadorsTableColumnPlatform";
export const AMBASSADORS_TABLE_COLUMN_INVITATION =
  "ambassadorsTableColumnInvitation";
export const MODAL_AMBASSADOR_ARIAL_LABEL = "modalAmbassadorAriaLabel";
export const MODAL_AMBASSADOR_TITLE = "modalAmbassadorTitle";
export const MODAL_AMBASSADOR_DESCRIPTION = "modalAmbassadorDescription";
export const WITH_RECURRENT_INVITATION_TITLE = "withRecurrentInvitationTitle";
export const WITH_RECURRENT_PAYMENT_TITLE = "withRecurrentPaymentTitle";
export const VALUE_TO_PAY_AMBASSADOR = "valueToPayAmbassador";
export const VALUE_TO_PAY_AMBASSADOR_CLARIFICATION =
  "valueToPayAmbassadorClarification";
export const RECURRENT_FIELDS_DEFAULT_TITLE = "recurrentFieldsDefaultTitle";
export const RECURRENT_FIELDS_PAYMENT_TITLE = "recurrentFieldsPaymentTitle";
export const RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER =
  "recurrentFieldRepeatCountPlaceholder";
export const RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION =
  "recurrentFieldRepeatCountMinValidation";
export const RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION =
  "recurrentFieldRepeatCountMaxValidation";
export const RECURRENT_FIELD_FREQUENCY_PLACEHOLDER =
  "recurrentFieldFrequencyPlaceholder";
export const RECURRENT_FIELD_DAY_AN_PLACEHOLDER =
  "recurrentFieldDayAnPlaceholder";
export const RECURRENT_FIELD_DAY_ALL_PLACEHOLDER =
  "recurrentFieldDayAllPlaceholder";
export const MODAL_BROADCAST_ARIAL_LABEL = "modalBroadcastAriaLabel";
export const MODAL_BROADCAST_TITLE = "modalBroadcastTitle";
export const MODAL_BROADCAST_DESCRIPTION = "modalBroadcastDescription";
export const MODAL_BROADCAST_SELECT_USER_PLACEHOLDER =
  "modalBroadcastSelectUserPlaceholder";
export const MODAL_BROADCAST_SELECT_USER_REQUIRED =
  "modalBroadcastSelectUserRequired";
export const MODAL_BROADCAST_MESSAGE_PLACEHOLDER =
  "modalBroadcastMessagePlaceholder";
export const MODAL_BROADCAST_CONFIRM_TITLE = "modalBroadcastConfirmTitle";
export const MODAL_BROADCAST_CONFIRM_DESCRIPTION =
  "modalBroadcastConfirmDescription";

export const BROADCAST_MESSAGE_SENT = "broadcastMessageSent";
export const MODAL_PAY_AMBASSADORS_ARIAL_LABEL = "modalPayAmbassadorsAriaLabel";
export const MODAL_PAY_AMBASSADORS_TITLE = "modalPayAmbassadorsTitle";
export const MODAL_PAY_AMBASSADORS_DESCRIPTION =
  "modalPayAmbassadorsDescription";
export const MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER =
  "modalPayAmbassadorsSelectUserPlaceholder";
export const MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED =
  "modalPayAmbassadorsSelectUserRequired";
export const MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER =
  "modalPayAmbassadorsAmountPlaceholder";
export const MODAL_PAY_AMBASSADOR_AMOUNT_MIN = "modalPayAmbassadorAmountMin";
export const MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR =
  "modalPayAmbassadorAmountIndividualCreator";
export const MODAL_PAY_AMBASSADOR_TITLE_2 = "modalPayAmbassadorTitle2";
export const PAY_TO_AMBASSADORS = "payToAmbassadors";
export const INFLUENCER_IS_AMBASSADOR = "influencerIsAmbassador";
export const DO_AMBASSADOR = "doAmbassador";
export const EDIT_AMBASSADOR = "editAmbassador";
export const DONE_AMBASSADOR = "doneAmbassador";
export const MODAL_DO_AMBASSADOR_TITLE = "modalDoAmbassadorTitle";
export const MODAL_DO_AMBASSADOR_ARIA_LABEL = "modalDoAmbassadorAriaLabel";
export const MODAL_DO_AMBASSADOR_DESCRIPTION = "modalDoAmbassadorDescription";
export const AMBASSADOR_DELETE = "ambassadorDelete";
export const AMBASSADOR_DELETE_DESCRIPTION = "ambassadorDeleteDescription";

//INVITATIONS
export const INVITATIONS_TITLE = "invitationsTitle";
export const INVITATIONS_DESCRIPTION = "invitationsDescription";
export const INVITATIONS_ACTIVE_TITLE = "invitationsActiveTitle";
export const INVITATIONS_ACTIVE_TABLE_ARIA_LABEL =
  "invitationsActiveTableAriaLabel";
export const INVITATIONS_TABLE_COLUMN_CODE = "invitationsTableColumnCode";
export const INVITATIONS_TABLE_COLUMN_VALUE = "invitationsTableColumnValue";
export const INVITATIONS_TABLE_COLUMN_USE = "invitationsTableColumnUse";
export const INVITATIONS_TABLE_COLUMN_EXPIRATION =
  "invitationsTableColumnExpiration";
export const INVITATION_REDEEMED = "invitationRedeemed";
export const INVITATION_REJECTED = "invitationRejected";
export const INVITATION_EXPIRED = "invitationExpired";
export const INVITATIONS_INACTIVE_TITLE = "invitationsInactiveTitle";
export const INVITATIONS_INACTIVE_REDEEM_FILTER = "invitationsInactiveRedeem";
export const INVITATIONS_INACTIVE_EXPIRED_FILTER = "invitationsInactiveExpired";
export const INVITATIONS_INACTIVE_TABLE_ARIA_LABEL =
  "invitationsInactiveTableAriaLabel";
export const INVITATIONS_TABLE_COLUMN_TYPE_INVITATION =
  "invitationsTableColumnTypeInvitation";
export const INVITATIONS_TABLE_COLUMN_REJECTED_REASON =
  "invitationsTableColumnRejectedReason";
export const INVITATIONS_TABLE_COLUMN_DATE = "invitationsTableColumnDate";
export const INVITATIONS_EVENT_TABLE = "invitationsEventTable";
export const INVITATIONS_EVENT_TABLE_ARIA_LABEL =
  "invitationsEventTableAriaLabel";
export const INVITATIONS_EVENT_TABLE_COLUMN_EVENT =
  "invitationsEventTableColumnEvent";

//PUBLICATIONS
export const PUBLICATIONS_TITLE = "publicationsTitle";
export const PUBLICATIONS_DESCRIPTION = "publicationsDescription";
export const PUBLICATIONS_TABLE_ARIA_LABEL = "publicationsTableAriaLabel";
export const PUBLICATIONS_TABLE_COLUMN_DATE = "publicationsTableColumnDate";
export const PUBLICATION_STATUS_PENDING_CONTENT =
  "publicationStatusPendingContent";
export const PUBLICATION_STATUS_PENDING_APPROVAL =
  "publicationStatusPendingApproval";
export const PUBLICATION_STATUS_PENDING = "publicationStatusPending";
export const PUBLICATION_STATUS_PENDING_SOCIAL =
  "publicationStatusPendingSocial";
export const PUBLICATIONS_STATUS_PENDING_ANALYTICS =
  "publicationStatusPendingAnalytics";
export const PUBLICATIONS_STATUS_SENT = "publicationStatusSent";
export const PUBLICATIONS_STATUS_FEEDBACK = "publicationStatusFeedback";
export const PUBLICATIONS_STATUS_VERIFIED = "publicationStatusVerified";

//PUBLICATION
export const PUBLICATION_TITLE = "publicationTitle";
export const NUMBER_PUBLICATION = "numberPublication";
export const FEEDBACK_TITLE = "feedbackTitle";
export const PUBLICATION_DESCRIPTION = "publicationDescription";
export const PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION =
  "publicationFinishOnboardingNewBrandsDescription";
export const PUBLICATION_FINISH_DESCRIPTION = "publicationFinishDescription";
export const FEEDBACK_PLACEHOLDER = "feedbackPlaceholder";
export const FEEDBACK_REASON_ABOUT = "feedbackReasonAbout";
export const FEEDBACK_REASON = "feedbackReason";
export const FEEDBACK_COMMENT = "feedbackComment";
export const ANALYTICS_EXPLANATION_TITLE = "analyticsExplanationTitle";
export const ANALYTICS_EXPLANATION_DESCRIPTION =
  "analyticsExplanationDescription";
export const ANALYTICS_EXPLANATION_DESCRIPTION_2 =
  "analyticsExplanationDescription2";
export const ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION =
  "analyticsExplanationAccountsReachedDescription";
export const ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION =
  "analyticsExplanationEngagementDescription";
export const ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION =
  "analyticsExplanationStickersTapsDescription";
export const ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION =
  "analyticsExplanationNavigationDescription";
export const RECURRENT_VALUE_NOT_FOUND = "recurrentValueNotFound";
export const MODAL_REVIEW_ARIA_LABEL = "modalReviewAriaLabel";
export const MODAL_REVIEW_DISCLAIMER = "modalReviewDisclaimer";
export const MODAL_REVIEW_SCORE_PLACEHOLDER = "modalReviewScorePlaceholder";
export const MODAL_REVIEW_SCORE_CONTENT_PLACEHOLDER =
  "modalReviewScoreContentPlaceholder";
export const MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE =
  "modalReviewScoreContentMinValue";
export const MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE =
  "modalReviewScoreContentExplanationTitle";
export const MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION =
  "modalReviewScoreContentExplanationDescription";
export const MODAL_REVIEW_SCORE_ENGAGEMENT_PLACEHOLDER =
  "modalReviewScoreEngagementPlaceholder";
export const MODAL_REVIEW_SCORE_ENGAGEMENT_MIN_VALUE =
  "modalReviewScoreEngagementMinValue";
export const MODAL_REVIEW_COMMENT_PLACEHOLDER = "modalReviewCommentPlaceholder";
export const MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER =
  "modalReviewDoAmbassadorPlaceholder";
export const REVISION_COUNT = "revisionCount";
export const UPLOAD_NEW_CONTENT = "uploadNewContent";
export const ADD_URL_PUBLICATION = "addUrlPublication";

//ADS CAMPAIGNS
export const ADS_CAMPAIGNS_TITLE = "adsCampaignsTitle";
export const ADS_CAMPAIGNS_DESCRIPTION = "adsCampaignsDescription";

//ADS CAMPAIGN
export const ADS_CAMPAIGN_TITLE = "adsCampaignTitle";
export const ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE =
  "adsCampaignCampaignInformationSubTitle";
export const ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE =
  "adsCampaignBudgetInformationSubTitle";
export const ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE =
  "adsCampaignSegmentationSubTitle";
export const ADS_CAMPAIGN_HAS_BEEN_FINISHED = "adsCampaignHasBeenFinished";
export const ADS_CAMPAIGN_HAS_BEEN_ACTIVATE = "adsCampaignHasBeenActivate";
export const ADS_CAMPAIGN_HAS_BEEN_SAVE = "adsCampaignHasBeenSave";
export const ADS_CAMPAIGN_HAS_BEEN_DUPLICATE = "adsCampaignHasBeenDuplicate";

//CREATE ADS CAMPAIGN
export const CREATE_ADS_CAMPAIGN_NAME_QUESTION =
  "createAdsCampaignNameQuestion";
export const CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER =
  "createAdsCampaignNamePlaceholder";
export const CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION =
  "createAdsCampaignObjectiveQuestion";
export const CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION =
  "createAdsCampaignObjectiveDescription";
export const CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION =
  "createAdsCampaignDestinationUrlQuestion";
export const CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION =
  "createAdsCampaignTrackingMetaQuestion";
export const CREATE_ADS_CAMPAIGN_PLATFORM_QUESTION =
  "createAdsCampaignPlatformQuestion";
export const CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION =
  "createAdsCampaignTypeBudgetQuestion";
export const CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION =
  "createAdsCampaignBudgetDailyQuestion";
export const CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION =
  "createAdsCampaignBudgetTotalQuestion";
export const CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER =
  "createAdsCampaignBudgetPlaceholder";
export const CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION =
  "createAdsCampaignLocationsQuestion";
export const CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION =
  "createAdsCampaignAgeRangeQuestion";
export const CREATE_ADS_CAMPAIGN_GENDERS_QUESTION =
  "createAdsCampaignGendersQuestion";
export const CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION =
  "createAdsCampaignInterestsQuestion";
export const CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER =
  "createAdsCampaignInterestsPlaceholder";
export const CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION =
  "createAdsCampaignBehaviorsQuestion";
export const CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER =
  "createAdsCampaignBehaviorsPlaceholder";
export const CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION =
  "createAdsCampaignDemographicsQuestion";
export const CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER =
  "createAdsCampaignDemographicsPlaceholder";
export const CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION =
  "createAdsCampaignDescriptionQuestion";
export const CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER =
  "createAdsCampaignDescriptionPlaceholder";
export const CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION =
  "createAdsCampaignContextQuestion";
export const CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION =
  "createAdsCampaignContextDescription";
export const CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER =
  "createAdsCampaignContextPlaceholder";
export const CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION =
  "createAdsCampaignMediaUrlQuestion";
export const CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION =
  "createAdsCampaignLoadingGettingRequiredInformation";
export const CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION =
  "createAdsCampaignLoadingAnalyzingInformation";
export const CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS =
  "createAdsCampaignLoadingGeneratingRecommendations";
export const CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS =
  "createAdsCampaignLoadingFinalizingDetails";
export const CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_PRODUCT_ADVERTISE =
  "createAdsCampaignContextQuestionProductAdvertise";
export const CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_COMMUNICATE =
  "createAdsCampaignContextQuestionCommunicate";
export const CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_SEGMENTATION =
  "createAdsCampaignContextQuestionSegmentation";

export const CREATE_ADS_CAMPAIGN_INTRO_TITLE = "createAdsCampaignIntroTitle";
export const CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION =
  "createAdsCampaignIntroDescription";
export const CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN =
  "createAdsCampaignIntroFeatureCreateCampaign";
export const CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI =
  "createAdsCampaignIntroFeatureOptimizeWithAI";
export const CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE =
  "createAdsCampaignIntroFeatureManageSimple";
export const CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE =
  "createAdsCampaignIntroFeatureAnalyzePerformance";
export const CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE =
  "createAdsCampaignIntroFeatureAllInOnePlace";
export const CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION =
  "createAdsCampaignIntroCallToActionDescription";
export const CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON =
  "createAdsCampaignIntroCallToActionButton";

//CAMPAIGNS
export const CAMPAIGNS_TITLE = "campaignsTitle";
export const CAMPAIGNS_DESCRIPTION = "campaignsDescription";
export const CAMPAIGNS_SEARCHBAR_PLACEHOLDER = "campaignsSearchBarPlaceholder";
export const CAMPAIGNS_TABLE_COLUMN_NAME = "campaignsTableColumnName";
export const CAMPAIGNS_TABLE_COLUMN_PLATFORMS = "campaignsTableColumnPlatforms";
export const CAMPAIGNS_TABLE_COLUMN_STATUS = "campaignsTableColumnStatus";
export const CAMPAIGN_ACTIVATE = "campaignActivate";
export const CAMPAIGN_DEACTIVATE = "campaignDeactivate";
export const CAMPAIGN_ACTIVATE_TIME_CONFIRMATION =
  "campaignActivateTimeConfirmation";
export const CAMPAIGN_DEACTIVATE_TIME = "campaignDeactivateTime";
export const CAMPAIGN_FINISHED_TIME = "campaignDeactivateTime";
export const CAMPAIGN_DEACTIVATE_APPLICANTS_ACCEPTED =
  "campaignDeactivateApplicantsAccepted";
export const CAMPAIGN_FINISH_APPLICANTS_ACCEPTED =
  "campaignFinishApplicantsAccepted";
export const CAMPAIGN_ACTIVATE_FIELDS_REQUIRED =
  "campaignActivateFieldsRequired";
export const CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR =
  "campaignActivateMinBudgetCreator";
export const CAMPAIGN_ACTIVATE_TRAFFIC_LINK = "campaignActivateTrafficLink";
export const CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM =
  "campaignActivateHasCollaborationUserNameInstagram";
export const CAMPAIGN_ACTIVATE_ATTACHMENT_LINK =
  "campaignActivateAttachmentLink";
export const CAMPAIGN_FINISHED_CONFIRMATION = "campaignFinishedConfirmation";
export const CAMPAIGN_HAS_BEEN_FINISHED = "campaignHasBeenFinished";
export const CAMPAIGN_FINISH_ONBOARDING = "campaignFinishOnboarding";

//CAMPAIGN DETAIL
export const CAMPAIGN_DETAIL_TITLE = "campaignDetailTitle";
export const CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE =
  "campaignDetailApplicantsAcceptedTitle";
export const CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE =
  "campaignDetailApplicantsPendingTitle";
export const CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE =
  "campaignDetailApplicantsInvitedTitle";
export const CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER =
  "campaignDetailModalSendInvitePlaceholder";
export const CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER =
  "campaignDetailModalSendInviteValueInvitationPlaceholder";
export const CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION =
  "campaignDetailModalSendInviteCommentInvitation";
export const CAMPAIGN_DETAIL_CREATORS_INVITED_TITLE =
  "campaignDetailCreatorsInvitedTitle";
export const CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE =
  "campaignDetailCampaignInformationTitle";
export const CAMPAIGN_DETAIL_APPLICANT_PAYMENT_DESCRIPTION =
  "campaignDetailApplicantPaymentDescription";
export const CAMPAIGN_DETAIL_APPLICANT_PAYMENT_ADDITIONAL_DESCRIPTION =
  "campaignDetailApplicantPaymentAdditionalDescription";
export const CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION =
  "campaignDetailRejectApplicantConfirmation";
export const CAMPAIGN_DETAIL_INVITE_CREATOR_DESCRIPTION =
  "campaignDetailInviteCreatorDescription";
export const CAMPAIGN_DETAIL_INVITE_EXTERNAL_DESCRIPTION =
  "campaignDetailInviteExternalDescription";
export const CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_PLACEHOLDER =
  "campaignDetailInviteExternalBudgetPlaceholder";
export const CAMPAIGN_DETAIL_INVITE_EXTERNAL_ACCOUNT_NAME_REQUIRED =
  "campaignDetailInviteExternalAccountNameRequired";
export const CAMPAIGN_DETAIL_INVITE_EXTERNAL_SOCIAL_NETWORK_REQUIRED =
  "campaignDetailInviteExternalSocialNetworkRequired";
export const CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_REQUIRED =
  "campaignDetailInviteExternalBudgetRequired";
export const CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_MIN_VALUE =
  "campaignDetailInviteExternalBudgetMinValue";

export const CAMPAIGN_FIELDS_REQUIRED = "campaignFieldsRequired";
export const CAMPAIGN_NAME_PLACEHOLDER = "campaignNamePlaceholder";
export const CAMPAIGN_DESCRIPTION_PLACEHOLDER =
  "campaignDescriptionPlaceholder";
export const CAMPAIGN_GOAL_PLACEHOLDER = "campaignGoalPlaceholder";
export const CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER =
  "campaignAmountInfluencersPlaceholder";
export const CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER =
  "campaignTypeInfluencersPlaceholder";
export const CAMPAIGN_BUDGET_PLACEHOLDER = "campaignBudgetPlaceholder";
export const CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER =
  "campaignWithContentInSocialNetworkPlaceholder";
export const CAMPAIGN_PLATFORMS_PLACEHOLDER = "campaignPlatformsPlaceholder";
export const CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER =
  "campaignTypeContentMediaPlaceholder";
export const CAMPAIGN_TYPE_CONTENT_PLACEHOLDER =
  "campaignTypeContentPlaceholder";
export const CAMPAIGN_COPY_POST_PLACEHOLDER = "campaignCopyPostPlaceholder";
export const CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER =
  "campaignTrafficLinkPlaceholder";
export const CAMPAIGN_HAS_COLLABORATION_PLACEHOLDER =
  "campaignHasCollaborationPlaceholder";
export const CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER =
  "campaignProductAttributesPlaceholder";
export const CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER =
  "campaignMessageContentPlaceholder";
export const CAMPAIGN_DATES_START_DATE_PLACEHOLDER =
  "campaignDatesStartDatePlaceholder";
export const CAMPAIGN_DATES_END_DATE_PLACEHOLDER =
  "campaignDatesEndDatePlaceholder";
export const CAMPAIGN_REPOST_PLACEHOLDER = "campaignRepostPlaceholder";
export const CAMPAIGN_REPOST_PLACEHOLDER_ITEM = "campaignRepostPlaceholderItem";
export const CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER =
  "campaignAudienceLocationPlaceholder";
export const CAMPAIGN_AGE_RANGE_PLACEHOLDER = "campaignAgeRangePlaceholder";
export const CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER =
  "campaignCategoriesInfluencersPlaceholder";
export const CAMPAIGN_BRIEF_PLACEHOLDER = "campaignBriefPlaceholder";
export const CAMPAIGN_BRIEF_DESCRIPTION = "campaignBriefDescription";
export const CAMPAIGN_ATTACHMENT_FILE_PLACEHOLDER =
  "campaignAttachmentFilePlaceholder";
export const CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION =
  "campaignAttachmentFileDescription";
export const CAMPAIGN_ATTACHMENT_LINK_TITLE = "campaignAttachmentLinkTitle";
export const CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION =
  "campaignAttachmentLinkDescription";
export const CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER =
  "campaignAttachmentLinkPlaceholder";
export const CAMPAIGN_GENDERS_PLACEHOLDER = "campaignGendersPlaceholder";
export const CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER =
  "campaignReferenceProfilesPlaceholder";
export const CAMPAIGN_HASHTAG_PLACEHOLDER = "campaignHashtagPlaceholder";
export const CAMPAIGN_MESSAGE_TONE_PLACEHOLDER =
  "campaignMessageTonePlaceholder";
export const CAMPAIGN_ENVIRONMENT_PLACEHOLDER =
  "campaignEnvironmentPlaceholder";
export const CAMPAIGN_COLORS_CONTENT_PLACEHOLDER =
  "campaignColorsContentPlaceholder";
export const CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER =
  "campaignProhibitedColorsContentPlaceholder";
export const CAMPAIGN_KEY_WORDS_PLACEHOLDER = "campaignKeyWordsPlaceholder";
export const CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER =
  "campaignProhibitedKeyWordsPlaceholder";
export const CAMPAIGN_OUTFIT_PLACEHOLDER = "campaignOutfitPlaceholder";

//CREATE CAMPAIGNS
export const CREATE_CAMPAIGN_QUESTION = "createCampaignQuestion";
export const CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER =
  "createCampaignAcceptTermsDisclaimer";
export const CREATE_CAMPAIGN_NAME_EXAMPLE = "createCampaignNameExample";
export const CREATE_CAMPAIGN_NAME_REQUIRED = "createCampaignNameRequired";
export const CREATE_CAMPAIGN_DESCRIPTION_REQUIRED =
  "createCampaignDescriptionRequired";
export const CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED =
  "createCampaignAmountInfluencersRequired";
export const CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE =
  "createCampaignAmountInfluencerMinValue";
export const CREATE_CAMPAIGN_BUDGET_REQUIRED = "createCampaignBudgetRequired";
export const CREATE_CAMPAIGN_BUDGET_MIN_VALUE = "createCampaignBudgetMinValue";
export const CREATE_CAMPAIGN_PLATFORMS_REQUIRED =
  "createCampaignPlatformsRequired";
export const CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA =
  "createCampaignTypeContentMedia";
export const CREATE_CAMPAIGN_TYPE_CONTENT = "createCampaignTypeContent";
export const CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED =
  "createCampaignTrafficLinkRequired";
export const CREATE_CAMPAIGN_TRAFFIC_LINK_VALID =
  "createCampaignTrafficLinkValid";
export const CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED =
  "createCampaignProductAttributesRequired";
export const CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED =
  "createCampaignMessageContentRequired";
export const CREATE_CAMPAIGN_DATES_REQUIRED = "createCampaignDatesRequired";
export const CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION =
  "createCampaignRepostHasCollaboration";
export const CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED =
  "createCampaignAudienceLocationRequired";
export const CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS =
  "createCampaignCategoriesInfluencers";
export const CREATE_CAMPAIGN_ATTACHMENT_LINK = "createCampaignAttachmentLink";
export const CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE =
  "createCampaignHasBeenActivate";
export const CREATE_CAMPAIGN_HAS_BEEN_SAVE = "createCampaignHasBeenSave";
export const CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED =
  "createCampaignSaveProgressNameRequired";
export const CREATE_CAMPAIGN_SAVE_PROGRESS = "createCampaignSaveProgress";
export const CREATE_CAMPAIGN_NAME_QUESTION = "createCampaignNameQuestion";
export const CREATE_CAMPAIGN_NAME_PLACEHOLDER = "createCampaignNamePlaceholder";
export const CREATE_CAMPAIGN_DESCRIPTION_QUESTION =
  "createCampaignDescriptionQuestion";
export const CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER =
  "createCampaignDescriptionPlaceholder";
export const CREATE_CAMPAIGN_GOAL_QUESTION = "createCampaignGoalQuestion";
export const CREATE_CAMPAIGN_GOAL_PLACEHOLDER = "createCampaignGoalPlaceholder";
export const CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION =
  "createCampaignGoalEngagementOption";
export const CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION =
  "createCampaignGoalBrandAwarenessOption";
export const CREATE_CAMPAIGN_GOAL_LEADS_OPTION =
  "createCampaignGoalLeadsOption";
export const CREATE_CAMPAIGN_GOAL_SALES_OPTION =
  "createCampaignGoalSalesOption";
export const CREATE_CAMPAIGN_GOAL_REQUIRED = "createCampaignGoalRequired";
export const CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION =
  "createCampaignAmountInfluencersQuestion";
export const CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER =
  "createCampaignAmountInfluencersPlaceholder";
export const CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION =
  "createCampaignTypeInfluencersQuestion";
export const CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION =
  "createCampaignTypeInfluencersDescription";
export const CREATE_CAMPAIGN_BUDGET_QUESTION = "createCampaignBudgetQuestion";
export const CREATE_CAMPAIGN_BUDGET_DESCRIPTION =
  "createCampaignBudgetDescription";
export const CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION =
  "createCampaignWithContentInSocialNetworkQuestion";
export const CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_DESCRIPTION =
  "createCampaignWithContentInSocialNetworkDescription";
export const CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER =
  "createCampaignWithContentInSocialNetworkPlaceholder";
export const CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION =
  "createCampaignWithContentInSocialNetworkUgcOption";
export const CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION =
  "createCampaignWithContentInSocialNetworkInfluencerOption";
export const CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED =
  "createCampaignWithContentInSocialNetworkRequired";
export const CREATE_CAMPAIGN_PLATFORMS = "createCampaignPlatforms";
export const CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION =
  "createCampaignTypeContentMediaQuestion";
export const CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION =
  "createCampaignTypeContentQuestion";
export const CREATE_CAMPAIGN_COPYWRITING_QUESTION =
  "createCampaignCopywritingQuestion";
export const CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER =
  "createCampaignCopywritingPlaceholder";
export const CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION =
  "createCampaignTrafficLinkQuestion";
export const CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER =
  "createCampaignTrafficLinkPlaceholder";
export const CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION =
  "createCampaignHasCollaborationQuestion";
export const CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION =
  "createCampaignProductAttributesQuestion";
export const CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER =
  "createCampaignProductAttributesPlaceholder";
export const CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION =
  "createCampaignMessageContentQuestion";
export const CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER =
  "createCampaignMessageContentPlaceholder";
export const CREATE_CAMPAIGN_DATES_QUESTION = "createCampaignDatesQuestion";
export const CREATE_CAMPAIGN_DATES_DESCRIPTION =
  "createCampaignDatesDescription";
export const CREATE_CAMPAIGN_DATES_START_DATE_PLACEHOLDER =
  "createCampaignDatesStartDatePlaceholder";
export const CREATE_CAMPAIGN_DATES_END_DATE_PLACEHOLDER =
  "createCampaignDatesEndDatePlaceholder";
export const CREATE_CAMPAIGN_REPOST_QUESTION = "createCampaignRepostQuestion";
export const CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION =
  "createCampaignAudienceLocationQuestion";
export const CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER =
  "createCampaignAudienceLocationPlaceholder";
export const CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION =
  "createCampaignAudienceLocationAllOption";
export const CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION =
  "createCampaignCategoriesInfluencersQuestion";
export const CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER =
  "createCampaignCategoriesInfluencersPlaceholder";
export const CREATE_CAMPAIGN_AGE_RANGE_QUESTION =
  "createCampaignAgeRangeQuestion";
export const CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION =
  "createCampaignUGCContentFormatQuestion";
export const CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER =
  "createCampaignUGCContentFormatPlaceholder";
export const CREATE_CAMPAIGN_BRIEF_QUESTION = "createCampaignBriefQuestion";
export const CREATE_CAMPAIGN_BRIEF_DESCRIPTION =
  "createCampaignBriefDescription";
export const CREATE_CAMPAIGN_UPLOAD_BRIEF = "createCampaignUploadBrief";
export const CREATE_CAMPAIGN_REMOVED_BRIEF = "createCampaignRemovedBrief";
export const CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION =
  "createCampaignAttachmentFileQuestion";
export const CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION =
  "createCampaignAttachmentFileDescription";
export const CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION =
  "createCampaignAttachmentLinkQuestion";
export const CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION =
  "createCampaignAttachmentLinkDescription";
export const CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER =
  "createCampaignAttachmentLinkPlaceholder";
export const CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION =
  "createCampaignQuantityGenderQuestion";
export const CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION =
  "createCampaignReferenceProfilesQuestion";
export const CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER =
  "createCampaignReferenceProfilesPlaceholder";
export const CREATE_CAMPAIGN_HASHTAG_QUESTION = "createCampaignHashtagQuestion";
export const CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER =
  "createCampaignHashtagPlaceholder";
export const CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION =
  "createCampaignMessageToneQuestion";
export const CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER =
  "createCampaignMessageTonePlaceholder";
export const CREATE_CAMPAIGN_ENVIRONMENT_QUESTION =
  "createCampaignEnvironmentQuestion";
export const CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER =
  "createCampaignEnvironmentPlaceholder";
export const CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION =
  "createCampaignColorsContentQuestion";
export const CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER =
  "createCampaignColorsContentPlaceholder";
export const CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION =
  "createCampaignProhibitedColorsContentQuestion";
export const CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER =
  "createCampaignProhibitedColorsContentPlaceholder";
export const CREATE_CAMPAIGN_KEY_WORDS_QUESTION =
  "createCampaignKeyWordsQuestion";
export const CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER =
  "createCampaignKeyWordsPlaceholder";
export const CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION =
  "createCampaignProhibitedWordsQuestion";
export const CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER =
  "createCampaignProhibitedWordsPlaceholder";
export const CREATE_CAMPAIGN_OUTFIT_QUESTION = "createCampaignOutfitQuestion";
export const CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER =
  "createCampaignOutfitPlaceholder";
export const CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE =
  "createCampaignModalGenerateScriptAndDescriptionTitle";
export const CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING =
  "createCampaignModalGenerateScriptAndDescriptionTitleGenerating";
export const CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL =
  "createCampaignModalGenerateScriptAndDescriptionScriptLabel";

//APPLICANTS
export const APPLICANTS_STATUS_PENDING = "applicantsStatusPending";
export const APPLICANTS_STATUS_ACCEPTED = "applicantsStatusAccepted";
export const APPLICANTS_STATUS_REJECTED = "applicantsStatusRejected";
export const APPLICANTS_STATUS_CONTENT_SUBMITTED =
  "applicantsStatusContentSubmitted";
export const APPLICANTS_STATUS_CONTENT_REVISIONS =
  "applicantsStatusContentRevisions";
export const APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL =
  "applicantsStatusContentPendingSocial";
export const APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS =
  "applicantsStatusContentPendingAnalytics";
export const APPLICANTS_STATUS_DELIVERED = "applicantsStatusDelivered";
export const APPLICANT_CONTENT_OF_CREATOR = "applicantContentOfCreator";
export const APPLICANT_REVISION_PLACEHOLDER = "applicantRevisionPlaceholder";

//REPORTS SHOP
export const REPORT_SHOP_DESCRIPTION = "reportShopDescription";
export const START_DATE_AND_END_DATE_VALIDATION =
  "startDateAndEndDateValidation";
export const GLOBAL_REPORT_BUSINESS = "globalReportBusiness";
export const INSUFFICIENT_DATA_REPORT = "insufficientDataReport";
export const TOTAL_INVESTMENT = "totalInvestment";
export const TOTAL_EXPENSES = "totalExpenses";
export const COSTS_PER_1000_VIEWS = "costsPer1000Views";
export const COSTS_PER_ENGAGEMENT = "costsPerEngagement";
export const ACCOUNTS_REACHED = "accountsReached";
export const LIKES = "likes";
export const SHARES = "shares";
export const SAVES = "saves";
export const REPLIES = "replies";
export const STICKERS_TAPS = "stickersTaps";
export const COLLABORATIONS_SUMMARY = "collaborationsSummary";
export const PENDING_PUBLICATIONS = "pendingPublications";
export const PENDING_ANALYTICS_PUBLICATIONS = "pendingAnalyticsPublications";
export const FEEDBACK_PUBLICATIONS = "feedbackPublications";
export const INVITATIONS_SUMMARY = "invitationsSummary";
export const TOTAL_INVITATIONS = "totalInvitations";
export const INVITATIONS_REDEEMED = "invitationsRedeemed";
export const INVITATIONS_REJECTED = "invitationsRejected";
export const INVITATIONS_EXPIRED = "invitationsExpired";
export const INVITATIONS_ACCEPTANCE_RATE = "invitationsAcceptanceRate";
export const COLLABORATIONS_FOR_PLATFORM = "collaborationsForPlatform";
export const FEATURED_CONTENT = "featuredContent";
export const EMPTY_IMAGES_FOR_COMMENT = "emptyImagesForComment";
export const UPDATING_ANALYTICS = "updatingAnalytics";
export const REPORT_ORDERS = "reportsOrders";
export const ORDERS = "orders";
export const DELIVERIES = "deliveries";
export const IN_STORE = "inStore";
export const ORDERS_PENDING = "ordersPending";
export const ORDERS_PENDING_ANALYTICS = "ordersPendingAnalytics";
export const ORDERS_FEEDBACK = "ordersFeedback";
export const ORDERS_SENT = "ordersSent";
export const ORDERS_REVIEWED = "ordersReviewed";
export const CREATOR = "creator";
export const VALUE = "value";
export const WAS_GENERATED = "wasGenerated";

//DISCOVERY INFLUENCERS
export const DISCOVERY_INFLUENCERS_TITLE = "discoveryInfluencersTitle";
export const DISCOVERY_INFLUENCERS_DESCRIPTION =
  "discoveryInfluencersDescription";
export const DISCOVERY_INFLUENCERS_DONE_ONBOARDING =
  "discoveryInfluencersDoneOnboarding";
export const DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS =
  "discoveryInfluencersNoFoundResultsWithParameters";
export const DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED =
  "discoveryInfluencersNoFoundResultsFailed";
export const DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION =
  "discoveryInfluencersInviteSentDescription";
export const DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER =
  "discoveryInfluencersAccountNamePlaceholder";
export const DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER =
  "discoveryInfluencersPlatformPlaceholder";
export const DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER =
  "discoveryInfluencersLocationsPlaceholder";
export const DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER =
  "discoveryInfluencersMinFollowersPlaceholder";
export const DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE =
  "discoveryInfluencersMinFollowersMinValue";
export const DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED =
  "discoveryInfluencersMinFollowersRequired";
export const DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED =
  "discoveryInfluencersMinFollowersAndMaxFollowersRequired";
export const DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS =
  "discoveryInfluencersMinFollowersGreaterThanMaxFollowers";
export const DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER =
  "discoveryInfluencersMaxFollowersPlaceholder";
export const DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE =
  "discoveryInfluencersMaxFollowersMinValue";
export const DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED =
  "discoveryInfluencersMaxFollowersRequired";
export const DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED =
  "discoveryInfluencersMaxFollowersAndMinFollowersRequired";
export const DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS =
  "discoveryInfluencersMaxFollowersLessThanMinFollowers";
export const DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER =
  "discoveryInfluencersEngagementPlaceholder";
export const DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE =
  "discoveryInfluencersEngagementMinValue";
export const DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE =
  "discoveryInfluencersEngagementMaxValue";
export const DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE =
  "discoveryInfluencersEngagementType";
export const DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER =
  "discoveryInfluencersCreatorAgePlaceholder";
export const DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER =
  "discoveryInfluencerCreatorGenderPlaceholder";
export const DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER =
  "discoveryInfluencersAudienceGenderPlaceholder";
export const DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER =
  "discoveryInfluencersAudienceAgePlaceholder";
export const DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER =
  "discoveryInfluencersAudienceInterestsPlaceholder";
export const DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER =
  "discoveryInfluencersKeywordsPlaceholder";
export const DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL =
  "discoveryInfluencersTableAriaLabel";
export const DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME =
  "discoveryInfluencersTableColumnAccountName";
export const DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION =
  "discoveryInfluencersTableColumnLocation";
export const DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS =
  "discoveryInfluencersTableColumnFollowers";
export const DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT =
  "discoveryInfluencersTableColumnEngagement";
export const DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES =
  "discoveryInfluencersTableColumnAvgLikes";
export const DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED =
  "discoveryInfluencersInfluencerIsInvited";
export const DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND =
  "discoveryInfluencersStatisticsNoFound";
export const DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS =
  "discoveryInfluencersNoMoreInfluencers";

//GALLERY
export const MODAL_GALLERY_ARIA_LABEL = "modalGalleryAriaLabel";
export const MODAL_GALLERY_EMPTY_CONTENT = "modalGalleryEmptyContent";
export const MODAL_GALLERY_NO_MORE_CONTENT = "modalGalleryNoMoreContent";

//MODAL MEDIA
export const MODAL_MEDIA_ARIA_LABEL = "modalMediaAriaLabel";

//MODAL UPLOAD TIKTOK
export const MODAL_UPLOAD_TIKTOK_ARIA_LABEL = "modalUploadTiktokAriaLabel";
export const MODAL_UPLOAD_TIKTOK_TITLE = "modalUploadTiktokTitle";
export const MODAL_UPLOAD_FAILED_GET_CONFIGURATION =
  "modalUploadFailedGetConfiguration";
export const MODAL_UPLOAD_CONTENT_UPLOADED = "modalUploadTiktokVideoUploaded";
export const MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION =
  "modalUploadTiktokVideoUploadedDescription";
export const MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION =
  "modalUploadTiktokFailedGenerateDescription";
export const MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL =
  "modalUploadTiktokDescriptionLabel";
export const MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER =
  "modalUploadTiktokDescriptionPlaceholder";
export const MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL =
  "modalUploadTiktokWhoCanWatchLabel";
export const MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION =
  "modalUploadTiktokWhoCanWatchPublicOption";
export const MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION =
  "modalUploadTiktokWhoCanWatchFollowersOption";
export const MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION =
  "modalUploadTiktokWhoCanWatchOnlyIOption";
export const MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER =
  "modalUploadTiktokAllowUsersPlaceholder";
export const MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION =
  "modalUploadTiktokAllowUsersCommentOption";
export const MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION =
  "modalUploadTiktokAllowUsersDuosOption";
export const MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION =
  "modalUploadTiktokAllowUsersCutOption";

//MODAL UPLOAD INSTAGRAM
export const MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL =
  "modalUploadInstagramAriaLabel";
export const MODAL_UPLOAD_INSTAGRAM_TITLE = "modalUploadInstagramTitle";
export const MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION =
  "modalUploadInstagramVideoUploadedDescription";
export const MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL =
  "modalUploadInstagramDescriptionLabel";
export const MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER =
  "modalUploadInstagramDescriptionPlaceholder";
export const MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL =
  "modalUploadInstagramTypeLabel";
export const MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL =
  "modalUploadInstagramTypePostLabel";
export const MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL =
  "modalUploadInstagramTypeStoryLabel";
export const MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL =
  "modalUploadInstagramTypeReelLabel";

//MODAL MAIN GENERATE SOCIAL MEDIA CONTENT
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL =
  "modalMainGenerateSocialMediaContentAriaLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE =
  "modalMainGenerateSocialMediaContentTitle";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION =
  "modalMainGenerateSocialMediaContentDescription";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_LABEL =
  "modalMainGenerateSocialMediaContextLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_PLACEHOLDER =
  "modalMainGenerateSocialMediaContextPlaceholder";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WEBSITE_URL_LABEL =
  "modalMainGenerateSocialMediaWebsiteUrlLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_HAS_TEXTS_LABEL =
  "modalMainGenerateSocialMediaHasTextsLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT =
  "modalMainGenerateSocialMediaAllWithText";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT =
  "modalMainGenerateSocialMediaSomeWithText";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT =
  "modalMainGenerateSocialMediaWithoutText";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_LANGUAGE_LABEL =
  "modalMainGenerateSocialMediaContentLanguageLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TONES_LABEL =
  "modalMainGenerateSocialMediaContentTonesLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_TEXT_LABEL =
  "modalMainGenerateSocialMediaContentColorTextLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_BACKGROUND_LABEL =
  "modalMainGenerateSocialMediaContentColorBackgroundLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_HAS_TEXT_LABEL =
  "modalMainGenerateSocialMediaContentHasTextLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_FONT_FAMILY_LABEL =
  "modalMainGenerateSocialMediaContentFontFamilyLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_TEXT_LABEL =
  "modalMainGenerateSocialMediaContentAlignTextLabel";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_LOGO_LABEL =
  "modalMainGenerateSocialMediaContentAlignLogoLabel";
export const MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_TITLE =
  "modalMainSocialMediaContentGalleryTitle";
export const MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_EMPTY =
  "modalMainSocialMediaContentGalleryEmpty";
export const MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION =
  "modalMainSocialMediaContentGalleryUploadOption";
export const MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION =
  "modalMainSocialMediaContentGalleryFromCollaborationsOption";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_CONTENTS_REQUIRED =
  "modalMainGenerateSocialMediaContentDaysRequired";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULE_BUTTON =
  "modalMainGenerateSocialMediaContentScheduleButton";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULED_CONTENT =
  "modalMainGenerateSocialMediaContentScheduledContent";
export const MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALREADY_CONTENT_ON_THIS_DATE =
  "modalMainGenerateSocialMediaContentAlreadyContentOnThisDate";

//MODAL GENERATE SOCIAL MEDIA CONTENT
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL =
  "modalGenerateSocialMediaContentAriaLabel";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE =
  "modalGenerateSocialMediaContentTitle";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_LABEL =
  "modalGenerateSocialMediaContentSelectToneLabel";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_TOP_FIVE_OPTION =
  "modalGenerateSocialMediaContentSelectToneTopFiveOption";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION =
  "modalGenerateSocialMediaContentSelectToneEducationalOption";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION =
  "modalGenerateSocialMediaContentSelectToneQuotesOption";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION =
  "modalGenerateSocialMediaContentSelectToneCuriousFactsOption";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION =
  "modalGenerateSocialMediaContentSelectToneRhymesOrPoemsOption";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION =
  "modalGenerateSocialMediaContentSelectToneHumorOption";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_EMPTY_CONTENT =
  "modalGenerateSocialMediaContentEmptyContent";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TEXT_LABEL =
  "modalGenerateSocialMediaContentTextLabel";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION =
  "modalGenerateSocialMediaContentDescription";
export const MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_GENERATING_CONTENT =
  "modalGenerateSocialMediaContentGeneratingContent";

//REDEEM CODE
export const REDEEM_CODE_TITLE = "redeemCodeTitle";
export const REDEEM_CODE_DESCRIPTION = "redeemCodeDescription";
export const REDEEM_CODE_SEARCHBAR_PLACEHOLDER = "redeemCodeBarPlaceholder";
export const REDEEM_CODE_TABLE_COLUMN_CODE = "redeemCodeTableColumnCode";
export const REDEEM_CODE_TABLE_COLUMN_DATE = "redeemCodeTableColumnDate";
export const REDEEM_CODE_TABLE_COLUMN_VALUE = "redeemCodeTableColumnValue";
export const REDEEM_CODE_TABLE_COLUMN_TERMS = "redeemCodeTableColumnTerms";
export const CODE_REDEEMED = "codeRedeemed";
export const VALUE_CODE_REDEEMED = "valueCodeRedeemed";
export const VALUE_GENERAL_CODE_REDEEMED = "valueGeneralCodeRedeemed";
export const CODE_NO_EXIST = "codeNoExist";
export const CODE_NO_REDEEM_BEFORE_DATE = "codeNoRedeemBeforeDate";

//ORDERS
export const ORDERS_TITLE = "ordersTitle";
export const ORDERS_DESCRIPTION = "ordersDescription";
export const ORDERS_REMEMBER_MARK_AS_DELIVERED =
  "ordersRememberMarkAsDelivered";
export const ORDERS_SEARCHBAR_PLACEHOLDER = "ordersSearchBarPlaceholder";
export const ORDERS_TAB_TO_BE_ACCEPTED = "ordersTabToBeAccepted";
export const ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION =
  "ordersTabToBeAcceptedDescription";
export const ORDERS_TAB_IN_PREPARATION = "ordersTabInPreparation";
export const ORDERS_TAB_IN_PREPARATION_DESCRIPTION =
  "ordersTabInPreparationDescription";
export const ORDERS_TAB_TO_BE_DELIVERED = "ordersTabToBeDelivered";
export const ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION =
  "ordersTabToBeDeliveredDescription";
export const DELIVERY_INTERN = "deliveryIntern";
export const DELIVERY_INTERN_ON_THE_WAY = "deliveryInternOnTheWay";
export const DELIVERY_ON_THE_WAY = "deliveryOnTheWay";
export const ORDER_ID = "orderId";
export const ORDER_ACCEPTED = "orderAccepted";
export const ORDER_ACCEPTED_DESCRIPTION = "orderAcceptedDescription";
export const ORDER_READY = "orderReady";
export const ORDER_READY_DESCRIPTION = "orderReadyDescription";
export const ORDER_TO_BE_DELIVERED_CONFIRMATION =
  "orderToBeDeliveredConfirmation";
export const ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON =
  "orderToBeDeliveredConfirmationButton";
export const ORDER_CANCELLED_CONFIRMATION = "orderCancelledConfirmation";
export const ORDER_CANCELLED_CONFIRMATION_BUTTON =
  "orderCancelledConfirmationButton";
export const ORDER_DELIVERED = "orderDelivered";
export const ORDER_DELIVERED_DESCRIPTION = "orderDeliveredDescription";
export const DELIVERY_SELECTED = "deliverySelected";
export const DELIVERY_SELECTED_DESCRIPTION = "deliverySelectedDescription";
export const ORDER_REJECTED = "orderRejected";
export const ORDER_REJECTED_DESCRIPTION = "orderRejectedDescription";
export const ORDER_TOTAL_VALUE = "orderTotalValue";
export const ORDER_TOTAL_VALUE_DISCOUNT = "orderTotalValueDiscount";
export const ORDER_LIST_OF_PRODUCTS = "orderListOfProducts";
export const ORDER_OPTIONS = "orderOptions";
export const ORDER_TIME_PREPARATION_TITLE = "orderTimePreparationTitle";
export const MIN_VALUE_PREPARATION_ORDER = "minValuePreparationOrder";
export const MAX_VALUE_PREPARATION_ORDER = "maxValuePreparationOrder";
export const INVALID_TIME_PREPARATION = "invalidTimePreparation";
export const TIME_PREPARATION_VALUES_VALIDATION =
  "timePreparationValuesValidation";
export const GUIDE_NUMBER = "guideNumber";
export const GUIDE_NUMBER_REQUIRED = "guideNumberRequired";
export const DELIVERY_TYPE_DUPLICATE = "deliveryTypeDuplicate";
export const SELECT_DELIVERY_TYPE = "selectDeliveryType";
export const SELECT_DELIVERY_TYPE_OTHER = "selectDeliveryTypeOther";
export const DELIVERY_DATA = "deliveryData";
export const OWN_DELIVERY = "ownDelivery";

//EVENTS
export const EVENT_NO_EXIST = "eventNoExist";
export const EVENT_DISABLED = "eventDisabled";
export const EVENT_INVITATION_NO_BELONG = "eventInvitationNoBelong";
export const EVENT_HAS_FIELDS_REQUIRED = "eventHasFieldsRequired";
export const EVENT_RECURRENT_REQUIRED = "eventRecurrentRequired";

//TOP CONTENT
export const TOP_CONTENT_TITLE = "topContentTitle";
export const TOP_CONTENT_DESCRIPTION = "topContentDescription";
export const TOP_CONTENT_NOT_FOUND_IN_INTERVAL = "topContentNotFoundInInterval";

//ACCOUNT
export const ACCOUNT_TITLE = "accountTitle";
export const ACCOUNT_DESCRIPTION = "accountDescription";

//COMPANY INFORMATION
export const COMPANY_INFORMATION_TITLE = "companyInformationTitle";
export const COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE =
  "companyInformationSubcategoryDuplicate";
export const COMPANY_INFORMATION_TYPE_ID_PLACEHOLDER =
  "companyInformationTypeIdPlaceholder";
export const COMPANY_INFORMATION_ID_NUMBER_PLACEHOLDER =
  "companyInformationIdNumberPlaceholder";
export const COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH =
  "companyInformationIdNumberMinLength";
export const COMPANY_INFORMATION_ID_NUMBER_TYPE =
  "companyInformationIdNumberType";
export const COMPANY_INFORMATION_BRAND_NAME_PLACEHOLDER =
  "companyInformationBrandNamePlaceholder";
export const COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER =
  "companyInformationBusinessNamePlaceholder";
export const COMPANY_INFORMATION_ADDRESS_PLACEHOLDER =
  "companyInformationAddressPlaceholder";
export const COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER =
  "companyInformationAverageTicketPlaceholder";
export const COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE =
  "companyInformationAverageTicketMinValue";
export const COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER =
  "companyInformationSocialNetworkPlaceholder";
export const COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER =
  "companyInformationSecondSocialNetworkPlaceholder";
export const COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER =
  "companyInformationWebsiteUrlPlaceholder";
export const COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER =
  "companyInformationTrafficUrlPlaceholder";
export const COMPANY_INFORMATION_CATEGORY_PLACEHOLDER =
  "companyInformationCategoryPlaceholder";
export const COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER =
  "companyInformationSubcategoryPlaceholder";
export const COMPANY_INFORMATION_NEW_SUBCATEGORY =
  "companyInformationNewSubcategory";
export const COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER =
  "companyInformationShortDescriptionPlaceholder";
export const COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER =
  "companyInformationAdminPhonePlaceholder";
export const COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER =
  "companyInformationReservationUrlPlaceholder";
export const COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER =
  "companyInformationUploadLogoPlaceholder";
export const COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER =
  "companyInformationUploadLogoDescriptionPlaceholder";
export const COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER =
  "companyInformationUploadCoverPlaceholder";
export const COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER =
  "companyInformationUploadCoverDescriptionPlaceholder";
export const COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER =
  "companyInformationUploadRegulatoryDocumentPlaceholder";
export const COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER =
  "companyInformationUploadRegulatoryDocumentDescriptionPlaceholder";
export const COMPANY_INFORMATION_VIEW_DOCUMENT =
  "companyInformationViewDocument";
export const COMPANY_INFORMATION_GENERAL_DISCOUNT_TITLE =
  "companyInformationGeneralDiscountTitle";
export const COMPANY_INFORMATION_GENERAL_DISCOUNT_DESCRIPTION =
  "companyInformationGeneralDiscountDescription";
export const COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_1 =
  "companyInformationGeneralDiscountExplanation1";
export const COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_2 =
  "companyInformationGeneralDiscountExplanation2";
export const COMPANY_INFORMATION_GENERAL_DISCOUNT_PERCENTAGE_PLACEHOLDER =
  "companyInformationGeneralDiscountPercentagePlaceholder";
export const COMPANY_INFORMATION_GENERAL_DISCOUNT_CODE_PLACEHOLDER =
  "companyInformationGeneralDiscountCodePlaceholder";
export const COMPANY_INFORMATION_SHOP_ID_COPIED =
  "companyInformationShopIdCopied";
export const COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION =
  "companyInformationOnboardingFinishDescription";
export const COMPANY_INFORMATION_SAVED = "companyInformationSaved";

//PROFILE INFLUENCER INFORMATION
export const PROFILE_INFLUENCER_INFORMATION_SAVED =
  "profileInfluencerInformationSaved";
export const PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED =
  "profileInfluencerSavedAndGetInfluencersSuggested";
export const VIEW_SUGGESTED_CREATORS = "viewSuggestedCreators";
export const PROFILE_INFLUENCER_INFORMATION_TITLE =
  "profileInfluencerInformationTitle";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION =
  "profileInfluencerInformationCreatorDescription";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER =
  "profileInfluencerInformationCreatorCategoriesPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE =
  "profileInfluencerInformationCreatorCategoriesMinValue";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER =
  "profileInfluencerInformationCreatorCitiesPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_AUDIENCE_CITIES_PLACEHOLDER =
  "profileInfluencerInformationAudienceCitiesPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_MAX_VALUE =
  "profileInfluencerInformationCreatorCitiesMaxValue";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_PLACEHOLDER =
  "profileInfluencerInformationCreatorAudienceRangePlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_MIN_VALUE =
  "profileInfluencerInformationCreatorAudienceRangeMinValue";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_ENGAGEMENT =
  "profileInfluencerInformationCreatorAudienceEngagement";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_PLACEHOLDER =
  "profileInfluencerInformationCreatorEngagementRangePlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_MIN_VALUE =
  "profileInfluencerInformationCreatorEngagementRangeMinValue";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER =
  "profileInfluencerInformationCreatorGenderPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE =
  "profileInfluencerInformationCreatorAgeRangeMaxValue";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER =
  "profileInfluencerInformationCreatorAudienceGenderPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER =
  "profileInfluencerInformationCreatorAudienceAgeRangePlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_MAX_VALUE =
  "profileInfluencerInformationCreatorAudienceAgeRangeMaxValue";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER =
  "profileInfluencerInformationCreatorPlatformsPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_PLACEHOLDER =
  "profileInfluencerInformationCreatorAverageTicketPlaceholder";
export const PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_MIN_VALUE =
  "profileInfluencerInformationCreatorAverageTicketMinValue";
export const PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION =
  "profileInfluencerInformationReferenceProfilesDescription";
export const PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION1 =
  "profileInfluencerInformationReferenceProfilesExplanation1";
export const PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2 =
  "profileInfluencerInformationReferenceProfilesExplanation2";

//AUTOMATIC INVITES
export const AUTOMATIC_INVITES_TITLE = "automaticInvitesTitle";
export const AUTOMATIC_INVITES_ACTIVATE = "automaticInvitesActivate";
export const AUTOMATIC_INVITES_DEACTIVATE = "automaticInvitesDeactivate";
export const AUTOMATIC_INVITES_DESCRIPTION = "automaticInvitesDescription";
export const AUTOMATIC_INVITES_BUDGET_DESCRIPTION =
  "automaticInvitesBudgetDescription";
export const AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER =
  "automaticInvitesBudgetNumberInvitationsPlaceholder";
export const AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE =
  "automaticInvitesBudgetNumberInvitationsMinAndMaxValue";
export const AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION =
  "automaticInvitesBudgetNumberInvitationsRecommendation";
export const AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER =
  "automaticInvitesBudgetBudgetMonthlyPlaceholder";
export const AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE =
  "automaticInvitesBudgetBudgetMonthlyMinValue";
export const AUTOMATIC_INVITES_BUDGET_BUDGETS_DESCRIPTION =
  "automaticInvitesBudgetBudgetsDescription";
export const AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION1 =
  "automaticInvitesBudgetBudgetsExplanation1";
export const AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION2 =
  "automaticInvitesBudgetBudgetsExplanation2";
export const AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE =
  "automaticInvitesBudgetIndividualAudience";
export const AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE =
  "automaticInvitesBudgetIndividualAudienceRange";
export const AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_ENGAGEMENT_RANGE =
  "automaticInvitesBudgetIndividualEngagementRange";
export const AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE =
  "automaticInvitesBudgetIndividualBudgetAudience";
export const AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE =
  "automaticInvitesBudgetIndividualBudgetAudienceMinValue";
export const AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT =
  "automaticInvitesBudgetIndividualBudgetAudienceMinValueProduct";
export const AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION =
  "automaticInvitesOptionalDescription";
export const AUTOMATIC_INVITES_OPTIONAL_COMMENT_PLACEHOLDER =
  "automaticInvitesOptionalCommentPlaceholder";
export const AUTOMATIC_INVITES_OPTIONAL_TERMS_PLACEHOLDER =
  "automaticInvitesOptionalTermsPlaceholder";
export const AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER =
  "automaticInvitesOptionalFormatsPlaceholder";
export const AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER =
  "automaticInvitesOptionalUploadContentReferencePlaceholder";
export const AUTOMATIC_INVITES_TOOLTIP_TITLE = "automaticInvitesTooltipTitle";
export const AUTOMATIC_INVITES_TOOLTIP_DESCRIPTION =
  "automaticInvitesTooltipDescription";
export const AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_TITLE =
  "automaticInvitesTooltipNumberExchangesTitle";
export const AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_DESCRIPTION =
  "automaticInvitesTooltipNumberExchangesDescription";
export const AUTOMATIC_INVITES_TOOLTIP_BUDGETS_TITLE =
  "automaticInvitesTooltipBudgetsTitle";
export const AUTOMATIC_INVITES_TOOLTIP_BUDGETS_DESCRIPTION =
  "automaticInvitesTooltipBudgetsDescription";
export const AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_TITLE =
  "automaticInvitesTooltipBudgetMonthlyTitle";
export const AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_DESCRIPTION =
  "automaticInvitesTooltipBudgetMonthlyDescription";
export const AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_TITLE =
  "automaticInvitesTooltipAutomaticInvitesTitle";
export const AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_DESCRIPTION =
  "automaticInvitesTooltipAutomaticInvitesDescription";
export const AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_TITLE =
  "automaticInvitesTooltipPersonalizedSectionTitle";
export const AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_DESCRIPTION =
  "automaticInvitesTooltipPersonalizedSectionDescription";
export const AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION =
  "automaticInvitesTypeFormNumberInvitationsQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER =
  "automaticInvitesTypeFormNumberInvitationsPlaceholder";
export const AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION =
  "automaticInvitesTypeFormBudgetMonthlyQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER =
  "automaticInvitesTypeFormBudgetMonthlyPlaceholder";
export const AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION =
  "automaticInvitesTypeFormBudgetsQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION =
  "automaticInvitesTypeFormCommentQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_TYPE_CONTENT_QUESTION =
  "automaticInvitesTypeFormTypeContentQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION =
  "automaticInvitesTypeFormFormatsQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_TERMS_QUESTION =
  "automaticInvitesTypeFormTermsQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_TERMS_PLACEHOLDER =
  "automaticInvitesTypeFormTermsPlaceholder";
export const AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION =
  "automaticInvitesTypeFormContentReferenceQuestion";
export const AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION =
  "automaticInvitesTypeFormAutomaticInvitationsIsActiveQuestion";
export const AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED =
  "automaticInvitesNumberInvitationsRequired";
export const AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED =
  "automaticInvitesBudgetMonthlyRequired";
export const AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED =
  "automaticInvitesBudgetsValueRequired";
export const AUTOMATIC_INVITES_COMMENT_REQUIRED =
  "automaticInvitesCommentRequired";
export const AUTOMATIC_INVITES_TERMS_REQUIRED = "automaticInvitesTermsRequired";
export const AUTOMATIC_INVITES_TYPE_CONTENT_REQUIRED =
  "automaticInvitesTypeContentRequired";
export const AUTOMATIC_INVITES_FORMATS_REQUIRED =
  "automaticInvitesFormatsRequired";
export const AUTOMATIC_INVITES_CONTENT_REFERENCE_REQUIRED =
  "automaticInvitesContentReferenceRequired";

//PLATFORMS CONNECT
export const PLATFORMS_CONNECTED_TITLE = "platformsConnectedTitle";
export const PLATFORMS_CONNECTED_INSTAGRAM_FAILED =
  "platformsConnectedInstagramFailed";
export const PLATFORMS_TOKEN_EXPIRED = "platformsTokenExpired";

//SCHEDULE
export const SCHEDULE_TITLE = "scheduleTitle";
export const SCHEDULE_ECOMMERCE_ALERT_TITLE = "scheduleEcommerceAlertTitle";
export const SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION =
  "scheduleEcommerceAlertDescription";
export const SCHEDULE_ONE_DAY_WITH_TURN = "scheduleOneDayWithTurn";
export const SCHEDULE_EMPTY_TURN = "scheduleEmptyTurn";
export const SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION =
  "scheduleOpenAndCloseHourValidation";
export const SCHEDULE_MAX_CLOSE_HOUR = "scheduleMaxCloseHour";
export const SCHEDULE_TABLE_ARIA_LABEL = "scheduleTableAriaLabel";
export const SCHEDULE_TABLE_COLUMN_OPEN = "scheduleTableColumnOpen";
export const SCHEDULE_TABLE_COLUMN_CLOSE = "scheduleTableColumnClose";
export const SCHEDULE_SAVED = "scheduleSaved";

//LEGAL REPRESENTATIVE
export const LEGAL_REPRESENTATIVE_TITLE = "legalRepresentativeTitle";
export const LEGAL_REPRESENTATIVE_NAME_PLACEHOLDER = "legalRepresentativeName";
export const LEGAL_REPRESENTATIVE_LAST_NAME_PLACEHOLDER =
  "legalRepresentativeLastName";
export const LEGAL_REPRESENTATIVE_TYPE_ID_PLACEHOLDER =
  "legalRepresentativeTypeId";
export const LEGAL_REPRESENTATIVE_ID_NUMBER_PLACEHOLDER =
  "legalRepresentativeIdNumber";
export const LEGAL_REPRESENTATIVE_PHONE_PLACEHOLDER =
  "legalRepresentativePhone";
export const LEGAL_REPRESENTATIVE_SAVED = "legalRepresentativeSaved";

//PASSWORD
export const PASSWORD_TITLE = "passwordTitle";
export const PASSWORD_CURRENT_PASSWORD_PLACEHOLDER =
  "passwordCurrentPasswordPlaceholder";
export const PASSWORD_NEW_PASSWORD_PLACEHOLDER =
  "passwordNewPasswordPlaceholder";
export const PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER =
  "passwordConfirmPasswordPlaceholder";
export const PASSWORD_FORMAT_DESCRIPTION = "passwordFormatDescription";
export const PASSWORD_FORMAT_SUGGESTION1 = "passwordFormatSuggestion1";
export const PASSWORD_FORMAT_SUGGESTION2 = "passwordFormatSuggestion2";
export const PASSWORD_FORMAT_SUGGESTION3 = "passwordFormatSuggestion3";
export const PASSWORD_FORMAT_SUGGESTION4 = "passwordFormatSuggestion4";
export const PASSWORD_INSECURE = "passwordInsecure";
export const PASSWORDS_NOT_MATCH = "passwordsNotMatch";
export const PASSWORD_CURRENT_INVALID = "passwordCurrentInvalid";
export const PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS =
  "passwordAccountDisabledForMoreIntents";
export const PASSWORD_CHANGED = "passwordChanged";

//STORES
export const STORES_TITLE = "storesTitle";
export const STORES_DESCRIPTION = "storesDescription";
export const STORES_STORE_SHOPIFY_DUPLICATE = "storesStoreShopifyDuplicate";
export const STORES_STORE_WOOCOMMERCE_DUPLICATE =
  "storesStoreWoocommerceDuplicate";
export const STORES_STORE_WOOCOMMERCE_CREATED = "storesStoreWoocommerceCreated";
export const STORES_STORE_NAME_DUPLICATE = "storesStoreNameDuplicate";
export const STORES_STORE_SPECIAL_STORE_EXISTS =
  "storesStoreSpecialStoreExists";
export const STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION =
  "storesStoreOnboardingFinishedDescription";
export const STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION =
  "storesStoreOnboardingFinishedAdditionalDescription";
export const STORES_STORE_CREATED = "storesStoreCreated";
export const STORES_STORE_CREATED_DESCRIPTION = "storesStoreCreatedDescription";
export const STORES_STORE_COORDS_NOT_FOUND = "storesStoreCoordsNotFound";
export const STORES_STORE_SAVED = "storesStoreSaved";
export const STORES_STORE_NO_DELETED_LAST_STORE =
  "storesStoreNoDeletedLastStore";
export const STORES_STORE_DELETE_CONFIRMATION_TITLE =
  "storesStoreDeleteConfirmationTitle";
export const STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION =
  "storesStoreDeleteConfirmationDescription";
export const STORES_STORE_DELETED = "storesStoreDeleted";
export const STORES_TABLE_ARIAL_LABEL = "storesTableArialLabel";
export const STORES_TABLE_COLUMN_TYPE = "storesTableColumnType";
export const STORES_TABLE_COLUMN_NAME = "storesTableColumnName";
export const STORES_TABLE_COLUMN_CITY = "storesTableColumnCity";
export const STORES_TABLE_COLUMN_ADDRESS = "storesTableColumnAddress";
export const STORES_TABLE_COLUMN_EMAIL = "storesTableColumnEmail";
export const STORES_TABLE_COLUMN_PHONE = "storesTableColumnPhone";
export const STORES_TABLE_COLUMN_STATUS = "storesTableColumnStatus";
export const MODAL_STORE_ARIA_LABEL = "modalStoreAriaLabel";
export const MODAL_STORE_TITLE = "modalStoreTitle";
export const MODAL_STORE_TITLE_EDIT = "modalStoreTitleEdit";
export const MODAL_STORE_DESCRIPTION = "modalStoreDescription";
export const MODAL_STORE_DESCRIPTION_EDIT = "modalStoreDescriptionEdit";
export const MODAL_STORE_TYPE_PLACEHOLDER = "modalStoreTypePlaceholder";
export const MODAL_STORE_TYPE_REGULAR_OPTION = "modalStoreTypeRegularOption";
export const MODAL_STORE_HOW_TO_INTEGRATE_STORE =
  "modalStoreHowToIntegrateStore";
export const MODAL_STORE_NAME_PLACEHOLDER = "modalStoreNamePlaceholder";
export const MODAL_STORE_DESCRIPTION_PLACEHOLDER =
  "modalStoreDescriptionPlaceholder";
export const MODAL_STORE_ADDRESS_PLACEHOLDER = "modalStoreAddressPlaceholder";
export const MODAL_STORE_CITY_PLACEHOLDER = "modalStoreCityPlaceholder";
export const MODAL_STORE_PHONE_PLACEHOLDER = "modalStorePhonePlaceholder";
export const MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER =
  "modalStoreLinkStoreSpecialPlaceholder";
export const MODAL_STORE_NAME_SHOPIFY_PLACEHOLDER =
  "modalStoreNameShopifyPlaceholder";
export const MODAL_STORE_LOOK_NAME_SHOPIFY = "modalStoreLookNameShopify";
export const MODAL_STORE_LOOK_NAME_SHOPIFY_HELPER_TEXT =
  "modalStoreLookNameShopifyHelperText";
export const MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER =
  "modalStoreAccessTokenForBuzzlyPlaceholder";
export const MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER =
  "modalStoreDoCreateDiscountShippingPlaceholder";
export const MODAL_STORE_CITIES_PLACEHOLDER = "modalStoreCitiesPlaceholder";
export const MODAL_STORE_ORDERS_METHODS_PLACEHOLDER =
  "modalStoreOrdersMethodsPlaceholder";
export const MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION =
  "modalStoreOrdersMethodsDeliveryOption";
export const MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION =
  "modalStoreOrdersMethodsInStoreOption";
export const MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER =
  "modalStoreProductAvailabilityPlaceholder";
export const MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION =
  "modalStoreProductAvailabilityNationalOption";
export const MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION =
  "modalStoreProductAvailabilityLocalOption";
export const MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER =
  "modalStoreCoverageRadiusPlaceholder";
export const MODAL_STORE_TITLE_CREATE_INTEGRATE_SHOPIFY =
  "modalStoreTitleCreateIntegrateShopify";
export const MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_SHOPIFY =
  "modalStoreDescriptionCreateIntegrateShopify";
export const MODAL_STORE_BUTTON_CREATE_INTEGRATE_SHOPIFY =
  "modalStoreButtonCreateIntegrateShopify";
export const MODAL_STORE_DESCRIPTION_DISCONNECT_SHOPIFY =
  "modalStoreDescriptionDisconnectShopify";
export const MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE =
  "modalStoreTitleCreateIntegrateWoocommerce";
export const MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE =
  "modalStoreDescriptionCreateIntegrateWoocommerce";
export const MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE =
  "modalStoreButtonCreateIntegrateWoocommerce";
export const MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE =
  "modalStoreDescriptionDisconnectWoocommerce";

//MENU
export const MENU_TITLE = "menuTitle";
export const MENU_DESCRIPTION = "menuDescription";
export const MENU_EMPTY_STORES = "menuEmptyStores";
export const MODAL_COPY_MENU_ARIA_LABEL = "modalCopyMenuAriaLabel";
export const MODAL_COPY_MENU_TITLE = "modalCopyMenuTitle";
export const MODAL_COPY_MENU_DESCRIPTION = "modalCopyMenuDescription";
export const MODAL_COPY_MENU_SAME_STORE = "modalCopyMenuSameStore";
export const MODAL_COPY_MENU_CONFIRM_ALERT_TITLE =
  "modalCopyMenuConfirmAlertTitle";
export const MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION =
  "modalCopyMenuConfirmAlertDescription";
export const MODAL_COPY_MENU_COPIED = "modalCopyMenuCopied";
export const SELECT_CATALOG = "selectCatalog";
export const MODAL_UPLOAD_MENU_ARIA_LABEL = "modalUploadMenuAriaLabel";
export const MODAL_UPLOAD_MENU_TITLE = "modalUploadMenuTitle";
export const MODAL_UPLOAD_MENU_DESCRIPTION = "modalUploadMenuDescription";
export const MODAL_UPLOAD_MENU_FILE_PLACEHOLDER =
  "modalUploadMenuFilePlaceholder";
export const MODAL_UPLOAD_MENU_ERROR_FORMAT = "modalUploadMenuErrorFormat";
export const MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT =
  "modalUploadMenuMinPriceProduct";
export const MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE =
  "modalUploadMenuConfirmAlertTitle";
export const MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION =
  "modalUploadMenuConfirmAlertDescription";
export const MODAL_UPLOAD_MENU_UPLOADED = "modalUploadMenuUploaded";
export const MENU_ADD_CATEGORY = "menuAddCategory";
export const MENU_CATEGORY_NAME_PLACEHOLDER = "menuCategoryNamePlaceholder";
export const MENU_CATEGORY_REQUIRED = "menuCategoryRequired";
export const MENU_ADD_PRODUCT = "menuAddProduct";
export const MENU_PRODUCT_MIN_VALUE = "menuProductMinValue";
export const MENU_CREATE_PRODUCT_TITLE = "menuCreateProductTitle";
export const MENU_PRODUCT_NAME_PLACEHOLDER = "menuProductNamePlaceholder";
export const MENU_PRODUCT_DESCRIPTION_PLACEHOLDER =
  "menuProductDescriptionPlaceholder";
export const MENU_PRODUCT_PRICE_PLACEHOLDER = "menuProductPricePlaceholder";
export const MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER =
  "menuProductTypeProductPlaceholder";
export const MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION =
  "menuProductTypeProductExplanation";
export const MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE =
  "menuProductAccompanimentsTypeTitle";
export const MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION =
  "menuProductAccompanimentsTypeDescription";
export const MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER =
  "menuProductAddAccompanimentTypePlaceholder";
export const MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER =
  "menuProductAccompanimentTypeIsMandatoryPlaceholder";
export const MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER =
  "menuProductAddAccompanimentPlaceholder";
export const MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER =
  "menuProductAccompanimentTypeMinAndMaxNumber";
export const MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER =
  "menuProductAccompanimentTypeMinNumber";
export const MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER =
  "menuProductAccompanimentTypeNamePlaceholder";
export const MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER =
  "menuProductAccompanimentTypeMinOptionsPlaceholder";
export const MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER =
  "menuProductAccompanimentTypeMaxOptionsPlaceholder";
export const MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER =
  "menuProductAccompanimentNamePlaceholder";
export const MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER =
  "menuProductAccompanimentPricePlaceholder";
export const MODAL_MOVE_PRODUCT_ARIA_LABEL = "modalMoveProductAriaLabel";
export const MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE =
  "modalMoveProductCategoryObjective";
export const MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED =
  "modalMoveProductCategoryRequired";
export const MODAL_MOVE_PRODUCT_BUTTON = "modalMoveProductButton";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL =
  "modalCopyAccompanimentTypeAriaLabel";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE =
  "modalCopyAccompanimentTypeCategoryObjective";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE =
  "modalCopyAccompanimentTypeProductObjective";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE =
  "modalCopyAccompanimentTypeObjective";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER =
  "modalCopyAccompanimentTypeObjectivePlaceholder";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED =
  "modalCopyAccompanimentTypeCategoryRequired";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED =
  "modalCopyAccompanimentTypeProductRequired";
export const MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED =
  "modalCopyAccompanimentTypeRequired";
export const MENU_ONBOARDING_TEST_CATEGORY = "menuOnboardingTestCategory";
export const MENU_ONBOARDING_TEST_PRODUCT = "menuOnboardingTestProduct";
export const MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION =
  "menuOnboardingTestProductDescription";
export const MENU_ONBOARDING_FINISH_DESCRIPTION =
  "menuOnboardingFinishDescription";
export const MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION =
  "menuOnboardingFinishAdditionalDescription";
export const MENU_CATEGORY_DUPLICATE = "menuCategoryDuplicate";
export const MENU_CATEGORY_DUPLICATE_DESCRIPTION =
  "menuCategoryDuplicateDescription";
export const MENU_CATEGORY_CREATED = "menuCategoryCreated";
export const MENU_CATEGORY_SAVED = "menuCategorySaved";
export const MENU_CATEGORY_DELETE_CONFIRMATION_TITLE =
  "menuCategoryDeleteConfirmationTitle";
export const MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION =
  "menuCategoryDeleteConfirmationDescription";
export const MENU_CATEGORY_DELETING = "menuCategoryDeleting";
export const MENU_CATEGORY_DELETED = "menuCategoryDeleted";
export const MENU_PRODUCT_DUPLICATE = "menuProductDuplicate";
export const MENU_PRODUCT_DUPLICATE_DESCRIPTION =
  "menuProductDuplicateDescription";
export const MENU_PRODUCT_CREATED = "menuProductCreated";
export const MENU_PRODUCT_SAVED = "menuProductSaved";
export const MENU_PRODUCT_DELETE_CONFIRMATION_TITLE =
  "menuProductDeleteConfirmationTitle";
export const MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION =
  "menuProductDeleteConfirmationDescription";
export const MENU_PRODUCT_DELETING = "menuProductDeleting";
export const MENU_PRODUCT_DELETED = "menuProductDeleted";
export const MENU_PRODUCT_MOVE_SAME_CATEGORY = "menuProductMoveSameCategory";
export const MENU_PRODUCT_MOVED = "menuProductMoved";
export const MENU_PRODUCT_CHANGE_STATUS = "menuProductChangeStatus";
export const MENU_ACCOMPANIMENT_TYPE_DUPLICATE =
  "menuAccompanimentTypeDuplicate";
export const MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION =
  "menuAccompanimentTypeDuplicateDescription";
export const MENU_ACCOMPANIMENT_TYPE_COPIED = "menuAccompanimentTypeCopied";
export const MENU_ACCOMPANIMENT_DUPLICATED = "menuAccompanimentDuplicated";
export const MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION =
  "menuAccompanimentDuplicatedDescription";

//USERS
export const USERS_TITLE = "usersTitle";
export const USERS_DESCRIPTION = "usersDescription";
export const USERS_TABS_ARIA_LABEL = "usersTabsAriaLabel";
export const USERS_PORTAL_TAB = "usersPortalTab";
export const USERS_CONTACTS_TAB = "usersContactsTab";
export const USERS_EMAIL_DUPLICATE = "usersEmailDuplicate";
export const USERS_USER_CREATED = "usersUserCreated";
export const USERS_USER_SAVED = "usersUserSaved";
export const USERS_USER_DELETE_CONFIRMATION_TITLE =
  "usersUserDeleteConfirmationTitle";
export const USERS_USER_DELETE_CONFIRMATION_DESCRIPTION =
  "usersUserDeleteConfirmationDescription";
export const USERS_USER_DELETING = "usersUserDeleting";
export const USERS_USER_DELETED = "usersUserDeleted";
export const USERS_ONBOARDING_FINISH = "usersOnboardingFinish";
export const USERS_USER_SEARCH_BAR_PLACEHOLDER =
  "usersUserSearchBarPlaceholder";
export const USERS_TABLE_ARIA_LABEL = "usersTableAriaLabel";
export const USERS_TABLE_COLUMN_NAME = "usersTableColumnName";
export const USERS_TABLE_COLUMN_EMAIL = "usersTableColumnEmail";
export const USERS_TABLE_COLUMN_STORE = "usersTableColumnStore";
export const USERS_TABLE_COLUMN_ROLE = "usersTableColumnRole";
export const MODAL_USER_ARIA_LABEL = "modalUserAriaLabel";
export const MODAL_USER_TITLE = "modalUserTitle";
export const MODAL_USER_TITLE_EDIT = "modalUserTitleEdit";
export const MODAL_USER_DESCRIPTION = "modalUserDescription";
export const MODAL_USER_DESCRIPTION_EDIT = "modalUserDescriptionEdit";
export const MODAL_USER_NAME_PLACEHOLDER = "modalUserNamePlaceholder";
export const MODAL_USER_ROLE_PLACEHOLDER = "modalUserRolePlaceholder";
export const MODAL_USER_ROLE_OPERATOR_EXPLANATION =
  "modalUserRoleOperatorExplanation";
export const MODAL_USER_STORE_PLACEHOLDER = "modalUserStorePlaceholder";
export const MODAL_USER_PASSWORD_PLACEHOLDER = "modalUserPasswordPlaceholder";
export const MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER =
  "modalUserRepeatPasswordPlaceholder";
export const USERS_CONTACT_CREATED = "usersContactCreated";
export const USERS_CONTACT_SAVED = "usersContactSaved";
export const USERS_CONTACT_DELETE_CONFIRMATION_TITLE =
  "usersContactDeleteConfirmationTitle";
export const USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION =
  "usersContactDeleteConfirmationDescription";
export const USERS_CONTACT_DELETED = "usersContactDeleted";
export const USERS_CONTACT_SEARCH_BAR_PLACEHOLDER =
  "usersContactSearchBarPlaceholder";
export const USERS_CONTACT_TABLE_ARIA_LABEL = "usersContactTableAriaLabel";
export const USERS_CONTACT_TABLE_COLUMN_PHONE = "usersContactTableColumnPhone";
export const MODAL_CONTACT_ARIA_LABEL = "modalContactAriaLabel";
export const MODAL_CONTACT_TITLE = "modalContactTitle";
export const MODAL_CONTACT_TITLE_EDIT = "modalContactTitleEdit";
export const MODAL_CONTACT_DESCRIPTION = "modalContactDescription";
export const MODAL_CONTACT_DESCRIPTION_EDIT = "modalContactDescriptionEdit";
export const MODAL_CONTACT_PHONE_PLACEHOLDER = "modalContactPhonePlaceholder";

//BILLING AND PAYMENTS
export const BILLING_AND_PAYMENTS_TITLE = "billingAndPaymentsTitle";
export const BILLING_AND_PAYMENTS_DESCRIPTION = "billingAndPaymentsDescription";
export const BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL =
  "billingAndPaymentsTableAriaLabel";
export const BILLING_AND_PAYMENTS_TABLE_COLUMN_PAID_DATE =
  "billingAndPaymentsTableColumnPaidDate";
export const BILLING_AND_PAYMENTS_TABLE_COLUMN_DUE_DATE =
  "billingAndPaymentsTableColumnDueDate";
export const BILLING_AND_PAYMENTS_TABLE_COLUMN_VALUE =
  "billingAndPaymentsTableColumnValue";
export const BILLING_AND_PAYMENTS_TABLE_COLUMN_REASON =
  "billingAndPaymentsTableColumnReason";
export const BILLING_AND_PAYMENTS_TABLE_COLUMN_STATUS =
  "billingAndPaymentsTableColumnStatus";
export const PAYMENT_REASON_COLLABORATION = "paymentReasonCollaboration";
export const PAYMENT_REASON_APPLICANT = "paymentReasonApplicant";
export const PAYMENT_REASON_AMBASSADOR = "paymentReasonAmbassador";
export const PAYMENT_REASON_INFLUENCER = "paymentReasonInfluencer";
export const PAYMENT_REASON_INVITATION = "paymentReasonInvitation";
export const PAYMENT_REASON_CLAPP_CREDITS = "paymentReasonClappCredits";
export const PAYMENT_REASON_SUBSCRIPTION_PRO = "paymentReasonSubscriptionPro";
export const PAYMENT_REASON_PACKAGE = "paymentReasonPackage";
export const PAYMENT_REASON_PLAN = "paymentReasonPlan";
export const PAYMENT_PAID = "paymentPaid";
export const PAYMENT_PENDING = "paymentPending";
export const MODAL_INVOICE_ARIA_LABEL = "modalInvoiceAriaLabel";
export const MODAL_INVOICE_TITLE = "modalInvoiceTitle";
export const MODAL_INVOICE_DISCLAIMER = "modalInvoiceDisclaimer";
export const MODAL_INVOICE_CREDITS = "modalInvoiceCredits";
export const MODAL_INVOICE_NUMBER_CREDITS = "modalInvoiceNumberCredits";
export const MODAL_INVOICE_PLAN_ENTERPRISE_WITH_CREDITS =
  "modalInvoicePlanEnterpriseWithCredits";
export const MODAL_INVOICE_SUBSCRIPTION = "modalInvoiceSubscription";
export const MODAL_INVOICE_LICENSE = "modalInvoiceLicense";
export const MODAL_INVOICE_COLLABORATION = "modalInvoiceCollaboration";
export const MODAL_INVOICE_APPLICANT = "modalInvoiceApplicant";
export const MODAL_INVOICE_AMBASSADOR = "modalInvoiceAmbassador";
export const MODAL_INVOICE_INFLUENCER = "modalInvoiceInfluencer";
export const MODAL_INVOICE_INVITATION = "modalInvoiceInvitation";
export const MODAL_INVOICE_FEE = "modalInvoiceFee";
export const MODAL_INVOICE_IVA = "modalInvoiceIva";
export const MODAL_INVOICE_ORDER_TO_COLLECT = "modalInvoiceOrderToCollect";
export const MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID =
  "modalInvoiceApplicantCampaignPaid";
export const MODAL_PAY_INVOICE_ARIA_LABEL = "modalPayInvoiceAriaLabel";
export const MODAL_PAY_INVOICE_TITLE = "modalPayInvoiceTitle";
export const MODAL_PAY_INVOICE_WILL_PAY = "modalPayInvoiceWillPay";
export const BILLING_AND_PAYMENT_PLAN_DEACTIVATE =
  "billingAndPaymentPlanDeactivate";
export const BILLING_AND_PAYMENT_PLAN_CANCELLED =
  "billingAndPaymentPlanCancelled";
export const BILLING_AND_PAYMENT_PLAN_TRIAL = "billingAndPaymentPlanTrial";
export const BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION =
  "billingAndPaymentPlanTrialDescription";
export const BILLING_AND_PAYMENT_CHANGE_YOUR_PLAN =
  "billingAndPaymentChangeYourPlan";
export const BILLING_AND_PAYMENT_ACTIVATE_PLAN =
  "billingAndPaymentActivatePlan";
export const BILLING_AND_PAYMENT_SELECT_PLAN = "billingAndPaymentSelectPlan";
export const BILLING_AND_PAYMENT_NO_HAVE_PLAN = "billingAndPaymentNoHavePlan";
export const BILLING_AND_PAYMENT_PLAN_EXPIRED = "billingAndPaymentPlanExpired";
export const BILLING_AND_PAYMENT_PLAN_NAME = "billingAndPaymentPlanName";
export const BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE =
  "billingAndPaymentModelUsageCollaborationTitle";
export const BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST =
  "billingAndPaymentModelUsageCollaborationCost";
export const BILLING_AND_PAYMENT_MODEL_USAGE_WHAT_BENEFITS =
  "billingAndPaymentModelUsageWhatBenefits";
export const BILLING_AND_PAYMENT_MODEL_USAGE_SHOW_BENEFTIS =
  "billingAndPaymentModelUsageShowBenefits";
export const BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT1 =
  "billingAndPaymentModelUsageBenefit1";
export const BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT2 =
  "billingAndPaymentModelUsageBenefit2";
export const BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT3 =
  "billingAndPaymentModelUsageBenefit3";
export const BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT4 =
  "billingAndPaymentModelUsageBenefit4";
export const BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT5 =
  "billingAndPaymentModelUsageBenefit5";
export const BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT6 =
  "billingAndPaymentModelUsageBenefit6";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_NAME =
  "billingAndPaymentModelPackageName";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_CHANGE =
  "billingAndPaymentModelPackageChange";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_COLLECT_COST_COLLABORATION =
  "billingAndPaymentModelPackageCollectCostCollaboration";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_ADD_PAYMENT_METHOD_TO_ACTIVATE =
  "billingAndPaymentModelPackageAddPaymentMethodToActivate";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_EXPIRED =
  "billingAndPaymentModelPackageExpired";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE =
  "billingAndPaymentModelPackageNoHavePackage";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE =
  "billingAndPaymentModelPackageActivatePackage";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE =
  "billingAndPaymentModelPackageSelectPackage";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE =
  "billingAndPaymentModelPackageCancelPackage";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_WHAT_BENEFITS =
  "billingAndPaymentModelPackageWhatBenefits";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION =
  "billingAndPaymentModelPackageCostCollaboration";
export const BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_DISCLAIMER =
  "billingAndPaymentModelPackageCancelDisclaimer";
export const MODAL_SELECT_PACKAGE_ARIA_LABEL = "modalSelectPackageAriaLabel";
export const MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED =
  "modalSelectPackageAndPaymentMethodRequired";
export const MODAL_SELECT_PACKAGE_UPLOAD_GROWTH =
  "modalSelectPackageUploadGrowth";
export const MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE =
  "modalSelectPackagePlanPaidTitle";
export const MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION =
  "modalSelectPackagePlanPaidDescription";
export const MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE =
  "modalSelectPackagePricingHeroTitle";
export const MODAL_SELECT_PACKAGE_PRICING_DISCOVER_BUZZLY =
  "modalSelectPackagePricingDiscoverBuzzly";
export const MODAL_SELECT_PACKAGE_UPLOAD_GROWTH_DESCRIPTION =
  "modalSelectPackageUploadGrowthDescription";
export const MODAL_SELECT_PACKAGE_PRICING_HERO_DESCRIPTION =
  "modalSelectPackagePricingHeroDescription";
export const MODAL_SELECT_PACKAGE_VALUE_EXPRESSED =
  "modalSelectPackageValueExpressed";
export const MODAL_SELECT_PACKAGE_PRICE = "modalSelectPackagePrice";
export const MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE =
  "modalSelectPackageLicensedFeeEnterprise";
export const MODAL_SELECT_PACKAGE_CONTENT_ENTERPRISE =
  "modalSelectPackageContentEnterprise";
export const MODAL_SELECT_PACKAGE_LICENSED_FEE =
  "modalSelectPackageLicensedFee";
export const MODAL_SELECT_PACKAGE_LICENSED_PRICE =
  "modalSelectPackageLicensedPrice";
export const MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH =
  "modalSelectPackagePriceUploadGrowth";
export const MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT =
  "modalSelectPackageAccordingToTheRequirement";
export const MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME =
  "modalSelectPackageBuyPackageName";
export const MODAL_SELECT_PACKAGE_COST_CONTENT =
  "modalSelectPackageCostContent";
export const MODAL_SELECT_PACKAGE_COST_COLLABORATION =
  "modalSelectPackageCostCollaboration";
export const MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS =
  "modalSelectPackageBenefitDirectChatWithCreators";
export const MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION =
  "modalSelectPackageBenefitContentCuration";
export const MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM =
  "modalSelectPackageBenefitDirectPublicationPlatform";
export const MODAL_SELECT_PACKAGE_BENEFIT_COLLABORATE_WITH_CREATOR =
  "modalSelectPackageBenefitCollaborateWithCreator";
export const MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS =
  "modalSelectPackageBenefitLaunchCampaigns";
export const MODAL_SELECT_PACKAGE_BENEFIT_DOWNLOAD_CONTENT =
  "modalSelectPackageBenefitDownloadContent";
export const MODAL_SELECT_PACKAGE_BENEFIT_ANALYZE_METRICS =
  "modalSelectPackageBenefitAnalyzeMetrics";
export const MODAL_SELECT_PACKAGE_BENEFIT_CATALOG =
  "modalSelectPackageBenefitCatalog";
export const MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION =
  "modalSelectPackageBenefitAutomatization";
export const MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES =
  "modalSelectPackageBenefitAutomaticInvites";
export const MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS =
  "modalSelectPackageBenefitSupportTickets";
export const MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC =
  "modalSelectPackageBenefitAllBasic";
export const MODAL_SELECT_PACKAGE_BENEFIT_KAM = "modalSelectPackageBenefitKam";
export const MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT =
  "modalSelectPackageBenefitVolumeContent";
export const MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY =
  "modalSelectPackageBenefitDiscovery";
export const MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS =
  "modalSelectPackageBenefitAmbassadors";
export const MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT =
  "modalSelectPackageBenefitDirectChat";
export const MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING =
  "modalSelectPackageBenefitAssistanceMarketing";
export const MODAL_SELECT_PACKAGE_BENEFIT_ALL_GROWTH =
  "modalSelectPackageBenefitAllGrowth";
export const MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED =
  "modalSelectPackageBenefitBrandsUnlimited";
export const MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS =
  "modalSelectPackageBenefitPersonalizedIntegrations";
export const MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL =
  "modalSelectPackageBenefitImplementationsTechnological";
export const MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT =
  "modalSelectPackageBenefitPrioritizedSupport";
export const MODAL_SUBSCRIPTION_PRO_ARIA_LABEL =
  "modalSubscriptionProAriaLabel";
export const MODAL_SUBSCRIPTION_PRO_TITLE_HERO =
  "modalSubscriptionProTitleHero";
export const MODAL_SUBSCRIPTION_PRO_DESCRIPTION_HERO =
  "modalSubscriptionProDescriptionHero";
export const MODAL_SUBSCRIPTION_PRO_TITLE = "modalSubscriptionProTitle";
export const MODAL_SUBSCRIPTION_PRO_SUB_HEADER =
  "modalSubscriptionProSubHeader";
export const MODAL_SUBSCRIPTION_PRO_BENEFIT_1 = "modalSubscriptionProBenefit1";
export const MODAL_SUBSCRIPTION_PRO_BUY_BUTTON =
  "modalSubscriptionProBuyButton";

//PACKAGES
export const BASIC_PACKAGE_NAME = "basicPackageName";
export const BASIC_PACKAGE_SUB_HEADER = "basicPackageSubHeader";
export const BASIC_PACKAGE_DESCRIPTION = "basicPackageDescription";
export const PRO_PACKAGE_NAME = "proPackageName";
export const PRO_PACKAGE_SUB_HEADER = "proPackageSubHeader";
export const PRO_PACKAGE_DESCRIPTION = "proPackageDescription";
export const GROWTH_PACKAGE_NAME = "growthPackageName";
export const GROWTH_PACKAGE_SUB_HEADER = "growthPackageSubHeader";
export const GROWTH_PACKAGE_DESCRIPTION = "growthPackageDescription";
export const ENTERPRISE_PACKAGE_NAME = "enterprisePackageName";
export const ENTERPRISE_PACKAGE_SUB_HEADER = "enterprisePackageSubHeader";
export const ENTERPRISE_PACKAGE_DESCRIPTION = "enterprisePackageDescription";

//BILLING DATA
export const BILLING_DATA_TITLE = "billingDataTitle";
export const BILLING_DATA_DESCRIPTION = "billingDataDescription";
export const BILLING_DATA_SAVED = "billingDataSaved";
export const BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION =
  "billingDataPersonalInformationDescription";
export const BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER =
  "billingDataPersonalInformationTypePersonPlaceholder";
export const BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION =
  "billingDataPersonalInformationTypePersonNatureOption";
export const BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION =
  "billingDataPersonalInformationTypePersonLegalOption";
export const BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER =
  "billingDataPersonalInformationBusinessNamePlaceholder";
export const BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER =
  "billingDataPersonalInformationNamePlaceholder";
export const BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER =
  "billingDataPersonalInformationLastNamePlaceholder";
export const BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER =
  "billingDataPersonalInformationTypeIdPlaceholder";
export const BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER =
  "billingDataPersonalInformationIdNumberPlaceholder";
export const BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION =
  "billingDataContactInformationDescription";
export const BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER =
  "billingDataContactInformationEmailBillingPlaceholder";
export const BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER =
  "billingDataContactInformationAddressPlaceholder";
export const BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER =
  "billingDataContactInformationPhonePlaceholder";

export const MODAL_FREE_TRIAL_TITLE = "modalFreeTrialTitle";
export const MODAL_FREE_TRIAL_DESCRIPTION = "modalFreeTrialDescription";
export const MODAL_FREE_TRIAL_CAPTION = "modalFreeTrialCaption";
export const MODAL_FREE_TRIAL_BUTTON = "modalFreeTrialButton";
export const MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION =
  "modalFreeTrialStartAlertDescription";

export const BLOCK_PAYMENT_FAILED_TITLE = "blockPaymentFailedTitle";
export const BLOCK_TRIAL_END_TITLE = "blockTrialEndTitle";
export const BLOCK_PACKAGE_TITLE = "blockPackageTitle";
export const BLOCK_PACKAGE_DESCRIPTION = "blockPackageDescription";
export const BLOCK_PLAN_BUTTON = "blockPlanButton";
export const BLOCK_PLAN_BUTTON_ACTIVATE_PLAN = "blockPlanButtonActivatePlan";
export const BLOCK_TRIAL_ACTIVE_TITLE = "blockTrialActiveTitle";
export const BLOCK_TRIAL_ACTIVE_DESCRIPTION = "blockTrialActiveDescription";
export const BLOCK_TRIAL_ACTIVE_BUTTON = "blockTrialActiveButton";
export const BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE =
  "blockContactYourAdministratorTitle";

export const MODAL_BLOCK_LAST_INVITE_TITLE = "modalBlockLastInviteTitle";
export const MODAL_BLOCK_LAST_INVITE_DESCRIPTION =
  "modalBlockLastInviteDescription";
export const MODAL_BLOCK_LAST_INVITE_COUNT = "modalBlockLastInviteCount";
export const MODAL_BLOCK_LAST_INVITE_BUTTON = "modalBlockLastInviteButton";

export const MODAL_DISSATISFACTION_COLLABORATION_TITLE =
  "modalDissatisfactionCollaborationTitle";
export const MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION =
  "modalDissatisfactionCollaborationDescription";
export const MODAL_DISSATISFACTION_COLLABORATION_BUTTON =
  "modalDissatisfactionCollaborationButton";
export const MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT =
  "modalDissatisfactionCollaborationExplanationCredit";
export const MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED =
  "modalDissatisfactionCollaborationCreditReceived";

export const MODAL_BUY_CREDITS_ARIA_LABEL = "modalBuyCreditsAriaLabel";
export const MODAL_BUY_CREDITS_TITLE = "modalBuyCreditsTitle";
export const MODAL_BUY_CREDITS_DESCRIPTION = "modalBuyCreditsDescription";
export const MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION =
  "modalBuyCreditsCreditForCollaboration";
export const MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY =
  "modalBuyCreditsHowManyCreditsBuy";
export const MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME =
  "modalBuyCreditsRedeemedEstimatedTime";
export const MODAL_BUY_CREDITS_ADDITIONAL_CREDITS =
  "modalBuyCreditsAdditionalCredits";
export const MODAL_BUY_CREDITS_LAST_TEXT_PAY = "modalBuyCreditsLastTextPay";
export const MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE =
  "modalBuyCreditsAmountForPrice";
export const MODAL_BUY_CREDITS_GIFT_CREDITS = "modalBuyCreditsGiftCredits";
export const MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED =
  "modalBuyCreditsAndPaymentMethodRequired";
export const MODAL_BUY_CREDITS_NEGATIVE = "modalBuyCreditsNegative";
export const MODAL_BUY_CREDITS_PURCHASED = "modalBuyCreditsPurchased";
export const MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION =
  "modalBuyCreditsPurchasedDescription";

export const MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE =
  "modalOnboardingRequiredInformationTitle";
export const MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION =
  "modalOnboardingRequiredInformationDescription";
export const MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION =
  "modalOnboardingProfileInfluencerSection";
export const MODAL_ONBOARDING_STORES_SECTION = "modalOnboardingStoresSection";
export const MODAL_ONBOARDING_SCHEDULE_SECTION =
  "modalOnboardingScheduleSection";
export const MODAL_ONBOARDING_BASIC_INFORMATION_SECTION =
  "modalOnboardingBasicInformationSection";

//MODAL ADD BRAND SUPER ADMIN
export const MODAL_ADD_BRAND_SUPER_ADMIN_TITLE = "modalAddBrandSuperAdminTitle";

//LOGIN
export const FORGOT_PASSWORD = "forgotPassword";
export const NO_HAVE_ACCOUNT = "noHaveAccount";
export const REGISTER_HERE = "registerHere";
export const LOG_IN = "logIn";
export const RESET_PASSWORD_EMAIL_SENT = "resetPasswordEmailSent";

//RESET PASSWORD
export const RESET_PASSWORD = "resetPassword";
export const RESET_PASSWORD_GET_TOKEN_FAILED = "resetPasswordGetTokenFailed";
export const RESET_PASSWORD_SAVED = "resetPasswordSaved";

//REGISTER
export const REGISTER_COUNTRY_ANSWER = "registerCountryAnswer";
export const REGISTER_COUNTRY_PLACEHOLDER = "registerCountryPlaceholder";
export const REGISTER_NAME_ANSWER = "registerNameAnswer";
export const REGISTER_PHONE_ANSWER = "registerPhoneAnswer";
export const REGISTER_EMAIL_ANSWER = "registerEmailAnswer";
export const REGISTER_PASSWORD_ANSWER = "registerPasswordAnswer";
export const REGISTER_BUSINESS_NAME_ANSWER = "registerBusinessNameAnswer";
export const REGISTER_CATEGORY_ANSWER = "registerCategoryAnswer";
export const REGISTER_SOCIAL_NETWORK_ANSWER = "registerSocialNetworkAnswer";
export const REGISTER_SOCIAL_NETWORK_ANSWER_DESCRIPTION =
  "registerSocialNetworkAnswerDescription";
export const REGISTER_WEBSITE_ANSWER = "registerWebsiteAnswer";
export const REGISTER_WEBSITE_ANSWER_DESCRIPTION =
  "registerWebsiteAnswerDescription";
export const REGISTER_MONTHLY_SALES_ANSWER = "registerMonthlySalesAnswer";
export const REGISTER_BUDGET_MARKETING_ANSWER = "registerBudgetMarketingAnswer";
export const REGISTER_EMPLOYEES_ANSWER = "registerEmployeesAnswer";
export const REGISTER_PRODUCT_AVAILABILITY_ANSWER =
  "registerProductAvailabilityAnswer";
export const REGISTER_RUT_ANSWER = "registerRutAnswer";
export const REGISTER_INVIMA_ANSWER = "registerInvimaAnswer";
export const REGISTER_ICA_ANSWER = "registerIcaAnswer";
export const REGISTER_YOU_MEET_ANSWER = "registerYouMeetAnswer";
export const REGISTER_WRITE_PLACEHOLDER = "registerWritePlaceholder";
export const REGISTER_SELECT_PLACEHOLDER = "registerSelectPlaceholder";
export const REGISTER_COUNTRY_REQUIRED = "registerCountryRequired";
export const REGISTER_NAME_REQUIRED = "registerNameRequired";
export const REGISTER_PHONE_REQUIRED = "registerPhoneRequired";
export const REGISTER_PHONE_NUMERIC_REQUIRED = "registerPhoneNumericRequired";
export const REGISTER_BUSINESS_NAME_REQUIRED = "registerBusinessNameRequired";
export const REGISTER_CATEGORY_REQUIRED = "registerCategoryRequired";
export const REGISTER_SOCIAL_NETWORK_REQUIRED = "registerSocialNetworkRequired";
export const REGISTER_WEB_SITE_REQUIRED = "registerWebSiteRequired";
export const REGISTER_MONTHLY_SALES_REQUIRED = "registerMonthlySalesRequired";
export const REGISTER_BUDGET_MARKETING_REQUIRED =
  "registerBudgetMarketingRequired";
export const REGISTER_EMPLOYEES_REQUIRED = "registerEmployeesRequired";
export const REGISTER_PRODUCT_AVAILABILITY_REQUIRED =
  "registerProductAvailabilityRequired";
export const REGISTER_RUT_REQUIRED = "registerRutRequired";
export const REGISTER_INVIMA_REQUIRED = "registerInvimaRequired";
export const REGISTER_ICA_REQUIRED = "registerIcaRequired";
export const REGISTER_YOU_MEET_REQUIRED = "registerYouMeetRequired";
export const REGISTER_NO_HAVE_DOCUMENTS_ALERT_TITLE =
  "registerNoHaveDocumentsAlertTitle";
export const REGISTER_NO_HAVE_DOCUMENTS_ALERT_DESCRIPTION =
  "registerNoHaveDocumentsAlertDescription";
export const REGISTER_REGISTERED_ALERT_DESCRIPTION =
  "registerRegisteredAlertDescription";
export const PLEASE_SELECT_COUNTRY = "pleaseSelectCountry";
export const REGISTER_PROFILE_CREATOR_CITIES_ANSWER =
  "registerProfileCreatorCitiesAnswer";
export const REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER =
  "registerProfileCreatorPlatformsAnswer";
export const REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER =
  "registerProfileCreatorCategoriesAnswer";
export const REGISTER_PROFILE_CREATOR_GENDER_ANSWER =
  "registerProfileCreatorGenderAnswer";
export const REGISTER_PROFILE_CREATOR_AGE_ANSWER =
  "registerProfileCreatorAgeAnswer";
export const REGISTER_PROFILE_CREATOR_BODY_BUILD =
  "registerProfileCreatorBodyBuild";
export const REGISTER_PROFILE_CREATOR_CLOTHING_STYLE =
  "registerProfileCreatorClothingStyle";
export const REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES =
  "registerProfileCreatorDistinguishingFeatures";
export const REGISTER_PROFILE_CREATOR_CONTENT_STYLE =
  "registerProfileCreatorContentStyle";
export const REGISTER_PROFILE_CREATOR_CITIES_REQUIRED =
  "registerProfileCreatorCitiesRequired";
export const REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED =
  "registerProfileCreatorPlatformsRequired";
export const REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED =
  "registerProfileCreatorCategoriesRequired";
export const REGISTER_PROFILE_CREATOR_GENDER_REQUIRED =
  "registerProfileCreatorGenderRequired";
export const REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED =
  "registerProfileCreatorAgeRangeRequired";
export const REGISTER_PROFILE_CREATOR_BODY_BUILD_REQUIRED =
  "registerProfileCreatorBodyBuildRequired";
export const REGISTER_PROFILE_CREATOR_CLOTHING_STYLE_REQUIRED =
  "registerProfileCreatorClothingStyleRequired";
export const REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES_REQUIRED =
  "registerProfileCreatorDistinguishingFeaturesRequired";
export const REGISTER_PROFILE_CREATOR_CONTENT_STYLE_REQUIRED =
  "registerProfileCreatorContentStyleRequired";

//CHATS
export const CHAT_ERROR_SENT_MESSAGE = "chatErrorSentMessage";
export const CHAT_DO_PAYMENT = "chatDoPayment";
export const CHAT_WRITE_QUESTION_HERE = "chatWriteQuestionHere";
export const CHAT_WRITE_MESSAGE_HERE = "chatWriteMessageHere";
export const CHAT_EMAIL_NO_PERMITTED = "chatEmailNoPermitted";
export const CHAT_FIRST_MESSAGE_BOT = "chatFirstMessageBot";
export const CHAT_NO_MORE = "chatNoMore";

//NOTIFICATIONS
export const NOTIFICATIONS_TITLE = "notificationsTitle";
export const NOTIFICATIONS_MARK_READS = "notificationsMarkReads";
export const NOTIFICATIONS_EMPTY = "notificationsEmpty";
export const NOTIFICATIONS_NO_MORE = "notificationsNoMore";
export const NOTIFICATIONS_CHECK_NOTIFICATION =
  "notificationsCheckNotification";
export const ONE_SECOND_AGO = "oneSecondAgo";

//OWNER USERS
export const OWNER_USERS_TITLE = "ownerUsersTitle";
export const OWNER_USERS_SEARCH_BAR = "ownerUsersSearchBar";
export const OWNER_USERS_TABLE_ARIA_LABEL = "ownerUsersTableAriaLabel";
export const OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE =
  "ownerUsersDeleteUserConfirmationTitle";
export const OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION =
  "ownerUsersDeleteUserConfirmationDescription";
export const OWNER_USERS_DELETE_USER_CONFIRM = "ownerUsersDeleteUserConfirm";
export const OWNER_USERS_DELETE_USER_DELETING = "ownerUsersDeleteUserDeleting";
export const OWNER_USERS_DELETE_USER_DELETED = "ownerUsersDeleteUserDeleted";
export const OWNER_USERS_FAILED_DELETE_USER = "ownerUsersFailedDeleteUser";
export const OWNER_USERS_CREATED_USER = "ownerUsersCreatedUser";
export const OWNER_USERS_SAVED_USER = "ownerUsersSavedUser";
export const OWNER_USERS_MODAL_ARIA_LABEL = "ownerUsersModalAriaLabel";
export const OWNER_USERS_MODAL_TITLE = "ownerUsersModalTitle";
export const OWNER_USERS_MODAL_TITLE_EDIT = "ownerUsersModalTitleEdit";
export const OWNER_USERS_MODAL_DESCRIPTION = "ownerUsersModalDescription";
export const OWNER_USERS_MODAL_DESCRIPTION_EDIT =
  "ownerUsersModalDescriptionEdit";
export const OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE =
  "ownerUsersModalNoSelectBusinessToCreate";
export const OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES =
  "ownerUsersModalBrandNoHaveStores";

//DELIVERIES OWNER
export const DELIVERIES_OWNER_TITLE = "deliveriesOwnerTitle";
export const DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER =
  "deliveriesOwnerToBeAcceptedFilter";
export const DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER =
  "deliveriesOwnerToBeInPreparationFilter";
export const DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER =
  "deliveriesOwnerToBeDeliveredFilter";
export const DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING =
  "deliveriesOwnerDeliveryStatusAppPending";
export const DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION =
  "deliveriesOwnerDeliveryStatusInPreparation";
export const DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED =
  "deliveriesOwnerDeliveryStatusToBeDelivered";
export const DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED =
  "deliveriesOwnerDeliveryStatusDelivered";
export const DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED =
  "deliveriesOwnerDeliveryStatusCancelled";
export const DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED =
  "deliveriesOwnerDeliveryStatusRejected";
export const DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION =
  "deliveriesOwnerDeleteDeliveryConfirmationDescription";

//SHOPS
export const SHOPS_TITLE = "shopsTitle";
export const SHOPS_SEARCH_BAR_PLACEHOLDER = "shopsSearchBarPlaceholder";
export const SHOPS_TABLE_ARIA_LABEL = "shopsTableAriaLabel";
export const SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL =
  "shopsModalCreateShopAriaLabel";
export const SHOPS_MODAL_CREATE_SHOP_TITLE = "shopsModalCreateShopTitle";
export const SHOPS_MODAL_CREATE_SHOP_DESCRIPTION =
  "shopsModalCreateShopDescription";
export const SHOPS_SHOP_CREATE = "shopsShopCreate";

//SHOP DETAIL
export const SHOP_DETAIL_TITLE = "shopDetailTitle";
export const SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK =
  "shopDetailCreateEnterprisePaymentLink";
export const SHOP_DETAIL_HAS_BILLING_CYCLE = "shopDetailHasBillingCycle";
export const SHOP_DETAIL_REPORT_TITLE = "shopDetailReportTitle";
export const SHOP_DETAIL_REPORT_SELECT_DATES = "shopDetailReportSelectDates";
export const SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION =
  "shopDetailDescriptionPlanToSubscription";
export const SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE =
  "shopDetailReportDeleteInviteConfirmationTitle";
export const SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION =
  "shopDetailReportDeleteInviteConfirmationDescription";
export const SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE =
  "shopDetailReportExtendInviteConfirmationTitle";
export const SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION =
  "shopDetailReportExtendInviteConfirmationDescription";
export const SHOP_DETAIL_REPORT_EXTENDING_INVITE =
  "shopDetailReportExtendingInvite";
export const SHOP_DETAIL_REPORT_INVITATION_EXTENDED =
  "shopDetailReportInvitationExtended";

//SHOPS APPLICANTS
export const SHOPS_APPLICANTS_TITLE = "shopsApplicantsTitle";
export const SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER =
  "shopsApplicantsSearchBarPlaceholder";
export const SHOPS_APPLICANTS_TABLE_ARIA_LABEL =
  "shopsApplicantsTableAriaLabel";
export const SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_TITLE =
  "shopsApplicantsRejectShopConfirmationTitle";
export const SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_DESCRIPTION =
  "shopsApplicantsRejectShopConfirmationDescription";
export const SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_BUTTON =
  "shopsApplicantsRejectShopConfirmationButton";
export const SHOPS_APPLICANTS_REJECT_SHOP_REJECTING =
  "shopsApplicantsRejectShopRejecting";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_ARIA_LABEL =
  "shopsApplicantsModalDocumentsAriaLabel";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_TITLE =
  "shopsApplicantsModalDocumentsTitle";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_EMPTY =
  "shopsApplicantsModalDocumentsEmpty";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_RUT =
  "shopsApplicantsModalDocumentsRut";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_INVIMA =
  "shopsApplicantsModalDocumentsInvima";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_ICA =
  "shopsApplicantsModalDocumentsIca";
export const SHOPS_APPLICANTS_MODAL_DOCUMENTS_OPEN =
  "shopsApplicantsModalDocumentsOpen";
export const SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_ARIA_LABEL =
  "shopsApplicantsModalAcceptShopAriaLabel";
export const SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_TITLE =
  "shopsApplicantsModalAcceptShopTitle";
export const SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_CREATE_BRAND =
  "shopsApplicantsModalAcceptShopCreateBrand";

//SHOPS ACCEPTED
export const SHOPS_ACCEPTED_TITLE = "shopsAcceptedTitle";
export const SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED =
  "shopsAcceptedFilterTypeIsNotLogged";
export const SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED =
  "shopsAcceptedFilterTypeIsLogged";
export const SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR =
  "shopsAcceptedFilterTypePlanRegular";
export const SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE =
  "shopsAcceptedFilterTypePlanEnterprise";
export const SHOPS_ACCEPTED_TABLE_ARIA_LABEL = "shopsAcceptedTableAriaLabel";
export const SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL =
  "shopsAcceptedModalInformationAriaLabel";

//ADVERTISERS
export const ADVERTISER_SEARCH_BAR_PLACEHOLDER =
  "advertiserSearchBarPlaceholder";

//CREATORS SIGNUPS
export const REQUESTS_INFLUENCERS_ACCOUNT_TITLE =
  "requestsInfluencersAccountTitle";
export const REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER =
  "requestInfluencersSearchBarPlaceholder";
export const REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS =
  "requestInfluencersWaitingAnswerStatus";
export const REQUEST_INFLUENCERS_CODE_SENT_STATUS =
  "requestInfluencersCodeSentStatus";
export const REQUEST_INFLUENCERS_PENDING_SENT_CODE =
  "requestInfluencersPendingSentCode";

//CREATORS OWNER
export const CREATORS_OWNER_TITLE = "creatorsOwnerTitle";
export const CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER = "creatorsOwnerSearchBar";
export const CREATORS_OWNER_IS_PRE_REGISTERED_FILTER =
  "creatorsOwnerIsPreRegisteredFilter";
export const CREATORS_OWNER_WAS_REGISTERED_FILTER =
  "creatorsOwnerWasRegisteredFilter";
export const CREATORS_OWNER_CREATOR_NOT_FOUND = "creatorsOwnerCreatorNotFound";
export const CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE =
  "creatorsOwnerDeleteCreatorConfirmationTitle";
export const CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerDeleteCreatorConfirmationDescription";
export const CREATORS_OWNER_DELETING_CREATOR = "creatorsOwnerDeletingCreator";
export const CREATORS_OWNER_DELETED_CREATOR = "creatorsOwnerDeletedCreator";
export const CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE =
  "creatorsOwnerDeleteOrderConfirmationTitle";
export const CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerDeleteOrderConfirmationDescription";
export const CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE =
  "creatorsOwnerArchiveOrderConfirmationTitle";
export const CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerArchiveOrderConfirmationDescription";
export const CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE =
  "creatorsOwnerUnarchiveOrderConfirmationTitle";
export const CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerUnarchiveOrderConfirmationDescription";
export const CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE =
  "creatorsOwnerDeleteInvitationConfirmationTitle";
export const CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerDeleteInvitationConfirmationDescription";
export const CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE =
  "creatorsOwnerExtendInvitationConfirmationTitle";
export const CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerExtendInvitationConfirmationDescription";
export const CREATORS_OWNER_EXTENDING_INVITATION =
  "creatorsOwnerExtendingInvitation";
export const CREATORS_OWNER_INVITATION_EXTENDED =
  "creatorsOwnerInvitationExtended";
export const CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE =
  "creatorsOwnerCreateOrderFromInviteConfirmationTitle";
export const CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION =
  "creatorsOwnerCreateOrderFromInviteConfirmationDescription";
export const CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON =
  "creatorsOwnerCreateOrderFromInviteConfirmationButton";
export const CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING =
  "creatorsOwnerCreateOrderFromInviteCreating";
export const CREATORS_OWNER_REPORT_EMPTY_INVITATIONS =
  "creatorsOwnerReportEmptyInvitations";
export const CREATORS_OWNER_REPORT_DATE_REDEMPTION =
  "creatorsOwnerReportDateRedemption";
export const CREATORS_OWNER_REPORT_DELIVERIES = "creatorsOwnerReportDeliveries";
export const CREATORS_OWNER_REPORT_DELIVERIES_EMPTY =
  "creatorsOwnerReportDeliveriesEmpty";
export const CREATORS_OWNER_REPORT_WAS_ACCEPTED =
  "creatorsOwnerReportWasAccepted";
export const CREATORS_OWNER_REPORT_IS_DELIVERY =
  "creatorsOwnerReportIsDelivery";
export const CREATORS_OWNER_REPORT_ORDERS_EMPTY =
  "creatorsOwnerReportOrdersEmpty";
export const CREATORS_OWNER_REPORT_ORDERS_ARCHIVED =
  "creatorsOwnerReportOrdersArchived";
export const CREATORS_OWNER_REPORT_ORDERS_ARCHIVED_EMPTY =
  "creatorsOwnerReportOrdersArchivedEmpty";
export const CREATORS_OWNER_REPORT_WAS_ARCHIVED =
  "creatorsOwnerReportWasArchived";
export const CREATORS_OWNER_REPORT_TRANSACTIONS =
  "creatorsOwnerReportTransactions";
export const CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY =
  "creatorsOwnerReportTransactionsEmpty";
export const MODAL_EDIT_CREATOR_OWNER_TITLE = "modalEditCreatorOwnerTitle";
export const MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR =
  "modalEditCreatorOwnerHiddenCreator";
export const MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR =
  "modalEditCreatorOwnerShowCreator";
export const MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR =
  "modalEditCreatorOwnerDisableCreator";
export const MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR =
  "modalEditCreatorOwnerEnableCreator";
export const MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES =
  "modalEditCreatorOwnerSelectThreeCategories";
export const MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL =
  "modalEditCreatorOwnerMainAccountNameLabel";
export const MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL =
  "modalEditCreatorOwnerCategoriesLabel";
export const MODAL_DISABLED_CREATOR_OWNER_TITLE =
  "modalDisabledCreatorOwnerTitle";

//TRANSACTION
export const TRANSACTION_CONCEPT_LABEL = "transactionConceptLabel";
export const TRANSACTION_TYPE = "transactionType";
export const TRANSACTION_TYPE_CREDIT = "transactionTypeCredit";
export const TRANSACTION_TYPE_DEBIT = "transactionTypeDebit";
export const TRANSACTION_STATUS = "transactionStatus";
export const TRANSACTION_STATUS_PENDING = "transactionStatusPending";
export const TRANSACTION_STATUS_PENDING_RELEASE =
  "transactionStatusPendingRelease";
export const TRANSACTION_STATUS_IN_PROCESS_RELEASE =
  "transactionStatusInProcessRelease";
export const TRANSACTION_STATUS_RELEASED = "transactionStatusReleased";
export const TRANSACTION_STATUS_PENDING_SENT = "transactionStatusPendingSent";
export const TRANSACTION_IN_TRANSIT = "transactionInTransit";
export const TRANSACTION_STATUS_PAID = "transactionStatusPaid";
export const TRANSACTION_WAS_PAID = "transactionWasPaid";
export const MODAL_CREATE_TRANSACTION = "modalCreateTransaction";
export const MODAL_CREATE_TRANSACTION_AMOUNT_LABEL =
  "modalCreateTransactionAmountLabel";
export const MODAL_CREATE_TRANSACTION_CONCEPT_LABEL =
  "modalCreateTransactionConceptLabel";
export const MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR =
  "modalCreateTransactionConceptAmbassador";
export const MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT =
  "modalCreateTransactionConceptApplicant";
export const MODAL_CREATE_TRANSACTION_CONCEPT_ORDER =
  "modalCreateTransactionConceptOrder";
export const MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL =
  "modalCreateTransactionConceptReferral";
export const MODAL_CREATE_TRANSACTION_CONCEPT_ID =
  "modalCreateTransactionConceptId";

//CONTENT
export const CONTENT_TITLE = "contentTitle";

//CONTENT APPLICATION
export const CONTENT_APPLICATION_TITLE = "contentApplicationTitle";
export const CONTENT_APPLICATION_ACCEPT_TITLE = "contentApplicationAcceptTitle";
export const CONTENT_APPLICATION_ACCEPT_DESCRIPTION =
  "contentApplicationAcceptDescription";
export const CONTENT_APPLICATION_REJECT_TITLE = "contentApplicationRejectTitle";
export const CONTENT_APPLICATION_REJECT_DESCRIPTION =
  "contentApplicationRejectDescription";

//REPORTS
export const REPORTS_TITLE = "reportsTitle";
export const REPORTS_DESCRIPTION = "reportsDescription";
export const REPORTS_ERROR_GENERATING = "reportsErrorGenerating";
export const REPORTS_INVITATIONS_ACTIVE = "reportsInvitationsActive";
export const REPORTS_INVITATIONS_INACTIVE = "reportsInvitationsInactive";
export const REPORTS_REQUESTS_ACTIVE = "reportsRequestsActive";
export const REPORTS_REQUESTS_INACTIVE = "reportsRequestsInactive";
export const REPORTS_TYPE_USERS = "reportsTypeUsers";
export const REPORTS_NUMBER_USERS = "reportsNumberUsers";
export const REPORTS_TYPE_ORDER = "reportsTypeOrder";
export const REPORTS_NUMBER_ORDER = "reportsNumberOrder";
export const REPORTS_MODAL_DESCRIPTION = "reportsModalDescription";
export const REPORTS_MODAL_ONLY_BRANDS_ACTIVE = "reportsModalOnlyBrandsActive";
export const REPORTS_MODAL_TYPE_REPORT_LABEL = "reportsModalTypeReportLabel";
export const REPORTS_MODAL_TYPE_REPORT_BRANDS = "reportsModalTypeReportBrands";
export const REPORTS_MODAL_TYPE_REPORT_CREATORS =
  "reportsModalTypeReportCreators";
export const REPORTS_MODAL_TYPE_REPORT_ORDERS = "reportsModalTypeReportOrders";
export const REPORTS_MODAL_TYPE_REPORT_INVITATIONS =
  "reportsModalTypeReportInvitations";

//SHOPIFY TERMS
export const INTEGRATIONS_TERMS_TITLE = "shopifyTermsTitle";
export const INTEGRATIONS_TERMS_DESCRIPTION = "shopifyTermsDescription";
export const INTEGRATIONS_TERMS_CONTENT = "shopifyTermsContent";

//AUTH TIK TOK
export const AUTH_TIKTOK_VERIFICATION_LOADING = "authTikTokVerificationLoading";
export const AUTH_TIKTOK_VERIFICATION_SUCCESS = "authTikTokVerificationSuccess";
export const AUTH_TIKTOK_VERIFICATION_FAILED = "authTikTokVerificationFailed";

//COLUMN NAMES
export const COLUMN_NAME_SHOP_ID = "columnNameShopID";
export const COLUMN_NAME_SHOP = "columnNameShop";
export const COLUMN_NAME_BRAND = "columnNameBrand";
export const COLUMN_NAME_CATEGORY = "columnNameCategory";
export const COLUMN_NAME_IS_LOGGED = "columnNameIsLogged";
export const COLUMN_NAME_IS_ACTIVE = "columnNameIsActive";
export const COLUMN_NAME_IS_PLAN_ACTIVE = "columnNameIsPlanActive";
export const COLUMN_NAME_PACKAGE = "columnNamePlan";
export const COLUMN_NAME_EMAIL = "columnNameEmail";
export const COLUMN_NAME_NAME = "columnNameName";
export const COLUMN_NAME_PHONE = "columnNamePhone";
export const COLUMN_NAME_STAFF = "columnNameStaff";
export const COLUMN_NAME_ACCEPTED = "columnNameAcceptation";
export const COLUMN_NAME_MONTHLY_SALES = "columnNameMonthlySales";
export const COLUMN_NAME_BUDGET_MARKETING = "columnNameBudgetMarketing";
export const COLUMN_NAME_EMPLOYEES = "columnNameEmployees";
export const COLUMN_NAME_PRODUCT_AVAILABILITY = "columnNameProductAvailability";
export const COLUMN_NAME_SOCIAL_NETWORK = "columnNameSocialNetwork";
export const COLUMN_NAME_WEB_SITE = "columnNameWebSite";
export const COLUMN_NAME_YOU_MEET = "columnNameYouMeet";
export const COLUMN_NAME_DATE = "columnNameDate";
export const COLUMN_NAME_PLAN = "columnNamePlan";
export const COLUMN_NAME_PAYMENT_METHOD = "columnNamePaymentMethod";
export const COLUMN_NAME_COST_COLLABORATION = "columnNameCostCollaboration";
export const COLUMN_NAME_CREDITS = "columnNameCredits";
export const COLUMN_NAME_COUNTRY = "columnNameCountry";
export const COLUMN_NAME_CREATOR_NAME = "columnNameCreatorName";
export const COLUMN_NAME_CREATOR_PLATFORM = "columnNameCreatorPlatform";
export const COLUMN_NAME_CITY = "columnNameCity";
export const COLUMN_NAME_AUDIENCE = "columnNameAudience";
export const COLUMN_NAME_ENGAGEMENT = "columnNameEngagement";
export const COLUMN_NAME_CATEGORIES = "columnNameCategories";
export const COLUMN_NAME_SCORE = "columnNameScore";
export const COLUMN_NAME_ACTIVITY = "columnNameActivity";
export const COLUMN_NAME_ACTIONS = "columnNameActions";
export const COLUMN_NAME_ACTION = "columnNameAction";
export const COLUMN_NAME_INVITATION = "columnNameInvitation";
export const COLUMN_NAME_CODE_INVITATION = "columnNameCodeInvitation";
export const COLUMN_NAME_VALUE_INVITATION = "columnNameValueInvitation";
export const COLUMN_NAME_USE_INVITATION = "columnNameUseInvitation";
export const COLUMN_NAME_EXPIRATION_INVITATION =
  "columnNameExpirationInvitation";
export const COLUMN_NAME_TYPE_INVITATION = "columnNameTypeInvitation";
export const COLUMN_NAME_REJECTED_REASON_INVITATION =
  "columnNameRejectedReasonInvitation";
export const COLUMN_NAME_STATUS = "columnNameStatus";
export const COLUMN_NAME_REDEEMED_DATE = "columnNameRedeemedDate";
export const COLUMN_NAME_TERMS = "columnNameTerms";
export const COLUMN_NAME_TYPE = "columnNameType";
export const COLUMN_NAME_ADDRESS = "columnNameAddress";
export const COLUMN_NAME_STORE = "columnNameStore";
export const COLUMN_NAME_ROLE = "columnNameRole";
export const COLUMN_NAME_PAID_DATE = "columnNamePaidDate";
export const COLUMN_NAME_DUE_DATE = "columnNameDueDate";
export const COLUMN_NAME_REASON = "columnNameReason";
export const COLUMN_NAME_VALUE = "columnNameValue";
export const COLUMN_NAME_ID = "columnNameID";
export const COLUMN_NAME_CREATOR = "columnNameCreator";
export const COLUMN_NAME_CREATED_DATE = "columnNameCreatedDate";
export const COLUMN_NAME_ACCEPTED_DATE = "columnNameAcceptedDate";
export const COLUMN_NAME_DETAILS = "columnNameDetails";
export const COLUMN_NAME_DELIVERY = "columnNameDelivery";
export const COLUMN_NAME_CODE_VERIFICATION = "columnNameCodeVerification";
export const COLUMN_NAME_ACCOUNT_NAME = "columnNameAccountName";
export const COLUMN_NAME_IS_HIDDEN = "columnNameIsHidden";
export const COLUMN_NAME_COMMENT = "columnNameComment";
export const COLUMN_NAME_URLS = "columnNameURLs";
export const COLUMN_NAME_IS_PRE_REGISTERED = "columnNameIsPreRegistered";
export const COLUMN_NAME_WAS_PRE_REGISTERED = "columnNameWasPreRegistered";

//ALERTS
export const ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED =
  "alertActivateShopFieldsRequired";
export const ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON =
  "alertActivateShopFieldsRequiredFixButton";
export const ALERT_ACTIVATE_SHOP_BY_OWNER_FIELDS_REQUIRED =
  "alertActivateShopByOwnerFieldsRequired";
export const REMINDER_ACTIVATE_SHOP = "reminderActivateShop";
export const ALERT_INACTIVE_SHOP_TITLE = "alertInactiveShopTitle";
export const ALERT_INACTIVE_SHOP_DESCRIPTION = "alertInactiveShopDescription";
export const ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION =
  "alertInactiveShopChatDescription";
export const ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION =
  "alertOperatorActionUnauthorizedDescription";
export const ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION =
  "alertStoresRequiredForExchange";
export const ALERT_DISCARD_INFLUENCER_DEFINITIVELY_TITTLE =
  "alertDiscardInfluencerDefinitivelyTittle";
export const ALERT_DISCARD_INFLUENCER_DEFINITIVELY_DESCRIPTION =
  "alertDiscardInfluencerDefinitivelyDescription";
export const ALERT_DISCARD_INFLUENCER_DEFINITIVELY_BUTTON =
  "alertDiscardInfluencerDefinitivelyButton";
export const ALERT_CREATE_SECOND_ADS_CAMPAIGN = "alertCreateSecondAdsCampaign";
export const ALERT_CREATE_SECOND_ADS_CAMPAIGN_DESCRIPTION =
  "alertCreateSecondAdsCampaignDescription";
export const ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM =
  "alertRememberIfRechargeAccountPlatform";
export const ALERT_DESCRIPTION_DISCONNECT_FACEBOOK =
  "alertDescriptionDisconnectFacebook";
export const ALERT_DESCRIPTION_DISCONNECT_TIKTOK =
  "alertDescriptionDisconnectTikTok";
export const ALERT_DESCRIPTION_DISCONNECT_TIKTOK_BUSINESS =
  "alertDescriptionDisconnectTikTokBusiness";
export const ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE =
  "alertAdsCampaignBudgetConfirmTitle";
export const ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION =
  "alertAdsCampaignBudgetConfirmDescription";
export const ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN =
  "alertInfluencerAlreadyInvitedCampaign";

//OPTIONS UGC FORMATS
export const OPTION_UGC_FORMAT_GRWM = "optionUGCFormatGRWM";
export const OPTION_UGC_FORMAT_GRWM_EXPLANATION =
  "optionUGCFormatGRWMExplanation";
export const OPTION_UGC_FORMAT_OODT = "optionUGCFormatOODT";
export const OPTION_UGC_FORMAT_OODT_EXPLANATION =
  "optionUGCFormatOODTExplanation";
export const OPTION_UGC_FORMAT_HAULS = "optionUGCFormatHAULS";
export const OPTION_UGC_FORMAT_HAULS_EXPLANATION =
  "optionUGCFormatHAULSExplanation";
export const OPTION_UGC_FORMAT_DIYS = "optionUGCFormatDIYS";
export const OPTION_UGC_FORMAT_DIYS_EXPLANATION =
  "optionUGCFormatDIYSExplanation";
export const OPTION_UGC_FORMAT_UNBOXING = "optionUGCFormatUnboxing";
export const OPTION_UGC_FORMAT_UNBOXING_EXPLANATION =
  "optionUGCFormatUnboxingExplanation";
export const OPTION_UGC_FORMAT_CHALLENGES = "optionUGCFormatChallenges";
export const OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION =
  "optionUGCFormatChallengesExplanation";
export const OPTION_UGC_FORMAT_REVIEWS = "optionUGCFormatReviews";
export const OPTION_UGC_FORMAT_REVIEWS_EXPLANATION =
  "optionUGCFormatReviewsExplanation";
export const OPTION_UGC_FORMAT_VLOGS = "optionUGCFormatVlogs";
export const OPTION_UGC_FORMAT_VLOGS_EXPLANATION =
  "optionUGCFormatVlogsExplanation";
export const OPTION_UGC_FORMAT_QA = "optionUGCFormatQA";
export const OPTION_UGC_FORMAT_QA_EXPLANATION = "optionUGCFormatQAExplanation";
export const OPTION_UGC_FORMAT_STORYTIME = "optionUGCFormatStorytime";
export const OPTION_UGC_FORMAT_STORYTIME_EXPLANATION =
  "optionUGCFormatStorytimeExplanation";
export const OPTION_UGC_FORMAT_ROOM_TOURS = "optionUGCFormatRoomTours";
export const OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION =
  "optionUGCFormatRoomToursExplanation";
export const OPTION_UGC_FORMAT_WHATS_IN_MY_BAG = "optionUGCFormatWhatsInMyBag";
export const OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION =
  "optionUGCFormatWhatsInMyBagExplanation";
export const OPTION_UGC_FORMAT_COOKING = "optionUGCFormatCooking";
export const OPTION_UGC_FORMAT_COOKING_EXPLANATION =
  "optionUGCFormatCookingExplanation";
export const OPTION_UGC_FORMAT_FITNESS_ROUTINES =
  "optionUGCFormatFitnessRoutines";
export const OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION =
  "optionUGCFormatFitnessRoutinesExplanation";
export const OPTION_UGC_FORMAT_ASMR = "optionUGCFormatASMR";
export const OPTION_UGC_FORMAT_ASMR_EXPLANATION =
  "optionUGCFormatASMTExplanation";
export const OPTION_UGC_FORMAT_PLAN_WITH_ME = "optionUGCFormatPlanWithMe";
export const OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION =
  "optionUGCFormatPlanWithMeExplanation";
export const OPTION_UGC_FORMAT_MUKBANG = "optionUGCFormatMukbang";
export const OPTION_UGC_FORMAT_MUKBANG_EXPLANATION =
  "optionUGCFormatMukbangExplanation";
export const OPTION_UGC_FORMAT_DAY_IN_THE_LIFE = "optionUGCFormatDayInTheLife";
export const OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION =
  "optionUGCFormatDayInTheLifeExplanation";
export const OPTION_UGC_FORMAT_BEHIND_THE_SCENES =
  "optionUGCFormatBehindTheScenes";
export const OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION =
  "optionUGCFormatBehindTheScenesExplanation";
export const OPTION_UGC_FORMAT_TRAVEL_DIARIES = "optionUGCFormatTravelDiaries";
export const OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION =
  "optionUGCFormatTravelDiariesExplanation";

//ERRORS
export const SOMETHING_ERROR_GENERATING_STATISTICS =
  "somethingErrorGeneratingStatistics";
export const PROFILE_INFLUENCER_INCOMPLETE = "profileInfluencerIncomplete";
export const AUTH_SENT_EMAIL_RESET_PASSWORD = "authSentEmailResetPassword";
export const ACCOUNT_ROLE_INVALID = "accountRoleInvalid";
export const ERROR_INTERNAL = "errorInternal";
export const AUTH_INVALID_PASSWORD = "authInvalidPassword";
export const AUTH_WRONG_PASSWORD = "authWrongPassword";
export const AUTH_MANY_REQUESTS = "authManyRequests";
export const AUTH_USER_NOT_FOUND = "authUserNotFound";
export const AUTH_EMAIL_ALREADY_EXISTS = "authEmailAlreadyExists";
export const AUTH_INVALID_VERIFICATION_CODE = "authInvalidVerificationCode";
export const REGISTER_OTP_ANSWER = "registerOTPAnswer";
export const STRIPE_CARD_DECLINED = "authCardDeclined";
export const STRIPE_EXPIRED_CARD = "authExpiredCard";
export const STRIPE_INCORRECT_CVC = "authIncorrectCvc";
export const STRIPE_PROCESSING_ERROR = "authProcessingError";
export const STRIPE_INCORRECT_NUMBER = "authIncorrectNumber";
export const STRIPE_GENERIC_DECLINE = "authGenericDecline";
export const STRIPE_INSUFFICIENT_FUNDS = "authInsufficientFunds";
export const STRIPE_LOST_CARD = "authLostCard";
export const STRIPE_STOLEN_CARD = "authStolenCard";
export const STRIPE_PROFILE_NOT_FOUND = "authProfileNotFound";
export const STRIPE_INTENT_AUTH_FAILURE = "authIntentAuthFailure";
export const NIT_VALID = "nitValid";
export const BUSINESS_NAME_VALID = "businessNameValid";
export const BRAND_NAME_VALID = "brandNameValid";
export const ADDRESS_VALID = "addressValid";
export const CATEGORY_VALID = "categoryValid";
export const SUBCATEGORIES_VALID = "subcategoriesValid";
export const SHORT_DESCRIPTION_VALID = "shortDescriptionValid";
export const ORDERS_METHODS_VALID = "ordersMethodsValid";
export const LOGO_IMG_VALID = "logoImgValid";
export const COVER_IMG_VALID = "coverImgValid";
export const SCHEDULE_VALID = "scheduleValid";
export const STORES_VALID = "storesValid";
export const IS_PLAN_ACTIVE_VALID = "isPlanActiveValid";
export const MENUS_VALID = "menusValid";
export const DEFAULT_PAYMENT_METHOD_VALID = "defaultPaymentMethodValid";
export const HAS_DEBT_VALID = "hasDebtValid";
export const SUBSCRIPTION_PLAN_VALID = "subscriptionPlanValid";
export const PROFILE_INFLUENCER_VALID = "profileInfluencerValid";
export const AUTOMATIC_INVITATIONS_VALID = "automaticInvitationsValid";
export const SHOP_ID_USER_ID_NOT_SENT = "shopIdUserIdNotSent";
export const MENU_HAS_ELEMENTS_CREATED = "menuHasElementsCreated";
export const INVOICE_CREATED_FOR_RETRY_PAYMENT =
  "invoiceCreatedForRetryPayment";
export const OPERATOR_USER_STORE = "operatorUserStore";
export const STORE_NO_HAS_MENU = "storeNoHasMenu";
export const STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT =
  "storeMenuNoHasCategoryAndProduct";
export const STORE_COVERAGE_RADIUS_REQUIRED = "storeCoverageRadiusRequired";
export const MISSING_PARAMETERS = "missingParameters";
export const PASSWORD_INVALID = "passwordInvalid";
export const USER_ID_REQUIRED = "userIDRequired";
export const SHOP_ID_REQUIRED = "shopIDRequired";
export const LAST_USER_NOT_DELETED = "lastUserNotDeleted";
export const PLAN_TRIAL_USER_USED = "planTrialUserUsed";
export const ORDERS_NOT_FOUND_IN_INTERVAL = "ordersNotFoundInInterval";
export const TYPE_REPORT_NOT_FOUND = "typeReportNotFound";
export const SHOP_NOT_FOUND = "shopNotFound";
export const PAYMENT_MODEL_INVALID = "paymentModelInvalid";
export const SHOP_NOT_HAS_SOCIAL_NETWORK = "shopNotHasSocialNetwork";
export const NO_VALID_PLATFORM_FOUND = "noValidPlatformFound";
export const GETTING_STATISTICS = "gettingStatistics";
export const SHOP_BUSINESS_NAME_NOT_FOUND = "shopBusinessNameNotFound";
export const INFLUENCERS_SUGGESTED_NOT_FOUND = "influencersSuggestedNotFound";
export const REFERENCE_PROFILES_REQUIRED = "referenceProfilesRequired";
export const INFLUENCERS_SIMILAR_NOT_FOUND = "influencersSimilarNotFound";
export const DECRYPTING_PASSWORD = "decryptingPassword";
export const USER_ID_AND_PAYMENT_ID = "userIDAndPaymentID";
export const MESSAGES_REQUIRED = "messagesRequired";
export const PLAN_IS_NO_ACTIVE = "planIsNoActive";
export const USER_BANNED = "userBanned";
export const GETTING_LOCALES = "gettingLocales";
export const GETTING_PROFILE = "gettingProfile";
export const PRODUCTS_NOT_SENT = "productsNotSent";
export const INVITATION_NOT_FOUND = "invitationNotFound";
export const TOTAL_PRICE_EXCEEDED_VALUE_INVITATION =
  "totalPriceExceededValueInvitation";
export const ACCOUNT_NOT_FOUND = "accountNotFound";
export const COLLECTING_INFORMATION_RETRY_AFTER =
  "collectingInformationRetryAfter";
export const ERROR_OCURRED = "errorOcurred";
export const PLATFORM_NOT_SENT = "platformNotSent";
export const VERIFICATION_CODE_NOT_FOUND = "verificationCodeNotFound";
export const ORDER_NOT_FOUND = "orderNotFound";
export const INVITE_ID_NO_SENT = "inviteIDNoSent";
export const INVITE_NOT_FOUND = "inviteNotFound";
export const INVITE_CLOUD_TASK_ID_NOT_FOUND = "inviteCloudTaskIDNotFound";
export const TYPE_OR_PATH_NOT_SENT = "typeOrPathNotSent";
export const ID_OR_PATH_NOT_SENT = "idOrPathNotSent";
export const IDS_NOT_SENT = "idsNotSent";
export const ANALYTIC_URL_NOT_SENT = "analyticUrlNotSent";
export const ANALYTIC_URL_INVALID = "analyticUrlInvalid";
export const EXTRACTING_ANALYTICS = "extractingAnalytics";
export const CREDENTIALS_INVALID = "credentialsInvalid";
export const COLLABORATION_PRICE_NOT_BE_NEGATIVE =
  "collaborationPriceNotBeNegative";
export const CREDITS_NOT_BE_NEGATIVE = "creditsNotBeNegative";
export const PACKAGES_PRICES_NOT_BE_NEGATIVE = "packagesPricesNotBeNegative";
export const ROL_REQUIRED = "rolRequired";
export const SHOP_ID_AND_STORE_ID_REQUIRED = "shopIDAndStoreIDRequired";
export const PASSWORD_MUST_BE_6_CHARACTERS = "passwordMustBe6Characters";
export const ALL_USERS_NOT_DELETE = "allUsersNotDelete";
export const SHOP_APPLICANT_NOT_FOUND = "shopApplicantNotFound";
export const USER_PASSWORD_NOT_FOUND = "userPasswordNotFound";
export const PLAN_NOT_FOUND = "planNotFound";
export const SUBSCRIPTION_NOT_CREATED = "subscriptionNotCreated";
export const ID_SUBSCRIPTION_NOT_FOUND = "idSubscriptionNotFound";
export const SUBSCRIPTION_REACTIVATE = "subscriptionReactivate";
export const CUSTOMER_ID_NOT_FOUND = "customerIDNotFound";
export const APPLICANT_NOT_FOUND = "applicantNotFound";
export const SHOP_COUNTRY_NOT_FOUND = "shopCountryNotFound";
export const FINAL_PRICE_NOT_FOUND = "finalPriceNotFound";
export const PAYMENT_FAILED = "paymentFailed";
export const CREDITS_MUST_BE_GREATER_THAN_ZERO = "creditsMustBeGreaterThanZero";
export const AMBASSADOR_NOT_FOUND = "ambassadorNotFound";
export const CSRF_INVALID = "csrfInvalid";
export const INTEGRATION_INFORMATION_NOT_FOUND =
  "integrationInformationNotFound";
export const GETTING_VIDEO = "gettingVideo";
export const UPLOAD_URL_NOT_FOUND = "uploadUrlNotFound";
export const UPLOADING_VIDEO = "uploadingVideo";
export const STORE_NOT_FOUND = "storeNotFound";
export const NAME_REQUIRED = "nameRequired";
export const PRICE_REQUIRED = "priceRequired";
export const CURRENCY_REQUIRED = "currencyRequired";
export const RECURRING_REQUIRED = "recurringRequired";
export const CREATING_PRODUCT = "creatingProduct";
export const CREATING_PRICE = "creatingPrice";
export const CREATING_PAYMENT_LINK = "creatingPaymentLink";
export const GETTING_PAYMENT_LINK = "gettingPaymentLink";
export const TOKEN_INVALID = "tokenInvalid";
export const TOKEN_EXPIRED = "tokenExpired";
export const IG_ACCOUNT_NOT_FOUND = "igAccountNotFound";
export const IG_DATA_NOT_FOUND = "igDataNotFound";
export const REVIEW_NOT_FOUND = "reviewNotFound";
export const REVIEW_IS_NOT_ONE_START = "reviewIsNotOneStart";
export const DISSATISFACTION_CREDIT_ALREADY_EXISTS =
  "dissatisfactionCreditAlreadyExists";
export const META_INTEGRATION_UNLINKED_PAGES_ERROR =
  "metaIntegrationUnlinkedPagesError";
export const PAYMENT_METHOD_NOT_FOUND = "paymentMethodNotFound";
export const HAS_PENDING_PAYMENTS = "hasPendingPayments";
export const NO_IMAGES_FOUND_ON_PAGE = "noImagesFoundOnPage";
export const NO_CREATE_DISCOUNT_SHOPIFY = "noCreateDiscountShopify";
export const NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY =
  "noCreateWebhookOrderCreatedShopify";
export const NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY =
  "noCreateWebhookFulfillCreatedShopify";
export const NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY =
  "noCreateWebhookFulfillUpdatedShopify";
export const GETTING_INFORMATION_FROM_SHOPIFY_URL =
  "gettingInformationFromShopifyURL";
export const RATE_DAILY_LIMIT_AI = "rateDailyLimitAI";
export const RATE_DAILY_LIMIT_AI_CHAT = "rateDailyLimitAIChat";
export const PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META =
  "permissionsOrConfigurationAccountChangeMeta";
export const ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS =
  "accountIsNotTypeBusinessOrNoAcceptedAllPermissions";
export const FACEBOOK_PAGE_ID_NOT_FOUND = "facebookPageIDNotFound";
export const META_BUSINESS_ACCOUNT_NOT_FOUND = "metaBusinessAccountNotFound";
export const IG_BUSINESS_ACCOUNT_NOT_FOUND = "igBusinessAccountNotFound";
export const ACCOUNT_TIKTOK_AUTH_NOT_MATCH = "accountTiktokAuthNotMatch";
export const ACCOUNT_TOKEN_META_NOT_FOUND = "accountTokenMetaNotFound";
export const ACCOUNT_ID_BUSINESS_META_NOT_FOUND =
  "accountIDBusinessMetaNotFound";
export const OBJECTIVE_CAMPAIGN_META_INVALID = "objectiveCampaignMetaInvalid";
export const SPECIAL_CATEGORY_CAMPAIGN_META_INVALID =
  "specialCategoryCampaignMetaInvalid";
export const CAMPAIGN_META_NOT_DELETED = "campaignMetaNotDeleted";
export const CAMPAIGN_META_NOT_CREATED = "campaignMetaNotCreated";
export const ADS_CAMPAIGN_NOT_FOUND = "adsCampaignNotFound";
export const NO_PIXELS_FOUND = "noPixelsFound";
export const NO_PIXELS_ACTIVE = "noPixelsActive";
export const CAMPAIGN_META_NOT_FOUND = "campaignMetaNotFound";
export const CAMPAIGN_META_NOT_UPDATED = "campaignMetaNotUpdated";
export const CAMPAIGN_AD_SET_META_NOT_CREATED = "campaignAdSetMetaNotCreated";
export const CAMPAIGN_AD_SET_META_NOT_UPDATED = "campaignAdSetMetaNotUpdated";
export const CAMPAIGN_MEDIA_META_NOT_CREATED = "campaignMediaMetaNotCreated";
export const CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND =
  "campaignMediaThumbnailMetaNotFound";
export const CAMPAIGN_CREATIVE_AD_META_NOT_CREATED =
  "campaignCreativeAdMetaNotCreated";
export const CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED =
  "campaignCreativeAdMetaNotUpdated";
export const CAMPAIGN_CREATIVE_AD_META_NOT_FOUND =
  "campaignCreativeAdMetaNotFound";
export const CAMPAIGN_AD_META_NOT_CREATED = "campaignAdMetaNotCreated";
export const CAMPAIGN_AD_META_NOT_UPDATED = "campaignAdMetaNotUpdated";
export const CAMPAIGN_METRICS_META_NOT_FOUND = "campaignMetricsMetaNotFound";
export const CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE =
  "createAdsCampaignAccountNoHaveBalance";
export const ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE =
  "activeAdsCampaignAccountNoHaveBalance";
export const CAMPAIGN_AUDIENCE_IS_TOO_NARROW = "campaignAudienceIsTooNarrow";
export const CAMPAIGN_BUDGET_IS_TOO_LOW = "campaignBudgetIsTooLow";
export const IDENTITY_NOT_FOUND = "identityNotFound";
export const VIDEO_ERROR_LOW_RESOLUTION = "videoErrorLowResolution";
export const VIDEO_ERROR_NO_BACKGROUND_AUDIO = "videoErrorNoBackgroundAudio";
export const VIDEO_ERROR_ASPECT_RATIO = "videoErrorAspectRatio";
export const VIDEO_ERROR_BLACK_EDGE = "videoErrorBlackEdge";
export const VIDEO_ERROR_ILLEGAL_DURATION = "videoErrorIllegalDuration";
export const INTEGRATION_CURRENCY_NOT_FOUND = "integrationCurrencyNotFound";
export const USER_EMAIL_OR_PASSWORD_WRONG = "userEmailOrPasswordWrong";
export const USER_NOT_FOUND = "userNotFound";
export const ERROR_NETWORK = "ERR_NETWORK";

//ERROR COMPONENT
export const ERROR_COMPONENT_TITLE = "errorComponentTitle";
export const ERROR_COMPONENT_DESCRIPTION = "errorComponentDescription";
export const ERROR_COMPONENT_BUTTON_RELOAD_PAGE =
  "errorComponentButtonReloadPage";
export const ERROR_COMPONENT_CURRENT_ROUTE = "errorComponentCurrentRoute";

//CATEGORIES
export const RESTAURANTS = "restaurants";
export const FOODS = "foods";
export const TRAVEL = "travel";
export const ENTERTAINMENT = "entertainment";
export const TECHNOLOGY = "technology";
export const FITNESS = "fitness";
export const FASHION = "fashion";
export const BEAUTY = "beauty";
export const PETS = "pets";
export const HOME = "home";

//SUBCATEGORIES
export const RESTAURANTS_SUBCATEGORY_FOOD = "restaurantsSubcategoryFood";
export const RESTAURANTS_SUBCATEGORY_DRINKS = "restaurantsSubcategoryDrinks";
export const RESTAURANTS_SUBCATEGORY_DESSERTS =
  "restaurantsSubcategoryDesserts";
export const RESTAURANTS_SUBCATEGORY_COFFEE = "restaurantsSubcategoryCoffee";
export const RESTAURANTS_SUBCATEGORY_HOT_DRINKS =
  "restaurantsSubcategoryHotDrinks";
export const RESTAURANTS_SUBCATEGORY_COLD_DRINKS =
  "restaurantsSubcategoryColdDrinks";
export const RESTAURANTS_SUBCATEGORY_SOFT_DRINKS =
  "restaurantsSubcategorySoftDrinks";
export const RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS =
  "restaurantsSubcategoryFrozenDrinks";
export const RESTAURANTS_SUBCATEGORY_SUSHI = "restaurantsSubcategorySushi";
export const RESTAURANTS_SUBCATEGORY_ASIAN = "restaurantsSubcategoryAsian";
export const RESTAURANTS_SUBCATEGORY_JAPANESE =
  "restaurantsSubcategoryJapanese";
export const RESTAURANTS_SUBCATEGORY_CHINESE = "restaurantsSubcategoryChinese";
export const RESTAURANTS_SUBCATEGORY_ITALIAN = "restaurantsSubcategoryItalian";
export const RESTAURANTS_SUBCATEGORY_MEXICAN = "restaurantsSubcategoryMexican";
export const RESTAURANTS_SUBCATEGORY_HEALTHY = "restaurantsSubcategoryHealthy";
export const RESTAURANTS_SUBCATEGORY_VEGAN = "restaurantsSubcategoryVegan";
export const RESTAURANTS_SUBCATEGORY_VEGETARIAN =
  "restaurantsSubcategoryVegetarian";
export const FOODS_SUBCATEGORY_SEASONINGS = "foodsSubcategorySeasonings";
export const FOODS_SUBCATEGORY_FOOD = "foodsSubcategoryFood";
export const FOODS_SUBCATEGORY_GRANOLA = "foodsSubcategoryGranola";
export const FOODS_SUBCATEGORY_DRIED_FRUITS = "foodsSubcategoryDriedFruits";
export const FOODS_SUBCATEGORY_HEALTHY = "foodsSubcategoryHealthy";
export const TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM =
  "travelSubcategoryBeachSunTourism";
export const TRAVEL_SUBCATEGORY_RURAL_TOURISM = "travelSubcategoryRuralTourism";
export const TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM =
  "travelSubcategoryGastronomicTourism";
export const TRAVEL_SUBCATEGORY_NATURE_TOURISM =
  "travelSubcategoryNatureTourism";
export const TRAVEL_SUBCATEGORY_CULTURAL_TOURISM =
  "travelSubcategoryCulturalTourism";
export const TRAVEL_SUBCATEGORY_HEALTH_TOURISM =
  "travelSubcategoryHealthTourism";
export const ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT =
  "entertainmentSubcategoryEntertainment";
export const TECHNOLOGY_SUBCATEGORY_PHONES = "technologySubcategoryPhones";
export const TECHNOLOGY_SUBCATEGORY_COMPUTERS =
  "technologySubcategoryComputers";
export const TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES =
  "technologySubcategoryVideoGameConsoles";
export const TECHNOLOGY_SUBCATEGORY_ACCESSORIES =
  "technologySubcategoryAccessories";
export const TECHNOLOGY_SUBCATEGORY_PERIPHERALS =
  "technologySubcategoryPeripherals";
export const TECHNOLOGY_SUBCATEGORY_ELECTRONICS =
  "technologySubcategoryElectronics";
export const FITNESS_SUBCATEGORY_SPORTSWEAR = "fitnessSubcategorySportswear";
export const FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES =
  "fitnessSubcategorySportsAccessories";
export const FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS =
  "fitnessSubcategoryDietarySupplements";
export const FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT =
  "fitnessSubcategoryTrainingEquipment";
export const FASHION_SUBCATEGORY_HAUTE_COUTURE =
  "fashionSubcategoryHauteCouture";
export const FASHION_SUBCATEGORY_HIPSTER = "fashionSubcategoryHipster";
export const FASHION_SUBCATEGORY_CASUAL = "fashionSubcategoryCasual";
export const FASHION_SUBCATEGORY_CLASSIC = "fashionSubcategoryClassic";
export const FASHION_SUBCATEGORY_ROMANTIC = "fashionSubcategoryRomantic";
export const FASHION_SUBCATEGORY_SPORTSWEAR = "fashionSubcategorySportswear";
export const FASHION_SUBCATEGORY_PREPPY = "fashionSubcategoryPreppy";
export const FASHION_SUBCATEGORY_GRUNGE = "fashionSubcategoryGrunge";
export const FASHION_SUBCATEGORY_VINTAGE = "fashionSubcategoryVintage";
export const BEAUTY_SUBCATEGORY_COSMETICS = "beautySubcategoryCosmetics";
export const BEAUTY_SUBCATEGORY_EYE_CARE = "beautySubcategoryEyeCare";
export const BEAUTY_SUBCATEGORY_FEMININE_HYGIENE =
  "beautySubcategoryFeminineHygiene";
export const BEAUTY_SUBCATEGORY_FIRST_AID = "beautySubcategoryFirstAid";
export const BEAUTY_SUBCATEGORY_HAIR_CARE = "beautySubcategoryHairCare";
export const BEAUTY_SUBCATEGORY_MEDICAL_DEVICES =
  "beautySubcategoryMedicalDevices";
export const BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT =
  "beautySubcategoryMobilityEquipment";
export const BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS =
  "beautySubcategoryNutritionalSupplements";
export const BEAUTY_SUBCATEGORY_MEDICATIONS = "beautySubcategoryMedications";
export const BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE =
  "beautySubcategoryPersonalHygiene";
export const BEAUTY_SUBCATEGORY_SCALES = "beautySubcategoryScales";
export const BEAUTY_SUBCATEGORY_SKIN_CARE = "beautySubcategorySkinCare";
export const BEAUTY_SUBCATEGORY_VITAMINS = "beautySubcategoryVitamins";
export const BEAUTY_SUBCATEGORY_FACIAL_TISSUES =
  "beautySubcategoryFacialTissues";
export const PETS_SUBCATEGORY_FOOD = "petsSubcategoryFood";
export const PETS_SUBCATEGORY_COSMETICS = "petsSubcategoryCosmetics";
export const PETS_SUBCATEGORY_ACCESSORIES = "petsSubcategoryAccessories";
export const PETS_SUBCATEGORY_CLOTHING = "petsSubcategoryClothing";
export const PETS_SUBCATEGORY_MEDICATIONS = "petsSubcategoryMedications";
export const HOME_SUBCATEGORY_TEXTILES_CARPETS =
  "homeSubcategoryTextilesCarpets";
export const HOME_SUBCATEGORY_ART_PRINTS = "homeSubcategoryArtPrints";
export const HOME_SUBCATEGORY_STORAGE_ORGANIZATION =
  "homeSubcategoryStorageOrganization";
export const HOME_SUBCATEGORY_PLANT_DECOR = "homeSubcategoryPlantDecor";
export const HOME_SUBCATEGORY_CURTAINS_BLINDS = "homeSubcategoryCurtainsBlinds";

//OPTIONS
export const SEARCH_INFLUENCERS_TOPICS = "searchInfluencersTopicsOption";
export const SEARCH_INFLUENCERS_USERNAME = "searchInfluencersNameOption";

export const BODY_BUILD_THIN = "bodyBuildThin";
export const BODY_BUILD_AVERAGE = "bodyBuildAverage";
export const BODY_BUILD_ATHLETIC = "bodyBuildAthletic";
export const BODY_BUILD_WIDE = "bodyBuildWide";
export const BODY_BUILD_MUSCULAR = "bodyBuildMuscular";
export const BODY_BUILD_CURVY = "bodyBuildCurvy";

export const CLOTHING_STYLE_CASUAL = "clothingStyleCasual";
export const CLOTHING_STYLE_FORMAL = "clothingStyleFormal";
export const CLOTHING_STYLE_SPORTY = "clothingStyleSporty";
export const CLOTHING_STYLE_BUSINESS = "clothingStyleBusiness";
export const CLOTHING_STYLE_ELEGANT = "clothingStyleElegant";
export const CLOTHING_STYLE_TRENDY = "clothingStyleTrendy";
export const CLOTHING_STYLE_STREETWEAR = "clothingStyleStreetwear";
export const CLOTHING_STYLE_TRADITIONAL = "clothingStyleTraditional";
export const CLOTHING_STYLE_VINTAGE = "clothingStyleVintage";
export const CLOTHING_STYLE_BOHEMIAN = "clothingStyleBohemian";
export const CLOTHING_STYLE_HIPSTER = "clothingStyleHipster";
export const CLOTHING_STYLE_PUNK = "clothingStylePunk";
export const CLOTHING_STYLE_GOTHIC = "clothingStyleGothic";

export const DISTINGUISHING_FEATURES_TATTOOS = "distinguishingFeaturesTattoos";
export const DISTINGUISHING_FEATURES_SCARS = "distinguishingFeaturesScars";
export const DISTINGUISHING_FEATURES_BIRTHMARKS =
  "distinguishingFeaturesBirthmarks";
export const DISTINGUISHING_FEATURES_FRECKLES =
  "distinguishingFeaturesFreckles";
export const DISTINGUISHING_FEATURES_MOLES = "distinguishingFeaturesMoles";
export const DISTINGUISHING_FEATURES_PIERCINGS =
  "distinguishingFeaturesPiercings";
export const DISTINGUISHING_FEATURES_DIMPLES = "distinguishingFeaturesDimples";
export const DISTINGUISHING_FEATURES_WRINKLES =
  "distinguishingFeaturesWrinkles";
export const DISTINGUISHING_FEATURES_GLASSES = "distinguishingFeaturesGlasses";
export const DISTINGUISHING_FEATURES_BRACES = "distinguishingFeaturesBraces";
export const DISTINGUISHING_FEATURES_MAKEUP = "distinguishingFeaturesMakeup";
export const DISTINGUISHING_FEATURES_HAIR_ACCESSORIES =
  "distinguishingFeaturesHairAccessories";
export const DISTINGUISHING_FEATURES_BEARD = "distinguishingFeaturesBeard";
export const DISTINGUISHING_FEATURES_MUSTACHE =
  "distinguishingFeaturesMustache";

export const CONTENT_STYLE_ELEGANT = "contentStyleElegant";
export const CONTENT_STYLE_AESTHETIC = "contentStyleAesthetic";
export const CONTENT_STYLE_RAW = "contentStyleRaw";
export const CONTENT_STYLE_MINIMALISTIC = "contentStyleMinimalistic";
export const CONTENT_STYLE_ARTISTIC = "contentStyleArtistic";
export const CONTENT_STYLE_VINTAGE = "contentStyleVintage";
export const CONTENT_STYLE_MODERN = "contentStyleModern";
export const CONTENT_STYLE_PLAYFUL = "contentStylePlayful";
export const CONTENT_STYLE_RUSTIC = "contentStyleRustic";
export const CONTENT_STYLE_MONOCHROMATIC = "contentStyleMonochromatic";

export const EMPLOYEES_2_10 = "employees2-10";
export const EMPLOYEES_10_20 = "employees10-20";
export const EMPLOYEES_20_50 = "employees20-50";
export const EMPLOYEES_50_100 = "employees50-100";
export const EMPLOYEES_100_THAN = "employees100-than";

export const PRODUCT_AVAILABILITY_LOCAL = "productAvailabilityLocal";
export const PRODUCT_AVAILABILITY_NATIONAL = "productAvailabilityNational";
export const PRODUCT_AVAILABILITY_INTERNATIONAL =
  "productAvailabilityInternational";

export const INSTAGRAM = "instagram";
export const TIKTOK = "tiktok";
export const LINKEDIN = "linkedIn";
export const META = "meta";
export const META_FACEBOOK_AND_INSTAGRAM = "metaFacebookAndInstagram";
export const GOOGLE = "google";
export const DARK_KITCHEN = "darkKitchen";
export const OTHERS = "others";

export const ADS_CAMPAIGN_OBJECTIVE_AWARENESS = "adsCampaignObjectiveAwareness";
export const ADS_CAMPAIGN_OBJECTIVE_TRAFFIC = "adsCampaignObjectiveTraffic";
export const ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT =
  "adsCampaignObjectiveEngagement";
export const ADS_CAMPAIGN_OBJECTIVE_LEADS = "adsCampaignObjectiveLeads";
export const ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION =
  "adsCampaignObjectiveAppPromotion";
export const ADS_CAMPAIGN_OBJECTIVE_SALES = "adsCampaignObjectiveSales";
export const ADS_CAMPAIGN_TYPE_BUDGET_DAILY = "adsCampaignTypeBudgetDaily";
export const ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME =
  "adsCampaignTypeBudgetLifetime";
export const ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU =
  "adsCampaignCallToActionSeeMenu";
export const ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW =
  "adsCampaignCallToActionShopNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP =
  "adsCampaignCallToActionSignUp";
export const ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE =
  "adsCampaignCallToActionSubscribe";
export const ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE =
  "adsCampaignCallToActionWatchMore";
export const ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW =
  "adsCampaignCallToActionApplyNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW =
  "adsCampaignCallToActionBookNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW =
  "adsCampaignCallToActionCallNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US =
  "adsCampaignCallToActionContactUs";
export const ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD =
  "adsCampaignCallToActionDownload";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS =
  "adsCampaignCallToActionGetDirections";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE =
  "adsCampaignCallToActionGetQuote";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES =
  "adsCampaignCallToActionGetShowtimes";
export const ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE =
  "adsCampaignCallToActionLearnMore";
export const ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW =
  "adsCampaignCallToActionListenNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE =
  "adsCampaignCallToActionSendMessage";
export const ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW =
  "adsCampaignCallToActionOrderNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME =
  "adsCampaignCallToActionRequestTime";
export const ADS_CAMPAIGN_CALL_TO_ACTION_SAVE = "adsCampaignCallToActionSave";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER =
  "adsCampaignCallToActionGetOffer";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS =
  "adsCampaignCallToActionGetPromotions";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS =
  "adsCampaignCallToActionGetAccess";
export const ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES =
  "adsCampaignCallToActionGetUpdates";
export const ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW =
  "adsCampaignCallToActionInquireNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS =
  "adsCampaignCallToActionBuyTickets";
export const ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME =
  "adsCampaignCallToActionPlayGame";
export const ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE =
  "adsCampaignCallToActionExploreMore";
export const ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW =
  "adsCampaignCallToActionInstallNow";
export const ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP =
  "adsCampaignCallToActionUseApp";

export const LESS_500K = "-500000";
export const BETWEEN_500K_1M = "500000-1000000";
export const BETWEEN_1M_2_5M = "1000000-2500000";
export const BETWEEN_2_5M_5M = "2500000-5000000";
export const MORE_5M = "5000000+";
export const LESS_1M = "-1000000";
export const BETWEEN_1M_5M = "1000000-5000000";
export const BETWEEN_5M_15M = "5000000-15000000";
export const BETWEEN_15M_30M = "15000000-30000000";
export const BETWEEN_30M_100M = "30000000-100000000";
export const MORE_100M = "100000000+";
export const LESS_150 = "-150";
export const BETWEEN_150_250 = "150-250";
export const BETWEEN_250_650 = "250-650";
export const BETWEEN_650_1250 = "650-1250";
export const MORE_1250 = "1250+";
export const LESS_250 = "-250";
export const BETWEEN_250_1250 = "250-1250";
export const BETWEEN_1250_3750 = "1250-3750";
export const BETWEEN_3750_7500 = "3750-7500";
export const BETWEEN_7500_25K = "7500-25000";
export const MORE_25K = "25000+";
export const LESS_1000 = "-1000";
export const BETWEEN_1000_5000 = "1000-5000";
export const BETWEEN_5000_15K = "5000-15000";
export const BETWEEN_15K_30K = "15000-30000";
export const BETWEEN_30K_60K = "30000-60000";
export const MORE_60K = "60000+";
export const BETWEEN_1000_2000 = "1000-2000";
export const BETWEEN_2000_5000 = "2000-5000";
export const BETWEEN_5000_10K = "5000-10000";
export const MORE_10K = "10000+";

export const DOCUMENT_CC = "cc";
export const DOCUMENT_CE = "ce";
export const DOCUMENT_NUIP = "nuip";
export const DOCUMENT_NIT = "nit";
export const DOCUMENT_CI = "ci";
export const DOCUMENT_PASSPORT = "passport";
export const DOCUMENT_EIN = "ein";
export const DOCUMENT_RUC = "ruc";
export const DOCUMENT_ID = "id";
export const DOCUMENT_ID_EMIRATES = "idEmirates";
export const DOCUMENT_TRN = "trn";

//EXPLANATIONS TOOLTIP
export const ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION =
  "adsCampaignObjectiveAwarenessTitleExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION =
  "adsCampaignObjectiveAwarenessDescriptionExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION =
  "adsCampaignObjectiveTrafficTitleExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION =
  "adsCampaignObjectiveTrafficDescriptionExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION =
  "adsCampaignObjectiveEngagementTitleExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION =
  "adsCampaignObjectiveEngagementDescriptionExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION =
  "adsCampaignObjectiveLeadsTitleExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION =
  "adsCampaignObjectiveLeadsDescriptionExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION =
  "adsCampaignObjectiveAppPromotionTitleExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION =
  "adsCampaignObjectiveAppPromotionDescriptionExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION =
  "adsCampaignObjectiveSalesTitleExplanation";
export const ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION =
  "adsCampaignObjectiveSalesDescriptionExplanation";
export const ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION =
  "adsCampaignTypeBudgetDailyTitleExplanation";
export const ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION =
  "adsCampaignTypeBudgetDailyDescriptionExplanation";
export const ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION =
  "adsCampaignTypeBudgetTotalTitleExplanation";
export const ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION =
  "adsCampaignTypeBudgetTotalDescriptionExplanation";

//ONBOARDING

//MAIN FUNCTIONALITY
export const MAIN_FUNCTIONALITY_STEP_1 = "mainFunctionalityStep1";
export const MAIN_FUNCTIONALITY_STEP_2 = "mainFunctionalityStep2";
export const MAIN_FUNCTIONALITY_STEP_3 = "mainFunctionalityStep3";
export const MAIN_FUNCTIONALITY_STEP_4 = "mainFunctionalityStep4";
export const MODAL_INVITATION_STEP_1 = "modalInvitationStep1";
export const MODAL_INVITATION_STEP_2 = "modalInvitationStep2";
export const MODAL_INVITATION_STEP_3 = "modalInvitationStep3";
export const MODAL_INVITATION_STEP_4 = "modalInvitationStep4";
export const MODAL_INVITATION_STEP_5 = "modalInvitationStep5";
export const MODAL_INVITATION_STEP_6 = "modalInvitationStep6";
export const MODAL_INVITATION_STEP_7 = "modalInvitationStep7";
export const PUBLICATIONS_FUNCTIONALITY_STEP_1 =
  "publicationsFunctionalityStep1";
export const PUBLICATIONS_FUNCTIONALITY_STEP_2 =
  "publicationsFunctionalityStep2";
export const PUBLICATIONS_FUNCTIONALITY_STEP_3 =
  "publicationsFunctionalityStep3";
export const PUBLICATIONS_FUNCTIONALITY_STEP_4 =
  "publicationsFunctionalityStep4";
export const PUBLICATION_FUNCTIONALITY_STEP_1 = "publicationFunctionalityStep1";
export const PUBLICATION_FUNCTIONALITY_STEP_2 = "publicationFunctionalityStep2";
export const PUBLICATION_FUNCTIONALITY_STEP_3 = "publicationFunctionalityStep3";
export const REVIEW_FUNCTIONALITY_STEP_1 = "reviewFunctionalityStep1";
export const REVIEW_FUNCTIONALITY_STEP_2 = "reviewFunctionalityStep2";
export const REVIEW_FUNCTIONALITY_STEP_3 = "reviewFunctionalityStep3";
export const REVIEW_FUNCTIONALITY_STEP_4 = "reviewFunctionalityStep4";
export const REVIEW_FUNCTIONALITY_STEP_5 = "reviewFunctionalityStep5";
export const MAIN_FUNCTIONALITY_ONBOARDING_FINISH =
  "mainFunctionalityOnboardingFinish";

//MENU FUNCTIONALITY
export const MENU_FUNCTIONALITY_STEP_1 = "menuFunctionalityStep1";
export const MENU_FUNCTIONALITY_STEP_2 = "menuFunctionalityStep2";
export const MENU_FUNCTIONALITY_STEP_3 = "menuFunctionalityStep3";
export const MENU_FUNCTIONALITY_STEP_4 = "menuFunctionalityStep4";
export const MENU_FUNCTIONALITY_STEP_5 = "menuFunctionalityStep5";
export const MENU_FUNCTIONALITY_STEP_6 = "menuFunctionalityStep6";
export const MENU_FUNCTIONALITY_STEP_7 = "menuFunctionalityStep7";
export const MENU_FUNCTIONALITY_STEP_8 = "menuFunctionalityStep8";
export const MENU_FUNCTIONALITY_STEP_9 = "menuFunctionalityStep9";
export const MENU_FUNCTIONALITY_STEP_10 = "menuFunctionalityStep10";
export const MENU_FUNCTIONALITY_STEP_11 = "menuFunctionalityStep11";
export const MENU_FUNCTIONALITY_STEP_12 = "menuFunctionalityStep12";

//USERS FUNCTIONALITY
export const USERS_FUNCTIONALITY_STEP_1 = "usersFunctionalityStep1";
export const USERS_FUNCTIONALITY_STEP_2 = "usersFunctionalityStep2";
export const USERS_FUNCTIONALITY_STEP_3 = "usersFunctionalityStep3";
export const USERS_FUNCTIONALITY_STEP_4 = "usersFunctionalityStep4";

//OPERATOR FUNCTIONALITY
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_1 =
  "mainFunctionalityOperatorStep1";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_2 =
  "mainFunctionalityOperatorStep2";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_3 =
  "mainFunctionalityOperatorStep3";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_4 =
  "mainFunctionalityOperatorStep4";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_5 =
  "mainFunctionalityOperatorStep5";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_6 =
  "mainFunctionalityOperatorStep6";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_7 =
  "mainFunctionalityOperatorStep7";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_8 =
  "mainFunctionalityOperatorStep8";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_9 =
  "mainFunctionalityOperatorStep9";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_10 =
  "mainFunctionalityOperatorStep10";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_11 =
  "mainFunctionalityOperatorStep11";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_12 =
  "mainFunctionalityOperatorStep12";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_13 =
  "mainFunctionalityOperatorStep13";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_14 =
  "mainFunctionalityOperatorStep14";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_15 =
  "mainFunctionalityOperatorStep15";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_16 =
  "mainFunctionalityOperatorStep16";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_17 =
  "mainFunctionalityOperatorStep17";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_18 =
  "mainFunctionalityOperatorStep18";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_19 =
  "mainFunctionalityOperatorStep19";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_20 =
  "mainFunctionalityOperatorStep20";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_21 =
  "mainFunctionalityOperatorStep21";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_22 =
  "mainFunctionalityOperatorStep22";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_23 =
  "mainFunctionalityOperatorStep23";
export const MAIN_FUNCTIONALITY_OPERATOR_STEP_24 =
  "mainFunctionalityOperatorStep24";

//AMBASSADOR FUNCTIONALITY
export const AMBASSADORS_FUNCTIONALITY_STEP_1 = "ambassadorsFunctionalityStep1";
export const AMBASSADORS_FUNCTIONALITY_STEP_2 = "ambassadorsFunctionalityStep2";
export const AMBASSADORS_FUNCTIONALITY_STEP_3 = "ambassadorsFunctionalityStep3";
export const AMBASSADORS_FUNCTIONALITY_STEP_4 = "ambassadorsFunctionalityStep4";
export const AMBASSADORS_FUNCTIONALITY_STEP_5 = "ambassadorsFunctionalityStep5";
export const AMBASSADORS_FUNCTIONALITY_STEP_6 = "ambassadorsFunctionalityStep6";
export const AMBASSADORS_FUNCTIONALITY_STEP_7 = "ambassadorsFunctionalityStep7";
export const AMBASSADORS_FUNCTIONALITY_STEP_8 = "ambassadorsFunctionalityStep8";
export const AMBASSADORS_FUNCTIONALITY_STEP_9 = "ambassadorsFunctionalityStep9";
export const AMBASSADORS_FUNCTIONALITY_STEP_10 =
  "ambassadorsFunctionalityStep10";
export const AMBASSADORS_FUNCTIONALITY_STEP_11 =
  "ambassadorsFunctionalityStep11";

//DISCOVERY INFLUENCERS FUNCTIONALITY
export const DISCOVERY_FUNCTIONALITY_STEP_1 = "discoveryFunctionalityStep1";
export const DISCOVERY_FUNCTIONALITY_STEP_2 = "discoveryFunctionalityStep2";
export const DISCOVERY_FUNCTIONALITY_STEP_3 = "discoveryFunctionalityStep3";
export const DISCOVERY_FUNCTIONALITY_STEP_4 = "discoveryFunctionalityStep4";
export const DISCOVERY_FUNCTIONALITY_STEP_5 = "discoveryFunctionalityStep5";
export const DISCOVERY_FUNCTIONALITY_STEP_6 = "discoveryFunctionalityStep6";
export const DISCOVERY_FUNCTIONALITY_STEP_7 = "discoveryFunctionalityStep7";
export const DISCOVERY_FUNCTIONALITY_STEP_8 = "discoveryFunctionalityStep8";
export const DISCOVERY_FUNCTIONALITY_STEP_9 = "discoveryFunctionalityStep9";
export const DISCOVERY_FUNCTIONALITY_STEP_10 = "discoveryFunctionalityStep10";
export const DISCOVERY_FUNCTIONALITY_STEP_11 = "discoveryFunctionalityStep11";
export const DISCOVERY_FUNCTIONALITY_STEP_12 = "discoveryFunctionalityStep12";
export const DISCOVERY_FUNCTIONALITY_STEP_13 = "discoveryFunctionalityStep13";
export const DISCOVERY_FUNCTIONALITY_STEP_14 = "discoveryFunctionalityStep14";
export const DISCOVERY_FUNCTIONALITY_STEP_15 = "discoveryFunctionalityStep15";
export const DISCOVERY_FUNCTIONALITY_STEP_16 = "discoveryFunctionalityStep16";
export const DISCOVERY_FUNCTIONALITY_STEP_17 = "discoveryFunctionalityStep17";

//CAMPAIGNS FUNCTIONALITY
export const CAMPAIGNS_FUNCTIONALITY_STEP_1 = "campaignsFunctionalityStep1";
export const CAMPAIGNS_FUNCTIONALITY_STEP_2 = "campaignsFunctionalityStep2";
export const CAMPAIGNS_FUNCTIONALITY_STEP_3 = "campaignsFunctionalityStep3";
export const CAMPAIGNS_FUNCTIONALITY_STEP_4 = "campaignsFunctionalityStep4";
export const CAMPAIGNS_FUNCTIONALITY_STEP_5 = "campaignsFunctionalityStep5";
export const CAMPAIGNS_FUNCTIONALITY_STEP_6 = "campaignsFunctionalityStep6";
export const CAMPAIGNS_FUNCTIONALITY_STEP_7 = "campaignsFunctionalityStep7";
export const CAMPAIGNS_FUNCTIONALITY_STEP_8 = "campaignsFunctionalityStep8";
export const CAMPAIGNS_FUNCTIONALITY_STEP_9 = "campaignsFunctionalityStep9";
export const CAMPAIGNS_FUNCTIONALITY_STEP_10 = "campaignsFunctionalityStep10";
export const CAMPAIGNS_FUNCTIONALITY_STEP_11 = "campaignsFunctionalityStep11";
export const CAMPAIGNS_FUNCTIONALITY_STEP_12 = "campaignsFunctionalityStep12";
export const CAMPAIGNS_FUNCTIONALITY_STEP_13 = "campaignsFunctionalityStep13";
export const CAMPAIGNS_FUNCTIONALITY_STEP_14 = "campaignsFunctionalityStep14";
export const CAMPAIGNS_FUNCTIONALITY_STEP_15 = "campaignsFunctionalityStep15";
export const CAMPAIGNS_FUNCTIONALITY_STEP_16 = "campaignsFunctionalityStep16";
export const CAMPAIGNS_FUNCTIONALITY_STEP_17 = "campaignsFunctionalityStep17";

//STORES FUNCTIONALITY
export const STORES_FUNCTIONALITY_STEP_1 = "storesFunctionalityStep1";
export const STORES_FUNCTIONALITY_STEP_2 = "storesFunctionalityStep2";
export const STORES_FUNCTIONALITY_STEP_3 = "storesFunctionalityStep3";

//MODALS FUNCTIONALITY
export const MODAL_FUNCTIONALITY_AMBASSADOR = "modalFunctionalityAmbassador";
export const MODAL_FUNCTIONALITY_DISCOVERY = "modalFunctionalityDiscovery";
export const MODAL_FUNCTIONALITY_CAMPAIGNS = "modalFunctionalityCampaigns";
export const MODAL_FUNCTIONALITY_AUTO_INVITES = "modalFunctionalityAutoInvites";
