import React, { useEffect, useState } from "react";
import Select from "./Select";
import { useTranslationApp } from "../../../lib/i18next";
import { FIELD_REQUIRED } from "../../../locales/keysTranslations";
import i18next from "i18next";
import {
  optionsCallToActionAppPromotionMeta,
  optionsCallToActionAwarenessMeta,
  optionsCallToActionEngagementMeta,
  optionsCallToActionLeadsMeta,
  optionsCallToActionSalesMeta,
  optionsCallToActionTrafficMeta,
} from "../../../utils/slidersMarks";
import {
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
} from "../../../utils/constants";

const SelectCallToActionAds = React.forwardRef((props, ref) => {
  const {
    control,
    errors,
    rules = {
      required: {
        value: true,
        message: i18next.t(FIELD_REQUIRED),
      },
    },
    name,
    label,
    disabled,
    fullWidth,
    variant = "filled",
    objective,
  } = props;

  const [options, setOptions] = useState([]);
  const { t } = useTranslationApp();

  useEffect(() => {
    if (!objective) return;

    if (ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION)
      setOptions(optionsCallToActionAwarenessMeta);
    if (ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION)
      setOptions(optionsCallToActionTrafficMeta);
    if (ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION)
      setOptions(optionsCallToActionEngagementMeta);
    if (ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION)
      setOptions(optionsCallToActionLeadsMeta);
    if (ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION)
      setOptions(optionsCallToActionAppPromotionMeta);
    if (ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION)
      setOptions(optionsCallToActionSalesMeta);
  }, [objective]);

  return (
    <Select
      label={t(label)}
      disabled={disabled}
      name={name}
      variant={variant}
      options={options}
      fullWidth={fullWidth}
      doTranslate={true}
      control={control}
      errors={errors}
      rules={rules}
    />
  );
});

export default SelectCallToActionAds;
