import { useSelectorApp } from "../../lib/redux";
import { Navigate } from "react-router-dom";
import { OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE } from "../../utils/constants";

export const StaffRoute = ({ children, isLogged, blockedManager = false }) => {
  const { user } = useSelectorApp((state) => state.auth);
  if (!isLogged) return <Navigate to="/login" />;

  if (blockedManager && user.role === MANAGER_ROLE) return <Navigate to="/" />;

  if (
    user.role !== OWNER_ROLE &&
    user.role !== MANAGER_ROLE &&
    user.role !== STAFF_ROLE
  )
    return <Navigate to="/" />;

  return children;
};
