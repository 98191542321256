import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import DialogActions from "@mui/material/DialogActions";
import SelectsMenu from "./SelectsMenu";
import { startCopyMenu } from "../../../actions/menu";
import { ConfirmAlert, SimpleAlert } from "../../../utils/alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_COPY_CATALOG,
  FIELD_ALL_REQUIRED,
  MODAL_COPY_MENU_ARIA_LABEL,
  MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION,
  MODAL_COPY_MENU_CONFIRM_ALERT_TITLE,
  MODAL_COPY_MENU_COPIED,
  MODAL_COPY_MENU_DESCRIPTION,
  MODAL_COPY_MENU_SAME_STORE,
  MODAL_COPY_MENU_TITLE,
} from "../../../locales/keysTranslations";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const INITIAL_STATE = {
  menuFrom: "",
  menuTo: "",
};
const ModalCopyMenu = ({ open, onCloseModal, storesList, onChangeStore }) => {
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const onChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    if (!formValues.menuFrom || !formValues.menuTo)
      return setError(t(FIELD_ALL_REQUIRED));
    if (formValues.menuFrom === formValues.menuTo)
      return setError(t(MODAL_COPY_MENU_SAME_STORE));
    const response = await ConfirmAlert({
      title: t(MODAL_COPY_MENU_CONFIRM_ALERT_TITLE),
      text: t(MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION),
      confirmButtonText: t(BUTTON_COPY_CATALOG),
    });
    setError(null);
    if (response.isConfirmed) {
      const response = await dispatch(startCopyMenu(formValues));
      if (response) {
        SimpleAlert({
          title: t(MODAL_COPY_MENU_COPIED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        onChangeStore(formValues.menuTo);
        onCloseModal();
      }
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      ariaLabel={t(MODAL_COPY_MENU_ARIA_LABEL)}
      scroll="paper"
      maxWidth="xs"
      title={t(MODAL_COPY_MENU_TITLE)}
      description={t(MODAL_COPY_MENU_DESCRIPTION)}
      sxContent={{
        px: { xs: 3, md: 5 },
        pb: 2,
        pt: "16px !important",
      }}
    >
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <Alert
            severity="error"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              align: "center",
            }}
          >
            {error}
          </Alert>
        </Box>
      )}
      <SelectsMenu
        data={formValues}
        storesList={storesList}
        onChange={onChange}
      />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          pt: 0,
          mb: 1,
          px: 5,
        }}
      >
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: () => {
              setFormValues(INITIAL_STATE);
              onCloseModal();
            },
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_COPY_CATALOG,
            onClick: () => onSubmit(),
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalCopyMenu;
