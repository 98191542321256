import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslationApp } from "../../../lib/i18next";
import LoadingButton from "./LoadingButton";

const ButtonMenu = ({
  loading = false,
  options,
  textButton = "",
  variantButton = "contained",
  colorButton = "primary",
  fullWidth = false,
  sx = {},
  sxMenu = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const onSelectMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslationApp();

  return (
    <>
      <LoadingButton
        loading={loading}
        fullWidth={fullWidth}
        variant={variantButton}
        color={colorButton}
        onClick={onSelectMenu}
        sx={sx}
      >
        {t(textButton)}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{ ...sxMenu }}
      >
        {options.map((option, index) => {
          if (option.customComponent)
            return <Box key={index}>{option.customComponent}</Box>;
          return (
            <MenuItem
              key={option.label}
              onClick={() => {
                option.onClick();
                setAnchorEl(null);
              }}
            >
              {option.icon ? option.icon : null} {t(option.label)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonMenu;
