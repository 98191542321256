import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import { BOX_SHADOW } from "../../../utils/colors";
import { numberCompactFormat } from "../../../utils/numberFormats";
import { PUBLISHED_DATE } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LinkText from "../Texts/LinkText";

const InfluencerPost = ({ data }) => {
  const { t } = useTranslationApp();
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 4,
        boxShadow: BOX_SHADOW,
      }}
    >
      <LinkText href={data.url} target="_blank">
        <CardMedia
          component="img"
          height="200"
          image={data.thumbnailUrl}
          alt={`A post of ${data.description}`}
        />
      </LinkText>
      <CardContent sx={{}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography variant="body1">
              {numberCompactFormat(data?.engagement?.likeCount)}
            </Typography>
            <ThumbUpIcon sx={{ fontSize: "1.3rem", mt: "-4px" }} />
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography variant="body1">
              {numberCompactFormat(data?.engagement?.commentCount)}
            </Typography>
            <CommentIcon sx={{ fontSize: "1.3rem" }} />
          </Box>
        </Box>
        <Box
          sx={{ overflow: "hidden", textOverflow: "ellipsis", maxHeight: 145 }}
        >
          <Typography variant="body1" color="text.secondary">
            {data.description}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {t(PUBLISHED_DATE, {
            date: new Date(data.publishedAt),
          })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default InfluencerPost;
