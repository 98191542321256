import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

const RequestInfluencerAccountsActions = ({
  data,
  id,
  onSelectRequest,
  onCodeSent,
}) => {
  return (
    <Box>
      {data.verificationCode && !data.isCodeSent ? (
        <IconButton onClick={() => onCodeSent(id)}>
          <MarkEmailReadIcon />
        </IconButton>
      ) : null}
      {/* {!data.verificationCode && data.isPending ? (
        <IconButton color="primary" onClick={() => onSelectRequest(data, id)}>
          <EditIcon />
        </IconButton>
      ) : null} */}
    </Box>
  );
};

export default RequestInfluencerAccountsActions;
