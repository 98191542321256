import React from "react";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import LoadingButton from "../Buttons/LoadingButton";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CALCULATE,
  END_DATE_PLACEHOLDER,
  FIELD_REQUIRED,
  START_DATE_AND_END_DATE_VALIDATION,
  START_DATE_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import i18next from "i18next";

const DatesFields = ({
  control,
  errors,
  getValues,
  textButton = i18next.t(BUTTON_CALCULATE),
  textSecondButton = i18next.t(BUTTON_CALCULATE),
  colorButton = "secondary",
  isFetching = false,
  isHiddenButton = false,
  disabled = false,
  showSecondButton = false,
  secondAction = () => {},
  dataTour = "",
  sx = {},
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
        gap: 3,
        mt: 2,
        ...sx,
      }}
      data-tour={dataTour}
    >
      <Controller
        name="startDate"
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
          validate: {
            value: (value) => {
              const endDate = getValues("endDate");
              if (endDate.getTime() && value.getTime() > endDate.getTime()) {
                return t(START_DATE_AND_END_DATE_VALIDATION);
              }
            },
          },
        }}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t(START_DATE_PLACEHOLDER)}
            renderInput={(params) => (
              <TextField
                sx={{ maxWidth: 300 }}
                fullWidth
                variant="filled"
                {...params}
                error={!!errors[field.name]}
                helperText={
                  !!errors[field.name] ? errors[field.name]?.message : ""
                }
              />
            )}
          />
        )}
      />
      <Controller
        name="endDate"
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
          validate: {
            value: (value) => {
              const startDate = getValues("startDate");
              if (
                startDate.getTime() &&
                value.getTime() < startDate.getTime()
              ) {
                return t(START_DATE_AND_END_DATE_VALIDATION);
              }
            },
          },
        }}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t(END_DATE_PLACEHOLDER)}
            renderInput={(params) => (
              <TextField
                sx={{ maxWidth: 300 }}
                fullWidth
                variant="filled"
                {...params}
                error={!!errors[field.name]}
                helperText={
                  !!errors[field.name] ? errors[field.name]?.message : ""
                }
              />
            )}
          />
        )}
      />
      {!isHiddenButton && (
        <LoadingButton
          disabled={disabled}
          loading={isFetching}
          color={colorButton}
          variant="contained"
          type="submit"
        >
          {textButton}
        </LoadingButton>
      )}
      {showSecondButton && (
        <LoadingButton
          disabled={disabled}
          loading={isFetching}
          color={colorButton}
          variant="contained"
          sx={{
            background: GRADIENT_PURPLE_FUCHSIA,
            color: `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT}`,
          }}
          onClick={secondAction}
        >
          {textSecondButton}
        </LoadingButton>
      )}
    </Box>
  );
};

export default DatesFields;
