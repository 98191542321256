import React from "react";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TimeField from "../../../Common/NumberFormats/TimeField";
import { SCHEDULE_EVENT_EDIT_TURN } from "../../../../utils/constants";
import Button from "../../../Common/Buttons/Button";
import {
  ACTIONS,
  BUTTON_ADD_TURN,
  DAY,
  SCHEDULE_TABLE_COLUMN_CLOSE,
  SCHEDULE_TABLE_COLUMN_OPEN,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import TableContainer from "../../../Common/Table/TableContainer";
import TableCell from "../../../Common/Table/TableCell";

const DAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

const ScheduleTable = ({
  data,
  onChangeForm,
  onAddTurn,
  onDeleteTurn,
  isECommerce,
  sxTable = {},
}) => {
  const { t } = useTranslationApp();
  return (
    <TableContainer
      elevation={0}
      hasPagination={false}
      hasMarginBottom={false}
      keys={data}
      sx={{ width: "100%", minWidth: 750, maxHeight: 700, ...sxTable }}
    >
      <TableHead>
        <TableRow>
          <TableCell isHeader={true}>{t(DAY)}</TableCell>
          <TableCell align="center" isHeader={true}>
            {t(SCHEDULE_TABLE_COLUMN_OPEN)}
          </TableCell>
          <TableCell align="center" isHeader={true}>
            {t(SCHEDULE_TABLE_COLUMN_CLOSE)}
          </TableCell>
          <TableCell align="center" isHeader={true}>
            {t(ACTIONS)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          if (row?.turns?.length > 0) {
            return row.turns.map((turn, index2) => (
              <TableRow
                key={turn.id}
                sx={{
                  border: 0,
                }}
              >
                <TableCell
                  indexRow={index}
                  sx={{
                    border: 0,
                  }}
                >
                  {index2 === 0 && t(DAYS[index])}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    border: 0,
                  }}
                >
                  <TextField
                    variant="filled"
                    disabled={isECommerce}
                    hiddenLabel={true}
                    name="start"
                    value={turn.start}
                    InputProps={{
                      inputComponent: TimeField,
                    }}
                    onChange={(e) =>
                      onChangeForm(e, {
                        event: SCHEDULE_EVENT_EDIT_TURN,
                        value: {
                          day: row.day,
                          idturn: turn.id,
                        },
                      })
                    }
                  />
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    border: 0,
                  }}
                >
                  <TextField
                    variant="filled"
                    hiddenLabel={true}
                    disabled={isECommerce}
                    name="end"
                    value={turn.end}
                    InputProps={{
                      inputComponent: TimeField,
                    }}
                    onChange={(e) =>
                      onChangeForm(e, {
                        event: SCHEDULE_EVENT_EDIT_TURN,
                        value: {
                          day: row.day,
                          idturn: turn.id,
                        },
                      })
                    }
                  />
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    border: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => onDeleteTurn(row.day, turn.id)}
                      type="button"
                      disabled={isECommerce}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Button
                      disabled={isECommerce}
                      variant="text"
                      hasShadow={false}
                      startIcon={<AddCircleIcon />}
                      onClick={() => onAddTurn(row.day)}
                      sx={{
                        minWidth: 174,
                        fontWeight: 600,
                        color: "text.primary",
                      }}
                      type="button"
                    >
                      {t(BUTTON_ADD_TURN)}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ));
          }
          return (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                indexRow={index}
                sx={{
                  border: 0,
                }}
              >
                {t(DAYS[index])}
              </TableCell>
              <TableCell
                indexRow={index}
                align="center"
                sx={{
                  border: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="text"
                    startIcon={<AddCircleIcon />}
                    onClick={() => onAddTurn(row.day)}
                    hasShadow={false}
                    sx={{
                      minWidth: 174,
                      fontWeight: 600,
                      color: "text.primary",
                    }}
                    type="button"
                  >
                    {t(BUTTON_ADD_TURN)}
                  </Button>
                </Box>
              </TableCell>
              <TableCell indexRow={index} />
              <TableCell indexRow={index} />
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};

export default ScheduleTable;
