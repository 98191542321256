import React, { useState } from "react";
import ModalBasicLayout from "../ModalBasicLayout";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_ACTIVATE_BRAND,
  MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION,
  MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE,
  READY,
} from "../../../../locales/keysTranslations";
import OnboardingRequiredInformationContainer from "./OnboardingRequiredInformationContainer";
import ContainerModalActions from "../../Containers/ContainerModalActions";
import { useDispatchApp } from "../../../../lib/redux";
import { setShowModalOnboardingRequiredInformation } from "../../../../actions/ui";
import { startChangeIsActiveShop } from "../../../../actions/shops";
import { SimpleAlert } from "../../../../utils/alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../../utils/constants";

const ModalOnboardingRequiredInformation = ({ modalOpen }) => {
  const [isValidData, setIsValidData] = useState(true);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onCloseModal = () =>
    dispatch(setShowModalOnboardingRequiredInformation(false));

  const onActivateBrand = async () => {
    const response = await dispatch(startChangeIsActiveShop(true));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      hasTitle={true}
      title={t(MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE)}
      description={t(MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION)}
    >
      <OnboardingRequiredInformationContainer
        onChangeIsValidData={setIsValidData}
      />
      <ContainerModalActions
        hasPadding={false}
        sx={{ mt: 2 }}
        propsPrimaryButton={{
          showButton: true,
          disabled: !isValidData,
          text: BUTTON_ACTIVATE_BRAND,
          onClick: onActivateBrand,
        }}
      />
    </ModalBasicLayout>
  );
};

export default ModalOnboardingRequiredInformation;
