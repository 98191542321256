import React, { useState } from "react";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Accordion from "../Containers/Accordion";
import { FAQQuestions } from "../FAQ/faq";
import SearchBar from "../SearchBar/SearchBar";
import ModalBasicLayout from "./ModalBasicLayout";
import {
  BUTTON_CONTINUE,
  BUTTON_INIT_TUTORIAL,
  BUTTON_START_CHAT,
  EMPTY_QUESTIONS_SHOW,
  FAQ_DESCRIPTION,
  HELP,
  WARNING,
  WARNING_MOBILE_TUTORIAL,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import { getNameBusinessTheme } from "../../../actions/getters";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { startOpenChatBotConversation } from "../../../actions/chat";
import { BORDER_RADIUS } from "../../../utils/constants";
import { useMediaQuery } from "@mui/material";
import { ConfirmAlert } from "../../../utils/alerts";
import { useNavigate } from "react-router-dom";
import { useTour } from "@reactour/tour";
import { STEPS_MAIN_FUNCTIONALITY } from "../../../onboarding/steps";

const ModalFAQ = ({ modalOpen, onCloseModal }) => {
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { setIsOpen, setSteps } = useTour();
  const { t } = useTranslationApp();
  const navigate = useNavigate();
  const onChangeSearchText = (value) => setSearchText(value);

  const onOpenChatBot = () => {
    dispatch(startOpenChatBotConversation());
    onCloseModal();
  };

  const onOpenTutorial = async () => {
    if (isMobile) {
      const result = await ConfirmAlert({
        title: t(WARNING),
        text: t(WARNING_MOBILE_TUTORIAL),
        confirmButtonText: t(BUTTON_CONTINUE),
      });
      if (!result.isConfirmed) return;
    }
    setSteps(STEPS_MAIN_FUNCTIONALITY);
    navigate("/creators?tab=0");
    setIsOpen(true);
    onCloseModal();
  };

  const filteredFAQQuestions =
    searchText.length > 0
      ? FAQQuestions.filter((question) => {
          if (question.title.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
          }
          if (
            question.content.toLowerCase().includes(searchText.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
      : FAQQuestions;

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      ariaLabel={t(HELP)}
      title={t(HELP)}
    >
      <ContainerModalActions
        sx={{
          mb: 2,
        }}
        hasPadding={false}
        propsSecondaryButton={{
          showButton: true,
          text: BUTTON_INIT_TUTORIAL,
          onClick: onOpenTutorial,
        }}
        propsPrimaryButton={{
          showButton: true,
          text: BUTTON_START_CHAT,
          onClick: onOpenChatBot,
        }}
      />
      <Typography variant="body1">{t(FAQ_DESCRIPTION)}</Typography>
      <SearchBar onEditText={onChangeSearchText} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {filteredFAQQuestions.length > 0 ? (
          filteredFAQQuestions.map((question, index) => {
            return (
              <Accordion
                title={t(question.title, {
                  NAME_BUSINESS,
                })}
                sx={{
                  borderRadius: `${BORDER_RADIUS * 4}px !important`,
                }}
                key={index}
              >
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: t(question.content, {
                      NAME_BUSINESS,
                    }),
                  }}
                />
              </Accordion>
            );
          })
        ) : (
          <Typography variant="body1" align="center">
            {t(EMPTY_QUESTIONS_SHOW)}
          </Typography>
        )}
      </Box>
      <DialogActions sx={{ p: 0, pt: 2 }}></DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalFAQ;
