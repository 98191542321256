import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  Pie,
  PieChart as PieChartContainer,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import StatisticsContainer from "./StatisticContainer";
import { COLORS_STATISTICS } from "../../../utils/colors";
import { capitalizeFirstLetter } from "../../../utils/formats";
import {
  COLLABORATION,
  COLLABORATIONS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const PieChart = ({
  data = [],
  count = {},
  title = "Porcentaje de valores",
  sx = {},
}) => {
  const { t } = useTranslationApp();
  if (data.length === 0) return null;
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { xs: "100%", md: "calc(100vw - 330px)" },
        ...sx,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <StatisticsContainer
        sx={{
          height: 280,
          pr: 2,
          pb: 2,
          width: {
            xs: "100%",
            sm: 620,
          },
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChartContainer margin={{ left: 0, right: 0 }} stroke="none">
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              label={(object) => {
                return `${object.value}% (${count[object.name]} ${
                  count[object.name] > 1 ? t(COLLABORATIONS) : t(COLLABORATION)
                })`;
              }}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              stroke="none"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  stroke="none"
                  fill={COLORS_STATISTICS[index % COLORS_STATISTICS.length]}
                />
              ))}
            </Pie>
            <Legend formatter={(value) => capitalizeFirstLetter(value)} />
            <Tooltip
              formatter={(value, name) => {
                return count[name];
              }}
            />
          </PieChartContainer>
        </ResponsiveContainer>
      </StatisticsContainer>
    </Box>
  );
};

export default PieChart;
