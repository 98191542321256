import React, { useState } from "react";
import { useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../Loader/Loader";
import ModalBuyCredits from "./ModalBuyCredits";
import { useTheme } from "@mui/material/styles";
import { CREDITS } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { BUSINESS_ID_PROJECT } from "../../../utils/constants";
import _ from "lodash";

const CreditsCounter = ({ onSlideMenu, onDrawerToggle, sx }) => {
  const theme = useTheme();
  const [modalOpenBuyCredits, setModalOpenBuyCredits] = useState(false);
  const { clappCredits, isLoading, costCollaboration } = useSelectorApp(
    (state) => state.shop
  );
  const { plan } = useSelectorApp((state) => state.payments);

  const { t } = useTranslationApp();
  const onChangeModalBuyCreditsStatus = (status) => {
    if (!plan) return;
    setModalOpenBuyCredits(status);
  };

  if (isLoading) return <Loader color="secondary" hasMarginTop={false} />;

  if (!_.isEmpty(BUSINESS_ID_PROJECT)) return null;

  if (costCollaboration === 0) return null;

  return (
    <>
      <Tooltip sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${
              onSlideMenu ? theme.palette.background.paperContrastText : "white"
            }`,
            borderRadius: 4,
            py: "2px",
            px: onSlideMenu ? 0 : 2,
            mx: onSlideMenu ? 2 : 0,
            ...sx,
          }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              color: "text.primary",
            }}
          >
            {t(CREDITS)}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "text.primary",
            }}
          >
            {clappCredits < 0 ? 0 : clappCredits}
          </Typography>
        </Box>
      </Tooltip>
      {modalOpenBuyCredits && (
        <ModalBuyCredits
          modalOpen={modalOpenBuyCredits}
          onCloseModal={() => onChangeModalBuyCreditsStatus(false)}
        />
      )}
    </>
  );
};

export default CreditsCounter;
