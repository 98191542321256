import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";

const ContentCommon = ({ text, interpolations }) => {
  const { t } = useTranslationApp();

  return (
    <Typography
      color="inherit"
      variant="body1"
      sx={{ mb: 1 }}
      dangerouslySetInnerHTML={{
        __html: t(text, { ...interpolations }),
      }}
    />
  );
};

export default ContentCommon;
