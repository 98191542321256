import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../../../../../lib/i18next";
import { getInfluencerIsHiddenFromTables } from "../../../../../actions/getters";
import {
  capitalizeFirstLetter,
  checkArrayIntersection,
  getCityInfluencer,
  normalizeString,
} from "../../../../../utils/formats";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import TableContainer from "../../../../Common/Table/TableContainer";
import TableCell from "../../../../Common/Table/TableCell";
import {
  COLUMN_NAME_ACTION,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_CITY,
  COLUMN_NAME_AUDIENCE,
  COLUMN_NAME_ENGAGEMENT,
  COLUMN_NAME_CATEGORIES,
  COLUMN_NAME_SCORE,
  BUTTON_SEE_ALL_APPLICANTS,
  EMPTY_APPLICANTS_PENDING,
} from "../../../../../locales/keysTranslations";
import { keyframes } from "@mui/system";
import { PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW } from "../../../../../onboarding/stepsSelectors";
import ActionsApplicantButtons from "./ActionsApplicantButtons";
import StatusApplicant from "./StatusApplicant";
import NameInfluencerCell from "../../../../Common/Influencer/NameInfluencerCell";
import SocialNetworkCell from "../../../../Common/Influencer/SocialNetworkCell";
import { getAnalyticsByUser } from "../../../../../utils/socialNetworks";
import InfluencerCategories from "../../../../Common/Influencer/InfluencerCategories";
import Rating from "../../../../Common/Forms/Rating/Rating";
import Button from "../../../../Common/Buttons/Button";
import InitChatButton from "../../../../Common/Chat/InitChatButton";
import { isFilteringInfluencers } from "../../../../../services/shops";
import useCreatorsFilters from "../../../../../hooks/useCreatorsFilters";

const spin = keyframes`
  from {
    background-color: inherit;
  }
  to {
    background-color: rgba(107, 248, 154, 0.5);
  }
`;

const ApplicantsPendingTable = ({
  data,
  dataCampaign,
  onAcceptApplicant,
  onRejectedApplicant,
  onOpenChatApplicant,
  statusCampaign,
  campaignID,
  page,
  onPageChange,
  rowsPerPage,
  applicationID,
  isViewHighlighted,
  filters = {},
  onOpenChatInfluencer,
}) => {
  const [isFilterCampaignActive, setIsFilterCampaignActive] = useState(true);
  const influencers = useSelectorApp((state) => state.influencers.influencers);
  const { onInfluencerPassFilter } = useCreatorsFilters();
  const { isOpen } = useTour();

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
  } = filters;

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
  });
  let {
    platforms = {},
    audienceLocation,
    categoriesInfluencers,
    ageRange,
    quantityFemale,
    quantityMale,
  } = dataCampaign;
  audienceLocation = audienceLocation.map((city) => normalizeString(city));

  const filteredInfluencers = () => {
    const applicants = Object.keys(data);
    if (applicants.length === 0)
      return {
        applicantsFiltered: {},
        applicantsFilteredByCampaign: {},
      };

    const filteredInfluencers = {};
    const filteredInfluencersCampaign = {};

    for (let applicantKey of applicants) {
      const applicant = data[applicantKey];
      const influencer = influencers[applicant.userID] || {};

      const passFilter = onInfluencerPassFilter({
        influencer,
        filters,
        userID: applicant.userID,
      });

      if (!passFilter) continue;

      filteredInfluencers[applicantKey] = applicant;
      if (!platforms[influencer.mainPlatform]) continue;

      const cityInfluencer = normalizeString(getCityInfluencer(influencer));
      if (
        audienceLocation.length > 0 &&
        !audienceLocation.includes(cityInfluencer)
      )
        continue;

      if (
        categoriesInfluencers.length > 0 &&
        !checkArrayIntersection(influencer?.categories, categoriesInfluencers)
      )
        continue;

      if (ageRange) {
        const minAge = ageRange[0];
        const maxAge = ageRange[1];
        const ageRangeInfluencer = influencer.ageRange;
        if (!ageRangeInfluencer) continue;
        const splitAgeRange = ageRangeInfluencer.split("-");
        let stayInAgeRange = false;
        splitAgeRange.forEach((age) => {
          if (age >= minAge && age <= maxAge) stayInAgeRange = true;
        });
        if (!stayInAgeRange) continue;
      }

      if (
        (quantityFemale && !quantityMale && influencer.gender !== "female") ||
        (!quantityFemale && quantityMale && influencer.gender !== "male")
      ) {
        continue;
      }

      filteredInfluencersCampaign[applicantKey] = applicant;
    }
    return {
      applicantsFiltered: filteredInfluencers,
      applicantsFilteredByCampaign: filteredInfluencersCampaign,
    };
  };

  const { applicantsFiltered, applicantsFilteredByCampaign } =
    filteredInfluencers();
  const applicantsFilteredByCampaignKeys = Object.keys(
    applicantsFilteredByCampaign
  );

  useEffect(() => {
    if (applicantsFilteredByCampaignKeys.length < 5)
      setIsFilterCampaignActive(false);
  }, [applicantsFilteredByCampaignKeys]);

  const keysSorted = Object.keys(
    isFilterCampaignActive ? applicantsFilteredByCampaign : applicantsFiltered
  ).sort((a, b) => {
    //order first by applicants stay in applicantsFilteredByCampaignKeys
    if (
      applicantsFilteredByCampaignKeys.includes(a) &&
      !applicantsFilteredByCampaignKeys.includes(b)
    )
      return -1;

    return 1;
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={false}
        emptyText={EMPTY_APPLICANTS_PENDING}
        hasMarginBottom={false}
        isFiltering={isFiltering}
        sx={{ maxHeight: 500, overflow: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CITY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_AUDIENCE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ENGAGEMENT)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CATEGORIES)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SCORE)}
            </TableCell>
            <TableCell align="center" isHeader={true} isSticky={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            const applicant = data[key];
            if (!applicant) return null;
            const influencer = influencers[applicant.userID];
            if (!influencer) return null;
            if (
              dispatch(
                getInfluencerIsHiddenFromTables({ userID: applicant.userID })
              )
            )
              return null;

            return (
              <>
                <TableRow
                  key={key}
                  data-tour={
                    index === 0 &&
                    isOpen &&
                    PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW
                  }
                >
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{
                      animation:
                        key === applicationID &&
                        !isViewHighlighted &&
                        `${spin} 1s infinite ease`,
                      animationDirection: "alternate",
                    }}
                  >
                    <StatusApplicant
                      status={applicant.status}
                      data={applicant}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{
                      animation:
                        key === applicationID &&
                        !isViewHighlighted &&
                        `${spin} 1s infinite ease`,
                      animationDirection: "alternate",
                    }}
                  >
                    <NameInfluencerCell
                      userID={applicant.userID}
                      data={influencer}
                      showButton={false}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{ minWidth: 190 }}
                  >
                    {capitalizeFirstLetter(getCityInfluencer(influencer))}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <SocialNetworkCell
                      platform={influencer.mainPlatform}
                      data={getAnalyticsByUser(influencer)}
                      metric="audience"
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <SocialNetworkCell
                      platform={influencer.mainPlatform}
                      data={getAnalyticsByUser(influencer)}
                      metric="engagement"
                      sign="%"
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{ maxWidth: 200 }}
                  >
                    <InfluencerCategories
                      influencer={influencer}
                      categoriesSelected={categoriesSelected}
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <Rating
                      value={influencer.score}
                      readOnly={true}
                      precision={0.5}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    isSticky={true}
                    sx={{
                      animation:
                        key === applicationID &&
                        !isViewHighlighted &&
                        `${spin} 1s infinite ease`,
                      animationDirection: "alternate",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <ActionsApplicantButtons
                        onAcceptApplicant={onAcceptApplicant}
                        onRejectedApplicant={onRejectedApplicant}
                        onOpenChatApplicant={onOpenChatApplicant}
                        statusCampaign={statusCampaign}
                        data={applicant}
                        applicantID={key}
                      />
                      <InitChatButton
                        onClick={() => onOpenChatInfluencer(applicant.userID)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
                {index === keysSorted.length - 1 && isFilterCampaignActive && (
                  <TableRow>
                    <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                      <Button onClick={() => setIsFilterCampaignActive(false)}>
                        {t(BUTTON_SEE_ALL_APPLICANTS)}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ApplicantsPendingTable;
