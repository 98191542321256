import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "../Buttons/Button";
import {
  BUTTON_SEE_MORE,
  BUTTON_INVITE_CAMPAIGN,
  BUTTON_INVITE_EXCHANGE,
  BUTTON_SEND_PAYMENT,
  DESCRIPTION_INVITE_EXCHANGE,
  DESCRIPTION_INVITE_CAMPAIGN,
  DESCRIPTION_SEND_MESSAGE,
  DESCRIPTION_SEND_PAYMENT,
  BUTTON_EDIT_INVITE,
  BUTTON_ORDER_IN_PROCESS,
  BUTTON_SEND_MESSAGE,
  READY,
  BUTTON_CREATE_CAMPAIGN2,
  ERROR,
  ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ModalInfluencerActions from "./ModalInfluencerActions";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startOpenInfluencerConversation } from "../../../actions/chat";
import ModalInvitation from "../../User/InfluencersListTable/ModalInvitation";
import ModalInviteCampaign from "../Modal/ModalInviteCampaign";
import {
  startGetCampaignsActive,
  startGetInviteInfluencerCampaign,
  startInviteInfluencerCampaign,
} from "../../../actions/campaigns";
import { useNavigate } from "react-router-dom";
import ModalPayInfluencer from "./ModalPayInfluencer";
import Tooltip from "@mui/material/Tooltip";
import { startGetInviteByUserID } from "../../../actions/invites";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  INVITATION_INFLUENCER_MODE_CREATE,
  INVITATION_INFLUENCER_MODE_EDIT,
  INVITATION_INFLUENCER_MODE_INVITE,
  PRE_REGISTERED_INFLUENCER_CHAT_PROCESS,
  PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS,
  PRE_REGISTERED_INFLUENCER_INVITE_PROCESS,
  PRE_REGISTERED_INFLUENCER_PAYMENT_PROCESS,
} from "../../../utils/constants";
import { getUsersHasDelivery } from "../../../actions/deliveries";
import Loader from "../Loader/Loader";
import {
  getInfluencerHasPreRegisteredProcess,
  getInfluencerRedux,
  getIsBlockedActions,
  getIsCampaignModeFeatureFlag,
} from "../../../actions/getters";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SimpleAlert } from "../../../utils/alerts";
import _ from "lodash";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  BUTTON_INVITE_TO_CAMPAIGN as BUTTON_INVITE_TO_CAMPAIGN_TOUR,
  BUTTON_INVITE_TO_EXCHANGE as BUTTON_INVITE_TO_EXCHANGE_TOUR,
  BUTTON_SEND_PAYMENT as BUTTON_SEND_PAYMENT_TOUR,
  BUTTON_SEND_MESSAGE as BUTTON_SEND_MESSAGE_TOUR,
  MODAL_INFLUENCER_ACTIONS_SECTIONS,
} from "../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import LoadingButton from "../Buttons/LoadingButton";

const ButtonsMenu = ({
  onChangeStatusModalAction,
  dataTour,
  sx,
  colorButton = "secondary",
}) => {
  const { t } = useTranslationApp();

  return (
    <Button
      color={colorButton}
      dataTour={dataTour}
      onClick={(e) => {
        e.stopPropagation();
        onChangeStatusModalAction(true);
      }}
      sx={sx}
    >
      {t(BUTTON_SEE_MORE)}
    </Button>
  );
};

const ButtonsActions = ({
  userID,
  sx,
  tooltipPosition,
  showInviteButton,
  onClickInvite,
  showInviteCampaignButton,
  grid = {
    xs: 12,
    sm: 6,
  },
  forcedGrid = false,
  isDisabledCampaignButton,
  showSendMessageButton,
  onOpenChatInfluencer,
  showSendPaymentButton,
  onClickSendPayment,
}) => {
  const [usersWithDelivery, setUsersWithDelivery] = useState([]);
  const [campaigns, setCampaigns] = useState({});
  const [loadingButtonInvite, setLoadingButtonInvite] = useState(true);
  const [loadingButtonInviteCampaign, setLoadingButtonInviteCampaign] =
    useState(false);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const isCampaignMode = dispatch(getIsCampaignModeFeatureFlag());
  const navigate = useNavigate();
  const { isOpen } = useTour();

  const invites = useSelectorApp((state) => state?.invites?.invites);
  const preRegisteredInfluencersProcess = useSelectorApp(
    (state) => state?.influencers?.preRegisteredInfluencersProcess
  );
  const influencer = dispatch(getInfluencerRedux(userID));

  const hasProcessInvite = dispatch(
    getInfluencerHasPreRegisteredProcess({
      mainAccountName: influencer.mainAccountName,
      mainPlatform: influencer.mainPlatform,
      type: PRE_REGISTERED_INFLUENCER_INVITE_PROCESS,
      preRegisteredInfluencersProcess,
    })
  );
  const hasProcessInviteCampaign = dispatch(
    getInfluencerHasPreRegisteredProcess({
      mainAccountName: influencer.mainAccountName,
      mainPlatform: influencer.mainPlatform,
      type: PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS,
      preRegisteredInfluencersProcess,
    })
  );
  const hasProcessSendMessage = dispatch(
    getInfluencerHasPreRegisteredProcess({
      mainAccountName: influencer.mainAccountName,
      mainPlatform: influencer.mainPlatform,
      type: PRE_REGISTERED_INFLUENCER_CHAT_PROCESS,
      preRegisteredInfluencersProcess,
    })
  );
  const hasProcessSendPayment = dispatch(
    getInfluencerHasPreRegisteredProcess({
      mainAccountName: influencer.mainAccountName,
      mainPlatform: influencer.mainPlatform,
      type: PRE_REGISTERED_INFLUENCER_PAYMENT_PROCESS,
      preRegisteredInfluencersProcess,
    })
  );

  const hasAnyProcess =
    hasProcessInvite ||
    hasProcessSendMessage ||
    hasProcessSendPayment ||
    hasProcessInviteCampaign;

  const getInitialData = async () => {
    dispatch(startGetInviteByUserID(userID));
    const disabled = await dispatch(getUsersHasDelivery());
    const campaignsActive = await dispatch(startGetCampaignsActive());
    setLoadingCampaigns(false);
    if (campaignsActive) setCampaigns(campaignsActive);

    setUsersWithDelivery(disabled);
    setLoadingButtonInvite(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const isInvited = Object.keys(invites).find(
    (key) => invites[key].userID === userID
  );
  const hasOrderInProcess = usersWithDelivery.includes(userID);

  const sxTooltip = {
    textAlign: "center",
  };
  const sxContainerButtons = {
    width: "100%",
  };
  const sxGeneralButtons = {
    minWidth: 170,
    width: "100%",
  };

  const getButtonInviteConfigurations = () => {
    if (hasOrderInProcess) {
      return {
        sx: {},
        disabled: true,
        label: BUTTON_ORDER_IN_PROCESS,
      };
    }
    if (isInvited) {
      return {
        sx: {},
        disabled: false,
        label: BUTTON_EDIT_INVITE,
      };
    }
    return {
      sx: {
        background: GRADIENT_PURPLE_FUCHSIA,
        color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
      },
      disabled: false,
      label: BUTTON_INVITE_EXCHANGE,
    };
  };

  const buttonInviteConfiguration = getButtonInviteConfigurations();

  const navigateToCreateCampaign = () =>
    navigate(`/create-campaign?userIDToInvite=${userID}`);

  const onClickButtonInviteCampaign = (event) => {
    if (isOpen) return;
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) return;

    if (_.isEmpty(campaigns)) {
      navigateToCreateCampaign();
    }

    setAnchorEl(event.currentTarget);
  };

  const onInviteCampaign = async (campaignID) => {
    if (isOpen) return;
    setLoadingButtonInviteCampaign(true);
    const hasCurrentInvite = await dispatch(
      startGetInviteInfluencerCampaign({
        campaignID,
        userID,
      })
    );

    if (hasCurrentInvite) {
      setLoadingButtonInviteCampaign(false);
      setAnchorEl(null);
      SimpleAlert({
        title: t(ERROR),
        text: t(ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return;
    }

    const response = await dispatch(
      startInviteInfluencerCampaign({
        campaignID,
        userID,
      })
    );
    setLoadingButtonInviteCampaign(false);
    if (response) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const gridColumns = (from) => {
    if (forcedGrid) {
      return grid;
    }
    if (from === "exchange" && !showInviteCampaignButton) {
      return {
        xs: 12,
      };
    }

    if (from === "campaign" && (isCampaignMode || !showInviteButton)) {
      return {
        xs: 12,
      };
    }
    return grid;
  };
  return (
    <Grid
      container
      spacing={1}
      sx={sx}
      data-tour={MODAL_INFLUENCER_ACTIONS_SECTIONS}
    >
      <Grid item {...gridColumns("campaign")}>
        {showInviteCampaignButton && (
          <>
            {loadingCampaigns && <Loader hasMarginTop={false} />}
            {!loadingCampaigns && (
              <Tooltip
                PopperProps={{
                  sx: sxTooltip,
                }}
                title={t(DESCRIPTION_INVITE_CAMPAIGN)}
                arrow
                placement={tooltipPosition || "left"}
              >
                <Box sx={{ ...sxContainerButtons }}>
                  <LoadingButton
                    loading={loadingButtonInviteCampaign}
                    sx={{
                      background: GRADIENT_PURPLE_FUCHSIA,
                      color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                      ...sxGeneralButtons,
                    }}
                    disabled={isDisabledCampaignButton || hasAnyProcess}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickButtonInviteCampaign(e);
                    }}
                    dataTour={BUTTON_INVITE_TO_CAMPAIGN_TOUR}
                  >
                    {t(BUTTON_INVITE_CAMPAIGN)}
                  </LoadingButton>
                </Box>
              </Tooltip>
            )}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: 36 * 4.5,
                },
              }}
            >
              {Object.keys(campaigns).map((campaignID) => (
                <MenuItem
                  key={campaignID}
                  onClick={() => {
                    onInviteCampaign(campaignID);
                    setAnchorEl(null);
                  }}
                >
                  {campaigns[campaignID].name}
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => navigateToCreateCampaign()}
                sx={{
                  fontWeight: 600,
                  gap: 1,
                }}
              >
                <AddCircleIcon sx={{ ml: "-4px" }} />
                {t(BUTTON_CREATE_CAMPAIGN2)}
              </MenuItem>
            </Menu>
          </>
        )}
      </Grid>
      {!isCampaignMode && (
        <Grid item {...gridColumns("exchange")}>
          {showInviteButton && (
            <>
              {loadingButtonInvite && <Loader hasMarginTop={false} />}
              {!loadingButtonInvite && (
                <Tooltip
                  PopperProps={{
                    sx: sxTooltip,
                  }}
                  title={t(DESCRIPTION_INVITE_EXCHANGE)}
                  arrow
                  placement={tooltipPosition || "right"}
                >
                  <Box sx={{ ...sxContainerButtons }}>
                    <Button
                      sx={{
                        ...buttonInviteConfiguration.sx,
                        ...sxGeneralButtons,
                      }}
                      disabled={
                        buttonInviteConfiguration.disabled || hasAnyProcess
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isOpen) return;
                        onClickInvite();
                      }}
                      dataTour={BUTTON_INVITE_TO_EXCHANGE_TOUR}
                    >
                      {t(buttonInviteConfiguration.label)}
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </>
          )}
        </Grid>
      )}
      <Grid item {...gridColumns("payment")}>
        {showSendPaymentButton && (
          <Tooltip
            PopperProps={{
              sx: sxTooltip,
            }}
            title={t(DESCRIPTION_SEND_PAYMENT)}
            arrow
            placement={tooltipPosition || "left"}
          >
            <Box sx={{ ...sxContainerButtons }}>
              <Button
                disabled={hasAnyProcess}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isOpen) return;
                  onClickSendPayment();
                }}
                sx={{ ...sxGeneralButtons }}
                dataTour={BUTTON_SEND_PAYMENT_TOUR}
              >
                {t(BUTTON_SEND_PAYMENT)}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Grid>
      <Grid item {...gridColumns("chat")}>
        {showSendMessageButton && (
          <Tooltip
            PopperProps={{
              sx: sxTooltip,
            }}
            title={t(DESCRIPTION_SEND_MESSAGE)}
            arrow
            placement={tooltipPosition || "right"}
          >
            <Box sx={{ ...sxContainerButtons }}>
              <Button
                disabled={hasAnyProcess}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isOpen) return;
                  onOpenChatInfluencer();
                }}
                sx={{
                  ...sxGeneralButtons,
                }}
                dataTour={BUTTON_SEND_MESSAGE_TOUR}
              >
                {t(BUTTON_SEND_MESSAGE)}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};
const ButtonInfluencerActions = forwardRef((props, ref) => {
  const {
    userID,
    dataTour,
    influencer,
    mode = "menu",
    sx,
    grid,
    colorButton = "secondary",
    tooltipPosition,
    showInviteButton = true,
    showInviteCampaignButton = true,
    showSendMessageButton = true,
    showSendPaymentButton = true,
    forcedGrid = false,
    additionalDataCreateInvite = {},
    onClick = () => {},
    onCloseModal = () => {},
  } = props;

  const [modalOpenActions, setModalOpenActions] = useState(false);
  const [modalInvitationOpen, setModalInvitationOpen] = useState(false);
  const [modalInvitationCampaignOpen, setModalInvitationCampaignOpen] =
    useState(false);
  const [modalSendPayment, setModalSendPayment] = useState(false);

  const dispatch = useDispatchApp();

  const invites = useSelectorApp((state) => state?.invites?.invites);

  const inviteID = Object.keys(invites).find(
    (key) => invites[key].userID === userID
  );

  useImperativeHandle(ref, () => ({
    openModalActions: () => {
      setModalOpenActions(true);
      onClick();
    },
  }));

  const onClickInvite = async () => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedFreeTrial: true,
        verifyBlockedExchange: true,
      })
    );
    if (isBlocked) return;

    setModalInvitationOpen(true);
  };

  const onClickSendPayment = () => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) return;

    setModalSendPayment(true);
  };

  const onOpenChatInfluencer = async () => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedLastInvite: true,
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) return;
    await dispatch(
      startOpenInfluencerConversation({
        userID,
      })
    );
    //
    //setModalOpenActions(false);
    //For some reason setState to false to close the actions modal does not work, this temporary solution was implemented to close the modal:
    const buttonHtml = document.getElementsByClassName("close-modal-button")[0];
    buttonHtml?.click();
  };

  return (
    <>
      {mode === "menu" && (
        <ButtonsMenu
          userID={userID}
          dataTour={dataTour}
          influencer={influencer}
          onChangeStatusModalAction={(status) => {
            setModalOpenActions(status);
            if (status) onClick();
          }}
          sx={sx}
          forcedGrid={forcedGrid}
          showInviteButton={showInviteButton}
          onClickInvite={onClickInvite}
          showInviteCampaignButton={showInviteCampaignButton}
          showSendMessageButton={showSendMessageButton}
          onOpenChatInfluencer={onOpenChatInfluencer}
          showSendPaymentButton={showSendPaymentButton}
          onClickSendPayment={onClickSendPayment}
          colorButton={colorButton}
        />
      )}
      {mode === "buttons" && (
        <ButtonsActions
          userID={userID}
          influencer={influencer}
          onChangeStatusModalAction={setModalOpenActions}
          sx={sx}
          grid={grid}
          forcedGrid={forcedGrid}
          tooltipPosition={tooltipPosition}
          showInviteButton={showInviteButton}
          onClickInvite={onClickInvite}
          showInviteCampaignButton={showInviteCampaignButton}
          showSendMessageButton={showSendMessageButton}
          onOpenChatInfluencer={onOpenChatInfluencer}
          showSendPaymentButton={showSendPaymentButton}
          onClickSendPayment={onClickSendPayment}
        />
      )}
      {modalOpenActions && (
        <ModalInfluencerActions
          userID={userID}
          influencer={influencer}
          modalOpen={modalOpenActions}
          onCloseModal={() => {
            setModalOpenActions(false);
            onCloseModal();
          }}
          sx={sx}
          grid={grid}
          forcedGrid={forcedGrid}
          colorButton={colorButton}
          tooltipPosition={tooltipPosition}
          showInviteButton={showInviteButton}
          showInviteCampaignButton={showInviteCampaignButton}
          showSendMessageButton={showSendMessageButton}
          showSendPaymentButton={showSendPaymentButton}
          additionalDataCreateInvite={additionalDataCreateInvite}
        />
      )}
      {modalInvitationOpen && (
        <ModalInvitation
          userID={userID}
          dataInvite={
            inviteID
              ? {
                  ...invites[inviteID],
                  code: invites[inviteID].inviteCode,
                  inviteID: inviteID,
                }
              : {}
          }
          from={INVITATION_INFLUENCER_MODE_INVITE}
          mode={
            inviteID
              ? INVITATION_INFLUENCER_MODE_EDIT
              : INVITATION_INFLUENCER_MODE_CREATE
          }
          dataInfluencer={influencer}
          modalOpen={modalInvitationOpen}
          onCloseModal={() => setModalInvitationOpen(false)}
          additionalDataCreateInvite={additionalDataCreateInvite}
        />
      )}
      {modalInvitationCampaignOpen && (
        <ModalInviteCampaign
          modalOpen={modalInvitationCampaignOpen}
          onCloseModal={() => setModalInvitationCampaignOpen(false)}
          userID={userID}
          influencer={influencer}
        />
      )}
      {modalSendPayment && (
        <ModalPayInfluencer
          modalOpen={modalSendPayment}
          onCloseModal={() => setModalSendPayment(false)}
          userID={userID}
          influencer={influencer}
        />
      )}
    </>
  );
});

export default ButtonInfluencerActions;
