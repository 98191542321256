import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  FIELD_PHONE_VALID,
  FIELD_REQUIRED,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import { useLocales } from "../../../Contexts/LocaleContext";

const PhoneTextField = ({
  control,
  rules = {
    validate: ({ value, info }) => {
      const countryCallingCode = info?.countryCallingCode;
      const phoneFormatted = value
        ?.replace(`+${countryCallingCode}`, "")
        ?.replace(/ /g, "");
      if (!phoneFormatted) {
        return i18next.t(FIELD_REQUIRED);
      }
      if (!matchIsValidTel(value)) {
        return i18next.t(FIELD_PHONE_VALID);
      }
    },
  },
  errors = {},
  label = "",
  variant = "standard",
  name = "",
  value = "",
  fullWidth = false,
  multiline = false,
  minRows,
  maxRows,
  helperText,
  onKeyDown,
  InputProps,
  disabled = false,
  onChange = () => {},
  onClick = () => {},
  actionsChange = () => {},
  doOnChange = true,
  useValue = true,
  sx,
  placeholder = "",
  autoFocus = false,
  dataTour = "",
  defaultCountry = "co",
  onlyCountries = ["co"],
  disableDropdown = true,
  withoutController = false,
}) => {
  const LOCALES = useLocales();
  const [currentDefaultCountry, setCurrentDefaultCountry] =
    useState(defaultCountry);
  const [currentOnlyCountries, setCurrentOnlyCountries] =
    useState(onlyCountries);

  useEffect(() => {
    const LOCALE = LOCALES[defaultCountry] || {};
    const isDemoCountry = LOCALE?.isDemoCountry;

    if (isDemoCountry) {
      const newsCurrentOnlyCountries = onlyCountries?.map(
        (c) => LOCALES[c].codeDemo
      );
      setCurrentDefaultCountry(LOCALE.codeDemo);
      setCurrentOnlyCountries(newsCurrentOnlyCountries);
    }
    // eslint-disable-next-line
  }, [defaultCountry, onlyCountries]);

  if (
    currentDefaultCountry.length > 2 ||
    currentOnlyCountries.some((c) => c.length > 2)
  ) {
    return null;
  }

  const conditionalProps = {};
  if (currentOnlyCountries.length > 0) {
    conditionalProps.onlyCountries = currentOnlyCountries.map((c) =>
      c.toUpperCase()
    );
  }

  if (withoutController) {
    return (
      <MuiTelInput
        {...conditionalProps}
        forceCallingCode={true}
        disableDropdown={disableDropdown}
        defaultCountry={currentDefaultCountry.toUpperCase()}
        data-tour={dataTour}
        autoFocus={autoFocus}
        label={label}
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        fullWidth={fullWidth}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        name={name}
        error={!!errors[name]}
        helperText={!!errors[name] ? errors[name]?.message : helperText}
        onKeyDown={onKeyDown}
        InputProps={InputProps}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 68 * 4.5 + 8 * 2,
            },
          },
        }}
        onChange={(value, info) => {
          if (doOnChange) {
            onChange({ value, info });
          }
          actionsChange(value);
        }}
        value={value}
        sx={{
          ...sx,
          ".MuiFormHelperText-root": {
            ml: 0,
          },
        }}
      />
    );
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <MuiTelInput
          {...conditionalProps}
          forceCallingCode={true}
          disableDropdown={disableDropdown}
          defaultCountry={currentDefaultCountry.toUpperCase()}
          data-tour={dataTour}
          autoFocus={autoFocus}
          label={label}
          onClick={onClick}
          disabled={disabled}
          variant={variant}
          fullWidth={fullWidth}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          error={!!errors[name]}
          helperText={!!errors[name] ? errors[name]?.message : helperText}
          onKeyDown={onKeyDown}
          InputProps={InputProps}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 68 * 4.5 + 8 * 2,
              },
            },
          }}
          {...field}
          onChange={(value, info) => {
            if (doOnChange) {
              field.onChange({ value, info });
            }
            actionsChange(value);
          }}
          value={useValue ? field?.value?.value : value}
          sx={{
            ...sx,
            ".MuiFormHelperText-root": {
              ml: 0,
            },
          }}
        />
      )}
    />
  );
};

export default PhoneTextField;
