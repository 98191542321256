import React, { useEffect, useState } from "react";
import TextFieldMUI from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../locales/keysTranslations";
import LoadingButton from "../Buttons/LoadingButton";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
  GRAY_DARK_THEME_COLOR,
} from "../../../utils/colors";
import { useTranslationApp } from "../../../lib/i18next";
import Typography from "@mui/material/Typography";

const TextField = ({
  control,
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  errors = {},
  label = "",
  variant = "standard",
  name = "",
  value = "",
  fullWidth = false,
  multiline = false,
  minRows,
  maxRows,
  helperText,
  onKeyDown,
  InputProps,
  disabled = false,
  onClick = () => {},
  actionsChange = () => {},
  doOnChange = true,
  useValue = true,
  sx,
  placeholder = "",
  autoFocus = false,
  dataTour = "",
  type = "text",
  color = "primary",
  showCounterCharacters = false,
  maxCharacters = 0,
  InputLabelProps = {},
  className = "",
  hasButtonGenerate = false,
  useGradientButton = true,
  propsButtonGenerate = {},
  alignButtonGenerate = "top-right",
}) => {
  const [lengthValue, setLengthValue] = useState(value.length);

  const horizontalPosition = alignButtonGenerate.split("-")[1];
  const verticalPosition = alignButtonGenerate.split("-")[0];
  const alignItems = horizontalPosition === "right" ? "flex-end" : "flex-start";

  const hiddenLabel = variant === "filled" && !label;

  const { t } = useTranslationApp();

  const onGetSxButtonGenerate = () => {
    if (
      alignButtonGenerate === "top-right" ||
      alignButtonGenerate === "top-left"
    ) {
      const sxBase = {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      };
      if (alignButtonGenerate === "top-right") {
        return {
          ...sxBase,
          mr: 1,
        };
      } else {
        return {
          ...sxBase,
          ml: 1,
        };
      }
    }
    if (
      alignButtonGenerate === "bottom-right" ||
      alignButtonGenerate === "bottom-left"
    ) {
      const sxBase = {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      };
      if (alignButtonGenerate === "bottom-right") {
        return {
          ...sxBase,
          mr: 1,
        };
      } else {
        return {
          ...sxBase,
          ml: 1,
        };
      }
    }
  };

  const sxButtonGenerate = onGetSxButtonGenerate();
  const sxGradient = useGradientButton
    ? {
        background: GRADIENT_PURPLE_FUCHSIA,
        color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
        "&.Mui-disabled": {
          backgroundColor: GRAY_DARK_THEME_COLOR,
        },
      }
    : {};
  const hasHelperText =
    errors[name]?.message || helperText || showCounterCharacters;

  useEffect(() => {
    setLengthValue(value.length);
  }, [value]);

  if (hasButtonGenerate) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems,
          width: "100%",
        }}
      >
        {verticalPosition === "top" && (
          <LoadingButton
            disabled={disabled}
            startIcon={<AutoAwesomeIcon />}
            size="small"
            sx={{
              ...sxButtonGenerate,
              ...sxGradient,
            }}
            type="button"
            // disabled={disabled}
            loading={propsButtonGenerate.loading}
            onClick={propsButtonGenerate.onClick}
          >
            {t(propsButtonGenerate.text)}
          </LoadingButton>
        )}
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={value}
          render={({ field }) => (
            <TextFieldMUI
              hiddenLabel={hiddenLabel}
              className={className}
              color={color}
              data-tour={dataTour}
              autoFocus={autoFocus}
              label={label || ""}
              onClick={onClick}
              disabled={disabled}
              variant={variant}
              fullWidth={fullWidth}
              type={type}
              multiline={multiline}
              minRows={minRows}
              maxRows={maxRows}
              placeholder={placeholder}
              error={!!errors[name]}
              onKeyDown={onKeyDown}
              InputProps={InputProps}
              {...field}
              inputRef={field.ref}
              onChange={(e) => {
                if (doOnChange) {
                  field.onChange(e);
                }
                actionsChange(e?.target?.value);
                setLengthValue(e?.target?.value?.length);
              }}
              value={useValue ? field.value : value}
              sx={{
                ...sx,
                ".MuiFormHelperText-root": {
                  mx: 0,
                },
                ".MuiInputLabel-root": {
                  whiteSpace:
                    multiline && !(field.value || value) ? "normal" : "nowrap",
                  ".Mui-focused": {
                    whiteSpace: "nowrap",
                  },
                },
              }}
              InputLabelProps={InputLabelProps}
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection:
              horizontalPosition === "right" ? "row-reverse" : "row",
            width: "100%",
          }}
        >
          {verticalPosition === "bottom" && (
            <LoadingButton
              disabled={disabled}
              startIcon={<AutoAwesomeIcon />}
              size="small"
              sx={{
                ...sxButtonGenerate,
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                "&.Mui-disabled": {
                  backgroundColor: GRAY_DARK_THEME_COLOR,
                },
              }}
              type="button"
              loading={propsButtonGenerate.loading}
              onClick={propsButtonGenerate.onClick}
            >
              {t(propsButtonGenerate.text)}
            </LoadingButton>
          )}
          {hasHelperText && (
            <Box
              component="span"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {(errors[name]?.message || helperText) && (
                <Typography variant="caption" color="error">
                  {!!errors[name] ? errors[name]?.message : helperText}
                </Typography>
              )}
              {showCounterCharacters && (
                <Typography variant="caption">
                  {lengthValue}/{maxCharacters}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <TextFieldMUI
          hiddenLabel={hiddenLabel}
          className={className}
          color={color}
          data-tour={dataTour}
          autoFocus={autoFocus}
          label={label || ""}
          onClick={onClick}
          disabled={disabled}
          variant={variant}
          fullWidth={fullWidth}
          type={type}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          error={!!errors[name]}
          helperText={
            <Box
              component="span"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <span>{!!errors[name] ? errors[name]?.message : helperText}</span>
              {showCounterCharacters && (
                <span>
                  {field.value.length}/{maxCharacters}
                </span>
              )}
            </Box>
          }
          onKeyDown={onKeyDown}
          InputProps={InputProps}
          {...field}
          inputRef={field.ref}
          onChange={(e) => {
            if (doOnChange) {
              field.onChange(e);
            }
            actionsChange(e.target.value);
          }}
          value={useValue ? field.value : value}
          sx={{
            ...sx,
            ".MuiFormHelperText-root": {
              mx: 0,
            },
            ".MuiFormLabel-root": {
              whiteSpace:
                multiline && !(field.value || value) ? "normal" : "nowrap",
              "&.Mui-focused": {
                whiteSpace: "nowrap",
              },
            },
          }}
          InputLabelProps={InputLabelProps}
        />
      )}
    />
  );
};

export default TextField;
