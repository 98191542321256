import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { setOpenSlideMenu } from "../../../../actions/ui";
import SlideMenu from "../SlideMenu/SlideMenu";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { startLogout } from "../../../../actions/auth";
import Button from "../../Buttons/Button";
import SwitchShop from "./SwitchShop";
import { startGetLogoShop } from "../../../../actions/shops";
import { RED_COLOR } from "../../../../utils/colors";
import Typography from "@mui/material/Typography";
import {
  ADMIN_ROLE,
  DRAWER_WIDTH,
  OPERATOR_ROLE,
  OWNER_ROLE,
  SUPER_ADMIN_ROLE,
} from "../../../../utils/constants";
import ButtonHelpVideo from "../../Buttons/ButtonHelpVideo";
import { LOGOUT, NOTIFICATIONS } from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import SelectCountry from "../../Selects/SelectCountry";
import { useForm } from "react-hook-form";
const LogoShop = ({ logo }) => {
  return (
    <Avatar
      src={logo}
      alt="Logo Marca"
      variant="rounded"
      sx={{
        width: 42,
        height: 42,
        mt: "2px",
        mr: "4px",
        ".MuiAvatar-img": { objectFit: "cover" },
      }}
    />
  );
};

const NavBar = () => {
  const [logo, setLogo] = useState(null);
  const [notificationsUnRead, setNotificationsUnRead] = useState(0);
  const { slideMenuIsOpen } = useSelectorApp((state) => state.ui);
  const { user } = useSelectorApp((state) => state.auth);
  const { notifications, isLoading } = useSelectorApp(
    (state) => state.notifications
  );
  const currencyShop = useSelectorApp((state) => state.shop.currency);

  const {
    user: { role },
  } = useSelectorApp((state) => state.auth);
  const { control } = useForm({
    defaultValues: {
      country: "",
    },
  });

  const dispatch = useDispatchApp();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { t } = useTranslationApp();

  const onDrawerToggle = () => {
    dispatch(setOpenSlideMenu(!slideMenuIsOpen));
  };

  const onLogout = () => {
    dispatch(startLogout());
  };

  useEffect(() => {
    (async () => {
      if (role !== OWNER_ROLE) {
        const logoShop = await dispatch(startGetLogoShop());
        setLogo(logoShop);
      }
    })();
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    let notificationsCount = 0;
    Object.keys(notifications).forEach((notificationKey) => {
      if (!notifications[notificationKey].isRead) return notificationsCount++;
    });
    setNotificationsUnRead(notificationsCount);
  }, [notifications]);

  const isAdminUser = role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE;
  const isOwner = role === OWNER_ROLE;
  const isOperator = role === OPERATOR_ROLE;

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          backgroundImage: "none",
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onDrawerToggle}
              sx={{
                p: 0,
                pl: 0.6,
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box
            className="buttons-nav"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            {isAdminUser && (
              <>
                {!isMobile && (
                  <SelectCountry
                    showDemoCountries={true}
                    doChangeCustomClaim={true}
                    label=""
                    name="country"
                    size="small"
                    control={control}
                    variant="standard"
                    fullWidth
                    useUserValue={true}
                    disabled={Boolean(currencyShop)}
                    labelInput="name"
                    valueFrom="shop"
                    sx={{
                      px: 2,
                      width: 160,
                      ".MuiFormControl-root": {
                        ".MuiInputBase-root": {
                          pt: "0 !important",
                          pr: "18px !important",
                          ".MuiSelect-select": {
                            backgroundColor: "transparent",
                          },
                          borderBottom: "none !important",
                          "::before": {
                            borderBottom: "none !important",
                          },
                          "::after": {
                            borderBottom: "none !important",
                          },
                          "&.Mui-focused": {
                            borderBottom: "none !important",
                          },
                        },
                      },
                    }}
                    sxInput={{
                      ".MuiInputBase-root": {
                        minHeight: 56,
                      },
                    }}
                  />
                )}
                {!isMobile && <ButtonHelpVideo />}
                <SwitchShop onSlideMenu={false} />
              </>
            )}
            {!isOwner && !isOperator && !isLoading && !isMobile && (
              <Link
                to="/notifications"
                style={{ textDecoration: "none", color: "white" }}
              >
                <Button
                  color="secondary"
                  hasShadow={false}
                  sx={{
                    height: 42,
                    maxHeight: 42,
                    backgroundColor: "background.paper",
                    color: "background.paperContrastText",

                    "&:hover": {
                      backgroundColor: "background.paper",
                      color: "background.paperContrastText",

                      filter: "brightness(0.95)",
                    },
                  }}
                >
                  {t(NOTIFICATIONS)}
                  {notificationsUnRead > 0 && (
                    <Typography
                      variant="caption"
                      sx={{
                        pt: "2px",
                        pr: "2px",
                        minWidth: 23,
                        height: 23,
                        backgroundColor: RED_COLOR,
                        borderRadius: 4,
                        position: "absolute",
                        top: 0,
                        right: 0,
                        transform: "scale(1) translate(38%, -15%)",
                        color: "white !important",
                      }}
                    >
                      {notificationsUnRead > 99 ? "+99" : notificationsUnRead}
                    </Typography>
                  )}
                </Button>
              </Link>
            )}
            {!isMobile && (
              <Button
                color="secondary"
                hasShadow={false}
                sx={{
                  height: 42,
                  maxHeight: 42,
                  backgroundColor: "background.paper",
                  color: "background.paperContrastText",
                  "&:hover": {
                    backgroundColor: "background.paper",
                    color: "background.paperContrastText",
                    filter: "brightness(0.95)",
                  },
                }}
                onClick={onLogout}
              >
                {t(LOGOUT)}
              </Button>
            )}
            {!!logo && !isMobile && isAdminUser ? (
              <>
                <Link to="/account">
                  <LogoShop logo={logo} />
                </Link>
              </>
            ) : (
              !!logo && isOperator && <LogoShop logo={logo} />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        container={window.document.body
          .querySelector("#root")
          .querySelector("div")}
        variant="temporary"
        open={slideMenuIsOpen}
        onClose={onDrawerToggle}
        anchor="left"
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            backgroundColor: "background.paper",
            color: "background.paperContrastText",
            backgroundImage: "none",
            "&.MuiPaper-root": {
              overflowX: "hidden",
              "::-webkit-scrollbar": {
                display: "none",
              },
            },
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SlideMenu onDrawerToggle={onDrawerToggle} isMobile={true} />
      </Drawer>
    </React.Fragment>
  );
};

export default NavBar;
