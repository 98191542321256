import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { numberCompactFormat } from "../../../../../utils/numberFormats";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  AUDIENCE_BY_SIMILAR_AUDIENCE,
  AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND,
  FOLLOWERS,
  PROFILE,
} from "../../../../../locales/keysTranslations";
import Loader from "../../../Loader/Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinkText from "../../../Texts/LinkText";

const InfluencerAudienceLookAtLikes = ({ data = {}, isLoading }) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (data.length === 0) return null;

  const keys = Object.keys(data);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
        {t(AUDIENCE_BY_SIMILAR_AUDIENCE)}
      </Typography>
      {isLoading && <Loader />}
      {!isLoading && keys.length === 0 && (
        <Typography variant="body1">
          {t(AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND)}
        </Typography>
      )}
      {!isLoading && keys.length >= 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: -1 }}
            >
              <Typography variant="subtitle1">{t(PROFILE)}</Typography>
              <Box>
                <Typography variant="subtitle1">{t(FOLLOWERS)}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            sm={6}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: -1 }}
            >
              <Typography variant="subtitle1">{t(PROFILE)}</Typography>
              <Box>
                <Typography variant="subtitle1">{t(FOLLOWERS)}</Typography>
              </Box>
            </Box>
          </Grid>
          {Object.keys(data).map((key, index) => {
            const influencer = data[key];
            if (index < 10) {
              return (
                <Grid item xs={12} sm={6} key={key}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <LinkText
                      href={`/influencer?userID=${key}`}
                      target="_blank"
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {influencer?.imageURL && (
                          <Avatar src={influencer?.imageURL} />
                        )}
                        <Typography variant={isMobile ? "body1" : "h6"}>
                          {influencer.mainAccountName}
                        </Typography>
                      </Box>
                    </LinkText>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h6">
                        {numberCompactFormat(influencer.followers)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      )}
    </Box>
  );
};

export default InfluencerAudienceLookAtLikes;
