import { DUMMY_INVITE } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  invites: {},
  invitesInactive: {},
  eventInvites: {},
  isLoading: true,
};

export const invitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVITES_BY_SHOP_FINISH:
      return {
        ...state,
        invites: action.payload,
        isLoading: false,
      };
    case types.GET_EVENT_INVITES_BY_SHOP_FINISH:
      return {
        ...state,
        eventInvites: action.payload,
        isLoading: false,
      };
    case types.GET_INVITES_INACTIVE_BY_SHOP_FINISH:
      return {
        ...state,
        invitesInactive: action.payload,
        isLoading: false,
      };
    case types.GET_INVITES_BY_SHOP_PAGINATION:
      return {
        ...state,
        invites: { ...state.invites, ...action.payload },
        isLoading: false,
      };
    case types.GET_EVENT_INVITES_BY_SHOP_PAGINATION:
      return {
        ...state,
        eventInvites: { ...state.eventInvites, ...action.payload },
        isLoading: false,
      };
    case types.GET_INVITES_INACTIVE_BY_SHOP_PAGINATION:
      return {
        ...state,
        invitesInactive: { ...state.invitesInactive, ...action.payload },
        isLoading: false,
      };
    case types.INVITE_INFLUENCER:
      return {
        ...state,
        invites: {
          ...state.invites,
          ...action.payload,
        },
      };
    case types.INVITE_MULTIPLE_INFLUENCERS:
      return {
        ...state,
        invites: {
          ...state.invites,
          ...action.payload,
        },
      };
    case types.INVITE_EVENT_INFLUENCER:
      return {
        ...state,
        eventInvites: {
          ...state.eventInvites,
          ...action.payload,
        },
      };
    case types.ADD_INVITE_INACTIVE:
      return {
        ...state,
        invitesInactive: {
          ...state.invitesInactive,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.EDIT_INVITE:
      return {
        ...state,
        invites: {
          ...state.invites,
          [action.payload.key]: {
            ...state.invites[action.payload.key],
            ...action.payload.data,
          },
        },
      };
    case types.EDIT_EVENT_INVITE:
      return {
        ...state,
        eventInvites: {
          ...state.eventInvites,
          [action.payload.key]: {
            ...state.eventInvites[action.payload.key],
            ...action.payload.data,
          },
        },
      };
    case types.DELETE_INVITE:
      const { [action.payload]: inviteToDelete, ...updatedInvites } =
        state.invites;
      return {
        ...state,
        invites: updatedInvites,
        invitesInactive: {
          ...state.invitesInactive,
          [action.payload]: inviteToDelete,
        },
      };
    case types.DELETE_EVENT_INVITE:
      const { [action.payload]: inviteEventToDelete, ...updatedInvitesEvent } =
        state.eventInvites;
      return {
        ...state,
        eventInvites: updatedInvitesEvent,
        invitesInactive: {
          ...state.invitesInactive,
          [action.payload]: inviteEventToDelete,
        },
      };
    case types.LOADING_INVITES_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_INVITE]: inviteDelete, ...invites } = state.invites;
      const { [DUMMY_INVITE]: inviteInactiveDelete, ...invitesInactive } =
        state.invitesInactive;
      return {
        ...state,
        invites: invites,
        invitesInactive: invitesInactive,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
