import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RED_COLOR, GREEN_COLOR } from "../../../utils/colors";
import TableCell from "../../Common/Table/TableCell";
import TableContainer from "../../Common/Table/TableContainer";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import {
  PAGE_INVITATIONS_INACTIVE_TABLE,
  PAGE_INVITATIONS_INACTIVE_TABLE_CODE,
  PAGE_INVITATIONS_INACTIVE_TABLE_DATE,
  PAGE_INVITATIONS_INACTIVE_TABLE_REASON,
  PAGE_INVITATIONS_INACTIVE_TABLE_TYPE,
  PAGE_INVITATIONS_INACTIVE_TABLE_USER,
  PAGE_INVITATIONS_INACTIVE_TABLE_VALUE,
} from "../../../onboarding/stepsSelectors";
import { validateIsValidDate } from "../../../utils/dates";
import {
  BUTTON_VIEW_INVITE,
  COLUMN_NAME_CREATOR_NAME,
  DATE_SHORT,
  INVITATIONS_INACTIVE_TABLE_ARIA_LABEL,
  COLUMN_NAME_CODE_INVITATION,
  COLUMN_NAME_DATE,
  COLUMN_NAME_REJECTED_REASON_INVITATION,
  COLUMN_NAME_TYPE_INVITATION,
  COLUMN_NAME_VALUE_INVITATION,
  INVITATION_EXPIRED,
  INVITATION_REDEEMED,
  INVITATION_REJECTED,
  VALUE_WITH_CURRENCY,
  EMPTY_INVITATIONS_INACTIVE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ButtonCopy from "../../Common/Buttons/ButtonCopy";
import {
  getCurrencyObject,
  getInfluencerIsHiddenFromTables,
} from "../../../actions/getters";

const InvitationsInactiveTable = ({
  data,
  loading,
  rowsPerPage,
  page,
  onPageChange,
  onSelectInvitation,
  filter,
}) => {
  const { influencers } = useSelectorApp((state) => state.influencers);

  const keys = Object.keys(data);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const currentDataPage = keys.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const getDataFiltered = () => {
    const filtered = {};
    if (!filter) return data;
    currentDataPage.forEach((key) => {
      if (filter === "redeemed" && data[key].redemptionDate)
        return (filtered[key] = data[key]);
      if (filter === "expired" && !data[key].redemptionDate)
        return (filtered[key] = data[key]);
    });
    return filtered;
  };

  const keysFiltered = Object.keys(getDataFiltered());

  const keysSorted = keysFiltered.sort(
    (a, b) => data[b].creationTime - data[a].creationTime
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={loading}
        emptyText={EMPTY_INVITATIONS_INACTIVE}
        ariaLabel={t(INVITATIONS_INACTIVE_TABLE_ARIA_LABEL)}
        additionalValidationNext={currentDataPage.length === 0}
      >
        <TableHead>
          <TableRow data-tour={PAGE_INVITATIONS_INACTIVE_TABLE}>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_INACTIVE_TABLE_USER}
            >
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_INACTIVE_TABLE_CODE}
            >
              {t(COLUMN_NAME_CODE_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_INACTIVE_TABLE_VALUE}
            >
              {t(COLUMN_NAME_VALUE_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_INACTIVE_TABLE_TYPE}
            >
              {t(COLUMN_NAME_TYPE_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_INACTIVE_TABLE_REASON}
            >
              {t(COLUMN_NAME_REJECTED_REASON_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_INACTIVE_TABLE_DATE}
            >
              {t(COLUMN_NAME_DATE)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? currentDataPage : currentDataPage).map(
            (key, index) => {
              if (
                dispatch(
                  getInfluencerIsHiddenFromTables({
                    userID: data[key]?.userID,
                    validateIsDiscarded: false,
                  })
                )
              )
                return null;

              if (filter === "redeemed" && !data[key].redemptionDate)
                return null;
              if (filter === "expired" && data[key].redemptionDate) return null;

              return (
                <TableRow key={key}>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{ minWidth: 190 }}
                  >
                    <NameInfluencerCell
                      userID={data[key].userID}
                      data={influencers[data[key].userID]}
                      name={influencers[data[key].userID]?.mainAccountName}
                      isInvited={true}
                      color="secondary"
                      textButtonInvited={t(BUTTON_VIEW_INVITE)}
                      onClickButton={() => onSelectInvitation(key, "inactive")}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{ minWidth: 190 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {data[key].inviteCode}
                      <ButtonCopy value={data[key].inviteCode} />
                    </Box>
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: data[key].value,
                      currency: dispatch(getCurrencyObject(data[key].currency)),
                    })}
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{
                      color: data[key].redemptionDate ? GREEN_COLOR : RED_COLOR,
                    }}
                  >
                    {data[key].redemptionDate
                      ? t(INVITATION_REDEEMED)
                      : data[key]?.isRejected
                      ? t(INVITATION_REJECTED)
                      : t(INVITATION_EXPIRED)}
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{ minWidth: 150, maxWidth: 200 }}
                  >
                    {data[key]?.isRejected ? data[key]?.reason : "-"}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {validateIsValidDate(data[key].creationTime) &&
                      t(DATE_SHORT, {
                        date: new Date(data[key].creationTime),
                      })}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default InvitationsInactiveTable;
