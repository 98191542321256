import React from "react";
import Grid from "@mui/material/Grid";
import ProfileInfluencerFormCreator from "./ProfileInfluencerFormCreator";
import ProfileInfluencerFormReferenceProfiles from "./ProfileInfluencerFormReferenceProfiles";

const ProfileInfluencerForm = ({
  control,
  errors,
  watch,
  setValue,
  sx,
  onDeleteReferenceProfile,
}) => {
  return (
    <Grid container spacing={2} sx={{ ...sx }}>
      <ProfileInfluencerFormCreator
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
      />
      <ProfileInfluencerFormReferenceProfiles
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        onDeleteReferenceProfile={onDeleteReferenceProfile}
      />
    </Grid>
  );
};

export default ProfileInfluencerForm;
