import React, { useState, useEffect } from "react";
import { useDispatchApp } from "../../lib/redux";
import Loader from "../../components/Common/Loader/Loader";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../components/Common/SearchBar/SearchBar";
import {
  startGetCreatorsPayments,
  startReleaseApplicantPayment,
} from "../../actions/owner";
import PayCreatorsTable from "../../components/Owner/PayCreators/PayCreatorsTable";
import {
  APPLICANT_PAYMENT_STATUS_PENDING_RELEASE,
  APPLICANT_PAYMENT_STATUS_RELEASED,
} from "../../utils/constants";
import PaymentsTypeFilter from "../../components/Owner/PayCreators/PaymentsTypeFilter";
import ModalDetailPaymentApplicant from "../../components/Owner/PayCreators/ModalDetailPaymentApplicant";
import TitlePage from "../../components/Common/Texts/TitlePage";

const PayCreators = () => {
  const [data, setData] = useState({});
  const [dataTable, setDataTable] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentSelect, setPaymentSelect] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatchApp();

  const getPendingReleasePayments = async () => {
    const payments = await dispatch(startGetCreatorsPayments());
    setPaymentType(APPLICANT_PAYMENT_STATUS_PENDING_RELEASE);
    setData(payments);
    setDataTable(payments);
    setLoading(false);
    return true;
  };

  const onFilterByTypePayment = () => {
    if (paymentType !== null) {
      let filteredData = {};
      Object.keys(data).forEach((paymentKey) => {
        if (data[paymentKey].status === paymentType) {
          return (filteredData = {
            ...filteredData,
            [paymentKey]: {
              ...data[paymentKey],
            },
          });
        }
      });
      setDataTable(filteredData);
    } else {
      setDataTable(data);
    }
  };

  useEffect(() => {
    getPendingReleasePayments();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    onFilterByTypePayment();
    //eslint-disable-next-line
  }, [paymentType]);

  const onSearch = (value) => {
    if (value.length > 0) {
      let filteredData = {};
      Object.keys(data).filter((item) => {
        if (
          data[item].userID.toLowerCase().includes(value.toLowerCase()) &&
          data[item].status === paymentType
        ) {
          return (filteredData = {
            ...filteredData,
            [item]: data[item],
          });
        }
        return null;
      });
      return setDataTable(filteredData);
    } else {
      return onFilterByTypePayment();
    }
  };

  const onSelectPayment = (paymentID) => {
    setPaymentSelect(paymentID);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
    setPaymentSelect(null);
  };

  const onChangePaymentType = (paymentTypeSelected) => {
    if (paymentTypeSelected === paymentType) return setPaymentType(null);
    return setPaymentType(paymentTypeSelected);
  };

  const onPayApplicant = async ({ userID, paymentID }) => {
    await dispatch(startReleaseApplicantPayment({ userID, paymentID }));
    setData({
      ...data,
      [paymentID]: {
        ...data[paymentID],
        status: APPLICANT_PAYMENT_STATUS_RELEASED,
        paymentReleaseDate: new Date().getTime(),
      },
    });
    setDataTable({
      ...dataTable,
      [paymentID]: {
        ...data[paymentID],
        status: APPLICANT_PAYMENT_STATUS_RELEASED,
        paymentReleaseDate: new Date().getTime(),
      },
    });
    onFilterByTypePayment();

    return true;
  };
  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <TitlePage>Pagos Pendientes</TitlePage>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          width: "100%",
          mt: 2,
          mb: 4,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            minWidth: 200,
          }}
        >
          <SearchBar
            placeholder="Buscar por userID"
            hasButton={false}
            onEditText={(text) => onSearch(text)}
          />
        </Box>
        <PaymentsTypeFilter
          paymentType={paymentType}
          onChangePaymentType={onChangePaymentType}
        />
      </Box>
      {loading ? (
        <Loader size={70} fullWidth={true} />
      ) : (
        <PayCreatorsTable data={dataTable} onSelectPayment={onSelectPayment} />
      )}
      {modalOpen && (
        <ModalDetailPaymentApplicant
          paymentData={data[paymentSelect]}
          modalOpen={modalOpen}
          paymentSelect={paymentSelect}
          onCloseModal={onCloseModal}
          isMobile={isMobile}
          onPayApplicant={onPayApplicant}
        />
      )}
    </Box>
  );
};

export default PayCreators;
