import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NotificationsContainer from "../components/User/Notifications/NotificationsContainer";
import Loader from "../components/Common/Loader/Loader";
import useNearScreen from "../hooks/useNearScreen";
import { usePagination } from "../hooks/usePagination";
import {
  startGetAllNotificationsPagination,
  startMarkAllNotificationsRead,
} from "../actions/notifications";
import { useDebounced } from "../hooks/useDebounce";
import TitlePage from "../components/Common/Texts/TitlePage";
import LoadingButton from "../components/Common/Buttons/LoadingButton";
import { useTranslationApp } from "../lib/i18next";
import {
  NOTIFICATIONS_EMPTY,
  NOTIFICATIONS_MARK_READS,
  NOTIFICATIONS_TITLE,
} from "../locales/keysTranslations";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const PAGE_SIZE = 15;

const Notifications = () => {
  const [notificationList, setNotificationList] = useState({});
  const [loadLastItem, setLoadLastItem] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "300px",
    once: false,
  });

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const getNextNotifications = useDebounced(() => {
    dispatch(
      startGetAllNotificationsPagination({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
        loadLastItem: () => setLoadLastItem(true),
      })
    );
  }, 1000);

  const { notifications, isLoading } = useSelectorApp(
    (state) => state.notifications
  );

  const notificationsKeys = Object.keys(notifications);

  const { rowsPerPage, lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue:
      notificationsKeys.length > 0 ? notificationsKeys.length : PAGE_SIZE,
    onCallBackNextPage: getNextNotifications,
  });

  useEffect(() => {
    if (notificationsKeys.length === 0) return;

    let filteredData = {};
    notificationsKeys.forEach((key) => {
      filteredData[key] = {
        ...notifications[key],
        date: new Date(notifications[key].date),
      };
    });
    setNotificationList(filteredData);

    // eslint-disable-next-line
  }, [notifications]);

  useEffect(() => {
    if (isNearScreen && !loadLastItem) getNextNotifications();
    //eslint-disable-next-line
  }, [isNearScreen]);

  const onMarkAllNotificationsRead = async () => {
    setIsFetching(true);
    await dispatch(startMarkAllNotificationsRead());
    setIsFetching(false);
  };

  return (
    <BlockPlanNoActive>
      <ContainerPage
        sx={{
          height: "calc(100% - 88px)",
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            gap: 2,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TitlePage
            sx={{
              wordBreak: "break-word",
            }}
          >
            {t(NOTIFICATIONS_TITLE)}
          </TitlePage>
          {isLoading && <Loader size={40} fullWidth={true} />}
          {!isLoading && notificationsKeys.length === 0 && (
            <Typography
              variant="h5"
              sx={{
                mb: 3,
              }}
            >
              {t(NOTIFICATIONS_EMPTY)}
            </Typography>
          )}
          {!isLoading && notificationsKeys.length > 0 && (
            <LoadingButton
              loading={isFetching}
              onClick={onMarkAllNotificationsRead}
              hasShadow={false}
              variant="text"
              color="primary"
              sx={{
                color: "text.primary",
                textDecoration: "underline",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t(NOTIFICATIONS_MARK_READS)}
            </LoadingButton>
          )}
        </Box>
        {!isLoading && notificationsKeys.length > 0 && (
          <NotificationsContainer
            data={notificationList}
            lastItemRef={fromRef}
            loadLastItem={loadLastItem}
          />
        )}
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default Notifications;
