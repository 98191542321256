import React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import StatusButton from "../../Common/Buttons/StatusButton";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import {
  PAGE_PUBLICATIONS_TABLE,
  PAGE_PUBLICATIONS_TABLE_STATUS,
  PAGE_PUBLICATIONS_TABLE_USER,
  PAGE_PUBLICATIONS_TABLE_DATE,
  PAGE_PUBLICATIONS_TABLE_VALUE,
  PAGE_PUBLICATIONS_TABLE_ACTIONS,
  PUBLICATION_STATUS,
} from "../../../onboarding/stepsSelectors";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import { validateIsValidDate } from "../../../utils/dates";
import InitChatButton from "../../Common/Chat/InitChatButton";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_ACTION,
  COLUMN_NAME_CREATOR_NAME,
  DATE_SHORT,
  COLUMN_NAME_VALUE_INVITATION,
  VALUE_WITH_CURRENCY,
  PUBLICATIONS_TABLE_ARIA_LABEL,
  COLUMN_NAME_DATE,
  COLUMN_NAME_STATUS,
  EMPTY_ORDERS,
} from "../../../locales/keysTranslations";
import { getIsEnabledOrderChat } from "../../../services/orders";
import { ROLES_ADMIN_SHOPS } from "../../../utils/constants";
import { getCurrencyObject, getRole } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";

const ConsumptionsTable = ({
  data,
  loading,
  influencers,
  rowsPerPage,
  page,
  onPageChange,
  onOpenChatPublication,
  onSelectOrder,
}) => {
  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const role = dispatch(getRole());
  const isAdminUser = ROLES_ADMIN_SHOPS.includes(role);

  const keysSorted = Object.keys(data).sort((a, b) => {
    const dateA = data[a].date;
    const dateB = data[b].date;
    if (!dateA && !dateB) return 0;
    return data[b]?.date?.getTime() - data[a]?.date?.getTime();
  });

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={loading}
        emptyText={EMPTY_ORDERS}
        ariaLabel={t(PUBLICATIONS_TABLE_ARIA_LABEL)}
      >
        <TableHead>
          <TableRow data-tour={PAGE_PUBLICATIONS_TABLE}>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_PUBLICATIONS_TABLE_STATUS}
            >
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_PUBLICATIONS_TABLE_USER}
            >
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_PUBLICATIONS_TABLE_DATE}
            >
              {t(COLUMN_NAME_DATE)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_PUBLICATIONS_TABLE_VALUE}
            >
              {t(COLUMN_NAME_VALUE_INVITATION)}
            </TableCell>
            {isAdminUser && (
              <TableCell
                align="center"
                isHeader={true}
                dataTour={PAGE_PUBLICATIONS_TABLE_ACTIONS}
              >
                {t(COLUMN_NAME_ACTION)}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => (
            <TableRow key={key}>
              <TableCell align="center" indexRow={index}>
                <Box data-tour={index === 0 && PUBLICATION_STATUS}>
                  <StatusButton
                    status={data[key].postStatus}
                    orderID={key}
                    onSelectOrder={onSelectOrder}
                  />
                </Box>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <NameInfluencerCell
                  userID={data[key].userID}
                  data={influencers[data[key].userID]}
                  showButton={false}
                />
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {validateIsValidDate(data[key].date) &&
                  t(DATE_SHORT, {
                    date: data[key].date,
                  })}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {t(VALUE_WITH_CURRENCY, {
                  value: data[key].value,
                  currency: dispatch(getCurrencyObject(data[key].currency)),
                })}
              </TableCell>
              {isAdminUser && (
                <TableCell align="center" indexRow={index}>
                  {getIsEnabledOrderChat(data[key]) && (
                    <InitChatButton
                      type="icon"
                      onClick={() => onOpenChatPublication(key)}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ConsumptionsTable;
