import React from "react";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "../../Common/Buttons/Button";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  capitalizeFirstLetter,
  getCityInfluencer,
} from "../../../utils/formats";
import Loader from "../../Common/Loader/Loader";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import {
  STAFF_ROLE,
  COUNTRIES_BUZZLY,
  WAS_PRE_REGISTERED_FILTER_TYPE,
  IS_PRE_REGISTERED_FILTER_TYPE,
} from "../../../utils/constants";
import { useSelectorApp } from "../../../lib/redux";
import {
  BUTTON_VIEW_DETAILS,
  COLUMN_NAME_ACCOUNT_NAME,
  COLUMN_NAME_ACTION,
  COLUMN_NAME_CITY,
  COLUMN_NAME_COUNTRY,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_ID,
  COLUMN_NAME_IS_HIDDEN,
  COLUMN_NAME_IS_PRE_REGISTERED,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_WAS_PRE_REGISTERED,
  NO,
  YES,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import _ from "lodash";

const InfluencersUsersTable = ({
  data,
  filter,
  searchText,
  loading,
  rowsPerPage,
  page,
  onPageChange,
  onSelectInfluencer,
  onDeleteInfluencer,
}) => {
  const signUpCountry = useSelectorApp((state) => state?.auth?.user?.country);
  const userRole = useSelectorApp((state) => state?.auth?.user?.role);

  const { t } = useTranslationApp();

  const getInfluencersFiltered = () => {
    const filtered = {};
    Object.keys(data).forEach((key) => {
      const influencer = data[key];
      if (influencer?.signUpCountry !== signUpCountry) {
        return;
      }
      if (
        !influencer?.mainAccountName
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) &&
        !influencer?.name?.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return;
      }
      if (
        !_.isEmpty(filter) &&
        ((filter === IS_PRE_REGISTERED_FILTER_TYPE &&
          !influencer?.isPreRegistered) ||
          (filter === WAS_PRE_REGISTERED_FILTER_TYPE &&
            !influencer?.wasPreRegistered))
      ) {
        return;
      }

      return (filtered[key] = influencer);
    });
    return filtered;
  };

  const influencersFiltered = getInfluencersFiltered();
  const keys = Object.keys(influencersFiltered);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keys}
        loading={loading}
        sx={{ overflow: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACCOUNT_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_IS_HIDDEN)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_IS_PRE_REGISTERED)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_WAS_PRE_REGISTERED)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PHONE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CITY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_COUNTRY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#F2F2F2",
              }}
            >
              <TableCell colSpan={16} sx={{ textAlign: "center" }}>
                <Loader size={70} fullWidth />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {(rowsPerPage > 0
              ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : keys
            ).map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  indexRow={index}
                  sx={{
                    wordBreak: "break-all",
                    minWidth: 150,
                  }}
                >
                  {row}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[row].mainAddress?.userInfo?.fullName ?? "-"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <NameInfluencerCell
                    userID={row}
                    data={data[row]}
                    showButton={false}
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[row]?.isHidden ? t(YES) : t(NO)}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[row]?.isPreRegistered || data[row]?.wasPreRegistered
                    ? t(YES)
                    : t(NO)}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[row]?.wasPreRegistered ? t(YES) : "-"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body2">
                    {data[row]?.signUpPhone ?? ""}
                  </Typography>
                  <Typography variant="body2">
                    {data[row].mainAddress?.userInfo?.phoneNumber ?? ""}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[row]?.email || "-"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body2">
                    {capitalizeFirstLetter(getCityInfluencer(data[row]))}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body2">
                    {COUNTRIES_BUZZLY.find(
                      (c) => c.code === data[row]?.signUpCountry
                    )?.label ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Button onClick={() => onSelectInfluencer(row)}>
                    {t(BUTTON_VIEW_DETAILS)}
                  </Button>
                  <IconButton
                    onClick={() => onDeleteInfluencer({ userID: row })}
                    disabled={userRole === STAFF_ROLE}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </TableContainer>
    </Box>
  );
};

export default InfluencersUsersTable;
