import React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import ActionsContactTable from "./ActionsContactTable";
import Typography from "@mui/material/Typography";
import TableContainer from "../../../Common/Table/TableContainer";
import TableCell from "../../../Common/Table/TableCell";
import {
  COLUMN_NAME_ACTION,
  USERS_CONTACT_TABLE_ARIA_LABEL,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_NAME,
  EMPTY_CONTACTS,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const ContactTable = ({ data, loading, onSelectContact, onDeleteContact }) => {
  const keys = Object.keys(data);
  const { t } = useTranslationApp();
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        keys={keys}
        loading={loading}
        emptyText={EMPTY_CONTACTS}
        ariaLabel={t(USERS_CONTACT_TABLE_ARIA_LABEL)}
        hasPagination={false}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PHONE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center" indexRow={index}>
                <Typography variant="body1">{data[row].name}</Typography>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <Typography variant="body1">{data[row].email}</Typography>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <Typography variant="body1">{data[row].phone}</Typography>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <ActionsContactTable
                  onSelectContact={onSelectContact}
                  onDeleteContact={onDeleteContact}
                  data={data[row]}
                  idContact={row}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ContactTable;
