import React from "react";
import Box from "@mui/material/Box";
import KanbanInPreparationButton from "./KanbanInPreparationButton";

const KanbanCardInPreparationContent = ({
  height,
  data,
  setData,
  onSelectOrden,
  onReadyOrden,
  onLaunchSound,
  storeSelected,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        maxHeight: {
          xs: `calc(${height}px - 96px)`,
          lg: `calc(${height}px - 94px)`,
        },
        minHeight: 400,
      }}
    >
      {Object.keys(data).map((key) => (
        <KanbanInPreparationButton
          key={data[key].deliveryID}
          data={data[key]}
          onLaunchSound={onLaunchSound}
          setData={setData}
          onClickButton={onSelectOrden}
          onReadyOrden={onReadyOrden}
          storeSelected={storeSelected}
        />
      ))}
    </Box>
  );
};

export default KanbanCardInPreparationContent;
