import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Loader from "../Loader/Loader";
import LinkText from "./LinkText";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { getAdAccountUrlMeta } from "../../../actions/getters";
import { useMediaQuery } from "@mui/material";
import { INSTAGRAM, META } from "../../../utils/constants";
import { logoPlatform } from "../../../utils/socialNetworks";
import { getInfluencerSocialLink } from "../../../utils/urls";

const MetaTextInformation = ({
  sx = {},
  target = "business",
  variant = "h6",
  variantMobile = "body1",
  targetHref = "_blank",
  hasIcon = false,
  sxIcon = {},
  integrationID,
  adAccountID,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isLoading, integrations } = useSelectorApp((state) => state.shop);
  const facebookIntegrations = integrations?.facebook || {};
  const facebookIntegration = facebookIntegrations?.[integrationID] || {};
  const businessAccount = facebookIntegration?.businessAccounts?.[adAccountID];
  const pageData = Object.values(facebookIntegration?.pages || {})[0];
  const instagramData = pageData?.instagramBusinessAccount;

  const dispatch = useDispatchApp();

  const getInformation = () => {
    if (target === "business") {
      return {
        name: businessAccount?.name || "",
        url: dispatch(getAdAccountUrlMeta(adAccountID)),
        icon: logoPlatform(META),
        iconName: META,
      };
    }
    if (target === "instagram") {
      return {
        name: instagramData?.username || "",
        url: getInfluencerSocialLink({
          mainPlatform: INSTAGRAM,
          mainAccountName: instagramData?.username,
        }),
        icon: logoPlatform(INSTAGRAM),
        iconName: INSTAGRAM,
      };
    }
  };

  if (isLoading) return <Loader size={30} hasMarginTop={false} />;
  if (!facebookIntegration) return null;

  const informationTarget = getInformation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      {hasIcon && informationTarget.icon && (
        <Avatar
          alt={`Logo de ${informationTarget.iconName}`}
          src={informationTarget.icon}
          variant="square"
          sx={{
            mr: 1,
            height: {
              xs: 30,
              sm: 40,
            },
            width: {
              xs: 30,
              sm: 40,
            },
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
            ...sxIcon,
          }}
        />
      )}
      <LinkText
        variant={isMobile ? variantMobile : variant}
        href={informationTarget.url}
        target={targetHref}
      >
        {informationTarget.name}
      </LinkText>
    </Box>
  );
};

export default MetaTextInformation;
