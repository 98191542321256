import React from "react";
import Grid from "@mui/material/Grid";
import GeneralStatisticPoint from "../../Owner/Statistics/General/GeneralStatisticPoint";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GroupIcon from "@mui/icons-material/Group";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import {
  numberCompactFormat,
  numberDecimal,
} from "../../../utils/numberFormats";
import { useTranslationApp } from "../../../lib/i18next";
import {
  AUDIENCE_CREDIBILITY,
  AUDIENCE_CREDIBILITY_TITLE_EXPLANATION,
  AVG_COMMENTS,
  AVG_LIKES,
  AVG_VIEWS,
  ENGAGEMENT,
  FOLLOWERS,
  SCORE,
} from "../../../locales/keysTranslations";
import Rating from "../Forms/Rating/Rating";
import ContentCredibilityAudience from "../Tooltips/Content/ContentCredibilityAudience";
import useMediaQuery from "@mui/material/useMediaQuery";

const InfluencerSummary = ({ data, from = "influencers" }) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <GeneralStatisticPoint
          Icon={GroupIcon}
          title={t(FOLLOWERS)}
          value={`${numberCompactFormat(data.followers)}`}
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <GeneralStatisticPoint
          Icon={TrendingUpIcon}
          title={t(ENGAGEMENT)}
          value={`${numberDecimal(
            data.engagement > 99 ? data.engagement / 100 : data.engagement
          )}%`}
        />
      </Grid>
      {from === "influencers" ? (
        <Grid
          item
          xs={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <GeneralStatisticPoint
            Icon={BurstModeIcon}
            title={t(SCORE)}
            value={
              <Rating
                value={data.score || 0}
                readOnly={true}
                precision={0.5}
                size={isMobile ? "small" : "medium"}
                variantText="h6"
              />
            }
          />
        </Grid>
      ) : null}
      {data.averageViews ? (
        <Grid
          item
          xs={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <GeneralStatisticPoint
            Icon={RemoveRedEyeIcon}
            title={t(AVG_VIEWS)}
            value={`${numberCompactFormat(data.averageViews)}`}
          />
        </Grid>
      ) : null}
      {data.averageLikes ? (
        <Grid
          item
          xs={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <GeneralStatisticPoint
            Icon={ThumbUpIcon}
            title={t(AVG_LIKES)}
            value={`${numberCompactFormat(data.averageLikes)}`}
          />
        </Grid>
      ) : null}
      {data.averageComments ? (
        <Grid
          item
          xs={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <GeneralStatisticPoint
            Icon={CommentIcon}
            title={t(AVG_COMMENTS)}
            value={`${numberCompactFormat(data.averageComments)}`}
          />
        </Grid>
      ) : null}
      {data?.audience?.credibilityScore && (
        <Grid
          item
          xs={12}
          sm={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <GeneralStatisticPoint
            Icon={PersonAddDisabledIcon}
            title={t(AUDIENCE_CREDIBILITY)}
            value={`${numberDecimal(data?.audience?.credibilityScore * 100)}%`}
            color={
              data?.audience?.credibilityScore >= 0.6 ? "text.primary" : "red"
            }
            hasTooltip={true}
            tooltipTitle={t(AUDIENCE_CREDIBILITY_TITLE_EXPLANATION)}
            tooltipContent={<ContentCredibilityAudience />}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InfluencerSummary;
