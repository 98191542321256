import { DUMMY_INFLUENCER_LOCAL } from "../onboarding/stepsSelectors";
import types from "../types";
import { influencersCategories } from "../utils/categories";

const initialState = {
  influencers: {},
  influencersExternal: {},
  influencersDiscarded: {},
  preRegisteredInfluencersProcess: {},
  favorites: {},
  categories: influencersCategories.map((category) => ({
    value: category,
    label: category,
  })),
  isLoading: true,
};

export const influencersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INFLUENCERS_FINISH:
      return {
        ...state,
        influencers: action.payload,
        isLoading: false,
      };
    case types.GET_NEXT_INFLUENCERS_FINISH:
      return {
        ...state,
        influencers: { ...state.influencers, ...action.payload },
        isLoading: false,
      };
    case types.GET_NEXT_INFLUENCERS_EXTERNAL_FINISH:
      return {
        ...state,
        influencersExternal: {
          ...state.influencersExternal,
          ...action.payload,
        },
        isLoading: false,
      };

    case types.DELETE_INFLUENCER_EXTERNAL:
      delete state.influencersExternal[action.payload];
      return {
        ...state,
      };
    case types.GET_PRE_REGISTERED_INFLUENCERS_PROCESS:
      return {
        ...state,
        preRegisteredInfluencersProcess: action.payload,
        isLoading: false,
      };
    case types.SAVE_STATISTICS_INFLUENCER:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          [action.payload.userID]: {
            ...state.influencers[action.payload.userID],
            ...action.payload.data,
          },
        },
        isLoading: false,
      };
    case types.SAVE_INFLUENCER_INFORMATION:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          [action.payload.userID]: {
            ...state.influencers[action.payload.userID],
            ...action.payload.data,
          },
        },
        isLoading: false,
      };
    case types.CHANGE_IS_HIDDEN_INFLUENCER:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          [action.payload.userID]: {
            ...state.influencers[action.payload.userID],
            isHidden: action.payload.isHidden,
          },
        },
        isLoading: false,
      };
    case types.DELETE_INFLUENCER:
      delete state.influencers[action.payload.userID];
      return {
        ...state,
      };

    case types.CLEAN_IS_SUGGESTED_INFLUENCERS:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          ...action.payload,
        },
      };
    case types.SET_INFLUENCERS_FAVORITES:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          ...action.payload,
        },
      };
    case types.SAVE_INFLUENCER_FAVORITE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          [`${action.payload.shopID}-${action.payload.userID}`]: action.payload,
        },
      };
    case types.DELETE_INFLUENCER_FAVORITE:
      const {
        [`${action.payload.shopID}-${action.payload.userID}`]: favoriteDeleted,
        ...favorites
      } = state.favorites;
      return {
        ...state,
        favorites: favorites,
      };

    case types.GET_CUSTOM_TAGS_INFLUENCERS:
      return {
        ...state,
        categories: [...state.categories, ...action.payload],
      };

    case types.DISCARD_INFLUENCER_DEFINITIVELY:
      const {
        [action.payload.userID]: influencerDiscarded,
        ...restInfluencers
      } = state.influencers;
      return {
        ...state,
        influencers: restInfluencers,
        influencersDiscarded: {
          ...state.influencersDiscarded,
          [action.payload.userID]: action.payload.data,
        },
      };

    case types.GET_INFLUENCERS_DISCARDED_DEFINITIVELY:
      return {
        ...state,
        influencersDiscarded: {
          ...state.influencersDiscarded,
          ...action.payload,
        },
      };

    case types.LOADING_INFLUENCERS_FINISH:
      const loading = action.payload || false;
      return {
        ...state,
        isLoading: loading,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_INFLUENCER_LOCAL]: influencerDeleted, ...influencers } =
        state.influencers;
      return {
        ...state,
        influencers: influencers,
      };
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    case types.GLOBAL_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
