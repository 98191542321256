import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PricingCard from "./PricingCard";
import { PLAN_BASIC } from "../../../utils/constants";

const PricingCardsContainer = ({
  plans = [],
  isProrated = false,
  onChangePlanSelected,
}) => {
  const grid = () => {
    if (plans.length === 1) {
      return {
        xs: 12,
        md: 6,
      };
    }
    if (plans.length === 2) {
      return {
        xs: 12,
        md: 6,
        lg: 6,
      };
    }
    if (plans.length === 3) {
      return {
        xs: 12,
        md: 6,
        lg: 4,
      };
    }
  };

  const isOnlyBasicPlan = plans.length === 1 && plans[0].id === PLAN_BASIC;

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 3,
        px: {
          md: 4,
          xs: 3,
        },
        mb: 3,
      }}
    >
      <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
        {plans.map((plan) => (
          <Grid item key={plan.id} {...grid()}>
            <PricingCard
              isOnlyBasicPlan={isOnlyBasicPlan}
              data={plan}
              onChangePlanSelected={onChangePlanSelected}
              id={plan.id}
              lengthPlans={plans.length}
              isProrated={isProrated}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingCardsContainer;
