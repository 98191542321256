import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { useForm } from "react-hook-form";
import ProfileInfluencerForm from "./ProfileInfluencerForm";
import {
  startSaveProfileInfluencerInfo,
  startSaveReferenceProfiles,
} from "../../../../actions/shops";
import Loader from "../../../Common/Loader/Loader";
import { formatCitiesSave } from "../../../../utils/formats";
import { useNavigate } from "react-router-dom";
import ContainerPaperSection from "../ContainerPaperSection";
import { useLocale } from "../../../../Contexts/LocaleContext";
import {
  BUTTON_SAVE_CHANGES,
  PROFILE_INFLUENCER_INFORMATION_SAVED,
  PROFILE_INFLUENCER_INFORMATION_TITLE,
  PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED,
  VIEW_SUGGESTED_CREATORS,
} from "../../../../locales/keysTranslations";
import {
  ALERT_ICON_TYPE_SUCCESS,
  TAB_SUGGESTED_INDEX,
} from "../../../../utils/constants";
import { ConfirmAlert, SimpleAlert } from "../../../../utils/alerts";
import { useTranslationApp } from "../../../../lib/i18next";
import { validationProfileInfluencer } from "../../../../utils/influencers";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { formatAgesRanges } from "../../../../services/shops";

const FIELDS = [
  "categories",
  "cities",
  "gender",
  "ageRange",
  "audienceGender",
  "audienceAgeRange",
  "platforms",
  "referenceProfiles",
];
const ProfileInfluencerContainer = ({
  elevation = 1,
  hasDividers = true,
  from = "account",
  modeContainer = "normal",
  id = "profileInfluencer",
  className = "profileInfluencer",
  showBottomButton = true,
  onCallbackSave = () => {},
  verifyProfile = true,
  customComponentButtons = null,
  sx = {},
}) => {
  const { handleSubmit, watch, control, setValue, formState } = useForm({
    defaultValues: {
      categories: [],
      cities: [],
      ageRange: [],
      gender: {
        male: false,
        female: false,
      },
      audienceGender: {
        male: false,
        female: false,
      },
      audienceAgeRange: [],
      platforms: {
        tiktok: false,
        instagram: false,
        youtube: false,
      },
      referenceProfiles: [],
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const profileInfluencerData = useSelectorApp(
    (state) => state.shop.profileInfluencer
  );

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const LOCALE = useLocale();

  useEffect(() => {
    Object.keys(profileInfluencerData).forEach((key) => {
      if (!FIELDS.includes(key)) return;
      if (key === "cities") {
        const citiesFormatted =
          formatCitiesSave({
            mode: "load",
            cities: profileInfluencerData[key],
            citiesShop: LOCALE.cities,
          }) ?? [];
        return setValue(key, citiesFormatted);
      }
      if (key === "ageRange" || key === "audienceAgeRange") {
        return setValue(
          key,
          formatAgesRanges({ ages: profileInfluencerData[key], mode: "load" })
        );
      }
      setValue(key, profileInfluencerData[key]);
    });
    setIsLoading(false);
    // eslint-disable-next-line
  }, [profileInfluencerData]);

  const onDeleteReferenceProfile = async (index) => {
    const referenceProfiles = watch("referenceProfiles");
    referenceProfiles.splice(index, 1);

    const response = await dispatch(
      startSaveReferenceProfiles(referenceProfiles)
    );

    if (response) {
      setValue("referenceProfiles", referenceProfiles);
    }
  };

  const onSubmit = async (data) => {
    setIsFetching(true);
    const citiesFormatted = formatCitiesSave({
      mode: "save",
      cities: data.cities,
      citiesShop: LOCALE.cities,
    });
    const audienceCitiesFormatted = formatCitiesSave({
      mode: "save",
      cities: data.audienceCities,
      citiesShop: LOCALE.cities,
    });
    const response = await dispatch(
      startSaveProfileInfluencerInfo(
        {
          ...data,
          cities: citiesFormatted,
          audienceCities: audienceCitiesFormatted,
        },
        false
      )
    );
    setIsFetching(false);
    if (response) {
      onCallbackSave();
      if (!verifyProfile) return true;

      const validateProfileIsComplete = validationProfileInfluencer({
        ...profileInfluencerData,
        ...data,
      });
      if (!validateProfileIsComplete) {
        SimpleAlert({
          title: t(PROFILE_INFLUENCER_INFORMATION_SAVED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      } else {
        const result = await ConfirmAlert({
          title: t(PROFILE_INFLUENCER_INFORMATION_SAVED),
          text: t(PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED),
          confirmButtonText: t(VIEW_SUGGESTED_CREATORS),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        if (result.isConfirmed) {
          return navigate(`/creators?tab=${TAB_SUGGESTED_INDEX}`);
        }
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <ContainerPaperSection
      title={PROFILE_INFLUENCER_INFORMATION_TITLE}
      onSubmit={handleSubmit(onSubmit)}
      isFetching={isFetching}
      showTopButton={false}
      showBottomButton={showBottomButton}
      buttonText={BUTTON_SAVE_CHANGES}
      hasDividers={hasDividers}
      elevation={elevation}
      id={id}
      className={className}
      mode={modeContainer}
      sx={sx}
    >
      <ProfileInfluencerForm
        control={control}
        watch={watch}
        errors={errors}
        from={from}
        setValue={setValue}
        onDeleteReferenceProfile={onDeleteReferenceProfile}
      />
      {customComponentButtons && customComponentButtons}
    </ContainerPaperSection>
  );
};

export default ProfileInfluencerContainer;
