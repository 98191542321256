import React, { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Pricing from "../../../pages/Pricing";
import RadioGroupCards from "../Stripe/RadioGroupCards";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import StripePayForm from "../Stripe/StripePayForm";
import { startPayPackage } from "../../../actions/payments";
// import { SimpleAlert } from "../../../utils/alerts";
// import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import LoadingButton from "../Buttons/LoadingButton";
import { onInit3DSecureAuth } from "../../../services/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import {
  CANCEL_ANYTIME,
  MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED,
  MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION,
  MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE,
  PAY,
  VALUE_WITH_CURRENCY,
  YOU_WILL_PAY,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Typography from "@mui/material/Typography";
import { useLocale } from "../../../Contexts/LocaleContext";
import ButtonToWhatsapp from "../Buttons/ButtonToWhatsapp";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import { SimpleAlert } from "../../../utils/alerts";
import { startValidationActivateDataShop } from "../../../actions/shops";

const SelectPlanPackage = ({
  onCallbackPayPackage = () => {},
  onChangeStep = () => {},
  onCloseModal = () => {},
  onCallbackFinish = () => {},
  isProrated = false,
}) => {
  const [planSelected, setPlanSelected] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [card, setCard] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const stripe = useStripe();
  const { t } = useTranslationApp();

  const {
    defaultPaymentMethod: { id },
  } = useSelectorApp((state) => state.payments.stripe);
  const [modePayment] = useState(
    id.length > 0 ? "hasPaymentMethod" : "noPaymentMethod"
  );

  const onChangePlanSelected = (plan) => {
    setPlanSelected(plan);
    onChangeStep("paying");
  };
  const onChangeCard = (card) => setCard(card);

  const onPayPackage = async (paymentMethodID = card, paymentIntent) => {
    if (paymentMethodID.length === 0 || planSelected === null)
      return setError(t(MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED));
    setError(null);
    setLoading(true);
    const response = await dispatch(
      startPayPackage({
        paymentMethodID,
        packageID: planSelected,
        paymentIntent,
        isProrated,
      })
    );
    if (response.ok) {
      setLoading(false);
      setIsPaid(true);
      onChangeStep("paid");
      onCallbackPayPackage();
      onCallbackFinish();
      SimpleAlert({
        title: t(MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE),
        text: t(MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      dispatch(startValidationActivateDataShop());
      return {
        ok: true,
      };
    }
    if (!response.ok && response.status === "requiresAction") {
      const responseVerification = await onInit3DSecureAuth({
        clientSecret: response.clientSecret,
        stripe,
        onCallbackFailed: onCloseModal,
      });
      if (responseVerification.ok) {
        const paymentIntent = responseVerification.paymentIntent;
        await onPayPackage(paymentMethodID, paymentIntent);
        return { ok: true };
      }
    }
    return { ok: false };
  };
  return (
    <Box>
      {!planSelected && (
        <Pricing
          onChangePlanSelected={onChangePlanSelected}
          isProrated={isProrated}
        />
      )}
      {planSelected && !isPaid && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: {
              xs: 3,
              sm: 5,
            },
            py: 3,
          }}
        >
          {!!error && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Alert
                severity="error"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "auto",
                  align: "center",
                }}
              >
                {error}
              </Alert>
            </Box>
          )}
          {modePayment === "noPaymentMethod" ? (
            <StripePayForm
              textButton={t(PAY)}
              onSubmit={(paymentMethod, paymentIntent) =>
                onPayPackage(paymentMethod.id)
              }
              saveCard={true}
              setDefaultCard={true}
              externalValidation={planSelected === null}
              sx={{ maxWidth: "sm" }}
              onCallbackFailed3DSecure={onCloseModal}
              CustomComponentDetailPayment={
                <DetailPricePlan plan={planSelected} />
              }
            />
          ) : (
            <RadioGroupCards
              onSelectCard={onChangeCard}
              showMakeDefaultPaymentMethod={false}
              CustomComponentDetailPayment={
                <DetailPricePlan plan={planSelected} />
              }
              sx={{ maxWidth: "sm" }}
            />
          )}

          {modePayment === "hasPaymentMethod" && (
            <LoadingButton
              loading={loading}
              color="secondary"
              onClick={() => onPayPackage()}
              disabled={!card || !planSelected}
              sx={{ mt: 2, minWidth: 130 }}
            >
              {t(PAY)}
            </LoadingButton>
          )}

          <Typography variant="caption" sx={{ mt: 0.5 }}>
            {t(CANCEL_ANYTIME)}
          </Typography>

          <ButtonToWhatsapp sx={{ mt: 1.2 }} />
        </Box>
      )}
    </Box>
  );
};

const DetailPricePlan = ({ plan }) => {
  const plans = useSelectorApp((state) => state?.payments?.plans);
  const { t } = useTranslationApp();
  const planInfo = plans[plan];
  const LOCALE = useLocale();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 2,
        mt: 2,
        width: "100%",
      }}
    >
      <Typography variant="body1" align="center" sx={{ fontWeight: 600 }}>
        {`${t(YOU_WILL_PAY)}:`}
      </Typography>
      <Typography variant="body1" align="center">
        {t(VALUE_WITH_CURRENCY, {
          value: planInfo.price,
          currency: planInfo?.currency || LOCALE.currency,
        })}
      </Typography>
    </Box>
  );
};
export default SelectPlanPackage;
