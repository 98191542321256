import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EventInformationTypeForm from "../components/User/Events/CreateEvent/EventInformationTypeForm";
import { startCreateEvent } from "../actions/events";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
} from "../utils/constants";

const CreateEvent = () => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, watch, getValues, setValue } = useForm({
    defaultValues: {
      name: "",
      description: "",
      dateTimeStart: null,
      dateTimeEnd: null,
      isRecurrent: false,
      typeRecurrent: "",
      location: "",
    },
  });

  const onNextStep = (name, value) => {
    switch (name) {
      case "name":
        if (value.length === 0)
          return setError({ text: "El nombre es requerido", step: 1 });
        setError(null);
        return setStep(step + 1);

      case "description":
        if (value.length === 0)
          return setError({ text: "La descripción es requerida", step: 2 });
        setError(null);
        return setStep(step + 1);

      case "dates":
        if (!value.dateTimeStart || !value.dateTimeEnd)
          return setError({
            text: "Ambas fechas son requeridas",
            step: 3,
          });
        const dateStart = new Date(value.dateTimeStart);
        const dateEnd = new Date(value.dateTimeEnd);
        if (dateStart.getTime() > dateEnd.getTime())
          return setError({
            text: "La fecha de inicio no puede ser mayor a la fecha de finalización",
            step: 3,
          });
        setError(null);
        return setStep(step + 1);

      case "isRecurrent":
        if (value.isRecurrent === "true" && value.typeRecurrent.length === 0)
          return setError({
            text: "El tipo de recurrencia es requerida",
            step: 4,
          });
        setError(null);
        return setStep(step + 1);

      case "location":
        if (value.length === 0)
          return setError({
            text: "El lugar es requerido",
            step: 5,
          });
        setError(null);
        return false;

      default:
        return setStep(step + 1);
    }
  };

  const onPreviousStep = () => {
    setStep(step - 1);
  };

  const onFinish = async (status) => {
    const formValues = getValues();
    const response = await dispatch(
      startCreateEvent({
        ...formValues,
        dateTimeStart: formValues.dateTimeStart.toISOString(),
        dateTimeEnd: formValues.dateTimeEnd.toISOString(),
        isActive: status === "active" ? true : false,
      })
    );
    if (response) {
      setStep(step + 1);
      SimpleAlert({
        title: `Evento Creado`,
        text: `La campaña ha sido creado en estado ${
          status === "active" ? "activado" : "desactivado"
        } correctamente`,
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      navigate("/events");
    } else {
      SimpleAlert({
        title: `Error al crear el evento`,
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 80px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pt: 3,
          px: 4,
          gap: 2,
        }}
      >
        <FormControl variant="filled" fullWidth sx={{ maxWidth: 200 }}>
          <InputLabel id="select-question">Pregunta</InputLabel>
          <Select
            labelId="select-question"
            id="select-question"
            value={step}
            label="Pregunta"
            onChange={(e) => setStep(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                },
              },
            }}
          >
            {Object.keys(getValues()).map((key, item) => {
              return (
                <MenuItem
                  key={key}
                  value={item + 1}
                  sx={{ display: "none", justifyContent: "space-between" }}
                >
                  {`Pregunta N°${item + 1}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <EventInformationTypeForm
          step={step}
          error={error}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          onFinish={onFinish}
          watch={watch}
          setValue={setValue}
          control={control}
          getValues={getValues}
        />
      </Box>
    </Box>
  );
};

export default CreateEvent;
