import React from "react";
import { ACTIVATE, DEACTIVATE } from "../../../locales/keysTranslations";
import Switch from "../../Common/Forms/Switchs/Switch";

const SwitchStore = ({ isActive, onChangeStatusStore, storeID }) => {
  const onChange = (checked) => {
    onChangeStatusStore(storeID, checked);
  };
  return (
    <Switch
      isActive={isActive}
      onChangeIsActive={onChange}
      labelActive={DEACTIVATE}
      labelDeactivate={ACTIVATE}
    />
  );
};

export default SwitchStore;
