import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./ClappApp";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./i18n";

if (process.env.REACT_APP_IS_STAGING === "false") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
