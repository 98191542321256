import React, { useState, useEffect } from "react";
import Button from "./Button";
import ModalDoAmbassador from "../Modal/ModalDoAmbassador";
import { useDispatchApp } from "../../../lib/redux";
import { startGetAmbassador } from "../../../actions/influencers";
import Loader from "../Loader/Loader";
import Box from "@mui/material/Box";
import { useTranslationApp } from "../../../lib/i18next";
import {
  DO_AMBASSADOR,
  EDIT_AMBASSADOR,
} from "../../../locales/keysTranslations";
import { getIsBlockedActions, getShopID } from "../../../actions/getters";
import { useNavigate } from "react-router-dom";

const ButtonDoAmbassador = ({
  userID,
  sxButton = {},
  sxContainer = {},
  disabled = false,
  color = "primary",
  onClosePrevComponent = () => {},
  isExternalBlocked = false,
  onCallbackExternalBlocked = () => {},
}) => {
  const [modalDoAmbassadorOpen, setModalDoAmbassadorOpen] = useState(false);
  const [isAmbassador, setIsAmbassador] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const shopID = dispatch(getShopID());

  const getInitialData = async () => {
    const isAmbassador = await dispatch(startGetAmbassador(userID));
    setIsAmbassador(isAmbassador);
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [userID]);

  if (isLoading) return <Loader />;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
        ...sxContainer,
      }}
    >
      <Button
        color={color}
        disabled={disabled}
        onClick={() => {
          if (isExternalBlocked) {
            onCallbackExternalBlocked();
            return;
          }
          const isBlocked = dispatch(
            getIsBlockedActions({
              verifyBlockedFreeTrial: true,
              verifyBlockedExchange: true,
            })
          );
          if (isBlocked) return;
          if (isAmbassador) {
            onClosePrevComponent();
            return navigate(`/ambassadors?ambassadorID=${shopID}-${userID}`);
          }
          setModalDoAmbassadorOpen(true);
        }}
        sx={{ ...sxButton }}
      >
        {t(isAmbassador ? EDIT_AMBASSADOR : DO_AMBASSADOR)}
      </Button>
      {modalDoAmbassadorOpen && (
        <ModalDoAmbassador
          modalOpen={modalDoAmbassadorOpen}
          onCloseModal={() => setModalDoAmbassadorOpen(false)}
          userID={userID}
          onCallback={() => setIsAmbassador(true)}
        />
      )}
    </Box>
  );
};

export default ButtonDoAmbassador;
