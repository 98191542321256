import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TextWithIcon = ({
  text,
  variantText = "h6",
  colorText = "text.primary",
  Icon,
  colorIcon = "primary",
  sx,
}) => {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center", ...sx }}>
      <Icon
        sx={{
          color: `${colorIcon} !important`,
        }}
      />
      <Typography
        variant={variantText}
        sx={{ color: `${colorText} !important` }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TextWithIcon;
