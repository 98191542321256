import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LinearLoaderWithTime = ({ label, labels = [], time, sx }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const maxProgress = 99; //Max progress value
    const baseIncrement = 100 / (time / 1000); //Increment value based on time
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= maxProgress) {
          return prevProgress;
        }

        //Slow down the increment as it approaches 100%
        const slowDownFactor = (100 - prevProgress) / 100;
        const increment = baseIncrement * slowDownFactor;

        return Math.min(prevProgress + increment, maxProgress);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const getCurrentLabel = () => {
    if (labels.length === 0) return "";

    //Get label based on progress, must be distributed to cover 100% of the progress.
    const stepWidth = 100 / labels.length;
    if (progress === 100) return labels[labels.length - 1];
    let currentLabel = "";
    for (let i = 0; i < labels.length; i++) {
      if (progress < (i + 1) * stepWidth) {
        currentLabel = labels[i];
        break;
      }
    }
    return currentLabel;
  };

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Typography variant="body1" align="center" sx={{}}>
        {label ? label : getCurrentLabel()}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ borderRadius: 5 }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LinearLoaderWithTime;
