import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  DAYS as DAYS_VALUE,
  HOURS as HOURS_VALUE,
  MINUTES as MINUTES_VALUE,
  WEEKS as WEEKS_VALUE,
} from "../../../../utils/constants";
import {
  ORDER_MODAL_LIST_TIME,
  ORDER_MODAL_LIST_TIME_TYPE,
} from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  DAYS,
  HOURS,
  MAX_VALUE_PREPARATION_ORDER,
  MINUTES,
  MIN_VALUE_PREPARATION_ORDER,
  ORDER_TIME_PREPARATION_TITLE,
  SELECT_A_OPTION,
  WEEKS,
} from "../../../../locales/keysTranslations";

const TimePreparation = ({
  time,
  typeTime,
  onChangeTypeTime,
  onPlusTime,
  onSubtractTime,
}) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="body1" sx={{ color: "#757575" }}>
        {t(ORDER_TIME_PREPARATION_TITLE)}
      </Typography>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "background.paperSecondary",
          backgroundImage: "none",
          px: 2,
          py: { xs: 1, md: 2 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <FormControl
            sx={{ m: 1, maxWidth: 400, width: "100%" }}
            variant="filled"
          >
            <Select
              data-tour={ORDER_MODAL_LIST_TIME_TYPE}
              displayEmpty
              value={typeTime}
              name="typeTime"
              onChange={(e) => onChangeTypeTime(e.target.value)}
            >
              <MenuItem value="" disabled>
                {t(SELECT_A_OPTION)}
              </MenuItem>
              <MenuItem value={MINUTES_VALUE}>{t(MINUTES)}</MenuItem>
              <MenuItem value={HOURS_VALUE}>{t(HOURS)}</MenuItem>
              <MenuItem value={DAYS_VALUE}>{t(DAYS)}</MenuItem>
              <MenuItem value={WEEKS_VALUE}>{t(WEEKS)}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex" }} data-tour={ORDER_MODAL_LIST_TIME}>
          <IconButton
            size="large"
            onClick={onSubtractTime}
            disabled={time === 0}
          >
            <DoDisturbOnIcon fontSize="inherit" />
          </IconButton>
          <Paper
            sx={{
              backgroundColor: "background.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: { xs: 75, md: 100 },
              width: { xs: 75, md: 100 },
              borderRadius: 20,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {time}
            </Typography>
          </Paper>
          <IconButton size="large" onClick={onPlusTime} disabled={time === 45}>
            <AddCircleIcon fontSize="inherit" />
          </IconButton>
        </Box>
        {(time === 0 || time === 45) && (
          <Alert severity="info" sx={{ alignItems: "center" }}>
            {t(
              time === 0
                ? MIN_VALUE_PREPARATION_ORDER
                : MAX_VALUE_PREPARATION_ORDER
            )}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default TimePreparation;
