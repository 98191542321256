import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ShopPost from "./ShopPost";
import { getExtensionFile, openLink } from "../../../../utils/urls";
import {
  ENGAGEMENT_INTERACTIONS,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
} from "../../../../utils/constants";
import { useSelectorApp } from "../../../../lib/redux";
import InfluencerNameLogo from "../../Influencer/InfluencerNameLogo";
import { numberCurrencyFormat } from "../../../../utils/numberFormats";
import TextWithIcon from "../../Texts/TextWithIcon";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ACCOUNTS_REACHED,
  COMMENTS,
  LIKES,
  REPLIES,
  SAVES,
  SHARES,
  STICKERS_TAPS,
  VIEW,
} from "../../../../locales/keysTranslations";
import Button from "../../Buttons/Button";
import LinkText from "../../Texts/LinkText";

const URL_WEB = process.env.REACT_APP_WEB_URL;

const ShopPosts = ({ data = [], isDownloading = false }) => {
  const { influencers } = useSelectorApp((state) => state.influencers);
  const { t } = useTranslationApp();

  if (Object.keys(influencers).length === 0) return null;
  if (data.length === 0) return null;

  return (
    <Box sx={{ my: 2 }}>
      {data.map((collaboration) => {
        const analyticsData = collaboration.analyticsData || {};
        const values = {
          [ACCOUNTS_REACHED]: numberCurrencyFormat(
            analyticsData.accountsReached
          ),
          [LIKES]: numberCurrencyFormat(analyticsData.likes),
          [COMMENTS]: numberCurrencyFormat(analyticsData.comments),
          [SHARES]: numberCurrencyFormat(analyticsData.shares),
          [SAVES]: numberCurrencyFormat(analyticsData.saves),
          [REPLIES]: numberCurrencyFormat(analyticsData.replies),
          [STICKERS_TAPS]: numberCurrencyFormat(analyticsData.stickerTaps),
        };
        return (
          <Box key={collaboration.orderID} sx={{ mb: 2 }}>
            <InfluencerNameLogo
              data={influencers[collaboration.userID]}
              userID={collaboration.userID}
            />
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2} alignItems="center">
                {ENGAGEMENT_INTERACTIONS.map((interaction) => {
                  if (values[interaction.label] === "0") return null;
                  return (
                    <Grid item key={interaction.label}>
                      <TextWithIcon
                        Icon={interaction.icon}
                        text={values[interaction.label]}
                      />
                    </Grid>
                  );
                })}
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={() =>
                      openLink(`/publication?orderID=${collaboration.orderID}`)
                    }
                  >
                    {t(VIEW)}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              {Object.values(collaboration?.posts || {}).map((post) => {
                const extension = getExtensionFile(post);
                if (
                  !VIDEO_FORMATS.includes(extension) &&
                  !IMAGE_FORMATS.includes(extension)
                ) {
                  return null;
                }
                if (isDownloading) {
                  return (
                    <LinkText
                      href={`${URL_WEB}/publication?orderID=${collaboration.orderID}`}
                      key={post}
                    >
                      <Grid item>
                        <ShopPost url={post} orderID={collaboration.orderID} />
                      </Grid>
                    </LinkText>
                  );
                }
                return (
                  <Grid item key={post}>
                    <ShopPost url={post} orderID={collaboration.orderID} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default ShopPosts;
