import React from "react";
import { useCountdown } from "../../../hooks/useCountDown";
import ExpiredTimer from "./ExpiredTimer";
import ShowCounter from "./ShowCounter";

const CountDownTimer = ({ targetDate, expiredMessage }) => {
  const { days, hours, minutes, seconds } = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredTimer message={expiredMessage} />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountDownTimer;
