import { useEffect } from "react";
import { useState } from "react";

const useUserIP = () => {
  const [userIP, setUserIP] = useState(null);

  const getIP = async () => {
    let firstApi = "https://api.ipify.org?format=json";
    let secondApi = "https://api64.ipify.org?format=json";
    let response = await fetch(firstApi);

    if (!response.ok) {
      response = await fetch(secondApi);
    }

    const data = await response.json();

    if (!data) {
      console.log("Error getting IP");
      return false;
    }

    const responseGeo = await fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_KEY_GEO_API}&ip=${data.ip}`
    );

    const dataGeo = await responseGeo.json();

    if (!dataGeo) {
      console.log("Error getting IP");
      return false;
    }

    setUserIP({
      ip: data?.ip,
      country: dataGeo?.country_name,
      countryCode: dataGeo?.country_code2,
    });
  };

  useEffect(() => {
    getIP();
  }, []);

  return {
    userIP,
  };
};

export default useUserIP;
