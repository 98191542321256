import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import AnswerContainer from "../../../Common/Forms/TypeForm/AnswerContainer";
import AnswerTitle from "../../../Common/Forms/TypeForm/AnswerTitle";
import { Controller } from "react-hook-form";
import NextButtonStep from "../../../Common/Forms/TypeForm/NextButtonStep";
import ActionsButtons from "../../../Common/Forms/TypeForm/ActionsButtons";
import { DateTimePicker } from "@mui/x-date-pickers";
import addMinutes from "date-fns/addMinutes";
import { getRecurrentOptions } from "../../../../utils/dates";

const EventInformationTypeForm = ({
  step,
  error,
  setValue,
  control,
  watch,
  getValues,
  onNextStep,
  onPreviousStep,
  onFinish,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: step >= 1 && step <= 9 ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer stepPosition={1} step={step} sx={{ display: "flex" }}>
        <AnswerTitle>
          {`${step}. ¿Cuál será el nombre de tu evento?`}
        </AnswerTitle>
        {!!error && error.step === 1 && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder="Nueva colección X de zapatos"
              variant="standard"
              {...field}
            />
          )}
        />
        <NextButtonStep
          sx={{ mt: 2 }}
          onNextStep={() => onNextStep("name", getValues("name"))}
        >
          Siguiente
        </NextButtonStep>
      </AnswerContainer>
      <AnswerContainer stepPosition={2} step={step}>
        <AnswerTitle>
          {`${step}. ¡Crea una descripción llamativa y clara de tu evento para que los creadores se animen a ir!`}
        </AnswerTitle>
        {!!error && error.step === 2 && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder="La colección de zapatos X que revolucionará el mercado del calzado"
              variant="standard"
              multiline
              minRows={1}
              maxRows={4}
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("description", getValues("description"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={3} step={step}>
        <AnswerTitle sx={{ mb: 2 }}>
          {`${step}. ¿Cuál será la fecha de inicio y de finalización de tu evento?`}
        </AnswerTitle>
        {!!error && error.step === 3 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="dateTimeStart"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              minDate={new Date()}
              onChange={(date) => {
                field.onChange(date);
                setValue("dateTimeEnd", null);
              }}
              label="Fecha de inicio del evento"
              renderInput={(params) => (
                <TextField
                  sx={{ mt: 0 }}
                  fullWidth
                  variant="standard"
                  {...params}
                />
              )}
            />
          )}
        />
        <Controller
          name="dateTimeEnd"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              disabled={watch("dateTimeStart") === null}
              label="Fecha de finalización de la evento"
              minDateTime={addMinutes(new Date(watch("dateTimeStart")), 10)}
              renderInput={(params) => (
                <TextField
                  sx={{ my: 1 }}
                  fullWidth
                  variant="standard"
                  {...params}
                />
              )}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("dates", {
              dateTimeStart: getValues("dateTimeStart"),
              dateTimeEnd: getValues("dateTimeEnd"),
            })
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={4} step={step}>
        <AnswerTitle>{`${step}. ¿Tu evento será recurrente?`}</AnswerTitle>
        <Typography variant="body1" sx={{ mb: 2 }}>
          La recurrencia quiere decir que este evento se repetirá con cierto
          patrón (diariamente, semanalmente, mensualmente o anualmente)
        </Typography>
        {!!error && error.step === 4 && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="isRecurrent"
          control={control}
          render={({ field }) => (
            <RadioGroup
              aria-labelledby="isRecurrent-label"
              defaultValue={false}
              {...field}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sí" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          )}
        />
        {watch("isRecurrent") === "true" && (
          <>
            <AnswerTitle>{`${step}.1 ¿Cuál es la recurrencia?`}</AnswerTitle>
            <Controller
              name="typeRecurrent"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
                  <InputLabel id="typeRecurrent">
                    Selecciona el tipo de recurrencia
                  </InputLabel>
                  <Select
                    labelId="typeRecurrent"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8 * 2,
                          width: 250,
                        },
                      },
                    }}
                    {...field}
                  >
                    {getRecurrentOptions(watch("dateTimeStart")).map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </>
        )}
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("isRecurrent", {
              isRecurrent: getValues("isRecurrent"),
              typeRecurrent: getValues("typeRecurrent"),
            })
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={5} step={step} sx={{ display: "flex" }}>
        <AnswerTitle>
          {`${step}. ¿Cuál será el lugar de tu evento?`}
        </AnswerTitle>
        {!!error && error.step === 5 && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder="CRA 45 #15-53 Tercer piso, Bogotá"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("location", getValues("location"))}
          onFinish={onFinish}
          showDraftButton={true}
          showSaveButton={true}
          showNextButton={false}
          type="Evento"
        />
      </AnswerContainer>
    </Box>
  );
};

export default EventInformationTypeForm;
