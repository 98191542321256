import React from "react";
import TableCellMui from "@mui/material/TableCell";
import { useMediaQuery, useTheme } from "@mui/material";

const TableCell = ({
  children,
  align,
  sx,
  isHeader = false,
  indexRow,
  colSpan = 0,
  onClick = () => {},
  dataTour = "",
  customColor = "",
  isSticky = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isStickyCell = isSticky && !isMobile;

  const sxSticky = isStickyCell
    ? {
        position: "sticky",
        right: 0,
        zIndex: isHeader ? 110 : 100,
        backgroundColor: "white",
      }
    : {};

  const getBackgroundColor = () => {
    if (customColor) {
      return customColor;
    }
    if (isHeader) {
      return theme.palette.table.header.background;
    }

    if (indexRow % 2 === 0) {
      return theme.palette.table.cell.backgroundEven;
    }
    return theme.palette.table.cell.backgroundOdd;
  };
  const getTextColor = () => {
    if (isHeader) {
      return theme.palette.table.header.color;
    }
    return theme.palette.table.cell.color;
  };

  const backgroundColor = getBackgroundColor();
  const color = getTextColor();

  return (
    <TableCellMui
      data-tour={dataTour}
      align={align}
      colSpan={colSpan}
      onClick={onClick}
      sx={{
        minWidth: "130px",
        ...sx,
        ...sxSticky,
        backgroundColor: backgroundColor,
        color,
        fontSize: isHeader && "0.95rem",
        fontWeight: isHeader && 600,
        maxWidth: isHeader && "180px",
        whiteSpace: isHeader && "nowrap",
        overflow: isHeader && "hidden",
        textOverflow: isHeader && "ellipsis",
        borderLeft: isStickyCell && "1px solid #e0e0e0",
        borderBottom: "none",
      }}
    >
      {children}
    </TableCellMui>
  );
};

export default TableCell;
