import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RED_COLOR } from "../../../utils/colors";

const ExpiredTimer = ({ message = "Expirada" }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ color: RED_COLOR }}>
        {message}
      </Typography>
    </Box>
  );
};

export default ExpiredTimer;
