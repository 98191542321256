import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Loader from "../../components/Common/Loader/Loader";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../components/Common/SearchBar/SearchBar";
import {
  startGetInfluencer,
  startGetInfluencers,
  startGetInfluencersByNameAndMainAccountName,
} from "../../actions/influencers";
import InfluencersUsersTable from "../../components/Owner/InfluencersUsers/InfluencersUsersTable";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  DEFAULT_TABLE_PAGES,
  DELAY_DEBOUNCE,
  IS_PRE_REGISTERED_FILTER_TYPE,
  WAS_PRE_REGISTERED_FILTER_TYPE,
} from "../../utils/constants";
import ModalInfluencerDetails from "../../components/Owner/InfluencersUsers/ModalInfluencerDetails";
import { usePagination } from "../../hooks/usePagination";
import { ConfirmAlert, SimpleAlert, TimerAlert } from "../../utils/alerts";
import { startDeleteInfluencerAccount } from "../../actions/owner";
import { useDebounced } from "../../hooks/useDebounce";
import TitlePage from "../../components/Common/Texts/TitlePage";
import { useSearchParams } from "react-router-dom";
import { t } from "i18next";
import {
  BUTTON_DELETE,
  CREATORS_OWNER_CREATOR_NOT_FOUND,
  CREATORS_OWNER_DELETED_CREATOR,
  CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETING_CREATOR,
  CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER,
  CREATORS_OWNER_TITLE,
  LOADING_INFORMATION,
} from "../../locales/keysTranslations";
import ContainerPage from "../../components/Common/Containers/ContainerPage";
import InfluencersUsersFilters from "../../components/Owner/InfluencersUsers/InfluencersUsersFilters";

const InfluencersUsers = () => {
  const [data, setData] = useState({});
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [influencerSelected, setInfluencerSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onGetNextPageInfluencers = () => {
    dispatch(
      startGetInfluencers({
        lastKey,
        onChangeLastKey,
        limit: rowsPerPage,
        isPreRegistered: filter === IS_PRE_REGISTERED_FILTER_TYPE,
        wasPreRegistered: filter === WAS_PRE_REGISTERED_FILTER_TYPE,
        onCallbackLoadingFinish: () => setLoading(false),
      })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: onGetNextPageInfluencers,
  });

  const matches = useMediaQuery("(min-width:600px)");

  const [params] = useSearchParams();
  const userID = params.get("userID");

  const dispatch = useDispatchApp();
  const { influencers, isLoading } = useSelectorApp(
    (state) => state.influencers
  );
  useEffect(() => {
    setLoading(true);
    dispatch(
      startGetInfluencers({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey,
        isPreRegistered: filter === IS_PRE_REGISTERED_FILTER_TYPE,
        onCallbackLoadingFinish: () => setLoading(false),
      })
    );
    //eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setData(influencers);
    //eslint-disable-next-line
  }, [influencers]);

  useEffect(() => {
    if (searchText.length > 0) {
      setLoading(true);
      onSearchInfluencer();
    }
    //eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    (async () => {
      if (userID) {
        const response = await dispatch(startGetInfluencer(userID));
        if (!response) {
          SimpleAlert({
            title: t(CREATORS_OWNER_CREATOR_NOT_FOUND),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return;
        }
        onSelectInfluencer(userID);
      }
    })();
    //eslint-disable-next-line
  }, [userID]);

  const onSelectInfluencer = (uid) => {
    setInfluencerSelected(uid);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setInfluencerSelected(null);
    setModalOpen(false);
  };

  const onChangeFilter = (filterSelect) => {
    if (filterSelect !== null) {
      if (filterSelect === filter) {
        setFilter(null);
      } else {
        setFilter(filterSelect);
      }
    } else {
      setFilter(filterSelect);
    }
  };

  const onSearchInfluencer = useDebounced(async () => {
    onPageChange(0);
    if (searchText.length > 0) {
      await dispatch(startGetInfluencersByNameAndMainAccountName(searchText));
    }
    setLoading(false);
  }, DELAY_DEBOUNCE);

  const onDeleteInfluencer = async ({ userID }) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(CREATORS_OWNER_DELETING_CREATOR),
        timer: 15000,
      });
      const response = await dispatch(startDeleteInfluencerAccount({ userID }));
      if (response) {
        SimpleAlert({
          title: t(CREATORS_OWNER_DELETED_CREATOR),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };

  return (
    <ContainerPage>
      <TitlePage>{t(CREATORS_OWNER_TITLE)}</TitlePage>
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          flexDirection: matches ? "row" : "column",
          width: "100%",
          mt: 2,
          mb: 2,
          gap: { xs: 0, sm: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            minWidth: 200,
          }}
        >
          <SearchBar
            placeholder={t(CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER)}
            hasButton={false}
            onEditText={(text) => setSearchText(text)}
          />
        </Box>
        <InfluencersUsersFilters
          filter={filter}
          onChangeFilter={onChangeFilter}
        />
      </Box>
      {isLoading ? (
        <Loader
          size={70}
          fullWidth={true}
          hasMessage={true}
          variantMessage="h5"
          message={t(LOADING_INFORMATION)}
        />
      ) : (
        <InfluencersUsersTable
          data={data}
          filter={filter}
          searchText={searchText}
          loading={loading}
          onSelectInfluencer={onSelectInfluencer}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          onDeleteInfluencer={onDeleteInfluencer}
        />
      )}
      {modalOpen && (
        <ModalInfluencerDetails
          modalOpen={modalOpen}
          onCloseModal={onCloseModal}
          userID={influencerSelected}
          data={influencers[influencerSelected]}
        />
      )}
    </ContainerPage>
  );
};

export default InfluencersUsers;
