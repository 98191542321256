import React from "react";
import { useDispatchApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import KanbanAcceptButton from "./KanbanAcceptButton";
import { startSetIsNewDelivery } from "../../../../actions/deliveries";
import { DUMMY_ORDER, NEW_ORDER } from "../../../../onboarding/stepsSelectors";

const KanbanCardToBeAcceptContent = ({
  height,
  data,
  onSelectOrden,
  storeSelected,
}) => {
  const dispatch = useDispatchApp();
  const onClick = (orden) => {
    onSelectOrden(orden);
    //when the order was already opened for the first time, its isNew  property is changed
    dispatch(startSetIsNewDelivery(storeSelected, orden.deliveryID));
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        maxHeight: {
          xs: `calc(${height}px - 96px)`,
          lg: `calc(${height}px - 94px)`,
        },
        minHeight: 400,
      }}
    >
      {Object.keys(data).map((key) => (
        <KanbanAcceptButton
          dataTour={data[key].deliveryID === DUMMY_ORDER && NEW_ORDER}
          key={data[key].deliveryID}
          data={data[key]}
          onClickButton={(orden) => onClick(orden)}
        />
      ))}
    </Box>
  );
};

export default KanbanCardToBeAcceptContent;
