import React, { useEffect, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import FormUsers from "./FormUsers";
import { REGEX_EMAIL } from "../../../../utils/regexsValidation";
import {
  ACCOUNT_MODE_CREATE,
  ADMIN_ROLE,
  OPERATOR_ROLE,
} from "../../../../utils/constants";
import Button from "../../../Common/Buttons/Button";
import LoadingButton from "../../../Common/Buttons/LoadingButton";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import {
  DUMMY_STORE,
  USER_FORM_CREATE_BUTTON,
} from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useDispatchApp } from "../../../../lib/redux";
import { changeShowNextButton } from "../../../../actions/onboarding";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_CREATE,
  BUTTON_SAVE,
  FIELD_ALL_REQUIRED,
  FIELD_EMAIL_VALID,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_MIN_LENGTH,
  MODAL_USER_ARIA_LABEL,
  MODAL_USER_DESCRIPTION,
  MODAL_USER_DESCRIPTION_EDIT,
  MODAL_USER_TITLE,
  MODAL_USER_TITLE_EDIT,
} from "../../../../locales/keysTranslations";
import { getNameBusinessTheme } from "../../../../actions/getters";

const INITIAL_STATE = {
  uid: "",
  name: "",
  email: "",
  shopID: "",
  storeID: "",
  role: "",
  password: "",
  repeatPassword: "",
};
const ModalUsers = ({
  data,
  shopID,
  storesList,
  isLoading,
  open,
  mode = ACCOUNT_MODE_CREATE,
  onCloseModal,
  onCreateUser,
  onEditUser,
}) => {
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);
  useEffect(() => {
    setFormValues({
      uid: mode === ACCOUNT_MODE_CREATE ? "" : data.uid,
      name: mode === ACCOUNT_MODE_CREATE ? "" : data.name,
      shopID: shopID,
      storeID: mode === ACCOUNT_MODE_CREATE ? "" : data.storeID,
      email: mode === ACCOUNT_MODE_CREATE ? "" : data.email,
      role: mode === ACCOUNT_MODE_CREATE ? "" : data.role,
      password: "",
      repeatPassword: "",
    });
    // eslint-disable-next-line
  }, [mode, data]);

  const { isOpen, steps, currentStep } = useTour();
  const dispatch = useDispatchApp();

  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { t } = useTranslationApp();

  useEffect(() => {
    if (isOpen) {
      const step = steps[currentStep];
      if (step.actionID === "selectAdminRole") {
        setFormValues({
          ...formValues,
          role: ADMIN_ROLE,
          storeID: "",
        });
      }
      if (step.actionID === "selectOperatorRole") {
        setFormValues({
          ...formValues,
          role: OPERATOR_ROLE,
          storeID: "",
        });
      }
      if (step.actionID === "selectDummyStore") {
        setFormValues({
          ...formValues,
          storeID: DUMMY_STORE,
        });
      }
    }
    // eslint-disable-next-line
  }, [currentStep, isOpen]);

  const onChange = (e) => {
    if (isOpen && e.target.value !== "") {
      dispatch(changeShowNextButton(true));
    } else if (isOpen && e.target.value === "") {
      dispatch(changeShowNextButton(false));
    }
    if (e.target.name === "role" && e.target.value === ADMIN_ROLE) {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        storeID: "",
      });
    } else {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (mode === ACCOUNT_MODE_CREATE) {
      if (isOpen) {
        const response = await onCreateUser(formValues);
        response && setFormValues(INITIAL_STATE);
        return;
      }
      if (
        !formValues.name ||
        !formValues.shopID ||
        (formValues.role === OPERATOR_ROLE ? !formValues.storeID : false) ||
        !formValues.email ||
        !formValues.role ||
        !formValues.password ||
        !formValues.repeatPassword
      ) {
        return setError(t(FIELD_ALL_REQUIRED));
      }
      if (!REGEX_EMAIL.test(formValues.email)) {
        return setError(t(FIELD_EMAIL_VALID));
      }
      if (formValues.password !== formValues.repeatPassword) {
        return setError(t(FIELD_PASSWORDS_NO_MATCH));
      }
      if (formValues.password.length < 6) {
        return setError(t(FIELD_PASSWORD_MIN_LENGTH));
      }
      const response = await onCreateUser(formValues);
      response && setFormValues(INITIAL_STATE);
      setError(null);
    } else {
      if (isOpen) {
        const response = await onEditUser(formValues);
        response && setFormValues(INITIAL_STATE);
        return;
      }
      if (
        !formValues.name ||
        !formValues.shopID ||
        (formValues.role === OPERATOR_ROLE ? !formValues.storeID : false) ||
        !formValues.email ||
        !formValues.role
      ) {
        return setError(t(FIELD_ALL_REQUIRED));
      }
      if (!REGEX_EMAIL.test(formValues.email)) {
        return setError(t(FIELD_EMAIL_VALID));
      }
      const response = await onEditUser(formValues, formValues.uid);
      response && setFormValues(INITIAL_STATE);
      setError(null);
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_USER_ARIA_LABEL)}
      title={t(mode === "create" ? MODAL_USER_TITLE : MODAL_USER_TITLE_EDIT)}
      description={t(
        mode === "create"
          ? MODAL_USER_DESCRIPTION
          : MODAL_USER_DESCRIPTION_EDIT,
        { NAME_BUSINESS }
      )}
    >
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert
            severity="error"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              align: "center",
            }}
          >
            {error}
          </Alert>
        </Box>
      )}
      <FormUsers
        data={formValues}
        storesList={storesList}
        onChangeForm={onChange}
        mode={mode}
      />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          mb: 2,
          px: 5,
          py: 0,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            setFormValues(INITIAL_STATE);
            onCloseModal();
          }}
        >
          {t(BUTTON_CANCEL)}
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          fullWidth
          loading={isLoading}
          onClick={onSubmit}
          sx={{ minWidth: 90 }}
          dataTour={USER_FORM_CREATE_BUTTON}
        >
          {mode === ACCOUNT_MODE_CREATE ? t(BUTTON_CREATE) : t(BUTTON_SAVE)}
        </LoadingButton>
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalUsers;
