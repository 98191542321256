import React from "react";
import Grid from "@mui/material/Grid";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import GeneralStatisticPoint from "./GeneralStatisticPoint";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ACCEPTED,
  EXPIRED,
  REDEEMED,
  REJECTED,
  REPORTS_INVITATIONS_ACTIVE,
  REPORTS_INVITATIONS_INACTIVE,
  REPORTS_REQUESTS_ACTIVE,
  REPORTS_REQUESTS_INACTIVE,
} from "../../../../locales/keysTranslations";

const GeneralStatisticsPointContainer = ({ data }) => {
  const { t } = useTranslationApp();

  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <GeneralStatisticPoint
          Icon={PersonAddIcon}
          title={t(REPORTS_INVITATIONS_ACTIVE)}
          value={data.invites}
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <GeneralStatisticPoint
          Icon={PersonOffIcon}
          title={t(REPORTS_INVITATIONS_INACTIVE)}
          value={data.invitesInactive.total}
          items={[
            {
              label: t(EXPIRED),
              value: data.invitesInactive.expired,
            },
            {
              label: t(REDEEMED),
              value: data.invitesInactive.redeem,
            },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <GeneralStatisticPoint
          Icon={PersonSearchIcon}
          title={t(REPORTS_REQUESTS_ACTIVE)}
          value={data.requests}
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <GeneralStatisticPoint
          Icon={SearchOffIcon}
          title={t(REPORTS_REQUESTS_INACTIVE)}
          value={data.requestsAccepted + data.requestsRejected}
          items={[
            {
              label: t(ACCEPTED),
              value: data.requestsAccepted,
            },
            {
              label: t(REJECTED),
              value: data.requestsRejected,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralStatisticsPointContainer;
