import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "../Containers/Accordion";
import ContainerKeyValue from "../Containers/ContainerKeyValue";
import { getTransactionStatus } from "../../../utils/formats";
import Button from "../Buttons/Button";
import ModalCreateTransactionInfluencer from "./ModalCreateTransactionInfluencer";
import {
  BUTTON_CREATE_TRANSACTION,
  CREATORS_OWNER_REPORT_TRANSACTIONS,
  CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY,
  DATE_LONG,
  TRANSACTION_CONCEPT_LABEL,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_DEBIT,
  TRANSACTION_WAS_PAID,
  UNKNOWN,
  VALUE,
  VALUE_WITH_CURRENCY,
  WAS_GENERATED,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const Transaction = ({ id, data }) => {
  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ContainerKeyValue keyValue="ID: " value={id} />
        <ContainerKeyValue
          keyValue={`${t(TRANSACTION_CONCEPT_LABEL)}: `}
          value={data.from || t(UNKNOWN)}
        />
        <ContainerKeyValue
          keyValue={`${t(TRANSACTION_TYPE)}: `}
          value={t(
            data.type === "credit"
              ? TRANSACTION_TYPE_CREDIT
              : TRANSACTION_TYPE_DEBIT
          )}
        />
        <ContainerKeyValue
          keyValue={`${t(TRANSACTION_STATUS)}: `}
          value={t(getTransactionStatus(data))}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            value: data.amount,
            currency: data.currency,
          })}`}
        />
        {data.creationTime && (
          <ContainerKeyValue
            keyValue={`${t(WAS_GENERATED)}: `}
            value={t(DATE_LONG, {
              date: new Date(data.creationTime),
            })}
          />
        )}
        {data.paymentReleasedTime && (
          <ContainerKeyValue
            keyValue={`${t(TRANSACTION_WAS_PAID)}: `}
            value={t(DATE_LONG, {
              date: new Date(data.paymentReleasedTime),
            })}
          />
        )}
      </Box>
    </Box>
  );
};

const InfluencerTransactions = ({
  data,
  userID,
  callbackCreateTransaction,
  influencer,
}) => {
  const [modalCreateTransaction, setModalCreateTransaction] = useState(false);

  const { t } = useTranslationApp();

  const keysSorted = Object.keys(data).sort((a, b) => {
    if (
      data[a].creationTime === undefined ||
      data[b].creationTime === undefined
    )
      return 0;
    return data[b].creationTime - data[a].creationTime;
  });

  return (
    <Box sx={{ mx: 0, width: "100%" }}>
      <Accordion title={t(CREATORS_OWNER_REPORT_TRANSACTIONS)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: 230,
            overflowY: "auto",
            pl: 0,
            pr: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              onClick={() => setModalCreateTransaction(true)}
            >
              {t(BUTTON_CREATE_TRANSACTION)}
            </Button>
          </Box>
          {keysSorted.length === 0 ? (
            <Typography>
              {t(CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY)}
            </Typography>
          ) : (
            keysSorted.map((transactionID) => {
              return (
                <Transaction
                  key={transactionID}
                  id={transactionID}
                  data={data[transactionID]}
                  influencer={influencer}
                />
              );
            })
          )}
        </Box>
      </Accordion>
      {modalCreateTransaction && (
        <ModalCreateTransactionInfluencer
          modalOpen={modalCreateTransaction}
          onCloseModal={() => setModalCreateTransaction(false)}
          userID={userID}
          callbackCreateTransaction={callbackCreateTransaction}
        />
      )}
    </Box>
  );
};

export default InfluencerTransactions;
