import React from "react";
import Box from "@mui/material/Box";
import { DRAWER_WIDTH } from "../../../utils/constants";

const ContainerPage = ({
  children,
  sx,
  mode = "margin",
  hasMarginBottom = false,
}) => {
  const PADDING_DESKTOP = 4;
  const PADDING_MOBILE = 1.5;

  const sxPersonalized =
    mode === "margin"
      ? {
          pl: {
            md: PADDING_DESKTOP,
            xs: PADDING_MOBILE,
          },
        }
      : {
          pl: {
            md: PADDING_DESKTOP,
            xs: PADDING_MOBILE,
          },
        };
  return (
    <Box
      sx={{
        ...sxPersonalized,
        pb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        maxWidth: {
          xs: `calc(100% - ${PADDING_MOBILE * 8}px)`,
          md: `calc(100vw - ${DRAWER_WIDTH + PADDING_DESKTOP * 8}px)`,
        },
        mb: hasMarginBottom ? 15 : 0,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerPage;
