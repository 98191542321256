import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  GREEN_COLOR_LIGHT,
  GREY_DARK_COLOR,
  ORANGE_COLOR,
  RED_COLOR,
} from "../../../../../utils/colors";
import {
  APPLICANT_STATUS_ACCEPTED,
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_STATUS_PENDING,
  APPLICANT_STATUS_PENDING_ANALYTICS,
  APPLICANT_STATUS_PENDING_SOCIAL,
  APPLICANT_STATUS_REJECTED,
  APPLICANT_STATUS_REVISIONS,
  APPLICANT_STATUS_SUBMITTED,
} from "../../../../../utils/constants";
import CounterRevisionText from "../../CounterRevisionText";
import {
  APPLICANTS_STATUS_ACCEPTED,
  APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS,
  APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL,
  APPLICANTS_STATUS_CONTENT_REVISIONS,
  APPLICANTS_STATUS_CONTENT_SUBMITTED,
  APPLICANTS_STATUS_DELIVERED,
  APPLICANTS_STATUS_PENDING,
  APPLICANTS_STATUS_REJECTED,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";

const StatusApplicant = ({ status = APPLICANT_STATUS_SUBMITTED, data }) => {
  const states = {
    [APPLICANT_STATUS_PENDING]: {
      textButton: APPLICANTS_STATUS_PENDING,
      color: GREY_DARK_COLOR,
    },
    [APPLICANT_STATUS_REJECTED]: {
      textButton: APPLICANTS_STATUS_REJECTED,
      color: RED_COLOR,
    },
    [APPLICANT_STATUS_ACCEPTED]: {
      textButton: APPLICANTS_STATUS_ACCEPTED,
      color: ORANGE_COLOR,
    },
    [APPLICANT_STATUS_SUBMITTED]: {
      textButton: APPLICANTS_STATUS_CONTENT_SUBMITTED,
      color: ORANGE_COLOR,
    },
    [APPLICANT_STATUS_REVISIONS]: {
      textButton: APPLICANTS_STATUS_CONTENT_REVISIONS,
      color: ORANGE_COLOR,
    },
    [APPLICANT_STATUS_PENDING_SOCIAL]: {
      textButton: APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL,
      color: ORANGE_COLOR,
    },
    [APPLICANT_STATUS_PENDING_ANALYTICS]: {
      textButton: APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS,
      color: ORANGE_COLOR,
    },
    [APPLICANT_STATUS_DELIVERED]: {
      textButton: APPLICANTS_STATUS_DELIVERED,
      color: GREEN_COLOR_LIGHT,
    },
  };
  const { textButton, color } = states[status];
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: 1,
        minWidth: 140,
      }}
    >
      <Box
        sx={{
          minWidth: 10,
          width: 10,
          height: 10,
          borderRadius: 4,
          backgroundColor: color,
        }}
      ></Box>
      <Typography variant="body2" align="center" sx={{ width: "100%" }}>
        {status === APPLICANT_STATUS_REVISIONS ||
        (data.revision && status === APPLICANT_STATUS_SUBMITTED) ? (
          <CounterRevisionText
            text={t(textButton)}
            revision={(data.revision ?? 0) + 1}
            increment={0}
          />
        ) : (
          t(textButton)
        )}
      </Typography>
    </Box>
  );
};

export default StatusApplicant;
