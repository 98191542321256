import React, { useState } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "../Buttons/LoadingButton";
import { useDispatchApp } from "../../../lib/redux";
import { startAssignDissatisfactionCredit } from "../../../actions/shops";
import { SimpleAlert } from "../../../utils/alerts";
import {
  MODAL_DISSATISFACTION_COLLABORATION_BUTTON,
  MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION,
  MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT,
  MODAL_DISSATISFACTION_COLLABORATION_TITLE,
  MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED,
} from "../../../locales/keysTranslations";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";

const ModalDissatisfactionCollaboration = ({
  modalOpen,
  onCloseModal,
  orderID,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const onAssignCredit = async () => {
    setIsFetching(true);
    const response = await dispatch(startAssignDissatisfactionCredit(orderID));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsFetching(false);
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      hasTitle={true}
      sx={{ ".MuiPaper-root": { background: GRADIENT_PURPLE_FUCHSIA } }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT} !important`,
        }}
      >
        <Typography variant="h6" align="center" sx={{ fontWeight: 600, mb: 2 }}>
          {t(MODAL_DISSATISFACTION_COLLABORATION_TITLE)}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 2 }}>
          {t(MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION)}
        </Typography>
        <LoadingButton loading={isFetching} onClick={onAssignCredit}>
          {t(MODAL_DISSATISFACTION_COLLABORATION_BUTTON)}
        </LoadingButton>
        <Typography variant="caption" align="center" sx={{ mt: 2 }}>
          {t(MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT)}
        </Typography>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalDissatisfactionCollaboration;
