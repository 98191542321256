import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { Controller, useForm } from "react-hook-form";
import TextFieldMui from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { REGEX_URL } from "../../../utils/regexsValidation";
import { generalCategories, subCategories } from "../../../utils/categories";
import {
  MIN_ALL_NUMBER_VALUE,
  MIN_LENGTH_ALL_FIELDS,
} from "../../../utils/constants";
import CurrencyField from "../../Common/NumberFormats/CurrencyField";
import {
  formatCitiesSave,
  formatPhoneValueLibrary,
} from "../../../utils/formats";
import { startSaveCompanyInfo } from "../../../actions/shops";
import Loader from "../../Common/Loader/Loader";
import TextFieldController from "../../Common/Fields/TextField";
import Select from "../../Common/Selects/Select";
import SelectMultiple from "../../Common/Selects/SelectMultiple";
import ContainerPaperSection from "./ContainerPaperSection";
import { useLocale } from "../../../Contexts/LocaleContext";
import PhoneTextField from "../../Common/Fields/PhoneTextField";
import UploadFile from "../../Common/Buttons/UploadFile";
import RemoveFile from "../../Common/Buttons/RemoveFile";
import {
  BUTTON_REPLACE_IMAGE,
  COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER,
  COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE,
  COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER,
  COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER,
  COMPANY_INFORMATION_CATEGORY_PLACEHOLDER,
  COMPANY_INFORMATION_NEW_SUBCATEGORY,
  COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER,
  COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER,
  COMPANY_INFORMATION_SHOP_ID_COPIED,
  COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER,
  COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE,
  COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER,
  COMPANY_INFORMATION_TITLE,
  COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER,
  COMPANY_INFORMATION_VIEW_DOCUMENT,
  COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER,
  FIELD_MIN_LENGTH,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ButtonCopy from "../../Common/Buttons/ButtonCopy";
import UploadImageButtons from "../../Common/Buttons/UploadImageButtons";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import LinkText from "../../Common/Texts/LinkText";
import _ from "lodash";

const FIELDS = [
  "businessName",
  "reservationURL",
  "regulatoryDocument",
  "averageTicket",
  "socialNetwork",
  "secondSocialNetwork",
  "websiteURL",
  "trafficURL",
  "shortDescription",
  "adminPhone",
  "cities",
  "category",
  "subCategories",
  "ordersMethods",
  "justoURL",
  "shopifyURL",
  "logoImg",
  "coverImg",
];

const CompanyInformationForm = ({
  elevation = 1,
  hasDividers = true,
  showBottomButton = true,
  modeContainer = "normal",
  onCallbackSave = () => {},
  customComponentButtons = null,
  onlyRequiredFields = false,
  onlyEmptyFields = false,
  sx = {},
  sxTitle = {},
  grid = {
    xs: 12,
    md: 6,
  },
  sxPreviewsLogos = {},
}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [errorField, setErrorField] = useState(null);

  const dispatch = useDispatchApp();
  const { user } = useSelectorApp((state) => state.auth);
  const shop = useSelectorApp((state) => state.shop);

  const { t } = useTranslationApp();

  const LOCALE = useLocale();

  const sxPreview = {
    maxHeight: 200,
    ...sxPreviewsLogos,
  };

  const { handleSubmit, watch, control, getValues, setValue, formState } =
    useForm({
      defaultValues: {
        businessName: "",
        reservationURL: "",
        regulatoryDocument: "",
        averageTicket: "",
        socialNetwork: "",
        secondSocialNetwork: "",
        websiteURL: "",
        trafficURL: "",
        shortDescription: "",
        adminPhone: "",
        cities: [],
        category: "",
        subCategories: [],
        ordersMethods: "",
        justoURL: "",
        shopifyURL: "",
        logoImg: "",
        coverImg: "",
      },
    });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const category = watch("category");

  useEffect(() => {
    Object.keys(shop).forEach((key) => {
      if (!FIELDS.includes(key)) return;
      if (key === "cities") {
        const citiesFormatted =
          formatCitiesSave({
            mode: "load",
            cities: shop[key],
            citiesShop: LOCALE.cities,
          }) ?? [];
        return setValue(key, citiesFormatted);
      }
      if (key === "adminPhone") {
        return setValue(
          "adminPhone",
          formatPhoneValueLibrary({
            phone: shop[key],
            country: shop.signUpCountry,
            locale: LOCALE,
          })
        );
      }
      setValue(key, shop[key]);
    });
    setIsLoading(false);
    // eslint-disable-next-line
  }, [shop]);

  const onOpenAlert = () => {
    setOpenAlert(true);
  };
  const onCloseAlert = () => {
    setOpenAlert(false);
  };

  const onAddSubCategory = (newSubCategory) => {
    const subCategoriesState = getValues("subCategories");
    const category = getValues("category");
    if (
      subCategoriesState.some(
        (subcategory) =>
          subcategory.toLowerCase() === newSubCategory.toLowerCase()
      )
    ) {
      setErrorField(t(COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE));
      return false;
    }
    setErrorField(null);
    let newSubCategories = subCategoriesState;
    newSubCategories.filter((value) => value.length !== 0);
    newSubCategories.push(newSubCategory);
    setValue("subCategories", newSubCategories);
    subCategories[category].unshift(newSubCategory);
    return true;
  };

  const onSubmit = async (data) => {
    setIsFetching(true);
    const citiesFormatted = formatCitiesSave({
      mode: "save",
      cities: data.cities,
      citiesShop: LOCALE.cities,
    });

    const response = await dispatch(
      startSaveCompanyInfo({
        ...data,
        cities: citiesFormatted,
        adminPhone: formatPhoneValueLibrary({
          phone: data?.adminPhone?.value,
          mode: "save",
        }),
      })
    );
    if (response) {
      onCallbackSave();
    }
    setIsFetching(false);
  };

  if (isLoading) return <Loader />;

  const getIsShowEmptyField = (field) => {
    if (!onlyEmptyFields) return true;

    return _.isEmpty(shop[field]);
  };

  return (
    <ContainerPaperSection
      title={COMPANY_INFORMATION_TITLE}
      showTopButton={false}
      elevation={elevation}
      hasDividers={hasDividers}
      showBottomButton={showBottomButton}
      modeContainer={modeContainer}
      onSubmit={handleSubmit(onSubmit)}
      isFetching={isFetching}
      sx={{ mt: 0, ...sx }}
      sxTitle={sxTitle}
    >
      <Grid container spacing={2}>
        {!onlyRequiredFields && (
          <Grid item {...grid}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <TextFieldMui
                value={user.shopID}
                disabled={true}
                variant="filled"
                label="Shop ID"
                fullWidth
              />
              <ButtonCopy value={user.shopID} onClick={onOpenAlert} />
            </Box>
          </Grid>
        )}
        {getIsShowEmptyField("businessName") && (
          <Grid item {...grid}>
            <TextFieldController
              name="businessName"
              label={t(COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                minLength: {
                  value: MIN_LENGTH_ALL_FIELDS,
                  message: t(FIELD_MIN_LENGTH, {
                    value: MIN_LENGTH_ALL_FIELDS,
                  }),
                },
              }}
            />
          </Grid>
        )}
        {getIsShowEmptyField("adminPhone") && (
          <Grid item {...grid}>
            <PhoneTextField
              name="adminPhone"
              defaultCountry={shop.signUpCountry}
              onlyCountries={[]}
              disableDropdown={false}
              label={t(COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="filled"
              control={control}
              errors={errors}
            />
          </Grid>
        )}
        {getIsShowEmptyField("websiteURL") && (
          <Grid item {...grid}>
            <TextFieldController
              name="websiteURL"
              label={t(COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: {
                  value: (value) => {
                    if (value && !REGEX_URL.test(value)) {
                      return t(FIELD_URL_VALID);
                    }
                  },
                },
              }}
            />
          </Grid>
        )}
        {getIsShowEmptyField("category") && (
          <Grid item {...grid}>
            <Select
              className="category"
              name="category"
              label={t(COMPANY_INFORMATION_CATEGORY_PLACEHOLDER)}
              fullWidth={true}
              variant="filled"
              inputProps={{
                "aria-label": t(COMPANY_INFORMATION_CATEGORY_PLACEHOLDER),
              }}
              options={generalCategories.map((category) => ({
                ...category,
                label: t(category.label),
              }))}
              actionsChange={() => {
                setValue("subCategories", []);
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {getIsShowEmptyField("subCategories") && (
          <Grid item {...grid}>
            <SelectMultiple
              className="subCategories"
              name="subCategories"
              label={t(COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER)}
              fullWidth={true}
              showAddAction={true}
              disabled={!watch("category")}
              renderValue={(selected) => selected.join(", ")}
              variant="filled"
              menuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8 * 2,
                    width: 250,
                  },
                },
              }}
              options={
                watch("category")
                  ? subCategories[watch("category")].map((option) => t(option))
                  : []
              }
              labelAddAction={t(COMPANY_INFORMATION_NEW_SUBCATEGORY)}
              addAction={onAddSubCategory}
              errorAddAction={errorField}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {getIsShowEmptyField("shortDescription") && (
          <Grid item {...grid}>
            <TextFieldController
              className="shortDescription"
              name="shortDescription"
              label={t(COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER)}
              placeholder={t(COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER)}
              multiline
              minRows={3}
              maxRows={4}
              control={control}
              variant="filled"
              fullWidth
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                minLength: {
                  value: 3,
                  message: t(FIELD_MIN_LENGTH, {
                    value: 3,
                  }),
                },
              }}
            />
          </Grid>
        )}
        {!onlyRequiredFields && getIsShowEmptyField("trafficURL") && (
          <Grid item {...grid}>
            <TextFieldController
              className="trafficURL"
              name="trafficURL"
              label={t(COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                validate: {
                  value: (value) => {
                    if (value && !REGEX_URL.test(value)) {
                      return t(FIELD_URL_VALID);
                    }
                  },
                },
              }}
            />
          </Grid>
        )}
        {!onlyRequiredFields && getIsShowEmptyField("socialNetwork") && (
          <Grid item {...grid}>
            <TextFieldController
              name="socialNetwork"
              label={t(COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                validate: {
                  value: (value) => {
                    if (value && !REGEX_URL.test(value)) {
                      return t(FIELD_URL_VALID);
                    }
                  },
                },
              }}
            />
          </Grid>
        )}
        {!onlyRequiredFields && getIsShowEmptyField("secondSocialNetwork") && (
          <Grid item {...grid}>
            <TextFieldController
              name="secondSocialNetwork"
              label={t(COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                validate: {
                  value: (value) => {
                    if (value && !REGEX_URL.test(value)) {
                      return t(FIELD_URL_VALID);
                    }
                  },
                },
              }}
            />
          </Grid>
        )}
        {!onlyRequiredFields && getIsShowEmptyField("averageTicket") && (
          <Grid item {...grid}>
            <TextFieldController
              name="averageTicket"
              label={t(COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              InputProps={{
                inputComponent: CurrencyField,
              }}
              rules={{
                validate: (value) => {
                  if (value && Number(value) < MIN_ALL_NUMBER_VALUE) {
                    return t(COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE, {
                      value: MIN_ALL_NUMBER_VALUE,
                      currency: LOCALE.currency,
                    });
                  }
                },
              }}
            />
          </Grid>
        )}
        {!onlyRequiredFields && getIsShowEmptyField("reservationURL") && (
          <Grid item {...grid}>
            <TextFieldController
              className="reservationURL"
              name="reservationURL"
              label={t(COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                validate: {
                  value: (value) => {
                    if (value && !REGEX_URL.test(value)) {
                      return t(FIELD_URL_VALID);
                    }
                  },
                },
              }}
            />
          </Grid>
        )}
        {getIsShowEmptyField("logoImg") && (
          <Grid item {...grid} sx={{ height: "100%" }} className="logoImg">
            <Typography variant="body1">
              {t(COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "column",
                my: 1,
              }}
            >
              {t(COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER)}
            </Typography>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
              name="logoImg"
              render={({ field }) => (
                <UploadImageButtons
                  urlImage={field.value}
                  targetName={field.name}
                  pathStorage={`shops/${user.shopID}/images/`}
                  idUploadComponent="logoImg"
                  pathRTDB={`shops/${user.shopID}/logoImg`}
                  onReadyUrlImage={field.onChange}
                  onRemoveUrlImage={field.onChange}
                  textButtonRemove={BUTTON_REPLACE_IMAGE}
                  showImage={true}
                  mode="replace"
                  sxPreview={sxPreview}
                />
              )}
            />
            {errors?.logoImg && (
              <Typography variant="body2" color="error">
                {errors?.logoImg?.message}
              </Typography>
            )}
          </Grid>
        )}
        {getIsShowEmptyField("coverImg") && (
          <Grid item {...grid} sx={{ height: "100%" }} className="coverImg">
            <Typography variant="body1">
              {t(COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "column",
                my: 1,
              }}
            >
              {t(COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER)}
            </Typography>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
              name="coverImg"
              render={({ field }) => (
                <UploadImageButtons
                  urlImage={field.value}
                  targetName={field.name}
                  pathStorage={`shops/${user.shopID}/images/`}
                  pathRTDB={`shops/${user.shopID}/coverImg`}
                  onReadyUrlImage={field.onChange}
                  onRemoveUrlImage={field.onChange}
                  textButtonRemove={BUTTON_REPLACE_IMAGE}
                  idUploadComponent="coverImg"
                  showImage={true}
                  mode="replace"
                  sxPreview={sxPreview}
                />
              )}
            />
            {errors?.coverImg && (
              <Typography variant="body2" color="error">
                {errors?.coverImg?.message}
              </Typography>
            )}
          </Grid>
        )}
        {category === "foods" && getIsShowEmptyField("regulatoryDocument") && (
          <Grid
            item
            xs={12}
            sx={{ height: "100%" }}
            className="regulatoryDocument"
          >
            <Typography variant="h6">
              {t(COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "column",
                minHeight: { xs: 0, md: 50 },
                mt: 1,
              }}
            >
              {t(
                COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER
              )}
            </Typography>
            <Controller
              control={control}
              rules={{
                validate: {
                  value: (value) => {
                    if (category === "foods" && !value) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                },
              }}
              name="regulatoryDocument"
              render={({ field }) => (
                <>
                  {!field.value && (
                    <UploadFile
                      pathStorage={`shops/${user.shopID}/documents/`}
                      pathRTDB={`shops/${user.shopID}/regulatoryDocument`}
                      automaticSetRTDB={true}
                      acceptFormats="application/pdf"
                      targetName={field.name}
                      onReadyFile={field.onChange}
                    />
                  )}
                  {field?.value && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 1,
                      }}
                    >
                      <LinkText href={field.value} target="_blank">
                        {t(COMPANY_INFORMATION_VIEW_DOCUMENT)}
                      </LinkText>
                      <RemoveFile
                        pathStorage={`shops/${user.shopID}/documents/`}
                        deleteFile={true}
                        automaticRemoveRTDB={true}
                        pathRTDB={`shops/${user.shopID}/regulatoryDocument`}
                        targetName={field.name}
                        urlFile={field.value}
                        onRemoveFile={field.onChange}
                      />
                    </Box>
                  )}
                </>
              )}
            />
            {errors?.regulatoryDocument && (
              <Typography variant="body2" color="error">
                {errors?.regulatoryDocument?.message}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={4000}
        onClose={onCloseAlert}
      >
        <Alert onClose={onCloseAlert} severity="info" sx={{ width: "100%" }}>
          {t(COMPANY_INFORMATION_SHOP_ID_COPIED)}
        </Alert>
      </Snackbar>
      {customComponentButtons && customComponentButtons}
    </ContainerPaperSection>
  );
};

export default CompanyInformationForm;
