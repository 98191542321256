import React, { useEffect, useState } from "react";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import TitlePage from "../components/Common/Texts/TitlePage";
import { PAGE_CAMPAIGN_TITLE } from "../onboarding/stepsSelectors";
import {
  CAMPAIGN_DETAIL_TITLE,
  INFORMATION_INVALID_ACCESS,
  LOADING_INFORMATION,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import { useDispatchApp } from "../lib/redux";
import {
  startGetAdsCampaign,
  startGetCurrencyPlatform,
} from "../actions/adsCampaigns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SimpleAlert } from "../utils/alerts";
import {
  ADS_CAMPAIGN_MODE_EDIT,
  ADS_CAMPAIGN_MODE_PREVIEW,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ALERT_ICON_TYPE_ERROR,
} from "../utils/constants";
import Loader from "../components/Common/Loader/Loader";
import AdsCampaignStatistics from "../components/User/AdsCampaigns/AdsCampaign/AdsCampaignStatistics";
import Box from "@mui/material/Box";
import AdsCampaignInformation from "../components/User/AdsCampaigns/AdsCampaign/AdsCampaignInformation";
import AdsCampaignButtonsActions from "../components/User/AdsCampaigns/AdsCampaign/AdsCampaignButtonsActions";

const AdsCampaign = () => {
  const [campaign, setCampaign] = useState({});
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currencyAccount, setCurrencyAccount] = useState();

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");

  const getInitialData = async () => {
    const response = await dispatch(startGetAdsCampaign(campaignID));

    if (response) {
      setCampaign(response);
      onGetCurrencyAccount(response.platform);
      const status = response.status;
      if (status === ADS_CAMPAIGN_STATUS_DRAFT) {
        setMode(ADS_CAMPAIGN_MODE_PREVIEW);
      } else {
        setMode(ADS_CAMPAIGN_MODE_EDIT);
      }
      setIsLoading(false);
    } else {
      SimpleAlert({
        title: t(INFORMATION_INVALID_ACCESS),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      navigate("/ads-campaigns");
    }

    setIsLoading(false);
  };

  const onGetCurrencyAccount = async (platform) => {
    if (!platform) return;
    const response = await dispatch(
      startGetCurrencyPlatform({
        platform,
      })
    );
    if (response) {
      setCurrencyAccount(response);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [campaignID]);

  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }

  return (
    <ContainerPage
      mode="padding"
      hasMarginBottom={true}
      sx={{
        minHeight: "calc(100vh - 64px)",
        mb: mode === ADS_CAMPAIGN_MODE_PREVIEW ? 30 : 15,
      }}
    >
      <TitlePage
        dataTour={PAGE_CAMPAIGN_TITLE}
        sx={{
          fontSize: { xs: "1.9rem", sm: "2.5rem" },
        }}
      >
        {t(CAMPAIGN_DETAIL_TITLE, {
          name: campaign.name || "",
        })}
      </TitlePage>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mt: 2,
          gap: 2,
        }}
      >
        {mode === ADS_CAMPAIGN_MODE_EDIT && (
          <>
            <AdsCampaignButtonsActions
              campaignID={campaignID}
              onChangeData={setCampaign}
              data={campaign}
            />
            <AdsCampaignStatistics
              data={campaign}
              campaignID={campaignID}
              onChangeData={setCampaign}
            />
          </>
        )}
        <AdsCampaignInformation
          data={campaign}
          mode={mode}
          onChangeMode={setMode}
          currency={currencyAccount}
          campaignID={campaignID}
          onChangeData={setCampaign}
        />
      </Box>
    </ContainerPage>
  );
};

export default AdsCampaign;
