import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import logoWooCommerce from "../../../../assets/img/woocommerce-logo.webp";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { useTranslationApp } from "../../../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import {
  startDisconnectWooCommerceIntegration,
  startGetShopifyIntegration,
} from "../../../../actions/shops";
import LinkText from "../../../Common/Texts/LinkText";
import {
  ARE_YOU_SURE,
  BUTTON_CONNECT_WOOCOMMERCE,
  BUTTON_DISCONNECT,
  MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE,
  MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE,
  WOOCOMMERCE,
} from "../../../../locales/keysTranslations";
import {
  SHOPIFY_STORE_TYPE,
  WORDPRESS_WOOCOMMERCE_STORE_TYPE,
} from "../../../../utils/constants";
import LoadingButton from "../../../Common/Buttons/LoadingButton";
import {
  BACKGROUND_COLOR_WOOCOMMERCE,
  COLOR_WOOCOMMERCE,
} from "../../../../utils/colors";
import ModalStore from "../../Stores/ModalStore";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmAlert } from "../../../../utils/alerts";

const WooCommerceSection = () => {
  const [data, setData] = useState(null);
  const [modalWooCommerceOpen, setModalWooCommerceOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const ref = useRef(null);
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [params] = useSearchParams();
  const dispatch = useDispatchApp();
  const stores = useSelectorApp((state) => state.shop.stores);

  const storeIDWooCommerce = Object.keys(stores || {}).find(
    (storeID) => stores[storeID].storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE
  );
  const storeWooCommerce = stores[storeIDWooCommerce];
  const storeIDShopify = Object.keys(stores || {}).find(
    (storeID) => stores[storeID].storeType === SHOPIFY_STORE_TYPE
  );
  const storeShopify = stores[storeIDShopify];

  const focusWooCommerce = params.get("focusWooCommerce");

  const onGetInitialData = async () => {
    const response = await dispatch(startGetShopifyIntegration());
    if (response) {
      setData(response);
    }
    setIsLoading(false);
  };

  const onDisconnectShopify = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE),
      confirmButtonText: t(BUTTON_DISCONNECT),
    });
    if (!result.isConfirmed) return;

    setIsFetching(true);
    const response = await dispatch(startDisconnectWooCommerceIntegration());
    if (response) {
      setData(null);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (focusWooCommerce) {
      setTimeout(() => {
        window.document
          .querySelectorAll(".platforms-connect-section")[0]
          .scrollIntoView({
            inline: "nearest",
            behavior: "smooth",
            block: "center",
          });
      }, 300);
    }
    // eslint-disable-next-line
  }, [focusWooCommerce]);

  useEffect(() => {
    onGetInitialData();
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <Loader />;

  if (storeShopify) return null;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mt: 2,
      }}
      ref={ref}
    >
      {data?.isActive && storeWooCommerce && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: 320,
          }}
        >
          <Avatar
            alt={`Logo WooCommerce`}
            src={logoWooCommerce}
            variant="square"
            sx={{
              mr: 1,
              height: {
                xs: 30,
                sm: 40,
              },
              width: {
                xs: 30,
                sm: 40,
              },
              "& .MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <LinkText
              variant={isMobile ? "body1" : "h6"}
              href={storeWooCommerce?.storeURL}
              target="_blank"
              sx={{
                wordBreak: "break-all",
              }}
            >
              {t(WOOCOMMERCE)}
            </LinkText>
            {isFetching ? (
              <Loader size={20} hasMarginTop={false} />
            ) : (
              <IconButton onClick={onDisconnectShopify}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
      {(!data?.isActive || !storeWooCommerce) && (
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isFetching}
          onClick={() => setModalWooCommerceOpen(true)}
          sx={{
            backgroundColor: BACKGROUND_COLOR_WOOCOMMERCE,
            color: COLOR_WOOCOMMERCE,
            "&:hover": {
              backgroundColor: BACKGROUND_COLOR_WOOCOMMERCE,
              color: COLOR_WOOCOMMERCE,
              filter: "brightness(0.9)",
            },
            minWidth: 230,
          }}
        >
          <Avatar
            alt={`Logo WooCommerce`}
            src={logoWooCommerce}
            variant="square"
            sx={{
              width: "20px",
              height: "20px",
              mr: 1,
              "& .MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
          {t(BUTTON_CONNECT_WOOCOMMERCE)}
        </LoadingButton>
      )}

      {modalWooCommerceOpen && (
        <ModalStore
          modalOpen={modalWooCommerceOpen}
          onCallbackCreateStore={() =>
            setData({
              isActive: true,
            })
          }
          onCloseModal={() => setModalWooCommerceOpen(false)}
          requiredStoreType={WORDPRESS_WOOCOMMERCE_STORE_TYPE}
          mode="create"
          titleCreate={MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE}
          descriptionCreate={
            MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE
          }
          buttonCreate={MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE}
        />
      )}
    </Box>
  );
};

export default WooCommerceSection;
