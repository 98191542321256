export const DISCOVERY_INFLUENCER_DATA = [
  {
    externalId: "199319380",
    platformUsername: null,
    url: "https://www.instagram.com/gabrielatafur",
    imageUrl:
      "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDZqwMezQuCGaZDpqOPjbUqjhm%2BLgdLdcYB5neyiJyC0zzNZeEA70OVZPQ7eB8Gf55JnOPKFBZ4%2B3fNz0hxYOptnWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
    fullName: "Gabriela Tafur",
    introduction:
      "Colombiana. Lawyer, writer, model, TV hostess. \n📍Stanford, CA",
    contentCount: 1194,
    sponsoredPostsPerformance: null,
    isVerified: true,
    platformAccountType: "CREATOR",
    gender: "female",
    ageGroup: "25-34",
    language: "es",
    followerCount: null,
    subscriberCount: null,
    averageLikes: 15322,
    averageDislikes: null,
    averageComments: 96,
    averageViews: 0,
    averageReelsViews: 182572,
    engagementRate: 0.021831,
    location: {
      city: null,
      state: null,
      country: "Colombia",
    },
    reportGeneratedAt: "2023-09-27T15:04:38.907000+00:00",
    updatedAt: "2023-09-21T16:08:11.289000+00:00",
    postsHiddenLikesPercentageValue: 0.176471,
    followers: 701835,
    engagement: 2.18,
    mainAccountName: "gabrielatafur",
    mainPlatform: "instagram",
    mainAudienceGender: "female",
    mainAudienceAgeRange: "25-34",
    mainAudienceCity: "bogota",
    isInvited: false,
  },
];
export const DISCOVERY_INFLUENCERS_DATA = [
  {
    workPlatform: {
      id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
      name: "TikTok",
      logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
    },
    platformUsername: null,
    externalId: "6808213124489004038",
    url: "https://www.tiktok.com/@6808213124489004038",
    imageUrl:
      "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/5fd6f8b77e63f5d74e798e403073ba31~c5_720x720.jpeg",
    fullName: "Ivana",
    introduction: "Pronto empezaré a usar esto bien, denme tiempo 🤍",
    isVerified: false,
    platformAccountType: null,
    gender: "female",
    ageGroup: null,
    language: "es",
    followerCount: null,
    subscriberCount: null,
    contentCount: null,
    engagementRate: 11.490445859872612,
    averageLikes: 721600,
    averageViews: 5100000,
    creatorLocation: {
      city: null,
      state: null,
      country: "Colombia",
    },
    contactDetails: null,
    followers: 62800,
    engagement: 1149.04,
    mainAccountName: "ivanagineth",
    isInvited: false,
  },
  {
    workPlatform: {
      id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
      name: "TikTok",
      logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
    },
    platformUsername: null,
    externalId: "7074603692577850373",
    url: "https://www.tiktok.com/@7074603692577850373",
    imageUrl:
      "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/be2688f65ce31c23b4308188be20d190~c5_720x720.jpeg",
    fullName: "Stefania Quiñones ❤️",
    introduction: "Citas por Instagram ⬆️\nCali-Co🇨🇴📍\nBy: Stefania 👸🏾🫀💇‍♀️✨",
    isVerified: false,
    platformAccountType: null,
    gender: "female",
    ageGroup: null,
    language: "es",
    followerCount: null,
    subscriberCount: null,
    contentCount: null,
    engagementRate: 6.774696189495366,
    averageLikes: 657823,
    averageViews: 9131033,
    creatorLocation: {
      city: null,
      state: null,
      country: "Colombia",
    },
    contactDetails: null,
    followers: 97100,
    engagement: 677.47,
    mainAccountName: "s.obregon2",
    isInvited: false,
  },
  {
    workPlatform: {
      id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
      name: "TikTok",
      logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
    },
    platformUsername: null,
    externalId: "6711083012009903110",
    url: "https://www.tiktok.com/@6711083012009903110",
    imageUrl:
      "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/8cb495c679a1f48c74356ac28c7750c4~c5_720x720.jpeg",
    fullName: "Tatilika",
    introduction: null,
    isVerified: false,
    platformAccountType: null,
    gender: "female",
    ageGroup: null,
    language: "es",
    followerCount: null,
    subscriberCount: null,
    contentCount: null,
    engagementRate: 11.899409448818897,
    averageLikes: 604490,
    averageViews: 5041726,
    creatorLocation: {
      city: null,
      state: null,
      country: "Colombia",
    },
    contactDetails: null,
    followers: 50800,
    engagement: 1189.94,
    mainAccountName: "tatilikaa",
    isInvited: false,
  },
  {
    workPlatform: {
      id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
      name: "TikTok",
      logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
    },
    platformUsername: null,
    externalId: "6803131342324581381",
    url: "https://www.tiktok.com/@6803131342324581381",
    imageUrl:
      "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/5f9cc4140f615d12dbb296a3406c4ce2~c5_720x720.jpeg",
    fullName: "Andrea Gil",
    introduction: null,
    isVerified: false,
    platformAccountType: null,
    gender: "female",
    ageGroup: null,
    language: "es",
    followerCount: null,
    subscriberCount: null,
    contentCount: null,
    engagementRate: 7.632669826224329,
    averageLikes: 483148,
    averageViews: 8625233,
    creatorLocation: {
      city: null,
      state: null,
      country: "Colombia",
    },
    contactDetails: null,
    followers: 63300,
    engagement: 763.27,
    mainAccountName: "andreagilserna",
    isInvited: false,
  },
  {
    workPlatform: {
      id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
      name: "TikTok",
      logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
    },
    platformUsername: null,
    externalId: "56335171720859649",
    url: "https://www.tiktok.com/@56335171720859649",
    imageUrl:
      "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/7e75a595de9e36eb58b711cca75d95ca~c5_720x720.jpeg",
    fullName: "Sofíamontoya",
    introduction: null,
    isVerified: false,
    platformAccountType: null,
    gender: "female",
    ageGroup: null,
    language: "es",
    followerCount: null,
    subscriberCount: null,
    contentCount: null,
    engagementRate: 8.773406374501992,
    averageLikes: 440425,
    averageViews: 2872453,
    creatorLocation: {
      city: null,
      state: null,
      country: "Colombia",
    },
    contactDetails: null,
    followers: 50200,
    engagement: 877.34,
    mainAccountName: "sofiamontoya41",
    isInvited: false,
  },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6963120254789207045",
  //     url: "https://www.tiktok.com/@6963120254789207045",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/b3f3de4d752cd1950b0b83fb262d7127~c5_720x720.jpeg",
  //     fullName: "ailinrosa952",
  //     introduction: null,
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 6.893155258764608,
  //     averageLikes: 412900,
  //     averageViews: 27300000,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 59900,
  //     engagement: 689.32,
  //     mainAccountName: "ailinrosa952",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6958076800505971718",
  //     url: "https://www.tiktok.com/@6958076800505971718",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/4b12343e83eb9205579161ee986a09eb~c5_720x720.jpeg",
  //     fullName: "Rc_barraza",
  //     introduction: "I'm RC from Colombia 19 years",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 7.479202898550724,
  //     averageLikes: 412852,
  //     averageViews: 3050143,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 55200,
  //     engagement: 747.92,
  //     mainAccountName: "robert_rc19",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6892158655291458562",
  //     url: "https://www.tiktok.com/@6892158655291458562",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/c13da1bd3c1e05f47ac9dde349a2a5a4.jpeg",
  //     fullName: "Danna Paola Rivera M",
  //     introduction: "Actriz🎬 Cantante🎤 Modelo👠\nMEXICANA🇲🇽",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 5.292401628222524,
  //     averageLikes: 390050,
  //     averageViews: 4100000,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 73700,
  //     engagement: 529.24,
  //     mainAccountName: "dannapaolanews",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6693606957745996806",
  //     url: "https://www.tiktok.com/@6693606957745996806",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/f7e3bc8699d1a05f0265e7acbfb5b897~c5_720x720.jpeg",
  //     fullName: "^^",
  //     introduction:
  //       "any middle aged actress could run me over and i’d thank her.",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "en",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 4.609185628742515,
  //     averageLikes: 384867,
  //     averageViews: 2058560,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 83500,
  //     engagement: 460.92,
  //     mainAccountName: "imblanchetts",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6868636408855020550",
  //     url: "https://www.tiktok.com/@6868636408855020550",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/c5a2e15f153cd61aa6742aa494b5ddcd~c5_720x720.jpeg",
  //     fullName: "Samantha Agudelo",
  //     introduction: "💜💚",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 4.134155005382131,
  //     averageLikes: 384063,
  //     averageViews: 2550236,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 92900,
  //     engagement: 413.42,
  //     mainAccountName: "samanthaagudelo09",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6876811448568890373",
  //     url: "https://www.tiktok.com/@6876811448568890373",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/ed3510da5eb0351b195c02bb3e14f586~c5_720x720.jpeg",
  //     fullName: "✨",
  //     introduction: "♡︎𝑽𝒂𝒍𝒐𝒓𝒂 𝒍𝒐 𝒒𝒖𝒆 𝒕𝒊𝒆𝒏𝒆𝒔♡︎",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 4.2353496115427305,
  //     averageLikes: 381605,
  //     averageViews: 3385950,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 90100,
  //     engagement: 423.53,
  //     mainAccountName: "comedia.series",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6958930651366032390",
  //     url: "https://www.tiktok.com/@6958930651366032390",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/d1af82580054849b28757f9748539384~c5_720x720.jpeg",
  //     fullName: "♡",
  //     introduction: "Domi mi plantita 💘",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 4.835082592121982,
  //     averageLikes: 380521,
  //     averageViews: 1793811,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 78700,
  //     engagement: 483.51,
  //     mainAccountName: "dominikmimujercita",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6831418747078280198",
  //     url: "https://www.tiktok.com/@6831418747078280198",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/72cf74f1561eb9956eda448daf9d1ae8~c5_720x720.jpeg",
  //     fullName: "ㅤS4RA :]",
  //     introduction: "Dice venite yo le digo Messi ;$",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 6.973534971644613,
  //     averageLikes: 368900,
  //     averageViews: 3400000,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 52900,
  //     engagement: 697.35,
  //     mainAccountName: "sariitoo",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6809741939710903302",
  //     url: "https://www.tiktok.com/@6809741939710903302",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/c7097d11b3a9e60efd00a26c52d65b94~c5_720x720.jpeg",
  //     fullName: "Maria Franco",
  //     introduction: "Mamá de una fan de Louis Tomlinson y Harry Styles",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "en",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 5.457536231884058,
  //     averageLikes: 338913,
  //     averageViews: 1732439,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 62100,
  //     engagement: 545.75,
  //     mainAccountName: "marialufe0",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6801236664238654469",
  //     url: "https://www.tiktok.com/@6801236664238654469",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/3c65418d130e4c5d86d768e02464e09e~c5_720x720.jpeg",
  //     fullName: "Mariiana H. Noreña",
  //     introduction: "🐶🐾❤️‍🩹",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 5.162565687789799,
  //     averageLikes: 334018,
  //     averageViews: 2403724,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 64700,
  //     engagement: 516.26,
  //     mainAccountName: "marianan___",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6901062985033221122",
  //     url: "https://www.tiktok.com/@6901062985033221122",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/735c5ced058c0bf76a596973ec4a4ca5~c5_720x720.jpeg",
  //     fullName: "SamuelXD",
  //     introduction: null,
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 3.6923892773892772,
  //     averageLikes: 316807,
  //     averageViews: 4209175,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 85800,
  //     engagement: 369.24,
  //     mainAccountName: "samuel_videosxxxd",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "7098776760813028358",
  //     url: "https://www.tiktok.com/@7098776760813028358",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/b7624d198ab8e2ae8927f17f7e8f757a~c5_720x720.jpeg",
  //     fullName: "🖤.",
  //     introduction: "♡.",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 4.34587982832618,
  //     averageLikes: 303777,
  //     averageViews: 1416315,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 69900,
  //     engagement: 434.59,
  //     mainAccountName: "ale_mix372",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6782204098512192517",
  //     url: "https://www.tiktok.com/@6782204098512192517",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/d8d25044acdcda022ca5522e68381c32~c5_720x720.jpeg",
  //     fullName: "Dahiana García",
  //     introduction: "i:𝐋☹︎𝐕𝐄",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 4.37795631825273,
  //     averageLikes: 280627,
  //     averageViews: 2625894,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 64100,
  //     engagement: 437.8,
  //     mainAccountName: "garcia_chica",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "165376932174290944",
  //     url: "https://www.tiktok.com/@165376932174290944",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/83798dc358133db685b0041198354370~c5_720x720.jpeg",
  //     fullName: "Shay",
  //     introduction: "21\n🇨🇴🇧🇷🇵🇪",
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 3.184954604409857,
  //     averageLikes: 245560,
  //     averageViews: 900768,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 77100,
  //     engagement: 318.5,
  //     mainAccountName: "shayna_bt4",
  //     isInvited: false,
  //   },
  //   {
  //     workPlatform: {
  //       id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  //       name: "TikTok",
  //       logoUrl: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  //     },
  //     platformUsername: null,
  //     externalId: "6860605875604145158",
  //     url: "https://www.tiktok.com/@6860605875604145158",
  //     imageUrl:
  //       "https://p16-amd-va.tiktokcdn.com/tos-maliva-avt-0068/73333f62a62146789891da5c84c21901~c5_720x720.jpeg",
  //     fullName: "💟",
  //     introduction: null,
  //     isVerified: false,
  //     platformAccountType: null,
  //     gender: "female",
  //     ageGroup: null,
  //     language: "es",
  //     followerCount: null,
  //     subscriberCount: null,
  //     contentCount: null,
  //     engagementRate: 3.67258114374034,
  //     averageLikes: 237616,
  //     averageViews: 1002378,
  //     creatorLocation: {
  //       city: null,
  //       state: null,
  //       country: "Colombia",
  //     },
  //     contactDetails: null,
  //     followers: 64700,
  //     engagement: 367.26,
  //     mainAccountName: "vvc757",
  //     isInvited: false,
  //   },
];
