import types from "../types";
import {
  // PRIMARY_COLOR,
  // PRIMARY_COLOR_TEXT,
  RED_COLOR,
  // SECONDARY_COLOR,
  // SECONDARY_COLOR_TEXT,
  // PAPER_BACKGROUND_COLOR,
  // PAPER_CONTRAST_TEXT,
  PAPER_BACKGROUND_COLOR_DARK,
  PAPER_CONTRAST_TEXT_DARK,
  PAPER_SECONDARY_BACKGROUND_COLOR_DARK,
  PAPER_SECONDARY_CONTRAST_TEXT_DARK,
  FIELD_PLACEHOLDER_COLOR_DARK,
  // FIELD_PLACEHOLDER_COLOR,
  PRIMARY_COLOR_DARK,
  SECONDARY_COLOR_DARK,
  PRIMARY_COLOR_TEXT_DARK,
  SECONDARY_COLOR_TEXT_DARK,
  GREY_COLOR,
  GREY_DARK_COLOR,
  TABLE_HEADER_DARK,
  LIST_MENU_BACKGROUND_COLOR_DARK,
} from "../utils/colors";
import { BORDER_RADIUS, FONT_FAMILY } from "../utils/constants";

const themeCommon = {
  typography: {
    fontFamily: FONT_FAMILY,
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // "& .MuiFilledInput-root": {
          //   paddingTop: `${3 * 8}px`,
          //   paddingBottom: `${1 * 8}px`,
          // },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: `${BORDER_RADIUS * 4}px !important`,
          "&.MuiFilledInput-root": {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
            "&.Mui-disabled": {
              backgroundColor: "rgba(255, 255, 255, 0.05)",
            },
            "&.Mui-focused": {
              backgroundColor: "rgba(255, 255, 255, 0.07)",
            },
            ":hover": {
              backgroundColor: "rgba(255, 255, 255, 0.07)",
            },
            ":before": {
              borderBottom: "none !important",
            },
            ":after": {
              borderBottom: "none !important",
            },
          },
        },
        input: ({ theme }) => {
          return {
            "&:-webkit-autofill": {
              boxShadow: `0 0 0 100px #202020 inset !important`,
              WebkitTextFillColor:
                theme.palette.background.paperSecondaryContrastText,
              borderRadius: "unset !important",
              mixBlendMode: "color-dodge",
            },
          };
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: `${BORDER_RADIUS * 4}px !important`,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            color: `${theme.palette.text.primary}`,
            "&.Mui-focused": {
              color: "inherit",
            },
          };
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            "&.Mui-checked": {
              color: theme.palette.text.primary,
            },
          };
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            "&.Mui-checked": {
              color: theme.palette.text.primary,
            },
          };
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            color: theme.palette.text.primary,
          };
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.palette.grey.dark,
          };
        },
        bar: ({ theme }) => {
          return {
            backgroundColor: theme.palette.white.main,
          };
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: "rgba(0, 0, 0, 0.54)",
        },
        root: ({ theme }) => ({
          "&.Mui-disabled": {
            backgroundColor: theme.palette.grey.dark,
          },
        }),
      },
    },
  },
  favicon: "https://clapp-prod.s3.amazonaws.com/Buzzly/favicon.ico",
  nameBusiness: "Buzzly",
  logoInvoice: "https://clapp-prod.s3.amazonaws.com/Buzzly/logo.png",
};

// const themeLight = {
//   ...themeCommon,
//   logo: "https://clapp-prod.s3.amazonaws.com/Buzzly/logo.png",
//   logoChat: "https://clapp-prod.s3.amazonaws.com/Buzzly/favicon.ico",
//   logoLogin: "https://clapp-prod.s3.amazonaws.com/Buzzly/logo.png",
//   logoNavbar: "https://clapp-prod.s3.amazonaws.com/Buzzly/logo-white.png",
//   logoError: "https://clapp-prod.s3.amazonaws.com/Buzzly/logo.png",
//   palette: {
//     mode: "light",
//     primary: {
//       main: PRIMARY_COLOR,
//       contrastText: PRIMARY_COLOR_TEXT,
//     },
//     secondary: {
//       main: SECONDARY_COLOR,
//       contrastText: SECONDARY_COLOR_TEXT,
//     },
//     tertiary: {
//       main: "#5cecff",
//     },
//     white: {
//       main: "#ffffff",
//       contrastText: "#000000",
//     },
//     error: {
//       main: RED_COLOR,
//     },
//     grey: {
//       main: GREY_COLOR,
//       dark: GREY_DARK_COLOR,
//     },
//     hover: {
//       main: "brightness(0.9)",
//     },
//     background: {
//       paper: PAPER_BACKGROUND_COLOR,
//       paperContrastText: PAPER_CONTRAST_TEXT,
//       paperSecondary: PAPER_BACKGROUND_COLOR,
//       paperSecondaryContrastText: PAPER_CONTRAST_TEXT,
//       default: PAPER_BACKGROUND_COLOR,
//     },
//     table: {
//       header: {
//         background: PAPER_BACKGROUND_COLOR,
//         color: PAPER_CONTRAST_TEXT,
//       },
//       cell: {
//         background: PAPER_BACKGROUND_COLOR,
//         color: PAPER_CONTRAST_TEXT,
//       },
//     },
//     fields: {
//       placeholder: FIELD_PLACEHOLDER_COLOR,
//     },
//   },
// };

const themeDark = {
  ...themeCommon,
  logo: "https://clapp-prod.s3.us-east-1.amazonaws.com/Buzzly/logo-ugc-white.webp",
  logoChat: "https://clapp-prod.s3.amazonaws.com/Buzzly/favicon.ico",
  logoLogin:
    "https://clapp-prod.s3.us-east-1.amazonaws.com/Buzzly/logo-ugc-white.webp",
  logoNavbar:
    "https://clapp-prod.s3.us-east-1.amazonaws.com/Buzzly/logo-ugc-white.webp",
  logoError:
    "https://clapp-prod.s3.us-east-1.amazonaws.com/Buzzly/logo-ugc-white.webp",
  palette: {
    mode: "dark",
    primary: {
      main: PRIMARY_COLOR_DARK,
      contrastText: PRIMARY_COLOR_TEXT_DARK,
    },
    secondary: {
      main: SECONDARY_COLOR_DARK,
      contrastText: SECONDARY_COLOR_TEXT_DARK,
    },
    tertiary: {
      main: "#5cecff",
      contrastText: "#000000",
    },
    white: {
      main: "#ffffff",
      contrastText: "#000000",
    },
    grey: {
      main: GREY_COLOR,
      dark: GREY_DARK_COLOR,
    },
    error: {
      main: RED_COLOR,
      contrastText: "#ffffff",
    },
    hover: {
      main: "brightness(1.1)",
    },
    background: {
      paper: PAPER_BACKGROUND_COLOR_DARK,
      paperContrastText: PAPER_CONTRAST_TEXT_DARK,
      paperSecondary: PAPER_SECONDARY_BACKGROUND_COLOR_DARK,
      paperSecondaryContrastText: PAPER_SECONDARY_CONTRAST_TEXT_DARK,
      listMenu: LIST_MENU_BACKGROUND_COLOR_DARK,
      default: PAPER_BACKGROUND_COLOR_DARK,
    },
    table: {
      header: {
        background: TABLE_HEADER_DARK,
        color: PAPER_SECONDARY_CONTRAST_TEXT_DARK,
      },
      cell: {
        backgroundOdd: TABLE_HEADER_DARK,
        backgroundEven: PAPER_BACKGROUND_COLOR_DARK,
        color: PAPER_SECONDARY_CONTRAST_TEXT_DARK,
      },
    },
    fields: {
      placeholder: FIELD_PLACEHOLDER_COLOR_DARK,
    },
  },
};

const initialState = {
  errorText: "",
  error: false,
  slideMenuIsOpen: false,
  modalImage: {
    open: false,
    url: "",
  },
  isActiveSoundDelivery: false,
  showModalBlockLastInvite: false,
  showModalSelectPackage: false,
  showModalOnboardingRequiredInformation: false,
  notification: {
    open: false,
    vertical: "bottom",
    horizontal: "right",
    message: "",
    action: "",
  },
  menusWithActions: {
    publications: false,
    deliveries: false,
    reports: false,
  },
  showBackdrop: false,
  showOpenChatButton: true,
  storesWithNewOrder: {},
  // theme: themeLight,
  theme: themeDark,
  version: "",
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UI_SET_THEME_BUSINESS: {
      return {
        ...state,
        theme: {
          ...state.theme,
          palette: {
            ...state.theme.palette,
            primary: {
              main:
                action.payload.primaryColor || state.theme.palette.primary.main,
              contrastText:
                action.payload.textPrimaryColor ||
                state.theme.palette.primary.contrastText,
            },
            secondary: {
              main:
                action.payload.secondaryColor ||
                state.theme.palette.secondary.main,
              contrastText:
                action.payload.textSecondaryColor ||
                state.theme.palette.secondary.contrastText,
            },
          },
          logo: action.payload.logo || state.theme.logo,
          logoChat: action.payload.logoChat || state.theme.logoChat,
          logoLogin: action.payload.logoLogin || state.theme.logoLogin,
          logoNavbar: action.payload.logoNavbar || state.theme.logoNavbar,
          logoError: action.payload.logoError || state.theme.logoError,
          logoInvoice: action.payload.logoInvoice || state.theme.logoInvoice,
          favicon: action.payload.favicon || state.theme.favicon,
          nameBusiness: action.payload.nameBusiness || state.theme.nameBusiness,
        },
      };
    }
    case types.UI_SET_ERROR:
      return {
        ...state,
        errorText: action.payload,
        error: true,
      };
    case types.UI_CLEAR_ERROR:
      return {
        ...state,
        errorText: "",
        error: false,
      };
    case types.UI_SET_OPEN_SLIDE_MENU:
      return {
        ...state,
        slideMenuIsOpen: action.payload,
      };
    case types.UI_SET_SHOW_OPEN_CHAT_BUTTON:
      return {
        ...state,
        showOpenChatButton:
          typeof action.payload === "boolean"
            ? action.payload
            : !state.showOpenChatButton,
      };
    case types.UI_SET_OPEN_MODAL_IMAGE:
      return {
        ...state,
        modalImage: {
          open: action.payload.open,
          url: action.payload.url,
        },
      };
    case types.UI_SET_ACTIVE_SOUND_DELIVERY:
      return {
        ...state,
        isActiveSoundDelivery: action.payload,
      };
    case types.UI_SET_OPEN_NOTIFICATION:
      return {
        ...state,
        notification: {
          open: action.payload.open,
          vertical: action.payload.vertical,
          horizontal: action.payload.horizontal,
          message: action.payload.message,
          action: action.payload.action,
        },
      };
    case types.UI_SET_SHOW_BACKDROP:
      return {
        ...state,
        showBackdrop: action.payload,
      };
    case types.UI_SET_NEW_ORDER_IN_STORE:
      return {
        ...state,
        storesWithNewOrder: {
          ...state.storesWithNewOrder,
          [action.payload.store]: action.payload.status,
        },
      };
    case types.UI_ENABLE_MENU_WITH_ACTION:
      return {
        ...state,
        menusWithActions: {
          ...state.menusWithActions,
          [action.payload]: true,
        },
      };
    case types.UI_DISABLE_MENU_WITH_ACTION:
      return {
        ...state,
        menusWithActions: {
          ...state.menusWithActions,
          [action.payload]: false,
        },
      };
    case types.SET_VERSION: {
      return {
        ...state,
        version: action.payload,
      };
    }
    case types.UI_SHOW_MODAL_BLOCK_LAST_INVITE: {
      return {
        ...state,
        showModalBlockLastInvite: action.payload,
      };
    }
    case types.UI_SHOW_MODAL_SELECT_PACKAGE: {
      return {
        ...state,
        showModalSelectPackage: action.payload,
      };
    }
    case types.UI_SHOW_MODAL_ONBOARDING_REQUIRED_INFORMATION: {
      return {
        ...state,
        showModalOnboardingRequiredInformation: action.payload,
      };
    }
    case types.RESET_UI_STATE: {
      return {
        ...initialState,
        version: state.version,
        theme: state.theme,
      };
    }
    case types.GLOBAL_RESET_STATE:
      return {
        ...initialState,
        version: state.version,
        theme: state.theme,
      };
    default:
      return state;
  }
};
