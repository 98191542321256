import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import logoTiktok from "../../../assets/img/logo-tiktok.webp";
import logoIG from "../../../assets/img/logo-ig.webp";
import logoFB from "../../../assets/img/logo-fb.webp";
import logoYT from "../../../assets/img/logo-yt.png";
import {
  numberCompactFormat,
  numberDecimal,
} from "../../../utils/numberFormats";
import { YOUTUBE } from "../../../utils/constants";

const SocialNetworks = {
  tiktok: logoTiktok,
  instagram: logoIG,
  facebook: logoFB,
  youtube: logoYT,
};
const SocialNetworkCell = ({
  platform,
  sign = "",
  metric = "audience",
  data,
}) => {
  const getNumberDisplay = () => {
    const signDisplay = metric === "audiencie" ? "" : sign;
    let number =
      metric === "audience"
        ? numberCompactFormat(data.followers)
        : numberDecimal(data.engagement ?? data.likes / data.followers);
    if (number === 0 || number === "0" || number === 0.01) return "-";
    return `${number}${signDisplay}`;
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 1,
      }}
    >
      {data && (
        <Avatar
          alt={`Logo de ${platform}`}
          src={SocialNetworks[platform]}
          variant="square"
          sx={{
            mr: 1,
            width: `${platform === YOUTUBE ? "43.3px" : "30px"}`,
            height: "30px",
          }}
        />
      )}
      <Typography variant="body1">{data ? getNumberDisplay() : "-"}</Typography>
    </Box>
  );
};

export default SocialNetworkCell;
