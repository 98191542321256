import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "../Buttons/Button";
import { getLinkRedirect } from "../../../utils/urls";
import { useDispatchApp } from "../../../lib/redux";
import {
  getInfluencerIsFavoriteRedux,
  getRole,
} from "../../../actions/getters";
import { DESIGNER_ROLE } from "../../../utils/constants";
import i18next from "i18next";
import {
  BUTTON_EDIT_INVITE,
  BUTTON_VIEW_REQUEST,
} from "../../../locales/keysTranslations";
import _ from "lodash";
import Loader from "../Loader/Loader";
import InfluencerIsFavoriteIcon from "./InfluencerIsFavoriteIcon";
import LinkText from "../Texts/LinkText";

const NameInfluencerCell = ({
  userID = "",
  data = {},
  showButton = true,
  textButton = i18next.t(BUTTON_VIEW_REQUEST),
  textButtonInvited = i18next.t(BUTTON_EDIT_INVITE),
  isInvited = false,
  isExternal = false,
  color = "secondary",
  dataTourButton = "",
  disabled,
  onClickButton,
  maxWidthButton,
}) => {
  const dispatch = useDispatchApp();
  const role = dispatch(getRole());

  const isDesigner = role === DESIGNER_ROLE;

  const isFavorite = dispatch(getInfluencerIsFavoriteRedux(userID));

  return (
    <Box
      sx={{
        minWidth: 140,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {_.isEmpty(data) ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              mt: isFavorite && !isDesigner ? -4 : 0,
            }}
          >
            <InfluencerIsFavoriteIcon
              userID={userID}
              sx={{
                position: "relative",
                zIndex: 200,
                top: 25,
                left: 25,
              }}
            />
            {data?.imageURL && <Avatar src={data?.imageURL} />}
          </Box>
          <LinkText
            variant="body1"
            href={getLinkRedirect({
              role,
              userID,
              isExternal,
              influencer: data,
            })}
            target="_blank"
            color="inherit"
          >
            {data.mainAccountName}
          </LinkText>
          {showButton && (
            <Button
              variant="contained"
              color={color}
              disableElevation
              onClick={onClickButton}
              disabled={disabled}
              dataTour={dataTourButton}
              sx={{
                mt: 1,
                width: "100%",
                maxWidth: maxWidthButton || {
                  xs: "100%",
                  lg: "80%",
                },
              }}
            >
              {isInvited ? textButtonInvited : textButton}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default NameInfluencerCell;
