import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  THIS_CREATOR_IS_FAVORITE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  getInfluencerHasPreRegisteredProcess,
  getInfluencerIsFavoriteRedux,
  getInfluencerRedux,
  getRole,
} from "../../../actions/getters";
import Loader from "../Loader/Loader";
import {
  startDeleteInfluencerFavorite,
  startSaveInfluencerFavorite,
} from "../../../actions/influencers";
import { useTheme } from "@emotion/react";
import {
  PRE_REGISTERED_INFLUENCER_FAVORITE_PROCESS,
  ROLES_ADMIN_SHOPS,
} from "../../../utils/constants";
import { useTour } from "@reactour/tour";

const InfluencerIsFavoriteIcon = ({
  userID,
  showActions = false,
  sx,
  modeColor = "black",
}) => {
  const [isHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const dispatch = useDispatchApp();
  const role = dispatch(getRole());
  const { t } = useTranslationApp();
  const { isOpen } = useTour();

  const influencer = dispatch(getInfluencerRedux(userID));
  const isFavorite = dispatch(getInfluencerIsFavoriteRedux(userID));
  const preRegisteredInfluencersProcess = useSelectorApp(
    (state) => state?.influencers?.preRegisteredInfluencersProcess
  );
  const hasProcessFavorite = dispatch(
    getInfluencerHasPreRegisteredProcess({
      mainAccountName: influencer.mainAccountName,
      mainPlatform: influencer.mainPlatform,
      type: PRE_REGISTERED_INFLUENCER_FAVORITE_PROCESS,
      preRegisteredInfluencersProcess,
    })
  );

  const finalShowActions = showActions && !hasProcessFavorite;

  const iconType = isFavorite ? FavoriteIcon : FavoriteBorderIcon;
  const iconActionType = isHover
    ? isFavorite
      ? FavoriteBorderIcon
      : FavoriteIcon
    : iconType;
  const IconToShow = showActions ? iconActionType : iconType;
  const colorFavorite =
    modeColor === "white"
      ? theme.palette.white.main
      : theme.palette.tertiary.main;
  const colorBackground =
    modeColor === "white"
      ? theme.palette.tertiary.main
      : theme.palette.primary.main;

  const getTooltipText = () => {
    if (!finalShowActions) return t(THIS_CREATOR_IS_FAVORITE);

    if (isFavorite) return t(REMOVE_FROM_FAVORITES);

    return t(ADD_TO_FAVORITES);
  };

  const onClickAction = async () => {
    if (isOpen) return;
    if (!finalShowActions) return;

    setIsLoading(true);

    if (isFavorite) {
      await dispatch(startDeleteInfluencerFavorite(userID));
    } else {
      await dispatch(startSaveInfluencerFavorite(userID));
    }

    setIsLoading(false);
  };

  if (!ROLES_ADMIN_SHOPS.includes(role)) return null;
  return (
    <Tooltip title={getTooltipText()}>
      <Box
        sx={{
          mb: 1,
          width: "fit-content",
          backgroundColor: !showActions ? colorBackground : "transparent",
          borderRadius: 4,
          padding: isFavorite || showActions ? "6px" : "0px",
          ...sx,
        }}
      >
        {isLoading ? (
          <Loader
            size={20}
            hasMarginTop={false}
            colorCustom={
              modeColor === "white"
                ? theme.palette.text.primary
                : theme.palette.secondary.main
            }
          />
        ) : (
          <IconToShow
            onClick={(e) => {
              e.stopPropagation();
              onClickAction(e);
            }}
            sx={{
              color: colorFavorite,
              display: showActions || isFavorite ? "block" : "none",
              "&:hover": {
                cursor: finalShowActions ? "pointer" : "default",
                color: colorFavorite,
                transform: finalShowActions
                  ? isFavorite
                    ? "scale(0.8)"
                    : "scale(1.3)"
                  : "none",
                transition: showActions ? "1s" : "0s",
              },
              fontSize: showActions ? "1.5em" : "1em",
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default InfluencerIsFavoriteIcon;
