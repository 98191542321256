import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "../../../Common/Buttons/LoadingButton";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ALERT_ICON_TYPE_SUCCESS,
  META,
  META_WHITE,
} from "../../../../utils/constants";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import {
  ADS_CAMPAIGN_HAS_BEEN_ACTIVATE,
  ADS_CAMPAIGN_HAS_BEEN_DUPLICATE,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  BUTTON_ACTIVATE_CAMPAIGN,
  BUTTON_DEACTIVATE_CAMPAIGN,
  BUTTON_DUPLICATE,
  META_CAMPAIGN,
  // TIKTOK_CAMPAIGN,
} from "../../../../locales/keysTranslations";
import ButtonMenu from "../../../Common/Buttons/ButtonMenu";
import { logoPlatform } from "../../../../utils/socialNetworks";
import { useTranslationApp } from "../../../../lib/i18next";
import { useDispatchApp } from "../../../../lib/redux";
import {
  startChangeStatusAdsCampaign,
  startCreateAdsCampaign,
} from "../../../../actions/adsCampaigns";
import { useNavigate } from "react-router-dom";
import { SimpleAlert } from "../../../../utils/alerts";

const AdsCampaignButtonsActions = ({ campaignID, onChangeData, data = {} }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  const onChangeStatusCampaign = async (newStatus) => {
    setIsSaving(true);
    const response = await dispatch(
      startChangeStatusAdsCampaign({
        campaignID,
        status: newStatus,
        platform: data.platform,
      })
    );
    setIsSaving(false);
    if (response) {
      SimpleAlert({
        title:
          newStatus === ADS_CAMPAIGN_STATUS_ACTIVE
            ? t(ADS_CAMPAIGN_HAS_BEEN_ACTIVATE)
            : t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onChangeData({
        ...data,
        status: newStatus,
      });
    }
  };

  const onDuplicateCampaign = async (platform) => {
    const newCampaign = {
      platform,
      status: ADS_CAMPAIGN_STATUS_DEACTIVATED,
      locations: data.locations,
      objective: data.objective,
      name: data.name,
      typeBudget: data.typeBudget,
      budget: data.budget,
      ageRange: data.ageRange,
      genders: data.genders,
      interests: data.interests,
      description: data.description,
      mediaUrl: data.mediaUrl,
    };
    setIsDuplicating(true);
    const campaignID = await dispatch(startCreateAdsCampaign(newCampaign));

    setIsDuplicating(false);

    if (campaignID) {
      SimpleAlert({
        title: t(ADS_CAMPAIGN_HAS_BEEN_DUPLICATE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      navigate(`/ads-campaign?campaignID=${campaignID}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      {data.status !== ADS_CAMPAIGN_STATUS_FINISHED && (
        <LoadingButton
          loading={isSaving}
          type="submit"
          color="error"
          onClick={() => {
            onChangeStatusCampaign(
              data.status === ADS_CAMPAIGN_STATUS_DEACTIVATED
                ? ADS_CAMPAIGN_STATUS_ACTIVE
                : ADS_CAMPAIGN_STATUS_DEACTIVATED
            );
          }}
          sx={{
            background:
              data.status === ADS_CAMPAIGN_STATUS_DEACTIVATED &&
              GRADIENT_PURPLE_FUCHSIA,
            color:
              data.status === ADS_CAMPAIGN_STATUS_DEACTIVATED
                ? `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT}`
                : "error.contrastText",
          }}
        >
          {t(
            data.status === ADS_CAMPAIGN_STATUS_DEACTIVATED
              ? BUTTON_ACTIVATE_CAMPAIGN
              : BUTTON_DEACTIVATE_CAMPAIGN
          )}
        </LoadingButton>
      )}
      <ButtonMenu
        loading={isDuplicating}
        textButton={BUTTON_DUPLICATE}
        options={[
          {
            label: META_CAMPAIGN,
            onClick: () => onDuplicateCampaign(META),
            icon: (
              <Avatar
                src={logoPlatform(META_WHITE)}
                variant="square"
                sx={{
                  width: "20px",
                  height: "auto",
                  mr: 1,
                }}
              />
            ),
          },
          // {
          //   label: TIKTOK_CAMPAIGN,
          //   onClick: () => onDuplicateCampaign(TIKTOK),
          //   icon: (
          //     <Avatar
          //       src={logoPlatform(TIKTOK)}
          //       variant="square"
          //       sx={{
          //         width: "20px",
          //         height: "20px",
          //         mr: 1,
          //       }}
          //     />
          //   ),
          // },
        ]}
        sx={{
          minWidth: 140,
        }}
      />
    </Box>
  );
};

export default AdsCampaignButtonsActions;
