import React, { useState } from "react";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import AutomaticInvitesContainer from "./AutomaticInvitesContainer";
import ContainerModalActions from "../../../Common/Containers/ContainerModalActions";
import { BUTTON_EDIT } from "../../../../locales/keysTranslations";
import DialogActions from "@mui/material/DialogActions";
import ModalAutomaticInvitesTypeForm from "../../../Common/Modal/ModalAutomaticInvitesTypeForm";

const ModalAutomaticInvitesConfiguration = ({
  modalOpen,
  from = "invitations",
  onCloseModal,
}) => {
  const [
    modalAutomaticInvitesTypeFormOpen,
    setModalAutomaticInvitesTypeFormOpen,
  ] = useState(false);
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
    >
      <AutomaticInvitesContainer
        from={from}
        hasDividers={false}
        elevation={0}
        sx={{
          mt: 0,
          p: 0,
          backgroundColor: "background.paper",
        }}
      />
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          sx={{
            mt: 2,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_EDIT,
            onClick: () => setModalAutomaticInvitesTypeFormOpen(true),
          }}
        />
      </DialogActions>
      {modalAutomaticInvitesTypeFormOpen && (
        <ModalAutomaticInvitesTypeForm
          modalOpen={modalAutomaticInvitesTypeFormOpen}
          onCloseModal={() => setModalAutomaticInvitesTypeFormOpen(false)}
          onCallbackSave={() => setModalAutomaticInvitesTypeFormOpen(false)}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalAutomaticInvitesConfiguration;
