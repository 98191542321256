import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { CATEGORIES, COMMENT } from "../../../../locales/keysTranslations";

const ModalMediaPublicationFeedback = ({ data }) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ width: "100%", maxWidth: "75ch" }}>
      <Typography variant="body1" sx={{ color: "#fff" }}>{`${t(
        CATEGORIES
      )}: ${data?.categories?.map((category) => category)}`}</Typography>

      <Typography variant="body1" sx={{ color: "#fff" }}>
        {`${t(COMMENT)}: ${data?.comment}`}
      </Typography>
    </Box>
  );
};

export default ModalMediaPublicationFeedback;
