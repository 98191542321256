import {
  equalTo,
  get,
  limitToFirst,
  orderByChild,
  orderByKey,
  query,
  ref,
  remove,
  set,
  startAfter,
  update,
} from "firebase/database";
import { db, firestore } from "../firebase";
import axios from "../lib/axios";
import types from "../types";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_WARNING,
  PAYMENT_MODEL_PACKAGE,
  PAYMENT_MODEL_USE,
  PLAN_COLLABORATION,
} from "../utils/constants";
import { renewToken } from "./auth";
import {
  deleteInfluencer,
  saveInfluencerInfo,
  saveStatisticsInfluencer,
  startGetInfluencer,
  startGetInfluencersByUserIDs,
} from "./influencers";
import { createAdminAccount } from "./users";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { deleteDeliveryOwner } from "./deliveries";
import {
  startActiveShopFirstTime,
  startGetShopsByShopIDs,
  validateShopDataToActive,
} from "./shops";
import { encryptPassword, getObjectError } from "../utils/formats";
import {
  ARE_YOU_SURE,
  BUTTON_DELETE,
  DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP,
  WARNING,
} from "../locales/keysTranslations";
import i18next from "i18next";
import { getNameBusinessTheme, getUserID } from "./getters";
import { startGetInvitesByShop } from "./invites";

const FUNCTIONS_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startGenerateStatistics =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { startDate, endDate },
        url: `${FUNCTIONS_URL}/generateStatistics`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const startGenerateReports =
  ({ startDate, endDate, report, shops, onlyShopsActive }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { startDate, endDate, report, shops, onlyShopsActive },
        url: `${FUNCTIONS_URL}/generateReports`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGenerateReportShop =
  ({ startDate, endDate, shopID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { startDate, endDate, shopID },
        url: `${FUNCTIONS_URL}/generateReportShop`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGetInvitationsByUser =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, `invites`),
        orderByChild("userID"),
        equalTo(userID)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const shopIDs = [];
        snapshot.forEach((snapshotOrder) => {
          shopIDs.push(snapshotOrder.val().shopID);
        });
        await dispatch(startGetShopsByShopIDs({ shopIDs }));
        const data = snapshot.val();
        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGetDetailPaymentApplicantData =
  ({ userID, campaignID, shopID }) =>
  async (dispatch) => {
    try {
      const influencerData = (await dispatch(startGetInfluencer(userID))) ?? {};
      const snapshotShop = await get(ref(db, `shops/${shopID}/businessName`));
      const shopData = snapshotShop.val() ?? "";
      const snapshotCampaign = await get(
        ref(db, `campaigns/${campaignID}/name`)
      );
      const campaignData = snapshotCampaign.val() ?? "";
      return {
        ok: true,
        influencer: influencerData,
        shop: shopData,
        campaign: campaignData,
      };
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGetInvitationsInactiveByUser =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, `invitesInactive`),
        orderByChild("userID"),
        equalTo(userID)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const shopIDs = [];
        snapshot.forEach((snapshotOrder) => {
          shopIDs.push(snapshotOrder.val().shopID);
        });
        await dispatch(startGetShopsByShopIDs({ shopIDs }));
        const data = snapshot.val();
        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const startGetOrdersByUser =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, `orders`),
        orderByChild("userID"),
        equalTo(userID)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const shopIDs = [];
        snapshot.forEach((snapshotOrder) => {
          shopIDs.push(snapshotOrder.val().shopID);
        });
        await dispatch(startGetShopsByShopIDs({ shopIDs }));
        const data = snapshot.val();
        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const startGetTransactionsByUser =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, `transactions`),
        orderByChild("userID"),
        equalTo(userID)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const startGetOrdersArchivedByUser =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const q = query(
        ref(db, `ordersArchived`),
        orderByChild("userID"),
        equalTo(userID)
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const shopIDs = [];
        snapshot.forEach((snapshotOrder) => {
          shopIDs.push(snapshotOrder.val().shopID);
        });
        await dispatch(startGetShopsByShopIDs({ shopIDs }));
        const data = snapshot.val();
        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGetCreatorsPayments = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `creatorPayments`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const userIDs = [];
      const shopIDs = [];
      Object.keys(data).forEach((paymentKey) => {
        const payment = data[paymentKey];
        if (payment?.shopID) shopIDs.push(payment.shopID);
        if (payment?.userID) userIDs.push(payment.userID);
      });
      await dispatch(startGetShopsByShopIDs({ shopIDs }));
      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startReleaseApplicantPayment =
  ({ userID, paymentID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { userID, paymentID },
        url: `${FUNCTIONS_URL}/ReleaseApplicantPayment`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startChangeIsActiveShopByOwner =
  ({ shopID, status }) =>
  async (dispatch, getState) => {
    try {
      const dbRef = ref(db, `shops/${shopID}/`);
      if (!status) {
        await dispatch(startGetInvitesByShop(shopID));

        const invites = getState().invites.invites;
        const length = Object.keys(invites || {}).length;

        if (length > 0) {
          SimpleAlert({
            title: i18next.t(WARNING),
            text: i18next.t(HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP),
            icon: ALERT_ICON_TYPE_WARNING,
          });
          return false;
        }

        await update(dbRef, { isActive: status });
        dispatch(
          changeIsActiveShopByOwner({
            shopID,
            status,
          })
        );
        return true;
      }
      const snapshotShop = await get(dbRef);
      const shopData = snapshotShop.val();

      const isValidData = dispatch(validateShopDataToActive(shopData));
      if (!isValidData) return false;

      const isActiveFirstTime = shopData?.isActiveFirstTime ?? true;

      if (!isActiveFirstTime) {
        dispatch(startActiveShopFirstTime({ shopID }));
      }

      await update(dbRef, { isActive: status });

      dispatch(
        changeIsActiveShopByOwner({
          shopID,
          status,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

const changeIsActiveShopByOwner = (data) => ({
  type: types.CHANGE_IS_ACTIVE_SHOP_BY_OWNER,
  payload: data,
});

export const startChangeIsPlanActiveShopByOwner =
  ({ shopID, status }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `shops/${shopID}/isPlanActive`);
      await set(dbRef, status);
      dispatch(
        changeIsPlanActiveShopByOwner({
          shopID,
          status,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const changeIsPlanActiveShopByOwner = (data) => ({
  type: types.CHANGE_IS_PLAN_ACTIVE_SHOP_BY_OWNER,
  payload: data,
});
export const startChangeIsRenewPlanShopByOwner =
  ({ shopID, status }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `shops/${shopID}/isRenewPlan`);
      await set(dbRef, status);
      dispatch(
        changeIsRenewPlanShopByOwner({
          shopID,
          status,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const changeIsRenewPlanShopByOwner = (data) => ({
  type: types.CHANGE_IS_RENEW_PLAN_SHOP_BY_OWNER,
  payload: data,
});
export const startChangeFeatureFlagShopByOwner =
  ({ shopID, status, featureFlag }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `shops/${shopID}/featureFlags/${featureFlag}`);
      await set(dbRef, status);
      dispatch(
        changeFeatureFlagShopByOwner({
          shopID,
          featureFlag,
          status,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const changeFeatureFlagShopByOwner = (data) => ({
  type: types.CHANGE_FEATURE_FLAG_SHOP_BY_OWNER,
  payload: data,
});

export const startCreateShop = (shopData) => async (dispatch, getState) => {
  try {
    const country = getState()?.auth?.user?.country;
    const token = await dispatch(renewToken());
    const passwordEncrypted = encryptPassword(shopData.password);
    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/createShop`,
      data: {
        email: shopData.email,
        password: passwordEncrypted,
        name: shopData.name,
        paymentModel: shopData.paymentModel,
        businessName: shopData.businessName,
        hasBillingCycle: shopData.hasBillingCycle,
        clappCredits: parseInt(shopData.clappCredits),
        costCollaboration: shopData.costCollaboration,
        trialDays: shopData.trialDays,
        priceBasic: shopData.priceBasic,
        costCollaborationBasic: shopData.costCollaborationBasic,
        pricePro: shopData.pricePro,
        costCollaborationPro: shopData.costCollaborationPro,
        staffID: shopData.staffID,
        signUpCountry: country,
        createUser: shopData.createUser,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.ok) {
      const userDataRedux = {
        uid: data.uid,
        shopID: data.shopID,
        name: shopData.name,
        email: shopData.email,
      };
      const shopDataRedux = {
        shopID: data.shopID,
        data: {
          businessName: shopData.businessName,
          clappCredits: parseInt(shopData.clappCredits),
          costCollaboration:
            shopData.paymentModel === PAYMENT_MODEL_USE
              ? shopData.costCollaboration
              : "",
          subscriptionPlan:
            shopData.paymentModel === PAYMENT_MODEL_USE
              ? PLAN_COLLABORATION
              : "",
          staffID: shopData.staffID,
          signUpCountry: country,
        },
      };
      if (shopData.createUser) {
        dispatch(createAdminAccount(userDataRedux));
      }
      dispatch(createShop(shopDataRedux));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    SimpleAlert({
      title: "Ocurrió un error al crear la shop",
      text: `Code: ${error.response.data.message}`,
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};

const createShop = (data) => ({
  type: types.CREATE_SHOP,
  payload: data,
});

export const startSaveShopInfoByOwner =
  ({ shopID, data }) =>
  async (dispatch) => {
    try {
      const hasBillingCycleTask = (
        await get(ref(db, `shops/${shopID}/hasBillingCycleTask`))
      ).val();
      const updates = {};

      if (!hasBillingCycleTask && data.hasBillingCycle) {
        const response = await dispatch(
          startCreatePaymentMonthlyTask({ shopID })
        );
        updates[`shops/${shopID}/hasBillingCycleTask`] = true;
        if (!response) return;
      }

      updates[`shops/${shopID}/businessName`] = data.businessName;
      updates[`shops/${shopID}/shortDescription`] = data.shortDescription;
      updates[`shops/${shopID}/clappCredits`] = parseInt(data.clappCredits);
      updates[`shops/${shopID}/costCollaboration`] = parseInt(
        data.costCollaboration
      );
      updates[`shops/${shopID}/hasBillingCycle`] = data.hasBillingCycle;
      updates[`shops/${shopID}/staffID`] = data.staffID;
      updates[`shops/${shopID}/sortingIndex`] = data.sortingIndex;

      await update(ref(db), updates);

      return true;
    } catch (error) {
      console.log(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: `Code:  ${error.code}`,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startCreatePaymentMonthlyTask =
  ({ shopID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/createPaymentMonthlyTask`,
        data: {
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.ok;
    } catch (error) {
      console.log(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: `Code ${error.code}`,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startDeleteInfluencerAccount =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/deleteInfluencerAccount`,
        data: {
          userID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          deleteInfluencer({
            userID,
          })
        );
      }
      return data.ok;
    } catch (error) {
      console.log(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: `Code ${error.code}`,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startDisableInfluencerAccountForTime =
  ({ userID, disableDate }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { userID, disableDate },
        url: `${FUNCTIONS_URL}/disableInfluencerAccountForTime`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          saveInfluencerInfo({
            userID,
            data: {
              isDisabled: true,
              isHidden: true,
            },
          })
        );
        return data.ok;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          text: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      SimpleAlert({
        title: "Ocurrió un error",
        text: error.response.data.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };

export const startActiveInfluencerAccount =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { userID },
        url: `${FUNCTIONS_URL}/activeInfluencerAccountByOwner`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          saveInfluencerInfo({
            userID,
            data: {
              isDisabled: false,
              isHidden: false,
            },
          })
        );
        return data.ok;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          text: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      SimpleAlert({
        title: "Ocurrió un error",
        text: error.response.data.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };

export const startExtendInvitationTime =
  ({ inviteID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { inviteID },
        url: `${FUNCTIONS_URL}/extendInvitationTime`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data?.data?.expiryDays;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          text: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      SimpleAlert({
        title: "Ocurrió un error",
        text: error.response.data.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };

export const startDeleteDelivery =
  ({ deliveryID, data }) =>
  async (dispatch) => {
    try {
      const result = await ConfirmAlert({
        title: i18next.t(ARE_YOU_SURE),
        text: i18next.t(
          DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION
        ),
        confirmButtonText: i18next.t(BUTTON_DELETE),
      });
      if (result.isConfirmed) {
        const updates = {};
        updates[
          `deliveries/${data.shopID}/${data.storeID}/${data.status}/${deliveryID}`
        ] = null;
        updates[`orders/${data.orderID}`] = null;

        const dbRef = doc(firestore, "influencers", data.userID);
        await updateDoc(dbRef, {
          [`deliveries.${data.deliveryID}`]: deleteField(),
        });
        await update(ref(db), updates);
        dispatch(deleteDeliveryOwner(deliveryID));
        return true;
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startDeleteInviteByOwner = (inviteID) => async () => {
  try {
    const dbRef = ref(db, `invites/${inviteID}`);
    await remove(dbRef);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startDeleteOrderByOwner = (orderID) => async () => {
  try {
    const dbRef = ref(db, `orders/${orderID}`);
    await remove(dbRef);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startArchiveOrderByOwner = (orderID) => async (dispatch) => {
  try {
    const userID = dispatch(getUserID());
    const dbRef = ref(db, `orders/${orderID}`);
    const snapshot = await get(dbRef);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();

    const updates = {};

    const orderArchived = {
      ...data,
      archivedTime: new Date().getTime(),
      userIDArchived: userID,
    };

    updates[`ordersArchived/${orderID}`] = orderArchived;
    updates[`orders/${orderID}`] = null;

    await update(ref(db), updates);

    return orderArchived;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startUnArchiveOrderByOwner = (orderID) => async () => {
  try {
    const dbRef = ref(db, `ordersArchived/${orderID}`);
    const snapshot = await get(dbRef);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();

    const updates = {};

    delete data.archivedTime;
    delete data.userIDArchived;

    updates[`orders/${orderID}`] = data;
    updates[`ordersArchived/${orderID}`] = null;

    await update(ref(db), updates);

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startUpdateStatisticsInfluencer =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { userID },
        url: `${FUNCTIONS_URL}/updateStatisticsInfluencer`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(saveStatisticsInfluencer({ userID, data: data.data }));
        return data.data;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          text: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      SimpleAlert({
        title: "Ocurrió un error",
        text: error?.response?.data?.message ?? error.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error?.response?.data?.message ?? error.message);
      return false;
    }
  };

export const startGetStaffList = () => async (dispatch, getState) => {
  try {
    const country = getState()?.auth?.user?.country;
    const dbRef = ref(db, `staff`);
    const q = query(dbRef, orderByChild("country"), equalTo(country));
    const snapshot = await get(q);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const newData = [];
      Object.keys(data).forEach((staffID) => {
        newData.push({
          ...data[staffID],
          staffID: staffID,
        });
      });
      dispatch(getStaffList(newData));
      return newData;
    }
    return dispatch(getStaffList([]));
  } catch (error) {
    console.log(error);
    return false;
  }
};
const getStaffList = (data) => ({
  type: types.GET_STAFF_LIST,
  payload: data,
});

export const startAddStaff = (staff) => async (dispatch) => {
  try {
    const passwordEncrypt = encryptPassword(staff.password);
    staff.password = passwordEncrypt;
    delete staff.repeatPassword;

    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      data: staff,
      url: `${FUNCTIONS_URL}/createStaffAccount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data.ok) {
      const staffData = data?.data;
      const staffID = staffData?.staffID;
      dispatch(
        addStaff({
          name: staffData.name,
          staffID,
          email: staffData.email,
          country: staffData.country,
        })
      );
      return {
        staffID,
        name: staffData.name,
        email: staffData.email,
      };
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: "Ocurrió un error",
      text: errorFormatted.message,
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const addStaff = (data) => ({
  type: types.ADD_STAFF,
  payload: data,
});

export const startAssignStaff =
  ({ staffID, shopID }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `shops/${shopID}/staffID`);
      await set(dbRef, staffID);
      dispatch(
        assignStaff({
          staffID,
          shopID,
        })
      );
    } catch (error) {
      console.log(error);
      return false;
    }
  };

const assignStaff = (data) => ({
  type: types.ASSIGN_STAFF_SHOP,
  payload: data,
});

export const startGetUserPassword =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { userID },
        url: `${FUNCTIONS_URL}/getPasswordUser`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          text: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: errorFormatted.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };

export const startGetFeedbacks =
  ({ limit, onChangeLastKey, lastKey, onCallBack = () => {} }) =>
  async (dispatch) => {
    try {
      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `feedbacks`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `feedbacks`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      } else {
        q = query(ref(db, `feedbacks`), orderByChild("creationTime"));
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) return onCallBack();

      const shopIDs = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();
        if (count === snapshotSize && limit) onChangeLastKey(data.creationTime);
        shopIDs.push(data.shopID);
        count++;
      });

      await dispatch(startGetShopsByShopIDs({ shopIDs }));

      lastKey
        ? dispatch(nextFeedbacks(snapshot.val()))
        : dispatch(getFeedbacks(snapshot.val()));

      onCallBack();
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: errorFormatted.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const getFeedbacks = (data) => ({
  type: types.GET_FEEDBACKS,
  payload: data,
});

const nextFeedbacks = (data) => ({
  type: types.NEXT_FEEDBACKS,
  payload: data,
});

export const startGetProductsStripe = (lastKey) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      data: { lastKey },
      url: `${FUNCTIONS_URL}/getProductsStripe`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.ok) {
      return data.data;
    } else {
      SimpleAlert({
        title: "Ocurrió un error",
        text: data.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  } catch (error) {
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: "Ocurrió un error",
      text: errorFormatted.message,
      icon: ALERT_ICON_TYPE_ERROR,
    });
    console.log(error);
    return false;
  }
};
export const startCreatePaymentLink =
  ({
    name,
    costCollaboration,
    credits,
    description,
    price,
    currency,
    isRecurring,
    recurring,
    metadata,
    shopID,
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: {
          name,
          credits,
          costCollaboration,
          description,
          price,
          currency,
          isRecurring,
          recurring,
          metadata,
          shopID,
        },
        url: `${FUNCTIONS_URL}/createPaymentLink`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.data;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          text: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: errorFormatted.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
export const startCreateAdsPaymentLink = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      data,
      url: `${FUNCTIONS_URL}/createAdsPaymentLink`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      return result.data;
    }

    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: "Ocurrió un error",
      text: errorFormatted.message,
      icon: ALERT_ICON_TYPE_ERROR,
    });
    console.log(error);
    return false;
  }
};

export const startGetShopPaymentsFailed = (shopID) => async (dispatch) => {
  try {
    const dbRef = ref(db, `payments/${shopID}`);
    const q = query(dbRef, orderByChild("isPaid"), equalTo(false));

    const snapshot = await get(q);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();
    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: "Ocurrió un error",
      text: errorFormatted.message,
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startUploadNewContentPublication =
  ({ orderID, posts }) =>
  async () => {
    try {
      const dbRef = ref(db, `orders/${orderID}/posts`);
      await set(dbRef, posts);
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: errorFormatted.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startCreateInfluencerTransactionOwner =
  ({ userID, from, fromID, amount }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { userID, from, fromID, amount },
        url: `${FUNCTIONS_URL}/createInfluencerTransactionOwner`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.data;
      } else {
        SimpleAlert({
          title: "Ocurrió un error",
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startChangePlanToSubscriptionBasic =
  ({ shopID, plan }) =>
  async (dispatch) => {
    try {
      const updates = {};

      const NAME_BUSINESS = dispatch(getNameBusinessTheme());

      updates[`shops/${shopID}/isPlanActive`] = false;
      updates[`shops/${shopID}/isActive`] = false;
      updates[`shops/${shopID}/paymentModel`] = PAYMENT_MODEL_PACKAGE;
      updates[`shops/${shopID}/subscriptionPlan`] = null;
      updates[`shops/${shopID}/plans`] = {
        basic: {
          name: `${NAME_BUSINESS} Pro`,
          benefits: {
            costCollaboration: plan.costCollaboration,
          },
          price: plan.price,
        },
      };

      await update(ref(db), updates);
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: "Ocurrió un error",
        text: errorFormatted.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetContentApplication =
  ({ limit, onChangeLastKey, lastKey, onCallBack = () => {} }) =>
  async () => {
    try {
      const dbRef = ref(db, `contentApplication`);

      let q;

      if (limit) {
        q = lastKey
          ? query(dbRef, orderByKey(), startAfter(lastKey), limitToFirst(limit))
          : query(dbRef, orderByKey(), limitToFirst(limit));
      } else {
        q = query(dbRef, orderByKey());
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        onCallBack();
        return false;
      }

      const data = snapshot.val();
      const newLastKey = Object.keys(data).pop();
      onChangeLastKey(newLastKey);

      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }
  };

export const startAcceptContentApplication =
  ({ data, applicationID }) =>
  async () => {
    try {
      const dbRef = ref(db, `contentApplication/${applicationID}`);

      await set(dbRef, [...data, true]);

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }
  };
export const startRejectContentApplication = (applicationID) => async () => {
  try {
    const dbRef = ref(db, `contentApplication/${applicationID}`);
    await remove(dbRef);

    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);

    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });

    return false;
  }
};
