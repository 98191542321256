import { get, ref as refDB } from "firebase/database";
import { db } from "../firebase";
import { getObjectError } from "../utils/formats";
import { SimpleAlert } from "../utils/alerts";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";
import i18next from "i18next";
import { influencersCategories } from "../utils/categories";
import axios from "../lib/axios";
import { renewToken } from "../actions/auth";

const FUNCTIONS_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const mapInfluencerCategories = (category, type = "all") => {
  switch (category) {
    case "Music":
      if (type === "intern") return ["Music"];
      if (type === "phyllo") return ["Music"];
      if (type === "all") return ["Music"];
      return [category];

    case "Sports":
      if (type === "intern") return ["Sports"];
      if (type === "phyllo") return ["Sports"];
      if (type === "all") return ["Sports"];
      return [category];

    case "Pets":
      if (type === "intern") return ["Pets"];
      if (type === "phyllo") return ["Pets"];
      if (type === "all") return ["Pets"];
      return [category];

    case "Beauty & Cosmetics":
    case "Beauty":
      if (type === "intern") return ["Beauty"];
      if (type === "phyllo") return ["Beauty & Cosmetics"];
      if (type === "all") return ["Beauty & Cosmetics", "Beauty"];
      return [category];

    case "Fitness & Yoga":
    case "Fitness":
      if (type === "intern") return ["Fitness"];
      if (type === "phyllo") return ["Fitness & Yoga"];
      if (type === "all") return ["Fitness & Yoga", "Fitness"];
      return [category];

    case "Home Decor, Furniture & Garden":
    case "Home":
    case "Environment":
      if (type === "intern") return ["Environment", "Home"];
      if (type === "phyllo") return ["Home Decor, Furniture & Garden"];
      if (type === "all")
        return ["Environment", "Home Decor, Furniture & Garden", "Home"];
      return [category];

    case "Healthy Lifestyle":
    case "Lifestyle":
    case "Wellness":
      if (type === "intern") return ["Wellness", "Lifestyle"];
      if (type === "phyllo") return ["Healthy Lifestyle"];
      if (type === "all") return ["Wellness", "Healthy Lifestyle", "Lifestyle"];
      return [category];

    case "Clothes, Shoes, Handbags & Accessories":
    case "Fashion":
    case "Model":
      if (type === "intern") return ["Model", "Fashion"];
      if (type === "phyllo") return ["Clothes, Shoes, Handbags & Accessories"];
      if (type === "all")
        return ["Clothes, Shoes, Handbags & Accessories", "Model", "Fashion"];
      return [category];

    case "Television & Film":
    case "Media":
    case "Entertainment":
      if (type === "intern") return ["Entertainment", "Media"];
      if (type === "phyllo") return ["Television & Film"];
      if (type === "all")
        return ["Entertainment", "Television & Film", "Media"];
      return [category];

    case "Toys, Children & Baby":
    case "Education":
      if (type === "intern") return ["Education"];
      if (type === "phyllo") return ["Toys, Children & Baby"];
      if (type === "all") return ["Toys, Children & Baby", "Education"];
      return [category];

    case "Restaurants, Food & Grocery":
    case "Foodie":
    case "Cooking":
      if (type === "intern") return ["Cooking", "Foodie"];
      if (type === "phyllo") return ["Restaurants, Food & Grocery"];
      if (type === "all")
        return ["Restaurants, Food & Grocery", "Cooking", "Foodie"];
      return [category];

    case "Electronics & Computers":
    case "Technology":
      if (type === "intern") return ["Technology"];
      if (type === "phyllo") return ["Electronics & Computers"];
      if (type === "all") return ["Electronics & Computers", "Technology"];
      return [category];

    case "Productivity":
    case "Business & Careers":
    case "Business":
      if (type === "intern") return ["Business", "Productivity"];
      if (type === "phyllo") return ["Business & Careers"];
      if (type === "all")
        return ["Business & Careers", "Business", "Productivity"];
      return [category];

    case "Art & Design":
    case "Arts & Culture":
      if (type === "intern") return ["Arts & Culture"];
      if (type === "phyllo") return ["Art & Design"];
      if (type === "all") return ["Art & Design", "Arts & Culture"];
      return [category];

    case "Travel, Tourism & Aviation":
    case "Travel":
      if (type === "intern") return ["Travel"];
      if (type === "phyllo") return ["Travel, Tourism & Aviation"];
      if (type === "all") return ["Travel, Tourism & Aviation", "Travel"];
      return [category];

    case "Friends, Family & Relationships":
      if (type === "intern") return [];
      if (type === "phyllo") return ["Friends, Family & Relationships"];
      if (type === "all") return ["Friends, Family & Relationships"];
      return [category];

    case "Camera & Photography":
      if (type === "intern") return [];
      if (type === "phyllo") return ["Camera & Photography"];
      if (type === "all") return ["Camera & Photography"];
      return [category];

    case "Luxury Goods":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Luxury Goods"];
      if (type === "all") return ["Luxury Goods"];
      return [category];

    case "Activewear":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Activewear"];
      if (type === "all") return ["Activewear"];
      return [category];

    case "Wedding":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Wedding"];
      if (type === "all") return ["Wedding"];
      return [category];

    case "Shopping & Retail":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Shopping & Retail"];
      if (type === "all") return ["Shopping & Retail"];
      return [category];

    case "Jewellery & Watches":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Jewellery & Watches"];
      if (type === "all") return ["Jewellery & Watches"];
      return [category];

    case "Gaming":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Gaming"];
      if (type === "all") return ["Gaming"];
      return [category];

    case "Healthcare & Medicine":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Healthcare & Medicine"];
      if (type === "all") return ["Healthcare & Medicine"];
      return [category];

    case "Tobacco & Smoking":
      if (type === "intern") return [""];
      if (type === "phyllo") return ["Tobacco & Smoking"];
      if (type === "all") return ["Tobacco & Smoking"];
      return [category];

    default:
      return [category];
  }
};

export const startGetCategoriesFromTopicsPhyllo =
  (topics = []) =>
  async (dispatch) => {
    try {
      const topicsFiltered = topics.filter(
        (topic) => !influencersCategories.includes(topic)
      );

      if (!topicsFiltered.length) return [];

      const queries = topicsFiltered.map((topic) => {
        return get(refDB(db, `topicsPhylloToCategories/${topic}`));
      });

      const categoriesResponses = await Promise.all(queries);

      const topicsNotFound = [];

      const categories = [];

      categoriesResponses.forEach((response) => {
        if (response.exists()) {
          const categoriesFound = response.val();
          categories.push([...categoriesFound]);
          return null;
        } else {
          topicsNotFound.push(response.ref.key);
          return null;
        }
      });

      if (topicsNotFound?.length > 0) {
        const token = await dispatch(renewToken());

        const { data } = await axios({
          method: "post",
          data: {
            topics: topicsNotFound,
          },
          url: `${FUNCTIONS_URL}/getCategoriesMappedFromTopicPhyllo`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.ok) {
          const newCategories = data?.data?.categories || [];
          categories.push(...newCategories);
        }
      }

      return categories.flat();
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const getLocationStringPhyllo = (location = {}) => {
  return `${location.city ? `${location.city}` : ""}${
    location.state ? `, ${location.state}` : ""
  }${location.country ? `, ${location.country}` : ""}`;
};

export const getAudienceLocationsFormatted = (locations = []) => {
  return locations.map((location) => {
    if (location.type === "country") {
      return location.code;
    }
    return location.formattedName;
  });
};
