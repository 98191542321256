import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import RemoveFile from "../../../Common/Buttons/RemoveFile";
import UploadFile from "../../../Common/Buttons/UploadFile";
import AnswerContainer from "../../../Common/Forms/TypeForm/AnswerContainer";
import AnswerTitle from "../../../Common/Forms/TypeForm/AnswerTitle";
import { Controller } from "react-hook-form";
import ActionsButtons from "../../../Common/Forms/TypeForm/ActionsButtons";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_REMOVE_CONTENT,
  BUTTON_UPLOAD_CONTENT,
  CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION,
  CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION,
  CREATE_CAMPAIGN_BRIEF_DESCRIPTION,
  CREATE_CAMPAIGN_BRIEF_QUESTION,
  CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION,
  CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER,
  CREATE_CAMPAIGN_ENVIRONMENT_QUESTION,
  CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER,
  CREATE_CAMPAIGN_HASHTAG_QUESTION,
  CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER,
  CREATE_CAMPAIGN_KEY_WORDS_QUESTION,
  CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER,
  CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION,
  CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER,
  CREATE_CAMPAIGN_OUTFIT_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER,
  CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION,
  CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION,
  CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER,
  CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION,
  CREATE_CAMPAIGN_REMOVED_BRIEF,
  CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER,
  CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION,
  CREATE_CAMPAIGN_UPLOAD_BRIEF,
  FEMALES,
  MALES,
} from "../../../../locales/keysTranslations";
import SelectUGCContentFormat from "../../../Common/Selects/SelectUGCContentFormat";
import LinkText from "../../../Common/Texts/LinkText";

const AttachmentsSection = ({
  shopID,
  step,
  currentAnswer,
  stepVisible,
  error,
  control,
  getValues,
  setValue,
  onNextStep,
  onPreviousStep,
  onGenerateScriptAndDescription,
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: step >= 20 && step <= 32 ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer stepPosition={"brief"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_BRIEF_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "brief" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CREATE_CAMPAIGN_BRIEF_DESCRIPTION)}
        </Typography>
        <Controller
          name="brief"
          control={control}
          render={({ field }) => (
            <>
              {!field.value ? (
                <UploadFile
                  textButton={CREATE_CAMPAIGN_UPLOAD_BRIEF}
                  pathStorage={`campaigns/${shopID}`}
                  automaticSetRTDB={false}
                  acceptFormats="application/pdf"
                  targetName="brief"
                  onReadyFile={field.onChange}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <LinkText
                    variant="subtitle1"
                    href={field.value}
                    target="_blank"
                    sx={{ fontWeight: 600 }}
                  >
                    Brief.pdf
                  </LinkText>
                  <RemoveFile
                    textButton={CREATE_CAMPAIGN_REMOVED_BRIEF}
                    pathStorage={`campaigns/${shopID}`}
                    targetName="brief"
                    urlFile={field.value}
                    automaticRemoveRTDB={false}
                    onRemoveFile={field.onChange}
                  />
                </Box>
              )}
            </>
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("brief", getValues("brief"))}
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"attachmentFile"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION)} `}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION)}
        </Typography>
        {!!error && error.step === "attachmentFile" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="attachmentFile"
          control={control}
          render={({ field }) => (
            <>
              {!field.value ? (
                <UploadFile
                  textButton={BUTTON_UPLOAD_CONTENT}
                  pathStorage={`campaigns/${shopID}`}
                  automaticSetRTDB={false}
                  acceptFormats="image/jpeg, image/jpg, image/webp, image/png"
                  targetName="attachmentFile"
                  onReadyFile={field.onChange}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "100%",
                      height: "100%",
                      maxWidth: 400,
                      maxHeight: 400,
                      my: 1,
                    }}
                    src={field.value}
                    variant="rounded"
                  />
                  <RemoveFile
                    textButton={BUTTON_REMOVE_CONTENT}
                    pathStorage={`campaigns/${shopID}`}
                    targetName="attachmentFile"
                    urlFile={field.value}
                    automaticRemoveRTDB={false}
                    onRemoveFile={field.onChange}
                  />
                </Box>
              )}
            </>
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("attachmentFile", getValues("attachmentFile"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"attachmentLink"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION)}`}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION)}
        </Typography>
        {!!error && error.step === "attachmentLink" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="attachmentLink"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("attachmentLink", getValues("attachmentLink"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"ugcContentFormat"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "ugcContentFormat" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <SelectUGCContentFormat
          label={t(CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER)}
          fullWidth
          margin="normal"
          variant="standard"
          withController={true}
          name="ugcContentFormat"
          doTranslate={true}
          control={control}
          rules={{
            required: false,
          }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("ugcContentFormat", getValues("ugcContentFormat"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"quantityGender"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "quantityGender" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="quantityMale"
                  control={control}
                  render={({ field }) => (
                    <Checkbox checked={field.value} {...field} />
                  )}
                />
              }
              label={t(MALES)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="quantityFemale"
                  control={control}
                  render={({ field }) => (
                    <Checkbox checked={field.value} {...field} />
                  )}
                />
              }
              label={t(FEMALES)}
            />
          </Grid>
        </Grid>

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("quantityGender", {
              quantityMale: getValues("quantityMale"),
              quantityFemale: getValues("quantityFemale"),
            })
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"referenceProfiles"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "referenceProfiles" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="referenceProfiles"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("referenceProfiles", getValues("referenceProfiles"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"hashtag"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_HASHTAG_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "hashtag" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="hashtag"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("hashtag", getValues("hashtag"))}
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"messageTone"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "messageTone" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="messageTone"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("messageTone", getValues("messageTone"))}
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"environmentContent"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_ENVIRONMENT_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "environmentContent" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="environmentContent"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("environmentContent", getValues("environmentContent"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"colorsContent"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "colorsContent" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="colorsContent"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("colorsContent", getValues("colorsContent"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"prohibitedColorsContent"}
        step={currentAnswer}
      >
        <AnswerTitle>
          {`${stepVisible}. ${t(
            CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION
          )}`}
        </AnswerTitle>
        {!!error && error.step === "prohibitedColorsContent" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="prohibitedColorsContent"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(
                CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER
              )}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep(
              "prohibitedColorsContent",
              getValues("prohibitedColorsContent")
            )
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"keyWords"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_KEY_WORDS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "keyWords" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="keyWords"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("keyWords", getValues("keyWords"))}
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"prohibitedWords"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "prohibitedWords" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="prohibitedWords"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("prohibitedWords", getValues("prohibitedWords"))
          }
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          colorNextButton="primary"
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"outfit"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_OUTFIT_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "outfit" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="outfit"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={false}
          showSaveButton={true}
          showNextButton={false}
        />
      </AnswerContainer>
    </Box>
  );
};

export default AttachmentsSection;
