import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION } from "../../../../locales/keysTranslations";

const ContentCredibilityAudience = () => {
  const { t } = useTranslationApp();
  return (
    <Typography color="inherit" variant="body1" sx={{ mb: 1 }}>
      {t(AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION)}
    </Typography>
  );
};

export default ContentCredibilityAudience;
