import React, { useState } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import Box from "@mui/material/Box";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useForm } from "react-hook-form";
import { useDispatchApp } from "../../../lib/redux";
import { startCreateAmbassador } from "../../../actions/ambassadors";
import { SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_SUCCESS,
  MIN_ALL_NUMBER_VALUE,
} from "../../../utils/constants";
import {
  BUTTON_CANCEL,
  DONE_AMBASSADOR,
  DO_AMBASSADOR,
  FIELD_REQUIRED,
  INVITE_VALUE_MIN_VALUE,
  FIELD_MIN_VALUE_TO_PAY_AMBASSADOR,
  MODAL_DO_AMBASSADOR_ARIA_LABEL,
  MODAL_DO_AMBASSADOR_DESCRIPTION,
  MODAL_DO_AMBASSADOR_TITLE,
  READY,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import AmbassadorForm from "../../User/Ambassadors/AmbassadorForm";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalDoAmbassador = ({
  modalOpen,
  onCloseModal,
  userID,
  onCallback = () => {},
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { control, watch, setValue, handleSubmit, formState } = useForm({
    defaultValues: {
      value: 0,
      withRecurrentInvite: false,
      recurrentPattern: "",
      valueToPay: 0,
      withRecurrentPayment: false,
      recurrentPatternPayment: "",
    },
  });
  const LOCALE = useLocale();

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();

  const withRecurrentInvite = watch("withRecurrentInvite");
  const withRecurrentPayment = watch("withRecurrentPayment");

  const dispatch = useDispatchApp();

  const onSubmit = async (data) => {
    setIsFetching(true);

    const response = await dispatch(
      startCreateAmbassador({
        ...data,
        userID,
      })
    );

    setIsFetching(false);

    if (response) {
      onCallback();
      onCloseModal();
      SimpleAlert({
        title: t(READY),
        text: t(DONE_AMBASSADOR),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      ariaLabel={t(MODAL_DO_AMBASSADOR_ARIA_LABEL)}
      title={t(MODAL_DO_AMBASSADOR_TITLE)}
      description={t(MODAL_DO_AMBASSADOR_DESCRIPTION)}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <AmbassadorForm
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          validate={{
            value: {
              validate: (value) => {
                if (withRecurrentInvite) {
                  if (value === "" || value === null || !value) {
                    return t(FIELD_REQUIRED);
                  }
                  if (parseInt(value) < MIN_ALL_NUMBER_VALUE) {
                    return t(INVITE_VALUE_MIN_VALUE, {
                      value: MIN_ALL_NUMBER_VALUE,
                      currency: LOCALE.currency,
                    });
                  }
                }
              },
            },
            valueToPay: {
              validate: (value) => {
                if (withRecurrentPayment) {
                  if (value === "" || value === null || !value) {
                    return t(FIELD_REQUIRED);
                  }
                  if (parseInt(value) < MIN_ALL_NUMBER_VALUE) {
                    return t(FIELD_MIN_VALUE_TO_PAY_AMBASSADOR, {
                      value: MIN_ALL_NUMBER_VALUE,
                      currency: "USD",
                    });
                  }
                }
              },
            },
          }}
        />
        <ContainerModalActions
          hasPadding={false}
          sx={{
            mt: 2,
          }}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
            disabled: isFetching,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: DO_AMBASSADOR,
            type: "submit",
            loading: isFetching,
            onClick: (e) => {
              e?.stopPropagation();
            },
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalDoAmbassador;
