import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import {
  DELIVERY_TYPE_DUPLICATE,
  GUIDE_NUMBER,
  OWN_DELIVERY,
  SELECT_DELIVERY_TYPE,
  SELECT_DELIVERY_TYPE_OTHER,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { capitalizeFirstLetter } from "../../../../utils/formats";

const DeliverySearch = ({
  onChangeDelivery,
  onChangeGuideNumber,
  error,
  dataTour = "",
}) => {
  const [delivery, setDelivery] = useState("interno");
  const [errorField, setErrorField] = useState(null);
  const [deliveries, setDeliveries] = useState(["interno"]);
  const [newDelivery, setNewDelivery] = useState("");
  const [guideNumber, setGuideNumber] = useState("");

  const { t } = useTranslationApp();

  const LOCALE = useLocale();

  useEffect(() => {
    const deliveries = LOCALE.deliveries;
    setDeliveries(["interno", ...deliveries]);
    onChangeDelivery("interno");
    //eslint-disable-next-line
  }, []);

  const onAddNewDelivery = () => {
    if (
      deliveries.some((delivery) => {
        return delivery.toLowerCase() === newDelivery.toLowerCase();
      })
    )
      return setErrorField(t(DELIVERY_TYPE_DUPLICATE));
    setErrorField(null);
    let newDeliveries = deliveries;
    newDeliveries.filter((value) => value.length !== 0);
    newDeliveries.push(newDelivery.toLowerCase());
    setDeliveries(newDeliveries);
    onChangeDeliverySelected(newDelivery.toLowerCase());
    setNewDelivery("");
  };

  const onChangeDeliverySelected = (deliverySelected) => {
    setDelivery(deliverySelected);
    onChangeDelivery(deliverySelected);
  };

  const onSetGuideNumber = (guideNumber) => {
    onChangeGuideNumber(guideNumber);
    setGuideNumber(guideNumber);
  };

  return (
    <Box data-tour={dataTour}>
      <Typography variant="body1" sx={{ color: "#757575" }}>
        Delivery
      </Typography>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "background.paperSecondary",
          backgroundImage: "none",
          px: 2,
          py: { xs: 1, md: 2 },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <FormControl fullWidth variant="filled">
          <InputLabel id="deliveries">{t(SELECT_DELIVERY_TYPE)}</InputLabel>
          <Select
            labelId="deliveries"
            id="deliveries"
            name="delivery"
            label={t(SELECT_DELIVERY_TYPE)}
            onChange={(e) => {
              onChangeDeliverySelected(e.target.value);
            }}
            value={delivery}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8 * 2,
                  width: 250,
                },
              },
            }}
          >
            <Box sx={{ px: 2, py: 1 }} onKeyDown={(e) => e.stopPropagation()}>
              <TextField
                label={t(SELECT_DELIVERY_TYPE_OTHER)}
                error={!!errorField}
                helperText={errorField}
                variant="filled"
                name="newDelivery"
                value={newDelivery}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    onAddNewDelivery();
                  }
                }}
                onChange={(e) => setNewDelivery(e.target.value)}
                fullWidth
              />
            </Box>
            {deliveries.map((delivery) => (
              <MenuItem key={delivery} value={delivery}>
                <ListItemText
                  primary={
                    delivery === "interno"
                      ? t(OWN_DELIVERY)
                      : capitalizeFirstLetter(delivery)
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {delivery !== "interno" && (
          <TextField
            label={t(GUIDE_NUMBER)}
            error={error}
            helperText={error ? error : ""}
            sx={{ ml: 0 }}
            variant="filled"
            name="guideNumber"
            value={guideNumber}
            onChange={(e) => onSetGuideNumber(e.target.value)}
            fullWidth
          />
        )}
      </Paper>
    </Box>
  );
};

export default DeliverySearch;
