import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { openLink } from "../../../utils/urls";
import WhatsAppLogo from "../../../assets/img/whatsapp.png";
import { useTranslationApp } from "../../../lib/i18next";
import { BUTTON_YOU_HAVE_DOUBTS } from "../../../locales/keysTranslations";
import { LINK_TO_WHATSAPP_SUPPORT_PRICES } from "../../../utils/constants";
import Typography from "@mui/material/Typography";
import { useDispatchApp } from "../../../lib/redux";
import { getNameBusinessTheme } from "../../../actions/getters";

const ButtonToWhatsapp = ({
  label = BUTTON_YOU_HAVE_DOUBTS,
  link = LINK_TO_WHATSAPP_SUPPORT_PRICES,
  sx = {},
}) => {
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5,
        ...sx,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          textDecoration: "underline",
          fontSize: "1.1rem",
          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
          },
        }}
        onClick={() => openLink(`${link}${NAME_BUSINESS}`)}
      >
        {t(label)}
      </Typography>
      <Avatar
        src={WhatsAppLogo}
        sx={{
          width: 35,
          height: 35,
        }}
      />
    </Box>
  );
};

export default ButtonToWhatsapp;
