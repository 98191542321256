import { createTheme } from "@mui/material";
import {
  GRAY_DARK_BACKGROUND_COLOR,
  GRAY_DARK_THEME_COLOR,
  GRAY_DARK_THEME_COLOR_DISABLED,
} from "../../../utils/colors";
import { BORDER_RADIUS } from "../../../utils/constants";

const themeDark = (outerTheme) =>
  createTheme({
    ...outerTheme,
    components: {
      ...outerTheme.components,
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            color: "black",
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              background: "none !important",
              backgroundColor: `${GRAY_DARK_BACKGROUND_COLOR} !important`,
              color: `${GRAY_DARK_THEME_COLOR_DISABLED} !important`,
            },
          },
          loadingIndicator: {
            color: "#fff !important",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: GRAY_DARK_THEME_COLOR,
            "&.Mui-disabled": {
              color: GRAY_DARK_THEME_COLOR_DISABLED,
            },
            "&.Mui-checked": {
              color: "white",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: `${GRAY_DARK_THEME_COLOR} !important`,
            "&.Mui-focused": {
              color: `${GRAY_DARK_THEME_COLOR} !important`,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: GRAY_DARK_THEME_COLOR,
          },
          label: {
            "&.Mui-disabled": {
              color: GRAY_DARK_THEME_COLOR,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: `${BORDER_RADIUS * 4}px !important`,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            borderRadius: `${BORDER_RADIUS * 4}px !important`,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": GRAY_DARK_THEME_COLOR,
            "--TextField-brandBorderHoverColor": GRAY_DARK_THEME_COLOR,
            "--TextField-brandBorderFocusedColor": GRAY_DARK_THEME_COLOR,
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: `${GRAY_DARK_BACKGROUND_COLOR} !important`,
            borderRadius: `${BORDER_RADIUS * 4}px !important`,
            color: "white",
            "&:hover": {
              backgroundColor: GRAY_DARK_BACKGROUND_COLOR,
            },
            "&.Mui-focused": {
              backgroundColor: GRAY_DARK_BACKGROUND_COLOR,
            },
            "&:before, &:after": {
              borderBottom: "none !important",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "none !important",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
          input: {
            "&.Mui-disabled": {
              WebkitTextFillColor: "rgba(255, 255, 255, 0.38)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderRadius: `${BORDER_RADIUS * 4}px !important`,
            color: `${GRAY_DARK_BACKGROUND_COLOR} !important`,
            backgroundColor: GRAY_DARK_BACKGROUND_COLOR,
            "&:before": {
              borderBottom: "none !important",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "none !important",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            ".MuiButtonBase": {
              color: "white",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: GRAY_DARK_BACKGROUND_COLOR,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            color: "white",
          },
          switchViewButton: {
            color: "white",
          },
          switchHeader: {
            color: "white",
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: "white",
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.26)",
            },
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          weekDayLabel: {
            color: "white",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.26)",
            },
            "&.Mui-selected": {
              backgroundColor: "white",
              color: "black",
              fontWeight: 600,
              "&:focus": {
                backgroundColor: "white",
                color: "black",
              },
            },
          },
          dayWithMargin: {
            color: "white",
            backgroundColor: "transparent",
          },
          today: {
            "&:not(.Mui-selected)": {
              border: "1px solid rgba(255, 255, 255, 0.6)",
            },
          },
        },
      },
      PrivatePickersYear: {
        styleOverrides: {
          root: {
            color: "white",
            backgroundColor: "transparent",
          },
          button: {
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.6)",
            },
            "&.Mui-selected": {
              backgroundColor: "white",
              color: "black",
              fontWeight: 600,
              "&:focus": {
                backgroundColor: "white",
                color: "black",
              },
            },
          },
        },
      },
      MuiClockNumber: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
    },
  });

export default themeDark;
