import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import TextField from "../../Common/Fields/TextField";
import Select from "../../Common/Selects/Select";
import NumberField from "../../Common/NumberFormats/NumberField";
import { GENDER_OPTIONS, PLATFORMS_OPTIONS } from "../../../utils/constants";
import { REGEX_NUMBER_TWO_DIGITS_AND_DECIMALS } from "../../../utils/regexsValidation";
import { CATEGORIES_PHYLLO } from "../../../utils/categories";
import SelectMultiple from "../../Common/Selects/SelectMultiple";
import Autocomplete from "@mui/material/Autocomplete";
import TextFieldMUI from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import Button from "../../Common/Buttons/Button";
import _ from "lodash";
import {
  PAGE_EXPLORER_FILTERS,
  PAGE_EXPLORER_FILTERS_MAX_FOLLOWERS,
  PAGE_EXPLORER_FILTERS_MIN_FOLLOWERS,
  PAGE_EXPLORER_FILTERS_NAME,
  PAGE_EXPLORER_FILTERS_PLATFORM,
  PAGE_EXPLORER_SEARCH_BUTTON,
} from "../../../onboarding/stepsSelectors";
import { optionsAudienceAgeGender } from "../../../utils/slidersMarks";
import CountriesPhylloField from "../../Common/Fields/CountriesPhylloField";
import { useTour } from "@reactour/tour";
import {
  BUTTON_SEARCH,
  DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE,
  DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER,
  FIELD_REQUIRED,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const DiscoveryInfluencersAdvancedForm = ({
  watch,
  setValue,
  isFetching,
  onCleanFilters,
  control,
  errors,
  isFiltering,
  controlPlatform,
  platform,
}) => {
  const { isOpen } = useTour();
  const { t } = useTranslationApp();
  return (
    <Grid
      container
      spacing={2}
      sx={{ mt: 1 }}
      component="form"
      data-tour={PAGE_EXPLORER_FILTERS}
    >
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          disabled={isOpen}
          dataTour={PAGE_EXPLORER_FILTERS_NAME}
          name="accountName"
          label={t(DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER)}
          onClick={() => onCleanFilters()}
          variant="filled"
          fullWidth={true}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          disabled={isOpen}
          dataTour={PAGE_EXPLORER_FILTERS_PLATFORM}
          name="platform"
          label={t(DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER)}
          variant="filled"
          fullWidth={true}
          options={PLATFORMS_OPTIONS}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          control={controlPlatform}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <CountriesPhylloField
          setValue={setValue}
          disabled={isOpen}
          control={control}
          errors={errors}
          name="locations"
          label={t(DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER)}
          isFiltering={isFiltering}
          watch={watch}
          platform={platform}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          dataTour={PAGE_EXPLORER_FILTERS_MIN_FOLLOWERS}
          name="minFollowerCount"
          label={t(DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          InputProps={{
            inputComponent: NumberField,
          }}
          rules={{
            min: {
              value: 5000,
              message: t(DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE, {
                value: 5000,
              }),
            },
            validate: (value) => {
              const valueInt = parseInt(value);
              const maxFollowerCount = parseInt(watch("maxFollowerCount"));

              if (_.isEmpty(value) && maxFollowerCount > 0)
                return t(DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED);

              if (valueInt > 0 && maxFollowerCount === 0) {
                return t(
                  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED
                );
              }
              if (valueInt > 0 && valueInt > maxFollowerCount) {
                return t(
                  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS
                );
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          dataTour={PAGE_EXPLORER_FILTERS_MAX_FOLLOWERS}
          name="maxFollowerCount"
          label={t(DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          InputProps={{
            inputComponent: NumberField,
          }}
          rules={{
            min: {
              value: 5000,
              message: t(DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE, {
                value: 5000,
              }),
            },
            validate: (value) => {
              const valueInt = parseInt(value);
              const minFollowerCount = parseInt(watch("minFollowerCount"));
              if (_.isEmpty(value) && minFollowerCount > 0)
                return t(DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED);
              if (valueInt > 0 && minFollowerCount === 0) {
                return t(
                  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED
                );
              }
              if (valueInt > 0 && valueInt < minFollowerCount) {
                return t(
                  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS
                );
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          name="engagementRate"
          label={t(DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          rules={{
            min: {
              value: 0.1,
              message: t(DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE, {
                value: 0.1,
              }),
            },
            max: {
              value: 50,
              message: t(DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE, {
                value: 50,
              }),
            },
            pattern: {
              value: REGEX_NUMBER_TWO_DIGITS_AND_DECIMALS,
              message: t(DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          name="creatorGender"
          label={t(DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
          options={GENDER_OPTIONS}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          name="creatorAge"
          label={t(DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          options={optionsAudienceAgeGender.map((item) => {
            if (item.value === "65-") {
              return {
                ...item,
                value: "18-65",
              };
            }
            return item;
          })}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          name="audienceGender"
          label={t(DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
          options={GENDER_OPTIONS}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          name="audienceAge"
          label={t(DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          options={optionsAudienceAgeGender.map((item) => {
            if (item.value === "65-") {
              return {
                ...item,
                value: "18-65",
              };
            }
            return item;
          })}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SelectMultiple
          name="audienceInterests"
          label={t(DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER)}
          variant="filled"
          disabled={
            (typeof isFiltering === "boolean" ? !isFiltering : null) || isOpen
          }
          fullWidth={true}
          options={CATEGORIES_PHYLLO}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8 * 2,
                width: 250,
              },
            },
          }}
          rules={{
            required: {
              value: false,
              message: "",
            },
          }}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Controller
          control={control}
          name="keywords"
          render={({ field }) => (
            <Autocomplete
              multiple
              disabled={
                (typeof isFiltering === "boolean" ? !isFiltering : null) ||
                isOpen
              }
              options={[]}
              freeSolo
              {...field}
              onChange={(event, value) => {
                field.onChange(value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="filled"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  variant="filled"
                  label={t(DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER)}
                />
              )}
            />
          )}
        />
      </Grid>
      {!isFetching && (
        <Grid item xs={12}>
          <Button type="submit" dataTour={PAGE_EXPLORER_SEARCH_BUTTON}>
            {t(BUTTON_SEARCH)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default DiscoveryInfluencersAdvancedForm;
