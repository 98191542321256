import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NavBar from "./NavBar/NavBar";
import MenuNavigation from "./MenuNavigation/MenuNavigation";
import OverlayChat from "../Overlays/OverlayChat";

const Navigation = ({ children, isLogged }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {isLogged && (
        <React.Fragment>
          <NavBar />
          <MenuNavigation />
        </React.Fragment>
      )}
      <Box component="main" sx={{ width: "100%", minHeight: "100vh" }}>
        {isLogged && <Toolbar />}
        <OverlayChat>{children}</OverlayChat>
      </Box>
    </Box>
  );
};

export default Navigation;
