import React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SwitchStore from "./SwitchStore";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import { capitalizeFirstLetter, getCityObject } from "../../../utils/formats";
import { REGULAR_STORE_TYPE } from "../../../utils/constants";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../lib/i18next";
import {
  STORES_TABLE_ARIAL_LABEL,
  COLUMN_NAME_TYPE,
  COLUMN_NAME_NAME,
  COLUMN_NAME_CITY,
  COLUMN_NAME_ADDRESS,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_ACTION,
  EMPTY_STORES,
} from "../../../locales/keysTranslations";

const StoresTable = ({
  data,
  isLoading,
  onSelectStore,
  onDeleteStore,
  onChangeStatusStore,
}) => {
  const { t } = useTranslationApp();

  const LOCALE = useLocale();
  const specialStores = LOCALE?.specialStores;

  const keys = Object.keys(data);
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        keys={keys}
        loading={isLoading}
        emptyText={EMPTY_STORES}
        hasPagination={false}
        aria-label={t(STORES_TABLE_ARIAL_LABEL)}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_TYPE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CITY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ADDRESS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PHONE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key, index) => {
            return (
              <TableRow key={key}>
                <TableCell align="center" indexRow={index}>
                  {data[key]?.storeType
                    ? data[key]?.storeType === REGULAR_STORE_TYPE
                      ? "Regular"
                      : capitalizeFirstLetter(
                          specialStores?.[data[key].storeType]?.value
                        )
                    : "-"}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[key].name}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[key].storeType === REGULAR_STORE_TYPE
                    ? getCityObject(LOCALE.cities, data[key].city)?.label
                    : ""}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[key].address}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[key].email}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[key].phone}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <SwitchStore
                    storeID={key}
                    isActive={data[key].isActive}
                    onChangeStatusStore={onChangeStatusStore}
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <IconButton onClick={() => onDeleteStore(key)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => onSelectStore(data[key], key)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default StoresTable;
