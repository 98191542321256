import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PricingHero from "../components/User/Pricing/PricingHero";
import PricingCardsContainer from "../components/User/Pricing/PricingCardsContainer";
import {
  PLANS_PACKAGE,
  PLAN_BASIC,
  PLAN_ENTERPRISE,
  PLAN_GROWTH,
  companyInformation,
  PLANS_INFO,
  PLAN_PRO,
  LINK_LANDING_CONTACT,
} from "../utils/constants";
import { useSelectorApp } from "../lib/redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { BUTTON_YOU_HAVE_DOUBTS } from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import { openLink } from "../utils/urls";

const Pricing = ({ onChangePlanSelected, isProrated = false }) => {
  const [plans, setPlans] = useState([]);
  const { plans: allPlans } = useSelectorApp((state) => state.payments);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslationApp();

  const isFeaturedPlan = (key) => key === PLAN_GROWTH || key === PLAN_PRO;
  const plansIncludeFeaturedPlan = Object.keys(allPlans).some((key) =>
    isFeaturedPlan(key)
  );
  useEffect(() => {
    const plans = [];
    const plansKeys = Object.keys(allPlans);
    if (isProrated) {
      plans.push({
        ...allPlans[PLAN_GROWTH],
        ...PLANS_INFO[PLAN_GROWTH],
        id: PLAN_GROWTH,
        idInformation: PLAN_GROWTH,
      });
    } else {
      plansKeys.forEach((key) => {
        if (key !== PLAN_ENTERPRISE && PLANS_PACKAGE.includes(key)) {
          if (isFeaturedPlan(key) && !plansKeys.includes(PLAN_BASIC)) {
            return plans.push({
              ...allPlans[key],
              ...PLANS_INFO[key],
              id: key,
              idInformation: `${key}_ONLY`,
            });
          }
          return plans.push({
            ...allPlans[key],
            ...PLANS_INFO[key],
            id: key,
            idInformation: key,
          });
        }
      });
      if (plansKeys.includes(PLAN_ENTERPRISE)) {
        plans.push({
          ...allPlans[PLAN_ENTERPRISE],
          ...PLANS_INFO[PLAN_ENTERPRISE],
          id: PLAN_ENTERPRISE,
          idInformation:
            (plansIncludeFeaturedPlan && allPlans?.[PLAN_GROWTH]?.price > 0) ||
            allPlans?.[PLAN_PRO]?.price > 0
              ? PLAN_ENTERPRISE
              : `${PLAN_ENTERPRISE}_ONLY`,
        });
      }
    }
    setPlans(plans);
    //eslint-disable-next-line
  }, [allPlans]);

  const isOnlyBasicPlan = plans.length === 1 && plans[0].id === PLAN_BASIC;

  return (
    <Box>
      <PricingHero
        isOnlyBasicPlan={isOnlyBasicPlan}
        isMobile={isMobile}
        isProrated={isProrated}
      />
      <PricingCardsContainer
        plans={plans}
        isProrated={isProrated}
        onChangePlanSelected={onChangePlanSelected}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 2,
          mx: 1,
          gap: 2,
        }}
      >
        <Typography
          variant="body1"
          align="center"
          onClick={() => openLink(LINK_LANDING_CONTACT)}
          sx={{
            textDecoration: "underline",
            fontSize: "1.1rem",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          {t(BUTTON_YOU_HAVE_DOUBTS)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" align="center">
            {`${companyInformation.name} - A Delaware LLC`}
          </Typography>
          <Typography variant="body2" align="center">
            {companyInformation.address}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Pricing;
