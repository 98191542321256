import { useEffect } from "react";

const MetaSDKLoader = () => {
  useEffect(() => {
    const loadFacebookSDK = () => {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

        window.FB.init({
          appId: appId,
          xfbml: true,
          version: "v17.0",
        });
      };
    };

    loadFacebookSDK();
  }, []);
  return null;
};

export default MetaSDKLoader;
