import types from "../types";

const initialState = {
  user: null,
  isLoading: true,
  isActivePlan: true,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case types.AUTH_UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.AUTH_LOADING_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.AUTH_LOGOUT:
      return {
        ...state,
        user: null,
      };
    case types.AUTH_RENEW_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.CHANGE_SHOP_SELECTED:
      return {
        ...state,
        user: {
          ...state.user,
          shopID: action.payload,
        },
      };
    case types.CHANGE_COUNTRY_USER:
      return {
        ...state,
        user: {
          ...state.user,
          country: action.payload,
        },
      };
    case types.CHANGE_COUNTRY_SHOP:
      return {
        ...state,
        user: {
          ...state.user,
          country: action.payload,
        },
      };
    case types.CHANGE_STORE_SELECTED:
      return {
        ...state,
        user: {
          ...state.user,
          storeID: action.payload,
        },
      };
    case types.VERIFY_IS_ACTIVE_PLAN:
      return {
        ...state,
        isActivePlan: action.payload,
      };
    case types.SET_USER_FEEDBACK:
      return {
        ...state,
        user: {
          ...state.user,
          feedback: action.payload,
        },
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
