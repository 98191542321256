import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { setShowBackdrop } from "../../../actions/ui";
import {
  startChangeCountryShop,
  startChangeCountryUser,
} from "../../../actions/auth";
import { getCountry } from "../../../actions/getters";
import { Controller } from "react-hook-form";
import { useTranslationApp } from "../../../lib/i18next";
import {
  FIELD_ALL_REQUIRED,
  REGISTER_COUNTRY_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import { useTheme } from "@mui/material";

const SelectCountry = ({
  showDemoCountries = false,
  control,
  variant = "standard",
  doChangeCustomClaim = false,
  name = "value",
  label = REGISTER_COUNTRY_PLACEHOLDER,
  actionsChange = () => {},
  labelInput = "code",
  useUserValue = true,
  valueFrom = "shop",
  size = "medium",
  useColorTheme = false,
  disabled = false,
  sxInput = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_ALL_REQUIRED),
    },
  },
  sx = {},
  errors = {},
  value,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const locales = useSelectorApp((state) => state?.locales?.localesList) || {};

  const localesFormatted = Object.values(locales)
    .filter((locale) => (showDemoCountries ? locale : !locale.isDemoCountry))
    .sort((a, b) => {
      if (a.isDemoCountry && !b.isDemoCountry) {
        return 1;
      } else if (!a.isDemoCountry && b.isDemoCountry) {
        return -1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const theme = useTheme();
  const country = dispatch(getCountry(valueFrom));

  const onChangeCustomClaim = async (option) => {
    dispatch(setShowBackdrop(true));
    let response;
    if (valueFrom === "shop") {
      response = await dispatch(startChangeCountryShop(option.code));
    }
    if (valueFrom === "user") {
      response = await dispatch(startChangeCountryUser(option.code));
    }
    response && setSelectedCountry(option);
    dispatch(setShowBackdrop(false));
    return;
  };

  useEffect(() => {
    if (country && useUserValue) {
      const found = localesFormatted.find((c) => c.code === country) || null;
      setSelectedCountry(found);
    }
    //eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (!value) return;

    const found = localesFormatted.find((c) => c.code === value) || null;
    if (found.code === selectedCountry?.code) return;

    setSelectedCountry(found);
    //eslint-disable-next-line
  }, [value]);

  if (!selectedCountry) return null;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          id="random"
          size={size}
          sx={{ ...sx }}
          options={localesFormatted}
          color="primary"
          disabled={disabled}
          fullWidth
          disableClearable={true}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          getOptionLabel={(option) =>
            labelInput === "code" ? option.code : option.name
          }
          value={selectedCountry}
          onChange={async (event, newValue) => {
            actionsChange();
            if (newValue.code === selectedCountry?.code) return;
            if (doChangeCustomClaim) {
              await onChangeCustomClaim(newValue);
            }
            field.onChange(newValue.code);
            setSelectedCountry(newValue);
          }}
          componentsProps={{
            popper: {
              sx: {
                width: "200px !important",
              },
            },
          }}
          renderOption={(props, option) => {
            return (
              <Box
                component="li"
                sx={{
                  "& > img": { mr: 2, flexShrink: 0 },
                  wordWrap: "break-word",
                }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${
                    option?.isDemoCountry ? option.codeDemo : option.code
                  }.png`}
                  srcSet={`https://flagcdn.com/w40/${
                    option?.isDemoCountry ? option.codeDemo : option.code
                  }.png 2x`}
                  alt=""
                />
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {option.name}
                </Typography>
              </Box>
            );
          }}
          renderInput={(params) => {
            return (
              <>
                <TextField
                  {...params}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color:
                        useColorTheme &&
                        `${theme.palette.background.paperContrastText} !important`,
                    },
                    "& .MuiInputBase-input": {
                      color:
                        useColorTheme &&
                        `${theme.palette.background.paperContrastText} !important`,
                    },
                    "& .MuiAutocomplete-endAdornment": {
                      "& .MuiButtonBase-root": {
                        color:
                          useColorTheme &&
                          `${theme.palette.background.paperContrastText} !important`,
                      },
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor:
                        useColorTheme &&
                        `${theme.palette.background.paperContrastText} !important`,
                    },
                    ...sxInput,
                  }}
                  label={label ? t(label) : ""}
                  hiddenLabel={!label}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "false",
                  }}
                  variant={variant}
                  error={!!errors[name]}
                  helperText={
                    errors[name] && (
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{errors[name]?.message}</span>
                      </Box>
                    )
                  }
                  InputProps={{
                    ...params.InputProps,

                    startAdornment: selectedCountry && (
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${
                          selectedCountry?.isDemoCountry
                            ? selectedCountry.codeDemo.toLowerCase()
                            : selectedCountry.code.toLowerCase()
                        }.png`}
                        srcSet={`https://flagcdn.com/w40/${
                          selectedCountry?.isDemoCountry
                            ? selectedCountry.codeDemo.toLowerCase()
                            : selectedCountry.code.toLowerCase()
                        }.png 2x`}
                        alt=""
                        style={{
                          marginRight: "8px",
                          verticalAlign: "middle",
                        }}
                      />
                    ),
                  }}
                />
              </>
            );
          }}
        />
      )}
    />
  );
};

export default SelectCountry;
