import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearLoader from "../../../Loader/LinearLoader";
import { AUDIENCE_BY_CITIES } from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";

const InfluencerAudienceCities = ({ data = [] }) => {
  const { t } = useTranslationApp();
  if (data.length === 0) return null;

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        {t(AUDIENCE_BY_CITIES)}
      </Typography>
      {data.map((city, index) => {
        if (index < 10) {
          return (
            <LinearLoader
              label={city.name}
              value={city.value}
              key={city.name}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};

export default InfluencerAudienceCities;
