import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import TextField from "../Fields/TextField";
import CurrencyField from "../NumberFormats/CurrencyField";
import ModalBasicLayout from "./ModalBasicLayout";
import { useTour } from "@reactour/tour";
import { PAGE_EXPLORER_INVITATION_VALUE } from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_SAVE,
  FIELD_REQUIRED,
} from "../../../locales/keysTranslations";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalValueField = ({
  modalOpen,
  onCloseModal,
  textButton = BUTTON_SAVE,
  value = "",
  label = "Valor",
  helperText = "",
  hasTitle = false,
  title = "",
  onCallbackButton = () => {},
  isNumberField = false,
  maxWidth = "xs",
  multiline = false,
  hasSecondButton = false,
  onCallbackSecondButton = () => {},
  secondTextButton = BUTTON_CANCEL,
  rules = {},
  InputLabelProps,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen } = useTour();

  const { t } = useTranslationApp();

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      value,
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const onSubmit = async (data) => {
    const { value } = data;
    setIsFetching(true);
    await onCallbackButton(value);
    setIsFetching(false);
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      maxWidth={maxWidth}
      fullWidth
      scroll="paper"
      ariaLabel={`Modal de ${label}`}
      title={title}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 1,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          dataTour={PAGE_EXPLORER_INVITATION_VALUE}
          name="value"
          label={label}
          variant="filled"
          multiline={multiline}
          minRows={3}
          maxRows={4}
          fullWidth
          errors={errors}
          control={control}
          helperText={helperText}
          rules={{
            required: {
              value: isOpen ? false : true,
              message: t(FIELD_REQUIRED),
            },
            ...rules,
          }}
          InputLabelProps={InputLabelProps}
          InputProps={{
            inputComponent: isNumberField ? CurrencyField : undefined,
          }}
        />
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: hasSecondButton,
            text: secondTextButton,
            onClick: onCallbackSecondButton,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: textButton,
            loading: isFetching,
            onClick: () => {},
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalValueField;
