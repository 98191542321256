import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import UploadImageButtons from "../../Buttons/UploadImageButtons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "../../../../utils/constants";
import { useDispatchApp } from "../../../../lib/redux";
import { getNameBusinessTheme } from "../../../../actions/getters";
import Loader from "../../Loader/Loader";
import {
  BUTTON_UPLOAD_CONTENT,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ModalGallery from "../../Gallery/ModalGallery";
import Button from "../../Buttons/Button";

const IconButtonAddContent = ({
  onAddContent: onAddContentCallback,
  pathStorage,
  mode = "icon",
  multiple = false,
  textButton = BUTTON_UPLOAD_CONTENT,
  acceptedFormats = IMAGE_FORMATS.concat(VIDEO_FORMATS),
  sx = {},
  showContent = false,
  showContentMode = "container",
  showButtonUpload = true,
  urlContent = "",
  size = "medium",
  color = "secondary",
  sxPreview = {},
  sxButton = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalGalleryOpen, setModalGalleryOpen] = useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const open = Boolean(anchorEl);
  const onSelectMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const onAddContent = async (newMedia) => {
    const contents = [
      {
        url: newMedia,
        from: "upload",
      },
    ];

    await onAddContentCallback(contents);
  };

  const onAddContentFromCollaborations = async (contents) => {
    setIsLoading(true);

    const contentsFormatted = contents.map((content) => ({
      url: content.url,
      from: "order",
      id: content.key,
      userID: content.userID,
    }));

    await onAddContentCallback(contentsFormatted);

    setIsLoading(false);
  };

  const getFilterModalGallery = () => {
    let hasVideoFormat = "";
    let hasImageFormat = "";

    acceptedFormats.forEach((format) => {
      if (VIDEO_FORMATS.includes(format)) {
        hasVideoFormat = true;
      }
      if (IMAGE_FORMATS.includes(format)) {
        hasImageFormat = true;
      }
    });

    if (hasVideoFormat && hasImageFormat) {
      return null;
    }

    if (hasVideoFormat) {
      return "video";
    }

    if (hasImageFormat) {
      return "image";
    }
  };

  const filterModalGallery = getFilterModalGallery();

  return (
    <Box
      sx={{
        height: mode === "icon" ? 92 : "auto",
        width: mode === "icon" ? 92 : "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <UploadImageButtons
        targetName="media"
        isHidden={true}
        onReadyUrlImage={(e) => {
          const url = e.target.value;
          if (!url) return;
          onAddContent(url);
        }}
        multiple={multiple}
        acceptedFormats={acceptedFormats}
        automaticSetRTDB={false}
        pathStorage={pathStorage}
        hasText={false}
        hasIcon={true}
        showImage={showContent}
        showImageMode={showContentMode}
        urlImage={urlContent}
        showButtonRemove={false}
        colorUpload="primary"
        uploadIcon={<AddCircleIcon sx={{ m: 0 }} />}
        idUploadComponent="add-content-media"
        onCallbackLoading={(loading) => setIsLoading(loading)}
        sxPreview={sxPreview}
        sx={{
          height: 92,
          width: 92,
          maxHeight: "unset",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiButton-endIcon": {
            m: 0,
            "& .MuiSvgIcon-root": {
              fontSize: "3rem",
              color: "white !important",
            },
          },
        }}
        sxContainer={{
          "& .Mui-disabled": {
            backgroundColor: "transparent !important",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      />
      {isLoading && <Loader colorCustom="white" hasMarginTop={false} />}
      {!isLoading && showButtonUpload && (
        <>
          {mode === "icon" ? (
            <IconButton sx={{ p: 0 }} onClick={onSelectMenu} type="button">
              <AddCircleIcon
                sx={{ color: "white !important", height: 40, width: 40 }}
              />
            </IconButton>
          ) : (
            <Button
              size={size}
              variant="contained"
              color={color}
              onClick={onSelectMenu}
              type="button"
              sx={sxButton}
            >
              {t(textButton)}
            </Button>
          )}
        </>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            document.getElementById("add-content-media").click();
            setAnchorEl(null);
          }}
        >
          {t(MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalGalleryOpen(true);
            setAnchorEl(null);
          }}
        >
          {t(
            MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION,
            {
              NAME_BUSINESS,
            }
          )}
        </MenuItem>
      </Menu>
      {modalGalleryOpen && (
        <ModalGallery
          filterByFormat={filterModalGallery}
          multiple={multiple}
          acceptedFormats={acceptedFormats}
          modalOpen={modalGalleryOpen}
          onCloseModal={() => setModalGalleryOpen(false)}
          onCallbackSelectedItems={onAddContentFromCollaborations}
        />
      )}
    </Box>
  );
};

export default IconButtonAddContent;
