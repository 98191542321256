import React, { useState } from "react";
import Box from "@mui/material/Box";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ModalMedia from "../Modal/ModalMedia";

const MediaDownloadContainer = ({
  children,
  url,
  sx = {},
  sxOverlay = {},
  hasSubSection = false,
  titleSubSection = "",
  actionIcon,
  onClosePrevComponent = () => {},
  metadata = {},
  onClick = () => {},
  onOpenModal = () => {},
  onClose = () => {},
  disabledActions = false,
}) => {
  const [isHover, setIsHover] = useState(false);

  const [modalMediaOpen, setModalMediaOpen] = useState(false);

  const onChangeIsHover = (status) => setIsHover(status);
  return (
    <Box
      sx={{
        ":hover": {
          transition: "1s",
          zIndex: 1,
        },
        cursor: isHover ? "pointer" : "default",
        width: "100%",
        height: "100%",
        ...sx,
      }}
      onMouseOver={() => {
        if (!modalMediaOpen) onChangeIsHover(true);
      }}
      onMouseLeave={() => {
        if (!modalMediaOpen) onChangeIsHover(false);
      }}
      onClick={() => {
        setModalMediaOpen(true);
        onClick();
        onOpenModal();
      }}
    >
      <Box
        sx={{
          filter: isHover ? "brightness(0.5)" : "brightness(1)",
          transition: "1s",
          width: "100%",
          height: "100%",
          ...sxOverlay,
        }}
      >
        {children}
      </Box>
      {hasSubSection && (
        <ImageListItemBar title={titleSubSection} actionIcon={actionIcon} />
      )}
      {modalMediaOpen && (
        <ModalMedia
          url={url}
          modalOpen={modalMediaOpen}
          onCloseModal={(e) => {
            setModalMediaOpen(false);
            onClose();
          }}
          onClosePrevComponent={onClosePrevComponent}
          metadata={metadata}
          disabledActions={disabledActions}
        />
      )}
    </Box>
  );
};

export default MediaDownloadContainer;
