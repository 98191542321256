import React from "react";
import Typography from "@mui/material/Typography";

const ContentUGCContentFormat = ({ text }) => {
  return (
    <Typography color="inherit" variant="body1" sx={{ mb: 1 }}>
      {text}
    </Typography>
  );
};

export default ContentUGCContentFormat;
