import React, { useEffect, useState } from "react";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CONTINUE,
  BUTTON_GENERATE_OTHER,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING,
  FIELD_MAX_LENGTH,
  FIELD_MIN_LENGTH,
  FIELD_REQUIRED,
} from "../../../../locales/keysTranslations";
import TextField from "../../../Common/Fields/TextField";
import LoadingButton from "../../../Common/Buttons/LoadingButton";
import { useForm } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { startGenerateDescriptionAndScriptCampaign } from "../../../../actions/campaigns";
import { useDispatchApp } from "../../../../lib/redux";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const ModalGenerateScriptAndDescriptionCampaign = ({
  modalOpen,
  onCloseModal = () => {},
  data = {},
  onCallbackSave = () => {},
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslationApp();

  const { control, formState, setValue, handleSubmit } = useForm({
    defaultValues: {
      descriptionAi: "",
      script: "",
    },
  });
  const errors = formState.errors;
  useFocusErrorForm(formState);

  const dispatch = useDispatchApp();

  useEffect(() => {
    onGenerateScriptAndDescriptionCampaign({});
    // eslint-disable-next-line
  }, []);

  const onGenerateScriptAndDescriptionCampaign = async ({
    generateDescription = true,
    generateScript = true,
  }) => {
    setIsGenerating(true);
    const response = await dispatch(
      startGenerateDescriptionAndScriptCampaign({
        campaignData: data,
        generateDescription,
        generateScript,
      })
    );
    if (response) {
      const { description, script } = response;
      if (description && generateDescription) {
        setValue("descriptionAi", description);
      }
      if (script && generateScript) {
        setValue("script", script);
      }
    }
    setIsGenerating(false);
  };

  const onSubmit = async (data) => {
    setIsSaving(true);
    await onCallbackSave(data);
    setIsSaving(false);
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      hasTitle={true}
      ariaLabel={t(CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE)}
      showCloseButton={false}
      sxTitle={{
        pt: 2,
        pb: 0,
        pl: 2,
        pr: 2,
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h6" align="center" sx={{ fontWeight: 600, mb: 2 }}>
          {t(
            isGenerating
              ? CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING
              : CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE
          )}
        </Typography>

        <TextField
          control={control}
          label={t(
            CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL
          )}
          name="script"
          InputLabelProps={{ shrink: true }}
          variant="filled"
          multiline={true}
          minRows={10}
          maxRows={10}
          fullWidth
          showCounterCharacters={true}
          maxCharacters={3000}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            minLength: {
              value: 50,
              message: t(FIELD_MIN_LENGTH, { value: 50 }),
            },
            maxLength: {
              value: 3000,
              message: t(FIELD_MAX_LENGTH, { value: 3000 }),
            },
          }}
          errors={errors}
        />
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 1,
            width: "100%",
          }}
        >
          <LoadingButton
            startIcon={<AutoAwesomeIcon />}
            loading={isGenerating}
            disabled={isSaving}
            fullWidth
            type="button"
            onClick={() =>
              onGenerateScriptAndDescriptionCampaign({
                generateDescription: false,
              })
            }
            sx={{
              background: GRADIENT_PURPLE_FUCHSIA,
              color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            }}
          >
            {t(BUTTON_GENERATE_OTHER)}
          </LoadingButton>
          <LoadingButton
            disabled={isGenerating}
            loading={isSaving}
            color="secondary"
            fullWidth
          >
            {t(BUTTON_CONTINUE)}
          </LoadingButton>
        </Box>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalGenerateScriptAndDescriptionCampaign;
