import React, { useState, useEffect } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import { usePagination } from "../../../hooks/usePagination";
import {
  DEFAULT_TABLE_PAGES,
  INVITATION_INFLUENCER_MODE_CREATE,
  INVITATION_INFLUENCER_MODE_EDIT,
  INVITATION_INFLUENCER_MODE_INVITE,
  MAX_REFERENCE_PROFILES,
} from "../../../utils/constants";
import _ from "lodash";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startGetInvitesByShop } from "../../../actions/invites";
import ModalInvitation from "../../User/InfluencersListTable/ModalInvitation";
import {
  startGetReferenceProfiles,
  startSaveReferenceProfiles,
} from "../../../actions/shops";
import ReferenceProfilesTable from "../Table/ReferenceProfilesTable";

const ModalReferenceProfiles = ({
  modalOpen,
  onCloseModal,
  loading,
  influencers,
}) => {
  const { rowsPerPage, page, onPageChange } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: () => {},
  });
  const [modalInvitationOpen, setModalInvitationOpen] = useState(false);
  const [influencer, setInfluencer] = useState(null);
  const [referenceProfiles, setReferenceProfile] = useState([]);

  const dispatch = useDispatchApp();

  const averageTicket = useSelectorApp((state) => state.shop.averageTicket);
  const invites = useSelectorApp((state) => state.invites.invites);

  const getInitialData = async () => {
    const referenceProfiles = await dispatch(startGetReferenceProfiles());
    if (referenceProfiles) setReferenceProfile(referenceProfiles);
  };

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(influencers) && !loading) {
      dispatch(startGetInvitesByShop());
    }
    //eslint-disable-next-line
  }, [loading]);

  const onSelectInfluencer = (influencer) => {
    setInfluencer(influencer);
    setModalInvitationOpen(true);
  };

  const onAddProfileReference = async ({ userID }) => {
    const influencer = influencers[userID];
    if (!influencer) return;

    const found = referenceProfiles.find(
      (profile) => profile.userID === userID
    );
    if (found) return;

    let newProfilesReference = [...referenceProfiles];
    if (referenceProfiles.length < MAX_REFERENCE_PROFILES) {
      newProfilesReference.push({
        accountName: influencer.mainAccountName,
        platform: influencer.mainPlatform,
        userID,
      });
    } else {
      newProfilesReference.shift();
      newProfilesReference.push({
        accountName: influencer.mainAccountName,
        platform: influencer.mainPlatform,
        userID,
      });
    }
    const response = await dispatch(
      startSaveReferenceProfiles(newProfilesReference)
    );
    if (response) {
      setReferenceProfile(newProfilesReference);
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      hasTitle={false}
      sxContent={{
        padding: 5,
      }}
    >
      <ReferenceProfilesTable
        influencers={influencers}
        loading={loading}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onActionInfluencer={onSelectInfluencer}
        onAddProfileReference={onAddProfileReference}
        referenceProfiles={referenceProfiles}
      />

      {modalInvitationOpen && (
        <ModalInvitation
          dataInfluencer={influencer}
          userID={influencer?.userID}
          dataInvite={
            influencer?.inviteID
              ? {
                  ...invites[influencer?.inviteID],
                  code: invites[influencer.inviteID].inviteCode,
                  inviteID: influencer.inviteID,
                }
              : {}
          }
          onCloseModal={() => {
            setModalInvitationOpen(false);
            setInfluencer(null);
          }}
          averageTicket={averageTicket}
          from={INVITATION_INFLUENCER_MODE_INVITE}
          mode={
            influencer?.inviteID
              ? INVITATION_INFLUENCER_MODE_EDIT
              : INVITATION_INFLUENCER_MODE_CREATE
          }
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalReferenceProfiles;
