import React from "react";
import Box from "@mui/material/Box";
import { PAGE_CAMPAIGNS_ANSWER_CONTAINER } from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { MAX_WIDTH_ANSWER_CONTAINER } from "../../../../utils/constants";

const AnswerContainer = ({
  step,
  stepPosition,
  children,
  onNextCallback = () => {},
  sx,
  sxContainer = {},
}) => {
  const { isOpen } = useTour();
  if (step !== stepPosition) {
    return null;
  }
  return (
    <div
      style={{
        width: "100%",
        maxWidth: MAX_WIDTH_ANSWER_CONTAINER,
        ...sxContainer,
      }}
      className={
        step === stepPosition && !isOpen
          ? "animate__animated animate__fadeInUp animate__faster"
          : ""
      }
      data-tour={step === stepPosition && PAGE_CAMPAIGNS_ANSWER_CONTAINER}
      onKeyUp={(e) => {
        if (e.code === "Enter") {
          onNextCallback();
        }
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: MAX_WIDTH_ANSWER_CONTAINER,
          px: 4,
          ...sx,
          display: step !== stepPosition ? "none" : "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default AnswerContainer;
