import React, { useState, useEffect } from "react";
import ContainerPaperSection from "../ContainerPaperSection";
import Loader from "../../../Common/Loader/Loader";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  startGenerateCommentProfileInfluencer,
  startSaveAutoInvitationsIsActive,
  startSaveProfileInfluencerInfo,
} from "../../../../actions/shops";
import ProfileInfluencerFormOptional from "./ProfileInfluencerFormOptional";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Grid";
import ProfileInfluencerFormBudget from "./ProfileInfluencerFormBudget";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  AUTOMATIC_INVITES_ACTIVATE,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION,
  AUTOMATIC_INVITES_DEACTIVATE,
  AUTOMATIC_INVITES_DESCRIPTION,
  AUTOMATIC_INVITES_TITLE,
  BUTTON_CONTINUE,
  EDIT,
  PROFILE_INFLUENCER_INFORMATION_SAVED,
  PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED,
  READY,
  VIEW_SUGGESTED_CREATORS,
} from "../../../../locales/keysTranslations";
import { ConfirmAlert, SimpleAlert } from "../../../../utils/alerts";
import {
  ALERT_ICON_TYPE_INFO,
  ALERT_ICON_TYPE_SUCCESS,
  TAB_SUGGESTED_INDEX,
} from "../../../../utils/constants";
import { validationProfileInfluencer } from "../../../../utils/influencers";
import { formatURL } from "../../../../utils/formats";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { getIsBlockedActions } from "../../../../actions/getters";
import Switch from "../../../Common/Forms/Switchs/Switch";

const FIELDS = [
  "autoInvitationsIsActive",
  "budgets",
  "budgetMonthly",
  "comment",
  "typeContent",
  "formats",
  "numberInvitations",
  "terms",
  "contentReference",
];
const AutomaticInvitesContainer = ({
  elevation = 1,
  sx = {},
  hasDividers = true,
  from = "invitations",
  onCallbackSave = () => {},
}) => {
  const [isSavingAutoInvitations, setIsSavingAutoInvitations] = useState(false);
  const { handleSubmit, watch, control, setValue, getValues, formState } =
    useForm({
      defaultValues: {
        autoInvitationsIsActive: false,
        budgets: [
          {
            value: "",
            audienceRange: [5000, 500000],
            fieldName: `budget1`,
          },
        ],
        budgetMonthly: null,
        comment: "",
        formats: {
          instagramBurstStories: false,
          instagramPost: false,
          instagramReel: false,
          instagramStory: false,
          tiktokPost: false,
          tiktokStory: false,
        },
        numberInvitations: null,
        terms: "",
        contentReference: "",
        typeContent: {
          images: false,
          videos: true,
        },
      },
    });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingComment, setIsFetchingComment] = useState(false);

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const autoInvitationsIsActive = watch("autoInvitationsIsActive");

  const profileInfluencerData = useSelectorApp(
    (state) => state.shop.profileInfluencer
  );
  const isFreeTrial = useSelectorApp((state) => state.shop.isFreeTrial);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof isFreeTrial === "boolean") {
      setValue("autoInvitationsIsActive", false);
      return;
    }
    // eslint-disable-next-line
  }, [isFreeTrial]);

  useEffect(() => {
    Object.keys(profileInfluencerData).forEach((key) => {
      if (!FIELDS.includes(key)) return;
      if (key === "budgets") {
        const budgetsFormatted = profileInfluencerData[key].map(
          (budget, index) => {
            setValue(`budget${index + 1}`, budget);
            return {
              ...budget,
              fieldName: `budget${index + 1}`,
            };
          }
        );
        return setValue(key, budgetsFormatted);
      }
      if (
        key === "autoInvitationsIsActive" &&
        typeof isFreeTrial === "boolean"
      ) {
        setValue(key, false);
        return;
      }
      setValue(key, profileInfluencerData[key]);
    });
    setIsLoading(false);
    // eslint-disable-next-line
  }, [profileInfluencerData, isFreeTrial]);

  const onSaveAutoInvitations = async (autoInvitationsIsActive) => {
    setIsSavingAutoInvitations(true);
    const response = await dispatch(
      startSaveAutoInvitationsIsActive(autoInvitationsIsActive)
    );
    if (!response) {
      setValue("autoInvitationsIsActive", !autoInvitationsIsActive);
    } else {
      setValue("autoInvitationsIsActive", autoInvitationsIsActive);
    }
    setIsSavingAutoInvitations(false);
  };

  const onGenerateComment = async () => {
    setIsFetchingComment(true);
    const response = await dispatch(
      startGenerateCommentProfileInfluencer({
        typeContent: getValues("typeContent"),
        formats: getValues("formats"),
        wantsSocialMediaPost: true,
      })
    );
    if (response) {
      setValue("comment", response);
    }
    setIsFetchingComment(false);
  };

  const onSubmit = async (data) => {
    if (autoInvitationsIsActive) {
      const isBlocked = dispatch(
        getIsBlockedActions({
          verifyBlockedLastInvite: true,
          verifyBlockedFreeTrial: true,
          verifyBlockedExchange: true,
          from: "automaticInvitations",
        })
      );

      if (isBlocked) {
        setValue("autoInvitationsIsActive", false);
        return;
      }
    }
    const { numberInvitations } = data;

    if (Number(numberInvitations) <= 9) {
      const result = await ConfirmAlert({
        title: t(AUTOMATIC_INVITES_TITLE),
        text: t(AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION),
        cancelButtonText: t(EDIT),
        confirmButtonText: t(BUTTON_CONTINUE),
        icon: ALERT_ICON_TYPE_INFO,
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    setIsFetching(true);
    Object.keys(data).forEach((key) => {
      const info = data[key];
      if (/^budget\d+$/.test(key) && !/^budgets/.test(key)) {
        delete data[key];
      }
      if (key === "budgets") {
        data[key] = info.map((budget) => {
          const { value, audienceRange } = budget;
          return {
            value: Number(value),
            audienceRange,
          };
        });
      }
    });
    const response = await dispatch(
      startSaveProfileInfluencerInfo(
        {
          ...data,
          contentReference: data.contentReference
            ? formatURL(data.contentReference)
            : "",
        },
        true
      )
    );
    setIsFetching(false);
    if (response) {
      onCallbackSave();
      if (from === "invitations") {
        const validateProfileIsComplete = validationProfileInfluencer({
          ...profileInfluencerData,
          ...data,
        });
        if (!validateProfileIsComplete) {
          SimpleAlert({
            title: t(PROFILE_INFLUENCER_INFORMATION_SAVED),
            icon: ALERT_ICON_TYPE_SUCCESS,
          });
        } else {
          const result = await ConfirmAlert({
            title: t(PROFILE_INFLUENCER_INFORMATION_SAVED),
            text: t(PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED),
            confirmButtonText: t(VIEW_SUGGESTED_CREATORS),
            icon: ALERT_ICON_TYPE_SUCCESS,
          });
          if (result.isConfirmed) {
            return navigate(`/creators?tab=${TAB_SUGGESTED_INDEX}`);
          }
        }
      }
      if (from === "modalInvitation") {
        SimpleAlert({
          title: t(READY),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <ContainerPaperSection
      title={AUTOMATIC_INVITES_TITLE}
      onSubmit={handleSubmit(onSubmit)}
      isFetching={isFetching}
      showTopButton={false}
      showBottomButton={false}
      hasDividers={hasDividers}
      elevation={elevation}
      className="automaticInvitations"
      sx={sx}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: 2,
              p: 1,
              background: GRADIENT_PURPLE_FUCHSIA,
              maxWidth: "100%",
              borderRadius: 5,
            }}
          >
            <FormControl
              component="fieldset"
              variant="standard"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                sx={{
                  display: "flex",
                  ".MuiFormControlLabel-label": {
                    fontSize: "1rem",
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT} !important`,
                  },
                  ml: 0,
                }}
                labelPlacement="end"
                label={
                  watch("autoInvitationsIsActive")
                    ? t(AUTOMATIC_INVITES_ACTIVATE)
                    : t(AUTOMATIC_INVITES_DEACTIVATE)
                }
                control={
                  <Controller
                    control={control}
                    name="autoInvitationsIsActive"
                    render={({ field }) => (
                      <Switch
                        hasLabel={false}
                        disabled={isSavingAutoInvitations}
                        sx={{
                          mr: 1,
                        }}
                        isActive={field.value}
                        onChangeIsActive={(checked) => {
                          if (checked) {
                            const isBlocked = dispatch(
                              getIsBlockedActions({
                                verifyBlockedLastInvite: true,
                                verifyBlockedFreeTrial: true,
                                verifyBlockedExchange: true,
                                from: "automaticInvitations",
                              })
                            );
                            if (isBlocked) return;
                          }

                          onSaveAutoInvitations(checked);
                          return field.onChange(checked);
                        }}
                      />
                    )}
                  />
                }
              />
            </FormControl>
            <FormHelperText
              sx={{
                pl: 1.5,
                color: `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT} !important`,
              }}
            >
              {t(AUTOMATIC_INVITES_DESCRIPTION)}
            </FormHelperText>
          </Box>
        </Grid>
        <ProfileInfluencerFormBudget
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <ProfileInfluencerFormOptional
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSaveAutoInvitations={onSaveAutoInvitations}
          isFetchingComment={isFetchingComment}
          onGenerateComment={onGenerateComment}
        />
      </Grid>
    </ContainerPaperSection>
  );
};

export default AutomaticInvitesContainer;
