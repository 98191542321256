import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ModalYouKnow = ({ modalOpen, onCloseModal, data }) => {
  const navigate = useNavigate();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="md"
      sxContent={{
        p: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      sxPaper={{
        height: "100%",
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: "none",
        m: {
          xs: 2,
          md: 4,
        },
        overflow: "hidden",
      }}
      hasTitle={false}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            zIndex: 1,
            mt: { xs: 1, md: 2 },
            ml: { xs: 1, md: 2 },
            color: "#fff",
            backgroundColor: "#000",
            "&:hover": {
              backgroundColor: "#000",
              color: "#fff",
              filter: "brightness(0.8)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Avatar
          src={data.imageURL}
          variant="square"
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(data.redirectURL);
            onCloseModal();
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalYouKnow;
