import { useSelectorApp } from "../../lib/redux";
import { Navigate } from "react-router-dom";
import {
  ADMIN_ROLE,
  OPERATOR_ROLE,
  SUPER_ADMIN_ROLE,
} from "../../utils/constants";

export const OperatorRoute = ({ children, isLogged }) => {
  const { user } = useSelectorApp((state) => state.auth);
  return isLogged &&
    (user.role === OPERATOR_ROLE ||
      user.role === ADMIN_ROLE ||
      user.role === SUPER_ADMIN_ROLE) ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
