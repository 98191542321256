import React from "react";
import Box from "@mui/material/Box";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import _ from "lodash";
import { REGULAR_STORE_TYPE } from "../../../utils/constants";
import ModalStore from "../../User/Stores/ModalStore";
import ModalCatalog from "../Modal/ModalCatalog";
import { startChangeIsActiveStore } from "../../../actions/shops";

const OnboardingStoresContainer = ({ modalOpen, onCloseModal }) => {
  const stores = useSelectorApp((state) => state?.shop?.stores);
  const dispatch = useDispatchApp();

  const storesKeys = Object.keys(stores || {});
  const isEmptyStores = _.isEmpty(stores);
  const store = stores[storesKeys[0]];
  const requiredCatalog = store?.storeType === REGULAR_STORE_TYPE;

  const onChangeStatusStore = async () => {
    const response = await dispatch(
      startChangeIsActiveStore(storesKeys[0], true)
    );
    return response;
  };

  return (
    <Box>
      {isEmptyStores && (
        <ModalStore
          modalOpen={isEmptyStores}
          onCloseModal={onCloseModal}
          closeModalAfterCreate={false}
          closeModalAfterUpdate={false}
          validateMenu={false}
          mode={"create"}
        />
      )}
      {!isEmptyStores && requiredCatalog && (
        <ModalCatalog
          modalOpen={!isEmptyStores && requiredCatalog}
          onCloseModal={onCloseModal}
          onCallbackCreateCatalog={onChangeStatusStore}
        />
      )}
    </Box>
  );
};

export default OnboardingStoresContainer;
