import React from "react";
import Chip from "@mui/material/Chip";

//icons
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import DiamondIcon from "@mui/icons-material/Diamond";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import SignLanguageIcon from "@mui/icons-material/SignLanguage";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import AlbumIcon from "@mui/icons-material/Album";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import SchoolIcon from "@mui/icons-material/School";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import BusinessIcon from "@mui/icons-material/Business";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import FlightIcon from "@mui/icons-material/Flight";
import SpaIcon from "@mui/icons-material/Spa";
import ForestIcon from "@mui/icons-material/Forest";
import PetsIcon from "@mui/icons-material/Pets";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import GradeIcon from "@mui/icons-material/Grade";
import { useTheme } from "@mui/material/styles";
import { useTranslationApp } from "../../../lib/i18next";
import {
  FAVORITES_CATEGORY,
  NEW_CREATORS_CATEGORY,
  TOP_RATED_CATEGORY,
} from "../../../locales/keysTranslations";
import { capitalizeSections } from "../../../utils/formats";
import { influencersCategories } from "../../../utils/categories";

const categoryIcons = {
  Lifestyle: <SensorOccupiedIcon />,
  Media: <PermMediaIcon />,
  Education: <SchoolIcon />,
  Fitness: <FitnessCenterIcon />,
  Foodie: <FastfoodIcon />,
  Sports: <SportsSoccerIcon />,
  Productivity: <KeyboardDoubleArrowUpIcon />,
  Model: <EmojiPeopleIcon />,
  Cooking: <RestaurantIcon />,
  Business: <BusinessIcon />,
  Gastronomía: <DinnerDiningIcon />,
  Moda: <CheckroomIcon />,
  Fashion: <DiamondIcon />,
  Wellness: <SelfImprovementIcon />,
  Maquillaje: <SignLanguageIcon />,
  Music: <MusicNoteIcon />,
  Entertainment: <VideogameAssetIcon />,
  Technology: <ImportantDevicesIcon />,
  Beauty: <SpaIcon />,
  Travel: <FlightIcon />,
  Celebridades: <PersonPinIcon />,
  "Arts & Culture": <AlbumIcon />,
  Environment: <ForestIcon />,
  Pets: <PetsIcon />,
  Home: <HomeIcon />,
  [NEW_CREATORS_CATEGORY]: <FiberNewIcon />,
  [FAVORITES_CATEGORY]: <FavoriteIcon />,
  [TOP_RATED_CATEGORY]: <GradeIcon />,
};

const InfluencerCategory = ({
  category,
  size = "medium",
  mode = "dark",
  onSelectCategory,
  isSelected,
  isCustomTagSelected = false,
  styles,
  disabled = false,
  sx = {},
}) => {
  const { t } = useTranslationApp();
  const theme = useTheme();

  const isSpecialCategory =
    category === NEW_CREATORS_CATEGORY ||
    category === FAVORITES_CATEGORY ||
    category === TOP_RATED_CATEGORY;

  const isCustomTag = !influencersCategories.includes(category);

  const label = isSpecialCategory
    ? t(category)
    : isCustomTag
    ? capitalizeSections(category.replace(/_/g, " "), " ")
    : category;

  const fontSizeXs =
    size === "large" ? "0.75rem" : size === "medium" ? "0.65rem" : "0.55rem";
  const fontSizeSm =
    size === "large" ? "0.8rem" : size === "medium" ? "0.7rem" : "0.6rem";

  const sxCommon = {
    ...styles,
    "& .MuiChip-label": {
      fontSize: { xs: fontSizeXs, sm: fontSizeSm },
    },
  };

  const backgroundColorDark = isSelected
    ? theme.palette.primary.main
    : theme.palette.background.paper;
  const backgroundColorLight = isSelected
    ? theme.palette.secondary.main
    : theme.palette.white.main;
  const backgroundColor =
    mode === "dark" ? backgroundColorDark : backgroundColorLight;

  const colorDark = isSelected
    ? theme.palette.primary.contrastText
    : theme.palette.background.paper.contrastText;
  const colorLight = isSelected
    ? theme.palette.secondary.contrastText
    : theme.palette.white.contrastText;

  const color = mode === "dark" ? colorDark : colorLight;

  if (isSpecialCategory) {
    return (
      <Chip
        sx={{
          ...sxCommon,
          backgroundColor: "transparent",
          color: isSelected
            ? "background.paperSecondaryContrastText"
            : "fields.placeholder",
          "&:hover": {
            backgroundColor: "transparent",
            color: isSelected
              ? "background.paperSecondaryContrastText"
              : "fields.placeholder",
          },
          flexDirection: "column",
          height: "auto",
          py: 0.1,
          "& .MuiChip-icon": {
            color: isSelected
              ? "background.paperSecondaryContrastText"
              : "fields.placeholder",
            m: 0,
          },
          ...sx,
        }}
        size={size}
        disabled={disabled}
        icon={categoryIcons[category]}
        label={label}
        onClick={() => {
          onSelectCategory(category);
        }}
      />
    );
  }
  return (
    <Chip
      sx={{
        ...sxCommon,
        backgroundColor,
        color,
        "& .MuiChip-label": {
          fontSize: { xs: "0.65rem", sm: "0.7rem" },
        },
        "& .MuiSvgIcon-root": {
          color,
        },
        ...sx,
      }}
      size={size}
      disabled={disabled}
      icon={categoryIcons[category]}
      label={label}
      color={isSelected ? "primary" : "default"}
      onClick={() => {
        onSelectCategory(category);
      }}
    />
  );
};

export default InfluencerCategory;
