import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Box from "@mui/material/Box";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getShopData } from "../../actions/shops";
import { ConfirmAlert, SimpleAlert } from "../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  DEFAULT_PACKAGE_PRICES,
  PAYMENT_MODEL_PACKAGE,
} from "../../utils/constants";
import Loader from "../../components/Common/Loader/Loader";
import ShopBasicInfo from "../../components/Common/Shops/ShopBasicInfo";
import ShopActions from "../../components/Owner/Shops/ShopActions";
import ShopReport from "../../components/Owner/Shops/ShopReport";
import { startGetPlans } from "../../actions/payments";
import Button from "../../components/Common/Buttons/Button";
import ModalShopInformation from "../../components/Owner/Shops/ModalShopInformation";
import TitlePage from "../../components/Common/Texts/TitlePage";
import {
  startChangePlanToSubscriptionBasic,
  startGetStaffList,
} from "../../actions/owner";
import ModalStripeCreatePaymentLink from "../../components/Common/Stripe/ModalStripeCreatePaymentLink";
import Typography from "@mui/material/Typography";
import {
  ARE_YOU_SURE,
  BUTTON_EDIT,
  BUTTON_PASS_TO_SUBSCRIPTION_BASIC,
  CHANGE,
  INFORMATION_INVALID_ACCESS,
  READY,
  SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK,
  SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION,
  SHOP_DETAIL_TITLE,
  USERS,
} from "../../locales/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const ShopDetail = () => {
  const [data, setData] = useState({});
  const [modalOpenInformation, setModalOpenInformation] = useState(false);
  const [
    modalStripeCreatePaymentLinkOpen,
    setModalStripeCreatePaymentLinkOpen,
  ] = useState(false);
  const [loading, setLoading] = useState(true);

  const country = useSelectorApp((state) => state?.auth?.user?.country);
  const { t } = useTranslationApp();

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatchApp();

  const shopID = params.get("shopID");

  const getInitialData = async () => {
    if (!shopID) {
      return navigate("/shops");
    }

    const shopData = await dispatch(getShopData(shopID));
    if (!shopData) {
      SimpleAlert({
        title: t(INFORMATION_INVALID_ACCESS),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return navigate("/shops");
    }
    await dispatch(startGetPlans());
    setData({ ...shopData, shopID });
    setLoading(false);
  };

  useEffect(() => {
    dispatch(startGetStaffList());
    //eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [shopID]);

  const onChangeStatusModalInformation = (status) => {
    setModalOpenInformation(status);
  };
  const onChangeStatusModalStripeCreatePaymentLink = (status) => {
    setModalStripeCreatePaymentLinkOpen(status);
  };

  const onChangePlanToSubscriptionBasic = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION),
      confirmButtonText: t(CHANGE),
    });

    if (!result.isConfirmed) return;

    const response = await dispatch(
      startChangePlanToSubscriptionBasic({
        shopID,
        plan: DEFAULT_PACKAGE_PRICES?.basic,
      })
    );

    if (!response) return;

    setData({
      ...data,
      isPlanActive: false,
      isActive: false,
      paymentModel: PAYMENT_MODEL_PACKAGE,
      subscriptionPlan: null,
    });
    SimpleAlert({
      title: t(READY),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
  };

  if (loading) return <Loader fullWidth />;

  return (
    <ContainerPage>
      <TitlePage>
        {t(SHOP_DETAIL_TITLE, {
          value: data.businessName || "",
        })}
      </TitlePage>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          width: "100%",
          mt: 2,
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: { xs: "100%", md: "35%" },
          }}
        >
          <ShopBasicInfo data={data} />
          <ShopActions data={data} />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <Button
              sx={{
                minWidth: 240,
              }}
              onClick={onChangePlanToSubscriptionBasic}
            >
              {t(BUTTON_PASS_TO_SUBSCRIPTION_BASIC)}
            </Button>
            <Button
              sx={{
                minWidth: 240,
              }}
              onClick={() => onChangeStatusModalInformation(true)}
            >
              {t(BUTTON_EDIT)}
            </Button>
            <Button
              sx={{
                minWidth: 240,
                backgroundColor: "#635BFF",
                color: "white",
                "&:hover": {
                  backgroundColor: "#635BFF",
                  filter: "brightness(0.9)",
                },
              }}
              onClick={() => onChangeStatusModalStripeCreatePaymentLink(true)}
            >
              {t(SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK)}
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {t(USERS)}
            </Typography>
            {data.users &&
              Object.keys(data.users).map((userID) => {
                const user = data.users[userID];
                return <Box key={userID}>{user.email}</Box>;
              })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: { xs: "100%", md: "65%" },
          }}
        >
          <ShopReport data={data} />
        </Box>
      </Box>
      {modalOpenInformation && (
        <ModalShopInformation
          modalOpen={modalOpenInformation}
          onCloseModal={() => onChangeStatusModalInformation(false)}
          data={data}
          onChangeData={setData}
        />
      )}
      {modalStripeCreatePaymentLinkOpen && (
        <ModalStripeCreatePaymentLink
          modalOpen={modalStripeCreatePaymentLinkOpen}
          onCloseModal={() => onChangeStatusModalStripeCreatePaymentLink(false)}
          metadata={{
            shopID: data.shopID,
          }}
          data={data}
          shopID={data.shopID}
        />
      )}
    </ContainerPage>
  );
};

export default ShopDetail;
