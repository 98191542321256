import React from "react";
import { useSelectorApp } from "../lib/redux";
import Container from "@mui/material/Container";
import ResetPasswordForm from "../components/Web/ResetPassword/ResetPasswordForm";

const ResetPassword = () => {
  const { theme } = useSelectorApp((state) => state.ui);

  return (
    <Container
      maxWidth="md"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={theme.logo}
        alt="Logo"
        style={{ width: "50%", maxWidth: 300 }}
      />
      <ResetPasswordForm />
    </Container>
  );
};

export default ResetPassword;
