import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Button from "../../Common/Buttons/Button";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import { COUNTRIES_BUZZLY } from "../../../utils/constants";
import {
  ACTIONS,
  BUTTON_VIEW_DETAILS,
  COLUMN_NAME_STAFF,
  COLUMN_NAME_COST_COLLABORATION,
  COLUMN_NAME_COUNTRY,
  COLUMN_NAME_CREDITS,
  COLUMN_NAME_IS_ACTIVE,
  COLUMN_NAME_PAYMENT_METHOD,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_PLAN,
  COLUMN_NAME_SHOP,
  COLUMN_NAME_ID,
  SHOPS_TABLE_ARIA_LABEL,
  VALUE_WITH_CURRENCY,
  YES,
  NO,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocale } from "../../../Contexts/LocaleContext";
import { getPlanName } from "../../../utils/formats";

const ShopsTable = ({
  data,
  onSelectShop,
  rowsPerPage,
  page,
  onRowsPerPageChange,
  onPageChange,
  signUpCountry,
}) => {
  const onFilterData = () => {
    const filtered = {};

    Object.keys(data).forEach((key) => {
      if (data[key]?.signUpCountry === signUpCountry) {
        filtered[key] = data[key];
      }
    });

    return filtered;
  };

  const LOCALE = useLocale();
  const { t } = useTranslationApp();

  const filteredData = onFilterData();

  const keys = Object.keys(filteredData);

  const staffList = useSelectorApp((state) => state.shop.staff);

  const { plans } = useSelectorApp((state) => state.payments);

  const getStaffName = (staffID) => {
    const found = staffList.find((c) => c.staffID === staffID);
    if (found) return found.name;
    return "-";
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        loading={false}
        rowsPerPage={rowsPerPage}
        keys={keys}
        ariaLabel={t(SHOPS_TABLE_ARIA_LABEL)}
        sx={{ overflow: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SHOP)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PHONE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PLAN)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_IS_ACTIVE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_PAYMENT_METHOD)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_COST_COLLABORATION)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREDITS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STAFF)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_COUNTRY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(ACTIONS)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : keys
          ).map((row, index) => (
            <TableRow key={index}>
              <TableCell
                align="center"
                indexRow={index}
                sx={{
                  wordBreak: "break-all",
                }}
              >
                {row}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row]?.businessName ?? "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row]?.adminPhone ?? "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row]?.subscriptionPlan
                  ? getPlanName({ planID: data[row].subscriptionPlan, plans })
                  : "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row]?.isActive ? t(YES) : t(NO)}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row]?.stripe?.defaultPaymentMethod?.id ? t(YES) : t(NO)}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].costCollaboration
                  ? t(VALUE_WITH_CURRENCY, {
                      value: data[row].costCollaboration,
                      currency: LOCALE.currency,
                    })
                  : "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row]?.clappCredits ?? 0}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {getStaffName(data[row]?.staffID)}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {COUNTRIES_BUZZLY.find(
                  (c) => c.code === data[row]?.signUpCountry
                )?.label ?? "-"}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <Button onClick={() => navigate(`/shop?shopID=${row}`)}>
                  {t(BUTTON_VIEW_DETAILS)}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ShopsTable;
