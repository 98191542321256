import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatchApp } from "../lib/redux";
import { getShopID } from "../actions/getters";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  INVITATION_INFLUENCER_MODE_CREATE,
  INVITATION_INFLUENCER_MODE_INVITE,
} from "../utils/constants";
import {
  startGetInfluencer,
  startGetInfluencerStatistics,
  startGetSimilarProfiles,
} from "../actions/influencers";
import InfluencerSummary from "../components/Common/Influencer/InfluencerSummary";
import InfluencerNameLogo from "../components/Common/Influencer/InfluencerNameLogo";
import InfluencerPopularPosts from "../components/Common/Influencer/InfluencerPopularPosts/InfluencerPopularPosts";
// import InfluencerEngagement from "../components/Common/Influencer/InfluencerStatistics/InfluencerEngagement";
import InfluencerAudience from "../components/Common/Influencer/InfluencerStatistics/InfluencerAudience/InfluencerAudience";
import { startDiscoveryInfluencer } from "../actions/influencers";
import { getTimeFromDateFirestore } from "../utils/dates";
import LoadingButton from "../components/Common/Buttons/LoadingButton";
import { startUpdateStatisticsInfluencer } from "../actions/owner";
import differenceInDays from "date-fns/differenceInDays";
import { startGetInviteByUserID } from "../actions/invites";
import ModalInvitation from "../components/User/InfluencersListTable/ModalInvitation";
import { startGetAverageTicketShop } from "../actions/shops";
import ModalReferenceProfiles from "../components/Common/Modal/ModalReferenceProfiles";
import _ from "lodash";
import { useTranslationApp } from "../lib/i18next";
import {
  ATTENTION,
  BUTTON_USE_AS_REFERENCE_PROFILE,
  COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION,
  COLLECTING_INFORMATION_RETRY_AFTER,
  INFLUENCER_NOT_FOUND,
  LAST_UPDATE_DATE,
  LIST_CREATORS_WITH_AUDIENCE_SIMILAR,
  RETRIEVING_AUDIENCE_STATISTICS,
  RETRIEVING_BASIC_PROFILE_INFORMATION,
  RETRIEVING_PROFILE_STATISTICS,
  RETRIEVING_TOP_POSTS_INFORMATION,
  UPDATE,
} from "../locales/keysTranslations";
import { getUsersHasDelivery } from "../actions/deliveries";
import { startGetAmbassadorByUserID } from "../actions/ambassadors";
import ButtonInfluencerActions from "../components/Common/Influencer/ButtonInfluencerActions";
import Button from "../components/Common/Buttons/Button";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import LinearLoaderWithTime from "../components/Common/Loader/LinearLoaderWithTime";
import Skeleton from "@mui/material/Skeleton";

const InfluencerStatistics = () => {
  const [influencer, setInfluencer] = useState(null);
  const [similarInfluencers, setSimilarInfluencers] = useState({});
  const [isEnabledOrderChat, setIsEnabledOrderChat] = useState(false);
  const [isRetryMode, setIsRetryMode] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingSimilarInfluencers, setIsLoadingSimilarInfluencers] =
    useState(true);

  const [modalInvitationOpen, setModalInvitationOpen] = useState(false);
  const [modalReferenceProfileOpen, setModalReferenceProfilesOpen] =
    useState(false);
  const [averageTicket, setAverageTicket] = useState(null);

  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { t } = useTranslationApp();
  const shopID = dispatch(getShopID());

  const userID = params.get("userID");

  const from = params.get("from") ?? "influencers";
  const platform = params.get("platform");
  const accountName = params.get("accountName");

  const showUpdateStatisticsButton =
    differenceInDays(
      new Date(),
      new Date(getTimeFromDateFirestore(influencer?.analyticsUpdatedAt))
    ) >= 15;

  const getInitialData = async () => {
    if (!shopID) return;

    if (retryCount > 10) {
      SimpleAlert({
        text: t(COLLECTING_INFORMATION_RETRY_AFTER),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return navigate("/creators");
    }

    if (from === "discovery") {
      const response = await dispatch(
        startDiscoveryInfluencer({
          accountName,
          platform,
          retryRequest: false,
        })
      );
      if (!response.ok) {
        if (response.statusCode === 404) {
          SimpleAlert({
            title: t(INFLUENCER_NOT_FOUND),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return navigate("/creators");
        }
        if (response.statusCode === 400) {
          setIsRetryMode(true);
          return;
        }
      }
      const influencerStatistics = response.data;

      if (!influencerStatistics) {
        SimpleAlert({
          text: t(COLLECTING_INFORMATION_RETRY_AFTER),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return navigate("/creators");
      }

      setInfluencer({ userID, ...influencerStatistics });
      setIsLoading(false);
      setIsRetryMode(false);
    } else {
      if (!userID) {
        return navigate("/creators");
      }
      const influencerData = await dispatch(startGetInfluencer(userID));
      if (!influencerData) {
        SimpleAlert({
          title: t(INFLUENCER_NOT_FOUND),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return navigate("/creators");
      }
      const influencerStatistics = await dispatch(
        startGetInfluencerStatistics(userID)
      );
      if (!influencerData) {
        SimpleAlert({
          text: t(COLLECTING_INFORMATION_RETRY_AFTER),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return navigate("/creators");
      }
      onGetSimilarProfiles({
        mainAccountName: influencerData.mainAccountName,
        mainPlatform: influencerData.mainPlatform,
        userID,
      });
      dispatch(startGetAmbassadorByUserID(userID));
      dispatch(startGetInviteByUserID(userID));
      const ticket = await dispatch(startGetAverageTicketShop());
      setAverageTicket(ticket);
      const disabled = await dispatch(getUsersHasDelivery());
      setInfluencer({
        userID,
        disabledInvite: disabled.includes(userID),
        ...influencerData,
        ...influencerStatistics,
      });
      setIsLoading(false);
      setIsRetryMode(false);
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isRetryMode) {
      return;
    }

    const retry = async () => {
      console.log("hola");
      if (!isRetryMode) return;
      getInitialData();
      setRetryCount((prev) => prev + 1);
    };

    //retry every 30 seconds
    const timer = setInterval(() => {
      retry();
    }, 30000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [isRetryMode]);

  const onUpdateStatisticsInfluencer = async () => {
    setIsFetching(true);
    const response = await dispatch(
      startUpdateStatisticsInfluencer({ userID })
    );
    if (response) {
      setInfluencer({ ...influencer, ...response });
    }
    setIsFetching(false);
  };

  const onGetSimilarProfiles = async ({
    mainAccountName,
    mainPlatform,
    userID,
  }) => {
    setIsLoadingSimilarInfluencers(true);

    const similarInfluencers = await dispatch(
      startGetSimilarProfiles([
        {
          accountName: mainAccountName,
          platform: mainPlatform,
          userID: userID,
        },
      ])
    );
    if (!_.isEmpty(similarInfluencers))
      setSimilarInfluencers(similarInfluencers);
    setIsLoadingSimilarInfluencers(false);
  };

  if (isRetryMode) {
    return (
      <Box
        sx={{
          height: "calc(100%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
          px: { xs: 2, sm: 4 },
          pb: 2,
        }}
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            maxWidth: 600,
          }}
        >
          <Typography variant="h5" align="center">
            {t(ATTENTION)}
          </Typography>
          <Typography variant="body1">
            {t(COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION)}
          </Typography>
        </Box>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
    );
  }

  if (isLoading && !influencer)
    return (
      <Box
        sx={{
          height: "calc(100% - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: { xs: 2, sm: 4 },
        }}
      >
        <LinearLoaderWithTime
          labels={[
            t(RETRIEVING_BASIC_PROFILE_INFORMATION),
            t(RETRIEVING_PROFILE_STATISTICS),
            t(RETRIEVING_AUDIENCE_STATISTICS),
            t(RETRIEVING_TOP_POSTS_INFORMATION),
          ]}
          time={60 * 1000}
        />
      </Box>
    );

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <InfluencerNameLogo
            data={influencer}
            variantText="h4"
            variantTextMobile="h5"
            styleText="italic"
            showFavorite={true}
            redirectSocial={true}
            modeColorFavorite="white"
            sx={{ mb: 0 }}
            sxFavorite={{ mt: 1 }}
          />

          {from === "influencers" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "flex-start", sm: "flex-end" },
                width: { xs: "100%", sm: "auto" },
                mt: {
                  xs: 2,
                  sm: 0,
                },
                mb: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              {showUpdateStatisticsButton && (
                <Typography variant="body1" gutterBottom textAlign="right">
                  {t(LAST_UPDATE_DATE, {
                    date: new Date(
                      getTimeFromDateFirestore(influencer?.analyticsUpdatedAt)
                    ),
                  })}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Button
                  color="primary"
                  onClick={() => setModalReferenceProfilesOpen(true)}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "unset",
                    },
                  }}
                >
                  {t(BUTTON_USE_AS_REFERENCE_PROFILE)}
                </Button>
                {showUpdateStatisticsButton && (
                  <LoadingButton
                    loading={isFetching}
                    color="secondary"
                    onClick={onUpdateStatisticsInfluencer}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "unset",
                      },
                    }}
                  >
                    {t(UPDATE)}
                  </LoadingButton>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
        {from === "influencers" && (
          <ButtonInfluencerActions
            userID={userID}
            influencer={influencer}
            mode="buttons"
            forcedGrid={true}
            sx={{
              maxWidth: 720,
              mb: 2,
            }}
            tooltipPosition="bottom"
            grid={{
              xs: 12,
              sm: 6,
              lg: 3,
            }}
          />
        )}
        <InfluencerSummary data={influencer} from={from} />
        <InfluencerAudience
          data={influencer.audience}
          from={from}
          influencer={influencer}
          similarInfluencers={similarInfluencers}
          isLoadingSimilarInfluencers={isLoadingSimilarInfluencers}
          userID={userID}
          isEnabledOrderChat={isEnabledOrderChat}
          onChangeShowOrderChat={setIsEnabledOrderChat}
        />
        <InfluencerPopularPosts data={influencer.topContents} />
      </ContainerPage>
      {modalInvitationOpen && (
        <ModalInvitation
          dataInfluencer={influencer}
          influencerID={influencer?.userID}
          onCloseModal={() => setModalInvitationOpen(false)}
          averageTicket={averageTicket}
          from={INVITATION_INFLUENCER_MODE_INVITE}
          mode={INVITATION_INFLUENCER_MODE_CREATE}
        />
      )}
      {modalReferenceProfileOpen && (
        <ModalReferenceProfiles
          modalOpen={modalReferenceProfileOpen}
          onCloseModal={() => setModalReferenceProfilesOpen(false)}
          influencers={similarInfluencers}
          loading={isLoadingSimilarInfluencers}
          title={t(LIST_CREATORS_WITH_AUDIENCE_SIMILAR)}
        />
      )}
    </BlockPlanNoActive>
  );
};

export default InfluencerStatistics;
