import types from "../types";

const initialState = {
  requestsInfluencers: {},
  isLoading: true,
};

export const requestsInfluencersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REQUESTS_INFLUENCERS_FINISH:
      return {
        ...state,
        requestsInfluencers: action.payload,
        isLoading: false,
      };
    case types.ACCEPT_REQUEST_INFLUENCER:
      return {
        ...state,
        requestsInfluencers: {
          ...state.requestsInfluencers,
          [action.payload.id]: {
            ...state.requestsInfluencers[action.payload.id],
            verificationCode: action.payload.verificationCode,
          },
        },
      };
    case types.MARK_CODE_SENT_REQUEST:
      return {
        ...state,
        requestsInfluencers: {
          ...state.requestsInfluencers,
          [action.payload]: {
            ...state.requestsInfluencers[action.payload],
            isCodeSent: true,
          },
        },
      };
    case types.REJECT_REQUEST_INFLUENCER:
      delete state.requestsInfluencers[action.payload.id];
      return {
        ...state,
      };
    case types.LOADING_REQUESTS_INFLUENCERS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
