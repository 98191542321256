import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useTranslationApp } from "../lib/i18next";
import Loader from "../components/Common/Loader/Loader";
import {
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION,
  AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED,
  AUTOMATIC_INVITES_COMMENT_REQUIRED,
  AUTOMATIC_INVITES_FORMATS_REQUIRED,
  AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED,
  AUTOMATIC_INVITES_TITLE,
  AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED,
  BUTTON_CONTINUE,
  BUTTON_EDIT,
  CREATE_CAMPAIGN_QUESTION,
  FIELD_MAX_LENGTH,
  FIELD_NUMBER_NEGATIVE,
  FIELD_URL_VALID,
  LOADING_INFORMATION,
  QUESTION,
  READY,
} from "../locales/keysTranslations";
import AutomaticInvitesTypeFormForm from "../components/User/AutomaticInvitesTypeForm/AutomaticInvitesTypeFormForm";
import Select from "../components/Common/Selects/Select";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_INFO,
  ALERT_ICON_TYPE_SUCCESS,
  MIN_ALL_NUMBER_VALUE,
} from "../utils/constants";
import { useLocale } from "../Contexts/LocaleContext";
import { REGEX_URL } from "../utils/regexsValidation";
import { getIsBlockedActions } from "../actions/getters";
import { startSaveProfileInfluencerInfo } from "../actions/shops";
import { formatURL } from "../utils/formats";

const answerOrder = {
  1: "numberInvitations",
  2: "budgetMonthly",
  3: "budgets",
  4: "comment",
  5: "formats",
  6: "contentReference",
  7: "autoInvitationsIsActive",
};
const steps = Object.keys(answerOrder);

const AutomaticInvitesTypeForm = ({ onCallbackSave = () => {} }) => {
  const [visibleSteps] = useState(steps.map((step) => parseInt(step)));
  const [lastStep, setLastStep] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const LOCALE = useLocale();

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      step: 1,
      numberInvitations: null,
      budgetMonthly: null,
      budgets: [
        {
          value: "",
          audienceRange: [5000, 500000],
          fieldName: `budget1`,
        },
      ],
      comment: "",
      typeContent: {
        images: false,
        videos: true,
      },
      formats: {
        instagramBurstStories: false,
        instagramPost: false,
        instagramReel: false,
        instagramStory: false,
        tiktokPost: false,
        tiktokStory: false,
      },
      terms: "",
      contentReference: "",
      autoInvitationsIsActive: false,
    },
  });

  const step = watch("step");
  const autoInvitationsIsActive = watch("autoInvitationsIsActive");

  const currentAnswer = answerOrder[step];
  const dispatch = useDispatchApp();
  const minProductValueShop = useSelectorApp(
    (state) => state.shop.minProductValue
  );
  const { t } = useTranslationApp();

  const profileInfluencer = useSelectorApp(
    (state) => state?.shop?.profileInfluencer
  );

  const onNextStep = async (name, value, finish = false, customValue) => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const nextStep = visibleSteps[currentStepPosition + 1];

    switch (name) {
      case "numberInvitations":
        const valueFormatted = Number(value);
        if (!valueFormatted || valueFormatted === 0)
          return setError({
            text: t(AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED),
            step: answerOrder[step],
          });

        if (valueFormatted < 0)
          return setError({
            text: t(FIELD_NUMBER_NEGATIVE),
            step: answerOrder[step],
          });

        if (valueFormatted < 5 || valueFormatted > 100)
          return setError({
            text: t(
              AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE,
              {
                min: 5,
                max: 100,
              }
            ),
            step: answerOrder[step],
          });

        if (valueFormatted <= 9) {
          const result = await ConfirmAlert({
            title: t(AUTOMATIC_INVITES_TITLE),
            text: t(AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION),
            cancelButtonText: t(BUTTON_EDIT),
            confirmButtonText: t(BUTTON_CONTINUE),
            icon: ALERT_ICON_TYPE_INFO,
          });

          if (!result.isConfirmed) {
            return;
          }
        }

        setError(null);
        return setValue("step", nextStep);

      case "budgetMonthly":
        if (value?.length === 0 || !value)
          return setError({
            text: t(AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED),
            step: answerOrder[step],
          });

        if (value < MIN_ALL_NUMBER_VALUE)
          return setError({
            text: t(AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE, {
              value: MIN_ALL_NUMBER_VALUE,
              currency: LOCALE.currency,
            }),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "budgets":
        let hasError = false;

        value.forEach((budget) => {
          if (hasError) return;
          if (!budget.value) {
            hasError = true;
            setError({
              text: t(AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED),
              step: answerOrder[step],
            });
          }

          const valueFormatted = parseInt(budget.value);

          if (valueFormatted < MIN_ALL_NUMBER_VALUE) {
            hasError = true;
            setError({
              text: t(
                AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
                {
                  value: MIN_ALL_NUMBER_VALUE,
                  currency: LOCALE.currency,
                }
              ),
              step: answerOrder[step],
            });
          }
          if (valueFormatted < minProductValueShop) {
            hasError = true;
            setError({
              text: t(
                AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
                {
                  value: minProductValueShop,
                  currency: LOCALE.currency,
                }
              ),
              step: answerOrder[step],
            });
          }
        });

        if (hasError) return;

        setError(null);
        return setValue("step", nextStep);

      case "comment":
        if (value?.length === 0)
          return setError({
            text: t(AUTOMATIC_INVITES_COMMENT_REQUIRED),
            step: answerOrder[step],
          });

        if (value?.length > 3000)
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: 3000,
            }),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "formats":
        const hasFormat = Object.values(value).some((value) => value);

        if (!hasFormat)
          return setError({
            text: t(AUTOMATIC_INVITES_FORMATS_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "contentReference":
        if (value && !REGEX_URL.test(value))
          return setError({
            text: t(FIELD_URL_VALID),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "autoInvitationsIsActive":
        setValue("autoInvitationsIsActive", customValue);
        if (finish) await onSubmit();

        return setError(null);

      default:
        return setValue("step", nextStep);
    }
  };
  const onPreviousStep = () => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const previousStep = visibleSteps[currentStepPosition - 1];
    setValue("step", previousStep);
  };

  const onSubmit = async () => {
    if (autoInvitationsIsActive) {
      const isBlocked = dispatch(
        getIsBlockedActions({
          verifyBlockedLastInvite: true,
          verifyBlockedFreeTrial: true,
          verifyBlockedExchange: true,
          from: "automaticInvitations",
        })
      );

      if (isBlocked) {
        setValue("autoInvitationsIsActive", false);
        return;
      }
    }
    const formValues = getValues();

    Object.keys(formValues).forEach((key) => {
      const info = formValues[key];
      if (/^budget\d+$/.test(key) && !/^budgets/.test(key)) {
        delete formValues[key];
      }
      if (key === "budgets") {
        formValues[key] = info.map((budget) => {
          const { value, audienceRange } = budget;
          return {
            value: Number(value),
            audienceRange,
          };
        });
      }
    });

    const response = await dispatch(
      startSaveProfileInfluencerInfo(
        {
          ...formValues,
          contentReference: formValues.contentReference
            ? formatURL(formValues.contentReference)
            : "",
        },
        true
      )
    );
    if (response) {
      onCallbackSave();
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  useEffect(() => {
    if (step > lastStep) setLastStep(step);
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    Object.keys(profileInfluencer).forEach((key) => {
      if (!profileInfluencer[key]) return;
      if (!Object.values(answerOrder).includes(key)) return;
      if (key === "budgets") {
        const budgetsFormatted = profileInfluencer[key].map((budget, index) => {
          setValue(`budget${index + 1}`, budget);
          return {
            ...budget,
            fieldName: `budget${index + 1}`,
          };
        });
        return setValue(key, budgetsFormatted);
      }
      if (
        key === "autoInvitationsIsActive" &&
        typeof isFreeTrial === "boolean"
      ) {
        setValue(key, false);
        return;
      }
      setValue(key, profileInfluencer[key]);
    });
    setIsLoading(false);
    // eslint-disable-next-line
  }, [profileInfluencer]);

  if (isLoading)
    return (
      <Loader
        size={40}
        fullWidth={true}
        message={t(LOADING_INFORMATION)}
        hasMessage={true}
      />
    );

  return (
    <Box
      sx={{
        pt: 1,
        minHeight: "calc(100vh - 354px)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifySelf: "flex-start",
          gap: 2,
        }}
      >
        <Select
          name="step"
          variant="outlined"
          actionsChange={(value) =>
            setValue("step", visibleSteps[value - 1] || visibleSteps[0])
          }
          label={t(QUESTION)}
          fullWidth
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
              },
            },
          }}
          rules={{}}
          sx={{
            maxWidth: 200,
          }}
          options={visibleSteps.map((_, index) => ({
            value: index + 1,
            label: t(CREATE_CAMPAIGN_QUESTION, {
              question: `N°${index + 1}`,
            }),
            isHidden: index + 1 > lastStep,
          }))}
          control={control}
          errors={{}}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <AutomaticInvitesTypeFormForm
          currentAnswer={currentAnswer}
          step={step}
          error={error}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          errors={errors}
        />
      </Box>
    </Box>
  );
};

export default AutomaticInvitesTypeForm;
