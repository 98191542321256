import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ImageIcon from "@mui/icons-material/Image";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useTheme } from "@mui/material/styles";
import { IMAGES, VIDEOS } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const ChipsFilterGallery = ({ onChangeFilter, filterSelect = true }) => {
  const theme = useTheme();

  const { t } = useTranslationApp();

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Chip
        icon={<ImageIcon />}
        label={t(IMAGES)}
        onClick={() => {
          onChangeFilter("image");
        }}
        sx={{
          backgroundColor:
            filterSelect === "image"
              ? theme.palette.secondary.main
              : "white !important",
          color:
            filterSelect === "image"
              ? theme.palette.secondary.contrastText
              : "black",
          ":hover": {
            backgroundColor:
              filterSelect === "image"
                ? theme.palette.secondary.main
                : "white !important",
            filter: "brightness(0.7)",
          },
          ".MuiChip-icon": {
            color:
              filterSelect === "image"
                ? theme.palette.secondary.contrastText
                : "black",
          },
        }}
      />
      <Chip
        icon={<VideocamIcon />}
        label={t(VIDEOS)}
        onClick={() => {
          onChangeFilter("video");
        }}
        sx={{
          backgroundColor:
            filterSelect === "video"
              ? theme.palette.secondary.main
              : "white !important",
          color:
            filterSelect === "video"
              ? theme.palette.secondary.contrastText
              : "black",
          ":hover": {
            backgroundColor:
              filterSelect === "video"
                ? theme.palette.secondary.main
                : "white !important",
            filter: "brightness(0.7)",
          },
          ".MuiChip-icon": {
            color:
              filterSelect === "video"
                ? theme.palette.secondary.contrastText
                : "black",
          },
        }}
      />
    </Box>
  );
};

export default ChipsFilterGallery;
