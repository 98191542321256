import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "../../../../Common/Buttons/Button";
import { GREEN_COLOR, ORANGE_COLOR } from "../../../../../utils/colors";
import {
  APPLICANT_STATUS_ACCEPTED,
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_STATUS_PENDING,
  APPLICANT_STATUS_PENDING_ANALYTICS,
  APPLICANT_STATUS_PENDING_SOCIAL,
  APPLICANT_STATUS_REVISIONS,
  APPLICANT_STATUS_SUBMITTED,
} from "../../../../../utils/constants";
import { useTour } from "@reactour/tour";
import ModalApplicant from "./ModalApplicant";
import {
  BUTTON_ASSIGN_INVITE,
  BUTTON_REVIEW_CONTENT,
  BUTTON_VIEW_APPLICANT,
  BUTTON_VIEW_PRELIMINARY_CONTENT,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";

const states = {
  [APPLICANT_STATUS_PENDING]: {
    textButton: BUTTON_VIEW_APPLICANT,
  },
  [APPLICANT_STATUS_ACCEPTED]: {
    textButton: BUTTON_ASSIGN_INVITE,
    color: ORANGE_COLOR,
  },
  [APPLICANT_STATUS_SUBMITTED]: {
    textButton: BUTTON_REVIEW_CONTENT,
    color: ORANGE_COLOR,
  },
  [APPLICANT_STATUS_REVISIONS]: {
    textButton: BUTTON_VIEW_PRELIMINARY_CONTENT,
    color: ORANGE_COLOR,
  },
  [APPLICANT_STATUS_PENDING_SOCIAL]: {
    textButton: BUTTON_VIEW_APPLICANT,
    color: GREEN_COLOR,
  },
  [APPLICANT_STATUS_PENDING_ANALYTICS]: {
    textButton: BUTTON_VIEW_APPLICANT,
    color: GREEN_COLOR,
  },
  [APPLICANT_STATUS_DELIVERED]: {
    textButton: BUTTON_VIEW_APPLICANT,
    color: GREEN_COLOR,
  },
};

const ActionsApplicantButtons = ({
  data = {},
  applicantID,
  onAcceptApplicant,
  onRejectedApplicant,
  onSelectApplicant,
}) => {
  const { t } = useTranslationApp();

  const [modalOpen, setModalOpen] = useState(false);

  const onChangeModalOpenStatus = () => {
    setModalOpen(!modalOpen);
  };

  const { isOpen, currentStep, steps } = useTour();

  const step = steps[currentStep];

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", gap: 1, width: "100%" }}
    >
      <Button
        variant="contained"
        type="button"
        color="secondary"
        fullWidth={true}
        sx={{
          display: data.status === APPLICANT_STATUS_ACCEPTED ? "none" : "block",
        }}
        onClick={() => {
          if (data.status === APPLICANT_STATUS_PENDING) {
            onChangeModalOpenStatus();
            return;
          }
          onSelectApplicant(applicantID, "publication");
        }}
        disabled={isOpen && step?.actionData === "disabledButton"}
      >
        {t(states[data.status]?.textButton)}
      </Button>

      {modalOpen && (
        <ModalApplicant
          modalOpen={modalOpen}
          onCloseModal={onChangeModalOpenStatus}
          onAcceptApplicant={onAcceptApplicant}
          onRejectedApplicant={onRejectedApplicant}
          data={data}
          applicantID={applicantID}
          campaignID={data.campaignID}
        />
      )}
    </Box>
  );
};

export default ActionsApplicantButtons;
