import React, { useEffect, useState } from "react";
import TitlePage from "../../components/Common/Texts/TitlePage";
import { useTranslationApp } from "../../lib/i18next";
import {
  BUTTON_ACCEPT,
  BUTTON_REJECT,
  CONTENT_APPLICATION_ACCEPT_DESCRIPTION,
  CONTENT_APPLICATION_ACCEPT_TITLE,
  CONTENT_APPLICATION_REJECT_DESCRIPTION,
  CONTENT_APPLICATION_REJECT_TITLE,
  CONTENT_APPLICATION_TITLE,
  READY,
} from "../../locales/keysTranslations";
import { useDispatchApp } from "../../lib/redux";
import {
  ALERT_ICON_TYPE_SUCCESS,
  DEFAULT_TABLE_PAGES,
} from "../../utils/constants";
import {
  startAcceptContentApplication,
  startGetContentApplication,
  startRejectContentApplication,
} from "../../actions/owner";
import Loader from "../../components/Common/Loader/Loader";
import ContentApplicationTable from "../../components/Owner/ContentApplication/ContentApplicationTable";
import { usePagination } from "../../hooks/usePagination";
import { ConfirmAlert, SimpleAlert } from "../../utils/alerts";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const ContentApplication = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onGetInitialData = async () => {
    const response = await dispatch(
      startGetContentApplication({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey,
      })
    );
    if (response) {
      setData(response);
    }
    setIsLoading(false);
  };
  const onGetNextPageContentApplication = async () => {
    const response = await dispatch(
      startGetContentApplication({
        lastKey,
        onChangeLastKey,
        limit: rowsPerPage,
      })
    );
    if (response) {
      setData({
        ...data,
        ...response,
      });
    }
  };

  const { rowsPerPage, page, lastKey, onChangeLastKey, onPageChange } =
    usePagination({
      rowsPerPageValue: DEFAULT_TABLE_PAGES,
      onCallBackNextPage: onGetNextPageContentApplication,
    });

  useEffect(() => {
    onGetInitialData();
    // eslint-disable-next-line
  }, []);

  const onAcceptContentApplication = async (id) => {
    const result = await ConfirmAlert({
      title: t(CONTENT_APPLICATION_ACCEPT_TITLE),
      text: t(CONTENT_APPLICATION_ACCEPT_DESCRIPTION),
      confirmButtonText: t(BUTTON_ACCEPT),
    });

    if (!result.isConfirmed) return;

    const response = await dispatch(
      startAcceptContentApplication({
        data: data[id],
        applicationID: id,
      })
    );
    if (!response) return false;

    setData((prevData) => {
      return {
        ...prevData,
        [id]: [...prevData[id], true],
      };
    });
    SimpleAlert({
      title: t(READY),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
  };

  const onRejectContentApplication = async (id) => {
    const result = await ConfirmAlert({
      title: t(CONTENT_APPLICATION_REJECT_TITLE),
      text: t(CONTENT_APPLICATION_REJECT_DESCRIPTION),
      confirmButtonText: t(BUTTON_REJECT),
    });

    if (!result.isConfirmed) return;

    const response = await dispatch(startRejectContentApplication(id));
    if (!response) return false;

    const { [id]: _, ...newData } = data;

    setData(newData);
    SimpleAlert({
      title: t(READY),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
  };

  return (
    <ContainerPage>
      <TitlePage
        sx={{
          mb: 2,
        }}
      >
        {t(CONTENT_APPLICATION_TITLE)}
      </TitlePage>
      {isLoading && <Loader size={70} fullWidth={true} />}
      {!isLoading && (
        <ContentApplicationTable
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onAcceptContentApplication={onAcceptContentApplication}
          onRejectContentApplication={onRejectContentApplication}
        />
      )}
    </ContainerPage>
  );
};

export default ContentApplication;
