import React from "react";
import Box from "@mui/material/Box";
import InfluencerCategory from "./InfluencerCategory";
import { influencersCategories } from "../../../utils/categories";

const InfluencerCategories = ({
  influencer,
  size = "medium",
  mode = "dark",
  onSelectCategory = () => {},
  categoriesSelected = [],
  sx = {},
  sxGrid = {},
  sxCategory = {},
  maxCategoriesRender = 4,
  rows = 2,
}) => {
  const { categories = [] } = influencer || {};

  const originalCategories = [...categories];

  const originalCategoriesTruncated =
    originalCategories && originalCategories.length > maxCategoriesRender
      ? originalCategories.splice(0, maxCategoriesRender)
      : originalCategories;

  const customTagsSelected = categoriesSelected.filter(
    (category) =>
      !influencersCategories.includes(category) && categories.includes(category)
  );

  const categoriesToRender = [
    ...originalCategoriesTruncated,
    ...customTagsSelected,
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: categoriesToRender.length > 1 ? "min-content" : "auto",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${rows}, 1fr)`,
          gridGap: 8,
          justifyContent: "center",
          alignItems: "center",
          ...sxGrid,
        }}
      >
        {categoriesToRender &&
          categoriesToRender?.map((category, index) => {
            const isOriginalCategory = influencersCategories.includes(category);
            const isCustomTagSelected =
              !isOriginalCategory && categoriesSelected.includes(category);

            const isOddAndIsLast =
              categoriesToRender.length % 2 !== 0 &&
              index === categoriesToRender.length - 1;

            return (
              <Box
                key={index}
                sx={{
                  gridColumn: isOddAndIsLast
                    ? `span ${maxCategoriesRender - rows}`
                    : "auto",
                }}
              >
                <InfluencerCategory
                  size={size}
                  mode={mode}
                  category={categoriesToRender[index]}
                  isCustomTagSelected={isCustomTagSelected}
                  onSelectCategory={onSelectCategory}
                  sx={sxCategory}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
export default InfluencerCategories;
