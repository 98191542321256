import types from "../types";
import { get, onValue, ref, update } from "firebase/database";
import { db, firestore } from "../firebase";
import {
  disableMenuWithAction,
  enableMenuWithAction,
  setActiveSoundDelivery,
  setNewOrderInStore,
  setOpenNotification,
} from "./ui";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import {
  ALERT_ICON_TYPE_ERROR,
  GENERAL_REDEMPTION,
  INFLUENCER_ROLE,
  POST_STATUS_PENDING,
  ROLES_DASHBOARD,
} from "../utils/constants";
import { createOrder } from "./orders";
import { doc, updateDoc } from "firebase/firestore";
import { startGetCategoryShop, startGetBusinessNameByShopIDs } from "./shops";
import differenceInHours from "date-fns/differenceInHours";
import { startGetInfluencersByUserIDs } from "./influencers";
import {
  getCategoryShop,
  getRole,
  getShopID,
  getShopIDs,
  getStoreID,
} from "./getters";
import { getObjectError } from "../utils/formats";
import { SimpleAlert } from "../utils/alerts";
import i18next from "i18next";
import {
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  NEW_ORDER,
} from "../locales/keysTranslations";
import { getCreationTimeFromID } from "../utils/dates";
import differenceInDays from "date-fns/differenceInDays";

export const setAllDeliveries = (data) => ({
  type: types.GET_ALL_DELIVERIES_FINISH,
  payload: data,
});

const setAllShopsDeliveries = (data) => ({
  type: types.GET_ALL_SHOPS_DELIVERIES_FINISH,
  payload: data,
});

const setAllStoresDeliveries = (data) => ({
  type: types.GET_ALL_STORES_DELIVERIES_FINISH,
  payload: data,
});

export const hasNewDeliveries =
  ({ data, shopID, storeID }) =>
  async (dispatch) => {
    const toBeAcceptedKeys = Object.keys(data ?? {});
    let hasNewOrder = false;

    for (const deliveryKey of toBeAcceptedKeys) {
      if (data[deliveryKey].isNew) {
        window.focus();
        hasNewOrder = true;
        dispatch(
          setOpenNotification({
            open: true,
            message: NEW_ORDER,
            action: shopID
              ? `/delivery?shopID=${shopID}&storeID=${storeID}`
              : `/delivery?storeID=${storeID}`,
          })
        );
        dispatch(
          setNewOrderInStore({
            store: storeID,
            status: true,
          })
        );
        dispatch(enableMenuWithAction("deliveries"));
        return dispatch(setActiveSoundDelivery(true));
      }
    }
    return hasNewOrder;
  };

const deliveredDeliveries =
  ({ data }) =>
  async (dispatch) => {
    let category = dispatch(getCategoryShop());
    if (category.length === 0) {
      category = await dispatch(startGetCategoryShop());
    }
    const toBeDeliveredKeys = Object.keys(data ?? {});
    const nowDate = new Date();

    for (const deliveryKey of toBeDeliveredKeys) {
      const delivery = data[deliveryKey];
      if (delivery.from === GENERAL_REDEMPTION) continue;

      if (!delivery.dateOrderToBeDelivered) continue;

      if (
        category === "restaurants" &&
        differenceInHours(nowDate, new Date(delivery.dateOrderToBeDelivered)) >=
          1
      ) {
        await dispatch(startDeliveredDelivery(delivery));
      }
      if (
        differenceInBusinessDays(
          nowDate,
          new Date(delivery.dateOrderToBeDelivered)
        ) >= 5
      ) {
        await dispatch(startDeliveredDelivery(delivery));
      }
    }
    return true;
  };

const cleanUpCancelledDeliveries =
  ({ data = {} }) =>
  async () => {
    try {
      const deliveriesCancelled = Object.keys(data);

      if (deliveriesCancelled.length === 0) return true;

      const nowDate = new Date();

      const updates = {};

      for (const deliveryID of deliveriesCancelled) {
        const delivery = data[deliveryID];

        const creationTime = getCreationTimeFromID(deliveryID);

        if (differenceInDays(nowDate, new Date(creationTime)) >= 15) {
          updates[
            `deliveries/${delivery.shopID}/${delivery.storeID}/cancelled/${deliveryID}`
          ] = null;
        }
      }

      if (Object.keys(updates).length === 0) return true;

      await update(ref(db), updates);

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const getAllDeliveriesOwner = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `deliveries`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) {
      dispatch(setAllShopsDeliveries({}));
    }
    const data = snapshot.val();

    const userIDs = [];
    const shopIDs = [];

    const deliveriesToBeDelivered = {};
    Object.keys(data).forEach((shopID) => {
      const stores = data[shopID];
      Object.keys(stores).forEach((storeID) => {
        const store = stores[storeID];
        Object.keys(store).forEach((typeDelivery) => {
          if (typeDelivery !== "cancelled") {
            const deliveries = store[typeDelivery];
            Object.keys(deliveries).forEach((deliveryID) => {
              const delivery = deliveries[deliveryID];
              deliveriesToBeDelivered[deliveryID] = {
                ...delivery,
                status: typeDelivery,
              };
              userIDs.push(delivery.userID);
              shopIDs.push(delivery.shopID);
            });
          }
        });
      });
    });
    await dispatch(
      startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
    );
    await dispatch(startGetBusinessNameByShopIDs({ shopIDs }));
    dispatch(setAllShopsDeliveries(deliveriesToBeDelivered));
    return true;
  } catch (error) {
    dispatch(loadingDeliveriesFinish());
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const getUsersHasDelivery = () => async (_, getState) => {
  try {
    const allDeliveries = getState().deliveries.allStoresDeliveries;
    const userIDs = [];
    const storesKeys = Object.keys(allDeliveries);
    for (let storeKey of storesKeys) {
      const deliveriesTypes = Object.keys(allDeliveries[storeKey]);
      for (let deliveryType of deliveriesTypes) {
        if (deliveryType !== "cancelled") {
          const deliveriesKeys = Object.keys(
            allDeliveries[storeKey][deliveryType]
          );
          for (let deliveryKey of deliveriesKeys) {
            const delivery = allDeliveries[storeKey][deliveryType][deliveryKey];
            userIDs.push(delivery.userID);
          }
        }
      }
    }
    return userIDs;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const getAllDeliveriesOfAllShops = () => async (dispatch, getState) => {
  try {
    const shopIDs = dispatch(getShopIDs());
    for (let shopID of shopIDs) {
      const dbRef = ref(db, `deliveries/${shopID}`);
      onValue(dbRef, async (snapshot) => {
        const data = snapshot.val() ?? {};
        dispatch(setAllShopsDeliveries(data));
        let hasNewOrder = false;
        const storeIDs = Object.keys(data ?? {});
        for (const storeID of storeIDs) {
          if (!hasNewOrder) {
            hasNewOrder = await dispatch(
              hasNewDeliveries({
                data: data[storeID].toBeAccepted,
                shopID,
                storeID,
              })
            );
          }
          await dispatch(
            deliveredDeliveries({ data: data[storeID].toBeDelivered })
          );
          await dispatch(
            cleanUpCancelledDeliveries({ data: data[storeID].cancelled })
          );
        }
        if (!hasNewOrder) {
          dispatch(setActiveSoundDelivery(false));
          dispatch(
            setOpenNotification({
              open: false,
              message: "",
              action: "",
            })
          );
          dispatch(disableMenuWithAction("deliveries"));
        }
      });
    }
  } catch (error) {
    dispatch(loadingDeliveriesFinish());
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const getAllDeliveriesOfAllStores = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `deliveries/${shopID}`);
    onValue(dbRef, async (snapshot) => {
      const data = snapshot.val();
      dispatch(setAllStoresDeliveries(data ?? {}));
      let hasNewOrder = false;
      const storeIDs = Object.keys(data ?? {});
      for (const storeID of storeIDs) {
        if (!hasNewOrder) {
          hasNewOrder = await dispatch(
            hasNewDeliveries({
              data: data[storeID].toBeAccepted,
              storeID,
            })
          );
        }
        await dispatch(
          deliveredDeliveries({ data: data[storeID].toBeDelivered })
        );
        await dispatch(
          cleanUpCancelledDeliveries({ data: data[storeID].cancelled })
        );
      }
      if (!hasNewOrder) {
        dispatch(setActiveSoundDelivery(false));
        dispatch(
          setOpenNotification({
            open: false,
            message: "",
            action: "",
          })
        );
        dispatch(disableMenuWithAction("deliveries"));
      }
    });
  } catch (error) {
    dispatch(loadingDeliveriesFinish());
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const getAllDeliveriesByStore = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const storeID = dispatch(getStoreID());
    const dbRef = ref(db, `deliveries/${shopID}/${storeID}`);
    onValue(dbRef, async (snapshot) => {
      const data = snapshot.val() ?? {};
      dispatch(setAllDeliveries(data));
      let hasNewOrder = false;
      hasNewOrder = await dispatch(
        hasNewDeliveries({
          data: data.toBeAccepted ?? {},
          storeID,
        })
      );
      await dispatch(deliveredDeliveries({ data: data.toBeDelivered ?? {} }));
      await dispatch(
        cleanUpCancelledDeliveries({ data: data.cancelled ?? {} })
      );
      if (!hasNewOrder) {
        dispatch(setActiveSoundDelivery(false));
        dispatch(
          setOpenNotification({
            open: false,
            message: "",
            action: "",
          })
        );
        dispatch(disableMenuWithAction("deliveries"));
      }
    });
  } catch (error) {
    dispatch(loadingDeliveriesFinish());
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startSetIsNewDelivery =
  (storeID, deliveryID) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      if (!isOpenOnboarding) {
        const shopID = dispatch(getShopID());
        const dbRef = ref(
          db,
          `deliveries/${shopID}/${storeID}/toBeAccepted/${deliveryID}`
        );
        await update(dbRef, {
          isNew: false,
        });
      }
      dispatch(
        setIsNewDelivery({
          key: deliveryID,
          data: false,
        })
      );
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const setIsNewDelivery = (data) => ({
  type: types.SET_HAS_OPEN_DELIVERY,
  payload: data,
});

export const startRejectDelivery =
  (typeProcess, delivery) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const shopID = dispatch(getShopID());
        const userRole = delivery.userRole || INFLUENCER_ROLE;
        const dbRef = doc(
          firestore,
          userRole === INFLUENCER_ROLE ? "influencers" : "shoppers",
          delivery.userID
        );
        await updateDoc(dbRef, {
          [`deliveries.${delivery.deliveryID}.status`]: "rejected",
        });
        const updates = {};
        updates[
          `deliveries/${shopID}/${delivery.storeID}/${typeProcess}/${delivery.deliveryID}`
        ] = null;
        updates[
          `deliveries/${shopID}/${delivery.storeID}/cancelled/${delivery.deliveryID}`
        ] = delivery;
        await update(ref(db), updates);
      }
      dispatch(
        rejectDelivery({
          typeProcess,
          key: delivery.deliveryID,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const rejectDelivery = (data) => ({
  type: types.REJECT_DELIVERY,
  payload: data,
});

export const startAcceptDelivery =
  (delivery, timePreparation) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (isOpenOnboarding) {
        const data = getState().deliveries.toBeAccepted[delivery.deliveryID];
        const dateOrderAccepted = new Date().toISOString();
        const newData = {
          data: {
            [delivery.deliveryID]: {
              ...data,
              timePreparation,
              dateOrderAccepted,
            },
          },
          deliveryID: delivery.deliveryID,
          typeProcessOld: "toBeAccepted",
          typeProcessTarget: "inPreparation",
        };
        dispatch(acceptDelivery(newData));
        return true;
      }

      const shopID = dispatch(getShopID());
      const dateOrderAccepted = new Date().toISOString();
      const dbRef = ref(
        db,
        `deliveries/${shopID}/${delivery.storeID}/toBeAccepted/${delivery.deliveryID}`
      );
      const snapshot = await get(dbRef);

      if (!snapshot.exists()) {
        return true;
      }

      const data = snapshot.val();
      const updates = {};
      updates[
        `deliveries/${shopID}/${delivery.storeID}/toBeAccepted/${delivery.deliveryID}`
      ] = null;
      updates[
        `deliveries/${shopID}/${delivery.storeID}/inPreparation/${delivery.deliveryID}`
      ] = {
        ...data,
        timePreparation,
        dateOrderAccepted,
      };

      const userRole = delivery.userRole || INFLUENCER_ROLE;

      const firestoreRef = doc(
        firestore,
        userRole === INFLUENCER_ROLE ? "influencers" : "shoppers",
        delivery.userID
      );
      await updateDoc(firestoreRef, {
        [`deliveries.${delivery.deliveryID}.status`]: "inPreparation",
        [`deliveries.${delivery.deliveryID}.timePreparation`]: timePreparation,
        [`deliveries.${delivery.deliveryID}.dateOrderAccepted`]:
          dateOrderAccepted,
      });

      await update(ref(db), updates);
      const newData = {
        data: {
          [delivery.deliveryID]: {
            ...data,
            timePreparation,
            dateOrderAccepted,
          },
        },
        deliveryID: delivery.deliveryID,
        typeProcessOld: "toBeAccepted",
        typeProcessTarget: "inPreparation",
      };
      dispatch(acceptDelivery(newData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const acceptDelivery = (data) => ({
  type: types.ACCEPT_DELIVERY,
  payload: data,
});

export const startReadyDelivery =
  (delivery, deliverySelected) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const shopID = dispatch(getShopID());
        const updates = {};
        const dateOrderToBeDelivered = new Date().toISOString();
        updates[
          `deliveries/${shopID}/${delivery.storeID}/inPreparation/${delivery.deliveryID}`
        ] = null;
        updates[
          `deliveries/${shopID}/${delivery.storeID}/toBeDelivered/${delivery.deliveryID}`
        ] = {
          ...delivery,
          deliveryInfo: deliverySelected,
          dateOrderToBeDelivered,
        };

        const userRole = delivery.userRole || INFLUENCER_ROLE;

        const dbRef = doc(
          firestore,
          userRole === INFLUENCER_ROLE ? "influencers" : "shoppers",
          delivery.userID
        );
        await updateDoc(dbRef, {
          [`deliveries.${delivery.deliveryID}.status`]: "ready",
          [`deliveries.${delivery.deliveryID}.deliveryInfo`]: deliverySelected,
          [`deliveries.${delivery.deliveryID}.dateOrderToBeDelivered`]:
            dateOrderToBeDelivered,
        });
        await update(ref(db), updates);
      }
      const newData = {
        data: {
          [delivery.deliveryID]: {
            ...delivery,
            deliveryInfo: deliverySelected,
          },
        },
        deliveryID: delivery.deliveryID,
        typeProcessOld: "inPreparation",
        typeProcessTarget: "toBeDelivered",
      };
      dispatch(readyDelivery(newData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const readyDelivery = (data) => ({
  type: types.READY_DELIVERY,
  payload: data,
});

export const startDeliveredDelivery =
  (delivery) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      const role = dispatch(getRole());

      if (!isOpenOnboarding) {
        let deliveryData = delivery;
        if (ROLES_DASHBOARD.includes(role)) {
          const snapshotDelivery = await get(
            ref(
              db,
              `deliveries/${delivery.shopID}/${delivery.storeID}/toBeDelivered/${delivery.deliveryID}`
            )
          );
          if (!snapshotDelivery.exists()) {
            return true;
          }

          deliveryData = snapshotDelivery.val();
        }
        const shopID = deliveryData.shopID;
        const storeID = deliveryData.storeID;
        const userID = deliveryData.userID;
        const inviteID = deliveryData.inviteID;
        const deliveryID = deliveryData.deliveryID;
        const orderID = deliveryData.orderID;
        const from = deliveryData.from;

        const updates = {};

        updates[`deliveries/${shopID}/${storeID}/toBeDelivered/${deliveryID}`] =
          null;

        if (from !== GENERAL_REDEMPTION) {
          const snapshotInvite = await get(
            ref(db, `invitesInactive/${inviteID}/campaignID`)
          );
          if (!snapshotInvite.exists()) {
            const snapshotOrder = await get(ref(db, `orders/${orderID}`));
            if (!snapshotOrder.exists()) {
              const orderData = {
                date: new Date().toISOString(),
                postStatus: POST_STATUS_PENDING,
                shopID,
                userID: userID,
                value: deliveryData.totalPrice,
                inviteID: inviteID,
                isDelivery: true,
                storeID: storeID,
                message: deliveryData.message,
              };
              updates[`orders/${orderID}`] = orderData;
              dispatch(
                createOrder({
                  key: orderID,
                  data: orderData,
                })
              );
            }
          }
        }
        const userRole = delivery.userRole || INFLUENCER_ROLE;
        const dbRef = doc(
          firestore,
          userRole === INFLUENCER_ROLE ? "influencers" : "shoppers",
          userID
        );
        await updateDoc(dbRef, {
          [`deliveries.${deliveryID}.status`]: "delivered",
        });
        await update(ref(db), updates);
      }

      dispatch(deliveredDelivery(delivery.deliveryID));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const deliveredDelivery = (data) => ({
  type: types.DELIVERED_DELIVERY,
  payload: data,
});

export const deleteDeliveryOwner = (data) => ({
  type: types.DELETE_DELIVERY_OWNER,
  payload: data,
});

const loadingDeliveriesFinish = () => ({
  type: types.LOADING_DELIVERIES_FINISH,
});
