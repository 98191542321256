import types from "../types";
import {
  ref,
  onValue,
  update,
  query,
  orderByChild,
  limitToFirst,
  startAfter,
  get,
  equalTo,
} from "firebase/database";
import { db } from "../firebase";
import { disableMenuWithAction, enableMenuWithAction } from "./ui";
import { getShopID } from "./getters";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";
import { getObjectError } from "../utils/formats";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";

export const startGetAllNotifications =
  (onReferenceAvailable) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = query(
        ref(db, `notifications/${shopID}`),
        orderByChild("creationTimeNegative"),
        limitToFirst(15)
      );
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val() ?? {};
        let hasActionPublication = false;
        dispatch(getAllNotifications(data));
        Object.keys(data).forEach((notificationID) => {
          if (
            data[notificationID].type === "verifyOrder" &&
            !data[notificationID].isRead
          ) {
            hasActionPublication = true;
          }
        });
        hasActionPublication
          ? dispatch(enableMenuWithAction("publications"))
          : dispatch(disableMenuWithAction("publications"));
      });
      onReferenceAvailable(dbRef);
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAllNotificationsPagination =
  ({ limit, onChangeLastKey, lastKey, loadLastItem }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());

      if (!lastKey) {
        const notifications = getState().notifications.notifications;

        const notificationsKeys = Object.keys(notifications ?? {});
        onChangeLastKey(
          notifications[notificationsKeys[0]].creationTimeNegative
        );
        lastKey = notifications[notificationsKeys[0]].creationTimeNegative;
      }

      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `notifications/${shopID}`),
              orderByChild("creationTimeNegative"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `notifications/${shopID}`),
              orderByChild("creationTimeNegative"),
              limitToFirst(limit)
            );
      }
      const snapshot = await get(q);

      if (!snapshot.exists()) return loadLastItem();

      const notifications = snapshot.val();
      const notificationsKeys = Object.keys(notifications ?? {});

      onChangeLastKey(notifications[notificationsKeys[0]].creationTimeNegative);

      let hasActionPublication = false;

      notificationsKeys.forEach((notificationID) => {
        if (
          notifications[notificationID].type === "verifyOrder" &&
          !notifications[notificationID].isRead
        ) {
          hasActionPublication = true;
        }
      });

      hasActionPublication
        ? dispatch(enableMenuWithAction("publications"))
        : dispatch(disableMenuWithAction("publications"));

      dispatch(getAllNotificationsPagination(notifications));

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const getAllNotifications = (data) => ({
  type: types.GET_ALL_NOTIFICATIONS_FINISH,
  payload: data,
});
const getAllNotificationsPagination = (data) => ({
  type: types.GET_ALL_NOTIFICATIONS_PAGINATION_FINISH,
  payload: data,
});

export const startUpdateIsReadNotification =
  (notificationID) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `notifications/${shopID}/${notificationID}`);
      await update(dbRef, {
        isRead: true,
      });
      dispatch(
        updateIsReadNotification({
          key: notificationID,
          data: true,
        })
      );
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const updateIsReadNotification = (data) => ({
  type: types.UPDATE_IS_READ_NOTIFICATION,
  payload: data,
});

export const startMarkAllNotificationsRead =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = query(
        ref(db, `notifications/${shopID}`),
        orderByChild("isRead"),
        equalTo(false)
      );
      const snapshot = await get(q);
      if (!snapshot.exists()) return;
      const notifications = snapshot.val();
      const notificationsKeys = Object.keys(notifications ?? {});
      const updates = {};
      notificationsKeys.forEach((notificationID) => {
        updates[`notifications/${shopID}/${notificationID}/isRead`] = true;
      });
      await update(ref(db), updates);
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
