import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "../Containers/Accordion";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ContainerKeyValue from "../Containers/ContainerKeyValue";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BRAND,
  CODE,
  COLUMN_NAME_ID,
  CREATORS_OWNER_REPORT_DATE_REDEMPTION,
  CREATORS_OWNER_REPORT_EMPTY_INVITATIONS,
  DATE_LONG,
  EXPIRED_SINGULAR,
  IS_AUTOMATIC,
  NO,
  REDEEMED_SINGULAR,
  REPORTS_INVITATIONS_ACTIVE,
  REPORTS_INVITATIONS_INACTIVE,
  STATUS,
  VALUE,
  VALUE_WITH_CURRENCY,
  WAS_GENERATED,
  YES,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";

const Invitation = ({
  data,
  id,
  type,
  onDeleteInvite,
  onExtendInvite,
  onCreateOrderFromInvite,
  influencer,
}) => {
  const { shops } = useSelectorApp((state) => state.shop);
  const { t } = useTranslationApp();

  const LOCALES = useLocales();
  const LOCALE = LOCALES[influencer?.signUpCountry || "co"];

  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {type === "active" && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <IconButton
              sx={{ p: 0, pb: "4px" }}
              disabled={data?.isExtended ?? false}
              onClick={() => onExtendInvite(id)}
            >
              <MoreTimeIcon />
            </IconButton>
            <IconButton
              sx={{ p: 0, pb: "4px" }}
              onClick={() => onCreateOrderFromInvite(id, type)}
            >
              <ArchiveIcon />
            </IconButton>
            <IconButton
              sx={{ p: 0, pb: "4px" }}
              onClick={() => onDeleteInvite(id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        {type === "inactive" && !data?.redemptionDate && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <IconButton
              sx={{ p: 0, pb: "4px" }}
              onClick={() => onCreateOrderFromInvite(id, type)}
            >
              <ArchiveIcon />
            </IconButton>
          </Box>
        )}
        <ContainerKeyValue keyValue={`${t(COLUMN_NAME_ID)}: `} value={id} />
        <ContainerKeyValue
          keyValue={`${t(BRAND)}: `}
          value={shops[data.shopID]?.businessName ?? "-"}
          redirectURL="shop?shopID"
          redirectValue={data.shopID}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            value: data.value,
            currency: LOCALE.currency,
          })}`}
        />
        <ContainerKeyValue keyValue={`${t(CODE)}: `} value={data.inviteCode} />
        <ContainerKeyValue
          keyValue={`${t(WAS_GENERATED)}: `}
          value={t(DATE_LONG, {
            date: new Date(data.creationTime),
          })}
        />
        <ContainerKeyValue
          keyValue={`${t(IS_AUTOMATIC)}: `}
          value={data?.isAutomatic ? t(YES) : t(NO)}
        />

        {type === "inactive" && (
          <ContainerKeyValue
            keyValue={`${t(STATUS)}: `}
            value={
              data?.redemptionDate ? t(REDEEMED_SINGULAR) : t(EXPIRED_SINGULAR)
            }
          />
        )}
        {type === "inactive" && data?.redemptionDate && (
          <ContainerKeyValue
            keyValue={t(CREATORS_OWNER_REPORT_DATE_REDEMPTION)}
            value={t(DATE_LONG, {
              date: new Date(data.redemptionDate),
            })}
          />
        )}
      </Box>
    </Box>
  );
};

const InfluencerInvitations = ({
  data,
  type = "active",
  onDeleteInvite,
  onExtendInvite,
  onCreateOrderFromInvite,
  influencer,
}) => {
  const { t } = useTranslationApp();
  const keysSorted = Object.keys(data).sort((a, b) => {
    if (data[a].orderFrom === undefined || data[b].orderFrom === undefined)
      return 0;
    return (
      new Date(data[b]?.orderFrom)?.getTime() -
      new Date(data[a]?.orderFrom)?.getTime()
    );
  });
  return (
    <Box sx={{ mx: 0, width: "100%" }}>
      <Accordion
        title={t(
          type === "active"
            ? REPORTS_INVITATIONS_ACTIVE
            : REPORTS_INVITATIONS_INACTIVE
        )}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: 230,
            overflowY: "auto",
            pl: 0,
            pr: 1,
          }}
        >
          {keysSorted.length === 0 ? (
            <Typography>
              {t(CREATORS_OWNER_REPORT_EMPTY_INVITATIONS)}
            </Typography>
          ) : (
            keysSorted.map((invitationKey) => {
              return (
                <Invitation
                  key={invitationKey}
                  id={invitationKey}
                  data={data[invitationKey]}
                  onDeleteInvite={onDeleteInvite}
                  onExtendInvite={onExtendInvite}
                  onCreateOrderFromInvite={onCreateOrderFromInvite}
                  type={type}
                  influencer={influencer}
                />
              );
            })
          )}
        </Box>
      </Accordion>
    </Box>
  );
};

export default InfluencerInvitations;
