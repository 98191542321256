import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { startGetBusinessName, startGetMonthlyReport } from "../actions/shops";
import { useDispatchApp } from "../lib/redux";
import Loader from "../components/Common/Loader/Loader";
import subMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { useForm } from "react-hook-form";
import { getRole, getShopID } from "../actions/getters";
import DatesFields from "../components/Common/Fields/DatesFields";
import _ from "lodash";
import ShopSummary from "../components/Common/Shop/ShopSummary";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import PieChart from "../components/Common/Statistics/PieChart";
import ShopPosts from "../components/Common/Shop/ShopPosts/ShopPosts";
import Button from "../components/Common/Buttons/Button";
import { waitDelay } from "../utils/dates";
import { startGetInfluencersByUserIDs } from "../actions/influencers";
import ShopNegativePosts from "../components/Common/Shop/ShopNegativePosts/ShopNegativePosts";
import { SUPER_ADMIN_ROLE } from "../utils/constants";
import {
  PAGE_REPORTS,
  PAGE_REPORTS_DATES_FORM,
} from "../onboarding/stepsSelectors";
import ShopSummaryCollabs from "../components/Common/Shop/ShopSummaryCollabs";
import ShopSummaryInvites from "../components/Common/Shop/ShopSummaryInvites";
import {
  BUTTON_DOWNLOAD,
  BUTTON_ENTERPRISE_CALCULATE,
  COLLABORATIONS_FOR_PLATFORM,
  GLOBAL_REPORT_BUSINESS,
  INSUFFICIENT_DATA_REPORT,
  LOADING_INFORMATION,
  LOADING_REPORT_INFORMATION,
  REPORT_SHOP_DESCRIPTION,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import TitlePage from "../components/Common/Texts/TitlePage";
import useFocusErrorForm from "../hooks/useFocusErrorForm";
import { setShowOpenChatButton } from "../actions/ui";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const MonthlyReport = () => {
  const [data, setData] = useState({});
  const [businessName, setBusinessName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);

  const monthAgo = subMonths(new Date(), 1);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const shopID = dispatch(getShopID());
  const role = dispatch(getRole());

  const { control, handleSubmit, getValues, formState } = useForm({
    defaultValues: {
      startDate: startOfMonth(monthAgo),
      endDate: endOfMonth(monthAgo),
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const getInitialData = async () => {
    const businessNameShop = await dispatch(startGetBusinessName());
    setBusinessName(businessNameShop);
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [shopID]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const userIDsOrders = data?.topOrders?.map((order) => order.userID);
      const userIDsNegativeOrders = data?.negativeOrders?.map(
        (order) => order.userID
      );
      dispatch(
        startGetInfluencersByUserIDs({
          userIDs: [...userIDsOrders, ...userIDsNegativeOrders],
          getIsDiscarded: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    (async () => {
      if (isDownloading) {
        await waitDelay(1000);
        window.print();
      }
      setIsDownloading(false);
      dispatch(setShowOpenChatButton(true));
    })();
    // eslint-disable-next-line
  }, [isDownloading]);

  const onSubmit = async ({ report = "shop" }) => {
    setIsFetching(true);
    const formValues = getValues();
    const response = await dispatch(
      startGetMonthlyReport({ ...formValues, shopID, report })
    );
    setError(null);
    if (!response.ok) {
      setError(response.message);
    } else {
      setData(response.data);
    }
    setIsFetching(false);
  };

  const onDownloadReport = async () => {
    dispatch(setShowOpenChatButton(false));
    setIsDownloading(true);
  };

  if (isLoading)
    return (
      <Loader size={70} hasMessage={true} message={t(LOADING_INFORMATION)} />
    );

  return (
    <BlockPlanNoActive>
      <ContainerPage sx={{}}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <Box>
            <TitlePage dataTour={PAGE_REPORTS} sx={{}}>
              {businessName}
            </TitlePage>

            <DescriptionPage>{t(REPORT_SHOP_DESCRIPTION)}</DescriptionPage>
          </Box>
          {!_.isEmpty(data) && !isDownloading && !isFetching && (
            <Button onClick={onDownloadReport}>{t(BUTTON_DOWNLOAD)}</Button>
          )}
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mb: 2 }}>
          <DatesFields
            control={control}
            getValues={getValues}
            errors={errors}
            isFetching={isFetching}
            isHiddenButton={isDownloading}
            showSecondButton={role === SUPER_ADMIN_ROLE}
            textSecondButton={t(BUTTON_ENTERPRISE_CALCULATE)}
            secondAction={() => onSubmit({ report: "business" })}
            dataTour={PAGE_REPORTS_DATES_FORM}
          />
        </Box>
        {isFetching ? (
          <Loader
            size={70}
            hasMessage={true}
            fullWidth={true}
            message={t(LOADING_REPORT_INFORMATION)}
          />
        ) : null}
        {!isFetching && error && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          </Box>
        )}
        <>
          {!isFetching &&
            !error &&
            !_.isEmpty(data) &&
            data?.totalEngagement >= 10 && (
              <>
                {role === SUPER_ADMIN_ROLE && data?.businessName && (
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      fontStyle: "normal",
                    }}
                  >
                    {t(GLOBAL_REPORT_BUSINESS, {
                      businessName: data?.businessName,
                    })}
                  </Typography>
                )}
                <ShopSummary data={data} />

                <ShopSummaryCollabs data={data} />

                <ShopSummaryInvites data={data} />

                <PieChart
                  title={t(COLLABORATIONS_FOR_PLATFORM)}
                  count={data?.platformCount}
                  data={data?.percentagePlatform}
                  sx={{ mt: 1, maxWidth: { xs: "99%", md: "60%" } }}
                />

                <ShopPosts
                  data={data?.topOrders}
                  isDownloading={isDownloading}
                />

                <ShopNegativePosts data={data?.negativeOrders} />
              </>
            )}
          {!isFetching &&
            !error &&
            !_.isEmpty(data) &&
            data?.totalEngagement <= 9 && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                  {t(INSUFFICIENT_DATA_REPORT)}
                </Alert>
              </Box>
            )}
        </>
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default MonthlyReport;
