import { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { getUser } from "../actions/getters";
import {
  startChangeIsActiveStore,
  startGetMenuByStoreId,
  startGetStores,
} from "../actions/shops";
import {
  ACCOMPANIMENT_MODE_CREATE,
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  OPERATOR_ROLE,
  PLACE_HOLDER_IMAGE,
  ROLES_ADMIN_SHOPS,
} from "../utils/constants";
import {
  AlertOperatorActionUnauthorized,
  ConfirmAlert,
  SimpleAlert,
  TimerAlert,
} from "../utils/alerts";
import {
  createCategory,
  createProduct,
  startChangeIsMandatory,
  startChangeOrderAccompaniments,
  startChangeOrderAccompanimentsType,
  startChangeOrderCategories,
  startChangeOrderProducts,
  startChangeStatusAccompaniment,
  startChangeStatusProduct,
  startCopyAccompanimentType,
  startCreateAccompaniment,
  startCreateAccompanimentType,
  startCreateCategory,
  startCreateProduct,
  startDeleteAccompaniment,
  startDeleteAccompanimentType,
  startDeleteCategory,
  startDeleteProduct,
  startDuplicateCategory,
  startDuplicateProduct,
  startEditAccompaniment,
  startEditAccompanimentType,
  startEditCategory,
  startEditProduct,
  startMoveProduct,
} from "../actions/menu";
import { useTour } from "@reactour/tour";
import { DUMMY_STORE } from "../onboarding/stepsSelectors";
import {
  changeShowNextButton,
  setIsNewBrand,
  setSaveDataOnboarding,
} from "../actions/onboarding";
import { useNavigate, useLocation } from "react-router-dom";
import { waitDelay } from "../utils/dates";
import { useTranslationApp } from "../lib/i18next";
import {
  ACTIVATE,
  BUTTON_DELETE,
  DEACTIVATE,
  MENU_ACCOMPANIMENT_DUPLICATED,
  MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION,
  MENU_ACCOMPANIMENT_TYPE_COPIED,
  MENU_ACCOMPANIMENT_TYPE_DUPLICATE,
  MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION,
  MENU_CATEGORY_CREATED,
  MENU_CATEGORY_DELETED,
  MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION,
  MENU_CATEGORY_DELETE_CONFIRMATION_TITLE,
  MENU_CATEGORY_DELETING,
  MENU_CATEGORY_DUPLICATE,
  MENU_CATEGORY_DUPLICATE_DESCRIPTION,
  MENU_CATEGORY_SAVED,
  MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION,
  MENU_ONBOARDING_FINISH_DESCRIPTION,
  MENU_ONBOARDING_TEST_CATEGORY,
  MENU_ONBOARDING_TEST_PRODUCT,
  MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION,
  MENU_PRODUCT_CHANGE_STATUS,
  MENU_PRODUCT_CREATED,
  MENU_PRODUCT_DELETED,
  MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION,
  MENU_PRODUCT_DELETE_CONFIRMATION_TITLE,
  MENU_PRODUCT_DELETING,
  MENU_PRODUCT_DUPLICATE,
  MENU_PRODUCT_DUPLICATE_DESCRIPTION,
  MENU_PRODUCT_MOVED,
  MENU_PRODUCT_MOVE_SAME_CATEGORY,
  MENU_PRODUCT_SAVED,
  READY,
} from "../locales/keysTranslations";

export function useMenu({
  initialMenu = [],
  initialStore = "",
  stateLocation = "",
}) {
  const [menu, setMenu] = useState(initialMenu);
  const [storeSelected, setStoreSelected] = useState(initialStore);
  const [categorySelected, setCategorySelected] = useState("");
  const [productSelected, setProductSelected] = useState("");
  const [indexProduct, setIndexProduct] = useState("");
  const [modalMoveProduct, setModalMoveProduct] = useState({
    open: false,
    product: {},
  });

  const location = useLocation();
  const storeIDLocation = location?.state?.storeID;

  const isNewBrand = useSelectorApp((state) => state.onboarding.isNewBrand);

  const [modalCopyAccompanimentType, setModalCopyAccompanimentType] =
    useState(false);
  const [modalOpenCopy, setModalOpenCopy] = useState(false);
  const [modalOpenUpload, setModalOpenUpload] = useState(false);

  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  const user = dispatch(getUser());
  const userRole = user.role;
  const stores = useSelectorApp((state) => state.shop.stores);
  const currentMenu = useSelectorApp((state) => state.shop.currentMenu);
  const isLoading = useSelectorApp((state) => state.shop.isLoading);

  const isOpenOnboarding = useSelectorApp((state) => state.onboarding.isOpen);

  const { isOpen, currentStep, steps, setCurrentStep, setIsOpen } = useTour();

  const { t } = useTranslationApp();

  useEffect(() => {
    dispatch(setSaveDataOnboarding(isNewBrand));
    // eslint-disable-next-line
  }, [isNewBrand]);

  useEffect(() => {
    if (isOpen) {
      const step = steps[currentStep] || {};
      if (step?.actionID === "selectStore") {
        if (storeIDLocation && isNewBrand) {
          onSelectStore(storeIDLocation);
        } else {
          onSelectStore(DUMMY_STORE);
        }
      }
      if (step?.actionID === "selectCategory") {
        onSelectCategory("0");
      }
      if (step?.actionID === "showProductForm") {
        onSelectProduct(ACCOMPANIMENT_MODE_CREATE);
      }
      if (step?.actionID === "showProductFormEdit") {
        onSelectProduct(menu[categorySelected].products[0], "0");
      }
      if (step?.actionID === "createCategoryTest") {
        dispatch(
          createCategory([
            { name: t(MENU_ONBOARDING_TEST_CATEGORY), products: [] },
          ])
        );
        onSelectCategory("0");
        dispatch(
          createProduct({
            categoryID: "0",
            products: [
              {
                name: t(MENU_ONBOARDING_TEST_PRODUCT),
                description: t(MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION),
                price: "10000",
                photoURL: PLACE_HOLDER_IMAGE,
                isProductSensitive: false,
                options: [],
                isActive: true,
              },
            ],
          })
        );
      }
    } else if (!isOpen && (storeSelected === DUMMY_STORE || isNewBrand)) {
      setStoreSelected("");
      setCategorySelected("");
      setProductSelected("");
      setIndexProduct("");
    }

    //eslint-disable-next-line
  }, [currentStep, menu, isOpen]);

  useEffect(() => {
    dispatch(startGetStores({}));
    if (stateLocation?.storeID) {
      setStoreSelected(stateLocation.storeID);
    }
    if (user.role === OPERATOR_ROLE) {
      setStoreSelected(user.storeID);
    } else {
      setStoreSelected("");
    }
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    if (storeSelected) {
      dispatch(startGetMenuByStoreId(user.shopID, storeSelected));
      setMenu(currentMenu || {});
    }
    setCategorySelected("");
    setProductSelected("");
    setIndexProduct("");
    // eslint-disable-next-line
  }, [storeSelected]);

  useEffect(() => {
    setMenu(currentMenu);
    // eslint-disable-next-line
  }, [currentMenu]);

  const onSelectStore = (e) => {
    if (e?.target?.value) {
      setStoreSelected(e.target.value);
    } else {
      setStoreSelected(e);
    }
  };

  const onChangeStatusModalCopy = (status) => setModalOpenCopy(status);

  const onChangeStatusModalUpload = (status) => setModalOpenUpload(status);

  const onChangeStatusModalCopyAccompanimentType = (status) =>
    setModalCopyAccompanimentType(status);

  const onChangeStatusModalMoveProduct = (status) =>
    setModalMoveProduct(status);

  // CATEGORIES
  const onSelectCategory = (category) => {
    setCategorySelected(category);
    setProductSelected("");
  };
  const onChangeOrderCategories = async (categories) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    setMenu(categories);
    const response = await dispatch(
      startChangeOrderCategories(user.shopID, storeSelected, categories)
    );

    return response;
  };
  const onCreateCategory = async (category) => {
    if (userRole === OPERATOR_ROLE && !isOpen)
      return AlertOperatorActionUnauthorized();
    if (
      Object.keys(menu).some((key) => menu[key].name === category) &&
      !isOpenOnboarding
    ) {
      SimpleAlert({
        title: t(MENU_CATEGORY_DUPLICATE),
        text: t(MENU_CATEGORY_DUPLICATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    setProductSelected("");
    const response = dispatch(
      startCreateCategory(user.shopID, storeSelected, category)
    );
    if (isOpenOnboarding) {
      dispatch(changeShowNextButton(true));
      await waitDelay(200);
      setCurrentStep(currentStep + 1);
      return response;
    }
    if (response) {
      SimpleAlert({
        title: t(MENU_CATEGORY_CREATED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };
  const onDeleteCategory = async (category) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const result = await ConfirmAlert({
      title: t(MENU_CATEGORY_DELETE_CONFIRMATION_TITLE),
      text: t(MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(MENU_CATEGORY_DELETING),
        timer: 4000,
      });
      setProductSelected("");
      setCategorySelected("");
      const response = await dispatch(
        startDeleteCategory(user.shopID, storeSelected, category)
      );
      if (response) {
        SimpleAlert({
          title: t(MENU_CATEGORY_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      return response;
    }
    return false;
  };
  const onEditCategory = async (newCategoryName, oldCategoryID) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    if (Object.keys(menu).some((key) => menu[key].name === newCategoryName)) {
      SimpleAlert({
        title: t(MENU_CATEGORY_DUPLICATE),
        text: t(MENU_CATEGORY_DUPLICATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    setProductSelected("");
    setCategorySelected("");
    const response = dispatch(
      startEditCategory(
        user.shopID,
        storeSelected,
        newCategoryName,
        oldCategoryID
      )
    );
    if (response) {
      SimpleAlert({
        title: t(MENU_CATEGORY_SAVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };
  const onDuplicateCategory = async (categoryID) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startDuplicateCategory(storeSelected, menu[categoryID])
    );
    if (response) {
      setCategorySelected("0");
    }
  };

  // PRODUCTS
  const onSelectProduct = (product, id) => {
    if (product === "create") {
      setProductSelected(product);
    } else {
      setProductSelected({ ...product, id });
    }
    setIndexProduct(id ?? "");
  };
  const onFinishCreateProduct = () => {
    setProductSelected("");
    setIndexProduct("");
  };
  const onChangeOrderProducts = async (products) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    setProductSelected("");
    const newOrder = Object.keys(menu).map((index) => {
      if (index === categorySelected) {
        return {
          ...menu[categorySelected],
          products,
        };
      }
      return menu[index];
    });
    setMenu(newOrder);
    const response = await dispatch(
      startChangeOrderProducts(
        user.shopID,
        storeSelected,
        categorySelected,
        products
      )
    );
    return response;
  };
  const onSaveProduct = async (product) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    if (
      Object.keys(menu[categorySelected]?.products)?.some(
        (key) =>
          menu[categorySelected].products[key].name === product.name &&
          key !== indexProduct
      )
    ) {
      SimpleAlert({
        title: t(MENU_PRODUCT_DUPLICATE),
        text: t(MENU_PRODUCT_DUPLICATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }

    const response = await dispatch(
      startEditProduct(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        product
      )
    );
    if (response) {
      SimpleAlert({
        title: t(MENU_PRODUCT_SAVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      setProductSelected("");
    }
    return response;
  };
  const onCreateProduct = async (product) => {
    if (userRole === OPERATOR_ROLE && !isOpen)
      return AlertOperatorActionUnauthorized();
    if (
      !isOpenOnboarding &&
      menu[categorySelected].products &&
      Object.keys(menu[categorySelected]?.products)?.some(
        (key) => menu[categorySelected].products[key].name === product.name
      )
    ) {
      SimpleAlert({
        title: t(MENU_PRODUCT_DUPLICATE),
        text: t(MENU_PRODUCT_DUPLICATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    const response = await dispatch(
      startCreateProduct(user.shopID, storeSelected, categorySelected, product)
    );
    if (isOpenOnboarding) {
      setIndexProduct(response.key);
      setCurrentStep(currentStep + 1);
      return response;
    }
    if (response.ok) {
      SimpleAlert({
        title: t(MENU_PRODUCT_CREATED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      setIndexProduct(response.key);
    }
    return response;
  };
  const onStartMoveProduct = (product) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    setModalMoveProduct({
      open: true,
      product,
    });
  };
  const onMoveProduct = async (category) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    setProductSelected("");
    if (category === categorySelected) {
      return SimpleAlert({
        title: t(MENU_PRODUCT_MOVE_SAME_CATEGORY),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    let keyProduct;
    !productSelected
      ? Object.keys(menu[categorySelected].products).some((key) => {
          if (
            menu[categorySelected].products[key].name ===
            modalMoveProduct.product.name
          ) {
            keyProduct = key;
          }
          return null;
        })
      : (keyProduct = indexProduct);
    const response = await dispatch(
      startMoveProduct(
        user.shopID,
        storeSelected,
        categorySelected,
        keyProduct,
        category
      )
    );
    if (response) {
      onChangeStatusModalMoveProduct({
        open: false,
        product: {},
      });
      SimpleAlert({
        title: t(MENU_PRODUCT_MOVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };
  const onDeleteProduct = async (product) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const result = await ConfirmAlert({
      title: t(MENU_PRODUCT_DELETE_CONFIRMATION_TITLE),
      text: t(MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      setProductSelected("");
      let keyProduct;
      !productSelected
        ? Object.keys(menu[categorySelected].products).some((key) => {
            if (menu[categorySelected].products[key].name === product.name) {
              keyProduct = key;
            }
            return null;
          })
        : (keyProduct = indexProduct);
      TimerAlert({
        title: t(MENU_PRODUCT_DELETING),
        timer: 4000,
      });
      const response = await dispatch(
        startDeleteProduct(
          user.shopID,
          storeSelected,
          categorySelected,
          keyProduct,
          product.photoURL
        )
      );
      if (response) {
        SimpleAlert({
          title: t(MENU_PRODUCT_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      return response;
    }
    return false;
  };
  const onDuplicateProduct = async (product) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startDuplicateProduct(storeSelected, categorySelected, product)
    );
    if (response) {
      setIndexProduct(0);
      setProductSelected(menu[categorySelected].products[0]);
    }
  };
  const onChangeStatusProduct = async (product) => {
    let keyProduct;
    !productSelected
      ? Object.keys(menu[categorySelected].products).some((key) => {
          if (menu[categorySelected].products[key].name === product.name) {
            keyProduct = key;
          }
          return null;
        })
      : (keyProduct = indexProduct);

    const response = await dispatch(
      startChangeStatusProduct(
        user.shopID,
        storeSelected,
        categorySelected,
        keyProduct,
        !product.isActive
      )
    );
    if (isOpenOnboarding) {
      if (userRole === OPERATOR_ROLE) {
        navigate("/redeem-code");
      }
      dispatch(setIsNewBrand(false));
      setIsOpen(false);
      if (isNewBrand && ROLES_ADMIN_SHOPS.includes(userRole)) {
        dispatch(startChangeIsActiveStore(storeSelected, true, true));
        navigate("/account");
        SimpleAlert({
          title: t(READY),
          text: t(MENU_ONBOARDING_FINISH_DESCRIPTION),
          additionalText: t(MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      } else {
        SimpleAlert({
          title: t(READY),
          text: t(MENU_ONBOARDING_FINISH_DESCRIPTION),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      return response;
    }
    if (response) {
      SimpleAlert({
        title: t(MENU_PRODUCT_CHANGE_STATUS, {
          status: !product.isActive ? t(DEACTIVATE) : t(ACTIVATE),
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  // ACCOMPANIMENT TYPES
  const onCreateAccompanimentType = async (accompanimentType) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();

    if (!isOpenOnboarding) {
      let isDuplicated = false;
      Object.keys(menu[categorySelected].products).map((keyProduct) => {
        if (keyProduct === indexProduct) {
          isDuplicated = Object.keys(
            menu[categorySelected].products[keyProduct].options ?? []
          )?.some((key) => {
            return (
              menu[categorySelected].products[keyProduct].options[key].name ===
              accompanimentType.name
            );
          });
        }
        return false;
      });
      if (isDuplicated)
        return SimpleAlert({
          title: t(MENU_ACCOMPANIMENT_TYPE_DUPLICATE),
          text: t(MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION),
          icon: ALERT_ICON_TYPE_ERROR,
        });
    }
    const response = await dispatch(
      startCreateAccompanimentType(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType
      )
    );

    if (isOpenOnboarding) {
      setCurrentStep(currentStep + 1);
      return response;
    }

    return response;
  };
  const onEditAccompanimentType = async (
    accompanimentType,
    accompanimentTypeData
  ) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    let isDuplicated = false;
    Object.keys(menu[categorySelected].products).map((keyProduct) => {
      if (keyProduct === indexProduct) {
        isDuplicated = Object.keys(
          menu[categorySelected].products[keyProduct].options ?? []
        )?.some((key) => {
          return (
            menu[categorySelected].products[keyProduct].options[key].name ===
              accompanimentTypeData.name && key !== accompanimentType
          );
        });
      }
      return false;
    });
    if (isDuplicated)
      return SimpleAlert({
        title: t(MENU_ACCOMPANIMENT_TYPE_DUPLICATE),
        text: t(MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    const response = await dispatch(
      startEditAccompanimentType(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType,
        accompanimentTypeData
      )
    );
    return response;
  };
  const onDeleteAccompanimentType = async (accompanimentType) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startDeleteAccompanimentType(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType
      )
    );
    return response;
  };
  const onStartCopyAccompanimentType = async () => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    onChangeStatusModalCopyAccompanimentType(true);
  };
  const onCopyAccompanimentType = async (
    category,
    product,
    accompanimentType
  ) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startCopyAccompanimentType(
        user.shopID,
        storeSelected,
        category,
        product,
        accompanimentType,
        categorySelected,
        indexProduct
      )
    );
    if (response) {
      onChangeStatusModalCopyAccompanimentType(false);
      SimpleAlert({
        title: t(MENU_ACCOMPANIMENT_TYPE_COPIED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };
  const onChangeOrderAccompanimentsType = async (accompanimentsType) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startChangeOrderAccompanimentsType(
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentsType
      )
    );
    return response;
  };
  const onChangeIsMandatory = async (accompanimentType, isMandatory) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startChangeIsMandatory(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType,
        isMandatory
      )
    );
    return response;
  };

  // ACCOMPANIMENTS
  const onCreateAccompaniment = async (accompaniment, accompanimentType) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();

    if (!isOpenOnboarding) {
      let isDuplicated;
      Object.keys(menu[categorySelected].products).map((keyProduct) => {
        if (keyProduct === indexProduct) {
          Object.keys(menu[categorySelected].products[keyProduct].options).map(
            (keyOption) => {
              if (keyOption === accompanimentType) {
                if (
                  menu[categorySelected].products[keyProduct].options[keyOption]
                    .options
                ) {
                  isDuplicated = Object.keys(
                    menu[categorySelected].products[keyProduct].options[
                      keyOption
                    ].options
                  ).some((key) => {
                    return (
                      menu[categorySelected].products[keyProduct].options[
                        keyOption
                      ].options[key].name === accompaniment.name
                    );
                  });
                } else {
                  isDuplicated = false;
                }
              }
              return false;
            }
          );
        }
        return false;
      });
      if (isDuplicated)
        return SimpleAlert({
          title: t(MENU_ACCOMPANIMENT_DUPLICATED),
          text: t(MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION),
          icon: ALERT_ICON_TYPE_ERROR,
        });
    }

    const response = await dispatch(
      startCreateAccompaniment(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType,
        accompaniment
      )
    );

    if (isOpenOnboarding) {
      setCurrentStep(currentStep + 1);
      return response;
    }

    return response;
  };
  const onEditAccompaniment = async (
    accompanimentType,
    accompanimentID,
    accompaniment,
    indexAccompaniment
  ) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    let isDuplicated;
    Object.keys(menu[categorySelected].products).map((keyProduct) => {
      if (keyProduct === indexProduct) {
        Object.keys(menu[categorySelected].products[keyProduct].options).map(
          (keyOption) => {
            if (keyOption === accompanimentType) {
              if (
                menu[categorySelected].products[keyProduct].options[keyOption]
                  .options
              ) {
                isDuplicated = Object.keys(
                  menu[categorySelected].products[keyProduct].options[keyOption]
                    .options
                ).some((key) => {
                  return (
                    menu[categorySelected].products[keyProduct].options[
                      keyOption
                    ].options[key].name === accompaniment.name &&
                    key !== indexAccompaniment.toString()
                  );
                });
              } else {
                isDuplicated = false;
              }
            }
            return false;
          }
        );
      }
      return false;
    });
    if (isDuplicated)
      return SimpleAlert({
        title: t(MENU_ACCOMPANIMENT_DUPLICATED),
        text: t(MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    const response = await dispatch(
      startEditAccompaniment(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType,
        accompanimentID,
        accompaniment
      )
    );
    return response;
  };
  const onDeleteAccompaniment = async (accompanimentType, accompaniment) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startDeleteAccompaniment(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType,
        accompaniment
      )
    );
    return response;
  };
  const onChangeOrderAccompaniments = async (
    accompanimentsTypeID,
    accompaniments
  ) => {
    if (userRole === OPERATOR_ROLE) return AlertOperatorActionUnauthorized();
    const response = await dispatch(
      startChangeOrderAccompaniments(
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentsTypeID,
        accompaniments
      )
    );
    return response;
  };
  const onChangeStatusAccompaniment = async (
    accompanimentType,
    accompaniment,
    status
  ) => {
    const response = await dispatch(
      startChangeStatusAccompaniment(
        user.shopID,
        storeSelected,
        categorySelected,
        indexProduct,
        accompanimentType,
        accompaniment,
        status
      )
    );
    return response;
  };

  return {
    // GENERAL
    menu,
    isLoading,

    // STORES
    storeSelected,
    stores,
    onSelectStore,

    // MODAL
    modalOpenCopy,
    modalOpenUpload,
    modalMoveProduct,
    modalCopyAccompanimentType,
    onChangeStatusModalCopy,
    onChangeStatusModalUpload,
    onChangeStatusModalCopyAccompanimentType,
    onChangeStatusModalMoveProduct,

    // CATEGORIES
    categorySelected,
    onSelectCategory,
    onChangeOrderCategories,
    onCreateCategory,
    onDeleteCategory,
    onEditCategory,
    onDuplicateCategory,

    // PRODUCTS
    productSelected,
    indexProduct,
    onSelectProduct,
    onFinishCreateProduct,
    onChangeOrderProducts,
    onSaveProduct,
    onCreateProduct,
    onStartMoveProduct,
    onMoveProduct,
    onDeleteProduct,
    onDuplicateProduct,
    onChangeStatusProduct,

    // ACCOMPANIMENTS TYPE
    onCreateAccompanimentType,
    onEditAccompanimentType,
    onDeleteAccompanimentType,
    onStartCopyAccompanimentType,
    onCopyAccompanimentType,
    onChangeOrderAccompanimentsType,
    onChangeIsMandatory,

    // ACCOMPANIMENTS
    onCreateAccompaniment,
    onEditAccompaniment,
    onDeleteAccompaniment,
    onChangeOrderAccompaniments,
    onChangeStatusAccompaniment,
  };
}
