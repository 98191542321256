import React, { useEffect, useState } from "react";
import Loader from "../../../Common/Loader/Loader";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { DEFAULT_TABLE_PAGES } from "../../../../utils/constants";
import { usePagination } from "../../../../hooks/usePagination";
import Box from "@mui/material/Box";
import { startGetAdvertisers } from "../../../../actions/advertisers";
import AdvertisersTable from "./AdvertisersTable";
import Button from "../../../Common/Buttons/Button";
import { useTranslationApp } from "../../../../lib/i18next";
import { BUTTON_CREATE_PAYMENT_LINK } from "../../../../locales/keysTranslations";
import ModalStripeCreateAdsPaymentLink from "../../../Common/Stripe/ModalStripeCreateAdsPaymentLink";

const Advertisers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalCreateAdsPaymentLinkOpen, setModalCreateAdsPaymentLinkOpen] =
    useState(false);

  const limit = DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false;

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const { advertisers } = useSelectorApp((state) => state.advertisers);

  const onToggleModalCreateAdsPaymentLink = () =>
    setModalCreateAdsPaymentLinkOpen((prev) => !prev);

  const onGetNextPageAdvertisers = () => {
    dispatch(
      startGetAdvertisers({ limit: rowsPerPage, onChangeLastKey, lastKey })
    );
  };

  const { rowsPerPage, page, lastKey, onChangeLastKey, onPageChange } =
    usePagination({
      rowsPerPageValue: DEFAULT_TABLE_PAGES,
      onCallBackNextPage: onGetNextPageAdvertisers,
    });

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      startGetAdvertisers({
        limit: limit,
        onChangeLastKey,
        onCallbackFinish: () => setIsLoading(false),
      })
    );

    //eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {isLoading ? (
        <Loader size={70} fullWidth={true} />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: 2,
            }}
          >
            <Button onClick={onToggleModalCreateAdsPaymentLink} type="button">
              {t(BUTTON_CREATE_PAYMENT_LINK)}
            </Button>
          </Box>
          {modalCreateAdsPaymentLinkOpen && (
            <ModalStripeCreateAdsPaymentLink
              modalOpen={modalCreateAdsPaymentLinkOpen}
              onCloseModal={onToggleModalCreateAdsPaymentLink}
            />
          )}
          <AdvertisersTable
            data={advertisers}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default Advertisers;
