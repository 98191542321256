import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import ModalBasicLayout from "../ModalBasicLayout";
import AddBrandSuperAdminForm from "./AddBrandSuperAdminForm";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ADD_BRAND,
  BUTTON_CANCEL,
  MODAL_ADD_BRAND_SUPER_ADMIN_TITLE,
} from "../../../../locales/keysTranslations";
import LoadingButton from "../../Buttons/LoadingButton";
import { useDispatchApp } from "../../../../lib/redux";
import { startAddBrandToBusiness } from "../../../../actions/shops";

const ModalAddBrandSuperAdmin = ({
  modalOpen,
  onCloseModal,
  onCallbackFinish,
}) => {
  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues: {
      businessName: "",
      name: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
  });
  const [isFetching, setIsFetching] = useState(false);

  const { errors } = formState;

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onSubmit = async (data) => {
    setIsFetching(true);
    const response = await dispatch(startAddBrandToBusiness(data));
    setIsFetching(false);
    if (response) {
      onCallbackFinish(response);
      onCloseModal();
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
      ariaLabel={t(MODAL_ADD_BRAND_SUPER_ADMIN_TITLE)}
      title={t(MODAL_ADD_BRAND_SUPER_ADMIN_TITLE)}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <AddBrandSuperAdminForm
          control={control}
          errors={errors}
          watch={watch}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 1,
            width: "100%",
            mt: 2,
          }}
        >
          <LoadingButton
            disabled={isFetching}
            color="primary"
            type="button"
            onClick={onCloseModal}
            sx={{
              minWidth: 130,
            }}
          >
            {t(BUTTON_CANCEL)}
          </LoadingButton>
          <LoadingButton
            loading={isFetching}
            color="secondary"
            type="submit"
            sx={{
              minWidth: 130,
            }}
          >
            {t(ADD_BRAND)}
          </LoadingButton>
        </Box>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalAddBrandSuperAdmin;
