import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { IS_PRE_REGISTERED_FILTER_TYPE } from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";
import PersonIcon from "@mui/icons-material/Person";
import { CREATORS_OWNER_IS_PRE_REGISTERED_FILTER } from "../../../locales/keysTranslations";

const InfluencersUsersFilters = ({ filter, onChangeFilter }) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "nowrap" }}>
      <Chip
        icon={<PersonIcon />}
        label={t(CREATORS_OWNER_IS_PRE_REGISTERED_FILTER)}
        onClick={() => {
          onChangeFilter(IS_PRE_REGISTERED_FILTER_TYPE);
        }}
        color={
          filter === IS_PRE_REGISTERED_FILTER_TYPE ? "secondary" : "default"
        }
      />
    </Box>
  );
};

export default InfluencersUsersFilters;
