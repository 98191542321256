import {
  get,
  limitToFirst,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
  startAfter,
  update,
} from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import { getShopID } from "./getters";
import { startGetInfluencersByUserIDs } from "./influencers";
import { getObjectError } from "../utils/formats";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  CHAT_FROM_AMBASSADOR,
} from "../utils/constants";
import { waitDelay } from "../utils/dates";
import { generateRandomCode } from "../utils/generateRandomsValues";
import {
  AMBASSADOR_DELETE,
  AMBASSADOR_DELETE_DESCRIPTION,
  BUTTON_DELETE,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  SHOP_ID_USER_ID_NOT_SENT,
} from "../locales/keysTranslations";
import i18next from "i18next";

export const startGetAmbassadorsByShop =
  ({ limit, onChangeLastKey, lastKey, onCallbackFinish = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/ambassadors`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/ambassadors`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysAmbassadors = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysAmbassadors.push(snapshot.key);
        count++;
      });

      const queriesAmbassador = [];
      keysAmbassadors.forEach((keyAmbassadors) => {
        queriesAmbassador.push(get(ref(db, `ambassadors/${keyAmbassadors}`)));
      });

      const ambassadorsSnapshots = await Promise.all(queriesAmbassador);

      let ambassadors = {};
      let userIDs = [];
      ambassadorsSnapshots.forEach((ambassadorSnapshot) => {
        ambassadors[ambassadorSnapshot.key] = ambassadorSnapshot.val();
        userIDs.push(ambassadorSnapshot.val().userID);
      });

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      // lastKey
      // ?
      dispatch(getAmbassadorsByShopPagination(ambassadors));
      onCallbackFinish();
      // : dispatch(getAmbassadorsByShop(ambassadors));
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingAmbassadorsFinish({}));
      return false;
    }
  };
export const startGetAmbassadorByUserID = (userID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const ambassadorID = `${shopID}-${userID}`;
    const dbRef = ref(db, `ambassadors/${ambassadorID}`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(
        startGetInfluencersByUserIDs({
          userIDs: [userID],
          getIsDiscarded: false,
        })
      );
      dispatch(getAmbassadorsByShopPagination({ [ambassadorID]: data }));
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
// const getAmbassadorsByShop = (ambassadors) => ({
//   type: types.GET_AMBASSADORS_FINISH,
//   payload: ambassadors,
// });
const getAmbassadorsByShopPagination = (ambassadors) => ({
  type: types.GET_NEXT_AMBASSADORS_FINISH,
  payload: ambassadors,
});

export const startCreateAmbassador = (data) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const userID = data.userID;

    if (!shopID || !userID)
      return SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(SHOP_ID_USER_ID_NOT_SENT),
        icon: ALERT_ICON_TYPE_ERROR,
      });

    const ambassadorID = `${shopID}-${userID}`;
    const dbRef = ref(db, `ambassadors/${ambassadorID}`);

    const inviteCode = generateRandomCode(8);

    const ambassadorObject = {
      ...data,
      inviteCode,
      creationTime: new Date().getTime(),
      orderFrom: new Date().toISOString(),
      shopID,
    };

    await set(dbRef, ambassadorObject);

    dispatch(createAmbassador({ key: ambassadorID, data: ambassadorObject }));

    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const createAmbassador = (data) => (dispatch, getState) => {
  const isOpenOnboarding = getState().onboarding.isOpen;

  dispatch({
    type: types.CREATE_AMBASSADOR,
    payload: data,
  });
  if (!isOpenOnboarding) {
    dispatch(startCreateChatAmbassador(data.data));
  }
};

export const startCreateChatAmbassador =
  (ambassador) => async (dispatch, getState) => {
    try {
      let chatID;
      const chats = getState().chat.chats;

      Object.keys(chats).forEach((keyChat) => {
        const chat = chats[keyChat];
        if (!chat.users) return;
        if (chat.users.includes(ambassador.userID)) {
          chatID = keyChat;
        }
      });

      const { shopID, userID } = ambassador;

      if (!chatID) {
        chatID = push(ref(db, "chats")).key;
        const updates = {};
        updates[`chats/${chatID}`] = {
          chatID: chatID,
          users: [shopID, userID],
          from: CHAT_FROM_AMBASSADOR,
        };
        await update(ref(db), updates);
      }
      await waitDelay(300);

      dispatch(
        startAssignChatIDAmbassador({
          ambassadorID: `${shopID}-${userID}`,
          chatID,
        })
      );
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startEditAmbassador =
  ({ ambassadorID, ambassador }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `ambassadors/${ambassadorID}`);
      await update(dbRef, ambassador);
      dispatch(
        editAmbassador({
          key: ambassadorID,
          data: ambassador,
        })
      );
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const editAmbassador = (ambassadorID) => ({
  type: types.EDIT_AMBASSADOR,
  payload: ambassadorID,
});

export const startDeleteAmbassador =
  (ambassadorID) => async (dispatch, getState) => {
    try {
      const result = await ConfirmAlert({
        title: i18next.t(AMBASSADOR_DELETE),
        text: i18next.t(AMBASSADOR_DELETE_DESCRIPTION),
        confirmButtonText: i18next.t(BUTTON_DELETE),
      });
      if (result.isConfirmed) {
        const dbRef = ref(db, `ambassadors/${ambassadorID}`);
        await remove(dbRef);
        dispatch(deleteAmbassador(ambassadorID));
        return true;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const deleteAmbassador = (ambassadorID) => ({
  type: types.DELETE_AMBASSADOR,
  payload: ambassadorID,
});

export const startAssignChatIDAmbassador =
  ({ ambassadorID, chatID }) =>
  async (dispatch) => {
    try {
      const dbRef = ref(db, `ambassadors/${ambassadorID}/chatID`);
      await set(dbRef, chatID);
      dispatch(
        assignChatIDAmbassador({
          key: ambassadorID,
          chatID,
        })
      );
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const assignChatIDAmbassador = (ambassadorID) => ({
  type: types.ASSIGN_CHAT_ID_AMBASSADOR,
  payload: ambassadorID,
});

const loadingAmbassadorsFinish = () => ({
  type: types.LOADING_AMBASSADORS_FINISH,
});
