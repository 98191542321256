import React from "react";
import { Controller } from "react-hook-form";
import AnswerContainer from "../../../Common/Forms/TypeForm/AnswerContainer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import AnswerTitle from "../../../Common/Forms/TypeForm/AnswerTitle";
import ActionsButtons from "../../../Common/Forms/TypeForm/ActionsButtons";
import NumberField from "../../../Common/NumberFormats/NumberField";
import { PAGE_CAMPAIGNS_ANSWER_NAME } from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION,
  // CREATE_CAMPAIGN_BUDGET_DESCRIPTION,
  CREATE_CAMPAIGN_BUDGET_QUESTION,
  CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER,
  CREATE_CAMPAIGN_COPYWRITING_QUESTION,
  CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CREATE_CAMPAIGN_DESCRIPTION_QUESTION,
  CREATE_CAMPAIGN_GOAL_PLACEHOLDER,
  CREATE_CAMPAIGN_GOAL_QUESTION,
  CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION,
  CREATE_CAMPAIGN_NAME_PLACEHOLDER,
  CREATE_CAMPAIGN_NAME_QUESTION,
  CREATE_CAMPAIGN_PLATFORMS,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION,
  CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION,
  CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION,
  IMAGES,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
  NO,
  SELECT_A_OPTION,
  VALUE_EXPRESSED_IN,
  VIDEOS,
  YES,
} from "../../../../locales/keysTranslations";
import SliderBudgetCampaign from "../../../Common/Forms/Sliders/SliderBudgetCampaign";
import RadioGroup from "../../../Common/Forms/RadioGrupo/RadioGroup";
import Select from "../../../Common/Selects/Select";
import {
  GOAL_CAMPAIGN_OPTIONS,
  MAX_FOLLOWERS,
  MIN_FOLLOWERS,
} from "../../../../utils/constants";
import { useMediaQuery } from "@mui/material";
import SliderBase from "../../../Common/Forms/Sliders/SliderBase";
import { marksInfluencersMax500K } from "../../../../utils/slidersMarks";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { getMinValue } from "../../../../services/campaigns";

const CampaignInformationSection = ({
  step,
  currentAnswer,
  stepVisible,
  error,
  control,
  watch,
  setValue,
  getValues,
  onNextStep,
  onPreviousStep,
}) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const LOCALE = useLocale();
  const isUgcCampaign = watch("isUgcCampaign");
  const typeInfluencers = watch("typeInfluencers");

  const minValue = getMinValue({
    LOCALE,
    isUgcCampaign,
    typeInfluencers,
  });
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: step >= 1 && step <= 15 ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer
        stepPosition={"name"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_NAME_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "name" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              data-tour={PAGE_CAMPAIGNS_ANSWER_NAME}
              fullWidth
              margin="normal"
              placeholder={t(CREATE_CAMPAIGN_NAME_PLACEHOLDER)}
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          showPreviousButton={false}
          onNextStep={() => onNextStep("name", getValues("name"))}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"description"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_DESCRIPTION_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "description" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder={t(CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER)}
              variant="standard"
              multiline
              minRows={1}
              maxRows={4}
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("description", getValues("description"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"goal"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_GOAL_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "goal" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Select
          name="goal"
          label={t(CREATE_CAMPAIGN_GOAL_PLACEHOLDER)}
          variant="standard"
          margin="normal"
          fullWidth
          options={GOAL_CAMPAIGN_OPTIONS}
          doTranslate={true}
          control={control}
          rules={{
            required: false,
          }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("goal", getValues("goal"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"amountInfluencers"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "amountInfluencers" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="amountInfluencers"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder={t(CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER)}
              variant="standard"
              {...field}
              InputProps={{
                inputComponent: NumberField,
              }}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("amountInfluencers", getValues("amountInfluencers"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"typeInfluencers"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION)}`}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION, {
            value: minValue,
            currency: "USD",
          })}
        </Typography>
        {!!error && error.step === "typeInfluencers" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="typeInfluencers"
          control={control}
          render={({ field }) => (
            <SliderBase
              range={field.value}
              onChange={field.onChange}
              min={MIN_FOLLOWERS}
              max={MAX_FOLLOWERS}
              step={1000}
              marks={marksInfluencersMax500K.filter((mark, index) => {
                if (isMobile) {
                  if (index === 0) {
                    return mark;
                  }
                  if (index % 2) {
                    return mark;
                  } else {
                    return false;
                  }
                }

                return mark;
              })}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("typeInfluencers", getValues("typeInfluencers"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"individualBudget"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 2 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_BUDGET_QUESTION)}`}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(VALUE_EXPRESSED_IN, { currency: "USD" })}
        </Typography>
        {!!error && error.step === "individualBudget" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}

        <SliderBudgetCampaign
          control={control}
          name="individualBudget"
          watch={watch}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("individualBudget", getValues("individualBudget"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"isUgcCampaign"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 2 }}>
          {`${stepVisible}. ${t(
            CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION
          )}`}
        </AnswerTitle>
        {!!error && error.step === "isUgcCampaign" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <RadioGroup
          control={control}
          isBooleanValues={true}
          variantTitle={isMobile ? "body1" : "h6"}
          name="isUgcCampaign"
          label={t(CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER)}
          options={[
            {
              value: true,
              label: t(
                CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION
              ),
            },
            {
              value: false,
              label: t(
                CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION
              ),
            },
          ]}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("isUgcCampaign", getValues("isUgcCampaign"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"platforms"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_PLATFORMS)}`}
        </AnswerTitle>
        {!!error && error.step === "platforms" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="platforms"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.instagram}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          instagram: e.target.checked,
                        })
                      }
                      name="instagram"
                    />
                  )}
                />
              }
              label="Instagram"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="platforms"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.tiktok}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          tiktok: e.target.checked,
                        })
                      }
                      name="tiktok"
                    />
                  )}
                />
              }
              label="TikTok"
            />
          </Grid>
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("platforms", getValues("platforms"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"typeContentMedia"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>{`${stepVisible}. ${t(
          CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION
        )}`}</AnswerTitle>
        {!!error && error.step === "typeContentMedia" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="typeContentMedia"
              nameValue="videos"
              label={t(VIDEOS)}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckItem
              control={control}
              nameKey="typeContentMedia"
              nameValue="images"
              label={t(IMAGES)}
            />
          </Grid>
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("typeContentMedia", getValues("typeContentMedia"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"typeContent"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 1 }}>{`${stepVisible}. ${t(
          CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION
        )}`}</AnswerTitle>
        {!!error && error.step === "typeContent" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1} sx={{ mb: 1 }}>
          {Object.keys(watch("platforms")).map((platform) => {
            if (watch("platforms")[platform] && platform === "instagram") {
              return (
                <>
                  <Grid item xs={6}>
                    <CheckItem
                      control={control}
                      nameKey="typeContent"
                      nameValue="instagramBurstStories"
                      label={t(INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckItem
                      control={control}
                      nameKey="typeContent"
                      nameValue="instagramPost"
                      label={t(INVITE_FORM_FORMAT_INSTAGRAM_POST)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckItem
                      control={control}
                      nameKey="typeContent"
                      nameValue="instagramReel"
                      label={t(INVITE_FORM_FORMAT_INSTAGRAM_REEL)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckItem
                      control={control}
                      nameKey="typeContent"
                      nameValue="instagramStory"
                      label={t(INVITE_FORM_FORMAT_INSTAGRAM_STORY)}
                    />
                  </Grid>
                </>
              );
            }
            if (watch("platforms")[platform] && platform === "tiktok") {
              return (
                <>
                  <Grid item xs={6}>
                    <CheckItem
                      control={control}
                      nameKey="typeContent"
                      nameValue="tiktokPost"
                      label={t(INVITE_FORM_FORMAT_TIKTOK_POST)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckItem
                      control={control}
                      nameKey="typeContent"
                      nameValue="tiktokStory"
                      label={t(INVITE_FORM_FORMAT_TIKTOK_STORY)}
                    />
                  </Grid>
                </>
              );
            }
            return null;
          })}
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("typeContent", {
              typeContent: getValues("typeContent"),
              copyPost: getValues("copyPost"),
              trafficLink: getValues("trafficLink"),
            })
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"copyPost"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_COPYWRITING_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "copyPost" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="copyPost"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder={t(CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER)}
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("copyPost", getValues("copyPost"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"hasCollaboration"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION)}`}
        </AnswerTitle>

        <RadioGroup
          control={control}
          isBooleanValues={true}
          name="hasCollaboration"
          variantTitle={isMobile ? "body1" : "h6"}
          label={t(SELECT_A_OPTION)}
          options={[
            {
              value: true,
              label: t(YES),
            },
            {
              value: false,
              label: t(NO),
            },
          ]}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("hasCollaboration", getValues("hasCollaboration"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"trafficLink"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "trafficLink" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="trafficLink"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
              variant="standard"
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("trafficLink", getValues("trafficLink"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"productAttributes"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "productAttributes" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="productAttributes"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              multiline
              minRows={1}
              maxRows={4}
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("productAttributes", getValues("productAttributes"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"messageContent"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "messageContent" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="messageContent"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder={t(CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER)}
              fullWidth
              margin="normal"
              variant="standard"
              multiline
              minRows={1}
              maxRows={4}
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("messageContent", getValues("messageContent"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
    </Box>
  );
};

const CheckItem = ({ control, nameKey, nameValue, label }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={nameKey}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={field.value[nameValue]}
              onChange={(e) =>
                field.onChange({
                  ...field.value,
                  [nameValue]: e.target.checked,
                })
              }
              name={nameValue}
            />
          )}
        />
      }
      label={label}
    />
  );
};
export default CampaignInformationSection;
