import React from "react";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import {
  marksAge,
  textNumberCompactFormat,
} from "../../../../../utils/slidersMarks";
import { capitalizeFirstLetter } from "../../../../../utils/formats";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM,
  CAMPAIGN_AGE_RANGE_PLACEHOLDER,
  CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER,
  CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER,
  CAMPAIGN_REPOST_PLACEHOLDER,
  CAMPAIGN_REPOST_PLACEHOLDER_ITEM,
  FIELD_REQUIRED,
} from "../../../../../locales/keysTranslations";
import { GREY_SLIDER_COLOR } from "../../../../../utils/colors";
import SelectCities from "../../../../Common/Selects/SelectCities";
import TextField from "../../../../Common/Fields/TextField";
import SelectInfluencerCategories from "../../../../Common/Selects/SelectInfluencerCategories";

const InfluencersInformation = ({
  control,
  errors,
  watch,
  setValue,
  isDisabledInput,
}) => {
  const { t } = useTranslationApp();

  const isUgcCampaign = watch("isUgcCampaign");
  const platforms = watch("platforms");
  const repost = watch("repost");
  const hasCollaboration = watch("hasCollaboration");

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      {!isUgcCampaign && (
        <>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth>
              <Typography variant="body1">
                {t(CAMPAIGN_REPOST_PLACEHOLDER)}
              </Typography>
              {Object.keys(platforms).map((platform) => {
                if (platforms[platform]) {
                  return (
                    <TextField
                      name={`repost-${platform}`}
                      fullWidth
                      disabled={isDisabledInput}
                      margin="normal"
                      label={t(CAMPAIGN_REPOST_PLACEHOLDER_ITEM, {
                        platform: capitalizeFirstLetter(platform),
                      })}
                      variant="filled"
                      useValue={false}
                      value={repost[platform] ?? ""}
                      sx={{ mt: 1 }}
                      doOnChange={false}
                      control={control}
                      actionsChange={(value) => {
                        setValue("repost", {
                          ...repost,
                          [platform]: value,
                        });
                      }}
                      errors={errors}
                      rules={{
                        validate: () => {
                          if (
                            hasCollaboration &&
                            platform === "instagram" &&
                            !repost[platform]
                          ) {
                            return t(
                              CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM
                            );
                          }
                        },
                      }}
                    />
                  );
                }
                return null;
              })}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectCities
              multiple={true}
              showAllOption={true}
              disabled={isDisabledInput}
              label={t(CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER)}
              variant="filled"
              name="audienceLocation"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
              errors={errors}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} lg={6}>
        <Typography variant="body1">
          {t(CAMPAIGN_AGE_RANGE_PLACEHOLDER)}
        </Typography>
        <Controller
          name="ageRange"
          control={control}
          render={({ field }) => (
            <Slider
              sx={{
                width: "calc(100% - 24px)",
                ml: 1,
                ".MuiSlider-track": {
                  height: 2,
                },
                ".MuiSlider-rail": {
                  backgroundColor: GREY_SLIDER_COLOR,
                  height: 4,
                },
                ".MuiSlider-mark": {
                  backgroundColor: "transparent",
                },
              }}
              disabled={isDisabledInput}
              getAriaLabel={() => "Type Influencers Campaign"}
              valueLabelDisplay="auto"
              getAriaValueText={textNumberCompactFormat}
              step={1}
              marks={marksAge}
              min={18}
              max={60}
              valueLabelFormat={textNumberCompactFormat}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SelectInfluencerCategories
          disabled={isDisabledInput}
          name="categoriesInfluencers"
          label={CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};

export default InfluencersInformation;
