import React, { useEffect, useState } from "react";
import {
  ERROR,
  FIELD_REQUIRED,
  PASSWORDS_NOT_MATCH,
  PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER,
  PASSWORD_FORMAT_DESCRIPTION,
  PASSWORD_FORMAT_SUGGESTION1,
  PASSWORD_FORMAT_SUGGESTION2,
  PASSWORD_FORMAT_SUGGESTION3,
  PASSWORD_FORMAT_SUGGESTION4,
  PASSWORD_INSECURE,
  PASSWORD_NEW_PASSWORD_PLACEHOLDER,
  RESET_PASSWORD,
  RESET_PASSWORD_GET_TOKEN_FAILED,
} from "../../../locales/keysTranslations";
import { REGEX_PASSWORD } from "../../../utils/regexsValidation";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "../../Common/Fields/TextField";
import { useForm } from "react-hook-form";
import { useDispatchApp } from "../../../lib/redux";
import { useTranslationApp } from "../../../lib/i18next";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import { startResetPassword } from "../../../actions/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SimpleAlert } from "../../../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../../../utils/constants";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";

const ResetPasswordForm = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatchApp();

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const token = params.get("token");

  const { t } = useTranslationApp();
  const { handleSubmit, watch, control, formState } = useForm({
    defaultValues: {
      newPassword: "",
      repeatPassword: "",
    },
    mode: "onChange",
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  useEffect(() => {
    if (!token) {
      SimpleAlert({
        title: t(ERROR),
        text: t(RESET_PASSWORD_GET_TOKEN_FAILED),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      navigate("/login");
      return;
    }
    // eslint-disable-next-line
  }, []);

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setIsFetching(true);
    const response = await dispatch(startResetPassword({ ...data, token }));
    setIsFetching(false);
    if (response) {
      navigate("/login");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: 3,
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", lg: "30%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {t(PASSWORD_FORMAT_DESCRIPTION)}
          </Typography>
          <Typography variant="body2">
            {`* ${t(PASSWORD_FORMAT_SUGGESTION1)}`}
          </Typography>
          <Typography variant="body2">
            {`* ${t(PASSWORD_FORMAT_SUGGESTION2)}`}
          </Typography>
          <Typography variant="body2">
            {`* ${t(PASSWORD_FORMAT_SUGGESTION3)}`}
          </Typography>
          <Typography variant="body2">
            {`* ${t(PASSWORD_FORMAT_SUGGESTION4)}`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ maxWidth: 350 }}>
        <TextField
          label={t(PASSWORD_NEW_PASSWORD_PLACEHOLDER)}
          type={showPassword ? "text" : "password"}
          name="newPassword"
          variant="filled"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: {
              value: (value) => {
                if (!REGEX_PASSWORD.test(value)) {
                  return t(PASSWORD_INSECURE);
                }
                if (value !== watch("repeatPassword")) {
                  return t(PASSWORDS_NOT_MATCH);
                }
              },
            },
          }}
        />
        <TextField
          label={t(PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER)}
          type={showPassword ? "text" : "password"}
          name="repeatPassword"
          variant="filled"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: {
              value: (value) => {
                if (!REGEX_PASSWORD.test(value)) {
                  return t(PASSWORD_INSECURE);
                }
                if (value !== watch("newPassword")) {
                  return t(PASSWORDS_NOT_MATCH);
                }
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <LoadingButton color="secondary" loading={isFetching}>
          {t(RESET_PASSWORD)}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ResetPasswordForm;
