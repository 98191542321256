import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { REGEX_EMAIL } from "../../../utils/regexsValidation";
import { ChatContext } from "../../../Contexts/ChatContext";
import {
  CHAT_BOT_USER_ID,
  IMAGE_FORMATS,
  PRE_REGISTERED_INFLUENCER_CHAT_PROCESS,
  ROLES_DASHBOARD,
  VIDEO_FORMATS,
} from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Loader/Loader";
import {
  BUTTON_SEND,
  BUTTON_SEND_FILE,
  BUTTON_SEND_PAYMENT,
  CHAT_EMAIL_NO_PERMITTED,
  CHAT_WRITE_MESSAGE_HERE,
  CHAT_WRITE_QUESTION_HERE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { setCurrentWriteMessage } from "../../../actions/chat";
import {
  getInfluencerHasPreRegisteredProcess,
  getInfluencerRedux,
  getRole,
} from "../../../actions/getters";
import Button from "../Buttons/Button";
import PaidIcon from "@mui/icons-material/Paid";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import SendIcon from "@mui/icons-material/Send";
import { BOX_SHADOW_ACTIONS_MODAL } from "../../../utils/colors";
import { InputBase } from "@mui/material";
import LoadingButton from "../Buttons/LoadingButton";

const ChatMessageInput = ({
  value = "",
  onSendMessage,
  disabled = false,
  onOpenModalPayment,
  data,
}) => {
  const { isUploadingFile, currentWriteMessage } = useContext(ChatContext);

  const [message, setMessage] = useState(value);
  const [isSending, setIsSending] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const influencer = dispatch(getInfluencerRedux(data.userID));
  const preRegisteredInfluencersProcess = useSelectorApp(
    (state) => state?.influencers?.preRegisteredInfluencersProcess
  );
  const hasProcessSendMessage = dispatch(
    getInfluencerHasPreRegisteredProcess({
      mainAccountName: influencer.mainAccountName,
      mainPlatform: influencer.mainPlatform,
      type: PRE_REGISTERED_INFLUENCER_CHAT_PROCESS,
      preRegisteredInfluencersProcess,
    })
  );

  const role = dispatch(getRole());

  useEffect(() => {
    setMessage(currentWriteMessage);
    // eslint-disable-next-line
  }, [data]);

  const onChangeMessage = (message) => {
    setMessage(message);
    dispatch(setCurrentWriteMessage(message));
  };

  const onValidateMessage = (message) => {
    if (REGEX_EMAIL.test(message) && !ROLES_DASHBOARD.includes(role)) {
      setError(t(CHAT_EMAIL_NO_PERMITTED));
      return false;
    }
    setError(null);
    return true;
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onClickSendMessage = async () => {
    if (!message && file === null) return false;
    if (error !== null) return false;

    let currentMessage = message;
    let currentFile = file;
    onChangeMessage("");
    setFile(null);

    const messageFormatted = currentMessage.replace("&nbsp;", " ").trim();

    const response = await onSendMessage(messageFormatted, currentFile);
    if (!response) {
      onChangeMessage(currentMessage);
      setFile(currentFile);
    }
  };

  return (
    <Box
      sx={{
        boxShadow: BOX_SHADOW_ACTIONS_MODAL,
        mr: "-12px",
        px: 2,
        pt: 2,
        zIndex: 10,
      }}
      onKeyDown={(e) => {
        if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.shiftKey) {
          e.preventDefault();
          onClickSendMessage();
        }
      }}
    >
      <input
        type="file"
        hidden
        id="attachButton"
        onChange={onChangeFile}
        accept={[...IMAGE_FORMATS, ...VIDEO_FORMATS, "pdf"].map((item) => {
          if (item === "pdf") return `application/${item},`;
          if (VIDEO_FORMATS.includes(item)) return `video/${item},`;
          return `image/${item},`;
        })}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <InputBase
          value={message}
          disabled={disabled || isUploadingFile}
          placeholder={
            data.userID !== CHAT_BOT_USER_ID
              ? t(CHAT_WRITE_MESSAGE_HERE)
              : t(CHAT_WRITE_QUESTION_HERE)
          }
          multiline
          minRows={3}
          maxRows={4}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            padding: "8px 16px",
          }}
          onChange={(e) => {
            const value = e.target.value;
            onValidateMessage(value);
            onChangeMessage(value);
          }}
        />
        <LoadingButton
          loading={isSending}
          color="secondary"
          disabled={
            error !== null ||
            (message === "" && file === null) ||
            isUploadingFile ||
            disabled ||
            Boolean(hasProcessSendMessage)
          }
          onClick={async (e) => {
            setIsSending(true);
            await onClickSendMessage(e);
            setIsSending(false);
          }}
          sx={{
            width: {
              xs: "100%",
              sm: "auto",
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "white !important",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <SendIcon sx={{ mr: 1 }} />
          {t(BUTTON_SEND)}
        </LoadingButton>
      </Box>
      {error && (
        <Box
          sx={{
            mx: "22px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mb: 1,
          }}
        >
          <Typography
            variant="body2"
            color="error"
            align="center"
            sx={{ mb: "4px" }}
          >
            {error}
          </Typography>
        </Box>
      )}
      {file && (
        <Box
          sx={{
            px: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: "2px",
            pb: "-2px",
            mb: 1,
          }}
        >
          {isUploadingFile ? (
            <Loader size={20} hasMarginTop={false} colorCustom="white" />
          ) : (
            <>
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ mb: "2px", color: "text.primary" }}
                >
                  {file.name}
                </Typography>
              </Box>
              <IconButton sx={{ p: 0 }} onClick={() => setFile(null)}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Box>
      )}
      {data?.isInfluencer && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            pt: "4px",
            pb: 1,
          }}
        >
          <Button
            color="primary"
            sx={{
              maxWidth: { xs: "100%", md: "50%" },
              width: "100%",
            }}
            onClick={onOpenModalPayment}
          >
            <PaidIcon sx={{ mr: 1 }} />
            {t(BUTTON_SEND_PAYMENT)}
          </Button>
          <Button
            color="primary"
            sx={{
              maxWidth: { xs: "100%", md: "50%" },
              width: "100%",
            }}
            onClick={async () => {
              document.getElementById("attachButton")?.click();
            }}
          >
            <UploadFileRoundedIcon sx={{ mr: 1 }} />
            {t(BUTTON_SEND_FILE)}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ChatMessageInput;
