import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Accordion from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FieldAccompanimentType from "./FieldAccompanimentType";
import FieldAccompaniments from "./FieldAccompaniments";
import Button from "../../../Common/Buttons/Button";
import { reorder } from "../../../../utils/draggable";
import { OPERATOR_ROLE } from "../../../../utils/constants";
import { MenuContext } from "../../../../Contexts/MenuContext";
import {
  ADD_PRODUCT_CATEGORY_OPTION,
  ADD_PRODUCT_OPTION,
  IS_MANDATORY_CATEGORY_OPTION,
  NEW_PRODUCT_CATEGORY_OPTION,
} from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useDispatchApp } from "../../../../lib/redux";
import { changeShowNextButton } from "../../../../actions/onboarding";
import {
  BUTTON_DELETE,
  BUTTON_EDIT,
  MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION,
  MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER,
  MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER,
  MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import Switch from "../../../Common/Forms/Switchs/Switch";

const AccompanimentsType = ({ data, userRole }) => {
  const {
    // ACCOMPANIMENTS TYPE
    onCreateAccompanimentType,
    onEditAccompanimentType,
    onDeleteAccompanimentType,
    onStartCopyAccompanimentType,
    onChangeOrderAccompanimentsType,
    onChangeIsMandatory,

    // ACCOMPANIMENTS
    onCreateAccompaniment,
    onEditAccompaniment,
    onDeleteAccompaniment,
    onChangeOrderAccompaniments,
    onChangeStatusAccompaniment,
  } = useContext(MenuContext);

  const { isOpen, setCurrentStep, currentStep, steps } = useTour();

  const [openCollapseAccompaniment, setOpenCollapseAccompaniment] =
    useState(false);

  const [isCreatingAccompanimentType, setIsCreatingAccompanimentType] =
    useState(false);
  const [isCreatingAccompaniment, setIsCreatingAccompaniment] = useState(false);

  const [modeFieldAccompanimentType, setModeFieldAccompanimentType] =
    useState(null);
  const [modeFieldAccompaniment, setModeFieldAccompaniment] = useState(null);

  const [accompanimentTypeSelect, setAccompanimentTypeSelect] = useState(null);
  const [accompanimentSelect, setAccompanimentSelect] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const onClick = ({ event, accompanimentType, accompaniment }) => {
    setAnchorEl(event.currentTarget);
    accompanimentType && setAccompanimentTypeSelect(accompanimentType);
    accompaniment && setAccompanimentSelect(accompaniment);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onChangeIsMandatoryAccompaniment = (accompanimentType, isMandatory) => {
    onChangeIsMandatory(accompanimentType, isMandatory);
  };

  const onClickAccompaniment = (panel) => (event, newExpanded) => {
    if (
      openCollapseAccompaniment !== panel &&
      steps[currentStep].actionID === "showOption" &&
      isOpen
    ) {
      setCurrentStep(currentStep + 1);
    }
    setOpenCollapseAccompaniment(
      openCollapseAccompaniment !== panel ? panel : false
    );
  };

  const onDragEndAccompanimentsType = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(data, source.index, destination.index);
    onChangeOrderAccompanimentsType(newItems);
  };

  const onDragEndAccompaniments = ({ destination, source }, accompaniments) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(
      data[accompaniments].options,
      source.index,
      destination.index
    );
    onChangeOrderAccompaniments(accompaniments, newItems);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 1 }}>
        {t(MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE)}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {t(MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION)}
      </Typography>
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          color: "background.paperContrastText",
        }}
        component="div"
        aria-labelledby="accompaniments-list"
      >
        <Button
          type="button"
          startIcon={<AddIcon />}
          onClick={() => {
            setModeFieldAccompanimentType("create");
            setIsCreatingAccompanimentType(true);
            if (isOpen) {
              setCurrentStep(currentStep + 1);
              dispatch(changeShowNextButton(true));
            }
          }}
          hasShadow={false}
          variant="text"
          disabled={userRole === OPERATOR_ROLE}
          dataTour={ADD_PRODUCT_CATEGORY_OPTION}
          sx={{ my: 1, color: "text.primary" }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {t(MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER)}
          </Typography>
        </Button>
        {isCreatingAccompanimentType &&
          modeFieldAccompanimentType === "create" && (
            <FieldAccompanimentType
              onCreateAccompanimentType={(accompaniment) => {
                onCreateAccompanimentType(accompaniment);
                setIsCreatingAccompanimentType(false);
              }}
              mode="create"
              onCopyAccompanimentType={() => {
                onStartCopyAccompanimentType();
                setIsCreatingAccompanimentType(false);
              }}
              onCancel={() => setIsCreatingAccompanimentType(false)}
            />
          )}
        <Box>
          <DragDropContext onDragEnd={onDragEndAccompanimentsType}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data
                    ? Object.keys(data)?.map((accompaniments, index) => (
                        <Draggable
                          draggableId={index.toString()}
                          index={index}
                          key={index}
                        >
                          {(provided) => {
                            if (
                              isCreatingAccompanimentType &&
                              accompanimentTypeSelect === accompaniments &&
                              modeFieldAccompanimentType === "edit"
                            ) {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                >
                                  <FieldAccompanimentType
                                    onEditAccompanimentType={(data) => {
                                      onEditAccompanimentType(
                                        accompaniments,
                                        data
                                      );
                                      setModeFieldAccompanimentType(null);
                                      setAccompanimentTypeSelect(null);
                                      setIsCreatingAccompanimentType(false);
                                    }}
                                    data={data[accompaniments]}
                                    mode="edit"
                                    key={index}
                                    onCancel={() =>
                                      setIsCreatingAccompanimentType(false)
                                    }
                                  />
                                </div>
                              );
                            }
                            return (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={index}
                                data-tour={
                                  index === 0 && NEW_PRODUCT_CATEGORY_OPTION
                                }
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems:
                                    openCollapseAccompaniment ===
                                    `accompaniments-type-${index}`
                                      ? "flex-start"
                                      : "center",
                                }}
                              >
                                <Accordion
                                  disableGutters={true}
                                  elevation={0}
                                  expanded={
                                    openCollapseAccompaniment ===
                                      `accompaniments-type-${index}` ||
                                    (isOpen && index === 0)
                                  }
                                  onChange={onClickAccompaniment(
                                    `accompaniments-type-${index}`
                                  )}
                                  sx={{ width: "100%" }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls={`accompaniments-type-${index}-content`}
                                    id={`accompaniments-type-${index}-header`}
                                    sx={{
                                      minHeight: "0 !important",
                                      my: 0,
                                      px: 4,
                                    }}
                                  >
                                    <Typography
                                      sx={{ width: "33%", flexShrink: 0 }}
                                    >
                                      {data[accompaniments].name}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Switch
                                      dataTour={IS_MANDATORY_CATEGORY_OPTION}
                                      labelPlacement="end"
                                      labelActive={
                                        MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER
                                      }
                                      labelDeactivate={
                                        MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER
                                      }
                                      isActive={
                                        data[accompaniments].isMandatory
                                      }
                                      disabled={userRole === OPERATOR_ROLE}
                                      onChangeIsActive={(checked) =>
                                        onChangeIsMandatoryAccompaniment(
                                          accompaniments,
                                          checked
                                        )
                                      }
                                    />

                                    <Button
                                      type="button"
                                      hasShadow={false}
                                      variant="text"
                                      startIcon={<AddIcon />}
                                      onClick={() => {
                                        setModeFieldAccompaniment("create");
                                        setIsCreatingAccompaniment(true);
                                        if (isOpen) {
                                          setCurrentStep(currentStep + 1);
                                        }
                                      }}
                                      sx={{
                                        my: 1,
                                        color: "text.primary",
                                        width: "fit-content",
                                      }}
                                      disabled={userRole === OPERATOR_ROLE}
                                      dataTour={ADD_PRODUCT_OPTION}
                                    >
                                      <Typography
                                        variant="body1"
                                        sx={{ fontWeight: 600 }}
                                      >
                                        {t(
                                          MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER
                                        )}
                                      </Typography>
                                    </Button>
                                    {isCreatingAccompaniment &&
                                      modeFieldAccompaniment === "create" && (
                                        <FieldAccompaniments
                                          onCreateAccompaniment={(
                                            accompaniment
                                          ) => {
                                            onCreateAccompaniment(
                                              accompaniment,
                                              accompaniments
                                            );
                                            setIsCreatingAccompaniment(false);
                                            setModeFieldAccompaniment(null);
                                            setAccompanimentSelect(null);
                                          }}
                                          mode="create"
                                          onCancel={() =>
                                            setIsCreatingAccompaniment(false)
                                          }
                                        />
                                      )}
                                    {data[accompaniments].options && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          mx: 3,
                                        }}
                                      >
                                        <DragDropContext
                                          onDragEnd={(e) =>
                                            onDragEndAccompaniments(
                                              e,
                                              accompaniments
                                            )
                                          }
                                        >
                                          <Droppable droppableId="droppable-listt">
                                            {(provided) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                              >
                                                {Object.keys(
                                                  data[accompaniments]?.options
                                                )?.map((option, index) => (
                                                  <Draggable
                                                    draggableId={index.toString()}
                                                    index={index}
                                                    key={index}
                                                  >
                                                    {(provided) => {
                                                      if (
                                                        isCreatingAccompaniment &&
                                                        accompanimentSelect ===
                                                          option &&
                                                        modeFieldAccompaniment ===
                                                          "edit"
                                                      ) {
                                                        return (
                                                          <div
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            key={index}
                                                          >
                                                            <FieldAccompaniments
                                                              onEditAccompaniment={(
                                                                accompaniment
                                                              ) => {
                                                                onEditAccompaniment(
                                                                  accompaniments,
                                                                  option,
                                                                  accompaniment,
                                                                  index
                                                                );
                                                                setIsCreatingAccompaniment(
                                                                  false
                                                                );
                                                                setModeFieldAccompaniment(
                                                                  null
                                                                );
                                                                setAccompanimentSelect(
                                                                  null
                                                                );
                                                              }}
                                                              mode="edit"
                                                              data={
                                                                data[
                                                                  accompaniments
                                                                ].options[
                                                                  option
                                                                ]
                                                              }
                                                              key={index}
                                                              onCancel={() =>
                                                                setIsCreatingAccompaniment(
                                                                  false
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        );
                                                      }
                                                      return (
                                                        <Box
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          key={index}
                                                          sx={{
                                                            display: "flex",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <Box
                                                            sx={{
                                                              width: "100%",
                                                              display: "flex",
                                                              p: 1,
                                                              alignItems:
                                                                "center",
                                                              "&:hover": {
                                                                backgroundColor:
                                                                  "background.paperSecondary",
                                                              },
                                                            }}
                                                          >
                                                            <Typography
                                                              variant="body1"
                                                              align="left"
                                                              sx={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              {
                                                                data[
                                                                  accompaniments
                                                                ].options[
                                                                  option
                                                                ].name
                                                              }
                                                            </Typography>
                                                          </Box>
                                                          <IconButton
                                                            edge="end"
                                                            aria-label="more-options"
                                                            sx={{ mt: "3px" }}
                                                            disabled={
                                                              userRole ===
                                                              OPERATOR_ROLE
                                                            }
                                                            onClick={(
                                                              event
                                                            ) => {
                                                              setAccompanimentTypeSelect(
                                                                null
                                                              );
                                                              setAccompanimentSelect(
                                                                null
                                                              );
                                                              onClick({
                                                                event,
                                                                accompanimentType:
                                                                  accompaniments,
                                                                accompaniment:
                                                                  option,
                                                              });
                                                            }}
                                                          >
                                                            <MoreVertIcon />
                                                          </IconButton>
                                                          <IconButton
                                                            edge="end"
                                                            aria-label="desactive-accompaniment"
                                                            sx={{ mt: "3px" }}
                                                            onClick={() =>
                                                              onChangeStatusAccompaniment(
                                                                accompaniments,
                                                                option,
                                                                !data[
                                                                  accompaniments
                                                                ].options[
                                                                  option
                                                                ].isActive ??
                                                                  false
                                                              )
                                                            }
                                                          >
                                                            {data[
                                                              accompaniments
                                                            ].options[option]
                                                              .isActive ??
                                                            true ? (
                                                              <VisibilityIcon />
                                                            ) : (
                                                              <VisibilityOffIcon color="disabled" />
                                                            )}
                                                          </IconButton>
                                                        </Box>
                                                      );
                                                    }}
                                                  </Draggable>
                                                ))}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Box>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                                <IconButton
                                  edge="end"
                                  aria-label="more-options"
                                  sx={{ mt: "3px" }}
                                  disabled={userRole === OPERATOR_ROLE}
                                  onClick={(event) => {
                                    setAccompanimentTypeSelect(null);
                                    setAccompanimentSelect(null);
                                    onClick({
                                      event,
                                      accompanimentType: accompaniments,
                                    });
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>
                            );
                          }}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "more-options-button",
        }}
      >
        <MenuItem
          onClick={() => {
            if (accompanimentSelect === null) {
              setModeFieldAccompanimentType("edit");
              setIsCreatingAccompanimentType(true);
            } else {
              setModeFieldAccompaniment("edit");
              setIsCreatingAccompaniment(true);
            }
            onClose();
          }}
        >
          {t(BUTTON_EDIT)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (accompanimentSelect === null) {
              onDeleteAccompanimentType(accompanimentTypeSelect);
              setAccompanimentTypeSelect(null);
              setAccompanimentSelect(null);
            } else {
              onDeleteAccompaniment(
                accompanimentTypeSelect,
                accompanimentSelect
              );
              setAccompanimentTypeSelect(null);
              setAccompanimentSelect(null);
            }
            onClose();
          }}
        >
          {t(BUTTON_DELETE)}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AccompanimentsType;
