import React from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSelectorApp } from "../../../lib/redux";
import {
  DUMMY_AMBASSADOR,
  PAGE_AMBASSADORS_TABLE,
  PAGE_AMBASSADORS_TABLE_ACTIONS,
  PAGE_AMBASSADORS_TABLE_INVITATION_VALUE,
  PAGE_AMBASSADORS_TABLE_LAST_COLLABORATION,
  PAGE_AMBASSADORS_TABLE_NAME,
  PAGE_AMBASSADORS_TABLE_ROW,
} from "../../../onboarding/stepsSelectors";
import InitChatButton from "../../Common/Chat/InitChatButton";
import { useTranslationApp } from "../../../lib/i18next";
import {
  AMBASSADORS_TABLE_ARIAL_LABEL,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_INVITATION,
  LAST_COLLABORATION,
  COLUMN_NAME_ACTION,
  VALUE_WITH_CURRENCY,
  EMPTY_AMBASSADORS,
} from "../../../locales/keysTranslations";
import { useLocale } from "../../../Contexts/LocaleContext";
import InfluencerLastCollaboration from "../../Common/Influencer/InfluencerLastCollaboration";

const AmbassadorsTable = ({
  data,
  loading,
  rowsPerPage,
  page,
  onPageChange,
  onSelectAmbassador,
  onOpenChatAmbassador,
  onDeleteAmbassador,
}) => {
  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].creationTime - data[a].creationTime
  );

  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const influencers = useSelectorApp((state) => state.influencers.influencers);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={loading}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        emptyText={EMPTY_AMBASSADORS}
        ariaLabel={t(AMBASSADORS_TABLE_ARIAL_LABEL)}
      >
        <TableHead>
          <TableRow data-tour={PAGE_AMBASSADORS_TABLE}>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_AMBASSADORS_TABLE_NAME}
            >
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_AMBASSADORS_TABLE_INVITATION_VALUE}
            >
              {t(COLUMN_NAME_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_AMBASSADORS_TABLE_LAST_COLLABORATION}
            >
              {t(LAST_COLLABORATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_AMBASSADORS_TABLE_ACTIONS}
            >
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            const influencer = influencers[data[key].userID];
            return (
              <TableRow
                key={key}
                data-tour={
                  key === DUMMY_AMBASSADOR && PAGE_AMBASSADORS_TABLE_ROW
                }
              >
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{
                    px: 1,
                    minWidth: 134,
                  }}
                >
                  <NameInfluencerCell
                    userID={data[key].userID}
                    data={influencer}
                    name={influencer.mainAccountName}
                    showButton={false}
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {t(VALUE_WITH_CURRENCY, {
                    value: data[key].value,
                    currency: LOCALE.currency,
                  })}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <InfluencerLastCollaboration userID={data[key].userID} />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <InitChatButton
                    type="icon"
                    onClick={() => onOpenChatAmbassador(key)}
                  />
                  <IconButton
                    color="text.primary"
                    onClick={() => onSelectAmbassador(key)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="text.primary"
                    onClick={() => onDeleteAmbassador(key)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default AmbassadorsTable;
