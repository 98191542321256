import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TooltipModal from "../../Common/Tooltips/TooltipModal";
import ContentAnalytics from "../../Common/Tooltips/Content/ContentAnalytics";
import { PUBLICATION_ANALYTICS_SECTION } from "../../../onboarding/stepsSelectors";
import { numberCurrencyFormat } from "../../../utils/numberFormats";
import _ from "lodash";
import {
  ENGAGEMENT_INTERACTIONS,
  ENGAGEMENT_KEYS,
} from "../../../utils/constants";
import GeneralStatisticPoint from "../../Owner/Statistics/General/GeneralStatisticPoint";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ACCOUNTS_REACHED,
  ANALYTICS,
  ANALYTICS_EXPLANATION_TITLE,
  COMMENTS,
  EMPTY_ANALYTICS,
  LIKES,
  REPLIES,
  SAVES,
  SHARES,
  STICKERS_TAPS,
} from "../../../locales/keysTranslations";

const AnalyticsSection = ({ order, isMobile }) => {
  const { t } = useTranslationApp();
  const { analyticsData } = order;
  const values = {
    [ACCOUNTS_REACHED]: numberCurrencyFormat(
      analyticsData.accountsReached || 0
    ),
    [LIKES]: numberCurrencyFormat(analyticsData.likes || 0),
    [COMMENTS]: numberCurrencyFormat(analyticsData.comments || 0),
    [SHARES]: numberCurrencyFormat(analyticsData.shares || 0),
    [SAVES]: numberCurrencyFormat(analyticsData.saves || 0),
    [REPLIES]: numberCurrencyFormat(analyticsData.replies || 0),
    [STICKERS_TAPS]: numberCurrencyFormat(analyticsData.stickerTaps || 0),
  };

  if (_.isEmpty(order.analyticsData)) return null;

  const hasAnalytics = Object.keys(order.analyticsData || {})?.some(
    (analytic) =>
      analyticsData[analytic] > 0 && ENGAGEMENT_KEYS.includes(analytic)
  );

  return (
    <Box
      sx={{
        width: "auto",
        height: "100%",
        mb: 2,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}
        data-tour={PUBLICATION_ANALYTICS_SECTION}
      >
        <Typography variant={isMobile ? "h4" : "h5"} sx={{ fontWeight: 600 }}>
          {t(ANALYTICS)}
        </Typography>
        <TooltipModal
          title={t(ANALYTICS_EXPLANATION_TITLE)}
          content={<ContentAnalytics />}
        />
      </Box>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        {hasAnalytics ? (
          <>
            {Object.keys(analyticsData).map((key) => {
              if (!ENGAGEMENT_KEYS.includes(key)) return null;
              if (analyticsData[key] === 0) return null;
              const interaction = ENGAGEMENT_INTERACTIONS.find(
                (interaction) => interaction.key === key
              );
              return (
                <Grid
                  key={interaction.label}
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: {
                      xs: "center",
                      sm: "flex-start",
                    },
                  }}
                >
                  <GeneralStatisticPoint
                    sx={{
                      width: "100%",
                      alignItems: {
                        display: "flex",
                        flexDirection: "column",
                        xs: "center",
                        sm: "flex-start",
                      },
                    }}
                    Icon={interaction.icon}
                    title={t(interaction.label)}
                    value={values[interaction.label]}
                  />
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid item>
            <Typography variant={isMobile ? "h5" : "h6"}>
              {t(EMPTY_ANALYTICS)}
            </Typography>
            {/* <AnalyticsImages order={order} isMobile={isMobile} /> */}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AnalyticsSection;
