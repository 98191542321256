import { useForm, useWatch } from "react-hook-form";
import {
  COUNTRIES_WITH_ONLY_ONE_LOCATION,
  INITIAL_STATE_ENGAGEMENT,
  INITIAL_STATE_FOLLOWERS,
  SEARCH_INFLUENCERS_TOPICS_OPTION,
  SEARCH_INFLUENCERS_USERNAME_OPTION,
} from "../utils/constants";
import { useEffect, useRef } from "react";
import { differenceBetweenObjects } from "../utils/objects";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { getInfluencerIsFavoriteRedux } from "../actions/getters";
import {
  checkArrayIntersection,
  filterEngagement,
  filterFollowers,
  normalizeString,
} from "../utils/formats";
import {
  getAudienceLocationsFormatted,
  getLocationStringPhyllo,
} from "../services/phyllo";

const orderWatched = [
  "favoritesCategorySelected",
  "topRatedCategorySelected",
  "categoriesSelected",
  "bodyBuild",
  "clothingStyle",
  "distinguishingFeatures",
  "contentStyle",
  "citySelected",
  "audienceLocations",
  "genderSelected",
  "rangeFollowers",
  "rangeEngagement",
  "searchType",
  "topicsSelected",
  "searchText",
  "newCategorySelected",
];

const convertObjectFiltersWatched = (watchedFilters) => {
  const watchedFiltersObject = {};
  watchedFilters.forEach((value, index) => {
    watchedFiltersObject[orderWatched[index]] = value;
  });
  return watchedFiltersObject;
};

const useCreatorsFilters = () => {
  const INITIAL_STATE = {
    searchText: "",
    topicsSelected: [],
    searchType: SEARCH_INFLUENCERS_TOPICS_OPTION,
    newCategorySelected: false,
    favoritesCategorySelected: false,
    topRatedCategorySelected: false,
    categoriesSelected: [],
    audienceLocations: [],
    bodyBuild: "",
    clothingStyle: "",
    distinguishingFeatures: "",
    contentStyle: "",
    citySelected: "",
    genderSelected: "",
    rangeFollowers: INITIAL_STATE_FOLLOWERS,
    rangeEngagement: INITIAL_STATE_ENGAGEMENT,
  };
  const { control, watch, setValue, reset } = useForm({
    defaultValues: INITIAL_STATE,
  });

  const filters = {
    searchText: watch("searchText"),
    topicsSelected: watch("topicsSelected"),
    searchType: watch("searchType"),
    newCategorySelected: watch("newCategorySelected"),
    favoritesCategorySelected: watch("favoritesCategorySelected"),
    topRatedCategorySelected: watch("topRatedCategorySelected"),
    categoriesSelected: watch("categoriesSelected"),
    bodyBuild: watch("bodyBuild"),
    clothingStyle: watch("clothingStyle"),
    distinguishingFeatures: watch("distinguishingFeatures"),
    contentStyle: watch("contentStyle"),
    citySelected: watch("citySelected"),
    audienceLocations: watch("audienceLocations"),
    genderSelected: watch("genderSelected"),
    rangeFollowers: watch("rangeFollowers"),
    rangeEngagement: watch("rangeEngagement"),
  };

  const dispatch = useDispatchApp();
  const influencers = useSelectorApp((state) => state.influencers.influencers);

  const watchedFilters = useWatch({
    control,
    name: orderWatched,
  });

  const previousFiltersRef = useRef(watchedFilters);

  const setFilters = (key, value) => {
    if (key === "newCategorySelected") {
      if (filters.newCategorySelected) {
        setValue("newCategorySelected", false);
      } else {
        onReset({
          valuesExcept: ["searchType"],
        });
        setValue("newCategorySelected", value);
      }
      return;
    }
    if (key === "favoritesCategorySelected") {
      if (filters.favoritesCategorySelected) {
        setValue("favoritesCategorySelected", false);
      } else {
        setValue("favoritesCategorySelected", value);
        setValue("newCategorySelected", false);
        setValue("topRatedCategorySelected", false);
      }
      return;
    }
    if (key === "topRatedCategorySelected") {
      if (filters.topRatedCategorySelected) {
        setValue("topRatedCategorySelected", false);
      } else {
        setValue("topRatedCategorySelected", value);
        setValue("newCategorySelected", false);
        setValue("favoritesCategorySelected", false);
      }
      return;
    }

    if (key === "searchType") {
      if (
        value === SEARCH_INFLUENCERS_TOPICS_OPTION &&
        filters?.topicsSelected?.length > 0
      ) {
        setValue("topicsSelected", []);
      }
      if (value === SEARCH_INFLUENCERS_USERNAME_OPTION) {
        setValue("searchText", "");
      }
    }
    setValue(key, value);
  };

  const onReset = (props = {}) => {
    const { values = [], valuesExcept = [] } = props;
    if (values.length === 0 && valuesExcept.length === 0) {
      reset();
      return;
    }

    if (values.length > 0 && valuesExcept.length > 0) {
      throw new Error(
        "You can't use both values and valuesExcept at the same time"
      );
    }

    const currentFilters = convertObjectFiltersWatched(watchedFilters);

    Object.keys(currentFilters).forEach((key) => {
      if (values.length > 0 && values.includes(key)) {
        setValue(key, INITIAL_STATE[key]);
      } else if (valuesExcept.length > 0 && !valuesExcept.includes(key)) {
        setValue(key, INITIAL_STATE[key]);
      }
    });
  };

  const onInfluencerPassFilter = ({
    influencer = {},
    userID,
    filters: filtersPassed,
  }) => {
    if (!filters) {
      filtersPassed = filters;
    }

    const {
      searchType,
      searchText,
      newCategorySelected,
      favoritesCategorySelected,
      topRatedCategorySelected,
      categoriesSelected,
      bodyBuild,
      clothingStyle,
      distinguishingFeatures,
      contentStyle,
      citySelected,
      audienceLocations,
      genderSelected,
      rangeFollowers,
      rangeEngagement,
    } = filtersPassed;

    if (newCategorySelected) {
      const isNew = influencers[userID];
      if (isNew) return true;
    }

    if (favoritesCategorySelected) {
      const isFavorite = dispatch(getInfluencerIsFavoriteRedux(userID));
      if (!isFavorite) return false;
    }

    if (
      (topRatedCategorySelected && influencer?.score < 4) ||
      influencer?.score > 5
    )
      return false;

    if (
      searchType === SEARCH_INFLUENCERS_USERNAME_OPTION &&
      searchText &&
      searchText?.length > 3 &&
      !influencer?.mainAccountName
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    )
      return false;

    if (
      !COUNTRIES_WITH_ONLY_ONE_LOCATION.includes(influencer.signUpCountry) &&
      citySelected
    ) {
      if (
        !influencer.isExternal &&
        !normalizeString(influencer?.signUpCity).includes(
          normalizeString(citySelected)
        )
      )
        return false;

      //city splitted in case it comes from phyllo
      const citySelectedNormalized = normalizeString(
        citySelected?.split(",")?.[0]?.trim()
      );
      if (
        influencer.isExternal &&
        !normalizeString(
          getLocationStringPhyllo(influencer.creatorLocation)
        ).includes(citySelectedNormalized)
      )
        return false;
    }

    if (
      audienceLocations?.length > 0 &&
      !influencer.isExternal &&
      !checkArrayIntersection(
        influencer?.mainAudienceLocation,
        getAudienceLocationsFormatted(audienceLocations)
      )
    )
      return false;

    if (
      bodyBuild &&
      influencer?.bodyBuild &&
      !influencer?.bodyBuild?.includes(bodyBuild)
    ) {
      return false;
    }

    if (
      clothingStyle &&
      influencer?.clothingStyle &&
      !influencer?.clothingStyle?.includes(clothingStyle)
    ) {
      return false;
    }

    if (
      distinguishingFeatures &&
      influencer?.distinguishingFeatures &&
      !influencer?.distinguishingFeatures?.includes(distinguishingFeatures)
    ) {
      return false;
    }

    if (
      contentStyle &&
      influencer?.contentStyle &&
      !influencer?.contentStyle?.includes(contentStyle)
    ) {
      return false;
    }

    if (genderSelected && influencer?.gender !== genderSelected) return false;

    if (
      categoriesSelected?.length > 0 &&
      !checkArrayIntersection(influencer?.categories, categoriesSelected)
    )
      return false;

    if (!filterFollowers(influencer?.followers, rangeFollowers)) return false;

    if (!filterEngagement(influencer?.engagement, rangeEngagement))
      return false;

    return true;
  };

  useEffect(() => {
    const previousFilters = previousFiltersRef.current;
    const currentFilters = watchedFilters;

    const difference = differenceBetweenObjects({
      obj1: convertObjectFiltersWatched(currentFilters),
      obj2: convertObjectFiltersWatched(previousFilters),
    });

    if (difference.includes("searchText") && filters.searchText?.length === 1) {
      onReset({
        valuesExcept: ["searchText", "searchType"],
      });
    }
    if (
      difference.length > 0 &&
      !difference.includes("searchText") &&
      filters.searchText?.length > 0
    ) {
      setValue("searchText", "");
    }
    if (difference.includes("newCategorySelected")) {
      onReset({
        valuesExcept: ["newCategorySelected", "searchType"],
      });
    }

    previousFiltersRef.current = currentFilters;
    // eslint-disable-next-line
  }, [watchedFilters]);

  return {
    control,
    filters,
    onChangeFilters: setFilters,
    onReset,
    onInfluencerPassFilter,
  };
};

export default useCreatorsFilters;
