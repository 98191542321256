import React, { useContext, useState } from "react";
import { ConversationHeader, Avatar } from "@chatscope/chat-ui-kit-react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ROLES_DASHBOARD } from "../../../utils/constants";
import { ChatContext } from "../../../Contexts/ChatContext";
import { BUTTON_VIEW_DETAILS } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { getRole } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinkText from "../Texts/LinkText";

const ChatConversationHeader = ({
  data,
  onChangeShowChat,
  onOpenModalInfluencer,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatchApp();

  const role = dispatch(getRole());
  const { currentChat, onSelectChat } = useContext(ChatContext);
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const onClickMenu = (event) => setAnchorEl(event?.currentTarget);

  const open = Boolean(anchorEl);

  const showActionsOwner = ROLES_DASHBOARD.includes(role);
  return (
    <ConversationHeader
      style={{
        border: "none",
        display: "flex",
        justifyContent: _.isEmpty(currentChat) ? "flex-end" : "space-between",
        height: 60,
      }}
    >
      {!_.isEmpty(currentChat) && isMobile && (
        <ConversationHeader.Back>
          <IconButton onClick={() => onSelectChat(null)}>
            <ArrowBackIcon />
          </IconButton>
        </ConversationHeader.Back>
      )}
      {!_.isEmpty(currentChat) && (
        <Avatar src={data?.imageURL} name={data?.name} />
      )}
      {!_.isEmpty(currentChat) && (
        <ConversationHeader.Content>
          {data?.isInfluencer ? (
            <LinkText
              href={`/influencer?userID=${data.userID}`}
              target="_blank"
              sx={{
                fontWeight: 900,
                cursor: "pointer",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {data?.name}
            </LinkText>
          ) : (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 900,
                color: "text.primary",
              }}
            >
              {data?.name}
            </Typography>
          )}
        </ConversationHeader.Content>
      )}
      <ConversationHeader.Actions>
        {data?.isInfluencer && showActionsOwner && (
          <IconButton onClick={(e) => onClickMenu(e)}>
            <MoreVertIcon />
          </IconButton>
        )}
        <IconButton
          onClick={onChangeShowChat}
          sx={{
            padding: 0,
          }}
        >
          <CloseIcon sx={{ fontSize: "1.4rem" }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={() => onClickMenu(null)}>
          {showActionsOwner && (
            <MenuItem
              onClick={() => {
                onClickMenu(null);
                onOpenModalInfluencer();
              }}
            >
              {t(BUTTON_VIEW_DETAILS)}
            </MenuItem>
          )}
        </Menu>
      </ConversationHeader.Actions>
    </ConversationHeader>
  );
};

export default ChatConversationHeader;
