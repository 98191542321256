import React, { useState } from "react";
import Box from "@mui/material/Box";
import CampaignInformation from "./CampaignInformation";
import InfluencersInformation from "./InfluencersInformation";
import AttachmentsCampaign from "./AttachmentsCampaign";
import Typography from "@mui/material/Typography";
import LoadingButton from "../../../../Common/Buttons/LoadingButton";
import { useForm } from "react-hook-form";
import { formatCitiesSave } from "../../../../../utils/formats";
import {
  ALERT_ICON_TYPE_ERROR,
  BORDER_RADIUS,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DRAFT,
  CAMPAIGN_STATUS_FINISHED,
} from "../../../../../utils/constants";
import { ConfirmAlert, SimpleAlert } from "../../../../../utils/alerts";
import differenceInDays from "date-fns/differenceInDays";
import { useLocale } from "../../../../../Contexts/LocaleContext";
import {
  PAGE_CAMPAIGN_DETAIL_ACTIVE_BUTTON,
  PAGE_CAMPAIGN_DETAIL_FINISHED_BUTTON,
  PAGE_CAMPAIGN_DETAIL_SAVE_BUTTON,
  PAGE_CAMPAIGN_DETAIL_TITLE,
} from "../../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  BUTTON_ACTIVATE_CAMPAIGN,
  BUTTON_DEACTIVATE_CAMPAIGN,
  BUTTON_FINISHED_CAMPAIGN,
  BUTTON_SAVE_CAMPAIGN,
  CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE,
  CAMPAIGN_HAS_BEEN_FINISHED,
} from "../../../../../locales/keysTranslations";
import { WARNING } from "../../../../../locales/keysTranslations";
import { CAMPAIGN_ACTIVATE_TIME_CONFIRMATION } from "../../../../../locales/keysTranslations";
import { ACTIVATE } from "../../../../../locales/keysTranslations";
import { CAMPAIGN_DEACTIVATE_TIME } from "../../../../../locales/keysTranslations";
import ContainerPaperSection from "../../../Account/ContainerPaperSection";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../../utils/colors";
import useFocusErrorForm from "../../../../../hooks/useFocusErrorForm";
import { startGenerateDescriptionAndScriptCampaign } from "../../../../../actions/campaigns";
import { useDispatchApp } from "../../../../../lib/redux";

const INITIAL_STATE = {
  name: "",
  individualBudget: 50,
  budget: 50,
  description: "",
  descriptionAi: "",
  script: "",
  goal: "",
  isUgcCampaign: false,
  platforms: {
    instagram: false,
    tiktok: false,
    youtube: false,
  },
  hashtag: "",
  productAttributes: "",
  messageContent: "",
  messageTone: "",
  lastActiveDate: "",
  environmentContent: "",
  colorsContent: "",
  prohibitedColorsContent: "",
  trafficLink: "",
  keyWords: "",
  prohibitedWords: "",
  outfit: "",
  additionalComments: "",
  referenceProfiles: "",
  prohibitedProfiles: "",
  repost: {},
  socialNetworkLink: "",
  typeContent: {
    instagramBurstStories: false,
    instagramPost: false,
    instagramReel: false,
    instagramStory: false,
    tiktokPost: false,
    tiktokStory: false,
    youtubeVideo: false,
    youtubeShort: false,
  },
  typeContentMedia: {
    images: false,
    videos: false,
  },
  ugcContentFormat: "",
  hasCollaboration: false,
  amountInfluencers: "",
  audienceLocation: [],
  typeInfluencers: [0, 0],
  ageRange: [18, 40],
  categoriesInfluencers: [],
  brief: "",
  attachmentFile: "",
  attachmentLink: "",
  quantityMale: false,
  quantityFemale: false,
};

const Campaign = ({ data, onSaveCampaign }) => {
  const { control, watch, setValue, handleSubmit, formState } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      ...data,
    },
  });

  const [isFetching, setIsFetching] = useState(false);
  const [newStatus, setNewStatus] = useState(false);

  const dispatch = useDispatchApp();

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();

  const { isOpen } = useTour();

  const LOCALE = useLocale();

  const onGenerateScriptAndDescriptionCampaign = async () => {
    const response = await dispatch(
      startGenerateDescriptionAndScriptCampaign({
        campaignID: data.campaignID,
        generateDescription: false,
      })
    );
    if (response) {
      setValue("script", response.script);
    }
    return true;
  };

  const onSubmit = async (formValues) => {
    if (isOpen) return;
    const citiesFormatted = formatCitiesSave({
      mode: "save",
      cities: formValues.audienceLocation,
      citiesShop: LOCALE.cities,
    });

    if (newStatus === CAMPAIGN_STATUS_ACTIVE) {
      const result = await ConfirmAlert({
        title: t(WARNING),
        text: t(CAMPAIGN_ACTIVATE_TIME_CONFIRMATION),
        confirmButtonText: t(ACTIVATE),
      });
      if (!result.isConfirmed) return;
    }

    if (
      data.status === CAMPAIGN_STATUS_ACTIVE &&
      newStatus === CAMPAIGN_STATUS_DRAFT &&
      data.lastActiveDate &&
      differenceInDays(new Date(), new Date(data.lastActiveDate)) < 1
    )
      return SimpleAlert({
        title: t(WARNING),
        text: t(CAMPAIGN_DEACTIVATE_TIME),
        icon: ALERT_ICON_TYPE_ERROR,
      });

    setIsFetching(true);
    const response = await onSaveCampaign(
      { ...formValues, audienceLocation: citiesFormatted },
      newStatus
    );
    setIsFetching(false);
    return response;
  };

  const commonSxMainButtons = {
    fontSize: "0.95rem",
    width: { xs: "100%", md: 174 },
    minHeight: 46,
  };

  return (
    <ContainerPaperSection
      title={CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE}
      onSubmit={handleSubmit(onSubmit)}
      dataTourTitle={PAGE_CAMPAIGN_DETAIL_TITLE}
      sx={{
        alignItems: "center",
      }}
      showTopButton={false}
      showBottomButton={data.status === CAMPAIGN_STATUS_FINISHED}
      customBottomButton={
        <Typography variant="h6">{t(CAMPAIGN_HAS_BEEN_FINISHED)}</Typography>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <CampaignInformation
          control={control}
          errors={errors}
          isDisabledInput={data.status === CAMPAIGN_STATUS_ACTIVE}
          watch={watch}
          setValue={setValue}
          onGenerateScriptAndDescriptionCampaign={
            onGenerateScriptAndDescriptionCampaign
          }
        />
        <InfluencersInformation
          control={control}
          errors={errors}
          isDisabledInput={data.status === CAMPAIGN_STATUS_ACTIVE}
          watch={watch}
          setValue={setValue}
        />
        <AttachmentsCampaign
          control={control}
          errors={errors}
          isDisabledInput={data.status === CAMPAIGN_STATUS_ACTIVE}
          watch={watch}
          pathRTDB={`campaigns/${data.campaignID}`}
        />
        {data.status !== CAMPAIGN_STATUS_FINISHED && (
          <Box
            sx={{
              position: "fixed",
              bottom: 20,
              left: { xs: 20, md: 250 },
              zIndex: 1100,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1,
              p: 2.5,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(8px)",
              borderRadius: BORDER_RADIUS,
            }}
          >
            <LoadingButton
              loading={isFetching}
              variant="contained"
              disabled={data.status === CAMPAIGN_STATUS_FINISHED}
              onClick={() => {
                setNewStatus(
                  data.status === CAMPAIGN_STATUS_DRAFT
                    ? CAMPAIGN_STATUS_DRAFT
                    : CAMPAIGN_STATUS_FINISHED
                );
              }}
              dataTour={
                data.status === CAMPAIGN_STATUS_DRAFT
                  ? PAGE_CAMPAIGN_DETAIL_SAVE_BUTTON
                  : PAGE_CAMPAIGN_DETAIL_FINISHED_BUTTON
              }
              color="primary"
              type="submit"
              sx={commonSxMainButtons}
            >
              {data.status === CAMPAIGN_STATUS_DRAFT
                ? t(BUTTON_SAVE_CAMPAIGN)
                : t(BUTTON_FINISHED_CAMPAIGN)}
            </LoadingButton>
            <LoadingButton
              type="submit"
              color="secondary"
              dataTour={
                data.status === CAMPAIGN_STATUS_DRAFT
                  ? PAGE_CAMPAIGN_DETAIL_ACTIVE_BUTTON
                  : PAGE_CAMPAIGN_DETAIL_SAVE_BUTTON
              }
              onClick={() =>
                data.status === CAMPAIGN_STATUS_DRAFT
                  ? setNewStatus(CAMPAIGN_STATUS_ACTIVE)
                  : setNewStatus(CAMPAIGN_STATUS_DRAFT)
              }
              sx={{
                background:
                  data.status === CAMPAIGN_STATUS_DRAFT &&
                  GRADIENT_PURPLE_FUCHSIA,
                color:
                  data.status === CAMPAIGN_STATUS_DRAFT
                    ? `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT}`
                    : "secondary.contrastText",
                ...commonSxMainButtons,
              }}
            >
              {t(
                data.status === CAMPAIGN_STATUS_DRAFT
                  ? BUTTON_ACTIVATE_CAMPAIGN
                  : BUTTON_DEACTIVATE_CAMPAIGN
              )}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </ContainerPaperSection>
  );
};

export default Campaign;
