import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material";
import { useTranslationApp } from "../../../lib/i18next";
import { useTour } from "@reactour/tour";
import useMediaQuery from "@mui/material/useMediaQuery";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const SegmentedController = ({
  initialValue = 0,
  dataTour = "",
  value,
  onChangeTab = () => {},
  tabs = [],
}) => {
  const [tab, setTab] = useState(initialValue);

  const theme = useTheme();

  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isOpen } = useTour();

  useEffect(() => {
    if (value !== undefined) setTab(value);
  }, [value]);

  return (
    <Tabs
      variant={isMobile ? "scrollable" : "standard"}
      orientation="horizontal"
      value={tab}
      data-tour={dataTour}
      onChange={(_, newTab) => {
        setTab(newTab);
        onChangeTab(newTab);
      }}
      centered={isMobile ? false : true}
      sx={{
        mb: 3,
        width: "100%",
        zIndex: 2,
        ".MuiTabs-scroller": {
          ".MuiTabs-flexContainer": {
            justifyContent: "unset",
            ".Mui-selected": {
              borderBottom: `2px solid ${theme.palette.background.paperSecondaryContrastText}`,
              color: `${theme.palette.background.paperSecondaryContrastText} !important`,
            },
            color: theme.palette.fields.placeholder,
          },
          ".MuiTabs-indicator": {
            display: "none",
          },
        },
      }}
    >
      {tabs.map((tab, index) => {
        if (tab.show ?? true) {
          return (
            <Tab
              key={tab.label}
              icon={tab.icon}
              disabled={isOpen}
              data-tour={tab.dataTour}
              label={t(tab.label)}
              iconPosition={tab.iconPosition || "top"}
              {...a11yProps(t(tab.label))}
              wrapped
              sx={{
                minHeight: {
                  xs: 52,
                  sm: 72,
                },
                fontWeight: 600,
                fontSize: { xs: "0.8rem", sm: "1.1rem" },
                fontStyle: "normal",
                textTransform: "capitalize",
                flex: 1,
                maxWidth: "unset",
                "& .MuiSvgIcon-root": {
                  fontSize: { xs: "1.3rem", sm: "1.5rem" },
                },
              }}
            />
          );
        }

        return null;
      })}
    </Tabs>
  );
};

export default SegmentedController;
