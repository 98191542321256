import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "../../Common/Buttons/Button";
import { logoPlatform } from "../../../utils/socialNetworks";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import LinkText from "../../Common/Texts/LinkText";

const ModalRequestInfluencer = ({
  data,
  open,
  onCloseModal,
  onAcceptRequest,
  onRejectRequest,
}) => {
  const [verificationCode, setVerificationCode] = useState("");

  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel="Modal de Solicitud de Creador de Contenido"
      title="Información de la Solicitud"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            alt={`Logo de ${data.platform}`}
            src={logoPlatform(data.platform)}
            variant="rounded"
            sx={{ mr: 1, width: "30px", height: "30px" }}
          />
          <LinkText
            href={`${
              data.platform === "instagram"
                ? "https://www.instagram.com/"
                : data.platform === "tiktok"
                ? "https://www.tiktok.com/@"
                : "https://www.youtube.com/c/"
            }${data.accountName}`}
            sx={{ fontWeight: 600 }}
            target="_blank"
            variant="body1"
          >
            {data.accountName}
          </LinkText>
        </Box>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Ingresa un código de verificación
        </Typography>
        <TextField
          value={verificationCode}
          label="Código de Verificación"
          onChange={(e) => setVerificationCode(e.target.value)}
        />
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          mt: 2,
          mb: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={verificationCode.length === 0}
          onClick={() => onAcceptRequest(verificationCode)}
        >
          Aceptar Solicitud
        </Button>
        <Button
          variant="contained"
          color="error"
          disableElevation
          sx={{ minWidth: 90 }}
          disabled={verificationCode.length > 0}
          onClick={onRejectRequest}
        >
          Rechazar Solicitud
        </Button>
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalRequestInfluencer;
