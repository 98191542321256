import React from "react";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useSelectorApp } from "../../../lib/redux";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_COMMENT,
  COLUMN_NAME_DATE,
  COLUMN_NAME_SHOP,
  DATE_SHORT,
} from "../../../locales/keysTranslations";
import LinkText from "../../Common/Texts/LinkText";

const FeedbacksTable = ({
  data,
  contentType,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].creationTime - data[a].creationTime
  );
  const { shops } = useSelectorApp((state) => state.shop);
  const { t } = useTranslationApp();

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={Object.keys(data)}
        loading={false}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SHOP)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_COMMENT)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_DATE)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            if (data[key].postStatus !== contentType && contentType !== null)
              return null;
            return (
              <TableRow key={index}>
                <TableCell align="center" indexRow={index}>
                  <LinkText
                    variant="body2"
                    href={`shop?shopID=${data[key].shopID}`}
                    target="_blank"
                  >
                    {shops[data[key].shopID]?.businessName ?? "-"}
                  </LinkText>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {data[key].feedback}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {t(DATE_SHORT, {
                    date: new Date(data[key].creationTime),
                  })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default FeedbacksTable;
