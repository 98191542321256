import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InfluencerAudienceCountry from "./InfluencerAudienceCountry";
import InfluencerAudienceAgeDistribution from "./InfluencerAudienceAgeDistribution";
import InfluencerAudienceGenderSplit from "./InfluencerAudienceGenderSplit";
// import InfluencerAudienceLanguages from "./InfluencerAudienceLanguages";
import InfluencerAudienceLookAtLikes from "./InfluencerAudienceLookAtLikes";
import InfluencerAudienceCities from "./InfluencerAudienceCities";
import InfluencerAudienceBrands from "./InfluencerAudienceBrands";
import InfluencerAudienceInterests from "./InfluencerAudienceInterests";
import { useTranslationApp } from "../../../../../lib/i18next";
import { AUDIENCE_INFORMATION } from "../../../../../locales/keysTranslations";
import InfluencerGalleryContent from "../../../../User/InfluencersStatistics/InfluencerGalleryContent";

const InfluencerAudience = ({
  data = {},
  influencer,
  userID,
  similarInfluencers,
  isLoadingSimilarInfluencers,
  isEnabledOrderChat,
  onChangeShowOrderChat,
  from,
}) => {
  const { t } = useTranslationApp();
  const {
    // languages = [],
    countries = [],
    cities = [],
    brandAffinity = [],
    interests = [],
    genderAgeDistribution = [],
  } = data;

  return (
    <Box sx={{ width: "100%", my: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          fontStyle: "normal",
        }}
      >
        {t(AUDIENCE_INFORMATION)}
      </Typography>
      <Grid container spacing={2}>
        {/* {languages.length > 0 && (
          <Grid item xs={12}>
            <InfluencerAudienceCredibility data={credibilityScore} />
          </Grid>
        )} */}
        {countries?.length > 0 && (
          <Grid item xs={12} md={6}>
            <InfluencerAudienceCountry data={countries} />
          </Grid>
        )}
        {cities?.length > 0 && (
          <Grid item xs={12} md={6}>
            <InfluencerAudienceCities data={cities} />
          </Grid>
        )}
        {brandAffinity?.length > 0 && (
          <Grid item xs={12} md={6}>
            <InfluencerAudienceBrands data={brandAffinity} />
          </Grid>
        )}
        {interests?.length > 0 && (
          <Grid item xs={12} md={6}>
            <InfluencerAudienceInterests data={interests} />
          </Grid>
        )}
        {genderAgeDistribution?.length > 0 && (
          <Grid item xs={12} md={6}>
            <InfluencerAudienceAgeDistribution data={genderAgeDistribution} />
          </Grid>
        )}
        {genderAgeDistribution?.length > 0 && (
          <Grid item xs={12} md={6}>
            <InfluencerAudienceGenderSplit data={genderAgeDistribution} />
          </Grid>
        )}

        {from === "influencers" && (
          <Grid item xs={12}>
            <InfluencerGalleryContent
              userID={userID}
              isEnabledOrderChat={isEnabledOrderChat}
              onChangeShowOrderChat={onChangeShowOrderChat}
            />
          </Grid>
        )}

        {from === "influencers" && (
          <Grid item xs={12}>
            <InfluencerAudienceLookAtLikes
              data={similarInfluencers}
              isLoading={isLoadingSimilarInfluencers}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default InfluencerAudience;
