import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from "../Forms/Rating/Rating";

const InfluencerNodeData = ({ title, data, isRating = false, sx = {} }) => {
  return (
    <Box sx={{ minHeight: 95, textAlign: "left", ...sx }}>
      <Typography
        component="p"
        align="left"
        variant="h6"
        sx={{ fontWeight: "normal" }}
      >
        {title}
      </Typography>
      {isRating ? (
        <Rating value={data} readOnly={true} precision={0.5} variantText="h6" />
      ) : (
        <Typography
          component="p"
          align="left"
          variant="h4"
          sx={{ fontWeight: 900 }}
        >
          {data}
        </Typography>
      )}
    </Box>
  );
};

export default InfluencerNodeData;
