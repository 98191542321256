import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "../Tooltips/Tooltip";

const TitlePage = ({
  children,
  variantPrimary = "h3",
  variantSecondary = "h4",
  align,
  color,
  gutterBottom,
  dataTour = "",
  hasTooltip = false,
  toolTipAction = () => {},
  sxContainer = {},
  sx = {},
}) => {
  const isMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: {
          xs: 1,
          sm: 2,
        },
        flexDirection: "row",
        ...sxContainer,
      }}
    >
      <Typography
        align={align}
        color={color}
        variant={isMobile ? variantPrimary : variantSecondary}
        component="h1"
        gutterBottom={gutterBottom}
        sx={{
          fontWeight: "900",
          fontSize: { xs: "1.7rem", sm: "2.5rem" },
          textTransform: "capitalize",
          ...sx,
        }}
        data-tour={dataTour}
      >
        {children}
      </Typography>
      {hasTooltip && <Tooltip onClick={toolTipAction} />}
    </Box>
  );
};

export default TitlePage;
