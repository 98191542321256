import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import ShopInformationForm from "./ShopInformationForm";
import { startSaveShopInfoByOwner } from "../../../actions/owner";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { getNameBusinessTheme } from "../../../actions/getters";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";
import { BUTTON_CANCEL, BUTTON_SAVE } from "../../../locales/keysTranslations";

const ModalShopInformation = ({
  modalOpen,
  onCloseModal,
  data,
  onChangeData,
}) => {
  const [error, setError] = useState(null);

  const { control, getValues } = useForm({
    defaultValues: {
      businessName: data.businessName ?? "",
      shortDescription: data.shortDescription ?? "",
      clappCredits: data.clappCredits ?? 0,
      costCollaboration: data.costCollaboration ?? 0,
      hasBillingCycle: data.hasBillingCycle ?? false,
      staffID: data.staffID ?? null,
      sortingIndex: data?.sortingIndex || "",
    },
  });

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const onSubmit = async () => {
    const formValues = getValues();
    if (
      formValues.businessName.length === 0 ||
      formValues.shortDescription.length === 0 ||
      formValues.clappCredits.length === 0 ||
      formValues.costCollaboration.length === 0
    ) {
      return setError("Todos lo campos son requeridos");
    }
    if (formValues.clappCredits < 0) {
      return setError(`Los ${NAME_BUSINESS} Credits no pueden ser negativos`);
    }
    if (formValues.costCollaboration < 0) {
      return setError("Los costo por colaboración no puede ser negativo");
    }
    if (!formValues.staffID || formValues?.staffID?.length === 0) {
      return setError("Debe seleccionar un staff");
    }
    if (formValues.sortingIndex && formValues.sortingIndex < 1) {
      return setError("El índice de ordenamiento debe ser mayor a 0");
    }
    setError(null);
    const response = await dispatch(
      startSaveShopInfoByOwner({
        shopID: data.shopID,
        data: {
          ...formValues,
          sortingIndex: formValues.sortingIndex
            ? Number(formValues.sortingIndex)
            : null,
        },
      })
    );
    if (response) {
      onCloseModal();
      onChangeData({
        ...data,
        ...formValues,
      });
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel="Modal de información de Marca"
      title="Editar marca"
      description="Edita la información de una marca"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {!!error && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Alert
              severity="error"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                align: "center",
              }}
            >
              {error}
            </Alert>
          </Box>
        )}
        <ShopInformationForm control={control} />
      </Box>
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SAVE,
            onClick: onSubmit,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalShopInformation;
