import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ModalBasicLayout from "./ModalBasicLayout";
import { getExtensionFile } from "../../../utils/urls";
import {
  ALERT_ICON_TYPE_SUCCESS,
  POST_STATUS_PENDING_CONTENT,
  POST_STATUS_NEGATIVE_REVIEW,
  APPLICANT_STATUS_REVISIONS,
  ROLES_ADMIN_SHOPS,
  POST_STATUS_PENDING_APPROVAL,
} from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_SEND_REVISION,
  CURRENT_CONTENT,
  EXPLANATION_SEND_ONE_REVISION,
  FEEDBACK_PLACEHOLDER,
  FIELD_MIN_LENGTH,
  MODAL_MEDIA_ARIA_LABEL,
  READY,
  REVISIONS,
  REVISION_COUNT,
  SEND_REVISION_INFLUENCER_COMMENT,
} from "../../../locales/keysTranslations";
import ModalUploadSocialNetwork from "./ModalUploadSocialNetwork";
import ContainerOrderStatistics from "../Containers/ContainerOrderStatistics";
import ModalMediaPublicationButtons from "./ModalMediaPublication/ModalMediaPublicationButtons";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { generateRandomCode } from "../../../utils/generateRandomsValues";
import { verifyInviteCodeExists } from "../../../actions/invites";
import {
  startReviewApplicant,
  startReviewInfluencer,
} from "../../../actions/influencers";
import { waitDelay } from "../../../utils/dates";
import { useNavigate } from "react-router-dom";
import { SimpleAlert } from "../../../utils/alerts";
import ModalReview from "../../User/Publication/ModalReview";
import ModalDissatisfactionCollaboration from "./ModalDissatisfactionCollaboration";
import ModalValueField from "./ModalValueField";
import { startSaveUserFeedback } from "../../../actions/users";
import { useTour } from "@reactour/tour";
import { startOpenInfluencerConversation } from "../../../actions/chat";
import {
  startApproveContent,
  startSendRevision,
} from "../../../actions/orders";
import ModalMediaPublicationHead from "./ModalMediaPublication/ModalMediaPublicationHead";
import ModalMediaPublicationPlayer from "./ModalMediaPublication/ModalMediaPublicationPlayer";
import { ThemeProvider, useTheme } from "@mui/material";
import themeDark from "../Themes/themeDark";
import Select from "../Selects/Select";
import { REVISION_NUMBERS } from "../../../utils/formats";
import { useForm } from "react-hook-form";
import ModalMediaPublicationFeedback from "./ModalMediaPublication/ModalMediaPublicationFeedback";
import { startRevisionsApplicantContent } from "../../../actions/campaigns";
import {
  STEP_AMBASSADORS_FUNCTIONALITY,
  STEP_MAIN_FUNCTIONALITY,
} from "../../../onboarding/stepsSelectors";
import { STEPS_REVIEW_FUNCTIONALITY } from "../../../onboarding/steps";
import { getRole } from "../../../actions/getters";
import ModalMediaPublicationPreviewContents from "./ModalMediaPublication/ModalMediaPublicationPreviewContents";

const ModalMediaPublication = ({
  modalOpen = false,
  onCloseModal,
  data = {},
  allData = [],
  position,
  onChangeHistory,
  showGalleryButtons,
  mode = "gallery",
  url,
  onClosePrevComponent = () => {},
  onFailedLoadUrl = () => {},
  metadata = {},
  showArrowButtons = false,
  showPrevButton = false,
  showNextButton = false,
  onNext = () => {},
  onPrev = () => {},
  onChangePosition = () => {},
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [modalUploadSocialNetworkOpen, setModalUploadSocialNetworkOpen] =
    useState(false);
  const [from, setFrom] = useState("");
  const [modalReviewOpen, setModalReviewOpen] = useState(false);
  const [modalFeedbackOpen, setModalFeedbackOpen] = useState(false);
  const [
    modalDissatisfactionCollaborationOpen,
    setModalDissatisfactionCollaborationOpen,
  ] = useState(false);
  const [modalSendRevisionOpen, setModalSendRevisionOpen] = useState(false);
  const { control, setValue } = useForm({
    defaultValues: {
      revision: 0,
    },
  });

  const id = data?.key;

  const outerTheme = useTheme();

  const extension = getExtensionFile(url);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  const influencers = useSelectorApp(
    (state) => state?.influencers?.influencers
  );
  const influencer = { ...influencers[data?.userID], userID: data?.userID };
  const orders = useSelectorApp((state) => state?.orders?.orders);
  const applicants = useSelectorApp((state) => state?.campaigns?.applicants);
  // const user = useSelectorApp((state) => state?.auth?.user);

  const role = dispatch(getRole());
  const isAdminUser = ROLES_ADMIN_SHOPS.includes(role);

  const { isOpen, steps, setSteps, currentStep, setCurrentStep } = useTour();
  const onboardingName = steps[currentStep]?.onboardingName;

  useEffect(() => {
    if (isOpen && modalReviewOpen) {
      if (onboardingName === STEP_MAIN_FUNCTIONALITY) {
        setSteps(STEPS_REVIEW_FUNCTIONALITY);
      }
      if (onboardingName === STEP_AMBASSADORS_FUNCTIONALITY) {
        setCurrentStep(currentStep + 1);
      }
    } else if (!isOpen && currentStep > 1) {
      navigate("/publications");
    }
    // eslint-disable-next-line
  }, [isOpen, modalReviewOpen]);

  useEffect(() => {
    if (
      (data.from === "order" &&
        data?.postStatus === POST_STATUS_PENDING_CONTENT) ||
      (data.from === "applicant" &&
        data.postStatus === APPLICANT_STATUS_REVISIONS)
    ) {
      const value = data?.history?.length > 0 ? data?.history?.length - 1 : 0;
      setValue("revision", value);
      onChangeHistory({
        historyIndex: value,
        id: data?.key,
        from: data.from,
      });
      return;
    }
    setValue("revision", "current");
    onChangeHistory({
      historyIndex: "current",
      id: data?.key,
      from: data.from,
    });

    //eslint-disable-next-line
  }, [id]);

  const onOpenModalReviewStatusChange = (status) => setModalReviewOpen(status);

  const onOpenModalFeedbackStatusChange = (status) =>
    setModalFeedbackOpen(status);

  const onOpenModalDissatisfactionCollaborationStatusChange = (status) =>
    setModalDissatisfactionCollaborationOpen(status);

  const onOpenModalSendRevisionStatusChange = (status) =>
    setModalSendRevisionOpen(status);

  const onReviewInfluencer = async (formValues) => {
    if (!formValues.isAmbassador && formValues.doAmbassador) {
      let inviteCode;
      if (formValues.inviteCode === "") {
        inviteCode = generateRandomCode(8);
      } else {
        const isCodeExists = await dispatch(
          verifyInviteCodeExists(formValues.inviteCode)
        );
        if (isCodeExists) return false;
        inviteCode = formValues.inviteCode;
      }
      formValues.inviteCode = inviteCode;
    }

    let response;

    if (data.from === "order") {
      const order = { ...orders[data.key], orderID: data.key };
      const fromStatus = order.postStatus;
      response = await dispatch(
        startReviewInfluencer(order, formValues, fromStatus)
      );

      if (fromStatus === POST_STATUS_PENDING_APPROVAL) {
        await dispatch(startApproveContent(data.key));
      }
    }
    if (data.from === "applicant") {
      const applicant = { ...applicants[data.key], applicantID: data.key };
      response = await dispatch(startReviewApplicant(applicant, formValues));
    }

    if (response) {
      // if (!user.feedback) {
      //   onOpenModalFeedbackStatusChange(true);
      // return;
      // }

      if (!formValues.isAmbassador && formValues.doAmbassador) {
        await waitDelay(400);
        navigate("/ambassadors");
        onClosePrevComponent();
        if (isOpen && onboardingName === STEP_AMBASSADORS_FUNCTIONALITY) {
          setCurrentStep(currentStep + 1);
        }
      }
      if (!isOpen) {
        if (Number(formValues.score) === 1) {
          onOpenModalDissatisfactionCollaborationStatusChange(true);
        } else {
          SimpleAlert({
            title: t(READY),
            icon: ALERT_ICON_TYPE_SUCCESS,
          });
        }
      }
    }
    return response;
  };
  const onSendRevisionContent = async (comment) => {
    let response;
    if (data.from === "order") {
      response = await dispatch(
        startSendRevision({
          orderID: data.key,
          data: {
            comment,
          },
        })
      );
    }
    if (data.from === "applicant") {
      const applicant = applicants[data.key];
      response = await dispatch(
        startRevisionsApplicantContent({
          applicantID: data.key,
          comment,
          revision: applicant.revision ?? 0,
          content: applicant.content,
        })
      );
    }

    if (response) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onOpenModalSendRevisionStatusChange(false);
    }
    return;
  };

  const onOpenChat = async () => {
    let userID;

    if (data.from === "order") {
      userID = orders[data.key].userID;
    } else if (data.from === "applicant") {
      userID = applicants[data.key].userID;
    }

    await dispatch(
      startOpenInfluencerConversation({
        userID,
      })
    );
    onClosePrevComponent();
  };

  const onSaveFeedback = async (feedback) => {
    await dispatch(startSaveUserFeedback(feedback));
    SimpleAlert({
      title: t(READY),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    onOpenModalFeedbackStatusChange(false);
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      ariaLabel={t(MODAL_MEDIA_ARIA_LABEL)}
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        mb: 2,
        overflowX: "hidden",
      }}
      sxTitle={{
        py: 1,
        pb: 2,
      }}
      onKeyDown={(e) => {
        if (!showArrowButtons) return;
        if (e.key === "ArrowLeft" && showPrevButton) return onPrev();
        if (e.key === "ArrowRight" && showNextButton) return onNext();
        return;
      }}
      customComponentTitle={
        data?.history?.length > 0 ? (
          <ThemeProvider theme={themeDark(outerTheme)}>
            <Select
              name="revision"
              label={t(REVISIONS)}
              variant="filled"
              control={control}
              options={[
                {
                  value: "current",
                  label: t(CURRENT_CONTENT),
                },
                ...data?.history.map((item, index) => ({
                  value: index,
                  label: t(REVISION_COUNT, {
                    value: t(REVISION_NUMBERS[index + 1]),
                  }),
                })),
              ]}
              actionsChange={(value) =>
                onChangeHistory({
                  historyIndex: value,
                  id: data?.key,
                  from: data.from,
                })
              }
              sx={{
                minWidth: 120,
              }}
            />
          </ThemeProvider>
        ) : null
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: "100%",
          height: "98%",
          mx: { xs: 1, sm: 2 },
          gap: 2,
        }}
      >
        <ModalMediaPublicationHead
          data={data}
          isAdminUser={isAdminUser}
          onOpenChatPublication={onOpenChat}
        />

        <ContainerOrderStatistics data={data?.analyticsData} dataItem={data} />
        <ModalMediaPublicationPlayer
          data={data}
          showGalleryButtons={showGalleryButtons}
          isAdminUser={isAdminUser}
          showArrowButtons={showArrowButtons}
          showPrevButton={showPrevButton}
          showNextButton={showNextButton}
          onPrev={onPrev}
          onNext={onNext}
          onOpenModalReviewStatusChange={onOpenModalReviewStatusChange}
          onOpenModalSendRevisionStatusChange={
            onOpenModalSendRevisionStatusChange
          }
          onFailedLoadUrl={onFailedLoadUrl}
          onCloseModal={onCloseModal}
        />
        <ModalMediaPublicationPreviewContents
          data={allData}
          position={position}
          onChangePosition={onChangePosition}
        />
        <ModalMediaPublicationButtons
          data={data}
          isAdminUser={isAdminUser}
          isFetching={isFetching}
          setIsFetching={setIsFetching}
          url={url}
          extension={extension}
          setFrom={setFrom}
          setModalUploadSocialNetworkOpen={setModalUploadSocialNetworkOpen}
          onClosePrevComponent={onClosePrevComponent}
          onOpenModalReviewStatusChange={onOpenModalReviewStatusChange}
          onOpenModalSendRevisionStatusChange={
            onOpenModalSendRevisionStatusChange
          }
        />
        {data.postStatus === POST_STATUS_NEGATIVE_REVIEW && (
          <ModalMediaPublicationFeedback data={data} />
        )}
      </Box>
      {modalUploadSocialNetworkOpen && (
        <ModalUploadSocialNetwork
          modalOpen={modalUploadSocialNetworkOpen}
          onCloseModal={() => {
            setModalUploadSocialNetworkOpen(false);
            setFrom("");
          }}
          url={url}
          hls={data.hls}
          metadata={metadata}
          from={from}
          onClosePrevComponent={() => {
            onClosePrevComponent();
            onCloseModal();
          }}
        />
      )}
      {modalReviewOpen && (
        <ModalReview
          data={influencer}
          modalOpen={modalReviewOpen}
          onCloseModal={() => onOpenModalReviewStatusChange(false)}
          onReviewInfluencer={onReviewInfluencer}
        />
      )}
      {modalDissatisfactionCollaborationOpen && (
        <ModalDissatisfactionCollaboration
          modalOpen={modalDissatisfactionCollaborationOpen}
          onCloseModal={() =>
            onOpenModalDissatisfactionCollaborationStatusChange(false)
          }
          orderID={data.key}
        />
      )}
      {modalFeedbackOpen && (
        <ModalValueField
          modalOpen={modalFeedbackOpen}
          onCloseModal={() => onOpenModalFeedbackStatusChange(false)}
          onCallbackButton={onSaveFeedback}
          hasSecondButton={true}
          onCallbackSecondButton={() => onOpenModalFeedbackStatusChange(false)}
          label={FEEDBACK_PLACEHOLDER}
          maxWidth="sm"
          multiline={true}
          rules={{
            minLength: {
              value: 10,
              message: t(FIELD_MIN_LENGTH, { value: 10 }),
            },
          }}
        />
      )}
      {modalSendRevisionOpen && (
        <ModalValueField
          modalOpen={modalSendRevisionOpen}
          onCloseModal={() => onOpenModalSendRevisionStatusChange(false)}
          onCallbackButton={onSendRevisionContent}
          hasSecondButton={true}
          textButton={BUTTON_SEND_REVISION}
          onCallbackSecondButton={() =>
            onOpenModalSendRevisionStatusChange(false)
          }
          label={t(SEND_REVISION_INFLUENCER_COMMENT)}
          maxWidth="sm"
          helperText={
            data.from === "order" ? t(EXPLANATION_SEND_ONE_REVISION) : ""
          }
          multiline={true}
          InputLabelProps={{
            sx: {
              textWrap: "wrap",
              "&.MuiInputLabel-shrink": {
                textWrap: "nowrap",
              },
            },
          }}
          rules={{
            minLength: {
              value: 10,
              message: t(FIELD_MIN_LENGTH, { value: 10 }),
            },
          }}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalMediaPublication;
