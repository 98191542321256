import React from "react";
import Box from "@mui/material/Box";
import GalleryItem from "./GalleryItem";
import Grid from "@mui/material/Grid";
import BlockPlanNoActive from "../Block/BlockPlanNoActive";
import Loader from "../Loader/Loader";
import Typography from "@mui/material/Typography";
import { MODAL_GALLERY_NO_MORE_CONTENT } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const Gallery = ({
  data,
  reviews,
  onCloseModal,
  lastItemRef,
  loadLastItem,
  onFailedLoadUrl,
  onSelectItem,
  onSelectPreview,
  selectedItems,
}) => {
  const { t } = useTranslationApp();
  return (
    <BlockPlanNoActive onCallback={onCloseModal}>
      <Grid
        container
        spacing={2}
        sx={{
          px: 4,
        }}
      >
        {data?.length === 0 && (
          <Grid item xs={12}>
            <div ref={lastItemRef}></div>
          </Grid>
        )}
        {data?.length > 0 &&
          data.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <GalleryItem
                  item={item}
                  index={index}
                  reviews={reviews}
                  onCloseModalGallery={onCloseModal}
                  onFailedLoadUrl={onFailedLoadUrl}
                  onSelectItem={onSelectItem}
                  onSelectPreview={onSelectPreview}
                  selectedItems={selectedItems}
                />
                {data.length === index + 1 && <div ref={lastItemRef}></div>}
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {loadLastItem ? (
              <Typography variant="h6" sx={{ color: "white" }}>
                {t(MODAL_GALLERY_NO_MORE_CONTENT)}
              </Typography>
            ) : (
              <Loader size={30} sx={{ mb: 2 }} />
            )}
          </Box>
        </Grid>
      </Grid>
    </BlockPlanNoActive>
  );
};

export default Gallery;
