import {
  RESTAURANTS,
  NEW_CREATORS_CATEGORY as NEW_CREATORS_CATEGORY_TRANSLATION,
  FOODS,
  TRAVEL,
  ENTERTAINMENT,
  TECHNOLOGY,
  FITNESS,
  FASHION,
  BEAUTY,
  PETS,
  HOME,
  RESTAURANTS_SUBCATEGORY_FOOD,
  RESTAURANTS_SUBCATEGORY_DRINKS,
  RESTAURANTS_SUBCATEGORY_DESSERTS,
  RESTAURANTS_SUBCATEGORY_COFFEE,
  RESTAURANTS_SUBCATEGORY_HOT_DRINKS,
  RESTAURANTS_SUBCATEGORY_COLD_DRINKS,
  RESTAURANTS_SUBCATEGORY_SOFT_DRINKS,
  RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS,
  RESTAURANTS_SUBCATEGORY_SUSHI,
  RESTAURANTS_SUBCATEGORY_ASIAN,
  RESTAURANTS_SUBCATEGORY_JAPANESE,
  RESTAURANTS_SUBCATEGORY_CHINESE,
  RESTAURANTS_SUBCATEGORY_ITALIAN,
  RESTAURANTS_SUBCATEGORY_MEXICAN,
  RESTAURANTS_SUBCATEGORY_HEALTHY,
  RESTAURANTS_SUBCATEGORY_VEGAN,
  RESTAURANTS_SUBCATEGORY_VEGETARIAN,
  FOODS_SUBCATEGORY_SEASONINGS,
  FOODS_SUBCATEGORY_FOOD,
  FOODS_SUBCATEGORY_GRANOLA,
  FOODS_SUBCATEGORY_DRIED_FRUITS,
  FOODS_SUBCATEGORY_HEALTHY,
  TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM,
  TRAVEL_SUBCATEGORY_RURAL_TOURISM,
  TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM,
  TRAVEL_SUBCATEGORY_NATURE_TOURISM,
  TRAVEL_SUBCATEGORY_CULTURAL_TOURISM,
  TRAVEL_SUBCATEGORY_HEALTH_TOURISM,
  ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT,
  TECHNOLOGY_SUBCATEGORY_PHONES,
  TECHNOLOGY_SUBCATEGORY_COMPUTERS,
  TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES,
  TECHNOLOGY_SUBCATEGORY_ACCESSORIES,
  TECHNOLOGY_SUBCATEGORY_PERIPHERALS,
  TECHNOLOGY_SUBCATEGORY_ELECTRONICS,
  FITNESS_SUBCATEGORY_SPORTSWEAR,
  FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES,
  FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS,
  FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT,
  FASHION_SUBCATEGORY_HAUTE_COUTURE,
  FASHION_SUBCATEGORY_HIPSTER,
  FASHION_SUBCATEGORY_CASUAL,
  FASHION_SUBCATEGORY_CLASSIC,
  FASHION_SUBCATEGORY_ROMANTIC,
  FASHION_SUBCATEGORY_SPORTSWEAR,
  FASHION_SUBCATEGORY_PREPPY,
  FASHION_SUBCATEGORY_GRUNGE,
  FASHION_SUBCATEGORY_VINTAGE,
  BEAUTY_SUBCATEGORY_COSMETICS,
  BEAUTY_SUBCATEGORY_EYE_CARE,
  BEAUTY_SUBCATEGORY_FEMININE_HYGIENE,
  BEAUTY_SUBCATEGORY_FIRST_AID,
  BEAUTY_SUBCATEGORY_HAIR_CARE,
  BEAUTY_SUBCATEGORY_MEDICAL_DEVICES,
  BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT,
  BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS,
  BEAUTY_SUBCATEGORY_MEDICATIONS,
  BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE,
  BEAUTY_SUBCATEGORY_SCALES,
  BEAUTY_SUBCATEGORY_SKIN_CARE,
  BEAUTY_SUBCATEGORY_VITAMINS,
  BEAUTY_SUBCATEGORY_FACIAL_TISSUES,
  PETS_SUBCATEGORY_FOOD,
  PETS_SUBCATEGORY_COSMETICS,
  PETS_SUBCATEGORY_ACCESSORIES,
  PETS_SUBCATEGORY_CLOTHING,
  PETS_SUBCATEGORY_MEDICATIONS,
  HOME_SUBCATEGORY_TEXTILES_CARPETS,
  HOME_SUBCATEGORY_ART_PRINTS,
  HOME_SUBCATEGORY_STORAGE_ORGANIZATION,
  HOME_SUBCATEGORY_PLANT_DECOR,
  HOME_SUBCATEGORY_CURTAINS_BLINDS,
} from "../locales/keysTranslations";

export const influencersCategories = [
  "Lifestyle",
  "Fashion",
  "Media",
  "Entertainment",
  "Education",
  "Foodie",
  "Fitness",
  "Pets",
  "Model",
  "Technology",
  "Productivity",
  "Cooking",
  "Business",
  "Wellness",
  "Arts & Culture",
  "Sports",
  "Music",
  "Travel",
  "Beauty",
  "Environment",
  "Home",
];

export const mapCategoriesToTopicPhyllo = (category, language) => {
  switch (category) {
    case "Lifestyle":
      return "lifestyle";
    case "Fashion":
      if (language === "es") return "modas";
      return "fashionable";
    case "Media":
      return "media";
    case "Entertainment":
      if (language === "es") return "entretenimiento";
      return "entertainment";
    case "Education":
      if (language === "es") return "educacion";
      return "education";
    case "Foodie":
      if (language === "es") return "comida";
      return "foodie";
    case "Fitness":
      return "fitness";
    case "Pets":
      if (language === "es") return "mascotas";
      return "pets";
    case "Model":
      if (language === "es") return "modelo";
      return "model";
    case "Technology":
      if (language === "es") return "tecnologia";
      return "technology";
    case "Productivity":
      return "productivity";
    case "Cooking":
      if (language === "es") return "cocina";
      return "cooking";
    case "Business":
      if (language === "es") return "negocios";
      return "business";
    case "Wellness":
      if (language === "es") return "bienestar";
      return "wellness";
    case "Arts & Culture":
      if (language === "es") return "artes";
      return "arts";
    case "Sports":
      if (language === "es") return "deportes";
      return "sports";
    case "Music":
      return "musical";
    case "Travel":
      if (language === "es") return "viajes";
      return "travel";
    case "Beauty":
      return "beautyinfluencer";
    case "Environment":
      if (language === "es") return "ambiente";
      return "environment";
    case "Home":
      if (language === "es") return "hogar";
      return "home";
    default:
      return category;
  }
};
export const mapTopicPhylloToCategory = (category) => {
  switch (category) {
    case "lifestyle":
      return "Lifestyle";
    case "fashionable":
    case "modas":
      return "Fashion";
    case "media":
      return "Media";
    case "entertainment":
    case "entretenimiento":
      return "Entertainment";
    case "education":
    case "educacion":
      return "Education";
    case "foodie":
    case "comida":
      return "Foodie";
    case "fitness":
      return "Fitness";
    case "pets":
    case "mascotas":
      return "Pets";
    case "model":
    case "modelo":
      return "Model";
    case "technology":
    case "tecnologia":
      return "Technology";
    case "productivity":
      return "Productivity";
    case "cooking":
    case "cocina":
      return "Cooking";
    case "business":
    case "negocios":
      return "Business";
    case "wellness":
    case "bienestar":
      return "Wellness";
    case "arts":
    case "artes":
      return "Arts & Culture";
    case "sports":
    case "deportes":
      return "Sports";
    case "musical":
      return "Music";
    case "travel":
    case "viajar":
      return "Travel";
    case "beautyinfluencer":
      return "Beauty";
    case "environment":
    case "ambiente":
      return "Environment";
    case "home":
    case "hogar":
      return "Home";
    default:
      return category;
  }
};

export const mapCategories = (category) => {
  switch (category) {
    case "Music":
      return ["Music"];

    case "Sports":
      return ["Sports"];

    case "Pets":
      return ["Pets"];

    case "Beauty & Cosmetics":
    case "Beauty":
      return ["Beauty & Cosmetics", "Beauty"];

    case "Fitness & Yoga":
    case "Fitness":
      return ["Fitness & Yoga", "Fitness"];

    case "Home Decor, Furniture & Garden":
    case "Home":
    case "Environment":
      return ["Environment", "Home Decor, Furniture & Garden", "Home"];

    case "Healthy Lifestyle":
    case "Lifestyle":
    case "Wellness":
      return ["Wellness", "Healthy Lifestyle", "Lifestyle"];

    case "Clothes, Shoes, Handbags & Accessories":
    case "Fashion":
    case "Model":
      return ["Clothes, Shoes, Handbags & Accessories", "Model", "Fashion"];

    case "Television & Film":
    case "Media":
    case "Entertainment":
      return ["Entertainment", "Television & Film", "Media"];

    case "Toys, Children & Baby":
    case "Education":
      return ["Toys, Children & Baby", "Education"];

    case "Restaurants, Food & Grocery":
    case "Foodie":
    case "Cooking":
      return ["Restaurants, Food & Grocery", "Cooking", "Foodie"];

    case "Electronics & Computers":
    case "Technology":
      return ["Electronics & Computers", "Technology"];

    case "Productivity":
    case "Business & Careers":
    case "Business":
      return ["Business & Careers", "Business", "Productivity"];

    case "Art & Design":
    case "Arts & Culture":
      return ["Art & Design", "Arts & Culture"];

    case "Travel, Tourism & Aviation":
    case "Travel":
      return ["Travel, Tourism & Aviation", "Travel"];

    case "Friends, Family & Relationships":
      return ["Friends, Family & Relationships"];

    case "Camera & Photography":
      return ["Camera & Photography"];

    case "Luxury Goods":
      return ["Luxury Goods"];

    case "Activewear":
      return ["Activewear"];

    case "Wedding":
      return ["Wedding"];

    case "Shopping & Retail":
      return ["Shopping & Retail"];

    case "Jewellery & Watches":
      return ["Jewellery & Watches"];

    case "Gaming":
      return ["Gaming"];

    case "Healthcare & Medicine":
      return ["Healthcare & Medicine"];

    case "Tobacco & Smoking":
      return ["Tobacco & Smoking"];

    default:
      return [category];
  }
};

export const CATEGORIES_PHYLLO = [
  "Television & Film",
  "Music",
  "Shopping & Retail",
  "Coffee, Tea & Beverages",
  "Camera & Photography",
  "Clothes, Shoes, Handbags & Accessories",
  "Beer, Wine & Spirits",
  "Sports",
  "Electronics & Computers",
  "Gaming",
  "Activewear",
  "Art & Design",
  "Travel, Tourism & Aviation",
  "Business & Careers",
  "Beauty & Cosmetics",
  "Healthcare & Medicine",
  "Jewellery & Watches",
  "Restaurants, Food & Grocery",
  "Toys, Children & Baby",
  "Fitness & Yoga",
  "Wedding",
  "Tobacco & Smoking",
  "Pets",
  "Healthy Lifestyle",
  "Luxury Goods",
  "Home Decor, Furniture & Garden",
  "Friends, Family & Relationships",
  "Cars & Motorbikes",
];

export let NEW_CREATORS_CATEGORY = NEW_CREATORS_CATEGORY_TRANSLATION;

export const subCategories = {
  restaurants: [
    RESTAURANTS_SUBCATEGORY_FOOD,
    RESTAURANTS_SUBCATEGORY_DRINKS,
    RESTAURANTS_SUBCATEGORY_DESSERTS,
    RESTAURANTS_SUBCATEGORY_COFFEE,
    RESTAURANTS_SUBCATEGORY_HOT_DRINKS,
    RESTAURANTS_SUBCATEGORY_COLD_DRINKS,
    RESTAURANTS_SUBCATEGORY_SOFT_DRINKS,
    RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS,
    RESTAURANTS_SUBCATEGORY_SUSHI,
    RESTAURANTS_SUBCATEGORY_ASIAN,
    RESTAURANTS_SUBCATEGORY_JAPANESE,
    RESTAURANTS_SUBCATEGORY_CHINESE,
    RESTAURANTS_SUBCATEGORY_ITALIAN,
    RESTAURANTS_SUBCATEGORY_MEXICAN,
    RESTAURANTS_SUBCATEGORY_HEALTHY,
    RESTAURANTS_SUBCATEGORY_VEGAN,
    RESTAURANTS_SUBCATEGORY_VEGETARIAN,
  ],
  foods: [
    FOODS_SUBCATEGORY_SEASONINGS,
    FOODS_SUBCATEGORY_FOOD,
    FOODS_SUBCATEGORY_GRANOLA,
    FOODS_SUBCATEGORY_DRIED_FRUITS,
    FOODS_SUBCATEGORY_HEALTHY,
  ],
  travel: [
    TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM,
    TRAVEL_SUBCATEGORY_RURAL_TOURISM,
    TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM,
    TRAVEL_SUBCATEGORY_NATURE_TOURISM,
    TRAVEL_SUBCATEGORY_CULTURAL_TOURISM,
    TRAVEL_SUBCATEGORY_HEALTH_TOURISM,
  ],
  entertainment: [ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT],
  technology: [
    TECHNOLOGY_SUBCATEGORY_PHONES,
    TECHNOLOGY_SUBCATEGORY_COMPUTERS,
    TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES,
    TECHNOLOGY_SUBCATEGORY_ACCESSORIES,
    TECHNOLOGY_SUBCATEGORY_PERIPHERALS,
    TECHNOLOGY_SUBCATEGORY_ELECTRONICS,
  ],
  fitness: [
    FITNESS_SUBCATEGORY_SPORTSWEAR,
    FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES,
    FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS,
    FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT,
  ],
  fashion: [
    FASHION_SUBCATEGORY_HAUTE_COUTURE,
    FASHION_SUBCATEGORY_HIPSTER,
    FASHION_SUBCATEGORY_CASUAL,
    FASHION_SUBCATEGORY_CLASSIC,
    FASHION_SUBCATEGORY_ROMANTIC,
    FASHION_SUBCATEGORY_SPORTSWEAR,
    FASHION_SUBCATEGORY_PREPPY,
    FASHION_SUBCATEGORY_GRUNGE,
    FASHION_SUBCATEGORY_VINTAGE,
  ],
  beauty: [
    BEAUTY_SUBCATEGORY_COSMETICS,
    BEAUTY_SUBCATEGORY_EYE_CARE,
    BEAUTY_SUBCATEGORY_FEMININE_HYGIENE,
    BEAUTY_SUBCATEGORY_FIRST_AID,
    BEAUTY_SUBCATEGORY_HAIR_CARE,
    BEAUTY_SUBCATEGORY_MEDICAL_DEVICES,
    BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT,
    BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS,
    BEAUTY_SUBCATEGORY_MEDICATIONS,
    BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE,
    BEAUTY_SUBCATEGORY_SCALES,
    BEAUTY_SUBCATEGORY_SKIN_CARE,
    BEAUTY_SUBCATEGORY_VITAMINS,
    BEAUTY_SUBCATEGORY_FACIAL_TISSUES,
  ],
  pets: [
    PETS_SUBCATEGORY_FOOD,
    PETS_SUBCATEGORY_COSMETICS,
    PETS_SUBCATEGORY_ACCESSORIES,
    PETS_SUBCATEGORY_CLOTHING,
    PETS_SUBCATEGORY_MEDICATIONS,
  ],
  home: [
    HOME_SUBCATEGORY_TEXTILES_CARPETS,
    HOME_SUBCATEGORY_ART_PRINTS,
    HOME_SUBCATEGORY_STORAGE_ORGANIZATION,
    HOME_SUBCATEGORY_PLANT_DECOR,
    HOME_SUBCATEGORY_CURTAINS_BLINDS,
  ],
};

export const generalCategories = [
  { label: RESTAURANTS, value: "restaurants" },
  { label: FOODS, value: "foods" },
  { label: TRAVEL, value: "travel" },
  { label: ENTERTAINMENT, value: "entertainment" },
  { label: TECHNOLOGY, value: "technology" },
  { label: FITNESS, value: "fitness" },
  { label: FASHION, value: "fashion" },
  { label: BEAUTY, value: "beauty" },
  { label: PETS, value: "pets" },
  { label: HOME, value: "home" },
];
