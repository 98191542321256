import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { numberCompactFormat } from "../../../utils/numberFormats";
import TooltipModal from "../Tooltips/TooltipModal";
import ContentAudience from "../Tooltips/Content/ContentAudience";
import { useTranslationApp } from "../../../lib/i18next";
import {
  AUDIENCE,
  AUDIENCE_TITLE_EXPLANATION,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import { GREY_SLIDER_COLOR } from "../../../utils/colors";
import { MAX_FOLLOWERS } from "../../../utils/constants";
import { marksInfluencersMax500K } from "../../../utils/slidersMarks";
import useMediaQuery from "@mui/material/useMediaQuery";
function valuetext(value) {
  return `${numberCompactFormat(value)}`;
}

const InfluencerRangeFilter = ({
  rangeFollowers,
  onChangeRange,
  title = i18next.t(AUDIENCE),
  variantText = "h6",
  sx,
}) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "calc(100% - 8px)",
        px: 1,
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <TooltipModal
          title={t(AUDIENCE_TITLE_EXPLANATION)}
          content={<ContentAudience />}
        />
      </Box>
      <Slider
        getAriaLabel={() => "Influencer Range"}
        value={rangeFollowers}
        onChange={(e, value) => onChangeRange(value, e)}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1000}
        marks={marksInfluencersMax500K.filter((mark, index) => {
          if (isMobile) {
            if (index === 0) {
              return mark;
            }
            if (index % 2) {
              return mark;
            } else {
              return false;
            }
          }

          return mark;
        })}
        min={0}
        max={MAX_FOLLOWERS}
        valueLabelFormat={valuetext}
        sx={{
          ".MuiSlider-track": {
            height: 2,
          },
          ".MuiSlider-rail": {
            backgroundColor: GREY_SLIDER_COLOR,
            height: 4,
          },
          ".MuiSlider-mark": {
            backgroundColor: "transparent",
          },
        }}
      />
    </Box>
  );
};

export default InfluencerRangeFilter;
