import { DUMMY_USER, DUMMY_USER2 } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  users: {},
  contacts: {},
  isLoading: true,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USERS_CREATE_ADMIN_ACCOUNT:
      return {
        ...state,
        users: {
          [action.payload.uid]: action.payload,
          ...state.users,
        },
      };
    case types.USERS_GET_ADMIN_ACCOUNTS_FINISH:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };
    case types.USERS_GET_NEXT_ADMIN_ACCOUNTS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.USERS_GET_USERS_BY_SHOPID_FINISH:
      return {
        ...state,
        users: {
          ...action.payload,
        },
        isLoading: false,
      };
    case types.USERS_GET_CONTACTS_BY_SHOPID_FINISH:
      return {
        ...state,
        contacts: {
          ...action.payload,
        },
        isLoading: false,
      };
    case types.USERS_CREATE_ACCOUNTS_BY_ADMIN:
      return {
        ...state,
        users: {
          [action.payload.uid]: action.payload,
          ...state.users,
        },
      };
    case types.EDIT_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.userID]: action.payload.data,
        },
      };
    case types.DELETE_USER:
      delete state.users[action.payload];
      return {
        ...state,
        users: {
          ...state.users,
        },
      };
    case types.USERS_CREATE_CONTACT:
      return {
        ...state,
        contacts: {
          ...action.payload,
          ...state.contacts,
        },
      };
    case types.USERS_EDIT_CONTACT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.USERS_DELETE_CONTACT:
      delete state.contacts[action.payload];
      return {
        ...state,
      };
    case types.CREATE_USER_ACCOUNT_BY_OWNER:
      return {
        ...state,
        users: {
          [action.payload.userID]: action.payload.data,
          ...state.users,
        },
      };
    case types.EDIT_USER_ACCOUNT_BY_OWNER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.userID]: {
            ...state.users[action.payload.userID],
            role: action.payload.role,
            businessID: action.payload.businessID,
            businessName: action.payload.businessName,
            shopIDs: action.payload.shopIDs,
            shopID: action.payload.shopID,
            storeID: action.payload.storeID,
            email: action.payload.email,
            name: action.payload.name,
          },
        },
      };
    case types.DELETE_USER_ACCOUNT_BY_OWNER:
      delete state.users[action.payload];
      return {
        ...state,
        users: {
          ...state.users,
        },
      };
    case types.ACCEPT_SHOP_APPLICANT:
      return {
        ...state,
        users: {
          [action.payload.userID]: action.payload.user,
          ...state.users,
        },
      };
    case types.SET_USERS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.USERS_LOADING_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const {
        [DUMMY_USER]: userDeleted,
        [DUMMY_USER2]: userDeleted2,
        ...users
      } = state.users;

      return {
        ...state,
        users: users,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
