import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import CardMedia from "../../Common/Media/CardMedia";
import Avatar from "@mui/material/Avatar";
import LinkText from "../../Common/Texts/LinkText";
import { getLinkRedirect } from "../../../utils/urls";
import { getIsBlockedActions, getRole } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import Rating from "../../Common/Forms/Rating/Rating";
import Typography from "@mui/material/Typography";
import {
  FOLLOWERS_ABBREVIATED,
  ENGAGEMENT_ABBREVIATED,
  LAST_COLLABORATION,
  BUTTON_DISCARD,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import InfluencerCategories from "../../Common/Influencer/InfluencerCategories";
import {
  capitalizeFirstLetter,
  getCityInfluencer,
} from "../../../utils/formats";
import InfluencerIsFavoriteIcon from "../../Common/Influencer/InfluencerIsFavoriteIcon";
import { logoPlatform } from "../../../utils/socialNetworks";
import ContainerOverlay from "../../Common/Containers/ContainerOverlay";
import { randomNumberBetween } from "../../../utils/numbers";
import ButtonInfluencerActions from "../../Common/Influencer/ButtonInfluencerActions";
import {
  numberCompactFormat,
  numberDecimal,
} from "../../../utils/numberFormats";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import GradeIcon from "@mui/icons-material/Grade";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { startOpenInfluencerConversation } from "../../../actions/chat";
import useDraggableCard from "../../../hooks/useDraggableComponent";
import InfluencerLastCollaboration from "../../Common/Influencer/InfluencerLastCollaboration";
import { BUTTON_INFLUENCER_ACTIONS } from "../../../onboarding/stepsSelectors";
import Button from "../../Common/Buttons/Button";

const commonSxContainerRow = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};
const commonSxContainerNameAndImage = {
  display: "flex",
  alignItems: "center",
  gap: 1,
};
const commonSxImage = {
  width: 30,
  height: 30,
};
const commonSxSubTitleItems = {
  fontWeight: 900,
};

const InfluencerCard = React.memo(
  ({
    data,
    onDiscardInfluencer = () => {},
    onClickCloseButton = () => {},
    onCallbackClickCard = () => {},
    showCloseButton = false,
  }) => {
    const dispatch = useDispatchApp();
    const { t } = useTranslationApp();
    const role = dispatch(getRole());
    const refButton = useRef();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const contents = data?.topPlatformContents || [];
    const content =
      contents[randomNumberBetween(0, contents?.length - 1)] || {};

    const borderRadius = isMobile ? "10px" : "5px";

    const onOpenChatInfluencer = async () => {
      const isBlocked = dispatch(
        getIsBlockedActions({
          verifyBlockedLastInvite: true,
          verifyBlockedFreeTrial: true,
        })
      );
      if (isBlocked) return;
      await dispatch(
        startOpenInfluencerConversation({
          userID: data.userID,
        })
      );
    };

    const onHandleDecision = (goRight) => {
      if (!goRight) {
        onDiscardInfluencer(data.userID);
      }
    };

    const { cardRef, startDrag, isDragging } = useDraggableCard({
      onCallback: onHandleDecision,
      disabledDragRight: true,
      disabledDragLeft: true,
    });

    useEffect(() => {
      if (!isMobile) return;
      if (!cardRef.current?.scrollIntoView) return;
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      // eslint-disable-next-line
    }, [isMobile]);

    return (
      <Box
        data-tour={BUTTON_INFLUENCER_ACTIONS}
        className="card"
        ref={cardRef}
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
          minHeight: { xs: "calc(100vh - 160px)", xl: 550 },
          cursor: "pointer",
          "&:hover": {
            filter: "brightness(0.9)",
          },
          userSelect: "none",
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
        }}
        onMouseDown={startDrag}
        onTouchStart={startDrag}
        onClick={() => {
          if (isDragging) return;
          refButton?.current?.openModalActions();
          onCallbackClickCard();
        }}
      >
        <CardMedia
          url={content?.mediaUrl || data.imageURL}
          sx={{
            borderRadius,
            backgroundColor: "transparent",
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
          propsType={{
            muted: true,
            autoplay: true,
            controls: false,
            loop: true,
            draggable: false,
          }}
          onceLoadLazy={false}
        />
        <ContainerOverlay
          direction="bottom"
          sx={{
            borderRadius,
            top: 0,
            left: 0,
            height: "15%",
          }}
        >
          <Box sx={commonSxContainerRow}>
            <Box sx={commonSxContainerNameAndImage}>
              <Avatar
                src={logoPlatform(data.mainPlatform)}
                sx={commonSxImage}
                variant="square"
              />
              <LinkText
                variant="body1"
                href={getLinkRedirect({
                  userID: data.userID,
                  role,
                  isExternal: false,
                  influencer: data,
                })}
                target="_blank"
                color="inherit"
                sx={{
                  textDecoration: "none",
                }}
              >
                {data.mainAccountName}
              </LinkText>
            </Box>
            <InfluencerIsFavoriteIcon
              userID={data.userID}
              showActions={true}
              modeColor="white"
              sx={{
                mb: 0,
                p: 0,
                mt: 0.2,
                fontSize: "1.1em",
              }}
            />
          </Box>
        </ContainerOverlay>
        <ContainerOverlay
          direction="top"
          sx={{
            borderRadius,
            bottom: 0,
            left: 0,
          }}
        >
          <Box sx={commonSxContainerRow}>
            <InfluencerLastCollaboration
              userID={data.userID}
              variantText="caption"
              sx={{
                alignItems: "flex-start",
              }}
              sxText={{
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "none",
                  cursor: "pointer",
                },
              }}
              label={LAST_COLLABORATION}
            />
          </Box>
          <Box sx={commonSxContainerRow}>
            <Rating
              value={data.score}
              readOnly={true}
              precision={0.5}
              size="small"
            />
          </Box>
          <Box
            sx={{
              ...commonSxContainerRow,
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <Typography variant="caption" sx={commonSxSubTitleItems}>
              {`${t(FOLLOWERS_ABBREVIATED)}: ${
                numberCompactFormat(data.followers) || "-"
              }`}
            </Typography>
            <Typography variant="caption" sx={commonSxSubTitleItems}>
              {`${t(ENGAGEMENT_ABBREVIATED)}: ${
                `${numberDecimal(data.engagement)}%` || "-"
              }`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <FmdGoodIcon
                sx={{
                  width: 17,
                  height: 17,
                }}
              />
              <Typography variant="caption" sx={commonSxSubTitleItems}>
                {capitalizeFirstLetter(getCityInfluencer(data))}
              </Typography>
            </Box>
          </Box>
          <InfluencerCategories
            influencer={data}
            maxCategoriesRender={3}
            rows={3}
            size="small"
            mode="light"
            sx={{
              alignItems: "flex-start",
            }}
            sxGrid={{
              gridGap: 8,
            }}
            sxCategory={{
              backgroundColor: "primary.main",
              color: "white",
              borderRadius: "5px",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />

          <Box
            sx={{
              ...commonSxContainerRow,
              display: {
                xs: "flex",
                sm: "none",
              },
              justifyContent: "center",
              mt: 1,
              mb: 1,
              gap: 2,
            }}
          >
            <LoadingButton
              onClick={(e) => {
                e.stopPropagation();
                onDiscardInfluencer(data.userID);
              }}
              sx={{
                p: 1.5,
                minWidth: "unset",
                maxHeight: "unset",
                borderRadius: 25,
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                  filter: "brightness(0.9)",
                },
              }}
            >
              <CloseIcon
                sx={{ fontSize: "2rem", color: "primary.contrastText" }}
              />
            </LoadingButton>
            <LoadingButton
              sx={{
                p: 1.5,
                minWidth: "unset",
                maxHeight: "unset",
                borderRadius: 25,
                backgroundColor: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  filter: "brightness(0.9)",
                },
              }}
            >
              <GradeIcon
                sx={{ fontSize: "2rem", color: "secondary.contrastText" }}
              />
            </LoadingButton>
            <LoadingButton
              sx={{
                p: 2,
                minWidth: "unset",
                maxHeight: "unset",
                borderRadius: 25,
                backgroundColor: "tertiary.main",
                "&:hover": {
                  backgroundColor: "tertiary.main",
                  filter: "brightness(0.9)",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                onOpenChatInfluencer();
              }}
            >
              <ChatBubbleIcon
                sx={{ fontSize: "1.5rem", color: "tertiary.contrastText" }}
              />
            </LoadingButton>
          </Box>
          {showCloseButton && (
            <Box sx={commonSxContainerRow}>
              <Button
                color="primary"
                onClick={(e) => {
                  e?.stopPropagation();
                  onClickCloseButton();
                }}
                fullWidth
              >
                {t(BUTTON_DISCARD)}
              </Button>
            </Box>
          )}
        </ContainerOverlay>
        <Box
          sx={{
            display: "none",
          }}
        >
          <ButtonInfluencerActions
            ref={refButton}
            userID={data?.userID}
            influencer={data}
          />
        </Box>
      </Box>
    );
  }
);

export default InfluencerCard;
