import { capitalizeFirstLetter } from "./formats";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ReplyIcon from "@mui/icons-material/Reply";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  ACCOUNTS_REACHED,
  ADMIN,
  BASIC_PACKAGE_DESCRIPTION,
  BASIC_PACKAGE_NAME,
  BASIC_PACKAGE_SUB_HEADER,
  COMMENTS,
  CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION,
  CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION,
  CREATE_CAMPAIGN_GOAL_LEADS_OPTION,
  CREATE_CAMPAIGN_GOAL_SALES_OPTION,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED,
  DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER,
  DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER,
  DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER,
  DESIGNER,
  ENTERPRISE_PACKAGE_DESCRIPTION,
  ENTERPRISE_PACKAGE_NAME,
  ENTERPRISE_PACKAGE_SUB_HEADER,
  GROWTH_PACKAGE_DESCRIPTION,
  GROWTH_PACKAGE_NAME,
  GROWTH_PACKAGE_SUB_HEADER,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  LIKES,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION,
  // MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_TOP_FIVE_OPTION,
  MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC,
  MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
  MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING,
  MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES,
  MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION,
  MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED,
  MODAL_SELECT_PACKAGE_BENEFIT_CATALOG,
  MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM,
  MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY,
  MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL,
  MODAL_SELECT_PACKAGE_BENEFIT_KAM,
  MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS,
  MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS,
  MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT,
  MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS,
  MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT,
  OPERATOR,
  PRO_PACKAGE_DESCRIPTION,
  PRO_PACKAGE_NAME,
  PRO_PACKAGE_SUB_HEADER,
  PUBLICATIONS_STATUS_FEEDBACK,
  PUBLICATIONS_STATUS_PENDING_ANALYTICS,
  PUBLICATIONS_STATUS_SENT,
  PUBLICATIONS_STATUS_VERIFIED,
  PUBLICATION_STATUS_PENDING,
  PUBLICATION_STATUS_PENDING_APPROVAL,
  PUBLICATION_STATUS_PENDING_CONTENT,
  REPLIES,
  SAVES,
  SHARES,
  STICKERS_TAPS,
  UNKNOWN_FORMAT,
} from "../locales/keysTranslations";

export const BUSINESS_ID_PROJECT = process.env.REACT_APP_BUSINESS_ID_PROJECT;
export const MASTER_PASSWORD_BRANDS =
  process.env.REACT_APP_KEY_MASTER_PASSWORD_BRANDS;

export const FONT_FAMILY = "Inter";

export const MAX_WIDTH_ANSWER_CONTAINER = 780;

// LOCAL STORAGE
export const INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY = "influencersSuggested";
export const LOCAL_STORAGE_KEYS_CLEAR = [
  INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY,
];

//TAB CREATORS
export const TAB_CREATORS_INDEX = 0;
export const TAB_SUGGESTED_INDEX = 1;
export const TAB_REQUESTS_INDEX = 2;

//FILTER INFLUENCERS
export const CATEGORY_FILTER_TYPE = "category";
export const GENDER_FILTER_TYPE = "gender";
export const CITY_FILTER_TYPE = "city";
export const FOLLOWERS_FILTER_TYPE = "followers";
export const ENGAGEMENT_FILTER_TYPE = "engagement";
export const IS_PRE_REGISTERED_FILTER_TYPE = "isPreRegistered";
export const WAS_PRE_REGISTERED_FILTER_TYPE = "wasPreRegistered";

// ALERTS
export const ALERT_ICON_TYPE_SUCCESS = "success";
export const ALERT_ICON_TYPE_ERROR = "error";
export const ALERT_ICON_TYPE_INFO = "info";
export const ALERT_ICON_TYPE_WARNING = "warning";

// NOTIFICATIONS
export const NOTIFICATION_TYPE_NEW_MESSAGE = "new_message";
export const NOTIFICATION_TYPE_REQUEST = "request";

// ACCOMPANIMENTS COMPONENTS
export const ACCOMPANIMENT_MODE_CREATE = "create";
export const ACCOMPANIMENT_MODE_EDIT = "edit";
export const ACCOMPANIMENT_TYPE_EVENT_NAME = "accompanimentType";
export const ACCOMPANIMENT_EVENT_NAME = "accompaniments";
export const ACCOMPANIMENT_IS_MANDATORY_NAME = "isMandatory";

// ACCOUNT COMPONENTS
export const ACCOUNT_MODE_CREATE = "create";
export const ACCOUNT_MODE_EDIT = "edit";
export const SCHEDULE_EVENT_EDIT_TURN = "edit_turn";
export const SCHEDULE_EVENT_DELETE_TURN = "delete_turn";
export const SCHEDULE_EVENT_ADD_TURN = "add_turn";
export const MAX_HOUR_TIME = 2359;
export const MIN_LENGTH_IDENTIFICATION = 6;

export const REGULAR_STORE_TYPE = "regular";
export const JUSTO_STORE_TYPE = "justo";
export const SHOPIFY_STORE_TYPE = "shopify";
export const WORDPRESS_WOOCOMMERCE_STORE_TYPE = "woocommerce";

export const SPECIAL_STORES = [
  JUSTO_STORE_TYPE,
  SHOPIFY_STORE_TYPE,
  WORDPRESS_WOOCOMMERCE_STORE_TYPE,
];
export const SPECIAL_STORES_DATA = [
  {
    name: capitalizeFirstLetter(JUSTO_STORE_TYPE),
    value: JUSTO_STORE_TYPE,
    documentation:
      "https://glaze-blarney-9c2.notion.site/RedeemCode-con-Justo-671e89d90d744d61a519d7c170705456",
    documentationInvites:
      "https://glaze-blarney-9c2.notion.site/Creaci-n-de-cupones-e-invitaciones-justo-47aee3ec7b5c44fdbed0751f3b702943",
  },
  {
    name: capitalizeFirstLetter(SHOPIFY_STORE_TYPE),
    value: SHOPIFY_STORE_TYPE,
    documentation:
      "https://glaze-blarney-9c2.notion.site/Integraci-n-con-tiendas-de-Shopify-235292293bf84745bdd5b6deaedd64da",
    documentationInvites:
      "https://glaze-blarney-9c2.notion.site/Creaci-n-de-cupones-e-invitaciones-Shopify-a759f878af9b49e99aa91adc5d517ba7",
  },
  {
    name: "Woocommerce",
    value: WORDPRESS_WOOCOMMERCE_STORE_TYPE,
    documentation: "",
    documentationInvites: "",
  },
];

//INVITATION INFLUENCER
export const INVITATION_INFLUENCER_MODE_INVITE = "invite";
export const INVITATION_INFLUENCER_MODE_INVITE_INACTIVE = "inviteInactive";
export const INVITATION_INFLUENCER_MODE_REQUEST = "request";
export const INVITATION_INFLUENCER_MODE_CREATE = "create";
export const INVITATION_INFLUENCER_MODE_EDIT = "edit";
export const INVITATION_INFLUENCER_MODE_SUGGESTED = "suggested";
export const INVITATION_INFLUENCER_MODE_AMBASSADOR = "ambassador";
export const MIN_VALUE_INVITATION = 10000;
export const INVITATION_COMMENT_MAX_LENGTH = 3000;
export const DEFAULT_INVITE_EXPIRY_DAYS = 7;
export const INVITE_EXTENDED_EXPIRY_DAYS = 21;

//REQUESTS
export const REQUEST_STATUS_REJECTED = "rejected";
export const REQUEST_STATUS_ACCEPTED = "accepted";

// OWNER COMPONENTS
export const CREATE_USERS_ADMIN_MODE_CREATE = "create";
export const CREATE_USERS_ADMIN_MODE_EDIT = "edit";
export const CREATE_SHOPS_ADMIN_MODE_CREATE = "create";
export const CREATE_SHOPS_ADMIN_MODE_EDIT = "edit";

// BILLING DATA COMPONENTS
export const TYPE_PERSON_LEGAL = "legal";
export const TYPE_PERSON_NATURAL = "natural";

// IMAGES COMPONENTS
export const MAX_SIZE_IMAGE = 10485760; // 10MB
export const MAX_SIZE_FILES = 50000000; // 50MB
export const PLACE_HOLDER_IMAGE =
  "https://clapp-prod.s3.amazonaws.com/ClappIn/place-holder-product.jpeg";
export const PLACE_HOLDER_PERSON_ICON =
  "https://clapp-prod.s3.amazonaws.com/ClappIn/person-icon-default.png";

// POSTS STATUS
export const POST_STATUS_PENDING_CONTENT = "pendingContent";
export const POST_STATUS_PENDING_APPROVAL = "pendingApproval";
export const POST_STATUS_PENDING = "pending";
export const POST_STATUS_PENDING_SOCIAL = "pendingSocial";
export const POST_STATUS_ANALYTICS = "pendingAnalytics";
export const POST_STATUS_NEGATIVE_REVIEW = "negativeReview";
export const POST_STATUS_SENT = "sent";
export const POST_STATUS_REVIEWED = "reviewed";

export const COUNT_PATTERN_RRULE = 50;

// DELIVERIES
export const STATUS_DELIVERIES = [
  {
    value: "toBeAccepted",
    label: DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER,
  },
  {
    value: "inPreparation",
    label: DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER,
  },
  {
    value: "toBeDelivered",
    label: DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER,
  },
];
export const STATUS_DELIVERIES_APP = [
  {
    value: "pending",
    valueWeb: "toBeAccepted",
    label: DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING,
  },
  {
    value: "inPreparation",
    valueWeb: "inPreparation",
    label: DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION,
  },
  {
    value: "ready",
    valueWeb: "toBeDelivered",
    label: DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED,
  },
  {
    value: "delivered",
    valueWeb: "toBeDelivered",
    label: DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED,
  },
  {
    value: "cancelled",
    valueWeb: null,
    label: DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED,
  },
  {
    value: "rejected",
    valueWeb: null,
    label: DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED,
  },
];
export const DOCUMENTS_TYPES = [
  {
    value: "cc",
    label: "Cédula de Ciudadanía",
  },
  {
    value: "ce",
    label: "Cédula Extranjería",
  },
  {
    value: "nuip",
    label: "NUIP",
  },
  {
    value: "nit",
    label: "NIT",
  },
];

export const collabsFormats = [
  {
    value: "instagramPost",
    label: INVITE_FORM_FORMAT_INSTAGRAM_POST,
  },
  {
    value: "instagramReel",
    label: INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  },
  {
    value: "instagramStory",
    label: INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  },
  {
    value: "tiktokPost",
    label: INVITE_FORM_FORMAT_TIKTOK_POST,
  },
  {
    value: "unknown",
    label: UNKNOWN_FORMAT,
  },
];

export const GOAL_CAMPAIGN_OPTIONS = [
  {
    value: "brandAwareness",
    label: CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION,
  },
  {
    value: "engagement",
    label: CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION,
  },
  {
    value: "leads",
    label: CREATE_CAMPAIGN_GOAL_LEADS_OPTION,
  },
  {
    value: "sales",
    label: CREATE_CAMPAIGN_GOAL_SALES_OPTION,
  },
];
export const FORMATS_INVITES = {
  instagramBurstStories: false,
  instagramPost: false,
  instagramReel: false,
  instagramStory: false,
  tiktokPost: false,
  tiktokStory: false,
};

export const WITH_TEXT_SOCIAL_MEDIA_CONTENT = "withText";
export const SOME_TEXT_SOCIAL_MEDIA_CONTENT = "someText";
export const WITHOUT_TEXT_SOCIAL_MEDIA_CONTENT = "withoutText";

export const TOP_FIVE_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT = "topFive";
export const EDUCATIONAL_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT =
  "educational";
export const QUOTES_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT = "quotes";
export const CURIOUS_FACTS_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT =
  "curiousFacts";
export const RHYMES_OR_POEMS_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT =
  "rhymesOrPoems";
export const HUMOR_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT = "humor";
export const OPTIONS_TONE_GENERATE_SOCIAL_MEDIA_CONTENT = [
  {
    value: EDUCATIONAL_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT,
    label: MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION,
  },
  {
    value: QUOTES_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT,
    label: MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION,
  },
  {
    value: CURIOUS_FACTS_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT,
    label: MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION,
  },
  {
    value: RHYMES_OR_POEMS_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT,
    label:
      MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION,
  },
  {
    value: HUMOR_OPTION_TONE_GENERATE_SOCIAL_MEDIA_CONTENT,
    label: MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION,
  },
];

export const UGC_FORMAT_GRWM_OPTION = "grwn";
export const UGC_FORMAT_OODT_OPTION = "oddt";
export const UGC_FORMAT_HAULS_OPTION = "hauls";
export const UGC_FORMAT_DIYS_OPTION = "diys";
export const UGC_FORMAT_UNBOXING_OPTION = "unboxing";
export const UGC_FORMAT_CHALLENGES_OPTION = "challenges";
export const UGC_FORMAT_REVIEWS_OPTION = "reviews";
export const UGC_FORMAT_VLOGS_OPTION = "vlogs";
export const UGC_FORMAT_QA_OPTION = "qa";
export const UGC_FORMAT_STORYTIME_OPTION = "storyTime";
export const UGC_FORMAT_ROOM_TOURS_OPTION = "roomTours";
export const UGC_FORMAT_WHATS_IN_MY_BAG_OPTION = "whatsInMyBag";
export const UGC_FORMAT_COOKING_OPTION = "cooking";
export const UGC_FORMAT_FITNESS_ROUTINES_OPTION = "fitnessRoutines";
export const UGC_FORMAT_ASMR_OPTION = "asmr";
export const UGC_FORMAT_PLAN_WITH_ME_OPTION = "planWithMe";
export const UGC_FORMAT_MUKBANG_OPTION = "mukbang";
export const UGC_FORMAT_DAY_IN_THE_LIFE_OPTION = "dayInTheLife";
export const UGC_FORMAT_BEHIND_THE_SCENES_OPTION = "behindTheScenes";
export const UGC_FORMAT_TRAVEL_DIARIES_OPTION = "travelDiaries";

export const ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION = "awareness";
export const ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION = "traffic";
export const ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION = "engagement";
export const ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION = "leads";
export const ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION = "appPromotion";
export const ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION = "sales";
export const ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META = [
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
];
export const ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION = "daily";
export const ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION = "total";
export const ADS_CAMPAIGN_CONTEXT_MIN_LENGTH = 200;
export const ADS_CAMPAIGN_CONTEXT_MAX_LENGTH = 1000;

export const ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE = "interests";
export const ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE = "behaviors";
export const ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE =
  "demographics";
export const ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE = "geographic";

export const ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH = 300;
export const ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH = 100;

export const ADS_CAMPAIGN_FORM_MODE_SIMPLE = "simple";
export const ADS_CAMPAIGN_FORM_MODE_ADVANCED = "advanced";
export const ADS_CAMPAIGN_MODE_PREVIEW = "preview";
export const ADS_CAMPAIGN_MODE_EDIT = "edit";

export const SEARCH_INFLUENCERS_TOPICS_OPTION = "topics";
export const SEARCH_INFLUENCERS_USERNAME_OPTION = "name";

export const FROM_DATA_INFLUENCERS = "influencers";
export const FROM_DATA_PHYLLO = "phyllo";

export const COUNTRIES_WITH_ONLY_ONE_LOCATION = ["pr"];

export const BODY_BUILD_THIN_VALUE = "thin";
export const BODY_BUILD_AVERAGE_VALUE = "average";
export const BODY_BUILD_ATHLETIC_VALUE = "athletic";
export const BODY_BUILD_WIDE_VALUE = "wide";
export const BODY_BUILD_MUSCULAR_VALUE = "muscular";
export const BODY_BUILD_CURVY_VALUE = "curvy";

export const CLOTHING_STYLE_CASUAL_VALUE = "casual";
export const CLOTHING_STYLE_FORMAL_VALUE = "formal";
export const CLOTHING_STYLE_SPORTY_VALUE = "sporty";
export const CLOTHING_STYLE_BUSINESS_VALUE = "business";
export const CLOTHING_STYLE_ELEGANT_VALUE = "elegant";
export const CLOTHING_STYLE_TRENDY_VALUE = "trendy";
export const CLOTHING_STYLE_STREETWEAR_VALUE = "streetwear";
export const CLOTHING_STYLE_TRADITIONAL_VALUE = "traditional";
export const CLOTHING_STYLE_VINTAGE_VALUE = "vintage";
export const CLOTHING_STYLE_BOHEMIAN_VALUE = "bohemian";
export const CLOTHING_STYLE_CHIC_VALUE = "chic";
export const CLOTHING_STYLE_HIPSTER_VALUE = "hipster";
export const CLOTHING_STYLE_PUNK_VALUE = "punk";
export const CLOTHING_STYLE_GOTHIC_VALUE = "gothic";

export const DISTINGUISHING_FEATURES_TATTOOS_VALUE = "tattoos";
export const DISTINGUISHING_FEATURES_SCARS_VALUE = "scars";
export const DISTINGUISHING_FEATURES_BIRTHMARKS_VALUE = "birthmarks";
export const DISTINGUISHING_FEATURES_FRECKLES_VALUE = "freckles";
export const DISTINGUISHING_FEATURES_MOLES_VALUE = "moles";
export const DISTINGUISHING_FEATURES_PIERCINGS_VALUE = "piercings";
export const DISTINGUISHING_FEATURES_DIMPLES_VALUE = "dimples";
export const DISTINGUISHING_FEATURES_WRINKLES_VALUE = "wrinkles";
export const DISTINGUISHING_FEATURES_GLASSES_VALUE = "glasses";
export const DISTINGUISHING_FEATURES_BRACES_VALUE = "braces";
export const DISTINGUISHING_FEATURES_MAKEUP_VALUE = "makeup";
export const DISTINGUISHING_FEATURES_HAIR_ACCESSORIES_VALUE = "hairAccessories";
export const DISTINGUISHING_FEATURES_BEARD_VALUE = "beard";
export const DISTINGUISHING_FEATURES_MUSTACHE_VALUE = "mustache";

export const CONTENT_STYLE_ELEGANT_VALUE = "elegant";
export const CONTENT_STYLE_AESTHETIC_VALUE = "aesthetic";
export const CONTENT_STYLE_RAW_VALUE = "raw";
export const CONTENT_STYLE_MINIMALISTIC_VALUE = "minimalistic";
export const CONTENT_STYLE_ARTISTIC_VALUE = "artistic";
export const CONTENT_STYLE_VINTAGE_VALUE = "vintage";
export const CONTENT_STYLE_MODERN_VALUE = "modern";
export const CONTENT_STYLE_PLAYFUL_VALUE = "playful";
export const CONTENT_STYLE_RUSTIC_VALUE = "rustic";
export const CONTENT_STYLE_MONOCHROMATIC_VALUE = "monochromatic";

export const NAME_BUSINESS = "Buzzly";
export const EMAIL_SUPPORT = "heybuzzly@heybuzzly.com";

// PLANS
export const PLAN_SME = "sme";
export const PLAN_CORPORATE = "corporate";
export const PLAN_CUSTOM = "custom";
export const PLAN_COLLABORATION = "collaboration";
export const PLAN_BASIC = "basic";
export const PLAN_PRO = "pro";
export const PLAN_GROWTH = "growth";
export const PLAN_ENTERPRISE = "enterprise";
export const PLAN_TRIAL = "trial";
export const PAYMENT_APPLICANT = "applicant";
export const PAYMENT_AMBASSADOR = "ambassador";
export const PAYMENT_INFLUENCER = "influencer";
export const PAYMENT_INVITATION = "invitation";
export const SUBSCRIPTION_PRO = "pro";
export const GENERAL_REDEMPTION = "generalRedemption";

export const PLANS_SUBSCRIPTION = [PLAN_SME, PLAN_CORPORATE];
export const PLANS_PACKAGE = [
  PLAN_BASIC,
  PLAN_PRO,
  PLAN_GROWTH,
  PLAN_ENTERPRISE,
];

export const PLANS_INFO = {
  [PLAN_BASIC]: {
    name: BASIC_PACKAGE_NAME,
    subHeader: BASIC_PACKAGE_SUB_HEADER,
    description: BASIC_PACKAGE_DESCRIPTION,
  },
  [PLAN_PRO]: {
    name: PRO_PACKAGE_NAME,
    subHeader: PRO_PACKAGE_SUB_HEADER,
    description: PRO_PACKAGE_DESCRIPTION,
  },
  [PLAN_GROWTH]: {
    name: GROWTH_PACKAGE_NAME,
    subHeader: GROWTH_PACKAGE_SUB_HEADER,
    description: GROWTH_PACKAGE_DESCRIPTION,
  },
  [PLAN_ENTERPRISE]: {
    name: ENTERPRISE_PACKAGE_NAME,
    subHeader: ENTERPRISE_PACKAGE_SUB_HEADER,
    description: ENTERPRISE_PACKAGE_DESCRIPTION,
  },
};

export const PLANS_BENEFITS = {
  [PLAN_BASIC]: [
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS,
    MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
    MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION,
    MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS,
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM,
  ],
  [PLAN_PRO]: [
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS,
    MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
    MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION,
    MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS,
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM,
  ],
  [PLAN_GROWTH]: [
    MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC,
    MODAL_SELECT_PACKAGE_BENEFIT_KAM,
    MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY,
    MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT,
    MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING,
  ],
  [`${PLAN_GROWTH}_ONLY`]: [
    MODAL_SELECT_PACKAGE_BENEFIT_CATALOG,
    MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION,
    MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES,
    MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS,
    MODAL_SELECT_PACKAGE_BENEFIT_KAM,
    MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY,
    MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT,
    MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING,
  ],
  [PLAN_ENTERPRISE]: [
    MODAL_SELECT_PACKAGE_BENEFIT_KAM,
    MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT,
    MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL,
    MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT,
  ],
  [`${PLAN_ENTERPRISE}_ONLY`]: [
    MODAL_SELECT_PACKAGE_BENEFIT_CATALOG,
    MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION,
    MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES,
    MODAL_SELECT_PACKAGE_BENEFIT_KAM,
    MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY,
    MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
    MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT,
    MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING,
    MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED,
    MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS,
    MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL,
    MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT,
  ],
};

export const COST_FOR_COLLABORATION = 29900;

export const CLAPP_CREDITS = "credits";

//PAYMENT MODELS
export const PAYMENT_MODEL_USE = "use";
export const PAYMENT_MODEL_SUBSCRIPTION = "subscription";
export const PAYMENT_MODEL_PACKAGE = "package";

//PRE REGISTERED INFLUENCERS PROCESS
export const PRE_REGISTERED_INFLUENCER_INVITE_PROCESS = "invite";
export const PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS =
  "inviteCampaign";
export const PRE_REGISTERED_INFLUENCER_PAYMENT_PROCESS = "payment";
export const PRE_REGISTERED_INFLUENCER_CHAT_PROCESS = "chat";
export const PRE_REGISTERED_INFLUENCER_FAVORITE_PROCESS = "favorite";

//Time
export const MINUTES = "mins";
export const HOURS = "hours";
export const HOURS_TO_MINUTES = 60;
export const DAYS = "days";
export const DAYS_TO_MINUTES = 1440;
export const WEEKS = "weeks";
export const WEEKS_TO_MINUTES = 10080;

//Tables
export const DEFAULT_TABLE_PAGES = 50;
export const DEFAULT_CREATORS_CARDS = 12;

export const ORDER_TYPE_STATISTICS = "order";
export const APPLICANT_TYPE_STATISTICS = "applicant";

//CAMPAIGNS
export const APPLICANT_STATUS_PENDING = "pending";
export const APPLICANT_STATUS_REJECTED = "rejected";
export const APPLICANT_STATUS_ACCEPTED = "accepted";
export const APPLICANT_STATUS_SUBMITTED = "submitted";
export const APPLICANT_STATUS_REVISIONS = "revisions";
export const APPLICANT_STATUS_PENDING_SOCIAL = "pendingSocial";
export const APPLICANT_STATUS_PENDING_ANALYTICS = "pendingAnalytics";
export const APPLICANT_STATUS_DELIVERED = "delivered";

export const CAMPAIGN_STATUS_ACTIVE = "active";
export const CAMPAIGN_STATUS_DRAFT = "draft";
export const CAMPAIGN_STATUS_FINISHED = "finished";

export const ADS_CAMPAIGN_STATUS_DRAFT = "draft";
export const ADS_CAMPAIGN_STATUS_ACTIVE = "active";
export const ADS_CAMPAIGN_STATUS_DEACTIVATED = "deactivated";
export const ADS_CAMPAIGN_STATUS_FINISHED = "finished";

export const VALIDATION_FROM_CAMPAIGN_DETAIL = "campaignDetail";
export const VALIDATION_FROM_CAMPAIGNS_TABLE = "campaignsTable";

//PAYMENTS APPLICANTS
export const APPLICANT_PAYMENT_STATUS_PENDING = "pending";
export const APPLICANT_PAYMENT_STATUS_PENDING_RELEASE = "pendingRelease";
export const APPLICANT_PAYMENT_STATUS_RELEASED = "released";

//TRANSACTIONS
export const CREATOR_PAYMENT_PENDING = "pending";
export const CREATOR_PAYMENT_PENDING_RELEASE = "pendingRelease";
export const CREATOR_PAYMENT_RELEASED = "released";
export const CREATOR_DEBIT_PENDING_SEND = "pendingSend";
export const CREATOR_DEBIT_IN_TRANSIT = "inTransit";
export const CREATOR_DEBIT_PAID = "paid";

//SHOPS APPLICANTS
export const SHOP_APPLICANT_COLOMBIA_STATUS = "co";
export const SHOP_APPLICANT_ECUADOR_STATUS = "ec";

//INVITATIONS EXTERNALS
export const INVITATION_INFLUENCER_EXTERNAL_PENDING = "pending";
export const INVITATION_INFLUENCER_EXTERNAL_REGISTERED = "registered";
export const INVITATION_INFLUENCER_EXTERNAL_ACCEPTED = "accepted";
export const INVITATION_INFLUENCER_EXTERNAL_REJECTED = "rejected";

//INFLUENCERS REFERENCE
export const MAX_REFERENCE_PROFILES = 3;

//COUNTRIES
export const COUNTRY_COLOMBIA = "co";
export const COUNTRY_DUBAI = "ae";

//Links Apps
export const LINK_APPLE =
  "https://apps.apple.com/app/clapp-content-creator-club/id6443504978";
export const LINK_ANDROID =
  "https://play.google.com/store/apps/details?id=com.heyflup.flup";
export const LINK_TO_WHATSAPP_ENTERPRISE =
  "https://api.whatsapp.com/send?phone=573102246494&text=%C2%A1Quiero%20inscribirme%20a%20un%20plan%20Enterprise!";
export const LINK_TO_WHATSAPP_SUPPORT_PRICES =
  "https://api.whatsapp.com/send?phone=573102246494&text=Estoy%20interesado%20en%20suscribirme%20a%20";
export const LINK_TO_WHATSAPP_SUPPORT_TECH =
  "https://api.whatsapp.com/send?phone=573102246494&text=Tengo%unas%2C%dudas%20p%C3%A1gina%20web%20me%20est%C3%A1%20mostrando%20este%20error%3A";
export const LINK_TO_WHATSAPP_SUPPORT_CONTENT =
  "https://api.whatsapp.com/send?phone=573102246494&text=Hola%2C%20tengo%20un%20problema%20con%20un%20contenido";
export const LINK_TO_WHATSAPP_RESET_PASSWORD =
  "https://api.whatsapp.com/send?phone=573102246494&text=Hola%2C%20quiero%20cambiar%20mi%20contrase%C3%B1a%2C%20mi%20correo%20es%20";
export const LINK_LANDING_CONTACT = "https://heybuzzly.com/contact";

//SOCIAL NETWORKS
export const INSTAGRAM = "instagram";
export const FACEBOOK = "facebook";
export const META = "meta";
export const META_WHITE = "metaWhite";
export const TIKTOK = "tiktok";
export const TIKTOK_BUSINESS = "tiktokBusiness";
export const YOUTUBE = "youtube";
export const PLATFORMS = [INSTAGRAM, TIKTOK];
export const INSTAGRAM_WHITE = "instagramWhite";

//INTEGRATION SCOPES
export const META_SCOPES = [
  "pages_show_list",
  "ads_management",
  "ads_read",
  "business_management",
  "instagram_basic",
  "instagram_content_publish",
  "pages_read_engagement",
];
export const TIKTOK_BUSINESS_SCOPES = [
  69, 40, 41, 42, 43, 44, 8, 20, 21, 150, 22, 152, 60, 61,
];

export const FEE_VALUE_DIRECT_PAYMENT = 0.1;
export const FEE_VALUE_CAMPAIGN = 0.2;

export const DRAWER_WIDTH = 220;

//OPTIONS

export const PLATFORMS_OPTIONS = [
  { value: INSTAGRAM, label: "Instagram" },
  { value: TIKTOK, label: "Tik Tok" },
];
export const GENDER_OPTIONS = [
  {
    value: "male",
    label: "Masculino",
  },
  {
    value: "female",
    label: "Femenino",
  },
  {
    value: "any",
    label: "Cualquiera",
  },
];

//CHAT
export const CHAT_BOT_USER_ID = "buzzlybot";
export const CHAT_FROM_AMBASSADOR = "ambassador";
export const CHAT_FROM_PUBLICATION = "publication";
export const CHAT_FROM_INFLUENCERS = "influencers";
export const CHAT_FROM_CAMPAIGN = "campaign";
export const CHAT_SUB_FROM_ADVERTISER = "advertiser";
export const CHAT_SUB_FROM_SHOP = "shop";

//SUPPORTED IMAGE FORMATS
export const IMAGE_FORMATS = ["jpeg", "jpg", "webp", "png"];
//SUPPORTED VIDEO FORMATS
export const VIDEO_FORMATS = [
  "mp4",
  "quicktime",
  "mov",
  "avi",
  "mpeg",
  "wmv",
  "mov",
  "m3u8",
];

export const BORDER_RADIUS = 3;

//CITIES CLAPP
export const CITIES_CLAPP = [
  { label: "Bogotá", value: "bogota" },
  { label: "Cartagena", value: "cartagena" },
  { label: "Barranquilla", value: "barranquilla" },
  { label: "Medellín", value: "medellin" },
  { label: "Cali", value: "cali" },
  { label: "Pereira", value: "pereira" },
  { label: "Monteria", value: "monteria" },
  { label: "Santa Marta", value: "santamarta" },
  { label: "Manizales", value: "manizales" },
  { label: "Armenia", value: "armenia" },
  { label: "Ibagué", value: "ibague" },
  { label: "Villavicencio", value: "villavicencio" },
  { label: "Floridablanca", value: "floridablanca" },
  { label: "Palmira", value: "palmira" },
  { label: "Sabaneta", value: "sabaneta" },
  { label: "La Vega", value: "la vega" },
  { label: "Chía", value: "chia" },
  { label: "Cajicá", value: "cajica" },
  { label: "Bucaramanga", value: "bucaramanga" },
  { label: "Barichara", value: "barichara" },
  { label: "Cúcuta", value: "cucuta" },
  { label: "Zipaquirá", value: "zipaquira" },
  { label: "Melgar", value: "melgar" },
  { label: "Apartadó", value: "apartado" },
  { label: "Pitalito", value: "pitalito" },
  { label: "San Gil", value: "san gil" },
  { label: "Popayán", value: "popayan" },
  { label: "Soledad", value: "soledad" },
  { label: "Cartago", value: "cartago" },
  { label: "Sincelejo", value: "sincelejo" },
  { label: "Barrancabermeja", value: "barrancabermeja" },
  { label: "Yopal", value: "yopal" },
  { label: "Fusagasugá", value: "fusagasuga" },
  { label: "Tunja", value: "tunja" },
  { label: "Chiquinquirá", value: "chiquinquira" },
  { label: "Girardot", value: "girardot" },
  { label: "Florencia", value: "florencia" },
  { label: "Ipiales", value: "ipiales" },
  { label: "Wajira", value: "wajira" },
  { label: "Neiva", value: "neiva" },
  { label: "Valledupar", value: "valledupar" },
  { label: "Facatativá", value: "facatativa" },
  { label: "Huila", value: "huila" },
  { label: "Villeta", value: "villeta" },
  { label: "Mocoa", value: "mocoa" },
  { label: "Espinal ", value: "espinal" },
  { label: "La Mesa", value: "la mesa" },
  { label: "Aguachica", value: "aguachica" },
  { label: "Chaparral", value: "chaparral" },
  { label: "Tuluá", value: "tulua" },
  { label: "Acacías", value: "acacias" },
  { label: "La Dorada", value: "la dorada" },
];

//STATUS ORDERS
export const STATUS_ORDERS = [
  {
    value: POST_STATUS_PENDING_CONTENT,
    label: PUBLICATION_STATUS_PENDING_CONTENT,
  },
  {
    value: POST_STATUS_PENDING_APPROVAL,
    label: PUBLICATION_STATUS_PENDING_APPROVAL,
  },
  { value: POST_STATUS_PENDING, label: PUBLICATION_STATUS_PENDING },
  {
    value: POST_STATUS_ANALYTICS,
    label: PUBLICATIONS_STATUS_PENDING_ANALYTICS,
  },
  { value: POST_STATUS_NEGATIVE_REVIEW, label: PUBLICATIONS_STATUS_FEEDBACK },
  { value: POST_STATUS_SENT, label: PUBLICATIONS_STATUS_SENT },
  { value: POST_STATUS_REVIEWED, label: PUBLICATIONS_STATUS_VERIFIED },
];

export const companyInformation = {
  name: "Clapp Technologies, LLC",
  ein: "EIN 36-5040962",
  cp: "CP 575/576",
  address: "651 N Broad St, Middletown, New Castle, DE, 19709 United States",
  email: "info@heybuzzly.com",
  phone: "(+57) 3009711909",
};

export const RECURRENT_MULTIPLIER = {
  monthly: 1,
  quarterly: 3,
  semiyearly: 6,
  yearly: 12,
};

export const deliveriesList = [
  {
    name: "Mensajeros propios",
    value: "interno",
  },
  {
    name: "Envia",
    value: "envia",
  },
  {
    name: "Fedex",
    value: "fedex",
  },
  {
    name: "Interrapidisimo",
    value: "interrapidisimo",
  },
  {
    name: "Servientrega",
    value: "servientrega",
  },
  {
    name: "Coordinadora",
    value: "coordinadora",
  },
];

export const MIN_FOLLOWERS = 5000;
export const MAX_FOLLOWERS = 500000;
export const MAX_ENGAGEMENT = 30;

export const INITIAL_STATE_CATEGORIES = [];
export const INITIAL_STATE_FOLLOWERS = [0, MAX_FOLLOWERS];
export const INITIAL_STATE_ENGAGEMENT = [0, MAX_ENGAGEMENT];

export const DELAY_DEBOUNCE = 1000;
export const OWNER_ROLE = "owner";
export const MANAGER_ROLE = "manager";
export const STAFF_ROLE = "bdr";
export const SUPER_ADMIN_ROLE = "superAdmin";
export const ADMIN_ROLE = "admin";
export const OPERATOR_ROLE = "operator";
export const DESIGNER_ROLE = "designer";
export const INFLUENCER_ROLE = "influencer";
export const SHOPPER_ROLE = "shopper";

export const ROLES_ALLOWED = [
  OWNER_ROLE,
  MANAGER_ROLE,
  STAFF_ROLE,
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  OPERATOR_ROLE,
  DESIGNER_ROLE,
];
export const ROLES_DASHBOARD = [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE];
export const ROLES_ADMIN_SHOPS = [SUPER_ADMIN_ROLE, ADMIN_ROLE];
export const ROLES_SHOPS = [
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  OPERATOR_ROLE,
  DESIGNER_ROLE,
];

export const ROLES_MAP_TRANSLATION = {
  [ADMIN_ROLE]: ADMIN,
  [OPERATOR_ROLE]: OPERATOR,
  [DESIGNER_ROLE]: DESIGNER,
};

export const ENGAGEMENT_INTERACTIONS = [
  {
    label: ACCOUNTS_REACHED,
    icon: RemoveRedEyeIcon,
    key: "accountsReached",
  },
  {
    label: LIKES,
    icon: ThumbUpIcon,
    key: "likes",
  },
  {
    label: COMMENTS,
    icon: CommentIcon,
    key: "comments",
  },
  {
    label: SHARES,
    icon: SendIcon,
    key: "shares",
  },
  {
    label: SAVES,
    icon: BookmarkIcon,
    key: "saves",
  },
  {
    label: REPLIES,
    icon: ReplyIcon,
    key: "replies",
  },
  {
    label: STICKERS_TAPS,
    icon: TouchAppIcon,
    key: "stickerTaps",
  },
];

export const ENGAGEMENT_KEYS = [
  "accountsReached",
  "likes",
  "saves",
  "replies",
  "comments",
  "shares",
  "stickerTaps",
];

export const MIN_LENGTH_ALL_FIELDS = 2;

export const COUNTRIES_BUZZLY = [
  { code: "co", label: "Colombia" },
  {
    code: "ec",
    label: "Ecuador",
  },
];

//LOCALES COMMON DATA
export const BUDGET_CAMPAIGN_DATA = {
  marks: [
    {
      label: "60",
      value: 60,
    },
    {
      label: "500",
      value: 500,
    },
    {
      label: "1K",
      value: 1000,
    },
    {
      label: "1.5K",
      value: 1500,
    },
    {
      label: "2K",
      value: 2000,
    },
    {
      label: "2.5K",
      value: 2500,
    },
    {
      label: "3K",
      value: 3000,
    },
  ],
  max: 3000,
  min: 60,
  step: 10,
  suggestedIndividualBudget: 125,
};
export const MIN_VALUE_PAY_AMBASSADOR = 50;
export const MIN_VALUE_BUDGET_CAMPAIGN = 60;
export const MIN_ALL_NUMBER_VALUE = 1;
export const DEFAULT_PACKAGE_PRICES = {
  basic: {
    costCollaboration: 0,
    currency: "USD",
    period: 3,
    price: 149,
  },
  pro: {
    costCollaboration: 0,
    currency: "USD",
    period: 6,
    price: 249,
  },
};

export const countryCodes = [
  {
    code: "ad",
    name: "Andorra",
  },
  {
    code: "ae",
    name: "United Arab Emirates",
  },
  {
    code: "af",
    name: "Afghanistan",
  },
  {
    code: "ag",
    name: "Antigua and Barbuda",
  },
  {
    code: "ai",
    name: "Anguilla",
  },
  {
    code: "al",
    name: "Albania",
  },
  {
    code: "am",
    name: "Armenia",
  },
  {
    code: "ao",
    name: "Angola",
  },
  {
    code: "ar",
    name: "Argentina",
  },
  {
    code: "as",
    name: "American Samoa",
  },
  {
    code: "at",
    name: "Austria",
  },
  {
    code: "au",
    name: "Australia",
  },
  {
    code: "aw",
    name: "Aruba",
  },
  {
    code: "ax",
    name: "Alland Islands",
  },
  {
    code: "az",
    name: "Azerbaijan",
  },
  {
    code: "ba",
    name: "Bosnia and Herzegovina",
  },
  {
    code: "bb",
    name: "Barbados",
  },
  {
    code: "bd",
    name: "Bangladesh",
  },
  {
    code: "be",
    name: "Belgium",
  },
  {
    code: "bf",
    name: "Burkina Faso",
  },
  {
    code: "bg",
    name: "Bulgaria",
  },
  {
    code: "bh",
    name: "Bahrain",
  },
  {
    code: "bi",
    name: "Burundi",
  },
  {
    code: "bj",
    name: "Benin",
  },
  {
    code: "bl",
    name: "Saint Barthelemy",
  },
  {
    code: "bm",
    name: "Bermuda",
  },
  {
    code: "bn",
    name: "Brunei Darussalam",
  },
  {
    code: "bo",
    name: "Bolivia",
  },
  {
    code: "bq",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "br",
    name: "Brazil",
  },
  {
    code: "bs",
    name: "Bahamas",
  },
  {
    code: "bt",
    name: "Bhutan",
  },
  {
    code: "bw",
    name: "Botswana",
  },
  {
    code: "by",
    name: "Belarus",
  },
  {
    code: "bz",
    name: "Belize",
  },
  {
    code: "ca",
    name: "Canada",
  },
  {
    code: "cc",
    name: "Cocos (Keeling) Islands",
  },
  {
    code: "cd",
    name: "Congo, Democratic Republic of the",
  },
  {
    code: "cf",
    name: "Central African Republic",
  },
  {
    code: "cg",
    name: "Congo, Republic of the",
  },
  {
    code: "ch",
    name: "Switzerland",
  },
  {
    code: "ci",
    name: "Cote d'Ivoire",
  },
  {
    code: "ck",
    name: "Cook Islands",
  },
  {
    code: "cl",
    name: "Chile",
  },
  {
    code: "cm",
    name: "Cameroon",
  },
  {
    code: "cn",
    name: "China",
  },
  {
    code: "co",
    name: "Colombia",
  },
  {
    code: "cr",
    name: "Costa Rica",
  },
  {
    code: "cu",
    name: "Cuba",
  },
  {
    code: "cv",
    name: "Cape Verde",
  },
  {
    code: "cw",
    name: "Curacao",
  },
  {
    code: "cx",
    name: "Christmas Island",
  },
  {
    code: "cy",
    name: "Cyprus",
  },
  {
    code: "cz",
    name: "Czech Republic",
  },
  {
    code: "de",
    name: "Germany",
  },
  {
    code: "dj",
    name: "Djibouti",
  },
  {
    code: "dk",
    name: "Denmark",
  },
  {
    code: "dm",
    name: "Dominica",
  },
  {
    code: "do",
    name: "Dominican Republic",
  },
  {
    code: "dz",
    name: "Algeria",
  },
  {
    code: "ec",
    name: "Ecuador",
  },
  {
    code: "ee",
    name: "Estonia",
  },
  {
    code: "eg",
    name: "Egypt",
  },
  {
    code: "eh",
    name: "Western Sahara",
  },
  {
    code: "er",
    name: "Eritrea",
  },
  {
    code: "es",
    name: "Spain",
  },
  {
    code: "et",
    name: "Ethiopia",
  },
  {
    code: "fi",
    name: "Finland",
  },
  {
    code: "fj",
    name: "Fiji",
  },
  {
    code: "fk",
    name: "Falkland Islands (Malvinas)",
  },
  {
    code: "fm",
    name: "Micronesia, Federated States of",
  },
  {
    code: "fo",
    name: "Faroe Islands",
  },
  {
    code: "fr",
    name: "France",
  },
  {
    code: "ga",
    name: "Gabon",
  },
  {
    code: "gb",
    name: "United Kingdom",
  },
  {
    code: "gd",
    name: "Grenada",
  },
  {
    code: "ge",
    name: "Georgia",
  },
  {
    code: "gf",
    name: "French Guiana",
  },
  {
    code: "gg",
    name: "Guernsey",
  },
  {
    code: "gh",
    name: "Ghana",
  },
  {
    code: "gi",
    name: "Gibraltar",
  },
  {
    code: "gl",
    name: "Greenland",
  },
  {
    code: "gm",
    name: "Gambia",
  },
  {
    code: "gn",
    name: "Guinea",
  },
  {
    code: "gp",
    name: "Guadeloupe",
  },
  {
    code: "gq",
    name: "Equatorial Guinea",
  },
  {
    code: "gr",
    name: "Greece",
  },
  {
    code: "gs",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "gt",
    name: "Guatemala",
  },
  {
    code: "gu",
    name: "Guam",
  },
  {
    code: "gw",
    name: "Guinea-Bissau",
  },
  {
    code: "gy",
    name: "Guyana",
  },
  {
    code: "hk",
    name: "Hong Kong",
  },
  {
    code: "hn",
    name: "Honduras",
  },
  {
    code: "hr",
    name: "Croatia",
  },
  {
    code: "ht",
    name: "Haiti",
  },
  {
    code: "hu",
    name: "Hungary",
  },
  {
    code: "id",
    name: "Indonesia",
  },
  {
    code: "ie",
    name: "Ireland",
  },
  {
    code: "il",
    name: "Israel",
  },
  {
    code: "im",
    name: "Isle of Man",
  },
  {
    code: "in",
    name: "India",
  },
  {
    code: "iq",
    name: "Iraq",
  },
  {
    code: "ir",
    name: "Iran, Islamic Republic of",
  },
  {
    code: "is",
    name: "Iceland",
  },
  {
    code: "it",
    name: "Italy",
  },
  {
    code: "je",
    name: "Jersey",
  },
  {
    code: "jm",
    name: "Jamaica",
  },
  {
    code: "jo",
    name: "Jordan",
  },
  {
    code: "jp",
    name: "Japan",
  },
  {
    code: "ke",
    name: "Kenya",
  },
  {
    code: "kg",
    name: "Kyrgyzstan",
  },
  {
    code: "kh",
    name: "Cambodia",
  },
  {
    code: "ki",
    name: "Kiribati",
  },
  {
    code: "km",
    name: "Comoros",
  },
  {
    code: "kn",
    name: "Saint Kitts and Nevis",
  },
  {
    code: "kp",
    name: "Korea, Democratic People's Republic of",
  },
  {
    code: "kr",
    name: "Korea, Republic of",
  },
  {
    code: "kw",
    name: "Kuwait",
  },
  {
    code: "ky",
    name: "Cayman Islands",
  },
  {
    code: "kz",
    name: "Kazakhstan",
  },
  {
    code: "la",
    name: "Lao People's Democratic Republic",
  },
  {
    code: "lb",
    name: "Lebanon",
  },
  {
    code: "lc",
    name: "Saint Lucia",
  },
  {
    code: "li",
    name: "Liechtenstein",
  },
  {
    code: "lk",
    name: "Sri Lanka",
  },
  {
    code: "lr",
    name: "Liberia",
  },
  {
    code: "ls",
    name: "Lesotho",
  },
  {
    code: "lt",
    name: "Lithuania",
  },
  {
    code: "lu",
    name: "Luxembourg",
  },
  {
    code: "lv",
    name: "Latvia",
  },
  {
    code: "ly",
    name: "Libya",
  },
  {
    code: "ma",
    name: "Morocco",
  },
  {
    code: "mc",
    name: "Monaco",
  },
  {
    code: "md",
    name: "Moldova, Republic of",
  },
  {
    code: "me",
    name: "Montenegro",
  },
  {
    code: "mf",
    name: "Saint Martin (French part)",
  },
  {
    code: "mg",
    name: "Madagascar",
  },
  {
    code: "mh",
    name: "Marshall Islands",
  },
  {
    code: "mk",
    name: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    code: "ml",
    name: "Mali",
  },
  {
    code: "mm",
    name: "Myanmar",
  },
  {
    code: "mn",
    name: "Mongolia",
  },
  {
    code: "mo",
    name: "Macao",
  },
  {
    code: "mp",
    name: "Northern Mariana Islands",
  },
  {
    code: "mq",
    name: "Martinique",
  },
  {
    code: "mr",
    name: "Mauritania",
  },
  {
    code: "ms",
    name: "Montserrat",
  },
  {
    code: "mt",
    name: "Malta",
  },
  {
    code: "mu",
    name: "Mauritius",
  },
  {
    code: "mv",
    name: "Maldives",
  },
  {
    code: "mw",
    name: "Malawi",
  },
  {
    code: "mx",
    name: "Mexico",
  },
  {
    code: "my",
    name: "Malaysia",
  },
  {
    code: "mz",
    name: "Mozambique",
  },
  {
    code: "na",
    name: "Namibia",
  },
  {
    code: "nc",
    name: "New Caledonia",
  },
  {
    code: "ne",
    name: "Niger",
  },
  {
    code: "nf",
    name: "Norfolk Island",
  },
  {
    code: "ng",
    name: "Nigeria",
  },
  {
    code: "ni",
    name: "Nicaragua",
  },
  {
    code: "nl",
    name: "Netherlands",
  },
  {
    code: "no",
    name: "Norway",
  },
  {
    code: "np",
    name: "Nepal",
  },
  {
    code: "nr",
    name: "Nauru",
  },
  {
    code: "nu",
    name: "Niue",
  },
  {
    code: "nz",
    name: "New Zealand",
  },
  {
    code: "om",
    name: "Oman",
  },
  {
    code: "pa",
    name: "Panama",
  },
  {
    code: "pe",
    name: "Peru",
  },
  {
    code: "pf",
    name: "French Polynesia",
  },
  {
    code: "pg",
    name: "Papua New Guinea",
  },
  {
    code: "ph",
    name: "Philippines",
  },
  {
    code: "pk",
    name: "Pakistan",
  },
  {
    code: "pl",
    name: "Poland",
  },
  {
    code: "pm",
    name: "Saint Pierre and Miquelon",
  },
  {
    code: "pn",
    name: "Pitcairn",
  },
  {
    code: "pr",
    name: "Puerto Rico",
  },
  {
    code: "ps",
    name: "Palestine, State of",
  },
  {
    code: "pt",
    name: "Portugal",
  },
  {
    code: "pw",
    name: "Palau",
  },
  {
    code: "py",
    name: "Paraguay",
  },
  {
    code: "qa",
    name: "Qatar",
  },
  {
    code: "re",
    name: "Reunion",
  },
  {
    code: "ro",
    name: "Romania",
  },
  {
    code: "rs",
    name: "Serbia",
  },
  {
    code: "ru",
    name: "Russian Federation",
  },
  {
    code: "rw",
    name: "Rwanda",
  },
  {
    code: "sa",
    name: "Saudi Arabia",
  },
  {
    code: "sb",
    name: "Solomon Islands",
  },
  {
    code: "sc",
    name: "Seychelles",
  },
  {
    code: "sd",
    name: "Sudan",
  },
  {
    code: "se",
    name: "Sweden",
  },
  {
    code: "sg",
    name: "Singapore",
  },
  {
    code: "sh",
    name: "Saint Helena",
  },
  {
    code: "si",
    name: "Slovenia",
  },
  {
    code: "sj",
    name: "Svalbard and Jan Mayen",
  },
  {
    code: "sk",
    name: "Slovakia",
  },
  {
    code: "sl",
    name: "Sierra Leone",
  },
  {
    code: "sm",
    name: "San Marino",
  },
  {
    code: "sn",
    name: "Senegal",
  },
  {
    code: "so",
    name: "Somalia",
  },
  {
    code: "sr",
    name: "Suriname",
  },
  {
    code: "ss",
    name: "South Sudan",
  },
  {
    code: "st",
    name: "Sao Tome and Principe",
  },
  {
    code: "sv",
    name: "El Salvador",
  },
  {
    code: "sx",
    name: "Sint Maarten (Dutch part)",
  },
  {
    code: "sy",
    name: "Syrian Arab Republic",
  },
  {
    code: "sz",
    name: "Swaziland",
  },
  {
    code: "tc",
    name: "Turks and Caicos Islands",
  },
  {
    code: "td",
    name: "Chad",
  },
  {
    code: "tf",
    name: "French Southern Territories",
  },
  {
    code: "tg",
    name: "Togo",
  },
  {
    code: "th",
    name: "Thailand",
  },
  {
    code: "tj",
    name: "Tajikistan",
  },
  {
    code: "tk",
    name: "Tokelau",
  },
  {
    code: "tl",
    name: "Timor-Leste",
  },
  {
    code: "tm",
    name: "Turkmenistan",
  },
  {
    code: "tn",
    name: "Tunisia",
  },
  {
    code: "to",
    name: "Tonga",
  },
  {
    code: "tr",
    name: "Turkey",
  },
  {
    code: "tt",
    name: "Trinidad and Tobago",
  },
  {
    code: "tv",
    name: "Tuvalu",
  },
  {
    code: "tw",
    name: "Taiwan",
  },
  {
    code: "tz",
    name: "United Republic of Tanzania",
  },
  {
    code: "ua",
    name: "Ukraine",
  },
  {
    code: "ug",
    name: "Uganda",
  },
  {
    code: "us",
    name: "United States",
  },
  {
    code: "uy",
    name: "Uruguay",
  },
  {
    code: "uz",
    name: "Uzbekistan",
  },
  {
    code: "va",
    name: "Holy See (Vatican City State)",
  },
  {
    code: "vc",
    name: "Saint Vincent and the Grenadines",
  },
  {
    code: "ve",
    name: "Venezuela",
  },
  {
    code: "vg",
    name: "British Virgin Islands",
  },
  {
    code: "vi",
    name: "US Virgin Islands",
  },
  {
    code: "vn",
    name: "Vietnam",
  },
  {
    code: "vu",
    name: "Vanuatu",
  },
  {
    code: "wf",
    name: "Wallis and Futuna",
  },
  {
    code: "ws",
    name: "Samoa",
  },
  {
    code: "xk",
    name: "Kosovo",
  },
  {
    code: "ye",
    name: "Yemen",
  },
  {
    code: "yt",
    name: "Mayotte",
  },
  {
    code: "za",
    name: "South Africa",
  },
  {
    code: "zm",
    name: "Zambia",
  },
  {
    code: "zw",
    name: "Zimbabwe",
  },
];

export const LANGUAGES_CODES = {
  aa: "Afar",
  ab: "Abkhazian",
  ae: "Avestan",
  af: "Afrikaans",
  ak: "Akan",
  am: "Amharic",
  an: "Aragonese",
  ar: "Arabic",
  as: "Assamese",
  av: "Avaric",
  ay: "Aymara",
  az: "Azerbaijani",
  ba: "Bashkir",
  be: "Belarusian",
  bg: "Bulgarian",
  bh: "Bihari languages",
  bi: "Bislama",
  bm: "Bambara",
  bn: "Bengali",
  bo: "Tibetan",
  br: "Breton",
  bs: "Bosnian",
  ca: "Catalan; Valencian",
  ce: "Chechen",
  ch: "Chamorro",
  co: "Corsican",
  cr: "Cree",
  cs: "Czech",
  cu: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
  cv: "Chuvash",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  dv: "Divehi; Dhivehi; Maldivian",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "Greek, Modern (1453-)",
  en: "English",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  ff: "Fulah",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Gaelic; Scomttish Gaelic",
  gl: "Galician",
  gn: "Guarani",
  gu: "Gujarati",
  gv: "Manx",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  ho: "Hiri Motu",
  hr: "Croatian",
  ht: "Haitian; Haitian Creole",
  hu: "Hungarian",
  hy: "Armenian",
  hz: "Herero",
  ia: "Interlingua (International Auxiliary Language Association)",
  id: "Indonesian",
  ie: "Interlingue; Occidental",
  ig: "Igbo",
  ii: "Sichuan Yi; Nuosu",
  ik: "Inupiaq",
  io: "Ido",
  is: "Icelandic",
  it: "Italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kg: "Kongo",
  ki: "Kikuyu; Gikuyu",
  kj: "Kuanyama; Kwanyama",
  kk: "Kazakh",
  kl: "Kalaallisut; Greenlandic",
  km: "Central Khmer",
  kn: "Kannada",
  ko: "Korean",
  kr: "Kanuri",
  ks: "Kashmiri",
  ku: "Kurdish",
  kv: "Komi",
  kw: "Cornish",
  ky: "Kirghiz; Kyrgyz",
  la: "Latin",
  lb: "Luxembourgish; Letzeburgesch",
  lg: "Ganda",
  li: "Limburgan; Limburger; Limburgish",
  ln: "Lingala",
  lo: "Lao",
  lt: "Lithuanian",
  lu: "Luba-Katanga",
  lv: "Latvian",
  mg: "Malagasy",
  mh: "Marshallese",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  na: "Nauru",
  nb: "Bokmål, Norwegian; Norwegian Bokmål",
  nd: "Ndebele, North; North Ndebele",
  ne: "Nepali",
  ng: "Ndonga",
  nl: "Dutch; Flemish",
  nn: "Norwegian Nynorsk; Nynorsk, Norwegian",
  no: "Norwegian",
  nr: "Ndebele, South; South Ndebele",
  nv: "Navajo; Navaho",
  ny: "Chichewa; Chewa; Nyanja",
  oc: "Occitan (post 1500)",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetian; Ossetic",
  pa: "Panjabi; Punjabi",
  pi: "Pali",
  pl: "Polish",
  ps: "Pushto; Pashto",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Romansh",
  rn: "Rundi",
  ro: "Romanian; Moldavian; Moldovan",
  ru: "Russian",
  rw: "Kinyarwanda",
  sa: "Sanskrit",
  sc: "Sardinian",
  sd: "Sindhi",
  se: "Northern Sami",
  sg: "Sango",
  si: "Sinhala; Sinhalese",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  ss: "Swati",
  st: "Sotho, Southern",
  su: "Sundanese",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga (Tonga Islands)",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  ty: "Tahitian",
  ug: "Uighur; Uyghur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapük",
  wa: "Walloon",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang; Chuang",
  zh: "Chinese",
  zu: "Zulu",
};
