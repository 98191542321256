import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../../Common/SearchBar/SearchBar";
import Button from "../../../Common/Buttons/Button";
import { CREATE_USER_BUTTON } from "../../../../onboarding/stepsSelectors";
import {
  BUTTON_CREATE_USER,
  FILTER,
  USERS_USER_SEARCH_BAR_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const HeaderUsersTab = ({ usersData, onChangeUsersDataTable, onOpenModal }) => {
  const { t } = useTranslationApp();
  const onSearchUser = (text) => {
    if (text.length > 0) {
      let filteredData = {};
      Object.keys(usersData).filter((key) => {
        if (usersData[key].name.toLowerCase().includes(text.toLowerCase())) {
          filteredData[key] = usersData[key];
        }
        return null;
      });
      onChangeUsersDataTable(filteredData);
    } else {
      onChangeUsersDataTable(usersData);
    }
  };
  const matches = useMediaQuery("(min-width:500px)");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: matches ? "row" : "column",
        gap: 1,
        maxWidth: 500,
        my: 2,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {t(FILTER)}
        </Typography>
        <SearchBar
          placeholder={t(USERS_USER_SEARCH_BAR_PLACEHOLDER)}
          color="white"
          hasButton={false}
          onEditText={(text) => onSearchUser(text)}
        />
      </Box>
      <Button
        variant="contained"
        disableElevation
        fullWidth={!matches}
        color="secondary"
        sx={{
          minWidth: 140,
          mb: matches ? 0 : 1,
        }}
        dataTour={CREATE_USER_BUTTON}
        onClick={onOpenModal}
      >
        {t(BUTTON_CREATE_USER)}
      </Button>
    </Box>
  );
};

export default HeaderUsersTab;
