import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import ModalTooltip from "./ModalTooltip";

const TooltipModal = ({ title, content, stopPropagation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const onOpenModal = () => {
    setIsOpen(true);
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Typography
        className="tooltip-modal"
        variant="body1"
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          px: "8px",
          borderRadius: 6,
          fontWeight: 600,
          cursor: "pointer",
          ":hover": {
            filter: "brightness(0.9)",
          },
          width: "fit-content",
        }}
        onClick={(e) => {
          onOpenModal();

          if (stopPropagation) {
            e.stopPropagation();
          }
        }}
      >
        ?
      </Typography>
      <ModalTooltip
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        title={title}
        content={content}
      />
    </React.Fragment>
  );
};

export default TooltipModal;
