import types from "../types";

const initialState = {
  requests: {},
  isLoading: true,
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REQUESTS_BY_SHOP_FINISH:
      return {
        ...state,
        requests: action.payload,
        isLoading: false,
      };
    case types.GET_REQUESTS_BY_SHOP_PAGINATION:
      return {
        ...state,
        requests: { ...state.requests, ...action.payload },
        isLoading: false,
      };
    case types.DELETE_REQUEST:
      const { [action.payload]: requestToDelete, ...updatedRequests } =
        state.requests;
      delete [action.payload];
      return {
        ...state,
        requests: updatedRequests,
      };
    case types.LOADING_REQUESTS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
