import {
  MENU_PLATFORM,
  MENU_CONFIGURATION,
  MENU_ADMINISTRATION,
  SUB_MENU_CREATORS,
  SUB_MENU_AMBASSADORS,
  SUB_MENU_INVITATIONS,
  SUB_MENU_PUBLICATIONS,
  // SUB_MENU_EVENTS,
  SUB_MENU_CAMPAIGNS,
  SUB_MENU_REDEEM_CODE,
  SUB_MENU_ORDERS,
  SUB_MENU_ACCOUNT,
  SUB_MENU_STORES,
  SUB_MENU_MENU,
  SUB_MENU_USERS,
  SUB_MENU_BILLING_AND_PAYMENTS,
  SUB_MENU_BILLING_DASHBOARD,
  SUB_MENU_BILLING_DATA,
  SUB_MENU_USERS_OWNER,
  SUB_MENU_BRANDS,
  SUB_MENU_BRAND_SIGNUPS,
  SUB_MENU_CREATORS_OWNER,
  SUB_MENU_CREATOR_SIGNUPS,
  SUB_MENU_CONTENTS,
  SUB_MENU_DELIVERIES,
  SUB_MENU_REPORTS_OWNER,
  SUB_MENU_FEEDBACK,
  MENU_MEDIA,
  SUB_MENU_CONTENT_APPLICATION,
  SUB_MENU_ADS_CAMPAIGNS,
  SUB_MENU_TOP_CONTENT,
} from "../../../locales/keysTranslations";
import { PLATFORM_GALLERY } from "../../../onboarding/stepsSelectors";
import {
  ADMIN_ROLE,
  STAFF_ROLE,
  MANAGER_ROLE,
  OPERATOR_ROLE,
  OWNER_ROLE,
  SUPER_ADMIN_ROLE,
  DESIGNER_ROLE,
} from "../../../utils/constants";
import ButtonGallery from "../Gallery/ButtonGallery";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GroupsIcon from "@mui/icons-material/Groups";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import BarChartIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import StoreIcon from "@mui/icons-material/Store";
import PeopleIcon from "@mui/icons-material/People";
import CategoryIcon from "@mui/icons-material/Category";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaidIcon from "@mui/icons-material/Paid";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CollectionsIcon from "@mui/icons-material/Collections";
import AddCommentIcon from "@mui/icons-material/AddComment";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import GradeIcon from "@mui/icons-material/Grade";
import ButtonRedirectAds from "../Buttons/ButtonRedirectAds";

const Menus = [
  {
    nameKey: "empty",
    rolesList: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
    subMenus: [
      {
        name: SUB_MENU_CREATORS,
        nameKey: "creators",
        link: "/creators",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        icon: <PersonSearchIcon />,
      },
      {
        name: SUB_MENU_CAMPAIGNS,
        nameKey: "campaigns",
        link: "/campaigns",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        isBlockedInvites: true,
        icon: <CampaignIcon />,
      },
      {
        name: SUB_MENU_ADS_CAMPAIGNS,
        nameKey: "ads-campaigns",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        isBlockedInvites: true,
        icon: <CampaignIcon />,
        customComponent: <ButtonRedirectAds />,
      },
      {
        name: SUB_MENU_AMBASSADORS,
        nameKey: "ambassadors",
        link: "/ambassadors",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        isBlockedInvites: true,
        icon: <GroupsIcon />,
        hiddenFeatureFlags: [
          {
            name: "isCampaignMode",
            value: true,
          },
        ],
      },
    ],
  },
  {
    name: MENU_PLATFORM,
    nameKey: "platform",
    rolesList: [SUPER_ADMIN_ROLE, ADMIN_ROLE, OPERATOR_ROLE],
    hiddenFeatureFlags: [
      {
        name: "isCampaignMode",
        value: true,
      },
    ],
    subMenus: [
      {
        name: SUB_MENU_STORES,
        nameKey: "stores",
        link: "/stores",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        icon: <StoreIcon />,
      },
      {
        name: SUB_MENU_MENU,
        nameKey: "menu",
        link: "/menu",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, OPERATOR_ROLE],
        icon: <CategoryIcon />,
      },
      {
        name: SUB_MENU_INVITATIONS,
        nameKey: "invites",
        link: "/invitations",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        icon: <ChecklistIcon />,
      },
      {
        name: SUB_MENU_REDEEM_CODE,
        nameKey: "redeemCode",
        link: "/redeem-code",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, OPERATOR_ROLE],
        icon: <SystemSecurityUpdateGoodIcon />,
      },
      {
        name: SUB_MENU_ORDERS,
        nameKey: "deliveries",
        link: "/delivery",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, OPERATOR_ROLE],
        icon: <ShoppingCartIcon />,
      },
      {
        name: SUB_MENU_PUBLICATIONS,
        nameKey: "publications",
        link: "/publications",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        isBlockedInvites: true,
        icon: <PhotoCameraIcon />,
      },
      // {
      //   name: SUB_MENU_EVENTS,
      //   nameKey: "events",
      //   link: "/events",
      //   roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
      // },
    ],
  },
  {
    name: MENU_MEDIA,
    nameKey: "media",
    rolesList: [SUPER_ADMIN_ROLE, ADMIN_ROLE, DESIGNER_ROLE],
    subMenus: [
      {
        name: "Gallery",
        nameKey: "gallery",
        link: "/gallery",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, DESIGNER_ROLE],
        isBlockedInvites: true,
        customComponent: <ButtonGallery dataTour={PLATFORM_GALLERY} />,
      },
      {
        name: SUB_MENU_TOP_CONTENT,
        nameKey: "top-content",
        link: "/top-content",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        isBlockedInvites: true,
        icon: <GradeIcon />,
      },
    ],
  },
  {
    name: MENU_CONFIGURATION,
    nameKey: "settings",
    rolesList: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
    subMenus: [
      {
        name: SUB_MENU_ACCOUNT,
        nameKey: "account",
        link: "/account",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        icon: <PersonIcon />,
      },
      {
        name: SUB_MENU_USERS,
        nameKey: "users",
        link: "/users",
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        icon: <PeopleIcon />,
      },
      {
        name: SUB_MENU_BILLING_AND_PAYMENTS,
        nameKey: "billingAndPayments",
        hasSubMenus: true,
        hiddenWhiteLabel: true,
        roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
        icon: <PaidIcon />,
        subMenus: [
          {
            name: SUB_MENU_BILLING_DASHBOARD,
            nameKey: "payments",
            link: "/billing-and-payments",
            icon: <PaymentsIcon />,
          },
          {
            name: SUB_MENU_BILLING_DATA,
            nameKey: "billingData",
            link: "/billing-data",
            icon: <AccountBalanceIcon />,
          },
        ],
      },
    ],
  },
  {
    name: MENU_ADMINISTRATION,
    nameKey: "administration",
    rolesList: [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE],
    subMenus: [
      {
        name: SUB_MENU_BRAND_SIGNUPS,
        nameKey: "brand-signups",
        link: "/brand-signups",
        roles: [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE],
        icon: <AddBusinessIcon />,
      },
      {
        name: SUB_MENU_BRANDS,
        nameKey: "brands",
        link: "/brands",
        roles: [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE],
        icon: <StoreIcon />,
      },
      {
        name: SUB_MENU_USERS_OWNER,
        nameKey: "usersOwner",
        link: "/users-accounts",
        roles: [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE],
        icon: <PeopleIcon />,
      },
      {
        name: SUB_MENU_DELIVERIES,
        nameKey: "deliveriesOwner",
        link: "/deliveries-owner",
        roles: [OWNER_ROLE, STAFF_ROLE],
        icon: <ShoppingCartIcon />,
      },
      {
        name: SUB_MENU_CREATOR_SIGNUPS,
        nameKey: "creator-signups",
        link: "/creator-signups",
        roles: [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE],
        icon: <PersonAddAlt1Icon />,
      },
      {
        name: SUB_MENU_CREATORS_OWNER,
        nameKey: "influencersOwner",
        link: "/influencers-accounts",
        roles: [OWNER_ROLE, MANAGER_ROLE, STAFF_ROLE],
        icon: <GroupsIcon />,
      },
      {
        name: SUB_MENU_CONTENTS,
        nameKey: "contents",
        link: "/contents",
        roles: [OWNER_ROLE, STAFF_ROLE],
        icon: <CollectionsIcon />,
      },
      {
        name: SUB_MENU_CONTENT_APPLICATION,
        nameKey: "contentApplication",
        link: "/content-application",
        roles: [OWNER_ROLE],
        icon: <RuleFolderIcon />,
      },
      {
        name: SUB_MENU_REPORTS_OWNER,
        nameKey: "statistics",
        link: "/statistics",
        roles: [OWNER_ROLE, STAFF_ROLE],
        icon: <BarChartIcon />,
      },
      {
        name: SUB_MENU_FEEDBACK,
        nameKey: "feedback",
        link: "/feedback",
        roles: [OWNER_ROLE],
        icon: <AddCommentIcon />,
      },
      // {
      //   name: SUB_MENU_PAYMENTS_FAILED,
      //   nameKey: "paymentsFailed",
      //   link: "/payments-failed",
      //   roles: [OWNER_ROLE, STAFF_ROLE],
      // },
    ],
  },
];

export default Menus;
