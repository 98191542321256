import i18next from "i18next";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_WARNING, ROLES_ADMIN_SHOPS } from "../utils/constants";
import { setShowModalSelectPackage } from "./ui";
import {
  ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION,
  WARNING,
} from "../locales/keysTranslations";
import ListErrorsActiveShop from "../components/Common/Lists/ListErrorsActiveShop";
import { validatePlanShop } from "./shops";
import { encryptPassword } from "../utils/formats";
import { renewToken } from "./auth";

export const getShopID = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.auth?.user?.shopID ?? "";
};
export const getCountry = (valueType) => (_, getState) => {
  //eslint-disable-next-line
  return valueType === "shop"
    ? getState()?.shop?.signUpCountry || "co"
    : getState()?.auth?.user?.country || "co";
};

export const getShopIDs = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.auth?.user?.shopIDs ?? [];
};

export const getIsCampaignModeFeatureFlag = () => (_, getState) => {
  const featureFlags = getState().shop?.featureFlags;
  const isCampaignMode = featureFlags?.isCampaignMode;

  return isCampaignMode;
};

export const getStoreID = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.auth?.user?.storeID ?? "";
};

export const getRole = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.auth?.user?.role ?? "";
};

export const getUser = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.auth?.user ?? {};
};

export const getUserID = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.auth?.user?.uid ?? "";
};

export const getStores = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.shop?.stores ?? {};
};

export const getShopIsActive = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.shop?.isActive;
};
export const getShopPaymentModel = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.payments?.paymentModel;
};
export const getShopBusinessName = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.shop?.businessName;
};
export const getParentShopID = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.shop?.parentShopID;
};
export const getCategoryShop = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.shop?.category;
};

export const getCostForCollaboration = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.shop?.costCollaboration;
};

export const getInfluencerRedux = (userID) => (_, getState) => {
  //eslint-disable-next-line
  const influencer = getState()?.influencers?.influencers?.[userID] || {};

  if (Object.keys(influencer).length > 0) {
    return influencer;
  }

  const influencerExternal =
    getState()?.influencers?.influencersExternal?.[userID] || {};

  return influencerExternal || {};
};
export const getPreRegisteredInfluencerProcess = () => (_, getState) => {
  const preRegisteredInfluencersProcess =
    getState()?.influencers?.preRegisteredInfluencersProcess || {};

  return preRegisteredInfluencersProcess;
};
export const getInfluencerHasPreRegisteredProcess =
  ({ mainAccountName, mainPlatform, type, preRegisteredInfluencersProcess }) =>
  (dispatch) => {
    if (!preRegisteredInfluencersProcess) {
      preRegisteredInfluencersProcess = dispatch(
        getPreRegisteredInfluencerProcess()
      );
    }

    const found = Object.values(preRegisteredInfluencersProcess || {}).find(
      (process) =>
        process.mainAccountName === mainAccountName &&
        process.mainPlatform === mainPlatform &&
        process.type === type
    );

    return found;
  };
export const getInfluencerIsHiddenFromTables =
  ({ userID, validateIsHidden = true, validateIsDiscarded = true }) =>
  (_, getState) => {
    if (validateIsHidden) {
      const influencer = getState()?.influencers?.influencers[userID];
      if (influencer?.isHidden) return true;
    }

    if (validateIsDiscarded) {
      const isInfluencerDiscarded =
        getState()?.influencers?.influencersDiscarded[userID];
      if (isInfluencerDiscarded) return true;
    }

    return false;
  };

export const getNameBusinessTheme = () => (_, getState) => {
  //eslint-disable-next-line
  return getState()?.ui?.theme?.nameBusiness;
};

export const getLinkRedirectAds =
  ({ path = "/", params = {} }) =>
  async (dispatch) => {
    const baseLink = process.env.REACT_APP_WEB_ADS_URL;
    const url = new URL(baseLink);

    url.pathname = "/verify-auth";
    url.searchParams.append("redirect", path);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    let token = await dispatch(renewToken());
    token = encryptPassword(token);
    url.searchParams.append("hash", token);
    return url.href;
  };

export const getInfluencerIsFavoriteRedux =
  (userID) => (dispatch, getState) => {
    const shopID = dispatch(getShopID());

    if (shopID === "" || !shopID) return false;

    const favorites = getState()?.influencers?.favorites;

    const isFavorite = favorites[`${shopID}-${userID}`] ? true : false;

    return isFavorite;
  };

export const getIsBlockedLastInvite = () => (dispatch, getState) => {
  // const role = dispatch(getRole());

  // if (!ROLES_ADMIN_SHOPS.includes(role)) return false;

  // const subscriptionPlan = getState()?.payments?.plan;
  // if (subscriptionPlan !== PLAN_COLLABORATION) return false;

  // const lastDateInvite = getState()?.shop?.lastDateInvite;
  // const isBlockedLastInviteDate = getState()?.shop?.blockedLastInviteDate;

  // if (!lastDateInvite) return false;

  // const currentDate = new Date();
  // const lastDate = new Date(lastDateInvite);

  // const differenceDays = differenceInDays(currentDate, lastDate);

  // if (differenceDays >= 30 || isBlockedLastInviteDate) {
  //   dispatch(setShowModalBlockLastInvite(true));
  //   if (isBlockedLastInviteDate === "") {
  //     dispatch(startBlockedShopForLastInvite("block"));
  //   }
  //   return true;
  // }

  return false;
};

export const getIsBlockedFreeTrial = () => (dispatch, getState) => {
  const role = dispatch(getRole());
  if (!ROLES_ADMIN_SHOPS.includes(role)) return false;
  const subscriptionPlan = getState()?.payments?.plan;
  if (subscriptionPlan !== "") return false;

  const isFreeTrial = getState()?.shop?.isFreeTrial;

  if (isFreeTrial === null) return false;

  if (isFreeTrial) {
    dispatch(setShowModalSelectPackage(true));
  }

  return isFreeTrial;
};

export const getIsBlockedExchange =
  ({ from }) =>
  (dispatch, getState) => {
    const stores = getState()?.shop?.stores;
    const schedule = getState()?.shop?.schedule;
    const storesKeys = Object.keys(stores || {});
    const theme = getState()?.ui?.theme;

    const isFreeTrial = getState()?.shop?.isFreeTrial;
    const subscriptionPlan = getState()?.payments?.plan;

    if (
      (!isFreeTrial || subscriptionPlan !== "") &&
      from !== "automaticInvitations"
    ) {
      const isActiveShop = dispatch(validatePlanShop());
      if (!isActiveShop) return true;
    }

    const errors = [];

    if (storesKeys.length === 0) {
      errors.push("stores");
    }

    const hasStoreActive = storesKeys.some((storeID) => {
      return stores[storeID].isActive;
    });

    if (!hasStoreActive && !errors.includes("stores")) {
      errors.push("stores");
    }

    let hasOneDayWithSchedule = false;
    schedule.forEach((day) => {
      if (day.turns) {
        hasOneDayWithSchedule = true;
      }
      return null;
    });

    if (!hasOneDayWithSchedule) {
      errors.push("schedule");
    }

    if (errors.length > 0) {
      SimpleAlert({
        title: i18next.t(WARNING),
        text: i18next.t(ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION),
        icon: ALERT_ICON_TYPE_WARNING,
        html: <ListErrorsActiveShop errors={errors} themeSettings={theme} />,
      });
      return true;
    }

    const isActiveShop = dispatch(validatePlanShop());

    return !isActiveShop;
  };

export const getIsBlockedActions =
  (props = {}) =>
  (dispatch) => {
    const {
      verifyBlockedLastInvite = false,
      verifyBlockedFreeTrial = false,
      verifyBlockedExchange = false,
      from,
    } = props;

    if (verifyBlockedLastInvite) {
      const isBlockedLastInvite = dispatch(getIsBlockedLastInvite());
      if (isBlockedLastInvite) return true;
    }

    if (verifyBlockedFreeTrial) {
      const isBlockedFreeTrial = dispatch(getIsBlockedFreeTrial());
      if (isBlockedFreeTrial) return true;
    }

    if (verifyBlockedExchange) {
      const isBlockedExchange = dispatch(getIsBlockedExchange({ from }));
      if (isBlockedExchange) return true;
    }

    return false;
  };

export const getAdAccountUrlMeta = (adAccountID) => () => {
  return `https://business.facebook.com/adsmanager/manage/accounts?act=${adAccountID}`;
};

export const getBillingUrlMeta = () => (_, getState) => {
  const integrations = getState()?.shop?.integrations;
  const metaAccountBusinessID = integrations?.facebook?.idBusiness;

  return `https://business.facebook.com/billing_hub/payment_activity?asset_id=${
    metaAccountBusinessID ? metaAccountBusinessID.split("act_")[1] : ""
  }&business_id=&placement=ads_manager`;
};

export const getBillingUrlTikTokBusiness = () => (_, getState) => {
  const integrations = getState()?.shop?.integrations;
  const tiktokBusinessAccountID = integrations?.tiktokBusiness?.idAccount;

  return `https://ads.tiktok.com/i18n/account/payment?aadvid=${tiktokBusinessAccountID}`;
};

export const getIsInfluencerInvited = (userID) => (dispatch, getState) => {
  const invites = getState()?.invites?.invites || {};

  const inviteID = Object.keys(invites).find(
    (inviteID) => invites[inviteID].userID === userID
  );

  return inviteID;
};

export const getCurrencyObject = (currencyFromObject) => (_, getState) => {
  //eslint-disable-next-line
  if (currencyFromObject) {
    return currencyFromObject;
  }
  const shopCurrency = getState()?.shop?.currency;
  if (shopCurrency) {
    return shopCurrency;
  }

  const localeCurrency = getState()?.locales?.locale?.currency;

  return localeCurrency;
};
