import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menus from "../ListMenus";
import Toolbar from "@mui/material/Toolbar";
import { startLogout } from "../../../../actions/auth";
import SwitchShop from "../NavBar/SwitchShop";
import ButtonSlideMenu from "../../Buttons/ButtonSlideMenu";
import SelectShops from "../../Selects/SelectShops";
import MenuGroup from "./MenuGroup";
import {
  ADMIN_ROLE,
  BUSINESS_ID_PROJECT,
  OWNER_ROLE,
  ROLES_DASHBOARD,
  SUPER_ADMIN_ROLE,
} from "../../../../utils/constants";
import ButtonHelpVideo from "../../Buttons/ButtonHelpVideo";
import {
  NAVIGATION_ACTIONS,
  NAVIGATION_PLATFORM,
  NAVIGATION_SETTINGS,
} from "../../../../onboarding/stepsSelectors";
import SelectCountry from "../../Selects/SelectCountry";
import { useForm } from "react-hook-form";
import { useTranslationApp } from "../../../../lib/i18next";
import { LOGOUT, NOTIFICATIONS } from "../../../../locales/keysTranslations";
import { getNameBusinessTheme } from "../../../../actions/getters";

const SlideMenu = ({ onDrawerToggle, isMobile }) => {
  const {
    user: { role, shopID },
  } = useSelectorApp((state) => state.auth);
  const dispatch = useDispatchApp();
  const { theme } = useSelectorApp((state) => state.ui);
  const featureFlags = useSelectorApp((state) => state?.shop?.featureFlags);
  const currencyShop = useSelectorApp((state) => state.shop.currency);
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { t } = useTranslationApp();

  const { control } = useForm({
    defaultValues: {
      country: "",
    },
  });

  const onLogout = () => {
    dispatch(startLogout());
  };
  const tourObject = {
    platform: NAVIGATION_PLATFORM,
    actions: NAVIGATION_ACTIONS,
    settings: NAVIGATION_SETTINGS,
  };

  const isAdminUser = role === ADMIN_ROLE;
  const isSuperAdminUser = role === SUPER_ADMIN_ROLE;
  const isOwnerUser = role === OWNER_ROLE;

  const hasRolesComponents =
    isOwnerUser || isSuperAdminUser || (isAdminUser && isMobile);
  return (
    <React.Fragment>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          overflowX: "hidden",
          pl: "24px !important",
          pr: 0,
          overflow: "hidden",
        }}
      >
        <Link
          to="/creators"
          style={{
            display: "flex",
            width: "65%",
            justifyContent: "flex-start",
          }}
        >
          <Avatar
            src={theme.logo}
            alt={`Logo ${NAME_BUSINESS}`}
            variant="square"
            sx={{
              width: "auto",
              ".MuiAvatar-img": {
                maxWidth: "105px",
                objectFit: "contain",
              },
            }}
          />
        </Link>
      </Toolbar>
      {hasRolesComponents && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {role === OWNER_ROLE && (
            <SelectCountry
              showDemoCountries={true}
              name="country"
              control={control}
              variant="filled"
              doChangeCustomClaim={true}
              useUserValue={true}
              useColorTheme={Boolean(BUSINESS_ID_PROJECT) ? true : false}
              actionsChange={onDrawerToggle}
              labelInput="name"
              valueFrom="user"
              sx={{
                px: 2,
                mb: 3,
              }}
            />
          )}
          {isAdminUser && isMobile && (
            <SelectCountry
              showDemoCountries={true}
              doChangeCustomClaim={true}
              label=""
              name="country"
              size="small"
              control={control}
              variant="filled"
              fullWidth
              useUserValue={true}
              disabled={Boolean(currencyShop)}
              labelInput="name"
              valueFrom="shop"
              sx={{
                px: 2,
              }}
              sxInput={{
                ".MuiInputBase-root": {
                  minHeight: 56,
                },
              }}
            />
          )}
          {role === SUPER_ADMIN_ROLE && (
            <SelectShops
              useColorTheme={Boolean(BUSINESS_ID_PROJECT) ? true : false}
            />
          )}
          {isMobile &&
            (role === ADMIN_ROLE || (role === SUPER_ADMIN_ROLE && shopID)) && (
              <>
                <ButtonHelpVideo
                  showButtonHelpWithoutFAQ={false}
                  onSlideMenu={true}
                  onDrawerToggle={onDrawerToggle}
                />
                <SwitchShop
                  onSlideMenu={true}
                  onDrawerToggle={onDrawerToggle}
                  hidden={true}
                />
              </>
            )}
        </Box>
      )}

      {(shopID || ROLES_DASHBOARD.includes(role)) &&
        Menus.map((menu) => {
          if (menu.hiddenFeatureFlags) {
            const isHiddenFeatureFlags = menu.hiddenFeatureFlags.some(
              (featureFlag) => {
                return featureFlags[featureFlag.name] === featureFlag.value;
              }
            );

            if (isHiddenFeatureFlags) {
              return null;
            }
          }
          return (
            <MenuGroup
              key={menu.nameKey}
              dataTour={tourObject[menu.nameKey]}
              menu={menu}
              isMobile={isMobile}
              onDrawerToggle={onDrawerToggle}
              hasRolesComponents={hasRolesComponents}
            />
          );
        })}
      {isMobile && (
        <Box sx={{ mx: 2, mt: 2 }}>
          {!ROLES_DASHBOARD.includes(role) && shopID && (
            <Link
              to="/notifications"
              style={{ textDecoration: "none" }}
              onClick={isMobile && onDrawerToggle}
            >
              <ButtonSlideMenu>{t(NOTIFICATIONS)}</ButtonSlideMenu>
            </Link>
          )}
          <ButtonSlideMenu onClick={onLogout}>{t(LOGOUT)}</ButtonSlideMenu>
        </Box>
      )}
    </React.Fragment>
  );
};

export default SlideMenu;
