import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";

const Counter = ({
  value = 0,
  increment = 1,
  decrement = 1,
  minValue = 0,
  maxValue = 9999,
  onChangeValue = () => {},
}) => {
  const [counter, setCounter] = useState(value);

  const onPlusCounter = () => {
    setCounter(counter + increment);
  };
  const onSubtractCounter = () => {
    setCounter(counter - decrement);
  };

  useEffect(() => {
    onChangeValue(counter);
    //eslint-disable-next-line
  }, [counter]);

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        size="large"
        onClick={onSubtractCounter}
        disabled={counter === minValue}
      >
        <DoDisturbOnIcon fontSize="inherit" />
      </IconButton>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.paperSecondary",
          backgroundImage: "none",
          height: { xs: 75, md: 100 },
          width: { xs: 75, md: 100 },
          borderRadius: 20,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {counter}
        </Typography>
      </Paper>
      <IconButton
        size="large"
        onClick={onPlusCounter}
        disabled={counter === maxValue}
      >
        <AddCircleIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export default Counter;
