import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Button from "../../Common/Buttons/Button";
import {
  LINK_LANDING_CONTACT,
  PLANS_BENEFITS,
  PLAN_BASIC,
  PLAN_GROWTH,
  PLAN_PRO,
} from "../../../utils/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  BUTTON_SUBSCRIBE_NOW,
  CANCEL_ANYTIME,
  CONTACT_US,
  MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT,
  MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME,
  MODAL_SELECT_PACKAGE_COST_COLLABORATION,
  MODAL_SELECT_PACKAGE_COST_CONTENT,
  MODAL_SELECT_PACKAGE_LICENSED_FEE,
  MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE,
  MODAL_SELECT_PACKAGE_LICENSED_PRICE,
  MODAL_SELECT_PACKAGE_PRICE,
  MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH,
  PRO_PACKAGE_NAME,
} from "../../../locales/keysTranslations";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../lib/i18next";
import { getNameBusinessTheme } from "../../../actions/getters";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";

const PricingCard = ({
  data,
  onChangePlanSelected,
  isProrated,
  isOnlyBasicPlan,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const LOCALE = useLocale();

  const idInformation = data.idInformation;

  const getID = (id) => {
    // if (id !== PLAN_ENTERPRISE && id !== PLAN_GROWTH) return id;
    // if (lengthPlans === 1) return `${id}_ONLY`;
    return id;
  };

  const planReadyToBuy = data.price > 0;

  const isLicensePlan = data.period;

  const isFeaturedPlan = data.id === PLAN_PRO || data.id === PLAN_GROWTH;

  return (
    <Card
      sx={{
        boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        minHeight: 432,
      }}
    >
      <CardHeader
        title={
          isOnlyBasicPlan
            ? t(PRO_PACKAGE_NAME, {
                NAME_BUSINESS,
              })
            : t(data.name, { NAME_BUSINESS })
        }
        subheader={!isOnlyBasicPlan && t(data.subHeader)}
        action={isFeaturedPlan ? <StarIcon /> : null}
        titleTypographyProps={{
          align: "center",
          fontWeight: 600,
          fontStyle: "normal",
          color: "white !important",
        }}
        subheaderTypographyProps={{
          align: "center",
          color: "white !important",
        }}
        sx={{
          backgroundColor: "#4f4c4c !important",
          color: "white !important",
        }}
      />
      <CardContent
        sx={{
          pt: 1,
          pb: 0,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            mt: 1,
          }}
        >
          {planReadyToBuy ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isLicensePlan ? (
                <InformationLicensed data={data} isMobile={isMobile} />
              ) : (
                <InformationPackage
                  data={data}
                  isMobile={isMobile}
                  isProrated={isProrated}
                />
              )}
            </Box>
          ) : (
            <InformationEnterprise data={data} isMobile={isMobile} />
          )}
        </Box>
        <Box
          component="ul"
          sx={{
            mt: 1,
            p: 0,
            listStyle: "none",
          }}
        >
          {data?.benefits?.costCollaboration > 0 && data.price > 0 ? (
            <Typography
              component="li"
              variant="subtitle1"
              align="center"
              sx={{ mb: 2 }}
            >
              {t(
                isLicensePlan
                  ? MODAL_SELECT_PACKAGE_COST_CONTENT
                  : MODAL_SELECT_PACKAGE_COST_COLLABORATION,
                {
                  value: data.benefits?.costCollaboration,
                  currency: data.currency || LOCALE.currency,
                }
              )}
            </Typography>
          ) : null}

          {PLANS_BENEFITS[getID(idInformation)]?.map((benefit, index) => (
            <Typography
              component="li"
              variant="subtitle2"
              align="left"
              key={index}
            >
              {t(benefit, {
                NAME_BUSINESS,
              })}
            </Typography>
          ))}
        </Box>
      </CardContent>
      <CardActions
        sx={{
          mb: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          variant={
            !isFeaturedPlan && !isOnlyBasicPlan ? "outlined" : "contained"
          }
          color={
            !isFeaturedPlan && !isOnlyBasicPlan ? "secondary" : "secondary"
          }
          onClick={() => {
            planReadyToBuy
              ? onChangePlanSelected(data.id)
              : window.open(LINK_LANDING_CONTACT);
          }}
          sx={{
            background:
              (isOnlyBasicPlan || isFeaturedPlan) && GRADIENT_PURPLE_FUCHSIA,
            color:
              (isOnlyBasicPlan || isFeaturedPlan) &&
              GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            ml: "0px !important",
            width: "100%",
          }}
        >
          {planReadyToBuy
            ? isOnlyBasicPlan
              ? t(BUTTON_SUBSCRIBE_NOW)
              : t(MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME, {
                  value: t(data.name, {
                    NAME_BUSINESS,
                  }),
                })
            : t(CONTACT_US)}
        </Button>
        <Typography variant="caption" sx={{ mt: 0.5 }}>
          {t(CANCEL_ANYTIME)}
        </Typography>
      </CardActions>
    </Card>
  );
};

const InformationLicensed = ({ isMobile, data }) => {
  const LOCALE = useLocale();
  const { t } = useTranslationApp();
  return (
    <>
      <Typography
        variant={isMobile ? "body2" : "body1"}
        color="text.primary"
        align="center"
      >
        {t(MODAL_SELECT_PACKAGE_LICENSED_FEE, {
          period: data?.period,
        })}
      </Typography>
      <Typography
        component="h2"
        variant={isMobile ? "body1" : "h6"}
        color="text.primary"
        align="center"
      >
        {t(MODAL_SELECT_PACKAGE_LICENSED_PRICE, {
          value: data.price,
          currency: data?.currency || LOCALE.currency,
        })}
      </Typography>
    </>
  );
};

const InformationPackage = ({ data, isMobile, isProrated }) => {
  const LOCALE = useLocale();
  const { t } = useTranslationApp();
  const { plans } = useSelectorApp((state) => state.payments);

  return (
    <>
      <Typography
        component="h2"
        variant={isMobile ? "body1" : "h6"}
        color="text.primary"
        align="center"
      >
        {t(MODAL_SELECT_PACKAGE_PRICE, {
          value: data.price,
          currency: LOCALE.currency,
        })}
      </Typography>
      {isProrated && (
        <Typography component="h3" variant={isMobile ? "body1" : "body2"}>
          {t(MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH, {
            value: data.price - plans[PLAN_BASIC]?.price,
            currency: LOCALE.currency,
          })}
        </Typography>
      )}
    </>
  );
};

const InformationEnterprise = ({ data, isMobile }) => {
  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Typography
        variant={isMobile ? "body2" : "body1"}
        color="text.primary"
        align="center"
      >
        {t(MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE)}
      </Typography>
      <Typography
        component="h2"
        variant={isMobile ? "body1" : "h6"}
        color="text.primary"
        align="center"
      >
        {t(MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT)}
      </Typography>
    </Box>
  );
};

export default PricingCard;
