import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../../lib/i18next";
import { BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION } from "../../../../../locales/keysTranslations";
import { useLocale } from "../../../../../Contexts/LocaleContext";

const PackageInfo = ({ data }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();
  return (
    <Box>
      <Typography variant="body1" align="left">
        {t(BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION, {
          value: data.benefits?.costCollaboration,
          currency: data?.currency || LOCALE.currency,
        })}
      </Typography>
    </Box>
  );
};

export default PackageInfo;
