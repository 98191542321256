import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import SocialNetworksSection from "../components/User/Publication/SocialNetworksSection";
import AnalyticsSection from "../components/User/Publication/AnalyticsSection";
import InfoPublication from "../components/User/Publication/InfoPublication";
import ModalReview from "../components/User/Publication/ModalReview";
import {
  startApproveContent,
  startAssignInviteIDNegativeReview,
  startGetOrderAnalytics,
  startGetOrderInfo,
  startSendRevision,
} from "../actions/orders";
import Loader from "../components/Common/Loader/Loader";
import {
  startGetInfluencer,
  startReviewInfluencer,
} from "../actions/influencers";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_CONTENT,
  POST_STATUS_REVIEWED,
  POST_STATUS_SENT,
} from "../utils/constants";
import NegativeReview from "../components/User/Publication/NegativeReview";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import ModalInvitation from "../components/User/InfluencersListTable/ModalInvitation";
import { startGetAverageTicketShop } from "../actions/shops";
import { generateRandomCode } from "../utils/generateRandomsValues";
import { verifyInviteCodeExists } from "../actions/invites";
import { startGetUserFeedback, startSaveUserFeedback } from "../actions/users";
import ModalValueField from "../components/Common/Modal/ModalValueField";
import { useTour } from "@reactour/tour";
import {
  DUMMY_ORDER,
  STEP_AMBASSADORS_FUNCTIONALITY,
  STEP_MAIN_FUNCTIONALITY,
} from "../onboarding/stepsSelectors";
import {
  STEPS_REVIEW_FUNCTIONALITY,
  STEPS_STORES_FUNCTIONALITY,
} from "../onboarding/steps";
import useLocalStorage from "../hooks/useLocalStorage";
import { waitDelay } from "../utils/dates";
import { setIsNewBrand } from "../actions/onboarding";
import { useTranslationApp } from "../lib/i18next";
import {
  BUTTON_CONTINUE,
  FEEDBACK_PLACEHOLDER,
  FIELD_MIN_LENGTH,
  INFORMATION_INVALID_ACCESS,
  PUBLICATION_FINISH_DESCRIPTION,
  PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION,
  READY,
} from "../locales/keysTranslations";
import ContentSection from "../components/User/Publication/ContentSection";
import ContentHistory from "../components/User/Publication/ContentHistory";
import { startUploadNewContentPublication } from "../actions/owner";
import ModalDissatisfactionCollaboration from "../components/Common/Modal/ModalDissatisfactionCollaboration";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const Publication = ({ from = "user" }) => {
  const [modalReviewOpen, setModalReviewOpen] = useState(false);
  const [modalInvitationOpen, setModalInvitationOpen] = useState(false);
  const [modalFeedbackOpen, setModalFeedbackOpen] = useState(false);
  const [
    modalDissatisfactionCollaborationOpen,
    setModalDissatisfactionCollaborationOpen,
  ] = useState(false);
  const [order, setOrder] = useState({});
  const [influencer, setInfluencer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsDoneTutorial] = useLocalStorage("isDoneTutorial", false);

  const [averageTicket, setAverageTicket] = useState(null);

  const [params] = useSearchParams();
  const { user } = useSelectorApp((state) => state.auth);
  const { orders } = useSelectorApp((state) => state.orders);
  const isMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const { isOpen, setSteps, setIsOpen, currentStep, steps, setCurrentStep } =
    useTour();
  const { isNewBrand } = useSelectorApp((state) => state.onboarding);
  const onboardingName = steps[currentStep]?.onboardingName;

  const orderID = params.get("orderID");

  const showApprovalContentSection =
    (order?.postStatus === POST_STATUS_PENDING_CONTENT ||
      order?.postStatus === POST_STATUS_PENDING_APPROVAL) &&
    from === "user";

  const showHistoryContentSection =
    order?.postStatus !== POST_STATUS_NEGATIVE_REVIEW;

  const showNegativeReviewSection =
    order?.postStatus === POST_STATUS_NEGATIVE_REVIEW;

  const showPublicationSection =
    order?.postStatus !== POST_STATUS_NEGATIVE_REVIEW &&
    order?.postStatus !== POST_STATUS_PENDING_APPROVAL &&
    order?.postStatus !== POST_STATUS_PENDING_CONTENT;

  useEffect(() => {
    (async () => {
      if (!orderID) {
        return navigate(from === "user" ? "/publications" : "/contents");
      }
      let orderData;
      let influencerData;
      let userID;

      if (!isOpen) {
        orderData = await dispatch(startGetOrderInfo(orderID));
        if (!orderData) {
          SimpleAlert({
            title: t(INFORMATION_INVALID_ACCESS),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return navigate(from === "user" ? "/publications" : "/contents");
        }

        let analytics = {};
        if (
          orderData.postStatus === POST_STATUS_SENT ||
          orderData.postStatus === POST_STATUS_REVIEWED
        ) {
          analytics = await dispatch(startGetOrderAnalytics(orderID));
        }

        setOrder({ orderID, ...orderData, analyticsData: analytics || {} });
        influencerData = await dispatch(startGetInfluencer(orderData.userID));
        userID = orderData.userID;
        setInfluencer({ ...influencerData, userID: orderData.userID });
      } else {
        orderData = orders[DUMMY_ORDER];

        influencerData = await dispatch(startGetInfluencer(orderData.userID));
        userID = orderData.userID;

        setOrder({
          orderID,
          ...orderData,
        });
      }
      setInfluencer({ ...influencerData, userID });
      const ticket = await dispatch(startGetAverageTicketShop());
      setAverageTicket(ticket);
      dispatch(startGetUserFeedback());
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, [orderID, user.shopID]);

  useEffect(() => {
    if (isOpen && modalReviewOpen) {
      if (onboardingName === STEP_MAIN_FUNCTIONALITY) {
        setSteps(STEPS_REVIEW_FUNCTIONALITY);
      }
      if (onboardingName === STEP_AMBASSADORS_FUNCTIONALITY) {
        setCurrentStep(currentStep + 1);
      }
    } else if (!isOpen && currentStep > 1) {
      navigate("/publications");
    }
    //eslint-disable-next-line
  }, [isOpen, modalReviewOpen]);

  const onOpenModalInvitationStatusChange = (status) =>
    setModalInvitationOpen(status);

  const onOpenModalReviewStatusChange = (status) => setModalReviewOpen(status);

  const onOpenModalFeedbackStatusChange = (status) =>
    setModalFeedbackOpen(status);

  const onOpenModalDissatisfactionCollaborationStatusChange = (status) =>
    setModalDissatisfactionCollaborationOpen(status);

  const onInviteInfluencer = async (inviteID) => {
    dispatch(startAssignInviteIDNegativeReview(order.orderID, inviteID));
    navigate("/publications");
  };

  const onReviewInfluencer = async (data) => {
    if (!data.isAmbassador && data.doAmbassador) {
      let inviteCode;
      if (data.inviteCode === "") {
        inviteCode = generateRandomCode(8);
      } else {
        const isCodeExists = await dispatch(
          verifyInviteCodeExists(data.inviteCode)
        );
        if (isCodeExists) return false;
        inviteCode = data.inviteCode;
      }
      data.inviteCode = inviteCode;
    }

    const response = await dispatch(startReviewInfluencer(order, data));

    if (response) {
      setOrder({
        ...order,
        orderID,
        postStatus: POST_STATUS_REVIEWED,
      });
      if (user.feedback || isOpen) {
        if (isOpen && onboardingName === STEP_MAIN_FUNCTIONALITY) {
          setIsDoneTutorial(true);
          navigate("/publications");
          dispatch(setIsNewBrand(false));
          setIsOpen(false);
          if (isNewBrand) {
            const result = await ConfirmAlert({
              title: t(READY),
              text: t(PUBLICATION_FINISH_DESCRIPTION),
              additionalText: t(
                PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION
              ),
              icon: ALERT_ICON_TYPE_SUCCESS,
              confirmButtonText: t(BUTTON_CONTINUE),
            });
            if (result.isConfirmed) {
              dispatch(setIsNewBrand(true));
              navigate(`/stores`);
              setSteps(STEPS_STORES_FUNCTIONALITY);
              setIsOpen(true);
            }
            return;
          } else {
            SimpleAlert({
              title: t(READY),
              text: t(PUBLICATION_FINISH_DESCRIPTION),
              icon: ALERT_ICON_TYPE_SUCCESS,
            });
          }
        }
        if (!data.isAmbassador && data.doAmbassador) {
          await waitDelay(400);
          navigate("/ambassadors");
          if (isOpen && onboardingName === STEP_AMBASSADORS_FUNCTIONALITY) {
            setCurrentStep(currentStep + 1);
          }
        }
        if (!isOpen) {
          if (Number(data.score) === 1) {
            onOpenModalDissatisfactionCollaborationStatusChange(true);
          } else {
            SimpleAlert({
              title: t(READY),
              icon: ALERT_ICON_TYPE_SUCCESS,
            });
          }
        }
      }
      if (!user.feedback) {
        onOpenModalFeedbackStatusChange(true);
      }
    }
    return response;
  };

  const onSaveFeedback = async (feedback) => {
    await dispatch(startSaveUserFeedback(feedback));
    SimpleAlert({
      title: t(READY),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    onOpenModalFeedbackStatusChange(false);
  };

  const onApproveContent = async () => {
    const response = await dispatch(startApproveContent(order.orderID));
    if (response) {
      setOrder({
        ...order,
        postStatus: POST_STATUS_PENDING,
      });
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const onSendRevision = async (data) => {
    const response = await dispatch(
      startSendRevision({
        orderID: order.orderID,
        data: {
          ...data,
          content: order.content,
        },
      })
    );
    if (response) {
      const history = order.history || [];
      const newOrder = {
        ...order,
        postStatus: POST_STATUS_PENDING_CONTENT,
        history: [
          ...history,
          {
            ...data,
            content: order.content,
            creationTime: new Date().getTime(),
          },
        ],
      };
      delete newOrder.content;

      setOrder(newOrder);

      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onUploadNewContent = async (posts) => {
    const newPosts = [...(order.posts || []), ...posts];

    const response = await dispatch(
      startUploadNewContentPublication({
        orderID: order.orderID,
        posts: newPosts,
      })
    );
    if (response) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });

      setOrder({
        ...order,
        posts: newPosts,
      });
    }
    return response;
  };

  if (isLoading) return <Loader />;

  return (
    <ContainerPage
      sx={{
        height: "calc(100vh - 88px)",
      }}
    >
      <InfoPublication
        influencer={influencer}
        order={order}
        onActionButton={() => onOpenModalReviewStatusChange(true)}
        isMobile={isMobile}
        from={from}
      />
      <Box
        sx={{
          mt: 1,
          width: "100%",
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        {showPublicationSection && (
          <>
            <AnalyticsSection order={order} isMobile={isMobile} />
            <Divider orientation="vertical" flexItem />
            <SocialNetworksSection
              order={order}
              influencer={influencer}
              isMobile={isMobile}
              onUploadNewContent={onUploadNewContent}
            />
          </>
        )}
        {showApprovalContentSection && (
          <>
            <ContentSection
              data={order}
              isMobile={isMobile}
              onApproveContent={onApproveContent}
              onCallbackSendRevision={onSendRevision}
            />
          </>
        )}
        {showHistoryContentSection && (
          <>
            <ContentHistory data={order} isMobile={isMobile} />
          </>
        )}
        {showNegativeReviewSection && (
          <NegativeReview
            order={order}
            onClick={() => onOpenModalInvitationStatusChange(true)}
            isMobile={isMobile}
          />
        )}
      </Box>
      {modalReviewOpen && (
        <ModalReview
          data={influencer}
          modalOpen={modalReviewOpen}
          onCloseModal={() => onOpenModalReviewStatusChange(false)}
          onReviewInfluencer={onReviewInfluencer}
        />
      )}
      {modalInvitationOpen && (
        <ModalInvitation
          dataInfluencer={influencer}
          userID={order.userID}
          onCloseModal={() => onOpenModalInvitationStatusChange(true)}
          onCallBackInvite={onInviteInfluencer}
          averageTicket={averageTicket}
        />
      )}
      {modalFeedbackOpen && (
        <ModalValueField
          modalOpen={modalFeedbackOpen}
          onCloseModal={() => onOpenModalFeedbackStatusChange(false)}
          onCallbackButton={onSaveFeedback}
          hasSecondButton={true}
          onCallbackSecondButton={() => onOpenModalFeedbackStatusChange(false)}
          label={FEEDBACK_PLACEHOLDER}
          maxWidth="sm"
          multiline={true}
          rules={{
            minLength: {
              value: 10,
              message: t(FIELD_MIN_LENGTH, { value: 10 }),
            },
          }}
        />
      )}
      {modalDissatisfactionCollaborationOpen && (
        <ModalDissatisfactionCollaboration
          modalOpen={modalDissatisfactionCollaborationOpen}
          onCloseModal={() =>
            onOpenModalDissatisfactionCollaborationStatusChange(false)
          }
          orderID={order.orderID}
        />
      )}
    </ContainerPage>
  );
};

export default Publication;
