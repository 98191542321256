import types from "../types";
const initialState = {
  advertisers: {},
  adsUsers: {},
  isLoading: true,
};

export const advertisersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADVERTISER_USERS:
      return {
        ...state,
        adsUsers: {
          ...state.adsUsers,
          ...action.payload,
        },
      };
    case types.GET_ADVERTISERS:
      return {
        ...state,
        advertisers: action.payload,
      };
    case types.GET_NEXT_ADVERTISERS:
      return {
        ...state,
        advertisers: {
          ...state.advertisers,
          ...action.payload,
        },
      };
    case types.CLEAN_ADS_CAMPAIGNS:
      return initialState;
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;

    default:
      return state;
  }
};
