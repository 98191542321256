import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextFieldMUI from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { calculateSuggestedPrice } from "../../../utils/influencers";
import { getAnalyticsByUser } from "../../../utils/socialNetworks";
import TooltipModal from "../../Common/Tooltips/TooltipModal";
import {
  FEE_VALUE_DIRECT_PAYMENT,
  INSTAGRAM,
  INVITATION_COMMENT_MAX_LENGTH,
  INVITATION_INFLUENCER_MODE_CREATE,
  INVITATION_INFLUENCER_MODE_EDIT,
  INVITATION_INFLUENCER_MODE_INVITE,
  MIN_ALL_NUMBER_VALUE,
  TIKTOK,
} from "../../../utils/constants";
import Accordion from "../../Common/Containers/Accordion";
import {
  INVITE_FORM_COMMENT,
  INVITE_FORM_VALUE,
  MODAL_INVITE_FORM,
} from "../../../onboarding/stepsSelectors";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ADVANCED_CONFIGURATION,
  INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION,
  INVITE_FORM_CODE_HELPER_TEXT,
  INVITE_FORM_CODE_PLACEHOLDER,
  INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT,
  INVITE_FORM_COMMENT_HELPER_TEXT,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
  INVITE_FORM_ORDER_FROM_HELPER_TEXT,
  INVITE_FORM_ORDER_FROM_PLACE_HOLDER,
  INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER,
  INVITE_FORM_SELECT_FORMATS_PLATFORM,
  INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION,
  INVITE_FORM_VALUE_HELPER_TEXT,
  INVITE_FORM_VALUE_PLACEHOLDER_2,
  AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER,
  BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR,
  BUTTON_GENERATE_SCRIPT,
  FIELD_REQUIRED,
  INVITE_VALUE_MIN_VALUE,
  INVITE_COMMENT_MAX_LENGTH,
  INVITE_COMMENT_NO_LINK,
  INVITE_COMMENT_NO_PHONE,
  INVITE_FORM_CODE_MIN_LENGTH,
  INVITE_PERSONALIZED_URL_INVALID,
  INVITE_FORM_VALUE_QUESTION,
  INVITE_FORM_VALUE_CLARIFICATION,
  INVITE_FORM_VALUE_TO_PAY_QUESTION,
  INVITE_FORM_VALUE_TO_PAY_CLARIFICATION,
  INVITE_VALUE_TO_PAY_MIN_VALUE,
  INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER,
  INVITE_FORM_UGC_FORMAT_QUESTION,
  INVITE_FORM_LANGUAGE_QUESTION,
  INVITE_FORM_REFERENCE_URL_QUESTION,
  ADD_NEW_STYLE,
  WANTS_SOCIAL_MEDIA_POST,
  DOES_NOT_WANTS_SOCIAL_MEDIA_POST,
  INVITE_FORM_VALUE_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import ContentTrackingURL from "../../Common/Tooltips/Content/ContentTrackingURL";
import { DatePicker } from "@mui/x-date-pickers";
import { getNameBusinessTheme } from "../../../actions/getters";
import {
  startAddReferenceProfile,
  startGenerateCommentProfileInfluencer,
  startSaveLastInviteConfiguration,
} from "../../../actions/shops";
import SelectUGCContentFormat from "../../Common/Selects/SelectUGCContentFormat";
import ButtonOpenModalAutomaticInvitesConfiguration from "../../Common/Buttons/ButtonOpenModalAutomaticInvitesConfiguration";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import LinkText from "../../Common/Texts/LinkText";
import CurrencyField from "../../Common/NumberFormats/CurrencyField";
import TextField from "../../Common/Fields/TextField";
import {
  REGEX_NUMBER_IN_STRING,
  REGEX_URL,
} from "../../../utils/regexsValidation";
import { Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTour } from "@reactour/tour";
import Select from "../../Common/Selects/Select";
import { optionsLanguage } from "../../../utils/slidersMarks";
import { numberDecimal } from "../../../utils/numberFormats";
import Switch from "../../Common/Forms/Switchs/Switch";

const InvitationForm = ({
  control,
  errors,
  watch,
  userID,
  mode = INVITATION_INFLUENCER_MODE_CREATE,
  from = INVITATION_INFLUENCER_MODE_INVITE,
  isAmbassadorInvitation,
  getValues,
  setValue,
  dataInfluencer,
  averageTicket,
  disabledAdvancedSettings,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const LOCALE = useLocale();

  const ugcContentFormat = watch("ugcContentFormat");
  const valueToPay = Number(watch("valueToPay")) || 0;
  const comment = watch("comment");
  const wantsSocialMediaPost = watch("wantsSocialMediaPost");

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { isOpen } = useTour();

  const { justo } = useSelectorApp((state) => state.shop.ordersMethods);
  const lastInviteConfiguration = useSelectorApp(
    (state) => state.shop.lastInviteConfiguration
  );
  const autoInvitationsIsActive = useSelectorApp(
    (state) => state?.shop?.profileInfluencer?.autoInvitationsIsActive
  );
  const minProductValueShop = useSelectorApp(
    (state) => state?.shop?.minProductValue
  );

  useEffect(() => {
    if (!wantsSocialMediaPost) {
      const formats = getValues("formats");
      Object.keys(formats).forEach((key) => {
        formats[key] = false;
      });

      setValue("formats", formats);
    }
    //eslint-disable-next-line
  }, [wantsSocialMediaPost]);

  useEffect(() => {
    if (!lastInviteConfiguration?.comment) {
      onGenerateComment({
        saveInConfiguration: true,
      });
    }
    //eslint-disable-next-line
  }, []);

  const onGenerateComment = async ({ saveInConfiguration = false }) => {
    setIsLoading(true);
    const response = await dispatch(
      startGenerateCommentProfileInfluencer({
        ugcContentFormat: getValues("ugcContentFormat"),
        formats: getValues("formats"),
        typeContent: getValues("typeContent"),
        wantsSocialMediaPost: getValues("wantsSocialMediaPost"),
        language: getValues("language"),
      })
    );
    if (response) {
      setValue("comment", response, {
        shouldValidate: true,
      });
      if (saveInConfiguration) {
        dispatch(
          startSaveLastInviteConfiguration({
            comment: response,
          })
        );
      }
    }
    setIsLoading(false);
  };

  const sxSubTitle = {
    mb: 2.5,
    fontWeight: 600,
  };
  return (
    <Grid container spacing={3} data-tour={MODAL_INVITE_FORM}>
      <Grid item xs={12}>
        <Typography variant="body1" align="left" sx={sxSubTitle}>
          {t(INVITE_FORM_VALUE_QUESTION)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: 1,
          }}
        >
          <TextField
            name="value"
            variant="filled"
            dataTour={INVITE_FORM_VALUE}
            fullWidth
            label={t(
              averageTicket
                ? INVITE_FORM_VALUE_HELPER_TEXT
                : INVITE_FORM_VALUE_PLACEHOLDER,
              {
                value: calculateSuggestedPrice(
                  getAnalyticsByUser(dataInfluencer),
                  averageTicket,
                  minProductValueShop
                ),
                currency: LOCALE.currency,
              }
            )}
            InputProps={{
              inputComponent: CurrencyField,
            }}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: isOpen ? false : true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (parseInt(value) < MIN_ALL_NUMBER_VALUE && !isOpen) {
                  return t(INVITE_VALUE_MIN_VALUE, {
                    value: MIN_ALL_NUMBER_VALUE,
                    currency: LOCALE.currency,
                  });
                }
              },
            }}
          />
        </Box>
        <Typography
          variant="caption"
          component="p"
          align="left"
          sx={{ color: "fields.placeholder" }}
        >
          {t(INVITE_FORM_VALUE_CLARIFICATION)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="left" sx={sxSubTitle}>
          {t(INVITE_FORM_UGC_FORMAT_QUESTION)}
        </Typography>
        <SelectUGCContentFormat
          name="ugcContentFormat"
          doTranslate={true}
          fullWidth={true}
          control={control}
          errors={errors}
          value={ugcContentFormat}
          showAddAction={true}
          labelAddAction={ADD_NEW_STYLE}
          onAddAction={(contentFormat) =>
            setValue("ugcContentFormat", contentFormat)
          }
          rules={{
            required: {
              value: isOpen ? false : true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="left" sx={sxSubTitle}>
          {t(INVITE_FORM_COMMENT_HELPER_TEXT)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            dataTour={INVITE_FORM_COMMENT}
            variant="filled"
            multiline
            minRows={6}
            maxRows={6}
            InputLabelProps={{
              sx: {
                textWrap: "wrap",
                "&.MuiInputLabel-shrink": {
                  textWrap: "nowrap",
                },
              },
            }}
            value={comment}
            name="comment"
            hasButtonGenerate={true}
            propsButtonGenerate={{
              onClick: onGenerateComment,
              loading: isLoading,
              text: BUTTON_GENERATE_SCRIPT,
            }}
            alignButtonGenerate="bottom-right"
            showCounterCharacters={true}
            maxCharacters={INVITATION_COMMENT_MAX_LENGTH}
            fullWidth
            control={control}
            errors={errors}
            rules={{
              required: {
                value: isOpen ? false : true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (!isOpen) {
                  if (value.length > INVITATION_COMMENT_MAX_LENGTH) {
                    return t(INVITE_COMMENT_MAX_LENGTH, {
                      value: INVITATION_COMMENT_MAX_LENGTH,
                    });
                  }
                  if (REGEX_URL.test(value)) {
                    return t(INVITE_COMMENT_NO_LINK);
                  }
                  if (REGEX_NUMBER_IN_STRING.test(value)) {
                    return t(INVITE_COMMENT_NO_PHONE);
                  }
                }
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="left" sx={sxSubTitle}>
          {t(INVITE_FORM_LANGUAGE_QUESTION)}
        </Typography>
        <Select
          name="language"
          label={t(INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER)}
          variant="filled"
          fullWidth
          options={optionsLanguage}
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: isOpen ? false : true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <FormControl
          component="fieldset"
          variant="standard"
          error={!!errors?.typeContent}
          sx={{ width: "100%" }}
        >
          <FormLabel component="legend" sx={{ fontWeight: 600, mb: 1 }}>
            {t(INVITE_FORM_TYPE_CONTENT)}
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <CheckItem
                nameKey="typeContent"
                nameValue="images"
                label={t(INVITE_FORM_TYPE_CONTENT_IMAGES)}
                control={control}
                rules={{
                  validate: () => {
                    if (!Object.values(typeContent).includes(true) && !isOpen) {
                      return t(INVITE_FORM_TYPE_CONTENT_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CheckItem
                nameKey="typeContent"
                nameValue="videos"
                label={t(INVITE_FORM_TYPE_CONTENT_VIDEOS)}
                control={control}
                rules={{
                  validate: () => {
                    if (!Object.values(typeContent).includes(true) && !isOpen) {
                      return t(INVITE_FORM_TYPE_CONTENT_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
          </Grid>
          {errors?.typeContent && (
            <Typography variant="caption" color="error">
              {errors?.typeContent?.message}
            </Typography>
          )}
        </FormControl>
      </Grid> */}
      {justo && (
        <Grid item xs={12}>
          <TextField
            name="code"
            variant="filled"
            fullWidth
            label={t(INVITE_FORM_CODE_PLACEHOLDER)}
            disabled={mode === INVITATION_INFLUENCER_MODE_EDIT}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: isOpen ? false : true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (value?.length < 4 && !isOpen) {
                  t(INVITE_FORM_CODE_MIN_LENGTH, {
                    value: 4,
                  });
                }
              },
            }}
          />
          <Typography
            variant="caption"
            align="left"
            sx={{ color: "fields.placeholder" }}
          >
            {t(INVITE_FORM_CODE_HELPER_TEXT)}
          </Typography>
          {justo && (
            <FormHelperText>
              <LinkText
                href="https://glaze-blarney-9c2.notion.site/Creaci-n-de-cupones-e-invitaciones-justo-47aee3ec7b5c44fdbed0751f3b702943"
                target="_blank"
                sx={{
                  fontSize: "0.75rem",
                  color: "fields.placeholder",
                }}
              >
                {t(INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT, {
                  platform: "Justo",
                  NAME_BUSINESS,
                })}
              </LinkText>
            </FormHelperText>
          )}
        </Grid>
      )}
      {isAmbassadorInvitation && (
        <Grid item xs={12}>
          <Typography variant="body1" align="left" sx={sxSubTitle}>
            {t(INVITE_FORM_ORDER_FROM_HELPER_TEXT)}
          </Typography>
          <Controller
            control={control}
            name="orderFrom"
            render={({ field }) => (
              <DatePicker
                {...field}
                value={new Date(field.value)}
                label={t(INVITE_FORM_ORDER_FROM_PLACE_HOLDER)}
                onChange={(date) => {
                  field.onChange(new Date(date).toISOString());
                }}
                renderInput={(params) => (
                  <TextFieldMUI fullWidth variant="filled" {...params} />
                )}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" align="left" sx={sxSubTitle}>
          {t(INVITE_FORM_REFERENCE_URL_QUESTION)}
        </Typography>
        <TextField
          name="referenceUrl"
          label={t(
            AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER
          )}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: false,
          }}
        />
      </Grid>
      {!autoInvitationsIsActive && (
        <Grid item xs={12}>
          <ButtonOpenModalAutomaticInvitesConfiguration
            buttonText={BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR}
            buttonTextInterpolation={{
              mainAccountName: dataInfluencer?.mainAccountName,
            }}
            onClick={() => dispatch(startAddReferenceProfile(userID))}
            from="modalInvitation"
            sx={{
              width: "100%",
              background: GRADIENT_PURPLE_FUCHSIA,
              color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            }}
          />
        </Grid>
      )}
      {!disabledAdvancedSettings && (
        <Grid item xs={12}>
          <Accordion
            title={t(ADVANCED_CONFIGURATION)}
            hasBorder={false}
            sxTitle={{
              textDecoration: "underline",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t(INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="wantsSocialMediaPost"
                  render={({ field }) => {
                    return (
                      <Switch
                        labelPlacement="end"
                        hasLabel={true}
                        labelActive={WANTS_SOCIAL_MEDIA_POST}
                        labelDeactivate={DOES_NOT_WANTS_SOCIAL_MEDIA_POST}
                        isActive={field.value}
                        onChangeIsActive={field.onChange}
                      />
                    );
                  }}
                />
              </Grid>
              {wantsSocialMediaPost && (
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    disabled={mode === INVITATION_INFLUENCER_MODE_EDIT}
                    sx={{ width: "100%" }}
                  >
                    <FormLabel component="legend" sx={{ fontWeight: 600 }}>
                      {t(INVITE_FORM_SELECT_FORMATS_PLATFORM)}
                    </FormLabel>
                    <Typography variant="caption">
                      {t(INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION)}
                    </Typography>
                    <FormGroup>
                      <Grid container spacing={1}>
                        {dataInfluencer.mainPlatform === INSTAGRAM && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <CheckItem
                                control={control}
                                nameKey="formats"
                                nameValue="instagramBurstStories"
                                label={t(
                                  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CheckItem
                                control={control}
                                nameKey="formats"
                                nameValue="instagramPost"
                                label={t(INVITE_FORM_FORMAT_INSTAGRAM_POST)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CheckItem
                                control={control}
                                nameKey="formats"
                                nameValue="instagramReel"
                                label={t(INVITE_FORM_FORMAT_INSTAGRAM_REEL)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CheckItem
                                control={control}
                                nameKey="formats"
                                nameValue="instagramStory"
                                label={t(INVITE_FORM_FORMAT_INSTAGRAM_STORY)}
                              />
                            </Grid>
                          </>
                        )}
                        {dataInfluencer.mainPlatform === TIKTOK && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <CheckItem
                                control={control}
                                nameKey="formats"
                                nameValue="tiktokPost"
                                label={t(INVITE_FORM_FORMAT_TIKTOK_POST)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CheckItem
                                control={control}
                                nameKey="formats"
                                nameValue="tiktokStory"
                                label={t(INVITE_FORM_FORMAT_TIKTOK_STORY)}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TextField
                    name="personalizedURL"
                    label={t(INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER)}
                    variant="filled"
                    fullWidth
                    control={control}
                    errors={errors}
                    rules={{
                      required: false,
                      validate: (value) => {
                        if (value && !REGEX_URL.test(value) && !isOpen) {
                          return t(INVITE_PERSONALIZED_URL_INVALID);
                        }
                      },
                    }}
                  />
                  <TooltipModal
                    title={t(INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER)}
                    content={<ContentTrackingURL />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="left" sx={sxSubTitle}>
                  {t(INVITE_FORM_VALUE_TO_PAY_QUESTION)}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: 1,
                  }}
                >
                  <TextField
                    name="valueToPay"
                    variant="filled"
                    disabled={mode === INVITATION_INFLUENCER_MODE_EDIT}
                    label={t(INVITE_FORM_VALUE_PLACEHOLDER_2)}
                    InputProps={{
                      inputComponent: CurrencyField,
                    }}
                    fullWidth
                    control={control}
                    errors={errors}
                    rules={{
                      required: false,
                      validate: (value) => {
                        if (
                          parseInt(value) > 0 &&
                          parseInt(value) < 10 &&
                          !isOpen
                        ) {
                          return t(INVITE_VALUE_TO_PAY_MIN_VALUE, {
                            value: 10,
                            currency: "USD",
                          });
                        }
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="caption"
                  component="p"
                  align="left"
                  sx={{ color: "fields.placeholder" }}
                >
                  {t(INVITE_FORM_VALUE_TO_PAY_CLARIFICATION, {
                    currency: "USD",
                    value: valueToPay,
                    valueWithFee: numberDecimal(
                      valueToPay + valueToPay * FEE_VALUE_DIRECT_PAYMENT
                    ),
                    fee: FEE_VALUE_DIRECT_PAYMENT * 100,
                  })}
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
              <TextField
                name="terms"
                value={data.terms}
                label={t(INVITE_FORM_TERMS_PLACEHOLDER)}
                multiline={true}
                minRows={2}
                maxRows={3}
                onChange={onChange}
                disabled={mode === INVITATION_INFLUENCER_MODE_EDIT}
                variant="filled"
                fullWidth
              />
            </Grid> */}
            </Grid>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
};

const CheckItem = ({ control, nameKey, nameValue, label, rules = {} }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={nameKey}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Checkbox
              checked={field.value[nameValue]}
              onChange={(e) =>
                field.onChange({
                  ...field.value,
                  [nameValue]: e.target.checked,
                })
              }
              name={nameValue}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export default InvitationForm;
