import React from "react";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DateTimePicker } from "@mui/x-date-pickers";
import addMinutes from "date-fns/addMinutes";
import { getRecurrentOptions } from "../../../../utils/dates";

const EventForm = ({ control, watch, setValue }) => {
  return (
    <Grid container spacing={1} sx={{ mb: 2 }}>
      <Grid item xs={12} lg={6}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              label="Nombre del evento"
              variant="filled"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              label="Descripción del evento"
              variant="filled"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Controller
          name="dateTimeStart"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              onChange={(date) => {
                field.onChange(date);
                setValue("dateTimeEnd", null);
              }}
              label="Fecha de inicio del evento"
              renderInput={(params) => (
                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  {...params}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Controller
          name="dateTimeEnd"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              minDateTime={addMinutes(new Date(watch("dateTimeStart")), 10)}
              disabled={watch("dateTimeStart") === null}
              label="Fecha de finalización del evento"
              renderInput={(params) => (
                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  {...params}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Controller
          name="isRecurrent"
          control={control}
          render={({ field }) => (
            <>
              <FormControl>
                <FormLabel id="isRecurrent-label">
                  El evento es recurrente
                </FormLabel>
                <RadioGroup
                  aria-labelledby="isRecurrent-label"
                  defaultValue={false}
                  {...field}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sí"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              {watch("isRecurrent") === "true" && (
                <>
                  <Controller
                    name="typeRecurrent"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
                        <InputLabel id="typeRecurrent">
                          Selecciona el tipo de recurrencia
                        </InputLabel>
                        <Select
                          labelId="typeRecurrent"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 4.5 + 8 * 2,
                                width: 250,
                              },
                            },
                          }}
                          {...field}
                        >
                          {getRecurrentOptions(watch("dateTimeStart")).map(
                            (option) => (
                              <MenuItem value={option.value}>
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              label="Lugar del evento"
              variant="filled"
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EventForm;
