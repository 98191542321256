import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  startGetInvitesByShopPagination,
  startGetInvitesInactiveByShopPagination,
} from "../actions/invites";
import ModalInvitation from "../components/User/InfluencersListTable/ModalInvitation";
import {
  DEFAULT_TABLE_PAGES,
  INVITATION_INFLUENCER_MODE_EDIT,
  INVITATION_INFLUENCER_MODE_INVITE,
  INVITATION_INFLUENCER_MODE_INVITE_INACTIVE,
  PRE_REGISTERED_INFLUENCER_INVITE_PROCESS,
} from "../utils/constants";
import { startGetAverageTicketShop } from "../actions/shops";
import { usePagination } from "../hooks/usePagination";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { PAGE_INVITATIONS } from "../onboarding/stepsSelectors";
import {
  INVITATIONS_ACTIVE_TITLE,
  INVITATIONS_DESCRIPTION,
  INVITATIONS_INACTIVE_EXPIRED_FILTER,
  INVITATIONS_INACTIVE_REDEEM_FILTER,
  INVITATIONS_INACTIVE_TITLE,
  INVITATIONS_TITLE,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import SegmentedController from "../components/Common/Containers/SegmentedController";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { startOpenInfluencerConversation } from "../actions/chat";
import InvitationsActiveTable from "../components/User/Invitations/InvitationsActiveTable";
import InvitationsInactiveTable from "../components/User/Invitations/InvitationsInactiveTable";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { getInfluencerRedux } from "../actions/getters";
import { getProcessInfluencerFormatted } from "../services/influencers";
import { getNextInfluencersExternal } from "../actions/influencers";
import _ from "lodash";

const Invitations = () => {
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [invitesActive, setInvitesActive] = useState({});
  // const [eventInvites, setEventInvites] = useState({});
  const [invitesInactive, setInvitesInactive] = useState({});
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [averageTicket, setAverageTicket] = useState(null);
  const [filter, setFilter] = useState(null);

  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const {
    invites,
    invitesInactive: invitesInactiveAll,
    // eventInvites: eventInvitesAll,
  } = useSelectorApp((state) => state.invites);
  const { user } = useSelectorApp((state) => state.auth);
  const { influencers, preRegisteredInfluencersProcess } = useSelectorApp(
    (state) => state.influencers
  );

  const getNextInvites = async () => {
    await dispatch(
      startGetInvitesByShopPagination({
        lastKey: lastKeyInvites,
        limit: rowsPerPageInvites,
        onChangeLastKey: onChangeLastKeyInvites,
      })
    );
  };

  // const getNextEventInvites = async () => {
  //   await dispatch(
  //     startGetEventInvitesByShopPagination({
  //       lastKey: lastKeyEventInvites,
  //       limit: rowsPerPageEventInvites,
  //       onChangeLastKey: onChangeLastKeyEventInvites,
  //     })
  //   );
  // };

  const getNextInvitesInactive = async () => {
    await dispatch(
      startGetInvitesInactiveByShopPagination({
        lastKey: lastKeyInvitesInactive,
        limit: rowsPerPageInvitesInactive,
        onChangeLastKey: onChangeLastKeyInvitesInactive,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(
      startGetInvitesByShopPagination({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKeyInvites,
        onCallbackFinish: () => setIsLoading(false),
      })
    );
    // dispatch(
    //   startGetEventInvitesByShopPagination({
    //     limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
    //     onChangeLastKey: onChangeLastKeyEventInvites,
    //   })
    // );
    dispatch(
      startGetInvitesInactiveByShopPagination({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKeyInvitesInactive,
        onCallbackFinish: () => setIsLoading(false),
      })
    );
    const ticket = await dispatch(startGetAverageTicketShop());
    setAverageTicket(ticket);
  };

  const {
    rowsPerPage: rowsPerPageInvites,
    page: pageInvites,
    lastKey: lastKeyInvites,
    onChangeLastKey: onChangeLastKeyInvites,
    onRowsPerPageChange: onRowsPerPageChangeInvites,
    onPageChange: onPageChangeInvites,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextInvites,
  });

  // const {
  //   rowsPerPage: rowsPerPageEventInvites,
  //   page: pageEventInvites,
  //   lastKey: lastKeyEventInvites,
  //   onChangeLastKey: onChangeLastKeyEventInvites,
  //   onRowsPerPageChange: onRowsPerPageChangeEventInvites,
  //   onPageChange: onPageChangeEventInvites,
  // } = usePagination({
  //   rowsPerPageValue: DEFAULT_TABLE_PAGES,
  //   onCallBackNextPage: getNextEventInvites,
  // });

  const {
    rowsPerPage: rowsPerPageInvitesInactive,
    page: pageInvitesInactive,
    lastKey: lastKeyInvitesInactive,
    onChangeLastKey: onChangeLastKeyInvitesInactive,
    onRowsPerPageChange: onRowsPerPageChangeInvitesInactive,
    onPageChange: onPageChangeInvitesInactive,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextInvitesInactive,
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [user.shopID]);

  // useEffect(() => {
  //   let eventInvites = {};
  //   Object.keys(eventInvitesAll).map((key) => {
  //     return (eventInvites = {
  //       ...eventInvites,
  //       [key]: {
  //         ...eventInvitesAll[key],
  //         orderFrom: new Date(eventInvitesAll[key].orderFrom),
  //       },
  //     });
  //   });

  //   setEventInvites(eventInvites);
  //   //eslint-disable-next-line
  // }, [eventInvitesAll]);

  useEffect(() => {
    const influencersInviteProcess = Object.keys(
      preRegisteredInfluencersProcess
    ).filter(
      (key) =>
        preRegisteredInfluencersProcess[key].type ===
        PRE_REGISTERED_INFLUENCER_INVITE_PROCESS
    );
    let invitesInProcess = {};

    influencersInviteProcess.forEach((key) => {
      const processFormatted = getProcessInfluencerFormatted(
        preRegisteredInfluencersProcess[key]
      );
      if (!processFormatted) return null;

      const { influencer, data } = processFormatted;

      const influencerIsLoaded = dispatch(
        getInfluencerRedux(influencer?.userID)
      );

      if (_.isEmpty(influencerIsLoaded)) {
        dispatch(
          getNextInfluencersExternal({
            [influencer?.userID]: influencer,
          })
        );
      }

      invitesInProcess = {
        ...invitesInProcess,
        ...data,
      };
    });

    let invitesActive = {};
    const allInvitesToProcess = { ...invites, ...invitesInProcess };

    Object.keys(allInvitesToProcess).map((key) => {
      if (!allInvitesToProcess[key]) return null;
      return (invitesActive = {
        ...invitesActive,
        [key]: {
          ...allInvitesToProcess[key],
          orderFrom: new Date(allInvitesToProcess[key]?.orderFrom),
        },
      });
    });

    setInvitesActive(invitesActive);
    //eslint-disable-next-line
  }, [invites, preRegisteredInfluencersProcess]);

  useEffect(() => {
    let invitesInactive = {};
    Object.keys(invitesInactiveAll).map((key) => {
      if (!invitesInactiveAll[key]) return null;
      if (invitesInactiveAll[key]?.redemptionDate) {
        return (invitesInactive = {
          ...invitesInactive,
          [key]: {
            ...invitesInactiveAll[key],
            orderFrom: new Date(invitesInactiveAll[key]?.orderFrom),
            redemptionDate: new Date(
              parseInt(invitesInactiveAll[key]?.redemptionDate ?? new Date())
            ),
          },
        });
      }
      return (invitesInactive = {
        ...invitesInactive,
        [key]: {
          ...invitesInactiveAll[key],
          orderFrom: new Date(invitesInactiveAll[key]?.orderFrom),
        },
      });
    });
    setInvitesInactive(invitesInactive);
    //eslint-disable-next-line
  }, [invitesInactiveAll]);

  const onSelectInvitation = (inviteID, from) => {
    // if (from === "events") {
    //   return setSelectedInvitation({
    //     ...eventInvites[inviteID],
    //     inviteID: inviteID,
    //     code: eventInvites[inviteID].inviteCode,
    //     from,
    //   });
    // }
    if (from === "active") {
      return setSelectedInvitation({
        ...invitesActive[inviteID],
        inviteID: inviteID,
        code: invitesActive[inviteID].inviteCode,
        from,
      });
    }
    if (from === "inactive") {
      return setSelectedInvitation({
        ...invitesInactive[inviteID],
        inviteID: inviteID,
        code: invitesInactive[inviteID].inviteCode,
        from,
      });
    }
  };

  const onChangeFilter = (filterSelect) => {
    if (tab !== 1) setTab(1);
    if (filterSelect !== null) {
      if (filterSelect === filter) {
        setFilter(null);
      } else {
        setFilter(filterSelect);
      }
    } else {
      setFilter(filterSelect);
    }
  };

  const onOpenChatInvite = (inviteID) => {
    const invite = invitesActive[inviteID];

    dispatch(startOpenInfluencerConversation({ userID: invite.userID }));
  };

  const onCloseModal = () => {
    setSelectedInvitation(null);
  };

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <TitlePage dataTour={PAGE_INVITATIONS}>
              {t(INVITATIONS_TITLE)}
            </TitlePage>
            <DescriptionPage>{t(INVITATIONS_DESCRIPTION)}</DescriptionPage>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              mt: {
                xs: 0,
                sm: 1,
              },
              mb: {
                xs: 2,
                sm: 0,
              },
            }}
          >
            <Chip
              icon={<CheckIcon />}
              label={t(INVITATIONS_INACTIVE_REDEEM_FILTER)}
              onClick={() => {
                onChangeFilter("redeemed");
              }}
              color={filter === "redeemed" ? "primary" : "default"}
            />
            <Chip
              icon={<ClearIcon />}
              label={t(INVITATIONS_INACTIVE_EXPIRED_FILTER)}
              onClick={() => {
                onChangeFilter("expired");
              }}
              color={filter === "expired" ? "primary" : "default"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <SegmentedController
            initialValue={tab}
            value={tab}
            onChangeTab={(newTab) => {
              if (newTab === 0) {
                setFilter(null);
              }
              setTab(newTab);
            }}
            tabs={[
              {
                icon: <PersonIcon />,
                label: INVITATIONS_ACTIVE_TITLE,
              },
              {
                icon: <PersonOffIcon />,
                label: INVITATIONS_INACTIVE_TITLE,
              },
            ]}
          />
          {tab === 0 && (
            <InvitationsActiveTable
              data={invitesActive}
              loading={isLoading}
              onSelectInvitation={onSelectInvitation}
              rowsPerPage={rowsPerPageInvites}
              page={pageInvites}
              onRowsPerPageChange={onRowsPerPageChangeInvites}
              onPageChange={onPageChangeInvites}
              onOpenChatInvite={onOpenChatInvite}
            />
          )}
          {tab === 1 && (
            <InvitationsInactiveTable
              data={invitesInactive}
              loading={isLoading}
              onSelectInvitation={onSelectInvitation}
              rowsPerPage={rowsPerPageInvitesInactive}
              page={pageInvitesInactive}
              onRowsPerPageChange={onRowsPerPageChangeInvitesInactive}
              onPageChange={onPageChangeInvitesInactive}
              filter={filter}
            />
          )}
          {/* <EventInvitations
                  data={eventInvites}
                  onSelectInvitation={onSelectInvitation}
                  rowsPerPage={rowsPerPageEventInvites}
                  page={pageEventInvites}
                  onRowsPerPageChange={onRowsPerPageChangeEventInvites}
                  onPageChange={onPageChangeEventInvites}
                /> */}
        </Box>
        {!!selectedInvitation && (
          <ModalInvitation
            dataInfluencer={influencers[selectedInvitation.userID]}
            userID={selectedInvitation.userID}
            dataInvite={{
              ...selectedInvitation,
              inviteID: selectedInvitation.inviteID,
              code: selectedInvitation.inviteCode,
            }}
            onCloseModal={onCloseModal}
            averageTicket={averageTicket}
            mode={INVITATION_INFLUENCER_MODE_EDIT}
            from={
              selectedInvitation.from === "active"
                ? INVITATION_INFLUENCER_MODE_INVITE
                : INVITATION_INFLUENCER_MODE_INVITE_INACTIVE
            }
          />
        )}
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default Invitations;
