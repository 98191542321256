import React from "react";
import Box from "@mui/material/Box";
import Button from "../../../Common/Buttons/Button";
import { BUTTON_VIEW_DETAILS, PAY } from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const ActionsInvoicesTable = ({
  data,
  onSelectInvoice,
  onPayInvoice,
  keyInvoice,
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: { xs: "inline", lg: "flex" },
        gap: 2,
        justifyContent: "center",
      }}
    >
      <Button
        variant="contained"
        color={data.isPaid ? "secondary" : "primary"}
        sx={{
          mb: 1,
          minWidth: 115,
          maxWidth: 140,
        }}
        onClick={() => onSelectInvoice(keyInvoice)}
        fullWidth={true}
      >
        {t(BUTTON_VIEW_DETAILS)}
      </Button>
      {!data.isPaid && !data.params.orderIDs && !data.paymentLink && (
        <Button
          color="secondary"
          onClick={() => onPayInvoice(keyInvoice)}
          fullWidth
          sx={{
            minWidth: 130,
            maxWidth: "fit-content",
          }}
        >
          {t(PAY)}
        </Button>
      )}
    </Box>
  );
};

export default ActionsInvoicesTable;
