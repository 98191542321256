import { DUMMY_APPLICANT, DUMMY_CAMPAIGN } from "../onboarding/stepsSelectors";
import types from "../types";
import {
  APPLICANT_STATUS_ACCEPTED,
  APPLICANT_STATUS_REJECTED,
  APPLICANT_STATUS_REVISIONS,
} from "../utils/constants";

const initialState = {
  campaigns: {},
  invitations: {},
  invitationsExternals: {},
  applicants: {},
  isLoading: true,
};

export const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CAMPAIGNS_BY_SHOP_PAGINATION:
      return {
        ...state,
        campaigns: { ...state.campaigns, ...action.payload },
        isLoading: false,
      };
    case types.CLEAN_DATA_CAMPAIGN:
      return {
        ...state,
        invitations: {},
        invitationsExternals: {},
        applicants: {},
      };
    case types.CREATE_CAMPAIGN:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.SAVE_CAMPAIGN:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.CHANGE_STATUS_CAMPAIGN:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.key]: {
            ...state.campaigns[action.payload.key],
            status: action.payload.status,
          },
        },
      };
    case types.EDIT_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload.key]: {
            ...state.applicants[action.payload.key],
            ...action.payload.data,
          },
        },
      };
    case types.GET_APPLICANTS_BY_CAMPAIGN_ID:
      return {
        ...state,
        applicants: action.payload,
      };
    case types.GET_APPLICANTS_BY_SHOP:
      return {
        ...state,
        applicants: action.payload,
      };
    case types.GET_APPLICANTS_BY_PAGINATION:
      return {
        ...state,
        applicants: { ...state.applicants, ...action.payload },
      };
    case types.ACCEPTED_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload]: {
            ...state.applicants[action.payload],
            status: APPLICANT_STATUS_ACCEPTED,
          },
        },
      };
    case types.INVITED_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload.applicantID]: {
            ...state.applicants[action.payload.applicantID],
            inviteID: action.payload.inviteID,
          },
        },
      };
    case types.REJECTED_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload]: {
            ...state.applicants[action.payload],
            status: APPLICANT_STATUS_REJECTED,
          },
        },
      };
    case types.ACCEPTED_CONTENT_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload.key]: {
            ...state.applicants[action.payload.key],
            status: action.payload.status,
          },
        },
      };
    case types.REVISIONS_CONTENT_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload.applicantID]: {
            ...state.applicants[action.payload.applicantID],
            status: APPLICANT_STATUS_REVISIONS,
            revision: action.payload.revision,
          },
        },
      };
    case types.GET_INVITATIONS_CAMPAIGNS_BY_CAMPAIGN_FINISH:
      return {
        ...state,
        invitations: action.payload,
        isLoading: false,
      };
    case types.GET_INVITATIONS_CAMPAIGNS_BY_CAMPAIGN_NEXT_FINISH:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.INVITE_INFLUENCER_CAMPAIGN:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          [action.payload.key]: action.payload.data,
        },
        isLoading: false,
      };
    case types.DELETE_INVITE_INFLUENCER_CAMPAIGN:
      delete state.invitations[action.payload];
      return {
        ...state,
        invitations: {
          ...state.invitations,
        },
        isLoading: false,
      };
    case types.GET_INVITATIONS_INFLUENCERS_EXTERNALS_BY_CAMPAIGN_FINISH:
      return {
        ...state,
        invitationsExternals: action.payload,
        isLoading: false,
      };
    case types.INVITE_INFLUENCER_EXTERNAL_CAMPAIGN:
      return {
        ...state,
        invitationsExternals: {
          ...state.invitationsExternals,
          [action.payload.key]: action.payload.data,
        },
        isLoading: false,
      };
    case types.CHANGE_STATUS_INVITE_INFLUENCER_EXTERNAL:
      return {
        ...state,
        invitationsExternals: {
          ...state.invitationsExternals,
          [action.payload.key]: {
            ...state.invitationsExternals[action.payload.key],
            status: action.payload.status,
          },
        },
        isLoading: false,
      };
    case types.ASSIGN_CHAT_ID_APPLICANT:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          [action.payload.key]: {
            ...state.applicants[action.payload.key],
            chatID: action.payload.chatID,
          },
        },
      };
    case types.DISABLE_ALL_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_CAMPAIGN]: campaignDelete, ...campaigns } =
        state.campaigns;
      const { [DUMMY_APPLICANT]: applicantDelete, ...applicants } =
        state.applicants;
      return {
        ...state,
        campaigns: campaigns,
        applicants: applicants,
      };
    case types.LOADING_CAMPAIGNS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;

    default:
      return state;
  }
};
