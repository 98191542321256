import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import InfluencerInformationForm from "./InfluencerInformationForm";
import {
  startChangeIsHiddenInfluencer,
  startSaveInfluencerInformation,
} from "../../../actions/influencers";
import ModalDisableInfluencerAccount from "./ModalDisableInfluencerAccount";
import { startActiveInfluencerAccount } from "../../../actions/owner";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import {
  BUTTON_CANCEL,
  BUTTON_SAVE,
  FIELD_ALL_REQUIRED,
  MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES,
  MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_TITLE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalInfluencerInformation = ({
  modalOpen,
  onCloseModal,
  data,
  userID,
  onSaveInformation,
}) => {
  const [modalDisableAccount, setModalDisableAccount] = useState(false);
  const [error, setError] = useState(null);

  const { control, getValues, setValue, watch } = useForm({
    defaultValues: {
      mainAccountName: data.mainAccountName,
      categories: data.categories,
      isHidden: data.isHidden ?? false,
    },
  });

  const isHidden = watch("isHidden");
  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onChangeModalDisableAccountStatus = (status) => {
    setModalDisableAccount(status);
  };

  const onChangeIsHiddenStatus = async () => {
    const response = await dispatch(
      startChangeIsHiddenInfluencer({ userID, isHidden: !isHidden })
    );

    if (response) {
      setValue("isHidden", !isHidden);
    }

    return;
  };

  const onSubmit = async () => {
    const formValues = getValues();
    if (
      formValues.mainAccountName.length === 0 ||
      formValues.categories.length === 0
    ) {
      return setError(t(FIELD_ALL_REQUIRED));
    }
    if (formValues.categories.length > 3) {
      return setError(t(MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES));
    }
    setError(null);

    const response = await dispatch(
      startSaveInfluencerInformation({ userID: userID, data: formValues })
    );

    if (response) {
      onSaveInformation(formValues);
      onCloseModal();
    }
  };

  const onCallbackDisableAccount = () => setValue("isHidden", true);

  const onActiveInfluencerAccount = async () => {
    const response = await dispatch(startActiveInfluencerAccount({ userID }));

    if (response) {
      setValue("isHidden", false);
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_EDIT_CREATOR_OWNER_TITLE)}
      title={t(MODAL_EDIT_CREATOR_OWNER_TITLE)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {!!error && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Alert
              severity="error"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                align: "center",
              }}
            >
              {error}
            </Alert>
          </Box>
        )}
        <InfluencerInformationForm control={control} watch={watch} />

        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            color: "secondary",
            showButton: true,
            text: isHidden
              ? MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR
              : MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR,
            onClick: onChangeIsHiddenStatus,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: data.isDisabled
              ? MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR
              : MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR,
            onClick: () => {
              if (data.isDisabled) {
                onActiveInfluencerAccount();
              } else {
                onChangeModalDisableAccountStatus(true);
              }
            },
          }}
        />
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SAVE,
            onClick: onSubmit,
          }}
        />
      </Box>
      {modalDisableAccount && (
        <ModalDisableInfluencerAccount
          modalOpen={modalDisableAccount}
          onCloseModal={() => onChangeModalDisableAccountStatus(false)}
          onCallbackSuccess={onCallbackDisableAccount}
          userID={userID}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalInfluencerInformation;
