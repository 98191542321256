import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Switch from "../../../Common/Forms/Switchs/Switch";
import { numberCompactFormat } from "../../../../utils/numberFormats";
import { logoPlatform } from "../../../../utils/socialNetworks";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_STATUS_FINISHED,
  BORDER_RADIUS,
} from "../../../../utils/constants";
import Loader from "../../../Common/Loader/Loader";
import { useTranslationApp } from "../../../../lib/i18next";
import { useNavigate } from "react-router-dom";
import {
  AGES,
  CLICKS,
  CTR,
  DRAFT,
  FINISHED,
  GENDERS,
  IMPRESSIONS,
  LOCATIONS,
  OBJECTIVE,
  PLATFORM,
  STATISTICS,
  TARGET_CAMPAIGN,
  VALUE_WITH_PERCENTAGE,
} from "../../../../locales/keysTranslations";
import { optionsAdsCampaignObjectives } from "../../../../utils/slidersMarks";
import { getKeyMetricAdsCampaign } from "../../../../utils/formats";

const AdsCampaignsListItem = ({
  data = {},
  campaignID,
  onChangeStatusCampaign = () => {},
}) => {
  const platform = data?.platform;

  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(`/ads-campaign?campaignID=${campaignID}`)}
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        width: "100%",
        backgroundColor: "background.paperSecondary",
        borderRadius: BORDER_RADIUS,
        py: { xs: 2, sm: 2 },
        px: { xs: 2, sm: 3 },
        gap: 2,
        cursor: "pointer",
        "&:hover": {
          filter: theme.palette.hover.main,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: { xs: 0, sm: 2 },
          width: { xs: "100%", sm: "50%" },
        }}
      >
        <NameItem
          data={data}
          onChangeStatusCampaign={onChangeStatusCampaign}
          campaignID={campaignID}
        />
        <ObjectiveItem data={data} />
        <TargetItem data={data} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "flex-end" },
          gap: 2,
          width: { xs: "100%", sm: "50%" },
        }}
      >
        {!isMobile && <PlatformsItem platform={platform} />}
        <StatisticsItem data={data} />
      </Box>
    </Box>
  );
};

const NameItem = ({ data, onChangeStatusCampaign = () => {}, campaignID }) => {
  const [isSwitching, setIsSwitching] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 1,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {data.name}
        </Typography>
        {data?.status === ADS_CAMPAIGN_STATUS_FINISHED && (
          <Typography
            variant="body2"
            color="fields.placeholder"
            sx={{
              mt: 0.25,
            }}
          >
            {t(FINISHED)}
          </Typography>
        )}
        {data?.status === ADS_CAMPAIGN_STATUS_DRAFT && (
          <Typography
            variant="body2"
            color="fields.placeholder"
            sx={{
              mt: 0.25,
            }}
          >
            {t(DRAFT)}
          </Typography>
        )}
        {data?.status !== ADS_CAMPAIGN_STATUS_FINISHED &&
          data?.status !== ADS_CAMPAIGN_STATUS_DRAFT && (
            <>
              {isSwitching && <Loader size={20} hasMarginTop={false} />}
              {!isSwitching && (
                <Switch
                  sx={{
                    mt: 0.25,
                  }}
                  hasLabel={false}
                  isActive={data.status === "active"}
                  size="small"
                  onChangeIsActive={async () => {
                    setIsSwitching(true);
                    await onChangeStatusCampaign(campaignID);
                    setIsSwitching(false);
                  }}
                />
              )}
            </>
          )}
      </Box>
      {isMobile && <PlatformsItem platform={data.platform} />}
    </Box>
  );
};

const SubTitleSection = ({ text, align }) => {
  const { t } = useTranslationApp();
  return (
    <Typography variant="body2" color="fields.placeholder" align={align}>
      {t(text)}
    </Typography>
  );
};

const TargetItem = ({ data }) => {
  const locations = data?.locations || null;
  const locationsRender = [...locations]
    .splice(0, 3)
    .map((location) => location.name);
  const hasMoreLocations = locations.length > 3;

  const genders = data?.genders || null;
  const gendersActive = genders
    ? Object.keys(genders).filter((gender) => genders[gender])
    : null;

  const age = data?.age || null;

  const { t } = useTranslationApp();

  if (!locations && !gendersActive && !age) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SubTitleSection text={TARGET_CAMPAIGN} />
      {locations && (
        <Typography variant="body1">{`${t(LOCATIONS)}: ${locationsRender.join(
          ", "
        )}${hasMoreLocations ? "..." : ""}`}</Typography>
      )}
      {gendersActive && (
        <Typography variant="body1">
          {`${t(GENDERS)}: ${gendersActive
            .map((gender) => t(gender))
            .join(", ")}`}
        </Typography>
      )}
      {age && (
        <Typography variant="body1">{`${t(AGES)}: ${age[0]} - ${
          age[1]
        }`}</Typography>
      )}
    </Box>
  );
};

const ObjectiveItem = ({ data }) => {
  const objectiveObject = optionsAdsCampaignObjectives.find(
    (objetive) => objetive.value === data.objective
  );

  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        mb: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      <SubTitleSection text={OBJECTIVE} />
      <Typography variant="body1">{t(objectiveObject.label)}</Typography>
    </Box>
  );
};

const PlatformsItem = ({ platform }) => {
  return (
    <Box>
      <SubTitleSection text={PLATFORM} align="right" />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, gap: 1 }}>
        <Avatar
          src={logoPlatform(platform)}
          alt={`${platform} Logo`}
          variant="square"
          sx={{ width: 30, height: "auto" }}
        />
      </Box>
    </Box>
  );
};

const StatisticsItem = ({ data }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslationApp();

  const statistics = data?.statistics || {};

  const keyMetricInformation = getKeyMetricAdsCampaign(data);

  return (
    <Box>
      <SubTitleSection text={STATISTICS} align={isMobile ? "left" : "right"} />
      <Box
        sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 1 }}
      >
        <Box>
          <Typography variant="body1">{t(IMPRESSIONS)}</Typography>
          <Typography variant="body1">
            {numberCompactFormat(statistics.impressions || 0)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">{t(CLICKS)}</Typography>
          <Typography variant="body1">
            {numberCompactFormat(statistics.clicks || 0)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">{t(CTR)}</Typography>
          <Typography variant="body1">
            {t(VALUE_WITH_PERCENTAGE, {
              value: statistics.ctr || 0,
            })}
          </Typography>
        </Box>
        {keyMetricInformation && (
          <Box>
            <Typography variant="body1">
              {t(keyMetricInformation.label)}
            </Typography>
            <Typography variant="body1">
              {numberCompactFormat(keyMetricInformation.value || 0)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdsCampaignsListItem;
