import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ModalBasicLayout from "./ModalBasicLayout";
import { getExtensionFile } from "../../../utils/urls";
import {
  ROLES_SHOPS,
  TIKTOK,
  VIDEO_FORMATS,
  IMAGE_FORMATS,
  INSTAGRAM,
} from "../../../utils/constants";
import LoadingButton from "../Buttons/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatchApp } from "../../../lib/redux";
import { getRole } from "../../../actions/getters";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_DOWNLOAD,
  BUTTON_UPLOAD_INSTAGRAM,
  BUTTON_UPLOAD_TIKTOK,
  MODAL_MEDIA_ARIA_LABEL,
  MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TITLE,
  MODAL_UPLOAD_TIKTOK_ARIA_LABEL,
  MODAL_UPLOAD_TIKTOK_TITLE,
} from "../../../locales/keysTranslations";
import ModalUploadSocialNetwork from "./ModalUploadSocialNetwork";
import ButtonMetaAction from "../Buttons/ButtonMetaAction";
import ButtonTikTokAction from "../Buttons/ButtonTikTokAction";
import VideoPlayer from "../Video/VideoPlayer";
import { startGetAwsDownloadUrl } from "../../../actions/shops";

const ModalMedia = ({
  modalOpen = false,
  onCloseModal,
  url,
  metadata = {},
  onClosePrevComponent = () => {},
  disabledActions = false,
  customActions,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [modalUploadSocialNetworkOpen, setModalUploadSocialNetworkOpen] =
    useState(false);
  const [from, setFrom] = useState("");
  const extension = getExtensionFile(url);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const role = dispatch(getRole());

  const getTitles = () => {
    if (from === TIKTOK)
      return {
        title: MODAL_UPLOAD_TIKTOK_TITLE,
        ariaLabel: MODAL_UPLOAD_TIKTOK_ARIA_LABEL,
      };
    if (from === INSTAGRAM)
      return {
        title: MODAL_UPLOAD_INSTAGRAM_TITLE,
        ariaLabel: MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL,
      };
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      ariaLabel={t(MODAL_MEDIA_ARIA_LABEL)}
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
      }}
      sxTitle={{
        py: 1,
      }}
    >
      <Box
        sx={{
          height: { xs: "70vh", sm: "80vh" },
          display: "flex",
          justifyContent: "center",
          px: 1,
        }}
        className="modal-media-content"
      >
        {VIDEO_FORMATS.includes(extension) ? (
          <VideoPlayer
            url={url}
            controls={true}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            controlsList="nodownload"
            autoplay
            loop
            muted
          />
        ) : (
          <Avatar
            variant="rounded"
            src={url}
            alt={`Media`}
            sx={{
              height: "100%",
              width: "100%",
              ".MuiAvatar-img": {
                objectFit: "contain",
                objectPosition: "top",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          height: { xs: "22vh", sm: "13vh" },
          display: "flex",
          alignItems: { xs: "flex-end", sm: "center" },
          justifyContent: "space-between",
          gap: 2,
          width: "100%",
          px: 2,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
            width: "100%",
          }}
        >
          {customActions}
          {!disabledActions && (
            <>
              <LoadingButton
                loading={isFetching}
                startIcon={<DownloadIcon />}
                color="secondary"
                sx={{
                  backgroundColor: isFetching
                    ? "gray !important"
                    : "secondary.main",
                }}
                onClick={async () => {
                  setIsFetching(true);
                  await dispatch(startGetAwsDownloadUrl(url));
                  setIsFetching(false);
                }}
              >
                {t(BUTTON_DOWNLOAD)}
              </LoadingButton>
              {VIDEO_FORMATS.includes(extension) &&
                ROLES_SHOPS.includes(role) && (
                  <ButtonTikTokAction
                    text={BUTTON_UPLOAD_TIKTOK}
                    sx={{
                      width: "auto",
                    }}
                    onClick={() => {
                      setFrom(TIKTOK);
                      setModalUploadSocialNetworkOpen(true);
                    }}
                  />
                )}
              {(VIDEO_FORMATS.includes(extension) ||
                IMAGE_FORMATS.includes(extension)) &&
                ROLES_SHOPS.includes(role) && (
                  <ButtonMetaAction
                    sx={{ width: "auto" }}
                    text={BUTTON_UPLOAD_INSTAGRAM}
                    onClick={() => {
                      setFrom(INSTAGRAM);
                      setModalUploadSocialNetworkOpen(true);
                    }}
                  />
                )}
            </>
          )}
        </Box>
      </Box>

      {modalUploadSocialNetworkOpen && (
        <ModalUploadSocialNetwork
          modalOpen={modalUploadSocialNetworkOpen}
          onCloseModal={() => {
            setModalUploadSocialNetworkOpen(false);
            setFrom("");
          }}
          title={getTitles().title}
          ariaLabel={getTitles().ariaLabel}
          url={url}
          metadata={metadata}
          from={from}
          onClosePrevComponent={() => {
            onClosePrevComponent();
            onCloseModal();
          }}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalMedia;
