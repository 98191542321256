import _ from "lodash";
import { numberRounded } from "./numberFormats";
import { FORMATS_INVITES } from "./constants";

export const calculateSuggestedPrice = (
  analytics,
  averageTicket,
  minProductValueShop
) => {
  const getSuggestedPrice = () => {
    if (!analytics?.followers || !analytics?.engagement) {
      return numberRounded(averageTicket);
    }
    const audienceInteraction =
      analytics.followers * (analytics.engagement / 100);

    if (audienceInteraction >= 0 && audienceInteraction <= 500) {
      return numberRounded(averageTicket * 2);
    }
    if (audienceInteraction >= 501 && audienceInteraction <= 1000) {
      return numberRounded(averageTicket * 2.5);
    }
    if (audienceInteraction >= 1001 && audienceInteraction <= 2000) {
      return numberRounded(averageTicket * 3);
    }
    if (audienceInteraction >= 2001 && audienceInteraction <= 3000) {
      return numberRounded(averageTicket * 3.5);
    }
    if (audienceInteraction >= 3001 && audienceInteraction <= 5000) {
      return numberRounded(averageTicket * 4);
    }
    if (audienceInteraction >= 5001) {
      return numberRounded(averageTicket * 4.5);
    }
    return numberRounded(averageTicket);
  };

  const suggestedPrice = getSuggestedPrice();

  return suggestedPrice < minProductValueShop
    ? minProductValueShop
    : suggestedPrice;
};

export const validationProfileInfluencer = (
  profileInfluencer,
  mode = "all"
) => {
  if (
    mode === "all" &&
    (!profileInfluencer?.categories ||
      !profileInfluencer?.cities ||
      !profileInfluencer?.gender ||
      !profileInfluencer?.ageRange ||
      !profileInfluencer?.audienceRange ||
      !profileInfluencer?.engagementRange ||
      !profileInfluencer?.audienceGender ||
      !profileInfluencer?.audienceAgeRange ||
      !profileInfluencer?.platforms ||
      !profileInfluencer?.numberInvitations ||
      !profileInfluencer?.budgets ||
      !profileInfluencer?.budgetMonthly ||
      !profileInfluencer?.comment ||
      !profileInfluencer?.typeContent)
  ) {
    return false;
  }
  if (
    mode === "profileInfluencer" &&
    (!profileInfluencer?.categories ||
      !profileInfluencer?.cities ||
      !profileInfluencer?.gender ||
      !profileInfluencer?.ageRange ||
      !profileInfluencer?.audienceGender ||
      !profileInfluencer?.audienceAgeRange ||
      !profileInfluencer?.platforms)
  ) {
    return false;
  }
  if (
    mode === "automaticInvitations" &&
    (!profileInfluencer?.budgets ||
      !profileInfluencer?.budgetMonthly ||
      !profileInfluencer?.numberInvitations ||
      !profileInfluencer?.comment ||
      !profileInfluencer?.typeContent)
  ) {
    return false;
  }
  return true;
};

export const getEmailInfluencerExternalData = (data) => {
  let email = null;
  if (!_.isEmpty(data?.contactDetails)) {
    const emailFound = data.contactDetails.find(
      (contact) => contact.type === "email"
    );
    email = emailFound?.value;
  }
  return email;
};

export const getSuggestedInviteInformation = ({
  profileInfluencer,
  lastInviteConfiguration = {},
  influencer,
}) => {
  if (!profileInfluencer || !influencer) return {};

  let budget = {
    value: 0,
  };

  const {
    comment: lastComment,
    ugcContentFormat: lastUgcContentFormat,
    language: lastLanguage,
    referenceUrl: lastReferenceUrl,
    formats: lastFormats,
    wantsSocialMediaPost,
  } = lastInviteConfiguration;

  const comment = lastComment || profileInfluencer?.comment || "";
  const terms = profileInfluencer?.terms || "";
  const formats = lastFormats || profileInfluencer?.formats || FORMATS_INVITES;
  const contentReference =
    lastReferenceUrl || profileInfluencer?.contentReference || "";
  const typeContent = {
    images: false,
    videos: true,
  };
  const language = lastLanguage || "";
  const ugcContentFormat = lastUgcContentFormat || "";

  if (!profileInfluencer?.budgets || profileInfluencer?.budgets?.length === 0) {
    return {
      comment,
      terms,
      formats,
      referenceUrl: contentReference,
      typeContent,
      language,
      ugcContentFormat,
      wantsSocialMediaPost,
      value: "",
    };
  }

  profileInfluencer?.budgets?.forEach((budgetItem) => {
    const audienceRangeMin = Number(budgetItem.audienceRange[0]);
    const audienceRangeMax = Number(budgetItem.audienceRange[1]);
    const budgetItemValue = Number(budgetItem.value);
    const budgetValue = Number(budget.value);

    if (
      audienceRangeMax === 500000 &&
      influencer?.followers >= audienceRangeMin &&
      budgetItemValue > budgetValue
    ) {
      budget = budgetItem;
      return;
    }

    if (
      influencer?.followers >= audienceRangeMin &&
      influencer?.followers <= audienceRangeMax &&
      budgetItemValue > budgetValue
    ) {
      budget = budgetItem;
      return;
    }

    return;
  });

  if (!budget.value) {
    //search a most near budget
    let minDiff = 1000;
    profileInfluencer?.budgets?.forEach((budgetItem) => {
      const audienceRangeMin = Number(budgetItem.audienceRange[0]);
      const audienceRangeMax = Number(budgetItem.audienceRange[1]);

      const diffMin = Math.abs(influencer?.followers - audienceRangeMin);
      const diffMax = Math.abs(influencer?.followers - audienceRangeMax);

      if (diffMin <= minDiff || diffMax <= minDiff) {
        minDiff = diffMin;
        budget = budgetItem;
      }
    });
  }

  const inviteValue = parseInt(budget.value) || "";

  return {
    comment,
    terms,
    formats,
    referenceUrl: contentReference,
    typeContent,
    language,
    ugcContentFormat,
    wantsSocialMediaPost,
    value: inviteValue,
  };
};
