import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import logoTiktok from "../../../assets/img/logo-tiktok.webp";
import logoIG from "../../../assets/img/logo-ig.webp";
import logoFB from "../../../assets/img/logo-fb.webp";
import logoYT from "../../../assets/img/logo-yt.png";
import LinkText from "../Texts/LinkText";

const SocialNetworks = {
  tiktok: logoTiktok,
  instagram: logoIG,
  facebook: logoFB,
  youtube: logoYT,
};

const InfluencerSocialNetwork = ({
  influencerName = "",
  influencerPlatform = "instagram",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar
        alt={`Logo de ${influencerPlatform}`}
        src={SocialNetworks[influencerPlatform]}
        variant="square"
        sx={{ mr: 1, width: "38px", height: "38px" }}
      />
      <LinkText
        target="_blank"
        href={`${
          influencerPlatform === "instagram"
            ? "https://www.instagram.com/"
            : influencerPlatform === "tiktok"
            ? "https://www.tiktok.com/@"
            : "https://www.youtube.com/c/"
        }${influencerName}`}
        sx={{ fontWeight: 600 }}
      >
        {influencerName}
      </LinkText>
    </Box>
  );
};

export default InfluencerSocialNetwork;
