import React from "react";
import Accordion from "../../Common/Containers/Accordion";
import Box from "@mui/material/Box";
import { STATUS_ORDERS } from "../../../utils/constants";
import ContainerKeyValue from "../../Common/Containers/ContainerKeyValue";
import Typography from "@mui/material/Typography";
import {
  REPORT_ORDERS,
  STATUS,
  ORDERS,
  DELIVERIES,
  IN_STORE,
  ORDERS_PENDING,
  ORDERS_PENDING_ANALYTICS,
  ORDERS_FEEDBACK,
  ORDERS_SENT,
  ORDERS_REVIEWED,
  CREATOR,
  VALUE,
  WAS_GENERATED,
  VALUE_WITH_CURRENCY,
  DATE_LONG,
  COLUMN_NAME_ID,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocales } from "../../../Contexts/LocaleContext";

const ShopReportOrders = ({ data, influencers, summary, shop }) => {
  const { t } = useTranslationApp();

  return (
    <Accordion title={t(REPORT_ORDERS)} sx={{}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          maxHeight: 400,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
          <Typography variant="body1">{`${t(ORDERS)}: ${
            summary.totalOrders
          } - `}</Typography>
          <Typography variant="body1">{`${t(DELIVERIES)}: ${
            summary.deliveryOrders
          } - `}</Typography>
          <Typography variant="body1">{`${t(IN_STORE)}: ${
            summary.storeOrders
          } - `}</Typography>
          <Typography variant="body1">{`${t(ORDERS_PENDING)}: ${
            summary.pending
          } - `}</Typography>
          <Typography variant="body1">{`${t(ORDERS_PENDING_ANALYTICS)}s: ${
            summary.pendingAnalytics
          } - `}</Typography>
          <Typography variant="body1">{`${t(ORDERS_FEEDBACK)}: ${
            summary.negativeReview
          } - `}</Typography>
          <Typography variant="body1">{`${t(ORDERS_SENT)}: ${
            summary.sent
          } - `}</Typography>
          <Typography variant="body1">{`${t(ORDERS_REVIEWED)}: ${
            summary.reviewed
          }`}</Typography>
        </Box>
        {Object.keys(data).map((orderKey) => {
          return (
            <Order
              key={orderKey}
              orderKey={orderKey}
              data={data[orderKey]}
              influencer={influencers[data[orderKey].userID]}
              userID={data[orderKey].userID}
              shop={shop}
            />
          );
        })}
      </Box>
    </Accordion>
  );
};

const Order = ({ data, orderKey, influencer, userID, shop }) => {
  const { t } = useTranslationApp();
  const LOCALES = useLocales();
  const LOCALE = LOCALES[shop.signUpCountry];
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ContainerKeyValue
          keyValue={`${t(COLUMN_NAME_ID)}: `}
          value={orderKey}
          redirectURL="content?orderID"
        />
        <ContainerKeyValue
          keyValue={`${t(CREATOR)}: `}
          value={influencer?.mainAccountName}
          redirectURL="influencers-accounts?userID"
          redirectValue={userID}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            currency: LOCALE.currency,
            value: data.value,
          })}`}
        />
        <ContainerKeyValue
          keyValue={`${t(WAS_GENERATED)}: `}
          value={t(DATE_LONG, {
            date: new Date(data.date),
          })}
        />
        <ContainerKeyValue
          keyValue={`${t(STATUS)}: `}
          value={t(
            STATUS_ORDERS.find((c) => c.value === data.postStatus).label
          )}
        />
      </Box>
    </Box>
  );
};

export default ShopReportOrders;
