import React from "react";
import { TourProvider, components } from "@reactour/tour";
import { STEPS_MAIN_FUNCTIONALITY } from "./steps";
import OnboardingContainer from "./OnboardingContainer";
import { GREY_COLOR, GREY_DARK_COLOR } from "../utils/colors";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../components/Common/Buttons/Button";
import { useSelectorApp } from "../lib/redux";
import { useTheme } from "@mui/material";

function Badge({ children }) {
  const theme = useTheme();
  console.log(theme);
  return (
    <components.Badge
      styles={{
        badge: (base) => ({
          ...base,
          backgroundColor: GREY_COLOR,
          color: "black",
        }),
      }}
    >
      {children}
    </components.Badge>
  );
}
function Close({ onClick }) {
  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      size="small"
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        padding: "3px",
        color: "black",
        backgroundColor: `${GREY_COLOR} !important`,
        "&:hover": {
          backgroundColor: `${GREY_COLOR} !important`,
          color: "black",
          filter: "brightness(0.8)",
        },
      }}
    >
      <CloseIcon fontSize="1.3rem" />
    </IconButton>
  );
}
function PrevButton({ currentStep, setCurrentStep }) {
  const disabled = currentStep === 0;

  const onClick = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <Button
      id="prev-button-step"
      size="small"
      onClick={onClick}
      disabled={disabled}
      color="primary"
      sx={{
        borderRadius: "50%",
      }}
    >
      <ArrowBackIcon
        sx={{
          fontSize: "1.0rem",
          fontWeight: 600,
        }}
      />
    </Button>
  );
}
function NextButton({ stepsLength, currentStep, setCurrentStep }) {
  const disabled = currentStep === stepsLength - 1;

  const onClick = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <Button
      id="next-button-step"
      size="small"
      onClick={onClick}
      disabled={disabled}
      color="secondary"
      sx={{
        borderRadius: "50%",
      }}
    >
      <ArrowForwardIcon
        sx={{
          fontSize: "1.0rem",
          fontWeight: 600,
        }}
      />
    </Button>
  );
}

const OnboardingContext = ({ children }) => {
  const { step, steps } = useSelectorApp((state) => state.onboarding);
  const currentStep = steps[step];

  const theme = useTheme();

  return (
    <TourProvider
      // disableWhenSelectorFalsy={true}
      disableDotsNavigation={true}
      disableKeyboardNavigation={true}
      disableInteraction={true}
      showBadge={false}
      steps={STEPS_MAIN_FUNCTIONALITY}
      scrollSmooth={true}
      afterOpen={() => {
        document.body.style.overflow = "hidden";
      }}
      beforeClose={() => {
        document.body.style.overflow = "unset";
      }}
      onClickMask={() => {}}
      padding={{
        popover: 18,
      }}
      components={{ Badge, Close }}
      prevButton={PrevButton}
      nextButton={NextButton}
      showDots={false}
      styles={{
        dot: (base, { current }) => ({
          ...base,
          backgroundColor: current ? GREY_DARK_COLOR : GREY_COLOR,
        }),
        popover: (base) => ({
          ...base,
          backgroundColor: theme.palette.background.paper,
          borderRadius: "20px",
          display: currentStep?.isHidden ? "none" : "inherit",
        }),
        maskArea: (base) => ({
          ...base,
          rx: 20,
        }),
        highlightedArea: (base) => ({
          ...base,
          rx: 20,
        }),
      }}
    >
      <OnboardingContainer>{children}</OnboardingContainer>
    </TourProvider>
  );
};

export default OnboardingContext;
