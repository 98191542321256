import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TooltipModal from "../../Common/Tooltips/TooltipModal";
import ContentQuality from "../../Common/Tooltips/Content/ContentQuality";
import Loader from "../../Common/Loader/Loader";
import Select from "../../Common/Selects/Select";
import TextField from "../../Common/Fields/TextField";
import { Controller } from "react-hook-form";
import { useTour } from "@reactour/tour";
import {
  REVIEW_FORM,
  REVIEW_FORM_COMMENT,
  REVIEW_FORM_CONTENT,
  REVIEW_FORM_DO_AMBASSADOR,
  REVIEW_FORM_SCORE_CONTENT,
  STEP_MAIN_FUNCTIONALITY,
} from "../../../onboarding/stepsSelectors";
import { useLocale } from "../../../Contexts/LocaleContext";
import {
  FIELD_REQUIRED,
  INFLUENCER_IS_AMBASSADOR,
  INVITE_COMMENT_MIN_LENGTH,
  INVITE_VALUE_MIN_VALUE,
  MODAL_REVIEW_COMMENT_PLACEHOLDER,
  MODAL_REVIEW_DISCLAIMER,
  MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER,
  MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE,
  MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE,
  MODAL_REVIEW_SCORE_PLACEHOLDER,
  NO,
  YES,
  FIELD_MIN_VALUE_TO_PAY_AMBASSADOR,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Rating from "../../Common/Forms/Rating/Rating";
import { getRole } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import {
  MIN_ALL_NUMBER_VALUE,
  ROLES_ADMIN_SHOPS,
} from "../../../utils/constants";
import AmbassadorForm from "../Ambassadors/AmbassadorForm";

const ReviewForm = ({
  isAmbassador,
  isFetching,
  control,
  watch,
  setValue,
  errors,
}) => {
  const { isOpen, currentStep, steps } = useTour();
  const onboardingName = steps[currentStep]?.onboardingName;
  const LOCALE = useLocale();

  const dispatch = useDispatchApp();
  const role = dispatch(getRole());
  const isAdminUser = ROLES_ADMIN_SHOPS.includes(role);

  const doAmbassador = watch("doAmbassador");
  const withRecurrentInvite = watch("withRecurrentInvite");
  const withRecurrentPayment = watch("withRecurrentPayment");

  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      data-tour={REVIEW_FORM}
    >
      <Box
        data-tour={REVIEW_FORM_CONTENT}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Typography variant="body1" gutterBottom sx={{ fontWeight: 600 }}>
          {t(MODAL_REVIEW_DISCLAIMER)}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t(MODAL_REVIEW_SCORE_PLACEHOLDER)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Controller
              name="score"
              control={control}
              rules={{
                validate: (value) => {
                  if (!isOpen) {
                    if (value === 0 || value === null) {
                      return t(FIELD_REQUIRED);
                    }
                    if (value === 1) {
                      return t(MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE);
                    }
                  }
                },
              }}
              render={({ field }) => (
                <Rating
                  {...field}
                  data-tour={REVIEW_FORM_SCORE_CONTENT}
                  value={Number(field.value)}
                  precision={1}
                  sx={{
                    fontSize: { xs: "2.20rem", md: "2.75rem" },
                  }}
                />
              )}
            />
            <TooltipModal
              title={t(MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE)}
              content={<ContentQuality />}
            />
          </Box>
          {errors["score"] && errors["score"]?.message && (
            <Typography variant="body1" color="error">
              {errors["score"].message}
            </Typography>
          )}
        </Box>
        <TextField
          dataTour={REVIEW_FORM_COMMENT}
          label={t(MODAL_REVIEW_COMMENT_PLACEHOLDER)}
          name="comment"
          multiline
          minRows={2}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            validate: (value) => {
              if (!isOpen) {
                if (value.length > 0 && value.length < 10) {
                  return t(INVITE_COMMENT_MIN_LENGTH, {
                    value: 10,
                  });
                }
              }
            },
          }}
        />
      </Box>
      {(isOpen && onboardingName === STEP_MAIN_FUNCTIONALITY) ||
      !isAdminUser ? null : (
        <>
          {isFetching ? (
            <Loader size={40} />
          ) : isAmbassador ? (
            <Typography variant="body1" sx={{ mt: 2, fontWeight: 600 }}>
              {t(INFLUENCER_IS_AMBASSADOR)}
            </Typography>
          ) : (
            <Box
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {t(MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER)}
              </Typography>
              <Select
                dataTour={REVIEW_FORM_DO_AMBASSADOR}
                name="doAmbassador"
                displayEmpty={true}
                variant="filled"
                options={[
                  { value: false, label: t(NO) },
                  { value: true, label: t(YES) },
                ]}
                fullWidth
                sx={{
                  "& .MuioutlinedInput-root": {
                    "& .MuiSelect-select": {
                      pt: "16px !important",
                      pb: "16px !important",
                    },
                  },
                }}
                control={control}
                actionsChange={() => {
                  setValue("nextConsumption", "");
                  setValue("value", 0);
                  setValue("inviteCode", "");
                }}
                errors={errors}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Box>
          )}
          {!isAmbassador && doAmbassador && (
            <AmbassadorForm
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              validate={{
                value: {
                  validate: (value) => {
                    if (!isOpen && withRecurrentInvite) {
                      if (value === "" || value === null || !value) {
                        return t(FIELD_REQUIRED);
                      }
                      if (parseInt(value) < MIN_ALL_NUMBER_VALUE) {
                        return t(INVITE_VALUE_MIN_VALUE, {
                          value: MIN_ALL_NUMBER_VALUE,
                          currency: LOCALE.currency,
                        });
                      }
                    }
                  },
                },
                valueToPay: {
                  validate: (value) => {
                    if (withRecurrentPayment) {
                      if (value === "" || value === null || !value) {
                        return t(FIELD_REQUIRED);
                      }
                      if (parseInt(value) < MIN_ALL_NUMBER_VALUE) {
                        return t(FIELD_MIN_VALUE_TO_PAY_AMBASSADOR, {
                          value: MIN_ALL_NUMBER_VALUE,
                          currency: "USD",
                        });
                      }
                    }
                  },
                },
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ReviewForm;
