import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import {
  APPLICANT_PAYMENT_STATUS_PENDING,
  APPLICANT_PAYMENT_STATUS_PENDING_RELEASE,
  APPLICANT_PAYMENT_STATUS_RELEASED,
} from "../../../utils/constants";

const PaymentsTypeFilter = ({ paymentType, onChangePaymentType }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      <Chip
        icon={<MoneyOffIcon />}
        label="Pendiente"
        color={
          paymentType === APPLICANT_PAYMENT_STATUS_PENDING
            ? "primary"
            : "default"
        }
        onClick={() => {
          onChangePaymentType(APPLICANT_PAYMENT_STATUS_PENDING);
        }}
      />
      <Chip
        icon={<AttachMoneyIcon />}
        label="Pendiente de Liberar"
        color={
          paymentType === APPLICANT_PAYMENT_STATUS_PENDING_RELEASE
            ? "primary"
            : "default"
        }
        onClick={() => {
          onChangePaymentType(APPLICANT_PAYMENT_STATUS_PENDING_RELEASE);
        }}
      />
      <Chip
        icon={<PriceCheckIcon />}
        label="Liberado"
        color={
          paymentType === APPLICANT_PAYMENT_STATUS_RELEASED
            ? "primary"
            : "default"
        }
        onClick={() => {
          onChangePaymentType(APPLICANT_PAYMENT_STATUS_RELEASED);
        }}
      />
    </Box>
  );
};

export default PaymentsTypeFilter;
