import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "../../Fields/TextField";
import {
  BUSINESS_NAME_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  FIELD_ALL_REQUIRED,
  FIELD_EMAIL_VALID,
  FIELD_MIN_LENGTH,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_CHANGE_MIN_LENGTH,
  FIELD_PASSWORD_MIN_LENGTH,
  NAME_AND_LAST_NAME_PLACEHOLDER,
  PASSWORD_CONFIRMATION_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { REGEX_EMAIL } from "../../../../utils/regexsValidation";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AddBrandSuperAdminForm = ({ control, errors, watch }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslationApp();

  const repeatPassword = watch("repeatPassword");
  const password = watch("password");

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1 }}>
      <TextField
        name="businessName"
        variant="filled"
        label={t(BUSINESS_NAME_PLACEHOLDER)}
        fullWidth
        control={control}
        errors={errors}
        rules={{
          required: {
            value: true,
            message: t(FIELD_ALL_REQUIRED),
          },
          minLength: {
            value: 3,
            message: t(FIELD_MIN_LENGTH, { length: 3 }),
          },
        }}
      />
      <TextField
        name="name"
        variant="filled"
        label={t(NAME_AND_LAST_NAME_PLACEHOLDER)}
        fullWidth
        control={control}
        errors={errors}
        rules={{
          required: {
            value: true,
            message: t(FIELD_ALL_REQUIRED),
          },
          minLength: {
            value: 3,
            message: t(FIELD_MIN_LENGTH, { length: 3 }),
          },
        }}
      />
      <TextField
        name="email"
        variant="filled"
        label={t(EMAIL_PLACEHOLDER)}
        fullWidth
        control={control}
        errors={errors}
        rules={{
          required: {
            value: true,
            message: t(FIELD_ALL_REQUIRED),
          },
          pattern: {
            value: REGEX_EMAIL,
            message: t(FIELD_EMAIL_VALID),
          },
        }}
      />
      <TextField
        name="password"
        variant="filled"
        label={t(PASSWORD_PLACEHOLDER)}
        fullWidth
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
        control={control}
        errors={errors}
        rules={{
          required: {
            value: true,
            message: t(FIELD_ALL_REQUIRED),
          },
          validate: (value) => {
            if (value.length < 6) {
              return t(FIELD_PASSWORD_MIN_LENGTH);
            }
            if (value !== repeatPassword) {
              return t(FIELD_PASSWORDS_NO_MATCH);
            }
            if (value.length > 0 && value.length < 6) {
              return t(FIELD_PASSWORD_CHANGE_MIN_LENGTH);
            }
          },
        }}
      />
      <TextField
        name="repeatPassword"
        variant="filled"
        label={t(PASSWORD_CONFIRMATION_PLACEHOLDER)}
        fullWidth
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
        control={control}
        errors={errors}
        rules={{
          required: {
            value: true,
            message: t(FIELD_ALL_REQUIRED),
          },
          validate: (value) => {
            if (value.length < 6) {
              return t(FIELD_PASSWORD_MIN_LENGTH);
            }
            if (value !== password) {
              return t(FIELD_PASSWORDS_NO_MATCH);
            }
            if (value.length > 0 && value.length < 6) {
              return t(FIELD_PASSWORD_CHANGE_MIN_LENGTH);
            }
          },
        }}
      />
    </Box>
  );
};

export default AddBrandSuperAdminForm;
