import React from "react";
import Link from "@mui/material/Link";

const LinkText = ({
  variant = "body1",
  children,
  href,
  target = "_blank",
  rel = "noopener noreferrer",
  sx = {},
  onClick = () => {},
  dataTour = "",
}) => {
  return (
    <Link
      data-tour={dataTour}
      variant={variant}
      href={href}
      target={target}
      onClick={onClick}
      rel={rel}
      sx={{
        color: "background.paperContrastText",
        textDecorationColor: "unset",
        ...sx,
        // textDecorationColor: "background.paperContrastText",
      }}
    >
      {children}
    </Link>
  );
};

export default LinkText;
