import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../components/Common/SearchBar/SearchBar";
import RequestsInfluencersAccountsTable from "../../components/Owner/RequestsInfluencersAccounts/RequestsInfluencersAccountsTable";
import {
  startAcceptRequestInfluencer,
  startGetRequestAccountByName,
  startGetRequestsInfluencers,
  startMarkCodeSent,
  startRejectRequestInfluencer,
} from "../../actions/requestsInfluencers";
import Loader from "../../components/Common/Loader/Loader";
import ModalRequestInfluencer from "../../components/Owner/RequestsInfluencersAccounts/ModalRequestInfluencer";
import { SimpleAlert } from "../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  DEFAULT_TABLE_PAGES,
} from "../../utils/constants";
import { usePagination } from "../../hooks/usePagination";
import TitlePage from "../../components/Common/Texts/TitlePage";
import {
  LOADING_INFORMATION,
  REQUESTS_INFLUENCERS_ACCOUNT_TITLE,
  REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER,
} from "../../locales/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const RequestsInfluencersAccounts = () => {
  const [data, setData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [requestSelected, setRequestSelected] = useState(null);

  const matches = useMediaQuery("(min-width:500px)");

  const dispatch = useDispatchApp();
  const { requestsInfluencers, isLoading } = useSelectorApp(
    (state) => state.requestsInfluencers
  );
  const country = useSelectorApp((state) => state?.auth?.user?.country);
  const { t } = useTranslationApp();

  const getNextRequests = async () => {
    // await dispatch(
    //   startGetRequestsInfluencers({
    //     lastKey,
    //     limit: rowsPerPage,
    //     onChangeLastKey,
    //   })
    // );
  };

  const getInitialData = async () => {
    dispatch(
      startGetRequestsInfluencers({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
      })
    );
  };

  const {
    rowsPerPage,
    page,
    // lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextRequests,
  });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    setData(requestsInfluencers);
  }, [requestsInfluencers]);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onSelectRequest = (request, uid) => {
    setRequestSelected({
      ...request,
      uid,
    });
    setOpenModal(true);
  };

  const onCodeSent = async (id) => {
    const response = await dispatch(startMarkCodeSent(id));
    if (!response) {
      SimpleAlert({
        title: "Ocurrió un Error",
        text: "Ocurrió un error al marcar como enviado el código de la solicitud, intenta de nuevo",
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return response;
  };
  const onAcceptRequest = async (code) => {
    const response = await dispatch(
      startAcceptRequestInfluencer(requestSelected.uid, code)
    );
    if (response) {
      SimpleAlert({
        title: "Solicitud Aceptada",
        text: "La solicitud ha sido aceptada correctamente",
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();
    } else {
      SimpleAlert({
        title: "Ocurrió un Error",
        text: "Ocurrió un error al aceptar la solicitud, intenta de nuevo",
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return response;
  };
  const onRejectRequest = async () => {
    const response = await dispatch(
      startRejectRequestInfluencer(requestSelected.uid)
    );
    if (response) {
      SimpleAlert({
        title: "Solicitud Rechazada",
        text: "La solicitud ha sido rechazada correctamente",
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();
    } else {
      SimpleAlert({
        title: "Ocurrió un Error",
        text: "Ocurrió un error al rechazar la solicitud, intenta de nuevo",
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return response;
  };

  const onSearchRequest = async (text) => {
    setSearchText(text);
    if (text.length > 0) {
      onPageChange(0);
      const response = await dispatch(startGetRequestAccountByName(text));
      setData({
        ...data,
        ...response,
      });
    }
  };
  return (
    <ContainerPage sx={{}}>
      <TitlePage>{t(REQUESTS_INFLUENCERS_ACCOUNT_TITLE)}</TitlePage>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: matches ? "row" : "column",
          width: "100%",
          mt: 2,
          mb: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            minWidth: 200,
          }}
        >
          <SearchBar
            placeholder={t(REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER)}
            hasButton={false}
            onEditText={(text) => onSearchRequest(text)}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Loader
          size={70}
          hasMessage={true}
          message={t(LOADING_INFORMATION)}
          variantMessage="h5"
          fullWidth={true}
        />
      ) : (
        <RequestsInfluencersAccountsTable
          data={data}
          searchText={searchText}
          onSelectRequest={onSelectRequest}
          onCodeSent={onCodeSent}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
        />
      )}
      {openModal && (
        <ModalRequestInfluencer
          open={openModal}
          data={requestSelected}
          onCloseModal={onCloseModal}
          onAcceptRequest={onAcceptRequest}
          onRejectRequest={onRejectRequest}
        />
      )}
    </ContainerPage>
  );
};

export default RequestsInfluencersAccounts;
