import React, { useState } from "react";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import DatesFields from "../components/Common/Fields/DatesFields";
import Loader from "../components/Common/Loader/Loader";
import {
  LOADING_REPORT_INFORMATION,
  TOP_CONTENT_DESCRIPTION,
  TOP_CONTENT_NOT_FOUND_IN_INTERVAL,
  TOP_CONTENT_TITLE,
} from "../locales/keysTranslations";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import useFocusErrorForm from "../hooks/useFocusErrorForm";
import { useTranslationApp } from "../lib/i18next";
import { useDispatchApp } from "../lib/redux";
import { startGetTopContent } from "../actions/orders";
import ShopPosts from "../components/Common/Shop/ShopPosts/ShopPosts";

const TopContent = () => {
  const monthAgo = subMonths(new Date(), 1);

  const { control, handleSubmit, getValues, formState } = useForm({
    defaultValues: {
      startDate: startOfMonth(monthAgo),
      endDate: endOfMonth(monthAgo),
    },
  });
  const errors = formState.errors;
  useFocusErrorForm(formState);

  const [isFetching, setIsFetching] = useState(false);
  const [topOrders, setTopOrders] = useState([]);
  const [error, setError] = useState(null);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onSubmit = async (formValues) => {
    const startDateTime = new Date(formValues.startDate).getTime();
    const endDateTime = new Date(formValues.endDate).getTime();

    setError(null);
    setIsFetching(true);
    const response = await dispatch(
      startGetTopContent({
        startDate: startDateTime,
        endDate: endDateTime,
      })
    );

    if (response.ok) {
      setTopOrders(response.data);
    } else if (response.message) {
      setError(response.message);
    } else {
      setError(t(TOP_CONTENT_NOT_FOUND_IN_INTERVAL));
    }

    setIsFetching(false);
  };
  return (
    <BlockPlanNoActive>
      <ContainerPage sx={{}}>
        <TitlePage sx={{}}>{t(TOP_CONTENT_TITLE)}</TitlePage>

        <DescriptionPage>{t(TOP_CONTENT_DESCRIPTION)}</DescriptionPage>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mb: 2 }}>
          <DatesFields
            control={control}
            getValues={getValues}
            errors={errors}
            isFetching={isFetching}
            secondAction={() => onSubmit({ report: "business" })}
          />
        </Box>
        {isFetching ? (
          <Loader
            size={70}
            hasMessage={true}
            fullWidth={true}
            message={t(LOADING_REPORT_INFORMATION)}
          />
        ) : null}
        {!isFetching && error && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          </Box>
        )}

        {!isFetching && topOrders.length > 0 && !error && (
          <ShopPosts data={topOrders} />
        )}
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default TopContent;
