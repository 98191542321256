import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import DatesForm from "../Statistics/DatesForm";
import Loader from "../../Common/Loader/Loader";
import {
  startDeleteInviteByOwner,
  startExtendInvitationTime,
  startGenerateReportShop,
} from "../../../actions/owner";
import ShopReportOrders from "./ShopReportOrders";
import ShopReportRequests from "./ShopReportRequests";
import ShopReportInvites from "./ShopReportInvites";
import ShopReportInvitesInactive from "./ShopReportInvitesInactive";
import Button from "../../Common/Buttons/Button";
import { generateXLSXShopReport } from "../../../utils/xlsx";
import {
  ALERT_ICON_TYPE_SUCCESS,
  STATUS_ORDERS,
} from "../../../utils/constants";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { ConfirmAlert, SimpleAlert, TimerAlert } from "../../../utils/alerts";
import {
  BUTTON_DELETE,
  BUTTON_DOWNLOAD_REPORT,
  BUTTON_EXTEND,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING,
  DATE_LONG,
  DATE_SHORT,
  FIELD_BOTH_DATES_REQUIRED,
  FIELD_START_DATE_GREATER_THAN_END_DATE,
  INVITATION_EXPIRED,
  INVITATION_REDEEMED,
  LOADING_REPORT_INFORMATION,
  NO,
  SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION,
  SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE,
  SHOP_DETAIL_REPORT_EXTENDING_INVITE,
  SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION,
  SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE,
  SHOP_DETAIL_REPORT_INVITATION_EXTENDED,
  SHOP_DETAIL_REPORT_TITLE,
  SOMETHING_ERROR_GENERATING_STATISTICS,
  YES,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { startCreateOrderFromInviteID } from "../../../actions/orders";

const ShopReport = ({ data }) => {
  const [dataReport, setDataReport] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);

  const { control, getValues } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const getDataReport = async () => {
    const formValues = getValues();
    formValues.startDate = startOfDay(formValues.startDate);
    formValues.endDate = endOfDay(formValues.endDate);
    if (!formValues.startDate || !formValues.endDate) {
      return setError(t(FIELD_BOTH_DATES_REQUIRED));
    }
    if (
      new Date(formValues.startDate).getTime() >
      new Date(formValues.endDate).getTime()
    ) {
      return setError(t(FIELD_START_DATE_GREATER_THAN_END_DATE));
    }
    setError(null);
    setIsCalculated(false);
    setIsLoading(true);
    const response = await dispatch(
      startGenerateReportShop({
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        shopID: data.shopID,
      })
    );
    if (!response) {
      setIsCalculated(false);
      setIsLoading(false);
      return setError(t(SOMETHING_ERROR_GENERATING_STATISTICS));
    }
    setDataReport(response);
    setIsCalculated(true);
    setIsLoading(false);
  };

  const onDownloadReport = () => {
    const ordersData = dataReport.orders.orders;
    const orders = Object.keys(ordersData).map((key) => {
      return {
        ID: ` ${key}`,
        Date: t(DATE_LONG, {
          date: new Date(ordersData[key].dateTime),
        }),
        Analytics: ordersData[key].analytics,
        InviteID: ` ${ordersData[key].inviteID}`,
        Delivery: ordersData[key].isDelivery ? t(YES) : t(NO),
        Message: ordersData[key].message,
        Status: t(
          STATUS_ORDERS.find((c) => c.value === ordersData[key].postStatus)
            .label
        ),
        Posts: ordersData[key].posts,
        Store:
          data.stores[ordersData[key].storeID]?.name ?? ordersData[key].storeID,
        Creator: dataReport.influencers[ordersData[key].userID].mainAccountName,
        Value: ordersData[key].value,
      };
    });
    const invitesData = dataReport.invites;
    const invites = Object.keys(invitesData).map((key) => {
      return {
        ID: ` ${key}`,
        Date: t(DATE_LONG, {
          date: new Date(invitesData[key].creationTime),
        }),
        "Date to Use": t(DATE_LONG, {
          date: new Date(invitesData[key].orderFrom),
        }),
        "Invite Code": invitesData[key].inviteCode,
        Comment: invitesData[key].comment,
        "Is Automatic": invitesData[key].isAutomatic ? t(YES) : t(NO),
        Creator:
          dataReport.influencers[invitesData[key].userID].mainAccountName,
        Value: invitesData[key].value,
      };
    });
    const invitesInactiveData = dataReport.invitesInactive;
    const invitesInactive = Object.keys(invitesInactiveData).map((key) => {
      return {
        ID: ` ${key}`,
        Date: t(DATE_LONG, {
          date: new Date(invitesInactiveData[key].creationTime),
        }),
        "Date to Use": t(DATE_LONG, {
          date: new Date(invitesInactiveData[key].orderFrom),
        }),
        Status: invitesInactiveData[key].redemptionDate
          ? t(INVITATION_REDEEMED)
          : t(INVITATION_EXPIRED),
        "Redemption Date": invitesInactiveData[key].redemptionDate
          ? t(DATE_LONG, {
              date: new Date(invitesInactiveData[key].redemptionDate),
            })
          : " - ",
        "Invite Code": invitesInactiveData[key].inviteCode,
        Comment: invitesInactiveData[key].comment,
        "Is Automatic": invitesInactiveData[key].isAutomatic ? t(YES) : t(NO),
        Creator:
          dataReport.influencers[invitesInactiveData[key].userID]
            .mainAccountName,
        Value: invitesInactiveData[key].value,
      };
    });

    const requestsData = dataReport.requests;
    const requests = Object.keys(requestsData).map((key) => {
      return {
        ID: ` ${key}`,
        Date: t(DATE_LONG, {
          date: new Date(requestsData[key].creationTime),
        }),
        Creator:
          dataReport.influencers[requestsData[key].userID].mainAccountName,
      };
    });
    const formValues = getValues();
    const nameFile = `${data.businessName} - ${t(DATE_SHORT, {
      date: new Date(formValues.startDate),
    })} - ${t(DATE_SHORT, {
      date: new Date(formValues.endDate),
    })}`;

    generateXLSXShopReport({
      orders,
      invites,
      invitesInactive,
      requests,
      nameFile,
    });
  };

  const onDeleteInvite = async (inviteID) => {
    const result = await ConfirmAlert({
      title: t(SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE),
      text: t(SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startDeleteInviteByOwner(inviteID));
      if (response) {
        setDataReport((prevState) => {
          const invites = { ...prevState.invites };
          delete invites[inviteID];
          return { ...prevState, invites };
        });
      }
    }
    return;
  };

  const onExtendInvite = async (inviteID) => {
    const result = await ConfirmAlert({
      title: t(SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE),
      text: t(SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_EXTEND),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(SHOP_DETAIL_REPORT_EXTENDING_INVITE),
        timer: 15000,
      });
      const response = await dispatch(startExtendInvitationTime({ inviteID }));
      if (response) {
        setDataReport((prevState) => {
          const invites = {
            ...prevState.invites,
            [inviteID]: {
              ...prevState.invites[inviteID],
              isExtended: true,
              expiryDays: response,
            },
          };
          return { ...prevState, invites };
        });
        SimpleAlert({
          title: t(SHOP_DETAIL_REPORT_INVITATION_EXTENDED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
    return;
  };

  const onCreateOrderFromInvite = async (inviteID, type) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(
        CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON
      ),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING),
        timer: 1000,
      });
      const response = await dispatch(
        startCreateOrderFromInviteID({ inviteID, type })
      );
      if (response) {
        const { order, orderID } = response;
        if (type === "active") {
          setDataReport((prevState) => {
            const invites = { ...prevState.invites };
            const invite = invites[inviteID];
            delete invites[inviteID];
            return {
              ...prevState,
              invites,
              invitesInactive: {
                ...prevState.invitesInactive,
                [inviteID]: {
                  ...invite,
                  redemptionDate: new Date().getTime(),
                  orderID,
                },
              },
              orders: {
                ...prevState.orders,
                orders: {
                  [orderID]: order,
                  ...(prevState.orders.orders || {}),
                },
              },
            };
          });
        } else {
          setDataReport((prevState) => {
            return {
              ...prevState,
              invitesInactive: {
                ...prevState.invitesInactive,
                [inviteID]: {
                  ...prevState.invitesInactive[inviteID],
                  redemptionDate: new Date().getTime(),
                  orderID,
                },
              },
              orders: {
                ...prevState.orders,
                orders: {
                  [orderID]: order,
                  ...(prevState.orders.orders || {}),
                },
              },
            };
          });
        }
      }
    }
    return;
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t(SHOP_DETAIL_REPORT_TITLE)}
      </Typography>
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "flex-start", sm: "flex-end" },
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "flex-start",
          gap: { xs: 1, sm: 2 },
          width: "100%",
          mb: 3,
        }}
      >
        <DatesForm control={control} onActionButton={getDataReport} />
      </Box>
      {!isCalculated && isLoading && (
        <Loader
          size={70}
          fullWidth={true}
          hasMessage={true}
          message={t(LOADING_REPORT_INFORMATION)}
        />
      )}
      {isCalculated && !isLoading && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <ShopReportOrders
              data={dataReport.orders.orders}
              summary={dataReport.orders.ordersSummary}
              influencers={dataReport.influencers}
              shop={data}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ShopReportRequests
              data={dataReport.requests}
              requestsAccepted={dataReport.requestsAccepted}
              requestsRejected={dataReport.requestsRejected}
              influencers={dataReport.influencers}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ShopReportInvites
              signUpCountry={data.signUpCountry}
              data={dataReport.invites}
              influencers={dataReport.influencers}
              onDeleteInvite={onDeleteInvite}
              onExtendInvite={onExtendInvite}
              onCreateOrderFromInvite={onCreateOrderFromInvite}
              shop={data}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ShopReportInvitesInactive
              signUpCountry={data.signUpCountry}
              data={dataReport.invitesInactive}
              influencers={dataReport.influencers}
              ordersLength={dataReport.orders.ordersSummary.totalOrders}
              onCreateOrderFromInvite={onCreateOrderFromInvite}
              shop={data}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ mb: 2, textDecoration: "none" }}
              onClick={onDownloadReport}
            >
              {t(BUTTON_DOWNLOAD_REPORT)}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ShopReport;
