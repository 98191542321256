import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { useMenu } from "../hooks/useMenu";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const { state } = useLocation();

  const menuState = useMenu({
    initialData: [],
    stateLocation: state,
  });

  return (
    <MenuContext.Provider
      value={{
        ...menuState,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
