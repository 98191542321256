import React, { useState, useEffect } from "react";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import CreateStripeProductForm from "./CreateStripeProductForm";
import { useDispatchApp } from "../../../lib/redux";
import { startGetShopPaymentsFailed } from "../../../actions/owner";
import Box from "@mui/material/Box";
import Loader from "../Loader/Loader";
import Typography from "@mui/material/Typography";
import ButtonCopy from "../Buttons/ButtonCopy";
import { useTranslationApp } from "../../../lib/i18next";
import {
  MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL,
  MODAL_CREATE_PAYMENT_LINK_DESCRIPTION,
  MODAL_CREATE_PAYMENT_LINK_LINKS,
  MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY,
  MODAL_CREATE_PAYMENT_LINK_NAME,
  MODAL_CREATE_PAYMENT_LINK_TITLE,
  VALUE_WITH_CURRENCY,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";
import LinkText from "../Texts/LinkText";

const ModalStripeCreatePaymentLink = ({
  modalOpen,
  onCloseModal,
  metadata,
  shopID,
  data,
}) => {
  const [paymentsLinks, setPaymentsLinks] = useState([]);
  const [isFething, setIsFetching] = useState(true);
  const dispatch = useDispatchApp();

  const LOCALES = useLocales();
  const LOCALE = LOCALES[data.signUpCountry];
  const { t } = useTranslationApp();

  const getInitialData = async () => {
    const payments = await dispatch(startGetShopPaymentsFailed(shopID));
    if (payments) {
      const paymentsFormatted = {};
      Object.keys(payments).forEach((key) => {
        const payment = payments[key];
        if (!payment.paymentLink) return;
        paymentsFormatted[key] = payment;
      });
      setPaymentsLinks(paymentsFormatted);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL)}
      title={t(MODAL_CREATE_PAYMENT_LINK_TITLE)}
      description={t(MODAL_CREATE_PAYMENT_LINK_DESCRIPTION)}
    >
      {isFething ? (
        <Loader size={40} sx={{ mb: 2 }} />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
            }}
          >
            {t(MODAL_CREATE_PAYMENT_LINK_LINKS)}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              maxHeight: 200,
              overflowY: "auto",
            }}
          >
            {Object.keys(paymentsLinks).length === 0 && (
              <Typography variant="body1">
                {t(MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY)}
              </Typography>
            )}

            {Object.keys(paymentsLinks).map((key) => {
              const payment = paymentsLinks[key];
              return (
                <Box key={key}>
                  <Typography variant="body1">{`${t(
                    MODAL_CREATE_PAYMENT_LINK_NAME,
                    {
                      value: payment.subscription,
                    }
                  )} - ${t(VALUE_WITH_CURRENCY, {
                    value: payment.value,
                    currency: payment.currency || LOCALE.currency,
                  })}`}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 1,
                      justifyContent: "flex-start",
                      mt: -1,
                    }}
                  >
                    <LinkText href={payment.paymentLink} target="_blank">
                      {payment.paymentLink}
                    </LinkText>
                    <ButtonCopy value={payment.paymentLink} />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <CreateStripeProductForm
        metadata={metadata}
        shopID={shopID}
        onCloseModal={onCloseModal}
      />
    </ModalBasicLayout>
  );
};

export default ModalStripeCreatePaymentLink;
