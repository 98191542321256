import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ModalMediaPublication from "../Modal/ModalMediaPublication";
import { POST_STATUS_PENDING_APPROVAL } from "../../../utils/constants";
import { useSelectorApp } from "../../../lib/redux";

const GalleryArrowButtons = ({
  data,
  mode,
  showGalleryButtons,
  getStructureDate,
  verifyIsInValidURLContent,
  onChangeData,
  onCloseModal,
  onGetNextItems,
}) => {
  const [position, setPosition] = useState(0);
  const orders = useSelectorApp((state) => state?.orders?.orders);
  const applicants = useSelectorApp((state) => state?.campaigns?.applicants);

  useEffect(() => {
    if (position > data.length - 1) {
      setPosition(data.length - 1);
    }
    if (position === data.length - 3) {
      onGetNextItems();
    }
    // eslint-disable-next-line
  }, [position, data]);

  useEffect(() => {
    const element = window.document.querySelector(".modal-media-content");

    if (element) {
      element?.classList?.add("animate__animated", "animate__fadeIn");
      setTimeout(() => {
        element?.classList?.remove("animate__animated", "animate__fadeIn");
      }, 500);
    }
  }, [position]);

  const getFirstAndLastIndexFromOrderID = (orderID) => {
    let firstIndex = -1;
    let lastIndex = -1;

    data.forEach((element, index) => {
      if (element.key === orderID) {
        if (firstIndex === -1) {
          firstIndex = index;
        }
        lastIndex = index;
      }
    });

    return { firstIndex, lastIndex };
  };

  const onChangeHistory = ({ historyIndex, id, from = "order" }) => {
    let dataItem;
    if (!id) return;
    if (from === "order") {
      dataItem = orders[id];
      if (!dataItem.history) return;
    }
    if (from === "applicant") {
      dataItem = applicants[id];
      if (!dataItem.contentHistory) return;
    }

    const { firstIndex, lastIndex } = getFirstAndLastIndexFromOrderID(id);

    const newData = [...data];
    const newItems = [];

    newData.splice(firstIndex, lastIndex - firstIndex + 1);

    if (historyIndex === "current") {
      let urls;

      if (from === "applicant") {
        urls = dataItem.content;
      }
      if (from === "order") {
        urls =
          dataItem.postStatus === POST_STATUS_PENDING_APPROVAL
            ? dataItem.content
            : dataItem.posts;
      }
      if (!urls) {
        newItems.push(
          getStructureDate({
            key: id,
            url: "",
            from,
            additionalData: {
              isEmpty: true,
            },
          })
        );
      } else {
        urls.forEach((url) => {
          if (verifyIsInValidURLContent(url)) return;
          newItems.push(
            getStructureDate({
              url,
              key: id,
              from,
            })
          );
        });
      }
    } else {
      const history = data[firstIndex].history[historyIndex];
      const content =
        from === "order" ? history?.content : history?.contentUrls;

      content.forEach((url) => {
        if (verifyIsInValidURLContent(url)) return;

        newItems.push(
          getStructureDate({
            url,
            key: id,
            from,
          })
        );
      });
    }
    newItems.forEach((item, index) => {
      newData.splice(firstIndex + index, 0, item);
    });
    onChangeData(newData);
    setPosition(firstIndex);
  };

  const onFailedLoadUrl = () => {
    const newItems = [...data];
    newItems.splice(position, 1);
    onChangeData(newItems);
  };

  return (
    <Box>
      <ModalMediaPublication
        showArrowButtons={data.length > 1}
        showPrevButton={position > 0}
        showNextButton={position < data.length - 1}
        onPrev={() => setPosition(position - 1)}
        onNext={() => setPosition(position + 1)}
        modalOpen={true}
        onCloseModal={onCloseModal}
        data={data[position]}
        allData={data}
        onChangeHistory={onChangeHistory}
        position={position}
        onChangePosition={setPosition}
        showGalleryButtons={showGalleryButtons}
        mode={mode}
        url={data[position]?.url}
        metadata={{
          orderID: data[position]?.key,
        }}
        onClosePrevComponent={onCloseModal}
        onFailedLoadUrl={onFailedLoadUrl}
      />
    </Box>
  );
};

export default GalleryArrowButtons;
