import React from "react";
import TableCell from "../../Common/Table/TableCell";
import TableContainer from "../../Common/Table/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import format from "date-fns/format";
import Typography from "@mui/material/Typography";
import { formatRecurrent } from "../../../utils/formats";
import EventStatus from "./EventStatus";
import { getRecurrentOption } from "../../../utils/dates";

const EventsTable = ({
  data,
  onClickEvent,
  onChangeStatusEvent,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const keys = Object.keys(data);

  const keysSorted = keys.sort((a, b) => {
    const dataA = data[a]?.creationTime ?? new Date();
    const dataB = data[b]?.creationTime ?? new Date();
    return dataB - dataA;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={false}
        ariaLabel="Tabla de Campañas"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              Nombre del Evento
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Fecha
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Lugar
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Recurrencia
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => (
            <TableRow
              key={index}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  filter: "brightness(0.9)",
                },
              }}
            >
              <TableCell
                align="center"
                indexRow={index}
                onClick={() => onClickEvent(key)}
              >
                {data[key]?.name}
              </TableCell>
              <TableCell
                align="center"
                indexRow={index}
                onClick={() => onClickEvent(key)}
              >
                <Typography variant="body2">
                  {format(new Date(data[key].dateTimeStart), "p dd/MM/yyyy")}
                </Typography>
                <Typography variant="body2">-</Typography>
                <Typography variant="body2">
                  {format(new Date(data[key].dateTimeEnd), "p dd/MM/yyyy")}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                indexRow={index}
                onClick={() => onClickEvent(key)}
              >
                {data[key]?.location}
              </TableCell>
              <TableCell
                align="center"
                indexRow={index}
                onClick={() => onClickEvent(key)}
              >
                <Typography variant="body2">
                  {data[key].typeRecurrent
                    ? `${
                        getRecurrentOption(
                          new Date(data[key].dateTimeStart),
                          data[key].typeRecurrent
                        ).label
                      }`
                    : "-"}
                </Typography>
                <Typography variant="body2">
                  {data[key].typeRecurrent &&
                    `(${formatRecurrent(data[key].typeRecurrent)})`}
                </Typography>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <EventStatus
                  isActive={data[key].isActive}
                  onChangeStatusEvent={() =>
                    onChangeStatusEvent({
                      eventID: key,
                      isActive: data[key].isActive ? false : true,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default EventsTable;
