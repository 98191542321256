import { subCategories } from "./categories";
import {
  MAX_ENGAGEMENT,
  MAX_FOLLOWERS,
  PLAN_COLLABORATION,
  PLAN_ENTERPRISE,
  SPECIAL_STORES_DATA,
  STATUS_DELIVERIES,
  STATUS_DELIVERIES_APP,
  TYPE_PERSON_LEGAL,
  countryCodes,
  CREATOR_PAYMENT_PENDING,
  CREATOR_PAYMENT_PENDING_RELEASE,
  CREATOR_PAYMENT_RELEASED,
  CREATOR_DEBIT_PENDING_SEND,
  CREATOR_DEBIT_IN_TRANSIT,
  CREATOR_DEBIT_PAID,
  STATUS_ORDERS,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
} from "./constants";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
import { REGEX_EMAIL } from "./regexsValidation";
import {
  DOWNLOADS,
  ERROR_OCURRED,
  FEMALES,
  INTERACTIONS,
  LEADS,
  MALES,
  ONE,
  PLAN_PACKAGE_NAME,
  PLAN_PAY_PER_USE_NAME,
  SALES,
  THREE,
  TRANSACTION_IN_TRANSIT,
  TRANSACTION_STATUS_IN_PROCESS_RELEASE,
  TRANSACTION_STATUS_PAID,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_PENDING_RELEASE,
  TRANSACTION_STATUS_PENDING_SENT,
  TRANSACTION_STATUS_RELEASED,
  TWO,
} from "../locales/keysTranslations";
import i18next from "i18next";
import { errorCodeToText } from "./errors";
import { numberDecimal } from "./numberFormats";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DownloadIcon from "@mui/icons-material/Download";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

export const formatCitiesSave = ({ mode, cities, citiesShop }) => {
  if (!Array.isArray(cities) || !Array.isArray(citiesShop)) return [];
  return cities.map((city) => {
    const obj = citiesShop.find((cityClapp) => cityClapp["value"] === city);
    return obj ? obj[mode === "save" ? "value" : "value"] : city;
  });
};
export const getCityObject = (cities, city) => {
  const obj = cities.find((cityClapp) => cityClapp.value === city);
  return obj;
};

export const formatSubCategoriesSave = ({ mode, category, subcategories }) => {
  //Valida que los props sean validos
  if (!subCategories[category] || !Array.isArray(subcategories)) return [];

  //Itera sobre las subcategories, buscando su objeto correspondiente en subCategories[category]
  // y según el modo se retorna el valor o el objeto completo
  return subcategories.map((subcategory) => {
    const obj = subCategories[category].find((subCategoryClapp) =>
      mode === "save"
        ? subCategoryClapp.value === subcategory.value
        : subCategoryClapp.value === subcategory
    );
    if (obj) {
      return obj && mode === "save" ? obj.value : obj;
    } else {
      return mode === "save"
        ? subcategory.value
        : { label: capitalizeFirstLetter(subcategory), value: subcategory };
    }
  });
};

export const lowerCaseFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toLowerCase() + str.slice(1);
};
export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const capitalizeSections = (str = "", split = ", ") => {
  const sections = str.split(split);
  const capitalizedSections = sections.map(
    (section) => section.charAt(0).toUpperCase() + section.slice(1)
  );
  return capitalizedSections.join(split);
};

export const getCustomerBillingInfo = ({ billing, shop }) => {
  const getCompanyBilling = (billing) => {
    if (billing?.typePerson === TYPE_PERSON_LEGAL) {
      return billing.businessName;
    } else {
      return `${billing.firstName} ${billing.lastName}`;
    }
  };
  if (billing?.typeId?.length > 0) {
    return {
      company: getCompanyBilling(billing),
      email: billing.email,
      phone: billing.phone,
      address: `${billing.address}, ${billing.city}`,
    };
  } else {
    return {
      company: shop.businessName,
      email: shop.email,
      phone: shop.adminPhone,
    };
  }
};

export const getCityInfluencer = (influencer = {}) => {
  if (influencer?.signUpCity) return influencer.signUpCity;
  if (influencer?.mainAddress?.cityCountry)
    return (
      influencer?.mainAddress?.cityCountry.split(
        influencer?.mainAddress?.cityCountry.includes(",") ? "," : " "
      )[0] ?? "-"
    );
  return "-";
};

export const normalizeString = (str = "", deleteSpecialCharacters = true) => {
  if (deleteSpecialCharacters) {
    // Primero, eliminamos los caracteres especiales usando una expresión regular
    str = str.replace(/[^\u00E0-\u00FC\w\s]/gi, "");
  }

  // Después, pasamos todo a minúsculas
  str = str.toLowerCase();

  // Por último, reemplazamos las letras con tildes a las letras sin tildes
  const map = { á: "a", é: "e", í: "i", ó: "o", ú: "u", ü: "u", ñ: "n" };
  str = str.replace(/[áéíóúüñ]/gi, (matched) => map[matched]);

  // Devolvemos el string normalizado
  return str;
};

export const getStoreSpecialInfo = (storeType) => {
  const store = SPECIAL_STORES_DATA.find((store) => storeType === store.value);
  return store ?? {};
};

export const getDeliveryStatusInfo = (deliveryStatus) => {
  const delivery = STATUS_DELIVERIES.find(
    (deliveryType) => deliveryStatus === deliveryType.value
  );
  return delivery ?? {};
};
export const getDeliveryStatusInfoApp = (deliveryStatus) => {
  const delivery = STATUS_DELIVERIES_APP.find(
    (deliveryType) => deliveryStatus === deliveryType.value
  );
  return delivery ?? {};
};
export const getOrderStatusInfo = (orderStatus) => {
  const order = STATUS_ORDERS.find(
    (orderType) => orderStatus === orderType.value
  );
  return order ?? {};
};
export const getTransactionStatus = (transaction) => {
  if (transaction.type === "credit") {
    if (transaction.status === CREATOR_PAYMENT_PENDING) {
      return TRANSACTION_STATUS_PENDING;
    }
    if (transaction.status === CREATOR_PAYMENT_PENDING_RELEASE) {
      if (transaction.isTransferring) {
        return TRANSACTION_STATUS_IN_PROCESS_RELEASE;
      }
      return TRANSACTION_STATUS_PENDING_RELEASE;
    }
    if (transaction.status === CREATOR_PAYMENT_RELEASED) {
      return TRANSACTION_STATUS_RELEASED;
    }
  } else {
    if (transaction.status === CREATOR_DEBIT_PENDING_SEND) {
      return TRANSACTION_STATUS_PENDING_SENT;
    }
    if (transaction.status === CREATOR_DEBIT_IN_TRANSIT) {
      return TRANSACTION_IN_TRANSIT;
    }
    if (transaction.status === CREATOR_DEBIT_PAID) {
      return TRANSACTION_STATUS_PAID;
    }
  }
};

export const getCountryAndCode = (code) => {
  const country = countryCodes.find(
    (country) => code.toLowerCase() === country.code.toLowerCase()
  );
  return country;
};
export const getGender = (gender = "") => {
  return gender.toLowerCase() === "male"
    ? i18next.t(MALES)
    : gender.toLowerCase() === "female"
    ? i18next.t(FEMALES)
    : "";
};

export const deleteDuplicateElementsByArray = (array = []) => {
  const uniqueArray = array.filter((elem, index, self) => {
    return index === self.indexOf(elem);
  });
  return uniqueArray;
};
export const checkArrayIntersection = (array1 = [], array2 = []) => {
  for (let i = 0; i < array1.length; i++) {
    if (array2.includes(array1[i])) {
      return true;
    }
  }
  return false;
};

//Do a function to check if an array contains all elements of another array, and return true or false
export const checkArrayContainsAllElements = (array1 = [], array2 = []) => {
  for (let i = 0; i < array2.length; i++) {
    if (!array1.includes(array2[i])) {
      return false;
    }
  }
  return true;
};
export const formatRecurrent = (recurrent) => {
  const recurrents = {
    day: "Diaramente",
    week: "Semanalmente",
    month: "Mensualmente",
    year: "Anualmente",
  };
  return recurrents[recurrent];
};

export const getAdditionalCredits = (credits) => {
  if (!credits) return 0;
  if (credits >= 1 && credits <= 4) return 0;
  if (credits >= 5 && credits <= 9) return 1;
  if (credits >= 10 && credits <= 14) return 2;
  if (credits >= 15) return 3;
};

export const formatMessagesToChatBot = ({ messages = [], shopID }) => {
  return Object.keys(messages).map((messageID) => {
    const message = messages[messageID];
    return {
      role: message.userID === shopID ? "user" : "assistant",
      content: message.message,
    };
  });
};

export const hexToRGB = (hex, alpha) => {
  if (!hex) return null;
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

export const encryptPassword = (password) => {
  try {
    const bytes = AES.encrypt(
      password,
      process.env.REACT_APP_KEY_ENCRYPT
    ).toString();
    return bytes;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const decryptPassword = (hash) => {
  try {
    const bytes = AES.decrypt(hash, process.env.REACT_APP_KEY_ENCRYPT);
    const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedPassword;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getObjectError = (error) => {
  if (error?.response?.data) {
    const codeError = error?.response?.data?.code;
    const statusCode = error?.response?.data?.statusCode;
    const messageError = error?.response?.data?.message;
    const errorTranslated = errorCodeToText(codeError);

    if (codeError === ERROR_OCURRED) {
      return {
        ok: false,
        message: messageError,
        code: codeError,
        statusCode,
      };
    }

    return {
      ok: false,
      code: codeError,
      statusCode,
      message: errorTranslated === codeError ? messageError : errorTranslated,
    };
  }
  const errorTranslated = errorCodeToText(error.code);
  return {
    ok: false,
    message: errorTranslated,
    code: error.code,
    statusCode: error.statusCode,
  };
};

export const removeContactInfoFromString = (message) => {
  // This regex pattern should catch more phone numbers (including international ones) but still may not cover all cases.
  const phoneNumberRegex = /(\+\d{1,3})?([.\s]?\d){5,15}/g;

  const spelledNumberRegex =
    /\b(zero|one|two|three|four|five|six|seven|eight|nine|cero|uno|dos|tres|cuatro|cinco|seis|siete|ocho|nueve)\b/gi;

  // This regex pattern is for detecting email addresses.
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  // // Simple regex pattern for URLs (http, https, www)
  //eslint-disable-next-line
  // const urlRegex = /(\b(https?:\/\/|www\.)[-A-Z0-9+&@#\/%?=_|!:,.;]*[-A-Z0-9+&@#\/%=_|])/gi;

  // // Replace detected phone numbers, email addresses and URLs with '**'
  let sanitizedMessage = message.replace(emailRegex, "**");
  sanitizedMessage = sanitizedMessage.replace(phoneNumberRegex, " ***");
  sanitizedMessage = sanitizedMessage.replace(spelledNumberRegex, " ***");
  // sanitizedMessage = sanitizedMessage.replace(urlRegex, "**");

  return sanitizedMessage;
};

export const numbersInArray = (min, max) => {
  let array = [];
  for (let i = min; i <= max; i++) {
    array.push(i);
  }
  return array;
};

export const filterFollowers = (followers, rangeFollowers) => {
  if (rangeFollowers[1] === MAX_FOLLOWERS && followers < rangeFollowers[0]) {
    return false;
  }
  if (
    rangeFollowers[0] === 0 &&
    followers > rangeFollowers[1] &&
    rangeFollowers[1] < MAX_FOLLOWERS
  ) {
    return false;
  }
  if (
    (followers < rangeFollowers[0] || followers > rangeFollowers[1]) &&
    rangeFollowers[1] < MAX_FOLLOWERS &&
    rangeFollowers[0] > 0
  ) {
    return false;
  }
  return true;
};

export const filterEngagement = (engagement, rangeEngagement) => {
  if (
    rangeEngagement[1] === MAX_ENGAGEMENT &&
    engagement < rangeEngagement[0]
  ) {
    return false;
  }
  if (
    rangeEngagement[0] === 0 &&
    engagement > rangeEngagement[1] &&
    rangeEngagement[1] < MAX_ENGAGEMENT
  ) {
    return false;
  }
  if (
    (engagement < rangeEngagement[0] || engagement > rangeEngagement[1]) &&
    rangeEngagement[1] < MAX_ENGAGEMENT &&
    rangeEngagement[0] > 0
  ) {
    return false;
  }
  return true;
};

export const formatURL = (url) => {
  const [urlBase, params] = url.split("?");
  const lowerCaseUrl = urlBase.toLowerCase();

  if (!lowerCaseUrl.includes("http")) {
    return `https://${urlBase}${params ? `?${params}` : ""}`;
  }
  if (!lowerCaseUrl.includes("www") && !lowerCaseUrl.includes("http")) {
    return `https://www.${urlBase}${params ? `?${params}` : ""}`;
  }
  if (REGEX_EMAIL.test(urlBase)) {
    return `mailto:${urlBase}${params ? `?${params}` : ""}`;
  }

  return `${urlBase}${params ? `?${params}` : ""}`;
};

export const REVISION_NUMBERS = {
  1: ONE,
  2: TWO,
  3: THREE,
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const formatPhoneValueLibrary = ({
  phone,
  country,
  locale,
  mode = "load",
}) => {
  if (mode === "load") {
    return {
      value: phone,
      info: {
        countryCallingCode: locale?.phoneCode,
        countryCode: country.toUpperCase(),
        nationalNumber: "",
        numberType: null,
        numberValue: `+${locale?.phoneCode}${phone}`,
        reason: "country",
      },
    };
  }
  return phone.replace(/ /g, "");
};

export const calculateSuggestedEngagementByFollowers = ({
  followers = 0,
  engagementMin = 0.1,
  engagementMax = 10,
  followersMin = 5000,
  followersMax = 500000,
}) => {
  // Relación lineal básica entre followers y engagement
  let engagement = numberDecimal(
    engagementMax -
      ((parseInt(followers) - followersMin) / (followersMax - followersMin)) *
        (engagementMax - engagementMin)
  );

  if (followers > 30000 && followers <= 50000) {
    engagement /= 1.1;
  }
  if (followers > 50000 && followers <= 100000) {
    engagement /= 1.3;
  }
  if (followers > 100000 && followers <= 150000) {
    engagement /= 1.7;
  }
  if (followers > 150000 && followers <= 200000) {
    engagement /= 2;
  }
  if (followers > 200000 && followers <= 300000) {
    engagement /= 2.2;
  }
  if (followers > 300000) {
    engagement /= 2.4;
  }

  if (engagement < engagementMin) return engagementMin;
  if (engagement > engagementMax) return engagementMax;

  return engagement;
};

export const getPlanName = ({ planID, plans }) => {
  if (planID === PLAN_COLLABORATION) return i18next.t(PLAN_PAY_PER_USE_NAME);

  return i18next.t(PLAN_PACKAGE_NAME, {
    value: planID === PLAN_ENTERPRISE ? "Enterprise" : plans[planID]?.name,
  });
};

export const formatKeyDatabase = (str = "", replace = "_dot_") =>
  str.replace(/\./g, replace).toLowerCase();

export const getKeyMetricAdsCampaign = (data = {}) => {
  const objective = data.objective;
  const statistics = data.statistics || {};

  if (objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION) {
    return {
      value: statistics.interactions,
      label: INTERACTIONS,
      icon: ThumbUpIcon,
    };
  }
  if (objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION) {
    return {
      value: statistics.leads,
      label: LEADS,
      icon: AssignmentTurnedInIcon,
    };
  }
  if (objective === ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION) {
    return {
      value: statistics.downloads,
      label: DOWNLOADS,
      icon: DownloadIcon,
    };
  }
  if (objective === ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION) {
    return {
      value: statistics.sales,
      label: SALES,
      icon: MonetizationOnIcon,
    };
  }
};

export const getUuidFirebaseAuth = () => {
  const uuid = crypto.randomUUID();
  const uuidFormatted = uuid.replace(/-/g, "");

  return uuidFormatted.substring(0, uuidFormatted.length - 4);
};
