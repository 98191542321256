import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller } from "react-hook-form";
import { useDispatchApp } from "../../../lib/redux";
import { useTranslationApp } from "../../../lib/i18next";
import {
  EMPTY_RESULTS_SHOW,
  LOADING,
  TYPE_LEAST_CHARACTERS,
} from "../../../locales/keysTranslations";
import { useDebounced } from "../../../hooks/useDebounce";
import { DELAY_DEBOUNCE } from "../../../utils/constants";
import { startGetTopicsTagsPhyllo } from "../../../actions/influencers";
import Typography from "@mui/material/Typography";
import { capitalizeFirstLetter } from "../../../utils/formats";
import {
  influencersCategories,
  mapTopicPhylloToCategory,
} from "../../../utils/categories";

const DEFAULT_OPTIONS = influencersCategories.map((category) => ({
  name: category,
  value: category,
}));

const SelectTopicsPhyllo = ({
  control,
  errors = {},
  rules,
  name,
  label,
  placeholder,
  helperText,
  variant,
  fullWidth,
  sx = {},
  actionsChange = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onGetTopicsPhyllo = useDebounced(
    async (search) => {
      setLoading(true);
      const response = await dispatch(
        startGetTopicsTagsPhyllo({ keyword: search })
      );
      if (!response) {
        setLoading(false);
        return;
      }
      const optionsFilteredByDefault = response.filter((option) => {
        const optionMapped = mapTopicPhylloToCategory(option.value);
        return !influencersCategories.includes(optionMapped);
      });

      setOptions([...DEFAULT_OPTIONS, ...optionsFilteredByDefault]);
      setLoading(false);
    },
    [DELAY_DEBOUNCE]
  );

  const hasHelperText = helperText || errors[name]?.message;
  const limitTags = 2;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          sx={sx}
          multiple={true}
          options={options}
          fullWidth={fullWidth}
          open={open}
          limitTags={limitTags}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            return (
              <>
                {value.slice(0, limitTags).map((option, index) => {
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      label={capitalizeFirstLetter(option.name)}
                    />
                  );
                })}

                <Typography
                  variant="body1"
                  sx={{
                    mr: 1,
                  }}
                >
                  {numTags > limitTags && ` +${numTags - limitTags}`}
                </Typography>
              </>
            );
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={(_, value) => {
            if (value.length > 2) {
              setLoading(true);
              onGetTopicsPhyllo(value);
            }
          }}
          onChange={(_, value) => {
            if (value.length > 1) {
              const newValue = value.slice(1);
              field.onChange(newValue);
              actionsChange(newValue);
              return;
            }
            field.onChange(value);
            actionsChange(value);
          }}
          noOptionsText={t(
            options.length === 0 ? TYPE_LEAST_CHARACTERS : EMPTY_RESULTS_SHOW,
            {
              value: 3,
            }
          )}
          loadingText={t(LOADING)}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          getOptionLabel={(option) => capitalizeFirstLetter(option?.name)}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!errors[name]}
              helperText={
                hasHelperText ? (
                  <Box
                    component="span"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>
                      {!!errors[name] ? errors[name]?.message : helperText}
                    </span>
                  </Box>
                ) : null
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectTopicsPhyllo;
