import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Select from "../Selects/Select";
import {
  getDaysOptionsRRule,
  getRecurrentOptionsRRule,
} from "../../../utils/dates";
import Typography from "@mui/material/Typography";
import { RRule } from "rrule";
import { numbersInArray } from "../../../utils/formats";
import { REVIEW_FORM_RECURRENT_FIELDS } from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  FIELD_REQUIRED,
  RECURRENT_FIELDS_DEFAULT_TITLE,
  RECURRENT_FIELD_DAY_ALL_PLACEHOLDER,
  RECURRENT_FIELD_DAY_AN_PLACEHOLDER,
  RECURRENT_FIELD_FREQUENCY_PLACEHOLDER,
  RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION,
  RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION,
  RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import { COUNT_PATTERN_RRULE } from "../../../utils/constants";
import { useForm } from "react-hook-form";

const MAX_MONTHS = 2;
const MAX_WEEKS = MAX_MONTHS * 4;

const RecurrentFields = ({
  errors,
  setValue,
  text = RECURRENT_FIELDS_DEFAULT_TITLE,
  date = new Date(),
  recurrentPattern = "",
  name = "recurrentPattern",
}) => {
  const {
    control: controlForm,
    watch: watchForm,
    setValue: setValueForm,
  } = useForm({});
  const [, setRule] = useState(null);

  const now = new Date(date);

  const { t } = useTranslationApp();

  const watchFrequency = watchForm("frequency");
  const watchRepeatCount = watchForm("repeatCount") ?? "";
  const watchDay = watchForm("day") || "";

  useEffect(() => {
    let freq;
    let interval;
    // Extraer el valor de FREQ (frecuencia)
    const freqMatch = recurrentPattern.match(/FREQ=([^;]+)/);
    freq = freqMatch ? freqMatch[1] : "WEEKLY";

    // Extraer el valor de INTERVAL (intervalo)
    const intervalMatch = recurrentPattern.match(/INTERVAL=([^;]+)/);
    interval = intervalMatch ? parseInt(intervalMatch[1]) : 1;

    setValueForm("repeatCount", interval);
    setValueForm("frequency", RRule[freq]);
    setValueForm(
      "day",
      getDaysOptionsRRule().find(
        (option) => option?.valueNumeric === now?.getDay()
      )?.value
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      watchDay?.toString()?.length > 0 &&
      watchRepeatCount?.toString()?.length > 0 &&
      Number(watchRepeatCount) > 0 &&
      watchFrequency?.toString()?.length > 0
    ) {
      const objectRule = {};
      objectRule.freq = watchFrequency;
      objectRule.interval = watchRepeatCount;
      objectRule.count = COUNT_PATTERN_RRULE;
      objectRule.dtstart = now;
      // objectRule.tzid = "America/Bogota";

      if (watchFrequency === RRule.WEEKLY || watchFrequency === RRule.MONTHLY) {
        objectRule.byweekday = [watchDay];
      }

      if (watchFrequency === RRule.MONTHLY) {
        objectRule.freq = RRule.WEEKLY;
        objectRule.interval = objectRule.interval * 4;
      }

      const rule = new RRule(objectRule);

      setRule(rule);
      setValue(name, rule.toString());
    } else {
      setRule(null);
    }
    // eslint-disable-next-line
  }, [watchFrequency, watchRepeatCount, watchDay]);

  const finalMaxMonth =
    watchRepeatCount > MAX_MONTHS ? watchRepeatCount : MAX_MONTHS;
  const finalMaxWeek =
    watchRepeatCount > MAX_WEEKS ? watchRepeatCount : MAX_WEEKS;

  return (
    <Grid container spacing={1.5} data-tour={REVIEW_FORM_RECURRENT_FIELDS}>
      <Grid item xs={12}>
        <Typography variant="body1">{t(text)}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Select
          label={t(RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER)}
          name="repeatCount"
          variant="filled"
          fullWidth
          options={(watchFrequency === RRule.MONTHLY
            ? numbersInArray(1, finalMaxMonth)
            : numbersInArray(1, finalMaxWeek)
          ).map((option) => ({
            label: option,
            value: option,
          }))}
          control={controlForm}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            min: {
              value: 1,
              message: t(RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION, {
                value: 1,
              }),
            },
            validate: {
              value: (value) => {
                const isMonth = RRule.MONTHLY;
                const isWeek = RRule.WEEKLY;

                if (
                  (watchFrequency === isMonth && value > finalMaxMonth) ||
                  (watchFrequency === isWeek && value > finalMaxWeek)
                ) {
                  return t(RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION, {
                    months: finalMaxMonth,
                    weeks: finalMaxWeek,
                  });
                }
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Select
          name="frequency"
          label={t(RECURRENT_FIELD_FREQUENCY_PLACEHOLDER)}
          variant="filled"
          fullWidth
          options={getRecurrentOptionsRRule().map((option) => ({
            label:
              watchRepeatCount > 1 ? t(option.labelPlural) : t(option.label),
            value: option.value,
          }))}
          actionsChange={(value) => {
            setValueForm("frequency", value);
            setValueForm("repeatCount", 1);
          }}
          control={controlForm}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Select
          doTranslate={true}
          label={
            watchFrequency === RRule.MONTHLY
              ? t(RECURRENT_FIELD_DAY_AN_PLACEHOLDER)
              : t(RECURRENT_FIELD_DAY_ALL_PLACEHOLDER)
          }
          name="day"
          variant="filled"
          fullWidth
          options={getDaysOptionsRRule()}
          control={controlForm}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RecurrentFields;
