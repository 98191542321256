import React from "react";
import { logoPlatform } from "../../../utils/socialNetworks";
import LoadingButton from "./LoadingButton";
import Avatar from "@mui/material/Avatar";

const ButtonPlatform = ({
  platform,
  disabled,
  type = "",
  color = "primary",
  children,
  size = "medium",
  loading = false,
  onClick = () => {},
  sx = {},
}) => {
  const getStylesPlatform = () => {
    const styles = {
      logo: platform,
      background: "",
    };

    styles.logo = logoPlatform(styles.logo);

    return styles;
  };

  const styles = getStylesPlatform();

  return (
    <LoadingButton
      disabled={disabled}
      type={type}
      loading={loading}
      size={size}
      onClick={onClick}
      color={color}
      sx={{
        ...sx,
        background: styles.background,
      }}
    >
      <Avatar
        src={styles.logo}
        variant="square"
        sx={{
          width: "20px",
          height: "20px",
          mr: 1,
          ".MuiAvatar-img": {
            objectFit: "contain",
          },
        }}
      />
      {children}
    </LoadingButton>
  );
};

export default ButtonPlatform;
