import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Pie, PieChart, Cell, ResponsiveContainer, Legend } from "recharts";
import Typography from "@mui/material/Typography";
import Loader from "../../../Loader/Loader";
import StatisticsContainer from "../../../Statistics/StatisticContainer";
import { numberDecimal } from "../../../../../utils/numberFormats";
import { COLORS_STATISTICS } from "../../../../../utils/colors";
import {
  AUDIENCE_BY_PERCENTAGE_GENDERS,
  FEMALES,
  MALES,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";

const InfluencerAudienceGenderSplit = ({ data = [] }) => {
  const [statistics, setStatistics] = useState(null);

  const { t } = useTranslationApp();

  useEffect(() => {
    if (data.length > 0) {
      let countFemale = 0;
      let countMale = 0;

      [...data].map((audience, index) => {
        if (audience.gender === "FEMALE") {
          countFemale += audience.value;
        }
        if (audience.gender === "MALE") {
          countMale += audience.value;
        }
        return null;
      });
      setStatistics([
        {
          name: t(MALES),
          value: numberDecimal(countMale),
        },
        {
          name: t(FEMALES),
          value: numberDecimal(countFemale),
        },
      ]);
    }
    //eslint-disable-next-line
  }, []);

  if (data.length === 0) return null;
  if (!statistics) return <Loader size={40} />;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { xs: "100%", md: "calc(100vw - 320px)" },
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        {t(AUDIENCE_BY_PERCENTAGE_GENDERS)}
      </Typography>
      <StatisticsContainer sx={{ height: 280, pr: 2, pb: 2 }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="90%">
            <Pie
              data={statistics}
              cx="50%"
              cy="50%"
              label={(object) => `${object.value}%`}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS_STATISTICS[index % COLORS_STATISTICS.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </StatisticsContainer>
    </Box>
  );
};

export default InfluencerAudienceGenderSplit;
