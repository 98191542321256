import React from "react";
import Container from "@mui/material/Container";
import { useTranslationApp } from "../../../lib/i18next";
import Typography from "@mui/material/Typography";
import { AUTH_TIKTOK_VERIFICATION_SUCCESS } from "../../../locales/keysTranslations";
const AuthTikTokSuccess = () => {
  const { t } = useTranslationApp();
  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" align="center">
        {t(AUTH_TIKTOK_VERIFICATION_SUCCESS)}
      </Typography>
    </Container>
  );
};

export default AuthTikTokSuccess;
