import {
  ref,
  get,
  orderByChild,
  query,
  equalTo,
  update,
  remove,
  startAfter,
  limitToFirst,
  push,
} from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import {
  ALERT_ICON_TYPE_ERROR,
  POST_STATUS_PENDING,
  REQUEST_STATUS_ACCEPTED,
} from "../utils/constants";
import { generalDiscountRedemption, startCreateOrder } from "./orders";
import format from "date-fns/format";
import {
  startGetInfluencersByUserIDs,
  startGetUserIDInfluencerByPrefix,
  startGetUserIDShopperByPrefix,
  verifyInfluencerIsLimitedFunctionalities,
} from "./influencers";
import { SimpleAlert } from "../utils/alerts";
import axios from "../lib/axios";
import { renewToken } from "./auth";
import { startSaveLastInviteConfiguration, validatePlanShop } from "./shops";
import { generateRandomCode } from "../utils/generateRandomsValues";
import { getCategoryShop, getShopID, getShopIsActive } from "./getters";
import { getObjectError } from "../utils/formats";
import { startGetEventByID, startGetEventNameByEventIDs } from "./events";
import { verifyRecurrentDate } from "../utils/dates";
import { DUMMY_INVITE, DUMMY_USER } from "../onboarding/stepsSelectors";
import { startDeleteRequest, startGetRequestByShopIDuserID } from "./requests";
import { removeInfluencersSuggestedStorage } from "../services/influencers";
import i18next from "i18next";
import {
  CODE_NO_EXIST,
  CODE_NO_REDEEM_BEFORE_DATE,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  EVENT_DISABLED,
  EVENT_INVITATION_NO_BELONG,
  EVENT_NO_EXIST,
  INFLUENCER_NO_REDEEM_CODE,
  INVITE_CODE_IN_OTHER_INVITE,
  INVITE_MIN_VALUE_OF_MENU,
  SHOP_DEACTIVATE_ALERT_DESCRIPTION,
  WARNING,
} from "../locales/keysTranslations";

const FUNCTIONS_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startGetInvitesByShop = (shopID) => async (dispatch, getState) => {
  try {
    if (!shopID) {
      shopID = dispatch(getShopID());
    }
    const q = query(
      ref(db, "invites"),
      orderByChild("shopID"),
      equalTo(shopID)
    );
    const querySnapshot = await get(q);
    if (querySnapshot.exists()) {
      const invites = querySnapshot.val();
      dispatch(getInvitesByShop(invites));
      return true;
    } else {
      dispatch(loadingInvitesFinish());
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    dispatch(loadingInvitesFinish());
    return false;
  }
};
export const startGetEventInvitesByShop = () => async (dispatch, getState) => {
  try {
    const shopID = getState().auth.user.shopID;
    const q = query(
      ref(db, "eventInvites"),
      orderByChild("shopID"),
      equalTo(shopID)
    );
    const querySnapshot = await get(q);
    if (querySnapshot.exists()) {
      const invites = querySnapshot.val();
      dispatch(getEventInvitesByShop(invites));
      return true;
    } else {
      dispatch(loadingInvitesFinish());
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    dispatch(loadingInvitesFinish());
  }
};
export const startGetEventInvitesByShopPagination =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch, getState) => {
    try {
      const shopID = getState().auth.user.shopID;
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/eventInvites`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/eventInvites`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysInvites = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysInvites.push(snapshot.key);
        count++;
      });

      const queriesInvites = [];
      keysInvites.forEach((keyInvite) => {
        queriesInvites.push(get(ref(db, `eventInvites/${keyInvite}`)));
      });

      const invitesSnapshots = await Promise.all(queriesInvites);

      let invites = {};
      let userIDs = [];
      let eventIDs = [];
      invitesSnapshots.forEach((inviteSnapshot) => {
        invites = {
          ...invites,
          [inviteSnapshot.key]: inviteSnapshot.val(),
        };
        userIDs.push(inviteSnapshot.val().userID);
        eventIDs.push(inviteSnapshot.val().eventID);
      });
      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      await dispatch(startGetEventNameByEventIDs({ eventIDs }));
      dispatch(getEventInvitesByShopPagination(invites));
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
    }
  };
const getEventInvitesByShop = (invites) => ({
  type: types.GET_EVENT_INVITES_BY_SHOP_FINISH,
  payload: invites,
});
const getEventInvitesByShopPagination = (invites) => ({
  type: types.GET_EVENT_INVITES_BY_SHOP_PAGINATION,
  payload: invites,
});

export const startGetInvitesByShopPagination =
  ({ limit, onChangeLastKey, lastKey, onCallbackFinish = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/invites`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/invites`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysInvites = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysInvites.push(snapshot.key);
        count++;
      });

      const queriesInvites = [];
      keysInvites.forEach((keyInvite) => {
        queriesInvites.push(get(ref(db, `invites/${keyInvite}`)));
      });

      const invitesSnapshots = await Promise.all(queriesInvites);

      let invites = {};
      let userIDs = [];
      invitesSnapshots.forEach((inviteSnapshot) => {
        invites = {
          ...invites,
          [inviteSnapshot.key]: inviteSnapshot.val(),
        };
        userIDs.push(inviteSnapshot.val().userID);
      });

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      lastKey
        ? dispatch(getInvitesByShopPagination(invites))
        : dispatch(getInvitesByShop(invites));

      onCallbackFinish();
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
    }
  };
export const startGetInvitesByInvitesID =
  ({ invitesID }) =>
  async (dispatch, getState) => {
    try {
      const currentInvites = Object.keys(getState().invites.invites);
      invitesID = invitesID.filter((item, index) => {
        return invitesID.indexOf(item) === index;
      });

      const queriesInvites = [];

      invitesID.forEach((inviteKey) => {
        if (currentInvites.includes(inviteKey)) return;
        queriesInvites.push(get(ref(db, `invites/${inviteKey}`)));
      });

      const invitesSnapshot = await Promise.all(queriesInvites);

      let invites = {};
      invitesSnapshot.forEach((inviteSnapshot) => {
        invites[inviteSnapshot.key] = inviteSnapshot.val();
      });

      dispatch(getInvitesByShopPagination(invites));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
    }
  };
const getInvitesByShop = (invites) => ({
  type: types.GET_INVITES_BY_SHOP_FINISH,
  payload: invites,
});
const getInvitesByShopPagination = (invites) => ({
  type: types.GET_INVITES_BY_SHOP_PAGINATION,
  payload: invites,
});

export const startGetInvitesInactiveByShop =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = query(
        ref(db, "invitesInactive"),
        orderByChild("shopID"),
        equalTo(shopID)
      );
      const querySnapshot = await get(q);
      if (querySnapshot.exists()) {
        const invitesInactive = querySnapshot.val();
        dispatch(getInvitesInactiveByShop(invitesInactive));
        return true;
      } else {
        dispatch(loadingInvitesFinish());
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
    }
  };
export const startGetInvitesInactiveByInvitesID =
  ({ invitesID }) =>
  async (dispatch, getState) => {
    try {
      const currentInvitesInactive = Object.keys(
        getState().invites.invitesInactive
      );
      invitesID = invitesID.filter((item, index) => {
        return invitesID.indexOf(item) === index;
      });

      const queriesInvites = [];

      invitesID.forEach((inviteKey) => {
        if (currentInvitesInactive.includes(inviteKey)) return;
        queriesInvites.push(get(ref(db, `invitesInactive/${inviteKey}`)));
      });

      const invitesInactiveSnapshot = await Promise.all(queriesInvites);

      let invitesInactive = {};
      invitesInactiveSnapshot.forEach((inviteInactiveSnapshot) => {
        invitesInactive = {
          ...invitesInactive,
          [inviteInactiveSnapshot.key]: inviteInactiveSnapshot.val(),
        };
      });

      dispatch(getInvitesInactiveByShopPagination(invitesInactive));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
    }
  };
export const startGetInvitesInactiveByShopPagination =
  ({ limit, onChangeLastKey, lastKey, onCallbackFinish = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/invitesInactive`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/invitesInactive`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysInvitesInactive = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysInvitesInactive.push(snapshot.key);
        count++;
      });

      const queriesInvitesInactive = [];
      keysInvitesInactive.forEach((keyInvite) => {
        queriesInvitesInactive.push(
          get(ref(db, `invitesInactive/${keyInvite}`))
        );
      });

      const invitesInactiveSnapshots = await Promise.all(
        queriesInvitesInactive
      );

      let invitesInactive = {};
      let userIDs = [];
      invitesInactiveSnapshots.forEach((inviteInactiveSnapshot) => {
        invitesInactive = {
          ...invitesInactive,
          [inviteInactiveSnapshot.key]: inviteInactiveSnapshot.val(),
        };
        userIDs.push(inviteInactiveSnapshot.val().userID);
      });

      await dispatch(
        startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
      );
      lastKey
        ? dispatch(getInvitesInactiveByShopPagination(invitesInactive))
        : dispatch(getInvitesInactiveByShop(invitesInactive));
      onCallbackFinish();
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
    }
  };
const getInvitesInactiveByShop = (invites) => ({
  type: types.GET_INVITES_INACTIVE_BY_SHOP_FINISH,
  payload: invites,
});
const getInvitesInactiveByShopPagination = (invites) => ({
  type: types.GET_INVITES_INACTIVE_BY_SHOP_PAGINATION,
  payload: invites,
});

export const startInviteMultipleInfluencers =
  (data) => async (dispatch, getState) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop) return false;

      const shopID = dispatch(getShopID());
      const category = dispatch(getCategoryShop());
      const updates = {};

      const invites = {};

      const influencersKeys = [];

      for (const invite of data) {
        const inviteCode = generateRandomCode(8);
        const inviteKey = push(ref(db, `invites`)).key;
        const inviteData = {
          ...invite,
          shopID,
          category,
          inviteCode,
          orderFrom: new Date().toISOString(),
          isActive: true,
        };
        updates[`invites/${inviteKey}`] = inviteData;

        invites[inviteKey] = inviteData;

        influencersKeys.push(invite.userID);
      }

      await update(ref(db), updates);

      for (const influencerKey of influencersKeys) {
        const request = await dispatch(
          startGetRequestByShopIDuserID({ userID: influencerKey })
        );
        if (request) {
          const requestID = Object.keys(request)[0];
          await dispatch(
            startDeleteRequest({ requestID, status: REQUEST_STATUS_ACCEPTED })
          );
        }

        removeInfluencersSuggestedStorage(influencerKey);
      }

      dispatch(inviteMultipleInfluencers(invites));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return true;
    }
  };

const inviteMultipleInfluencers = (data) => ({
  type: types.INVITE_MULTIPLE_INFLUENCERS,
  payload: data,
});

export const startEditInvite =
  (inviteID, data) => async (dispatch, getState) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop) return false;

      const minProductValueShop = getState()?.shop?.minProductValue;
      const LOCALE = getState()?.locales?.locale;

      if (minProductValueShop && data.value < minProductValueShop) {
        SimpleAlert({
          title: i18next.t(WARNING),
          text: i18next.t(INVITE_MIN_VALUE_OF_MENU, {
            productValue: minProductValueShop,
            inviteValue: data.value,
            currency: LOCALE.currency,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }

      const contentReference =
        getState()?.shop?.profileInfluencer?.contentReference || "";

      let invite = getState().invites.invites[inviteID];
      if (!invite) invite = getState().invites.eventInvites[inviteID];

      let inviteCode;

      if (data.code === "") {
        inviteCode = generateRandomCode(8);
      } else if (invite.inviteCode !== data.code) {
        const isCodeExists = await dispatch(verifyInviteCodeExists(data.code));
        if (isCodeExists) return;
        inviteCode = data.code;
      } else {
        inviteCode = data.code;
      }
      const inviteData = {
        value: Number(data.value),
        valueToPay: data.valueToPay ? Number(data.valueToPay) : null,
        inviteCode,
        orderFrom: data.orderFrom,
        comment: data.comment,
        discountCode: data.discountCode,
        formats: Object.values(data.formats).includes(true)
          ? data.formats
          : null,
        typeContent: data.typeContent,
        eventID: data.eventID ?? null,
        personalizedURL: data.personalizedURL || null,
        referenceUrl: data?.referenceUrl || contentReference,
        ugcContentFormat: data.ugcContentFormat,
        wantsSocialMediaPost: data?.wantsSocialMediaPost,
        language: data?.language || LOCALE.language,
      };

      const dbRef = data.eventID
        ? ref(db, `eventInvites/${inviteID}`)
        : ref(db, `invites/${inviteID}`);
      await update(dbRef, inviteData);

      const inviteDataRedux = {
        key: inviteID,
        data: inviteData,
      };
      data.eventID
        ? dispatch(editEventInvite(inviteDataRedux))
        : dispatch(editInvite(inviteDataRedux));

      dispatch(
        startSaveLastInviteConfiguration({
          comment: inviteData?.comment,
          typeContent: inviteData?.typeContent,
          ugcContentFormat: inviteData?.ugcContentFormat,
          language: inviteData?.language,
          referenceUrl: inviteData?.referenceUrl,
          formats: inviteData?.formats,
          wantsSocialMediaPost: data?.wantsSocialMediaPost,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      const isActive = dispatch(getShopIsActive());
      SimpleAlert({
        title: i18next.t(ERROR),
        text: !isActive
          ? i18next.t(SHOP_DEACTIVATE_ALERT_DESCRIPTION)
          : i18next.t(ERROR_DESCRIPTION_GENERIC, {
              message: errorFormatted.message,
              code: errorFormatted.code,
            }),

        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }
  };
const editInvite = (data) => ({
  type: types.EDIT_INVITE,
  payload: data,
});
const editEventInvite = (data) => ({
  type: types.EDIT_EVENT_INVITE,
  payload: data,
});

export const startDeleteInvite = (inviteID) => async (dispatch, getState) => {
  try {
    const validationShop = dispatch(validatePlanShop());
    if (!validationShop) return false;
    const shopID = dispatch(getShopID());
    const isNormalInvite = getState().invites.invites[inviteID];

    if (isNormalInvite) {
      let dbRef = ref(db, `invites/${inviteID}`);
      await remove(dbRef);
      dbRef = ref(db, `shopsIDS/${shopID}/invites/${inviteID}`);
      await remove(dbRef);
      dispatch(deleteInvite(inviteID));
    } else {
      let dbRef = ref(db, `eventInvites/${inviteID}`);
      await remove(dbRef);
      dbRef = ref(db, `shopsIDS/${shopID}/eventInvites/${inviteID}`);
      await remove(dbRef);
      dispatch(deleteEventInvite(inviteID));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const deleteInvite = (data) => ({
  type: types.DELETE_INVITE,
  payload: data,
});
const deleteEventInvite = (data) => ({
  type: types.DELETE_EVENT_INVITE,
  payload: data,
});

export const verifyInviteCodeExists =
  (inviteCode) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = query(
        ref(db, "invites"),
        orderByChild("inviteCode"),
        equalTo(inviteCode)
      );
      const querySnapshot = await get(q);
      if (!querySnapshot.exists()) return false;
      const invites = querySnapshot.val();
      let inviteCodeExists = false;
      Object.keys(invites).forEach((key) => {
        if (
          invites[key].inviteCode === inviteCode &&
          invites[key].shopID === shopID
        ) {
          inviteCodeExists = true;
        }
      });
      if (inviteCodeExists)
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(INVITE_CODE_IN_OTHER_INVITE),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      return inviteCodeExists;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetInviteByCode =
  (code, storeID) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      const generalDiscountCode = getState().shop.generalDiscountCode;
      const generalDiscountValue = getState().shop.generalDiscountValue;
      const shopID = dispatch(getShopID());

      if (isOpenOnboarding) {
        const inviteData = {
          value: 100000,
          inviteCode: code,
          shopID: shopID,
          userID: DUMMY_USER,
          orderFrom: new Date().toISOString(),
        };
        dispatch(inviteInfluencer({ [DUMMY_INVITE]: inviteData }));
        dispatch(deleteInvite(DUMMY_INVITE));

        const orderData = {
          value: inviteData.value,
          shopID: shopID,
          userID: inviteData.userID,
          postStatus: POST_STATUS_PENDING,
          date: new Date().toISOString(),
          inviteID: DUMMY_INVITE,
          isDelivery: false,
          storeID,
        };
        dispatch(startCreateOrder(orderData));
        return {
          ok: true,
          invite: { value: 100000 },
        };
      }

      const codeUpperCase = code.toUpperCase();
      const generalDiscountCodeUpperCase = generalDiscountCode.toUpperCase();
      if (
        generalDiscountCode &&
        generalDiscountValue > 0 &&
        codeUpperCase?.includes(generalDiscountCodeUpperCase) &&
        code.length > generalDiscountCode.length
      ) {
        const prefix = codeUpperCase.split(generalDiscountCodeUpperCase)[1];
        let userID = await dispatch(startGetUserIDInfluencerByPrefix(prefix));

        if (!userID) {
          userID = await dispatch(startGetUserIDShopperByPrefix(prefix));

          if (!userID) {
            SimpleAlert({
              title: i18next.t(ERROR),
              text: i18next.t(CODE_NO_EXIST),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            return false;
          }
        }

        const redemptionData = {
          shopID,
          storeID,
          isDelivery: false,
          userID,
          value: generalDiscountValue,
          creationTime: new Date().getTime(),
          code: codeUpperCase,
          from: "inStore",
        };

        const dbRef = ref(db, `generalRedemptions`);

        const key = await push(dbRef, redemptionData).key;

        dispatch(
          generalDiscountRedemption({
            key,
            data: redemptionData,
          })
        );
        return {
          ok: true,
          value: generalDiscountValue,
          from: "generalDiscount",
        };
      }

      const qInvite = query(
        ref(db, "invites"),
        orderByChild("inviteCode"),
        equalTo(code)
      );
      const queryInviteSnapshot = await get(qInvite);
      let invite = null;
      let inviteData = null;
      let inviteKey = "";

      if (!queryInviteSnapshot.exists()) {
        const qEvent = query(
          ref(db, "eventInvites"),
          orderByChild("inviteCode"),
          equalTo(code)
        );
        const queryEventSnapshot = await get(qEvent);
        if (!queryEventSnapshot.exists()) {
          SimpleAlert({
            title: i18next.t(ERROR),
            text: i18next.t(CODE_NO_EXIST),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return { ok: false };
        }
        invite = queryEventSnapshot.val();
      }

      if (invite === null) {
        invite = queryInviteSnapshot.val();
      }

      Object.keys(invite).forEach((key) => {
        if (invite[key].shopID === shopID) {
          inviteData = invite[key];
          inviteKey = key;
        }
      });

      if (inviteData === null) {
        const qEvent = query(
          ref(db, "eventInvites"),
          orderByChild("inviteCode"),
          equalTo(code)
        );
        const queryEventSnapshot = await get(qEvent);
        if (!queryEventSnapshot.exists()) {
          SimpleAlert({
            title: i18next.t(ERROR),
            text: i18next.t(CODE_NO_EXIST),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return {
            ok: false,
          };
        }
        invite = queryEventSnapshot.val();
        Object.keys(invite).forEach((key) => {
          if (invite[key].shopID === shopID) {
            inviteData = invite[key];
            inviteKey = key;
          }
        });
      }

      if (inviteData === null) {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(CODE_NO_EXIST),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }

      dispatch(getInvitesByShopPagination({ [inviteKey]: inviteData }));

      const userID = inviteData.userID;

      const isInfluencerLimited = await dispatch(
        verifyInfluencerIsLimitedFunctionalities(userID)
      );

      if (isInfluencerLimited) {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(INFLUENCER_NO_REDEEM_CODE),
          icon: ALERT_ICON_TYPE_ERROR,
        });

        return false;
      }

      if (inviteData.eventID) {
        const event = await dispatch(startGetEventByID(inviteData.eventID));
        if (!event) {
          SimpleAlert({
            title: i18next.t(ERROR),
            text: i18next.t(EVENT_NO_EXIST),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return {
            ok: false,
          };
        }
        if (!event.isActive) {
          SimpleAlert({
            title: i18next.t(ERROR),
            text: i18next.t(EVENT_DISABLED, {
              event: event.name,
            }),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return { ok: false };
        }
        if (inviteData.eventID !== storeID) {
          SimpleAlert({
            title: i18next.t(ERROR),
            text: i18next.t(EVENT_INVITATION_NO_BELONG, {
              event: event.name,
            }),
            icon: ALERT_ICON_TYPE_ERROR,
          });
          return { ok: false };
        }
        const response = verifyRecurrentDate({
          dateStart: event.dateTimeStart,
          dateEnd: event.dateTimeEnd,
          dateNow: new Date(),
          typeRecurrent: event.isRecurrent ? event.typeRecurrent : "once",
        });
        if (!response.ok) return response;
      } else {
        if (inviteData.orderFrom) {
          const orderFromDate = format(
            new Date(inviteData.orderFrom),
            "MM/dd/yyyy"
          );
          const todayDate = format(new Date(), "MM/dd/yyyy");
          const orderFromTime = new Date(orderFromDate).getTime();
          const todayDateTime = new Date(todayDate).getTime();
          if (todayDateTime < orderFromTime) {
            SimpleAlert({
              title: i18next.t(ERROR),
              text: i18next.t(CODE_NO_REDEEM_BEFORE_DATE, {
                date: new Date(orderFromDate),
              }),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            return false;
          }
        }
      }

      const activeDate = new Date().toISOString();

      // in onCreateOrder trigger set inviteInactive and delete of redux
      inviteData.eventID
        ? dispatch(deleteEventInvite(inviteKey))
        : dispatch(deleteInvite(inviteKey));

      if (inviteData.campaignID) {
        dispatch(
          startInactiveInvite({
            inviteID: inviteKey,
            isRedeem: true,
          })
        );
      } else {
        const orderData = {
          value: inviteData.value,
          shopID: inviteData.shopID,
          userID: inviteData.userID,
          postStatus: POST_STATUS_PENDING,
          date: activeDate,
          inviteID: inviteKey,
          isDelivery: false,
          storeID,
        };
        dispatch(startCreateOrder(orderData));
      }

      return {
        ok: true,
        value: inviteData.value,
        from: "invite",
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const inviteInfluencer = (data) => ({
  type: types.INVITE_INFLUENCER,
  payload: data,
});
export const inviteEventInfluencer = (data) => ({
  type: types.INVITE_EVENT_INFLUENCER,
  payload: data,
});

export const startInactiveInvite =
  ({ inviteID, isRedeem = false }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const snapshot = await get(ref(db, `invites/${inviteID}`));
      if (!snapshot.exists()) return true;

      const invite = snapshot.val();

      const updates = {};

      updates[`shopsIDS/${shopID}/invitesInactive/${inviteID}/creationTime`] =
        -invite.creationTime;
      updates[`shopsIDS/${shopID}/invites/${inviteID}`] = null;
      updates[`invitesInactive/${inviteID}`] = {
        ...invite,
        isActive: null,
        redemptionDate: isRedeem ? new Date().getTime() : null,
      };
      updates[`invites/${inviteID}`] = null;
      await update(ref(db), updates);
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startCreateSendAutomaticInvitesTask =
  () => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/createSendAutomaticsInvitesTask`,
        data: {
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.ok;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const loadingInvitesFinish = () => ({
  type: types.LOADING_INVITES_FINISH,
});

export const startGetInviteByUserID = (userID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const q = query(
      ref(db, "invites"),
      orderByChild("shopIDuserID"),
      equalTo(`${shopID}_${userID}`)
    );
    const querySnapshot = await get(q);
    if (querySnapshot.exists()) {
      const invites = querySnapshot.val();
      dispatch(getInvitesByShop(invites));
      return true;
    } else {
      dispatch(loadingInvitesFinish());
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    dispatch(loadingInvitesFinish());
  }
};
export const startGetInviteInactiveByID =
  (inviteID) => async (dispatch, getState) => {
    try {
      let invite = getState().invites.invites[inviteID];

      if (!invite) {
        const refDB = ref(db, `invitesInactive/${inviteID}`);

        const snapshot = await get(refDB);

        invite = snapshot.val();
      }

      return invite;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingInvitesFinish());
      return false;
    }
  };
