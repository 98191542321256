import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import intervalToDuration from "date-fns/intervalToDuration";
import { convertMinutes } from "../../../../utils/dates";
import { ORDER_IN_PREPARATION_TIME } from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import { MINUTE_LETTER_COUNT_DOWN } from "../../../../locales/keysTranslations";
import { GENERAL_REDEMPTION } from "../../../../utils/constants";
import { useTheme } from "@mui/material";

const CountDown = ({ data, onLaunchSound }) => {
  const theme = useTheme();

  const { t } = useTranslationApp();
  const interval = intervalToDuration({
    start: new Date(data.dateOrderAccepted),
    end: new Date(),
  }).minutes;

  const startingMinutes =
    data.timePreparation - interval >= 0 ? data.timePreparation - interval : 0;
  //eslint-disable-next-line
  const startingMinutesMemo = useMemo(() => startingMinutes, []);
  const [mins, setMinutes] = useState(startingMinutes);
  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (mins === 0) {
        clearInterval(sampleInterval);
      } else {
        setMinutes(mins - 1);
        mins - 1 === 0 && onLaunchSound();
      }
    }, 60000);
    return () => {
      clearInterval(sampleInterval);
    };
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      data-tour={ORDER_IN_PREPARATION_TIME}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color:
            data?.from === GENERAL_REDEMPTION
              ? `${theme.palette.secondary.contrastText}`
              : `${theme.palette.primary.contrastText}`,
        }}
        size={55}
        value={(mins / startingMinutesMemo) * 100}
      />
      <Box sx={{ position: "absolute" }}>
        {!mins ? (
          <>
            <Typography variant="body1" color="inherit">
              0
            </Typography>
            <Typography variant="body2" color="inherit" sx={{ mt: "-4px" }}>
              {t(MINUTE_LETTER_COUNT_DOWN)}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" color="inherit">
              {convertMinutes(mins).value}
            </Typography>
            <Typography variant="body2" color="inherit" sx={{ mt: "-4px" }}>
              {t(convertMinutes(mins).label)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CountDown;
