import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import PropTypes from "prop-types";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  MAX_SIZE_FILES,
} from "../../../utils/constants";
import { saveUrl, startUploadFileAws } from "../../../actions/shops";
import LoadingButton from "./LoadingButton";
import {
  BUTTON_UPLOAD_FILE,
  ERROR,
  FIELD_FILE_REQUIRED,
  FIELD_FILE_SIZE_VALID,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const UploadFile = ({
  sx,
  hasIcon = true,
  icon = AttachmentIcon,
  color = "secondary",
  variant = "contained",
  textButton = BUTTON_UPLOAD_FILE,
  pathStorage = "",
  pathRTDB = "",
  automaticSetRTDB = false,
  targetName = "",
  acceptFormats = "application/pdf",
  onReadyFile,
  withAuth = true,
  maxSizeFile = MAX_SIZE_FILES,
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onChangeFile = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > maxSizeFile) {
          SimpleAlert({
            title: t(ERROR),
            text: t(FIELD_FILE_REQUIRED, {
              value: 50,
              type: "MB",
            }),
            type: ALERT_ICON_TYPE_ERROR,
          });
        } else {
          setLoading(true);

          const url = await dispatch(
            startUploadFileAws(file, file.type, pathStorage, withAuth)
          );
          automaticSetRTDB && dispatch(saveUrl(url, pathRTDB));
          onReadyFile({
            target: { name: targetName, value: url, file },
          });

          setLoading(false);
        }
      } else {
        setLoading(false);
        SimpleAlert({
          title: t(ERROR),
          text: t(FIELD_FILE_SIZE_VALID),
          type: ALERT_ICON_TYPE_ERROR,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <LoadingButton
      sx={{ borderRadius: 3, ...sx }}
      endIcon={hasIcon && <AttachmentIcon />}
      loading={loading}
      component="label"
      color={color}
      variant={variant}
      disableElevation
    >
      {t(textButton)}
      <input
        hidden
        accept={acceptFormats}
        name="file"
        type="file"
        onChange={onChangeFile}
      />
    </LoadingButton>
  );
};

UploadFile.propTypes = {
  hasIcon: PropTypes.bool,
  textButton: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  acceptFormats: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  targetName: PropTypes.string,
  pathStorage: PropTypes.string.isRequired,
  pathRTDB: PropTypes.string,
  onReadyFile: PropTypes.func.isRequired,
  automaticSetRTDB: PropTypes.bool.isRequired,
};

export default UploadFile;
