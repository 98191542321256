import { DUMMY_ORDER } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  toBeAccepted: {},
  inPreparation: {},
  toBeDelivered: {},
  allStoresDeliveries: {},
  isLoading: true,
};

export const deliveriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_DELIVERIES_FINISH:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case types.GET_ALL_STORES_DELIVERIES_FINISH:
      return {
        ...state,
        allStoresDeliveries: action.payload,
        isLoading: false,
      };
    case types.GET_ALL_SHOPS_DELIVERIES_FINISH:
      return {
        ...state,
        allStoresDeliveries: {
          ...state.allStoresDeliveries,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.SET_HAS_OPEN_DELIVERY:
      return {
        ...state,
        toBeAccepted: {
          ...state.toBeAccepted,
          [action.payload.key]: {
            ...state.toBeAccepted[action.payload.key],
            isNew: action.payload.data,
          },
        },
      };
    case types.ACCEPT_DELIVERY:
      delete state[action.payload.typeProcessOld][action.payload.deliveryID];
      return {
        ...state,
        [action.payload.typeProcessTarget]: {
          ...action.payload.data,
          ...state[action.payload.typeProcessTarget],
        },
      };
    case types.REJECT_DELIVERY:
      delete state[action.payload.typeProcess][action.payload.key];
      return {
        ...state,
      };
    case types.READY_DELIVERY:
      delete state[action.payload.typeProcessOld][action.payload.deliveryID];
      return {
        ...state,
        [action.payload.typeProcessTarget]: {
          ...action.payload.data,
          ...state[action.payload.typeProcessTarget],
        },
      };
    case types.DELIVERED_DELIVERY:
      delete state[`toBeDelivered`][action.payload];
      return {
        ...state,
      };
    case types.DELETE_DELIVERY_OWNER:
      delete state.allStoresDeliveries[action.payload];
      return {
        ...state,
      };
    case types.LOADING_DELIVERIES_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_ORDER]: deliveryToBeAcceptedDeleted, ...toBeAccepted } =
        state.toBeAccepted;
      const { [DUMMY_ORDER]: deliveryInPreparationDeleted, ...inPreparation } =
        state.inPreparation;
      const { [DUMMY_ORDER]: deliveryToBeDeliveredDeleted, ...toBeDelivered } =
        state.toBeDelivered;

      return {
        ...state,
        toBeAccepted: toBeAccepted,
        inPreparation: inPreparation,
        toBeDelivered: toBeDelivered,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return {
        ...initialState,
        allStoresDeliveries: state.allStoresDeliveries,
      };
    case types.GLOBAL_RESET_DATA_STORE:
      return {
        ...initialState,
        allStoresDeliveries: state.allStoresDeliveries,
      };
    default:
      return state;
  }
};
