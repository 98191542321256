import React, { useContext, useState } from "react";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Button from "../Buttons/Button";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import ChatMainContainer from "./ChatMainContainer";
import {
  ADS_CAMPAIGN_STATUS_DRAFT,
  DESIGNER_ROLE,
  OPERATOR_ROLE,
} from "../../../utils/constants";
import {
  getIsBlockedActions,
  getRole,
  getShopID,
} from "../../../actions/getters";
import { useEffect } from "react";
import { closeChat, openChat } from "../../../actions/chat";
import { ChatContext } from "../../../Contexts/ChatContext";
import { getChatUnread } from "../../../services/chats";
import { BOX_SHADOW } from "../../../utils/colors";
import { BUTTON_CREATOR_CHAT } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

const OpenChatsButton = () => {
  const [hasChatUnread, setHasChatUnread] = useState(false);

  const isOpen = useSelectorApp((state) => state.chat.isOpen);
  const showOpenChatButton = useSelectorApp(
    (state) => state.ui.showOpenChatButton
  );
  const isPlanActive = useSelectorApp((state) => state?.auth?.isActivePlan);
  const adsCampaigns = useSelectorApp(
    (state) => state.adsCampaigns?.adsCampaigns
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatchApp();
  const userRole = dispatch(getRole());
  const shopID = dispatch(getShopID());
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");
  const adsCampaign = adsCampaigns?.[campaignID] || {};

  const { chats } = useContext(ChatContext);
  const { t } = useTranslationApp();

  useEffect(() => {
    const newChatsUnread = [];
    let hasOneChatUnread = false;

    Object.keys(chats || {}).forEach((chatID) => {
      const chat = chats[chatID];

      const isChatUnRead = getChatUnread({
        chat,
        shopID,
        role: userRole,
      });

      if (isChatUnRead) {
        newChatsUnread.push(chatID);
        hasOneChatUnread = true;
      }
    });

    setHasChatUnread(hasOneChatUnread);

    // eslint-disable-next-line
  }, [chats]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const onNavigateBlocked = () => navigate("/creators");

  const onOpenChat = (from) => {
    if (from === "click") {
      const isBlocked = dispatch(
        getIsBlockedActions({
          verifyBlockedLastInvite: true,
          verifyBlockedFreeTrial: true,
        })
      );
      if (isBlocked) return onNavigateBlocked();
    }
    if (!isPlanActive) return onNavigateBlocked();
    dispatch(openChat());
  };
  const onCloseChat = () => dispatch(closeChat());

  let SHOW_BUTTON = true;
  if (!userRole) {
    SHOW_BUTTON = false;
  }
  if (userRole === OPERATOR_ROLE || userRole === DESIGNER_ROLE) {
    SHOW_BUTTON = false;
  }

  if (!showOpenChatButton) return null;

  const isHidden =
    (location.pathname === "/ads-campaign" &&
      adsCampaign?.status === ADS_CAMPAIGN_STATUS_DRAFT) ||
    (isMobile && location.pathname === "/creators");

  return (
    <>
      {SHOW_BUTTON && !isOpen ? (
        <Tooltip title={"Chat"}>
          <Box
            className={
              hasChatUnread
                ? "animate__animated animate__flash animate__infinite animate__slow"
                : ""
            }
            sx={{
              display: isHidden ? "none" : "flex",
              position: "fixed",
              bottom: 25,
              right: 20,
              zIndex: 800,
            }}
          >
            <Button
              color="secondary"
              onClick={() => {
                onOpenChat("click");
              }}
              sx={{
                height: 30,
                maxHeight: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                fontSize: "0.975rem",
                px: { xs: 1.5, sm: 3 },
                py: { xs: 3, sm: 3 },
                boxShadow: BOX_SHADOW,
                backgroundColor: "background.paper",
                color: "secondary.main",
                border: `3px solid ${theme.palette.secondary.main}`,
                "&:hover": {
                  backgroundColor: "background.paper",
                  color: "secondary.main",
                  filter: theme.palette.hover.main,
                },
                ".MuiSvgIcon-root": {
                  fontSize: isMobile ? "1.8rem" : "1.5rem",
                },
              }}
            >
              <ChatBubbleIcon
                sx={{
                  mt: "4px",
                }}
              />
              {!isMobile && t(BUTTON_CREATOR_CHAT)}
            </Button>
          </Box>
        </Tooltip>
      ) : null}

      {isOpen && (
        <ChatMainContainer showChat={isOpen} onChangeShowChat={onCloseChat} />
      )}
    </>
  );
};

export default OpenChatsButton;
