import React from "react";
import { useDispatchApp } from "../../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import ActionsUsersTable from "./ActionsUsersTable";
import Typography from "@mui/material/Typography";
import TableContainer from "../../../Common/Table/TableContainer";
import TableCell from "../../../Common/Table/TableCell";
import {
  OPERATOR_ROLE,
  ROLES_MAP_TRANSLATION,
} from "../../../../utils/constants";
import { getUserID } from "../../../../actions/getters";
import {
  ADMIN_USER_ROLE_CELL,
  ADMIN_USER_ROW,
  OPERATOR_USER_ROLE_CELL,
  OPERATOR_USER_ROW,
} from "../../../../onboarding/stepsSelectors";
import {
  COLUMN_NAME_ACTION,
  USERS_TABLE_ARIA_LABEL,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_NAME,
  COLUMN_NAME_STORE,
  COLUMN_NAME_ROLE,
  EMPTY_USERS,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const UsersTable = ({
  data,
  loading,
  storesList,
  onSelectUser,
  onDeleteUser,
}) => {
  const dispatch = useDispatchApp();
  const uid = dispatch(getUserID());
  const keys = Object.keys(data);

  const { t } = useTranslationApp();

  const getDataTour = (index) => {
    if (index === 0)
      return {
        row: ADMIN_USER_ROW,
        cell: ADMIN_USER_ROLE_CELL,
      };

    if (index === 1) {
      return {
        row: OPERATOR_USER_ROW,
        cell: OPERATOR_USER_ROLE_CELL,
      };
    }
    return {};
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        keys={keys}
        loading={loading}
        emptyText={EMPTY_USERS}
        ariaLabel={t(USERS_TABLE_ARIA_LABEL)}
        hasPagination={false}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STORE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ROLE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((row, index) => {
            const user = data[row];
            if (user.isHidden) return null;
            return (
              <TableRow key={index} data-tour={getDataTour(index).row}>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body1" >
                    {user.name}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body1" >
                    {user.email}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {user.role === OPERATOR_ROLE &&
                    (storesList[user.storeID]?.name ?? "-")}
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  dataTour={getDataTour(index).cell}
                >
                  {t(ROLES_MAP_TRANSLATION[user.role] || "")}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <ActionsUsersTable
                    onSelectUser={onSelectUser}
                    onDeleteUser={onDeleteUser}
                    data={user}
                    uid={row}
                    isDisabled={uid === row}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default UsersTable;
