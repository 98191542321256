import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { FieldCategory } from "./Categories/FieldCategory";
import { reorder } from "../../../utils/draggable";
import { OPERATOR_ROLE } from "../../../utils/constants";
import { MenuContext } from "../../../Contexts/MenuContext";
import { useTheme } from "@mui/material/styles";
import {
  ADD_CATEGORY_MENU,
  NEW_CATEGORY,
} from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_DELETE,
  BUTTON_DUPLICATE,
  BUTTON_EDIT,
  MENU_ADD_CATEGORY,
} from "../../../locales/keysTranslations";

const CategoriesSection = React.memo(({ userRole }) => {
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [categorySelect, setCategorySelect] = useState(null);
  const [modeField, setModeField] = useState(null);

  const { t } = useTranslationApp();

  const {
    // GENERAL
    menu,

    // CATEGORIES
    categorySelected,
    onSelectCategory,
    onChangeOrderCategories,
    onCreateCategory,
    onDeleteCategory,
    onEditCategory,
    onDuplicateCategory,
  } = useContext(MenuContext);

  const theme = useTheme();

  const open = Boolean(anchorEl);

  const onClick = (event, category) => {
    setAnchorEl(event.currentTarget);
    setCategorySelect(category);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(menu, source.index, destination.index);
    onChangeOrderCategories(newItems);
  };

  return (
    <List
      sx={{
        width: "100%",
        minWidth: { md: 180, lg: 230, xl: 280 },
        bgcolor: "background.paper",
        color: "background.paperContrastText",
        overflow: "auto",
      }}
      component="div"
      aria-labelledby="categories-list"
    >
      <ListItemButton
        onClick={() => {
          setModeField("create");
          setIsCreatingCategory(true);
        }}
        disabled={userRole === OPERATOR_ROLE}
        data-tour={ADD_CATEGORY_MENU}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText
          primary={t(MENU_ADD_CATEGORY)}
          sx={{
            color: "text.primary",
            "& .MuiListItemText-primary": {
              fontWeight: 600,
            },
          }}
        />
      </ListItemButton>
      {isCreatingCategory && modeField === "create" && (
        <FieldCategory
          mode={modeField}
          onCreateCategory={(category) => {
            setIsCreatingCategory(false);
            onCreateCategory(category);
          }}
          onCancel={() => setIsCreatingCategory(false)}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {Object.keys(menu).map((category, index) => (
                <Draggable
                  draggableId={index.toString()}
                  index={index}
                  key={index}
                >
                  {(provided) => {
                    if (
                      isCreatingCategory &&
                      categorySelect === category &&
                      modeField === "edit"
                    ) {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={index}
                        >
                          <FieldCategory
                            mode={modeField}
                            onCreateCategory={(category) => {
                              setIsCreatingCategory(false);
                              onCreateCategory(category);
                            }}
                            onEditCategory={(category) => {
                              setIsCreatingCategory(false);
                              onEditCategory(category, categorySelect);
                            }}
                            onCancel={() => setIsCreatingCategory(false)}
                          />
                        </div>
                      );
                    }
                    return (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ pl: { xs: 1, sm: 2 } }}
                        key={index}
                        data-tour={index === 0 && NEW_CATEGORY}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="more-options"
                            onClick={(e) => onClick(e, category)}
                            disabled={userRole === OPERATOR_ROLE}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton
                          onClick={() => onSelectCategory(category)}
                          sx={{
                            borderLeft:
                              categorySelected === category &&
                              `4px solid ${theme.palette.primary.main}`,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              ml: categorySelected === category && "-4px",
                              minWidth: 32,
                            }}
                          >
                            <KeyboardArrowRightIcon />
                          </ListItemIcon>
                          <ListItemText primary={menu[category].name} />
                        </ListItemButton>
                      </ListItem>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "more-options-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onDuplicateCategory(categorySelect);
            onClose();
          }}
        >
          {t(BUTTON_DUPLICATE)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModeField("edit");
            setIsCreatingCategory(true);
            onClose();
          }}
        >
          {t(BUTTON_EDIT)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteCategory(categorySelect);
            onClose();
          }}
        >
          {t(BUTTON_DELETE)}
        </MenuItem>
      </Menu>
    </List>
  );
});

export default CategoriesSection;
