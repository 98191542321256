import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearLoader from "../../../Loader/LinearLoader";
import { getCountryAndCode } from "../../../../../utils/formats";
import { AUDIENCE_BY_COUNTRY } from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";

const InfluencerAudienceCountry = ({ data = [] }) => {
  const { t } = useTranslationApp();
  if (data.length === 0) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        {t(AUDIENCE_BY_COUNTRY)}
      </Typography>
      {data.map((country, index) => {
        if (index < 10) {
          const countryObject = getCountryAndCode(country.code);
          return (
            <LinearLoader
              label={countryObject.name}
              value={country.value}
              key={countryObject.code}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};

export default InfluencerAudienceCountry;
