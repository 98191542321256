import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { startGetBusinessNameByShopIDs } from "../../../actions/shops";
import { startChangeShopSelected } from "../../../actions/auth";
import Loader from "../Loader/Loader";
import { setShowBackdrop } from "../../../actions/ui";
import { getRole, getShopID, getShopIDs } from "../../../actions/getters";
import { useTheme, Typography } from "@mui/material";
import {
  ADD_BRAND,
  SELECT_BRAND_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUSINESS_ID_PROJECT,
  SUPER_ADMIN_ROLE,
} from "../../../utils/constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModalAddBrandSuperAdmin from "../Modal/ModalAddBrandSuperAdmin/ModalAddBrandSuperAdmin";

const SelectShops = ({ useColorTheme = false, sx }) => {
  const [shopSelected, setShopSelected] = useState("");
  const [modalOpenAddBrandSuperAdmin, setModalOpenAddBrandSuperAdmin] =
    useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatchApp();

  const role = dispatch(getRole());
  const shopID = dispatch(getShopID());
  const shopIDs = dispatch(getShopIDs());
  const { shops } = useSelectorApp((state) => state.shop);
  const theme = useTheme();
  const { t } = useTranslationApp();

  const getShopsBusinessName = async () => {
    if (shopIDs?.length > 0) {
      setLoading(true);
      await dispatch(startGetBusinessNameByShopIDs({ shopIDs }));
    }
    setLoading(false);
  };

  useEffect(() => {
    getShopsBusinessName();
    //eslint-disable-next-line
  }, [shopIDs]);

  useEffect(() => {
    if (shopID) {
      setShopSelected(shopID);
    }
    //eslint-disable-next-line
  }, [shopID]);

  const onChange = async (e) => {
    dispatch(setShowBackdrop(true));
    const response = await dispatch(startChangeShopSelected(e.target.value));
    response && setShopSelected(e.target.value);
    dispatch(setShowBackdrop(false));
  };

  const onAddBrand = () => {
    setModalOpenAddBrandSuperAdmin(true);
  };

  if (loading)
    return <Loader colorCustom={theme.palette.background.paperContrastText} />;

  return (
    <Box sx={{ mx: 2, ...sx }}>
      <FormControl fullWidth variant="filled">
        <InputLabel
          id="select-shop"
          sx={{
            color:
              useColorTheme &&
              `${theme.palette.background.paperContrastText} !important`,
          }}
        >
          {t(SELECT_BRAND_PLACEHOLDER)}
        </InputLabel>
        <Select
          labelId="select-shop"
          id="select-shop"
          value={shopSelected}
          label={t(SELECT_BRAND_PLACEHOLDER)}
          onChange={onChange}
          sx={{
            color:
              useColorTheme &&
              `${theme.palette.background.paperContrastText} !important`,
            "& .MuiSelect-icon": {
              color:
                useColorTheme &&
                `${theme.palette.background.paperContrastText} !important`,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor:
                useColorTheme &&
                `${theme.palette.background.paperContrastText} !important`,
            },
          }}
        >
          {role === SUPER_ADMIN_ROLE && BUSINESS_ID_PROJECT && (
            <MenuItem
              sx={{ display: "flex", justifyContent: "flex-start", gap: 1 }}
              onClick={onAddBrand}
            >
              <AddCircleIcon sx={{ ml: "-4px" }} />
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {t(ADD_BRAND)}
              </Typography>
            </MenuItem>
          )}
          {Object.keys(shops).map((shopKey) => {
            return (
              <MenuItem
                key={shopKey}
                value={shopKey}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {`${shops[shopKey]?.businessName ?? shopKey}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {modalOpenAddBrandSuperAdmin && (
        <ModalAddBrandSuperAdmin
          modalOpen={modalOpenAddBrandSuperAdmin}
          onCallbackFinish={(newShopID) =>
            onChange({ target: { value: newShopID } })
          }
          onCloseModal={() => setModalOpenAddBrandSuperAdmin(false)}
        />
      )}
    </Box>
  );
};

export default SelectShops;
