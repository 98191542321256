import React, { useContext, useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CurrencyField from "../../../Common/NumberFormats/CurrencyField";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
// import Typography from "@mui/material/Typography";
// import AccompanimentsType from "./AccompanimentsType";
import Button from "../../../Common/Buttons/Button";
import { MenuContext } from "../../../../Contexts/MenuContext";
import {
  ADD_PRODUCT_FORM,
  ADD_PRODUCT_FORM_CREATE_BUTTON,
  ADD_PRODUCT_FORM_DESCRIPTION,
  ADD_PRODUCT_FORM_IMAGE,
  ADD_PRODUCT_FORM_IS_SENSITIVE,
  ADD_PRODUCT_FORM_NAME,
  ADD_PRODUCT_FORM_PRICE,
} from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { changeShowNextButton } from "../../../../actions/onboarding";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CREATE,
  BUTTON_REPLACE_IMAGE,
  FIELD_ALL_REQUIRED,
  MENU_CREATE_PRODUCT_TITLE,
  MENU_PRODUCT_DESCRIPTION_PLACEHOLDER,
  MENU_PRODUCT_MIN_VALUE,
  MENU_PRODUCT_NAME_PLACEHOLDER,
  MENU_PRODUCT_PRICE_PLACEHOLDER,
  MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION,
  MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import UploadImageButtons from "../../../Common/Buttons/UploadImageButtons";
import { MIN_ALL_NUMBER_VALUE } from "../../../../utils/constants";

const INITIAL_STATE = {
  name: "",
  description: "",
  price: "",
  photoURL: "",
  isProductSensitive: false,
  options: [],
  isActive: true,
};

const ProductSteper = ({ data, pathRTDB }) => {
  const { onFinishCreateProduct, onCreateProduct } = useContext(MenuContext);
  const [activeStep] = useState(0);
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);

  const { user } = useSelectorApp((state) => state.auth);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const { isOpen } = useTour();

  useEffect(() => {
    setFormValues(data ?? { ...INITIAL_STATE, options: [] });
  }, [data]);

  const onChangeImage = (e) => {
    if (isOpen) {
      dispatch(changeShowNextButton(true));
    }
    setFormValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onChange = (event) => {
    if (event.target.name === "isProductSensitive")
      return setFormValues({
        ...formValues,
        [event.target.name]: event.target.checked,
      });

    if (isOpen && event.target.value !== "") {
      dispatch(changeShowNextButton(true));
    } else if (isOpen && event.target.value === "") {
      dispatch(changeShowNextButton(false));
    }
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (e) => {
    if (
      !formValues.name ||
      !formValues.description ||
      !formValues.photoURL ||
      !formValues.price
    )
      return setError(t(FIELD_ALL_REQUIRED));

    if (parseInt(formValues.price) < MIN_ALL_NUMBER_VALUE)
      return setError(
        t(MENU_PRODUCT_MIN_VALUE, {
          value: MIN_ALL_NUMBER_VALUE,
        })
      );
    if (activeStep === 0) {
      const response = await onCreateProduct(formValues);
      return response && onFinishCreateProduct();
    }

    setError(null);
    if (activeStep === 1) {
      onFinishCreateProduct();
    }
  };
  return (
    <Box
      sx={{
        mt: 2,
        ml: {
          xs: 0,
          lg: 2,
        },
        width: "100%",
        overflow: "auto",
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0} data-tour={ADD_PRODUCT_FORM}>
          <StepLabel>{t(MENU_CREATE_PRODUCT_TITLE)}</StepLabel>
          <StepContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mb: 2,
                flexDirection: "column",
              }}
            >
              {error && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <Alert
                    severity="error"
                    sx={{
                      width: "100%",
                      maxWidth: 350,
                      justifyContent: "center",
                    }}
                  >
                    {error}
                  </Alert>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "center",
                }}
                data-tour={ADD_PRODUCT_FORM_IMAGE}
              >
                <UploadImageButtons
                  urlImage={formValues.photoURL}
                  targetName="photoURL"
                  pathStorage={`shops/${user.shopID}/images/`}
                  pathRTDB={`${pathRTDB}/photoURL`}
                  onReadyUrlImage={onChangeImage}
                  onRemoveUrlImage={onChangeImage}
                  textButtonRemove={BUTTON_REPLACE_IMAGE}
                  automaticSetRTDB={false}
                  deleteFile={false}
                  deleteRTDB={false}
                  showImage={true}
                  mode="replace"
                  centeredSecondButton={true}
                />
              </Box>
              <TextField
                id="name"
                name="name"
                label={t(MENU_PRODUCT_NAME_PLACEHOLDER)}
                variant="filled"
                fullWidth
                margin="normal"
                value={formValues.name}
                onChange={onChange}
                data-tour={ADD_PRODUCT_FORM_NAME}
              />
              <TextField
                id="description"
                name="description"
                label={t(MENU_PRODUCT_DESCRIPTION_PLACEHOLDER)}
                multiline
                rows={3}
                variant="filled"
                fullWidth
                margin="normal"
                value={formValues.description}
                onChange={onChange}
                data-tour={ADD_PRODUCT_FORM_DESCRIPTION}
              />
              <TextField
                id="price"
                name="price"
                label={t(MENU_PRODUCT_PRICE_PLACEHOLDER)}
                variant="filled"
                fullWidth
                margin="normal"
                value={formValues.price}
                onChange={onChange}
                data-tour={ADD_PRODUCT_FORM_PRICE}
                InputProps={{
                  inputComponent: CurrencyField,
                }}
              />
              <FormControl
                component="fieldset"
                variant="standard"
                data-tour={ADD_PRODUCT_FORM_IS_SENSITIVE}
              >
                <FormLabel component="legend" sx={{ fontWeight: 600 }}>
                  {t(MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER)}
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isProductSensitive}
                        name="isProductSensitive"
                        onChange={onChange}
                      />
                    }
                    label={t(MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION)}
                  />
                </FormGroup>
              </FormControl>
              <Box>
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  sx={{ mt: 1, mr: 1 }}
                  dataTour={ADD_PRODUCT_FORM_CREATE_BUTTON}
                >
                  {t(BUTTON_CREATE)}
                </Button>
              </Box>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export default ProductSteper;
