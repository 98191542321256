import types from "../types";
import {
  ref,
  get,
  onValue,
  query,
  orderByChild,
  startAfter,
  limitToFirst,
  equalTo,
} from "firebase/database";
import { db } from "../firebase";
import axios from "../lib/axios";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  PLAN_COLLABORATION,
  CLAPP_CREDITS,
  PAYMENT_MODEL_PACKAGE,
  PRE_REGISTERED_INFLUENCER_PAYMENT_PROCESS,
} from "../utils/constants";
import { SimpleAlert } from "../utils/alerts";
import { renewToken } from "./auth";
import {
  errorCodeToText,
  verifyIsErrorPermissionDenied,
} from "../utils/errors";
import { getAdditionalCredits, getObjectError } from "../utils/formats";
import {
  getInfluencerRedux,
  getShopID,
  getShopPaymentModel,
  getUser,
  getUserID,
} from "./getters";
import { startGetShopsByShopIDs } from "./shops";
import i18next from "i18next";
import {
  BILLING_AND_PAYMENT_PLAN_CANCELLED,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  INVOICE_CREATED_FOR_RETRY_PAYMENT,
  PAYMENT_SUCCESSFUL,
} from "../locales/keysTranslations";
import { openLink } from "../utils/urls";
import { trackingPurchase } from "../services/tracking";
import { startGeneratePreRegisteredInfluencerExecution } from "./influencers";

const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startPlanTrialSubscription =
  (plan) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const user = dispatch(getUser());
      const { data } = await axios({
        method: "post",
        url: `${url}/planTrialSubscription`,
        data: {
          shopID: user.shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        SimpleAlert({
          title: data.message,
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        dispatch(setPlan(plan));
        dispatch(verifyIsActivePlan(true));
        return true;
      } else {
        SimpleAlert({
          title: data.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startPaySubscriptionStripe =
  ({ planID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/createPaySubscriptionIntentStripe`,
        data: {
          planID,
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startReactiveSubscriptionStripe =
  () => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/reactiveSubscriptionStripe`,
        data: {
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setSubscriptionEnd(""));
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startChangeSubscriptionPlanStripe =
  ({ newPriceID, planID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/changeSubscriptionPlanStripe`,
        data: {
          shopID,
          newPriceID,
          planID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
      if (data.ok) {
        dispatch(setPlan(planID));
      }
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startCancelSubscriptionStripe =
  () => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/cancelSubscriptionStripe`,
        data: {
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setSubscriptionEnd(data.data.current_period_end * 1000));
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetStripeSources = () => async (dispatch, getState) => {
  try {
    const token = await dispatch(renewToken());
    const shopID = dispatch(getShopID());
    const { data } = await axios({
      method: "post",
      url: `${url}/getStripeSources`,
      data: {
        shopID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startCreateStripeSource =
  ({ paymentMethodID, setDefault = false, activatePlan = true }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const userID = dispatch(getUserID());
      const { data } = await axios({
        method: "post",
        url: `${url}/createStripeSource`,
        data: {
          paymentMethodID,
          shopID,
          userID,
          setDefault,
          activatePlan,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        setDefault && dispatch(startSetStripeSourceDefault(data.data));
        setDefault && dispatch(setStripeCustomerID(data.data.customerID));
      }
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorCodeToText(error.response.data.code),
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return { ok: false };
    }
  };

export const startDeleteStripeSource =
  ({ paymentMethodID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const userID = dispatch(getUserID());
      const { data } = await axios({
        method: "post",
        url: `${url}/deleteStripeSource`,
        data: {
          paymentMethodID,
          shopID,
          userID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startMakeStripeSourceDefault =
  ({ paymentMethodID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/makeStripeSourceDefault`,
        data: {
          paymentMethodID,
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startSetStripeSourceDefault = (cardData) => async (dispatch) => {
  try {
    dispatch(setStripeSourceDefault(cardData));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const setStripeSourceDefault = (data) => ({
  type: types.SET_DEFAULT_PAYMENT_METHOD,
  payload: data,
});

const setStripeCustomerID = (data) => ({
  type: types.SET_CUSTOMER_ID,
  payload: data,
});

export const setStripeSubscriptionID = (data) => ({
  type: types.SET_SUBSCRIPTION_ID,
  payload: data,
});

export const startSetSubscriptionEnd =
  (subscriptionEnd) => async (dispatch) => {
    try {
      dispatch(setSubscriptionEnd(subscriptionEnd));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const setSubscriptionEnd = (data) => ({
  type: types.SET_SUBSCRIPTION_END,
  payload: data,
});

export const startGetStripeInfo = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `shops/${shopID}/stripe`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(getStripeInfo(data));
      return data;
    }
    dispatch(loadingFinishPayments());
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const getStripeInfo = (data) => ({
  type: types.GET_STRIPE_INFO_FINISH,
  payload: data,
});

export const startActivePlanCollaboration =
  () => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/activePlanCollaboration`,
        data: {
          shopID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(setPlan(PLAN_COLLABORATION));
      }
      return data.ok;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startPayApplicantStripe =
  ({ applicantID, paymentMethodID, campaignID, paymentIntent = "" }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/createPayApplicantStripe`,
        data: {
          applicantID,
          shopID,
          paymentMethodID,
          campaignID,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data;
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return {
        ok: false,
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startCreateInvoicePayApplicant =
  ({ applicantID, campaignID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/createInvoicePayApplicant`,
        data: {
          applicantID,
          shopID,
          campaignID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data?.data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startPayInvoice =
  ({ invoiceID, paymentMethodID, paymentIntent }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const invoice = getState().payments.payments[invoiceID];
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/payInvoice`,
        data: {
          invoiceID,
          paymentMethodID,
          shopID,
          startPayInvoice,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          payInvoice({
            invoiceID,
            paymentID: data.data.paymentID,
            invoiceIDStripe: data.data.invoiceID,
            paidDate: data.data.paidDate,
          })
        );
        if (invoice.subscription === CLAPP_CREDITS) {
          dispatch(
            payClappCredits({
              credits: invoice.items[0].quantity,
            })
          );
        }
        return { ok: true };
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return { ok: false };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const payInvoice = (data) => ({
  type: types.PAY_INVOICE,
  payload: data,
});

export const startPayClappCredits =
  ({ credits, paymentMethodID, paymentIntent }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/payClappCredits`,
        data: {
          credits,
          paymentMethodID,
          shopID,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          payClappCredits({
            shopID,
            credits: credits + getAdditionalCredits(credits),
            payment: data.data.payment,
          })
        );
        return { ok: true };
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return {
        ok: false,
        data: {
          paymentIntent: data.data.paymentIntent,
        },
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (error?.response?.data) {
        dispatch(
          payClappCredits({
            credits: 0,
            payment: error?.response?.data?.data?.payment,
          })
        );
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: `${errorCodeToText(
              error?.response?.data?.code
            )} ${i18next.t(INVOICE_CREATED_FOR_RETRY_PAYMENT)}`,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return {
          ok: false,
          data: {
            paymentIntent: error?.response?.data?.data?.paymentIntent,
          },
        };
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return {
        ok: false,
      };
    }
  };
export const payClappCredits = (data) => ({
  type: types.PAY_CLAPP_CREDITS,
  payload: data,
});
export const startPayPackage =
  ({ packageID, paymentMethodID, paymentIntent = "", isProrated }) =>
  async (dispatch, getState) => {
    try {
      const plans = getState().payments.plans;
      const plan = plans?.[packageID];

      const shopID = dispatch(getShopID());
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/payPackagePlan`,
        data: {
          packageID,
          paymentMethodID,
          shopID,
          paymentIntent,
          isProrated,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          payPackagePlan({
            payment: data.data.payment,
            plan: packageID,
            costCollaboration: plan?.benefits?.costCollaboration,
          })
        );
        trackingPurchase(plan.price);
        return { ok: true };
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return {
        ok: false,
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (error?.response?.data) {
        dispatch(
          payPackagePlan({
            payment: errorFormatted.data?.payment,
            plan: packageID,
          })
        );
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return {
          ok: false,
          data: {
            paymentIntent: errorFormatted.data?.paymentIntent,
          },
        };
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return {
        ok: false,
      };
    }
  };
export const startCancelPackagePlan = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const token = await dispatch(renewToken());
    await axios({
      method: "post",
      url: `${url}/cancelPackagePlan`,
      data: {
        shopID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    SimpleAlert({
      title: i18next.t(BILLING_AND_PAYMENT_PLAN_CANCELLED),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};
const payPackagePlan = (data) => ({
  type: types.PAY_PACKAGE_PLAN,
  payload: data,
});

export const startVerifyPayment =
  (shopID, paymentID, onFinishVerify) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/verifyPayment`,
        data: {
          shopID,
          paymentID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onFinishVerify(response.data);
      dispatch(setPlan(response.data.plan));
      dispatch(verifyIsActivePlan(response.data.isActive));
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(verifyIsActivePlan(false));
      onFinishVerify(error.response.data);
    }
  };

export const setPlan = (plan) => ({
  type: types.SET_PLAN,
  payload: plan,
});

export const startGetPlan = (shopID) => async (dispatch) => {
  try {
    const snapshot = await get(ref(db, `shops/${shopID}/subscriptionPlan`));
    if (snapshot.exists()) {
      const subscriptionPlan = snapshot.val();
      dispatch(getPlan(subscriptionPlan));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};
const getPlan = (plan) => ({
  type: types.GET_PLAN_FINISH,
  payload: plan,
});

export const startGetSubscriptionDate = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const snapshot = await get(ref(db, `shops/${shopID}/subscriptionDate`));
    if (snapshot.exists()) {
      const subscriptionDate = snapshot.val();
      dispatch(getSubscriptionDate(subscriptionDate));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};
const getSubscriptionDate = (data) => ({
  type: types.GET_SUBSCRIPTION_DATE_FINISH,
  payload: data,
});

export const startGetPlanInfo = (planID) => async () => {
  try {
    const snapshot = await get(ref(db, `plans/${planID}`));
    if (snapshot.exists()) {
      const planInfo = snapshot.val();
      return planInfo;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetPayments =
  ({ onCallbackFinish = () => {} }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const snapshot = await get(ref(db, `payments/${shopID}/`));
      if (snapshot.exists()) {
        const payments = snapshot.val();
        dispatch(getPayments(payments));
        onCallbackFinish();
        return true;
      } else {
        dispatch(loadingFinishPayments());
        onCallbackFinish();
        return false;
      }
    } catch (error) {
      console.log(error);
      onCallbackFinish();
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
  };
const getPayments = (payments) => ({
  type: types.GET_PAYMENTS_FINISH,
  payload: payments,
});
export const startVerifyPaymentsFailed = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const q = query(
      ref(db, `payments/${shopID}`),
      orderByChild("isPaid"),
      equalTo(false),
      limitToFirst(1)
    );
    const snapshot = await get(q);

    return snapshot.exists();
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
      return false;
    }
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};

export const startVerifyIsActivePlan =
  (onReferenceAvailable) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `shops/${shopID}/isPlanActive`);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(verifyIsActivePlan(data));
      });
      onReferenceAvailable(dbRef);
    } catch (error) {
      console.log(error);
      dispatch(verifyIsActivePlan(false));
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const verifyIsActivePlan = (isActive) => ({
  type: types.VERIFY_IS_ACTIVE_PLAN,
  payload: isActive,
});

export const startGetPlans = () => async (dispatch) => {
  try {
    const paymentModel = dispatch(getShopPaymentModel());
    if (paymentModel === PAYMENT_MODEL_PACKAGE) {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `shops/${shopID}/plans`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const plansInfo = snapshot.val();
        dispatch(getPlans(plansInfo));
        return plansInfo;
      }
      return {};
    } else {
      const dbRef = ref(db, `plans`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const plansInfo = snapshot.val();
        dispatch(getPlans(plansInfo));
        return plansInfo;
      }
      return {};
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getPlans = (data) => ({
  type: types.GET_PLANS_FINISH,
  payload: data,
});

export const startGetAdsPlans = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `adsPlans`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetPaymentModel = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `shops/${shopID}/paymentModel`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const paymentModel = snapshot.val();
      dispatch(getPaymentModel(paymentModel));
      return paymentModel;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getPaymentModel = (data) => ({
  type: types.GET_PAYMENT_MODEL_FINISH,
  payload: data,
});

export const startGetSubscriptionPlanInfo = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `shops/${shopID}/subscriptionPlanInfo`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const subscriptionPlanInfo = snapshot.val();
      dispatch(getSubscriptionPlanInfo(subscriptionPlanInfo));
      return subscriptionPlanInfo;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getSubscriptionPlanInfo = (data) => ({
  type: types.GET_SUBSCRIPTION_PLAN_INFO_FINISH,
  payload: data,
});

export const startPayAmbassadorStripe =
  ({ usersIDs, amount, paymentMethodID, paymentIntent = "" }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        url: `${url}/createPayAmbassadorStripe`,
        data: {
          usersIDs,
          shopID,
          amount,
          paymentMethodID,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        const { payment } = data.data;
        dispatch(payAmbassador({ payment }));
        SimpleAlert({
          title: i18next.t(PAYMENT_SUCCESSFUL),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        return { ok: true };
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return { ok: false };
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const payAmbassador = (data) => ({
  type: types.PAY_AMBASSADOR,
  payload: data,
});
export const startPayInfluencerStripe =
  ({ userID, amount, paymentMethodID, paymentIntent = "" }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/createPayInfluencerStripe`,
        data: {
          userID,
          amount,
          paymentMethodID,
          paymentIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        const influencer = dispatch(getInfluencerRedux(userID));
        const isExternal = influencer.isExternal;

        if (isExternal) {
          await dispatch(
            startGeneratePreRegisteredInfluencerExecution({
              userID,
              mainAccountName: influencer.mainAccountName,
              mainPlatform: influencer.mainPlatform,
              imageURL: influencer.imageURL,
              withActions: false,
              type: PRE_REGISTERED_INFLUENCER_PAYMENT_PROCESS,
            })
          );
        }
        const { payment } = data.data;
        dispatch(payInfluencer({ payment }));
        SimpleAlert({
          title: i18next.t(PAYMENT_SUCCESSFUL),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        return { ok: true };
      }
      if (!data.ok && data.status === "requiresAction") {
        return {
          ok: false,
          clientSecret: data.data.clientSecret,
          status: data.status,
        };
      }
      return { ok: false };
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const payInfluencer = (data) => ({
  type: types.PAY_INFLUENCER,
  payload: data,
});

export const startGetPaymentsFailed =
  ({ limit, onChangeLastKey, lastKey, onCallBack = () => {} }) =>
  async (dispatch) => {
    try {
      let q;
      if (limit) {
        q = lastKey
          ? query(
              ref(db, `paymentsFailed`),
              orderByChild("creationTimeNegative"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `paymentsFailed`),
              orderByChild("creationTimeNegative"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          ref(db, `paymentsFailed`),
          orderByChild("creationTimeNegative")
        );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) return onCallBack();

      const shopIDs = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();
        if (count === snapshotSize && limit)
          onChangeLastKey(data.creationTimeNegative);
        shopIDs.push(data.shopID);
        count++;
      });

      await dispatch(startGetShopsByShopIDs({ shopIDs }));

      lastKey
        ? dispatch(nextPaymentsFailed(snapshot.val()))
        : dispatch(getPaymentsFailed(snapshot.val()));

      onCallBack();
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetInvoiceUrl =
  ({ paymentID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/getInvoiceUrl`,
        data: {
          paymentID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        openLink(data.data.url);
      }
      return data.ok;
    } catch (error) {
      const errorFormatted = getObjectError(error);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return {
        ok: false,
      };
    }
  };

const getPaymentsFailed = (data) => ({
  type: types.GET_PAYMENTS_FAILED,
  payload: data,
});

const nextPaymentsFailed = (data) => ({
  type: types.GET_NEXT_PAYMENTS_FAILED,
  payload: data,
});

const loadingFinishPayments = () => ({
  type: types.LOADING_PAYMENTS_FINISH,
});
