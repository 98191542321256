import {
  POST_STATUS_ANALYTICS,
  POST_STATUS_PENDING,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_CONTENT,
} from "../utils/constants";

export const getIsEnabledOrderChat = (order) => {
  if (
    order.postStatus === POST_STATUS_PENDING_CONTENT ||
    order.postStatus === POST_STATUS_PENDING_APPROVAL ||
    order.postStatus === POST_STATUS_PENDING ||
    order.postStatus === POST_STATUS_ANALYTICS
  ) {
    return true;
  }
  return false;
};
