import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  BarChart as BarContainer,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import Typography from "@mui/material/Typography";
import Loader from "../../../Loader/Loader";
import StatisticsContainer from "../../../Statistics/StatisticContainer";
import { getGender } from "../../../../../utils/formats";
import { numberDecimal } from "../../../../../utils/numberFormats";
import { COLORS_STATISTICS } from "../../../../../utils/colors";
import {
  AUDIENCE_BY_DISTRIBUTION_AGE,
  FEMALES,
  MALES,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";

const InfluencerAudienceAgeDistribution = ({ data = [] }) => {
  const [statistics, setStatistics] = useState(null);

  const { t } = useTranslationApp();

  useEffect(() => {
    if (data.length > 0) {
      const dataFormatted = [];
      [...data].map((audience, index) => {
        if (index < 6) {
          dataFormatted.push({
            rango: audience.ageRange,
            [getGender(audience?.gender)]: audience?.value,
            [getGender(data[index + 5]?.gender)]: data[index + 5]?.value,
          });
        }
        return null;
      });
      setStatistics(dataFormatted);
    }
    //eslint-disable-next-line
  }, []);

  if (data.length === 0) return null;
  if (!statistics) return <Loader size={40} />;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { xs: "100%", md: "calc(100vw - 320px)" },
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        {t(AUDIENCE_BY_DISTRIBUTION_AGE)}
      </Typography>
      <StatisticsContainer sx={{ height: 400, pr: 2, pb: 2 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarContainer
            width={500}
            height={300}
            data={statistics}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="rango" />
            <YAxis width={30} />
            <Tooltip
              formatter={(value) => {
                return `${numberDecimal(value)}%`;
              }}
            />
            <Bar
              dataKey={t(MALES)}
              fill={COLORS_STATISTICS[0]}
              radius={[12, 12, 0, 0]}
            />
            <Bar
              dataKey={t(FEMALES)}
              fill={COLORS_STATISTICS[1]}
              radius={[12, 12, 0, 0]}
            />
            <Legend />
          </BarContainer>
        </ResponsiveContainer>
      </StatisticsContainer>
    </Box>
  );
};

export default InfluencerAudienceAgeDistribution;
