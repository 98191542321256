import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { RED_LIGHT_COLOR } from "../../../../utils/colors";
import { useTranslationApp } from "../../../../lib/i18next";
import { NEW, ORDER_ID } from "../../../../locales/keysTranslations";
import { BORDER_RADIUS, GENERAL_REDEMPTION } from "../../../../utils/constants";

const KanbanAcceptButton = ({ data, onClickButton, dataTour = "" }) => {
  const { t } = useTranslationApp();
  const theme = useTheme();

  return (
    <Box
      data-tour={dataTour}
      onClick={() => onClickButton(data)}
      sx={{
        backgroundColor:
          data?.from === GENERAL_REDEMPTION
            ? "secondary.main"
            : "background.paper",
        display: "flex",
        justifyContent: "space-between",
        maxHeight: "none",
        py: 1,
        px: 2,
        color:
          data?.from === GENERAL_REDEMPTION
            ? `${theme.palette.secondary.contrastText} !important`
            : `${theme.palette.primary.contrastText} !important`,
        textAlign: "center",
        ":hover": {
          cursor: "pointer",
        },
        borderRadius: BORDER_RADIUS,
        gap: 1,
      }}
    >
      <Typography
        variant="body1"
        color="inherit"
        sx={{ fontWeight: 600, wordBreak: "break-all" }}
      >
        {t(ORDER_ID, {
          id: data.deliveryID,
        })}
      </Typography>
      {data.isNew && (
        <Typography
          variant="body1"
          color="inherit"
          sx={{ color: RED_LIGHT_COLOR }}
        >
          {t(NEW)}
        </Typography>
      )}
    </Box>
  );
};

export default KanbanAcceptButton;
