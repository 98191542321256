import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../../Common/Buttons/Button";
import {
  POST_STATUS_ANALYTICS,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_CONTENT,
  POST_STATUS_SENT,
} from "../../../utils/constants";
import InfluencerNameLogo from "../../Common/Influencer/InfluencerNameLogo";
import TitlePage from "../../Common/Texts/TitlePage";
import {
  PUBLICATION_TITLE,
  REVIEW_BUTTON,
} from "../../../onboarding/stepsSelectors";
import ButtonDoAmbassador from "../../Common/Buttons/ButtonDoAmbassador";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_REVIEW,
  FEEDBACK_COMMENT,
  FEEDBACK_REASON,
  FEEDBACK_TITLE,
  NUMBER_PUBLICATION,
  PUBLICATION_TITLE as PUBLICATION_TITLE_TRANSLATION,
} from "../../../locales/keysTranslations";
import InitChatButton from "../../Common/Chat/InitChatButton";
import { useDispatchApp } from "../../../lib/redux";
import { startOpenPublicationConversation } from "../../../actions/chat";

const InfoPublication = ({ influencer, order, onActionButton, from }) => {
  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onOpenChatPublication = () => {
    dispatch(
      startOpenPublicationConversation({
        chatID: order.chatID,
        userID: order.userID,
        orderID: order.orderID,
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      data-tour={PUBLICATION_TITLE}
    >
      <TitlePage sx={{ mb: 2, mt: 1 }}>
        {order.postStatus === POST_STATUS_NEGATIVE_REVIEW
          ? t(FEEDBACK_TITLE)
          : t(PUBLICATION_TITLE_TRANSLATION)}
      </TitlePage>
      <Box
        sx={{
          display: "flex",
          width: "calc(100% - 16px)",
          justifyContent: "space-between",
          maxWidth: {
            xs: "90%",
            sm: 500,
          },
        }}
      >
        <Box>
          <InfluencerNameLogo
            variantText="h5"
            data={influencer}
            userID={order.userID}
          />
          <Box sx={{ display: "flex", mt: 1, flexWrap: "wrap", mr: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {`${t(NUMBER_PUBLICATION)}:`}
            </Typography>
            <Typography variant="body1">{order.orderID}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 1,
              mt: 1,
            }}
          >
            {order.postStatus === POST_STATUS_SENT && from === "user" ? (
              <Button
                dataTour={REVIEW_BUTTON}
                variant="contained"
                color="error"
                onClick={onActionButton}
                sx={{
                  maxHeight: 40,
                  minWidth: 120,
                  mt: 1,
                }}
              >
                {t(BUTTON_REVIEW)}
              </Button>
            ) : null}
            {order.postStatus !== POST_STATUS_SENT && from === "user" ? (
              <ButtonDoAmbassador
                userID={influencer?.userID}
                sxContainer={{
                  width: "auto",
                  justifyContent: "flex-start",
                }}
              />
            ) : null}
            {order.postStatus === POST_STATUS_NEGATIVE_REVIEW && (
              <>
                <Typography variant="body1">
                  {`${t(FEEDBACK_REASON)}: ${
                    order.negativeReview.categories
                      ? order.negativeReview.categories[0]
                      : "-"
                  }`}
                </Typography>
                <Typography variant="body1">
                  {`${t(FEEDBACK_COMMENT)}: ${
                    order.negativeReview.comment ?? "-"
                  }`}
                </Typography>
              </>
            )}
            {(order.postStatus === POST_STATUS_PENDING_CONTENT ||
              order.postStatus === POST_STATUS_PENDING_APPROVAL ||
              order.postStatus === POST_STATUS_PENDING ||
              order.postStatus === POST_STATUS_ANALYTICS) &&
              from === "user" && (
                <InitChatButton
                  color="secondary"
                  type="button"
                  onClick={onOpenChatPublication}
                />
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoPublication;
