import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NumberField from "../../../../Common/NumberFormats/NumberField";
import {
  GOAL_CAMPAIGN_OPTIONS,
  INSTAGRAM,
  MAX_FOLLOWERS,
  MIN_FOLLOWERS,
  TIKTOK,
} from "../../../../../utils/constants";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  FIELD_URL_VALID,
  CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER,
  CAMPAIGN_BUDGET_PLACEHOLDER,
  CAMPAIGN_COPY_POST_PLACEHOLDER,
  CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CAMPAIGN_GOAL_PLACEHOLDER,
  CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER,
  CAMPAIGN_NAME_PLACEHOLDER,
  CAMPAIGN_PLATFORMS_PLACEHOLDER,
  CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER,
  CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER,
  CAMPAIGN_TYPE_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION,
  FIELD_REQUIRED,
  IMAGES,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
  VALUE_EXPRESSED_IN,
  VIDEOS,
  CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR,
  SELECT_A_OPTION,
  YES,
  NO,
  CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL,
  FIELD_MIN_LENGTH,
  FIELD_MAX_LENGTH,
  BUTTON_GENERATE_OTHER,
  CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION,
} from "../../../../../locales/keysTranslations";
import SliderBudgetCampaign from "../../../../Common/Forms/Sliders/SliderBudgetCampaign";
import RadioGroup from "../../../../Common/Forms/RadioGrupo/RadioGroup";
import Select from "../../../../Common/Selects/Select";
import TextField from "../../../../Common/Fields/TextField";
import { REGEX_URL } from "../../../../../utils/regexsValidation";
import { getMinValue } from "../../../../../services/campaigns";
import { useLocale } from "../../../../../Contexts/LocaleContext";
import SliderBase from "../../../../Common/Forms/Sliders/SliderBase";
import { marksInfluencersMax500K } from "../../../../../utils/slidersMarks";
import { useMediaQuery } from "@mui/material";

const CampaignInformation = ({
  control,
  errors,
  setValue,
  watch,
  isDisabledInput = true,
  onGenerateScriptAndDescriptionCampaign,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const LOCALE = useLocale();

  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const typeInfluencers = watch("typeInfluencers");
  const isUgcCampaign = watch("isUgcCampaign");
  const typeContent = watch("typeContent");
  const typeContentMedia = watch("typeContentMedia");
  const platforms = watch("platforms");
  const showCopy =
    typeContent.instagramPost ||
    typeContent.instagramReel ||
    typeContent.tiktokPost;

  const minValue = getMinValue({
    LOCALE,
    isUgcCampaign,
    typeInfluencers,
  });

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} lg={6}>
        <TextField
          name="name"
          label={t(CAMPAIGN_NAME_PLACEHOLDER)}
          margin="normal"
          variant="filled"
          fullWidth
          disabled={isDisabledInput}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Select
          name="goal"
          variant="filled"
          disabled={isDisabledInput}
          fullWidth
          label={t(CAMPAIGN_GOAL_PLACEHOLDER)}
          options={GOAL_CAMPAIGN_OPTIONS}
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          alignSelf: "flex-end",
        }}
      >
        <TextField
          name="descriptionAi"
          label={t(CAMPAIGN_DESCRIPTION_PLACEHOLDER)}
          fullWidth
          margin="normal"
          disabled={isDisabledInput}
          variant="filled"
          multiline
          minRows={6}
          maxRows={6}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          sx={{
            pb: "20px",
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="script"
          label={t(
            CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL
          )}
          fullWidth
          margin="normal"
          disabled={isDisabledInput}
          variant="filled"
          multiline
          minRows={6}
          maxRows={6}
          showCounterCharacters={true}
          maxCharacters={3000}
          hasButtonGenerate={true}
          alignButtonGenerate="top-right"
          propsButtonGenerate={{
            loading: isGenerating,
            onClick: async () => {
              setIsGenerating(true);
              await onGenerateScriptAndDescriptionCampaign();
              setIsGenerating(false);
            },
            text: BUTTON_GENERATE_OTHER,
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            minLength: {
              value: 50,
              message: t(FIELD_MIN_LENGTH, { value: 50 }),
            },
            maxLength: {
              value: 3000,
              message: t(FIELD_MAX_LENGTH, { value: 3000 }),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="body1">
          {t(CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION)}
        </Typography>
        <RadioGroup
          disabled={true}
          variantTitle="body2"
          variantOption="body2"
          isBooleanValues={true}
          name="isUgcCampaign"
          label={t(CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER)}
          options={[
            {
              value: true,
              label: t(
                CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION
              ),
            },
            {
              value: false,
              label: t(
                CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION
              ),
            },
          ]}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="amountInfluencers"
          fullWidth
          margin="normal"
          disabled={isDisabledInput}
          label={t(CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER)}
          variant="filled"
          InputProps={{
            inputComponent: NumberField,
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            value: (value) => {
              if (value < 1) {
                return t(CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE, {
                  value: 1,
                });
              }
            },
          }}
        />
      </Grid>
      {!isUgcCampaign && (
        <Grid item xs={12} lg={6}>
          <Box sx={{ pl: -1, pt: 1 }}>
            <Typography variant="body1">
              {t(CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER)}
            </Typography>
            <Typography variant="body2">
              {t(CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION, {
                value: minValue,
                currency: "USD",
              })}
            </Typography>
            <Controller
              name="typeInfluencers"
              control={control}
              render={({ field }) => (
                <SliderBase
                  range={field.value}
                  onChange={field.onChange}
                  min={MIN_FOLLOWERS}
                  max={MAX_FOLLOWERS}
                  sx={{
                    maxWidth: { xs: "100%", lg: "calc(100% - 5%)" },
                  }}
                  disabled={isDisabledInput}
                  step={1000}
                  marks={marksInfluencersMax500K.filter((mark, index) => {
                    if (isMobile) {
                      if (index === 0) {
                        return mark;
                      }
                      if (index % 2) {
                        return mark;
                      } else {
                        return false;
                      }
                    }

                    return mark;
                  })}
                />
              )}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Box sx={{ pl: -1, pt: 1 }}>
          <Typography variant="body1">
            {t(CAMPAIGN_BUDGET_PLACEHOLDER)}
          </Typography>
          <Typography variant="body2">
            {t(VALUE_EXPRESSED_IN, {
              currency: "USD",
            })}
          </Typography>
          <SliderBudgetCampaign
            watch={watch}
            disabled={isDisabledInput}
            control={control}
            errors={errors}
            rules={{
              validate: (value) => {
                if (value < minValue) {
                  return t(CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR, {
                    value: minValue,
                    currency: "USD",
                  });
                }
              },
            }}
            name="individualBudget"
            variantTitle="body1"
          />
        </Box>
      </Grid>
      {!isUgcCampaign && (
        <Grid item xs={12} lg={6}>
          <FormControl
            component="fieldset"
            variant="filled"
            disabled={isDisabledInput}
          >
            <FormLabel component="legend" sx={{ fontWeight: 500 }}>
              {t(CAMPAIGN_PLATFORMS_PLACEHOLDER)}
            </FormLabel>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CheckItem
                  control={control}
                  nameKey="platforms"
                  nameValue="instagram"
                  label="Instagram"
                  rules={{
                    validate: (value) => {
                      if (!Object.values(platforms).includes(true)) {
                        return t(FIELD_REQUIRED);
                      }
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CheckItem
                  control={control}
                  nameKey="platforms"
                  nameValue="tiktok"
                  label="TikTok"
                  rules={{
                    validate: () => {
                      if (!Object.values(platforms).includes(true)) {
                        return t(FIELD_REQUIRED);
                      }
                    },
                  }}
                />
              </Grid>
            </Grid>
            {errors?.platforms?.message && (
              <Typography variant="caption" color="error">
                {errors?.platforms?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <FormControl
          component="fieldset"
          variant="filled"
          disabled={isDisabledInput}
        >
          <FormLabel component="legend" sx={{ fontWeight: 500 }}>
            {t(CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER)}
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CheckItem
                control={control}
                nameKey="typeContentMedia"
                nameValue="videos"
                label={t(VIDEOS)}
                rules={{
                  validate: () => {
                    if (!Object.values(typeContentMedia).includes(true)) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CheckItem
                control={control}
                nameKey="typeContentMedia"
                nameValue="images"
                label={t(IMAGES)}
                rules={{
                  validate: () => {
                    if (!Object.values(typeContentMedia).includes(true)) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
          </Grid>
          {errors?.typeContentMedia?.message && (
            <Typography variant="caption" color="error">
              {errors?.typeContentMedia?.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      {!isUgcCampaign && Object.values(platforms).includes(true) && (
        <>
          <Grid item xs={12} lg={6}>
            <Typography variant="body1">
              {t(CAMPAIGN_TYPE_CONTENT_PLACEHOLDER)}
            </Typography>
            <FormControl
              component="fieldset"
              variant="filled"
              disabled={isDisabledInput}
            >
              <Grid container spacing={1} sx={{ mb: 1 }}>
                {platforms[INSTAGRAM] && (
                  <>
                    <Grid item xs={6}>
                      <CheckItem
                        control={control}
                        nameKey="typeContent"
                        nameValue="instagramBurstStories"
                        disabled={isDisabledInput}
                        label={t(INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES)}
                        rules={{
                          validate: () => {
                            if (!Object.values(typeContent).includes(true)) {
                              return t(FIELD_REQUIRED);
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CheckItem
                        control={control}
                        nameKey="typeContent"
                        nameValue="instagramPost"
                        disabled={isDisabledInput}
                        label={t(INVITE_FORM_FORMAT_INSTAGRAM_POST)}
                        rules={{
                          validate: () => {
                            if (!Object.values(typeContent).includes(true)) {
                              return t(FIELD_REQUIRED);
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CheckItem
                        control={control}
                        nameKey="typeContent"
                        nameValue="instagramReel"
                        disabled={isDisabledInput}
                        label={t(INVITE_FORM_FORMAT_INSTAGRAM_REEL)}
                        rules={{
                          validate: () => {
                            if (!Object.values(typeContent).includes(true)) {
                              return t(FIELD_REQUIRED);
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CheckItem
                        control={control}
                        nameKey="typeContent"
                        nameValue="instagramStory"
                        disabled={isDisabledInput}
                        label={t(INVITE_FORM_FORMAT_INSTAGRAM_STORY)}
                        rules={{
                          validate: () => {
                            if (!Object.values(typeContent).includes(true)) {
                              return t(FIELD_REQUIRED);
                            }
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
                {platforms[TIKTOK] && (
                  <>
                    <Grid item xs={6}>
                      <CheckItem
                        control={control}
                        nameKey="typeContent"
                        nameValue="tiktokPost"
                        disabled={isDisabledInput}
                        label={t(INVITE_FORM_FORMAT_TIKTOK_POST)}
                        rules={{
                          validate: () => {
                            if (!Object.values(typeContent).includes(true)) {
                              return t(FIELD_REQUIRED);
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CheckItem
                        control={control}
                        nameKey="typeContent"
                        nameValue="tiktokStory"
                        disabled={isDisabledInput}
                        label={t(INVITE_FORM_FORMAT_TIKTOK_STORY)}
                        rules={{
                          validate: () => {
                            if (!Object.values(typeContent).includes(true)) {
                              return t(FIELD_REQUIRED);
                            }
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {errors?.typeContent?.message && (
                <Typography variant="caption" color="error">
                  {errors?.typeContent?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {(showCopy ||
            typeContent.instagramPost ||
            typeContent.instagramReel) && (
            <Grid item xs={12} lg={6}>
              <TextField
                name="copyPost"
                label={t(CAMPAIGN_COPY_POST_PLACEHOLDER)}
                disabled={isDisabledInput}
                fullWidth
                margin="normal"
                variant="filled"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
              {(typeContent.instagramPost || typeContent.instagramReel) && (
                <>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {t(CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION)}
                  </Typography>
                  <RadioGroup
                    disabled={false}
                    variantTitle="body2"
                    variantOption="body2"
                    isBooleanValues={true}
                    name="hasCollaboration"
                    label={t(SELECT_A_OPTION)}
                    options={[
                      {
                        value: true,
                        label: t(YES),
                      },
                      {
                        value: false,
                        label: t(NO),
                      },
                    ]}
                    control={control}
                    errors={errors}
                    rules={{
                      required: {
                        value: false,
                        message: t(FIELD_REQUIRED),
                      },
                    }}
                  />
                </>
              )}
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} lg={6}>
        <TextField
          name="trafficLink"
          label={t(CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
          disabled={isDisabledInput}
          fullWidth
          margin="normal"
          variant="filled"
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value && !REGEX_URL.test(value)) {
                return t(FIELD_URL_VALID);
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="productAttributes"
          label={t(CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER)}
          fullWidth
          margin="normal"
          variant="filled"
          disabled={isDisabledInput}
          multiline
          minRows={1}
          maxRows={4}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="messageContent"
          label={t(CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER)}
          disabled={isDisabledInput}
          fullWidth
          margin="normal"
          variant="filled"
          multiline
          minRows={1}
          maxRows={4}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

const CheckItem = ({ control, nameKey, nameValue, label, rules = {} }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={nameKey}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Checkbox
              checked={field.value[nameValue]}
              onChange={(e) =>
                field.onChange({
                  ...field.value,
                  [nameValue]: e.target.checked,
                })
              }
              name={nameValue}
            />
          )}
        />
      }
      componentsProps={{
        typography: {
          variant: "body2",
        },
      }}
      label={label}
    />
  );
};

export default CampaignInformation;
