import React from "react";

const CounterRevisionText = ({
  text = "Revisión",
  revision = 0,
  increment = 0,
}) => {
  return <>{`${text} (${revision + increment}/3)`}</>;
};

export default CounterRevisionText;
