import React, { useState } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import {
  ACCEPTED,
  BUTTON_ACCEPT,
  BUTTON_REJECT,
  COLUMN_NAME_ACTIONS,
  COLUMN_NAME_ID,
  COLUMN_NAME_URLS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import Typography from "@mui/material/Typography";
import { GREEN_COLOR } from "../../../utils/colors";
import LinkText from "../../Common/Texts/LinkText";

const ContentApplicationTable = ({
  data,
  page,
  rowsPerPage,
  onPageChange,
  onAcceptContentApplication,
  onRejectContentApplication,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslationApp();

  const keys = Object.keys(data);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        loading={false}
        keys={keys}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_URLS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTIONS)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : keys
          ).map((row, index) => {
            let items = data[row];
            const isAccepted = items.includes(true);
            if (items.length > 0) {
              items = items.filter((url) => url !== "" && url !== true);
            }
            return (
              <TableRow key={index}>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  {row}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {items.map((url, index) => (
                      <LinkText
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url.length > 40 ? `${url.substring(0, 40)}...` : url}
                      </LinkText>
                    ))}
                  </Box>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {isAccepted && (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                          color: GREEN_COLOR,
                        }}
                      >
                        {t(ACCEPTED)}
                      </Typography>
                    )}
                    {!isAccepted && (
                      <>
                        <LoadingButton
                          color="primary"
                          loading={isLoading}
                          onClick={async () => {
                            setIsLoading(true);
                            onRejectContentApplication(row);
                            setIsLoading(false);
                          }}
                        >
                          {t(BUTTON_REJECT)}
                        </LoadingButton>
                        <LoadingButton
                          color="secondary"
                          loading={isLoading}
                          onClick={async () => {
                            setIsLoading(true);
                            onAcceptContentApplication(row);
                            setIsLoading(false);
                          }}
                        >
                          {t(BUTTON_ACCEPT)}
                        </LoadingButton>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ContentApplicationTable;
