import React, { useEffect, useState } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import { useDispatchApp } from "../../../lib/redux";
import Typography from "@mui/material/Typography";
import Loader from "../../Common/Loader/Loader";
import GalleryContent from "./GalleryContent";
import {
  EMPTY_CONTENT_SHOW,
  GALLERY_CONTENT_BUZZLY,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useNearScreen from "../../../hooks/useNearScreen";
import { startGetOrdersByUserIDPagination } from "../../../actions/orders";
import { useDebounced } from "../../../hooks/useDebounce";
import { usePagination } from "../../../hooks/usePagination";
import { getIsEnabledOrderChat } from "../../../services/orders";
import { getNameBusinessTheme, getShopID } from "../../../actions/getters";

const PAGE_SIZE = 5;
const InfluencerGalleryContent = ({
  userID,
  isEnabledOrderChat,
  onChangeShowOrderChat = () => {},
}) => {
  const [orders, setOrders] = useState({});
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslationApp();

  // const orders = useSelectorApp((state) => state.orders.orders);

  const [loadLastItem, setLoadLastItem] = useState(false);

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "100px",
    once: false,
  });

  const dispatch = useDispatchApp();
  const shopID = dispatch(getShopID());
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const onChangeLoadLastItem = (status) => setLoadLastItem(status);

  const getInitialData = async () => {
    const response = await dispatch(
      startGetOrdersByUserIDPagination({
        userID,
        limit: PAGE_SIZE !== -1 ? PAGE_SIZE + 1 : false,
        onChangeLastKey: onChangeLastKey,
        loadLastItem: () => onChangeLoadLastItem(true),
      })
    );
    if (response) {
      setOrders({
        ...orders,
        ...response,
      });
    }
  };

  const getNextOrders = useDebounced(async () => {
    const response = await dispatch(
      startGetOrdersByUserIDPagination({
        userID,
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
        loadLastItem: () => onChangeLoadLastItem(true),
      })
    );
    if (response) {
      setOrders({
        ...orders,
        ...response,
      });
    }
  }, 1000);

  const { rowsPerPage, lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: getNextOrders,
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isNearScreen && !loadLastItem) getNextOrders();
    //eslint-disable-next-line
  }, [isNearScreen]);

  useEffect(() => {
    const ordersKeys = Object.keys(orders);
    if (ordersKeys.length === 0) {
      if (isLoading && loadLastItem) {
        setIsLoading(false);
      }
      setContent([]);
    } else {
      const urls = [];

      ordersKeys.forEach((key) => {
        const order = orders[key];

        if (
          order.userID === userID &&
          order.shopID === shopID &&
          getIsEnabledOrderChat(order) &&
          !isEnabledOrderChat
        ) {
          onChangeShowOrderChat(true);
        }

        if (order.posts && _.isArray(order.posts)) {
          order.posts.forEach((post) => {
            if (
              post.includes("firebasestorage") ||
              post.includes("amazonaws")
            ) {
              urls.push(post);
            }
          });
        }
      });

      if (urls.length === 0) {
        if (!loadLastItem) {
          getNextOrders();
        } else {
          setIsLoading(false);
        }
        return;
      }
      if (urls.length > 0) {
        setIsLoading(false);
        setContent(urls);
        return;
      }
    }
    //eslint-disable-next-line
  }, [orders, loadLastItem]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          fontStyle: "normal",
          mb: 1,
        }}
      >
        {t(GALLERY_CONTENT_BUZZLY, {
          NAME_BUSINESS,
        })}
      </Typography>
      {isLoading ? (
        <Loader fullWidth />
      ) : content.length > 0 ? (
        <GalleryContent
          data={content}
          lastItemRef={fromRef}
          loadLastItem={loadLastItem}
        />
      ) : (
        <Typography variant="body1">{t(EMPTY_CONTENT_SHOW)}</Typography>
      )}
    </Box>
  );
};

export default InfluencerGalleryContent;
