import React, { useState } from "react";
import LoadingButton from "./LoadingButton";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
  GRAY_DARK_THEME_COLOR,
} from "../../../utils/colors";
import { useTranslationApp } from "../../../lib/i18next";
import { BUTTON_GENERATE_DESCRIPTION } from "../../../locales/keysTranslations";

const ButtonGenerateAI = ({
  textButton = BUTTON_GENERATE_DESCRIPTION,
  onCallback = () => {},
  sx = {},
  color = "primary",
  size = "small",
  useGradient = true,
  disabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslationApp();

  const sxGradient = useGradient
    ? {
        background: GRADIENT_PURPLE_FUCHSIA,
        color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
      }
    : {};
  return (
    <LoadingButton
      disabled={disabled}
      color={color}
      startIcon={<AutoAwesomeIcon />}
      size={size}
      sx={{
        ...sxGradient,
        "&.Mui-disabled": {
          backgroundColor: GRAY_DARK_THEME_COLOR,
        },
        ...sx,
      }}
      type="button"
      loading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        await onCallback();
        setIsLoading(false);
      }}
    >
      {t(textButton)}
    </LoadingButton>
  );
};

export default ButtonGenerateAI;
