import React from "react";
import Box from "@mui/material/Box";
import KanbanToBeDeliveryButton from "./KanbanToBeDeliveryButton";

const KanbanCardToBeDeliveryContent = ({ height, data, onSelectOrden }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        maxHeight: {
          xs: `calc(${height}px - 96px)`,
          lg: `calc(${height}px - 94px)`,
        },
        minHeight: 400,
      }}
    >
      {Object.keys(data).map((key) => (
        <KanbanToBeDeliveryButton
          key={data[key].deliveryID}
          data={data[key]}
          onClickButton={(orden) => onSelectOrden(orden)}
        />
      ))}
    </Box>
  );
};

export default KanbanCardToBeDeliveryContent;
