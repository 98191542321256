import { useSelectorApp } from "../../lib/redux";
import { Navigate } from "react-router-dom";
import {
  ADMIN_ROLE,
  DESIGNER_ROLE,
  ROLES_DASHBOARD,
  SUPER_ADMIN_ROLE,
} from "../../utils/constants";
import Loader from "../../components/Common/Loader/Loader";

export const PublicRoute = ({ children, restricted, isLogged }) => {
  const { user } = useSelectorApp((state) => state.auth);
  const { isLoading } = useSelectorApp((state) => state.payments);

  if (isLogged && restricted) {
    if (ROLES_DASHBOARD.includes(user?.role))
      return <Navigate to="/brand-signups" />;

    if (isLoading) return <Loader />;

    if (user?.role === SUPER_ADMIN_ROLE || user?.role === ADMIN_ROLE) {
      return <Navigate to="/creators" />;
    }

    if (user?.role === DESIGNER_ROLE) {
      return <Navigate to="/publications" />;
    }

    return <Navigate to="/redeem-code" />;
  }

  return children;
};
