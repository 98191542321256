import {
  ref as refDB,
  get,
  query,
  // orderByKey,
  update,
  // startAfter,
  // limitToFirst,
  orderByChild,
  startAt,
  endAt,
  equalTo,
} from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import { getObjectError } from "../utils/formats";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";

export const startGetRequestsInfluencers =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch, getState) => {
    try {
      const country = getState()?.auth?.user?.country;
      const q = query(
        refDB(db, `requestedInfluencerAccounts`),
        orderByChild("country"),
        equalTo(country)
      );
      // const q = lastKey
      //   ? query(
      //       refDB(db, `requestedInfluencerAccounts`),
      //       orderByKey("isPending"),
      //       startAfter(lastKey),
      //       limitToFirst(limit)
      //     )
      //   : query(
      //       refDB(db, `requestedInfluencerAccounts`),
      //       orderByKey("isPending"),
      //       limitToFirst(limit)
      //     );
      const snapshot = await get(q);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const keys = Object.keys(data);
        onChangeLastKey(keys[keys.length - 1]);
        dispatch(getRequestsInfluencers(data));
        return true;
      } else {
        dispatch(loadingFinish());
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getRequestsInfluencers = (data) => ({
  type: types.GET_REQUESTS_INFLUENCERS_FINISH,
  payload: data,
});

export const startGetRequestAccountByName = (textQuery) => async () => {
  try {
    const dbRef = refDB(db, "requestedInfluencerAccounts");
    const q = query(
      dbRef,
      orderByChild("accountName"),
      startAt(textQuery),
      endAt(textQuery + "\uf8ff")
    );
    const snapshop = await get(q);
    if (snapshop.exists()) {
      const requestAccounts = snapshop.val();
      return requestAccounts;
    }
    return {};
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startMarkCodeSent = (id) => async (dispatch) => {
  try {
    const updates = {};
    updates[`requestedInfluencerAccounts/${id}/isCodeSent`] = true;
    await update(refDB(db), updates);
    dispatch(markCodeSent(id));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const markCodeSent = (data) => ({
  type: types.MARK_CODE_SENT_REQUEST,
  payload: data,
});

export const startAcceptRequestInfluencer =
  (id, verificationCode) => async (dispatch) => {
    try {
      const updates = {};
      updates[`requestedInfluencerAccounts/${id}/verificationCode`] =
        verificationCode;
      updates[`requestedInfluencerAccounts/${id}/isPending`] = false;
      await update(refDB(db), updates);
      dispatch(
        acceptRequestInfluencer({
          id,
          verificationCode,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const acceptRequestInfluencer = (data) => ({
  type: types.ACCEPT_REQUEST_INFLUENCER,
  payload: data,
});

export const startRejectRequestInfluencer =
  (id) => async (dispatch, getState) => {
    try {
      const requestData =
        getState().requestsInfluencers.requestsInfluencers[id];
      const updates = {};
      updates[`requestedInfluencerAccounts/${id}/`] = null;
      updates[`requestedInfluencerAccountsRejected/${id}/`] = {
        ...requestData,
        isRejected: true,
        isPending: false,
      };
      await update(refDB(db), updates);
      dispatch(
        rejectRequestInfluencer({
          id,
          isRejected: true,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const rejectRequestInfluencer = (data) => ({
  type: types.REJECT_REQUEST_INFLUENCER,
  payload: data,
});

const loadingFinish = () => ({
  type: types.LOADING_REQUESTS_INFLUENCERS_FINISH,
});
