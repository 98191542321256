import React, { useState } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DEFAULT_TABLE_PAGES } from "../../../utils/constants";
import format from "date-fns/format";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import { useSelectorApp } from "../../../lib/redux";

const PayCreatorsTable = ({ data, onSelectPayment }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(DEFAULT_TABLE_PAGES);

  const shops = useSelectorApp((state) => state.shop.shops);
  const influencers = useSelectorApp((state) => state.influencers.influencers);

  const keys = Object.keys(data);

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        keys={keys}
        loading={false}
        ariaLabel="Tabla de Pagos a Creadores"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              ID Pago
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Tipo
            </TableCell>
            <TableCell align="center" isHeader={true}>
              ID Tipo
            </TableCell>
            <TableCell align="center" isHeader={true}>
              ID Creador
            </TableCell>
            <TableCell align="center" isHeader={true}>
              ID Shop
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Fecha de Pago
            </TableCell>
            <TableCell align="center" isHeader={true}>
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : keys
          ).map((key, index) => {
            if (data[key]?.isHidden) return null;
            const shop = shops[data[key].shopID];
            const influencer = influencers[data[key].userID];
            return (
              <TableRow key={key}>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    minWidth: 190,
                    wordBreak: "break-word",
                  }}
                >
                  {data[key].paymentID}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    minWidth: 190,
                    wordBreak: "break-all",
                  }}
                >
                  {data[key].from}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    minWidth: 190,
                    wordBreak: "break-all",
                  }}
                >
                  {data[key].typeID}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {influencer?.mainAccountName || "-"}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  {shop?.businessName || "-"}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{ maxWidth: 200 }}
                >
                  {data[key].paymentDate &&
                    format(new Date(data[key].paymentDate), "dd/MM/yyyy")}
                </TableCell>
                <TableCell
                  indexRow={index}
                  align="center"
                  sx={{ maxWidth: 200 }}
                >
                  <IconButton onClick={() => onSelectPayment(key)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default PayCreatorsTable;
