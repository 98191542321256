import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import TextField from "../Fields/TextField";
import Select from "../Selects/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import NumberField from "../NumberFormats/NumberField";
import { useDispatchApp } from "../../../lib/redux";
import { startCreatePaymentLink } from "../../../actions/owner";
import ButtonCopy from "../Buttons/ButtonCopy";
import {
  BUTTON_CANCEL,
  BUTTON_CREATE,
  COST_COLLABORATION_PLACEHOLDER,
  CREDITS_PLACEHOLDER,
  CURRENCY_PLACEHOLDER,
  DESCRIPTION_OPTIONAL_PLACEHOLDER,
  FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE,
  FIELD_CREDITS_NOT_BE_NEGATIVE,
  FIELD_REQUIRED,
  IS_RECURRENCE_PLACEHOLDER,
  NAME_PLACEHOLDER,
  PRICE_PLACEHOLDER,
  RECURRENCE_PLACEHOLDER,
  RECURRENT_OPTION,
  RECURRING_DAYS,
  RECURRING_MONTHS,
  RECURRING_WEEKS,
  RECURRING_YEARS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import { getNameBusinessTheme } from "../../../actions/getters";
import CurrencyField from "../NumberFormats/CurrencyField";
import LinkText from "../Texts/LinkText";
import ContainerModalActions from "../Containers/ContainerModalActions";

const CreateStripeProductForm = ({ metadata = {}, shopID, onCloseModal }) => {
  const [paymentLink, setPaymentLink] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { control, watch, handleSubmit, formState } = useForm({
    defaultValues: {
      costCollaboration: 0,
      credits: 0,
      name: "",
      description: "",
      price: "",
      currency: "usd",
      isRecurring: true,
      recurring: "month",
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const isRecurring = watch("isRecurring");

  const onSubmit = async (data) => {
    setIsFetching(true);
    const response = await dispatch(
      startCreatePaymentLink({
        ...data,
        metadata,
        shopID,
      })
    );
    if (response) {
      setPaymentLink(response.paymentLink);
    }
    setIsFetching(false);
  };

  return (
    <Grid
      container
      spacing={1.5}
      sx={{ width: "100%" }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="name"
          label={t(NAME_PLACEHOLDER)}
          variant="filled"
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="description"
          label={t(DESCRIPTION_OPTIONAL_PLACEHOLDER)}
          variant="filled"
          control={control}
          errors={errors}
          rules={{}}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          fullWidth
          name="credits"
          label={t(CREDITS_PLACEHOLDER, {
            NAME_BUSINESS,
          })}
          variant="filled"
          control={control}
          errors={errors}
          InputProps={{
            inputComponent: NumberField,
          }}
          rules={{
            validate: (value) => {
              if (String(value)?.length === 0) return t(FIELD_REQUIRED);
              if (value < 0)
                return t(FIELD_CREDITS_NOT_BE_NEGATIVE, { NAME_BUSINESS });
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          fullWidth
          name="price"
          label={t(PRICE_PLACEHOLDER)}
          variant="filled"
          control={control}
          errors={errors}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Select
          fullWidth
          name="currency"
          label={t(CURRENCY_PLACEHOLDER)}
          variant="filled"
          control={control}
          errors={errors}
          options={[
            { value: "usd", label: "USD" },
            { value: "cop", label: "COP" },
          ]}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          fullWidth
          name="costCollaboration"
          label={t(COST_COLLABORATION_PLACEHOLDER)}
          variant="filled"
          control={control}
          errors={errors}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          rules={{
            validate: (value) => {
              if (String(value)?.length === 0) return t(FIELD_REQUIRED);
              if (value < 0) return t(FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE);
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Select
          fullWidth
          name="currency"
          label={t(CURRENCY_PLACEHOLDER)}
          variant="filled"
          control={control}
          errors={errors}
          options={[
            { value: "usd", label: "USD" },
            { value: "cop", label: "COP" },
          ]}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="isRecurring"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControl>
              <FormLabel id="is-recurring-product">
                {t(IS_RECURRENCE_PLACEHOLDER)}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="is-recurring-product"
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value === "true" ? true : false);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t(RECURRENT_OPTION)}
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
      {isRecurring && (
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            name="recurring"
            label={t(RECURRENCE_PLACEHOLDER)}
            variant="filled"
            control={control}
            errors={errors}
            rules={{
              validate: {
                required: (value) => {
                  if (isRecurring && !value) return t(FIELD_REQUIRED);
                },
              },
            }}
            options={[
              {
                value: "day",
                label: t(RECURRING_DAYS),
              },
              {
                value: "week",
                label: t(RECURRING_WEEKS),
              },
              {
                value: "month",
                label: t(RECURRING_MONTHS),
              },
              {
                value: "year",
                label: t(RECURRING_YEARS),
              },
            ]}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {paymentLink && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
                justifyContent: "center",
              }}
            >
              <LinkText href={paymentLink} target="_blank">
                {paymentLink}
              </LinkText>
              <ButtonCopy value={paymentLink} />
            </Box>
          )}
          <ContainerModalActions
            hasPadding={false}
            propsSecondaryButton={{
              showButton: true,
              text: BUTTON_CANCEL,
              onClick: onCloseModal,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_CREATE,
              onClick: () => {},
              loading: isFetching,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateStripeProductForm;
