import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import AmbassadorForm from "./AmbassadorForm";
import { startEditAmbassador } from "../../../actions/ambassadors";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_SAVE,
  FIELD_REQUIRED,
  INVITE_VALUE_MIN_VALUE,
  FIELD_MIN_VALUE_TO_PAY_AMBASSADOR,
  MODAL_AMBASSADOR_ARIAL_LABEL,
  MODAL_AMBASSADOR_DESCRIPTION,
  MODAL_AMBASSADOR_TITLE,
} from "../../../locales/keysTranslations";
import ModalInvitation from "../InfluencersListTable/ModalInvitation";
import { startGetAverageTicketShop } from "../../../actions/shops";
import {
  INVITATION_INFLUENCER_MODE_AMBASSADOR,
  INVITATION_INFLUENCER_MODE_EDIT,
  MIN_ALL_NUMBER_VALUE,
} from "../../../utils/constants";
import { startGetInvitesByInvitesID } from "../../../actions/invites";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import { useLocale } from "../../../Contexts/LocaleContext";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const INITIAL_STATE = {
  userID: "",
  inviteCode: "",
  recurrentPattern: "",
  recurrentPatternPayment: "",
  orderFrom: new Date(),
  withRecurrentInvite: false,
  value: 0,
  withRecurrentPayment: false,
  valueToPay: 0,
};
const ModalAmbassador = ({ modalOpen, onCloseModal, data = {} }) => {
  const { handleSubmit, control, setValue, watch, reset, formState } = useForm({
    defaultValues: INITIAL_STATE,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [modalInvitationOpen, setModalInvitationOpen] = useState(false);
  const [averageTicket, setAverageTicket] = useState(0);

  const invite = useSelectorApp(
    (state) => state?.invites?.invites[data.inviteID]
  );
  const influencer = useSelectorApp(
    (state) => state?.influencers?.influencers[data.userID]
  );

  const withRecurrentInvite = watch("withRecurrentInvite");
  const withRecurrentPayment = watch("withRecurrentPayment");

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const dispatch = useDispatchApp();
  const LOCALE = useLocale();
  const { t } = useTranslationApp();

  const getInitialData = async () => {
    dispatch(startGetInvitesByInvitesID({ invitesID: [data.inviteID] }));
    const ticket = await dispatch(startGetAverageTicketShop());
    setAverageTicket(ticket);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("userID", data.userID || "");
    setValue("inviteCode", data.inviteCode || "");
    setValue("orderFrom", data.orderFrom || new Date());
    setValue("recurrentPattern", data.recurrentPattern || "");
    setValue("value", data.value ?? 0);
    setValue("withRecurrentInvite", data.withRecurrentInvite ?? false);
    setValue("withRecurrentPayment", data.withRecurrentPayment ?? false);
    setValue("valueToPay", data.valueToPay ?? 0);
    setValue("recurrentPatternPayment", data.recurrentPatternPayment || "");
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (formData) => {
    setIsFetching(true);
    const response = await dispatch(
      startEditAmbassador({
        ambassadorID: data.ambassadorID,
        ambassador: {
          userID: formData.userID,
          inviteCode: formData.inviteCode,
          orderFrom: formData.orderFrom,
          recurrentPattern: formData.recurrentPattern,
          value: formData.value,
          withRecurrentInvite: formData.withRecurrentInvite,
          valueToPay: formData.valueToPay,
          withRecurrentPayment: formData.withRecurrentPayment,
          recurrentPatternPayment: formData.recurrentPatternPayment,
        },
      })
    );
    setIsFetching(false);
    if (response) {
      reset(INITIAL_STATE);
      onCloseModal();
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_AMBASSADOR_ARIAL_LABEL)}
      title={t(MODAL_AMBASSADOR_TITLE)}
      description={t(MODAL_AMBASSADOR_DESCRIPTION)}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <AmbassadorForm
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          validate={{
            value: {
              validate: (value) => {
                if (withRecurrentInvite) {
                  if (value === "" || value === null || !value) {
                    return t(FIELD_REQUIRED);
                  }
                  if (parseInt(value) < MIN_ALL_NUMBER_VALUE) {
                    return t(INVITE_VALUE_MIN_VALUE, {
                      value: MIN_ALL_NUMBER_VALUE,
                      currency: LOCALE.currency,
                    });
                  }
                }
              },
            },
            valueToPay: {
              validate: (value) => {
                if (withRecurrentPayment) {
                  if (value === "" || value === null || !value) {
                    return t(FIELD_REQUIRED);
                  }
                  if (parseInt(value) < MIN_ALL_NUMBER_VALUE) {
                    return t(FIELD_MIN_VALUE_TO_PAY_AMBASSADOR, {
                      value: MIN_ALL_NUMBER_VALUE,
                      currency: "USD",
                    });
                  }
                }
              },
            },
          }}
        />
        <ContainerModalActions
          hasPadding={false}
          sx={{
            mt: withRecurrentInvite || withRecurrentPayment ? 2 : 0,
          }}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SAVE,
            onClick: () => {},
            loading: isFetching,
          }}
        />

        {modalInvitationOpen && (
          <ModalInvitation
            dataInfluencer={influencer}
            userID={data.userID}
            dataInvite={{
              ...invite,
              inviteID: data.inviteID,
              code: data.inviteCode,
            }}
            onCloseModal={() => setModalInvitationOpen(false)}
            averageTicket={averageTicket}
            mode={INVITATION_INFLUENCER_MODE_EDIT}
            from={INVITATION_INFLUENCER_MODE_AMBASSADOR}
          />
        )}
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalAmbassador;
