import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CardMedia from "../../Media/CardMedia";
import { useTheme } from "@mui/material";

const ModalMediaPublicationPreviewContents = ({
  data = {},
  position = 0,
  onChangePosition,
}) => {
  const [itemsToRender, setItemsToRender] = useState([]);
  const [hasUrls, setHasUrls] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    let MAX_PREVIEW_ITEMS;
    let INIT_CUT = 0;
    if (position === 0) {
      MAX_PREVIEW_ITEMS = 3;
    }
    if (position === 1) {
      MAX_PREVIEW_ITEMS = 4;
    }
    if (position >= 2) {
      MAX_PREVIEW_ITEMS = 5;
      INIT_CUT = position - 2;
    }

    const items = [...data]
      .slice(INIT_CUT, INIT_CUT + MAX_PREVIEW_ITEMS)
      .map((item, index) => ({
        ...item,
        position: INIT_CUT + index,
      }));
    const hasUrl = items.some((item) => item.url);
    setHasUrls(hasUrl);
    setItemsToRender(items);

    // eslint-disable-next-line
  }, [position, data]);

  const sizeSquare = 54;

  if (!hasUrls) {
    return null;
  }
  return (
    <Box
      sx={{
        mt: 1,
        width: "100%",
        minWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        height: sizeSquare,
        minHeight: sizeSquare,
        display: "flex",
        justifyContent: "center",
        gap: 2,
      }}
    >
      {itemsToRender.map((item, index) => {
        return (
          <Box
            key={index}
            onClick={() => onChangePosition(item.position)}
            sx={{
              cursor: "pointer",
              width: sizeSquare,
              height: sizeSquare,
              minWidth: sizeSquare,
              minHeight: sizeSquare,
              borderRadius: 1,
              border: `2px solid ${
                item?.position === position
                  ? theme.palette.secondary.main
                  : "transparent"
              }`,
            }}
          >
            <CardMedia
              url={item.url}
              propsType={{
                controls: false,
                autoplay: false,
              }}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ModalMediaPublicationPreviewContents;
