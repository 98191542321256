import types from "../types";

const initialState = {
  businesses: {},
  isLoading: true,
};

export const businessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BUSINESSES_FINISH:
      return {
        ...state,
        isLoading: false,
        businesses: action.payload,
      };
    case types.CREATE_BUSINESS:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [action.payload.businessID]: {
            businessName: action.payload.businessName,
            shops: action.payload.shops,
          },
        },
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
