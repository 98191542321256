import React, { useContext, useEffect, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "../../../assets/styles/chat.css";
import {
  Avatar,
  Conversation,
  ConversationList,
  MainContainer,
  Search,
  Sidebar,
} from "@chatscope/chat-ui-kit-react";
import ChatContainer from "./ChatContainer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ChatContext } from "../../../Contexts/ChatContext";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  CHAT_BOT_USER_ID,
  ROLES_SHOPS,
  ROLES_DASHBOARD,
  PLACE_HOLDER_PERSON_ICON,
  FONT_FAMILY,
  CHAT_SUB_FROM_ADVERTISER,
  CHAT_SUB_FROM_SHOP,
} from "../../../utils/constants";
import {
  getNameBusinessTheme,
  getRole,
  getShopID,
  getUserID,
} from "../../../actions/getters";
import { startGetBusinessName } from "../../../actions/shops";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CHAT_NO_MORE,
  FIELD_SEARCH_CHATS_MORE_3_CHARACTERS,
  NAME_BUSINESS_BOT,
  SEARCH,
} from "../../../locales/keysTranslations";
import Loader from "../Loader/Loader";
import useNearScreen from "../../../hooks/useNearScreen";
import { usePagination } from "../../../hooks/usePagination";
import { useDebounced } from "../../../hooks/useDebounce";
import {
  closeChat,
  startGetNextChats,
  startSearchChats,
} from "../../../actions/chat";
import ModalInfluencerDetails from "../../Owner/InfluencersUsers/ModalInfluencerDetails";
import { getChatUnread } from "../../../services/chats";
import ModalPayInfluencer from "../Influencer/ModalPayInfluencer";
import { useMediaQuery } from "@mui/material";
import _ from "lodash";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { resetState } from "../../../actions/auth";
import logoPerson from "../../../assets/img/logo-person.png";

const PAGE_SIZE = 10;

const ChatMainContainer = ({ onChangeShowChat }) => {
  const [modalOpenPayInfluencer, setOpenModalPayInfluencer] = useState(false);
  const [modalInfluencerOpen, setOpenModalInfluencerOpen] = useState(false);
  const [influencerSelected, setInfluencerSelected] = useState(null);
  const [loadLastItem, setLoadLastItem] = useState(false);
  const [searchText, setSearchText] = useState("");

  const {
    chats,
    onSelectChat,
    LOGO_BUSINESS,
    LOGO_CHATBOT,
    logoShop,
    currentChat,
  } = useContext(ChatContext);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "200px",
    once: false,
  });

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { t } = useTranslationApp();
  const { isActivePlan } = useSelectorApp((state) => state.auth);

  const shopID = dispatch(getShopID());
  const currentUserID = dispatch(getUserID());
  const role = dispatch(getRole());

  const influencers = useSelectorApp((state) => state.influencers.influencers);
  const shops = useSelectorApp((state) => state.shop.shops);
  const advertisers = useSelectorApp((state) => state.advertisers.advertisers);
  const businessName = useSelectorApp((state) => state.shop.businessName);

  const getNextChats = useDebounced(() => {
    dispatch(
      startGetNextChats({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
        loadLastItem: () => setLoadLastItem(true),
        from: ROLES_SHOPS.includes(role) ? "shop" : "user",
      })
    );
  }, 1000);

  const { rowsPerPage, lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: getNextChats,
  });

  useEffect(() => {
    if (isNearScreen && !loadLastItem) getNextChats();
    //eslint-disable-next-line
  }, [isNearScreen]);

  useEffect(() => {
    dispatch(startGetBusinessName());
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    setLoadLastItem(false);
    //eslint-disable-next-line
  }, [searchText]);
  useEffect(() => {
    if (!role || !currentUserID) {
      dispatch(closeChat());
      dispatch(resetState());
    }
    //eslint-disable-next-line
  }, [role, currentUserID]);

  const onChangeSearchText = (value) => {
    setSearchText(value);
    if (value.length >= 1 && value.length <= 2) {
      setLoadLastItem(true);
      return;
    }
    if (value.length >= 3) {
      dispatch(
        startSearchChats({
          textQuery: value,
          onFinishLoad: () => setLoadLastItem(true),
        })
      );
    }
  };

  const onChangeStatusInfluencer = (status, userID) => {
    setOpenModalInfluencerOpen(status);
    setInfluencerSelected(userID);
  };

  const getChatInfo = (userID, chatID) => {
    const currentChat = chats[chatID];

    if (userID === CHAT_BOT_USER_ID)
      return {
        name: t(NAME_BUSINESS_BOT, {
          NAME_BUSINESS,
        }),
        imageURL: LOGO_CHATBOT,
      };

    if (userID === shopID) {
      return {
        name: businessName,
        imageURL: logoShop,
      };
    }

    if (currentChat.from === "help") {
      if (
        ROLES_DASHBOARD.includes(role) &&
        currentChat?.helpers?.includes(userID)
      ) {
        return {
          name: `${NAME_BUSINESS} Team`,
          imageURL: LOGO_BUSINESS,
        };
      }
      if (
        ROLES_DASHBOARD.includes(role) &&
        currentChat?.subFrom === CHAT_SUB_FROM_ADVERTISER
      ) {
        return {
          name: advertisers[userID]?.businessName,
          imageURL: logoPerson,
        };
      }
      if (
        ROLES_DASHBOARD.includes(role) &&
        currentChat?.subFrom === CHAT_SUB_FROM_SHOP
      ) {
        return {
          name: shops[userID]?.businessName,
          imageURL: shops[userID]?.logoImg,
        };
      }
      if (ROLES_SHOPS.includes(role)) {
        return {
          name: `${NAME_BUSINESS} Team`,
          imageURL: LOGO_BUSINESS,
        };
      }
    }

    return {
      ...influencers[userID],
      name: influencers[userID]?.mainAccountName,
    };
  };

  const filteredChats =
    searchText.length >= 1
      ? Object.keys(chats).filter((chatID) => {
          if (chatID === CHAT_BOT_USER_ID)
            return chats[chatID]?.name
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase());
          return influencers[chats[chatID].userID]?.mainAccountName
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase());
        })
      : Object.keys(chats);

  const sortedChats = filteredChats.sort(
    (chatID1, chatID2) =>
      chats[chatID2].lastMessageDate - chats[chatID1].lastMessageDate
  );

  if (!role || !currentUserID) return null;

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 0, sm: "20px" },
          right: { xs: 0, sm: "20px" },
          zIndex: isActivePlan ? 1200 : 900,
          maxWidth: { xs: "100vw", sm: 900 },
          maxHeight: { xs: "calc(100% - 56px)", sm: "calc(100% - 104px)" },
          height: { xs: "calc(100% - 56px)", md: 720 },
          width: "100%",
          display: "flex",
          fontFamily: `${FONT_FAMILY} !important`,
          borderRadius: isMobile ? 0 : 6,
          pl: { xs: 0, sm: "36px" },
        }}
        className={`animate__animated animate__fadeIn animate__faster`}
      >
        {!isMobile || (isMobile && _.isEmpty(currentChat)) ? (
          <Sidebar
            position="left"
            scrollable={true}
            style={{
              maxWidth: isMobile ? "unset" : 300,
              width: "100%",
            }}
          >
            <div as="Search" className="cs-search-container">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 61,
                }}
              >
                <Search
                  placeholder={t(SEARCH)}
                  onChange={onChangeSearchText}
                  onClearClick={() => onChangeSearchText("")}
                />
                {isMobile && (
                  <IconButton
                    onClick={onChangeShowChat}
                    sx={{
                      padding: 0,
                      mr: "14px",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "1.4rem" }} />
                  </IconButton>
                )}
              </Box>
              {searchText.length >= 1 && searchText.length <= 2 && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{
                    ml: 2,
                    WebkitBoxDecorationBreak: "clone",
                    display: "block",
                    lineHeight: 1.2,
                  }}
                >
                  {t(FIELD_SEARCH_CHATS_MORE_3_CHARACTERS)}
                </Typography>
              )}
            </div>
            <ConversationList>
              {sortedChats.map((chatID, index) => {
                const chat = chats[chatID];
                return (
                  <Conversation
                    className={
                      chat.chatID === CHAT_BOT_USER_ID ? "chat-bot" : ""
                    }
                    key={chat.chatID}
                    name={getChatInfo(chat.userID, chatID).name}
                    lastSenderName={getChatInfo(chat.lastSender, chatID).name}
                    info={chat?.lastMessage?.replace(/<br+>/g, " ")}
                    onClick={() => onSelectChat(chat.chatID)}
                    unreadDot={getChatUnread({
                      chat,
                      shopID,
                      role,
                    })}
                  >
                    <Avatar
                      src={getChatInfo(chat.userID, chatID).imageURL}
                      name={getChatInfo(chat.userID, chatID).name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = PLACE_HOLDER_PERSON_ICON;
                        e.target.style = "object-fit: contain";
                      }}
                    />
                  </Conversation>
                );
              })}
              <div ref={fromRef} as="Conversation"></div>
              <div
                as="Conversation"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {loadLastItem ? (
                  <Typography
                    variant="body1"
                    sx={{ mt: 1, color: "text.primary" }}
                  >
                    {t(CHAT_NO_MORE)}
                  </Typography>
                ) : (
                  <Loader size={30} />
                )}
              </div>
            </ConversationList>
          </Sidebar>
        ) : null}
        {!isMobile || (isMobile && !_.isEmpty(currentChat)) ? (
          <MainContainer>
            <ChatContainer
              onChangeShowChat={onChangeShowChat}
              onOpenModalPayment={() => setOpenModalPayInfluencer(true)}
              onOpenModalInfluencer={(userID) =>
                onChangeStatusInfluencer(true, currentChat.userID)
              }
            />
          </MainContainer>
        ) : null}
        {modalOpenPayInfluencer && (
          <ModalPayInfluencer
            modalOpen={modalOpenPayInfluencer}
            userID={currentChat.userID}
            isSpecificValue={true}
            onCloseModal={() => setOpenModalPayInfluencer(false)}
          />
        )}
        {modalInfluencerOpen && (
          <ModalInfluencerDetails
            modalOpen={modalInfluencerOpen}
            onCloseModal={() => {
              onChangeStatusInfluencer(false, null);
            }}
            data={influencers[influencerSelected]}
            userID={influencerSelected}
          />
        )}
      </Box>
    </Box>
  );
};

export default ChatMainContainer;
