import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import StoreForm from "./StoreForm";
import {
  formatCitiesSave,
  formatPhoneValueLibrary,
} from "../../../utils/formats";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  REGULAR_STORE_TYPE,
  SHOPIFY_STORE_TYPE,
  WORDPRESS_WOOCOMMERCE_STORE_TYPE,
} from "../../../utils/constants";
import { useForm } from "react-hook-form";
import {
  startConnectShopify,
  startConnectWordpressWoocommerce,
  startCreateStore,
  startGetShopifyIntegration,
  startGetWordPressWoocommerceIntegration,
  startUpdateStore,
} from "../../../actions/shops";
import { useDispatch } from "react-redux";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useSelectorApp } from "../../../lib/redux";
import { STORE_FORM } from "../../../onboarding/stepsSelectors";
import { ConfirmAlert, SimpleAlert } from "../../../utils/alerts";
import {
  getCurrencyObject,
  getNameBusinessTheme,
  getShopID,
} from "../../../actions/getters";
import {
  BUTTON_CANCEL,
  BUTTON_CONTINUE,
  BUTTON_CREATE,
  BUTTON_SAVE,
  ERROR,
  MODAL_STORE_ARIA_LABEL,
  MODAL_STORE_DESCRIPTION,
  MODAL_STORE_DESCRIPTION_EDIT,
  MODAL_STORE_TITLE,
  MODAL_STORE_TITLE_EDIT,
  NO,
  OK,
  READY,
  STORES_STORE_COORDS_NOT_FOUND,
  STORES_STORE_CREATED,
  STORES_STORE_CREATED_DESCRIPTION,
  STORES_STORE_NAME_DUPLICATE,
  STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION,
  STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION,
  STORES_STORE_SAVED,
  STORES_STORE_SHOPIFY_DUPLICATE,
  STORES_STORE_SPECIAL_STORE_EXISTS,
  STORES_STORE_WOOCOMMERCE_DUPLICATE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import { getLatLngByAddress } from "../../../googlemaps/googlemaps";
import { useTour } from "@reactour/tour";
import { setIsNewBrand } from "../../../actions/onboarding";
import { STEPS_MENU_FUNCTIONALITY } from "../../../onboarding/steps";
import { useNavigate } from "react-router-dom";
import { validMenuIsRequiredForStore } from "../../../services/shops";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const INITIAL_STATE = {
  id: "",
  storeType: REGULAR_STORE_TYPE,
  storeURL: "",
  name: "",
  address: "",
  phone: "",
  email: "",
  city: "",
  isActive: false,
  ordersMethods: {
    delivery: false,
    inStore: false,
  },
  currency: "",
  productAvailability: "",
  coverageRadius: "",
  cities: [],
  specialAccessToken: "",
  doCreateDiscountShipping: false,
  isActiveIntegration: false,
};

const ModalStore = ({
  data,
  modalOpen,
  onCloseModal,
  closeModalAfterCreate = true,
  closeModalAfterUpdate = true,
  onCallbackCreateStore = () => {},
  mode,
  validateMenu = true,
  requiredStoreType,
  titleCreate = MODAL_STORE_TITLE,
  descriptionCreate = MODAL_STORE_DESCRIPTION,
  buttonCreate = BUTTON_CREATE,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    getValues,
    setValue,
    control,
    watch,
    handleSubmit,
    reset,
    trigger,
    formState,
  } = useForm({
    defaultValues: INITIAL_STATE,
  });

  const dispatch = useDispatch();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const navigate = useNavigate();
  const LOCALE = useLocale();
  const currencyShop = dispatch(getCurrencyObject());

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { isOpen, setIsOpen, setSteps, setCurrentStep } = useTour();
  const { t } = useTranslationApp();

  const storeType = watch("storeType");

  const { user } = useSelectorApp((state) => state.auth);
  const signUpCountry = useSelectorApp((state) => state?.shop.signUpCountry);
  const { stores, websiteURL } = useSelectorApp((state) => state?.shop);
  const isNewBrand = useSelectorApp((state) => state.onboarding.isNewBrand);
  const shopID = dispatch(getShopID());

  useEffect(() => {
    setValue("id", mode === "create" ? "" : data.id);
    setValue(
      "storeType",
      mode === "create"
        ? REGULAR_STORE_TYPE
        : data.storeType ?? REGULAR_STORE_TYPE
    );
    setValue("storeURL", mode === "create" ? "" : data.storeURL ?? "");
    setValue("name", mode === "create" ? "" : data.name);
    setValue("isActive", mode === "create" ? false : data.isActive);
    setValue("address", mode === "create" ? "" : data.address);
    setValue(
      "ordersMethods",
      mode === "create"
        ? { delivery: false, inStore: false }
        : data.ordersMethods ?? { delivery: false, inStore: false }
    );
    setValue(
      "productAvailability",
      mode === "create" ? "" : data.productAvailability ?? ""
    );
    setValue(
      "coverageRadius",
      mode === "create" ? "" : data.coverageRadius ?? ""
    );
    setValue(
      "phone",
      mode === "create"
        ? ""
        : formatPhoneValueLibrary({
            phone: data?.phone,
            country: signUpCountry,
            locale: LOCALE,
          })
    );
    setValue("email", mode === "create" ? "" : data.email);
    setValue(
      "cities",
      mode === "create"
        ? []
        : formatCitiesSave({
            mode: "load",
            cities: data.cities,
            citiesShop: LOCALE.cities,
          }) ?? []
    );
    setValue("city", mode === "create" ? "" : data.city);

    (async () => {
      if (mode === "edit" && data.storeType === SHOPIFY_STORE_TYPE) {
        const shopifyData = await dispatch(startGetShopifyIntegration());
        if (!shopifyData) return;
        setValue(
          "doCreateDiscountShipping",
          shopifyData.doCreateDiscountShipping
        );
      }
      if (
        mode === "edit" &&
        data.storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE
      ) {
        const wordpressWoocommerceData = await dispatch(
          startGetWordPressWoocommerceIntegration()
        );
        if (!wordpressWoocommerceData) return;
        setValue(
          "doCreateDiscountShipping",
          wordpressWoocommerceData.doCreateDiscountShipping
        );
        setValue(
          "isActiveIntegration",
          wordpressWoocommerceData.isActiveIntegration
        );
      }
    })();
    // eslint-disable-next-line
  }, [data, mode]);

  useEffect(() => {
    setValue("currency", currencyShop);
    // eslint-disable-next-line
  }, [currencyShop]);

  useEffect(() => {
    if (requiredStoreType) {
      setValue("storeType", requiredStoreType);
    }
    // eslint-disable-next-line
  }, [requiredStoreType]);

  const onCreateStore = async (store) => {
    let existsStoreWithSameName = false;
    let existsSpecialStore = false;

    Object.keys(stores).forEach((key) => {
      if (stores[key].name === store.name) {
        existsStoreWithSameName = true;
      }
      if (stores[key].storeType !== REGULAR_STORE_TYPE) {
        existsSpecialStore = true;
      }
    });
    if (existsStoreWithSameName) {
      SimpleAlert({
        title: t(ERROR),
        text: t(STORES_STORE_NAME_DUPLICATE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    if (store.storeType !== REGULAR_STORE_TYPE) {
      if (existsSpecialStore) {
        SimpleAlert({
          title: t(ERROR),
          text: t(STORES_STORE_SPECIAL_STORE_EXISTS),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
      delete store.id;
      const response = await dispatch(startCreateStore(user.shopID, store));
      if (response?.ok) {
        if (closeModalAfterCreate) {
          onCloseModal();
        }
        onCallbackCreateStore();
        return true;
      }
    }
    const resultLatLng = await getLatLngByAddress(
      `${store.address}, ${store.city}, ${LOCALE.NAME}`
    );
    if (resultLatLng.ok) {
      store = {
        ...store,
        lat: resultLatLng.lat,
        lng: resultLatLng.lng,
      };
      delete store.id;
      const response = await dispatch(startCreateStore(user.shopID, store));
      if (response?.ok) {
        if (isOpen) {
          setIsOpen(false);
          dispatch(setIsNewBrand(false));
          if (isNewBrand) {
            const result = await ConfirmAlert({
              title: t(READY),
              text: t(STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION),
              additionalText:
                isNewBrand &&
                t(STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION),
              confirmButtonText: isNewBrand ? t(BUTTON_CONTINUE) : t(OK),
              showCancelButton: isNewBrand,
              icon: ALERT_ICON_TYPE_SUCCESS,
            });
            if (result.isConfirmed && isNewBrand) {
              dispatch(setIsNewBrand(true));
              setSteps(STEPS_MENU_FUNCTIONALITY);
              setCurrentStep(0);
              setIsOpen(true);
              navigate(`/menu`, {
                state: { storeID: response.key },
              });
            }
            if (closeModalAfterCreate) {
              onCloseModal();
            }
            return;
          } else {
            setIsOpen(false);
            SimpleAlert({
              title: t(READY),
              text: t(STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION),
              icon: ALERT_ICON_TYPE_SUCCESS,
            });
          }
        }

        const isRequiredMenu = validMenuIsRequiredForStore({
          store,
          websiteURL,
        });

        if (isRequiredMenu && validateMenu) {
          const result = await ConfirmAlert({
            title: t(STORES_STORE_CREATED),
            text: t(STORES_STORE_CREATED_DESCRIPTION),
            confirmButtonText: t(BUTTON_CONTINUE),
            cancelButtonText: t(NO),
            icon: ALERT_ICON_TYPE_SUCCESS,
          });
          if (result.isConfirmed) {
            navigate("/menu", { state: { storeID: response.key } });
          }
        }
        onCallbackCreateStore();
        if (closeModalAfterCreate) {
          onCloseModal();
        }
        return true;
      }
    } else {
      SimpleAlert({
        title: t(ERROR),
        text: t(STORES_STORE_COORDS_NOT_FOUND),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

  const onSaveStore = async (store, storeID, isSilent = false) => {
    if (
      Object.keys(stores).some(
        (key) => stores[key].name === store.name && key !== store.id
      )
    ) {
      SimpleAlert({
        title: t(ERROR),
        text: t(STORES_STORE_NAME_DUPLICATE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    if (store.storeType !== REGULAR_STORE_TYPE) {
      delete store.id;
      const response = await dispatch(
        startUpdateStore(user.shopID, storeID, store)
      );
      if (response && !isSilent) {
        SimpleAlert({
          title: t(STORES_STORE_SAVED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      if (!isSilent) {
        onCallbackCreateStore();
        if (closeModalAfterUpdate) {
          onCloseModal();
        }
      }
      return response;
    }
    const resultLatLng = await getLatLngByAddress(
      `${store.address}, ${store.city}, ${LOCALE.NAME}`
    );
    if (resultLatLng.ok) {
      store = {
        ...store,
        lat: resultLatLng.lat,
        lng: resultLatLng.lng,
      };
      delete store.id;
      const response = await dispatch(
        startUpdateStore(user.shopID, storeID, store)
      );
      if (response) {
        SimpleAlert({
          title: t(STORES_STORE_SAVED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      if (closeModalAfterUpdate) {
        onCloseModal();
      }
      onCallbackCreateStore();
      return response;
    } else {
      SimpleAlert({
        title: t(ERROR),
        text: t(STORES_STORE_COORDS_NOT_FOUND),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

  const onSubmitForm = async () => {
    const formValues = getValues();

    const { storeType } = formValues;

    setIsLoading(true);

    if (storeType === SHOPIFY_STORE_TYPE && mode === "create") {
      if (
        Object.keys(stores).some(
          (key) => stores[key].storeType === SHOPIFY_STORE_TYPE
        )
      ) {
        SimpleAlert({
          title: t(ERROR),
          text: t(STORES_STORE_SHOPIFY_DUPLICATE),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        setIsLoading(false);
        return false;
      }
      const response = await onVerifyShopifyIntegration();

      if (!response) {
        setIsLoading(false);
        return false;
      }
    }

    const citiesFormatted = formatCitiesSave({
      mode: "save",
      cities: formValues.cities,
      citiesShop: LOCALE.cities,
    });
    formValues.cities = citiesFormatted;
    formValues.phone = formatPhoneValueLibrary({
      phone: formValues?.phone?.value,
      mode: "save",
    });
    if (mode === "create") {
      const response = await onCreateStore(formValues);
      response && reset();
    } else {
      const response = await onSaveStore(formValues, formValues.id);
      response && reset();
    }
    setIsLoading(false);
  };

  const onVerifyShopifyIntegration = async () => {
    const formValues = getValues();

    const { specialAccessToken, storeURL } = formValues;

    const response = await dispatch(
      startConnectShopify({
        accessToken: specialAccessToken,
        storeURL,
      })
    );

    return response;
  };

  const onSaveWordpressWoocommerce = async () => {
    const result = await trigger();
    if (!result) return false;

    const formValues = getValues();

    let storeKey;

    const citiesFormatted = formatCitiesSave({
      mode: "save",
      cities: formValues.cities,
      citiesShop: LOCALE.cities,
    });
    formValues.cities = citiesFormatted;
    formValues.phone = formatPhoneValueLibrary({
      phone: formValues?.phone?.value,
      mode: "save",
    });

    console.log("formValues", formValues);
    console.log(stores);
    if (!formValues.id) {
      if (
        Object.keys(stores).some((key) => stores[key].name === formValues.name)
      ) {
        SimpleAlert({
          title: t(ERROR),
          text: t(STORES_STORE_WOOCOMMERCE_DUPLICATE),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
      delete formValues.id;
      const response = await dispatch(startCreateStore(shopID, formValues));
      if (!response.ok) return;
      storeKey = response.key;
    } else {
      storeKey = formValues.id;
      await onSaveStore(formValues, formValues.id, true);
    }

    await dispatch(startConnectWordpressWoocommerce(storeKey));
    return true;
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      fullWidth={true}
      onCloseModal={onCloseModal}
      maxWidth="sm"
      scroll="paper"
      ariaLabel={t(MODAL_STORE_ARIA_LABEL)}
      title={mode === "create" ? t(titleCreate) : t(MODAL_STORE_TITLE_EDIT)}
      description={
        mode === "create"
          ? t(descriptionCreate, {
              NAME_BUSINESS,
            })
          : t(MODAL_STORE_DESCRIPTION_EDIT)
      }
      dataTour={STORE_FORM}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitForm)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <StoreForm
          control={control}
          watch={watch}
          mode={mode}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          signUpCountry={signUpCountry}
          requiredStoreType={requiredStoreType}
          onSaveWordpressWoocommerce={onSaveWordpressWoocommerce}
        />

        {(storeType !== WORDPRESS_WOOCOMMERCE_STORE_TYPE ||
          (mode === "edit" &&
            storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE)) && (
          <ContainerModalActions
            hasPadding={false}
            propsSecondaryButton={{
              showButton: true,
              text: BUTTON_CANCEL,
              onClick: onCloseModal,
            }}
            propsPrimaryButton={{
              showButton: true,
              loading: isLoading,
              text: mode === "create" ? buttonCreate : BUTTON_SAVE,
              onClick: () => {},
            }}
          />
        )}
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalStore;
