import types from "../types";
const initialState = {
  adsCampaigns: {},
  isLoading: true,
};

export const adsCampaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADS_CAMPAIGNS_FINISH:
      return {
        ...state,
        adsCampaigns: { ...state.adsCampaigns, ...action.payload },
        isLoading: false,
      };
    case types.SAVE_ADS_CAMPAIGN_FINISH:
      return {
        ...state,
        adsCampaigns: {
          ...state.adsCampaigns,
          [action.payload.key]: {
            ...state.adsCampaigns[action.payload.key],
            ...action.payload.data,
          },
        },
      };

    case types.CLEAN_ADS_CAMPAIGNS:
      return initialState;
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;

    default:
      return state;
  }
};
