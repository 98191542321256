import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TimerIcon from "@mui/icons-material/Timer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import {
  DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER,
  DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER,
  DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const DeliveriesOwnerFiltersType = ({
  deliveryType,
  onChangeDeliveryType = () => {},
}) => {
  const { t } = useTranslationApp();
  return (
    <Box sx={{ display: "flex", gap: 2, mb: 3, flexWrap: "wrap" }}>
      <Chip
        icon={<CheckBoxIcon />}
        label={t(DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER)}
        onClick={() => {
          onChangeDeliveryType("toBeAccepted");
        }}
        color={deliveryType === "toBeAccepted" ? "primary" : "default"}
      />
      <Chip
        icon={<TimerIcon />}
        label={t(DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER)}
        onClick={() => {
          onChangeDeliveryType("inPreparation");
        }}
        color={deliveryType === "inPreparation" ? "primary" : "default"}
      />
      <Chip
        icon={<DeliveryDiningIcon />}
        label={t(DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER)}
        onClick={() => {
          onChangeDeliveryType("toBeDelivered");
        }}
        color={deliveryType === "toBeDelivered" ? "primary" : "default"}
      />
    </Box>
  );
};

export default DeliveriesOwnerFiltersType;
