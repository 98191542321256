import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { businessesReducer } from "./businessesReducer";
import { campaignsReducer } from "./campaignsReducer";
import { deliveriesReducer } from "./deliveriesReducer";
import { influencersReducer } from "./influencersReducer";
import { invitesReducer } from "./invitesReducer";
import { notificationsReducer } from "./notificationsReducer";
import { ordersReducer } from "./ordersReducer";
import { paymentsReducer } from "./paymentsReducer";
import { requestsInfluencersReducer } from "./requestsInfluencersReducer";
import { requestsReducer } from "./requestsReducer";
import { ambassadorsReducer } from "./ambassadorsReducer";
import { shopsReducer } from "./shopsReducer";
import { uiReducer } from "./uiReducer";
import { usersReducer } from "./usersReducer";
import { chatReducer } from "./chatReducer";
import { onboardingReducer } from "./onboardingReducer";
import { localesReducer } from "./localesReducer";
import { eventsReducer } from "./eventsReducer";
import { adsCampaignsReducer } from "./adsCampaignsReducer";
import { advertisersReducer } from "./advertirsersReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  advertisers: advertisersReducer,
  adsCampaigns: adsCampaignsReducer,
  businesses: businessesReducer,
  campaigns: campaignsReducer,
  chat: chatReducer,
  deliveries: deliveriesReducer,
  events: eventsReducer,
  influencers: influencersReducer,
  locales: localesReducer,
  invites: invitesReducer,
  notifications: notificationsReducer,
  orders: ordersReducer,
  payments: paymentsReducer,
  requests: requestsReducer,
  onboarding: onboardingReducer,
  ambassadors: ambassadorsReducer,
  requestsInfluencers: requestsInfluencersReducer,
  shop: shopsReducer,
  ui: uiReducer,
  users: usersReducer,
});
