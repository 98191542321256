import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { generalCategories } from "../../../utils/categories";
import { useTranslationApp } from "../../../lib/i18next";
import LinkText from "../Texts/LinkText";

const ShopNameLink = ({ data, variantText = "h6", showCategory = true }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 1,
        mb: 1,
      }}
    >
      {data.socialNetwork ? (
        <LinkText
          href={data.socialNetwork}
          target="_blank"
          sx={{ fontWeight: 600 }}
        >
          <Typography variant={variantText}>
            {data?.businessName ?? "-"}
          </Typography>
        </LinkText>
      ) : (
        <Typography variant={variantText}>
          {data?.businessName ?? "-"}
        </Typography>
      )}
      {data.category && showCategory && (
        <Chip
          label={t(
            generalCategories.find((c) => c.value === data.category).label
          )}
          onClick={() => {}}
        />
      )}
    </Box>
  );
};

export default ShopNameLink;
