import React from "react";
import Box from "@mui/material/Box";
import CreditCard from "../CreditCard";
import PackageContainer from "./PackageContainer";
import { useDispatchApp } from "../../../../../lib/redux";
import { getParentShopID, getShopID } from "../../../../../actions/getters";

const ModelPackage = ({ planData, dataInvoices }) => {
  const dispatch = useDispatchApp();

  const shopID = dispatch(getShopID());
  const parentShopID = dispatch(getParentShopID());

  return (
    <Box
      sx={{
        mb: 3,
        display: "flex",
        gap: 3,
        flexDirection: {
          xs: "column",
          lg: "row",
        },
      }}
    >
      <PackageContainer data={planData} />
      <Box sx={{ width: { xs: "100%", lg: "55%" } }}>
        {(parentShopID.length === 0 || shopID === parentShopID) && (
          <CreditCard />
        )}
      </Box>
    </Box>
  );
};

export default ModelPackage;
