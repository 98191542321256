import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslationApp } from "../../../../lib/i18next";
import { useDispatch } from "react-redux";
import { ConfirmAlert, SimpleAlert } from "../../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  IMAGE_FORMATS,
} from "../../../../utils/constants";
import {
  BUTTON_CONTINUE,
  BUTTON_GENERATE_DESCRIPTION,
  BUTTON_UPLOAD_INSTAGRAM,
  BUTTON_VIEW_PROFILE,
  ERROR,
  FIELD_REQUIRED,
  INSTAGRAM,
  MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION,
  MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL,
  MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER,
  MODAL_UPLOAD_CONTENT_UPLOADED,
  MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION,
  MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL,
} from "../../../../locales/keysTranslations";
import {
  getExtensionFile,
  getInfluencerSocialLink,
} from "../../../../utils/urls";
import { useSelectorApp } from "../../../../lib/redux";
import InfluencerNameLogo from "../../Influencer/InfluencerNameLogo";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "../../Fields/TextField";
import {
  startGenerateContentDescriptionInstagram,
  startUploadContentInstagram,
} from "../../../../actions/shops";
import { ThemeProvider, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Select from "../../Selects/Select";
import themeDark from "../../Themes/themeDark";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import ButtonMetaAction from "../../Buttons/ButtonMetaAction";

const InstagramUploadForm = ({ url, metadata = {}, onClosePrevComponent }) => {
  const [isFetchingDescription, setIsFetchingDescription] = useState(false);

  const format = getExtensionFile(url);

  const { control, handleSubmit, setValue, formState, watch } = useForm({
    defaultValues: {
      description: "",
    },
  });

  const description = watch("description");

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const outerTheme = useTheme();
  const darkTheme = themeDark(outerTheme);

  const facebookIntegrations = useSelectorApp(
    (state) => state.shop.integrations.facebook
  );
  const integrationID = Object.keys(facebookIntegrations || {})[0];
  const facebookIntegration = facebookIntegrations?.[integrationID] || {};
  const pageData = Object.values(facebookIntegration?.pages || {})[0];
  const instagramData = pageData?.instagramBusinessAccount;

  const callbackTokenExpired = () => {
    onClosePrevComponent();
    navigate("account?focusInstagram=true&instagramLogout=true");
  };

  const onSubmit = async (data) => {
    const response = await dispatch(
      startUploadContentInstagram(
        { ...data, urls: [url], integrationID },
        callbackTokenExpired
      )
    );
    if (response) {
      const result = await ConfirmAlert({
        title: t(MODAL_UPLOAD_CONTENT_UPLOADED),
        text: t(MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
        cancelButtonText: t(BUTTON_CONTINUE),
        confirmButtonText: t(BUTTON_VIEW_PROFILE),
      });
      if (result.isConfirmed) {
        window.open(
          getInfluencerSocialLink({
            mainPlatform: INSTAGRAM,
            mainAccountName: instagramData?.username,
          }),
          "_blank"
        );
      }
    }
  };

  const onGenerateDescription = async () => {
    if (!metadata.orderID)
      return SimpleAlert({
        title: t(ERROR),
        text: t(MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });

    setIsFetchingDescription(true);
    const response = await dispatch(
      startGenerateContentDescriptionInstagram(metadata.orderID)
    );
    if (response) {
      setValue("description", response);
    }
    setIsFetchingDescription(false);
  };

  const onGetOptionsType = () => {
    if (IMAGE_FORMATS.includes(format))
      return [
        {
          label: t(MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL),
          value: "IMAGE",
        },
        {
          label: t(MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL),
          value: "STORIES",
        },
      ];
    return [
      {
        label: t(MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL),
        value: "REELS",
      },
      {
        label: t(MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL),
        value: "STORIES",
      },
    ];
  };

  return (
    <Grid container direction="column" component="form" sx={{ width: "100%" }}>
      <ThemeProvider theme={darkTheme}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <InfluencerNameLogo
              data={{
                mainPlatform: INSTAGRAM,
                mainAccountName: instagramData.username,
              }}
              colorText="white"
              sx={{ mb: 0 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            label={t(MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL)}
            name="description"
            placeholder={t(MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER)}
            InputLabelProps={{ shrink: true }}
            variant="filled"
            multiline={true}
            minRows={4}
            maxRows={6}
            fullWidth
            showCounterCharacters={true}
            maxCharacters={350}
            value={description}
            hasButtonGenerate={metadata?.orderID}
            alignButtonGenerate="top-right"
            propsButtonGenerate={{
              loading: isFetchingDescription,
              onClick: onGenerateDescription,
              text: BUTTON_GENERATE_DESCRIPTION,
            }}
            control={control}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Select
            control={control}
            label={t(MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL)}
            fullWidth
            name="mediaType"
            backgroundColor="none"
            variant="filled"
            options={onGetOptionsType()}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, pb: 2 }}>
          <ButtonMetaAction
            text={BUTTON_UPLOAD_INSTAGRAM}
            sx={{
              p: 4,
              fontSize: "1.1rem",
            }}
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};

export default InstagramUploadForm;
