import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RenderContentMedia from "../../../Common/Media/RenderContentMedia";
import { detectPlatform } from "../../../../utils/urls";

const ContentApplicantHistory = ({ data, isMobile }) => {
  return (
    <Box
      sx={{
        width: "auto",
      }}
    >
      {data.map((revision, index) => {
        if (index >= 1) {
          return (
            <Box key={index} sx={{ mb: 3 }}>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}
              >
                <Typography
                  variant={isMobile ? "h4" : "h5"}
                  sx={{ fontWeight: 600 }}
                >
                  {`Revisión N°${index}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  gap: 2,
                  display: "flex",
                  overflowX: "auto",
                  pb: 1,
                }}
              >
                {revision.contentUrls.map((media, indexMedia) => {
                  const result = detectPlatform(media);
                  return (
                    <RenderContentMedia
                      key={indexMedia}
                      result={result}
                      url={media}
                      keyMedia={indexMedia}
                    />
                  );
                })}
              </Box>
              <Box>
                <Typography variant="body1">{revision.comment}</Typography>
              </Box>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default ContentApplicantHistory;
