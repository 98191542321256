import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfluencerNameLogo from "../../../Common/Influencer/InfluencerNameLogo";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "../../../Common/Buttons/Button";
import ModalReferenceProfiles from "../../../Common/Modal/ModalReferenceProfiles";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { startGetSimilarProfiles } from "../../../../actions/influencers";
import {
  BUTTON_ADD_REFERENCE_PROFILE,
  LIST_CREATORS_WITH_AUDIENCE_SIMILAR,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProfileInfluencerFormReferenceProfiles = ({
  watch,
  onDeleteReferenceProfile,
}) => {
  const [modalReferenceProfilesOpen, setModalReferenceProfilesOpen] =
    useState(false);
  const [similarInfluencers, setSimilarInfluencers] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();

  const onGetSimilarProfiles = async () => {
    setIsLoading(true);
    setModalReferenceProfilesOpen(true);
    const similarInfluencers = await dispatch(
      startGetSimilarProfiles(referenceProfiles)
    );
    if (!_.isEmpty(similarInfluencers)) {
      setSimilarInfluencers(similarInfluencers);
    } else {
      setSimilarInfluencers({});
    }
    setIsLoading(false);
  };

  const referenceProfiles = watch("referenceProfiles");

  return (
    <>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          sx={{
            fontWeight: 600,
          }}
        >
          {t(PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION)}
        </Typography>
        <Typography variant="subtitle2">
          {t(PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {referenceProfiles.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={item.accountName}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <InfluencerNameLogo
                    data={{
                      mainPlatform: item.platform,
                      mainAccountName: item.accountName,
                      hasAnalytics: true,
                    }}
                    userID={item.userID}
                    sx={{
                      mb: 0,
                    }}
                  />
                  <IconButton
                    onClick={() => onDeleteReferenceProfile(index)}
                    sx={{ p: 0, mt: "4px" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {referenceProfiles.length > 0 && (
          <Grid item xs={12}>
            <Button
              sx={{ mt: 2 }}
              color="secondary"
              type="button"
              onClick={onGetSimilarProfiles}
            >
              {t(BUTTON_ADD_REFERENCE_PROFILE)}
            </Button>
          </Grid>
        )}
      </Grid>
      {modalReferenceProfilesOpen && (
        <ModalReferenceProfiles
          modalOpen={modalReferenceProfilesOpen}
          onCloseModal={() => setModalReferenceProfilesOpen(false)}
          influencers={similarInfluencers}
          loading={isLoading}
          title={t(LIST_CREATORS_WITH_AUDIENCE_SIMILAR)}
        />
      )}
    </>
  );
};

export default ProfileInfluencerFormReferenceProfiles;
