import React from "react";
import NumberFormat from "react-number-format";

const TimeField = React.forwardRef(function TimeField(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##:##"
      placeholder="HH:MM"
      isNumericString
    />
  );
});

export default TimeField;
