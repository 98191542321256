import React from "react";
import { Link } from "react-router-dom";
import { RED_COLOR } from "../../../utils/colors";
import {
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_CONTENT,
  POST_STATUS_SENT,
} from "../../../utils/constants";
import Button from "./Button";
import { useTour } from "@reactour/tour";
import { STEPS_PUBLICATION_FUNCTIONALITY } from "../../../onboarding/steps";
import {
  PUBLICATIONS_STATUS_FEEDBACK,
  PUBLICATIONS_STATUS_PENDING_ANALYTICS,
  PUBLICATIONS_STATUS_SENT,
  PUBLICATIONS_STATUS_VERIFIED,
  PUBLICATION_STATUS_PENDING,
  PUBLICATION_STATUS_PENDING_APPROVAL,
  PUBLICATION_STATUS_PENDING_CONTENT,
  PUBLICATION_STATUS_PENDING_SOCIAL,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const states = {
  [POST_STATUS_PENDING_CONTENT]: {
    textButton: PUBLICATION_STATUS_PENDING_CONTENT,
    variant: "text",
    textDecoration: "underline",
    fontWeight: 600,
    clickable: true,
  },
  [POST_STATUS_PENDING_APPROVAL]: {
    textButton: PUBLICATION_STATUS_PENDING_APPROVAL,
    color: RED_COLOR,
    variant: "contained",
    textDecoration: "none",
    fontWeight: 600,
    clickable: true,
  },
  pending: {
    textButton: PUBLICATION_STATUS_PENDING,
    variant: "text",
    textDecoration: "underline",
    fontWeight: 600,
    clickable: true,
  },
  pendingSocial: {
    textButton: PUBLICATION_STATUS_PENDING_SOCIAL,
    variant: "text",
    textDecoration: "underline",
    fontWeight: 600,
    clickable: true,
  },
  pendingAnalytics: {
    textButton: PUBLICATIONS_STATUS_PENDING_ANALYTICS,
    variant: "text",
    textDecoration: "underline",
    fontWeight: 600,
    clickable: true,
  },
  sent: {
    textButton: PUBLICATIONS_STATUS_SENT,
    color: RED_COLOR,
    variant: "contained",
    textDecoration: "none",
    fontWeight: 600,
    clickable: true,
  },
  negativeReview: {
    textButton: PUBLICATIONS_STATUS_FEEDBACK,
    color: RED_COLOR,
    variant: "text",
    textDecoration: "underline",
    fontWeight: 600,
    clickable: true,
  },
  reviewed: {
    textButton: PUBLICATIONS_STATUS_VERIFIED,
    variant: "text",
    textDecoration: "underline",
    fontWeight: 600,
    clickable: true,
  },
};

const ButtonStatus = ({ status, onClick = () => {} }) => {
  const { textButton, color, variant, textDecoration, fontWeight } =
    states[status];
  const { t } = useTranslationApp();

  const isButtonContained = variant === "contained";

  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={status === POST_STATUS_SENT ? "error" : "inherit"}
      component="p"
      disableTouchRipple={true}
      hasShadow={variant === "contained" ? true : false}
      sx={{
        textDecoration: textDecoration,
        fontWeight: fontWeight,
        backgroundColor: isButtonContained && RED_COLOR,
        cursor: "pointer",
        color: `${isButtonContained ? "white" : color} !important`,
        "&:hover": {
          textDecoration: !isButtonContained && "underline",
          backgroundColor: !isButtonContained ? "transparent" : RED_COLOR,
        },
      }}
    >
      {t(textButton)}
    </Button>
  );
};
const StatusButton = ({
  status = "pending",
  orderID,
  from = "user",
  onSelectOrder,
}) => {
  const { isOpen, setSteps } = useTour();

  return (
    <React.Fragment>
      {from === "user" ? (
        <ButtonStatus
          status={status}
          onClick={() => {
            if (isOpen) {
              setSteps(STEPS_PUBLICATION_FUNCTIONALITY);
            } else {
              onSelectOrder(orderID);
            }
          }}
        />
      ) : (
        <Link
          to={`/content?orderID=${orderID}`}
          onClick={() => {
            if (isOpen) {
              setSteps(STEPS_PUBLICATION_FUNCTIONALITY);
            }
          }}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ButtonStatus status={status} />
        </Link>
      )}
    </React.Fragment>
  );
};

export default StatusButton;
