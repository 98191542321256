import {
  endAt,
  get,
  limitToFirst,
  orderByChild,
  query,
  ref as refDB,
  startAfter,
  startAt,
} from "firebase/database";
import { db } from "../firebase";
import {
  deleteDuplicateElementsByArray,
  getObjectError,
} from "../utils/formats";
import { SimpleAlert } from "../utils/alerts";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";
import types from "../types";

export const startGetAdvertisers =
  ({ limit, onChangeLastKey, lastKey, onCallbackFinish = () => {} }) =>
  async (dispatch) => {
    try {
      let q;
      if (limit) {
        q = lastKey
          ? query(
              refDB(db, `advertisers`),
              orderByChild("creationTimeNegative"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              refDB(db, `advertisers`),
              orderByChild("creationTimeNegative"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          refDB(db, `advertisers`),
          orderByChild("creationTimeNegative")
        );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        lastKey ? getNextAdvertisers({}) : dispatch(getAdvertisers({}));
        return true;
      }

      const advertisers = snapshot.val() || {};
      const advertisersKeys = Object.keys(advertisers);
      const advertiserUserIDsGet = [];
      advertisersKeys.forEach((advertiserID) => {
        const advertiser = advertisers[advertiserID];

        const userID = Object.keys(advertiser.users)[0];
        advertiserUserIDsGet.push({
          advertiserID,
          userID,
        });
      });

      await dispatch(
        startGetAdvertiserUsersByID({ advertiserUserIDs: advertiserUserIDsGet })
      );

      const newLastKey = advertisersKeys[0];
      onChangeLastKey(advertisers[newLastKey].creationTimeNegative);
      onCallbackFinish();
      dispatch(getNextAdvertisers(advertisers));

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAdvertisersByID =
  ({ advertiserIDs }) =>
  async (dispatch, getState) => {
    try {
      const currentAdvertisers = getState().advertisers.advertisers;

      const currentAdvertisersKeys = Object.keys(currentAdvertisers);
      advertiserIDs = advertiserIDs.filter((item, index) => {
        return advertiserIDs.indexOf(item) === index;
      });

      const queriesAdvertisers = [];

      advertiserIDs.forEach((advertiserID) => {
        if (currentAdvertisersKeys.includes(advertiserID)) return;
        queriesAdvertisers.push(get(refDB(db, `advertisers/${advertiserID}`)));
      });
      const advertiserUserIDsGet = [];

      const advertisersSnapshots = await Promise.all(queriesAdvertisers);

      let advertisers = {};
      advertisersSnapshots.forEach((advertiserSnapshot) => {
        if (!advertiserSnapshot.exists()) return;
        const advertiser = advertiserSnapshot.val();
        advertisers = {
          ...advertisers,
          [advertiserSnapshot.key]: advertiser,
        };
        const userID = Object.keys(advertiser.users)[0];
        advertiserUserIDsGet.push({
          advertiserID: advertiserSnapshot.key,
          userID,
        });
      });

      await dispatch(
        startGetAdvertiserUsersByID({ advertiserUserIDs: advertiserUserIDsGet })
      );

      dispatch(getNextAdvertisers(advertisers));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const startGetAdvertiserUsersByID =
  ({ advertiserUserIDs }) =>
  async (dispatch) => {
    try {
      const queriesUsers = [];
      advertiserUserIDs.forEach((item) => {
        queriesUsers.push(
          get(refDB(db, `adsUsers/${item.advertiserID}/${item.userID}`))
        );
      });
      const usersSnapshots = await Promise.all(queriesUsers);
      let users = {};
      usersSnapshots.forEach((userSnapshot) => {
        if (!userSnapshot.exists()) return;
        const user = userSnapshot.val();
        users = {
          ...users,
          [userSnapshot.key]: user,
        };
      });
      dispatch(getAdvertiserUsers(users));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetAdvertisersBusinessNameByIDs =
  ({ advertiserIDs }) =>
  async (dispatch) => {
    try {
      advertiserIDs = deleteDuplicateElementsByArray(advertiserIDs);

      const queriesAdvertisers = [];
      advertiserIDs.forEach((advertiserID) => {
        queriesAdvertisers.push(
          get(refDB(db, `advertisers/${advertiserID}/businessName`))
        );
      });
      const advertisersSnapshots = await Promise.all(queriesAdvertisers);
      let advertisers = {};
      advertisersSnapshots.forEach((advertiserSnapshot, index) => {
        if (!advertiserSnapshot.exists()) return;
        const businessName = advertiserSnapshot.val();

        advertisers = {
          ...advertisers,
          [advertiserIDs[index]]: { businessName },
        };
      });
      dispatch(getNextAdvertisers(advertisers));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAdvertisersByBusinessName =
  (textQuery) => async (dispatch) => {
    try {
      const dbRef = refDB(db, "advertisers");
      const q = query(
        dbRef,
        orderByChild("businessName"),
        startAt(textQuery),
        endAt(textQuery + "\uf8ff")
      );
      const snapshot = await get(q);
      if (snapshot.exists()) {
        const advertisers = snapshot.val();
        return advertisers;
      }
      return {};
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const getAdvertiserUsers = (data) => ({
  type: types.GET_ADVERTISER_USERS,
  payload: data,
});
const getAdvertisers = (data) => ({
  type: types.GET_ADVERTISERS,
  payload: data,
});
const getNextAdvertisers = (data) => ({
  type: types.GET_NEXT_ADVERTISERS,
  payload: data,
});
