import React, { useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Counter from "../Counters/Counter";
import RadioGroupCards from "../Stripe/RadioGroupCards";
import { startPayClappCredits } from "../../../actions/payments";
import { SimpleAlert } from "../../../utils/alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import { getAdditionalCredits } from "../../../utils/formats";
import StripePayForm from "../Stripe/StripePayForm";
import { RED_COLOR } from "../../../utils/colors";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { onInit3DSecureAuth } from "../../../services/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import {
  MODAL_BUY_CREDITS_ADDITIONAL_CREDITS,
  MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE,
  MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED,
  MODAL_BUY_CREDITS_ARIA_LABEL,
  MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION,
  MODAL_BUY_CREDITS_DESCRIPTION,
  MODAL_BUY_CREDITS_GIFT_CREDITS,
  MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY,
  MODAL_BUY_CREDITS_LAST_TEXT_PAY,
  MODAL_BUY_CREDITS_NEGATIVE,
  MODAL_BUY_CREDITS_PURCHASED,
  MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION,
  MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME,
  PAY,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocale } from "../../../Contexts/LocaleContext";
import { getNameBusinessTheme } from "../../../actions/getters";
import useMediaQuery from "@mui/material/useMediaQuery";

const ModalBuyCredits = ({ modalOpen, onCloseModal }) => {
  const [error, setError] = useState(null);
  const [card, setCard] = useState(0);
  const [credits, setCredits] = useState(0);

  const dispatch = useDispatchApp();
  const stripe = useStripe();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const LOCALE = useLocale();

  const { costCollaboration } = useSelectorApp((state) => state.shop);
  const { subscriptionPlanInfo } = useSelectorApp((state) => state.payments);

  const {
    defaultPaymentMethod: { id },
  } = useSelectorApp((state) => state.payments.stripe);

  const additionalCredits = getAdditionalCredits(credits);

  const onPayCredits = async (paymentMethodID = card, paymentIntent) => {
    if (paymentMethodID.length === 0 || credits.length === 0)
      return setError(t(MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED));
    if (credits < 1) return setError(t(MODAL_BUY_CREDITS_NEGATIVE));
    setError(null);
    const response = await dispatch(
      startPayClappCredits({
        paymentMethodID,
        credits,
        paymentIntent,
      })
    );
    if (response.ok) {
      SimpleAlert({
        title: t(MODAL_BUY_CREDITS_PURCHASED),
        text: t(MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();

      return { ok: true };
    }

    if (!response.ok && response.status === "requiresAction") {
      const responseVerification = await onInit3DSecureAuth({
        clientSecret: response.clientSecret,
        stripe,
        onCallbackFailed: onCloseModal,
      });
      if (responseVerification.ok) {
        const paymentIntent = responseVerification.paymentIntent;
        await onPayCredits(paymentMethodID, paymentIntent);
        return { ok: true };
      }
    }
    return { ok: false };
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_BUY_CREDITS_ARIA_LABEL, {
        NAME_BUSINESS,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" align="center" sx={{ fontWeight: 600 }}>
            {t(MODAL_BUY_CREDITS_DESCRIPTION)}
          </Typography>
          <Typography variant="body2" align="center">
            {t(MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION, {
              value: costCollaboration,
              currency: subscriptionPlanInfo?.currency || LOCALE.currency,
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" align="center">
            {t(MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY)}
          </Typography>
          <PromotionsText
            credits={credits}
            costCollaboration={costCollaboration}
          />
          <Counter
            value={credits}
            onChangeValue={setCredits}
            minValue={0}
            maxValue={15}
          />
          <Typography variant="body2" sx={{ mt: 2 }} align="center">
            {t(MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME, {
              value: credits / 2,
            })}
          </Typography>
          {additionalCredits > 0 && (
            <Typography
              variant="body2"
              sx={{ mb: 2, color: RED_COLOR }}
              align="center"
            >
              {t(MODAL_BUY_CREDITS_ADDITIONAL_CREDITS, {
                value: additionalCredits,
              })}
            </Typography>
          )}
        </Box>
        {!!error && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Alert
              severity="error"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                align: "center",
              }}
            >
              {error}
            </Alert>
          </Box>
        )}
        {id.length === 0 ? (
          <StripePayForm
            textButton={t(PAY)}
            onSubmit={(paymentMethod) => onPayCredits(paymentMethod.id)}
            saveCard={false}
            setDefaultCard={false}
            externalValidation={credits === 0}
            lastText={t(MODAL_BUY_CREDITS_LAST_TEXT_PAY, {
              value: costCollaboration * credits,
              currency: subscriptionPlanInfo?.currency || LOCALE.currency,
            })}
          />
        ) : (
          <>
            <RadioGroupCards
              onSelectCard={(paymentMethod) => setCard(paymentMethod)}
              onCallbackMakeDefaultPaymentMethod={onCloseModal}
              showMakeDefaultPaymentMethod={false}
              showCustomButton={true}
              customButtonText={PAY}
              onCallbackCustomButtonText={() => onPayCredits()}
            />
          </>
        )}
      </Box>
      {id.length > 0 && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            mb: 1,
          }}
        >
          <Typography variant={isMobile ? "body2" : "body1"}>
            {t(MODAL_BUY_CREDITS_LAST_TEXT_PAY, {
              value: costCollaboration * credits,
              currency: subscriptionPlanInfo?.currency || LOCALE.currency,
            })}
          </Typography>
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

const PromotionsText = ({ credits, costCollaboration }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const { subscriptionPlanInfo } = useSelectorApp((state) => state.payments);

  if (credits >= 0 && credits <= 4)
    return (
      <Box>
        <Typography variant="body2" align="center">
          {t(MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE, {
            credits: 5,
            value: 5 * costCollaboration,
            currency: subscriptionPlanInfo?.currency || LOCALE.currency,
          })}
        </Typography>
        <Typography variant="body2" align="center" fontWeight="bold">
          {t(MODAL_BUY_CREDITS_GIFT_CREDITS, {
            creditsGift: 1,
            creditsBuy: 5,
          })}
        </Typography>
      </Box>
    );
  if (credits >= 5 && credits <= 9)
    return (
      <Box>
        <Typography variant="body2" align="center">
          {t(MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE, {
            credits: 10,
            value: 10 * costCollaboration,
            currency: subscriptionPlanInfo?.currency || LOCALE.currency,
          })}
        </Typography>
        <Typography variant="body2" align="center" fontWeight="bold">
          {t(MODAL_BUY_CREDITS_GIFT_CREDITS, {
            creditsGift: 2,
            creditsBuy: 10,
          })}
        </Typography>
      </Box>
    );
  if (credits >= 10)
    return (
      <Box>
        <Typography variant="body2" align="center">
          {t(MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE, {
            credits: 15,
            value: 15 * costCollaboration,
            currency: subscriptionPlanInfo?.currency || LOCALE.currency,
          })}
        </Typography>
        <Typography variant="body2" align="center" fontWeight="bold">
          {t(MODAL_BUY_CREDITS_GIFT_CREDITS, {
            creditsGift: 3,
            creditsBuy: 15,
          })}
        </Typography>
      </Box>
    );
};
export default ModalBuyCredits;
