import React, { useState } from "react";
import Box from "@mui/material/Box";
import PreviousButtonStep from "./PreviousButtonStep";
import NextButtonStep from "./NextButtonStep";
import { CAMPAIGN_STATUS_DRAFT } from "../../../../utils/constants";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_ADVANCED_CONFIGURATION,
  BUTTON_FINISH,
  BUTTON_NEXT,
  BUTTON_PREVIOUS,
} from "../../../../locales/keysTranslations";
import LoadingButton from "../../Buttons/LoadingButton";
import Loader from "../../Loader/Loader";

const ActionsButtons = ({
  onPreviousStep,
  onNextStep,
  onFinish,
  valueDraft = CAMPAIGN_STATUS_DRAFT,
  valueFinish = CAMPAIGN_STATUS_DRAFT,
  customComponentPreviousSave,
  customComponentPreviousNext,
  showSaveButton = false,
  draftButtonText = BUTTON_FINISH,
  saveButtonText = BUTTON_FINISH,
  hasAdvancedSettings = false,
  showDraftButton = false,
  showNextButton = true,
  showPreviousButton = true,
  colorNextButton = "secondary",
  showLoadingComponent = false,
  customComponentLoading,
}) => {
  const [loadingFinish, setLoadingFinish] = useState(false);
  const { t } = useTranslationApp();

  if (showLoadingComponent && loadingFinish) {
    if (customComponentLoading) {
      return customComponentLoading;
    }
    return <Loader size={40} fullWidth />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap:
          hasAdvancedSettings || (showNextButton && showSaveButton)
            ? "wrap"
            : "nowrap",
        gap: { xs: 1, sm: 2 },
        alignItems: "flex-start",
        mt: 1,
        mb: 2,
      }}
    >
      {showPreviousButton && (
        <PreviousButtonStep
          sx={{ mt: 2, minWidth: 120 }}
          onPreviousStep={onPreviousStep}
        >
          {t(BUTTON_PREVIOUS)}
        </PreviousButtonStep>
      )}
      {hasAdvancedSettings && (
        <NextButtonStep
          sx={{ mt: 2, minWidth: 120 }}
          color="primary"
          onNextStep={onNextStep}
        >
          {t(BUTTON_ADVANCED_CONFIGURATION)}
        </NextButtonStep>
      )}
      {customComponentPreviousNext && customComponentPreviousNext}
      {showNextButton && (
        <NextButtonStep
          sx={{ mt: 2, minWidth: 120 }}
          color={colorNextButton}
          onNextStep={onNextStep}
        >
          {t(BUTTON_NEXT)}
        </NextButtonStep>
      )}
      {customComponentPreviousSave && customComponentPreviousSave}
      {showDraftButton && (
        <LoadingButton
          loading={loadingFinish}
          onClick={async () => {
            setLoadingFinish(true);
            await onFinish(valueDraft);
            setLoadingFinish(false);
          }}
          sx={{ mt: 2, minWidth: 120, height: 40 }}
          variant="contained"
          type="button"
        >
          {t(draftButtonText)}
        </LoadingButton>
      )}
      {showSaveButton && (
        <LoadingButton
          loading={loadingFinish}
          onClick={async () => {
            setLoadingFinish(true);
            await onFinish(valueFinish);
            setLoadingFinish(false);
          }}
          sx={{ mt: 2, minWidth: 120, height: 40 }}
          variant="contained"
          color="secondary"
          type="button"
        >
          {t(saveButtonText)}
        </LoadingButton>
      )}
    </Box>
  );
};

export default ActionsButtons;
