import { ref, get } from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import { validatePlanShop } from "./shops";
import {
  getBillingUrlMeta,
  getBillingUrlTikTokBusiness,
  getShopID,
} from "./getters";
import { getObjectError } from "../utils/formats";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import i18next from "i18next";
import {
  ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM,
  BUTTON_RECHARGE_BALANCE,
  CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  INFORMATION,
} from "../locales/keysTranslations";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_INFO,
  META,
  TIKTOK,
} from "../utils/constants";
import { renewToken } from "./auth";
import axios from "../lib/axios";
import { openLink } from "../utils/urls";

const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startGetRecommendedSettingsAdsCampaign =
  (campaign) => async (dispatch) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop) return false;

      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/generateRecommendedSettingsAdsCampaign`,
        data: campaign,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }
  };
export const startCreateAdsCampaign =
  (campaign) => async (dispatch, getState) => {
    try {
      const validationShop = dispatch(validatePlanShop());
      if (!validationShop) return false;

      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/createAdsCampaignPlatforms`,
        data: campaign,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        const campaignData = data?.data;
        const campaignID = campaignData.id;
        return campaignID;
      }
      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);

      if (
        errorFormatted.code === CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE ||
        errorFormatted.code === ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE
      ) {
        const result = await ConfirmAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
          confirmButtonText: i18next.t(BUTTON_RECHARGE_BALANCE),
        });
        if (result.isConfirmed) {
          const platform = campaign.platform;

          const billingURL = dispatch(
            platform === META
              ? getBillingUrlMeta()
              : getBillingUrlTikTokBusiness()
          );

          openLink(billingURL);
        }
      } else {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
      return false;
    }
  };

export const startGetAdsCampaigns = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `adsCampaigns/${shopID}`);

    const snapshot = await get(dbRef);
    const data = snapshot.val();

    dispatch(getAdsCampaigns(data));

    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetAdsCampaign = (campaignID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `adsCampaigns/${shopID}/${campaignID}`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      return false;
    }

    const data = snapshot.val();

    dispatch(
      getAdsCampaigns({
        [campaignID]: data,
      })
    );

    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const getAdsCampaigns = (data) => ({
  type: types.GET_ADS_CAMPAIGNS_FINISH,
  payload: data,
});

export const startSaveAdsCampaign =
  ({ campaign, campaignID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/updateAdsCampaignPlatforms`,
        data: {
          ...campaign,
          campaignID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        dispatch(
          saveAdsCampaign({
            key: campaignID,
            data,
          })
        );
      }

      return data.ok;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (
        errorFormatted.code === CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE ||
        errorFormatted.code === ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE
      ) {
        const result = await ConfirmAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
          confirmButtonText: i18next.t(BUTTON_RECHARGE_BALANCE),
        });
        if (result.isConfirmed) {
          const platform = campaign.platform;

          const billingURL = dispatch(
            platform === META
              ? getBillingUrlMeta()
              : getBillingUrlTikTokBusiness()
          );

          openLink(billingURL);
        }
      } else {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
      return false;
    }
  };
export const startChangeStatusAdsCampaign =
  ({ status, platform, campaignID }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const signUpCountry = getState().shop.signUpCountry;

      const { data } = await axios({
        method: "post",
        url: `${url}/updateAdsCampaignPlatforms`,
        data: {
          status,
          campaignID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        dispatch(
          saveAdsCampaign({
            key: campaignID,
            data: {
              status,
              statusAndCountry: `${status}-${signUpCountry}`,
            },
          })
        );
      }

      return data.ok;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (
        errorFormatted.code === CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE ||
        errorFormatted.code === ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE
      ) {
        const result = await ConfirmAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
          confirmButtonText: i18next.t(BUTTON_RECHARGE_BALANCE),
        });
        if (result.isConfirmed) {
          const billingURL = dispatch(
            platform === META
              ? getBillingUrlMeta()
              : getBillingUrlTikTokBusiness()
          );

          openLink(billingURL);
          SimpleAlert({
            title: i18next.t(INFORMATION),
            text: i18next.t(ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM),
            icon: ALERT_ICON_TYPE_INFO,
          });
        }
      } else {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
      return false;
    }
  };

const saveAdsCampaign = (data) => ({
  type: types.SAVE_ADS_CAMPAIGN_FINISH,
  payload: data,
});

export const generateAdsCampaignDescription =
  (campaign) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/generateAdsCampaignDescription`,
        data: campaign,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        return result?.data?.description;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: result.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const cleanAdsCampaigns = () => ({
  type: types.CLEAN_ADS_CAMPAIGNS,
});

export const startGetAdsCampaignSegmentation =
  ({ keyWord, type, platform, objective }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getAdsCampaignSegmentation`,
        data: {
          keyWords: [keyWord],
          type,
          platform,
          objective,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data.data;
      }

      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAdsCampaignTrackingIDs =
  ({ platform }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getAdsCampaignTrackingIDs`,
        data: { platform },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data.data;
      }

      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startUpdateAdsCampaignsInformationFromPlatforms =
  ({ campaignIDs }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/updateAdsCampaignsInformationFromPlatforms`,
        data: { campaignIDs },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        campaignIDs.forEach((campaignID) => {
          dispatch(
            saveAdsCampaign({
              key: campaignID,
              data: data.data[campaignID],
            })
          );
        });

        return data.data;
      }

      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetCurrencyPlatform =
  ({ platform }) =>
  async (dispatch, getState) => {
    try {
      let currency;

      const integrations = getState()?.shop?.integrations;

      if (platform === META) {
        currency = integrations?.facebook?.currency;
      }
      if (platform === TIKTOK) {
        currency = integrations?.tiktokBusiness?.currency;
      }

      if (currency) {
        return currency;
      }

      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getCurrencyPlatform`,
        data: { platform },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data?.data?.currency;
      }

      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
