import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useDispatchApp } from "../../../../lib/redux";
import ContainerPaperSection from "../../Account/ContainerPaperSection";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  ADS_CAMPAIGN_MODE_EDIT,
  ADS_CAMPAIGN_MODE_PREVIEW,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ALERT_ICON_TYPE_SUCCESS,
} from "../../../../utils/constants";
import {
  BUTTON_FINISHED_CAMPAIGN,
  BUTTON_SAVE_CAMPAIGN,
  ADS_CAMPAIGN_HAS_BEEN_FINISHED,
  ADS_CAMPAIGN_HAS_BEEN_ACTIVATE,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
} from "../../../../locales/keysTranslations";
import LoadingButton from "../../../Common/Buttons/LoadingButton";
import { useTranslationApp } from "../../../../lib/i18next";
import AdsCampaignForm from "./AdsCampaignForm";
import {
  startSaveAdsCampaign,
  generateAdsCampaignDescription,
} from "../../../../actions/adsCampaigns";
import { SimpleAlert } from "../../../../utils/alerts";
import AdsCampaignReasoningContainer from "./AdsCampaignReasoningContainer";
import addDays from "date-fns/addDays";

const INITIAL_STATE = {
  name: "",
  callToAction: "",
  objective: "",
  destinationURL: "",
  trackingID: null,
  platform: "",
  typeBudget: "daily",
  startDate: new Date(),
  endDate: addDays(new Date(), 1),
  budget: "",
  ageRange: [20, 65],
  genders: {
    male: false,
    female: false,
  },
  locations: [],
  interests: [],
  behaviors: [],
  demographics: [],
  description: "",
  mediaUrl: "",
};
const AdsCampaignInformation = ({
  data = {},
  mode,
  currency,
  onChangeData = () => {},
  onChangeMode = () => {},
  campaignID,
}) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState,
    getValues,
    reset,
    trigger,
    setFocus,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      ...data,
    },
  });
  const containerFormRef = useRef(null);
  const [isReasoning, setIsReasoning] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [newStatus, setNewStatus] = useState(false);
  const errors = formState.errors;

  const isModeEdit = mode === ADS_CAMPAIGN_MODE_EDIT;
  const isModePreview = mode === ADS_CAMPAIGN_MODE_PREVIEW;

  useFocusErrorForm(formState);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const hasStatistics = Object.values(data?.statistics || {})?.some(
    (statistic) => Number(statistic) > 1
  );

  useEffect(() => {
    const keys = Object.keys(data);

    keys.forEach((key) => {
      setValue(key, data[key]);
    });

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!isReasoning && isModePreview) {
      const container = containerFormRef.current;

      if (container) {
        window.scrollTo({
          top: container.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line
  }, [isReasoning]);

  const onSubmit = async (formValues) => {
    setIsFetching(true);
    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: {
          ...formValues,
          status: newStatus,
        },
        campaignID,
      })
    );
    if (response) {
      onChangeData({ ...formValues, status: newStatus });
      SimpleAlert({
        title:
          newStatus === ADS_CAMPAIGN_STATUS_ACTIVE
            ? t(ADS_CAMPAIGN_HAS_BEEN_ACTIVATE)
            : t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsFetching(false);
  };

  const onGenerateDescription = async () => {
    const formValues = getValues();
    const {
      name,
      objective,
      platform,
      ageRange,
      genders,
      locations,
      interests,
      behaviors,
      demographics,
    } = formValues;

    const response = await dispatch(
      generateAdsCampaignDescription({
        name,
        objective,
        platform,
        ageRange,
        genders,
        locations,
        interests,
        behaviors,
        demographics,
      })
    );

    if (response) {
      setValue("description", response);
    }
  };

  const showForm = isModeEdit || (isModePreview && !isReasoning);

  const isDisabledInput =
    (data.status === ADS_CAMPAIGN_STATUS_ACTIVE ||
      data.status === ADS_CAMPAIGN_STATUS_FINISHED) &&
    isModeEdit;

  return (
    <ContainerPaperSection
      hasAccordion={isModeEdit}
      hasDividers={isModeEdit}
      initialValueAccordion={!hasStatistics}
      showTopButton={false}
      showBottomButton={isModeEdit}
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        alignItems: "center",
        mt: 0,
        px: 0,
        py: 0,
      }}
      customBottomButton={
        <ButtonsActionsCampaign
          data={data}
          isFetching={isFetching}
          setIsFetching={setIsFetching}
          onSubmit={onSubmit}
          setNewStatus={setNewStatus}
        />
      }
    >
      {isModePreview && (
        <AdsCampaignReasoningContainer
          campaignID={campaignID}
          getCurrentData={getValues}
          validateFields={trigger}
          setFocus={setFocus}
          reasoning={data.reasoning}
          platform={data.platform}
          currency={currency}
          mode={mode}
          control={control}
          isDisabledInput={isDisabledInput}
          errors={errors}
          onChangeMode={onChangeMode}
          onCallbackFinishReasoning={() => setIsReasoning(false)}
          onCallbackFinishGenerateAgain={() => setIsReasoning(true)}
          onChangeData={(newData) => {
            reset((prev) => {
              return {
                ...prev,
                ...newData,
              };
            });
            onChangeData(newData);
          }}
        />
      )}
      {showForm && (
        <Box
          ref={containerFormRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <AdsCampaignForm
            mode={mode}
            control={control}
            getValues={getValues}
            errors={errors}
            watch={watch}
            setValue={setValue}
            currency={currency}
            isDisabledInput={isDisabledInput}
            onGenerateDescription={onGenerateDescription}
          />
        </Box>
      )}
    </ContainerPaperSection>
  );
};

const ButtonsActionsCampaign = ({ data, isFetching, setNewStatus }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "flex-end",
        gap: 2,
      }}
    >
      {data.status !== ADS_CAMPAIGN_STATUS_FINISHED ? (
        <LoadingButton
          loading={isFetching}
          variant="contained"
          disabled={data.status === ADS_CAMPAIGN_STATUS_FINISHED}
          onClick={() => {
            setNewStatus(ADS_CAMPAIGN_STATUS_DEACTIVATED);
          }}
          color="primary"
          type="submit"
          sx={{
            minWidth: 190,
          }}
        >
          {data.status === ADS_CAMPAIGN_STATUS_DEACTIVATED
            ? t(BUTTON_SAVE_CAMPAIGN)
            : t(BUTTON_FINISHED_CAMPAIGN)}
        </LoadingButton>
      ) : (
        <Typography variant="h6">
          {t(ADS_CAMPAIGN_HAS_BEEN_FINISHED)}
        </Typography>
      )}
    </Box>
  );
};
export default AdsCampaignInformation;
