import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { capitalizeFirstLetter } from "../../../../utils/formats";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  DELIVERY_DATA,
  DELIVERY_INTERN,
  GUIDE_NUMBER,
} from "../../../../locales/keysTranslations";

const DeliveryComing = ({ delivery }) => {
  const { t } = useTranslationApp();
  return (
    <Box>
      <Typography variant="body1" sx={{ color: "#757575" }}>
        {t(DELIVERY_DATA)}
      </Typography>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "background.paperSecondary",
          backgroundImage: "none",
          px: 2,
          py: { xs: 1, md: 2 },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: 1,
          }}
        >
          {delivery?.company ? (
            <>
              <Typography variant="h6">
                {`Delivery: ${capitalizeFirstLetter(
                  delivery.company === "interno"
                    ? t(DELIVERY_INTERN)
                    : delivery.company
                )}`}
              </Typography>
              {delivery.company !== "interno" && (
                <Typography variant="h6">
                  {`${t(GUIDE_NUMBER)}: ${delivery.guideNumber ?? "-"}`}
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="h6">
              {`Delivery: ${t(DELIVERY_INTERN)}`}
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DeliveryComing;
