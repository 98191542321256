import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import CardMedia from "../Media/CardMedia";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from "../Forms/Rating/Rating";

const ContainerOverlay = ({ children, sx }) => {
  return (
    <Box
      className="overlay"
      sx={{
        position: "absolute",
        background: "rgba(0, 0, 0, 0.6)",
        width: "100%",
        opacity: 0,
        transition: ".3s",
        px: 1,
        py: 0.5,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

const GalleryItem = ({
  item,
  reviews,
  index,
  onCloseModalGallery,
  onFailedLoadUrl,
  onSelectItem,
  onSelectPreview,
  selectedItems,
}) => {
  const { influencers } = useSelectorApp((state) => state.influencers);

  const review = reviews[item.reviewID];

  return (
    <Box
      sx={{
        cursor: "pointer",
        position: "relative",
        "&:hover .overlay": {
          opacity: 1,
        },
        aspectRatio: "1/1",
      }}
      onClick={() => onSelectPreview(index)}
    >
      <CardMedia
        url={item.url}
        onFailedLoadUrl={() => onFailedLoadUrl(index)}
        sx={{
          objectFit: "cover",
          borderRadius: 3,
          height: 400,
          width: "100%",
        }}
        propsType={{
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
        }}
      />
      <ContainerOverlay
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: review ? "space-between" : "flex-end",
          bottom: 0,
          left: 0,
        }}
      >
        {review && <Rating value={review.score} readOnly={true} />}
        <Typography variant="body1">
          {influencers[item.userID]?.mainAccountName}
        </Typography>
      </ContainerOverlay>
    </Box>
  );
};

export default GalleryItem;
