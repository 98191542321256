import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../../Common/SearchBar/SearchBar";
import Button from "../../../Common/Buttons/Button";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CREATE_CONTACT,
  FILTER,
  USERS_CONTACT_SEARCH_BAR_PLACEHOLDER,
} from "../../../../locales/keysTranslations";

const HeaderContactTab = ({
  onOpenModal,
  onChangeContactDataTable,
  contactData,
}) => {
  const { t } = useTranslationApp();

  const onSearchContact = (text) => {
    if (text.length > 0) {
      let filteredData = {};
      Object.keys(contactData).filter((key) => {
        if (contactData[key].name.toLowerCase().includes(text.toLowerCase())) {
          filteredData[key] = contactData[key];
        }
        return null;
      });
      onChangeContactDataTable(filteredData);
    } else {
      onChangeContactDataTable(contactData);
    }
  };
  const matches = useMediaQuery("(min-width:500px)");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: matches ? "row" : "column",
        gap: 1,
        maxWidth: 500,
        my: 2,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {t(FILTER)}
        </Typography>
        <SearchBar
          placeholder={t(USERS_CONTACT_SEARCH_BAR_PLACEHOLDER)}
          color="white"
          hasButton={false}
          onEditText={(text) => onSearchContact(text)}
        />
      </Box>
      <Button
        variant="contained"
        disableElevation
        fullWidth={!matches}
        color="secondary"
        sx={{ minWidth: 140 }}
        onClick={onOpenModal}
      >
        {t(BUTTON_CREATE_CONTACT)}
      </Button>
    </Box>
  );
};

export default HeaderContactTab;
