import XLSX from "xlsx";

const convertObjectFlat = (objectArray) => {
  return objectArray.map((object) => {
    const flatOrder = {};
    Object.entries(object).forEach(([key, value]) => {
      if (typeof value === "object") {
        flatOrder[key] = JSON.stringify(value);
      } else {
        flatOrder[key] = value;
      }
    });
    return flatOrder;
  });
};

export const generateXLSXShopReport = async ({
  orders,
  invites,
  invitesInactive,
  requests,
  nameFile,
}) => {
  const workbook = XLSX.utils.book_new();

  const ordersArray = Object.values(orders);
  const ordersFlatArray = convertObjectFlat(ordersArray);
  const worksheetOrders = XLSX.utils.json_to_sheet(ordersFlatArray);
  XLSX.utils.book_append_sheet(workbook, worksheetOrders, "Orders");

  const invitesArray = Object.values(invites);
  const invitesFlatArray = convertObjectFlat(invitesArray);
  const worksheetInvites = XLSX.utils.json_to_sheet(invitesFlatArray);
  XLSX.utils.book_append_sheet(workbook, worksheetInvites, "Invites");

  const invitesInactiveArray = Object.values(invitesInactive);
  const invitesInactiveFlatArray = convertObjectFlat(invitesInactiveArray);
  const worksheetInvitesInactive = XLSX.utils.json_to_sheet(
    invitesInactiveFlatArray
  );
  XLSX.utils.book_append_sheet(
    workbook,
    worksheetInvitesInactive,
    "Inactive Invites"
  );

  const requestsArray = Object.values(requests);
  const requestsFlatArray = convertObjectFlat(requestsArray);
  const worksheetRequests = XLSX.utils.json_to_sheet(requestsFlatArray);
  XLSX.utils.book_append_sheet(workbook, worksheetRequests, "Requests");

  const csvData = XLSX.write(workbook, { type: "array", bookType: "xlsx" });

  // Descargamos el archivo CSV
  const filename = `${nameFile}.xlsx`;
  const blob = new Blob([csvData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;",
  });

  if (navigator.msSaveBlob) {
    // Para IE
    navigator.msSaveBlob(blob, filename);
  } else {
    // Para otros navegadores
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // HTML5 link de descarga
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const generateXLSXDataRoom = async ({ data, nameFile, type }) => {
  const workbook = XLSX.utils.book_new();
  const dataArray = Object.values(data);
  const dataFlatArray = convertObjectFlat(dataArray);
  const worksheetData = XLSX.utils.json_to_sheet(dataFlatArray);
  XLSX.utils.book_append_sheet(workbook, worksheetData, `DataRoom ${type}`);

  const csvData = XLSX.write(workbook, { type: "array", bookType: "xlsx" });

  // Descargamos el archivo CSV
  const filename = `${nameFile}.xlsx`;
  const blob = new Blob([csvData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;",
  });

  if (navigator.msSaveBlob) {
    // Para IE
    navigator.msSaveBlob(blob, filename);
  } else {
    // Para otros navegadores
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // HTML5 link de descarga
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
