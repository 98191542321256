import types from "../types";
import { db } from "../firebase";
import { ref, get } from "firebase/database";
import { getObjectError } from "../utils/formats";
import { SimpleAlert } from "../utils/alerts";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";

export const startGetLocale = (locale) => async (dispatch, getState) => {
  try {
    const dbRef = ref(db, `locales/${locale}`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(getLocale({ locale, data }));
      return snapshot.val();
    } else {
      dispatch(getLocaleFinish());
      return {};
    }
  } catch (error) {
    dispatch(getLocaleFinish());
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getLocale = (data) => ({
  type: types.GET_LOCALE_FINISH,
  payload: data,
});
export const startGetLocalesList = (locale) => async (dispatch, getState) => {
  try {
    const dbRef = ref(db, `localesList`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(getLocalesList(data));
      return snapshot.val();
    } else {
      dispatch(getLocaleFinish());
      return {};
    }
  } catch (error) {
    dispatch(getLocaleFinish());
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getLocalesList = (data) => ({
  type: types.GET_LOCALES_LIST_FINISH,
  payload: data,
});

const getLocaleFinish = () => ({
  type: types.LOCALE_LOADING_FINISH,
});

export const setLocale = (data) => ({
  type: types.SET_LOCALE_FINISH,
  payload: data,
});
