import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Box from "@mui/material/Box";
import Loader from "../../components/Common/Loader/Loader";
import { DEFAULT_TABLE_PAGES } from "../../utils/constants";
import { startGetOrders } from "../../actions/orders";
import { usePagination } from "../../hooks/usePagination";
import ContentsTable from "../../components/Owner/Contents/ContentsTable";
import ContentsTypeFilter from "../../components/Owner/Contents/ContentsTypeFilter";
import TitlePage from "../../components/Common/Texts/TitlePage";
import {
  CONTENT_TITLE,
  LOADING_INFORMATION,
} from "../../locales/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const Contents = () => {
  const [data, setData] = useState({});
  const [contentType, setContentType] = useState(null);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const { orders, isLoading } = useSelectorApp((state) => state.orders);

  const getNextOrders = async () => {
    dispatch(
      startGetOrders({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(
      startGetOrders({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
      })
    );
  };

  const { rowsPerPage, page, lastKey, onChangeLastKey, onPageChange } =
    usePagination({
      rowsPerPageValue: DEFAULT_TABLE_PAGES,
      onCallBackNextPage: getNextOrders,
    });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(orders).length > 0) {
      let filteredData = {};
      Object.keys(orders).map((key) => {
        return (filteredData = {
          ...filteredData,
          [key]: {
            ...orders[key],
            date: new Date(orders[key].date),
          },
        });
      });
      setData(filteredData);
    }
    //eslint-disable-next-line
  }, [orders]);

  const onChangeContentType = (contentTypeSelected) => {
    onPageChange(0);
    if (contentTypeSelected === contentType) return setContentType(null);
    return setContentType(contentTypeSelected);
  };

  return (
    <ContainerPage>
      <TitlePage>{t(CONTENT_TITLE)}</TitlePage>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 3,
            minWidth: 200,
          }}
        >
          <ContentsTypeFilter
            contentType={contentType}
            onChangeContentType={onChangeContentType}
          />
        </Box>
        {isLoading ? (
          <Loader
            size={70}
            hasMessage={true}
            message={t(LOADING_INFORMATION)}
            variantMessage="h5"
            fullWidth={true}
          />
        ) : (
          <ContentsTable
            data={data}
            contentType={contentType}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        )}
      </Box>
    </ContainerPage>
  );
};

export default Contents;
