import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import TextField from "../../Fields/TextField";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import InfluencerNameLogo from "../../Influencer/InfluencerNameLogo";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  TIKTOK,
} from "../../../../utils/constants";
import Select from "../../Selects/Select";
import Checkbox from "../../Fields/Checkbox";
import {
  startGenerateVideoDescriptionTiktok,
  startGetCreatorConfigUpload,
  startUploadVideoTiktok,
} from "../../../../actions/shops";
import { useDispatch } from "react-redux";
import { ConfirmAlert, SimpleAlert } from "../../../../utils/alerts";
import { getInfluencerSocialLink } from "../../../../utils/urls";
import {
  BUTTON_CONTINUE,
  BUTTON_GENERATE_DESCRIPTION,
  BUTTON_UPLOAD_TIKTOK,
  BUTTON_VIEW_PROFILE,
  ERROR,
  FIELD_REQUIRED,
  LOADING_CONFIGURATION,
  MODAL_UPLOAD_FAILED_GET_CONFIGURATION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER,
  MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL,
  MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER,
  MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION,
  MODAL_UPLOAD_CONTENT_UPLOADED,
  MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import Loader from "../../Loader/Loader";
import themeDark from "../../Themes/themeDark";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import ButtonTikTokAction from "../../Buttons/ButtonTikTokAction";

const TiktokUploadForm = ({ url, metadata = {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingDescription, setIsFetchingDescription] = useState(false);
  const { control, handleSubmit, setValue, formState, watch } = useForm({
    defaultValues: {
      description: "",
      privacy: "PUBLIC_TO_EVERYONE",
      allow: {
        comments: true,
        duet: false,
        stitch: false,
      },
    },
  });
  const [creatorInfo, setCreatorInfo] = useState(null);
  const [error, setError] = useState(null);

  const description = watch("description");

  const outerTheme = useTheme();
  const darkTheme = themeDark(outerTheme);

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();

  const dispatch = useDispatch();

  const getInitialData = async () => {
    const response = await dispatch(startGetCreatorConfigUpload());
    if (!response) {
      setError(true);
      setIsLoading(false);
      return;
    }
    setCreatorInfo(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    setIsFetching(true);
    const response = await dispatch(startUploadVideoTiktok({ ...data, url }));
    setIsFetching(false);
    if (response) {
      const result = await ConfirmAlert({
        title: t(MODAL_UPLOAD_CONTENT_UPLOADED),
        text: t(MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
        cancelButtonText: t(BUTTON_CONTINUE),
        confirmButtonText: t(BUTTON_VIEW_PROFILE),
      });
      if (result.isConfirmed) {
        window.open(
          getInfluencerSocialLink({
            mainPlatform: TIKTOK,
            mainAccountName: creatorInfo?.creator_username,
          }),
          "_blank"
        );
      }
    }
  };

  const onGenerateDescription = async () => {
    if (!metadata.orderID)
      return SimpleAlert({
        title: t(ERROR),
        text: t(MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });

    setIsFetchingDescription(true);
    const response = await dispatch(
      startGenerateVideoDescriptionTiktok(metadata.orderID)
    );
    if (response) {
      setValue("description", response);
    }
    setIsFetchingDescription(false);
  };

  if (isLoading)
    return (
      <Loader
        fullWidth={true}
        hasMessage={true}
        message={t(LOADING_CONFIGURATION)}
        colorCustom="white"
        colorText="white"
      />
    );

  if (error && !isFetching)
    return (
      <Typography variant="body1" sx={{ maxWidth: "75ch" }}>
        {t(MODAL_UPLOAD_FAILED_GET_CONFIGURATION)}
      </Typography>
    );

  return (
    <Grid
      container
      direction="column"
      component="form"
      sx={{ width: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ThemeProvider theme={darkTheme}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <InfluencerNameLogo
              data={{
                mainPlatform: TIKTOK,
                mainAccountName: creatorInfo?.creator_username,
              }}
              colorText="white"
              sx={{ mb: 0 }}
            />
            <Avatar src={creatorInfo.creator_avatar_url} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            control={control}
            label={t(MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL)}
            name="description"
            placeholder={t(MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER)}
            InputLabelProps={{ shrink: true }}
            variant="filled"
            multiline={true}
            minRows={4}
            maxRows={6}
            fullWidth
            value={description}
            showCounterCharacters={true}
            maxCharacters={350}
            hasButtonGenerate={metadata?.orderID}
            alignButtonGenerate="top-right"
            propsButtonGenerate={{
              loading: isFetchingDescription,
              onClick: onGenerateDescription,
              text: BUTTON_GENERATE_DESCRIPTION,
            }}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Select
            control={control}
            label={t(MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL)}
            fullWidth
            name="privacy"
            variant="filled"
            backgroundColor="none"
            options={[
              {
                label: t(MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION),
                value: "PUBLIC_TO_EVERYONE",
              },
              {
                label: t(MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION),
                value: "MUTUAL_FOLLOW_FRIENDS",
              },
              {
                label: t(MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION),
                value: "SELF_ONLY",
              },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Checkbox
            control={control}
            name="allow"
            label={t(MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER)}
            fullWidth
            sxFormGroup={{
              display: "flex",
              flexDirection: "row",
            }}
            // value={{ comments: false, duet: false, stitch: false }}
            options={[
              {
                label: t(MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION),
                value: "comments",
                disabled: creatorInfo.comment_disabled,
              },
              {
                label: t(MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION),
                value: "duet",
                disabled: creatorInfo.duet_disabled,
              },
              {
                label: t(MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION),
                value: "stitch",
                disabled: creatorInfo.stitch_disabled,
              },
            ]}
            rules={{}}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, pb: 2 }}>
          <ButtonTikTokAction
            text={BUTTON_UPLOAD_TIKTOK}
            sx={{
              p: 4,
              fontSize: "1.1rem",
            }}
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};

export default TiktokUploadForm;
