import React, { useState } from "react";
import { useSelectorApp } from "../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import { DEFAULT_TABLE_PAGES } from "../../../utils/constants";
import Typography from "@mui/material/Typography";
import {
  capitalizeFirstLetter,
  getDeliveryStatusInfo,
} from "../../../utils/formats";
import ActionsDeliveriesOwner from "./ActionsDeliveriesOwner";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_ACCEPTED_DATE,
  COLUMN_NAME_CREATED_DATE,
  COLUMN_NAME_CREATOR,
  COLUMN_NAME_DELIVERY,
  COLUMN_NAME_ACTION,
  COLUMN_NAME_ID,
  COLUMN_NAME_SHOP,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_VALUE,
  EMPTY_RESULTS_SHOW,
  VALUE_WITH_CURRENCY,
  DATE_SHORT,
} from "../../../locales/keysTranslations";
import { useLocale } from "../../../Contexts/LocaleContext";
import LinkText from "../../Common/Texts/LinkText";

const DeliveriesOwnerTable = ({
  data,
  filter,
  rowsPerPage = DEFAULT_TABLE_PAGES,
  onSelectDelivery,
  onDeleteDelivery,
}) => {
  const [page, setPage] = useState(0);

  const keysSorted = Object.keys(data).sort(
    (a, b) =>
      new Date(data[b].date).getTime() - new Date(data[a].date).getTime()
  );
  const currentDataPage = keysSorted.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const { t } = useTranslationApp();
  const { influencers } = useSelectorApp((state) => state.influencers);
  const { shops } = useSelectorApp((state) => state.shop);
  const LOCALE = useLocale();

  const onChangePage = (newPage) => {
    setPage(newPage);
  };

  const currentKeysFiltered = currentDataPage.filter((key) => {
    const delivery = data[key];

    if (filter === "toBeAccepted" && delivery.status !== "toBeAccepted")
      return null;
    if (filter === "inPreparation" && delivery.status !== "inPreparation")
      return null;
    if (filter === "toBeDelivered" && delivery.status !== "toBeDelivered")
      return null;
    return key;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={false}
        emptyText={EMPTY_RESULTS_SHOW}
        additionalValidationEmpty={currentKeysFiltered.length === 0 && filter}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SHOP)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_VALUE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATED_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACCEPTED_DATE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_DELIVERY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? currentDataPage : keysSorted).map(
            (key, index) => {
              const delivery = data[key];

              if (
                filter === "toBeAccepted" &&
                delivery.status !== "toBeAccepted"
              )
                return null;
              if (
                filter === "inPreparation" &&
                delivery.status !== "inPreparation"
              )
                return null;
              if (
                filter === "toBeDelivered" &&
                delivery.status !== "toBeDelivered"
              )
                return null;

              return (
                <TableRow key={index}>
                  <TableCell align="center" indexRow={index}>
                    {key}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <LinkText
                      variant="body2"
                      href={`shop?shopID=${data[key].shopID}`}
                      target="_blank"
                    >
                      {shops[data[key].shopID]?.businessName ?? "-"}
                    </LinkText>
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <NameInfluencerCell
                      userID={data[key].userID}
                      data={influencers[data[key].userID]}
                      showButton={false}
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {t(getDeliveryStatusInfo(data[key].status).label)}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: data[key].totalPrice,
                      currency: LOCALE.currency,
                    })}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {t(DATE_SHORT, {
                      date: new Date(data[key].date),
                    })}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {data[key].dateOrderAccepted
                      ? t(DATE_SHORT, {
                          date: new Date(data[key].dateOrderAccepted),
                        })
                      : "-"}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <Typography variant="body2">
                      {capitalizeFirstLetter(
                        data[key]?.deliveryInfo?.company ?? "-"
                      )}
                    </Typography>
                    {data[key]?.deliveryInfo?.company !== "interno" && (
                      <Typography variant="body2">
                        {data[key]?.deliveryInfo?.guideNumber}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <ActionsDeliveriesOwner
                      id={key}
                      data={data[key]}
                      onSelectDelivery={onSelectDelivery}
                      onDeleteDelivery={onDeleteDelivery}
                    />
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default DeliveriesOwnerTable;
