import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { getExtensionFile } from "../../../utils/urls";
//use  videojs-contrib-quality-levels
import "videojs-contrib-quality-levels";
import "jb-videojs-hls-quality-selector";
import CircularProgress from "@mui/material/CircularProgress";

const sourceTypes = {
  mp4: "video/mp4",
  webm: "video/webm",
  ogg: "video/ogg",
  m3u8: "application/x-mpegURL",
  mov: "video/quicktime",
  mkv: "video/x-matroska",
  avi: "video/x-msvideo",
  flv: "video/x-flv",
  wmv: "video/x-ms-wmv",
  mpg: "video/mpeg",
  mpeg: "video/mpeg",
  m4v: "video/x-m4v",
  ts: "video/mp2t",
  vob: "video/x-ms-vob",
  swf: "application/x-shockwave-flash",
  rm: "application/vnd.rn-realmedia",
  rmvb: "application/vnd.rn-realmedia-vbr",
  divx: "video/x-msvideo",
  asf: "video/x-ms-asf",
  ogv: "video/ogg",
  webmv: "video/webm",
  m3u: "audio/x-mpegurl",
};

const VideoPlayer = React.memo(
  ({
    url = "",
    poster,
    controls = true,
    autoplay = true,
    muted = true,
    loop = false,
    fluid = true,
    aspectRatio = "16:9",
    bigPlayButton = true,
    className = "",
    onCallbackLoading = () => {},
    onReady = () => {},
    onPlay = () => {},
    onPause = () => {},
    onEnded = () => {},
    onSeeking = () => {},
    onSeeked = () => {},
    onTimeUpdate = () => {},
    onVolumeChange = () => {},
    onRateChange = () => {},
    onResize = () => {},
    onError = () => {},
    onFullscreenChange = () => {},
    onUserActive = () => {},
    onUserInactivity = () => {},
    onDispose = () => {},
    sx = {},
  }) => {
    const [playerInitialized, setPlayerInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const ext = getExtensionFile(url);

    useEffect(() => {
      if (!url) return;

      if (!playerInitialized) {
        if (videoRef?.current) {
          playerRef.current = videojs(
            videoRef?.current,
            {
              controls,
              autoplay,
              muted,
              loop,
              fill: true,
              playbackRates: [0.5, 1, 1.5, 2],
              sources: [
                {
                  src: url,
                  type: sourceTypes[ext] || "video/mp4",
                },
              ],
              plugins: {
                hlsQualitySelector: {
                  displayCurrentQuality: ext === "m3u8" ? false : true,
                },
              },
              html5: {
                hls: {
                  limitRenditionByPlayerDimensions: false,
                  useDevicePixelRatio: true,
                },
              },
              style: {
                background: "red",
              },
            },
            () => {
              setPlayerInitialized(true);
            }
          );
          playerRef.current.on("loadeddata", () => {
            setIsLoading(false);
          });

          playerRef.current.on("error", () => {
            setIsLoading(false);
          });
        }
      } else if (url) {
        playerRef?.current?.src(url);
      }
      // eslint-disable-next-line
    }, [videoRef, url]);

    useEffect(() => {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
      // eslint-disable-next-line
    }, [playerRef]);

    useEffect(() => {
      if (!playerInitialized) return;
    }, [playerInitialized]);

    useEffect(() => {
      onCallbackLoading(isLoading);
      // eslint-disable-next-line
    }, [isLoading]);

    const onErrorVideo = (e) => {
      onError(e);
      setIsLoading(false);
    };

    useEffect(() => {
      setIsLoading(true);
    }, [url]);

    return (
      <div
        data-vjs-player
        onClick={(e) => {
          const target = e.target;
          if (
            target.className === "vjs-icon-placeholder" ||
            target.className === "vjs-control-text"
          ) {
            e.stopPropagation();
          }
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <CircularProgress
              sx={{
                color: "white !important",
              }}
            />
          </Box>
        )}
        <Box
          component="video"
          ref={videoRef}
          playsInline
          className="video-js vjs-big-play-centered"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            overflow: "hidden",
            ...sx,
          }}
          onError={(e) => onErrorVideo(e)}
        />
      </div>
    );
  }
);

export default VideoPlayer;
