import logoYT from "../assets/img/logo-yt.png";
import logoIG from "../assets/img/logo-ig.webp";
import logoFB from "../assets/img/logo-fb.webp";
import logoIGWhite from "../assets/img/logo-ig-white.png";
import logoTikTok from "../assets/img/logo-tiktok.webp";
import logoTikTokBusiness from "../assets/img/logo-tiktok-business.webp";
import logoMeta from "../assets/img/logo-meta.webp";
import logoMetaWhite from "../assets/img/logo-meta-white.webp";
import {
  INSTAGRAM,
  INSTAGRAM_WHITE,
  TIKTOK,
  YOUTUBE,
  FACEBOOK,
  META,
  META_WHITE,
  TIKTOK_BUSINESS,
} from "./constants";

export const logoPlatform = (platform) => {
  switch (platform) {
    case TIKTOK:
      return logoTikTok;
    case TIKTOK_BUSINESS:
      return logoTikTokBusiness;
    case INSTAGRAM:
      return logoIG;
    case INSTAGRAM_WHITE:
      return logoIGWhite;
    case YOUTUBE:
      return logoYT;
    case FACEBOOK:
      return logoFB;
    case META:
      return logoMeta;
    case META_WHITE:
      return logoMetaWhite;

    default:
      break;
  }
};

export const getAnalyticsByUser = (user = {}) => {
  return {
    followers: user.followers || 0,
    engagement: user.engagement || 0,
    detailedAnalytics: user.detailedAnalytics || "",
  };
};
