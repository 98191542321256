import React, { useState } from "react";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import StripePayForm from "../Stripe/StripePayForm";
import RadioGroupCards from "../Stripe/RadioGroupCards";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { numberRounded } from "../../../utils/numberFormats";
import { startPayAmbassadorStripe } from "../../../actions/payments";
import {
  FEE_VALUE_DIRECT_PAYMENT,
  MIN_VALUE_PAY_AMBASSADOR,
} from "../../../utils/constants";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { onInit3DSecureAuth } from "../../../services/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_CANCEL,
  MODAL_PAY_AMBASSADORS_ARIAL_LABEL,
  MODAL_PAY_AMBASSADOR_AMOUNT_MIN,
  PAY,
  PAY_TO_AMBASSADORS,
  SERVICE_FEE,
  TOTAL_PAYMENT,
  VALUE_WITH_CURRENCY,
} from "../../../locales/keysTranslations";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalPayAmbassador = ({
  modalOpen,
  onCloseModal,
  usersIDs = [],
  isSpecificValue = true,
  amount = "",
  onCallbackPay = () => {},
}) => {
  const [amountValue, setAmountValue] = useState(amount);
  const [card, setCard] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslationApp();

  const LOCALE = useLocale();

  const dispatch = useDispatchApp();
  const stripe = useStripe();

  const {
    defaultPaymentMethod: { id },
  } = useSelectorApp((state) => state.payments.stripe);

  const onPayAmbassador = async (
    paymentMethodID = card,
    amountPay = amountValue,
    paymentIntent
  ) => {
    if (Number(amountValue) < MIN_VALUE_PAY_AMBASSADOR) {
      return setError(
        t(MODAL_PAY_AMBASSADOR_AMOUNT_MIN, {
          value: MIN_VALUE_PAY_AMBASSADOR,
          currency: LOCALE.currency,
        })
      );
    }
    setError(null);
    setLoading(true);
    const response = await dispatch(
      startPayAmbassadorStripe({
        paymentMethodID,
        amount: amountPay,
        usersIDs,
        paymentIntent,
      })
    );
    if (response.ok) {
      setLoading(false);
      onCloseModal();
      onCallbackPay();
      return { ok: true };
    }
    if (!response.ok && response.status === "requiresAction") {
      const responseVerification = await onInit3DSecureAuth({
        clientSecret: response.clientSecret,
        stripe,
        onCallbackFailed: onCloseModal,
      });
      if (responseVerification.ok) {
        const paymentIntent = responseVerification.paymentIntent;
        await onPayAmbassador(paymentMethodID, amountPay, paymentIntent);
        return { ok: true };
      }
    }
    setLoading(false);
    return { ok: false };
  };

  const FEE_VALUE_CALCULATE = numberRounded(
    amountValue * FEE_VALUE_DIRECT_PAYMENT
  );
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      ariaLabel={t(MODAL_PAY_AMBASSADORS_ARIAL_LABEL)}
    >
      {id.length === 0 ? (
        <StripePayForm
          textButton={t(PAY)}
          onSubmit={(paymentMethod) => onPayAmbassador(paymentMethod.id)}
          saveCard={false}
          setDefaultCard={false}
        />
      ) : (
        <RadioGroupCards
          isSpecificValue={isSpecificValue}
          error={error}
          onChangeAmount={(value) => setAmountValue(Number(value))}
          onSelectCard={(paymentMethod) => setCard(paymentMethod)}
          onCallbackMakeDefaultPaymentMethod={onCloseModal}
          showMakeDefaultPaymentMethod={false}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ mt: 1, fontWeight: 600 }}
        >
          {t(PAY_TO_AMBASSADORS)}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: Number(amountValue) * usersIDs.length,
            currency: "USD",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ mt: 1, fontWeight: 600 }}
        >
          {`${t(SERVICE_FEE)}:`}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: FEE_VALUE_CALCULATE * usersIDs.length,
            currency: "USD",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ mt: 1, fontWeight: 600 }}
        >
          {`${t(TOTAL_PAYMENT)}:`}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1 }}>
          {t(VALUE_WITH_CURRENCY, {
            value:
              (Number(amountValue) + FEE_VALUE_CALCULATE) * usersIDs.length,
            currency: "USD",
          })}
        </Typography>
      </Box>
      {id.length > 0 && (
        <DialogActions sx={{ p: 0 }}>
          <ContainerModalActions
            propsSecondaryButton={{
              showButton: true,
              text: BUTTON_CANCEL,
              onClick: onCloseModal,
              disabled: loading,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: PAY,
              onClick: () => onPayAmbassador(card, amountValue),
              disabled: !card || usersIDs.length === 0,
            }}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalPayAmbassador;
