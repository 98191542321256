import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GalleryContentItem from "./GalleryContentItem";
import Loader from "../../Common/Loader/Loader";

const GalleryContent = ({ data, lastItemRef, loadLastItem }) => {
  return (
    <>
      <Grid container spacing={2}>
        {data.map((item, index) => {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <GalleryContentItem item={item} />
              {data.length === index + 1 && <div ref={lastItemRef}></div>}
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 2 }}
      >
        {!loadLastItem && <Loader size={30} sx={{ mb: 2 }} />}
      </Box>
    </>
  );
};

export default GalleryContent;
