import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ScheduleTable from "./Schedule/ScheduleTable";
import {
  MAX_HOUR_TIME,
  SCHEDULE_EVENT_ADD_TURN,
  SCHEDULE_EVENT_DELETE_TURN,
  SCHEDULE_EVENT_EDIT_TURN,
} from "../../../utils/constants";
import { startSaveScheduleInfo } from "../../../actions/shops";
import Loader from "../../Common/Loader/Loader";
import ContainerPaperSection from "./ContainerPaperSection";
import { ConfirmAlert } from "../../../utils/alerts";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_BE_ECOMMERCE,
  BUTTON_NOT_BE_ECOMMERCE,
  FRIDAY,
  MONDAY,
  SATURDAY,
  SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION,
  SCHEDULE_ECOMMERCE_ALERT_TITLE,
  SCHEDULE_EMPTY_TURN,
  SCHEDULE_MAX_CLOSE_HOUR,
  SCHEDULE_ONE_DAY_WITH_TURN,
  SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION,
  SCHEDULE_TITLE,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
  YES,
} from "../../../locales/keysTranslations";
import _ from "lodash";

const INITIAL_STATE = [
  {
    day: MONDAY,
    turns: [],
  },
  {
    day: TUESDAY,
    turns: [],
  },
  {
    day: WEDNESDAY,
    turns: [],
  },
  {
    day: THURSDAY,
    turns: [],
  },
  {
    day: FRIDAY,
    turns: [],
  },
  {
    day: SATURDAY,
    turns: [],
  },
  {
    day: SUNDAY,
    turns: [],
  },
];

const ScheduleForm = ({
  elevation = 1,
  hasDividers = true,
  showBottomButton = true,
  modeContainer = "normal",
  onCallbackSave = () => {},
  customComponentButtons = null,
  sx = {},
  sxTable = {},
}) => {
  const [formValues, setFormValues] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isECommerce, setIsECommerce] = useState(false);

  const { t } = useTranslationApp();

  const scheduleData = useSelectorApp((state) => state.shop.schedule);
  const dispatch = useDispatchApp();

  useEffect(() => {
    const value = scheduleData ? _.cloneDeep(scheduleData) : INITIAL_STATE;
    setFormValues(value);
    if (value && value?.length > 0) {
      let isECommerce = true;
      value.forEach((item) => {
        if (!isECommerce) return;
        if (!item?.turns || item?.turns?.length === 0)
          return (isECommerce = false);

        item.turns.forEach((turn) => {
          if (turn.start !== "0000" || turn.end !== "2359")
            return (isECommerce = false);
        });
      });
      setIsECommerce(isECommerce);
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [scheduleData]);

  const onChangeForm = (e, { event, value }) => {
    if (event === SCHEDULE_EVENT_EDIT_TURN) {
      setFormValues(
        formValues.map((item) => {
          if (item.day === value.day) {
            item.turns.map((turn) => {
              if (turn.id === value.idturn) {
                return (turn[e.target.name] = e.target.value);
              }
              return turn;
            });
          }
          return item;
        })
      );
    } else if (event === SCHEDULE_EVENT_ADD_TURN) {
      setFormValues(
        formValues.map((item) => {
          if (item.day === value.day) {
            if (item?.turns?.length !== undefined) {
              item.turns.push({
                id: item.turns.length + 1,
                start: "",
                end: "",
              });
            } else {
              item.turns = [
                {
                  id: 1,
                  start: "",
                  end: "",
                },
              ];
            }
          }
          return item;
        })
      );
    } else if (event === SCHEDULE_EVENT_DELETE_TURN) {
      setFormValues(
        formValues.map((item) => {
          if (item.day === value.day && item.turns.length > 1) {
            item.turns = item.turns.filter((turn) => turn.id !== value.idturn);
          } else if (item.day === value.day && item.turns.length === 1) {
            item.turns = [];
          }
          return item;
        })
      );
    }
  };

  const onAddTurn = (day) => {
    onChangeForm(null, {
      event: SCHEDULE_EVENT_ADD_TURN,
      value: {
        day,
      },
    });
  };

  const onDeleteTurn = (day, idturn) => {
    onChangeForm(null, {
      event: SCHEDULE_EVENT_DELETE_TURN,
      value: {
        day,
        idturn,
      },
    });
  };

  const onSetECommerce = async () => {
    if (isECommerce) return setIsECommerce(false);
    const result = await ConfirmAlert({
      title: t(SCHEDULE_ECOMMERCE_ALERT_TITLE),
      text: t(SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION),
      confirmButtonText: t(YES),
    });
    if (!result.isConfirmed) return;
    setIsECommerce(true);
    const eCommerceSchedule = [
      {
        day: MONDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
      {
        day: TUESDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
      {
        day: WEDNESDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
      {
        day: THURSDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
      {
        day: FRIDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
      {
        day: SATURDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
      {
        day: SUNDAY,
        turns: [
          {
            id: 1,
            start: "0000",
            end: "2359",
          },
        ],
      },
    ];
    setError(null);
    dispatch(startSaveScheduleInfo(eCommerceSchedule));
    onCallbackSave();
    return;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isScheduleEmpty = true;
    let isEmptyAnyTurn = false;
    let isTurnStartOverEnd = false;
    let isTurnInvalid = false;
    formValues.map((item) => {
      if (item.turns?.length > 0) isScheduleEmpty = false;
      item?.turns?.map((turn) => {
        if (turn.start === "" || turn.end === "") {
          isEmptyAnyTurn = true;
        }
        if (turn.start > turn.end) {
          isTurnStartOverEnd = true;
        }
        if (turn.start > MAX_HOUR_TIME || turn.end > MAX_HOUR_TIME) {
          isTurnInvalid = true;
        }
        return turn;
      });
      return item;
    });
    if (isScheduleEmpty) {
      return setError(t(SCHEDULE_ONE_DAY_WITH_TURN));
    }
    if (isEmptyAnyTurn) {
      return setError(t(SCHEDULE_EMPTY_TURN));
    }
    if (isTurnStartOverEnd) {
      return setError(t(SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION));
    }
    if (isTurnInvalid) {
      return setError(t(SCHEDULE_MAX_CLOSE_HOUR));
    }
    setError(null);
    dispatch(startSaveScheduleInfo(formValues));
    onCallbackSave();
  };

  if (isLoading) return <Loader />;

  return (
    <ContainerPaperSection
      onSubmit={onSubmit}
      title={SCHEDULE_TITLE}
      buttonTopText={
        isECommerce ? BUTTON_NOT_BE_ECOMMERCE : BUTTON_BE_ECOMMERCE
      }
      mode={modeContainer}
      elevation={elevation}
      hasDividers={hasDividers}
      showBottomButton={showBottomButton}
      buttonTopOnClick={onSetECommerce}
      buttonTopType="button"
      buttonTopColor="secondary"
      className="schedule"
      sx={{
        overflow: "auto",
        ...sx,
      }}
    >
      {!!error && (
        <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
          <Alert severity="error" sx={{ justifyContent: "center" }}>
            {error}
          </Alert>
        </Box>
      )}
      <ScheduleTable
        data={formValues}
        onChangeForm={onChangeForm}
        onAddTurn={onAddTurn}
        onDeleteTurn={onDeleteTurn}
        isECommerce={isECommerce}
        sxTable={sxTable}
      />
      {customComponentButtons && customComponentButtons}
    </ContainerPaperSection>
  );
};

export default ScheduleForm;
