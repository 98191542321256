import React, { useEffect, useState } from "react";
import Button from "./Button";
import ModalAutomaticInvitesConfiguration from "../../User/Account/ProfileInfluencer/ModalAutomaticInvitesConfiguration";
import { useTranslationApp } from "../../../lib/i18next";
import { AUTOMATIC_INVITES_TITLE } from "../../../locales/keysTranslations";
import { useSelectorApp } from "../../../lib/redux";
import ModalAutomaticInvitesTypeForm from "../Modal/ModalAutomaticInvitesTypeForm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ButtonOpenModalAutomaticInvitesConfiguration = ({
  buttonText = AUTOMATIC_INVITES_TITLE,
  buttonTextInterpolation = {},
  color = "primary",
  sx = {},
  from = "invitations",
  onClick = () => {},
}) => {
  const [
    modalAutomaticInvitesConfigurationOpen,
    setModalAutomaticInvitesConfigurationOpen,
  ] = useState(false);
  const [
    modalAutomaticInvitesTypeFormOpen,
    setModalAutomaticInvitesTypeFormOpen,
  ] = useState(false);

  const { t } = useTranslationApp();
  const profileInfluencer = useSelectorApp(
    (state) => state?.shop?.profileInfluencer
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const focus = params.get("focus");

  const onClickButton = () => {
    let hasAutomaticInvitesInfo = true;

    if (
      !profileInfluencer?.numberInvitations ||
      !profileInfluencer?.budgetMonthly ||
      !profileInfluencer?.comment
    ) {
      hasAutomaticInvitesInfo = false;
    }
    if (
      profileInfluencer?.budgets?.length === 0 ||
      !profileInfluencer?.budgets
    ) {
      hasAutomaticInvitesInfo = false;
    }
    if (profileInfluencer?.budgets?.some((budget) => !budget?.value)) {
      hasAutomaticInvitesInfo = false;
    }
    if (
      !Object.values(profileInfluencer?.typeContent || {}).some((type) => type)
    ) {
      hasAutomaticInvitesInfo = false;
    }
    if (!Object.values(profileInfluencer?.formats || {}).some((type) => type)) {
      hasAutomaticInvitesInfo = false;
    }
    if (hasAutomaticInvitesInfo) {
      setModalAutomaticInvitesConfigurationOpen(true);
    } else {
      setModalAutomaticInvitesTypeFormOpen(true);
    }
  };

  useEffect(() => {
    if (!focus) return;
    navigate(location.pathname, {
      replace: true,
    });
    if (focus === "automaticInvitations") {
      onClickButton();
    }
    // eslint-disable-next-line
  }, [focus]);

  return (
    <>
      <Button
        color={color}
        sx={sx}
        onClick={() => {
          onClick();
          onClickButton();
        }}
      >
        {t(buttonText, {
          ...buttonTextInterpolation,
        })}
      </Button>
      {modalAutomaticInvitesConfigurationOpen && (
        <ModalAutomaticInvitesConfiguration
          modalOpen={modalAutomaticInvitesConfigurationOpen}
          from={from}
          onCloseModal={() => setModalAutomaticInvitesConfigurationOpen(false)}
        />
      )}
      {modalAutomaticInvitesTypeFormOpen && (
        <ModalAutomaticInvitesTypeForm
          modalOpen={modalAutomaticInvitesTypeFormOpen}
          onCloseModal={() => setModalAutomaticInvitesTypeFormOpen(false)}
          onCallbackSave={() => {
            setModalAutomaticInvitesTypeFormOpen(false);
            setModalAutomaticInvitesConfigurationOpen(true);
          }}
        />
      )}
    </>
  );
};

export default ButtonOpenModalAutomaticInvitesConfiguration;
