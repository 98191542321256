import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  POST_STATUS_ANALYTICS,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING,
  POST_STATUS_REVIEWED,
  POST_STATUS_SENT,
  STATUS_ORDERS,
} from "../../../utils/constants";
import PendingIcon from "@mui/icons-material/Pending";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SendIcon from "@mui/icons-material/Send";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslationApp } from "../../../lib/i18next";

const ContentsTypeFilter = ({ contentType, onChangeContentType }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      <Chip
        icon={<PendingIcon />}
        label={t(
          STATUS_ORDERS.find((s) => s.value === POST_STATUS_PENDING).label
        )}
        color={contentType === POST_STATUS_PENDING ? "primary" : "default"}
        onClick={() => {
          onChangeContentType(POST_STATUS_PENDING);
        }}
      />
      <Chip
        icon={<PendingActionsIcon />}
        label={t(
          STATUS_ORDERS.find((s) => s.value === POST_STATUS_ANALYTICS).label
        )}
        color={contentType === POST_STATUS_ANALYTICS ? "primary" : "default"}
        onClick={() => {
          onChangeContentType(POST_STATUS_ANALYTICS);
        }}
      />
      <Chip
        icon={<FeedbackIcon />}
        label={t(
          STATUS_ORDERS.find((s) => s.value === POST_STATUS_NEGATIVE_REVIEW)
            .label
        )}
        color={
          contentType === POST_STATUS_NEGATIVE_REVIEW ? "primary" : "default"
        }
        onClick={() => {
          onChangeContentType(POST_STATUS_NEGATIVE_REVIEW);
        }}
      />
      <Chip
        icon={<SendIcon />}
        label={t(STATUS_ORDERS.find((s) => s.value === POST_STATUS_SENT).label)}
        color={contentType === POST_STATUS_SENT ? "primary" : "default"}
        onClick={() => {
          onChangeContentType(POST_STATUS_SENT);
        }}
      />
      <Chip
        icon={<VerifiedIcon />}
        label={t(
          STATUS_ORDERS.find((s) => s.value === POST_STATUS_REVIEWED).label
        )}
        color={contentType === POST_STATUS_REVIEWED ? "primary" : "default"}
        onClick={() => {
          onChangeContentType(POST_STATUS_REVIEWED);
        }}
      />
    </Box>
  );
};

export default ContentsTypeFilter;
