import { get, ref } from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import { SimpleAlert } from "../utils/alerts";
import i18next from "i18next";
import { getObjectError } from "../utils/formats";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";

export const startGetBusinesses = () => async (dispatch) => {
  try {
    const dbRef = ref(db, `businesses`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const businesses = snapshot.val();
      dispatch(getBusinesses(businesses));
    } else {
      dispatch(getBusinesses({}));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const getBusinesses = (data) => ({
  type: types.GET_BUSINESSES_FINISH,
  payload: data,
});

export const createBusiness = (data) => ({
  type: types.CREATE_BUSINESS,
  payload: data,
});
