import React from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import TextField from "../../../Common/Fields/TextField";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION,
  AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER,
  BUTTON_GENERATE_SCRIPT,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
  INVITE_COMMENT_MAX_LENGTH,
  INVITE_FORM_COMMENT_HELPER_TEXT,
  INVITE_FORM_COMMENT_PLACEHOLDER_3,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
} from "../../../../locales/keysTranslations";
import { REGEX_URL } from "../../../../utils/regexsValidation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { INVITATION_COMMENT_MAX_LENGTH } from "../../../../utils/constants";

const ProfileInfluencerFormOptional = ({
  control,
  errors,
  isFetchingComment,
  onGenerateComment,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();
  return (
    <>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          sx={{
            fontWeight: 600,
          }}
        >
          {t(AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="comment"
          disabled={true}
          label={t(INVITE_FORM_COMMENT_HELPER_TEXT)}
          placeholder={t(INVITE_FORM_COMMENT_PLACEHOLDER_3)}
          multiline
          minRows={4}
          maxRows={4}
          variant="filled"
          fullWidth
          InputLabelProps={{
            sx: {
              textWrap: "wrap",
              "&.MuiInputLabel-shrink": {
                textWrap: "nowrap",
              },
            },
          }}
          alignButtonGenerate="bottom-right"
          propsButtonGenerate={{
            loading: isFetchingComment,
            onClick: onGenerateComment,
            text: BUTTON_GENERATE_SCRIPT,
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value?.length > INVITATION_COMMENT_MAX_LENGTH) {
                return t(INVITE_COMMENT_MAX_LENGTH, {
                  value: INVITATION_COMMENT_MAX_LENGTH,
                });
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" variant="standard" disabled={true}>
          <FormLabel component="legend" sx={{ fontWeight: 500 }}>
            {t(AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER)}
          </FormLabel>
          <FormGroup>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="formats"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value.instagramBurstStories}
                          name="instagramBurstStories"
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              instagramBurstStories: e.target.checked,
                            })
                          }
                        />
                      )}
                    />
                  }
                  label={t(INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="formats"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value.instagramPost}
                          name="instagramPost"
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              instagramPost: e.target.checked,
                            })
                          }
                        />
                      )}
                    />
                  }
                  label={t(INVITE_FORM_FORMAT_INSTAGRAM_POST)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="formats"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value.instagramReel}
                          name="instagramReel"
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              instagramReel: e.target.checked,
                            })
                          }
                        />
                      )}
                    />
                  }
                  label={t(INVITE_FORM_FORMAT_INSTAGRAM_REEL)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="formats"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value.instagramStory}
                          name="instagramStory"
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              instagramStory: e.target.checked,
                            })
                          }
                        />
                      )}
                    />
                  }
                  label={t(INVITE_FORM_FORMAT_INSTAGRAM_STORY)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="formats"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value.tiktokPost}
                          name="tiktokPost"
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              tiktokPost: e.target.checked,
                            })
                          }
                        />
                      )}
                    />
                  }
                  label={t(INVITE_FORM_FORMAT_TIKTOK_POST)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="formats"
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value.tiktokStory}
                          name="tiktokStory"
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              tiktokStory: e.target.checked,
                            })
                          }
                        />
                      )}
                    />
                  }
                  label={t(INVITE_FORM_FORMAT_TIKTOK_STORY)}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <TextField
          disabled={true}
          name="terms"
          label={t(AUTOMATIC_INVITES_OPTIONAL_TERMS_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          disabled={true}
          name="contentReference"
          label={t(
            AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER
          )}
          variant="filled"
          fullWidth={true}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value && !REGEX_URL.test(value)) {
                return t(FIELD_URL_VALID);
              }
            },
          }}
        />
      </Grid>
    </>
  );
};

export default ProfileInfluencerFormOptional;
