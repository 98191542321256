import { STEPS_MAIN_FUNCTIONALITY } from "../onboarding/steps";
import { STEPPER_MAIN_FUNCTIONALITY } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  isOpen: false,
  onboardingType: STEPPER_MAIN_FUNCTIONALITY,
  step: 0,
  steps: STEPS_MAIN_FUNCTIONALITY,
  showPrevButton: true,
  showNextButton: true,
  saveData: false,
  isNewBrand: false,
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OPEN_ONBOARDING:
      return {
        ...state,
        isOpen: action.payload,
      };
    case types.SET_STEP_ONBOARDING:
      return {
        ...state,
        step: action.payload,
      };
    case types.SET_STEPS_ONBOARDING:
      return {
        ...state,
        steps: action.payload,
      };
    case types.SET_IS_NEW_BRAND:
      return {
        ...state,
        isNewBrand: action.payload,
      };

    case types.CHANGE_SHOW_PREV_BUTTON:
      return {
        ...state,
        showPrevButton: action.payload,
      };

    case types.CHANGE_SHOW_NEXT_BUTTON:
      return {
        ...state,
        showNextButton: action.payload,
      };
    case types.CHANGE_SAVE_DATA_ONBOARDING:
      return {
        ...state,
        saveData: action.payload,
      };

    case types.GLOBAL_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
