import React, { useState } from "react";
import { useSelectorApp } from "../../../lib/redux";
import StripePayForm from "./StripePayForm";
import RadioGroupCards from "./RadioGroupCards";
import Box from "@mui/material/Box";
import { PAY } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ContainerStripeInformation = ({
  onCallback = () => {},
  externalValidation = false,
  onCallbackFailed3DSecure = () => {},
  loading,
  card,
  onChangeCard = () => {},
  isSpecificValue = false,
  amount,
  onChangeAmount = () => {},
  CustomComponent,
  CustomComponentDetailPayment,
}) => {
  const { t } = useTranslationApp();
  const {
    defaultPaymentMethod: { id },
  } = useSelectorApp((state) => state.payments.stripe);

  const [modePayment] = useState(
    id.length > 0 ? "hasPaymentMethod" : "noPaymentMethod"
  );
  return (
    <>
      {modePayment === "noPaymentMethod" ? (
        <StripePayForm
          textButton={t(PAY)}
          onSubmit={(paymentMethod, paymentIntent) => {
            onCallback(paymentMethod.id, paymentIntent);
            onChangeCard(paymentMethod.id);
          }}
          saveCard={true}
          setDefaultCard={true}
          amount={amount}
          onChangeAmount={onChangeAmount}
          isSpecificValue={isSpecificValue}
          externalValidation={externalValidation}
          sx={{ maxWidth: "sm" }}
          onCallbackFailed3DSecure={onCallbackFailed3DSecure}
          CustomComponent={CustomComponent}
          CustomComponentDetailPayment={CustomComponentDetailPayment}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <RadioGroupCards
            onSelectCard={onChangeCard}
            amount={amount}
            onChangeAmount={onChangeAmount}
            isSpecificValue={isSpecificValue}
            showMakeDefaultPaymentMethod={false}
            sx={{ maxWidth: "sm" }}
            minWidthButtons={130}
            CustomComponent={CustomComponent}
            CustomComponentDetailPayment={CustomComponentDetailPayment}
          />
          <ContainerModalActions
            sx={{
              mt: 2,
            }}
            hasPadding={false}
            propsPrimaryButton={{
              showButton: true,
              text: t(PAY),
              onClick: () => onCallback(),
              disabled: !card || externalValidation,
            }}
          />
          {/* <LoadingButton
            loading={loading}
            color="primary"
            onClick={() => onCallback()}
            disabled={!card || externalValidation}
            sx={{ mt: 2, minWidth: 130 }}
          >
            {t(PAY)}
          </LoadingButton> */}
        </Box>
      )}
    </>
  );
};

export default ContainerStripeInformation;
