import React, { useEffect, useState } from "react";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import TitlePage from "../components/Common/Texts/TitlePage";
import {
  BUTTON_CREATE,
  ADS_CAMPAIGNS_DESCRIPTION,
  ADS_CAMPAIGNS_TITLE,
  LOADING_INFORMATION,
  META_CAMPAIGN,
  // TIKTOK_CAMPAIGN,
} from "../locales/keysTranslations";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTranslationApp } from "../lib/i18next";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import AdsCampaignsList from "../components/User/AdsCampaigns/AdsCampaigns/AdsCampaignsList";
import Loader from "../components/Common/Loader/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  cleanAdsCampaigns,
  startGetAdsCampaigns,
  startChangeStatusAdsCampaign,
  startUpdateAdsCampaignsInformationFromPlatforms,
} from "../actions/adsCampaigns";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  META_WHITE,
  // TIKTOK,
} from "../utils/constants";
import ButtonMenu from "../components/Common/Buttons/ButtonMenu";
import ButtonMetaAction from "../components/Common/Buttons/ButtonMetaAction";
// import ButtonTikTokBusinessAction from "../components/Common/Buttons/ButtonTikTokBusinessAction";
import { getNameBusinessTheme } from "../actions/getters";
import _ from "lodash";
import CreateAdsCampaignIntro from "../components/User/AdsCampaigns/CreateAdsCampaigns/CreateAdsCampaignIntro";

const AdsCampaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatedCampaignsActive, setIsUpdatedCampaigns] = useState(false);

  const { adsCampaigns } = useSelectorApp((state) => state.adsCampaigns);
  const { user } = useSelectorApp((state) => state.auth);
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const [params] = useSearchParams();
  const content = params.get("content");
  const redirectCreate = params.get("redirectCreate") === "true";

  const onUpdateAdsCampaignInformationFromPlatforms = async (campaignIDs) => {
    const response = await dispatch(
      startUpdateAdsCampaignsInformationFromPlatforms({
        campaignIDs,
      })
    );
    return response;
  };

  const getInitialData = async () => {
    await dispatch(startGetAdsCampaigns());
    setIsLoading(false);
  };

  const onChangeStatusCampaign = async (campaignID) => {
    const campaign = campaigns[campaignID];
    const newStatus =
      campaign.status === ADS_CAMPAIGN_STATUS_ACTIVE
        ? ADS_CAMPAIGN_STATUS_DEACTIVATED
        : ADS_CAMPAIGN_STATUS_ACTIVE;

    const response = await dispatch(
      startChangeStatusAdsCampaign({
        campaignID,
        status: newStatus,
        platform: campaign.platform,
      })
    );
    return response;
  };
  useEffect(() => {
    dispatch(cleanAdsCampaigns());
    getInitialData();
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    const campaignsKeys = Object.keys(adsCampaigns || {});
    if (redirectCreate && campaignsKeys.length > 0) {
      navigate(`/create-ads-campaign?platform=meta&content=${content}`, {
        replace: true,
      });
      return;
    }

    setCampaigns((prevState) => {
      const data = {};
      const campaignsActive = [];

      campaignsKeys.forEach((campaignID) => {
        data[campaignID] = adsCampaigns[campaignID];
        if (adsCampaigns[campaignID].status === ADS_CAMPAIGN_STATUS_ACTIVE)
          campaignsActive.push(campaignID);
      });

      if (campaignsActive.length > 0 && !isUpdatedCampaignsActive) {
        setIsUpdatedCampaigns(true);
        onUpdateAdsCampaignInformationFromPlatforms(campaignsActive);
      }
      return {
        ...prevState,
        ...data,
      };
    });

    // eslint-disable-next-line
  }, [adsCampaigns, isLoading]);

  if (isLoading)
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );

  return (
    <BlockPlanNoActive
      sx={{
        height: "90%",
      }}
    >
      {!campaigns || _.isEmpty(campaigns) ? (
        <CreateAdsCampaignIntro />
      ) : (
        <ContainerPage hasMarginBottom={true}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "100%",
              mb: 3,
            }}
          >
            <Box>
              <TitlePage>{t(ADS_CAMPAIGNS_TITLE, { NAME_BUSINESS })}</TitlePage>
              <DescriptionPage>{t(ADS_CAMPAIGNS_DESCRIPTION)}</DescriptionPage>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: { xs: "wrap", sm: "nowrap" },
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <ButtonMenu
                textButton={BUTTON_CREATE}
                fullWidth={isMobile}
                sxMenu={{
                  maxWidth: "200px",
                }}
                options={[
                  {
                    customComponent: (
                      <ButtonMetaAction
                        hasShadow={false}
                        text={META_CAMPAIGN}
                        logo={META_WHITE}
                        sx={{
                          background: "unset",
                          p: 1,
                          borderRadius: 0,
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.1)",
                          },
                        }}
                        onClick={() =>
                          navigate(`/create-ads-campaign?platform=meta`)
                        }
                      />
                    ),
                  },
                  // {
                  //   customComponent: (
                  //     <ButtonTikTokBusinessAction
                  //       hasShadow={false}
                  //       text={TIKTOK_CAMPAIGN}
                  //       logo={TIKTOK}
                  //       sx={{
                  //         background: "unset",
                  //         p: 1,
                  //         borderRadius: 0,
                  //         "&:hover": {
                  //           backgroundColor: "rgba(255,255,255,0.1)",
                  //         },
                  //       }}
                  //       onClick={() =>
                  //         navigate("/create-ads-campaign?platform=tiktok")
                  //       }
                  //     />
                  //   ),
                  // },
                ]}
                sx={{
                  minWidth: 140,
                }}
              />
            </Box>
          </Box>
          <AdsCampaignsList
            data={campaigns}
            onChangeStatusCampaign={onChangeStatusCampaign}
          />
        </ContainerPage>
      )}
    </BlockPlanNoActive>
  );
};

export default AdsCampaigns;
