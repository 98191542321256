import {
  DARK_KITCHEN,
  EMPLOYEES_100_THAN,
  EMPLOYEES_10_20,
  EMPLOYEES_20_50,
  EMPLOYEES_2_10,
  EMPLOYEES_50_100,
  GOOGLE,
  LEFT,
  LINKEDIN,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT,
  OTHERS,
  PRODUCT_AVAILABILITY_INTERNATIONAL,
  PRODUCT_AVAILABILITY_LOCAL,
  PRODUCT_AVAILABILITY_NATIONAL,
  RIGHT,
  OPTION_UGC_FORMAT_GRWM,
  OPTION_UGC_FORMAT_GRWM_EXPLANATION,
  OPTION_UGC_FORMAT_OODT,
  OPTION_UGC_FORMAT_OODT_EXPLANATION,
  OPTION_UGC_FORMAT_HAULS,
  OPTION_UGC_FORMAT_HAULS_EXPLANATION,
  OPTION_UGC_FORMAT_DIYS,
  OPTION_UGC_FORMAT_DIYS_EXPLANATION,
  OPTION_UGC_FORMAT_UNBOXING,
  OPTION_UGC_FORMAT_UNBOXING_EXPLANATION,
  OPTION_UGC_FORMAT_CHALLENGES,
  OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION,
  OPTION_UGC_FORMAT_REVIEWS,
  OPTION_UGC_FORMAT_REVIEWS_EXPLANATION,
  OPTION_UGC_FORMAT_VLOGS,
  OPTION_UGC_FORMAT_VLOGS_EXPLANATION,
  OPTION_UGC_FORMAT_QA,
  OPTION_UGC_FORMAT_QA_EXPLANATION,
  OPTION_UGC_FORMAT_STORYTIME,
  OPTION_UGC_FORMAT_STORYTIME_EXPLANATION,
  OPTION_UGC_FORMAT_ROOM_TOURS,
  OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION,
  OPTION_UGC_FORMAT_WHATS_IN_MY_BAG,
  OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION,
  OPTION_UGC_FORMAT_COOKING,
  OPTION_UGC_FORMAT_COOKING_EXPLANATION,
  OPTION_UGC_FORMAT_FITNESS_ROUTINES,
  OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION,
  OPTION_UGC_FORMAT_ASMR,
  OPTION_UGC_FORMAT_ASMR_EXPLANATION,
  OPTION_UGC_FORMAT_PLAN_WITH_ME,
  OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION,
  OPTION_UGC_FORMAT_MUKBANG,
  OPTION_UGC_FORMAT_MUKBANG_EXPLANATION,
  OPTION_UGC_FORMAT_DAY_IN_THE_LIFE,
  OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION,
  OPTION_UGC_FORMAT_BEHIND_THE_SCENES,
  OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION,
  OPTION_UGC_FORMAT_TRAVEL_DIARIES,
  OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT,
  ADS_CAMPAIGN_OBJECTIVE_LEADS,
  ADS_CAMPAIGN_OBJECTIVE_SALES,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION,
  SPANISH,
  ENGLISH,
  BODY_BUILD_AVERAGE,
  BODY_BUILD_WIDE,
  BODY_BUILD_MUSCULAR,
  BODY_BUILD_CURVY,
  BODY_BUILD_ATHLETIC,
  CLOTHING_STYLE_CASUAL,
  CLOTHING_STYLE_FORMAL,
  CLOTHING_STYLE_SPORTY,
  CLOTHING_STYLE_ELEGANT,
  CLOTHING_STYLE_TRENDY,
  CLOTHING_STYLE_BOHEMIAN,
  CLOTHING_STYLE_HIPSTER,
  CLOTHING_STYLE_PUNK,
  DISTINGUISHING_FEATURES_TATTOOS,
  DISTINGUISHING_FEATURES_SCARS,
  DISTINGUISHING_FEATURES_BIRTHMARKS,
  DISTINGUISHING_FEATURES_MOLES,
  DISTINGUISHING_FEATURES_PIERCINGS,
  DISTINGUISHING_FEATURES_DIMPLES,
  DISTINGUISHING_FEATURES_WRINKLES,
  DISTINGUISHING_FEATURES_GLASSES,
  DISTINGUISHING_FEATURES_BRACES,
  DISTINGUISHING_FEATURES_MAKEUP,
  DISTINGUISHING_FEATURES_HAIR_ACCESSORIES,
  DISTINGUISHING_FEATURES_BEARD,
  CONTENT_STYLE_ELEGANT,
  CONTENT_STYLE_AESTHETIC,
  CONTENT_STYLE_MINIMALISTIC,
  CONTENT_STYLE_ARTISTIC,
  CONTENT_STYLE_MODERN,
  CONTENT_STYLE_PLAYFUL,
  CONTENT_STYLE_MONOCHROMATIC,
  // BODY_BUILD_THIN,
  // CLOTHING_STYLE_BUSINESS,
  // CLOTHING_STYLE_STREETWEAR,
  // CLOTHING_STYLE_TRADITIONAL,
  // CLOTHING_STYLE_VINTAGE,
  // CLOTHING_STYLE_GOTHIC,
  // DISTINGUISHING_FEATURES_FRECKLES,
  // DISTINGUISHING_FEATURES_MUSTACHE,
  // CONTENT_STYLE_RAW,
  // CONTENT_STYLE_VINTAGE,
  // CONTENT_STYLE_RUSTIC,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU,
  ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP,
  ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE,
  ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US,
  ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES,
  ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE,
  ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME,
  ADS_CAMPAIGN_CALL_TO_ACTION_SAVE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES,
  ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS,
  ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME,
  ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP,
  PIXEL_REQUIRED,
  SEARCH_INFLUENCERS_TOPICS,
  SEARCH_INFLUENCERS_USERNAME,
} from "../locales/keysTranslations";
import {
  INSTAGRAM,
  SOME_TEXT_SOCIAL_MEDIA_CONTENT,
  TIKTOK,
  WITHOUT_TEXT_SOCIAL_MEDIA_CONTENT,
  WITH_TEXT_SOCIAL_MEDIA_CONTENT,
  UGC_FORMAT_GRWM_OPTION,
  UGC_FORMAT_OODT_OPTION,
  UGC_FORMAT_HAULS_OPTION,
  UGC_FORMAT_DIYS_OPTION,
  UGC_FORMAT_UNBOXING_OPTION,
  UGC_FORMAT_CHALLENGES_OPTION,
  UGC_FORMAT_REVIEWS_OPTION,
  UGC_FORMAT_VLOGS_OPTION,
  UGC_FORMAT_QA_OPTION,
  UGC_FORMAT_STORYTIME_OPTION,
  UGC_FORMAT_ROOM_TOURS_OPTION,
  UGC_FORMAT_WHATS_IN_MY_BAG_OPTION,
  UGC_FORMAT_COOKING_OPTION,
  UGC_FORMAT_FITNESS_ROUTINES_OPTION,
  UGC_FORMAT_ASMR_OPTION,
  UGC_FORMAT_PLAN_WITH_ME_OPTION,
  UGC_FORMAT_MUKBANG_OPTION,
  UGC_FORMAT_DAY_IN_THE_LIFE_OPTION,
  UGC_FORMAT_BEHIND_THE_SCENES_OPTION,
  UGC_FORMAT_TRAVEL_DIARIES_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  BODY_BUILD_AVERAGE_VALUE,
  BODY_BUILD_ATHLETIC_VALUE,
  BODY_BUILD_WIDE_VALUE,
  BODY_BUILD_MUSCULAR_VALUE,
  BODY_BUILD_CURVY_VALUE,
  CLOTHING_STYLE_CASUAL_VALUE,
  CLOTHING_STYLE_FORMAL_VALUE,
  CLOTHING_STYLE_SPORTY_VALUE,
  CLOTHING_STYLE_ELEGANT_VALUE,
  CLOTHING_STYLE_TRENDY_VALUE,
  CLOTHING_STYLE_BOHEMIAN_VALUE,
  CLOTHING_STYLE_HIPSTER_VALUE,
  CLOTHING_STYLE_PUNK_VALUE,
  DISTINGUISHING_FEATURES_TATTOOS_VALUE,
  DISTINGUISHING_FEATURES_SCARS_VALUE,
  DISTINGUISHING_FEATURES_BIRTHMARKS_VALUE,
  DISTINGUISHING_FEATURES_MOLES_VALUE,
  DISTINGUISHING_FEATURES_PIERCINGS_VALUE,
  DISTINGUISHING_FEATURES_DIMPLES_VALUE,
  DISTINGUISHING_FEATURES_WRINKLES_VALUE,
  DISTINGUISHING_FEATURES_GLASSES_VALUE,
  DISTINGUISHING_FEATURES_BRACES_VALUE,
  DISTINGUISHING_FEATURES_MAKEUP_VALUE,
  DISTINGUISHING_FEATURES_HAIR_ACCESSORIES_VALUE,
  DISTINGUISHING_FEATURES_BEARD_VALUE,
  CONTENT_STYLE_ELEGANT_VALUE,
  CONTENT_STYLE_AESTHETIC_VALUE,
  CONTENT_STYLE_MINIMALISTIC_VALUE,
  CONTENT_STYLE_ARTISTIC_VALUE,
  CONTENT_STYLE_MODERN_VALUE,
  CONTENT_STYLE_PLAYFUL_VALUE,
  CONTENT_STYLE_MONOCHROMATIC_VALUE,
  SEARCH_INFLUENCERS_USERNAME_OPTION,
  SEARCH_INFLUENCERS_TOPICS_OPTION,
  // BODY_BUILD_THIN_VALUE,
  // CLOTHING_STYLE_BUSINESS_VALUE,
  // CLOTHING_STYLE_STREETWEAR_VALUE,
  // CLOTHING_STYLE_TRADITIONAL_VALUE,
  // CLOTHING_STYLE_VINTAGE_VALUE,
  // CLOTHING_STYLE_GOTHIC_VALUE,
  // DISTINGUISHING_FEATURES_FRECKLES_VALUE,
  // DISTINGUISHING_FEATURES_MUSTACHE_VALUE,
  // CONTENT_STYLE_RAW_VALUE,
  // CONTENT_STYLE_VINTAGE_VALUE,
  // CONTENT_STYLE_RUSTIC_VALUE,
} from "./constants";
import { numberCompactFormat } from "./numberFormats";

export function textNumberCompactFormat(value) {
  return `${numberCompactFormat(value)}`;
}

export const marksBudgetCampaign = [
  {
    value: 100000,
    label: "100K",
  },
  {
    value: 1000000,
    label: "1M",
  },
  {
    value: 20000000,
    label: "20M",
  },
  {
    value: 40000000,
    label: "40M",
  },
  {
    value: 60000000,
    label: "60M",
  },
  {
    value: 80000000,
    label: "80M",
  },
  {
    value: 100000000,
    label: "100M",
  },
];

export const marksInfluencers = [
  {
    value: 100000,
    label: "100K",
  },
  {
    value: 500000,
    label: "500K",
  },
  {
    value: 1000000,
    label: "1M",
  },
  {
    value: 2000000,
    label: "2M",
  },
  {
    value: 3000000,
    label: "3M",
  },
  {
    value: 4000000,
    label: "4M",
  },
  {
    value: 5000000,
    label: "5M",
  },
];

export const marksInfluencersMax100K = [
  {
    value: 1000,
    label: "1K",
  },
  {
    value: 25000,
    label: "25K",
  },
  {
    value: 50000,
    label: "50K",
  },
  {
    value: 75000,
    label: "75K",
  },
  {
    value: 100000,
    label: "100K",
  },
];
export const marksInfluencersMax500K = [
  {
    value: 5000,
    label: "5K",
  },
  {
    value: 100000,
    label: "100K",
  },
  {
    value: 200000,
    label: "200K",
  },
  {
    value: 300000,
    label: "300K",
  },
  {
    value: 400000,
    label: "400K",
  },
  {
    value: 500000,
    label: "500K+",
  },
];

export const marksAge = [
  {
    value: 18,
    label: "18",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
];

export const marksEngagement = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: 15,
    label: "15%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 30,
    label: "30%",
  },
];

export const marksEngagementMax10 = [
  {
    value: 0.1,
    label: "0%",
  },
  {
    value: 2.5,
    label: "2.5%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 7.5,
    label: "7.5%",
  },
  {
    value: 10,
    label: "10%",
  },
];

export const marksInvitationsValue = [
  {
    value: 10000,
    label: "10K",
  },
  {
    value: 30000,
    label: "30K",
  },
  {
    value: 60000,
    label: "60K",
  },
  {
    value: 100000,
    label: "100K",
  },
  {
    value: 150000,
    label: "150K",
  },
  {
    value: 200000,
    label: "200K",
  },
];

export const marksPercentaje = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const optionsAudienceAgeGender = [
  // { value: "13-17", label: "13-17" },
  { value: "18-24", label: "18-24" },
  { value: "25-34", label: "25-34" },
  { value: "35+", label: "35+" },
];

export const optionsEmployees = [
  {
    label: EMPLOYEES_2_10,
    value: "2-10",
  },
  {
    label: EMPLOYEES_10_20,
    value: "10-20",
  },
  {
    label: EMPLOYEES_20_50,
    value: "20-50",
  },
  {
    label: EMPLOYEES_50_100,
    value: "50-100",
  },
  {
    label: EMPLOYEES_100_THAN,
    value: "100+",
  },
];

export const optionsYouMeet = [
  {
    label: INSTAGRAM,
    value: "instagram",
  },
  {
    label: TIKTOK,
    value: "tiktok",
  },
  {
    label: LINKEDIN,
    value: "linkedin",
  },
  {
    label: GOOGLE,
    value: "google",
  },
  {
    label: DARK_KITCHEN,
    value: "darkKitchen",
  },
  {
    label: OTHERS,
    value: "other",
  },
];

export const optionsProductAvailability = [
  {
    label: PRODUCT_AVAILABILITY_LOCAL,
    value: "local",
  },
  {
    label: PRODUCT_AVAILABILITY_NATIONAL,
    value: "national",
  },
  {
    label: PRODUCT_AVAILABILITY_INTERNATIONAL,
    value: "international",
  },
];

export const optionsFontFamily = [
  {
    label: "Poppins",
    value: "poppins",
  },
  {
    label: "Raleway",
    value: "raleway",
  },
  {
    label: "Montserrat",
    value: "montserrat",
  },
  {
    label: "Inter",
    value: "inter",
  },
  {
    label: "Open Sans",
    value: "openSans",
  },
  {
    label: "Roboto",
    value: "roboto",
  },
  {
    label: "Lato",
    value: "lato",
  },
  {
    label: "Playfair Display",
    value: "playfairDisplay",
  },
  {
    label: "PT Sans",
    value: "ptSans",
  },
];

export const optionsAlignText = [
  {
    label: LEFT,
    value: "left",
  },
  {
    label: RIGHT,
    value: "right",
  },
];

export const optionsAlignLogo = [
  {
    label: LEFT,
    value: "left",
  },
  {
    label: RIGHT,
    value: "right",
  },
];

export const optionsHasTexts = [
  {
    label: MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT,
    value: WITH_TEXT_SOCIAL_MEDIA_CONTENT,
  },
  {
    label: MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT,
    value: SOME_TEXT_SOCIAL_MEDIA_CONTENT,
  },
  {
    label: MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT,
    value: WITHOUT_TEXT_SOCIAL_MEDIA_CONTENT,
  },
];

export const optionsUGCFormats = [
  {
    label: OPTION_UGC_FORMAT_GRWM,
    value: UGC_FORMAT_GRWM_OPTION,
    explanation: OPTION_UGC_FORMAT_GRWM_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_OODT,
    value: UGC_FORMAT_OODT_OPTION,
    explanation: OPTION_UGC_FORMAT_OODT_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_HAULS,
    value: UGC_FORMAT_HAULS_OPTION,
    explanation: OPTION_UGC_FORMAT_HAULS_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_DIYS,
    value: UGC_FORMAT_DIYS_OPTION,
    explanation: OPTION_UGC_FORMAT_DIYS_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_UNBOXING,
    value: UGC_FORMAT_UNBOXING_OPTION,
    explanation: OPTION_UGC_FORMAT_UNBOXING_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_CHALLENGES,
    value: UGC_FORMAT_CHALLENGES_OPTION,
    explanation: OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_REVIEWS,
    value: UGC_FORMAT_REVIEWS_OPTION,
    explanation: OPTION_UGC_FORMAT_REVIEWS_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_VLOGS,
    value: UGC_FORMAT_VLOGS_OPTION,
    explanation: OPTION_UGC_FORMAT_VLOGS_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_QA,
    value: UGC_FORMAT_QA_OPTION,
    explanation: OPTION_UGC_FORMAT_QA_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_STORYTIME,
    value: UGC_FORMAT_STORYTIME_OPTION,
    explanation: OPTION_UGC_FORMAT_STORYTIME_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_ROOM_TOURS,
    value: UGC_FORMAT_ROOM_TOURS_OPTION,
    explanation: OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_WHATS_IN_MY_BAG,
    value: UGC_FORMAT_WHATS_IN_MY_BAG_OPTION,
    explanation: OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_COOKING,
    value: UGC_FORMAT_COOKING_OPTION,
    explanation: OPTION_UGC_FORMAT_COOKING_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_FITNESS_ROUTINES,
    value: UGC_FORMAT_FITNESS_ROUTINES_OPTION,
    explanation: OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_ASMR,
    value: UGC_FORMAT_ASMR_OPTION,
    explanation: OPTION_UGC_FORMAT_ASMR_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_PLAN_WITH_ME,
    value: UGC_FORMAT_PLAN_WITH_ME_OPTION,
    explanation: OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_MUKBANG,
    value: UGC_FORMAT_MUKBANG_OPTION,
    explanation: OPTION_UGC_FORMAT_MUKBANG_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_DAY_IN_THE_LIFE,
    value: UGC_FORMAT_DAY_IN_THE_LIFE_OPTION,
    explanation: OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_BEHIND_THE_SCENES,
    value: UGC_FORMAT_BEHIND_THE_SCENES_OPTION,
    explanation: OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION,
  },
  {
    label: OPTION_UGC_FORMAT_TRAVEL_DIARIES,
    value: UGC_FORMAT_TRAVEL_DIARIES_OPTION,
    explanation: OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION,
  },
];

export const optionsAdsCampaignObjectives = [
  {
    value: ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_AWARENESS,
    title: ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC,
    title: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT,
    title: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
    chip: PIXEL_REQUIRED,
  },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_LEADS,
    title: ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
    chip: PIXEL_REQUIRED,
  },
  // {
  //   value: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION,
  //   label: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION,
  //   title: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION,
  //   explanation: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION,
  // },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_SALES,
    title: ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
    chip: PIXEL_REQUIRED,
  },
];

export const optionsAdsCampaignTypeBudgets = [
  {
    value: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
    label: ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
    title: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
    label: ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
    title: ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION,
  },
];

export const optionsLanguage = [
  { value: "es", label: SPANISH },
  { value: "en", label: ENGLISH },
];

export const optionsSearchInfluencers = [
  { value: SEARCH_INFLUENCERS_TOPICS_OPTION, label: SEARCH_INFLUENCERS_TOPICS },
  {
    value: SEARCH_INFLUENCERS_USERNAME_OPTION,
    label: SEARCH_INFLUENCERS_USERNAME,
  },
];

export const optionsBodyBuild = [
  // {
  //   label: BODY_BUILD_THIN,
  //   value: BODY_BUILD_THIN_VALUE,
  // },
  {
    label: BODY_BUILD_AVERAGE,
    value: BODY_BUILD_AVERAGE_VALUE,
  },
  {
    label: BODY_BUILD_ATHLETIC,
    value: BODY_BUILD_ATHLETIC_VALUE,
  },
  {
    label: BODY_BUILD_WIDE,
    value: BODY_BUILD_WIDE_VALUE,
  },
  {
    label: BODY_BUILD_MUSCULAR,
    value: BODY_BUILD_MUSCULAR_VALUE,
  },
  {
    label: BODY_BUILD_CURVY,
    value: BODY_BUILD_CURVY_VALUE,
  },
];

export const optionsClothingStyle = [
  {
    label: CLOTHING_STYLE_CASUAL,
    value: CLOTHING_STYLE_CASUAL_VALUE,
  },
  {
    label: CLOTHING_STYLE_FORMAL,
    value: CLOTHING_STYLE_FORMAL_VALUE,
  },
  {
    label: CLOTHING_STYLE_SPORTY,
    value: CLOTHING_STYLE_SPORTY_VALUE,
  },
  // {
  //   label: CLOTHING_STYLE_BUSINESS,
  //   value: CLOTHING_STYLE_BUSINESS_VALUE,
  // },
  {
    label: CLOTHING_STYLE_ELEGANT,
    value: CLOTHING_STYLE_ELEGANT_VALUE,
  },
  {
    label: CLOTHING_STYLE_TRENDY,
    value: CLOTHING_STYLE_TRENDY_VALUE,
  },
  // {
  //   label: CLOTHING_STYLE_STREETWEAR,
  //   value: CLOTHING_STYLE_STREETWEAR_VALUE,
  // },
  // {
  //   label: CLOTHING_STYLE_TRADITIONAL,
  //   value: CLOTHING_STYLE_TRADITIONAL_VALUE,
  // },
  // {
  //   label: CLOTHING_STYLE_VINTAGE,
  //   value: CLOTHING_STYLE_VINTAGE_VALUE,
  // },
  {
    label: CLOTHING_STYLE_BOHEMIAN,
    value: CLOTHING_STYLE_BOHEMIAN_VALUE,
  },
  {
    label: CLOTHING_STYLE_HIPSTER,
    value: CLOTHING_STYLE_HIPSTER_VALUE,
  },
  {
    label: CLOTHING_STYLE_PUNK,
    value: CLOTHING_STYLE_PUNK_VALUE,
  },
  // {
  //   label: CLOTHING_STYLE_GOTHIC,
  //   value: CLOTHING_STYLE_GOTHIC_VALUE,
  // },
];

export const optionsDistinguishingFeatures = [
  {
    label: DISTINGUISHING_FEATURES_TATTOOS,
    value: DISTINGUISHING_FEATURES_TATTOOS_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_SCARS,
    value: DISTINGUISHING_FEATURES_SCARS_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_BIRTHMARKS,
    value: DISTINGUISHING_FEATURES_BIRTHMARKS_VALUE,
  },
  // {
  //   label: DISTINGUISHING_FEATURES_FRECKLES,
  //   value: DISTINGUISHING_FEATURES_FRECKLES_VALUE,
  // },
  {
    label: DISTINGUISHING_FEATURES_MOLES,
    value: DISTINGUISHING_FEATURES_MOLES_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_PIERCINGS,
    value: DISTINGUISHING_FEATURES_PIERCINGS_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_DIMPLES,
    value: DISTINGUISHING_FEATURES_DIMPLES_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_WRINKLES,
    value: DISTINGUISHING_FEATURES_WRINKLES_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_GLASSES,
    value: DISTINGUISHING_FEATURES_GLASSES_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_BRACES,
    value: DISTINGUISHING_FEATURES_BRACES_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_MAKEUP,
    value: DISTINGUISHING_FEATURES_MAKEUP_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_HAIR_ACCESSORIES,
    value: DISTINGUISHING_FEATURES_HAIR_ACCESSORIES_VALUE,
  },
  {
    label: DISTINGUISHING_FEATURES_BEARD,
    value: DISTINGUISHING_FEATURES_BEARD_VALUE,
  },
  // {
  //   label: DISTINGUISHING_FEATURES_MUSTACHE,
  //   value: DISTINGUISHING_FEATURES_MUSTACHE_VALUE,
  // },
];

export const optionsContentStyle = [
  {
    label: CONTENT_STYLE_ELEGANT,
    value: CONTENT_STYLE_ELEGANT_VALUE,
  },
  {
    label: CONTENT_STYLE_AESTHETIC,
    value: CONTENT_STYLE_AESTHETIC_VALUE,
  },
  // {
  //   label: CONTENT_STYLE_RAW,
  //   value: CONTENT_STYLE_RAW_VALUE,
  // },
  {
    label: CONTENT_STYLE_MINIMALISTIC,
    value: CONTENT_STYLE_MINIMALISTIC_VALUE,
  },
  {
    label: CONTENT_STYLE_ARTISTIC,
    value: CONTENT_STYLE_ARTISTIC_VALUE,
  },
  // {
  //   label: CONTENT_STYLE_VINTAGE,
  //   value: CONTENT_STYLE_VINTAGE_VALUE,
  // },
  {
    label: CONTENT_STYLE_MODERN,
    value: CONTENT_STYLE_MODERN_VALUE,
  },
  {
    label: CONTENT_STYLE_PLAYFUL,
    value: CONTENT_STYLE_PLAYFUL_VALUE,
  },
  // {
  //   label: CONTENT_STYLE_RUSTIC,
  //   value: CONTENT_STYLE_RUSTIC_VALUE,
  // },
  {
    label: CONTENT_STYLE_MONOCHROMATIC,
    value: CONTENT_STYLE_MONOCHROMATIC_VALUE,
  },
];

export const optionsCallToActionAwarenessMeta = [
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  {
    value: "GET_DIRECTIONS",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS,
  },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "SEND_MESSAGE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SAVE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SAVE },
];

export const optionsCallToActionTrafficMeta = [
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  {
    value: "GET_PROMOTIONS",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS,
  },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "GET_ACCESS", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS },
  { value: "GET_UPDATES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
];

export const optionsCallToActionEngagementMeta = [
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  {
    value: "GET_PROMOTIONS",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS,
  },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "INQUIRE_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW },
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "GET_UPDATES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
];

export const optionsCallToActionLeadsMeta = [
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "BUY_TICKETS", label: ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
];

export const optionsCallToActionAppPromotionMeta = [
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "EXPLORE_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "USE_APP", label: ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
];

export const optionsCallToActionSalesMeta = [
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
  { value: "BUY_TICKETS", label: ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS },
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
];

export const optionsCurrencies = [
  { label: "AED", value: "AED" },
  { label: "AFN", value: "AFN" },
  { label: "ALL", value: "ALL" },
  { label: "AMD", value: "AMD" },
  { label: "ANG", value: "ANG" },
  { label: "AOA", value: "AOA" },
  { label: "ARS", value: "ARS" },
  { label: "AUD", value: "AUD" },
  { label: "AWG", value: "AWG" },
  { label: "AZN", value: "AZN" },
  { label: "BAM", value: "BAM" },
  { label: "BBD", value: "BBD" },
  { label: "BDT", value: "BDT" },
  { label: "BGN", value: "BGN" },
  { label: "BHD", value: "BHD" },
  { label: "BIF", value: "BIF" },
  { label: "BMD", value: "BMD" },
  { label: "BND", value: "BND" },
  { label: "BOB", value: "BOB" },
  { label: "BRL", value: "BRL" },
  { label: "BSD", value: "BSD" },
  { label: "BTC", value: "BTC" },
  { label: "BTN", value: "BTN" },
  { label: "BWP", value: "BWP" },
  { label: "BYR", value: "BYR" },
  { label: "BZD", value: "BZD" },
  { label: "CAD", value: "CAD" },
  { label: "CDF", value: "CDF" },
  { label: "CHF", value: "CHF" },
  { label: "CLP", value: "CLP" },
  { label: "CNY", value: "CNY" },
  { label: "COP", value: "COP" },
  { label: "CRC", value: "CRC" },
  { label: "CUC", value: "CUC" },
  { label: "CUP", value: "CUP" },
  { label: "CVE", value: "CVE" },
  { label: "CZK", value: "CZK" },
  { label: "DJF", value: "DJF" },
  { label: "DKK", value: "DKK" },
  { label: "DOP", value: "DOP" },
  { label: "DZD", value: "DZD" },
  { label: "EGP", value: "EGP" },
  { label: "ERN", value: "ERN" },
  { label: "ETB", value: "ETB" },
  { label: "EUR", value: "EUR" },
  { label: "FJD", value: "FJD" },
  { label: "FKP", value: "FKP" },
  { label: "GBP", value: "GBP" },
  { label: "GEL", value: "GEL" },
  { label: "GGP", value: "GGP" },
  { label: "GHS", value: "GHS" },
  { label: "GIP", value: "GIP" },
  { label: "GMD", value: "GMD" },
  { label: "GNF", value: "GNF" },
  { label: "GTQ", value: "GTQ" },
  { label: "GYD", value: "GYD" },
  { label: "HKD", value: "HKD" },
  { label: "HNL", value: "HNL" },
  { label: "HRK", value: "HRK" },
  { label: "HTG", value: "HTG" },
  { label: "HUF", value: "HUF" },
  { label: "IDR", value: "IDR" },
  { label: "ILS", value: "ILS" },
  { label: "IMP", value: "IMP" },
  { label: "INR", value: "INR" },
  { label: "IQD", value: "IQD" },
  { label: "IRR", value: "IRR" },
  { label: "IRT", value: "IRT" },
  { label: "ISK", value: "ISK" },
  { label: "JEP", value: "JEP" },
  { label: "JMD", value: "JMD" },
  { label: "JOD", value: "JOD" },
  { label: "JPY", value: "JPY" },
  { label: "KES", value: "KES" },
  { label: "KGS", value: "KGS" },
  { label: "KHR", value: "KHR" },
  { label: "KMF", value: "KMF" },
  { label: "KPW", value: "KPW" },
  { label: "KRW", value: "KRW" },
  { label: "KWD", value: "KWD" },
  { label: "KYD", value: "KYD" },
  { label: "KZT", value: "KZT" },
  { label: "LAK", value: "LAK" },
  { label: "LBP", value: "LBP" },
  { label: "LKR", value: "LKR" },
  { label: "LRD", value: "LRD" },
  { label: "LSL", value: "LSL" },
  { label: "LYD", value: "LYD" },
  { label: "MAD", value: "MAD" },
  { label: "MDL", value: "MDL" },
  { label: "MGA", value: "MGA" },
  { label: "MKD", value: "MKD" },
  { label: "MMK", value: "MMK" },
  { label: "MNT", value: "MNT" },
  { label: "MOP", value: "MOP" },
  { label: "MRO", value: "MRO" },
  { label: "MUR", value: "MUR" },
  { label: "MVR", value: "MVR" },
  { label: "MWK", value: "MWK" },
  { label: "MXN", value: "MXN" },
  { label: "MYR", value: "MYR" },
  { label: "MZN", value: "MZN" },
  { label: "NAD", value: "NAD" },
  { label: "NGN", value: "NGN" },
  { label: "NIO", value: "NIO" },
  { label: "NOK", value: "NOK" },
  { label: "NPR", value: "NPR" },
  { label: "NZD", value: "NZD" },
  { label: "OMR", value: "OMR" },
  { label: "PAB", value: "PAB" },
  { label: "PEN", value: "PEN" },
  { label: "PGK", value: "PGK" },
  { label: "PHP", value: "PHP" },
  { label: "PKR", value: "PKR" },
  { label: "PLN", value: "PLN" },
  { label: "PRB", value: "PRB" },
  { label: "PYG", value: "PYG" },
  { label: "QAR", value: "QAR" },
  { label: "RON", value: "RON" },
  { label: "RSD", value: "RSD" },
  { label: "RUB", value: "RUB" },
  { label: "RWF", value: "RWF" },
  { label: "SAR", value: "SAR" },
  { label: "SBD", value: "SBD" },
  { label: "SCR", value: "SCR" },
  { label: "SDG", value: "SDG" },
  { label: "SEK", value: "SEK" },
  { label: "SGD", value: "SGD" },
  { label: "SHP", value: "SHP" },
  { label: "SLL", value: "SLL" },
  { label: "SOS", value: "SOS" },
  { label: "SRD", value: "SRD" },
  { label: "SSP", value: "SSP" },
  { label: "STD", value: "STD" },
  { label: "SYP", value: "SYP" },
  { label: "SZL", value: "SZL" },
  { label: "THB", value: "THB" },
  { label: "TJS", value: "TJS" },
  { label: "TMT", value: "TMT" },
  { label: "TND", value: "TND" },
  { label: "TOP", value: "TOP" },
  { label: "TRY", value: "TRY" },
  { label: "TTD", value: "TTD" },
  { label: "TWD", value: "TWD" },
  { label: "TZS", value: "TZS" },
  { label: "UAH", value: "UAH" },
  { label: "UGX", value: "UGX" },
  { label: "USD", value: "USD" },
  { label: "UYU", value: "UYU" },
  { label: "UZS", value: "UZS" },
  { label: "VEF", value: "VEF" },
  { label: "VND", value: "VND" },
  { label: "VUV", value: "VUV" },
  { label: "WST", value: "WST" },
  { label: "XAF", value: "XAF" },
  { label: "XCD", value: "XCD" },
  { label: "XOF", value: "XOF" },
  { label: "XPF", value: "XPF" },
  { label: "YER", value: "YER" },
  { label: "ZAR", value: "ZAR" },
  { label: "ZMW", value: "ZMW" },
];
