import React from "react";
import ButtonSlideMenu from "./ButtonSlideMenu";
import CampaignIcon from "@mui/icons-material/Campaign";
import { SUB_MENU_ADS_CAMPAIGNS } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import {
  getLinkRedirectAds,
  getNameBusinessTheme,
} from "../../../actions/getters";
import { openLink } from "../../../utils/urls";

const ButtonRedirectAds = ({ dataTour = "" }) => {
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  return (
    <ButtonSlideMenu
      dataTour={dataTour}
      startIcon={<CampaignIcon />}
      variant="contained"
      color="secondary"
      hasShadow={false}
      disableElevation
      fullWidth
      onClick={async () => {
        const urlRedirect = await dispatch(
          getLinkRedirectAds({
            path: "/",
          })
        );
        openLink(urlRedirect);
      }}
    >
      {t(SUB_MENU_ADS_CAMPAIGNS, {
        NAME_BUSINESS,
      })}
    </ButtonSlideMenu>
  );
};

export default ButtonRedirectAds;
