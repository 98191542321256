import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import { reorder } from "../../../utils/draggable";
import { OPERATOR_ROLE } from "../../../utils/constants";
import { MenuContext } from "../../../Contexts/MenuContext";
import { useTheme } from "@mui/material/styles";
import {
  ADD_PRODUCT_MENU,
  INACTIVE_PRODUCT,
  NEW_PRODUCT,
} from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ACTIVATE,
  BUTTON_DELETE,
  BUTTON_DUPLICATE,
  BUTTON_MOVE_OTHER_CATEGORY,
  DEACTIVATE,
  MENU_ADD_PRODUCT,
} from "../../../locales/keysTranslations";

const ProductsSection = ({ data, userRole }) => {
  const {
    // PRODUCTS
    productSelected,
    onSelectProduct,
    onChangeOrderProducts,
    onStartMoveProduct,
    onDeleteProduct,
    onDuplicateProduct,
    onChangeStatusProduct,
  } = useContext(MenuContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [productSelect, setProductSelect] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const onClick = (event, product) => {
    setAnchorEl(event.currentTarget);
    setProductSelect(product);
  };

  const { t } = useTranslationApp();

  const onClose = () => {
    setAnchorEl(null);
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(data.products, source.index, destination.index);
    onChangeOrderProducts(newItems);
  };
  return (
    <List
      sx={{
        width: "100%",
        minWidth: { md: 180, lg: 230, xl: 280 },
        bgcolor: "background.paper",
        color: "background.paperContrastText",
        overflow: "auto",
      }}
      component="div"
      aria-labelledby="product-list"
    >
      <ListItemButton
        onClick={() => onSelectProduct("create")}
        disabled={userRole === OPERATOR_ROLE}
        data-tour={ADD_PRODUCT_MENU}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText
          primary={t(MENU_ADD_PRODUCT)}
          sx={{
            color: "text.primary",
            "& .MuiListItemText-primary": {
              fontWeight: 600,
            },
          }}
        />
      </ListItemButton>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-listt">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data?.products
                ? Object.keys(data.products).map((product, index) => (
                    <Draggable
                      draggableId={index.toString()}
                      index={index}
                      key={index}
                    >
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={index}
                          data-tour={index === 0 && NEW_PRODUCT}
                          sx={{
                            backgroundColor:
                              !data.products[product]?.isActive &&
                              "background.paperSecondary",
                          }}
                          secondaryAction={
                            <>
                              <IconButton
                                data-tour={index === 0 && INACTIVE_PRODUCT}
                                sx={{
                                  display: { xs: "none", sm: "inline-flex" },
                                }}
                                onClick={() =>
                                  onChangeStatusProduct(data.products[product])
                                }
                              >
                                {data.products[product]?.isActive ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon color="disabled" />
                                )}
                              </IconButton>
                              <IconButton
                                edge="end"
                                aria-label="more-options"
                                onClick={(e) =>
                                  onClick(e, data.products[[product]])
                                }
                                disabled={userRole === OPERATOR_ROLE}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </>
                          }
                        >
                          <ListItemButton
                            key={index}
                            onClick={() =>
                              onSelectProduct(data.products[product], product)
                            }
                            sx={{
                              borderLeft:
                                productSelected?.name ===
                                  data.products[product]?.name &&
                                `4px solid ${theme.palette.primary.main}`,
                            }}
                          >
                            <ListItemAvatar
                              sx={{
                                ml:
                                  productSelected?.name ===
                                    data.products[product]?.name && "-4px",
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                alt={`Image de ${data.products[product].name}`}
                                src={data.products[product].photoURL}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={data.products[product].name}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))
                : null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "more-options-button",
        }}
      >
        <MenuItem
          sx={{ display: { xs: "flex", sm: "none" } }}
          onClick={() => {
            onChangeStatusProduct(productSelect);
            onClose();
          }}
        >
          {productSelect?.isActive ? t(DEACTIVATE) : t(ACTIVATE)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDuplicateProduct(productSelect);
            onClose();
          }}
        >
          {t(BUTTON_DUPLICATE)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onStartMoveProduct(productSelect);
            onClose();
          }}
        >
          {t(BUTTON_MOVE_OTHER_CATEGORY)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteProduct(productSelect);
            onClose();
          }}
        >
          {t(BUTTON_DELETE)}
        </MenuItem>
      </Menu>
    </List>
  );
};

export default ProductsSection;
