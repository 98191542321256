import { useEffect, useState } from "react";

const useFocusErrorForm = (formState = {}) => {
  const [isValidating, setIsValidating] = useState(true);
  useEffect(() => {
    const errors = Object.keys(formState.errors || {});
    if (errors.length === 0) {
      setIsValidating(false);
      return;
    }

    if (isValidating) {
      return;
    }

    setIsValidating(true);
    const elements = Object.keys(formState.errors)
      .map((name) => document.getElementsByName(name)[0])
      .filter((el) => !!el);
    elements.sort((a, b) => {
      if (a.localName === "textarea") return 1;

      return b.scrollHeight - a.scrollHeight;
    });
    elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
    elements[0]?.focus();

    // eslint-disable-next-line
  }, [formState]);
};

export default useFocusErrorForm;
