import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import AutomaticInvitesTypeForm from "../../../pages/AutomaticInvitesTypeForm";

const ModalAutomaticInvitesTypeForm = ({
  modalOpen,
  onCloseModal = () => {},
  onCallbackSave = () => {},
}) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
    >
      <AutomaticInvitesTypeForm onCallbackSave={onCallbackSave} />
    </ModalBasicLayout>
  );
};

export default ModalAutomaticInvitesTypeForm;
