import React, { useContext } from "react";
import {
  ChatContainer as ChatContainerLibrary,
  MessageList,
  Message,
  TypingIndicator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import ChatConversationHeader from "./ChatConversationHeader";
import ChatMessageInput from "./ChatMessageInput";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startSendMessageToChatBot } from "../../../actions/chat";
import { ChatContext } from "../../../Contexts/ChatContext";
import {
  CHAT_BOT_USER_ID,
  CHAT_SUB_FROM_ADVERTISER,
  CHAT_SUB_FROM_SHOP,
  FONT_FAMILY,
  ROLES_DASHBOARD,
  ROLES_SHOPS,
} from "../../../utils/constants";
import {
  getInfluencerRedux,
  getNameBusinessTheme,
  getRole,
  getShopID,
  getUserID,
} from "../../../actions/getters";
import ChatCustomMessage from "./ChatCustomMessage";
import {
  CHAT_ERROR_SENT_MESSAGE,
  NAME_BUSINESS_BOT,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import _ from "lodash";
import logoPerson from "../../../assets/img/logo-person.png";

const ChatContainer = ({
  onChangeShowChat,
  onOpenModalPayment,
  onOpenModalInfluencer,
}) => {
  const {
    currentChat,
    currentChatMessages,
    isTypingBot,
    onAddMessage,
    onChangeIsTypingBot,
    logoShop,
    LOGO_BUSINESS,
    LOGO_CHATBOT,
  } = useContext(ChatContext);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const shopID = dispatch(getShopID());
  const userID = dispatch(getUserID());
  const role = dispatch(getRole());
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const shops = useSelectorApp((state) => state.shop.shops);
  const advertisers = useSelectorApp((state) => state.advertisers.advertisers);

  const currentUserID = ROLES_DASHBOARD.includes(role) ? userID : shopID;

  const getAllCurrentData = () => {
    if (currentChat.userID === CHAT_BOT_USER_ID) {
      return {
        name: t(NAME_BUSINESS_BOT, {
          NAME_BUSINESS,
        }),
        imageURL: LOGO_CHATBOT,
        userID: currentChat.userID,
      };
    }

    if (currentChat.from === "help") {
      if (
        ROLES_DASHBOARD.includes(role) &&
        currentChat?.subFrom === CHAT_SUB_FROM_ADVERTISER
      ) {
        return {
          name: advertisers[currentChat.userID]?.businessName,
          imageURL: logoPerson,
          userID: currentChat.userID,
        };
      }
      if (
        ROLES_DASHBOARD.includes(role) &&
        currentChat?.subFrom === CHAT_SUB_FROM_SHOP
      ) {
        return {
          name: shops[currentChat.userID]?.businessName,
          imageURL: shops[currentChat.userID]?.logoImg,
          userID: currentChat.userID,
        };
      }
      if (
        ROLES_SHOPS.includes(role) &&
        currentChat?.subFrom === CHAT_SUB_FROM_SHOP
      ) {
        return {
          name: `${NAME_BUSINESS} Team`,
          imageURL: LOGO_BUSINESS,
          userID: currentChat.userID,
        };
      }
    }

    const influencer = dispatch(getInfluencerRedux(currentChat.userID));
    return {
      ...influencer,
      name: influencer?.mainAccountName,
      userID: currentChat.userID,
      isInfluencer: true,
    };
  };

  const allCurrentChatData = getAllCurrentData();

  const onSendMessageBot = async (message, file) => {
    const newMessage = await onAddMessage({
      message,
      senderID: currentUserID,
      receiverID: currentChat.userID,
      file,
    });

    if (currentChat.userID === CHAT_BOT_USER_ID) {
      const messageID = new Date().getTime();
      const newMessages = { ...currentChatMessages, [messageID]: newMessage };
      onChangeIsTypingBot();
      const data = await dispatch(startSendMessageToChatBot(newMessages));

      if (!data) {
        onAddMessage({
          message: t(CHAT_ERROR_SENT_MESSAGE),
          senderID: CHAT_BOT_USER_ID,
          receiverID: currentUserID,
        });
        onChangeIsTypingBot();
        return;
      }
      onAddMessage({
        message: data,
        senderID: CHAT_BOT_USER_ID,
        receiverID: currentUserID,
      });
      onChangeIsTypingBot();
    }
    return newMessage;
  };

  const sortedMessages = Object.keys(currentChatMessages).sort(
    (a, b) =>
      currentChatMessages[a].creationTime - currentChatMessages[b].creationTime
  );
  return (
    <ChatContainerLibrary
      style={{
        border: "none",
      }}
    >
      <ChatConversationHeader
        data={allCurrentChatData}
        as="ConversationHeader"
        onChangeShowChat={onChangeShowChat}
        onOpenModalInfluencer={onOpenModalInfluencer}
      />

      <MessageList
        typingIndicator={isTypingBot && <TypingIndicator />}
        style={{ marginTop: 0, fontFamily: FONT_FAMILY }}
      >
        {sortedMessages.map((messageID, index) => {
          const message = currentChatMessages[messageID];

          const getDirection = () => {
            if (currentChat?.from === "help") {
              if (ROLES_DASHBOARD.includes(role)) {
                if (currentChat?.helpers?.includes(message.userID)) {
                  return "outgoing";
                } else {
                  return "incoming";
                }
              }
              if (ROLES_SHOPS.includes(role)) {
                if (message.userID === shopID) {
                  return "outgoing";
                } else {
                  return "incoming";
                }
              }
            } else {
              return message.userID === currentUserID ? "outgoing" : "incoming";
            }
          };

          const direction = getDirection();

          const getImageURL = () => {
            if (
              currentChat?.from === "help" &&
              currentChat?.helpers &&
              currentChat?.helpers?.includes(message.userID)
            ) {
              return LOGO_BUSINESS;
            }
            if (message.userID === shopID) {
              return logoShop;
            }
            if (currentChat.userID === CHAT_BOT_USER_ID) {
              if (direction === "outgoing") {
                return LOGO_BUSINESS;
              } else {
                return LOGO_CHATBOT;
              }
            }
            return allCurrentChatData.imageURL;
          };

          return (
            <Message
              key={index}
              model={{
                message: message.message,
                sender: allCurrentChatData.name,
                direction: direction,
                type: "html",
              }}
              style={{
                marginBottom: 10,
              }}
            >
              <Avatar src={getImageURL()} name={allCurrentChatData.name} />
              <Message.CustomContent>
                <ChatCustomMessage
                  message={message}
                  direction={direction}
                  currentChat={currentChat}
                />
              </Message.CustomContent>
            </Message>
          );
        })}
      </MessageList>
      {!_.isEmpty(currentChat) && (
        <ChatMessageInput
          data={allCurrentChatData}
          as="MessageInput"
          onSendMessage={onSendMessageBot}
          disabled={isTypingBot}
          onOpenModalPayment={onOpenModalPayment}
        />
      )}
    </ChatContainerLibrary>
  );
};

export default ChatContainer;
