import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import CheckboxMUI from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";

const Checkbox = ({
  control,
  rules = {
    required: {
      value: true,
      message: "Este campo es requerido",
    },
  },
  errors = {},
  label = "",
  variant = "standard",
  name = "",
  value = "",
  fullWidth = false,
  options = [],
  actionsChange = () => {},
  sxFormGroup = {},
  dataTour = "",
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <FormControl
          component="fieldset"
          variant={variant}
          data-tour={dataTour}
          fullWidth={fullWidth}
          name={field.name}
          error={!!errors[name]}
        >
          <FormLabel component="legend" sx={{ fontWeight: 600 }}>
            {label}
          </FormLabel>
          <FormGroup sx={{ ...sxFormGroup }}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                disabled={option.disabled || false}
                control={
                  <CheckboxMUI
                    checked={field.value[option.value] ?? false}
                    name={option.value}
                  />
                }
                label={option.label}
                onChange={(e) => {
                  field.onChange({
                    ...field.value,
                    [option.value]: e.target.checked,
                  });
                  actionsChange({
                    ...field.value,
                    [option.value]: e.target.checked,
                  });
                }}
              />
            ))}
          </FormGroup>
          {!!errors[name] && (
            <Typography variant="caption" color="red">
              {!!errors[name] ? errors[name]?.message : ""}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default Checkbox;
