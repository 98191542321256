import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { INVITE_PERSONALIZED_URL_EXPLANATION } from "../../../../locales/keysTranslations";

const ContentTrackingURL = () => {
  const { t } = useTranslationApp();
  return (
    <React.Fragment>
      <Typography color="inherit" variant="body1" sx={{ mb: 1 }}>
        {t(INVITE_PERSONALIZED_URL_EXPLANATION)}
      </Typography>
    </React.Fragment>
  );
};

export default ContentTrackingURL;
