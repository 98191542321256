import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InfluencerPost from "../InfluencerPost";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { INFLUENCER_POPULAR_CONTENT } from "../../../../locales/keysTranslations";

const InfluencerPopularPosts = ({ data = [] }) => {
  const { t } = useTranslationApp();
  if (data.length === 0) return null;
  return (
    <Box sx={{ my: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          fontStyle: "normal",
        }}
      >
        {t(INFLUENCER_POPULAR_CONTENT)}
      </Typography>
      <Grid container spacing={2}>
        {data.map((post, index) => {
          if (index < 8) {
            return (
              <Grid item xs={12} sm={6} lg={4} xl={3} key={post?.url}>
                <InfluencerPost data={post} />
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </Box>
  );
};

export default InfluencerPopularPosts;
