import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DEFAULT_CREATORS_CARDS,
  DELAY_DEBOUNCE,
  FROM_DATA_INFLUENCERS,
  FROM_DATA_PHYLLO,
  INSTAGRAM,
  TAB_CREATORS_INDEX,
  TIKTOK,
} from "../../../utils/constants";
import {
  getCountry,
  getInfluencerIsHiddenFromTables,
  getIsInfluencerInvited,
} from "../../../actions/getters";
import InfluencerCard from "../Creators/InfluencerCard";
import { isFilteringInfluencers } from "../../../services/shops";
import {
  BUTTON_ADJUST_PREFERENCES,
  CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER,
  CREATORS_SUGGESTED_EMPTY,
  EMPTY_RESULTS_SHOW,
  GO_TO_PROFILE,
  LOADING_SUGGESTED_INFORMATION,
  NO_MORE_RESULTS_TO_SHOW,
  WE_ARE_DOING_A_DEEPER_SEARCH,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import {
  startGetInfluencersPhylloByFilters,
  startGetInfluencersSuggested,
} from "../../../actions/influencers";
import { useDebounced } from "../../../hooks/useDebounce";
import useCreatorsFilters from "../../../hooks/useCreatorsFilters";
import { validationProfileInfluencer } from "../../../utils/influencers";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../Common/Buttons/Button";
import LinearLoaderWithTime from "../../Common/Loader/LinearLoaderWithTime";
import { usePagination } from "../../../hooks/usePagination";
import _ from "lodash";
import Loader from "../../Common/Loader/Loader";
import { randomNumberBetween } from "../../../utils/numbers";
import {
  INTERNAL_SERVER_ERROR_PHYLLO,
  REQUEST_TIME_OUT_PHYLLO,
} from "../../../utils/errors";

const InfluencersSuggestedTable = ({ filters = {}, onChangeTab }) => {
  const [data, setData] = useState({});
  const [errorPhyllo, setErrorPhyllo] = useState(null);
  const [fromData, setFromData] = useState(FROM_DATA_INFLUENCERS);
  const [emptyPlatformPhyllo, setEmptyPlatformPhyllo] = useState({
    [TIKTOK]: false,
    [INSTAGRAM]: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [influencersDiscarded, setInfluencersDiscarded] = useState([]);
  const influencersLoadedByMainAccountName = Object.keys(data).map(
    (key) => data[key]?.mainAccountName
  );

  const { onInfluencerPassFilter, onReset } = useCreatorsFilters();

  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
  } = filters;

  const { t } = useTranslationApp();
  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const invites = useSelectorApp((state) => state.invites.invites);
  const profileInfluencer = useSelectorApp(
    (state) => state.shop.profileInfluencer
  );
  const signUpCountry = dispatch(getCountry("shop"));

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
  });

  const getInformationIsInvited = (userID) => {
    const inviteID = dispatch(getIsInfluencerInvited(userID));
    if (inviteID) {
      return {
        isActive: true,
        isInvited: true,
        inviteID,
        userID,
      };
    }
    return {
      isActive: true,
      isInvited: false,
      userID,
    };
  };

  const onGetData = useDebounced(
    async (type = "first", forcedDataFrom) => {
      if (type === "first") {
        setIsLoading(true);
      }

      const dataFromToPass = forcedDataFrom || fromData;
      let influencers;

      if (dataFromToPass === FROM_DATA_INFLUENCERS) {
        influencers = await dispatch(startGetInfluencersSuggested({}));
        onChangeFromData(FROM_DATA_PHYLLO);
        if (_.isEmpty(influencers)) {
          onGetData("first", FROM_DATA_PHYLLO);
          return;
        }
      }
      if (dataFromToPass === FROM_DATA_PHYLLO) {
        let genderPhyllo = "";
        const profileInfluencerGender = profileInfluencer?.gender || {};
        const gendersTrue = Object.values(profileInfluencerGender).filter(
          (gender) => gender
        );

        if (gendersTrue.length === 1) {
          const genderTrue = Object.keys(profileInfluencerGender).find(
            (key) => profileInfluencerGender[key]
          );
          genderPhyllo = genderTrue;
        }

        const categories = profileInfluencer.categories || [];
        const categorySelected =
          categories[randomNumberBetween(0, categories.length - 1)];
        const topics = categorySelected
          ? [
              {
                name: categorySelected,
                value: categorySelected,
              },
            ]
          : [];

        influencers = await dispatch(
          startGetInfluencersPhylloByFilters({
            gender: genderPhyllo,
            topics: topics,
            followers: rangeFollowers,
            engagement: rangeEngagement,
            lastKey,
            onChangeLastKey: () => onChangeLastKey(lastKey + rowsPerPage),
            onLoadEmptyPlatform: (platform) => {
              setEmptyPlatformPhyllo((prev) => ({
                ...prev,
                [platform]: true,
              }));
            },
            onChangeErrorCode: (error) => setErrorPhyllo(error),
            onChangeLoadLastItem: () => onChangeLoadLastItem(true),
            platforms: Object.keys(emptyPlatformPhyllo).filter(
              (platform) => !emptyPlatformPhyllo[platform]
            ),
            limit: rowsPerPage,
          })
        );
        if (type !== "first" && !_.isEmpty(influencers)) {
          influencers = onFilterInfluencersPhylloAlreadyLoaded(influencers);
        }
      }

      if (influencers) {
        const dataFormatted = {};

        Object.keys(influencers || {}).forEach((key) => {
          const influencer = influencers[key];
          const isInvited = getInformationIsInvited(key);

          dataFormatted[key] = {
            ...influencer,
            ...isInvited,
          };
        });

        if (type === "first") {
          setData(dataFormatted);
        } else {
          setData((prevData) => {
            return {
              ...prevData,
              ...dataFormatted,
            };
          });
        }
      }

      setIsLoading(false);
    },
    [DELAY_DEBOUNCE]
  );

  const onFilterInfluencersPhylloAlreadyLoaded = (newInfluencers) => {
    const influencersFiltered = {};
    Object.keys(newInfluencers).forEach((key) => {
      const influencer = newInfluencers[key];
      if (
        influencersLoadedByMainAccountName.includes(influencer?.mainAccountName)
      )
        return;

      influencersFiltered[key] = influencer;
    });
    return influencersFiltered;
  };

  const onFilterData = () => {
    const influencersKeys = Object.keys(data);

    if (influencersKeys.length === 0) return {};

    const filtered = {};

    for (let influencerKey of influencersKeys) {
      const influencer = data[influencerKey] || {};

      if (influencersDiscarded.includes(influencerKey) && isMobile) continue;

      const passFilter = onInfluencerPassFilter({
        influencer,
        userID: influencerKey,
        filters,
      });

      if (!passFilter) continue;

      filtered[influencerKey] = influencer;
    }

    return filtered;
  };

  const onChangeFromData = (value) => {
    setFromData(value);
    onChangeLastKey(value === FROM_DATA_INFLUENCERS ? null : 0);
  };

  const onDiscardInfluencer = async (userID) => {
    if (keysSorted.length - 1 === 0) {
      setIsLoading(true);
    }
    if (keysSorted.length === 1) {
      onPageChange(page + 1);
    }
    setInfluencersDiscarded((prev) => [...prev, userID]);
  };

  const dataFiltered = onFilterData();
  const keysSorted = Object.keys(dataFiltered).sort(
    (a, b) => dataFiltered[b]?.score - dataFiltered[a]?.score
  );

  const currentCreator = data[keysSorted[0]];

  const {
    fromRef,
    page,
    onPageChange,
    lastKey,
    onChangeLastKey,
    rowsPerPage,
    loadLastItem,
    onChangeLoadLastItem,
  } = usePagination({
    rowsPerPageValue: DEFAULT_CREATORS_CARDS,
    distance: "700px",
    onCallBackNextPage: () => {
      onGetData("next");
    },
  });

  useEffect(() => {
    if (fromData === FROM_DATA_PHYLLO && isFiltering) {
      onChangeTab(TAB_CREATORS_INDEX);
    }
    setIsLoading(true);
    onGetData("first", FROM_DATA_INFLUENCERS);
    setEmptyPlatformPhyllo({
      [TIKTOK]: false,
      [INSTAGRAM]: false,
    });
    setErrorPhyllo(null);
    // eslint-disable-next-line
  }, [
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    signUpCountry,
  ]);

  useEffect(() => {
    if (emptyPlatformPhyllo?.[TIKTOK] && emptyPlatformPhyllo?.[INSTAGRAM]) {
      onChangeLoadLastItem(true);
    }
    // eslint-disable-next-line
  }, [emptyPlatformPhyllo?.[TIKTOK], emptyPlatformPhyllo?.[INSTAGRAM]]);

  useEffect(() => {
    onReset();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const invitesKeys = Object.keys(invites || {});
    if (invitesKeys.length === 0) return;

    const dataFormatted = {};

    Object.keys(data).forEach((key) => {
      const influencer = data[key];
      const isInvited = getInformationIsInvited(key);

      dataFormatted[key] = {
        ...influencer,
        ...isInvited,
      };
    });

    setData(dataFormatted);

    // eslint-disable-next-line
  }, [invites]);

  const isInvalidProfileInfluencer = !validationProfileInfluencer(
    profileInfluencer,
    "profileInfluencer"
  );

  if (isInvalidProfileInfluencer)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body1" sx={{ maxWidth: "75ch" }} align="center">
          {t(CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER)}
        </Typography>
        <Link to={`/account?focus=profileInfluencer`}>
          <Button sx={{ mt: 2 }}>{t(GO_TO_PROFILE)}</Button>
        </Link>
      </Box>
    );

  if (isLoading) {
    return (
      <LinearLoaderWithTime
        label={t(LOADING_SUGGESTED_INFORMATION)}
        time={50 * 1000}
      />
    );
  }

  if (keysSorted.length === 0) {
    if (influencersDiscarded.length > 0) {
      return (
        <Typography variant="body1" align="center">
          {t(NO_MORE_RESULTS_TO_SHOW)}
        </Typography>
      );
    }
    if (
      errorPhyllo === REQUEST_TIME_OUT_PHYLLO ||
      errorPhyllo === INTERNAL_SERVER_ERROR_PHYLLO
    ) {
      <Typography variant="body1" align="center">
        {t(WE_ARE_DOING_A_DEEPER_SEARCH)}
      </Typography>;
    }
    if (isFiltering) {
      return (
        <Typography variant="body1" align="center">
          {t(EMPTY_RESULTS_SHOW)}
        </Typography>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Typography variant="body1" align="center">
          {t(CREATORS_SUGGESTED_EMPTY)}
        </Typography>
        <Button
          color="secondary"
          onClick={() => navigate("/account?focus=profileInfluencer")}
        >
          {t(BUTTON_ADJUST_PREFERENCES)}
        </Button>
      </Box>
    );
  }

  return (
    <Grid container spacing={1}>
      {!isMobile &&
        keysSorted.map((key, index) => {
          const influencer = data[key] || {};
          if (dispatch(getInfluencerIsHiddenFromTables({ userID: key })))
            return null;

          return (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={key}>
              <InfluencerCard data={influencer} />
            </Grid>
          );
        })}
      {isMobile && currentCreator && (
        <Grid item xs={12}>
          <InfluencerCard
            data={currentCreator}
            onDiscardInfluencer={onDiscardInfluencer}
          />
        </Grid>
      )}
      {!loadLastItem && !isMobile && (
        <Grid item xs={12}>
          <div ref={fromRef}>
            <Loader size={30} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default InfluencersSuggestedTable;
