import React from "react";
import FormControl from "@mui/material/FormControl";
import SwitchMui from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { t } from "i18next";
import { ACTIVATE, DEACTIVATE } from "../../../../locales/keysTranslations";
import {
  GREEN_COLOR_LIGHT,
  GREY_DISABLED_COLOR,
  SWITCH_THUMB,
} from "../../../../utils/colors";

const Switch = ({
  isActive,
  size = "medium",
  onChangeIsActive = () => {},
  hasLabel = true,
  labelActive = DEACTIVATE,
  labelDeactivate = ACTIVATE,
  colorActivate = GREEN_COLOR_LIGHT,
  colorDeactivate = GREY_DISABLED_COLOR,
  labelPlacement = "start",
  sx = {},
  disabled = false,
  dataTour,
}) => {
  const onChangeSwitchActive = (e) => {
    onChangeIsActive(e.target.checked, e);
  };
  return (
    <FormControl
      disabled={disabled}
      component="fieldset"
      variant="standard"
      sx={{ ...sx }}
    >
      <FormControlLabel
        data-tour={dataTour}
        sx={{
          display: "flex",
          ".MuiFormControlLabel-label": {
            fontSize: "0.915rem",
            fontWeight: 600,
          },
          ml: 0,
        }}
        onClick={(e) => e.stopPropagation()}
        control={
          <SwitchMui
            size={size}
            color={isActive ? "primary" : "warning"}
            checked={isActive}
            onChange={(e) => {
              e.stopPropagation();
              onChangeSwitchActive(e);
            }}
            sx={{
              ".MuiSwitch-track": {
                background: `${
                  isActive ? colorActivate : GREY_DISABLED_COLOR
                } !important`,
                opacity: "1 !important",
              },
              ".MuiSwitch-thumb": {
                backgroundColor: `${SWITCH_THUMB} !important`,
              },
            }}
          />
        }
        labelPlacement={labelPlacement}
        label={hasLabel ? (isActive ? t(labelActive) : t(labelDeactivate)) : ""}
      />
    </FormControl>
  );
};

export default Switch;
