import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeFirstLetter } from "../../../utils/formats";
import Loader from "../Loader/Loader";

const RadioSelectCard = ({
  cardInfo,
  cardID,
  disabled,
  loading,
  onDeletePaymentMethod,
}) => {
  const { defaultPaymentMethod } = useSelectorApp(
    (state) => state.payments.stripe
  );

  return (
    <Paper
      square={true}
      elevation={1}
      sx={{
        px: 2,
        pt: 1,
        backgroundColor: "background.paperSecondary",
        display: "flex",
        gap: 1,
        pb: 1,
      }}
    >
      <FormControlLabel
        labelPlacement="start"
        value={cardID}
        control={<Radio sx={{}} />}
        sx={{ width: "100%", justifyContent: "space-between", ml: 0 }}
        label={`${capitalizeFirstLetter(cardInfo.brand)} •••• ${
          cardInfo.last4
        }`}
      />
      {cardID !== defaultPaymentMethod.id && (
        <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
          {loading ? (
            <Loader size={25} hasMarginTop={false} />
          ) : (
            <IconButton
              onClick={() => onDeletePaymentMethod(cardID)}
              disabled={disabled}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default RadioSelectCard;
