import types from "../types";

const initialState = {
  notifications: {},
  isLoading: true,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_NOTIFICATIONS_FINISH:
      return {
        ...state,
        notifications: action.payload,
        isLoading: false,
      };
    case types.GET_ALL_NOTIFICATIONS_PAGINATION_FINISH:
      return {
        ...state,
        notifications: { ...state.notifications, ...action.payload },
      };
    case types.UPDATE_IS_READ_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.key]: {
            ...state.notifications[action.payload.key],
            isRead: action.payload.data,
          },
        },
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
