import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import ConsumptionsTable from "../components/User/Consumptions/ConsumptionsTable";
import {
  createOrder,
  editOrder,
  startGetOrdersByShop,
} from "../actions/orders";
import { usePagination } from "../hooks/usePagination";
import { DEFAULT_TABLE_PAGES, POST_STATUS_PENDING } from "../utils/constants";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { startOpenPublicationConversation } from "../actions/chat";
import {
  DUMMY_INVITE,
  DUMMY_ORDER,
  PAGE_PUBLICATIONS,
} from "../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../lib/i18next";
import {
  PUBLICATIONS_DESCRIPTION,
  PUBLICATIONS_TITLE,
} from "../locales/keysTranslations";
import ModalGalleryArrowButtons from "../components/Common/Gallery/ModalGalleryArrowButtons";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import _ from "lodash";

const Consumptions = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [orderSelected, setOrderSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatchApp();
  const { user } = useSelectorApp((state) => state.auth);
  const { orders } = useSelectorApp((state) => state.orders);
  const { influencers } = useSelectorApp((state) => state.influencers);

  const { t } = useTranslationApp();

  const dummyInvite = useSelectorApp(
    (state) => state.invites.invites[DUMMY_INVITE]
  );

  const { isOpen, currentStep, steps } = useTour();

  const onOpenModal = (orderID) => {
    setModalOpen(true);
    setOrderSelected(orderID);
  };
  const onCloseModal = () => {
    setModalOpen(false);
    setOrderSelected(null);
  };

  const getNextOrders = async () => {
    await dispatch(
      startGetOrdersByShop({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(
      startGetOrdersByShop({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
        onCallbackFinish: () => setIsLoading(false),
      })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextOrders,
  });

  useEffect(() => {
    if (_.isEmpty(orders)) return;

    let filtered = {};
    Object.keys(orders).forEach((key) => {
      filtered[key] = {
        ...orders[key],
        date: new Date(orders[key]?.date),
      };
    });
    setData(filtered);
  }, [orders]);

  useEffect(() => {
    if (isOpen) {
      if (steps[currentStep].actionData === "create") {
        dispatch(
          createOrder({
            data: {
              value: 100000,
              shopID: user.shopID,
              userID: dummyInvite.userID,
              postStatus: POST_STATUS_PENDING,
              date: new Date().toISOString(),
              inviteID: DUMMY_INVITE,
              isDelivery: false,
              storeID: "DUMMY_STORE",
              analyticsData: {
                accountsReached: 942,
                creationTime: 1693092742008,
                engagement: 0.01,
                orderID: DUMMY_ORDER,
                stickerTaps: 13,
                comments: 4,
                likes: 32,
                shares: 3,
              },
            },
            key: DUMMY_ORDER,
          })
        );
      }
      if (steps[currentStep].actionData === "edit") {
        dispatch(
          editOrder({
            data: steps[currentStep].data,
            key: DUMMY_ORDER,
          })
        );
      }
    }
    //eslint-disable-next-line
  }, [currentStep]);

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [user.shopID]);

  const onOpenChatPublication = (orderID) => {
    const publication = data[orderID];
    dispatch(
      startOpenPublicationConversation({
        chatID: publication.chatID,
        userID: publication.userID,
        orderID,
      })
    );
  };

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <TitlePage dataTour={PAGE_PUBLICATIONS}>
          {t(PUBLICATIONS_TITLE)}
        </TitlePage>
        <DescriptionPage>{t(PUBLICATIONS_DESCRIPTION)}</DescriptionPage>
        {/* <SearchBar
        placeholder="Buscar Publicaciones"
        onEditText={(text) => onSearch(text)}
      /> */}

        <ConsumptionsTable
          data={data}
          loading={isLoading}
          influencers={influencers}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          onOpenChatPublication={onOpenChatPublication}
          onSelectOrder={onOpenModal}
        />
        {modalOpen && (
          <ModalGalleryArrowButtons
            modalOpen={modalOpen}
            onCloseModal={() => onCloseModal()}
            mode="order"
            orderID={orderSelected}
          />
        )}
      </ContainerPage>
    </BlockPlanNoActive>
  );
};

export default Consumptions;
