import React, { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import { useSelectorApp } from "../../../lib/redux";
import { Controller, useForm } from "react-hook-form";
import {
  ALERT_ICON_TYPE_SUCCESS,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
} from "../../../utils/constants";
import Avatar from "@mui/material/Avatar";
import TextField from "../Fields/TextField";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ClearIcon from "@mui/icons-material/Clear";
import { ChatContext } from "../../../Contexts/ChatContext";
import { waitDelay } from "../../../utils/dates";
import { ConfirmAlert, SimpleAlert } from "../../../utils/alerts";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { AMBASSADORS_BROADCAST_FORM } from "../../../onboarding/stepsSelectors";
import {
  BROADCAST_MESSAGE_SENT,
  BUTTON_CANCEL,
  BUTTON_SEND_BROADCAST,
  BUTTON_UPLOAD_FILE,
  FIELD_REQUIRED,
  FILE_SELECTED,
  MODAL_BROADCAST_ARIAL_LABEL,
  MODAL_BROADCAST_CONFIRM_DESCRIPTION,
  MODAL_BROADCAST_CONFIRM_TITLE,
  MODAL_BROADCAST_DESCRIPTION,
  MODAL_BROADCAST_MESSAGE_PLACEHOLDER,
  MODAL_BROADCAST_SELECT_USER_PLACEHOLDER,
  MODAL_BROADCAST_SELECT_USER_REQUIRED,
  MODAL_BROADCAST_TITLE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import ContainerModalActions from "../Containers/ContainerModalActions";

const ModalBroadcast = ({ modalOpen, onCloseModal }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { onAddBroadcastMessage } = useContext(ChatContext);
  const { handleSubmit, control, setValue, watch, formState } = useForm({
    defaultValues: {
      chatsSelected: [],
      message: "",
      file: {
        file: null,
        value: "",
      },
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();

  const chats = useSelectorApp((state) => state.chat.chats);
  const { ambassadors } = useSelectorApp((state) => state.ambassadors);
  const influencers = useSelectorApp((state) => state.influencers.influencers);

  const onSubmit = async (data) => {
    const result = await ConfirmAlert({
      title: t(MODAL_BROADCAST_CONFIRM_TITLE),
      text: t(MODAL_BROADCAST_CONFIRM_DESCRIPTION),
      confirmButtonText: t(BUTTON_SEND_BROADCAST),
    });
    if (!result.isConfirmed) return;

    setIsFetching(true);
    const response = await onAddBroadcastMessage({
      message: data.message,
      file: data.file.file,
      chatsIDs: data.chatsSelected,
    });
    await waitDelay(1000);
    setIsFetching(false);
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(BROADCAST_MESSAGE_SENT),
        type: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
      ariaLabel={t(MODAL_BROADCAST_ARIAL_LABEL)}
      title={t(MODAL_BROADCAST_TITLE)}
      description={t(MODAL_BROADCAST_DESCRIPTION)}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        data-tour={AMBASSADORS_BROADCAST_FORM}
      >
        <Controller
          name="chatsSelected"
          control={control}
          rules={{
            validate: {
              value: (value) => {
                if (value.length === 0) {
                  return t(MODAL_BROADCAST_SELECT_USER_REQUIRED);
                }
              },
            },
          }}
          render={({ field }) => (
            <FormControl
              sx={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
              }}
              component="fieldset"
              variant="standard"
              error={!!errors.chatsSelected}
            >
              <FormLabel component="legend" sx={{ mb: 1 }}>
                {t(MODAL_BROADCAST_SELECT_USER_PLACEHOLDER)}
              </FormLabel>
              <FormGroup
                sx={{
                  display: "flex",
                  gap: 1,
                  maxHeight: 300,
                  overflowY: "auto",
                  flexWrap: "nowrap",
                }}
              >
                {Object.keys(ambassadors).map((ambassadorID) => {
                  const ambassador = ambassadors[ambassadorID];
                  const influencer = influencers[ambassador.userID];
                  const chatID = ambassador.chatID;
                  const chat = chats[chatID];

                  if (!chat && !chatID) return null;

                  return (
                    <Box
                      key={chatID}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Avatar
                        src={influencer.imageURL}
                        alt={`ImageURL of ${influencer.mainAccountName}`}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value.includes(chatID)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                field.onChange([...field.value, chatID]);
                              } else {
                                field.onChange(
                                  field.value.filter(
                                    (chatIDFilter) => chatIDFilter !== chatID
                                  )
                                );
                              }
                            }}
                          />
                        }
                        label={influencer.mainAccountName}
                      />
                    </Box>
                  );
                })}
              </FormGroup>
              {errors?.chatsSelected?.message && (
                <FormHelperText>
                  {errors?.chatsSelected?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
        <TextField
          name="message"
          label={t(MODAL_BROADCAST_MESSAGE_PLACEHOLDER)}
          multiline
          minRows={2}
          maxRows={4}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        {watch("file").file ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {t(FILE_SELECTED, {
                fileName: watch("file")?.file?.name,
              })}
            </Typography>
            <IconButton
              color="primary"
              onClick={() =>
                setValue("file", {
                  value: "",
                  file: null,
                })
              }
            >
              <ClearIcon />
            </IconButton>
          </Box>
        ) : (
          <Controller
            name="file"
            control={control}
            render={({ field }) => {
              return (
                <LoadingButton
                  component="label"
                  endIcon={<AttachmentIcon />}
                  sx={{
                    minWidth: 139,
                  }}
                >
                  <input
                    hidden
                    accept={`${IMAGE_FORMATS.map(
                      (item) => `image/${item},`
                    ).join(" ")}, ${VIDEO_FORMATS.map(
                      (item) => `video/${item},`
                    ).join(" ")}, application/pdf`}
                    name="file"
                    type="file"
                    onChange={(e) => {
                      field.onChange({
                        file: e.target.files[0],
                        value: "",
                      });
                    }}
                  />
                  {t(BUTTON_UPLOAD_FILE)}
                </LoadingButton>
              );
            }}
          />
        )}
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
            disabled: isFetching,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SEND_BROADCAST,
            type: "submit",
            loading: isFetching,
            onClick: () => {},
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalBroadcast;
