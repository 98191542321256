import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinkText from "../Texts/LinkText";

const ContainerKeyValue = ({
  keyValue,
  value,
  variantText = "body1",
  redirectURL = "",
  redirectValue = "",
  onClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Typography
        variant={variantText}
        color="inherit"
        sx={{ fontWeight: 600 }}
      >
        {keyValue}
      </Typography>
      {redirectURL !== "" ? (
        <LinkText
          href={`${redirectURL}=${redirectValue || value}`}
          target="_blank"
        >
          <Typography variant={variantText} sx={{ wordBreak: "break-word" }}>
            {value}
          </Typography>
        </LinkText>
      ) : (
        <Typography
          variant={variantText}
          color="inherit"
          sx={{
            wordBreak: "break-word",
            cursor: onClick ? "pointer" : "default",
            textDecoration: onClick ? "underline" : "none",
            "&:hover": {
              textDecoration: onClick ? "underline" : "none",
              cursor: onClick ? "pointer" : "default",
            },
          }}
          onClick={onClick ? () => onClick() : () => {}}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

export default ContainerKeyValue;
