import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InfluencerSocialNetwork from "../../Common/Influencer/InfluencerSocialNetwork";
import ReviewForm from "./ReviewForm";
import { generateRandomCode } from "../../../utils/generateRandomsValues";
import { useForm } from "react-hook-form";
import { startGetAmbassador } from "../../../actions/influencers";
import { useDispatchApp } from "../../../lib/redux";
import _ from "lodash";
import { STEP_AMBASSADORS_FUNCTIONALITY } from "../../../onboarding/stepsSelectors";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { useTour } from "@reactour/tour";
import {
  BUTTON_CANCEL,
  BUTTON_FINISH,
  DO_AMBASSADOR,
  MODAL_REVIEW_ARIA_LABEL,
  RECURRENT_VALUE_NOT_FOUND,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const ModalReview = ({ data, modalOpen, onCloseModal, onReviewInfluencer }) => {
  const { isOpen, steps, currentStep } = useTour();

  const step = steps[currentStep];

  const { t } = useTranslationApp();

  const { handleSubmit, control, setValue, watch, formState } = useForm({
    defaultValues: {
      doAmbassador:
        isOpen && step?.onboardingName === STEP_AMBASSADORS_FUNCTIONALITY
          ? true
          : false,
      withRecurrentInvite:
        isOpen && step?.onboardingName === STEP_AMBASSADORS_FUNCTIONALITY
          ? true
          : false,
      score: 0,
      comment: "",
      nextConsumption: "",
      consumptionValue: 0,
      inviteCode: "",
      withRecurrentPayment: false,
      valueToPay: 0,
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const [isAmbassador, setIsAmbassador] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatchApp();
  const doAmbassador = watch("doAmbassador");

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (!isOpen) {
        const isAmbassador = await dispatch(startGetAmbassador(data.userID));
        setIsAmbassador(isAmbassador ? true : false);
      } else {
        setIsAmbassador(false);
      }
      setIsFetching(false);
    })();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    setIsSaving(true);

    let reviewData = {
      score: data.score,
      comment: data.comment,
      doAmbassador: data.doAmbassador,
      withRecurrentInvite: data.withRecurrentInvite,
      withRecurrentPayment: data.withRecurrentPayment,
      isAmbassador,
    };
    if (data.doAmbassador && !data.isAmbassador) {
      if (_.isEmpty(data.recurrentPattern) && data.withRecurrentInvite)
        return (errors.day = {
          message: t(RECURRENT_VALUE_NOT_FOUND),
        });

      if (_.isEmpty(data.recurrentPatternPayment) && data.withRecurrentPayment)
        return (errors.day = {
          message: t(RECURRENT_VALUE_NOT_FOUND),
        });

      reviewData = {
        ...reviewData,
        inviteCode:
          data.inviteCode === ""
            ? generateRandomCode(8)
            : data.inviteCode.trim(),
        recurrentPattern: data.recurrentPattern,
        consumptionValue: data.value,
        recurrentPatternPayment: data.recurrentPatternPayment,
        valueToPay: data.valueToPay,
      };
    }

    const response = await onReviewInfluencer(reviewData);
    if (response) {
      onCloseModal();
    }
    setIsSaving(false);
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_REVIEW_ARIA_LABEL)}
    >
      <Box
        sx={{
          display: "flex",
          mb: 1,
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: 1,
        }}
      >
        <InfluencerSocialNetwork
          influencerPlatform={data.mainPlatform}
          influencerName={data.mainAccountName}
        />
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 2,
          gap: 2,
        }}
      >
        <ReviewForm
          isAmbassador={isAmbassador}
          isFetching={isFetching}
          control={control}
          watch={watch}
          setValue={setValue}
          errors={errors}
        />
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: doAmbassador ? DO_AMBASSADOR : BUTTON_FINISH,
            onClick: () => {},
            loading: isSaving,
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalReview;
