import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Controller, useForm } from "react-hook-form";
import { useDispatchApp } from "../../../../lib/redux";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { startGenerateReports } from "../../../../actions/owner";
import DatesForm from "../DatesForm";
import Loader from "../../../Common/Loader/Loader";
import SelectShops from "../SelectShops";
import { startGetAllShops } from "../../../../actions/shops";
import Button from "../../../Common/Buttons/Button";
import format from "date-fns/format";
import { generateXLSXDataRoom } from "../../../../utils/xlsx";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxMUI from "@mui/material/Checkbox";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_DOWNLOAD_REPORT,
  FIELD_ALL_REQUIRED,
  FIELD_START_DATE_GREATER_THAN_END_DATE,
  LOADING_INFORMATION,
  REPORTS_ERROR_GENERATING,
  REPORTS_MODAL_ONLY_BRANDS_ACTIVE,
  REPORTS_MODAL_TYPE_REPORT_BRANDS,
  REPORTS_MODAL_TYPE_REPORT_CREATORS,
  REPORTS_MODAL_TYPE_REPORT_INVITATIONS,
  REPORTS_MODAL_TYPE_REPORT_LABEL,
  REPORTS_MODAL_TYPE_REPORT_ORDERS,
} from "../../../../locales/keysTranslations";
import Select from "../../../Common/Selects/Select";

const Reports = () => {
  const [data, setData] = useState(null);
  const [isCalculated, setIsCalculated] = useState(false);
  const { control, getValues, watch } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
      report: "",
      onlyShopsActive: false,
      shops: [],
    },
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const report = watch("report");

  useEffect(() => {
    if (report === "orders") {
      dispatch(startGetAllShops());
    }
    setIsCalculated(false);
    // eslint-disable-next-line
  }, [report]);

  const onCalculateReport = async () => {
    const formValues = getValues();
    formValues.startDate = startOfDay(formValues.startDate);
    formValues.endDate = endOfDay(formValues.endDate);

    if (!formValues.startDate || !formValues.endDate || !formValues.report) {
      return setError(t(FIELD_ALL_REQUIRED));
    }
    if (
      new Date(formValues.startDate).getTime() >
      new Date(formValues.endDate).getTime()
    ) {
      return setError(t(FIELD_START_DATE_GREATER_THAN_END_DATE));
    }
    setError(null);
    setIsCalculated(false);
    setLoading(true);
    const response = await dispatch(
      startGenerateReports({
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        report: formValues.report,
        shops: formValues.shops,
        onlyShopsActive: formValues.onlyShopsActive,
      })
    );
    if (!response) {
      setIsCalculated(false);
      setLoading(false);
      return setError(t(REPORTS_ERROR_GENERATING));
    }
    setData(response);
    setIsCalculated(true);
    setLoading(false);
    return true;
  };

  const onDownloadReport = () => {
    const formValues = getValues();
    let nameFile;
    let type;
    if (formValues.report === "shops") {
      nameFile = `Brands - ${format(
        new Date(formValues.startDate),
        "dd MMM yyyy"
      )} - ${format(new Date(formValues.endDate), "dd MMM yyyy")}`;
      type = "Shops";
    }
    if (formValues.report === "creators") {
      nameFile = `Creators - ${format(
        new Date(formValues.startDate),
        "dd MMM yyyy"
      )} - ${format(new Date(formValues.endDate), "dd MMM yyyy")}`;
      type = "Creators";
    }
    if (formValues.report === "orders") {
      nameFile = `Orders - ${format(
        new Date(formValues.startDate),
        "dd MMM yyyy"
      )} - ${format(new Date(formValues.endDate), "dd MMM yyyy")}`;
      type = "Orders";
    }
    if (formValues.report === "invites") {
      nameFile = `Invites - ${format(
        new Date(formValues.startDate),
        "dd MMM yyyy"
      )} - ${format(new Date(formValues.endDate), "dd MMM yyyy")}`;
      type = "Invites";
    }
    return generateXLSXDataRoom({ data, nameFile, type });
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 2,
          mb: 2,
        }}
      >
        <Select
          label={t(REPORTS_MODAL_TYPE_REPORT_LABEL)}
          variant="filled"
          name="report"
          options={[
            { value: "shops", label: t(REPORTS_MODAL_TYPE_REPORT_BRANDS) },
            { value: "creators", label: t(REPORTS_MODAL_TYPE_REPORT_CREATORS) },
            { value: "orders", label: t(REPORTS_MODAL_TYPE_REPORT_ORDERS) },
            {
              value: "invites",
              label: t(REPORTS_MODAL_TYPE_REPORT_INVITATIONS),
            },
          ]}
          control={control}
        />
        {report === "shops" && (
          <Controller
            control={control}
            name="onlyShopsActive"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<CheckboxMUI checked={field.value} />}
                label={t(REPORTS_MODAL_ONLY_BRANDS_ACTIVE)}
              />
            )}
          />
        )}
        {report === "orders" && <SelectShops control={control} />}
        <DatesForm
          control={control}
          onActionButton={onCalculateReport}
          sx={{ mt: 0 }}
          colorButton="secondary"
        />
      </Box>
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Box>
      )}
      {!isCalculated && loading && (
        <Loader
          size={70}
          fullWidth={true}
          hasMessage={true}
          variantMessage="h6"
          message={t(LOADING_INFORMATION)}
        />
      )}
      {isCalculated && !loading && (
        <Button
          sx={{ mb: 2, textDecoration: "none" }}
          onClick={onDownloadReport}
        >
          {t(BUTTON_DOWNLOAD_REPORT)}
        </Button>
      )}
    </Box>
  );
};

export default Reports;
