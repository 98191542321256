import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useTabs = ({ initialTab = 1, tabs = [] }) => {
  const [tab, setTab] = useState(initialTab);
  const [params] = useSearchParams();
  const tabURL = params.get("tab");

  useEffect(() => {
    if (tabURL && typeof parseInt(tabURL) === "number") {
      setTab(parseInt(tabURL));
    }
  }, [tabURL]);

  const onChangeTab = (tab) => {
    setTab(tab);
  };

  return { tab, onChangeTab, tabs };
};

export default useTabs;
