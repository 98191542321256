import React from "react";
import { Controller } from "react-hook-form";
import SliderBase from "./SliderBase";
import { textNumberCompactFormat } from "../../../../utils/slidersMarks";
import { BUDGET_CAMPAIGN_DATA } from "../../../../utils/constants";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { getMarks, getMinValue } from "../../../../services/campaigns";

const SliderBudgetCampaign = ({
  name,
  control,
  errors,
  rules = {},
  variantTitle,
  disabled = false,
  watch,
}) => {
  const LOCALE = useLocale();
  const isUgcCampaign = watch("isUgcCampaign");
  const typeInfluencers = watch("typeInfluencers");

  const minValue = getMinValue({
    LOCALE,
    isUgcCampaign,
    typeInfluencers,
  });
  const marks = getMarks({
    marks: BUDGET_CAMPAIGN_DATA.marks,
    LOCALE,
    isUgcCampaign,
    typeInfluencers,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <SliderBase
          disabled={disabled}
          onChange={field.onChange}
          range={field.value}
          errors={errors}
          min={minValue}
          max={BUDGET_CAMPAIGN_DATA.max}
          marks={marks}
          step={BUDGET_CAMPAIGN_DATA.step}
          getAriaValueText={textNumberCompactFormat}
          variantTitle={variantTitle}
        />
      )}
    />
  );
};

export default SliderBudgetCampaign;
