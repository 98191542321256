import React from "react";
import LoadingButtonMui from "@mui/lab/LoadingButton";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { BORDER_RADIUS } from "../../../utils/constants";

const ButtonLarge = ({
  id,
  children,
  variant = "contained",
  component = "button",
  color = "secondary",
  fullWidth = false,
  disableElevation = true,
  disabled = false,
  size = "medium",
  ariaLabel = "",
  type = "",
  startIcon = "",
  endIcon = "",
  sx = {},
  onClick,
  dataTour = "",
  refButton,
  loading = false,
  loadingPosition,
  background = GRADIENT_PURPLE_FUCHSIA,
  colorText = `${GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT}`,
  withoutGradient = false,
}) => {
  const sxColor = {};

  if (!withoutGradient) {
    sxColor.background = background;
    sxColor.color = colorText;
  }
  return (
    <LoadingButtonMui
      id={id}
      ref={refButton}
      data-tour={dataTour}
      variant={variant}
      disabled={disabled}
      color={color}
      component={component}
      loading={loading}
      loadingPosition={loadingPosition}
      fullWidth={fullWidth}
      disableElevation={disableElevation}
      aria-label={ariaLabel}
      size={size}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        ...sxColor,
        textTransform: "capitalize",
        borderRadius: BORDER_RADIUS,
        fontWeight: 600,
        maxHeight: 80,
        lineHeight: "normal",
        minWidth: "max-content",
        py: "10px",
        px: "20px",
        fontSize: "1.575rem",
        whiteSpace: "nowrap",
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </LoadingButtonMui>
  );
};

export default ButtonLarge;
