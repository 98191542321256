import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import NumberField from "../../Common/NumberFormats/NumberField";
import {
  REGULAR_STORE_TYPE,
  SHOPIFY_STORE_TYPE,
  WORDPRESS_WOOCOMMERCE_STORE_TYPE,
} from "../../../utils/constants";
import { capitalizeFirstLetter } from "../../../utils/formats";
import Select from "../../Common/Selects/Select";
import TextField from "../../Common/Fields/TextField";
import Checkbox from "../../Common/Fields/Checkbox";
import _ from "lodash";
import { REGEX_EMAIL, REGEX_URL } from "../../../utils/regexsValidation";
import { Controller } from "react-hook-form";
import SelectCities from "../../Common/Selects/SelectCities";
import PhoneTextField from "../../Common/Fields/PhoneTextField";
import { useLocale } from "../../../Contexts/LocaleContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxMUI from "@mui/material/Checkbox";
import {
  STORE_FORM_STORE_ADDRESS,
  STORE_FORM_STORE_CITY,
  STORE_FORM_STORE_EMAIL,
  STORE_FORM_STORE_NAME,
  STORE_FORM_STORE_ORDERS_METHODS,
  STORE_FORM_STORE_ORDERS_METHODS_PRODUCT_AVAILABILITY,
  STORE_FORM_STORE_PHONE,
  STORE_FORM_STORE_TYPE,
} from "../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import {
  BUTTON_CONNECT_WOOCOMMERCE,
  EMAIL_PLACEHOLDER,
  FIELD_EMAIL_VALID,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
  MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER,
  MODAL_STORE_ADDRESS_PLACEHOLDER,
  MODAL_STORE_TYPE_PLACEHOLDER,
  MODAL_STORE_CITIES_PLACEHOLDER,
  MODAL_STORE_CITY_PLACEHOLDER,
  MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER,
  MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER,
  MODAL_STORE_HOW_TO_INTEGRATE_STORE,
  MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER,
  MODAL_STORE_NAME_PLACEHOLDER,
  MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION,
  MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION,
  MODAL_STORE_ORDERS_METHODS_PLACEHOLDER,
  MODAL_STORE_PHONE_PLACEHOLDER,
  MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION,
  MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION,
  MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER,
  MODAL_STORE_TYPE_REGULAR_OPTION,
  FIELD_ACCESS_TOKEN_SHOPIFY_VALID,
  CURRENCY_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { getNameBusinessTheme } from "../../../actions/getters";
import LinkText from "../../Common/Texts/LinkText";
import { optionsCurrencies } from "../../../utils/slidersMarks";

const StoreForm = ({
  control,
  watch,
  mode,
  errors,
  setValue,
  getValues,
  signUpCountry,
  requiredStoreType,
  onSaveWordpressWoocommerce,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const storeType = watch("storeType");
  const ordersMethods = watch("ordersMethods");
  const productAvailability = watch("productAvailability");

  const LOCALE = useLocale();
  const specialStoresData = LOCALE.specialStores || [];
  const specialStores = Object.keys(specialStoresData);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { isOpen } = useTour();

  const currencyShop = useSelectorApp((state) => state.shop.currency);

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Select
          name="storeType"
          label={t(MODAL_STORE_TYPE_PLACEHOLDER)}
          disabled={isOpen || mode === "edit" || Boolean(requiredStoreType)}
          fullWidth
          variant="filled"
          dataTour={STORE_FORM_STORE_TYPE}
          options={[
            {
              label: t(MODAL_STORE_TYPE_REGULAR_OPTION),
              value: REGULAR_STORE_TYPE,
            },
            ...specialStores.map((store) => ({
              value: specialStoresData[store].value,
              label: capitalizeFirstLetter(specialStoresData[store].value),
            })),
          ]}
          actionsChange={(value) => {
            if (value === REGULAR_STORE_TYPE) {
              setValue("storeURL", "");
            }
            if (value !== REGULAR_STORE_TYPE) {
              setValue(
                "name",
                capitalizeFirstLetter(specialStoresData[value].value)
              );
            }
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        {storeType !== REGULAR_STORE_TYPE && (
          <FormHelperText>
            <LinkText
              href={specialStoresData[storeType].documentation}
              target="_blank"
            >
              {t(MODAL_STORE_HOW_TO_INTEGRATE_STORE, {
                value: capitalizeFirstLetter(
                  specialStoresData[storeType].value
                ),
                NAME_BUSINESS,
              })}
            </LinkText>
          </FormHelperText>
        )}
      </Grid>
      {storeType === REGULAR_STORE_TYPE && (
        <>
          <Grid item xs={12}>
            <TextField
              label={t(MODAL_STORE_NAME_PLACEHOLDER)}
              variant="filled"
              name="name"
              fullWidth
              dataTour={STORE_FORM_STORE_NAME}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (storeType === REGULAR_STORE_TYPE && _.isEmpty(value)) {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
            />
          </Grid>
          {!currencyShop && (
            <Grid item xs={12}>
              <Select
                name="currency"
                label={t(CURRENCY_PLACEHOLDER)}
                fullWidth
                variant="filled"
                options={optionsCurrencies}
                control={control}
                errors={errors}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.8,
                      width: 250,
                    },
                  },
                }}
                rules={{
                  validate: (value) => {
                    if (storeType === REGULAR_STORE_TYPE && _.isEmpty(value)) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label={t(MODAL_STORE_ADDRESS_PLACEHOLDER)}
              variant="filled"
              name="address"
              fullWidth
              dataTour={STORE_FORM_STORE_ADDRESS}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (storeType === REGULAR_STORE_TYPE && _.isEmpty(value)) {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectCities
              name="city"
              label={t(MODAL_STORE_CITY_PLACEHOLDER)}
              fullWidth
              variant="filled"
              dataTour={STORE_FORM_STORE_CITY}
              control={control}
              errors={errors}
              actionsChange={(value) => {
                setValue("cities", [value]);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.8,
                    width: 250,
                  },
                },
              }}
              rules={{
                validate: (value) => {
                  if (storeType === REGULAR_STORE_TYPE && _.isEmpty(value)) {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <PhoneTextField
          defaultCountry={signUpCountry}
          onlyCountries={[]}
          disableDropdown={false}
          label={t(MODAL_STORE_PHONE_PLACEHOLDER)}
          variant="filled"
          name="phone"
          fullWidth
          dataTour={STORE_FORM_STORE_PHONE}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t(EMAIL_PLACEHOLDER)}
          variant="filled"
          name="email"
          dataTour={STORE_FORM_STORE_EMAIL}
          fullWidth
          control={control}
          errors={errors}
          rules={{
            validate: (value) => {
              if (_.isEmpty(value)) {
                return t(FIELD_REQUIRED);
              }
              if (!REGEX_EMAIL.test(value)) {
                return t(FIELD_EMAIL_VALID);
              }
            },
          }}
        />
      </Grid>
      {storeType !== REGULAR_STORE_TYPE && (
        <>
          <Grid item xs={12}>
            <TextField
              label={t(MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER, {
                value: capitalizeFirstLetter(
                  specialStoresData[storeType].value
                ),
              })}
              variant="filled"
              name="storeURL"
              fullWidth
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (specialStores.includes(storeType) && _.isEmpty(value)) {
                    return t(FIELD_REQUIRED);
                  }
                  if (
                    specialStores.includes(storeType) &&
                    !REGEX_URL.test(value)
                  ) {
                    return t(FIELD_URL_VALID);
                  }
                },
              }}
            />
          </Grid>
          {storeType === SHOPIFY_STORE_TYPE && (
            <>
              <Grid item xs={12}>
                <TextField
                  label={t(MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER, {
                    NAME_BUSINESS,
                  })}
                  variant="filled"
                  name="specialAccessToken"
                  fullWidth
                  control={control}
                  errors={errors}
                  rules={{
                    validate: (value) => {
                      if (
                        storeType === SHOPIFY_STORE_TYPE &&
                        _.isEmpty(value) &&
                        mode === "create"
                      ) {
                        return t(FIELD_REQUIRED);
                      }
                      if (
                        storeType === SHOPIFY_STORE_TYPE &&
                        value &&
                        !value.includes("shpat_")
                      ) {
                        return t(FIELD_ACCESS_TOKEN_SHOPIFY_VALID);
                      }
                    },
                  }}
                />
              </Grid>
            </>
          )}
          {(storeType === SHOPIFY_STORE_TYPE ||
            storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE) && (
            <Grid item xs={12}>
              <Controller
                name="doCreateDiscountShipping"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <FormControlLabel
                    name={field.name}
                    control={
                      <CheckboxMUI
                        checked={field.value}
                        onChange={(e) =>
                          field.onChange({
                            target: {
                              name: "doCreateDiscountShipping",
                              value: e.target.checked,
                            },
                          })
                        }
                        name={field.name}
                      />
                    }
                    label={t(
                      MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER
                    )}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SelectCities
              label={t(MODAL_STORE_CITIES_PLACEHOLDER)}
              name="cities"
              multiple={true}
              showAllOption={true}
              control={control}
              rules={{
                validate: (value) => {
                  if (specialStores.includes(storeType) && _.isEmpty(value)) {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
              variant="filled"
              errors={errors}
            />
          </Grid>
        </>
      )}
      {storeType === REGULAR_STORE_TYPE && (
        <Grid item xs={12}>
          <Checkbox
            name="ordersMethods"
            label={t(MODAL_STORE_ORDERS_METHODS_PLACEHOLDER)}
            fullWidth
            dataTour={STORE_FORM_STORE_ORDERS_METHODS}
            variant="standard"
            options={[
              {
                label: t(MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION),
                value: "delivery",
              },
              {
                label: t(MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION),
                value: "inStore",
              },
            ]}
            control={control}
            errors={errors}
            actionsChange={(value) => {
              if (value.inStore) {
                setValue("coverageRadius", 100);
                setValue("cities", [getValues("city")]);
              }
            }}
            rules={{
              validate: (value) => {
                if (
                  storeType === REGULAR_STORE_TYPE &&
                  !Object.values(value ?? []).includes(true)
                ) {
                  return t(FIELD_REQUIRED);
                }
              },
            }}
          />
        </Grid>
      )}
      {storeType === REGULAR_STORE_TYPE && ordersMethods.delivery && (
        <>
          <Grid item xs={12}>
            <Select
              name="productAvailability"
              label={t(MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER)}
              dataTour={STORE_FORM_STORE_ORDERS_METHODS_PRODUCT_AVAILABILITY}
              fullWidth
              variant="filled"
              options={[
                {
                  label: t(MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION),
                  value: "national",
                },
                {
                  label: t(MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION),
                  value: "local",
                },
              ]}
              actionsChange={(value) => {
                if (value === "local") {
                  setValue("cities", [getValues("city")]);
                }
                if (value === "national") {
                  setValue(
                    "cities",
                    LOCALE.cities.map((city) => city.value)
                  );
                  setValue("coverageRadius", 100000);
                }
              }}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (
                    storeType === REGULAR_STORE_TYPE &&
                    ordersMethods.delivery &&
                    _.isEmpty(value)
                  ) {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
            />
          </Grid>
          {productAvailability === "local" && (
            <Grid item xs={12}>
              <TextField
                label={t(MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER)}
                variant="filled"
                name="coverageRadius"
                fullWidth
                InputProps={{
                  inputComponent: NumberField,
                }}
                control={control}
                errors={errors}
                rules={{
                  validate: (value) => {
                    if (
                      storeType === REGULAR_STORE_TYPE &&
                      ordersMethods.delivery &&
                      productAvailability === "local" &&
                      _.isEmpty(value.toString())
                    ) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
          )}
        </>
      )}
      {storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE &&
        !watch("isActiveIntegration") && (
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                loading={isFetching}
                color="secondary"
                fullWidth
                type="button"
                onClick={async () => {
                  setIsFetching(true);
                  await onSaveWordpressWoocommerce();
                  setIsFetching(false);
                }}
              >
                {t(BUTTON_CONNECT_WOOCOMMERCE)}
              </LoadingButton>
            </Box>
          </Grid>
        )}
    </Grid>
  );
};

export default StoreForm;
