import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION,
  BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import PhoneTextField from "../../../Common/Fields/PhoneTextField";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContactSection = ({
  formValues,
  onChangeForm,
  defaultCountry,
  onlyCountries,
}) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box sx={{ width: "100%", ml: 1, pt: 3, pb: 4 }}>
      <Typography
        variant={isMobile ? "body1" : "h6"}
        sx={{ mb: 2, fontWeight: 600, fontStyle: "normal" }}
      >
        {t(BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION)}
      </Typography>
      <Grid container spacing={2} sx={{ width: "calc(100% + 8px)" }}>
        <Grid item xs={12}>
          <TextField
            label={t(
              BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER
            )}
            name="email"
            variant="filled"
            value={formValues.email}
            onChange={onChangeForm}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t(BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER)}
            name="address"
            variant="filled"
            value={formValues.address}
            onChange={onChangeForm}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneTextField
            label={t(BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER)}
            name="phone"
            variant="filled"
            value={formValues?.phone?.value}
            onChange={({ value, info }) =>
              onChangeForm({
                target: {
                  name: "phone",
                  value: {
                    value,
                    info,
                  },
                },
              })
            }
            fullWidth
            forceCallingCode={true}
            defaultCountry={defaultCountry}
            onlyCountries={[]}
            disableDropdown={false}
            withoutController={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactSection;
