import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "../../Common/Buttons/Button";
import {
  ALERT_ICON_TYPE_SUCCESS,
  DEFAULT_TABLE_PAGES,
  INVITATION_INFLUENCER_MODE_CREATE,
  INVITATION_INFLUENCER_MODE_EDIT,
  INVITATION_INFLUENCER_MODE_INVITE,
  INVITATION_INFLUENCER_MODE_INVITE_INACTIVE,
  INVITATION_INFLUENCER_MODE_REQUEST,
  REQUEST_STATUS_REJECTED,
} from "../../../utils/constants";
import {
  startGetInfluencer,
  startGetInfluencerShopDiscountCode,
  startInviteInfluencer,
} from "../../../actions/influencers";
import InvitationForm from "./InvitationForm";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import {
  startGetAverageTicketShop,
  startGetOrdersMethods,
} from "../../../actions/shops";
import Loader from "../../Common/Loader/Loader";
import { ConfirmAlert, SimpleAlert } from "../../../utils/alerts";
import { startDeleteInvite, startEditInvite } from "../../../actions/invites";
import { startDeleteRequest } from "../../../actions/requests";
import {
  INVITE_FORM_BUTTON,
  MODAL_INVITE_INFLUENCER_INFORMATION,
} from "../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { useLocale } from "../../../Contexts/LocaleContext";
import { startGetEventsByShop } from "../../../actions/events";
import {
  BUTTON_CANCEL_INVITE,
  BUTTON_CONTINUE,
  BUTTON_EDIT_INVITE,
  BUTTON_INVITE,
  BUTTON_REJECT_REQUEST,
  INVITE_CANCELED,
  INVITE_CANCELED_DESCRIPTION,
  INVITE_EDIT,
  INVITE_SENT,
  INVITE_SENT_DESCRIPTION,
  PUBLICATION_FINISH_DESCRIPTION,
  PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION,
  READY,
  REQUEST_REJECTED,
  REQUEST_REJECTED_DESCRIPTION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { getSuggestedInviteInformation } from "../../../utils/influencers";
import { setIsNewBrand } from "../../../actions/onboarding";
import { STEPS_STORES_FUNCTIONALITY } from "../../../onboarding/steps";
import { useNavigate } from "react-router-dom";
import InfluencerNameLogo from "../../Common/Influencer/InfluencerNameLogo";
import { BOX_SHADOW_ACTIONS_MODAL } from "../../../utils/colors";
import { startGetAmbassadorByUserID } from "../../../actions/ambassadors";
import { getShopID } from "../../../actions/getters";
import { useForm } from "react-hook-form";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";

const ModalInvitation = ({
  dataInvite,
  dataInfluencer,
  userID,
  requestID,
  onCloseModal,
  from = INVITATION_INFLUENCER_MODE_INVITE,
  mode = INVITATION_INFLUENCER_MODE_CREATE,
  onCallBackInvite = () => {},
  additionalDataCreateInvite = {},
  disabledAdvancedSettings = false,
}) => {
  const { control, getValues, watch, setValue, trigger, formState } = useForm({
    defaultValues: {
      value: dataInvite?.value ?? "",
      valueToPay: dataInvite?.valueToPay ?? "",
      code: dataInvite?.inviteCode ?? "",
      orderFrom: dataInvite?.orderFrom ?? new Date().toISOString(),
      formats: {
        instagramBurstStories:
          dataInvite?.formats?.instagramBurstStories ?? false,
        instagramPost: dataInvite?.formats?.instagramPost ?? false,
        instagramReel: dataInvite?.formats?.instagramReel ?? false,
        instagramStory: dataInvite?.formats?.instagramStory ?? false,
        tiktokPost: dataInvite?.formats?.tiktokPost ?? false,
        tiktokStory: dataInvite?.formats?.tiktokStory ?? false,
      },
      typeContent: {
        images: false,
        videos: true,
      },
      ugcContentFormat: dataInvite?.ugcContentFormat ?? "",
      comment: dataInvite?.comment ?? "",
      terms: dataInvite?.terms ?? "",
      // event: dataInvite?.eventID ? dataInvite.eventID : false,
      personalizedURL: dataInvite?.personalizedURL ?? "",
      referenceUrl: dataInvite?.referenceUrl ?? "",
      wantsSocialMediaPost: dataInvite?.wantsSocialMediaPost ?? true,
      language: dataInvite?.language ?? "",
    },
  });
  const [influencer, setInfluencer] = useState(null);
  const [averageTicket, setAverageTicket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const shopID = dispatch(getShopID());

  const ambassador = useSelectorApp(
    (state) => state.ambassadors.ambassadors[`${shopID}-${userID}`]
  );
  const profileInfluencer = useSelectorApp(
    (state) => state.shop.profileInfluencer
  );
  const lastInviteConfiguration = useSelectorApp(
    (state) => state.shop.lastInviteConfiguration
  );
  const { isOpen, setIsOpen, setSteps } = useTour();

  const LOCALE = useLocale();

  const getInfluencerData = async () => {
    if (!userID && !dataInfluencer) return;

    if (dataInfluencer) {
      setInfluencer(dataInfluencer);
    } else if (!dataInfluencer && userID) {
      const influencerData = await dispatch(startGetInfluencer(userID, false));
      setInfluencer(influencerData);
    }

    dispatch(startGetAmbassadorByUserID(userID));
  };

  const getInitialData = async () => {
    await getInfluencerData();

    await dispatch(
      startGetEventsByShop({
        limit: DEFAULT_TABLE_PAGES,
        onChangeLastKey: () => {},
      })
    );

    const ticket = await dispatch(startGetAverageTicketShop());
    setAverageTicket(ticket);

    dispatch(startGetOrdersMethods());

    const discountCode = await dispatch(
      startGetInfluencerShopDiscountCode({ userID })
    );

    setValue("discountCode", discountCode.discountCode || "");
    setValue("language", LOCALE.language);

    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === INVITATION_INFLUENCER_MODE_CREATE) {
      const dataSuggested = getSuggestedInviteInformation({
        lastInviteConfiguration,
        profileInfluencer,
        influencer,
      });
      Object.keys(dataSuggested || {}).forEach((key) => {
        setValue(key, dataSuggested[key]);
      });
    }
    //eslint-disable-next-line
  }, [influencer, profileInfluencer]);

  const onInviteInfluencer = async (data) => {
    const response = await dispatch(
      startInviteInfluencer(
        data,
        userID,
        additionalDataCreateInvite,
        disabledAdvancedSettings
      )
    );
    if (response.ok) {
      onCallBackInvite(response.inviteID);
      onCloseModal();
      if (isOpen) {
        setIsOpen(false);
        const result = await ConfirmAlert({
          title: t(READY),
          text: t(PUBLICATION_FINISH_DESCRIPTION),
          additionalText: t(
            PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION
          ),
          icon: ALERT_ICON_TYPE_SUCCESS,
          confirmButtonText: t(BUTTON_CONTINUE),
        });
        if (result.isConfirmed) {
          dispatch(setIsNewBrand(true));
          navigate(`/stores`);
          setSteps(STEPS_STORES_FUNCTIONALITY);
          setIsOpen(true);
        }
      } else {
        SimpleAlert({
          title: t(INVITE_SENT),
          text: t(INVITE_SENT_DESCRIPTION, {
            influencerName: influencer?.mainAccountName,
            value: data.value,
            currency: LOCALE.currency,
          }),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
    return response;
  };

  const onEditInvite = async (data) => {
    const response = await dispatch(startEditInvite(dataInvite.inviteID, data));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(INVITE_EDIT),
        text: t(INVITE_SENT_DESCRIPTION, {
          influencerName: influencer?.mainAccountName,
          value: data.value,
          currency: LOCALE.currency,
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onDeleteRequest = async () => {
    const response = dispatch(
      startDeleteRequest({ requestID, status: REQUEST_STATUS_REJECTED })
    );
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(REQUEST_REJECTED),
        text: t(REQUEST_REJECTED_DESCRIPTION, {
          influencerName: influencer?.mainAccountName,
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  //eslint-disable-next-line
  const onCancelInvite = async () => {
    const response = dispatch(startDeleteInvite(dataInvite.inviteID));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(INVITE_CANCELED),
        text: t(INVITE_CANCELED_DESCRIPTION, {
          influencerName: influencer?.mainAccountName,
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const onSubmit = async () => {
    const result = await trigger();
    if (!result) return;

    const formValues = getValues();

    if (isOpen && !formValues.value) {
      formValues.value = 100000;
      setValue("value", 100000);
    }

    setIsFetching(true);

    mode === INVITATION_INFLUENCER_MODE_CREATE
      ? await onInviteInfluencer({
          ...formValues,
          code: formValues.code.trim(),
          eventID: formValues.event === false ? null : formValues.event,
        })
      : await onEditInvite({
          ...formValues,
          code: formValues.code.trim(),
          eventID: formValues.event === false ? null : formValues.event,
        });
    setIsFetching(false);
  };

  return (
    <ModalBasicLayout
      modalOpen={!!influencer}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box data-tour={MODAL_INVITE_INFLUENCER_INFORMATION} sx={{ mb: 2 }}>
            <InfluencerNameLogo userID={userID} data={influencer} />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InvitationForm
              userID={userID}
              getValues={getValues}
              setValue={setValue}
              control={control}
              watch={watch}
              errors={errors}
              dataInfluencer={influencer}
              averageTicket={averageTicket}
              mode={mode}
              from={from}
              isAmbassadorInvitation={
                ambassador && ambassador.inviteID === dataInvite.inviteID
              }
              disabledAdvancedSettings={disabledAdvancedSettings}
            />
          </Box>
        </>
      )}
      {!isLoading && from !== INVITATION_INFLUENCER_MODE_INVITE_INACTIVE && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            py: 2,
            boxShadow: BOX_SHADOW_ACTIONS_MODAL,
            gap: 1,
          }}
        >
          <LoadingButton
            loading={isFetching}
            color="secondary"
            sx={{ maxWidth: 300 }}
            fullWidth={true}
            onClick={onSubmit}
            dataTour={INVITE_FORM_BUTTON}
          >
            {mode === INVITATION_INFLUENCER_MODE_EDIT
              ? t(BUTTON_EDIT_INVITE)
              : t(BUTTON_INVITE)}
          </LoadingButton>
          {from === INVITATION_INFLUENCER_MODE_INVITE &&
            mode === INVITATION_INFLUENCER_MODE_EDIT &&
            dataInvite.isAutomatic && (
              <Button
                color="primary"
                sx={{ maxWidth: 300, ml: "0px !important" }}
                fullWidth={true}
                onClick={onCancelInvite}
              >
                {t(BUTTON_CANCEL_INVITE)}
              </Button>
            )}
          {from === INVITATION_INFLUENCER_MODE_REQUEST && (
            <Button
              color="primary"
              sx={{ maxWidth: 300, ml: "0px !important" }}
              fullWidth={true}
              onClick={onDeleteRequest}
            >
              {t(BUTTON_REJECT_REQUEST)}
            </Button>
          )}
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalInvitation;
