import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Alert from "@mui/material/Alert";
import {
  REGEX_ALPHA_NUMERIC,
  REGEX_NUMERIC,
  REGEX_TEXT,
} from "../../../utils/regexsValidation";
import CurrencyField from "../NumberFormats/CurrencyField";
import LoadingButton from "../Buttons/LoadingButton";
import {
  FIELD_ALPHANUMERIC,
  FIELD_NUMERIC,
  FIELD_REQUIRED,
  FIELD_TEXT,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const InputFieldButton = ({
  placeholder = "Escribe algo",
  buttonText = "Añadir",
  hasButton = true,
  value = "",
  onActionButton,
  hasNumberFormat = false,
  isText = false,
  isNumeric = false,
  isAlphanumeric = false,
  loading = false,
  dataTour = "",
}) => {
  const { t } = useTranslationApp();
  const [field, setField] = useState(value);
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!field) {
      setError(t(FIELD_REQUIRED));
    } else if (isNumeric && !REGEX_NUMERIC.test(field)) {
      setError(t(FIELD_NUMERIC));
    } else if (isText && !REGEX_TEXT.test(field)) {
      setError(t(FIELD_TEXT));
    } else if (isAlphanumeric && !REGEX_ALPHA_NUMERIC.test(field)) {
      setError(t(FIELD_ALPHANUMERIC));
    } else {
      const response = await onActionButton(field);
      setError("");
      if (response) setField("");
    }
  };

  return (
    <>
      <Paper
        component="form"
        elevation={0}
        onSubmit={onSubmit}
        data-tour={dataTour}
        sx={{
          p: "2px 2px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: 400,
          backgroundColor: "background.paperSecondary",
          my: 2,
          borderRadius: 3,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder }}
          value={field}
          name="field"
          onChange={(e) => setField(e.target.value)}
          inputComponent={hasNumberFormat ? CurrencyField : null}
        />
        {hasButton && (
          <LoadingButton
            variant="contained"
            color="secondary"
            ariaLabel="directions"
            disableElevation
            type="submit"
            loading={loading}
          >
            {buttonText}
          </LoadingButton>
        )}
      </Paper>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
    </>
  );
};

export default InputFieldButton;
