import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { Controller, useForm } from "react-hook-form";
import { useSelectorApp } from "../../../lib/redux";
import TextField from "../../Common/Fields/TextField";
import NumberField from "../../Common/NumberFormats/NumberField";
import ModalPayAmbassador from "../../Common/Chat/ModalPayAmbassador";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { AMBASSADORS_PAYMENTS_FORM } from "../../../onboarding/stepsSelectors";
import {
  BUTTON_CANCEL,
  BUTTON_CONTINUE,
  FIELD_REQUIRED,
  MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER,
  MODAL_PAY_AMBASSADORS_ARIAL_LABEL,
  MODAL_PAY_AMBASSADORS_DESCRIPTION,
  MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER,
  MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED,
  MODAL_PAY_AMBASSADORS_TITLE,
  MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR,
  MODAL_PAY_AMBASSADOR_AMOUNT_MIN,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";
import { MIN_VALUE_PAY_AMBASSADOR } from "../../../utils/constants";

const ModalPayAmbassadors = ({ modalOpen, onCloseModal }) => {
  const [modalPayAmbassadorOpen, setModalPayAmbassadorOpen] = useState(false);
  const { handleSubmit, control, watch, formState } = useForm({
    defaultValues: {
      usersIDs: [],
      amount: "",
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const { t } = useTranslationApp();

  const { ambassadors } = useSelectorApp((state) => state.ambassadors);
  const influencers = useSelectorApp((state) => state.influencers.influencers);

  const onChangeStatusModalPayAmbassadorOpen = (status) =>
    setModalPayAmbassadorOpen(status);

  const onSubmit = (data) => {
    onChangeStatusModalPayAmbassadorOpen(true);
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_PAY_AMBASSADORS_ARIAL_LABEL)}
      title={t(MODAL_PAY_AMBASSADORS_TITLE)}
      description={t(MODAL_PAY_AMBASSADORS_DESCRIPTION)}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        data-tour={AMBASSADORS_PAYMENTS_FORM}
      >
        <Controller
          name="usersIDs"
          control={control}
          rules={{
            validate: {
              value: (value) => {
                if (value.length === 0) {
                  return t(MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED);
                }
              },
            },
          }}
          render={({ field }) => (
            <FormControl
              sx={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
              }}
              component="fieldset"
              variant="standard"
              error={!!errors.usersIDs}
            >
              <FormLabel component="legend" sx={{ mb: 1 }}>
                {t(MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER)}
              </FormLabel>
              <FormGroup
                sx={{
                  display: "flex",
                  gap: 1,
                  maxHeight: 300,
                  overflowY: "auto",
                  flexWrap: "nowrap",
                }}
              >
                {Object.keys(ambassadors).map((ambassadorID) => {
                  const ambassador = ambassadors[ambassadorID];
                  const influencer = influencers[ambassador.userID];

                  return (
                    <Box
                      key={ambassadorID}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Avatar
                        src={influencer.imageURL}
                        alt={`ImageURL of ${influencer.mainAccountName}`}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value.includes(ambassador.userID)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                field.onChange([
                                  ...field.value,
                                  ambassador.userID,
                                ]);
                              } else {
                                field.onChange(
                                  field.value.filter(
                                    (userID) => userID !== ambassador.userID
                                  )
                                );
                              }
                            }}
                          />
                        }
                        label={influencer.mainAccountName}
                      />
                    </Box>
                  );
                })}
              </FormGroup>
              {errors?.usersIDs?.message && (
                <FormHelperText>{errors?.usersIDs?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <TextField
          name="amount"
          label={t(MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER)}
          multiline
          variant="filled"
          fullWidth
          InputProps={{
            inputComponent: NumberField,
          }}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            min: {
              value: MIN_VALUE_PAY_AMBASSADOR,
              message: t(MODAL_PAY_AMBASSADOR_AMOUNT_MIN, {
                value: MIN_VALUE_PAY_AMBASSADOR,
                currency: "USD",
              }),
            },
          }}
        />
        <Typography variant="body1">
          {t(MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR, {
            value: watch("amount"),
            currency: "USD",
          })}
        </Typography>

        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_CONTINUE,
            onClick: () => {},
          }}
        />
      </Box>
      {modalPayAmbassadorOpen && (
        <ModalPayAmbassador
          modalOpen={modalPayAmbassadorOpen}
          onCloseModal={() => onChangeStatusModalPayAmbassadorOpen(false)}
          usersIDs={watch("usersIDs")}
          amount={Number(watch("amount"))}
          isSpecificValue={false}
          onCallbackPay={() => {
            onCloseModal();
          }}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalPayAmbassadors;
