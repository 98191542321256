export function deleteProperty(obj, prop) {
  const { [prop]: _, ...newObj } = obj;
  return newObj;
}

export function editProperty(obj, prop, oldProp) {
  const { [prop]: _, ...newObj } = obj;
  return deleteProperty({ [prop]: obj[oldProp], ...newObj }, oldProp);
}

export const differenceBetweenObjects = ({
  obj1 = {},
  obj2 = {},
  exclude = [],
  ignoreEmpty = true,
}) => {
  const diffKeys = [];

  for (const key in obj1) {
    if (!obj1[key] && ignoreEmpty) {
      continue;
    }
    if (exclude.includes(key)) {
      continue;
    }
    const value1 = JSON.stringify(obj1[key]);
    const value2 = JSON.stringify(obj2[key]);

    if (value1 !== value2) {
      diffKeys.push(key);
    }
  }

  return diffKeys;
};
