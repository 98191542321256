import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ACCOUNT_MODE_EDIT } from "../../../../utils/constants";
import {
  EMAIL_PLACEHOLDER,
  MODAL_CONTACT_PHONE_PLACEHOLDER,
  MODAL_USER_NAME_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import PhoneTextField from "../../../Common/Fields/PhoneTextField";

const FormContact = ({ data, onChangeForm, mode, signUpCountry }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <TextField
        name="name"
        value={data.name}
        onChange={onChangeForm}
        label={t(MODAL_USER_NAME_PLACEHOLDER)}
        fullWidth
        variant="filled"
      />
      <PhoneTextField
        label={t(MODAL_CONTACT_PHONE_PLACEHOLDER)}
        disabled={mode === ACCOUNT_MODE_EDIT}
        name="phone"
        variant="filled"
        value={data?.phone.value}
        onChange={({ value, info }) => {
          onChangeForm({
            target: {
              name: "phone",
              value: {
                value,
                info,
              },
            },
          });
        }}
        fullWidth
        forceCallingCode={true}
        defaultCountry={signUpCountry}
        onlyCountries={[]}
        disableDropdown={false}
        withoutController={true}
      />
      <TextField
        name="email"
        value={data.email}
        onChange={onChangeForm}
        label={t(EMAIL_PLACEHOLDER)}
        fullWidth
        variant="filled"
      />
    </Box>
  );
};

export default FormContact;
