import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { useTranslationApp } from "../../../../lib/i18next";
import { useDispatchApp } from "../../../../lib/redux";
import { getNameBusinessTheme } from "../../../../actions/getters";
import {
  CREATE_ADS_CAMPAIGN_INTRO_TITLE,
  CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON,
  META_CAMPAIGN,
} from "../../../../locales/keysTranslations";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import ButtonMenu from "../../../Common/Buttons/ButtonMenu";
import ButtonMetaAction from "../../../Common/Buttons/ButtonMetaAction";
import { META_WHITE } from "../../../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";

const CreateAdsCampaignIntro = ({ onCallback = () => {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const sxSubContainers = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 2,
    height: "auto",
    width: "100%",
    maxWidth: "sm",
  };
  const sxItems = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
  };

  const variantTextItem = isMobile ? "body2" : "body1";

  const [params] = useSearchParams();
  const content = params.get("content");
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const navigate = useNavigate();

  return (
    <Box
      className="animate__animated animate__fadeIn"
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: { xs: "column", lg: "row" },
        px: {
          xs: 1.5,
          md: 4,
        },
      }}
    >
      <Box sx={sxSubContainers}>
        <Box sx={sxItems}>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              fontWeight: 900,
              background: GRADIENT_PURPLE_FUCHSIA,
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {t(CREATE_ADS_CAMPAIGN_INTRO_TITLE, {
              NAME_BUSINESS,
            })}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION)}
          </Typography>
        </Box>
        <Box sx={{ ...sxItems, gap: 0 }}>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE)}
          </Typography>
        </Box>
        <Box sx={sxItems}>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION)}
          </Typography>
          <ButtonMenu
            textButton={CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON}
            fullWidth={isMobile}
            sxMenu={{
              maxWidth: "200px",
            }}
            sx={{
              background: GRADIENT_PURPLE_FUCHSIA,
              color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
              width: {
                xs: "100%",
                sm: "min-content",
              },
            }}
            options={[
              {
                customComponent: (
                  <ButtonMetaAction
                    hasShadow={false}
                    text={META_CAMPAIGN}
                    logo={META_WHITE}
                    sx={{
                      background: "unset",
                      p: 1,
                      borderRadius: 0,
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.1)",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        `/create-ads-campaign?platform=meta${
                          content ? `&content=${content}` : ""
                        }`
                      );
                      onCallback();
                    }}
                  />
                ),
              },
              // {
              //   customComponent: (
              //     <ButtonTikTokBusinessAction
              //       hasShadow={false}
              //       text={TIKTOK_CAMPAIGN}
              //       logo={TIKTOK}
              //       sx={{
              //         background: "unset",
              //         p: 1,
              //         borderRadius: 0,
              //         "&:hover": {
              //           backgroundColor: "rgba(255,255,255,0.1)",
              //         },
              //       }}
              //       onClick={() =>
              //         navigate("/create-ads-campaign?platform=tiktok")
              //       }
              //     />
              //   ),
              // },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAdsCampaignIntro;
