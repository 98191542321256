import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SliderBase from "../../../Common/Forms/Sliders/SliderBase";
import { marksInfluencersMax500K } from "../../../../utils/slidersMarks";
import NumberField from "../../../Common/NumberFormats/NumberField";
import CurrencyField from "../../../Common/NumberFormats/CurrencyField";
import { Controller } from "react-hook-form";
import TextField from "../../../Common/Fields/TextField";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { useSelectorApp } from "../../../../lib/redux";
import {
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER,
  FIELD_REQUIRED,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MIN_ALL_NUMBER_VALUE } from "../../../../utils/constants";

const ProfileInfluencerFormBudget = ({ control, errors, watch, setValue }) => {
  const LOCALE = useLocale();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();

  const minProductValueShop = useSelectorApp(
    (state) => state.shop.minProductValue
  );

  const budgets = watch("budgets");

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          sx={{
            fontWeight: 600,
            mt: 1,
          }}
        >
          {t(AUTOMATIC_INVITES_BUDGET_DESCRIPTION)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="numberInvitations"
          disabled={true}
          label={t(AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER)}
          variant="filled"
          fullWidth
          InputProps={{
            inputComponent: NumberField,
          }}
          control={control}
          errors={errors}
          rules={{
            validate: {
              value: (value) => {
                if (Number(value) < 5 || Number(value) > 100) {
                  return t(
                    AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE,
                    {
                      min: 5,
                      max: 100,
                    }
                  );
                }
              },
            },
          }}
        />
      </Grid>

      {budgets.map((budget, index) => {
        const numAudience = index + 1;
        return (
          <Grid item xs={12} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={budget.fieldName}
                  render={({ field }) => (
                    <SliderBase
                      disabled={true}
                      innerRef={field.ref}
                      range={budget.audienceRange}
                      onChange={(e) => {
                        const value = e.target.value;
                        const newBudgets = [...budgets];
                        newBudgets[index].audienceRange = value;
                        field.onChange({
                          ...field.value,
                          audienceRange: value,
                        });
                      }}
                      title={t(
                        AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE,
                        {
                          value: numAudience,
                        }
                      )}
                      variantTitle="body1"
                      step={1000}
                      min={5000}
                      max={500000}
                      marks={marksInfluencersMax500K}
                      sx={{ px: 1, width: "100%" }}
                      sxTitle={{
                        fontWeight: 500,
                      }}
                    />
                  )}
                />
                {!!errors["audienceRange"] && (
                  <Typography variant="body2" color="error">
                    {errors["audienceRange"]?.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  disabled={true}
                  name={budget.fieldName}
                  label={t(
                    AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE,
                    {
                      value: numAudience,
                    }
                  )}
                  InputProps={{
                    inputComponent: CurrencyField,
                  }}
                  variant="filled"
                  value={budget.value}
                  doOnChange={false}
                  useValue={false}
                  actionsChange={(value) => {
                    const newBudgets = [...budgets];
                    newBudgets[index].value = value;
                    setValue("budgets", newBudgets);
                    setValue(budget.fieldName, {
                      ...budget,
                      value,
                    });
                  }}
                  fullWidth
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                    validate: {
                      value: (budget) => {
                        if (!budget.value) return t(FIELD_REQUIRED);
                        if (parseInt(budget.value) < MIN_ALL_NUMBER_VALUE) {
                          return t(
                            AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
                            {
                              value: MIN_ALL_NUMBER_VALUE,
                              currency: LOCALE.currency,
                            }
                          );
                        }
                        if (parseInt(budget.value) < minProductValueShop) {
                          return t(
                            AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
                            {
                              value: minProductValueShop,
                              currency: LOCALE.currency,
                            }
                          );
                        }
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          name="budgetMonthly"
          disabled={true}
          label={t(AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER)}
          InputProps={{
            inputComponent: CurrencyField,
          }}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            validate: {
              value: (value) => {
                if (
                  watch("autoInvitationsIsActive") &&
                  value < MIN_ALL_NUMBER_VALUE
                ) {
                  return t(AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE, {
                    value: MIN_ALL_NUMBER_VALUE,
                    currency: LOCALE.currency,
                  });
                }
              },
            },
          }}
        />
      </Grid>
    </>
  );
};

export default ProfileInfluencerFormBudget;
