import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "./LoadingButton";
import { BUTTON_UPLOAD_INSTAGRAM } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startConnectFacebook } from "../../../actions/shops";
import { logoPlatform } from "../../../utils/socialNetworks";
import { INSTAGRAM_WHITE, META_SCOPES } from "../../../utils/constants";
import { GRADIENT_INSTAGRAM } from "../../../utils/colors";

const ButtonMetaAction = ({
  text = BUTTON_UPLOAD_INSTAGRAM,
  prevBlockClick = false,
  onPrevBlockClick = () => {},
  onClick = () => {},
  showGlobalLoading = false,
  sx = {},
  scope = META_SCOPES,
  logo = INSTAGRAM_WHITE,
  hasShadow = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const integrations = useSelectorApp((state) => state?.shop?.integrations);
  const facebookIntegrations = integrations?.facebook || {};
  const integrationID = Object.keys(facebookIntegrations)[0];
  const facebookIntegration = facebookIntegrations[integrationID] || {};
  const showFacebookOption = facebookIntegration?.isActive;

  return (
    <LoadingButton
      loading={isLoading}
      color="primary"
      hasShadow={hasShadow}
      sx={{
        background: GRADIENT_INSTAGRAM,
        width: "100%",
        ...sx,
      }}
      onClick={async (e) => {
        e.preventDefault();
        if (prevBlockClick) {
          onPrevBlockClick();
          return;
        }

        if (!showFacebookOption) {
          dispatch(
            startConnectFacebook({
              showGlobalLoading,
              onChangeLoading: (loading) => setIsLoading(loading),
              onCallbackConnected: onClick,
            })
          );
          return;
        }

        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      }}
    >
      <Avatar
        src={logoPlatform(logo)}
        variant="square"
        sx={{
          width: "20px",
          height: "20px",
          mr: 1,
          ".MuiAvatar-img": {
            objectFit: "contain",
          },
        }}
      />
      {t(text)}
    </LoadingButton>
  );
};

export default ButtonMetaAction;
