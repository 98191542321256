import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import {
  ACCOUNT_MODE_CREATE,
  ADMIN_ROLE,
  DESIGNER_ROLE,
  OPERATOR_ROLE,
} from "../../../../utils/constants";
import Typography from "@mui/material/Typography";
import {
  USER_FORM_EMAIL,
  USER_FORM_NAME,
  USER_FORM_PASSWORD,
  USER_FORM_PASSWORD_CONFIRMATION,
  USER_FORM_ROLE,
  USER_FORM_STORE,
} from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ADMIN,
  DESIGNER,
  EMAIL_PLACEHOLDER,
  MODAL_USER_NAME_PLACEHOLDER,
  MODAL_USER_PASSWORD_PLACEHOLDER,
  MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER,
  MODAL_USER_ROLE_OPERATOR_EXPLANATION,
  MODAL_USER_ROLE_PLACEHOLDER,
  MODAL_USER_STORE_PLACEHOLDER,
  OPERATOR,
} from "../../../../locales/keysTranslations";
import { useLocale } from "../../../../Contexts/LocaleContext";

const FormUsers = ({ data, storesList, onChangeForm, mode }) => {
  const { t } = useTranslationApp();

  const LOCALE = useLocale();
  const SPECIAL_STORES = Object.keys(LOCALE.specialStores || {});

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <TextField
        name="name"
        value={data.name}
        onChange={onChangeForm}
        label={t(MODAL_USER_NAME_PLACEHOLDER)}
        fullWidth
        variant="filled"
        data-tour={USER_FORM_NAME}
      />
      <TextField
        name="email"
        value={data.email}
        onChange={onChangeForm}
        label={t(EMAIL_PLACEHOLDER)}
        fullWidth
        variant="filled"
        data-tour={USER_FORM_EMAIL}
      />
      <FormControl fullWidth variant="filled" data-tour={USER_FORM_ROLE}>
        <InputLabel id="select-rol">
          {t(MODAL_USER_ROLE_PLACEHOLDER)}
        </InputLabel>
        <Select
          name="role"
          label={t(MODAL_USER_ROLE_PLACEHOLDER)}
          value={data.role}
          onChange={onChangeForm}
          labelId="select-rol"
        >
          <MenuItem value={ADMIN_ROLE}>{t(ADMIN)}</MenuItem>
          <MenuItem value={OPERATOR_ROLE}>
            <Tooltip
              title={t(MODAL_USER_ROLE_OPERATOR_EXPLANATION)}
              sx={{ width: "100%" }}
            >
              <Typography variant="body1">{t(OPERATOR)}</Typography>
            </Tooltip>
          </MenuItem>
          <MenuItem value={DESIGNER_ROLE}>{t(DESIGNER)}</MenuItem>
        </Select>
      </FormControl>
      {data.role === OPERATOR_ROLE && (
        <FormControl fullWidth variant="filled" data-tour={USER_FORM_STORE}>
          <InputLabel id="select-rol">
            {t(MODAL_USER_STORE_PLACEHOLDER)}
          </InputLabel>
          <Select
            name="storeID"
            label={t(MODAL_USER_STORE_PLACEHOLDER)}
            value={data.storeID}
            onChange={onChangeForm}
            labelId="select-store"
          >
            {Object.keys(storesList).map((key) => {
              if (SPECIAL_STORES.includes(storesList[key].storeType))
                return null;
              return (
                <MenuItem key={key} value={key}>
                  {storesList[key].name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {mode === ACCOUNT_MODE_CREATE && (
        <>
          <TextField
            name="password"
            value={data.password}
            onChange={onChangeForm}
            label={t(MODAL_USER_PASSWORD_PLACEHOLDER)}
            fullWidth
            variant="filled"
            data-tour={USER_FORM_PASSWORD}
          />
          <TextField
            name="repeatPassword"
            value={data.repeatPassword}
            onChange={onChangeForm}
            label={t(MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER)}
            fullWidth
            variant="filled"
            data-tour={USER_FORM_PASSWORD_CONFIRMATION}
          />
        </>
      )}
    </Box>
  );
};

export default FormUsers;
