import React from "react";
import Button from "./Button";

const ButtonSlideMenu = ({ children, onClick, startIcon, endIcon, sx }) => {
  return (
    <Button
      hasShadow={false}
      variant="contained"
      color="secondary"
      disableElevation
      fullWidth
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        backgroundColor: "background.paper",
        color: "background.paperContrastText",
        justifyContent: "left",
        mb: 1,
        width: "min-content",
        fontSize: "0.905rem",
        fontWeight: 600,
        pl: 1,
        "&:hover": {
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonSlideMenu;
