import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CompanyInformationForm from "../components/User/Account/CompanyInformationForm";
import ScheduleForm from "../components/User/Account/ScheduleForm";
import ProfileInfluencerContainer from "../components/User/Account/ProfileInfluencer/ProfileInfluencerContainer";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { getShopData } from "../actions/shops";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Loader from "../components/Common/Loader/Loader";
import PasswordForm from "../components/User/Account/PasswordForm";
import PlatformsConnectSection from "../components/User/Account/PlatformsConnectSection";
import { useSearchParams } from "react-router-dom";
import {
  ACCOUNT_DESCRIPTION,
  ACCOUNT_TITLE,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import ButtonOpenModalAutomaticInvitesConfiguration from "../components/Common/Buttons/ButtonOpenModalAutomaticInvitesConfiguration";
import { getIsCampaignModeFeatureFlag } from "../actions/getters";

const Account = () => {
  const [loading, setLoading] = useState(true);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const { user } = useSelectorApp((state) => state.auth);
  const isCampaignMode = dispatch(getIsCampaignModeFeatureFlag());

  const [params] = useSearchParams();
  const focus = params.get("focus");

  useEffect(() => {
    if (loading) return;
    if (focus) {
      setTimeout(
        () =>
          window?.document?.querySelectorAll(`.${focus}`)[0]?.scrollIntoView({
            inline: "nearest",
            behavior: "smooth",
            block: "center",
          }),
        600
      );
    }
  }, [focus, loading]);

  useEffect(() => {
    dispatch(getShopData(user.shopID, () => setLoading(false)));
    // eslint-disable-next-line
  }, [user.shopID]);

  return (
    <ContainerPage>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box>
          <TitlePage>{t(ACCOUNT_TITLE)}</TitlePage>
          <DescriptionPage>{t(ACCOUNT_DESCRIPTION)}</DescriptionPage>
        </Box>
        {!isCampaignMode && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              mt: {
                xs: 0,
                sm: 1,
              },
              mb: {
                xs: 2,
                sm: 0,
              },
            }}
          >
            <ButtonOpenModalAutomaticInvitesConfiguration from="invitations" />
          </Box>
        )}
      </Box>
      {loading ? (
        <Loader fullWidth />
      ) : (
        <Box
          sx={{
            pb: 3,
            width: "100%",
          }}
        >
          <CompanyInformationForm />
          <ProfileInfluencerContainer />
          <PlatformsConnectSection />
          {!isCampaignMode && <ScheduleForm />}
          <PasswordForm />
        </Box>
      )}
    </ContainerPage>
  );
};

export default Account;
