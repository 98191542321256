import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import TextFieldMUI from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import {
  ADMIN_ROLE,
  OPERATOR_ROLE,
  SUPER_ADMIN_ROLE,
} from "../../../utils/constants";
import Select from "../../Common/Selects/Select";
import TextField from "../../Common/Fields/TextField";
import { Controller } from "react-hook-form";
import { REGEX_EMAIL } from "../../../utils/regexsValidation";
import { startGetShopsByName } from "../../../actions/shops";
import { useLocale } from "../../../Contexts/LocaleContext";
import {
  ADMIN,
  EMAIL_PLACEHOLDER,
  FIELD_EMAIL_VALID,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_CHANGE_MIN_LENGTH,
  FIELD_PASSWORD_MIN_LENGTH,
  FIELD_REQUIRED,
  MAIN_BRAND_PLACEHOLDER,
  MODAL_USER_ROLE_PLACEHOLDER,
  NAME_BUSINESS_PLACEHOLDER,
  OPERATOR,
  OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES,
  OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE,
  PASSWORD_CONFIRMATION_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  SELECT_BRANDS_PLACEHOLDER,
  SELECT_BRAND_PLACEHOLDER,
  SELECT_BUSINESS_PLACEHOLDER,
  NAME_AND_LAST_NAME_PLACEHOLDER,
  SELECT_STORE_PLACEHOLDER,
  SUPER_ADMIN,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const CreateAdminUsersForm = ({ control, watch, mode, errors, setValue }) => {
  const [shopsOptions, setShopsOptions] = useState([]);
  const [shopSearch, setShopSearch] = useState("");
  const [businessesOptions, setBusinessesOptions] = useState([]);
  const { shops } = useSelectorApp((state) => state.shop);
  const { businesses } = useSelectorApp((state) => state.businesses);
  const country = useSelectorApp((state) => state.auth.user.country);

  const { t } = useTranslationApp();

  const LOCALE = useLocale();
  const SPECIAL_STORES = Object.keys(LOCALE.specialStores || {});

  const dispatch = useDispatchApp();

  const role = watch("role");
  const businessID = watch("businessID") ?? "";
  const shopIDs = watch("shopIDs") ?? [];
  const shopID = watch("shopID") ?? "";
  const password = watch("password") ?? "";
  const repeatPassword = watch("repeatPassword") ?? "";

  useEffect(() => {
    let filteredShop = [];
    Object.keys(shops ?? {}).forEach((shopKey) => {
      if (shops[shopKey]?.signUpCountry !== country) return;
      return filteredShop.push(shopKey);
    });
    setShopsOptions(filteredShop);
    let filteredBusinesses = [];
    Object.keys(businesses ?? {}).forEach((businessesID) => {
      return filteredBusinesses.push(businessesID);
    });
    setBusinessesOptions(filteredBusinesses);
  }, [shops, businesses, country]);

  useEffect(() => {
    if (shopSearch.length >= 3) {
      dispatch(startGetShopsByName(shopSearch));
    }
    // eslint-disable-next-line
  }, [shopSearch]);

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1.5 }}>
      {(mode !== "edit" || role !== OPERATOR_ROLE) && (
        <Select
          name="role"
          label={t(MODAL_USER_ROLE_PLACEHOLDER)}
          options={[
            { value: SUPER_ADMIN_ROLE, label: t(SUPER_ADMIN) },
            { value: ADMIN_ROLE, label: t(ADMIN) },
            { value: OPERATOR_ROLE, label: t(OPERATOR) },
          ]}
          fullWidth
          variant="filled"
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
          actionsChange={() => {
            setValue("businessID", "");
            setValue("businessName", "");
            setValue("shopIDs", []);
            setValue("parentShopID", "");
            if (role === SUPER_ADMIN_ROLE) {
              setValue("shopID", "");
            }
            setValue("storeID", "");
          }}
        />
      )}
      {role === SUPER_ADMIN_ROLE && (
        <>
          <Controller
            name="businessID"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <Autocomplete
                  {...field}
                  disablePortal
                  getOptionLabel={(option) =>
                    businesses[option]?.businessName ?? option
                  }
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    if (!newValue) {
                      setValue("businessName", "");
                      setValue("parentShopID", "");
                      setValue("shopIDs", []);
                    }
                    if (newValue?.length > 0) {
                      setValue("businessName", "");
                      setValue(
                        "parentShopID",
                        businesses[newValue]?.parentShopID ?? ""
                      );
                      setValue("shopIDs", businesses[newValue]?.shops);
                    }
                  }}
                  options={businessesOptions}
                  isOptionEqualToValue={(option, value) => {
                    return value === "" ? true : option === value;
                  }}
                  renderInput={(params) => (
                    <TextFieldMUI
                      {...params}
                      variant="filled"
                      label={t(SELECT_BUSINESS_PLACEHOLDER)}
                      fullWidth
                      error={!!errors["businessID"]}
                      helperText={
                        !!errors["businessID"]
                          ? errors["businessID"]?.message
                          : ""
                      }
                    />
                  )}
                />
                <FormHelperText>
                  {t(OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE)}
                </FormHelperText>
              </>
            )}
          />
          {!businessID && (
            <TextField
              name="businessName"
              label={t(NAME_BUSINESS_PLACEHOLDER)}
              fullWidth
              variant="filled"
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (role === SUPER_ADMIN_ROLE && value === "") {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
            />
          )}
          {(!businessID || mode === "edit") && (
            <Controller
              name="shopIDs"
              control={control}
              defaultValue={[]}
              rules={{
                validate: (value) => {
                  if (role === SUPER_ADMIN_ROLE && value.length === 0) {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  multiple
                  getOptionLabel={(option) =>
                    shops[option]?.businessName ?? option
                  }
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    setValue("parentShopID", "");
                  }}
                  options={shopsOptions ?? [] ?? []}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    const value = inputProps.value;
                    setShopSearch(value);
                    return (
                      <TextFieldMUI
                        {...params}
                        variant="filled"
                        label={t(SELECT_BRANDS_PLACEHOLDER)}
                        fullWidth
                        error={!!errors["shopIDs"]}
                        helperText={
                          !!errors["shopIDs"] ? errors["shopIDs"]?.message : ""
                        }
                      />
                    );
                  }}
                />
              )}
            />
          )}
          {(!businessID || mode === "edit") && (
            <Select
              name="parentShopID"
              fullWidth
              variant="filled"
              label={t(MAIN_BRAND_PLACEHOLDER)}
              options={
                shopIDs?.length > 0
                  ? shopIDs.map((shopID) => ({
                      value: shopID,
                      label: shops[shopID]?.businessName ?? shopID,
                    }))
                  : []
              }
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (role === SUPER_ADMIN_ROLE && value === "") {
                    return t(FIELD_REQUIRED);
                  }
                },
              }}
            />
          )}
        </>
      )}
      {(role === ADMIN_ROLE || role === OPERATOR_ROLE) && (
        <Controller
          name="shopID"
          control={control}
          defaultValue=""
          rules={{
            validate: (value) => {
              if (
                (role === ADMIN_ROLE || role === OPERATOR_ROLE) &&
                (value?.length === 0 || value === "")
              ) {
                return t(FIELD_REQUIRED);
              }
            },
          }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              getOptionLabel={(option) => shops[option]?.businessName ?? option}
              onChange={(event, newValue) => {
                field.onChange(newValue);
                setValue("storeID", "");
              }}
              isOptionEqualToValue={(option, value) => {
                return value === "" ? true : option === value;
              }}
              options={shopsOptions ?? []}
              renderInput={(params) => {
                const inputProps = params.inputProps;
                const value = inputProps.value;
                setShopSearch(value);
                return (
                  <TextFieldMUI
                    {...params}
                    variant="filled"
                    label={t(SELECT_BRAND_PLACEHOLDER)}
                    fullWidth
                    error={!!errors["shopID"]}
                    helperText={
                      !!errors["shopID"] ? errors["shopID"]?.message : ""
                    }
                  />
                );
              }}
            />
          )}
        />
      )}
      {role === OPERATOR_ROLE && shopID.length > 0 ? (
        Object.keys(shops[shopID]?.stores ?? {}).length > 0 ? (
          <Select
            name="storeID"
            label={t(SELECT_STORE_PLACEHOLDER)}
            variant="filled"
            fullWidth
            options={Object.keys(shops[shopID]?.stores ?? {})
              .filter(
                (storeKey) =>
                  !SPECIAL_STORES.includes(
                    shops[shopID]?.stores[storeKey]?.storeType
                  )
              )
              .map((storeKey) => ({
                value: storeKey,
                label: shops[shopID].stores[storeKey]?.name ?? storeKey,
              }))}
            control={control}
            errors={errors}
            rules={{
              validate: (value) => {
                if (
                  role === OPERATOR_ROLE &&
                  (value?.length === 0 || value === "")
                ) {
                  return t(FIELD_REQUIRED);
                }
              },
            }}
          />
        ) : (
          t(OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES)
        )
      ) : null}
      <TextField
        name="name"
        label={t(NAME_AND_LAST_NAME_PLACEHOLDER)}
        fullWidth
        variant="filled"
        control={control}
        errors={errors}
        rules={{
          required: { value: true, message: t(FIELD_REQUIRED) },
        }}
      />
      <TextField
        name="email"
        label={t(EMAIL_PLACEHOLDER)}
        fullWidth
        variant="filled"
        control={control}
        errors={errors}
        rules={{
          required: { value: true, message: t(FIELD_REQUIRED) },
          pattern: {
            value: REGEX_EMAIL,
            message: t(FIELD_EMAIL_VALID),
          },
        }}
      />
      <TextField
        name="password"
        label={t(PASSWORD_PLACEHOLDER)}
        fullWidth
        variant="filled"
        control={control}
        errors={errors}
        rules={{
          validate: (value) => {
            if (mode === "create" && value === "") {
              return t(FIELD_REQUIRED);
            }
            if (mode === "create" && value.length < 6) {
              return t(FIELD_PASSWORD_MIN_LENGTH);
            }
            if (value !== repeatPassword) {
              return t(FIELD_PASSWORDS_NO_MATCH);
            }
            if (mode === "edit" && value.length > 0 && value.length < 6) {
              return t(FIELD_PASSWORD_CHANGE_MIN_LENGTH);
            }
          },
        }}
      />
      <TextField
        name="repeatPassword"
        label={t(PASSWORD_CONFIRMATION_PLACEHOLDER)}
        fullWidth
        variant="filled"
        control={control}
        errors={errors}
        rules={{
          validate: (value) => {
            if (mode === "create" && value === "") {
              return t(FIELD_REQUIRED);
            }
            if (mode === "create" && value.length < 6) {
              return t(FIELD_PASSWORD_MIN_LENGTH);
            }
            if (password !== value) {
              return t(FIELD_PASSWORDS_NO_MATCH);
            }
            if (mode === "edit" && value.length > 0 && value.length < 6) {
              return t(FIELD_PASSWORD_CHANGE_MIN_LENGTH);
            }
          },
        }}
      />
    </Box>
  );
};

export default CreateAdminUsersForm;
