import React from "react";
import Accordion from "../../Common/Containers/Accordion";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ContainerKeyValue from "../../Common/Containers/ContainerKeyValue";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_ID,
  CREATOR,
  DATE_LONG,
  REPORT_REQUESTS,
  REQUESTS_ACCEPTED,
  REQUESTS_ACTIVE,
  REQUESTS_REJECTED,
  WAS_GENERATED,
} from "../../../locales/keysTranslations";

const ShopReportRequests = ({
  data,
  requestsAccepted = {},
  requestsRejected = {},
  influencers,
}) => {
  const { t } = useTranslationApp();
  return (
    <Accordion title={t(REPORT_REQUESTS)} sx={{}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          maxHeight: 400,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1 }}>
          <Typography variant="body1">{`${t(REQUESTS_ACCEPTED)}: ${
            Object.keys(requestsAccepted).length
          } ${t(REQUESTS_REJECTED)}: ${
            Object.keys(requestsRejected).length
          }`}</Typography>
          <Typography variant="body1">{`${t(REQUESTS_ACTIVE)}: ${
            Object.keys(data).length
          }`}</Typography>
        </Box>
        {Object.keys(data).map((requestKey) => {
          return (
            <Request
              key={requestKey}
              requestKey={requestKey}
              data={data[requestKey]}
              influencer={influencers[data[requestKey].userID]}
              userID={data[requestKey].userID}
            />
          );
        })}
      </Box>
    </Accordion>
  );
};

const Request = ({ data, requestKey, influencer, userID }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ContainerKeyValue
          keyValue={`${t(COLUMN_NAME_ID)}: `}
          value={requestKey}
        />
        <ContainerKeyValue
          keyValue={`${t(CREATOR)}: `}
          value={influencer?.mainAccountName}
          redirectURL="influencers-accounts?userID"
          redirectValue={userID}
        />
        <ContainerKeyValue
          keyValue={`${t(WAS_GENERATED)}: `}
          value={t(DATE_LONG, {
            date: new Date(data.creationTime),
          })}
        />
      </Box>
    </Box>
  );
};

export default ShopReportRequests;
