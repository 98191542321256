import { useSelectorApp } from "../../lib/redux";
import { Navigate } from "react-router-dom";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../utils/constants";

export const AdminRoute = ({
  children,
  isLogged,
  denyWithPlan = false,
  plan,
}) => {
  const { user } = useSelectorApp((state) => state.auth);
  if (isLogged && denyWithPlan && plan !== "")
    return <Navigate to="/billing-and-payments" />;
  return isLogged &&
    user.shopID &&
    (user.role === ADMIN_ROLE || user.role === SUPER_ADMIN_ROLE) ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
