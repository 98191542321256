import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { logoPlatform } from "../../../utils/socialNetworks";
import { ROLES_DASHBOARD, YOUTUBE } from "../../../utils/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getInfluencerSocialLink } from "../../../utils/urls";
import InfluencerIsFavoriteIcon from "./InfluencerIsFavoriteIcon";
import { getRole } from "../../../actions/getters";
import { useDispatchApp } from "../../../lib/redux";
import LinkText from "../Texts/LinkText";

const InfluencerNameLogo = ({
  dataTour = "",
  data,
  userID,
  variantText = "h6",
  variantTextMobile = "body1",
  styleText = "normal",
  sizeLogo = 30,
  colorText = "inherit",
  sx = {},
  showFavorite = false,
  modeColorFavorite = "white",
  sxFavorite = {},
  isExternal = false,
  redirectSocial = false,
}) => {
  const isMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatchApp();
  const role = dispatch(getRole());

  const getLinkRedirect = () => {
    if (ROLES_DASHBOARD.includes(role)) {
      return getInfluencerSocialLink(data);
    }
    if (data?.hasAnalytics && !redirectSocial) {
      return `/influencer?userID=${userID}`;
    }
    if (isExternal && !redirectSocial) {
      return `influencer?from=discovery&accountName=${data.mainAccountName}&platform=${data.mainPlatform}`;
    }
    return getInfluencerSocialLink(data);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
        ...sx,
      }}
      data-tour={dataTour}
    >
      <Avatar
        alt={`Logo de ${data?.mainPlatform}`}
        src={logoPlatform(data?.mainPlatform)}
        variant="square"
        sx={{
          mr: 1,
          width: `${data?.mainPlatform === YOUTUBE ? "43.3px" : "30px"}`,
          height: `${sizeLogo}px`,
        }}
      />
      <LinkText
        href={getLinkRedirect()}
        sx={{
          fontWeight: 600,
          fontStyle: styleText,
          wordBreak: "break-word",
          color: `${colorText} !important`,
        }}
        target="_blank"
        variant={isMobile ? variantText : variantTextMobile}
      >
        {data?.mainAccountName}
      </LinkText>
      {showFavorite && (
        <InfluencerIsFavoriteIcon
          userID={data.userID}
          showActions={true}
          sx={{ mb: 0, ml: 2, p: 0, ...sxFavorite }}
          modeColor={modeColorFavorite}
        />
      )}
    </Box>
  );
};

export default InfluencerNameLogo;
