import React, { useCallback, useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import useSound from "use-sound";
import notification from "../assets/sounds/notification-delivery.mp3";
import { PublicRoute } from "./routes/PublicRoute";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { checkingFinish, login, startLogout } from "../actions/auth";
import CssBaseline from "@mui/material/CssBaseline";
import Navigation from "../components/Common/Navigation/Navigation";

//Routes
import { AdminRoute } from "./routes/AdminRoute";
import { OperatorRoute } from "./routes/OperatorRoute";
import { OwnerRoute } from "./routes/OwnerRoute";

//Pages
import Login from "../pages/Login";
import RedeemCode from "../pages/RedeemCode";
// import RequestInfluencers from "../pages/RequestInfluencers";
// import InfluencersList from "../pages/InfluencersList";
import Consumptions from "../pages/Consumptions";
import Publication from "../pages/Publication";
import Delivery from "../pages/Delivery";
import BillingData from "../pages/BillingData";
import BillingAndPayments from "../pages/BillingAndPayments";
import Menu from "../pages/Menu";
import Account from "../pages/Account";
import Notifications from "../pages/Notifications";
import Users from "../pages/Users";
import AdminUsers from "../pages/Owner/AdminUsers";
import Stores from "../pages/Stores";
import Error404 from "../pages/Error404";
import Campaigns from "../pages/Campaigns";
import CreateCampaigns from "../pages/CreateCampaigns";
import CampaignDetail from "../pages/CampaignDetail";
import ContentApplicant from "../pages/ContentApplicant";
import PayCreators from "../pages/Owner/PayCreators";

import {
  startGetPlan,
  startVerifyIsActivePlan,
  startGetPaymentModel,
  startGetStripeInfo,
  startGetPlans,
} from "../actions/payments";
import { startGetAllNotifications } from "../actions/notifications";
import {
  startGetCostCollaborationShop,
  startGetClappCredits,
  startGetIsActiveShop,
  startGetLastCommentInvite,
  startGetCategoryShop,
  startGetSignUpCountryShop,
  startGetIsActiveShopFirstTime,
  startGetIntegrations,
  startGetLastDateInvite,
  startGetBlockedLastInviteDate,
  startGetProfileInfluencerData,
  startGetShowSocialFeedGenerator,
  startGetIsFreeTrial,
  startGetBusinessID,
  startGetStores,
  startGetScheduleInfo,
  startGetLastInviteConfiguration,
  startGetFeatureFlags,
  startGetCurrencyShop,
} from "../actions/shops";
import {
  getAllDeliveriesByStore,
  getAllDeliveriesOfAllShops,
  getAllDeliveriesOfAllStores,
} from "../actions/deliveries";
import Notification from "../components/Common/Notifications/Notification";
import InfluencersUsers from "../pages/Owner/InfluencersUsers";
import RequestsInfluencersAccounts from "../pages/Owner/RequestsInfluencersAccounts";
import Shops from "../pages/Owner/Shops";
import Statistics from "../pages/Owner/Statistics";
import Invitations from "../pages/Invitations";
import Register from "../pages/Register";
import ShopDetail from "../pages/Owner/ShopDetail";
import Contents from "../pages/Owner/Contents";
import {
  ADMIN_ROLE,
  OPERATOR_ROLE,
  OWNER_ROLE,
  ROLES_ALLOWED,
  SUPER_ADMIN_ROLE,
  ROLES_DASHBOARD,
  BUSINESS_ID_PROJECT,
  DESIGNER_ROLE,
} from "../utils/constants";
import { off } from "firebase/database";
import Backdrop from "../components/Common/Loader/Backdrop";
import DeliveriesOwner from "../pages/Owner/DeliveriesOwner";
import { MenuProvider } from "../Contexts/MenuContext";
import InfluencerStatistics from "../pages/InfluencerStatistics";
import DiscoveryInfluencers from "../pages/DiscoveryInfluencers";
import MonthlyReport from "../pages/MonthlyReport";
import Ambassadors from "../pages/Ambassadors";
import { startGetAllChats } from "../actions/chat";
import RegisterShops from "../pages/Owner/RegisterShops";
import Events from "../pages/Events";
import CreateEvent from "../pages/CreateEvent";
import EventDetail from "../pages/EventDetail";
import OnboardingContext from "../onboarding/OnboardingContext";
import Creators from "../pages/Creators";
import Feedbacks from "../pages/Owner/Feedbacks";
import CreatorsScript from "../pages/CreatorsScript";
import { LocaleProvider } from "../Contexts/LocaleContext";
import { startGetLocale, startGetLocalesList } from "../actions/locales";
import { ManagerRoute } from "./routes/ManagerRoute";
import { FunctionalitiesModalsProvider } from "../Contexts/FunctionalitiesModalsContext";
import { startGetMinProductValue } from "../actions/menu";
import { StaffRoute } from "./routes/StaffRoute";
import PaymentsFailed from "../pages/Owner/PaymentsFailed";
import {
  setShowModalOnboardingRequiredInformation,
  setShowModalSelectPackage,
  startGetThemeBusiness,
  startGetVersionWeb,
} from "../actions/ui";
import ResetPassword from "../pages/ResetPassword";
import IntegrationsTerms from "../pages/IntegrationsTerms";
import ModalBlockVersionApp from "../components/Common/Modal/ModalBlockVersionApp";
import { getIsBlockedActions } from "../actions/getters";
import Order from "../pages/Order";
import { RedirectProvider } from "../Contexts/RedirectContext";
import Loader from "../components/Common/Loader/Loader";
import _ from "lodash";
import { Helmet } from "react-helmet";
import OpenChatsButton from "../components/Common/Chat/OpenChatsButton";
import ModalSelectPackage from "../components/User/BillingAndPayments/BillingAndPayments/ModelPackage/ModalSelectPackage";
import DesignerRoute from "./routes/DesignerRoute";
import ContentApplication from "../pages/Owner/ContentApplication";
import AuthTikTokCallback from "../pages/Public/AuthTiktok/AuthTikTokCallback";
import AuthTikTokFailed from "../pages/Public/AuthTiktok/AuthTikTokFailed";
import AuthTikTokSuccess from "../pages/Public/AuthTiktok/AuthTikTokSuccess";
import AdsCampaigns from "../pages/AdsCampaigns";
import CreateAdsCampaigns from "../pages/CreateAdsCampaigns";
import AdsCampaign from "../pages/AdsCampaign";
import TopContent from "../pages/TopContent";
import ModalOnboardingRequiredInformation from "../components/Common/Modal/ModalOnboardingRequiredInformation/ModalOnboardingRequiredInformation";
import { startGetPreRegisteredInfluencersProcess } from "../actions/influencers";

const AppRouter = () => {
  const [isActiveShopListener, setIsActiveShopListener] = useState(null);
  const [isPlanActiveListener, setIsPlanActiveListener] = useState(null);
  const [preRegisteredProcessListener, setPreRegisteredProcessListener] =
    useState(null);
  const [notificationsListener, setNotificationsListener] = useState(null);
  const [chatsListener, setChatsListener] = useState(null);
  const [loadingRequiredData, setLoadingRequiredData] = useState(true);
  const [versionApp] = useState("1.1.6");
  const [play, { sound }] = useSound(notification);

  const {
    isActiveSoundDelivery,
    version,
    showModalSelectPackage,
    showModalOnboardingRequiredInformation,
  } = useSelectorApp((state) => state.ui);
  const { user, isLoading } = useSelectorApp((state) => state.auth);
  const subscriptionPlan = useSelectorApp((state) => state.payments.plan);
  const { blockedLastInviteDate } = useSelectorApp((state) => state.shop);
  const theme = useSelectorApp((state) => state.ui.theme);

  const isOpenVersionBlock =
    version && versionApp !== version && !loadingRequiredData;

  const dispatch = useDispatchApp();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        if (!ROLES_ALLOWED.includes(token.claims.role)) {
          auth.signOut();
          await dispatch(startLogout());
        } else {
          const userData = {
            uid: user.uid,
            email: user.email,
            name: token.claims.name,
            role: token.claims?.role,
            country: token.claims?.country,
            businessID: token.claims?.businessID,
            shopID: token.claims?.shopID,
            shopIDs: token.claims?.shopIDs,
            storeID: token.claims?.storeID,
            token: token.token,
          };
          dispatch(login(userData));
        }
      } else {
        await dispatch(startLogout());
        dispatch(checkingFinish());
      }
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getRequiredData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading) return;

    dispatch(
      getIsBlockedActions({
        verifyBlockedLastInvite: true,
      })
    );
    //eslint-disable-next-line
  }, [blockedLastInviteDate, isLoading, subscriptionPlan]);

  useEffect(() => {
    getSuperAdminData();
    //eslint-disable-next-line
  }, [user?.shopIDs]);

  useEffect(() => {
    onClearListeners();
    getData();
    return () => {
      onClearListeners();
    };
    //eslint-disable-next-line
  }, [user?.shopID]);

  useEffect(() => {
    getDataOwner();
    //eslint-disable-next-line
  }, [user]);

  const getRequiredData = async () => {
    await dispatch(startGetLocalesList());
    await dispatch(startGetVersionWeb());
    if (!_.isEmpty(BUSINESS_ID_PROJECT)) {
      await dispatch(startGetThemeBusiness());
    }
    setLoadingRequiredData(false);
  };

  const getData = () => {
    getCommonData();
    getCommonAdminsData();
    getAdminData();
    getDataOperator();
    getDataDesigner();
  };

  const getDataOwner = async () => {
    if (ROLES_DASHBOARD.includes(user?.role)) {
      dispatch(startGetLocale(user?.country));
      dispatch(
        startGetAllChats((refChats) => {
          setChatsListener(refChats);
        }, "user")
      );
      dispatch(startGetPlans());
    }
  };
  const getCommonAdminsData = async () => {
    if (
      user?.role === ADMIN_ROLE ||
      (user?.role === SUPER_ADMIN_ROLE && user?.shopID?.length > 0)
    ) {
      dispatch(startGetProfileInfluencerData());
      dispatch(startGetStripeInfo());
      dispatch(startGetIsFreeTrial());
      dispatch(startGetClappCredits());
      dispatch(startGetIntegrations());
      dispatch(startGetStores());
      dispatch(startGetScheduleInfo());
      dispatch(
        startGetAllNotifications((refNotifications) => {
          setNotificationsListener(refNotifications);
        })
      );
      dispatch(
        startGetPreRegisteredInfluencersProcess((refProcess) => {
          setPreRegisteredProcessListener(refProcess);
        })
      );
      dispatch(
        startGetAllChats((refChats) => {
          setChatsListener(refChats);
        }, "shop")
      );
      dispatch(startGetMinProductValue());
    }
  };

  const getSuperAdminData = () => {
    if (user?.role === SUPER_ADMIN_ROLE && user?.shopIDs.length > 0) {
      dispatch(getAllDeliveriesOfAllShops());
    }
  };

  const getAdminData = () => {
    if (user?.role === ADMIN_ROLE) {
      dispatch(getAllDeliveriesOfAllStores());
    }
  };

  const getDataOperator = () => {
    if (user?.role === OPERATOR_ROLE) {
      dispatch(getAllDeliveriesByStore());
    }
  };

  const getDataDesigner = () => {
    if (user?.role === DESIGNER_ROLE) {
      dispatch(startGetIntegrations());
    }
  };

  const getCommonData = async () => {
    if (!ROLES_DASHBOARD.includes(user?.role) && user?.shopID?.length > 0) {
      dispatch(startGetFeatureFlags());
      dispatch(startGetCurrencyShop());
      dispatch(startGetPlan(user.shopID));
      const locale = await dispatch(startGetSignUpCountryShop());
      dispatch(startGetLocale(locale));
      dispatch(startGetBusinessID());
      dispatch(
        startVerifyIsActivePlan((refIsPlanActive) => {
          setIsPlanActiveListener(refIsPlanActive);
        })
      );
      dispatch(
        startGetIsActiveShop((refIsActiveShop) => {
          setIsActiveShopListener(refIsActiveShop);
        })
      );
      await dispatch(startGetPaymentModel());
      dispatch(startGetShowSocialFeedGenerator());
      dispatch(startGetPlans());
      dispatch(startGetIsActiveShopFirstTime());
      dispatch(startGetCostCollaborationShop());
      dispatch(startGetCategoryShop());
      dispatch(startGetLastInviteConfiguration());
      dispatch(startGetLastCommentInvite());
      dispatch(startGetLastDateInvite());
      dispatch(startGetBlockedLastInviteDate());
    }
  };

  const onClearListeners = () => {
    if (isActiveShopListener) {
      off(isActiveShopListener);
    }
    if (isPlanActiveListener) {
      off(isPlanActiveListener);
    }
    if (notificationsListener) {
      off(notificationsListener);
    }
    if (chatsListener) {
      off(chatsListener);
    }
    if (preRegisteredProcessListener) {
      off(preRegisteredProcessListener);
    }
  };

  const onPlaySound = useCallback(() => {
    sound?.loop(true);
    play();
  }, [play, sound]);

  const onStopSound = useCallback(() => {
    sound?.stop();
  }, [sound]);

  useEffect(() => {
    isActiveSoundDelivery ? onPlaySound() : onStopSound();
    return () => {
      onStopSound();
    };
  }, [isActiveSoundDelivery, onPlaySound, onStopSound]);

  if (loadingRequiredData) return <Loader fullScreen fullWidth />;

  global.NAME_BUSINESS = theme.nameBusiness;
  global.primaryColor = theme.palette.primary.main;
  global.secondaryColor = theme.palette.secondary.main;
  global.textPrimaryColor = theme.palette.primary.contrastText;
  global.textSecondaryColor = theme.palette.secondary.contrastText;
  global.paper = theme.palette.background.paper;
  global.paperContrastText = theme.palette.background.paperContrastText;
  global.paperSecondary = theme.palette.background.paperSecondary;
  global.paperSecondaryContrastText =
    theme.palette.background.paperSecondaryContrastText;
  document.documentElement.style.setProperty(
    "--primary-color",
    theme.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    theme.palette.secondary.main
  );
  document.documentElement.style.setProperty(
    "--text-primary-color",
    theme.palette.primary.contrastText
  );
  document.documentElement.style.setProperty(
    "--text-secondary-color",
    theme.palette.secondary.contrastText
  );
  document.documentElement.style.setProperty(
    "--paper",
    theme.palette.background.paper
  );
  document.documentElement.style.setProperty(
    "--paper-contrast-text",
    theme.palette.background.paperContrastText
  );
  document.documentElement.style.setProperty(
    "--paper-secondary",
    theme.palette.background.paperSecondary
  );
  document.documentElement.style.setProperty(
    "--paper-secondary-contrast-text",
    theme.palette.background.paperSecondaryContrastText
  );
  document.documentElement.style.setProperty(
    "--hover-main",
    theme.palette.hover.main
  );
  document.documentElement.style.setProperty(
    "--font-family",
    theme.typography.fontFamily
  );
  return (
    <BrowserRouter>
      <LocaleProvider>
        <OnboardingContext>
          <FunctionalitiesModalsProvider>
            <RedirectProvider>
              <Helmet>
                <title>{`${theme.nameBusiness} - Web Panel`}</title>
                <link rel="icon" href={theme.favicon} />
                <link rel="apple-touch-icon" href={theme.favicon} />
              </Helmet>
              <CssBaseline />
              <Navigation isLogged={user ? true : false}>
                <Notification />
                <Backdrop />
                <Routes>
                  <Route path="*" element={<Error404 />} />
                  <Route
                    path="/creator-script"
                    element={
                      <PublicRoute
                        restricted={false}
                        isLogged={user ? true : false}
                      >
                        <CreatorsScript />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/integrations-terms"
                    element={
                      <PublicRoute
                        restricted={false}
                        isLogged={user ? true : false}
                      >
                        <IntegrationsTerms />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/auth/tiktok/callback"
                    element={
                      <PublicRoute
                        restricted={false}
                        isLogged={user ? true : false}
                      >
                        <AuthTikTokCallback />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/auth/tiktok/success"
                    element={
                      <PublicRoute
                        restricted={false}
                        isLogged={user ? true : false}
                      >
                        <AuthTikTokSuccess />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/auth/tiktok/failed"
                    element={
                      <PublicRoute
                        restricted={false}
                        isLogged={user ? true : false}
                      >
                        <AuthTikTokFailed />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <PublicRoute
                        restricted={true}
                        isLogged={user ? true : false}
                      >
                        <Login />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <PublicRoute
                        restricted={true}
                        isLogged={user ? true : false}
                      >
                        <ResetPassword />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <PublicRoute
                        restricted={true}
                        isLogged={user ? true : false}
                      >
                        <Register />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/redeem-code"
                    element={
                      <OperatorRoute isLogged={user ? true : false}>
                        <RedeemCode />
                      </OperatorRoute>
                    }
                  />
                  <Route
                    path="/delivery"
                    element={
                      <OperatorRoute isLogged={user ? true : false}>
                        <Delivery />
                      </OperatorRoute>
                    }
                  />
                  <Route
                    path="/menu"
                    element={
                      <OperatorRoute isLogged={user ? true : false}>
                        <MenuProvider>
                          <Menu />
                        </MenuProvider>
                      </OperatorRoute>
                    }
                  />
                  {/*Routes Admin*/}
                  <Route
                    path="/creators"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Creators />
                      </AdminRoute>
                    }
                  />
                  {/* <Route
              path="/creators"
              element={
                <AdminRoute isLogged={user ? true : false}>
                  <InfluencersList />
                </AdminRoute>
              }
            /> */}
                  <Route
                    path="/ambassadors"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Ambassadors />
                      </AdminRoute>
                    }
                  />
                  {/* <Route
              path="/requests-influencers"
              element={
                <AdminRoute isLogged={user ? true : false}>
                  <RequestInfluencers />
                </AdminRoute>
              }
            /> */}
                  <Route
                    path="/invitations"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Invitations />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/publications"
                    element={
                      <DesignerRoute isLogged={user ? true : false}>
                        <Consumptions />
                      </DesignerRoute>
                    }
                  />
                  <Route
                    path="/publication"
                    element={
                      <DesignerRoute isLogged={user ? true : false}>
                        <Order />
                      </DesignerRoute>
                    }
                  />
                  <Route
                    path="/ads-campaign"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <AdsCampaign />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/ads-campaigns"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <AdsCampaigns />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/create-ads-campaign"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <CreateAdsCampaigns />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/campaigns"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Campaigns />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/campaign"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <CampaignDetail />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/create-campaign"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <CreateCampaigns />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/content-applicant"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <ContentApplicant />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/events"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Events />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/event"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <EventDetail />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/create-event"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <CreateEvent />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/top-content"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <TopContent />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Account />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/billing-data"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <BillingData />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/billing-and-payments"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <BillingAndPayments />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/notifications"
                    element={
                      <OperatorRoute isLogged={user ? true : false}>
                        <Notifications />
                      </OperatorRoute>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Users />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/stores"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <Stores />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/influencer"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <InfluencerStatistics />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/discovery-influencers"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <DiscoveryInfluencers />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <AdminRoute isLogged={user ? true : false}>
                        <MonthlyReport />
                      </AdminRoute>
                    }
                  />
                  {/*Routes Owner*/}
                  <Route
                    path="/users-accounts"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <AdminUsers />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/influencers-accounts"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <InfluencersUsers />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/creator-signups"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <RequestsInfluencersAccounts />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/brands"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <Shops />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/brand-signups"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <RegisterShops />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/shop"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <ShopDetail />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/pay-creators"
                    element={
                      <ManagerRoute isLogged={user ? true : false}>
                        <PayCreators />
                      </ManagerRoute>
                    }
                  />
                  <Route
                    path="/contents"
                    element={
                      <StaffRoute
                        isLogged={user ? true : false}
                        blockedManager={true}
                      >
                        <Contents />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/content-application"
                    element={
                      <OwnerRoute
                        isLogged={user ? true : false}
                        blockedManager={true}
                      >
                        <ContentApplication />
                      </OwnerRoute>
                    }
                  />
                  <Route
                    path="/deliveries-owner"
                    element={
                      <StaffRoute
                        isLogged={user ? true : false}
                        blockedManager={true}
                      >
                        <DeliveriesOwner />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/content"
                    element={
                      <StaffRoute
                        isLogged={user ? true : false}
                        blockedManager={true}
                      >
                        <Publication from={OWNER_ROLE} />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/statistics"
                    element={
                      <StaffRoute isLogged={user ? true : false}>
                        <Statistics />
                      </StaffRoute>
                    }
                  />
                  <Route
                    path="/feedback"
                    element={
                      <OwnerRoute isLogged={user ? true : false}>
                        <Feedbacks />
                      </OwnerRoute>
                    }
                  />
                  <Route
                    path="/payments-failed"
                    element={
                      <StaffRoute
                        isLogged={user ? true : false}
                        blockedManager={true}
                      >
                        <PaymentsFailed />
                      </StaffRoute>
                    }
                  />
                </Routes>
              </Navigation>
              {isOpenVersionBlock && (
                <ModalBlockVersionApp modalOpen={isOpenVersionBlock} />
              )}
              {/* {subscriptionPlan === PLAN_COLLABORATION && (
                <ModalBlockLastInvite />
              )} */}
              {showModalSelectPackage && (
                <ModalSelectPackage
                  modalOpen={showModalSelectPackage}
                  onCloseModal={() =>
                    dispatch(setShowModalSelectPackage(false))
                  }
                />
              )}
              {showModalOnboardingRequiredInformation && (
                <ModalOnboardingRequiredInformation
                  modalOpen={showModalOnboardingRequiredInformation}
                  onCloseModal={() =>
                    dispatch(setShowModalOnboardingRequiredInformation(false))
                  }
                />
              )}
              <OpenChatsButton />
            </RedirectProvider>
          </FunctionalitiesModalsProvider>
        </OnboardingContext>
      </LocaleProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
