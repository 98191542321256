import subDays from "date-fns/subDays";
import {
  ref as refDB,
  get,
  push,
  query,
  orderByChild,
  update,
  equalTo,
  set,
  limitToLast,
  startAt,
  endAt,
  onValue,
} from "firebase/database";
import {
  collection,
  query as queryFS,
  getDocs,
  orderBy,
  limit as limitFS,
  startAfter as startAfterFS,
  where,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { db, firestore } from "../firebase";
import types from "../types";
import { ConfirmAlert, SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  COUNTRIES_WITH_ONLY_ONE_LOCATION,
  INITIAL_STATE_CATEGORIES,
  INITIAL_STATE_FOLLOWERS,
  INSTAGRAM,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_SOCIAL,
  POST_STATUS_REVIEWED,
  POST_STATUS_SENT,
  PRE_REGISTERED_INFLUENCER_FAVORITE_PROCESS,
  PRE_REGISTERED_INFLUENCER_INVITE_PROCESS,
  REQUEST_STATUS_ACCEPTED,
  TIKTOK,
} from "../utils/constants";
import {
  capitalizeSections,
  deleteDuplicateElementsByArray,
  filterFollowers,
  getCityInfluencer,
  getObjectError,
  getUuidFirebaseAuth,
  normalizeString,
} from "../utils/formats";
import {
  getRequestsByShopPagination,
  startDeleteRequest,
  startGetRequestByShopIDuserID,
} from "./requests";
import {
  inviteEventInfluencer,
  inviteInfluencer,
  startGetInviteInactiveByID,
  verifyInviteCodeExists,
} from "./invites";
import {
  setLastDateInviteShop,
  startSaveLastInviteConfiguration,
  validatePlanShop,
} from "./shops";
import { generateRandomCode } from "../utils/generateRandomsValues";
import {
  getCategoryShop,
  getCountry,
  getInfluencerRedux,
  getPreRegisteredInfluencerProcess,
  getShopID,
  getShopIsActive,
} from "./getters";
import { renewToken } from "./auth";
import axios from "../lib/axios";
import { createAmbassador } from "./ambassadors";
import { DUMMY_AMBASSADOR, DUMMY_INVITE } from "../onboarding/stepsSelectors";
import _ from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import startOfDay from "date-fns/startOfDay";
import {
  getInfluencerFormattedFromPhyllo,
  getInfluencersSuggestedStorage,
  removeInfluencersSuggestedStorage,
  setInfluencersSuggestedStorage,
} from "../services/influencers";
import i18next from "i18next";
import {
  BUTTON_INVITE,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  INVITE_MIN_VALUE_OF_MENU,
  INVITE_STORE_LOCATION,
  SHOP_DEACTIVATE_ALERT_DESCRIPTION,
  WAIT,
  WARNING,
} from "../locales/keysTranslations";
import { getOrders } from "./orders";
import { getApplicantsByPagination } from "./campaigns";
import { verifyIsErrorPermissionDenied } from "../utils/errors";
import { validationProfileInfluencer } from "../utils/influencers";
import {
  influencersCategories,
  mapCategoriesToTopicPhyllo,
} from "../utils/categories";
import { getAudienceLocationsFormatted } from "../services/phyllo";

const FUNCTIONS_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startDiscardInfluencer =
  ({ userID }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `influencersDiscarded/${shopID}/${userID}`);

      await set(dbRef, {
        creationTime: new Date().getTime(),
      });

      dispatch(
        discardInfluencer({
          userID,
          data: {
            creationTime: new Date().getTime(),
          },
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const discardInfluencer = (data) => ({
  type: types.DISCARD_INFLUENCER_DEFINITIVELY,
  payload: data,
});
export const startGetDiscardedInfluencersByUserIDs =
  ({ userIDs }) =>
  async (dispatch, getState) => {
    try {
      userIDs = deleteDuplicateElementsByArray(userIDs);

      const shopID = dispatch(getShopID());
      const influencersDiscardedRedux =
        getState()?.influencers?.influencersDiscarded;
      const currentInfluencersDiscarded = Object.keys(
        influencersDiscardedRedux
      );

      const influencersDiscarded = {};
      const queriesInfluencersDiscarded = [];

      let userIDsNotDiscarded = [];

      userIDs.forEach((userID) => {
        if (!userID) return;
        if (currentInfluencersDiscarded.includes(userID)) return;

        const dbRef = refDB(db, `influencersDiscarded/${shopID}/${userID}`);
        queriesInfluencersDiscarded.push(get(dbRef));
      });

      const influencersDiscardedSnapshot = await Promise.allSettled(
        queriesInfluencersDiscarded
      );

      influencersDiscardedSnapshot.forEach((snap) => {
        if (snap.status === "rejected") return;

        snap = snap.value;

        if (!snap.exists()) {
          userIDsNotDiscarded.push(snap.key);
          return;
        }

        influencersDiscarded[snap.key] = snap.val();
      });

      dispatch(getDiscardedInfluencers(influencersDiscarded));

      return userIDsNotDiscarded;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const getDiscardedInfluencers = (data) => ({
  type: types.GET_INFLUENCERS_DISCARDED_DEFINITIVELY,
  payload: data,
});

export const startGetInfluencersTopRated =
  ({
    limit,
    onChangeLastKey,
    lastKey,
    onCallbackFinish = () => {},
    withRequests = false,
  }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().shop.signUpCountry;
      const shopID = dispatch(getShopID());

      const q = lastKey
        ? queryFS(
            collection(firestore, "influencers"),
            where("score", ">=", 4),
            where("score", "<=", 5),
            where("isDeleted", "==", false),
            where("isHidden", "==", false),
            where("signUpCountry", "==", signUpCountry),
            orderBy("score", "desc"),
            startAfterFS(lastKey),
            limitFS(limit)
          )
        : queryFS(
            collection(firestore, "influencers"),
            where("score", ">=", 4),
            where("score", "<=", 5),
            where("isDeleted", "==", false),
            where("isHidden", "==", false),
            where("signUpCountry", "==", signUpCountry),
            orderBy("score", "desc"),
            limitFS(limit)
          );

      const docSnap = await getDocs(q);
      const data = {};

      if (docSnap.size === 0) {
        onCallbackFinish();
        return true;
      }

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      const usersIDs = Object.keys(data);

      if (withRequests) {
        const requestsSnapshot = [];

        usersIDs.forEach((userID) => {
          requestsSnapshot.push(
            get(
              query(
                refDB(db, "requests"),
                orderByChild("shopIDuserID"),
                equalTo(`${shopID}-${userID}`)
              )
            )
          );
        });

        const response = await Promise.all(requestsSnapshot);

        const requests = {};

        response.forEach((snap) => {
          if (snap.exists()) {
            const snapshot = snap.val();
            const requestKey = Object.keys(snapshot)[0];
            const request = snapshot[requestKey];
            requests[requestKey] = request;
          }
        });
        dispatch(getRequestsByShopPagination(requests));
      }

      onChangeLastKey(docSnap.docs[docSnap.docs.length - 1]);

      dispatch(getNextInfluencers(data));
      onCallbackFinish();

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetInfluencersFavorites =
  ({
    limit,
    onChangeLastKey,
    lastKey,
    onCallbackFinish = () => {},
    onChangeLoadLastItem = () => {},
    withRequests = false,
  }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());

      if (!shopID) return false;

      const q = lastKey
        ? queryFS(
            collection(firestore, "influencersFavorites"),
            where("shopID", "==", shopID),
            startAfterFS(lastKey),
            limitFS(limit)
          )
        : queryFS(
            collection(firestore, "influencersFavorites"),
            where("shopID", "==", shopID),
            limitFS(limit)
          );

      const docSnap = await getDocs(q);

      const data = {};

      if (docSnap.size === 0) {
        dispatch(setInfluencersFavorites(data));
        onCallbackFinish();
        onChangeLoadLastItem();
        return true;
      }

      const usersIDs = [];

      docSnap.forEach((doc) => {
        if (!doc.exists()) return;
        const favoriteData = doc.data();
        data[doc.id] = favoriteData;
        usersIDs.push(favoriteData.userID);
      });

      if (withRequests) {
        const requestsSnapshot = [];

        usersIDs.forEach((userID) => {
          requestsSnapshot.push(
            get(
              query(
                refDB(db, "requests"),
                orderByChild("shopIDuserID"),
                equalTo(`${shopID}-${userID}`)
              )
            )
          );
        });

        const response = await Promise.all(requestsSnapshot);

        const requests = {};

        response.forEach((snap) => {
          if (snap.exists()) {
            const snapshot = snap.val();
            const requestKey = Object.keys(snapshot)[0];
            const request = snapshot[requestKey];
            requests[requestKey] = request;
          }
        });
        dispatch(getRequestsByShopPagination(requests));
      }

      onChangeLastKey(docSnap.docs[docSnap.docs.length - 1]);

      const influencers = await dispatch(
        startGetInfluencersByUserIDs({
          userIDs: usersIDs,
          getIsFavorites: false,
          returnInfluencersRedux: true,
        })
      );
      dispatch(setInfluencersFavorites(data));

      onCallbackFinish();

      return influencers;
    } catch (error) {
      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      onChangeLoadLastItem();
      return false;
    }
  };

export const startGetInfluencersFavoritesByUserIDs =
  ({ userIDs }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());

      if (!shopID) return false;

      const favorites = {};
      let favoritesRedux = {};

      if (getState) {
        favoritesRedux = getState()?.influencers.favorites;
      }

      const currentFavorites = Object.keys(favoritesRedux);

      userIDs = userIDs.filter((item, index) => {
        return userIDs.indexOf(item) === index;
      });

      const queriesFavorites = [];

      userIDs.forEach((userID) => {
        if (!userID) return;
        if (currentFavorites.includes(userID)) return;

        const docRef = doc(
          firestore,
          "influencersFavorites",
          `${shopID}-${userID}`
        );

        queriesFavorites.push(getDoc(docRef));
      });

      const favoritesSnapshot = await Promise.allSettled(queriesFavorites);

      favoritesSnapshot.forEach((doc) => {
        if (doc.status === "rejected") return;

        doc = doc.value;

        if (!doc.exists()) return;

        favorites[doc.id] = doc.data();
      });

      dispatch(setInfluencersFavorites(favorites));

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const setInfluencersFavorites = (data) => ({
  type: types.SET_INFLUENCERS_FAVORITES,
  payload: data,
});

export const startSaveInfluencerFavorite = (userID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const influencer = dispatch(getInfluencerRedux(userID));
    const isExternal = influencer?.isExternal;

    const path = ["influencersFavorites", `${shopID}-${userID}`];
    const data = {
      userID,
      shopID,
      creationTime: new Date().getTime(),
    };

    if (isExternal) {
      const response = await dispatch(
        startGeneratePreRegisteredInfluencerExecution({
          userID,
          mainAccountName: influencer.mainAccountName,
          mainPlatform: influencer.mainPlatform,
          imageURL: influencer.imageURL,
          actions: [
            {
              path,
              data,
              db: "firestore",
            },
          ],
          withActions: true,
          type: PRE_REGISTERED_INFLUENCER_FAVORITE_PROCESS,
        })
      );

      if (!response) return false;
    } else {
      const docRef = doc(firestore, ...path);
      await setDoc(docRef, {
        userID,
        shopID,
        creationTime: Timestamp.now(),
      });
    }

    dispatch(
      saveInfluencerFavorite({
        userID,
        creationTime: new Date().getTime(),
        shopID,
      })
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const saveInfluencerFavorite = (data) => ({
  type: types.SAVE_INFLUENCER_FAVORITE,
  payload: data,
});
export const startDeleteInfluencerFavorite = (userID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const docRef = doc(
      firestore,
      "influencersFavorites",
      `${shopID}-${userID}`
    );

    await deleteDoc(docRef);

    dispatch(
      deleteInfluencerFavorite({
        userID,
        shopID,
      })
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const deleteInfluencerFavorite = (data) => ({
  type: types.DELETE_INFLUENCER_FAVORITE,
  payload: data,
});

export const startGetInfluencersByUserIDs =
  ({
    userIDs,
    customKeys = {},
    getIsFavorites = true,
    getIsDiscarded = true,
    returnInfluencersRedux = false,
  }) =>
  async (dispatch, getState) => {
    try {
      let influencersRedux = {};

      if (getState) {
        influencersRedux = getState()?.influencers.influencers;
      }

      const currentInfluencers = Object.keys(influencersRedux);
      userIDs = userIDs.filter((item, index) => {
        return userIDs.indexOf(item) === index;
      });

      if (getIsDiscarded) {
        userIDs = await dispatch(
          startGetDiscardedInfluencersByUserIDs({ userIDs })
        );
      }

      let influencers = {};

      const queriesInfluencers = [];
      userIDs.forEach((userID) => {
        if (!userID) return;
        if (
          currentInfluencers.includes(userID) &&
          _.isEmpty(customKeys) &&
          !returnInfluencersRedux
        )
          return;
        if (currentInfluencers.includes(userID)) {
          influencers[userID] = {
            ...influencersRedux[userID],
            ...customKeys,
          };
        }
        queriesInfluencers.push(getDoc(doc(firestore, `influencers`, userID)));
      });
      const influencersSnapshot = await Promise.all(queriesInfluencers);

      influencersSnapshot.forEach((doc) => {
        influencers = {
          ...influencers,
          [doc.id]: { ...doc.data(), ...customKeys },
        };
      });
      dispatch(getNextInfluencers(influencers));

      if (getIsFavorites) {
        dispatch(startGetInfluencersFavoritesByUserIDs({ userIDs }));
      }

      return influencers;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetInfluencers =
  ({
    limit,
    onChangeLastKey,
    lastKey,
    getIsFavorites = true,
    getIsDiscarded = true,
    isPreRegistered,
    wasPreRegistered,
    onCallbackLoadingFinish = () => {},
  }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState()?.auth?.user?.country;

      let q = lastKey
        ? queryFS(
            collection(firestore, "influencers"),
            orderBy("mainAccountName", "desc"),
            where("isDeleted", "==", false),
            where("signUpCountry", "==", signUpCountry),
            startAfterFS(lastKey),
            limitFS(limit)
          )
        : queryFS(
            collection(firestore, "influencers"),
            orderBy("mainAccountName", "desc"),
            where("isDeleted", "==", false),
            where("signUpCountry", "==", signUpCountry),
            limitFS(limit)
          );

      if (isPreRegistered) {
        q = queryFS(q, where("isPreRegistered", "==", true));
      }

      if (wasPreRegistered) {
        q = queryFS(q, where("wasPreRegistered", "==", true));
      }

      const docSnap = await getDocs(q);
      let data = {};

      if (docSnap.size === 0) {
        onCallbackLoadingFinish();
        dispatch(getNextInfluencers(data));
        return true;
      }

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      let userIDs = Object.keys(data);

      if (getIsDiscarded) {
        userIDs = await dispatch(
          startGetDiscardedInfluencersByUserIDs({ userIDs })
        );
      }

      const influencers = {};
      userIDs.forEach((userID) => {
        influencers[userID] = data[userID];
      });

      onChangeLastKey(
        docSnap.docs[docSnap.docs.length - 1].data().mainAccountName
      );

      dispatch(getNextInfluencers(influencers));

      if (getIsFavorites) {
        dispatch(
          startGetInfluencersFavoritesByUserIDs({
            userIDs,
          })
        );
      }

      onCallbackLoadingFinish();

      return true;
    } catch (error) {
      console.log(error);
      onCallbackLoadingFinish();
      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetUserIDInfluencerByPrefix =
  (prefix = "") =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = dispatch(getCountry("shop"));

      const q = queryFS(
        collection(firestore, "influencers"),
        where("signUpCountry", "==", signUpCountry),
        where("userIDPrefix", "==", prefix.toUpperCase())
      );

      const docSnap = await getDocs(q);
      const data = {};

      if (docSnap.size === 0) {
        dispatch(getNextInfluencers(data));
        return false;
      }

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      const userID = Object.keys(data)[0];

      dispatch(getNextInfluencers(data));
      return userID;
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetUserIDShopperByPrefix =
  (prefix = "") =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = dispatch(getCountry("shop"));

      const q = queryFS(
        collection(firestore, "shoppers"),
        where("signUpCountry", "==", signUpCountry),
        where("userIDPrefix", "==", prefix.toUpperCase())
      );

      const docSnap = await getDocs(q);
      const data = {};

      if (docSnap.size === 0) {
        return false;
      }

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      const userID = Object.keys(data)[0];

      return userID;
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetInfluencersWithFilters =
  ({
    limit,
    onChangeLastKey = () => {},
    onChangeLoadLastItem = () => {},
    lastKey,
    categories = INITIAL_STATE_CATEGORIES,
    followers = INITIAL_STATE_FOLLOWERS,
    gender = "",
    city = "",
    audienceLocations = [],
    onCallbackFinish = () => {},
    getIsFavorites = true,
    getIsDiscarded = true,
    useFollowers = false,
    useScore = false,
  }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().shop.signUpCountry;
      let q;
      q = queryFS(
        collection(firestore, "influencers"),
        where("signUpCountry", "==", signUpCountry),
        where("isDeleted", "==", false),
        where("isHidden", "==", false)
      );
      if (useScore) {
        q = queryFS(q, orderBy("score", "desc"));
      } else if (useFollowers) {
        q = queryFS(
          q,
          orderBy("followers", "desc"),
          where("followers", ">=", followers[0]),
          where("followers", "<=", followers[1])
        );
      } else {
        q = queryFS(q, orderBy("creationTime", "desc"));
      }
      if (categories.length > 0) {
        const topicsPhyllo = [];
        const ownCategories = [];

        categories.forEach((category) => {
          if (influencersCategories.includes(category)) {
            ownCategories.push(category);
          } else {
            topicsPhyllo.push(category);
          }
        });

        if (topicsPhyllo.length > 0) {
          onCallbackFinish();
          onChangeLoadLastItem();
          return false;
        }

        q = queryFS(
          q,
          where(
            "categories",
            "array-contains-any",
            deleteDuplicateElementsByArray(ownCategories)
          )
        );
      }
      if (city?.length > 0) {
        q = queryFS(q, where("signUpCity", "==", city?.trim()));
      }
      if (audienceLocations.length > 0) {
        const audiencesLocationsFormatted =
          getAudienceLocationsFormatted(audienceLocations);

        q = queryFS(
          q,
          where(
            "mainAudienceLocation",
            "array-contains-any",
            audiencesLocationsFormatted
          )
        );
      }
      if (gender?.length > 0) {
        q = queryFS(q, where("gender", "==", gender));
      }
      if (lastKey) {
        q = queryFS(q, startAfterFS(lastKey));
      }
      q = queryFS(q, limitFS(limit));

      const docSnap = await getDocs(q);
      const data = {};

      if (docSnap.size === 0) {
        onCallbackFinish();
        onChangeLoadLastItem();
        return false;
      }

      docSnap.forEach((doc) => {
        const info = doc.data();
        if (useScore && !filterFollowers(info.followers, followers)) return;

        data[doc.id] = info;
      });

      let userIDs = Object.keys(data);
      if (getIsDiscarded) {
        userIDs = await dispatch(
          startGetDiscardedInfluencersByUserIDs({ userIDs })
        );
      }

      const influencers = {};
      userIDs.forEach((userID) => {
        influencers[userID] = data[userID];
      });

      onChangeLastKey(docSnap.docs[docSnap.docs.length - 1]);

      dispatch(getNextInfluencers(influencers));

      if (getIsFavorites) {
        dispatch(
          startGetInfluencersFavoritesByUserIDs({
            userIDs,
          })
        );
      }
      onCallbackFinish();
      return influencers;
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      onChangeLoadLastItem();

      return false;
    }
  };

export const startGetInfluencersWithFiltersAndRequests =
  ({
    limit,
    onChangeLastKey,
    lastKey,
    categories = INITIAL_STATE_CATEGORIES,
    followers = INITIAL_STATE_FOLLOWERS,
    city = "",
    gender = "",
    onCallbackFinish = () => {},
    onChangeLoadLastItem = () => {},
    useFollowers = false,
    useScore = false,
    getIsFavorites = true,
    getIsDiscarded = true,
  }) =>
  async (dispatch, getState) => {
    try {
      let q;
      const signUpCountry = getState().shop.signUpCountry;
      const shopID = dispatch(getShopID());
      q = queryFS(
        collection(firestore, "influencers"),
        where("signUpCountry", "==", signUpCountry),
        where("isDeleted", "==", false),
        where("isHidden", "==", false)
      );
      if (useScore) {
        q = queryFS(q, orderBy("score", "desc"));
      } else if (useFollowers) {
        q = queryFS(
          q,
          orderBy("followers", "desc"),
          where("followers", ">=", followers[0]),
          where("followers", "<=", followers[1])
        );
      } else {
        q = queryFS(q, orderBy("creationTime", "desc"));
      }
      if (categories.length > 0) {
        q = query(q, where("categories", "array-contains-any", categories));
      }
      if (city?.length > 0) {
        q = query(q, where("signUpCity", "==", city));
      }
      if (gender?.length > 0) {
        q = query(q, where("gender", "==", gender));
      }
      if (lastKey) {
        q = query(q, startAfterFS(lastKey));
      }
      q = query(q, limitFS(limit));

      const docSnap = await getDocs(q);
      const data = {};

      if (docSnap.size === 0) {
        onCallbackFinish();
        onChangeLoadLastItem();
        return true;
      }

      const requestsSnapshot = [];

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      let userIDs = Object.keys(data);

      if (getIsDiscarded) {
        userIDs = await dispatch(
          startGetDiscardedInfluencersByUserIDs({ userIDs })
        );
      }

      userIDs.forEach((userID) => {
        requestsSnapshot.push(
          get(
            query(
              refDB(db, "requests"),
              orderByChild("shopIDuserID"),
              equalTo(`${shopID}-${userID}`)
            )
          )
        );
      });

      const response = await Promise.all(requestsSnapshot);

      const requests = {};
      const influencers = {};

      response.forEach((snap) => {
        if (!snap.exists()) return;

        const snapshot = snap.val();
        const requestKey = Object.keys(snapshot)[0];
        const request = snapshot[requestKey];
        requests[requestKey] = request;
        influencers[request.userID] = data[request.userID];
      });

      onChangeLastKey(docSnap.docs[docSnap.docs.length - 1]);

      dispatch(getNextInfluencers(influencers));
      dispatch(getRequestsByShopPagination(requests));

      if (getIsFavorites) {
        dispatch(
          startGetInfluencersFavoritesByUserIDs({
            userIDs: Object.keys(influencers),
          })
        );
      }

      onCallbackFinish();
      return influencers;
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetNewInfluencers =
  ({
    withRequests = false,
    getIsDiscarded = true,
    onCallbackFinish = () => {},
  }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().shop.signUpCountry;

      const shopID = dispatch(getShopID());
      const thirtyDaysOld = subDays(new Date(), 30);
      const dbRef = collection(firestore, `influencers`);
      const q = queryFS(
        dbRef,
        where("creationTime", ">=", thirtyDaysOld),
        where("signUpCountry", "==", signUpCountry)
      );

      const docSnap = await getDocs(q);
      const data = {};

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      let influencersIDs = Object.keys(data);

      if (getIsDiscarded) {
        influencersIDs = await dispatch(
          startGetDiscardedInfluencersByUserIDs({ userIDs: influencersIDs })
        );
      }

      const influencersWithoutOrders = [];
      for (const influencerID of influencersIDs) {
        if (influencersWithoutOrders.length < 50) {
          const response = await dispatch(
            startGetOrdersByInfluencer(influencerID)
          );
          if (!response) influencersWithoutOrders.push(influencerID);
        }
      }

      const influencersWithoutInvites = [];
      for (const influencerID of influencersIDs) {
        if (influencersWithoutInvites.length < 50) {
          const response = await dispatch(
            startGetInvitesInactiveByInfluencer(influencerID)
          );
          if (!response) influencersWithoutInvites.push(influencerID);
        }
      }

      const allInfluencersPassedFilters = [
        ...influencersWithoutOrders,
        ...influencersWithoutInvites,
      ];

      const keysWithoutDuplication = allInfluencersPassedFilters
        .filter((item, index) => {
          return allInfluencersPassedFilters.indexOf(item) === index;
        })
        .slice(0, 50);

      const influencers = await dispatch(
        startGetInfluencersByUserIDs({
          userIDs: keysWithoutDuplication,
          customKeys: {
            isNew: true,
          },
          getIsDiscarded: false,
        })
      );

      if (withRequests) {
        const requestsSnapshot = [];

        keysWithoutDuplication.forEach((key) => {
          requestsSnapshot.push(
            get(
              query(
                refDB(db, "requests"),
                orderByChild("shopIDuserID"),
                equalTo(`${shopID}-${key}`)
              )
            )
          );
        });

        const response = await Promise.all(requestsSnapshot);

        const requests = {};

        response.forEach((snap) => {
          if (snap.exists()) {
            const snapshot = snap.val();
            const requestKey = Object.keys(snapshot)[0];
            const request = snapshot[requestKey];
            requests[requestKey] = request;
          }
        });
        dispatch(getRequestsByShopPagination(requests));
      }
      onCallbackFinish();
      return influencers;
    } catch (error) {
      console.log(error);

      const errorFormatted = getObjectError(error);

      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return {};
    }
  };
export const getNextInfluencers = (data) => ({
  type: types.GET_NEXT_INFLUENCERS_FINISH,
  payload: data,
});

export const startInviteInfluencer =
  (data, userID, additionalData, disabledAdvancedSettings = false) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      const contentReference =
        getState()?.shop?.profileInfluencer?.contentReference || "";
      const minProductValueShop = getState()?.shop?.minProductValue;

      const LOCALE = getState()?.locales?.locale;
      const SPECIAL_STORES = Object.keys(LOCALE.specialStores);

      if (
        minProductValueShop &&
        data.value < minProductValueShop &&
        !isOpenOnboarding
      ) {
        SimpleAlert({
          title: i18next.t(WARNING),
          text: i18next.t(INVITE_MIN_VALUE_OF_MENU, {
            productValue: minProductValueShop,
            inviteValue: data.value,
            currency: LOCALE.currency,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return { ok: false };
      }

      const shopID = dispatch(getShopID());

      const influencer = dispatch(getInfluencerRedux(userID));
      const isExternal = influencer?.isExternal;

      if (!isOpenOnboarding && !isExternal) {
        const validationShop = dispatch(validatePlanShop());
        if (!validationShop)
          return {
            ok: false,
          };

        const stores = (await get(refDB(db, `shops/${shopID}/stores`))).val();

        let hasSpecialStore = false;

        let citiesStores = [];

        Object.keys(stores).forEach((storeID) => {
          if (!SPECIAL_STORES.includes(stores[storeID].storeType)) {
            citiesStores.push(
              ...stores[storeID].cities,
              normalizeString(stores[storeID].city)
            );
          } else {
            hasSpecialStore = true;
          }
        });

        const cityInfluencer = normalizeString(getCityInfluencer(influencer));

        if (!hasSpecialStore && !citiesStores.includes(cityInfluencer)) {
          let responseAlert = await ConfirmAlert({
            title: i18next.t(WAIT),
            text: i18next.t(INVITE_STORE_LOCATION, {
              city: `(${cityInfluencer})` || "",
            }),
            confirmButtonText: i18next.t(BUTTON_INVITE),
          });
          if (!responseAlert.isConfirmed)
            return {
              ok: false,
            };
        }
      }

      let inviteCode;

      if (data.code === "" || !data.code) {
        inviteCode = generateRandomCode(8);
      } else {
        if (!isOpenOnboarding) {
          const isCodeExists = await dispatch(
            verifyInviteCodeExists(data.code)
          );
          if (isCodeExists)
            return {
              ok: false,
            };
        }
        inviteCode = data.code;
      }

      const category = dispatch(getCategoryShop());

      let inviteData = {
        userID,
        shopID,
        category,
        orderFrom: new Date().toISOString(),
        eventID: data.eventID ? data.eventID : null,
        isActive: true,
        inviteCode,
        value: Number(data.value),
        valueToPay: data.valueToPay ? Number(data.valueToPay) : null,
        comment: data.comment,
        typeContent: data.typeContent,
        ugcContentFormat: data.ugcContentFormat,
        language: data?.language || LOCALE.language,
        referenceUrl: data?.referenceUrl || contentReference,
        wantsSocialMediaPost: data?.wantsSocialMediaPost,
        ...additionalData,
      };

      if (!disabledAdvancedSettings) {
        inviteData = {
          ...inviteData,
          formats: Object.values(data.formats).includes(true)
            ? data.formats
            : null,
          terms: data.terms,
          personalizedURL: data.personalizedURL || null,
        };
      }

      let keyInvite;

      if (!isOpenOnboarding) {
        keyInvite = push(refDB(db)).key;
        const pathInvite = data.eventID
          ? `eventInvites/${keyInvite}`
          : `invites/${keyInvite}`;

        if (isExternal) {
          const response = await dispatch(
            startGeneratePreRegisteredInfluencerExecution({
              userID,
              mainAccountName: influencer.mainAccountName,
              mainPlatform: influencer.mainPlatform,
              imageURL: influencer.imageURL,
              actions: [
                {
                  path: pathInvite,
                  data: inviteData,
                  db: "realtime",
                },
              ],
              withActions: true,
              type: PRE_REGISTERED_INFLUENCER_INVITE_PROCESS,
            })
          );
          if (!response) return { ok: false };
        } else {
          const dbRef = refDB(db, pathInvite);
          await set(dbRef, inviteData);

          const request = await dispatch(
            startGetRequestByShopIDuserID({ userID })
          );
          if (request) {
            const requestID = Object.keys(request)[0];
            await dispatch(
              startDeleteRequest({ requestID, status: REQUEST_STATUS_ACCEPTED })
            );
          }
        }

        dispatch(setLastDateInviteShop(new Date().getTime()));
        dispatch(
          startSaveLastInviteConfiguration({
            comment: data?.comment,
            typeContent: data?.typeContent,
            ugcContentFormat: data?.ugcContentFormat,
            language: data?.language,
            referenceUrl: data?.referenceUrl,
            formats: data?.formats,
            wantsSocialMediaPost: data?.wantsSocialMediaPost,
          })
        );
      } else {
        keyInvite = DUMMY_INVITE;
      }

      const inviteDataRedux = {
        [keyInvite]: {
          ...inviteData,
        },
      };

      data.eventID
        ? dispatch(inviteEventInfluencer(inviteDataRedux))
        : dispatch(inviteInfluencer(inviteDataRedux));

      removeInfluencersSuggestedStorage(userID);

      return { ok: true, inviteID: keyInvite };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      const isActive = dispatch(getShopIsActive());
      SimpleAlert({
        title: i18next.t(ERROR),
        text: !isActive
          ? i18next.t(SHOP_DEACTIVATE_ALERT_DESCRIPTION)
          : i18next.t(ERROR_DESCRIPTION_GENERIC, {
              message: errorFormatted.message,
              code: errorFormatted.code,
            }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return { ok: false };
    }
  };

export const startGetInfluencer =
  (userID, saveInfluencer) => async (dispatch) => {
    try {
      const dbRef = doc(firestore, "influencers", userID);
      const snapshotInfluencer = await getDoc(dbRef);
      if (!snapshotInfluencer.exists()) {
        return false;
      }
      const influencerData = snapshotInfluencer.data();
      if (saveInfluencer) {
        dispatch(getNextInfluencers({ [userID]: influencerData }));
      }
      dispatch(
        startGetInfluencersFavoritesByUserIDs({
          userIDs: [userID],
        })
      );
      return influencerData;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const startGetInfluencerStatistics =
  (userID) => async (dispatch, getState) => {
    try {
      const dbRef = doc(firestore, "influencersStatistics", userID);
      const snapshotInfluencer = await getDoc(dbRef);
      const influencerData = snapshotInfluencer.data();
      return influencerData;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetInfluencersByMainAccountName =
  ({ mainAccountName, getIsDiscarded = true }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().shop.signUpCountry;

      const q = query(
        collection(firestore, "influencers"),
        where("mainAccountName", ">=", mainAccountName.toLowerCase()),
        where(
          "mainAccountName",
          "<=",
          mainAccountName.toLowerCase() + "\uf8ff"
        ),
        where("signUpCountry", "==", signUpCountry),
        where("isHidden", "==", false),
        where("isDeleted", "==", false)
      );
      const docSnap = await getDocs(q);
      const data = {};

      if (docSnap.empty) return {};

      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      let userIDs = Object.keys(data);

      if (getIsDiscarded) {
        userIDs = await dispatch(
          startGetDiscardedInfluencersByUserIDs({ userIDs })
        );
      }

      const influencers = {};
      userIDs.forEach((userID) => {
        influencers[userID] = data[userID];
      });

      dispatch(getNextInfluencers(influencers));
      dispatch(
        startGetInfluencersFavoritesByUserIDs({
          userIDs: Object.keys(influencers),
        })
      );
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetInfluencersByNameAndMainAccountName =
  (textQuery) => async (dispatch, getState) => {
    try {
      const q = query(
        collection(firestore, "influencers"),
        where("mainAccountName", ">=", textQuery.toLowerCase()),
        where("mainAccountName", "<=", textQuery.toLowerCase() + "\uf8ff")
      );
      const q2 = query(
        collection(firestore, "influencers"),
        where("name", ">=", textQuery.toLowerCase()),
        where("name", "<=", textQuery.toLowerCase() + "\uf8ff")
      );

      const docSnap = await getDocs(q);
      const docSnap2 = await getDocs(q2);

      const data = {};

      if (docSnap.empty && docSnap2.empty) {
        return {};
      }

      if (!docSnap.empty) {
        docSnap.forEach((doc) => {
          data[doc.id] = doc.data();
        });
      }
      if (!docSnap2.empty) {
        docSnap2.forEach((doc) => {
          data[doc.id] = doc.data();
        });
      }

      dispatch(getNextInfluencers(data));
      dispatch(
        startGetInfluencersFavoritesByUserIDs({
          userIDs: Object.keys(data),
        })
      );
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startReviewInfluencer =
  (order = {}, data, fromStatus = POST_STATUS_SENT) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const isOpenOnboarding = getState().onboarding.isOpen;

      const updates = {};
      const reviewKey = push(refDB(db, `reviews/${order.userID}`)).key;

      let newStatus;

      if (fromStatus === POST_STATUS_SENT) {
        newStatus = POST_STATUS_REVIEWED;
      }

      if (fromStatus === POST_STATUS_PENDING_APPROVAL) {
        const inviteID = order.inviteID;

        const invite = await dispatch(startGetInviteInactiveByID(inviteID));
        const formats = invite?.formats || {};
        if (
          formats?.instagramBurstStories ||
          formats?.instagramStory ||
          formats?.tiktokStory ||
          !invite?.wantsSocialMediaPost
        ) {
          newStatus = POST_STATUS_REVIEWED;
        } else {
          newStatus = POST_STATUS_PENDING_SOCIAL;
        }
      }

      updates[`reviews/${order.userID}/${reviewKey}`] = {
        shopID: order.shopID,
        orderID: order.orderID,
        date: new Date().getTime(),
        score: Number(data.score),
        comment: data.comment,
      };
      const orderID = order.orderID;
      delete order.orderID;
      delete order.analyticsData;
      updates[`orders/${orderID}/`] = {
        ...order,
        postStatus: newStatus,
        reviewID: reviewKey,
      };

      const isAmbassador = data.isAmbassador;
      const doAmbassador = data.doAmbassador;
      const now = new Date().getTime();

      let ambassadorObject = {};

      if (!isAmbassador && doAmbassador) {
        ambassadorObject = {
          userID: order.userID,
          shopID,
          recurrentPattern: data.recurrentPattern,
          value: data.consumptionValue,
          inviteCode: data.inviteCode,
          orderFrom: new Date(now).toISOString(),
          creationTime: now,
          withRecurrentInvite: data.withRecurrentInvite,
          withRecurrentPayment: data.withRecurrentPayment,
          recurrentPatternPayment: data.recurrentPatternPayment,
          valueToPay: data.valueToPay,
        };
        updates[`ambassadors/${shopID}-${order.userID}`] = ambassadorObject;

        dispatch(
          createAmbassador({
            key: isOpenOnboarding
              ? DUMMY_AMBASSADOR
              : `${shopID}-${order.userID}`,
            data: ambassadorObject,
          })
        );
      }

      const posts =
        fromStatus === POST_STATUS_PENDING_APPROVAL
          ? order.content
          : order.posts;

      if (!isOpenOnboarding) {
        dispatch(
          getOrders({
            [orderID]: {
              ...order,
              postStatus: newStatus,
              reviewID: reviewKey,
              posts,
            },
          })
        );
        await update(refDB(db), updates);
      }
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startReviewApplicant =
  (applicant, data) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const updates = {};
      const reviewKey = push(refDB(db, `reviews/${applicant.userID}`)).key;
      updates[`reviews/${applicant.userID}/${reviewKey}`] = {
        shopID: applicant.shopID,
        applicantID: applicant.applicantID,
        campaignID: applicant.campaignID,
        date: new Date().toISOString(),
        score: data.score,
        comment: data.comment,
      };
      updates[`applicants/${applicant.applicantID}/reviewID`] = reviewKey;

      const isAmbassador = data.isAmbassador;
      const doAmbassador = data.doAmbassador;
      const now = new Date().getTime();

      let ambassadorObject = {};

      if (!isAmbassador && doAmbassador) {
        ambassadorObject = {
          userID: applicant.userID,
          shopID,
          recurrentPattern: data.recurrentPattern,
          value: data.consumptionValue,
          inviteCode: data.inviteCode,
          orderFrom: new Date(now).toISOString(),
          creationTime: now,
          withRecurrentInvite: data.withRecurrentInvite,
        };
        updates[`ambassadors/${shopID}-${applicant.userID}`] = ambassadorObject;
        dispatch(
          createAmbassador({
            key: `${shopID}-${applicant.userID}`,
            data: ambassadorObject,
          })
        );
      }
      await update(refDB(db), updates);
      dispatch(
        getApplicantsByPagination({
          [applicant.applicantID]: {
            ...applicant,
            reviewID: reviewKey,
          },
        })
      );
      return { ok: true, reviewID: reviewKey };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return { ok: false };
    }
  };

export const startGetReviewsInfluencer = (userID) => async () => {
  try {
    if (!userID) return { ok: false };
    const dbRef = refDB(db, `reviews/${userID}`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      return { ok: true, reviews: snapshot.val() };
    } else {
      return { ok: false };
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startGetReviewsByIDs = (reviewsIDs) => async () => {
  try {
    const reviews = {};
    const reviewsQueries = [];

    reviewsIDs.forEach((review) => {
      reviewsQueries.push(
        get(refDB(db, `reviews/${review.userID}/${review.reviewID}`))
      );
    });

    const reviewsSnapshot = await Promise.all(reviewsQueries);

    reviewsSnapshot.forEach((doc) => {
      if (!doc.exists()) return;
      reviews[doc.key] = doc.val();
    });

    return reviews;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startSaveStatisticsInfluencer =
  ({ userID, data }) =>
  async (dispatch, getState) => {
    try {
      const influencer = getState().influencers.influencers[userID];
      const dbRef = doc(firestore, "influencers", userID);
      await updateDoc(dbRef, {
        ...data,
      });
      dispatch(
        saveStatisticsInfluencer({
          data,
          userID,
          mainPlatform: influencer.mainPlatform,
        })
      );
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const saveStatisticsInfluencer = (data) => ({
  type: types.SAVE_STATISTICS_INFLUENCER,
  payload: data,
});
export const setLoadingInfluencers = (loading = false) => ({
  type: types.LOADING_INFLUENCERS_FINISH,
  payload: loading,
});

export const startGetOrdersByInfluencer = (userID) => async () => {
  try {
    const q = query(
      refDB(db, "orders"),
      orderByChild("userID"),
      equalTo(userID)
    );
    const querySnapshot = await get(q);
    if (querySnapshot.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetInvitesInactiveByInfluencer = (userID) => async () => {
  try {
    const q = query(
      refDB(db, "invitesInactive"),
      orderByChild("userID"),
      equalTo(userID)
    );
    const querySnapshot = await get(q);
    if (querySnapshot.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startSaveInfluencerInformation =
  ({ userID, data }) =>
  async (dispatch) => {
    try {
      const dbRef = doc(firestore, "influencers", userID);
      await updateDoc(dbRef, {
        mainAccountName: data.mainAccountName,
        categories: data.categories,
        isHidden: data.isHidden,
        isBlocked: data.isHidden,
      });

      dispatch(saveInfluencerInfo({ userID, data }));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startChangeIsHiddenInfluencer =
  ({ userID, isHidden }) =>
  async (dispatch) => {
    try {
      const dbRef = doc(firestore, "influencers", userID);
      await updateDoc(dbRef, {
        isHidden: isHidden,
      });

      dispatch(changeIsHiddenInfluencer({ userID, isHidden }));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const changeIsHiddenInfluencer = (data) => ({
  type: types.CHANGE_IS_HIDDEN_INFLUENCER,
  payload: data,
});

export const deleteInfluencer = (data) => ({
  type: types.DELETE_INFLUENCER,
  payload: data,
});

export const saveInfluencerInfo = (data) => ({
  type: types.SAVE_INFLUENCER_INFORMATION,
  payload: data,
});

export const startSaveInfluencerShopDiscountCode =
  ({ userID, discountCode }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shopUserDiscountCodes/${shopID}-${userID}`);
      await set(dbRef, {
        discountCode,
        shopID,
        userID,
      });
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetInfluencerShopDiscountCode =
  ({ userID }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shopUserDiscountCodes/${shopID}-${userID}`);
      const snapshot = await get(dbRef);
      if (!snapshot.exists()) return false;
      return snapshot.val();
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startDiscoveryInfluencer =
  ({ accountName, platform, retryRequest = true }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { accountName, platform, retryRequest },
        url: `${FUNCTIONS_URL}/exploreInfluencer`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      // SimpleAlert({
      //   title: i18next.t(ERROR),
      //   text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
      //     message: errorFormatted.message,
      //     code: errorFormatted.code,
      //   }),
      //   icon: ALERT_ICON_TYPE_ERROR,
      // });
      return errorFormatted;
    }
  };

export const startDiscoveryInfluencers = (formValues) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      data: formValues,
      url: `${FUNCTIONS_URL}/discoveryInfluencers`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    // SimpleAlert({
    //   title: i18next.t(ERROR),
    //   text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
    //     message: errorFormatted.message,
    //     code: errorFormatted.code,
    //   }),
    //   icon: ALERT_ICON_TYPE_ERROR,
    // });
    return errorFormatted;
  }
};

export const startInviteInfluencerExternal = (data) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const dbRef = refDB(db, `invitesInfluencersExternals`);
    const allData = {
      ...data,
      shopID,
      status: "pending",
      accountName: data.accountName.toLowerCase(),
      shopIDaccountName: `${shopID}-${data.accountName.toLowerCase()}`,
    };

    await push(dbRef, allData);

    if (data.email) {
      const token = await dispatch(renewToken());
      axios({
        method: "post",
        data: allData,
        url: `${FUNCTIONS_URL}/sendMailInviteInfluencerExternal`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetInfluencerExternalByName =
  (accountName = "") =>
  async () => {
    try {
      const dbRef = query(
        refDB(db, `invitesInfluencersExternals`),
        orderByChild("accountName"),
        equalTo(accountName.toLowerCase())
      );

      const snapshot = await get(dbRef);
      if (!snapshot.exists()) return false;
      return snapshot.val();
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetLastCollaborationInfluencerAndShop =
  (userID) => async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());

      const dbRef = query(
        refDB(db, `shopsIDS/${shopID}/orders`),
        orderByChild("userID"),
        equalTo(userID),
        limitToLast(1)
      );
      const snapshot = await get(dbRef);
      if (!snapshot.exists()) return false;
      const data = snapshot.val();
      const orderID = Object.keys(data)[0];
      const order = { ...data[orderID], orderID };
      return order;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAmbassador = (userID) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `ambassadors/${shopID}-${userID}`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    return snapshot.val();
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetInfluencersSuggested =
  ({ onCallbackFinish = () => {}, onCallbackFetching = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const profileInfluencer = getState().shop.profileInfluencer;

      const nowStartOfDay = startOfDay(new Date()).getTime();

      const isInvalidAllProfileInfluencer = !validationProfileInfluencer(
        profileInfluencer,
        "profileInfluencer"
      );

      if (isInvalidAllProfileInfluencer) {
        onCallbackFinish(false);
        setInfluencersSuggestedStorage({
          influencers: {},
          lastUpdate: nowStartOfDay,
        });
        return;
      }
      const suggestedInfluencers = getInfluencersSuggestedStorage();

      if (
        Object.keys(suggestedInfluencers.influencers).length > 0 &&
        differenceInDays(nowStartOfDay, suggestedInfluencers.lastUpdate) < 1
      ) {
        dispatch(getNextInfluencers(suggestedInfluencers.influencers));
        dispatch(
          startGetInfluencersFavoritesByUserIDs({
            userIDs: Object.keys(suggestedInfluencers.influencers),
          })
        );
        onCallbackFinish();
        return suggestedInfluencers.influencers;
      }

      onCallbackFetching();
      const token = await dispatch(renewToken());

      if (!token) {
        return {};
      }

      const { data } = await axios({
        method: "post",
        data: {
          shopID,
        },
        url: `${FUNCTIONS_URL}/getInfluencersSuggested`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        const influencers = data.influencers;
        const influencersFormatted = {};
        Object.keys(influencers).forEach((influencerID) => {
          influencersFormatted[influencerID] = {
            ...influencers[influencerID],
            isSuggested: true,
          };
        });
        dispatch(getNextInfluencers(influencersFormatted));
        setInfluencersSuggestedStorage({
          influencers: influencersFormatted,
          lastUpdate: nowStartOfDay,
        });
        dispatch(
          startGetInfluencersFavoritesByUserIDs({
            userIDs: Object.keys(influencersFormatted),
          })
        );

        onCallbackFinish(false);
        return influencersFormatted;
      }

      onCallbackFinish(false);
      setInfluencersSuggestedStorage({
        influencers: {},
        lastUpdate: nowStartOfDay,
      });
      return {};
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      onCallbackFinish();
      console.log(errorFormatted);
      return errorFormatted;
    }
  };

export const startCleanIsSuggestedInfluencers =
  () => async (dispatch, getState) => {
    try {
      const influencers = getState().influencers.influencers;

      const influencersFormatted = {};
      Object.keys(influencers).forEach((influencerID) => {
        const influencer = influencers[influencerID];
        if (influencers[influencerID].isSuggested) {
          delete influencer.isSuggested;
          influencersFormatted[influencerID] = influencer;
          return;
        }
      });
      dispatch(cleanIsSuggestedInfluencers(influencersFormatted));
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(errorFormatted);
      return false;
    }
  };

const cleanIsSuggestedInfluencers = (data) => ({
  type: types.CLEAN_IS_SUGGESTED_INFLUENCERS,
  payload: data,
});

export const startGetSimilarProfiles =
  (referenceProfiles) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: {
          referenceProfiles,
        },
        url: `${FUNCTIONS_URL}/getSimilarProfiles`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        dispatch(
          startGetInfluencersFavoritesByUserIDs({
            userIDs: Object.keys(data?.data?.influencers || {}),
          })
        );
        return data?.data?.influencers || {};
      }
      // SimpleAlert({
      //   title: i18next.t(ERROR),
      //   text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
      //     message: data.message || "error",
      //     code: data.code || "error",
      //   }),
      //   icon: ALERT_ICON_TYPE_ERROR,
      // });
      return false;
    } catch (error) {
      console.log(error);
      // const errorFormatted = getObjectError(error);
      // SimpleAlert({
      //   title: i18next.t(ERROR),
      //   text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
      //     message: errorFormatted.message,
      //     code: errorFormatted.code,
      //   }),
      //   icon: ALERT_ICON_TYPE_ERROR,
      // });
      return false;
    }
  };

export const startGetCreatorsLocation = (textQuery) => async (dispatch) => {
  try {
    const dbRef = refDB(db, "phylloCountries");
    const q = query(
      dbRef,
      orderByChild("formattedLargeName"),
      startAt(textQuery.toLowerCase()),
      endAt(textQuery.toLowerCase() + "\uf8ff")
    );
    const snapshot = await get(q);
    if (snapshot.exists()) {
      const countries = snapshot.val();
      return Object.values(countries);
    }
    return [];
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetInfluencersPhylloByFilters =
  ({
    followers,
    engagement,
    gender,
    city,
    audienceLocations,
    lastKey,
    onChangeLastKey,
    topics = [],
    limit,
    onChangeLoadLastItem = () => {},
    onLoadEmptyPlatform = () => {},
    onChangeErrorCode = () => {},
    platforms = [INSTAGRAM, TIKTOK],
  }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().shop.signUpCountry;
      const locale = getState().locales.locale;
      const language = locale.language;
      const country = normalizeString(locale?.name, false);
      const offset = lastKey ? (typeof lastKey === "number" ? lastKey : 0) : 0;

      const hasAudienceLocationCityOrState = audienceLocations
        ? audienceLocations?.some((location) => location?.type !== "country")
        : false;

      const locations = [];
      let locationToSearch = "";

      if (city && !COUNTRIES_WITH_ONLY_ONE_LOCATION.includes(signUpCountry)) {
        locationToSearch = city;
      } else {
        locationToSearch = country;
      }

      const locationsQuery = await dispatch(
        startGetCreatorsLocation(locationToSearch?.trim())
      );

      if (locationsQuery) {
        let locationsFiltered = locationsQuery;

        if (!city || COUNTRIES_WITH_ONLY_ONE_LOCATION.includes(signUpCountry)) {
          locationsFiltered = locationsQuery.filter(
            (location) => location.type === "country"
          );
        }

        locationsFiltered.forEach((location) => {
          const locationName = normalizeString(location.largeName || "", false);

          if (
            locationName.includes(locationToSearch) &&
            locationName.includes(country)
          ) {
            locations.push(location);
          }
        });
      }

      if (locations.length === 0) {
        onChangeLoadLastItem();
        return {};
      }

      const token = await dispatch(renewToken());
      const promises = [];

      const getObjectRequest = (platform) => ({
        platform,
        minFollowerCount: followers[0],
        maxFollowerCount: followers[1],
        engagementRate: engagement[0],
        creatorGender: gender,
        audienceLocations,
        locations,
        topics: topics
          ? topics?.map((topic) =>
              mapCategoriesToTopicPhyllo(topic.value, language)
            )
          : "",
        audienceCredibilityScore: 0.65,
        specificContactDetails: ["EMAIL"],
        creatorAccountType: ["PERSONAL", "CREATOR"],
        offset,
      });

      if (platforms.includes(INSTAGRAM)) {
        promises.push(
          axios({
            method: "post",
            data: getObjectRequest(INSTAGRAM),
            url: `${FUNCTIONS_URL}/exploreInfluencers`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        );
      }
      if (
        !city &&
        !COUNTRIES_WITH_ONLY_ONE_LOCATION.includes(signUpCountry) &&
        !hasAudienceLocationCityOrState &&
        platforms.includes(TIKTOK)
      ) {
        promises.push(
          axios({
            method: "post",
            data: getObjectRequest(TIKTOK),
            url: `${FUNCTIONS_URL}/exploreInfluencers`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        );
      } else {
        onLoadEmptyPlatform(TIKTOK);
      }

      const responses = await Promise.allSettled(promises);

      const data = {};

      const queriesFailed = [];

      responses.forEach((response, index) => {
        const platform = platforms[index];
        if (response.status !== "fulfilled") {
          const code = response?.reason?.response?.data?.code;
          onChangeErrorCode(code);
          queriesFailed.push("");

          if (queriesFailed.length === responses.length) {
            onLoadEmptyPlatform(platform);
          }
          return;
        }

        const dataResponse = response.value.data;

        if (!dataResponse.ok) {
          onLoadEmptyPlatform(platform);
          return;
        }

        if (dataResponse?.data?.length === 0 || !dataResponse?.data) {
          onLoadEmptyPlatform(platform);
          return;
        }

        dataResponse?.data?.forEach((influencer) => {
          const newRandomUuid = getUuidFirebaseAuth();
          data[newRandomUuid] = getInfluencerFormattedFromPhyllo(
            {
              ...influencer,
              userID: newRandomUuid,
              signUpCountry,
              signUpCity: city,
            },
            _.isEmpty(city)
          );
        });
      });

      onChangeLastKey();

      const dataFilteredByInfluencers = dispatch(
        filterInfluencersExternalsByInfluencers(data)
      );

      dispatch(getNextInfluencersExternal(dataFilteredByInfluencers));

      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      onChangeLoadLastItem();
      return {};
    }
  };

export const startGetInfluencerPhyllo =
  ({ mainAccountName }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().shop.signUpCountry;

      const token = await dispatch(renewToken());
      const promises = [];

      promises.push(
        axios({
          method: "post",
          data: {
            accountName: mainAccountName,
            platform: INSTAGRAM,
          },
          url: `${FUNCTIONS_URL}/exploreInfluencer`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );

      promises.push(
        axios({
          method: "post",
          data: {
            accountName: mainAccountName,
            platform: TIKTOK,
          },
          url: `${FUNCTIONS_URL}/exploreInfluencer`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );

      const responses = await Promise.allSettled(promises);

      const data = {};

      responses.forEach((response) => {
        if (response.status !== "fulfilled") {
          return;
        }

        const dataResponse = response.value.data;

        if (!dataResponse.ok) {
          return;
        }

        const influencer = dataResponse.data;
        if (_.isEmpty(influencer)) return;

        const newRandomUuid = getUuidFirebaseAuth();
        data[newRandomUuid] = getInfluencerFormattedFromPhyllo({
          ...influencer,
          userID: newRandomUuid,
          signUpCountry,
        });
      });

      const dataFilteredByInfluencers = dispatch(
        filterInfluencersExternalsByInfluencers(data)
      );
      dispatch(getNextInfluencersExternal(dataFilteredByInfluencers));

      return dataFilteredByInfluencers;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }
  };

export const getNextInfluencersExternal = (data) => ({
  type: types.GET_NEXT_INFLUENCERS_EXTERNAL_FINISH,
  payload: data,
});

const filterInfluencersExternalsByInfluencers =
  (influencersExternals = {}) =>
  (dispatch, getState) => {
    try {
      const influencers = getState()?.influencers?.influencers || {};

      const filterNewEntries = () => {
        const comparisonMap = new Map(
          Object.values(influencers).map(
            ({ mainAccountName, mainPlatform }) => [
              `${mainAccountName}-${mainPlatform}`,
              true,
            ]
          )
        );

        // Filtrar los objetos que no están en comparisonMap
        return Object.entries(influencersExternals).reduce(
          (newEntries, [key, value]) => {
            const uniqueKey = `${value.mainAccountName}-${value.mainPlatform}`;
            if (!comparisonMap.has(uniqueKey)) {
              newEntries[key] = value; // Añadir solo los nuevos
            }
            return newEntries;
          },
          {}
        );
      };

      const result = filterNewEntries();

      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const verifyInfluencerIsLimitedFunctionalities =
  (userID) => async (dispatch) => {
    try {
      const influencer = await dispatch(startGetInfluencer(userID));

      if (!influencer) return false;

      if (
        influencer?.isHidden ||
        influencer?.isBlocked ||
        influencer?.isDeleted
      )
        return true;

      const isBlackListed = await dispatch(
        verifyInfluencerBlackList(influencer.mainAccountName)
      );

      return isBlackListed;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const verifyInfluencerBlackList = (mainAccountName) => async () => {
  try {
    const dbRef = refDB(db, `influencersBlackList/${mainAccountName}`);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) return true;

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startGetCustomTagsInfluencers =
  () => async (dispatch, getState) => {
    try {
      const dbRef = refDB(db, "customTags");
      const snapshot = await get(dbRef);

      if (!snapshot.exists()) return false;

      const currentCategories = getState()?.influencers?.categories?.map(
        (category) => normalizeString(category.label)
      );

      const data = snapshot.val();
      const formattedData = Object.keys(data)
        .map((key) => ({
          value: key,
          label: capitalizeSections(data[key], " "),
        }))
        .filter(
          (tag) => !currentCategories.includes(normalizeString(tag.label))
        );

      dispatch(getCustomTagsInfluencers(formattedData));
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

const getCustomTagsInfluencers = (data) => ({
  type: types.GET_CUSTOM_TAGS_INFLUENCERS,
  payload: data,
});

export const startGetInfluencersCitiesList = () => async (_, getState) => {
  try {
    const signUpCountry = getState().shop.signUpCountry;

    const dbRef = refDB(db, `influencersCities/${signUpCountry}`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startGeneratePreRegisteredInfluencerExecution =
  ({
    userID,
    mainAccountName,
    mainPlatform,
    imageURL,
    actions,
    withActions = true,
    type,
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        data: {
          userID,
          mainAccountName,
          mainPlatform,
          imageURL,
          actions,
          withActions,
          type,
        },
        url: `${FUNCTIONS_URL}/generatePreRegisteredInfluencerExecution`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.ok;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetPreRegisteredInfluencersProcess =
  (onReferenceAvailable = () => {}) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `preRegisteredInfluencersProcessing/${shopID}`);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val() || {};
        dispatch(startVerifyPreRegisteredInfluencerDelete(data));
        dispatch(getPreRegisteredInfluencersProcess(data));
      });
      onReferenceAvailable(dbRef);
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const startVerifyPreRegisteredInfluencerDelete =
  (newPreRegisteredProcess = {}) =>
  async (dispatch, getState) => {
    try {
      const currentPreRegisteredInfluencersProcess = dispatch(
        getPreRegisteredInfluencerProcess()
      );
      const influencersExternal =
        getState().influencers.influencersExternal || {};
      const influencersExternalKeys = Object.keys(influencersExternal);

      const itemsDeleted = Object.keys(
        currentPreRegisteredInfluencersProcess || {}
      ).filter((key) => !newPreRegisteredProcess[key]);

      if (itemsDeleted.length > 0) {
        itemsDeleted.forEach((key) => {
          const item = currentPreRegisteredInfluencersProcess[key] || {};
          dispatch(startGetInfluencer(item.userID));

          const { mainPlatform, mainAccountName } = item;

          const found = influencersExternalKeys.find(
            (key) =>
              influencersExternal?.[key]?.mainPlatform === mainPlatform &&
              influencersExternal?.[key]?.mainAccountName === mainAccountName
          );
          const foundedData = influencersExternal?.[found] || {};
          const deletedUserID =
            foundedData?.userID === item?.userID
              ? foundedData?.userID
              : item?.userID;
          dispatch(deleteInfluencerExternal(deletedUserID));
        });
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
const getPreRegisteredInfluencersProcess = (data) => ({
  type: types.GET_PRE_REGISTERED_INFLUENCERS_PROCESS,
  payload: data,
});

const deleteInfluencerExternal = (userID) => ({
  type: types.DELETE_INFLUENCER_EXTERNAL,
  payload: userID,
});

export const startGetTopicsTagsPhyllo =
  ({ keyword }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const response = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/getTopicsTagsPhyllo`,
        data: {
          keyword,
          platform: INSTAGRAM,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = response;

      if (!data.ok) {
        return [];
      }

      return data.data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return [];
    }
  };
