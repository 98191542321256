import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatchApp } from "./redux";
import { getNameBusinessTheme } from "../actions/getters";
import Typography from "@mui/material/Typography";

export const useTranslationApp = () => useTranslation();

export const ComponentTranslation = ({ keyTranslation }) => {
  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  return (
    <Typography
      sx={{
        color: "text.primary",
      }}
      dangerouslySetInnerHTML={{
        __html: t(keyTranslation, { NAME_BUSINESS }),
      }}
    />
  );
};
