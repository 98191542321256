import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import DiscoveryInfluencersAdvancedForm from "../components/User/DiscoveryInfluencers/DiscoveryInfluencersAdvancedForm";
import { useDispatchApp } from "../lib/redux";
import {
  startDiscoveryInfluencer,
  startDiscoveryInfluencers,
  startGetInfluencerExternalByName,
  startInviteInfluencerExternal,
} from "../actions/influencers";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import _ from "lodash";
import DiscoveryInfluencersList from "../components/User/DiscoveryInfluencers/DiscoveryInfluencersList";
import ModalValueField from "../components/Common/Modal/ModalValueField";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_SUCCESS,
  INSTAGRAM,
  INVITATION_INFLUENCER_EXTERNAL_PENDING,
  TIKTOK,
} from "../utils/constants";
import useNearScreen from "../hooks/useNearScreen";
import { openLink } from "../utils/urls";
import LinearLoaderWithTime from "../components/Common/Loader/LinearLoaderWithTime";
import { PAGE_EXPLORER } from "../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { STEPS_DISCOVERY_FUNCTIONALITY } from "../onboarding/steps";
import {
  DISCOVERY_INFLUENCERS_DATA,
  DISCOVERY_INFLUENCER_DATA,
} from "../data/dummy";
import { waitDelay } from "../utils/dates";
import {
  BUTTON_INVITE_BUZZLY,
  DISCOVERY_INFLUENCERS_DESCRIPTION,
  DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION,
  DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED,
  DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS,
  DISCOVERY_INFLUENCERS_TITLE,
  FIELD_REQUIRED,
  INVITE_SENT,
  INVITE_VALUE,
  LOADING_DOWNLOAD_PROFILES,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import { getNameBusinessTheme } from "../actions/getters";

const DiscoveryInfluencers = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creatorIndexSelected, setCreatorIndexSelected] = useState(null);
  const [modalInviteExternal, setModalInviteExternal] = useState(false);

  let RETRYING_TIME = 30000;

  const { isOpen, setIsOpen, setSteps, currentStep, setCurrentStep, steps } =
    useTour();
  const step = steps[currentStep];

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      accountName: "",
      locations: [],
      keywords: [],
      minFollowerCount: "",
      maxFollowerCount: "",
      engagementRate: "",
      creatorGender: "",
      creatorAge: "",
      audienceInterests: [],
    },
  });
  const {
    watch: watchPlatform,
    control: controlPlatform,
    setValue: setValuePlatform,
    reset: resetPlatform,
    formState: { errors: errorsPlatform },
  } = useForm({
    defaultValues: {
      platform: "",
    },
  });

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const platform = watchPlatform("platform");

  const formValues = watch();
  const isEmptyFrom = Object.keys(formValues).every((key) => {
    return _.isEmpty(formValues[key]);
  });

  const isFiltering = !isEmptyFrom
    ? Object.keys(formValues).some((key) => {
        if (key !== "accountName" && !_.isEmpty(formValues[key])) {
          return true;
        }
        return false;
      })
    : null;

  const [loadLastItem, setLoadLastItem] = useState(false);

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "300px",
    once: false,
  });

  useEffect(() => {
    if (!isOpen) {
      setData({});
      reset();
      resetPlatform();
      return;
    }

    if (step?.actionData === "name") {
      setValue("accountName", "gabrielatafur");
    }
    if (step?.actionData === "platform") {
      setValuePlatform("platform", INSTAGRAM);
    }
    if (step?.actionData === "platform2") {
      setValuePlatform("platform", TIKTOK);
    }
    if (step?.actionData === "minFollowers") {
      setValue("minFollowerCount", 50000);
    }
    if (step?.actionData === "maxFollowers") {
      setValue("maxFollowerCount", 100000);
    }
    // eslint-disable-next-line
  }, [steps, currentStep, isOpen]);

  useEffect(() => {
    const getNewData = async () => {
      if (_.isEmpty(platform))
        return (errorsPlatform.platform = {
          message: t(FIELD_REQUIRED),
        });
      errorsPlatform.platform = null;
      const response = await dispatch(
        startDiscoveryInfluencers({
          ...formValues,
          platform,
          offset: data.length,
        })
      );
      setError(null);
      if (!response.ok) {
        if (response.statusCode === 404) {
          setData({});
          setError(t(DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS));
          return;
        }
      }

      const results = response.data;

      if (_.isEmpty(results)) {
        setLoadLastItem(true);
        return;
      }

      const profiles = results;

      const indexes = Object.keys(profiles);
      for (const index of indexes) {
        const response = await dispatch(
          startGetInfluencerExternalByName(profiles[index].mainAccountName)
        );
        if (!response) {
          profiles[index].isInvited = false;
        } else {
          profiles[index].isInvited = true;
        }
        // profiles[index].isFound = false;
        // profiles[index].isVisited = false;
      }
      setData((prevData) => [...prevData, ...profiles]);
    };
    if (isNearScreen && !loadLastItem && !isOpen) {
      getNewData();
    }
    //eslint-disable-next-line
  }, [isNearScreen]);

  useEffect(() => {
    if (isRetrying) {
      setTimeout(() => {
        onSubmit(formValues);
      }, RETRYING_TIME);
    }
    //eslint-disable-next-line
  }, [isRetrying]);

  const onCleanFilters = () => {
    const accountName = formValues.accountName;
    reset({
      accountName,
    });
  };

  const onChangeStatusModalInviteExternal = (status, creator) => {
    setCreatorIndexSelected(creator);
    setModalInviteExternal(status);
    if (isOpen) {
      setCurrentStep(currentStep + 1);
    }
  };

  const onVerifyStatistics = async (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].isVerifying = true;
      return newData;
    });
    if (isOpen) {
      await waitDelay(1500);
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].isVerifying = false;
        newData[index].isFound = true;
        return newData;
      });
      setCurrentStep(currentStep + 1);
      return;
    }
    const response = await dispatch(
      startDiscoveryInfluencer({
        accountName: data[index].mainAccountName,
        platform,
      })
    );
    if (!response.ok) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].isVerifying = false;
        newData[index].isFound = false;
        return newData;
      });
    } else {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].isVerifying = false;
        newData[index].isFound = true;
        return newData;
      });
    }
  };

  const onRedirectInfluencer = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].isVisited = true;
      return newData;
    });

    if (isOpen) {
      setCurrentStep(currentStep + 1);
      return;
    }

    openLink(
      `/influencer?from=discovery&accountName=${data[index].mainAccountName}&platform=${platform}`
    );
  };

  const onInviteExternalInfluencer = async (value) => {
    if (isOpen) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[creatorIndexSelected].isInvited = true;
        return newData;
      });
      setCreatorIndexSelected(null);
      onChangeStatusModalInviteExternal(false);
      return;
    }

    const influencerData = data[creatorIndexSelected];
    let email = null;
    if (!_.isEmpty(influencerData?.contactDetails)) {
      const emailFound = influencerData.contactDetails.find(
        (contact) => contact.type === "email"
      );
      email = emailFound?.value;
    }
    const inviteExternal = {
      accountName: influencerData.mainAccountName,
      platform,
      budgetInvitation: value,
      status: INVITATION_INFLUENCER_EXTERNAL_PENDING,
      email,
    };
    const response = await dispatch(
      startInviteInfluencerExternal(inviteExternal)
    );
    if (response) {
      SimpleAlert({
        title: t(INVITE_SENT),
        text: t(DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION, {
          accountName: inviteExternal.accountName,
        }),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData[creatorIndexSelected].isInvited = true;
        return newData;
      });
      setCreatorIndexSelected(null);
      onChangeStatusModalInviteExternal(false);
    }
    return;
  };

  const onSubmit = async (data) => {
    if (isOpen) {
      if (step?.actionData === "search") {
        setData(DISCOVERY_INFLUENCER_DATA);
      }
      if (step?.actionData === "search2") {
        setData(DISCOVERY_INFLUENCERS_DATA);
      }
      setIsFetching(false);
      setCurrentStep(currentStep + 1);
      return;
    }

    if (_.isEmpty(platform))
      return (errorsPlatform.platform = { message: t(FIELD_REQUIRED) });
    errorsPlatform.platform = null;
    setLoadLastItem(false);
    const allData = { ...data, platform };
    const callFiltering = typeof isFiltering === "boolean" ? isFiltering : true;

    setIsFetching(true);
    setError(null);

    const response = callFiltering
      ? await dispatch(startDiscoveryInfluencers(allData))
      : await dispatch(startDiscoveryInfluencer(allData));

    if (!response.ok) {
      setData({});
      if (response.statusCode === 404) {
        setError(t(DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS));
        setIsFetching(false);
        return;
      }
      if (!isRetrying) {
        setIsRetrying(true);
        return;
      }
      setIsRetrying(false);
      setIsFetching(false);
      setError(t(DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED));
    }
    const results = response.data;

    if (_.isEmpty(results)) {
      setData({});
      setIsFetching(false);
      return;
    }

    const profiles = callFiltering ? results : [results];

    const indexes = Object.keys(profiles);
    for (const index of indexes) {
      const response = await dispatch(
        startGetInfluencerExternalByName(profiles[index].mainAccountName)
      );
      if (!response) {
        profiles[index].isInvited = false;
      } else {
        profiles[index].isInvited = true;
      }
    }
    if (!callFiltering) setLoadLastItem(true);
    setData(profiles);
    setIsFetching(false);
    return;
  };

  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TitlePage
        dataTour={PAGE_EXPLORER}
        hasTooltip={true}
        toolTipAction={() => {
          setSteps(STEPS_DISCOVERY_FUNCTIONALITY);
          setIsOpen(true);
        }}
      >
        {t(DISCOVERY_INFLUENCERS_TITLE)}
      </TitlePage>
      <DescriptionPage>
        {t(DISCOVERY_INFLUENCERS_DESCRIPTION, {
          NAME_BUSINESS,
        })}
      </DescriptionPage>
      <DiscoveryInfluencersAdvancedForm
        watch={watch}
        setValue={setValue}
        isFetching={isFetching}
        onCleanFilters={onCleanFilters}
        control={control}
        controlPlatform={controlPlatform}
        errors={{ ...errors, ...errorsPlatform }}
        isFiltering={isFiltering}
        platform={platform}
      />
      {isFetching && (
        <LinearLoaderWithTime
          isRetrying={isRetrying}
          numberRetries={1}
          time={RETRYING_TIME}
          label={t(LOADING_DOWNLOAD_PROFILES)}
          sx={{ mt: 2 }}
        />
      )}
      {error && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" align="center">
            {error}
          </Typography>
        </Box>
      )}
      {!isFetching && !_.isEmpty(data) && (
        <DiscoveryInfluencersList
          data={data}
          onInviteInfluencer={(index) =>
            onChangeStatusModalInviteExternal(true, index)
          }
          onRedirectInfluencer={onRedirectInfluencer}
          onVerifyStatistics={onVerifyStatistics}
          fromRef={fromRef}
          loadLastItem={loadLastItem}
        />
      )}
      {modalInviteExternal && (
        <ModalValueField
          modalOpen={modalInviteExternal}
          onCloseModal={() => onChangeStatusModalInviteExternal(false, null)}
          hasTitle={true}
          title={data[creatorIndexSelected].mainAccountName}
          textButton={BUTTON_INVITE_BUZZLY}
          label={t(INVITE_VALUE)}
          isNumberField={true}
          onCallbackButton={onInviteExternalInfluencer}
        />
      )}
    </Box>
  );
};

export default DiscoveryInfluencers;
