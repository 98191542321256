import React, { useState, useEffect } from "react";
import { useDispatchApp } from "../lib/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_STATUS_PENDING_SOCIAL,
  APPLICANT_STATUS_PENDING_ANALYTICS,
  APPLICANT_STATUS_REVISIONS,
  ALERT_ICON_TYPE_SUCCESS,
} from "../utils/constants";
import Loader from "../components/Common/Loader/Loader";
import ContentApplicantMedia from "../components/User/Campaigns/ContentApplicant/ContentApplicantMedia";
import ContentApplicantActions from "../components/User/Campaigns/ContentApplicant/ContentApplicantActions";
import {
  startAcceptedApplicantContent,
  startGetApplicantByID,
  startRejectedApplicant,
  startRevisionsApplicantContent,
} from "../actions/campaigns";
import {
  startGetInfluencer,
  startReviewApplicant,
} from "../actions/influencers";
import InfoApplicant from "../components/User/Campaigns/ContentApplicant/InfoApplicant";
import ContentApplicantAnalytics from "../components/User/Campaigns/ContentApplicant/ContentApplicantAnalytics";
import ContentApplicantHistory from "../components/User/Campaigns/ContentApplicant/ContentApplicantHistory";
import RenderContentMedia from "../components/Common/Media/RenderContentMedia";
import { detectPlatform } from "../utils/urls";
import ModalReview from "../components/User/Publication/ModalReview";
import { generateRandomCode } from "../utils/generateRandomsValues";
import { verifyInviteCodeExists } from "../actions/invites";
import { useTranslationApp } from "../lib/i18next";
import {
  INFORMATION_INVALID_ACCESS,
  LOADING_INFORMATION,
  READY,
} from "../locales/keysTranslations";

const ContentApplicant = () => {
  const [content, setContent] = useState({});
  const [modalReviewOpen, setModalReviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [influencer, setInfluencer] = useState({});
  const [heightElements, setHeightElements] = useState({
    info: 0,
  });

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery("(min-width:600px)");

  const applicantID = params.get("applicantID");

  const { t } = useTranslationApp();

  useEffect(() => {
    (async () => {
      if (!applicantID) {
        return navigate(-1);
      }
      const contentApplicant = await dispatch(
        startGetApplicantByID(applicantID)
      );
      if (!contentApplicant) {
        SimpleAlert({
          title: t(INFORMATION_INVALID_ACCESS),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return navigate(-1);
      }
      setContent({ applicantID, ...contentApplicant });
      const influencerData = await dispatch(
        startGetInfluencer(contentApplicant.userID)
      );
      setInfluencer({ ...influencerData, userID: contentApplicant.userID });
      setIsLoading(false);
    })();
    //eslint-disable-next-line
  }, [applicantID]);

  const onChangeModalOpenReview = (status) => {
    setModalReviewOpen(status);
  };

  const onAcceptApplicantContent = async () => {
    return await dispatch(startAcceptedApplicantContent(applicantID));
  };

  const onRevisionsApplicantContent = async (comment) => {
    return await dispatch(
      startRevisionsApplicantContent({
        applicantID,
        comment,
        revision: content.revision ?? 0,
        content: content.content,
      })
    );
  };
  const onRejectedApplicant = async () => {
    return await dispatch(startRejectedApplicant(applicantID));
  };

  const onReviewInfluencer = async (data) => {
    if (!data.isAmbassador && data.doAmbassador) {
      let inviteCode;
      if (data.inviteCode === "") {
        inviteCode = generateRandomCode(8);
      } else {
        const isCodeExists = await dispatch(
          verifyInviteCodeExists(data.inviteCode)
        );
        if (isCodeExists) return false;
        inviteCode = data.inviteCode;
      }
      data.inviteCode = inviteCode;
    }

    const response = await dispatch(startReviewApplicant(content, data));

    if (response.ok) {
      setContent({
        ...content,
        reviewID: response.reviewID,
      });
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    } else {
      SimpleAlert({
        title: "No se pudo guardar la información",
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return response;
  };

  if (isLoading)
    return (
      <Loader
        size={70}
        hasMessage={true}
        message={t(LOADING_INFORMATION)}
        variantMessage="h5"
        fullWidth={true}
      />
    );

  return (
    <Box
      sx={{
        mx: {
          md: 4,
          xs: 3,
        },
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "calc(100vh - 88px)",
      }}
    >
      <InfoApplicant
        influencer={influencer}
        onHeightElementsChange={setHeightElements}
        applicant={content}
        isMobile={isMobile}
        onOpenModalReview={() => onChangeModalOpenReview(true)}
      />

      <Box
        sx={{
          height: "100%",
          width: "100%",
          mt: `calc(${heightElements.info - 0}px)`,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          pb: 1,
        }}
      >
        {content.content && (
          <ContentApplicantMedia
            data={content.content}
            socialUrl={content.socialUrl}
          />
        )}
        {content.socialUrl && (
          <Box sx={{ display: "flex" }}>
            <RenderContentMedia
              result={detectPlatform(content.socialUrl)}
              url={content.socialUrl}
              keyMedia={"Link a Publicación"}
            />
          </Box>
        )}
        {(content.status === APPLICANT_STATUS_DELIVERED ||
          content.status === APPLICANT_STATUS_PENDING_ANALYTICS ||
          content.status === APPLICANT_STATUS_PENDING_SOCIAL) && (
          <ContentApplicantAnalytics
            data={content.analytics}
            isMobile={isMobile}
          />
        )}
        {content.status !== APPLICANT_STATUS_DELIVERED &&
          content.status !== APPLICANT_STATUS_PENDING_ANALYTICS &&
          content.status !== APPLICANT_STATUS_PENDING_SOCIAL &&
          content.status !== APPLICANT_STATUS_REVISIONS && (
            <ContentApplicantActions
              data={content}
              onAcceptApplicantContent={onAcceptApplicantContent}
              onRevisionsApplicantContent={onRevisionsApplicantContent}
              onRejectedApplicant={onRejectedApplicant}
            />
          )}
        {content.contentHistory && (
          <ContentApplicantHistory
            isMobile={isMobile}
            data={content.contentHistory}
          />
        )}
      </Box>
      {modalReviewOpen && (
        <ModalReview
          data={influencer}
          modalOpen={modalReviewOpen}
          onCloseModal={() => onChangeModalOpenReview(false)}
          onReviewInfluencer={onReviewInfluencer}
        />
      )}
    </Box>
  );
};

export default ContentApplicant;
