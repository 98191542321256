import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import StoresTable from "../components/User/Stores/StoresTable";
import ModalStore from "../components/User/Stores/ModalStore";
import {
  startChangeIsActiveStore,
  startDeleteStore,
  startGetStores,
  startGetWebsiteURLShop,
} from "../actions/shops";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
} from "../utils/constants";
import Loader from "../components/Common/Loader/Loader";
import { SimpleAlert, ConfirmAlert } from "../utils/alerts";
import Button from "../components/Common/Buttons/Button";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTour } from "@reactour/tour";
import { STEPS_STORES_FUNCTIONALITY } from "../onboarding/steps";
import {
  PAGE_STORE,
  PAGE_STORE_BUTTON_CREATE_STORE,
} from "../onboarding/stepsSelectors";
import {
  BUTTON_ADD_STORE,
  BUTTON_DELETE,
  ERROR,
  READY,
  STORES_DESCRIPTION,
  STORES_STORE_DELETED,
  STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION,
  STORES_STORE_DELETE_CONFIRMATION_TITLE,
  STORES_STORE_NO_DELETED_LAST_STORE,
  STORES_STORE_WOOCOMMERCE_CREATED,
  STORES_TITLE,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const Stores = () => {
  const [storesList, setStoresList] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [storeSelected, setStoreSelected] = useState(null);

  const { setIsOpen, setSteps, currentStep, setCurrentStep, isOpen } =
    useTour();

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const { user } = useSelectorApp((state) => state.auth);
  const { stores, isLoading } = useSelectorApp((state) => state.shop);

  const [params] = useSearchParams();

  const success = params.get("success");
  const IDs = params.get("user_id");

  useEffect(() => {
    dispatch(startGetStores({}));
    dispatch(startGetWebsiteURLShop());
    setStoresList(stores);
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    setStoresList(stores);
    // eslint-disable-next-line
  }, [stores]);

  useEffect(() => {
    if (isLoading) return;
    if (parseInt(success) && IDs) {
      const [, storeID] = IDs.split("-_-");
      const store = stores[storeID];
      if (storeID && store) {
        onChangeStatusStore(storeID, true);
        SimpleAlert({
          title: t(READY),
          text: t(STORES_STORE_WOOCOMMERCE_CREATED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        navigate("/stores", { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [success, IDs, isLoading, stores]);

  let navigate = useNavigate();

  const onCloseModal = () => {
    setStoreSelected(null);
    setModalOpen(false);
  };

  const onSelectStore = (store, storeID) => {
    setStoreSelected({
      ...store,
      id: storeID,
    });
    setModalOpen(true);
  };

  const onChangeStatusStore = async (store, status) => {
    dispatch(startChangeIsActiveStore(store, status));
  };

  const onDeleteStore = async (storeID) => {
    if (Object.keys(storesList).length === 1) {
      SimpleAlert({
        title: t(ERROR),
        text: t(STORES_STORE_NO_DELETED_LAST_STORE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    const result = await ConfirmAlert({
      title: t(STORES_STORE_DELETE_CONFIRMATION_TITLE),
      text: t(STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startDeleteStore(storeID));
      if (response) {
        SimpleAlert({
          title: t(STORES_STORE_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };
  return (
    <ContainerPage>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: 2,
          width: "100%",
        }}
      >
        <Box>
          <TitlePage
            hasTooltip={true}
            toolTipAction={() => {
              setSteps(STEPS_STORES_FUNCTIONALITY);
              setIsOpen(true);
              if (Object.keys(stores).length === 0) {
                navigate("/stores?isNewBrand=true");
              }
            }}
            dataTour={PAGE_STORE}
          >
            {t(STORES_TITLE)}
          </TitlePage>
          <DescriptionPage>{t(STORES_DESCRIPTION)}</DescriptionPage>
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            if (isOpen) {
              setCurrentStep(currentStep + 1);
            }
            setModalOpen(true);
          }}
          dataTour={PAGE_STORE_BUTTON_CREATE_STORE}
        >
          {t(BUTTON_ADD_STORE)}
        </Button>
      </Box>
      <Box
        sx={{
          py: 3,
          width: "100%",
          height: "100%",
        }}
      >
        {isLoading || storesList === null ? (
          <Loader size={70} />
        ) : (
          <StoresTable
            data={storesList}
            loading={isLoading}
            onSelectStore={onSelectStore}
            onDeleteStore={onDeleteStore}
            onChangeStatusStore={onChangeStatusStore}
          />
        )}
      </Box>
      {modalOpen && (
        <ModalStore
          data={storeSelected}
          modalOpen={modalOpen}
          onCloseModal={onCloseModal}
          mode={!!storeSelected ? "edit" : "create"}
        />
      )}
    </ContainerPage>
  );
};

export default Stores;
