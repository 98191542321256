import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslationApp } from "../../../lib/i18next";
import { HELP } from "../../../locales/keysTranslations";
import useMediaQuery from "@mui/material/useMediaQuery";
const Tooltip = ({ onClick }) => {
  const { t } = useTranslationApp();
  const theme = useTheme();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        px: {
          xs: 1,
          sm: 2,
        },
        py: "4px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        cursor: "pointer",
        ":hover": {
          filter: "brightness(0.9)",
        },
        width: "fit-content",
      }}
      onClick={onClick}
    >
      <Typography
        variant={isMobile ? "subtitle2" : "subtitle1"}
        fontWeight="bold"
      >
        {t(HELP)}
      </Typography>
      <HelpIcon
        sx={{
          fontSize: {
            xs: "1.2rem",
            sm: "1.5rem",
          },
        }}
      />
    </Box>
  );
};

export default Tooltip;
