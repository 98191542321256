import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ActionsCreateAdminUsersTable = ({
  uid,
  data,
  onDeleteUser,
  onSelectUser,
}) => {
  return (
    <Box>
      <IconButton onClick={() => onDeleteUser(data.shopID, uid)}>
        <DeleteIcon />
      </IconButton>
      <IconButton onClick={() => onSelectUser(uid, data)}>
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default ActionsCreateAdminUsersTable;
