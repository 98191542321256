import React, { useState } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import Box from "@mui/material/Box";
import InfluencerNameLogo from "../Influencer/InfluencerNameLogo";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  ALERT_ICON_TYPE_SUCCESS,
  CAMPAIGN_STATUS_ACTIVE,
} from "../../../utils/constants";
import Select from "../Selects/Select";
import { useForm } from "react-hook-form";
import {
  BUTTON_INVITE_CAMPAIGN,
  FIELD_REQUIRED,
  READY,
  SELECT_CAMPAIGN_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LoadingButton from "../Buttons/LoadingButton";
import { startInviteInfluencerCampaign } from "../../../actions/campaigns";
import { SimpleAlert } from "../../../utils/alerts";

const ModalInviteCampaign = ({
  modalOpen,
  onCloseModal,
  userID,
  influencer,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      campaign: "",
    },
  });

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const campaigns = useSelectorApp((state) => state?.campaigns?.campaigns);

  const campaignsActive = Object.keys(campaigns).filter(
    (campaignID) => campaigns[campaignID].status === CAMPAIGN_STATUS_ACTIVE
  );

  const onSubmit = async (formValues) => {
    setIsFetching(true);
    const response = await dispatch(
      startInviteInfluencerCampaign({
        campaignID: formValues.campaign,
        userID,
      })
    );
    if (response) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();
    }
    setIsFetching(false);
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InfluencerNameLogo userID={userID} data={influencer} sx={{ mb: 0 }} />
        <Select
          label={t(SELECT_CAMPAIGN_PLACEHOLDER)}
          name="campaign"
          fullWidth
          variant="filled"
          options={campaignsActive.map((campaignID) => ({
            value: campaignID,
            label: campaigns[campaignID].name,
          }))}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LoadingButton loading={isFetching}>
            {t(BUTTON_INVITE_CAMPAIGN)}
          </LoadingButton>
        </Box>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalInviteCampaign;
