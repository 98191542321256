import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  removeImageStorageAndDB,
  startDeleteFileAws,
} from "../../../actions/shops";
import { getUUIDFromURL } from "../../../utils/urls";
import { BUTTON_REMOVE_FILE } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LoadingButton from "./LoadingButton";

const RemoveFile = ({
  hasIcon = true,
  textButton = BUTTON_REMOVE_FILE,
  color = "primary",
  variant = "contained",
  pathStorage = "",
  pathRTDB = "",
  urlFile = "",
  targetName = "",
  automaticRemoveRTDB = false,
  onRemoveFile,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onRemove = async () => {
    setLoading(true);
    const UUID = getUUIDFromURL(urlFile);
    if (urlFile.includes("amazonaws")) {
      await dispatch(
        startDeleteFileAws(UUID, pathStorage, pathRTDB, automaticRemoveRTDB)
      );
    } else {
      await dispatch(
        removeImageStorageAndDB(
          UUID,
          pathStorage,
          pathRTDB,
          automaticRemoveRTDB
        )
      );
    }
    setLoading(false);
    onRemoveFile({
      target: { name: targetName, value: "" },
    });
  };

  return (
    <LoadingButton
      sx={{ borderRadius: 3 }}
      endIcon={hasIcon && <DeleteIcon />}
      loading={loading}
      loadingPosition="end"
      component="label"
      disableElevation
      color={color}
      variant={variant}
      onClick={onRemove}
    >
      {t(textButton)}
    </LoadingButton>
  );
};

RemoveFile.propTypes = {
  hasIcon: PropTypes.bool,
  textButton: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  targetName: PropTypes.string,
  urlFile: PropTypes.string.isRequired,
  pathStorage: PropTypes.string.isRequired,
  pathRTDB: PropTypes.string,
  automaticRemoveRTDB: PropTypes.bool.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};

export default RemoveFile;
