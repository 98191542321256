import React from "react";
import Button from "../../../Common/Buttons/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PAGE_CAMPAIGNS_ANSWER_NEXT_BUTTON } from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
const NextButtonStep = ({
  onNextStep,
  color = "secondary",
  children,
  sx = {},
}) => {
  const { isOpen, steps, currentStep } = useTour();
  const isDisabledOnboarding =
    isOpen && steps[currentStep].actionData === "disabledButton";
  return (
    <Button
      disabled={isDisabledOnboarding}
      sx={{ mt: 2, ...sx }}
      onClick={onNextStep}
      color={color}
      endIcon={<ArrowForwardIcon />}
      dataTour={PAGE_CAMPAIGNS_ANSWER_NEXT_BUTTON}
    >
      {children}
    </Button>
  );
};

export default NextButtonStep;
