import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { numberCompactFormat } from "../../../../utils/numberFormats";
import TooltipModal from "../../Tooltips/TooltipModal";
import { GREY_SLIDER_COLOR } from "../../../../utils/colors";

function valuetext(value) {
  return `${numberCompactFormat(value)}`;
}

const SliderBase = ({
  range,
  errors = {},
  onChange,
  name = "",
  title,
  variantTitle = "h6",
  disabled = false,
  marks,
  step = 1,
  min = 0,
  max = 100,
  isNumberLabel = true,
  hasTooltip = false,
  titleTooltip = "",
  track = "normal",
  contentTooltip = "",
  sx,
  sxTitle,
  innerRef,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        ...sx,
      }}
      ref={innerRef}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: -1.25 }}>
        <Typography
          variant={variantTitle}
          sx={{ fontWeight: variantTitle !== "h6" && 600, ...sxTitle }}
        >
          {title}
        </Typography>
        {hasTooltip && (
          <TooltipModal title={titleTooltip} content={contentTooltip} />
        )}
      </Box>
      <Slider
        getAriaLabel={() => "Range"}
        value={range}
        onChange={onChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        name={name}
        step={step}
        marks={marks}
        min={min}
        max={max}
        valueLabelFormat={isNumberLabel ? valuetext : ""}
        disabled={disabled}
        track={track}
        sx={{
          ".MuiSlider-rail": {
            backgroundColor:
              track === "normal" ? GREY_SLIDER_COLOR : "currentcolor",
            height: 4,
          },
          ".MuiSlider-track": {
            height: 2,
          },
          ".MuiSlider-mark": {
            backgroundColor: "transparent",
          },
        }}
      />
      {errors[name]?.message && (
        <Typography variant="caption" color="error">
          {errors[name]?.message}
        </Typography>
      )}
    </Box>
  );
};

export default SliderBase;
