import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import {
  REGEX_EMAIL,
  REGEX_NUMERIC,
  REGEX_PASSWORD,
  REGEX_URL,
} from "../utils/regexsValidation";
import { formatURL, getObjectError } from "../utils/formats";
import RegisterForm from "../components/User/Register/RegisterForm";
import { startRegisterShop, startVerifyShopSignup } from "../actions/auth";
import { matchIsValidTel } from "mui-tel-input";
import { useTranslationApp } from "../lib/i18next";
import {
  ERROR_OCURRED,
  FIELD_BOTH_PASSWORD_REQUIRED,
  FIELD_EMAIL_REGISTERED,
  FIELD_EMAIL_REQUIRED,
  FIELD_EMAIL_VALID,
  FIELD_MIN_LENGTH,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_INSECURE,
  FIELD_PHONE_VALID,
  FIELD_URL_VALID,
  FIELD_VERIFICATION_CODE_REQUIRED,
  REGISTER_BUSINESS_NAME_REQUIRED,
  REGISTER_CATEGORY_REQUIRED,
  REGISTER_COUNTRY_REQUIRED,
  REGISTER_NAME_REQUIRED,
  REGISTER_PHONE_NUMERIC_REQUIRED,
  REGISTER_PHONE_REQUIRED,
  REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED,
  REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED,
  REGISTER_PROFILE_CREATOR_GENDER_REQUIRED,
  REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED,
  REGISTER_WEB_SITE_REQUIRED,
} from "../locales/keysTranslations";
import useUserIP from "../hooks/useUserIP";
import {
  fetchSignInMethodsForEmail,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { INSTAGRAM, TIKTOK, YOUTUBE } from "../utils/constants";
import { formatAgesRanges } from "../services/shops";
import { trackingLead } from "../services/tracking";

const PHONE_STEP = 11;

const answerOrder = {
  1: "name",
  2: "businessName",
  3: "category",
  4: "webSite",
  5: "profileCreatorPlatforms",
  6: "profileCreatorCategories",
  7: "profileCreatorGender",
  8: "profileCreatorAgeRange",
  9: "email",
  10: "password",
  [PHONE_STEP]: "phone",
  12: "OTP",
};
const stepsVisibleWithoutWhiteList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Register = () => {
  const [step, setStep] = useState(1);
  const [visibleSteps] = useState(stepsVisibleWithoutWhiteList);
  const [countdown, setCountdown] = useState(30);
  const [reCaptcha, setReCaptcha] = useState(null);
  const [isResendAllowed, setIsResendAllowed] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [error, setError] = useState(null);

  const currentAnswer = answerOrder[step];

  const LOCALES_LIST = useSelectorApp((state) => state?.locales?.localesList);

  const { userIP } = useUserIP();

  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  const { control, getValues, watch, setValue, trigger } = useForm({
    defaultValues: {
      signUpCountry: undefined,
      name: "",
      phone: "",
      businessName: "",
      category: "",
      webSite: "",
      profileCreatorPlatforms: {
        [INSTAGRAM]: false,
        [TIKTOK]: false,
        [YOUTUBE]: false,
      },
      profileCreatorCategories: [],
      profileCreatorGender: {
        male: false,
        female: false,
      },
      profileCreatorAgeRange: [],
      email: "",
      password: "",
      repeatPassword: "",
    },
  });

  const signUpCountry = watch("signUpCountry");
  const phone = watch("phone");

  const currentStepPosition = visibleSteps.indexOf(step);
  const previousStep = visibleSteps[currentStepPosition - 1];
  const nextStep = visibleSteps[currentStepPosition + 1];

  const { t } = useTranslationApp();

  const verifyIpSignUp = async () => {
    if (!userIP) return;

    const ip = userIP.ip;
    const response = await dispatch(startVerifyShopSignup(ip));
    if (response && REGEX_EMAIL.test(response)) {
      navigate(`/login?email=${response}`);
    }

    const found = Object.values(LOCALES_LIST).find(
      (locale) => locale.code === userIP.countryCode.toLowerCase()
    );

    if (!found) {
      setValue("signUpCountry", "us");
      return;
    }

    setValue("signUpCountry", userIP.countryCode.toLowerCase());
  };

  useEffect(() => {
    verifyIpSignUp();
    // eslint-disable-next-line
  }, [userIP]);

  useEffect(() => {
    onChangePhone();
    // eslint-disable-next-line
  }, [phone]);

  useEffect(() => {
    let timer;
    if (currentAnswer === "OTP" && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendAllowed(true);
    }
    return () => clearTimeout(timer);
  }, [countdown, currentAnswer]);

  useEffect(() => {
    if (reCaptcha) return;
    const reCaptchaVerifier = new RecaptchaVerifier(
      "id-recaptcha",
      {
        size: "invisible",
        callback: () => {},
      },
      auth
    );

    setReCaptcha(reCaptchaVerifier);

    return () => {
      reCaptcha?.clear();
    };
    // eslint-disable-next-line
  }, []);

  const onChangePhone = async () => {
    const countryCodePhone = phone?.info?.countryCode?.toLowerCase();
    if (!countryCodePhone) return;

    if (countryCodePhone !== signUpCountry) {
      setValue("signUpCountry", countryCodePhone);
      setValue("phone", phone);
    }

    if (step > PHONE_STEP) setStep(PHONE_STEP);
  };

  const onPreviousStep = (value) => {
    if (REGEX_NUMERIC.test(value)) {
      setStep(value);
    } else {
      setStep(previousStep);
    }
  };

  const onNextStep = async (name, value, finish = false) => {
    switch (name) {
      case "signUpCountry":
        if (!value)
          return setError({
            text: t(REGISTER_COUNTRY_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "name":
        if (!value)
          return setError({
            text: t(REGISTER_NAME_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "businessName":
        if (!value)
          return setError({
            text: t(REGISTER_BUSINESS_NAME_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setStep(nextStep);

      case "category":
        if (!value) {
          return setError({
            text: t(REGISTER_CATEGORY_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "webSite":
        if (value.length === 0) {
          return setError({
            text: t(REGISTER_WEB_SITE_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (!REGEX_URL.test(value)) {
          return setError({
            text: t(FIELD_URL_VALID),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "profileCreatorPlatforms":
        if (!value || !Object.values(value).some((v) => v)) {
          return setError({
            text: t(REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "profileCreatorCategories":
        if (!value || value?.length < 3) {
          return setError({
            text: t(REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "profileCreatorGender":
        if (!value || !Object.values(value).some((v) => v)) {
          return setError({
            text: t(REGISTER_PROFILE_CREATOR_GENDER_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "profileCreatorAgeRange":
        if (!value || value?.length < 2) {
          return setError({
            text: t(REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "email":
        if (!value) {
          return setError({
            text: t(FIELD_EMAIL_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (!REGEX_EMAIL.test(value)) {
          return setError({
            text: t(FIELD_EMAIL_VALID),
            step: answerOrder[step],
          });
        }

        const result = await fetchSignInMethodsForEmail(auth, value);
        if (result.length > 0) {
          return setError({
            text: t(FIELD_EMAIL_REGISTERED),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "password":
        if (!value.password || !value.repeatPassword) {
          return setError({
            text: t(FIELD_BOTH_PASSWORD_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (value.password !== value.repeatPassword) {
          return setError({
            text: t(FIELD_PASSWORDS_NO_MATCH),
            step: answerOrder[step],
          });
        }
        if (!REGEX_PASSWORD.test(value.password)) {
          return setError({
            text: t(FIELD_PASSWORD_INSECURE),
            step: answerOrder[step],
          });
        }

        setError(null);
        return setStep(nextStep);

      case "phone":
        if (!value)
          return setError({
            text: t(REGISTER_PHONE_REQUIRED),
            step: answerOrder[step],
          });
        const {
          value: phone,
          info: { countryCallingCode },
        } = value;
        const phoneFormatted = phone
          .replace(`+${countryCallingCode}`, "")
          .replace(/ /g, "");
        if (!matchIsValidTel(phone))
          return setError({
            text: t(FIELD_PHONE_VALID),
            step: answerOrder[step],
          });

        if (!REGEX_NUMERIC.test(phoneFormatted))
          return setError({
            text: t(REGISTER_PHONE_NUMERIC_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        if (finish) return onFinish();
        return setStep(nextStep);

      case "OTP":
        if (!value)
          return setError({
            text: t(FIELD_VERIFICATION_CODE_REQUIRED),
            step: answerOrder[step],
          });

        if (value?.length < 6)
          return setError({
            text: t(FIELD_MIN_LENGTH, {
              value: 6,
            }),
            step: answerOrder[step],
          });

        setError(null);

        if (finish) return onFinish();

        return setStep(nextStep);

      default:
        return setStep(nextStep);
    }
  };

  const resendCode = async () => {
    if (!isResendAllowed) return;

    setIsResendingCode(true);
    await onSendCode();
    setIsResendingCode(false);
    setCountdown(30);
    setIsResendAllowed(false);
  };
  const onRegister = async () => {
    const result = await trigger();
    if (!result) return false;

    const formValues = getValues();

    const { webSite, OTP } = formValues;

    const confirmResult = window.confirmationResult;

    if (!confirmResult) {
      setError(t(ERROR_OCURRED));
      console.error("No confirmation result");
      return;
    }

    try {
      const responseCode = await confirmResult.confirm(OTP);

      delete formValues.repeatPassword;

      const response = await dispatch(
        startRegisterShop({
          ...formValues,
          profileCreatorAgeRange: formatAgesRanges({
            ages: formValues.profileCreatorAgeRange,
            mode: "save",
          }),
          webSite: formatURL(webSite),
          phone: formValues?.phone?.value?.replace(/ /g, ""),
          ip: userIP.ip,
          userID: responseCode.user.uid,
        })
      );

      if (response) {
        trackingLead();
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      setError({ step: answerOrder[step], text: errorFormatted.message });
    }

    return;
  };

  const onSendCode = async () => {
    try {
      const result = await trigger();
      if (!result) return false;

      const formValues = getValues();
      const appVerifier = reCaptcha;
      const phoneNumber = formValues.phone.value.replace(/ /g, "");

      setError(null);
      const response = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );

      window.confirmationResult = response;
      setCountdown(30);
      setIsResendAllowed(false);
      setStep(nextStep);
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (
        errorFormatted.message ===
        "reCAPTCHA has already been rendered in this element"
      ) {
        setStep(nextStep);
        return;
      }
      console.log(errorFormatted);
      setError({ step: answerOrder[step], text: errorFormatted.message });
    }
  };

  const onFinish = async () => {
    if (currentAnswer === "OTP") {
      await onRegister();
    } else {
      await onSendCode();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100%)",
      }}
    >
      <Box id="id-recaptcha" />
      <RegisterForm
        error={error}
        step={step}
        getValues={getValues}
        watch={watch}
        currentAnswer={currentAnswer}
        control={control}
        onPreviousStep={onPreviousStep}
        onNextStep={onNextStep}
        countdown={countdown}
        isResendAllowed={isResendAllowed}
        isResendingCode={isResendingCode}
        resendCode={resendCode}
      />
    </Box>
  );
};

export default Register;
