import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { numberDecimal, numberRounded } from "../../../utils/numberFormats";
import { useTranslationApp } from "../../../lib/i18next";
import {
  INVITATIONS_ACCEPTANCE_RATE,
  INVITATIONS_EXPIRED,
  INVITATIONS_REDEEMED,
  INVITATIONS_REJECTED,
  INVITATIONS_SUMMARY,
  TOTAL_INVITATIONS,
} from "../../../locales/keysTranslations";

const ShopSummaryInvites = ({ data = {} }) => {
  const { t } = useTranslationApp();

  if (_.isEmpty(data)) return null;

  const {
    totalInvites,
    totalInvitesRedeemed,
    totalInvitesRejected,
    totalInvitesExpired,
  } = data;

  const acceptRatePercentage = numberDecimal(
    (totalInvitesRedeemed / totalInvites) * 100
  );

  const acceptRateRatio = numberRounded(
    1 / (totalInvitesRedeemed / totalInvites)
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
        }}
      >
        {t(INVITATIONS_SUMMARY)}
      </Typography>
      <Box component="ul">
        <Box component="li">
          <Typography variant="body1">
            {t(TOTAL_INVITATIONS, {
              count: totalInvites,
            })}
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1">
            {t(INVITATIONS_REDEEMED, {
              count: totalInvitesRedeemed,
            })}
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1">
            {t(INVITATIONS_REJECTED, {
              count: totalInvitesRejected,
            })}
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1">
            {t(INVITATIONS_EXPIRED, {
              count: totalInvitesExpired,
            })}
          </Typography>
        </Box>
        <Box component="li">
          <Typography variant="body1">
            {t(INVITATIONS_ACCEPTANCE_RATE, {
              percentage: acceptRatePercentage,
              ratio: acceptRateRatio,
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShopSummaryInvites;
