import React, { useState } from "react";
import { SUB_MENU_GALLERY } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ModalGalleryArrowButtons from "./ModalGalleryArrowButtons";
import { getIsBlockedActions } from "../../../actions/getters";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useNavigate } from "react-router-dom";
import ButtonSlideMenu from "../Buttons/ButtonSlideMenu";

const ButtonGallery = ({ dataTour = "" }) => {
  const [modalArrowButtonsOpen, setModalArrowButtonsOpen] = useState(false);

  const dispatch = useDispatchApp();
  const isPlanActive = useSelectorApp((state) => state?.auth?.isActivePlan);
  const navigate = useNavigate();

  const onNavigateBlocked = () => navigate("/creators");

  const onChangeStatusModalArrowButtonsOpen = (status) =>
    setModalArrowButtonsOpen(status);

  const { t } = useTranslationApp();
  return (
    <>
      <ButtonSlideMenu
        dataTour={dataTour}
        startIcon={<CollectionsIcon />}
        variant="contained"
        color="secondary"
        hasShadow={false}
        disableElevation
        fullWidth
        onClick={() => {
          const isBlocked = dispatch(
            getIsBlockedActions({
              verifyBlockedLastInvite: true,
              verifyBlockedFreeTrial: true,
            })
          );
          if (isBlocked) return onNavigateBlocked();
          if (!isPlanActive) return onNavigateBlocked();
          onChangeStatusModalArrowButtonsOpen(true);
        }}
      >
        {t(SUB_MENU_GALLERY)}
      </ButtonSlideMenu>
      {modalArrowButtonsOpen && (
        <ModalGalleryArrowButtons
          mode="gallery"
          modalOpen={modalArrowButtonsOpen}
          onCloseModal={() => onChangeStatusModalArrowButtonsOpen(false)}
        />
      )}
    </>
  );
};

export default ButtonGallery;
