import React from "react";
import Drawer from "@mui/material/Drawer";
import SlideMenu from "../SlideMenu/SlideMenu";
import { DRAWER_WIDTH } from "../../../../utils/constants";

const MenuNavigation = () => {
  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
          backgroundImage: "none",
          "&.MuiPaper-root": {
            overflowX: "hidden",
            borderRight: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          },
        },
        display: {
          xs: "none",
          md: "flex",
        },
      }}
      variant="permanent"
      anchor="left"
      data-tour="navigation"
    >
      <SlideMenu isMobile={false} />
    </Drawer>
  );
};

export default MenuNavigation;
