import React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import StatusCampaign from "./StatusCampaign";
import TableCell from "../../Common/Table/TableCell";
import TableContainer from "../../Common/Table/TableContainer";
import {
  PAGE_CAMPAIGNS_TABLE,
  PAGE_CAMPAIGNS_TABLE_NAME,
  PAGE_CAMPAIGNS_TABLE_STATUS,
} from "../../../onboarding/stepsSelectors";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DRAFT,
} from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";
import {
  COLUMN_NAME_NAME,
  COLUMN_NAME_STATUS,
  EMPTY_CAMPAIGNS,
} from "../../../locales/keysTranslations";

const CampaignsTable = ({
  data,
  loading,
  onClickCampaign,
  onChangeStatusCampaign,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const { t } = useTranslationApp();

  const keys = Object.keys(data);

  const keysSorted = keys.sort(
    (a, b) => data[b].creationTime - data[a].creationTime
  );
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={loading}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        emptyText={EMPTY_CAMPAIGNS}
      >
        <TableHead>
          <TableRow data-tour={PAGE_CAMPAIGNS_TABLE}>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_CAMPAIGNS_TABLE_NAME}
            >
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_CAMPAIGNS_TABLE_STATUS}
            >
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => (
            <TableRow
              key={key}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  filter: "brightness(0.9)",
                },
              }}
            >
              <TableCell
                align="center"
                indexRow={index}
                onClick={() => onClickCampaign(key)}
              >
                {data[key].name}
              </TableCell>
              <TableCell
                align="center"
                indexRow={index}
                onClick={() => onClickCampaign(key)}
              >
                <StatusCampaign
                  status={data[key].status}
                  onChangeStatusCampaign={() =>
                    onChangeStatusCampaign({
                      campaignID: key,
                      status:
                        data[key].status === CAMPAIGN_STATUS_ACTIVE
                          ? CAMPAIGN_STATUS_DRAFT
                          : CAMPAIGN_STATUS_ACTIVE,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default CampaignsTable;
