import _ from "lodash";
import {
  INITIAL_STATE_ENGAGEMENT,
  INITIAL_STATE_FOLLOWERS,
  REGULAR_STORE_TYPE,
} from "../utils/constants";

export const validMenuIsRequiredForStore = ({ store, websiteURL }) => {
  if (store.storeType !== REGULAR_STORE_TYPE) {
    return false;
  }

  const ordersMethods = store.ordersMethods || {};

  if (ordersMethods.delivery) {
    return true;
  }

  if (!ordersMethods.delivery && ordersMethods.inStore && websiteURL) {
    return false;
  }

  if (!ordersMethods.delivery && ordersMethods.inStore && !websiteURL) {
    return true;
  }
};

export const formatAgesRanges = ({ ages = [], mode = "save" }) => {
  if (!ages || !_.isArray(ages)) return ages;

  const value = [...ages];
  if (mode === "save" && value.includes("35+")) {
    const index = value.indexOf("35+");
    value.splice(index, 1);
    value.push("35-44");
    value.push("45-64");
  } else if (
    mode === "load" &&
    (value.includes("35-44") || value.includes("45-64"))
  ) {
    if (value.includes("35-44")) {
      const index = value.indexOf("35-44");
      value.splice(index, 1);
    }

    if (value.includes("45-64")) {
      const index = value.indexOf("45-64");
      value.splice(index, 1);
    }

    value.push("35+");
  }

  return value;
};

export const isFilteringInfluencers = ({
  newCategorySelected,
  favoritesCategorySelected,
  topRatedCategorySelected,
  bodyBuild = "",
  clothingStyle = "",
  distinguishingFeatures = "",
  contentStyle = "",
  categoriesSelected = [],
  genderSelected = "",
  citySelected = "",
  rangeEngagement = [],
  rangeFollowers = [],
  searchText = "",
  topicsSelected = [],
  audienceLocations = [],
}) => {
  if (
    newCategorySelected ||
    favoritesCategorySelected ||
    topRatedCategorySelected ||
    categoriesSelected?.length > 0 ||
    audienceLocations?.length > 0 ||
    topicsSelected?.length > 0 ||
    genderSelected ||
    citySelected ||
    searchText ||
    bodyBuild ||
    clothingStyle ||
    distinguishingFeatures ||
    contentStyle ||
    rangeFollowers[0] !== INITIAL_STATE_FOLLOWERS[0] ||
    rangeFollowers[1] !== INITIAL_STATE_FOLLOWERS[1] ||
    rangeEngagement[0] !== INITIAL_STATE_ENGAGEMENT[0] ||
    rangeEngagement[1] !== INITIAL_STATE_ENGAGEMENT[1]
  ) {
    return true;
  }

  return false;
};
