import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const ActionsDeliveriesOwner = ({
  id,
  data,
  onSelectDelivery,
  onDeleteDelivery,
}) => {
  return (
    <Box>
      <IconButton onClick={() => onSelectDelivery(id, data.status)}>
        <RemoveRedEyeIcon />
      </IconButton>
      <IconButton onClick={() => onDeleteDelivery(id)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default ActionsDeliveriesOwner;
