import types from "../types";
import { db } from "../firebase";
import {
  equalTo,
  get,
  limitToFirst,
  orderByChild,
  push,
  query,
  ref,
  set,
  startAfter,
  update,
} from "firebase/database";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";
import { getObjectError } from "../utils/formats";
import i18next from "i18next";
import {
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  EVENT_HAS_FIELDS_REQUIRED,
  EVENT_RECURRENT_REQUIRED,
  START_DATE_AND_END_DATE_VALIDATION,
} from "../locales/keysTranslations";

export const startGetEventsByShop =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch, getState) => {
    try {
      const shopID = getState().auth.user.shopID;
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/events`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/events`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysEvents = [];
      const snapshotSize = snapshot.size;
      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysEvents.push(snapshot.key);
        count++;
      });

      const queriesEvents = [];
      keysEvents.forEach((keyEvent) => {
        queriesEvents.push(get(ref(db, `events/${keyEvent}`)));
      });

      const eventsSnapshots = await Promise.all(queriesEvents);

      let events = {};
      eventsSnapshots.forEach((eventSnapshot) => {
        events = {
          ...events,
          [eventSnapshot.key]: eventSnapshot.val(),
        };
      });
      dispatch(getEventsByShop(events));
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingEventsFinish({}));
    }
  };
export const startGetEventsActiveNameByShop =
  () => async (dispatch, getState) => {
    try {
      const shopID = getState().auth.user.shopID;
      const q = query(
        ref(db, `shopsIDS/${shopID}/events`),
        orderByChild("isActive"),
        equalTo(true)
      );
      const snapshot = await get(q);

      const keysEvents = [];

      snapshot.forEach((snapshot) => {
        keysEvents.push(snapshot.key);
      });

      const queriesEvents = [];
      keysEvents.forEach((keyEvent) => {
        queriesEvents.push(get(ref(db, `events/${keyEvent}/name`)));
      });

      const eventsSnapshots = await Promise.all(queriesEvents);

      let events = {};
      eventsSnapshots.forEach((eventSnapshot, index) => {
        events[keysEvents[index]] = {
          name: eventSnapshot.val(),
        };
      });
      dispatch(getEventsNameByShop(events));
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      dispatch(loadingEventsFinish({}));
    }
  };
export const startGetEventNameByEventIDs =
  ({ eventIDs }) =>
  async (dispatch, getState) => {
    try {
      const currentEvents = Object.keys(getState().events.events);
      eventIDs = eventIDs.filter((item, index, arr) => {
        return arr.indexOf(item) === index || !currentEvents.includes(item);
      });

      const queriesEvents = [];
      eventIDs.forEach((eventID) => {
        queriesEvents.push(get(ref(db, `events/${eventID}/name`)));
      });
      const eventsSnapshot = await Promise.all(queriesEvents);
      let events = {};
      eventsSnapshot.forEach((eventSnapshot, index) => {
        if (eventSnapshot.val()) {
          events[eventIDs[index]] = {
            name: eventSnapshot.val(),
          };
        }
      });
      dispatch(getEventsNameByShop(events));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getEventsByShop = (data) => ({
  type: types.GET_EVENTS_BY_SHOP,
  payload: data,
});
const getEventsNameByShop = (data) => ({
  type: types.GET_EVENTS_NAME_BY_SHOP,
  payload: data,
});

export const startCreateEvent = (event) => async (dispatch, getState) => {
  try {
    const shopID = getState().auth.user.shopID;
    const dbRef = ref(db, `events`);
    const creationTime = new Date().getTime();
    event = {
      ...event,
      shopID,
      creationTime,
    };
    const keyEvent = await push(dbRef, event).key;
    await set(ref(db, `shopsIDS/${shopID}/events/${keyEvent}`), {
      creationTime: -creationTime,
      isActive: event.isActive,
    });
    dispatch(
      createEvent({
        key: keyEvent,
        data: event,
      })
    );
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const createEvent = (data) => ({
  type: types.CREATE_EVENT,
  payload: data,
});

export const startSaveEvent =
  ({ eventID, data, isActive }) =>
  async (dispatch, getState) => {
    try {
      const shopID = getState().auth.user.shopID;

      const updates = {};
      updates[`events/${eventID}`] = { ...data, isActive };
      updates[`shopsIDS/${shopID}/events/${eventID}/isActive`] = isActive;

      await update(ref(db), updates);
      dispatch(
        saveEvent({
          key: eventID,
          data,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const saveEvent = (data) => ({
  type: types.SAVE_EVENT,
  payload: data,
});

export const startChangeStatusEvent =
  ({ eventID, isActive }) =>
  async (dispatch, getState) => {
    try {
      const shopID = getState().auth.user.shopID;
      const event = getState().events.events[eventID];
      if (
        !event.name ||
        !event.description ||
        !event.dateTimeStart ||
        !event.dateTimeEnd ||
        !event.location
      ) {
        return SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(EVENT_HAS_FIELDS_REQUIRED),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
      const dateStart = new Date(event.dateTimeStart);
      const dateEnd = new Date(event.dateTimeEnd);
      if (dateStart.getTime() > dateEnd.getTime()) {
        return SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(START_DATE_AND_END_DATE_VALIDATION),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
      if (event.isRecurrent && event.typeRecurrent.length === 0) {
        if (dateStart.getTime() > dateEnd.getTime()) {
          return SimpleAlert({
            title: i18next.t(ERROR),
            text: i18next.t(EVENT_RECURRENT_REQUIRED),
            icon: ALERT_ICON_TYPE_ERROR,
          });
        }
      }

      const updates = {};
      updates[`events/${eventID}/isActive`] = isActive;
      updates[`shopsIDS/${shopID}/events/${eventID}/isActive`] = isActive;
      await update(ref(db), updates);

      dispatch(
        changeStatusEvent({
          key: eventID,
          isActive,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeStatusEvent = (data) => ({
  type: types.CHANGE_STATUS_EVENT,
  payload: data,
});

export const startGetEventByID = (eventID) => async (_, getState) => {
  try {
    const shopID = getState().auth.user.shopID;
    const dbRef = ref(db, `events/${eventID}`);
    const snapshop = await get(dbRef);
    if (snapshop.exists()) {
      const data = snapshop.val();
      if (data?.shopID === shopID) {
        return data;
      }
      return false;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const loadingEventsInit = () => ({
  type: types.LOADING_EVENTS_INIT,
});
const loadingEventsFinish = () => ({
  type: types.LOADING_EVENTS_FINISH,
});
