import types from "../types";

const initialState = {
  events: {},
  isLoading: true,
};

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EVENTS_BY_SHOP:
      return {
        ...state,
        events: { ...state.events, ...action.payload },
        isLoading: false,
      };
    case types.GET_EVENTS_NAME_BY_SHOP:
      return {
        ...state,
        events: { ...state.events, ...action.payload },
        isLoading: true,
      };
    case types.CREATE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.SAVE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.CHANGE_STATUS_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.key]: {
            ...state.events[action.payload.key],
            isActive: action.payload.isActive,
          },
        },
      };
    case types.LOADING_EVENTS_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case types.LOADING_EVENTS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
