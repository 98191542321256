import { createContext, useContext, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import ModalYouKnow from "../components/Common/Modal/ModalYouKnow";
import differenceInDays from "date-fns/differenceInDays";
import {
  JUSTO_STORE_TYPE,
  ROLES_SHOPS,
  SPECIAL_STORES,
} from "../utils/constants";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useTour } from "@reactour/tour";
import {
  MODAL_FUNCTIONALITY_AMBASSADOR,
  MODAL_FUNCTIONALITY_AUTO_INVITES,
  MODAL_FUNCTIONALITY_CAMPAIGNS,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import { startGetStores } from "../actions/shops";
import { randomNumberBetween } from "../utils/numbers";

export const FunctionalitiesModalsContext = createContext();

const defaultFunctionalitiesModals = ["campaigns", "ambassadors"];
const recurrentFunctionalitiesModals = ["autoInvites"];

const FUNCTIONALITY_VIEW_THRESHOLD_DAYS = 1;
const FUNCTIONALITY_RESET_DAYS = 29;

export const FunctionalitiesModalsProvider = ({ children }) => {
  const [functionalitiesModalsViewed, setFunctionalitiesModalsViewed] =
    useLocalStorage("functionalitiesModalsViewed", []);
  const [lastFunctionalityDateView, setLastFunctionalityDateView] =
    useLocalStorage("lastFunctionalityDateView", "");
  const [
    lastRecurrentFunctionalityDateView,
    setLastRecurrentFunctionalityDateView,
  ] = useLocalStorage("lastRecurrentFunctionalityDateView", "");

  const [functionalitySelected, setFunctionalitySelected] = useState(null);
  const [functionalityModalOpen, setFunctionalityModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatchApp();
  const auth = useSelectorApp((state) => state?.auth);
  const featureFlags = useSelectorApp((state) => state?.shop?.featureFlags);
  const stores = useSelectorApp((state) => state?.shop?.stores);
  const profileInfluencer = useSelectorApp(
    (state) => state?.shop?.profileInfluencer
  );
  const isActive = useSelectorApp((state) => state?.shop?.isActive);
  const hasActivateAutoInvites = profileInfluencer?.autoInvitationsIsActive;
  const role = auth?.user?.role;
  const isActivePlan = auth?.isActivePlan;
  const isLoadingAuth = auth?.isLoading;

  const isECommerceBrand = Object.keys(stores || {}).some((storeID) => {
    const store = stores[storeID];
    const storeType = store?.storeType;
    const isSpecialStore =
      SPECIAL_STORES.includes(storeType) && storeType !== JUSTO_STORE_TYPE;

    return isSpecialStore && store?.isActive;
  });

  const { t } = useTranslationApp();

  const { isOpen } = useTour();

  const infoComponents = {
    campaigns: {
      imageURL: t(MODAL_FUNCTIONALITY_CAMPAIGNS),
      redirectURL: "/campaigns",
    },
    ambassadors: {
      imageURL: t(MODAL_FUNCTIONALITY_AMBASSADOR),
      redirectURL: "/ambassadors",
    },
    autoInvites: {
      imageURL: t(MODAL_FUNCTIONALITY_AUTO_INVITES),
      redirectURL: "/account?focus=autoInvites",
    },
  };

  const getInitialData = async () => {
    await dispatch(startGetStores());
    setIsLoading(false);
  };

  const getFunctionalitiesNotViewed = () => {
    return defaultFunctionalitiesModals.filter(
      (functionality) => !functionalitiesModalsViewed.includes(functionality)
    );
  };

  const resetFunctionalities = () => {
    setFunctionalitiesModalsViewed([]);
    setLastFunctionalityDateView("");
  };

  const shouldOpenModal = (lastViewDate) => {
    return (
      lastViewDate.length === 0 ||
      differenceInDays(new Date(), new Date(lastViewDate)) >=
        FUNCTIONALITY_VIEW_THRESHOLD_DAYS
    );
  };

  const handleFunctionalitiesModal = (functionalitiesNotViewed) => {
    if (functionalitiesNotViewed.length > 0) {
      const [functionality] = functionalitiesNotViewed;
      setFunctionalitySelected(functionality);
      setFunctionalitiesModalsViewed((prevViewed) => [
        ...prevViewed,
        functionality,
      ]);
      setLastFunctionalityDateView(Date.now());
      setFunctionalityModalOpen(true);

      return true;
    }

    return false;
  };

  const validShowAutoInvites = () => {
    if (!isActive) return false;
    if (hasActivateAutoInvites) return false;
    if (isECommerceBrand) return true;
    if (featureFlags?.isTrainedPhysicalBrand) return true;

    return false;
  };

  const handleRecurrentFunctionalitiesModal = () => {
    const randomIndexFunctionality = randomNumberBetween(
      0,
      recurrentFunctionalitiesModals.length - 1
    );

    const functionality =
      recurrentFunctionalitiesModals[randomIndexFunctionality];

    if (functionality === "autoInvites" && !validShowAutoInvites())
      return false;

    setFunctionalitySelected(functionality);
    setLastRecurrentFunctionalityDateView(Date.now());
    setFunctionalityModalOpen(true);

    return true;
  };
  useEffect(() => {
    if (!ROLES_SHOPS.includes(role)) return;
    getInitialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFunctionalityModalOpen(false);
    if (!ROLES_SHOPS.includes(role)) return;
    if (!isActivePlan) return;
    if (isLoadingAuth) return;
    if (isOpen) return;
    if (isLoading) return;

    if (
      differenceInDays(new Date(), new Date(lastFunctionalityDateView)) >=
      FUNCTIONALITY_RESET_DAYS
    ) {
      resetFunctionalities();
    }

    if (shouldOpenModal(lastFunctionalityDateView)) {
      const functionalitiesNotViewed = getFunctionalitiesNotViewed();
      const isShowed = handleFunctionalitiesModal(functionalitiesNotViewed);

      if (isShowed) return;
    }

    if (shouldOpenModal(lastRecurrentFunctionalityDateView)) {
      handleRecurrentFunctionalitiesModal();
    }
    // eslint-disable-next-line
  }, [role, isOpen, isLoadingAuth, isLoading, featureFlags, isActive]);

  return (
    <FunctionalitiesModalsContext.Provider
      value={{
        functionalitiesModals: defaultFunctionalitiesModals,
        functionalitiesModalsViewed,
        onFunctionalitiesModalsViewedChange: setFunctionalitiesModalsViewed,
      }}
    >
      {children}
      {functionalityModalOpen && functionalitySelected && (
        <ModalYouKnow
          modalOpen={functionalityModalOpen}
          onCloseModal={() => setFunctionalityModalOpen(false)}
          data={infoComponents[functionalitySelected]}
        />
      )}
    </FunctionalitiesModalsContext.Provider>
  );
};

export const useFunctionalitiesModalsContext = () => {
  return useContext(FunctionalitiesModalsContext);
};
