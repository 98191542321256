import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

const Loader = ({
  fullScreen = false,
  size = 40,
  hasMessage = false,
  variantMessage = "body1",
  message = "",
  alignMessage = "center",
  fullWidth = false,
  hasMarginTop = true,
  color = "background.paperContrastText",
  colorCustom = "",
  colorText = "background.paperContrastText",
  sx = {},
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: fullScreen ? "100vh" : "auto",
        mt: hasMarginTop ? 2 : 0,
        width: fullWidth ? "100%" : "auto",
        ...sx,
      }}
    >
      <CssBaseline />
      <CircularProgress
        size={size}
        sx={{
          color: colorCustom ? `${colorCustom} !important` : color,
        }}
      />
      {hasMessage && (
        <Typography
          variant={variantMessage}
          align={alignMessage}
          sx={{ my: 2, color: `${colorText} !important` }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Loader;
