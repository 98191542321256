import React, { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ModalFAQ from "../Modal/ModalFAQ";
import { HELP } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const ButtonHelpVideo = ({
  onSlideMenu = false,
  showButtonHelpWithoutFAQ = true,
  onDrawerToggle = () => {},
  sx = {},
}) => {
  const [modalFAQOpen, setModalFAQOpen] = useState(false);

  const { t } = useTranslationApp();

  const onChangeStatusModalFAQOpen = (status) => setModalFAQOpen(status);

  return (
    <>
      <Box
        className="button-open-faq"
        sx={{
          display: "none",
        }}
        onClick={() => {
          onChangeStatusModalFAQOpen(true);
        }}
      />

      <Box
        onClick={() => {
          onChangeStatusModalFAQOpen(true);
        }}
        className="button-help-video"
        sx={{
          mx: onSlideMenu ? 2 : 0,
          mr: onSlideMenu ? 0 : 1.5,
          py: "4px",
          cursor: "pointer",
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: "background.paperSecondaryContrastText",
              fontWeight: 600,
            }}
          >
            {t(HELP)}
          </Typography>
          <HelpIcon
            sx={{
              color: "background.paperSecondaryContrastText",
            }}
          />
        </Box>
      </Box>
      {modalFAQOpen && (
        <ModalFAQ
          modalOpen={modalFAQOpen}
          onCloseModal={() => onChangeStatusModalFAQOpen(false)}
        />
      )}
    </>
  );
};

export default ButtonHelpVideo;
