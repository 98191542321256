import {
  equalTo,
  get,
  limitToFirst,
  orderByChild,
  query,
  ref,
  startAfter,
  update,
} from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import {
  startGetInfluencer,
  startGetInfluencersByUserIDs,
} from "./influencers";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  REQUEST_STATUS_ACCEPTED,
} from "../utils/constants";
import { getShopID } from "./getters";
import { getObjectError } from "../utils/formats";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";
import { verifyIsErrorPermissionDenied } from "../utils/errors";

export const startGetRequestsByShop =
  ({
    limit,
    onChangeLastKey,
    lastKey,
    onCallbackFinish = () => {},
    onChangeLoadLastItem = () => {},
  }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = lastKey
        ? query(
            ref(db, `shopsIDS/${shopID}/requests`),
            orderByChild("creationTime"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `shopsIDS/${shopID}/requests`),
            orderByChild("creationTime"),
            limitToFirst(limit)
          );
      const snapshot = await get(q);

      const keysRequests = [];
      const snapshotSize = snapshot.size;

      if (snapshotSize === 0) {
        onCallbackFinish();
        onChangeLoadLastItem(true);
        return;
      }

      let count = 1;

      snapshot.forEach((snapshot) => {
        if (count === snapshotSize)
          onChangeLastKey(snapshot.val().creationTime);
        keysRequests.push(snapshot.key);
        count++;
      });

      const queriesRequests = [];
      keysRequests.forEach((keyRequest) => {
        queriesRequests.push(get(ref(db, `requests/${keyRequest}`)));
      });

      const requestsSnapshots = await Promise.all(queriesRequests);

      let requests = {};
      let userIDs = [];
      requestsSnapshots.forEach((requestSnapshot) => {
        requests = {
          ...requests,
          [requestSnapshot.key]: requestSnapshot.val(),
        };
        userIDs.push(requestSnapshot.val().userID);
      });

      const influencers = await dispatch(
        startGetInfluencersByUserIDs({ userIDs })
      );

      lastKey
        ? dispatch(getRequestsByShopPagination(requests))
        : dispatch(getRequestsByShop(requests));

      onCallbackFinish();
      return influencers;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      dispatch(loadingRequestsFinish({}));
      onChangeLoadLastItem(true);
      if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
        return false;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
  };

const getRequestsByShop = (requests) => ({
  type: types.GET_REQUESTS_BY_SHOP_FINISH,
  payload: requests,
});
export const getRequestsByShopPagination = (requests) => ({
  type: types.GET_REQUESTS_BY_SHOP_PAGINATION,
  payload: requests,
});

export const startGetRequestByShopIDuserID =
  ({ userID }) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const q = query(
        ref(db, "requests"),
        orderByChild("shopIDuserID"),
        equalTo(`${shopID}-${userID}`)
      );
      const snapshot = await get(q);
      if (snapshot.exists) {
        const data = snapshot.val();
        dispatch(getRequestsByShopPagination(data));
        return data;
      }
      return null;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetRequest =
  ({ requestID }) =>
  async (dispatch) => {
    try {
      const snapshot = await get(ref(db, `requests/${requestID}`));
      if (snapshot.exists()) {
        let data = snapshot.val();
        const influencer = await dispatch(startGetInfluencer(data.userID));

        return { ...data, ...influencer };
      }
      return null;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startDeleteRequest =
  ({ requestID, status = REQUEST_STATUS_ACCEPTED }) =>
  async (dispatch, getState) => {
    try {
      const request = getState().requests.requests[requestID];
      const shopID = dispatch(getShopID());

      const updates = {};
      updates[`requests/${requestID}`] = null;

      const now = new Date().getTime();

      if (request) {
        const node =
          status === REQUEST_STATUS_ACCEPTED
            ? "requestsAccepted"
            : "requestsRejected";

        const statusTime =
          status === REQUEST_STATUS_ACCEPTED ? "acceptTime" : "rejectTime";

        updates[`${node}/${requestID}`] = { ...request, [statusTime]: now };
        updates[`shopsIDS/${shopID}/${node}/${requestID}`] = {
          creationTime: -now,
        };
      }

      await update(ref(db), updates);
      dispatch(deleteRequest(requestID));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });

      return false;
    }
  };
const deleteRequest = (requestID) => ({
  type: types.DELETE_REQUEST,
  payload: requestID,
});
const loadingRequestsFinish = () => ({
  type: types.LOADING_REQUESTS_FINISH,
});
