import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "../../../Common/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { APPLICANT_STATUS_REVISIONS } from "../../../../utils/constants";
import CounterRevisionText from "../CounterRevisionText";
import { ConfirmAlert } from "../../../../utils/alerts";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  APPLICANT_REVISION_PLACEHOLDER,
  BUTTON_ACCEPT_CONTENT,
  BUTTON_REJECT_CONTENT,
  BUTTON_SEND_REVIEW,
} from "../../../../locales/keysTranslations";

const ContentApplicantActions = ({
  data,
  onAcceptApplicantContent,
  onRevisionsApplicantContent,
  onRejectedApplicant,
}) => {
  const [comment, setComment] = useState(
    data.status === APPLICANT_STATUS_REVISIONS ? data.comment : ""
  );

  const { t } = useTranslationApp();

  const navigate = useNavigate();

  const onChangeForm = (e) => {
    setComment(e.target.value);
  };

  const onAcceptedApplicant = async () => {
    await onAcceptApplicantContent();
    navigate(`/campaign?campaignID=${data.campaignID}`);
  };
  const onSendRevisions = async () => {
    await onRevisionsApplicantContent(comment);
    navigate(`/campaign?campaignID=${data.campaignID}`);
  };
  const onRejectApplicant = async () => {
    const response = await ConfirmAlert({
      title: "¿Estás seguro de rechazar el contenido?",
      text: "Una vez rechazado no podrás acceder a el",
      confirmButtonText: "Rechazar",
    });
    if (response.isConfirmed) {
      await onRejectedApplicant();
      navigate(`/campaign?campaignID=${data.campaignID}`);
    }
  };

  return (
    <Box
      sx={{
        height: "40%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: 500,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          type="button"
          disabled={!!comment && (data.revision ?? 0) < 3}
          onClick={onAcceptedApplicant}
        >
          {t(BUTTON_ACCEPT_CONTENT)}
        </Button>
        {(data.revision ?? 0) < 3 && (
          <TextField
            label={t(APPLICANT_REVISION_PLACEHOLDER)}
            name="comments"
            value={comment}
            fullWidth
            multiline
            minRows={2}
            maxRows={3}
            variant="filled"
            onChange={onChangeForm}
          />
        )}
        {(data.revision ?? 0) < 3 && (
          <Button
            variant="contained"
            color="secondary"
            type="button"
            disabled={!comment}
            onClick={onSendRevisions}
          >
            <CounterRevisionText
              text={t(BUTTON_SEND_REVIEW)}
              revision={data.revision ?? 0}
              increment={1}
            />
          </Button>
        )}
        {data.revision === 3 && (
          <Button
            variant="contained"
            color="error"
            type="button"
            onClick={onRejectApplicant}
          >
            {t(BUTTON_REJECT_CONTENT)}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ContentApplicantActions;
