import React, { useContext } from "react";
import { useDispatchApp } from "../lib/redux";
import { MenuContext } from "../Contexts/MenuContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuContainer from "../components/User/Menu/MenuContainer";
import { Link } from "react-router-dom";
import Loader from "../components/Common/Loader/Loader";
import Button from "../components/Common/Buttons/Button";
import ModalCopyMenu from "../components/User/Menu/ModalCopyMenu";
import SelectStore from "../components/Common/Selects/SelectStore";
import ModalUploadMenu from "../components/User/Menu/ModalUploadMenu";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../utils/constants";
import { getRole, getUser } from "../actions/getters";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTour } from "@reactour/tour";
import { STEPS_MENU_FUNCTIONALITY } from "../onboarding/steps";
import { SELECT_STORE_MENU } from "../onboarding/stepsSelectors";
import { useTranslationApp } from "../lib/i18next";
import {
  BUTTON_ADD_STORE,
  BUTTON_COPY_CATALOG,
  BUTTON_UPLOAD_CATALOG,
  MENU_DESCRIPTION,
  MENU_EMPTY_STORES,
  MENU_TITLE,
} from "../locales/keysTranslations";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const Menu = ({ sx = {} }) => {
  const {
    storeSelected,
    stores,
    modalOpenCopy,
    modalOpenUpload,
    isLoading,
    onSelectStore,
    onChangeStatusModalCopy,
    onChangeStatusModalUpload,
  } = useContext(MenuContext);
  const dispatch = useDispatchApp();
  const user = dispatch(getUser());

  const { t } = useTranslationApp();

  const { setSteps, setIsOpen } = useTour();

  const role = dispatch(getRole());

  const onInitOnboardingMenu = () => {
    setSteps(STEPS_MENU_FUNCTIONALITY);
    setIsOpen(true);
  };

  const isMobile = useMediaQuery("(min-width:600px)");

  if (isLoading) return <Loader size={70} />;

  return (
    <ContainerPage sx={sx}>
      {stores && Object.keys(stores).length > 0 ? (
        <>
          <TitlePage
            hasTooltip={role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE}
            toolTipAction={onInitOnboardingMenu}
          >
            {t(MENU_TITLE)}
          </TitlePage>
          <DescriptionPage>{t(MENU_DESCRIPTION)}</DescriptionPage>
          {(user.role === ADMIN_ROLE || user.role === SUPER_ADMIN_ROLE) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                flexDirection: isMobile ? "row" : "column-reverse",
                gap: 2,
              }}
            >
              <SelectStore
                dataTour={SELECT_STORE_MENU}
                storesList={stores}
                storeSelected={storeSelected}
                onChange={onSelectStore}
                hiddenSpecialStores={true}
              />
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    minWidth: 140,
                    maxWidth: 300,
                    width: isMobile ? "auto" : "100%",
                  }}
                  onClick={() => onChangeStatusModalUpload(true)}
                >
                  {t(BUTTON_UPLOAD_CATALOG)}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    minWidth: 140,
                    maxWidth: 300,
                    width: isMobile ? "auto" : "100%",
                  }}
                  onClick={() => onChangeStatusModalCopy(true)}
                >
                  {t(BUTTON_COPY_CATALOG)}
                </Button>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography variant="h6" component="h1" sx={{ fontWeight: 600 }}>
            {t(MENU_EMPTY_STORES)}
          </Typography>
          <Link to="/stores" style={{ textDecoration: "none" }}>
            <Button variant="contained" sx={{ mt: 2 }}>
              {t(BUTTON_ADD_STORE)}
            </Button>
          </Link>
        </>
      )}
      {storeSelected && <MenuContainer />}
      {modalOpenCopy && (
        <ModalCopyMenu
          open={modalOpenCopy}
          onCloseModal={() => onChangeStatusModalCopy(false)}
          storesList={stores}
          onChangeStore={onSelectStore}
        />
      )}
      {modalOpenUpload && (
        <ModalUploadMenu
          open={modalOpenUpload}
          onCloseModal={() => onChangeStatusModalUpload(false)}
          storesList={stores}
          onSelectStore={onSelectStore}
        />
      )}
    </ContainerPage>
  );
};

export default Menu;
