import React, { useEffect, useState } from "react";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfluencerNameLogo from "./InfluencerNameLogo";
import { useForm } from "react-hook-form";
import TextField from "../Fields/TextField";
import {
  FIELD_MIN_AMOUNT_PAY,
  FIELD_REQUIRED,
  MODAL_PAY_CREATOR_AMOUNT_LABEL,
  PAY_INFLUENCER,
  READY,
  VALUE_WITH_CURRENCY,
  TOTAL_PAYMENT,
  SERVICE_FEE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerStripeInformation from "../Stripe/ContainerStripeInformation";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import CurrencyField from "../NumberFormats/CurrencyField";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startPayInfluencerStripe } from "../../../actions/payments";
import { SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_SUCCESS,
  FEE_VALUE_DIRECT_PAYMENT,
  MIN_VALUE_PAY_AMBASSADOR,
} from "../../../utils/constants";
import { onInit3DSecureAuth } from "../../../services/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import { numberDecimal } from "../../../utils/numberFormats";
import { startGetInfluencer } from "../../../actions/influencers";
import Loader from "../Loader/Loader";

const ModalPayInfluencer = ({
  modalOpen,
  onCloseModal,
  userID,
  influencer,
}) => {
  const [loading, setLoading] = useState(false);
  const [influencerData, setInfluencerData] = useState(influencer);
  const [isLoadingInfluencer, setIsLoadingInfluencer] = useState(
    influencer ? false : true
  );

  const { control, setValue, watch, getValues, trigger, formState } = useForm({
    defaultValues: {
      amount: 0,
      card: "",
    },
  });

  useFocusErrorForm(formState);
  const errors = formState.errors;

  const card = watch("card");
  const amount = watch("amount");

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const stripe = useStripe();

  const influencers = useSelectorApp(
    (state) => state?.influencers?.influencers
  );

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const getInitialData = async () => {
    const isInfluencerInRedux = influencers[userID];
    if (isInfluencerInRedux) {
      setInfluencerData(isInfluencerInRedux);
      setIsLoadingInfluencer(false);
      return;
    }
    const response = await dispatch(startGetInfluencer(userID, false));
    if (response) {
      setInfluencerData(response);
      setIsLoadingInfluencer(false);
    }
  };

  const onPayInfluencer = async (paymentMethodID = card, paymentIntent) => {
    const isValid = await trigger();

    if (!isValid) return;

    const formValues = getValues();

    setLoading(true);

    const response = await dispatch(
      startPayInfluencerStripe({
        paymentMethodID,
        amount: formValues.amount,
        userID,
        paymentIntent,
      })
    );

    if (response.ok) {
      setLoading(false);
      onCloseModal();

      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });

      return { ok: true };
    }
    if (!response.ok && response.status === "requiresAction") {
      const responseVerification = await onInit3DSecureAuth({
        clientSecret: response.clientSecret,
        stripe,
        onCallbackFailed: onCloseModal,
      });
      if (responseVerification.ok) {
        const paymentIntent = responseVerification.paymentIntent;
        await onPayInfluencer(
          paymentMethodID,
          formValues.amount,
          paymentIntent
        );
        return { ok: true };
      }
    }
    setLoading(false);
    return { ok: false };
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        {isLoadingInfluencer ? (
          <Loader size={30} />
        ) : (
          <InfluencerNameLogo
            userID={userID}
            data={influencerData}
            sx={{ mb: 0 }}
          />
        )}

        <ContainerStripeInformation
          onCallback={onPayInfluencer}
          CustomComponent={
            <TextField
              name="amount"
              variant="filled"
              InputProps={{
                inputComponent: CurrencyField,
              }}
              label={t(MODAL_PAY_CREATOR_AMOUNT_LABEL)}
              fullWidth
              sx={{ my: 1 }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: (value) => {
                  if (Number(value) < MIN_VALUE_PAY_AMBASSADOR) {
                    return t(FIELD_MIN_AMOUNT_PAY, {
                      value: MIN_VALUE_PAY_AMBASSADOR,
                      currency: "USD",
                    });
                  }
                },
              }}
            />
          }
          CustomComponentDetailPayment={
            <DetailPayment amount={Number(amount)} />
          }
          card={card}
          onChangeCard={(newCard) => setValue("card", newCard)}
          loading={loading}
        />
      </Box>
    </ModalBasicLayout>
  );
};

const DetailPayment = ({ amount }) => {
  const { t } = useTranslationApp();
  const FEE_VALUE_CALCULATE = numberDecimal(amount * FEE_VALUE_DIRECT_PAYMENT);

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ mt: 1, fontWeight: 600 }}
        >
          {`${t(PAY_INFLUENCER)}:`}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: amount,
            currency: "USD",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ mt: 1, fontWeight: 600 }}
        >
          {`${t(SERVICE_FEE)}:`}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: FEE_VALUE_CALCULATE,
            currency: "USD",
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ mt: 1, fontWeight: 600 }}
        >
          {`${t(TOTAL_PAYMENT)}:`}
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 1 }}>
          {t(VALUE_WITH_CURRENCY, {
            value: amount + FEE_VALUE_CALCULATE,
            currency: "USD",
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default ModalPayInfluencer;
