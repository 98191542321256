import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  APPLICANT_STATUS_PENDING,
  APPLICANT_STATUS_REJECTED,
  DEFAULT_TABLE_PAGES,
} from "../../../../../utils/constants";
import { useSearchParams } from "react-router-dom";
import ApplicantsTable from "./ApplicantsTable";
import { PAGE_CAMPAIGN_TABLE_APPLICANTS } from "../../../../../onboarding/stepsSelectors";
import {
  CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE,
  CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE,
  CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE,
} from "../../../../../locales/keysTranslations";
import ApplicantsPendingTable from "./ApplicantsPendingTable";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonIcon from "@mui/icons-material/Person";
import _ from "lodash";
import SegmentedController from "../../../../Common/Containers/SegmentedController";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import InvitationsCampaignsTable from "../InvitationsCampaigns/InvitationsCampaignsTable";
import { useDispatchApp } from "../../../../../lib/redux";
import { getIsBlockedActions } from "../../../../../actions/getters";
import { startOpenInfluencerConversation } from "../../../../../actions/chat";
import FiltersInfluencers from "../../../../Common/Filters/FiltersInfluencers";
import useCreatorsFilters from "../../../../../hooks/useCreatorsFilters";

const APPLICANTS_INVITED_TAB = 0;
const APPLICANTS_PENDING_TAB = 1;
const APPLICANTS_ACCEPTED_TAB = 2;

const ApplicantsContainer = ({
  data,
  dataCampaign,
  invitesCampaign,
  onAcceptApplicant,
  onRejectedApplicant,
  onSelectApplicant,
  statusCampaign,
  campaignID,
}) => {
  const [tab, setTab] = useState(APPLICANTS_PENDING_TAB);
  const { filters, onChangeFilters, control, onReset } = useCreatorsFilters();

  const [page, setPage] = useState(0);
  const [isViewHighlighted, setViewHighlighted] = useState(false);
  const [rowsPerPage] = useState(DEFAULT_TABLE_PAGES);

  const [params] = useSearchParams();
  const applicationID = params.get("applicationID");

  const dispatch = useDispatchApp();

  const influencersInvited = Object.keys(invitesCampaign).map(
    (key) => invitesCampaign[key].userID
  );

  const getApplicantsPending = () => {
    const applicantsFiltered = {};
    const usersIDsApplicantID = {};

    Object.keys(data).forEach((key) => {
      if (data[key]?.status === APPLICANT_STATUS_PENDING) {
        usersIDsApplicantID[data[key].userID] = key;

        if (!influencersInvited.includes(data[key].userID))
          applicantsFiltered[key] = data[key];
      }
    });

    return { applicantsPending: applicantsFiltered, usersIDsApplicantID };
  };
  const { applicantsPending, usersIDsApplicantID: usersIDsApplicantIDPending } =
    getApplicantsPending();

  const getApplicantsAccepted = () => {
    const applicantsFiltered = {};
    const usersIDsApplicantID = {};

    Object.keys(data).forEach((key) => {
      if (
        data[key]?.status !== APPLICANT_STATUS_PENDING &&
        data[key]?.status !== APPLICANT_STATUS_REJECTED
      ) {
        usersIDsApplicantID[data[key].userID] = key;
        applicantsFiltered[key] = data[key];
      }
    });
    return {
      applicantsAccepted: applicantsFiltered,
      usersIDsApplicantID: usersIDsApplicantID,
    };
  };
  const {
    applicantsAccepted,
    usersIDsApplicantID: usersIDsApplicantIDAccepted,
  } = getApplicantsAccepted();

  const getInvitesCampaign = () => {
    const invitesCampaignFiltered = {};

    Object.keys(invitesCampaign).forEach((key) => {
      const invite = invitesCampaign[key];
      if (usersIDsApplicantIDAccepted[invite.userID]) {
        return;
      }
      if (usersIDsApplicantIDPending[invite.userID]) {
        invite.applicantID = usersIDsApplicantIDPending[invite.userID];
      }
      invitesCampaignFiltered[key] = invite;
    });

    return { invitesCampaign: invitesCampaignFiltered };
  };
  const { invitesCampaign: invites } = getInvitesCampaign();

  useEffect(() => {
    if (!_.isEmpty(applicantsAccepted)) {
      setTab(APPLICANTS_ACCEPTED_TAB);
    } else if (!_.isEmpty(applicantsPending)) {
      setTab(APPLICANTS_PENDING_TAB);
    } else if (!_.isEmpty(invitesCampaign)) {
      setTab(APPLICANTS_INVITED_TAB);
    }

    setTimeout(() => {
      setViewHighlighted(true);
    }, 6000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = (newPage) => {
    setPage(newPage);
  };

  const onOpenChatInfluencer = async (userID) => {
    const isBlocked = dispatch(
      getIsBlockedActions({
        verifyBlockedLastInvite: true,
        verifyBlockedFreeTrial: true,
      })
    );
    if (isBlocked) return;
    await dispatch(
      startOpenInfluencerConversation({
        userID,
      })
    );
  };

  if (
    _.isEmpty(applicantsAccepted) &&
    _.isEmpty(applicantsPending) &&
    _.isEmpty(invitesCampaign)
  )
    return null;

  return (
    <Box sx={{ width: "100%" }} data-tour={PAGE_CAMPAIGN_TABLE_APPLICANTS}>
      <FiltersInfluencers
        control={control}
        filters={filters}
        onChangeFilters={onChangeFilters}
        onSetInitialStateFilters={onReset}
        disabledNewCategory={false}
        hiddenNewCategory={true}
        hiddenSearchBar={true}
        hiddenTypeSearch={true}
      />
      <SegmentedController
        initialValue={tab}
        value={tab}
        onChangeTab={setTab}
        tabs={[
          {
            label: CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE,
            icon: <PersonAddAlt1Icon />,
          },
          {
            label: CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE,
            icon: <PersonIcon />,
          },
          {
            label: CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE,
            icon: <HowToRegIcon />,
          },
        ]}
      />
      {tab === APPLICANTS_ACCEPTED_TAB && (
        <ApplicantsTable
          filters={filters}
          data={applicantsAccepted}
          onSelectApplicant={onSelectApplicant}
          campaignID={campaignID}
          statusCampaign={statusCampaign}
          page={page}
          onPageChange={onChangePage}
          rowsPerPage={rowsPerPage}
          applicationID={applicationID}
          isViewHighlighted={isViewHighlighted}
          onOpenChatInfluencer={onOpenChatInfluencer}
        />
      )}
      {tab === APPLICANTS_PENDING_TAB && (
        <ApplicantsPendingTable
          filters={filters}
          data={applicantsPending}
          dataCampaign={dataCampaign}
          onAcceptApplicant={onAcceptApplicant}
          onRejectedApplicant={onRejectedApplicant}
          campaignID={campaignID}
          statusCampaign={statusCampaign}
          page={page}
          onPageChange={onChangePage}
          rowsPerPage={rowsPerPage}
          applicationID={applicationID}
          isViewHighlighted={isViewHighlighted}
          onOpenChatInfluencer={onOpenChatInfluencer}
        />
      )}
      {tab === APPLICANTS_INVITED_TAB && (
        <InvitationsCampaignsTable
          filters={filters}
          data={invites}
          onAcceptApplicant={onAcceptApplicant}
          onRejectedApplicant={onRejectedApplicant}
          statusCampaign={statusCampaign}
          campaignID={campaignID}
          onOpenChatInfluencer={onOpenChatInfluencer}
        />
      )}
    </Box>
  );
};

export default ApplicantsContainer;
