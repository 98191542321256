import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DEFAULT_CREATORS_CARDS,
  DELAY_DEBOUNCE,
  REQUEST_STATUS_REJECTED,
} from "../../../utils/constants";
import {
  getCountry,
  getInfluencerIsHiddenFromTables,
  getIsInfluencerInvited,
} from "../../../actions/getters";
import InfluencerCard from "../Creators/InfluencerCard";
import { isFilteringInfluencers } from "../../../services/shops";
import Loader from "../../Common/Loader/Loader";
import {
  EMPTY_REQUESTS,
  EMPTY_RESULTS_SHOW,
  LOADING_INFORMATION,
  NO_MORE_RESULTS_TO_SHOW,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import { usePagination } from "../../../hooks/usePagination";
import {
  startGetInfluencersFavorites,
  startGetInfluencersWithFiltersAndRequests,
  startGetNewInfluencers,
} from "../../../actions/influencers";
import { useDebounced } from "../../../hooks/useDebounce";
import useCreatorsFilters from "../../../hooks/useCreatorsFilters";
import {
  startDeleteRequest,
  startGetRequestsByShop,
} from "../../../actions/requests";

const RequestInfluencersTable = ({ filters = {} }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [influencersDiscarded, setInfluencersDiscarded] = useState([]);

  const { onInfluencerPassFilter } = useCreatorsFilters();

  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
  } = filters;

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const requests = useSelectorApp((state) => state.requests.requests);
  const invites = useSelectorApp((state) => state.invites.invites);
  const influencers = useSelectorApp((state) => state.influencers.influencers);
  const signUpCountry = dispatch(getCountry("shop"));

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
  });

  const getInformationIsInvited = (userID) => {
    const inviteID = dispatch(getIsInfluencerInvited(userID));
    if (inviteID) {
      return {
        isActive: true,
        isInvited: true,
        inviteID,
        userID,
      };
    }
    return {
      isActive: true,
      isInvited: false,
      userID,
    };
  };

  const onGetData = useDebounced(
    async (type = "first") => {
      let lastKeyToPass = lastKey;

      if (type === "first") {
        onChangeLastKey(null);
        lastKeyToPass = null;
        setIsLoading(true);
        onChangeLoadLastItem(false);
      }

      if (favoritesCategorySelected) {
        await dispatch(
          startGetInfluencersFavorites({
            limit: rowsPerPage,
            lastKey: lastKeyToPass,
            onChangeLastKey,
            withRequests: true,
            onChangeLoadLastItem: () => onChangeLoadLastItem(true),
          })
        );
      } else if (newCategorySelected) {
        await dispatch(
          startGetNewInfluencers({
            withRequests: true,
          })
        );
      } else if (isFiltering) {
        await dispatch(
          startGetInfluencersWithFiltersAndRequests({
            limit: rowsPerPage,
            onChangeLastKey: onChangeLastKey,
            gender: genderSelected,
            categories: categoriesSelected,
            followers: rangeFollowers,
            city: citySelected,
            useScore: topRatedCategorySelected,
            lastKey: lastKeyToPass,
            onChangeLoadLastItem: () => onChangeLoadLastItem(true),
          })
        );
      } else {
        await dispatch(
          startGetRequestsByShop({
            lastKey: lastKeyToPass,
            limit: rowsPerPage,
            onChangeLastKey,
            onChangeLoadLastItem: () => onChangeLoadLastItem(true),
          })
        );
      }

      setIsLoading(false);
    },
    [DELAY_DEBOUNCE]
  );

  const onFilterData = () => {
    const influencersKeys = Object.keys(data);

    if (influencersKeys.length === 0) return {};

    const filtered = {};

    for (let influencerKey of influencersKeys) {
      const influencer = influencers[influencerKey] || {};
      if (influencersDiscarded.includes(influencerKey) && isMobile) continue;

      const passFilter = onInfluencerPassFilter({
        influencer,
        userID: influencerKey,
        filters,
      });

      if (!passFilter) continue;

      filtered[influencerKey] = influencer;
    }

    return filtered;
  };

  const onDiscardInfluencer = async (userID) => {
    if (keysSorted.length - 1 === 0) {
      setIsLoading(true);
    }
    if (keysSorted.length === 1) {
      onPageChange(page + 1);
    }
    await dispatch(
      startDeleteRequest({
        requestID: data[userID].requestID,
        status: REQUEST_STATUS_REJECTED,
      })
    );
    setInfluencersDiscarded((prev) => [...prev, userID]);
  };

  const onDeleteRequest = async (requestID, userID) => {
    await dispatch(
      startDeleteRequest({ requestID, status: REQUEST_STATUS_REJECTED })
    );
  };

  const dataFiltered = onFilterData();
  const keysSorted = Object.keys(dataFiltered).sort((a, b) => {
    if (isFiltering) {
      return dataFiltered[b]?.score - dataFiltered[a]?.score;
    } else {
      return dataFiltered[b]?.creationTime - dataFiltered[a]?.creationTime;
    }
  });

  const currentCreator = data[keysSorted[0]];

  const {
    fromRef,
    page,
    onPageChange,
    lastKey,
    onChangeLastKey,
    rowsPerPage,
    loadLastItem,
    onChangeLoadLastItem,
  } = usePagination({
    rowsPerPageValue: DEFAULT_CREATORS_CARDS,
    onCallBackNextPage: () => {
      onGetData("next");
    },
  });

  useEffect(() => {
    setIsLoading(true);
    onGetData("first");
    // eslint-disable-next-line
  }, [
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    signUpCountry,
  ]);

  useEffect(() => {
    const requestsKeys = Object.keys(requests);

    if (requestsKeys.length === 0) return;

    const filtered = {};

    for (let requestsKey of requestsKeys) {
      const request = requests[requestsKey] || {};
      const userID = request.userID;
      const influencer = influencers[userID];

      if (!influencer) continue;

      const isInvited = getInformationIsInvited(userID);

      filtered[userID] = {
        ...influencer,
        ...isInvited,
        requestID: requestsKey,
        creationTime: request.creationTime,
      };
    }

    setData(filtered);

    // eslint-disable-next-line
  }, [requests, influencers, invites]);

  if (isLoading) {
    return (
      <Loader
        size={40}
        fullWidth
        hasMessage={true}
        message={t(LOADING_INFORMATION)}
        variantMessage="body1"
      />
    );
  }

  if (keysSorted.length === 0) {
    if (influencersDiscarded.length > 0) {
      return (
        <Typography variant="body1" align="center">
          {t(NO_MORE_RESULTS_TO_SHOW)}
        </Typography>
      );
    }
    return (
      <Typography variant="body1" align="center">
        {t(isFiltering ? EMPTY_RESULTS_SHOW : EMPTY_REQUESTS)}
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      {!isMobile &&
        keysSorted.map((key, index) => {
          const influencer = data[key] || {};
          if (dispatch(getInfluencerIsHiddenFromTables({ userID: key })))
            return null;

          return (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={key}>
              <InfluencerCard
                data={influencer}
                showCloseButton={true}
                onClickCloseButton={() =>
                  onDeleteRequest(influencer.requestID, influencer.userID)
                }
              />
              {index === keysSorted.length - 1 && <div ref={fromRef} />}
            </Grid>
          );
        })}
      {isMobile && currentCreator && (
        <Grid item xs={12}>
          <InfluencerCard
            data={currentCreator}
            onDiscardInfluencer={onDiscardInfluencer}
          />
        </Grid>
      )}
      {!loadLastItem && !isMobile && (
        <Grid item xs={12}>
          <div ref={fromRef}>
            <Loader size={30} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default RequestInfluencersTable;
