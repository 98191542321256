import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import GeneralStatisticPoint from "../../../Owner/Statistics/General/GeneralStatisticPoint";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  CLICKS,
  CTR,
  IMPRESSIONS,
  LAST_UPDATE_DATE,
  SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS,
  VALUE_WITH_PERCENTAGE,
} from "../../../../locales/keysTranslations";
import { numberCompactFormat } from "../../../../utils/numberFormats";
import { getKeyMetricAdsCampaign } from "../../../../utils/formats";
import { startUpdateAdsCampaignsInformationFromPlatforms } from "../../../../actions/adsCampaigns";
import { useDispatchApp } from "../../../../lib/redux";
import { ADS_CAMPAIGN_STATUS_ACTIVE } from "../../../../utils/constants";
import differenceInHours from "date-fns/differenceInHours";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";

let styles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
const AdsCampaignStatistics = ({ data = {}, campaignID, onChangeData }) => {
  const statistics = data?.statistics || {};

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const keyMetricInformation = getKeyMetricAdsCampaign(data);

  const onUpdateAdsCampaignInformationFromPlatforms = async () => {
    if (data?.status !== ADS_CAMPAIGN_STATUS_ACTIVE) return;
    const response = await dispatch(
      startUpdateAdsCampaignsInformationFromPlatforms({
        campaignIDs: [campaignID],
      })
    );
    if (response) {
      const data = response[campaignID];
      onChangeData((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    }
  };

  useEffect(() => {
    onUpdateAdsCampaignInformationFromPlatforms();

    //eslint-disable-next-line
  }, []);

  const lastActiveTime = new Date(data?.lastActiveTime || new Date());
  const now = new Date();
  const differenceHours = differenceInHours(now, lastActiveTime);
  const showDisclaimerUpdating =
    differenceHours <= 4 &&
    !data?.statistics?.lastUpdatedTime &&
    data?.isFirstActive;
  const lastUpdatedStatistics = data?.statistics?.lastUpdatedTime;

  return (
    <>
      {showDisclaimerUpdating && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: -1.5,
          }}
        >
          <ErrorIcon sx={{ color: "fields.placeholder", fontSize: 18 }} />
          <Typography
            variant="body2"
            sx={{
              color: "fields.placeholder",
            }}
          >
            {t(SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS)}
          </Typography>
        </Box>
      )}
      {lastUpdatedStatistics && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: -1.5,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "fields.placeholder",
            }}
          >
            {t(LAST_UPDATE_DATE, { date: new Date(lastUpdatedStatistics) })}
          </Typography>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={6} lg={3} sx={styles}>
          <GeneralStatisticPoint
            Icon={RemoveRedEyeIcon}
            title={t(IMPRESSIONS)}
            value={`${numberCompactFormat(statistics.impressions || 0)}`}
          />
        </Grid>
        <Grid item xs={6} lg={3} sx={styles}>
          <GeneralStatisticPoint
            Icon={AdsClickIcon}
            title={t(CLICKS)}
            value={`${numberCompactFormat(statistics.clicks || 0)}`}
          />
        </Grid>
        <Grid item xs={6} lg={3} sx={styles}>
          <GeneralStatisticPoint
            Icon={TrendingUpIcon}
            title={t(CTR)}
            value={`${t(VALUE_WITH_PERCENTAGE, {
              value: statistics.ctr || 0,
            })}`}
          />
        </Grid>
        {keyMetricInformation && (
          <Grid item xs={6} lg={3} sx={styles}>
            <GeneralStatisticPoint
              Icon={keyMetricInformation.icon}
              title={t(keyMetricInformation.label)}
              value={numberCompactFormat(keyMetricInformation.value || 0)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AdsCampaignStatistics;
