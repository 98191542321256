import React, { useState, useEffect } from "react";
import LinkText from "../components/Common/Texts/LinkText";
import {
  CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER,
  CREATE_CAMPAIGN_QUESTION,
  // FIELD_BEHAVIORS_REQUIRED,
  FIELD_BUDGET_REQUIRED,
  FIELD_DESCRIPTION_REQUIRED,
  FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED,
  FIELD_DESTINATION_URL_REQUIRED,
  FIELD_GENDERS_REQUIRED,
  FIELD_INTERESTS_REQUIRED,
  FIELD_LOCATIONS_REQUIRED,
  FIELD_MAX_LENGTH,
  FIELD_META_TRACKING_ID_REQUIRED,
  FIELD_MIN_LENGTH,
  FIELD_NAME_REQUIRED,
  FIELD_OBJECTIVE_REQUIRED,
  FIELD_TIKTOK_TRACKING_ID_REQUIRED,
  FIELD_TYPE_BUDGET_REQUIRED,
  FIELD_URL_VALID,
  FIELD_CONTENT_REQUIRED,
  LOADING_INFORMATION,
  QUESTION,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import Box from "@mui/material/Box";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { getNameBusinessTheme } from "../actions/getters";
import Select from "../components/Common/Selects/Select";
import { useForm } from "react-hook-form";
import CreateAdsCampaignForm from "../components/User/AdsCampaigns/CreateAdsCampaigns/CreateAdsCampaignForm";
import Loader from "../components/Common/Loader/Loader";
import { useLocale } from "../Contexts/LocaleContext";
import {
  generateAdsCampaignDescription,
  startCreateAdsCampaign,
  startGetCurrencyPlatform,
  startGetRecommendedSettingsAdsCampaign,
} from "../actions/adsCampaigns";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
  ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
  ADS_CAMPAIGN_FORM_MODE_ADVANCED,
  ADS_CAMPAIGN_FORM_MODE_SIMPLE,
  ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH,
  META,
  TIKTOK,
} from "../utils/constants";
import { REGEX_URL } from "../utils/regexsValidation";
import { startGetUrlForAds } from "../actions/shops";
import CreateAdsCampaignAdvancedForm from "../components/User/AdsCampaigns/CreateAdsCampaigns/CreateAdsCampaignAdvancedForm";

const answerAdvancedOrder = {
  1: "name",
  2: "objective",
  3: "trackingID",
  4: "destinationURL",
  5: "budget",
  6: "ageRange",
  7: "genders",
  8: "locations",
  9: "interests",
  10: "behaviors",
  11: "demographics",
  12: "description",
  13: "mediaUrl",
};
const stepsAdvancedCampaignMeta = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const stepsAdvancedCampaignTikTok = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13];

const answerSimpleOrderWithTracking = {
  1: "mediaUrl",
  2: "objective",
  3: "trackingID",
  4: "destinationURL",
  5: "context",
};
const answerSimpleOrderWithoutTracking = {
  1: "mediaUrl",
  2: "objective",
  3: "destinationURL",
  4: "context",
};
const stepsSimpleCampaignMetaWithTracking = [1, 2, 3, 4, 5];
const stepsSimpleCampaignMetaWithoutTracking = [1, 2, 3, 4];
const stepsSimpleCampaignTikTok = [1, 2, 3, 4, 5];

const CreateAdsCampaigns = () => {
  const [mode] = useState(ADS_CAMPAIGN_FORM_MODE_SIMPLE);
  const [params] = useSearchParams();
  const platform = params.get("platform");
  const content = params.get("content");

  const [answerOrder, setAnswerOrder] = useState([]);
  const [visibleSteps, setVisibleSteps] = useState([]);

  const [lastStep, setLastStep] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [pixels, setPixels] = useState([]);

  const LOCALE = useLocale();
  const [currencyAccount, setCurrencyAccount] = useState(LOCALE.currency);

  const { control, errors, watch, setValue, getValues } = useForm({
    defaultValues: {
      step: 1,
      name: "",
      objective: "",
      destinationURL: "",
      trackingID: null,
      platform: "",
      typeBudget: "daily",
      budget: "",
      ageRange: [20, 65],
      genders: {
        male: false,
        female: false,
      },
      locations: [],
      interests: [],
      behaviors: [],
      demographics: [],
      description: "",
      mediaUrl: "",
      context: "",
    },
  });

  const step = watch("step");
  const objective = watch("objective");
  const currentAnswer = answerOrder[step];
  const stepVisible = visibleSteps.indexOf(step) + 1;

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const navigate = useNavigate();

  const { profileInfluencer } = useSelectorApp((state) => state.shop);

  const onGetUrlForAds = async () => {
    const response = await dispatch(startGetUrlForAds());
    if (response) {
      setValue("destinationURL", response);
    }
  };

  const onGetCurrencyAccount = async () => {
    const response = await dispatch(
      startGetCurrencyPlatform({
        platform,
      })
    );
    if (response) {
      setCurrencyAccount(response);
    }
  };

  const onNextStep = async (name, value, finish = false) => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const nextStep = visibleSteps[currentStepPosition + 1];

    switch (name) {
      case "name":
        if (value.length === 0)
          return setError({
            text: t(FIELD_NAME_REQUIRED),
            step: answerOrder[step],
          });
        if (value?.length < 3) {
          return setError({
            text: t(FIELD_MIN_LENGTH, {
              value: 3,
            }),
            step: answerOrder[step],
          });
        }
        if (value?.length > 60) {
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: 60,
            }),
            step: answerOrder[step],
          });
        }
        setError(null);
        return setValue("step", nextStep);

      case "objective":
        if (value.length === 0)
          return setError({
            text: t(FIELD_OBJECTIVE_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setValue("step", nextStep);

      case "destinationURL":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_DESTINATION_URL_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (!REGEX_URL.test(value)) {
          return setError({
            text: t(FIELD_URL_VALID),
            step: answerOrder[step],
          });
        }

        const objective = getValues("objective");
        const pixelSelected = getValues("trackingID");
        const pixel = pixels.find((pixel) => pixel.id === pixelSelected);
        const hasIncluded = pixel?.urls?.some((url) => url.includes(value));

        if (!hasIncluded) {
          if (
            objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION ||
            objective === ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION
          ) {
            return setError({
              text: t(FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED),
              step: answerOrder[step],
            });
          }
        }

        setError(null);
        return setValue("step", nextStep);

      case "trackingID":
        if (!value)
          return setError({
            text: t(
              platform === META
                ? FIELD_META_TRACKING_ID_REQUIRED
                : FIELD_TIKTOK_TRACKING_ID_REQUIRED
            ),
            step: answerOrder[step],
          });
        setError(null);
        return setValue("step", nextStep);

      case "typeBudget":
        if (!value)
          return setError({
            text: t(FIELD_TYPE_BUDGET_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setValue("step", nextStep);

      case "budget":
        if (!value)
          return setError({
            text: t(FIELD_BUDGET_REQUIRED),
            step: answerOrder[step],
          });

        setError(null);
        return setValue("step", nextStep);

      case "locations":
        if (value.length === 0)
          return setError({
            text: t(FIELD_LOCATIONS_REQUIRED),
            step: answerOrder[step],
          });
        setError(null);
        return setValue("step", nextStep);

      case "genders":
        if (!Object.values(value).includes(true)) {
          return setError({
            text: t(FIELD_GENDERS_REQUIRED),
            step: answerOrder[step],
          });
        }
        setError(null);
        return setValue("step", nextStep);

      case "interests":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_INTERESTS_REQUIRED),
            step: answerOrder[step],
          });
        }
        setError(null);

        return setValue("step", nextStep);

      case "description":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_DESCRIPTION_REQUIRED),
            step: answerOrder[step],
          });
        }
        const maxLength =
          platform === META
            ? ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH
            : ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH;
        if (value.length > maxLength) {
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: maxLength,
            }),
            step: answerOrder[step],
          });
        }

        setError(null);

        return setValue("step", nextStep);

      case "context":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_DESCRIPTION_REQUIRED),
            step: answerOrder[step],
          });
        }
        if (value.length < ADS_CAMPAIGN_CONTEXT_MIN_LENGTH) {
          return setError({
            text: t(FIELD_MIN_LENGTH, {
              value: ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
            }),
            step: answerOrder[step],
          });
        }
        if (value.length > ADS_CAMPAIGN_CONTEXT_MAX_LENGTH) {
          return setError({
            text: t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
            }),
            step: answerOrder[step],
          });
        }
        setError(null);

        if (finish) return onCreateCampaign();

        return setValue("step", nextStep);

      case "mediaUrl":
        if (value.length === 0) {
          return setError({
            text: t(FIELD_CONTENT_REQUIRED),
            step: answerOrder[step],
          });
        }
        setError(null);

        return setValue("step", nextStep);

      default:
        return setValue("step", nextStep);
    }
  };
  const onPreviousStep = () => {
    const currentStepPosition = visibleSteps.indexOf(step);
    const previousStep = visibleSteps[currentStepPosition - 1];
    setValue("step", previousStep);
  };

  const onCreateCampaign = async () => {
    const formValues = getValues();

    setIsCreating(true);

    if (mode === ADS_CAMPAIGN_FORM_MODE_SIMPLE) {
      const data = await dispatch(
        startGetRecommendedSettingsAdsCampaign({
          platform: formValues.platform,
          objective: formValues.objective,
          context: formValues.context,
          trackingID: formValues.trackingID,
          destinationURL: formValues.destinationURL,
          mediaUrl: formValues.mediaUrl,
        })
      );
      if (data) {
        navigate(`/ads-campaign?campaignID=${data.id}`);
      }
    }
    if (mode === ADS_CAMPAIGN_FORM_MODE_ADVANCED) {
      const data = {
        ...formValues,
        status: ADS_CAMPAIGN_STATUS_DEACTIVATED,
      };

      delete data.step;

      let campaignID = await dispatch(startCreateAdsCampaign(data));

      if (campaignID) {
        navigate(`/ads-campaign?campaignID=${campaignID}`);
      }
    }

    setIsCreating(false);
  };

  const onGenerateDescription = async () => {
    const formValues = getValues();
    const {
      name,
      objective,
      platform,
      ageRange,
      genders,
      locations,
      interests,
      behaviors,
      demographics,
    } = formValues;

    const response = await dispatch(
      generateAdsCampaignDescription({
        name,
        objective,
        platform,
        ageRange,
        genders,
        locations,
        interests,
        behaviors,
        demographics,
      })
    );

    if (response) {
      setValue("description", response);
    }
  };

  useEffect(() => {
    if (step > lastStep) setLastStep(stepVisible);
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (platform && (platform === META || platform === TIKTOK))
      setValue("platform", platform);
    else navigate("/ads-campaigns");

    onGetUrlForAds();
    onGetCurrencyAccount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const withTracking =
      ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
      platform === META;
    const isModeSimple = mode === ADS_CAMPAIGN_FORM_MODE_SIMPLE;
    const isModeAdvanced = mode === ADS_CAMPAIGN_FORM_MODE_ADVANCED;

    if (isModeAdvanced && platform === META) {
      setAnswerOrder(answerAdvancedOrder);
      setVisibleSteps(stepsAdvancedCampaignMeta);
    }
    if (isModeAdvanced && platform === TIKTOK) {
      setAnswerOrder(answerAdvancedOrder);
      setVisibleSteps(stepsAdvancedCampaignTikTok);
    }
    if (isModeSimple && platform === META && withTracking) {
      setAnswerOrder(answerSimpleOrderWithTracking);
      setVisibleSteps(stepsSimpleCampaignMetaWithTracking);
    }
    if (isModeSimple && platform === META && !withTracking) {
      setAnswerOrder(answerSimpleOrderWithoutTracking);
      setVisibleSteps(stepsSimpleCampaignMetaWithoutTracking);
    }
    if (isModeSimple && platform === TIKTOK) {
      setAnswerOrder(answerSimpleOrderWithTracking);
      setVisibleSteps(stepsSimpleCampaignTikTok);
    }
    // eslint-disable-next-line
  }, [mode, platform, objective]);

  useEffect(() => {
    if (content) setValue("mediaUrl", content);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      profileInfluencer?.ageRange &&
      profileInfluencer?.ageRange?.length > 0
    ) {
      const ageRange = profileInfluencer?.ageRange;
      let minAge;
      let maxAge;

      ageRange.forEach((age) => {
        const ages = age.split("-").map((age) => parseInt(age));

        if (ages.includes(65)) {
          maxAge = 65;
        }

        if (!minAge || ages[0] < minAge) {
          minAge = ages[0];
        }

        if (!maxAge || ages[1] > maxAge) {
          maxAge = ages[1];
        }
      });

      if (minAge && maxAge) {
        setValue("ageRange", [minAge, maxAge]);
      }
    }
    profileInfluencer?.audienceGender &&
      setValue("genders", profileInfluencer.audienceGender);

    setIsLoading(false);
    // eslint-disable-next-line
  }, [profileInfluencer]);

  if (isLoading || answerOrder.length === 0 || visibleSteps.length === 0)
    return (
      <Loader
        size={40}
        fullWidth={true}
        message={t(LOADING_INFORMATION)}
        hasMessage={true}
      />
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 80px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pt: 3,
          px: 4,
          gap: 2,
        }}
      >
        <Select
          name="step"
          variant="outlined"
          actionsChange={(value) =>
            setValue("step", visibleSteps[value - 1] || visibleSteps[0])
          }
          label={t(QUESTION)}
          fullWidth
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
              },
            },
          }}
          rules={{}}
          sx={{
            maxWidth: 200,
          }}
          options={visibleSteps.map((_, index) => ({
            value: index + 1,
            label: t(CREATE_CAMPAIGN_QUESTION, {
              question: `N°${index + 1}`,
            }),
            isHidden: index + 1 > lastStep,
          }))}
          control={control}
          errors={errors}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {mode === ADS_CAMPAIGN_FORM_MODE_SIMPLE && (
          <CreateAdsCampaignForm
            currentAnswer={currentAnswer}
            stepVisible={stepVisible}
            error={error}
            onNextStep={onNextStep}
            onPreviousStep={onPreviousStep}
            control={control}
            getValues={getValues}
            watch={watch}
            isCreating={isCreating}
            onChangePixels={setPixels}
          />
        )}
        {mode === ADS_CAMPAIGN_FORM_MODE_ADVANCED && (
          <CreateAdsCampaignAdvancedForm
            step={step}
            currentAnswer={currentAnswer}
            stepVisible={stepVisible}
            error={error}
            onNextStep={onNextStep}
            onPreviousStep={onPreviousStep}
            setValue={setValue}
            control={control}
            getValues={getValues}
            watch={watch}
            isCreating={isCreating}
            onGenerateDescription={onGenerateDescription}
            onChangePixels={setPixels}
            currency={currencyAccount}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <LinkText
          href="https://www.heybuzzly.com/saas-terms"
          target="_blank"
          sx={{
            textAlign: "center",
          }}
        >
          {t(CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER, {
            NAME_BUSINESS,
          })}
        </LinkText>
      </Box>
    </Box>
  );
};

export default CreateAdsCampaigns;
