import React from "react";
import AnswerContainer from "../../../Common/Forms/TypeForm/AnswerContainer";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
  marksAge,
  textNumberCompactFormat,
} from "../../../../utils/slidersMarks";
import AnswerTitle from "../../../Common/Forms/TypeForm/AnswerTitle";
import { Controller } from "react-hook-form";
import { capitalizeFirstLetter } from "../../../../utils/formats";
import ActionsButtons from "../../../Common/Forms/TypeForm/ActionsButtons";
import {
  CAMPAIGN_REPOST_PLACEHOLDER_ITEM,
  CREATE_CAMPAIGN_AGE_RANGE_QUESTION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_REPOST_QUESTION,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { GREY_SLIDER_COLOR } from "../../../../utils/colors";
import SelectInfluencerCategories from "../../../Common/Selects/SelectInfluencerCategories";
import SelectCities from "../../../Common/Selects/SelectCities";

const InfluencersInformationSection = ({
  step,
  currentAnswer,
  stepVisible,
  error,
  watch,
  control,
  getValues,
  onNextStep,
  onPreviousStep,
  onGenerateScriptAndDescription,
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: step >= 16 && step <= 19 ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer stepPosition={"repost"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_REPOST_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "repost" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <FormControl fullWidth>
          {Object.keys(watch("platforms")).map((platform) => {
            if (watch("platforms")[platform] && platform !== "youtube") {
              return (
                <Controller
                  key={platform}
                  name="repost"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      margin="normal"
                      placeholder={t(CAMPAIGN_REPOST_PLACEHOLDER_ITEM, {
                        platform: capitalizeFirstLetter(platform),
                      })}
                      variant="standard"
                      value={field.value[platform] ?? ""}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          [platform]: e.target.value,
                        })
                      }
                    />
                  )}
                />
              );
            }
            return null;
          })}
        </FormControl>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("repost", getValues("repost"))}
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"audienceLocation"} step={currentAnswer}>
        <AnswerTitle>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "audienceLocation" && (
          <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <SelectCities
          name="audienceLocation"
          showAllOption={true}
          label={t(CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER)}
          fullWidth
          variant="standard"
          sx={{ mt: 1 }}
          displayEmpty
          multiple={true}
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("audienceLocation", getValues("audienceLocation"))
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"categoriesInfluencers"}
        step={currentAnswer}
      >
        <AnswerTitle>
          {`${stepVisible}. ${t(
            CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION
          )}`}
        </AnswerTitle>
        {!!error && error.step === "categoriesInfluencers" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <SelectInfluencerCategories
          name="categoriesInfluencers"
          control={control}
          rules={{}}
          variant="standard"
          fullWidth
          sx={{ my: 1 }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep(
              "categoriesInfluencers",
              getValues("categoriesInfluencers")
            )
          }
          hasAdvancedSettings={false}
          showSaveButton={false}
        />
      </AnswerContainer>
      <AnswerContainer stepPosition={"ageRange"} step={currentAnswer}>
        <AnswerTitle sx={{ mb: 2 }}>
          {`${stepVisible}. ${t(CREATE_CAMPAIGN_AGE_RANGE_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "ageRange" && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="ageRange"
          control={control}
          render={({ field }) => (
            <Slider
              sx={{
                width: "calc(100% - 24px)",
                ml: 1,
                ".MuiSlider-track": {
                  height: 2,
                },
                ".MuiSlider-rail": {
                  backgroundColor: GREY_SLIDER_COLOR,
                  height: 4,
                },
                ".MuiSlider-mark": {
                  backgroundColor: "transparent",
                },
              }}
              getAriaLabel={() => "Type Influencers Campaign"}
              valueLabelDisplay="auto"
              getAriaValueText={textNumberCompactFormat}
              step={1}
              marks={marksAge}
              min={18}
              max={60}
              valueLabelFormat={textNumberCompactFormat}
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("ageRange", getValues("ageRange"))}
          onFinish={onGenerateScriptAndDescription}
          hasAdvancedSettings={true}
          showSaveButton={true}
          showNextButton={false}
        />
      </AnswerContainer>
    </Box>
  );
};

export default InfluencersInformationSection;
