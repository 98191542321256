import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelectorApp } from "../lib/redux";

export const RedirectContext = createContext();

const privateRoutes = [
  "/redeem-code",
  "/delivery",
  "/menu",
  "/creators",
  "/ambassadors",
  "/requests-influencers",
  "/invitations",
  "/publications",
  "/publication",
  "/campaigns",
  "/campaign",
  "/create-campaign",
  "/content-applicant",
  "/events",
  "/event",
  "/create-event",
  "/account",
  "/billing-data",
  "/billing-and-payments",
  "/notifications",
  "/users",
  "/stores",
  "/influencer",
  "/discovery-influencers",
  "/reports",
  "/users-accounts",
  "/influencers-accounts",
  "/creator-signups",
  "/brands",
  "/brand-signups",
  "/shop",
  "/pay-creators",
  "/contents",
  "/deliveries-owner",
  "/content",
  "/statistics",
  "/feedback",
  "/payments-failed",
];

export const RedirectProvider = ({ children }) => {
  const [firstUrl, setFirstUrl] = useState();
  const [urlRedirect, setUrlRedirect] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading, user } = useSelectorApp((state) => state.auth);
  useEffect(() => {
    let queryParams = location.search;
    const hashParams = location.hash;
    let url = location.pathname;

    if (url === "/login") {
      url = "";
    }

    if (queryParams.includes("redirect")) {
      queryParams = queryParams.replace(/redirect=[^&]+&?/, "");
    }

    if (queryParams === "?") queryParams = "";

    setFirstUrl(url);
    setUrlRedirect(encodeURIComponent(`${url}${queryParams}${hashParams}`));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (!user && urlRedirect && privateRoutes.includes(firstUrl)) {
      return navigate(`${location.pathname}?redirect=${urlRedirect}`);
    }

    if (user && urlRedirect) {
      setUrlRedirect("");
      return navigate(decodeURIComponent(urlRedirect));
    }
    // eslint-disable-next-line
  }, [isLoading, urlRedirect, user]);

  return (
    <RedirectContext.Provider value={{}}>{children}</RedirectContext.Provider>
  );
};
