import React, { useEffect, useState } from "react";
import {
  FIELD_REQUIRED,
  SELECT_A_OPTION,
  SELECT_UGC_CONTENT_FORMAT,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import { Controller } from "react-hook-form";
import SelectMUI from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { optionsUGCFormats } from "../../../utils/slidersMarks";
import { useTranslationApp } from "../../../lib/i18next";
import TooltipModal from "../Tooltips/TooltipModal";
import ContentUGCContentFormat from "../Tooltips/Content/ContentUGCContentFormat";
import Loader from "../Loader/Loader";

const SelectUGCContentFormat = ({
  variant = "filled",
  label = i18next.t(SELECT_UGC_CONTENT_FORMAT),
  placeholder = "",
  name,
  value,
  disabled = false,
  displayEmpty = false,
  showEmptyOption = false,
  fullWidth = false,
  idLabel = `select-${name}`,
  options = optionsUGCFormats,
  doTranslate = false,
  isRounded = false,
  backgroundColor = "",
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8 * 2,
        width: 250,
      },
    },
  },
  sx = {},
  onChange = () => {},
  actionsChange = () => {},
  withController = true,
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  showAddAction = false,
  onAddAction = () => {},
  labelAddAction = "",
}) => {
  const [newOption, setNewOption] = useState("");
  const [optionsState, setOptionsState] = useState(options);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [errorFieldNewOption, setErrorFieldNewOption] = useState(null);

  const { t } = useTranslationApp();

  const addAction = (newOption) => {
    //verify if the new option already exists
    const exists = optionsState.find(
      (option) => option.value === newOption || option.label === newOption
    );

    if (exists) {
      setErrorFieldNewOption(null);
      return false;
    }
    setErrorFieldNewOption(null);

    let newOptions = [...optionsState];

    newOptions.unshift({
      value: newOption,
      label: newOption,
      isCustom: true,
    });

    setOptionsState(newOptions);
    onAddAction(newOption);
    setNewOption("");
    return true;
  };

  useEffect(() => {
    const some = optionsState.some(
      (option) => option.value === value || option.label === value
    );
    if (!some) {
      setOptionsState((prev) => [
        {
          value,
          label: value,
          isCustom: true,
        },
        ...prev,
      ]);
    }
    setIsLoadingOptions(false);
    // eslint-disable-next-line
  }, []);

  if (isLoadingOptions) return <Loader />;

  if (withController) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={value}
        render={({ field }) => (
          <FormControl
            fullWidth={fullWidth}
            variant={variant}
            error={!!errors[name]}
            disabled={disabled}
            sx={{
              ...sx,
            }}
          >
            {label && <InputLabel id={idLabel}>{label}</InputLabel>}
            <SelectMUI
              placeholder={placeholder}
              labelId={idLabel}
              label={label}
              displayEmpty={displayEmpty}
              {...field}
              ref={field.ref}
              inputRef={field.ref}
              MenuProps={MenuProps}
              onChange={(e) => {
                field.onChange(e);
                actionsChange(e.target.value);
              }}
              sx={{
                borderRadius: isRounded ? 4 : 1,
                backgroundColor,
                "& .MuiSelect-select": {
                  "& .tooltip-modal": {
                    display: "none",
                  },
                },
              }}
            >
              {showEmptyOption && (
                <MenuItem disabled value="">
                  <em>{t(SELECT_A_OPTION)}</em>
                </MenuItem>
              )}
              {showAddAction && (
                <Box
                  sx={{ px: 2, py: 1 }}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <TextField
                    label={t(labelAddAction)}
                    error={!!errorFieldNewOption}
                    helperText={errorFieldNewOption}
                    variant={variant}
                    name="newOption"
                    value={newOption}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        addAction(newOption);
                      }
                    }}
                    onChange={(e) => setNewOption(e.target.value)}
                    fullWidth
                  />
                </Box>
              )}
              {optionsState.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {doTranslate && !option.isCustom
                    ? t(option.label)
                    : option.label}
                  {option.explanation && (
                    <TooltipModal
                      title={t(option.label)}
                      content={
                        <ContentUGCContentFormat text={t(option.explanation)} />
                      }
                      stopPropagation={true}
                    />
                  )}
                </MenuItem>
              ))}
            </SelectMUI>
            {!!errors[name] && (
              <Typography variant="caption" color="error">
                {errors[name]?.message}
              </Typography>
            )}
          </FormControl>
        )}
      />
    );
  }
  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      error={!!errors[name]}
      disabled={disabled}
      sx={{
        ...sx,
      }}
    >
      {label && <InputLabel id={idLabel}>{label}</InputLabel>}
      <SelectMUI
        placeholder={placeholder}
        labelId={idLabel}
        name={name}
        label={label}
        value={value}
        displayEmpty={displayEmpty}
        MenuProps={MenuProps}
        onChange={(e) => {
          onChange(e);
          actionsChange(e.target.value);
        }}
        sx={{
          borderRadius: isRounded ? 4 : 1,
          backgroundColor,
          "& .MuiSelect-select": {
            "& .tooltip-modal": {
              display: "none",
            },
          },
        }}
      >
        {showEmptyOption && (
          <MenuItem disabled value="">
            <em>{t(SELECT_A_OPTION)}</em>
          </MenuItem>
        )}
        {showAddAction && (
          <Box sx={{ px: 2, py: 1 }} onKeyDown={(e) => e.stopPropagation()}>
            <TextField
              label={t(labelAddAction)}
              error={!!errorFieldNewOption}
              helperText={errorFieldNewOption}
              variant={variant}
              name="newOption"
              value={newOption}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  addAction(newOption);
                }
              }}
              onChange={(e) => setNewOption(e.target.value)}
              fullWidth
            />
          </Box>
        )}
        {optionsState.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {doTranslate && !option.isCustom ? t(option.label) : option.label}
            {option.explanation && (
              <TooltipModal
                title={t(option.label)}
                content={
                  <ContentUGCContentFormat text={t(option.explanation)} />
                }
                stopPropagation={true}
              />
            )}
          </MenuItem>
        ))}
      </SelectMUI>
      {!!errors[name] && (
        <Typography variant="caption" color="error">
          {errors[name]?.message}
        </Typography>
      )}
    </FormControl>
  );
};

export default SelectUGCContentFormat;
