import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import TitlePage from "../components/Common/Texts/TitlePage";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import {
  startDeleteAmbassador,
  startGetAmbassadorsByShop,
} from "../actions/ambassadors";
import { DEFAULT_TABLE_PAGES } from "../utils/constants";
import { usePagination } from "../hooks/usePagination";
import AmbassadorsTable from "../components/User/Ambassadors/AmbassadorsTable";
import { startOpenAmbassadorConversation } from "../actions/chat";
import ModalAmbassador from "../components/User/Ambassadors/ModalAmbassador";
import {
  AMBASSADORS_BROADCAST_BUTTON,
  AMBASSADORS_PAYMENTS_BUTTON,
  PAGE_AMBASSADORS,
} from "../onboarding/stepsSelectors";
import Button from "../components/Common/Buttons/Button";
import ModalBroadcast from "../components/Common/Chat/ModalBroadcast";
import ModalPayAmbassadors from "../components/User/Ambassadors/ModalPayAmbassadors";
import { useTour } from "@reactour/tour";
import { STEPS_AMBASSADORS_FUNCTIONALITY } from "../onboarding/steps";
import { useTranslationApp } from "../lib/i18next";
import {
  AMBASSADORS_DESCRIPTION,
  AMBASSADORS_TITLE,
  BUTTON_BROADCAST,
  BUTTON_PAYMENTS,
} from "../locales/keysTranslations";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContainerPage from "../components/Common/Containers/ContainerPage";
import _ from "lodash";

const Ambassadors = () => {
  const [data, setData] = useState({});
  const [ambassadorSelected, setAmbassadorSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalAmbassadorOpen, setModalAmbassadorOpen] = useState(false);
  const [modalBroadcastOpen, setModalBroadcastOpen] = useState(false);
  const [modalPayAmbassadorsOpen, setModalPayAmbassadorsOpen] = useState(false);

  const [params] = useSearchParams();
  const ambassadorID = params.get("ambassadorID");

  const { ambassadors } = useSelectorApp((state) => state.ambassadors);
  const { user } = useSelectorApp((state) => state.auth);

  const { t } = useTranslationApp();

  const { setIsOpen, setSteps } = useTour();

  const navigate = useNavigate();
  const dispatch = useDispatchApp();

  const getNextAmbassadors = async () => {
    await dispatch(
      startGetAmbassadorsByShop({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(
      startGetAmbassadorsByShop({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
        onCallbackFinish: () => setLoading(false),
      })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextAmbassadors,
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    if (ambassadorID) {
      const ambassador = data[ambassadorID];
      if (!ambassador) return;
      setAmbassadorSelected(ambassadorID);
      onChangeStatusModalAmbassador(true);
      navigate(`/ambassadors`);
    }
    // eslint-disable-next-line
  }, [ambassadorID, data]);

  useEffect(() => {
    if (_.isEmpty(ambassadors)) return;
    let filtered = {};
    Object.keys(ambassadors).forEach((key) => {
      filtered[key] = {
        ...ambassadors[key],
        ambassadorID: key,
      };
    });
    setData(filtered);
    // eslint-disable-next-line
  }, [ambassadors]);

  const onChangeStatusModalAmbassador = (status) =>
    setModalAmbassadorOpen(status);

  const onChangeStatusModalBroadcast = (status) =>
    setModalBroadcastOpen(status);

  const onChangeStatusModalPayAmbassador = (status) =>
    setModalPayAmbassadorsOpen(status);

  const onOpenChatAmbassador = (ambassadorID) => {
    const ambassador = data[ambassadorID];
    dispatch(
      startOpenAmbassadorConversation({
        chatID: ambassador.chatID,
        userID: ambassador.userID,
      })
    );
  };

  const onDeleteAmbassador = async (ambassadorID) =>
    await dispatch(startDeleteAmbassador(ambassadorID));

  return (
    <BlockPlanNoActive>
      <ContainerPage>
        <TitlePage
          hasTooltip={true}
          toolTipAction={() => {
            setSteps(STEPS_AMBASSADORS_FUNCTIONALITY);
            setIsOpen(true);
          }}
          dataTour={PAGE_AMBASSADORS}
        >
          {t(AMBASSADORS_TITLE)}
        </TitlePage>
        <DescriptionPage>{t(AMBASSADORS_DESCRIPTION)}</DescriptionPage>
        <Box
          sx={{
            width: "100%",
            mb: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            onClick={() => onChangeStatusModalPayAmbassador(true)}
            dataTour={AMBASSADORS_PAYMENTS_BUTTON}
          >
            {t(BUTTON_PAYMENTS)}
          </Button>
          <Button
            onClick={() => onChangeStatusModalBroadcast(true)}
            dataTour={AMBASSADORS_BROADCAST_BUTTON}
          >
            {t(BUTTON_BROADCAST)}
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <AmbassadorsTable
            data={data}
            loading={loading}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            onSelectAmbassador={(ambassadorID) => {
              setAmbassadorSelected(ambassadorID);
              onChangeStatusModalAmbassador(true);
            }}
            onOpenChatAmbassador={onOpenChatAmbassador}
            onDeleteAmbassador={onDeleteAmbassador}
          />
        </Box>
      </ContainerPage>
      {ambassadorSelected && (
        <ModalAmbassador
          modalOpen={modalAmbassadorOpen}
          onCloseModal={() => {
            setAmbassadorSelected(null);
            onChangeStatusModalAmbassador(false);
          }}
          data={data[ambassadorSelected]}
        />
      )}
      {modalBroadcastOpen && (
        <ModalBroadcast
          modalOpen={modalBroadcastOpen}
          onCloseModal={() => onChangeStatusModalBroadcast(false)}
        />
      )}
      {modalPayAmbassadorsOpen && (
        <ModalPayAmbassadors
          modalOpen={modalPayAmbassadorsOpen}
          onCloseModal={() => onChangeStatusModalPayAmbassador(false)}
        />
      )}
    </BlockPlanNoActive>
  );
};

export default Ambassadors;
