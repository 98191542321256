import React from "react";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import KanbanCard from "./KanbanCard";
import KanbanTabs from "./KanbanTabs";
import OrdenModal from "./OrdenModal";
import {
  STATUS_IN_PREPARATION,
  STATUS_TO_BE_ACCEPTED,
  STATUS_TO_BE_DELIVERED,
} from "../../../onboarding/stepsSelectors";

const KanbanDelivery = ({
  data,
  setData,
  ordenSelected,
  setOrdenSelected,
  onLaunchSound,
  onStopSoundState,
  storeSelected,
}) => {
  const onCloseModal = () => {
    setOrdenSelected(null);
  };

  const matches = useMediaQuery("(min-width:1200px)");

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={matches ? 4 : 0}
      sx={{
        mt: 2,
        ml: 0,
        pb: 2,
        pr: {
          lg: 4,
        },
        width: "100%",
        height: "100%",
      }}
    >
      {matches ? (
        <>
          <Grid item md={4}>
            <KanbanCard
              data={data?.toBeAccepted}
              ordenSelected={ordenSelected}
              setData={setData}
              setOrdenSelected={setOrdenSelected}
              type="toBeAccepted"
              storeSelected={storeSelected}
              dataTour={STATUS_TO_BE_ACCEPTED}
            />
          </Grid>
          <Grid item md={4}>
            <KanbanCard
              data={data?.inPreparation}
              ordenSelected={ordenSelected}
              setData={setData}
              setOrdenSelected={setOrdenSelected}
              type="inPreparation"
              onLaunchSound={onLaunchSound}
              storeSelected={storeSelected}
              dataTour={STATUS_IN_PREPARATION}
            />
          </Grid>
          <Grid item md={4}>
            <KanbanCard
              data={data?.toBeDelivered}
              ordenSelected={ordenSelected}
              setData={setData}
              setOrdenSelected={setOrdenSelected}
              type="toBeDelivered"
              storeSelected={storeSelected}
              dataTour={STATUS_TO_BE_DELIVERED}
            />
          </Grid>
        </>
      ) : (
        <KanbanTabs
          data={data}
          onStopSound={onStopSoundState}
          ordenSelected={ordenSelected}
          setData={setData}
          setOrdenSelected={setOrdenSelected}
          storeSelected={storeSelected}
          onLaunchSound={onLaunchSound}
        />
      )}
      {!!ordenSelected && (
        <OrdenModal
          allData={data}
          data={ordenSelected.data}
          onCloseModal={onCloseModal}
          setAllData={setData}
          setData={setOrdenSelected}
          type={ordenSelected.type}
          storeSelected={storeSelected}
          onStopSound={onStopSoundState}
        />
      )}
    </Grid>
  );
};

export default KanbanDelivery;
