import React, { useState } from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import { useForm } from "react-hook-form";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "../Fields/TextField";
import { REGEX_EMAIL } from "../../../utils/regexsValidation";
import LoadingButton from "../Buttons/LoadingButton";
import { SimpleAlert } from "../../../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../../../utils/constants";
import { startAddStaff } from "../../../actions/owner";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";

const ModalStaff = ({
  modalOpen,
  onCloseModal,
  data,
  staffID,
  mode = "create",
  onCallbackCreate = () => {},
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const user = useSelectorApp((state) => state.auth.user);

  const staffList = useSelectorApp((state) => state.shop.staff);

  const dispatch = useDispatchApp();

  const { control, watch, handleSubmit, formState } = useForm({
    defaultValues: {
      name: data.name || "",
      email: data.email || "",
      country: data.country || user.country || "co",
      password: "",
      repeatPassword: "",
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const password = watch("password");
  const repeatPassword = watch("repeatPassword");

  const onSubmit = async (staff) => {
    const found = staffList.find(
      (c) => c.name.toLowerCase() === staff.name.toLowerCase()
    );
    if (found) {
      SimpleAlert({
        title: "Error",
        text: "Un staff con este nombre ya existe",
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }

    setIsFetching(true);
    const staffData = await dispatch(startAddStaff(staff));
    setIsFetching(false);

    if (!staffData) return false;

    onCallbackCreate(staffData);

    onCloseModal();

    return true;
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      title={mode === "create" ? "Crear staff" : `Staff ${data.name}`}
    >
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)();
          e.stopPropagation();
        }}
      >
        <Grid item xs={12}>
          <TextField
            variant="filled"
            name="name"
            label="Nombre"
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            name="email"
            label="Correo electrónico"
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: "Este campo es requerido",
              },
              validate: {
                email: (value) => {
                  if (!REGEX_EMAIL.test(value)) {
                    return "El correo electrónico no es válido";
                  }
                },
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            name="password"
            label="Contraseña"
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: "Este campo es requerido",
              },
              validate: {
                password: (value) => {
                  if (value.length < 6) {
                    return "La contraseña debe tener mínimo 6 caracteres";
                  }
                  if (value !== repeatPassword) {
                    return "Las contraseñas no coinciden";
                  }
                },
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            name="repeatPassword"
            label="Repetir Contraseña"
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: "Este campo es requerido",
              },
              validate: {
                password: (value) => {
                  if (value.length < 6) {
                    return "La contraseña debe tener mínimo 6 caracteres";
                  }
                  if (value !== password) {
                    return "Las contraseñas no coinciden";
                  }
                },
              },
            }}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              width: "100%",
            }}
          >
            <LoadingButton loading={isFetching} color="primary">
              Cancelar
            </LoadingButton>
            <LoadingButton loading={isFetching} color="secondary">
              Crear Staff
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </ModalBasicLayout>
  );
};

export default ModalStaff;
