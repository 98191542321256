import {
  equalTo,
  get,
  limitToFirst,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  startAfter,
  update,
} from "firebase/database";
import axios from "../lib/axios";
import types from "../types";
import {
  formatMessagesToChatBot,
  getObjectError,
  removeContactInfoFromString,
} from "../utils/formats";
import { renewToken } from "./auth";
import {
  getInfluencerRedux,
  getNameBusinessTheme,
  getRole,
  getShopID,
  getUserID,
} from "./getters";
import { db, firestore } from "../firebase";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  CHAT_BOT_USER_ID,
  CHAT_FROM_AMBASSADOR,
  CHAT_FROM_CAMPAIGN,
  CHAT_FROM_INFLUENCERS,
  CHAT_FROM_PUBLICATION,
  CHAT_SUB_FROM_ADVERTISER,
  CHAT_SUB_FROM_SHOP,
  PRE_REGISTERED_INFLUENCER_CHAT_PROCESS,
  ROLES_ADMIN_SHOPS,
  ROLES_DASHBOARD,
} from "../utils/constants";
import { startAssignChatIDAmbassador } from "./ambassadors";
import {
  getNextInfluencers,
  startGeneratePreRegisteredInfluencerExecution,
  startGetInfluencersByUserIDs,
} from "./influencers";
import { startAssignChatIDOrder } from "./orders";
import { startAssignChatIDApplicant } from "./campaigns";
import i18next from "i18next";
import {
  CHAT_FIRST_MESSAGE_BOT,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  NAME_BUSINESS_BOT,
  RATE_DAILY_LIMIT_AI,
  RATE_DAILY_LIMIT_AI_CHAT,
} from "../locales/keysTranslations";
import {
  collection,
  getDocs,
  where,
  query as queryFS,
} from "firebase/firestore";
import _ from "lodash";
import { verifyIsErrorPermissionDenied } from "../utils/errors";
import { startGetShopsByShopIDs } from "./shops";
import { startGetAdvertisersByID } from "./advertisers";

const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const getPushKeyChat = () => (dispatch) => {
  const shopID = dispatch(getShopID());
  return push(ref(db, `chats/${shopID}`)).key;
};

export const startGetChatsByIDS =
  ({ chatsIDS = [] }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      chatsIDS = chatsIDS.filter((item, index) => {
        return chatsIDS.indexOf(item) === index;
      });
      const queriesChats = [];
      chatsIDS.forEach((chatID) => {
        queriesChats.push(get(ref(db, `chats/${chatID}`)));
      });
      const chatsSnapshot = await Promise.all(queriesChats);
      let chats = {};

      const role = dispatch(getRole());

      chatsSnapshot.forEach((snap) => {
        if (!snap.exists()) return;
        const chat = snap.val();
        const userID = chat?.users?.filter((item) => item !== shopID);
        chats[snap.key] = {
          ...chat,
          userID: userID[0],
        };
      });

      const userIDs = [];
      const shopIDs = [];
      const advertiserIDs = [];

      Object.keys(chats).forEach((chatID) => {
        const chat = chats[chatID];

        if (chat.from === "help" && chat.subFrom) {
          if (ROLES_ADMIN_SHOPS.includes(role)) return;

          if (chat.subFrom === CHAT_SUB_FROM_ADVERTISER) {
            advertiserIDs.push(chat.userID);
          }
          if (chat.subFrom === CHAT_SUB_FROM_SHOP) {
            shopIDs.push(chat.userID);
          }
        } else {
          userIDs.push(chat.userID);
        }
      });

      if (advertiserIDs.length > 0) {
        await dispatch(
          startGetAdvertisersByID({
            advertiserIDs,
          })
        );
      }
      if (shopIDs.length > 0) {
        await dispatch(
          startGetShopsByShopIDs({
            shopIDs,
          })
        );
      }
      if (userIDs.length > 0) {
        await dispatch(
          startGetInfluencersByUserIDs({ userIDs, getIsDiscarded: false })
        );
      }
      dispatch(getAllChats(chats));

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAllChats =
  (onReferenceAvailable, from = "shop") =>
  async (dispatch) => {
    try {
      const ID =
        from === "shop" ? dispatch(getShopID()) : dispatch(getUserID());

      const dbRef = query(
        ref(db, `chatsIDS/${ID}`),
        orderByChild("lastMessageDate"),
        limitToFirst(20)
      );
      onValue(dbRef, async (snapshot) => {
        const data = snapshot.val() ?? {};
        const chatsIDS = Object.keys(data);
        await dispatch(startGetChatsByIDS({ chatsIDS }));
      });
      onReferenceAvailable(dbRef);
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getAllChats = (data) => ({
  type: types.SET_CHATS,
  payload: data,
});
export const startGetNextChats =
  ({ from = "shop", limit, onChangeLastKey, lastKey, loadLastItem }) =>
  async (dispatch, getState) => {
    try {
      const ID =
        from === "shop" ? dispatch(getShopID()) : dispatch(getUserID());

      if (!lastKey) {
        const chats = getState().chat.chats;

        const chatsSorted = Object.keys(chats ?? {}).sort(
          (chatID1, chatID2) =>
            chats[chatID2].lastMessageDate - chats[chatID1].lastMessageDate
        );

        const newLastKey =
          chats[chatsSorted[chatsSorted.length - 1]].lastMessageDate * -1; // -1 negative time for order DESC

        onChangeLastKey(newLastKey);
        lastKey = newLastKey;
      }

      let q = lastKey
        ? query(
            ref(db, `chatsIDS/${ID}`),
            orderByChild("lastMessageDate"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `chatsIDS/${ID}`),
            orderByChild("lastMessageDate"),
            limitToFirst(limit)
          );

      const snapshot = await get(q);

      if (!snapshot.exists()) return loadLastItem();

      const chats = snapshot.val();
      const chatsIDS = Object.keys(chats ?? {});

      onChangeLastKey(chats[chatsIDS[0]].lastMessageDate);

      await dispatch(startGetChatsByIDS({ chatsIDS }));

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startSetFirstMessageChatBot = () => async (dispatch, getState) => {
  try {
    const chatsMessages = getState().chat.chatsMessages[CHAT_BOT_USER_ID];
    const keysMessages = Object.keys(chatsMessages || {});
    if (keysMessages.length > 0) return true;

    const NAME_BUSINESS = dispatch(getNameBusinessTheme());

    const messageObject = {
      message: i18next.t(CHAT_FIRST_MESSAGE_BOT),
      sender: i18next.t(NAME_BUSINESS_BOT, {
        NAME_BUSINESS,
      }),
      direction: "incoming",
      role: "receiver",
      creationTime: new Date().getTime(),
    };

    const chat = {
      userID: CHAT_BOT_USER_ID,
      chatID: CHAT_BOT_USER_ID,
      lastMessage: messageObject.message,
      lastSender: CHAT_BOT_USER_ID,
    };

    dispatch(
      setNewChat({
        chatID: CHAT_BOT_USER_ID,
        chat,
      })
    );
    dispatch(
      addMessageChat({
        message: messageObject,
        messageID: 123123,
        chatID: CHAT_BOT_USER_ID,
        addToCurrentChat: true,
      })
    );

    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};

export const startSendMessageToChatBot = (messages) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const shopID = dispatch(getShopID());
    const newMessagesFormatted = formatMessagesToChatBot({ messages, shopID });
    const { data } = await axios({
      method: "post",
      url: `${url}/sendMessageChatBot`,
      data: {
        messages: newMessagesFormatted,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.ok) {
      return data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    if (errorFormatted.code === RATE_DAILY_LIMIT_AI) {
      return i18next.t(RATE_DAILY_LIMIT_AI_CHAT);
    }
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startOpenAmbassadorConversation =
  ({ chatID = "", userID }) =>
  async (dispatch, getState) => {
    try {
      let chat = getState().chat.chats[chatID];
      if (!chat) {
        const chatIDLoaded = await dispatch(startGetChatByUserID(userID));
        if (!chatIDLoaded) {
          const chat = {
            userID,
            from: CHAT_FROM_AMBASSADOR,
            isTemporary: true,
          };
          dispatch(setCurrentChat(chat));
          dispatch(openChat());
          return false;
        }

        chatID = chatIDLoaded;
        chat = getState().chat.chats[chatID];
      }
      dispatch(setCurrentChat(chat));
      dispatch(openChat());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startOpenPublicationConversation =
  ({ chatID = "", userID, orderID }) =>
  async (dispatch, getState) => {
    try {
      let chat = getState().chat.chats[chatID];
      if (!chat) {
        const chatIDLoaded = await dispatch(startGetChatByUserID(userID));
        if (!chatIDLoaded) {
          const chat = {
            userID,
            from: CHAT_FROM_PUBLICATION,
            orderID,
            isTemporary: true,
          };
          dispatch(setCurrentChat(chat));
          dispatch(openChat());
          return true;
        }

        chatID = chatIDLoaded;
        chat = getState().chat.chats[chatID];
      }
      dispatch(setCurrentChat(chat));
      dispatch(openChat());
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startOpenChatBotConversation = () => async (dispatch) => {
  try {
    const chat = {
      userID: CHAT_BOT_USER_ID,
      chatID: CHAT_BOT_USER_ID,
    };
    dispatch(setCurrentChat(chat));
    dispatch(openChat());
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
};
export const startOpenInfluencerConversation =
  ({ userID, chatID }) =>
  async (dispatch, getState) => {
    try {
      let chat = getState().chat.chats[chatID];
      if (!chat) {
        let chatIDLoaded;

        if (userID) {
          chatIDLoaded = await dispatch(startGetChatByUserID(userID));
        }

        if (!chatIDLoaded) {
          const chat = {
            userID,
            from: CHAT_FROM_INFLUENCERS,
            isTemporary: true,
          };
          dispatch(setCurrentChat(chat));
          dispatch(openChat());
          return true;
        }

        chatID = chatIDLoaded;
        chat = getState().chat.chats[chatID];
      }
      dispatch(setCurrentChat(chat));
      dispatch(openChat());
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startOpenApplicantConversation =
  ({ chatID = "", userID, applicantID }) =>
  async (dispatch, getState) => {
    try {
      let chat = getState().chat.chats[chatID];

      if (!chat) {
        const chatIDLoaded = await dispatch(startGetChatByUserID(userID));
        if (!chatIDLoaded) {
          const chat = {
            userID,
            from: CHAT_FROM_CAMPAIGN,
            applicantID,
            isTemporary: true,
          };
          dispatch(setCurrentChat(chat));
          dispatch(openChat());
          return false;
        }

        chatID = chatIDLoaded;
        chat = getState().chat.chats[chatID];
      }

      dispatch(setCurrentChat(chat));
      dispatch(openChat());
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const setNewChat = (data) => ({
  type: types.SET_NEW_CHAT,
  payload: data,
});

export const startGetMessagesCurrentChat =
  ({ chatID = "", onReferenceAvailable }) =>
  async (dispatch, getState) => {
    try {
      if (!chatID) return false;
      const dbRef = ref(db, `chatsMessages/${chatID}`);
      onValue(dbRef, (snapshot) => {
        if (!snapshot.exists()) {
          dispatch(
            setCurrentChatMessages({
              messages: {},
              chatID,
            })
          );
        } else {
          const messages = snapshot.val();
          dispatch(
            setCurrentChatMessages({
              messages,
              chatID,
            })
          );
        }
      });
      const chats = getState().chat.chats;
      dispatch(setCurrentChat(chats[chatID]));
      onReferenceAvailable(dbRef);
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const setCurrentChat = (currentChat) => (dispatch) => {
  dispatch(clearTemporaryChats());
  dispatch({
    type: types.SET_CURRENT_CHAT,
    payload: currentChat,
  });
};
export const setChatBotDefault = (currentChat) => (dispatch) => {
  dispatch(clearTemporaryChats());
  dispatch({
    type: types.SET_CHAT_BOT_DEFAULT,
  });
};
export const setCurrentChatMessages = (currentMessages) => ({
  type: types.SET_CURRENT_CHAT_MESSAGES,
  payload: currentMessages,
});

export const startSendMessageChat =
  ({ chatID, message, userID, addToCurrentChat = true }) =>
  async (dispatch, getState) => {
    try {
      if (chatID === CHAT_BOT_USER_ID) {
        dispatch(
          addMessageChat({
            message: message,
            messageID: new Date().getTime(),
            chatID,
            addToCurrentChat,
          })
        );
        return message;
      }
      const shopID = dispatch(getShopID());
      const role = dispatch(getRole());

      if (!chatID) {
        chatID = await dispatch(startGetChatByUserID(userID));
      }

      const influencer = dispatch(getInfluencerRedux(userID));
      const isExternal = influencer?.isExternal;
      const actionsExternal = [];

      const isFirstMessage = !chatID;

      const chatData = getState().chat.currentChat;

      const now = new Date().getTime();

      const updates = {};

      if (isFirstMessage) {
        chatID = push(ref(db, "chats")).key;
        const path = `chats/${chatID}`;
        const pathData = {
          chatID: chatID,
          users: [shopID, userID],
          from: chatData.from || "influencers",
          orderID:
            chatData.from === CHAT_FROM_PUBLICATION ? chatData.orderID : null,
          applicantID:
            chatData.from === CHAT_FROM_CAMPAIGN ? chatData.applicantID : null,
        };
        updates[path] = pathData;
        actionsExternal.push({
          path,
          data: pathData,
          db: "realtime",
        });
      }

      const messageID = push(ref(db, `chatsMessages/${chatID}`)).key;

      const pathMessage = `chatsMessages/${chatID}/${messageID}`;
      const messageObject = {
        ...message,
        message: ROLES_DASHBOARD.includes(role)
          ? message.message
          : removeContactInfoFromString(message.message),
        creationTime: now,
      };

      updates[pathMessage] = messageObject;
      actionsExternal.push({
        path: pathMessage,
        data: messageObject,
        db: "realtime",
      });

      const chat = {
        userID,
        chatID: chatID,
        lastMessage: messageObject.message,
        lastSender: messageObject.userID,
      };

      if (isExternal) {
        const response = await dispatch(
          startGeneratePreRegisteredInfluencerExecution({
            userID,
            mainAccountName: influencer.mainAccountName,
            mainPlatform: influencer.mainPlatform,
            imageURL: influencer.imageURL,
            actions: actionsExternal,
            withActions: true,
            type: PRE_REGISTERED_INFLUENCER_CHAT_PROCESS,
          })
        );
        if (!response) return false;
      } else {
        await update(ref(db), updates);
      }

      if (isFirstMessage) {
        dispatch(
          setNewChat({
            chatID: chatID,
            chat,
          })
        );
        if (chatData.from === CHAT_FROM_AMBASSADOR) {
          dispatch(
            startAssignChatIDAmbassador({
              ambassadorID: `${shopID}-${userID}`,
              chatID,
            })
          );
        }
        if (chatData.from === CHAT_FROM_PUBLICATION) {
          dispatch(
            startAssignChatIDOrder({
              orderID: chatData.orderID,
              chatID,
            })
          );
        }
        if (chatData.from === CHAT_FROM_CAMPAIGN) {
          dispatch(
            startAssignChatIDApplicant({
              applicantID: chatData.applicantID,
              chatID,
            })
          );
        }
      }
      dispatch(
        addMessageChat({
          message: messageObject,
          messageID,
          chatID,
          addToCurrentChat,
        })
      );

      return {
        [messageID]: messageObject,
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startSendBroadcastMessageChat =
  ({ message, chatsIDs, file }) =>
  async (dispatch) => {
    try {
      const senderID = dispatch(getShopID());
      const role = dispatch(getRole());

      const now = new Date().getTime();

      const updates = {};

      const messagesIDs = [];

      chatsIDs.forEach((chatID) => {
        const messageID = push(ref(db, `chatsMessages/${chatID}`)).key;

        const messageObject = {
          creationTime: now,
          message: ROLES_DASHBOARD.includes(role)
            ? message
            : removeContactInfoFromString(message),
          userID: senderID,
          messageType: file ? "media" : "text",
          mediaURL: file,
        };

        updates[`chatsMessages/${chatID}/${messageID}`] = messageObject;

        messagesIDs.push({
          chatID,
          messageID,
          messageObject,
        });
      });

      await update(ref(db), updates);

      messagesIDs.forEach((messageData) => {
        dispatch(
          addMessagesChat({
            message: messageData.messageObject,
            messageID: messageData.messageID,
            chatID: messageData.chatID,
          })
        );
      });
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const addMessageChat = (data) => ({
  type: types.ADD_MESSAGE_CHAT,
  payload: data,
});
export const addMessagesChat = (data) => ({
  type: types.ADD_MESSAGES_CHAT,
  payload: data,
});
export const addMessageChatInList = (data) => ({
  type: types.ADD_MESSAGE_CHAT_IN_LIST,
  payload: data,
});

export const startGetChatByUserID = (userID) => async (dispatch, getState) => {
  try {
    const chats = getState().chat.chats;

    const chatIDFound = Object.keys(chats).find((chatID) => {
      const chat = chats[chatID];
      return chat?.users?.includes(userID);
    });

    if (chatIDFound) {
      return chatIDFound;
    }

    const shopID = dispatch(getShopID());
    const dbRef = query(
      ref(db, `chatsIDS/${shopID}`),
      orderByChild("receiver"),
      equalTo(userID)
    );

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();
    const chatID = Object.keys(data)[0];

    const snapshotChat = await get(ref(db, `chats/${chatID}`));

    const chatInfo = snapshotChat.val();

    const chat = {
      ...chatInfo,
      userID,
    };

    dispatch(
      getAllChats({
        [chatID]: chat,
      })
    );

    return chatID;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startGetChatByChatID = (chatID) => async (dispatch, getState) => {
  try {
    const chatRedux = getState().chat.chats[chatID];

    if (chatRedux) {
      return chatRedux;
    }

    const snapshotChat = await get(ref(db, `chats/${chatID}`));

    const chatInfo = snapshotChat.val();

    const chat = chatInfo;

    dispatch(
      getAllChats({
        [chatID]: chat,
      })
    );

    return chatID;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startMarkReadChat = (chatID) => async (dispatch, getState) => {
  try {
    const chat = getState().chat.chats[chatID];
    const shopID = dispatch(getShopID());
    const userID = dispatch(getUserID());

    if (
      chatID === CHAT_BOT_USER_ID ||
      chat.lastSender === shopID ||
      chat.lastSender === userID
    ) {
      return true;
    }
    const dbRef = ref(db, `chats/${chatID}/isRead`);

    await set(dbRef, true);

    dispatch(markReadChat(chatID));
  } catch (error) {
    const errorFormatted = getObjectError(error);
    if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
      return false;
    }

    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const markReadChat = (chatID) => ({
  type: types.MARK_READ_CHAT,
  payload: chatID,
});

export const startMarkDisabledChat = (chatID) => async (dispatch, getState) => {
  try {
    if (chatID === CHAT_BOT_USER_ID) {
      return true;
    }

    const updates = {};

    updates[`chats/${chatID}/isDisabled`] = true;
    updates[`chats/${chatID}/from`] = "influencers";

    await update(ref(db), updates);

    dispatch(markDisabledChat(chatID));
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startSearchChats =
  ({ textQuery, onFinishLoad = () => {} }) =>
  async (dispatch, getState) => {
    const role = dispatch(getRole());

    let userID = ROLES_ADMIN_SHOPS.includes(role)
      ? dispatch(getShopID())
      : dispatch(getUserID());

    let q = queryFS(
      collection(firestore, "influencers"),
      where("mainAccountName", ">=", textQuery.toLowerCase()),
      where("mainAccountName", "<=", textQuery.toLowerCase() + "\uf8ff")
    );

    if (ROLES_ADMIN_SHOPS.includes(role)) {
      const signUpCountry = getState().shop.signUpCountry;
      where("signUpCountry", "==", signUpCountry);
    }

    const docSnap = await getDocs(q);
    const data = {};

    if (!docSnap.empty) {
      docSnap.forEach((doc) => {
        data[doc.id] = doc.data();
      });
    }

    dispatch(getNextInfluencers(data));

    if (_.isEmpty(data)) {
      onFinishLoad();
      return;
    }

    const queriesChats = [];
    Object.keys(data).forEach((influencerID) => {
      const dbRef = query(
        ref(db, `chatsIDS/${userID}`),
        orderByChild("receiver"),
        equalTo(influencerID)
      );
      queriesChats.push(get(dbRef));
    });

    const chatsSnapshot = await Promise.all(queriesChats);

    const chatsIDS = [];

    chatsSnapshot.forEach((snap) => {
      if (!snap.exists()) return;
      const chat = snap.val();
      chatsIDS.push(...Object.keys(chat));
    });
    await dispatch(startGetChatsByIDS({ chatsIDS }));
    onFinishLoad();
    return true;
  };

const markDisabledChat = (chatID) => ({
  type: types.MARK_DISABLED_CHAT,
  payload: chatID,
});

const clearTemporaryChats = () => ({
  type: types.CLEAR_TEMPORARY_CHATS,
});

export const changeIsTypingBot = () => ({
  type: types.SET_IS_TYPING_BOT,
});

export const openChat = () => ({
  type: types.OPEN_CHAT,
});

export const setCurrentWriteMessage = (message) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_WRITE_MESSAGE,
    payload: message,
  });
};
export const closeChat = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_CHAT,
  });
};

export const uploadingFile = () => (dispatch) => {
  dispatch({
    type: types.SET_UPLOADING_FILE,
  });
};
