import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CreatorsScript = () => {
  const [params] = useSearchParams();
  const message = params.get("message") || "";
  const messageShowFormatted = message
    ?.replace("%23", "#")
    ?.replace("%26", "&")
    ?.replace("%3F", "?")
    ?.replace("%3D", "=");
  const messageFormatted = message
    ?.replace(/<\/p>/g, "\n\n")
    ?.replace(/<[^>]*>/g, "");

  const [openAlert, setOpenAlert] = useState(false);

  if (!message)
    return (
      <Box
        sx={{
          height: "calc(100vh - 64px)",
          width: "99%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: 1,
        }}
      >
        <Typography variant="h6">
          No se pudo obtener el guión, por favor intenta de nuevo o contacta a
          soporte.
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        width: "99%",
        height: "calc(100vh - 64px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
        p: 2,
      }}
    >
      <Box sx={{ maxWidth: "75ch", maxHeight: "90%", overflowY: "auto" }}>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: messageShowFormatted }}
        />
      </Box>
      <Box sx={{ display: "flex", maxHeight: "8%" }}>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(messageFormatted);
            setOpenAlert(true);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openAlert}
          autoHideDuration={4000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert
            onClose={() => setOpenAlert(false)}
            severity="info"
            sx={{ width: "100%" }}
          >
            Copiado
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default CreatorsScript;
