import React from "react";
import {
  BarChart as BarContainer,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { BAR_BLACK_COLOR } from "../../../utils/colors";

const BarChart = ({ data, dataKeyX, nameX, dataKeyY, nameY }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarContainer
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey={dataKeyX} />
        <YAxis width={15} />
        <Tooltip />
        <Bar
          dataKey={dataKeyY}
          fill={BAR_BLACK_COLOR}
          radius={[12, 12, 0, 0]}
        />
      </BarContainer>
    </ResponsiveContainer>
  );
};

export default BarChart;
