import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_CANCEL,
  BUTTON_COPY,
  CATEGORIES,
  MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL,
  MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED,
  MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER,
  MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED,
  MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED,
  PRODUCTS,
} from "../../../../locales/keysTranslations";
import ContainerModalActions from "../../../Common/Containers/ContainerModalActions";

const ModalCopyAccompanimentType = ({
  data,
  categories,
  onCloseModal,
  onCopyAccompanimentType,
}) => {
  const [category, setCategory] = useState("");
  const [product, setProduct] = useState("");
  const [accompanimentType, setAccompanimentType] = useState("");
  const [error, setError] = useState(null);

  const { t } = useTranslationApp();

  const onSubmit = () => {
    if (category === "") {
      return setError(t(MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED));
    }
    if (product === "") {
      return setError(t(MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED));
    }
    if (accompanimentType === "") {
      return setError(t(MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED));
    }
    onCopyAccompanimentType(category, product, accompanimentType);
  };
  return (
    <ModalBasicLayout
      modalOpen={data}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL)}
      customComponentTitle={
        !!error && (
          <Alert severity="error" sx={{ alignItems: "center" }}>
            {error}
          </Alert>
        )
      }
      description={t(MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE)}
    >
      <FormControl fullWidth variant="filled">
        <InputLabel id="select-categories">{t(CATEGORIES)}</InputLabel>
        <Select
          labelId="select-categories"
          value={category}
          name="categories"
          label={t(CATEGORIES)}
          onChange={(e) => {
            setCategory(e.target.value);
            setProduct("");
            setAccompanimentType("");
          }}
        >
          {Object.keys(categories).map((category, index) => (
            <MenuItem key={index} value={category}>
              {categories[category].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {category && (
        <>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            {t(MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE)}
          </Typography>
          <FormControl fullWidth variant="filled">
            <InputLabel id="select-products">{t(PRODUCTS)}</InputLabel>
            <Select
              labelId="select-products"
              value={product}
              name="products"
              label={t(PRODUCTS)}
              onChange={(e) => {
                setProduct(e.target.value);
                setAccompanimentType("");
              }}
            >
              {Object.keys(categories[category].products ?? []).map(
                (product, index) => (
                  <MenuItem key={index} value={product}>
                    {categories[category].products[product].name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </>
      )}
      {category && product && (
        <>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            {t(MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE)}
          </Typography>
          <FormControl fullWidth variant="filled">
            <InputLabel id="select-accompanimentType">
              {t(MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER)}
            </InputLabel>
            <Select
              labelId="select-accompanimentType"
              value={accompanimentType}
              name="accompanimentType"
              label={t(MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER)}
              onChange={(e) => setAccompanimentType(e.target.value)}
            >
              {Object.keys(
                categories[category].products[product].options ?? []
              ).map((option, index) => (
                <MenuItem key={index} value={option}>
                  {categories[category].products[product].options[option].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <DialogActions>
        <ContainerModalActions
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_COPY,
            onClick: onSubmit,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalCopyAccompanimentType;
