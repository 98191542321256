import { DUMMY_ORDER } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  orders: {},
  generalRedemptions: {},
  isLoading: true,
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS_FINISH:
      return {
        ...state,
        orders: { ...state.orders, ...action.payload },
        isLoading: false,
      };
    case types.GET_ORDERS_BY_SHOP_FINISH:
      return {
        ...state,
        orders: { ...state.orders, ...action.payload },
        isLoading: false,
      };
    case types.REMOVE_ORDERS:
      return {
        ...state,
        orders: {},
      };
    case types.GET_ORDERS_BY_STORE_FINISH:
      return {
        ...state,
        orders: action.payload,
        isLoading: false,
      };
    case types.GET_ORDERS_BY_STORE_PAGINATION:
      return {
        ...state,
        orders: { ...state.orders, ...action.payload },
        isLoading: false,
      };
    case types.CREATE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.EDIT_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.payload.key]: {
            ...state.orders[action.payload.key],
            ...action.payload.data,
          },
        },
      };
    case types.ASSIGN_CHAT_ID_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.payload.key]: {
            ...state.orders[action.payload.key],
            chatID: action.payload.chatID,
          },
        },
      };
    case types.GENERAL_DISCOUNT_REDEMPTION:
      return {
        ...state,
        generalRedemptions: {
          ...state.generalRedemptions,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.GET_GENERAL_REDEMPTIONS_BY_STORE_FINISH:
      return {
        ...state,
        generalRedemptions: action.payload,
        isLoading: false,
      };
    case types.GET_GENERAL_REDEMPTIONS_BY_STORE_PAGINATION:
      return {
        ...state,
        generalRedemptions: { ...state.generalRedemptions, ...action.payload },
        isLoading: false,
      };
    case types.LOADING_ORDERS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_ORDER]: orderDelete, ...orders } = state.orders;
      return {
        ...state,
        orders: orders,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
