import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Loader from "../../../components/Common/Loader/Loader";
import { AUTH_TIKTOK_VERIFICATION_LOADING } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { startGetTokenTiktok } from "../../../actions/shops";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatchApp } from "../../../lib/redux";

const AuthTikTokCallback = () => {
  const { t } = useTranslationApp();

  const navigate = useNavigate();
  const dispatch = useDispatchApp();

  const [params] = useSearchParams();

  const code = params.get("code");
  const state = params.get("state");

  const getConfigTiktok = async () => {
    if (!code || !state) {
      navigate("/auth/tiktok/failed");
      return;
    }
    const response = await dispatch(
      startGetTokenTiktok({
        code,
        state,
        withAuth: false,
      })
    );
    if (response) {
      navigate("/auth/tiktok/success");
    } else {
      navigate("/auth/tiktok/failed");
    }
  };

  useEffect(() => {
    getConfigTiktok();
    // eslint-disable-next-line
  }, []);
  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Loader hasMessage={true} message={t(AUTH_TIKTOK_VERIFICATION_LOADING)} />
    </Container>
  );
};

export default AuthTikTokCallback;
