import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  Link as LinkReact,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { startLogin, startSendEmailResetPassword } from "../../../actions/auth";
import Button from "../../Common/Buttons/Button";
import { REGEX_EMAIL } from "../../../utils/regexsValidation";
import { useTranslationApp } from "../../../lib/i18next";
import {
  EMAIL_PLACEHOLDER,
  FIELD_EMAIL_VALID,
  FIELD_REQUIRED,
  FORGOT_PASSWORD,
  LOG_IN,
  NO_HAVE_ACCOUNT,
  PASSWORD_PLACEHOLDER,
  REGISTER_HERE,
} from "../../../locales/keysTranslations";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import { useForm } from "react-hook-form";
import TextField from "../../Common/Fields/TextField";
import { clearError, setShowBackdrop } from "../../../actions/ui";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";

const LoginForm = () => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledEmail, setIsDisabledEmail] = useState(false);

  const { error, errorText } = useSelectorApp((state) => state.ui);

  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const email = params.get("email");

  useEffect(() => {
    if (email && REGEX_EMAIL.test(email)) {
      setValue("email", email);
      setIsDisabledEmail(true);
      navigate("/login");
    }
    //eslint-disable-next-line
  }, [email]);

  const { t } = useTranslationApp();

  const onChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onResetPassword = async () => {
    const result = await trigger("email");

    if (!result) return;

    clearErrors();

    dispatch(clearError());

    const formValues = getValues();
    dispatch(setShowBackdrop(true));

    // setIsFetching(true);
    await dispatch(startSendEmailResetPassword(formValues.email));
    // setIsFetching(false);
    dispatch(setShowBackdrop(false));
  };

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    await dispatch(startLogin(formValues.email, formValues.password));
    setIsLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorText}
        </Alert>
      )}

      <TextField
        margin="normal"
        fullWidth
        id="email"
        label={t(EMAIL_PLACEHOLDER)}
        name="email"
        disabled={isDisabledEmail}
        autoComplete="email"
        autoFocus
        variant="filled"
        color="primary"
        sx={{ borderRadius: 3, mb: 2 }}
        InputLabelProps={{ shrink: true }}
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
          validate: {
            emailValid: (value) => {
              if (!REGEX_EMAIL.test(value)) {
                return t(FIELD_EMAIL_VALID);
              }
            },
          },
        }}
        errors={errors}
      />
      <TextField
        margin="normal"
        fullWidth
        name="password"
        label={t(PASSWORD_PLACEHOLDER)}
        type={showPassword ? "text" : "password"}
        id="password"
        autoComplete="current-password"
        variant="filled"
        color="primary"
        sx={{ borderRadius: 3 }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={onChangeShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
        }}
        errors={errors}
      />
      <LoadingButton
        disabled={isLoading}
        hasShadow={false}
        variant="text"
        align="center"
        type="button"
        onClick={onResetPassword}
        sx={{
          fontWeight: "normal",
          color: "text.primary",
          backgroundColor: "transparent !important",
        }}
      >
        {t(FORGOT_PASSWORD)}
      </LoadingButton>
      <LoadingButton
        loading={isLoading}
        type="submit"
        color="secondary"
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
      >
        {t(LOG_IN)}
      </LoadingButton>
      {!isDisabledEmail && (
        <>
          <Typography variant="body2" align="center" sx={{ mt: 2, mb: 2 }}>
            {t(NO_HAVE_ACCOUNT)}
          </Typography>
          <LinkReact to="/register" style={{ textDecoration: "none" }}>
            <Button type="button" color="primary" fullWidth variant="contained">
              {t(REGISTER_HERE)}
            </Button>
          </LinkReact>
        </>
      )}
    </Box>
  );
};

export default LoginForm;
