import React, { useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PLAN_COLLABORATION, PLAN_TRIAL } from "../../../../../utils/constants";
import LoadingButton from "../../../../Common/Buttons/LoadingButton";
import UsageChargeInfo from "../ModelUsage/UsageChargeInfo";
import ModalStripeAddCard from "../../../../Common/Stripe/ModalStripeAddCard";
import { startActivePlanCollaboration } from "../../../../../actions/payments";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../../utils/colors";
import {
  BILLING_AND_PAYMENT_ACTIVATE_PLAN,
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE,
  BILLING_AND_PAYMENT_NO_HAVE_PLAN,
  BILLING_AND_PAYMENT_PLAN_EXPIRED,
  BILLING_AND_PAYMENT_PLAN_TRIAL,
  BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION,
  PLAN,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { getNameBusinessTheme } from "../../../../../actions/getters";
import CreditsCounter from "../../../../Common/Credits/CreditsCounter";

const UsageContainer = ({ data }) => {
  const [modalOpenAddSource, setOpenModalAddSource] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const { t } = useTranslationApp();

  const {
    defaultPaymentMethod: { brand, last4 },
  } = useSelectorApp((state) => state.payments.stripe);
  const isActivePlan = useSelectorApp((state) => state.auth.isActivePlan);
  const { plan } = useSelectorApp((state) => state.payments);

  const onChangeStatusModal = (status) => {
    brand.length === 0 && last4.length === 0
      ? setOpenModalAddSource(status)
      : onActivePlanCollaboration();
  };

  const onActivePlanCollaboration = async () => {
    setLoading(true);
    const response = await dispatch(startActivePlanCollaboration());
    setLoading(false);
    return response;
  };

  const onAddPaymentMethod = async () => {
    setLoading(true);
    const response = await onActivePlanCollaboration();
    setLoading(false);
    if (response) {
      setOpenModalAddSource(false);
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", lg: "45%" },
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
        {t(PLAN)}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          backgroundColor: "background.paperSecondary",
          boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
          borderRadius: 4,
          minHeight: 112,
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          {isActivePlan && plan === PLAN_TRIAL && (
            <>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontStyle: "normal",
                }}
              >
                {t(BILLING_AND_PAYMENT_PLAN_TRIAL)}
              </Typography>
              <Typography variant="body1">
                {t(BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION, {
                  NAME_BUSINESS,
                })}
              </Typography>
            </>
          )}
          {!isActivePlan && plan === PLAN_TRIAL && (
            <>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontStyle: "normal",
                }}
              >
                {t(BILLING_AND_PAYMENT_PLAN_TRIAL)}
              </Typography>
              <Typography variant="body1">
                {t(BILLING_AND_PAYMENT_PLAN_EXPIRED, {
                  NAME_BUSINESS,
                })}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                  flexGrow: 1,
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={loading}
                  sx={{
                    width: "auto",
                    background: GRADIENT_PURPLE_FUCHSIA,
                    color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  }}
                  onClick={() => onChangeStatusModal(true)}
                >
                  {t(BILLING_AND_PAYMENT_ACTIVATE_PLAN)}
                </LoadingButton>
              </Box>
            </>
          )}

          {isActivePlan && plan === PLAN_COLLABORATION && (
            <UsageChargeInfo data={data} />
          )}

          {!isActivePlan && plan === PLAN_COLLABORATION && (
            <>
              <Typography variant="h6" sx={{ mb: 1, fontStyle: "normal" }}>
                {t(BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexGrow: 1,
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={loading}
                  sx={{
                    width: "auto",
                    background: GRADIENT_PURPLE_FUCHSIA,
                    color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  }}
                  onClick={() => onChangeStatusModal(true)}
                >
                  {t(BILLING_AND_PAYMENT_ACTIVATE_PLAN)}
                </LoadingButton>
              </Box>
            </>
          )}

          {((!isActivePlan && !plan) || (isActivePlan && !plan)) && (
            <>
              <Typography variant="h6" sx={{ mb: 1, fontStyle: "normal" }}>
                {t(BILLING_AND_PAYMENT_NO_HAVE_PLAN)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={loading}
                  sx={{
                    width: "auto",
                    background: GRADIENT_PURPLE_FUCHSIA,
                    color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  }}
                  onClick={() => onChangeStatusModal(true)}
                >
                  {t(BILLING_AND_PAYMENT_ACTIVATE_PLAN)}
                </LoadingButton>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <CreditsCounter
            onSlideMenu={true}
            sx={{
              px: 2,
              mx: 0,
            }}
          />
        </Box>
      </Paper>
      {modalOpenAddSource && (
        <ModalStripeAddCard
          modalOpen={modalOpenAddSource}
          onSubmit={onAddPaymentMethod}
          saveCard={true}
          setDefaultCard={true}
          onCloseModal={() => onChangeStatusModal(false)}
        />
      )}
    </Box>
  );
};

export default UsageContainer;
