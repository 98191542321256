import React, { useEffect, useState } from "react";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import { Controller } from "react-hook-form";
import InfluencerRangeFilter from "../Influencer/InfluencerRangeFilter";
import {
  ANY,
  AUDIENCE,
  AUDIENCE_LOCATION_PLACEHOLDER,
  BUTTON_CLEAR_FILTERS,
  BUTTON_SEARCH,
  CREATOR_BODY_BUILD_PLACEHOLDER,
  CREATOR_CLOTHING_STYLE_PLACEHOLDER,
  CREATOR_CONTENT_STYLE_PLACEHOLDER,
  CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER,
  CREATOR_LOCATION_PLACEHOLDER,
  ENGAGEMENT,
  ENGAGEMENT_TITLE_EXPLANATION,
  FEMALES,
  GENDER,
  MALES,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import SliderBase from "../Forms/Sliders/SliderBase";
import {
  marksEngagement,
  optionsBodyBuild,
  optionsClothingStyle,
  optionsContentStyle,
  optionsDistinguishingFeatures,
} from "../../../utils/slidersMarks";
import { COUNTRIES_WITH_ONLY_ONE_LOCATION } from "../../../utils/constants";
import ContentEngagement from "../Tooltips/Content/ContentEngagement";
import { GREY_SLIDER_COLOR } from "../../../utils/colors";
import Box from "@mui/material/Box";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Select from "../Selects/Select";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContainerModalActions from "../Containers/ContainerModalActions";
import SelectCities from "../Selects/SelectCities";
import { startGetInfluencersCitiesList } from "../../../actions/influencers";
import CountriesPhylloField from "../Fields/CountriesPhylloField";

const ModalFiltersInfluencers = ({
  modalOpen,
  onCloseModal,
  hiddenNewCategory,
  control,
  filters,
  onChangeFilters,
  onSetInitialStateFilters = () => {},
  onCallbackSearch = () => {},
}) => {
  const [cities, setCities] = useState([]);
  const { signUpCountry } = useSelectorApp((state) => state.shop);
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatchApp();

  const isHiddenNewCategory = hiddenNewCategory;

  const getCities = async () => {
    const response = await dispatch(
      startGetInfluencersCitiesList(signUpCountry)
    );

    if (response) {
      setCities(response);
    }
  };
  useEffect(() => {
    getCities();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
    >
      <Box
        sx={{
          mt: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <>
          <Box>
            <Select
              isRounded={true}
              control={control}
              label={t(GENDER)}
              name="genderSelected"
              options={[
                {
                  value: "",
                  label: t(ANY),
                },
                {
                  value: "female",
                  label: t(FEMALES),
                },
                {
                  value: "male",
                  label: t(MALES),
                },
              ]}
              variant="filled"
              fullWidth
              actionsChange={() => {
                if (!isHiddenNewCategory && filters.newCategorySelected) {
                  onChangeFilters("newCategorySelected", false);
                }
              }}
            />
          </Box>
          {!COUNTRIES_WITH_ONLY_ONE_LOCATION.includes(signUpCountry) && (
            <Box>
              <SelectCities
                isRounded={true}
                control={control}
                name="citySelected"
                additionalOptions={cities.map((city) => ({
                  value: city,
                  label: city,
                }))}
                label={t(CREATOR_LOCATION_PLACEHOLDER)}
                mode="query"
                variant="filled"
                fullWidth
                actionsChange={() => {
                  if (!isHiddenNewCategory && filters.newCategorySelected) {
                    onChangeFilters("newCategorySelected", false);
                  }
                }}
              />
            </Box>
          )}
          <Box>
            <CountriesPhylloField
              isRounded={true}
              control={control}
              name="audienceLocations"
              label={t(AUDIENCE_LOCATION_PLACEHOLDER)}
              variant="filled"
              fullWidth
              actionsChange={(value) => {
                if (!isHiddenNewCategory && filters.newCategorySelected) {
                  onChangeFilters("newCategorySelected", false);
                }

                if (value?.length > 0 && filters.topicsSelected) {
                  onChangeFilters("topicsSelected", []);
                }
              }}
            />
          </Box>
          <Box>
            <Select
              name="bodyBuild"
              label={t(CREATOR_BODY_BUILD_PLACEHOLDER)}
              fullWidth
              variant="filled"
              formatOptions="object"
              options={optionsBodyBuild}
              doTranslate={true}
              control={control}
            />
          </Box>
          <Box>
            <Select
              name="clothingStyle"
              label={t(CREATOR_CLOTHING_STYLE_PLACEHOLDER)}
              fullWidth
              variant="filled"
              formatOptions="object"
              options={optionsClothingStyle}
              doTranslate={true}
              control={control}
            />
          </Box>
          <Box>
            <Select
              name="distinguishingFeatures"
              label={t(CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER)}
              fullWidth
              showAllOption={true}
              variant="filled"
              formatOptions="object"
              options={optionsDistinguishingFeatures}
              doTranslate={true}
              control={control}
            />
          </Box>
          <Box>
            <Select
              name="contentStyle"
              label={t(CREATOR_CONTENT_STYLE_PLACEHOLDER)}
              fullWidth
              showAllOption={true}
              variant="filled"
              formatOptions="object"
              options={optionsContentStyle}
              doTranslate={true}
              control={control}
            />
          </Box>
        </>
        <Box>
          <Controller
            control={control}
            name="rangeFollowers"
            render={({ field }) => (
              <InfluencerRangeFilter
                title={t(AUDIENCE)}
                rangeFollowers={field.value}
                onChangeRange={(range, e) => {
                  if (!isHiddenNewCategory && filters.newCategorySelected) {
                    onChangeFilters("newCategorySelected", false);
                  }
                  field.onChange(e);
                }}
                sx={{
                  maxWidth: "100%",
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="rangeEngagement"
            render={({ field }) => (
              <SliderBase
                range={field.value}
                onChange={(e) => {
                  if (!isHiddenNewCategory && filters.newCategorySelected) {
                    onChangeFilters("newCategorySelected", false);
                  }
                  field.onChange(e);
                }}
                name="engagementRange"
                title={t(ENGAGEMENT)}
                variantTitle="body1"
                step={0.5}
                min={0}
                max={30}
                marks={marksEngagement.filter((mark, index) => {
                  if (isMobile) {
                    if (index % 2 === 0) {
                      return mark;
                    } else {
                      return false;
                    }
                  }

                  return mark;
                })}
                hasTooltip={true}
                titleTooltip={t(ENGAGEMENT_TITLE_EXPLANATION)}
                contentTooltip={<ContentEngagement />}
                sx={{
                  width: "calc(100% - 8px)",
                  px: 1,
                  ".MuiSlider-rail": {
                    backgroundColor: GREY_SLIDER_COLOR,
                  },
                  ".MuiSlider-mark": {
                    backgroundColor: "transparent",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      <DialogActions
        sx={{
          minWidth: 130,
          p: 0,
        }}
      >
        <ContainerModalActions
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SEARCH,
            onClick: () => {
              onCallbackSearch();
              onCloseModal();
            },
          }}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CLEAR_FILTERS,
            onClick: onSetInitialStateFilters,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalFiltersInfluencers;
