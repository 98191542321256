import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import StatusApplicant from "./StatusApplicant";
import ActionsApplicantButtons from "./ActionsApplicantButtons";
import TableContainer from "../../../../Common/Table/TableContainer";
import TableCell from "../../../../Common/Table/TableCell";
import { keyframes } from "@mui/system";
import NameInfluencerCell from "../../../../Common/Influencer/NameInfluencerCell";
import {
  capitalizeFirstLetter,
  getCityInfluencer,
} from "../../../../../utils/formats";
import SocialNetworkCell from "../../../../Common/Influencer/SocialNetworkCell";
import { getAnalyticsByUser } from "../../../../../utils/socialNetworks";
import { PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW } from "../../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../../../../../lib/i18next";
import {
  COLUMN_NAME_ACTION,
  COLUMN_NAME_CITY,
  COLUMN_NAME_AUDIENCE,
  COLUMN_NAME_ENGAGEMENT,
  COLUMN_NAME_CATEGORIES,
  COLUMN_NAME_SCORE,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_STATUS,
  EMPTY_APPLICANTS_ACCEPTED,
} from "../../../../../locales/keysTranslations";
import { getInfluencerIsHiddenFromTables } from "../../../../../actions/getters";
import InfluencerCategories from "../../../../Common/Influencer/InfluencerCategories";
import Rating from "../../../../Common/Forms/Rating/Rating";
import InitChatButton from "../../../../Common/Chat/InitChatButton";
import { isFilteringInfluencers } from "../../../../../services/shops";
import useCreatorsFilters from "../../../../../hooks/useCreatorsFilters";

const spin = keyframes`
  from {
    background-color: inherit;
  }
  to {
    background-color: rgba(107, 248, 154, 0.5);
  }
`;

const ApplicantsTable = ({
  data,
  onSelectApplicant,
  onOpenChatApplicant,
  statusCampaign,
  page,
  onPageChange,
  rowsPerPage,
  applicationID,
  isViewHighlighted,
  filters = {},
  campaignID,
  onOpenChatInfluencer,
}) => {
  const influencers = useSelectorApp((state) => state.influencers.influencers);

  const { isOpen } = useTour();
  const { onInfluencerPassFilter } = useCreatorsFilters();
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
  } = filters;

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    categoriesSelected,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
  });

  const filteredInfluencers = () => {
    const applicants = Object.keys(data);
    if (applicants.length === 0) return {};

    const filtered = {};

    for (let applicantKey of applicants) {
      const applicant = data[applicantKey];
      const influencer = influencers[applicant.userID] || {};

      const passFilter = onInfluencerPassFilter({
        influencer,
        filters,
        userID: applicant.userID,
      });

      if (!passFilter) continue;

      filtered[applicantKey] = applicant;
    }
    return filtered;
  };

  const keysSorted = Object.keys(filteredInfluencers()).sort((a, b) => {
    const applicantA = data[a];
    const applicantB = data[b];
    const influencerA = influencers[applicantA.userID];
    const influencerB = influencers[applicantB.userID];
    if (topRatedCategorySelected) {
      return influencerB?.score - influencerA?.score;
    } else {
      return influencerB?.followers - influencerA?.followers;
    }
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        emptyText={EMPTY_APPLICANTS_ACCEPTED}
        loading={false}
        hasMarginBottom={false}
        isFiltering={isFiltering}
        sx={{ maxHeight: 500, overflow: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_STATUS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CITY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_AUDIENCE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ENGAGEMENT)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CATEGORIES)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SCORE)}
            </TableCell>
            <TableCell align="center" isHeader={true} isSticky={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            const applicant = data[key];
            const influencer = influencers[applicant.userID];
            if (
              dispatch(
                getInfluencerIsHiddenFromTables({
                  userID: applicant.userID,
                  validateIsDiscarded: false,
                })
              )
            )
              return null;

            return (
              <TableRow
                key={key}
                data-tour={
                  index === 0 &&
                  isOpen &&
                  PAGE_CAMPAIGN_TABLE_APPLICANTS_FIRST_ROW
                }
              >
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{
                    animation:
                      key === applicationID &&
                      !isViewHighlighted &&
                      `${spin} 1s infinite ease`,
                    animationDirection: "alternate",
                  }}
                >
                  <StatusApplicant status={applicant.status} data={applicant} />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{
                    animation:
                      key === applicationID &&
                      !isViewHighlighted &&
                      `${spin} 1s infinite ease`,
                    animationDirection: "alternate",
                  }}
                >
                  <NameInfluencerCell
                    userID={applicant.userID}
                    data={influencer}
                    showButton={false}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  {capitalizeFirstLetter(getCityInfluencer(influencer))}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <SocialNetworkCell
                    platform={influencer.mainPlatform}
                    data={getAnalyticsByUser(influencer)}
                    metric="audience"
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <SocialNetworkCell
                    platform={influencer.mainPlatform}
                    data={getAnalyticsByUser(influencer)}
                    metric="engagement"
                    sign="%"
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ maxWidth: 200 }}
                >
                  <InfluencerCategories
                    influencer={influencer}
                    categoriesSelected={categoriesSelected}
                  />
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Rating
                    value={influencer.score}
                    readOnly={true}
                    precision={0.5}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  isSticky={true}
                  sx={{
                    animation:
                      key === applicationID &&
                      !isViewHighlighted &&
                      `${spin} 1s infinite ease`,
                    animationDirection: "alternate",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <ActionsApplicantButtons
                      onSelectApplicant={onSelectApplicant}
                      onOpenChatApplicant={onOpenChatApplicant}
                      statusCampaign={statusCampaign}
                      data={applicant}
                      applicantID={key}
                    />
                    <InitChatButton
                      onClick={() => onOpenChatInfluencer(applicant.userID)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default ApplicantsTable;
