import { useSelectorApp } from "../../lib/redux";
import { Navigate } from "react-router-dom";
import { OWNER_ROLE, MANAGER_ROLE } from "../../utils/constants";

export const ManagerRoute = ({ children, isLogged }) => {
  const { user } = useSelectorApp((state) => state.auth);
  if (!isLogged) return <Navigate to="/login" />;
  if (user.role !== OWNER_ROLE && user.role !== MANAGER_ROLE)
    return <Navigate to="/" />;
  return children;
};
