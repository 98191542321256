import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import {
  BUTTON_CALCULATE,
  END_DATE_PLACEHOLDER,
  START_DATE_PLACEHOLDER,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const DatesForm = ({
  control,
  sx,
  textButton = BUTTON_CALCULATE,
  colorButton = "secondary",
  onActionButton = () => {},
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslationApp();

  const onClick = async () => {
    setLoading(true);
    await onActionButton();
    setLoading(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
        gap: 3,
        mt: 2,
        ...sx,
      }}
    >
      <Controller
        name="startDate"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t(START_DATE_PLACEHOLDER)}
            renderInput={(params) => (
              <TextField
                sx={{ maxWidth: 300 }}
                fullWidth
                variant="filled"
                {...params}
              />
            )}
          />
        )}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={t(END_DATE_PLACEHOLDER)}
            renderInput={(params) => (
              <TextField
                sx={{ maxWidth: 300 }}
                fullWidth
                variant="filled"
                {...params}
              />
            )}
          />
        )}
      />
      <LoadingButton
        disabled={disabled}
        loading={loading}
        color={colorButton}
        variant="contained"
        onClick={onClick}
      >
        {t(textButton)}
      </LoadingButton>
    </Box>
  );
};

export default DatesForm;
