import React from "react";
import Button from "../../Buttons/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PAGE_CAMPAIGNS_ANSWER_PREV_BUTTON } from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";

const PreviousButtonStep = ({ onPreviousStep, children, sx = {} }) => {
  const { isOpen, steps, currentStep } = useTour();
  const isDisabledOnboarding =
    isOpen && steps[currentStep].actionData === "disabledButton";
  return (
    <Button
      disabled={isDisabledOnboarding}
      sx={{ mt: 2, ...sx }}
      color="primary"
      onClick={onPreviousStep}
      startIcon={<ArrowBackIcon />}
      dataTour={PAGE_CAMPAIGNS_ANSWER_PREV_BUTTON}
    >
      {children}
    </Button>
  );
};

export default PreviousButtonStep;
