import React from "react";
import { useSelectorApp } from "../../../../lib/redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "../../../../utils/constants";
import { getExtensionFile } from "../../../../utils/urls";
import InfluencerNameLogo from "../../Influencer/InfluencerNameLogo";
import ShopPost from "../ShopPosts/ShopPost";
import {
  CATEGORY,
  COMMENTS,
  EMPTY_IMAGES_FOR_COMMENT,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const ShopNegativePosts = ({ data = [] }) => {
  const { t } = useTranslationApp();
  const { influencers } = useSelectorApp((state) => state.influencers);
  if (Object.keys(influencers).length === 0) return null;
  if (data.length === 0) return null;

  return (
    <Box sx={{ my: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          fontStyle: "normal",
          mb: 3,
        }}
      >
        {t(COMMENTS)}
      </Typography>
      {data.map((collaboration) => {
        return (
          <Box key={collaboration.orderID} sx={{ mb: 2 }}>
            <InfluencerNameLogo
              data={influencers[collaboration.userID]}
              userID={collaboration.userID}
            />
            <Typography variant="body1">
              {`${t(
                CATEGORY
              )}: ${collaboration?.negativeReview?.categories?.join(", ")}`}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ maxWidth: "75ch" }}>
              {collaboration?.negativeReview?.comment}
            </Typography>
            {collaboration?.negativeReview?.photoURLs ? (
              <Grid container spacing={2}>
                {Object.values(
                  collaboration?.negativeReview?.photoURLs || {}
                ).map((post) => {
                  const extension = getExtensionFile(post);
                  if (
                    !VIDEO_FORMATS.includes(extension) &&
                    !IMAGE_FORMATS.includes(extension)
                  ) {
                    return null;
                  }
                  return (
                    <Grid item key={post}>
                      <ShopPost url={post} orderID={collaboration.orderID} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="body1" fontWeight="bold">
                {t(EMPTY_IMAGES_FOR_COMMENT)}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ShopNegativePosts;
