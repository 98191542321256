import React, { useState } from "react";
import Box from "@mui/material/Box";
import RenderContentMedia from "../../Common/Media/RenderContentMedia";
import { detectPlatform } from "../../../utils/urls";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_APPROVE_CONTENT,
  BUTTON_SEND_REVISION,
  COMMENTS,
  CONTENT,
  EMPTY_CONTENT_SHOW,
} from "../../../locales/keysTranslations";
import { POST_STATUS_PENDING_APPROVAL } from "../../../utils/constants";
import { useForm } from "react-hook-form";
import TextField from "../../Common/Fields/TextField";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";

const ContentSection = ({
  data = {},
  isMobile,
  onApproveContent = () => {},
  onCallbackSendRevision = () => {},
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { control, handleSubmit, watch, formState } = useForm({
    defaultValues: {
      comment: "",
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const comment = watch("comment");
  const postStatus = data.postStatus;
  const content = data.content || [];
  const revisions = data?.history?.length || 0;

  const { t } = useTranslationApp();

  const onSubmit = async (data) => {
    setIsFetching(true);
    await onCallbackSendRevision(data);
    setIsFetching(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant={isMobile ? "h4" : "h5"}
        sx={{ fontWeight: 600, mb: 3 }}
      >
        {t(CONTENT)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "auto",
          overflow: "auto",
          gap: 1,
          pb: 1,
        }}
      >
        {content.length > 0 &&
          content.map((media, index) => {
            const result = detectPlatform(media);
            return (
              <RenderContentMedia
                key={index}
                result={result}
                url={media}
                keyMedia={index}
              />
            );
          })}
      </Box>
      {postStatus === POST_STATUS_PENDING_APPROVAL && (
        <Box
          sx={{ mt: 2, width: "100%", maxWidth: 400 }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <LoadingButton
            color="primary"
            loading={isFetching}
            disabled={comment.length > 0}
            type="button"
            sx={{ mb: 2 }}
            onClick={onApproveContent}
          >
            {t(BUTTON_APPROVE_CONTENT)}
          </LoadingButton>
          <TextField
            name="comment"
            label={t(COMMENTS)}
            multiline
            minRows={3}
            maxRows={3}
            fullWidth
            disabled={revisions === 3}
            variant="filled"
            control={control}
            errors={errors}
          />
          <LoadingButton
            color="secondary"
            disabled={comment.length === 0 || revisions === 3}
            loading={isFetching}
            sx={{ mt: 2 }}
          >
            {`${t(BUTTON_SEND_REVISION)} ${revisions}/3`}
          </LoadingButton>
        </Box>
      )}
      {content.length === 0 && (
        <Typography variant={isMobile ? "h5" : "h6"}>
          {t(EMPTY_CONTENT_SHOW)}
        </Typography>
      )}
    </Box>
  );
};

export default ContentSection;
