import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import ContactTable from "./ContactTable";
import HeaderContactTab from "./HeaderContactTab";
import ModalContact from "./ModalContact";
import {
  ACCOUNT_MODE_CREATE,
  ACCOUNT_MODE_EDIT,
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
} from "../../../../utils/constants";
import {
  SimpleAlert,
  ConfirmAlert,
  AlertInactivePlan,
} from "../../../../utils/alerts";
import {
  startCreateContact,
  startDeleteContact,
  startEditContact,
} from "../../../../actions/users";
import { startChangeIsActiveShop } from "../../../../actions/shops";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_DELETE,
  ERROR,
  USERS_CONTACT_CREATED,
  USERS_CONTACT_DELETED,
  USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_CONTACT_DELETE_CONFIRMATION_TITLE,
  USERS_CONTACT_SAVED,
  USERS_EMAIL_DUPLICATE,
} from "../../../../locales/keysTranslations";

const ContactTab = ({ contactData, loading }) => {
  const [contactDataTable, setContactDataTable] = useState(contactData);
  const [open, setOpenModal] = useState(false);
  const [contactSelected, setContactSelected] = useState(null);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const { isActivePlan } = useSelectorApp((state) => state.auth);

  useEffect(() => {
    setContactDataTable(contactData);
  }, [contactData]);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setContactSelected(null);
  };

  const onSelectContact = (idContact) => {
    setContactSelected(idContact);
    setOpenModal(true);
  };

  const onCreateContact = async (data) => {
    if (!isActivePlan) {
      const result = await AlertInactivePlan();
      if (result.isConfirmed) {
        dispatch(startChangeIsActiveShop(true));
      }
      return false;
    }
    if (
      Object.keys(contactData).some(
        (key) => contactData[key].email === data.email
      )
    ) {
      SimpleAlert({
        title: t(ERROR),
        text: t(USERS_EMAIL_DUPLICATE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    const response = await dispatch(startCreateContact(data));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(USERS_CONTACT_CREATED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onEditContact = async (data) => {
    if (!isActivePlan) {
      const result = await AlertInactivePlan();
      if (result.isConfirmed) {
        dispatch(startChangeIsActiveShop(true));
      }
      return false;
    }
    if (
      Object.keys(contactData).some(
        (key) =>
          contactData[key].email === data.email && key !== contactSelected
      )
    ) {
      SimpleAlert({
        title: t(ERROR),
        text: t(USERS_EMAIL_DUPLICATE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    const response = await dispatch(startEditContact(contactSelected, data));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(USERS_CONTACT_SAVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onDeleteContact = async (id) => {
    const result = await ConfirmAlert({
      title: t(USERS_CONTACT_DELETE_CONFIRMATION_TITLE),
      text: t(USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startDeleteContact(id));
      if (response) {
        SimpleAlert({
          title: t(USERS_CONTACT_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
    onCloseModal();
  };
  return (
    <Box sx={{ mb: 3 }}>
      <HeaderContactTab
        contactData={contactData}
        onChangeContactDataTable={setContactDataTable}
        onOpenModal={onOpenModal}
      />
      <ContactTable
        data={contactDataTable}
        loading={loading}
        onSelectContact={onSelectContact}
        onDeleteContact={onDeleteContact}
      />
      {open && (
        <ModalContact
          open={open}
          data={contactData[contactSelected]}
          onCloseModal={onCloseModal}
          onCreateContact={onCreateContact}
          onEditContact={onEditContact}
          mode={!!contactSelected ? ACCOUNT_MODE_EDIT : ACCOUNT_MODE_CREATE}
        />
      )}
    </Box>
  );
};

export default ContactTab;
