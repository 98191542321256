import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AnswerContainer from "../../../Common/Forms/TypeForm/AnswerContainer";
import AnswerTitle from "../../../Common/Forms/TypeForm/AnswerTitle";
import Alert from "@mui/material/Alert";
import TextField from "../../../Common/Fields/TextField";
import {
  CREATE_ADS_CAMPAIGN_NAME_QUESTION,
  CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER,
  SELECT_A_OPTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
  CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION,
  CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION,
  CREATE_ADS_CAMPAIGN_GENDERS_QUESTION,
  MALES,
  FEMALES,
  CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION,
  CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION,
  CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
  BUTTON_REPLACE_VIDEO,
  BUTTON_SELECT_VIDEO,
  CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION,
  CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION,
  CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  META,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ActionsButtons from "../../../Common/Forms/TypeForm/ActionsButtons";
import Select from "../../../Common/Selects/Select";
import {
  marksAge,
  optionsAdsCampaignObjectives,
  textNumberCompactFormat,
} from "../../../../utils/slidersMarks";
import { Controller } from "react-hook-form";
import {
  ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE,
  ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  TIKTOK,
  VIDEO_FORMATS,
} from "../../../../utils/constants";
import { useLocale } from "../../../../Contexts/LocaleContext";
import NumberField from "../../../Common/NumberFormats/NumberField";
import Slider from "@mui/material/Slider";
import { GREY_SLIDER_COLOR } from "../../../../utils/colors";
import SearchSegmentationsField from "../../../Common/Fields/SearchSegmentationsField";
import IconButtonAddContent from "../../../Common/Modal/ModalGenerateSocialMediaContent/IconButtonAddContent";
import { useDispatchApp } from "../../../../lib/redux";
import { getShopID } from "../../../../actions/getters";
import CardMedia from "../../../Common/Media/CardMedia";
import SelectTrackingIDField from "../../../Common/Fields/SelectTrackingIDField";
import ButtonGenerateAI from "../../../Common/Buttons/ButtonGenerateAI";

const CreateAdsCampaignAdvancedForm = ({
  step,
  currentAnswer,
  stepVisible,
  error,
  onNextStep,
  onPreviousStep,
  setValue,
  control,
  getValues,
  watch,
  isCreating,
  onGenerateDescription,
  onChangePixels,
  currency,
}) => {
  const typeBudget = watch("typeBudget");
  const mediaUrl = watch("mediaUrl");
  const platform = watch("platform");
  const objective = watch("objective");

  const { t } = useTranslationApp();
  const LOCALE = useLocale();
  const CITIES = [...LOCALE?.cities]?.splice(0, 3).map((city) => city.label);

  const dispatch = useDispatchApp();
  const shopID = dispatch(getShopID());

  const maxLengthDescription =
    platform === META
      ? ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH
      : ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer
        stepPosition={"name"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_NAME_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "name" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="name"
          fullWidth
          placeholder={t(CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER)}
          variant="standard"
        />
        <ActionsButtons
          showPreviousButton={false}
          onNextStep={() => onNextStep("name", getValues("name"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"destinationURL"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "destinationURL" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="destinationURL"
          fullWidth
          placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
          variant="standard"
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("destinationURL", getValues("destinationURL"))
          }
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"objective"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION)}`}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION)}
        </Typography>
        {!!error && error.step === "objective" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Select
          options={optionsAdsCampaignObjectives}
          name="objective"
          displayEmpty={true}
          showEmptyOption={true}
          fullWidth
          placeholder={t(SELECT_A_OPTION)}
          doTranslate={true}
          control={control}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("objective", getValues("objective"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"trackingID"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "trackingID" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <SelectTrackingIDField
          control={control}
          name="trackingID"
          placeholder={t(SELECT_A_OPTION)}
          fullWidth
          variant="standard"
          platform={platform}
          onLoadOptions={onChangePixels}
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("trackingID", getValues("trackingID"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"budget"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(
            typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
              ? CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION
              : CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION
          )}`}
        </AnswerTitle>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t(VALUE_EXPRESSED_IN, {
            currency: (currency || LOCALE.currency).toUpperCase(),
          })}
        </Typography>
        {!!error && error.step === "budget" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          name="budget"
          fullWidth
          placeholder={t(CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER)}
          variant="standard"
          InputProps={{
            inputComponent: NumberField,
          }}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("budget", getValues("budget"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"ageRange"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "ageRange" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Controller
          name="ageRange"
          control={control}
          render={({ field }) => (
            <Slider
              sx={{
                width: "calc(100% - 24px)",
                ml: 1,
                ".MuiSlider-track": {
                  height: 2,
                },
                ".MuiSlider-rail": {
                  backgroundColor: GREY_SLIDER_COLOR,
                  height: 4,
                },
                ".MuiSlider-mark": {
                  backgroundColor: "transparent",
                },
              }}
              getAriaLabel={() => "Type Influencers Campaign"}
              valueLabelDisplay="auto"
              getAriaValueText={textNumberCompactFormat}
              step={1}
              marks={marksAge}
              min={18}
              max={64}
              valueLabelFormat={textNumberCompactFormat}
              {...field}
            />
          )}
        />
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("ageRange", getValues("ageRange"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"genders"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_GENDERS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "genders" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="genders"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.male}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          male: e.target.checked,
                        })
                      }
                      name="male"
                    />
                  )}
                />
              }
              label={t(MALES)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Controller
                  name="genders"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value.female}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          female: e.target.checked,
                        })
                      }
                      name="female"
                    />
                  )}
                />
              }
              label={t(FEMALES)}
            />
          </Grid>
        </Grid>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("genders", getValues("genders"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"locations"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "locations" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}

        <SearchSegmentationsField
          control={control}
          name="locations"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE}
          mode="fetching"
          objective={objective}
          platform={platform}
          placeholder={CITIES.join(", ")}
          fullWidth
          variant="standard"
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("locations", getValues("locations"))}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"interests"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "interests" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}

        <SearchSegmentationsField
          control={control}
          name="interests"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE}
          mode="fetching"
          objective={objective}
          platform={platform}
          placeholder={t(CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER)}
          fullWidth
          variant="standard"
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("interests", getValues("interests"))}
          loading={isCreating}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"behaviors"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "behaviors" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}

        <SearchSegmentationsField
          control={control}
          name="behaviors"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE}
          mode={platform === TIKTOK ? "fetching" : "load"}
          groupBy={["path", 0]}
          platform={platform}
          objective={objective}
          placeholder={t(CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER)}
          fullWidth
          variant="standard"
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("behaviors", getValues("behaviors"))}
          loading={isCreating}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"demographics"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "demographics" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}

        <SearchSegmentationsField
          control={control}
          name="demographics"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE}
          mode={platform === TIKTOK ? "fetching" : "load"}
          groupBy={["path", 0]}
          platform={platform}
          objective={objective}
          placeholder={t(CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER)}
          fullWidth
          variant="standard"
        />

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() =>
            onNextStep("demographics", getValues("demographics"))
          }
          loading={isCreating}
        />
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"description"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "description" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        <TextField
          control={control}
          multiline
          minRows={4}
          maxRows={5}
          name="description"
          placeholder={t(CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER)}
          fullWidth
          variant="standard"
          showCounterCharacters={true}
          maxCharacters={maxLengthDescription}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("description", getValues("description"))
            }
            loading={isCreating}
          />
          <ButtonGenerateAI
            useGradient={false}
            sx={{ width: "min-content" }}
            onCallback={onGenerateDescription}
          />
        </Box>
      </AnswerContainer>
      <AnswerContainer
        stepPosition={"mediaUrl"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerTitle sx={{ mb: 1 }}>
          {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION)}`}
        </AnswerTitle>
        {!!error && error.step === "mediaUrl" && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {error.text}
          </Alert>
        )}
        {mediaUrl && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <CardMedia
              url={mediaUrl}
              sx={{
                width: "100%",
                maxWidth: 150,
                height: "auto",
                position: "relative",
              }}
            />
          </Box>
        )}
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onFinish={() => onNextStep("mediaUrl", getValues("mediaUrl"), true)}
          customComponentPreviousSave={
            <Controller
              name="mediaUrl"
              control={control}
              render={({ field }) => (
                <IconButtonAddContent
                  mode="button"
                  color={mediaUrl ? "primary" : "secondary"}
                  size="large"
                  pathStorage={`adsCampaigns/${shopID}`}
                  onAddContent={(contents) => field.onChange(contents[0].url)}
                  multiple={false}
                  acceptedFormats={VIDEO_FORMATS}
                  showContent={false}
                  urlContent={mediaUrl}
                  textButton={
                    mediaUrl ? BUTTON_REPLACE_VIDEO : BUTTON_SELECT_VIDEO
                  }
                  sx={{
                    mt: 2,
                    alignItems: "flex-start",
                  }}
                  sxButton={{
                    height: 40,
                    fontSize: "0.875rem",
                  }}
                />
              )}
            />
          }
          showSaveButton={mediaUrl}
          showNextButton={false}
          loading={isCreating}
        />
      </AnswerContainer>
    </Box>
  );
};

export default CreateAdsCampaignAdvancedForm;
