import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import SelectPlanPackage from "../../../../Common/PlanPackage/SelectPlanPackage";
import ModalBasicLayout from "../../../../Common/Modal/ModalBasicLayout";
import { MODAL_SELECT_PACKAGE_ARIA_LABEL } from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { useTheme } from "@mui/material";

const ModalSelectPackage = ({
  modalOpen,
  onCloseModal,
  showHelpVideo = true,
  isProrated = false,
  onCallbackPayPackage = () => {},
}) => {
  const refContainer = useRef();
  const [step, setStep] = useState("selectPlan");
  const theme = useTheme();
  const maxWidth = step === "paying" ? "sm" : "lg";

  const { t } = useTranslationApp();

  useEffect(() => {
    if (!refContainer.current) return;
    refContainer.current.scrollTop = 0;
  }, [step]);
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={step !== "paid" ? onCloseModal : () => {}}
      maxWidth={maxWidth}
      fullWidth
      scroll="paper"
      ariaLabel={t(MODAL_SELECT_PACKAGE_ARIA_LABEL)}
      sxContent={{ p: 0, px: 0 }}
      hasTitle={false}
      refContent={refContainer}
    >
      <Box
        sx={{
          position: "absolute",
          left: { xs: "10px", sm: "15px" },
          top: { xs: "10px", sm: "15px" },
          zIndex: 1000,
        }}
      >
        {step !== "paid" && (
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              p: 0.5,
              fontSize: "1.4rem",
              color: "background.paperSecondaryContrastText",
              backgroundColor: "background.paperSecondary",
              "&:hover": {
                color: "background.paperSecondaryContrastText",
                backgroundColor: "background.paperSecondary",
                filter: theme.palette.hover.main,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <SelectPlanPackage
        onCallbackPayPackage={onCallbackPayPackage}
        onCallbackFinish={() => {
          onCloseModal();
        }}
        onChangeStep={setStep}
        onCloseModal={onCloseModal}
        isProrated={isProrated}
        refContainer={refContainer}
      />
    </ModalBasicLayout>
  );
};

export default ModalSelectPackage;
