import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Notification from "./Notification";
import Loader from "../../Common/Loader/Loader";
import { NOTIFICATIONS_NO_MORE } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const NotificationsContainer = ({ data, lastItemRef, loadLastItem }) => {
  const { t } = useTranslationApp();

  const keysSorted = Object.keys(data).sort(
    (a, b) => data[b].date.getTime() - data[a].date.getTime()
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        mb: 3,
        height: "100%",
      }}
    >
      {keysSorted.map((key, index) => {
        if (index === keysSorted.length - 1) {
          return (
            <Box key={index}>
              <Notification data={data[key]} notificationKey={key} />
              <div ref={lastItemRef}></div>
            </Box>
          );
        }
        return (
          <Notification key={index} data={data[key]} notificationKey={key} />
        );
      })}
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {loadLastItem ? (
          <Typography variant="h6">{t(NOTIFICATIONS_NO_MORE)}</Typography>
        ) : (
          <Loader size={30} />
        )}
      </Box>
    </Box>
  );
};

export default NotificationsContainer;
