import React, { useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ButtonSlideMenu from "../../Buttons/ButtonSlideMenu";
import { useTheme } from "@mui/material/styles";
import {
  PLATFORM_AMBASSADORS,
  PLATFORM_CAMPAIGNS,
  PLATFORM_INFLUENCERS,
  PLATFORM_INVITATIONS,
  PLATFORM_MENU,
  PLATFORM_ORDERS,
  PLATFORM_PUBLICATIONS,
  PLATFORM_REDEEM_CODE,
  PLATFORM_REPORTS,
  PLATFORM_REQUESTS,
  PLATFORM_USERS,
} from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  getIsBlockedActions,
  getNameBusinessTheme,
} from "../../../../actions/getters";
import {
  BUSINESS_ID_PROJECT,
  DESIGNER_ROLE,
  SUPER_ADMIN_ROLE,
} from "../../../../utils/constants";
import _ from "lodash";
import { MENU_SERVICES } from "../../../../locales/keysTranslations";
import LinkText from "../../Texts/LinkText";

const MenuGroup = ({
  menu,
  isMobile,
  onDrawerToggle,
  dataTour = "",
  hasRolesComponents,
}) => {
  const [open, setOpen] = useState(true);

  const theme = useTheme();

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const featureFlags = useSelectorApp((state) => state?.shop?.featureFlags);
  const { menusWithActions } = useSelectorApp((state) => state.ui);
  const {
    user: { role },
  } = useSelectorApp((state) => state.auth);
  const { paymentModel, plan } = useSelectorApp((state) => state.payments);
  const showSocialFeedGenerator = useSelectorApp(
    (state) => state?.shop?.showSocialFeedGenerator
  );

  const onClick = () => {
    setOpen(!open);
  };

  const tourObject = {
    creators: PLATFORM_INFLUENCERS,
    ambassadors: PLATFORM_AMBASSADORS,
    requests: PLATFORM_REQUESTS,
    invites: PLATFORM_INVITATIONS,
    publications: PLATFORM_PUBLICATIONS,
    campaigns: PLATFORM_CAMPAIGNS,
    reports: PLATFORM_REPORTS,
    redeemCode: PLATFORM_REDEEM_CODE,
    deliveries: PLATFORM_ORDERS,
    menu: PLATFORM_MENU,
    users: PLATFORM_USERS,
  };

  if (!menu.rolesList.includes(role)) return null;

  if (
    menu.name === MENU_SERVICES &&
    !showSocialFeedGenerator &&
    role === DESIGNER_ROLE
  )
    return null;

  return (
    <Box data-tour={dataTour} sx={{ mx: 2 }}>
      {menu.rolesList.includes(role) && menu.name ? (
        <Typography
          variant="body2"
          color={theme.palette.background.paperContrastText}
          sx={{ ml: 1, fontWeight: 300 }}
        >
          {t(menu.name)}
        </Typography>
      ) : (
        <Box sx={{ mt: hasRolesComponents ? 0 : -1.5 }} />
      )}
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {menu.subMenus.map((subMenu) => {
          if (!subMenu.roles.includes(role)) return null;

          if (subMenu.hiddenFeatureFlags) {
            const isHiddenFeatureFlags = subMenu.hiddenFeatureFlags.some(
              (featureFlag) => {
                return featureFlags[featureFlag.name] === featureFlag.value;
              }
            );

            if (isHiddenFeatureFlags) {
              return null;
            }
          }

          if (
            subMenu.hiddenWhiteLabel &&
            !_.isEmpty(BUSINESS_ID_PROJECT) &&
            role !== SUPER_ADMIN_ROLE
          )
            return null;

          if (subMenu.hasSubMenus) {
            return (
              <Box key={subMenu.nameKey}>
                <ButtonSlideMenu
                  startIcon={subMenu.icon}
                  endIcon={open ? <ExpandLess /> : <ExpandMore />}
                  onClick={onClick}
                >
                  {t(subMenu.name, { NAME_BUSINESS })}
                </ButtonSlideMenu>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {subMenu.subMenus.map((subSubMenu) => (
                    <Link
                      to={subSubMenu.link}
                      key={subSubMenu.nameKey}
                      onClick={isMobile && onDrawerToggle}
                      style={{
                        textDecoration: "none",
                        paddingLeft: "16px",
                      }}
                    >
                      <ButtonSlideMenu startIcon={subSubMenu.icon}>
                        {t(subSubMenu.name)}
                      </ButtonSlideMenu>
                    </Link>
                  ))}
                </Collapse>
              </Box>
            );
          }

          if (menusWithActions[subMenu.nameKey]) {
            return (
              <Link
                to={subMenu.link}
                key={subMenu.nameKey}
                onClick={() => {
                  if (subMenu.isBlockedInvites) {
                    const isBlocked = dispatch(
                      getIsBlockedActions({
                        verifyBlockedLastInvite: true,
                      })
                    );
                    if (isBlocked) return;
                  }

                  if (isMobile) onDrawerToggle();
                }}
                style={{ textDecoration: "none" }}
              >
                <ButtonSlideMenu
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    ml: "-4px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      ".MuiSvgIcon-root": {
                        width: 20,
                        height: 20,
                      },
                    }}
                  >
                    {subMenu.icon}
                    {t(subMenu.name, { NAME_BUSINESS })}
                  </Box>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: "red",
                      borderRadius: 3,
                    }}
                  ></Box>
                </ButtonSlideMenu>
              </Link>
            );
          }

          if (
            subMenu.validation &&
            subMenu.validation({ role, paymentModel, plan })
          ) {
            if (subMenu.isRedirect) {
              return (
                <LinkText
                  href={subMenu.link}
                  target="_blank"
                  key={subMenu.nameKey}
                  onClick={() => {
                    const isBlocked = dispatch(
                      getIsBlockedActions({
                        verifyBlockedLastInvite: true,
                      })
                    );
                    if (isBlocked) return;

                    if (isMobile) onDrawerToggle();
                  }}
                  sx={{ textDecoration: "none" }}
                  dataTour={tourObject[subMenu.nameKey]}
                >
                  <ButtonSlideMenu startIcon={subMenu.icon}>
                    {t(subMenu.name, { NAME_BUSINESS })}
                  </ButtonSlideMenu>
                </LinkText>
              );
            }

            return (
              <Link
                to={subMenu.link}
                key={subMenu.nameKey}
                onClick={() => {
                  if (subMenu.isBlockedInvites) {
                    const isBlocked = dispatch(
                      getIsBlockedActions({
                        verifyBlockedLastInvite: true,
                      })
                    );
                    if (isBlocked) return;
                  }

                  if (isMobile) onDrawerToggle();
                }}
                style={{ textDecoration: "none" }}
                data-tour={tourObject[subMenu.nameKey]}
              >
                <ButtonSlideMenu startIcon={subMenu.icon}>
                  {t(subMenu.name, { NAME_BUSINESS })}
                </ButtonSlideMenu>
              </Link>
            );
          }

          if (subMenu.isRedirect)
            return (
              <LinkText
                href={subMenu.link}
                target="_blank"
                key={subMenu.nameKey}
                onClick={() => {
                  if (subMenu.isBlockedInvites) {
                    const isBlocked = dispatch(
                      getIsBlockedActions({
                        verifyBlockedLastInvite: true,
                      })
                    );
                    if (isBlocked) return;
                  }

                  if (isMobile) onDrawerToggle();
                }}
                sx={{ textDecoration: "none" }}
                dataTour={tourObject[subMenu.nameKey]}
              >
                <ButtonSlideMenu startIcon={subMenu.icon}>
                  {t(subMenu.name, { NAME_BUSINESS })}
                </ButtonSlideMenu>
              </LinkText>
            );

          if (subMenu.customComponent)
            return (
              <Box key={subMenu.nameKey}>
                {React.cloneElement(subMenu.customComponent)}
              </Box>
            );
          return (
            <Link
              to={subMenu.link}
              key={subMenu.nameKey}
              onClick={() => {
                if (subMenu.isBlockedInvites) {
                  const isBlocked = dispatch(
                    getIsBlockedActions({
                      verifyBlockedLastInvite: true,
                    })
                  );
                  if (isBlocked) return;
                }

                if (isMobile) onDrawerToggle();
              }}
              style={{ textDecoration: "none" }}
              data-tour={tourObject[subMenu.nameKey]}
            >
              <ButtonSlideMenu startIcon={subMenu.icon}>
                {t(subMenu.name, { NAME_BUSINESS })}
              </ButtonSlideMenu>
            </Link>
          );
        })}
      </List>
    </Box>
  );
};

export default MenuGroup;
