import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonArrow from "../../Buttons/ButtonArrow";
import {
  BUTTON_APPROVE_CONTENT,
  BUTTON_PROMOTE,
  BUTTON_REVIEW,
  CURRENT_CONTENT_EMPTY,
  NO_SEND_CONTENT_FEEDBACK,
  READY,
} from "../../../../locales/keysTranslations";
import {
  ALERT_ICON_TYPE_SUCCESS,
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_STATUS_SUBMITTED,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_SENT,
  VIDEO_FORMATS,
} from "../../../../utils/constants";
import Button from "../../Buttons/Button";
import { useTranslationApp } from "../../../../lib/i18next";
import { getExtensionFile, openLink } from "../../../../utils/urls";
import { SimpleAlert } from "../../../../utils/alerts";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { startAcceptedApplicantContent } from "../../../../actions/campaigns";
import { REVIEW_BUTTON } from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import VideoPlayer from "../../Video/VideoPlayer";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import { getLinkRedirectAds } from "../../../../actions/getters";

const ModalMediaPublicationPlayer = ({
  data,
  showGalleryButtons,
  isAdminUser,
  showArrowButtons,
  showPrevButton,
  showNextButton,
  onPrev,
  onNext,
  onOpenModalReviewStatusChange,
  onFailedLoadUrl,
  onCloseModal,
}) => {
  const [isLoadingMedia, setIsLoadingMedia] = useState(true);
  const url = data?.url;
  const hls = data?.hls;

  const extension = getExtensionFile(url);
  const isVideo = VIDEO_FORMATS.includes(extension);
  const { t } = useTranslationApp();

  const refButton = useRef();

  const applicants = useSelectorApp((state) => state?.campaigns?.applicants);

  const { isOpen, currentStep, setCurrentStep } = useTour();

  useEffect(() => {
    setIsLoadingMedia(true);
  }, [url]);

  useEffect(() => {
    if (refButton?.current && isOpen) {
      setCurrentStep(currentStep + 1);
    }
    // eslint-disable-next-line
  }, [refButton]);

  const dispatch = useDispatchApp();

  const onApproveContent = async () => {
    let response;
    if (data.from === "order") {
      onOpenModalReviewStatusChange(true);
    }
    if (data.from === "applicant") {
      response = await dispatch(startAcceptedApplicantContent(data.key));
    }
    if (response) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const hasReviewButton =
    (data.from === "order" && data?.postStatus === POST_STATUS_SENT) ||
    (data.from === "applicant" &&
      data?.postStatus === APPLICANT_STATUS_DELIVERED &&
      !applicants[data.key].reviewID);
  const hasApprovalButton =
    ((data.from === "order" &&
      data?.postStatus === POST_STATUS_PENDING_APPROVAL) ||
      (data.from === "applicant" &&
        data?.postStatus === APPLICANT_STATUS_SUBMITTED)) &&
    isAdminUser;

  const isFeedbackPublicationOrder =
    data?.from === "order" && data?.postStatus === POST_STATUS_NEGATIVE_REVIEW;

  const hasMainActionButtons =
    (hasReviewButton || hasApprovalButton || (isVideo && !data.isEmpty)) &&
    !isLoadingMedia;

  const commonSxButton = {
    fontSize: "1.035rem",
    width: "100%",
  };
  return (
    <Box
      sx={{
        height: "72%",
        display: "flex",
        justifyContent: "center",
        maxWidth: "100vw",
        px: 1,
        flexGrow: 1,
      }}
      className="modal-media-content"
    >
      <ButtonArrow
        show={showArrowButtons && showPrevButton && showGalleryButtons}
        orientation="prev"
        onClickAction={onPrev}
        hasBackground={true}
        sxContainer={{
          position: "absolute",
          top: "50%",
          left: 16,
          zIndex: 100,
        }}
      />
      <Box
        sx={{
          position: "relative",
        }}
      >
        {hasMainActionButtons && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              zIndex: 100,
              background: "rgb(255 255 255 / 25%)",
              height: 80,
              gap: 1,
              px: 1,
            }}
          >
            {hasReviewButton && (
              <Button
                color="primary"
                refButton={refButton}
                dataTour={REVIEW_BUTTON}
                onClick={() => onOpenModalReviewStatusChange(true)}
                sx={commonSxButton}
              >
                {t(BUTTON_REVIEW)}
              </Button>
            )}
            {hasApprovalButton && (
              <Button
                color="primary"
                onClick={onApproveContent}
                sx={commonSxButton}
              >
                {t(BUTTON_APPROVE_CONTENT)}
              </Button>
            )}
            {isVideo && (
              <Button
                onClick={async () => {
                  const urlRedirect = await dispatch(
                    getLinkRedirectAds({
                      path: "/create-ads-campaign",
                      params: {
                        content: url,
                        mode: "quick",
                      },
                    })
                  );
                  openLink(urlRedirect);
                }}
                sx={{
                  background: GRADIENT_PURPLE_FUCHSIA,
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  ...commonSxButton,
                }}
              >
                {t(BUTTON_PROMOTE)}
              </Button>
            )}
          </Box>
        )}
        {data.isEmpty ? (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "white !important",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            {t(
              isFeedbackPublicationOrder
                ? NO_SEND_CONTENT_FEEDBACK
                : CURRENT_CONTENT_EMPTY
            )}
          </Typography>
        ) : (
          <>
            {isVideo ? (
              <VideoPlayer
                options={{
                  autoplay: true,
                  controls: true,
                  responsive: true,
                  fluid: true,
                  experimentalSvgIcons: true,
                }}
                url={hls || url}
                sx={{
                  position: "relative",
                  width: "auto",
                  height: "100%",
                  objectPosition: "center",
                }}
                onCallbackLoading={(value) => setIsLoadingMedia(value)}
                onError={onFailedLoadUrl}
              />
            ) : (
              <img
                onLoad={() => setIsLoadingMedia(false)}
                src={url}
                alt={`Media`}
                style={{
                  position: "relative",
                  width: "100%",
                  maxHeight: "100%",
                  height: "fit-content",
                  objectPosition: "top",
                  objectFit: "contain",
                }}
              />
            )}
          </>
        )}
      </Box>
      <ButtonArrow
        show={showArrowButtons && showNextButton && showGalleryButtons}
        orientation="next"
        onClickAction={onNext}
        hasBackground={true}
        sxContainer={{
          position: "absolute",
          top: "50%",
          right: 16,
          zIndex: 100,
        }}
      />
    </Box>
  );
};

export default ModalMediaPublicationPlayer;
