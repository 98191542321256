import React, { useState } from "react";
import { useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "../Containers/Accordion";
import { getDeliveryStatusInfoApp } from "../../../utils/formats";
import OrdenModal from "../../User/Delivery/OrdenModal";
import { OWNER_ROLE } from "../../../utils/constants";
import ContainerKeyValue from "../Containers/ContainerKeyValue";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BRAND,
  CREATORS_OWNER_REPORT_DELIVERIES,
  CREATORS_OWNER_REPORT_DELIVERIES_EMPTY,
  CREATORS_OWNER_REPORT_WAS_ACCEPTED,
  DATE_LONG,
  STATUS,
  VALUE,
  VALUE_WITH_CURRENCY,
  WAS_GENERATED,
} from "../../../locales/keysTranslations";
import { useLocales } from "../../../Contexts/LocaleContext";

const Delivery = ({ id, data, onSelectDelivery, influencer }) => {
  const { shops } = useSelectorApp((state) => state.shop);
  const { t } = useTranslationApp();
  const totalPrice = data.products
    ? data.products
        .map((product) => Number(product.price) * Number(product.quantity || 1))
        .reduce((partialSum, a) => partialSum + a, 0)
    : 0;

  const LOCALES = useLocales();
  const LOCALE = LOCALES[influencer?.signUpCountry || "co"];
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: 3,
        py: 1,
        px: 2,
        cursor: "pointer",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ContainerKeyValue
          keyValue="ID: "
          value={id}
          onClick={() => onSelectDelivery(id, data.status, totalPrice)}
        />
        <ContainerKeyValue
          keyValue={`${t(BRAND)}: `}
          value={shops[data.shopID]?.businessName ?? "-"}
          redirectURL="shop?shopID"
          redirectValue={data.shopID}
        />
        <ContainerKeyValue
          keyValue={`${t(VALUE)}: `}
          value={`${t(VALUE_WITH_CURRENCY, {
            value: totalPrice,
            currency: LOCALE.currency,
          })}`}
        />
        <ContainerKeyValue
          keyValue={`${t(STATUS)}: `}
          value={t(getDeliveryStatusInfoApp(data.status).label)}
        />

        {data.date && (
          <ContainerKeyValue
            keyValue={`${t(WAS_GENERATED)}: `}
            value={t(DATE_LONG, {
              date: new Date(data.date),
            })}
          />
        )}
        {data.dateOrderAccepted && (
          <ContainerKeyValue
            keyValue={`${t(CREATORS_OWNER_REPORT_WAS_ACCEPTED)}: `}
            value={t(DATE_LONG, {
              date: new Date(data.dateOrderAccepted),
            })}
          />
        )}
      </Box>
    </Box>
  );
};

const InfluencerDeliveries = ({
  data = {},
  userID,
  onDeliveredDelivery,
  influencer,
}) => {
  const [deliverySelected, setDeliverySelected] = useState(null);

  const { t } = useTranslationApp();

  const onSelectDelivery = (deliveryID, status, totalPrice) =>
    setDeliverySelected({ deliveryID, status, totalPrice });

  const onCloseModal = () => setDeliverySelected(null);

  const keysSorted = Object.keys(data).sort((a, b) => {
    if (data[a].date === undefined || data[b].date === undefined) return 0;
    return (
      new Date(data[b]?.date)?.getTime() - new Date(data[a]?.date)?.getTime()
    );
  });
  return (
    <Box sx={{ mx: 0, width: "100%" }}>
      <Accordion title={t(CREATORS_OWNER_REPORT_DELIVERIES)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: 230,
            overflowY: "auto",
            pl: 0,
            pr: 1,
          }}
        >
          {keysSorted.length === 0 ? (
            <Typography>{t(CREATORS_OWNER_REPORT_DELIVERIES_EMPTY)}</Typography>
          ) : (
            keysSorted.map((deliveryID) => {
              return (
                <Delivery
                  key={deliveryID}
                  id={deliveryID}
                  data={data[deliveryID]}
                  onSelectDelivery={onSelectDelivery}
                  influencer={influencer}
                />
              );
            })
          )}
        </Box>
      </Accordion>
      {deliverySelected && (
        <OrdenModal
          data={{
            ...data[deliverySelected.deliveryID],
            userID,
            orderID: deliverySelected.deliveryID,
            deliveryID: deliverySelected.deliveryID,
            totalPrice: deliverySelected.totalPrice,
          }}
          onCloseModal={onCloseModal}
          type={getDeliveryStatusInfoApp(deliverySelected.status).valueWeb}
          from={OWNER_ROLE}
          onDeliveredDeliveryCallback={onDeliveredDelivery}
        />
      )}
    </Box>
  );
};

export default InfluencerDeliveries;
