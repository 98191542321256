import React from "react";
import Reports from "./Reports";
import ModalBasicLayout from "../../../Common/Modal/ModalBasicLayout";
import {
  REPORTS_MODAL_DESCRIPTION,
  REPORTS_TITLE,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const ModalReports = ({ modalOpen, onCloseModal }) => {
  const { t } = useTranslationApp();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      title={t(REPORTS_TITLE)}
      description={t(REPORTS_MODAL_DESCRIPTION)}
    >
      <Reports />
    </ModalBasicLayout>
  );
};

export default ModalReports;
