import React, { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import GeneralStatisticsPointContainer from "./GeneralStatisticsPointContainer";
import DatesForm from "../DatesForm";
import { useForm } from "react-hook-form";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { useDispatchApp } from "../../../../lib/redux";
import { startGenerateStatistics } from "../../../../actions/owner";
import Loader from "../../../Common/Loader/Loader";
import GeneralStatisticsGraphs from "./GeneralStatisticsGraphs";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  FIELD_BOTH_DATES_REQUIRED,
  FIELD_START_DATE_GREATER_THAN_END_DATE,
  LOADING_INFORMATION,
  REPORTS_ERROR_GENERATING,
} from "../../../../locales/keysTranslations";

const GeneralStatistics = () => {
  const [data, setData] = useState(null);
  const { control, getValues } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const onCalculateStatistics = async () => {
    const formValues = getValues();
    formValues.startDate = startOfDay(formValues.startDate);
    formValues.endDate = endOfDay(formValues.endDate);

    if (!formValues.startDate || !formValues.endDate) {
      return setError(t(FIELD_BOTH_DATES_REQUIRED));
    }
    if (
      new Date(formValues.startDate).getTime() >
      new Date(formValues.endDate).getTime()
    ) {
      return setError(t(FIELD_START_DATE_GREATER_THAN_END_DATE));
    }
    setError(null);
    setLoading(true);
    const response = await dispatch(
      startGenerateStatistics({
        startDate: formValues.startDate,
        endDate: formValues.endDate,
      })
    );
    if (!response) {
      setLoading(false);
      return setError(t(REPORTS_ERROR_GENERATING));
    }

    setData(response);
    setLoading(false);
    return true;
  };

  return (
    <Box sx={{ width: "100%", minHeight: 250 }}>
      <DatesForm
        control={control}
        onActionButton={onCalculateStatistics}
        sx={{ mb: 2 }}
        colorButton="secondary"
      />
      {!!error && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        </Box>
      )}
      {loading && (
        <Loader
          size={70}
          fullWidth={true}
          hasMessage={true}
          variantMessage="h5"
          message={t(LOADING_INFORMATION)}
        />
      )}
      {data && !loading && <GeneralStatisticsPointContainer data={data} />}
      {data && !loading && <GeneralStatisticsGraphs data={data} />}
    </Box>
  );
};

export default GeneralStatistics;
