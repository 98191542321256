import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import ProfileInfluencerContainer from "../../User/Account/ProfileInfluencer/ProfileInfluencerContainer";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { BUTTON_CANCEL, BUTTON_SAVE } from "../../../locales/keysTranslations";

const ModalProfileInfluencer = ({
  modalOpen,
  onCloseModal,
  modeContainer = "normal",
  hasDividers = true,
  elevation = 0,
  showBottomButton = false,
  onCallbackSave = () => {},
  sx = {},
}) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      hasTitle={true}
    >
      <ProfileInfluencerContainer
        modeContainer={modeContainer}
        hasDividers={hasDividers}
        elevation={elevation}
        showBottomButton={showBottomButton}
        id="profile-influencer-form"
        className="profile-influencer-form"
        sx={sx}
        customComponentButtons={
          <ContainerModalActions
            hasPadding={false}
            propsSecondaryButton={{
              showButton: true,
              text: BUTTON_CANCEL,
              onClick: onCloseModal,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_SAVE,
              type: "submit",
              onClick: () => {},
            }}
          />
        }
        onCallbackSave={onCallbackSave}
      />
    </ModalBasicLayout>
  );
};

export default ModalProfileInfluencer;
