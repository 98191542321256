import React from "react";
import SelectMultiple from "../../../Common/Selects/SelectMultiple";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller } from "react-hook-form";
import { optionsAudienceAgeGender } from "../../../../utils/slidersMarks";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  FEMALE,
  FIELD_REQUIRED,
  MALE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import SelectCities from "../../../Common/Selects/SelectCities";
import SelectInfluencerCategories from "../../../Common/Selects/SelectInfluencerCategories";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProfileInfluencerFormCreator = ({ control, errors }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          sx={{
            fontWeight: 600,
          }}
        >
          {t(PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInfluencerCategories
          name="categories"
          label={PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: {
              value: (value) => {
                if (value.length < 3) {
                  return t(
                    PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE,
                    {
                      value: 3,
                    }
                  );
                }
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" variant="filled">
          <FormLabel component="legend" sx={{ fontWeight: 500 }}>
            {t(PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER)}
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="platforms"
                    rules={{
                      validate: {
                        value: (value) => {
                          if (!Object.values(value).includes(true))
                            return t(FIELD_REQUIRED);
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.instagram}
                        name="instagram"
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            instagram: e.target.checked,
                          })
                        }
                      />
                    )}
                  />
                }
                label="Instagram"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="platforms"
                    rules={{
                      validate: {
                        value: (value) => {
                          if (!Object.values(value).includes(true))
                            return t(FIELD_REQUIRED);
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.tiktok}
                        name="tiktok"
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            tiktok: e.target.checked,
                          })
                        }
                      />
                    )}
                  />
                }
                label="TikTok"
              />
            </Grid>
          </Grid>
          {!!errors["platforms"] && (
            <Typography variant="body2" color="error">
              {errors["platforms"]?.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectMultiple
          name="ageRange"
          label={t(PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE)}
          fullWidth={true}
          variant="filled"
          options={optionsAudienceAgeGender.map((option) => option.value)}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" variant="filled">
          <FormLabel component="legend" sx={{ fontWeight: 500 }}>
            {t(PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER)}
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="gender"
                    rules={{
                      validate: {
                        value: (value) => {
                          if (!Object.values(value).includes(true))
                            return t(FIELD_REQUIRED);
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.male}
                        name="male"
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            male: e.target.checked,
                          })
                        }
                      />
                    )}
                  />
                }
                label={t(MALE)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="gender"
                    rules={{
                      validate: {
                        value: (value) => {
                          if (!Object.values(value).includes(true))
                            return t(FIELD_REQUIRED);
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.female}
                        name="female"
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            female: e.target.checked,
                          })
                        }
                      />
                    )}
                  />
                }
                label={t(FEMALE)}
              />
            </Grid>
          </Grid>
          {!!errors["gender"] && (
            <Typography variant="body2" color="error">
              {errors["gender"]?.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectMultiple
          name="audienceAgeRange"
          label={t(
            PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER
          )}
          fullWidth={true}
          variant="filled"
          options={optionsAudienceAgeGender.map((option) => option.value)}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" variant="filled">
          <FormLabel component="legend" sx={{ fontWeight: 500 }}>
            {t(
              PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER
            )}
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="audienceGender"
                    rules={{
                      validate: {
                        value: (value) => {
                          if (!Object.values(value).includes(true))
                            return t(FIELD_REQUIRED);
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.male}
                        name="male"
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            male: e.target.checked,
                          })
                        }
                      />
                    )}
                  />
                }
                label={t(MALE)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="audienceGender"
                    rules={{
                      validate: {
                        value: (value) => {
                          if (!Object.values(value).includes(true))
                            return t(FIELD_REQUIRED);
                        },
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.female}
                        name="female"
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            female: e.target.checked,
                          })
                        }
                      />
                    )}
                  />
                }
                label={t(FEMALE)}
              />
            </Grid>
          </Grid>
          {!!errors["audienceGender"] && (
            <Typography variant="body2" color="error">
              {errors["audienceGender"]?.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectCities
          label={t(PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER)}
          variant="filled"
          name="cities"
          multiple={true}
          showAnyOption={true}
          control={control}
          errors={errors}
          rules={{
            validate: {
              value: (value) => {
                if (value.length === 0) {
                  return t(FIELD_REQUIRED);
                }
              },
            },
          }}
        />
      </Grid>
    </>
  );
};

export default ProfileInfluencerFormCreator;
