import React, { useContext, useState } from "react";
import { MenuContext } from "../../../Contexts/MenuContext";
import { useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CategoriesSection from "./CategoriesSection";
import Loader from "../../Common/Loader/Loader";
import ProductsSection from "./ProductsSection";
import ProductDetail from "./Products/ProductDetail";
import {
  ACCOMPANIMENT_MODE_CREATE,
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
} from "../../../utils/constants";
import ModalMoveProduct from "./Products/ModalMoveProduct";
import ProductSteper from "./Products/ProductSteper";
import ModalCopyAccompanimentType from "./Products/ModalCopyAccompanimentType";
import { useTranslationApp } from "../../../lib/i18next";
import { LOADING_INFORMATION } from "../../../locales/keysTranslations";

const MenuContainer = () => {
  const {
    // GENERAL
    menu,

    // STORES
    storeSelected,

    // MODAL
    modalMoveProduct,
    modalCopyAccompanimentType,
    onChangeStatusModalCopyAccompanimentType,
    onChangeStatusModalMoveProduct,

    // CATEGORIES
    categorySelected,

    // PRODUCTS
    productSelected,
    indexProduct,
    onMoveProduct,

    // ACCOMPANIMENTS TYPE
    onCopyAccompanimentType,
  } = useContext(MenuContext);

  const [heightWindow] = useState(window.innerHeight);
  const { user } = useSelectorApp((state) => state.auth);
  const userRole = user?.role;

  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
        height: {
          xs: `${
            heightWindow > 550
              ? `calc(100vh - ${
                  userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE
                    ? "319px"
                    : "196px"
                })`
              : "400px"
          }`,
          lg: `${
            heightWindow > 550
              ? `calc(100vh - ${
                  userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE
                    ? "276px"
                    : "196px"
                })`
              : "400px"
          }`,
        },
      }}
    >
      {!menu ? (
        <Loader
          size={70}
          hasMessage={true}
          message={t(LOADING_INFORMATION)}
          variantMessage="h5"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "auto",
              height: { xs: "40%", lg: "100%" },
            }}
          >
            <CategoriesSection userRole={userRole} />
            {categorySelected && (
              <>
                <Divider orientation="vertical" />
                <ProductsSection
                  data={menu[categorySelected]}
                  userRole={userRole}
                />
              </>
            )}
            <Divider orientation="vertical" flexItem />
          </Box>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ mt: { xs: 1, lg: 0 } }}
          />
          {productSelected === ACCOMPANIMENT_MODE_CREATE ? (
            <ProductSteper
              data={
                indexProduct.length > 0
                  ? menu[categorySelected]?.products[indexProduct] ?? {}
                  : undefined
              }
              pathRTDB={`menus/${user.shopID}/${storeSelected}/${categorySelected}/products/${indexProduct}`}
            />
          ) : (
            productSelected && (
              <ProductDetail
                data={menu[categorySelected]?.products[indexProduct]}
                pathRTDB={`menus/${user.shopID}/${storeSelected}/${categorySelected}/products/${indexProduct}`}
                userRole={userRole}
              />
            )
          )}
        </Box>
      )}
      {modalMoveProduct.open && (
        <ModalMoveProduct
          categories={menu}
          data={modalMoveProduct}
          onCloseModal={() =>
            onChangeStatusModalMoveProduct({
              open: false,
              product: {},
            })
          }
          onMoveProduct={onMoveProduct}
        />
      )}
      {modalCopyAccompanimentType && (
        <ModalCopyAccompanimentType
          categories={menu}
          data={modalCopyAccompanimentType}
          onCloseModal={() => onChangeStatusModalCopyAccompanimentType(false)}
          onCopyAccompanimentType={onCopyAccompanimentType}
        />
      )}
    </Box>
  );
};

export default MenuContainer;
