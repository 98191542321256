import types from "../types";

export const setOpenOnboarding = (isOpen) => ({
  type: types.SET_OPEN_ONBOARDING,
  payload: isOpen,
});
export const setStepsOnboarding = (steps) => ({
  type: types.SET_STEPS_ONBOARDING,
  payload: steps,
});
export const setStepOnboarding = (step) => ({
  type: types.SET_STEP_ONBOARDING,
  payload: step,
});
export const setSaveDataOnboarding = (value) => ({
  type: types.CHANGE_SAVE_DATA_ONBOARDING,
  payload: value,
});
export const setIsNewBrand = (value) => ({
  type: types.SET_IS_NEW_BRAND,
  payload: value,
});

export const changeShowPrevButton = (showPrevButton) => ({
  type: types.CHANGE_SHOW_PREV_BUTTON,
  payload: showPrevButton,
});

export const changeShowNextButton = (showNextButton) => ({
  type: types.CHANGE_SHOW_NEXT_BUTTON,
  payload: showNextButton,
});
