import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Box from "@mui/material/Box";
import ModalInvoice from "../components/User/BillingAndPayments/BillingAndPayments/ModalInvoice";
import {
  startGetPaymentModel,
  startGetPayments,
  startGetSubscriptionDate,
  startGetSubscriptionPlanInfo,
} from "../actions/payments";
import {
  PAYMENT_MODEL_PACKAGE,
  // PAYMENT_MODEL_SUBSCRIPTION,
  PAYMENT_MODEL_USE,
  PLANS_PACKAGE,
} from "../utils/constants";
import {
  starGetBillingData,
  startGetBasicShopInfo,
  startGetParentShopID,
} from "../actions/shops";
import ModalPayInvoice from "../components/Common/Modal/ModalPayInvoice";
import InvoicesTable from "../components/User/BillingAndPayments/BillingAndPayments/InvoicesTable";
import ModelUsage from "../components/User/BillingAndPayments/BillingAndPayments/ModelUsage/ModelUsage";
import ModelPackage from "../components/User/BillingAndPayments/BillingAndPayments/ModelPackage/ModelPackage";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import {
  BILLING_AND_PAYMENTS_DESCRIPTION,
  BILLING_AND_PAYMENTS_TITLE,
} from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const BillingAndPayments = () => {
  const [dataInvoices, setDataInvoices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const [modalPayInvoiceOpen, setModalPayInvoiceOpen] = useState(false);
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
  const [planData, setPlanData] = useState(null);

  const dispatch = useDispatchApp();
  const { user } = useSelectorApp((state) => state.auth);
  const { payments, plan, plans, paymentModel, subscriptionPlanInfo } =
    useSelectorApp((state) => state.payments);
  const { billing } = useSelectorApp((state) => state.shop);

  const { t } = useTranslationApp();

  useEffect(() => {
    (async () => {
      await dispatch(startGetPaymentModel());
      dispatch(starGetBillingData());
      dispatch(startGetParentShopID());
      dispatch(startGetSubscriptionDate());
      dispatch(
        startGetPayments({
          onCallbackFinish: () => setIsLoading(false),
        })
      );
    })();
    //eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    dispatch(startGetSubscriptionPlanInfo());
    //eslint-disable-next-line
  }, [plan]);

  useEffect(() => {
    billing.typeId.length === 0 && dispatch(startGetBasicShopInfo());
    //eslint-disable-next-line
  }, [billing]);

  useEffect(() => {
    let filteredData = {};
    if (Object.keys(payments).length > 0) {
      Object.keys(payments).map((key) => {
        return (filteredData = {
          ...filteredData,
          [key]: {
            ...payments[key],
            paidDate: payments[key].paidDate
              ? new Date(payments[key].paidDate)
              : "",
            dueDate: new Date(payments[key].dueDate),
          },
        });
      });
    }
    setDataInvoices(filteredData);
  }, [payments]);

  useEffect(() => {
    if (!PLANS_PACKAGE.includes(plan)) {
      setPlanData(plans[plan]);
    } else {
      setPlanData(subscriptionPlanInfo);
    }
    //eslint-disable-next-line
  }, [plan, plans, subscriptionPlanInfo]);

  const onChangeStatusModalInvoice = (status, invoice = null) => {
    setModalInvoiceOpen(status);
    setInvoiceSelected(invoice);
  };

  const onChangeStatusModalPayInvoice = (status, invoice = null) => {
    setModalPayInvoiceOpen(status);
    setInvoiceSelected(invoice);
  };

  const onPayInvoice = async (invoiceID) => {
    onChangeStatusModalPayInvoice(true, invoiceID);
  };

  return (
    <ContainerPage sx={{ height: "calc(100% - 88px)" }}>
      <TitlePage>{t(BILLING_AND_PAYMENTS_TITLE)}</TitlePage>
      <DescriptionPage>{t(BILLING_AND_PAYMENTS_DESCRIPTION)}</DescriptionPage>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {paymentModel === PAYMENT_MODEL_USE && (
          <ModelUsage planData={planData} dataInvoices={dataInvoices} />
        )}
        {paymentModel === PAYMENT_MODEL_PACKAGE && (
          <ModelPackage planData={planData} dataInvoices={dataInvoices} />
        )}
        <InvoicesTable
          data={dataInvoices}
          loading={isLoading}
          onSelectInvoice={(invoice) =>
            onChangeStatusModalInvoice(true, invoice)
          }
          onPayInvoice={onPayInvoice}
        />
      </Box>
      {modalInvoiceOpen && (
        <ModalInvoice
          data={dataInvoices[invoiceSelected]}
          invoice={invoiceSelected}
          modalOpen={modalInvoiceOpen}
          onCloseModal={() => onChangeStatusModalInvoice(false)}
        />
      )}
      {modalPayInvoiceOpen && (
        <ModalPayInvoice
          data={dataInvoices[invoiceSelected]}
          modalOpen={modalPayInvoiceOpen}
          invoiceID={invoiceSelected}
          onCloseModal={() => onChangeStatusModalPayInvoice(false)}
        />
      )}
    </ContainerPage>
  );
};

export default BillingAndPayments;
