import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CountDownTimer from "../../Common/CountDown/CountDownTimer";
import addDays from "date-fns/addDays";
import NameInfluencerCell from "../../Common/Influencer/NameInfluencerCell";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import {
  PAGE_INVITATIONS_TABLE,
  PAGE_INVITATIONS_TABLE_CODE,
  PAGE_INVITATIONS_TABLE_FROM,
  PAGE_INVITATIONS_TABLE_TO,
  PAGE_INVITATIONS_TABLE_USER,
  PAGE_INVITATIONS_TABLE_VALUE,
} from "../../../onboarding/stepsSelectors";
import { validateIsValidDate } from "../../../utils/dates";
import {
  BUTTON_VIEW_INVITE,
  DATE_SHORT,
  INVITATIONS_ACTIVE_TABLE_ARIA_LABEL,
  INVITATION_EXPIRED,
  VALUE_WITH_CURRENCY,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_ACTION,
  COLUMN_NAME_CODE_INVITATION,
  COLUMN_NAME_VALUE_INVITATION,
  COLUMN_NAME_USE_INVITATION,
  COLUMN_NAME_EXPIRATION_INVITATION,
  EMPTY_INVITATIONS,
  PROCESSING,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import InitChatButton from "../../Common/Chat/InitChatButton";
import ButtonCopy from "../../Common/Buttons/ButtonCopy";
import {
  getInfluencerIsHiddenFromTables,
  getInfluencerRedux,
} from "../../../actions/getters";
import { DEFAULT_INVITE_EXPIRY_DAYS } from "../../../utils/constants";
import { getCurrencyObject } from "../../../actions/getters";

const InvitationsActiveTable = ({
  data,
  loading,
  onSelectInvitation,
  rowsPerPage,
  page,
  onPageChange,
  onOpenChatInvite,
}) => {
  const { influencers } = useSelectorApp((state) => state.influencers);

  const keys = Object.keys(data);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const keysSorted = keys.sort(
    (a, b) => data[b].orderFrom.getTime() - data[a].orderFrom.getTime()
  );
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keysSorted}
        loading={loading}
        emptyText={EMPTY_INVITATIONS}
        ariaLabel={t(INVITATIONS_ACTIVE_TABLE_ARIA_LABEL)}
      >
        <TableHead>
          <TableRow data-tour={PAGE_INVITATIONS_TABLE}>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_TABLE_USER}
            >
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_TABLE_USER}
            >
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_TABLE_CODE}
            >
              {t(COLUMN_NAME_CODE_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_TABLE_VALUE}
            >
              {t(COLUMN_NAME_VALUE_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_TABLE_FROM}
            >
              {t(COLUMN_NAME_USE_INVITATION)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              dataTour={PAGE_INVITATIONS_TABLE_TO}
            >
              {t(COLUMN_NAME_EXPIRATION_INVITATION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keysSorted.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keysSorted
          ).map((key, index) => {
            if (
              dispatch(
                getInfluencerIsHiddenFromTables({
                  userID: data[key]?.userID,
                  validateIsDiscarded: false,
                })
              )
            )
              return null;
            if (Object.keys(influencers).length === 0) return null;

            const userID = data[key]?.userID;
            const influencer = dispatch(getInfluencerRedux(userID));
            const isExternal = influencer.isExternal;

            return (
              <TableRow key={key}>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  <NameInfluencerCell
                    userID={data[key].userID}
                    data={influencer}
                    name={influencer?.mainAccountName}
                    disabled={isExternal}
                    isInvited={true}
                    color="secondary"
                    textButtonInvited={t(
                      isExternal ? PROCESSING : BUTTON_VIEW_INVITE
                    )}
                    onClickButton={() => onSelectInvitation(key, "active")}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  <InitChatButton
                    type="icon"
                    onClick={() => onOpenChatInvite(key)}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {data[key].inviteCode}
                    <ButtonCopy value={data[key].inviteCode} />
                  </Box>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {t(VALUE_WITH_CURRENCY, {
                    value: data[key].value,
                    currency: dispatch(getCurrencyObject(data[key].currency)),
                  })}
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  {validateIsValidDate(data[key].orderFrom) &&
                    t(DATE_SHORT, {
                      date: new Date(data[key].orderFrom),
                    })}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CountDownTimer
                      targetDate={addDays(
                        data[key].orderFrom,
                        data[key].expiryDays
                          ? data[key].expiryDays
                          : DEFAULT_INVITE_EXPIRY_DAYS
                      )}
                      expiredMessage={t(INVITATION_EXPIRED)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default InvitationsActiveTable;
