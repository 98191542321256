import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TitlePage from "../../components/Common/Texts/TitlePage";
import Loader from "../../components/Common/Loader/Loader";
import FeedbacksTable from "../../components/Owner/Feedbacks/FeedbacksTable";
import { usePagination } from "../../hooks/usePagination";
import { DEFAULT_TABLE_PAGES } from "../../utils/constants";
import { startGetFeedbacks } from "../../actions/owner";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const Feedbacks = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatchApp();

  const feedbacks = useSelectorApp((state) => state.shop.feedbacks);

  const getNextFeedbacks = async () => {
    dispatch(
      startGetFeedbacks({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(
      startGetFeedbacks({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
        onCallBack: () => setIsLoading(false),
      })
    );
  };

  const { rowsPerPage, page, lastKey, onChangeLastKey, onPageChange } =
    usePagination({
      rowsPerPageValue: DEFAULT_TABLE_PAGES,
      onCallBackNextPage: getNextFeedbacks,
    });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(feedbacks);
    //eslint-disable-next-line
  }, [feedbacks]);

  return (
    <ContainerPage>
      <TitlePage>Feedbacks</TitlePage>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          mt: 2,
        }}
      >
        {isLoading ? (
          <Loader
            size={70}
            hasMessage={true}
            message="Cargando información"
            variantMessage="h5"
            fullWidth={true}
          />
        ) : (
          <FeedbacksTable
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        )}
      </Box>
    </ContainerPage>
  );
};

export default Feedbacks;
