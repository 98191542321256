import React, { useState } from "react";
import { useSelectorApp } from "../../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { capitalizeFirstLetter } from "../../../../utils/formats";
import Button from "../../../Common/Buttons/Button";
import ModalStripePaymentMethods from "../../../Common/Stripe/ModalStripePaymentMethods";
import ModalStripeAddCard from "../../../Common/Stripe/ModalStripeAddCard";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ADD_CARD,
  MAIN_PAYMENT_METHOD,
  NO_ADDED,
  PAYMENT_METHODS,
} from "../../../../locales/keysTranslations";

const CreditCard = () => {
  const [modalOpenStripeSources, setOpenModalStripeSources] = useState(false);
  const [modalOpenAddSource, setOpenModalAddSource] = useState(false);

  const { t } = useTranslationApp();

  const {
    defaultPaymentMethod: { brand, last4 },
  } = useSelectorApp((state) => state.payments.stripe);

  const onChangeStatusModal = (status) => {
    brand.length === 0 && last4.length === 0
      ? setOpenModalAddSource(status)
      : setOpenModalStripeSources(status);
  };

  const onAddPaymentMethod = () => setOpenModalAddSource(false);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
        {t(PAYMENT_METHODS)}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          p: 2,
          width: "100%",
          backgroundColor: "background.paperSecondary",
          height: "100%",
          boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" align="left">
            {`${t(MAIN_PAYMENT_METHOD)}:`}
          </Typography>
          <Typography variant="h6" align="left">
            {brand && last4
              ? `${capitalizeFirstLetter(brand)} •••• ${last4}`
              : t(NO_ADDED)}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="secondary"
            onClick={() => {
              onChangeStatusModal(true);
            }}
          >
            {t(ADD_CARD)}
          </Button>
        </Box>
      </Paper>
      {modalOpenStripeSources && (
        <ModalStripePaymentMethods
          modalOpen={modalOpenStripeSources}
          onCloseModal={() => onChangeStatusModal(false)}
        />
      )}
      {modalOpenAddSource && (
        <ModalStripeAddCard
          modalOpen={modalOpenAddSource}
          onSubmit={onAddPaymentMethod}
          onCloseModal={() => onChangeStatusModal(false)}
          saveCard={true}
          setDefaultCard={true}
        />
      )}
    </Box>
  );
};

export default CreditCard;
