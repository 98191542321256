import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ToBeAcceptModalSection from "./toBeAccept/ToBeAcceptModalSection";
import DetailOrdenModal from "./DetailOrdenModal";
import InPreparationModalSection from "./InPreparation/InPreparationModalSection";
import ToBeDeliveryModalSection from "./ToBeDelivery/ToBeDeliveryModalSection";
import {
  startAcceptDelivery,
  startDeliveredDelivery,
  startReadyDelivery,
  startRejectDelivery,
} from "../../../actions/deliveries";
import { ConfirmAlert, SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  GENERAL_REDEMPTION,
  INFLUENCER_ROLE,
} from "../../../utils/constants";
import { startGetInfluencer } from "../../../actions/influencers";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import { useTour } from "@reactour/tour";
import { ORDER_MODAL_LIST_TOTAL_PRICE } from "../../../onboarding/stepsSelectors";
import { waitDelay } from "../../../utils/dates";
import { useTranslationApp } from "../../../lib/i18next";
import {
  DELIVERY_SELECTED,
  DELIVERY_SELECTED_DESCRIPTION,
  ORDER,
  ORDER_ACCEPTED,
  ORDER_ACCEPTED_DESCRIPTION,
  ORDER_CANCELLED_CONFIRMATION,
  ORDER_CANCELLED_CONFIRMATION_BUTTON,
  ORDER_DELIVERED,
  ORDER_DELIVERED_DESCRIPTION,
  ORDER_READY,
  ORDER_READY_DESCRIPTION,
  ORDER_REJECTED,
  ORDER_REJECTED_DESCRIPTION,
  ORDER_TOTAL_VALUE,
  ORDER_TOTAL_VALUE_DISCOUNT,
  ORDER_TO_BE_DELIVERED_CONFIRMATION,
  ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON,
  PAYMENT_PAID,
  VALUE_WITH_CURRENCY,
} from "../../../locales/keysTranslations";

import { useLocale } from "../../../Contexts/LocaleContext";
import { numberRounded } from "../../../utils/numberFormats";
import PaidIcon from "@mui/icons-material/Paid";
import { useTheme } from "@mui/material";
const OrdenModal = ({
  data,
  onCloseModal,
  type,
  allData,
  setAllData,
  storeSelected,
  onStopSound,
  from = "user",
  onDeliveredDeliveryCallback = () => {},
}) => {
  const [influencer, setInfluencer] = useState(null);
  const matches = useMediaQuery("(min-width:900px)");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatchApp();

  const role = data?.userRole || INFLUENCER_ROLE;

  const LOCALE = useLocale();
  const theme = useTheme();
  const { t } = useTranslationApp();

  const { isOpen, currentStep, setCurrentStep } = useTour();

  const influencers = useSelectorApp((state) => state.influencers.influencers);

  useEffect(() => {
    (async () => {
      if (isOpen) {
        const influencerData = influencers[data.userID];
        setInfluencer(influencerData);
      } else if (role === INFLUENCER_ROLE) {
        const influencerData = await dispatch(startGetInfluencer(data.userID));
        setInfluencer(influencerData);
      }
    })();
    // eslint-disable-next-line
  }, [data.userID, isOpen]);

  const onAcceptOrden = async (minutes) => {
    const response = await dispatch(startAcceptDelivery(data, minutes));
    if (response) {
      onCloseModal();
      if (isOpen) {
        await waitDelay(500);
        return setCurrentStep(currentStep + 1);
      }
      SimpleAlert({
        title: t(ORDER_ACCEPTED),
        text: t(ORDER_ACCEPTED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const onReadyOrden = async (delivery) => {
    onStopSound();
    const response = await dispatch(startReadyDelivery(data, delivery));
    if (response) {
      onCloseModal();
      if (isOpen) {
        await waitDelay(500);
        return setCurrentStep(currentStep + 1);
      }
      SimpleAlert({
        title: t(ORDER_READY),
        text: t(ORDER_READY_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const onDelivered = async () => {
    if (!isOpen) {
      const result = await ConfirmAlert({
        title: t(ORDER_TO_BE_DELIVERED_CONFIRMATION),
        confirmButtonText: t(ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON),
      });
      if (!result.isConfirmed) return;
    }
    const response = await dispatch(startDeliveredDelivery(data));
    if (response) {
      onCloseModal();
      onDeliveredDeliveryCallback({
        orderID: data.orderID,
        deliveryID: data.deliveryID,
      });
      if (isOpen) {
        return setCurrentStep(currentStep + 1);
      }
      SimpleAlert({
        title: t(ORDER_DELIVERED),
        text: t(ORDER_DELIVERED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
  };

  const onSelectDelivery = (delivery) => {
    setAllData((prevState) => ({
      ...prevState,
      toBeDelivered: allData.toBeDelivered.map((item) => {
        if (item.id === data.id) {
          return {
            ...item,
            deliverySelected: delivery,
          };
        }
        return item;
      }),
    }));
    onCloseModal();
    SimpleAlert({
      title: t(DELIVERY_SELECTED),
      text: t(DELIVERY_SELECTED_DESCRIPTION, {
        delivery: delivery,
      }),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
  };

  const onRejectOrden = async () => {
    const result = await ConfirmAlert({
      title: t(ORDER_CANCELLED_CONFIRMATION),
      confirmButtonText: t(ORDER_CANCELLED_CONFIRMATION_BUTTON),
    });
    if (!result.isConfirmed) return;
    const response = await dispatch(startRejectDelivery(type, data));
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(ORDER_REJECTED),
        text: t(ORDER_REJECTED_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={!!data}
      fullWidth={true}
      onCloseModal={onCloseModal}
      maxWidth="md"
      scroll="paper"
      fullScreen
      ariaLabel="Modal de Orden"
      titleContent={false}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: {
            xs: "calc(100vw - 20px)",
            sm: "calc(100vw - 50px)",
            md: "calc(100vw - 100px)",
            lg: "calc(100vw - 300px)",
          },
          maxHeight: "calc(100vh - 8%)",
          borderRadius: 4,
          py: 1,
          pt: 0,
          backgroundImage: "none",
        },
      }}
      sxTitle={{
        backgroundColor:
          data.from === GENERAL_REDEMPTION
            ? "secondary.main"
            : "background.paper",
      }}
      customComponentTitle={
        <Box>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            align="left"
            sx={{
              fontWeight: 600,
              color:
                data?.from === GENERAL_REDEMPTION
                  ? theme.palette.secondary.contrastText
                  : theme.palette.text.primary,
            }}
          >{`${t(ORDER)}: ${data.deliveryID}`}</Typography>
          {data.from === GENERAL_REDEMPTION && (
            <Box sx={{ display: "flex", gap: 1 }}>
              <PaidIcon
                sx={{
                  color:
                    data?.from === GENERAL_REDEMPTION
                      ? theme.palette.secondary.contrastText
                      : theme.palette.text.primary,
                }}
              />
              <Typography
                variant={isMobile ? "body2" : "body1"}
                sx={{
                  color:
                    data?.from === GENERAL_REDEMPTION
                      ? theme.palette.secondary.contrastText
                      : theme.palette.text.primary,
                }}
              >
                {t(PAYMENT_PAID)}
              </Typography>
            </Box>
          )}
        </Box>
      }
      sxContent={{
        p: 0,
        px: 0,
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: { xs: 1, md: 3 },
        overflow: "auto",
      }}
    >
      <DetailOrdenModal data={data} />
      <Box
        sx={{
          width: { xs: "100%", md: "40%" },
          height: { xs: "auto", md: "100%" },
          overflow: { xs: "auto", md: "hidden" },
        }}
      >
        {type === "toBeAccepted" && (
          <ToBeAcceptModalSection
            data={data}
            from={from}
            influencer={influencer}
            onAcceptOrden={(minutes) => onAcceptOrden(minutes)}
            onRejectOrden={onRejectOrden}
          />
        )}
        {type === "inPreparation" && (
          <InPreparationModalSection
            data={data}
            from={from}
            influencer={influencer}
            onReadyOrden={(delivery) => onReadyOrden(delivery)}
            onRejectOrden={onRejectOrden}
          />
        )}
        {type === "toBeDelivered" && (
          <ToBeDeliveryModalSection
            data={data}
            from={from}
            influencer={influencer}
            onSelectDelivery={(delivery) => onSelectDelivery(delivery)}
            onDelivered={onDelivered}
          />
        )}
      </Box>
      {matches && (
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            px: 5,
            backgroundColor: "background.paper",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: { xs: "100%", md: "60%" },
              ml: "0px !important",
            }}
          >
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontWeight: 600 }}
            >
              {t(ORDER_TOTAL_VALUE)}
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontWeight: 600 }}
              data-tour={ORDER_MODAL_LIST_TOTAL_PRICE}
            >
              {t(VALUE_WITH_CURRENCY, {
                value: data.totalPrice,
                currency: LOCALE.currency,
              })}
            </Typography>
          </Box>
          {data.discount && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: { xs: "100%", md: "60%" },
                ml: "0px !important",
              }}
            >
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontWeight: 600 }}
              >
                {t(ORDER_TOTAL_VALUE_DISCOUNT, { value: data.discount })}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                sx={{ fontWeight: 600 }}
              >
                {t(VALUE_WITH_CURRENCY, {
                  value: numberRounded(
                    data.totalPrice - (data.totalPrice * data.discount) / 100
                  ),
                  currency: LOCALE.currency,
                })}
              </Typography>
            </Box>
          )}
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default OrdenModal;
