import React from "react";
import { Navigate } from "react-router-dom";
import {
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  DESIGNER_ROLE,
} from "../../utils/constants";
import { useSelectorApp } from "../../lib/redux";

const DesignerRoute = ({ children, isLogged }) => {
  const { user } = useSelectorApp((state) => state.auth);
  return isLogged &&
    user.shopID &&
    (user.role === ADMIN_ROLE ||
      user.role === SUPER_ADMIN_ROLE ||
      user.role === DESIGNER_ROLE) ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default DesignerRoute;
