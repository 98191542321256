import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfluencerNameLogo from "../../Common/Influencer/InfluencerNameLogo";
import {
  ORDER_MODAL_LIST_TOTAL_PRICE,
  ORDER_MODAL_LIST_USER,
} from "../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ADDRESS,
  CLIENT,
  EMAIL,
  IDENTIFICATION_NUMBER,
  NAME,
  ORDER_TOTAL_VALUE,
  ORDER_TOTAL_VALUE_DISCOUNT,
  PHONE_NUMBER,
  VALUE_WITH_CURRENCY,
} from "../../../locales/keysTranslations";
import { useLocale } from "../../../Contexts/LocaleContext";
import Loader from "../../Common/Loader/Loader";
import { numberRounded } from "../../../utils/numberFormats";
import {
  GENERAL_REDEMPTION,
  INFLUENCER_ROLE,
  SHOPPER_ROLE,
} from "../../../utils/constants";
const ClientInfoModal = ({ data, influencer, totalPrice }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();

  const role = data?.userRole || INFLUENCER_ROLE;

  const getAddress = (address) => {
    if (!address) return "-";

    const { address: street, city, cityCountry, country, details } = address;

    const cityAndCountry =
      city && country ? `${city}, ${country}` : cityCountry;

    const fullAddress = `${street} ${details ?? ""}, ${cityAndCountry}`;

    return fullAddress;
  };
  const getPhone = () => {
    if (role === INFLUENCER_ROLE) {
      if (influencer?.signUpPhone) return influencer?.signUpPhone;
      if (influencer?.mainAddress?.userInfo?.phoneNumber)
        return influencer?.mainAddress?.userInfo?.phoneNumber;
    }

    if (role === SHOPPER_ROLE) {
      if (data?.address?.userInfo?.phoneNumber)
        return data?.address?.userInfo?.phoneNumber;
    }

    return "-";
  };
  const matches = useMediaQuery("(max-width:900px)");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (!influencer && role === INFLUENCER_ROLE) return <Loader fullWidth />;

  return (
    <Box sx={{ mb: 1 }}>
      <Typography
        variant={isMobile ? "body2" : "body1"}
        sx={{ color: "#757575" }}
      >
        {t(CLIENT)}
      </Typography>
      <Paper
        elevation={1}
        data-tour={ORDER_MODAL_LIST_USER}
        sx={{
          backgroundColor: "background.paperSecondary",
          backgroundImage: "none",
          px: 2,
          py: { xs: 1, md: 2 },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {influencer && role === INFLUENCER_ROLE && (
          <InfluencerNameLogo data={influencer} userID={data.userID} />
        )}
        <Typography variant="body2">
          {`${t(ADDRESS)}: ${getAddress(data.address)}`}
        </Typography>
        <Typography variant="body2">
          {`${t(NAME)}: ${data?.address?.userInfo?.fullName ?? "-"}`}
        </Typography>
        <Typography variant="body2">
          {`${t(IDENTIFICATION_NUMBER)}: ${
            data?.address?.userInfo?.idNumber ?? "-"
          }`}
        </Typography>
        {influencer?.email && role === INFLUENCER_ROLE && (
          <Typography variant="body2">
            {`${t(EMAIL)}: ${influencer?.email}`}
          </Typography>
        )}
        <Typography variant="body2">
          {`${t(PHONE_NUMBER)}: ${getPhone()}`}
        </Typography>

        {matches && (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              color="inherit"
              sx={{ fontWeight: 600 }}
              data-tour={ORDER_MODAL_LIST_TOTAL_PRICE}
            >
              {`${t(ORDER_TOTAL_VALUE)}: ${t(VALUE_WITH_CURRENCY, {
                value: totalPrice,
                currency: LOCALE.currency,
              })}`}
            </Typography>
            {data.from === GENERAL_REDEMPTION && (
              <Typography
                variant={isMobile ? "body2" : "body1"}
                color="inherit"
                sx={{ fontWeight: 600 }}
                data-tour={ORDER_MODAL_LIST_TOTAL_PRICE}
              >
                {`${t(ORDER_TOTAL_VALUE_DISCOUNT, {
                  value: data.discount,
                })}: ${t(VALUE_WITH_CURRENCY, {
                  value: numberRounded(
                    data.totalPrice - (data.totalPrice * data.discount) / 100
                  ),
                  currency: LOCALE.currency,
                })}`}
              </Typography>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
};

export default ClientInfoModal;
