import { useRef, useCallback, useState } from "react";

const DECISION_THRESHOLD = 100;

const useDraggableCard = ({
  onCallback,
  disabledDragRight = true,
  disabledDragLeft = true,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const pullDeltaX = useRef(0);
  const cardRef = useRef(null);
  const startDrag = useCallback(
    (event) => {
      if (isDragging) return;
      if (disabledDragRight && disabledDragLeft) return;

      const actualCard = cardRef.current;
      if (!actualCard) return;

      const startX = event.pageX ?? event.touches[0].pageX;
      const screenWidth = window.innerWidth;

      const onMove = (event) => {
        const currentX = event.pageX ?? event.touches[0].pageX;
        const deltaX = currentX - startX;
        pullDeltaX.current = deltaX;

        if (deltaX === 0) return;

        if (disabledDragRight && deltaX > 0) return;
        if (disabledDragLeft && deltaX < 0) return;

        setIsDragging(true);

        const deg = deltaX / 14;
        actualCard.style.transform = `translateX(${deltaX}px) rotate(${deg}deg)`;
        actualCard.style.cursor = "grabbing";

        const opacity = Math.abs(deltaX) / 100;
        const isRight = deltaX > 0;

        const choiceEl = isRight
          ? actualCard.querySelector(".choice.like")
          : actualCard.querySelector(".choice.nope");

        if (choiceEl) choiceEl.style.opacity = opacity;

        // Prevent horizontal scroll
        if (Math.abs(deltaX) > screenWidth / 2) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "unset";
        }
      };

      const onEnd = (e) => {
        document.removeEventListener("mousemove", onMove);
        document.removeEventListener("mouseup", onEnd);
        document.removeEventListener("touchmove", onMove);
        document.removeEventListener("touchend", onEnd);

        const decisionMade = Math.abs(pullDeltaX.current) >= DECISION_THRESHOLD;

        const resetCard = () => {
          actualCard.removeAttribute("style");
          actualCard.classList.remove("reset");
          pullDeltaX.current = 0;
          setIsDragging(false);

          document.body.style.overflow = "unset";
        };

        if (decisionMade) {
          const goRight = pullDeltaX.current >= 0;
          const transitionCallback = () => {
            onCallback(goRight);
            resetCard();
            actualCard.removeEventListener("transitionend", transitionCallback);
          };

          actualCard.addEventListener("transitionend", transitionCallback);

          setTimeout(transitionCallback, 200);
        } else {
          actualCard.classList.add("reset");
          actualCard.classList.remove("go-right", "go-left");
          actualCard.querySelectorAll(".choice").forEach((choice) => {
            choice.style.opacity = 0;
          });
          resetCard();
        }

        // actualCard.addEventListener("transitionend", () => {
        //   actualCard.removeAttribute("style");
        //   actualCard.classList.remove("reset");
        //   pullDeltaX.current = 0;
        //   setIsDragging(false);

        //   document.body.style.overflow = "unset";
        // });

        actualCard.querySelectorAll(".choice").forEach((el) => {
          el.style.opacity = 0;
        });
      };

      document.addEventListener("mousemove", onMove);
      document.addEventListener("mouseup", onEnd);
      document.addEventListener("touchmove", onMove, { passive: true });
      document.addEventListener("touchend", onEnd, { passive: true });
    },
    // eslint-disable-next-line
    [onCallback]
  );

  return { cardRef, startDrag, isDragging };
};

export default useDraggableCard;
