import {
  FAQ_QUESTION_WHAT_IS_BUZZLY,
  FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_REQUEST,
  FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_PUBLICATION,
  FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_AMBASSADOR,
  FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_CAMPAIGN,
  FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_ORDER,
  FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_STORE,
  FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION,
  FAQ_QUESTION_WHAT_ROLES_EXISTS,
  FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION,
  FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS,
  FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION,
  FAQ_QUESTION_MUST_CALL_ADMIN,
  FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION,
  FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION,
  FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION,
  FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE,
  FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_INVITATION,
  FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION,
  FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS,
  FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION,
  FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS,
  FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION,
} from "../../../locales/keysTranslations";

export const FAQQuestions = [
  {
    title: FAQ_QUESTION_WHAT_IS_BUZZLY,
    content: FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_INVITATION,
    content: FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_REQUEST,
    content: FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_PUBLICATION,
    content: FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_AMBASSADOR,
    content: FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_CAMPAIGN,
    content: FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_ORDER,
    content: FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_IS_STORE,
    content: FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_WHAT_ROLES_EXISTS,
    content: FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS,
    content: FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_MUST_CALL_ADMIN,
    content: FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION,
    content:
      FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE,
    content: FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS,
    content:
      FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION,
  },
  {
    title: FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS,
    content:
      FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION,
  },
];
