import React, { useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  PAYMENT_MODEL_PACKAGE,
  PLAN_COLLABORATION,
} from "../../../utils/constants";
import { REGEX_EMAIL } from "../../../utils/regexsValidation";
import TooltipModal from "../Tooltips/TooltipModal";
import ContentInfoCard from "../Tooltips/Content/ContentInfoCard";
import StripeInformationCollected from "./StripeInformationCollected";
import AcceptTerms from "./AcceptTerms";
import { startAcceptTerms } from "../../../actions/shops";
import { startCreateStripeSource } from "../../../actions/payments";
import StripeSecurePayments from "./StripeSecurePayments";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import {
  ADD_CARD,
  CARD_INFORMATION_EXPLANATION_TITLE,
  EMAIL_PLACEHOLDER,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  FIELD_EMAIL_VALID,
  NAME_PLACEHOLDER,
  QUANTITY_TO_PAY,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import TextField from "../Fields/TextField";
import NumberField from "../NumberFormats/NumberField";
import { useTheme } from "@mui/material";
import ContainerModalActions from "../Containers/ContainerModalActions";

const StripePayForm = ({
  textButton = i18next.t(ADD_CARD),
  colorButton = "secondary",
  positionButton = "center",
  onSubmit = () => {},
  onCallbackFailed3DSecure = () => {},
  saveCard = true,
  setDefaultCard = false,
  amount: amountValue = "",
  onChangeAmount = () => {},
  isSpecificValue,
  lastText = "",
  externalValidation = false,
  sx = {},
  CustomComponent,
  CustomComponentDetailPayment,
}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    cardComplete: false,
  });
  const [amount, setAmount] = useState(amountValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [check, setCheck] = useState(false);

  const { paymentModel, plan } = useSelectorApp((state) => state.payments);
  const theme = useTheme();

  const { t } = useTranslationApp();

  const onChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const dispatch = useDispatchApp();
  const elements = useElements();
  const stripe = useStripe();

  const onAddPaymentMethod = async (paymentMethodID) => {
    let activatePlan = true;
    if (
      paymentModel === PAYMENT_MODEL_PACKAGE &&
      (plan !== PLAN_COLLABORATION || plan === "")
    ) {
      activatePlan = false;
    }
    const response = await dispatch(
      startCreateStripeSource({
        paymentMethodID,
        setDefault: setDefaultCard,
        activatePlan,
      })
    );
    return response?.ok;
  };

  const onCreatePaymentMethod = async (e) => {
    try {
      e.preventDefault();
      if (!REGEX_EMAIL.test(formValues.email))
        return setError(t(FIELD_EMAIL_VALID));
      if (!stripe || !elements) return;
      setLoading(true);

      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: {
          email: formValues.email,
          name: formValues.name,
        },
      });
      dispatch(startAcceptTerms());
      if (saveCard) {
        const response = await onAddPaymentMethod(
          paymentMethod.paymentMethod.id
        );
        if (!response) {
          setLoading(false);
          return;
        }
      }
      const response = await onSubmit({
        id: paymentMethod.paymentMethod.id,
        brand: paymentMethod.paymentMethod.card.brand,
        last4: paymentMethod.paymentMethod.card.last4,
      });
      if (response?.ok) {
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      SimpleAlert({
        title: t(ERROR),
        text: t(ERROR_DESCRIPTION_GENERIC, {
          message: error.message,
          code: error.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
  };

  return (
    <Box sx={{ ...sx }}>
      <StripeSecurePayments />
      <Grid
        component="form"
        justifyContent="center"
        alignItems="center"
        container
        spacing={2}
        sx={{ width: "100%", ml: 0 }}
      >
        {error && (
          <Grid item xs={12} sx={{ pl: "0px !important" }}>
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          </Grid>
        )}
        {isSpecificValue && (
          <Box sx={{ mb: 2 }}>
            <TextField
              value={amount}
              label={t(QUANTITY_TO_PAY)}
              variant="filled"
              fullWidth
              InputProps={{
                inputComponent: NumberField,
              }}
              onChange={(e) => {
                setAmount(e.target.value);
                onChangeAmount(e.target.value);
              }}
            />
          </Box>
        )}
        {CustomComponent && CustomComponent}
        <Grid item xs={12} sx={{ pl: "0px !important" }}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "background.paperSecondary",
              px: 2,
              py: 1,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, width: "100%" }}
              placeholder={t(NAME_PLACEHOLDER)}
              name="name"
              background="paperSecondary"
              inputProps={{ "aria-label": t(NAME_PLACEHOLDER) }}
              value={formValues.name}
              onChange={onChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pl: "0px !important" }}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "background.paperSecondary",
              px: 2,
              py: 1,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, width: "100%" }}
              background="paperSecondary"
              placeholder={t(EMAIL_PLACEHOLDER)}
              name="email"
              inputProps={{ "aria-label": t(EMAIL_PLACEHOLDER) }}
              value={formValues.email}
              onChange={onChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pl: "0px !important" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
            <TooltipModal
              title={t(CARD_INFORMATION_EXPLANATION_TITLE)}
              content={<ContentInfoCard />}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "background.paperSecondary",
              px: 2,
              py: 2,
              borderRadius: 1,
            }}
          >
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    color: theme.palette.text.primary,
                  },
                },
              }}
              onChange={(event) => {
                if (event.complete) {
                  onChange({
                    target: {
                      name: "cardComplete",
                      value: true,
                    },
                  });
                } else {
                  onChange({
                    target: {
                      name: "cardComplete",
                      value: false,
                    },
                  });
                }
              }}
            />
          </Box>
          <StripeInformationCollected />
        </Grid>
        {CustomComponentDetailPayment && (
          <Grid item xs={12} sx={{ pl: "0px !important" }}>
            {CustomComponentDetailPayment}
          </Grid>
        )}
        <AcceptTerms
          onChangeIsAcceptedTerms={(acceptedTerms) =>
            setIsAcceptedTerms(acceptedTerms)
          }
          onChangeCheck={(check) => setCheck(check)}
        />
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: positionButton,
            pl: "0px !important",
          }}
        >
          <ContainerModalActions
            hasPadding={false}
            propsPrimaryButton={{
              showButton: true,
              text: textButton,
              loading,
              onClick: onCreatePaymentMethod,
              disabled:
                !formValues.name ||
                !formValues.email ||
                !formValues.cardComplete ||
                (!isAcceptedTerms && !check) ||
                externalValidation,
            }}
          />
        </Grid>
        {lastText.length > 0 && (
          <Grid
            item
            xs={12}
            sx={{
              pl: "0px !important",
            }}
          >
            <Typography variant="h6" align="center" sx={{ mt: 1 }}>
              {lastText}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StripePayForm;
