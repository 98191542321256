import React, { useEffect } from "react";
import SelectMultiple from "./SelectMultiple";
import { CATEGORIES } from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startGetCustomTagsInfluencers } from "../../../actions/influencers";
import { capitalizeSections } from "../../../utils/formats";
import { influencersCategories } from "../../../utils/categories";

const SelectInfluencerCategories = ({
  control,
  rules = {},
  errors = {},
  disabled = false,
  label = CATEGORIES,
  placeholder,
  displayEmpty = false,
  showEmptyOption = false,
  variant = "filled",
  fullWidth = true,
  name = "categoriesSelected",
  backgroundColor = "",
  customOnChange,
  isRounded = true,
  showAddAction = false,
  onlyOriginalCategories = true,
  sx = {},
}) => {
  const categories = useSelectorApp((state) => state.influencers.categories);
  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  useEffect(() => {
    dispatch(startGetCustomTagsInfluencers());
    // eslint-disable-next-line
  }, []);

  //filtered repeated
  const finalOptions = [
    ...(onlyOriginalCategories ? influencersCategories : categories),
  ].sort((a, b) =>
    onlyOriginalCategories ? a.localeCompare(b) : a.label.localeCompare(b.label)
  );

  return (
    <SelectMultiple
      isRounded={isRounded}
      name={name}
      label={label ? t(label) : ""}
      placeholder={placeholder ? t(placeholder) : ""}
      disabled={disabled}
      options={finalOptions}
      variant={variant}
      displayEmpty={displayEmpty}
      showEmptyOption={showEmptyOption}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      customOnChange={customOnChange}
      control={control}
      showAddAction={showAddAction}
      rules={rules}
      errors={errors}
      formatOptions={onlyOriginalCategories ? "string" : "object"}
      renderValue={(selected) =>
        selected
          .map((option) => capitalizeSections(option.replace(/_/g, " "), " "))
          .join(", ")
      }
      sx={sx}
    />
  );
};

export default SelectInfluencerCategories;
