import React from "react";
import Box from "@mui/material/Box";
import RenderContentMedia from "../../../Common/Media/RenderContentMedia";
import { detectPlatform } from "../../../../utils/urls";

const ContentApplicantMedia = ({ data }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          overflow: "auto",
          gap: 1,
          pb: 1,
        }}
      >
        {data.map((media, index) => {
          const result = detectPlatform(media);
          return (
            <RenderContentMedia
              key={index}
              result={result}
              url={media}
              keyMedia={index}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ContentApplicantMedia;
