import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  ORDER_MODAL_LIST_MESSAGE,
  ORDER_MODAL_LIST_PRODUCTS,
} from "../../../onboarding/stepsSelectors";
import {
  VALUE_WITH_CURRENCY,
  ORDER_LIST_OF_PRODUCTS,
  ORDER_OPTIONS,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocale } from "../../../Contexts/LocaleContext";
import { useTheme } from "@mui/material";

const DetailOrdenModal = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const LOCALE = useLocale();
  return (
    <Box
      sx={{
        mb: { xs: 0, md: 2 },
        width: { xs: "100%", md: "60%" },
        pl: { xs: 2, md: 6 },
        pr: 2,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        minHeight: "35%",
      }}
    >
      <Typography variant={isMobile ? "body1" : "h6"} sx={{ my: 2 }}>
        {t(ORDER_LIST_OF_PRODUCTS)}
      </Typography>
      <Paper
        sx={{
          width: "100%",
          backgroundColor: "background.paperSecondary",
          backgroundImage: "none",
        }}
        data-tour={ORDER_MODAL_LIST_PRODUCTS}
      >
        {data?.products?.length > 0 &&
          data.products.map((product, index) => (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                borderBottom: 1,
                gap: 2,
              }}
              key={index}
            >
              <Box
                sx={{
                  p: { xs: 1, sm: 2 },
                  borderRight: 1,
                  borderLeft: `4px solid ${theme.palette.secondary.main}`,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={isMobile ? "body2" : "body1"}
                  sx={{ fontWeight: 600 }}
                >
                  {`${product.quantity}x`}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                    justifyContent: "space-between",
                    alignItems: {
                      xs: "flex-start",
                      sm: "center",
                    },
                    pr: { xs: 1, md: 2 },
                    gap: 1,
                  }}
                >
                  <Typography variant={isMobile ? "body2" : "body1"}>
                    {product.name}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"}>
                    {t(VALUE_WITH_CURRENCY, {
                      value: parseInt(product.price * product.quantity),
                      currency: LOCALE.currency,
                    })}
                  </Typography>
                </Box>
                {product.selectedOptions && (
                  <Box>
                    <Typography
                      variant={isMobile ? "body2" : "body1"}
                      sx={{ fontWeight: 600 }}
                    >
                      {t(ORDER_OPTIONS)}
                    </Typography>
                    <Box>
                      {Object.keys(product.selectedOptions).map((option) => {
                        return (
                          <Box key={option} sx={{ display: "flex" }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >{`${option}: `}</Typography>
                            <Typography variant="body2" sx={{ ml: "2px" }}>
                              {product.selectedOptions[option].join(", ")}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
      </Paper>
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end", mt: 2 }}>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          data-tour={ORDER_MODAL_LIST_MESSAGE}
        >
          {data.message ?? ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailOrdenModal;
