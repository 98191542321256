import _ from "lodash";
import {
  INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY,
  PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS,
  PRE_REGISTERED_INFLUENCER_INVITE_PROCESS,
} from "../utils/constants";
import startOfDay from "date-fns/startOfDay";
import { mapInfluencerCategories } from "./phyllo";

export const getInfluencersSuggestedStorage = () => {
  const suggestedInfluencers = JSON.parse(
    localStorage.getItem(INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY)
  ) || {
    influencers: {},
    lastUpdate: startOfDay(new Date().getTime()),
  };

  return suggestedInfluencers;
};

export const setInfluencersSuggestedStorage = (suggestedInfluencers) => {
  localStorage.setItem(
    INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY,
    JSON.stringify(suggestedInfluencers)
  );
};

export const removeInfluencersSuggestedStorage = (userID) => {
  const suggestedInfluencers = getInfluencersSuggestedStorage();
  if (_.isEmpty(suggestedInfluencers.influencers)) return;
  delete suggestedInfluencers.influencers[userID];
  setInfluencersSuggestedStorage(suggestedInfluencers);
};

export const getInfluencerFormattedFromPhyllo = (
  influencer,
  useCreatorLocation = true
) => {
  const { city, state, country } =
    influencer.creatorLocation || influencer?.location || {};
  const audienceInterests = influencer?.filterMatch?.audienceInterests || [];
  const creatorLocation = city || state || country;

  const categories = audienceInterests
    .map((interest) => mapInfluencerCategories(interest?.name, "intern"))
    ?.flat();

  return {
    ...influencer,
    creatorLocation: influencer.creatorLocation || influencer?.location || {},
    categories,
    imageURL: influencer.imageUrl,
    signUpCity: useCreatorLocation ? creatorLocation : influencer.signUpCity,
    isExternal: true,
  };
};

export const getProcessInfluencerFormatted = (process = {}) => {
  if (
    process.type !== PRE_REGISTERED_INFLUENCER_INVITE_PROCESS &&
    process.type !== PRE_REGISTERED_INFLUENCER_INVITE_CAMPAIGN_PROCESS
  ) {
    return {
      data: {},
      influencer: {
        mainAccountName: process.mainAccountName,
        mainPlatform: process.mainPlatform,
        imageURL: process.imageURL,
        isExternal: true,
      },
    };
  }

  if (process.type === PRE_REGISTERED_INFLUENCER_INVITE_PROCESS) {
    const inviteData = process?.actions?.[0]?.data || {};
    const path = process?.actions?.[0]?.path || "";

    const inviteID = path.split("/").pop();
    const userID = inviteData.userID;
    return {
      data: {
        [inviteID]: {
          ...inviteData,
          inviteID,
        },
      },
      influencer: {
        userID,
        mainAccountName: process.mainAccountName,
        mainPlatform: process.mainPlatform,
        imageURL: process.imageURL,
        isExternal: true,
      },
    };
  }

  return {
    data: {},
    influencer: {
      mainAccountName: process.mainAccountName,
      mainPlatform: process.mainPlatform,
      imageURL: process.imageURL,
      isExternal: true,
    },
  };
};
