import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CountDown from "./CountDown";
import { ORDER_IN_PREPARATION } from "../../../../onboarding/stepsSelectors";
import { BORDER_RADIUS, GENERAL_REDEMPTION } from "../../../../utils/constants";
import { ORDER_ID } from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { useTheme } from "@mui/material";
const KanbanInPreparationButton = ({
  data,
  onClickButton,
  setData,
  onLaunchSound,
  storeSelected,
}) => {
  const { t } = useTranslationApp();
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor:
          data?.from === GENERAL_REDEMPTION
            ? "secondary.main"
            : "background.paper",
        borderRadius: BORDER_RADIUS,
      }}
      data-tour={ORDER_IN_PREPARATION}
    >
      <Box
        onClick={() => onClickButton(data)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          px: 2,
          color:
            data?.from === GENERAL_REDEMPTION
              ? `${theme.palette.secondary.contrastText} !important`
              : `${theme.palette.primary.contrastText} !important`,
          textAlign: "center",
          maxHeight: "none",
          ":hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography
          variant="body1"
          color="inherit"
          sx={{ fontWeight: 600, wordBreak: "break-all", mr: 1 }}
        >
          {t(ORDER_ID, {
            id: data.deliveryID,
          })}
        </Typography>
        <CountDown onLaunchSound={onLaunchSound} data={data} />
      </Box>
    </Box>
  );
};

export default KanbanInPreparationButton;
