import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  DELETE_USER_BUTTON,
  EDIT_USER_BUTTON,
} from "../../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";

const ActionsUsersTable = ({
  uid,
  data,
  onSelectUser,
  onDeleteUser,
  isDisabled,
}) => {
  const { isOpen, setCurrentStep, currentStep } = useTour();
  return (
    <Box>
      <IconButton
        color="primary.contrastText"
        data-tour={DELETE_USER_BUTTON}
        onClick={() => onDeleteUser(uid)}
        disabled={isDisabled}
      >
        <DeleteIcon />
      </IconButton>
      <IconButton
        color="primary.contrastText"
        data-tour={EDIT_USER_BUTTON}
        onClick={() => {
          if (isOpen) setCurrentStep(currentStep + 1);
          onSelectUser(uid, data);
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default ActionsUsersTable;
