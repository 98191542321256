import React, { useState, useEffect, useImperativeHandle } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DEFAULT_CREATORS_CARDS,
  DELAY_DEBOUNCE,
  FROM_DATA_INFLUENCERS,
  FROM_DATA_PHYLLO,
  INSTAGRAM,
  SEARCH_INFLUENCERS_USERNAME_OPTION,
  TIKTOK,
} from "../../../utils/constants";
import {
  getCountry,
  getInfluencerIsHiddenFromTables,
  getIsInfluencerInvited,
} from "../../../actions/getters";
import InfluencerCard from "../Creators/InfluencerCard";
import { isFilteringInfluencers } from "../../../services/shops";
import Loader from "../../Common/Loader/Loader";
import {
  EMPTY_RESULTS_SHOW,
  LOADING_INFORMATION,
  NO_MORE_RESULTS_TO_SHOW,
  WE_ARE_DOING_A_DEEPER_SEARCH,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import { usePagination } from "../../../hooks/usePagination";
import {
  startGetInfluencerPhyllo,
  startGetInfluencersByMainAccountName,
  startGetInfluencersFavorites,
  startGetInfluencersPhylloByFilters,
  startGetInfluencersWithFilters,
  startGetNewInfluencers,
} from "../../../actions/influencers";
import { useDebounced } from "../../../hooks/useDebounce";
import useCreatorsFilters from "../../../hooks/useCreatorsFilters";
import { useTour } from "@reactour/tour";
import { STEPS_MODAL_INVITATION } from "../../../onboarding/steps";
import _ from "lodash";
import LinearLoaderWithTime from "../../Common/Loader/LinearLoaderWithTime";
import { DUMMY_INFLUENCER_LOCAL } from "../../../onboarding/stepsSelectors";
import {
  INTERNAL_SERVER_ERROR_PHYLLO,
  REQUEST_TIME_OUT_PHYLLO,
} from "../../../utils/errors";

const InfluencersListTable = ({ filters = {}, refAction }) => {
  const [data, setData] = useState({});
  const [fromData, setFromData] = useState(FROM_DATA_INFLUENCERS);
  const [isLoading, setIsLoading] = useState(true);
  const [emptyPlatformPhyllo, setEmptyPlatformPhyllo] = useState({
    [TIKTOK]: false,
    [INSTAGRAM]: false,
  });
  const [errorPhyllo, setErrorPhyllo] = useState(null);
  const [influencersDiscarded, setInfluencersDiscarded] = useState([]);
  const influencersLoadedByMainAccountName = Object.keys(data).map(
    (key) => data[key]?.mainAccountName
  );
  const { onInfluencerPassFilter } = useCreatorsFilters();
  const {
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    citySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
    topicsSelected,
    searchType,
    audienceLocations,
  } = filters;

  const { isOpen, currentStep } = useTour();
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const invites = useSelectorApp((state) => state.invites.invites);
  const { setSteps } = useTour();
  const signUpCountry = dispatch(getCountry("shop"));

  const isFiltering = isFilteringInfluencers({
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    citySelected,
    audienceLocations,
    bodyBuild,
    clothingStyle,
    distinguishingFeatures,
    contentStyle,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
    topicsSelected,
  });

  const getInformationIsInvited = (userID) => {
    const inviteID = dispatch(getIsInfluencerInvited(userID));
    if (inviteID) {
      return {
        isActive: true,
        isInvited: true,
        inviteID,
        userID,
      };
    }
    return {
      isActive: true,
      isInvited: false,
      userID,
    };
  };

  const doQueryFavorites = favoritesCategorySelected;
  const doQueryNew = newCategorySelected;
  const doQuerySearchByUserNameInfluencers = (finalFromData) =>
    searchText.length >= 3 &&
    searchType === SEARCH_INFLUENCERS_USERNAME_OPTION &&
    finalFromData === FROM_DATA_INFLUENCERS;
  const doQuerySearchByUserNamePhyllo = (finalFromData) =>
    searchText.length >= 3 &&
    searchType === SEARCH_INFLUENCERS_USERNAME_OPTION &&
    finalFromData === FROM_DATA_PHYLLO;
  const doQueryPhyllo = (finalFromData) => finalFromData === FROM_DATA_PHYLLO;
  const doQueryInfluencers = (finalFromData) =>
    finalFromData === FROM_DATA_INFLUENCERS;

  const onGetData = useDebounced(
    async (type = "first", forcedDataFrom) => {
      if (isOpen) return;
      let lastKeyToPass = lastKey;

      const dataFromToPass = forcedDataFrom || fromData;
      const emptyPlatformToPass =
        type === "first"
          ? {
              [TIKTOK]: false,
              [INSTAGRAM]: false,
            }
          : emptyPlatformPhyllo;

      if (type === "first") {
        onChangeLastKey(null);
        lastKeyToPass = null;
        onChangeLoadLastItem(false);
        setIsLoading(true);
      }

      let influencers;

      if (doQueryFavorites) {
        influencers = await dispatch(
          startGetInfluencersFavorites({
            limit: rowsPerPage,
            lastKey: lastKeyToPass,
            onChangeLastKey,
            onChangeLoadLastItem: () => onChangeLoadLastItem(true),
          })
        );
      } else if (doQueryNew) {
        influencers = await dispatch(
          startGetNewInfluencers({
            withRequests: false,
          })
        );
        onChangeLoadLastItem(true);
      } else if (doQuerySearchByUserNameInfluencers(dataFromToPass)) {
        influencers = await dispatch(
          startGetInfluencersByMainAccountName({ mainAccountName: searchText })
        );
        if (_.isEmpty(influencers)) {
          onGetData("first", FROM_DATA_PHYLLO);
          return;
        }
      } else if (doQuerySearchByUserNamePhyllo(dataFromToPass)) {
        influencers = await dispatch(
          startGetInfluencerPhyllo({ mainAccountName: searchText })
        );
        onChangeLoadLastItem(true);
      } else if (doQueryPhyllo(dataFromToPass)) {
        influencers = await dispatch(
          startGetInfluencersPhylloByFilters({
            gender: genderSelected,
            followers: rangeFollowers,
            engagement: rangeEngagement,
            city: citySelected,
            audienceLocations,
            lastKey: lastKeyToPass,
            topics: topicsSelected,
            onChangeLastKey: () => onChangeLastKey(lastKeyToPass + rowsPerPage),
            onLoadEmptyPlatform: (platform) => {
              setEmptyPlatformPhyllo((prev) => ({
                ...prev,
                [platform]: true,
              }));
            },
            onChangeErrorCode: (error) => setErrorPhyllo(error),
            onChangeLoadLastItem: () => onChangeLoadLastItem(true),
            platforms: Object.keys(emptyPlatformToPass).filter(
              (platform) => !emptyPlatformToPass[platform]
            ),
            limit: rowsPerPage,
          })
        );
        if (type !== "first" && !_.isEmpty(influencers)) {
          influencers = onFilterInfluencersPhylloAlreadyLoaded(influencers);
        }
      } else if (doQueryInfluencers(dataFromToPass)) {
        influencers = await dispatch(
          startGetInfluencersWithFilters({
            limit: rowsPerPage,
            onChangeLastKey: onChangeLastKey,
            onChangeLoadLastItem: () => {},
            gender: genderSelected,
            categories: topicsSelected?.map((topic) => topic?.value),
            followers: rangeFollowers,
            city: citySelected,
            audienceLocations,
            useScore: true,
            lastKey: lastKeyToPass,
          })
        );

        if (_.isEmpty(influencers) && dataFromToPass !== FROM_DATA_PHYLLO) {
          onChangeFromData(FROM_DATA_PHYLLO);
          if (type === "first") {
            onGetData("first", FROM_DATA_PHYLLO);
          }
          return;
        }
      }

      if (influencers) {
        const dataFormatted = {};

        Object.keys(influencers || {}).forEach((key) => {
          const influencer = influencers[key];
          const isInvited = getInformationIsInvited(key);

          dataFormatted[key] = {
            ...influencer,
            ...isInvited,
          };
        });

        if (type === "first") {
          setData(dataFormatted);
        } else {
          setData({
            ...data,
            ...dataFormatted,
          });
        }
      }

      setIsLoading(false);
    },
    [DELAY_DEBOUNCE]
  );

  const onFilterInfluencersPhylloAlreadyLoaded = (newInfluencers) => {
    const influencersFiltered = {};
    Object.keys(newInfluencers).forEach((key) => {
      const influencer = newInfluencers[key];
      if (
        influencersLoadedByMainAccountName.includes(influencer?.mainAccountName)
      )
        return;

      influencersFiltered[key] = influencer;
    });
    return influencersFiltered;
  };

  const onChangeFromData = (value) => {
    setFromData(value);
    onChangeLastKey(value === FROM_DATA_INFLUENCERS ? null : 0);
  };

  const onFilterData = () => {
    const influencersKeys = Object.keys(data);
    if (influencersKeys.length === 0) return {};

    const filtered = {};

    for (let influencerKey of influencersKeys) {
      const influencer = data[influencerKey] || {};

      if (influencersDiscarded.includes(influencerKey) && isMobile) continue;

      const passFilter = onInfluencerPassFilter({
        influencer,
        userID: influencerKey,
        filters,
      });

      if (!passFilter) continue;

      filtered[influencerKey] = influencer;
    }

    return filtered;
  };

  const onDiscardInfluencer = (userID) => {
    if (keysSorted.length - 1 === 0) {
      setIsLoading(true);
    }
    if (keysSorted.length === 1) {
      onPageChange(page + 1);
    }
    setInfluencersDiscarded((prev) => [...prev, userID]);
  };

  const dataFiltered = onFilterData();
  const keysSorted = Object.keys(dataFiltered).sort(
    (a, b) => dataFiltered[b]?.score - dataFiltered[a]?.score
  );

  const currentCreator = data[keysSorted[0]];

  const {
    fromRef,
    page,
    onPageChange,
    lastKey,
    onChangeLastKey,
    rowsPerPage,
    loadLastItem,
    onChangeLoadLastItem,
  } = usePagination({
    rowsPerPageValue: DEFAULT_CREATORS_CARDS,
    distance: "700px",
    onCallBackNextPage: () => {
      onGetData("next");
    },
  });

  const resetStatesAndDoNewQuery = () => {
    setEmptyPlatformPhyllo({
      [TIKTOK]: false,
      [INSTAGRAM]: false,
    });
    setErrorPhyllo(null);
    onChangeFromData(FROM_DATA_INFLUENCERS);
    setIsLoading(true);
    onGetData("first", FROM_DATA_INFLUENCERS);
  };

  useEffect(() => {
    if (!isOpen && data[DUMMY_INFLUENCER_LOCAL]) {
      const dataFiltered = { ...data };
      delete dataFiltered[DUMMY_INFLUENCER_LOCAL];
      setData(dataFiltered);
    }
    if (isOpen) {
      const dummyInfluencer = {
        userID: DUMMY_INFLUENCER_LOCAL,
        mainAccountName: "sofiavergara",
        mainPlatform: "instagram",
        signUpCity: "United States",
        followers: 35000000,
        engagement: 3.5,
        score: 5,
        categories: ["Fashion", "Model"],
        imageURL:
          "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDbVG1qgTd0rFOzYff99R7G6EW%2Bnvf8V7VNj26rqR6mgx7iVQq2QPXFR573uTKHwcmEIjcJynQCz9aoJSYnRUChWrOPQt%2BBH8xfdE2dL1avFgg%3D%3D",
      };
      setData({
        [DUMMY_INFLUENCER_LOCAL]: dummyInfluencer,
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isOpen, currentStep]);

  useImperativeHandle(refAction, () => ({
    onGetData: () => {
      resetStatesAndDoNewQuery();
    },
  }));

  useEffect(() => {
    resetStatesAndDoNewQuery();
    // eslint-disable-next-line
  }, [
    topRatedCategorySelected,
    newCategorySelected,
    favoritesCategorySelected,
    genderSelected,
    rangeEngagement,
    rangeFollowers,
    searchText,
    topicsSelected,
    signUpCountry,
    citySelected,
    audienceLocations,
  ]);

  useEffect(() => {
    if (emptyPlatformPhyllo?.[TIKTOK] && emptyPlatformPhyllo?.[INSTAGRAM]) {
      onChangeLoadLastItem(true);
    }
    // eslint-disable-next-line
  }, [emptyPlatformPhyllo?.[TIKTOK], emptyPlatformPhyllo?.[INSTAGRAM]]);

  useEffect(() => {
    const invitesKeys = Object.keys(invites || {});
    if (invitesKeys.length === 0) return;

    const dataFormatted = {};

    Object.keys(data).forEach((key) => {
      const influencer = data[key];
      const isInvited = getInformationIsInvited(key);

      dataFormatted[key] = {
        ...influencer,
        ...isInvited,
      };
    });

    setData(dataFormatted);

    //eslint-disable-next-line
  }, [invites]);

  if (isLoading) {
    return (
      <LinearLoaderWithTime label={t(LOADING_INFORMATION)} time={50 * 1000} />
    );
  }

  if (keysSorted.length === 0) {
    if (influencersDiscarded.length > 0) {
      return (
        <Typography variant="body1" align="center">
          {t(NO_MORE_RESULTS_TO_SHOW)}
        </Typography>
      );
    }
    if (
      errorPhyllo === REQUEST_TIME_OUT_PHYLLO ||
      errorPhyllo === INTERNAL_SERVER_ERROR_PHYLLO
    ) {
      <Typography variant="body1" align="center">
        {t(WE_ARE_DOING_A_DEEPER_SEARCH)}
      </Typography>;
    }
    return (
      <Typography variant="body1" align="center">
        {t(isFiltering ? EMPTY_RESULTS_SHOW : EMPTY_RESULTS_SHOW)}
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      {!isMobile &&
        keysSorted.map((key, index) => {
          const influencer = data[key] || {};
          if (dispatch(getInfluencerIsHiddenFromTables({ userID: key })))
            return null;

          return (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={key}>
              <InfluencerCard
                data={influencer}
                onCallbackClickCard={() => {
                  setSteps(STEPS_MODAL_INVITATION);
                }}
              />
            </Grid>
          );
        })}
      {isMobile && currentCreator && (
        <Grid item xs={12}>
          <InfluencerCard
            data={currentCreator}
            onDiscardInfluencer={onDiscardInfluencer}
            onCallbackClickCard={() => {
              setSteps(STEPS_MODAL_INVITATION);
            }}
          />
        </Grid>
      )}
      {!loadLastItem && !isMobile && (
        <Grid item xs={12}>
          <div ref={fromRef}>
            <Loader size={30} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default InfluencersListTable;
