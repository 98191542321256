import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ALERT_ICON_TYPE_SUCCESS,
  ROLES_ADMIN_SHOPS,
  ROLES_SHOPS,
} from "../utils/constants";
import { useTour } from "@reactour/tour";
import { STEPS_MAIN_FUNCTIONALITY } from "./steps";
import {
  changeShowNextButton,
  changeShowPrevButton,
  setIsNewBrand,
  setOpenOnboarding,
  setSaveDataOnboarding,
  setStepOnboarding,
  setStepsOnboarding,
} from "../actions/onboarding";
import { useDispatchApp } from "../lib/redux";
import { resetDataDummy } from "../actions/auth";
import useLocalStorage from "../hooks/useLocalStorage";
import { SimpleAlert } from "../utils/alerts";
import {
  startSetIsLoggedFirstTime,
  startGetBusinessName,
} from "../actions/shops";
import { useTranslationApp } from "../lib/i18next";
import {
  COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION,
  READY,
} from "../locales/keysTranslations";
import useMediaQuery from "@mui/material/useMediaQuery";

const OnboardingContainer = ({ children }) => {
  const { currentStep, setCurrentStep, steps, isOpen, setIsOpen, setSteps } =
    useTour();
  const [, setIsLoggedFirstTime, initialValueUsed] = useLocalStorage(
    "isLoggedFirstTime",
    false
  );

  const navigate = useNavigate();

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatchApp();
  const user = useSelector((state) => state.auth.user);
  const { paymentModel, isLoading } = useSelector((state) => state.payments);

  const {
    showPrevButton,
    showNextButton,
    isNewBrand,
    isOpen: isOpenPrev,
  } = useSelector((state) => state.onboarding);

  const { t } = useTranslationApp();

  const prevButton = document.getElementById("prev-button-step");
  const nextButton = document.getElementById("next-button-step");

  useEffect(() => {
    if (showPrevButton) {
      if (prevButton) {
        prevButton.style.visibility = "unset";
      }
    } else {
      if (prevButton) {
        prevButton.style.visibility = "hidden";
      }
    }
    if (showNextButton) {
      if (nextButton) {
        nextButton.style.visibility = "unset";
      }
    } else {
      if (nextButton) {
        nextButton.style.visibility = "hidden";
      }
    }
  }, [showPrevButton, showNextButton, prevButton, nextButton]);

  useEffect(() => {
    if (!isOpen) {
    }
    dispatch(setOpenOnboarding(isOpen));
    //eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const stepInformation = steps[currentStep];

    if (isOpen) {
      document.body.style.overflow = "hidden";

      dispatch(setStepOnboarding(currentStep));
      if (stepInformation?.switchRoute && isOpen) {
        navigate(stepInformation.switchRoute, true);
      }
      if (stepInformation?.disablePrev) {
        dispatch(changeShowPrevButton(false));
      } else {
        dispatch(changeShowPrevButton(true));
      }
      if (stepInformation?.disableNext) {
        dispatch(changeShowNextButton(false));
      } else {
        dispatch(changeShowNextButton(true));
      }

      if (stepInformation?.isFinished) {
        setIsOpen(false);
      }

      if (stepInformation?.action) {
        dispatch(stepInformation.action);
      }
    } else {
      document.body.style.overflow = "unset";
      if (stepInformation?.switchRouterOnClose && !isNewBrand) {
        navigate(stepInformation?.switchRouterOnClose);
      }
      if (stepInformation?.alert && isOpenPrev !== isOpen) {
        SimpleAlert({
          ...stepInformation?.alert,
          title: t(stepInformation?.alert?.title),
          text: t(stepInformation?.alert?.text),
        }).then((result) => {
          if (!result.isDismissed && !result.isConfirmed) return;
          if (!stepInformation?.actionAlert) return;
          if (typeof stepInformation.actionAlert === "function") {
            stepInformation.actionAlert();
          } else {
            dispatch(stepInformation.actionAlert);
          }
        });
      }

      // Reset onboarding
      setCurrentStep(0);
      dispatch(setStepOnboarding(0));
      dispatch(setSaveDataOnboarding(false));
      setSteps(STEPS_MAIN_FUNCTIONALITY);
      dispatch(resetDataDummy());

      if (isNewBrand && ROLES_ADMIN_SHOPS.includes(user.role)) {
        navigate("/account");
        SimpleAlert({
          title: t(READY),
          text: t(COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
      dispatch(setIsNewBrand(false));
    }

    // eslint-disable-next-line
  }, [currentStep, isOpen]);

  useEffect(() => {
    setCurrentStep(0);
    dispatch(setStepOnboarding(0));
    dispatch(setStepsOnboarding(steps));
    //eslint-disable-next-line
  }, [steps]);

  useEffect(() => {
    (async () => {
      if (isLoading) return;
      if (!user) return;
      if (!paymentModel) return;
      if (!ROLES_SHOPS.includes(user.role)) return;

      dispatch(startGetBusinessName());

      const isLoggedFirstTimeDB = await dispatch(startSetIsLoggedFirstTime());

      if (isLoggedFirstTimeDB) return;

      if (initialValueUsed || !isLoggedFirstTimeDB) {
        setIsLoggedFirstTime(true);
        if (!isTablet) {
          setIsOpen(true);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isLoading, paymentModel]);

  return <>{children}</>;
};

export default OnboardingContainer;
