import React from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useSelectorApp } from "../../../lib/redux";

const OverlayChat = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const chatIsOpen = useSelectorApp((state) => state.chat.isOpen);
  const { isActivePlan } = useSelectorApp((state) => state.auth);

  return (
    <div
      className="information-overlay-chat"
      style={{ position: "relative", height: "calc(100% - 64px)" }}
    >
      {children}
      {chatIsOpen && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: isActivePlan ? 1000 : 900,
            minHeight: `calc(100vh - ${isMobile ? 56 : 64}px)`,
          }}
        ></Box>
      )}
    </div>
  );
};

export default OverlayChat;
