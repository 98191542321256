import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { startAssignStaff, startGetStaffList } from "../../../actions/owner";
import { MANAGER_ROLE, OWNER_ROLE } from "../../../utils/constants";
import ModalStaff from "../Modal/ModalStaff";

const filter = createFilterOptions();

const StaffListDropdown = ({
  value = null,
  shopID,
  doFetch = false,
  doAssign = true,
  fullWidth = false,
  variant = "filled",
  name = "staffID",
  errors = {},
  onChange = () => {},
  sx = {},
}) => {
  const [search, setSearch] = useState(null);
  const [modalStaffOpen, setModalStaffOpen] = useState(false);

  const dispatch = useDispatchApp();

  const user = useSelectorApp((state) => state.auth.user);

  const staffList = useSelectorApp((state) => state.shop.staff);

  const country = useSelectorApp((state) => state?.auth?.user?.country);

  const getInitialData = async () => {
    if (!doFetch) return;
    dispatch(startGetStaffList());
  };

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (!value) return;
    const found = staffList.find((c) => c.staffID === value);
    if (found) setSearch(found);
    //eslint-disable-next-line
  }, [value]);

  const onCallbackAddStaff = async (staff) => {
    if (!staff) return false;

    const staffID = staff?.staffID;
    const name = staff?.name;

    onChange(staffID);
    setSearch({
      name,
    });

    if (!doAssign) return staffID;

    await dispatch(startAssignStaff({ staffID: staffID, shopID }));

    return staffID;
  };

  const onAssignStaff = async ({ staffID, shopID }) => {
    if (!staffID) return false;
    if (!doAssign) return true;
    const response = await dispatch(startAssignStaff({ staffID, shopID }));
    if (!response) return false;
    return true;
  };

  return (
    <>
      <Autocomplete
        value={search}
        onChange={async (event, newValue) => {
          if (typeof newValue === "string") {
            //Writing a new value
            setSearch({
              name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            if (user?.role !== OWNER_ROLE && user?.role !== MANAGER_ROLE)
              return;

            setModalStaffOpen(true);
          } else {
            const response = await onAssignStaff({
              shopID,
              staffID: newValue.staffID,
            });
            onChange(newValue.staffID);
            if (response) {
              setSearch(newValue);
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue.toLowerCase() === option.name.toLowerCase()
          );
          if (inputValue !== "" && !isExisting) {
            if (user?.role === OWNER_ROLE || user?.role === MANAGER_ROLE) {
              filtered.push({
                inputValue,
                name: `Agregar "${inputValue}"`,
              });
            }
          }

          return filtered;
        }}
        disableClearable={true}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={staffList}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{ width: "100%", maxWidth: fullWidth ? "100%" : 180, ...sx }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Staff"
            variant={variant}
            error={!!errors[name]}
            helperText={!!errors[name] ? errors[name]?.message : ""}
          />
        )}
      />
      {modalStaffOpen && (
        <ModalStaff
          modalOpen={modalStaffOpen}
          onCloseModal={() => setModalStaffOpen(false)}
          data={{
            name: search?.name,
          }}
          maxWidth="md"
          fullWidth={true}
          onCallbackCreate={onCallbackAddStaff}
        />
      )}
    </>
  );
};
export default StaffListDropdown;
