import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import CompanyInformationForm from "../../User/Account/CompanyInformationForm";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { BUTTON_CANCEL, BUTTON_SAVE } from "../../../locales/keysTranslations";

const ModalBasicInformationShop = ({
  modalOpen,
  onCloseModal,
  modeContainer = "normal",
  hasDividers = true,
  elevation = 0,
  showBottomButton = false,
  onlyRequiredFields = true,
  onlyEmptyFields = true,
  onCallbackSave = () => {},
  sx = {},
  sxTitle = {},
}) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      hasTitle={true}
    >
      <CompanyInformationForm
        modeContainer={modeContainer}
        hasDividers={hasDividers}
        elevation={elevation}
        showBottomButton={showBottomButton}
        sx={{ ...sx }}
        sxTitle={{ ...sxTitle }}
        onlyRequiredFields={onlyRequiredFields}
        onlyEmptyFields={onlyEmptyFields}
        onCallbackSave={onCallbackSave}
        grid={{
          xs: 12,
        }}
        sxPreviewsLogos={{
          maxHeight: 100,
        }}
        customComponentButtons={
          <ContainerModalActions
            hasPadding={false}
            sx={{
              mt: 2,
            }}
            propsSecondaryButton={{
              showButton: true,
              text: BUTTON_CANCEL,
              type: "button",
              onClick: onCloseModal,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_SAVE,
              type: "submit",
              onClick: () => {},
            }}
          />
        }
      />
    </ModalBasicLayout>
  );
};

export default ModalBasicInformationShop;
