import React from "react";
import ModalBasicLayout from "../../../../Common/Modal/ModalBasicLayout";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import {
  BUTTON_CONTINUE,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER,
  FIELD_REQUIRED,
  NO,
  READY,
  YES,
} from "../../../../../locales/keysTranslations";
import TextField from "../../../../Common/Fields/TextField";
import { useTranslationApp } from "../../../../../lib/i18next";
import RadioGroup from "../../../../Common/Forms/RadioGrupo/RadioGroup";
import CurrencyField from "../../../../Common/NumberFormats/CurrencyField";
import LoadingButton from "../../../../Common/Buttons/LoadingButton";
import { startInviteInfluencer } from "../../../../../actions/influencers";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import { SimpleAlert } from "../../../../../utils/alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../../../utils/constants";
import useFocusErrorForm from "../../../../../hooks/useFocusErrorForm";

const ModalSendInviteApplicant = ({
  modalOpen,
  onCloseModal,
  applicantID,
  onCallbackInvite,
}) => {
  const { control, watch, handleSubmit, formState } = useForm({
    defaultValues: {
      value: "",
      doSendInvite: false,
    },
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const doSendInvite = watch("doSendInvite");
  const applicantData = useSelectorApp(
    (state) => state?.campaigns?.applicants[applicantID]
  );
  const campaignData = useSelectorApp(
    (state) => state?.campaigns?.campaigns[applicantData?.campaignID]
  );

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  console.log(errors);

  const onSubmit = async (data) => {
    const { value, doSendInvite } = data;

    if (!doSendInvite) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      onCloseModal();
      return;
    }

    const influencerID = applicantData.userID;
    const campaignID = applicantData.campaignID;

    const response = await dispatch(
      startInviteInfluencer(
        {
          value: parseInt(value),
          comment: t(CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION),
          typeContent: campaignData?.typeContentMedia,
          code: "",
        },
        influencerID,
        {
          expiryDays: 30,
          campaignID,
        },
        true
      )
    );

    if (response.ok) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      await onCallbackInvite(response.inviteID);
      onCloseModal();
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth
      maxWidth="xs"
      scroll="paper"
      title={t(CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER)}
      variantTitle="h6"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 1,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <RadioGroup
          control={control}
          name="doSendInvite"
          isBooleanValues={true}
          options={[
            { value: false, label: t(NO) },
            { value: true, label: t(YES) },
          ]}
          rules={{}}
        />
        {doSendInvite && (
          <TextField
            name="value"
            label={t(
              CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER
            )}
            variant="filled"
            fullWidth
            InputProps={{
              inputComponent: CurrencyField,
            }}
            errors={errors}
            control={control}
            rules={{
              required: {
                value: doSendInvite,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        )}
        <LoadingButton color="secondary">{t(BUTTON_CONTINUE)}</LoadingButton>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalSendInviteApplicant;
