import React, { useEffect, useState } from "react";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import InfluencerDetailHorizontal from "./InfluencerDetailHorizontal";
import { getAnalyticsByUser } from "../../../utils/socialNetworks";
import {
  startGetInfluencer,
  startGetReviewsInfluencer,
} from "../../../actions/influencers";
import _ from "lodash";
import Loader from "../Loader/Loader";
import { useDispatchApp } from "../../../lib/redux";
import InfluencerReviews from "./InfluencerReviews";
import ButtonInfluencerActions from "./ButtonInfluencerActions";
import { BOX_SHADOW_ACTIONS_MODAL } from "../../../utils/colors";
import { MODAL_INFLUENCER_ACTIONS_INFORMATION } from "../../../onboarding/stepsSelectors";

const ModalInfluencerActions = ({
  userID,
  influencer,
  maxWidth = "sm",
  scroll = "paper",
  fullWidth = true,
  modalOpen,
  onCloseModal = () => {},
  sx,
  grid,
  colorButton,
  tooltipPosition,
  showInviteButton,
  showInviteCampaignButton,
  showSendMessageButton,
  showSendPaymentButton,
  additionalDataCreateInvite,
}) => {
  const [influencerData, setInfluencerData] = useState({});
  const [isLoading, setIsLoading] = useState(_.isEmpty(influencer));
  const [reviews, setReviews] = useState(null);

  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    await getInfluencerData();
    await getReviews();
    setIsLoading(false);
  };

  const getInfluencerData = async () => {
    if (!userID && !influencer) return;
    if (influencer) {
      setInfluencerData(influencer);
      return;
    }
    const influencerData = await dispatch(startGetInfluencer(userID, false));
    setInfluencerData(influencerData);
    return;
  };
  const getReviews = async () => {
    const responseReviews = await dispatch(startGetReviewsInfluencer(userID));
    if (responseReviews.ok) {
      setReviews(responseReviews.reviews);
    }
    return;
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      scroll={scroll}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
          }}
          data-tour={MODAL_INFLUENCER_ACTIONS_INFORMATION}
        >
          <InfluencerDetailHorizontal
            analytics={getAnalyticsByUser(influencerData)}
            data={influencerData}
            userID={userID}
            colorButton="primary"
            sx={{ width: "100%" }}
            sxNameLogo={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            redirectSocial={true}
          />
          <InfluencerReviews data={reviews} maxHeight={200} />
        </Box>
      )}
      {!isLoading && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            py: 2,
            px: 2,
            boxShadow: BOX_SHADOW_ACTIONS_MODAL,
            gap: 2,
          }}
        >
          <ButtonInfluencerActions
            mode="buttons"
            userID={userID}
            influencer={influencer}
            sx={sx}
            grid={grid}
            colorButton={colorButton}
            tooltipPosition={tooltipPosition}
            showInviteButton={showInviteButton}
            showInviteCampaignButton={showInviteCampaignButton}
            showSendMessageButton={showSendMessageButton}
            showSendPaymentButton={showSendPaymentButton}
            additionalDataCreateInvite={additionalDataCreateInvite}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalInfluencerActions;
