import { Loader } from "@googlemaps/js-api-loader";

export const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly",
});

export const getLatLngByAddress = (address) => {
  return new Promise((resolve, reject) => {
    loader.load().then((google) => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: address,
        },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            let latitude = results[0].geometry.location.lat();
            let longitude = results[0].geometry.location.lng();
            resolve({ ok: true, lat: latitude, lng: longitude });
          } else {
            resolve({ ok: false });
          }
        }
      );
    });
  });
};
