import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { REGEX_PASSWORD } from "../../../utils/regexsValidation";
import Loader from "../../Common/Loader/Loader";
import { useEffect } from "react";
import ContainerPaperSection from "./ContainerPaperSection";
import { useForm } from "react-hook-form";
import TextField from "../../Common/Fields/TextField";
import { useDispatch } from "react-redux";
import { startChangePassword } from "../../../actions/auth";
import { useTranslationApp } from "../../../lib/i18next";
import {
  FIELD_REQUIRED,
  PASSWORDS_NOT_MATCH,
  PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER,
  PASSWORD_CURRENT_PASSWORD_PLACEHOLDER,
  PASSWORD_FORMAT_DESCRIPTION,
  PASSWORD_FORMAT_SUGGESTION1,
  PASSWORD_FORMAT_SUGGESTION2,
  PASSWORD_FORMAT_SUGGESTION3,
  PASSWORD_FORMAT_SUGGESTION4,
  PASSWORD_INSECURE,
  PASSWORD_NEW_PASSWORD_PLACEHOLDER,
  PASSWORD_TITLE,
} from "../../../locales/keysTranslations";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";

const INITIAL_STATE = {
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};

const PasswordForm = ({
  onCallback = () => {},
  elevation = 1,
  showTopButton = false,
  showBottomButton = true,
  sx = {},
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslationApp();

  const { handleSubmit, watch, control, reset, formState } = useForm({
    defaultValues: INITIAL_STATE,
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [1000]);
  }, []);

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setIsFetching(true);
    const response = await dispatch(startChangePassword(data));
    setIsFetching(false);
    if (response) {
      reset();
      onCallback();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <ContainerPaperSection
      onSubmit={handleSubmit(onSubmit)}
      title={PASSWORD_TITLE}
      showTopButton={false}
      showBottomButton={showBottomButton}
      isFetching={isFetching}
      elevation={elevation}
      sx={{ ...sx, mb: 12 }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: {
            xs: "column",
            lg: "row",
          },
        }}
      >
        <Grid container spacing={2} sx={{ width: { xs: "100%", lg: "70%" } }}>
          <Grid item xs={12} md={6}>
            <TextField
              label={t(PASSWORD_CURRENT_PASSWORD_PLACEHOLDER)}
              name="oldPassword"
              type={showPassword ? "text" : "password"}
              variant="filled"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t(PASSWORD_NEW_PASSWORD_PLACEHOLDER)}
              type={showPassword ? "text" : "password"}
              name="newPassword"
              variant="filled"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: {
                  value: (value) => {
                    if (!REGEX_PASSWORD.test(value)) {
                      return t(PASSWORD_INSECURE);
                    }
                    if (value !== watch("repeatPassword")) {
                      return t(PASSWORDS_NOT_MATCH);
                    }
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t(PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER)}
              type={showPassword ? "text" : "password"}
              name="repeatPassword"
              variant="filled"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: {
                  value: (value) => {
                    if (!REGEX_PASSWORD.test(value)) {
                      return t(PASSWORD_INSECURE);
                    }
                    if (value !== watch("newPassword")) {
                      return t(PASSWORDS_NOT_MATCH);
                    }
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            width: { xs: "100%", lg: "30%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {t(PASSWORD_FORMAT_DESCRIPTION)}
            </Typography>
            <Typography variant="body2">
              {`* ${t(PASSWORD_FORMAT_SUGGESTION1)}`}
            </Typography>
            <Typography variant="body2">
              {`* ${t(PASSWORD_FORMAT_SUGGESTION2)}`}
            </Typography>
            <Typography variant="body2">
              {`* ${t(PASSWORD_FORMAT_SUGGESTION3)}`}
            </Typography>
            <Typography variant="body2">
              {`* ${t(PASSWORD_FORMAT_SUGGESTION4)}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ContainerPaperSection>
  );
};

export default PasswordForm;
