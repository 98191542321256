import React from "react";
import Paper from "@mui/material/Paper";

const StatisticsContainer = ({ children, sx }) => {
  return (
    <Paper sx={{ ...sx }} elevation={0}>
      {children}
    </Paper>
  );
};

export default StatisticsContainer;
