import i18next from "i18next";
import { SimpleAlert } from "./alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  IMAGE_FORMATS,
  MAX_SIZE_FILES,
  MAX_SIZE_IMAGE,
  ROLES_DASHBOARD,
  VIDEO_FORMATS,
} from "./constants";
import { getHeightAndWidthFromDataUrl } from "./images";
import imageCompression from "browser-image-compression";
import { ERROR } from "../locales/keysTranslations";

export const getUUIDFromURL = (url) => {
  url = decodeURIComponent(url);

  let fragments = url.split("/");
  let uuid = fragments[fragments.length - 1];
  if (uuid.indexOf("?") !== -1) {
    uuid = uuid.split("?")[0];
  }

  return uuid;
};

export const detectPlatform = (url) => {
  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return "youtube";
  }
  if (url.includes("instagram.com") || url.includes("instagram.")) {
    return "instagram";
  }
  if (url.includes("tiktok.com") || url.includes("tiktok.co")) {
    return "tiktok";
  }
  return "asset";
};

export const getExtensionFile = (url = "") => {
  const lengthUrlSplit = url.split(".");
  const extension = lengthUrlSplit[lengthUrlSplit.length - 1].split("?")[0];
  return extension.toLowerCase();
};

export async function downloadContent(urlFrom) {
  try {
    const response = await fetch(urlFrom, {
      cache: "no-store",
    });

    if (response.ok) {
      const blob = await response.blob();
      let fileName = getUUIDFromURL(urlFrom);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");

      const extension = getExtensionFile(urlFrom);
      if (extension === "quicktime") {
        fileName = fileName.replace("quicktime", "mov");
      }

      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    }
    return response.ok;
  } catch (error) {
    console.error(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: error.message,
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
}

export const openLink = (url) => window.open(url, "_blank");

export const validateFile = (file) => {
  const ext = file.type.split("/")[1];
  if (
    ext !== "pdf" &&
    !IMAGE_FORMATS.includes(ext) &&
    !VIDEO_FORMATS.includes(ext)
  ) {
    SimpleAlert({
      title: "Error",
      text: "El formato del archivo no es valido",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
  if (IMAGE_FORMATS.includes(ext) && file.size > MAX_SIZE_IMAGE) {
    SimpleAlert({
      title: "Error",
      text: "La imagen es muy grande, por favor selecciona una con un tamaño menor a 10MB",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
  if (
    (VIDEO_FORMATS.includes(ext) || ext === "pdf") &&
    file.size > MAX_SIZE_FILES
  ) {
    SimpleAlert({
      title: "Error",
      text: "El archivo es muy grande, por favor selecciona uno con un tamaño menor a 30MB",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
  return true;
};

export const isImage = (file) => {
  const ext = file.type.split("/")[1];
  if (IMAGE_FORMATS.includes(ext)) return true;
  return false;
};

export const compressImage = async (file) => {
  try {
    if (file.size < 2000000) return file;
    const dimensions = await getHeightAndWidthFromDataUrl(file);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight:
        dimensions.width >= 800
          ? Math.round(dimensions.width * 0.25)
          : dimensions.width,
      useWebWorker: true,
      initialQuality: 0.8,
    };
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
    SimpleAlert({
      title: "Error",
      text: "Error comprimiendo el archivo",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const getInfluencerSocialLink = (influencer = {}) => {
  const { mainPlatform, mainAccountName } = influencer;

  if (mainPlatform === "instagram") {
    return `https://www.instagram.com/${mainAccountName}`;
  }
  if (mainPlatform === "tiktok") {
    return `https://www.tiktok.com/@${mainAccountName}`;
  }
  if (mainPlatform === "youtube") {
    return `https://www.youtube.com/c/${mainAccountName}`;
  }
};

export const getFileNameUrl = (url, withoutExtension = true) => {
  const regex = /\/([^/]+)\/?$/;
  const match = regex.exec(url);

  if (!match) return null;

  if (withoutExtension) {
    return match[1].split(".")[0];
  }

  return match[1];
};

export const getHlsUrl = ({ url = "", hls = [] }) => {
  const fileName = getFileNameUrl(url);
  const found = hls.find((item) => fileName === getFileNameUrl(item));

  return found;
};

export const getLinkRedirect = ({ userID, role, influencer, isExternal }) => {
  if (ROLES_DASHBOARD.includes(role)) {
    return getInfluencerSocialLink(influencer);
  }
  if (influencer?.hasAnalytics && !isExternal) {
    return `/influencer?userID=${userID}`;
  }
  if (isExternal) {
    return `influencer?from=discovery&accountName=${influencer.mainAccountName}&platform=${influencer.mainPlatform}`;
  }
  return getInfluencerSocialLink(influencer);
};
