import { ref as refDB, get, update, set } from "firebase/database";
import { db } from "../firebase";
import types from "../types";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_ERROR } from "../utils/constants";
import { saveMenuByStoreId } from "./shops";
import { getShopID } from "./getters";
import { getObjectError } from "../utils/formats";
import i18next from "i18next";
import {
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  MENU_HAS_ELEMENTS_CREATED,
} from "../locales/keysTranslations";

export const startChangeOrderCategories =
  (shopID, storeID, categories) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const dbRef = refDB(db, `menus/${shopID}/${storeID}`);
        await set(dbRef, categories);
      }
      dispatch(changeOrderCategories(categories));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeOrderCategories = (data) => ({
  type: types.CHANGE_ORDER_CATEGORIES,
  payload: data,
});

export const startCreateCategory =
  (shopID, storeID, category) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      const saveDataOnboarding = getState().onboarding.saveData;
      const menu = [...getState().shop.currentMenu] ?? [];
      menu.unshift({ name: category });
      if (!isOpenOnboarding || (saveDataOnboarding && isOpenOnboarding)) {
        const dbRef = refDB(db, `menus/${shopID}/${storeID}`);
        await set(dbRef, menu);
      }
      dispatch(createCategory(menu));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const createCategory = (data) => ({
  type: types.CREATE_CATEGORY,
  payload: data,
});

export const startEditCategory =
  (shopID, storeID, newCategoryName, oldCategoryID) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${oldCategoryID}/name`
        );
        await set(dbRef, newCategoryName);
      }
      const newData = {
        name: newCategoryName,
        key: oldCategoryID,
      };
      dispatch(editCategory(newData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const editCategory = (data) => ({
  type: types.EDIT_CATEGORY,
  payload: data,
});

export const startDeleteCategory =
  (shopID, storeID, categoryID) => async (dispatch, getState) => {
    try {
      const menu = [...getState().shop.currentMenu];
      const isOpenOnboarding = getState().onboarding.isOpen;
      // Se desactivó el borrado de imgs de productos (Se deja por si requiere activar de nuevo)
      // const data = menu[categoryID];
      // let arrayKeysFilesAws = [];
      // data.products &&
      //   Object.keys(data.products).map(async (key) => {
      //     if (data.products[key].photoURL) {
      //       if (data.products[key].photoURL.includes("amazonaws")) {
      //         const UID = getUUIDFromURL(data.products[key].photoURL);
      //         arrayKeysFilesAws.push({ Key: `shops/${shopID}/images/${UID}` });
      //       } else {
      //         const UID = getUUIDFromURL(data.products[key].photoURL);
      //         const refImage = refStorage(
      //           storage,
      //           `shops/${shopID}/images/${UID}`
      //         );
      //         await deleteObject(refImage);
      //       }
      //     }
      //     return null;
      //   });
      // await dispatch(startDeleteFilesAws(arrayKeysFilesAws));

      menu.splice(categoryID, 1);
      if (!isOpenOnboarding) {
        const dbRef = refDB(db, `menus/${shopID}/${storeID}`);
        await set(dbRef, menu);
      }
      dispatch(deleteCategory(menu));
      dispatch(startUpdateMinProductValue());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const deleteCategory = (data) => ({
  type: types.DELETE_CATEGORY,
  payload: data,
});

export const startDuplicateCategory =
  (storeID, category) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      const categoryData = JSON.parse(JSON.stringify(category));
      categoryData.name = `${category.name} copy`;
      const shopID = dispatch(getShopID());
      const menu = [...getState().shop.currentMenu];
      menu.unshift(categoryData);
      if (!isOpenOnboarding) {
        const dbRef = refDB(db, `menus/${shopID}/${storeID}`);
        await set(dbRef, menu);
      }
      dispatch(duplicateCategory(menu));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const duplicateCategory = (data) => ({
  type: types.DUPLICATE_CATEGORY,
  payload: data,
});

export const startChangeOrderProducts =
  (shopID, storeID, categoryID, products) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products`
        );
        await set(dbRef, products);
      }
      dispatch(
        changeOrderProducts({
          categoryID,
          products,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeOrderProducts = (data) => ({
  type: types.CHANGE_ORDER_PRODUCTS,
  payload: data,
});

export const startCreateProduct =
  (shopID, storeID, categoryID, product) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      const saveDataOnboarding = getState().onboarding.saveData;
      const products = getState().shop.currentMenu[categoryID].products ?? [];
      products.unshift({
        ...product,
      });
      if (!isOpenOnboarding || (saveDataOnboarding && isOpenOnboarding)) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products`
        );
        await set(dbRef, products);
      }
      const productData = {
        categoryID,
        products,
      };
      dispatch(createProduct(productData));
      dispatch(startUpdateMinProductValue());
      return {
        ok: true,
        key: 0,
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const createProduct = (data) => ({
  type: types.CREATE_PRODUCT,
  payload: data,
});

export const startEditProduct =
  (shopID, storeID, categoryID, productID, data) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const dataFormatted = {};
      Object.keys(data).forEach((key) => {
        if (key === "undefined" || data[key] === undefined) return;
        dataFormatted[key] = data[key];
      });

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}`
        );
        await update(dbRef, dataFormatted);
      }

      const productData = {
        categoryID,
        productID,
        data,
      };
      dispatch(editProduct(productData));
      dispatch(startUpdateMinProductValue());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const editProduct = (data) => ({
  type: types.EDIT_PRODUCT,
  payload: data,
});

export const startDeleteProduct =
  (shopID, storeID, categoryID, productID, photoURL) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const products = getState().shop.currentMenu[categoryID].products ?? [];
      products.splice(productID, 1);

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products`
        );
        await set(dbRef, products);
      }

      dispatch(
        deleteProduct({
          categoryID,
          products,
        })
      );

      // Se desactivó el borrado de las imgs de los productos (Se deja por si se vuelve a activar)
      // if (photoURL) {
      //   if (photoURL.includes("amazonaws")) {
      //     const UID = getUUIDFromURL(photoURL);
      //     await dispatch(startDeleteFileAws(UID, `shops/${shopID}/images/`));
      //   } else {
      //     const UID = getUUIDFromURL(photoURL);
      //     const storageRef = refStorage(
      //       storage,
      //       `shops/${shopID}/images/${UID}`
      //     );
      //     await deleteObject(storageRef);
      //   }
      // }
      dispatch(startUpdateMinProductValue());

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const deleteProduct = (data) => ({
  type: types.DELETE_PRODUCT,
  payload: data,
});

export const startDuplicateProduct =
  (storeID, categoryID, product) => async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const productData = JSON.parse(JSON.stringify(product));
      productData.name = `${product.name} copy`;
      const shopID = dispatch(getShopID());
      const products = getState().shop.currentMenu[categoryID].products ?? [];
      products.unshift(productData);

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products`
        );
        await set(dbRef, products);
      }

      dispatch(
        duplicateProduct({
          categoryID,
          products,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const duplicateProduct = (data) => ({
  type: types.DUPLICATE_PRODUCT,
  payload: data,
});

export const startChangeStatusProduct =
  (shopID, storeID, categoryID, productID, isActive) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/isActive`
        );
        await set(dbRef, isActive);
      }
      dispatch(
        changeStatusProduct({
          categoryID,
          productID,
          isActive,
        })
      );
      dispatch(startUpdateMinProductValue());

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeStatusProduct = (data) => ({
  type: types.CHANGE_STATUS_PRODUCT,
  payload: data,
});

export const startMoveProduct =
  (shopID, storeID, categoryID, productID, targetCategory) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const product =
        getState().shop.currentMenu[categoryID].products[productID];
      const productsCategoryFrom =
        getState().shop.currentMenu[categoryID].products ?? [];
      const productsTargetCategory =
        getState().shop.currentMenu[targetCategory].products ?? [];
      productsCategoryFrom.splice(productID, 1);
      productsTargetCategory.unshift({
        ...product,
      });

      if (!isOpenOnboarding) {
        const updates = {};
        updates[`menus/${shopID}/${storeID}/${categoryID}/products/`] =
          productsCategoryFrom;
        updates[`menus/${shopID}/${storeID}/${targetCategory}/products/`] =
          productsTargetCategory;
        await update(refDB(db), updates);
      }

      const newData = {
        productID,
        categoryID,
        targetCategory,
        productsFrom: productsCategoryFrom,
        productsTarget: productsTargetCategory,
      };
      dispatch(moveProduct(newData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const moveProduct = (data) => ({
  type: types.MOVE_PRODUCT,
  payload: data,
});

export const startCreateAccompanimentType =
  (shopID, storeID, categoryID, productID, accompanimentType) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const options =
        getState().shop.currentMenu[categoryID].products[productID].options ??
        [];
      options.unshift({
        isMandatory: false,
        ...accompanimentType,
      });

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options`
        );
        await set(dbRef, options);
      }

      const accompanimentTypeData = {
        categoryID,
        productID,
        options,
      };
      dispatch(createAccompanimentType(accompanimentTypeData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const createAccompanimentType = (data) => ({
  type: types.CREATE_ACCOMPANIMENT_TYPE,
  payload: data,
});

export const startDeleteAccompanimentType =
  (shopID, storeID, categoryID, productID, accompanimentTypeID) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const options =
        getState().shop.currentMenu[categoryID].products[productID].options ??
        [];
      options.splice(accompanimentTypeID, 1);

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options`
        );
        await set(dbRef, options);
      }

      const accompanimentTypeData = {
        categoryID,
        productID,
        options,
      };
      dispatch(deleteAccompanimentType(accompanimentTypeData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const deleteAccompanimentType = (data) => ({
  type: types.DELETE_ACCOMPANIMENT_TYPE,
  payload: data,
});

export const startEditAccompanimentType =
  (shopID, storeID, categoryID, productID, accompanimentTypeID, data) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;
      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentTypeID}`
        );
        await update(dbRef, {
          ...data,
        });
      }
      const accompanimentTypeData = {
        categoryID,
        productID,
        accompanimentTypeID,
        data,
      };
      dispatch(editAccompanimentType(accompanimentTypeData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const editAccompanimentType = (data) => ({
  type: types.EDIT_ACCOMPANIMENT_TYPE,
  payload: data,
});

export const startCopyAccompanimentType =
  (
    shopID,
    storeID,
    category,
    product,
    accompanimentType,
    targetCategory,
    targetProduct
  ) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const accompaniment =
        getState().shop.currentMenu[category].products[product].options[
          accompanimentType
        ];
      const accompanimentsTypeTarget =
        getState().shop.currentMenu[targetCategory].products[targetProduct]
          .options ?? [];
      accompanimentsTypeTarget.unshift({
        ...accompaniment,
      });

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${targetCategory}/products/${targetProduct}/options`
        );
        await set(dbRef, accompanimentsTypeTarget);
      }

      const accompanimentTypeData = {
        targetCategory,
        targetProduct,
        options: accompanimentsTypeTarget,
      };
      dispatch(copyAccompanimentType(accompanimentTypeData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const copyAccompanimentType = (data) => ({
  type: types.COPY_ACCOMPANIMENT_TYPE,
  payload: data,
});

export const startChangeOrderAccompanimentsType =
  (storeID, categoryID, productID, accompanimentsType) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const shopID = dispatch(getShopID());

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options`
        );
        await set(dbRef, accompanimentsType);
      }

      const accompanimentsTypeData = {
        categoryID,
        productID,
        options: accompanimentsType,
      };
      dispatch(changeOrderAccompanimentsType(accompanimentsTypeData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeOrderAccompanimentsType = (data) => ({
  type: types.CHANGE_ORDER_ACCOMPANIMENTS_TYPE,
  payload: data,
});

export const startCreateAccompaniment =
  (
    shopID,
    storeID,
    categoryID,
    productID,
    accompanimentTypeID,
    accompaniment
  ) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const options =
        getState().shop.currentMenu[categoryID].products[productID].options[
          accompanimentTypeID
        ].options ?? [];
      options.unshift({
        ...accompaniment,
      });

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentTypeID}/options/`
        );
        await set(dbRef, options);
      }

      const accompanimentData = {
        categoryID,
        productID,
        accompanimentTypeID,
        options,
      };
      dispatch(createAccompaniment(accompanimentData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const createAccompaniment = (data) => ({
  type: types.CREATE_ACCOMPANIMENT,
  payload: data,
});

export const startEditAccompaniment =
  (
    shopID,
    storeID,
    categoryID,
    productID,
    accompanimentTypeID,
    accompanimentID,
    data
  ) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentTypeID}/options/${accompanimentID}`
        );
        await set(dbRef, data);
      }

      const accompanimentData = {
        categoryID,
        productID,
        accompanimentTypeID,
        accompanimentID,
        data,
      };
      dispatch(editAccompaniment(accompanimentData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const editAccompaniment = (data) => ({
  type: types.EDIT_ACCOMPANIMENT,
  payload: data,
});

export const startDeleteAccompaniment =
  (
    shopID,
    storeID,
    categoryID,
    productID,
    accompanimentTypeID,
    accompanimentID
  ) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      const options =
        getState().shop.currentMenu[categoryID].products[productID].options[
          accompanimentTypeID
        ].options ?? [];
      options.splice(accompanimentID, 1);

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentTypeID}/options`
        );
        await set(dbRef, options);
      }

      const accompanimentData = {
        categoryID,
        productID,
        accompanimentTypeID,
        options,
      };
      dispatch(deleteAccompaniment(accompanimentData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const deleteAccompaniment = (data) => ({
  type: types.DELETE_ACCOMPANIMENT,
  payload: data,
});

export const startChangeOrderAccompaniments =
  (storeID, categoryID, productID, accompanimentsTypeID, accompaniments) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());

      const isOpenOnboarding = getState().onboarding.isOpen;

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentsTypeID}/options`
        );
        await set(dbRef, accompaniments);
      }

      const accompanimentsData = {
        categoryID,
        productID,
        accompanimentsTypeID,
        options: accompaniments,
      };
      dispatch(changeOrderAccompaniments(accompanimentsData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeOrderAccompaniments = (data) => ({
  type: types.CHANGE_ORDER_ACCOMPANIMENTS,
  payload: data,
});

export const startChangeIsMandatory =
  (shopID, storeID, categoryID, productID, accompanimentTypeID, isMandatory) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentTypeID}`
        );
        await update(dbRef, { isMandatory });
      }

      dispatch(
        changeIsMandatory({
          categoryID,
          productID,
          accompanimentTypeID,
          isMandatory,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeIsMandatory = (data) => ({
  type: types.CHANGE_IS_MANDATORY,
  payload: data,
});

export const startChangeStatusAccompaniment =
  (
    shopID,
    storeID,
    categoryID,
    productID,
    accompanimentTypeID,
    accompaniment,
    status
  ) =>
  async (dispatch, getState) => {
    try {
      const isOpenOnboarding = getState().onboarding.isOpen;

      if (!isOpenOnboarding) {
        const dbRef = refDB(
          db,
          `menus/${shopID}/${storeID}/${categoryID}/products/${productID}/options/${accompanimentTypeID}/options/${accompaniment}/isActive`
        );
        await set(dbRef, status);
      }

      dispatch(
        changeStatusAccompaniment({
          categoryID,
          productID,
          accompanimentTypeID,
          accompaniment,
          status,
        })
      );
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeStatusAccompaniment = (data) => ({
  type: types.CHANGE_STATUS_ACCOMPANIMENT,
  payload: data,
});

export const startCopyMenu = (data) => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const isOpenOnboarding = getState().onboarding.isOpen;

    if (isOpenOnboarding) return true;

    const dbRef = refDB(db, `menus/${shopID}/${data.menuFrom}/`);
    const snapshopMenu = await get(dbRef);
    if (snapshopMenu.exists()) {
      const menu = snapshopMenu.val();

      // Se desactivó el borrado de imgs de productos (Se deja por si se activa de nuevo)
      // const menuWithoutPhotos = menu.map((category, indexCategory) => {
      //   if (category.products) {
      //     category.products.map((product, indexProduct) => {
      //       delete menu[indexCategory].products[indexProduct].photoURL;
      //       return product;
      //     });
      //   }
      //   return category;
      // });

      await set(refDB(db, `menus/${shopID}/${data.menuTo}`), menu);
      dispatch(saveMenuByStoreId(menu));
      return true;
    } else {
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(MENU_HAS_ELEMENTS_CREATED),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startUploadMenu =
  (storeID, data) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `menus/${shopID}/${storeID}`);
      await set(dbRef, data);
      dispatch(uploadMenu(data));
      dispatch(startUpdateMinProductValue());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const uploadMenu = (data) => ({
  type: types.UPLOAD_MENU,
  payload: data,
});

export const startGetMinProductValue = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/minProductValue`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(setMinProductValue(snapshot.val()));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startUpdateMinProductValue = () => async (dispatch, getState) => {
  try {
    const menu = getState().shop.currentMenu;
    const shopID = dispatch(getShopID());

    if (!menu) return false;

    let minProductValue;

    menu.forEach((category) => {
      if (category?.products?.length > 0) {
        category.products.forEach((product) => {
          if (!minProductValue) minProductValue = parseInt(product.price);
          if (parseInt(product?.price) < minProductValue) {
            minProductValue = parseInt(product.price);
          }
        });
      }
    });

    const dbRef = refDB(db, `shops/${shopID}/minProductValue`);

    await set(dbRef, minProductValue);
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const setMinProductValue = (data) => ({
  type: types.SET_MIN_PRODUCT_VALUE,
  payload: data,
});
