import types from "../types";

const initialState = {
  plan: "",
  stripe: {
    stripeCustomerID: "",
    stripeSubscriptionID: "",
    defaultPaymentMethod: {
      brand: "",
      last4: "",
      id: "",
    },
  },
  paymentModel: "",
  subscriptionDate: "",
  subscriptionPlanInfo: {},
  payments: {},
  paymentsFailed: {},
  plans: {},
  isLoading: true,
};

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PLAN_FINISH:
      return {
        ...state,
        isLoading: false,
        plan: action.payload,
      };
    case types.GET_SUBSCRIPTION_DATE_FINISH:
      return {
        ...state,
        isLoading: false,
        subscriptionDate: action.payload,
      };
    case types.GET_SUBSCRIPTION_PLAN_INFO_FINISH:
      return {
        ...state,
        isLoading: false,
        subscriptionPlanInfo: action.payload,
      };
    case types.GET_PAYMENT_MODEL_FINISH:
      return {
        ...state,
        isLoading: false,
        paymentModel: action.payload,
      };
    case types.GET_PLANS_FINISH:
      return {
        ...state,
        isLoading: false,
        plans: action.payload,
      };
    case types.GET_STRIPE_INFO_FINISH:
      return {
        ...state,
        stripe: { ...state.stripe, ...action.payload },
        isLoading: false,
      };
    case types.SET_DEFAULT_PAYMENT_METHOD:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          defaultPaymentMethod: action.payload,
        },
        isLoading: false,
      };
    case types.SET_CUSTOMER_ID:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          stripeCustomerID: action.payload,
        },
        isLoading: false,
      };
    case types.SET_SUBSCRIPTION_ID:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          stripeSubscriptionID: action.payload,
        },
        isLoading: false,
      };
    case types.SET_SUBSCRIPTION_END:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          subscriptionEnd: action.payload,
        },
        isLoading: false,
      };
    case types.SET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case types.PAY_INVOICE:
      return {
        ...state,
        payments: {
          ...state.payments,
          [action.payload.invoiceID]: {
            ...state.payments[action.payload.invoiceID],
            paymentID: action.payload.paymentID,
            invoiceID: action.payload.invoiceIDStripe,
            paidDate: action.payload.paidDate,
            isPaid: true,
          },
        },
      };
    case types.GET_PAYMENTS_FINISH:
      return {
        ...state,
        payments: action.payload,
        isLoading: false,
      };
    case types.PAY_CLAPP_CREDITS:
      return {
        ...state,
        payments: {
          ...action.payload.payment,
          ...state.payments,
        },
        isLoading: false,
      };
    case types.PAY_PACKAGE_PLAN:
      return {
        ...state,
        payments: {
          ...action.payload.payment,
          ...state.payments,
        },
        plan: action.payload.plan,
        isLoading: false,
      };
    case types.PAY_AMBASSADOR:
      return {
        ...state,
        payments: {
          ...action.payload.payment,
          ...state.payments,
        },
        isLoading: false,
      };
    case types.PAY_INFLUENCER:
      return {
        ...state,
        payments: {
          ...action.payload.payment,
          ...state.payments,
        },
        isLoading: false,
      };
    case types.GET_PAYMENTS_FAILED:
      return {
        ...state,
        paymentsFailed: action.payload,
        isLoading: false,
      };
    case types.GET_NEXT_PAYMENTS_FAILED:
      return {
        ...state,
        paymentsFailed: {
          ...state.paymentsFailed,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.LOADING_PAYMENTS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return initialState;
    default:
      return state;
  }
};
