import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { BUTTON_CANCEL, BUTTON_SAVE } from "../../../locales/keysTranslations";
import ScheduleForm from "../../User/Account/ScheduleForm";

const ModalScheduleShop = ({
  modalOpen,
  onCloseModal,
  modeContainer = "normal",
  hasDividers = true,
  elevation = 0,
  showBottomButton = false,
  onCallbackSave = () => {},
  sx = {},
  sxTable = {},
}) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      hasTitle={true}
    >
      <ScheduleForm
        modeContainer={modeContainer}
        hasDividers={hasDividers}
        elevation={elevation}
        showBottomButton={showBottomButton}
        sx={{ ...sx }}
        sxTable={{
          ...sxTable,
          maxHeight: { xs: "calc(100vh - 292px)" },
        }}
        onCallbackSave={onCallbackSave}
        customComponentButtons={
          <ContainerModalActions
            hasPadding={false}
            sx={{
              mt: 2,
            }}
            propsSecondaryButton={{
              showButton: true,
              text: BUTTON_CANCEL,
              type: "button",
              onClick: onCloseModal,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_SAVE,
              type: "submit",
              onClick: () => {},
            }}
          />
        }
      />
    </ModalBasicLayout>
  );
};

export default ModalScheduleShop;
