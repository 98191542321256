import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
// import DiscoveryInfluencersListItem from "./DiscoveryInfluencersListItem";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import {
  numberCompactFormat,
  numberDecimal,
} from "../../../utils/numberFormats";
import Loader from "../../Common/Loader/Loader";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import {
  PAGE_EXPLORER_ACTION_BUTTON,
  PAGE_EXPLORER_FIRST_ROW,
  PAGE_EXPLORER_TABLE,
} from "../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ACTIONS,
  BUTTON_INVITE,
  BUTTON_METRICS_READY,
  BUTTON_VIEW_REPORT,
  DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED,
  DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS,
  DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND,
  DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION,
} from "../../../locales/keysTranslations";
import LinkText from "../../Common/Texts/LinkText";

const DiscoveryInfluencersList = ({
  data,
  onVerifyStatistics,
  onRedirectInfluencer,
  onInviteInfluencer,
  fromRef,
  loadLastItem,
}) => {
  const keys = Object.keys(data);
  const rowsPerPage = keys.length;

  const { t } = useTranslationApp();

  const { isOpen, steps, currentStep } = useTour();
  const step = steps[currentStep];
  const disabledButtonsOnboarding =
    isOpen && step.actionData === "disabledButton";

  const getButtonData = (item, index) => {
    if (typeof item.isFound !== "boolean") {
      return { color: "secondary", buttonText: t(BUTTON_VIEW_REPORT) };
    }
    if (item.isFound && !item.isVisited) {
      return { color: "secondary", buttonText: t(BUTTON_METRICS_READY) };
    }
    if ((item.isFound && item.isVisited) || !item.isFound) {
      return { color: "tertiary", buttonText: t(BUTTON_INVITE) };
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
      }}
      data-tour={PAGE_EXPLORER_TABLE}
    >
      <TableContainer
        page={0}
        onPageChange={() => {}}
        rowsPerPage={rowsPerPage}
        keys={data}
        loading={false}
        ariaLabel={t(DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL)}
        hasPagination={false}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(ACTIONS)}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? data.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
            : data
          ).map((item, index) => {
            const buttonData = getButtonData(item, index);
            return (
              <TableRow
                key={index}
                data-tour={index === 0 && PAGE_EXPLORER_FIRST_ROW}
                sx={{
                  mb: 1,
                }}
              >
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Avatar src={item.imageUrl} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <LinkText variant="body1" target="_blank" href={item.url}>
                        {item.mainAccountName}
                      </LinkText>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  <Typography variant="body1" align="center">
                    {item?.creatorLocation?.country ??
                      item?.location?.country ??
                      "-"}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{ minWidth: 190 }}
                >
                  <Typography variant="body1" align="center">
                    {numberCompactFormat(item.followers)}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body1" align="center">
                    {`${numberDecimal(
                      item.engagementRate > 0.99
                        ? item.engagementRate
                        : item.engagementRate * 100
                    )}%`}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <Typography variant="body1" align="center">
                    {`${numberCompactFormat(item.averageLikes)}`}
                  </Typography>
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {item.isInvited ? (
                    <Typography
                      variant="body1"
                      data-tour={PAGE_EXPLORER_ACTION_BUTTON}
                      gutterBottom
                    >
                      {t(DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED)}
                    </Typography>
                  ) : null}
                  {typeof item.isFound === "boolean" && !item.isFound && (
                    <Typography variant="body1" gutterBottom>
                      {t(DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND)}
                    </Typography>
                  )}
                  {(!(item.isInvited && item.isFound && item.isVisited) ||
                    (!item.isInvited && !item.isFound)) && (
                    <div
                      className={`${
                        item.isFound && !item.isVisited
                          ? "animate__animated animate__fadeIn animate__infinite"
                          : ""
                      }`}
                    >
                      <LoadingButton
                        dataTour={PAGE_EXPLORER_ACTION_BUTTON}
                        loading={item.isVerifying}
                        disabled={disabledButtonsOnboarding}
                        color={buttonData.color}
                        onClick={() => {
                          if (typeof item.isFound !== "boolean") {
                            return onVerifyStatistics(index);
                          }
                          if (item.isFound && !item.isVisited) {
                            return onRedirectInfluencer(index);
                          }
                          if (
                            (item.isFound && item.isVisited) ||
                            !item.isFound
                          ) {
                            return onInviteInfluencer(index);
                          }
                        }}
                      >
                        {buttonData.buttonText}
                      </LoadingButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          {rowsPerPage > 1 && !isOpen ? (
            <>
              <TableRow>
                <TableCell colSpan={6}>
                  <div ref={fromRef}></div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {loadLastItem ? (
                      <Typography variant="h6">
                        {t(DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS)}
                      </Typography>
                    ) : (
                      <Loader size={30} />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </>
          ) : null}
        </TableBody>
      </TableContainer>
    </Box>
    // <Box>
    //   {data.map((item, index) => (
    //     <DiscoveryInfluencersListItem key={index} data={item} />
    //   ))}
    // </Box>
  );
};

export default DiscoveryInfluencersList;
