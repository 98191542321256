import {
  CAMPAIGN_ACTIVATE_ATTACHMENT_LINK,
  CAMPAIGN_ACTIVATE_FIELDS_REQUIRED,
  CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM,
  CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR,
  CAMPAIGN_FIELDS_REQUIRED,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE,
  CREATE_CAMPAIGN_ATTACHMENT_LINK,
  CREATE_CAMPAIGN_BUDGET_MIN_VALUE,
  CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION,
} from "../locales/keysTranslations";
import {
  MAX_FOLLOWERS,
  MIN_FOLLOWERS,
  VALIDATION_FROM_CAMPAIGN_DETAIL,
} from "../utils/constants";
import i18next from "i18next";
import { REGEX_URL } from "../utils/regexsValidation";
import _ from "lodash";

export const filterCampaignDataSave = (data) => {
  const finalData = {
    name: data.name,
    description: data.description,
    descriptionAi: data.descriptionAi,
    script: data.script,
    goal: data.goal,
    amountInfluencers: data.amountInfluencers,
    individualBudget: data.individualBudget,
    trafficLink: data.trafficLink,
    isUgcCampaign: data.isUgcCampaign,
    typeContentMedia: data.typeContentMedia,
    productAttributes: data.productAttributes,
    messageContent: data.messageContent,
    categoriesInfluencers: data.categoriesInfluencers,
    ageRange: data.ageRange,
    brief: data.brief,
    attachmentFile: data.attachmentFile,
    attachmentLink: data.attachmentLink,
    quantityMale: data.quantityMale,
    quantityFemale: data.quantityFemale,
    messageTone: data.messageTone,
    environmentContent: data.environmentContent,
    colorsContent: data.colorsContent,
    ugcContentFormat: data.ugcContentFormat,
    prohibitedColorsContent: data.prohibitedColorsContent,
    keyWords: data.keyWords,
    prohibitedWords: data.prohibitedWords,
    outfit: data.outfit,
    typeInfluencers: data.typeInfluencers,
  };

  if (!data.isUgcCampaign) {
    finalData.platforms = data.platforms || {};
    finalData.typeContent = data.typeContent || {};
    finalData.copyPost = data.copyPost || "";
    finalData.hasCollaboration = data.hasCollaboration || false;
    finalData.repost = data.repost || {};
    finalData.audienceLocation = data.audienceLocation || [];
    finalData.referenceProfiles = data.referenceProfiles || "";
    finalData.hashtag = data.hashtag || "";
  }

  return finalData;
};

export const validationCampaignData = ({
  data = {},
  locale,
  from = VALIDATION_FROM_CAMPAIGN_DETAIL,
}) => {
  try {
    const minValuePaymentCampaign = getMinValue({
      LOCALE: locale,
      isUgcCampaign: data.isUgcCampaign,
      typeInfluencers: data.typeInfluencers,
    });

    if (
      !data?.name ||
      !data?.description ||
      !data?.goal ||
      !data.trafficLink ||
      !data?.amountInfluencers ||
      !data?.individualBudget ||
      data?.isUgcCampaign === null ||
      data?.isUgcCampaign === undefined ||
      !Object.values(data?.typeContentMedia).includes(true) ||
      !data?.productAttributes ||
      !data?.messageContent ||
      data?.categoriesInfluencers?.length === 0 ||
      !data?.ageRange
    ) {
      return {
        ok: false,
        message: i18next.t(
          from === VALIDATION_FROM_CAMPAIGN_DETAIL
            ? CAMPAIGN_FIELDS_REQUIRED
            : CAMPAIGN_ACTIVATE_FIELDS_REQUIRED
        ),
      };
    }

    if (!data?.isUgcCampaign) {
      if (
        !Object.values(data?.platforms || {}).includes(true) ||
        !Object.values(data?.typeContent || {}).includes(true) ||
        data?.audienceLocation?.length === 0
      ) {
        return {
          ok: false,
          message: i18next.t(
            from === VALIDATION_FROM_CAMPAIGN_DETAIL
              ? CAMPAIGN_FIELDS_REQUIRED
              : CAMPAIGN_ACTIVATE_FIELDS_REQUIRED
          ),
        };
      }

      if (data?.hasCollaboration && data?.repost?.instagram?.length === 0) {
        return {
          ok: false,
          message: i18next.t(
            from === VALIDATION_FROM_CAMPAIGN_DETAIL
              ? CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION
              : CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM
          ),
        };
      }
    }

    if (data?.amountInfluencers < 1)
      return {
        ok: false,
        message: i18next.t(CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE, {
          value: 1,
        }),
      };

    if (data.individualBudget < minValuePaymentCampaign) {
      return {
        ok: false,
        message: i18next.t(
          from === VALIDATION_FROM_CAMPAIGN_DETAIL
            ? CREATE_CAMPAIGN_BUDGET_MIN_VALUE
            : CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR,
          {
            value: minValuePaymentCampaign,
            currency: "USD",
          }
        ),
      };
    }

    if (data?.attachmentLink && !REGEX_URL.test(data?.attachmentLink)) {
      return {
        ok: false,
        message: i18next.t(
          from === VALIDATION_FROM_CAMPAIGN_DETAIL
            ? CREATE_CAMPAIGN_ATTACHMENT_LINK
            : CAMPAIGN_ACTIVATE_ATTACHMENT_LINK
        ),
      };
    }

    return { ok: true };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: error.message,
    };
  }
};

export const getMinValue = ({ LOCALE, isUgcCampaign, typeInfluencers }) => {
  if (isUgcCampaign) {
    return LOCALE.minValuePaymentUgcCampaign;
  }
  const minValuesPaymentInfluencerCampaign =
    LOCALE.minValuesPaymentInfluencerCampaign;

  // Definimos los rangos de entrada y salida
  const minValueTypeInfluencers = MIN_FOLLOWERS;
  const maxValueTypeInfluencers = MAX_FOLLOWERS;
  const minValuePaymentInfluencerCampaign =
    minValuesPaymentInfluencerCampaign?.[0] || 60;
  const maxValuePaymentInfluencerCampaign =
    minValuesPaymentInfluencerCampaign?.[1] || 60;

  // verify if the typeInfluencers is in the range
  typeInfluencers = Math.max(
    minValueTypeInfluencers,
    Math.min(maxValueTypeInfluencers, typeInfluencers)
  );

  const percentage =
    (typeInfluencers - minValueTypeInfluencers) /
    (maxValueTypeInfluencers - minValueTypeInfluencers);
  const valueToPay =
    minValuePaymentInfluencerCampaign +
    percentage *
      (maxValuePaymentInfluencerCampaign - minValuePaymentInfluencerCampaign);

  return Math.round(valueToPay);
};
export const getMarks = ({ marks, LOCALE, isUgcCampaign, typeInfluencers }) => {
  const marksCloned = _.cloneDeep(marks);

  marksCloned.shift();

  if (isUgcCampaign) {
    marksCloned.unshift({
      value: LOCALE.minValuePaymentUgcCampaign,
      label: LOCALE.minValuePaymentUgcCampaign,
    });
  } else {
    const minValue = getMinValue({ LOCALE, isUgcCampaign, typeInfluencers });

    marksCloned.unshift({
      value: minValue,
      label: minValue,
    });
  }

  return marksCloned;
};
