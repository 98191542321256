import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { setOpenNotification } from "../../../actions/ui";
import Button from "../Buttons/Button";
import { Link } from "react-router-dom";
import { useTranslationApp } from "../../../lib/i18next";
import { GO } from "../../../locales/keysTranslations";

const Notification = () => {
  const {
    open,
    message,
    vertical,
    horizontal,
    action: actionNotification,
  } = useSelectorApp((state) => state.ui.notification);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onCloseNotification = () => {
    dispatch(
      setOpenNotification({
        open: false,
        message: "",
        action: "",
      })
    );
  };

  const action = (
    <React.Fragment>
      {actionNotification && (
        <Link
          to={actionNotification}
          style={{ textDecoration: "none" }}
          onClick={onCloseNotification}
        >
          <Button color="secondary" size="small">
            {t(GO)}
          </Button>
        </Link>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onCloseNotification}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onCloseNotification}
      message={message && t(message)}
      key={vertical + horizontal}
      action={action}
    />
  );
};

export default Notification;
