import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ShopsAccepted from "../../components/Owner/RegisterShops/ShopsAccepted/ShopsAccepted";
import { useDispatch } from "react-redux";
import { startGetStaffList } from "../../actions/owner";
import { useSelectorApp } from "../../lib/redux";
import StoreIcon from "@mui/icons-material/Store";
import TitlePage from "../../components/Common/Texts/TitlePage";
import {
  UGC,
  ADS,
  SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED,
  SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED,
  SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE,
  SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR,
  SHOPS_APPLICANTS_TITLE,
} from "../../locales/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import ContainerPage from "../../components/Common/Containers/ContainerPage";
import Chip from "../../components/Common/Chips/Chip";
import SegmentedController from "../../components/Common/Containers/SegmentedController";
import CampaignIcon from "@mui/icons-material/Campaign";
import Advertisers from "../../components/Owner/RegisterShops/Advertisers/Advertisers";

const RegisterShops = () => {
  const country = useSelectorApp((state) => state?.auth?.user?.country);
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslationApp();

  const onChangeFilter = (filterSelect) => {
    if (tab !== 1) setTab(1);
    if (filterSelect !== null) {
      if (filterSelect === filter) {
        setFilter(null);
      } else {
        setFilter(filterSelect);
      }
    } else {
      setFilter(filterSelect);
    }
  };

  useEffect(() => {
    dispatch(startGetStaffList());
    //eslint-disable-next-line
  }, [country]);

  return (
    <ContainerPage>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: {
            xs: 1,
            md: 0,
          },
          justifyContent: "space-between",
          width: "100%",
          mb: 2,
        }}
      >
        <TitlePage>{t(SHOPS_APPLICANTS_TITLE)}</TitlePage>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Chip
            icon={<PersonIcon />}
            label={SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED}
            onClick={() => {
              onChangeFilter("isNotLogged");
            }}
            isSelected={filter === "isNotLogged"}
          />
          <Chip
            icon={<AccountCircleIcon />}
            label={SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED}
            onClick={() => {
              onChangeFilter("isLogged");
            }}
            isSelected={filter === "isLogged"}
          />
          <Chip
            icon={<StoreIcon />}
            label={SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR}
            onClick={() => {
              onChangeFilter("planRegular");
            }}
            isSelected={filter === "planRegular"}
          />
          <Chip
            icon={<BusinessIcon />}
            label={SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE}
            onClick={() => {
              onChangeFilter("planEnterprise");
            }}
            isSelected={filter === "planEnterprise"}
          />
        </Box>
      </Box>

      <SegmentedController
        initialValue={tab}
        value={tab}
        onChangeTab={(newTab) => {
          if (newTab === 0) {
            setFilter(null);
          }
          setTab(newTab);
        }}
        tabs={[
          {
            icon: <CampaignIcon />,
            label: ADS,
          },
          {
            icon: <StoreIcon />,
            label: UGC,
          },
        ]}
      />
      {tab === 0 && <Advertisers filter={filter} />}
      {tab === 1 && <ShopsAccepted filter={filter} />}
    </ContainerPage>
  );
};

export default RegisterShops;
