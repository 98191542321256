import {
  ref,
  get,
  query,
  push,
  update,
  remove,
  startAfter,
  limitToFirst,
  orderByKey,
  orderByChild,
  startAt,
  endAt,
  set,
} from "firebase/database";
import { db } from "../firebase";
import axios from "../lib/axios";
import types from "../types";
import { SimpleAlert } from "../utils/alerts";
import { ALERT_ICON_TYPE_ERROR, ROLES_ADMIN_SHOPS } from "../utils/constants";
import { renewToken } from "./auth";
import { createBusiness } from "./businesses";
import { startGetBusinessNameAndStoresByShopIDs } from "./shops";
import { getRole, getShopID, getUserID } from "./getters";
import { encryptPassword, getObjectError } from "../utils/formats";
import { DUMMY_USER } from "../onboarding/stepsSelectors";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../locales/keysTranslations";

const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const startGetUsersByName = (textQuery) => async (dispatch) => {
  try {
    const dbRef = ref(db, "users");
    const q = query(
      dbRef,
      orderByChild("name"),
      startAt(textQuery),
      endAt(textQuery + "\uf8ff")
    );
    const snapshot = await get(q);
    if (snapshot.exists()) {
      const users = snapshot.val();
      dispatch(getNextUserAdminAccounts(users));
      return users;
    }
    return {};
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetUsersByEmail = (textQuery) => async (dispatch) => {
  try {
    const dbRef = ref(db, "users");
    const q = query(
      dbRef,
      orderByChild("email"),
      startAt(textQuery.toLowerCase()),
      endAt(textQuery.toLowerCase() + "\uf8ff")
    );
    const snapshot = await get(q);
    if (snapshot.exists()) {
      const users = snapshot.val();
      dispatch(getNextUserAdminAccounts(users));
      return users;
    }
    return {};
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetUserAdminAccounts =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().auth.user.country;

      const q = lastKey
        ? query(
            ref(db, `usersBySignUpCountry/${signUpCountry}`),
            orderByKey(),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `usersBySignUpCountry/${signUpCountry}`),
            orderByKey(),
            limitToFirst(limit)
          );

      const snapshot = await get(q);

      if (snapshot.exists()) {
        const users = snapshot.val();
        const userIDs = Object.keys(users);
        await dispatch(
          startGetUsersByUserIDs({
            userIDs,
            isNew: !lastKey,
            getShopData: true,
          })
        );
        onChangeLastKey(userIDs[userIDs.length - 1]);
        return true;
      } else {
        dispatch(loadingFinish());
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetUsersByUserIDs =
  ({ userIDs, setNew = false, getShopData = true }) =>
  async (dispatch, getState) => {
    try {
      const currentUsers = Object.keys(getState().users.users);
      userIDs = userIDs.filter((item, index) => {
        return userIDs.indexOf(item) === index;
      });

      const queriesUsers = [];
      userIDs.forEach((userID) => {
        if (currentUsers.includes(userID)) return;
        queriesUsers.push(get(ref(db, `users/${userID}`)));
      });

      const usersSnapshot = await Promise.all(queriesUsers);

      let users = {};
      usersSnapshot.forEach((userSnapshot) => {
        users = {
          ...users,
          [userSnapshot.key]: userSnapshot.val(),
        };
      });

      if (getShopData) {
        const keys = Object.keys(users);
        const shopIDs = [];

        keys.forEach((key) => {
          if (users[key].shopID) shopIDs.push(users[key].shopID);
        });

        await dispatch(startGetBusinessNameAndStoresByShopIDs({ shopIDs }));
      }
      setNew
        ? dispatch(getUserAdminAccounts(users))
        : dispatch(getNextUserAdminAccounts(users));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const getUserAdminAccounts = (users) => ({
  type: types.USERS_GET_ADMIN_ACCOUNTS_FINISH,
  payload: users,
});
export const getNextUserAdminAccounts = (users) => ({
  type: types.USERS_GET_NEXT_ADMIN_ACCOUNTS,
  payload: users,
});

export const startGetUsersByShopID =
  ({ onCallbackFinish = () => {} }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const snapshot = await get(ref(db, `shops/${shopID}/users`));
      if (snapshot.exists()) {
        const users = snapshot.val();
        dispatch(getUsersByShopID(users));
        onCallbackFinish();
        return true;
      } else {
        dispatch(loadingFinish());
        onCallbackFinish();
        return false;
      }
    } catch (error) {
      console.log(error);
      onCallbackFinish();
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const getUsersByShopID = (users) => ({
  type: types.USERS_GET_USERS_BY_SHOPID_FINISH,
  payload: users,
});

export const startCreateAccountsByAdmin =
  (userData) => async (dispatch, getState) => {
    try {
      // const { user } = getState().auth;
      // const snapshot = await get(ref(db, `shops/${user.shopID}/users`));
      // const snapshotPlan = await get(
      //   ref(db, `shops/${user.shopID}/subscriptionPlan`)
      // );
      // if (!snapshotPlan.exists()) {
      //   SimpleAlert({
      //     title: "Debes seleccionar primero un plan",
      //     icon: ALERT_ICON_TYPE_ERROR,
      //   });
      //   return false;
      // }
      // if (snapshot.exists()) {
      //   const users = snapshot.val();
      //   const plan = await dispatch(startGetPlanInfo(snapshotPlan.val()));
      //   let usersQuantity = 0;
      //   const maxUsersQuantity =
      //     plan.benefits[
      //       `${
      //         userData.role === ADMIN_ROLE
      //           ? "adminAccounts"
      //           : "operatorAccounts"
      //       }`
      //     ];
      //   Object.keys(users).map((key) => {
      //     if (users[key].role === userData.role) usersQuantity++;
      //     return null;
      //   });
      //   if (usersQuantity >= maxUsersQuantity) {
      //     SimpleAlert({
      //       title: "No puede crear más usuarios",
      //       text: `No puede crear más de ${maxUsersQuantity} usuarios ${
      //         userData.role === ADMIN_ROLE ? "administradores" : "operadores"
      //       } con su plan actual`,
      //       icon: ALERT_ICON_TYPE_ERROR,
      //     });
      //     return;
      //   }
      // }
      const isOpenOnboarding = getState().onboarding.isOpen;

      if (isOpenOnboarding) {
        userData = {
          email: userData.email,
          name: userData.name,
          role: userData.role,
          shopID: userData.shopID,
          storeID: userData.storeID,
          uid: DUMMY_USER,
        };
        dispatch(createAccountsByAdmin(userData));
        return true;
      }
      const token = await dispatch(renewToken());
      const passwordEncrypted = encryptPassword(userData.password);
      const { data } = await axios({
        method: "post",
        url: `${url}/createAccountsByAdmin`,
        data: {
          email: userData.email,
          password: passwordEncrypted,
          name: userData.name,
          role: userData.role,
          shopID: userData.shopID,
          storeID: userData.storeID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        userData = {
          email: userData.email,
          name: userData.name,
          role: userData.role,
          shopID: userData.shopID,
          storeID: userData.storeID,
          uid: data.uid,
        };
        dispatch(createAccountsByAdmin(userData));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const createAccountsByAdmin = (userData) => ({
  type: types.USERS_CREATE_ACCOUNTS_BY_ADMIN,
  payload: userData,
});

export const startEditUser = (data) => async (dispatch, getState) => {
  try {
    const isOpenOnboarding = getState().onboarding.isOpen;
    if (isOpenOnboarding) {
      data = {
        data: {
          email: data.email,
          name: data.name,
          role: data.role,
          storeID: data.storeID,
        },
        userID: data.uid,
      };
      dispatch(editUser(data));
      return true;
    }
    const token = await dispatch(renewToken());
    const response = await axios({
      method: "post",
      url: `${url}/editUser`,
      data: {
        userID: data.uid,
        name: data.name,
        email: data.email,
        role: data.role,
        storeID: data.storeID,
        shopID: data.shopID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.ok) {
      data = {
        data: {
          email: data.email,
          name: data.name,
          role: data.role,
          storeID: data.storeID,
        },
        userID: data.uid,
      };
      dispatch(editUser(data));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const editUser = (data) => ({
  type: types.EDIT_USER,
  payload: data,
});

export const startDeleteUser = (userID) => async (dispatch, getState) => {
  try {
    const isOpenOnboarding = getState().onboarding.isOpen;

    if (isOpenOnboarding) {
      dispatch(deleteUser(userID));
      return true;
    }

    const token = await dispatch(renewToken());
    const { user } = getState().auth;
    const response = await axios({
      method: "post",
      url: `${url}/deleteUser`,
      data: {
        shopID: user.shopID,
        userID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.ok) {
      dispatch(deleteUser(userID));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const deleteUser = (data) => ({
  type: types.DELETE_USER,
  payload: data,
});

export const startGetContactsByShopID = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const snapshot = await get(ref(db, `shops/${shopID}/contacts`));
    if (snapshot.exists()) {
      const contacts = snapshot.val();
      dispatch(getContactByShopID(contacts));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const getContactByShopID = (contacts) => ({
  type: types.USERS_GET_CONTACTS_BY_SHOPID_FINISH,
  payload: contacts,
});

export const startCreateContact = (data) => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `shops/${shopID}/contacts`);
    const contactKey = await push(dbRef, data);
    data = {
      [contactKey.key]: {
        ...data,
      },
    };
    dispatch(createContact(data));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const createContact = (data) => ({
  type: types.USERS_CREATE_CONTACT,
  payload: data,
});

export const startEditContact =
  (contactID, data) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = ref(db, `shops/${shopID}/contacts/${contactID}`);
      await update(dbRef, data);
      data = {
        key: contactID,
        data,
      };
      dispatch(editContact(data));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const editContact = (data) => ({
  type: types.USERS_EDIT_CONTACT,
  payload: data,
});

export const startDeleteContact = (contactID) => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `shops/${shopID}/contacts/${contactID}`);
    await remove(dbRef);
    dispatch(deleteContact(contactID));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const deleteContact = (contactID) => ({
  type: types.USERS_DELETE_CONTACT,
  payload: contactID,
});

export const startCreateUserAccountByOwner =
  ({ userData }) =>
  async (dispatch, getState) => {
    try {
      const country = getState()?.auth?.user?.country;
      const token = await dispatch(renewToken());
      const passwordEncrypted = encryptPassword(userData.password);
      const { data } = await axios({
        method: "post",
        data: {
          ...userData,
          password: passwordEncrypted,
          signUpCountry: country,
        },
        url: `${url}/createUserAccount`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          createUserAccountByOwner({
            data: { ...userData, signUpCountry: country },
            userID: data.uid,
          })
        );
        if (data.newBusinessID.length > 0) {
          dispatch(
            createBusiness({
              businessName: userData.businessName,
              businessID: data.newBusinessID,
              shops: userData.shopIDs,
              parentShopID: userData.parentShopID,
            })
          );
        }
        return data;
      } else {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: data.message || "error",
            code: data.code || "error",
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const createUserAccountByOwner = (data) => ({
  type: types.CREATE_USER_ACCOUNT_BY_OWNER,
  payload: data,
});

export const startEditUserAccountByOwner =
  ({ userData, userID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const passwordEncrypted = encryptPassword(userData.password);
      const { data } = await axios({
        method: "post",
        data: { ...userData, userID, password: passwordEncrypted },
        url: `${url}/editUserAccount`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(
          editUserAccountByOwner({
            ...userData,
            userID,
            businessID:
              data.newBusinessID.length > 0
                ? data.newBusinessID
                : userData.businessID,
          })
        );
        if (data.newBusinessID.length > 0) {
          dispatch(
            createBusiness({
              businessName: userData.businessName,
              businessID: data.newBusinessID,
              shops: userData.shopIDs,
              parentShopID: userData.parentShopID,
            })
          );
        }
        return data;
      } else {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: data.message || "error",
            code: data.code || "error",
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const editUserAccountByOwner = (data) => ({
  type: types.EDIT_USER_ACCOUNT_BY_OWNER,
  payload: data,
});

export const startDeleteUserAccountByOwner =
  ({ shopID, userID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { shopID, userID },
        url: `${url}/deleteUserAccount`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(deleteUserAccountByOwner(userID));
      }
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetUserFeedback = () => async (dispatch, getState) => {
  try {
    const role = dispatch(getRole());
    if (!ROLES_ADMIN_SHOPS.includes(role)) return true;

    const feedback = getState().auth.user.feedback;
    if (feedback !== undefined) {
      dispatch(setUserFeedback(feedback));
      return feedback;
    }
    const userID = dispatch(getUserID());
    const dbRef = ref(db, `feedbacks/${userID}/feedback`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const feedback = snapshot.val();
      dispatch(setUserFeedback(feedback));
      return feedback;
    } else {
      dispatch(setUserFeedback(""));
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startVerifyUserHasPassword = (userID) => async (dispatch) => {
  try {
    const dbRef = ref(db, `users/${userID}/passwordEncrypted`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startSaveUserFeedback = (feedback) => async (dispatch) => {
  try {
    const userID = dispatch(getUserID());
    const shopID = dispatch(getShopID());
    const dbRef = ref(db, `feedbacks/${userID}`);
    await set(dbRef, {
      feedback,
      userID,
      shopID,
      creationTime: new Date().getTime(),
    });
    dispatch(setUserFeedback(feedback));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const setUserFeedback = (data) => ({
  type: types.SET_USER_FEEDBACK,
  payload: data,
});

const deleteUserAccountByOwner = (data) => ({
  type: types.DELETE_USER_ACCOUNT_BY_OWNER,
  payload: data,
});

export const createAdminAccount = (userData) => ({
  type: types.USERS_CREATE_ADMIN_ACCOUNT,
  payload: userData,
});

const loadingFinish = () => ({
  type: types.USERS_LOADING_FINISH,
});
export const setLoadingUsers = (data) => ({
  type: types.SET_USERS_LOADING,
  payload: data,
});
