import React from "react";
import { useSelectorApp } from "../../../lib/redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import ActionsCreateAdminUsersTable from "./ActionsCreateAdminUsersTable";
import Typography from "@mui/material/Typography";
import TableContainer from "../../Common/Table/TableContainer";
import TableCell from "../../Common/Table/TableCell";
import {
  COLUMN_NAME_ACTION,
  COLUMN_NAME_ID,
  COLUMN_NAME_SHOP,
  COLUMN_NAME_SHOP_ID,
  OWNER_USERS_TABLE_ARIA_LABEL,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_NAME,
  COLUMN_NAME_ROLE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LinkText from "../../Common/Texts/LinkText";

const AdminUsersTable = ({
  data,
  onDeleteUser,
  onSelectUser,
  rowsPerPage,
  page,
  onPageChange,
  signUpCountry,
}) => {
  const { shops } = useSelectorApp((state) => state.shop);

  const { t } = useTranslationApp();

  const onFilterData = () => {
    const filtered = {};

    Object.keys(data).forEach((key) => {
      if (data[key].signUpCountry === signUpCountry) {
        filtered[key] = data[key];
      }
    });

    return filtered;
  };

  const filteredData = onFilterData();

  const keys = Object.keys(filteredData);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keys}
        loading={false}
        ariaLabel={t(OWNER_USERS_TABLE_ARIA_LABEL)}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_EMAIL)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SHOP)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SHOP_ID)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ROLE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : keys
          ).map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center" indexRow={index}>
                {row}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].name}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].email}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <LinkText
                  variant="body2"
                  href={`shop?shopID=${data[row].shopID}`}
                  target="_blank"
                >
                  {shops[data[row].shopID]?.businessName ?? "-"}
                </LinkText>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <Typography variant="body2">{data[row].shopID}</Typography>
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {data[row].role}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                <ActionsCreateAdminUsersTable
                  uid={row}
                  data={data[row]}
                  onDeleteUser={onDeleteUser}
                  onSelectUser={onSelectUser}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default AdminUsersTable;
