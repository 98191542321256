import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "../../../Common/Fields/TextField";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  AGE_RANGE_PLACEHOLDER,
  BUTTON_GENERATE_DESCRIPTION,
  DAILY_BUDGET_PLACEHOLDER,
  DESCRIPTION_PLACEHOLDER,
  FEMALES,
  FIELD_MAX_LENGTH,
  FIELD_MIN_LENGTH,
  FIELD_REQUIRED,
  GENDERS_PLACEHOLDER,
  INTERESTS_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  MALES,
  NAME_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
  CONTENT_TO_ADVERTISE,
  BUTTON_SELECT_CONTENT,
  BUTTON_REPLACE_CONTENT,
  BEHAVIORS_PLACEHOLDER,
  DEMOGRAPHICS_PLACEHOLDER,
  TRACKING_META_ID_PLACEHOLDER,
  META,
  FIELD_URL_VALID,
  DESTINATION_URL_PLACEHOLDER,
  TRACKING_TIKTOK_ID_PLACEHOLDER,
  CALL_TO_ACTION_PLACEHOLDER,
  ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE,
  ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE,
  TYPE_BUDGET_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  START_DATE_AND_END_DATE_VALIDATION,
  CAMPAIGN_DATES_START_DATE_PLACEHOLDER,
  CAMPAIGN_DATES_END_DATE_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import Select from "../../../Common/Selects/Select";
import SelectCallToActionAds from "../../../Common/Selects/SelectCallToActionAds";
import {
  marksAge,
  optionsAdsCampaignObjectives,
  optionsAdsCampaignTypeBudgets,
  textNumberCompactFormat,
} from "../../../../utils/slidersMarks";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import {
  ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_MODE_EDIT,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE,
  ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  IMAGE_FORMATS,
  TIKTOK,
  VIDEO_FORMATS,
} from "../../../../utils/constants";
import { Controller } from "react-hook-form";
import NumberField from "../../../Common/NumberFormats/NumberField";
import { GREY_SLIDER_COLOR } from "../../../../utils/colors";
import SearchSegmentationsField from "../../../Common/Fields/SearchSegmentationsField";
import IconButtonAddContent from "../../../Common/Modal/ModalGenerateSocialMediaContent/IconButtonAddContent";
import { getShopID } from "../../../../actions/getters";
import { useDispatchApp } from "../../../../lib/redux";
import SelectTrackingIDField from "../../../Common/Fields/SelectTrackingIDField";
import { REGEX_URL } from "../../../../utils/regexsValidation";
import { Divider, useMediaQuery } from "@mui/material";
import ModalMedia from "../../../Common/Modal/ModalMedia";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import DateField from "../../../Common/Fields/DateField";
import addDays from "date-fns/addDays";

const AdsCampaignForm = ({
  isDisabledInput,
  mode,
  onGenerateDescription,
  watch,
  control,
  getValues,
  errors,
  currency,
}) => {
  const [modalMediaOpen, setModalMediaOpen] = useState(false);
  const [isFetchingDescription, setIsFetchingDescription] = useState(false);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const shopID = dispatch(getShopID());
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const description = watch("description");
  const genders = watch("genders");
  const mediaUrl = watch("mediaUrl");
  const platform = watch("platform");
  const objective = watch("objective");
  const callToAction = watch("callToAction");
  const typeBudget = watch("typeBudget");

  const maxLengthDescription =
    platform === META
      ? ADS_CAMPAIGN_META_DESCRIPTION_MAX_LENGTH
      : ADS_CAMPAIGN_TIKTOK_DESCRIPTION_MAX_LENGTH;

  const isModeEdit = mode === ADS_CAMPAIGN_MODE_EDIT;

  const variantSubTitle = isMobile ? "h6" : "h6";
  const sxVariantSubTitle = {
    fontWeight: 900,
  };
  const sxDivider = {
    mb: 2,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
          {t(ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="name"
          label={t(NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isDisabledInput}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            minLength: {
              value: 3,
              message: t(FIELD_MIN_LENGTH, {
                value: 3,
              }),
            },
            maxLength: {
              value: 60,
              message: t(FIELD_MAX_LENGTH, {
                value: 60,
              }),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Select
          label={t(OBJECTIVE_PLACEHOLDER)}
          options={optionsAdsCampaignObjectives}
          disabled={isModeEdit}
          name="objective"
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="destinationURL"
          label={t(DESTINATION_URL_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isModeEdit}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value && !REGEX_URL.test(value)) {
                return t(FIELD_URL_VALID);
              }
            },
          }}
        />
      </Grid>
      {ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
        platform === META && (
          <Grid item xs={12} lg={6}>
            <SelectTrackingIDField
              name="trackingID"
              disabled={isModeEdit}
              label={t(
                platform === META
                  ? TRACKING_META_ID_PLACEHOLDER
                  : TRACKING_TIKTOK_ID_PLACEHOLDER
              )}
              fullWidth
              variant="outlined"
              platform={platform}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
      {callToAction && (
        <Grid item xs={12} lg={6}>
          <SelectCallToActionAds
            label={CALL_TO_ACTION_PLACEHOLDER}
            disabled={isDisabledInput}
            name="callToAction"
            fullWidth
            objective={objective}
            doTranslate={true}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <TextField
          label={t(DESCRIPTION_PLACEHOLDER)}
          name="description"
          variant="filled"
          value={description}
          multiline
          minRows={3}
          maxRows={4}
          hasButtonGenerate={!isDisabledInput}
          useGradientButton={false}
          propsButtonGenerate={{
            text: BUTTON_GENERATE_DESCRIPTION,
            loading: isFetchingDescription,
            onClick: async () => {
              setIsFetchingDescription(true);
              await onGenerateDescription();
              setIsFetchingDescription(false);
            },
          }}
          fullWidth
          disabled={isDisabledInput}
          showCounterCharacters={true}
          maxCharacters={maxLengthDescription}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value.length > maxLengthDescription) {
                return t(FIELD_MAX_LENGTH, {
                  value: maxLengthDescription,
                });
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={sxDivider} />
        <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
          {t(ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Select
          label={t(TYPE_BUDGET_PLACEHOLDER)}
          disabled={isModeEdit}
          name="typeBudget"
          fullWidth
          variant="filled"
          doTranslate={true}
          options={optionsAdsCampaignTypeBudgets}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          label={t(
            typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
              ? DAILY_BUDGET_PLACEHOLDER
              : LIFETIME_BUDGET_PLACEHOLDER
          )}
          name="budget"
          disabled={isDisabledInput}
          fullWidth
          variant="filled"
          InputProps={{
            inputComponent: NumberField,
          }}
          helperText={
            currency
              ? t(VALUE_EXPRESSED_IN, {
                  currency: currency.toUpperCase(),
                })
              : ""
          }
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      {typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION && (
        <>
          <Grid item xs={12} lg={6}>
            <DateField
              minDate={new Date()}
              name="startDate"
              label={t(CAMPAIGN_DATES_START_DATE_PLACEHOLDER)}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
                    if (!value) {
                      return t(FIELD_REQUIRED);
                    }
                    const endDateValue = getValues("endDate");
                    if (endDateValue) {
                      const startDate = new Date(value);
                      const endDate = new Date(endDateValue);
                      if (startDate > endDate) {
                        return t(START_DATE_AND_END_DATE_VALIDATION);
                      }
                    }
                  }
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DateField
              name="endDate"
              minDate={addDays(new Date(), 1)}
              label={t(CAMPAIGN_DATES_END_DATE_PLACEHOLDER)}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
                    if (!value) {
                      return t(FIELD_REQUIRED);
                    }
                    const startDateValue = getValues("startDate");
                    if (startDateValue) {
                      const endDate = new Date(value);
                      const startDate = new Date(startDateValue);
                      if (startDate > endDate) {
                        return t(START_DATE_AND_END_DATE_VALIDATION);
                      }
                    }
                  }
                },
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider sx={sxDivider} />
        <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
          {t(ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="body1">{t(AGE_RANGE_PLACEHOLDER)}</Typography>
        <Controller
          name="ageRange"
          control={control}
          render={({ field }) => (
            <Slider
              sx={{
                width: "calc(100% - 24px)",
                ml: 1,
                ".MuiSlider-track": {
                  height: 2,
                },
                ".MuiSlider-rail": {
                  backgroundColor: GREY_SLIDER_COLOR,
                  height: 4,
                },
                ".MuiSlider-mark": {
                  backgroundColor: "transparent",
                },
              }}
              disabled={isDisabledInput}
              getAriaLabel={() => "Type Influencers Campaign"}
              valueLabelDisplay="auto"
              getAriaValueText={textNumberCompactFormat}
              step={1}
              marks={marksAge}
              min={18}
              max={64}
              valueLabelFormat={textNumberCompactFormat}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControl
          component="fieldset"
          variant="filled"
          disabled={isDisabledInput}
          error={!!errors?.genders?.message}
        >
          <FormLabel component="legend" sx={{ fontWeight: 500 }}>
            {t(GENDERS_PLACEHOLDER)}
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="genders"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!Object.values(genders).includes(true)) {
                          return t(FIELD_REQUIRED);
                        }
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.male}
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            male: e.target.checked,
                          })
                        }
                        name="male"
                      />
                    )}
                  />
                }
                label={t(MALES)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="genders"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!Object.values(genders).includes(true)) {
                          return t(FIELD_REQUIRED);
                        }
                      },
                    }}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value.female}
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            female: e.target.checked,
                          })
                        }
                        name="female"
                      />
                    )}
                  />
                }
                label={t(FEMALES)}
              />
            </Grid>
          </Grid>
          {errors?.genders?.message && (
            <Typography variant="caption" color="error">
              {errors?.genders?.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SearchSegmentationsField
          label={t(LOCATIONS_PLACEHOLDER)}
          name="locations"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_GEOGRAPHIC_TYPE}
          mode="fetching"
          platform={platform}
          objective={objective}
          fullWidth
          variant="filled"
          disabled={isDisabledInput}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SearchSegmentationsField
          label={t(INTERESTS_PLACEHOLDER)}
          name="interests"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE}
          mode="fetching"
          platform={platform}
          objective={objective}
          fullWidth
          variant="filled"
          disabled={isDisabledInput}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SearchSegmentationsField
          label={t(BEHAVIORS_PLACEHOLDER)}
          name="behaviors"
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE}
          mode={platform === TIKTOK ? "fetching" : "load"}
          groupBy={["path", 0]}
          platform={platform}
          objective={objective}
          fullWidth
          variant="filled"
          disabled={isDisabledInput}
          control={control}
          errors={errors}
          rules={{}}
        />
      </Grid>
      {platform === META && (
        <Grid item xs={12} lg={6}>
          <SearchSegmentationsField
            label={t(DEMOGRAPHICS_PLACEHOLDER)}
            name="demographics"
            type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE}
            mode={platform === TIKTOK ? "fetching" : "load"}
            groupBy={["path", 0]}
            platform={platform}
            objective={objective}
            fullWidth
            variant="filled"
            disabled={isDisabledInput}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: false,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider sx={sxDivider} />
        <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
          {t(CONTENT_TO_ADVERTISE)}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          {mediaUrl && (
            <IconButton
              onClick={() => setModalMediaOpen(true)}
              sx={{
                p: 0,
              }}
            >
              <PermMediaIcon
                sx={{
                  fontSize: "2rem",
                }}
              />
            </IconButton>
          )}
          <Controller
            name="mediaUrl"
            control={control}
            render={({ field }) => (
              <IconButtonAddContent
                mode="button"
                showButtonUpload={!isDisabledInput}
                pathStorage={`adsCampaigns/${shopID}/`}
                onAddContent={(contents) => field.onChange(contents[0].url)}
                multiple={false}
                acceptedFormats={VIDEO_FORMATS.concat(IMAGE_FORMATS)}
                showContent={false}
                urlContent={mediaUrl}
                showContentMode="container"
                textButton={
                  mediaUrl ? BUTTON_REPLACE_CONTENT : BUTTON_SELECT_CONTENT
                }
                color="primary"
                sx={{
                  gap: 1,
                  alignItems: "flex-start",
                }}
              />
            )}
          />
        </Box>
      </Grid>
      {modalMediaOpen && (
        <ModalMedia
          url={mediaUrl}
          modalOpen={modalMediaOpen}
          onCloseModal={(e) => {
            e?.stopPropagation();
            setModalMediaOpen(false);
          }}
          disabledActions={true}
        />
      )}
    </Grid>
  );
};

export default AdsCampaignForm;
