import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ClientInfoModal from "../ClientInfoModal";
import TimePreparation from "./TimePreparation";
import Button from "../../../Common/Buttons/Button";
import { SimpleAlert } from "../../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  DAYS,
  DAYS_TO_MINUTES,
  HOURS,
  HOURS_TO_MINUTES,
  OWNER_ROLE,
  WEEKS,
  WEEKS_TO_MINUTES,
} from "../../../../utils/constants";
import { ORDER_MODAL_LIST_ACCEPTED_BUTTON } from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_ACCEPT_ORDER,
  BUTTON_REJECT_ORDER,
  DATE_LONG,
  INVALID_TIME_PREPARATION,
  TIME_PREPARATION_VALUES_VALIDATION,
} from "../../../../locales/keysTranslations";

const ToBeAcceptModalSection = ({
  data,
  from,
  influencer,
  onAcceptOrden,
  onRejectOrden,
}) => {
  const [time, setTime] = useState(0);
  const [typeTime, setTypeTime] = useState("");

  const { t } = useTranslationApp();

  const onSubmit = () => {
    if (!typeTime) {
      return SimpleAlert({
        title: t(INVALID_TIME_PREPARATION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    if (time <= 0 || time >= 60) {
      return SimpleAlert({
        title: t(TIME_PREPARATION_VALUES_VALIDATION, {
          min: 0,
          max: 60,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    let totalTime = time;
    if (typeTime === HOURS) {
      totalTime = time * HOURS_TO_MINUTES;
    }
    if (typeTime === DAYS) {
      totalTime = time * DAYS_TO_MINUTES;
    }
    if (typeTime === WEEKS) {
      totalTime = time * WEEKS_TO_MINUTES;
    }

    onAcceptOrden(totalTime);
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        px: 2,
        py: { xs: 1, md: 2 },
        overflow: "auto",
      }}
      elevation={0}
      square={true}
    >
      <ClientInfoModal
        data={data}
        totalPrice={data.totalPrice}
        influencer={influencer}
      />
      {from !== OWNER_ROLE && (
        <>
          <TimePreparation
            time={time}
            typeTime={typeTime}
            onPlusTime={() => {
              setTime(time + 1);
            }}
            onSubtractTime={() => {
              setTime(time - 1);
            }}
            onChangeTypeTime={setTypeTime}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "column" },
              justifyContent: "center",
              gap: 1,
              mt: 1,
            }}
          >
            <Button
              color="error"
              variant="text"
              hasShadow={false}
              onClick={onRejectOrden}
            >
              {t(BUTTON_REJECT_ORDER)}
            </Button>
            <Button
              variant="contained"
              sx={{ p: { xs: 1, md: 2 } }}
              onClick={onSubmit}
              dataTour={ORDER_MODAL_LIST_ACCEPTED_BUTTON}
            >
              {t(BUTTON_ACCEPT_ORDER)}
            </Button>
          </Box>
        </>
      )}
      <Typography variant="body2" align="center">
        {t(DATE_LONG, {
          date: new Date(data.date),
        })}
      </Typography>
    </Paper>
  );
};

export default ToBeAcceptModalSection;
