import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "../../../Common/Buttons/Button";
import NumberField from "../../../Common/NumberFormats/NumberField";
import {
  ADD_PRODUCT_CATEGORY_OPTION_CREATE,
  ADD_PRODUCT_CATEGORY_OPTION_MAX,
  ADD_PRODUCT_CATEGORY_OPTION_MIN,
  ADD_PRODUCT_CATEGORY_OPTION_NAME,
} from "../../../../onboarding/stepsSelectors";
import { useDispatchApp } from "../../../../lib/redux";
import { useTour } from "@reactour/tour";
import { changeShowNextButton } from "../../../../actions/onboarding";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_COPY,
  BUTTON_CREATE,
  BUTTON_SAVE,
  FIELD_ALL_REQUIRED,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER,
} from "../../../../locales/keysTranslations";

const FieldAccompanimentType = ({
  data,
  mode,
  onCreateAccompanimentType,
  onEditAccompanimentType,
  onCopyAccompanimentType,
  onCancel,
}) => {
  const [values, setValues] = useState({
    name: mode === "create" ? "" : data.name,
    minOptions: mode === "create" ? "" : data.minOptions,
    maxOptions: mode === "create" ? "" : data.maxOptions,
  });
  const [error, setError] = useState(false);

  const { isOpen } = useTour();
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onChange = (event) => {
    if (isOpen && event.target.value !== "") {
      dispatch(changeShowNextButton(true));
    } else if (isOpen && event.target.value === "") {
      dispatch(changeShowNextButton(false));
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    if (
      !values.name ||
      values.minOptions.toString().length === 0 ||
      values.maxOptions.toString().length === 0
    )
      return setError(t(FIELD_ALL_REQUIRED));
    if (values.minOptions > values.maxOptions)
      return setError(t(MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER));

    if (values.minOptions < 0 || values.maxOptions < 0) {
      return setError(t(MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER));
    }
    setError(null);
    return mode === "create"
      ? onCreateAccompanimentType({
          ...values,
          minOptions: parseInt(values.minOptions),
          maxOptions: parseInt(values.maxOptions),
        })
      : onEditAccompanimentType({
          ...values,
          minOptions: parseInt(values.minOptions),
          maxOptions: parseInt(values.maxOptions),
        });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} data-tour={ADD_PRODUCT_CATEGORY_OPTION_NAME}>
        <TextField
          variant="filled"
          hiddenLabel={true}
          name="name"
          autoFocus={true}
          onChange={onChange}
          onKeyDown={(e) => {
            e.key === "Escape" && onCancel();
          }}
          value={values.name}
          sx={{
            width: "100%",
            px: 2,
          }}
          placeholder={t(MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER)}
        />
      </Grid>
      <Grid item xs={12} sm={6} data-tour={ADD_PRODUCT_CATEGORY_OPTION_MIN}>
        <TextField
          variant="filled"
          hiddenLabel={true}
          name="minOptions"
          inputComponent={NumberField}
          onKeyDown={(e) => {
            e.key === "Escape" && onCancel();
          }}
          onChange={onChange}
          value={values.minOptions}
          sx={{
            width: "100%",
            px: 2,
          }}
          placeholder={t(
            MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} data-tour={ADD_PRODUCT_CATEGORY_OPTION_MAX}>
        <TextField
          variant="filled"
          hiddenLabel={true}
          name="maxOptions"
          inputComponent={NumberField}
          onKeyDown={(e) => {
            e.key === "Escape" && onCancel();
          }}
          onChange={onChange}
          value={values.maxOptions}
          sx={{
            width: "100%",
            px: 2,
          }}
          placeholder={t(
            MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER
          )}
        />
      </Grid>
      {error && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          mr: 2,
          mt: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Button
          color="secondary"
          type="button"
          onClick={onSubmit}
          dataTour={ADD_PRODUCT_CATEGORY_OPTION_CREATE}
        >
          {mode === "create" ? t(BUTTON_CREATE) : t(BUTTON_SAVE)}
        </Button>
        {mode === "create" && (
          <Button
            color="secondary"
            type="button"
            onClick={onCopyAccompanimentType}
          >
            {t(BUTTON_COPY)}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default FieldAccompanimentType;
