import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import {
  ALERT_ICON_TYPE_SUCCESS,
  CREATE_USERS_ADMIN_MODE_CREATE,
} from "../../../utils/constants";
import CreateAdminUsersForm from "./CreateAdminUsersForm";
import {
  startCreateUserAccountByOwner,
  startEditUserAccountByOwner,
} from "../../../actions/users";
import { SimpleAlert } from "../../../utils/alerts";
import { useForm } from "react-hook-form";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import {
  BUTTON_CANCEL,
  BUTTON_CREATE,
  BUTTON_SAVE,
  OWNER_USERS_CREATED_USER,
  OWNER_USERS_MODAL_ARIA_LABEL,
  OWNER_USERS_MODAL_DESCRIPTION,
  OWNER_USERS_MODAL_DESCRIPTION_EDIT,
  OWNER_USERS_MODAL_TITLE,
  OWNER_USERS_MODAL_TITLE_EDIT,
  OWNER_USERS_SAVED_USER,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const INITIAL_STATE = {
  userID: "",
  role: "",
  businessID: "",
  businessName: "",
  shopIDs: [],
  parentShopID: "",
  shopID: "",
  storeID: "",
  name: "",
  email: "",
  password: "",
  repeatPassword: "",
};

const ModalUser = ({ open, onCloseModal, data, mode }) => {
  const { handleSubmit, control, setValue, watch, reset, formState } = useForm({
    defaultValues: INITIAL_STATE,
  });

  const errors = formState.errors;
  useFocusErrorForm(formState);

  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatchApp();
  const { businesses } = useSelectorApp((state) => state.businesses);

  const { t } = useTranslationApp();

  useEffect(() => {
    setValue(
      "userID",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.userID
    );
    setValue("role", mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.role);
    setValue(
      "businessID",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.businessID
    );
    setValue(
      "businessName",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.businessName
    );
    setValue(
      "shopIDs",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? [] : data.shopIDs
    );
    setValue(
      "parentShopID",
      mode === CREATE_USERS_ADMIN_MODE_CREATE
        ? []
        : businesses[data.businessID]?.parentShopID
    );
    setValue(
      "shopID",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.shopID
    );
    setValue(
      "storeID",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.storeID
    );
    setValue("name", mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.name);
    setValue(
      "email",
      mode === CREATE_USERS_ADMIN_MODE_CREATE ? "" : data.email
    );
    setValue("password", "");
    setValue("repeatPassword", "");
    // eslint-disable-next-line
  }, [mode, data]);

  const onCreateUser = async (userData) => {
    setIsFetching(true);
    const response = await dispatch(
      startCreateUserAccountByOwner({ userData })
    );
    setIsFetching(false);
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(OWNER_USERS_CREATED_USER),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };
  const onEditUser = async (userData, userID) => {
    setIsFetching(true);
    const response = await dispatch(
      startEditUserAccountByOwner({ userData, userID })
    );
    setIsFetching(false);
    if (response) {
      onCloseModal();
      SimpleAlert({
        title: t(OWNER_USERS_SAVED_USER),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return response;
  };

  const onSubmit = async (data) => {
    if (mode === CREATE_USERS_ADMIN_MODE_CREATE) {
      const response = await onCreateUser(data);
      response && reset();
    } else {
      const response = await onEditUser(data, data.userID);
      response && reset();
    }
  };

  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      ariaLabel={t(OWNER_USERS_MODAL_ARIA_LABEL)}
      title={t(
        mode === "create"
          ? OWNER_USERS_MODAL_TITLE
          : OWNER_USERS_MODAL_TITLE_EDIT
      )}
      description={t(
        mode === "create"
          ? OWNER_USERS_MODAL_DESCRIPTION
          : OWNER_USERS_MODAL_DESCRIPTION_EDIT
      )}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <CreateAdminUsersForm
          control={control}
          watch={watch}
          mode={mode}
          errors={errors}
          setValue={setValue}
        />
        <ContainerModalActions
          hasPadding={false}
          sx={{ mt: 2 }}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_CANCEL,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            text:
              mode === CREATE_USERS_ADMIN_MODE_CREATE
                ? BUTTON_CREATE
                : BUTTON_SAVE,
            loading: isFetching,
            onClick: () => {},
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalUser;
