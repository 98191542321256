import React from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { influencersCategories } from "../../../utils/categories";
import {
  MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL,
  MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL,
  SELECT_A_OPTION,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const InfluencerInformationForm = ({ control }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 350,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Controller
        name="mainAccountName"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            margin="normal"
            label={t(MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL)}
            variant="filled"
            {...field}
          />
        )}
      />
      <Controller
        name="categories"
        control={control}
        render={({ field }) => (
          <Autocomplete
            multiple
            id="categories"
            options={influencersCategories}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label={t(MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL)}
                placeholder={t(SELECT_A_OPTION)}
              />
            )}
            {...field}
            onChange={(event, newValue) => {
              field.onChange(newValue);
            }}
          />
        )}
      />
    </Box>
  );
};

export default InfluencerInformationForm;
