import React from "react";
import Box from "@mui/material/Box";
import FilerobotImageEditor, { TABS } from "react-filerobot-image-editor";
import { useSelectorApp } from "../../../lib/redux";

const ImageEditor = ({ img, onSave }) => {
  const theme = useSelectorApp((state) => state.ui.theme);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        ".FIE_topbar-save-button": {
          backgroundColor: theme.palette.secondary.main,
          color: "black",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            filter: "brightness(0.9)",
          },
        },
      }}
    >
      <FilerobotImageEditor
        onBeforeSave={() => false}
        source={img}
        Crop={{
          autoResize: true,
        }}
        onSave={(editedImageObject) => {
          onSave(editedImageObject.imageBase64, editedImageObject.mimeType);
        }}
        annotationsCommon={{
          fill: "#000",
        }}
        language="es"
        Text={{ text: "Filerobot..." }}
        Rotate={{ angle: 90, componentType: "slider" }}
        tabsIds={[TABS.ADJUST]}
        defaultTabId={TABS.ADJUST}
      />
    </Box>
  );
};

export default ImageEditor;
