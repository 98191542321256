import React from "react";
import Box from "@mui/material/Box";
import DateTimeDisplay from "./DateTimeDisplay";
import { useTranslationApp } from "../../../lib/i18next";
import {
  DAY,
  DAYS,
  HOUR,
  HOURS,
  MINUTE,
  MINUTES,
  SECOND,
  SECONDS,
} from "../../../locales/keysTranslations";

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "1rem",
        gap: 2,
        borderRadius: 6,
        px: 2,
        pb: 0.5,
        maxWidth: 380,
      }}
    >
      <DateTimeDisplay
        value={days}
        type={days > 1 ? t(DAYS) : t(DAY)}
        isDanger={false}
      />
      <DateTimeDisplay
        value={hours}
        type={hours > 1 ? t(HOURS) : t(HOUR)}
        isDanger={days === 0 && hours <= 12}
      />
      <DateTimeDisplay
        value={minutes}
        type={minutes > 1 ? t(MINUTES) : t(MINUTE)}
        isDanger={days === 0 && hours === 0 && minutes <= 30}
      />
      <DateTimeDisplay
        value={seconds}
        type={seconds > 1 ? t(SECONDS) : t(SECOND)}
        isDanger={false}
      />
    </Box>
  );
};

export default ShowCounter;
