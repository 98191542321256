import React from "react";
import Box from "@mui/material/Box";
import InitChatButton from "../../Chat/InitChatButton";
import InfluencerNameLogo from "../../Influencer/InfluencerNameLogo";
import { useSelectorApp } from "../../../../lib/redux";

const ModalMediaPublicationHead = ({
  data,
  isAdminUser,
  onOpenChatPublication,
}) => {
  const influencers = useSelectorApp(
    (state) => state?.influencers?.influencers
  );
  const influencer = { ...influencers[data?.userID], userID: data?.userID };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        flexShrink: 0,
      }}
    >
      <InfluencerNameLogo
        variantText="h5"
        userID={data?.userID}
        data={influencer}
        colorText="white"
        sx={{ display: "flex", justifyContent: "center", mb: 0 }}
        showFavorite={true}
        modeColorFavorite="black"
      />
      {isAdminUser && (
        <InitChatButton
          color="white"
          type="icon"
          sx={{
            p: 0,
          }}
          onClick={onOpenChatPublication}
        />
      )}
    </Box>
  );
};

export default ModalMediaPublicationHead;
