import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { CssBaseline, useMediaQuery } from "@mui/material";
import Button from "../Buttons/Button";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ERROR_COMPONENT_BUTTON_RELOAD_PAGE,
  ERROR_COMPONENT_DESCRIPTION,
  ERROR_COMPONENT_TITLE,
} from "../../../locales/keysTranslations";
import { useSelectorApp } from "../../../lib/redux";
import ThemeContainer from "../Containers/ThemeContainer";

const GeneralError = ({ error }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const LOGO_BUSINESS = useSelectorApp((state) => state?.ui?.theme?.logoError);

  const onReloadPage = () => window.location.reload();

  const { t } = useTranslationApp();

  return (
    <ThemeContainer>
      <CssBaseline />
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: "background.paper",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Avatar
          src={LOGO_BUSINESS}
          alt="Logo"
          variant="square"
          sx={{
            width: "auto",
            height: "auto",
            maxHeight: "80px",
            ".MuiAvatar-img": {
              maxWidth: "200px",
              objectFit: "contain",
            },
          }}
        />
        <Typography
          variant={isMobile ? "h6" : "h5"}
          sx={{ mt: 2, fontWeight: 600 }}
          align="center"
          gutterBottom
        >
          {t(ERROR_COMPONENT_TITLE)}
        </Typography>
        <Typography
          variant={isMobile ? "body1" : "h6"}
          sx={{ fontWeight: "normal" }}
          align="center"
        >
          {t(ERROR_COMPONENT_DESCRIPTION)}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            my: 2,
          }}
        >
          <Button
            onClick={onReloadPage}
            sx={{
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "black",
                filter: "brightness(0.9)",
              },
              height: 40,
            }}
          >
            {t(ERROR_COMPONENT_BUTTON_RELOAD_PAGE)}
          </Button>
        </Box>
      </Container>
    </ThemeContainer>
  );
};

export default GeneralError;
