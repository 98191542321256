import React from "react";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ShopNameLink from "./ShopNameLink";
import { capitalizeFirstLetter, getPlanName } from "../../../utils/formats";
import {
  ACTIVE,
  COST_COLLABORATION_PLACEHOLDER,
  CREDITS_PLACEHOLDER,
  DEACTIVATED,
  NO,
  NUMBER_CURRENCY,
  SHOP_DETAIL_HAS_BILLING_CYCLE,
  STORES,
  YES,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useLocales } from "../../../Contexts/LocaleContext";
import { getNameBusinessTheme } from "../../../actions/getters";

const ShopBasicInfo = ({ data }) => {
  const dispatch = useDispatchApp();

  const { plans } = useSelectorApp((state) => state.payments);
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const { t } = useTranslationApp();

  const LOCALES = useLocales();
  const LOCALE = LOCALES[data.signUpCountry];

  const getStoresInfo = () => {
    const stores = data.stores || {};

    const storesToShow = {
      regular: false,
    };

    Object.keys(stores).forEach((storeID) => {
      const store = stores[storeID];
      const storeType = store.storeType || "regular";

      if (storesToShow[storeType] === undefined) {
        storesToShow[storeType] = store.isActive;
        return;
      }

      if (storesToShow[storeType]) return;

      storesToShow[storeType] = store.isActive;
    });

    return storesToShow;
  };

  const listStores = getStoresInfo();

  return (
    <Box>
      <ShopNameLink data={data} variantText="h5" />
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">{data.adminPhone ?? ""}</Typography>
        <Typography variant="body1">
          {getPlanName({ planID: data.subscriptionPlan, plans })}
        </Typography>
        {LOCALE.currency && (
          <Typography variant="body1">
            {`${t(COST_COLLABORATION_PLACEHOLDER)}: ${t(NUMBER_CURRENCY, {
              value: data.costCollaboration ?? 0,
              currency: LOCALE.currency,
            })}`}
          </Typography>
        )}
        <Typography variant="body1">
          {`${t(CREDITS_PLACEHOLDER, {
            NAME_BUSINESS,
          })}: ${data.clappCredits ?? 0}`}
        </Typography>
        <Typography variant="body1">
          {`${t(SHOP_DETAIL_HAS_BILLING_CYCLE)}: ${
            data.hasBillingCycle ? t(YES) : t(NO)
          }`}
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          {t(STORES)}
        </Typography>
        {Object.keys(listStores).map((storeType) => {
          const isActive = listStores[storeType];
          return (
            <Typography key={storeType} variant="body1">
              {`${capitalizeFirstLetter(storeType)}: ${
                isActive ? t(ACTIVE) : t(DEACTIVATED)
              }`}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShopBasicInfo;
