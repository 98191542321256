import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../components/Common/SearchBar/SearchBar";
import AdminUsersTable from "../../components/Owner/AdminUsers/AdminUsersTable";
import ModalUser from "../../components/Owner/AdminUsers/ModalUser";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  CREATE_USERS_ADMIN_MODE_CREATE,
  CREATE_USERS_ADMIN_MODE_EDIT,
  DEFAULT_TABLE_PAGES,
} from "../../utils/constants";
import {
  startDeleteUserAccountByOwner,
  startGetUserAdminAccounts,
  startGetUsersByEmail,
} from "../../actions/users";
import { SimpleAlert, ConfirmAlert, TimerAlert } from "../../utils/alerts";
import Button from "../../components/Common/Buttons/Button";
import Loader from "../../components/Common/Loader/Loader";
import { usePagination } from "../../hooks/usePagination";
import { startGetBusinesses } from "../../actions/businesses";
import TitlePage from "../../components/Common/Texts/TitlePage";
import { useTranslationApp } from "../../lib/i18next";
import {
  BUTTON_ADD_USER,
  OWNER_USERS_DELETE_USER_CONFIRM,
  OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION,
  OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE,
  OWNER_USERS_DELETE_USER_DELETED,
  OWNER_USERS_DELETE_USER_DELETING,
  OWNER_USERS_FAILED_DELETE_USER,
  OWNER_USERS_SEARCH_BAR,
  OWNER_USERS_TITLE,
} from "../../locales/keysTranslations";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const AdminUsers = () => {
  const [data, setData] = useState({});
  const [dataTable, setDataTable] = useState({});
  const [open, setOpenModal] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  const dispatch = useDispatchApp();
  const matches = useMediaQuery("(min-width:500px)");

  const { users, isLoading } = useSelectorApp((state) => state.users);
  const user = useSelectorApp((state) => state.auth.user);

  const { t } = useTranslationApp();

  const getNextUsers = async () => {
    await dispatch(
      startGetUserAdminAccounts({
        lastKey,
        limit: rowsPerPage,
        onChangeLastKey,
      })
    );
  };

  const getInitialData = async () => {
    dispatch(startGetBusinesses());
    dispatch(
      startGetUserAdminAccounts({
        limit: DEFAULT_TABLE_PAGES !== -1 ? DEFAULT_TABLE_PAGES + 1 : false,
        onChangeLastKey: onChangeLastKey,
      })
    );
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: getNextUsers,
  });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [user?.country]);

  useEffect(() => {
    setData(users);
    setDataTable(users);
  }, [users]);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setUserSelected(null);
  };

  const onSelectUser = (userID, user) => {
    setUserSelected({
      userID,
      ...user,
    });
    setOpenModal(true);
  };

  const onDeleteUser = async (shopID, userID) => {
    const result = await ConfirmAlert({
      title: t(OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE),
      text: t(OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(OWNER_USERS_DELETE_USER_CONFIRM),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(OWNER_USERS_DELETE_USER_DELETING),
        timer: 8000,
      });
      const response = await dispatch(
        startDeleteUserAccountByOwner({ shopID, userID })
      );
      if (response.ok) {
        SimpleAlert({
          title: t(OWNER_USERS_DELETE_USER_DELETED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      } else {
        SimpleAlert({
          title: t(OWNER_USERS_FAILED_DELETE_USER),
          text: response.message,
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
    }
  };

  const onSearchUser = async (text) => {
    onPageChange(0);
    if (text.length > 0) {
      const response = await dispatch(startGetUsersByEmail(text));
      setDataTable(response);
    } else {
      setDataTable(data);
    }
  };

  return (
    <ContainerPage>
      <TitlePage>{t(OWNER_USERS_TITLE)}</TitlePage>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: matches ? "row" : "column",
          width: "100%",
          mt: 2,
          mb: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            minWidth: 200,
          }}
        >
          <SearchBar
            placeholder={t(OWNER_USERS_SEARCH_BAR)}
            hasButton={false}
            onEditText={(text) => onSearchUser(text)}
          />
        </Box>
        <Button
          fullWidth={!matches}
          variant="contained"
          onClick={onOpenModal}
          sx={{
            mb: matches ? 0 : 2,
          }}
        >
          {t(BUTTON_ADD_USER)}
        </Button>
      </Box>
      {isLoading ? (
        <Loader size={70} fullWidth={true} />
      ) : (
        <AdminUsersTable
          data={dataTable}
          onSelectUser={onSelectUser}
          onDeleteUser={onDeleteUser}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          signUpCountry={user.country}
        />
      )}
      {open && (
        <ModalUser
          open={open}
          data={userSelected}
          onCloseModal={onCloseModal}
          mode={
            !!userSelected
              ? CREATE_USERS_ADMIN_MODE_EDIT
              : CREATE_USERS_ADMIN_MODE_CREATE
          }
        />
      )}
    </ContainerPage>
  );
};

export default AdminUsers;
