import React from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import SelectMUI from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../lib/i18next";
import {
  FIELD_REQUIRED,
  SELECT_A_OPTION,
} from "../../../locales/keysTranslations";
import i18next from "i18next";
import TooltipModal from "../Tooltips/TooltipModal";
import ContentCommon from "../Tooltips/Content/ContentCommon";
import { Chip } from "@mui/material";

const Select = ({
  dataTour = "",
  control,
  rules = { required: { value: true, message: i18next.t(FIELD_REQUIRED) } },
  errors = {},
  label = "",
  variant = "standard",
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8 * 2,
        width: 250,
      },
    },
  },
  name = "",
  value = "",
  size = "medium",
  fullWidth = false,
  idLabel = `select-${name}`,
  options = [],
  displayEmpty = false,
  actionsChange = () => {},
  disabled = false,
  placeholder = "",
  showEmptyOption = false,
  sx = {},
  className = "",
  doTranslate = false,
  isRounded = false,
  useOnChange = true,
  backgroundColor = "",
}) => {
  const hiddenLabel = variant === "filled" && !label;

  const { t } = useTranslationApp();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <FormControl
          className={className}
          fullWidth={fullWidth}
          variant={variant}
          error={!!errors[name]}
          disabled={disabled}
          sx={{
            ...sx,
          }}
          data-tour={dataTour}
          hiddenLabel={hiddenLabel}
        >
          {label && <InputLabel id={idLabel}>{label}</InputLabel>}
          <SelectMUI
            size={size}
            placeholder={placeholder}
            labelId={idLabel}
            label={label}
            displayEmpty={displayEmpty}
            {...field}
            ref={field.ref}
            inputRef={field.ref}
            MenuProps={MenuProps}
            onChange={(e) => {
              if (useOnChange) {
                field.onChange(e);
              }
              actionsChange(e.target.value);
            }}
            sx={{
              borderRadius: isRounded ? 4 : 1,
              backgroundColor,
              "& .MuiSelect-select": {
                "& .MuiChip-root": {
                  display: "none",
                },
                "& .tooltip-modal": {
                  display: "none",
                },
              },
            }}
          >
            {showEmptyOption && (
              <MenuItem
                disabled
                value=""
                sx={{
                  px: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <em>{t(SELECT_A_OPTION)}</em>
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  display: option.isHidden ? "none" : "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                >
                  {doTranslate ? t(option.label) : option.label}
                  {option.chip && <Chip label={t(option.chip)} size="small" />}
                </Box>
                {option?.explanation && (
                  <TooltipModal
                    title={t(option?.title || option?.label)}
                    content={<ContentCommon text={option.explanation} />}
                    stopPropagation={true}
                  />
                )}
              </MenuItem>
            ))}
          </SelectMUI>
          {!!errors[name] && (
            <Typography variant="caption" color="error">
              {errors[name]?.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default Select;
