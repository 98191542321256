import React, { useEffect, useState } from "react";
import { numberCurrencyFormat } from "../../../utils/numberFormats";
import {
  ACCOUNTS_REACHED,
  COMMENTS,
  LIKES,
  REPLIES,
  SAVES,
  SHARES,
  STICKERS_TAPS,
} from "../../../locales/keysTranslations";
import {
  APPLICANT_STATUS_DELIVERED,
  APPLICANT_TYPE_STATISTICS,
  ENGAGEMENT_INTERACTIONS,
  ENGAGEMENT_KEYS,
  ORDER_TYPE_STATISTICS,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING,
} from "../../../utils/constants";
import Grid from "@mui/material/Grid";
import GeneralStatisticPoint from "../../Owner/Statistics/General/GeneralStatisticPoint";
import { useTranslationApp } from "../../../lib/i18next";
import TextWithIcon from "../Texts/TextWithIcon";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import _ from "lodash";
import Loader from "../Loader/Loader";
import { useTour } from "@reactour/tour";
import { startUpdatePublicationStatistics } from "../../../actions/shops";

const ContainerOrderStatistics = ({
  data = {},
  dataItem = {},
  modeColor = "white",
  modeStatistic = "icon",
}) => {
  const [isUpdatingAnalytics, setIsUpdatingAnalytics] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const { isOpen } = useTour();

  const id = dataItem?.key;
  const orders = useSelectorApp((state) => state.orders.orders);
  const applicants = useSelectorApp((state) => state.campaigns.applicants);

  const values = {
    [ACCOUNTS_REACHED]: numberCurrencyFormat(data?.accountsReached || 0),
    [LIKES]: numberCurrencyFormat(data?.likes || 0),
    [COMMENTS]: numberCurrencyFormat(data?.comments || 0),
    [SHARES]: numberCurrencyFormat(data?.shares || 0),
    [SAVES]: numberCurrencyFormat(data?.saves || 0),
    [REPLIES]: numberCurrencyFormat(data?.replies || 0),
    [STICKERS_TAPS]: numberCurrencyFormat(data?.stickerTaps || 0),
  };

  const hasAnalytics = Object.keys(data || {})?.some(
    (analytic) => data[analytic] > 0 && ENGAGEMENT_KEYS.includes(analytic)
  );

  useEffect(() => {
    if (isOpen) {
      setIsUpdatingAnalytics(false);
      return;
    }
    if (isUpdatingAnalytics) {
      setIsUpdatingAnalytics(false);
    }
    if (
      dataItem.from === "order" &&
      orders[dataItem.key].postStatus !== POST_STATUS_NEGATIVE_REVIEW &&
      orders[dataItem.key].postStatus !== POST_STATUS_PENDING &&
      (orders[dataItem.key]?.hasUpdatableStatistics ||
        orders[dataItem.key].hasUpdatableStatistics === undefined)
    ) {
      onUpdateStatistics();
    }
    if (
      dataItem.from === "applicant" &&
      applicants[dataItem.key].status === APPLICANT_STATUS_DELIVERED &&
      (applicants[dataItem.key]?.hasUpdatableStatistics ||
        applicants[dataItem.key].hasUpdatableStatistics === undefined)
    ) {
      onUpdateStatistics();
    }
    // eslint-disable-next-line
  }, [id]);

  const onUpdateStatistics = async () => {
    setIsUpdatingAnalytics(true);
    await dispatch(
      startUpdatePublicationStatistics({
        id,
        type:
          dataItem.from === "order"
            ? ORDER_TYPE_STATISTICS
            : APPLICANT_TYPE_STATISTICS,
      })
    );
    setIsUpdatingAnalytics(false);
  };

  if (isUpdatingAnalytics && (_.isEmpty(data) || !hasAnalytics))
    return <Loader color="secondary" hasMarginTop={false} size={30} />;

  if (_.isEmpty(data) || !hasAnalytics) return null;

  return (
    <>
      {
        <Grid container justifyContent="center" spacing={3} sx={{ mb: 1 }}>
          {hasAnalytics && (
            <>
              {Object.keys(data)
                .sort()
                .map((key) => {
                  if (!ENGAGEMENT_KEYS.includes(key)) return null;
                  if (data[key] === 0) return null;
                  const interaction = ENGAGEMENT_INTERACTIONS.find(
                    (interaction) => interaction.key === key
                  );
                  return (
                    <Grid
                      key={interaction.label}
                      item
                      xs="auto"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: {
                          xs: "center",
                          sm: "flex-start",
                        },
                      }}
                    >
                      {modeStatistic === "icon" ? (
                        <TextWithIcon
                          Icon={interaction.icon}
                          text={values[interaction.label]}
                          colorIcon={modeColor}
                          colorText={modeColor}
                          sx={{
                            color: modeColor,
                          }}
                        />
                      ) : (
                        <GeneralStatisticPoint
                          sx={{
                            width: "100%",
                            alignItems: {
                              display: "flex",
                              flexDirection: "column",
                              xs: "center",
                              sm: "flex-start",
                            },
                          }}
                          Icon={interaction.icon}
                          title={t(interaction.label)}
                          value={values[interaction.label]}
                          color={modeColor}
                        />
                      )}
                    </Grid>
                  );
                })}
              {isUpdatingAnalytics && (
                <Grid item xs="auto">
                  <Loader color="secondary" hasMarginTop={false} size={30} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      }
    </>
  );
};

export default ContainerOrderStatistics;
