import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { logoPlatform } from "../../../../utils/socialNetworks";
import { TIKTOK } from "../../../../utils/constants";
import { startRevokeTiktokAccount } from "../../../../actions/shops";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import {
  ALERT_DESCRIPTION_DISCONNECT_TIKTOK,
  ARE_YOU_SURE,
  BUTTON_CONNECT_TIKTOK,
  BUTTON_DISCONNECT,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ButtonTikTokAction from "../../../Common/Buttons/ButtonTikTokAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinkText from "../../../Common/Texts/LinkText";
import { ConfirmAlert } from "../../../../utils/alerts";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const TiktokSection = () => {
  const [isFetching, setIsFetching] = useState(false);
  const ref = useRef(null);

  const { isLoading: loadingShopData, integrations } = useSelectorApp(
    (state) => state.shop
  );

  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const loading = loadingShopData;

  const tiktokIntegration = integrations?.tiktok || {};

  const {
    isActive: isTiktokConnected = false,
    deepLink = "",
    displayName = "",
  } = tiktokIntegration;

  const dispatch = useDispatchApp();

  const [params] = useSearchParams();

  const focusTiktok = params.get("focusTiktok");

  const onRevokeTiktokAccount = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DESCRIPTION_DISCONNECT_TIKTOK),
      confirmButtonText: t(BUTTON_DISCONNECT),
    });
    if (!result.isConfirmed) return;

    setIsFetching(true);
    await dispatch(startRevokeTiktokAccount());
    setIsFetching(false);
  };

  useEffect(() => {
    if (focusTiktok) {
      setTimeout(() => {
        window.document
          .querySelectorAll(".platforms-connect-section")[0]
          .scrollIntoView({
            inline: "nearest",
            behavior: "smooth",
            block: "center",
          });
      }, 300);
    }
    // eslint-disable-next-line
  }, [focusTiktok]);

  if (loading) return <Loader />;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
      ref={ref}
    >
      {isTiktokConnected ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: 320,
          }}
        >
          <Avatar
            alt={`Logo de ${TIKTOK}`}
            src={logoPlatform(TIKTOK)}
            variant="square"
            sx={{
              mr: 1,
              height: {
                xs: 30,
                sm: 40,
              },
              width: {
                xs: 30,
                sm: 40,
              },
              "& .MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <LinkText
              variant={isMobile ? "body1" : "h6"}
              href={deepLink}
              target="_blank"
              sx={{
                wordBreak: "break-all",
              }}
            >
              {displayName}
            </LinkText>
            {isFetching ? (
              <Loader size={20} hasMarginTop={false} />
            ) : (
              <IconButton onClick={onRevokeTiktokAccount}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ) : (
        <ButtonTikTokAction
          text={BUTTON_CONNECT_TIKTOK}
          sx={{
            width: "auto",
            minWidth: 230,
          }}
        />
      )}
    </Box>
  );
};

export default TiktokSection;
