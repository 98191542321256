import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { useDispatchApp } from "../../../lib/redux";
import { startGetCreatorsLocation } from "../../../actions/influencers";
import {
  capitalizeFirstLetter,
  capitalizeSections,
} from "../../../utils/formats";
import TextFieldMUI from "@mui/material/TextField";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CREATOR_LOCATION_PLACEHOLDER,
  FIELD_TYPE_MIN_LENGTH,
  EMPTY_RESULTS_SHOW,
  LOADING_INFORMATION,
} from "../../../locales/keysTranslations";
import { TIKTOK } from "../../../utils/constants";

const CountriesPhylloField = ({
  setValue,
  control,
  errors,
  name = "",
  label = CREATOR_LOCATION_PLACEHOLDER,
  isFiltering,
  watch,
  platform,
  limitTags = 5,
  actionsChange = () => {},
}) => {
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  useEffect(() => {
    (async () => {
      if (searchText.length > 2) {
        setIsLoading(true);
        let countriesOptions = await dispatch(
          startGetCreatorsLocation(searchText)
        );
        if (platform === TIKTOK) {
          countriesOptions = countriesOptions.filter(
            (location) => location?.type === "country"
          );
        }

        setOptions(countriesOptions);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [searchText]);

  const getEmptyOptionsLabel = () => {
    if (isLoading) return t(LOADING_INFORMATION);
    if (searchText.length > 2 && options.length === 0)
      return t(EMPTY_RESULTS_SHOW);
    if (searchText.length <= 2 && options.length === 0)
      return t(FIELD_TYPE_MIN_LENGTH, {
        value: 3,
      });
    return t(EMPTY_RESULTS_SHOW);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          multiple
          onClose={() => {
            setSearchText("");
          }}
          noOptionsText={getEmptyOptionsLabel()}
          disabled={typeof isFiltering === "boolean" ? !isFiltering : null}
          limitTags={limitTags}
          options={options}
          loading={isLoading}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => capitalizeSections(option.largeName)}
          filterSelectedOptions
          renderOption={(props, option) => {
            return (
              <li {...props} key={props.id}>
                {capitalizeFirstLetter(option.largeName)}
              </li>
            );
          }}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            return (
              <>
                {value.slice(0, limitTags).map((option, index) => {
                  return (
                    <Typography {...getTagProps({ index })} key={index}>
                      {capitalizeFirstLetter(option.largeName)}
                    </Typography>
                  );
                })}

                <Typography
                  variant="body1"
                  sx={{
                    mr: 1,
                  }}
                >
                  {numTags > limitTags && ` +${numTags - limitTags}`}
                </Typography>
              </>
            );
          }}
          {...field}
          onChange={(event, value) => {
            if (value.length === 0) {
              field.onChange([]);
              actionsChange([]);
              return;
            }
            if (value.length > 1) {
              const last = value.pop();
              actionsChange([last]);
              field.onChange([last]);
            } else {
              actionsChange(value);
              field.onChange(value);
            }
          }}
          renderInput={(params) => (
            <TextFieldMUI
              {...params}
              variant="filled"
              label={label}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
        />
      )}
    />
  );
};

export default CountriesPhylloField;
