import React, { useEffect, useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import Box from "@mui/material/Box";
import InfluencerDetail from "../../Common/Influencer/InfluencerDetail";
import { getAnalyticsByUser } from "../../../utils/socialNetworks";
import {
  startGetInfluencer,
  startGetReviewsInfluencer,
} from "../../../actions/influencers";
import InfluencerReviews from "../../Common/Influencer/InfluencerReviews";
import Loader from "../../Common/Loader/Loader";
import {
  startArchiveOrderByOwner,
  startDeleteInviteByOwner,
  startDeleteOrderByOwner,
  startExtendInvitationTime,
  startGetInvitationsByUser,
  startGetInvitationsInactiveByUser,
  startGetOrdersArchivedByUser,
  startGetOrdersByUser,
  startGetTransactionsByUser,
  startUnArchiveOrderByOwner,
  startUpdateStatisticsInfluencer,
} from "../../../actions/owner";
import InfluencerInvitations from "../../Common/Influencer/InfluencerInvitations";
import ModalInfluencerInformation from "../../Common/Influencer/ModalInfluencerInformation";
import Typography from "@mui/material/Typography";
import InfluencerDeliveries from "../../Common/Influencer/InfluencerDeliveries";
import { startGetShopsByShopIDs } from "../../../actions/shops";
import InfluencerOrders from "../../Common/Influencer/InfluencerOrders";
import { ConfirmAlert, SimpleAlert, TimerAlert } from "../../../utils/alerts";
import { ALERT_ICON_TYPE_SUCCESS } from "../../../utils/constants";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import { getTimeFromDateFirestore } from "../../../utils/dates";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import InfluencerOrdersArchived from "../../Common/Influencer/InfluencerOrdersArchived";
import {
  startCreateOrderFromInviteID,
  startGetOrderInfo,
} from "../../../actions/orders";
import InfluencerTransactions from "../../Common/Influencer/InfluencerTransactions";
import {
  BUTTON_ARCHIVE,
  BUTTON_DELETE,
  BUTTON_EDIT,
  BUTTON_EXTEND,
  BUTTON_UNARCHIVE,
  BUTTON_UPDATE_STATISTICS,
  CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING,
  CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_EXTENDING_INVITATION,
  CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE,
  CREATORS_OWNER_INVITATION_EXTENDED,
  CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE,
  LAST_UPDATE_DATE,
  REPORTS_TITLE,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ContainerModalActions from "../../Common/Containers/ContainerModalActions";

const ModalInfluencerDetails = ({ modalOpen, onCloseModal, data, userID }) => {
  const [influencer, setInfluencer] = useState(data);
  const [modalOpenInformation, setModalOpenInformation] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [invites, setInvites] = useState(null);
  const [invitesInactive, setInvitesInactive] = useState(null);
  const [orders, setOrders] = useState(null);
  const [ordersArchived, setOrdersArchived] = useState(null);
  const [transactions, setTransactions] = useState({});
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const getData = async () => {
    if (!data) {
      const influencer = await dispatch(startGetInfluencer(userID));
      setInfluencer(influencer);
    }

    const responseReviews = await dispatch(startGetReviewsInfluencer(userID));
    if (responseReviews.ok) {
      setReviews(responseReviews.reviews);
    }

    const responseInvites = await dispatch(
      startGetInvitationsByUser({ userID: userID })
    );
    setInvites(responseInvites);

    const responseInvitesInactive = await dispatch(
      startGetInvitationsInactiveByUser({ userID: userID })
    );
    setInvitesInactive(responseInvitesInactive);

    const responseOrders = await dispatch(
      startGetOrdersByUser({ userID: userID })
    );
    setOrders(responseOrders);
    const responseOrdersArchived = await dispatch(
      startGetOrdersArchivedByUser({ userID: userID })
    );
    setOrdersArchived(responseOrdersArchived);
    const responseTransactions = await dispatch(
      startGetTransactionsByUser({ userID: userID })
    );
    setTransactions(responseTransactions);

    if (influencer?.deliveries) {
      const shopIDs = [];
      Object.keys(influencer.deliveries).forEach((deliveryID) =>
        shopIDs.push(influencer.deliveries[deliveryID].shopID)
      );
      await dispatch(startGetShopsByShopIDs({ shopIDs }));
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInfluencer(data);
  }, [data]);

  const onChangeStatusModalInformation = (status) => {
    setModalOpenInformation(status);
  };

  const onUpdateStatisticsInfluencer = async () => {
    setIsFetching(true);
    await dispatch(startUpdateStatisticsInfluencer({ userID }));
    setIsFetching(false);
  };

  const onDeleteOrder = async (orderID) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startDeleteOrderByOwner(orderID));
      if (response) {
        setOrders((prevState) => {
          const orders = { ...prevState };
          delete orders[orderID];
          return { ...orders };
        });
      }
    }
    return;
  };
  const onArchivedOrder = async (orderID) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_ARCHIVE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startArchiveOrderByOwner(orderID));
      if (response) {
        setOrdersArchived((prevState) => {
          const ordersArchived = { ...prevState };
          return {
            [orderID]: response,
            ...ordersArchived,
          };
        });
        setOrders((prevState) => {
          const orders = { ...prevState };
          delete orders[orderID];
          return { ...orders };
        });
      }
    }
    return;
  };
  const onUnArchivedOrder = async (orderID) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_UNARCHIVE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startUnArchiveOrderByOwner(orderID));
      if (response) {
        setOrders((prevState) => {
          const orders = { ...prevState };
          return {
            [orderID]: response,
            ...orders,
          };
        });
        setOrdersArchived((prevState) => {
          const ordersArchived = { ...prevState };
          delete ordersArchived[orderID];
          return { ...ordersArchived };
        });
      }
    }
    return;
  };

  const onDeleteInvite = async (inviteID) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(startDeleteInviteByOwner(inviteID));
      if (response) {
        setInvites((prevState) => {
          const invites = { ...prevState };
          delete invites[inviteID];
          return { ...invites };
        });
      }
    }
    return;
  };

  const onExtendInvite = async (inviteID) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(BUTTON_EXTEND),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(CREATORS_OWNER_EXTENDING_INVITATION),
        timer: 15000,
      });
      const response = await dispatch(startExtendInvitationTime({ inviteID }));
      if (response) {
        setInvites((prevState) => {
          const invites = {
            ...prevState,
            [inviteID]: {
              ...prevState[inviteID],
              isExtended: true,
              expiryDays: response,
            },
          };
          return { ...invites };
        });
        SimpleAlert({
          title: t(CREATORS_OWNER_INVITATION_EXTENDED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
    return;
  };

  const onDeliveredDelivery = async ({ orderID, deliveryID }) => {
    const order = await dispatch(startGetOrderInfo(orderID));
    if (!order) return;
    setOrders((prevState) => ({
      ...prevState,
      [orderID]: order,
    }));

    setInfluencer((prevState) => ({
      ...prevState,
      deliveries: {
        ...prevState.deliveries,
        [deliveryID]: {
          ...prevState.deliveries[deliveryID],
          status: "delivered",
        },
      },
    }));

    return true;
  };

  const onCreateOrderFromInvite = async (inviteID, type) => {
    const result = await ConfirmAlert({
      title: t(CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE),
      text: t(CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION),
      confirmButtonText: t(
        CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON
      ),
    });
    if (result.isConfirmed) {
      TimerAlert({
        title: t(CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING),
        timer: 1000,
      });
      const response = await dispatch(
        startCreateOrderFromInviteID({ inviteID, type })
      );
      if (response) {
        const { order, orderID } = response;
        if (type === "active") {
          let invite = invites[inviteID];
          setInvites((prevState) => {
            const invites = { ...prevState };
            delete invites[inviteID];
            return { ...invites };
          });
          setInvitesInactive({
            ...invitesInactive,
            [inviteID]: {
              ...invite,
              redemptionDate: new Date().getTime(),
              orderID,
            },
          });
        } else {
          setInvitesInactive({
            ...invitesInactive,
            [inviteID]: {
              ...invitesInactive[inviteID],
              redemptionDate: new Date(),
              orderID,
            },
          });
        }
        setOrders({
          [orderID]: order,
          ...orders,
        });
      }
    }
    return;
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="lg"
      scroll="paper"
    >
      {loading ? (
        <Loader size={70} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-around",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: { xs: "100%", md: "60%" },
            }}
          >
            <InfluencerDetail
              analytics={getAnalyticsByUser(influencer)}
              userID={userID}
              data={influencer}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <LoadingButton
                loading={isFetching}
                onClick={onUpdateStatisticsInfluencer}
              >
                {t(BUTTON_UPDATE_STATISTICS)}
              </LoadingButton>
              {influencer?.analyticsUpdatedAt && (
                <Typography variant="body2">
                  {t(LAST_UPDATE_DATE, {
                    date: new Date(
                      getTimeFromDateFirestore(influencer?.analyticsUpdatedAt)
                    ),
                  })}
                </Typography>
              )}
              <Typography variant="body2"></Typography>
            </Box>
            <InfluencerReviews data={reviews} />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "40%" },
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="h6">{t(REPORTS_TITLE)}</Typography>
            <InfluencerInvitations
              data={invites}
              type="active"
              onDeleteInvite={onDeleteInvite}
              onExtendInvite={onExtendInvite}
              onCreateOrderFromInvite={onCreateOrderFromInvite}
              influencer={influencer}
            />
            <InfluencerInvitations
              data={invitesInactive}
              type="inactive"
              onCreateOrderFromInvite={onCreateOrderFromInvite}
              influencer={influencer}
            />
            <InfluencerDeliveries
              data={influencer.deliveries}
              userID={userID}
              onDeliveredDelivery={onDeliveredDelivery}
              influencer={influencer}
            />
            <InfluencerOrders
              data={orders}
              onDeleteOrder={onDeleteOrder}
              onArchivedOrder={onArchivedOrder}
              influencer={influencer}
            />
            <InfluencerOrdersArchived
              data={ordersArchived}
              onUnArchivedOrder={onUnArchivedOrder}
              influencer={influencer}
            />
            <InfluencerTransactions
              data={transactions}
              userID={userID}
              influencer={influencer}
              callbackCreateTransaction={(transaction) =>
                setTransactions({
                  ...transactions,
                  ...transaction,
                })
              }
            />
          </Box>
        </Box>
      )}
      <ContainerModalActions
        propsPrimaryButton={{
          showButton: true,
          text: BUTTON_EDIT,
          onClick: () => onChangeStatusModalInformation(true),
        }}
      />
      {modalOpenInformation && (
        <ModalInfluencerInformation
          modalOpen={modalOpenInformation}
          onCloseModal={() => onChangeStatusModalInformation(false)}
          data={influencer}
          userID={userID}
          onSaveInformation={(data) =>
            setInfluencer({
              ...influencer,
              ...data,
            })
          }
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalInfluencerDetails;
