import React, { useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AppRouter from "./router/AppRouter";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ThemeContainer from "./components/Common/Containers/ThemeContainer";
import { ChatProvider } from "./Contexts/ChatContext";
import * as Sentry from "@sentry/react";
import GeneralError from "./components/Common/Error/GeneralError";
import es from "date-fns/locale/es";
import MetaSDKLoader from "./components/Common/Meta/MetaSDKLoader";
import { trackingPageView } from "./services/tracking";

const stripePromise = (async () => {
  try {
    return await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  } catch (err) {
    console.error(err);
    window.location.reload();
  }
})();

const getErrorPage = (errorData) => {
  return <GeneralError {...errorData} />;
};

function ClappApp() {
  useEffect(() => {
    trackingPageView();
  }, []);

  return (
    <React.Fragment>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <Sentry.ErrorBoundary fallback={getErrorPage}>
            <ThemeContainer>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
              >
                <MetaSDKLoader />
                <ChatProvider>
                  <AppRouter />
                </ChatProvider>
              </LocalizationProvider>
            </ThemeContainer>
          </Sentry.ErrorBoundary>
        </Provider>
      </Elements>
    </React.Fragment>
  );
}

export default ClappApp;
