import React from "react";
import ModalBasicLayout from "./ModalBasicLayout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardMedia from "../Media/CardMedia";
import { useTranslationApp } from "../../../lib/i18next";
import { INSTAGRAM, TIKTOK } from "../../../utils/constants";
import TiktokUploadForm from "../Forms/Tiktok/TiktokUploadForm";
import InstagramUploadForm from "../Forms/Instagram/InstagramUploadForm";
import {
  MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TITLE,
  MODAL_UPLOAD_TIKTOK_ARIA_LABEL,
  MODAL_UPLOAD_TIKTOK_TITLE,
} from "../../../locales/keysTranslations";
import { DARK_LIGHT_COLOR } from "../../../utils/colors";

const ModalUploadSocialNetwork = ({
  modalOpen,
  onCloseModal,
  url,
  hls,
  metadata = {},
  from,
  onClosePrevComponent,
}) => {
  const { t } = useTranslationApp();

  const getTitles = () => {
    if (from === TIKTOK)
      return {
        title: MODAL_UPLOAD_TIKTOK_TITLE,
        ariaLabel: MODAL_UPLOAD_TIKTOK_ARIA_LABEL,
      };
    if (from === INSTAGRAM)
      return {
        title: MODAL_UPLOAD_INSTAGRAM_TITLE,
        ariaLabel: MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL,
      };
  };
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      title={t(getTitles().title)}
      ariaLabel={t(getTitles().ariaLabel)}
      fullScreen
      fullWidth={true}
      hasTitle={true}
      sx={{ ".MuiPaper-root": { backgroundColor: DARK_LIGHT_COLOR } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
      }}
      sxTitle={{
        py: 1,
        px: 2,
      }}
      colorTitle="white"
    >
      <Box sx={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}>
        <Grid
          container
          sx={{
            px: 2,
            width: { xs: "100%", lg: "85%" },
            height: { xs: "auto", md: "calc(100vh - 120px)" },
            maxHeight: "80vh",
            pb: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                height: "100%",
                maxHeight: "100%",
                width: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CardMedia
                url={hls || url}
                sx={{
                  height: "100%",
                  width: "auto",
                  maxWidth: "100%",
                  objectPosition: "center",
                  position: "relative",
                }}
                sxImage={{
                  objectFit: "contain",
                  objectPosition: "center top",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              maxHeight: "100%",
              pt: {
                xs: 2,
                md: 0,
              },
              pl: {
                xs: 0,
                md: 2,
              },
            }}
          >
            {from === TIKTOK && (
              <TiktokUploadForm
                url={url}
                metadata={metadata}
                onClosePrevComponent={() => {
                  onClosePrevComponent();
                  onCloseModal();
                }}
              />
            )}
            {from === INSTAGRAM && (
              <InstagramUploadForm
                metadata={metadata}
                url={url}
                onClosePrevComponent={() => {
                  onClosePrevComponent();
                  onCloseModal();
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalUploadSocialNetwork;
