import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import Button from "../../../Common/Buttons/Button";
import { SimpleAlert } from "../../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
} from "../../../../utils/constants";
import { startSaveEvent } from "../../../../actions/events";
import EventForm from "./EventForm";

const INITIAL_STATE = {
  name: "",
  description: "",
  dateTimeStart: null,
  dateTimeEnd: null,
  isRecurrent: false,
  typeRecurrent: "",
  location: "",
};
const EventFormContainer = ({ data, isMobile, eventID }) => {
  const { control, watch, getValues, setValue } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      ...data,
    },
  });
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const onSubmit = async (isActive) => {
    const formValues = getValues();
    if (
      !formValues.name ||
      !formValues.description ||
      !formValues.dateTimeStart ||
      !formValues.dateTimeEnd ||
      !formValues.location
    ) {
      return setError("Todos los campos son requeridos");
    }
    const dateStart = new Date(formValues.dateTimeStart);
    const dateEnd = new Date(formValues.dateTimeEnd);
    if (dateStart.getTime() > dateEnd.getTime()) {
      return setError(
        "La fecha de inicio no puede ser mayor a la fecha de finalización"
      );
    }
    if (formValues.isRecurrent && formValues.typeRecurrent.length === 0) {
      if (dateStart.getTime() > dateEnd.getTime()) {
        return SimpleAlert({
          title: "Ocurrió un error",
          text: "El tipo de recursión es requerido",
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }
    }
    const response = await dispatch(
      startSaveEvent({
        eventID,
        data: formValues,
        isActive,
      })
    );
    if (response) {
      SimpleAlert({
        title: `Evento guardado y ${isActive ? "activado" : "desactivado"}`,
        text: `El evento ha sido guardado y ${
          isActive ? "activado" : "desactivado"
        } correctamente`,
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    } else {
      SimpleAlert({
        title: `Error al guardar y ${
          isActive ? "activar" : "desactivar"
        } el evento`,
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
  };

  return (
    <Box
      sx={{
        my: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        variant={isMobile ? "h4" : "h5"}
        sx={{
          fontWeight: 600,
          mb: 1,
        }}
      >
        Información del Evento
      </Typography>
      {!!error && (
        <Alert
          severity="error"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
            align: "center",
          }}
          autoFocus
        >
          {error}
        </Alert>
      )}
      <EventForm control={control} watch={watch} setValue={setValue} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button variant="contained" onClick={() => onSubmit(false)}>
          Guardar Evento
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit(true)}
          color="secondary"
        >
          Activar Evento
        </Button>
      </Box>
    </Box>
  );
};

export default EventFormContainer;
