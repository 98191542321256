import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../../../../lib/redux";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PLANS_PACKAGE, PLAN_TRIAL } from "../../../../../utils/constants";
import LoadingButton from "../../../../Common/Buttons/LoadingButton";
import PackageInfo from "./PackageInfo";
import differenceInMonths from "date-fns/differenceInMonths";
import { startCancelPackagePlan } from "../../../../../actions/payments";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../../utils/colors";
import _ from "lodash";
import { getCostForCollaboration } from "../../../../../actions/getters";
import ModalStripeAddCard from "../../../../Common/Stripe/ModalStripeAddCard";
import { useSearchParams } from "react-router-dom";
import {
  BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE,
  PLAN,
} from "../../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { setShowModalSelectPackage } from "../../../../../actions/ui";
import { capitalizeFirstLetter } from "../../../../../utils/formats";
import CreditsCounter from "../../../../Common/Credits/CreditsCounter";

const PackageContainer = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [modalOpenAddSource, setModalOpenAddSource] = useState(false);
  const isActivePlan = useSelectorApp((state) => state.auth.isActivePlan);
  const isFreeTrial = useSelectorApp((state) => state.shop.isFreeTrial);
  const { plan, subscriptionDate } = useSelectorApp((state) => state.payments);

  const dispatch = useDispatchApp();

  const [params] = useSearchParams();

  const { t } = useTranslationApp();
  const openPlans = params.get("openPlans");

  const costForCollaboration = dispatch(getCostForCollaboration());

  useEffect(() => {
    if (
      openPlans &&
      ((!isActivePlan && !isFreeTrial) || isFreeTrial) &&
      plan === ""
    ) {
      dispatch(setShowModalSelectPackage(true));
    } else {
      dispatch(setShowModalSelectPackage(false));
    }
    //eslint-disable-next-line
  }, [openPlans, isFreeTrial]);

  const onChangeStatusModalAddCard = (status) => {
    setModalOpenAddSource(status);
  };

  const onCancelPackagePlan = async () => {
    setLoading(true);
    await dispatch(startCancelPackagePlan());
    setLoading(false);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", lg: "45%" },
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
        {t(PLAN)}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
          p: 2,
          width: "100%",
          minHeight: 112,
          backgroundColor: "background.paperSecondary",
          boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
          borderRadius: 4,
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          {!_.isEmpty(data) && (
            <HasPlanInformation
              data={data}
              plan={plan}
              costForCollaboration={costForCollaboration}
            />
          )}
          {_.isEmpty(data) && (
            <Typography
              variant="h5"
              align="left"
              sx={{ fontWeight: 600, mb: 1 }}
            >
              {t(BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE)}
            </Typography>
          )}
          <ActionsButtons
            plan={plan}
            isActivePlan={isActivePlan}
            loading={loading}
            subscriptionDate={subscriptionDate}
            onChangeStatusModalAddCard={onChangeStatusModalAddCard}
            onCancelPackagePlan={onCancelPackagePlan}
          />
        </Box>
        <Box
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <CreditsCounter
            onSlideMenu={true}
            sx={{
              px: 2,
              mx: 0,
            }}
          />
        </Box>
      </Paper>
      {modalOpenAddSource && (
        <ModalStripeAddCard
          modalOpen={modalOpenAddSource}
          onCloseModal={() => onChangeStatusModalAddCard(false)}
          onSubmit={() => onChangeStatusModalAddCard(false)}
          saveCard={true}
          setDefaultCard={true}
        />
      )}
    </Box>
  );
};

const ActionsButtons = ({
  plan,
  isActivePlan,
  loading,
  subscriptionDate,
  onCancelPackagePlan,
}) => {
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 1,
        justifyContent: "flex-end",
      }}
    >
      {differenceInMonths(
        new Date(),
        subscriptionDate?.length > 0 ? subscriptionDate : new Date()
      ) >= 3 &&
        isActivePlan && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <LoadingButton
              color="error"
              loading={loading}
              sx={{ width: "auto" }}
              onClick={onCancelPackagePlan}
            >
              {t(BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE)}
            </LoadingButton>
          </Box>
        )}
      {(plan === PLAN_TRIAL || plan === "") && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            flexGrow: 1,
            width: "100%",
          }}
        >
          <LoadingButton
            color="primary"
            loading={false}
            sx={{
              width: "auto",
              background: GRADIENT_PURPLE_FUCHSIA,
              color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            }}
            onClick={() => dispatch(setShowModalSelectPackage(true))}
          >
            {!isActivePlan
              ? t(BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE)
              : t(BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE)}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

const HasPlanInformation = ({ data, plan }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" align="left">
          {PLANS_PACKAGE.includes(plan) && capitalizeFirstLetter(data.name)}
        </Typography>
      </Box>
      {PLANS_PACKAGE.includes(plan) && <PackageInfo data={data} />}
    </>
  );
};

export default PackageContainer;
