import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ClientInfoModal from "../ClientInfoModal";
import DeliveryComing from "./DeliveryComing";
import Button from "../../../Common/Buttons/Button";
import { ORDER_MODAL_BUTTON_DELIVERED } from "../../../../onboarding/stepsSelectors";
import {
  BUTTON_DELIVER_ORDER,
  ORDERS_REMEMBER_MARK_AS_DELIVERED,
} from "../../../../locales/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import Typography from "@mui/material/Typography";

const ToBeDeliveryModalSection = ({ data, from, influencer, onDelivered }) => {
  const { t } = useTranslationApp();
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        px: 2,
        py: { xs: 1, md: 2 },
        overflow: "auto",
      }}
      elevation={0}
      square={true}
    >
      <ClientInfoModal
        data={data}
        totalPrice={data.totalPrice}
        influencer={influencer}
      />

      <DeliveryComing delivery={data.deliveryInfo} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "column" },
          justifyContent: "center",
          gap: 1,
          mt: 1,
        }}
      >
        <Typography variant="body1">
          {t(ORDERS_REMEMBER_MARK_AS_DELIVERED)}
        </Typography>
        <Button
          variant="contained"
          sx={{ p: { xs: 1, md: 2 } }}
          onClick={onDelivered}
          dataTour={ORDER_MODAL_BUTTON_DELIVERED}
        >
          {t(BUTTON_DELIVER_ORDER)}
        </Button>
      </Box>
    </Paper>
  );
};

export default ToBeDeliveryModalSection;
