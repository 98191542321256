import { createContext, useContext, useMemo } from "react";
import { useSelectorApp } from "../lib/redux";
import Loader from "../components/Common/Loader/Loader";
import { ROLES_DASHBOARD } from "../utils/constants";

export const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  const countryShop = useSelectorApp((state) => state?.shop?.signUpCountry);
  const countryUser = useSelectorApp((state) => state?.auth?.user?.country);
  const role = useSelectorApp((state) => state?.auth?.user?.role);

  const { locales, isLoading } = useSelectorApp((state) => state?.locales);

  const locale = useMemo(() => {
    return ROLES_DASHBOARD.includes(role)
      ? locales[countryUser] || { cities: [] }
      : locales[countryShop] || { cities: [] };
  }, [role, countryUser, countryShop, locales]);

  if (isLoading) return <Loader fullWidth fullScreen size={70} />;

  return (
    <LocaleContext.Provider
      value={{
        ...locale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  return useContext(LocaleContext);
};
export const useLocales = () => {
  const locales = useSelectorApp((state) => state?.locales?.locales);
  return locales;
};
