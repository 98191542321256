import React from "react";
import Box from "@mui/material/Box";
import Switch from "../../Common/Forms/Switchs/Switch";

const SwitchStatusEvent = ({ isActive, onChangeStatusEvent, color }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Switch isActive={isActive} onChangeIsActive={onChangeStatusEvent} />
    </Box>
  );
};

export default SwitchStatusEvent;
