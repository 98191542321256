import React, { useState } from "react";
import { useDispatchApp } from "../../../lib/redux";
import { read, utils } from "xlsx";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "../../Common/Buttons/Button";
import { ConfirmAlert, SimpleAlert } from "../../../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  MIN_ALL_NUMBER_VALUE,
} from "../../../utils/constants";
import LoadingButton from "../../Common/Buttons/LoadingButton";
import { startUploadMenu } from "../../../actions/menu";
import SelectStore from "../../Common/Selects/SelectStore";
import ModalBasicLayout from "../../Common/Modal/ModalBasicLayout";
import {
  BUTTON_CANCEL,
  BUTTON_CONTINUE,
  BUTTON_COPY_CATALOG,
  BUTTON_DOWNLOAD_FORMAT,
  BUTTON_UPLOAD_FILE,
  FILE_SELECTED,
  MODAL_UPLOAD_MENU_ARIA_LABEL,
  MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION,
  MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE,
  MODAL_UPLOAD_MENU_DESCRIPTION,
  MODAL_UPLOAD_MENU_ERROR_FORMAT,
  MODAL_UPLOAD_MENU_FILE_PLACEHOLDER,
  MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT,
  MODAL_UPLOAD_MENU_TITLE,
  MODAL_UPLOAD_MENU_UPLOADED,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import LinkText from "../../Common/Texts/LinkText";

const ModalUploadMenu = ({ open, onCloseModal, storesList, onSelectStore }) => {
  const [infoFile, setInfoFile] = useState(null);
  const [storeSelected, setStoreSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [objectMenu, setObjectMenu] = useState(null);

  const dispatch = useDispatchApp();

  const { t } = useTranslationApp();

  const onChangeFile = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (!file) return setIsLoading(false);
    setInfoFile(file.name);
    const wb = read(await file.arrayBuffer());
    const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    const newObject = {};

    let isInvalidObject = null;

    data.forEach((x) => {
      if (isInvalidObject) return;
      if (
        !x.categoria ||
        !x["nombre del producto"] ||
        !x["descripcion del producto"] ||
        !x["precio del producto"]
      ) {
        setObjectMenu(null);
        return (isInvalidObject = t(MODAL_UPLOAD_MENU_ERROR_FORMAT));
      }
      //Si la categoría no existe en mewObject entonces
      //la creamos e inicializamos el arreglo de productos.
      if (!newObject.hasOwnProperty(x.categoria)) {
        newObject[x.categoria] = {
          name: x.categoria,
          products: [],
        };
      }

      if (x["precio del producto"] < MIN_ALL_NUMBER_VALUE)
        return (isInvalidObject = t(MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT, {
          value: MIN_ALL_NUMBER_VALUE,
        }));

      //Agregamos los datos de profesionales.
      newObject[x.categoria].products.push({
        name: x["nombre del producto"],
        description: x["descripcion del producto"],
        price: x["precio del producto"],
        photoURL: x["url imagen"] ?? "",
        isActive: true,
        isProductSensitive: false,
      });
    });
    if (isInvalidObject) {
      setIsLoading(false);
      return SimpleAlert({
        title: "Error",
        text: isInvalidObject,
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    const objectMenu = Object.keys(newObject).map((key) => newObject[key]);
    setObjectMenu(objectMenu);
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const result = await ConfirmAlert({
      title: t(MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE),
      text: t(MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION),
      confirmButtonText: t(BUTTON_CONTINUE),
    });
    if (result.isConfirmed) {
      const response = await dispatch(
        startUploadMenu(storeSelected, objectMenu)
      );
      if (response) {
        SimpleAlert({
          title: t(MODAL_UPLOAD_MENU_UPLOADED),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
        onSelectStore(storeSelected);
        onCloseModal();
      }
    }
  };
  return (
    <ModalBasicLayout
      modalOpen={open}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      ariaLabel={t(MODAL_UPLOAD_MENU_ARIA_LABEL)}
      title={t(MODAL_UPLOAD_MENU_TITLE)}
      description={t(MODAL_UPLOAD_MENU_DESCRIPTION)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
          mb: 2,
        }}
      >
        <SelectStore
          storesList={storesList}
          storeSelected={storeSelected}
          onChange={(e) => setStoreSelected(e.target.value)}
          sx={{ m: 0 }}
          hiddenSpecialStores={true}
          showTooltip={false}
        />
      </Box>
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t(MODAL_UPLOAD_MENU_FILE_PLACEHOLDER)}
      </Typography>
      {infoFile && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t(FILE_SELECTED, {
            fileName: infoFile,
          })}
        </Typography>
      )}
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button variant="contained" color="primary" component="label">
          {t(BUTTON_UPLOAD_FILE)}
          <input
            hidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
            onChange={onChangeFile}
          />
        </Button>
        <LinkText
          href="https://clapp-prod.s3.amazonaws.com/ClappIn/Formato+Cat%C3%A1logo.xlsx"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <Button variant="contained" color="secondary" component="label">
            {t(BUTTON_DOWNLOAD_FORMAT)}
          </Button>
        </LinkText>
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          pt: 0,
          mb: 1,
          px: 5,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onCloseModal}
        >
          {t(BUTTON_CANCEL)}
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={!objectMenu || storeSelected.length === 0}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={onSubmit}
          sx={{ minWidth: 90 }}
        >
          {t(BUTTON_COPY_CATALOG)}
        </LoadingButton>
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalUploadMenu;
