import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableContainer from "./TableContainer";
import TableCell from "./TableCell";
import Loader from "../Loader/Loader";
import Typography from "@mui/material/Typography";
import NameInfluencerCell from "../Influencer/NameInfluencerCell";
import { MODAL_INVITE_BUTTON } from "../../../onboarding/stepsSelectors";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { getUsersHasDelivery } from "../../../actions/deliveries";
import { Link } from "react-router-dom";
import {
  capitalizeFirstLetter,
  getCityInfluencer,
} from "../../../utils/formats";
import SocialNetworkCell from "../Influencer/SocialNetworkCell";
import { getAnalyticsByUser } from "../../../utils/socialNetworks";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_EDIT_INVITE,
  BUTTON_INVITE,
  BUTTON_ORDER_IN_PROCESS,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_CITY,
  COLUMN_NAME_AUDIENCE,
  COLUMN_NAME_ENGAGEMENT,
  COLUMN_NAME_CATEGORIES,
  COLUMN_NAME_SCORE,
  EMPTY_SIMILAR_PROFILES_SHOW,
  LOADING_SIMILAR_PROFILES_INFORMATION,
  ACTIONS,
  BUTTON_ADD_AS_REFERENCE,
  BUTTON_ADDED,
  CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER,
  GO_TO_PROFILE,
} from "../../../locales/keysTranslations";
import InfluencerCategories from "../Influencer/InfluencerCategories";
import Rating from "../Forms/Rating/Rating";
import ButtonInfluencerActions from "../Influencer/ButtonInfluencerActions";
import Button from "../Buttons/Button";
import { validationProfileInfluencer } from "../../../utils/influencers";
import { getInfluencerIsHiddenFromTables } from "../../../actions/getters";

const ReferenceProfilesTable = ({
  influencers,
  referenceProfiles,
  loading,
  page,
  onPageChange,
  rowsPerPage,
  onActionInfluencer,
  onAddProfileReference,
}) => {
  const [usersDisabled, setUsersDisabled] = useState([]);
  const [data, setData] = useState(influencers);

  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();

  const invites = useSelectorApp((state) => state.invites.invites);
  const profileInfluencer = useSelectorApp(
    (state) => state.shop.profileInfluencer
  );

  useEffect(() => {
    if (loading) return;

    const newInfluencers = {};

    Object.keys(influencers).forEach((userID) => {
      const isInvited = isInfluencerInvited(userID);
      newInfluencers[userID] = { ...influencers[userID], ...isInvited };
    });
    setData(newInfluencers);

    //eslint-disable-next-line
  }, [influencers, invites]);

  const isInfluencerInvited = (userID) => {
    const defaultObject = { isInvited: false, isActive: true, userID };
    const inviteID = Object.keys(invites).find(
      (inviteID) => invites[inviteID].userID === userID
    );
    if (inviteID) {
      return {
        isInvited: true,
        isActive: invites[inviteID].isActive,
        inviteID,
        userID,
      };
    }
    return defaultObject;
  };

  useEffect(() => {
    (async () => {
      const disabled = await dispatch(getUsersHasDelivery());
      setUsersDisabled(disabled);
    })();
    //eslint-disable-next-line
  }, []);

  const isInvalidProfileInfluencer = !validationProfileInfluencer(
    profileInfluencer,
    "profileInfluencer"
  );

  const keys = Object.keys(data ?? {});

  if (isInvalidProfileInfluencer)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body1" sx={{ maxWidth: "75ch" }} align="center">
          {t(CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER)}
        </Typography>
        <Link to={`/account?focus=profileInfluencer`}>
          <Button sx={{ mt: 2 }}>{t(GO_TO_PROFILE)}</Button>
        </Link>
      </Box>
    );

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TableContainer
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        keys={keys}
        loading={loading}
        emptyText={EMPTY_SIMILAR_PROFILES_SHOW}
        hasMarginBottom={false}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CREATOR_NAME)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CITY)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_AUDIENCE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ENGAGEMENT)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CATEGORIES)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_SCORE)}
            </TableCell>
            <TableCell align="center" isHeader={true} isSticky={true}>
              {t(ACTIONS)}
            </TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#F2F2F2",
              }}
            >
              <TableCell align="center" colSpan={15}>
                <Loader
                  size={70}
                  fullWidth
                  hasMessage={true}
                  message={t(LOADING_SIMILAR_PROFILES_INFORMATION)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {(rowsPerPage > 0
              ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : keys
            ).map((key, index) => {
              const influencer = data[key];
              if (dispatch(getInfluencerIsHiddenFromTables({ userID: key })))
                return null;

              const isReferenceProfile = referenceProfiles.find(
                (profile) => profile.userID === key
              );

              return (
                <TableRow key={key}>
                  <TableCell align="center" indexRow={index}>
                    <NameInfluencerCell
                      showButton={false}
                      dataTourButton={
                        !influencer?.isInvited ? MODAL_INVITE_BUTTON : ""
                      }
                      userID={key}
                      data={influencer}
                      isInvited={influencer?.isInvited}
                      color={influencer?.isInvited ? "primary" : "secondary"}
                      textButtonInvited={
                        usersDisabled.includes(key)
                          ? t(BUTTON_ORDER_IN_PROCESS)
                          : t(BUTTON_EDIT_INVITE)
                      }
                      textButton={
                        usersDisabled.includes(key)
                          ? t(BUTTON_ORDER_IN_PROCESS)
                          : t(BUTTON_INVITE)
                      }
                      disabled={usersDisabled.includes(key)}
                      onClickButton={() => onActionInfluencer(influencer)}
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    {capitalizeFirstLetter(getCityInfluencer(influencer))}
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <SocialNetworkCell
                      platform={influencer.mainPlatform}
                      data={getAnalyticsByUser(influencer)}
                      metric="audience"
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <SocialNetworkCell
                      platform={influencer.mainPlatform}
                      data={getAnalyticsByUser(influencer)}
                      metric="engagement"
                      sign="%"
                    />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <InfluencerCategories influencer={influencer} />
                  </TableCell>
                  <TableCell align="center" indexRow={index}>
                    <Rating
                      value={influencer.score}
                      readOnly={true}
                      precision={0.5}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    indexRow={index}
                    sx={{ minWidth: 190 }}
                    isSticky={true}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Button
                        disabled={Boolean(isReferenceProfile)}
                        sx={{
                          minWidth: 155,
                        }}
                        onClick={() =>
                          onAddProfileReference({
                            userID: key,
                          })
                        }
                      >
                        {t(
                          isReferenceProfile
                            ? BUTTON_ADDED
                            : BUTTON_ADD_AS_REFERENCE
                        )}
                      </Button>
                      <ButtonInfluencerActions
                        userID={key}
                        influencer={influencer}
                        sx={{
                          minWidth: 155,
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </TableContainer>
    </Box>
  );
};

export default ReferenceProfilesTable;
