import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import {
  LOADING_CONTENT,
  MODAL_GALLERY_ARIA_LABEL,
  MODAL_GALLERY_EMPTY_CONTENT,
} from "../../../locales/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  startGetOrderInfo,
  startGetOrdersByShop,
} from "../../../actions/orders";
import { usePagination } from "../../../hooks/usePagination";
import { useDebounced } from "../../../hooks/useDebounce";
import GalleryArrowButtons from "./GalleryArrowButtons";
import { startGetUserFeedback } from "../../../actions/users";
import {
  APPLICANT_STATUS_ACCEPTED,
  APPLICANT_STATUS_PENDING,
  APPLICANT_STATUS_REJECTED,
  APPLICANT_STATUS_REVISIONS,
  POST_STATUS_NEGATIVE_REVIEW,
  POST_STATUS_PENDING,
  POST_STATUS_PENDING_APPROVAL,
  POST_STATUS_PENDING_CONTENT,
} from "../../../utils/constants";
import {
  startGetApplicantByID,
  startGetApplicantsByShopPagination,
} from "../../../actions/campaigns";
import { useTour } from "@reactour/tour";
import { getHlsUrl } from "../../../utils/urls";

const PAGE_SIZE = 10;

const ModalGalleryArrowButtons = ({
  modalOpen,
  onCloseModal,
  mode = "gallery",
  orderID,
  applicantID,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadLastItemOrders, setLoadLastItemOrders] = useState(false);
  const [loadLastItemApplicants, setLoadLastItemApplicants] = useState(false);

  const loadLastItems = loadLastItemOrders && loadLastItemApplicants;

  const dispatch = useDispatchApp();

  const orders = useSelectorApp((state) => state?.orders?.orders);
  const applicants = useSelectorApp((state) => state?.campaigns?.applicants);

  const { isOpen } = useTour();

  const { t } = useTranslationApp();

  const onChangeLoadLastItem = (status) => setLoadLastItemOrders(status);

  const getInitialData = async () => {
    if (isOpen) return;
    setLoading(true);
    dispatch(startGetUserFeedback());
    if (mode === "gallery") {
      await dispatch(
        startGetOrdersByShop({
          limit: PAGE_SIZE !== -1 ? PAGE_SIZE + 1 : false,
          onChangeLastKey: onChangeLastKey,
          getAnalytics: true,
          loadLastItem: () => onChangeLoadLastItem(true),
        })
      );
      await dispatch(
        startGetApplicantsByShopPagination({
          limit: PAGE_SIZE !== -1 ? PAGE_SIZE + 1 : false,
          onChangeLastKey: onChangeLastKeyApplicants,
          loadLastItem: () => setLoadLastItemApplicants(true),
        })
      );
    } else if (mode === "order" && orderID !== "orderDummy") {
      await dispatch(startGetOrderInfo(orderID, true));
    } else if (mode === "applicant") {
      await dispatch(startGetApplicantByID(applicantID));
    }
  };

  const getNextData = useDebounced(async () => {
    if (loadLastItemOrders && loadLastItemApplicants) return;
    if (mode !== "gallery") return;

    if (!loadLastItemOrders) {
      await dispatch(
        startGetOrdersByShop({
          lastKey,
          limit: rowsPerPage,
          onChangeLastKey,
          loadLastItem: () => onChangeLoadLastItem(true),
          getAnalytics: true,
        })
      );
    }

    if (!loadLastItemApplicants) {
      await dispatch(
        startGetApplicantsByShopPagination({
          lastKey: lastKeyApplicants,
          limit: rowsPerPage,
          onChangeLastKey: onChangeLastKeyApplicants,
          loadLastItem: () => setLoadLastItemApplicants(true),
        })
      );
    }
  }, 1000);

  const { rowsPerPage, lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: getNextData,
  });
  const {
    lastKey: lastKeyApplicants,
    onChangeLastKey: onChangeLastKeyApplicants,
  } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: () => {},
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  const updateOrdersContent = () => {
    if (Object.keys(orders).length > 0) {
      const ordersContent = onFilterOrders();
      return ordersContent;
    }
    return [];
  };
  const updateApplicantsContent = () => {
    if (Object.keys(applicants).length > 0) {
      const applicantsContent = onFilterApplicants();
      return applicantsContent;
    }
    return [];
  };

  const handleUpdate = (ordersContent, applicantsContent) => {
    let allContent;
    if (mode === "order") {
      allContent = [...ordersContent].sort(
        (a, b) => b.creationTime - a.creationTime
      );
    }
    if (mode === "applicant") {
      allContent = [...applicantsContent].sort(
        (a, b) => b.creationTime - a.creationTime
      );
    }
    if (mode === "gallery") {
      allContent = [...ordersContent, ...applicantsContent].sort(
        (a, b) => b.creationTime - a.creationTime
      );
    }

    if (allContent.length <= 3 && mode === "gallery") {
      getNextData();
      if (loading && loadLastItems) {
        setLoading(false);
        setData(allContent);
      }
    } else {
      setLoading(false);
      setData(allContent);
    }
  };

  useEffect(() => {
    const ordersContent = updateOrdersContent();
    const applicantsContent = updateApplicantsContent();

    handleUpdate(ordersContent, applicantsContent);

    if (
      Object.keys(orders).length === 0 &&
      Object.keys(applicants).length === 0 &&
      loading &&
      loadLastItems
    ) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [orders, applicants, loadLastItems]);

  const getStructureDate = ({
    url,
    verifyPostLink,
    key,
    additionalData = {},
    from = "order",
  }) => {
    if (from === "order") {
      const order = orders[key];

      const hls = order.hls;
      let hlsLink;

      if (hls) {
        hlsLink = getHlsUrl({
          url,
          hls,
        });
      }

      return {
        url,
        key,
        userID: order.userID,
        postStatus: order.postStatus,
        analyticsData: order.analyticsData,
        verifyPostLink,
        history: order.history,
        chatID: order.chatID,
        from,
        creationTime: order.dateTime,
        hls: hlsLink,
        ...additionalData,
      };
    }
    if (from === "applicant") {
      const applicant = applicants[key];

      const hls = applicant.hls;
      let hlsLink;

      if (hls) {
        hlsLink = getHlsUrl({
          url,
          hls,
        });
      }

      return {
        url,
        key,
        userID: applicant.userID,
        postStatus: applicant.status,
        analyticsData: applicant.analyticsData,
        verifyPostLink,
        history: applicant.contentHistory,
        chatID: applicant.chatID,
        from,
        creationTime: applicant.creationTime,
        hls: hlsLink,
        ...additionalData,
      };
    }
  };

  const verifyIsInValidURLContent = (url) =>
    !url.includes("firebasestorage") && !url.includes("amazonaws");

  const onFilterOrders = () => {
    let filteredData = [];

    Object.keys(orders).forEach((keyOrder) => {
      if (mode === "order" && keyOrder !== orderID) return;
      if (
        mode === "order" &&
        orders[keyOrder].postStatus === POST_STATUS_PENDING
      ) {
        filteredData.push(
          getStructureDate({
            key: keyOrder,
            additionalData: {
              isEmpty: true,
            },
            from: "order",
          })
        );
        return;
      }
      if (
        mode === "order" &&
        orders[keyOrder].postStatus === POST_STATUS_NEGATIVE_REVIEW
      ) {
        const posts = orders[keyOrder]?.negativeReview?.photoURLs;

        if (!posts)
          return filteredData.push(
            getStructureDate({
              key: keyOrder,
              additionalData: {
                categories: orders[keyOrder]?.negativeReview?.categories,
                comment: orders[keyOrder]?.negativeReview?.comment,
                isEmpty: true,
              },
              from: "order",
            })
          );

        posts.forEach((url) => {
          if (verifyIsInValidURLContent(url)) return;
          return filteredData.push(
            getStructureDate({
              url,
              key: keyOrder,
              additionalData: {
                categories: orders[keyOrder]?.negativeReview?.categories,
                comment: orders[keyOrder]?.negativeReview?.comment,
              },
              from: "order",
            })
          );
        });
        return;
      }

      const order = orders[keyOrder];
      const content = order.content;
      const history = order.history;
      const posts = orders[keyOrder].posts;

      if (
        order.postStatus === POST_STATUS_PENDING_CONTENT &&
        history?.length > 0
      ) {
        const content = history[0].content;

        content.forEach((url) => {
          if (verifyIsInValidURLContent(url)) return;
          return filteredData.push(
            getStructureDate({
              url,
              key: keyOrder,
              from: "order",
            })
          );
        });
        return;
      }

      if (order.postStatus === POST_STATUS_PENDING_APPROVAL) {
        Object.keys(content).forEach((keyPost) => {
          const url = content[keyPost];
          if (verifyIsInValidURLContent(url)) return;
          return filteredData.push(
            getStructureDate({
              url,
              key: keyOrder,
              from: "order",
            })
          );
        });

        return;
      }

      if (!posts) return;

      const index = Object.keys(posts).find((keyPost) => {
        const url = posts[keyPost];
        return verifyIsInValidURLContent(url);
      });

      const verifyPostLink = (index ? posts[index] : "") || order.socialUrl;
      Object.keys(posts).forEach((keyPost) => {
        const url = posts[keyPost];
        if (verifyIsInValidURLContent(url)) return;
        return filteredData.push(
          getStructureDate({
            url,
            key: keyOrder,
            verifyPostLink,
            from: "order",
          })
        );
      });
    });
    return filteredData;
  };

  const onFilterApplicants = () => {
    let filteredData = [];

    Object.keys(applicants).forEach((keyApplicant) => {
      if (mode === "applicant" && keyApplicant !== applicantID) return;

      const applicant = applicants[keyApplicant] || {};
      const status = applicant.status;
      const content = applicant.content || [];
      const history = applicant.contentHistory;
      const verifyPostLink = applicant.socialUrl;

      if (
        status === APPLICANT_STATUS_PENDING ||
        status === APPLICANT_STATUS_REJECTED ||
        status === APPLICANT_STATUS_ACCEPTED
      )
        return;

      if (status === APPLICANT_STATUS_REVISIONS && history?.length > 0) {
        const content = history[0]?.contentUrls;
        content.forEach((url) => {
          if (verifyIsInValidURLContent(url)) return;
          return filteredData.push(
            getStructureDate({
              url,
              key: keyApplicant,
              from: "applicant",
            })
          );
        });
        return;
      }
      content.forEach((url) => {
        if (verifyIsInValidURLContent(url)) return;
        return filteredData.push(
          getStructureDate({
            url,
            key: keyApplicant,
            verifyPostLink,
            from: "applicant",
          })
        );
      });
    });
    return filteredData;
  };

  const showGalleryButtons = mode === "gallery";
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      aria-label={t(MODAL_GALLERY_ARIA_LABEL)}
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
        overflowX: "hidden",
        "::-webkit-scrollbar": {
          width: 0,
          height: 0,
          background: "transparent",
        },
      }}
    >
      {loading ? (
        <Loader size={70} hasMessage={true} message={t(LOADING_CONTENT)} />
      ) : data.length > 0 ? (
        <GalleryArrowButtons
          data={data}
          mode={mode}
          showGalleryButtons={showGalleryButtons}
          getStructureDate={getStructureDate}
          verifyIsInValidURLContent={verifyIsInValidURLContent}
          onChangeData={setData}
          onCloseModal={onCloseModal}
          onGetNextItems={getNextData}
        />
      ) : (
        <Box
          sx={{
            height: "calc(100% - 64px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 4,
          }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{ color: "white !important" }}
          >
            {t(MODAL_GALLERY_EMPTY_CONTENT)}
          </Typography>
        </Box>
      )}
    </ModalBasicLayout>
  );
};

export default ModalGalleryArrowButtons;
