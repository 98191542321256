import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  TYPE_PERSON_LEGAL,
  TYPE_PERSON_NATURAL,
} from "../../../../utils/constants";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION,
  BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER,
} from "../../../../locales/keysTranslations";
import useMediaQuery from "@mui/material/useMediaQuery";

const PersonalSection = ({ formValues, onChangeForm }) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const LOCALE = useLocale();
  return (
    <Box sx={{ width: "100%", ml: 1, pb: 1 }}>
      <Typography
        variant={isMobile ? "body1" : "h6"}
        sx={{ mb: 2, fontWeight: 600, fontStyle: "normal" }}
      >
        {t(BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION)}
      </Typography>
      <Grid container spacing={2} sx={{ width: "calc(100% + 8px)" }}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="select-tipo-persona">
              {t(BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER)}
            </InputLabel>
            <Select
              labelId="select-tipo-persona"
              value={formValues.typePerson}
              name="typePerson"
              label={t(
                BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER
              )}
              onChange={onChangeForm}
            >
              <MenuItem value={TYPE_PERSON_NATURAL}>
                {t(BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION)}
              </MenuItem>
              <MenuItem value={TYPE_PERSON_LEGAL}>
                {t(BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION)}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {formValues.typePerson === "legal" ? (
          <Grid item xs={12} sm={6}>
            <TextField
              label={t(
                BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER
              )}
              fullWidth
              variant="filled"
              name="businessName"
              value={formValues.businessName}
              onChange={onChangeForm}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t(BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER)}
                fullWidth
                variant="filled"
                name="firstName"
                value={formValues.firstName}
                onChange={onChangeForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t(
                  BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER
                )}
                fullWidth
                variant="filled"
                name="lastName"
                value={formValues.lastName}
                onChange={onChangeForm}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="select-tipo-identificación">
              {t(BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER)}
            </InputLabel>
            <Select
              labelId="select-tipo-identificación"
              label={t(BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER)}
              name="typeId"
              value={formValues.typeId}
              onChange={onChangeForm}
            >
              {LOCALE?.documentsType &&
                LOCALE?.documentsType?.map((document) => (
                  <MenuItem value={document.value} key={document.value}>
                    {t(document.value)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t(BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER)}
            fullWidth
            variant="filled"
            name="numId"
            value={formValues.numId}
            onChange={onChangeForm}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalSection;
