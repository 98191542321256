import React, { useEffect } from "react";
import { useSelectorApp } from "../lib/redux";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROLE } from "../utils/constants";

const Error404 = () => {
  const navigate = useNavigate();
  const { user } = useSelectorApp((state) => state.auth);

  useEffect(() => {
    user
      ? navigate(user.role === ADMIN_ROLE ? "/creators" : "/delivery")
      : navigate("/login");
    // eslint-disable-next-line
  }, []);

  return <div></div>;
};

export default Error404;
