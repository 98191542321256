import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { ORDER_TO_BE_DELIVERED } from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  DELIVERY_INTERN_ON_THE_WAY,
  DELIVERY_ON_THE_WAY,
  ORDER_ID,
} from "../../../../locales/keysTranslations";
import { BORDER_RADIUS, GENERAL_REDEMPTION } from "../../../../utils/constants";
import { useTheme } from "@mui/material";

const KanbanToBeDeliveryButton = ({ data, onClickButton }) => {
  const theme = useTheme();
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        backgroundColor:
          data?.from === GENERAL_REDEMPTION
            ? "secondary.main"
            : "background.paper",
        borderRadius: BORDER_RADIUS,
      }}
      data-tour={ORDER_TO_BE_DELIVERED}
    >
      <Box
        onClick={() => onClickButton(data)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxHeight: "none",
          py: 1,
          px: 2,
          color:
            data?.from === GENERAL_REDEMPTION
              ? `${theme.palette.secondary.contrastText} !important`
              : `${theme.palette.primary.contrastText} !important`,
          textAlign: "center",
          ":hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography
          variant="body1"
          color="inherit"
          sx={{ fontWeight: 600, wordBreak: "break-all" }}
        >
          {t(ORDER_ID, {
            id: data.deliveryID,
          })}
        </Typography>
      </Box>
      <Divider
        light={true}
        sx={{
          backgroundColor:
            data?.from === GENERAL_REDEMPTION
              ? `${theme.palette.secondary.contrastText} !important`
              : `${theme.palette.primary.contrastText} !important`,
          mx: 2,
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          my: 1,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-star",
            gap: 1,
          }}
        >
          <DeliveryDiningIcon
            sx={{
              color:
                data?.from === GENERAL_REDEMPTION
                  ? `${theme.palette.secondary.contrastText} !important`
                  : `${theme.palette.primary.contrastText} !important`,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color:
                data?.from === GENERAL_REDEMPTION
                  ? `${theme.palette.secondary.contrastText} !important`
                  : `${theme.palette.primary.contrastText} !important`,
            }}
          >
            {data?.deliveryInfo?.company === "interno"
              ? t(DELIVERY_INTERN_ON_THE_WAY)
              : t(DELIVERY_ON_THE_WAY, {
                  delivery: data?.deliveryInfo?.company,
                })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default KanbanToBeDeliveryButton;
