import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGenerateAI from "../../../Common/Buttons/ButtonGenerateAI";
import {
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE,
  BUTTON_PUBLISH_CAMPAIGN,
  BUTTON_EDIT_BUDGET,
  BUTTON_GENERATE_AGAIN,
  READY,
  WHY_THESE_RECOMMENDED_SETTINGS,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  ADS_CAMPAIGN_CONTEXT,
  CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER,
  FIELD_REQUIRED,
  FIELD_MIN_LENGTH,
  FIELD_MAX_LENGTH,
} from "../../../../locales/keysTranslations";
import {
  ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
  ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
  ADS_CAMPAIGN_MODE_EDIT,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
  DRAWER_WIDTH,
} from "../../../../utils/constants";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslationApp } from "../../../../lib/i18next";
import { useDispatchApp } from "../../../../lib/redux";
import {
  startCreateAdsCampaign,
  startGetRecommendedSettingsAdsCampaign,
} from "../../../../actions/adsCampaigns";
import { ConfirmAlert, SimpleAlert } from "../../../../utils/alerts";
import Accordion from "../../../Common/Containers/Accordion";
import TypingText from "../../../Common/Texts/TypingText";
import ButtonPlatform from "../../../Common/Buttons/ButtonPlatform";
import LinearLoaderWithTime from "../../../Common/Loader/LinearLoaderWithTime";
import TextField from "../../../Common/Fields/TextField";

const AdsCampaignReasoningContainer = ({
  campaignID,
  getCurrentData = () => {},
  onChangeData = () => {},
  onChangeMode = () => {},
  onCallbackFinishGenerateAgain = () => {},
  onCallbackFinishReasoning = () => {},
  validateFields,
  setFocus = () => {},
  platform,
  reasoning,
  currency,
  mode,
  control,
  isDisabledInput,
  errors,
}) => {
  const isModeEdit = mode === ADS_CAMPAIGN_MODE_EDIT;

  const [isCreating, setIsCreating] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTyping, setIsTyping] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onGenerateAgain = async () => {
    const isValidPlatform = await validateFields("platform");
    const isValidObjective = await validateFields("objective");
    const isValidTrackingID = await validateFields("trackingID");
    const isValidContext = await validateFields("context");
    const isValidDestinationUrl = await validateFields("destinationURL");
    const isValidMediaUrl = await validateFields("mediaUrl");

    if (
      !isValidPlatform ||
      !isValidObjective ||
      !isValidTrackingID ||
      !isValidContext ||
      !isValidDestinationUrl ||
      !isValidMediaUrl
    ) {
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsGenerating(true);
    const data = getCurrentData();
    const response = await dispatch(
      startGetRecommendedSettingsAdsCampaign({
        platform: data.platform,
        objective: data.objective,
        context: data.context,
        trackingID: data.trackingID,
        destinationURL: data.destinationURL,
        mediaUrl: data.mediaUrl,
        campaignID,
      })
    );
    setIsGenerating(false);
    if (response) {
      setIsTyping(true);
      onChangeData(response.campaign);
      onCallbackFinishGenerateAgain();
    }
  };

  const onCreateCampaign = async () => {
    const isValidGlobal = await validateFields();
    if (!isValidGlobal) {
      return;
    }

    const data = getCurrentData();

    const result = await ConfirmAlert({
      title: t(ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE),
      text: t(ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION, {
        value: data.budget,
        currency,
      }),
      confirmButtonText: t(BUTTON_PUBLISH_CAMPAIGN),
      cancelButtonText: t(BUTTON_EDIT_BUDGET),
    });

    if (!result.isConfirmed) {
      setFocus("budget");
      return;
    }

    setIsCreating(true);
    const campaign = {
      ...data,
      status: ADS_CAMPAIGN_STATUS_ACTIVE,
    };

    let response = await dispatch(
      startCreateAdsCampaign({ ...campaign, campaignID })
    );
    setIsCreating(false);

    if (response) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      onChangeMode(ADS_CAMPAIGN_MODE_EDIT);
    }
  };
  return (
    <>
      <Accordion
        initialValue={true}
        title={t(WHY_THESE_RECOMMENDED_SETTINGS)}
        variantTitle={isMobile ? "body1" : "h6"}
        sx={{
          border: `1px solid ${theme.palette.background.paperSecondary}`,
          borderRadius: BORDER_RADIUS,
          "::before": {
            backgroundColor: "transparent",
          },
          m: "0 !important",
        }}
        sxTitle={{
          fontWeight: 900,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            height: "auto",
          }}
        >
          {isGenerating ? (
            <LinearLoaderWithTime
              time={40 * 1000}
              labels={[
                t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
                t(CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION),
                t(CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS),
                t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
              ]}
            />
          ) : (
            <TypingText
              text={reasoning}
              onCallbackFinish={() => {
                setIsTyping(false);
                onCallbackFinishReasoning();
              }}
              sx={{
                maxHeight: 200,
              }}
            />
          )}
        </Box>
      </Accordion>
      {!isModeEdit && !isGenerating && !isTyping && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 2,
            borderTopLeftRadius: BORDER_RADIUS,
            borderTopRightRadius: BORDER_RADIUS,
            border: `1px solid ${theme.palette.background.paperSecondary}`,
            position: "fixed",
            zIndex: 1000,
            backgroundColor: theme.palette.background.paper,
            bottom: 0,
            left: { xs: 0, md: `${DRAWER_WIDTH}px` },
            height: "auto",
            width: "100%",
            maxWidth: {
              xs: "100%",
              md: `calc(100% - ${DRAWER_WIDTH}px)`,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mb: 1,
            }}
          >
            <Typography variant={isMobile ? "body1" : "h6"}>
              {t(ADS_CAMPAIGN_CONTEXT)}
            </Typography>
          </Box>
          <TextField
            placeholder={t(CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER)}
            name="context"
            variant="filled"
            multiline
            minRows={4}
            maxRows={5}
            fullWidth
            disabled={isDisabledInput}
            control={control}
            errors={errors}
            sx={{
              ".MuiInputBase-root": {
                fontSize: "1.1rem",
                pt: 2,
                px: { xs: 1, sm: 2 },
                pb: 1.5,
                ".MuiInputBase-input": {
                  pr: 1,
                },
              },
            }}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (value.length < ADS_CAMPAIGN_CONTEXT_MIN_LENGTH) {
                  return t(FIELD_MIN_LENGTH, {
                    value: ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
                  });
                }
                if (value.length > ADS_CAMPAIGN_CONTEXT_MAX_LENGTH) {
                  return t(FIELD_MAX_LENGTH, {
                    value: ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
                  });
                }
              },
            }}
          />
          {!isGenerating && !isTyping && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
                gap: 1,
              }}
            >
              <ButtonGenerateAI
                useGradient={false}
                color="primary"
                size="large"
                disabled={isCreating}
                textButton={BUTTON_GENERATE_AGAIN}
                onCallback={onGenerateAgain}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  minWidth: 220,
                  fontSize: "1.05rem",
                }}
              />
              <ButtonPlatform
                platform={platform}
                disabled={isGenerating}
                size="large"
                type="button"
                color="white"
                loading={isCreating}
                onClick={onCreateCampaign}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  minWidth: 220,
                  fontSize: "1.05rem",
                }}
              >
                {t(BUTTON_PUBLISH_CAMPAIGN)}
              </ButtonPlatform>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default AdsCampaignReasoningContainer;
