import React, { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from "../../components/Common/SearchBar/SearchBar";
import {
  startGetShopsByOwner,
  startGetShopsByName,
  setLoadingShops,
} from "../../actions/shops";
import Loader from "../../components/Common/Loader/Loader";
import ShopsTable from "../../components/Owner/Shops/ShopsTable";
import { usePagination } from "../../hooks/usePagination";
import {
  CREATE_SHOPS_ADMIN_MODE_CREATE,
  CREATE_SHOPS_ADMIN_MODE_EDIT,
  DEFAULT_TABLE_PAGES,
} from "../../utils/constants";
import { startGetPlans } from "../../actions/payments";
import Button from "../../components/Common/Buttons/Button";
import ModalShop from "../../components/Owner/Shops/ModalShop";
import TitlePage from "../../components/Common/Texts/TitlePage";
import { startGetStaffList } from "../../actions/owner";
import { setLoadingUsers } from "../../actions/users";
import {
  BUTTON_ADD_SHOP,
  SHOPS_SEARCH_BAR_PLACEHOLDER,
  SHOPS_TITLE,
} from "../../locales/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import ContainerPage from "../../components/Common/Containers/ContainerPage";

const Shops = () => {
  const [data, setData] = useState({});
  const [dataTable, setDataTable] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [shopSelected, setShopSelected] = useState(null);
  const onGetNextPageShops = () => {
    dispatch(startGetShopsByOwner({ lastKey, limit: rowsPerPage }));
  };

  const {
    rowsPerPage,
    page,
    lastKey,
    onChangeLastKey,
    onRowsPerPageChange,
    onPageChange,
  } = usePagination({
    rowsPerPageValue: DEFAULT_TABLE_PAGES,
    onCallBackNextPage: onGetNextPageShops,
  });

  const matches = useMediaQuery("(min-width:500px)");

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const { shops, isLoading } = useSelectorApp((state) => state.shop);
  const user = useSelectorApp((state) => state.auth.user);

  useEffect(() => {
    dispatch(startGetPlans());
    return () => {
      dispatch(setLoadingShops(true));
      dispatch(setLoadingUsers(true));
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(startGetStaffList());
    dispatch(
      startGetShopsByOwner({
        limit: rowsPerPage !== -1 ? rowsPerPage + 1 : false,
      })
    );
    //eslint-disable-next-line
  }, [user?.country]);

  useEffect(() => {
    setData(shops);
    setDataTable(shops);
    const keys = Object.keys(shops);
    onChangeLastKey(keys[keys.length - 1]);
    //eslint-disable-next-line
  }, [shops]);

  const onChangeModalStatus = (status) => {
    setModalOpen(status);
  };

  const onSearchShop = async (text) => {
    if (text.length > 0) {
      const response = await dispatch(startGetShopsByName(text));
      setDataTable(response);
    } else {
      setDataTable(data);
    }
  };

  return (
    <ContainerPage>
      <TitlePage>{t(SHOPS_TITLE)}</TitlePage>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: matches ? "row" : "column",
          width: "100%",
          mt: 2,
          mb: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            minWidth: 200,
          }}
        >
          <SearchBar
            placeholder={t(SHOPS_SEARCH_BAR_PLACEHOLDER)}
            hasButton={false}
            onEditText={(text) => onSearchShop(text)}
          />
        </Box>
        <Button
          fullWidth={!matches}
          variant="contained"
          onClick={() => onChangeModalStatus(true)}
          sx={{
            minWidth: 145,
            mb: matches ? 0 : 2,
          }}
        >
          {t(BUTTON_ADD_SHOP)}
        </Button>
      </Box>
      {isLoading ? (
        <Loader size={70} fullWidth={true} />
      ) : (
        <ShopsTable
          data={dataTable}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          signUpCountry={user.country}
        />
      )}
      {modalOpen && (
        <ModalShop
          modalOpen={modalOpen}
          data={shopSelected}
          onCloseModal={() => {
            setShopSelected(null);
            onChangeModalStatus(false);
          }}
          mode={
            !!shopSelected
              ? CREATE_SHOPS_ADMIN_MODE_EDIT
              : CREATE_SHOPS_ADMIN_MODE_CREATE
          }
        />
      )}
    </ContainerPage>
  );
};

export default Shops;
