import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import KanbanCard from "./KanbanCard";
import {
  STATUS_IN_PREPARATION,
  STATUS_TO_BE_ACCEPTED,
  STATUS_TO_BE_DELIVERED,
} from "../../../onboarding/stepsSelectors";
import { useTour } from "@reactour/tour";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ORDERS_TAB_IN_PREPARATION,
  ORDERS_TAB_TO_BE_ACCEPTED,
  ORDERS_TAB_TO_BE_DELIVERED,
} from "../../../locales/keysTranslations";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`Tab-de-${index}`}
      aria-labelledby={`Tab-de-${index}`}
      sx={{ height: "calc(100vh - 370px)" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 3, sm: 4 }, height: "100%" }}>{children}</Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `Tab-de-${index}`,
    "aria-controls": `Tab-de-${index}`,
  };
}

const KanbanTabs = ({
  data,
  onStopSound,
  setData,
  ordenSelected,
  setOrdenSelected,
  storeSelected,
  onLaunchSound,
}) => {
  const { t } = useTranslationApp();
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery("(min-width:500px)");
  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const { isOpen, steps, currentStep } = useTour();

  useEffect(() => {
    if (isOpen) {
      const step = steps[currentStep];
      if (step.tab === "inPreparation") {
        setValue(1);
      }
      if (step.tab === "toBeDelivered") {
        setValue(2);
      }
    }
  }, [currentStep, isOpen, steps]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={onChange}
          variant={matches ? "fullWidth" : "scrollable"}
          scrollButtons
          allowScrollButtonsMobile
          aria-label="tabs de ordenes"
          centered={matches}
        >
          <Tab
            data-tour={STATUS_TO_BE_ACCEPTED}
            label={t(ORDERS_TAB_TO_BE_ACCEPTED)}
            {...a11yProps(ORDERS_TAB_TO_BE_ACCEPTED)}
          />
          <Tab
            data-tour={STATUS_IN_PREPARATION}
            label={t(ORDERS_TAB_IN_PREPARATION)}
            {...a11yProps(ORDERS_TAB_IN_PREPARATION)}
          />
          <Tab
            data-tour={STATUS_TO_BE_DELIVERED}
            label={t(ORDERS_TAB_TO_BE_DELIVERED)}
            {...a11yProps(ORDERS_TAB_TO_BE_DELIVERED)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <KanbanCard
          data={data?.toBeAccepted}
          onStopSound={onStopSound}
          ordenSelected={ordenSelected}
          setData={setData}
          setOrdenSelected={setOrdenSelected}
          type="toBeAccepted"
          storeSelected={storeSelected}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KanbanCard
          data={data?.inPreparation}
          ordenSelected={ordenSelected}
          setData={setData}
          setOrdenSelected={setOrdenSelected}
          type="inPreparation"
          storeSelected={storeSelected}
          onLaunchSound={onLaunchSound}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <KanbanCard
          data={data?.toBeDelivered}
          ordenSelected={ordenSelected}
          setData={setData}
          setOrdenSelected={setOrdenSelected}
          type="toBeDelivered"
          storeSelected={storeSelected}
        />
      </TabPanel>
    </Box>
  );
};

export default KanbanTabs;
