import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ClientInfoModal from "../ClientInfoModal";
import DeliverySearch from "./DeliverySearch";
import Button from "../../../Common/Buttons/Button";
import { OWNER_ROLE } from "../../../../utils/constants";
import {
  ORDER_MODAL_BUTTON_READY,
  ORDER_MODAL_DELIVERY_INFO,
} from "../../../../onboarding/stepsSelectors";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_READY_ORDER,
  BUTTON_REJECT_ORDER,
  GUIDE_NUMBER_REQUIRED,
} from "../../../../locales/keysTranslations";

const InPreparationModalSection = ({
  data,
  from,
  influencer,
  onReadyOrden,
  onRejectOrden,
}) => {
  const [error, setError] = useState(null);
  const [delivery, setDelivery] = useState({
    company: "",
    guideNumber: "",
  });

  const { t } = useTranslationApp();

  const onSubmit = () => {
    if (delivery.company !== "interno" && delivery.guideNumber.length === 0) {
      return setError(t(GUIDE_NUMBER_REQUIRED));
    }
    onReadyOrden(delivery);
  };
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        px: 2,
        py: { xs: 1, md: 2 },
        overflow: "auto",
      }}
      elevation={0}
      square={true}
    >
      <ClientInfoModal
        data={data}
        totalPrice={data.totalPrice}
        influencer={influencer}
      />
      {from !== OWNER_ROLE && (
        <>
          <DeliverySearch
            dataTour={ORDER_MODAL_DELIVERY_INFO}
            delivery={delivery}
            error={error}
            onChangeDelivery={(deliverySelect) => {
              setDelivery({
                ...delivery,
                company: deliverySelect,
              });
            }}
            onChangeGuideNumber={(guideNumber) => {
              setDelivery({
                ...delivery,
                guideNumber,
              });
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "column" },
              justifyContent: "center",
              gap: 1,
              mt: 1,
            }}
          >
            <Button
              color="error"
              variant="text"
              hasShadow={false}
              onClick={onRejectOrden}
            >
              {t(BUTTON_REJECT_ORDER)}
            </Button>
            <Button
              variant="contained"
              sx={{ p: { xs: 1, md: 2 } }}
              onClick={onSubmit}
              dataTour={ORDER_MODAL_BUTTON_READY}
            >
              {t(BUTTON_READY_ORDER)}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default InPreparationModalSection;
