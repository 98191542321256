import { EMAIL_SUPPORT } from "../../utils/constants";
import {
  SEARCH,
  LOADING_INFORMATION,
  AUDIENCE,
  AUDIENCE_TITLE_EXPLANATION,
  AUDIENCE_EXPLANATION,
  ENGAGEMENT,
  ENGAGEMENT_TITLE_EXPLANATION,
  ENGAGEMENT_EXPLANATION,
  RECORDS_IN_TABLE,
  PAGE_IN_TABLE,
  BUTTON_INVITE,
  BUTTON_EDIT_INVITE,
  BUTTON_VIEW_REQUEST,
  BUTTON_REJECT_REQUEST,
  BUTTON_ORDER_IN_PROCESS,
  BUTTON_VIEW_COLLABORATION,
  NEW_CREATORS_CATEGORY,
  CREATORS_TITLE,
  CREATOR_DESCRIPTION,
  SEARCH_BAR_TEXT_CREATORS,
  TAB_CREATORS,
  TAB_REQUESTS,
  TAB_SUGGESTED,
  CREATORS_TABLE_COLUMN_NAME,
  CREATORS_TABLE_COLUMN_CITY,
  CREATORS_TABLE_COLUMN_AUDIENCE,
  CREATORS_TABLE_COLUMN_ENGAGEMENT,
  CREATORS_TABLE_COLUMN_DATE,
  CREATORS_TABLE_COLUMN_CATEGORIES,
  CREATORS_TABLE_COLUMN_SCORE,
  CREATORS_TABLE_COLUMN_ACTIVITY,
  BUTTON_VIEW_INVITE,
  DATE_SHORT,
  MODAL_INVITATION_TITLE,
  MODAL_INVITATION_TITLE_CAMPAIGN,
  BUTTON_VIEW_METRICS,
  QUALITY_AUDIENCE,
  QUALITY_CONTENT,
  CONTENT,
  ANALYTIC,
  EMPTY_COMMENTS,
  COMMENTS,
  SHOW_COMMENTS,
  BUTTON_CANCEL_INVITE,
  INVITE_FORM_VALUE_PLACEHOLDER,
  INVITE_VALUE,
  INVITE_VALUE_EXPLANATION,
  INVITE_FORM_CODE_PLACEHOLDER,
  INVITE_FORM_CODE_HELPER_TEXT,
  INVITE_FORM_COMMENT_PLACEHOLDER,
  INVITE_FORM_COMMENT_HELPER_TEXT,
  INVITE_FORM_VALUE_HELPER_TEXT,
  INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT,
  ADVANCED_CONFIGURATION,
  INVITE_FORM_DISCOUNT_CODE_PLACEHOLDER,
  INVITE_FORM_DISCOUNT_CODE_HELPER_TEXT,
  INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION,
  INVITE_FORM_SELECT_FORMATS_PLATFORM,
  INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES,
  INVITE_FORM_FORMAT_INSTAGRAM_POST,
  INVITE_FORM_FORMAT_INSTAGRAM_REEL,
  INVITE_FORM_FORMAT_INSTAGRAM_STORY,
  INVITE_FORM_FORMAT_TIKTOK_POST,
  INVITE_FORM_FORMAT_TIKTOK_STORY,
  INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER,
  INVITE_FORM_TERMS_PLACEHOLDER,
  INVITE_FORM_SELECT_EVENTS_PLACEHOLDER,
  SELECT_EVENTS_DISABLED_OPTION,
  MENU_PLATFORM,
  MENU_ACTIONS,
  MENU_CONFIGURATION,
  MENU_ADMINISTRATION,
  SUB_MENU_CREATORS,
  SUB_MENU_AMBASSADORS,
  SUB_MENU_INVITATIONS,
  SUB_MENU_PUBLICATIONS,
  SUB_MENU_EVENTS,
  SUB_MENU_CAMPAIGNS,
  SUB_MENU_REPORTS,
  SUB_MENU_REDEEM_CODE,
  SUB_MENU_ORDERS,
  SUB_MENU_ACCOUNT,
  SUB_MENU_STORES,
  SUB_MENU_MENU,
  SUB_MENU_USERS,
  SUB_MENU_BILLING_AND_PAYMENTS,
  SUB_MENU_BILLING_DASHBOARD,
  SUB_MENU_BILLING_DATA,
  SUB_MENU_USERS_OWNER,
  SUB_MENU_BRANDS,
  SUB_MENU_BRAND_SIGNUPS,
  SUB_MENU_CREATORS_OWNER,
  SUB_MENU_CREATORS_EXTERNALS,
  SUB_MENU_PAY_CREATORS,
  SUB_MENU_CREATOR_SIGNUPS,
  SUB_MENU_CONTENTS,
  SUB_MENU_DELIVERIES,
  SUB_MENU_REPORTS_OWNER,
  SUB_MENU_FEEDBACK,
  SUB_MENU_PAYMENTS_FAILED,
  SUB_MENU_DISCOVERY,
  SUB_MENU_GALLERY,
  INVITE_SENT,
  INVITE_SENT_DESCRIPTION,
  INVITE_EDIT,
  REQUEST_REJECTED,
  REQUEST_REJECTED_DESCRIPTION,
  INVITE_CANCELED,
  INVITE_CANCELED_DESCRIPTION,
  HELP,
  CREDITS,
  DEACTIVATE,
  ACTIVATE,
  BRAND,
  NOTIFICATIONS,
  LOGOUT,
  LOADING_SUGGESTED_INFORMATION,
  GO_TO_PROFILE,
  CREATORS_SUGGESTED_EMPTY,
  CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER,
  INVITE_VALUE_REQUIRED,
  INVITE_VALUE_MIN_VALUE,
  INVITE_COMMENT_MAX_LENGTH,
  INVITE_COMMENT_NO_LINK,
  INVITE_COMMENT_NO_PHONE,
  INVITE_TERMS_LENGTH,
  INVITE_PERSONALIZED_URL_INVALID,
  WARNING,
  INVITE_MIN_VALUE_OF_MENU,
  SHOP_DEACTIVATE_ALERT,
  SHOP_DEACTIVATE_ALERT_DESCRIPTION,
  WAIT,
  INVITE_STORE_LOCATION,
  ERROR,
  INVITE_CODE_IN_OTHER_INVITE,
  ERROR_DESCRIPTION_GENERIC,
  INFLUENCER_NOT_FOUND,
  LIST_CREATORS_WITH_AUDIENCE_SIMILAR,
  LAST_UPDATE_DATE,
  BUTTON_INIT_CHAT,
  UPDATE,
  INFORMATION_INVALID_ACCESS,
  FOLLOWERS,
  AVG_LIKES,
  AVG_COMMENTS,
  ANALYTICS,
  AVG_VIEWS,
  AUDIENCE_INFORMATION,
  AUDIENCE_CREDIBILITY,
  AUDIENCE_CREDIBILITY_TITLE_EXPLANATION,
  AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION,
  AUDIENCE_BY_COUNTRY,
  AUDIENCE_BY_CITIES,
  AUDIENCE_BY_BRAND_AFFINITY,
  AUDIENCE_BY_INTERESTS,
  FEMALES,
  MALES,
  AUDIENCE_BY_DISTRIBUTION_AGE,
  AUDIENCE_BY_PERCENTAGE_GENDERS,
  BUTTON_USE_AS_REFERENCE_PROFILE,
  AUDIENCE_BY_SIMILAR_AUDIENCE,
  PROFILE,
  INFLUENCER_POPULAR_CONTENT,
  PUBLISHED_DATE,
  GALLERY_CONTENT_BUZZLY,
  EMPTY_CONTENT_SHOW,
  BUTTON_INVITE_ALL,
  LOADING_SIMILAR_PROFILES_INFORMATION,
  EMPTY_SIMILAR_PROFILES_SHOW,
  INVITE_THESE_CREATORS,
  INVITE_THESE_CREATORS_CONFIRMATION,
  FIELD_REQUIRED,
  INVITE_FORM_COMMENT_PLACEHOLDER_2,
  INVITE_COMMENT_MIN_LENGTH,
  INVITE_CREATORS,
  EMPTY_RESULTS_SHOW,
  DISCOVER_NEW_CREATORS,
  REPORT_SHOP_DESCRIPTION,
  BUTTON_DOWNLOAD,
  START_DATE_AND_END_DATE_VALIDATION,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  BUTTON_CALCULATE,
  BUTTON_ENTERPRISE_CALCULATE,
  LOADING_REPORT_INFORMATION,
  GLOBAL_REPORT_BUSINESS,
  INSUFFICIENT_DATA_REPORT,
  COLLABORATIONS,
  TOTAL_INVESTMENT,
  TOTAL_EXPENSES,
  COSTS_PER_1000_VIEWS,
  COSTS_PER_ENGAGEMENT,
  ACCOUNTS_REACHED,
  LIKES,
  SHARES,
  SAVES,
  REPLIES,
  STICKERS_TAPS,
  COLLABORATIONS_SUMMARY,
  PENDING_PUBLICATIONS,
  PENDING_ANALYTICS_PUBLICATIONS,
  FEEDBACK_PUBLICATIONS,
  UNKNOWN_FORMAT,
  INVITATIONS_SUMMARY,
  TOTAL_INVITATIONS,
  INVITATIONS_REDEEMED,
  INVITATIONS_REJECTED,
  INVITATIONS_EXPIRED,
  INVITATIONS_ACCEPTANCE_RATE,
  COLLABORATIONS_FOR_PLATFORM,
  COLLABORATION,
  FEATURED_CONTENT,
  CATEGORY,
  EMPTY_IMAGES_FOR_COMMENT,
  REDEEM_CODE_TITLE,
  REDEEM_CODE_DESCRIPTION,
  REDEEM_CODE_SEARCHBAR_PLACEHOLDER,
  BUTTON_REDEEM_CODE,
  BUTTON_ADD_STORE,
  SELECT_STORE,
  STORE,
  EMPTY_STORES,
  NO_STORES_CREATED,
  REDEEM_CODE_TABLE_COLUMN_VALUE,
  REDEEM_CODE_TABLE_COLUMN_DATE,
  REDEEM_CODE_TABLE_COLUMN_CODE,
  FIELD_NUMERIC,
  FIELD_TEXT,
  FIELD_ALPHANUMERIC,
  CODE_REDEEMED,
  VALUE_CODE_REDEEMED,
  CODE_NO_EXIST,
  CODE_NO_REDEEM_BEFORE_DATE,
  EVENT_NO_EXIST,
  EVENT_DISABLED,
  EVENT_INVITATION_NO_BELONG,
  ORDERS_SEARCHBAR_PLACEHOLDER,
  ORDERS_DESCRIPTION,
  ORDERS_TITLE,
  NEW_ORDER,
  ORDERS_TAB_TO_BE_ACCEPTED,
  ORDERS_TAB_IN_PREPARATION,
  ORDERS_TAB_TO_BE_DELIVERED,
  ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION,
  ORDERS_TAB_IN_PREPARATION_DESCRIPTION,
  ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION,
  NEW,
  MINUTE_LETTER_COUNT_DOWN,
  HOUR_LETTER_COUNT_DOWN,
  DAY_LETTER_COUNT_DOWN,
  WEEK_LETTER_COUNT_DOWN,
  DELIVERY_INTERN,
  DELIVERY_ON_THE_WAY,
  ORDER_ACCEPTED,
  ORDER_ACCEPTED_DESCRIPTION,
  ORDER_READY,
  ORDER_READY_DESCRIPTION,
  ORDER_TO_BE_DELIVERED_CONFIRMATION,
  ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON,
  ORDER_DELIVERED,
  ORDER_DELIVERED_DESCRIPTION,
  DELIVERY_SELECTED,
  ORDER_REJECTED,
  ORDER_REJECTED_DESCRIPTION,
  DELIVERY_SELECTED_DESCRIPTION,
  ORDER_TOTAL_VALUE,
  ORDER_LIST_OF_PRODUCTS,
  VALUE_WITH_CURRENCY,
  NUMBER_CURRENCY,
  ORDER_OPTIONS,
  CLIENT,
  ADDRESS,
  IDENTIFICATION_NUMBER,
  PHONE_NUMBER,
  NAME,
  BUTTON_ACCEPT_ORDER,
  BUTTON_REJECT_ORDER,
  DATE_LONG,
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  ORDER_TIME_PREPARATION_TITLE,
  MIN_VALUE_PREPARATION_ORDER,
  MAX_VALUE_PREPARATION_ORDER,
  INVALID_TIME_PREPARATION,
  TIME_PREPARATION_VALUES_VALIDATION,
  GO,
  BUTTON_READY_ORDER,
  GUIDE_NUMBER_REQUIRED,
  DELIVERY_TYPE_DUPLICATE,
  SELECT_DELIVERY_TYPE,
  SELECT_DELIVERY_TYPE_OTHER,
  GUIDE_NUMBER,
  DELIVERY_DATA,
  BUTTON_DELIVER_ORDER,
  BUTTON_CANCEL,
  BUTTON_CREATE_CAMPAIGN,
  CAMPAIGNS_TITLE,
  CAMPAIGNS_DESCRIPTION,
  CAMPAIGNS_SEARCHBAR_PLACEHOLDER,
  CAMPAIGNS_TABLE_COLUMN_NAME,
  CAMPAIGNS_TABLE_COLUMN_PLATFORMS,
  CAMPAIGNS_TABLE_COLUMN_STATUS,
  CAMPAIGN_ACTIVATE,
  CAMPAIGN_DEACTIVATE,
  CAMPAIGN_ACTIVATE_TIME_CONFIRMATION,
  CAMPAIGN_DEACTIVATE_TIME,
  CAMPAIGN_DEACTIVATE_APPLICANTS_ACCEPTED,
  CAMPAIGN_ACTIVATE_FIELDS_REQUIRED,
  CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR,
  CAMPAIGN_ACTIVATE_TRAFFIC_LINK,
  CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM,
  CAMPAIGN_ACTIVATE_ATTACHMENT_LINK,
  CREATE_CAMPAIGN_QUESTION,
  CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER,
  CREATE_CAMPAIGN_NAME_REQUIRED,
  CREATE_CAMPAIGN_DESCRIPTION_REQUIRED,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE,
  CREATE_CAMPAIGN_BUDGET_REQUIRED,
  CREATE_CAMPAIGN_BUDGET_MIN_VALUE,
  CREATE_CAMPAIGN_PLATFORMS_REQUIRED,
  CREATE_CAMPAIGN_TYPE_CONTENT,
  CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED,
  CREATE_CAMPAIGN_DATES_REQUIRED,
  CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS,
  CREATE_CAMPAIGN_ATTACHMENT_LINK,
  CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE,
  CREATE_CAMPAIGN_HAS_BEEN_SAVE,
  CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED,
  CREATE_CAMPAIGN_SAVE_PROGRESS,
  BUTTON_SAVE_PROGRESS,
  QUESTION,
  CREATE_CAMPAIGN_NAME_EXAMPLE,
  BUTTON_NEXT,
  BUTTON_PREVIOUS,
  BUTTON_ADVANCED_CONFIGURATION,
  BUTTON_FINISH,
  CREATE_CAMPAIGN_NAME_QUESTION,
  CREATE_CAMPAIGN_NAME_PLACEHOLDER,
  CREATE_CAMPAIGN_DESCRIPTION_QUESTION,
  CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER,
  CREATE_CAMPAIGN_BUDGET_QUESTION,
  CREATE_CAMPAIGN_BUDGET_DESCRIPTION,
  CREATE_CAMPAIGN_PLATFORMS,
  CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION,
  CREATE_CAMPAIGN_COPYWRITING_QUESTION,
  CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER,
  CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION,
  CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION,
  CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_DATES_QUESTION,
  CREATE_CAMPAIGN_DATES_DESCRIPTION,
  CREATE_CAMPAIGN_DATES_START_DATE_PLACEHOLDER,
  CREATE_CAMPAIGN_DATES_END_DATE_PLACEHOLDER,
  CREATE_CAMPAIGN_REPOST_QUESTION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER,
  CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER,
  CREATE_CAMPAIGN_AGE_RANGE_QUESTION,
  CREATE_CAMPAIGN_BRIEF_QUESTION,
  CREATE_CAMPAIGN_BRIEF_DESCRIPTION,
  CREATE_CAMPAIGN_UPLOAD_BRIEF,
  CREATE_CAMPAIGN_REMOVED_BRIEF,
  CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION,
  CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION,
  CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER,
  CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION,
  CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION,
  CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER,
  CREATE_CAMPAIGN_HASHTAG_QUESTION,
  CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER,
  CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION,
  CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER,
  CREATE_CAMPAIGN_ENVIRONMENT_QUESTION,
  CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER,
  CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION,
  CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER,
  CREATE_CAMPAIGN_KEY_WORDS_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION,
  CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER,
  CREATE_CAMPAIGN_OUTFIT_QUESTION,
  CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER,
  BUTTON_UPLOAD_CONTENT,
  BUTTON_REMOVE_CONTENT,
  CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER,
  READY,
  BUTTON_FINISHED_CAMPAIGN,
  CAMPAIGN_FINISHED_CONFIRMATION,
  BUTTON_SAVE_CAMPAIGN,
  BUTTON_DEACTIVATE_CAMPAIGN,
  BUTTON_ACTIVATE_CAMPAIGN,
  CAMPAIGN_HAS_BEEN_FINISHED,
  CAMPAIGN_DETAIL_TITLE,
  CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE,
  CAMPAIGN_DETAIL_CREATORS_INVITED_TITLE,
  CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE,
  BUTTON_INVITE_CREATOR,
  ACTIONS,
  STATUS,
  CITIES,
  CATEGORIES,
  CAMPAIGN_FINISH_APPLICANTS_ACCEPTED,
  PAYMENT_SUCCESSFUL,
  BUTTON_SEND_INVITE,
  CAMPAIGN_DETAIL_APPLICANT_PAYMENT_DESCRIPTION,
  CAMPAIGN_DETAIL_APPLICANT_PAYMENT_ADDITIONAL_DESCRIPTION,
  CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION,
  BUTTON_REJECT,
  CAMPAIGN_DETAIL_INVITE_CREATOR_DESCRIPTION,
  LIST_OF_CREATORS,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_DESCRIPTION,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_PLACEHOLDER,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_ACCOUNT_NAME_REQUIRED,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_SOCIAL_NETWORK_REQUIRED,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_REQUIRED,
  CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_MIN_VALUE,
  CAMPAIGN_FIELDS_REQUIRED,
  CAMPAIGN_NAME_PLACEHOLDER,
  CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER,
  CAMPAIGN_BUDGET_PLACEHOLDER,
  CAMPAIGN_PLATFORMS_PLACEHOLDER,
  CAMPAIGN_TYPE_CONTENT_PLACEHOLDER,
  CAMPAIGN_COPY_POST_PLACEHOLDER,
  CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  CAMPAIGN_HAS_COLLABORATION_PLACEHOLDER,
  CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER,
  CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER,
  CAMPAIGN_DATES_START_DATE_PLACEHOLDER,
  CAMPAIGN_DATES_END_DATE_PLACEHOLDER,
  CAMPAIGN_REPOST_PLACEHOLDER,
  CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER,
  CAMPAIGN_AGE_RANGE_PLACEHOLDER,
  CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER,
  CAMPAIGN_BRIEF_PLACEHOLDER,
  CAMPAIGN_BRIEF_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_FILE_PLACEHOLDER,
  CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_LINK_TITLE,
  CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION,
  CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER,
  CAMPAIGN_GENDERS_PLACEHOLDER,
  CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER,
  CAMPAIGN_HASHTAG_PLACEHOLDER,
  CAMPAIGN_MESSAGE_TONE_PLACEHOLDER,
  CAMPAIGN_ENVIRONMENT_PLACEHOLDER,
  CAMPAIGN_COLORS_CONTENT_PLACEHOLDER,
  CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER,
  CAMPAIGN_KEY_WORDS_PLACEHOLDER,
  CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER,
  CAMPAIGN_OUTFIT_PLACEHOLDER,
  BUTTON_ASSIGN_INVITE,
  BUTTON_REVIEW_CONTENT,
  BUTTON_ACCEPT,
  BUTTON_VIEW_PRELIMINARY_CONTENT,
  BUTTON_VIEW_APPLICANT,
  BUTTON_ACCEPT_AND_PAY,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  CONTENT_CREATOR,
  PRICE_TO_BE_PAID,
  PAYMENT_METHODS_SECURE,
  PAYMENT_METHODS_SECURE_EXPLANATION,
  PAYMENT_METHODS_ENCRYPTED,
  PAY,
  PAYMENT_METHODS_EMPTY,
  MAIN_PAYMENT_METHOD,
  OTHERS_PAYMENT_METHODS_EMPTY,
  OTHERS_PAYMENT_METHODS,
  ADD_CARD,
  SAVE_CONFIGURATION,
  ADD,
  NAME_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  PASSWORD_CONFIRMATION_PLACEHOLDER,
  CARD_INFORMATION_EXPLANATION_TITLE,
  CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE,
  CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE,
  CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION,
  CARD_INFORMATION_CVV_EXPLANATION_TITLE,
  CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION,
  APPLICANTS_STATUS_PENDING,
  APPLICANTS_STATUS_ACCEPTED,
  APPLICANTS_STATUS_REJECTED,
  APPLICANTS_STATUS_CONTENT_SUBMITTED,
  APPLICANTS_STATUS_CONTENT_REVISIONS,
  APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL,
  APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS,
  APPLICANTS_STATUS_DELIVERED,
  BUTTON_REVIEW,
  APPLICANT_CONTENT_OF_CREATOR,
  APPLICANT_REVISION_PLACEHOLDER,
  BUTTON_ACCEPT_CONTENT,
  BUTTON_REJECT_CONTENT,
  BUTTON_SEND_REVIEW,
  BUTTON_CONTINUE,
  BUTTON_MORE_QUESTIONS,
  BUTTON_UNDERSTOOD,
  WARNING_MOBILE_TUTORIAL,
  FAQ_DESCRIPTION,
  EMPTY_QUESTIONS_SHOW,
  BUTTON_INIT_TUTORIAL,
  FAQ_QUESTION_WHAT_IS_BUZZLY,
  FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_REQUEST,
  FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_PUBLICATION,
  FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_AMBASSADOR,
  FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_CAMPAIGN,
  FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_ORDER,
  FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_STORE,
  FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION,
  FAQ_QUESTION_WHAT_ROLES_EXISTS,
  FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION,
  FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS,
  FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION,
  FAQ_QUESTION_MUST_CALL_ADMIN,
  FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION,
  FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION,
  FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION,
  FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE,
  FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION,
  FAQ_QUESTION_WHAT_IS_INVITATION,
  FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION,
  BUTTON_PAYMENTS,
  BUTTON_BROADCAST,
  AMBASSADORS_TITLE,
  AMBASSADORS_DESCRIPTION,
  AMBASSADORS_TABLE_COLUMN_PLATFORM,
  AMBASSADORS_TABLE_COLUMN_INVITATION,
  AMBASSADORS_TABLE_ARIAL_LABEL,
  BUTTON_SAVE,
  MODAL_AMBASSADOR_ARIAL_LABEL,
  MODAL_AMBASSADOR_TITLE,
  RECURRENT_FIELDS_DEFAULT_TITLE,
  RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION,
  RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER,
  RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION,
  RECURRENT_FIELD_FREQUENCY_PLACEHOLDER,
  RECURRENT_FIELD_DAY_AN_PLACEHOLDER,
  RECURRENT_FIELD_DAY_ALL_PLACEHOLDER,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  SELECT_A_OPTION,
  WEEK,
  MONTH,
  MONTHS,
  MODAL_BROADCAST_ARIAL_LABEL,
  MODAL_BROADCAST_TITLE,
  MODAL_BROADCAST_SELECT_USER_PLACEHOLDER,
  MODAL_BROADCAST_SELECT_USER_REQUIRED,
  MODAL_BROADCAST_MESSAGE_PLACEHOLDER,
  BROADCAST_MESSAGE_SENT,
  BUTTON_SEND_MESSAGE,
  BUTTON_UPLOAD_FILE,
  BUTTON_REMOVE_FILE,
  FILE_SELECTED,
  MODAL_PAY_AMBASSADORS_ARIAL_LABEL,
  MODAL_PAY_AMBASSADORS_TITLE,
  MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER,
  MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED,
  MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER,
  MODAL_PAY_AMBASSADOR_AMOUNT_MIN,
  MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR,
  SELECT_PAYMENT_METHOD,
  MODAL_PAY_AMBASSADOR_TITLE_2,
  PAY_TO_AMBASSADORS,
  YOU_WILL_PAY,
  AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION,
  INVITATIONS_TITLE,
  INVITATIONS_DESCRIPTION,
  INVITATIONS_ACTIVE_TITLE,
  INVITATIONS_ACTIVE_TABLE_ARIA_LABEL,
  INVITATIONS_TABLE_COLUMN_CODE,
  INVITATIONS_TABLE_COLUMN_VALUE,
  INVITATIONS_TABLE_COLUMN_USE,
  INVITATIONS_TABLE_COLUMN_EXPIRATION,
  INVITATION_EXPIRED,
  DAY,
  HOUR,
  MINUTE,
  SECOND,
  SECONDS,
  INVITATION_REDEEMED,
  INVITATION_REJECTED,
  INVITATIONS_INACTIVE_TITLE,
  INVITATIONS_INACTIVE_REDEEM_FILTER,
  INVITATIONS_INACTIVE_EXPIRED_FILTER,
  INVITATIONS_INACTIVE_TABLE_ARIA_LABEL,
  INVITATIONS_TABLE_COLUMN_TYPE_INVITATION,
  INVITATIONS_TABLE_COLUMN_REJECTED_REASON,
  INVITATIONS_TABLE_COLUMN_DATE,
  INVITATIONS_EVENT_TABLE,
  INVITATIONS_EVENT_TABLE_ARIA_LABEL,
  INVITATIONS_EVENT_TABLE_COLUMN_EVENT,
  PUBLICATION_TITLE,
  NUMBER_PUBLICATION,
  FEEDBACK_TITLE,
  PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION,
  PUBLICATION_FINISH_DESCRIPTION,
  FEEDBACK_PLACEHOLDER,
  FIELD_MIN_LENGTH,
  PUBLICATIONS_TITLE,
  PUBLICATIONS_DESCRIPTION,
  PUBLICATIONS_TABLE_ARIA_LABEL,
  PUBLICATIONS_TABLE_COLUMN_DATE,
  PUBLICATION_STATUS_PENDING,
  PUBLICATIONS_STATUS_PENDING_ANALYTICS,
  PUBLICATIONS_STATUS_SENT,
  PUBLICATIONS_STATUS_FEEDBACK,
  PUBLICATIONS_STATUS_VERIFIED,
  FEEDBACK_REASON,
  FEEDBACK_COMMENT,
  ANALYTICS_EXPLANATION_TITLE,
  ANALYTICS_EXPLANATION_DESCRIPTION,
  ANALYTICS_EXPLANATION_DESCRIPTION_2,
  ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION,
  ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION,
  ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION,
  ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION,
  EMPTY_ANALYTICS,
  BUTTON_VERIFY_PUBLICATION,
  FEEDBACK_REASON_ABOUT,
  BUTTON_INVITE_AGAIN,
  YES,
  NO,
  RECURRENT_VALUE_NOT_FOUND,
  MODAL_REVIEW_ARIA_LABEL,
  MODAL_REVIEW_DISCLAIMER,
  MODAL_REVIEW_SCORE_CONTENT_PLACEHOLDER,
  MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE,
  MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE,
  MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION,
  MODAL_REVIEW_SCORE_ENGAGEMENT_PLACEHOLDER,
  MODAL_REVIEW_SCORE_ENGAGEMENT_MIN_VALUE,
  MODAL_REVIEW_COMMENT_PLACEHOLDER,
  INFLUENCER_IS_AMBASSADOR,
  MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER,
  DO_AMBASSADOR,
  DONE_AMBASSADOR,
  MODAL_DO_AMBASSADOR_TITLE,
  MODAL_DO_AMBASSADOR_ARIA_LABEL,
  LOADING_DOWNLOAD_PROFILES,
  BUTTON_INVITE_BUZZLY,
  DISCOVERY_INFLUENCERS_TITLE,
  DISCOVERY_INFLUENCERS_DESCRIPTION,
  DISCOVERY_INFLUENCERS_DONE_ONBOARDING,
  DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS,
  DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED,
  DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION,
  DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE,
  DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED,
  DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE,
  DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE,
  DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER,
  DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER,
  BUTTON_SEARCH,
  FIELD_TYPE_MIN_LENGTH,
  BUTTON_METRICS_READY,
  DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT,
  DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES,
  DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED,
  DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND,
  DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS,
  IMAGES,
  VIDEOS,
  LOADING_CONTENT,
  MODAL_GALLERY_ARIA_LABEL,
  MODAL_GALLERY_EMPTY_CONTENT,
  MODAL_GALLERY_NO_MORE_CONTENT,
  MODAL_MEDIA_ARIA_LABEL,
  BUTTON_UPLOAD_TIKTOK,
  MODAL_UPLOAD_TIKTOK_ARIA_LABEL,
  MODAL_UPLOAD_TIKTOK_TITLE,
  MODAL_UPLOAD_FAILED_GET_CONFIGURATION,
  LOADING_CONFIGURATION,
  BUTTON_VIEW_PROFILE,
  BUTTON_GENERATE_DESCRIPTION,
  MODAL_UPLOAD_CONTENT_UPLOADED,
  MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION,
  MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION,
  MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL,
  MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION,
  MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION,
  MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION,
  ACCOUNT_TITLE,
  ACCOUNT_DESCRIPTION,
  BUTTON_UPLOAD_IMAGE,
  BUTTON_REMOVE_IMAGE,
  FIELD_IMAGE_REQUIRED,
  FIELD_IMAGE_FORMAT_VALID,
  FIELD_IMAGE_SIZE_VALID,
  FIELD_FILE_REQUIRED,
  FIELD_FILE_SIZE_VALID,
  FIELD_URL_VALID,
  COMPANY_INFORMATION_TITLE,
  COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE,
  COMPANY_INFORMATION_TYPE_ID_PLACEHOLDER,
  COMPANY_INFORMATION_ID_NUMBER_PLACEHOLDER,
  COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH,
  COMPANY_INFORMATION_ID_NUMBER_TYPE,
  COMPANY_INFORMATION_BRAND_NAME_PLACEHOLDER,
  COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER,
  COMPANY_INFORMATION_ADDRESS_PLACEHOLDER,
  COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER,
  COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE,
  COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER,
  COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER,
  COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER,
  COMPANY_INFORMATION_CATEGORY_PLACEHOLDER,
  COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER,
  COMPANY_INFORMATION_NEW_SUBCATEGORY,
  COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER,
  COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER,
  COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER,
  COMPANY_INFORMATION_VIEW_DOCUMENT,
  COMPANY_INFORMATION_SHOP_ID_COPIED,
  BUTTON_SAVE_CHANGES,
  PROFILE_INFLUENCER_INFORMATION_TITLE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_ENGAGEMENT,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_MIN_VALUE,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION1,
  PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2,
  AUTOMATIC_INVITES_DEACTIVATE,
  AUTOMATIC_INVITES_ACTIVATE,
  AUTOMATIC_INVITES_TITLE,
  AUTOMATIC_INVITES_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE,
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_BUDGETS_DESCRIPTION,
  AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION1,
  AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION2,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT,
  BUTTON_UPLOAD_VIDEO,
  BUTTON_REMOVE_VIDEO,
  AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION,
  AUTOMATIC_INVITES_OPTIONAL_COMMENT_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_TERMS_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER,
  AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER,
  PROFILE_INFLUENCER_INFORMATION_SAVED,
  BUTTON_DISCONNECT,
  BUTTON_CONNECT_TIKTOK,
  PLATFORMS_CONNECTED_TITLE,
  SCHEDULE_TITLE,
  SCHEDULE_ECOMMERCE_ALERT_TITLE,
  SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION,
  SCHEDULE_ONE_DAY_WITH_TURN,
  SCHEDULE_EMPTY_TURN,
  SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION,
  SCHEDULE_MAX_CLOSE_HOUR,
  BUTTON_BE_ECOMMERCE,
  BUTTON_NOT_BE_ECOMMERCE,
  BUTTON_ADD_TURN,
  SCHEDULE_TABLE_ARIA_LABEL,
  SCHEDULE_TABLE_COLUMN_OPEN,
  SCHEDULE_TABLE_COLUMN_CLOSE,
  SCHEDULE_SAVED,
  LEGAL_REPRESENTATIVE_TITLE,
  LEGAL_REPRESENTATIVE_NAME_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_LAST_NAME_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_TYPE_ID_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_ID_NUMBER_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_PHONE_PLACEHOLDER,
  LEGAL_REPRESENTATIVE_SAVED,
  PASSWORD_TITLE,
  PASSWORD_CURRENT_PASSWORD_PLACEHOLDER,
  PASSWORD_NEW_PASSWORD_PLACEHOLDER,
  PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER,
  PASSWORD_FORMAT_DESCRIPTION,
  PASSWORD_FORMAT_SUGGESTION1,
  PASSWORD_FORMAT_SUGGESTION2,
  PASSWORD_FORMAT_SUGGESTION3,
  PASSWORD_FORMAT_SUGGESTION4,
  PASSWORD_INSECURE,
  PASSWORDS_NOT_MATCH,
  PASSWORD_CHANGED,
  PASSWORD_CURRENT_INVALID,
  PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS,
  STORES_TITLE,
  STORES_DESCRIPTION,
  STORES_STORE_WOOCOMMERCE_CREATED,
  STORES_STORE_NAME_DUPLICATE,
  STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION,
  STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION,
  STORES_STORE_CREATED,
  STORES_STORE_CREATED_DESCRIPTION,
  STORES_STORE_COORDS_NOT_FOUND,
  STORES_STORE_SAVED,
  STORES_STORE_NO_DELETED_LAST_STORE,
  STORES_STORE_DELETE_CONFIRMATION_TITLE,
  STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION,
  STORES_STORE_DELETED,
  OK,
  BUTTON_DELETE,
  STORES_TABLE_ARIAL_LABEL,
  STORES_TABLE_COLUMN_NAME,
  STORES_TABLE_COLUMN_CITY,
  STORES_TABLE_COLUMN_ADDRESS,
  STORES_TABLE_COLUMN_EMAIL,
  STORES_TABLE_COLUMN_PHONE,
  STORES_TABLE_COLUMN_STATUS,
  STORES_TABLE_COLUMN_TYPE,
  BUTTON_CREATE,
  MODAL_STORE_ARIA_LABEL,
  MODAL_STORE_TYPE_PLACEHOLDER,
  MODAL_STORE_HOW_TO_INTEGRATE_STORE,
  MODAL_STORE_NAME_PLACEHOLDER,
  MODAL_STORE_DESCRIPTION_PLACEHOLDER,
  MODAL_STORE_ADDRESS_PLACEHOLDER,
  MODAL_STORE_CITY_PLACEHOLDER,
  MODAL_STORE_PHONE_PLACEHOLDER,
  MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER,
  MODAL_STORE_NAME_SHOPIFY_PLACEHOLDER,
  MODAL_STORE_LOOK_NAME_SHOPIFY,
  MODAL_STORE_LOOK_NAME_SHOPIFY_HELPER_TEXT,
  MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER,
  MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER,
  MODAL_STORE_CITIES_PLACEHOLDER,
  MODAL_STORE_ORDERS_METHODS_PLACEHOLDER,
  MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION,
  MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION,
  MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER,
  MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION,
  MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION,
  MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER,
  FIELD_EMAIL_VALID,
  FIELD_SPACES_VALID,
  MODAL_STORE_TYPE_REGULAR_OPTION,
  STORES_STORE_WOOCOMMERCE_DUPLICATE,
  FIELD_PHONE_VALID,
  BUTTON_CREATE_USER,
  FILTER,
  OPERATOR,
  ADMIN,
  USERS_TITLE,
  USERS_DESCRIPTION,
  USERS_TABS_ARIA_LABEL,
  USERS_PORTAL_TAB,
  USERS_CONTACTS_TAB,
  USERS_EMAIL_DUPLICATE,
  USERS_USER_CREATED,
  USERS_USER_SAVED,
  USERS_USER_DELETE_CONFIRMATION_TITLE,
  USERS_USER_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_USER_DELETING,
  USERS_USER_DELETED,
  USERS_ONBOARDING_FINISH,
  USERS_USER_SEARCH_BAR_PLACEHOLDER,
  USERS_TABLE_ARIA_LABEL,
  USERS_TABLE_COLUMN_NAME,
  USERS_TABLE_COLUMN_EMAIL,
  USERS_TABLE_COLUMN_STORE,
  USERS_TABLE_COLUMN_ROLE,
  MODAL_USER_ARIA_LABEL,
  MODAL_USER_TITLE,
  MODAL_USER_NAME_PLACEHOLDER,
  MODAL_USER_ROLE_PLACEHOLDER,
  MODAL_USER_ROLE_OPERATOR_EXPLANATION,
  MODAL_USER_STORE_PLACEHOLDER,
  MODAL_USER_PASSWORD_PLACEHOLDER,
  MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER,
  FIELD_ALL_REQUIRED,
  FIELD_PASSWORDS_NO_MATCH,
  FIELD_PASSWORD_MIN_LENGTH,
  BUTTON_CREATE_CONTACT,
  USERS_CONTACT_CREATED,
  USERS_CONTACT_SAVED,
  USERS_CONTACT_DELETE_CONFIRMATION_TITLE,
  USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION,
  USERS_CONTACT_DELETED,
  USERS_CONTACT_SEARCH_BAR_PLACEHOLDER,
  USERS_CONTACT_TABLE_ARIA_LABEL,
  USERS_CONTACT_TABLE_COLUMN_PHONE,
  MODAL_CONTACT_ARIA_LABEL,
  MODAL_CONTACT_TITLE,
  MODAL_CONTACT_PHONE_PLACEHOLDER,
  BILLING_DATA_TITLE,
  BILLING_DATA_DESCRIPTION,
  BILLING_DATA_SAVED,
  BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION,
  BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER,
  BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION,
  BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER,
  BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER,
  BILLING_AND_PAYMENTS_TITLE,
  BILLING_AND_PAYMENTS_DESCRIPTION,
  BUTTON_VIEW_DETAILS,
  BUTTON_DOWNLOAD_INVOICE,
  BUTTON_CLOSE,
  TOTAL,
  BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_PAID_DATE,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_DUE_DATE,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_VALUE,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_REASON,
  BILLING_AND_PAYMENTS_TABLE_COLUMN_STATUS,
  PAYMENT_REASON_COLLABORATION,
  PAYMENT_REASON_APPLICANT,
  PAYMENT_REASON_AMBASSADOR,
  PAYMENT_REASON_CLAPP_CREDITS,
  PAYMENT_REASON_PACKAGE,
  PAYMENT_REASON_PLAN,
  PAYMENT_PAID,
  PAYMENT_PENDING,
  MODAL_INVOICE_ARIA_LABEL,
  MODAL_INVOICE_TITLE,
  MODAL_INVOICE_DISCLAIMER,
  MODAL_INVOICE_NUMBER_CREDITS,
  MODAL_INVOICE_ORDER_TO_COLLECT,
  MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID,
  MODAL_PAY_INVOICE_ARIA_LABEL,
  MODAL_PAY_INVOICE_TITLE,
  MODAL_PAY_INVOICE_WILL_PAY,
  NO_HAVE,
  ADD_PAYMENT_METHOD,
  SUPPORT,
  CONTACT_US,
  BILLING_AND_PAYMENT_CHANGE_YOUR_PLAN,
  BILLING_AND_PAYMENT_ACTIVATE_PLAN,
  BILLING_AND_PAYMENT_SELECT_PLAN,
  BILLING_AND_PAYMENT_NO_HAVE_PLAN,
  BILLING_AND_PAYMENT_PLAN_EXPIRED,
  BILLING_AND_PAYMENT_PLAN_NAME,
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST,
  BILLING_AND_PAYMENT_MODEL_USAGE_WHAT_BENEFITS,
  BILLING_AND_PAYMENT_MODEL_USAGE_SHOW_BENEFTIS,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT1,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT2,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT3,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT4,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT5,
  BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT6,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_NAME,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CHANGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_COLLECT_COST_COLLABORATION,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_ADD_PAYMENT_METHOD_TO_ACTIVATE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_EXPIRED,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE,
  MODAL_SELECT_PACKAGE_ARIA_LABEL,
  MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED,
  MODAL_SELECT_PACKAGE_UPLOAD_GROWTH,
  MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE,
  MODAL_SELECT_PACKAGE_PRICING_DISCOVER_BUZZLY,
  MODAL_SELECT_PACKAGE_UPLOAD_GROWTH_DESCRIPTION,
  MODAL_SELECT_PACKAGE_PRICING_HERO_DESCRIPTION,
  MODAL_SELECT_PACKAGE_VALUE_EXPRESSED,
  MODAL_SELECT_PACKAGE_PRICE,
  MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH,
  MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT,
  MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME,
  MODAL_SELECT_PACKAGE_COST_COLLABORATION,
  MODAL_SELECT_PACKAGE_BENEFIT_CATALOG,
  MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION,
  MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES,
  MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS,
  MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC,
  MODAL_SELECT_PACKAGE_BENEFIT_KAM,
  MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY,
  MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT,
  MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING,
  MODAL_SELECT_PACKAGE_BENEFIT_ALL_GROWTH,
  MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED,
  MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS,
  MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL,
  MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_WHAT_BENEFITS,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION,
  BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_DISCLAIMER,
  BASIC_PACKAGE_NAME,
  BASIC_PACKAGE_SUB_HEADER,
  BASIC_PACKAGE_DESCRIPTION,
  GROWTH_PACKAGE_NAME,
  GROWTH_PACKAGE_SUB_HEADER,
  GROWTH_PACKAGE_DESCRIPTION,
  ENTERPRISE_PACKAGE_NAME,
  ENTERPRISE_PACKAGE_SUB_HEADER,
  ENTERPRISE_PACKAGE_DESCRIPTION,
  COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION,
  MODAL_FREE_TRIAL_TITLE,
  MODAL_FREE_TRIAL_DESCRIPTION,
  MODAL_FREE_TRIAL_BUTTON,
  ACCEPT_TERMS_PAY,
  DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE,
  QUANTITY_TO_PAY,
  BLOCK_PAYMENT_FAILED_TITLE,
  BLOCK_TRIAL_END_TITLE,
  BLOCK_PLAN_BUTTON,
  MODAL_BUY_CREDITS_ARIA_LABEL,
  MODAL_BUY_CREDITS_TITLE,
  MODAL_BUY_CREDITS_DESCRIPTION,
  MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION,
  MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY,
  MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME,
  MODAL_BUY_CREDITS_ADDITIONAL_CREDITS,
  MODAL_BUY_CREDITS_LAST_TEXT_PAY,
  MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE,
  MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED,
  MODAL_BUY_CREDITS_NEGATIVE,
  MODAL_BUY_CREDITS_PURCHASED,
  MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION,
  MODAL_BUY_CREDITS_GIFT_CREDITS,
  BUTTON_UPLOAD_CATALOG,
  BUTTON_COPY_CATALOG,
  BUTTON_DOWNLOAD_FORMAT,
  BUTTON_EDIT,
  BUTTON_DUPLICATE,
  BUTTON_MOVE_OTHER_CATEGORY,
  MENU_TITLE,
  MENU_DESCRIPTION,
  MENU_EMPTY_STORES,
  MODAL_COPY_MENU_ARIA_LABEL,
  MODAL_COPY_MENU_TITLE,
  MODAL_COPY_MENU_SAME_STORE,
  MODAL_COPY_MENU_CONFIRM_ALERT_TITLE,
  MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION,
  MODAL_COPY_MENU_COPIED,
  SELECT_CATALOG,
  MODAL_UPLOAD_MENU_ARIA_LABEL,
  MODAL_UPLOAD_MENU_TITLE,
  MODAL_UPLOAD_MENU_DESCRIPTION,
  MODAL_UPLOAD_MENU_FILE_PLACEHOLDER,
  MODAL_UPLOAD_MENU_ERROR_FORMAT,
  MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT,
  MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE,
  MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION,
  MODAL_UPLOAD_MENU_UPLOADED,
  MENU_ADD_CATEGORY,
  MENU_CATEGORY_NAME_PLACEHOLDER,
  MENU_CATEGORY_REQUIRED,
  MENU_ADD_PRODUCT,
  MENU_PRODUCT_MIN_VALUE,
  MENU_CREATE_PRODUCT_TITLE,
  MENU_PRODUCT_NAME_PLACEHOLDER,
  MENU_PRODUCT_DESCRIPTION_PLACEHOLDER,
  MENU_PRODUCT_PRICE_PLACEHOLDER,
  MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER,
  MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION,
  MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE,
  MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION,
  MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER,
  MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER,
  BUTTON_COPY,
  FIELD_NUMBER_NEGATIVE,
  MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER,
  MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER,
  PRODUCT,
  PRODUCTS,
  MODAL_MOVE_PRODUCT_ARIA_LABEL,
  MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE,
  MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED,
  MODAL_MOVE_PRODUCT_BUTTON,
  MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL,
  MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE,
  MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER,
  MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED,
  MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED,
  MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED,
  MENU_ONBOARDING_TEST_CATEGORY,
  MENU_ONBOARDING_TEST_PRODUCT,
  MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION,
  MENU_ONBOARDING_FINISH_DESCRIPTION,
  MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION,
  MENU_CATEGORY_DUPLICATE,
  MENU_CATEGORY_DUPLICATE_DESCRIPTION,
  MENU_CATEGORY_CREATED,
  MENU_CATEGORY_SAVED,
  MENU_CATEGORY_DELETE_CONFIRMATION_TITLE,
  MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION,
  MENU_CATEGORY_DELETING,
  MENU_CATEGORY_DELETED,
  MENU_PRODUCT_DUPLICATE,
  MENU_PRODUCT_DUPLICATE_DESCRIPTION,
  MENU_PRODUCT_CREATED,
  MENU_PRODUCT_SAVED,
  MENU_PRODUCT_DELETE_CONFIRMATION_TITLE,
  MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION,
  MENU_PRODUCT_DELETING,
  MENU_PRODUCT_DELETED,
  MENU_PRODUCT_MOVE_SAME_CATEGORY,
  MENU_PRODUCT_MOVED,
  MENU_PRODUCT_CHANGE_STATUS,
  MENU_ACCOMPANIMENT_TYPE_DUPLICATE,
  MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION,
  MENU_ACCOMPANIMENT_TYPE_COPIED,
  MENU_ACCOMPANIMENT_DUPLICATED,
  MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION,
  FIELD_EMAIL_REQUIRED,
  FORGOT_PASSWORD,
  NO_HAVE_ACCOUNT,
  REGISTER_HERE,
  LOG_IN,
  ACCOUNT_ROLE_INVALID,
  ERROR_INTERNAL,
  AUTH_INVALID_PASSWORD,
  AUTH_WRONG_PASSWORD,
  AUTH_MANY_REQUESTS,
  AUTH_USER_NOT_FOUND,
  AUTH_EMAIL_ALREADY_EXISTS,
  STRIPE_CARD_DECLINED,
  STRIPE_EXPIRED_CARD,
  STRIPE_INCORRECT_CVC,
  STRIPE_PROCESSING_ERROR,
  STRIPE_INCORRECT_NUMBER,
  STRIPE_GENERIC_DECLINE,
  STRIPE_INSUFFICIENT_FUNDS,
  STRIPE_LOST_CARD,
  STRIPE_STOLEN_CARD,
  STRIPE_PROFILE_NOT_FOUND,
  STRIPE_INTENT_AUTH_FAILURE,
  NIT_VALID,
  BUSINESS_NAME_VALID,
  BRAND_NAME_VALID,
  ADDRESS_VALID,
  CATEGORY_VALID,
  SUBCATEGORIES_VALID,
  SHORT_DESCRIPTION_VALID,
  ORDERS_METHODS_VALID,
  LOGO_IMG_VALID,
  COVER_IMG_VALID,
  SCHEDULE_VALID,
  STORES_VALID,
  IS_PLAN_ACTIVE_VALID,
  MENUS_VALID,
  DEFAULT_PAYMENT_METHOD_VALID,
  HAS_DEBT_VALID,
  SUBSCRIPTION_PLAN_VALID,
  PROFILE_INFLUENCER_VALID,
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED,
  ALERT_ACTIVATE_SHOP_BY_OWNER_FIELDS_REQUIRED,
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON,
  REMINDER_ACTIVATE_SHOP,
  REGISTER_COUNTRY_ANSWER,
  REGISTER_COUNTRY_PLACEHOLDER,
  REGISTER_NAME_ANSWER,
  REGISTER_PHONE_ANSWER,
  REGISTER_EMAIL_ANSWER,
  REGISTER_BUSINESS_NAME_ANSWER,
  REGISTER_CATEGORY_ANSWER,
  REGISTER_SOCIAL_NETWORK_ANSWER,
  REGISTER_SOCIAL_NETWORK_ANSWER_DESCRIPTION,
  REGISTER_WEBSITE_ANSWER,
  REGISTER_WEBSITE_ANSWER_DESCRIPTION,
  REGISTER_MONTHLY_SALES_ANSWER,
  REGISTER_BUDGET_MARKETING_ANSWER,
  REGISTER_EMPLOYEES_ANSWER,
  REGISTER_PRODUCT_AVAILABILITY_ANSWER,
  REGISTER_RUT_ANSWER,
  REGISTER_INVIMA_ANSWER,
  REGISTER_ICA_ANSWER,
  REGISTER_YOU_MEET_ANSWER,
  REGISTER_WRITE_PLACEHOLDER,
  REGISTER_SELECT_PLACEHOLDER,
  BUTTON_REGISTER,
  BUTTON_NO_HAVE_DOCUMENT,
  BUTTON_UPLOAD_AFTER,
  REGISTER_COUNTRY_REQUIRED,
  REGISTER_NAME_REQUIRED,
  REGISTER_PHONE_REQUIRED,
  REGISTER_PHONE_NUMERIC_REQUIRED,
  REGISTER_BUSINESS_NAME_REQUIRED,
  REGISTER_CATEGORY_REQUIRED,
  REGISTER_SOCIAL_NETWORK_REQUIRED,
  REGISTER_WEB_SITE_REQUIRED,
  REGISTER_MONTHLY_SALES_REQUIRED,
  REGISTER_BUDGET_MARKETING_REQUIRED,
  REGISTER_EMPLOYEES_REQUIRED,
  REGISTER_PRODUCT_AVAILABILITY_REQUIRED,
  REGISTER_RUT_REQUIRED,
  REGISTER_INVIMA_REQUIRED,
  REGISTER_ICA_REQUIRED,
  REGISTER_YOU_MEET_REQUIRED,
  REGISTER_NO_HAVE_DOCUMENTS_ALERT_TITLE,
  REGISTER_NO_HAVE_DOCUMENTS_ALERT_DESCRIPTION,
  REGISTER_REGISTERED_ALERT_DESCRIPTION,
  RESTAURANTS,
  FOODS,
  TRAVEL,
  ENTERTAINMENT,
  TECHNOLOGY,
  FITNESS,
  FASHION,
  BEAUTY,
  PETS,
  HOME,
  EMPLOYEES_2_10,
  EMPLOYEES_10_20,
  EMPLOYEES_20_50,
  EMPLOYEES_50_100,
  EMPLOYEES_100_THAN,
  PRODUCT_AVAILABILITY_LOCAL,
  PRODUCT_AVAILABILITY_NATIONAL,
  PRODUCT_AVAILABILITY_INTERNATIONAL,
  INSTAGRAM,
  TIKTOK,
  LINKEDIN,
  GOOGLE,
  DARK_KITCHEN,
  OTHERS,
  PLEASE_SELECT_COUNTRY,
  BLOCK_PLAN_BUTTON_ACTIVATE_PLAN,
  BLOCK_PACKAGE_TITLE,
  BLOCK_PACKAGE_DESCRIPTION,
  BILLING_AND_PAYMENT_PLAN_TRIAL,
  BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION,
  BILLING_AND_PAYMENT_PLAN_DEACTIVATE,
  BUTTON_INVITED,
  MAIN_FUNCTIONALITY_STEP_1,
  MAIN_FUNCTIONALITY_STEP_2,
  MAIN_FUNCTIONALITY_STEP_3,
  MODAL_INVITATION_STEP_1,
  MODAL_INVITATION_STEP_2,
  MODAL_INVITATION_STEP_3,
  MODAL_INVITATION_STEP_4,
  MODAL_INVITATION_STEP_5,
  PUBLICATIONS_FUNCTIONALITY_STEP_1,
  PUBLICATIONS_FUNCTIONALITY_STEP_2,
  PUBLICATIONS_FUNCTIONALITY_STEP_3,
  PUBLICATIONS_FUNCTIONALITY_STEP_4,
  PUBLICATION_FUNCTIONALITY_STEP_1,
  PUBLICATION_FUNCTIONALITY_STEP_2,
  PUBLICATION_FUNCTIONALITY_STEP_3,
  REVIEW_FUNCTIONALITY_STEP_1,
  REVIEW_FUNCTIONALITY_STEP_2,
  REVIEW_FUNCTIONALITY_STEP_3,
  REVIEW_FUNCTIONALITY_STEP_4,
  REVIEW_FUNCTIONALITY_STEP_5,
  MENU_FUNCTIONALITY_STEP_1,
  MENU_FUNCTIONALITY_STEP_2,
  MENU_FUNCTIONALITY_STEP_3,
  MENU_FUNCTIONALITY_STEP_4,
  MENU_FUNCTIONALITY_STEP_5,
  MENU_FUNCTIONALITY_STEP_6,
  MENU_FUNCTIONALITY_STEP_7,
  MENU_FUNCTIONALITY_STEP_8,
  MENU_FUNCTIONALITY_STEP_9,
  MENU_FUNCTIONALITY_STEP_10,
  MENU_FUNCTIONALITY_STEP_11,
  MENU_FUNCTIONALITY_STEP_12,
  USERS_FUNCTIONALITY_STEP_1,
  USERS_FUNCTIONALITY_STEP_2,
  USERS_FUNCTIONALITY_STEP_3,
  USERS_FUNCTIONALITY_STEP_4,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_1,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_2,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_3,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_4,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_5,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_6,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_7,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_8,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_9,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_10,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_11,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_12,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_13,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_14,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_15,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_16,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_17,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_18,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_19,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_20,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_21,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_22,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_23,
  MAIN_FUNCTIONALITY_OPERATOR_STEP_24,
  AMBASSADORS_FUNCTIONALITY_STEP_1,
  AMBASSADORS_FUNCTIONALITY_STEP_2,
  AMBASSADORS_FUNCTIONALITY_STEP_3,
  AMBASSADORS_FUNCTIONALITY_STEP_4,
  AMBASSADORS_FUNCTIONALITY_STEP_5,
  AMBASSADORS_FUNCTIONALITY_STEP_6,
  AMBASSADORS_FUNCTIONALITY_STEP_7,
  AMBASSADORS_FUNCTIONALITY_STEP_8,
  AMBASSADORS_FUNCTIONALITY_STEP_9,
  AMBASSADORS_FUNCTIONALITY_STEP_10,
  AMBASSADORS_FUNCTIONALITY_STEP_11,
  DISCOVERY_FUNCTIONALITY_STEP_1,
  DISCOVERY_FUNCTIONALITY_STEP_2,
  DISCOVERY_FUNCTIONALITY_STEP_3,
  DISCOVERY_FUNCTIONALITY_STEP_4,
  DISCOVERY_FUNCTIONALITY_STEP_5,
  DISCOVERY_FUNCTIONALITY_STEP_6,
  DISCOVERY_FUNCTIONALITY_STEP_7,
  DISCOVERY_FUNCTIONALITY_STEP_8,
  DISCOVERY_FUNCTIONALITY_STEP_9,
  DISCOVERY_FUNCTIONALITY_STEP_10,
  DISCOVERY_FUNCTIONALITY_STEP_11,
  DISCOVERY_FUNCTIONALITY_STEP_12,
  DISCOVERY_FUNCTIONALITY_STEP_13,
  DISCOVERY_FUNCTIONALITY_STEP_14,
  DISCOVERY_FUNCTIONALITY_STEP_15,
  DISCOVERY_FUNCTIONALITY_STEP_16,
  DISCOVERY_FUNCTIONALITY_STEP_17,
  CAMPAIGNS_FUNCTIONALITY_STEP_1,
  CAMPAIGNS_FUNCTIONALITY_STEP_2,
  CAMPAIGNS_FUNCTIONALITY_STEP_3,
  CAMPAIGNS_FUNCTIONALITY_STEP_4,
  CAMPAIGNS_FUNCTIONALITY_STEP_5,
  CAMPAIGNS_FUNCTIONALITY_STEP_6,
  CAMPAIGNS_FUNCTIONALITY_STEP_7,
  CAMPAIGNS_FUNCTIONALITY_STEP_8,
  CAMPAIGNS_FUNCTIONALITY_STEP_9,
  CAMPAIGNS_FUNCTIONALITY_STEP_10,
  CAMPAIGNS_FUNCTIONALITY_STEP_11,
  CAMPAIGNS_FUNCTIONALITY_STEP_12,
  CAMPAIGNS_FUNCTIONALITY_STEP_13,
  CAMPAIGNS_FUNCTIONALITY_STEP_14,
  CAMPAIGNS_FUNCTIONALITY_STEP_15,
  CAMPAIGNS_FUNCTIONALITY_STEP_16,
  CAMPAIGNS_FUNCTIONALITY_STEP_17,
  STORES_FUNCTIONALITY_STEP_1,
  STORES_FUNCTIONALITY_STEP_2,
  STORES_FUNCTIONALITY_STEP_3,
  SHOP_ID_USER_ID_NOT_SENT,
  AMBASSADOR_DELETE,
  AMBASSADOR_DELETE_DESCRIPTION,
  AUTH_SENT_EMAIL_RESET_PASSWORD,
  EVENT_RECURRENT_REQUIRED,
  EVENT_HAS_FIELDS_REQUIRED,
  PROFILE_INFLUENCER_INCOMPLETE,
  MENU_HAS_ELEMENTS_CREATED,
  INVOICE_CREATED_FOR_RETRY_PAYMENT,
  BILLING_AND_PAYMENT_PLAN_CANCELLED,
  COMPANY_INFORMATION_SAVED,
  OPERATOR_USER_STORE,
  STORE_NO_HAS_MENU,
  STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT,
  STORE_COVERAGE_RADIUS_REQUIRED,
  ALERT_INACTIVE_SHOP_TITLE,
  ALERT_INACTIVE_SHOP_DESCRIPTION,
  ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION,
  BUTTON_YES_I_SURE,
  ERROR_COMPONENT_TITLE,
  ERROR_COMPONENT_DESCRIPTION,
  ERROR_COMPONENT_BUTTON_RELOAD_PAGE,
  ERROR_COMPONENT_CURRENT_ROUTE,
  LESS_500K,
  BETWEEN_500K_1M,
  BETWEEN_1M_2_5M,
  BETWEEN_2_5M_5M,
  MORE_5M,
  LESS_1M,
  BETWEEN_1M_5M,
  BETWEEN_5M_15M,
  BETWEEN_15M_30M,
  BETWEEN_30M_100M,
  MORE_100M,
  LESS_150,
  BETWEEN_150_250,
  BETWEEN_250_650,
  BETWEEN_650_1250,
  MORE_1250,
  LESS_250,
  BETWEEN_250_1250,
  BETWEEN_1250_3750,
  BETWEEN_3750_7500,
  BETWEEN_7500_25K,
  MORE_25K,
  DOCUMENT_CC,
  DOCUMENT_CE,
  DOCUMENT_NUIP,
  DOCUMENT_NIT,
  DOCUMENT_CI,
  DOCUMENT_PASSPORT,
  DOCUMENT_ID,
  DOCUMENT_RUC,
  MISSING_PARAMETERS,
  PASSWORD_INVALID,
  USER_ID_REQUIRED,
  SHOP_ID_REQUIRED,
  LAST_USER_NOT_DELETED,
  PLAN_TRIAL_USER_USED,
  ORDERS_NOT_FOUND_IN_INTERVAL,
  TYPE_REPORT_NOT_FOUND,
  SHOP_NOT_FOUND,
  PAYMENT_MODEL_INVALID,
  SHOP_NOT_HAS_SOCIAL_NETWORK,
  NO_VALID_PLATFORM_FOUND,
  GETTING_STATISTICS,
  SHOP_BUSINESS_NAME_NOT_FOUND,
  INFLUENCERS_SUGGESTED_NOT_FOUND,
  REFERENCE_PROFILES_REQUIRED,
  INFLUENCERS_SIMILAR_NOT_FOUND,
  DECRYPTING_PASSWORD,
  USER_ID_AND_PAYMENT_ID,
  MESSAGES_REQUIRED,
  PLAN_IS_NO_ACTIVE,
  USER_BANNED,
  GETTING_LOCALES,
  GETTING_PROFILE,
  PRODUCTS_NOT_SENT,
  INVITATION_NOT_FOUND,
  TOTAL_PRICE_EXCEEDED_VALUE_INVITATION,
  ACCOUNT_NOT_FOUND,
  COLLECTING_INFORMATION_RETRY_AFTER,
  ERROR_OCURRED,
  PLATFORM_NOT_SENT,
  VERIFICATION_CODE_NOT_FOUND,
  ORDER_NOT_FOUND,
  INVITE_ID_NO_SENT,
  INVITE_NOT_FOUND,
  INVITE_CLOUD_TASK_ID_NOT_FOUND,
  TYPE_OR_PATH_NOT_SENT,
  ID_OR_PATH_NOT_SENT,
  IDS_NOT_SENT,
  ANALYTIC_URL_NOT_SENT,
  ANALYTIC_URL_INVALID,
  EXTRACTING_ANALYTICS,
  CREDENTIALS_INVALID,
  COLLABORATION_PRICE_NOT_BE_NEGATIVE,
  CREDITS_NOT_BE_NEGATIVE,
  PACKAGES_PRICES_NOT_BE_NEGATIVE,
  ROL_REQUIRED,
  SHOP_ID_AND_STORE_ID_REQUIRED,
  PASSWORD_MUST_BE_6_CHARACTERS,
  ALL_USERS_NOT_DELETE,
  SHOP_APPLICANT_NOT_FOUND,
  USER_PASSWORD_NOT_FOUND,
  PLAN_NOT_FOUND,
  SUBSCRIPTION_NOT_CREATED,
  ID_SUBSCRIPTION_NOT_FOUND,
  SUBSCRIPTION_REACTIVATE,
  CUSTOMER_ID_NOT_FOUND,
  APPLICANT_NOT_FOUND,
  SHOP_COUNTRY_NOT_FOUND,
  FINAL_PRICE_NOT_FOUND,
  PAYMENT_FAILED,
  CREDITS_MUST_BE_GREATER_THAN_ZERO,
  AMBASSADOR_NOT_FOUND,
  CSRF_INVALID,
  INTEGRATION_INFORMATION_NOT_FOUND,
  GETTING_VIDEO,
  UPLOAD_URL_NOT_FOUND,
  UPLOADING_VIDEO,
  STORE_NOT_FOUND,
  NAME_REQUIRED,
  PRICE_REQUIRED,
  CURRENCY_REQUIRED,
  RECURRING_REQUIRED,
  CREATING_PRODUCT,
  CREATING_PRICE,
  CREATING_PAYMENT_LINK,
  GETTING_PAYMENT_LINK,
  INVITE_COMMENT_REQUIRED,
  INVITE_PERSONALIZED_URL_EXPLANATION,
  INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION,
  INVITE_FORM_TYPE_CONTENT,
  INVITE_FORM_TYPE_CONTENT_IMAGES,
  INVITE_FORM_TYPE_CONTENT_VIDEOS,
  INVITE_FORM_TYPE_CONTENT_REQUIRED,
  MODAL_REVIEW_SCORE_PLACEHOLDER,
  SCORE,
  INVITE_FORM_COMMENT_PLACEHOLDER_3,
  CHAT_EMAIL_NO_PERMITTED,
  CHAT_WRITE_MESSAGE_HERE,
  CHAT_WRITE_QUESTION_HERE,
  CHAT_DO_PAYMENT,
  CHAT_ERROR_SENT_MESSAGE,
  RESET_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD,
  RESET_PASSWORD_GET_TOKEN_FAILED,
  RESET_PASSWORD_SAVED,
  TOKEN_INVALID,
  TOKEN_EXPIRED,
  NOTIFICATIONS_CHECK_NOTIFICATION,
  NOTIFICATIONS_NO_MORE,
  NOTIFICATIONS_EMPTY,
  NOTIFICATIONS_MARK_READS,
  NOTIFICATIONS_TITLE,
  ONE_SECOND_AGO,
  DATE_DISTANCE,
  CAMPAIGN_FINISH_ONBOARDING,
  CHAT_FIRST_MESSAGE_BOT,
  OWN_DELIVERY,
  CHAT_NO_MORE,
  PUBLICATION_STATUS_PENDING_CONTENT,
  PUBLICATION_STATUS_PENDING_APPROVAL,
  BUTTON_SEND_REVISION,
  BUTTON_APPROVE_CONTENT,
  REVISION_COUNT,
  ONE,
  TWO,
  THREE,
  DATE_HOUR,
  ORDER_CANCELLED_CONFIRMATION,
  ORDER_CANCELLED_CONFIRMATION_BUTTON,
  COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER,
  IG_ACCOUNT_NOT_FOUND,
  IG_DATA_NOT_FOUND,
  BUTTON_UPLOAD_INSTAGRAM,
  BUTTON_CONNECT_INSTAGRAM,
  PLATFORMS_CONNECTED_INSTAGRAM_FAILED,
  MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TITLE,
  MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION,
  MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL,
  MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER,
  PLATFORMS_TOKEN_EXPIRED,
  INTEGRATIONS_TERMS_TITLE,
  INTEGRATIONS_TERMS_DESCRIPTION,
  INTEGRATIONS_TERMS_CONTENT,
  MODAL_FUNCTIONALITY_CAMPAIGNS,
  MODAL_FUNCTIONALITY_AMBASSADOR,
  MODAL_FUNCTIONALITY_DISCOVERY,
  LESS_1000,
  BETWEEN_1000_5000,
  BETWEEN_5000_15K,
  BETWEEN_15K_30K,
  BETWEEN_30K_60K,
  MORE_60K,
  BETWEEN_1000_2000,
  BETWEEN_2000_5000,
  BETWEEN_5000_10K,
  MORE_10K,
  DOCUMENT_EIN,
  MENU_SERVICES,
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE,
  RESTAURANTS_SUBCATEGORY_FOOD,
  RESTAURANTS_SUBCATEGORY_DRINKS,
  RESTAURANTS_SUBCATEGORY_DESSERTS,
  RESTAURANTS_SUBCATEGORY_COFFEE,
  RESTAURANTS_SUBCATEGORY_HOT_DRINKS,
  RESTAURANTS_SUBCATEGORY_COLD_DRINKS,
  RESTAURANTS_SUBCATEGORY_SOFT_DRINKS,
  RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS,
  RESTAURANTS_SUBCATEGORY_SUSHI,
  RESTAURANTS_SUBCATEGORY_ASIAN,
  RESTAURANTS_SUBCATEGORY_JAPANESE,
  RESTAURANTS_SUBCATEGORY_CHINESE,
  RESTAURANTS_SUBCATEGORY_ITALIAN,
  RESTAURANTS_SUBCATEGORY_MEXICAN,
  RESTAURANTS_SUBCATEGORY_HEALTHY,
  RESTAURANTS_SUBCATEGORY_VEGAN,
  RESTAURANTS_SUBCATEGORY_VEGETARIAN,
  FOODS_SUBCATEGORY_SEASONINGS,
  FOODS_SUBCATEGORY_FOOD,
  FOODS_SUBCATEGORY_GRANOLA,
  FOODS_SUBCATEGORY_DRIED_FRUITS,
  FOODS_SUBCATEGORY_HEALTHY,
  TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM,
  TRAVEL_SUBCATEGORY_RURAL_TOURISM,
  TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM,
  TRAVEL_SUBCATEGORY_NATURE_TOURISM,
  TRAVEL_SUBCATEGORY_CULTURAL_TOURISM,
  TRAVEL_SUBCATEGORY_HEALTH_TOURISM,
  ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT,
  TECHNOLOGY_SUBCATEGORY_PHONES,
  TECHNOLOGY_SUBCATEGORY_COMPUTERS,
  TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES,
  TECHNOLOGY_SUBCATEGORY_ACCESSORIES,
  TECHNOLOGY_SUBCATEGORY_PERIPHERALS,
  TECHNOLOGY_SUBCATEGORY_ELECTRONICS,
  FITNESS_SUBCATEGORY_SPORTSWEAR,
  FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES,
  FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS,
  FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT,
  FASHION_SUBCATEGORY_HAUTE_COUTURE,
  FASHION_SUBCATEGORY_HIPSTER,
  FASHION_SUBCATEGORY_CASUAL,
  FASHION_SUBCATEGORY_CLASSIC,
  FASHION_SUBCATEGORY_ROMANTIC,
  FASHION_SUBCATEGORY_SPORTSWEAR,
  FASHION_SUBCATEGORY_PREPPY,
  FASHION_SUBCATEGORY_GRUNGE,
  FASHION_SUBCATEGORY_VINTAGE,
  BEAUTY_SUBCATEGORY_COSMETICS,
  BEAUTY_SUBCATEGORY_EYE_CARE,
  BEAUTY_SUBCATEGORY_FEMININE_HYGIENE,
  BEAUTY_SUBCATEGORY_FIRST_AID,
  BEAUTY_SUBCATEGORY_HAIR_CARE,
  BEAUTY_SUBCATEGORY_MEDICAL_DEVICES,
  BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT,
  BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS,
  BEAUTY_SUBCATEGORY_MEDICATIONS,
  BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE,
  BEAUTY_SUBCATEGORY_SCALES,
  BEAUTY_SUBCATEGORY_SKIN_CARE,
  BEAUTY_SUBCATEGORY_VITAMINS,
  BEAUTY_SUBCATEGORY_FACIAL_TISSUES,
  PETS_SUBCATEGORY_FOOD,
  PETS_SUBCATEGORY_COSMETICS,
  PETS_SUBCATEGORY_ACCESSORIES,
  PETS_SUBCATEGORY_CLOTHING,
  PETS_SUBCATEGORY_MEDICATIONS,
  HOME_SUBCATEGORY_TEXTILES_CARPETS,
  HOME_SUBCATEGORY_ART_PRINTS,
  HOME_SUBCATEGORY_STORAGE_ORGANIZATION,
  HOME_SUBCATEGORY_PLANT_DECOR,
  HOME_SUBCATEGORY_CURTAINS_BLINDS,
  UPLOAD_NEW_CONTENT,
  MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL,
  MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL,
  BUTTON_RELOAD_PAGE,
  NEW_VERSION_TITLE,
  NEW_VERSION_DESCRIPTION,
  DELIVERY_INTERN_ON_THE_WAY,
  FIELD_EMAIL_REGISTERED,
  INVITATIONS,
  MODAL_BLOCK_LAST_INVITE_TITLE,
  MODAL_BLOCK_LAST_INVITE_DESCRIPTION,
  MODAL_BLOCK_LAST_INVITE_COUNT,
  MODAL_DISSATISFACTION_COLLABORATION_TITLE,
  MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION,
  MODAL_DISSATISFACTION_COLLABORATION_BUTTON,
  MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT,
  REVIEW_NOT_FOUND,
  REVIEW_IS_NOT_ONE_START,
  DISSATISFACTION_CREDIT_ALREADY_EXISTS,
  MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED,
  BUTTON_REPLACE_IMAGE,
  ADD_URL_PUBLICATION,
  VALUE_EXPRESSED_IN,
  ANY,
  GENDER,
  MESSAGE_AFTER_INVITATION,
  FIELD_SEARCH_CHATS_MORE_3_CHARACTERS,
  INFLUENCER_NO_REDEEM_CODE,
  ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION,
  MODAL_SUBSCRIPTION_PRO_ARIA_LABEL,
  MODAL_SUBSCRIPTION_PRO_TITLE_HERO,
  MODAL_SUBSCRIPTION_PRO_DESCRIPTION_HERO,
  MODAL_SUBSCRIPTION_PRO_TITLE,
  MODAL_SUBSCRIPTION_PRO_SUB_HEADER,
  MODAL_SUBSCRIPTION_PRO_BENEFIT_1,
  MODAL_SUBSCRIPTION_PRO_BUY_BUTTON,
  PAYMENT_REASON_SUBSCRIPTION_PRO,
  NO_ADDED,
  BUTTON_SEE_PUBLICATION_ON,
  EDIT_AMBASSADOR,
  SEND_REVISION_INFLUENCER_COMMENT,
  REVISIONS,
  CURRENT_CONTENT,
  CURRENT_CONTENT_EMPTY,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER,
  AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_ENGAGEMENT_RANGE,
  PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED,
  VIEW_SUGGESTED_CREATORS,
  AUTOMATIC_INVITATIONS_VALID,
  CREATORS_SUGGESTED_BAD_CONFIGURATION_AUTOMATIC_INVITATIONS,
  GO_TO_AUTOMATIC_INVITATIONS,
  MALE,
  FEMALE,
  COMMENT,
  NO_SEND_CONTENT_FEEDBACK,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_PERCENTAGE_PLACEHOLDER,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_CODE_PLACEHOLDER,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_TITLE,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_DESCRIPTION,
  FIELD_NUMBER_0_OR_NEGATIVE,
  FIELD_NUMBER_100_GREATER,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_1,
  COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_2,
  VALUE_GENERAL_CODE_REDEEMED,
  VALUE_WITH_PERCENTAGE,
  FIELD_NUMBER_LESS_THAN,
  FIELD_NUMBER_GREATER_THAN,
  OWNER_USERS_TITLE,
  OWNER_USERS_SEARCH_BAR,
  OWNER_USERS_TABLE_ARIA_LABEL,
  OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE,
  OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION,
  OWNER_USERS_DELETE_USER_CONFIRM,
  OWNER_USERS_DELETE_USER_DELETING,
  OWNER_USERS_DELETE_USER_DELETED,
  OWNER_USERS_FAILED_DELETE_USER,
  COLUMN_NAME_SHOP_ID,
  COLUMN_NAME_SHOP,
  BUTTON_ADD_USER,
  SUPER_ADMIN,
  OWNER_USERS_CREATED_USER,
  OWNER_USERS_SAVED_USER,
  OWNER_USERS_MODAL_ARIA_LABEL,
  OWNER_USERS_MODAL_TITLE,
  SELECT_BUSINESS_PLACEHOLDER,
  NAME_BUSINESS_PLACEHOLDER,
  SELECT_BRANDS_PLACEHOLDER,
  MAIN_BRAND_PLACEHOLDER,
  SELECT_BRAND_PLACEHOLDER,
  SELECT_STORE_PLACEHOLDER,
  NAME_AND_LAST_NAME_PLACEHOLDER,
  FIELD_PASSWORD_CHANGE_MIN_LENGTH,
  OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE,
  OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES,
  STORES_STORE_SPECIAL_STORE_EXISTS,
  ORDER_TOTAL_VALUE_DISCOUNT,
  UPDATING_ANALYTICS,
  AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION,
  GO_TO_ACCOUNT,
  ORDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_DESCRIPTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED,
  CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION,
  CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION,
  PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE,
  SHOPS_APPLICANTS_TITLE,
  SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER,
  SHOPS_APPLICANTS_TABLE_ARIA_LABEL,
  SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_TITLE,
  SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_DESCRIPTION,
  SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_BUTTON,
  SHOPS_APPLICANTS_REJECT_SHOP_REJECTING,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_ARIA_LABEL,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_TITLE,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_EMPTY,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_RUT,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_INVIMA,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_ICA,
  SHOPS_APPLICANTS_MODAL_DOCUMENTS_OPEN,
  SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_ARIA_LABEL,
  SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_TITLE,
  SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_CREATE_BRAND,
  SELECT_TYPE_PLAN_PLACEHOLDER,
  COST_COLLABORATION_PLACEHOLDER,
  CREDITS_PLACEHOLDER,
  TRIAL_DAYS_PLACEHOLDER,
  TYPE_PACKAGE_PLACEHOLDER,
  PRICE_BASIC_PLACEHOLDER,
  COST_COLLABORATION_BASIC_PLACEHOLDER,
  PRICE_GROWTH_PLACEHOLDER,
  COST_COLLABORATION_GROWTH_PLACEHOLDER,
  PLAN_PAY_PER_USE,
  PLAN_PACKAGE,
  PLAN_REGULAR,
  PLAN_ENTERPRISE,
  SHOPS_ACCEPTED_TITLE,
  SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED,
  SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED,
  SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR,
  SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE,
  SHOPS_ACCEPTED_TABLE_ARIA_LABEL,
  SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL,
  COLUMN_NAME_BRAND,
  COLUMN_NAME_CATEGORY,
  COLUMN_NAME_IS_LOGGED,
  COLUMN_NAME_IS_ACTIVE,
  COLUMN_NAME_PACKAGE,
  COLUMN_NAME_EMAIL,
  COLUMN_NAME_NAME,
  COLUMN_NAME_PHONE,
  COLUMN_NAME_STAFF,
  COLUMN_NAME_ACCEPTED,
  COLUMN_NAME_MONTHLY_SALES,
  COLUMN_NAME_BUDGET_MARKETING,
  COLUMN_NAME_EMPLOYEES,
  COLUMN_NAME_PRODUCT_AVAILABILITY,
  COLUMN_NAME_SOCIAL_NETWORK,
  COLUMN_NAME_WEB_SITE,
  COLUMN_NAME_YOU_MEET,
  COLUMN_NAME_DATE,
  COMMERCIAL_PLACEHOLDER,
  ADD_NEW_OPTION,
  MODAL_COMMERCIAL_TITLE_CREATE,
  MODAL_COMMERCIAL_TITLE_EDIT,
  MODAL_COMMERCIAL_CREATE_COMMERCIAL,
  MODAL_COMMERCIAL_ALREADY_EXIST,
  BUTTON_ADD_SHOP,
  SHOPS_TITLE,
  SHOPS_SEARCH_BAR_PLACEHOLDER,
  SHOPS_TABLE_ARIA_LABEL,
  COLUMN_NAME_PLAN,
  COLUMN_NAME_PAYMENT_METHOD,
  COLUMN_NAME_COST_COLLABORATION,
  COLUMN_NAME_COUNTRY,
  COLUMN_NAME_CREDITS,
  PLAN_PAY_PER_USE_NAME,
  PLAN_PACKAGE_NAME,
  BUSINESS_NAME_PLACEHOLDER,
  SHOPS_MODAL_CREATE_SHOP_TITLE,
  SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL,
  SHOPS_SHOP_CREATE,
  USERS,
  FIELD_CREDITS_NOT_BE_NEGATIVE,
  FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE,
  FIELD_TRIAL_MONTHS_NOT_BE_NEGATIVE,
  FIELD_PLAN_PRICES_NOT_BE_NEGATIVE,
  FIELD_STAFF_IS_REQUIRED,
  SHOP_DETAIL_TITLE,
  SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK,
  SHOP_DETAIL_HAS_BILLING_CYCLE,
  STORES,
  PLAN,
  AUTOMATIC_RENOVATION,
  DEACTIVATED,
  ACTIVE,
  MODAL_CREATE_PAYMENT_LINK_TITLE,
  MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL,
  MODAL_CREATE_PAYMENT_LINK_LINKS,
  MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY,
  MODAL_CREATE_PAYMENT_LINK_NAME,
  DESCRIPTION_OPTIONAL_PLACEHOLDER,
  PRICE_PLACEHOLDER,
  CURRENCY_PLACEHOLDER,
  IS_RECURRENCE_PLACEHOLDER,
  RECURRENCE_PLACEHOLDER,
  RECURRENT_OPTION,
  RECURRING_DAYS,
  RECURRING_WEEKS,
  RECURRING_MONTHS,
  RECURRING_YEARS,
  FIELD_BOTH_DATES_REQUIRED,
  FIELD_START_DATE_GREATER_THAN_END_DATE,
  SOMETHING_ERROR_GENERATING_STATISTICS,
  SHOP_DETAIL_REPORT_SELECT_DATES,
  SHOP_DETAIL_REPORT_TITLE,
  BUTTON_DOWNLOAD_REPORT,
  REPORT_ORDERS,
  ORDERS,
  DELIVERIES,
  IN_STORE,
  ORDERS_PENDING,
  ORDERS_PENDING_ANALYTICS,
  ORDERS_FEEDBACK,
  ORDERS_SENT,
  ORDERS_REVIEWED,
  CREATOR,
  VALUE,
  WAS_GENERATED,
  MODAL_BLOCK_LAST_INVITE_BUTTON,
  META_INTEGRATION_UNLINKED_PAGES_ERROR,
  PROFILE_INFLUENCER_INFORMATION_AUDIENCE_CITIES_PLACEHOLDER,
  EDIT,
  MENU_MEDIA,
  CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA,
  CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION,
  CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER,
  THIS_CREATOR_IS_FAVORITE,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  REDEEM_CODE_TABLE_COLUMN_TERMS,
  BUTTON_GENERATE_COMMENT,
  FAVORITES_CATEGORY,
  EMAIL,
  INVITE_FORM_CODE_REQUIRED,
  INVITE_FORM_CODE_MIN_LENGTH,
  INVITE_FORM_ORDER_FROM_PLACE_HOLDER,
  INVITE_FORM_ORDER_FROM_HELPER_TEXT,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_LABEL,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_TOP_FIVE_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION,
  BUTTON_GENERATE_CONTENT,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_EMPTY_CONTENT,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_GENERATING_CONTENT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TONES_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_TEXT_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_BACKGROUND_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_CONTENTS_REQUIRED,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULE_BUTTON,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULED_CONTENT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION,
  BUTTON_UPLOAD_LOGO,
  BUTTON_REPLACE_LOGO,
  BUTTON_UPLOAD_BACKGROUND,
  BUTTON_REPLACE_BACKGROUND,
  BUTTON_REMOVE_BACKGROUND,
  BUTTON_REGENERATE_CONTENT,
  SUB_MENU_SOCIAL_FEED,
  CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER,
  CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION,
  ORDERS_REMEMBER_MARK_AS_DELIVERED,
  BUTTON_CONTINUOUS,
  CREATE_CAMPAIGN_GOAL_QUESTION,
  CREATE_CAMPAIGN_GOAL_PLACEHOLDER,
  CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION,
  CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION,
  CREATE_CAMPAIGN_GOAL_LEADS_OPTION,
  CREATE_CAMPAIGN_GOAL_SALES_OPTION,
  CREATE_CAMPAIGN_GOAL_REQUIRED,
  CAMPAIGN_GOAL_PLACEHOLDER,
  MODAL_SELECT_PACKAGE_BENEFIT_COLLABORATE_WITH_CREATOR,
  MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS,
  MODAL_SELECT_PACKAGE_BENEFIT_DOWNLOAD_CONTENT,
  MODAL_SELECT_PACKAGE_BENEFIT_ANALYZE_METRICS,
  CAMPAIGN_REPOST_PLACEHOLDER_ITEM,
  PAYMENT_METHOD_NOT_FOUND,
  HAS_PENDING_PAYMENTS,
  CREATE_CAMPAIGN_TRAFFIC_LINK_VALID,
  ORDER_ID,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_LANGUAGE_LABEL,
  ENGLISH,
  SPANISH,
  CAMPAIGN_FINISHED_TIME,
  DATE_SHORT_LOCALIZED,
  DATE_LONG_LOCALIZED,
  VIEW,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL,
  FIELD_MAX_LENGTH,
  CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING,
  BUTTON_GENERATE_OTHER,
  MODAL_INVOICE_SUBSCRIPTION,
  MODAL_INVOICE_APPLICANT,
  MODAL_INVOICE_AMBASSADOR,
  MODAL_INVOICE_FEE,
  MODAL_INVOICE_IVA,
  MODAL_INVOICE_COLLABORATION,
  MODAL_INVOICE_CREDITS,
  MODAL_INVOICE_PLAN_ENTERPRISE_WITH_CREDITS,
  BUTTON_SUBSCRIBE_NOW,
  PRO_PACKAGE_NAME,
  FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE,
  ACCEPTED,
  PENDING,
  ARE_YOU_SURE,
  CHANGE,
  SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION,
  BUTTON_PASS_TO_SUBSCRIPTION_BASIC,
  REQUESTS_INFLUENCERS_ACCOUNT_TITLE,
  COLUMN_NAME_CREATOR_NAME,
  COLUMN_NAME_CITY,
  COLUMN_NAME_AUDIENCE,
  COLUMN_NAME_ENGAGEMENT,
  COLUMN_NAME_CATEGORIES,
  COLUMN_NAME_SCORE,
  COLUMN_NAME_ACTIVITY,
  COLUMN_NAME_CREATOR_PLATFORM,
  COLUMN_NAME_VALUE_INVITATION,
  COLUMN_NAME_ACTIONS,
  COLUMN_NAME_CODE_INVITATION,
  COLUMN_NAME_INVITATION,
  COLUMN_NAME_USE_INVITATION,
  COLUMN_NAME_EXPIRATION_INVITATION,
  COLUMN_NAME_TYPE_INVITATION,
  COLUMN_NAME_REJECTED_REASON_INVITATION,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_REDEEMED_DATE,
  COLUMN_NAME_TERMS,
  COLUMN_NAME_TYPE,
  COLUMN_NAME_ADDRESS,
  COLUMN_NAME_STORE,
  COLUMN_NAME_ROLE,
  COLUMN_NAME_PAID_DATE,
  COLUMN_NAME_DUE_DATE,
  COLUMN_NAME_REASON,
  COLUMN_NAME_VALUE,
  COLUMN_NAME_ID,
  COLUMN_NAME_CREATOR,
  COLUMN_NAME_CREATED_DATE,
  COLUMN_NAME_ACCEPTED_DATE,
  COLUMN_NAME_DETAILS,
  COLUMN_NAME_DELIVERY,
  COLUMN_NAME_CODE_VERIFICATION,
  COLUMN_NAME_ACCOUNT_NAME,
  COLUMN_NAME_IS_HIDDEN,
  COLUMN_NAME_COMMENT,
  BUTTON_EXTEND,
  SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE,
  SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION,
  SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE,
  SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION,
  SHOP_DETAIL_REPORT_INVITATION_EXTENDED,
  SHOP_DETAIL_REPORT_EXTENDING_INVITE,
  REPORT_REQUESTS,
  REQUESTS_ACCEPTED,
  REQUESTS_REJECTED,
  REQUESTS_ACTIVE,
  REPORT_INVITATIONS,
  CODE,
  IS_AUTOMATIC,
  REPORT_INVITATIONS_INACTIVE,
  REDEEMED,
  EXPIRED,
  REDEEMED_SINGULAR,
  EXPIRED_SINGULAR,
  DELIVERIES_OWNER_TITLE,
  DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER,
  DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER,
  DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER,
  DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION,
  REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER,
  REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS,
  REQUEST_INFLUENCERS_CODE_SENT_STATUS,
  REQUEST_INFLUENCERS_PENDING_SENT_CODE,
  EMPTY_RESULTS_SHOW_ON_THIS_PAGE,
  CONTENT_TITLE,
  REPORTS_TITLE,
  REPORTS_DESCRIPTION,
  REPORTS_ERROR_GENERATING,
  REPORTS_INVITATIONS_ACTIVE,
  REPORTS_INVITATIONS_INACTIVE,
  REPORTS_REQUESTS_ACTIVE,
  REPORTS_REQUESTS_INACTIVE,
  REPORTS_TYPE_USERS,
  REPORTS_NUMBER_USERS,
  REPORTS_TYPE_ORDER,
  REPORTS_NUMBER_ORDER,
  REJECTED,
  ROLE,
  REPORTS_MODAL_DESCRIPTION,
  REPORTS_MODAL_ONLY_BRANDS_ACTIVE,
  REPORTS_MODAL_TYPE_REPORT_LABEL,
  REPORTS_MODAL_TYPE_REPORT_BRANDS,
  REPORTS_MODAL_TYPE_REPORT_CREATORS,
  REPORTS_MODAL_TYPE_REPORT_ORDERS,
  REPORTS_MODAL_TYPE_REPORT_INVITATIONS,
  BUTTON_ARCHIVE,
  BUTTON_UNARCHIVE,
  CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE,
  CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE,
  CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_EXTENDING_INVITATION,
  CREATORS_OWNER_INVITATION_EXTENDED,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON,
  CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING,
  TRANSACTION_CONCEPT_LABEL,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_DEBIT,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_PENDING_RELEASE,
  TRANSACTION_STATUS_IN_PROCESS_RELEASE,
  TRANSACTION_STATUS_RELEASED,
  TRANSACTION_STATUS_PENDING_SENT,
  TRANSACTION_IN_TRANSIT,
  TRANSACTION_STATUS_PAID,
  UNKNOWN,
  BUTTON_CREATE_TRANSACTION,
  CREATORS_OWNER_TITLE,
  CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER,
  CREATORS_OWNER_CREATOR_NOT_FOUND,
  CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE,
  CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION,
  CREATORS_OWNER_DELETING_CREATOR,
  CREATORS_OWNER_DELETED_CREATOR,
  CREATORS_OWNER_REPORT_EMPTY_INVITATIONS,
  CREATORS_OWNER_REPORT_DATE_REDEMPTION,
  CREATORS_OWNER_REPORT_DELIVERIES,
  CREATORS_OWNER_REPORT_DELIVERIES_EMPTY,
  CREATORS_OWNER_REPORT_WAS_ACCEPTED,
  CREATORS_OWNER_REPORT_IS_DELIVERY,
  CREATORS_OWNER_REPORT_ORDERS_EMPTY,
  CREATORS_OWNER_REPORT_ORDERS_ARCHIVED,
  CREATORS_OWNER_REPORT_ORDERS_ARCHIVED_EMPTY,
  CREATORS_OWNER_REPORT_WAS_ARCHIVED,
  CREATORS_OWNER_REPORT_TRANSACTIONS,
  CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY,
  TRANSACTION_WAS_PAID,
  BUTTON_UPDATE_STATISTICS,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED,
  DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED,
  MODAL_CREATE_TRANSACTION,
  MODAL_CREATE_TRANSACTION_AMOUNT_LABEL,
  MODAL_CREATE_TRANSACTION_CONCEPT_LABEL,
  MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR,
  MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT,
  MODAL_CREATE_TRANSACTION_CONCEPT_ORDER,
  MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL,
  MODAL_CREATE_TRANSACTION_CONCEPT_ID,
  FIELD_END_DATE_GREATER_NOW,
  MODAL_EDIT_CREATOR_OWNER_TITLE,
  MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR,
  MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES,
  MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL,
  MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL,
  MODAL_DISABLED_CREATOR_OWNER_TITLE,
  BUTTON_SEND_PAYMENT,
  BUTTON_INVITE_CAMPAIGN,
  BUTTON_INVITE_EXCHANGE,
  BUTTON_VIEW_REPORT,
  REVIEWS,
  REVIEW,
  SHOW_REVIEWS,
  EMPTY_REVIEWS,
  DESCRIPTION_INVITE_EXCHANGE,
  DESCRIPTION_INVITE_CAMPAIGN,
  DESCRIPTION_SEND_MESSAGE,
  DESCRIPTION_SEND_PAYMENT,
  SELECT_CAMPAIGN_PLACEHOLDER,
  CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE,
  MODAL_PAY_CREATOR_AMOUNT_LABEL,
  FIELD_MIN_AMOUNT_PAY,
  PAYMENT_REASON_INFLUENCER,
  MODAL_INVOICE_INFLUENCER,
  PAY_INFLUENCER,
  REGISTER_PASSWORD_ANSWER,
  FIELD_PASSWORD_INSECURE,
  BLOCK_TRIAL_ACTIVE_TITLE,
  BLOCK_TRIAL_ACTIVE_DESCRIPTION,
  BLOCK_TRIAL_ACTIVE_BUTTON,
  MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION,
  BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE,
  ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION,
  FIELD_BOTH_PASSWORD_REQUIRED,
  MODAL_FREE_TRIAL_CAPTION,
  BUTTON_ADD_AS_REFERENCE,
  BUTTON_ADDED,
  BUTTON_CREATE_CAMPAIGN2,
  BUTTON_CREATOR_CHAT,
  BUTTON_SEND_FILE,
  COLUMN_NAME_ACTION,
  BUTTON_SEE_MORE,
  BUTTON_SEND,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_LABEL,
  RIGHT,
  LEFT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_HAS_TEXT_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_FONT_FAMILY_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_TEXT_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_LOGO_LABEL,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_HAS_TEXTS_LABEL,
  LOGO,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_PLACEHOLDER,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WEBSITE_URL_LABEL,
  FIELD_FILE_VALID_REQUIRED,
  FIELD_FILE_MAX_SIZE,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_TITLE,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION,
  MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TEXT_LABEL,
  BUTTON_YOU_HAVE_DOUBTS,
  CANCEL_ANYTIME,
  NO_IMAGES_FOUND_ON_PAGE,
  FIELD_URL_HTTPS_VALID,
  ADD_BRAND,
  MODAL_ADD_BRAND_SUPER_ADMIN_TITLE,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION,
  MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_EMPTY,
  DATE_LONG_TEXT,
  DATE,
  BUTTON_SAVE_CONTENT,
  BUTTON_REGENERATE_TEXT,
  DATE_LONG_TEXT_WITH_HOUR,
  DATE_LONG_TEXT_2,
  MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALREADY_CONTENT_ON_THIS_DATE,
  HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP,
  NO_CREATE_DISCOUNT_SHOPIFY,
  NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY,
  NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY,
  NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY,
  FIELD_ACCESS_TOKEN_SHOPIFY_VALID,
  STORES_STORE_SHOPIFY_DUPLICATE,
  BUTTON_SEND_BROADCAST,
  MODAL_BROADCAST_CONFIRM_TITLE,
  MODAL_BROADCAST_CONFIRM_DESCRIPTION,
  NAME_BUSINESS_BOT,
  SERVICE_FEE,
  TOTAL_PAYMENT,
  MODAL_USER_TITLE_EDIT,
  MODAL_USER_DESCRIPTION_EDIT,
  MODAL_USER_DESCRIPTION,
  MODAL_CONTACT_TITLE_EDIT,
  MODAL_CONTACT_DESCRIPTION,
  MODAL_CONTACT_DESCRIPTION_EDIT,
  MODAL_STORE_TITLE,
  MODAL_STORE_TITLE_EDIT,
  MODAL_STORE_DESCRIPTION,
  MODAL_STORE_DESCRIPTION_EDIT,
  MODAL_AMBASSADOR_DESCRIPTION,
  MODAL_BROADCAST_DESCRIPTION,
  MODAL_PAY_AMBASSADORS_DESCRIPTION,
  MODAL_COPY_MENU_DESCRIPTION,
  DESIGNER,
  SHOPS_MODAL_CREATE_SHOP_DESCRIPTION,
  MODAL_CREATE_PAYMENT_LINK_DESCRIPTION,
  OWNER_USERS_MODAL_DESCRIPTION_EDIT,
  OWNER_USERS_MODAL_DESCRIPTION,
  OWNER_USERS_MODAL_TITLE_EDIT,
  BUTTON_ADD_REFERENCE_PROFILE,
  AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND,
  OPTION_UGC_FORMAT_GRWM,
  OPTION_UGC_FORMAT_GRWM_EXPLANATION,
  OPTION_UGC_FORMAT_OODT,
  OPTION_UGC_FORMAT_OODT_EXPLANATION,
  OPTION_UGC_FORMAT_HAULS,
  OPTION_UGC_FORMAT_HAULS_EXPLANATION,
  OPTION_UGC_FORMAT_DIYS,
  OPTION_UGC_FORMAT_DIYS_EXPLANATION,
  OPTION_UGC_FORMAT_UNBOXING,
  OPTION_UGC_FORMAT_UNBOXING_EXPLANATION,
  OPTION_UGC_FORMAT_CHALLENGES,
  OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION,
  OPTION_UGC_FORMAT_REVIEWS,
  OPTION_UGC_FORMAT_REVIEWS_EXPLANATION,
  OPTION_UGC_FORMAT_VLOGS,
  OPTION_UGC_FORMAT_VLOGS_EXPLANATION,
  OPTION_UGC_FORMAT_QA,
  OPTION_UGC_FORMAT_QA_EXPLANATION,
  OPTION_UGC_FORMAT_STORYTIME,
  OPTION_UGC_FORMAT_STORYTIME_EXPLANATION,
  OPTION_UGC_FORMAT_ROOM_TOURS,
  OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION,
  OPTION_UGC_FORMAT_WHATS_IN_MY_BAG,
  OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION,
  OPTION_UGC_FORMAT_COOKING,
  OPTION_UGC_FORMAT_COOKING_EXPLANATION,
  OPTION_UGC_FORMAT_FITNESS_ROUTINES,
  OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION,
  OPTION_UGC_FORMAT_ASMR,
  OPTION_UGC_FORMAT_ASMR_EXPLANATION,
  OPTION_UGC_FORMAT_PLAN_WITH_ME,
  OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION,
  OPTION_UGC_FORMAT_MUKBANG,
  OPTION_UGC_FORMAT_MUKBANG_EXPLANATION,
  OPTION_UGC_FORMAT_DAY_IN_THE_LIFE,
  OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION,
  OPTION_UGC_FORMAT_BEHIND_THE_SCENES,
  OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION,
  OPTION_UGC_FORMAT_TRAVEL_DIARIES,
  OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION,
  SELECT_UGC_CONTENT_FORMAT,
  CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION,
  CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER,
  SUB_MENU_CONTENT_APPLICATION,
  CONTENT_APPLICATION_TITLE,
  COLUMN_NAME_URLS,
  CONTENT_APPLICATION_ACCEPT_TITLE,
  CONTENT_APPLICATION_ACCEPT_DESCRIPTION,
  CONTENT_APPLICATION_REJECT_TITLE,
  CONTENT_APPLICATION_REJECT_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_BUDGETS_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_BUDGETS_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_DESCRIPTION,
  AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_TITLE,
  AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_DESCRIPTION,
  BUTTON_CONNECT_WOOCOMMERCE,
  FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION,
  FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS,
  FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION,
  FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS,
  BUTTON_ADD_CONTENT,
  BUTTON_SEE_ALL_APPLICANTS,
  REQUEST_REJECT_CONFIRMATION_DESCRIPTION,
  MAIN_FUNCTIONALITY_STEP_4,
  MODAL_INVITATION_STEP_7,
  MODAL_INVITATION_STEP_6,
  MAIN_FUNCTIONALITY_ONBOARDING_FINISH,
  GETTING_INFORMATION_FROM_SHOPIFY_URL,
  LAST_COLLABORATION,
  REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER,
  REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER,
  REGISTER_PROFILE_CREATOR_GENDER_ANSWER,
  REGISTER_PROFILE_CREATOR_AGE_ANSWER,
  REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED,
  REGISTER_PROFILE_CREATOR_GENDER_REQUIRED,
  REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED,
  REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED,
  SELECT_SOME_OPTIONS,
  REGISTER_PROFILE_CREATOR_CITIES_ANSWER,
  REGISTER_PROFILE_CREATOR_CITIES_REQUIRED,
  RATE_DAILY_LIMIT_AI,
  RATE_DAILY_LIMIT_AI_CHAT,
  ALERT_DISCARD_INFLUENCER_DEFINITIVELY_TITTLE,
  ALERT_DISCARD_INFLUENCER_DEFINITIVELY_DESCRIPTION,
  ALERT_DISCARD_INFLUENCER_DEFINITIVELY_BUTTON,
  BUTTON_GO_BACK,
  TOP_RATED_CATEGORY,
  WITH_RECURRENT_INVITATION_TITLE,
  BUTTON_CLEAR_FILTERS,
  BUTTON_ACTIVATE_AUTOMATICS_INVITATIONS,
  BUTTON_DEACTIVATE_AUTOMATICS_INVITATIONS,
  PERSONALIZED_INSTRUCTIONS,
  BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR,
  EMPTY_REQUESTS,
  EMPTY_CAMPAIGNS,
  EMPTY_AMBASSADORS,
  EMPTY_INVITATIONS,
  EMPTY_INVITATIONS_INACTIVE,
  EMPTY_ORDERS,
  EMPTY_REDEEM_CODES,
  EMPTY_USERS,
  EMPTY_CONTACTS,
  EMPTY_INVOICES,
  EMPTY_INVITATIONS_APPLICANTS,
  EMPTY_APPLICANTS_PENDING,
  EMPTY_APPLICANTS_ACCEPTED,
  PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META,
  ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS,
  AUTH_TIKTOK_VERIFICATION_LOADING,
  AUTH_TIKTOK_VERIFICATION_SUCCESS,
  AUTH_TIKTOK_VERIFICATION_FAILED,
  DOCUMENT_ID_EMIRATES,
  DOCUMENT_TRN,
  ADS_CAMPAIGNS_TITLE,
  ADS_CAMPAIGNS_DESCRIPTION,
  EMPTY_ADS_CAMPAIGNS,
  FIELD_INTERESTS_REQUIRED,
  FIELD_GENDERS_REQUIRED,
  FIELD_LOCATIONS_REQUIRED,
  FIELD_CURRENCY_MIN_VALUE,
  FIELD_BUDGET_REQUIRED,
  FIELD_TYPE_BUDGET_REQUIRED,
  FIELD_OBJECTIVE_REQUIRED,
  FIELD_NAME_REQUIRED,
  CREATE_ADS_CAMPAIGN_NAME_QUESTION,
  CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION,
  CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION,
  CREATE_ADS_CAMPAIGN_GENDERS_QUESTION,
  CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS,
  ADS_CAMPAIGN_OBJECTIVE_SALES,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION,
  FIELD_PLATFORMS_REQUIRED,
  CREATE_ADS_CAMPAIGN_PLATFORM_QUESTION,
  CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION,
  CTR,
  CLICKS,
  IMPRESSIONS,
  STATISTICS,
  PLATFORMS,
  AGES,
  GENDERS,
  LOCATIONS,
  TARGET_CAMPAIGN,
  ADS_CAMPAIGN_TITLE,
  ADS_CAMPAIGN_HAS_BEEN_FINISHED,
  DESCRIPTION_PLACEHOLDER,
  INTERESTS_PLACEHOLDER,
  GENDERS_PLACEHOLDER,
  AGE_RANGE_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  PLATFORMS_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
  ADS_CAMPAIGN_HAS_BEEN_ACTIVATE,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER,
  FIELD_DESCRIPTION_REQUIRED,
  TYPE_LEAST_CHARACTERS,
  LOADING,
  META,
  META_FACEBOOK_AND_INSTAGRAM,
  PLATFORM,
  FIELD_PLATFORM_REQUIRED,
  ALERT_CREATE_SECOND_ADS_CAMPAIGN,
  ALERT_CREATE_SECOND_ADS_CAMPAIGN_DESCRIPTION,
  BUTTON_META_BILLING,
  BUTTON_TIKTOK_BILLING,
  PLATFORM_PLACEHOLDER,
  SUB_MENU_ADS_CAMPAIGNS,
  OBJECTIVE,
  SALES,
  DOWNLOADS,
  LEADS,
  INTERACTIONS,
  FIELD_VIDEO_REQUIRED,
  CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
  BUTTON_REPLACE_CONTENT,
  TIKTOK_CAMPAIGN,
  META_CAMPAIGN,
  ADS_CAMPAIGN_HAS_BEEN_DUPLICATE,
  BUTTON_REPLACE_VIDEO,
  BUTTON_SELECT_VIDEO,
  CONTENT_TO_ADVERTISE,
  FIELD_BEHAVIORS_REQUIRED,
  CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION,
  CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION,
  CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER,
  BEHAVIORS_PLACEHOLDER,
  DEMOGRAPHICS_PLACEHOLDER,
  BUTTON_SELECT_THIS_CONTENT,
  BUTTON_REMOVE_SELECTION,
  FACEBOOK_PAGE_ID_NOT_FOUND,
  META_BUSINESS_ACCOUNT_NOT_FOUND,
  IG_BUSINESS_ACCOUNT_NOT_FOUND,
  CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION,
  EMPTY_TRACKING_IDS_META,
  FIELD_META_TRACKING_ID_REQUIRED,
  TRACKING_META_ID_PLACEHOLDER,
  FINISHED,
  ACCOUNT_TIKTOK_AUTH_NOT_MATCH,
  ACCOUNT_TOKEN_META_NOT_FOUND,
  ACCOUNT_ID_BUSINESS_META_NOT_FOUND,
  OBJECTIVE_CAMPAIGN_META_INVALID,
  SPECIAL_CATEGORY_CAMPAIGN_META_INVALID,
  CAMPAIGN_META_NOT_DELETED,
  CAMPAIGN_META_NOT_CREATED,
  ADS_CAMPAIGN_NOT_FOUND,
  NO_PIXELS_FOUND,
  NO_PIXELS_ACTIVE,
  CAMPAIGN_META_NOT_FOUND,
  CAMPAIGN_META_NOT_UPDATED,
  CAMPAIGN_AD_SET_META_NOT_CREATED,
  CAMPAIGN_AD_SET_META_NOT_UPDATED,
  CAMPAIGN_MEDIA_META_NOT_CREATED,
  CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND,
  CAMPAIGN_CREATIVE_AD_META_NOT_CREATED,
  CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED,
  CAMPAIGN_CREATIVE_AD_META_NOT_FOUND,
  CAMPAIGN_AD_META_NOT_CREATED,
  CAMPAIGN_AD_META_NOT_UPDATED,
  CAMPAIGN_METRICS_META_NOT_FOUND,
  BUTTON_CONNECT_TIKTOK_BUSINESS,
  TIKTOK_BUSINESS,
  EMPTY_TRACKING_IDS_TIKTOK,
  SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  FIELD_DESTINATION_URL_REQUIRED,
  FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL,
  FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED,
  CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  BUTTON_RECHARGE_BALANCE,
  ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM,
  INFORMATION,
  DESTINATION_URL_PLACEHOLDER,
  CAMPAIGN_AUDIENCE_IS_TOO_NARROW,
  CAMPAIGN_BUDGET_IS_TOO_LOW,
  PRICE_PRO_PLACEHOLDER,
  COST_COLLABORATION_PRO_PLACEHOLDER,
  PRO_PACKAGE_SUB_HEADER,
  PRO_PACKAGE_DESCRIPTION,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS,
  MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION,
  MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM,
  MODAL_SELECT_PACKAGE_LICENSED_PRICE,
  MODAL_SELECT_PACKAGE_LICENSED_FEE,
  MODAL_SELECT_PACKAGE_COST_CONTENT,
  SHOPIFY,
  WOOCOMMERCE,
  BUTTON_CONNECT_SHOPIFY,
  MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE,
  MODAL_STORE_BUTTON_CREATE_INTEGRATE_SHOPIFY,
  MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_SHOPIFY,
  MODAL_STORE_TITLE_CREATE_INTEGRATE_SHOPIFY,
  MODAL_STORE_DESCRIPTION_DISCONNECT_SHOPIFY,
  MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE,
  ALERT_DESCRIPTION_DISCONNECT_TIKTOK,
  ALERT_DESCRIPTION_DISCONNECT_FACEBOOK,
  MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT,
  MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE,
  MODAL_SELECT_PACKAGE_CONTENT_ENTERPRISE,
  ALERT_DESCRIPTION_DISCONNECT_TIKTOK_BUSINESS,
  VIDEO_ERROR_ILLEGAL_DURATION,
  VIDEO_ERROR_BLACK_EDGE,
  VIDEO_ERROR_ASPECT_RATIO,
  VIDEO_ERROR_NO_BACKGROUND_AUDIO,
  IDENTITY_NOT_FOUND,
  VIDEO_ERROR_LOW_RESOLUTION,
  TRACKING_TIKTOK_ID_PLACEHOLDER,
  BUTTON_CONNECT_META,
  FIELD_TIKTOK_TRACKING_ID_REQUIRED,
  INVITE_FORM_VALUE_CLARIFICATION,
  INVITE_FORM_VALUE_QUESTION,
  INVITE_FORM_VALUE_PLACEHOLDER_2,
  INVITE_FORM_VALUE_TO_PAY_QUESTION,
  INVITE_FORM_VALUE_TO_PAY_CLARIFICATION,
  INVITE_FORM_VALUE_TO_PAY_CLARIFICATION_2,
  INVITE_VALUE_TO_PAY_MIN_VALUE,
  INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER,
  INVITE_FORM_UGC_FORMAT_QUESTION,
  INVITE_FORM_LANGUAGE_QUESTION,
  INVITE_FORM_REFERENCE_URL_QUESTION,
  PAYMENT_REASON_INVITATION,
  MODAL_INVOICE_INVITATION,
  PUBLICATION_STATUS_PENDING_SOCIAL,
  ADD_NEW_STYLE,
  WANTS_SOCIAL_MEDIA_POST,
  DOES_NOT_WANTS_SOCIAL_MEDIA_POST,
  BUTTON_CONTACT_AN_ADVISOR,
  ENGAGEMENT_ABBREVIATED,
  FOLLOWERS_ABBREVIATED,
  NO_MORE_RESULTS_TO_SHOW,
  BUTTON_GENERATE_SCRIPT,
  EXPLANATION_SEND_ONE_REVISION,
  I_NEED_HELP,
  REVISION_OPTION_GOOD,
  REVISION_OPTION_REGULAR,
  REVISION_OPTION_BAD,
  BUTTON_VIEW_INVOICE,
  MODAL_INVOICE_LICENSE,
  CREATOR_BODY_BUILD_PLACEHOLDER,
  CREATOR_CLOTHING_STYLE_PLACEHOLDER,
  CREATOR_CONTENT_STYLE_PLACEHOLDER,
  CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER,
  BODY_BUILD_THIN,
  BODY_BUILD_AVERAGE,
  BODY_BUILD_WIDE,
  BODY_BUILD_MUSCULAR,
  BODY_BUILD_CURVY,
  BODY_BUILD_ATHLETIC,
  CLOTHING_STYLE_CASUAL,
  CLOTHING_STYLE_FORMAL,
  CLOTHING_STYLE_SPORTY,
  CLOTHING_STYLE_BUSINESS,
  CLOTHING_STYLE_ELEGANT,
  CLOTHING_STYLE_TRENDY,
  CLOTHING_STYLE_STREETWEAR,
  CLOTHING_STYLE_TRADITIONAL,
  CLOTHING_STYLE_VINTAGE,
  CLOTHING_STYLE_BOHEMIAN,
  CLOTHING_STYLE_HIPSTER,
  CLOTHING_STYLE_PUNK,
  CLOTHING_STYLE_GOTHIC,
  DISTINGUISHING_FEATURES_TATTOOS,
  DISTINGUISHING_FEATURES_SCARS,
  DISTINGUISHING_FEATURES_BIRTHMARKS,
  DISTINGUISHING_FEATURES_FRECKLES,
  DISTINGUISHING_FEATURES_MOLES,
  DISTINGUISHING_FEATURES_PIERCINGS,
  DISTINGUISHING_FEATURES_DIMPLES,
  DISTINGUISHING_FEATURES_WRINKLES,
  DISTINGUISHING_FEATURES_GLASSES,
  DISTINGUISHING_FEATURES_BRACES,
  DISTINGUISHING_FEATURES_MAKEUP,
  DISTINGUISHING_FEATURES_HAIR_ACCESSORIES,
  DISTINGUISHING_FEATURES_BEARD,
  DISTINGUISHING_FEATURES_MUSTACHE,
  CONTENT_STYLE_ELEGANT,
  CONTENT_STYLE_AESTHETIC,
  CONTENT_STYLE_RAW,
  CONTENT_STYLE_MINIMALISTIC,
  CONTENT_STYLE_ARTISTIC,
  CONTENT_STYLE_VINTAGE,
  CONTENT_STYLE_MODERN,
  CONTENT_STYLE_PLAYFUL,
  CONTENT_STYLE_RUSTIC,
  CONTENT_STYLE_MONOCHROMATIC,
  REGISTER_PROFILE_CREATOR_CONTENT_STYLE,
  REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES,
  REGISTER_PROFILE_CREATOR_CLOTHING_STYLE,
  REGISTER_PROFILE_CREATOR_BODY_BUILD,
  REGISTER_PROFILE_CREATOR_BODY_BUILD_REQUIRED,
  REGISTER_PROFILE_CREATOR_CLOTHING_STYLE_REQUIRED,
  REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES_REQUIRED,
  REGISTER_PROFILE_CREATOR_CONTENT_STYLE_REQUIRED,
  BUTTON_ADJUST_PREFERENCES,
  FIELD_MAX_LENGTH_SELECT,
  FIELD_MIN_LENGTH_SELECT,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION,
  CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER,
  BUTTON_GENERATE_AGAIN,
  BUTTON_CREATE_ADS_CAMPAIGN,
  WHY_THESE_RECOMMENDED_SETTINGS,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION,
  BUTTON_EDIT_BUDGET,
  CALL_TO_ACTION_PLACEHOLDER,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU,
  ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP,
  ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE,
  ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US,
  ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES,
  ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE,
  ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME,
  ADS_CAMPAIGN_CALL_TO_ACTION_SAVE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES,
  ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS,
  ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME,
  ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP,
  ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE,
  BUTTON_PUBLISH_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_TITLE,
  PIXEL_REQUIRED,
  ADS_CAMPAIGN_CONTEXT,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_PRODUCT_ADVERTISE,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_COMMUNICATE,
  CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_SEGMENTATION,
  BUTTON_START_CHAT,
  AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_TYPE_CONTENT_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_TERMS_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_TERMS_PLACEHOLDER,
  AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION,
  AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION,
  URL_PLACEHOLDER,
  AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED,
  AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED,
  AUTOMATIC_INVITES_COMMENT_REQUIRED,
  AUTOMATIC_INVITES_TERMS_REQUIRED,
  AUTOMATIC_INVITES_TYPE_CONTENT_REQUIRED,
  AUTOMATIC_INVITES_FORMATS_REQUIRED,
  AUTOMATIC_INVITES_CONTENT_REFERENCE_REQUIRED,
  AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED,
  BUTTON_ACTIVATE_NOW,
  BUTTON_NOT_NOW,
  MODAL_FUNCTIONALITY_AUTO_INVITES,
  IS_TRAINED_PHYSICAL_BRAND,
  BUTTON_PROMOTE,
  BUTTON_SELECT_CONTENT,
  FIELD_CONTENT_REQUIRED,
  TYPE_BUDGET_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  DRAFT,
  SUB_MENU_TOP_CONTENT,
  TOP_CONTENT_DESCRIPTION,
  TOP_CONTENT_TITLE,
  TOP_CONTENT_NOT_FOUND_IN_INTERVAL,
  BUTTON_DISCARD,
  ADS,
  UGC,
  COLUMN_NAME_IS_PLAN_ACTIVE,
  AUTH_INVALID_VERIFICATION_CODE,
  REGISTER_OTP_ANSWER,
  WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE,
  FIELD_VERIFICATION_CODE_REQUIRED,
  INTEGRATION_CURRENCY_NOT_FOUND,
  MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE,
  MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION,
  MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE,
  MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION,
  BUTTON_ACTIVATE_BRAND,
  MODAL_ONBOARDING_SCHEDULE_SECTION,
  MODAL_ONBOARDING_STORES_SECTION,
  MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION,
  BUTTON_SAVE_CATALOG,
  BUTTON_SAVE_PRODUCT,
  MODAL_ONBOARDING_BASIC_INFORMATION_SECTION,
  BUTTON_RESEND_CODE,
  SEARCH_INFLUENCERS_USERNAME,
  SEARCH_INFLUENCERS_TOPICS,
  SEARCH_BY,
  TYPE_A_TOPIC,
  TYPE_A_USERNAME,
  SEARCH_TOPIC_PLACEHOLDER,
  SEARCH_USERNAME_PLACEHOLDER,
  PROCESSING,
  AUDIENCE_LOCATION_PLACEHOLDER,
  CREATOR_LOCATION_PLACEHOLDER,
  RETRIEVING_TOP_POSTS_INFORMATION,
  RETRIEVING_AUDIENCE_STATISTICS,
  RETRIEVING_PROFILE_STATISTICS,
  RETRIEVING_BASIC_PROFILE_INFORMATION,
  ATTENTION,
  COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION,
  USER_EMAIL_OR_PASSWORD_WRONG,
  USER_NOT_FOUND,
  MODAL_DO_AMBASSADOR_DESCRIPTION,
  WITH_RECURRENT_PAYMENT_TITLE,
  RECURRENT_FIELDS_PAYMENT_TITLE,
  VALUE_TO_PAY_AMBASSADOR,
  FIELD_MIN_VALUE_TO_PAY_AMBASSADOR,
  VALUE_TO_PAY_AMBASSADOR_CLARIFICATION,
  WE_ARE_DOING_A_DEEPER_SEARCH,
  ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION,
  CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION,
  CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER,
  CREATORS_OWNER_WAS_REGISTERED_FILTER,
  CREATORS_OWNER_IS_PRE_REGISTERED_FILTER,
  COLUMN_NAME_IS_PRE_REGISTERED,
  COLUMN_NAME_WAS_PRE_REGISTERED,
  APPLICATION_VALUE,
  ERROR_NETWORK,
  MONTHLY,
  QUARTERLY,
  SEMIYEARLY,
  YEARLY,
  PLAN_PLACEHOLDER,
  ADS_COINS_PLACEHOLDER,
  ADS_CREDITS_PLACEHOLDER,
  ADVERTISER_PLACEHOLDER,
  ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION,
  DESCRIPTION_PAYMENT_LINK_CLARIFICATION,
  BUTTON_CREATE_PAYMENT_LINK,
} from "../keysTranslations";

const translation = {
  /*COMMON*/
  [SEARCH]: "Search",
  [LOADING_INFORMATION]: "Loading information",
  [LOADING_SUGGESTED_INFORMATION]:
    "Searching suggested creators, it may take a while.",
  [LOADING_SIMILAR_PROFILES_INFORMATION]: "Loading reference profiles",
  [LOADING_REPORT_INFORMATION]: "Loading report",
  [LOADING_DOWNLOAD_PROFILES]: "Downloading profiles",
  [LOADING_CONTENT]: "Loading content",
  [LOADING_CONFIGURATION]: "Loading configuration",
  [INFORMATION_INVALID_ACCESS]: "You cannot access this information",
  [EMPTY_CONTENT_SHOW]: "No content to display",
  [EMPTY_SIMILAR_PROFILES_SHOW]: "No reference profiles found",
  [EMPTY_RESULTS_SHOW]: "No results found",
  [NO_MORE_RESULTS_TO_SHOW]: "There are no more results to display",
  [EMPTY_RESULTS_SHOW_ON_THIS_PAGE]: "No results found on this page",
  [EMPTY_QUESTIONS_SHOW]: "No questions found",
  [EMPTY_ANALYTICS]:
    "Analytics for this post could not be retrieved. Please contact support or your advisor for assistance.",
  [WE_ARE_DOING_A_DEEPER_SEARCH]:
    "We are doing a more in-depth search for creators that meet these criteria, please try again in 5 minutes",
  [ADVANCED_CONFIGURATION]: "Advanced configuration",
  [CREDITS]: "Credits",
  [WAIT]: "Wait",
  [COLLABORATIONS]: "Collaborations",
  [COLLABORATION]: "Collaboration",
  [DEACTIVATE]: "Deactivate",
  [ACTIVATE]: "Activate",
  [DEACTIVATED]: "Deactivated",
  [ACTIVE]: "Active",
  [BRAND]: "Brand",
  [WARNING]: "Warning",
  [INFORMATION]: "Information",
  [CATEGORY]: "Category",
  [CATEGORIES]: "Categories",
  [PRODUCT]: "Product",
  [PRODUCTS]: "Products",
  [ERROR]: "Error",
  [ACTIONS]: "Actions",
  [STATUS]: "Status",
  [CITIES]: "Cities",
  [UNKNOWN_FORMAT]: "Unknown format",
  [ERROR_DESCRIPTION_GENERIC]: "{{message}}",
  [HELP]: "Help",
  [READY]: "Done",
  [GO]: "Go",
  [GO_TO_PROFILE]: "Go to profile",
  [GO_TO_ACCOUNT]: "Go to account",
  [GO_TO_AUTOMATIC_INVITATIONS]: "Go to automatic invitations",
  [PROFILE]: "Profile",
  [LOGOUT]: "Log Out",
  [CLIENT]: "Client",
  [NAME]: "Name",
  [ADDRESS]: "Address",
  [IDENTIFICATION_NUMBER]: "Identification number",
  [PHONE_NUMBER]: "Phone number",
  [EMAIL]: "Email",
  [NOTIFICATIONS]: "Notifications",
  [CONTENT]: "Content",
  [ANALYTIC]: "Analytic",
  [ANALYTICS]: "Analytics",
  [COMMENTS]: "Comments",
  [COMMENT]: "Comment",
  [SHOW_COMMENTS]: "Show comments",
  [EMPTY_COMMENTS]: "No comments",
  [REVIEWS]: "Reviews",
  [REVIEW]: "Review",
  [SHOW_REVIEWS]: "Show reviews",
  [EMPTY_REVIEWS]: "No reviews",
  [SCORE]: "Score",
  [QUALITY_AUDIENCE]: "Audience quality",
  [QUALITY_CONTENT]: "Content quality",
  [AUDIENCE]: "Audience",
  [AUDIENCE_TITLE_EXPLANATION]: "What is the audience?",
  [AUDIENCE_EXPLANATION]:
    "The audience is the number of people who follow or subscribe to the content creator's profile. Larger audiences have a better reach for each post, but generally the interaction or engagement rate is lower as the audience increases.",
  [ENGAGEMENT]: "Engagement",
  [ENGAGEMENT_TITLE_EXPLANATION]: "What is the engagement?",
  [ENGAGEMENT_EXPLANATION]:
    "The engagement or interaction rate is the number of people who interact with the published content, whether it is a like, a comment, a share, or save, and other actions depending on the platform. If you want to estimate how many people will interact with the content, multiply the audience by the engagement and divide it by 100.",
  [FOLLOWERS]: "Followers",
  [AVG_VIEWS]: "Avg views",
  [AVG_LIKES]: "Avg likes",
  [AVG_COMMENTS]: "Avg comments",
  [RECORDS_IN_TABLE]: "Records",
  [PAGE_IN_TABLE]: "Page",
  [SHOP_DEACTIVATE_ALERT]: "Your brand is deactivated",
  [SHOP_DEACTIVATE_ALERT_DESCRIPTION]:
    "In order to perform this action, you must activate your brand",
  [UPDATE]: "Update",
  [MALES]: "Males",
  [MALE]: "Male",
  [FEMALES]: "Females",
  [FEMALE]: "Female",
  [STORE]: "Store",
  [SELECT_STORE]: "Select store",
  [NO_STORES_CREATED]: "No stores created",
  [EMPTY_STORES]: "No stores created",
  [ORDER]: "Order",
  [NEW_ORDER]: "New order",
  [NEW]: "New",
  [SECOND]: "Second",
  [SECONDS]: "Seconds",
  [MINUTE]: "Minute",
  [MINUTES]: "Minutes",
  [MINUTE_LETTER_COUNT_DOWN]: "min",
  [HOUR]: "Hour",
  [HOURS]: "Hours",
  [HOUR_LETTER_COUNT_DOWN]: "h",
  [DAY]: "Day",
  [DAYS]: "Days",
  [DAY_LETTER_COUNT_DOWN]: "d",
  [WEEK]: "Week",
  [WEEKS]: "Weeks",
  [WEEK_LETTER_COUNT_DOWN]: "w",
  [MONTH]: "Month",
  [MONTHS]: "Months",
  [QUESTION]: "Question",
  [CONTENT_CREATOR]: "Content creator",
  [ADD]: "Add",
  [MONDAY]: "Monday",
  [TUESDAY]: "Tuesday",
  [WEDNESDAY]: "Wednesday",
  [THURSDAY]: "Thursday",
  [FRIDAY]: "Friday",
  [SATURDAY]: "Saturday",
  [SUNDAY]: "Sunday",
  [SELECT_A_OPTION]: "Select an option",
  [FILE_SELECTED]: "Selected file: {{fileName}}",
  [YES]: "Yes",
  [NO]: "No",
  [IMAGES]: "Images",
  [VIDEOS]: "Videos",
  [OK]: "Ok",
  [FILTER]: "Filter",
  [SUPER_ADMIN]: "Super administrator",
  [ADMIN]: "Administrator",
  [OPERATOR]: "Operator",
  [DESIGNER]: "Designer",
  [TOTAL]: "Total",
  [NO_HAVE]: "No have",
  [NO_ADDED]: "Not Added",
  [SUPPORT]: "Support",
  [CONTACT_US]: "Contact us",
  [ONE]: "One",
  [TWO]: "Two",
  [THREE]: "Three",
  [INVITATIONS]: "Invitations",
  [ANY]: "Any",
  [GENDER]: "Gender",
  [INFLUENCER_NO_REDEEM_CODE]:
    "The creator associated with this code cannot redeem it due to an account limitation. Please contact support or your advisor.",
  [SEND_REVISION_INFLUENCER_COMMENT]:
    "Send feedback to content creator on why content wasn't approved, consider providing detailed specifics to improve the quality of the content.",
  [REVISIONS]: "Revisions",
  [CURRENT_CONTENT]: "Current content",
  [CURRENT_CONTENT_EMPTY]:
    "No current content, wait for the creator to upload it",
  [NO_SEND_CONTENT_FEEDBACK]: "The creator did not send feedback content",
  [EDIT]: "Edit",
  [THIS_CREATOR_IS_FAVORITE]: "Is favorite",
  [ADD_TO_FAVORITES]: "Add to favorites",
  [REMOVE_FROM_FAVORITES]: "Remove from favorites",
  [SPANISH]: "Spanish",
  [ENGLISH]: "English",
  [VIEW]: "View",
  [STORES]: "Stores",
  [PLAN]: "Plan",
  [AUTOMATIC_RENOVATION]: "Automatic renovation",
  [REPORT_ORDERS]: "Orders report",
  [ORDERS]: "Orders",
  [DELIVERIES]: "Deliveries",
  [IN_STORE]: "In-store",
  [ORDERS_PENDING]: "Pending",
  [ORDERS_PENDING_ANALYTICS]: "No analytics",
  [ORDERS_FEEDBACK]: "Feedback",
  [ORDERS_SENT]: "Sent",
  [ORDERS_REVIEWED]: "Reviewed",
  [CREATOR]: "Creator",
  [VALUE]: "Value",
  [WAS_GENERATED]: "Was generated",
  [ACCEPTED]: "Accepted",
  [PENDING]: "Pending",
  [ARE_YOU_SURE]: "Are you sure?",
  [CHANGE]: "Change",
  [REPORT_REQUESTS]: "Requests report",
  [REQUESTS_ACCEPTED]: "Accepted",
  [REQUESTS_REJECTED]: "Rejected",
  [REQUESTS_ACTIVE]: "Active requests",
  [REPORT_INVITATIONS]: "Invitations report",
  [CODE]: "Code",
  [IS_AUTOMATIC]: "Is automatic",
  [REPORT_INVITATIONS_INACTIVE]: "Inactive invitations report",
  [REDEEMED]: "Redeemed",
  [EXPIRED]: "Expired",
  [REDEEMED_SINGULAR]: "Redeemed",
  [EXPIRED_SINGULAR]: "Expired",
  [REJECTED]: "Rejected",
  [ROLE]: "Role",
  [UNKNOWN]: "Unknown",
  [RIGHT]: "Right",
  [LEFT]: "Left",
  [LOGO]: "Logo",
  [CANCEL_ANYTIME]: "Cancel anytime",
  [ADD_BRAND]: "Add brand",
  [DATE]: "Date",
  [HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP]:
    "You cannot deactivate your brand if you have active invitations",
  [NAME_BUSINESS_BOT]: "Ana from {{NAME_BUSINESS}}",
  [SERVICE_FEE]: "Service fee",
  [TOTAL_PAYMENT]: "Total payment",
  [LAST_COLLABORATION]: "Last collaboration",
  [PERSONALIZED_INSTRUCTIONS]: "Personalized instructions",
  [EMPTY_REQUESTS]: "You don't have any requests yet",
  [EMPTY_CAMPAIGNS]: "You don't have any campaigns yet",
  [EMPTY_AMBASSADORS]: "You don't have any ambassadors yet",
  [EMPTY_INVITATIONS]: "You don't have any invitations yet",
  [EMPTY_INVITATIONS_INACTIVE]: "You don't have any inactive invitations yet",
  [EMPTY_ORDERS]: "You don't have any collaborations yet",
  [EMPTY_REDEEM_CODES]: "You don't have any redeemed codes yet",
  [EMPTY_USERS]: "You haven't created any users yet",
  [EMPTY_CONTACTS]: "You haven't created any contacts yet",
  [EMPTY_INVOICES]: "You haven't created any invoices yet",
  [EMPTY_INVITATIONS_APPLICANTS]:
    "You haven't invited any creators to your campaign yet",
  [EMPTY_APPLICANTS_PENDING]: "You don't have any pending applicants yet",
  [EMPTY_APPLICANTS_ACCEPTED]: "You haven't accepted any applicants yet",
  [EMPTY_ADS_CAMPAIGNS]: "You don't have any ads campaigns yet",
  [TARGET_CAMPAIGN]: "Targeting",
  [LOCATIONS]: "Locations",
  [GENDERS]: "Genders",
  [AGES]: "Ages",
  [PLATFORMS]: "Platforms",
  [PLATFORM]: "Platform",
  [STATISTICS]: "Statistics",
  [IMPRESSIONS]: "Impressions",
  [CLICKS]: "Clicks",
  [CTR]: "CTR",
  [TYPE_LEAST_CHARACTERS]: "Type at least {{value}} characters",
  [LOADING]: "Loading",
  [OBJECTIVE]: "Objective",
  [INTERACTIONS]: "Interactions",
  [LEADS]: "Leads",
  [DOWNLOADS]: "Downloads",
  [SALES]: "Sales",
  [META_CAMPAIGN]: "Meta campaign",
  [TIKTOK_CAMPAIGN]: "TikTok campaign",
  [CONTENT_TO_ADVERTISE]: "Content to advertise",
  [EMPTY_TRACKING_IDS_META]: "You have no Meta pixels created",
  [FINISHED]: "Finished",
  [TIKTOK_BUSINESS]: "TikTok Business",
  [EMPTY_TRACKING_IDS_TIKTOK]: "You have no TikTok pixels created",
  [SHORT_TIME_HAS_PASSED_SINCE_ACTIVATION_STATISTICS]:
    "A short time has passed since you activated the campaign, the statistics may take a while to update",
  [SHOPIFY]: "Shopify",
  [WOOCOMMERCE]: "Woocommerce",
  [FOLLOWERS_ABBREVIATED]: "FLL",
  [ENGAGEMENT_ABBREVIATED]: "ER",
  [ADD_NEW_STYLE]: "Add new style",
  [WANTS_SOCIAL_MEDIA_POST]: "I want social media exposure",
  [DOES_NOT_WANTS_SOCIAL_MEDIA_POST]: "I don't want social media exposure",
  [EXPLANATION_SEND_ONE_REVISION]:
    "You will only be able to submit one revision",
  [I_NEED_HELP]: "I need help",
  [REVISION_OPTION_GOOD]: "Awesome! But I have a question",
  [REVISION_OPTION_REGULAR]: "It's okay, but something's missing",
  [REVISION_OPTION_BAD]: "This doesn't work for me",
  [WHY_THESE_RECOMMENDED_SETTINGS]: "Why these recommended settings?",
  [PIXEL_REQUIRED]: "Pixel required",
  [IS_TRAINED_PHYSICAL_BRAND]: "Is a trained physical brand",
  [DRAFT]: "Draft",
  [ADS]: "ADS",
  [UGC]: "UGC",
  [WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE]:
    "We will send a verification code to your phone, it may take up to a minute to arrive.",
  [SEARCH_BY]: "Search by",
  [TYPE_A_USERNAME]: "Type a username",
  [TYPE_A_TOPIC]: "Type a topic",
  [PROCESSING]: "Processing",
  [RETRIEVING_BASIC_PROFILE_INFORMATION]:
    "Retrieving basic profile information",
  [RETRIEVING_PROFILE_STATISTICS]: "Retrieving profile statistics",
  [RETRIEVING_AUDIENCE_STATISTICS]: "Retrieving audience statistics",
  [RETRIEVING_TOP_POSTS_INFORMATION]: "Retrieving top posts information",
  [ATTENTION]: "Attention",
  [COLLECTING_INFLUENCER_INFORMATION_PHYLLO_DESCRIPTION]:
    "The statistics of this creator are not available immediately, we are collecting the information. This action may take a few minutes, the page will be updated automatically when the data is ready.",
  [MONTHLY]: "Monthly",
  [QUARTERLY]: "Quarterly",
  [SEMIYEARLY]: "Semiyearly",
  [YEARLY]: "Yearly",
  [ADVERTISER_ATTACH_PAYMENT_LINK_CLARIFICATION]:
    "Once the payment of this link is made, the plan information will be attached to the advertiser you select.",
  [DESCRIPTION_PAYMENT_LINK_CLARIFICATION]:
    "This description will be displayed in the payment link to give more information to the advertiser about what he will pay for",

  /* PAYMENTS */
  [SELECT_PAYMENT_METHOD]: "Select payment method",
  [ADD_PAYMENT_METHOD]: "Add payment method",
  [PAYMENT_METHOD]: "Payment method",
  [PAYMENT_METHODS]: "Payment methods",
  [PAYMENT_METHODS_EMPTY]: "No payment methods available, add one",
  [MAIN_PAYMENT_METHOD]: "Main Payment Method",
  [OTHERS_PAYMENT_METHODS]: "Other payment methods",
  [OTHERS_PAYMENT_METHODS_EMPTY]: "No additional payment methods",
  [PAYMENT_METHODS_SECURE]: "Your payment methods will be secured",
  [PAYMENT_METHODS_SECURE_EXPLANATION]:
    "Your payment information will never be exposed, and you can modify it at any time",
  [PAYMENT_METHODS_ENCRYPTED]: "End-to-End Encryption",
  [PAYMENT_SUCCESSFUL]: "Payment successful",
  [PRICE_TO_BE_PAID]: "Price to be paid",
  [PAY]: "Pay",
  [ADD_CARD]: "Add card",
  [SAVE_CONFIGURATION]: "Save configuration",
  [CARD_INFORMATION_EXPLANATION_TITLE]: "Information collected from your card",
  [CARD_INFORMATION_NUMBER_CARD_EXPLANATION_TITLE]: "Card Number",
  [CARD_INFORMATION_NUMBER_CARD_EXPLANATION_DESCRIPTION]:
    "The card number consists of 16 digits and is separated into groups of 4. It is located on the front of the card.",
  [CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_TITLE]: "Expiration Date",
  [CARD_INFORMATION_EXPIRATION_DATE_EXPLANATION_DESCRIPTION]:
    "It can be found on the front or back of the card, and it consists of 4 or sometimes 3 numbers. Its format is MM/YY (Month in number/Last two digits of the year).",
  [CARD_INFORMATION_CVV_EXPLANATION_TITLE]: "Security Code (CVV)",
  [CARD_INFORMATION_CVV_EXPLANATION_DESCRIPTION]:
    "It is usually located on the back, sometimes on the front, and consists of 4 or 3 numbers. Its function is to validate the card.",
  [YOU_WILL_PAY]: "You will pay",
  [ACCEPT_TERMS_PAY]: `Accept {{NAME_BUSINESS}}'s terms and conditions`,
  [DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE]:
    "Are you sure you want to delete this payment method?",
  [QUANTITY_TO_PAY]: "Amount to pay",
  [USERS]: "Users",

  /* FIELDS PLACEHOLDERS */
  [NAME_PLACEHOLDER]: "Name",
  [EMAIL_PLACEHOLDER]: "Email",
  [PASSWORD_PLACEHOLDER]: "Password",
  [PASSWORD_CONFIRMATION_PLACEHOLDER]: "Confirm password",
  [SELECT_BUSINESS_PLACEHOLDER]: "Select a business",
  [NAME_BUSINESS_PLACEHOLDER]: "Business name",
  [SELECT_BRANDS_PLACEHOLDER]: "Select brands",
  [MAIN_BRAND_PLACEHOLDER]: "Main brand",
  [SELECT_BRAND_PLACEHOLDER]: "Select a brand",
  [SELECT_STORE_PLACEHOLDER]: "Select a store",
  [NAME_AND_LAST_NAME_PLACEHOLDER]: "First and last name",
  [SELECT_TYPE_PLAN_PLACEHOLDER]: "Plan type",
  [COST_COLLABORATION_PLACEHOLDER]: "Collaboration cost",
  [CREDITS_PLACEHOLDER]: `{{NAME_BUSINESS}} Credits`,
  [TRIAL_DAYS_PLACEHOLDER]: "Trial days",
  [TYPE_PACKAGE_PLACEHOLDER]: "Package type",
  [PRICE_BASIC_PLACEHOLDER]: "Basic subscription cost",
  [COST_COLLABORATION_BASIC_PLACEHOLDER]: "Basic collaboration cost",
  [PRICE_PRO_PLACEHOLDER]: "Pro subscription cost",
  [COST_COLLABORATION_PRO_PLACEHOLDER]: "Pro collaboration cost",
  [PRICE_GROWTH_PLACEHOLDER]: "Growth subscription cost",
  [COST_COLLABORATION_GROWTH_PLACEHOLDER]: "Growth collaboration cost",
  [COMMERCIAL_PLACEHOLDER]: "Commercial",
  [BUSINESS_NAME_PLACEHOLDER]: "Brand name",
  [DESCRIPTION_OPTIONAL_PLACEHOLDER]: "Description (optional)",
  [PRICE_PLACEHOLDER]: "Price",
  [CURRENCY_PLACEHOLDER]: "Currency",
  [IS_RECURRENCE_PLACEHOLDER]: "The charge is",
  [RECURRENCE_PLACEHOLDER]: "Recurrence",
  [SELECT_CAMPAIGN_PLACEHOLDER]: "Select a campaign",
  [SELECT_UGC_CONTENT_FORMAT]: "Select a content style",
  [SELECT_SOME_OPTIONS]: "Select some options",
  [OBJECTIVE_PLACEHOLDER]: "Objective",
  [PLATFORMS_PLACEHOLDER]: "Platforms",
  [PLATFORM_PLACEHOLDER]: "Platform",
  [DAILY_BUDGET_PLACEHOLDER]: "Daily budget",
  [LIFETIME_BUDGET_PLACEHOLDER]: "Total budget",
  [LOCATIONS_PLACEHOLDER]: "Locations",
  [AGE_RANGE_PLACEHOLDER]: "Age range",
  [GENDERS_PLACEHOLDER]: "Genders",
  [INTERESTS_PLACEHOLDER]: "Interests",
  [BEHAVIORS_PLACEHOLDER]: "Behaviors",
  [DEMOGRAPHICS_PLACEHOLDER]: "Demographics",
  [DESCRIPTION_PLACEHOLDER]: "Description",
  [TRACKING_META_ID_PLACEHOLDER]: "Meta Pixel ID",
  [TRACKING_TIKTOK_ID_PLACEHOLDER]: "TikTok Pixel ID",
  [DESTINATION_URL_PLACEHOLDER]: "URL where traffic is taken",
  [CREATOR_BODY_BUILD_PLACEHOLDER]: "Body type",
  [CREATOR_CLOTHING_STYLE_PLACEHOLDER]: "Clothing style",
  [CREATOR_CONTENT_STYLE_PLACEHOLDER]: "Content style",
  [CREATOR_DISTINGUISHING_FEATURES_PLACEHOLDER]: "Distinguishing features",
  [CALL_TO_ACTION_PLACEHOLDER]: "Call to action",
  [ADS_CAMPAIGN_CONTEXT]: "What is your campaign about?",
  [URL_PLACEHOLDER]: "https://www.url.com",
  [TYPE_BUDGET_PLACEHOLDER]: "Type of budget",
  [SEARCH_TOPIC_PLACEHOLDER]: "Foodie",
  [SEARCH_USERNAME_PLACEHOLDER]: "sofiavergara",
  [CREATOR_LOCATION_PLACEHOLDER]: "Creator location",
  [AUDIENCE_LOCATION_PLACEHOLDER]: "Audience location",
  [PLAN_PLACEHOLDER]: "Plan",
  [ADS_CREDITS_PLACEHOLDER]: "Credits",
  [ADS_COINS_PLACEHOLDER]: "Coins",
  [ADVERTISER_PLACEHOLDER]: "Advertiser",

  /*OPTIONS SELECT*/
  [PLAN_PAY_PER_USE]: "Pay-Per-Use subscription",
  [PLAN_PACKAGE]: "Package License",
  [PLAN_REGULAR]: "Regular plans (Basic and Pro)",
  [PLAN_ENTERPRISE]: "Enterprise",
  [ADD_NEW_OPTION]: `Add "{{value}}"`,
  [RECURRENT_OPTION]: "Recurrent",
  [RECURRING_DAYS]: "Every day",
  [RECURRING_WEEKS]: "Every week",
  [RECURRING_MONTHS]: "Every month",
  [RECURRING_YEARS]: "Every year",

  /*PLANS*/
  [PLAN_PAY_PER_USE_NAME]: "Pay Per Use Plan",
  [PLAN_PACKAGE_NAME]: "Plan {{value}}",

  /* FIELDS VALIDATIONS */
  [FIELD_REQUIRED]: "This field is required",
  [FIELD_NUMERIC]: "This field must be numeric",
  [FIELD_TEXT]: "This field must contain only letters",
  [FIELD_ALPHANUMERIC]: "This field must contain only letters and numbers",
  [FIELD_MIN_LENGTH]: "You must enter at least {{value}} characters",
  [FIELD_MAX_LENGTH]: "You must enter a maximum of {{value}} characters",
  [FIELD_FILE_VALID_REQUIRED]:
    "You must select a file in one of these formats: {{value}}",
  [FIELD_FILE_MAX_SIZE]:
    "The file is too large, please select one with a size smaller than {{value}}{{type}}",
  [FIELD_IMAGE_REQUIRED]: "You must select a valid image format",
  [FIELD_IMAGE_FORMAT_VALID]:
    "The image is too large, please select one with a size smaller than {{value}}{{type}}",
  [FIELD_IMAGE_SIZE_VALID]: "No image has been selected",
  [FIELD_FILE_REQUIRED]:
    "The file is too large, please select one with a size smaller than {{value}}{{type}}",
  [FIELD_FILE_SIZE_VALID]: "No file has been selected",
  [FIELD_URL_VALID]: "The URL must be valid",
  [FIELD_URL_HTTPS_VALID]: "The URL must include https://",
  [FIELD_EMAIL_VALID]: "The email must be valid",
  [FIELD_EMAIL_REGISTERED]:
    "The email is already registered, try with a different email address",
  [FIELD_SPACES_VALID]: "The field cannot contain spaces",
  [FIELD_PHONE_VALID]: "The phone must be valid",
  [FIELD_ALL_REQUIRED]: "All fields are required",
  [FIELD_BOTH_PASSWORD_REQUIRED]: "Both passwords are required",
  [FIELD_PASSWORDS_NO_MATCH]: "Passwords do not match",
  [FIELD_PASSWORD_MIN_LENGTH]: "Password must be at least 6 characters long",
  [FIELD_PASSWORD_CHANGE_MIN_LENGTH]:
    "If you're changing the password, it must be at least 6 characters long",
  [FIELD_PASSWORD_INSECURE]:
    "The password must be at least 6 characters, one uppercase letter, one lowercase letter, one number, and one special character",
  [FIELD_NUMBER_NEGATIVE]: "The number cannot be negative",
  [FIELD_EMAIL_REQUIRED]: "The email is required",
  [FIELD_SEARCH_CHATS_MORE_3_CHARACTERS]:
    "Type more than 3 characters to search for new chats",
  [FIELD_NUMBER_0_OR_NEGATIVE]: "The number cannot be 0 or negative",
  [FIELD_NUMBER_100_GREATER]: "The number cannot be greater than 100",
  [FIELD_NUMBER_LESS_THAN]: "The number cannot be less than {{value}}",
  [FIELD_NUMBER_GREATER_THAN]: "The number cannot be greater than {{value}}",
  [FIELD_CREDITS_NOT_BE_NEGATIVE]: `{{NAME_BUSINESS}} Credits cannot be negative`,
  [FIELD_COST_COLLABORATION_NOT_BE_NEGATIVE]:
    "Collaboration cost cannot be negative",
  [FIELD_TRIAL_MONTHS_NOT_BE_NEGATIVE]: "Trial months cannot be negative",
  [FIELD_TRIAL_DAYS_NOT_BE_NEGATIVE]: "Trial days cannot be negative",
  [FIELD_PLAN_PRICES_NOT_BE_NEGATIVE]: "Plan prices cannot be negative",
  [FIELD_STAFF_IS_REQUIRED]: "A staff must be selected",
  [FIELD_BOTH_DATES_REQUIRED]: "Both dates are required",
  [FIELD_START_DATE_GREATER_THAN_END_DATE]:
    "The start date cannot be greater than the end date",
  [FIELD_END_DATE_GREATER_NOW]:
    "The end date cannot be greater than the current date",
  [FIELD_MIN_AMOUNT_PAY]:
    "The amount to pay cannot be less than {{value,currency}}",
  [FIELD_ACCESS_TOKEN_SHOPIFY_VALID]:
    "Shopify's access token is not in the correct format, it usually starts with shpat_ followed by random characters",
  [FIELD_NAME_REQUIRED]: "Name is required",
  [FIELD_OBJECTIVE_REQUIRED]: "Objective is required",
  [FIELD_PLATFORMS_REQUIRED]: "Platforms are required",
  [FIELD_PLATFORM_REQUIRED]: "Platform is required",
  [FIELD_TYPE_BUDGET_REQUIRED]: "Budget type is required",
  [FIELD_BUDGET_REQUIRED]: "Budget is required",
  [FIELD_CURRENCY_MIN_VALUE]: "Value cannot be less than {{value,currency}}",
  [FIELD_LOCATIONS_REQUIRED]: "Locations are required",
  [FIELD_GENDERS_REQUIRED]: "Genders are required",
  [FIELD_INTERESTS_REQUIRED]: "Interests are required",
  [FIELD_BEHAVIORS_REQUIRED]: "Behaviors are required",
  [FIELD_DESCRIPTION_REQUIRED]: "Description is required",
  [FIELD_VIDEO_REQUIRED]: "Video is required",
  [FIELD_META_TRACKING_ID_REQUIRED]: "Meta pixel is required",
  [FIELD_DESTINATION_URL_REQUIRED]: "Destination URL is required",
  [FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL_REQUIRED]:
    "Destination URL is not configured with the selected pixel",
  [FIELD_DESTINATION_URL_NOT_FOUND_IN_EVENTS_PIXEL]:
    "The destination URL is not configured with the selected pixel; if you continue, some statistics may not be recorded",
  [FIELD_TIKTOK_TRACKING_ID_REQUIRED]: "TikTok pixel is required",
  [FIELD_MIN_LENGTH_SELECT]: "You must select at least {{value}} options",
  [FIELD_MAX_LENGTH_SELECT]: "You must select a maximum of {{value}} options",
  [FIELD_CONTENT_REQUIRED]: "Content is required",
  [FIELD_VERIFICATION_CODE_REQUIRED]: "Verification code is required",
  [FIELD_TYPE_MIN_LENGTH]: "Type at least {{value}} characters",
  [FIELD_MIN_VALUE_TO_PAY_AMBASSADOR]:
    "The value to be paid to the ambassador cannot be less than {{value,currency}}",

  /* MODAL INVITATION */
  [MODAL_INVITATION_TITLE]: "Invite {{influencerName}}",
  [MODAL_INVITATION_TITLE_CAMPAIGN]:
    "Invite {{influencerName}} to your campaign",
  [INVITE_FORM_ORDER_FROM_PLACE_HOLDER]: "Use from",
  [INVITE_FORM_ORDER_FROM_HELPER_TEXT]:
    "Creator will be able to use this code from this date",
  [INVITE_FORM_VALUE_PLACEHOLDER]: "Enter the value of the invitation",
  [INVITE_FORM_VALUE_PLACEHOLDER_2]: "Enter value",
  [INVITE_FORM_VALUE_QUESTION]:
    "What is the product value you want to gift the content creator?",
  [INVITE_FORM_VALUE_CLARIFICATION]:
    "This value is purely redeemable as gifts and will not be debited from your payment method.",
  [INVITE_FORM_VALUE_HELPER_TEXT]: "Suggested value: {{value,currency}}",
  [INVITE_FORM_VALUE_TO_PAY_QUESTION]:
    "Do you want to make an additional cash payment to the content creator?",
  [INVITE_FORM_VALUE_TO_PAY_CLARIFICATION]:
    "This amount will be debited from your payment method once you approve the content and will be paid to the creator. {{valueWithFee,currency}} will be debited (+{{fee}}% fee).",
  [INVITE_FORM_VALUE_TO_PAY_CLARIFICATION_2]:
    "{{valueWithFee,currency}} will be debited (+{{fee}}% fee)",
  [INVITE_VALUE]: "Value of the invitation",
  [INVITE_VALUE_EXPLANATION]:
    "This value will not be given in cash but as gifts of products from your brand.",
  [INVITE_FORM_UGC_FORMAT_QUESTION]:
    "What style of content do you want the creator to make?",
  [INVITE_FORM_LANGUAGE_QUESTION]:
    "In which language do you want the content to be created?",
  [INVITE_FORM_REFERENCE_URL_QUESTION]:
    "What content do you want the creator to use as a reference?",
  [INVITE_FORM_COMMENT_PLACEHOLDER]: "Write your recommendations here",
  [INVITE_FORM_COMMENT_PLACEHOLDER_2]: "Comment",
  [INVITE_FORM_COMMENT_PLACEHOLDER_3]:
    "I want the content to be educational, inviting the audience to experience my product by highlighting its advantages, in everyday scenes, preferably in an elegant environment.",
  [INVITE_FORM_COMMENT_HELPER_TEXT]:
    "Create a content script. Describe the style, message and any must-haves.",
  [INVITE_FORM_TYPE_CONTENT]: "Type of content",
  [INVITE_FORM_TYPE_CONTENT_IMAGES]: "Images",
  [INVITE_FORM_TYPE_CONTENT_VIDEOS]: "Videos",
  [INVITE_FORM_TYPE_CONTENT_REQUIRED]: "Type content is required",
  [INVITE_FORM_CODE_PLACEHOLDER]: "Use a custom code",
  [INVITE_FORM_CODE_HELPER_TEXT]:
    "Use your own e-commerce discount code to process the sale.",
  [INVITE_FORM_CODE_REQUIRED]: "The code is required",
  [INVITE_FORM_CODE_MIN_LENGTH]:
    "The code must be at least {{value}} characters",
  [INVITE_FORM_CODE_PLATFORM_INTEGRATION_TEXT]: `How to integrate my {{NAME_BUSINESS}} invitations with {{platform}}?`,
  [INVITE_FORM_DISCOUNT_CODE_PLACEHOLDER]: "Create a discount code",
  [INVITE_FORM_DISCOUNT_CODE_HELPER_TEXT]:
    "(Optional) Create a discount code for this creator's audience.",
  [INVITE_FORM_ADVANCED_CONFIGURATION_DESCRIPTION]:
    "You can explore the options in this section, though please be aware that some settings may impact the acceptance rate of invitations from content creators.",
  [INVITE_FORM_SELECT_FORMATS_PLATFORM]: "Select content format",
  [INVITE_FORM_SELECT_FORMATS_PLATFORM_DESCRIPTION]:
    "The final format will depend on the creator",
  [INVITE_FORM_FORMAT_INSTAGRAM_BURST_STORIES]: "Story burst",
  [INVITE_FORM_FORMAT_INSTAGRAM_POST]: "Instagram post",
  [INVITE_FORM_FORMAT_INSTAGRAM_REEL]: "Instagram reel",
  [INVITE_FORM_FORMAT_INSTAGRAM_STORY]: "Instagram story",
  [INVITE_FORM_FORMAT_TIKTOK_POST]: "TikTok post",
  [INVITE_FORM_FORMAT_TIKTOK_STORY]: "TikTok story",
  [INVITE_FORM_PERSONALIZED_URL_PLACEHOLDER]: "Personalized tracking link",
  [INVITE_FORM_TERMS_PLACEHOLDER]: "Terms and conditions",
  [INVITE_FORM_SELECT_LANGUAGE_PLACEHOLDER]: "Select language",
  [INVITE_FORM_SELECT_EVENTS_PLACEHOLDER]: "Select event",
  [SELECT_EVENTS_DISABLED_OPTION]: "No event",
  [INVITE_SENT]: "Invite sent",
  [INVITE_SENT_DESCRIPTION]:
    "For a value of {{value,currency}} to the content creator {{influencerName}}",
  [INVITE_EDIT]: "Invite edit",
  [REQUEST_REJECT_CONFIRMATION_DESCRIPTION]:
    "Reject this request or definitively discard this creator so that it does not appear again",
  [REQUEST_REJECTED]: "Request rejected",
  [REQUEST_REJECTED_DESCRIPTION]:
    "Content creator {{influencerName}}'s request was successfully rejected.",
  [INVITE_CANCELED]: "Invite canceled",
  [INVITE_CANCELED_DESCRIPTION]:
    "Content creator {{influencerName}}'s invitation has been canceled",
  [INVITE_VALUE_REQUIRED]: "The value of the invitation is required",
  [INVITE_VALUE_MIN_VALUE]:
    "The value of the invitation cannot be less than {{value,currency}}",
  [INVITE_VALUE_TO_PAY_MIN_VALUE]:
    "The value to pay cannot be less than {{value,currency}}",
  [INVITE_COMMENT_REQUIRED]: "Recommendations are required",
  [INVITE_COMMENT_MIN_LENGTH]:
    "Recommendations cannot be less than {{value}} characters",
  [INVITE_COMMENT_MAX_LENGTH]:
    "Recommendations cannot be longer than {{value}} characters",
  [INVITE_COMMENT_NO_LINK]: "There can't be a link in your recommendations",
  [INVITE_COMMENT_NO_PHONE]:
    "There cannot be a phone number in your recommendations",
  [INVITE_TERMS_LENGTH]:
    "Terms cannot have less than {{minLength}} and more than {{maxLength}} characters.",
  [INVITE_PERSONALIZED_URL_INVALID]: "The personalized link must be valid",
  [INVITE_PERSONALIZED_URL_EXPLANATION]:
    "The personalized tracking link is a link that you can create so that the content creator can use it in the collaboration with your brand, this way you will be able to know how many people entered your website from the content creator.",
  [INVITE_MIN_VALUE_OF_MENU]:
    "According to your catalog, the lowest value product is {{productValue,currency}} and your invitation is {{inviteValue,currency}}",
  [INVITE_STORE_LOCATION]:
    "You may not have a store in the city where this creator is located{{city}}. Would you like to invite him anyway?",
  [INVITE_CODE_IN_OTHER_INVITE]:
    "You are already using this code in another invitation",
  [INVITE_THESE_CREATORS]: "Invite these creators",
  [INVITE_THESE_CREATORS_CONFIRMATION]:
    "Are you sure you want to invite these {{count}} creators",
  [INVITE_CREATORS]: "Invite creators",
  [LIST_OF_CREATORS]: "List of creators",
  [MESSAGE_AFTER_INVITATION]: "We sent you an invitation! {{description}}",

  /*MODAL COMMERCIAL*/
  [MODAL_COMMERCIAL_TITLE_CREATE]: "Create commercial",
  [MODAL_COMMERCIAL_TITLE_EDIT]: "Edit commercial {{value}}",
  [MODAL_COMMERCIAL_CREATE_COMMERCIAL]: "Create commercial",
  [MODAL_COMMERCIAL_ALREADY_EXIST]:
    "A commercial with this name already exists",

  /*MODAL CREATE PAYMENT LINK*/
  [MODAL_CREATE_PAYMENT_LINK_TITLE]: "Create payment link",
  [MODAL_CREATE_PAYMENT_LINK_DESCRIPTION]:
    "Create a payment link for this brand, this will create an enterprise subscription.",
  [MODAL_CREATE_PAYMENT_LINK_ARIA_LABEL]: "Create Payment Link Modal",
  [MODAL_CREATE_PAYMENT_LINK_LINKS]: "Payment Links",
  [MODAL_CREATE_PAYMENT_LINK_LINKS_EMPTY]: "No payment links available",
  [MODAL_CREATE_PAYMENT_LINK_NAME]: "Payment {{value}}",

  /*MODAL HELP VIDEO AND FAQ*/
  [WARNING_MOBILE_TUTORIAL]:
    "For a better experience, perform the tutorial from your computer or laptop",
  [FAQ_DESCRIPTION]:
    "Here you will find the most frequently asked questions from our users. You can search for them by keywords or phrases.",
  [FAQ_QUESTION_WHAT_IS_BUZZLY]: `What is {{NAME_BUSINESS}}?`,
  [FAQ_QUESTION_WHAT_IS_BUZZLY_DESCRIPTION]: `{{NAME_BUSINESS}} is the first platform that allows small businesses to offer free or discounted products to content creators as gifts in exchange for exposure on social media. At the same time, creators can access a wide variety of products and services directly from their favorite brands through our mobile app.`,
  [FAQ_QUESTION_WHAT_IS_INVITATION]: "What is an Invitation?",
  [FAQ_QUESTION_WHAT_IS_INVITATION_DESCRIPTION]:
    "An invitation is a code given to a content creator to redeem in your store on the mobile app and receive products or services from your brand as gifts in exchange for generating content that you can use.",
  [FAQ_QUESTION_WHAT_IS_REQUEST]: "What is a Request?",
  [FAQ_QUESTION_WHAT_IS_REQUEST_DESCRIPTION]:
    "A request is a petition made by a content creator for you to send them an invitation to collaborate with your brand.",
  [FAQ_QUESTION_WHAT_IS_PUBLICATION]: "What is a Post?",
  [FAQ_QUESTION_WHAT_IS_PUBLICATION_DESCRIPTION]: `A Post is the collaboration generated when a content creator redeems an invitation in your store. A Post can have the following states: <br><br>
  <strong>Pending Post:</strong> The content creator redeemed the invitation but has not yet posted anything on their social media.<br>
  <strong>Pending Analytics:</strong> The content creator has already posted your brand's content on their social media and uploaded it, but analytics are not available yet.<br>
  <strong>Review:</strong> The creator has uploaded the analytics, and you can now review the collaboration's results to give a rating to the creator.<br>
  <strong>Verified:</strong> You have rated the collaboration based on the content and analytics generated.<br>
  <strong>Feedback:</strong> The creator had some issues with your products or services, so no collaboration was generated. You can invite them again.<br>
  `,
  [FAQ_QUESTION_WHAT_IS_AMBASSADOR]: "What is an Ambassador?",
  [FAQ_QUESTION_WHAT_IS_AMBASSADOR_DESCRIPTION]:
    "An ambassador is a content creator who has had a successful collaboration with your brand and whom you have chosen as an ambassador. This means you can invite them to collaborate every so often and have a closer communication with them.",
  [FAQ_QUESTION_WHAT_IS_CAMPAIGN]: "What is a Campaign?",
  [FAQ_QUESTION_WHAT_IS_CAMPAIGN_DESCRIPTION]: `A campaign is a more structured form of collaboration with content creators, where you can allocate a budget, set goals, content types, and specific requirements for content creators to apply. Once they apply, you can accept or reject creators for your campaign.`,
  [FAQ_QUESTION_WHAT_IS_ORDER]: "What is an Order?",
  [FAQ_QUESTION_WHAT_IS_ORDER_DESCRIPTION]: `An order is a request for products or services made by a content creator to your brand via delivery. An order can have the following states: <br><br>
  <strong>To Be Accepted:</strong> The content creator has already requested the products or services, but you need to accept the order and assign a processing time.<br>
  <strong>In Progress:</strong> You have accepted the order and are processing the products or services to send them to the content creator when ready, assigning shipping information.<br>
  <strong>To Be Delivered:</strong> You have already sent the products or services to the content creator, and only the delivery remains to be completed.<br>
  `,
  [FAQ_QUESTION_WHAT_IS_STORE]: "What is a Store?",
  [FAQ_QUESTION_WHAT_IS_STORE_DESCRIPTION]:
    "A store is a physical branch where content creators can redeem the invitations you send them to obtain products or services from your brand and generate content from your premises.",
  [FAQ_QUESTION_WHAT_ROLES_EXISTS]: "What Roles Exist?",
  [FAQ_QUESTION_WHAT_ROLES_EXISTS_DESCRIPTION]: `You can create different users to access your brand panel, and there are two roles: <br><br>
  <strong>Administrator:</strong> The administrator can manage invitations to content creators, requests, ambassadors, orders, campaigns, etc. They can also modify your brand information and create more users.<br>
  <strong>Operator:</strong> The operator is responsible for receiving the creator if they will visit one of your stores. They can redeem the code, manage orders in all three states, and view the catalog.<br>
  `,
  [FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS]:
    "Do I need to do anything additional on the platform after delivering the products?",
  [FAQ_QUESTION_ADDITIONAL_ACTIONS_DELIVERED_PRODUCTS_DESCRIPTION]: `You don't need to do anything additional. Simply enter the code in the "Redeem Code" section, verify the invitation's value, and deliver the products.<br><br>
  
<strong>1.</strong> Select the store at the top right.<br>
<strong>2.</strong> Enter the code provided by the content creator when they arrive at the store.<br>
<strong>3.</strong> Validate the code and its respective value.`,
  [FAQ_QUESTION_MUST_CALL_ADMIN]:
    "Do I need to call the business owner to verify the invitations?",
  [FAQ_QUESTION_MUST_CALL_ADMIN_DESCRIPTION]:
    "You don't need to call. If the platform accepts the code when you enter it, it means that the business owner, your direct manager, or the administrator has already sent an invitation to that content creator, and they are approved by that person.",
  [FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION]:
    "What if the content creator requests products that exceed the value of the invitation?",
  [FAQ_QUESTION_CREATOR_WANT_MORE_PRODUCTS_EXCEED_VALUE_INVITATION_DESCRIPTION]:
    "They must pay the excess. <strong>Tip:</strong> Always advise the creator to try to get as close as possible to the value of the invitation.",
  [FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE]:
    "What happens if the content creator redeems the code and does not use the entire value of the invitation?",
  [FAQ_QUESTION_CREATOR_REDEEM_CODE_WITH_MINOR_VALUE_DESCRIPTION]:
    "Once the code is redeemed, it expires automatically. This means the code won't work for any subsequent occasions.",
  [FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS]:
    "What are the differences between the gifting mode and paid campaigns?",
  [FAQ_QUESTION_DIFFERENCE_BETWEEN_EXCHANGES_AND_CAMPAIGNS_DESCRIPTION]: `The main difference is that in the <strong>gifting mode</strong>, content creators have more freedom to choose how to create the content, generating a more organic and natural collaboration. In contrast, in <strong>paid campaigns</strong>, brands can establish stricter rules on how the content should be created, and the creator must follow those guidelines for the content to be approved.`,
  [FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS]:
    "How can I ensure creators understand and accept the gifting terms?",
  [FAQ_QUESTION_ENSURE_CREATORS_UNDERSTAND_ACCEPT_EXCHANGE_TERMS_DESCRIPTION]: `To ensure that content creators understand and accept the gifting terms, you can do the following:<br><br>
    <strong>1.</strong> Review the creator's profile and metrics to see if the content they generally create aligns with what you are looking for.<br>
    <strong>2.</strong> The invitation comment should be clear and easy to understand.<br>
    <strong>3.</strong> Once you send the invitation, you can message the content creator to establish more direct communication and clarify any doubts they may have regarding creating the content.<br>
    `,

  /* INFLUENCER STATISTICS */
  [INFLUENCER_NOT_FOUND]: "This creator was not found",
  [LIST_CREATORS_WITH_AUDIENCE_SIMILAR]:
    "List of creators with similar audiences",
  [AUDIENCE_INFORMATION]: "Audience information",
  [AUDIENCE_CREDIBILITY]: "Audience credibility",
  [AUDIENCE_CREDIBILITY_TITLE_EXPLANATION]: "What is audience credibility?",
  [AUDIENCE_CREDIBILITY_DESCRIPTION_EXPLANATION]:
    "Audience credibility is an indicator that determines the percentage of followers who are real or valid profiles, i.e., bots or fraudulent profiles are excluded. The higher the percentage, the more reliable is the audience of the content creator",
  [AUDIENCE_BY_COUNTRY]: "Audience by country",
  [AUDIENCE_BY_CITIES]: "Audience by cities",
  [AUDIENCE_BY_BRAND_AFFINITY]: "Brand affinity",
  [AUDIENCE_BY_INTERESTS]: "Interests",
  [AUDIENCE_BY_DISTRIBUTION_AGE]: "Age and gender distribution",
  [AUDIENCE_BY_PERCENTAGE_GENDERS]: "Genders percentage",
  [AUDIENCE_BY_SIMILAR_AUDIENCE]: "Similar profiles",
  [AUDIENCE_BY_SIMILAR_AUDIENCE_NOT_FOUND]: "No similar profiles found",
  [INFLUENCER_POPULAR_CONTENT]: "Popular content",
  [GALLERY_CONTENT_BUZZLY]: `Portfolio`,

  /* LIST MENU */
  [MENU_PLATFORM]: "Gifting",
  [MENU_MEDIA]: "Media",
  [MENU_SERVICES]: "Tools",
  [MENU_ACTIONS]: "Operations",
  [MENU_CONFIGURATION]: "Settings",
  [MENU_ADMINISTRATION]: "Administration",
  [SUB_MENU_CREATORS]: "Creators",
  [SUB_MENU_AMBASSADORS]: "Ambassadors",
  [SUB_MENU_INVITATIONS]: "Invitations",
  [SUB_MENU_PUBLICATIONS]: "Collabs",
  [SUB_MENU_EVENTS]: "Events",
  [SUB_MENU_CAMPAIGNS]: "Campaigns",
  [SUB_MENU_ADS_CAMPAIGNS]: "{{NAME_BUSINESS}} Ads",
  [SUB_MENU_REPORTS]: "Reports",
  [SUB_MENU_DISCOVERY]: "Discovery",
  [SUB_MENU_SOCIAL_FEED]: "Social feed",
  [SUB_MENU_GALLERY]: "Gallery",
  [SUB_MENU_TOP_CONTENT]: "Top content",
  [SUB_MENU_REDEEM_CODE]: "Redeem code",
  [SUB_MENU_ORDERS]: "Orders",
  [SUB_MENU_ACCOUNT]: "Account",
  [SUB_MENU_STORES]: "My stores",
  [SUB_MENU_MENU]: "Catalog",
  [SUB_MENU_USERS]: "Users",
  [SUB_MENU_BILLING_AND_PAYMENTS]: "Billing",
  [SUB_MENU_BILLING_DASHBOARD]: "Payments",
  [SUB_MENU_BILLING_DATA]: "Information",
  [SUB_MENU_USERS_OWNER]: "Users",
  [SUB_MENU_BRANDS]: "Brands",
  [SUB_MENU_BRAND_SIGNUPS]: "Brand signups",
  [SUB_MENU_CREATORS_OWNER]: "Creators",
  [SUB_MENU_CREATORS_EXTERNALS]: "External creators",
  [SUB_MENU_PAY_CREATORS]: "Pay creators",
  [SUB_MENU_CREATOR_SIGNUPS]: "Creator signups",
  [SUB_MENU_CONTENTS]: "Content",
  [SUB_MENU_DELIVERIES]: "Deliveries",
  [SUB_MENU_CONTENT_APPLICATION]: "Content application",
  [SUB_MENU_REPORTS_OWNER]: "Reports",
  [SUB_MENU_FEEDBACK]: "Feedback",
  [SUB_MENU_PAYMENTS_FAILED]: "Failed payments",

  /* DATE */
  [DATE_HOUR]: "{{date, hour}}",
  [DATE_SHORT]: "{{date, monthText}} {{date, day}}, {{date, year}}",
  [DATE_SHORT_LOCALIZED]: "{{date, short}}",
  [DATE_LONG]: "{{date, day}}/{{date,month}}/{{date,year}} {{date,hour}}",
  [DATE_LONG_TEXT]:
    "{{date,dayText}}, {{date,monthText}} {{date, day}}, {{date,year}}",
  [DATE_LONG_TEXT_2]:
    "{{date,dayTextLong}}, {{date,monthText}} {{date, day}}, {{date,year}}",
  [DATE_LONG_TEXT_WITH_HOUR]:
    "{{date,dayText}}, {{date,monthText}} {{date, day}}, {{date,year}} {{date,hour}}",
  [DATE_LONG_LOCALIZED]: "{{date, long}}",
  [DATE_DISTANCE]: "{{date, ago}}",
  [LAST_UPDATE_DATE]: "Last update: {{date, relative}}",
  [PUBLISHED_DATE]: "Published: {{date, short}}",

  /* CURRENCY */
  [VALUE_WITH_CURRENCY]: "{{value, currency}}",
  [VALUE_WITH_PERCENTAGE]: "{{value, number}}%",
  [NUMBER_CURRENCY]: "{{value, currency}}",
  [VALUE_EXPRESSED_IN]: "Value expressed in {{currency}}",

  /* Buttons */
  [BUTTON_INVITE]: "Invite",
  [BUTTON_INVITE_ALL]: "Invite all",
  [BUTTON_VIEW_INVITE]: "View invitation",
  [BUTTON_EDIT_INVITE]: "Edit invitation",
  [BUTTON_CANCEL_INVITE]: "Cancel invite",
  [BUTTON_VIEW_REQUEST]: "View request",
  [BUTTON_REJECT_REQUEST]: "Reject request",
  [BUTTON_ORDER_IN_PROCESS]: "Order in process",
  [BUTTON_VIEW_COLLABORATION]: "View collaboration",
  [BUTTON_VIEW_METRICS]: "View metrics",
  [BUTTON_VIEW_REPORT]: "View report",
  [BUTTON_INIT_CHAT]: "Send message",
  [BUTTON_ADD_REFERENCE_PROFILE]: "Add reference profile",
  [BUTTON_USE_AS_REFERENCE_PROFILE]: "Use as reference profile",
  [BUTTON_ADD_AS_REFERENCE]: "Add as reference",
  [BUTTON_ADDED]: "Added",
  [BUTTON_DOWNLOAD]: "Download",
  [BUTTON_CALCULATE]: "Calculate",
  [BUTTON_ENTERPRISE_CALCULATE]: "Calculate enterprise",
  [BUTTON_REDEEM_CODE]: "Redeem",
  [BUTTON_ADD_STORE]: "Add store",
  [BUTTON_SEARCH]: "Search",
  [BUTTON_ACCEPT_ORDER]: "Accept order",
  [BUTTON_REJECT_ORDER]: "Reject order",
  [BUTTON_READY_ORDER]: "Order ready",
  [BUTTON_DELIVER_ORDER]: "Deliver order",
  [BUTTON_CANCEL]: "Cancel",
  [BUTTON_CREATE_CAMPAIGN]: "Create",
  [BUTTON_CREATE_CAMPAIGN2]: "Create new campaign",
  [BUTTON_SAVE_PROGRESS]: "Save progress",
  [BUTTON_NEXT]: "Next",
  [BUTTON_PREVIOUS]: "Previous",
  [BUTTON_ADVANCED_CONFIGURATION]: "Advanced configuration",
  [BUTTON_FINISH]: "Finish",
  [BUTTON_UPLOAD_CONTENT]: "Upload content",
  [BUTTON_REMOVE_CONTENT]: "Remove content",
  [BUTTON_FINISHED_CAMPAIGN]: "Finish campaign",
  [BUTTON_SAVE_CAMPAIGN]: "Save campaign",
  [BUTTON_DEACTIVATE_CAMPAIGN]: "Deactivate campaign",
  [BUTTON_ACTIVATE_CAMPAIGN]: "Activate campaign",
  [BUTTON_INVITE_CREATOR]: "Invite creator",
  [BUTTON_SEND_INVITE]: "Send invite",
  [BUTTON_REJECT]: "Reject",
  [BUTTON_ACCEPT]: "Accept",
  [BUTTON_ACCEPT_AND_PAY]: "Pay now",
  [BUTTON_ASSIGN_INVITE]: "Assign invitation",
  [BUTTON_REVIEW_CONTENT]: "Review content",
  [BUTTON_VIEW_PRELIMINARY_CONTENT]: "Review",
  [BUTTON_VIEW_APPLICANT]: "View applicant",
  [BUTTON_REVIEW]: "Rate",
  [BUTTON_ACCEPT_CONTENT]: "Accept content",
  [BUTTON_REJECT_CONTENT]: "Reject content",
  [BUTTON_SEND_REVIEW]: "Send review",
  [BUTTON_CONTINUE]: "Continue",
  [BUTTON_CONTINUOUS]: "Continuous",
  [BUTTON_UNDERSTOOD]: "Understood",
  [BUTTON_MORE_QUESTIONS]: "I have more questions",
  [BUTTON_INIT_TUTORIAL]: "Start tutorial",
  [BUTTON_PAYMENTS]: "Payments",
  [BUTTON_BROADCAST]: "Broadcast",
  [BUTTON_SAVE]: "Save",
  [BUTTON_SEND_MESSAGE]: "Chat now",
  [BUTTON_UPLOAD_FILE]: "Upload file",
  [BUTTON_REMOVE_FILE]: "Remove file",
  [BUTTON_VERIFY_PUBLICATION]: "Verify post",
  [BUTTON_SEE_PUBLICATION_ON]: "See on {{value}}",
  [BUTTON_INVITE_AGAIN]: "Invite again",
  [BUTTON_INVITE_BUZZLY]: `Invite to {{NAME_BUSINESS}}`,
  [BUTTON_METRICS_READY]: "Metrics ready",
  [BUTTON_UPLOAD_TIKTOK]: "Upload on TikTok",
  [BUTTON_VIEW_PROFILE]: "View profile",
  [BUTTON_GENERATE_DESCRIPTION]: "Generate description",
  [BUTTON_GENERATE_COMMENT]: "Generate comment",
  [BUTTON_UPLOAD_IMAGE]: "Upload image",
  [BUTTON_REMOVE_IMAGE]: "Remove image",
  [BUTTON_SAVE_CHANGES]: "Save changes",
  [BUTTON_UPLOAD_VIDEO]: "Upload video",
  [BUTTON_REMOVE_VIDEO]: "Remove video",
  [BUTTON_DISCONNECT]: "Disconnect",
  [BUTTON_CONNECT_TIKTOK]: "Connect TikTok",
  [BUTTON_CONNECT_TIKTOK_BUSINESS]: "Connect TikTok Business",
  [BUTTON_BE_ECOMMERCE]: "Always open",
  [BUTTON_NOT_BE_ECOMMERCE]: "Edit schedules",
  [BUTTON_ADD_TURN]: "Add schedule",
  [BUTTON_DELETE]: "Delete",
  [BUTTON_CREATE]: "Create",
  [BUTTON_CONNECT_WOOCOMMERCE]: "Connect WooCommerce",
  [BUTTON_CREATE_USER]: "Create user",
  [BUTTON_CREATE_CONTACT]: "Create contact",
  [BUTTON_VIEW_DETAILS]: "View details",
  [BUTTON_DOWNLOAD_INVOICE]: "Download invoice",
  [BUTTON_CLOSE]: "Close",
  [BUTTON_UPLOAD_CATALOG]: "Upload catalog",
  [BUTTON_COPY_CATALOG]: "Copy catalog",
  [BUTTON_DOWNLOAD_FORMAT]: "Download format",
  [BUTTON_DUPLICATE]: "Duplicate",
  [BUTTON_EDIT]: "Edit",
  [BUTTON_MOVE_OTHER_CATEGORY]: "Move to other category",
  [BUTTON_COPY]: "Copy",
  [BUTTON_REGISTER]: "Register",
  [BUTTON_NO_HAVE_DOCUMENT]: "I don't have this document",
  [BUTTON_UPLOAD_AFTER]: "Upload later",
  [BUTTON_INVITED]: "Invited",
  [BUTTON_YES_I_SURE]: "Yes, I'm sure",
  [BUTTON_SEND_REVISION]: "Send revision",
  [BUTTON_APPROVE_CONTENT]: "Approve",
  [BUTTON_UPLOAD_INSTAGRAM]: "Upload on Instagram",
  [BUTTON_CONNECT_INSTAGRAM]: "Connect Instagram",
  [BUTTON_RELOAD_PAGE]: "Reload page",
  [BUTTON_REPLACE_IMAGE]: "Replace image",
  [BUTTON_GENERATE_CONTENT]: "Generate content",
  [BUTTON_REGENERATE_CONTENT]: "Regenerate content",
  [BUTTON_REGENERATE_TEXT]: "Regenerate text",
  [BUTTON_UPLOAD_LOGO]: "Upload logo",
  [BUTTON_REPLACE_LOGO]: "Replace logo",
  [BUTTON_UPLOAD_BACKGROUND]: "Upload background",
  [BUTTON_REPLACE_BACKGROUND]: "Replace background",
  [BUTTON_REMOVE_BACKGROUND]: "Remove background",
  [BUTTON_GENERATE_OTHER]: "Regenerate",
  [BUTTON_SUBSCRIBE_NOW]: "Subscribe now",
  [BUTTON_ADD_USER]: "Add user",
  [BUTTON_ADD_SHOP]: "Add shop",
  [BUTTON_DOWNLOAD_REPORT]: "Download report",
  [BUTTON_PASS_TO_SUBSCRIPTION_BASIC]: "Switch to basic subscription",
  [BUTTON_EXTEND]: "Extend",
  [BUTTON_ARCHIVE]: "Archive",
  [BUTTON_UNARCHIVE]: "Unarchive",
  [BUTTON_CREATE_TRANSACTION]: "Create transaction",
  [BUTTON_UPDATE_STATISTICS]: "Update statistics",
  [BUTTON_INVITE_EXCHANGE]: "Send gift",
  [BUTTON_INVITE_CAMPAIGN]: "Invite to campaign",
  [BUTTON_SEND_PAYMENT]: "Send payment",
  [BUTTON_CREATOR_CHAT]: "Creator Chat",
  [BUTTON_SEND_FILE]: "Send file",
  [BUTTON_SEE_MORE]: "See more",
  [BUTTON_SEND]: "Send",
  [BUTTON_YOU_HAVE_DOUBTS]: "Questions? Contact Us",
  [BUTTON_SAVE_CONTENT]: "Save content",
  [BUTTON_SEND_BROADCAST]: "Send broadcast",
  [BUTTON_ADD_CONTENT]: "Add content",
  [BUTTON_SEE_ALL_APPLICANTS]: "See all applicants",
  [BUTTON_GO_BACK]: "Go back",
  [BUTTON_CLEAR_FILTERS]: "Clear filters",
  [BUTTON_ACTIVATE_AUTOMATICS_INVITATIONS]: "Activate automatic invitations",
  [BUTTON_DEACTIVATE_AUTOMATICS_INVITATIONS]:
    "Deactivate automatic invitations",
  [BUTTON_SEND_AUTOMATIC_INVITES_LOOKALIKE_CREATOR]:
    "Send automatic invitations to profiles similar to {{mainAccountName}}",
  [BUTTON_TIKTOK_BILLING]: "TikTok billing",
  [BUTTON_META_BILLING]: "Meta billing",
  [BUTTON_REPLACE_CONTENT]: "Replace content",
  [BUTTON_SELECT_VIDEO]: "Select video",
  [BUTTON_REPLACE_VIDEO]: "Replace video",
  [BUTTON_SELECT_THIS_CONTENT]: "Select this content",
  [BUTTON_REMOVE_SELECTION]: "Remove selection",
  [BUTTON_RECHARGE_BALANCE]: "Recharge balance",
  [BUTTON_CONNECT_SHOPIFY]: "Connect Shopify",
  [BUTTON_CONNECT_META]: "Connect Meta",
  [BUTTON_CONTACT_AN_ADVISOR]: "I need help",
  [BUTTON_GENERATE_SCRIPT]: "Generate script",
  [BUTTON_VIEW_INVOICE]: "View invoice",
  [BUTTON_ADJUST_PREFERENCES]: "Adjust preferences",
  [BUTTON_GENERATE_AGAIN]: "Generate again",
  [BUTTON_CREATE_ADS_CAMPAIGN]: "Create campaign",
  [BUTTON_EDIT_BUDGET]: "Edit budget",
  [BUTTON_PUBLISH_CAMPAIGN]: "Publish campaign",
  [BUTTON_START_CHAT]: "Start chat",
  [BUTTON_ACTIVATE_NOW]: "Activate now",
  [BUTTON_NOT_NOW]: "Not now",
  [BUTTON_PROMOTE]: "Promote",
  [BUTTON_SELECT_CONTENT]: "Select content",
  [BUTTON_DISCARD]: "Discard",
  [BUTTON_ACTIVATE_BRAND]: "Activate brand",
  [BUTTON_SAVE_CATALOG]: "Save catalog",
  [BUTTON_SAVE_PRODUCT]: "Save product",
  [BUTTON_RESEND_CODE]: "Resend code",
  [BUTTON_CREATE_PAYMENT_LINK]: "Create payment link",

  /* Categories */
  [NEW_CREATORS_CATEGORY]: "New creators",
  [FAVORITES_CATEGORY]: "Favorites",
  [TOP_RATED_CATEGORY]: "Top rated",

  //VERSION
  [NEW_VERSION_TITLE]: "New version available!",
  [NEW_VERSION_DESCRIPTION]:
    "We've released an update to our website that might conflict with your current version. We recommend refreshing the page to get the latest version and avoid potential errors.",

  //INFLUENCER ACTIONS
  [DESCRIPTION_INVITE_EXCHANGE]: "Send a digital voucher to redeem for content",
  [DESCRIPTION_INVITE_CAMPAIGN]: "Create a campaign and pay for content",
  [DESCRIPTION_SEND_MESSAGE]: "Chat with this creator",
  [DESCRIPTION_SEND_PAYMENT]: "Send a payment securely",

  //MODAL PAY INFLUENCER
  [MODAL_PAY_CREATOR_AMOUNT_LABEL]: "Payment amount",
  [PAY_INFLUENCER]: "Payment to creator",
  [APPLICATION_VALUE]: "Application value",

  /* PAGES */

  // Creators
  [CREATORS_TITLE]: "Content Creators",
  [CREATOR_DESCRIPTION]:
    "Find and filter creators to collaborate on gifting, send messages & payments",
  [SEARCH_BAR_TEXT_CREATORS]: "Search by username",
  [TAB_CREATORS]: "All",
  [TAB_REQUESTS]: "Requests",
  [TAB_SUGGESTED]: "For you",
  [CREATORS_SUGGESTED_EMPTY]:
    "No suggested creators found, adjust your preferences to find creators",
  [CREATORS_SUGGESTED_BAD_CONFIGURATION_PROFILE_INFLUENCER]:
    "To view suggested creators, you must first create or update your information in the account section.",
  [CREATORS_SUGGESTED_BAD_CONFIGURATION_AUTOMATIC_INVITATIONS]:
    "To view suggested creators, you must first fill out or update your information in the account section.",
  [CREATORS_TABLE_COLUMN_NAME]: "Name",
  [CREATORS_TABLE_COLUMN_CITY]: "City",
  [CREATORS_TABLE_COLUMN_AUDIENCE]: "Audience",
  [CREATORS_TABLE_COLUMN_ENGAGEMENT]: "Engagement",
  [CREATORS_TABLE_COLUMN_CATEGORIES]: "Categories",
  [CREATORS_TABLE_COLUMN_DATE]: "Date",
  [CREATORS_TABLE_COLUMN_SCORE]: "Score",
  [CREATORS_TABLE_COLUMN_ACTIVITY]: "Activity",
  [DISCOVER_NEW_CREATORS]: "Discover new creators",

  //AMBASSADORS
  [AMBASSADORS_TITLE]: "Ambassadors",
  [AMBASSADORS_DESCRIPTION]: "Manage your brand's ambassadors",
  [AMBASSADORS_ONBOARDING_FINISH_DESCRIPTION]:
    "You have completed the ambassador tutorial, now you know how to leverage your brand with ambassadors",
  [AMBASSADORS_TABLE_ARIAL_LABEL]: "Brand Ambassadors Table",
  [AMBASSADORS_TABLE_COLUMN_PLATFORM]: "Platform",
  [AMBASSADORS_TABLE_COLUMN_INVITATION]: "Invitation",
  [MODAL_AMBASSADOR_ARIAL_LABEL]: "Ambassador Modal",
  [MODAL_AMBASSADOR_TITLE]: "Edit ambassador",
  [MODAL_AMBASSADOR_DESCRIPTION]:
    "Edit the information of this ambassador of your brand",
  [WITH_RECURRENT_INVITATION_TITLE]: "Recurring invitation (optional)",
  [WITH_RECURRENT_PAYMENT_TITLE]: "Recurring payment (optional)",
  [VALUE_TO_PAY_AMBASSADOR]: "Value in cash to pay the ambassador",
  [VALUE_TO_PAY_AMBASSADOR_CLARIFICATION]:
    "This amount will be debited from your payment method in each cycle you describe below and paid to the creator. {{valueWithFee,currency}} will be debited (+{{fee}}% fee).",
  [RECURRENT_FIELDS_DEFAULT_TITLE]:
    "How often do you want to invite this creator?",
  [RECURRENT_FIELDS_PAYMENT_TITLE]:
    "How often do you want to pay this creator?",
  [RECURRENT_FIELD_REPEAT_COUNT_PLACEHOLDER]: "Every",
  [RECURRENT_FIELD_REPEAT_COUNT_MIN_VALIDATION]:
    "The minimum value is {{value}}",
  [RECURRENT_FIELD_REPEAT_COUNT_MAX_VALIDATION]:
    "The maximum recurrence is {{months}} months or {{weeks}} weeks",
  [RECURRENT_FIELD_FREQUENCY_PLACEHOLDER]: "Choose an option",
  [RECURRENT_FIELD_DAY_AN_PLACEHOLDER]: "A",
  [RECURRENT_FIELD_DAY_ALL_PLACEHOLDER]: "Every",
  [MODAL_BROADCAST_ARIAL_LABEL]: "Broadcast Modal",
  [MODAL_BROADCAST_TITLE]: "Send a broadcast",
  [MODAL_BROADCAST_DESCRIPTION]: "Send a message to all your ambassadors",
  [MODAL_BROADCAST_SELECT_USER_PLACEHOLDER]: "Select users",
  [MODAL_BROADCAST_SELECT_USER_REQUIRED]: "You must select at least one user",
  [MODAL_BROADCAST_MESSAGE_PLACEHOLDER]: "Message",
  [BROADCAST_MESSAGE_SENT]: "Global message sent",
  [MODAL_BROADCAST_CONFIRM_TITLE]: "Send Broadcast",
  [MODAL_BROADCAST_CONFIRM_DESCRIPTION]:
    "Are you sure you want to send a message to all your ambassadors?",
  [MODAL_PAY_AMBASSADORS_ARIAL_LABEL]: "Ambassadors payment modal",
  [MODAL_PAY_AMBASSADORS_TITLE]: "Send a payment",
  [MODAL_PAY_AMBASSADORS_DESCRIPTION]: "Send a payment to your ambassadors",
  [MODAL_PAY_AMBASSADORS_SELECT_USER_PLACEHOLDER]: "Select users",
  [MODAL_PAY_AMBASSADORS_SELECT_USER_REQUIRED]:
    "You must select at least one user",
  [MODAL_PAY_AMBASSADORS_AMOUNT_PLACEHOLDER]: "Payment Amount",
  [MODAL_PAY_AMBASSADOR_AMOUNT_MIN]: "The minimum amount is {{value,currency}}",
  [MODAL_PAY_AMBASSADOR_AMOUNT_INDIVIDUAL_CREATOR]:
    "Each ambassador will receive {{value,currency}}",
  [MODAL_PAY_AMBASSADOR_TITLE_2]: "Payments to ambassadors",
  [PAY_TO_AMBASSADORS]: "Payment to Ambassadors",
  [INFLUENCER_IS_AMBASSADOR]: "This creator is an ambassador for your brand",
  [EDIT_AMBASSADOR]: "Edit ambassador",
  [DO_AMBASSADOR]: "Add as ambassador",
  [DONE_AMBASSADOR]: "The creator has been assigned as an ambassador",
  [MODAL_DO_AMBASSADOR_TITLE]: "Make this creator an ambassador",
  [MODAL_DO_AMBASSADOR_ARIA_LABEL]: "Make this creator an ambassador",
  [MODAL_DO_AMBASSADOR_DESCRIPTION]:
    "If you make this creator an ambassador, you can invite them to collaborate more frequently.",
  [AMBASSADOR_DELETE]: "Delete ambassador",
  [AMBASSADOR_DELETE_DESCRIPTION]: "Are you sure you want to delete this user?",

  //INVITATIONS
  [INVITATIONS_TITLE]: "Invitations",
  [INVITATIONS_DESCRIPTION]: "Manage all your brand's invitations",
  [INVITATIONS_ACTIVE_TITLE]: "Active",
  [INVITATIONS_ACTIVE_TABLE_ARIA_LABEL]: "Active Invitations Table",
  [INVITATIONS_TABLE_COLUMN_CODE]: "Code",
  [INVITATIONS_TABLE_COLUMN_VALUE]: "Value",
  [INVITATIONS_TABLE_COLUMN_USE]: "Use",
  [INVITATIONS_TABLE_COLUMN_EXPIRATION]: "Expiration",
  [INVITATION_REDEEMED]: "Redeemed",
  [INVITATION_REJECTED]: "Rejected",
  [INVITATION_EXPIRED]: "Expired",
  [INVITATIONS_INACTIVE_TITLE]: "Inactive",
  [INVITATIONS_INACTIVE_REDEEM_FILTER]: "Redeemed",
  [INVITATIONS_INACTIVE_EXPIRED_FILTER]: "Expired",
  [INVITATIONS_INACTIVE_TABLE_ARIA_LABEL]: "Inactive invitations table",
  [INVITATIONS_TABLE_COLUMN_TYPE_INVITATION]: "Type",
  [INVITATIONS_TABLE_COLUMN_REJECTED_REASON]: "Rejection reason",
  [INVITATIONS_TABLE_COLUMN_DATE]: "Date",
  [INVITATIONS_EVENT_TABLE]: "Event invitations",
  [INVITATIONS_EVENT_TABLE_ARIA_LABEL]: "Event invitations table",
  [INVITATIONS_EVENT_TABLE_COLUMN_EVENT]: "Event",

  //PUBLICATIONS
  [PUBLICATIONS_TITLE]: "Collaborations",
  [PUBLICATIONS_DESCRIPTION]:
    "Review the collaborations created by content creators about your products",
  [PUBLICATIONS_TABLE_ARIA_LABEL]: "Collaborations table",
  [PUBLICATIONS_TABLE_COLUMN_DATE]: "Date",
  [PUBLICATION_STATUS_PENDING_CONTENT]: "Content Pending",
  [PUBLICATION_STATUS_PENDING_APPROVAL]: "Review",
  [PUBLICATION_STATUS_PENDING]: "Pending Post",
  [PUBLICATION_STATUS_PENDING_SOCIAL]: "Pending Post",
  [PUBLICATIONS_STATUS_PENDING_ANALYTICS]: "Pending Analytics",
  [PUBLICATIONS_STATUS_SENT]: "Review",
  [PUBLICATIONS_STATUS_FEEDBACK]: "Feedback",
  [PUBLICATIONS_STATUS_VERIFIED]: "Verified",

  //PUBLICATION
  [PUBLICATION_TITLE]: "Post",
  [NUMBER_PUBLICATION]: "Post #",
  [FEEDBACK_TITLE]: "Feedback",
  [PUBLICATION_FINISH_ONBOARDING_NEW_BRANDS_DESCRIPTION]:
    "Now you can continue with the store tutorial.",
  [PUBLICATION_FINISH_DESCRIPTION]:
    "You have completed the platform tutorial, and now you know how to generate invitations.",
  [FEEDBACK_PLACEHOLDER]: "How was your experience using the platform?",
  [FEEDBACK_REASON_ABOUT]: "Negative review about {{reason}}",
  [FEEDBACK_REASON]: "Reason for negative review",
  [FEEDBACK_COMMENT]: "Comment",
  [ANALYTICS_EXPLANATION_TITLE]: "How to Interpret Analytics?",
  [ANALYTICS_EXPLANATION_DESCRIPTION]:
    "In analytics, you can determine if the content that the content creator posted about your brand generated results according to your criteria.",
  [ANALYTICS_EXPLANATION_DESCRIPTION_2]:
    "Analytics are generally divided into different sections of information, but the most important ones are the summary and interactions. Here's an explanation of the different data in these sections.",
  [ANALYTICS_EXPLANATION_ACCOUNTS_REACHED_DESCRIPTION]:
    "• <b>Accounts Reached</b>: These are the different social media accounts the post reached. They can be divided into followers and non-followers, whether or not they follow the content creator.",
  [ANALYTICS_EXPLANATION_ENGAGEMENT_DESCRIPTION]:
    "• <b>Engagement</b>: These are the accounts that interacted with the post in some way, such as responses to stories or the number of times the post was shared by other users.",
  [ANALYTICS_EXPLANATION_STICKERS_TAPS_DESCRIPTION]:
    "• <b>Stickers Taps</b>: This represents the number of times users interacted with tags or stickers in a post, allowing them to view your brand's profile.",
  [ANALYTICS_EXPLANATION_NAVIGATION_DESCRIPTION]:
    "• <b>Navigation</b>: It describes the behavior of users with the content the content creator posted, whether they exited the story, moved on to the next without waiting for the completion time, waited for the entire specified time, or went back to view the story again.",
  [RECURRENT_VALUE_NOT_FOUND]:
    "The invitation's recurrence could not be obtained for some reason, please change the values.",
  [MODAL_REVIEW_ARIA_LABEL]: "Modal to review an influencer",
  [MODAL_REVIEW_DISCLAIMER]:
    "Your rating will not be shared with the content creator.",
  [MODAL_REVIEW_SCORE_PLACEHOLDER]:
    "Rate from 1 to 5 the results of this creator according to your expectations.",
  [MODAL_REVIEW_SCORE_CONTENT_PLACEHOLDER]:
    "Rate the quality and production of the content posted.",
  [MODAL_REVIEW_SCORE_CONTENT_MIN_VALUE]:
    "The minimum rating for content is 1 star",
  [MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_TITLE]:
    "How to interpret the content?",
  [MODAL_REVIEW_SCORE_CONTENT_EXPLANATION_DESCRIPTION]:
    "Rate the content creator based on the quality of the post - images, text, or videos about your brand. One star means you are not satisfied with the content and won't use or download it, while five stars mean you are completely satisfied with the content and will use and repost it on your channels or networks.",
  [MODAL_REVIEW_SCORE_ENGAGEMENT_PLACEHOLDER]:
    "Rate the reach and engagement of the posted content.",
  [MODAL_REVIEW_SCORE_ENGAGEMENT_MIN_VALUE]:
    "The minimum rating for statistics is 1 star",
  [MODAL_REVIEW_COMMENT_PLACEHOLDER]:
    "Describe what you thought of the content and the reach of this creator.",
  [MODAL_REVIEW_DO_AMBASSADOR_PLACEHOLDER]:
    "Do you want to collaborate with this creator again?",
  [REVISION_COUNT]: "Revision {{value}}",
  [UPLOAD_NEW_CONTENT]: "Upload new content",
  [ADD_URL_PUBLICATION]: "Add URL",

  //ADS CAMPAIGNS
  [ADS_CAMPAIGNS_TITLE]: "{{NAME_BUSINESS}} Ads",
  [ADS_CAMPAIGNS_DESCRIPTION]:
    "Create & manage social media campaigns and analyze metrics",

  //ADS CAMPAIGN
  [ADS_CAMPAIGN_TITLE]: "Campaign information",
  [ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE]: "Campaign information",
  [ADS_CAMPAIGN_BUDGET_INFORMATION_SUB_TITLE]: "Budget information",
  [ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE]: "Segmentation information",
  [ADS_CAMPAIGN_HAS_BEEN_FINISHED]: "This campaign has ended",
  [ADS_CAMPAIGN_HAS_BEEN_ACTIVATE]: "Campaign activated",
  [ADS_CAMPAIGN_HAS_BEEN_SAVE]: "Campaign saved",
  [ADS_CAMPAIGN_HAS_BEEN_DUPLICATE]: "Campaign duplicated",

  //CREATE ADS CAMPAIGN
  [CREATE_ADS_CAMPAIGN_NAME_QUESTION]:
    "What will be the name of your campaign?",
  [CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER]: "New summer collection",
  [CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION]:
    "What is the objective of your campaign?",
  [CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION]:
    "The objective of your campaign is important to display your ads in the best possible way.",
  [CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION]:
    "Where do you want to direct the traffic of your campaign?",
  [CREATE_ADS_CAMPAIGN_TRACKING_META_QUESTION]:
    "Which pixel do you want to use to track your campaign?",
  [CREATE_ADS_CAMPAIGN_PLATFORM_QUESTION]:
    "On which platform do you want to create the ads?",
  [CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION]:
    "What type of budget do you want to allocate to your campaign?",
  [CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION]:
    "What will be the daily budget of your campaign?",
  [CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION]:
    "What will be the total budget of your campaign?",
  [CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER]: "10,000",
  [CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION]:
    "In which locations do you want your ads to be shown?",
  [CREATE_ADS_CAMPAIGN_AGE_RANGE_QUESTION]:
    "What is the age range of the audience that will see your ads?",
  [CREATE_ADS_CAMPAIGN_GENDERS_QUESTION]:
    "What genders do you want your ads to be shown to?",
  [CREATE_ADS_CAMPAIGN_INTERESTS_QUESTION]:
    "What interests do you want the audience that will see your ads to have?",
  [CREATE_ADS_CAMPAIGN_INTERESTS_PLACEHOLDER]: "Beachwear, vacations, summer",
  [CREATE_ADS_CAMPAIGN_BEHAVIORS_QUESTION]:
    "What behaviors do you want the audience that will see your ads to have?",
  [CREATE_ADS_CAMPAIGN_BEHAVIORS_PLACEHOLDER]:
    "Beachwear shoppers, frequent travelers",
  [CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_QUESTION]:
    "What demographics do you want the audience that will see your ads to have?",
  [CREATE_ADS_CAMPAIGN_DEMOGRAPHICS_PLACEHOLDER]:
    "Recently engaged, upcoming birthday, upcoming anniversary",
  [CREATE_ADS_CAMPAIGN_DESCRIPTION_QUESTION]:
    "What text will accompany your ad?",
  [CREATE_ADS_CAMPAIGN_DESCRIPTION_PLACEHOLDER]:
    "The new summer collection is here, elevate your beach style with our outfits. Discover them now!",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION]: "Tell us about your campaign",
  [CREATE_ADS_CAMPAIGN_CONTEXT_DESCRIPTION]: `Write a paragraph detailing the product or service you want to promote, the main message and the target audience you want to reach.`,
  [CREATE_ADS_CAMPAIGN_CONTEXT_PLACEHOLDER]:
    "My campaign is to promote my new summer collection that I just launched. This collection is perfect for beach vacations, with swimsuits, shorts and beach accessories. It is ideal for those men and women who are looking for a fresh and modern look.",
  [CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION]:
    "What content do you want to promote?",
  [CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION]:
    "Getting required information",
  [CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION]: "Analyzing information",
  [CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS]:
    "Generating recommendations",
  [CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS]: "Finalizing details",
  [CREATE_ADS_CAMPAIGN_INTRO_TITLE]: "{{NAME_BUSINESS}} Ads",
  [CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION]:
    "A powerful solution for the creation, optimization and management of your campaigns across multiple platforms.",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN]:
    "Create ad campaigns in minutes",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN]:
    "Create ad campaigns in minutes",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI]:
    "Optimize your campaigns with AI",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE]:
    "Manage your campaigns easily",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE]:
    "Analyze the performance of your campaigns",
  [CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE]:
    "And much more! All in one place.",
  [CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION]:
    "What are you waiting for to get started? Create your campaign now!",
  [CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_BUTTON]: "Create campaign",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_PRODUCT_ADVERTISE]:
    "- What is the product or service you want to promote?",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_COMMUNICATE]:
    "- What is the main message you want to communicate?",
  [CREATE_ADS_CAMPAIGN_CONTEXT_QUESTION_SEGMENTATION]:
    "- What is the target audience you want to reach?",

  // CAMPAIGNS
  [CAMPAIGNS_TITLE]: "Campaigns",
  [CAMPAIGNS_DESCRIPTION]:
    "Create your campaign, select & pay applicants and approve the content",
  [CAMPAIGNS_SEARCHBAR_PLACEHOLDER]: "Search campaigns",
  [CAMPAIGNS_TABLE_COLUMN_NAME]: "Name",
  [CAMPAIGNS_TABLE_COLUMN_PLATFORMS]: "Platforms",
  [CAMPAIGNS_TABLE_COLUMN_STATUS]: "Status",
  [CAMPAIGN_ACTIVATE]: "Activate campaign",
  [CAMPAIGN_DEACTIVATE]: "Deactivate campaign",
  [CAMPAIGN_ACTIVATE_TIME_CONFIRMATION]:
    "Are you sure you want to activate this campaign? Your campaign will be active for a minimum of 24 hours.",
  [CAMPAIGN_DEACTIVATE_TIME]:
    "You must wait 24 hours to deactivate the campaign since its last activation.",
  [CAMPAIGN_FINISHED_TIME]:
    "You must wait 24 hours to end the campaign since its last activation.",
  [CAMPAIGN_DEACTIVATE_APPLICANTS_ACCEPTED]:
    "You cannot deactivate the campaign until all accepted applicants have submitted their content.",
  [CAMPAIGN_FINISH_APPLICANTS_ACCEPTED]:
    "You cannot finish the campaign until all accepted applicants have submitted their content.",
  [CAMPAIGN_ACTIVATE_FIELDS_REQUIRED]:
    "This campaign has empty required fields. Complete them to activate it.",
  [CAMPAIGN_ACTIVATE_MIN_BUDGET_CREATOR]:
    "The budget for each content creator should not be less than {{value,currency}}.",
  [CAMPAIGN_ACTIVATE_TRAFFIC_LINK]: "The traffic link must be a valid URL.",
  [CAMPAIGN_ACTIVATE_HAS_COLLABORATION_USER_NAME_INSTAGRAM]:
    "The Instagram username is required if you will make a collaboration.",
  [CAMPAIGN_ACTIVATE_ATTACHMENT_LINK]:
    "The reference link of the campaign must be a valid URL.",
  [CAMPAIGN_FINISHED_CONFIRMATION]:
    "Are you sure you want to finish this campaign?",
  [CAMPAIGN_HAS_BEEN_FINISHED]: "The campaign has been finished.",
  [CAMPAIGN_FINISH_ONBOARDING]:
    "You have finished the tutorial, now you know how to generate more personalized collaborations with content creators.",

  // CAMPAIGN DETAIL
  [CAMPAIGN_DETAIL_TITLE]: "Campaign {{name}}",
  [CAMPAIGN_DETAIL_APPLICANTS_ACCEPTED_TITLE]: "Participants",
  [CAMPAIGN_DETAIL_APPLICANTS_PENDING_TITLE]: "Requests",
  [CAMPAIGN_DETAIL_APPLICANTS_INVITED_TITLE]: "Invited",
  [CAMPAIGN_DETAIL_MODAL_SEND_INVITE_PLACEHOLDER]:
    "Does the creator need to receive a product from your brand?",
  [CAMPAIGN_DETAIL_MODAL_SEND_INVITE_VALUE_INVITATION_PLACEHOLDER]:
    "Value of the product in your catalog",
  [CAMPAIGN_DETAIL_MODAL_SEND_INVITE_COMMENT_INVITATION]:
    "For content instructions, review the campaign details.",
  [CAMPAIGN_DETAIL_CREATORS_INVITED_TITLE]: "Creators invited",
  [CAMPAIGN_DETAIL_CAMPAIGN_INFORMATION_TITLE]: "Campaign information",
  [CAMPAIGN_DETAIL_APPLICANT_PAYMENT_DESCRIPTION]:
    "Your payment has been successful. Now you must wait for {{influencerName}} to generate the content for your approval.",
  [CAMPAIGN_DETAIL_APPLICANT_PAYMENT_ADDITIONAL_DESCRIPTION]:
    "In addition, you can send a product redemption invitation to the content creator so that they can access your products for free.",
  [CAMPAIGN_DETAIL_REJECT_APPLICANT_CONFIRMATION]:
    "Are you sure you want to reject this applicant?",
  [CAMPAIGN_DETAIL_INVITE_CREATOR_DESCRIPTION]:
    "The invitation to {{influencerName}} will be sent, and you will be able to see whether or not they accept the invitation to your campaign.",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_DESCRIPTION]:
    "We couldn't find the creator on our platform. If you want to invite them, please select the following fields and click the Invite button.",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_PLACEHOLDER]: "Invitation Budget",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_ACCOUNT_NAME_REQUIRED]:
    "You must enter the content creator's username in the search.",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_SOCIAL_NETWORK_REQUIRED]:
    "You must select a social network.",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_REQUIRED]:
    "You must assign a budget.",
  [CAMPAIGN_DETAIL_INVITE_EXTERNAL_BUDGET_MIN_VALUE]:
    "The minimum budget to invite a creator is {{value,currency}}.",
  [CAMPAIGN_FIELDS_REQUIRED]:
    "Fields that are not advanced settings are required.",
  [CAMPAIGN_NAME_PLACEHOLDER]: "Campaign name",
  [CAMPAIGN_DESCRIPTION_PLACEHOLDER]: "Campaign description",
  [CAMPAIGN_GOAL_PLACEHOLDER]: "Campaign goal",
  [CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER]:
    "Number of content creators for your campaign",
  [CAMPAIGN_TYPE_INFLUENCERS_PLACEHOLDER]:
    "Minimum audience size for content creators",
  [CAMPAIGN_BUDGET_PLACEHOLDER]: "Individual budget per content",
  [CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER]:
    "With content published on social networks",
  [CAMPAIGN_PLATFORMS_PLACEHOLDER]: "Platforms for your campaign",
  [CAMPAIGN_TYPE_CONTENT_MEDIA_PLACEHOLDER]:
    "Type of content for your campaign",
  [CAMPAIGN_TYPE_CONTENT_PLACEHOLDER]: "Formats for your campaign",
  [CAMPAIGN_COPY_POST_PLACEHOLDER]: "Copy to accompany the content (optional)",
  [CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER]:
    "Link to where you want to direct traffic",
  [CAMPAIGN_HAS_COLLABORATION_PLACEHOLDER]:
    "I want the content to be posted with an Instagram collaboration",
  [CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER]:
    "Main product attributes to communicate",
  [CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER]:
    "Message to be communicated with the content",
  [CAMPAIGN_DATES_START_DATE_PLACEHOLDER]: "Campaign start date",
  [CAMPAIGN_DATES_END_DATE_PLACEHOLDER]: "Campaign end date",
  [CAMPAIGN_REPOST_PLACEHOLDER]:
    "Do you want the content creator to tag your profile?",
  [CAMPAIGN_REPOST_PLACEHOLDER_ITEM]: "{{platform}}'s username",
  [CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER]:
    "Cities of interest to impact with your campaign",
  [CAMPAIGN_AGE_RANGE_PLACEHOLDER]:
    "Age range for content creators for your campaign",
  [CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER]:
    "Themes to consider for profile search",
  [CAMPAIGN_BRIEF_PLACEHOLDER]: "Brand brief",
  [CAMPAIGN_BRIEF_DESCRIPTION]:
    "It is a small creative document with all the detailed information and action guide for the campaign, must be in PDF format.",
  [CAMPAIGN_ATTACHMENT_FILE_PLACEHOLDER]: "Campaign content reference",
  [CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION]:
    "An image or video that serves as a reference for content creators on how the campaign will be.",
  [CAMPAIGN_ATTACHMENT_LINK_TITLE]: "Campaign link reference",
  [CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION]:
    "A link to another page with content that serves as a reference for content creators on how the campaign will be.",
  [CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER]: "Reference link",
  [CAMPAIGN_GENDERS_PLACEHOLDER]: "Genders for the campaign",
  [CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER]:
    "Profile(s) that can serve as reference",
  [CAMPAIGN_HASHTAG_PLACEHOLDER]: "Campaign hashtag",
  [CAMPAIGN_MESSAGE_TONE_PLACEHOLDER]: "Tone of your campaign message",
  [CAMPAIGN_ENVIRONMENT_PLACEHOLDER]:
    "Environment where content will be created",
  [CAMPAIGN_COLORS_CONTENT_PLACEHOLDER]: "Colors to appear in the content",
  [CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER]:
    "Colors not to appear in the content",
  [CAMPAIGN_KEY_WORDS_PLACEHOLDER]: "Keywords for your campaign",
  [CAMPAIGN_PROHIBITED_KEY_WORDS_PLACEHOLDER]:
    "Words not to be used during the campaign",
  [CAMPAIGN_OUTFIT_PLACEHOLDER]: "Recommended outfit",

  // CREATE CAMPAIGN
  [CREATE_CAMPAIGN_QUESTION]: "Question {{question}}",
  [CREATE_CAMPAIGN_ACCEPT_TERMS_DISCLAIMER]: `By creating a campaign, you are accepting {{NAME_BUSINESS}}'s terms and conditions.`,
  [CREATE_CAMPAIGN_NAME_EXAMPLE]: "Example campaign",
  [CREATE_CAMPAIGN_NAME_REQUIRED]: "Name is required",
  [CREATE_CAMPAIGN_DESCRIPTION_REQUIRED]: "Description is required",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_REQUIRED]:
    "The number of content creators is required",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCER_MIN_VALUE]:
    "The number of content creators should not be less than {{value}}.",
  [CREATE_CAMPAIGN_BUDGET_REQUIRED]: "Budget is required",
  [CREATE_CAMPAIGN_BUDGET_MIN_VALUE]:
    "The budget for each content creator should not be less than {{value,currency}}",
  [CREATE_CAMPAIGN_PLATFORMS_REQUIRED]: "You must select at least one platform",
  [CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA]:
    "You must select at least one type of content",
  [CREATE_CAMPAIGN_TYPE_CONTENT]: "You must select at least one format",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_REQUIRED]:
    "The link to where you want to drive traffic is required.",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_VALID]:
    "The link to where you want to drive traffic must be a valid URL.",
  [CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_REQUIRED]:
    "Product attributes are required",
  [CREATE_CAMPAIGN_MESSAGE_CONTENT_REQUIRED]:
    "The message to be communicated with the content is required",
  [CREATE_CAMPAIGN_DATES_REQUIRED]: "Both dates are required",
  [CREATE_CAMPAIGN_REPOST_HAS_COLLABORATION]:
    "As you will make a collaboration on Instagram, the Instagram username is required.",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_REQUIRED]: "Cities are required",
  [CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS]:
    "The categories for the profile search are as follows",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK]: "The URL must be valid",
  [CREATE_CAMPAIGN_HAS_BEEN_ACTIVATE]: "Campaign activated",
  [CREATE_CAMPAIGN_HAS_BEEN_SAVE]: "Campaign saved",
  [CREATE_CAMPAIGN_SAVE_PROGRESS_NAME_REQUIRED]:
    "To save the progress, you must at least enter the campaign name",
  [CREATE_CAMPAIGN_SAVE_PROGRESS]: "Your progress has been saved",
  [CREATE_CAMPAIGN_NAME_QUESTION]: "What will be the name of your campaign?",
  [CREATE_CAMPAIGN_NAME_PLACEHOLDER]: "New X shoe collection",
  [CREATE_CAMPAIGN_DESCRIPTION_QUESTION]:
    "Create an eye-catching and clear description of your campaign to encourage creators to participate!",
  [CREATE_CAMPAIGN_DESCRIPTION_PLACEHOLDER]:
    "The X shoe collection that will revolutionize the footwear market",
  [CREATE_CAMPAIGN_GOAL_QUESTION]: "What is the goal of your campaign?",
  [CREATE_CAMPAIGN_GOAL_PLACEHOLDER]: "Select an option",
  [CREATE_CAMPAIGN_GOAL_BRAND_AWARENESS_OPTION]: "Brand awareness",
  [CREATE_CAMPAIGN_GOAL_ENGAGEMENT_OPTION]: "Engagement",
  [CREATE_CAMPAIGN_GOAL_LEADS_OPTION]: "Leads",
  [CREATE_CAMPAIGN_GOAL_SALES_OPTION]: "Sales conversion",
  [CREATE_CAMPAIGN_GOAL_REQUIRED]: "You must select a goal",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_QUESTION]:
    "How many content creators do you need for your campaign?",
  [CREATE_CAMPAIGN_AMOUNT_INFLUENCERS_PLACEHOLDER]: "10",
  [CREATE_CAMPAIGN_TYPE_INFLUENCERS_QUESTION]:
    "What is the minimum audience size required for content creators?",
  [CREATE_CAMPAIGN_TYPE_INFLUENCERS_DESCRIPTION]:
    "The minimum amount you will pay each creator with this audience will be {{value,currency}}",
  [CREATE_CAMPAIGN_BUDGET_QUESTION]:
    "How much are you willing to pay each content creator?",
  [CREATE_CAMPAIGN_BUDGET_DESCRIPTION]:
    "Based on the number of creators you need ({{quantity}}), we suggest a budget of {{budget,currency}}",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_QUESTION]: "Campaign type",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_DESCRIPTION]:
    "Mention on social networks means creators will post content mentioning your account. If you select no, you will simply receive the content.",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_PLACEHOLDER]:
    "Select an option",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_UGC_OPTION]:
    "UGC - User-generated content only",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_INFLUENCER_OPTION]:
    "Influencer - Get the content plus social media exposure",
  [CREATE_CAMPAIGN_WITH_CONTENT_IN_SOCIAL_NETWORK_REQUIRED]:
    "You must select an option",
  [CREATE_CAMPAIGN_PLATFORMS]: "Which platforms do you want for your campaign?",
  [CREATE_CAMPAIGN_TYPE_CONTENT_MEDIA_QUESTION]:
    "What will be the type of content for your campaign?",
  [CREATE_CAMPAIGN_TYPE_CONTENT_QUESTION]:
    "What will be the format for your campaign?",
  [CREATE_CAMPAIGN_COPYWRITING_QUESTION]:
    "What would be the “copy“ text that would accompany the content? (Optional)",
  [CREATE_CAMPAIGN_COPYWRITING_PLACEHOLDER]:
    "The new X Shoes collection has been launched, dare to try them!",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_QUESTION]:
    "What is the link where you want to take the traffic?",
  [CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER]: "https://www.yourbrand.com",
  [CREATE_CAMPAIGN_HAS_COLLABORATION_QUESTION]:
    "Do you want content to be published with an Instagram collaboration?",
  [CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_QUESTION]:
    "What are the main attributes of the product or service to be communicated with the content?",
  [CREATE_CAMPAIGN_PRODUCT_ATTRIBUTES_PLACEHOLDER]:
    "Novel, revolutionary, original, singular, etc.",
  [CREATE_CAMPAIGN_MESSAGE_CONTENT_QUESTION]:
    "What is the message to be communicated with the content?",
  [CREATE_CAMPAIGN_MESSAGE_CONTENT_PLACEHOLDER]:
    "It must be communicated that there is no other product like it on the market, because it is a new trend.",
  [CREATE_CAMPAIGN_DATES_QUESTION]:
    "What will be the start and end date of your campaign?",
  [CREATE_CAMPAIGN_DATES_DESCRIPTION]:
    "The duration of the campaign must be at least one week, so there is a mandatory space between the start date and the end date, and it must be created at least one week in advance.",
  [CREATE_CAMPAIGN_DATES_START_DATE_PLACEHOLDER]: "Campaign start date",
  [CREATE_CAMPAIGN_DATES_END_DATE_PLACEHOLDER]: "Campaign end date",
  [CREATE_CAMPAIGN_REPOST_QUESTION]:
    "If you want the content creator to tag your profile type the @. Otherwise, click next",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_QUESTION]:
    "Which are the cities of interest where you want to impact with your campaign?",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_PLACEHOLDER]: "Select cities",
  [CREATE_CAMPAIGN_AUDIENCE_LOCATION_ALL_OPTION]: "All",
  [CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_QUESTION]:
    "What are the categories of content creators you need for your campaign?",
  [CREATE_CAMPAIGN_CATEGORIES_INFLUENCERS_PLACEHOLDER]: "Select categories",
  [CREATE_CAMPAIGN_AGE_RANGE_QUESTION]:
    "What is the age range that content creators should be for your campaign?",
  [CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_QUESTION]:
    "What content style do you want for your campaign?",
  [CREATE_CAMPAIGN_UGC_CONTENT_FORMAT_PLACEHOLDER]: "Select a style",
  [CREATE_CAMPAIGN_BRIEF_QUESTION]: "Upload the brand brief",
  [CREATE_CAMPAIGN_BRIEF_DESCRIPTION]:
    "It is a small creative document with all the detailed information and action guide for the campaign, must be in PDF format.",
  [CREATE_CAMPAIGN_UPLOAD_BRIEF]: "Upload brief",
  [CREATE_CAMPAIGN_REMOVED_BRIEF]: "Remove brief",
  [CREATE_CAMPAIGN_ATTACHMENT_FILE_QUESTION]:
    "Upload a campaign content reference",
  [CREATE_CAMPAIGN_ATTACHMENT_FILE_DESCRIPTION]:
    "An image or video that serves as a reference for the content creators of how the content creators of what the campaign will look like.",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK_QUESTION]:
    "Upload a reference to the campaign's content link",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK_DESCRIPTION]:
    "A link to another page with content that will serve as a reference for the content content creators of what the campaign will look like.",
  [CREATE_CAMPAIGN_ATTACHMENT_LINK_PLACEHOLDER]: "https://www.yourbrand.com/",
  [CREATE_CAMPAIGN_QUANTITY_GENDER_QUESTION]:
    "What genres do you need for your campaign?",
  [CREATE_CAMPAIGN_REFERENCE_PROFILES_QUESTION]:
    "Do you have @ profile(s) that can serve as reference?",
  [CREATE_CAMPAIGN_REFERENCE_PROFILES_PLACEHOLDER]:
    "@xavi, @laura, @daniel, @maria, etc.",
  [CREATE_CAMPAIGN_HASHTAG_QUESTION]:
    "What will be the hashtag for your campaign?",
  [CREATE_CAMPAIGN_HASHTAG_PLACEHOLDER]: "#ShoesXTrend",
  [CREATE_CAMPAIGN_MESSAGE_TONE_QUESTION]:
    "How should the tone of your campaign message be?",
  [CREATE_CAMPAIGN_MESSAGE_TONE_PLACEHOLDER]:
    "Serious, jocular, calm, free, etc.",
  [CREATE_CAMPAIGN_ENVIRONMENT_QUESTION]:
    "What should the environment where the content will be made look like?",
  [CREATE_CAMPAIGN_ENVIRONMENT_PLACEHOLDER]:
    "Household, natural, commercial, free, etc.",
  [CREATE_CAMPAIGN_COLORS_CONTENT_QUESTION]:
    "What colors should be in the content?",
  [CREATE_CAMPAIGN_COLORS_CONTENT_PLACEHOLDER]: "Blue and white",
  [CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_QUESTION]:
    "What colors can NOT be in the content?",
  [CREATE_CAMPAIGN_PROHIBITED_COLORS_CONTENT_PLACEHOLDER]: "Red and black",
  [CREATE_CAMPAIGN_KEY_WORDS_QUESTION]:
    "What are the keywords of your campaign?",
  [CREATE_CAMPAIGN_KEY_WORDS_PLACEHOLDER]: "Revolutionary, trendy, new, etc.",
  [CREATE_CAMPAIGN_PROHIBITED_WORDS_QUESTION]:
    "What NOT to say during the campaign?",
  [CREATE_CAMPAIGN_PROHIBITED_WORDS_PLACEHOLDER]:
    "Words, details, reference to other brands, etc.",
  [CREATE_CAMPAIGN_OUTFIT_QUESTION]: "Is there any recommended outfit?",
  [CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER]: "Recommended outfit",
  [CREATE_CAMPAIGN_OUTFIT_PLACEHOLDER]:
    "What should or should not be shown in the physical",
  [CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE]:
    "¡Ready! Here is your content script",
  [CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_TITLE_GENERATING]:
    "We are generating a content script for your campaign...",
  [CREATE_CAMPAIGN_MODAL_GENERATE_SCRIPT_AND_DESCRIPTION_SCRIPT_LABEL]:
    "Campaign script",

  //APPLICANTS
  [APPLICANTS_STATUS_PENDING]: "Pending",
  [APPLICANTS_STATUS_ACCEPTED]: "Content pending",
  [APPLICANTS_STATUS_REJECTED]: "Rejected",
  [APPLICANTS_STATUS_CONTENT_SUBMITTED]: "Content to review",
  [APPLICANTS_STATUS_CONTENT_REVISIONS]: "Content submitted",
  [APPLICANTS_STATUS_CONTENT_PENDING_SOCIAL]: "Pending social media posting",
  [APPLICANTS_STATUS_CONTENT_PENDING_ANALYTICS]: "Pending analytics",
  [APPLICANTS_STATUS_DELIVERED]: "Content ready",
  [APPLICANT_CONTENT_OF_CREATOR]: "Content of {{influencerName}}",
  [APPLICANT_REVISION_PLACEHOLDER]: "Send a review to the content creator",

  //REPORTS SHOP
  [REPORT_SHOP_DESCRIPTION]:
    "Here you will find the monthly report of your collaborations",
  [START_DATE_AND_END_DATE_VALIDATION]:
    "The start date cannot be greater than the end date.",
  [START_DATE_PLACEHOLDER]: "Start date",
  [END_DATE_PLACEHOLDER]: "End date",
  [GLOBAL_REPORT_BUSINESS]: "Global report of {{businessName}}",
  [INSUFFICIENT_DATA_REPORT]:
    "Not enough information to generate the report, try another date range",
  [TOTAL_INVESTMENT]: "Total investment",
  [TOTAL_EXPENSES]: "Total expenses",
  [COSTS_PER_1000_VIEWS]: "Cost per 1,000 Views",
  [COSTS_PER_ENGAGEMENT]: "Cost per engagement",
  [ACCOUNTS_REACHED]: "Accounts reached",
  [LIKES]: "Likes",
  [SHARES]: "Shares",
  [SAVES]: "Saves",
  [REPLIES]: "Replies",
  [STICKERS_TAPS]: "Stickers taps",
  [COLLABORATIONS_SUMMARY]: "Collaborations summary",
  [PENDING_PUBLICATIONS]: "Pending posts: {{count}}",
  [PENDING_ANALYTICS_PUBLICATIONS]: "Pending analytics posts: {{count}}",
  [FEEDBACK_PUBLICATIONS]: "Feedback: {{count}}",
  [INVITATIONS_SUMMARY]: "Invitations summary",
  [TOTAL_INVITATIONS]: "Total invitations: {{count}}",
  [INVITATIONS_REDEEMED]: "Redeemed: {{count}}",
  [INVITATIONS_REJECTED]: "Rejected: {{count}}",
  [INVITATIONS_EXPIRED]: "Expired: {{count}}",
  [INVITATIONS_ACCEPTANCE_RATE]:
    "Acceptance rate: {{percentage}}% (1 out of every {{ratio}} invitations)",
  [COLLABORATIONS_FOR_PLATFORM]: "Collaborations for platform",
  [FEATURED_CONTENT]: "Featured content",
  [EMPTY_IMAGES_FOR_COMMENT]: "No images for comment",
  [UPDATING_ANALYTICS]: "Updating analytics",

  // DISCOVERY INFLUENCERS
  [DISCOVERY_INFLUENCERS_TITLE]: "Discover Content Creators",
  [DISCOVERY_INFLUENCERS_DESCRIPTION]: `Search for content creators outside of {{NAME_BUSINESS}} using various parameters`,
  [DISCOVERY_INFLUENCERS_DONE_ONBOARDING]:
    "You have completed the content creator exploration tutorial, and now you know how to search for creators based on your requirements.",
  [DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_WITH_PARAMETERS]:
    "No creators were found with these parameters.",
  [DISCOVERY_INFLUENCERS_NO_FOUND_RESULTS_FAILED]:
    "An error occurred. The statistics service is being updated. Please try again later.",
  [DISCOVERY_INFLUENCERS_INVITE_SENT_DESCRIPTION]:
    "The invitation to {{accountName}} will be sent, and we will notify you if it's accepted.",
  [DISCOVERY_INFLUENCERS_ACCOUNT_NAME_PLACEHOLDER]: "Username",
  [DISCOVERY_INFLUENCERS_PLATFORM_PLACEHOLDER]: "Platform",
  [DISCOVERY_INFLUENCERS_LOCATIONS_PLACEHOLDER]: "Creator's location",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_PLACEHOLDER]: "Minimum followers",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_MIN_VALUE]:
    "The minimum number of followers is {{value,number}}",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_REQUIRED]:
    "This value cannot be empty if the maximum number of followers has a value",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_AND_MAX_FOLLOWERS_REQUIRED]:
    "The maximum number of followers cannot be 0",
  [DISCOVERY_INFLUENCERS_MIN_FOLLOWERS_GREATER_THAN_MAX_FOLLOWERS]:
    "The minimum number of followers cannot be greater than the maximum",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_PLACEHOLDER]: "Maximum followers",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_MIN_VALUE]:
    "The minimum number of followers is {{value,number}}",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_REQUIRED]:
    "This value cannot be empty if the minimum number of followers has a value",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_AND_MIN_FOLLOWERS_REQUIRED]:
    "The maximum number of followers cannot be 0",
  [DISCOVERY_INFLUENCERS_MAX_FOLLOWERS_LESS_THAN_MIN_FOLLOWERS]:
    "The maximum number of followers cannot be less than the minimum",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_PLACEHOLDER]: "Engagement",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_MIN_VALUE]:
    "The minimum engagement value is {{value,number}}",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_MAX_VALUE]:
    "The maximum engagement value is {{value,number}}",
  [DISCOVERY_INFLUENCERS_ENGAGEMENT_TYPE]:
    "Only numbers are allowed with a maximum of 2 decimal places",
  [DISCOVERY_INFLUENCERS_CREATOR_GENDER_PLACEHOLDER]: "Creator's gender",
  [DISCOVERY_INFLUENCERS_CREATOR_AGE_PLACEHOLDER]: "Creator's age",
  [DISCOVERY_INFLUENCERS_AUDIENCE_GENDER_PLACEHOLDER]: "Audience's gender",
  [DISCOVERY_INFLUENCERS_AUDIENCE_AGE_PLACEHOLDER]: "Audience's age",
  [DISCOVERY_INFLUENCERS_AUDIENCE_INTERESTS_PLACEHOLDER]:
    "Audience's interests",
  [DISCOVERY_INFLUENCERS_KEYWORDS_PLACEHOLDER]: "Keywords",
  [DISCOVERY_INFLUENCERS_TABLE_ARIA_LABEL]: "Found creators table",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_ACCOUNT_NAME]: "Username",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_LOCATION]: "Location",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_FOLLOWERS]: "Followers",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_ENGAGEMENT]: "Engagement",
  [DISCOVERY_INFLUENCERS_TABLE_COLUMN_AVG_LIKES]: "Average likes",
  [DISCOVERY_INFLUENCERS_INFLUENCER_IS_INVITED]: "Already invited",
  [DISCOVERY_INFLUENCERS_STATISTICS_NO_FOUND]: "Metrics not loaded",
  [DISCOVERY_INFLUENCERS_NO_MORE_INFLUENCERS]: "No more creators",

  //GALLERY
  [MODAL_GALLERY_ARIA_LABEL]: "Brand content gallery modal",
  [MODAL_GALLERY_EMPTY_CONTENT]:
    "No content has been created for your brand yet. Invite content creators to get started.",
  [MODAL_GALLERY_NO_MORE_CONTENT]: "No more content to display",

  //MODAL_MEDIA
  [MODAL_MEDIA_ARIA_LABEL]: "Modal media",

  //MODAL UPLOAD TIKTOK
  [MODAL_UPLOAD_TIKTOK_ARIA_LABEL]: "Upload video to TikTok modal",
  [MODAL_UPLOAD_TIKTOK_TITLE]: "Upload video to TikTok",
  [MODAL_UPLOAD_FAILED_GET_CONFIGURATION]:
    "An error occurred while getting the configuration. Please try again later or contact support.",
  [MODAL_UPLOAD_CONTENT_UPLOADED]: "Content uploaded!",
  [MODAL_UPLOAD_TIKTOK_VIDEO_UPLOADED_DESCRIPTION]:
    "The video has been successfully uploaded to TikTok and will appear on your profile in a moment.",
  [MODAL_UPLOAD_FAILED_GENERATE_DESCRIPTION]:
    "Failed to retrieve collaboration information. Please contact support.",
  [MODAL_UPLOAD_TIKTOK_DESCRIPTION_LABEL]: "Description",
  [MODAL_UPLOAD_TIKTOK_DESCRIPTION_PLACEHOLDER]:
    "Remember to add a catchy description for your video, tag the users appearing in it, and add relevant hashtags.",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_LABEL]: "Who can watch this video?",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_PUBLIC_OPTION]: "Public",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_FOLLOWERS_OPTION]: "Followers",
  [MODAL_UPLOAD_TIKTOK_WHO_CAN_WATCH_ONLY_I_OPTION]: "Only me",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_PLACEHOLDER]: "Allow users to",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_COMMENT_OPTION]: "Comment",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_DUOS_OPTION]: "Duets",
  [MODAL_UPLOAD_TIKTOK_ALLOW_USERS_CUT_OPTION]: "Cut",

  //MODAL UPLOAD INSTAGRAM
  [MODAL_UPLOAD_INSTAGRAM_ARIA_LABEL]: "Upload media to Instagram modal",
  [MODAL_UPLOAD_INSTAGRAM_TITLE]: "Upload media to Instagram",
  [MODAL_UPLOAD_INSTAGRAM_CONTENT_UPLOADED_DESCRIPTION]:
    "The content has been successfully uploaded to Instagram and will appear on your profile in a moment.",
  [MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_LABEL]: "Description",
  [MODAL_UPLOAD_INSTAGRAM_DESCRIPTION_PLACEHOLDER]:
    "Remember to add a catchy description for your content, tag the users appearing in it, and add relevant hashtags.",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_LABEL]: "Type",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_POST_LABEL]: "Post",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_STORY_LABEL]: "Story",
  [MODAL_UPLOAD_INSTAGRAM_TYPE_REEL_LABEL]: "Reel",

  //MODAL MAIN GENERATE SOCIAL MEDIA CONTENT
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL]:
    "Modal for generating weekly content",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE]: `Feed Generator`,
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION]:
    "Generate your weekly social media content with a click",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_LABEL]:
    "What do you want to communicate with this content?",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTEXT_PLACEHOLDER]:
    "I want to communicate that my products/brand is everything the user needs",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WEBSITE_URL_LABEL]: "Your website URL",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_HAS_TEXTS_LABEL]:
    "Do you want the content to have text?",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_ALL_WITH_TEXT]: "All with text",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_SOME_WITH_TEXT]: "Some with text",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_WITHOUT_TEXT]: "None with text",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_LANGUAGE_LABEL]: "Language",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_TONES_LABEL]: "Content tones",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_TEXT_LABEL]: "Text color",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_COLOR_BACKGROUND_LABEL]:
    "Background color",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_HAS_TEXT_LABEL]: "With text",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_FONT_FAMILY_LABEL]: "Font family",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_TEXT_LABEL]: "Text alignment",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALIGN_LOGO_LABEL]: "Logo alignment",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_TITLE]: "Gallery",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_EMPTY]:
    "There is no content yet, add some",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_UPLOAD_OPTION]: "Upload from device",
  [MODAL_MAIN_SOCIAL_MEDIA_CONTENT_GALLERY_FROM_COLLABORATIONS_OPTION]:
    "Upload from {{NAME_BUSINESS}}",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_CONTENTS_REQUIRED]:
    "You must select at least one day",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULE_BUTTON]:
    "Schedule content",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_SCHEDULED_CONTENT]:
    "Scheduled content",
  [MODAL_MAIN_GENERATE_SOCIAL_MEDIA_CONTENT_ALREADY_CONTENT_ON_THIS_DATE]:
    "There is already a content for this date, please select another one",

  //MODAL GENERATE SOCIAL MEDIA CONTENT
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_ARIA_LABEL]:
    "Modal for generating content",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TITLE]: "Generate Content",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_LABEL]: "Choose tone",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_TOP_FIVE_OPTION]: "Top five",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_EDUCATION_OPTION]:
    "Educational",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_QUOTES_OPTION]:
    "Famous quotes",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_CURIOUS_FACTS_OPTION]:
    "Curious facts",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_RHYMES_OR_POEMS_OPTION]:
    "Rhymes or poems",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_SELECT_TONE_HUMOR_OPTION]: "Humor",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_EMPTY_CONTENT]: "No generated content",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_TEXT_LABEL]: "Image text",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_DESCRIPTION]: "Content description",
  [MODAL_GENERATE_SOCIAL_MEDIA_CONTENT_GENERATING_CONTENT]:
    "Generating content",

  //REDEEM CODE
  [REDEEM_CODE_TITLE]: "Redeem Code",
  [REDEEM_CODE_DESCRIPTION]:
    "Enter the code provided by the content creator to validate the value and redeem the product",
  [REDEEM_CODE_SEARCHBAR_PLACEHOLDER]: "Write a code",
  [REDEEM_CODE_TABLE_COLUMN_CODE]: "Code",
  [REDEEM_CODE_TABLE_COLUMN_DATE]: "Redemption date",
  [REDEEM_CODE_TABLE_COLUMN_VALUE]: "Value",
  [REDEEM_CODE_TABLE_COLUMN_TERMS]: "Terms and conditions",
  [CODE_REDEEMED]: "Code redeemed",
  [VALUE_CODE_REDEEMED]: "Value: {{value,currency}}",
  [VALUE_GENERAL_CODE_REDEEMED]: "Value: {{value}}%",
  [CODE_NO_EXIST]: "This code does not exist",
  [CODE_NO_REDEEM_BEFORE_DATE]:
    "You cannot redeem this code yet, you can do it from {{date,short}}.",

  //ORDERS
  [ORDERS_TITLE]: "Orders",
  [ORDERS_DESCRIPTION]: "Manage orders placed by content creators",
  [ORDERS_REMEMBER_MARK_AS_DELIVERED]:
    "Remember to mark your order as 'Delivered' so the creator can upload your content.",
  [ORDERS_SEARCHBAR_PLACEHOLDER]: "Search by order number",
  [ORDERS_TAB_TO_BE_ACCEPTED]: "To be accepted",
  [ORDERS_TAB_TO_BE_ACCEPTED_DESCRIPTION]:
    "Here you will see new orders from guest content creators. Accept them to confirm that you will process them.",
  [ORDERS_TAB_IN_PREPARATION]: "Processing",
  [ORDERS_TAB_IN_PREPARATION_DESCRIPTION]:
    "Here you will see the orders to be processed on your side. When the time expires, a delivery will be ready to pick it up.",
  [ORDERS_TAB_TO_BE_DELIVERED]: "To be delivered",
  [ORDERS_TAB_TO_BE_DELIVERED_DESCRIPTION]:
    "Here you will see the orders ready on your side for a delivery to pick them up.",
  [DELIVERY_INTERN]: "Own",
  [DELIVERY_INTERN_ON_THE_WAY]: "Own delivery on the way",
  [DELIVERY_ON_THE_WAY]: "Delivery {{delivery}} on the way",
  [ORDER_ID]: "Order #{{id}}",
  [ORDER_ACCEPTED]: "Order accepted",
  [ORDER_ACCEPTED_DESCRIPTION]:
    "The order has been accepted and moved to processing",
  [ORDER_READY]: "Order ready",
  [ORDER_READY_DESCRIPTION]:
    "The order is ready and has been moved for delivery.",
  [ORDER_TO_BE_DELIVERED_CONFIRMATION]:
    "Are you sure the order has been delivered?",
  [ORDER_TO_BE_DELIVERED_CONFIRMATION_BUTTON]: "Deliver order",
  [ORDER_CANCELLED_CONFIRMATION]: "Are you sure you want to cancel the order?",
  [ORDER_CANCELLED_CONFIRMATION_BUTTON]: "Cancel order",
  [ORDER_DELIVERED]: "Order delivered",
  [ORDER_DELIVERED_DESCRIPTION]:
    "The order has already been delivered to the user",
  [DELIVERY_SELECTED]: "Selected delivery!",
  [DELIVERY_SELECTED_DESCRIPTION]:
    "Delivery {{delivery}} has been selected for this order.",
  [ORDER_REJECTED]: "Order rejected",
  [ORDER_REJECTED_DESCRIPTION]: "The order has been rejected",
  [ORDER_TOTAL_VALUE]: "Total value",
  [ORDER_TOTAL_VALUE_DISCOUNT]: "With {{value}}% discount",
  [ORDER_LIST_OF_PRODUCTS]: "List of products",
  [ORDER_OPTIONS]: "Options",
  [ORDER_TIME_PREPARATION_TITLE]: "Indicate the delivery time of the order",
  [MIN_VALUE_PREPARATION_ORDER]:
    "This is the minimum time to process this order",
  [MAX_VALUE_PREPARATION_ORDER]:
    "This is the maximum time to process this order",
  [INVALID_TIME_PREPARATION]: "You must choose a valid time type",
  [TIME_PREPARATION_VALUES_VALIDATION]:
    "You must choose a time greater than {{min}} or less than {{max}}",
  [GUIDE_NUMBER]: "Guide number",
  [GUIDE_NUMBER_REQUIRED]: "Guide number is required",
  [DELIVERY_TYPE_DUPLICATE]: "There is already a delivery with this name",
  [SELECT_DELIVERY_TYPE]: "Select a delivery",
  [SELECT_DELIVERY_TYPE_OTHER]: "Other delivery",
  [DELIVERY_DATA]: "Delivery data",
  [OWN_DELIVERY]: "Own delivery",

  //EVENTS
  [EVENT_NO_EXIST]: "The event does not exist",
  [EVENT_DISABLED]: "The {{event}} event is not active",
  [EVENT_INVITATION_NO_BELONG]:
    "This code does not belong to the {{event}} event",
  [EVENT_HAS_FIELDS_REQUIRED]:
    "This event has empty required fields. To activate it, please complete them.",
  [EVENT_RECURRENT_REQUIRED]: "The type of recurrence is required.",

  [TOP_CONTENT_TITLE]: "Top Content",
  [TOP_CONTENT_DESCRIPTION]: "Find out which are the best performing contents.",
  [TOP_CONTENT_NOT_FOUND_IN_INTERVAL]:
    "No collaborations were found in this time interval.",

  //ACCOUNT
  [ACCOUNT_TITLE]: "My Account",
  [ACCOUNT_DESCRIPTION]: "Fill out your company information",

  //COMPANY INFORMATION
  [COMPANY_INFORMATION_TITLE]: "Set up your brand",
  [COMPANY_INFORMATION_SUBCATEGORY_DUPLICATE]:
    "A subcategory with this name already exists",
  [COMPANY_INFORMATION_TYPE_ID_PLACEHOLDER]: "Type of identification",
  [COMPANY_INFORMATION_ID_NUMBER_PLACEHOLDER]: "Identification number",
  [COMPANY_INFORMATION_ID_NUMBER_MIN_LENGTH]:
    "The identification number must have at least {{value}} digits",
  [COMPANY_INFORMATION_ID_NUMBER_TYPE]:
    "The identification number should only consist of digits. If it's a NIT, it should include a hyphen and the verification digit.",
  [COMPANY_INFORMATION_BRAND_NAME_PLACEHOLDER]: "Brand name",
  [COMPANY_INFORMATION_BUSINESS_NAME_PLACEHOLDER]: "Business name",
  [COMPANY_INFORMATION_ADDRESS_PLACEHOLDER]: "Registered address",
  [COMPANY_INFORMATION_AVERAGE_TICKET_PLACEHOLDER]: "Average ticket size",
  [COMPANY_INFORMATION_AVERAGE_TICKET_MIN_VALUE]:
    "The average ticket value must be at least {{value,currency}}",
  [COMPANY_INFORMATION_SOCIAL_NETWORK_PLACEHOLDER]: "Social network",
  [COMPANY_INFORMATION_SECOND_SOCIAL_NETWORK_PLACEHOLDER]:
    "Secondary social network",
  [COMPANY_INFORMATION_WEBSITE_URL_PLACEHOLDER]: "Brand website",
  [COMPANY_INFORMATION_TRAFFIC_URL_PLACEHOLDER]: "Redirect traffic URL",
  [COMPANY_INFORMATION_CATEGORY_PLACEHOLDER]: "Categories",
  [COMPANY_INFORMATION_SUBCATEGORY_PLACEHOLDER]: "Subcategories",
  [COMPANY_INFORMATION_NEW_SUBCATEGORY]: "New subcategory",
  [COMPANY_INFORMATION_SHORT_DESCRIPTION_PLACEHOLDER]:
    "Write a striking description of your brand to encourage creators to collaborate with you",
  [COMPANY_INFORMATION_ADMIN_PHONE_PLACEHOLDER]: "Administrator's phone",
  [COMPANY_INFORMATION_RESERVATION_URL_PLACEHOLDER]: "Booking URL",
  [COMPANY_INFORMATION_UPLOAD_LOGO_PLACEHOLDER]: "Upload brand logo",
  [COMPANY_INFORMATION_UPLOAD_LOGO_DESCRIPTION_PLACEHOLDER]:
    "This will help customers or couriers identify your brand.",
  [COMPANY_INFORMATION_UPLOAD_COVER_PLACEHOLDER]: "Upload cover image",
  [COMPANY_INFORMATION_UPLOAD_COVER_DESCRIPTION_PLACEHOLDER]:
    "This will increase visibility. Use horizontal images or 16:9 aspect ratio for better viewing.",
  [COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_PLACEHOLDER]:
    "Upload regulatory document",
  [COMPANY_INFORMATION_UPLOAD_REGULATORY_DOCUMENT_DESCRIPTION_PLACEHOLDER]:
    "Regulatory document for the sale of food (e.g., Invima, FDA)",
  [COMPANY_INFORMATION_VIEW_DOCUMENT]: "View document",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_TITLE]: "General discount code",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_DESCRIPTION]: `The general discount code is a code that {{NAME_BUSINESS}} creators can use to purchase your products just like any other customer, so no collaboration will be generated.`,
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_1]:
    "If you save a discount percentage and you have an e-commerce integration, a discount code will be created with that percentage.",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_EXPLANATION_2]:
    "If you remove the discount percentage, have an e-commerce integration, and previously had a general code created, it will be deactivated.",

  [COMPANY_INFORMATION_GENERAL_DISCOUNT_PERCENTAGE_PLACEHOLDER]:
    "Discount percentage",
  [COMPANY_INFORMATION_GENERAL_DISCOUNT_CODE_PLACEHOLDER]: "Discount code",
  [COMPANY_INFORMATION_SHOP_ID_COPIED]: "Shop ID copied",
  [COMPANY_INFORMATION_ONBOARDING_FINISH_DESCRIPTION]:
    "Now you will need to complete all the information about your brand in this account section.",
  [COMPANY_INFORMATION_SAVED]: "Company information saved",
  [OPERATOR_USER_STORE]:
    "To delete this store you must not have any operator user associated to it, delete it or associate it to another store.",

  //PROFILE INFLUENCER INFORMATION
  [PROFILE_INFLUENCER_INFORMATION_SAVED]:
    "Content creator profile information saved",
  [PROFILE_INFLUENCER_SAVED_AND_GET_INFLUENCERS_SUGGESTED]:
    "Now you can review your suggested creators",
  [VIEW_SUGGESTED_CREATORS]: "View suggested creators",
  [PROFILE_INFLUENCER_INFORMATION_TITLE]: "Content creator profile",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_DESCRIPTION]:
    "Let's talk about your ideal content creator",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_PLACEHOLDER]:
    "Content creator categories",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CATEGORIES_MIN_VALUE]:
    "At least {{value}} categories must be selected",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_PLACEHOLDER]:
    "Cities where creators are located",
  [PROFILE_INFLUENCER_INFORMATION_AUDIENCE_CITIES_PLACEHOLDER]:
    "Cities where you want to be seen",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_CITIES_MAX_VALUE]:
    "You can select up to {{value}} cities",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_PLACEHOLDER]:
    "Minimum audience",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_RANGE_MIN_VALUE]:
    "The audience must be at least {{value,number}}",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_ENGAGEMENT]:
    "Audience that could engage based on {{value}} engagement",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_PLACEHOLDER]:
    "Minimum engagement",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_ENGAGEMENT_RANGE_MIN_VALUE]:
    "Engagement must be at least {{value,number}}%",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_GENDER_PLACEHOLDER]: "Creator gender",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AGE_RANGE_MAX_VALUE]:
    "Creator age range",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_GENDER_PLACEHOLDER]:
    "Audience gender",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_PLACEHOLDER]:
    "Audience age range",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AUDIENCE_AGE_RANGE_MAX_VALUE]:
    "You can select up to {{value}} options",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_PLATFORMS_PLACEHOLDER]: "Platforms",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_PLACEHOLDER]:
    "Average ticket value",
  [PROFILE_INFLUENCER_INFORMATION_CREATOR_AVERAGE_TICKET_MIN_VALUE]:
    "The average ticket value must be at least {{value,currency}}",
  [PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_DESCRIPTION]:
    "Reference profiles",
  [PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION1]:
    " - To add a reference profile, you need to view their metrics by selecting 'Invite' and 'View Metrics'. Then click on the 'Add reference profile.",
  [PROFILE_INFLUENCER_INFORMATION_REFERENCE_PROFILES_EXPLANATION2]:
    "- You can have a maximum of three reference profiles.",

  //AUTOMATIC INVITES
  [AUTOMATIC_INVITES_TITLE]: "Gifting configuration",
  [AUTOMATIC_INVITES_ACTIVATE]: "Auto-invites activated",
  [AUTOMATIC_INVITES_DEACTIVATE]: "Auto-invites deactivated",
  [AUTOMATIC_INVITES_DESCRIPTION]:
    "Let us do the work, we'll send your invitations automatically",
  [AUTOMATIC_INVITES_BUDGET_DESCRIPTION]: "Budget",
  [AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_PLACEHOLDER]:
    "Number of contents per month",
  [AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_MIN_AND_MAX_VALUE]:
    "The number of contents is a minimum of {{min}} and a maximum of {{max}}",
  [AUTOMATIC_INVITES_BUDGET_NUMBER_INVITATIONS_RECOMMENDATION]:
    "Social media algorithms value quantity of content more than quality. We suggest a minimum of 10 contents, but we recommend 25 for best results.",
  [AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_PLACEHOLDER]:
    "Total cost worth of products per month",
  [AUTOMATIC_INVITES_BUDGET_BUDGET_MONTHLY_MIN_VALUE]:
    "The total cost of products cannot be less than {{value,currency}}",
  [AUTOMATIC_INVITES_BUDGET_BUDGETS_DESCRIPTION]:
    "Add invitation budgets for each audience you are interested in",
  [AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION1]:
    "- If you want to set a general budget for your invitations, select the entire range (from 1K to 500K) and enter the budget.",
  [AUTOMATIC_INVITES_BUDGET_BUDGETS_EXPLANATION2]:
    "- If you enter 500K (the maximum) in a range, all creators with an audience of over 500K will be included.",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE]: "Audience #{{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_AUDIENCE_RANGE]:
    "Audience range #{{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_ENGAGEMENT_RANGE]:
    "Engagement range #{{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE]:
    "Budget for audience #{{value}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE]:
    "The minimum budget is {{value,currency}}",
  [AUTOMATIC_INVITES_BUDGET_INDIVIDUAL_BUDGET_AUDIENCE_MIN_VALUE_PRODUCT]:
    "Your lowest-priced product is {{value,currency}} {{currency}} and you cannot offer a budget lower than this value.",
  [AUTOMATIC_INVITES_OPTIONAL_DESCRIPTION]: "Content personalization",
  [AUTOMATIC_INVITES_OPTIONAL_COMMENT_PLACEHOLDER]:
    "Comment for content creators",
  [AUTOMATIC_INVITES_OPTIONAL_TERMS_PLACEHOLDER]:
    "Terms and conditions of your gifting",
  [AUTOMATIC_INVITES_OPTIONAL_FORMATS_PLACEHOLDER]:
    "Select the required content format",
  [AUTOMATIC_INVITES_OPTIONAL_UPLOAD_CONTENT_REFERENCE_PLACEHOLDER]:
    "Reference content url",
  [AUTOMATIC_INVITES_TOOLTIP_TITLE]: "Gifting configuration",
  [AUTOMATIC_INVITES_TOOLTIP_DESCRIPTION]:
    "In this section, you can configure key parameters related to the gifts that will be sent to content creators. All settings you make will be set by default in the invitations you send or those that are automatically sent <strong>only if you activate this option.</strong>",
  [AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_TITLE]:
    "Number of gifts per month",
  [AUTOMATIC_INVITES_TOOLTIP_NUMBER_EXCHANGES_DESCRIPTION]:
    "<strong>If you activate automatic invitations</strong>, this will be the maximum number of gifts that will be sent to content creators each month.",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGETS_TITLE]: "Individual budgets",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGETS_DESCRIPTION]:
    "In the individual budgets, you can assign an individual gift budget to creators within the audience range you select, you can add as many budgets as you need.",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_TITLE]:
    "Total value worth of product gifts per month",
  [AUTOMATIC_INVITES_TOOLTIP_BUDGET_MONTHLY_DESCRIPTION]:
    "<strong>If you activate automatic invitations</strong>, this will be the total value of products in gifting that will be sent. This value will be distributed among the individual budgets you have configured.",
  [AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_TITLE]: "Automatic invitations",
  [AUTOMATIC_INVITES_TOOLTIP_AUTOMATIC_INVITES_DESCRIPTION]: `If you activate this option, {{NAME_BUSINESS}} will automatically send invitations to content creators who meet the requirements you have configured in the <strong>Content Creator Profile</strong> section, respecting the gifting limits and maximum monthly budget configured in the <strong>Gifting Settings</strong> section. <br/> <br/>
      This option will allow you to save time and effort in searching for content creators, as our system will filter them according to your interests and send invitations automatically.
      `,
  [AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_TITLE]:
    "Personalize your gifts",
  [AUTOMATIC_INVITES_TOOLTIP_PERSONALIZED_SECTION_DESCRIPTION]:
    "With these parameters, you can personalize the gifts you will manually send to content creators, however, you can modify them before sending. <strong>If you activate automatic invitations</strong>, these will be the parameters that will be set by default.",
  [AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_QUESTION]:
    "How many pieces of content do you want per month?",
  [AUTOMATIC_INVITES_TYPE_FORM_NUMBER_INVITATIONS_PLACEHOLDER]: "12",
  [AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_QUESTION]:
    "What's the total cost of products you're willing to offer in gifts for content each month?",
  [AUTOMATIC_INVITES_TYPE_FORM_BUDGET_MONTHLY_PLACEHOLDER]: "$500,000",
  [AUTOMATIC_INVITES_TYPE_FORM_BUDGETS_QUESTION]:
    "What's the cost of the product you want to give to each content creator?",
  [AUTOMATIC_INVITES_TYPE_FORM_COMMENT_QUESTION]:
    "What is the script that creators should follow to make the content?",
  [AUTOMATIC_INVITES_TYPE_FORM_TYPE_CONTENT_QUESTION]:
    "What is the type of content you want?",
  [AUTOMATIC_INVITES_TYPE_FORM_FORMATS_QUESTION]:
    "What are the content formats you want?",
  [AUTOMATIC_INVITES_TYPE_FORM_TERMS_QUESTION]:
    "What are the terms and conditions of your gifts?",
  [AUTOMATIC_INVITES_TYPE_FORM_TERMS_PLACEHOLDER]:
    "I only want creative videos",
  [AUTOMATIC_INVITES_TYPE_FORM_CONTENT_REFERENCE_QUESTION]:
    "Provide a link to an example of content that can serve as a guide for the creators.",
  [AUTOMATIC_INVITES_TYPE_FORM_AUTOMATIC_INVITATIONS_IS_ACTIVE_QUESTION]:
    "Do you wish to enable auto-invites?",
  [AUTOMATIC_INVITES_NUMBER_INVITATIONS_REQUIRED]:
    "The number of contents is required",
  [AUTOMATIC_INVITES_BUDGET_MONTHLY_REQUIRED]:
    "The total cost of products per month is required",
  [AUTOMATIC_INVITES_BUDGETS_VALUE_REQUIRED]: "All budgets must have a value",
  [AUTOMATIC_INVITES_COMMENT_REQUIRED]: "The script is required",
  [AUTOMATIC_INVITES_TERMS_REQUIRED]: "The terms and conditions are required",
  [AUTOMATIC_INVITES_TYPE_CONTENT_REQUIRED]: "The type of content is required",
  [AUTOMATIC_INVITES_FORMATS_REQUIRED]: "The content formats are required",
  [AUTOMATIC_INVITES_CONTENT_REFERENCE_REQUIRED]:
    "The reference content is required",

  //PLATFORMS CONNECT
  [PLATFORMS_CONNECTED_TITLE]: "Connect your accounts",
  [PLATFORMS_CONNECTED_INSTAGRAM_FAILED]: "Could not connect with instagram",
  [PLATFORMS_TOKEN_EXPIRED]: "Your token has expired, please reconnect",

  //SCHEDULE
  [SCHEDULE_TITLE]: "Schedules",
  [SCHEDULE_ECOMMERCE_ALERT_TITLE]: "Are you sure?",
  [SCHEDULE_ECOMMERCE_ALERT_DESCRIPTION]:
    "This will make your brand's schedule 24/7",
  [SCHEDULE_ONE_DAY_WITH_TURN]: "At least one day must have a shift",
  [SCHEDULE_EMPTY_TURN]: "There cannot be empty shifts",
  [SCHEDULE_OPEN_AND_CLOSE_HOUR_VALIDATION]:
    "The opening time cannot be greater than the closing time",
  [SCHEDULE_MAX_CLOSE_HOUR]:
    "The time cannot be greater than 23:59, use 00:00 instead of 24:00",
  [SCHEDULE_TABLE_ARIA_LABEL]: "Schedule table",
  [SCHEDULE_TABLE_COLUMN_OPEN]: "Opens",
  [SCHEDULE_TABLE_COLUMN_CLOSE]: "Closes",
  [SCHEDULE_SAVED]: "Schedule saved",

  //LEGAL REPRESENTATIVE
  [LEGAL_REPRESENTATIVE_TITLE]: "Legal representative information",
  [LEGAL_REPRESENTATIVE_NAME_PLACEHOLDER]: "First name",
  [LEGAL_REPRESENTATIVE_LAST_NAME_PLACEHOLDER]: "Last name",
  [LEGAL_REPRESENTATIVE_TYPE_ID_PLACEHOLDER]: "ID type",
  [LEGAL_REPRESENTATIVE_ID_NUMBER_PLACEHOLDER]: "ID number",
  [LEGAL_REPRESENTATIVE_PHONE_PLACEHOLDER]: "Phone",
  [LEGAL_REPRESENTATIVE_SAVED]: "Legal representative information saved",

  //PASSWORD
  [PASSWORD_TITLE]: "Change password",
  [PASSWORD_CURRENT_PASSWORD_PLACEHOLDER]: "Current password",
  [PASSWORD_NEW_PASSWORD_PLACEHOLDER]: "New password",
  [PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER]: "Confirm new password",
  [PASSWORD_FORMAT_DESCRIPTION]: "Password format:",
  [PASSWORD_FORMAT_SUGGESTION1]: "At least 6 characters",
  [PASSWORD_FORMAT_SUGGESTION2]: "At least one uppercase letter",
  [PASSWORD_FORMAT_SUGGESTION3]: "At least one lowercase letter",
  [PASSWORD_FORMAT_SUGGESTION4]: "At least one number",
  [PASSWORD_INSECURE]:
    "Password is not secure, please consider the recommendations",
  [PASSWORDS_NOT_MATCH]: "New passwords do not match",
  [PASSWORD_CHANGED]: "Password changed",
  [PASSWORD_CURRENT_INVALID]: "Current password is incorrect",
  [PASSWORD_ACCOUNT_DISABLED_FOR_MORE_INTENTS]:
    "The account has been temporarily disabled due to too many failed attempts. Please try again later.",

  //STORES
  [STORES_TITLE]: "My Stores",
  [STORES_DESCRIPTION]: "Create one or multiple stores for your brand",
  [STORES_STORE_SHOPIFY_DUPLICATE]:
    "You already have a store integrated with Shopify",
  [STORES_STORE_WOOCOMMERCE_DUPLICATE]:
    "You already have a store integrated with Wordpress Woocommerce",
  [STORES_STORE_WOOCOMMERCE_CREATED]:
    "The store and the Woocommerce integration have been created successfully",
  [STORES_STORE_NAME_DUPLICATE]: "A store with this name already exists",
  [STORES_STORE_SPECIAL_STORE_EXISTS]:
    "You already have a special store created",
  [STORES_STORE_ONBOARDING_FINISHED_DESCRIPTION]:
    "You have completed the stores tutorial, and now you know how to create your stores and configure them.",
  [STORES_STORE_ONBOARDING_FINISHED_ADDITIONAL_DESCRIPTION]:
    "Now you can continue with the catalog tutorial.",
  [STORES_STORE_CREATED]: "Store created",
  [STORES_STORE_CREATED_DESCRIPTION]:
    "The store has been created successfully. Would you like to create its catalog?",
  [STORES_STORE_COORDS_NOT_FOUND]:
    "This address was not found on Google Maps; please use a more specific address",
  [STORES_STORE_SAVED]: "Store saved",
  [STORES_STORE_NO_DELETED_LAST_STORE]:
    "You cannot delete all the stores; you must leave at least one.",
  [STORES_STORE_DELETE_CONFIRMATION_TITLE]:
    "Are you sure you want to delete this store?",
  [STORES_STORE_DELETE_CONFIRMATION_DESCRIPTION]:
    "This action is irreversible, and you won't be able to recover this information.",
  [STORES_STORE_DELETED]: "Store deleted",
  [STORES_TABLE_ARIAL_LABEL]: "Stores table",
  [STORES_TABLE_COLUMN_TYPE]: "Type",
  [STORES_TABLE_COLUMN_NAME]: "Name",
  [STORES_TABLE_COLUMN_CITY]: "City",
  [STORES_TABLE_COLUMN_ADDRESS]: "Address",
  [STORES_TABLE_COLUMN_EMAIL]: "Email",
  [STORES_TABLE_COLUMN_PHONE]: "Phone",
  [STORES_TABLE_COLUMN_STATUS]: "Status",
  [MODAL_STORE_ARIA_LABEL]: "Store modal",
  [MODAL_STORE_TITLE]: "Create a store",
  [MODAL_STORE_TITLE_EDIT]: "Edit a store",
  [MODAL_STORE_DESCRIPTION]:
    "Create a store that is a physical or virtual brand point of sale.",
  [MODAL_STORE_DESCRIPTION_EDIT]: "Edit a store you have created.",
  [MODAL_STORE_TYPE_PLACEHOLDER]: "Store type",
  [MODAL_STORE_TYPE_REGULAR_OPTION]: "Regular",
  [MODAL_STORE_HOW_TO_INTEGRATE_STORE]: `How to integrate my {{value}} store with {{NAME_BUSINESS}}?`,
  [MODAL_STORE_NAME_PLACEHOLDER]: "Store name",
  [MODAL_STORE_DESCRIPTION_PLACEHOLDER]: "Description",
  [MODAL_STORE_ADDRESS_PLACEHOLDER]: "Address",
  [MODAL_STORE_CITY_PLACEHOLDER]: "City",
  [MODAL_STORE_PHONE_PLACEHOLDER]: "Phone",
  [MODAL_STORE_LINK_STORE_SPECIAL_PLACEHOLDER]: "Link to your {{value}} store",
  [MODAL_STORE_NAME_SHOPIFY_PLACEHOLDER]: "Shopify Store Name",
  [MODAL_STORE_LOOK_NAME_SHOPIFY]: "Check your store's name",
  [MODAL_STORE_LOOK_NAME_SHOPIFY_HELPER_TEXT]:
    "Your store's name is what will appear in the URL to which you'll be redirected. You should copy the part that comes before <strong>.myshopify.com</strong>. For example, if the URL redirects to https://<strong>testclapps</strong>.myshopify.com, the store's name is <strong>testclapps</strong>.",
  [MODAL_STORE_ACCESS_TOKEN_FOR_BUZZLY_PLACEHOLDER]: `Access Token for {{NAME_BUSINESS}}`,
  [MODAL_STORE_DO_CREATE_DISCOUNT_SHIPPING_PLACEHOLDER]: "Offer free shipping",
  [MODAL_STORE_CITIES_PLACEHOLDER]: "Select cities",
  [MODAL_STORE_ORDERS_METHODS_PLACEHOLDER]:
    "Select methods for product delivery",
  [MODAL_STORE_ORDERS_METHODS_DELIVERY_OPTION]: "Delivery",
  [MODAL_STORE_ORDERS_METHODS_IN_STORE_OPTION]: "In-Store",
  [MODAL_STORE_PRODUCT_AVAILABILITY_PLACEHOLDER]: "Product availability",
  [MODAL_STORE_PRODUCT_AVAILABILITY_NATIONAL_OPTION]: "National (All cities)",
  [MODAL_STORE_PRODUCT_AVAILABILITY_LOCAL_OPTION]:
    "Local (Only in the selected city)",
  [MODAL_STORE_COVERAGE_RADIUS_PLACEHOLDER]: "Coverage radius in kilometers",
  [MODAL_STORE_TITLE_CREATE_INTEGRATE_SHOPIFY]: "Integrate Shopify",
  [MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_SHOPIFY]:
    "Connect your Shopify store with {{NAME_BUSINESS}} to facilitate the management of your invitations and redemptions.",
  [MODAL_STORE_DESCRIPTION_DISCONNECT_SHOPIFY]:
    "All Shopify invitation and redemption management functionalities will be disabled.",
  [MODAL_STORE_BUTTON_CREATE_INTEGRATE_SHOPIFY]: "Integrate Shopify",
  [MODAL_STORE_TITLE_CREATE_INTEGRATE_WOOCOMMERCE]: "Integrate WooCommerce",
  [MODAL_STORE_DESCRIPTION_CREATE_INTEGRATE_WOOCOMMERCE]:
    "Connect your WooCommerce store with {{NAME_BUSINESS}} to facilitate the management of your invitations and redemptions.",
  [MODAL_STORE_BUTTON_CREATE_INTEGRATE_WOOCOMMERCE]: "Integrate WooCommerce",
  [MODAL_STORE_DESCRIPTION_DISCONNECT_WOOCOMMERCE]:
    "All WooCommerce invitation and redemption management functionalities will be disabled.",

  //MENU
  [MENU_TITLE]: "Catalog",
  [MENU_DESCRIPTION]: "Create the catalog of your brand's products",
  [MENU_EMPTY_STORES]: "You don't have a store created to add a catalog",
  [MODAL_COPY_MENU_ARIA_LABEL]: "Modal to copy catalog",
  [MODAL_COPY_MENU_TITLE]: "Copy catalog",
  [MODAL_COPY_MENU_DESCRIPTION]: "Copy the catalog from one store to another",
  [MODAL_COPY_MENU_SAME_STORE]: "You cannot copy a catalog to the same store",
  [MODAL_COPY_MENU_CONFIRM_ALERT_TITLE]:
    "Are you sure you want to copy this catalog?",
  [MODAL_COPY_MENU_CONFIRM_ALERT_DESCRIPTION]:
    "This action will overwrite the catalog in the destination store.",
  [MODAL_COPY_MENU_COPIED]: "Catalog copied",
  [SELECT_CATALOG]: "Select a catalog",
  [MODAL_UPLOAD_MENU_ARIA_LABEL]: "Modal to upload catalog",
  [MODAL_UPLOAD_MENU_TITLE]: "Upload catalog",
  [MODAL_UPLOAD_MENU_DESCRIPTION]:
    "Select the store to which you want to upload the catalog",
  [MODAL_UPLOAD_MENU_FILE_PLACEHOLDER]:
    "To upload a catalog, you must do so from an Excel format with the extension xlsx, following the template for the columns.",
  [MODAL_UPLOAD_MENU_ERROR_FORMAT]:
    "An error has been found in the format. Please do not modify the structure of the template or leave required fields empty.",
  [MODAL_UPLOAD_MENU_MIN_PRICE_PRODUCT]:
    "The price of a product must be {{value,number}} or higher",
  [MODAL_UPLOAD_MENU_CONFIRM_ALERT_TITLE]:
    "Are you sure you want to upload this catalog?",
  [MODAL_UPLOAD_MENU_CONFIRM_ALERT_DESCRIPTION]:
    "This action will delete the existing catalog and replace it.",
  [MODAL_UPLOAD_MENU_UPLOADED]: "Catalog uploaded",
  [MENU_ADD_CATEGORY]: "Add category",
  [MENU_CATEGORY_NAME_PLACEHOLDER]: "Category name",
  [MENU_CATEGORY_REQUIRED]: "You must enter a category name",
  [MENU_ADD_PRODUCT]: "Add product",
  [MENU_PRODUCT_MIN_VALUE]: "The price must be {{value,number}} or higher",
  [MENU_CREATE_PRODUCT_TITLE]: "Create the base product",
  [MENU_PRODUCT_NAME_PLACEHOLDER]: "Name",
  [MENU_PRODUCT_DESCRIPTION_PLACEHOLDER]: "Description",
  [MENU_PRODUCT_PRICE_PLACEHOLDER]: "Price",
  [MENU_PRODUCT_TYPE_PRODUCT_PLACEHOLDER]: "Product type",
  [MENU_PRODUCT_TYPE_PRODUCT_EXPLANATION]:
    "This product is not suitable for the general public (harmful or for individuals over 18 years)",
  [MENU_PRODUCT_ACCOMPANIMENTS_TYPE_TITLE]: "Product Options",
  [MENU_PRODUCT_ACCOMPANIMENTS_TYPE_DESCRIPTION]:
    "Add options and customization for this product",
  [MENU_PRODUCT_ADD_ACCOMPANIMENT_TYPE_PLACEHOLDER]:
    "Add product option category",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_IS_MANDATORY_PLACEHOLDER]:
    "Is it mandatory?",
  [MENU_PRODUCT_ADD_ACCOMPANIMENT_PLACEHOLDER]: "Add product option",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_AND_MAX_NUMBER]:
    "The minimum options cannot be greater than the maximum",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_NUMBER]:
    "The minimum or maximum options cannot be less than 0",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_NAME_PLACEHOLDER]: "Name",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MIN_OPTIONS_PLACEHOLDER]: "Minimum options",
  [MENU_PRODUCT_ACCOMPANIMENT_TYPE_MAX_OPTIONS_PLACEHOLDER]: "Maximum options",
  [MENU_PRODUCT_ACCOMPANIMENT_NAME_PLACEHOLDER]: "Name",
  [MENU_PRODUCT_ACCOMPANIMENT_PRICE_PLACEHOLDER]: "Price",
  [MODAL_MOVE_PRODUCT_ARIA_LABEL]:
    "Modal to move a product to another category",
  [MODAL_MOVE_PRODUCT_CATEGORY_OBJECTIVE]:
    "Select the category you want to move the product to",
  [MODAL_MOVE_PRODUCT_CATEGORY_REQUIRED]: "You must select a category",
  [MODAL_MOVE_PRODUCT_BUTTON]: "Move product",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_ARIA_LABEL]:
    "Modal to copy an accompaniment type category to another product",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_OBJECTIVE]:
    "Select the product category that contains the accompaniment type category",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_OBJECTIVE]:
    "Select the product that contains the accompaniment type category",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE]:
    "Select the accompaniment type category you want to copy",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_OBJECTIVE_PLACEHOLDER]:
    "Accompaniment type category",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_CATEGORY_REQUIRED]:
    "You must select a category",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_PRODUCT_REQUIRED]: "You must select a product",
  [MODAL_COPY_ACCOMPANIMENT_TYPE_REQUIRED]:
    "You must select an accompaniment type category",
  [MENU_ONBOARDING_TEST_CATEGORY]: "Test category",
  [MENU_ONBOARDING_TEST_PRODUCT]: "Test product",
  [MENU_ONBOARDING_TEST_PRODUCT_DESCRIPTION]: "Test description",
  [MENU_ONBOARDING_FINISH_DESCRIPTION]:
    "You have completed the catalog creation tutorial, and now you know how to manage your catalog.",
  [MENU_ONBOARDING_FINISH_ADDITIONAL_DESCRIPTION]:
    "Now, you should fill out all the information about your brand in this account section.",
  [MENU_CATEGORY_DUPLICATE]: "Duplicate category",
  [MENU_CATEGORY_DUPLICATE_DESCRIPTION]:
    "A category with this name already exists",
  [MENU_CATEGORY_CREATED]: "Category created",
  [MENU_CATEGORY_SAVED]: "Category saved",
  [MENU_CATEGORY_DELETE_CONFIRMATION_TITLE]: "Delete Category",
  [MENU_CATEGORY_DELETE_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to delete this category?",
  [MENU_CATEGORY_DELETING]: "Deleting category",
  [MENU_CATEGORY_DELETED]: "Category deleted",
  [MENU_PRODUCT_DUPLICATE]: "Duplicate product",
  [MENU_PRODUCT_DUPLICATE_DESCRIPTION]:
    "A product with this name already exists",
  [MENU_PRODUCT_CREATED]: "Product created",
  [MENU_PRODUCT_SAVED]: "Product saved",
  [MENU_PRODUCT_DELETE_CONFIRMATION_TITLE]: "Delete product",
  [MENU_PRODUCT_DELETE_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to delete this product?",
  [MENU_PRODUCT_DELETING]: "Deleting product",
  [MENU_PRODUCT_DELETED]: "Product deleted",
  [MENU_PRODUCT_MOVE_SAME_CATEGORY]:
    "Cannot move the product to the same category",
  [MENU_PRODUCT_MOVED]: "Product moved",
  [MENU_PRODUCT_CHANGE_STATUS]: "Product {{status}}",
  [MENU_ACCOMPANIMENT_TYPE_DUPLICATE]: "Duplicate product option category",
  [MENU_ACCOMPANIMENT_TYPE_DUPLICATE_DESCRIPTION]:
    "A product option category with this name already exists",
  [MENU_ACCOMPANIMENT_TYPE_COPIED]: "Product option category copied",
  [MENU_ACCOMPANIMENT_DUPLICATED]: "Product option duplicated",
  [MENU_ACCOMPANIMENT_DUPLICATED_DESCRIPTION]:
    "A product option with this name already exists",

  //USERS
  [USERS_TITLE]: "Users",
  [USERS_DESCRIPTION]: "Create users to help manage your brand",
  [USERS_TABS_ARIA_LABEL]: "Users and Contacts Tabs",
  [USERS_PORTAL_TAB]: "Users",
  [USERS_CONTACTS_TAB]: "Contacts",
  [USERS_EMAIL_DUPLICATE]: "The email is already in use by another user",
  [USERS_USER_CREATED]: "User created",
  [USERS_USER_SAVED]: "User saved",
  [USERS_USER_DELETE_CONFIRMATION_TITLE]:
    "Are you sure you want to delete this user?",
  [USERS_USER_DELETE_CONFIRMATION_DESCRIPTION]:
    "Once deleted, it cannot be recovered",
  [USERS_USER_DELETING]: "Deleting user",
  [USERS_USER_DELETED]: "User deleted",
  [USERS_ONBOARDING_FINISH]:
    "Now you know which roles exist and what each one is for.",
  [USERS_USER_SEARCH_BAR_PLACEHOLDER]: "Search for a user",
  [USERS_TABLE_ARIA_LABEL]: "Users table",
  [USERS_TABLE_COLUMN_NAME]: "Name",
  [USERS_TABLE_COLUMN_EMAIL]: "Email",
  [USERS_TABLE_COLUMN_STORE]: "Store",
  [USERS_TABLE_COLUMN_ROLE]: "Role",
  [MODAL_USER_ARIA_LABEL]: "Create user modal",
  [MODAL_USER_TITLE]: "Create user",
  [MODAL_USER_TITLE_EDIT]: "Edit user",
  [MODAL_USER_DESCRIPTION]:
    "Create a user for your company so they can also access {{NAME_BUSINESS}}.",
  [MODAL_USER_DESCRIPTION_EDIT]:
    "Edit a user for your company who has access to {{NAME_BUSINESS}}.",
  [MODAL_USER_NAME_PLACEHOLDER]: "Full name",
  [MODAL_USER_ROLE_PLACEHOLDER]: "User role",
  [MODAL_USER_ROLE_OPERATOR_EXPLANATION]:
    "People who manage actions like Redeem Code and Orders, and ensure that products are activated or deactivated based on inventory.",
  [MODAL_USER_STORE_PLACEHOLDER]: "Select a store",
  [MODAL_USER_PASSWORD_PLACEHOLDER]: "Password",
  [MODAL_USER_REPEAT_PASSWORD_PLACEHOLDER]: "Repeat password",
  [USERS_CONTACT_CREATED]: "Contact Created",
  [USERS_CONTACT_SAVED]: "Contact Saved",
  [USERS_CONTACT_DELETE_CONFIRMATION_TITLE]:
    "Are you sure you want to delete this contact?",
  [USERS_CONTACT_DELETE_CONFIRMATION_DESCRIPTION]:
    "Once deleted, it cannot be recovered",
  [USERS_CONTACT_DELETED]: "Contact Deleted",
  [USERS_CONTACT_SEARCH_BAR_PLACEHOLDER]: "Search for contact",
  [USERS_CONTACT_TABLE_ARIA_LABEL]: "Contact Table",
  [USERS_CONTACT_TABLE_COLUMN_PHONE]: "Phone",
  [MODAL_CONTACT_ARIA_LABEL]: "Create Contact Modal",
  [MODAL_CONTACT_TITLE]: "Create a contact",
  [MODAL_CONTACT_TITLE_EDIT]: "Edit a contact",
  [MODAL_CONTACT_DESCRIPTION]:
    "Create a contact for {{NAME_BUSINESS}} to communicate with you.",
  [MODAL_CONTACT_DESCRIPTION_EDIT]: "Edit a contact you have created.",
  [MODAL_CONTACT_PHONE_PLACEHOLDER]: "Contact's Phone Number",

  //BILLING AND PAYMENTS
  [BILLING_AND_PAYMENTS_TITLE]: "Billing Panel",
  [BILLING_AND_PAYMENTS_DESCRIPTION]: "Manage your business plan and payments",
  [BILLING_AND_PAYMENTS_TABLE_ARIA_LABEL]: "Invoices table",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_PAID_DATE]: "Payment date",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_DUE_DATE]: "Due date",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_VALUE]: "Value",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_REASON]: "Reason",
  [BILLING_AND_PAYMENTS_TABLE_COLUMN_STATUS]: "Status",
  [PAYMENT_REASON_COLLABORATION]: "Payment for collaboration",
  [PAYMENT_REASON_APPLICANT]: "Payment for applicant",
  [PAYMENT_REASON_AMBASSADOR]: "Ambassador payment",
  [PAYMENT_REASON_INFLUENCER]: "Creator payment",
  [PAYMENT_REASON_INVITATION]: "Invitation payment",
  [PAYMENT_REASON_CLAPP_CREDITS]: `Payment for credits`,
  [PAYMENT_REASON_SUBSCRIPTION_PRO]: `Subscription {{NAME_BUSINESS}} Pro`,
  [PAYMENT_REASON_PACKAGE]: "Plan {{name}}",
  [PAYMENT_REASON_PLAN]: "Plan {{name}}",
  [PAYMENT_PAID]: "Paid",
  [PAYMENT_PENDING]: "Pending",
  [MODAL_INVOICE_ARIA_LABEL]: "Invoice details modal",
  [MODAL_INVOICE_TITLE]: "Invoice #{{value}}",
  [MODAL_INVOICE_DISCLAIMER]:
    "If you believe there's an error in the billing, you can contact support for detailed assistance.",
  [MODAL_INVOICE_CREDITS]: "Credits payment",
  [MODAL_INVOICE_NUMBER_CREDITS]: "Number of credits",
  [MODAL_INVOICE_PLAN_ENTERPRISE_WITH_CREDITS]:
    "{{credits}} media & publicity collaborations",
  [MODAL_INVOICE_SUBSCRIPTION]:
    "{{name}} subscription payment, period from {{startDate, short}} to {{endDate, short}}",
  [MODAL_INVOICE_LICENSE]:
    "{{name}} license payment, period from {{startDate, short}} to {{endDate, short}}",
  [MODAL_INVOICE_COLLABORATION]: "Content gifting payment",
  [MODAL_INVOICE_APPLICANT]: "Campaign applicant payment",
  [MODAL_INVOICE_AMBASSADOR]: "Ambassador payment",
  [MODAL_INVOICE_INFLUENCER]: "Content creator payment",
  [MODAL_INVOICE_INVITATION]: "Gifting invitation payment",
  [MODAL_INVOICE_FEE]: "Fee {{value}}%",
  [MODAL_INVOICE_IVA]: "IVA {{value}}%",
  [MODAL_INVOICE_ORDER_TO_COLLECT]: "Order to collect",
  [MODAL_INVOICE_APPLICANT_CAMPAIGN_PAID]: "Campaign",
  [MODAL_PAY_INVOICE_ARIA_LABEL]: "Invoice payment modal",
  [MODAL_PAY_INVOICE_TITLE]: "Pay Invoice #{{value}}",
  [MODAL_PAY_INVOICE_WILL_PAY]: "You will pay {{value,currency}}",
  [BILLING_AND_PAYMENT_PLAN_DEACTIVATE]: "Your plan is inactive",
  [BILLING_AND_PAYMENT_PLAN_CANCELLED]: "Your plan has been cancelled",
  [BILLING_AND_PAYMENT_PLAN_TRIAL]: "Trial",
  [BILLING_AND_PAYMENT_PLAN_TRIAL_DESCRIPTION]: `You have an active trial plan so you can learn the benefits of using {{NAME_BUSINESS}}`,
  [BILLING_AND_PAYMENT_CHANGE_YOUR_PLAN]: "Change your plan now",
  [BILLING_AND_PAYMENT_ACTIVATE_PLAN]: "Activate plan",
  [BILLING_AND_PAYMENT_SELECT_PLAN]: "Select a plan",
  [BILLING_AND_PAYMENT_NO_HAVE_PLAN]: "-",
  [BILLING_AND_PAYMENT_PLAN_EXPIRED]: `Your trial plan has expired. Add a payment method and activate your plan now to continue using {{NAME_BUSINESS}}.`,
  [BILLING_AND_PAYMENT_PLAN_NAME]: "Plan {{name}}",
  [BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE]: "Pay per use",
  [BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST]:
    "{{value,currency}} per gift",
  [BILLING_AND_PAYMENT_MODEL_USAGE_WHAT_BENEFITS]:
    "What benefits do you get with this plan?",
  [BILLING_AND_PAYMENT_MODEL_USAGE_SHOW_BENEFTIS]: "Show Benefits",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT1]:
    "Access to our catalog of content creators.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT2]:
    "Show your products and/or services to audiences of 5,000 to 2 million people on Instagram, TikTok, and YouTube.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT3]:
    "Analyze profiles, verify the quality and authenticity of their content, and get information about their audience.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT4]:
    "Automate product and service gifting (invitations and requests).",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT5]: "Automate paid campaigns.",
  [BILLING_AND_PAYMENT_MODEL_USAGE_BENEFIT6]:
    "Download content from the platform and use it on your social media, website, and other marketing channels.",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_NAME]: "Plan {{name}}",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_CHANGE]: "Change your plan now",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_COLLECT_COST_COLLABORATION]:
    "You haven't selected a plan yet, for now, you will be charged {{value,currency}} for each collaboration that occurs.",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_ADD_PAYMENT_METHOD_TO_ACTIVATE]:
    "Add a payment method to activate your plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_EXPIRED]: `Your trial plan has expired. Select a plan to continue using {{NAME_BUSINESS}}.`,
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_NO_HAVE_PACKAGE]: "-",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_ACTIVATE_PACKAGE]: "Activate plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_SELECT_PACKAGE]: "Select a plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_PACKAGE]: "Cancel plan",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_WHAT_BENEFITS]:
    "What benefits do you get with this plan?",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_COST_COLLABORATION]:
    "{{value,currency}} per collaboration",
  [BILLING_AND_PAYMENT_MODEL_PACKAGE_CANCEL_DISCLAIMER]:
    "* Subscriptions automatically renew every month. If you wish to cancel or change your plan, you must contact support at least 5 days in advance.",
  [MODAL_SELECT_PACKAGE_ARIA_LABEL]: "Plan Selection Modal",
  [MODAL_SELECT_PACKAGE_AND_PAYMENT_METHOD_REQUIRED]:
    "Plan and payment method are required",
  [MODAL_SELECT_PACKAGE_UPLOAD_GROWTH]: "Upgrade to Growth!",
  [MODAL_SELECT_PACKAGE_PLAN_PAID_TITLE]: "Successful Payment",
  [MODAL_SELECT_PACKAGE_PLAN_PAID_DESCRIPTION]: "Your plan has been activated",
  [MODAL_SELECT_PACKAGE_PRICING_HERO_TITLE]:
    "Are you ready to create content that actually drives sales?",
  [MODAL_SELECT_PACKAGE_PRICING_DISCOVER_BUZZLY]: `Discover {{NAME_BUSINESS}}`,
  [MODAL_SELECT_PACKAGE_UPLOAD_GROWTH_DESCRIPTION]:
    "With the Growth plan, you can access more benefits",
  [MODAL_SELECT_PACKAGE_PRICING_HERO_DESCRIPTION]:
    "Discover the platform that will take your brand to the next level of recognition on social media.",
  [MODAL_SELECT_PACKAGE_VALUE_EXPRESSED]:
    "Values are expressed in {{currency}}",
  [MODAL_SELECT_PACKAGE_PRICE]: "{{value,currency}}/mo",
  [MODAL_SELECT_PACKAGE_LICENSED_FEE_ENTERPRISE]: "License fee",
  [MODAL_SELECT_PACKAGE_LICENSED_FEE]: "License fee ({{period}} months)",
  [MODAL_SELECT_PACKAGE_LICENSED_PRICE]: "{{value,currency}}",
  [MODAL_SELECT_PACKAGE_CONTENT_ENTERPRISE]: "Content",
  [MODAL_SELECT_PACKAGE_PRICE_UPLOAD_GROWTH]:
    "You will be charged {{value,currency}} for upgrading to Growth",
  [MODAL_SELECT_PACKAGE_ACCORDING_TO_THE_REQUIREMENT]:
    "According to requirement",
  [MODAL_SELECT_PACKAGE_BUY_PACKAGE_NAME]: "Buy {{value}}",
  [MODAL_SELECT_PACKAGE_COST_COLLABORATION]:
    "{{value,currency}} per collaboration",
  [MODAL_SELECT_PACKAGE_COST_CONTENT]: "{{value,currency}} per content",
  [MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT_WITH_CREATORS]:
    "• Direct chat with creators",
  [MODAL_SELECT_PACKAGE_BENEFIT_CONTENT_CURATION]: "• Content curation",
  [MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_PUBLICATION_PLATFORM]:
    "• Direct Post to IG or TikTok",
  [MODAL_SELECT_PACKAGE_BENEFIT_COLLABORATE_WITH_CREATOR]:
    "• Collaborate with creators",
  [MODAL_SELECT_PACKAGE_BENEFIT_LAUNCH_CAMPAIGNS]: "• Launch campaigns",
  [MODAL_SELECT_PACKAGE_BENEFIT_DOWNLOAD_CONTENT]:
    "• Download content for your social media feed ",
  [MODAL_SELECT_PACKAGE_BENEFIT_ANALYZE_METRICS]:
    "• Analyze audience metrics, reach and engagement",
  [MODAL_SELECT_PACKAGE_BENEFIT_CATALOG]: "• Access content creator catalog",
  [MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIZATION]:
    "• Automation of product gifting for content",
  [MODAL_SELECT_PACKAGE_BENEFIT_AUTOMATIC_INVITES]: "• Automatic invitations",
  [MODAL_SELECT_PACKAGE_BENEFIT_SUPPORT_TICKETS]:
    "• Support via ticket assistance",
  [MODAL_SELECT_PACKAGE_BENEFIT_ALL_BASIC]: `• All benefits of Basic plan`,
  [MODAL_SELECT_PACKAGE_BENEFIT_KAM]:
    "• KAM - account manager (Strategic consulting)",
  [MODAL_SELECT_PACKAGE_BENEFIT_VOLUME_CONTENT]: "• Volume of contents",
  [MODAL_SELECT_PACKAGE_BENEFIT_DISCOVERY]:
    "• Discover and analyze metrics of any IG or TikTok profile",
  [MODAL_SELECT_PACKAGE_BENEFIT_AMBASSADORS]: "• Brand ambassador program",
  [MODAL_SELECT_PACKAGE_BENEFIT_DIRECT_CHAT]: "• Direct chat with creators",
  [MODAL_SELECT_PACKAGE_BENEFIT_ASSISTANCE_MARKETING]:
    "• 24/7 AI marketing assistant",
  [MODAL_SELECT_PACKAGE_BENEFIT_ALL_GROWTH]: "• All benefits of Growth plan",
  [MODAL_SELECT_PACKAGE_BENEFIT_BRANDS_UNLIMITED]: "• Unlimited brands",
  [MODAL_SELECT_PACKAGE_BENEFIT_PERSONALIZED_INTEGRATIONS]:
    "• Personalized integrations (WhatsApp, websites)",
  [MODAL_SELECT_PACKAGE_BENEFIT_IMPLEMENTATIONS_TECHNOLOGICAL]:
    "• Technological implementations",
  [MODAL_SELECT_PACKAGE_BENEFIT_PRIORITIZED_SUPPORT]: "• Priority support",
  [MODAL_SUBSCRIPTION_PRO_ARIA_LABEL]: "Modal to pay for pro subscription",
  [MODAL_SUBSCRIPTION_PRO_TITLE_HERO]:
    "Are you ready to unlock tools to enhance your collaborations?",
  [MODAL_SUBSCRIPTION_PRO_DESCRIPTION_HERO]: `{{NAME_BUSINESS}} Pro will grant you access to features that will help improve your collaborations with creators.`,
  [MODAL_SUBSCRIPTION_PRO_TITLE]: `{{NAME_BUSINESS}} Pro`,
  [MODAL_SUBSCRIPTION_PRO_SUB_HEADER]: "More Features",
  [MODAL_SUBSCRIPTION_PRO_BENEFIT_1]:
    "• Start direct chats with non-ambassador creators",
  [MODAL_SUBSCRIPTION_PRO_BUY_BUTTON]: `Buy {{NAME_BUSINESS}} Pro`,

  //PACKAGES
  [BASIC_PACKAGE_NAME]: `{{NAME_BUSINESS}} Basic`,
  [BASIC_PACKAGE_SUB_HEADER]: "Engage your audience",
  [BASIC_PACKAGE_DESCRIPTION]: "For small businesses",
  [PRO_PACKAGE_NAME]: "{{NAME_BUSINESS}} Pro",
  [PRO_PACKAGE_SUB_HEADER]: "Grow your brand",
  [PRO_PACKAGE_DESCRIPTION]: "For small and medium-sized businesses",
  [GROWTH_PACKAGE_NAME]: "Growth",
  [GROWTH_PACKAGE_SUB_HEADER]: "Most popular",
  [GROWTH_PACKAGE_DESCRIPTION]: "For small and medium-sized businesses",
  [ENTERPRISE_PACKAGE_NAME]: "Enterprise",
  [ENTERPRISE_PACKAGE_SUB_HEADER]: "Scale your business",
  [ENTERPRISE_PACKAGE_DESCRIPTION]:
    "For corporate groups and agencies, contact us for a custom plan.",
  [PRO_PACKAGE_NAME]: "{{NAME_BUSINESS}} Pro",

  //BILLING DATA
  [BILLING_DATA_TITLE]: "Billing Data",
  [BILLING_DATA_DESCRIPTION]: "Complete the data used for issuing invoices",
  [BILLING_DATA_SAVED]: "Billing data saved",
  [BILLING_DATA_PERSONAL_INFORMATION_DESCRIPTION]: "Personal information",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_PLACEHOLDER]: "Type",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_NATURE_OPTION]: "Individual",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_PERSON_LEGAL_OPTION]: "Business",
  [BILLING_DATA_PERSONAL_INFORMATION_BUSINESS_NAME_PLACEHOLDER]:
    "Business name",
  [BILLING_DATA_PERSONAL_INFORMATION_NAME_PLACEHOLDER]: "First name",
  [BILLING_DATA_PERSONAL_INFORMATION_LAST_NAME_PLACEHOLDER]: "Last name",
  [BILLING_DATA_PERSONAL_INFORMATION_TYPE_ID_PLACEHOLDER]:
    "Type of identification",
  [BILLING_DATA_PERSONAL_INFORMATION_ID_NUMBER_PLACEHOLDER]:
    "Identification number",
  [BILLING_DATA_CONTACT_INFORMATION_DESCRIPTION]: "Contact information",
  [BILLING_DATA_CONTACT_INFORMATION_EMAIL_BILLING_PLACEHOLDER]:
    "Email (Billing)",
  [BILLING_DATA_CONTACT_INFORMATION_ADDRESS_PLACEHOLDER]: "Address",
  [BILLING_DATA_CONTACT_INFORMATION_PHONE_PLACEHOLDER]: "Mobile number",

  [MODAL_FREE_TRIAL_TITLE]: "Start your 3-day free trial!",
  [MODAL_FREE_TRIAL_DESCRIPTION]:
    "Collaborate on gifting, campaigns, send payments and chat with content creators.",
  [MODAL_FREE_TRIAL_CAPTION]: "Start now for free",
  [MODAL_FREE_TRIAL_BUTTON]: "Try it FREE",
  [MODAL_FREE_TRIAL_START_ALERT_DESCRIPTION]:
    "You've unlocked your free trial, upgrade to {{NAME_BUSINESS}} Pro to start working with content creators.",

  [BLOCK_PAYMENT_FAILED_TITLE]: "Your payment failed",
  [BLOCK_TRIAL_END_TITLE]: "Your trial period has ended",
  [BLOCK_PACKAGE_DESCRIPTION]:
    "Activate your plan to collaborate with creators, launch campaigns, analyze metrics & download content for your social media feed",
  [BLOCK_PACKAGE_TITLE]: "Plan deactivated",
  [BLOCK_PLAN_BUTTON]: "Go to billing panel",
  [BLOCK_PLAN_BUTTON_ACTIVATE_PLAN]: "Activate plan",
  [BLOCK_TRIAL_ACTIVE_TITLE]: "Free trial is active",
  [BLOCK_TRIAL_ACTIVE_DESCRIPTION]:
    "Activate your plan to unlock the full potential of {{NAME_BUSINESS}}",
  [BLOCK_TRIAL_ACTIVE_BUTTON]: "Activate plan",
  [BLOCK_CONTACT_YOUR_ADMINISTRATOR_TITLE]:
    "Your account is inactive, please contact your administrator.",

  [MODAL_BLOCK_LAST_INVITE_TITLE]: "Account Restriction: Action Required",
  [MODAL_BLOCK_LAST_INVITE_DESCRIPTION]:
    "We've applied a temporary restriction to your account to uphold the quality of our platform. Certain features will be unavailable until you invite five creators.",
  [MODAL_BLOCK_LAST_INVITE_COUNT]: "{{count}}/5",
  [MODAL_BLOCK_LAST_INVITE_BUTTON]: "Invite creators",

  [MODAL_DISSATISFACTION_COLLABORATION_TITLE]:
    "We understand you're dissatisfied",
  [MODAL_DISSATISFACTION_COLLABORATION_DESCRIPTION]:
    "We're sorry the collaboration didn't meet your expectations. That's why we'll offer you the next collaboration for free",
  [MODAL_DISSATISFACTION_COLLABORATION_BUTTON]: "Receive 1 credit",
  [MODAL_DISSATISFACTION_COLLABORATION_EXPLANATION_CREDIT]:
    "*A credit equals a free collaboration and will be applied to your next collaboration.",
  [MODAL_DISSATISFACTION_COLLABORATION_CREDIT_RECEIVED]:
    "You received one credit",

  [MODAL_BUY_CREDITS_ARIA_LABEL]: `Modal to buy {{NAME_BUSINESS}} credits`,
  [MODAL_BUY_CREDITS_TITLE]: "Buy credits",
  [MODAL_BUY_CREDITS_DESCRIPTION]:
    "One credit equals one creator's post on gifting",
  [MODAL_BUY_CREDITS_CREDIT_FOR_COLLABORATION]:
    "Each successful gifting costs {{value,currency}}",
  [MODAL_BUY_CREDITS_HOW_MANY_CREDITS_BUY]:
    "How many credits would you like to buy?",
  [MODAL_BUY_CREDITS_REDEEMED_ESTIMATED_TIME]:
    "Your credits will be redeemed in an estimated {{value}} months",
  [MODAL_BUY_CREDITS_ADDITIONAL_CREDITS]: "We'll gift you {{value}} credit(s)!",
  [MODAL_BUY_CREDITS_LAST_TEXT_PAY]:
    "The total payment will be {{value,currency}}",
  [MODAL_BUY_CREDITS_AMOUNT_FOR_PRICE]:
    "{{credits}} Credits for {{value,currency}}",
  [MODAL_BUY_CREDITS_GIFT_CREDITS]:
    "(We gift you {{creditsGift}} for buying more than {{creditsBuy}})",
  [MODAL_BUY_CREDITS_AND_PAYMENT_METHOD_REQUIRED]:
    "The number of credits and payment method are required",
  [MODAL_BUY_CREDITS_NEGATIVE]: "The number of credits cannot be less than 1",
  [MODAL_BUY_CREDITS_PURCHASED]: "Credits purchased",
  [MODAL_BUY_CREDITS_PURCHASED_DESCRIPTION]:
    "Your credits have been added successfully, and you can view them in the top bar.",

  //MODAL ONBOARDING REQUIRED INFORMATION
  [MODAL_ONBOARDING_REQUIRED_INFORMATION_TITLE]: "Required information",
  [MODAL_ONBOARDING_REQUIRED_INFORMATION_DESCRIPTION]:
    "Complete this information to activate your brand and start collaborating with content creators.",
  [MODAL_ONBOARDING_PROFILE_INFLUENCER_SECTION]: "Creator profile",
  [MODAL_ONBOARDING_STORES_SECTION]: "Stores",
  [MODAL_ONBOARDING_SCHEDULE_SECTION]: "Schedule",
  [MODAL_ONBOARDING_BASIC_INFORMATION_SECTION]: "Brand information",

  //MODAL ADD BRAND SUPER ADMIN
  [MODAL_ADD_BRAND_SUPER_ADMIN_TITLE]: "Add brand",

  //LOGIN
  [FORGOT_PASSWORD]: "Forgot your password?",
  [NO_HAVE_ACCOUNT]: "Don't have an account yet?",
  [REGISTER_HERE]: "Register",
  [LOG_IN]: "Log In",
  [RESET_PASSWORD_EMAIL_SENT]:
    "We have sent an email to you with a link so you can reset your password.",

  //RESET PASSWORD
  [RESET_PASSWORD]: "Reset password",
  [RESET_PASSWORD_GET_TOKEN_FAILED]:
    "We could not obtain the token to reset the password, try opening the link sent to your email again or clicking the Forgot your password button again",
  [RESET_PASSWORD_SAVED]: "Password changed",

  //REGISTER
  [REGISTER_COUNTRY_ANSWER]: "In which country is your brand located?",
  [REGISTER_COUNTRY_PLACEHOLDER]: "Country",
  [REGISTER_NAME_ANSWER]: "What is your name?",
  [REGISTER_PHONE_ANSWER]: "What is your mobile number?",
  [REGISTER_EMAIL_ANSWER]: "What is your email address?",
  [REGISTER_PASSWORD_ANSWER]: "Set a password",
  [REGISTER_OTP_ANSWER]: "Enter verification code",
  [REGISTER_BUSINESS_NAME_ANSWER]: "What is your brand's name?",
  [REGISTER_CATEGORY_ANSWER]: "What is your brand's category?",
  [REGISTER_SOCIAL_NETWORK_ANSWER]:
    "What is your brand's primary social network?",
  [REGISTER_SOCIAL_NETWORK_ANSWER_DESCRIPTION]:
    "Enter your social network's username.",
  [REGISTER_WEBSITE_ANSWER]: "What is your brand's website?",
  [REGISTER_WEBSITE_ANSWER_DESCRIPTION]:
    "Enter your IG or other social media link if you don't have a website.",
  [REGISTER_MONTHLY_SALES_ANSWER]: "What is your brand's monthly revenue?",
  [REGISTER_BUDGET_MARKETING_ANSWER]:
    "What is your brand's monthly marketing budget?",
  [REGISTER_EMPLOYEES_ANSWER]: "How many employees does your brand have?",
  [REGISTER_PRODUCT_AVAILABILITY_ANSWER]: "Where are your customers located?",
  [REGISTER_RUT_ANSWER]: "Upload your company's constitution document",
  [REGISTER_INVIMA_ANSWER]: "Upload your brand's health authority registration",
  [REGISTER_ICA_ANSWER]:
    "Upload the product authority registration for animal products for your brand",
  [REGISTER_YOU_MEET_ANSWER]: "Where did you hear about us?",
  [REGISTER_WRITE_PLACEHOLDER]: "Write your answer here",
  [REGISTER_SELECT_PLACEHOLDER]: "Select an option",
  [REGISTER_COUNTRY_REQUIRED]: "You must select your country",
  [REGISTER_NAME_REQUIRED]: "You must enter your name",
  [REGISTER_PHONE_REQUIRED]: "You must enter your mobile number",
  [REGISTER_PHONE_NUMERIC_REQUIRED]: "The mobile number is not valid",
  [REGISTER_BUSINESS_NAME_REQUIRED]: "You must enter your brand's name",
  [REGISTER_CATEGORY_REQUIRED]: "You must select a category",
  [REGISTER_SOCIAL_NETWORK_REQUIRED]:
    "The social network username must be at least 3 characters long",
  [REGISTER_WEB_SITE_REQUIRED]:
    "You must enter the link to your brand's website",
  [REGISTER_MONTHLY_SALES_REQUIRED]:
    "You must select your brand's monthly revenue",
  [REGISTER_BUDGET_MARKETING_REQUIRED]:
    "You must select your brand's monthly marketing budget",
  [REGISTER_EMPLOYEES_REQUIRED]:
    "You must select the number of employees in your brand",
  [REGISTER_PRODUCT_AVAILABILITY_REQUIRED]:
    "You must select the availability of your products",
  [REGISTER_RUT_REQUIRED]:
    "You must select the RUT or company constitution document",
  [REGISTER_INVIMA_REQUIRED]:
    "You must select your brand's INVIMA registration",
  [REGISTER_ICA_REQUIRED]: "You must select your brand's ICA registration",
  [REGISTER_YOU_MEET_REQUIRED]:
    "You must select an option for where you heard about us",
  [REGISTER_NO_HAVE_DOCUMENTS_ALERT_TITLE]: "Sorry",
  [REGISTER_NO_HAVE_DOCUMENTS_ALERT_DESCRIPTION]:
    "We cannot continue the process if you don't have the required documents. Please revisit the process once you have them.",
  [REGISTER_REGISTERED_ALERT_DESCRIPTION]:
    "We are reviewing your information. We will send you an email once your brand is approved.",
  [PLEASE_SELECT_COUNTRY]: "Please select a country",
  [REGISTER_PROFILE_CREATOR_CITIES_ANSWER]:
    "Which cities do you want to find creators from to work with?",
  [REGISTER_PROFILE_CREATOR_PLATFORMS_ANSWER]:
    "On which social media or content platforms do you want to find creators to work with?",
  [REGISTER_PROFILE_CREATOR_CATEGORIES_ANSWER]:
    "What type of content do you want your ideal creator to make?",
  [REGISTER_PROFILE_CREATOR_GENDER_ANSWER]:
    "Which genders of creators do you want to work with?",
  [REGISTER_PROFILE_CREATOR_AGE_ANSWER]:
    "Which age groups of creators do you want to work with?",
  [REGISTER_PROFILE_CREATOR_BODY_BUILD]:
    "What type of body build do you want your ideal creator to have?",
  [REGISTER_PROFILE_CREATOR_CLOTHING_STYLE]:
    "What type of clothing style do you want your ideal creator to have?",
  [REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES]:
    "What distinguishing features do you want your ideal creator to have?",
  [REGISTER_PROFILE_CREATOR_CONTENT_STYLE]:
    "What type of content style do you want your ideal creator to have?",
  [REGISTER_PROFILE_CREATOR_CITIES_REQUIRED]:
    "You must select at least one city",
  [REGISTER_PROFILE_CREATOR_PLATFORMS_REQUIRED]:
    "You must select at least one platform",
  [REGISTER_PROFILE_CREATOR_CATEGORIES_REQUIRED]:
    "You must select at least three categories",
  [REGISTER_PROFILE_CREATOR_GENDER_REQUIRED]:
    "You must select at least one gender",
  [REGISTER_PROFILE_CREATOR_AGE_RANGE_REQUIRED]:
    "You must select at least two age ranges",
  [REGISTER_PROFILE_CREATOR_BODY_BUILD_REQUIRED]:
    "You must select at least two body builds",
  [REGISTER_PROFILE_CREATOR_CLOTHING_STYLE_REQUIRED]:
    "You must select at least three clothing styles",
  [REGISTER_PROFILE_CREATOR_DISTINGUISHING_FEATURES_REQUIRED]:
    "You must select at least three distinguishing features",
  [REGISTER_PROFILE_CREATOR_CONTENT_STYLE_REQUIRED]:
    "You must select at least three content styles",

  //CHATS
  [CHAT_ERROR_SENT_MESSAGE]:
    "Sorry, there was an internal failure, please try again later.",
  [CHAT_DO_PAYMENT]: "Make payment",
  [CHAT_WRITE_QUESTION_HERE]: "Write a question",
  [CHAT_WRITE_MESSAGE_HERE]: "Write a message",
  [CHAT_EMAIL_NO_PERMITTED]: "Sending emails is not allowed",
  [ONE_SECOND_AGO]: "1 second ago",
  [CHAT_FIRST_MESSAGE_BOT]:
    "Hello, I'm Ana, expert advisor on the platform.\nHow can I help you today?",
  [CHAT_NO_MORE]: "No more chats to load",

  //NOTIFICATIONS
  [NOTIFICATIONS_TITLE]: "Notifications",
  [NOTIFICATIONS_MARK_READS]: "Mark as read",
  [NOTIFICATIONS_EMPTY]: "You have no notifications",
  [NOTIFICATIONS_NO_MORE]: "You have no more notifications",
  [NOTIFICATIONS_CHECK_NOTIFICATION]: "Check",

  //SHOPS
  [SHOPS_TITLE]: "Brands",
  [SHOPS_SEARCH_BAR_PLACEHOLDER]: "Search by brand name",
  [SHOPS_TABLE_ARIA_LABEL]: "Brands Table",
  [SHOPS_MODAL_CREATE_SHOP_ARIA_LABEL]: "Brand Modal",
  [SHOPS_MODAL_CREATE_SHOP_TITLE]: "Create a Brand",
  [SHOPS_MODAL_CREATE_SHOP_DESCRIPTION]:
    "Create a brand that will join {{NAME_BUSINESS}}",
  [SHOPS_SHOP_CREATE]: "Brand created",

  //SHOP DETAIL
  [SHOP_DETAIL_TITLE]: "Brand {{value}}",
  [SHOP_DETAIL_CREATE_ENTERPRISE_PAYMENT_LINK]:
    "Create enterprise payment link",
  [SHOP_DETAIL_HAS_BILLING_CYCLE]: "Has billing cycle",
  [SHOP_DETAIL_REPORT_TITLE]: "Report",
  [SHOP_DETAIL_REPORT_SELECT_DATES]: "Select a date range and click calculate",
  [SHOP_DETAIL_DESCRIPTION_PLAN_TO_SUBSCRIPTION]:
    "This will delete the current plan and change it so that the brand must pay a basic subscription",
  [SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_TITLE]: "Delete Invitation",
  [SHOP_DETAIL_REPORT_DELETE_INVITE_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to delete this invitation?",
  [SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_TITLE]: "Extend Invitation",
  [SHOP_DETAIL_REPORT_EXTEND_INVITE_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to extend the time for this invitation?",
  [SHOP_DETAIL_REPORT_EXTENDING_INVITE]: "Extending Invitation Time",
  [SHOP_DETAIL_REPORT_INVITATION_EXTENDED]: "Invitation Extended",

  //SHOPS APPLICANTS
  [SHOPS_APPLICANTS_TITLE]: "Brand signups",
  [SHOPS_APPLICANTS_SEARCH_BAR_PLACEHOLDER]: "Search by brand name",
  [SHOPS_APPLICANTS_TABLE_ARIA_LABEL]: "Brands table",
  [SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_TITLE]: "Reject brand",
  [SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to reject this brand?",
  [SHOPS_APPLICANTS_REJECT_SHOP_CONFIRMATION_BUTTON]: "Reject",
  [SHOPS_APPLICANTS_REJECT_SHOP_REJECTING]: "Rejecting brand",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_ARIA_LABEL]: "Brand documents modal",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_TITLE]: "{{value}} documents",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_EMPTY]: "No documents to display",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_RUT]: "RUT",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_INVIMA]: "INVIMA",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_ICA]: "ICA",
  [SHOPS_APPLICANTS_MODAL_DOCUMENTS_OPEN]: "Open",
  [SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_ARIA_LABEL]: "Accept brand modal",
  [SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_TITLE]: "Accept brand",
  [SHOPS_APPLICANTS_MODAL_ACCEPT_SHOP_CREATE_BRAND]: "Create brand",

  //SHOPS ACCEPTED
  [SHOPS_ACCEPTED_TITLE]: "Accepted Brands",
  [SHOPS_ACCEPTED_FILTER_TYPE_IS_NOT_LOGGED]: "Not logged in",
  [SHOPS_ACCEPTED_FILTER_TYPE_IS_LOGGED]: "Logged in",
  [SHOPS_ACCEPTED_FILTER_TYPE_PLAN_REGULAR]: "Regular plan",
  [SHOPS_ACCEPTED_FILTER_TYPE_PLAN_ENTERPRISE]: "Enterprise plan",
  [SHOPS_ACCEPTED_TABLE_ARIA_LABEL]: "Shops Table",
  [SHOPS_ACCEPTED_MODAL_INFORMATION_ARIA_LABEL]: "Shop Information",

  // OWNER USERS
  [OWNER_USERS_TITLE]: "Users",
  [OWNER_USERS_SEARCH_BAR]: "Search users",
  [OWNER_USERS_TABLE_ARIA_LABEL]: "Administrators users table",
  [OWNER_USERS_DELETE_USER_CONFIRMATION_TITLE]:
    "Are you sure you want to delete this user?",
  [OWNER_USERS_DELETE_USER_CONFIRMATION_DESCRIPTION]:
    "Once deleted, it cannot be recovered",
  [OWNER_USERS_DELETE_USER_CONFIRM]: "Yes, delete",
  [OWNER_USERS_DELETE_USER_DELETING]: "Deleting user",
  [OWNER_USERS_DELETE_USER_DELETED]: "User deleted",
  [OWNER_USERS_FAILED_DELETE_USER]: "User was not deleted",
  [OWNER_USERS_CREATED_USER]: "User created",
  [OWNER_USERS_SAVED_USER]: "User saved",
  [OWNER_USERS_MODAL_ARIA_LABEL]: "Create user modal",
  [OWNER_USERS_MODAL_TITLE]: "Create user",
  [OWNER_USERS_MODAL_TITLE_EDIT]: "Edit user",
  [OWNER_USERS_MODAL_DESCRIPTION]: "Create a user for a brand",
  [OWNER_USERS_MODAL_DESCRIPTION_EDIT]: "Edit a previously created user",
  [OWNER_USERS_MODAL_NO_SELECT_BUSINESS_TO_CREATE]:
    "Do not select a business if you will create a new one",
  [OWNER_USERS_MODAL_BRAND_NO_HAVE_STORES]:
    "This brand doesn't have any stores",

  //DELIVERIES OWNER
  [DELIVERIES_OWNER_TITLE]: "Deliveries",
  [DELIVERIES_OWNER_TO_BE_ACCEPTED_FILTER]: "To be accepted",
  [DELIVERIES_OWNER_TO_BE_IN_PREPARATION_FILTER]: "Processing",
  [DELIVERIES_OWNER_TO_BE_DELIVERED_FILTER]: "To be delivered",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_PENDING]: "Pending acceptance",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_IN_PREPARATION]: "Processing",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_TO_BE_DELIVERED]: "To be delivered",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_DELIVERED]: "Delivered",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_CANCELLED]: "Cancelled",
  [DELIVERIES_OWNER_DELIVERY_STATUS_APP_REJECTED]: "Rejected",
  [DELIVERIES_OWNER_DELETE_DELIVERY_CONFIRMATION_DESCRIPTION]:
    "Once the delivery is deleted, you won't be able to recover it",

  //CREATORS SIGNUPS
  [REQUESTS_INFLUENCERS_ACCOUNT_TITLE]: "Creator signups",
  [REQUEST_INFLUENCERS_SEARCH_BAR_PLACEHOLDER]: "Search by name",
  [REQUEST_INFLUENCERS_WAITING_ANSWER_STATUS]: "Waiting for response",
  [REQUEST_INFLUENCERS_CODE_SENT_STATUS]: "Code sent",
  [REQUEST_INFLUENCERS_PENDING_SENT_CODE]: "Pending code send",

  //CREATORS OWNER
  [CREATORS_OWNER_TITLE]: "Creators",
  [CREATORS_OWNER_SEARCH_BAR_PLACEHOLDER]: "Search by name or username",
  [CREATORS_OWNER_IS_PRE_REGISTERED_FILTER]: "Pre-registered",
  [CREATORS_OWNER_WAS_REGISTERED_FILTER]: "Already registered",
  [CREATORS_OWNER_CREATOR_NOT_FOUND]: "Creator not found",
  [CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_TITLE]: "Delete this creator?",
  [CREATORS_OWNER_DELETE_CREATOR_CONFIRMATION_DESCRIPTION]:
    "Once deleted, it cannot be recovered",
  [CREATORS_OWNER_DELETING_CREATOR]: "Deleting creator",
  [CREATORS_OWNER_DELETED_CREATOR]: "Creator deleted",
  [CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_TITLE]: "Delete order",
  [CREATORS_OWNER_DELETE_ORDER_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to delete this order?",
  [CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_TITLE]: "Archive order",
  [CREATORS_OWNER_ARCHIVE_ORDER_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to archive this order?",
  [CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_TITLE]: "Unarchive order",
  [CREATORS_OWNER_UNARCHIVE_ORDER_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to unarchive this order?",
  [CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_TITLE]: "Delete invitation",
  [CREATORS_OWNER_DELETE_INVITATION_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to delete this invitation?",
  [CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_TITLE]: "Extend invitation",
  [CREATORS_OWNER_EXTEND_INVITATION_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to extend the time for this invitation?",
  [CREATORS_OWNER_EXTENDING_INVITATION]: "Extending invitation time",
  [CREATORS_OWNER_INVITATION_EXTENDED]: "Invitation extended",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_TITLE]:
    "Mark as redeemed",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_DESCRIPTION]:
    "Are you sure you want to mark this invitation as redeemed? This will generate a new order",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CONFIRMATION_BUTTON]:
    "Mark as redeemed",
  [CREATORS_OWNER_CREATE_ORDER_FROM_INVITE_CREATING]:
    "Redeeming invitation and creating order",
  [CREATORS_OWNER_REPORT_EMPTY_INVITATIONS]: "You don't have any invitations",
  [CREATORS_OWNER_REPORT_DATE_REDEMPTION]: "Redemption date",
  [CREATORS_OWNER_REPORT_DELIVERIES]: "Deliveries",
  [CREATORS_OWNER_REPORT_DELIVERIES_EMPTY]: "No deliveries yet",
  [CREATORS_OWNER_REPORT_WAS_ACCEPTED]: "Was accepted",
  [CREATORS_OWNER_REPORT_IS_DELIVERY]: "Is delivery",
  [CREATORS_OWNER_REPORT_ORDERS_EMPTY]: "No orders yet",
  [CREATORS_OWNER_REPORT_ORDERS_ARCHIVED]: "Archived orders",
  [CREATORS_OWNER_REPORT_ORDERS_ARCHIVED_EMPTY]: "No archived orders",
  [CREATORS_OWNER_REPORT_WAS_ARCHIVED]: "Was archived",
  [CREATORS_OWNER_REPORT_TRANSACTIONS]: "Transactions",
  [CREATORS_OWNER_REPORT_TRANSACTIONS_EMPTY]: "No transactions yet",
  [MODAL_EDIT_CREATOR_OWNER_TITLE]: "Creator information",
  [MODAL_EDIT_CREATOR_OWNER_HIDDEN_CREATOR]: "Hide creator",
  [MODAL_EDIT_CREATOR_OWNER_SHOW_CREATOR]: "Show creator",
  [MODAL_EDIT_CREATOR_OWNER_DISABLE_CREATOR]: "Disable creator",
  [MODAL_EDIT_CREATOR_OWNER_ENABLE_CREATOR]: "Enable creator",
  [MODAL_EDIT_CREATOR_OWNER_SELECT_THREE_CATEGORIES]:
    "You cannot select more than 3 categories",
  [MODAL_EDIT_CREATOR_OWNER_MAIN_ACCOUNT_NAME_LABEL]: "Content creator's @",
  [MODAL_EDIT_CREATOR_OWNER_CATEGORIES_LABEL]: "Categories",
  [MODAL_DISABLED_CREATOR_OWNER_TITLE]: "Disable content creator",

  //TRANSACTIONS
  [TRANSACTION_CONCEPT_LABEL]: "Concept",
  [TRANSACTION_TYPE]: "Type",
  [TRANSACTION_TYPE_CREDIT]: "Credit",
  [TRANSACTION_TYPE_DEBIT]: "Debit",
  [TRANSACTION_STATUS]: "Status",
  [TRANSACTION_STATUS_PENDING]: "Pending",
  [TRANSACTION_STATUS_PENDING_RELEASE]: "Pending release",
  [TRANSACTION_STATUS_IN_PROCESS_RELEASE]: "In process of release",
  [TRANSACTION_STATUS_RELEASED]: "Released",
  [TRANSACTION_STATUS_PENDING_SENT]: "Pending to send",
  [TRANSACTION_IN_TRANSIT]: "In transit",
  [TRANSACTION_STATUS_PAID]: "Paid",
  [TRANSACTION_WAS_PAID]: "Was paid",
  [MODAL_CREATE_TRANSACTION]: "Create a transaction for a creator",
  [MODAL_CREATE_TRANSACTION_AMOUNT_LABEL]: "Amount (USD)",
  [MODAL_CREATE_TRANSACTION_CONCEPT_LABEL]: "Concept",
  [MODAL_CREATE_TRANSACTION_CONCEPT_AMBASSADOR]: "Ambassador",
  [MODAL_CREATE_TRANSACTION_CONCEPT_APPLICANT]: "Applicant",
  [MODAL_CREATE_TRANSACTION_CONCEPT_ORDER]: "Order",
  [MODAL_CREATE_TRANSACTION_CONCEPT_REFERRAL]: "Referral",
  [MODAL_CREATE_TRANSACTION_CONCEPT_ID]: "Concept ID (Optional)",

  //CONTENT
  [CONTENT_TITLE]: "Brand content",

  //CONTENT APPLICATION
  [CONTENT_APPLICATION_TITLE]: "Content application",
  [CONTENT_APPLICATION_ACCEPT_TITLE]: "Accept application",
  [CONTENT_APPLICATION_ACCEPT_DESCRIPTION]:
    "By accepting this application, the user will be able to register as a content creator",
  [CONTENT_APPLICATION_REJECT_TITLE]: "Reject application",
  [CONTENT_APPLICATION_REJECT_DESCRIPTION]:
    "By rejecting this application, the user will not be able to register as a content creator",

  //REPORTS
  [REPORTS_TITLE]: "Reports",
  [REPORTS_DESCRIPTION]:
    "Here you will find the general statistics of the {{NAME_BUSINESS}} ecosystem",
  [REPORTS_ERROR_GENERATING]:
    "Something went wrong with generating statistics, please try again.",
  [REPORTS_INVITATIONS_ACTIVE]: "Active invitations",
  [REPORTS_INVITATIONS_INACTIVE]: "Inactive invitations",
  [REPORTS_REQUESTS_ACTIVE]: "Active requests",
  [REPORTS_REQUESTS_INACTIVE]: "Inactive requests",
  [REPORTS_TYPE_USERS]: "User type",
  [REPORTS_NUMBER_USERS]: "Number of users",
  [REPORTS_TYPE_ORDER]: "Order type",
  [REPORTS_NUMBER_ORDER]: "Number of orders",
  [REPORTS_MODAL_DESCRIPTION]:
    "Here you will find reports of different categories",
  [REPORTS_MODAL_ONLY_BRANDS_ACTIVE]: "Only active stores",
  [REPORTS_MODAL_TYPE_REPORT_LABEL]: "Report type",
  [REPORTS_MODAL_TYPE_REPORT_BRANDS]: "Brands",
  [REPORTS_MODAL_TYPE_REPORT_CREATORS]: "Creators",
  [REPORTS_MODAL_TYPE_REPORT_ORDERS]: "Orders",
  [REPORTS_MODAL_TYPE_REPORT_INVITATIONS]: "Invitations",

  //SHOPIFY TERMS
  [INTEGRATIONS_TERMS_TITLE]: `{{NAME_BUSINESS}}'s Privacy Policy with Integrations`,
  [INTEGRATIONS_TERMS_DESCRIPTION]: "Last updated: {{date,short}}",
  [INTEGRATIONS_TERMS_CONTENT]: `
    <strong>1. Information collected</strong><br>
    
    At {{NAME_BUSINESS}}, we respect the privacy of our users and customers. Through our integration with platforms, we collect limited and specific information only for the technical operation of our application. The data we collect includes:<br>
    
    Integration store name: We use this information to identify the store and facilitate the execution of specific actions within the platform.<br>
    
    Authorization token: We use an authorization token provided by platform merchants to make necessary technical requests for monitoring delivery status changes and executing actions as required.<br><br>
    
    <strong>2. Use of information</strong><br>
    
    It's important to note that we do not store any personal data or identifiable information of merchants or their customers. The collected information is used solely for the technical execution of the {{NAME_BUSINESS}} application on the platform. It is not used for any other purpose and is not stored in our databases.<br><br>
    
    <strong>3. Data retention</strong><br>
    
    In line with our no data storage policy, we do not retain any information of merchants or their customers. Any received information is used in real-time and discarded immediately after its use.<br><br>
    
    <strong>4. Contact</strong><br>
    
    If you have any questions or concerns about our privacy policy, feel free to contact us at ${EMAIL_SUPPORT}.
  `,

  [AUTH_TIKTOK_VERIFICATION_LOADING]: "Verifying your TikTok account",
  [AUTH_TIKTOK_VERIFICATION_SUCCESS]: "Your TikTok account has been verified",
  [AUTH_TIKTOK_VERIFICATION_FAILED]:
    "An error occurred. We couldn't verify your TikTok account",

  // COLUMN NAMES
  [COLUMN_NAME_SHOP_ID]: "Shop ID",
  [COLUMN_NAME_SHOP]: "Shop",
  [COLUMN_NAME_BRAND]: "Brand",
  [COLUMN_NAME_CATEGORY]: "Category",
  [COLUMN_NAME_IS_LOGGED]: "Logged in",
  [COLUMN_NAME_IS_ACTIVE]: "Is active",
  [COLUMN_NAME_IS_PLAN_ACTIVE]: "Plan active",
  [COLUMN_NAME_PACKAGE]: "Package",
  [COLUMN_NAME_EMAIL]: "Email",
  [COLUMN_NAME_NAME]: "Name",
  [COLUMN_NAME_PHONE]: "Phone",
  [COLUMN_NAME_STAFF]: "Staff",
  [COLUMN_NAME_ACCEPTED]: "Accepted",
  [COLUMN_NAME_MONTHLY_SALES]: "Monthly sales",
  [COLUMN_NAME_BUDGET_MARKETING]: "Marketing budget",
  [COLUMN_NAME_EMPLOYEES]: "Employees",
  [COLUMN_NAME_PRODUCT_AVAILABILITY]: "Product availability",
  [COLUMN_NAME_SOCIAL_NETWORK]: "Social network",
  [COLUMN_NAME_WEB_SITE]: "Website",
  [COLUMN_NAME_YOU_MEET]: "Where you met us",
  [COLUMN_NAME_DATE]: "Date",
  [COLUMN_NAME_PLAN]: "Plan",
  [COLUMN_NAME_PAYMENT_METHOD]: "Payment method",
  [COLUMN_NAME_COST_COLLABORATION]: "Cost per collaboration",
  [COLUMN_NAME_CREDITS]: "Credits",
  [COLUMN_NAME_COUNTRY]: "Country",
  [COLUMN_NAME_CREATOR_NAME]: "Name",
  [COLUMN_NAME_CREATOR_PLATFORM]: "Platform",
  [COLUMN_NAME_CITY]: "City",
  [COLUMN_NAME_AUDIENCE]: "Audience",
  [COLUMN_NAME_ENGAGEMENT]: "Engagement",
  [COLUMN_NAME_CATEGORIES]: "Categories",
  [COLUMN_NAME_SCORE]: "Score",
  [COLUMN_NAME_ACTIVITY]: "Activity",
  [COLUMN_NAME_ACTIONS]: "Actions",
  [COLUMN_NAME_ACTION]: "Action",
  [COLUMN_NAME_INVITATION]: "Invitation",
  [COLUMN_NAME_CODE_INVITATION]: "Code",
  [COLUMN_NAME_VALUE_INVITATION]: "Value",
  [COLUMN_NAME_USE_INVITATION]: "Use",
  [COLUMN_NAME_EXPIRATION_INVITATION]: "Expire",
  [COLUMN_NAME_TYPE_INVITATION]: "Type",
  [COLUMN_NAME_REJECTED_REASON_INVITATION]: "Rejected by",
  [COLUMN_NAME_STATUS]: "Status",
  [COLUMN_NAME_REDEEMED_DATE]: "Redeemed on",
  [COLUMN_NAME_TERMS]: "Terms",
  [COLUMN_NAME_TYPE]: "Type",
  [COLUMN_NAME_ADDRESS]: "Address",
  [COLUMN_NAME_STORE]: "Store",
  [COLUMN_NAME_ROLE]: "Role",
  [COLUMN_NAME_PAID_DATE]: "Paid on",
  [COLUMN_NAME_DUE_DATE]: "Due on",
  [COLUMN_NAME_REASON]: "Reason",
  [COLUMN_NAME_VALUE]: "Value",
  [COLUMN_NAME_ID]: "ID",
  [COLUMN_NAME_CREATOR]: "Creator",
  [COLUMN_NAME_CREATED_DATE]: "Created on",
  [COLUMN_NAME_ACCEPTED_DATE]: "Accepted on",
  [COLUMN_NAME_DETAILS]: "Details",
  [COLUMN_NAME_DELIVERY]: "Delivery",
  [COLUMN_NAME_CODE_VERIFICATION]: "Verification code",
  [COLUMN_NAME_ACCOUNT_NAME]: "Account name",
  [COLUMN_NAME_IS_HIDDEN]: "Hidden",
  [COLUMN_NAME_COMMENT]: "Comment",
  [COLUMN_NAME_URLS]: "Urls",
  [COLUMN_NAME_IS_PRE_REGISTERED]: "Is pre-registered",
  [COLUMN_NAME_WAS_PRE_REGISTERED]: "Already registered",

  //ALERTS
  [ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED]:
    "To activate your brand, you need to complete the following fields: ",
  [ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED_FIX_BUTTON]: "Complete now",
  [ALERT_ACTIVATE_SHOP_BY_OWNER_FIELDS_REQUIRED]:
    "To activate this brand, you need to complete the following fields: ",
  [REMINDER_ACTIVATE_SHOP]:
    "Activate your account so creators can see your brand.",
  [ALERT_INACTIVE_SHOP_TITLE]: "Brand Deactivated",
  [ALERT_INACTIVE_SHOP_DESCRIPTION]:
    "Activate the brand to continue using the platform.",
  [ALERT_INACTIVE_SHOP_CHAT_DESCRIPTION]:
    "Activate the brand to start this chat",
  [ALERT_OPERATOR_ACTION_UNAUTHORIZED_DESCRIPTION]:
    "You cannot perform this action because you don't have sufficient permissions.",
  [ALERT_STORES_REQUIRED_FOR_EXCHANGE_ACTION]:
    "In order to invite a creator to collaborate, you need to complete the following fields: ",
  [ALERT_DISCARD_INFLUENCER_DEFINITIVELY_TITTLE]: "Discard creator",
  [ALERT_DISCARD_INFLUENCER_DEFINITIVELY_DESCRIPTION]:
    "This creator will no longer appear and you will not be able to collaborate with them",
  [ALERT_DISCARD_INFLUENCER_DEFINITIVELY_BUTTON]: "Discard creator",
  [ALERT_CREATE_SECOND_ADS_CAMPAIGN]: "Create campaign on {{platform}}",
  [ALERT_CREATE_SECOND_ADS_CAMPAIGN_DESCRIPTION]:
    "Do you wish to create a campaign on {{platform}} with the same settings as this campaign?",
  [ALERT_REMEMBER_IF_RECHARGE_ACCOUNT_PLATFORM]:
    "If you recharge your account, please note that it may take a few minutes for the balance to be reflected and for this option to be re-enabled.",
  [ALERT_DESCRIPTION_DISCONNECT_FACEBOOK]:
    "All campaign functionalities will be disabled in Meta and post directly to Instagram.",
  [ALERT_DESCRIPTION_DISCONNECT_TIKTOK]:
    "All functionalities to post directly to TikTok will be disabled.",
  [ALERT_DESCRIPTION_DISCONNECT_TIKTOK_BUSINESS]:
    "All functionalities for creating and managing TikTok campaigns will be disabled",
  [ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE]: "Are you sure?",
  [ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION]:
    "You will create a campaign with a budget of {{value,currency}}",
  [ALERT_INFLUENCER_ALREADY_INVITED_CAMPAIGN]:
    "This creator has already been invited to this campaign",

  //OPTIONS UGC FORMATS
  [OPTION_UGC_FORMAT_GRWM]: "GRWM (Get Ready With Me)",
  [OPTION_UGC_FORMAT_GRWM_EXPLANATION]:
    "This type of content involves creators sharing their preparation routine, whether for everyday or special events. Viewers can watch as the creator applies makeup, styles hair, and selects outfits, while discussing various topics in an informal manner.",
  [OPTION_UGC_FORMAT_OODT]: "OOTD (Outfit of the Day)",
  [OPTION_UGC_FORMAT_OODT_EXPLANATION]:
    "In these videos or posts, creators showcase the outfit they have chosen for the day. Popular among fashion enthusiasts, it may include details on where they purchased the clothing, how they pair it with other items, and style tips.",
  [OPTION_UGC_FORMAT_HAULS]: "Hauls",
  [OPTION_UGC_FORMAT_HAULS_EXPLANATION]:
    "'Hauls' are videos or photo series where creators display a collection of recently purchased products, which may include fashion, beauty, technology, etc. They often share their first impressions of these products and may include usage demonstrations.",
  [OPTION_UGC_FORMAT_DIYS]: "Tutorials/DIYs",
  [OPTION_UGC_FORMAT_DIYS_EXPLANATION]:
    "Tutorials or 'Do It Yourself' (DIYs) are step-by-step guides created by users that demonstrate how to make something, from cooking recipes to craft projects and beauty hacks. These contents are educational and aim to teach skills in an easy and accessible way.",
  [OPTION_UGC_FORMAT_UNBOXING]: "Unboxing",
  [OPTION_UGC_FORMAT_UNBOXING_EXPLANATION]:
    "In these videos, creators unbox new products in front of the camera to show their contents and provide a first impression. They are common in technology, toys, cosmetics, and more, helping viewers decide if they want to purchase those products.",
  [OPTION_UGC_FORMAT_CHALLENGES]: "Challenges",
  [OPTION_UGC_FORMAT_CHALLENGES_EXPLANATION]:
    "Challenges are activities in which creators engage to entertain their followers. These can range from food challenges, games, physical activities to fashion challenges, like creating an outfit with specific items.",
  [OPTION_UGC_FORMAT_REVIEWS]: "Reviews/Testimonials",
  [OPTION_UGC_FORMAT_REVIEWS_EXPLANATION]:
    "Creators share their detailed opinions and experiences about a specific product or service. These reviews help other users form an opinion before making a purchase.",
  [OPTION_UGC_FORMAT_VLOGS]: "Vlogs",
  [OPTION_UGC_FORMAT_VLOGS_EXPLANATION]:
    "Vlogs are video diaries where creators share aspects of their daily life, travels, special events, etc. They are personal and allow viewers to feel a closer connection with the creator's lifestyle.",
  [OPTION_UGC_FORMAT_QA]: "Q&A (Questions and Answers)",
  [OPTION_UGC_FORMAT_QA_EXPLANATION]:
    "In a question and answer format, creators interact directly with their audience by answering questions they have previously sent. This helps build a more personal and direct relationship with followers.",
  [OPTION_UGC_FORMAT_STORYTIME]: "Storytime",
  [OPTION_UGC_FORMAT_STORYTIME_EXPLANATION]:
    "These are videos or posts where creators narrate personal stories or significant experiences. They are engaging due to their narrative aspect and often include lessons or morals.",
  [OPTION_UGC_FORMAT_ROOM_TOURS]: "Room Tours",
  [OPTION_UGC_FORMAT_ROOM_TOURS_EXPLANATION]:
    "Creators showcase their personal space, such as their bedroom, studio, or entire house. These videos are popular because they allow followers to get decoration ideas and feel a closeness to the creator's lifestyle.",
  [OPTION_UGC_FORMAT_WHATS_IN_MY_BAG]: "What’s in My Bag?",
  [OPTION_UGC_FORMAT_WHATS_IN_MY_BAG_EXPLANATION]:
    "In this type of content, creators reveal what they carry in their bags or backpacks daily. It is particularly popular among celebrities and influencers in fashion and beauty, as it offers a glimpse into their personal essentials.",
  [OPTION_UGC_FORMAT_COOKING]: "Cooking/Baking Recipes",
  [OPTION_UGC_FORMAT_COOKING_EXPLANATION]:
    "Creators share recipes and cooking or baking processes in the form of videos or posts. These contents are useful for those seeking new meal ideas and enjoy the visual and explanatory aspect of cooking.",
  [OPTION_UGC_FORMAT_FITNESS_ROUTINES]: "Fitness Routines",
  [OPTION_UGC_FORMAT_FITNESS_ROUTINES_EXPLANATION]:
    "Fitness influencers share their workout routines, health tips, and wellness journeys. These videos are motivational and educational, helping followers stay active and healthy.",
  [OPTION_UGC_FORMAT_ASMR]: "ASMR (Autonomous Sensory Meridian Response)",
  [OPTION_UGC_FORMAT_ASMR_EXPLANATION]:
    "ASMR videos are designed to evoke a relaxing sensation in viewers through soft sounds and meticulous movements. They are highly popular for relaxation and stress management.",
  [OPTION_UGC_FORMAT_PLAN_WITH_ME]: "Plan With Me",
  [OPTION_UGC_FORMAT_PLAN_WITH_ME_EXPLANATION]:
    "In these videos, creators show how they organize their agenda or weekly planner, sharing time management techniques and planner decoration. It is ideal for stationery and personal organization enthusiasts.",
  [OPTION_UGC_FORMAT_MUKBANG]: "Mukbang",
  [OPTION_UGC_FORMAT_MUKBANG_EXPLANATION]:
    "Originated in South Korea, in these videos creators consume large amounts of food while interacting with their audience. It has been popularized globally as a form of virtual company during meals.",
  [OPTION_UGC_FORMAT_DAY_IN_THE_LIFE]: "Day in the Life",
  [OPTION_UGC_FORMAT_DAY_IN_THE_LIFE_EXPLANATION]:
    "Creators share a video documenting a typical day in their life, showing from morning to night. This gives followers a comprehensive view of their daily activities and lifestyle.",
  [OPTION_UGC_FORMAT_BEHIND_THE_SCENES]: "Behind the Scenes",
  [OPTION_UGC_FORMAT_BEHIND_THE_SCENES_EXPLANATION]:
    "These videos offer a behind-the-scenes look into the lives of creators, including how they prepare their content, manage businesses, or even special events they attend.",
  [OPTION_UGC_FORMAT_TRAVEL_DIARIES]: "Travel Diaries",
  [OPTION_UGC_FORMAT_TRAVEL_DIARIES_EXPLANATION]:
    "In travel diaries, creators share experiences, tips, and adventures from their travels. These videos or posts are appealing to those seeking travel inspiration or learning about new cultures.",

  //ERRORS
  [SOMETHING_ERROR_GENERATING_STATISTICS]:
    "Something went wrong with the generation of statistics, please try again.",
  [PROFILE_INFLUENCER_INCOMPLETE]:
    "You don't have your content creator profile complete or up to date.",
  [AUTH_SENT_EMAIL_RESET_PASSWORD]:
    "An email has been sent to reset the password.",
  [ACCOUNT_ROLE_INVALID]: "This account does not have a valid role to log in",
  [ERROR_INTERNAL]: "Internal error, please try again later",
  [AUTH_INVALID_PASSWORD]: "The password is not valid",
  [AUTH_WRONG_PASSWORD]: "Incorrect password",
  [AUTH_MANY_REQUESTS]: "Too many failed attempts, please try again later",
  [AUTH_USER_NOT_FOUND]: "User does not exist",
  [AUTH_EMAIL_ALREADY_EXISTS]: "Email is already in use",
  [AUTH_INVALID_VERIFICATION_CODE]: "Invalid verification code",
  [STRIPE_CARD_DECLINED]:
    "The card has been declined. Please verify the card information or use a different card",
  [STRIPE_EXPIRED_CARD]:
    "The card has expired. Please verify the expiration date or use a different card",
  [STRIPE_INCORRECT_CVC]:
    "Incorrect card security code. Please verify the card security code or use a different card",
  [STRIPE_PROCESSING_ERROR]:
    "There was an error processing the card. Please try again later or use a different payment method",
  [STRIPE_INCORRECT_NUMBER]:
    "Incorrect card number. Please verify the card number or use a different card",
  [STRIPE_GENERIC_DECLINE]: "The card was declined for an unknown reason",
  [STRIPE_INSUFFICIENT_FUNDS]:
    "Insufficient funds on the card to complete the purchase or for verification charge",
  [STRIPE_LOST_CARD]: "Payment declined because the card was reported as lost",
  [STRIPE_STOLEN_CARD]:
    "Payment declined because the card was reported as stolen",
  [STRIPE_PROFILE_NOT_FOUND]: "This profile does not exist",
  [STRIPE_INTENT_AUTH_FAILURE]: "Card authentication failed",
  [NIT_VALID]: "Identification number",
  [BUSINESS_NAME_VALID]: "Business legal name",
  [BRAND_NAME_VALID]: "Brand name",
  [ADDRESS_VALID]: "Business address",
  [CATEGORY_VALID]: "Category",
  [SUBCATEGORIES_VALID]: "Subcategories",
  [SHORT_DESCRIPTION_VALID]: "Short business description",
  [ORDERS_METHODS_VALID]: "Order delivery methods",
  [LOGO_IMG_VALID]: "Business logo",
  [COVER_IMG_VALID]: "Cover image",
  [SCHEDULE_VALID]: "Business hours of operation",
  [STORES_VALID]: "At least one created and active store",
  [IS_PLAN_ACTIVE_VALID]: "You must have an active plan",
  [MENUS_VALID]:
    "You must have at least one category with at least one product created in the catalog",
  [DEFAULT_PAYMENT_METHOD_VALID]:
    "You must have a default added card or purchased credits",
  [HAS_DEBT_VALID]: "You should not have pending payments",
  [SUBSCRIPTION_PLAN_VALID]: "You must have an active plan",
  [PROFILE_INFLUENCER_VALID]:
    "You should create or update your content creator profile",
  [AUTOMATIC_INVITATIONS_VALID]:
    "You should fill out or update your gifting configuration",
  [SHOP_ID_USER_ID_NOT_SENT]: "ShopID and userID no sent",
  [MENU_HAS_ELEMENTS_CREATED]:
    "Make sure that the selected catalog has items created.",
  [INVOICE_CREATED_FOR_RETRY_PAYMENT]:
    "An invoice was created in the billing panel for you to retry payment.",
  [STORE_NO_HAS_MENU]: "This store doesn't have a catalog to be activated.",
  [STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT]:
    "This store doesn't have a catalog to be activated.",
  [STORE_COVERAGE_RADIUS_REQUIRED]:
    "Coverage radius is required to activate this store.",
  [MISSING_PARAMETERS]: "Required parameters were not sent.",
  [PASSWORD_INVALID]: "Invalid password.",
  [USER_ID_REQUIRED]: "UserID is required.",
  [SHOP_ID_REQUIRED]: "ShopID is required.",
  [LAST_USER_NOT_DELETED]:
    "All users cannot be deleted; at least one must remain.",
  [PLAN_TRIAL_USER_USED]:
    "The trial period has already been used for this user.",
  [ORDERS_NOT_FOUND_IN_INTERVAL]:
    "No orders were found in the selected date range.",
  [TYPE_REPORT_NOT_FOUND]: "Report type not found.",
  [SHOP_NOT_FOUND]: "Brand not found.",
  [PAYMENT_MODEL_INVALID]: "Invalid payment model.",
  [SHOP_NOT_HAS_SOCIAL_NETWORK]:
    "The brand does not have an associated social network.",
  [NO_VALID_PLATFORM_FOUND]: "No valid platform found.",
  [GETTING_STATISTICS]:
    "Error getting statistics; please try again later or contact support.",
  [SHOP_BUSINESS_NAME_NOT_FOUND]: "Brand name not found.",
  [INFLUENCERS_SUGGESTED_NOT_FOUND]: "Suggested creators not found.",
  [REFERENCE_PROFILES_REQUIRED]: "Reference profiles not found.",
  [INFLUENCERS_SIMILAR_NOT_FOUND]: "Reference profiles not found.",
  [DECRYPTING_PASSWORD]: "Error getting password.",
  [USER_ID_AND_PAYMENT_ID]: "UserID and paymentID are required.",
  [MESSAGES_REQUIRED]: "Messages are required.",
  [PLAN_IS_NO_ACTIVE]: "The plan is not active.",
  [USER_BANNED]: "This user is banned.",
  [GETTING_LOCALES]:
    "Error getting local files; please try again later or contact support.",
  [GETTING_PROFILE]:
    "Error getting profile data; please try again later or contact support.",
  [PRODUCTS_NOT_SENT]: "Products were not sent.",
  [INVITATION_NOT_FOUND]: "Invitation not found",
  [TOTAL_PRICE_EXCEEDED_VALUE_INVITATION]:
    "The total price exceeds the invitation value.",
  [ACCOUNT_NOT_FOUND]: "Account not found.",
  [COLLECTING_INFORMATION_RETRY_AFTER]:
    "Error collecting information; please try again later or contact support.",
  [ERROR_OCURRED]: "An unexpected error occurred, please try again later",
  [PLATFORM_NOT_SENT]: "Platform not sent.",
  [VERIFICATION_CODE_NOT_FOUND]: "Verification code not found.",
  [ORDER_NOT_FOUND]: "Order not found.",
  [INVITE_ID_NO_SENT]: "InviteID not sent.",
  [INVITE_NOT_FOUND]: "Invitation not found.",
  [INVITE_CLOUD_TASK_ID_NOT_FOUND]: "CloudTaskID for the invitation not found.",
  [TYPE_OR_PATH_NOT_SENT]: "File or path not sent.",
  [ID_OR_PATH_NOT_SENT]: "File ID or path not sent.",
  [IDS_NOT_SENT]: "IDs not sent",
  [ANALYTIC_URL_NOT_SENT]: "Analytic URL not sent.",
  [ANALYTIC_URL_INVALID]: "Invalid analytic URL.",
  [EXTRACTING_ANALYTICS]:
    "Error extracting analytics; please try again later or contact support.",
  [CREDENTIALS_INVALID]: "Invalid credentials.",
  [COLLABORATION_PRICE_NOT_BE_NEGATIVE]:
    "Collaboration price cannot be negative.",
  [CREDITS_NOT_BE_NEGATIVE]: "Credits cannot be negative.",
  [PACKAGES_PRICES_NOT_BE_NEGATIVE]: "Plan prices cannot be negative.",
  [ROL_REQUIRED]: "Role is required.",
  [SHOP_ID_AND_STORE_ID_REQUIRED]: "ShopID and storeID are required.",
  [PASSWORD_MUST_BE_6_CHARACTERS]: "Password must be at least 6 characters.",
  [ALL_USERS_NOT_DELETE]:
    "All users cannot be deleted; at least one must remain.",
  [SHOP_APPLICANT_NOT_FOUND]: "Brand application not found.",
  [USER_PASSWORD_NOT_FOUND]: "User password not found.",
  [PLAN_NOT_FOUND]: "Plan not found.",
  [SUBSCRIPTION_NOT_CREATED]: "Subscription not created.",
  [ID_SUBSCRIPTION_NOT_FOUND]: "Subscription ID not found.",
  [SUBSCRIPTION_REACTIVATE]: "Subscription reactivated.",
  [CUSTOMER_ID_NOT_FOUND]: "CustomerID not found.",
  [APPLICANT_NOT_FOUND]: "Applicant not found.",
  [SHOP_COUNTRY_NOT_FOUND]: "Brand country not found.",
  [FINAL_PRICE_NOT_FOUND]: "Could not obtain the final price.",
  [PAYMENT_FAILED]: "Payment failed",
  [CREDITS_MUST_BE_GREATER_THAN_ZERO]: "Credits must be greater than 0.",
  [AMBASSADOR_NOT_FOUND]: "Ambassador not found.",
  [CSRF_INVALID]: "Invalid CSRF",
  [INTEGRATION_INFORMATION_NOT_FOUND]: "Integration information not found.",
  [GETTING_VIDEO]: "Error getting the video.",
  [UPLOAD_URL_NOT_FOUND]: "Upload URL not found.",
  [UPLOADING_VIDEO]: "Error uploading the video.",
  [STORE_NOT_FOUND]: "Store not found.",
  [NAME_REQUIRED]: "Name is required.",
  [PRICE_REQUIRED]: "Price is required.",
  [CURRENCY_REQUIRED]: "Currency is required.",
  [RECURRING_REQUIRED]: "Recurring is required.",
  [CREATING_PRODUCT]: "Error creating the product.",
  [CREATING_PRICE]: "Error creating the price.",
  [CREATING_PAYMENT_LINK]: "Error creating the payment link.",
  [GETTING_PAYMENT_LINK]: "Error getting the payment link.",
  [TOKEN_INVALID]: "Token invalid",
  [TOKEN_EXPIRED]: "Token expired",
  [IG_ACCOUNT_NOT_FOUND]:
    "No linked Instagram account found to a Facebook page. Please ensure you have selected an Instagram account during integration or link one and try again.",
  [IG_DATA_NOT_FOUND]: "Instagram data not found",
  [REVIEW_NOT_FOUND]: "No review found for this collaboration",
  [REVIEW_IS_NOT_ONE_START]:
    "The review for this collaboration is not 1 star, a credit cannot be issued",
  [DISSATISFACTION_CREDIT_ALREADY_EXISTS]:
    "A free credit has already been assigned for this collaboration",
  [META_INTEGRATION_UNLINKED_PAGES_ERROR]:
    "You may not have linked your Instagram account to your Facebook or Meta Business page. Please link your accounts to proceed.",
  [PAYMENT_METHOD_NOT_FOUND]:
    "You don't have an added payment method, please add one to continue.",
  [HAS_PENDING_PAYMENTS]:
    "You have pending invoices, please make the payment to continue.",
  [NO_IMAGES_FOUND_ON_PAGE]: "No images found on the page",
  [NO_CREATE_DISCOUNT_SHOPIFY]:
    "We encountered an error from Shopify during integration, make sure you have selected the scopes 'read_price_rules', 'write_price_rules', 'write_discounts', and 'read_discounts' during the integration steps.",
  [NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY]:
    "We encountered an error from Shopify during integration, make sure you have selected the scope 'read_orders' during the integration steps.",
  [NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY]:
    "We encountered an error from Shopify during integration, make sure you have selected the scope 'read_fulfillments' during the integration steps.",
  [NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY]:
    "We encountered an error from Shopify during integration, make sure you have selected the scope 'read_fulfillments' during the integration steps.",
  [GETTING_INFORMATION_FROM_SHOPIFY_URL]:
    "Error getting Shopify settings, please make sure the URL is correct and the store access is public.",
  [RATE_DAILY_LIMIT_AI]:
    "You have reached the daily limit for using artificial intelligence features. Please try again tomorrow.",
  [RATE_DAILY_LIMIT_AI_CHAT]:
    "You have reached your Buzzly Help limit for today. Please try again tomorrow.",
  [PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META]:
    "Your account permissions or settings changed, please try again. If the error persists, disconnect and reconnect the account.",
  [ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS]:
    "Your Instagram account is not of the Business type or you did not select all the permissions, please change your account to business and try again. If the error persists, disconnect and reconnect the account.",
  [FACEBOOK_PAGE_ID_NOT_FOUND]:
    "No linked Facebook page found. Please ensure you have selected a page during integration or link one and try again.",
  [META_BUSINESS_ACCOUNT_NOT_FOUND]:
    "No linked business account found. Please ensure you have selected an business account during integration or link one and try again.",
  [IG_BUSINESS_ACCOUNT_NOT_FOUND]:
    "No linked Instagram account found to your general Meta account. Please ensure you have selected an Instagram account during integration or link one and try again.",
  [ACCOUNT_TIKTOK_AUTH_NOT_MATCH]:
    "We couldn't verify your account, please try again.",
  [ACCOUNT_TOKEN_META_NOT_FOUND]:
    "We couldn't find your Meta integration information, please try again or reconnect your account.",
  [ACCOUNT_ID_BUSINESS_META_NOT_FOUND]:
    "No linked Meta Business account found, please try again or link a Business account.",
  [OBJECTIVE_CAMPAIGN_META_INVALID]:
    "The selected campaign objective is not valid.",
  [SPECIAL_CATEGORY_CAMPAIGN_META_INVALID]:
    "The selected special category type is not valid.",
  [CAMPAIGN_META_NOT_DELETED]:
    "The campaign could not be deleted, please try again.",
  [CAMPAIGN_META_NOT_CREATED]:
    "The campaign could not be created, please try again.",
  [ADS_CAMPAIGN_NOT_FOUND]: "The ad campaign was not found.",
  [NO_PIXELS_FOUND]:
    "No pixels associated with your Meta account were found, please create one and try again.",
  [NO_PIXELS_ACTIVE]:
    "No active pixel associated with your Meta account, please activate one and try again.",
  [CAMPAIGN_META_NOT_FOUND]: "The campaign was not found in Meta.",
  [CAMPAIGN_META_NOT_UPDATED]:
    "The campaign could not be updated, please try again.",
  [CAMPAIGN_AD_SET_META_NOT_CREATED]:
    "The ad set could not be created, please try again.",
  [CAMPAIGN_AD_SET_META_NOT_UPDATED]:
    "The ad set could not be updated, please try again.",
  [CAMPAIGN_MEDIA_META_NOT_CREATED]:
    "The campaign video could not be uploaded, please try again.",
  [CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND]:
    "The campaign video thumbnail was not found.",
  [CAMPAIGN_CREATIVE_AD_META_NOT_CREATED]:
    "The ad creative template could not be created, please try again.",
  [CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED]:
    "The ad creative template could not be updated, please try again.",
  [CAMPAIGN_CREATIVE_AD_META_NOT_FOUND]:
    "The ad creative template was not found.",
  [CAMPAIGN_AD_META_NOT_CREATED]:
    "The ad could not be created, please try again.",
  [CAMPAIGN_AD_META_NOT_UPDATED]:
    "The ad could not be updated, please try again.",
  [CAMPAIGN_METRICS_META_NOT_FOUND]: "The campaign metrics were not found.",
  [CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE]:
    "You do not have enough balance to create a campaign, please recharge your account and try again.",
  [ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE]:
    "You do not have enough balance to activate the campaign, please recharge your account and try again.",
  [CAMPAIGN_AUDIENCE_IS_TOO_NARROW]:
    "Your target audience is too narrow, try changing your targeting criteria to reach a wider audience.",
  [CAMPAIGN_BUDGET_IS_TOO_LOW]: "Your budget is too low.",
  [IDENTITY_NOT_FOUND]:
    "We couldn't find a TikTok profile linked to your TikTok Business account, please link a profile and try again.",
  [VIDEO_ERROR_LOW_RESOLUTION]:
    "The video resolution is too low, try uploading a higher resolution video.",
  [VIDEO_ERROR_NO_BACKGROUND_AUDIO]:
    "The video has no audio or the audio is too low, try uploading a video with clear and audible audio.",
  [VIDEO_ERROR_ASPECT_RATIO]:
    "The video does not have a permitted aspect ratio, try uploading a video with a 1:1 (square), 9:16 (vertical), or 16:9 (horizontal) aspect ratio.",
  [VIDEO_ERROR_BLACK_EDGE]:
    "The video has black bars or edges, try uploading a video without borders.",
  [VIDEO_ERROR_ILLEGAL_DURATION]:
    "The video has an unsupported duration, try uploading a video between 5 and 60 seconds long.",
  [INTEGRATION_CURRENCY_NOT_FOUND]: "Currency integration not found",
  [USER_EMAIL_OR_PASSWORD_WRONG]: "Email or password is incorrect",
  [USER_NOT_FOUND]: "User not found with this email",
  [ERROR_NETWORK]:
    "A network error occurred, please check your internet connection and try again.",

  //ERROR COMPONENT
  [ERROR_COMPONENT_TITLE]: "Oops! An error occurred",
  [ERROR_COMPONENT_DESCRIPTION]:
    "This may be caused by a technical error. Try again by reloading the page and if the error persists contact support.",
  [ERROR_COMPONENT_BUTTON_RELOAD_PAGE]: "Reload page",
  [ERROR_COMPONENT_CURRENT_ROUTE]: "Current route {{value}}",

  //CATEGORIES
  [RESTAURANTS]: "Restaurants",
  [FOODS]: "Foods",
  [TRAVEL]: "Travels and tourism",
  [ENTERTAINMENT]: "Entertainment",
  [TECHNOLOGY]: "Technology",
  [FITNESS]: "Fitness",
  [FASHION]: "Fashion",
  [BEAUTY]: "Health & Beauty",
  [PETS]: "Pets",
  [HOME]: "Home Decor",

  //SUBCATEGORIES
  [RESTAURANTS_SUBCATEGORY_FOOD]: "Food",
  [RESTAURANTS_SUBCATEGORY_DRINKS]: "Drinks",
  [RESTAURANTS_SUBCATEGORY_DESSERTS]: "Desserts",
  [RESTAURANTS_SUBCATEGORY_COFFEE]: "Coffee",
  [RESTAURANTS_SUBCATEGORY_HOT_DRINKS]: "Hot Drinks",
  [RESTAURANTS_SUBCATEGORY_COLD_DRINKS]: "Cold Drinks",
  [RESTAURANTS_SUBCATEGORY_SOFT_DRINKS]: "Soft Drinks",
  [RESTAURANTS_SUBCATEGORY_FROZEN_DRINKS]: "Frozen Drinks",
  [RESTAURANTS_SUBCATEGORY_SUSHI]: "Sushi",
  [RESTAURANTS_SUBCATEGORY_ASIAN]: "Asian",
  [RESTAURANTS_SUBCATEGORY_JAPANESE]: "Japanese",
  [RESTAURANTS_SUBCATEGORY_CHINESE]: "Chinese",
  [RESTAURANTS_SUBCATEGORY_ITALIAN]: "Italian",
  [RESTAURANTS_SUBCATEGORY_MEXICAN]: "Mexican",
  [RESTAURANTS_SUBCATEGORY_HEALTHY]: "Healthy",
  [RESTAURANTS_SUBCATEGORY_VEGAN]: "Vegan",
  [RESTAURANTS_SUBCATEGORY_VEGETARIAN]: "Vegetarian",
  [FOODS_SUBCATEGORY_SEASONINGS]: "Seasonings",
  [FOODS_SUBCATEGORY_FOOD]: "Food",
  [FOODS_SUBCATEGORY_GRANOLA]: "Granola",
  [FOODS_SUBCATEGORY_DRIED_FRUITS]: "Dried Fruits",
  [FOODS_SUBCATEGORY_HEALTHY]: "Healthy",
  [TRAVEL_SUBCATEGORY_BEACH_SUN_TOURISM]: "Beach and Sun Tourism",
  [TRAVEL_SUBCATEGORY_RURAL_TOURISM]: "Rural Tourism",
  [TRAVEL_SUBCATEGORY_GASTRONOMIC_TOURISM]: "Gastronomic Tourism",
  [TRAVEL_SUBCATEGORY_NATURE_TOURISM]: "Nature Tourism",
  [TRAVEL_SUBCATEGORY_CULTURAL_TOURISM]: "Cultural Tourism",
  [TRAVEL_SUBCATEGORY_HEALTH_TOURISM]: "Health Tourism",
  [ENTERTAINMENT_SUBCATEGORY_ENTERTAINMENT]: "Entertainment",
  [TECHNOLOGY_SUBCATEGORY_PHONES]: "Phones",
  [TECHNOLOGY_SUBCATEGORY_COMPUTERS]: "Computers",
  [TECHNOLOGY_SUBCATEGORY_VIDEO_GAME_CONSOLES]: "Video Game Consoles",
  [TECHNOLOGY_SUBCATEGORY_ACCESSORIES]: "Accessories",
  [TECHNOLOGY_SUBCATEGORY_PERIPHERALS]: "Peripherals",
  [TECHNOLOGY_SUBCATEGORY_ELECTRONICS]: "Electronics",
  [FITNESS_SUBCATEGORY_SPORTSWEAR]: "Sportswear",
  [FITNESS_SUBCATEGORY_SPORTS_ACCESSORIES]: "Sports Accessories",
  [FITNESS_SUBCATEGORY_DIETARY_SUPPLEMENTS]: "Dietary Supplements",
  [FITNESS_SUBCATEGORY_TRAINING_EQUIPMENT]: "Training Equipment",
  [FASHION_SUBCATEGORY_HAUTE_COUTURE]: "Haute Couture Fashion",
  [FASHION_SUBCATEGORY_HIPSTER]: "Hipster Fashion",
  [FASHION_SUBCATEGORY_CASUAL]: "Casual Fashion",
  [FASHION_SUBCATEGORY_CLASSIC]: "Classic Fashion",
  [FASHION_SUBCATEGORY_ROMANTIC]: "Romantic Fashion",
  [FASHION_SUBCATEGORY_SPORTSWEAR]: "Sportswear Fashion",
  [FASHION_SUBCATEGORY_PREPPY]: "Preppy Fashion",
  [FASHION_SUBCATEGORY_GRUNGE]: "Grunge Fashion",
  [FASHION_SUBCATEGORY_VINTAGE]: "Vintage Fashion",
  [BEAUTY_SUBCATEGORY_COSMETICS]: "Cosmetics",
  [BEAUTY_SUBCATEGORY_EYE_CARE]: "Eye Care",
  [BEAUTY_SUBCATEGORY_FEMININE_HYGIENE]: "Feminine Hygiene",
  [BEAUTY_SUBCATEGORY_FIRST_AID]: "First Aid",
  [BEAUTY_SUBCATEGORY_HAIR_CARE]: "Hair Care",
  [BEAUTY_SUBCATEGORY_MEDICAL_DEVICES]: "Medical Devices",
  [BEAUTY_SUBCATEGORY_MOBILITY_EQUIPMENT]: "Mobility Equipment",
  [BEAUTY_SUBCATEGORY_NUTRITIONAL_SUPPLEMENTS]: "Nutritional Supplements",
  [BEAUTY_SUBCATEGORY_MEDICATIONS]: "Medications",
  [BEAUTY_SUBCATEGORY_PERSONAL_HYGIENE]: "Personal Hygiene",
  [BEAUTY_SUBCATEGORY_SCALES]: "Scales",
  [BEAUTY_SUBCATEGORY_SKIN_CARE]: "Skin Care",
  [BEAUTY_SUBCATEGORY_VITAMINS]: "Vitamins",
  [BEAUTY_SUBCATEGORY_FACIAL_TISSUES]: "Facial Tissues",
  [PETS_SUBCATEGORY_FOOD]: "Pet Food",
  [PETS_SUBCATEGORY_COSMETICS]: "Pet Cosmetics",
  [PETS_SUBCATEGORY_ACCESSORIES]: "Pet Accessories",
  [PETS_SUBCATEGORY_CLOTHING]: "Pet Clothing",
  [PETS_SUBCATEGORY_MEDICATIONS]: "Pet Medications",
  [HOME_SUBCATEGORY_TEXTILES_CARPETS]: "Textiles and Carpets",
  [HOME_SUBCATEGORY_ART_PRINTS]: "Art Prints",
  [HOME_SUBCATEGORY_STORAGE_ORGANIZATION]: "Storage and Organization",
  [HOME_SUBCATEGORY_PLANT_DECOR]: "Plant Decorations",
  [HOME_SUBCATEGORY_CURTAINS_BLINDS]: "Curtains and Blinds",

  //OPTIONS
  [SEARCH_INFLUENCERS_TOPICS]: "topic",
  [SEARCH_INFLUENCERS_USERNAME]: "username",

  [BODY_BUILD_THIN]: "Thin",
  [BODY_BUILD_AVERAGE]: "Average",
  [BODY_BUILD_WIDE]: "Wide",
  [BODY_BUILD_MUSCULAR]: "Muscular",
  [BODY_BUILD_CURVY]: "Curvy",
  [BODY_BUILD_ATHLETIC]: "Athletic",

  [CLOTHING_STYLE_CASUAL]: "Casual",
  [CLOTHING_STYLE_FORMAL]: "Formal",
  [CLOTHING_STYLE_SPORTY]: "Sporty",
  [CLOTHING_STYLE_BUSINESS]: "Business",
  [CLOTHING_STYLE_ELEGANT]: "Elegant",
  [CLOTHING_STYLE_TRENDY]: "Trendy",
  [CLOTHING_STYLE_STREETWEAR]: "Streetwear",
  [CLOTHING_STYLE_TRADITIONAL]: "Traditional",
  [CLOTHING_STYLE_VINTAGE]: "Vintage",
  [CLOTHING_STYLE_BOHEMIAN]: "Bohemian",
  [CLOTHING_STYLE_HIPSTER]: "Hipster",
  [CLOTHING_STYLE_PUNK]: "Punk",
  [CLOTHING_STYLE_GOTHIC]: "Gothic",

  [DISTINGUISHING_FEATURES_TATTOOS]: "Tattoos",
  [DISTINGUISHING_FEATURES_SCARS]: "Scars",
  [DISTINGUISHING_FEATURES_BIRTHMARKS]: "Birthmarks",
  [DISTINGUISHING_FEATURES_FRECKLES]: "Freckles",
  [DISTINGUISHING_FEATURES_MOLES]: "Moles",
  [DISTINGUISHING_FEATURES_PIERCINGS]: "Piercings",
  [DISTINGUISHING_FEATURES_DIMPLES]: "Dimples",
  [DISTINGUISHING_FEATURES_WRINKLES]: "Wrinkles",
  [DISTINGUISHING_FEATURES_GLASSES]: "Glasses",
  [DISTINGUISHING_FEATURES_BRACES]: "Braces",
  [DISTINGUISHING_FEATURES_MAKEUP]: "Makeup",
  [DISTINGUISHING_FEATURES_HAIR_ACCESSORIES]: "Hair Accessories",
  [DISTINGUISHING_FEATURES_BEARD]: "Beard",
  [DISTINGUISHING_FEATURES_MUSTACHE]: "Mustache",

  [CONTENT_STYLE_ELEGANT]: "Elegant",
  [CONTENT_STYLE_AESTHETIC]: "Aesthetic",
  [CONTENT_STYLE_RAW]: "Raw",
  [CONTENT_STYLE_MINIMALISTIC]: "Minimalistic",
  [CONTENT_STYLE_ARTISTIC]: "Artistic",
  [CONTENT_STYLE_VINTAGE]: "Vintage",
  [CONTENT_STYLE_MODERN]: "Modern",
  [CONTENT_STYLE_PLAYFUL]: "Playful",
  [CONTENT_STYLE_RUSTIC]: "Rustic",
  [CONTENT_STYLE_MONOCHROMATIC]: "Monochromatic",

  [EMPLOYEES_2_10]: "2-10 Employees",
  [EMPLOYEES_10_20]: "10-20 Employees",
  [EMPLOYEES_20_50]: "20-50 Employees",
  [EMPLOYEES_50_100]: "50-100 Employees",
  [EMPLOYEES_100_THAN]: "100+ Employees",

  [PRODUCT_AVAILABILITY_LOCAL]: "Locally (in my city)",
  [PRODUCT_AVAILABILITY_NATIONAL]: "Nationally",
  [PRODUCT_AVAILABILITY_INTERNATIONAL]: "Globally",

  [META]: "Meta",
  [META_FACEBOOK_AND_INSTAGRAM]: "Meta (Facebook and Instagram)",
  [INSTAGRAM]: "Instagram",
  [TIKTOK]: "TikTok",
  [LINKEDIN]: "LinkedIn",
  [GOOGLE]: "Google",
  [DARK_KITCHEN]: "Dark kitchens",
  [OTHERS]: "Others",

  [ADS_CAMPAIGN_OBJECTIVE_AWARENESS]: "Awareness",
  [ADS_CAMPAIGN_OBJECTIVE_TRAFFIC]: "Traffic",
  [ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT]: "Engagement",
  [ADS_CAMPAIGN_OBJECTIVE_LEADS]: "Leads",
  [ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION]: "App promotion",
  [ADS_CAMPAIGN_OBJECTIVE_SALES]: "Sales",
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY]: "Daily budget",
  [ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME]: "Total budget",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU]: "See menu",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW]: "Shop now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP]: "Sign up",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE]: "Subscribe",
  [ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE]: "Watch more",
  [ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW]: "Apply now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW]: "Book now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_CALL_NOW]: "Call now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US]: "Contact us",
  [ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD]: "Download",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS]: "Get directions",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE]: "Get quote",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES]: "Get showtimes",
  [ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE]: "Learn more",
  [ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW]: "Listen now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE]: "Send message",
  [ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW]: "Order now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME]: "Request time",
  [ADS_CAMPAIGN_CALL_TO_ACTION_SAVE]: "Save",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER]: "Get offer",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_PROMOTIONS]: "Get promotions",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_ACCESS]: "Get access",
  [ADS_CAMPAIGN_CALL_TO_ACTION_GET_UPDATES]: "Get updates",
  [ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW]: "Inquire now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS]: "Buy tickets",
  [ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME]: "Play game",
  [ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE]: "Explore more",
  [ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW]: "Install now",
  [ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP]: "Use app",

  [LESS_500K]: "Less than $500,000.00",
  [BETWEEN_500K_1M]: "$500,000.00 - $1,000,000.00",
  [BETWEEN_1M_2_5M]: "$1,000,000.00 - $2,500,000.00",
  [BETWEEN_2_5M_5M]: "$2,500,000.00 - $5,000,000.00",
  [MORE_5M]: "More than $5,000,000.00",
  [LESS_1M]: "Less than $1,000,000.00",
  [BETWEEN_1M_5M]: "$1,000,000.00 - $5,000,000.00",
  [BETWEEN_5M_15M]: "$5,000,000.00 - $15,000,000.00",
  [BETWEEN_15M_30M]: "$15,000,000.00 - $30,000,000.00",
  [BETWEEN_30M_100M]: "$30,000,000.00 - $100,000,000.00",
  [MORE_100M]: "More than $100,000,000.00",
  [LESS_150]: "Less than $150",
  [BETWEEN_150_250]: "$150.00 - $250.00",
  [BETWEEN_250_650]: "$250.00 - $650.00",
  [BETWEEN_650_1250]: "$650.00 - $1,250.00",
  [MORE_1250]: "More than $1,250.00",
  [LESS_250]: "Less than $250.00",
  [BETWEEN_250_1250]: "$250.00 - $1,250.00",
  [BETWEEN_1250_3750]: "$1,250.00 - $3,750.00",
  [BETWEEN_3750_7500]: "$3,750.00 - $7,500.00",
  [BETWEEN_7500_25K]: "$7,500.00 - $25,000.00",
  [MORE_25K]: "More than $25,000.00",
  [LESS_1000]: "Less than $1,000.00",
  [BETWEEN_1000_5000]: "$1,000.00 - $5,000.00",
  [BETWEEN_5000_15K]: "$5,000.00 - $15,000.00",
  [BETWEEN_15K_30K]: "$15,000.00 - $30,000.00",
  [BETWEEN_30K_60K]: "$30,000.00 - $60,000.00",
  [MORE_60K]: "More than $60,000.00",
  [BETWEEN_1000_2000]: "$1,000.00 - $2,000.00",
  [BETWEEN_2000_5000]: "$2,000.00 - $5,000.00",
  [BETWEEN_5000_10K]: "$5,000.00 - $10,000.00",
  [MORE_10K]: "More than $10,000.00",

  [DOCUMENT_CC]: "Citizenship card",
  [DOCUMENT_CE]: "Foreigner ID",
  [DOCUMENT_NUIP]: "NUIP",
  [DOCUMENT_NIT]: "NIT",
  [DOCUMENT_CI]: "Identity card",
  [DOCUMENT_PASSPORT]: "Passport",
  [DOCUMENT_EIN]: "EIN",
  [DOCUMENT_RUC]: "RUC",
  [DOCUMENT_ID]: "ID",
  [DOCUMENT_ID_EMIRATES]: "Emirates ID",
  [DOCUMENT_TRN]: "TRN",

  //EXPLANATIONS TOOLTIP
  [ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION]:
    "What is the awareness objective?",
  [ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION]: `Ads will be shown to people who are more likely to remember them. This objective is ideal for increasing brand or product awareness. <br><br>
    Good for:<br>
    <li><strong>Reach:</strong> Target individuals who are more likely to recall your brand.</li>
    <li><strong>Brand awareness:</strong> Aim to make your brand memorable to potential customers.</li>
    <li><strong>Video views:</strong> Engage people who are more likely to view your video content.</li>
    <li><strong>Store location awareness:</strong> Attract people using your business's physical location.</li>
    `,
  [ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION]:
    "What is the traffic objective?",
  [ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION]: `Ads will be shown to people who are more likely to click on them. This objective is ideal for increasing traffic to your website or app. <br><br>
    Good for:<br>
    <li><strong>Link clicks:</strong> Encourage individuals to click on a link to your website, app, or event page.</li>
    <li><strong>Landing page views:</strong> Drive traffic to view the content on your landing page.</li>
    <li><strong>Instagram profile visits:</strong> Motivate people to visit your Instagram profile.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION]:
    "What is the engagement objective?",
  [ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION]: `Ads will be shown to people who are more likely to interact with them. This objective is ideal for increasing comments, likes, shares, and clicks on your ad. <br><br>
    Good for:<br>
    <li><strong>Video views:</strong> Attract viewers who are likely to watch your videos.</li>
    <li><strong>Post engagement:</strong> Encourage interactions with your social media posts.</li>
    <li><strong>Conversions:</strong> Prompt individuals to view content, contact your business, add items to a wishlist, or perform other actions on your website or app.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION]:
    "What is the leads objective?",
  [ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION]: `Ads will be shown to people who are more likely to provide contact information. This objective is ideal for increasing lead registrations.<br><br>
    Good for:<br>
    <li><strong>Instant forms:</strong> Find individuals willing to provide their contact details and other information by filling out a form.</li>
    <li><strong>Conversions:</strong> Encourage people to share their contact information, complete registration forms, submit applications, or perform other actions on your website or app.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION]:
    "What is the app promotion objective?",
  [ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION]: `Ads will be shown to people who are more likely to download your app. This objective is ideal for increasing app downloads.<br><br>
    Good for:<br>
    <li><strong>App installs:</strong> Encourage individuals to download and install your app.</li>
    <li><strong>App events:</strong> Motivate users to make in-app purchases or perform other actions within your app.</li>`,
  [ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION]:
    "What is the sales objective?",
  [ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION]: `Ads will be shown to people who are more likely to make a purchase. This objective is ideal for increasing sales of your product or service.<br><br>
    Good for:<br>
    <li><strong>Catalog sales:</strong> Encourage people to purchase products or services from your catalog.</li>
    <li><strong>Conversions:</strong> Get individuals to add items to their cart, make purchases, start subscriptions, or perform other actions on your website or app.</li>`,
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION]:
    "What does a daily budget type mean?",
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION]:
    "A daily budget is the maximum amount you are willing to spend per day on your campaign. The social network will try not to exceed this limit but may spend slightly more on days with more opportunities to show your ads.",
  [ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION]:
    "What does a total budget type mean?",
  [ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION]:
    "A total budget is the maximum amount you are willing to spend for your entire campaign. The social network will try not to exceed this limit but may spend slightly more on days with more opportunities to show your ads.",

  //ONBOARDING

  //MAIN FUNCTIONALITY
  [MAIN_FUNCTIONALITY_STEP_1]: `This is the Content Creators section. Here, you will find a list of creators who are registered on {{NAME_BUSINESS}} and available to collaborate with your brand.`,
  [MAIN_FUNCTIONALITY_STEP_2]:
    "You can use filters to search for content creators by their account name, categories, city, number of followers, audience engagement percentage, and more.",
  [MAIN_FUNCTIONALITY_STEP_3]: `You have three sections to search for content creators: <br/><br/>
    <strong>Creators:</strong> This is the general list of all creators registered on {{NAME_BUSINESS}}.<br/>
    <strong>For you:</strong> This is the list of creators that match the creator profile you are interested in.<br/>
    <strong>Requests:</strong> This is the list of creators who are interested in your brand and send you a collaboration request.
    `,
  [MAIN_FUNCTIONALITY_STEP_4]:
    "This is the general information of the creator, you can click on each card to access more complete information. Click on the card.",
  [MODAL_INVITATION_STEP_1]:
    "This is the content creator's information, where you can see their social network, audience, engagement, and overall ratings on {{NAME_BUSINESS}}.",
  [MODAL_INVITATION_STEP_2]:
    "If you want to see more comprehensive and detailed information, you should click the View Report button.",
  [MODAL_INVITATION_STEP_3]:
    "These are the actions you can perform with the creator.",
  [MODAL_INVITATION_STEP_4]:
    "<strong>Invite to campaign:</strong> You can invite a creator to participate in an active paid campaign you've created, or create one if you don't have one.",
  [MODAL_INVITATION_STEP_5]:
    "<strong>Invite to gift:</strong> You can invite a creator to collaborate with your brand through the gifting of your products or services.",
  [MODAL_INVITATION_STEP_6]:
    "<strong>Send payment:</strong> You can securely and easily send a payment to the creator.",
  [MODAL_INVITATION_STEP_7]:
    "<strong>Chat now:</strong> You can send a message to the creator directly from the platform.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_1]:
    "Once creators redeem their invitations, posts will be generated. To go to the posts section, click on the Posts button in the side menu.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_2]:
    "Posts can have multiple states. The first is Pending Post, which occurs when the creator has redeemed their invitation but has not yet published the generated content.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_3]:
    "The second is Pending Analytics, which happens when the creator has published the generated content but has not yet published the analytics to view collaboration results.",
  [PUBLICATIONS_FUNCTIONALITY_STEP_4]:
    "The third is Review, which occurs when the creator has published the generated content and analytics. Now you should review them, rate the creator, and consider making them an ambassador. Click the Review button.",
  [PUBLICATION_FUNCTIONALITY_STEP_1]:
    "Here, you will see the analytics and results generated by the content creator's content.",
  [PUBLICATION_FUNCTIONALITY_STEP_2]:
    "Here, you will see all the content that the content creator generated for your brand.",
  [PUBLICATION_FUNCTIONALITY_STEP_3]:
    "This is the button to rate the content creator. Click the Rate button.",
  [REVIEW_FUNCTIONALITY_STEP_1]:
    "This is the form for rating the content creator. Your review information will not be shared with the creator.",
  [REVIEW_FUNCTIONALITY_STEP_2]:
    "You can rate the content of the content creator on a scale of 1 to 5, indicating how good the generated content was.",
  [REVIEW_FUNCTIONALITY_STEP_3]:
    "You can rate the analytics of the content creator on a scale of 1 to 5, indicating how good the generated results were.",
  [REVIEW_FUNCTIONALITY_STEP_4]:
    "Describe in text how the overall experience was with the collaboration. This text must be at least 10 characters long.",
  [REVIEW_FUNCTIONALITY_STEP_5]:
    "Once the form is filled out, to submit your review, click the Finish button.",
  [MAIN_FUNCTIONALITY_ONBOARDING_FINISH]:
    "You have completed the platform tutorial. Now you know where to find content creators, view their information, and perform various actions with them.",

  //MENU FUNCTIONALITY
  [MENU_FUNCTIONALITY_STEP_1]:
    "Here, you can select the store for which you want to manage its catalog.",
  [MENU_FUNCTIONALITY_STEP_2]:
    "You can add a category here to organize your products or services. Click the button and enter the category name.",
  [MENU_FUNCTIONALITY_STEP_3]: "This is the new category you've just created.",
  [MENU_FUNCTIONALITY_STEP_4]: "Here, you can add a product or service.",
  [MENU_FUNCTIONALITY_STEP_5]:
    "Click the button to upload an image for the product.",
  [MENU_FUNCTIONALITY_STEP_6]: "Enter the name of the product and click Next.",
  [MENU_FUNCTIONALITY_STEP_7]:
    "Enter the description of the product and click Next.",
  [MENU_FUNCTIONALITY_STEP_8]: "Enter the price of the product and click Next.",
  [MENU_FUNCTIONALITY_STEP_9]:
    "Indicate if the product is harmful to health or for adults by clicking the checkbox.",
  [MENU_FUNCTIONALITY_STEP_10]:
    "Once the form is filled out, to create the product, click the Create button.",
  [MENU_FUNCTIONALITY_STEP_11]: "This is the product you've just created.",
  [MENU_FUNCTIONALITY_STEP_12]:
    "Click to activate/deactivate a product based on your inventory.",

  //USERS FUNCTIONALITY
  [USERS_FUNCTIONALITY_STEP_1]: "This is an Administrator user.",
  [USERS_FUNCTIONALITY_STEP_2]:
    "The Administrator role has access to all functionalities, meaning it can manage invitations to creators, manage posts, modify brand information, manage payment information, and more.",
  [USERS_FUNCTIONALITY_STEP_3]: "This is an Operator user.",
  [USERS_FUNCTIONALITY_STEP_4]:
    "The Operator role has access only to the functionalities of redeeming creators' codes who arrive at your physical store, managing orders/deliveries made by creators via the app, and activating/deactivating products based on inventory.",

  //MAIN FUNCTIONALITY OPERATOR
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_1]:
    "With the redemption bar, you can enter a code and attempt to redeem it. Enter any code and click Redeem.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_2]:
    "Here is the code you just redeemed and the value of the invitation. This is the value that will be granted to the content creator. If what the creator requests is higher than the invitation value, they will be charged the excess.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_3]: `This is the Orders section. Here, you can view the list of orders placed by content creators via delivery through the {{NAME_BUSINESS}} app. Orders/deliveries have 3 states.`,
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_4]:
    "The first is Pending Acceptance. This is when a content creator has placed an order, is waiting for you to accept it, and assign a process time.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_5]:
    "The second is Processing. This is when a content creator has placed an order, you've accepted it, and assigned a process time. You should now communicate the processing of the order",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_6]:
    "The third is Pending Delivery. This is when the order, which was in processing, is ready, and you've dispatched it using a delivery method. Once you are notified of the delivery or the creator marks it as delivered, the order will disappear from here.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_7]:
    "A new order has just come in. The platform will start making a sound until you see the new order. Click to view it.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_8]:
    "Here are the products that the content creator has ordered. You can see the quantity and price of each product.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_9]:
    "Here is the message left by the content creator, which can be instructions or recommendations for the delivery.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_10]:
    "Here is the total value of the order.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_11]:
    "Here is the content creator who placed the order and their information.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_12]:
    "Here, you can specify the order's processing and delivery time, which can be in minutes, hours, days, or weeks.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_13]:
    "Here, you can indicate the amount of time for order processing and delivery. You can add or subtract time.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_14]:
    "Once you have reviewed the information and assigned the order's processing time, you can click the Accept Order button to move it to processing.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_15]:
    "This is the order you have just accepted, and its status has changed.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_16]:
    "Here, you can see how much processing time is remaining. Click to view the order details again.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_17]:
    "Once the order is ready, you should assign delivery information to it.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_18]:
    "Once the form is filled, click the Order Ready button to mark the order as ready.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_19]:
    "This is the order you have just dispatched, and its status has changed. Here, you will find the delivery information you assigned to the order. Once you are informed that the order has been delivered, you should mark it as delivered. Click to see the order details.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_20]:
    "After reviewing the information and being informed of the delivery to the content creator, you should mark the order as delivered. Click the Order Delivered button for this.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_21]:
    "This is the catalog section. Here, you can view the list of products available in your brand's catalog.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_22]:
    "Here is the list of categories, including the test category.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_23]:
    "Here is the list of products, including the test product.",
  [MAIN_FUNCTIONALITY_OPERATOR_STEP_24]:
    "As an operator, you can only activate or deactivate products based on inventory availability. To deactivate/activate a product, click the button.",

  //AMBASSADOR FUNCTIONALITY
  [AMBASSADORS_FUNCTIONALITY_STEP_1]:
    "This is the Ambassadors page. Ambassadors are content creators you choose to work with more closely after a collaboration.",
  [AMBASSADORS_FUNCTIONALITY_STEP_2]:
    "It all starts when you want to rate a creator. Click the Rate button.",
  [AMBASSADORS_FUNCTIONALITY_STEP_3]: "Complete this part of the form.",
  [AMBASSADORS_FUNCTIONALITY_STEP_4]:
    "When you want to make a creator an ambassador, you should select the Yes option.",
  [AMBASSADORS_FUNCTIONALITY_STEP_5]:
    "You should assign a value for the ambassador's recurring invitation.",
  [AMBASSADORS_FUNCTIONALITY_STEP_6]:
    "You should specify the recurrence of the ambassador's invitation, i.e., how often they will collaborate with your brand.",
  [AMBASSADORS_FUNCTIONALITY_STEP_7]:
    "Once the form is filled, to complete the rating, click the Finish button.",
  [AMBASSADORS_FUNCTIONALITY_STEP_8]:
    "When you create an ambassador, you will be redirected to the Ambassadors page.",
  [AMBASSADORS_FUNCTIONALITY_STEP_9]:
    "This is the ambassador you just created and their information.",
  [AMBASSADORS_FUNCTIONALITY_STEP_10]:
    "Here, you can make payments to ambassadors.",
  [AMBASSADORS_FUNCTIONALITY_STEP_11]:
    "Here, you can send a global message to your ambassadors.",

  //DISCOVERY INFLUENCERS FUNCTIONALITY
  [DISCOVERY_FUNCTIONALITY_STEP_1]: `This is the Explore page. Here, you can search for content creators using various filters who are not registered on {{NAME_BUSINESS}}.`,
  [DISCOVERY_FUNCTIONALITY_STEP_2]:
    "With filters, you can search for content creators filtered by their platform, categories, location, number of followers, audience engagement percentage, gender, interests, age, keywords, etc.",
  [DISCOVERY_FUNCTIONALITY_STEP_3]:
    "For example, we will search for the creator 'gabrielatafur'.",
  [DISCOVERY_FUNCTIONALITY_STEP_4]: "On the Instagram platform.",
  [DISCOVERY_FUNCTIONALITY_STEP_5]: "Click the Search button.",
  [DISCOVERY_FUNCTIONALITY_STEP_6]:
    "Here, you will find information about the creator 'gabrielatafur', including her location, number of followers, engagement, and average likes.",
  [DISCOVERY_FUNCTIONALITY_STEP_7]:
    "You can request complete statistics by clicking the View Statistics button.",
  [DISCOVERY_FUNCTIONALITY_STEP_8]:
    "Once the statistics are ready, clicking this button will open another page where you can view her complete statistics. In this case, it won't open. Click the View Statistics button.",
  [DISCOVERY_FUNCTIONALITY_STEP_9]: `After you've seen the statistics, if the creator has not been invited to {{NAME_BUSINESS}} or is not registered on the platform, you can invite her by clicking the Invite button.`,
  [DISCOVERY_FUNCTIONALITY_STEP_10]:
    "You can assign a value to the invitation.",
  [DISCOVERY_FUNCTIONALITY_STEP_11]: `Click the Invite to {{NAME_BUSINESS}} button.`,
  [DISCOVERY_FUNCTIONALITY_STEP_12]: `Here, it will show that she has been invited. We will inform the creator, and we'll notify you if she registers on {{NAME_BUSINESS}}.`,
  [DISCOVERY_FUNCTIONALITY_STEP_13]:
    "You can also search for multiple creators who meet different filters. For example, we will search for TikTok creators.",
  [DISCOVERY_FUNCTIONALITY_STEP_14]: "With a minimum of 50,000 followers.",
  [DISCOVERY_FUNCTIONALITY_STEP_15]: "And a maximum of 100,000 followers.",
  [DISCOVERY_FUNCTIONALITY_STEP_16]: "Click the Search button.",
  [DISCOVERY_FUNCTIONALITY_STEP_17]:
    "Here, all the results for creators who meet this requirement will be displayed. You can also request complete statistics, invite them, and more.",

  //CAMPAIGNS FUNCTIONALITY
  [CAMPAIGNS_FUNCTIONALITY_STEP_1]:
    "This is the Campaigns page. Here, you can create campaigns to generate more personalized collaborations with creators.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_2]:
    "Let's start by creating a campaign. Click on Create Campaign.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_3]:
    "In the center of the screen, questions for your campaign will appear.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_4]:
    "In the first question, you should write the name of your campaign.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_5]:
    "Once you've answered the question, click the Next button.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_6]:
    "After clicking the Next button, the next question will appear.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_7]:
    "Here, you can select the question you want to view. Only the questions you've viewed will be shown.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_8]:
    "The form may be quite extensive, so you can save your progress by clicking Save Progress.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_9]:
    "Once you save your progress, you'll be redirected to the campaign page.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_10]:
    "This is the Applicants section, where you can see the creators who have applied to your campaign. You can view their information, statistics, accept them, or reject them.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_11]:
    "Here, you can also use filters to view the applicants that interest you.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_12]:
    "This is a creator who applied to your campaign.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_13]:
    "This is the section for Invited Creators, where you will find the creators who interested you for your campaign and whom you invited.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_14]:
    "In this section, you will find information about your campaign. Here, you can complete the missing information and activate the campaign so that creators can apply.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_15]:
    "With this button, you can save the changes to your campaign, and it will remain in a deactivated state, meaning creators cannot apply yet.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_16]:
    "With this button, you can activate the campaign. You must have all the required information to do this. In this state, creators can apply to your campaign.",
  [CAMPAIGNS_FUNCTIONALITY_STEP_17]:
    "With this button, you can mark the campaign as completed. After this, no creator can apply, and you cannot activate it again.",

  //STORES FUNCTIONALITY
  [STORES_FUNCTIONALITY_STEP_1]:
    "This is the Stores page. Here, you can create all the branches your brand has, assign their information, and delivery methods.",
  [STORES_FUNCTIONALITY_STEP_2]:
    "Let's start by creating a store. Click on Create Store.",
  [STORES_FUNCTIONALITY_STEP_3]:
    "This is all the required information to create a store. Fill in all the available fields, and once they are filled, click the Create button.",

  //MODAL FUNCTIONALITY
  [MODAL_FUNCTIONALITY_CAMPAIGNS]:
    "https://clapp-prod.s3.amazonaws.com/Buzzly/campaigns-en.png",
  [MODAL_FUNCTIONALITY_AMBASSADOR]:
    "https://clapp-prod.s3.amazonaws.com/Buzzly/ambassadors-en.png",
  [MODAL_FUNCTIONALITY_DISCOVERY]:
    "https://clapp-prod.s3.amazonaws.com/Buzzly/discovery-en.png",
  [MODAL_FUNCTIONALITY_AUTO_INVITES]:
    "https://clapp-prod.s3.amazonaws.com/Buzzly/autoInvites-en.webp",
};

export default translation;
