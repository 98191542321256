import React from "react";
import { useSelectorApp } from "../../../../../lib/redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST,
  BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE,
} from "../../../../../locales/keysTranslations";
import { useLocale } from "../../../../../Contexts/LocaleContext";
import { useTranslationApp } from "../../../../../lib/i18next";

const UsageChargeInfo = ({ data }) => {
  const { costCollaboration } = useSelectorApp((state) => state.shop);

  const LOCALE = useLocale();

  const { t } = useTranslationApp();
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          mb: 1,
        }}
      >
        {t(BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_TITLE)}
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t(BILLING_AND_PAYMENT_MODEL_USAGE_COLLABORATION_COST, {
          value: costCollaboration,
          currency: LOCALE.currency,
        })}
      </Typography>
    </Box>
  );
};

export default UsageChargeInfo;
