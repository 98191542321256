import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchBar from "../components/Common/SearchBar/SearchBar";
import KanbanDelivery from "../components/User/Delivery/KanbanDelivery";
import Loader from "../components/Common/Loader/Loader";
import { startGetStores } from "../actions/shops";
import { Link } from "react-router-dom";
import Button from "../components/Common/Buttons/Button";
import SelectStore from "../components/Common/Selects/SelectStore";
import {
  resetUIReducer,
  setActiveSoundDelivery,
  setShowBackdrop,
} from "../actions/ui";
import intervalToDuration from "date-fns/intervalToDuration";
import {
  startChangeShopSelected,
  startChangeStoreSelected,
} from "../actions/auth";
import {
  ADMIN_ROLE,
  OPERATOR_ROLE,
  SUPER_ADMIN_ROLE,
} from "../utils/constants";
import BlockPlanNoActive from "../components/Common/Block/BlockPlanNoActive";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTour } from "@reactour/tour";
import { hasNewDeliveries, setAllDeliveries } from "../actions/deliveries";
import { DUMMY_DELIVERY, DUMMY_ORDER } from "../onboarding/stepsSelectors";
import { useTranslationApp } from "../lib/i18next";
import {
  BUTTON_ADD_STORE,
  NO_STORES_CREATED,
  ORDERS_DESCRIPTION,
  ORDERS_REMEMBER_MARK_AS_DELIVERED,
  ORDERS_SEARCHBAR_PLACEHOLDER,
  ORDERS_TITLE,
} from "../locales/keysTranslations";
import { lowerCaseFirstLetter } from "../utils/formats";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const Delivery = () => {
  const [ordenSelected, setOrdenSelected] = useState(null);
  const [storeSelected, setStoreSelected] = useState("");
  const [data, setData] = useState({
    toBeAccepted: {},
    inPreparation: {},
    toBeDelivered: {},
  });
  const [allData, setAllData] = useState({
    toBeAccepted: {},
    inPreparation: {},
    toBeDelivered: {},
  });

  const { toBeAccepted, inPreparation, toBeDelivered, allStoresDeliveries } =
    useSelectorApp((state) => state.deliveries);
  const { user } = useSelectorApp((state) => state.auth);
  const { stores, isLoading: isLoadingStores } = useSelectorApp(
    (state) => state.shop
  );
  const storesKeys = Object.keys(stores || {});

  const { t } = useTranslationApp();

  const { isOpen, steps, currentStep, setCurrentStep } = useTour();

  const [params] = useSearchParams();

  const shopID = params.get("shopID");
  const storeID = params.get("storeID");

  const dispatch = useDispatchApp();

  const changeShopSelected = async () => {
    if (
      user.role === SUPER_ADMIN_ROLE &&
      user.shopIDs.length > 0 &&
      user.shopIDs.includes(shopID) &&
      user.shopID !== shopID
    ) {
      dispatch(setShowBackdrop(true));
      await dispatch(startChangeShopSelected(shopID));
      dispatch(setShowBackdrop(false));
    }
  };

  useEffect(() => {
    if (isOpen) {
      const step = steps[currentStep];
      if (step?.actionID === "createDelivery") {
        dispatch(
          setAllDeliveries({
            toBeAccepted: {
              [DUMMY_ORDER]: {
                shopID,
                storeID,
                ...DUMMY_DELIVERY,
              },
            },
          })
        );
        dispatch(
          hasNewDeliveries({
            data: {
              [DUMMY_ORDER]: {
                shopID,
                storeID,
                ...DUMMY_DELIVERY,
              },
            },
            shopID,
            storeID,
          })
        );
      }
    } else {
      dispatch(resetUIReducer());
    }
    // eslint-disable-next-line
  }, [currentStep, isOpen, steps]);

  useEffect(() => {
    changeShopSelected();
    //eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    (user.role === ADMIN_ROLE || user.role === SUPER_ADMIN_ROLE) &&
      dispatch(startGetStores({}));
    if (user.role === OPERATOR_ROLE) {
      setStoreSelected(user.storeID);
    } else {
      setStoreSelected("");
    }
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    if (storeSelected !== "" && user.role !== OPERATOR_ROLE) {
      dispatch(startChangeStoreSelected(storeSelected));
    } // eslint-disable-next-line
  }, [storeSelected]);

  useEffect(() => {
    if (storesKeys.includes(storeID)) setStoreSelected(storeID);
  }, [storesKeys, storeID]);

  useEffect(() => {
    if (user.role === OPERATOR_ROLE) {
      setData({
        toBeAccepted: toBeAccepted,
        inPreparation: inPreparation,
        toBeDelivered: toBeDelivered,
      });
      setAllData({
        toBeAccepted: toBeAccepted,
        inPreparation: inPreparation,
        toBeDelivered: toBeDelivered,
      });
    }
    // eslint-disable-next-line
  }, [toBeAccepted, inPreparation, toBeDelivered]);

  useEffect(() => {
    if (
      (user.role === ADMIN_ROLE || user.role === SUPER_ADMIN_ROLE) &&
      storeSelected !== ""
    ) {
      const {
        toBeAccepted: toBeAcceptedStore,
        inPreparation: inPreparationStore,
        toBeDelivered: toBeDeliveredStore,
      } = allStoresDeliveries[storeSelected] ?? {};

      setData({
        toBeAccepted: toBeAcceptedStore ?? {},
        inPreparation: inPreparationStore ?? {},
        toBeDelivered: toBeDeliveredStore ?? {},
      });
      setAllData({
        toBeAccepted: toBeAcceptedStore ?? {},
        inPreparation: inPreparationStore ?? {},
        toBeDelivered: toBeDeliveredStore ?? {},
      });
    }
    // eslint-disable-next-line
  }, [allStoresDeliveries, storeSelected, user.shopID]);

  const onLaunchSound = () => {
    dispatch(setActiveSoundDelivery(true));
  };
  const onStopSound = () => {
    dispatch(setActiveSoundDelivery(false));
  };

  const onSelectOrden = (event) => {
    setOrdenSelected(event);
    if (isOpen && steps[currentStep]?.actionID === "openModal") {
      setCurrentStep(currentStep + 1);
    }
    if (event?.type === "toBeAccepted" && event?.data?.isNew) {
      return onStopSound();
    }
    if (event?.type === "inPreparation") {
      const interval = intervalToDuration({
        start: new Date(event?.data?.dateOrderAccepted),
        end: new Date(),
      }).minutes;
      const minutes =
        event?.data?.timePreparation - interval >= 0
          ? event?.data?.timePreparation - interval
          : 0;
      minutes === 0 && onStopSound();
    }
  };

  const onSelectStore = (event) => {
    setStoreSelected(event.target.value);
  };

  const onSearch = (value) => {
    if (value.length > 0) {
      let toBeAcceptedData = {};
      Object.keys(allData.toBeAccepted).filter((key) => {
        if (
          allData.toBeAccepted[key].deliveryID
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return (toBeAcceptedData = {
            ...toBeAcceptedData,
            [key]: allData.toBeAccepted[key],
          });
        }
        return null;
      });
      let inPreparationData = {};
      Object.keys(allData.inPreparation).filter((key) => {
        if (
          allData.inPreparation[key].deliveryID
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return (inPreparationData = {
            ...inPreparationData,
            [key]: allData.inPreparation[key],
          });
        }
        return null;
      });
      let toBeDeliveredData = {};
      Object.keys(allData.toBeDelivered).filter((key) => {
        if (
          allData.toBeDelivered[key].deliveryID
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return (toBeDeliveredData = {
            ...toBeDeliveredData,
            [key]: allData.toBeDelivered[key],
          });
        }
        return null;
      });
      setData({
        toBeAccepted: toBeAcceptedData,
        inPreparation: inPreparationData,
        toBeDelivered: toBeDeliveredData,
      });
    } else {
      setData(allData);
    }
  };

  return (
    <BlockPlanNoActive>
      <Box
        sx={{
          height: { xs: "calc(100% - 80px)", sm: "calc(100% - 88px)" },
        }}
      >
        <ContainerPage
          mode="padding"
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex ",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TitlePage>{t(ORDERS_TITLE)}</TitlePage>
            <DescriptionPage>
              {`${t(ORDERS_DESCRIPTION)}, ${lowerCaseFirstLetter(
                t(ORDERS_REMEMBER_MARK_AS_DELIVERED)
              )}`}
            </DescriptionPage>

            {isLoadingStores && <Loader size={40} />}

            {(storesKeys.length > 0 || storeSelected.length > 0) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: {
                    xs: "flex-start",
                    sm: "center",
                  },
                  justifyContent: "space-between",
                  gap: 2,
                  flexDirection: {
                    xs: "column-reverse",
                    sm: "row",
                  },
                }}
              >
                <SearchBar
                  placeholder={t(ORDERS_SEARCHBAR_PLACEHOLDER)}
                  onEditText={(value) => onSearch(value)}
                  sx={{ my: 0 }}
                />
                {(user.role === ADMIN_ROLE ||
                  user.role === SUPER_ADMIN_ROLE) && (
                  <SelectStore
                    storeSelected={storeSelected}
                    storesList={stores}
                    onChange={onSelectStore}
                    showNewOrder={true}
                    hiddenSpecialStores={true}
                  />
                )}
              </Box>
            )}
          </Box>
        </ContainerPage>
        {storesKeys.length === 0 &&
          !isLoadingStores &&
          user.role !== OPERATOR_ROLE && (
            <>
              <Typography
                variant="h6"
                sx={{
                  px: { xs: 3, md: 4 },
                  width: "100%",
                  mt: 2,
                }}
              >
                {t(NO_STORES_CREATED)}
              </Typography>
              <Link to="/stores" style={{ textDecoration: "none" }}>
                <Button
                  sx={{
                    mx: { xs: 3, md: 4 },
                    mt: 2,
                  }}
                >
                  {t(BUTTON_ADD_STORE)}
                </Button>
              </Link>
            </>
          )}

        {storeSelected && (
          <KanbanDelivery
            data={data}
            setData={setData}
            ordenSelected={ordenSelected}
            setOrdenSelected={onSelectOrden}
            onLaunchSound={onLaunchSound}
            onStopSoundState={onStopSound}
            storeSelected={storeSelected}
          />
        )}

        {!storeSelected && <></>}
      </Box>
    </BlockPlanNoActive>
  );
};

export default Delivery;
