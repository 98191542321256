import React, { useState, useEffect } from "react";
import { useDispatchApp, useSelectorApp } from "../lib/redux";
import Box from "@mui/material/Box";
import UsersTabs from "../components/User/Users/UsersTabs";
import {
  startGetContactsByShopID,
  startGetUsersByShopID,
} from "../actions/users";
import { startGetStores } from "../actions/shops";
import TitlePage from "../components/Common/Texts/TitlePage";
import DescriptionPage from "../components/Common/Texts/DescriptionPage";
import { useTour } from "@reactour/tour";
import { STEPS_USERS_FUNCTIONALITY } from "../onboarding/steps";
import { USERS_DESCRIPTION, USERS_TITLE } from "../locales/keysTranslations";
import { useTranslationApp } from "../lib/i18next";
import ContainerPage from "../components/Common/Containers/ContainerPage";

const Users = () => {
  const [usersData, setUsersData] = useState({});
  const [contactData, setContactData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useSelectorApp((state) => state.auth);
  const { users, contacts } = useSelectorApp((state) => state.users);
  const stores = useSelectorApp((state) => state.shop.stores);
  const dispatch = useDispatchApp();

  const { setIsOpen, setSteps } = useTour();

  const { t } = useTranslationApp();

  useEffect(() => {
    dispatch(
      startGetUsersByShopID({
        onCallbackFinish: () => setIsLoading(false),
      })
    );
    dispatch(startGetContactsByShopID(user.shopID));
    dispatch(startGetStores({}));
    // eslint-disable-next-line
  }, [user.shopID]);

  useEffect(() => {
    setUsersData(users);
    setContactData(contacts);
  }, [users, stores, contacts]);

  return (
    <ContainerPage
      sx={{
        height: "calc(100% - 88px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <TitlePage
          hasTooltip={true}
          toolTipAction={() => {
            setSteps(STEPS_USERS_FUNCTIONALITY);
            setIsOpen(true);
          }}
        >
          {t(USERS_TITLE)}
        </TitlePage>
        <DescriptionPage>{t(USERS_DESCRIPTION)}</DescriptionPage>
      </Box>
      <UsersTabs
        loading={isLoading}
        usersData={usersData}
        storesList={stores || {}}
        onChangeUsersData={setUsersData}
        contactData={contactData}
        onChangeContactData={setContactData}
      />
    </ContainerPage>
  );
};

export default Users;
