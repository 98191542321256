import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslationApp } from "../../../lib/i18next";
import {
  SELECT_CATALOG,
  SELECT_STORE,
} from "../../../locales/keysTranslations";
import { useLocale } from "../../../Contexts/LocaleContext";
import InputLabel from "@mui/material/InputLabel";

const SelectsMenu = ({ data, storesList, onChange }) => {
  const { t } = useTranslationApp();
  const LOCALE = useLocale();
  const SPECIAL_STORES = Object.keys(LOCALE.specialStores || {});
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <FormControl variant="filled" sx={{ maxWidth: 400, width: "100%" }}>
        <InputLabel>{t(SELECT_CATALOG)}</InputLabel>

        <Select
          value={data.menuFrom}
          name="menuFrom"
          onChange={onChange}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{t(SELECT_CATALOG)}</em>
          </MenuItem>
          {Object.keys(storesList).map((key) => {
            if (SPECIAL_STORES.includes(storesList[key].storeType)) return null;
            return (
              <MenuItem key={key} value={key}>
                {storesList[key].name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ maxWidth: 400, width: "100%" }} variant="filled">
        <InputLabel>{t(SELECT_STORE)}</InputLabel>
        <Select
          label={t(SELECT_STORE)}
          value={data.menuTo}
          name="menuTo"
          onChange={onChange}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{t(SELECT_STORE)}</em>
          </MenuItem>
          {Object.keys(storesList).map((key) => {
            if (SPECIAL_STORES.includes(storesList[key].storeType)) return null;
            return (
              <MenuItem key={key} value={key}>
                {storesList[key].name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectsMenu;
