import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import TooltipModal from "../../Tooltips/TooltipModal";

const AnswerTitle = ({ children, sx, toolTip = null }) => {
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        mb: 1,
      }}
    >
      <Typography variant={matches ? "h5" : "body1"} sx={{ ...sx }}>
        {children}
      </Typography>
      {toolTip && (
        <TooltipModal title={toolTip.title} content={toolTip.content} />
      )}
    </Box>
  );
};

export default AnswerTitle;
